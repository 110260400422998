import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_TASK_EMPLOYEE,
} from '../actionTypes'

import { showLoading, hideLoading } from './loading'

export const getTaskEmployee = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TASK_EMPLOYEE.REQUEST })
    const { jwt,company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    /* OLD VERSION 
    const { data, meta } = await getAPI({
      url: `/api/employees`,
      headers: headers,
    })
    */
   //--- New Version By Jame เพิ่มให้เป็นไปตาม company และ API เฉพาะ
    let params = {
      company_id: company_info?.id ? company_info?.id : null,
    }

    const { data, meta } = await getAPI({
      url: '/api/employee-dropdrow',
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_TASK_EMPLOYEE.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_TASK_EMPLOYEE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Employee Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}