import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getEmployee } from '../../../redux/actions/summaryTask'
import { selectSummaryTask } from '../../../redux/selectors'

import useOutsideClick from '../helper/useOutsideClick'
import SearchSuggestBox from './SearchSuggestBox'

export default function SearchSuggestEmployee({
  selectedId,
  placeHolder,
  onSelectedSuggest,
  isError = false,
  departmentId,
  isFilterByDepartment = false,
  isDisabled = false,
  isDisableOwnerId = false,
  onTextChange,
  isPreview,
}) {
  const dispatch = useDispatch()

  const { employeeList } = useSelector(selectSummaryTask)
  const [dropDownViews, setDropDownView] = useState([])
  const [searchText, setSearchText] = useState('')
  const [selectedName, setSelectedName] = useState('')
  const [isOnBlur, setIsOnBlur] = useState(false)
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))
  const [noOwnerEmployeeList, setNoOwnerEmployeeList] = useState([])

  const outerRef = useOutsideClick(() => {
    setSearchText('')
  })

  
  useEffect(() => {
    const pagination = {
      page: 1,
      pageSize: 1000,
    }
    //
    //
    if (isFilterByDepartment) {
      if (departmentId) {
        let filters = {
          departmentId: departmentId,
        }
        dispatch(getEmployee(filters, pagination))
      }
    }
  }, [isFilterByDepartment, departmentId])

  useEffect(() => {
    if (employeeList?.length > 0) {
      let tmpData = []
      let tmpNoOwnerList = []

      for (let i = 0; i < employeeList.length; i++) {
        if (isDisableOwnerId) {
          if (employeeList[i].id != employee_info.id) {
            let tmp = {
              type: 'item-image',
              value: {
                title: (employeeList[i]?.first_name || '') + ' ' + (employeeList[i]?.last_name || ''),
                desc: employeeList[i]?.position || '',
                image: employeeList[i]?.employee_image || '',
              },
            }
            tmpData = [...tmpData, tmp]
            tmpNoOwnerList.push(employeeList[i])
          }
        } else {
          let tmp = {
            type: 'item-image',
            value: {
              title: (employeeList[i]?.first_name || '') + ' ' + (employeeList[i]?.last_name || ''),
              desc: employeeList[i]?.position || '',
              image: employeeList[i]?.employee_image || '',
            },
          }
          tmpData = [...tmpData, tmp]
        }
      }
      setNoOwnerEmployeeList(tmpNoOwnerList)

      let views = []
      for (let i = 0; i < tmpData.length; i++) {
        if (tmpData[i]?.type == 'header') {
          views.push(<div className={'header'}>{tmpData[i]?.value}</div>)
        } else if (tmpData[i]?.type == 'item-normal' || tmpData[i]?.type == 'item-image') {
          views.push(
            <div className={'item-normal'}>
              {tmpData[i]?.type == 'item-image' && (
                <div className="item-img">
                  {tmpData[i]?.value?.image ? <img src={tmpData[i].value.image} alt="search user image" /> : ''}
                </div>
              )}
              <div className="item-texts">
                {tmpData[i]?.value?.title && <div className="title">{tmpData[i]?.value?.title}</div>}
                {tmpData[i]?.value?.desc && <div className="desc">{tmpData[i]?.value?.desc}</div>}
              </div>
            </div>
          )
        }
      }
      setDropDownView([...views])
    } else {
      setDropDownView([])
    }
  }, [employeeList])

  useEffect(() => {
    const pagination = {
      page: 1,
      pageSize: 1000,
    }
    let filters = {
      searchText: searchText,
    }
    dispatch(getEmployee(filters, pagination))
  }, [searchText])

  useEffect(() => {
    if (employeeList?.length > 0 && selectedId) {
      for (let i = 0; i < employeeList.length; i++) {
        if (employeeList[i].id == selectedId) {
          setSelectedName(employeeList[i].first_name + ' ' + employeeList[i].last_name)
        }
      }
    }
  }, [employeeList, selectedId])

  const onSelectSuggest = (index) => {
    let data
    if (isDisableOwnerId) {
      data = noOwnerEmployeeList[index]
    } else {
      data = employeeList[index]
    }
    setSelectedName(data.first_name + ' ' + data.last_name)
    onSelectedSuggest(data)
    setIsOnBlur(true)
  }

  return (
    <Styled ref={outerRef} className="search_suggest_employee">
      {!isPreview ? (
        <SearchSuggestBox
          onChange={(e) => {
            setSearchText(e)
            if (onTextChange) {
              onTextChange(e)
            }
          }}
          dropdownViews={dropDownViews}
          onSelected={(index) => onSelectSuggest(index)}
          className={'search-suggest'}
          searchWidth="300px"
          dropdownWidth="300px"
          placeHolder={placeHolder}
          initValue={selectedName}
          borderColor={isError ? '#d92d20' : '#d0d5dd'}
          isDisabled={isDisabled}
        />
      ) : (
        <div className={'employee_name'}>{selectedName}</div>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  .title {
    font-weight: bold;
  }
  .title,
  .desc {
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`
