import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import { GetPermissionAction,GetPermissionOther,GetPermissionTabList } from '../../utils/common'

import SearchText from '../../components/form/SearchText'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import Table from '../../components/common/Table'
import CollapseMenu from '../../components/common/CollapseMenu'
import SidePanel from '../../components/common/SidePanel'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import { getProvinces, getDistricts, getSubDistricts } from '../../redux/actions/master'
import { getCompanyProfileById, updateCompanyProfile } from '../../redux/actions/company'
import { selectMaster } from '../../redux/selectors'

import ModalCreateCompany from '../../pages/department/ModalCreateCompany'
import ModalEditCompany from '../../pages/department/ModalEditCompany'

import { updateSubCompany, getTableSubCompany } from '../../redux/actions/company'
import { selectCompanyProfile } from '../../redux/selectors'


const Div = styled.div`
  p {
    margin: 0;
    font-size: 14px;
  }
  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }
  .text-bold {
    font-weight: bold;
  }
  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .button-footer-company-profile {
    margin-bottom: 25rem;
  }
  .button-footer {
    margin-bottom: 35rem;
  }
  .h-full {
    height: 100%;
  }
  .left-profile {
    margin-left: 0px;
  }
  .left {
    margin-left: 260px;
  }
  .right {
    margin-right: 230px;
  }
  .col-12 {
    width: 100%;
  }
  .input-col {
    display: flex;
    grid-template-columns: repeat(2, 10rem) 8rem;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input-header {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .icon {
    cursor: pointer;
  }
  .divider {
    margin: 2rem 0;
    border: 0.1rem solid #eaecf0;
  }
  .create-access-role-header {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .side-panel-container {
    padding: 2rem;
  }
  .d-flex {
    display: flex;
  }
  .row {
    flex-direction: row;
  }
  .justify-content-flex-start {
    justify-content: flex-start;
  }
  .css_margin_top_10 {
    margin-top: 10px;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .content-header {
    margin-top: 24px;
  }
  .float-end {
    float: right;
  }

  .float-start {
    float: left;
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .mt-50 {
    margin-top: 50px;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .w-100 {
    width: 100%;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .date-filter-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .drop-down {
    min-width: 10rem;
  }

  .task-report-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .task-box {
    border: 1px solid black;
    width: 100%;
    margin: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
  }

  .task-box .amount-tasks {
    font-weight: bold;
    text-align: center;
    color: black;
    font-size: 20px;
  }

  .filter-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .wrp-filter-menu {
      position: relative;
      display: flex;
    }

    .wrp-filter-btn {
      position: relative;
    }

    .dialog-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 300px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);
      max-height: 300px;

      .wrp-person-list {
        position: relative;
      }
      .person-list {
        height: 220px;
        overflow-y: scroll;
      }

      .person-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 4px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
        }

        .img-profile {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          background-color: var(--Gray-200);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .person-item:hover {
        background-color: var(--Gray-50);
      }
    }

    .filter-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 500px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);

      .wrp-filter-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clear-btn {
          padding: 4px 6px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        .clear-btn:hover {
          background: var(--Primary-25);
        }
      }
      .wrp-filter-option {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .title-option {
          font-weight: 600;
          margin-bottom: 6px;
        }
        .option-item-style {
          border: 1px solid var(--Gray-300);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Gray-50);
        }
        .option-item-style:hover {
          background-color: var(--Gray-100);
        }
        .option-item-active {
          border: 1px solid var(--Primary-600);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Primary-600);
          color: #fff;
        }
      }
    }
  }

  .wrp-filter-menu {
    display: flex;
    align-items: center;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header-company-profile {
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      border: 1px solid var(--Gray-200);
      background: var(--Base-White);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .main_box {
    display: grid;
    grid-template-columns: repeat(2, 25% [col-start] 75% [col-end]);
    /*gap: 20px;*/
    padding-top: 10px;
    .main_box_text_head {
      font-size: 14px;
      font-weight: 600;
      color: var(--Gray-700);
    }
    .main_box_text_sub {
      font-size: 14px;
      color: var(--Gray-600);
    }
    .main_box_edit {
      border: 1px solid rgb(208, 213, 221);
      border-radius: 8px;
    }
    .main_box_fill_data {
      margin: 16px 24px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 20px;
      .topic_fill {
        font-size: 14px;
        color: #212121;
        margin-bottom: 5px;
      }
      .topic_detail {
        font-size: 14px;
        color: #757575;
      }
    }
    .main_box_button {
      margin: 16px 24px;
      text-align: end;
    }
    .border_buttom {
      border-bottom: 1px solid rgb(234, 236, 240);
    }
  }

  .TextField_employee {
    width: 100%;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
`

const initCompanyFrom = {
  company_name_TH: '',
  company_name_EN: '',
  address: '',
  contact_name: '',
  contact_number: '',
  tex_id: '',
  thai_province: '',
  thai_amphure: '',
  thai_tambon: '',
  postcode: '',
  prefix_employee_id: '',

  address_EN: '',
  display_province_EN: '',
  display_amphure_EN: '',
  display_tambon_EN: '',
  contact_name_EN: '',

  theme: '',
  username_field: '',
}

const initCreateSubCompanyFrom = {
  sub_company_no: '',
  company_name_TH: '',
  company_name_EN: '',
  address: '',
  contact_name: '',
  contact_number: '',
  tex_id: '',
  thai_province: '',
  thai_amphure: '',
  thai_tambon: '',
  postcode: '',
  prefix_employee_id: '',
  num_pad_employee_id: 5,
  parents: 1,
  lv: 2,

  address_EN: '',
  display_province_EN: '',
  display_amphure_EN: '',
  display_tambon_EN: '',
  contact_name_EN: '',

  theme: '',
  username_field: '',
}

const initEditSubCompanyFrom = {
  sub_company_no: '',
  company_name_TH: '',
  address: '',
  contact_name: '',
  contact_number: '',
  thai_province: '',
  thai_amphure: '',
  thai_tambon: '',
  postcode: '',
  prefix_employee_id: '',

  theme: '',
  username_field: '',
}

const initialColorTheme = [
  {
    text: 'Purple',
    value: 'Default',
  },
  {
    text: 'Dark Green',
    value: 'Credence',
  },
  {
    text: 'Light Green',
    value: 'Wangkanai',
  },
]

const CompanyProfile = ({ companyID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const initLang = localStorage.getItem('lang')
  //--- ST ส่วนของ Permission LV3-4
  const permissionEditCompanyProfile = GetPermissionOther('company_profile', 'edit_company_profile');
  const permissionNewSubCompany = GetPermissionOther('company_profile', 'new_subcompany');
  //--- ED ส่วนของ Permission LV3-4

  const SubCompanyColumn = [
    {
      Header: t('sub_company_no'),
      accessor: 'branch_no',
      disableSortBy: false,
    },
    {
      Header: t('sub_company_name'),
      accessor: 'branch_name',
      disableSortBy: false,
    },
    {
      Header: t('address'),
      accessor: 'address',
      disableSortBy: false,
    },
    {
      Header: t('contact_name'),
      accessor: 'contact_name',
      disableSortBy: false,
    },
    {
      Header: t('contact_no'),
      accessor: 'contact_no',
      disableSortBy: false,
    },
    {
      Header: t('employee_prefix'),
      accessor: 'prefix_employee',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      accessor: 'action',
      disableSortBy: false,
    },
  ]

  const { provinces, districts, subdistricts } = useSelector(selectMaster)
  const { companyList, branchList, limit, pageCount, total } = useSelector(selectCompanyProfile)
  // new
  const [companyFrom, setCompanyFrom] = useState(initCompanyFrom)
  const [NewSubcompanyFrom, setNewSubcompanyFrom] = useState(initCreateSubCompanyFrom)
  const [subcompanyId, setsubcompanyId] = useState(null)
  const [EditSubcompanyFrom, setEditSubcompanyFrom] = useState(initEditSubCompanyFrom)

  const [checkEdit, setEditCheck] = useState(true)
  const [dataTable, setDataTable] = useState([])
  // list
  const [provinceList, setProvinceList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [subDistrictList, setSubDistrictList] = useState([])
  // side panel
  const [isOpenSidepanelNewBranch, setOpenSidepanelNewBranch] = useState(false)
  const [isOpenSidepanelEditBranch, setOpenSidepanelEditBranch] = useState(false)

  // modal
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  // filter sub company
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')

  const editSubCompany = (id, inp_v) => {
    setsubcompanyId(id)
    setOpenSidepanelEditBranch(true)
  }

  const deleteSubCompany = (id) => {
    setsubcompanyId(id)
    setConfirmDeleteModal(true)
  }

  const fetchData = useCallback(
    async (filter) => {
      dispatch(getTableSubCompany(filter))
    },
    [dispatch]
  )

  const OpenEditCompanayProfile = useCallback(
    (id) => {
      if(permissionEditCompanyProfile?.canUpdate === true){
        dispatch(getCompanyProfileById(id))
        setEditCheck(false)
      }else{
        let message_permission = 'No permission allowed ' + permissionEditCompanyProfile?.name_en;
        if(initLang == 'th'){
          message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionEditCompanyProfile?.name_th;
        }
        setTitleFail(message_permission);
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    setCompanyFrom((v) => ({
      ...v,
      company_name_TH: companyList.company_name_TH,
      company_name_EN: companyList.company_name_EN,
      address: companyList.address,
      contact_name: companyList.contact_name,
      contact_number: companyList.contact_number,
      tex_id: companyList.tex_id,
      thai_province: companyList.thai_province?.id,
      thai_amphure: companyList.thai_amphure?.id,
      thai_tambon: companyList.thai_tambon?.id,
      postcode: companyList.thai_tambon?.zip_code,
      prefix_employee_id: companyList.prefix_employee_id,

      address_EN: companyList.address_EN,
      display_province_EN: companyList.thai_province?.name_en,
      display_amphure_EN: companyList.thai_amphure?.name_en,
      display_tambon_EN: companyList.thai_tambon?.name_en,
      contact_name_EN: companyList.contact_name_EN,

      theme: companyList.theme,
      username_field: companyList.username_field,
    }))
  }, [companyList])

  useEffect(() => {
    const newbranchList = branchList.map((v) => {
      const list = [
        {
          text: t('Edit'),
          action: () => editSubCompany(v.id, v),
        },
        {
          text: t('delete'),
          action: () => deleteSubCompany(v.id),
        },
      ]
      return {
        ...v,
        branch_no: v?.id,
        branch_name: v?.company_name_TH,
        address: v?.address,
        contact_name: v?.contact_name,
        contact_no: v?.contact_number,
        prefix_employee: v?.prefix_employee_id,
        status: v?.status,
        action: <CollapseMenu list={list} />,
      }
    })
    setDataTable(newbranchList)
  }, [branchList])

  useEffect(() => {
    const filter = {
      skip: 1 * limit - limit,
      limit: limit,
    }
    fetchData(filter)
  }, [fetchData, limit])

  useEffect(() => {
    dispatch(getCompanyProfileById(companyID))
    dispatch(getProvinces())
  }, [dispatch])

  // set dropdown for province,district,sub district
  useEffect(() => {
    const newList = provinces.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
      text_en: v?.attributes?.name_en,
    }))
    setProvinceList(newList)
  }, [provinces])
  useEffect(() => {
    const newList = districts.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
      text_en: v?.attributes?.name_en,
    }))
    setDistrictList(newList)
  }, [districts])
  useEffect(() => {
    const newList = subdistricts.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
      postcode: v?.attributes?.zip_code,
      text_en: v?.attributes?.name_en,
    }))

    setSubDistrictList(newList)
  }, [subdistricts])

  //--- New Sub Company Get amphure,tambon
  useEffect(() => {
    if (NewSubcompanyFrom?.thai_province) {
      dispatch(getDistricts(NewSubcompanyFrom?.thai_province))
      setNewSubcompanyFrom((prev) => ({
        ...prev,
        thai_amphure: '',
        thai_tambon: '',
        postcode: '',
      }))
    }
  }, [NewSubcompanyFrom?.thai_province, dispatch])
  useEffect(() => {
    if (NewSubcompanyFrom?.thai_amphure) {
      dispatch(getSubDistricts(NewSubcompanyFrom?.thai_amphure))
      setNewSubcompanyFrom((prev) => ({
        ...prev,
        thai_tambon: '',
        postcode: '',
      }))
    }
  }, [NewSubcompanyFrom?.thai_amphure, dispatch])
  useEffect(() => {
    if (NewSubcompanyFrom?.thai_tambon) {
      const subDistrictMatch = subDistrictList.find((v) => v.value == NewSubcompanyFrom?.thai_tambon)
      setNewSubcompanyFrom((prev) => ({
        ...prev,
        postcode: subDistrictMatch.postcode,
      }))
    }
  }, [NewSubcompanyFrom?.thai_tambon, subDistrictList])

  //--- Edit Sub Company Get amphure,tambon
  useEffect(() => {
    if (EditSubcompanyFrom?.thai_province) {
      dispatch(getDistricts(EditSubcompanyFrom?.thai_province))
    }
  }, [EditSubcompanyFrom?.thai_province, dispatch])
  useEffect(() => {
    if (EditSubcompanyFrom?.thai_amphure) {
      dispatch(getSubDistricts(EditSubcompanyFrom?.thai_amphure))
    }
  }, [EditSubcompanyFrom?.thai_amphure, dispatch])
  useEffect(() => {
    if (EditSubcompanyFrom.thai_tambon != '') {
      const dataMatch = subDistrictList.find((v) => v.value === EditSubcompanyFrom.thai_tambon)
      if (dataMatch) {
        setEditSubcompanyFrom((prev) => ({
          ...prev,
          postcode: dataMatch?.postcode,
        }))
      }
    }
  }, [EditSubcompanyFrom.thai_tambon])

  ///---- END Get amphure,tambon
  useEffect(() => {
    if (companyFrom.thai_tambon != '') {
      const dataMatch = subDistrictList.find((v) => v.value === companyFrom.thai_tambon)
      setCompanyFrom((prev) => ({
        ...prev,
        postcode: dataMatch?.postcode,
        display_tambon_EN: dataMatch?.text_en,
      }))
    }
  }, [companyFrom.thai_tambon])

  useEffect(() => {
    if (companyFrom?.thai_province) {
      const dataMatch = provinceList.find((v) => v.value === companyFrom.thai_province)
      setCompanyFrom((prev) => ({
        ...prev,
        display_province_EN: dataMatch?.text_en,
      }))
      dispatch(getDistricts(companyFrom?.thai_province))
    }
  }, [companyFrom?.thai_province, dispatch])

  useEffect(() => {
    if (companyFrom?.thai_amphure) {
      const dataMatch = districtList.find((v) => v.value === companyFrom.thai_amphure)
      setCompanyFrom((prev) => ({
        ...prev,
        display_amphure_EN: dataMatch?.text_en,
      }))
      dispatch(getSubDistricts(companyFrom?.thai_amphure))
    }
  }, [companyFrom?.thai_amphure, dispatch])

  // onChange
  const onChangeFormEditCompany = (event) => {
    const { name, value } = event.target
    setCompanyFrom((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeDropDown = (name, value) => {
    if (name === 'thai_province') {
      setCompanyFrom((prev) => ({
        ...prev,
        [name]: value,
        thai_amphure: '',
        thai_tambon: '',
        postcode: '',
        display_amphure_EN: '',
        display_tambon_EN: '',
      }))
    } else if (name === 'thai_amphure') {
      setCompanyFrom((prev) => ({
        ...prev,
        [name]: value,
        thai_tambon: '',
        postcode: '',
        display_tambon_EN: '',
      }))
    } else if (name === 'thai_tambon') {
      setCompanyFrom((prev) => ({
        ...prev,
        [name]: value,
        postcode: '',
      }))
    } else {
      setCompanyFrom((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }
  const onChangePagination = async (value) => {
    const filter = {
      skip: value.page,
      limit: limit,
    }
    fetchData(filter)
  }
  const onClearSearch = () => {
    const filter = {
      skip: 0,
      limit: limit,
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
  }

  // submit
  const onSubmitUpdateCompany = () => {
    const formEdit = {
      data: {
        ...companyFrom,
      },
    }
    dispatch(updateCompanyProfile(1, formEdit))
    setEditCheck(true)
  }
  const confirmDelete = () => {
    const request = {
      data: {
        status: 'delete',
      },
    }
    dispatch(updateSubCompany(subcompanyId, request)).then(({ type }) => {
      if (type.endsWith('_SUCCESS')) {
        setConfirmDeleteModal(false)
        //---- CallRefresSubCompanyList
        setsubcompanyId(null)
        onClearSearch()
      } else {
        setTitleFail('Error Delete')
        setnameItemFail('')
        setDescriptionFail('')
        setFailModal(true)
      }
    })
  }

  // modal
  const closeEditCompanyProfile = () => {
    setEditCheck(true)
    setCompanyFrom((v) => ({
      ...v,
      company_name_TH: companyList.company_name_TH,
      company_name_EN: companyList.company_name_EN,
      address: companyList.address,
      contact_name: companyList.contact_name,
      contact_number: companyList.contact_number,
      tex_id: companyList.tex_id,
      thai_province: companyList.thai_province?.id,
      thai_amphure: companyList.thai_amphure?.id,
      thai_tambon: companyList.thai_tambon?.id,
      postcode: companyList.thai_tambon?.zip_code,

      address_EN: companyList.address_EN,
      display_province_EN: companyList.thai_province?.name_en,
      display_amphure_EN: companyList.thai_amphure?.name_en,
      display_tambon_EN: companyList.thai_tambon?.name_en,
      contact_name_EN: companyList.contact_name_EN,

      theme: companyList.theme,
      username_field: companyList.username_field,
    }))
  }
  const openCompanyProfile = () => {
    if(permissionNewSubCompany?.canUpdate === true){
      setOpenSidepanelNewBranch(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionNewSubCompany?.name_en;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionNewSubCompany?.name_th;
      }
      setTitleFail(message_permission);
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }
    
  }
  const closeOpenSidepanelNewBranch = () => {
    setOpenSidepanelNewBranch(false)
  }
  const SubmitOpenSidepanelNewBranch = () => {
    setOpenSidepanelNewBranch(false)
    onClearSearch()
  }
  const closeOpenSidepanelEditBranch = () => {
    setOpenSidepanelEditBranch(false)
  }
  const SubmitOpenSidepanelEditBranch = () => {
    setOpenSidepanelEditBranch(false)
    onClearSearch()
  }

  return (
    <Div>
      <div className="mb-2-rem">
        <div className="d-flex justify-content-between">
          <div>
            <div className="text-header">{t('company_profile')}</div>
            <span>{t('company_profile_sub_header')}</span>
          </div>
          {permissionEditCompanyProfile?.canRead === true && (
            <div>
              {checkEdit === true && <Button onClick={() => OpenEditCompanayProfile('1')}>{t('Edit')}</Button>}
            </div>
          )}
        </div>
        <div className="divider"></div>

        <div className="main_box">
          <div>
            <div className="main_box_text_head">Company Information (TH)</div>
            <div className="main_box_text_sub">Basic information of company in Thai</div>
          </div>
          <div>
            <div className="main_box_edit">
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">{t('company_name_th')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('company_name_th')}
                      name="company_name_TH"
                      value={companyFrom?.company_name_TH}
                      onChange={onChangeFormEditCompany}
                      disabled={checkEdit}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('tax_id_no')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('tax_id_no')}
                      name="tex_id"
                      value={companyFrom?.tex_id}
                      onChange={onChangeFormEditCompany}
                      disabled={checkEdit}
                    />
                  </div>
                </div>
              </div>
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">{t('address')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('address')}
                      name="address"
                      value={companyFrom?.address}
                      onChange={onChangeFormEditCompany}
                      disabled={checkEdit}
                    />
                  </div>
                </div>
              </div>
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">{t('province')}</div>
                  <div>
                    <Dropdown
                      id="Province"
                      optionList={provinceList}
                      value={companyFrom?.thai_province}
                      onChange={(value) => onChangeDropDown('thai_province', value)}
                      disabled={checkEdit}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('district')}</div>
                  <div>
                    <Dropdown
                      id="District"
                      optionList={districtList}
                      value={companyFrom?.thai_amphure}
                      onChange={(value) => onChangeDropDown('thai_amphure', value)}
                      disabled={checkEdit}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('sub_district')}</div>
                  <div>
                    <Dropdown
                      id="Sub-District"
                      optionList={subDistrictList}
                      value={companyFrom?.thai_tambon}
                      onChange={(value) => onChangeDropDown('thai_tambon', value)}
                      disabled={checkEdit}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('postcode')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('postcode')}
                      name="Passcode"
                      value={companyFrom?.postcode}
                      onChange={onChangeFormEditCompany}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">{t('contact_name')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('contact_name')}
                      name="contact_name"
                      value={companyFrom?.contact_name}
                      onChange={onChangeFormEditCompany}
                      disabled={checkEdit}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('contact_no')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('contact_no')}
                      name="contact_number"
                      value={companyFrom?.contact_number}
                      onChange={onChangeFormEditCompany}
                      disabled={checkEdit}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border_header"></div>

        <div className="main_box">
          <div>
            <div className="main_box_text_head">Company Information (EN)</div>
            <div className="main_box_text_sub">Basic information of company in English</div>
          </div>
          <div>
            <div className="main_box_edit">
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">{t('company_name_en')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('company_name_en')}
                      name="company_name_EN"
                      value={companyFrom?.company_name_EN}
                      onChange={onChangeFormEditCompany}
                      disabled={checkEdit}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('tax_id_no')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('tax_id_no')}
                      name="tex_id"
                      value={companyFrom?.tex_id}
                      onChange={onChangeFormEditCompany}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">{t('address')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('address')}
                      name="address_EN"
                      value={companyFrom?.address_EN}
                      onChange={onChangeFormEditCompany}
                      disabled={checkEdit}
                    />
                  </div>
                </div>
              </div>

              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">{t('province')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('province')}
                      name="Passcode"
                      value={companyFrom?.display_province_EN}
                      disabled={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('district')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('district')}
                      name="Passcode"
                      value={companyFrom?.display_amphure_EN}
                      disabled={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('sub_district')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('sub_district')}
                      name="Passcode"
                      value={companyFrom?.display_tambon_EN}
                      disabled={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('postcode')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('postcode')}
                      name="Passcode"
                      value={companyFrom?.postcode}
                      onChange={onChangeFormEditCompany}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">{t('contact_name')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('contact_name')}
                      name="contact_name_EN"
                      value={companyFrom?.contact_name_EN}
                      onChange={onChangeFormEditCompany}
                      disabled={checkEdit}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('contact_no')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('contact_no')}
                      name="contact_number"
                      value={companyFrom?.contact_number}
                      onChange={onChangeFormEditCompany}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="border_header"></div>

        <div className="main_box">
          <div>
            <div className="main_box_text_head">Other Setting</div>
            <div className="main_box_text_sub">Other system settings for company</div>
          </div>
          <div>
            <div className="main_box_edit">
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">{t('prefix_employee_id_max_3_characters')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('prefix_employee_id')}
                      name="prefix_employee_id"
                      value={companyFrom?.prefix_employee_id}
                      onChange={onChangeFormEditCompany}
                      disabled={checkEdit}
                    />
                  </div>
                </div>
                <div></div>
              </div>
              <div className="main_box_fill_data">
                <div>
                  <div className="topic_fill">Username Field</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder="Username Field"
                      name="username_field"
                      value={companyFrom?.username_field}
                      onChange={onChangeFormEditCompany}
                      disabled={checkEdit}
                    />
                    <p>Select which data field to be used as log-in (other than email)</p>
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Color theme</div>
                  <div>
                    <Dropdown
                      id="color-theme"
                      optionList={initialColorTheme}
                      value={companyFrom?.theme}
                      onChange={(value) => onChangeDropDown('theme', value)}
                      disabled={checkEdit}
                    />
                    <p>Select color theme for website</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {checkEdit === false ? (
          <div className="d-flex justify-content-flex-end css_margin_top_10">
            <ButtonOutline onClick={closeEditCompanyProfile} className="mr-1-rem">
              {t('cancel')}
            </ButtonOutline>
            <Button onClick={onSubmitUpdateCompany}>{t('save')}</Button>
          </div>
        ) : (
          ''
        )}
        <div className="border_header"></div>
      </div>

      <div>
        <div className="text-header">{t('sub_company')}</div>
        <span>{t('create_and_update_sub_company_details_here')}</span>
      </div>

      <div className="table-wrapper">
        <div className="table-header-company-profile">
          <div className="mb-1-rem">
            <div className="group">
              <div className="table-title">{t('sub_company_list')}</div>
              <div className="table-total">
                {total} {t('sub_companies')}
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <SearchText className="mb-1-rem" value={search} onChange={(e) => setSearch(e.target.value)} />
            {permissionNewSubCompany?.canRead === true && (
              <Button onClick={openCompanyProfile}>{t('new_sub_company')}</Button>
            )}
            
          </div>
        </div>
        <Table
          columns={SubCompanyColumn}
          data={dataTable}
          onStateChange={onChangePagination}
          pageCount={pageCount}
          page={page}
        />
      </div>

      {/* side panel */}
      <SidePanel isOpen={isOpenSidepanelNewBranch} setIsOpen={setOpenSidepanelNewBranch} width={1200}>
        <ModalCreateCompany
          width={1200}
          onClose={closeOpenSidepanelNewBranch}
          onSubmit={SubmitOpenSidepanelNewBranch}
        />
      </SidePanel>
      <SidePanel isOpen={isOpenSidepanelEditBranch} setIsOpen={setOpenSidepanelEditBranch} width={1200}>
        <ModalEditCompany
          width={1200}
          onClose={closeOpenSidepanelEditBranch}
          onSubmit={SubmitOpenSidepanelEditBranch}
          CompanyId={subcompanyId}
        />
      </SidePanel>

      {/* Dialog */}
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={confirmDelete}
        title={t('confrim_delete')}
        description={`Would you like to confirm delete this Sub Company?`}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default CompanyProfile
