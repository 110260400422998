import { OP } from 'redux/actionTypes'

import { initPagination } from 'constants/pagination'
import { deleteAPI, getAPI, postAPI, putAPI } from 'utils/api'
import { formatDate } from 'utils/dateHelpers'
import { hideLoading, showLoading } from '../loading'

const { GET, POST, PUT, DELETE, CLEAR } = OP

const pathname = '/api/op-sw-temps'

export default function OPSowTpl({ headers, company_info, employee_info }) {
  const getSowTpl = (sowTplId) => ({
    list: (payload) => {
      const { page, pageSize } = payload?.pagination || initPagination
      const { REQUEST, SUCCESS, FAILURE } = GET.SOW_TPL_LIST

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            sort: ['id:desc'],
            fields: ['code', 'name', 'description', 'hr', 'min', 'status', 'updatedAt'],
            pagination: { page, pageSize },
            filters: {
              company: {
                id: company_info?.id,
              },
              ...payload?.filters,
            },
            populate: {
              department: {
                fields: ['name'],
              },
            },
          }

          const response = await getAPI({
            url: `${pathname}`,
            headers,
            params,
          })

          const updatedData = response?.data?.map((item) => {
            const duration = `${item?.hr}h ${item?.min}m`
            const updatedAt = formatDate(item?.updatedAt)

            return { ...item, duration, updatedAt }
          })

          return dispatch({ type: SUCCESS, ...response, data: updatedData })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    byId: () => {
      const { REQUEST, SUCCESS, FAILURE } = GET.SOW_TPL_BY_ID

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            fields: ['code', 'name', 'description', 'hr', 'min', 'status'],
            populate: {
              department: {
                fields: ['name'],
              },
              positions: {
                fields: ['name'],
              },
              deliveries: {
                populate: '*',
              },
              tasks: {
                fields: ['code', 'name', 'status'],
                populate: {
                  department: {
                    fields: ['name'],
                  },
                },
              },
            },
          }

          const response = await getAPI({
            url: `${pathname}/${sowTplId}`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    logs: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.SOW_TPL_BY_ID_LOGS
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            pagination: { page, pageSize },
            sort: ['id:desc'],
          }

          const response = await getAPI({
            url: `${pathname}/${sowTplId}/logs`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const postSowTpl = (sowTplId) => ({
    create: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.SOW_TPL

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `${pathname}`,
            headers,
            data: {
              ...payload,
              company: {
                id: company_info?.id,
              },
            },
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    active: () => {
      const { REQUEST, SUCCESS, FAILURE } = POST.SOW_TPL_ID_ACTIVE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `${pathname}/${sowTplId}/active`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    inactive: () => {
      const { REQUEST, SUCCESS, FAILURE } = POST.SOW_TPL_ID_INACTIVE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `${pathname}/${sowTplId}/inactive`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const putSowTpl = (sowTplId) => ({
    update: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = PUT.SOW_TPL

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await putAPI({
            url: `${pathname}/${sowTplId}`,
            headers,
            data: { ...payload },
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const deleteSowTpl = (sowTplId) => {
    const { REQUEST, SUCCESS, FAILURE } = DELETE.SOW_TPL

    return async (dispatch) => {
      dispatch(showLoading())
      dispatch({ type: REQUEST })

      try {
        const response = await deleteAPI({
          url: `${pathname}/${sowTplId}`,
          headers,
        })

        return dispatch({ type: SUCCESS, ...response })
      } catch (error) {
        return dispatch({ type: FAILURE, error })
      } finally {
        dispatch(hideLoading())
      }
    }
  }

  const clearSowTpl = () => {
    return (dispatch) => dispatch({ type: CLEAR.SOW_TPL })
  }

  return {
    getSowTpl,
    postSowTpl,
    putSowTpl,
    deleteSowTpl,
    clearSowTpl,
  }
}
