import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export default function OptionBox({
  design = 'outline',
  className,
  dropdownWidth = 200,
  dropdownMaxHeight = 400,
  optionWidth = 50,
  dropdownTopGap = 5,
  bgColor = '#fff',
  borderRadius = '10px',
  borderColor = '#aaaaaa',
  borderWidth = '1px',
  datalist = [
    { id: 'view', text: 'View' },
    { id: 'edit', text: 'Edit' },
    { id: 'delete', text: 'Delete' },
    { id: 'download', text: 'Download' },
    { id: 'request', text: 'Request Approve' },
  ],
  onSelected,
  isVertical,
}) {
  const outerRef = useRef()

  const dropdownRef = useRef()

  const [isFocus, setIsFocus] = useState(false)

  const [globalMousePos, setGlobalMousePos] = useState({})
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })
  const [popUpTopPosition, setPopUpTopPosition] = useState(0)
  const [popUpLeftPosition, setPopUpLeftPosition] = useState(0)
  const [outerButtonHeight, setOuterButtonHeight] = useState(0)

  useEffect(() => {
    function handleClickOutside(event) {
      if (outerRef.current && !outerRef.current.contains(event.target)) {
        setIsFocus(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [outerRef])

  useEffect(() => {
    const handleMouseMove = (event) => {
      const cx = event.clientX
      const cy = event.clientY
      setGlobalMousePos({
        x: cx,
        y: cy,
      })
    }
    window.addEventListener('mousemove', handleMouseMove)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])

  useEffect(() => {
    setOuterButtonHeight(outerRef?.current?.clientHeight ?? 0)
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }, [])

  useEffect(() => {
    const tmpDropdownHeight = dropdownRef?.current?.clientHeight

    if (isFocus) {
      if (tmpDropdownHeight) {
        if (globalMousePos?.y + tmpDropdownHeight < windowSize?.height) {
          setPopUpTopPosition(outerButtonHeight + dropdownTopGap)
        } else {
          setPopUpTopPosition(-(tmpDropdownHeight + dropdownTopGap))
        }
      }

      if (globalMousePos?.x > dropdownWidth) {
        setPopUpLeftPosition(-dropdownWidth + optionWidth - 5)
      } else {
        setPopUpLeftPosition(5)
      }
    }
  }, [isFocus])

  const handleSelected = (index) => {
    setIsFocus(false)
    if (onSelected) {
      onSelected(index)
    }
  }

  const handleOptionClick = () => {
    if (isFocus == true) {
      setIsFocus(false)
    } else {
      setIsFocus(true)
    }
  }

  return (
    <Styled
      className={(design == 'outline' ? 'outline' : 'solid') + (className ? ' ' + className : '')}
      ref={outerRef}
      bgColor={bgColor}
      borderRadius={borderRadius}
      borderColor={borderColor}
      dropdownWidth={dropdownWidth}
      optionWidth={optionWidth}
      isFocus={isFocus}
      dropdownMaxHeight={dropdownMaxHeight}
      borderWidth={borderWidth}
      listTop={popUpTopPosition}
      listLeft={popUpLeftPosition}
    >
      <span className={'option-button'} onClick={handleOptionClick}>
        <IconOption width={'20'} degree={isVertical ? 90 : 0} />
      </span>

      {isFocus && (
        <ul className="list-dropdown" ref={dropdownRef}>
          {datalist?.map((item, index) => (
            <li key={'item-' + index} onClick={() => handleSelected(index)} className={'item item-' + index}>
              {item.text}
            </li>
          ))}
        </ul>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  position: relative;
  display: inline-block;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .option-button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    width: ${({ optionWidth }) => optionWidth + 'px'};
  }

  .item {
    padding: 10px;
    display: block;
    text-align: center;
    margin: 0 10px;
    cursor: pointer;
    border-radius: ${({ borderRadius }) => borderRadius};
    user-select: none;
  }

  .item:first-child {
    margin-top: 10px;
  }
  .item:last-child {
    margin-bottom: 10px;
  }

  .item:hover {
    background-color: #efefef;
  }

  .list-dropdown {
    position: absolute;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
    top: ${({ listTop }) => listTop + 'px'};
    left: ${({ listLeft }) => listLeft + 'px'};
    max-height: ${({ dropdownMaxHeight }) => dropdownMaxHeight + 'px'};
    overflow-y: auto;
    width: ${({ dropdownWidth }) => dropdownWidth + 'px'};
    border-radius: ${({ borderRadius }) => borderRadius};
    border: ${({ borderWidth }) => borderWidth} solid ${({ borderColor }) => borderColor};
    background-color: ${({ bgColor }) => bgColor};
    z-index: 999;
    background-color: ${({ bgColor }) => bgColor};
  }
`

function IconOption({ width = '30', color = '#333', degree = '0' }) {
  return (
    <svg
      width={width}
      viewBox="0 0 513 513"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: 'rotate(' + degree + 'deg)' }}
    >
      <path
        d="M256.038 304.449C282.547 304.449 304.038 282.958 304.038 256.449C304.038 229.939 282.547 208.449 256.038 208.449C229.528 208.449 208.038 229.939 208.038 256.449C208.038 282.958 229.528 304.449 256.038 304.449Z"
        fill="black"
      />
      <path
        d="M416.038 304.449C442.547 304.449 464.038 282.958 464.038 256.449C464.038 229.939 442.547 208.449 416.038 208.449C389.528 208.449 368.038 229.939 368.038 256.449C368.038 282.958 389.528 304.449 416.038 304.449Z"
        fill="black"
      />
      <path
        d="M96.0375 304.449C122.547 304.449 144.038 282.958 144.038 256.449C144.038 229.939 122.547 208.449 96.0375 208.449C69.5279 208.449 48.0375 229.939 48.0375 256.449C48.0375 282.958 69.5279 304.449 96.0375 304.449Z"
        fill="black"
      />
    </svg>
  )
}
