import styled from 'styled-components'
import iconClose from '../../assets/images/icon-close.svg'
import IconButton from '../common/IconButton'
import Modal from '../common/Modal'
import Button from '../form/button/Button'

import PropTypes from 'prop-types'

const Div = styled.div`
  position: relative;
  padding: 24px;
  width: 100%;
  max-width: 400px;

  .dialog-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    img {
      margin-right: 16px;
      margin-bottom: 8px;
    }

    .content {
      flex: 1;
      min-width: 270px;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
      text-align: center;
    }

    .name-item {
      font-size: 16px;
      color: var(--Gray-900);
      text-align: center;
      margin-bottom: 1rem;
    }

    .description {
      font-size: 14px;
      margin-top: 4px;
      color: var(--Gray-600);
      text-align: center;
    }
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;

    button {
      background: var(--Error-600);
      border: 1px var(--Error-600);
      justify-content: center;
    }
  }

  .close-modal-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .icon-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .bt-close {
    img {
      width: 16px;
      height: 16px;
    }
  }
`

const DialogFail = ({
  open,
  onClose,
  icon,
  title,
  description,
  textYes,
  textNo,
  onSubmit,
  nameItem,
  nameItem2,
  ...props
}) => {
  return (
    <Modal open={open} onClose={onClose} disableBackdropClick>
      <Div {...props}>
        <div className="close-modal-content">
          <IconButton className="bt-close" src={iconClose} onClick={onClose} />
        </div>
        <div className="icon-content">
          <img src={icon} />
        </div>
        <div className="dialog-content">
          <div className="content">
            <div className="title">{title}</div>
            {nameItem && <div className="name-item">{nameItem}</div>}
            {nameItem2 && <div className="name-item">{nameItem2}</div>}
            <div className="description">{description}</div>
          </div>
        </div>
        <div className="dialog-button">
          <Button onClick={onSubmit}>{textYes || 'Yes'}</Button>
        </div>
      </Div>
    </Modal>
  )
}

DialogFail.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  nameItem: PropTypes.string,
  nameItem2: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  description2: PropTypes.string,
  textYes: PropTypes.string,
  textNo: PropTypes.string,
  onSubmit: PropTypes.func,
  subTitle: PropTypes.string,
}

export default DialogFail
