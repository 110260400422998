import dayjs from 'dayjs'

import { TASK } from 'constants/operation-process'
import { initPagination } from 'constants/pagination'
import { OP } from 'redux/actionTypes'
import { getAPI, postAPI } from 'utils/api'
import { formatDate } from 'utils/dateHelpers'
import { hideLoading, showLoading } from '../loading'

const { GET, POST, CLEAR } = OP

const deliveriesParams = {
  fields: ['name', 'info', 'is_completed', 'type'],
  populate: {
    files: {
      filters: { status: 'Active' },
      fields: ['name', 'status'],
      populate: {
        file: {
          fields: ['mime', 'url', 'createdAt'],
        },
        employee: {
          fields: ['first_name', 'last_name'],
        },
      },
    },
    document: {
      fields: ['type', 'status'],
      populate: {
        business_doc: {
          fields: ['id', 'doc_id'],
        },
        signers: {
          fields: ['label', 'status', 'note'],
          populate: {
            employee: {
              fields: ['first_name', 'last_name'],
              populate: {
                position: {
                  fields: ['name'],
                },
              },
            },
          },
        },
      },
    },
  },
}

export default function OPTask({ headers, company_info, employee_info }) {
  const getTask = () => ({
    board: (payload) => {
      const { page, pageSize } = payload?.pagination || initPagination
      const { REQUEST, SUCCESS, FAILURE } = GET.TASKS_BOARD

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            sort: ['status:desc', 'createdAt:desc'],
            pagination: { page, pageSize },
            filters: {
              ...payload?.filters,
              status: TASK.STATUS.Open,
            },
            populate: {
              sw: {
                populate: {
                  sp: {
                    populate: {
                      employee: {
                        fields: ['first_name', 'last_name'],
                      },
                    },
                  },
                },
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `/api/op-tasks/board/company/${company_info.id}`,
            headers,
            params,
          })

          const updatedData = data?.map((task) => {
            const sp_name = task?.sw?.sp?.name
            const sw_name = task?.sw?.name
            const task_name = task?.name
            const sw_due_date = dayjs(task?.sw?.due_date).isValid()
              ? dayjs(task?.sw?.due_date).format('DD MMM YYYY')
              : null
            const created_by = task?.sw?.sp?.employee?.first_name + ' ' + task?.sw?.sp?.employee?.last_name

            return {
              ...task,
              sp_name,
              sw_name,
              task_name,
              sw_due_date,
              created_by,
            }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    me: (payload) => {
      const { page, pageSize } = payload?.pagination || initPagination
      const { REQUEST, SUCCESS, FAILURE } = GET.TASKS_ME

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            sort: ['status:desc', 'createdAt:desc'],
            pagination: { page, pageSize },
            filters: {
              ...payload?.filters,
            },
            fields: ['name', 'status', 'code'],
            populate: {
              sw: {
                fields: ['name', 'code', 'due_date'],
                populate: {
                  sp: {
                    fields: ['name', 'code'],
                    populate: {
                      employee: {
                        fields: ['first_name', 'last_name'],
                      },
                    },
                  },
                },
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `/api/op-tasks/me/company/${company_info.id}`,
            headers,
            params,
          })

          const updatedData = data?.map((task) => {
            const sp_name = task?.sw?.sp?.name
            const sw_name = task?.sw?.name
            const task_name = task?.name
            const sw_due_date = dayjs(task?.sw?.due_date).isValid()
              ? dayjs(task?.sw?.due_date).format('DD MMM YYYY')
              : null
            const created_by = task?.sw?.sp?.employee?.first_name + ' ' + task?.sw?.sp?.employee?.last_name

            return {
              ...task,
              sp_name,
              sw_name,
              task_name,
              sw_due_date,
              created_by,
            }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    byId: (taskId) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.TASK_BY_ID

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            populate: {
              employee: {
                fields: ['first_name', 'last_name'],
              },
              deliveries: deliveriesParams,
              sw: {
                populate: {
                  sp: {
                    populate: {
                      employee: {
                        fields: ['first_name', 'last_name'],
                      },
                    },
                  },
                },
              },
              files: {
                fields: ['name'],
                filters: { status: 'active' },
                populate: {
                  file: {
                    fields: ['mime', 'url', 'createdAt'],
                  },
                },
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `/api/op-tasks/${taskId}`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    employees: (taskId, payload) => {
      const { page, pageSize } = payload?.pagination || { page: 1, pageSize: 1000 }
      const { REQUEST, SUCCESS, FAILURE } = GET.TASK_BY_ID_EMPLOYEES

      return async (dispatch) => {
        // dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: payload?.filters || {},
            pagination: { page, pageSize },
            fields: ['first_name', 'last_name'],
            sort: ['first_name', 'last_name'],
          }

          const { data, meta } = await getAPI({
            url: `/api/op-tasks/${taskId}/employees`,
            headers,
            params,
          })

          const updatedData = data.map((employee) => ({
            ...employee,
            full_name: employee?.first_name + ' ' + employee?.last_name,
          }))

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          // dispatch(hideLoading())
        }
      }
    },
    files: (taskId, { pagination, filters = {} }) => {
      const { page, pageSize } = pagination || initPagination
      const { REQUEST, SUCCESS, FAILURE } = GET.TASK_BY_ID_FILES

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const newParams = {
            pagination: { page, pageSize },
            filters: {
              status: 'active',
              ...filters,
            },
            sort: ['createdAt:desc'],
            fields: ['name'],
            populate: {
              file: {
                fields: ['mime', 'url', 'createdAt'],
              },
              employee: {
                fields: ['first_name', 'last_name'],
              },
              task: {
                fields: ['name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `/api/op-tasks/${taskId}/files`,
            headers,
            params: newParams,
          })

          const updatedData = data?.map((file) => {
            const created_by = file?.employee?.first_name + ' ' + file?.employee?.last_name
            const createdAt = dayjs(file?.createdAt).isValid() ? dayjs(file?.createdAt).format('DD MMM YYYY') : null

            return { ...file, created_by, createdAt }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    logs: (taskId, payload) => {
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }
      const { REQUEST, SUCCESS, FAILURE } = GET.TASK_BY_ID_LOGS

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const newParams = {
            filters: payload?.filters || {},
            pagination: { page, pageSize },
            sort: ['createdAt:desc'],
            fields: ['action', 'createdAt'],
            populate: {
              employee: {
                fields: ['first_name', 'last_name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `/api/op-tasks/${taskId}/logs`,
            headers,
            params: newParams,
          })

          const updatedData = data.map((log) => {
            const created_by = log?.employee ? log.employee?.first_name + ' ' + log.employee?.last_name : null
            const createdAt = formatDate(log?.createdAt) || null

            return {
              ...log,
              created_by,
              createdAt,
            }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const postTask = () => ({
    assign: (taskId, payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.TASK_BY_ID_ASSIGN

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data, meta } = await postAPI({
            url: `/api/op-tasks/${taskId}/assign`,
            headers,
            data: payload,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    checkUnassign: (taskId) => {
      const { REQUEST, SUCCESS } = POST.TASK_BY_ID_CHECK_UNASSIGN

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data } = await postAPI({
            url: `/api/op-tasks/${taskId}/check_permissions_unassign`,
            headers,
          })

          return dispatch({ type: SUCCESS, canUnassignTask: !!data })
        } catch (error) {
          return dispatch({ type: SUCCESS, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    unassign: (taskId) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.TASK_BY_ID_UNASSIGN

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const res = await postAPI({
            url: `/api/op-tasks/${taskId}/unassign`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...res })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    complete: (taskId) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.TASK_BY_ID_COMPLETE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const res = await postAPI({
            url: `/api/op-tasks/${taskId}/complete`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...res })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    file: (taskId, payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.TASK_BY_ID_FILE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const res = await postAPI({
            url: `/api/op-tasks/${taskId}/files`,
            headers,
            data: payload,
          })

          return dispatch({ type: SUCCESS, ...res })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const clearTask = () => (dispatch) => {
    return dispatch({ type: CLEAR.TASK })
  }

  return {
    getTask,
    postTask,
    clearTask,
  }
}
