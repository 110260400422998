import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import InputFile from '../../components/form/InputFile'
import MediaFile from '../../containers/cms/MediaFile'

import logoIcon from '../../assets/images/logo-icon.png'
import ButtonOutline from '../../components/form/button/ButtonOutline'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  padding: 6rem 15rem;
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .align-items-center {
    align-items: center;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-2rem {
    font-size: 1.5rem;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }

  .group-input {
    margin-bottom: 4rem;
  }

  .header-group-input {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--Gray-200, #eaecf0);
  }

  .input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-new-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    /* align-content: center; */
  }

  .input-new-3 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input-new-4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-2 {
    display: grid;
    grid-template-columns: 24rem 1fr;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-header {
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--Gray-700);
    margin-bottom: 5px;
  }

  .button-outline {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }
`

function ViewCreateApplicationForm() {
  const query = new URLSearchParams(useLocation().search)
  const requireList = query.get('requireList')
  const [viewRequire, setViewRequire] = useState([])
  const { t } = useTranslation()
  useEffect(() => {
    const newList = requireList.split(',')
    setViewRequire(newList)
  }, [requireList])

  return (
    <Div>
      <div className="d-flex justify-content-center align-items-center mb-2-rem">
        <img src={logoIcon} />
      </div>
      <div className="header mb-2-rem">Employee Data Information</div>

      {viewRequire.some((v) => v == '1') && (
        <>
          <div className="group-input">
            <div className="header-group-input mb-1-rem">{t('basic_information')}</div>
            <div className="divider"></div>
            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('title')}*</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('first_name')}*</div>
                <TextField disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('last_name')}*</div>
                <TextField disabled={true} />
              </div>
            </div>

            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('first_name_th')}*</div>
                <TextField disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('last_name_th')}*</div>
                <TextField disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('photo')}</div>
                <MediaFile multiple={false} disabled={true} />
              </div>
            </div>

            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('departments')}*</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('job_position')}*</div>
                <Dropdown disabled={true} />
              </div>

              
              {
                /*
                <div className="input-new-2">
                <div className="input-header">{t('photo')}</div>
                <MediaFile multiple={false} disabled={true} />
                </div>
                */
              }
            </div>

            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('phone_number')}*</div>
                <TextField disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('email')}*</div>
                <TextField disabled={true} />
              </div>
            </div>
          </div>

          <div className="group-input">
            <div className="header-group-input mb-1-rem">{t('family_citizenship_information')}</div>
            <div className="divider"></div>
            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('martial_status')}*</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('number_of_children')}*</div>
                <TextField disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('nationality')}*</div>
                <Dropdown disabled={true} />
              </div>
            </div>

            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('gender')}*</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('identification_no')}.*</div>
                <TextField disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('passport_no')}.</div>
                <TextField disabled={true} />
              </div>
            </div>

            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('date_of_birth')}*</div>
                <Date disabled={true} />
              </div>
            </div>
          </div>

          <div className="group-input">
            <div className="header-group-input mb-1-rem">{t('private_contact')}</div>
            <div className="divider"></div>
            <div className="input-new-2 mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('address')}*</div>
                <TextField disabled={true} />
              </div>
            </div>
            <div className="input-new-3 mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('province')}*</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('district')}*</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('sub_district')}*</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('postcode')}*</div>
                <TextField disabled={true} />
              </div>
            </div>
            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('bank_account')}*</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('account_number')}*</div>
                <TextField disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('private_car_plate')}</div>
                <TextField disabled={true} />
              </div>
            </div>
            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('emergency_contact')}</div>
                <TextField disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('emergency_phone')}</div>
                <TextField disabled={true} />
              </div>
            </div>
          </div>
        </>
      )}

      {viewRequire.some((v) => v == '2') && (
        <>
          <div className="group-input">
            <div className="header-group-input mb-1-rem">{t('education_information')}</div>
            <div className="divider"></div>
            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('elementary_school')}</div>
                <TextField disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('entry_year')}</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('graduate_year')}</div>
                <Dropdown disabled={true} />
              </div>
            </div>
            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('junior_high_school')}</div>
                <TextField disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('entry_year')}</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('graduate_year')}</div>
                <Dropdown disabled={true} />
              </div>
            </div>
            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('senior_high_school')}</div>
                <TextField disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('entry_year')}</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('graduate_year')}</div>
                <Dropdown disabled={true} />
              </div>
            </div>
          </div>

          <div className="group-input">
            <div className="header-group-input mb-1-rem">{t('university')}</div>
            <div className="divider"></div>

            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('certificate_level')}</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('Field_of_study')}</div>
                <TextField disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('university')}</div>
                <TextField disabled={true} />
              </div>
            </div>
            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('entry_year')}</div>
                <Dropdown disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('graduate_year')}</div>
                <Dropdown disabled={true} />
              </div>
            </div>

            <ButtonOutline className="button-outline" disabled={true}>
             {t('add_major')}
            </ButtonOutline>
          </div>
        </>
      )}

      {viewRequire.some((v) => v == '3') && (
        <div className="group-input">
          <div className="header-group-input mb-1-rem">{t('resume')}</div>
          <div className="divider"></div>
          <div className="input-new mb-1-rem">
            <div className="input-new-2">
              <MediaFile disabled={true} />
            </div>
          </div>
        </div>
      )}

      {viewRequire.some((v) => v == '4') && (
        <div className="group-input">
          <div className="header-group-input mb-1-rem">{t('work_permit')}</div>
          <div className="divider"></div>
          <div className="input-new-4 mb-1-rem">
            <div className="input-new-2">
              <div className="input-header">{t('visa_no')}.</div>
              <TextField disabled={true} />
            </div>
            <div className="input-new-2">
              <div className="input-header">{t('visa_expire_date')}</div>
              <Date disabled={true} />
            </div>
          </div>
          <div className="input-new-4 mb-1-rem">
            <div className="input-new-2">
              <div className="input-header">{t('work_permit_no')}.</div>
              <TextField disabled={true} />
            </div>
            <div className="input-new-2">
              <div className="input-header">{t('work_permit_expire_date')}</div>
              <Date disabled={true} />
            </div>
          </div>

          <div className="input-new-4 mb-1-rem">
            <div className="input-new-2">
              <div className="input-header">{t('upload_file')}</div>
              <MediaFile disabled={true} />
            </div>
          </div>
        </div>
      )}
    </Div>
  )
}

export default ViewCreateApplicationForm
