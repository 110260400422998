import dayjs from 'dayjs'

import { APPROVER, REVIEWER, SW } from 'constants/operation-process'
import { initPagination } from 'constants/pagination'
import { OP } from 'redux/actionTypes'
import { getAPI, postAPI } from 'utils/api'
import { formatDate } from 'utils/dateHelpers'
import { hideLoading, showLoading } from '../loading'

const { GET, POST, CLEAR } = OP

const pathname = '/api/op-sws'

const deliveriesParams = {
  fields: ['name', 'info', 'is_completed', 'type'],
  populate: {
    files: {
      filters: { status: 'Active' },
      fields: ['name', 'status'],
      populate: {
        file: {
          fields: ['mime', 'url', 'createdAt'],
        },
        employee: {
          fields: ['first_name', 'last_name'],
        },
      },
    },
    document: {
      fields: ['type', 'status'],
      populate: {
        business_doc: {
          fields: ['id', 'doc_id'],
        },
        signers: {
          fields: ['label', 'status', 'note'],
          populate: {
            employee: {
              fields: ['first_name', 'last_name'],
              populate: {
                position: {
                  fields: ['name'],
                },
              },
            },
          },
        },
      },
    },
  },
}

export default function OPsw({ headers, company_info }) {
  const getSW = () => ({
    board: (payload) => {
      const { page, pageSize } = payload?.pagination || initPagination

      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = GET.SWs_BOARD

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: {
              ...payload?.filters,
              status: SW.STATUS.Open,
            },
            sort: ['status:desc', 'createdAt:desc'],
            pagination: { page, pageSize },
            fields: ['code', 'name', 'due_date', 'status'],
            populate: {
              sp: {
                fields: ['name'],
                populate: {
                  employee: {
                    fields: ['first_name', 'last_name'],
                  },
                },
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `${pathname}/board/company/${company_info?.id}`,
            headers,
            params,
          })

          const updatedData = data.map((sw) => {
            const sp_name = sw?.sp?.name
            const sw_name = sw?.name
            const created_by = sw?.sp?.employee?.first_name + ' ' + sw?.sp?.employee?.last_name
            const due_date = dayjs(sw?.due_date).isValid() ? dayjs(sw?.due_date).format('DD MMM YYYY') : null

            return { ...sw, created_by, sp_name, sw_name, due_date }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    me: (payload) => {
      const { page, pageSize } = payload?.pagination || initPagination

      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = GET.SWs_ME

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            sort: ['status:desc', 'createdAt:desc'],
            pagination: { page, pageSize },
            fields: ['code', 'name', 'due_date', 'status'],
            populate: {
              sp: {
                fields: ['name'],
                populate: {
                  employee: {
                    fields: ['first_name', 'last_name'],
                  },
                },
              },
            },
            filters: {
              ...payload?.filters,
            },
          }

          const { data, meta } = await getAPI({
            url: `${pathname}/me/company/${company_info?.id}`,
            headers,
            params,
          })

          const updatedData = data?.map((sw) => {
            const sp_name = sw?.sp?.name
            const sw_name = sw?.name
            const created_by = sw?.sp?.employee?.first_name + ' ' + sw?.sp?.employee?.last_name
            const due_date =
              sw?.due_date && dayjs(sw?.due_date).isValid() ? dayjs(sw?.due_date).format('DD MMM YYYY') : null

            return {
              ...sw,
              sw_name,
              sp_name,
              created_by,
              due_date,
            }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    byId: (swId) => {
      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = GET.SW_BY_ID

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            fields: ['code', 'name', 'description', 'due_date', 'status'],
            populate: {
              sp: {
                fields: ['name', 'code'],
              },
              department: {
                fields: ['name'],
              },
              employee: {
                fields: ['first_name', 'last_name'],
              },
              deliveries: deliveriesParams,
              tasks: {
                fields: ['code', 'name', 'description', 'status'],
                populate: {
                  department: {
                    fields: ['name'],
                  },
                  employee: {
                    fields: ['first_name', 'last_name'],
                  },
                  deliveries: deliveriesParams,
                },
              },
              reviewers: {
                filters: {
                  status: REVIEWER.STATUS.NotPass,
                },
                fields: ['status', 'note', 'updatedAt'],
                populate: {
                  employee: {
                    fields: ['first_name', 'last_name'],
                  },
                },
              },
              approver: {
                filters: {
                  status: APPROVER.STATUS.Rejected,
                },
                fields: ['status', 'note', 'updatedAt'],
                populate: {
                  employee: {
                    fields: ['first_name', 'last_name'],
                  },
                },
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `${pathname}/${swId}`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    files: (swId, { pagination, filters = {} }) => {
      const { page, pageSize } = pagination || initPagination

      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = GET.SW_BY_ID_FILES

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: {
              ...filters,
              status: 'active',
            },
            sort: ['createdAt:desc'],
            fields: ['name'],
            populate: {
              file: {
                fields: ['mime', 'url', 'createdAt'],
              },
              employee: {
                fields: ['first_name', 'last_name'],
              },
              task: {
                fields: ['name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `${pathname}/${swId}/files`,
            headers,
            params,
          })

          const updatedData = data?.map((file) => {
            const created_by = file?.employee?.first_name + ' ' + file?.employee?.last_name
            const createdAt = dayjs(file?.createdAt).isValid() ? dayjs(file?.createdAt).format('DD MMM YYYY') : null

            return { ...file, created_by, createdAt }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    employees: (swId, { pagination, filters = {} }) => {
      const { page, pageSize } = pagination || { ...initPagination, pageSize: 1000 }
      const { REQUEST, SUCCESS, FAILURE } = GET.SW_BY_ID_EMPLOYEES

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters,
            pagination: { page, pageSize },
            fields: ['first_name', 'last_name'],
            sort: ['first_name', 'last_name'],
          }

          const { data, meta } = await getAPI({
            url: `${pathname}/${swId}/employees`,
            headers,
            params,
          })

          const updatedData = data.map((employee) => ({
            ...employee,
            full_name: employee?.first_name + ' ' + employee?.last_name,
          }))

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    logs: (swId, payload) => {
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }
      const { REQUEST, SUCCESS, FAILURE } = GET.SW_BY_ID_LOGS

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const reviewAndApproveParams = {
            fields: ['id'],
            populate: {
              position: {
                fields: ['name'],
              },
            },
          }

          const params = {
            filters: payload?.filters || {},
            pagination: { page, pageSize },
            sort: ['createdAt:desc'],
            fields: ['action', 'createdAt'],
            populate: {
              employee: {
                fields: ['first_name', 'last_name'],
              },
              approver: reviewAndApproveParams,
              reviewer: reviewAndApproveParams,
              task: {
                fields: ['code', 'name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `${pathname}/${swId}/logs`,
            headers,
            params,
          })

          const updatedData = data.map((log) => {
            const created_by = log?.employee ? log.employee?.first_name + ' ' + log.employee?.last_name : null
            const createdAt = formatDate(log?.createdAt) || null

            return {
              ...log,
              created_by,
              createdAt,
            }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const postSW = () => ({
    assign: (swId, payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.SW_BY_ID_ASSIGN

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const res = await postAPI({
            url: `${pathname}/${swId}/assign`,
            headers,
            data: payload,
          })

          return dispatch({ type: SUCCESS, ...res })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    checkUnassign: (swId) => {
      const { REQUEST, SUCCESS } = POST.SW_BY_ID_CHECK_UNASSIGN

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data } = await postAPI({
            url: `${pathname}/${swId}/check_permissions_unassign`,
            headers,
          })

          return dispatch({ type: SUCCESS, canUnassignSW: !!data })
        } catch (error) {
          return dispatch({ type: SUCCESS, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    unassign: (swId) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.SW_BY_ID_UNASSIGN

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const res = await postAPI({
            url: `${pathname}/${swId}/unassign`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...res })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    complete: (swId) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.SW_BY_ID_COMPLETE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const res = await postAPI({
            url: `${pathname}/${swId}/complete`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...res })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    file: (swId, payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.SW_BY_ID_FILE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const res = await postAPI({
            url: `${pathname}/${swId}/files`,
            headers,
            data: payload,
          })

          return dispatch({ type: SUCCESS, ...res })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const clearSW = () => (dispatch) => {
    return dispatch({ type: CLEAR.SW })
  }

  return {
    getSW,
    postSW,
    clearSW,
  }
}
