import { initPagination } from 'constants/pagination'
import { OP } from 'redux/actionTypes'
import { deleteAPI, getAPI, postAPI } from 'utils/api'
import { formatDate } from 'utils/dateHelpers'
import { hideLoading, showLoading } from '../loading'

const { GET, POST, DELETE, CLEAR } = OP

export default function OPExtra({ headers, company_info, employee_info }) {
  const milestonesParams = {
    fields: ['id'],
    populate: {
      sw: {
        fields: ['code', 'name', 'description', 'hr', 'min'],
        populate: {
          department: {
            fields: ['id', 'name'],
          },
          positions: {
            fields: ['id', 'name'],
          },
          deliveries: {
            fields: ['name', 'info', 'type'],
            populate: {
              document: {
                fields: ['type'],
              },
            },
          },
          tasks: {
            fields: ['code', 'name', 'description'],
            populate: {
              department: {
                fields: ['id'],
              },
              deliveries: {
                fields: ['name', 'info'],
              },
            },
          },
        },
      },
      reviewers: {
        fields: ['hr', 'min'],
        populate: {
          position: { fields: ['id', 'name'] },
        },
      },
      approver: {
        fields: ['hr', 'min'],
        populate: {
          position: { fields: ['id', 'name'] },
        },
      },
    },
  }

  const getExtra = () => ({
    spTemplates: (payload) => {
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }

      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = GET.SP_TEMPLATES
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            pagination: { page, pageSize },
            fields: ['name', 'code'],
            filters: {
              company: { id: company_info?.id ? company_info.id : null },
              department: { id: employee_info?.department_id ? employee_info.department_id : null },
              ...payload?.filters,
            },
          }

          const { data, meta } = await getAPI({
            url: '/api/op-sp-temps',
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    spTemplateById: (spTempId) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.SP_TEMPLATE_BY_ID

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            fields: ['name', 'code', 'description'],
            populate: {
              company: {
                fields: ['id'],
                populate: {
                  working_times: true,
                },
              },
              department: {
                fields: ['id'],
              },
              milestones: milestonesParams,
            },
          }

          const { data, meta } = await getAPI({
            url: `/api/op-sp-temps/${spTempId}`,
            headers,
            params,
          })
          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    clTemplates: (payload) => {
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }

      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = GET.CL_TEMPLATES
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            pagination: { page, pageSize },
            fields: ['name', 'code'],
            filters: {
              company: { id: company_info?.id ? company_info.id : null },
              ...payload?.filters,
            },
          }

          const { data, meta } = await getAPI({
            url: '/api/op-chain-link-temps',
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    clTemplateById: (clTempId) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.CL_TEMPLATE_BY_ID

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            fields: ['name', 'code', 'description'],
            populate: {
              company: {
                fields: ['id'],
                populate: {
                  working_times: true,
                },
              },
              department: {
                fields: ['id'],
              },
              chains: {
                fields: ['condition'],
                populate: {
                  sp: {
                    fields: ['code', 'name', 'description'],
                    populate: {
                      department: {
                        fields: ['id', 'name'],
                      },
                      positions: {
                        fields: ['id', 'name'],
                      },
                      milestones: milestonesParams,
                    },
                  },
                },
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `/api/op-chain-link-temps/${clTempId}`,
            headers,
            params,
          })
          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    tags: ({ pagination, filters = {} }) => {
      const { page, pageSize } = pagination || { ...initPagination, pageSize: 1000 }

      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = GET.TAGS
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            pagination: {
              page,
              pageSize,
            },
            fields: ['name'],
            sort: ['name'],
            filters: {
              ...filters,
            },
          }

          const { data, meta } = await getAPI({
            url: '/api/op-tags',
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        }
      }
    },
    notes: (spId, payload) => {
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 100 }
      const { REQUEST, SUCCESS, FAILURE } = GET.NOTES

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: {
              ...(payload?.filters || {}),
              sp: { id: spId },
            },
            sort: ['createdAt:desc'],
            pagination: { page, pageSize },
            populate: {
              employee: {
                fields: ['id', 'first_name', 'last_name'],
              },
            },
          }

          const { data, meta } = await getAPI({ url: `/api/op-notes`, headers, params })

          const employeeCache = {}

          const employeeParams = {
            fields: ['id', 'photo'],
            populate: {
              photo: {
                fields: ['url'],
              },
            },
          }

          const updatedData = await Promise.all(
            data?.map(async (note) => {
              let empData

              if (employeeCache[note.employee.id]) {
                empData = employeeCache[note.employee.id]
              } else {
                const response = await getAPI({
                  url: `/api/employees/${note.employee.id}`,
                  headers,
                  params: employeeParams,
                })
                empData = response.data
                employeeCache[note.employee.id] = empData
              }

              const createdAt = formatDate(note?.createdAt) || null
              const created_by = note?.employee.first_name + ' ' + note?.employee.last_name

              return {
                ...note,
                created_by,
                createdAt,
                employee: {
                  ...note.employee,
                  employee_profile_img: empData?.photo_images?.[0]?.url,
                },
              }
            })
          )

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    departments: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.DEPARTMENTS
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            pagination: { page, pageSize },
            fields: ['id', 'name'],
            filters: {
              company: { id: company_info?.id },
              ...payload?.filters,
            },
          }

          const { data, meta } = await getAPI({
            url: '/api/departments',
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    docTypes: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.DOC_TYPE_LIST
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            pagination: { page, pageSize },
            fields: ['doc_types'],
            filters: {
              company: {
                id: company_info?.id,
              },
            },
          }

          const response = await getAPI({
            url: '/api/doc-types',
            headers,
            params,
          })

          const updatedData = response?.data[0]?.doc_types || []

          return dispatch({ type: SUCCESS, data: updatedData, meta: response?.meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const postExtra = () => ({
    note: (spId, data) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.NOTE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: '/api/op-notes',
            headers,
            data: {
              ...data,
              sp: { id: spId },
            },
          })

          return dispatch({ type: SUCCESS, data: response?.data, meta: response?.meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    delivery: (deliveryId, payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.DELIVERY_ID_FILE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `/api/op-deliveries/${deliveryId}/files`,
            headers,
            data: payload,
          })

          return dispatch({ type: SUCCESS, data: response?.data, meta: response?.meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const deleteExtra = () => ({
    file: (fileId) => {
      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = DELETE.FILE

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data, meta } = await deleteAPI({
            url: `/api/op-files/${fileId}`,
            headers,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    delivery: (deliveryFileId) => {
      const { REQUEST, SUCCESS, FAILURE } = DELETE.DELIVERY_ID_FILE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data, meta } = await deleteAPI({
            url: `/api/op-delivery-files/${deliveryFileId}`,
            headers,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const clearNotes = () => {
    return (dispatch) => {
      return dispatch({ type: CLEAR.NOTES })
    }
  }

  return {
    getExtra,
    postExtra,
    deleteExtra,
    clearNotes,
  }
}
