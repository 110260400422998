import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getCustomerList } from '../../../redux/actions/document'
import { selectDocument } from '../../../redux/selectors'
import useOutsideClick from '../helper/useOutsideClick'
import SearchSuggestBox from './SearchSuggestBox'
export default function SearchSuggestCustomer({
  selectedId,
  selectedIdName,
  placeHolder,
  onSelectedSuggest,
  isError = false,
  departmentId,
  isFilterByDepartment = false,
  isDisabled = false,
  isDisableOwnerId = false,
  type = 'customer_id',
  onTextChange,
}) {
  const dispatch = useDispatch()

  const { customerList } = useSelector(selectDocument)
  const [dropDownViews, setDropDownView] = useState([])
  const [searchText, setSearchText] = useState('')
  const [selectedName, setSelectedName] = useState('')
  const [isOnBlur, setIsOnBlur] = useState(false)
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))

  const outerRef = useOutsideClick(() => {
    setSearchText('')
  })

  useEffect(() => {
    let filters
    if (type == 'customer_id') {
      filters = {
        customerId: searchText,
      }
    } else if (type == 'customer_name') {
      filters = {
        customerName: searchText,
      }
    } else if (type == 'tax_id') {
      filters = {
        taxId: searchText,
      }
    }
    if (selectedIdName) {
      filters = {
        select_id_name: selectedIdName,
      }
    }

    dispatch(getCustomerList(filters))
  }, [searchText, selectedIdName])

  useEffect(() => {
    if (customerList?.length > 0) {
      let tmpData = []
      for (let i = 0; i < customerList.length; i++) {
        let tmp = {
          type: 'item-image',
          value: {
            title: customerList[i]?.name || '',
            desc: customerList[i]?.customer_id || '',
            tax_id: customerList[i]?.tax_id || '',
          },
        }
        tmpData = [...tmpData, tmp]
      }

      let views = []
      for (let i = 0; i < tmpData.length; i++) {
        if (tmpData[i]?.type == 'header') {
          views.push(<div className={'header'}>{tmpData[i]?.value}</div>)
        } else if (tmpData[i]?.type == 'item-normal' || tmpData[i]?.type == 'item-image') {
          views.push(
            <div className={'item-normal'}>
              {/* {tmpData[i]?.type == 'item-image' && (
                <div className="item-img">
                  {tmpData[i]?.value?.image ? <img src={tmpData[i].value.image} alt="search user image" /> : ''}
                </div>
              )} */}
              <div className="item-texts">
                {tmpData[i]?.value?.title && <div className="title">{tmpData[i]?.value?.title}</div>}
                {tmpData[i]?.value?.desc && <div className="desc">{'- ' + tmpData[i]?.value?.desc}</div>}
                {tmpData[i]?.value?.tax_id && <div className="tax-id">{'- ' + tmpData[i]?.value?.tax_id}</div>}
              </div>
            </div>
          )
        }
      }
      setDropDownView([...views])
    } else {
      setDropDownView([])
    }
  }, [customerList])

  useEffect(() => {
    if (customerList?.length > 0 && selectedId) {
      //

      for (let i = 0; i < customerList.length; i++) {
        if (customerList[i].id == selectedId) {
          if (type == 'customer_id') {
            setSelectedName(customerList[i].customer_id)
          } else if (type == 'customer_name') {
            setSelectedName(customerList[i].name)
          } else if (type == 'tax_id') {
            setSelectedName(customerList[i].tax_id)
          }
        }
      }
    }
  }, [customerList, selectedId])

  const onSelectSuggest = (index) => {
    const data = customerList[index]

    if (type == 'customer_id') {
      setSelectedName(data.customer_id)
    } else if (type == 'customer_name') {
      setSelectedName(data.customer_name)
    } else if (type == 'tax_id') {
      setSelectedName(data.tax_id)
    }
    onSelectedSuggest(data)
    setIsOnBlur(true)
  }
  return (
    <Styled ref={outerRef}>
      <SearchSuggestBox
        onChange={(e) => {
          setSearchText(e)
          if (onTextChange) {
            onTextChange(e)
          }
        }}
        dropdownViews={dropDownViews}
        onSelected={(index) => onSelectSuggest(index)}
        className={'search-suggest'}
        searchWidth="300px"
        dropdownWidth="400px"
        placeHolder={placeHolder}
        initValue={selectedName}
        borderColor={isError ? '#d92d20' : '#d0d5dd'}
        isDisabled={isDisabled}
        isHideIcon
      />
    </Styled>
  )
}

const Styled = styled.div`
  .item-texts {
    /* width: 310px; */
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .title {
    font-weight: bold;
    margin-right: 6px;
    font-size: 18px;
  }
  .title,
  .desc {
    width: fit-content;
    white-space: nowrap;
    margin-right: 6px;
  }
  .tax-id {
    flex: 1;
    width: fit-content;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .list-dropdown {
    min-height: 50px;
    .no-employ {
      top: 12px !important;
    }
  }
`
