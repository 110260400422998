import { useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { BsLayoutSidebarInset } from 'react-icons/bs'
import { RiSearchLine } from 'react-icons/ri'
import { TbLayoutSidebarRightCollapse } from 'react-icons/tb'

import logo from 'assets/images/logo.svg'
import Input, { InputAdornment } from 'components/form2/form-input'

const Header = forwardRef(function Header({ onCollapse, onExpand, isCollapse, onSearchEnter, onSearchClear }, ref) {
  const inputRef = useRef(null)
  const [searchValues, setSearchValues] = useState([])
  const breakpointLG = useMediaQuery('(min-width:1024px)')

  const handleCollapse = () => {
    onCollapse && onCollapse()
  }

  const handleExpand = () => {
    onExpand && onExpand()
  }

  const handleSearchEnter = (event) => {
    const value = event.target.value

    if (event.key === 'Enter') {
      const hasDuplicate = searchValues.find((item) => item?.name === value)

      if (hasDuplicate) {
        event.target.value = ''
        return
      }

      const uId =
        Date.now().toString(36) + Math.floor(Math.pow(10, 12) + Math.random() * 9 * Math.pow(10, 12)).toString(36) // generate unique id e.g. 1z2b3h4a5c6d

      setSearchValues((prev) => {
        const newSearchValues = [...prev, { id: uId, name: value }]
        onSearchEnter?.(newSearchValues)
        return newSearchValues
      })

      event.target.value = ''

      return
    }
  }

  useImperativeHandle(ref, () => ({
    onSearchClear() {
      setSearchValues([])
      onSearchClear && onSearchClear()
    },
    setNewSearchValues(newSearchValues) {
      setSearchValues(newSearchValues)
    },
  }))

  return (
    <StyledHeader ref={ref} className="erp-menu-header">
      <StyledLogo>
        <img className="erp-menu-logo" src={logo} alt="logo" />
        <span className="erp-menu-title">ERP System</span>
      </StyledLogo>

      {breakpointLG && (
        <span className={`erp-menu-collapse-icon`} onClick={() => (isCollapse ? handleExpand() : handleCollapse())}>
          {isCollapse ? <TbLayoutSidebarRightCollapse fontSize={20} /> : <BsLayoutSidebarInset fontSize={20} />}
        </span>
      )}

      <span
        className={`erp-menu-search ${isCollapse ? 'in-active' : 'active'}`}
        onClick={() => {
          if (isCollapse) {
            handleExpand()

            setTimeout(() => {
              inputRef.current.focus()
            }, 300)
          }
        }}
      >
        <RiSearchLine className="search-icon" fontSize={20} />

        <Input
          className="search-input"
          slotProps={{
            input: {
              ref: inputRef,
            },
          }}
          placeholder="Search..."
          onKeyDown={handleSearchEnter}
          startAdornment={
            <InputAdornment>
              <SearchOutlinedIcon />
            </InputAdornment>
          }
        />
      </span>
    </StyledHeader>
  )
})

export default Header

const StyledHeader = styled('div')`
  display: flex;
  align-items: center;
  padding: 16px;
  min-height: 64px;
  height: auto;
  z-index: 2;
  position: relative;
  top: 0;
  transition: all 0.3s;

  & .erp-menu-collapse-icon {
    display: flex;
    transition: all 0.3s;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 6px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
      color: var(--Primary-50);
    }
  }

  .erp-menu-search {
    .search-icon,
    .search-input {
      display: none;
    }
  }

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    padding: 16px;
    min-height: 64px;
    height: auto;
    border-bottom: 1px solid var(--Primary-500);
    z-index: 2;
    position: sticky;
    top: 0;
    transition: all 0.3s;

    flex-wrap: wrap;

    .erp-menu-search {
      display: flex;
      width: 100%;
      margin-top: 1rem;

      &.active {
        .search-icon {
          display: none;
        }

        .search-input {
          display: flex;
        }
      }

      &.in-active {
        .search-icon {
          display: block;
        }

        .search-input {
          display: none;
        }
      }

      & .MuiInput-root {
        width: 100%;
        background: var(--Primary-500);
        border: 1px solid var(--Primary-600);
        color: var(--Primary-200);

        &:not(.Mui-disabled):hover {
          border: 1px solid var(--Primary-500);
        }

        & .Mui-focused {
          background: var(--Base-White);
          color: var(--Gray-900);

          & .MuiInput-input::placeholder {
            color: var(--Gray-200);
          }
        }

        & .MuiInput-input {
          box-shadow: none !important;
          color: var(--Primary-50);

          &::placeholder {
            color: var(--Primary-200);
          }
        }
      }
    }
  }
`

const StyledLogo = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  -webkit-box-flex: 1;
  flex-grow: 1;

  &img.erp-menu-logo {
    width: 32px;
    height: 32px;
    margin-right: 14px;
  }

  & > span.erp-menu-title {
    -webkit-box-flex: 1;
    flex-grow: 1;
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
    color: var(--Primary-100);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`
