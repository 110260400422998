import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getAdminUser } from '../../../../utils/common'
import { dlog } from '../../../../utils/helper'
import Checkbox from '../../../form/Checkbox'
import Date from '../../../form/Date'
import Dropdown from '../../../form/Dropdown'
import TextField from '../../../form/TextField'

import { useDispatch } from 'react-redux'
import { getTaskTemplateList } from '../../../../redux/actions/projectTask'
import Button from '../../../form/button/Button'
import SearchSuggestEmployee from '../../../widget/combo/SearchSuggestEmployee'
import ImageButton from '../../../widget/element/ImageButton'
import TextEditor from '../../../widget/element/TextEditor'
import IconBin from '../../../widget/icon/IconBin'
import SearchSuggestTemplate from '../../../widget/combo/SearchSuggestTemplate'
import { useTranslation } from 'react-i18next'

export default function ProjectDetail({ data, isInvalid = false, onDataChange, type }) {
  const { t } = useTranslation()
  const user = getAdminUser()
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const dispatch = useDispatch()
  const tmpData = {
    name: '',
    type: 'General',
    description: '',
    start_date: '',
    end_date: '',
    project_manager: '',
    created_by: type == 'new_project' ? employee_info?.id : '',
    task_kpis: [],
    company_id: company_info?.id
  }
  const tmpKpis = [
    {
      name: 'Time',
      description: '',
      isChecked: false,
      isError: false,
    },
    {
      name: 'Cost',
      description: '',
      isChecked: false,
      isError: false,
    },
    {
      name: 'Quality',
      description: '',
      isChecked: false,
      isError: false,
    },
    {
      name: 'Productivity',
      description: '',
      isChecked: false,
      isError: false,
    },
  ]
  const [projectTypeList, setProjectTypeList] = useState([
    {
      text: 'Blank Project',
      value: 'General',
    },
  ])

  const [menuTabList, setMenuTabList] = useState([
    {
      name: 'Project Detail',
      value: 'project-detail',
      active_status: true,
    },
    {
      name: 'Team',
      value: 'team',
      active_status: false,
    },
  ])
  const [currentMenuTabSelected, setCurrentMenuTabSelected] = useState('project-detail')

  const [projectData, setProjectData] = useState(tmpData)
  const [taskKpiList, setTaskKpiList] = useState([...tmpKpis])
  const [moreKpiList, setMoreKpiList] = useState([])

  const [isErrorProjectName, setIsErrorProjectName] = useState(false)
  const [isErrorProjectType, setIsErrorProjectType] = useState(false)
  const [isErrorDescription, setIsErrorDescription] = useState(false)
  const [isErrorStartDate, setIsErrorStartDate] = useState(false)
  const [isErrorEndDate, setIsErrorEndDate] = useState(false)
  const [isErrorProjectManager, setIsErrorProjectManager] = useState(false)

  const [projectName, setProjectName] = useState(projectData.name)
  const [projectType, setProjectType] = useState(projectData.name || 'General')
  const [isSetTemplateList, setIsSetTemplateList] = useState(false)

  // useEffect(() => {
  //   ;(async () => {
  //     if (!isSetTemplateList) {
  //       const taskTemplateRes = await dispatch(getTaskTemplateList())
  //       if (taskTemplateRes?.data) {
  //         let templateList = [...taskTemplateRes.data]
  //         let tmpTemplateList = []
  //         for (let i = 0; i < templateList.length; i++) {
  //           let tmpObj = {
  //             text: templateList[i].name,
  //             value: templateList[i].id,
  //           }
  //           tmpTemplateList.push(tmpObj)
  //         }
  //         setProjectTypeList([...projectTypeList, ...tmpTemplateList])
  //         setIsSetTemplateList(true)
  //       }
  //     }
  //   })()
  // }, [])

  useEffect(() => {
    
      if (type == 'new_project') {
        if (Object.keys(data).length > 0) {
          if (data?.task_kpis) {
            setKpisFromData(data?.task_kpis)
          }
          let tmpData = data
          if (tmpData?.description != '') {
            // dlog(tmpData, "DATA_NEW_PROJECT")
            //   tmpData.description = JSON.parse(tmpData.description)
            let tmpDesc = tmpData?.description
            let tmpConvertDeltaContent = tmpDesc?.substring(0, 1)
            let tmpContents = ''
            if (tmpConvertDeltaContent == '[') {
              tmpContents = tmpDesc
            } else {
              // let tmp = {
              //   delta: [
              //     {
              //       insert: tmpDesc+"\n"
              //     }
              //   ],
              //   html: "<p>"+tmpDesc+"</p>",
              // }
              let tmp = [
                {
                  insert: tmpDesc + '\n',
                },
              ]
              tmpContents = JSON.stringify(tmp)
            }
            tmpData.description = tmpContents
          }
          setProjectName(tmpData.name)
          setProjectData(data)
        }
      } else {
        // EDIT_PROJECT ------
        // dlog(data, 'DATA_EDIT')
        if (Object.keys(data).length > 0) {
          let tmpData = {
            name: data?.name,
            type: data?.type,
            description: data?.description,
            start_date: data?.start_date,
            end_date: data?.end_date,
            project_manager: data?.project_manager?.id,
            created_by: data?.createdBy?.id,
            task_kpis: data?.project_task_kpis,
            // task_kpis: [],
          }

          if (tmpData?.description != '') {
            let tmpDesc = tmpData?.description
            let tmpConvertDeltaContent = tmpDesc?.substring(0, 1)
            let tmpContents = ''
            if (tmpConvertDeltaContent == '[') {
              tmpContents = tmpDesc
            } else {
              let tmp = [
                {
                  insert: tmpDesc + '\n',
                },
              ]
              tmpContents = JSON.stringify(tmp)
            }
            tmpData.description = tmpContents
          }
          // dlog(tmpData, "NEW_TMP_DATA")

          // dlog(data?.project_task_kpis.length, "LENGTH")
          if (data.task_template_ref) {
            setProjectType(data.task_template_ref.id)
          } else {
            setProjectType(data?.type)
          }
          setProjectName(data?.name)
          setKpisFromData(data?.project_task_kpis)
          setProjectData(tmpData)
        }
      }
    
  }, [data])

  useEffect(() => {
    if (isInvalid) {
      checkValidateInputs()
    }
  }, [isInvalid])

  const setKpisFromData = (kpiList) => {
    if (kpiList.length > 0) {
      let tmpList = [...kpiList]
      let tmpMainKpis = [...taskKpiList]
      for (let i = 0; i < tmpMainKpis.length; i++) {
        for (let j = 0; j < tmpList.length; j++) {
          if (tmpList[j].name == tmpMainKpis[i].name) {
            tmpMainKpis[i].isChecked = true
            tmpMainKpis[i].description = tmpList[j].description
            if (type == 'edit_project') {
              tmpMainKpis[i].isEdit = false
              tmpMainKpis[i].id = tmpList[j].id
            }
          }
        }
      }
      setTaskKpiList([...tmpMainKpis])

      let tmpMoreKpis = []
      for (let i = 0; i < tmpList.length; i++) {
        if (
          tmpList[i].name != 'Time' &&
          tmpList[i].name != 'Cost' &&
          tmpList[i].name != 'Quality' &&
          tmpList[i].name != 'Productivity'
        ) {
          let tmpObj = {
            name: tmpList[i].name,
            description: tmpList[i].description,
            isError: false,
          }
          if (type == 'edit_project') {
            tmpObj.id = tmpList[i].id
          }
          tmpMoreKpis.push(tmpObj)
        }
      }
      setMoreKpiList([...tmpMoreKpis])
    }
  }

  const handleOnChangeInput = (e, type) => {
    let tmpData = projectData

    if (type == 'project_name') {
      let val = e.target.value
      tmpData.name = val
      // setProjectName(val)
    } else if (type == 'description') {
      let val = e.delta
      // dlog(val, "VAL_tmpData")
      tmpData.description = JSON.stringify(val)
    }
    let tmpList = combineKpiData(taskKpiList, moreKpiList)
    tmpData.task_kpis = tmpList
    setProjectData(tmpData)
    if (onDataChange) {
      onDataChange(tmpData)
    }
  }
  const onChangeDate = (value, type) => {
    let tmpData = projectData
    if (type == 'start_date') {
      tmpData.start_date = value
    } else {
      tmpData.end_date = value
    }
    let tmpList = combineKpiData(taskKpiList, moreKpiList)
    tmpData.task_kpis = tmpList
    setProjectData(tmpData)
    if (onDataChange) {
      onDataChange(tmpData)
    }
  }
  const handleDropdownSelected = (value, type) => {
    let tmpData = projectData
    // dlog('handleDropdownSelected !!!')
    if (type == 'manager') {
      // tmpData.project_manager = value;
      tmpData.project_manager = value.id // searchSuggestEmployee
      setIsErrorProjectManager(false)
    } else {
      if (value != 'General') {
        // แบบเดิม
        // if (projectTypeList.length > 1) {
        //   let tmpList = [...projectTypeList]
        //   const tmpObj = tmpList.find((x) => x.value == value)
        //   tmpData.type = tmpObj.text
        //   tmpData.task_template_ref = value
        // }

        //--- แบบใหม่ ถ้าใช้ SearchSuggestTemplate ---
        // dlog(value, "VALLL")
        tmpData.type = value.name
        tmpData.task_template_ref = value.id
      } else {
        tmpData.type = value
      }

      // setProjectType(value) // แบบเดิม
      setProjectType(value.name) // ถ้าใช้ SearchSuggestTemplate ใช้ ==> value.name
    }
    let tmpList = combineKpiData(taskKpiList, moreKpiList)
    tmpData.task_kpis = tmpList
    setProjectData(tmpData)
    if (onDataChange) {
      onDataChange(tmpData)
    }
  }
  const onChangeSelectCheckBox = (value, index) => {
    let tmpKpiData = [...taskKpiList]
    tmpKpiData[index].isChecked = value
    // if(type == 'edit_project' && value) {
    //   dlog(tmpKpiData, 'tmpKpiData')
    //   dlog('ADD_NEW_ONE')
    //   dlog(data?.project_task_kpis, 'OLD_DATA')

    // }
    let tmpData = projectData
    let tmpList = combineKpiData(tmpKpiData, moreKpiList)
    tmpData.task_kpis = tmpList
    setTaskKpiList([...tmpKpiData])
    if (onDataChange) {
      onDataChange(tmpData)
    }
  }
  const setTextKpi = (value, index) => {
    let tmpKpiData = [...taskKpiList]
    tmpKpiData[index].description = value
    if (type == 'edit_project') {
      tmpKpiData[index].isEdit = true
    }
    let tmpData = projectData
    let tmpList = combineKpiData(tmpKpiData, moreKpiList)
    tmpData.task_kpis = tmpList
    setTaskKpiList([...tmpKpiData])
    if (onDataChange) {
      onDataChange(tmpData)
    }
  }
  const handleClickBtnAddKpiTopic = () => {
    let tmpObj = {
      name: '',
      description: '',
      isError: false,
    }
    if (type == 'edit_project') {
      tmpObj.isAdd = true
    }
    let tmpList = [...moreKpiList]
    tmpList.push(tmpObj)
    setMoreKpiList([...tmpList])
  }
  const handleRemoveMoreKpi = (index) => {
    let tmpMoreList = [...moreKpiList]
    tmpMoreList.splice(index, 1)
    let tmpData = projectData
    let tmpList = combineKpiData(taskKpiList, tmpMoreList)
    tmpData.task_kpis = tmpList
    setMoreKpiList([...tmpMoreList])
    if (onDataChange) {
      onDataChange(tmpData)
    }
  }
  const setTextMoreKpi = (value, index, type) => {
    let tmpKpiData = [...moreKpiList]
    if (type == 'name') {
      tmpKpiData[index].name = value
    } else {
      tmpKpiData[index].description = value
    }
    if (type == 'edit_project') {
      tmpKpiData[index].isEdit = true
    }
    let tmpData = projectData
    let tmpList = combineKpiData(taskKpiList, tmpKpiData)
    tmpData.task_kpis = tmpList
    setMoreKpiList([...tmpKpiData])
    if (onDataChange) {
      onDataChange(tmpData)
    }
  }
  const combineKpiData = (mainKpis, moreKpis) => {
    let dataList = []

    for (let i = 0; i < mainKpis.length; i++) {
      if (mainKpis[i].isChecked) {
        let tmpObj
        if (type == 'new_project') {
          tmpObj = {
            name: mainKpis[i].name,
            description: mainKpis[i].description,
          }
        } else {
          tmpObj = mainKpis[i]
        }
        dataList.push(tmpObj)
      }
    }

    for (let i = 0; i < moreKpis.length; i++) {
      let tmpObj
      if (type == 'new_project') {
        tmpObj = {
          name: moreKpis[i].name,
          description: moreKpis[i].description,
        }
      } else {
        tmpObj = moreKpis[i]
      }
      dataList.push(tmpObj)
    }
    // dlog(dataList, "dataList")
    // dlog(dataList.length, "dataList LENGTH")
    return dataList
  }
  const checkValidateInputs = () => {
    let isValidProjectName = false
    let isValidProjectType = false
    let isValidDescription = false
    let isValidStartDate = false
    let isValidEndDate = false
    let isValidProjectManager = false

    let tmpData = projectData

    if (tmpData.name != '') {
      isValidProjectName = true
      setIsErrorProjectName(false)
    } else {
      isValidProjectName = false
      setIsErrorProjectName(true)
    }

    if (tmpData.type != '') {
      isValidProjectType = true
      setIsErrorProjectType(false)
    } else {
      isValidProjectType = false
      setIsErrorProjectType(true)
    }

    if (tmpData.description != '') {
      isValidDescription = true
      setIsErrorDescription(false)
    } else {
      isValidDescription = false
      setIsErrorDescription(true)
    }

    if (tmpData.start_date != '') {
      isValidStartDate = true
      setIsErrorStartDate(false)
    } else {
      isValidStartDate = false
      setIsErrorStartDate(true)
    }

    if (tmpData.end_date != '') {
      isValidEndDate = true
      setIsErrorEndDate(false)
    } else {
      isValidEndDate = false
      setIsErrorEndDate(true)
    }

    if (tmpData.project_manager != '') {
      isValidProjectManager = true
      setIsErrorProjectManager(false)
    } else {
      isValidProjectManager = false
      setIsErrorProjectManager(true)
    }

    if (
      isValidProjectName &&
      isValidProjectType &&
      isValidDescription &&
      isValidStartDate &&
      isValidEndDate &&
      isValidProjectManager
    ) {
      return true
    } else {
      return false
    }
  }
  // const generateId = (length) => {
  //   var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  //   var result = ''
  //   for (var i = 0; i < length; i++) {
  //     result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
  //   }
  //   return result
  // }

  const convertValue = (contents) => {
    if (contents != '') {
      return JSON.parse(contents)
    }
    return ''
  }
  return (
    <Styled>
      <div className="content-container">
        {currentMenuTabSelected == 'project-detail' && (
          <div className="project-detail">
            <div className="top-content">
              <div className="row-wrapper">
                <div className="wrp-input-field pdr-10">
                  <div className="title-filed">{t('project_name')}</div>
                  <TextField
                    className="text-field w-100"
                    // key={'project-name-' + generateId(5)}
                    value={projectName}
                    onChange={(e) => {
                      setIsErrorProjectName(false)
                      setProjectName(e.target.value)
                      handleOnChangeInput(e, 'project_name')
                    }}
                    error={isErrorProjectName}
                    placeholder={t('project_name')}
                  />
                </div>

                <div className="wrp-input-field">
                  <div className="title-filed">{t('project_type')}</div>
                  {/* <Dropdown
                    className="dropdown w-100"
                    optionList={projectTypeList}
                    value={projectType}
                    onChange={(e) => {
                      setIsErrorProjectType(false)
                      handleDropdownSelected(e, 'type')
                    }}
                    error={isErrorProjectType}
                  /> */}
                  <SearchSuggestTemplate 
                    placeHolder={(projectType == "General" ? "Blank Project" : projectType) || "Blank Project"}
                    selectedId={projectType}
                    onSelectedSuggest={(e) => handleDropdownSelected(e, 'type')}
                  />
                </div>
              </div>

              <div className="wrp-input-field mb-10">
                <div className="txt-description mb-6">{t('description')}</div>
                {/* <InputTextArea
                  className={'input-text-area ' + (isErrorDescription ? 'input-text-area-error' : '')}
                  placeHolder="Description"
                  initValue={projectData.description}
                  onChange={(e) => {
                    setIsErrorDescription(false)
                    handleOnChangeInput(e, 'description')
                  }}
                /> */}
                {projectData && (
                  <TextEditor
                    className="text-editor"
                    initValue={convertValue(projectData.description)}
                    onBlur={(e) => {
                      // setIsErrorDescription(false)
                      handleOnChangeInput(e, 'description')
                    }}
                  />
                )}
              </div>

              <div className="row-wrapper">
                <div className="wrp-input-field pdr-10">
                  <div className="title-filed">{t('start_date')}</div>
                  <Date
                    className={'date-select w-100'}
                    value={projectData?.start_date}
                    onChange={(e) => {
                      setIsErrorStartDate(false)
                      onChangeDate(e, 'start_date')
                    }}
                    error={isErrorStartDate}
                  />
                </div>

                <div className="wrp-input-field">
                  <div className="title-filed">{t('end_date')}</div>
                  <Date
                    className={'date-select w-100'}
                    value={projectData?.end_date}
                    onChange={(e) => {
                      setIsErrorEndDate(false)
                      onChangeDate(e, 'end_date')
                    }}
                    error={isErrorEndDate}
                  />
                </div>
              </div>

              <div className="wrp-input-field pdr-10">
                <div className="title-filed">{t('project_manager')}</div>
                <div className="wrp-search-suggestion">
                  <SearchSuggestEmployee
                    placeHolder={t('search_name')}
                    selectedId={projectData.project_manager}
                    onSelectedSuggest={(e) => handleDropdownSelected(e, 'manager')}
                    isError={isErrorProjectManager}
                  />
                </div>
              </div>
            </div>

            <div className="bottom-content">
              <div className="title-bottom-content">{t('project_kpi_evaluation')}</div>
              <div className="bottom-input">
                {taskKpiList?.map((item, index) => (
                  <div className="wrp-main-kpi" key={'main-kpi-' + index}>
                    <Checkbox
                      className="check-box-style"
                      label={item.name}
                      onChange={(e) => onChangeSelectCheckBox(e, index)}
                      checked={item.isChecked}
                      key={item.name}
                    />
                    <div className="text-field-main-kpi">
                      <TextField
                        className="text-field-kpi"
                        disabled={!item.isChecked}
                        value={item.description}
                        onChange={(e) => setTextKpi(e.target.value, index)}
                        error={item.isError}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className="wrp-more-kpi">
                {moreKpiList?.map((item, index) => (
                  <div className="wrp-more-kpi-item" key={'more-kpi-' + index}>
                    <TextField
                      className="text-filed-choice"
                      value={item.name}
                      onChange={(e) => setTextMoreKpi(e.target.value, index, 'name')}
                      error={item.name == '' && item.isError ? true : false}
                    />
                    <div className="wrp-detail-kpi">
                      <TextField
                        className="text-field-kpi"
                        value={item.description}
                        onChange={(e) => setTextMoreKpi(e.target.value, index, 'description')}
                        error={item.description == '' && item.isError ? true : false}
                      />
                      <div className="btn-del-filed-kpi" onClick={() => handleRemoveMoreKpi(index)}>
                        <ImageButton size={38} icon={<IconBin />} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <Button onClick={() => handleClickBtnAddKpiTopic()}>{t('add_kpi_topic')}</Button>
          </div>
        )}
        {currentMenuTabSelected == 'team' && <div className="team">{t('team')}</div>}
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  .content-container {
    padding: 20px;
    flex: 1;
  }
  /* .project-detail, */
  .team {
    margin-top: 20px;
  }
  .top-content {
    /* display: grid;
    grid-template-columns: fit-content(100%) auto;
    grid-gap: 16px;
    margin-bottom: 32px; */
    border-bottom: 1px solid var(--Gray-300);
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
  .title-filed {
    color: var(--Gray-600);
    display: flex;
    align-items: center;
    margin-bottom: 4px;
  }
  .text-field,
  .date-select,
  .dropdown {
    width: 240px;
  }
  .description {
    align-items: start;
    .txt-description {
      height: 40px;
      display: flex;
      align-items: center;
    }
  }
  .input-text-area {
    width: 100%;
    border: 1px solid var(--Gray-300);
    font-size: 16px;
    resize: none;
  }
  .input-text-area-error {
    border: 1px solid var(--Error-600);
  }
  .title-bottom-content {
    font-weight: bold;
    font-size: 24px;
  }
  .bottom-input {
    margin: 16px 0;
  }
  .check-box-style {
    display: flex;
    align-items: center;
    width: 160px;
    margin-right: 5px;
    label {
      margin-right: 0;
      display: flex;
      align-items: center;

      .MuiTypography-root {
        font-size: 16px;
      }
    }
  }
  .text-field-main-kpi {
    width: 100%;
  }
  .text-field-kpi {
    /* width: 95%; */
    width: 100%;
  }
  .text-filed-choice {
    width: 160px;
    margin-right: 5px;
  }
  .wrp-detail-kpi {
    display: flex;
    width: 100%;
  }
  .btn-del-filed-kpi {
    margin-left: 10px;
    svg {
      position: relative;
      top: 5px;
    }
  }
  .wrp-main-kpi {
    width: 100%;
    display: flex;
    margin-bottom: 10px;
  }
  .wrp-more-kpi-item {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;
  }
  .wrp-search-suggestion {
    .wrp-search-input {
      width: 50%;
    }
    .list-dropdown {
      width: 50%;
    }
  }

  .wrp-tab-menu {
    width: 100%;
    display: flex;

    .btn-menu-style {
      border: none;
      border-bottom: 2px solid transparent;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      border-bottom: 2px solid var(--Primary-600) !important;
    }
  }

  .bottom-content-button {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    border-top: 1px solid var(--Gray-300);

    .btn-cancel {
      margin-right: 8px;
    }
  }

  .row-wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 20px;

    .wrp-input-field {
      flex: 1;
    }
  }
  .w-100 {
    width: 100%;
  }
  .w-50 {
    width: 50%;
  }
  .mb-10 {
    margin-bottom: 20px;
  }
  .mb-6 {
    margin-bottom: 6px;
  }
  .pdr-10 {
    padding-right: 10px;
  }

  .text-editor {
    height: 200px;
    .main_container {
      height: 175px !important;
    }
  }
`
