import React, { useState, useEffect } from 'react'
import ReactQuill from 'react-quill'
import { getCompanyInfo } from '../../utils/common'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import Modal from '../common/Modal'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'

import logo from '../../assets/images/logo.svg'
import { IoMdClose } from 'react-icons/io'

const Div = styled.div`
  .mr-12-px {
    margin-right: 12px;
  }
  .mr-110-px {
    margin-right: 110px;
  }
  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .icon {
    cursor: pointer;
  }
  .icon-close-container {
    display: flex;
    justify-content: flex-end;
  }
  .button-w {
    display: flex;
    justify-content: center;
    flex: 1;
    text-align: center;
  }
  .d-flex {
    display: flex;
  }
  .gap {
    gap: 1rem;
  }
  .justify-end {
    justify-content: flex-end;
    width: 110%;
    margin-top: 20px;
  }
  .justify-center {
    justify-content: center;
  }
  .icon {
    margin-top: 10px;
    font-size: 22px;
  }
  .card-modal-container {
    padding: 32px;
    border-radius: 8px;
    overflow: hidden;
  }

  .card-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 20px;
    h2,
    p {
      margin: 0px;
      padding: 5px 10px;
    }
    p {
      color: #6941c6;
    }
    h2 {
      text-align: center;
    }
  }

  .card-modal-body {
    max-height: 356px;
    overflow-y: auto;
    margin-bottom: 1rem;
  }

  .card-modal-footer {
    padding: 10px 20px;
  }

  /* Styling for the scrollbar */
  .card-modal-body::-webkit-scrollbar {
    width: 6px;
  }

  .card-modal-body::-webkit-scrollbar-track {
    background-color: #f7f7f7;
  }

  .card-modal-body::-webkit-scrollbar-thumb {
    background-color: #b2b2b2;
  }
  .modal-content {
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    max-width: 800px;
    margin: 0;
    text-align: center;
    .d-flex {
      display: flex;
    }
    .justify-end {
      justify-content: flex-end;
      width: 100%;
      margin: 0px;
    }
    h2,
    p {
      margin: 0px;
      padding: 5px 5px;
    }
    .done {
      margin-top: 20px;
    }
  }

  .card-modal-container-signa {
    padding: 10px 20px;
    margin-top: 15px;
    .text-header {
      margin-top: 10px;
      margin-bottom: 10px;
      h4,
      span {
        margin: 0px;
      }
    }
    .justify-between {
      justify-content: space-between;
    }
    .justify-end {
      justify-content: flex-end;
      width: 100%;
      margin-top: 20px;
    }
    .card-modal-header-signa {
      display: block;
      align-items: start;
      padding: 10px 20px;
      margin: -15px;

      h2,
      p {
        margin: 0px;
        padding: 5px 10px;
      }
    }
    .card-modal-body-signa {
      width: 100%;
      max-height: 300px;

      padding: 15px 20px;
      margin-bottom: 5px;

      border: solid 1px hsl(0deg 0% 93.33%);
      border-radius: 10px;

      background-color: hsl(0deg 0% 93.33%);
    }

    .sigCanvas {
      width: 100% !important;
      height: 300px !important;
    }

    .upload-croped-image-body {
    }

    .card-modal-footer-signa {
      padding: 10px 15px;
      margin-bottom: 15px;
    }

    .line {
      border-top: 1px solid #eaecf0;
      padding: 0;
      margin: 0 -20px;
    }
    .btn-signa {
      margin-top: 15px;
    }
    .text-signa {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .wrp-tab-menu {
      width: 100%;
      margin-bottom: 1rem;
      display: flex;

      .btn-menu-style {
        border: none;
        border-bottom: 2px solid transparent;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        border-bottom: 1px solid rgb(94 96 100 / 28%);
      }

      .menu-active {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: #f9f5ff;
        color: #6941c6;
        border-bottom: 2px solid var(--Primary-600) !important;
      }
    }
  }

  .version-content {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    margin-right: 1rem;

    .version-card {
      border-radius: 8px;
      border: 1px solid var(--Gray-300);
      background: #fcfcfd;
      width: 132px;
      padding: 10px 14px;
    }

    .version-no {
      color: var(--Gray-900);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-right: 8px;
    }

    .latest-badge {
      border-radius: 16px;
      border: 1px solid var(--Primary-200);
      background: var(--Primary-50);
      padding: 2px 6px;

      color: var(--Primary-700);
    }
  }

  .mb-0_5-rem {
    margin-bottom: 0.5rem;
  }

  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .topic {
    color: var(--Gray-600);
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
    .topic-header {
      color: var(--Gray-900);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }
    .ql-editor {
      font-family: 'Inter', 'Noto Sans Thai';

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
    /* p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        margin: 0;
      } */
    .ql-tooltip {
      display: none;
    }
  }

  .checkbox-consent {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    display: flex;
    align-items: center;

    padding: 24px 0;
  }

  .mr-8-px {
    margin-right: 8px;
  }

  .company-name {
    color: var(--Primary-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
`

const Badge = styled.div`
  display: inline-block;
  padding: 4px 12px;

  border-radius: 16px;
  border: 2px solid var(--BADGE-11-Font);
  color: var(--BADGE-11-Font);

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
`

const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-center {
    align-items: center;
  }
  &.align-items-end {
    align-items: flex-end;
  }
`

function ModalPdpaPreview({ isModalOpen, onClose, previewData }) {
  const { t } = useTranslation()
  const companyInfo = getCompanyInfo()
  // window width
  const [widthModalPdpa, setWidthModalPdpa] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      const widthModal = (window.innerWidth * 75) / 100
      setWidthModalPdpa(widthModal)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Div>
      <Modal open={Boolean(isModalOpen)} disableBackdropClick width={`${widthModalPdpa}px`}>
        <Div>
          <div className="card-modal-container">
            <FlexContainer className="justify-content-space-between align-items-center mb-24-px">
              <FlexContainer className="align-items-end">
                <div className="version-content">
                  <div className="mb-0_5-rem">{t('version')}</div>
                  <div className="version-card">
                    <span className="version-no">{previewData?.version}</span>
                    <span className="latest-badge">{t('latest')}</span>
                  </div>
                </div>
                <Badge>{t('preview')}</Badge>
              </FlexContainer>

              <div className="card-modal-header mr-110-px">
                <div className="company-name">
                  {previewData?.doc_lang === 'en' ? companyInfo?.company_name_EN : companyInfo?.company_name_TH}
                </div>
                <h2>{previewData?.name}</h2>
              </div>
              <IoMdClose className="icon" onClick={onClose} />
            </FlexContainer>

            {previewData?.topics?.length > 0 ? (
              <div className="card-modal-body">
                {previewData?.topics?.map((v) => (
                  <div className="topic" key={v?.id}>
                    <div className="topic-header">{v?.title}</div>
                    <ReactQuill value={v?.description} readOnly={true} theme={'bubble'} />
                  </div>
                ))}
              </div>
            ) : (
              <div className="card-modal-body">
                {previewData?.other_topic && (
                  <div className="topic">
                    <ReactQuill value={previewData?.other_topic} readOnly={true} theme={'bubble'} />
                  </div>
                )}
              </div>
            )}

            <div className="card-modal-footer">
              <div className="d-flex justify-center gap">
                <ButtonOutline className="button-w" disabled={true} onClick={onClose}>
                  {t('decline')}
                </ButtonOutline>
                <Button className="button-w" disabled={true}>
                  {t('acknowledge_text')}
                </Button>
              </div>
            </div>
          </div>
        </Div>
      </Modal>
    </Div>
  )
}

export default ModalPdpaPreview
