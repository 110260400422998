export const FIELD_TYPE = {
  string: 'string',
  text: 'text',
  richtext: 'richtext',
  email: 'email',
  uid: 'uid',
  integer: 'integer',
  biginteger: 'biginteger',
  decimal: 'decimal',
  boolean: 'boolean',
  enumeration: 'enumeration',
  relation: 'relation',
  media: 'media',
  datetime: 'datetime',
  date: 'date',
  time: 'time',
}

export const FILTER_CONDIION = {
  $eq: '$eq',
  $ne: '$ne',
  $lte: '$lte',
  $gte: '$gte',
  $contains: '$contains',
  $notContains: '$notContains',
}

export const MAP_FILTER_CONDIION_WORD = {
  $eq: 'is',
  $ne: 'is not',
  $lte: 'is lower than or equal to',
  $gte: 'is greater than  or equal to',
  $contains: 'is contains',
  $notContains: 'is does not contains',
}
