import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Loading from '../../../../components/common/Loading'
import DocumentSettingPreview from '../../../../components/feature/document-setting/DocumentSettingPreview'
import ModalBox from '../../../../components/widget/element/ModalBox'
import IconXCircle from '../../../../components/widget/icon/IconXCircle'
import MediaFile from '../../../../containers/cms/MediaFile'
export default function LogoRubberStamp({ docType, settingData, onDataChange }) {
  const allowTypes = ['images']

  const [imageForDocument, setImageForDocument] = useState({
    url_company_logo: '',
    url_rubber_stamp: '',
    url_signature_file: '',
  })

  const [isLoading, setIsLoading] = useState(false)
  const [documentSettingData, setDocumentSettingData] = useState()

  useEffect(() => {
    if (settingData) {
      let tmpImageForDocumentObj = { ...imageForDocument }
      if (docType != '') {
        tmpImageForDocumentObj.url_company_logo = settingData[docType]?.company_logo
        tmpImageForDocumentObj.url_rubber_stamp = settingData[docType]?.rubber_stamp
        // tmpImageForDocumentObj.url_signature_file = settingData[docType]?.signature
      }
      setImageForDocument(tmpImageForDocumentObj)
      setDocumentSettingData(settingData)
    }
  }, [settingData])

  const onChangeUploadFile = (value, imageType) => {
    setIsLoading(true)

    if (value) {
      let tmpData = { ...imageForDocument }
      let url = value[0].url
      let tmpDocumentSettingData = { ...settingData }

      if (imageType == 'logo') {
        tmpData.url_company_logo = url
        tmpDocumentSettingData[docType].company_logo = url
      } else if (imageType == 'rubber') {
        tmpData.url_rubber_stamp = url
        tmpDocumentSettingData[docType].rubber_stamp = url
      }

      if (onDataChange) {
        setImageForDocument(tmpData)
        onDataChange(tmpDocumentSettingData)
      }
    }

    setTimeout(function () {
      setIsLoading(false)
    }, 2000)
  }
  const handleDeleteImage = (imageTypeDelete) => {
    if (imageTypeDelete == 'company_logo') {
      setImageForDocument((prev) => ({ ...prev, url_company_logo: '' }))
    } else if (imageTypeDelete == 'rubber_stamp') {
      setImageForDocument((prev) => ({ ...prev, url_rubber_stamp: '' }))
    }
    // else if (imageTypeDelete == 'signature') {
    //   setImageForDocument((prev) => ({ ...prev, url_signature_file: '' }))
    // }
  }

  return (
    <Styled className="logo-rubber-stamp">
      {isLoading && (
        <ModalBox>
          <div className="wrp-loading">
            <Loading />
            <p>กรุณารอสักครู่ ระบบกำลังดำเนินการ ...</p>
          </div>
        </ModalBox>
      )}

      <div className="logo-rubber-stamp-top">
        <div className="logo-rubber-stamp-content-left">
          <div className="upload-file">
            <div className="wrp-title-btn-delete-image">
              <div className="title-upload-file">Company Logo</div>
              {imageForDocument.url_company_logo != '' && (
                <div className="btn-delete-image" onClick={() => handleDeleteImage('company_logo')}>
                  <IconXCircle width="16" />
                </div>
              )}
            </div>
            <div className="box-upload-file">
              {imageForDocument.url_company_logo == '' && (
                <MediaFile
                  className={'upload-media-file'}
                  multiple={false}
                  allowedTypes={allowTypes}
                  onChange={(e) => onChangeUploadFile(e, 'logo')}
                  inputForm="file_upload"
                />
              )}
              {imageForDocument.url_company_logo != '' && (
                <div className="wrp-image-logo">
                  <img className="image-logo" src={imageForDocument.url_company_logo} />
                </div>
              )}
            </div>
            <div className="note-upload-file">*ขนาดภาพที่แนะนำ 1400*300px และขนาดไม่เกิน 3mb</div>
          </div>
          <div className="upload-file">
            <div className="wrp-title-btn-delete-image">
              <div className="title-upload-file">Rubber Stamp</div>
              {imageForDocument.url_rubber_stamp != '' && (
                <div className="btn-delete-image" onClick={() => handleDeleteImage('rubber_stamp')}>
                  <IconXCircle width="16" />
                </div>
              )}
            </div>
            <div className="box-upload-file">
              {imageForDocument.url_rubber_stamp == '' && (
                <MediaFile
                  multiple={false}
                  allowedTypes={allowTypes}
                  onChange={(e) => onChangeUploadFile(e, 'rubber')}
                  inputForm="file_upload"
                />
              )}
              {imageForDocument.url_rubber_stamp != '' && (
                <div className="wrp-image-rubber">
                  <img className="image-rubber" src={imageForDocument?.url_rubber_stamp} />
                </div>
              )}
            </div>
            <div className="note-upload-file">*ขนาดภาพที่แนะนำ 500*300px และขนาดไม่เกิน 3mb</div>
          </div>
          {/* <div className="upload-file">
            <div className="wrp-title-btn-delete-image">
              <div className="title-upload-file">Signature</div>
              {imageForDocument.url_signature_file != '' && (
                <div className="btn-delete-image" onClick={() => handleDeleteImage('signature')}>
                  <IconXCircle width="16" />
                </div>
              )}
            </div>

            <div className="box-upload-file">
              {imageForDocument.url_signature_file == '' && (
                <MediaFile
                  multiple={false}
                  allowedTypes={allowTypes}
                  onChange={(e) => onChangeUploadFile(e, 'signature')}
                  inputForm="file_upload"
                />
              )}
              {imageForDocument.url_signature_file != '' && (
                <div className="wrp-image-signature">
                  <img className="image-signature" src={imageForDocument?.url_signature_file} />
                </div>
              )}
            </div>
            <div className="note-upload-file">*ขนาดภาพที่แนะนำ 400*150px และขนาดไม่เกิน 3mb</div>
          </div> */}
        </div>

        <div className="logo-rubber-stamp-content-right">
          <DocumentSettingPreview
            docType={docType}
            settingData={settingData}
            imageUrlObj={imageForDocument}
            condition={documentSettingData?.purchase_condition}
          />
        </div>
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  .wrp-image-rubber {
    /* width: 50px;
    height: 50px; */
  }

  width: 100%;
  display: flex;
  flex-direction: column;

  .logo-rubber-stamp-top {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .logo-rubber-stamp-content-left {
    .upload-file {
      margin-bottom: 24px;

      .wrp-title-btn-delete-image {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 345px;

        .title-upload-file {
          font-size: 16px;
          font-weight: 500;
          color: var(--Gray-700);
          margin-bottom: 16px;
        }
        .btn-delete-image {
          cursor: pointer;
        }
      }

      .box-upload-file {
        width: 345px;
        height: 261px;
        background-color: var(--Gray-50);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .upload-zone {
        width: 345px !important;
        height: 261px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .image-logo,
      .image-rubber,
      .image-signature {
        max-width: 345px;
        max-height: 261px;
      }

      .note-upload-file {
      }
    }
  }
  .logo-rubber-stamp-content-right {
    display: flex;
  }

  .wrp-loading {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const Div = styled.div``
