import { Stack } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { FiCalendar } from 'react-icons/fi'

import { TASK } from 'constants/operation-process'
import { Chip } from '.'
import AssignTo from '../assign-to'

export default function Task({ data: task, due_date, dateFormat = 'DD MMM YYYY', titleOnly }) {
  const { t } = useTranslation()
  const { employee } = task
  const { first_name, last_name } = employee || {}

  return (
    <>
      <Stack spacing={1}>
        <p className="title">{task?.name}</p>

        {!titleOnly && (
          <>
            <Stack direction="row" gap="0.5rem">
              <FiCalendar size={16} />
              <p>{`${t('due_date')}: ${
                due_date && dayjs(due_date).isValid() && dayjs(due_date).format(dateFormat)
              }`}</p>
            </Stack>

            <AssignTo name={first_name && last_name ? `${first_name} ${last_name}` : '-'} />
          </>
        )}
      </Stack>

      {!titleOnly && task?.status && <Chip label={task.status} color={TASK.STATUS_CHIP[task.status]} />}
    </>
  )
}
