import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { DateTimeHHmmssDisplay,GetPermissionOther,GetPermissionTabList } from '../../../utils/common'

import { selectSharedService, selectDocumentTemplate } from '../../../redux/selectors'
import {
  getDocumentAcknowledge,
  deleteDocumentAcknowledge,
  setDocumentAcknowledgementInfo,
  duplicateDocumentAcknowledge,
} from '../../../redux/actions/sharedService'
import { getDocumentCategory, getDocumentTemplate } from '../../../redux/actions/documentTemplate'

import styled from 'styled-components'
import Button from '../../../components/form/button/Button'
import SearchText from '../../../components/form/SearchText'
import Table from '../../../components/common/Table'
import DialogConfirmDelete from '../../../components/dialog/DialogConfirmDelete'
import Dropdown from '../../../components/form/Dropdown'
import Modal from '../../../components/common/Modal'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import DialogSuccess from '../../../components/dialog/DialogSuccess'

import { TbPointFilled } from 'react-icons/tb'
import { FiTrash2, FiCopy } from 'react-icons/fi'
import { RiSearchLine } from 'react-icons/ri'
import { LiaTimesSolid } from 'react-icons/lia'
import successIcon from '../../../assets/images/success-icon.png'

import failIcon from '../../../assets/images/fail-icon.png'
import DialogFail from '../../../components/dialog/DialogFail'

const Div = styled.div`
  // shared margin,padding
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  // shared-component
  .icon {
    cursor: pointer;
  }
  .badge {
    color: var(--Primary-700);
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;

    border-radius: 1rem;
    border: 0.0625rem solid var(--Primary-200);
    background: var(--Primary-50);

    padding: 0.1875rem;

    &.pink {
      border: 0.0625rem solid var(--BADGE-12-Border);
      background: var(--BADGE-12-Bg);
      color: var(--BADGE-12-Font);
    }
    &.orange {
      border: 0.0625rem solid var(--BADGE-13-Border);
      background: var(--BADGE-13-Bg);
      color: var(--BADGE-13-Font);
    }
    &.green {
      border: 0.0625rem solid var(--BADGE-14-Border);
      background: var(--BADGE-14-Bg);
      color: var(--BADGE-14-Font);
    }
    &.purple {
      border: 0.0625rem solid var(--BADGE-15-Border);
      background: var(--BADGE-15-Bg);
      color: var(--BADGE-15-Font);
    }
    &.in-use {
      border: 0.0625rem solid var(--BADGE-16-Border);
      background: var(--BADGE-16-Bg);
      color: var(--BADGE-16-Font);
    }
  }
  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
  .status-badge {
    display: flex;
    align-items: center;
    border-radius: 0.375rem;
    border: 1px solid var(--Gray-300);
    background: var(--Base-White);

    color: var(--Gray-700);
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;

    padding: 0 0.375rem;

    .active-color {
      color: var(--Status-Active);
    }
    .inactive-color {
      color: var(--Status-Inactive);
    }
    .draft-color {
      color: var(--Status-Draft);
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background: var(--Gray-200);
    margin-bottom: 2em;
  }

  // typography
  .feature-header {
    color: var(--Gray-900);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
  }
  .feature-description {
    color: var(--Gray-600);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 1.25em;
  }
  .document-name {
    color: var(--Primary-700);
    font-style: normal;
    font-weight: 500;
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  // layout
  .tabmenu-container {
    display: flex;
    margin-bottom: 1.5rem;
    .tab-menu {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      color: var(--Gray-500);
      padding: 0.75rem;
      :hover {
        cursor: pointer;
      }
      &.active {
        color: var(--Primary-700);
        border-bottom: 2px solid var(--Primary-700);
        background: var(--Primary-50);
      }
    }
  }
  .table-wrapper {
    .table-header {
      padding: 1rem 0.875rem;
      border-radius: 0.5rem 0.5rem 0 0;
      background: var(--Base-White);
      border: 0.0625rem solid var(--Gray-200);

      .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.25rem;

        .table-header-name {
          color: var(--Gray-900);
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          margin-right: 0.5rem;
        }
      }

      .search-container {
        display: flex;
        .clear-button-container {
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }
  .modal-content {
    width: 800px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--Gray-600);

    .category-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      height: 500px;

      .category {
        padding: 1rem 2rem;
        border-right: 1px solid var(--Gray-200);
        overflow: auto;
        .category-item {
          display: flex;
          align-items: center;
          padding: 8px;
          border-radius: 8px;
          background: var(--Base-White);
          align-items: center;
          cursor: pointer;
          color: var(--Gray-900);
          font-weight: 500;

          &.active {
            background: var(--Primary-600);
            color: var(--Base-White);
          }
        }
      }

      .category-view {
        padding: 1rem 2rem;
        overflow: auto;
        .view-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;

          color: var(--Gray-900);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;

          .view-link {
            cursor: pointer;
            color: var(--Primary-700);
            font-weight: 600;
          }
        }
      }
    }
  }
  .input-container {
    margin-right: 0.75rem;
    &.width-20 {
      width: 20%;
    }
    .input-header {
      color: var(--Gray-700);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 0.375rem;
    }
  }
  .action-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .action-item {
      margin-right: 0.75rem;
      color: var(--Gray-600);
      cursor: pointer;

      :hover {
        color: var(--Primary-700);
      }
    }
  }
  .search-content {
    padding: 10px;
    border-bottom: 1px solid var(--Gray-200);

    .search-input {
      position: relative;
      color: var(--Gray-500);

      .input-field {
        box-sizing: border-box;
        padding: 10px;
        padding-left: 40px;
        border: none;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }

      .start-icon {
        position: absolute;
        top: 10px;
        left: 10px;
      }

      .end-icon {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
`

const initStatudDropdown = [
  {
    text: 'Active',
    value: 'Active',
  },
  {
    text: 'Inactive',
    value: 'Inactive',
  },
  {
    text: 'Draft',
    value: 'Draft',
  },
]

const initMessageModal = {
  headline: '',
  message: '',
}

function DocumentManagement() {
  const { t } = useTranslation()
  const initLang = localStorage.getItem('lang')
  //--- ST ส่วนของ Permission LV3-4
  const permissionNewDocument = GetPermissionOther('Document Management', 'New Document')
  const permissionDeleteDocument = GetPermissionOther('Document Management', 'Delete Document')
  const permissionViewDocument = GetPermissionOther('Document Management', 'View Document')
  /*
  const { permission } = JSON.parse(localStorage.getItem('admin'))
  const [permissionNewDocument, setpermissionNewDocument] = useState(null);
  let permissionDeleteDocument = null;
  let permissionViewDocument = null;
  useEffect(() => {
    const this_sub_menu_permission = permission['Human Resources & Organization'].filter((it_2) => it_2.path === '/organization-management/acknowledgement-system');
    const tab_permission = this_sub_menu_permission[0].permission_tab?.filter((it_2) => it_2.menu_setting.rout_path === 'AcknowledgementSystem_DocumentManagement')
    if(tab_permission.length > 0){
      const this_permissionNewDocument = tab_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'AcknowledgementSystem_DocumentManagement_NewDocument');
      if(this_permissionNewDocument){
        setpermissionNewDocument(this_permissionNewDocument)
      }
      const this_permissionDeleteDocument = tab_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'AcknowledgementSystem_DocumentManagement_DeleteDocument');
      if(this_permissionDeleteDocument){
        //setpermissionDeleteDocument(this_permissionDeleteDocument)
        permissionDeleteDocument = this_permissionDeleteDocument;
      }
      const this_permissionViewDocument = tab_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'AcknowledgementSystem_DocumentManagement_ViewDocument');
      if(this_permissionViewDocument){
        //setpermissionViewDocument(this_permissionViewDocument)
        permissionViewDocument = this_permissionViewDocument;
      }
    }
  }, [])
  */
  //--- ED ส่วนของ Permission LV3-4
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const documentColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('document_name'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('latest_version'),
      accessor: 'version',
      disableSortBy: false,
    },
    {
      Header: t('category'),
      accessor: 'category',
      disableSortBy: false,
    },
    {
      Header: t('type'),
      accessor: 'type',
      disableSortBy: false,
    },
    {
      Header: t('language'),
      accessor: 'doc_lang',
      disableSortBy: false,
    },
    {
      Header: t('topics'),
      accessor: 'topics',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('create_update_by'),
      accessor: 'created_by',
      disableSortBy: false,
    },
    {
      Header: t('last_modify'),
      accessor: 'updatedAt',
      disableSortBy: false,
    },
    {
      Header: t('publish_date'),
      accessor: 'publish_date',
      disableSortBy: false,
    },
    {
      accessor: 'action',
      disableSortBy: false,
    },
  ]

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { documentAcknowledgements, page, pageCount, total, limit, isLoading, state } = useSelector(selectSharedService)
  const { documentTemplates, categories } = useSelector(selectDocumentTemplate)
  const [documentList, setDocumentList] = useState([])
  const [tabCategory, setTabCategory] = useState(0)
  const [documentId, setDocumentId] = useState('')
  // filter
  const [search, setSearch] = useState('')
  const [categorySelected, setCategorySelected] = useState('')
  const [statusSelected, setStatusSelected] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  //filter search templates
  const [searchTemplate, setSearchTemplate] = useState('')
  const [searchTemplateFilters, setSearchTemplateFilters] = useState([])
  // dropdown list
  const [categoryList, setCategoryList] = useState([])
  // modal
  const [precreateDocumentModal, setPrecreateDocumentModal] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  // modal message
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)

  const fetchData = useCallback(
    (filter) => {
      dispatch(getDocumentAcknowledge(filter))
    },
    [dispatch]
  )
  const fetchDocumentTemplates = useCallback(
    (category, multipleSearch = []) => {
      const filter = {
        page: 1,
        pageSize: 100,
        name: searchTemplate,
        multipleSearch,
      }
      if (category !== 0) filter.category = category
      dispatch(getDocumentTemplate(filter))
    },
    [dispatch, searchTemplate]
  )
  const deleteDocument = useCallback((data) => {


    if(permissionDeleteDocument?.canUpdate === true){
      if (data.status === 'Draft') {
        setDocumentId(data?.id)
        setConfirmDeleteModal(true)
      }
    }else{
      let message_permission = 'No permission allowed ' + permissionDeleteDocument?.name_en;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionDeleteDocument?.name_th;
      }
      setTitleFail(message_permission);
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }
  }, [])
  const viewDocument = useCallback((data) => {
    if(permissionViewDocument?.canUpdate === true){
      setDocumentId(data?.id)
      if (data.status === 'Draft') navigate(`edit-document/${data?.id}`)
      else navigate(`view-document/${data?.id}`)
    }else{
      let message_permission = 'No permission allowed ' + permissionViewDocument?.name_en;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionViewDocument?.name_th;
      }
      setTitleFail(message_permission);
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }
  }, [])
  const duplidateTemplate = useCallback((id) => {
    setDocumentId(id)
    dispatch(duplicateDocumentAcknowledge(id))
  }, [])

  useEffect(() => {
    dispatch(getDocumentAcknowledge())
    dispatch(getDocumentCategory())
    dispatch(setDocumentAcknowledgementInfo({}))
  }, [])

  useEffect(() => {
    if (isLoading === false && state === 'DELETE_DOCUMENT_ACKNOWLEDGE.SUCCESS') {
      setSuccessMessageModal({
        headline: 'Deleting successful',
        message: '',
      })
      setSuccessModal(true)
    }
    if (state === 'DUPLICATE_DOCUMENT_ACKNOWLEDGE.SUCCESS' && isLoading === false) {
      setSuccessMessageModal({
        headline: t('duplicated_success_text'),
        message: '',
      })
      setSuccessModal(true)
    }
  }, [isLoading, state])

  useEffect(() => {
    const multipleSearch = searchTemplateFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    fetchDocumentTemplates(tabCategory, multipleSearch)
  }, [tabCategory])

  useEffect(() => {
    const newDocumentList = documentAcknowledgements.map((v, i) => {
      const topicsText = v.topics.map((v) => v.title).join(',')
      const statusStyle = v.status === 'Inactive' ? 'inactive-color' : 'draft-color'
      return {
        ...v,
        no: i + 1,
        version: v.version.toFixed(1),
        doc_lang: v.doc_lang === 'en' ? 'English' : 'Thai',
        name: (
          <div
            className="document-name"
            onClick={() => viewDocument(v)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                viewDocument(v)
              }
            }}
          >
            {v.name}
          </div>
        ),
        category: (
          <div
            // className={`badge ${
            //   v?.category?.name === 'Employee Handbook'
            //     ? 'pink'
            //     : v?.category?.name === 'Newsletters'
            //     ? 'orange'
            //     : v?.category?.name === 'Employment Contract'
            //     ? 'green'
            //     : 'purple'
            // }`}
            className={`badge pink`}
          >
            {v?.category?.name}
          </div>
        ),
        topics: <div>{topicsText.length > 30 ? `${topicsText.slice(0, 30)}...` : topicsText}</div>,
        status: (
          <div className="status-badge">
            <TbPointFilled className={`${v.status === 'Active' ? 'active-color' : statusStyle}`} size={20} />
            <span>{v.status}</span>
          </div>
        ),
        updatedAt: DateTimeHHmmssDisplay(v.updatedAt),
        publish_date: DateTimeHHmmssDisplay(v.publish_date),
        created_by: `${v.creator.first_name} ${v.creator.last_name}`,
        action: (
          <div className="action-container">
            <FiCopy className="action-item" size={18} onClick={() => duplidateTemplate(v.id)} />
            <FiTrash2 className="action-item" size={18} onClick={() => deleteDocument(v)} />
          </div>
        ),
      }
    })
    setDocumentList(newDocumentList)
  }, [documentAcknowledgements, duplidateTemplate, deleteDocument])

  useEffect(() => {
    if (categorySelected !== '' || statusSelected !== '') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))
      const filter = {
        page: 1,
        pageSize: limit,
        multipleSearch,
        category: categorySelected,
        status: statusSelected,
      }
      fetchData(filter)
    }
  }, [searchFilters, categorySelected, statusSelected])

  useEffect(() => {
    const newCategoryList = categories.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setCategoryList(newCategoryList)
  }, [categories])

  // submit
  const confirmDelete = () => {
    dispatch(deleteDocumentAcknowledge(documentId))
    setConfirmDeleteModal(false)
  }

  // filter function
  const onChangePagination = async (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    const filter = {
      page: value.page,
      pageSize: limit,
      multipleSearch,
      category: categorySelected,
      status: statusSelected,
    }
    fetchData(filter)
  }

  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))
      const filter = {
        page: 1,
        pageSize: limit,
        multipleSearch,
        category: categorySelected,
        status: statusSelected,
      }
      fetchData(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }

  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    const filter = {
      page: 1,
      pageSize: limit,
      multipleSearch,
      category: categorySelected,
      status: statusSelected,
    }
    fetchData(filter)
    setSearch('')
  }

  const onClearSearch = () => {
    const filter = {
      page: 1,
      pageSize: limit,
      multipleSearch: [],
      category: '',
      status: '',
    }
    fetchData(filter)
    clearData()
  }

  const clearData = () => {
    setSearch('')
    setCategorySelected('')
    setStatusSelected('')
    setSearchFilters([])
  }

  const filterTemplate = (event) => {
    event.preventDefault()
    const newFilter = {
      key: uuidv4(),
      name: searchTemplate,
    }
    const multipleSearch = [...searchTemplateFilters, newFilter].map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    fetchDocumentTemplates(tabCategory, multipleSearch)
    setSearchTemplate('')
    setSearchTemplateFilters((prev) => [...prev, newFilter])
  }

  const deleteSearchTemplateBadge = (key) => {
    const newSearchFilters = searchTemplateFilters.filter((v) => v.key != key)
    setSearchTemplateFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    fetchDocumentTemplates(tabCategory, multipleSearch)
    setSearchTemplate('')
  }

  const clearDataSearchTemplate = () => {
    setTabCategory(0)
    setSearchTemplate('')
    setSearchTemplateFilters([])
  }

  // modal
  const openPrecreateDocumentModal = () => {
    
    if(permissionNewDocument?.canUpdate === true){
      setPrecreateDocumentModal(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionNewDocument?.name_en;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionNewDocument?.name_th;
      }
      setTitleFail(message_permission);
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }
  }

  const closePrecreateDocumentModal = () => {
    clearDataSearchTemplate()
    setPrecreateDocumentModal(false)
  }

  const toggleTabCategoryRequest = (category) => {
    setTabCategory(category)
  }

  const gotoCreateDocument = (id) => {
    navigate(`/organization-management/acknowledgement-system/create-document/${id}`)
  }

  return (
    <Div>
      <div className="table-wrapper">
        <div className="table-header">
          <div className="header-container">
            <div>
              <span className="table-header-name">{t('document_list')}</span>
              <span className="badge">
                {total} {t('document')}
              </span>
            </div>
            {permissionNewDocument?.canRead === true && (
              <Button onClick={openPrecreateDocumentModal}>{t('new_document')}</Button>
            )}
          </div>
          <div className="search-container mb-1-rem">
            <div className="input-container">
              <div className="input-header">{t('search')}</div>
              <SearchText
                placeholder={t('search')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSubmit={onSearch}
                onClear={onClearSearch}
              />
            </div>
            <div className="input-container width-20">
              <div className="input-header">{t('category')}</div>
              <Dropdown
                optionList={categoryList}
                value={categorySelected}
                onChange={(value) => setCategorySelected(value)}
              />
            </div>
            <div className="input-container width-20">
              <div className="input-header">{t('status')}</div>
              <Dropdown
                optionList={initStatudDropdown}
                value={statusSelected}
                onChange={(value) => setStatusSelected(value)}
              />
            </div>
            <div className="clear-button-container">
              <ButtonOutline onClick={onClearSearch}>{t('clear')}</ButtonOutline>
            </div>
          </div>
          <div className="badge-search-content">
            {searchFilters.map((v) => (
              <div className="badge" key={v.key}>
                <span className="name">{v.name}</span>{' '}
                <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
              </div>
            ))}
          </div>
        </div>
        <Table
          data={documentList}
          columns={documentColumn}
          onStateChange={onChangePagination}
          page={page}
          pageCount={pageCount}
        />
      </div>

      {/* Modal */}
      <Modal open={Boolean(precreateDocumentModal)} onClose={closePrecreateDocumentModal} disableBackdropClick>
        <Div>
          <div className="search-content">
            <div className="search-input">
              <RiSearchLine className="start-icon" />
              <form onSubmit={filterTemplate}>
                <input
                  className="input-field"
                  placeholder={t('search_application_form')}
                  value={searchTemplate}
                  onChange={(e) => setSearchTemplate(e.target.value)}
                />
              </form>

              <LiaTimesSolid size={25} onClick={closePrecreateDocumentModal} className="end-icon icon" />
            </div>
            <div className="badge-search-content">
              {searchTemplateFilters.map((v) => (
                <div className="badge" key={v.key}>
                  <span className="name">{v.name}</span>{' '}
                  <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchTemplateBadge(v.key)} />
                </div>
              ))}
            </div>
          </div>
          <div className="modal-content">
            <div className="category-content">
              <div className="category">
                <div className="mb-1-rem">{t('category')}</div>
                <div
                  className={`category-item ${tabCategory == 0 ? 'active' : ''}`}
                  onClick={() => toggleTabCategoryRequest(0)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      toggleTabCategoryRequest(0)
                    }
                  }}
                >
                  <span>{t('all')}</span>
                </div>
                {categories.map((v) => (
                  <div
                    key={v.id}
                    className={`category-item ${tabCategory == v.id ? 'active' : ''}`}
                    onClick={() => toggleTabCategoryRequest(v.id)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        toggleTabCategoryRequest(v.id)
                      }
                    }}
                  >
                    <span>{v.name}</span>
                  </div>
                ))}
              </div>
              <div className="category-view">
                {documentTemplates.map((v) => (
                  <div className="view-item" key={v.id}>
                    <div>
                      <span>{v.name} </span>
                    </div>
                    <span
                      className="view-link"
                      onClick={() => gotoCreateDocument(v.id)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          gotoCreateDocument(v.id)
                        }
                      }}
                    >
                      {t('view')}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Div>
      </Modal>
      {/* Dialog */}
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={confirmDelete}
        title={t('confrim_delete')}
        description={t('do_you_want_to_confirm_the_deletion_of_the_document')}
      />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => setSuccessModal(false)}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default DocumentManagement
