import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { createDocumentTask, updateDocumentTask } from '../../../../redux/actions/summaryTask'
import { selectSummaryTask } from '../../../../redux/selectors'
import { getAdminUser } from '../../../../utils/common'
import { dlog } from '../../../../utils/helper'
import Dropdown from '../../../form/Dropdown'
import TextField from '../../../form/TextField'
import Button from '../../../form/button/Button'
import ButtonOutline from '../../../form/button/ButtonOutline'
import IconArrowLeft from '../../../widget/icon/IconArrowLeft'
import IconChevronLeft from '../../../widget/icon/IconChevronLeft'
import IconXClose from '../../../widget/icon/IconXClose'
import ViewDoc from '../../approvals/ViewDoc'
import ProcurementTemplate from './template/ProcurementTemplate'
import PurchaseOrderTemplate from './template/PurchaseOrderTemplate'

import { useTranslation } from 'react-i18next'

export default function TaskDocumentCreationView({ data, onClickNavigationBack, isEdit, onDataChange, isRoutine }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { provinceListStore, amphureListStore, tambonListStore } = useSelector(selectSummaryTask)
  const [isShowDocumentTemplate, setIsShowDocumentTemplate] = useState(false)
  const [templateSelected, setTemplateSelected] = useState('')
  const [isShowDetail, setIsShowDetail] = useState(false)

  const [isShowPreview, setIsShowPreview] = useState(false)
  const [documentName, setDocumentName] = useState('')
  const [isErrorDocumentName, setIsErrorDocumentName] = useState(false)
  const [taskData, setTaskData] = useState('')
  const [docTempId, setDocTempId] = useState('')
  const [templateInfoData, setTemplateInfoData] = useState({})
  const [invalidTemplate, setInvalidTemplate] = useState(false)
  const [toggleUpdateDoc, setToggleUpdateDoc] = useState(false)
  const [newDataPreview, setNewDataPreview] = useState()
  const templateList = [
    // {
    //   text: 'Purchase Order',
    //   value: 'purchase_order',
    // },
    {
      text: 'Procurement',
      value: 'Procurement',
    },
  ]

  useEffect(() => {
    if (data && JSON.stringify(data) != '{}') {
      setTaskData(isEdit ? data.attributes : data)
      if (isEdit) {
        setDocTempId(data.id)
      }
    }
  }, [data])

  // const handleClickBtnEye = () => {
  //   dlog('ClickEye')
  //   // setIsShowDocumentTemplate(!isShowDocumentTemplate)
  // }
  const handleClickBtnIconBack = () => {
    setIsShowDocumentTemplate(!isShowDocumentTemplate)
  }
  const handleClickNavigationBack = () => {
    if (onClickNavigationBack) {
      onClickNavigationBack()
    }
  }
  const handleClickBtnCreate = () => {
    let isValidDoc = checkValidateDocName(documentName)
    let isValidTemplateData = checkValidateTemplateData()
    if (isValidDoc && isValidTemplateData) {
      let tmpObj = { ...templateInfoData }
      if (isRoutine) {
        tmpObj.task = taskData.id
      } else {
        tmpObj.milestone_task_type = taskData.id
      }

      tmpObj.name = documentName
      tmpObj.template = templateSelected
      tmpObj.owner = employee_info?.id // user?.id

      delete tmpObj['company']

      const data = { ...tmpObj }
      const request = { data }
      dispatch(createDocumentTask(request)).then(() => {
        if (onDataChange) {
          onDataChange()
          if (onClickNavigationBack) {
            onClickNavigationBack()
          }
        }
      })
    } else {
      if (!isValidTemplateData) {
        setInvalidTemplate(true)
      }
      alert('Please Check Your Information')
    }
  }

  const handleClickBtnSave = () => {
    if (templateInfoData && Object.keys(templateInfoData).length > 0) {
      let isValidTemplateData = checkValidateTemplateData()
      if (isValidTemplateData) {
        let tmpTemp = {
          name: templateInfoData.name,
          template: templateInfoData.template,
          purchase_from: templateInfoData.purchase_from,
          address: templateInfoData.address,
          reference_number: templateInfoData.reference_number,
          contact_person: templateInfoData.contact_person,
          email: templateInfoData.email,
          phone_no: templateInfoData.phone_no,
          purchase_date: templateInfoData.purchase_date,
          thai_province: templateInfoData.thai_province,
          thai_amphure: templateInfoData.thai_amphure,
          thai_tambon: templateInfoData.thai_tambon,
        }
        if (taskData.task?.data?.id) {
          tmpTemp.task = taskData.task?.data?.id
        }
        const data = { ...tmpTemp }
        const request = { data }
        dispatch(updateDocumentTask(docTempId, request, true)).then(() => {
          if (onDataChange) {
            onDataChange()
            if (onClickNavigationBack) {
              onClickNavigationBack()
            }
          }
        })
      } else {
        if (!isValidTemplateData) {
          setInvalidTemplate(true)
        }
        alert('Please Check Your Information')
      }
    } else {
      if (onClickNavigationBack) {
        onClickNavigationBack()
      }
    }
  }

  const checkValidateDocName = (docName) => {
    let isValidDocumentName = false
    if (docName != '') {
      isValidDocumentName = true
      setIsErrorDocumentName(false)
    } else {
      isValidDocumentName = false
      setIsErrorDocumentName(true)
    }
    if (isValidDocumentName) {
      return true
    }
    return false
  }
  const checkValidateTemplateData = () => {
    let tmpObj = { ...templateInfoData }

    if (tmpObj.purchase_from == undefined) {
      return false
    }

    let isValidPurchaseFrom = false
    let isValidAddress = false
    let isValidReferenceNumber = false
    let isValidContactPerson = false
    let isValidEmail = false
    let isValidPhoneNo = false
    let isValidPurchaseDate = false
    let isValidThaiProvince = false
    let isValidThaiAmphure = false
    let isValidThaiTambon = false
    let isValidPostCode = false
    // "purchase_from": "",
    // "address": "",
    // "reference_number": "",
    // "contact_person": "",
    // "email": "",
    // "phone_no": "",
    // "purchase_date": "",
    // "thai_province": "",
    // "thai_amphure": "",
    // "thai_tambon": "",

    if (tmpObj.purchase_from != '') {
      isValidPurchaseFrom = true
    } else {
      isValidPurchaseFrom = false
    }

    if (tmpObj.address != '') {
      isValidAddress = true
    } else {
      isValidAddress = false
    }

    if (tmpObj.reference_number != '') {
      isValidReferenceNumber = true
    } else {
      isValidReferenceNumber = false
    }

    if (tmpObj.contact_person != '') {
      isValidContactPerson = true
    } else {
      isValidContactPerson = false
    }

    if (tmpObj.email != '') {
      isValidEmail = true
    } else {
      isValidEmail = false
    }

    if (tmpObj.phone_no != '') {
      isValidPhoneNo = true
    } else {
      isValidPhoneNo = false
    }

    if (tmpObj.purchase_date != '') {
      isValidPurchaseDate = true
    } else {
      isValidPurchaseDate = false
    }

    if (tmpObj.thai_province != '') {
      isValidThaiProvince = true
    } else {
      isValidThaiProvince = false
    }

    if (tmpObj.thai_amphure != '') {
      isValidThaiAmphure = true
    } else {
      isValidThaiAmphure = false
    }

    if (tmpObj.thai_tambon != '') {
      isValidThaiTambon = true
    } else {
      isValidThaiTambon = false
    }

    if (tmpObj.post_code != '') {
      isValidPostCode = true
    } else {
      isValidPostCode = false
    }

    if (
      isValidPurchaseFrom &&
      isValidAddress &&
      isValidReferenceNumber &&
      isValidContactPerson &&
      isValidEmail &&
      isValidPhoneNo &&
      isValidPurchaseDate &&
      isValidThaiProvince &&
      isValidThaiAmphure &&
      isValidThaiTambon &&
      isValidPostCode
    ) {
      return true
    }
    return false
  }
  const handleDataChange = (e) => {
    let tmpObj = { ...e }
    setTemplateInfoData(tmpObj)
  }
  const handleClickPreview = () => {
    let tmp = {
      ...templateInfoData,
    }
    tmp.name = documentName
    tmp.template = templateSelected
    if (tmp.thai_province) {
      const findProvince = provinceListStore.find((e) => e.id == tmp.thai_province)
      tmp.thai_province = findProvince.attributes.name_th
    }
    if (tmp.thai_amphure) {
      const findAmphure = amphureListStore.find((e) => e.id == tmp.thai_amphure)
      tmp.thai_amphure = findAmphure.attributes.name_th
    }
    if (tmp.thai_tambon) {
      const findAmphure = tambonListStore.find((e) => e.id == tmp.thai_tambon)
      tmp.thai_tambon = findAmphure.attributes.name_th
    }

    setNewDataPreview(tmp)
    setToggleUpdateDoc(!toggleUpdateDoc)
    setIsShowPreview(true)
  }

  return (
    <Styled>
      {!isShowDocumentTemplate && (
        <div className="wrp-add-new-document">
          <div className="wrp-top">
            <div className="wrp-navigation">
              <div className="navigation-left">
                <div className="icon-chevron-left" onClick={() => handleClickNavigationBack()}>
                  <IconChevronLeft width="10px" />
                </div>
                <div className="txt-navigation-left">{isEdit ? t('Edit') : t('new_document')}</div>
              </div>
              <div className="navigation-right">
                <div className="icon-x-close" onClick={() => handleClickNavigationBack()}>
                  <IconXClose />
                </div>
              </div>
            </div>

            {!isEdit && (
              <div className="wrp-content mt-1-rem">
                <div className="left">
                  <div className="title">{t('document_name')}</div>
                  <TextField
                    className="text-field"
                    value={documentName}
                    error={isErrorDocumentName}
                    onChange={(e) => {
                      setDocumentName(e.target.value)
                    }}
                  />
                </div>

                <div className="right">
                  <div className="title">{t('template_document')}</div>
                  <Dropdown
                    className="dropdown mr-1-rem"
                    optionList={templateList}
                    value={templateSelected}
                    onChange={(value) => {
                      setTemplateSelected(value)
                    }}
                    placeHolder={t('select')}
                  />
                  {/* {templateSelected != 'default' && (
                      <div className="icon-eye">
                        <IconEye onClick={() => handleClickBtnEye()} />
                      </div>
                    )} */}
                </div>
              </div>
            )}

            {/* {templateSelected == 'purchase_order' || (isEdit && <PurchaseOrderTemplate />)} */}
            <div className="wrp-content-template">
              {(templateSelected == 'Procurement' || isEdit) && (
                <ProcurementTemplate
                  isInvalid={invalidTemplate}
                  onDataChange={(e) => handleDataChange(e)}
                  isEdit={isEdit}
                  initData={isEdit ? taskData : ''}
                />
              )}
            </div>
          </div>

          <div className="wrp-bottom">
            {(templateSelected || isEdit) && (
              <div className="wrp-btn-bottom">
                {!isEdit && (
                  <ButtonOutline className="btn-preview" onClick={handleClickPreview}>
                    {t('preview')}
                  </ButtonOutline>
                )}
                {isEdit ? (
                  <Button onClick={() => handleClickBtnSave()}>{t('save')}</Button>
                ) : (
                  <Button onClick={() => handleClickBtnCreate()}>{t('create')}</Button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {isShowPreview && (
        <div className="preview-doc">
          <div className="wrp-navigation">
            <div className="navigation-right">
              <div className="icon-x-close" onClick={() => setIsShowPreview(false)}>
                <IconXClose />
              </div>
            </div>
          </div>
          <ViewDoc isUpdate={toggleUpdateDoc} isNew={!isEdit} newData={newDataPreview} />
        </div>
      )}
      {isShowDocumentTemplate && (
        <>
          {isShowDetail && (
            <div className="wrp-purchase-order">
              <div className="wrp-navigation">
                <div className="navigation-left">
                  <div className="icon-arrow-left" onClick={() => handleClickBtnIconBack()}>
                    <IconArrowLeft color="#fff" />
                  </div>
                  <div className="txt-navigation">{t('back_to_task_creation')}</div>
                </div>
              </div>

              <PurchaseOrderTemplate />
            </div>
          )}
        </>
      )}
    </Styled>
  )
}
const Styled = styled.div`
  .wrp-add-new-document {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .MuiFormControlLabel-root {
    align-items: center;
  }
  .check-box-style {
    width: 50px;
  }
  .check-box-style .MuiCheckbox-colorPrimary {
    margin: 0;
    padding: 0;
  }

  position: absolute;
  background-color: #fff;
  inset: 0;
  overflow: auto;
  .wrp-navigation {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    border-bottom: 1px solid var(--Gray-300);
  }

  .navigation-left {
    display: flex;
  }
  .icon-chevron-left {
    display: flex;
    margin-right: 1rem;
    cursor: pointer;
  }
  .txt-navigation-left {
    color: gray;
    font-weight: bold;
    font-size: 20px;
  }
  .navigation-right {
    display: flex;
    align-items: center;
  }
  .icon-x-close {
    display: flex;
    cursor: pointer;
  }

  .wrp-btn-bottom {
    display: flex;
    justify-content: end;
    padding: 16px 32px;
    border-top: 1px solid var(--Gray-300);
  }
  .btn-preview {
    margin-right: 8px;
  }
  .wrp-content {
    /* display: grid;
    grid-template-columns: fit-content(100%) auto;
    grid-gap: 1rem 1rem; */

    display: flex;
    padding: 20px 21px;
    border-bottom: 1px solid var(--Gray-300);

    .left {
      width: 100%;
    }
    .right {
      width: 100%;

      margin-left: 12px;
    }
    .title {
      mix-blend-mode: 6px;
    }
    .text-field,
    .dropdown {
      width: 100%;
    }

    .icon-eye {
      margin-left: 16px;
    }
  }

  .wrp-content-template {
  }

  //Purchase Order
  .wrp-purchase-order {
    .wrp-navigation {
      background-color: var(--Gray-600);
      display: flex;
      padding: 1rem;
      .txt-navigation {
        color: #fff;
        font-weight: bold;
        font-size: 20px;
      }
    }
    .icon-arrow-left {
      display: flex;
      margin-right: 1rem;
      cursor: pointer;
    }
    .title-tool-select {
      font-weight: bold;
      font-size: 18px;
    }
    .wrp-select-class-date {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem 1rem;
      align-items: center;

      margin: 1rem 0;
    }
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .icon-search {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left-tool {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-column-gap: 0.5rem;
  }
  .right-tool {
    .btn-preview {
      margin-right: 0.5rem;
    }
  }

  .wrp-table {
    margin: 1rem 0;
  }
  .preview-doc {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    .wrp-navigation {
      display: flex;
      justify-content: end;
      padding: 16px 32px;
      border: none;
    }
    .navigation-right {
      display: flex;
      align-items: center;
    }
    .icon-x-close {
      display: flex;
      cursor: pointer;
    }
    .wrp-content {
      flex: 1;
      padding: 0 24px;
    }
  }
`
