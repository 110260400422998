import { useEffect, useState } from 'react'
import styled from 'styled-components'
import DocumentSettingPreview from '../../../../components/feature/document-setting/DocumentSettingPreview'
import TextField from '../../../../components/form/TextField'
import DropdownSelect from '../../../../components/widget/element/DropDownSelect'

export default function Signature({ docType, settingData, onDataChange }) {
  const NUMBER_LIST = [
    {
      text: 'None',
      value: 0,
    },
    {
      text: '1',
      value: 1,
    },
    {
      text: '2',
      value: 2,
    },
    {
      text: '3',
      value: 3,
    },
    {
      text: '4',
      value: 4,
    },
    {
      text: '5',
      value: 5,
    },
    {
      text: '6',
      value: 6,
    },
    {
      text: '7',
      value: 7,
    },
    {
      text: '8',
      value: 8,
    },
  ]
  const DEFAULT_SIGNATURE = {
    label: '',
    rubber_stamp: false,
  }
  const [selectedNumberSignature, setSelectedNumberSignature] = useState(0)
  const [selectedRubberStamp, setSelectedRubberStamp] = useState('')
  const [signatureList, setSignatureList] = useState([])
  const [imageForDocument, setImageForDocument] = useState({
    url_company_logo: '',
    url_rubber_stamp: '',
    url_signature_file: '',
  })
  const [documentSettingData, setDocumentSettingData] = useState()

  useEffect(() => {
    if (settingData && docType != '') {
      // console.log('settingData: '+JSON.stringify(settingData,null,2))
      console.log('TYPE : ' + docType)
      console.log('settingData: ' + JSON.stringify(settingData[docType], null, 2))

      let tmpImageForDocumentObj = { ...imageForDocument }

      if (settingData[docType]) {
        tmpImageForDocumentObj.url_company_logo = settingData[docType]?.company_logo
        tmpImageForDocumentObj.url_rubber_stamp = settingData[docType]?.rubber_stamp
        tmpImageForDocumentObj.url_signature_file = settingData[docType]?.signature

        if (settingData[docType].signature_list) {
          setSelectedNumberSignature(settingData[docType].signature_list.length)
          setSignatureList(settingData[docType].signature_list)
        }
      }
      setImageForDocument(tmpImageForDocumentObj)
      setDocumentSettingData(settingData)
    }
  }, [settingData, docType])

  const onChangeSelectNumber = (amount) => {
    setSelectedNumberSignature(amount)

    let tmpList = [...signatureList]
    let tmpDocumentSettingObj = { ...settingData }
    let tmpSignatureList = []
    if (amount > 0) {
      if (tmpList?.length > 0) {
        // list > select
        if (tmpList.length >= amount) {
          for (let i = 0; i < tmpList.length; i++) {
            if (i + 1 <= amount) {
              tmpSignatureList.push(tmpList[i])
            }
          }
        }

        // list < select
        if (tmpList.length < amount) {
          tmpSignatureList = [...tmpList]
          let remaining = amount - tmpList.length
          for (let i = 0; i < remaining; i++) {
            tmpSignatureList.push(DEFAULT_SIGNATURE)
          }
        }
      } else {
        for (let i = 0; i < amount; i++) {
          tmpSignatureList.push(DEFAULT_SIGNATURE)
        }
      }
    }

    tmpDocumentSettingObj[docType].signature_list = tmpSignatureList
    if (onDataChange) {
      setSignatureList(tmpSignatureList)
      onDataChange(tmpDocumentSettingObj)
    }
  }

  const handleChangeInput = (e, index) => {
    let tmpList = [...signatureList]
    let tmpDocumentSettingObj = { ...settingData }
    let tmpObj = { label: e.target.value, rubber_stamp: tmpList[index].rubber_stamp }
    tmpList[index] = tmpObj
    tmpDocumentSettingObj[docType].signature_list = tmpList
    if (onDataChange) {
      setSignatureList(tmpList)
      onDataChange(tmpDocumentSettingObj)
    }
  }

  const onChangeSelectRadio = (e) => {
    let indexSelected = e.target.value
    let tmpList = [...signatureList]
    let tmpDocumentSettingObj = { ...settingData }

    for (let i = 0; i < tmpList.length; i++) {
      if (i == indexSelected) {
        tmpList[i].rubber_stamp = true
      } else {
        tmpList[i].rubber_stamp = false
      }
    }
    tmpDocumentSettingObj[docType].signature_list = tmpList
    if (onDataChange) {
      setSelectedRubberStamp(indexSelected)
      setSignatureList(tmpList)
      onDataChange(tmpDocumentSettingObj)
    }
  }

  return (
    <Styled>
      <div className="wrp-main">
        <div className="wrp-container-left">
          <div className="condition-title">Number of signature</div>
          <DropdownSelect
            key={'dropdown-number-signature'}
            className={'input-dropdown'}
            dataList={NUMBER_LIST}
            initValue={selectedNumberSignature}
            onChange={(e) => {
              onChangeSelectNumber(e)
            }}
            placeHolder={'None'}
          />

          <div className="wrp-input-list">
            {signatureList?.map((item, index) => (
              <div className="input-item" key={'input-' + index}>
                <div className="wrp-input">
                  <p className="txt-title-item">Signature #{index + 1}</p>
                  <TextField
                    key={'input-text-item-' + index}
                    className={'text-field'}
                    onChange={(e) => handleChangeInput(e, index)}
                    value={item?.label}
                  />
                </div>
                <div className="form-check">
                  <input
                    type="radio"
                    className="form-check-input"
                    value={index}
                    name="rubber"
                    onChange={onChangeSelectRadio}
                    // checked={(selectedRubberStamp == index && selectedRubberStamp != '') ? true : false}
                    checked={item?.rubber_stamp}
                  />
                  <label className="form-check-label">Rubber Stamp</label>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="wrp-container-right">
          <DocumentSettingPreview
            docType={docType}
            settingData={settingData}
            imageUrlObj={imageForDocument}
            condition={settingData[docType]?.condition || ''}
          />
        </div>
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  width: 100%;
  .wrp-main {
    display: flex;
  }
  .wrp-container-left,
  .wrp-container-right {
    flex: 1;
  }
  .wrp-container-left {
  }
  .wrp-container-right {
    /* background-color: yellow; */
  }
  .condition-title {
    margin-bottom: 16px;
  }
  .input-dropdown {
    width: 50%;
    margin-bottom: 30px;
  }
  .wrp-input-list {
    width: 100%;
  }
  .input-item {
    display: flex;
    margin-bottom: 20px;
    align-items: flex-end;
  }
  .wrp-input,
  .form-check {
    flex: 1;
  }
  .wrp-input .text-field {
    width: 100%;
  }
  .txt-title-item {
    margin-top: 0;
    margin-bottom: 10px;
  }
  .form-check {
    display: flex;
    align-items: center;
    padding-left: 20px;
  }
  .form-check-input {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 0;
  }
  .form-check-label {
    margin-left: 8px;
  }
`
