import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import SidePanel from '../../../components/common/SidePanel'
import Table from '../../../components/common/Table'
import DialogFail from '../../../components/dialog/DialogFail'
import Checkbox from '../../../components/form/Checkbox'
import Date from '../../../components/form/Date'
import TextField from '../../../components/form/TextField'
import Button from '../../../components/form/button/Button'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import DocumentRequestApprove from '../../../components/widget/combo/DocumentRequestApprove'
import DocumentShareAndSendEmail from '../../../components/widget/combo/DoucumentShareAndSendEmail'
import DocumentOptionButton from '../../../components/widget/element/DocumentOptionButton'
import DropdownSelect from '../../../components/widget/element/DropDownSelect'
import InputTextArea from '../../../components/widget/element/InputTextArea'
import ModalBox from '../../../components/widget/element/ModalBox'
import IconBin from '../../../components/widget/icon/IconBin'
import {
  createPurchase,
  createSeller,
  editPurchaseDoc,
  getDocumentSettingByCompanyId,
  getSaleDocumentById,
} from '../../../redux/actions/document'

import DialogDocument from 'pages/document/DialogDocument'
import SearchSuggestSeller from '../../../components/widget/combo/SearchSuggestSeller'
import { selectDocument } from '../../../redux/selectors'
import {
  calculateDiscount,
  calculatePriceAfterTax,
  calculatePurchaseTotalPrice,
  removeArrayItemByIndex,
} from '../../../utils/helper'

export default function CreatePurchaseOrder({ onCallback }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const viewType = searchParams.get('type')
  const navigateType = searchParams.get('navigate')
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))

  const TABLE_HEADER_COLUMN = [
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'SKU',
      accessor: 'sku',
      disableSortBy: false,
    },
    {
      Header: 'Product Name / Details',
      accessor: 'product_name',
      disableSortBy: false,
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      disableSortBy: false,
    },
    {
      Header: 'UOM',
      accessor: 'uom',
      disableSortBy: false,
    },
    {
      Header: 'Price per unit',
      accessor: 'price',
      disableSortBy: false,
    },
    {
      Header: 'Discount',
      accessor: 'discount',
      disableSortBy: false,
    },
    {
      Header: 'Total Price',
      accessor: 'total_price',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'delete',
      disableSortBy: false,
    },
  ]

  const DEFAULT_BODY_ITEM_DATA = {
    no: '',
    sku: '',
    product_name: '',
    quantity: '',
    uom: '',
    price: '',
    discount_percent: 0,
    delete: false,
    //validate
    is_error_sku: false,
    is_error_product_name: false,
    is_error_quantity: false,
    is_error_uom: false,
    is_error_price: false,
    is_error_discount: false,
  }

  const [dataCreating, setDataCreating] = useState({
    //ข้อมูลลูกค้า บนซ้าย
    id: '',
    seller_id: '',
    seller_name: '',
    address: '',
    seller_phone: '',
    seller_fax: '',

    contact_person: '', // Supplier
    contact_phone: '',
    // contact_email: '',
    // contact_fax: '',
    shipping_location: '',
    shipping_note: '',

    purchase_date: '',
    payment_type: '',
    credit_day: 0,
    credit_due_date: '',
    // send_due_date: '',
    // payment_condition: '',
    // purchaser_employee_id: '',
    number_of_copy: 1,
    company_logo: '',
    rubber_stamp: '',
    // signature: '',
    condition: '',

    ref_number: '',
    include_tax: false,
    details: '',

    product_list: [],

    // signature: false,
    note: '',

    discount_percent: 0,
    vat: false,
    withholding_tax: false,
    withholding_tax_percent: 0,
    company_id: company_info?.id,
    doc_type: viewType,

    doc_group: '',
    company_logo: '',
    rubber_stamp: '',
    condition: '',

    quotation_id: '',
    quotation_date: '',
    agency: '',
    shipping_deadline: '',
    is_external: navigateType == 'external' ? true : false,
  })

  const [paymentTypeList, setPaymentTypeList] = useState([
    {
      text: 'Credit (day)',
      value: 'credit day and due date',
    },
    {
      text: 'Credit (not show date)',
      value: 'credit day',
    },
    {
      text: 'Cash',
      value: 'cash',
    },
  ])
  const [priceTaxSelectList, setPriceTaxSelectList] = useState([
    {
      text: 'Include Tax',
      value: true,
    },
    {
      text: 'Not Include Tax',
      value: false,
    },
  ])
  const [uomSelectList, setUomSelectList] = useState([
    {
      text: 'Piece',
      value: 'piece',
    },
    {
      text: 'Box',
      value: 'box',
    },
    {
      text: 'Pack',
      value: 'pack',
    },
  ])
  const [withHoldingTaxList, setWithHoldingTaxList] = useState([
    {
      text: '0.5%',
      value: 0.5,
    },
    {
      text: '0.75%',
      value: 0.75,
    },
    {
      text: '0.1%',
      value: 0.1,
    },
    {
      text: '1.5%',
      value: 1.5,
    },
    {
      text: '2%',
      value: 2,
    },
    {
      text: '3%',
      value: 3,
    },
    {
      text: '5%',
      value: 5,
    },
    {
      text: '10%',
      value: 10,
    },
    {
      text: '15%',
      value: 15,
    },
  ])

  const [bodyTableList, setBodyTableList] = useState([])
  const [isErrorSellerId, setIsErrorSellerId] = useState(false)
  const [isErrorSellerName, setIsErrorSellerName] = useState(false)
  const [validateInputData, setValidateInputData] = useState({
    is_error_seller_id: false,
    is_error_seller_name: false,
    is_error_address: false,
    is_error_seller_fax: false,
    is_error_seller_phone: false,

    is_error_contact_person: false,
    is_error_contact_phone: false,
    // is_error_contact_fax: false,
    // is_error_contact_email: false,

    is_error_purchase_date: false,
    is_error_payment_type: false,
    is_error_credit_day: false,
    is_error_credit_due_date: false,
    is_error_send_due_date: false,
    is_error_purchaser_employee_id: false,

    is_error_ref_number: false,
    is_error_details: false,

    is_error_product_list: false,

    is_error_note: false,

    is_error_discount_percent: false,

    is_error_withholding_tax_percent: false,

    // NEW
    is_error_quotation_id: false,
    is_error_quotation_date: false,
    is_error_agency: false,
    is_error_shipping_deadline: false,

    is_error_shipping_location: false,
    is_error_shipping_note: false,
  })
  const [isShowFailedModal, setIsShowFailedModal] = useState(false)
  const [textSellerId, setTextSellerId] = useState('')
  const [textSellerName, setTextSellerName] = useState('')

  const [sumTotalPrice, setTotalPrice] = useState(0)
  const [priceDiscount, setPriceDiscount] = useState(0)
  const [priceAfterDiscount, setPriceAfterDiscount] = useState(0)

  const [priceAfterVat, setPriceAfterVat] = useState(0)
  const [priceWithHoldingTax, setPriceWithHoldingTax] = useState(0)
  const [summaryPrice, setSummaryPrice] = useState(0)
  const [priceVat, setPriceVat] = useState(0) //ราคา vat

  const [currentDocId, setCurrentDocId] = useState('')

  const { saleDocumentData, documentSettingInfo } = useSelector(selectDocument)

  const [dialogPurchaseType, setDialogPurchaseType] = useState('')
  const [isShowDialogPreview, setIsShowDialogPreview] = useState(false)
  const [isShowModalDownload, setIsShowModalDownload] = useState(false)
  const [isShowDialogRequestApprove, setIsShowDialogRequestApprove] = useState(false)

  const [isShowModalShare, setIsShowModalShare] = useState(false)
  const [isHasSellerId, setIsHasSellerId] = useState(false)
  const [isHindModalShare, setIsHindModalShare] = useState(false)

  const [isEditPurchase, setIsEditPurchase] = useState(false)

  const [imageForDocument, setImageForDocument] = useState({
    url_company_logo: '',
    url_rubber_stamp: '',
    url_signature_file: '',
  })
  const [textPurchaseCondition, setTextPurchaseCondition] = useState('')
  const [currentDocType, setCurrentDocType] = useState('')

  useEffect(() => {
    ;(async () => {
      let url = window.location.pathname
      const myArray = url.split('/')

      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i] == 'edit') {
          setIsEditPurchase(true)
          // let tmpId = myArray[myArray.length - 1]
          let tmpId = searchParams.get('id')
          console.log('tmpId => ' + tmpId)
          setCurrentDocType(viewType)
          setCurrentDocId(tmpId)
          dispatch(getSaleDocumentById(tmpId))
        }
        if (myArray[i] == 'create') {
          setIsEditPurchase(false)
          // let tmpDocType = myArray[myArray.length - 1]
          // setDataCreating({ ...dataCreating, doc_type: viewType })
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (Object.keys(saleDocumentData).length > 0 && isEditPurchase) {
      console.log('saleDocumentData: ' + JSON.stringify(saleDocumentData, null, 2))
      let tmpData = {
        creator_id: employee_info.id,
        id: saleDocumentData?.seller?.id,
        seller_id: saleDocumentData?.seller?.id,

        seller_name: saleDocumentData?.seller?.name,
        address: saleDocumentData?.seller?.address,
        seller_phone: saleDocumentData?.seller?.phone,
        seller_fax: saleDocumentData?.seller?.fax,
        doc_id: saleDocumentData?.doc_id,

        contact_person: saleDocumentData?.contact_person,
        contact_phone: saleDocumentData?.contact_phone,
        contact_fax: saleDocumentData?.contact_fax,
        contact_email: saleDocumentData?.contact_email,
        purchase_date: saleDocumentData?.purchase_date,
        payment_type: saleDocumentData?.payment_type,
        credit_day: saleDocumentData?.credit_day,
        credit_due_date: saleDocumentData?.credit_due_date,
        send_due_date: saleDocumentData?.send_due_date,

        ref_number: saleDocumentData?.ref_number,
        include_tax: saleDocumentData?.include_tax,
        details: saleDocumentData?.details,
        product_list: saleDocumentData?.product_list,
        // signature: saleDocumentData?.signature,
        note: saleDocumentData?.note,
        vat: saleDocumentData?.vat,
        withholding_tax_percent: saleDocumentData?.withholding_tax_percent,
        withholding_tax: saleDocumentData?.withholding_tax_percent > 0 ? true : false,
        company_id: company_info?.id,

        // payment_condition: saleDocumentData?.payment_condition,
        // purchaser_employee_id: saleDocumentData?.purchaser_employee_id,
        // purchaser_name: saleDocumentData?.purchaser_name,
        number_of_copy: saleDocumentData?.number_of_copy,

        condition: saleDocumentData?.condition,
        doc_type: saleDocumentData?.doc_type,

        signature_list: saleDocumentData?.signature_list,
        rubber_stamp: saleDocumentData?.rubber_stamp,
        company_logo: saleDocumentData?.company_logo,

        quotation_id: saleDocumentData?.quotation_id,
        quotation_date: saleDocumentData?.quotation_date,
        agency: saleDocumentData?.agency,
        shipping_deadline: saleDocumentData?.shipping_deadline,

        shipping_location: saleDocumentData?.shipping_location,
        shipping_note: saleDocumentData?.shipping_note,
      }
      // console.log('tmpData: ' + JSON.stringify(tmpData, null, 2))
      setDataCreating(tmpData)
    }
    dispatch(getDocumentSettingByCompanyId())
  }, [saleDocumentData, isEditPurchase])

  useEffect(() => {
    if (documentSettingInfo) {
      let tmpImageForDocumentObj = { ...imageForDocument }
      // let tmpDocType = dataCreating?.doc_type

      if (viewType != '') {
        tmpImageForDocumentObj.url_company_logo = documentSettingInfo[viewType]?.company_logo
        tmpImageForDocumentObj.url_rubber_stamp = documentSettingInfo[viewType]?.rubber_stamp
        // tmpImageForDocumentObj.url_signature_file = documentSettingInfo[viewType]?.signature

        let tmpDataObj = { ...dataCreating }
        tmpDataObj.signature_list = documentSettingInfo[viewType]?.signature_list
        tmpDataObj.company_logo = documentSettingInfo[viewType]?.company_logo
        tmpDataObj.rubber_stamp = documentSettingInfo[viewType]?.rubber_stamp
        setDataCreating(tmpDataObj)
      }
      setImageForDocument(tmpImageForDocumentObj)
      setTextPurchaseCondition(documentSettingInfo?.purchase_condition)
    }
  }, [documentSettingInfo])

  useEffect(() => {
    if (dataCreating?.product_list) {
      let tmpData = { ...dataCreating }
      const viewList = tmpData.product_list.map((item, index) => ({
        no: index + 1,
        sku: (
          <TextField
            className={'input-table'}
            placeholder={'SKU0001'}
            onChange={(e) => handleChangeProductList(e, 'sku', index)}
            value={item.sku}
            error={item?.is_error_sku}
          />
        ),
        product_name: (
          <TextField
            className={'input-table'}
            placeholder={'Details'}
            onChange={(e) => handleChangeProductList(e, 'product_name', index)}
            value={item.product_name}
            error={item?.is_error_product_name}
          />
        ),
        quantity: (
          <TextField
            className={'input-table'}
            placeholder={'0'}
            value={convertIntComma(item?.quantity || 0)}
            pattern={'^[0-9.-/]+$'}
            name={'quantity-' + index}
            onChange={(e) => handleChangeProductList(e, 'quantity', index)}
            error={item?.is_error_quantity}
          />
        ),
        uom: (
          <DropdownSelect
            className={'input-table'}
            dataList={uomSelectList}
            onChange={(e) => handleChangeProductList(e, 'uom', index)}
            initValue={item.uom}
            isError={item?.is_error_uom}
          />
        ),
        price: (
          <TextField
            className={'input-table'}
            placeholder={'0.00'}
            name={'price-' + index}
            onChange={(e) => handleChangeProductList(e, 'price', index)}
            value={convertIntComma(item?.price || 0)}
            error={item?.is_error_price}
          />
        ),
        discount: (
          <TextField
            className={'input-table'}
            placeholder={'0'}
            name={'discount-' + index}
            value={item?.discount_percent}
            onChange={(e) => handleChangeProductList(e, 'discount_percent', index)}
            error={item?.is_error_discount}
          />
        ),
        total_price: calculateItemTotalPrice(item.price, item.quantity, item.discount_percent),
        delete: (
          <div className="btn-delete" onClick={() => handleClickBtnDelete(index)}>
            <IconBin />
          </div>
        ),
      }))

      //calculate price
      let tmpTotal = calculatePurchaseTotalPrice(tmpData.product_list)
      setTotalPrice(tmpTotal)

      let { discountPrice, totalPrice } = calculateDiscount(tmpTotal, tmpData?.discount_percent || 0)
      setPriceDiscount(discountPrice)
      setPriceAfterDiscount(totalPrice)

      if (tmpData?.include_tax) {
        let { priceAfterVat, priceVat, priceWithHoldingTax, summaryPrice } = calculatePriceAfterTax(
          true,
          tmpData?.vat,
          tmpData?.withholding_tax_percent || 0,
          totalPrice
        )
        setPriceAfterVat(priceAfterVat)
        setPriceVat(priceVat)
        setPriceWithHoldingTax(priceWithHoldingTax)
        setSummaryPrice(summaryPrice)
      } else {
        let { priceAfterVat, priceVat, priceWithHoldingTax, summaryPrice } = calculatePriceAfterTax(
          false,
          tmpData?.vat,
          tmpData?.withholding_tax_percent || 0,
          totalPrice
        )
        setPriceAfterVat(priceAfterVat)
        setPriceVat(priceVat)
        setPriceWithHoldingTax(priceWithHoldingTax)
        setSummaryPrice(summaryPrice)
      }

      setBodyTableList(viewList)
    }
  }, [dataCreating])

  const handleChangeData = (e, type) => {
    let tmpObj = { ...dataCreating }

    let tmpValidateObj = { ...validateInputData }
    //ข้อมูลลูกค้า ซ้ายบน
    if (type == 'seller_name') {
      tmpObj.seller_name = e.target.value
    } else if (type == 'address') {
      tmpObj.address = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_address = false
      }
    } else if (type == 'seller_fax') {
      let tmpString = e.target.value
      tmpObj.seller_fax = tmpString?.replace(/\D/g, '')
      if (e.target.value != '') {
        tmpValidateObj.is_error_seller_fax = false
      }
    } else if (type == 'seller_phone') {
      let tmpString = e.target.value
      tmpObj.seller_phone = tmpString?.replace(/\D/g, '')
      if (e.target.value != '') {
        tmpValidateObj.is_error_seller_phone = false
      }
    }
    //ข้อมูลลูกค้า ซ้ายล่าง
    else if (type == 'contact_person') {
      tmpObj.contact_person = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_contact_person = false
      }
    } else if (type == 'contact_phone') {
      let tmpString = e.target.value
      tmpObj.contact_phone = tmpString?.replace(/\D/g, '')
      if (e.target.value != '') {
        tmpValidateObj.is_error_contact_phone = false
      }
    } else if (type == 'contact_fax') {
      let tmpString = e.target.value
      tmpObj.contact_fax = tmpString?.replace(/\D/g, '')
      if (e.target.value != '') {
        tmpValidateObj.is_error_contact_fax = false
      }
    } else if (type == 'contact_email') {
      tmpObj.contact_email = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_contact_email = false
      }
    }
    //รายละเอียด ขวาบน
    else if (type == 'purchase_date') {
      tmpObj.purchase_date = e
      if (e) {
        tmpValidateObj.is_error_purchase_date = false
      }
    } else if (type == 'payment_type') {
      tmpObj.payment_type = e
      if (e) {
        tmpValidateObj.is_error_payment_type = false
      }
    } else if (type == 'credit_day') {
      let tmpString = e.target.value
      tmpObj.credit_day = parseInt(tmpString?.replace(/\D/g, ''))
      if (e.target.value != '') {
        tmpValidateObj.is_error_credit_day = false
      }
    } else if (type == 'credit_due_date') {
      tmpObj.credit_due_date = e
      if (e) {
        tmpValidateObj.is_error_credit_due_date = false
      }
    } else if (type == 'purchaser_employee_id') {
      tmpObj.purchaser_employee_id = e.id
      if (e) {
        tmpValidateObj.is_error_purchaser_employee_id = false
      }
    } else if (type == 'send_due_date') {
      tmpObj.send_due_date = e
      if (e) {
        tmpValidateObj.is_error_send_due_date = false
      }
    }

    // ข้อมูลสินค้า
    else if (type == 'ref_number') {
      tmpObj.ref_number = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_ref_number = false
      }
    } else if (type == 'include_tax') {
      tmpObj.include_tax = e
      if (e) {
        tmpObj.vat = true
      }
    } else if (type == 'details') {
      tmpObj.details = e.target.value
    } else if (type == 'signature') {
      tmpObj.signature = e
    } else if (type == 'note') {
      tmpObj.note = e.target.value
    } else if (type == 'payment_condition') {
      tmpObj.payment_condition = e.target.value
    }
    ///ราคา ขวาล่าง
    else if (type == 'discount_percent') {
      let tmpString = e.target.value
      let tmpDiscountPercent = parseFloat(tmpString?.replace(/\D/g, '') || 0)
      if (tmpDiscountPercent > 100) {
        tmpObj.discount_percent = 100
      } else {
        tmpObj.discount_percent = tmpDiscountPercent
      }
    } else if (type == 'vat') {
      tmpObj.vat = e

      if (!e) {
        setPriceVat(0)
      }
    } else if (type == 'withholding_tax') {
      tmpObj.withholding_tax = e
      if (!e) {
        tmpObj.withholding_tax_percent = 0
        setPriceWithHoldingTax(0)
      }
    } else if (type == 'withholding_tax_percent') {
      tmpObj.withholding_tax_percent = e
    } else if (type == 'quotation_id') {
      tmpObj.quotation_id = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_quotation_id = false
      }
    } else if (type == 'quotation_date') {
      tmpObj.quotation_date = e
      if (e) {
        tmpValidateObj.is_error_quotation_date = false
      }
    } else if (type == 'agency') {
      tmpObj.agency = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_agency = false
      }
    } else if (type == 'shipping_deadline') {
      tmpObj.shipping_deadline = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_shipping_deadline = false
      }
    } else if (type == 'shipping_location') {
      tmpObj.shipping_location = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_shipping_location = false
      }
    } else if (type == 'shipping_note') {
      tmpObj.shipping_note = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_shipping_note = false
      }
    }

    setDataCreating(tmpObj)
    setValidateInputData(tmpValidateObj)
  }
  const handleChangeProductList = (e, type, index) => {
    let tmpObj = { ...dataCreating }
    if (type == 'sku') {
      tmpObj.product_list[index].sku = e.target.value
      if (e.target.value != '') {
        tmpObj.product_list[index].is_error_sku = false
      }
    } else if (type == 'product_name') {
      tmpObj.product_list[index].product_name = e.target.value
      if (e.target.value != '') {
        tmpObj.product_list[index].is_error_product_name = false
      }
    } else if (type == 'quantity') {
      let tmpString = e.target.value

      let tmpInt = tmpString?.replace(/\D/g, '') || 0
      tmpObj.product_list[index].quantity = parseInt(tmpInt)
      if (e.target.value != '') {
        tmpObj.product_list[index].is_error_quantity = false
      }
    } else if (type == 'uom') {
      tmpObj.product_list[index].uom = e
      if (e) {
        tmpObj.product_list[index].is_error_uom = false
      }
    } else if (type == 'price') {
      let tmpString = e.target.value
      let tmpInt = tmpString?.replace(/\D/g, '') || 0
      tmpObj.product_list[index].price = parseInt(tmpInt)
      if (e.target.value) {
        tmpObj.product_list[index].is_error_price = false
      }
    } else if (type == 'discount_percent') {
      let tmpString = e.target.value
      let tmpNumber = Number(tmpString)

      if (tmpNumber >= 100) {
        tmpString = '100.00'
      }
      if (tmpNumber < 0) {
        tmpString = '0.00'
      }

      if (tmpString.length <= 6) {
        tmpObj.product_list[index].discount_percent = parseFloat(tmpString !== '' ? tmpString : '0').toFixed(2)
        if (e.target.value) {
          tmpObj.product_list[index].is_error_discount = false
        }
      }
    }
    setDataCreating({ ...tmpObj })
  }
  const handleClickBtnDelete = (index) => {
    let tmpBodyDataList = removeArrayItemByIndex(dataCreating?.product_list, index)
    setDataCreating({ ...dataCreating, product_list: tmpBodyDataList })
  }
  const handleClickBtnAddList = () => {
    let tmpBodyDataList = [...dataCreating?.product_list, DEFAULT_BODY_ITEM_DATA]
    setDataCreating({ ...dataCreating, product_list: tmpBodyDataList })
    setValidateInputData({ ...validateInputData, is_error_product_list: false })
  }
  const handleClickBtnPreview = () => {
    let isValid = checkInputValidation()
    if (isValid) {
      setIsShowDialogPreview(true)
      setDialogPurchaseType('render_preview')
    }
  }
  const handleClickBtnCreate = async () => {
    let isValid = checkInputValidation()
    if (isValid) {
      if (Object.keys(employee_info).length > 0) {
        let tmpObj = { ...dataCreating }
        tmpObj.creator_id = employee_info.id

        if (isEditPurchase) {
          // Edit Purchase

          let tmpProductList = [...tmpObj.product_list]
          let tmpNewList = []
          for (let i = 0; i < tmpProductList.length; i++) {
            let tmpProductItem = tmpProductList[i]
            delete tmpProductItem['no']
            delete tmpProductItem['delete']
            delete tmpProductItem['is_error_sku']
            delete tmpProductItem['is_error_product_name']
            delete tmpProductItem['is_error_quantity']
            delete tmpProductItem['is_error_discount']
            delete tmpProductItem['is_error_uom']
            delete tmpProductItem['is_error_price']

            tmpNewList.push(tmpProductItem)
          }
          tmpObj.product_list = [...tmpNewList]

          delete tmpObj['id']
          delete tmpObj['doc_id']
          delete tmpObj['seller_name']
          delete tmpObj['name']
          delete tmpObj['number_of_copy']
          delete tmpObj['company_logo']
          delete tmpObj['rubber_stamp']
          // delete tmpObj['signature']
          delete tmpObj['condition']

          const request = { data: tmpObj }
          await dispatch(editPurchaseDoc(currentDocId, request)).then((e) => {
            if (e?.data) {
              if (navigateType == 'external') {
                if (onCallback) {
                  onCallback(e?.data?.id)
                }
              } else {
                handleGoBack()
              }
            } else {
              alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
            }
          })
        } else {
          ///Create Purchase
          if (isHasSellerId) {
            tmpObj.seller_id = tmpObj.id
            let tmpProductList = [...tmpObj.product_list]
            let tmpNewList = []
            for (let i = 0; i < tmpProductList.length; i++) {
              let tmpProductItem = tmpProductList[i]

              tmpProductItem.discount_percent = parseFloat(tmpProductItem.discount_percent.toString())
              delete tmpProductItem['no']
              delete tmpProductItem['delete']
              delete tmpProductItem['is_error_sku']
              delete tmpProductItem['is_error_product_name']
              delete tmpProductItem['is_error_quantity']
              delete tmpProductItem['is_error_discount']
              delete tmpProductItem['is_error_uom']
              delete tmpProductItem['is_error_price']

              tmpNewList.push(tmpProductItem)
            }
            tmpObj.product_list = [...tmpNewList]
            delete tmpObj['id']
            delete tmpObj['seller_name']
            delete tmpObj['name']
            delete tmpObj['address']
            delete tmpObj['withholding_tax']

            if (tmpObj.payment_type == 'cash') {
              delete tmpObj['credit_day']
              delete tmpObj['credit_due_date']
            }
            delete tmpObj['doc_group']
            delete tmpObj['details']
            delete tmpObj['fax']
            delete tmpObj['phone']
            delete tmpObj['signature_list']
            delete tmpObj['discount_percent']
            delete tmpObj['number_of_copy']
            delete tmpObj['company_logo']
            delete tmpObj['rubber_stamp']
            delete tmpObj['condition']
            delete tmpObj['seller_phone']
            delete tmpObj['seller_fax']

            const request = { data: tmpObj }
            await dispatch(createPurchase(request)).then((e) => {
              if (e?.data) {
                if (navigateType == 'external') {
                  if (onCallback) {
                    onCallback(e?.data?.id)
                  }
                } else {
                  handleGoBack()
                }
              } else {
                alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
              }
            })
          } else {
            // Create Seller
            let data = {
              seller_id: textSellerId,
              name: textSellerName,
              address: tmpObj.address,
              company_id: company_info.id,
              phone: tmpObj.seller_phone,
              fax: tmpObj.seller_fax,
            }
            const request = { data }
            dispatch(createSeller(request)).then((e) => {
              if (e?.data) {
                let tmpInfo = { ...e.data }

                tmpObj.seller_id = tmpInfo.id
                tmpObj.seller_name = tmpInfo.name
                tmpObj.address = tmpInfo.address

                let tmpProductList = [...tmpObj.product_list]
                let tmpNewList = []
                for (let i = 0; i < tmpProductList.length; i++) {
                  let tmpProductItem = tmpProductList[i]
                  delete tmpProductItem['no']
                  delete tmpProductItem['delete']
                  delete tmpProductItem['is_error_sku']
                  delete tmpProductItem['is_error_product_name']
                  delete tmpProductItem['is_error_quantity']
                  delete tmpProductItem['is_error_uom']
                  delete tmpProductItem['is_error_price']
                  tmpNewList.push(tmpProductItem)
                }
                tmpObj.product_list = [...tmpNewList]
                delete tmpObj['seller_name']
                delete tmpObj['name']
                delete tmpObj['address']
                delete tmpObj['withholding_tax']
                delete tmpObj['id']
                // delete tmpObj['signature']

                delete tmpObj['doc_group']
                delete tmpObj['details']
                delete tmpObj['fax']
                delete tmpObj['phone']
                delete tmpObj['signature_list']
                delete tmpObj['discount_percent']
                delete tmpObj['number_of_copy']
                delete tmpObj['company_logo']
                delete tmpObj['rubber_stamp']
                delete tmpObj['condition']
                delete tmpObj['seller_phone']
                delete tmpObj['seller_fax']

                let tmpRequest = { data: { ...tmpObj } }
                dispatch(createPurchase(tmpRequest)).then((e) => {
                  if (e?.data) {
                    if (navigateType == 'external') {
                      if (onCallback) {
                        onCallback(e?.data?.id)
                      }
                    } else {
                      handleGoBack()
                    }
                  }
                })
              }
            })
          }
        }
      } else {
        setIsShowFailedModal(true)
      }
    } else {
      alert('กรอกข้อมูลให้ครบถ้วน')
    }
  }
  const checkInputValidation = () => {
    let tmpData = { ...dataCreating }
    let tmpValidateData = { ...validateInputData }
    // CHECK Valid input
    if (tmpData.seller_id != '' || textSellerId != '') {
      tmpValidateData.is_error_seller_id = false
      setIsErrorSellerId(false)
    } else {
      tmpValidateData.is_error_seller_id = true
      setIsErrorSellerId(true)
    }

    if (tmpData.seller_name != '' || textSellerName != '') {
      tmpValidateData.is_error_seller_name = false
      setIsErrorSellerName(false)
    } else {
      tmpValidateData.is_error_seller_name = true
      setIsErrorSellerName(true)
    }

    if (tmpData.address != '') {
      tmpValidateData.is_error_address = false
    } else {
      tmpValidateData.is_error_address = true
    }

    if (tmpData.contact_person != '') {
      tmpValidateData.is_error_contact_person = false
    } else {
      tmpValidateData.is_error_contact_person = true
    }

    if (tmpData.contact_phone != '') {
      tmpValidateData.is_error_contact_phone = false
    } else {
      tmpValidateData.is_error_contact_phone = true
    }

    // if (tmpData.contact_email != '') {
    //   tmpValidateData.is_error_contact_email = false
    // } else {
    //   tmpValidateData.is_error_contact_email = true
    // }

    if (tmpData.purchase_date != '') {
      tmpValidateData.is_error_purchase_date = false
    } else {
      tmpValidateData.is_error_purchase_date = true
    }

    // if (tmpData.send_due_date != '') {
    //   tmpValidateData.is_error_send_due_date = false
    // } else {
    //   tmpValidateData.is_error_send_due_date = true
    // }

    if (tmpData.payment_type != '') {
      tmpValidateData.is_error_payment_type = false
      //check credit day
      if (tmpData.payment_type != 'cash') {
        if (tmpData.credit_day != '') {
          tmpValidateData.is_error_credit_day = false
        } else {
          tmpValidateData.is_error_credit_day = true
        }
      }
      // Check DueDate
      if (tmpData.payment_type == 'credit day and due date') {
        if (tmpData.credit_due_date != '') {
          tmpValidateData.is_error_credit_due_date = false
        } else {
          tmpValidateData.is_error_credit_due_date = true
        }
      }
    } else {
      tmpValidateData.is_error_payment_type = true
    }

    // if (tmpData.purchaser_employee_id) {
    //   tmpValidateData.is_error_purchaser_employee_id = false
    // } else {
    //   tmpValidateData.is_error_purchaser_employee_id = true
    // }

    if (tmpData.ref_number) {
      tmpValidateData.is_error_ref_number = false
    } else {
      tmpValidateData.is_error_ref_number = true
    }

    if (tmpData?.withholding_tax) {
      if (tmpData.withholding_tax_percent != '') {
        tmpValidateData.is_error_withholding_tax_percent = false
      } else {
        tmpValidateData.is_error_withholding_tax_percent = true
      }
    } else {
      tmpValidateData.is_error_withholding_tax_percent = false
    }

    if (tmpData.quotation_id != '') {
      tmpValidateData.is_error_quotation_id = false
    } else {
      tmpValidateData.is_error_quotation_id = true
    }

    if (tmpData.quotation_date != '') {
      tmpValidateData.is_error_quotation_date = false
    } else {
      tmpValidateData.is_error_quotation_date = true
    }

    if (tmpData.agency != '') {
      tmpValidateData.is_error_agency = false
    } else {
      tmpValidateData.is_error_agency = true
    }

    if (tmpData.shipping_deadline != '') {
      tmpValidateData.is_error_shipping_deadline = false
    } else {
      tmpValidateData.is_error_shipping_deadline = true
    }

    if (tmpData.shipping_location != '') {
      tmpValidateData.is_error_shipping_location = false
    } else {
      tmpValidateData.is_error_shipping_location = true
    }

    //validate product list
    if (tmpData.product_list?.length > 0) {
      let tmpProductList = [...tmpData.product_list]
      let tmpArrayList = []
      let isErrorProductItem = false
      for (let index = 0; index < tmpProductList.length; index++) {
        let productItemObj = tmpProductList[index]

        if (productItemObj.sku == '') {
          productItemObj.is_error_sku = true
          isErrorProductItem = true
        } else {
          productItemObj.is_error_sku = false
        }
        if (productItemObj.product_name == '') {
          productItemObj.is_error_product_name = true
          isErrorProductItem = true
        } else {
          productItemObj.is_error_product_name = false
        }
        if (productItemObj.quantity == '') {
          productItemObj.is_error_quantity = true
          isErrorProductItem = true
        } else {
          productItemObj.is_error_quantity = false
        }
        if (productItemObj.uom == '') {
          productItemObj.is_error_uom = true
          isErrorProductItem = true
        } else {
          productItemObj.is_error_uom = false
        }
        if (productItemObj.price == '') {
          productItemObj.is_error_price = true
          isErrorProductItem = true
        } else {
          productItemObj.is_error_price = false
        }
        if (productItemObj?.discount_percent >= 0) {
          productItemObj.is_error_discount = false
        } else {
          productItemObj.is_error_discount = true
          isErrorProductItem = true
        }

        tmpArrayList.push(productItemObj)
      }

      tmpValidateData.is_error_product_list = isErrorProductItem
      tmpData.product_list = [...tmpArrayList]
    } else {
      tmpValidateData.is_error_product_list = true
    }
    setDataCreating(tmpData)

    setValidateInputData(tmpValidateData)
    for (const [key, value] of Object.entries(tmpValidateData)) {
      if (value) {
        return false
      }
    }
    return true
  }
  const handleSelectSeller = (value) => {
    console.log('handleSelectSeller_value: ' + JSON.stringify(value, null, 2))
    let tmpObj = {
      ...dataCreating,
      seller_name: value?.name,
      seller_phone: value?.phone,
      seller_fax: value?.fax,
      ...value,
    }
    let tmpValidateObj = { ...validateInputData }
    tmpValidateObj.is_error_seller_id = false
    tmpValidateObj.is_error_seller_name = false
    tmpValidateObj.is_error_address = false
    setIsErrorSellerName(false)
    setIsErrorSellerId(false)

    setDataCreating(tmpObj)
    setValidateInputData(tmpValidateObj)
    setIsHasSellerId(true)
  }
  const handleTextChange = (value, type) => {
    setIsHasSellerId(false)
    if (type == 'seller_id') {
      setTextSellerId(value)
      //validate
      if (value) {
        setIsErrorSellerId(false)
      }
    } else {
      setTextSellerName(value)
      //validate
      if (value) {
        setIsErrorSellerName(false)
      }
    }
  }

  const calculateItemTotalPrice = (pricePerUnit, quantity, discount) => {
    let tmpTotal = convertNumberToCommaTwodecimal(pricePerUnit * quantity - (pricePerUnit * quantity * discount) / 100)
    return tmpTotal
  }
  const convertNumberToCommaTwodecimal = (myNumber) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
    const tmpTotal = Number(myNumber).toLocaleString('en', options)
    return tmpTotal
  }
  const handleGoBack = () => {
    navigate('/document/purchases')
  }

  const handleClickedOption = async (type) => {
    setDialogPurchaseType(type)

    if (type == 'preview' || type == 'print') {
      setIsShowDialogPreview(true)
    } else if (type == 'download_pdf') {
      setIsShowModalDownload(true)
    } else if (type == 'request_approve') {
      setIsShowDialogRequestApprove(true)
    } else {
      setIsShowModalShare(true)
    }
  }

  const handleClickBtnDownload = () => {
    setIsShowModalDownload(false)
    setIsShowDialogPreview(true)
  }

  const handleClickSubmitRequestApproval = () => {
    setIsShowDialogRequestApprove(false)
  }

  const handleClickedPreview = () => {
    setIsHindModalShare(true)
    setDialogPurchaseType('preview')
    setIsShowDialogPreview(true)
  }

  const handleClickClosePreview = () => {
    setIsShowDialogPreview(false)
    setIsHindModalShare(false)
  }

  const convertIntComma = (val) => {
    if (val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }

  const getTitleName = (text) => {
    let arr = text.split('_')
    let tmpName = arr.join(' ')
    return tmpName
  }

  return (
    <Styled isHindModalShare={isHindModalShare}>
      <div className="wrp-header-container">
        <div className="header-text">
          <h2>
            {isEditPurchase ? 'Editing' : 'Creating'} document - {viewType.replace(/_/g, ' ')}
          </h2>
          {isEditPurchase && <p>{saleDocumentData?.doc_id}</p>}
        </div>

        {isEditPurchase && (
          <div className="header-right">
            <DocumentOptionButton onClicked={handleClickedOption} />
          </div>
        )}
      </div>

      <div className="content-container">
        <div className="body">
          <div className="body-top">
            <div className="input-left">
              <div className="input-left-top">
                <div className="row">
                  <div className="left">
                    <div className="wrp-input">
                      <p>Seller Id</p>
                      <SearchSuggestSeller
                        placeHolder="Seller id"
                        type={'seller_id'}
                        selectedId={dataCreating.id}
                        onSelectedSuggest={(e) => handleSelectSeller(e, 'seller_id')}
                        onTextChange={(e) => handleTextChange(e, 'seller_id')}
                        isError={isErrorSellerId}
                      />
                    </div>
                    <div className="wrp-input">
                      <p>Seller Name</p>
                      <SearchSuggestSeller
                        placeHolder="Seller name"
                        type={'seller_name'}
                        selectedId={dataCreating.id}
                        onSelectedSuggest={(e) => handleSelectSeller(e, 'seller_name')}
                        onTextChange={(e) => handleTextChange(e, 'seller_name')}
                        isError={isErrorSellerName}
                      />
                    </div>
                    <div className="wrp-input">
                      <p>เบอร์โทรศัพท์</p>
                      <TextField
                        className={'text-field'}
                        pattern={'^[0-9]*[.,]?[0-9]*$'}
                        onChange={(e) => handleChangeData(e, 'seller_phone')}
                        value={dataCreating?.seller_phone}
                        error={validateInputData.is_error_seller_phone}
                      />
                    </div>
                    <div className="wrp-input">
                      <p>FAX</p>
                      <TextField
                        className={'text-field'}
                        pattern={'^[0-9]*[.,]?[0-9]*$'}
                        onChange={(e) => handleChangeData(e, 'seller_fax')}
                        value={dataCreating?.seller_fax}
                        error={validateInputData.is_error_seller_fax}
                      />
                    </div>
                  </div>
                  <div className="right">
                    <div className="wrp-input">
                      <p>Seller Address</p>
                      <InputTextArea
                        className={'input-text-area-address' + (validateInputData?.is_error_address ? ' error' : '')}
                        onChange={(e) => handleChangeData(e, 'address')}
                        placeHolder="Address details"
                        resizeDisable
                        initValue={dataCreating?.address}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="input-left-bottom">
                <div className="row">
                  <div className="left">
                    <div className="wrp-input">
                      <p>ผู้ติดต่อ</p>
                      <TextField
                        className={'text-field'}
                        onChange={(e) => handleChangeData(e, 'contact_person')}
                        value={dataCreating?.contact_person}
                        error={validateInputData.is_error_contact_person}
                      />
                    </div>
                  </div>
                  <div className="right">
                    <div className="wrp-input">
                      <p>เบอร์โทรศัพท์</p>
                      <TextField
                        className={'text-field'}
                        pattern={'^[0-9]*[.,]?[0-9]*$'}
                        onChange={(e) => handleChangeData(e, 'contact_phone')}
                        value={dataCreating?.contact_phone}
                        error={validateInputData.is_error_contact_phone}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="left">
                    <div className="wrp-input">
                      <p>Email</p>
                      <TextField
                        className={'text-field'}
                        onChange={(e) => handleChangeData(e, 'contact_email')}
                        value={dataCreating?.contact_email}
                        error={validateInputData.is_error_contact_email}
                      />
                    </div>
                  </div>
                  <div className="right">
                    <div className="wrp-input">
                      <p>FAX</p>
                      <TextField
                        className={'text-field'}
                        pattern={'^[0-9]*[.,]?[0-9]*$'}
                        onChange={(e) => handleChangeData(e, 'contact_fax')}
                        value={dataCreating?.contact_fax}
                        error={validateInputData.is_error_contact_fax}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className="input-right">
              <div className="wrp-input">
                <p>วันที่สร้างใบสั่งซื้อสินค้า</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'purchase_date')}
                  value={dataCreating?.purchase_date}
                  error={validateInputData.is_error_purchase_date}
                />
              </div>
              <div className="wrp-input">
                <p>Payment Type</p>
                <DropdownSelect
                  className={'dropdown-select'}
                  dataList={paymentTypeList}
                  onChange={(e) => handleChangeData(e, 'payment_type')}
                  initValue={dataCreating?.payment_type}
                  isError={validateInputData.is_error_payment_type}
                />
              </div>
              {(dataCreating.payment_type == 'credit day and due date' ||
                dataCreating.payment_type == 'credit day') && (
                <div className="wrp-input">
                  <p>Credit (day)</p>
                  <TextField
                    className={'text-field'}
                    pattern={'^[0-9]*[.,]?[0-9]*$'}
                    onChange={(e) => {
                      handleChangeData(e, 'credit_day')
                    }}
                    value={dataCreating?.credit_day}
                    error={validateInputData.is_error_credit_day}
                  />
                </div>
              )}
              {dataCreating.payment_type == 'credit day and due date' && (
                <div className="wrp-input">
                  <p>Due Date</p>
                  <Date
                    onChange={(e) => handleChangeData(e, 'credit_due_date')}
                    value={dataCreating?.credit_due_date}
                    error={validateInputData.is_error_credit_due_date}
                  />
                </div>
              )}
              {/* <div className="wrp-input">
                <p>Purchaser employee</p>
                <SearchSuggestSellerEmployee
                  placeHolder="Search Purchaser Name"
                  onSelectedSuggest={(e) => handleChangeData(e, 'purchaser_employee_id')}
                  isError={validateInputData.is_error_purchaser_employee_id}
                  isDisabled={false}
                  selectedId={dataCreating?.purchaser_employee_id}
                />
              </div> */}
              {/* <div className="wrp-input">
                <p>วันกำหนดส่ง</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'send_due_date')}
                  value={dataCreating?.send_due_date}
                  error={validateInputData.is_error_send_due_date}
                />
              </div> */}
              {/* <div className="wrp-input">
                <p>เงื่อนไขการชำระเงิน</p>
                <TextField
                  className={'text-field'}
                  placeholder={'Detail'}
                  onChange={(e) => {
                    handleChangeData(e, 'payment_condition')
                  }}
                  value={dataCreating?.payment_condition}
                  error={validateInputData.is_error_payment_condition}
                />
              </div> */}

              {/* -------- NEW UI ---------- */}
              <div className="line-border"></div>
              <div className="wrp-input">
                <p>ใบเสนอราคาเลขที่</p>
                <TextField
                  className={'text-field'}
                  placeholder={'QU999999999'}
                  onChange={(e) => {
                    handleChangeData(e, 'quotation_id')
                  }}
                  value={dataCreating?.quotation_id}
                  error={validateInputData.is_error_quotation_id}
                />
              </div>

              <div className="wrp-input">
                <p>วันที่ตามใบเสนอราคา</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'quotation_date')}
                  value={dataCreating?.quotation_date}
                  error={validateInputData.is_error_quotation_date}
                />
              </div>

              <div className="wrp-input">
                <p>หน่วยงาน</p>
                <TextField
                  className={'text-field'}
                  placeholder={'Office'}
                  onChange={(e) => {
                    handleChangeData(e, 'agency')
                  }}
                  value={dataCreating?.agency}
                  error={validateInputData.is_error_agency}
                />
              </div>

              <div className="wrp-input">
                <p>กำหนดส่งสินค้า</p>
                <TextField
                  className={'text-field'}
                  placeholder={'Detail'}
                  onChange={(e) => {
                    handleChangeData(e, 'shipping_deadline')
                  }}
                  value={dataCreating?.shipping_deadline}
                  error={validateInputData.is_error_shipping_deadline}
                />
              </div>
            </div>
          </div>

          <div className="body-bottom">
            <div className="input-top">
              <div className="row">
                <div className="left">
                  <div className="wrp-input">
                    <p>Supplier</p>
                    <TextField
                      className={'text-field'}
                      onChange={(e) => handleChangeData(e, 'contact_person')}
                      value={dataCreating?.contact_person}
                      error={validateInputData.is_error_contact_person}
                    />
                  </div>
                </div>
                <div className="right">
                  <div className="wrp-input">
                    <p>เบอร์โทรศัพท์</p>
                    <TextField
                      className={'text-field'}
                      pattern={'^[0-9]*[.,]?[0-9]*$'}
                      onChange={(e) => handleChangeData(e, 'contact_phone')}
                      value={dataCreating?.contact_phone}
                      error={validateInputData.is_error_contact_phone}
                    />
                  </div>
                </div>
              </div>
              <div className="wrp-input">
                <p>ส่งสินค้าที่</p>
                <TextField
                  className={'text-field w-100'}
                  onChange={(e) => handleChangeData(e, 'shipping_location')}
                  value={dataCreating?.shipping_location}
                  placeHolder={'สถานที่ส่งสินค้า'}
                  error={validateInputData.is_error_shipping_location}
                />
              </div>

              <div className="row">
                <div className="left">
                  <div className="wrp-input">
                    <p>Reference Number</p>
                    <TextField
                      className={'text-field'}
                      onChange={(e) => handleChangeData(e, 'ref_number')}
                      value={dataCreating?.ref_number}
                      error={validateInputData.is_error_ref_number}
                    />
                  </div>
                </div>
                <div className="right">
                  <div className="wrp-input">
                    <p>Price Tax</p>
                    <DropdownSelect
                      placeHolder="Not Include Tax"
                      className={'dropdown-select'}
                      dataList={priceTaxSelectList}
                      onChange={(e) => handleChangeData(e, 'include_tax')}
                      initValue={dataCreating?.include_tax}
                    />
                  </div>
                </div>
              </div>

              <div className="wrp-input">
                <p>หมายเหตุ</p>
                <TextField
                  className={'text-field w-100'}
                  onChange={(e) => handleChangeData(e, 'shipping_note')}
                  value={dataCreating?.shipping_note}
                />
              </div>
            </div>

            <div className={validateInputData?.is_error_product_list ? 'wrp-table-no-data' : 'wrp-table'}>
              <Table columns={TABLE_HEADER_COLUMN} data={bodyTableList} />
            </div>

            <Button onClick={handleClickBtnAddList}>Add List</Button>

            <div className="verify">
              <div className="left">
                {/* <div className="wrp-checkbox-signature">
                  <Checkbox
                    className={'checkbox'}
                    onChange={(e) => handleChangeData(e, 'signature')}
                    checked={dataCreating?.signature}
                  />
                  <p>ลายเซ็นอิเล็กทรอนิกส์และตรายาง</p>
                </div> */}
                <div className="wrp-detail">
                  <p>หมายเหตุ</p>
                  <InputTextArea
                    className={'input-text-area'}
                    placeHolder="details"
                    onChange={(e) => handleChangeData(e, 'note')}
                    initValue={dataCreating?.note}
                  />
                </div>
              </div>

              <div className="right mr-10">
                <div className="inner-right-top">
                  <div className="row">
                    <p>Price</p>
                    <p className="price">{convertNumberToCommaTwodecimal(sumTotalPrice)}</p>
                  </div>

                  {!dataCreating?.include_tax && (
                    <div className="row">
                      <Checkbox
                        className={'checkbox'}
                        onChange={(e) => handleChangeData(e, 'vat')}
                        checked={dataCreating?.vat}
                      />
                      <p>VAT 7%</p>
                      <p className="price">{convertNumberToCommaTwodecimal(priceVat)}</p>
                    </div>
                  )}

                  <div className="row">
                    <p>Total Price</p>
                    <p className="price">
                      {dataCreating?.include_tax
                        ? convertNumberToCommaTwodecimal(priceAfterDiscount)
                        : convertNumberToCommaTwodecimal(priceAfterDiscount + priceVat)}
                    </p>
                  </div>

                  {dataCreating?.include_tax && (
                    <div className={'include-vat-container'}>
                      <div className={'row'}>
                        <p>VAT 7%</p>
                        <p className="price">{convertNumberToCommaTwodecimal(priceVat)}</p>
                      </div>
                      <div className={'row'}>
                        <p>Price dose not include VAT</p>
                        <p className="price">{priceAfterVat}</p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="inner-right-bottom">
                  <div className="row">
                    <Checkbox
                      className={'checkbox'}
                      onChange={(e) => handleChangeData(e, 'withholding_tax')}
                      checked={dataCreating?.withholding_tax ? true : false}
                    />
                    <p>หักภาษี ณ ที่จ่าย</p>
                    {dataCreating?.withholding_tax && (
                      <DropdownSelect
                        className={'dropdown-withholding-tax'}
                        dataList={withHoldingTaxList}
                        onChange={(e) => handleChangeData(e, 'withholding_tax_percent')}
                        initValue={dataCreating?.withholding_tax_percent}
                        isError={validateInputData.is_error_withholding_tax_percent}
                      />
                    )}

                    <p className="price">{convertNumberToCommaTwodecimal(priceWithHoldingTax)}</p>
                  </div>
                  <div className="row">
                    <p>Net Price</p>
                    <p className="price">{convertNumberToCommaTwodecimal(summaryPrice)}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <ButtonOutline onClick={() => handleGoBack()}>Cancel</ButtonOutline>
        <div className="wrp-btn-right">
          <Button className="btn-preview" onClick={handleClickBtnPreview}>
            Preview
          </Button>
          <Button className="btn-create" onClick={handleClickBtnCreate}>
            {isEditPurchase ? 'Save' : 'Create'}
          </Button>
        </div>
      </div>

      {isShowDialogRequestApprove && (
        <SidePanel isOpen={isShowDialogRequestApprove} onClose={() => setIsShowDialogRequestApprove(false)} width={900}>
          <DocumentRequestApprove
            docType={saleDocumentData?.doc_type}
            onClickNavigationBack={() => setIsShowDialogRequestApprove(false)}
            id={saleDocumentData.id}
            onClickBtnSubmit={() => handleClickSubmitRequestApproval()}
          />
        </SidePanel>
      )}

      {/* preview and print */}
      {isShowDialogPreview && (
        <DialogDocument
          onClose={() => handleClickClosePreview()}
          docType={saleDocumentData?.doc_type || dataCreating?.doc_type}
          previewType={dialogPurchaseType}
          id={saleDocumentData.id}
          onDownloaded={() => setIsShowDialogPreview(false)}
          data={dataCreating}
          linkImage={imageForDocument}
          textCondition={textPurchaseCondition}
        />
      )}

      {isShowModalDownload && (
        <ModalBox
          className={'modal-download'}
          onClosed={() => setIsShowModalDownload(false)}
          height="200px"
          width="450px"
          title={'Download ' + saleDocumentData?.doc_id}
          body={<div className="body">ใบสั่งซื้อ</div>}
          footer={
            <div className="bottom-modal">
              <ButtonOutline className={'footer-btn btn-cancel'} onClick={() => setIsShowModalDownload(false)}>
                Cancel
              </ButtonOutline>
              <Button className={'footer-btn btn-download'} onClick={handleClickBtnDownload}>
                Download
              </Button>
            </div>
          }
        />
      )}

      {isShowModalShare && (
        <div className="document-share-and-send-email">
          <DocumentShareAndSendEmail
            type={currentDocType}
            data={saleDocumentData}
            onClickPreview={() => handleClickedPreview()}
            onClose={() => setIsShowModalShare(false)}
          />
        </div>
      )}

      {isShowFailedModal && (
        <DialogFail
          open={Boolean(isShowFailedModal)}
          onClose={() => setIsShowFailedModal(false)}
          onSubmit={() => setIsShowFailedModal(false)}
          icon={failIcon}
          title={'You may not fully use this feature, since you are not employee.'}
          nameItem={''}
          description={''}
          textYes="Ok"
        />
      )}
    </Styled>
  )
}

const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .mr-10 {
    margin-right: 10px !important;
  }
  .wrp-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 0 20px;
    border-bottom: 1px solid #ddd;
    h2 {
      margin: 0;
      font-size: 36px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    p {
      margin: 0;
      color: var(--Gray-600);
      font-size: 16px;
    }
    .header-text {
      text-transform: capitalize;
    }
  }

  .content-container {
    padding: 20px 0;
    flex: 1;
  }

  .w-100 {
    width: 100% !important;
  }

  .checkbox label {
    margin: 0;
  }

  .body {
    .row {
      display: flex;
      .right {
        padding-left: 24px;
      }
    }

    .wrp-input {
      margin-bottom: 24px;
      /* background-color: red; */

      p {
        margin-top: 0;
        margin-bottom: 6px;
      }
      .text-field,
      .dropdown-select,
      .search-text {
        width: 300px;
      }
      .address {
        height: 134px;
      }
    }
    .error {
      border-color: red !important;
    }
    .body-top {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--Gray-200);

      .input-left-top {
        /* border-bottom: 1px solid var(--Gray-200); */
        .input-text-area-address {
          font-size: 14px;
          height: 134px;
          border-color: var(--Gray-300);
          border-width: 1px;
          width: 300px;
        }
      }
      .input-left-bottom {
        padding-top: 24px;
      }

      .input-right {
        margin-left: 24px;
      }
    }
    //////
    .body-bottom {
      padding: 24px 0;

      .input-top {
        width: fit-content;
      }

      .wrp-table {
        margin-bottom: 26px;
        > div {
          overflow-x: inherit;
        }
      }
      .wrp-table-no-data {
        margin-bottom: 20px;
        > div {
          overflow-x: inherit;
        }
        thead {
          border-top: 1px solid red;
          border-left: 1px solid red;
          border-right: 1px solid red;
          tr {
            border: none;
          }
        }
        tbody {
          border-left: 1px solid red;
          border-right: 1px solid red;
          border-bottom: 1px solid red;

          tr {
            border: none;
          }
        }
      }
      //table input
      .input-table {
        width: 100%;
      }
      .btn-delete {
        cursor: pointer;
      }
      .verify {
        margin-top: 31px;
        display: flex;
        justify-content: space-between;

        .left {
          .wrp-checkbox-signature {
            display: flex;
            align-items: center;

            .checkbox {
              width: fit-content;
            }
            p {
              margin: 0;
            }
          }
          .wrp-detail {
            margin-top: 30px;
            p {
              margin: 0 0 6px;
            }
          }
          .input-text-area {
            font-size: 14px;
            border-color: var(--Gray-300);
            width: 577px;
            height: 112px;
          }
        }
        .right {
          .inner-right-top,
          .inner-right-bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .row {
              display: flex;
              align-items: center;
              /* margin-bottom: 5px; */
              p {
                margin: 0;
              }

              .checkbox {
                width: fit-content;
              }
              .price {
                margin-left: 100px;
              }
            }
            .text-field-percent {
              margin: 0 10px;
              width: 50px;
              height: 30px;
            }
          }

          .inner-right-top {
            border-bottom: 1px solid var(--Gray-200);

            .row {
              margin-bottom: 10px;
              align-items: center;
            }

            .include-vat-container {
              border-top: 1px solid var(--Gray-200);
              width: 100%;
              padding-top: 10px;
              align-items: flex-end;
              text-align: end;
              .row {
                width: 100%;
                justify-content: flex-end;
              }
            }
          }
          .inner-right-bottom {
            .row {
              margin-top: 10px;
              align-items: center;
            }
            .dropdown-withholding-tax {
              width: 90px;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--Gray-200);
    padding: 16px 24px;

    .btn-preview {
      margin-right: 12px;
    }
  }

  //Modal
  .modal-download {
    .bottom-modal {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .btn-cancel {
        width: 48%;
        justify-content: center;
      }
      .btn-download {
        width: 48%;
        justify-content: center;
      }
    }
  }

  .document-share-and-send-email {
    display: ${({ isHindModalShare }) => (isHindModalShare ? 'none' : 'block')};
  }
  .line-border {
    width: 100%;
    border-bottom: 1px solid var(--Gray-200);
    margin-bottom: 10px;
  }
`
