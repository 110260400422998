import React from 'react'

import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import Badge from './Badge'

const TabMenuContainer = styled.div`
  border-radius: 8px;
  border: 1px solid var(--Gray-200);
  background: var(--Gray-50);

  .menu {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--Gray-500);
    padding: 8px 12px;
    margin: 4px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      border-radius: 8px;
      color: var(--Gray-700);
      border: 1px solid var(--Gray-200);
      background-color: var(--Base-White);
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1), 0px 1px 2px 0px rgba(16, 24, 40, 0.06);
    }
  }

  .badge-style {
    padding: 0 8px;
    border-radius: 16px;
    border-color: var(--Gray-200);
    background-color: var(--Gray-50);
    color: var(--Gray-700);

    /* &.active {
      border-color: var(--Error-200);
      background-color: var(--Base-White);
      color: var(--Primary-700);
    } */
  }
`

function TabGroupMenu2({ tabMenuOptions, tabMenu, setTabMenu }) {
  return (
    <TabMenuContainer>
      <Stack direction="row" alignItems="center" spacing={2}>
        {tabMenuOptions.map((v) => (
          <Box>
            <div className={`menu ${tabMenu === v.value ? 'active' : ''}`} onClick={() => setTabMenu(v.value)}>
              {v.text}{' '}
              {v.number && <Badge className={`badge-style ${tabMenu === v.value ? 'active' : ''}`}>{v.number}</Badge>}
            </div>
          </Box>
        ))}
      </Stack>
    </TabMenuContainer>
  )
}

export default TabGroupMenu2
