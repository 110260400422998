import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { showLoading, hideLoading } from '../../redux/actions/loading'
import { setNotiEmailDocId } from '../../redux/actions/shareServiceEmployee'
import { setIsRedirect } from '../../redux/actions/master'

import { getAdminUser } from '../../utils/common'

function ReadDocFromEmail() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(showLoading())
  }, [])

  useEffect(() => {
    dispatch(setNotiEmailDocId(id))
    const user = getAdminUser()
    if (user) {
      navigate('/share-service/company-information')
    } else {
      dispatch(hideLoading())
      dispatch(setIsRedirect('/share-service/company-information'))
      navigate('/login')
    }
  }, [id])

  return <div></div>
}

export default ReadDocFromEmail
