import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import DialogConfirm from './DialogConfirm'

import iconBin from '../../assets/images/icon-bin-red-round.svg'

const StyleDialogConfirm = styled(DialogConfirm)`
  .dialog-button {
    button + button {
      background: var(--Error-600);
      border-color: var(--Error-600);
    }
  }
`

const DialogConfirmDelete = ({
  open,
  onClose,
  nameItem,
  title,
  description,
  description2,
  onSubmit,
  textYes,
  textNo,
  ...props
}) => {
  const { t } = useTranslation()
  return (
    <StyleDialogConfirm
      open={open}
      onClose={onClose}
      icon={iconBin}
      title={title}
      description={description}
      description2={description2}
      nameItem={nameItem}
      textYes={textYes ? textYes : t('delete')}
      textNo={textNo ? textNo : t('cancel')}
      onSubmit={onSubmit}
      {...props}
    />
  )
}

DialogConfirmDelete.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  nameItem: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  description2: PropTypes.string,
  textYes: PropTypes.string,
  textNo: PropTypes.string,
  onSubmit: PropTypes.func,
  subTitle: PropTypes.string,
}

export default DialogConfirmDelete
