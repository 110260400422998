import { Chip as MuiChip } from '@mui/material'
import { styled } from '@mui/system'

const Chip = ({ ...props }) => {
  return <StyledChip {...props} />
}

export default Chip

const StyledChip = styled(MuiChip)`
  display: flex;
  height: auto;
  width: fit-content;
  padding: 0.1rem 0.7rem 0.1rem 0.575rem;
  align-items: center;
  gap: 0.25rem;
  cursor: default;

  & .MuiChip-icon {
    margin-left: 0;
    margin-right: 0.175rem;
  }

  & .MuiChip-label {
    padding: 0;
    text-align: center;

    /* Text xs/Medium */
    font-family: 'Inter', 'Noto Sans Thai';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 150% */
  }

  &.MuiChip-colorPrimary,
  &.MuiChip-colorSuccess,
  &.MuiChip-colorInfo,
  &.MuiChip-colorWarning,
  &.MuiChip-colorError,
  &.MuiChip-colorDefault {
    border-radius: 1rem !important;
    color: var(--Gray-700);
    border: 1px solid var(--Gray-200);
    background: var(--Gray-50);
  }

  &.MuiChip-colorPrimary {
    color: var(--Primary-700);
    border: 1px solid var(--Primary-200) !important;
    background: var(--Primary-50) !important;
    mix-blend-mode: multiply;
  }

  &.MuiChip-colorSuccess {
    color: var(--Success-700, #067647);
    border: 1px solid var(--Success-200, #abefc6) !important;
    background: var(--Success-50, #ecfdf3) !important;
  }

  &.MuiChip-colorWarning {
    color: var(--Warning-700, #b54708);
    border: 1px solid var(--Warning-200, #fedf89) !important;
    background: var(--Warning-50, #fffaeb) !important;
  }

  &.MuiChip-colorError {
    color: var(--Error-700, #b00020);
    border: 1px solid var(--Error-200, #ff8a80) !important;
    background: var(--Error-50, #ffebee) !important;
  }

  &.MuiChip-colorInfo {
    color: var(--Blue-700, #175cd3);
    border: 1px solid var(--Blue-200, #b2ddff) !important;
    background: var(--Blue-50, #eff8ff) !important;
  }
`
