import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DateTimeHHmmssDisplay } from '../../../utils/common'

import styled from 'styled-components'
import Button from '../../form/button/Button'
import ButtonOutline from '../../form/button/ButtonOutline'
import ButtonOutlineCollapsible from '../../common/ButtonOutlineCollapsible'
import KPITableData from './KPITableData'
import NewAccordion from '../../common/NewAccordion'
import SidePanel from '../../common/SidePanel'
import EditKPIAnnoucement from '../../../components/feature/kpi-management/EditKPIAnnoucement'
import DialogConfirm from '../../../components/dialog/DialogConfirm'
import DialogConfirmDelete from '../../dialog/DialogConfirmDelete'
import DialogSuccess from '../../dialog/DialogSuccess'
import DialogFail from '../../dialog/DialogFail'

import { TbBuildingBank } from 'react-icons/tb'
import { CiMenuKebab } from 'react-icons/ci'
import { AiOutlineHistory } from 'react-icons/ai'
import { ImTree } from 'react-icons/im'
import { FaRegCircleCheck } from 'react-icons/fa6'
import { CgFileDocument } from 'react-icons/cg'
import flagIcon from '../../../assets/images/flag-icon.png'
import successIcon from '../../../assets/images/success-icon.png'
import failIcon from '../../../assets/images/fail-icon.png'

import { selectKPI } from '../../../redux/selectors'
import { deleteKPI, getKpiById, getKpiDraftList, publishKPI } from '../../../redux/actions/kpiManagement'
import EditingKpiHistoryLog from './EditingKpiHistoryLog'

const Div = styled.div`
  // box model style
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }
  .mr-10-px {
    margin-right: 10px;
  }
  .mr-12-px {
    margin-right: 12px;
  }
  .mr-16-px {
    margin-right: 16px;
  }

  // typo style
  h2,
  p {
    margin: 0;
  }
  .kpi-name {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .highlight-text {
    color: var(--Primary-600);
  }
  .date-text {
    color: var(--Gray-900);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .kpi-heading {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .kpi-des {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  // color
  .icon-color {
    color: var(--Primary-600);
  }
  .published-btn {
    border: 1px solid var(--success-600);
    background: var(--success-600);
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-center {
    align-items: center;
  }
`
const Badge = styled.div`
  display: inline-block;

  border-radius: 16px;
  border: 1px solid var(--warning-200);
  background: var(--warning-50);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: var(--warning-700);
  text-align: center;

  padding: 0 12px;
`
const NoDataContainer = styled.div`
  margin: 50px 0;
  text-align: center;

  .icon-container {
    display: inline-block;
    border-radius: 10px;
    background-color: var(--Primary-200);
    padding: 12px;
    margin-bottom: 16px;

    .icon-no-data {
      color: var(--Primary-700);
    }
  }
  .no-info-text {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
`

function KPIOrganizationDraft() {
  // external hook
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // initial data
  // initial data
  const listAction = [
    {
      text: 'Edit',
      action: () => {
        openEditingKPISidePanel()
      },
    },
    {
      text: 'Delete',
      action: () => {
        openConfirmDeletePublishKpi()
      },
    },
  ]
  const fetchKPIData = () => {
    dispatch(getKpiDraftList())
  }
  useEffect(() => {
    fetchKPIData()
  }, [])

  // kpi data
  const { kpiInfoDraft } = useSelector(selectKPI)
  const [kpiData, setKpiData] = useState()
  const [kpiOrganization, setKpiOrganization] = useState([])
  const [kpiDepartment, setKpiDepartment] = useState([])

  useEffect(() => {
    if (kpiInfoDraft[0]) {
      const { id, name, status, kpi_organizations, kpi_departments, createdAt, employee } = kpiInfoDraft[0]

      setKpiData({
        id,
        name,
        status,
        createdAt,
        createdBy: `${employee.first_name} ${employee.last_name}`,
      })
      setKpiOrganization(kpi_organizations)

      const newKpiDepartment = Object.entries(kpi_departments).map(([key, value]) => ({
        title: key,
        content:
          value.length > 0 ? (
            <KPITableData data={value} hasHeading={key} />
          ) : (
            <NoDataContainer>
              <div className="icon-container">
                <CgFileDocument className="icon-no-data" size={28} />
              </div>

              <div className="no-info-text">No information</div>
            </NoDataContainer>
          ),
        kpiList: value,
      }))
      setKpiDepartment(newKpiDepartment)
    } else {
      setKpiData()
      setKpiOrganization([])
      setKpiDepartment([])
    }
  }, [kpiInfoDraft])

  // dynamic width side panel
  const [widthWindow, setWidthWindow] = useState(window.innerWidth)
  const [widthSidePanel, setWidthSidePanel] = useState()
  const [widthHistoryLogSidePanel, setWidthHistoryLogSidePanel] = useState()

  useEffect(() => {
    const handleResize = () => setWidthWindow(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    // width side panel
    const newWidthSidePanel = widthWindow < 1024 ? widthWindow : widthWindow - 98
    setWidthSidePanel(newWidthSidePanel)

    // width history log side panel
    const newWidthHistoryLogSidePanel = widthWindow < 1024 ? widthWindow : (widthWindow * 35) / 100
    setWidthHistoryLogSidePanel(newWidthHistoryLogSidePanel)
  }, [widthWindow])

  // publish kpi
  const [confirmPublishKpi, setConfirmPublishKpi] = useState(false)

  const openConfirmPublishKpi = () => {
    setConfirmPublishKpi(true)
  }
  const closeConfirmPublishKpi = () => {
    setConfirmPublishKpi(false)
  }
  const onSubmitPublishKpi = () => {
    console.log('onSubmitPublishKpi')
    console.log('kpiInfoDraft', kpiInfoDraft[0])

    const request = {
      status: 'publish',
    }
    dispatch(publishKPI(kpiInfoDraft[0]?.id, request))

    closeConfirmPublishKpi()
  }

  // editing KPI sidepanel
  const [openEditKPISidePanel, setOpenEditKPISidePanel] = useState(false)
  const openEditingKPISidePanel = () => {
    dispatch(getKpiById(kpiData.id))
    setOpenEditKPISidePanel(true)
  }
  const closeEditingKPISidePanel = () => {
    setOpenEditKPISidePanel(false)
  }

  // delete
  const [confirmDeletePublishKpi, setConfirmDeletePublishKpi] = useState(false)
  const openConfirmDeletePublishKpi = () => {
    setConfirmDeletePublishKpi(true)
  }
  const closeConfirmDeletePublishKpi = () => {
    setConfirmDeletePublishKpi(false)
  }
  const onSubmitDeleteKpi = () => {
    const request = {
      status: 'archive',
    }
    dispatch(deleteKPI(kpiInfoDraft[0]?.id, request))

    closeConfirmDeletePublishKpi()
  }

  // history side panel
  const [openHistorySidePanel, setOpenHistorySidePanel] = useState(false)

  const openHistoryLog = () => {
    setOpenHistorySidePanel(true)
  }
  const closeHistoryLog = () => {
    setOpenHistorySidePanel(false)
  }

  // state, isLoading,success-fail modal
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const { state, isLoading } = useSelector(selectKPI)
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  useEffect(() => {
    if (state === 'PUBLISH_KPI.SUCCESS' && isLoading === false) {
      setSuccessMessageModal({
        headline: 'KPI has been published',
        message: '',
      })
      setSuccessModal(true)
    }
    if (state === 'PUBLISH_KPI.FAILURE' && isLoading === false) {
      setFailMessageModal({
        headline: 'KPI has been publised Fail',
        message: '',
      })
      setFailModal(true)
    }
  }, [state, isLoading])

  return (
    <Div>
      {/* header section */}
      <FlexContainer className="justify-content-space-between align-items-center mb-32-px">
        <h2 className="kpi-name">KPI Name : {kpiData && <span className="highlight-text">{kpiData?.name}</span>}</h2>
        {kpiData && (
          <div>
            <Badge className="mr-10-px">{kpiData?.status}</Badge>
            <span>
              <b className="date-text">{DateTimeHHmmssDisplay(kpiData.createdAt)}</b> by {kpiData.createdBy}
            </span>
          </div>
        )}
      </FlexContainer>

      {/* Organization KPI section */}
      <section className="mb-32-px">
        <FlexContainer className="justify-content-space-between mb-24-px">
          <FlexContainer className="align-items-center">
            <TbBuildingBank size={24} className="icon-color mr-16-px" />
            <div>
              <h2 className="kpi-heading mb-8-px">Organization KPI</h2>
              <p className="kpi-des">Organization goals</p>
            </div>
          </FlexContainer>
          {kpiData && (
            <FlexContainer className="align-items-center">
              <Button className="published-btn mr-12-px" append={<FaRegCircleCheck />} onClick={openConfirmPublishKpi}>
                Publish this version
              </Button>
              <ButtonOutlineCollapsible className="mr-12-px" icon={<CiMenuKebab />} list={listAction} />
              <ButtonOutline>
                <AiOutlineHistory size={20} onClick={openHistoryLog} />
              </ButtonOutline>
            </FlexContainer>
          )}
        </FlexContainer>

        {kpiOrganization.length > 0 ? (
          <KPITableData data={kpiOrganization} />
        ) : (
          <NoDataContainer>
            <div className="icon-container">
              <CgFileDocument className="icon-no-data" size={28} />
            </div>

            <div className="no-info-text">No information</div>
          </NoDataContainer>
        )}
      </section>

      {/* Department KPI section */}
      <section>
        <FlexContainer className="align-items-center mb-24-px">
          <ImTree size={24} className="icon-color mr-16-px" />
          <div>
            <h2 className="kpi-heading mb-8-px">Department KPI</h2>
            <p className="kpi-des">Department goals</p>
          </div>
        </FlexContainer>

        {kpiDepartment.length > 0 ? (
          <NewAccordion items={kpiDepartment} />
        ) : (
          <NoDataContainer>
            <div className="icon-container">
              <CgFileDocument className="icon-no-data" size={28} />
            </div>

            <div className="no-info-text">No information</div>
          </NoDataContainer>
        )}

        {/* <NewAccordion items={initialDepartments} /> */}
      </section>

      {/* Side Panel */}
      <SidePanel isOpen={openEditKPISidePanel} setIsOpen={setOpenEditKPISidePanel} width={widthSidePanel}>
        <EditKPIAnnoucement open={openEditKPISidePanel} onClose={closeEditingKPISidePanel} />
      </SidePanel>
      <SidePanel isOpen={openHistorySidePanel} setIsOpen={setOpenHistorySidePanel} width={widthHistoryLogSidePanel}>
        <EditingKpiHistoryLog refId={kpiData?.id} open={openHistorySidePanel} onClose={closeHistoryLog} />
      </SidePanel>

      {/* Dialog */}
      <DialogConfirm
        open={Boolean(confirmPublishKpi)}
        onClose={() => setConfirmPublishKpi(false)}
        onSubmit={onSubmitPublishKpi}
        icon={flagIcon}
        title={'Publish this KPI version?'}
        description={'KPI details will be shown to department manager'}
        textYes={'Publish now'}
        textNo={t('cancel')}
      />
      {/* Dialog */}
      <DialogConfirmDelete
        open={Boolean(confirmDeletePublishKpi)}
        onClose={() => setConfirmDeletePublishKpi(false)}
        onSubmit={onSubmitDeleteKpi}
        title={'Delete KPI'}
        description={'Are you sure you want to delete this KPI?'}
      />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default KPIOrganizationDraft
