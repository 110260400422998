import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { DateDisplay,TimeDisplayHHmm,DateTimeHHmmDisplay } from '../../utils/common'

import { getMeeting,ApproveMeetingAttend,GetMeetingInfo } from '../../redux/actions/dailysummary'

import { selectDailySummary } from '../../redux/selectors'

import Dropdown from '../../components/form/Dropdown'
import TextField from '../../components/form/TextField'
import InputTextArea from '../../components/widget/element/InputTextArea'
import Button from '../../components/form/button/Button'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'
import { useTranslation } from 'react-i18next'

const Div = styled.div`
font-size: 16px;
font-style: normal;
font-weight: 400;
margin: 50px 50px;
.header_css{
  text-align: center;
  .header_css_text_1{
    color: #6941C6;
    font-size: 16px;
    font-weight: 600;
  }
  .header_css_text_2{
    font-size: 30px;
    color: #101828;
    font-weight: 600;
    padding: 10px 0px;
  }
  .header_css_text_3{
    font-size: 20px;
    color: #475467;
  }
}
.box_1{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 20px;
  margin-top: 50px;
  margin-left: 50px;
  margin-right: 50px;
  .box_1_1{
    .menu-active {
      border: none;
      padding: 10px 0px;
      font-size: 16px;
      font-weight: 600;
      color:#000000;
      background-color: #FFFFFF;
    }
    .detail{
      display: grid;
      grid-template-columns: repeat(2, 25% [col-start] 75% [col-end]);
      margin: 10px 0px;
      .topic{
        font-size: 14px;
        font-weight: 600;
        color:#344054;
      }
      .topic_detail{
        font-size: 14px;
        color:#344054;
        white-space:pre-wrap;
        line-break: anywhere;
      }
    }
  }
  .box_1_2{
    .menu-active {
      border: none;
      padding: 10px 18px;
      font-size: 16px;
      font-weight: 600;
      border-bottom: 2px solid var(--Primary-600) !important;
      color: #6941C6;
      background-color: #F9F5FF;
    }
  }
}
.border_header{
  border-bottom: 1px solid #D0D5DD;
}
.margin_top_11{
  margin-bottom: 11px;
}
.margin_bottom_11{
  margin-bottom: 11px;
}
.link_view_map{
  color: #344054;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.topic_css{
  margin: 10px 0px;
  color: #344054;
  font-size: 14px;
  font-weight: 600;
}
.div_dd{
  margin: 10px 0px;
}
.div_text{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 20px;
  margin: 10px 0px;
  .topic_fill{
    font-size: 14px;
    color: #344054;
  }
  .TextField_employee {
    width: 100%;
  }
}
.margin_top_40{
  margin-top: 40px;
}
.InputTextArea_css {
  width: 100%;
  font-size: 14px;
}
.button_css{
  width: 100%;
  justify-content: center;
}
`

const ApproveMeeting = () => {
  const { meeting_attendances_id } = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  
  /*
  useEffect(() => {
    const filter = {
        skip: 1,
        limit: 10,
        meeting_attendances_id : meeting_attendances_id
    }
    dispatch(getMeeting(filter))
  }, [dispatch])
  */

  const { MeetingList,MeetingRoomList, limit, total, skip, isLoading } = useSelector(selectDailySummary)


  const [MeetingData, setMeetingData] = useState({
    meeting_id : null,
    response : null,
    full_name : null,
    position : null,
    email: null,
    phone_no: null,
    delegate_full_name : null,
    delegate_position : null,
    delegate_email: null,
    delegate_phone_no: null,
    note:null
  })
  const [MeetingDataDisplay, setMeetingDataDisplay] = useState({})
  const [Title, setTitle] = useState('')
  const [meeting_date, setmeeting_date] = useState(null)
  const [meeting_date_start, setmeeting_date_start] = useState(null)
  const [meeting_date_end, setmeeting_date_end] = useState(null)
  const [meeting_room_name, setmeeting_room_name] = useState(null)
  const [google_map_link, setgoogle_map_link] = useState(null)

  const [online_meeting_link, setonline_meeting_link] = useState(null)
  const [online_meeting_id, setonline_meeting_id] = useState(null)
  const [online_meeting_code, setonline_meeting_code] = useState(null)

  const [Attendee, setAttendee] = useState(null)
  const [project, setproject] = useState(null)
  const [agenda_detail, setagenda_detail] = useState(null)

  /*
  useEffect(() => {
    //
    //setMeetingDataDisplay(MeetingList[0])
    if(MeetingList.length > 0){
      const this_data = MeetingList[0];
      
      setTitle(this_data.title)
      setmeeting_date(this_data.meeting_date)
      setmeeting_date_start(this_data.meeting_date_start)
      setmeeting_date_end(this_data.meeting_date_end)
      setmeeting_room_name(this_data.meeting_room_name)
      setgoogle_map_link(this_data.google_map_link)
      setAttendee(this_data.meeting_attendances.data?.map((v_data) => v_data.attributes.full_name).join(','))
      setproject(this_data.project)
      setagenda_detail(this_data.agenda_detail)
      const this_meeting_attendances = this_data.meeting_attendances?.data?.filter((v) => v.id == meeting_attendances_id);
      
      if(this_meeting_attendances.length > 0){
        const this_data_meeting_attendances = this_meeting_attendances[0].attributes;
        
        setMeetingData({
          response : this_data_meeting_attendances.response,
          full_name : this_data_meeting_attendances.full_name,
          position : this_data_meeting_attendances.position,
          email: this_data_meeting_attendances.email,
          phone_no: this_data_meeting_attendances.phone_no,
          note: this_data_meeting_attendances.note,
        })
      }
      
    }
  }, [MeetingList])
  */
  useEffect(() => {
    const sent_data_api = {
      data: {
        meeting_attendance_id: meeting_attendances_id,
      },
    }
    dispatch(GetMeetingInfo(sent_data_api)).then(({ type,data_info,data_meta }) => {
      if(type.endsWith('_SUCCESS')){
        setTitle(data_info.title)
        setmeeting_date(data_info.meeting_date)
        setmeeting_date_start(data_info.meeting_date_start)
        setmeeting_date_end(data_info.meeting_date_end)
        setmeeting_room_name(data_info.meeting_room_name)
        setgoogle_map_link(data_info.google_map_link)

        setonline_meeting_link(data_info.online_meeting_link)
        setonline_meeting_id(data_info.online_meeting_id)
        setonline_meeting_code(data_info.online_meeting_code)

        setAttendee(data_info.meeting_attendances?.map((v_data) => v_data.full_name).join('<br/>'))
        setproject(data_info.project)
        setagenda_detail(data_info.agenda_detail)
        setMeetingData({
          meeting_id : data_info.meeting_id,
          response : data_info.attendance_response,
          full_name : data_info.attendance_full_name,
          position : data_info.attendance_position,
          email: data_info.attendance_email,
          phone_no: data_info.attendance_phone_no,
          note: data_info.attendance_note,
          delegate_full_name : data_info.delegate_full_name,
          delegate_position : data_info.delegate_position,
          delegate_email: data_info.delegate_email,
          delegate_phone_no: data_info.delegate_phone_no,
        })
      }
    })
  }, [dispatch])
  
  const response_option = [
    { text: 'Waiting for response', value: 'Waiting for response' },
    { text: 'yes, join in meeting', value: 'yes, join in meeting' },
    { text: 'yes, join virtually', value: 'yes, join virtually' },
    { text: 'Yes, by someone else', value: 'Yes, by someone else' },
    { text: 'no', value: 'no' },
  ]

  const onResponse = (v_inp) => {
    setMeetingData({ ...MeetingData, response: v_inp })
  }

  const OnLinkUrl = (Link_URL) => {
    //alert('OnLinkUrl' + Link_URL)
    window.open(Link_URL, '_blank', 'noreferrer');
  }
  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)
  const ProcessApproveMeeting = () => {
    const sent_data_api = {
      data: {
        meeting_id: MeetingData.meeting_id,
        meeting_attendances_id: meeting_attendances_id,
        response : MeetingData.response,
        full_name : MeetingData.response === 'Yes, by someone else' ? MeetingData.delegate_full_name : MeetingData.full_name,
        delegate_full_name : MeetingData.response === 'Yes, by someone else' ? MeetingData.delegate_full_name : null ,
        delegate_position : MeetingData.response === 'Yes, by someone else' ? MeetingData.delegate_position : null,
        delegate_email: MeetingData.response === 'Yes, by someone else' ? MeetingData.delegate_email : null,
        delegate_phone_no: MeetingData.response === 'Yes, by someone else' ? MeetingData.delegate_phone_no : null,
        note:MeetingData.note,
        from:"From email response"
      },
    }
    
    
    dispatch(ApproveMeetingAttend(sent_data_api)).then(({ type }) => {
      if(type.endsWith('_SUCCESS')){
        setTitlesuccess('Email response submitted successfully');
        setSubTitlesuccess('');
        setDescriptionsuccess(''); 
        setsuccessModal(true)
      }else{
        setTitleFail('This response has been submitted');
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }    
    })
  }


  return (
    <Div>
      <div className="header_css">
        <div className="header_css_text_1">{t('email_response_form')}</div>
        <div className="header_css_text_2">{Title}</div>
        <div className="header_css_text_3">{t('please_answer_the_meeting_participation_form')}</div>
      </div>
      <div className="box_1">
        <div className="box_1_1">
          <div >
              <button className="menu-active">
                {t('meeting_information')}
              </button>
          </div>
          <div className="border_header"></div>
          <div className="detail">
              <div className="topic">{t('date')}</div>
              <div className="topic_detail">{DateDisplay(meeting_date)}</div>
          </div>
          <div className="detail">
              <div className="topic">{t('time')}</div>
              <div className="topic_detail">{TimeDisplayHHmm(meeting_date_start)} - {TimeDisplayHHmm(meeting_date_end)}</div>
          </div>
          <div className="detail">
              <div className="topic">Location</div>
              <div className="topic_detail">
                <div>{meeting_room_name ? meeting_room_name : '-'}</div>
                {google_map_link && (
                  <div className="link_view_map" onClick={() => OnLinkUrl(google_map_link)}>view map</div>
                )}
                {online_meeting_link && (
                  <div className="link_view_map" onClick={() => OnLinkUrl(online_meeting_link)}>Meeting Link</div>
                )}
                {online_meeting_id && (
                  <div>ID : {online_meeting_id ? online_meeting_id : '-'}</div>
                )}
                {online_meeting_code && (
                  <div>Passcode : {online_meeting_code ? online_meeting_code : '-'}</div>
                )}
              </div>
          </div>
          <div className="detail">
              <div className="topic">{t('attendee')}</div>
              <div className="topic_detail"><div dangerouslySetInnerHTML={{ __html: Attendee }}></div></div>
          </div>
          <div className="detail">
              <div className="topic">{t('detail_agenda')}</div>
              <div className="topic_detail">
                <div>{project ? project : '-'}</div>
                <div dangerouslySetInnerHTML={{ __html: agenda_detail }}></div>
              </div>
          </div>

        </div>
        <div className="box_1_2">
          <div >
              <button className="menu-active">
                {t('response')}
              </button>
          </div>
          <div className="border_header"></div>
          <div className="topic_css">
            1. {t('can_you_attend_this_meeting')}
          </div>
          <div>
            {t('if_no_please_specify')}
          </div>
          <div className="div_dd">
                <Dropdown
                  id={`MeetingSpace`}
                  className="dd_employee"
                  value={MeetingData.response}
                  optionList={response_option}
                  onChange={onResponse}
                  disabled={false}
                  placeHolder={t('select')}
                  ValueplaceHolderIsNull={true}
                />
          </div>
          {MeetingData.response == 'Yes, by someone else' && (   
            <div>
              <div className="div_text">
                  <div>
                      <div className="topic_fill">{t('full_name')} *</div>
                      <div>
                          <TextField
                            name="Fullname"
                            className="TextField_employee"
                            placeholder={t('full_name')}
                            value={MeetingData.delegate_full_name}
                            onChange={(e) => setMeetingData({ ...MeetingData, delegate_full_name: e.target.value })}
                          />
                      </div>
                  </div>
              </div>
              <div className="div_text">
                  <div>
                      <div className="topic_fill">{t('email')} *</div>
                      <div>
                          <TextField
                            name="Email"
                            className="TextField_employee"
                            placeholder={t('email')}
                            value={MeetingData.delegate_email}
                            onChange={(e) => setMeetingData({ ...MeetingData, delegate_email: e.target.value })}
                          />
                      </div>
                  </div>
                  <div>
                      <div className="topic_fill">{t('tel')} *</div>
                      <div>
                          <TextField
                            name="Tel"
                            className="TextField_employee"
                            placeholder={t('tel')}
                            value={MeetingData.delegate_phone_no}
                            onChange={(e) => setMeetingData({ ...MeetingData, delegate_phone_no: e.target.value })}
                          />
                      </div>
                  </div>
              </div>
            </div>
          )}

          <div className="topic_css margin_top_40">
            2. {t('additional_note_to_attendee')}
          </div>
          <div>
            {t('if_you_have_additional_question')}
          </div>
          <div className="div_dd">
              <InputTextArea
                  className="InputTextArea_css"
                  initValue={MeetingData.note}
                  onChange={(e) => setMeetingData({ ...MeetingData, note: e.target.value })}                        
                  placeHolder={t('meeting_note_to_attendee_placeholder')}
                  borderColor={'var(--Gray-300)'}
                  borderWidth="1px"
              />
          </div>
          <div className="margin_top_40">
            <Button className="button_css" onClick={ProcessApproveMeeting}>
              {t('submit')}
            </Button>
          </div>
        </div>
      </div>

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => setsuccessModal(false)}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />
    </Div>
  )
}

export default ApproveMeeting
