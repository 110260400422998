import styled from 'styled-components'

import Button from './Button'

const ButtonOutline = styled(Button)`
  color: var(--Tertiary-Gray-Button-Font);
  background: var(--Tertiary-Gray-Button-Bg);
  border-color: var(--Tertiary-Gray-Button-Stroke);

  :hover {
    background: var(--Tertiary-Gray-Button-Hover);
    color: var(--Tertiary-Gray-Button-Font);
  }
`

export default ButtonOutline
