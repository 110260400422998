import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DateDisplay, DateTimeHHmmDisplay,GetPermissionOther } from '../../utils/common'
import { convertDateFormat } from '../../utils/helper'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import OptionBox from '../../components/widget/combo/OptionBox'
import SearchText from '../../components/form/SearchText'
import TextField from '../../components/form/TextField'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import DropdownSelect from '../../components/widget/element/DropDownSelect'
import ImageButton from '../../components/widget/element/ImageButton'
import IconChevronLeft from '../../components/widget/icon/IconChevronLeft'
import IconUser from '../../components/widget/icon/IconUser'
import IconXClose from '../../components/widget/icon/IconXClose'
import Divider from '@mui/material/Divider'
import DeltaToHtml from '../../components/widget/combo/DeltaToHtml'
import { Avatar, Box, Stack } from '@mui/material'
import Card from 'components/common/Card'
import CapsuleStatus from 'components/widget/element/CapsuleStatus'
import NoteTask from 'components/feature/task/NoteTask'

import Modal from '../../components/common/Modal'
import DialogConfirm from '../../components/dialog/DialogConfirm'
import DialogTaskEditor from '../../components/dialog/DialogTaskEditor'
import DialogTaskPreviewer from '../../components/dialog/DialogTaskPreviewer'
import DialogProject from '../../components/feature/jobOrder/project/DialogProject'
import DialogFail from '../../components/dialog/DialogFail'
import TableProjectTask from '../../components/feature/table/TableProjectTask'
import MilestoneSidePanel from './MilestoneSidePanel'
import SidePanel from 'components/common/SidePanel'
import AutoComplete from 'components/form/AutoComplete'

import { useDispatch, useSelector } from 'react-redux'
import { selectProjectTask, selectTaskEmployee } from '../../redux/selectors'
import {
  createProgressUpdate,
  createTaskTemplate,
  deleteMilestone,
  editProgressUpdate,
  getAssignedEmployeeByProjectTask,
  getHistoryProgressUpdate,
  getLastestProgressUpdate,
  getMilestoneScopeByProjectId,
  getProgressUpdateById,
  getProjectTaskById,
  getScopeWorkByPositionId,
  getTaskTypeByTaskScope,
} from '../../redux/actions/projectTask'
import { getTaskEmployee } from '../../redux/actions/taskEmployee'

import { FaPlus, FaRegCircleCheck, FaRegFlag } from 'react-icons/fa6'
import { LiaTimesSolid } from 'react-icons/lia'
import failIcon from '../../assets/images/fail-icon.png'

export default function MileStone({ onClickBack, id }) {
  //--- ST ส่วนของ Permission LV3-4
  const initLang = localStorage.getItem('lang')
  const permissionEditProject = GetPermissionOther('project','Task_Project_EditProject');
  //--- ED ส่วนของ Permission LV3-4
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { projectTaskData } = useSelector(selectProjectTask)
  const { taskEmployeeList } = useSelector(selectTaskEmployee)
  const statusList = [
    {
      text: t('all_status'),
      value: -1,
    },
    {
      text: t('open'),
      value: 'Open',
    },
    {
      text: t('working'),
      value: 'Working',
    },
    {
      text: t('complete'),
      value: 'Complete',
    },
  ]
  const [createdByList, setCreatedByList] = useState([])

  const [searchTable, setSearchTable] = useState({
    owner: employee_info?.id,
    search: '',
    projectSelected: '',
    milestoneSelected: '',
    scopeOfWorkSelected: '',
    statusSelected: '',
    createBySelected: '',
    assignTo: '',
  })

  const { projectList, milestoneScopeList, taskTypeDataList } = useSelector(selectProjectTask)
  const [menuTabList, setMenuTabList] = useState([
    {
      name: t('overview'),
      value: 'overview',
      active_status: true,
    },
    {
      name: t('task'),
      value: 'task',
      active_status: false,
    },
    {
      name: t('note'),
      value: 'note',
      active_status: false,
    },
    // {
    //   name: 'Document',
    //   value: 'document',
    //   active_status: false,
    // },
    // {
    //   name: 'Message',
    //   value: 'message',
    //   active_status: false,
    // },
    // {
    //   name: 'KPI',
    //   value: 'kpi',
    //   active_status: false,
    // },
  ])
  const [menuTeamTabList, setMenuTeamTabList] = useState([
    {
      name: t('manager'),
      value: 'manager',
      active_status: true,
    },
    {
      name: t('team'),
      value: 'team',
      active_status: false,
    },
  ])
  const [optionItemMilestoneList, setOptionItemMilestoneList] = useState([
    // { id: 'Edit', text: 'edit' },
    // { id: 'Update Status', text: 'update-status' },
    { id: 'Delete', text: 'delete' },
  ])
  const [milestoneList, setMilestoneList] = useState([])
  const [scopeOfWorkList, setScopeOfWorkList] = useState([])
  const [projectListState, setProjectListState] = useState([])

  //Tap document//
  const [currentMenuTabSelected, setCurrentMenuTabSelected] = useState('overview')
  const [currentMenuTeamTabSelected, setCurrentMenuTeamTabSelected] = useState('manager')
  const [projectTaskInfo, setProjectTaskInfo] = useState()
  const [isDataUpdated, setIsDataUpdated] = useState(false)
  const [isShowSidePanel, setIsShowSidePanel] = useState(false)
  const [currentMileStoneType, setCurrentMileStoneType] = useState('create_milestone')
  const [milestoneId, setMilestoneId] = useState('')

  const [isDataChange, setIsDataChange] = useState(false)
  const [currentTaskType, setCurrentTaskType] = useState('')
  const [currentMilestoneIdSelected, setCurrentMilestoneIdSelected] = useState('')

  //modal//
  const [isShowNewTaskModal, setIsShowNewTaskModal] = useState(false)
  const [isShowModalSave, setIsShowModalSave] = useState(false)

  const [isShowModalConfirm, setIsShowModalConfirm] = useState(false)
  const [confirmModalType, setConfirmModalType] = useState('')
  const [textTitleConfirmModal, setTextTitleConfirmModal] = useState('')
  const [textDescConfirmModal, setTextDescConfirmModal] = useState('')

  const [templateName, setTemplateName] = useState('')
  const [isErrorTemplateName, setIsErrorTemplateName] = useState(false)

  const [isShowDialogProject, setIsShowDialogProject] = useState(false)

  const [taskId, setTaskId] = useState('')
  const [isShowPreviewer, setIsShowPreviewer] = useState(false)
  const [projectType, setProjectType] = useState('General')
  const [isClickedTaskFromMilestone, setIsClickedTaskFromMilestone] = useState(false)
  const [currentMilestoneTemplateData, setCurrentMilestoneTemplateData] = useState()
  const [isGetEmployee, setIsGetEmployee] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (id) {
        let tmpSearchTable = searchTable
        tmpSearchTable.projectSelected = id
        setSearchTable(tmpSearchTable)
        const projectInfoRes = await dispatch(getProjectTaskById(id))
        if (projectInfoRes?.data) {
          let tmpProjectTaskData = projectInfoRes.data
          if (Object.keys(tmpProjectTaskData).length > 0) {
            // if(tmpProjectTaskData.task_template_ref) {
            //   // PROJECT BY TEMPLATE
            // setProjectType('Template')
            //   const tmpTaskTemplateRes = await dispatch(getTaskTemplateById(tmpProjectTaskData.task_template_ref.id))
            //   if(tmpTaskTemplateRes?.data) {
            //     let tmpTemplateInfo = tmpTaskTemplateRes.data;
            //     let tmpMilestoneList = []
            //     let milestoneTemplateList = [...tmpTemplateInfo?.milestones]

            //     if(milestoneTemplateList.length > 0) {
            //       for(let i = 0; i < milestoneTemplateList.length; i++) {
            //         let tmpMilestoneScopeWorks = []
            //         let tmpMilestoneTemplateScope = [...milestoneTemplateList[i]?.scope_works]

            //         if(tmpMilestoneTemplateScope.length > 0) {
            //           let tmpMilestoneTask = []
            //           for(let indexScope = 0; indexScope < tmpMilestoneTemplateScope.length; indexScope++) {
            //             let tmpScopeWorkItem = tmpMilestoneTemplateScope[indexScope]
            //             let tmpTaskTypes = [...tmpScopeWorkItem?.task_types]
            //             let tmpPositionId = tmpScopeWorkItem?.position_id || '';

            //             let tmpPositionData;
            //             if(tmpPositionId) {
            //               const pagination = {
            //                 page: 1,
            //                 pageSize: 1000,
            //               }
            //               const tempRes = await dispatch(getScopeWorkByPositionId(tmpPositionId, pagination))
            //               if(tempRes?.data) {

            //                 tmpPositionData = tempRes.data[0];
            //               }
            //             }

            //             if(tmpTaskTypes.length > 0) {
            //               for(let indexTask = 0; indexTask < tmpTaskTypes.length; indexTask++) {
            //                 let tmpTaskObj = {
            //                   id: "",
            //                   cc: "",
            //                   value: "",
            //                   due_date: "",
            //                   detail: "",
            //                   task_type: tmpTaskTypes[indexTask]
            //                 }

            //                 tmpMilestoneTask.push(tmpTaskObj)
            //               }
            //             }

            //             delete tmpScopeWorkItem['task_types']
            //             delete tmpScopeWorkItem['position_id']

            //             let tmpScopeObj = {
            //               id: "",
            //               cc: "",
            //               value: "",
            //               due_date: "",
            //               // position_id: tmpPositionId,
            //               position: tmpPositionData,
            //               task_scope: tmpScopeWorkItem,
            //               milestone_task_types: [...tmpMilestoneTask]
            //             }
            //             tmpMilestoneScopeWorks.push(tmpScopeObj)
            //           }
            //         } else {

            //           tmpMilestoneScopeWorks = []
            //         }

            //         let tmpMilestoneItem = {
            //           name: milestoneTemplateList[i].name,
            //           due_date: milestoneTemplateList[i].due_date,
            //           milestone_scope_works: [...tmpMilestoneScopeWorks]
            //         }
            //         tmpMilestoneList.push(tmpMilestoneItem)
            //       }
            //     }

            //     tmpProjectTaskData.milestones = [...tmpMilestoneList]

            // setProjectTaskInfo(tmpProjectTaskData)
            //   }
            // } else {
            // setProjectTaskInfo(tmpProjectTaskData)
            // setProjectType('General')
            // }

            if (tmpProjectTaskData.task_template_ref) {
              setProjectType('Template')
            } else {
              setProjectType('General')
            }
            setProjectTaskInfo(tmpProjectTaskData)
          }
        }
      }
    })()
  }, [id])

  useEffect(() => {
    if (!isGetEmployee) {
      dispatch(getTaskEmployee())
      setIsGetEmployee(true)
    }
  }, [dispatch])

  useEffect(() => {
    if (Object.keys(projectTaskData).length > 0 && isDataUpdated) {
      setProjectTaskInfo(projectTaskData)
    }
  }, [projectTaskData, isDataUpdated])

  useEffect(() => {
    if (projectList?.length > 0) {
      let tmpList = [{ text: 'All projects', value: -1 }]
      for (let i = 0; i < projectList.length; i++) {
        let tmpObj = {
          text: projectList[i].name,
          value: projectList[i].id,
        }
        tmpList.push(tmpObj)
      }
      setProjectListState([...tmpList])
    }
  }, [projectList])

  useEffect(() => {
    if (id) {
      dispatch(getMilestoneScopeByProjectId(id))
      setMilestoneList([{ text: t('all_milestones'), id: -1 }])
      setScopeOfWorkList([{ text: t('all_scope_of_works'), id: -1 }])
    }
  }, [])

  useEffect(() => {
    if (taskEmployeeList?.length > 0) {
      let tmpList = [{ text: t('all_created_by'), value: -1 }]
      let tmpAssigntoList = []
      for (let i = 0; i < taskEmployeeList.length; i++) {
        let tmpObj = {
          text: taskEmployeeList[i].first_name + ' ' + taskEmployeeList[i].last_name,
          value: taskEmployeeList[i].id,
        }
        tmpList.push(tmpObj)
        tmpAssigntoList.push(tmpObj)
      }
      setCreatedByList([...tmpList])
    }
  }, [taskEmployeeList])

  useEffect(() => {
    let tmpList = [{ text: t('all_milestones'), value: -1 }]
    if (milestoneScopeList?.length > 0) {
      for (let i = 0; i < milestoneScopeList.length; i++) {
        let tmpTaskScope = milestoneScopeList[i]?.milestone
        let tmpObj = {
          text: tmpTaskScope?.name,
          value: tmpTaskScope?.id,
        }
        tmpList.push(tmpObj)
      }
    }
    setMilestoneList([...tmpList])
  }, [milestoneScopeList])

  useEffect(() => {
    let tmpList = [{ text: t('all_scope_of_works'), value: -1 }]
    if (taskTypeDataList?.length > 0) {
      for (let i = 0; i < taskTypeDataList.length; i++) {
        let tmpObj = {
          text: taskTypeDataList[i]?.name,
          value: taskTypeDataList[i].id,
        }
        tmpList.push(tmpObj)
      }
    }
    setScopeOfWorkList([...tmpList])
  }, [taskTypeDataList])

  const handleClickBack = () => {
    if (onClickBack) {
      onClickBack()
    }
  }
  // Tab bar
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
      } else {
        tmpList[i].active_status = false
      }
    }
    setCurrentMenuTabSelected(data.value)
    setMenuTabList([...tmpList])
  }
  // Tab bar team
  const handleClickTaskMenuTeamTab = (data) => {
    let tmpList = [...menuTeamTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
      } else {
        tmpList[i].active_status = false
      }
    }
    setCurrentMenuTeamTabSelected(data.value)
    setMenuTeamTabList([...tmpList])
  }

  const handleClickBtnAddMilestone = () => {
    setCurrentMileStoneType('create_milestone')
    setIsShowSidePanel(true)
  }
  const handleClickItemMileStone = async (dataItem) => {
    // if(projectType == "General") {

    //   setMilestoneId(dataItem.id)
    //   setCurrentMileStoneType('view_milestone')
    // } else {
    //   // PROJECT_TEMPLATE ----

    //   setMilestoneId('')
    //   setCurrentMilestoneTemplateData(dataItem)
    //   setCurrentMileStoneType('view_milestone_template')
    // }

    if (dataItem.is_template) {
      // TEMPLATE
      let tmpObj = { ...dataItem }
      let tmpTemplateScopeList = [...dataItem.template_scope_works]
      let tmpScopeWorkList = []
      if (tmpTemplateScopeList.length > 0) {
        for (let i = 0; i < tmpTemplateScopeList.length; i++) {
          let tmpPositionId = tmpTemplateScopeList[i].position_ref.id
          let tmpPositionData
          if (tmpPositionId) {
            const pagination = {
              page: 1,
              pageSize: 1000,
            }
            const tempRes = await dispatch(getScopeWorkByPositionId(tmpPositionId, pagination))
            if (tempRes?.data) {
              tmpPositionData = tempRes.data[0]
            }
          }
          if (tmpTemplateScopeList[i]) {
            let tmpScopeItemObj = {
              id: tmpTemplateScopeList[i].id,
              cc: '',
              value: '',
              due_date: '',
              position: tmpPositionData,
              task_scope: tmpTemplateScopeList[i].task_scope,
              milestone_task_types: [...tmpTemplateScopeList[i].task_types],

              createdAt: tmpTemplateScopeList[i].createdAt,
              updatedAt: tmpTemplateScopeList[i].updatedAt,
              publishedAt: tmpTemplateScopeList[i].publishedAt,
            }
            tmpScopeWorkList.push(tmpScopeItemObj)
          }
        }
      }
      tmpObj.milestone_scope_works = [...tmpScopeWorkList]
      delete tmpObj['template_scope_works']

      setCurrentMilestoneTemplateData(tmpObj)
      setCurrentMileStoneType('view_milestone_template')
    } else {
      // GENERAL
      setMilestoneId(dataItem.id)
      setCurrentMileStoneType('view_milestone')
    }
    setIsShowSidePanel(true)
  }
  const handleSelectOptionItemMilestone = (itemData, index) => {
    // if (index == 0) {

    // } else if (index == 1) {

    // } else if (index == 2) {
    setCurrentMilestoneIdSelected(itemData.id)
    setConfirmModalType('delete_milestone')
    setTextTitleConfirmModal('Delete Confirmation')
    setTextDescConfirmModal(
      'Tasks created under this milestone will be removed upon confirmation. Are you sure you wish to proceed with deletion ?'
    )
    setIsShowModalConfirm(true)
    // }
  }
  const handleEditMilestone = () => {
    //ส่ง id milestone ไปด้วย
    if (projectType == 'General') {
      setCurrentMileStoneType('edit_milestone')
    } else {
      if (currentMileStoneType == 'view_milestone_template') {
        //view_milestone_template
        setCurrentMileStoneType('edit_milestone_template')
      } else {
        // view_milestone
        setCurrentMileStoneType('edit_milestone')
      }
    }
  }
  const handleDataChange = async () => {
    await dispatch(getProjectTaskById(id)).then(() => {
      setIsShowSidePanel(false)
      setIsShowDialogProject(false)
      setIsShowPreviewer(false)
      setIsDataUpdated(true)
    })
  }

  const handleClickBtnSave = () => {
    setIsShowModalSave(true)
  }
  const handleClickedSubmitModal = async () => {
    if (confirmModalType == 'delete_milestone') {
      const data = { status: 'Delete' }
      const request = { data }
      dispatch(deleteMilestone(currentMilestoneIdSelected, request)).then(() => {
        dispatch(getProjectTaskById(id)).then(() => {
          setIsShowModalConfirm(false)
          setConfirmModalType('')
          setIsDataUpdated(true)
        })
      })
    }
  }

  //Filter header//
  const handleDropdownSelected = async (value, type) => {
    if (type == 'milestone') {
      setSearchTable({ ...searchTable, milestoneSelected: value, scopeOfWorkSelected: '' })
      if (value >= 0) {
        await dispatch(getTaskTypeByTaskScope(value))
      }
      setScopeOfWorkList([{ text: 'All scope of works', value: -1 }])
    } else if (type == 'scope_of_work') {
      setSearchTable({ ...searchTable, scopeOfWorkSelected: value })
    } else if (type == 'status') {
      setSearchTable({ ...searchTable, statusSelected: value })
    } else if (type == 'create_by') {
      setSearchTable({ ...searchTable, createBySelected: value })
    } else if (type == 'assign_to') {
      setSearchTable({ ...searchTable, assignTo: value })
    }
    setIsDataChange(!isDataChange)
  }

  const handleClickedConfirm = () => {
    if (templateName != '') {
      let tmpObj = {
        task_id: id,
        name: templateName,
        company_id: company_info?.id,
        created_by: employee_info?.id,
      }

      const data = { ...tmpObj }
      const request = { data }
      dispatch(createTaskTemplate(request))
      setIsShowModalSave(false)
    } else {
      setIsErrorTemplateName(true)
    }
  }
  const handleClickedTask = (id) => {
    setTaskId(id)
    setIsShowPreviewer(true)
  }

  const handleClickedClearAll = () => {
    let tmpObj = {
      owner: employee_info?.id,
      search: '',
      projectSelected: id,
      milestoneSelected: '',
      scopeOfWorkSelected: '',
      statusSelected: '',
      createBySelected: '',
      assignTo: '',
    }
    setSearchTable(tmpObj)
    setIsDataChange(!isDataChange)
  }

  const convertToDelta = (desc) => {
    if (desc != '') {
      //
      let tmpConvertDeltaContent = desc?.substring(0, 1)
      if (tmpConvertDeltaContent == '[') {
        return <DeltaToHtml contents={desc} />
      }
    }
    return desc
  }

  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const OpenIsShowDialogProject = () => {
    if (permissionEditProject?.canUpdate === true) {
      setIsShowDialogProject(true)
    } else {
      let message_permission = 'No permission allowed ' + permissionEditProject?.name_en
      if (initLang == 'th') {
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionEditProject?.name_th
      }
      setTitleFail(message_permission)
      setnameItemFail('')
      setDescriptionFail('')
      setFailModal(true)
    }
  }

  // latest,history progress udpate
  const { lastestProgressUpdate, historyProgressUpdate, progressUpdateInfo } = useSelector(selectProjectTask)
  const [tab, setTab] = useState('latest')

  const fetchLatestProgressUpdate = useCallback(
    (id) => {
      dispatch(getLastestProgressUpdate(id))
    },
    [dispatch]
  )
  const fetchHistoryProgressUpdate = useCallback(
    (id) => {
      dispatch(getHistoryProgressUpdate(id))
    },
    [dispatch]
  )
  const toggleTab = (tab) => {
    setTab(tab)
  }

  useEffect(() => {
    if (tab === 'latest') fetchLatestProgressUpdate(id)
    else fetchHistoryProgressUpdate(id)
  }, [tab, id])

  // create progress update side panel
  const [isOpenCreateProgressUpdate, setIsOpenCreateProgressUpdate] = useState(false)

  const openCreateProgressUpdate = () => {
    setIsOpenCreateProgressUpdate(true)
  }
  const closeCreateProgressUpdate = () => {
    setIsOpenCreateProgressUpdate(false)
  }
  const onSubmitCreateProgressUpdate = () => {
    const request = {
      ...form,
    }
    dispatch(createProgressUpdate(request))
    closeCreateProgressUpdate()
    clearFormCreate()
  }

  // edit progress update
  const [isOpenEditProgressUpdate, setIsOpenEditProgressUpdate] = useState(false)

  const fetchProgressUpdateById = useCallback(
    (id) => {
      dispatch(getProgressUpdateById(id))
    },
    [dispatch]
  )
  const openEditProgressUpdate = (id) => {
    fetchProgressUpdateById(id)
    setIsOpenEditProgressUpdate(true)
  }
  const closeEditProgressUpdate = () => {
    setIsOpenEditProgressUpdate(false)
  }

  // form
  const initialForm = {
    status_update: '',
    challenges_issue: '',
    next_step: '',
    estimate_completion: '',
    decision_point: '',
    other_comment: '',
    project_task: id,
  }

  const [form, setForm] = useState(initialForm)
  const [editForm, setEditForm] = useState(initialForm)
  const [isEditMode, setIsEditMode] = useState(false)

  const clearFormCreate = () => {
    setForm(initialForm)
  }
  const onChangeForm = (event) => {
    const { name, value } = event.target
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeFormEdit = (event) => {
    const { name, value } = event.target
    setEditForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const toggleIsEditMode = () => {
    setIsEditMode(!isEditMode)
  }
  const cancelEdit = () => {
    toggleIsEditMode()
  }
  const onSubmitEditProgressUpdate = () => {
    const request = {
      ...editForm,
    }
    dispatch(editProgressUpdate(progressUpdateInfo.id, request))
    toggleIsEditMode()
  }

  useEffect(() => {
    if (Object.keys(progressUpdateInfo).length > 0) {
      const { status_update, challenges_issue, next_step, estimate_completion, decision_point, other_comment } =
        progressUpdateInfo
      setEditForm((prevState) => ({
        ...prevState,
        status_update,
        challenges_issue,
        next_step,
        estimate_completion,
        decision_point,
        other_comment,
      }))
    }
  }, [progressUpdateInfo])

  // assigned employee list
  const { assignedEmployee } = useSelector(selectProjectTask)
  const [assignToList, setAssignToList] = useState([])

  const fetchAssignedEmployee = useCallback(() => {
    dispatch(getAssignedEmployeeByProjectTask(id))
  }, [dispatch, id])

  useEffect(() => {
    fetchAssignedEmployee()
  }, [])

  useEffect(() => {
    const newAssignedEmployees = assignedEmployee.map((v) => ({
      text: v.name,
      value: v.value,
    }))
    setAssignToList(newAssignedEmployees)
  }, [assignedEmployee])

  return (
    <>
      <PageStyled>
        <header>
          <div className="content-title">
            <ImageButton icon={<IconChevronLeft />} size={60} onClick={handleClickBack} />
            <div className="txt-title">{projectTaskInfo?.name}</div>
          </div>
          <div className="date-filter-container mt-1-rem">
            <div className="wrp-tab-menu">
              {menuTabList?.map((item, index) => (
                <button
                  onClick={() => handleClickTaskMenuTab(item)}
                  key={'btn-' + index}
                  className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        </header>
        {currentMenuTabSelected == 'overview' && (
          <Box mt={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
              <Box>
                <Card className="mr-1-rem">
                  <Box mb={1} className="heading-card">
                    Project Progress
                  </Box>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <span className="percent-data">{projectTaskInfo?.progress}%</span>
                    <CapsuleStatus status={projectTaskInfo?.status} />
                  </Stack>
                </Card>

                <Card className="mr-1-rem">
                  <Box mb={1} className="heading-card">
                    Total Time Left (Days)
                  </Box>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <span className={`percent-data ${projectTaskInfo?.daysLeft < 0 ? 'over-due' : ''}`}>
                      {projectTaskInfo?.daysLeft ? projectTaskInfo?.daysLeft : ''}
                    </span>
                  </Stack>
                </Card>

                <Card>
                  <Box mb={1} className="heading-card">
                    End Date
                  </Box>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <span className="percent-data">
                      {projectTaskInfo?.end_date ? DateDisplay(projectTaskInfo?.end_date) : ''}
                    </span>
                  </Stack>
                </Card>
              </Box>
              <Stack direction="row" spacing={2}>
                <Button onClick={handleClickBtnSave}>Save Milestone Template</Button>
                {permissionEditProject?.canRead === true && (
                  <Button className="btn-edit" onClick={() => OpenIsShowDialogProject()}>
                    Edit
                  </Button>
                )}
              </Stack>
            </Stack>

            <div className="wrp-content">
              <div className="content-left">
                <div className="title-content">{t('description')}</div>
                <div className="content-description">
                  <div className="project-type">
                    {t('project_type')} : {projectTaskInfo?.type}
                  </div>
                  <div className="detail-project">{convertToDelta(projectTaskInfo?.description)}</div>
                </div>
                <div className="title-content">{t('team')}</div>
                <div className="content-team">
                  <div className="wrp-tab-menu">
                    {menuTeamTabList?.map((item, index) => (
                      <button
                        onClick={() => handleClickTaskMenuTeamTab(item)}
                        key={'btn-' + index}
                        className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                      >
                        {item.name}
                      </button>
                    ))}
                  </div>
                  {currentMenuTeamTabSelected == 'manager' && (
                    <div className="inner-content-team">
                      <div className="left">
                        <div className="icon-user">
                          {projectTaskInfo?.project_manager?.employee_image != '' ? (
                            <img
                              className="img-profile-person"
                              src={projectTaskInfo?.project_manager?.employee_image}
                            />
                          ) : (
                            <IconUser width="35" />
                          )}
                        </div>
                        <div className="wrp-name-class">
                          <div className="name">
                            {projectTaskInfo?.project_manager?.first_name +
                              ' ' +
                              projectTaskInfo?.project_manager?.last_name}
                          </div>
                          <div className="class">
                            {projectTaskInfo?.project_manager?.department?.name}{' '}
                            {projectTaskInfo?.project_manager?.position?.name}
                          </div>
                        </div>
                      </div>
                      {/* <div className="right">
                        <Button>View Profile</Button>
                      </div> */}
                    </div>
                  )}
                  {currentMenuTeamTabSelected == 'team' && (
                    <>
                      {projectTaskInfo?.project_task_teams?.map((item, index) => (
                        <div className="inner-content-team" key={'person-in-team-' + index}>
                          <div className="left">
                            <div className="icon-user">
                              {item?.employee_image != '' ? (
                                <img className="img-profile-person" src={item?.employee_image} alt="team member" />
                              ) : (
                                <IconUser width="35" />
                              )}
                            </div>
                            <div className="wrp-name-class">
                              <div className="name">{item?.first_name + ' ' + item?.last_name}</div>
                              <div className="class">{item?.position}</div>
                            </div>
                          </div>
                          {/* <div className="right">
                            <Button>View Profile</Button>
                          </div> */}
                        </div>
                      ))}
                    </>
                  )}
                </div>

                <Box mt={2}>
                  <div className="title-content">Progress Update</div>

                  <Divider sx={{ mb: 2 }} />
                  <Card className="w-card-100">
                    <Stack direction="row" justifyContent="space-between">
                      <TabMenuContainer>
                        <span
                          className={`tab-menu ${tab === 'latest' ? 'active' : ''}`}
                          onClick={() => toggleTab('latest')}
                        >
                          {t('latest')}
                        </span>
                        <span
                          className={`tab-menu ${tab === 'history' ? 'active' : ''}`}
                          onClick={() => toggleTab('history')}
                        >
                          {t('history')}
                        </span>
                      </TabMenuContainer>
                      {Object.keys(lastestProgressUpdate).length > 0 && tab === 'latest' && (
                        <Button append={<FaPlus />} onClick={openCreateProgressUpdate}>
                          Add Update
                        </Button>
                      )}
                    </Stack>

                    {tab === 'latest' ? (
                      <>
                        {Object.keys(lastestProgressUpdate).length > 0 ? (
                          <UpdatedProgressContent>
                            <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
                              <Stack direction="row" alignItems="center">
                                <Avatar
                                  sx={{ width: 44, height: 44, mr: '8px' }}
                                  alt="creator progress update"
                                  src={lastestProgressUpdate?.creator_photo}
                                />

                                <Box>
                                  <div>{lastestProgressUpdate?.creator}</div>
                                  <div>{DateTimeHHmmDisplay(lastestProgressUpdate?.createdAt)}</div>
                                </Box>
                              </Stack>

                              <Button onClick={() => openEditProgressUpdate(lastestProgressUpdate?.id)}>Edit</Button>
                            </Stack>
                            <Box mb={2}>
                              <Box sx={{ mb: '6px' }}>Status Update</Box>
                              <Card className="w-card-100 content-progress-update">
                                {lastestProgressUpdate?.status_update}
                              </Card>
                            </Box>
                            <Box mb={2}>
                              <Box sx={{ mb: '6px' }}>Challenges / Issues</Box>
                              <Card className="w-card-100 content-progress-update">
                                {lastestProgressUpdate?.challenges_issue}
                              </Card>
                            </Box>
                            <Box mb={2}>
                              <Box sx={{ mb: '6px' }}>Next Steps</Box>
                              <Card className="w-card-100 content-progress-update">
                                {lastestProgressUpdate?.next_step}
                              </Card>
                            </Box>
                            <Box mb={2}>
                              <Box sx={{ mb: '6px' }}>Estimate Completion</Box>
                              <Card className="w-card-100 content-progress-update">
                                {lastestProgressUpdate?.estimate_completion}
                              </Card>
                            </Box>
                            <Box mb={2}>
                              <Box sx={{ mb: '6px' }}>Decision Points</Box>
                              <Card className="w-card-100 content-progress-update">
                                {lastestProgressUpdate?.decision_point}
                              </Card>
                            </Box>
                            <Box>
                              <Box sx={{ mb: '6px' }}>Other Component</Box>
                              <Card className="w-card-100 content-progress-update">
                                {lastestProgressUpdate?.other_comment}
                              </Card>
                            </Box>
                          </UpdatedProgressContent>
                        ) : (
                          <NoUpdateFoundContent>
                            <Stack justifyContent="center" alignItems="center">
                              <div className="heading">No update found</div>
                              <div className="sub-heading">Start making new update</div>
                              <Button append={<FaPlus />} onClick={openCreateProgressUpdate}>
                                New Progress Update
                              </Button>
                            </Stack>
                          </NoUpdateFoundContent>
                        )}
                      </>
                    ) : (
                      <UpdatedProgressContent>
                        {historyProgressUpdate.length > 0 &&
                          historyProgressUpdate.map((it) => (
                            <Box mb={2} key={it.id}>
                              <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
                                <Stack direction="row" alignItems="center">
                                  <Avatar
                                    sx={{ width: 44, height: 44, mr: '8px' }}
                                    alt="creator progress update"
                                    src={it?.creator_photo}
                                  />
                                  <Box>
                                    <div>{it?.creator}</div>
                                    <div>{DateTimeHHmmDisplay(it?.createdAt)}</div>
                                  </Box>
                                </Stack>

                                <Button onClick={() => openEditProgressUpdate(it.id)}>View</Button>
                              </Stack>
                              <Divider />
                            </Box>
                          ))}
                      </UpdatedProgressContent>
                    )}
                  </Card>
                </Box>
              </div>
              <div className="content-right">
                <div className="title-content">{t('milestone_progress')}</div>
                <div className="content-progress">
                  {projectTaskInfo?.milestones.length > 0 && (
                    <>
                      {projectTaskInfo?.milestones?.map((item, index) => (
                        <div className="wrp-item-create" key={'milestone-item-' + index}>
                          <div className="left" onClick={() => handleClickItemMileStone(item)}>
                            {item?.status === 'Complete' ? (
                              <div className="icon-pin-mark status-milestone-complete">
                                <FaRegCircleCheck className="icon-color" size={23} />
                              </div>
                            ) : item?.status === 'Doing' ? (
                              <div className="icon-pin-mark status-milestone-doing">
                                <FaRegFlag className="icon-color" size={23} />
                              </div>
                            ) : (
                              <div className="icon-pin-mark status-milestone-open">
                                <FaRegFlag className="icon-color" size={23} />
                              </div>
                            )}

                            <div className="wrp-txt-item">
                              <div className="text-item-create txt-bold">{item?.name}</div>
                              <div className="text-item-create">
                                {item?.due_date != null ? convertDateFormat(item?.due_date, 'DD MMM YYYY') : '-'}
                              </div>
                            </div>
                          </div>

                          <div className="right">
                            <OptionBox
                              dropdownTopGap={16}
                              datalist={optionItemMilestoneList}
                              onSelected={(index) => handleSelectOptionItemMilestone(item, index)}
                            />
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                  <Button className="btn-add-milestone" onClick={() => handleClickBtnAddMilestone()}>
                    {t('add_milestone')}
                  </Button>
                </div>
              </div>
            </div>

            {isShowSidePanel && (
              <MilestoneSidePanel
                isOpen={isShowSidePanel}
                projectId={id}
                projectData={projectTaskInfo}
                milestoneId={milestoneId}
                milestoneTemplateData={currentMilestoneTemplateData}
                type={currentMileStoneType}
                onClose={() => setIsShowSidePanel(false)}
                onEditMilestone={handleEditMilestone}
                onDataChange={() => handleDataChange()}
                onClickedTask={(e) => {
                  handleClickedTask(e)
                  setIsClickedTaskFromMilestone(true)
                }}
              />
            )}

            {isShowModalConfirm && (
              <DialogConfirm
                open={isShowModalConfirm}
                onClose={() => setIsShowModalConfirm(false)}
                onSubmit={handleClickedSubmitModal}
                title={textTitleConfirmModal}
                description={textDescConfirmModal}
                textNo={t('cancel')}
                textYes={t('confirm')}
                className={'modal-del'}
              />
            )}

            {isShowModalSave && (
              <Modal open={isShowModalSave} disableBackdropClick>
                <Div>
                  <div className="modal-container">
                    <div className="inner-modal">
                      <div className="wrp-header">
                        <div className="header">{t('please_input_template_name')}</div>
                        <div className="btn-close" onClick={() => setIsShowModalSave(false)}>
                          <IconXClose />
                        </div>
                      </div>
                      <div className="wrp-text-filed">
                        <TextField
                          className={'input-text-name-template'}
                          placeholder={t('template_name')}
                          value={templateName}
                          onChange={(e) => {
                            setIsErrorTemplateName(false)
                            setTemplateName(e.target.value)
                          }}
                          error={isErrorTemplateName}
                        />
                      </div>
                      <div className="wrp-button">
                        <ButtonOutline className={'btn-modal pdr-10'} onClick={() => setIsShowModalSave(false)}>
                          {t('cancel')}
                        </ButtonOutline>
                        <Button className={'btn-modal pdl-10'} onClick={handleClickedConfirm}>
                          {t('confirm')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Div>
              </Modal>
            )}

            {isShowDialogProject && (
              <DialogProject
                type={'edit_project'}
                projectInfoData={projectTaskInfo}
                onClose={() => setIsShowDialogProject(false)}
                onDataChange={handleDataChange}
              />
            )}
          </Box>
        )}

        {currentMenuTabSelected == 'task' && (
          <>
            <div className="filter-header">
              <div className="wrp-filter-menu">
                <div className="top">
                  <SearchText
                    className="search-text"
                    placeholder="Search"
                    value={searchTable.search}
                    onChange={(e) => setSearchTable({ ...searchTable, search: e.target.value })}
                    onSubmit={(e) => setIsDataChange(!isDataChange)}
                    onClear={() => {
                      setSearchTable({ ...searchTable, search: '' })
                      setIsDataChange(!isDataChange)
                    }}
                  ></SearchText>

                  <ButtonOutline className="btn-clear-all" onClick={() => handleClickedClearAll()}>
                    {t('clear_all')}
                  </ButtonOutline>
                </div>

                <div className="bottom">
                  <div className="wrp-dropdown">
                    <div className="title-dropdown">{t('Miestone')}</div>
                    <DropdownSelect
                      className="dropdown"
                      dataList={milestoneList}
                      initValue={searchTable.milestoneSelected}
                      onChange={(e) => handleDropdownSelected(e, 'milestone')}
                      placeHolder="Milestone"
                    />
                  </div>

                  <div className="wrp-dropdown">
                    <div className="title-dropdown">{t('scope_of_work')}</div>
                    <DropdownSelect
                      className="dropdown"
                      dataList={scopeOfWorkList}
                      initValue={searchTable.scopeOfWorkSelected}
                      onChange={(e) => handleDropdownSelected(e, 'scope_of_work')}
                      placeHolder="Scope of work"
                      isDisabled={searchTable.milestoneSelected ? false : true}
                    />
                  </div>

                  <div className="wrp-dropdown">
                    <div className="title-dropdown">{t('status')}</div>
                    <DropdownSelect
                      className="dropdown"
                      dataList={statusList}
                      initValue={searchTable.statusSelected}
                      onChange={(e) => handleDropdownSelected(e, 'status')}
                      placeHolder={'Status'}
                    />
                  </div>

                  <div className="wrp-dropdown">
                    <div className="title-dropdown">{t('created_by')}</div>
                    <DropdownSelect
                      className="dropdown"
                      dataList={createdByList}
                      initValue={searchTable.createBySelected}
                      onChange={(e) => handleDropdownSelected(e, 'create_by')}
                      placeHolder={t('created_by')}
                    />
                  </div>

                  <div className="wrp-dropdown">
                    <div className="title-dropdown">{t('assign_to')}</div>
                    <AutoComplete
                      className="dropdown"
                      optionList={assignToList}
                      value={searchTable.assignTo}
                      onChange={(value) => handleDropdownSelected(value, 'assign_to')}
                    />
                  </div>
                </div>
              </div>
              <div className="wrp-btn-filter">
                {/* <Button className="mr-1-rem">Evaluate KPI</Button> */}
                <Button
                  className="btn-new-task"
                  onClick={() => {
                    setCurrentTaskType('new_task_in_project')
                    setIsShowNewTaskModal(true)
                  }}
                >
                  {t('new_task')}
                </Button>
              </div>
            </div>

            <div className="wrp-paginate-table">
              <TableProjectTask
                projectId={id}
                isUpdate={isDataChange}
                filter={searchTable}
                onClickTableItem={(e) => handleClickedTask(e)}
              />
              {/* TableProjectTask */}
            </div>

            {isShowNewTaskModal && (
              <DialogTaskEditor
                // taskId={currentTaskType == 'edit_task' && taskId}
                type={currentTaskType}
                projectId={id}
                open={isShowNewTaskModal}
                onClose={() => setIsShowNewTaskModal(false)}
                onSuccess={() => {
                  handleDataChange()
                  setIsShowNewTaskModal(false)
                }}
              />
            )}
          </>
        )}

        {currentMenuTabSelected == 'note' && <NoteTask id={id} />}

        {/* Dialog */}
        {isShowPreviewer && (
          <DialogTaskPreviewer
            taskId={taskId}
            open={isShowPreviewer}
            onClose={() => {
              if (isClickedTaskFromMilestone) {
                setCurrentMileStoneType('view_milestone')
                setIsShowPreviewer(false)
                setIsShowSidePanel(true)
              } else {
                setIsShowPreviewer(false)
              }
            }}
            previewType={'task_milestone'}
            onDataChange={() => {
              handleDataChange()
            }}
          />
        )}
        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />

        {/* Side panel */}
        <SidePanel isOpen={isOpenCreateProgressUpdate} setIsOpen={setIsOpenCreateProgressUpdate} width={450}>
          <SidePanelContent>
            <Stack sx={{ mb: '24px' }} direction="row" justifyContent="space-between" alignItems="center">
              <div className="heading">Progress Update</div>
              <LiaTimesSolid className="icon" onClick={closeCreateProgressUpdate} />
            </Stack>

            <Box mb={2}>
              <div className="heading-input">Status Update</div>
              <TextArea name="status_update" value={form.status_update} onChange={onChangeForm} />
            </Box>
            <Box mb={2}>
              <div className="heading-input">Challenges / Issue</div>
              <TextArea name="challenges_issue" value={form.challenges_issue} onChange={onChangeForm} />
            </Box>
            <Box mb={2}>
              <div className="heading-input">Next Steps</div>
              <TextArea name="next_step" value={form.next_step} onChange={onChangeForm} />
            </Box>
            <Box mb={2}>
              <div className="heading-input">Estimate Completion</div>
              <TextArea name="estimate_completion" value={form.estimate_completion} onChange={onChangeForm} />
            </Box>
            <Box mb={2}>
              <div className="heading-input">Decision Points</div>
              <TextArea name="decision_point" value={form.decision_point} onChange={onChangeForm} />
            </Box>
            <Box mb={2}>
              <div className="heading-input">Other Comments</div>
              <TextArea name="other_comment" value={form.other_comment} onChange={onChangeForm} />
            </Box>
            <div className="footer-side-panel">
              <Divider sx={{ mb: 2 }} />
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <ButtonOutline onClick={closeCreateProgressUpdate}>{t('cancel')}</ButtonOutline>
                <Button onClick={onSubmitCreateProgressUpdate}>{t('create')}</Button>
              </Stack>
            </div>
          </SidePanelContent>
        </SidePanel>
        <SidePanel isOpen={isOpenEditProgressUpdate} setIsOpen={setIsOpenEditProgressUpdate} width={450}>
          <SidePanelContent>
            <Stack sx={{ mb: '24px' }} direction="row" justifyContent="space-between" alignItems="center">
              <div className="heading">View Project Update</div>
              <LiaTimesSolid className="icon" onClick={closeEditProgressUpdate} />
            </Stack>

            <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center">
                <Avatar
                  sx={{ width: 44, height: 44, mr: '8px' }}
                  alt="creator progress update"
                  src={progressUpdateInfo?.creator_photo}
                />
                <Box>
                  <div>{progressUpdateInfo?.creator}</div>
                  <div>{DateTimeHHmmDisplay(progressUpdateInfo?.createdAt)}</div>
                </Box>
              </Stack>

              {!isEditMode && <Button onClick={toggleIsEditMode}>Edit</Button>}
            </Stack>

            <Box mb={2}>
              <div className="heading-input">Status Update</div>
              <TextArea
                name="status_update"
                value={editForm.status_update}
                onChange={onChangeFormEdit}
                disabled={!isEditMode}
              />
            </Box>
            <Box mb={2}>
              <div className="heading-input">Challenges / Issue</div>
              <TextArea
                name="challenges_issue"
                value={editForm.challenges_issue}
                onChange={onChangeFormEdit}
                disabled={!isEditMode}
              />
            </Box>
            <Box mb={2}>
              <div className="heading-input">Next Steps</div>
              <TextArea
                name="next_step"
                value={editForm.next_step}
                onChange={onChangeFormEdit}
                disabled={!isEditMode}
              />
            </Box>
            <Box mb={2}>
              <div className="heading-input">Estimate Completion</div>
              <TextArea
                name="estimate_completion"
                value={editForm.estimate_completion}
                onChange={onChangeFormEdit}
                disabled={!isEditMode}
              />
            </Box>
            <Box mb={2}>
              <div className="heading-input">Decision Points</div>
              <TextArea
                name="decision_point"
                value={editForm.decision_point}
                onChange={onChangeFormEdit}
                disabled={!isEditMode}
              />
            </Box>
            <Box mb={2}>
              <div className="heading-input">Other Comments</div>
              <TextArea
                name="other_comment"
                value={editForm.other_comment}
                onChange={onChangeFormEdit}
                disabled={!isEditMode}
              />
            </Box>

            <div className="footer-side-panel">
              {isEditMode && (
                <>
                  <Divider sx={{ mb: 2 }} />

                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    <ButtonOutline onClick={cancelEdit}>{t('cancel')}</ButtonOutline>
                    <Button onClick={onSubmitEditProgressUpdate}>{t('save')}</Button>
                  </Stack>
                </>
              )}
            </div>
          </SidePanelContent>
        </SidePanel>
      </PageStyled>
    </>
  )
}

const PageStyled = styled.div`
  .status-milestone-doing {
    background-color: var(--Primary-50);

    .icon-color {
      color: var(--Primary-700);
    }
  }
  .status-milestone-complete {
    background-color: var(--success-50);

    .icon-color {
      color: var(--success-700);
    }
  }
  .status-milestone-open {
    background-color: var(--Base-White);

    .icon-color {
      color: var(--Gray-300);
    }
  }
  .w-card-100 {
    width: 100%;
  }
  .content-progress-update {
    white-space: pre-wrap;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .heading-card {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .percent-data {
    color: var(--Gray-900);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;

    &.over-due {
      color: var(--Error-700);
    }
  }
  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }
  .content-title {
    display: flex;
    .icon-chevron-left {
      margin-right: 16px;
    }
    .txt-title {
      font-size: 36px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .date-filter-container {
    width: 100%;
  }

  .wrp-tab-menu {
    display: flex;

    .btn-menu-style {
      border: none;
      border-bottom: 2px solid transparent;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: var(--Primary-50);
      border-bottom: 2px solid var(--Primary-600) !important;
    }
  }

  .header-button {
    display: flex;
    justify-content: flex-end;
    margin: 16px 0;
    .btn-edit {
      margin-left: 8px;
    }
  }

  .wrp-content {
    margin: 16px 0;
    display: flex;
    .title-content {
      font-weight: bold;
      margin-bottom: 8px;
    }
    .content-left {
      flex: 2;
      .content-description,
      .content-team {
        border: 1px solid var(--Gray-300);
        border-radius: 8px;
        padding: 16px;
      }
      //content-description
      .content-description {
        margin-bottom: 16px;
      }
      .detail-project {
        padding: 16px;
      }

      //content-team

      .inner-content-team {
        margin-top: 16px;
        border: 1px solid var(--Gray-300);
        padding: 16px;
        display: flex;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          .name {
            font-weight: bold;
          }
          .icon-user {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            margin-right: 16px;
          }
        }
        .right {
          display: flex;
          align-items: center;
        }
      }
    }
    .img-profile-person {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .content-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      position: relative;
      min-height: 500px;

      .content-progress {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 25px;
        border: 1px solid var(--Gray-300);
        border-radius: 8px;
        position: relative;
        overflow-y: scroll;
        .wrp-item-create {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px;
          margin-bottom: 16px;
          border: 1px solid var(--Gray-300);
          border-radius: 8px;
          .left {
            display: flex;
            align-items: center;
            cursor: pointer;
            .icon-pin-mark {
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid var(--Gray-200);
              border-radius: 8px;
              padding: 10px;
              margin-right: 8px;
            }
            .text-item-create {
              flex: 1;
            }
            .txt-bold {
              font-weight: bold;
            }
          }
          .right {
            cursor: pointer;
          }
        }
      }
      .btn-add-milestone {
        width: fit-content;
      }
    }
  }

  //Filter header//
  .filter-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 20px 0;

    .wrp-filter-menu {
      position: relative;
      /* display: flex; */
      align-items: center;
      flex-wrap: wrap;

      .top {
        width: 50%;
        display: flex;
        align-items: center;

        .search-text {
          /* min-width: 156px; */
          flex: 1;
          margin: 0 !important;
        }
        .search-text .prefix {
          margin-left: 10px;
          margin-right: 0;
        }

        .btn-clear-all {
          margin-left: 18px;
        }
      }
      .bottom {
        display: flex;
        width: 100%;
        margin-top: 12px;
        flex-direction: row;
        flex-wrap: wrap;

        .wrp-dropdown {
          flex: 1;
          padding-right: 12px;

          .title-dropdown {
            margin-bottom: 6px;
          }
          .dropdown {
            width: 100%;
          }
        }
        .last {
          padding: 0;
        }
      }
    }

    .wrp-filter-btn {
      position: relative;
    }

    .dialog-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 300px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);
      max-height: 300px;

      .wrp-person-list {
        position: relative;
      }
      .person-list {
        height: 220px;
        overflow-y: scroll;
      }

      .person-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 4px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
        }

        .img-profile {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          background-color: var(--Gray-200);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .person-item:hover {
        background-color: var(--Gray-50);
      }
    }

    .filter-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 500px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);

      .wrp-filter-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clear-btn {
          padding: 4px 6px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        .clear-btn:hover {
          background: var(--Primary-25);
        }
      }
      .wrp-filter-option {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .title-option {
          font-weight: 600;
          margin-bottom: 6px;
        }
        .option-item-style {
          border: 1px solid var(--Gray-300);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Gray-50);
        }
        .option-item-style:hover {
          background-color: var(--Gray-100);
        }
        .option-item-active {
          border: 1px solid var(--Primary-600);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Primary-600);
          color: #fff;
        }
      }
    }
    .btn-new-task {
      width: max-content;
    }
  }
  .search-text {
    min-width: 156px;
    margin-right: 16px;
  }
  .dropdown {
    margin-right: 16px;
    min-width: 156px;
  }

  //Tap document//
  .wrp-content-document {
    padding: 16px 0;
    .item-tab-document {
      border: 1px solid var(--Gray-300);
      border-radius: 15px;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      .left {
        display: flex;
        .box-icon {
          border: 1px solid var(--Gray-300);
          width: 116px;
          height: 116px;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width: 30px;
          }
        }
        .wrp-detail {
          margin-left: 16px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .top {
            .header {
              font-weight: bold;
              font-size: 24px;
            }

            .user {
              margin-top: 8px;
              display: flex;
              .icon-user {
                svg {
                  width: 25px;
                }
              }
              .name {
                margin-left: 8px;
              }
            }
          }
        }
      }
    }
  }
`
const Div = styled.div`
  .modal-container {
    padding: 24px;
    min-width: 400px;
    width: 450px !important;

    .inner-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      .wrp-header {
        display: flex;
        align-items: center;
        width: 100%;

        .header {
          color: #6941c6;
          font-weight: bold;
          font-size: 24px;
          flex: 1;
        }
        .btn-close {
          margin-left: 10px;
        }
      }

      .wrp-text-filed {
        margin: 20px 0;
        width: 100%;
        .input-text-name-template {
          width: 100%;
        }
      }

      .wrp-button {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .btn-modal {
          flex: 1;
          justify-content: center;
        }
        .pdr-10 {
          margin-right: 10px;
        }
        .pdl-10 {
          margin-left: 10px;
        }
      }
    }
  }
`
const TabMenuContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  .tab-menu {
    position: relative;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-500);
    padding: 0.75rem;
    :hover {
      cursor: pointer;
    }
    &.active {
      color: var(--Primary-700);
      border-bottom: 2px solid var(--Primary-700);
      background: var(--Primary-50);
    }
  }

  .nest-collapse {
    position: relative;

    .nested-item {
      position: absolute;
      top: 180%;
    }
  }
`
const NoUpdateFoundContent = styled.div`
  .heading {
    color: var(--Gray-900);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .sub-heading {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 24px;
  }
`
const UpdatedProgressContent = styled.div`
  color: var(--Gray-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  p {
    margin: 0;
  }

  .icon {
    cursor: pointer;
  }
  .color-icon-edit {
    color: var(--Gray-500);
  }
`
const SidePanelContent = styled.div`
  position: relative;

  padding: 24px 24px 0;

  color: var(--Gray-700);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  .color-icon-edit {
    color: var(--Gray-500);
  }

  .heading {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .icon {
    cursor: pointer;
  }

  .heading-input {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .footer-side-panel {
    background-color: var(--Base-White);
    position: sticky;
    bottom: 0;
    width: 100%;
    padding-bottom: 16px;
  }
`
const TextArea = styled.textarea`
  font-family: 'Inter', 'Noto Sans Thai';
  border-radius: 8px;
  border: 1px solid var(--Gray-300);
  background: var(--Base-White);

  padding: 8px 12px;
  width: 100%;
  height: 130px;
  resize: none;

  &:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    color: var(--Gray-500);
  }
`
