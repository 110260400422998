import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { getMeetingById, updateMeeting } from '../../redux/actions/dailysummary'
import { selectDailySummary } from '../../redux/selectors'
import { DateDisplay, TimeDisplayHHmm, DateTimeHHmmDisplay, DateTimeHHmmssDisplay } from '../../utils/common'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import TextField from '../../components/form/TextField'
import Button from '../../components/form/button/Button'
import SidePanel from '../../components/common/SidePanel'
import MeetingCalendar from '../../pages/my-calendar/MeetingCalendar'
import mapIcon from '../../assets/images/gg_map.png'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import LinkIcon from '../../assets/images/link_meeting.png'
import iconClose from '../../assets/images/icon-close.svg'
import { useTranslation } from 'react-i18next'

import userIconQuesTion from '../../assets/images/question_icon.png'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'

const DivMain = styled.div`
  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  .main_box {
    display: grid;
    grid-template-columns: repeat(2, 25% [col-start] 75% [col-end]);
    /*gap: 20px;*/
    padding-top: 10px;
    .main_box_text_head {
      font-size: 14px;
      font-weight: 600;
      color: var(--Gray-700);
    }
    .main_box_text_sub {
      font-size: 14px;
      color: var(--Gray-600);
    }
    .main_box_edit {
      border: 1px solid rgb(208, 213, 221);
      border-radius: 8px;
    }
    .main_box_fill_data {
      margin: 16px 24px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 20px;
      .topic_fill {
        font-size: 14px;
        color: #212121;
        margin-bottom: 5px;
      }
      .topic_detail {
        font-size: 14px;
        color: #757575;
      }
    }
    .main_box_button {
      margin: 16px 24px;
      text-align: end;
    }
    .border_buttom {
      border-bottom: 1px solid rgb(234, 236, 240);
    }
  }
  .TextField_employee {
    width: 100%;
  }
  .button_Edit {
    color: #6941c6;
    background-color: #f9f5ff;
    border: 1px solid #eaecf0;
  }
  .button_Cancel {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #eaecf0;
    margin-right: 1rem;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .prefix_icon {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .input-new-2 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr repeat(1, auto);
    grid-template-rows: auto;
    align-content: center;
  }

  .ql-container {
    min-height: 250px;
    font-family: inherit;
  }
`
const initialState = {
  meeting_type: null,
  meeting_room: null,
  meeting_space: '',
  title: null,
  meeting_no: null,
  meeting_date: null,
  time_start: '00.00',
  time_end: '00.00',
  online_meeting_link: null,
  online_meeting_id: null,
  online_meeting_code: null,
  contact_person: null,
  google_map_link: null,
  note: null,
  project: null,
  agenda_detail: null,
  summary: null,
}

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link', 'image', 'video'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: 2 }],
    [{ color: [] }, { background: [] }],
    ['formula'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ align: [] }],
    ['clean'],
  ],
}

const TabMeetingInfomation = ({ this_meeting_id }) => {
  const { user, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { t } = useTranslation()
  const dispatch = useDispatch()
  useEffect(() => {
    if (this_meeting_id) {
      dispatch(getMeetingById(this_meeting_id))
    }
  }, [this_meeting_id, dispatch])

  const { MeetingInfo } = useSelector(selectDailySummary)
  const [MeetingData, setMeetingData] = useState(initialState)

  useEffect(() => {
    if (MeetingInfo.id) {
      const setData = {
        meeting_type: MeetingInfo.meeting_type,
        meeting_room: MeetingInfo.meeting_room?.data?.id,
        meeting_space: MeetingInfo.meeting_room_name,
        title: MeetingInfo.title,
        meeting_no: MeetingInfo.meeting_no,
        meeting_date: dayjs(MeetingInfo.meeting_date).format('YYYY-MM-DD'),
        time_start: TimeDisplayHHmm(MeetingInfo.meeting_date_start),
        time_end: TimeDisplayHHmm(MeetingInfo.meeting_date_end),
        online_meeting_link: MeetingInfo.online_meeting_link,
        online_meeting_id: MeetingInfo.online_meeting_id,
        online_meeting_code: MeetingInfo.online_meeting_code,
        contact_person: MeetingInfo.contact_person,
        google_map_link: MeetingInfo.google_map_link,
        note: MeetingInfo.note,
        project: MeetingInfo.project,
        agenda_detail: MeetingInfo.agenda_detail,
        file_meeting: MeetingInfo.meeting_full_file.map((v) => v.id),
        summary: MeetingInfo.summary,
      }
      setMeetingData(setData)
    }
  }, [MeetingInfo])

  const [EditBox1, setEditBox1] = useState(false)
  const [EditBox2, setEditBox2] = useState(false)
  const [EditBox3, setEditBox3] = useState(false)
  const [EditBox4, setEditBox4] = useState(false)
  const [EditBox5, setEditBox5] = useState(false)
  const [EditBox6, setEditBox6] = useState(false)

  const MeetingType_option = [
    { text: 'Online', value: 'Online' },
    { text: 'Onsite', value: 'Onsite' },
    { text: 'Customer Onsite', value: 'Customer Onsite' },
  ]
  const onMeetingType = (v) => {
    setMeetingData({ ...MeetingData, meeting_type: v })
  }
  const onChangecurrentDate = (value) => {
    setMeetingData({ ...MeetingData, meeting_date: value })
  }
  const Project_option = [
    { text: 'ProjectA', value: 'ProjectA' },
    { text: 'ProjectB', value: 'ProjectB' },
  ]
  const onProject = (v) => {
    setMeetingData({ ...MeetingData, project: v })
  }

  const [WidthMeetingCalendar, setWidthMeetingCalendar] = useState('1400')
  const [ModalMeetingCalendar, setModalMeetingCalendar] = useState(false)
  const OpenModalReserve = () => {
    setWidthMeetingCalendar(window.innerWidth - 80)
    setModalMeetingCalendar(true)
  }
  const CloseModalMeetingCalendar = () => {
    setModalMeetingCalendar(false)
  }
  const SubmitModalMeetingCalendar = () => {
    setModalMeetingCalendar(false)
  }
  const [ModalConfirmCancelBooking, setModalConfirmCancelBooking] = useState(false)
  const [Modal_title, setModal_title] = useState('')
  const [Modal_NameItem, setModal_NameItem] = useState('')
  const [Modal_Description, setModal_Description] = useState('')

  const onCancleSpace = () => {
    //alert('5555555');
    setModal_title('Cancel space booking ?')
    setModal_NameItem(MeetingData.meeting_space)
    setModal_Description(
      DateDisplay(MeetingData.meeting_date) + ' ' + MeetingData.time_start + ' - ' + MeetingData.time_end
    )
    setModalConfirmCancelBooking(true)
  }

  const Savechanges = (id_box_edit) => {
    let sent_data_api = {
      data: {
        title: MeetingData.title,
        meeting_no: MeetingData.meeting_no,
      },
    }
    if (id_box_edit == 1) {
      sent_data_api = {
        data: {
          title: MeetingData.title,
          meeting_no: MeetingData.meeting_no,
        },
      }
    } else if (id_box_edit == 2) {
      sent_data_api = {
        data: {
          meeting_type: MeetingData.meeting_type,
          meeting_room: MeetingData.meeting_room,
          meeting_room_id: MeetingData.meeting_room,
          google_map_link: MeetingData.google_map_link,
        },
      }
    } else if (id_box_edit == 3) {
      sent_data_api = {
        data: {
          meeting_date: MeetingData.meeting_date,
          meeting_date_start: dayjs(
            MeetingData.meeting_date + ' ' + MeetingData.time_start.replace('.', ':') + ':00'
          ).format('YYYY-MM-DD HH:mm:ss'),
          meeting_date_end: dayjs(
            MeetingData.meeting_date + ' ' + MeetingData.time_end.replace('.', ':') + ':00'
          ).format('YYYY-MM-DD HH:mm:ss'),
        },
      }
    } else if (id_box_edit == 4) {
      sent_data_api = {
        data: {
          online_meeting_link: MeetingData.online_meeting_link,
          online_meeting_id: MeetingData.online_meeting_id,
          online_meeting_code: MeetingData.online_meeting_code,
        },
      }
    } else if (id_box_edit == 5) {
      sent_data_api = {
        data: {
          project: MeetingData.project,
          agenda_detail: MeetingData.agenda_detail,
        },
      }
    } else if (id_box_edit == 6) {
      sent_data_api = {
        data: {
          note: MeetingData.note,
        },
      }
    } else if (id_box_edit == 22) {
      //--- ยกเลิกห้องประชุม
      setModalConfirmCancelBooking(false)
      sent_data_api = {
        data: {
          meeting_room: null,
          meeting_room_id: null,
        },
      }
    }
    dispatch(updateMeeting(this_meeting_id, sent_data_api)).then(({ type }) => {
      if (type.endsWith('_SUCCESS')) {
        if (id_box_edit == 1) {
          setEditBox1(false)
        } else if (id_box_edit == 2) {
          setEditBox2(false)
        } else if (id_box_edit == 3) {
          setEditBox3(false)
        } else if (id_box_edit == 4) {
          setEditBox4(false)
        } else if (id_box_edit == 5) {
          setEditBox5(false)
        } else if (id_box_edit == 6) {
          setEditBox6(false)
        }
      }
    })
  }
  return (
    <DivMain>
      <div className="main_box">
        <div>
          <div className="main_box_text_head">{t('meeting_title_and_no_header')}</div>
          <div className="main_box_text_sub">{t('meeting_title_and_no_sub_header')}</div>
        </div>
        <div>
          <div className="main_box_edit">
            <div className="main_box_fill_data">
              <div>
                <div className="topic_fill">{t('title')} *</div>
                <div>
                  <TextField
                    name="title"
                    className="TextField_employee"
                    placeholder={t('meeting_title_placeholder')}
                    value={MeetingData.title}
                    onChange={(e) => setMeetingData({ ...MeetingData, title: e.target.value })}
                    disabled={!EditBox1}
                  />
                </div>
              </div>
              <div>
                <div className="topic_fill">{t('create_meeting_meeting_no')}</div>
                <div>
                  <TextField
                    name="meeting_no"
                    className="TextField_employee"
                    placeholder={t('meeting_no_placeholder')}
                    value={MeetingData.meeting_no}
                    onChange={(e) => setMeetingData({ ...MeetingData, meeting_no: e.target.value })}
                    disabled={!EditBox1}
                  />
                </div>
              </div>
            </div>
            <div className="border_buttom"></div>
            <div className="main_box_button">
              {EditBox1 == false && (
                <div>
                  <Button onClick={() => setEditBox1(true)} className="button_Edit">
                    {t('Edit')}
                  </Button>
                </div>
              )}
              {EditBox1 == true && (
                <div>
                  <Button onClick={() => setEditBox1(false)} className="button_Cancel">
                    {t('cancel')}
                  </Button>
                  <Button onClick={() => Savechanges(1)}>{t('save_changes')}</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="border_header"></div>
      <div className="main_box">
        <div>
          <div className="main_box_text_head">{t('meeting_type_space_booking_header')}</div>
          <div className="main_box_text_sub">{t('meeting_type_space_booking_sub_header')}</div>
        </div>
        <div>
          <div className="main_box_edit">
            <div className="main_box_fill_data">
              <div>
                <div className="topic_fill">{t('meeting_type')}</div>
                <div>
                  <Dropdown
                    id={`MeetingType`}
                    className="dd_employee"
                    value={MeetingData.meeting_type}
                    optionList={MeetingType_option}
                    onChange={onMeetingType}
                    disabled={!EditBox2}
                    placeHolder={t('select')}
                    ValueplaceHolderIsNull={true}
                  />
                </div>
              </div>
              <div>
                <div className="topic_fill">{t('meeting_space')}</div>
                <div className="input-new-2">
                  <TextField
                    name="meeting_space"
                    className="TextField_employee"
                    placeholder={t('reserve')}
                    value={MeetingData.meeting_space}
                    onChange={(e) => setMeetingData({ ...MeetingData, meeting_space: e.target.value })}
                    disabled={true}
                    endAdornment={MeetingData.meeting_space ? <img src={iconClose} onClick={onCancleSpace} /> : null}
                  />
                  <Button onClick={OpenModalReserve}>{t('reserve')}</Button>
                </div>
              </div>
              <div>
                <div className="topic_fill">{t('for_other_location')}</div>
                <div>
                  <TextField
                    startAdornment={<img src={mapIcon} />}
                    startAdornmentClassName="prefix_icon"
                    name="google_map_link"
                    className="TextField_employee"
                    placeholder={t('google_map_link')}
                    value={MeetingData.google_map_link}
                    onChange={(e) => setMeetingData({ ...MeetingData, google_map_link: e.target.value })}
                    disabled={!EditBox2}
                  />
                </div>
              </div>
            </div>
            <div className="border_buttom"></div>
            <div className="main_box_button">
              {EditBox2 == false && (
                <div>
                  <Button onClick={() => setEditBox2(true)} className="button_Edit">
                    {t('Edit')}
                  </Button>
                </div>
              )}
              {EditBox2 == true && (
                <div>
                  <Button onClick={() => setEditBox2(false)} className="button_Cancel">
                    {t('cancel')}
                  </Button>
                  <Button onClick={() => Savechanges(2)}>{t('save_changes')}</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="border_header"></div>
      <div className="main_box">
        <div>
          <div className="main_box_text_head">{t('meeting_date_time_header')}</div>
          <div className="main_box_text_sub">{t('meeting_date_time_sub_header')}</div>
        </div>
        <div>
          <div className="main_box_edit">
            <div className="main_box_fill_data">
              <div>
                <div className="topic_fill">{t('meeting_date')}</div>
                <div>
                  <Date
                    className={'input-calendar'}
                    value={MeetingData.meeting_date}
                    onChange={onChangecurrentDate}
                    disabled={!EditBox3}
                  />
                </div>
              </div>
              <div>
                <div className="topic_fill">{t('time_start')} *</div>
                <div>
                  <TextField
                    name="time_start"
                    className="TextField_employee"
                    placeholder={t('time_start')}
                    value={MeetingData.time_start}
                    onChange={(e) => setMeetingData({ ...MeetingData, time_start: e.target.value })}
                    disabled={!EditBox3}
                  />
                </div>
              </div>
              <div>
                <div className="topic_fill">{t('time_end')} *</div>
                <div>
                  <TextField
                    name="time_end"
                    className="TextField_employee"
                    placeholder={t('time_end')}
                    value={MeetingData.time_end}
                    onChange={(e) => setMeetingData({ ...MeetingData, time_end: e.target.value })}
                    disabled={!EditBox3}
                  />
                </div>
              </div>
            </div>
            <div className="border_buttom"></div>
            <div className="main_box_button">
              {EditBox3 == false && (
                <div>
                  <Button onClick={() => setEditBox3(true)} className="button_Edit">
                    {t('Edit')}
                  </Button>
                </div>
              )}
              {EditBox3 == true && (
                <div>
                  <Button onClick={() => setEditBox3(false)} className="button_Cancel">
                    {t('cancel')}
                  </Button>
                  <Button onClick={() => Savechanges(3)}>{t('save_changes')}</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="border_header"></div>
      <div className="main_box">
        <div>
          <div className="main_box_text_head">{t('meeting_online_setup_header')}</div>
          <div className="main_box_text_sub">{t('meeting_online_setup_sub_header')}</div>
        </div>
        <div>
          <div className="main_box_edit">
            <div className="main_box_fill_data">
              <div>
                <div className="topic_fill">{t('meeting_link')}</div>
                <div>
                  <TextField
                    startAdornment={<img src={LinkIcon} />}
                    startAdornmentClassName="prefix_icon"
                    name="online_meeting_link"
                    className="TextField_employee"
                    placeholder={t('meeting_link_placeholder')}
                    value={MeetingData.online_meeting_link}
                    onChange={(e) => setMeetingData({ ...MeetingData, online_meeting_link: e.target.value })}
                    disabled={!EditBox4}
                  />
                </div>
              </div>
              <div>
                <div className="topic_fill">{t('meeting_id')}</div>
                <div>
                  <TextField
                    name="online_meeting_id"
                    className="TextField_employee"
                    placeholder={t('meeting_id')}
                    value={MeetingData.online_meeting_id}
                    onChange={(e) => setMeetingData({ ...MeetingData, online_meeting_id: e.target.value })}
                    disabled={!EditBox4}
                  />
                </div>
              </div>
              <div>
                <div className="topic_fill">{t('meeting_code')}</div>
                <div>
                  <TextField
                    name="online_meeting_code"
                    className="TextField_employee"
                    placeholder={t('meeting_code_placeholder')}
                    value={MeetingData.online_meeting_code}
                    onChange={(e) => setMeetingData({ ...MeetingData, online_meeting_code: e.target.value })}
                    disabled={!EditBox4}
                  />
                </div>
              </div>
            </div>
            <div className="border_buttom"></div>
            <div className="main_box_button">
              {EditBox4 == false && (
                <div>
                  <Button onClick={() => setEditBox4(true)} className="button_Edit">
                    {t('Edit')}
                  </Button>
                </div>
              )}
              {EditBox4 == true && (
                <div>
                  <Button onClick={() => setEditBox4(false)} className="button_Cancel">
                    {t('cancel')}
                  </Button>
                  <Button onClick={() => Savechanges(4)}>{t('save_changes')}</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="border_header"></div>
      <div className="main_box">
        <div>
          <div className="main_box_text_head">{t('meeting_agenda_header')}</div>
          <div className="main_box_text_sub">{t('meeting_agenda_sub_header')}</div>
        </div>
        <div>
          <div className="main_box_edit">
            <div className="main_box_fill_data">
              <div>
                <div className="topic_fill">{t('project')}</div>
                <div>
                  <Dropdown
                    id={`project`}
                    className="dd_employee"
                    value={MeetingData.project}
                    optionList={Project_option}
                    onChange={onProject}
                    disabled={!EditBox5}
                    placeHolder={t('select')}
                    ValueplaceHolderIsNull={true}
                  />
                </div>
              </div>
              <div></div>
            </div>
            <div className="main_box_fill_data">
              <div>
                <div className="topic_fill">{t('detail_agenda')}</div>
                <div>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    value={MeetingData.agenda_detail}
                    onChange={(e) => setMeetingData({ ...MeetingData, agenda_detail: e })}
                  />
                </div>
              </div>
            </div>
            <div className="border_buttom"></div>
            <div className="main_box_button">
              {EditBox5 == false && (
                <div>
                  <Button onClick={() => setEditBox5(true)} className="button_Edit">
                    {t('Edit')}
                  </Button>
                </div>
              )}
              {EditBox5 == true && (
                <div>
                  <Button onClick={() => setEditBox5(false)} className="button_Cancel">
                    {t('cancel')}
                  </Button>
                  <Button onClick={() => Savechanges(5)}>{t('save_changes')}</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="border_header"></div>
      <div className="main_box">
        <div>
          <div className="main_box_text_head">{t('note')}</div>
          <div className="main_box_text_sub">{t('additional_note_to_attendee')}</div>
        </div>
        <div>
          <div className="main_box_edit">
            <div className="main_box_fill_data">
              <div>
                <div className="topic_fill">{t('note')}</div>
                <div>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    value={MeetingData.note}
                    onChange={(e) => setMeetingData({ ...MeetingData, note: e })}
                  />
                </div>
              </div>
            </div>
            <div className="border_buttom"></div>
            <div className="main_box_button">
              {EditBox6 == false && (
                <div>
                  <Button onClick={() => setEditBox6(true)} className="button_Edit">
                    {t('Edit')}
                  </Button>
                </div>
              )}
              {EditBox6 == true && (
                <div>
                  <Button onClick={() => setEditBox6(false)} className="button_Cancel">
                    {t('cancel')}
                  </Button>
                  <Button onClick={() => Savechanges(6)}>{t('save_changes')}</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="border_header"></div>

      <SidePanel isOpen={ModalMeetingCalendar} setIsOpen={setModalMeetingCalendar} width={WidthMeetingCalendar}>
        <MeetingCalendar
          width={WidthMeetingCalendar}
          onClose={CloseModalMeetingCalendar}
          onSubmit={SubmitModalMeetingCalendar}
          MeetingData={MeetingData}
          setMeetingData={setMeetingData}
        />
      </SidePanel>

      <DialogConfirmV2
        open={Boolean(ModalConfirmCancelBooking)}
        onClose={() => setModalConfirmCancelBooking(false)}
        onNo={() => setModalConfirmCancelBooking(false)}
        onSubmit={() => Savechanges(22)}
        icon={userIconQuesTion}
        title={Modal_title}
        nameItem={Modal_NameItem}
        description={Modal_Description}
        textYes={'Cancel booking'}
        textNo={t('cancel')}
      />
    </DivMain>
  )
}

export default TabMeetingInfomation
