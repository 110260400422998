import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_ROLE_LIST,
  GET_PERMISSION_ACTIONS,
  GET_PERMISSION_LIST,
  CREATE_ROLE,
  GET_ROLE_BY_ID,
  UPDATE_ROLE,
  DELETE_ROLE,
  GET_ALL_MENU,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getAccessRoles =
  (filter = { limit: 10, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_ROLE_LIST.REQUEST })
      const { token } = JSON.parse(localStorage.getItem('admin'))
      /*
      const headers = {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      }
      const params = { ...filter }
      const { data, meta } = await getAPI({
        url: '/api/role-custom-list',
        headers: headers,
        params,
      })
      */
      const headers = {
        Authorization: null,
        'Content-Type': 'application/json',
      }
      const params = { ...filter }
      const { data, meta } = await getAPI({
        url: '/api/user-permission-role-list-role',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_ROLE_LIST.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({ type: GET_ROLE_LIST.FAILURE, error, showAlert: true, alertMessage: 'Get Role List Fail' })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getAccessRoleById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ROLE_BY_ID.REQUEST })
    const { token } = JSON.parse(localStorage.getItem('admin'))
    /*
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/role-custom-list-byid?id=${id}`,
      headers: headers,
    })
    */
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/user-permission-role-by-id/${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_ROLE_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_ROLE_BY_ID.FAILURE, error, showAlert: true, alertMessage: 'Get Role Info Fail' })
  } finally {
    dispatch(hideLoading())
  }
}

export const getPermissionActions = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PERMISSION_ACTIONS.REQUEST })
    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: '/api/permission-actions',
      headers: headers,
    })

    return dispatch({
      type: GET_PERMISSION_ACTIONS.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_PERMISSION_ACTIONS.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Permission Action Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getPermissionList = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PERMISSION_LIST.REQUEST })
    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: '/api/permission-customs',
      headers: headers,
    })

    return dispatch({
      type: GET_PERMISSION_LIST.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_PERMISSION_LIST.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Permission List Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createRole = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_ROLE.REQUEST })
    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/user-permission-role-create-role`,
      data,
      headers: headers,
    })

    return dispatch({ type: CREATE_ROLE.SUCCESS })
  } catch (err) {
    return dispatch({ type: CREATE_ROLE.FAILURE, error: err })
  } finally {
    dispatch(getAccessRoles())
    dispatch(hideLoading())
  }
}

export const updateRole = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_ROLE.REQUEST })
    const { token } = JSON.parse(localStorage.getItem('admin'))
    /*
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/role-custom-list-updateRolePermission/${id}`,
      data,
      headers: headers,
    })
    */
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/user-permission-role-edit/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_ROLE.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Role Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_ROLE.FAILURE, error: err, showAlert: true, alertMessage: 'Update Role Fail' })
  } finally {
    dispatch(getAccessRoles())
    //dispatch(getAccessRoleById(id))
    dispatch(hideLoading())
  }
}

export const deleteRole = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_ROLE.REQUEST })
    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/role-custom-list-deleteRole/${id}`,
      headers: headers,
    })

    return dispatch({
      type: DELETE_ROLE.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Role Successful',
    })
  } catch (err) {
    return dispatch({ type: DELETE_ROLE.FAILURE, error: err, showAlert: true, alertMessage: 'Delete Role Fail' })
  } finally {
    dispatch(getAccessRoles())
    dispatch(hideLoading())
  }
}

export const getAllMenuSetting =
(filter = { limit: 10, skip: 0 }, id = 1) =>
async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ALL_MENU.REQUEST })
    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }
    const { data, meta } = await getAPI({
      url: `/api/menu-settings?populate=*&type=custom`,
      headers: headers,
    })

    return {type : "getAllMenuSetting_SUCCESS" , data_info : data , data_meta : meta};

  } catch (error) {
    return {type : "getAllMenuSetting_SUCCESS"};
  } finally {
    dispatch(hideLoading())
  }
}
