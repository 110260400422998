import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { DateDisplay } from '../../utils/common'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import SearchText from '../../components/form/SearchText'
import Table from '../../components/common/Table'
import Dropdown from '../../components/form/Dropdown'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ModalPdpa from '../../components/pdpa/ModalPdpa'

import { selectSharedServiceEmployee, selectDocumentTemplate } from '../../redux/selectors'
import {
  getPendingAssignments,
  getReviewAssignments,
  getOverdueAssignments,
  getAssignmentById,
  setNotiEmailDocId,
  setAssignmentInfo,
} from '../../redux/actions/shareServiceEmployee'
import { getDocumentCategory } from '../../redux/actions/documentTemplate'

import { LiaTimesSolid } from 'react-icons/lia'
import { BsStarFill } from 'react-icons/bs'
import pdfIcon from '../../assets/images/pdf-icon.png'

// style
const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .mr-8-px {
    margin-right: 8px;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .align-items-center {
    align-items: center;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 12px;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-2rem {
    font-size: 1.5rem;
  }

  .badge {
    padding: 2px 8px;
    align-items: center;
    color: var(--BADGE-3-Font);
    background: var(--BADGE-3-Bg);
    border: 1px solid var(--BADGE-3-Border);
    border-radius: 16px;

    &.over-due {
      color: var(--BADGE-2-Font);
      background: var(--BADGE-2-Bg);
      border: 1px solid var(--BADGE-2-Border);
    }
    &.red {
      border: 0.0625rem solid var(--BADGE-2-Border);
      background: var(--BADGE-2-Bg);
      color: var(--BADGE-2-Font);
    }
    &.orange {
      border: 0.0625rem solid var(--BADGE-13-Border);
      background: var(--BADGE-13-Bg);
      color: var(--BADGE-13-Font);
    }
    &.green {
      border: 0.0625rem solid var(--BADGE-14-Border);
      background: var(--BADGE-14-Bg);
      color: var(--BADGE-14-Font);
    }
  }

  .over-due-date {
    color: var(--Overdue-Badge-Font);
  }

  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid var(--Status-Icon-Stroke);
    color: var(--Status-Icon-Font);
    background: var(--Status-Icon-Bg);

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: var(--Status-Active);

      &.inactive {
        color: var(--Status-Inactive);
      }

      &.draft {
        color: var(--Status-Draft);
      }
    }
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .search-container {
    display: grid;
    grid-template-columns: repeat(3, 250px) 120px;
    grid-template-rows: auto;
    align-content: center;
    column-gap: 1rem;
    margin-bottom: 1rem;

    .headline-input {
      color: var(--Gray-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
  }

  .content-header {
    margin-top: 24px;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      border: 1px solid var(--Gray-200);
      background: var(--Base-White);

      .table-head {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--Gray-200);
        padding-bottom: 2rem;
        margin-bottom: 2rem;

        .group {
          display: flex;
          align-items: center;
        }

        .table-title {
          font-size: 18px;
          font-weight: 600;
          color: var(--Gray-900);
        }

        .table-total {
          margin-left: 16px;
          font-size: 12px;
          font-weight: 500;
          padding: 2px 8px;
          color: var(--Doing-Badge-Font);
          border-radius: 16px;
          border: 1px var(--Doing-Badge-Stroke) solid;
        }
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;

    &.yellow {
      color: #fdb022;
    }
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error-input {
    border: 1px solid red;
  }

  .side-panel-container {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .side-panel-container-2 {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .create-access-role-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-header {
    color: var(--gray-700);
    font-size: 16px;
    font-weight: 500;
  }

  .permission-header {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .permission-content {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .text-underline {
    text-decoration: underline;
  }
  .text-color-primary {
    color: #7f56d9;
  }
  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab-menu-container {
    /* display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem; */

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding: 12px;
      margin-right: 14px;
      background: var(--Tab-Bg-Default);
      color: var(--Tab-Font-Default);

      :last-child {
        margin-right: 0;
      }

      &.active {
        color: var(--Tab-Font-Current);
        border-bottom: 2px solid var(--Tab-Underline-Current);
        background: var(--Tab-Bg-Current);
        font-weight: bold;
      }
    }
  }
  .tab-content {
    overflow: auto;
    .view-information-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }
  }

  .share-list-search-container {
    display: grid;
    grid-template-columns: repeat(2, 10rem) 8rem;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--gray-200, #eaecf0);
  }

  .modal-content {
    width: 640px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--Gray-600);
  }

  .change-status-content {
    padding: 20px;
    width: 384px;
  }

  .category-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    height: 500px;

    .category {
      padding: 1rem 2rem;
      border-right: 1px solid var(--Gray-200);
      .category-item {
        display: flex;
        align-items: center;
        padding: 8px;
        border-radius: 8px;
        background: var(--Base-White);
        align-items: center;
        cursor: pointer;

        &.active {
          background: var(--Primary-600);
          color: var(--Base-White);
        }
      }
    }

    .category-view {
      padding: 1rem 2rem;
      .view-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .view-link {
          cursor: pointer;
          color: var(--Primary-700);
          font-weight: 600;
        }
      }
    }
  }

  .search-content {
    padding: 10px;
    border-bottom: 1px solid var(--Gray-200);
  }

  .search-input {
    position: relative;
    color: var(--Gray-500);

    .input-field {
      box-sizing: border-box;
      padding: 10px;
      padding-left: 40px;
      border: none;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .start-icon {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .end-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .preview-button {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }

  .headline {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .error {
    color: var(--Error-600);
  }

  .error-border {
    border: 1px solid var(--Error-300);
  }
`
const TabMenuContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  .tab-menu {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-500);
    padding: 0.75rem;
    :hover {
      cursor: pointer;
    }
    &.active {
      color: var(--Primary-700);
      border-bottom: 2px solid var(--Primary-700);
      background: var(--Primary-50);
    }
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.align-items-center {
    align-items: center;
  }
`

// initial data
const initTypeDropdown = [
  {
    text: 'Info',
    value: 'Info',
  },
  {
    text: 'Acknowledge',
    value: 'Acknowledge',
  },
  {
    text: 'Acknowledge and Sign',
    value: 'Acknowledge and Sign',
  },
]

function CompanyInformationShareServic() {
  const { t } = useTranslation()
  // column
  const assignmentColumn = [
    {
      Header: t('action'),
      accessor: 'action',
      disableSortBy: false,
    },
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('document_name'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('category'),
      accessor: 'category',
      disableSortBy: false,
    },
    {
      Header: t('type'),
      accessor: 'type',
      disableSortBy: false,
    },
    {
      Header: t('latest_version'),
      accessor: 'version',
      disableSortBy: false,
    },
    {
      Header: t('publish_date'),
      accessor: 'publish_date',
      disableSortBy: false,
    },
    {
      Header: t('due_date'),
      accessor: 'due_date',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('view_document'),
      accessor: 'view_document',
      disableSortBy: false,
    },
  ]
  const dispatch = useDispatch()
  // store reducer
  const {
    pendingAssignments,
    reviewAssignments,
    overdueAssignments,
    notiEmailDocId,
    page,
    pageCount,
    total,
    limit,
    isLoading,
    state,
  } = useSelector(selectSharedServiceEmployee)
  const { categories } = useSelector(selectDocumentTemplate)
  // state
  const [assigments, setAssignments] = useState([])
  const [tab, setTab] = useState('pending')
  // filter
  const [search, setSearch] = useState('')
  const [categorySelected, setCategorySelected] = useState('')
  const [typeSelected, setTypeSelected] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  // dropdown list
  const [categoryList, setCategoryList] = useState([])
  // modal
  const [isModalOpen, setIsModalOpen] = useState(false)

  const startNowTask = useCallback(
    (id) => {
      dispatch(getAssignmentById(id))
      setIsModalOpen(true)
    },
    [tab]
  )
  const fetchPendingAssignments = useCallback(
    (filter) => {
      dispatch(getPendingAssignments(filter))
    },
    [dispatch]
  )
  const fetchReviewAssignments = useCallback(
    (filter) => {
      dispatch(getReviewAssignments(filter))
    },
    [dispatch]
  )
  const fetchOverdueAssignments = useCallback(
    (filter) => {
      dispatch(getOverdueAssignments(filter))
    },
    [dispatch]
  )

  // useEffect for initial fetching data
  useEffect(() => {
    dispatch(getDocumentCategory())
  }, [])

  // useEffect is used to check if notiEmailDocId exists; if it doesn't, it will automatically open
  useEffect(() => {
    if (notiEmailDocId) {
      startNowTask(notiEmailDocId)
    }
  }, [notiEmailDocId])

  // useEffect is used to map category option list dropdown
  useEffect(() => {
    const newCategoryList = categories.map((v) => ({
      value: v?.id,
      text: v?.name,
    }))
    setCategoryList(newCategoryList)
  }, [categories])

  // useEffect is used for fetching assignment data according to type
  useEffect(() => {
    const filter = { page: 1, pageSize: 10 }
    if (tab === 'pending') fetchPendingAssignments(filter)
    else if (tab === 'reviewed') fetchReviewAssignments(filter)
    else fetchOverdueAssignments(filter)
  }, [tab])

  // useEffect for mapping pending assignment table data
  useEffect(() => {
    const currentDate = dayjs()
    const newPendingAssignmentList = pendingAssignments.map((v, i) => {
      const { name, type, version, publish_date, due_date, is_star, category, files } = v.document_acknow
      const dueDateObj = dayjs(due_date)
      const isOverDueDate = currentDate?.startOf('day')?.isAfter(dueDateObj.startOf('day'))
      return {
        ...v,
        no: (
          <FlexContainer className="align-items-center">
            <span className="mr-8-px">{i + 1}</span>
            {is_star === 1 && <BsStarFill className="icon yellow" />}
          </FlexContainer>
        ),
        name,
        version: version?.toFixed(1),
        publish_date: DateDisplay(publish_date),
        due_date: <div className={`${isOverDueDate ? 'over-due-date' : ''}`}>{DateDisplay(due_date)}</div>,
        status: (
          <span className={`badge ${v.status === 'Accept' ? 'green' : v.status === 'Decline' ? 'red' : 'orange'}`}>
            {v.status}
          </span>
        ),
        action: <Button onClick={() => startNowTask(v.id)}>{t('start_now')}</Button>,
        view_document:
          files && files[0]?.url ? (
            <img className="icon" src={pdfIcon} onClick={() => openPdfFile(files[0].url)} />
          ) : (
            '-'
          ),
        category: category?.name,
        type,
      }
    })

    setAssignments(newPendingAssignmentList)
  }, [pendingAssignments])

  // useEffect for mapping reviewed assignment table data
  useEffect(() => {
    const currentDate = dayjs()
    const newReviewAssignmentList = reviewAssignments.map((v, i) => {
      const { name, version, publish_date, due_date, is_star, category, type, files } = v.document_acknow
      const dueDateObj = dayjs(due_date)
      const isOverDueDate = currentDate?.startOf('day')?.isAfter(dueDateObj.startOf('day'))
      return {
        ...v,
        no: (
          <FlexContainer className="align-items-center">
            <span className="mr-8-px">{i + 1}</span>
            {is_star === 1 && <BsStarFill className="icon yellow" />}
          </FlexContainer>
        ),
        name,
        version: version?.toFixed(1),
        publish_date: DateDisplay(publish_date),
        due_date: <div className={`${isOverDueDate ? 'over-due-date' : ''}`}>{DateDisplay(due_date)}</div>,
        status: (
          <span
            className={`badge ${
              v.status === 'Accept' || v.status === 'Read' ? 'green' : v.status === 'Decline' ? 'red' : 'orange'
            }`}
          >
            {v.status === 'Read' ? 'Accept' : v.status}
          </span>
        ),
        action: <ButtonOutline onClick={() => startNowTask(v.id)}>{t('see_detail')}</ButtonOutline>,
        view_document:
          files && files[0]?.url ? (
            <img className="icon" src={pdfIcon} onClick={() => openPdfFile(files[0].url)} />
          ) : (
            '-'
          ),
        category: category?.name,
        type,
      }
    })

    setAssignments(newReviewAssignmentList)
  }, [reviewAssignments])

  // useEffect for mapping overdue assignment table data
  useEffect(() => {
    const currentDate = dayjs()
    const newOverdueAssignmentList = overdueAssignments.map((v, i) => {
      const { name, version, publish_date, due_date, is_star, category, type, files } = v.document_acknow
      const dueDateObj = dayjs(due_date)
      const isOverDueDate = currentDate?.startOf('day')?.isAfter(dueDateObj.startOf('day'))
      return {
        ...v,
        no: (
          <FlexContainer className="align-items-center">
            <span className="mr-8-px">{i + 1}</span>
            {is_star === 1 && <BsStarFill className="icon yellow" />}
          </FlexContainer>
        ),
        name,
        version: version?.toFixed(1),
        publish_date: DateDisplay(publish_date),
        due_date: <div className={`${isOverDueDate ? 'over-due-date' : ''}`}>{DateDisplay(due_date)}</div>,
        status: (
          <span className={`badge ${v.status === 'Accept' ? 'green' : v.status === 'Decline' ? 'red' : 'orange'}`}>
            {v.status}
          </span>
        ),
        action: <Button onClick={() => startNowTask(v.id)}>{t('start_now')}</Button>,
        view_document:
          files && files[0]?.url ? (
            <img className="icon" src={pdfIcon} onClick={() => openPdfFile(files[0].url)} />
          ) : (
            '-'
          ),
        category: category?.name,
        type,
      }
    })

    setAssignments(newOverdueAssignmentList)
  }, [overdueAssignments])

  // useEffect is used to catch up state for refreshing data according to type
  useEffect(() => {
    const filter = { page: 1, pageSize: 10 }
    if (isLoading === false && state === 'SIGN_SIGNATURE.SUCCESS') {
      if (tab === 'pending') fetchPendingAssignments(filter)
      else if (tab === 'reviewed') fetchReviewAssignments(filter)
      else fetchOverdueAssignments(filter)
    }
    if (isLoading === false && state === 'ACKNOWLEDGE_ASSIGNMENT.SUCCESS') {
      if (tab === 'pending') fetchPendingAssignments(filter)
      else if (tab === 'reviewed') fetchReviewAssignments(filter)
      else fetchOverdueAssignments(filter)
    }
    if (isLoading === false && state === 'READ_INFO_ASSIGNMENT.SUCCESS') {
      if (tab === 'pending') fetchPendingAssignments(filter)
      else if (tab === 'reviewed') fetchReviewAssignments(filter)
      else fetchOverdueAssignments(filter)
    }
    if (isLoading === false && state === 'GET_ASSIGNMENT_BY_ID.FAILURE') {
      dispatch(setNotiEmailDocId(null))
      dispatch(setAssignmentInfo({}))
      const filter = { page: 1, pageSize: 10 }
      fetchPendingAssignments(filter)
    }
  }, [isLoading, state, tab])

  // useEffect is used to filter by dropdown
  useEffect(() => {
    if (categorySelected !== '' || typeSelected !== '') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[$or][${i}][document_acknow][name][$contains]`]: v?.name,
      }))

      const filter = {
        page: 1,
        pageSize: limit,
        category: categorySelected,
        type: typeSelected,
        multipleSearch,
      }
      if (tab === 'pending') fetchPendingAssignments(filter)
      else if (tab === 'reviewed') fetchReviewAssignments(filter)
      else fetchOverdueAssignments(filter)
    }
  }, [categorySelected, typeSelected])

  // search
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`filters[$or][${i}][document_acknow][name][$contains]`]: v?.name,
      }))

      const filter = {
        page: 1,
        pageSize: limit,
        category: categorySelected,
        type: typeSelected,
        multipleSearch,
      }

      if (tab === 'pending') fetchPendingAssignments(filter)
      else if (tab === 'reviewed') fetchReviewAssignments(filter)
      else fetchOverdueAssignments(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const onClearSearch = () => {
    const filter = {
      page: 1,
      pageSize: limit,
      category: '',
      type: '',
      multipleSearch: [],
    }
    if (tab === 'pending') fetchPendingAssignments(filter)
    else if (tab === 'reviewed') fetchReviewAssignments(filter)
    else fetchOverdueAssignments(filter)
    clearData()
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][document_acknow][name][$contains]`]: v?.name,
    }))

    const filter = {
      page: 1,
      pageSize: limit,
      category: categorySelected,
      type: typeSelected,
      multipleSearch,
    }
    if (tab === 'pending') fetchPendingAssignments(filter)
    else if (tab === 'reviewed') fetchReviewAssignments(filter)
    else fetchOverdueAssignments(filter)
    setSearch('')
  }
  const onChangePagination = async (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$or][${i}][document_acknow][name][$contains]`]: v?.name,
    }))
    const filter = {
      page: value.page,
      pageSize: limit,
      category: categorySelected,
      type: typeSelected,
      multipleSearch,
    }

    if (tab === 'pending') fetchPendingAssignments(filter)
    else if (tab === 'reviewed') fetchReviewAssignments(filter)
    else fetchOverdueAssignments(filter)
  }
  const clearData = () => {
    setSearch('')
    setCategorySelected('')
    setTypeSelected('')
    setSearchFilters([])
  }

  // general
  const openPdfFile = (url) => {
    window.open(url, '_blank')
  }
  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState)
  }
  const toggleTab = (tab) => {
    setTab(tab)
    clearData()
  }

  return (
    <Div>
      <div className="mb-1-rem">
        <div className="content-title">{t('company_information')}</div>
        <div className="">{t('company_information_sub_heading')}</div>
      </div>

      <TabMenuContainer>
        <span className={`tab-menu ${tab === 'pending' ? 'active' : ''}`} onClick={() => toggleTab('pending')}>
          {t('pending')}
        </span>
        <span className={`tab-menu ${tab === 'reviewed' ? 'active' : ''}`} onClick={() => toggleTab('reviewed')}>
          {t('reviewed')}
        </span>
        <span className={`tab-menu ${tab === 'overdue' ? 'active' : ''}`} onClick={() => toggleTab('overdue')}>
          {t('overdue')}
        </span>
      </TabMenuContainer>

      <div className="table-wrapper">
        <div className="table-header">
          <div className="table-head">
            <div className="group">
              <div className="table-title">
                {tab === 'pending' ? t('pending') : tab === 'reviewed' ? t('reviewed') : t('overdue')}
              </div>
              <div className="table-total">
                {total} {t('task')}
              </div>
            </div>
          </div>

          <div>
            <div className="search-container">
              <div className="headline-input">{t('search')}</div>
              <div className="headline-input">{t('category')}</div>
              <div className="headline-input">{t('type')}</div>
            </div>
            <div className="search-container">
              <SearchText
                className="mb-1-rem"
                placeholder={t('search')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSubmit={onSearch}
                onClear={onClearSearch}
              />
              <Dropdown
                placeHolder={t('category')}
                optionList={categoryList}
                value={categorySelected}
                onChange={(value) => setCategorySelected(value)}
              />
              <Dropdown
                placeHolder={t('type')}
                optionList={initTypeDropdown}
                value={typeSelected}
                onChange={(value) => setTypeSelected(value)}
              />
              <ButtonOutline className="d-flex justify-content-center" onClick={onClearSearch}>
                {t('clear')}
              </ButtonOutline>
            </div>
            <div className="badge-search-content">
              {searchFilters.map((v) => (
                <div className="badge" key={v.key}>
                  <span className="name">{v?.name}</span>{' '}
                  <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <Table
          columns={assignmentColumn}
          data={assigments}
          onStateChange={onChangePagination}
          pageCount={pageCount}
          page={page}
        />
      </div>

      <ModalPdpa isModalOpen={isModalOpen} onClose={toggleModal} />
    </Div>
  )
}

export default CompanyInformationShareServic
