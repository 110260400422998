import { APPROVER } from 'constants/operation-process'
import { initPagination } from 'constants/pagination'
import { OP } from 'redux/actionTypes'
import { getAPI, postAPI } from 'utils/api'
import { hideLoading, showLoading } from '../loading'

const { GET, POST, CLEAR } = OP

const pathname = '/api/op-approvers'

const deliveriesParams = {
  populate: {
    files: {
      filters: { status: 'Active' },
      populate: {
        file: {
          fields: ['mime', 'url', 'createdAt'],
        },
        employee: {
          fields: ['first_name', 'last_name'],
        },
      },
    },
    document: {
      populate: {
        business_doc: {
          fields: ['id', 'doc_id'],
        },
        signers: {
          populate: {
            employee: {
              fields: ['first_name', 'last_name'],
              populate: {
                position: {
                  fields: ['name'],
                },
              },
            },
          },
        },
      },
    },
  },
}

export default function OPApproval({ headers, company_info }) {
  const getApproval = () => ({
    owner: (payload) => {
      const { page, pageSize } = payload?.pagination || initPagination
      const { REQUEST, SUCCESS, FAILURE } = GET.APPROVERS_OWNER

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            pagination: { page, pageSize },
            filters: {
              ...payload?.filters,
              status: APPROVER.STATUS.WaitingForApprove,
            },
            populate: {
              sw: {
                fields: ['code', 'name'],
                populate: {
                  sp: {
                    fields: ['name', 'code'],
                    populate: {
                      employee: {
                        fields: ['first_name', 'last_name'],
                      },
                    },
                  },
                },
              },
            },
          }

          const res = await getAPI({
            url: `${pathname}/me/company/${company_info.id}`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, ...res })
        } catch (error) {
          return dispatch({ type: FAILURE })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    byId: (approverId) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.APPROVER_BY_ID

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            fields: ['code', 'due_date', 'status'],
            populate: {
              sw: {
                fields: ['code', 'name', 'description', 'due_date', 'status'],
                populate: {
                  sp: {
                    fields: ['name', 'code'],
                  },
                  employee: {
                    fields: ['first_name', 'last_name'],
                  },
                  deliveries: deliveriesParams,
                  tasks: {
                    fields: ['name', 'status', 'code', 'description'],
                    populate: {
                      department: {
                        fields: ['name'],
                      },
                      employee: {
                        fields: ['first_name', 'last_name'],
                      },
                      deliveries: deliveriesParams,
                    },
                  },
                  reviewers: {
                    fields: ['status', 'updatedAt'],
                    populate: {
                      employee: {
                        fields: ['first_name', 'last_name'],
                      },
                    },
                  },
                  approver: {
                    fields: ['status', 'updatedAt'],
                    populate: {
                      employee: {
                        fields: ['first_name', 'last_name'],
                      },
                    },
                  },
                },
              },
              employee: {
                fields: ['id'],
              },
            },
          }

          const res = await getAPI({
            url: `${pathname}/${approverId}`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, ...res })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    logs: (approverId) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.APPROVER_BY_ID_LOGS

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            sort: ['createdAt:desc'],
            fields: ['createdAt', 'action'],
            populate: {
              employee: {
                fields: ['first_name', 'last_name'],
              },
            },
          }

          const res = await getAPI({
            url: `${pathname}/${approverId}/logs`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, ...res })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const postApproval = () => ({
    approve: (approverId, note) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.APPROVAL_APPROVE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const data = { note }

          const res = await postAPI({
            url: `${pathname}/${approverId}/approve`,
            headers,
            data,
          })

          return dispatch({ type: SUCCESS, ...res })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    reject: (approverId, note) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.APPROVAL_REJECT

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const data = { note }

          const res = await postAPI({
            url: `${pathname}/${approverId}/reject`,
            headers,
            data,
          })

          return dispatch({ type: SUCCESS, ...res })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const clearApproval = () => (dispatch) => {
    return dispatch({ type: CLEAR.APPROVER })
  }

  return {
    getApproval,
    postApproval,
    clearApproval,
  }
}
