import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getProjectTaskById } from '../../../redux/actions/projectTask'
import { getSummaryTask } from '../../../redux/actions/summaryTask'
import { selectProjectTask, selectSummaryTask } from '../../../redux/selectors'
import { convertDateFormat, dlog } from '../../../utils/helper'
import Table from '../../common/Table'
import moment from 'moment'
import CapsuleStatus from '../../widget/element/CapsuleStatus'

export default function TableProjectTask({
  isUpdate = false,
  filter,
  onClickTableItem,
  onTotalSummaryData,
  projectId,
}) {
  const taskColumn = [
    {
      Header: 'Task Id',
      accessor: 'task_id',
      disableSortBy: false,
    },
    {
      Header: 'Task Name',
      accessor: 'task_name',
      disableSortBy: false,
    },
    {
      Header: 'Milestone',
      accessor: 'milestone',
      disableSortBy: false,
    },
    {
      Header: 'Scope of work',
      accessor: 'scope_of_work',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: 'Created by',
      accessor: 'created_by',
      disableSortBy: false,
    },
    {
      Header: 'Assign To',
      accessor: 'assign_to',
      disableSortBy: false,
    },
    {
      Header: 'Closed Date',
      accessor: 'closed_date',
      disableSortBy: false,
    },
  ]

  const [dataViews, setDataViews] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const { taskList, isLoading, total, skip, limit, pageSize, page, pageCount, totals } = useSelector(selectSummaryTask)
  const { projectTaskData } = useSelector(selectProjectTask)
  const [isSetProjectData, setIsSetProjectData] = useState(false)
  const dispatch = useDispatch()

  // const fetchData = useCallback(
  //   async (pagination, filter) => {
  //     dispatch(getSummaryTask(pagination, filter))
  //   },
  //   [dispatch]
  // )

  useEffect(() => {(async () => {
    if (projectId) {
      await dispatch(getProjectTaskById(projectId, filter))
      // if(dataRes) {
      //   dlog(dataRes.data, "DATA")
      //   let viewList = []
      //   viewList = projectTaskData?.task.map((item) => ({
      //     ...item,
      //     task_id: item.id,
      //     task_name: (
      //       <span className="txt-product-name" onClick={() => handleClickProductName(item)}>
      //         {item.task_name}
      //       </span>
      //     ),
      //     project: item.project_refer,
      //     milestone: item.milestone,
      //     scope_of_work: item.scope_of_work,
      //     status: item.status,
      //     created_by: item.create_by,
      //     closed_date: item.close_date != '-' ? convertDateFormat(item.close_date,"DD/MMM/YYYY") : '-',
      //   }))
      //   setDataViews([...viewList])
      //   // setIsSetProjectData(true)
      // }
    }
  })()}, [dispatch, filter, isUpdate])

  useEffect(() => {
    if (projectTaskData) {
      let viewList = []
      viewList = projectTaskData?.task.map((item) => ({
        ...item,
        task_id: item.id,
        task_name: (
          <span className="txt-product-name" onClick={() => handleClickProductName(item)}>
            {item.task_name}
          </span>
        ),
        project: item.project_refer,
        milestone: item.milestone,
        scope_of_work: item.scope_of_work,
        status: <CapsuleStatus status={item.status} />,
        created_by: item.create_by,
        assign_to: item.assign_to,
        closed_date: item.close_date != '-' ? convertDateFormat(item.close_date,"DD/MMM/YYYY") : '-',
      }))
      setDataViews([...viewList])
    }
  }, [projectTaskData])

  // useEffect(() => {
  //   dlog(projectId, 'tableProjectTask_projectId')
    // const viewList = taskList.map((item) => ({
    //   ...item,
    //   task_id: item.task_id,
    //   task_name: (
    //     <span className="txt-product-name" onClick={() => handleClickProductName(item)}>
    //       {item.task_name}
    //     </span>
    //   ),
    //   project: item.project_refer,
    //   milestone: item.task_type,
    //   scope_of_work: item.task_scope,
    //   status: item.status,
    //   created_by: item.create_by,
    //   closed_date: item.closed_date != '' ? item.closed_date : '-',
    // }))
    // setDataViews(viewList)
  // }, [taskList])

  // useEffect(() => {
  //   const pagination = {
  //     page: page,
  //     pageSize: pageSize,
  //     pageCount: pageCount,
  //     total: total,
  //   }
  //   const totalPage = Math.ceil(total / pageSize)
  //   setTotalPages(totalPage)
  //   // fetchData(pagination, filter)
  // }, [ pageSize, pageCount, page, total, isUpdate])

  // const onChangePagination = async (value) => {
  //   const pagination = {
  //     page: value.page,
  //     pageSize: pageSize,
  //     pageCount: pageCount,
  //     total: total,
  //   }
  //   // fetchData(pagination, filter)
  //   setCurrentPage(value.page)
  // }

  const handleClickProductName = (itemData) => {
    if (onClickTableItem) {
      onClickTableItem(itemData.id)
    }
  }

  return (
    <Styled>
      <Table
        columns={taskColumn}
        data={dataViews}
        // onStateChange={onChangePagination}
        // pageCount={totalPages}
        // page={currentPage}
        loading={isLoading}
      />
    </Styled>
  )
}
const Styled = styled.div`
  .txt-product-name {
    cursor: pointer;
    :hover {
      /* color: red; */
      font-weight: bold;
    }
  }
`
