import { DialogTitle, IconButton, Modal, ModalClose, ModalDialog, Tooltip } from '@mui/joy'
import { Container as MuiContainer, Stack, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { forwardRef, useCallback, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { BsPip } from 'react-icons/bs'
import { CgArrowsExpandLeft } from 'react-icons/cg'
import { RiAddLine, RiDeleteBinLine, RiEyeLine } from 'react-icons/ri'

import Button from 'components/common/Button'
import Tabs from 'components/common/Tabs'
import Typography from 'components/common/Typography'
import ViewSopTpl from 'components/feature/operation-process/form/sop-tpl/view'
import DynamicForm from 'components/form2/dynamic-form'
import { Autocomplete } from 'components/form2/renderFields'
import { CHAIN_LINK_TEMPLATE } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'

const initModalProps = {
  sop: {
    id: null,
    open: false,
    layout: 'center',
  },
}

const CreateChainLinkTpl = forwardRef(function CreateChainLinkTpl(props, ref) {
  const { onSubmit, id, readonly, containerProps, noExtraActions } = props
  const lang = localStorage.getItem('lang') || 'en'
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [previewModalProps, setPreviewModalProps] = useState(initModalProps)

  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)

  const { extra: extraActions, chainLinkTpl: chainLinkTplActions, sopTpl: sopTplActions } = opActions()
  const { departments, chainLinkTpl, sopTpls, sopTpl } = opProcSelector

  const { getExtra } = extraActions()
  const { getChainLinkTpl, postChainLinkTpl, deleteChainLinkTpl, clearChainLinkTpl } = chainLinkTplActions()
  const { getSopTpl, clearSopTpl } = sopTplActions()

  const fetchChainLinkTplByID = useCallback(
    async (id) => {
      return dispatch(getChainLinkTpl(id).byId())
    },
    [dispatch]
  )

  const fetchDepartments = useCallback(
    async (payload) => {
      return dispatch(getExtra().departments(payload))
    },
    [dispatch]
  )

  const fetchSopTpls = useCallback(
    async (payload) => {
      return dispatch(getSopTpl().list({ ...payload, pagination: { page: 1, pageSize: 1000 } }))
    },
    [dispatch]
  )

  const fetchChainLinkTpls = useCallback(
    async (payload) => {
      return dispatch(getChainLinkTpl().list(payload))
    },
    [dispatch]
  )

  const postActiveChainLinkTpl = async (chainLinkTplId) => {
    return dispatch(postChainLinkTpl(chainLinkTplId).active())
  }

  const postInActiveChainLinkTpl = async (chainLinkTplId) => {
    return dispatch(postChainLinkTpl(chainLinkTplId).inactive())
  }

  const defaultValues = {
    name: '',
    description: '',
    chains: [],
  }

  const methods = useForm({
    defaultValues: async () => {
      await fetchDepartments()
      await fetchSopTpls()

      if (id) {
        const response = await fetchChainLinkTplByID(id)

        if (response?.data) {
          return response.data
        }

        return defaultValues
      }

      return defaultValues
    },
  })

  const formSchema = {
    properties: {
      'section-1': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('detail')}</Typography>
            <Typography variant="sectionText">{t('chainLink_detail_subtitle')}</Typography>
          </HeaderSection>
        ),
      },
      'divider-1': { type: 'divider' },
      name: {
        type: 'text',
        label: t('chainLink_tpl_name'),
        placeholder: t('chainLink_tpl_name_placeholder'),
        validator: {
          required: true,
          message: 'This field is required',
          minLength: 1,
          maxLength: 50,
        },
      },
      description: {
        type: 'textArea',
        label: t('chainLink_tpl_guideline'),
        props: {
          minRows: 3,
          maxRows: 5,
          multiline: true,
        },
      },
    },
    required: !readonly ? ['name'] : [],
  }

  const formSchema_2 = {
    properties: {
      'section-2': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('sop_list_title')}</Typography>
            <Typography variant="sectionText">{t('sop_list_subtitle')}</Typography>
          </HeaderSection>
        ),
      },
      'divider-2': { type: 'divider' },
      chains: {
        type: 'fieldArray',
        props: {
          render({ field, index, remove }) {
            const key = `chains.${index}`
            const sopSelectedId = methods.getValues(`${key}.sp.id`)

            return (
              <Draggable
                key={field.fieldId}
                draggableId={`chain-item-${field.fieldId}`}
                index={index}
                isDragDisabled={readonly}
              >
                {(provided, snapshot) => {
                  const { isDragging } = snapshot || {}

                  return (
                    <ContentList
                      ref={provided.innerRef}
                      className={`${isDragging ? 'dragging' : ''} ${
                        methods.formState.errors?.chains?.[index] ? 'error' : ''
                      } `}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Stack
                        className="sop-item-title"
                        sx={{
                          padding: '0.5rem 0.75rem',
                          borderRadius: '0.5rem',
                          background: 'var(--Primary-50)',
                          border: '1px solid var(--Primary-200)',
                          marginBottom: '1rem',
                          width: 'fit-content',
                        }}
                      >
                        <Typography variant="cardTitle" sx={{ color: 'var(--Primary-700)' }}>{`${t('sop')} – ${
                          index + 1
                        }`}</Typography>
                      </Stack>

                      <section key={`${key}.sp.department.id`}>
                        <label>{t('department')}:</label>
                        <Stack gap="0.5rem">
                          <Stack direction="row" gap="1rem" alignItems="center">
                            {readonly ? (
                              methods.getValues(`${key}.sp.department.name`)
                            ) : (
                              <Controller
                                control={methods.control}
                                name={`${key}.sp.department`}
                                rules={{ required: `กรุณาเลือก department` }}
                                defaultValue={field?.sp?.department || undefined}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    onChange={(val, selectVal) => {
                                      if (!selectVal) {
                                        methods.setValue(`${key}.sp`, null)
                                      }

                                      methods.setValue(`${key}.sp.department`, selectVal)
                                      methods.setValue(`${key}.sp.id`, null)
                                      methods.setValue(`${key}.sp.name`, null)

                                      field.onChange(selectVal)
                                    }}
                                    value={field.value || methods.watch(`${key}.sp.department`)}
                                    options={departments || []}
                                    getOptionLabel={(option) => option?.name || ''}
                                    renderOption={(props, option, { selected }) => (
                                      <AutocompleteOption {...props}>
                                        <p className="title">{`${option?.name}`}</p>
                                      </AutocompleteOption>
                                    )}
                                    isOptionEqualToValue={(option, value) => {
                                      return option?.id === value?.id
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} placeholder={t('department_placeholder')} />
                                    )}
                                    sx={{ width: '100%' }}
                                  />
                                )}
                              />
                            )}
                          </Stack>
                        </Stack>
                      </section>

                      <section key={`${key}.sp`}>
                        <label>{t('sop')}:</label>
                        <Stack gap="0.5rem">
                          <Stack direction="row" gap="1rem" alignItems="center">
                            {readonly ? (
                              methods.getValues(`${key}.sp.name`)
                            ) : (
                              <Controller
                                control={methods.control}
                                name={`${key}.sp`}
                                rules={{ required: `กรุณาเลือก${field?.label}` }}
                                defaultValue={field?.sp || undefined}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    disabled={!methods.watch(`${key}.sp.department.id`)}
                                    onChange={(val, selectVal) => {
                                      methods.setValue(`${key}.sp.id`, selectVal?.id)
                                      methods.setValue(`${key}.sp.name`, selectVal?.name)
                                      methods.setValue(`${key}.sp.code`, selectVal?.code)

                                      field.onChange(selectVal)
                                    }}
                                    value={methods.watch(`${key}.sp`)}
                                    options={
                                      sopTpls?.filter(
                                        (i) => i?.department?.id === methods.watch(key)?.sp?.department?.id
                                      ) || []
                                    }
                                    getOptionLabel={(option) => {
                                      if (option?.id && option?.name) {
                                        return `${option.name} - ${option.code}`
                                      }

                                      return ''
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                      <AutocompleteOption {...props}>
                                        <p className="title">{`${option?.name}`}</p>
                                        <p className="supporting-text">{`${option?.code}`}</p>
                                      </AutocompleteOption>
                                    )}
                                    isOptionEqualToValue={(option, value) => {
                                      return option?.id === value?.id
                                    }}
                                    renderInput={(params) => <TextField {...params} placeholder={'sop_placeholder'} />}
                                    sx={{ width: '100%' }}
                                  />
                                )}
                              />
                            )}

                            {methods.watch('chains')?.[index]?.sp?.department?.id && (
                              <IconButton
                                onClick={() => sopSelectedId && handlePreviewSop(sopSelectedId)}
                                size="sm"
                                variant="outlined"
                              >
                                <RiEyeLine />
                              </IconButton>
                            )}
                          </Stack>
                        </Stack>
                      </section>

                      {!readonly && (
                        <Tooltip title={'remove_sop'}>
                          <IconButton
                            className="remove-button"
                            size="sm"
                            onClick={() => {
                              const chains = methods.watch('chains')

                              remove(index)
                              methods.setValue(
                                'chains',
                                chains.filter((_, i) => i !== index)
                              )
                            }}
                          >
                            <RiDeleteBinLine fontSize={16} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ContentList>
                  )
                }}
              </Draggable>
            )
          },
          ...(!readonly && {
            actions({ append }) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginTop: methods.getValues('chains')?.length > 0 && '1rem',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      append({ sp: { department: { id: null }, id: null } })
                    }}
                    startIcon={<RiAddLine />}
                  >
                    {t('add_sop')}
                  </Button>
                </Stack>
              )
            },
          }),
        },
      },
    },
    required: !readonly ? ['chains'] : [],
  }

  const handleToggleModal = (modalKey, isOpen) => {
    setPreviewModalProps((prev) => ({
      ...prev,
      [modalKey]: {
        ...prev[modalKey],
        open: isOpen || !prev[modalKey].open,
      },
    }))
  }

  const handlePreviewSop = (sopId) => {
    setPreviewModalProps((prev) => {
      return {
        ...prev,
        sop: {
          ...prev.sop,
          id: sopId,
          open: true,
        },
      }
    })
  }

  const tabItems = [
    {
      key: 'overview',
      label: t('overview'),
      content: (
        <DynamicForm
          ref={ref}
          methods={methods}
          onSubmit={onSubmit}
          schema={formSchema}
          readonly={readonly}
          formProps={{
            className: 'chainLink-tpl-create-form',
          }}
        />
      ),
    },
    {
      key: 'sop',
      label: t('sop'),
      content: (
        <DynamicForm
          ref={ref}
          methods={methods}
          onSubmit={onSubmit}
          schema={formSchema_2}
          readonly={readonly}
          formProps={{
            className: 'chainLink-tpl-create-form',
          }}
        />
      ),
    },
  ]

  const readonlyTabItems = [
    {
      key: 'logs',
      label: t('logs'),
      content: 'Not available yet.',
    },
  ]

  const renderExtraButton = () => {
    let componentsArray = []

    if (chainLinkTpl?.status === CHAIN_LINK_TEMPLATE.STATUS.Inactive) {
      componentsArray.push(
        <Button variant="outlined" onClick={handleToggleStatus}>
          {t('active')}
        </Button>,
        <Button variant="outlined" color="error" onClick={handleDeleteSowTpl}>
          {t('delete')}
        </Button>
      )
    }

    if (chainLinkTpl?.status === CHAIN_LINK_TEMPLATE.STATUS.Active) {
      componentsArray.push(
        <Button variant="outlined" color="error" onClick={handleToggleStatus}>
          {t('in_active')}
        </Button>
      )
    }

    return (
      <>
        {componentsArray.map((component, index) => {
          return component
        })}
        <Button variant="outlined" color="primary" onClick={handleEditClick}>
          {t('Edit')}
        </Button>
      </>
    )
  }

  const handleEditClick = () => {
    if (chainLinkTpl?.id) {
      navigate(`${location.pathname}?id=${chainLinkTpl.id}&mode=edit`)
    }
  }

  const handleToggleStatus = () => {
    if (chainLinkTpl) {
      switch (chainLinkTpl.status) {
        case CHAIN_LINK_TEMPLATE.STATUS.Active:
          postInActiveChainLinkTpl(chainLinkTpl.id).then(() => {
            dispatch(getChainLinkTpl(chainLinkTpl.id).byId())
            fetchChainLinkTpls()
          })
          break

        case CHAIN_LINK_TEMPLATE.STATUS.Inactive:
          postActiveChainLinkTpl(chainLinkTpl.id).then(() => {
            dispatch(getChainLinkTpl(chainLinkTpl.id).byId())
            fetchChainLinkTpls()
          })
          break

        default:
          break
      }
    }
  }

  const handleDeleteSowTpl = () => {
    if (chainLinkTpl?.id) {
      dispatch(deleteChainLinkTpl(chainLinkTpl.id)).then(() => {
        navigate(location.pathname)
        dispatch(clearChainLinkTpl())
        fetchChainLinkTpls()
      })
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <Container className="sow-tpl-form-container" sx={{ ...containerProps }}>
          <Tabs
            items={[...tabItems, ...(readonly ? readonlyTabItems : [])]}
            defaultActiveKey="overview"
            sx={{ marginTop: '0.5rem' }}
            tabBarExtraContent={
              readonly && !noExtraActions && <TabBarExtraContainer>{renderExtraButton()}</TabBarExtraContainer>
            }
          />
        </Container>
      </FormProvider>

      <Modal
        open={previewModalProps.sop.open}
        onClose={() => {
          handleToggleModal('sop', false)
          dispatch(clearSopTpl())
        }}
      >
        <ModalDialog
          layout={previewModalProps.sop.layout}
          maxWidth={previewModalProps.sop.layout === 'fullscreen' ? '100vw' : 1440}
          minWidth={1440}
          sx={{
            padding: '0',
            background: 'var(--Base-White)',
          }}
        >
          <ModalClose />
          <DialogTitle
            sx={{
              justifyContent: 'flex-end',
              marginInlineEnd: '2.5rem',
              marginInlineStart: '1.5rem',
              paddingTop: '0.5rem',
              alignItems: 'center',
            }}
          >
            <Typography variant="sidepanelTitle" sx={{ flex: 'auto' }}>
              {t('preview')}: {sopTpl?.code}
            </Typography>

            {previewModalProps.sop.layout === 'center' ? (
              <Tooltip title={t('expand_fullscreen')}>
                <IconButton
                  onClick={() =>
                    setPreviewModalProps((prev) => {
                      return {
                        ...prev,
                        sop: {
                          ...prev.sop,
                          layout: 'fullscreen',
                        },
                      }
                    })
                  }
                  size="sm"
                >
                  <CgArrowsExpandLeft fontSize={13} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t('minimize_to_center')}>
                <IconButton
                  onClick={() =>
                    setPreviewModalProps((prev) => {
                      return {
                        ...prev,
                        sop: {
                          ...prev.sop,
                          layout: 'center',
                        },
                      }
                    })
                  }
                  size="sm"
                >
                  <BsPip fontSize={16} />
                </IconButton>
              </Tooltip>
            )}
          </DialogTitle>

          <Stack
            sx={{
              height: '100%',
              overflow: 'overlay',
              alignItems: 'center',
              mx: 'calc(-1 * var(--ModalDialog-padding))',
              px: 'var(--ModalDialog-padding)',
            }}
          >
            <MuiContainer maxWidth="xl">
              <ViewSopTpl id={previewModalProps.sop.id} noExtraActions readonly />
            </MuiContainer>
          </Stack>
        </ModalDialog>
      </Modal>
    </>
  )
})

export default CreateChainLinkTpl

const TabBarExtraContainer = styled('div')`
  display: flex;
  gap: 0.5rem;
`

const HeaderSection = styled('section')`
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  margin-top: 1rem;
`

const Container = styled('div')`
  height: auto;
  padding: 0 1.25rem 1rem 1.25rem;
  overflow &::-webkit-scrollbar {
    width: 0.75rem;
    background: rgba(0, 0, 0, 0.25); /* make scrollbar invisible by default */
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    opacity: 0.5;
  }

  &:active::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-track {
    background: var(--Base-White);
    border: 1px solid var(--Gray-200);
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    margin: 0 0.5rem;
  }
`

const AutocompleteOption = styled('li')`
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 0 0.75rem !important;
  gap: 0.5rem;

  .title {
    font-size: 14px;
    font-weight: 500;
    color: var(--Gray-700);
    line-height: 20px;
  }

  .supporting-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--Gray-500);
  }
`

const ContentList = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  background-color: var(--Base-White);
  padding: 1.5rem 3rem 1rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid var(--Gray-200);
  position: relative;
  transition: all 100ms ease-in-out;
  gap: 1rem;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

  &.error {
    border-color: var(--Error-500);
  }

  section.section-card-type {
    padding: 1rem 1.5rem;
    border: 1px solid var(--Gray-200);
    border-radius: 0.5rem;
  }

  &.dragging {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.8);
  }

  .remove-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    scale: 0;
    transition: all 100ms ease-in-out;
  }

  &:hover {
    scale: 1.005;

    .remove-button {
      scale: 1;
    }
  }

  @media not all and (min-width: 640px) {
    padding: 1rem 1.5rem 1rem 1.5rem;
    gap: 0.75rem;
  }
`
