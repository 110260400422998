import { getAPI, postAPI, putAPI } from '../../utils/api'

import {
  GET_SUMMARY_TASK,
  GET_SUMMARY_MY_TASK,
  GET_TASK_BY_ID,
  CREATE_TASK,
  EDIT_TASK,
  UPDATE_TASK_ACTION,
  GET_EMPLOYEE_TASK,
  GET_EMPLOYEE_TABLE_TASK,
  UPDATE_NOTE_ACTION,
  CREATE_DOCUMENT_TASK,
  GET_DOCUMENT_TASK,
  UPDATE_DOCUMENT_TASK,
  DELETE_DOCUMENT_TASK,
  ADD_DOCUMENT_ITEM,
  DELETE_DOCUMENT_ITEM,
  CREATE_DOC_REQUEST_APPROVE,
  GET_REQUEST_APPROVES,
  GET_REQUEST_APPROVE_ITEM,
  UPDATE_REQUEST_APPROVE_ITEM,
  GET_PROVINCE_LIST,
  GET_AMPHURE_LIST,
  GET_TAMBON_LIST,
  CREATE_SUB_TASK,
  UPDATE_SUB_TASK_ACTION,
  EDIT_SUB_TASK,
  GET_SUB_TASK_BY_ID,
  UPDATE_TASK_ROUTINE
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'
import { getAdminUser } from '../../utils/common'
import { dlog } from '../../utils/helper'

const user = getAdminUser()

export const getSummaryMyTask = (pagination,filters) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SUMMARY_MY_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    let filterPath = ''
    if (filters?.owner != null && filters?.owner != "") {
      let path = `&filters[owner]=${filters?.owner}`
      filterPath = filterPath + path
    }
    if (filters?.search != null) {
      let path = `&filters[name]=${filters?.search}`
      filterPath = filterPath + path
    }
    if (filters?.projectSelected != null && filters?.projectSelected != "") {
      let path = `&filters[project]=${filters?.projectSelected == -1 ? '' :filters.projectSelected}`
      filterPath = filterPath + path
    }
    if (filters?.scopeOfWorkSelected != null && filters?.scopeOfWorkSelected != "") {
      let path = `&filters[scope_of_work]=${filters.scopeOfWorkSelected == -1 ? '' :filters.scopeOfWorkSelected}`
      filterPath = filterPath + path
    }
    if (filters?.statusSelected != null && filters?.statusSelected != "") {
      let path = `&filters[status]=${filters.statusSelected == -1 ? '' :filters.statusSelected}`
      filterPath = filterPath + path
    }
    if (filters?.createBySelected != null && filters?.createBySelected != "") {
      let path = `&filters[create_by]=${filters.createBySelected== -1 ? '' :filters.createBySelected}`
      filterPath = filterPath + path
    }
    if (filters?.assignToSelected != null && filters?.assignToSelected != "") {
      let path = `&filters[assign_to]=${filters.assignToSelected== -1 ? '' :filters.assignToSelected}`
      filterPath = filterPath + path
    }
    if (filters?.dueDateSelected != null && filters?.dueDateSelected != "") {
      let path = `&filters[due_date]=${filters.dueDateSelected== '' ? '' :filters.dueDateSelected}`
      filterPath = filterPath + path
    }

    let paginatePath = ''
    if (pagination?.page != '' && pagination?.page != undefined) {
      let tmpPath = `&pagination[page]=${pagination?.page}`
      paginatePath = paginatePath + tmpPath
    }
    if (pagination?.pageSize != '' && pagination?.pageSize != undefined) {
      let tmpPath = `&pagination[pageSize]=${pagination?.pageSize}`
      paginatePath = paginatePath + tmpPath
    }
    
    // console.log('pagination: '+JSON.stringify(pagination,null,2))
    // console.log('filters: '+JSON.stringify(filters,null,2))
    const { data, total, meta } = await getAPI({
      // url: `/api/my-task?skip=${pagination.skip}&limit=${10}`+filterPath,
      url: `/api/my-task?`+paginatePath+filterPath,
      headers: headers,
    })
    
    return dispatch({
      type: GET_SUMMARY_MY_TASK.SUCCESS,
      data,
      total,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_SUMMARY_MY_TASK.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Summary My Task Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getSummaryTask = (pagination,filters) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SUMMARY_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    let filterPath = ''
    if (filters?.owner != null) {
      let path = `&filters[owner]=${filters?.owner}`
      filterPath = filterPath + path
    }
    if (filters?.search != null) {
      let path = `&filters[name]=${filters?.search}`
      filterPath = filterPath + path
    }
    if (filters?.scopeOfWorkSelected != null) {
      let path = `&filters[scope_work]=${filters.scopeOfWorkSelected == -1 ? '' :filters.scopeOfWorkSelected}`
      filterPath = filterPath + path
    }
    if (filters?.statusSelected != null) {
      let path = `&filters[status]=${filters.statusSelected == -1 ? '' :filters.statusSelected}`
      filterPath = filterPath + path
    }
    if (filters?.createBySelected != null) {
      let path = `&filters[create_by]=${filters.createBySelected== -1 ? '' :filters.createBySelected}`
      filterPath = filterPath + path
    }
    const { data, totals, meta } = await getAPI({
      url: `/api/tasks?pagination[page]=${pagination.page}&pagination[pageSize]=${10}`+filterPath,
      headers: headers,
    })

    return dispatch({
      type: GET_SUMMARY_TASK.SUCCESS,
      data,
      totals,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_SUMMARY_TASK.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Summary Task Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getTaskById = (id, filters) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TASK_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    let filterPath = '';
    if (filters?.searchText != null) {
      let path = `&filters[document_name]=${filters?.searchText}`
      filterPath = filterPath + path
    }

    if (filters?.typeSelected != null) {
      let path = `&filters[document_type]=${filters?.typeSelected}`
      filterPath = filterPath + path
    }

    if (filters?.statusSelected != null) {
      let path = `&filters[document_status]=${filters?.statusSelected}`
      filterPath = filterPath + path
    }
    if (filters?.subTaskStatus != null) {
      let path = `&filters[subtask_status]=${filters?.subTaskStatus}`
      filterPath = filterPath + path
    }

    const { data } = await getAPI({
      url: `/api/tasks/${id}?`+filterPath,
      headers: headers,
    })

    return dispatch({
      type: GET_TASK_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_TASK_BY_ID.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const createTask = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/tasks`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_TASK.SUCCESS,
      showAlert: true,
      alertMessage: 'Create TASK Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const editTask = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const dataRes = await putAPI({
      url: `/api/tasks/${id}`,
      data,
      headers: headers,
    })
    let tmpData = dataRes.data
    return dispatch({
      type: EDIT_TASK.SUCCESS,
      data: tmpData,
      showAlert: true,
      alertMessage: 'Edit TASK Successful',
    })
  } catch (err) {
    return dispatch({ type: EDIT_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateTaskAction = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_TASK_ACTION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/task-actions`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_TASK_ACTION.SUCCESS,
      showAlert: true,
      alertMessage: 'Update TASK Action Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_TASK_ACTION.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}
export const updateSubTaskAction = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_SUB_TASK_ACTION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/sub-task-actions`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_SUB_TASK_ACTION.SUCCESS,
      showAlert: true,
      alertMessage: 'Update SUB TASK Action Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_SUB_TASK_ACTION.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getEmployee = (filters, pagination) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_EMPLOYEE_TASK.REQUEST })
    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    // const params = { ...pagination }
    let filterPath = '&status=active';
    if (filters?.searchText != '' && filters?.searchText != undefined) {
      let path = `&other_search=${filters?.searchText}` // filters
      filterPath = filterPath + path
    }
    if (filters?.departmentId != null) {
      let path = `&filters[department][id]=${filters?.departmentId}`
      filterPath = filterPath + path
    }
    if(company_info?.id) {
      let path = `&company_id=${company_info?.id}`
      filterPath = filterPath + path
    }
    const { data, meta } = await getAPI({
      url: `/api/employees?pagination[page]=${pagination.page}&pagination[pageSize]=${pagination.pageSize}`+filterPath,
      headers: headers,
      // params,
    })

    return dispatch({
      type: GET_EMPLOYEE_TASK.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_EMPLOYEE_TASK.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Employee Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getEmployeeTable = (filters, pagination) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_EMPLOYEE_TABLE_TASK.REQUEST })
    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    // const params = { ...pagination }
    let filterPath = '&status=active';
    if (filters?.searchText != '' && filters?.searchText != undefined) {
      let path = `&other_search=${filters?.searchText}` // filters
      filterPath = filterPath + path
    }
    if (filters?.departmentId != null) {
      let path = `&filters[department][id]=${filters?.departmentId}`
      filterPath = filterPath + path
    }
    if(company_info?.id) {
      let path = `&company_id=${company_info?.id}`
      filterPath = filterPath + path
    }
    const { data, meta } = await getAPI({
      url: `/api/employees?pagination[page]=${pagination.page}&pagination[pageSize]=${pagination.pageSize}`+filterPath,
      headers: headers,
      // params,
    })

    return dispatch({
      type: GET_EMPLOYEE_TABLE_TASK.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_EMPLOYEE_TABLE_TASK.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Employee Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateNoteAction = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_NOTE_ACTION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/task-notes`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_NOTE_ACTION.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Note Action Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_NOTE_ACTION.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const createDocumentTask = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_DOCUMENT_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/procurement-templates`,
      data,
      headers: headers,
    })
    return dispatch({
      type: CREATE_DOCUMENT_TASK.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Document TASK Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_DOCUMENT_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDocumentTask = (id,isRoutine) => async (dispatch) => {
  // dlog(isRoutine,"isRoutine [getDocumentTask]")
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DOCUMENT_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/`+(isRoutine?`procurement-templates`:`project-task-docs`)+`/${id}?populate=*`,
      headers: headers,
    })
    return dispatch({
      type: GET_DOCUMENT_TASK.SUCCESS,
      data,
    })
  } catch (err) {
    return dispatch({ type: GET_DOCUMENT_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateDocumentTask = (id, data,isRoutine) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_DOCUMENT_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/`+(isRoutine?`procurement-templates`:`project-task-docs`)+`/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_DOCUMENT_TASK.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Document Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_DOCUMENT_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteDocument = (id,isRoutine) => async (dispatch) => {
  // dlog(isRoutine,"isRoutine [deleteDocument]")
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_DOCUMENT_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/`+(isRoutine?`procurement-templates`:`project-task-docs`)+`/${id}`,
      data: { data: { status: 'delete' } },
      headers: headers,
    })

    return dispatch({
      type: DELETE_DOCUMENT_TASK.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Document Successful',
    })
  } catch (err) {
    return dispatch({ type: DELETE_DOCUMENT_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const addDocumentItem = (dataObj,isRoutine) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: ADD_DOCUMENT_ITEM.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } =await postAPI({
      url: `/api/`+(isRoutine?`procurement-items`:`project-task-doc-items`),
      data:dataObj,
      headers: headers,
    })

    return dispatch({
      type: ADD_DOCUMENT_ITEM.SUCCESS,
      data,
      showAlert: true,
      alertMessage: 'Add Document Item Successful',
    })
  } catch (err) {
    return dispatch({ type: ADD_DOCUMENT_ITEM.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteDocumentItem = (id,isRoutine) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_DOCUMENT_ITEM.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/`+(isRoutine?`procurement-items`:`project-task-doc-items`)+`/${id}`,
      data: { data: { status: 'delete' } },
      headers: headers,
    })

    return dispatch({
      type: DELETE_DOCUMENT_ITEM.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Document Item Successful',
    })
  } catch (err) {
    return dispatch({ type: DELETE_DOCUMENT_ITEM.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const createDocRequestApprove = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_DOC_REQUEST_APPROVE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/request-approves`,
      data,
      headers: headers,
    })
    return dispatch({
      type: CREATE_DOC_REQUEST_APPROVE.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Document Request Approve Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_DOC_REQUEST_APPROVE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getRequestApprove = (filters, pagination) => async (dispatch) => {

  try {
    dispatch(showLoading())
    dispatch({ type: GET_REQUEST_APPROVES.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    let filterPath = ''
    if (filters?.name != null) {
      let path = `&filters[name]=${filters?.name}`
      filterPath = filterPath + path
    }
    if (filters?.owner != null) {
      let path = `&filters[owner]=${filters?.owner}`
      filterPath = filterPath + path
    }
    if (filters?.department != null) {
      let path = `&filters[department]=${filters?.department}`
      filterPath = filterPath + path
    }
    if (filters?.type != null) {
      let path = `&filters[type]=${filters?.type}`
      filterPath = filterPath + path
    }
    if (filters?.request_action != null) {
      let path = `&filters[request_action]=${filters?.request_action}`
      filterPath = filterPath + path
    }
    const { data, meta } = await getAPI({
      url:
        `/api/request-approves?pagination[page]=${pagination.page}&pagination[pageSize]=${pagination.pageSize}` +
        filterPath,
      headers: headers,
    })

    return dispatch({
      type: GET_REQUEST_APPROVES.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_REQUEST_APPROVES.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Request Approves Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getRequestApproveItem  = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_REQUEST_APPROVE_ITEM.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/request-approves/${id}?populate=*`,
      headers: headers,
    })
    return dispatch({
      type: GET_REQUEST_APPROVE_ITEM.SUCCESS,
      approveData :data,
    })
  } catch (err) {
    return dispatch({ type: GET_REQUEST_APPROVE_ITEM.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateRequestApproveItem = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_REQUEST_APPROVE_ITEM.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/request-approve-actions`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_REQUEST_APPROVE_ITEM.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Request Approve Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_REQUEST_APPROVE_ITEM.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProvinceList = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PROVINCE_LIST.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/thai-provinces?pagination[page]=1&pagination[pageSize]=100`,
      headers: headers,
    })

    return dispatch({
      type: GET_PROVINCE_LIST.SUCCESS,
      dataProvince: data,
    })
  } catch (err) {
    return dispatch({ type: GET_PROVINCE_LIST.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getAmphureList = (provinceId) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_AMPHURE_LIST.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/thai-amphure?pagination[page]=1&pagination[pageSize]=1000&filters[thai_province]=${provinceId}`,
      headers: headers,
    })

    return dispatch({
      type: GET_AMPHURE_LIST.SUCCESS,
      dataAmphure: data,
    })
  } catch (err) {
    return dispatch({ type: GET_AMPHURE_LIST.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getTambonList = (amphureId) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TAMBON_LIST.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/thai-tambon?pagination[page]=1&pagination[pageSize]=1000&filters[thai_amphure]=${amphureId}`,
      headers: headers,
    })

    return dispatch({
      type: GET_TAMBON_LIST.SUCCESS,
      dataTambon: data,
    })
  } catch (err) {
    return dispatch({ type: GET_TAMBON_LIST.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const createSubTask = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_SUB_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/sub-tasks`,
      data,
      headers: headers,
    })
    return dispatch({
      type: CREATE_SUB_TASK.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Sub Task Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_SUB_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}
export const editSubTask = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_SUB_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/sub-tasks/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: EDIT_SUB_TASK.SUCCESS,
      showAlert: true,
      alertMessage: 'Edit sub task Successful',
    })
  } catch (err) {
    return dispatch({ type: EDIT_SUB_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}
export const getSubTaskById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SUB_TASK_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/sub-tasks/${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_SUB_TASK_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_SUB_TASK_BY_ID.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteTaskRoutine = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_TASK_ROUTINE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/tasks/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_TASK_ROUTINE.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Task Routine Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_TASK_ROUTINE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}