import 'quill/dist/quill.snow.css'
import { useEffect, useRef, useState } from 'react'
import { useQuill } from 'react-quilljs'
import styled from 'styled-components'

export default function TextEditor({ onChange, placeHolder, initValue, className }) {
  // quill config
  const theme = 'snow'
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      ['link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: 2 }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['clean'],
    ],
  }
  const placeholder = placeHolder || 'Type here...'
  const formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'underline',
    'blockquote',
    'header',
    'list',
    'align',
    'direction',
    'code-block',
    'image',
    'video',
    'svg',
    'mathjax',
    'script',
  ]
  const { quill, quillRef } = useQuill({
    theme,
    formats,
    modules,
    placeholder,
  })

  useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(initValue)

      quill.on('text-change', (delta, oldDelta, source) => {
        // console.log('Text change!')
        // console.log(quill.getText())
        // console.log(quill.getContents())
        // console.log(quillRef.current.firstChild.innerHTML)
        // console.log(quill.root.innerHTML)
        onChange(quill.root.innerHTML)
      })
    }
  }, [quill])

  return (
    <Styled className={className}>
      <div className={'main_container'}>
        <div ref={quillRef} />
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  min-height: 140px;
  .main_container {
    color: #fff;
    width: 100%;
    padding-bottom: 20px;
    max-width: 960px;
    margin: 0 auto;
    font-family: 'Inter', 'Noto Sans Thai';

    small {
      display: block;
      /*font-size: 18px;*/
      padding-top: 20px;
      padding-bottom: 10px;
      i {
        /*font-size: 12px;*/
        font-style: normal;
      }
    }

    .ql-toolbar.ql-snow {
      background-color: #fff;
    }

    .ql-editor {
      background-color: #fff;
      color: #444;
    }
    .ql-container {
      /*font-size: 16px;*/
    }
  }
`
