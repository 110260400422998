import React, { useCallback, useEffect, useState } from 'react'
import { formatNumber } from 'utils/common'

import { useSelector, useDispatch } from 'react-redux'
import { selectDynamicForm } from 'redux/selectors'
import { deleteDynamicForm, getViewSchema } from 'redux/actions/dynamicForm'

import styled from 'styled-components'
import { Box, Grid, Stack } from '@mui/material'
import ButtonOutline from 'components/form/button/ButtonOutline'
import IconButton from 'components/common/IconButton'
import Divider from 'components/common/Divider'
import SidePanel from 'components/common/SidePanel'
import EditDynamicContent from './EditDynamicContent'
import DialogConfirmDelete from 'components/dialog/DialogConfirmDelete'

import { LiaTimesSolid } from 'react-icons/lia'
import { GrView } from 'react-icons/gr'
import failIcon from 'assets/images/fail-icon.png'

const Div = styled.div`
  position: relative;
  padding: 24px 24px 0;

  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  .side-panel-heading {
    color: var(--Gray-900);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
  }
  .side-panel-sub-heading {
    color: var(--Gray-600);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }

  .icon {
    cursor: pointer;
  }

  .heading-info {
    color: var(--Gray-500);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
  }
  .info {
    color: var(--Gray-500);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;

    white-space: normal;
    word-wrap: break-word;
  }

  .btn-outline-style {
    border-color: var(--Primary-300);
    color: var(--Primary-700);
    background-color: var(--Base-White);
  }

  .icon-button-style {
    display: inline-block;
    padding: 4px;
    border: 1px solid var(--Gray-300);
    border-radius: 4px;
    color: var(--Gray-600);
  }
  .file-name {
    font-weight: 600;
    color: var(--Primary-700);
  }
`

const gridLayoutHeading = {
  xs: 4, // < 600px
  sm: 4, //  600px - 900px
  md: 4, // 900px - 1200px
  lg: 4, // 1200px - 1536px
  xl: 4, // > 1536pxƒ
}
const gridLayoutValue = {
  xs: 8,
  sm: 8,
  md: 8,
  lg: 8,
  xl: 8,
}

function ViewDynamicContent({ id, name, onClose }) {
  // external hook
  const dispatch = useDispatch()

  // schema view page
  const { viewSchema } = useSelector(selectDynamicForm)

  // initiate data
  const fetchViewSchema = useCallback(
    (id, name) => {
      dispatch(getViewSchema(id, name))
    },
    [dispatch]
  )

  useEffect(() => {
    fetchViewSchema(id, name)
  }, [id, name])

  // title,description
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    if (Object.keys(viewSchema).length > 0) {
      const keyTitle = viewSchema?.title.replace(/[{}]/g, '')
      const keyDescription = viewSchema?.description.replace(/[{}]/g, '')

      const titleValue = viewSchema?.data_value[keyTitle]
      const descriptionValue = viewSchema?.data_value[keyDescription]

      setTitle(viewSchema?.title.replace(`{${keyTitle}}`, titleValue))
      setDescription(viewSchema?.description.replace(`{${keyDescription}}`, descriptionValue))
    }
  }, [viewSchema])

  // width window
  const [widthWindow, setWidthWindow] = useState(window.innerWidth)
  const [widthModal, setWidthModal] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidthWindow(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    const newWidthSidePanel =
      widthWindow < 769
        ? widthWindow
        : widthWindow > 769 && widthWindow < 1024
        ? (widthWindow * 80) / 100
        : (widthWindow * 55) / 100
    setWidthModal(newWidthSidePanel)
  }, [widthWindow])

  // view side panel
  const [isOpenEditSidePanel, setIsOpenEditSidePanel] = useState(false)

  const openEditSidePanel = () => {
    setIsOpenEditSidePanel(true)
  }
  const closeEditSidePanel = () => {
    setIsOpenEditSidePanel(false)
  }

  // confirm delete
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false)

  const openConfirmDelete = () => {
    setIsOpenConfirmDelete(true)
  }
  const closeConfirmDelete = () => {
    setIsOpenConfirmDelete(false)
  }
  const onSubmitDelete = () => {
    const { endpoint } = viewSchema?.delete_button
    const request = {
      data: {
        status: 'delete',
      },
    }

    dispatch(deleteDynamicForm(id, request, endpoint))
    closeConfirmDelete()
    onClose()
  }

  // state, isLoading
  const { state, isLoading } = useSelector(selectDynamicForm)

  useEffect(() => {
    if (state === 'UPDATE_DYNAMIC_FORM.SUCCESS' && isLoading === false) {
      fetchViewSchema(id, name)
    }
  }, [state, isLoading, name])

  // other function
  const viewFile = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <Div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <div className="side-panel-heading">{title}</div>
          <div className="side-panel-sub-heading">{description}</div>
        </Box>

        <LiaTimesSolid className="icon" size={18} onClick={onClose} />
      </Stack>

      <Box sx={{ my: 2 }}>
        <Divider />
      </Box>
      <Stack direction="row" justifyContent="flex-end">
        <Stack direction="row" spacing={1}>
          <ButtonOutline className="btn-outline-style" onClick={openConfirmDelete}>
            {viewSchema?.delete_button?.text}
          </ButtonOutline>
          <ButtonOutline className="btn-outline-style" onClick={openEditSidePanel}>
            {viewSchema?.edit_button?.text}
          </ButtonOutline>
        </Stack>
      </Stack>
      <Grid container spacing={1}>
        {Object.keys(viewSchema).length > 0 &&
          Object.entries(viewSchema?.properties).map(([key, value]) => (
            <>
              <Grid className="heading-info" item {...gridLayoutHeading}>
                {value?.title}
              </Grid>
              <Grid className="info" item {...gridLayoutValue}>
                {viewSchema?.properties[key]?.type === 'text-field-integer' ||
                viewSchema?.properties[key]?.type === 'text-field-decimal' ? (
                  formatNumber(viewSchema?.data_value[key])
                ) : viewSchema?.properties[key]?.type === 'upload-single' ? (
                  <Stack direction="row" spacing={1} alignItems="center">
                    {typeof viewSchema?.data_value[key] === 'object' && (
                      <>
                        <Box className="file-name">
                          {viewSchema?.data_value[key]?.name}
                          {viewSchema?.data_value[key]?.ext}
                        </Box>
                        <IconButton
                          type="button"
                          className="icon-button-style"
                          icon={<GrView size={14} onClick={() => viewFile(viewSchema?.data_value[key]?.url)} />}
                        />
                      </>
                    )}
                  </Stack>
                ) : viewSchema?.data_value[key] ? (
                  viewSchema?.data_value[key]
                ) : (
                  '-'
                )}
              </Grid>
            </>
          ))}
      </Grid>

      {/* side panel */}
      <SidePanel isOpen={isOpenEditSidePanel} setIsOpen={setIsOpenEditSidePanel} width={widthModal}>
        <EditDynamicContent id={id} name={name} onClose={closeEditSidePanel} />
      </SidePanel>

      {/* dialog */}
      <DialogConfirmDelete
        open={isOpenConfirmDelete}
        onClose={closeConfirmDelete}
        onSubmit={onSubmitDelete}
        icon={failIcon}
        title={`คุณแน่ใจหรือไม่ว่าต้องการลบ ${viewSchema?.data_value?.name}`}
        textYes={'ยืนยัน'}
        textNo={'ยกเลิก'}
      />
    </Div>
  )
}

export default ViewDynamicContent
