import { Menu, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import iconClose from '../../assets/images/icon-close.svg'
import TaskContentDocument from '../feature/taskPreviewer/document/TaskContentDocument'
import TaskDocumentCreationView from '../feature/taskPreviewer/document/TaskDocumentCreationView'
import TaskRequestApprove from '../feature/taskPreviewer/document/TaskRequestApprove'
import TaskContentNote from '../feature/taskPreviewer/note/TaskContentNote'
import TaskContentOverview from '../feature/taskPreviewer/overview/TaskContentOverview'
import TaskContentSubTask from '../feature/taskPreviewer/subtask/TaskContentSubTask'

import { useDispatch, useSelector } from 'react-redux'
import iconDotsHorizontal from '../../../src/assets/images/icon-dots-horizontal.svg'

import { useTranslation } from 'react-i18next'
import failIcon from '../../assets/images/fail-icon.png'
import successIcon from '../../assets/images/success-icon-2.png'
import { deleteTaskInProjectTask, getMileStoneTaskTypeInfoByTaskId } from '../../redux/actions/projectTask'
import { deleteDocument, deleteTaskRoutine, getDocumentTask, getTaskById } from '../../redux/actions/summaryTask'
import { selectProjectTask, selectSummaryTask } from '../../redux/selectors'
import SidePanel from '../common/SidePanel'
import TaskDocumentView from '../feature/taskPreviewer/document/TaskDocumentView'
import CapsuleStatus from '../widget/element/CapsuleStatus'
import ImageButton from '../widget/element/ImageButton'
import IconEditing from '../widget/icon/IconEditing'
import DialogConfirm from './DialogConfirm'
import DialogConfirmDelete from './DialogConfirmDelete'
import DialogFail from './DialogFail'
import DialogSuccess from './DialogSuccess'

export default function DialogTaskPreviewer({
  requestId,
  taskId,
  open,
  onClose,
  onEditTask,
  menuActive,
  docId,
  onDataChange,
  previewType = 'task_routine',
  onUpdate,
}) {
  const [isSelectMenuEdit, setIsSelectMenuEdit] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { taskInfo, documentInfo } = useSelector(selectSummaryTask)
  const { milestoneTaskTypeInfo } = useSelector(selectProjectTask)

  const [taskInfoState, setTaskInfoState] = useState()
  const [menuList, setMenuList] = useState([
    {
      name: 'Overview',
      value: 'overview',
      active_status: true,
    },
    {
      name: 'Document',
      value: 'document',
      active_status: false,
    },
    {
      name: 'Sub Task',
      value: 'sub_task',
      active_status: false,
    },
    {
      name: 'Note',
      value: 'note',
      active_status: false,
    },
    // {
    //   name: 'Attached File',
    //   value: 'attached_file',
    //   active_status: false,
    // },
  ])
  const [isDataUpdated, setIsDataUpdated] = useState(false)
  const [currentMenuSelected, setCurrentMenuSelected] = useState('overview')
  const [isShowPopupNewDocument, setIsShowPopupNewDocument] = useState(false)
  const [isShowPopupDocumentPreview, setIsShowPopupDocumentPreview] = useState(false)
  const [isShowPopupEdit, setIsShowPopupEdit] = useState(false)
  const [isShowPopupRequestApprove, setIsShowPopupRequestApprove] = useState(false)
  const [isShowModal, setIsShowModal] = useState(false)
  const [messageModal, setMessageModal] = useState('')
  const [actionEl, setActionEl] = useState(null)
  const openMenu = Boolean(actionEl)
  const [isShowMenuTab, setIsShowMenuTab] = useState(true)
  const [docIden, setDocIden] = useState(docId)
  const [isDownload, setIsDownload] = useState(false)
  const [searchFilter, setSearchFilter] = useState()
  const [isShowModalDelete, setIsShowModalDelete] = useState(false)
  const [isShowModalSuccessDelDoc, setIsShowModalSuccessDelDoc] = useState(false)

  const [isShowDeleteConfirm, setIsShowDeleteConfirm] = useState(false)
  const [isShowFailedModal, setIsShowFailedModal] = useState(false)

  useEffect(() => {
    if (menuActive) {
      handleClickTabMenu({ value: menuActive })
    }
  }, [menuActive])
  useEffect(() => {
    ;(async () => {
      if (taskId) {
        let tmpData
        if (previewType == 'task_routine') {
          if (searchFilter && Object.keys(searchFilter)?.length > 0) {
            tmpData = await dispatch(getTaskById(taskId, searchFilter))
          } else {
            tmpData = await dispatch(getTaskById(taskId))
          }
        } else {
          if (searchFilter && Object.keys(searchFilter)?.length > 0) {
            tmpData = await dispatch(getMileStoneTaskTypeInfoByTaskId(taskId, searchFilter))
          } else {
            tmpData = await dispatch(getMileStoneTaskTypeInfoByTaskId(taskId))
          }
        }

        if (tmpData?.data) {
          // console.log('tmpData?.data: ' + JSON.stringify(tmpData?.data, null, 2))
          setTaskInfoState(tmpData.data)
        }
      }
    })()
  }, [taskId, isDataUpdated, searchFilter])

  // useEffect(() => {
  //   if (taskId) {
  //     dlog("HERE_1")
  //     if (previewType == 'task_routine') {
  //       dispatch(getTaskById(taskId))
  //     } else {
  //       dispatch(getMileStoneTaskTypeInfoByTaskId(taskId))
  //     }
  //   }
  // }, [taskId, isDataUpdated])

  // useEffect(() => {
  //   if (taskId) {
  //     dlog("HERE_2")
  //     if (previewType == 'task_routine') {
  //       dispatch(getTaskById(taskId, searchFilter))
  //     } else {
  //       dispatch(getMileStoneTaskTypeInfoByTaskId(taskId, searchFilter))
  //     }
  //   }
  // }, [searchFilter])

  // useEffect(() => {
  //   dlog("HERE_3")
  //   if (previewType == 'task_routine' && Object.keys(taskInfo).length > 0) {
  //     setTaskInfoState(taskInfo)
  //   }
  // }, [taskInfo, isDataUpdated])

  // useEffect(() => {
  //   if (previewType == 'task_milestone') {
  //     if (Object.keys(milestoneTaskTypeInfo).length > 0) {
  //       setTaskInfoState(milestoneTaskTypeInfo)
  //     }
  //   }
  // }, [milestoneTaskTypeInfo, isDataUpdated])

  const handleUpdateSubTask = async () => {
    if (taskId) {
      getData(taskId)
    }
    if (onUpdate) {
      onUpdate()
    }
  }

  const handleActonClick = (event) => {
    setActionEl(event.currentTarget)
  }
  const handleActionMenu = (type) => {
    setActionEl(null)
    if (type == 'report') {
      // dlog('Download REPORT')
    } else if (type == 'pdf') {
      // dlog('Download PDF')
    } else if (type == 'delete') {
      // dlog('delete')
      setIsShowModalDelete(true)
    }
  }

  const handleClickTabMenu = (data) => {
    let tmpList = [...menuList]
    for (let i = 0; i < tmpList?.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setCurrentMenuSelected(data.value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuList([...tmpList])
  }
  const handleNewDocumentClicked = () => {
    setIsShowPopupNewDocument(true)
  }

  const handleViewClicked = (id) => {
    setDocIden(id)
    setIsShowPopupDocumentPreview(true)
  }
  const handleDownloadClicked = (id) => {
    setDocIden(id)
    setIsShowPopupDocumentPreview(true)
    setIsDownload(true)
  }
  const handleDownloaded = () => {
    setIsShowPopupDocumentPreview(false)
    setIsDownload(false)
  }
  const handleEditClicked = (id) => {
    dispatch(getDocumentTask(id, true))
    setIsShowPopupEdit(true)
    setIsSelectMenuEdit(true)
  }

  const handleRequestApproveClicked = (id) => {
    dispatch(getDocumentTask(id, true))
    setIsShowPopupRequestApprove(true)
  }

  const handleNavigationBackClicked = () => {
    setIsShowPopupNewDocument(false)
    setIsShowPopupDocumentPreview(false)
  }
  const handleNavigationBackClickedFromEdit = () => {
    setIsDataUpdated(!isDataUpdated)
    setIsShowPopupEdit(false)
    setIsSelectMenuEdit(false)
  }
  const handleRequestApproveCloseClicked = () => {
    setIsDataUpdated(!isDataUpdated)
    setIsShowPopupRequestApprove(false)
  }
  const handleOnDataChange = (data) => {
    setIsDataUpdated(!isDataUpdated)
  }
  const handleOnUploaded = () => {
    setIsDataUpdated(!isDataUpdated)
  }
  const handleDeletedClicked = (docIden) => {
    setDocIden(docIden)
    setIsShowDeleteConfirm(true)
  }

  const handleSubmitDeleteDoc = () => {
    dispatch(deleteDocument(docIden, true)).then(() => {
      setIsDataUpdated(!isDataUpdated)
      setIsShowDeleteConfirm(false)
      setIsShowModalSuccessDelDoc(true)
    })
  }
  const handleClickTools = (type) => {
    if (type == 'editing') {
      if (onEditTask) {
        onEditTask()
      }
    }
    // else if (type == 'link') {
    //   dlog('link')
    // } else if (type == 'menu') {
    //   dlog('menu')
    // }
  }
  const handleSubmitNote = async () => {
    getData(taskId)
  }

  const getData = async (taskId) => {
    let tmpData
    if (previewType == 'task_routine') {
      tmpData = await dispatch(getTaskById(taskId))
    } else {
      tmpData = await dispatch(getMileStoneTaskTypeInfoByTaskId(taskId))
    }
    if (tmpData?.data) {
      setTaskInfoState(tmpData.data)
    }
    // setIsDataUpdated(!isDataUpdated)
  }

  const handleClickMarkComplete = () => {
    setMessageModal('Would you like to mark complete this task ?')
    setIsShowModal(true)
  }

  const handleConfirmModal = () => {
    // TODO: Update to server
    setIsShowModal(false)
  }

  const handleClickClose = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleSubmitRequestApprove = () => {}

  const handleSubTaskViewChange = (isShowNewSubtask) => {
    setIsShowMenuTab(!isShowNewSubtask)
  }
  const handleOnSuccess = () => {
    setIsDataUpdated(!isDataUpdated)
    setIsShowMenuTab(true)
  }
  const handleTaskAction = () => {
    if (onDataChange) {
      onDataChange()
    }
  }
  const handleSearchFilterChange = (searchFilterObj) => {
    if (searchFilterObj) {
      setSearchFilter(searchFilterObj)
    }
  }
  const handleClickBtnConfirmDelete = () => {
    //Delete task with API
    if (Object.keys(employee_info)?.length > 0) {
      let tmpObj = {
        status: 'Delete',
        created_by: employee_info?.id,
      }

      const data = { ...tmpObj }
      const request = { data }

      if (previewType == 'task_milestone') {
        dispatch(deleteTaskInProjectTask(taskId, request)).then(() => {
          setIsShowModalDelete(false)
          if (onDataChange) {
            onDataChange()
          }
        })
      } else {
        dispatch(deleteTaskRoutine(taskId, request)).then(() => {
          setIsShowModalDelete(false)
          if (onDataChange) {
            onDataChange()
          }
        })
      }
    } else {
      setIsShowFailedModal(true)
    }
  }

  return (
    <SidePanel isOpen={open} onClose={onClose} width={900}>
      <Styled>
        <div className="header">
          <div className="left">
            <div className="wrp-btn-back">
              {/* <div className="btn-back" onClick={handleClickClose}>
                <img src={iconBack} />
              </div> */}
            </div>
            <div className="txt-title">
              {previewType == 'task_routine' ? taskInfo?.task_name : taskInfoState?.task_type?.name}
            </div>
          </div>
          <div className="right">
            {previewType == 'task_routine' && (
              <ImageButton
                className={'wrp-icon-editing'}
                icon={<IconEditing />}
                onClick={() => handleClickTools('editing')}
              />
            )}

            {/* <ImageButton src={iconLink} onClick={() => handleClickTools('link')} /> */}

            <ImageButton src={iconDotsHorizontal} onClick={handleActonClick} />

            <Menu anchorEl={actionEl} open={openMenu} onClose={handleActionMenu}>
              {/* <MenuItem onClick={() => handleActionMenu('report')}>Download Summary Report</MenuItem>
              <MenuItem onClick={() => handleActionMenu('pdf')}>Download PDF</MenuItem> */}
              <MenuItem onClick={() => handleActionMenu('delete')}>{t('delete')}</MenuItem>
            </Menu>

            <ImageButton src={iconClose} onClick={handleClickClose} />
          </div>
        </div>

        <div className="body">
          {isShowMenuTab && (
            <div className="wrp-txt-title">
              <div className="wrp-tab-menu">
                {menuList?.map((item, index) => (
                  <button
                    onClick={() => handleClickTabMenu(item)}
                    key={'btn-' + index}
                    className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                  >
                    {item.name}
                  </button>
                ))}
              </div>

              <CapsuleStatus status={taskInfoState?.status} />
              {/* <div className="status-project">{taskInfoState?.status}</div> */}
            </div>
          )}

          <div className="wrp-content-container">
            {currentMenuSelected == 'overview' && (
              <TaskContentOverview
                data={taskInfoState}
                previewType={previewType}
                onTaskAction={() => handleTaskAction()}
                onCancel={() => {
                  handleClickClose()
                }}
              />
            )}
            {currentMenuSelected == 'document' && (
              <TaskContentDocument
                data={taskInfoState}
                onNewDocumentClicked={handleNewDocumentClicked}
                onViewClicked={handleViewClicked}
                onEditClicked={handleEditClicked}
                onDeleteClicked={handleDeletedClicked}
                onDownloadClicked={handleDownloadClicked}
                onRequestApproveClicked={handleRequestApproveClicked}
                onSearchFilterChange={handleSearchFilterChange}
                isRoutine={previewType == 'task_routine'}
                onUploaded={handleOnUploaded}
              />
            )}
            {currentMenuSelected == 'sub_task' && (
              <TaskContentSubTask
                taskId={taskId}
                requestId={requestId}
                docId={docIden}
                data={taskInfoState}
                onUpdate={handleUpdateSubTask}
                onViewChange={(e) => handleSubTaskViewChange(e)}
                onSuccess={() => handleOnSuccess()}
                onSearchFilterChange={handleSearchFilterChange}
                isRoutine={previewType == 'task_routine'}
              />
            )}
            {/* {currentMenuSelected == 'attached_file' && <TaskContentAttachedFile />} */}
            {currentMenuSelected == 'note' && (
              <TaskContentNote
                data={taskInfoState}
                onSubmitNote={() => handleSubmitNote()}
                isRoutine={previewType == 'task_routine'}
              />
            )}
          </div>

          {isShowPopupNewDocument && (
            <TaskDocumentCreationView
              data={taskInfoState}
              onClickNavigationBack={handleNavigationBackClicked}
              onDataChange={handleOnDataChange}
              isRoutine={previewType == 'task_routine'}
            />
          )}
          {isShowPopupDocumentPreview && (
            <TaskDocumentView
              onClose={handleNavigationBackClicked}
              docId={docIden}
              isDownload={isDownload}
              isDownloaded={handleDownloaded}
              isRoutine={previewType == 'task_routine'}
            />
          )}

          {isShowPopupEdit && (
            <TaskDocumentCreationView
              data={documentInfo || ''}
              onClickNavigationBack={handleNavigationBackClickedFromEdit}
              isEdit={isSelectMenuEdit}
              onDataChange={handleOnDataChange}
            />
          )}
          {isShowPopupRequestApprove && (
            <TaskRequestApprove
              onClickNavigationBack={handleRequestApproveCloseClicked}
              onClickBtnSubmit={handleSubmitRequestApprove}
              isRoutine={previewType == 'task_routine'}
            />
          )}
          {/* <TemporaryDrawer /> */}

          <DialogConfirm
            open={isShowModal}
            onClose={() => setIsShowModal(false)}
            onSubmit={handleConfirmModal}
            description={messageModal}
            textYes={t('confirm')}
            textNo={t('cancel')}
          />

          <DialogConfirm
            open={isShowModalDelete}
            onClose={() => setIsShowModalDelete(false)}
            onSubmit={() => handleClickBtnConfirmDelete()}
            title={t('do_you_want_to_confirm_the_deletion_of_this_task')}
            textNo={t('cancel')}
            textYes={t('confirm')}
          />

          {isShowDeleteConfirm && (
            <DialogConfirmDelete
              open={isShowDeleteConfirm}
              title={t('delete_access_role')}
              description={
                <div className="text-desc-modal-delete">
                  {t('junior_developer')}
                  <br></br>
                  <br></br>
                  {t('delete_access_role_desc')}
                </div>
              }
              onSubmit={() => handleSubmitDeleteDoc()}
              onClose={() => setIsShowDeleteConfirm(true)}
            />
          )}

          {isShowModalSuccessDelDoc && (
            <DialogSuccess
              open={isShowModalSuccessDelDoc}
              onClose={() => setIsShowModalSuccessDelDoc(false)}
              onSubmit={() => setIsShowModalSuccessDelDoc(false)}
              icon={successIcon}
              title={t('access_role_delete_success')}
              textYes={t('done')}
            />
          )}

          {isShowFailedModal && (
            <DialogFail
              open={Boolean(isShowFailedModal)}
              onClose={() => setIsShowFailedModal(false)}
              onSubmit={() => setIsShowFailedModal(false)}
              icon={failIcon}
              title={'You may not fully use this feature, since you are not employee.'}
              nameItem={''}
              description={''}
              textYes="Ok"
            />
          )}
        </div>
      </Styled>
    </SidePanel>
  )
}

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  overflow: hidden;

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem 0.5rem;
  }

  .done {
    justify-content: flex-end !important;
  }

  .header {
    /* background-color: red; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 24px 0;
  }
  .wrp-tools {
    display: flex;
    align-items: center;
  }
  .wrp-btn-back {
    display: flex;
    padding-left: 30px;
  }
  .btn-back {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }
  .txt-title {
    margin-left: 10px;
  }

  .wrp-txt-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 24px 29px;
  }

  .txt-title {
    font-size: 24px;
    font-weight: 600;
  }

  .wrp-tab-menu {
    width: fit-content;
    display: flex;
    border-bottom: 1px solid var(--Gray-200);

    .btn-menu-style {
      border: none;
      border-bottom: 2px solid transparent;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      color: var(--Gray-500);
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: var(--Primary-50);
      border-bottom: 2px solid var(--Primary-700) !important;
      color: var(--Primary-700);
    }
  }

  .wrp-task-tools-container {
    display: flex;
    align-items: center;

    img {
      padding: 0.5rem;
      cursor: pointer;
      margin-left: 0.5rem;
      width: 35px;
      height: 35px;
    }

    .icon {
      width: 22px;
      height: 22px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .status-project {
    font-weight: bold;
    text-transform: capitalize;
  }
  .wrp-content-container {
    height: 100%;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .right {
    display: flex;
    align-items: center;
  }
  .right .wrp-icon-editing {
    padding-top: 8px;
  }
`
