import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import Button from '../../components/form/button/Button'
import TextField from '../../components/form/TextField'
import AutoComplete from '../../components/form/AutoComplete'
import AutoCompleteCustomText from '../../components/form/AutoCompleteCustomText'
import Table from '../../components/common/Table'
import OutlineCheckbox from '../../components/form/OutlineCheckbox'
import Dropdown from '../../components/form/Dropdown'
import Modal from '../../components/common/Modal'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import PublishConfirmModal from '../../components/feature/acknowledgement-system/PublishConfirmModal'
import PublishShowAllModal from '../../components/feature/acknowledgement-system/PublishShowAllModal'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import ModalPdpaPreview from '../../components/pdpa/ModalPdpaPreview'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { FiTrash2, FiArrowLeft, FiArrowUpRight } from 'react-icons/fi'
import { LiaTimesSolid } from 'react-icons/lia'
import { TbPointFilled } from 'react-icons/tb'
import { BsCheckLg } from 'react-icons/bs'
import previewIcon from '../../assets/images/preview-icon.png'
import successIcon from '../../assets/images/success-icon.png'

import { getEmployeeDropdrow, getEmployee2, getDepartmentsAndPositionsUseAuthen } from '../../redux/actions/master'
import {
  createDocumentAcknowledgeWithDraft,
  setSharedDocumentForm,
  setFlagDraft,
  setSharedServiceTemplateInfo,
  setDocumentAcknowledgementInfo,
} from '../../redux/actions/sharedService'
import { selectMaster, selectSharedService } from '../../redux/selectors'

const Div = styled.div`
  /* shared-layout */
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .mb-10-rem {
    margin-bottom: 10rem;
  }
  .mb-20-px {
    margin-bottom: 1.25rem;
  }
  .mb-24-px {
    margin-bottom: 1.5rem;
  }
  .mb-32-px {
    margin-bottom: 2rem;
  }
  .mb-64-px {
    margin-bottom: 4rem;
  }
  .mr-12-px {
    margin-right: 0.75rem;
  }
  .mr-20-px {
    margin-right: 1.25rem;
  }
  .d-flex {
    display: flex;
  }
  .jt-end {
    justify-content: flex-end;
  }

  /* shared-typography */
  .header {
    display: flex;
    align-items: center;

    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    .highlight {
      color: var(--Primary-700);
      font-weight: 600;
    }
  }
  .headline {
    color: var(--Gray-900, #101828);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
  }
  .text-center {
    text-align: center;
  }
  .header-step {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-weight: 600;

    &.active {
      color: var(--Primary-700);
    }
  }
  .description-step {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-weight: 400;

    &.active {
      color: var(--Primary-700);
    }
  }

  /* shared component */
  .divider {
    width: 100%;
    height: 1px;
    background: var(--Gray-200);
  }
  .input {
    margin-bottom: 1.5rem;

    .input-header {
      color: var(--Gray-700);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    .input-group {
      display: flex;
      justify-content: space-between;
    }

    .w-input {
      width: 100%;
    }

    .w-input-80 {
      width: 80%;
    }
    .w-input-15 {
      width: 15%;
    }
  }
  .add-image-btn {
    border-radius: 8px;
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }
  .step-item {
    width: 30px;
    height: 30px;

    border-radius: 50%;
    border: 1px solid var(--Stepper-Line);

    display: flex;
    justify-content: center;
    align-items: center;

    .step-icon {
      color: var(--Stepper-Icon);

      &.inactive {
        color: var(--Gray-200);
      }
    }

    &.inactive {
      border: 1px solid var(--Gray-200);
    }
  }
  .line-connect {
    width: 2px;
    /* flex-grow: 1; */
    height: 3rem;
    background-color: var(--Gray-200);
    margin: 0 auto;

    &.active {
      background-color: var(--Stepper-Line);
    }
  }
  .icon-view {
    width: 20px;
    height: auto;

    img {
      max-width: 100%;
    }
  }

  /* general */
  .document-container {
    display: flex;
    justify-content: space-between;

    .step-container {
      width: 30%;

      height: 400px;
      display: flex;

      .step-progress {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-right: 0.75rem;
      }
      .step-progress-2 {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        height: 100%;
      }
    }
    .input-container {
      width: 68%;
    }
  }

  .footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 2rem;
  }

  .checkbox-container {
    display: flex;
    align-items: center;

    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
  }

  .department-selected-container {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 1.5rem;

    .badge-department {
      display: flex;
      align-items: center;

      color: var(--BADGE-1-Font);
      border-radius: 6px;
      border: 1px solid var(--BADGE-1-Border);
      background: var(--BADGE-1-Bg);
      padding: 0.25rem;
      margin-right: 0.25rem;
    }
  }
`

const initNotificationChecked = [
  {
    text: 'Email Notification',
    isChecked: false,
  },
  {
    text: 'In-App Notification',
    isChecked: true,
  },
]

const initPublishToList = [
  {
    text: 'All Employee',
    value: 1,
  },
  {
    text: 'Specific Department',
    value: 2,
  },
  {
    text: 'Specific Employee',
    value: 3,
  },
]

function PublishDocumentForDraft() {
  const { t } = useTranslation()
  const employeeColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('name'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('department'),
      accessor: 'department',
      disableSortBy: false,
    },
    {
      Header: t('position'),
      accessor: 'position',
      disableSortBy: false,
    },
    {
      accessor: 'action',
      disableSortBy: false,
    },
  ]

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { employeeList, employeeList2, departments, page, pageCount, pageSize } = useSelector(selectMaster)
  const { sharedServiceTemplateInfo, sharedDocumentForm, documentAcknowledgementInfo, draftInfo, isLoading, state } =
    useSelector(selectSharedService)
  // data
  const [notificationChecked, setNotificationChecked] = useState(initNotificationChecked)
  const [publishToSelected, setPublishToSelected] = useState(1)
  const [addedEmployees, setAddedEmployees] = useState([])
  const [addedEmployeesDisplay, setAddedEmployeesDisplay] = useState([])
  const [employeeSelected, setEmployeeSelected] = useState(null)
  const [departmentSelected, setDepartmentSelected] = useState('')
  const [departmentSelectedList, setDepartmentSelectedList] = useState([])
  // dropdown list
  const [publishList] = useState(initPublishToList)
  const [employees, setEmployees] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  // modal
  const [publishModal, setPublishModal] = useState(false)
  const [showAllModal, setShowAllModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [previewPdpaModal, setPreviewPdpaModal] = useState(false)

  const deleteAddedEmployee = useCallback(
    (id) => {
      const newAddedEmployees = addedEmployees.filter((v) => v.id !== id)
      setAddedEmployees(newAddedEmployees)
    },
    [addedEmployees]
  )

  const deleteDepartmentSelected = useCallback(
    (id) => {
      const newDepartmentSelectedList = departmentSelectedList.filter((v) => v.value !== id)
      setDepartmentSelectedList(newDepartmentSelectedList)
    },
    [departmentSelectedList]
  )

  useEffect(() => {
    if (isLoading === false && state === 'CREATE_DOCUMENT_ACKNOWLEDGEMENT.SUCCESS') {
      setSuccessModal(true)
      dispatch(setSharedDocumentForm({}))
      dispatch(
        setFlagDraft({
          draftId: '',
          isDraft: false,
        })
      )
      dispatch(setSharedServiceTemplateInfo({}))
      dispatch(setDocumentAcknowledgementInfo({}))
    }
  }, [isLoading, state])

  useEffect(() => {
    setEmployeeSelected(null)
    setDepartmentSelected('')
    setDepartmentSelectedList([])
    setAddedEmployees([])
  }, [publishToSelected])

  useEffect(() => {
    const newDepartmentList = departments.map((v) => ({
      text: v.name,
      value: v.id,
    }))
    setDepartmentList(newDepartmentList)
  }, [departments])

  useEffect(() => {
    const request = {
      limit: 10000,
      skip: 0,
    }
    dispatch(getEmployeeDropdrow(request))
    dispatch(getDepartmentsAndPositionsUseAuthen())
  }, [dispatch])

  useEffect(() => {
    const newEmployeeList = employeeList.map((v) => ({
      value: v.id,
      name: `${v.first_name} ${v.last_name}`,
      department: v.department,
      position: v.position,
    }))
    setEmployees(newEmployeeList)
  }, [employeeList])

  useEffect(() => {
    const newAddedEmployeesDisplay = addedEmployees.map((v, i) => ({
      ...v,
      no: i + 1,
      name: `${v.first_name} ${v.last_name}`,
      action: <FiTrash2 className="icon" size={20} onClick={() => deleteAddedEmployee(v.id)} />,
    }))
    setAddedEmployeesDisplay(newAddedEmployeesDisplay)
  }, [addedEmployees])

  // onChange
  const handleChangeCheckBox = (event, index) => {
    const { checked } = event.target
    const oldNotificationChecked = [...notificationChecked]
    oldNotificationChecked[index].isChecked = checked

    setNotificationChecked(oldNotificationChecked)
  }

  const onChangeDepartmentSelect = (value) => {
    setDepartmentSelected(value)
    const isExisted = departmentSelectedList.find((v) => v.value === value)
    if (isExisted === undefined) {
      const matchDepartment = departmentList.find((v) => v.value === value)

      setDepartmentSelectedList((prev) => [
        ...prev,
        {
          text: matchDepartment.text,
          value: matchDepartment.value,
        },
      ])
    }
    setTimeout(() => {
      setDepartmentSelected('')
    }, 500)
  }

  const onChangePagination = (value) => {
    if (publishToSelected === 1) {
      const filter = {
        skip: value.page,
        limit: pageSize,
      }
      dispatch(getEmployee2(filter))
    } else if (publishToSelected === 2) {
      const departmentIds = departmentSelectedList.map((v, i) => ({
        [`filters[department][id][$in][${i}]`]: v.value,
      }))
      const filter = {
        skip: value.page,
        limit: pageSize,
        departmentIds,
      }
      dispatch(getEmployee2(filter))
    } else if (publishToSelected === 3) {
      const employeeIds = addedEmployees.map((v, i) => ({
        [`filters[id][$in][${i}]`]: v.id,
      }))
      const filter = {
        skip: value.page,
        limit: pageSize,
        employeeIds,
      }
      dispatch(getEmployee2(filter))
    }
  }
  // optional
  const addEmployee = () => {
    if (employeeSelected) {
      const isExisted = addedEmployees?.find((v) => v?.id === employeeSelected.value)

      if (isExisted === undefined) {
        const employeeMatch = employeeList.find((v) => v.id === employeeSelected.value)
        const { id, first_name, last_name, department, position, photo_url } = employeeMatch
        const addedEmployee = {
          id,
          first_name,
          last_name,
          department,
          position,
          photo_url,
        }
        setAddedEmployees((prev) => [...prev, addedEmployee])
      }
      setEmployeeSelected(null)
    }
  }
  const backToPrevious = () => {
    navigate(-1)
  }
  const backToAcknowledgementPage = () => {
    dispatch(setSharedDocumentForm({}))
    dispatch(
      setFlagDraft({
        draftId: '',
        isDraft: false,
      })
    )
    dispatch(setSharedServiceTemplateInfo({}))
    dispatch(setDocumentAcknowledgementInfo({}))
    dispatch(
      setFlagDraft({
        draftId: '',
        isDraft: false,
      })
    )
    navigate('/organization-management/acknowledgement-system?tab=document-management', { replace: true })
  }
  const publish = () => {
    setPublishModal(true)
  }

  // modal
  const closePuslishModal = () => {
    setPublishModal(false)
  }
  const openShowAllModal = () => {
    if (publishToSelected === 1) {
      const filter = {
        skip: 1,
        limit: 10,
      }
      dispatch(getEmployee2(filter))
    } else if (publishToSelected === 2) {
      const departmentIds = departmentSelectedList.map((v, i) => ({
        [`filters[department][id][$in][${i}]`]: v.value,
      }))
      const filter = {
        skip: 1,
        limit: 10,
        departmentIds,
      }
      dispatch(getEmployee2(filter))
    } else if (publishToSelected === 3) {
      const employeeIds = addedEmployees.map((v, i) => ({
        [`filters[id][$in][${i}]`]: v.id,
      }))
      const filter = {
        skip: 1,
        limit: 10,
        employeeIds,
      }
      dispatch(getEmployee2(filter))
    }

    setShowAllModal(true)
  }
  const closeShowAllModal = () => {
    setShowAllModal(false)
  }
  const confirmSuccess = () => {
    setSuccessModal(false)
    navigate('/organization-management/acknowledgement-system?tab=document-management', { replace: true })
  }

  // submit
  const submitPublish = () => {
    const request = {
      ...sharedDocumentForm,
      publish_to:
        publishToSelected === 1
          ? 'All Employee'
          : publishToSelected === 2
          ? 'Specific Department'
          : 'Specific Employee',
      departments: departmentSelectedList.length > 0 ? departmentSelectedList.map((v) => ({ id: v.value })) : null,
      employees: addedEmployees.length > 0 ? addedEmployees.map((v) => ({ id: v.id })) : null,
      email_noti: notificationChecked.find((v) => v.text === 'Email Notification')?.isChecked,
      in_app_noti: notificationChecked.find((v) => v.text === 'In-App Notification')?.isChecked,
      topics: sharedDocumentForm?.topics?.map((v) => ({
        title: v.title,
        description: v.description,
        images: v.images,
      })),
    }

    dispatch(createDocumentAcknowledgeWithDraft(documentAcknowledgementInfo?.id, request))

    setPublishModal(false)
  }

  // autocomplete
  const getOptionLabel = (option) => {
    if (!option) {
      return ''
    }
    return `${option.name}, ${option.department}, ${option.position}`
  }
  const renderOption = (props, option) => {
    return (
      <div {...props}>
        <b>{option.name}</b>, {option.department}, {option.position}
      </div>
    )
  }
  const onChangeEmployeeSelect = (value) => {
    setEmployeeSelected(value)
  }

  return (
    <Div>
      <div className="header mb-2-rem">
        <RiHome6Line size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px">{t('hr_organization')}</span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px" onClick={backToAcknowledgementPage}>
          {t('acknowledgement_system')}
        </span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="highlight">{sharedDocumentForm?.name}</span>
      </div>

      <div className="headline mb-20-px">{sharedDocumentForm?.name}</div>
      <div className="divider mb-24-px"></div>

      <div className="document-container mb-2-rem">
        <div className="step-container">
          <div className="step-progress">
            <div className="step-item">
              <BsCheckLg className="step-icon" size={20} />
            </div>
            <div className="line-connect active"></div>
            <div className="step-item">
              <TbPointFilled className="step-icon" size={20} />
            </div>
          </div>
          <div className="step-progress-2">
            <div className="mb-2-rem">
              <div className="header-step">{t('step_1_content_and_setting')}</div>
              <div className="description-step">{t('fill_required_fields_to_create_a_new_doc')}</div>
            </div>
            <div>
              <div className="header-step active">{t('step_2_publish')}</div>
              <div className="description-step active">{t('choose_recipients_and_notification_method')}</div>
            </div>
          </div>
        </div>
        <div className="input-container">
          <div className="input">
            <div className="input-header">{t('document_to_publish')}</div>
            <TextField
              className="w-input"
              value={`${sharedDocumentForm?.name} Version ${sharedDocumentForm?.version?.toFixed(1)}`}
              disabled={true}
            />
          </div>
          <div className="input">
            <div className="input-header">{t('publish_to')}</div>
            <Dropdown
              className="w-input"
              optionList={publishList}
              value={publishToSelected}
              onChange={(value) => setPublishToSelected(value)}
            />
          </div>
          {publishToSelected === 2 && (
            <>
              <div className="input">
                <div className="input-header">{t('select_department')}</div>
                <AutoComplete
                  className="w-input"
                  optionList={departmentList}
                  value={departmentSelected}
                  onChange={onChangeDepartmentSelect}
                />
              </div>
              <div className="department-selected-container">
                {departmentSelectedList.map((v) => (
                  <div className="badge-department" key={v.text}>
                    <span>{v.text}</span>
                    <LiaTimesSolid className="icon" onClick={() => deleteDepartmentSelected(v.value)} />
                  </div>
                ))}
              </div>
            </>
          )}

          {publishToSelected === 3 && (
            <div className="input">
              <div className="input-header">{t('select_employee')}</div>
              <div className="input-group">
                <AutoCompleteCustomText
                  className="w-input-80"
                  optionList={employees}
                  getOptionLabel={getOptionLabel}
                  renderOption={renderOption}
                  value={employeeSelected}
                  onChange={onChangeEmployeeSelect}
                />
                <Button className="w-input-15" onClick={addEmployee}>
                  {t('add_to_list')}
                </Button>
              </div>
            </div>
          )}

          <div className="input">
            <div className="input-header">{t('notification_settings')}</div>

            {notificationChecked.map((v, i) => (
              <div className="checkbox-container" key={i}>
                <OutlineCheckbox
                  className="mr-12-px"
                  checked={v.isChecked}
                  onChange={(e) => handleChangeCheckBox(e, i)}
                  disabled={v.text === 'In-App Notification' ? true : false}
                />
                <div>{v.text}</div>
              </div>
            ))}
          </div>
          <div className="divider mb-2-rem"></div>

          {publishToSelected === 3 && <Table columns={employeeColumn} data={addedEmployeesDisplay} />}
        </div>
      </div>

      <div className="divider mb-2-rem"></div>

      <div className="footer-container">
        <div>
          <ButtonOutline onClick={backToPrevious} append={<FiArrowLeft size={20} />}>
            {t('back')}
          </ButtonOutline>
        </div>
        <div>
          <ButtonOutline
            className="add-image-btn mr-12-px"
            append={<img className="icon-view" src={previewIcon} alt="previewIcon" />}
            onClick={() => setPreviewPdpaModal(true)}
          >
            {t('preview')}
          </ButtonOutline>
          <Button append={<FiArrowUpRight size={20} />} onClick={publish}>
            {t('publish')}
          </Button>
        </div>
      </div>

      {/* Modal */}
      <Modal open={publishModal} onClose={closePuslishModal} disableBackdropClick>
        <PublishConfirmModal
          // sharedServiceTemplateInfo={sharedServiceTemplateInfo}
          sharedDocumentForm={sharedDocumentForm}
          publishTo={publishToSelected}
          notificationType={notificationChecked}
          specificDepartments={departmentSelectedList}
          specificEmployees={addedEmployees}
          closeModal={closePuslishModal}
          submitConfirm={submitPublish}
          openShowAll={openShowAllModal}
        />
      </Modal>
      <Modal open={showAllModal} onClose={closeShowAllModal} disableBackdropClick>
        <PublishShowAllModal
          onChangePagination={onChangePagination}
          employeeColumn={employeeColumn}
          data={employeeList2}
          page={page}
          pageCount={pageCount}
          closeModal={closeShowAllModal}
        />
      </Modal>

      <ModalPdpaPreview
        previewData={sharedDocumentForm}
        isModalOpen={previewPdpaModal}
        onClose={() => setPreviewPdpaModal(false)}
      />
      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={confirmSuccess}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={t('publish_successful')}
        textYes={t('done')}
      />
    </Div>
  )
}

export default PublishDocumentForDraft
