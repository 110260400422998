import {
  Card,
  CardActions,
  CardContent,
  CardOverflow,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Modal,
  ModalClose,
  ModalDialog,
  Tooltip,
} from '@mui/joy'
import { Container, Stack, TextField, styled } from '@mui/material'
import { createElement, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IoMdInformationCircle } from 'react-icons/io'
import {
  RiAddLine,
  RiDeleteBinLine,
  RiDownloadLine,
  RiEditLine,
  RiEyeLine,
  RiPrinterLine,
  RiUserAddLine,
} from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import iconFail from 'assets/images/fail-icon.png'
import iconSuccess2 from 'assets/images/success-icon-2.png'
import iconUserPluse from 'assets/images/user-icon-plus.png'
import Button from 'components/common/Button'
import Empty from 'components/common/Empty'
import ModalV2 from 'components/common/ModalV2'
import TableV3 from 'components/common/TableV3'
import Typography from 'components/common/Typography'
import DynamicForm from 'components/form2/dynamic-form'
import FileUpload from 'components/form2/form-file-upload'
import { Autocomplete } from 'components/form2/renderFields'
import { DELIVERY, DOCUMENT, DOCUMENT_SIGNER, SW } from 'constants/operation-process'
import DialogDocument from 'pages/document/DialogDocument'
import RootCreateDocument from 'pages/document/business-doc/RootCreateDocument'
import { BsPip } from 'react-icons/bs'
import { CgArrowsExpandLeft } from 'react-icons/cg'
import { MdOutlineCancel } from 'react-icons/md'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { capitalizeEachWord } from 'utils/stringHelpers'
import { Chip } from '.'

const ActionCell = ({ items = [] }) => (
  <Stack direction="row" sx={{ gap: '0.5rem', justifyContent: 'flex-end' }}>
    {items.map((item, index) => (
      <Tooltip key={index} title={item?.text}>
        <IconButton onClick={item?.action} size="sm" variant="outlined" disabled={item?.disabled}>
          {item?.icon}
        </IconButton>
      </Tooltip>
    ))}
  </Stack>
)

const DeliveryTab = ({ allowUpload = true, deliveries = [], onUploadCallback, onDeleteCallback, hideActions }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)
  const [searchParams, setSearchParams] = useSearchParams()

  const [isModalDocumentOpen, setIsModalDocumentOpen] = useState(false)
  const [layoutModal, setLayoutModal] = useState('center')
  const [modalProps, setModalProps] = useState({ open: false })
  const [isDialogDocument, setIsDialogDocument] = useState(false)
  const [dialogDocumentProps, setDialogDocumentProps] = useState({})
  const [docSignerEmpOptions, setDocSignerEmpOptions] = useState([])

  const { document: documentActions, extra: extraActions } = opActions()
  const { postExtra, deleteExtra } = extraActions()
  const { getBizDoc, postDoc, deleteDoc, getDocSigner } = documentActions()

  const { sw } = opProcSelector
  const documentType = searchParams.get('type')
  const document = deliveries?.find((delivery) => delivery?.type === DELIVERY.TYPE.Document)?.document || null
  const signers = document?.signers || []
  const methods = useForm({
    defaultValues: { signers: signers?.map((signer) => ({ ...signer, signer_id: signer.id })) },
  })

  const tableColumns = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('attachment_col_name'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('attachment_col_info'),
      accessor: 'info',
      disableSortBy: false,
    },
    {
      Header: t('attachment_col_files'),
      accessor: 'files',
      disableSortBy: false,
    },
    {
      accessor: 'actions',
      disableSortBy: false,
    },
  ]

  const formSchema = {
    properties: {
      signers: {
        type: 'fieldArray',
        props: {
          render: ({ field, index }) => {
            const key = `signers.${index}.employee`

            return (
              <section key="signers" style={{ marginBottom: '1rem' }}>
                <label>{field?.label} :</label>
                <Stack gap="0.5rem">
                  <Stack direction="row" gap="1rem" alignItems="center">
                    <Autocomplete
                      {...methods.register(key, { required: `กรุณาเลือก${field.label}` })}
                      options={docSignerEmpOptions?.find((opt) => opt.id === field.signer_id)?.options || []}
                      getOptionLabel={(option) =>
                        `${option.first_name} ${option.last_name} (${option?.position?.name})`
                      }
                      onChange={(_e, selectVal) => {
                        methods.setValue(key, selectVal)
                      }}
                      defaultValue={field?.employee || null}
                      renderOption={(props, option, { selected }) => (
                        <AutocompleteOption {...props}>
                          <p className="title">{`${option.first_name} ${option.last_name}`}</p>
                          <p className="supporting-text">
                            {(option?.position && option.position?.name) || 'Position not found.'}
                          </p>
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => <TextField {...params} placeholder="Please select approver..." />}
                      disabled={
                        field?.status !== null &&
                        document.status !== DOCUMENT.STATUS.Canceled &&
                        document.status !== DOCUMENT.STATUS.Rejected
                      }
                      sx={{
                        width: '100%',
                        minWidth: '350px',
                        maxWidth: '400px',
                      }}
                    />

                    {field.status && <Chip label={field?.status} color={DOCUMENT_SIGNER.STATUS_CHIP[field.status]} />}
                  </Stack>

                  {methods.formState.errors?.signers?.[index]?.employee?.id && (
                    <span style={{ color: 'red' }}>
                      {methods.formState.errors?.signers?.[index]?.employee?.id?.message}
                    </span>
                  )}
                </Stack>
              </section>
            )
          },
        },
      },
    },
  }

  const fetchBizDoc = async (bizDocId) => {
    return dispatch(getBizDoc().byId(bizDocId))
  }

  const fetchDocSignerEmployee = useCallback(
    async (docSignerId, payload) => {
      return dispatch(getDocSigner().employees(docSignerId, payload))
    },
    [dispatch, getDocSigner]
  )

  const handleUploadDeliveryFiles = async (deliveryId, files) => {
    if (files) {
      await Promise.all(
        files.map((file) => {
          const payload = {
            file: {
              id: file.id,
            },
          }

          return dispatch(postExtra().delivery(deliveryId, payload))
            .then((res) => {
              const { data, type } = res

              if (type.endsWith('_SUCCESS') && !res.error) {
                return res
              }
            })
            .catch((error) => {
              console.error('Error uploading file:', error)
            })
        })
      )

      return onUploadCallback && onUploadCallback({ uploadFile: true })
    }
  }

  const handleClickCreateDocument = (docId, docType) => {
    return (_event) => {
      setSearchParams({ documentId: docId, type: docType, navigate: 'external' })
      setIsModalDocumentOpen(true)
    }
  }

  const handleCreateDocument = (businessDocId) => {
    if (!businessDocId) return

    const documentId = searchParams.get('documentId')

    if (!documentId) {
      console.error('Document ID not found.')
      return
    }

    dispatch(postDoc().create(documentId, businessDocId))
      .then((response) => {
        if (response.type.endsWith('_SUCCESS') && !response.error) {
          setIsModalDocumentOpen(false)
          setLayoutModal('center')
          navigate(`${location.pathname}`)
          onUploadCallback && onUploadCallback({ uploadFile: false })
        }
      })
      .catch((error) => {
        console.error('Error creating document:', error)
      })
  }

  const actionOptions = (fileUrl, fileName, deliveryFileId) => {
    return [
      {
        icon: <RiEyeLine />,
        text: t('preview'),
        action: () => {
          window.open(fileUrl, '_blank')
        },
      },
      {
        icon: <RiDownloadLine />,
        text: t('download'),
        action: () => {
          fetch(fileUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = fileName
              a.click()

              window.URL.revokeObjectURL(url) // free up storage--no longer needed.
            })
            .catch((error) => console.error(error))
        },
      },
      {
        icon: <RiDeleteBinLine />,
        text: t('delete'),
        disabled: !allowUpload || sw?.status === SW.STATUS.Completed || sw?.status === SW.STATUS.Canceled,
        action: () => {
          dispatch(deleteExtra().delivery(deliveryFileId)).then((res) => {
            if (res.type.endsWith('_SUCCESS') && !res.error) {
              return onDeleteCallback && onDeleteCallback({ uploadFile: true })
            }
          })
        },
      },
    ]
  }

  const actionDocOptions = ({ document }) => {
    return [
      {
        icon: <RiEyeLine />,
        text: t('preview'),
        action() {
          setIsDialogDocument(true)
          setDialogDocumentProps({
            docType: document.type,
            previewType: 'preview',
            id: document.business_doc.id,
          })
        },
      },
      {
        icon: <RiDownloadLine />,
        text: t('download_pdf'),
        action() {
          setIsDialogDocument(true)
          setDialogDocumentProps({
            docType: document.type,
            previewType: 'download_pdf',
            id: document.business_doc.id,
            docId: document.business_doc.doc_id,
            onDownloaded() {
              setIsDialogDocument(false)
            },
          })
        },
      },
      {
        icon: <RiPrinterLine />,
        text: t('print'),
        action() {
          setIsDialogDocument(true)
          setDialogDocumentProps({
            docType: document.type,
            previewType: 'print',
            id: document.business_doc.id,
          })
        },
      },
      {
        icon: <RiEditLine />,
        text: t('Edit'),
        disabled: !(
          document?.status === DOCUMENT.STATUS.Created ||
          document?.status === DOCUMENT.STATUS.Rejected ||
          document?.status === DOCUMENT.STATUS.Canceled
        ),
        action() {
          const group = () => {
            switch (document.type) {
              case DOCUMENT.TYPE.GoodsReceivedNote:
              case DOCUMENT.TYPE.PaymentVoucher:
                return 'business_doc'

              case DOCUMENT.TYPE.PurchaseOrder:
              case DOCUMENT.TYPE.ChequePaymentJournal:
              case DOCUMENT.TYPE.PaymentJournal:
              case DOCUMENT.TYPE.PettyCashVoucher:
              case DOCUMENT.TYPE.PurchaseRequest:
              case DOCUMENT.TYPE.ComparisonSchedule:
                return 'purchase'

              case DOCUMENT.TYPE.Quotation:
              case DOCUMENT.TYPE.Billing:
              case DOCUMENT.TYPE.Invoice:
              case DOCUMENT.TYPE.Receipt:
              case DOCUMENT.TYPE.DebitNote:
              case DOCUMENT.TYPE.PledgeProductCertificationRequest:
                return 'sales'
            }
          }
          navigate(
            `/document/${group()}/edit?documentId=${document.id}&type=${document.type}&id=${
              document.business_doc.id
            }&navigate=external`
          )
          setIsModalDocumentOpen(true)
        },
      },
      {
        icon: <RiDeleteBinLine />,
        text: t('delete'),
        disabled: document?.status !== DOCUMENT.STATUS.Created,
        action() {
          const successDeleteModal = {
            open: true,
            showCancel: false,
            onClose() {
              setModalProps({ open: false })
              return onDeleteCallback && onDeleteCallback()
            },
            onOk() {
              setModalProps({ open: false })
              return onDeleteCallback && onDeleteCallback()
            },
            titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
            titleText: `Document has been deleted.`,
            contentText: `The document "${document?.business_doc?.doc_id}" has been deleted successfully.`,
          }

          const confirmDeleteModal = {
            open: true,
            onClose() {
              setModalProps({ open: false })
            },
            onOk() {
              dispatch(deleteDoc().byId(document.id)).then((res) => {
                if (res.type.endsWith('_SUCCESS') && !res.error) {
                  setModalProps(successDeleteModal)
                  return onDeleteCallback && onDeleteCallback()
                }
              })
            },
            onCancel() {
              setModalProps({ open: false })
            },
            okText: 'Yes, Delete',
            cancelText: 'Cancel',
            titleIcon: createElement('img', { src: iconFail, width: 48, height: 48, alt: 'i-alert' }),
            titleText: `Are you sure you want to delete <br />"${document?.business_doc?.doc_id}"`,
            contentText: "This document will be deleted immediately. You can't undo this action.",
          }

          setModalProps(confirmDeleteModal)
        },
      },
    ]
  }

  const renderFileCell = (deliveryId, files = [], isCompleted = false) => {
    if ((!files || files.length === 0) && !isCompleted) {
      return {
        files: !hideActions && (
          <FileUpload
            id="delivery-file-upload"
            onUploadCallback={(files) => handleUploadDeliveryFiles(deliveryId, files)}
            inputFileProps={{ multiple: false, disabled: !allowUpload }}
            buttonProps={{ disabled: !allowUpload }}
          />
        ),
      }
    }

    const firstFiles = files?.[0] || {}
    const { id: deliveryFileId, name } = firstFiles || {}
    const { url } = firstFiles?.file || {}
    const { first_name, last_name } = firstFiles?.employee || {}
    const uploadedBy = `${first_name} ${last_name}`

    return {
      files: (
        <Tooltip title={`Uploaded by: ${uploadedBy}`}>
          <Link level="body-sm">{name}</Link>
        </Tooltip>
      ),
      actions: <ActionCell items={actionOptions(url, name, deliveryFileId)} />,
    }
  }

  const renderDocumentFileCell = (delivery) => {
    const { document } = delivery

    if (!document) {
      return { files: 'Document not found.' }
    }

    const { id: documentId, type: documentType, business_doc } = document

    if (!documentType) {
      return { files: 'Document type not found.' }
    }

    if (!business_doc) {
      return {
        files: (
          <Button
            variant="contained"
            startIcon={<RiAddLine />}
            onClick={handleClickCreateDocument(documentId, documentType)}
          >
            {capitalizeEachWord(t('create_document'))}
          </Button>
        ),
      }
    }

    const { doc_id: bizDocCode } = business_doc

    const options = actionDocOptions({ document })

    return {
      files: (
        <Stack direction="row" spacing={2} alignItems="baseline">
          <Tooltip title={`${document?.type}`}>
            <Link level="body-sm">{bizDocCode}</Link>
          </Tooltip>
        </Stack>
      ),
      actions: <ActionCell items={options} />,
    }
  }

  const transformDeliveriesToTable = (data) => {
    if (!data || data.length === 0) return []

    const mapDataItem = (item, index) => {
      let newItem = {
        ...item,
        no: index + 1,
        info: (
          <Tooltip title={item?.info}>
            <Link level="body-sm">
              Info
              <IoMdInformationCircle fontSize={16} />
            </Link>
          </Tooltip>
        ),
      }

      switch (item?.type) {
        case DELIVERY.TYPE.UploadFile:
          newItem = {
            ...newItem,
            ...renderFileCell(item.id, item?.files, item?.is_completed),
          }
          break

        case DELIVERY.TYPE.Document:
          newItem = {
            ...newItem,
            ...renderDocumentFileCell(item),
          }
          break

        default:
          newItem = {
            ...newItem,
          }
          break
      }

      return newItem
    }

    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const handleSubmit = (data) => {
    // console.log('Submit data:', { document, data })
    const successfulRequestApproval = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
        return onUploadCallback && onUploadCallback()
      },
      onOk() {
        setModalProps({ open: false })
        return onUploadCallback && onUploadCallback()
      },
      titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
      titleText: `Request approval has been sent to the signers.`,
      contentText: `The document "${document?.business_doc?.doc_id}" has been sent to the signers for approval.`,
    }

    const confirmRequestApproval = {
      open: true,
      onClose() {
        setModalProps({ open: false })
      },
      onOk() {
        dispatch(postDoc().byIdRequest(document.id, data)).then((res) => {
          if (res.type.endsWith('_SUCCESS') && !res.error) {
            setModalProps(successfulRequestApproval)
          }
        })
      },
      onCancel() {
        setModalProps({ open: false })
      },
      okText: 'Yes, Continue',
      cancelText: 'No',
      titleIcon: createElement('img', { src: iconUserPluse, width: 48, height: 48, alt: 'i-userPlus' }),
      titleText: 'Are you sure you want to request approval for this document?',
      contentText: `The document "${document?.business_doc?.doc_id}" will be sent to the signers for approval.`,
    }

    setModalProps(confirmRequestApproval)
  }

  const handleCancelApproval = () => {
    const successfulCancelApproval = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
        return onUploadCallback && onUploadCallback()
      },
      onOk() {
        setModalProps({ open: false })
        return onUploadCallback && onUploadCallback()
      },
      titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
      titleText: `Approval has been canceled.`,
      contentText: `The approval for the document "${document?.business_doc?.doc_id}" has been canceled.`,
    }

    const confirmCancelApproval = {
      open: true,
      onClose() {
        setModalProps({ open: false })
      },
      onOk() {
        dispatch(postDoc().byIdCancel(document.id)).then((res) => {
          if (res.type.endsWith('_SUCCESS') && !res.error) {
            setModalProps(successfulCancelApproval)
          }
        })
      },
      onCancel() {
        setModalProps({ open: false })
      },
      okText: 'Yes, Continue',
      cancelText: 'No',
      titleIcon: createElement('img', { src: iconFail, width: 48, height: 48, alt: 'i-alert' }),
      titleText: 'Are you sure you want to cancel approval for this document?',
      contentText: `The approval for the document "${document?.business_doc?.doc_id}" will be canceled.`,
      okProps: { color: 'error' },
    }

    setModalProps(confirmCancelApproval)
  }

  useEffect(() => {
    if (!documentType) {
      setIsModalDocumentOpen(false)
      setLayoutModal('center')
    }
  }, [searchParams])

  const handleFetchDocSignerEmployee = async (signersIds) => {
    if (signersIds.length > 0) {
      const docSignerOptions = await Promise.all(
        signersIds.map(async (signerId) => {
          const res = await fetchDocSignerEmployee(signerId)
          return { id: signerId, options: res?.data }
        })
      )

      return setDocSignerEmpOptions(docSignerOptions)
    }
  }

  useEffect(() => {
    /** New feature: get signer employees in first render. */
    if (signers.length > 0) {
      const signersIds = signers.map((signer) => signer.id)
      handleFetchDocSignerEmployee(signersIds)

      if (deliveries.length > 0) {
        methods.reset({ signers: signers?.map((signer) => ({ ...signer, signer_id: signer.id })) })
      }
    }
  }, [deliveries])

  if (!deliveries || deliveries.length === 0) {
    return <Empty title={t('no_deliveries_found')} description={t('no_deliveries_found_subtitle')} />
  }

  return (
    <StyledContainer maxWidth={false} disableGutters sx={{ padding: '0 1rem 1rem 1rem' }}>
      <Card
        variant="outlined"
        sx={{
          maxWidth: '100%',
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          overflow: 'hidden',
          bgcolor: 'var(--Base-White)',
          fontFamily: 'Inter, Noto Sans Thai',
        }}
      >
        <TableHeader>
          <Stack spacing={2}>
            <Typography variant="tableTitle">{capitalizeEachWord(t('attachment'))}</Typography>
            <TypographyP>{t('attachment_subtitle')}</TypographyP>
          </Stack>
        </TableHeader>
        <TableV3
          columns={tableColumns}
          data={transformDeliveriesToTable(
            deliveries.filter((delivery) => delivery?.type === DELIVERY.TYPE.UploadFile)
          )}
        />
      </Card>

      {deliveries.filter((delivery) => delivery?.type === DELIVERY.TYPE.Document).length > 0 && (
        <>
          <Card
            variant="outlined"
            sx={{
              maxWidth: '100%',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              margin: '1rem 0',
              overflow: 'hidden',
              bgcolor: 'var(--Base-White)',
              fontFamily: 'Inter, Noto Sans Thai',
            }}
          >
            <TableHeader>
              <Stack spacing={2}>
                <Typography variant="tableTitle">{capitalizeEachWord(t('document'))}</Typography>
                <TypographyP>{t('document_subtitle')}</TypographyP>
              </Stack>
            </TableHeader>
            <TableV3
              columns={tableColumns}
              data={transformDeliveriesToTable(
                deliveries.filter((delivery) => delivery?.type === DELIVERY.TYPE.Document)
              )}
            />
          </Card>

          <Card
            variant="outlined"
            sx={{
              maxWidth: '100%',
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
              margin: '1rem 0',
              pb: 0,
              overflow: 'hidden',
              bgcolor: 'var(--Base-White)',
              fontFamily: 'Inter, Noto Sans Thai',
            }}
          >
            {signers.length > 0 ? (
              <>
                <CardContent>
                  <Stack direction="row" gap="1rem">
                    <Typography variant="cardTitle" sx={{ marginBottom: '1rem' }}>
                      {t('request_approval')}
                    </Typography>
                    <Chip label={document?.status} color={DOCUMENT.STATUS_CHIP[document?.status]} />
                  </Stack>
                  <DynamicForm methods={methods} schema={formSchema} onSubmit={handleSubmit} />
                </CardContent>

                {!hideActions && sw?.status !== SW.STATUS.Completed && (
                  <CardOverflow sx={{ bgcolor: 'var(--Gray-50)', alignItems: 'flex-end' }}>
                    <Divider inset="context" />
                    <CardActions
                      sx={{
                        width: 'fit-content',
                      }}
                    >
                      <Button
                        color={
                          document.status === DOCUMENT.STATUS.Created ||
                          document.status === DOCUMENT.STATUS.Canceled ||
                          document.status === DOCUMENT.STATUS.Rejected
                            ? 'primary'
                            : 'error'
                        }
                        variant="contained"
                        startIcon={
                          document.status === DOCUMENT.STATUS.Created ? <RiUserAddLine /> : <MdOutlineCancel />
                        }
                        onClick={() => {
                          if (
                            document.status === DOCUMENT.STATUS.Created ||
                            document.status === DOCUMENT.STATUS.Canceled ||
                            document.status === DOCUMENT.STATUS.Rejected
                          ) {
                            return methods.handleSubmit(handleSubmit)()
                          }
                          return handleCancelApproval()
                        }}
                      >
                        {document.status === DOCUMENT.STATUS.Created ||
                        document.status === DOCUMENT.STATUS.Canceled ||
                        document.status === DOCUMENT.STATUS.Rejected
                          ? t('submit_request_approval')
                          : t('cancel_approval')}
                      </Button>
                    </CardActions>
                  </CardOverflow>
                )}
              </>
            ) : (
              <Stack height={320}>
                <Empty title={t('empty_signers')} description={t('empty_signers_subtitle')} />
              </Stack>
            )}
          </Card>
        </>
      )}

      <Modal
        open={isModalDocumentOpen}
        onClose={() => {
          setIsModalDocumentOpen(!isModalDocumentOpen)
          setLayoutModal('center')
          navigate(`${location.pathname}`)
        }}
      >
        <ModalDialog
          layout={layoutModal}
          maxWidth={layoutModal === 'fullscreen' ? '100vw' : 1440}
          minWidth={1440}
          sx={{
            padding: '0',
            background: 'var(--Base-White)',
          }}
        >
          <ModalClose />
          <DialogTitle
            sx={{
              justifyContent: 'flex-end',
              marginInlineEnd: '2.5rem',
              paddingTop: '0.5rem',
            }}
          >
            {layoutModal === 'center' ? (
              <Tooltip title={t('expand_fullscreen')}>
                <IconButton onClick={() => setLayoutModal('fullscreen')} size="sm">
                  <CgArrowsExpandLeft fontSize={13} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t('minimize_to_center')}>
                <IconButton onClick={() => setLayoutModal('center')} size="sm">
                  <BsPip fontSize={16} />
                </IconButton>
              </Tooltip>
            )}
          </DialogTitle>
          <Stack
            sx={{
              height: '100%',
              overflow: 'overlay',
              alignItems: 'center',
              mx: 'calc(-1 * var(--ModalDialog-padding))',
              px: 'var(--ModalDialog-padding)',
            }}
          >
            <Container maxWidth="xl">
              {documentType !== null && <RootCreateDocument onCallback={handleCreateDocument} />}
            </Container>
          </Stack>
        </ModalDialog>
      </Modal>

      <ModalV2 {...modalProps} />

      {isDialogDocument && (
        <DialogDocument
          {...dialogDocumentProps}
          onClose={() => {
            setIsDialogDocument(false)
            setDialogDocumentProps({})
          }}
        />
      )}
    </StyledContainer>
  )
}

export default DeliveryTab

const StyledContainer = styled(Container)`
  height: auto;
  padding-bottom: 1rem;

  // Webkit scrollbar - style #2
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b3b3b3;
  }
`

/* TODO: This code is repeated in multiple places */
const TableHeader = styled(Stack)({
  width: '100%',
})

/* TODO: This code is repeated in multiple places */
const TypographyP = styled('p')`
  overflow: hidden;
  color: var(--Gray-600);
  text-overflow: ellipsis;

  /* Text sm/Regular */
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */

  margin-top: 0.5rem !important;
`

const AutocompleteOption = styled('li')`
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 0 0.75rem !important;
  gap: 0.5rem;

  .title {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.25rem;
  }

  .supporting-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.25rem;
  }
`
