import { Avatar, useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'
import i18n from 'i18next'
import { forwardRef } from 'react'
import { IoChevronForward, IoClose, IoMenu } from 'react-icons/io5'
import { useDispatch } from 'react-redux'

import { logoutAdmin } from 'redux/actions/admin'
import { setIsRedirect } from 'redux/actions/master'
import { getAdminUser, getEmployeeInfo } from 'utils/common'

const Footer = forwardRef(function Footer(
  { onProfileClick, isCollapse = false, onBurgerClick, isOpenMenu = false },
  ref
) {
  const breakpointLG = useMediaQuery('(min-width:1024px)')
  const dispatch = useDispatch()
  const employee_info = getEmployeeInfo()
  const { first_name, last_name, email } = getAdminUser() || {}

  const handleToggleLanguage = (language) => {
    return () => {
      i18n.changeLanguage(language)
      localStorage.setItem('lang', language)
      window.location.reload()
    }
  }

  const handleLogout = () => {
    dispatch(setIsRedirect(null))
    dispatch(logoutAdmin())
  }

  const handleOpenExtraProfile = () => {
    const children = [
      {
        key: 'change_password',
        group_menu: 'myAccount_group',
        name_th: 'เปลี่ยนรหัสผ่าน',
        name_en: 'Change password',
        pathto: '/change-password',
        children: null,
        permission_tab: null,
        permission_other: null,
      },
      {
        key: 'change_language',
        group_menu: 'myAccount_group',
        name_th: 'เปลี่ยนภาษา',
        name_en: 'Language setting',
        pathto: '/',
        children: [
          {
            key: 'th',
            group_menu: 'myAccount_group',
            name_th: 'ไทย',
            name_en: 'Thai',
            pathto: handleToggleLanguage('th'),
            children: null,
            permission_tab: null,
            permission_other: null,
          },
          {
            key: 'en',
            group_menu: 'myAccount_group',
            name_th: 'อังกฤษ',
            name_en: 'English',
            pathto: handleToggleLanguage('en'),
            children: null,
            permission_tab: null,
            permission_other: null,
          },
        ],
        permission_tab: null,
        permission_other: null,
      },
      {
        key: 'logout',
        group_menu: 'myAccount_group',
        name_th: 'ออกจากระบบ',
        name_en: 'Logout',
        pathto: handleLogout,
        children: null,
        permission_tab: null,
        permission_other: null,
      },
    ]

    const extraItems = {
      key: 'my_account',
      group_menu: 'myAccount_group',
      name_th: 'บัญชีของฉัน',
      name_en: 'My account',
      pathto: '/',
      children,
      permission_tab: null,
      permission_other: null,
    }

    onProfileClick?.(extraItems)
  }

  return (
    <StyledFooter ref={ref}>
      <StyledFooterUserProfile
        className={`erp-profile${breakpointLG ? '' : '-header-nav'}`}
        onClick={handleOpenExtraProfile}
      >
        <span className="erp-profile-avatar">
          {employee_info?.photo ? (
            <Avatar alt="img-profile" src={employee_info.photo} sx={!breakpointLG ? { width: 32, height: 32 } : {}} />
          ) : (
            <Avatar sx={!breakpointLG ? { width: 32, height: 32 } : {}}>{first_name?.charAt(0)}</Avatar>
          )}
        </span>

        <div className="erp-profile-info">
          <h3 className="erp-name-user">
            {first_name} {last_name}
          </h3>
          <p className="erp-email-user">{email}</p>

          <span className="erp-profile-dropdown">
            <IoChevronForward fontSize={20} />
          </span>
        </div>
      </StyledFooterUserProfile>

      {!breakpointLG && (
        <StyledFooterUserProfile
          className={`erp-profile-header-nav${isOpenMenu ? ' open' : ' close'}`}
          onClick={() => onBurgerClick?.()}
        >
          <span className="erp-burger-icon close-i">
            <IoClose fontSize={32} />
          </span>

          <span className="erp-burger-icon open-i">
            <IoMenu fontSize={32} />
          </span>
        </StyledFooterUserProfile>
      )}
    </StyledFooter>
  )
})

export default Footer

const StyledFooterUserProfile = styled('div')`
  display: flex;
  padding: 1rem 0.5rem;
  margin: 0 8px;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.175);
  }

  .erp-burger-icon,
  .erp-profile-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }

  .erp-profile-info {
    position: relative;
    display: none;
    flex-direction: column;
    justify-content: center;

    flex-grow: 1;

    & > h3.erp-name-user {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
      color: var(--Primary-50);
      line-height: 20px;

      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > p.erp-email-user {
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      color: var(--Primary-200);
      line-height: 20px;

      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .erp-profile-dropdown {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &.erp-profile-header-nav {
    transition: all 0.3s ease-in-out;
    position: relative;

    > .erp-burger-icon {
      position: absolute;
    }

    &.open {
      > .erp-burger-icon.close-i {
        scale: 1;
      }

      > .erp-burger-icon.open-i {
        scale: 0;
      }
    }

    &.close {
      > .erp-burger-icon.close-i {
        scale: 0;
      }

      > .erp-burger-icon.open-i {
        scale: 1;
      }
    }
  }

  @media (min-width: 1024px) {
    .erp-profile-info {
      display: flex;
    }
  }
`

const StyledFooter = styled('footer')`
  display: flex;
  padding: 0.5rem 0;
  height: auto;
  z-index: 2;
  position: sticky;
  bottom: 0;
`
