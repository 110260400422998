import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import IconChevronLeft from '../icon/IconChevronLeft'

export default function DropdownSelect({
  onChange,
  onClick,
  initValue, //"all"
  dataList, //[{text:'All',value:'all'}]
  design = 'outline',
  className,
  width = '200px',

  bgColor = '#fff',
  borderRadius = '8px',
  borderColor = '#d0d5dd',
  borderErrorColor = 'red',
  bgDisabledColor = '#f5f5f5',
  style,
  isError,
  isDisabled,
  placeHolder = 'Select',
  isEllipsis,

  dropdownTopGap = 1,
  // dropdownWidth = 200,
}) {
  const outerRef = useRef()
  const dropdownRef = useRef()

  const [isFocus, setIsFocus] = useState(false)

  const [globalMousePos, setGlobalMousePos] = useState({})
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 })
  const [popUpTopPosition, setPopUpTopPosition] = useState(0)
  const [popUpLeftPosition, setPopUpLeftPosition] = useState(0)
  const [outerButtonHeight, setOuterButtonHeight] = useState(0)
  const [outerButtonWidth, setOuterButtonWidth] = useState(0)

  const [selectValue, setSelectValue] = useState(placeHolder)

  useEffect(() => {
    function handleClickOutside(event) {
      if (outerRef.current && !outerRef.current.contains(event.target)) {
        setIsFocus(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [outerRef])

  useEffect(() => {
    if (initValue && dataList) {
      const findInit = dataList.find((x) => x.value === initValue)

      if (findInit) {
        setSelectValue(findInit.text)
      }
    } else {
      setSelectValue(placeHolder)
    }
  }, [initValue, dataList])

  useEffect(() => {
    const handleMouseMove = (event) => {
      const cx = event.clientX
      const cy = event.clientY
      setGlobalMousePos({
        x: cx,
        y: cy,
      })
    }
    window.addEventListener('mousemove', handleMouseMove)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [])
  useEffect(() => {
    setOuterButtonHeight(outerRef?.current?.clientHeight ?? 0)
    setOuterButtonWidth(outerRef?.current?.clientWidth ?? 0)

    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }, [])
  useEffect(() => {
    const tmpDropdownHeight = dropdownRef?.current?.clientHeight

    if (isFocus) {
      if (tmpDropdownHeight) {
        if (globalMousePos?.y + tmpDropdownHeight < windowSize?.height) {
          setPopUpTopPosition(outerButtonHeight + dropdownTopGap)
        } else {
          setPopUpTopPosition(-(tmpDropdownHeight + dropdownTopGap))
        }
      }

      if (globalMousePos?.x > outerButtonWidth) {
        setPopUpLeftPosition(-outerButtonWidth - 5)
      } else {
        setPopUpLeftPosition(5)
      }
    }
  }, [isFocus])

  const handleSelected = (e, item) => {
    setIsFocus(false)
    setSelectValue(item?.text)
    if (onChange) {
      onChange(item.value, e)
    }
  }
  const handleClick = (e) => {
    if (onClick) {
      onClick(e)
    }
    setIsFocus(!isFocus)
  }
  return (
    <Styled
      className={
        (design == 'outline' ? 'outline' : 'solid') +
        (isError ? ' input-error' : '') +
        (className ? ' ' + className : '') +
        (isDisabled ? ' input-disabled' : '')
      }
      style={style}
      ref={outerRef}
      width={width}
      bgColor={bgColor}
      isFocus={isFocus}
      borderRadius={borderRadius}
      isDisabled={isDisabled}
      borderColor={borderColor}
      borderErrorColor={borderErrorColor}
      bgDisabledColor={bgDisabledColor}
      dropdownTopGap={dropdownTopGap}
      listTop={popUpTopPosition}
      listLeft={popUpLeftPosition}
      dropdownWidth={outerButtonWidth}
    >
      <div className="selected-wrp" onClick={handleClick}>
        <span className={'select-text' + (isEllipsis ? ' ellipsis' : '')}>{selectValue}</span>
        <i className="icon-dropdown">
          <IconChevronLeft degree="270" width="7" color="#667085" />
        </i>
      </div>
      {!isDisabled && isFocus && (
        <ul className="list" ref={dropdownRef}>
          {dataList?.map((item, index) => (
            <li key={'item-' + index} onClick={(e) => handleSelected(e, item)}>
              {item?.text || ''}
            </li>
          ))}
        </ul>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  position: relative;
  display: flex;
  user-select: none;
  cursor: pointer;
  width: ${({ width }) => width};
  background-color: ${({ bgColor }) => bgColor};
  color: #667085;
  font-size: 14px;
  .selected-wrp {
    position: relative;
    width: 100%;
    padding: 8px 10px;
  }
  .select-text {
    margin-right: 10px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .icon-dropdown {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .list {
    position: absolute;
    margin: 0;
    width: ${({ dropdownWidth }) => dropdownWidth + 'px'};

    z-index: 999;
    background-color: ${({ bgColor }) => bgColor};
    list-style: none;
    padding: 0;
    /* top: ${({ gap }) => 'calc(100% + ' + gap + ')'}; */
    top: ${({ listTop }) => listTop + 'px'};
    left: ${({ listLeft }) => listLeft + 'px'};
  }
  .list li {
    padding: 8px 10px;
  }

  &.outline {
    border-radius: ${({ borderRadius }) => borderRadius};
    border: 1px solid ${({ borderColor }) => borderColor};
  }
  &.solid {
    background-color: #ececec;
    border-radius: 10px;
  }
  &.outline .list {
    border-radius: ${({ borderRadius }) => borderRadius};
    border: 1px solid ${({ borderColor }) => borderColor};
    width: 102%;
    left: -1px;
    max-height: 150px;
    overflow-y: auto;
  }
  &.input-error.outline {
    border-color: ${({ borderErrorColor }) => borderErrorColor};
  }
  &.input-error.outline .list {
    border-color: ${({ borderErrorColor }) => borderErrorColor};
  }
  &.input-disabled.outline {
    background-color: ${({ bgDisabledColor }) => bgDisabledColor};
  }
`
