import { combineReducers } from 'redux'
import {
  GET_EMPLOYEE_PROFILE_INFO,
  REQUEST_EDIT_EMPLOYEE_PROFILE_INFO,
  EDIT_EMPLOYEE_PROFILE_INFO,
  GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE,
  GET_REQUEST_EDIT_LOGS,
  GET_REQUEST_EDIT_LOG_BY_ID,
  APPROVE_REQUEST_EDIT,
  GET_ARCHIVED_REQUEST,
  GET_EMPLOYEE_BY_ID,
  CHANGE_PASSWORD,
  VERIFY_PASSWORD,
  UPLOAD_SIGNATURE_IMAGE,
  UPLOAD_EMPLOYEE_SIGNATURE,
  GET_EMPLOYEE_SIGNATURE,
  CLEAR_STATE_EMPLOYEE_PROFILE,
  CLEAR_RESPONSE_UPLOAD_SIGNATURE,
} from '../actionTypes'

const initialState = {
  employeeProfileInfo: {},
  employeeInfo: {},
  requestEditLogsByEmployee: [],
  requestEditLogs: [],
  archivedRequest: [],
  employeeSignature: [],
  page: 1,
  pageCount: 1,
  total: 1,
  limit: 10,
  isLoading: false,
  state: '',
  response: {},
}

const employeeProfileReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_EMPLOYEE_PROFILE_INFO.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_EMPLOYEE_PROFILE_INFO.REQUEST',
      }
    case GET_EMPLOYEE_PROFILE_INFO.SUCCESS: {
      const { data } = action
      return {
        ...state,
        employeeProfileInfo: data,
        isLoading: false,
        state: 'GET_EMPLOYEE_PROFILE_INFO.SUCCESS',
      }
    }
    case GET_EMPLOYEE_PROFILE_INFO.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_EMPLOYEE_PROFILE_INFO.FAILURE',
      }
    case GET_EMPLOYEE_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_EMPLOYEE_BY_ID.REQUEST',
      }
    case GET_EMPLOYEE_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        employeeInfo: data,
        isLoading: false,
        state: 'GET_EMPLOYEE_BY_ID.SUCCESS',
      }
    }
    case GET_EMPLOYEE_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_EMPLOYEE_BY_ID.FAILURE',
      }
    case GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE.REQUEST',
      }
    case GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        requestEditLogsByEmployee: data,
        page: meta.pagination.page,
        limit: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
        state: 'GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE.SUCCESS',
      }
    }
    case GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE.FAILURE',
      }
    case GET_REQUEST_EDIT_LOGS.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_REQUEST_EDIT_LOGS.REQUEST',
      }
    case GET_REQUEST_EDIT_LOGS.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        requestEditLogs: data,
        page: meta.pagination.page,
        limit: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
        state: 'GET_REQUEST_EDIT_LOGS.SUCCESS',
      }
    }
    case GET_REQUEST_EDIT_LOGS.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_REQUEST_EDIT_LOGS.FAILURE',
      }
    case GET_ARCHIVED_REQUEST.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_ARCHIVED_REQUEST.REQUEST',
      }
    case GET_ARCHIVED_REQUEST.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        archivedRequest: data,
        page: meta.pagination.page,
        limit: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
        state: 'GET_ARCHIVED_REQUEST.SUCCESS',
      }
    }
    case GET_ARCHIVED_REQUEST.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_ARCHIVED_REQUEST.FAILURE',
      }
    case GET_REQUEST_EDIT_LOG_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_REQUEST_EDIT_LOG_BY_ID.REQUEST',
      }
    case GET_REQUEST_EDIT_LOG_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        employeeDashboardInfo: data,
        state: 'GET_REQUEST_EDIT_LOG_BY_ID.SUCCESS',
      }
    }
    case GET_REQUEST_EDIT_LOG_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_REQUEST_EDIT_LOG_BY_ID.FAILURE',
      }
    case REQUEST_EDIT_EMPLOYEE_PROFILE_INFO.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'REQUEST_EDIT_EMPLOYEE_PROFILE_INFO.REQUEST',
      }
    }
    case REQUEST_EDIT_EMPLOYEE_PROFILE_INFO.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'REQUEST_EDIT_EMPLOYEE_PROFILE_INFO.SUCCESS',
      }
    }
    case REQUEST_EDIT_EMPLOYEE_PROFILE_INFO.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'REQUEST_EDIT_EMPLOYEE_PROFILE_INFO.FAILURE',
      }
    }
    case EDIT_EMPLOYEE_PROFILE_INFO.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'EDIT_EMPLOYEE_PROFILE_INFO.REQUEST',
      }
    }
    case EDIT_EMPLOYEE_PROFILE_INFO.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_EMPLOYEE_PROFILE_INFO.SUCCESS',
      }
    }
    case EDIT_EMPLOYEE_PROFILE_INFO.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_EMPLOYEE_PROFILE_INFO.FAILURE',
      }
    }
    case APPROVE_REQUEST_EDIT.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'APPROVE_REQUEST_EDIT.REQUEST',
      }
    }
    case APPROVE_REQUEST_EDIT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'APPROVE_REQUEST_EDIT.SUCCESS',
      }
    }
    case APPROVE_REQUEST_EDIT.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'APPROVE_REQUEST_EDIT.FAILURE',
      }
    }
    case CHANGE_PASSWORD.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'CHANGE_PASSWORD.REQUEST',
      }
    }
    case CHANGE_PASSWORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CHANGE_PASSWORD.SUCCESS',
      }
    }
    case CHANGE_PASSWORD.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'CHANGE_PASSWORD.FAILURE',
      }
    }
    case VERIFY_PASSWORD.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'VERIFY_PASSWORD.REQUEST',
      }
    }
    case VERIFY_PASSWORD.SUCCESS: {
      const { response } = action
      return {
        ...state,
        isLoading: false,
        state: 'VERIFY_PASSWORD.SUCCESS',
        response,
      }
    }
    case VERIFY_PASSWORD.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'VERIFY_PASSWORD.FAILURE',
      }
    }
    case GET_EMPLOYEE_SIGNATURE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_EMPLOYEE_SIGNATURE.REQUEST',
      }
    }
    case GET_EMPLOYEE_SIGNATURE.SUCCESS: {
      const { data } = action
      return {
        ...state,
        employeeSignature: data,
        isLoading: false,
        state: 'GET_EMPLOYEE_SIGNATURE.SUCCESS',
      }
    }
    case GET_EMPLOYEE_SIGNATURE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_EMPLOYEE_SIGNATURE.FAILURE',
      }
    }
    case UPLOAD_SIGNATURE_IMAGE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'UPLOAD_SIGNATURE_IMAGE.REQUEST',
      }
    }
    case UPLOAD_SIGNATURE_IMAGE.SUCCESS: {
      const { response } = action
      return {
        ...state,
        response: response,
        isLoading: false,
        state: 'UPLOAD_SIGNATURE_IMAGE.SUCCESS',
      }
    }
    case UPLOAD_SIGNATURE_IMAGE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'UPLOAD_SIGNATURE_IMAGE.FAILURE',
      }
    }
    case UPLOAD_EMPLOYEE_SIGNATURE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'UPLOAD_EMPLOYEE_SIGNATURE.REQUEST',
      }
    }
    case UPLOAD_EMPLOYEE_SIGNATURE.SUCCESS: {
      const { response } = action
      return {
        ...state,
        response,
        isLoading: false,
        state: 'UPLOAD_EMPLOYEE_SIGNATURE.SUCCESS',
      }
    }
    case UPLOAD_EMPLOYEE_SIGNATURE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'UPLOAD_EMPLOYEE_SIGNATURE.FAILURE',
      }
    }
    case CLEAR_STATE_EMPLOYEE_PROFILE: {
      return {
        ...state,
        state: '',
      }
    }
    case CLEAR_RESPONSE_UPLOAD_SIGNATURE: {
      return {
        ...state,
        response: {},
      }
    }
    default:
      return state
  }
}

export default combineReducers({
  employeeProfileReducer,
})
