import { combineReducers } from 'redux'
import {
  GET_EMPLOYEE,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  GET_EMPLOYEE_BY_ID,
  UPDATE_EMPLOYEE_STATUS,
} from '../actionTypes'

const initialState = {
  employeeList: [],
  employeeInfo: {},
  total: 0,
  skip: 0,
  limit: 10,
  isLoading: false,
}

const employeeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_EMPLOYEE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_EMPLOYEE.SUCCESS: {
      const { data, meta, filter } = action
      
      return {
        ...state,
        employeeList: data,
        total: meta.pagination.total,
        skip: filter.skip,
        limit: filter.limit,
        isLoading: false,
      }
    }
    case GET_EMPLOYEE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_EMPLOYEE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_EMPLOYEE.SUCCESS:
      const { data_result } = action
      //
      //
      return {
        ...state,
        employeeInfo: data_result,
        isLoading: false,
      }
    case CREATE_EMPLOYEE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case UPDATE_EMPLOYEE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_EMPLOYEE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_EMPLOYEE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case GET_EMPLOYEE_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_EMPLOYEE_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        employeeInfo: data,
        isLoading: false,
      }
    }
    case GET_EMPLOYEE_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case UPDATE_EMPLOYEE_STATUS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_EMPLOYEE_STATUS.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_EMPLOYEE_STATUS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  employeeReducer,
})
