import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import failIcon from '../../assets/images/fail-icon.png'
import iconClose from '../../assets/images/icon-close.svg'
import Date from '../../components/form/Date'
import TextField from '../../components/form/TextField'
import {
  addTaskToProjectTask,
  getMilestoneByProjectId,
  getMilestoneScopeByMilestoneId,
  getMilestoneTaskTypeByMilestoneTaskScopeId,
  getProjectListDropdown,
  getTaskTypeByTaskScope,
} from '../../redux/actions/projectTask'
import { createTask, deleteDocument, editTask, getEmployee, getTaskById } from '../../redux/actions/summaryTask'
import { selectProjectTask, selectSummaryTask } from '../../redux/selectors'
import { getAdminUser } from '../../utils/common'
import SidePanel from '../common/SidePanel'
import Button from '../form/button/Button'
import ButtonOutline from '../form/button/ButtonOutline'
import SearchSuggestEmployee from '../widget/combo/SearchSuggestEmployee'
import DropdownSelect from '../widget/element/DropDownSelect'
import TextEditor from '../widget/element/TextEditor'
import DialogFail from './DialogFail'

import { useTranslation } from 'react-i18next'
import successIcon from '../../assets/images/success-icon-2.png'
import MediaFile from '../../containers/cms/MediaFile'
import { dlog, generateIconFileByType, removeArrayItemByIndex } from '../../utils/helper'
import IconXClose from '../widget/icon/IconXClose'
import DialogConfirmDelete from './DialogConfirmDelete'
import DialogSuccess from './DialogSuccess'

const DialogTaskEditor = ({ taskId, projectId, open, onClose, type, onSuccess }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = getAdminUser()
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { taskInfo, employeeList, total, pageSize, page, pageCount } = useSelector(selectSummaryTask)
  const {
    projectList,
    milestoneList,
    milestoneScopeList,
    taskTypeDataList,
    milestoneTaskTypeList,
    projectListDropdown,
  } = useSelector(selectProjectTask)

  const allowTypes = ['images', 'files', 'videos', 'audios', 'appleFiles']
  const [fileList, setFileList] = useState([])
  const [oldFileList, setOldFileList] = useState([])

  const [isPreview, setIsPreview] = useState(false)
  const [isShowNameEditor, setIsShowNameEditor] = useState(false)
  const [isGetEmployee, setIsGetEmployee] = useState(false)
  const [isSetBindingData, setIsSetBindingData] = useState(false)
  const [textDetail, setTextDetail] = useState('')
  const [searchName, setSearchName] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [isShowPersonPopup, setIsShowPersonPopup] = useState(false)
  const [employeeSelected, setEmployeeSelected] = useState()
  const [assignEmployeeList, setAssignEmployeeList] = useState([])
  const [assignTextUser, setAssignTextUser] = useState('')
  const [isSelectedForMilestone, setIsSelectedForMilestone] = useState(false)
  const [isSelectedForTaskScope, setIsSelectedForTaskScope] = useState(false)
  // const [isSelectedProject, setIsSelectedProject] = useState(false)
  const [isSelectedScope, setIsSelectedScope] = useState(false)

  // Validation
  const [isErrorTaskName, setIsErrorTaskName] = useState(false)
  const [isErrorAssignUser, setIsErrorAssignUser] = useState(false)
  const [isErrorStartDate, setIsErrorStartDate] = useState(false)
  const [isErrorDueDate, setIsErrorDueDate] = useState(false)
  const [isErrorTaskScope, setIsErrorTaskScope] = useState(false)
  const [isErrorTaskType, setIsErrorTaskType] = useState(false)
  const [isErrorTimeFrame, setIsErrorTimeFrame] = useState(false)
  const [isErrorValue, setIsErrorValue] = useState(false)
  const [isErrorMilestone, setIsErrorMilestone] = useState(false)
  const [isGetProjectTask, setIsGetProjectTask] = useState(false)

  const [isShowModalPreview, setIsShowModalPreview] = useState(false)
  const [isShowFailedModal, setIsShowFailedModal] = useState(false)

  const [oldTaskName, setOldTaskName] = useState('')
  const [oldStartDate, setOldStartDate] = useState('')
  const [oldTimeFrame, setOldTimeFrame] = useState('')
  const [currentDocId, setCurrentDocId] = useState('')
  const [isShowDeleteConfirm, setIsShowDeleteConfirm] = useState(false)
  const [isShowModalSuccessDelDoc, setIsShowModalSuccessDelDoc] = useState(false)

  const tmpData = {
    task_name: '',
    priority: 'Medium',
    start_date: '',
    due_date: '',
    status: 'Open',
    task_scope: '',
    task_type: '',
    assign_to: '',
    description: '',
    project_task: '',
    // owner: user?.id, // id ของ user
    owner: employee_info?.id || -99, // id ของ user
    type: 'routine', // routine or project
    time_frame: '',
    value: '',
    company_id: company_info?.id,
    files: [],
  }

  const tmpProjectData = {
    milestone_scope_work: '', // id of milestone scope work
    task_type: '', // id of task type
    assign_to: '',
    cc: '',
    value: '',
    due_date: '',
    detail: '',
    // created_by: user?.id,
    created_by: employee_info?.id || -99,
    company_id: company_info?.id,
    // files: [],
  }

  const [taskData, setTaskData] = useState(tmpData)
  const [taskProjectData, setTaskProjectData] = useState(tmpProjectData)
  const [currentProjectType, setCurrentProjectType] = useState('routine')
  const [projectTypeSelected, setProjectTypeSelected] = useState('none')
  const [milestoneSelected, setMilestoneSelected] = useState('')

  const [projectPriorityList, setProjectPriorityList] = useState([])
  const [milestoneDropdownList, setMilestoneDropdownList] = useState([])
  const [scopeList, setScopeList] = useState([
    {
      text: 'กรุณาเลือก Scope',
      value: '',
    },
  ])
  const [taskTypeList, setTaskTypeList] = useState([
    {
      text: 'กรุณาเลือก Task Type',
      value: '',
    },
  ])
  const [isSetNewTaskInProject, setIsSetNewTaskInProject] = useState(false)
  const [detailText, setDetailText] = useState('')
  const [isSetDetailText, setIsSetDetailText] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (!isGetProjectTask) {
        await dispatch(getProjectListDropdown()).then(() => {
          setIsGetProjectTask(true)
        })
      }
    })()
  }, [dispatch])

  useEffect(() => {
    if (projectListDropdown?.length > 0) {
      let tmpList = []
      let tmpNone = {
        text: 'None',
        value: 'none',
      }
      tmpList.push(tmpNone)
      for (let i = 0; i < projectListDropdown.length; i++) {
        let tmpObj = {
          text: projectListDropdown[i].name,
          value: projectListDropdown[i].id,
        }
        tmpList.push(tmpObj)
      }
      setProjectPriorityList([...tmpList])
    }
  }, [projectListDropdown, isGetProjectTask])

  // useEffect(() => {
  //   ;(async () => {
  //     const pagination = {
  //       page: 1,
  //       pageSize: 100,
  //       pageCount: 0,
  //       total: 0,
  //     }
  //     if (!isGetProjectTask) {
  //       await dispatch(getProjectTask(pagination)).then(() => {
  //         setIsGetProjectTask(true)
  //       })
  //     }
  //   })()
  // }, [dispatch])

  // useEffect(() => {
  //   if (projectList?.length > 0) {
  //     let tmpList = []
  //     let tmpNone = {
  //       text: 'None',
  //       value: 'none',
  //     }
  //     tmpList.push(tmpNone)
  //     for (let i = 0; i < projectList.length; i++) {
  //       let tmpObj = {
  //         text: projectList[i].name,
  //         value: projectList[i].id,
  //       }
  //       tmpList.push(tmpObj)
  //     }
  //     setProjectPriorityList([...tmpList])
  //   }
  // }, [projectList, isGetProjectTask])

  useEffect(() => {
    if (milestoneScopeList?.length > 0 && isSelectedForTaskScope) {
      let tmpList = []
      // let tmpDefaultObj = {
      //   text: 'กรุณาเลือก Scope',
      //   value: '',
      // }
      // tmpList.push(tmpDefaultObj)
      if (currentProjectType == 'routine') {
        for (let i = 0; i < milestoneScopeList.length; i++) {
          let tmpTaskScope = milestoneScopeList[i]?.task_scope
          let tmpObj = {
            text: tmpTaskScope?.name,
            value: tmpTaskScope?.id,
          }
          tmpList.push(tmpObj)
        }
      } else {
        // PROJECT
        for (let i = 0; i < milestoneScopeList.length; i++) {
          let tmpTaskScope = milestoneScopeList[i]?.task_scope
          let tmpObj = {
            text: tmpTaskScope?.name,
            value: milestoneScopeList[i]?.id,
          }
          tmpList.push(tmpObj)
        }
      }
      setScopeList([...tmpList])
    }
  }, [milestoneScopeList])

  useEffect(() => {
    if (milestoneList?.length > 0 && isSelectedForMilestone) {
      // dlog(milestoneScopeList.length, 'milestoneScopeList Length')
      let tmpList = []
      let tmpDefaultObj = {
        text: 'กรุณาเลือก Milestone',
        value: '',
      }
      tmpList.push(tmpDefaultObj)
      for (let i = 0; i < milestoneList.length; i++) {
        let tmpObj = {
          text: milestoneList[i]?.name,
          value: milestoneList[i]?.id,
        }
        tmpList.push(tmpObj)
      }
      setMilestoneDropdownList([...tmpList])
    }
  }, [milestoneList])

  useEffect(() => {
    let tmpList = []
    if (currentProjectType == 'routine') {
      if (taskTypeDataList?.length > 0 && isSelectedScope) {
        // dlog(taskTypeDataList.length, "taskTypeDataList Length")
        // let tmpDefaultObj = {
        //   text: 'กรุณาเลือก Task Type',
        //   value: '',
        // }
        // tmpList.push(tmpDefaultObj)
        for (let i = 0; i < taskTypeDataList.length; i++) {
          let tmpObj = {
            text: taskTypeDataList[i]?.name,
            value: taskTypeDataList[i].id,
          }
          tmpList.push(tmpObj)
        }
      }
      setTaskTypeList([...tmpList])
    } else {
      if (milestoneTaskTypeList?.length > 0 && isSelectedScope) {
        // dlog(milestoneTaskTypeList, 'milestoneTaskTypeList Length')
        for (let i = 0; i < milestoneTaskTypeList.length; i++) {
          let tmpTaskType = milestoneTaskTypeList[i]?.task_type
          let tmpObj = {
            text: tmpTaskType?.name,
            value: tmpTaskType?.id,
          }
          tmpList.push(tmpObj)
        }
      }
      let tmpArrayList = tmpList.map(({ value }) => value)
      let filteredArray = tmpList.filter(({ value }, index) => !tmpArrayList.includes(value, index + 1))
      // dlog(filteredArray, 'filteredArray')
      setTaskTypeList([...filteredArray])
    }
  }, [taskTypeDataList, milestoneTaskTypeList])

  useEffect(() => {
    ;(async () => {
      // const pagination = {
      //   page: page,
      //   pageSize: searchFilter != '' ? pageSize : 100,
      //   pageCount: pageCount,
      //   total: total,
      // }
      const pagination = {
        page: 1,
        pageSize: 1000,
      }
      if (!isGetEmployee) {
        // dlog(searchFilter, 'searchFilter')
        dispatch(getEmployee(searchFilter, pagination))
        setIsGetEmployee(true)
      }
    })()
  }, [dispatch, searchFilter])

  useEffect(() => {
    if (employeeList?.length > 0) {
      // dlog(employeeList, 'employeeList')
      setAssignEmployeeList([...employeeList])
    }
  }, [employeeList])

  useEffect(() => {
    ;(async () => {
      if (taskId && type == 'edit_task' && employeeList?.length > 0) {
        // getTaskData(taskId)
        await dispatch(getTaskById(taskId))
      }
    })()
  }, [employeeList])

  useEffect(() => {
    ;(async () => {
      // EDIT TASK
      if (taskInfo && type == 'edit_task' && assignEmployeeList != null && assignEmployeeList.length > 0) {
        dlog(taskInfo, 'taskInfo in EDITOR')
        let tmpTaskInfo = { ...taskInfo }
        const tmpData = {
          task_name: tmpTaskInfo.task_name,
          priority: tmpTaskInfo.priority,
          start_date: tmpTaskInfo.start_date,
          due_date: tmpTaskInfo.due_date,
          status: tmpTaskInfo.status,
          task_scope: tmpTaskInfo?.task_scope?.data?.id,
          task_type: tmpTaskInfo?.task_type?.data?.id,
          assign_to: tmpTaskInfo?.assign_to?.data?.id,
          description: tmpTaskInfo.description,
          project_task: tmpTaskInfo.project_task || 1,
          type: tmpTaskInfo.type,
          time_frame: tmpTaskInfo.time_frame,
          value: tmpTaskInfo.value,

          owner: employee_info?.id || -99,
          company_id: company_info?.id,
        }

        if (!isSetDetailText) {
          // dlog(tmpTaskInfo.description, 'DESC')
          setIsSetDetailText(true)
        }

        let tmpAssignTo = tmpData.assign_to
        let tmpTaskScope = tmpData.task_scope
        let tmpTaskType = tmpData.task_type
        if (!isSetBindingData) {
          let tmpAssignEmployeeList = [...assignEmployeeList]
          let tmpAssignUser = ''
          let tmpScopeList = []
          let tmpTaskTypeList = []

          for (let i = 0; i < tmpAssignEmployeeList.length; i++) {
            if (tmpAssignEmployeeList[i].id == tmpAssignTo) {
              tmpAssignUser = tmpAssignEmployeeList[i].first_name + ' ' + tmpAssignEmployeeList[i].last_name
              // dlog(tmpData.type, "tmpData_type")
              if (tmpData.type == 'routine') {
                tmpScopeList = tmpAssignEmployeeList[i]?.task_scopes
                // tmpTaskTypeList = tmpAssignEmployeeList[i]?.task_types[tmpTaskScope]?.tasks || [];
                await dispatch(getTaskTypeByTaskScope(tmpTaskScope))
              }
            }
          }
          if (tmpData.type == 'project') {
            if (projectList != null && projectList.length > 0) {
              let tmpList = []
              for (let i = 0; i < projectList.length; i++) {
                let tmpObj = {
                  text: projectList[i].name,
                  value: projectList[i].id,
                }
                tmpList.push(tmpObj)
              }
              let tmpNone = {
                text: 'None',
                value: 'none',
              }
              tmpList.push(tmpNone)
              setProjectPriorityList([...tmpList])
              //Scope -----
              // dispatch(getMilestoneScopeByProjectId(tmpData.project_task)) // ต้องเปลี่ยนเป็น getMilestoneScopeByMilstoneId แบบใหม่
              //TaskType -----
              // dispatch(getTaskTypeByTaskScope(tmpData.task_scope))
            }
          }

          if (tmpData.type == 'routine') {
            let scopeDropdownList = []
            if (tmpScopeList?.length > 0) {
              scopeDropdownList.push(scopeList[0])
              for (let i = 0; i < tmpScopeList.length; i++) {
                let tmpObj = {
                  text: tmpScopeList[i].attributes.name,
                  value: tmpScopeList[i].id,
                }
                scopeDropdownList.push(tmpObj)
              }
            }

            let taskTypeDropdownList = []
            if (tmpTaskTypeList?.length > 0) {
              taskTypeDropdownList.push(taskTypeList[0])
              for (let i = 0; i < tmpTaskTypeList.length; i++) {
                let tmpObj = {
                  text: tmpTaskTypeList[i].attributes.name,
                  value: tmpTaskTypeList[i].id,
                }
                taskTypeDropdownList.push(tmpObj)
              }
            }

            // SET STATE
            setScopeList([...scopeDropdownList]) // Set Dropdown Scope
            setTaskTypeList([...taskTypeDropdownList]) // Set Dropdown Task_type
          }

          setIsSelectedForTaskScope(true)
          setIsSelectedScope(true)
          setAssignTextUser(tmpAssignUser)
          setIsSetBindingData(true)
        }

        if (tmpData.type == 'routine') {
          let tmpFileList = []
          if (tmpTaskInfo?.procurement_templates && tmpTaskInfo?.procurement_templates.length > 0) {
            tmpFileList = [...tmpTaskInfo.procurement_templates]
          }
          let mainFileList = []
          if (tmpFileList.length > 0) {
            for (let index = 0; index < tmpFileList.length; index++) {
              mainFileList.push(tmpFileList[index]?.file_detail)
            }
          }
          setOldFileList([...tmpFileList])
          tmpData.files = [...mainFileList]
          setDetailText(tmpTaskInfo?.description)
          setTaskData(tmpData)
        } else {
          // setDetailText(JSON.stringify(tmpTaskInfo?.detail))
          setTaskProjectData(tmpData)
        }
      }
    })()
  }, [taskInfo, type, assignEmployeeList, scopeList, taskTypeList, isSetBindingData, projectList])

  useEffect(() => {
    ;(async () => {
      // NEW_TASK_IN_PROJECT
      if (type == 'new_task_in_project' && projectId) {
        let tmpProjectTaskData = {
          milestone_scope_work: '', // id of milestone scope work
          task_type: '', // id of task type
          assign_to: '',
          cc: '',
          value: '',
          due_date: '',
          detail: '',
          created_by: employee_info?.id || -99, // user?.id,
          company_id: company_info?.id,
        }
        if (!isSetNewTaskInProject) {
          setTaskData(tmpProjectTaskData)
          setTaskProjectData(tmpProjectTaskData)
        }

        if (projectList.length > 0) {
          for (let i = 0; i < projectList.length; i++) {
            if (projectList[i].id == projectId) {
              setCurrentProjectType('project')
              setProjectTypeSelected(projectId)
              let filters = {
                page: 1,
                pageSize: 100,
              }
              await dispatch(getMilestoneByProjectId(projectId, filters))
              setIsSelectedForMilestone(true)
              setIsSetNewTaskInProject(true)
            }
          }
        }
      }
    })()
  }, [type, assignEmployeeList, scopeList, taskTypeList, isSetBindingData, projectList])

  const onChangeStartDate = (value) => {
    setOldStartDate(value)
    setTaskData({ ...taskData, start_date: value })
  }
  const onChangeDueDate = (value) => {
    if (currentProjectType == 'routine') {
      setTaskData({ ...taskData, due_date: value })
    } else {
      setTaskProjectData({ ...taskProjectData, due_date: value })
    }
  }

  const onChangeEditTaskName = (event) => {
    setOldTaskName(event.target.value)
    setTaskData({ ...taskData, task_name: event.target.value })
  }

  const handleCloseModal = () => {
    if (onClose) {
      // clear file list
      setTaskData(tmpData)
      onClose()
    }
  }
  const handleTimeFrameChange = (e) => {
    setOldTimeFrame(e.target.value)
    if (currentProjectType == 'routine') {
      setTaskData({ ...taskData, time_frame: e.target.value })
    } else {
      setTaskProjectData({ ...taskProjectData, time_frame: e.target.value })
    }
  }
  const handleValueChange = (e) => {
    if (currentProjectType == 'routine') {
      setTaskData({ ...taskData, value: e.target.value })
    } else {
      setTaskProjectData({ ...taskProjectData, value: e.target.value })
    }
  }
  // DROPDOWN
  const handleProjectSelected = async (value) => {
    // setTaskData({ ...taskData, projectSelected: value })
    let tmpData
    if (currentProjectType == 'routine') {
      tmpData = { ...taskData }
    } else {
      tmpData = { ...taskProjectData }
    }

    if (value == 'none') {
      // dlog("NO_PROJECT")
      let tmpTaskRoutineData = {
        task_name: oldTaskName,
        priority: 'Medium',
        start_date: tmpData?.start_date || oldStartDate,
        due_date: tmpData?.due_date || '',
        status: 'Open',
        task_scope: '',
        task_type: '',
        assign_to: tmpData?.assign_to || '',
        description: '',
        project_task: '',
        owner: employee_info?.id || -99, // Phrase 2
        type: 'routine', // routine or project
        time_frame: tmpData?.time_frame || oldTimeFrame,
        value: tmpData?.value || '',
        company_id: company_info?.id,
      }

      tmpTaskRoutineData.project_task = ''
      tmpTaskRoutineData.type = 'routine'

      setCurrentProjectType('routine')
      setProjectTypeSelected(value)
      setMilestoneSelected('')

      // ----- CHECK ว่ามี ASSIGN_TO ไหม ------
      if (tmpTaskRoutineData.assign_to == '') {
        // NO_SIIGN_TO
        // dlog("NO_ASSIGN_TO")
        tmpTaskRoutineData.task_scope = ''
        tmpTaskRoutineData.task_type = ''

        setIsSelectedForTaskScope(false)
        setIsSelectedScope(false)
      } else {
        // HAVE_ASSIGN_TO
        // dlog("HAVE_ASSIGN_TO")

        // setIsSelectedForTaskScope(false) // disable scope dropdown
        if (employeeSelected) {
          let tmpEmployeeData = employeeSelected
          let tmpScopeList = tmpEmployeeData?.task_scopes // ARRAY
          if (tmpScopeList.length > 0) {
            let taskScopeList = []
            for (let i = 0; i < tmpScopeList.length; i++) {
              let tmpObj = {
                text: tmpScopeList[i].attributes.name,
                value: tmpScopeList[i].id,
              }
              taskScopeList.push(tmpObj)
            }
            setScopeList([...taskScopeList])
            setIsSelectedForTaskScope(true)
          }
        }
      }
      setTaskData(tmpTaskRoutineData) // SET_DATA
    } else {
      // TASK TO PROJECT
      // dlog("HAVE_PROJECT")
      let tmpProjectTaskData = {
        milestone_scope_work: '', // id of milestone scope work
        task_type: '', // id of task type
        assign_to: tmpData.assign_to || '',
        cc: '',
        value: tmpData.value || '',
        due_date: tmpData.due_date || '',
        detail: tmpData.description || '',
        created_by: employee_info?.id || -99, // user?.id,
        company_id: company_info?.id,
      }
      // let tmpNewData = tmpProjectTaskData // เปลี่ยน tmpData ให้เป็นของ Project

      let filters = {
        page: 1,
        pageSize: 100,
      }
      dispatch(getMilestoneByProjectId(value, filters))
      setProjectTypeSelected(value)
      setIsSelectedForMilestone(true)
      setIsSelectedForTaskScope(false)
      setIsSelectedScope(false)
      // setTaskData(tmpProjectTaskData)
      setTaskProjectData(tmpProjectTaskData)
      setCurrentProjectType('project')
    }
  }

  const handleMilestoneSelected = async (value) => {
    // dlog(value, "SELECT_MILESTONE")
    setMilestoneSelected(value)
    dispatch(getMilestoneScopeByMilestoneId(value))
    setIsSelectedForTaskScope(true)
  }

  const handleScopeSelected = async (value) => {
    if (currentProjectType == 'routine') {
      let tmptaskData = { ...taskData }
      tmptaskData.task_scope = value
      await dispatch(getTaskTypeByTaskScope(value))
      setTaskData(tmptaskData)
    } else {
      // currentProjectType == PROJECT
      let tmpTaskProjectData = { ...taskProjectData }
      // dlog(value, "SELECT_SCOPE_IN_PROJECT")
      tmpTaskProjectData.milestone_scope_work = value
      await dispatch(getMilestoneTaskTypeByMilestoneTaskScopeId(value))
      setTaskProjectData(tmpTaskProjectData)
    }
    setIsSelectedScope(true)
  }
  const handleTaskTypeSelected = (value) => {
    if (currentProjectType == 'routine') {
      setTaskData({ ...taskData, task_type: parseInt(value) })
    } else {
      // Project
      // dlog(value, 'SELECT_TASK_TYPE_IN_PROJECT')
      setTaskProjectData({ ...taskProjectData, task_type: parseInt(value) })
    }
  }

  const handleClickPublish = async () => {
    if (Object.keys(employee_info).length > 0) {
      let isValidInputs = validateInputs()
      if (isValidInputs) {
        let tmpFileList = []

        if (fileList?.length > 0) {
          for (let index = 0; index < fileList.length; index++) {
            tmpFileList.push(fileList[index].id)
          }
        }

        if (type == 'new_task' || type == 'new_task_in_project') {
          if (currentProjectType == 'routine') {
            // ROUTINE
            const data = { ...taskData }
            data.files = tmpFileList
            const request = { data }

            await dispatch(createTask(request)).then(() => {
              if (onSuccess) {
                onSuccess()
              }
            })
          } else {
            // PROJECT
            const data = { ...taskProjectData }
            // data.files = tmpFileList //แนบ upload file
            delete data['time_frame']
            delete data['start_date']
            delete data['task_scope'] // NEW_TEST
            const request = { data }
            // dlog(request, 'request_BEFORE_CREATE_PROJECT')
            await dispatch(addTaskToProjectTask(request)).then(() => {
              if (onSuccess) {
                onSuccess()
              }
            })
          }
        } else {
          // Edit Task
          const data = { ...taskData }
          data.files = tmpFileList
          delete data['status']
          const request = { data }
          await dispatch(editTask(taskId, request)).then(() => {
            if (onSuccess) {
              onSuccess()
            }
          })
        }
      } else {
        alert('Please Enter your info')
      }
    } else {
      setIsShowFailedModal(true)
    }
  }
  const validateInputs = () => {
    let tmpTaskData
    if (currentProjectType == 'routine') {
      tmpTaskData = { ...taskData }
    } else {
      tmpTaskData = { ...taskProjectData }
    }

    // dlog(tmpTaskData, "VALIDATE_DATA_NOW")
    let isValidTaskName = false
    let isValidAssignUser = false
    let isValidStartDate = false
    let isValidDueDate = false
    let isValidTaskScope = false
    let isValidTaskType = false
    let isValidTimeFrame = false
    let isValidValue = false
    let isValidMilestone = false

    // dlog(tmpTaskData, "tmpTaskData")

    if (tmpTaskData?.task_name != '') {
      isValidTaskName = true
      setIsErrorTaskName(false)
    } else {
      isValidTaskName = false
      setIsErrorTaskName(true)
      setIsShowNameEditor(true)
    }

    if (tmpTaskData?.assign_to != '') {
      isValidAssignUser = true
      setIsErrorAssignUser(false)
    } else {
      isValidAssignUser = false
      setIsErrorAssignUser(true)
    }

    if (tmpTaskData?.start_date != '') {
      isValidStartDate = true
      setIsErrorStartDate(false)
    } else {
      isValidStartDate = false
      setIsErrorStartDate(true)
    }

    if (tmpTaskData?.due_date != '') {
      isValidDueDate = true
      setIsErrorDueDate(false)
    } else {
      isValidDueDate = false
      setIsErrorDueDate(true)
    }

    if (currentProjectType == 'routine') {
      if (tmpTaskData?.task_scope) {
        isValidTaskScope = true
        setIsErrorTaskScope(false)
      } else {
        isValidTaskScope = false
        setIsErrorTaskScope(true)
      }
    } else {
      if (tmpTaskData?.milestone_scope_work) {
        isValidTaskScope = true
        setIsErrorTaskScope(false)
      } else {
        isValidTaskScope = false
        setIsErrorTaskScope(true)
      }
    }

    if (tmpTaskData?.task_type != '') {
      isValidTaskType = true
      setIsErrorTaskType(false)
    } else {
      isValidTaskType = false
      setIsErrorTaskType(true)
    }

    if (tmpTaskData?.time_frame != '') {
      isValidTimeFrame = true
      setIsErrorTimeFrame(false)
    } else {
      isValidTimeFrame = false
      setIsErrorTimeFrame(true)
    }

    if (tmpTaskData?.value != '') {
      isValidValue = true
      setIsErrorValue(false)
    } else {
      isValidValue = false
      setIsErrorValue(true)
    }

    if (currentProjectType == 'project') {
      if (milestoneSelected != '') {
        isValidMilestone = true
        setIsErrorMilestone(false)
      } else {
        isValidMilestone = false
        setIsErrorMilestone(true)
      }
    }

    if (currentProjectType == 'routine') {
      if (
        isValidTaskName &&
        isValidAssignUser &&
        isValidStartDate &&
        isValidDueDate &&
        isValidTaskScope &&
        isValidTaskType &&
        isValidTimeFrame &&
        isValidValue
      ) {
        return true
      }
      return false
    } else {
      if (
        isValidAssignUser &&
        isValidDueDate &&
        // isValidTaskScope &&
        isValidTaskType &&
        // isValidTimeFrame &&
        isValidValue &&
        isValidMilestone
      ) {
        return true
      }
      return false
    }
  }

  const handleDetailChange = (val) => {
    // dlog(val, "VAL_TEXT_EDITOR")
    let tmpDelta = val.delta
    let tmpData
    setDetailText(JSON.stringify(tmpDelta))
    if (currentProjectType == 'routine') {
      tmpData = { ...taskData }
      tmpData.description = JSON.stringify(tmpDelta)
      setTaskData(tmpData)
    } else {
      tmpData = { ...taskProjectData }
      tmpData.detail = JSON.stringify(tmpDelta)
      setTaskProjectData(tmpData)
    }
  }

  const handleClickSelectEmployee = (employeeData) => {
    // setData to dropdown
    if (employeeData) {
      let tmpData
      if (currentProjectType == 'routine') {
        tmpData = { ...taskData }
      } else {
        tmpData = { ...taskProjectData }
      }
      tmpData.assign_to = employeeData.id

      if (currentProjectType == 'routine') {
        tmpData.task_scope = ''
        tmpData.task_type = ''
        setTaskData(tmpData)
      } else {
        setTaskProjectData(tmpData)
      }

      let tmpAssignUser = ''
      tmpAssignUser = employeeData?.first_name + ' ' + employeeData?.last_name
      if (tmpData.type == 'routine') {
        // dlog("EMPLOYEE_ROUTINE")
        let tmpScopeList = employeeData?.task_scopes // ARRAY
        if (tmpScopeList.length > 0) {
          let taskScopeList = []
          let tmpDefaultObj = {
            text: 'กรุณาเลือก Scope',
            value: '',
          }
          taskScopeList.push(tmpDefaultObj)
          for (let i = 0; i < tmpScopeList.length; i++) {
            let tmpObj = {
              text: tmpScopeList[i].attributes.name,
              value: tmpScopeList[i].id,
            }
            taskScopeList.push(tmpObj)
          }
          setScopeList([...taskScopeList])
        }
      }
      setEmployeeSelected(employeeData)
      setAssignTextUser(tmpAssignUser)
      setIsErrorAssignUser(false)

      if (currentProjectType == 'routine') {
        setIsSelectedScope(false)
        setIsSelectedForTaskScope(true)
      } else {
        if (tmpData.milestone_scope_work == '') {
          if (isSelectedForTaskScope) {
            setIsSelectedForTaskScope(true) // dis SCOPE
          } else {
            setIsSelectedForTaskScope(false) // dis SCOPE
          }
          setIsSelectedScope(false) // dis TASK_TYPE
        } else {
          setIsSelectedForTaskScope(true)
        }
      }
    }
    setIsShowPersonPopup(!isShowPersonPopup)
  }

  const convertIntComma = (val) => {
    if (val) {
      let tmp = (Number(val.replace(/\D/g, '')) || '').toLocaleString()
      return tmp
    }
  }

  const onChangeUploadFile = (value) => {
    console.log('onChangeUploadFile: ' + JSON.stringify(value, null, 2))

    setFileList([...fileList, ...value])
  }

  const handleDeleteItemUploaded = (index) => {
    if (fileList.length > 0) {
      let tmpFileList = [...fileList]
      let tmpList = removeArrayItemByIndex(tmpFileList, index)
      setFileList([...tmpList])
    }
  }

  const handleClickDeleteFileItem = (docId) => {
    setCurrentDocId(docId)
    setIsShowDeleteConfirm(true)
  }

  const handleClickOpenFile = (url) => {
    window.open(url)
  }

  const handleSubmitDeleteDoc = () => {
    dispatch(deleteDocument(currentDocId, true)).then(() => {
      // setIsDataUpdated(!isDataUpdated)
      let tmpOldFileList = [...oldFileList]
      let tmpArr = tmpOldFileList.filter((obj) => obj.id !== currentDocId)
      setOldFileList([...tmpArr])
      setIsShowDeleteConfirm(false)
      setIsShowModalSuccessDelDoc(true)
    })
  }

  return (
    <SidePanel isOpen={open} onClose={handleCloseModal} width={900}>
      <Div>
        <div className="dialog-content">
          {!isPreview && taskData && (
            <div className="modal-container">
              <div className="wrp-modal-header">
                <div className="modal-header">{type == 'edit_task' ? t('edit_task') : t('new_task')}</div>
                <div className="btn-close" onClick={handleCloseModal}>
                  <img src={iconClose} />
                </div>
              </div>

              <div className="wrp-content">
                <div className="wrp-inputs-container">
                  <div className="wrp-task-name-editor">
                    <div className="title-filed">{t('task_name')}</div>

                    <TextField
                      className="task-name"
                      value={taskData?.task_name}
                      onChange={(e) => {
                        setIsErrorTaskName(false)
                        onChangeEditTaskName(e)
                      }}
                      placeholder={t('task_name')}
                      error={isErrorTaskName}
                    />
                  </div>

                  <div className="wrp-input-row">
                    <div className="wrp-input-left">
                      <div className="wrp-filed">
                        <div className="title-filed">{t('assign_to')}</div>
                        <div className="wrp-search-employee w-100">
                          <SearchSuggestEmployee
                            placeHolder="Search Employee Name"
                            selectedId={
                              currentProjectType == 'routine' ? taskData?.assign_to : taskProjectData?.assign_to
                            }
                            onSelectedSuggest={(e) => handleClickSelectEmployee(e)}
                            isError={isErrorAssignUser}
                            isDisabled={false}
                          />
                        </div>
                      </div>

                      <div className="wrp-filed">
                        <div className="title-filed">{t('start_date')}</div>
                        <Date
                          className={'w-100'}
                          value={taskData?.start_date}
                          onChange={(e) => {
                            setIsErrorStartDate(false)
                            onChangeStartDate(e)
                          }}
                          error={isErrorStartDate}
                          disabled={currentProjectType == 'project' ? true : false}
                        />
                      </div>

                      <div className="wrp-filed">
                        <div className="title-filed">{t('due_date')}</div>
                        <Date
                          className={'input-calendar'}
                          value={currentProjectType == 'routine' ? taskData?.due_date : taskProjectData?.due_date}
                          onChange={(e) => {
                            setIsErrorDueDate(false)
                            onChangeDueDate(e)
                          }}
                          error={isErrorDueDate}
                        />
                      </div>

                      <div className="wrp-filed">
                        <div className="title-filed">{t('timeframe')}</div>
                        <TextField
                          className="txt-field w-100"
                          value={currentProjectType == 'routine' ? taskData?.time_frame : taskProjectData?.time_frame}
                          onChange={(e) => {
                            setIsErrorTimeFrame(false)
                            handleTimeFrameChange(e)
                          }}
                          error={isErrorTimeFrame}
                          // disabled={currentProjectType == 'routine' ? false : true}
                        />
                      </div>
                    </div>

                    <div className="wrp-input-right">
                      {type != 'new_task_in_project' && (
                        <div className="wrp-filed">
                          <div className="title-filed">{t('project')}</div>

                          <DropdownSelect
                            className="dropdown"
                            key={'dropdown-project'}
                            dataList={projectPriorityList}
                            initValue={projectTypeSelected}
                            onChange={(e) => {
                              handleProjectSelected(e)
                            }}
                            placeHolder={'กรุณาเลือก Project'}
                          />
                        </div>
                      )}

                      {currentProjectType == 'project' && (
                        <div className="wrp-filed">
                          <div className="title-filed">{t('miestone')}</div>

                          <DropdownSelect
                            className={'dropdown ' + (!isSelectedForMilestone ? 'dropdown-disabled' : '')}
                            key={'dropdown-milestone'}
                            dataList={milestoneDropdownList}
                            initValue={milestoneSelected}
                            onChange={(e) => {
                              setIsErrorMilestone(false)
                              handleMilestoneSelected(e)
                            }}
                            // isDisabled={isSelectedForMilestone}
                            isError={isErrorMilestone}
                          />
                        </div>
                      )}

                      <div className="wrp-filed">
                        <div className="title-filed">{t('scope')}</div>

                        <DropdownSelect
                          className={'dropdown ' + (!isSelectedForTaskScope ? 'dropdown-disabled' : '')}
                          key={'dropdown-scope'}
                          dataList={scopeList}
                          initValue={
                            currentProjectType == 'routine'
                              ? taskData?.task_scope
                              : taskProjectData?.milestone_scope_work
                          }
                          onChange={(e) => {
                            setIsSelectedScope(true)
                            setIsErrorTaskScope(false)
                            handleScopeSelected(e)
                          }}
                          isDisabled={!isSelectedForTaskScope}
                          isError={isErrorTaskScope}
                          placeHolder={'กรุณาเลือก Scope'}
                        />
                      </div>

                      <div className="wrp-filed">
                        <div className="title-filed">{t('task_type')}</div>

                        <DropdownSelect
                          className={'dropdown ' + (!isSelectedScope ? 'dropdown-disabled' : '')}
                          key={'dropdown-task-type'}
                          dataList={taskTypeList}
                          initValue={currentProjectType == 'routine' ? taskData?.task_type : taskProjectData?.task_type}
                          onChange={(e) => {
                            setIsErrorTaskType(false)
                            handleTaskTypeSelected(e)
                          }}
                          isDisabled={!isSelectedScope}
                          isError={isErrorTaskType}
                          placeHolder={'กรุณาเลือก Task Type'}
                        />
                      </div>

                      <div className="wrp-filed">
                        <div className="title-filed">{t('value')}</div>
                        <TextField
                          className="txt-field"
                          value={convertIntComma(
                            currentProjectType == 'routine' ? taskData?.value : taskProjectData?.value
                          )}
                          pattern={'^[0-9]*[.,]?[0-9]*$'}
                          onChange={(e) => {
                            setIsErrorValue(false)
                            handleValueChange(e)
                          }}
                          error={isErrorValue}
                          startAdornment={'THB'}
                          startAdornmentClassName={'prefix'}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="title-filed">{t('your_comment')}</div>

                  <TextEditor
                    className="text-editor"
                    // initValue={
                    //   currentProjectType == 'routine'
                    //     ? taskData?.description
                    //       ? JSON.parse(taskData.description)
                    //       : ''
                    //     : taskProjectData?.detail
                    //     ? JSON.parse(taskProjectData.detail)
                    //     : ''
                    // }
                    initValue={type == 'new_task' ? '' : taskInfo?.description ? JSON.parse(taskInfo.description) : ''}
                    onBlur={(e) => handleDetailChange(e)}
                  />

                  {currentProjectType == 'routine' && (
                    <div className="wrp-bottom-btn-modal">
                      {oldFileList.length > 0 && (
                        <ul className={'ul-file-list'}>
                          {oldFileList.map((item, index) => (
                            <li key={'file-item-' + index}>
                              <div className="wrp-file-item">
                                <div className="detail-btn" onClick={() => handleClickOpenFile(item?.url)}>
                                  <div className="icon-file">
                                    {/* {item?.file_detail?.ext == '.png' ? <IconFilePdf width="40" /> : ''} */}
                                    {generateIconFileByType(item?.file_detail?.ext)}
                                  </div>
                                  <div className="detail-file">
                                    <div className="name-file">{item?.file_detail?.name}</div>
                                    <div className="size-file">
                                      <span>{item?.file_detail?.size}</span> KB (Old file)
                                    </div>
                                  </div>
                                </div>

                                <div className="btn-delete-file" onClick={() => handleClickDeleteFileItem(item?.id)}>
                                  <IconXClose />
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}

                      {fileList.length > 0 && (
                        <ul className={'ul-file-list'}>
                          {fileList.map((item, index) => (
                            <li key={'file-item-' + index}>
                              <div className="wrp-file-item">
                                <div className="detail-btn" onClick={() => handleClickOpenFile(item?.url)}>
                                  <div className="icon-file">{generateIconFileByType(item?.ext)}</div>
                                  <div className="detail-file">
                                    <div className="name-file">{item?.name}</div>
                                    <div className="size-file">
                                      <span>{item.size}</span> - KB 100% uploaded
                                    </div>
                                  </div>
                                </div>

                                <div className="btn-delete-file" onClick={() => handleDeleteItemUploaded(index)}>
                                  <IconXClose />
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}

                      <div className="wrp-media-file">
                        <MediaFile
                          className={'upload-media-file'}
                          multiple={true}
                          allowedTypes={allowTypes}
                          onChange={(e) => onChangeUploadFile(e)}
                          inputForm="file_upload"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="button-bottom">
                <div className="left">{/* <div className="btn-save">Save</div> */}</div>
                <div className="right">
                  <ButtonOutline className="btn-cancel" onClick={() => handleCloseModal()}>
                    {t('cancel')}
                  </ButtonOutline>
                  <Button onClick={handleClickPublish}>
                    {type == 'new_task' && t('create')}
                    {type == 'edit_task' && t('update')}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* --------------- PROVIEW_MODAL  ---------------- */}
        {isShowModalPreview && (
          <Modal open={isShowModalPreview} disableBackdropClick={true} onClose={() => setIsShowModalPreview(false)}>
            <Div>
              <div className="wrp-modal">
                <div className="top">
                  <div className="btn-x-close" onClick={() => setIsShowModalPreview(false)}>
                    <IconXClose color="#667085" />
                  </div>
                </div>

                <div className="view-input">
                  <div className="wrp-inputs-container">
                    <div className="wrp-task-name-editor">
                      <div className="title-filed">Task name</div>
                      <TextField
                        className="task-name"
                        value={taskData?.task_name}
                        onChange={(e) => {
                          setIsErrorTaskName(false)
                          onChangeEditTaskName(e)
                        }}
                        placeholder={'Task Name'}
                        error={isErrorTaskName}
                        disabled
                      />
                    </div>

                    <div className="wrp-input-row">
                      <div className="wrp-input-left">
                        <div className="wrp-filed">
                          <div className="title-filed">Assign to</div>
                          <div className="wrp-search-employee w-100">
                            <SearchSuggestEmployee
                              placeHolder="Search Employee Name"
                              onSelectedSuggest={(e) => handleClickSelectEmployee(e)}
                              isError={isErrorAssignUser}
                              isDisabled
                            />
                          </div>
                        </div>

                        <div className="wrp-filed">
                          <div className="title-filed">Start Date</div>
                          <Date
                            className={'w-100'}
                            value={taskData?.start_date}
                            onChange={(e) => {
                              setIsErrorStartDate(false)
                              onChangeStartDate(e)
                            }}
                            error={isErrorStartDate}
                            disabled={currentProjectType == 'project' ? true : false}
                          />
                        </div>

                        <div className="wrp-filed">
                          <div className="title-filed">Due Date</div>
                          <Date
                            className={'input-calendar'}
                            value={taskData?.due_date}
                            onChange={(e) => {
                              setIsErrorDueDate(false)
                              onChangeDueDate(e)
                            }}
                            error={isErrorDueDate}
                            disabled
                          />
                        </div>

                        <div className="wrp-filed">
                          <div className="title-filed">Timeframe</div>
                          <TextField
                            className="txt-field w-100"
                            defaultValue={taskData?.time_frame}
                            onChange={(e) => {
                              setIsErrorTimeFrame(false)
                              handleTimeFrameChange(e)
                            }}
                            error={isErrorTimeFrame}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="wrp-input-right">
                        {type != 'new_task_in_project' && (
                          <div className="wrp-filed">
                            <div className="title-filed">{t('project')}</div>

                            <DropdownSelect
                              className="dropdown"
                              key={'dropdown-project'}
                              dataList={projectPriorityList}
                              initValue={projectTypeSelected}
                              onChange={(e) => {
                                handleProjectSelected(e)
                              }}
                              placeHolder={'กรุณาเลือก Project'}
                              isDisabled
                            />
                          </div>
                        )}

                        {currentProjectType == 'project' && (
                          <div className="wrp-filed">
                            <div className="title-filed">{t('miestone')}</div>

                            <DropdownSelect
                              className={'dropdown ' + (!isSelectedForMilestone ? 'dropdown-disabled' : '')}
                              key={'dropdown-milestone'}
                              dataList={milestoneDropdownList}
                              initValue={milestoneSelected}
                              onChange={(e) => {
                                setIsErrorMilestone(false)
                                handleMilestoneSelected(e)
                              }}
                              // isDisabled={isSelectedForMilestone}
                              isError={isErrorMilestone}
                              isDisabled
                            />
                          </div>
                        )}

                        <div className="wrp-filed">
                          <div className="title-filed">{t('scope')}</div>

                          <DropdownSelect
                            className={'dropdown ' + (!isSelectedForTaskScope ? 'dropdown-disabled' : '')}
                            key={'dropdown-scope'}
                            dataList={scopeList}
                            initValue={
                              currentProjectType == 'routine'
                                ? taskData?.task_scope
                                : taskProjectData?.milestone_scope_work
                            }
                            onChange={(e) => {
                              setIsSelectedScope(true)
                              setIsErrorTaskScope(false) // validate
                              handleScopeSelected(e)
                            }}
                            // disabled={!isSelectedForTaskScope}
                            error={isErrorTaskScope}
                            placeHolder={'กรุณาเลือก Scope'}
                            isDisabled={currentProjectType == 'routine' && isSelectedForTaskScope ? true : false}
                          />
                        </div>

                        <div className="wrp-filed">
                          <div className="title-filed">{t('task_type')}</div>

                          <DropdownSelect
                            className={'dropdown ' + (!isSelectedScope ? 'dropdown-disabled' : '')}
                            key={'dropdown-task-type'}
                            dataList={taskTypeList}
                            initValue={
                              currentProjectType == 'routine' ? taskData?.task_type : taskProjectData?.task_type
                            }
                            onChange={(e) => {
                              setIsErrorTaskType(false)
                              handleTaskTypeSelected(e)
                            }}
                            isDisabled={!isSelectedScope}
                            isError={isErrorTaskType}
                            placeHolder={'กรุณาเลือก Task Type'}
                            // isDisabled
                          />
                        </div>

                        <div className="wrp-filed">
                          <div className="title-filed">{t('value')}</div>
                          <TextField
                            className="txt-field"
                            value={convertIntComma(taskData?.value)}
                            pattern={'^[0-9]*[.,]?[0-9]*$'}
                            onChange={(e) => {
                              setIsErrorValue(false)
                              handleValueChange(e)
                            }}
                            error={isErrorValue}
                            startAdornment={'THB'}
                            startAdornmentClassName={'prefix'}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Div>
          </Modal>
        )}

        {isShowFailedModal && (
          <DialogFail
            open={Boolean(isShowFailedModal)}
            onClose={() => setIsShowFailedModal(false)}
            onSubmit={() => setIsShowFailedModal(false)}
            icon={failIcon}
            title={'You may not fully use this feature, since you are not employee.'}
            nameItem={''}
            description={''}
            textYes="Ok"
          />
        )}

        {isShowDeleteConfirm && (
          <DialogConfirmDelete
            open={isShowDeleteConfirm}
            title={t('delete_access_role')}
            description={
              <div className="text-desc-modal-delete">
                {t('junior_developer')}
                <br></br>
                <br></br>
                {t('delete_access_role_desc')}
              </div>
            }
            onSubmit={() => handleSubmitDeleteDoc()}
            onClose={() => setIsShowDeleteConfirm(true)}
          />
        )}

        {isShowModalSuccessDelDoc && (
          <DialogSuccess
            open={isShowModalSuccessDelDoc}
            onClose={() => setIsShowModalSuccessDelDoc(false)}
            onSubmit={() => setIsShowModalSuccessDelDoc(false)}
            icon={successIcon}
            title={t('access_role_delete_success')}
            textYes={t('done')}
          />
        )}
      </Div>
    </SidePanel>
  )
}

const Div = styled.div`
  flex: 1;
  justify-content: flex-start;

  //constant
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .w-100 {
    width: 100%;
  }
  .px-10 {
    padding-left: 10px;
    padding-right: 10px;
  }
  .mt-1-rem {
    margin-top: 1rem;
  }
  .txt-center {
    text-align: center;
  }
  ul {
    list-style-type: none;
    padding: 0; /* Remove padding */
    margin: 0;
  }

  //Structure modal
  /* position: fixed; */
  /* z-index: 999; */
  /* right: 0;
  bottom: 0;
  top: 0;
  left: 0; */

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .dialog-content {
    /* width: 720px; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    background-color: var(--Base-White);
    border-radius: 8px;

    height: 100%;
  }

  //Design modal
  .modal-container {
    position: relative;
    width: 100%;

    flex: 1;

    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .wrp-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 1px solid #6941c6; */
    padding: 24px;
  }
  .modal-header {
    /* color: #6941c6; */
    font-weight: bold;
    font-size: 1.5rem;
    width: 100%;
  }
  .btn-close {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .btn-close:hover {
    background-color: var(--Gray-100);
    cursor: pointer;
  }

  .wrp-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 21px 24px;
  }
  .txt-name {
    width: 50%;
    font-weight: bold;
    font-size: 25px;
    color: var(--Gray-600);
    cursor: pointer;
  }
  .txt-name:hover {
    color: var(--Primary-500);
  }

  .wrp-task-name-editor {
    margin-bottom: 24px;
  }
  .wrp-input-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .wrp-input-left,
  .wrp-input-right {
    width: 100%;
  }

  .wrp-input-right {
    margin-left: 12px;
  }
  .wrp-filed {
    margin-bottom: 24px;
  }
  .title-filed {
    min-width: 90px;
    color: var(--Gray-600);
    margin-bottom: 6px;
  }
  //
  .task-name {
    width: 100%;
  }
  .txt-field,
  .dropdown {
    width: 100%;

    .prefix {
      border-right: 1px solid var(--Gray-300);
      padding: 10px 12px 10px 14px;
    }
  }
  .dropdown {
    height: 40px;
  }

  .dropdown-disabled {
    background-color: var(--Gray-100) !important;
    border-radius: 8px;
  }
  .input-calendar {
    width: 100%;
  }
  .txt-editor {
    width: 100%;
    height: 90px;
    margin: 1rem 0;
  }
  .text-editor {
    margin: 0 0 24px;
  }
  .wrp-bottom-btn-modal {
    .ul-file-list {
      max-width: 50%;

      .wrp-file-item {
        display: flex;
        padding: 10px;
        border: 1px solid var(--Gray-300);
        border-radius: 8px;
        margin-bottom: 10px;
        cursor: pointer;

        .detail-btn {
          display: flex;
          width: 100%;

          .detail-file {
            margin-left: 10px;
          }
        }
      }
    }
    .wrp-media-file {
      max-width: 50%;
    }
  }

  //modal 2
  .wrp-navigation {
    display: flex;
    align-items: center;
  }
  .btn-outline {
    cursor: pointer;
    margin-right: 0.5rem;
    border-radius: 25px;
    border: none;
    padding: 5px;
    height: min-content;
    span {
      margin: 0;
    }
  }
  .title-content {
    font-weight: bold;
    font-size: 20px;
  }

  .bottom-content {
    border-top: 1px solid var(--Gray-600);
    padding: 20px;
    text-align: right;
  }

  .wrp-btn-file {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-gap: 10px;
  }
  .wrp-search-employee {
    width: 100%;
    .wrp-search-input {
      width: 100%;
      .icon {
        top: 10px;
      }
    }
    .list-dropdown {
      width: 420px;
    }
  }
  .wrp-filter-btn {
    position: relative;
  }
  .wrp-filter-btn .wrp-input {
    width: 235px !important;
  }
  .wrp-input-assign-user {
    display: flex;
    align-items: center;
    border: 1px solid var(--Gray-300);
    border-radius: 8px;
    height: 40px;
    font-size: 14px;
    /* padding: 10px 18px; */
  }
  .wrp-input-error {
    /* display: flex;
    align-items: center; */
    border: 1px solid var(--Error-600) !important;
    /* border-radius: 8px;
    height: 40px;
    font-size: 14px; */
  }
  .btn-search-user {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 18px;
    border-radius: 8px;
  }

  .text-search {
    font-weight: normal;
    color: var(--Gray-600);
  }
  .btn-delete-user {
    cursor: pointer;
    padding: 10px 10px;
    height: 100%;
    border-radius: 8px;
    /* :hover {
      background-color: var(--Gray-100);
    } */
  }
  .dialog-popup {
    position: absolute;
    z-index: 1;
    background-color: #fff !important;
    min-width: 300px;
    padding: 1rem;
    border-radius: 4px;
    box-shadow: 1px 1px 6px 5px var(--Gray-200);
    max-height: 300px;

    .btn-close-dialog {
      position: absolute;
      background-color: var(--Error-600);
      padding: 6px;
      border-radius: 50%;
      top: -8px;
      right: -8px;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      :hover {
        background-color: red;
      }
    }

    .wrp-person-list {
      position: relative;
    }
    .person-list {
      height: 220px;
      overflow-y: scroll;
      margin-top: 10px;
    }

    .person-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      border-radius: 6px;
      padding: 6px 4px;
      margin-bottom: 8px;

      span {
        text-transform: capitalize;
      }

      .img-profile {
        margin-right: 10px;
        width: 30px;
        height: 30px;
        background-color: var(--Gray-200);
        border-radius: 50%;
        padding: 5px;
      }
    }
    .person-item:hover {
      background-color: var(--Gray-50);
    }
  }

  .wrp-inputs-container {
    flex: 1;
  }

  .button-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--Gray-300);
    padding: 16px 24px;

    .left {
      .btn-save {
        font-weight: bold;
        color: var(--Primary-700);
      }
    }
    .right {
      .btn-cancel {
        margin-right: 12px;
      }
    }
  }

  .wrp-modal {
    width: 700px;
    padding: 21px;
    .top {
      display: flex;
      justify-content: flex-end;

      .btn-x-close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .btn-x-close:hover {
        background-color: var(--Gray-100);
        cursor: pointer;
      }
    }
  }

  .ul-file-list {
    max-width: 50%;
    list-style-type: none;
    padding: 0;

    li {
      cursor: pointer;

      .wrp-file-item {
        padding: 10px;
        border: 1px solid var(--Gray-300);
        border-radius: 8px;
        margin-bottom: 10px;

        .detail-btn {
          display: flex;
          flex: 1;
          margin-left: 10px;

          .detail-file {
          }

          .name-file {
            max-width: 300px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          span {
            margin: 0;
          }
        }
      }
    }
  }
`

export default DialogTaskEditor
