export default function IconEdit({ width = '16', color = '#9E77ED', style }) {
  return (
    <svg width={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M7.33398 2.66812H4.53398C3.41388 2.66812 2.85383 2.66812 2.426 2.88611C2.04968 3.07785 1.74372 3.38382 1.55197 3.76014C1.33398 4.18796 1.33398 4.74802 1.33398 5.86812V11.4681C1.33398 12.5882 1.33398 13.1483 1.55197 13.5761C1.74372 13.9524 2.04968 14.2584 2.426 14.4501C2.85383 14.6681 3.41388 14.6681 4.53398 14.6681H10.134C11.2541 14.6681 11.8141 14.6681 12.242 14.4501C12.6183 14.2584 12.9243 13.9524 13.116 13.5761C13.334 13.1483 13.334 12.5882 13.334 11.4681V8.66812M5.33397 10.6681H6.45033C6.77645 10.6681 6.93951 10.6681 7.09296 10.6313C7.22901 10.5986 7.35907 10.5447 7.47836 10.4716C7.61292 10.3892 7.72822 10.2739 7.95882 10.0433L14.334 3.66812C14.8863 3.11584 14.8863 2.2204 14.334 1.66812C13.7817 1.11584 12.8863 1.11583 12.334 1.66812L5.95881 8.04328C5.7282 8.27388 5.6129 8.38919 5.53045 8.52374C5.45734 8.64304 5.40347 8.7731 5.37081 8.90915C5.33397 9.0626 5.33397 9.22566 5.33397 9.55178V10.6681Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
