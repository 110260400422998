import { getAPI, postAPI, putAPI, deleteAPI } from '../../utils/api'
import {
  GET_DailySummary,
  CREATE_DailySummary,
  UPDATE_DailySummary,
  GET_DailySummary_BY_ID,
  UPDATE_DailySummary_STATUS,
  CREATE_ActionPlan,
  GET_ActionPlan,
  UPDATE_ActionPlan,
  GET_ActionPlan_BY_ID,
  UPDATE_ActionPlan_STATUS,
  GET_MeetingRoom,
  GET_MeetingRoomAll,
  CREATE_MeetingRoom,
  UPDATE_MeetingRoom,
  GET_Meeting,
  CREATE_Meeting,
  UPDATE_Meeting,
  GET_Meeting_BY_ID,
  GET_MeetingForCheck,
  GET_MeetingAttendee,
  UPDATE_Meeting_attendances,
  UPDATE_ApproveMeetingAttend,
  GET_ActionPlanReturnData,
  GET_DailySummaryReturnData,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

import { postAPIdiscord } from '../../utils/apidiscord'

export const createDailySummary = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_DailySummary.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/daily-summary-reports`,
      data,
      headers: headers,
    })

    //dispatch(getDailySummary())

    return dispatch({
      type: CREATE_DailySummary.SUCCESS,
      showAlert: true,
      alertMessage: 'Create DailySummary Successful',
    })
  } catch (err) {
    try {
      const text_to_discode = `มี Error ตอน createDailySummary เด้อหมอเดฟ \n ### input_data ### \n [${JSON.stringify(
        data
      )}] \n ### error_message ### \n [${JSON.stringify(err)}]`
      const data_to_discord = {
        content: text_to_discode.substring(0, 1800),
      }
      await postAPIdiscord({
        url: ``,
        data: data_to_discord,
        headers: {
          'Content-Type': 'application/json',
        },
      })
    } catch (error) {
      console.log(error)
    }

    return dispatch({ type: CREATE_DailySummary.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDailySummary =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_DailySummary.REQUEST })
      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      //const params = { ...filter }
      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        is_active: 1,
        employee_id: filter.EmployeeId,
        'filters[company][id]': company_info?.id ? company_info?.id : null,
      }

      if (filter.what_done_today) {
        params = { ...params, what_done_today: filter.what_done_today }
      }
      if (filter.filter_date) {
        params = { ...params, filter_date: filter.filter_date }
      }

      if (filter.start_date && filter.end_date) {
        params = {
          ...params,
          'filters[dailysummary_date][$gte]': filter.start_date,
          'filters[dailysummary_date][$lte]': filter.end_date,
        }
      }

      const { data, meta } = await getAPI({
        url: '/api/daily-summary-reports',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_DailySummary.SUCCESS,
        data,
        meta,
        filter,
      })
    } catch (error) {
      return dispatch({ type: GET_DailySummary.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getDailySummaryReturnData =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_DailySummaryReturnData.REQUEST })
      const { jwt, user } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      //const params = { ...filter }
      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        is_active: 1,
        employee_id: filter.EmployeeId,
      }

      if (filter.what_done_today) {
        params = { ...params, what_done_today: filter.what_done_today }
      }
      if (filter.filter_date) {
        params = { ...params, filter_date: filter.filter_date }
      }

      if (filter.start_date && filter.end_date) {
        params = {
          ...params,
          'filters[dailysummary_date][$gte]': filter.start_date,
          'filters[dailysummary_date][$lte]': filter.end_date,
        }
      }

      const { data, meta } = await getAPI({
        url: '/api/daily-summary-reports',
        headers: headers,
        params,
      })
      return { type: 'getDailySummaryReturnData_SUCCESS', data_info: data, data_meta: meta }
    } catch (error) {
      return { type: 'getDailySummaryReturnData_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

export const getDailySummaryById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DailySummary_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/daily-summary-reports/${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_DailySummary_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_DailySummary_BY_ID.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateDailySummary = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_DailySummary.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/daily-summary-reports/${id}`,
      data,
      headers: headers,
    })

    //dispatch(getDailySummary())

    return dispatch({
      type: UPDATE_DailySummary.SUCCESS,
      showAlert: true,
      alertMessage: 'Update DailySummary Successful',
    })
  } catch (err) {
    try {
      const text_to_discode = `มี Error ตอน updateDailySummary เด้อหมอเดฟ \n ### input_data ### \n [${JSON.stringify(
        data
      )}] \n ### error_message ### \n [${JSON.stringify(err)}]`
      const data_to_discord = {
        content: text_to_discode.substring(0, 1800),
      }
      await postAPIdiscord({
        url: ``,
        data: data_to_discord,
        headers: {
          'Content-Type': 'application/json',
        },
      })
    } catch (error) {
      console.log(error)
    }

    return dispatch({
      type: UPDATE_DailySummary.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update DailySummary Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const ChangeStatusDailySummary = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_DailySummary_STATUS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/daily-summary-reports/${id}`,
      data,
      headers: headers,
    })

    dispatch(getDailySummary())

    return dispatch({
      type: UPDATE_DailySummary_STATUS.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_DailySummary_STATUS.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const createActionPlan = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_ActionPlan.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/action-plans`,
      data,
      headers: headers,
    })

    //dispatch(getActionPlan())

    return dispatch({
      type: CREATE_ActionPlan.SUCCESS,
      showAlert: true,
      alertMessage: 'Create ActionPlan Successful',
    })
  } catch (err) {
    try {
      const text_to_discode = `มี Error ตอน createActionPlan เด้อหมอเดฟ \n ### input_data ### \n [${JSON.stringify(
        data
      )}] \n ### error_message ### \n [${JSON.stringify(err)}]`
      const data_to_discord = {
        content: text_to_discode.substring(0, 1800),
      }
      await postAPIdiscord({
        url: ``,
        data: data_to_discord,
        headers: {
          'Content-Type': 'application/json',
        },
      })
    } catch (error) {
      console.log(error)
    }

    return dispatch({ type: CREATE_ActionPlan.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getActionPlan =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_ActionPlan.REQUEST })
      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      //const params = { ...filter }
      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        is_active: 1,
        employee_id: filter.EmployeeId,
        'filters[company][id]': company_info?.id ? company_info?.id : null,
      }

      if (filter.action_plan) {
        params = { ...params, action_plan: filter.action_plan }
      }
      if (filter.filter_date) {
        params = { ...params, filter_date: filter.filter_date }
      }
      if (filter.start_date && filter.end_date) {
        params = {
          ...params,
          'filters[action_plan_date][$gte]': filter.start_date,
          'filters[action_plan_date][$lte]': filter.end_date,
        }
      }

      const { data, meta } = await getAPI({
        url: '/api/action-plans',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_ActionPlan.SUCCESS,
        data,
        meta,
        filter,
      })
    } catch (error) {
      return dispatch({ type: GET_ActionPlan.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getActionPlanReturnData =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_ActionPlanReturnData.REQUEST })
      const { jwt, user } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      //const params = { ...filter }
      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        is_active: 1,
        employee_id: filter.EmployeeId,
      }

      if (filter.action_plan) {
        params = { ...params, action_plan: filter.action_plan }
      }
      if (filter.filter_date) {
        params = { ...params, filter_date: filter.filter_date }
      }
      if (filter.start_date && filter.end_date) {
        params = {
          ...params,
          'filters[action_plan_date][$gte]': filter.start_date,
          'filters[action_plan_date][$lte]': filter.end_date,
        }
      }

      const { data, meta } = await getAPI({
        url: '/api/action-plans',
        headers: headers,
        params,
      })

      return { type: 'getActionPlanReturnData_SUCCESS', data_info: data, data_meta: meta }
    } catch (error) {
      return { type: 'getActionPlanReturnData_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

export const updateActionPlan = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_ActionPlan.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/action-plans/${id}`,
      data,
      headers: headers,
    })

    //dispatch(getActionPlan())

    return dispatch({
      type: UPDATE_ActionPlan.SUCCESS,
      showAlert: true,
      alertMessage: 'Update DailySummary Successful',
    })
  } catch (err) {
    try {
      const text_to_discode = `มี Error ตอน updateActionPlan เด้อหมอเดฟ \n ### input_data ### \n [${JSON.stringify(
        data
      )}] \n ### error_message ### \n [${JSON.stringify(err)}]`
      const data_to_discord = {
        content: text_to_discode.substring(0, 1800),
      }
      await postAPIdiscord({
        url: ``,
        data: data_to_discord,
        headers: {
          'Content-Type': 'application/json',
        },
      })
    } catch (error) {
      console.log(error)
    }

    return dispatch({
      type: UPDATE_ActionPlan.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update DailySummary Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getActionPlanById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ActionPlan_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const params = {
      'populate[employee]': true,
      'populate[action_plan_details][populate][task_reference]': true,
      'populate[action_plan_details][populate][project_task_reference][populate][milestone_scope_work][populate][milestone][populate][project_task]': true,
      'populate[action_plan_details][populate][op_sw_reference][populate][sp]': true,
      'populate[action_plan_details][populate][op_task_reference][populate][sw][populate][sp]': true,
      'populate[action_plan_details][populate][op_approver_reference][populate][sw][populate][sp]': true,
      'populate[action_plan_details][populate][op_reviewer_reference][populate][sw][populate][sp]': true,
    }
    const { data } = await getAPI({
      url: `/api/action-plans/${id}`,
      params,
      headers: headers,
    })

    return dispatch({
      type: GET_ActionPlan_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_ActionPlan_BY_ID.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const ChangeStatusActionPlan = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_ActionPlan_STATUS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/action-plans/${id}`,
      data,
      headers: headers,
    })

    dispatch(getActionPlan())

    return dispatch({
      type: UPDATE_ActionPlan_STATUS.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_ActionPlan_STATUS.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMeetingRoomAll =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MeetingRoomAll.REQUEST })
      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      //const params = { ...filter }
      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'filters[company][id]': company_info?.id ? company_info?.id : null,
      }

      if (filter.meeting_room_name) {
        params = { ...params, meeting_room_name: filter.meeting_room_name }
      }

      const { data, meta } = await getAPI({
        url: '/api/meeting-rooms',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_MeetingRoomAll.SUCCESS,
        data,
        meta,
        filter,
      })
    } catch (error) {
      return dispatch({ type: GET_MeetingRoomAll.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getMeetingRoom =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MeetingRoom.REQUEST })
      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      //const params = { ...filter }
      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'filters[active]': 1,
        'filters[company][id]': company_info?.id ? company_info?.id : null,
      }

      const { data, meta } = await getAPI({
        url: '/api/meeting-rooms',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_MeetingRoom.SUCCESS,
        data,
        meta,
        filter,
      })
    } catch (error) {
      return dispatch({ type: GET_MeetingRoom.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getMeeting =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_Meeting.REQUEST })
      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      //const params = { ...filter }
      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'filters[company][id]': company_info?.id ? company_info?.id : null,
      }
      if (filter.active) {
        params = { ...params, 'filters[active]': filter.active }
      }
      if (filter.EmployeeId) {
        params = { ...params, 'filters[meeting_attendances][employee_id]': filter.EmployeeId }
        params = { ...params, 'filters[meeting_attendances][active]': 1 }
      }

      if (filter.meeting_room) {
        params = { ...params, 'filters[meeting_room]': filter.meeting_room }
      }
      if (filter.filter_date) {
        params = { ...params, 'filters[meeting_date]': filter.filter_date }
      }
      if (filter.meeting_attendances_id) {
        params = { ...params, 'filters[meeting_attendances][id]': filter.meeting_attendances_id }
      }
      if (filter.start_date && filter.end_date) {
        params = {
          ...params,
          'filters[meeting_date][$gte]': filter.start_date,
          'filters[meeting_date][$lte]': filter.end_date,
        }
      }

      const { data, meta } = await getAPI({
        url: '/api/meetings',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_Meeting.SUCCESS,
        data,
        meta,
        filter,
      })
    } catch (error) {
      return dispatch({ type: GET_Meeting.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const createMeetingRoom = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_MeetingRoom.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/meeting-rooms`,
      data,
      headers: headers,
    })

    dispatch(getMeetingRoomAll())

    return dispatch({
      type: CREATE_MeetingRoom.SUCCESS,
      showAlert: true,
      alertMessage: 'Create MeetingRoom Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_MeetingRoom.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const createMeeting = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_Meeting.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/meetings`,
      data,
      headers: headers,
    })

    //dispatch(getMeeting())

    return dispatch({
      type: CREATE_Meeting.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Meeting Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_Meeting.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMeetingForCheck =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MeetingForCheck.REQUEST })
      const { jwt, user } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      //const params = { ...filter }

      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'filters[active]': 1,
      }

      if (filter.EmployeeId) {
        params = { ...params, 'filters[meeting_attendances][employee_id]': filter.EmployeeId }
        params = { ...params, 'filters[meeting_attendances][active]': 1 }
      }

      if (filter.meeting_room) {
        params = { ...params, 'filters[meeting_room]': filter.meeting_room }
      }
      if (filter.filter_date) {
        params = { ...params, 'filters[meeting_date]': filter.filter_date }
      }

      if (filter.meeting_attendances_id) {
        params = { ...params, 'filters[meeting_attendances][id]': filter.meeting_attendances_id }
      }

      if (filter.start_date && filter.end_date) {
        params = {
          ...params,
          'filters[meeting_date][$gte]': filter.start_date,
          'filters[meeting_date][$lte]': filter.end_date,
        }
      }

      if (filter.meeting_room_is_not_null) {
        params = { ...params, 'filters[meeting_room_id][$notNull]': filter.meeting_room_is_not_null }
      }
      const { data, meta } = await getAPI({
        url: '/api/meetings',
        headers: headers,
        params,
      })
      return { type: 'getMeetingForCheck_SUCCESS', data_info: data, data_meta: meta }
      /*
      return dispatch({
        type: GET_MeetingForCheck.SUCCESS,
        data,
        meta,
        filter,
      })
      */
    } catch (error) {
      return { type: 'getMeetingForCheck_ERROR' }
      //return dispatch({ type: GET_MeetingForCheck.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getMeetingById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_Meeting_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/meetings/${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_Meeting_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_Meeting_BY_ID.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateMeetingRoom = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_MeetingRoom.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/meeting-rooms/${id}`,
      data,
      headers: headers,
    })

    dispatch(getMeetingRoomAll())

    return dispatch({
      type: UPDATE_MeetingRoom.SUCCESS,
      showAlert: true,
      alertMessage: 'Update MeetingRoom Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_MeetingRoom.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update MeetingRoom Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateMeeting = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_Meeting.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/meetings/${id}`,
      data,
      headers: headers,
    })

    dispatch(getMeetingById(id))

    return dispatch({
      type: UPDATE_Meeting.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Meeting Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_Meeting.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update Meeting Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMeetingAttendee =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MeetingAttendee.REQUEST })
      const { jwt, user } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      //const params = { ...filter }
      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'filters[active]': 1, //--- เฉพาะรายการที่ยังไม่ถูกลบ
      }
      if (filter.meeting_id) {
        params = { ...params, 'filters[meeting_id]': filter.meeting_id }
      }

      const { data, meta } = await getAPI({
        url: '/api/meeting-attendances',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_MeetingAttendee.SUCCESS,
        data,
        meta,
        filter,
      })
    } catch (error) {
      return dispatch({ type: GET_MeetingAttendee.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getMeetingAttendeeReturnData =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MeetingAttendee.REQUEST })
      const { jwt, user } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'filters[active]': 1, //--- เฉพาะรายการที่ยังไม่ถูกลบ
      }
      if (filter.active) {
        params = { ...params, 'filters[active]': filter.active }
      }
      if (filter.meeting_id) {
        params = { ...params, 'filters[meeting_id]': filter.meeting_id }
      }
      if (filter.actual_attend) {
        params = { ...params, 'filters[actual_attend]': filter.actual_attend }
      }

      const { data, meta } = await getAPI({
        url: '/api/meeting-attendances',
        headers: headers,
        params,
      })
      return { type: 'getMeetingForCheck_SUCCESS', data_info: data, data_meta: meta }
    } catch (error) {
      return { type: 'getMeetingForCheck_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

export const updateMeetingAttendances = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_Meeting_attendances.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/meeting-attendances/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_Meeting_attendances.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Meeting Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_Meeting_attendances.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update Meeting Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const ApproveMeetingAttend = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_ApproveMeetingAttend.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }
    await postAPI({
      url: `/api/approve-meeting-create`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_ApproveMeetingAttend.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Employee With Application Form Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_ApproveMeetingAttend.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Employee With Application Form Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMeetingMessage =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MeetingAttendee.REQUEST })
      const { jwt, user } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
      }
      if (filter.meeting_id) {
        params = { ...params, 'filters[meeting_id]': filter.meeting_id }
      }

      const { data, meta } = await getAPI({
        url: '/api/meeting-messages',
        headers: headers,
        params,
      })
      return { type: 'getMeetingForCheck_SUCCESS', data_info: data, data_meta: meta }
    } catch (error) {
      return { type: 'getMeetingForCheck_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

export const GetMeetingInfo = (inp_data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_ApproveMeetingAttend.REQUEST })
    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }
    const { data } = await postAPI({
      url: `/api/meeting-info`,
      data: inp_data,
      headers: headers,
    })
    return { type: 'GetMeetingInfo_SUCCESS', data_info: data.data_info }
  } catch (err) {
    return { type: 'GetMeetingInfo_ERROR' }
  } finally {
    dispatch(hideLoading())
  }
}

export const createMeetingMessage = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_Meeting.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/meeting-messages`,
      data,
      headers: headers,
    })

    //dispatch(getMeeting())

    return dispatch({
      type: CREATE_Meeting.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Meeting Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_Meeting.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const MeetingCheckRoom = (inp_data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_Meeting.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    //alert(JSON.stringify(inp_data))
    const { data } = await postAPI({
      url: `/api/meeting-checkroom`,
      data: inp_data,
      headers: headers,
    })

    return { type: 'MeetingCheckRoom_SUCCESS', data_info: data }
  } catch (err) {
    return { type: 'MeetingCheckRoom_ERROR' }
  } finally {
    dispatch(hideLoading())
  }
}

export const CreateFileUserUpload = (file_id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_Meeting.REQUEST })
    const { jwt, user } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const inp_data = {
      data: {
        file_id: file_id,
        create_by_full_name: user.first_name + ' ' + user.last_name,
        created_by_id: null, //user.id
      },
    }
    //alert(JSON.stringify(inp_data))
    const { data } = await postAPI({
      url: `/api/file-user-uploads`,
      data: inp_data,
      headers: headers,
    })

    return { type: 'CreateFileUserUpload_SUCCESS', data_info: data }
  } catch (err) {
    return { type: 'CreateFileUserUpload_ERROR' }
  } finally {
    dispatch(hideLoading())
  }
}

export const sendtEmail = (inp_data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_Meeting_attendances.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await postAPI({
      url: `/api/meeting-sentmail`,
      data: inp_data,
      headers: headers,
    })

    return { type: 'sendtEmail_SUCCESS', data_info: data }
  } catch (err) {
    return { type: 'sendtEmail_ERROR' }
  } finally {
    dispatch(hideLoading())
  }
}
