import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Papa from 'papaparse'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import Dropdown from '../../components/form/Dropdown'
import SearchText from '../../components/form/SearchText'
import Table from '../../components/common/Table'
import Modal from '../../components/common/Modal'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import InputFile from '../../components/form/InputFile'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/icon-edit.svg'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { AiOutlineCloudUpload } from 'react-icons/ai'

import {
  getMaterialList,
  getMaterialCategory,
  getMaterialType,
  getSuppliers,
  getMaterialNotes,
  createMaterialNote,
  deleteMaterial,
  uploadCSVMaterial,
} from '../../redux/actions/material'
import { selectMaterial } from '../../redux/selectors'
import { useTranslation } from 'react-i18next'
const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .content-header {
    margin-top: 24px;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error {
    color: red;
  }
`

const initStatusList = [
  {
    text: 'status',
    value: '',
  },
  {
    text: 'active',
    value: 'active',
  },
  {
    text: 'inactive',
    value: 'inactive',
  },
]

const limitLetter = 275

const Material = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const materialColumn = [
    {
      Header: t('id'),
      accessor: 'id',
      disableSortBy: false,
    },
    {
      Header: t('code'),
      accessor: 'code',
      disableSortBy: false,
    },
    {
      Header: t('material_name'),
      accessor: 'material_name',
      disableSortBy: false,
    },
    {
      Header: t('category'),
      accessor: 'category',
      disableSortBy: false,
    },
    {
      Header: t('type'),
      accessor: 'type',
      disableSortBy: false,
    },
    {
      Header: t('uom'),
      accessor: 'UoM',
      disableSortBy: false,
    },
    {
      Header: t('supplier'),
      accessor: 'supplier',
      disableSortBy: false,
    },
    {
      Header: t('cost_per_unit'),
      accessor: 'cost_per_unit',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('note'),
      accessor: 'noteBtn',
      disableSortBy: false,
    },
    {
      accessor: 'deleteBtn',
      disableSortBy: false,
    },
    {
      accessor: 'editBtn',
      disableSortBy: false,
    },
  ]
  const csvColumn = [
    {
      Header: t('material_name'),
      accessor: 'material_name',
      disableSortBy: false,
    },
    {
      Header: t('category'),
      accessor: 'Category',
      disableSortBy: false,
    },
    {
      Header: t('type'),
      accessor: 'Type',
      disableSortBy: false,
    },
    {
      Header: t('supplier'),
      accessor: 'Supplier',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      accessor: 'deleteBtn',
      disableSortBy: false,
    },
  ]
  const noteColumn = [
    {
      Header: t('time'),
      accessor: 'createdAt',
      disableSortBy: false,
    },
    {
      Header: t('note_by'),
      accessor: 'note_by',
      disableSortBy: false,
    },
    {
      Header: t('note'),
      accessor: 'note',
      disableSortBy: false,
    },
  ]
  const { materialList, materialCategory, materialType, suppliers, isLoading, total, skip, limit, notes } =
    useSelector(selectMaterial)
  // data
  const [materials, setMaterials] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(1)
  const [note, setNote] = useState('')
  const [materialId, setMaterialId] = useState('')
  const [isSubmitNote, setIsSubmitNote] = useState(false)
  const [fileCsv, setFileCsv] = useState()
  const [csvData, setCsvData] = useState([])
  const [errorUpload, setErrorUpload] = useState(false)
  const [limitChar, setLimitChar] = useState(limitLetter)
  // selected
  const [categorySelected, setCategorySelected] = useState('')
  const [typeSelected, setTypeSelected] = useState('')
  const [supplierSelected, setSupplierSelected] = useState('')
  const [statusSelected, setStatusSelected] = useState('')
  const [search, setSearch] = useState('')
  // dropdown list
  const [categoryList, setCategoryList] = useState([])
  const [typeList, setTypeList] = useState([])
  const [supplierList, setSupplierList] = useState([])
  const [statusList] = useState(initStatusList)
  // modal
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [uploadCsvModal, setUploadCsvModal] = useState(false)
  const [uploadCsvSuccessModal, setUploadCsvSuccessModal] = useState(false)
  const [noteModal, setNoteModal] = useState(false)
  const [errorAlertModal, setErrorAlertModal] = useState(false)

  const fetchData = useCallback(
    async (filter) => {
      dispatch(getMaterialList(filter))
    },
    [dispatch]
  )
  const noteMaterial = useCallback(
    async (id) => {
      dispatch(getMaterialNotes(id))
      setMaterialId(id)
      setNoteModal(true)
    },
    [dispatch]
  )

  const deleteMaterialCsv = useCallback((id) => {
    setCsvData((csvData) => csvData.filter((v) => v.id !== id))
  }, [])

  useEffect(() => {
    const restLetter = limitLetter - note.length
    setLimitChar(restLetter)
  }, [note])

  useEffect(() => {
    if (fileCsv) {
      Papa.parse(fileCsv, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const data = results.data
          const finalData = data.map((v, i) => {
            const material_category = categoryList.find((i) => i.value == v.Category)
            const material_type = typeList.find((i) => i.value == v.Type)
            const suppliers = supplierList.find((i) => i.value == v.Supplier)

            return {
              id: i,
              ...v,
              material_name: v['Material Name'],
              supplier: suppliers != undefined ? suppliers['id'] : null,
              material_category: material_category != undefined ? material_category['id'] : null,
              material_type: material_type != undefined ? material_type['id'] : null,
              status:
                material_category == null || material_type == null || suppliers == null
                  ? 'ข้อมูลไม่ถูก Format'
                  : 'ผ่าน',
              deleteBtn: (
                <img
                  id={`csv-data-${i}`}
                  className="icon"
                  src={iconDelete}
                  title="Delete"
                  onClick={() => deleteMaterialCsv(i)}
                />
              ),
            }
          })

          setCsvData(finalData)
        },
        error: function (err) {
          alert(`ไม่สามารถอัพโหลด CSV ได้: ${err}`)
        },
      })
    }
  }, [fileCsv, categoryList, typeList, supplierList, deleteMaterialCsv])

  useEffect(() => {
    const totalPage = Math.ceil(total / limit)
    setTotalPages(totalPage)
  }, [total, skip, limit])

  useEffect(() => {
    dispatch(getMaterialCategory())
    dispatch(getMaterialType())
    dispatch(getSuppliers())
  }, [dispatch])

  useEffect(() => {
    const filter = {
      skip: 1 * limit - limit,
      limit: limit,
    }
    fetchData(filter)
  }, [fetchData, limit])

  useEffect(() => {
    const newMaterials = materialList.map((v) => ({
      ...v,
      id: <Link to={`/inventory-management/materials/material-detail/${v.id}`}>{v.id}</Link>,
      noteBtn: <Button onClick={() => noteMaterial(v.id)}>Note</Button>,
      deleteBtn: <img className="icon" src={iconDelete} title="Delete" onClick={() => deleteMaterialItem(v.id)} />,
      editBtn: (
        <Link to={`/inventory-management/materials/edit-material/${v.id}`}>
          <img src={iconEdit} title="Edit" />
        </Link>
      ),
    }))
    setMaterials(newMaterials)
  }, [materialList, noteMaterial])

  useEffect(() => {
    const newMaterialCategories = materialCategory.map((v) => ({
      id: v.id,
      text: v.attributes.name,
      value: v.attributes.name,
    }))
    setCategoryList([{ text: 'category', value: '' }, ...newMaterialCategories])
  }, [materialCategory])

  useEffect(() => {
    const newMaterialTypes = materialType.map((v) => ({
      id: v.id,
      text: v.attributes.name,
      value: v.attributes.name,
    }))
    setTypeList([{ text: 'type', value: '' }, ...newMaterialTypes])
  }, [materialType])
  useEffect(() => {
    const newSuppliers = suppliers.map((v) => ({
      id: v.id,
      text: v.attributes.supplier_name,
      value: v.attributes.supplier_name,
    }))
    setSupplierList([{ text: 'supplier', value: '' }, ...newSuppliers])
  }, [suppliers])

  const onSubmitSearch = () => {
    const filter = {
      skip: 0,
      limit: limit,
      category: categorySelected == '' ? null : categorySelected,
      type: typeSelected == '' ? null : typeSelected,
      supplier: supplierSelected == '' ? null : supplierSelected,
      status: statusSelected == '' ? null : statusSelected,
    }
    fetchData(filter)
    setPage(1)
  }

  const onClearSearch = () => {
    const filter = {
      skip: 0,
      limit: limit,
      category: null,
      type: null,
      supplier: null,
    }
    fetchData(filter)
    setCategorySelected('')
    setTypeSelected('')
    setSupplierSelected('')
    setStatusSelected('')
    setPage(1)
  }

  const onChangePagination = async (value) => {
    const filter = {
      skip: value.page * limit - limit,
      limit: limit,
      category: categorySelected == '' ? null : categorySelected,
      type: typeSelected == '' ? null : typeSelected,
      supplier: supplierSelected == '' ? null : supplierSelected,
      status: statusSelected == '' ? null : statusSelected,
    }
    fetchData(filter)
    setPage(value.page)
  }

  const deleteMaterialItem = (id) => {
    setMaterialId(id)
    setConfirmDeleteModal(true)
  }

  const confirmDelete = async () => {
    const request = {
      data: {
        status: 'delete',
      },
    }
    dispatch(deleteMaterial(materialId, request))
    setConfirmDeleteModal(false)
    setPage(1)
  }

  const uploadCsv = () => {
    if (csvData.length > 0) {
      setUploadCsvModal(false)
      setUploadCsvSuccessModal(true)
    } else {
      setErrorUpload(true)
    }
  }

  const closeUploadCsvModal = () => {
    clearUploadCsv()
    setUploadCsvModal(false)
  }

  const closeUploadCsvSuccessModal = () => {
    clearUploadCsv()
    setUploadCsvSuccessModal(false)
  }

  const onChangeFile = (event) => {
    const file = event.target.files[0]
    setFileCsv(file)
  }

  const onSubmitUploadCsv = () => {
    if (!csvData.every((v) => v.status == 'ผ่าน')) {
      setErrorAlertModal(true)
    } else {
      const data = csvData.map((v) => ({
        material_name: v.material_name,
        supplier: v.supplier,
        material_category: v.material_category,
        material_type: v.material_type,
      }))
      const request = { data }
      dispatch(uploadCSVMaterial(request))
    }
    setUploadCsvSuccessModal(false)
    clearUploadCsv()
    setPage(1)
  }

  const clearUploadCsv = () => {
    setFileCsv()
    setCsvData([])
    setErrorUpload(false)
  }

  const onSubmitNote = () => {
    setIsSubmitNote(true)
    if (note !== '') {
      const { user } = JSON.parse(localStorage.getItem('admin'))
      const request = {
        data: {
          note: note,
          note_by: `${user.firstname} ${user.lastname}`,
          material: materialId,
        },
      }
      dispatch(createMaterialNote(request))
      setIsSubmitNote(false)
      setNote('')
    }
  }

  return (
    <Div>
      <header>
        <div className="content-title">{t('material')}</div>
        <div className="content-header">
          <SearchText
            className="mb-1-rem"
            placeholder={t('search')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="mb-1-rem">
            <Dropdown
              className="drop-down mr-1-rem"
              value={categorySelected}
              optionList={categoryList}
              onChange={(value) => setCategorySelected(value)}
            />
            <Dropdown
              className="drop-down mr-1-rem"
              value={typeSelected}
              optionList={typeList}
              onChange={(value) => setTypeSelected(value)}
            />
            <Dropdown
              className="drop-down mr-1-rem"
              value={supplierSelected}
              optionList={supplierList}
              onChange={(value) => setSupplierSelected(value)}
            />
            <Dropdown
              className="drop-down mr-1-rem"
              value={statusSelected}
              optionList={statusList}
              onChange={(value) => setStatusSelected(value)}
            />
          </div>
          <div>
            <Button onClick={onClearSearch} className="mr-1-rem">
              {t('clear')}
            </Button>
            <Button onClick={onSubmitSearch}>{t('search')}</Button>
          </div>
        </div>
      </header>
      <div className="table-wrapper">
        <div className="table-header">
          <div className="group">
            <div className="table-title">{t('meterial_list')}</div>
            <div className="table-total">{total} {t('items')}</div>
          </div>

          <div>
            <Button onClick={() => setUploadCsvModal(true)} className="mr-1-rem" append={<AiOutlineCloudUpload />}>
              {t('import_from_file')}
            </Button>
            <Link className="create-bt" to={`/inventory-management/materials/create-material`}>
              <Button append={<img src={iconPlus} />}>{t('register_new_material')}</Button>
            </Link>
          </div>
        </div>
        <Table
          columns={materialColumn}
          data={materials}
          onStateChange={onChangePagination}
          pageCount={totalPages}
          page={page}
          loading={isLoading}
        />
      </div>
      {/* Modal */}
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={confirmDelete}
        title={t('confrim_delete')}
        description={t('delete_item_description')}
      />
      <Modal open={Boolean(uploadCsvModal)} onClose={closeUploadCsvModal} disableBackdropClick>
        <Div>
          <div className="modal-container">
            <div className="modal-header">{t('csv_upload')}</div>
            <InputFile error={errorUpload} file={fileCsv} onChange={onChangeFile} />
            {errorUpload && <div className="error">{t('please_select_file')}</div>}
            <a
              href="https://2bsimplesystem.s3.ap-southeast-1.amazonaws.com/material_csv_a548ede1af.csv"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('download_example_csv')}
            </a>
            <div className="dialog-button">
              <ButtonOutline onClick={closeUploadCsvModal}>{t('cancel')}</ButtonOutline>
              <Button onClick={uploadCsv}>{t('click_to_upload')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
      <Modal open={Boolean(uploadCsvSuccessModal)} onClose={closeUploadCsvSuccessModal} disableBackdropClick>
        <Div>
          <div className="modal-container">
            <div className="modal-header">{t('csv_upload_success')}</div>
            <Table columns={csvColumn} data={csvData} />
            <div className="dialog-button">
              <ButtonOutline onClick={closeUploadCsvSuccessModal}>{t('cancel')}</ButtonOutline>
              <Button onClick={onSubmitUploadCsv}>{t('click_to_upload')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
      <Modal open={Boolean(noteModal)} onClose={() => setNoteModal(false)} disableBackdropClick>
        <Div>
          <div className="modal-container">
            <div className="modal-header">{t('note')}</div>
            <div className="mb-1-rem">
              <Table columns={noteColumn} data={notes} />
            </div>
            <textarea
              className={`text-area mb-1-rem ${isSubmitNote && note == '' ? 'error' : ''}`}
              value={note}
              onChange={(e) => setNote(e.target.value.substring(0, 275))}
            ></textarea>
            {isSubmitNote && note == '' && <div className="error">{t('please_enter_a_message')}</div>}
            <div>{limitChar} charater left</div>
            <div className="dialog-button">
              <ButtonOutline onClick={() => setNoteModal(false)}>{t('cancel')}</ButtonOutline>
              <Button onClick={onSubmitNote}>{t('submit')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
      <Modal open={Boolean(errorAlertModal)} onClose={() => setErrorAlertModal(false)} disableBackdropClick>
        <Div>
          <div className="modal-container">
            <div className="modal-header">
              {t('not_format_upload')}
            </div>
            <div className="dialog-button-2">
              <Button onClick={() => setErrorAlertModal(false)}>{t('close')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
    </Div>
  )
}

export default Material
