import { IconButton, Tooltip } from '@mui/joy'
import { Drawer, Stack, useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BsPip } from 'react-icons/bs'
import { CgArrowsExpandLeft, CgClose } from 'react-icons/cg'

import Typography from 'components/common/Typography'

function SidePanelV2(props) {
  const { t } = useTranslation()
  const desktopScreen = useMediaQuery('(min-width: 768px)')
  const headerRef = useRef(null)
  const footerRef = useRef(null)
  const [fullscreen, setFullscreen] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [footerHeight, setFooterHeight] = useState(0)
  const { open, onClose, children, titleText, supportingText, maxDrawerWidth, footerEl, sxBody } = props

  useEffect(() => {
    if (headerRef.current && footerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight)
      setFooterHeight(footerRef.current.offsetHeight)
    }
  }, [headerRef.current?.offsetHeight, footerRef.current?.offsetHeight])

  const drawerProps = {
    anchor: desktopScreen ? 'right' : 'bottom',
    variant: 'temporary',
    ModalProps: {
      keepMounted: false,
    },
  }

  useEffect(() => {
    if (!open) {
      setFullscreen(false)
    }
  }, [open])

  return (
    <MuiDrawer {...drawerProps} $fullscreen={fullscreen} maxDrawerWidth={maxDrawerWidth} open={open} onClose={onClose}>
      <HeaderWrapper id="panel-v2-header" ref={headerRef}>
        <HeaderContent>
          {typeof titleText === 'string' ? <Typography variant="sidePanelTitle">{titleText}</Typography> : titleText}

          {typeof supportingText === 'string' ? (
            <Typography variant="sidePanelSubtitle">{supportingText}</Typography>
          ) : (
            supportingText
          )}
        </HeaderContent>

        <HeaderIconContainer>
          {desktopScreen && (
            <>
              {fullscreen ? (
                <Tooltip title={t('exit_fullscreen')}>
                  <IconButton aria-label="shrink" onClick={() => setFullscreen(false)}>
                    <BsPip />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title={t('expand_fullscreen')}>
                  <IconButton aria-label="expand" onClick={() => setFullscreen(true)}>
                    <CgArrowsExpandLeft />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
          <Tooltip title="Close">
            <IconButton aria-label="close" onClick={onClose}>
              <CgClose />
            </IconButton>
          </Tooltip>
        </HeaderIconContainer>
      </HeaderWrapper>

      <Body
        id="panel-v2-body"
        sx={{ ...sxBody }}
        marginBottom={footerHeight}
        height={`calc(100vh - ${headerHeight}px)`}
      >
        {children}
      </Body>

      <Footer id="panel-v2-footer" ref={footerRef}>
        {footerEl}
      </Footer>
    </MuiDrawer>
  )
}

export default SidePanelV2

// BUG: Animation not working (Resolved ✅)
const MuiDrawer = styled(Drawer)`
  & .MuiDrawer-paper {
    min-height: 100vh;
    min-width: 100vw;
    width: ${(props) => (props.$fullscreen ? '100%' : '0%')};
    overflow-y: hidden;
    transition: all 0.3s ease-in-out !important;

    @media (min-width: 768px) {
      min-width: 768px;
      max-width: ${(props) => (props.$fullscreen ? '100%' : '768px')};
    }

    @media (min-width: 1024px) {
      min-width: 1024px;
      max-width: ${(props) => (props.$fullscreen ? '100%' : '1024px')};
    }
  }
`

const HeaderWrapper = styled('header')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky; /* for browser compatibility */
  position: sticky;
  top: 0;

  border-bottom: 1px solid var(--Gray-200);
  padding: 1.5rem 1rem;
  background-color: var(--Base-White);
  z-index: 999;

  @media screen and (min-width: 768px) {
    padding: 1.5rem 1.25rem;
  }
`

const HeaderContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '.5rem',
})

const HeaderIconContainer = styled('div')({
  display: 'flex',
  alignItems: 'start',
  height: '100%',
  width: 'fit-conntent',
})

const Body = styled(Stack)`
  height: ${(props) => props.height};
  overflow-y: auto;
  width: 100%;
  margin-bottom: ${(props) => props.marginBottom}px;
  position: relative;

  &::-webkit-scrollbar {
    width: '0.25rem';
    height: '0.25rem';

    &-thumb {
      background-color: 'var(--Gray-600)';
      border-radius: '0.5rem';
    }
  }
`

const Footer = styled('footer')`
  display: flex;
  background-color: var(--Base-White);
  border-top: 1px solid var(--Gray-200);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
`
