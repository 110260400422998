import { OP } from 'redux/actionTypes'

import { initPagination } from 'constants/pagination'
import { deleteAPI, getAPI, postAPI, putAPI } from 'utils/api'
import { formatDate } from 'utils/dateHelpers'
import { hideLoading, showLoading } from '../loading'

const { GET, POST, PUT, DELETE, CLEAR } = OP

const pathname = '/api/op-task-temps'

export default function OPTaskTemp({ headers, company_info, employee_info }) {
  const getTaskTemp = (taskTempId) => ({
    list: (payload) => {
      const { page, pageSize } = payload?.pagination || initPagination
      const { REQUEST, SUCCESS, FAILURE } = GET.TASK_TEMP_LIST

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            sort: ['id:desc'],
            pagination: { page, pageSize },
            filters: {
              company: {
                id: company_info?.id,
              },
              ...payload?.filters,
            },
            populate: {
              department: {
                fields: ['name'],
              },
            },
          }

          const response = await getAPI({
            url: `${pathname}`,
            headers,
            params,
          })

          const updatedData = response?.data?.map((item) => {
            const updatedAt = formatDate(item?.updatedAt)

            return { ...item, updatedAt }
          })

          return dispatch({ type: SUCCESS, ...response, data: updatedData })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    byId: () => {
      const { REQUEST, SUCCESS, FAILURE } = GET.TASK_TEMP_BY_ID

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            populate: {
              department: {
                fields: ['name'],
              },
              deliveries: true,
            },
          }

          const response = await getAPI({
            url: `${pathname}/${taskTempId}`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    logs: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.TASK_TEMP_BY_ID_LOGS
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            pagination: { page, pageSize },
            sort: ['id:desc'],
          }

          const response = await getAPI({
            url: `${pathname}/${taskTempId}/logs`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const postTaskTemp = (taskTempId) => ({
    create: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.TASK_TEMP

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `${pathname}`,
            headers,
            data: {
              ...payload,
              company: {
                id: company_info?.id,
              },
            },
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    active: () => {
      const { REQUEST, SUCCESS, FAILURE } = POST.TASK_TEMP_ID_ACTIVE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `${pathname}/${taskTempId}/active`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    inactive: () => {
      const { REQUEST, SUCCESS, FAILURE } = POST.TASK_TEMP_ID_INACTIVE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `${pathname}/${taskTempId}/inactive`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const putTaskTemp = (taskTempId) => ({
    update: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = PUT.TASK_TEMP

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await putAPI({
            url: `${pathname}/${taskTempId}`,
            headers,
            data: { ...payload },
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const deleteTaskTemp = (taskTempId) => {
    const { REQUEST, SUCCESS, FAILURE } = DELETE.TASK_TEMP

    return async (dispatch) => {
      dispatch(showLoading())
      dispatch({ type: REQUEST })

      try {
        const response = await deleteAPI({
          url: `${pathname}/${taskTempId}`,
          headers,
        })

        return dispatch({ type: SUCCESS, ...response })
      } catch (error) {
        return dispatch({ type: FAILURE, error })
      } finally {
        dispatch(hideLoading())
      }
    }
  }

  const clearTaskTemp = () => {
    return (dispatch) => dispatch({ type: CLEAR.TASK_TEMP })
  }

  return {
    getTaskTemp,
    postTaskTemp,
    putTaskTemp,
    deleteTaskTemp,
    clearTaskTemp,
  }
}
