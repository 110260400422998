import SidePanel from 'components/common/SidePanel'
import SignatureListView from 'components/feature/document-setting/SignatureListView'
import DocumentRequestApprove from 'components/widget/combo/DocumentRequestApprove'
import DocumentShareAndSendEmail from 'components/widget/combo/DoucumentShareAndSendEmail'
import ModalBox from 'components/widget/element/ModalBox'
import IconXClose from 'components/widget/icon/IconXClose'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { useRef, useState } from 'react'
import { pdfjs } from 'react-pdf'
import { useDispatch } from 'react-redux'
import { getBusinessDocumentList } from 'redux/actions/document'
import styled from 'styled-components'
import Button from '../../../../components/form/button/Button'
import ButtonOutline from '../../../../components/form/button/ButtonOutline'
import ViewByType from './ViewByType'

export default function DialogDoc({
  docType = 'business_doc',
  previewType = 'preview',
  headerList = [],
  bodyList = [],
  footerList = [],
  onCloseClicked,
  companyDocData,
  docId,
  docTitle,
  copyNumber = 1,
  isBindingDataDone,
  onDownloaded,
  currentPage,
  filter,
  itemSelected,
  rubberStamp,
  signatureList,
}) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  const pdfRef = useRef([])
  const dispatch = useDispatch()

  const [previewTypeCurrent, setPreviewTypeCurrent] = useState(previewType)
  const [isHideModalShare, setIsHideModalShare] = useState(false)

  const handleDownloadPdf = async () => {
    if (previewTypeCurrent == 'download_pdf' && pdfRef && docId && isBindingDataDone) {
      setTimeout(async () => {
        const element = pdfRef.current
        if (element) {
          const pdf = new jsPDF()

          for (let i = 0; i < copyNumber; i++) {
            if (i > 0) {
              pdf.addPage()
            }

            const canvas = await html2canvas(element[i], {
              logging: true,
              letterRendering: 1,
              allowTaint: false,
              useCORS: true,
            })

            const data = canvas.toDataURL('image/png')

            const imgProperties = pdf.getImageProperties(data)
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width

            pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight)
          }

          pdf.save((itemSelected?.doc_id || '') + '.pdf')
          if (onDownloaded) {
            onDownloaded()
          }
        }
      }, 800)
    }
  }

  const handleClickBtnPrint = () => {
    window.print()
  }
  const createHeaderView = (list) => {
    return (
      <>
        <div className="header_wrp">
          <div className={'top_header'}>
            <div className={'top_header_left'}>
              {companyDocData?.seller_logo && (
                <div className={'company_logo'}>
                  <img src={companyDocData.seller_logo} />
                </div>
              )}
            </div>
            <div className={'top_header_right'}>
              <div className={'type_bill'}>{docTitle ? docTitle : ''}</div>
            </div>
          </div>
          <div className={'bottom_header'}>
            <div className="column left">
              {/* {list[0]?.map((rowItem, index) => (
            <div key={'row_item_' + (index + 1)} className={'row row_' + (index + 1)}>
              {createLeftRightColumnViews(rowItem, 'header_left')}
            </div>
          ))} */}
              {companyDocData && (
                <div className={'company_doc_wrp'}>
                  {companyDocData?.seller_company_name && (
                    <div className={'from_text'}>{companyDocData.seller_company_name}</div>
                  )}

                  {companyDocData?.seller_address && (
                    <div className={'from_text'}>{companyDocData?.seller_address}</div>
                  )}

                  {companyDocData?.seller_tax_id && (
                    <div className={'seller_tax_id'}>
                      <div className={'seller_tax_id_wrp'}>
                        <div className={'seller_tax_id_title'}>เลขประจำตัวผู้เสียภาษี</div>
                        <div className={'seller_tax_id_text'}>{companyDocData.seller_tax_id}</div>
                      </div>
                    </div>
                  )}

                  {companyDocData?.customer_company_name && (
                    <div className={'to_detail'}>
                      <div className={'to_header'}>
                        {companyDocData?.title ? companyDocData.header_title : 'รายละเอียด'}
                      </div>

                      {companyDocData?.customer_company_id && (
                        <div className={'customer_company_id_wrp'}>
                          <div className={'to_header'}>รหัสลูกค้า</div>
                          <div className={'to_text'}>{companyDocData.customer_company_id}</div>
                        </div>
                      )}

                      <div className={'to_text'}>{companyDocData.customer_company_name}</div>

                      {companyDocData?.customer_address && (
                        <div className={'to_text'}>{companyDocData.customer_address}</div>
                      )}

                      {companyDocData?.customer_phone && (
                        <div className={'customer_phone_wrp'}>
                          <div className={'to_header'}>เบอร์โทรศัพท์</div>
                          <div className={'to_text'}>{companyDocData.customer_phone}</div>
                        </div>
                      )}

                      {companyDocData?.sale_area && (
                        <div className={'sale_area_wrp'}>
                          <div className={'to_header'}>เขตการขาย</div>
                          <div className={'to_text'}>{companyDocData.sale_area}</div>
                        </div>
                      )}

                      {companyDocData?.code_sale && (
                        <div className={'code_sale_wrp'}>
                          <div className={'to_header'}>code sale</div>
                          <div className={'to_text'}>{companyDocData.code_sale}</div>
                        </div>
                      )}

                      {companyDocData?.customer_tax_id && (
                        <div className={'customer_tax_id_wrp'}>
                          <div className={'to_header'}>เลขผู้เสียภาษี</div>
                          <div className={'to_text'}>{companyDocData.customer_tax_id}</div>
                        </div>
                      )}
                    </div>
                  )}
                  {companyDocData?.payment_detail && (
                    <div className={'to_detail'}>
                      <div className={'to_header'}>จ่ายให้</div>
                      <div className={'to_text'}>{companyDocData.payment_detail}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="column right">
              <div className={'row row_0 doc_right'}>
                <div className={'view_item'}>
                  <div className={'inner_row_left'}>เลขที่</div>
                  <div className={'inner_row_right'}>{companyDocData?.doc_id || 'GI-20000'}</div>
                </div>
              </div>
              {list[1]?.map((rowItem, index) => (
                <div
                  key={'row_item_' + (index + 1)}
                  className={'doc_right row row_' + (index + 1) + (rowItem?.is_hidden ? ' hidden' : '')}
                >
                  <ViewByType isPreview={true} key={rowItem.id} dataItem={rowItem} layoutType={'header_right'} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    )
  }

  const createBodyView = (list) => {
    return (
      <div className={'body'}>
        {list?.map((bodyRowItem, bodyRowIndex) => (
          <div key={'body_row_item_' + bodyRowIndex} className="body_row_item">
            {bodyRowItem?.map((bodyColumnItem) => (
              <ViewByType isPreview={true} key={bodyColumnItem.id} dataItem={bodyColumnItem} />
            ))}
          </div>
        ))}
      </div>
    )
  }

  const createFooterView = (list) => {
    return <div className="footer">{createLeftRightColumnViews(list, 'footer')}</div>
  }

  const createLeftRightColumnViews = (list, sectionType) => {
    let leftList = []
    let rightList = []

    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      if (item?.position?.includes('left')) {
        leftList.push(item)
      } else if (item?.position?.includes('right')) {
        rightList.push(item)
      }
    }

    return (
      <>
        {leftList?.length > 0 && (
          <div className={'row_inner left'}>
            {leftList?.map((item) => (
              <ViewByType isPreview={true} key={item.id} dataItem={item} />
            ))}
          </div>
        )}
        {rightList?.length > 0 && (
          <div className={'row_inner right'}>
            {rightList?.map((item) => (
              <ViewByType isPreview={true} key={item.id} dataItem={item} />
            ))}
          </div>
        )}
      </>
    )
  }

  const getDocView = () => {
    return (
      <div className={'preview_document_wrap ' + docType}>
        {createHeaderView(headerList)}
        {createBodyView(bodyList)}
        {createFooterView(footerList)}
      </div>
    )
  }

  const handleClickClosed = () => {
    if (onCloseClicked) {
      if (isHideModalShare) {
        setPreviewTypeCurrent('share')
        setIsHideModalShare(false)
      } else {
        onCloseClicked()
      }
    }
  }

  const handleClickSubmitRequestApproval = () => {
    dispatch(getBusinessDocumentList({ page: currentPage, pageSize: 10 }, filter))
    if (onCloseClicked) {
      onCloseClicked()
    }
  }

  const handleClickedPreview = () => {
    setIsHideModalShare(true)
    setPreviewTypeCurrent('preview')
  }

  if (!isBindingDataDone && !previewTypeCurrent == 'download_pdf') {
    return (
      <Styled>
        <div className="preview_outer">
          <div className="inner_preview">
            <div className="wrp-btn-close">
              <div className="btn-close" onClick={handleClickClosed}>
                <IconXClose />
              </div>
            </div>
          </div>
        </div>
      </Styled>
    )
  }

  const css = `
  @page {
    size: A4;
    margin: 0 !important;
    border: 1px solid black;
  }
  @media print {
    html, body {
      /* width: 210mm; */
      height: 297mm;
    }
    .content_item{
      border-bottom: 1px solid transparent !important;
    }
    header,.header_text,.side-menu,.header_text,.wrp-tab-menu,.filter-button,.wrp-table,html body #root .main-content > .footer,.footer_layout,.wrp-btn-close,aside,.wrp-main-page .header,footer{
      display: none !important;
      visibility: hidden;
    }
    .erp_body,.erp_body_content{
      padding: 0 !important; 
      margin: 0 !important;
      margin-bottom: 0 !important;
    }
    body .main-content{
      padding: 0 !important;
    }
    body .content-block{
      margin-bottom: 0 !important;
    }
    .dialog_doc {
      position: relative;
      overflow-y: auto;
      visibility: visible;
      background-color: transparent;
      display: block;
    }
  } 
`

  return (
    <Styled
      className={'dialog_doc ' + docType + (previewTypeCurrent == 'download_pdf' ? ' download_pdf_modal' : '')}
      previewType={previewTypeCurrent}
    >
      <style>{css}</style>
      {(previewTypeCurrent == 'preview' ||
        previewTypeCurrent == 'print' ||
        previewTypeCurrent == 'download_pdf' ||
        previewTypeCurrent == 'preview_create') && (
        <div className={'preview_outer' + (previewTypeCurrent == 'download_pdf' ? ' download_pdf_wrp' : '')}>
          <div className={'preview_wrp'}>
            <div className="inner_preview">
              {(previewTypeCurrent == 'preview_create' || previewTypeCurrent == 'preview') && (
                <div className="wrp-btn-close">
                  <div className="btn-close" onClick={handleClickClosed}>
                    <IconXClose />
                  </div>
                </div>
              )}

              <div className={'print_area'}>
                {[...Array(copyNumber)].map((elementInArray, index) => (
                  <div
                    className={'content_item' + (previewTypeCurrent == 'download_pdf' ? ' download_pdf_styled' : '')}
                    ref={(element) => {
                      pdfRef.current[index] = element
                    }}
                    size="A4"
                    key={'pdf' + index}
                  >
                    <div className="number_of_copy_bill">{index + 1 + '/' + copyNumber}</div>

                    {getDocView()}
                    <div className={'wrp_signature'}>
                      <SignatureListView rubberStamp={rubberStamp} signatureList={signatureList} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {previewTypeCurrent == 'request_approve' && (
        <SidePanel isOpen={previewTypeCurrent == 'request_approve'} onClose={handleClickClosed} width={900}>
          <DocumentRequestApprove
            docType={docType}
            id={docId}
            onClickNavigationBack={handleClickClosed}
            onClickBtnSubmit={() => handleClickSubmitRequestApproval()}
          />
        </SidePanel>
      )}
      {previewTypeCurrent == 'download_pdf' && (
        <ModalBox
          className={'wrp-modal'}
          onClosed={handleClickClosed}
          height="200px"
          width="450px"
          title={'Download ' + itemSelected?.doc_id}
          body={<div className="modal_body">{docTitle}</div>}
          footer={
            <div className="bottom">
              <ButtonOutline className={'footer-btn btn-cancel'} onClick={handleClickClosed}>
                Cancel
              </ButtonOutline>
              <Button className={'footer-btn btn-download'} onClick={handleDownloadPdf}>
                Download
              </Button>
            </div>
          }
        />
      )}
      {previewTypeCurrent == 'print' && (
        <div className="footer_layout">
          <ButtonOutline onClick={handleClickClosed}>Cancel</ButtonOutline>
          <Button className={'btn-print'} onClick={handleClickBtnPrint}>
            Print
          </Button>
        </div>
      )}
      {previewTypeCurrent == 'share' && (
        <div className="document-share-and-send-email">
          <DocumentShareAndSendEmail
            type={docType}
            data={itemSelected}
            onClickPreview={() => handleClickedPreview()}
            onClose={handleClickClosed}
          />
        </div>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  z-index: 999;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);

  display: flex;
  flex-direction: column;
  &.download_pdf_modal {
    background-color: transparent;
  }
  .content_item {
    border-bottom: 1px solid var(--Gray-600);
    padding: 0 24px;
    /* min-height: 1280px; */
    display: flex;
    flex-direction: column;
    page-break-after: always;
    min-height: 200mm !important;
  }
  .preview_document_wrap {
    flex: 1;
  }

  .preview_outer {
    flex: 1;
    position: relative;
    display: flex;
    overflow-y: auto;

    .preview_wrp {
      margin: 0 auto;
    }
    .inner_preview {
      background-color: #fff;
      width: 210mm;
    }
  }

  .wrp-download-status {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer_layout {
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    border-top: 1px solid var(--Gray-200);
    padding: 16px 24px;
    .btn-print {
      margin-left: 12px;
    }
  }
  /* layout css */
  .header_wrp,
  .footer {
    display: flex;
    flex-direction: row;
  }
  .column.left,
  .footer .left {
    flex: 2;
  }
  .column.right,
  .footer .right {
    flex: 1;
  }

  .header_wrp {
    position: relative;
    padding-top: 20px;

    .row {
      display: flex;
      justify-content: flex-start;
    }
    .row_inner {
      margin-right: 20px;
      min-width: 300px;
      .input_text .title,
      .input_text_area_wrp .title {
        margin-bottom: 6px;
      }
    }

    .view_item .text_field,
    .view_item .input_text_area,
    .view_item .input_search {
      width: 100%;
    }
    .view_item .input_text_area {
      height: 135px;
      border-color: var(--Gray-300);
      border-width: 1px;
    }

    .column.right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .row {
        min-width: 300px;
      }
      .view_item {
        width: 100%;
      }
      .view_item .title {
        margin-bottom: 6px;
      }
      .view_item .dropdown_select {
        width: 100%;
      }
      .view_item .wrp-search-input,
      .view_item ul {
        width: 100%;
      }
    }
  }

  .body {
    padding-top: 20px;
    border-top: 1px solid var(--Gray-200);
    .body_row_item {
      display: flex;
      /* border-bottom: 1px solid #eee; */
      margin-bottom: 24px;
    }
    .body_column_item {
      margin-right: 20px;
    }
  }

  .footer {
    .row_inner.left {
      margin-right: 20px;

      .view_item {
        .input_text_area_wrp {
          .title {
            margin-bottom: 6px;
          }
          .input_text_area {
            width: 700px;
            height: 112px;
            border: 1px solid var(--Gray-200);
          }
        }
      }
    }
    .row_inner.right {
      .view_item {
        .wrp_calculate {
          .row {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  .width_100 {
    width: 100%;
  }
  .margin_right_10px {
    margin-right: 10ppx;
  }
  .hidden {
    display: none !important;
  }

  //Business Doc Style
  .wrp-btn-close {
    display: flex;
    justify-content: flex-end;
  }
  .btn-close {
    padding: 10px;
    cursor: pointer;
  }

  .header_wrp {
    display: flex;
    flex-direction: column;
  }
  .top_header {
    display: flex;
    justify-content: space-between;
  }
  .bottom_header {
    display: flex;
    flex-direction: row;
  }

  .company_logo {
    height: 100px;
    img {
      max-height: 100%;
    }
  }
  .type_bill {
    color: var(--Primary-700);
    font-weight: bold;
    font-size: 24px;
  }

  .company_doc_wrp {
    margin-right: 50px;
  }

  .to_detail > .to_header {
    background-color: var(--Primary-700);
    max-width: 228px;
    color: white;
    padding: 0 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .seller_tax_id_wrp,
  .customer_tax_id_wrp,
  .customer_company_id_wrp,
  .customer_phone_wrp,
  .sale_area_wrp,
  .code_sale_wrp {
    display: flex;
    .to_header {
      margin-right: 8px;
    }
  }
  .seller_tax_id_title,
  .customer_tax_id_title {
    margin-right: 6px;
  }

  .to_detail {
    margin-top: 18px;
  }

  .doc_right .view_item {
    display: flex;
    justify-content: space-between !important;
  }
  .body {
    border: none;
  }
  .footer {
    border: none;
  }

  .wrp_table_preview {
    width: 100%;
    border-radius: 0;
    border: none !important;
    th {
      background: var(--Primary-700) !important;
      color: #ffffff;
      text-align: center;
      padding: 4px;
    }
    .width_300px {
      width: 300px;
    }
    .width_80 {
      width: 80%;
    }
    th.width_10 {
      width: 10%;
      .view_item {
        width: 100%;
      }
    }
    th.width_20 {
      width: 20%;
      .view_item {
        width: 100%;
      }
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 0 !important;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 0 !important;
    }

    td {
      /* border-collapse: collapse; */
      border: 1px solid var(--Gray-200);
      padding: 4px;
    }
    tr {
      .td {
        text-align: center;
      }
      .td .width_300px {
        text-align: start;
      }
      .td .width_80 {
        text-align: start;
      }
    }
  }

  .text_align_left {
    text-align: left;
  }
  .padding_left_right_10px {
    padding: 0 10px;
  }

  canvas {
    width: 100% !important;
    height: auto !important;
  }
  * {
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }

  .download_pdf_wrp {
    position: absolute;
    top: -2000000000px;
  }

  .download_pdf_styled {
    padding: 24px !important;

    break-before: auto;
    break-before: avoid;
    break-before: always;
    break-before: all;
  }

  .number_of_copy_bill {
    text-align: end;
    margin-top: 8px;
  }
`
