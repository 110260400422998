import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_MeetingAttendee,
  GET_NOTIFICATIONS_LIST_ALL,
  GET_NOTIFICATIONS_READE_ALL,
  GET_NOTIFICATIONS_UNREADE_ALL,
  UPDATE_NOTIFICATIONS,
  SET_IS_REFRESH_NOTI,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getNotificationsList =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_NOTIFICATIONS_LIST_ALL.REQUEST })
      const { jwt, employee_info } = JSON.parse(localStorage.getItem('admin'))

      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let params = {
        'filters[owner]': employee_info?.id ? employee_info?.id : -99,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
      }

      const { data, meta } = await getAPI({
        url: '/api/notifications?sort[id]=desc',
        headers: headers,
        params,
      })

      // return dispatch({
      //   type: GET_NOTIFICATIONS_LIST_ALL.SUCCESS,
      //   data,
      //   meta,
      // })
      return {
        type: GET_NOTIFICATIONS_LIST_ALL.SUCCESS,
        data,
        meta,
      }
    } catch (error) {
      return dispatch({ type: GET_NOTIFICATIONS_LIST_ALL.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getNotificationsReade =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_NOTIFICATIONS_READE_ALL.REQUEST })
      const { jwt, employee_info } = JSON.parse(localStorage.getItem('admin'))

      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let params = {
        'filters[owner]': employee_info?.id ? employee_info?.id : -99,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
      }

      const { data, meta } = await getAPI({
        url: '/api/notifications?filters[is_read]=true&sort[id]=desc',
        headers: headers,
        params,
      })

      // return dispatch({
      //   type: GET_NOTIFICATIONS_READE_ALL.SUCCESS,
      //   data,
      //   meta,
      // })
      return {
        type: GET_NOTIFICATIONS_READE_ALL.SUCCESS,
        data,
        meta,
      }
    } catch (error) {
      return dispatch({ type: GET_NOTIFICATIONS_READE_ALL.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getNotificationsUnReade =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_NOTIFICATIONS_UNREADE_ALL.REQUEST })
      const { jwt, employee_info } = JSON.parse(localStorage.getItem('admin'))

      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let params = {
        'filters[owner]': employee_info?.id ? employee_info?.id : -99,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
      }

      const { data, meta } = await getAPI({
        url: '/api/notifications?filters[is_read]=false&sort[id]=desc&filters[status]=active',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_NOTIFICATIONS_UNREADE_ALL.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({ type: GET_NOTIFICATIONS_UNREADE_ALL.FAILURE, error })
    } finally {
      dispatch(setIsRefreshNoti(false))
      dispatch(hideLoading())
    }
  }

export const updateNotificationsList = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_NOTIFICATIONS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/notifications/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_NOTIFICATIONS.SUCCESS,
    })
  } catch (error) {
    return dispatch({ type: UPDATE_NOTIFICATIONS.FAILURE, error: error })
  } finally {
    dispatch(getNotificationsUnReade())
    dispatch(hideLoading())
  }
}

export const updateNotifications = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_NOTIFICATIONS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/notifications/${id}`,
      data,
      headers: headers,
    })

    return { type: 'updateNotifications_SUCCESS' }
  } catch (error) {
    return { type: 'updateNotifications_ERROR' }
  } finally {
    dispatch(getNotificationsUnReade())
    dispatch(hideLoading())
  }
}

export const getNotificationsReturnData =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_NOTIFICATIONS_LIST_ALL.REQUEST })
      const { jwt, user } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
      }

      if (filter.owner) {
        params = { ...params, 'filters[owner]': filter.owner }
      }
      if (filter.is_read) {
        if (filter.is_read === 'read') {
          params = { ...params, 'filters[is_read]': true }
        } else if (filter.is_read === 'unread') {
          params = { ...params, 'filters[is_read]': false }
        }
      }

      const { data, meta } = await getAPI({
        url: '/api/notifications?sort[id]=desc&filters[status]=active',
        headers: headers,
        params,
      })
      return { type: 'getNotificationsReturnData_SUCCESS', data_info: data, data_meta: meta }
    } catch (error) {
      return { type: 'getNotificationsReturnData_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

export const getHomeReturnData =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_NOTIFICATIONS_LIST_ALL.REQUEST })
      const { jwt, user } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let params = {
        skip: filter.skip,
        limit: filter.limit,
      }

      if (filter.owner) {
        params = { ...params, 'filters[owner]': filter.owner }
      }

      if (filter.tab) {
        params = { ...params, 'filters[tab]': filter.tab }
      }

      if (filter.name) {
        params = { ...params, 'filters[name]': filter.name }
      }

      if (filter.project) {
        params = { ...params, 'filters[project]': filter.project }
      }

      if (filter.milestone) {
        params = { ...params, 'filters[milestone]': filter.milestone }
      }

      if (filter.scope_of_work) {
        params = { ...params, 'filters[scope_of_work]': filter.scope_of_work }
      }

      if (filter.status) {
        params = { ...params, 'filters[status]': filter.status }
      }

      if (filter.create_by) {
        params = { ...params, 'filters[create_by]': filter.create_by }
      }

      const { data } = await getAPI({
        url: '/api/home',
        headers: headers,
        params,
      })

      return { type: 'getHomeReturnData_SUCCESS', data_info: data }
    } catch (error) {
      return { type: 'getHomeReturnData_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

export const setIsRefreshNoti = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_REFRESH_NOTI,
    data,
  })
}
