import { Avatar, Stack } from '@mui/material'
import { styled } from '@mui/system'

const NoteItem = ({ data, ...props }) => {
  const { message, createdAt, updatedAt, created_by, employee } = data || {}
  const { employee_profile_img } = employee || {}

  return (
    <NoteWrapper {...props}>
      <div className="header">
        <Avatar sx={{ width: 24, height: 24 }} src={employee_profile_img} />
        <div className="meta">
          <span className="author">{created_by}</span>
          <div className="date">{createdAt}</div>
        </div>
      </div>

      <div className="body-container">
        <div className="text-content">
          <p>{message}</p>
        </div>
      </div>
    </NoteWrapper>
  )
}

export default NoteItem

const NoteWrapper = styled(Stack)`
  border-radius: 8px;
  border: 1px solid var(--Gray-200);
  margin-bottom: 20px;

  & div.header {
    display: flex;
    vertical-align: baseline;
    padding: 24px 20px 8px 20px;
    min-width: 0;
    gap: 0.5rem;
    align-items: center;

    & > div.meta {
      display: flex;
      flex-grow: 1;
      min-height: 1rem;

      & > span.author {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.14;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        transition: none;
        color: var(--Gray-700);
      }

      & > div.date {
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex-shrink: 0;
        color: var(--Gray-400);
        margin: 0 0 0 auto;
      }
    }
  }

  & div.body-container {
    padding: 4px 20px 20px 20px;
    display: block;
    min-width: 0;

    & div.text-content {
      & > p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: var(--Gray-700);
        word-break: break-word;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;

        min-height: 20px;
      }
    }
  }
`
