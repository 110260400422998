import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import SidePanel from '../../../components/common/SidePanel'
import Table from '../../../components/common/Table'
import DialogFail from '../../../components/dialog/DialogFail'
import Checkbox from '../../../components/form/Checkbox'
import Date from '../../../components/form/Date'
import TextField from '../../../components/form/TextField'
import Button from '../../../components/form/button/Button'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import DocumentRequestApprove from '../../../components/widget/combo/DocumentRequestApprove'
import DocumentShareAndSendEmail from '../../../components/widget/combo/DoucumentShareAndSendEmail'
import SearchSuggestCustomer from '../../../components/widget/combo/SearchSuggestCustomer'
import SearchSuggestSellerEmployee from '../../../components/widget/combo/SearchSuggestSellerEmployee'
import DocumentOptionButton from '../../../components/widget/element/DocumentOptionButton'
import InputTextArea from '../../../components/widget/element/InputTextArea'
import ModalBox from '../../../components/widget/element/ModalBox'
import IconBin from '../../../components/widget/icon/IconBin'
import {
  createCustomer,
  createSaleDocument,
  editSaleDocument,
  getDocumentSettingByCompanyId,
  getSaleDocumentById,
} from '../../../redux/actions/document'
import { selectDocument } from '../../../redux/selectors'
import {
  calculateDiscount,
  calculatePriceAfterTax,
  calculateTotalPrice,
  removeArrayItemByIndex,
} from '../../../utils/helper'
import DialogDocument from '../DialogDocument'

export default function CreateDebitNote({ onCallback }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const viewType = searchParams.get('type')
  const navigateType = searchParams.get('navigate')
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))

  const TABLE_HEADER_COLUMN = [
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'รายละเอียด',
      accessor: 'product_name',
      disableSortBy: false,
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
      disableSortBy: false,
    },
    {
      Header: 'Price per unit',
      accessor: 'price',
      disableSortBy: false,
    },
    {
      Header: 'Total Price',
      accessor: 'total_price',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'delete',
      disableSortBy: false,
    },
  ]

  const DEFAULT_BODY_ITEM_DATA = {
    no: '',
    product_name: '',
    quantity: '',
    price: '',
    // discount_percent: 0,
    delete: false,
    //validate
    is_error_product_name: false,
    is_error_quantity: false,
    is_error_price: false,
  }

  const [dataCreating, setDataCreating] = useState({
    //ข้อมูลลูกค้า บนซ้าย
    id: '',
    customer_id: '',
    name: '',
    tax_id: '',
    address: '',
    office: '',

    doc_date: '',
    tax_invoice_id: '',
    tax_invoice_date: '',
    seller_employee_id: '',
    sales_territory: '',

    include_tax: false,
    details: '',

    product_list: [],

    vat: false,
    // withholding_tax: false,
    // withholding_tax_percent: 0,
    note: '',
    old_value: 0,
    discount_percent: 0,

    company_id: company_info?.id,
    doc_type: viewType,
    is_external: navigateType == 'external' ? true : false,
  })

  const [paymentTypeList, setPaymentTypeList] = useState([
    {
      text: 'Credit (day)',
      value: 'credit day and due date',
    },
    {
      text: 'Credit (not show date)',
      value: 'credit day',
    },
    {
      text: 'Cash',
      value: 'cash',
    },
  ])
  const [priceTaxSelectList, setPriceTaxSelectList] = useState([
    {
      text: 'Include Tax',
      value: true,
    },
    {
      text: 'Not Include Tax',
      value: false,
    },
  ])
  const [uomSelectList, setUomSelectList] = useState([
    {
      text: 'Piece',
      value: 'piece',
    },
    {
      text: 'Box',
      value: 'box',
    },
    {
      text: 'Pack',
      value: 'pack',
    },
  ])
  const [withHoldingTaxList, setWithHoldingTaxList] = useState([
    {
      text: '0.5%',
      value: 0.5,
    },
    {
      text: '0.75%',
      value: 0.75,
    },
    {
      text: '0.1%',
      value: 0.1,
    },
    {
      text: '1.5%',
      value: 1.5,
    },
    {
      text: '2%',
      value: 2,
    },
    {
      text: '3%',
      value: 3,
    },
    {
      text: '5%',
      value: 5,
    },
    {
      text: '10%',
      value: 10,
    },
    {
      text: '15%',
      value: 15,
    },
  ])

  const [bodyTableList, setBodyTableList] = useState([])
  const [isErrorCustomerId, setIsErrorCustomerId] = useState(false)
  const [isErrorCustomerName, setIsErrorCustomerName] = useState(false)
  const [isErrorTaxId, setIsErrorTaxId] = useState(false)

  const [validateInputData, setValidateInputData] = useState({
    is_error_customer_id: false,
    is_error_name: false,
    is_error_tax_id: false,
    is_error_address: false,
    is_error_office: false,

    // is_error_contact_person: false,
    // is_error_contact_phone: false,
    // is_error_contact_email: false,

    is_error_doc_date: false,
    is_error_tax_invoice_id: false,
    is_error_tax_invoice_date: false,
    is_error_sales_territory: false,
    is_error_seller_employee_id: false,

    is_error_details: false,
    //table
    is_error_product_list: false, // array[]
    is_error_note: false,
    ///ราคา ขวาล่าง
    is_error_discount_percent: false,
    is_error_withholding_tax_percent: false,
  })
  const [isShowFailedModal, setIsShowFailedModal] = useState(false)
  const [textCustomerId, setTextCustomerId] = useState('')
  const [textCustomerName, setTextCustomerName] = useState('')
  const [textTaxId, setTextTaxId] = useState('')

  const [sumTotalPrice, setTotalPrice] = useState(0)
  const [priceDiscount, setPriceDiscount] = useState(0)
  const [priceAfterDiscount, setPriceAfterDiscount] = useState(0)
  // const [ vatPrice, setVatPrice ] = useState(0)
  const [priceAfterVat, setPriceAfterVat] = useState(0)
  const [priceWithHoldingTax, setPriceWithHoldingTax] = useState(0)
  const [summaryPrice, setSummaryPrice] = useState(0)
  const [priceVat, setPriceVat] = useState(0) //ราคา vat

  const [isEditSaleDocument, setIsEditSaleDocument] = useState(false)
  const [currentSaleDocumentId, setCurrentSaleDocumentId] = useState('')

  const { saleDocumentData, documentSettingInfo } = useSelector(selectDocument)

  const [dialogSaleDocType, setDialogSaleDocType] = useState('')
  const [isShowDialogSaleDoc, setIsShowDialogSaleDoc] = useState(false)
  const [isShowModalDownloadSaleDoc, setIsShowModalDownloadSaleDoc] = useState(false)
  const [isShowDialogRequestApprove, setIsShowDialogRequestApprove] = useState(false)

  const [isShowModalShareSaleDoc, setIsShowModalShareSaleDoc] = useState(false)
  const [isHasCustomerId, setIsHasCustomerId] = useState(false)
  const [isHindModalShare, setIsHindModalShare] = useState(false)
  const [imageForDocument, setImageForDocument] = useState({
    url_company_logo: '',
    url_rubber_stamp: '',
    url_signature_file: '',
  })

  useEffect(() => {
    ;(async () => {
      let url = window.location.pathname
      const myArray = url.split('/')
      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i] == 'edit') {
          setIsEditSaleDocument(true)
          // let tmpId = myArray[myArray.length - 1]
          let tmpId = searchParams.get('id')
          setCurrentSaleDocumentId(tmpId)
          dispatch(getSaleDocumentById(tmpId))
        }
        if (myArray[i] == 'create') {
          setIsEditSaleDocument(false)
          // let tmpDocType = myArray[myArray.length - 1]
          // setDataCreating({ ...dataCreating, doc_type: viewType })
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (Object.keys(saleDocumentData).length > 0 && isEditSaleDocument) {
      let tmpData = {
        creator_id: employee_info.id, //employee id ของคนสร้าง doc
        customer_id: saleDocumentData?.customer?.id,
        id: saleDocumentData?.customer?.id,

        customer_name: saleDocumentData?.customer?.name,
        doc_id: saleDocumentData?.doc_id,
        seller_name: saleDocumentData?.seller_name,

        doc_date: saleDocumentData?.doc_date,
        tax_invoice_id: saleDocumentData?.tax_invoice_id,
        tax_invoice_date: saleDocumentData?.tax_invoice_date,
        sales_territory: saleDocumentData?.sales_territory,
        seller_employee_id: saleDocumentData?.seller_employee_id, //employee id ของ seller
        include_tax: saleDocumentData?.include_tax,
        details: saleDocumentData?.details,
        product_list: saleDocumentData?.product_list,
        signature: saleDocumentData?.signature,
        note: saleDocumentData?.note,
        discount_percent: saleDocumentData?.discount_percent,
        old_value: saleDocumentData?.old_value,
        vat: saleDocumentData?.vat,
        // withholding_tax_percent: saleDocumentData?.withholding_tax_percent, //หักภาษ๊ ณ ที่จ่าย
        // withholding_tax: saleDocumentData?.withholding_tax_percent > 0 ? true : false,
        company_id: company_info?.id, //company id ของคนสร้าง doc
        doc_type: saleDocumentData?.doc_type,

        //ไม่ได้ใช้ ลบออก
        address: saleDocumentData?.customer?.address,
        tax_id: saleDocumentData?.customer?.tax_id,
        office: saleDocumentData?.customer?.office,
      }

      setDataCreating(tmpData)
    }
    dispatch(getDocumentSettingByCompanyId())
  }, [saleDocumentData, isEditSaleDocument])

  useEffect(() => {
    if (dataCreating?.product_list) {
      const viewList = dataCreating.product_list.map((item, index) => ({
        no: index + 1,
        product_name: (
          <TextField
            className={'input-table'}
            placeholder={'Product Name'}
            onChange={(e) => handleChangeProductList(e, 'product_name', index)}
            value={item.product_name}
            error={item?.is_error_product_name}
          />
        ),
        quantity: (
          <TextField
            className={'input-table'}
            placeholder={'0'}
            value={convertIntComma(item?.quantity || 0)}
            // pattern={'^[0-9]*[.,]?[0-9]*$'}
            pattern={'^[0-9.-/]+$'}
            onChange={(e) => handleChangeProductList(e, 'quantity', index)}
            error={item?.is_error_quantity}
          />
        ),
        price: (
          <TextField
            className={'input-table'}
            placeholder={'0.00'}
            onChange={(e) => handleChangeProductList(e, 'price', index)}
            value={convertIntComma(item?.price || 0)}
            error={item?.is_error_price}
          />
        ),
        // discount_percent: (
        //   <TextField
        //     className={'input-table'}
        //     placeholder={'0.00'}
        //     onChange={(e) => handleChangeProductList(e, 'discount_percent', index)}
        //     value={convertIntComma(item?.discount_percent || 0)}
        //     error={item?.is_error_discount_percent}
        //   />
        // ),
        total_price: calculateItemTotalPrice(item.price, item.quantity), // OLD
        // total_price: calculateItemTotalPrice(item.price, item.quantity, item.discount_percent),
        delete: (
          <div className="btn-delete" onClick={() => handleClickBtnDelete(index)}>
            <IconBin />
          </div>
        ),
      }))
      //calculate price
      let tmpData = { ...dataCreating }
      let tmpTotal = calculateTotalPrice(tmpData.product_list) // OLD
      // let tmpTotal = calculatePurchaseTotalPrice(tmpData.product_list) // OLD
      setTotalPrice(tmpTotal)

      let { discountPrice, totalPrice } = calculateDiscount(tmpTotal, tmpData?.discount_percent)
      setPriceDiscount(discountPrice)
      setPriceAfterDiscount(totalPrice)

      if (tmpData?.include_tax) {
        let { priceAfterVat, priceVat, priceWithHoldingTax, summaryPrice } = calculatePriceAfterTax(
          true,
          tmpData?.vat,
          tmpData?.withholding_tax_percent || 0,
          totalPrice
        )
        setPriceAfterVat(priceAfterVat)
        setPriceVat(priceVat)
        // setPriceWithHoldingTax(priceWithHoldingTax)
        setSummaryPrice(summaryPrice)
      } else {
        let { priceAfterVat, priceVat, priceWithHoldingTax, summaryPrice } = calculatePriceAfterTax(
          false,
          tmpData?.vat,
          tmpData?.withholding_tax_percent || 0,
          totalPrice
        )
        setPriceAfterVat(priceAfterVat)
        setPriceVat(priceVat)
        // setPriceWithHoldingTax(priceWithHoldingTax)
        setSummaryPrice(summaryPrice)
      }

      setBodyTableList(viewList)
    }
  }, [dataCreating])

  useEffect(() => {
    if (documentSettingInfo) {
      let tmpImageForDocumentObj = { ...imageForDocument }
      let tmpDocType = dataCreating?.doc_type

      if (viewType != '') {
        tmpImageForDocumentObj.url_company_logo = documentSettingInfo[viewType]?.company_logo
        tmpImageForDocumentObj.url_rubber_stamp = documentSettingInfo[viewType]?.rubber_stamp
        // tmpImageForDocumentObj.url_signature_file = documentSettingInfo[viewType]?.signature

        let tmpDataObj = { ...dataCreating }
        tmpDataObj.signature_list = documentSettingInfo[viewType]?.signature_list
        tmpDataObj.company_logo = documentSettingInfo[viewType]?.company_logo
        tmpDataObj.rubber_stamp = documentSettingInfo[viewType]?.rubber_stamp
        setDataCreating(tmpDataObj)
      }
      setImageForDocument(tmpImageForDocumentObj)
    }
  }, [documentSettingInfo])

  const handleChangeData = (e, type) => {
    let tmpObj = { ...dataCreating }

    let tmpValidateObj = { ...validateInputData }
    //ข้อมูลลูกค้า ซ้ายบน
    if (type == 'customer_name') {
      tmpObj.name = e.target.value
    } else if (type == 'tax_id') {
      tmpObj.tax_id = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_tax_id = false
      }
    } else if (type == 'address') {
      tmpObj.address = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_address = false
      }
    } else if (type == 'office') {
      tmpObj.office = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_office = false
      }
    } else if (type == 'doc_date') {
      tmpObj.doc_date = e
      if (e) {
        tmpValidateObj.is_error_doc_date = false
      }
    } else if (type == 'tax_invoice_id') {
      tmpObj.tax_invoice_id = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_tax_invoice_id = false
      }
    } else if (type == 'tax_invoice_date') {
      tmpObj.tax_invoice_date = e
      if (e) {
        tmpValidateObj.is_error_tax_invoice_date = false
      }
    } else if (type == 'seller_employee_id') {
      tmpObj.seller_employee_id = e.id
      if (e) {
        tmpValidateObj.is_error_seller_employee_id = false
      }
    } else if (type == 'sales_territory') {
      tmpObj.sales_territory = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_sales_territory = false
      }
    } else if (type == 'details') {
      tmpObj.details = e.target.value
    } else if (type == 'note') {
      tmpObj.note = e.target.value
    } else if (type == 'old_value') {
      let tmpString = e.target.value
      let tmpInt = tmpString?.replace(/\D/g, '') || 0
      tmpObj.old_value = parseInt(tmpInt)
      if (e.target.value) {
        tmpValidateObj.is_error_old_value = false
      }
    } else if (type == 'discount_percent') {
      let tmpString = e.target.value
      let tmpDiscountPercent = parseFloat(tmpString?.replace(/\D/g, '') || 0)
      if (tmpDiscountPercent > 100) {
        tmpObj.discount_percent = 100
      } else {
        tmpObj.discount_percent = tmpDiscountPercent
      }
    } else if (type == 'vat') {
      tmpObj.vat = e

      if (!e) {
        let tmpPriceVat = (priceAfterDiscount * 7) / 100
        //   setVatPrice(tmpPriceVat)
        setPriceVat(tmpPriceVat)
      }
    } else if (type == 'withholding_tax') {
      tmpObj.withholding_tax = e
      if (!e) {
        tmpObj.withholding_tax_percent = 0
        setPriceWithHoldingTax(0)
      }
    } else if (type == 'withholding_tax_percent') {
      tmpObj.withholding_tax_percent = e
    }

    setDataCreating(tmpObj)
    setValidateInputData(tmpValidateObj)
  }
  const handleChangeProductList = (e, type, index) => {
    let tmpObj = { ...dataCreating }
    if (type == 'product_name') {
      tmpObj.product_list[index].product_name = e.target.value
      if (e.target.value != '') {
        tmpObj.product_list[index].is_error_product_name = false
      }
    } else if (type == 'quantity') {
      let tmpString = e.target.value
      // var pattern = /^\d+\.?\d*$/;

      let tmpInt = tmpString?.replace(/\D/g, '') || 0
      tmpObj.product_list[index].quantity = parseInt(tmpInt)
      if (e.target.value != '') {
        tmpObj.product_list[index].is_error_quantity = false
      }
      // } else if (type == 'uom') {
      //   tmpObj.product_list[index].uom = e
      //   if (e) {
      //     tmpObj.product_list[index].is_error_uom = false
      //   }
    } else if (type == 'price') {
      let tmpString = e.target.value
      let tmpInt = tmpString?.replace(/\D/g, '') || 0
      tmpObj.product_list[index].price = parseInt(tmpInt)
      if (e.target.value) {
        tmpObj.product_list[index].is_error_price = false
      }
    } else if (type == 'discount_percent') {
      let tmpString = e.target.value
      let tmpNumber = Number(tmpString)

      if (tmpNumber >= 100) {
        tmpString = '100.00'
      }
      if (tmpNumber < 0) {
        tmpString = '0.00'
      }

      if (tmpString.length <= 6) {
        tmpObj.product_list[index].discount_percent = parseFloat(tmpString !== '' ? tmpString : '0').toFixed(2)
        if (e.target.value) {
          tmpObj.product_list[index].is_error_discount = false
        }
      }
    }
    setDataCreating({ ...tmpObj })
  }
  const handleClickBtnDelete = (index) => {
    let tmpBodyDataList = removeArrayItemByIndex(dataCreating?.product_list, index)
    setDataCreating({ ...dataCreating, product_list: tmpBodyDataList })
  }
  const handleClickBtnAddList = () => {
    let tmpBodyDataList = [...dataCreating?.product_list, DEFAULT_BODY_ITEM_DATA]
    setDataCreating({ ...dataCreating, product_list: tmpBodyDataList })
    setValidateInputData({ ...validateInputData, is_error_product_list: false })
  }
  const handleClickBtnPreview = () => {
    let isValid = checkInputValidation()
    if (isValid) {
      setDialogSaleDocType('render_preview')
      setIsShowDialogSaleDoc(true)
    }
  }
  const handleClickBtnCreate = async () => {
    let isValid = checkInputValidation()
    if (isValid) {
      if (Object.keys(employee_info).length > 0) {
        let tmpObj = { ...dataCreating }

        tmpObj.creator_id = employee_info.id

        if (isEditSaleDocument) {
          // Edit Quotation
          let tmpProductList = [...tmpObj.product_list]
          let tmpNewList = []
          for (let i = 0; i < tmpProductList.length; i++) {
            let tmpProductItem = tmpProductList[i]
            delete tmpProductItem['no']
            delete tmpProductItem['delete']
            delete tmpProductItem['is_error_product_name']
            delete tmpProductItem['is_error_quantity']
            delete tmpProductItem['is_error_price']

            tmpNewList.push(tmpProductItem)
          }
          tmpObj.product_list = [...tmpNewList]

          delete tmpObj['id']
          delete tmpObj['customer_name']
          delete tmpObj['doc_id']
          delete tmpObj['seller_name']

          const request = { data: tmpObj }
          await dispatch(editSaleDocument(currentSaleDocumentId, request)).then((e) => {
            if (e?.data) {
              if (navigateType == 'external') {
                if (onCallback) {
                  onCallback(e?.data?.id)
                }
              } else {
                navigate('/document/sales')
              }
            } else {
              alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
            }
          })
        } else {
          ///Create Quotation
          if (isHasCustomerId) {
            tmpObj.customer_id = tmpObj.id
            let tmpProductList = [...tmpObj.product_list]
            let tmpNewList = []
            for (let i = 0; i < tmpProductList.length; i++) {
              let tmpProductItem = tmpProductList[i]
              // tmpProductItem.discount_percent = parseFloat(tmpProductItem.discount_percent.toString())
              delete tmpProductItem['no']
              delete tmpProductItem['delete']
              delete tmpProductItem['is_error_product_name']
              delete tmpProductItem['is_error_quantity']
              delete tmpProductItem['is_error_price']

              tmpNewList.push(tmpProductItem)
            }
            tmpObj.product_list = [...tmpNewList]
            delete tmpObj['name']
            delete tmpObj['office']
            delete tmpObj['address']
            delete tmpObj['withholding_tax']
            delete tmpObj['tax_id']
            delete tmpObj['id']
            // if (tmpObj.payment_type == 'cash') {
            //   delete tmpObj['credit_day']
            //   delete tmpObj['credit_due_date']
            // } else if (tmpObj.payment_type == 'credit day') {
            //   delete tmpObj['credit_due_date']
            // }

            const request = { data: tmpObj }
            await dispatch(createSaleDocument(request)).then((e) => {
              if (e?.data) {
                if (navigateType == 'external') {
                  if (onCallback) {
                    onCallback(e?.data?.id)
                  }
                } else {
                  handleGoBack()
                }
              } else {
                alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
              }
            })
          } else {
            // Create Customer
            let data = {
              customer_id: textCustomerId,
              name: textCustomerName,
              address: tmpObj.address,
              tax_id: textTaxId,
              office: tmpObj.office,
              company_id: company_info.id,
            }
            const request = { data }

            dispatch(createCustomer(request)).then((e) => {
              if (e?.data) {
                // if (Object.keys(e.data).length > 0) {
                let tmpCustomerInfo = { ...e.data }

                tmpObj.customer_id = tmpCustomerInfo.id
                tmpObj.name = tmpCustomerInfo.name
                tmpObj.address = tmpCustomerInfo.address
                tmpObj.office = tmpCustomerInfo.office
                tmpObj.tax_id = tmpCustomerInfo.tax_id

                let tmpProductList = [...tmpObj.product_list]
                let tmpNewList = []
                for (let i = 0; i < tmpProductList.length; i++) {
                  let tmpProductItem = tmpProductList[i]
                  // tmpProductItem.discount_percent = parseFloat(tmpProductItem.discount_percent.toString())

                  delete tmpProductItem['no']
                  delete tmpProductItem['delete']
                  delete tmpProductItem['is_error_product_name']
                  delete tmpProductItem['is_error_quantity']
                  delete tmpProductItem['is_error_price']
                  tmpNewList.push(tmpProductItem)
                }
                tmpObj.product_list = [...tmpNewList]
                delete tmpObj['name']
                delete tmpObj['office']
                delete tmpObj['address']
                delete tmpObj['withholding_tax']
                delete tmpObj['tax_id']
                delete tmpObj['id']
                // if (tmpObj.payment_type == 'cash') {
                //   delete tmpObj['credit_day']
                //   delete tmpObj['credit_due_date']
                // } else if (tmpObj.payment_type == 'credit day') {
                //   delete tmpObj['credit_due_date']
                // }
                let tmpRequest = { data: { ...tmpObj } }
                dispatch(createSaleDocument(tmpRequest)).then((e) => {
                  if (e?.data) {
                    if (navigateType == 'external') {
                      if (onCallback) {
                        onCallback(e?.data?.id)
                      }
                    } else {
                      handleGoBack()
                    }
                  } else {
                    alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
                  }
                })
              }
            })
          }
        }
      } else {
        setIsShowFailedModal(true)
      }
    } else {
      alert('กรอกข้อมูลให้ครบถ้วน')
    }
  }
  const checkInputValidation = () => {
    let tmpData = { ...dataCreating }
    let tmpValidateData = { ...validateInputData }
    // CHECK Valid input
    if (tmpData.customer_id != '' || textCustomerId != '') {
      tmpValidateData.is_error_customer_id = false
      setIsErrorCustomerId(false)
    } else {
      tmpValidateData.is_error_customer_id = true
      setIsErrorCustomerId(true)
    }

    if (tmpData.name != '' || textCustomerName != '') {
      tmpValidateData.is_error_name = false
      setIsErrorCustomerName(false)
    } else {
      tmpValidateData.is_error_name = true
      setIsErrorCustomerName(true)
    }

    if (tmpData.tax_id != '' || textTaxId != '') {
      tmpValidateData.is_error_tax_id = false
      setIsErrorTaxId(false)
    } else {
      tmpValidateData.is_error_tax_id = true
      setIsErrorTaxId(true)
    }

    if (tmpData.office != '') {
      tmpValidateData.is_error_office = false
    } else {
      tmpValidateData.is_error_office = true
    }

    if (tmpData.address != '') {
      tmpValidateData.is_error_address = false
    } else {
      tmpValidateData.is_error_address = true
    }

    if (tmpData.doc_date != '') {
      tmpValidateData.is_error_doc_date = false
    } else {
      tmpValidateData.is_error_doc_date = true
    }

    if (tmpData.tax_invoice_id != '') {
      tmpValidateData.is_error_tax_invoice_id = false
    } else {
      tmpValidateData.is_error_tax_invoice_id = true
    }

    if (tmpData.tax_invoice_date != '') {
      tmpValidateData.is_error_tax_invoice_date = false
    } else {
      tmpValidateData.is_error_tax_invoice_date = true
    }

    if (tmpData.sales_territory != '') {
      tmpValidateData.is_error_sales_territory = false
    } else {
      tmpValidateData.is_error_sales_territory = true
    }

    if (tmpData.seller_employee_id) {
      tmpValidateData.is_error_seller_employee_id = false
    } else {
      tmpValidateData.is_error_seller_employee_id = true
    }

    if (tmpData?.old_value > 0) {
      tmpValidateData.is_error_old_value = false
    } else {
      tmpValidateData.is_error_old_value = true
    }

    // if (tmpData.details != '') {
    //   tmpValidateData.is_error_details = false
    // } else {
    //   tmpValidateData.is_error_details = true
    // }

    // if (tmpData.note != '') {
    //   tmpValidateData.is_error_note = false
    // } else {
    //   tmpValidateData.is_error_note = true
    // }

    // if (tmpData?.discount_percent >= 0) {
    //   tmpValidateData.is_error_discount_percent = false
    // } else {
    //   tmpValidateData.is_error_discount_percent = true
    // }
    if (tmpData?.withholding_tax) {
      if (tmpData.withholding_tax_percent != '') {
        tmpValidateData.is_error_withholding_tax_percent = false
      } else {
        tmpValidateData.is_error_withholding_tax_percent = true
      }
    } else {
      tmpValidateData.is_error_withholding_tax_percent = false
    }

    //validate product list
    if (tmpData.product_list?.length > 0) {
      let tmpList = [...tmpData.product_list]
      let tmpArrayList = []
      let isErrorProductItem = false
      for (let index = 0; index < tmpList.length; index++) {
        let itemObj = tmpList[index]

        if (itemObj.product_name == '') {
          itemObj.is_error_product_name = true
          isErrorProductItem = true
        } else {
          itemObj.is_error_product_name = false
        }
        if (itemObj.quantity == '') {
          itemObj.is_error_quantity = true
          isErrorProductItem = true
        } else {
          itemObj.is_error_quantity = false
        }
        if (itemObj.price == '') {
          itemObj.is_error_price = true
          isErrorProductItem = true
        } else {
          itemObj.is_error_price = false
        }

        tmpArrayList.push(itemObj)
      }

      tmpValidateData.is_error_product_list = isErrorProductItem
      tmpData.product_list = [...tmpArrayList]
    } else {
      tmpValidateData.is_error_product_list = true
    }
    setDataCreating(tmpData)

    setValidateInputData(tmpValidateData)
    for (const [key, value] of Object.entries(tmpValidateData)) {
      if (value) {
        return false
      }
    }
    return true
  }
  const handleSelectCustomer = (value, type) => {
    let tmpObj = { ...dataCreating, ...value }
    let tmpValidateObj = { ...validateInputData }
    tmpValidateObj.is_error_customer_id = false
    tmpValidateObj.is_error_name = false
    tmpValidateObj.is_error_address = false
    tmpValidateObj.is_error_tax_id = false
    tmpValidateObj.is_error_office = false
    setIsErrorCustomerName(false)
    setIsErrorCustomerId(false)
    setIsErrorTaxId(false)

    setDataCreating(tmpObj)
    setValidateInputData(tmpValidateObj)
    setIsHasCustomerId(true)
  }
  const handleTextChange = (value, type) => {
    // let tmpObj = { ...dataCreating }
    setIsHasCustomerId(false)
    if (type == 'customer_id') {
      // tmpObj.id = ''
      // tmpObj.customer_id = ''
      setTextCustomerId(value)
      //validate
      if (value) {
        setIsErrorCustomerId(false)
      }
    } else if (type == 'customer_name') {
      // tmpObj.id = ''
      // tmpObj.customer_id = ''
      setTextCustomerName(value)
      //validate
      if (value) {
        setIsErrorCustomerName(false)
      }
    } else if (type == 'tax_id') {
      setTextTaxId(value)
      //validate
      if (value) {
        setIsErrorTaxId(false)
      }
    }
    // setDataCreating(tmpObj)
  }

  const calculateItemTotalPrice = (pricePerUnit, quantity) => {
    let tmpTotal = convertNumberToCommaTwoDecimal(pricePerUnit * quantity) // OLD
    return tmpTotal
  }

  // const calculateItemTotalPrice = (pricePerUnit, quantity, discount) => {
  //   // let tmpTotal = convertNumberToCommaTwoDecimal(pricePerUnit * quantity) // OLD

  //   let tmpTotal = convertNumberToCommaTwoDecimal(pricePerUnit * quantity - (pricePerUnit * quantity * discount) / 100)
  //   return tmpTotal
  // }
  const convertNumberToCommaTwoDecimal = (myNumber) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
    const tmpTotal = Number(myNumber).toLocaleString('en', options)
    return tmpTotal
  }
  const handleGoBack = () => {
    navigate(-1)
  }

  const handleClickedOption = async (type) => {
    setDialogSaleDocType(type)

    if (type == 'preview' || type == 'print') {
      setIsShowDialogSaleDoc(true)
    } else if (type == 'download_pdf') {
      setIsShowModalDownloadSaleDoc(true)
    } else if (type == 'request_approve') {
      setIsShowDialogRequestApprove(true)
    } else {
      setIsShowModalShareSaleDoc(true)
    }
  }

  const handleClickBtnDownloadQuotation = () => {
    // setIsDownload(true)
    setIsShowModalDownloadSaleDoc(false)
    setIsShowDialogSaleDoc(true)
  }

  const handleClickSubmitRequestApproval = () => {
    setIsShowDialogRequestApprove(false)
  }

  const handleClickedPreview = () => {
    setIsHindModalShare(true)
    setDialogSaleDocType('preview')
    setIsShowDialogSaleDoc(true)
  }

  const handleClickClosePreview = () => {
    setIsShowDialogSaleDoc(false)
    setIsHindModalShare(false)
  }

  const convertIntComma = (val) => {
    if (val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }

  const getTitleName = (text) => {
    let arr = text.split('_')
    let tmpName = arr.join(' ')
    return tmpName
  }

  return (
    <Styled isHindModalShare={isHindModalShare}>
      <div className="wrp-header-container">
        <div className="header-left">
          <h2 className={'header-edit-document'}>
            {isEditSaleDocument ? 'Editing' : 'Creating'} document - {getTitleName(viewType)}
          </h2>
          {isEditSaleDocument && <p>{saleDocumentData?.doc_id}</p>}
        </div>

        {isEditSaleDocument && (
          <div className="header-right">
            <DocumentOptionButton onClicked={handleClickedOption} />
          </div>
        )}
      </div>

      <div className="content-container">
        <div className="body">
          <div className="body-top">
            <div className="input-left">
              <div className="input-left-top">
                <div className="row">
                  <div className="left">
                    <div className="wrp-input">
                      <p>Customer Id</p>
                      <SearchSuggestCustomer
                        placeHolder="Customer id"
                        type={'customer_id'}
                        selectedId={dataCreating.id}
                        onSelectedSuggest={(e) => handleSelectCustomer(e, 'customer_id')}
                        onTextChange={(e) => handleTextChange(e, 'customer_id')}
                        isError={isErrorCustomerId}
                      />
                    </div>
                    <div className="wrp-input">
                      <p>Customer Name</p>
                      <SearchSuggestCustomer
                        placeHolder="Customer name"
                        type={'customer_name'}
                        selectedId={dataCreating.id}
                        onSelectedSuggest={(e) => handleSelectCustomer(e, 'customer_name')}
                        onTextChange={(e) => handleTextChange(e, 'customer_name')}
                        // onTextChange={(e) => setTextCustomerName(e)}
                        isError={isErrorCustomerName}
                      />
                    </div>
                    <div className="wrp-input">
                      <p>Tax id </p>
                      <SearchSuggestCustomer
                        placeHolder="Tax id"
                        type={'tax_id'}
                        selectedId={dataCreating.id}
                        onSelectedSuggest={(e) => handleSelectCustomer(e, 'tax_id')}
                        onTextChange={(e) => handleTextChange(e, 'tax_id')}
                        isError={isErrorTaxId}
                      />
                    </div>
                  </div>
                  <div className="right">
                    <div className="wrp-input">
                      <p>Address</p>
                      <InputTextArea
                        className={'input-text-area-address' + (validateInputData?.is_error_address ? ' error' : '')}
                        onChange={(e) => handleChangeData(e, 'address')}
                        placeHolder="Address details"
                        resizeDisable
                        initValue={dataCreating?.address}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="wrp-input">
                    <p>Office/Branch Number</p>
                    <TextField
                      className={'text-field'}
                      placeholder={'สำนักงาน / สาขาเลขที่'}
                      onChange={(e) => {
                        handleChangeData(e, 'office')
                      }}
                      value={dataCreating?.office}
                      error={validateInputData.is_error_office}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="input-right">
              <div className="wrp-input">
                <p>Date</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'doc_date')}
                  value={dataCreating?.doc_date}
                  error={validateInputData.is_error_doc_date}
                />
              </div>
              <div className="wrp-input">
                <p>อ้างอิงถึงใบกำกับภาษี</p>
                <TextField
                  className={'text-field'}
                  onChange={(e) => {
                    handleChangeData(e, 'tax_invoice_id')
                  }}
                  value={dataCreating?.tax_invoice_id}
                  error={validateInputData.is_error_tax_invoice_id}
                />
              </div>
              <div className="wrp-input">
                <p>Date</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'tax_invoice_date')}
                  value={dataCreating?.tax_invoice_date}
                  error={validateInputData.is_error_tax_invoice_date}
                />
              </div>
              <div className="wrp-input">
                <p>Seller employee</p>
                <SearchSuggestSellerEmployee
                  placeHolder="Search Employee Name"
                  onSelectedSuggest={(e) => handleChangeData(e, 'seller_employee_id')}
                  isError={validateInputData.is_error_seller_employee_id}
                  isDisabled={false}
                  selectedId={dataCreating?.seller_employee_id}
                />
              </div>
              <div className="wrp-input">
                <p>เขตการขาย</p>
                <TextField
                  className={'text-field'}
                  onChange={(e) => {
                    handleChangeData(e, 'sales_territory')
                  }}
                  value={dataCreating?.sales_territory}
                  error={validateInputData.is_error_sales_territory}
                />
              </div>
            </div>
          </div>

          <div className="body-bottom">
            <div className="input-top">
              <p>Details</p>
              <TextField
                className={'text-field w-100'}
                onChange={(e) => handleChangeData(e, 'details')}
                value={dataCreating?.details}
              />
            </div>

            <div className={validateInputData?.is_error_product_list ? 'wrp-table-no-data' : 'wrp-table'}>
              <Table columns={TABLE_HEADER_COLUMN} data={bodyTableList} />
            </div>

            <Button onClick={handleClickBtnAddList}>Add List</Button>

            <div className="verify">
              <div className="left">
                <div className="wrp-detail">
                  <p>หมายเหตุ</p>
                  <InputTextArea
                    className={'input-text-area'}
                    placeHolder="details"
                    onChange={(e) => handleChangeData(e, 'note')}
                    initValue={dataCreating?.note}
                  />
                </div>
              </div>

              <div className="right mr-10">
                <div className="inner-right-top">
                  <div className="row">
                    <p>Price</p>
                    <p className="price">{convertNumberToCommaTwoDecimal(sumTotalPrice)}</p>
                  </div>

                  <div className="row">
                    <p>Discount</p>
                    <TextField
                      className={'text-field-percent'}
                      pattern={'^[0-9]*[.,]?[0-9]*$'}
                      onChange={(e) => handleChangeData(e, 'discount_percent')}
                      value={dataCreating?.discount_percent}
                      error={validateInputData.is_error_discount_percent}
                    />
                    <p>%</p>
                    <p className="price">{convertNumberToCommaTwoDecimal(priceDiscount)}</p>
                  </div>

                  <div className="row">
                    <p>Price after discount</p>
                    <p className="price">{convertNumberToCommaTwoDecimal(priceAfterDiscount)}</p>
                  </div>
                </div>

                <div className="inner-right-bottom">
                  <div className="row">
                    <p>มูลค่าของสินค้าหรือบริการตามใบกำกับภาษีเดิม</p>
                    <div className="wrp-old-value">
                      <TextField
                        className={'text-field-input'}
                        pattern={'^[0-9]*[.,]?[0-9]*$'}
                        onChange={(e) => handleChangeData(e, 'old_value')}
                        value={dataCreating?.old_value}
                        error={validateInputData.is_error_old_value}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <p>มูลค่าของสินค้าหรือบริการที่ถูกต้อง</p>
                    <p className="price">
                      {dataCreating?.old_value > 0
                        ? convertNumberToCommaTwoDecimal(dataCreating?.old_value + priceAfterDiscount)
                        : convertNumberToCommaTwoDecimal(priceAfterDiscount)}
                    </p>
                  </div>

                  <div className="row">
                    <p>ผลต่าง</p>
                    <p className="price">{convertNumberToCommaTwoDecimal(priceAfterDiscount)}</p>
                  </div>

                  {!dataCreating?.include_tax && (
                    <div className="row">
                      <Checkbox
                        className={'checkbox'}
                        onChange={(e) => handleChangeData(e, 'vat')}
                        checked={dataCreating?.vat}
                      />
                      <p>VAT 7%</p>
                      <p className="price">{convertNumberToCommaTwoDecimal(priceVat)}</p>
                    </div>
                  )}

                  <div className="row">
                    <p className="txt-bold">จำนวนเงินทั้งสิ้น</p>
                    <p className="price">
                      {dataCreating?.include_tax
                        ? convertNumberToCommaTwoDecimal(priceAfterDiscount)
                        : convertNumberToCommaTwoDecimal(priceAfterDiscount + priceVat)}
                    </p>
                  </div>

                  {/* {dataCreating?.include_tax && (
                    <div className={'include-vat-container'}>
                      <div className={'row'}>
                        <p>VAT 7%</p>
                        <p className="price">{convertNumberToCommaTwoDecimal(priceVat)}</p>
                      </div>
                      <div className={'row'}>
                        <p>Price dose not include VAT</p>
                        <p className="price">{priceAfterVat}</p>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <ButtonOutline onClick={() => handleGoBack()}>Cancel</ButtonOutline>
        <div className="wrp-btn-right">
          <Button className="btn-preview" onClick={handleClickBtnPreview}>
            Preview
          </Button>
          <Button className="btn-create" onClick={handleClickBtnCreate}>
            {isEditSaleDocument ? 'Save' : 'Create'}
          </Button>
        </div>
      </div>

      {isShowDialogRequestApprove && (
        <SidePanel isOpen={isShowDialogRequestApprove} onClose={() => setIsShowDialogRequestApprove(false)} width={900}>
          <DocumentRequestApprove
            docType={saleDocumentData.doc_type}
            onClickNavigationBack={() => setIsShowDialogRequestApprove(false)}
            id={saleDocumentData.id}
            onClickBtnSubmit={() => handleClickSubmitRequestApproval()}
          />
        </SidePanel>
      )}

      {/* preview and print */}
      {isShowDialogSaleDoc && (
        <DialogDocument
          onClose={() => handleClickClosePreview()}
          docType={viewType}
          previewType={dialogSaleDocType}
          id={saleDocumentData.id}
          onDownloaded={() => setIsShowDialog(false)}
          data={dataCreating}
          linkImage={dataCreating?.signature ? imageForDocument : null}
        />
      )}

      {isShowModalDownloadSaleDoc && (
        <ModalBox
          className={'modal-download'}
          onClosed={() => setIsShowModalDownloadSaleDoc(false)}
          height="200px"
          width="450px"
          title={'Download ' + saleDocumentData?.doc_id}
          body={<div className="body">ใบเพิ่มหนี้</div>}
          footer={
            <div className="bottom-modal">
              <ButtonOutline className={'footer-btn btn-cancel'} onClick={() => setIsShowModalDownloadSaleDoc(false)}>
                Cancel
              </ButtonOutline>
              <Button className={'footer-btn btn-download'} onClick={handleClickBtnDownloadQuotation}>
                Download
              </Button>
            </div>
          }
        />
      )}

      {isShowModalShareSaleDoc && (
        <div className="document-share-and-send-email">
          <DocumentShareAndSendEmail
            type={viewType}
            data={saleDocumentData}
            onClickPreview={() => handleClickedPreview()}
            onClose={() => setIsShowModalShareSaleDoc(false)}
          />
        </div>
      )}

      {isShowFailedModal && (
        <DialogFail
          open={Boolean(isShowFailedModal)}
          onClose={() => setIsShowFailedModal(false)}
          onSubmit={() => setIsShowFailedModal(false)}
          icon={failIcon}
          title={'You may not fully use this feature, since you are not employee.'}
          nameItem={''}
          description={''}
          textYes="Ok"
        />
      )}
    </Styled>
  )
}

const Styled = styled.div`
  .mr-10 {
    margin-right: 10px !important;
  }
  .wrp-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 0 20px;
    border-bottom: 1px solid #ddd;
    h2 {
      margin: 0;
      font-size: 36px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    p {
      margin: 0;
      color: var(--Gray-600);
      font-size: 16px;
    }

    .header-edit-document {
      text-transform: capitalize;
    }
  }

  .content-container {
    padding: 20px 0;
  }

  .w-100 {
    width: 100% !important;
  }

  .checkbox label {
    margin: 0;
  }

  .body {
    .row {
      display: flex;
      .right {
        padding-left: 24px;
      }
    }

    .wrp-input {
      margin-bottom: 24px;
      /* background-color: red; */

      p {
        margin-top: 0;
        margin-bottom: 6px;
      }
      .text-field,
      .dropdown-select,
      .search-text {
        width: 300px;
      }
      .address {
        height: 134px;
      }
    }
    .error {
      border-color: red !important;
    }
    .body-top {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--Gray-200);

      .input-left-top {
        .input-text-area-address {
          font-size: 14px;
          height: 134px;
          border-color: var(--Gray-300);
          border-width: 1px;
          width: 300px;
        }
      }
      .input-left-bottom {
        padding-top: 24px;
      }

      .input-right {
        margin-left: 24px;
      }
    }
    //////
    .body-bottom {
      padding: 24px 0;

      .input-top {
        max-width: 630px;
        margin-bottom: 20px;
      }

      .wrp-table {
        margin-bottom: 26px;
        > div {
          overflow-x: inherit;
        }
      }
      .wrp-table-no-data {
        margin-bottom: 20px;
        > div {
          overflow-x: inherit;
        }
        thead {
          border-top: 1px solid red;
          border-left: 1px solid red;
          border-right: 1px solid red;
          tr {
            border: none;
          }
        }
        tbody {
          border-left: 1px solid red;
          border-right: 1px solid red;
          border-bottom: 1px solid red;

          tr {
            border: none;
          }
        }
      }
      //table input
      .input-table {
        width: 100%;
      }
      .btn-delete {
        cursor: pointer;
      }
      .verify {
        margin-top: 31px;
        display: flex;
        justify-content: space-between;

        .left {
          .wrp-checkbox-signature {
            display: flex;
            align-items: center;

            .checkbox {
              width: fit-content;
            }
            p {
              margin: 0;
            }
          }
          .wrp-detail {
            margin-top: 30px;
            p {
              margin: 0 0 6px;
            }
          }
          .input-text-area {
            font-size: 14px;
            border-color: var(--Gray-300);
            width: 577px;
            height: 112px;
          }
        }
        .right {
          .inner-right-top,
          .inner-right-bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .row {
              display: flex;
              align-items: center;
              /* margin-bottom: 5px; */
              p {
                margin: 0;
              }

              .checkbox {
                width: fit-content;
              }
              .price {
                margin-left: 100px;
              }
              .wrp-old-value {
                margin-left: 30px;
              }
            }
            .text-field-percent {
              margin: 0 10px;
              width: 50px;
              height: 30px;
            }
            .text-field-input {
              width: 100px;
              height: 30px;
            }
          }

          .inner-right-top {
            border-bottom: 1px solid var(--Gray-200);

            .row {
              margin-bottom: 10px;
              align-items: center;
            }

            .include-vat-container {
              border-top: 1px solid var(--Gray-200);
              width: 100%;
              padding-top: 10px;
              align-items: flex-end;
              text-align: end;
              .row {
                width: 100%;
                justify-content: flex-end;
              }
            }
          }
          .inner-right-bottom {
            .row {
              margin-top: 10px;
              align-items: center;
            }
            .dropdown-withholding-tax {
              width: 90px;
              margin-left: 8px;
            }
          }
        }
      }
    }
    .txt-bold {
      font-weight: bold;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--Gray-200);
    padding: 16px 24px;

    .btn-preview {
      margin-right: 12px;
    }
  }

  //Modal
  .modal-download {
    .bottom-modal {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .btn-cancel {
        width: 48%;
        justify-content: center;
      }
      .btn-download {
        width: 48%;
        justify-content: center;
      }
    }
  }

  .document-share-and-send-email {
    display: ${({ isHindModalShare }) => (isHindModalShare ? 'none' : 'block')};
  }
`
