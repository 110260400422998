import { Stack } from '@mui/material'
import { styled } from '@mui/system'

const PageLayout = ({ children }) => {
  return <StyledStack>{children}</StyledStack>
}

export default PageLayout

const StyledStack = styled(Stack)`
  position: relative;
  gap: 1rem;

  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 6px;
    background: transparent; /* make scrollbar invisible by default */
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    background: var(--Gray-200); /* make scrollbar visible on hover and active */
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    opacity: 0.5;
  }

  &:active::-webkit-scrollbar-thumb {
    scale: 1.05 !important;
    background: var(--Gray-500);
    transition: background 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  @media (min-width: 1024px) {
    gap: 1.5rem;
  }
`
