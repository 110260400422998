import { useTranslation } from 'react-i18next'

/**
 *
 * @param {string} type 'chain-link' | 'sop'
 * @returns TableColumn[]
 */
export default function (type = 'chain-link', otherProps) {
  const { t } = useTranslation()

  switch (type) {
    case 'chain-link':
      return [
        {
          Header: t('operation_id'),
          accessor: 'code',
          disableSortBy: false,
        },
        {
          Header: t('chain_link'),
          accessor: 'chain_link_temp',
          disableSortBy: false,
        },
        {
          Header: t('name'),
          accessor: 'name',
          disableSortBy: false,
        },
        {
          Header: t('status'),
          accessor: 'status',
          disableSortBy: false,
        },
        {
          Header: t('progress'),
          accessor: 'progress',
          disableSortBy: false,
        },
        {
          Header: t('created_date'),
          accessor: 'createdAt',
          disableSortBy: false,
        },
        {
          Header: t('start_date'),
          accessor: 'start_date',
          disableSortBy: false,
        },
        {
          Header: t('due_date'), // เปลี่ยนเป็น วันที่ต้องส่งงาน
          accessor: 'due_date',
          disableSortBy: false,
        },
        {
          Header: t('created_by'),
          accessor: 'created_by',
          disableSortBy: false,
        },
        {
          accessor: 'actions',
          disableSortBy: true,
          width: 100,
        },
      ]
    case 'sop':
      return [
        {
          Header: t('operation_id'),
          accessor: 'code',
          disableSortBy: false,
        },
        {
          Header: t('sop'),
          accessor: 'sp_temp_name',
          disableSortBy: false,
        },
        {
          Header: t('name'),
          accessor: 'name',
          disableSortBy: false,
        },
        {
          Header: t('status'),
          accessor: 'status',
          disableSortBy: false,
        },
        {
          Header: t('progress'),
          accessor: 'progress',
          disableSortBy: false,
        },
        {
          Header: t('created_date'),
          accessor: 'createdAt',
          disableSortBy: false,
        },
        {
          Header: t('start_date'),
          accessor: 'start_date',
          disableSortBy: false,
        },
        {
          Header: t('due_date'),
          accessor: 'due_date',
          disableSortBy: false,
        },
        {
          Header: t('created_by'),
          accessor: 'created_by',
          disableSortBy: false,
        },
        {
          accessor: 'actions',
          disableSortBy: true,
          width: 100,
        },
      ]
    default:
      break
  }
}
