import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getActionPlanById, getActionPlanReturnData } from '../../redux/actions/dailysummary'
import { selectDailySummary } from '../../redux/selectors'
import dayjs from 'dayjs'

import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import iconPlus from '../../assets/images/icon-plus.svg'

import InputTextArea from '../../components/widget/element/InputTextArea'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import iconDelete from '../../assets/images/icon-bin.svg'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import TextField from '../../components/form/TextField'
import TextFieldTime from '../../components/form/TextFieldTime'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'

import ReactQuill from 'react-quill'

import SidePanelV2 from 'components/common/SidePanelV2'
import DrawerDetails from 'components/feature/operation-process/default-cards'

const Div = styled.div`
  padding: 24px 32px;
  height: 100%;
  .highlight-text {
    color: var(--Primary-600);
    font-weight: 700;
  }
  .css_type_of_activity {
    font-size: 14px;
    color: var(--Primary-600);
    font-weight: 600;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .css_header_detail_how_to {
    font-weight: bold;
  }
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css {
      color: #7f56d9;
      font-weight: bold;
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .content_header {
    width: ${(props) => props.width}px;
    position: fixed;
    background: #ffffff;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dialog-content {
    padding-top: 118px;
    padding-bottom: 106px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width: ${(props) => props.width}px;
    background-color: #ffffff;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .div_table {
    padding: 15px;
  }
  .resp-table {
    width: 100%;
    display: table;
  }
  .resp-table-body {
    display: table-row-group;
  }
  .resp-table-row {
    display: table-row;
  }
  .table-body-head_cell {
    display: table-cell;
    border: 1px solid #d0d5dd;
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    text-align: center;
    font-weight: 600;
    background-color: #f2f4f7;
    color: #475467;
    font-size: 12px;
  }
  .table-body-cell {
    display: table-cell;
    border: 1px solid #dddddd;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: center;
    font-size: 12px;
  }
  .width_time {
    /*width: 30%;*/
    width: 22%;
  }
  .width_activity {
    width: 39%;
  }
  .width_result {
    width: 39%;
  }
  .width_del {
    width: 6%;
    text-align: center;
  }
  .InputTextArea_css {
    width: 100%;
  }
  .dd_css {
    margin: 0px 5px;
  }
  .basic-Information-detail {
    padding: 14px;
    width: 50%;
    display: flex;
  }
  .css_calendar {
    margin-left: 15px;
  }
  .padding-detail {
    padding: 0px 14px;
  }
  .border_top_left_radius {
    border-top-left-radius: 8px;
  }
  .border_top_right_radius {
    border-top-right-radius: 8px;
  }
  .border_bottom_left_radius {
    border-bottom-left-radius: 8px;
  }
  .border_bottom_right_radius {
    border-bottom-right-radius: 8px;
  }
  .css_time_text {
    text-align: center;
    font-size: 12px;
  }
  .TextField_css {
    width: 100%;
  }
  .css_cursor_pointer {
    cursor: pointer;
  }

  /* ST CSS POPUP IMG */
  .dialog-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  /* Style for the dialog content */
  .dialog-img-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    position: relative;
    max-width: 1200px;
    padding: 0 15px;
  }
  /* Style for the close icon */
  .dialog-img-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0;
  }

  .dialog-img-popup-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .dialog-img-popup_header {
    text-align: left;
  }
  .dialog-img-popup_detail {
    text-align: left;
    padding: 10px 0px;
  }
  /* ED CSS POPUP IMG */

  /* ST CSS ส่วนของการกรอกข้อมูล */
  .time_sort {
    padding: 10px 14px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #dddddd;
    width: 150px;
    font-size: 14px;
    font-weight: 700;
  }
  .group_main_box {
    padding: 0px 14px;
    padding-top: 14px;
  }
  .main_box {
    display: grid;
    grid-template-columns: repeat(2, 10% [col-start] 90% [col-end]);
    gap: 10px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .main_box_text_head {
    font-size: 14px;
    font-weight: 600;
    color: var(--Gray-700);
    margin-bottom: 10px;
  }
  .main_box_activity_result {
    display: grid;
    grid-template-columns: repeat(2, 50% [col-start] 50% [col-end]);
    gap: 10px;
  }
  .group_main_box_button {
    margin-left: 10px;
  }
  .main_box_button {
    display: flex;
    gap: 10px;
  }
  .div_box_deatail {
    /*min-height: 128px;*/
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    /*background-color: #F9FAFB;*/
    font-size: 14px;
    /*padding: 10px;*/
    white-space: pre-wrap;
    line-break: anywhere;
  }
  .main_box_time {
    display: grid;
    grid-template-columns: repeat(2, 10% [col-start] 90% [col-end]);
    gap: 10px;
  }

  .ql-container {
    min-height: 45px;
    font-family: inherit;
  }
  .ql-tooltip {
    display: none;
  }

  /* ED CSS ส่วนของการกรอกข้อมูล */
`
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'
import { useTranslation } from 'react-i18next'
import Divider from 'components/common/Divider'
import { Box, Stack } from '@mui/material'
import DialogTaskPreviewer from 'components/dialog/DialogTaskPreviewer'

const ViewActionPlan = ({ onClose, onSubmit, ActionPlanId, ...props }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const errorMessage = ''
  const editable = true
  const [currentDate, setcurrentDate] = useState(dayjs().format('YYYY-MM-DD'))
  const { user, employee_info } = JSON.parse(localStorage.getItem('admin'))

  const [ThisActionPlanId, setThisActionPlanId] = useState(ActionPlanId)
  useEffect(() => {
    if (ActionPlanId) {
      dispatch(getActionPlanById(ActionPlanId))
    }
  }, [dispatch, ActionPlanId])

  const { ActionPlanInfo } = useSelector(selectDailySummary)
  const [ActionPlanData, setActionPlanData] = useState({
    display_date: null,
    display_report_by: null,
    data_action_plan_detail: [],
  })

  useEffect(() => {
    if (ActionPlanInfo.id) {
      const setData = {
        display_date: dayjs(ActionPlanInfo.action_plan_date_start).format('D / MMM / YYYY , h:mm A'),
        display_report_by: ActionPlanInfo.create_by,
        data_action_plan_detail: ActionPlanInfo.action_plan_details,
      }

      setActionPlanData(setData)
      setcurrentDate(dayjs(ActionPlanInfo.action_plan_date_start).format('YYYY-MM-DD'))
    }
  }, [ActionPlanInfo])

  const [EmployeeIdCalendar, setEmployeeIdCalendar] = useState(employee_info?.id ? employee_info?.id : -99)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)
  const onChangecurrentDate = (value) => {
    setcurrentDate(value)
    const filter = {
      skip: 1,
      limit: 10000,
      active: 1,
      EmployeeId: EmployeeIdCalendar,
      filter_date: value,
    }
    dispatch(getActionPlanReturnData(filter)).then(({ type, data_info }) => {
      if (type.endsWith('_SUCCESS')) {
        if (data_info.length > 0) {
          const this_id = data_info[0].id
          setThisActionPlanId(this_id)
          dispatch(getActionPlanById(this_id))
        } else {
          setTitleFail('ไม่พบข้อมูล')
          setnameItemFail('')
          setDescriptionFail('')
          setFailModal(true)
        }
      } else {
        setTitleFail('ไม่พบข้อมูล')
        setnameItemFail('')
        setDescriptionFail('')
        setFailModal(true)
      }
    })
  }

  const GotoEdit = () => {
    onSubmit(ThisActionPlanId)
  }
  const [isSortedAsc, setisSortedAsc] = useState(true)
  const SortData = (inp_isSortedAsc) => {
    const this_isSortedAsc = !inp_isSortedAsc // true -> false , false -> true
    setisSortedAsc(this_isSortedAsc)
    const this_new_data = ActionPlanData.data_action_plan_detail.sort((a, b) => {
      if (this_isSortedAsc) {
        return (
          parseInt(dayjs(a?.attributes?.action_plan_detail_date_start).format('HH.mm').replace('.', '')) -
          parseInt(dayjs(b?.attributes?.action_plan_detail_date_start).format('HH.mm').replace('.', ''))
        ) // ASC
      } else {
        return (
          parseInt(dayjs(b?.attributes?.action_plan_detail_date_start).format('HH.mm').replace('.', '')) -
          parseInt(dayjs(a?.attributes?.action_plan_detail_date_start).format('HH.mm').replace('.', ''))
        ) // DESC
      }
    })

    setActionPlanData({ ...ActionPlanData, data_action_plan_detail: this_new_data })
  }

  const [dialog, setDialog] = useState(false)
  const toggleDialog = () => {
    setDialog(true)
  }
  const ClosetoggleDialog = () => {
    setDialog(false)
  }

  const [sidePanelData, setSidePanelData] = useState()

  const displayTaskForActionPlan = (taskData) => {
    const {
      project_task_reference,
      task_reference,
      op_sw_reference,
      op_task_reference,
      op_approver_reference,
      op_reviewer_reference,
    } = taskData

    if (project_task_reference.id) {
      return (
        <Box className="css_type_of_activity" onClick={() => openTaskSidePanel(project_task_reference, 'project')}>
          <Box>Project :{project_task_reference?.project_name}</Box>
          <Box>Task Name: {project_task_reference?.task_name}</Box>
        </Box>
      )
    } else if (task_reference.id) {
      return (
        <Box className="css_type_of_activity" onClick={() => openTaskSidePanel(task_reference, 'task')}>
          <Box>Project :{task_reference?.project_name}</Box>
          <Box>Task Name: {task_reference?.task_name}</Box>
        </Box>
      )
    } else if (op_sw_reference.id) {
      return (
        <Box className="css_type_of_activity" onClick={() => openTaskSidePanel(op_sw_reference, 'op-sw')}>
          <Box>SOP : {op_sw_reference?.sop}</Box>
          <Box>SOW :{op_sw_reference?.sow}</Box>
          <Box>Task : {op_sw_reference?.task}</Box>
        </Box>
      )
    } else if (op_task_reference.id) {
      return (
        <Box className="css_type_of_activity" onClick={() => openTaskSidePanel(op_task_reference, 'op-task')}>
          <Box>SOP : {op_task_reference?.sop}</Box>
          <Box>SOW :{op_task_reference?.sow}</Box>
          <Box>Task : {op_task_reference?.task}</Box>
        </Box>
      )
    } else if (op_approver_reference.id) {
      return (
        <Box className="css_type_of_activity" onClick={() => openTaskSidePanel(op_approver_reference, 'op-approval')}>
          <Box>SOP : {op_approver_reference?.sop}</Box>
          <Box>SOW :{op_approver_reference?.sow}</Box>
          <Box>Task : {op_approver_reference?.task}</Box>
        </Box>
      )
    } else if (op_reviewer_reference.id) {
      return (
        <Box className="css_type_of_activity" onClick={() => openTaskSidePanel(op_reviewer_reference, 'op-review')}>
          <Box>SOP : {op_reviewer_reference?.sop}</Box>
          <Box>SOW :{op_reviewer_reference?.sow}</Box>
          <Box>Task : {op_reviewer_reference?.task}</Box>
        </Box>
      )
    } else {
      return <Box></Box>
    }
  }

  // op sow side panel
  const [isOpenDetailOPSoWSidePanel, setIsOpenDetailOPSoWSidePanel] = useState(false)

  const openDetailOPSoWSidePanel = () => {
    setIsOpenDetailOPSoWSidePanel(true)
  }
  const closeDetailOPSoWSidePanel = () => {
    setIsOpenDetailOPSoWSidePanel(false)
  }

  // op task side panel
  const [isOpenDetailOPTaskSidePanel, setIsOpenDetailOPTaskSidePanel] = useState(false)

  const openDetailOPTaskSidePanel = () => {
    setIsOpenDetailOPTaskSidePanel(true)
  }
  const closeDetailOPTaskSidePanel = () => {
    setIsOpenDetailOPTaskSidePanel(false)
  }

  // op review side panel
  const [isOpenDetailOPReviewSidePanel, setIsOpenDetailOPReviewSidePanel] = useState(false)

  const openDetailOPReviewSidePanel = () => {
    setIsOpenDetailOPReviewSidePanel(true)
  }
  const closeDetailOPReviewSidePanel = () => {
    setIsOpenDetailOPReviewSidePanel(false)
  }

  // op approval side panel
  const [isOpenDetailOPApprovalSidePanel, setIsOpenDetailOPApprovalSidePanel] = useState(false)

  const openDetailOPApprovalSidePanel = () => {
    setIsOpenDetailOPApprovalSidePanel(true)
  }
  const closeDetailOPApprovalSidePanel = () => {
    setIsOpenDetailOPApprovalSidePanel(false)
  }

  // dialog task preview side panel
  const [isShowDialogTaskPreview, setIsShowDialogTaskPreview] = useState(false)

  const openShowDialogTaskPreview = () => {
    setIsShowDialogTaskPreview(true)
  }
  const closeShowDialogTaskPreview = () => {
    setIsShowDialogTaskPreview(false)
  }

  const openTaskSidePanel = (data, type) => {
    if (type === 'task' || type === 'project') {
      setSidePanelData({
        ...data,
        type: type === 'task' ? 'task_routine' : 'task_milestone',
      })
    } else {
      setSidePanelData(data)
    }
    if (type === 'op-sw') {
      openDetailOPSoWSidePanel()
    } else if (type === 'op-task') {
      openDetailOPTaskSidePanel()
    } else if (type === 'op-review') {
      openDetailOPReviewSidePanel()
    } else if (type === 'op-approval') {
      openDetailOPApprovalSidePanel()
    } else {
      openShowDialogTaskPreview()
    }
  }

  return (
    <Div {...props}>
      <Box>
        <header>
          <div className="content-title">{t('action_plan')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">
          <div>
            <div>{t('create_action_plan_sub_header')}</div>
            <div className="highlight-text">{ActionPlanData?.display_report_by}</div>
          </div>
        </div>
      </Box>

      <Box sx={{ mt: '12px', mb: 2 }}>
        <Divider />
      </Box>

      <div>
        <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between">
          <Date className={'input-calendar'} value={currentDate} onChange={onChangecurrentDate} />

          <Button onClick={GotoEdit}>{t('Edit')}</Button>
        </Stack>

        <Box className="css_header_detail_how_to link" onClick={() => toggleDialog()}>
          {t('Read_How_to_fill_in_Action_Plan')}
        </Box>
        <Box sx={{ mb: 2 }} className="css_header_detail" onClick={() => toggleDialog()}>
          {t('create_action_plan_info')}
        </Box>

        <div className="time_sort" onClick={() => SortData(isSortedAsc)}>
          Time Reorder
          <span>{isSortedAsc ? ' ↓' : ' ↑'}</span>
        </div>

        <div className="group_main_box">
          {/* Hide temporary */}
          {ActionPlanData?.data_action_plan_detail?.map((item, index) => (
            <div>
              <Stack direction="row" spacing={2}>
                <Box className="main_box_text_head">{index + 1}.</Box>
                <Box className="main_box_text_head">
                  {/* {dayjs(item?.attributes?.action_plan_detail_date_start).format('HH.mm')} -{' '}
                  {dayjs(item?.attributes?.action_plan_detail_date_end).format('HH.mm')} */}
                  {dayjs(item?.action_plan_detail_date_start).format('HH.mm')} -{' '}
                  {dayjs(item?.action_plan_detail_date_end).format('HH.mm')}
                </Box>
                {/* <Box className="main_box_text_head">{item.attributes.type_of_activity}</Box> */}
                <Box className="main_box_text_head">{item?.type_of_activity}</Box>
                {displayTaskForActionPlan(item)}
              </Stack>

              <Stack direction="row" justifyContent="space-between">
                <Box sx={{ width: '49%' }}>
                  <div className="main_box_text_head">{t('create_action_plan_time_table_activity')}</div>
                  <div className="div_box_deatail">
                    {/* <ReactQuill value={item.attributes.activity} readOnly={true} theme={'bubble'} /> */}
                    <ReactQuill value={item.activity} readOnly={true} theme={'bubble'} />
                  </div>
                </Box>
                <Box sx={{ width: '49%' }}>
                  <div className="main_box_text_head">{t('create_action_plan_time_table_result')}</div>
                  <div className="div_box_deatail">
                    {/* <ReactQuill value={item.attributes.result} readOnly={true} theme={'bubble'} /> */}
                    <ReactQuill value={item.result} readOnly={true} theme={'bubble'} />
                  </div>
                </Box>
              </Stack>

              <Box sx={{ mt: 2, mb: 2 }}>
                <Divider />
              </Box>
            </div>
          ))}
        </div>

        {/*
        <div className="div_table">
          <div className="resp-table">
            <div className="resp-table-body">
              <div className="resp-table-row">
                <div className="table-body-head_cell width_time border_top_left_radius css_cursor_pointer" onClick={() => SortData(isSortedAsc)}>
                  {t('time')}
                  <span>{isSortedAsc ? ' ↓' : ' ↑'}</span>
                </div>
                <div className="table-body-head_cell width_activity">{t('create_action_plan_time_table_activity')}</div>
                <div className="table-body-head_cell width_result border_top_right_radius">{t('create_action_plan_time_table_result')}</div>
              </div>
              {ActionPlanData.data_action_plan_detail?.map((item, index) => (
                <div className="resp-table-row" key={index}>
                  <div className={`table-body-cell width_time ${index == ActionPlanData.data_action_plan_detail.length - 1 ? 'border_bottom_left_radius' : ''}`}>
                      <div className="css_time_text">
                        {dayjs(item.attributes.action_plan_detail_date_start).format('HH.mm')} - {dayjs(item.attributes.action_plan_detail_date_end).format('HH.mm')}
                      </div>
                  </div>
                  <div className="table-body-cell width_activity">
                    {item.attributes.activity}
                  </div>
                  <div className={`table-body-cell width_result ${index == ActionPlanData.data_action_plan_detail.length - 1 ? 'border_bottom_right_radius' : ''}`}>
                    {item.attributes.result}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
          */}
      </div>
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />
      {dialog && (
        <div className="dialog-img">
          <div className="dialog-img-content">
            <h3 className="dialog-img-popup_header">{t('How_to_fill_in_Action_Plan')}</h3>
            <button className="dialog-img-close-icon" onClick={ClosetoggleDialog}>
              &#10005;
            </button>
            <div className="dialog-img-popup_detail">
              <div>{t('How_to_fill_in_Action_Plan_1')}</div>
              <div>{t('How_to_fill_in_Action_Plan_2')}</div>
              <div>{t('How_to_fill_in_Action_Plan_3')}</div>
              <div>{t('How_to_fill_in_Action_Plan_4')}</div>
              <div>{t('How_to_fill_in_Action_Plan_5')}</div>
              <div>{t('How_to_fill_in_Action_Plan_6')}</div>
            </div>
          </div>
        </div>
      )}

      {/* side panel */}
      <SidePanelV2
        titleText={sidePanelData?.sow}
        supportingText={`SOW Code: ${sidePanelData?.code}`}
        open={isOpenDetailOPSoWSidePanel}
        onClose={(event) => {
          closeDetailOPSoWSidePanel()
        }}
      >
        <DrawerDetails.SoW id={sidePanelData?.id_ref} />
      </SidePanelV2>
      <SidePanelV2
        titleText={sidePanelData?.sow}
        supportingText={`SOW Code: ${sidePanelData?.code}`}
        open={isOpenDetailOPTaskSidePanel}
        onClose={(event) => {
          closeDetailOPTaskSidePanel()
        }}
      >
        <DrawerDetails.Task taskId={sidePanelData?.id_ref} />
      </SidePanelV2>
      <SidePanelV2
        titleText={sidePanelData?.sow}
        supportingText={`SOW Code: ${sidePanelData?.code}`}
        open={isOpenDetailOPReviewSidePanel}
        onClose={(event) => {
          closeDetailOPReviewSidePanel()
        }}
      >
        <DrawerDetails.Reviewer reviewerId={sidePanelData?.id_ref} />
      </SidePanelV2>
      <SidePanelV2
        titleText={sidePanelData?.sow}
        supportingText={`SOW Code: ${sidePanelData?.code}`}
        open={isOpenDetailOPApprovalSidePanel}
        onClose={(event) => {
          closeDetailOPApprovalSidePanel()
        }}
      >
        <DrawerDetails.Approval approverId={sidePanelData?.id_ref} />
      </SidePanelV2>

      {isShowDialogTaskPreview && (
        <DialogTaskPreviewer
          taskId={sidePanelData?.id_ref}
          open={isShowDialogTaskPreview}
          onClose={closeShowDialogTaskPreview}
          previewType={sidePanelData?.type}
        />
      )}
    </Div>
  )
}

export default ViewActionPlan

{
  /* <div className="main_box">
                <div className="main_box_time">
                  <div>
                    <div className="main_box_text_head">{index + 1}.</div>
                  </div>

                  <div>
                    <div className="main_box_text_head">{t('time')}</div>
                    <div className="main_box_text_head">
                      {dayjs(item.attributes.action_plan_detail_date_start).format('HH.mm')} -{' '}
                      {dayjs(item.attributes.action_plan_detail_date_end).format('HH.mm')}
                    </div>
                    <div className="main_box_text_head css_type_of_activity">{t('type_of_activity')}</div>
                    <div className="css_type_of_activity">{item.attributes.type_of_activity}</div>
                  </div>
                </div>
                <div className="main_box_activity_result">
                  <div>
                    <div className="main_box_text_head">{t('create_action_plan_time_table_activity')}</div>
                    <div className="div_box_deatail">
                      <ReactQuill value={item.attributes.activity} readOnly={true} theme={'bubble'} />
                    </div>
                  </div>
                  <div>
                    <div className="main_box_text_head">{t('create_action_plan_time_table_result')}</div>
                    <div className="div_box_deatail">
                      <ReactQuill value={item.attributes.result} readOnly={true} theme={'bubble'} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="border_header"></div> */
}
