import { Divider, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { createElement, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { RiAddLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import iconFail from 'assets/images/fail-icon.png'
import iconSuccess2 from 'assets/images/success-icon-2.png'
import Button from 'components/common/Button'
import Chip from 'components/common/Chip'
import CollapseMenu from 'components/common/CollapseMenu'
import ModalV2 from 'components/common/ModalV2'
import SidePanelV2 from 'components/common/SidePanelV2'
import TableV3 from 'components/common/TableV3'
import Typography from 'components/common/Typography'
import CreateSopTpl from 'components/feature/operation-process/form/sop-tpl/create'
import EditSopTpl from 'components/feature/operation-process/form/sop-tpl/edit'
import ViewSopTpl from 'components/feature/operation-process/form/sop-tpl/view'
import Filter from 'components/feature/operation-process/form/sop-tpl/filters'
import { SOP_TEMPLATE } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { ButtonGroup, TableContainer, TableHeader, TypographyH3 } from '.'
import tableColumns from './table-columns'

const init_drawer = {
  createSopTpl: false,
  viewSopTpl: false,
  editSopTpl: false,
  sopTplId: undefined,
}

const SopMGTList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const sopTplId = searchParams.get('id')
  const mode = searchParams.get('mode')

  const filterRef = useRef(null)
  const createSopTplRef = useRef(null)
  const editSopTplRef = useRef(null)
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)

  const { sopTpl: sopTplActions, extra: extraActions } = opActions()
  const { getExtra } = extraActions()
  const { getSopTpl, postSopTpl, putSopTpl, deleteSopTpl, clearSopTpl } = sopTplActions()

  const { sopTpls, sopTpls_pagination, sopTpl } = opProcSelector
  const { page, pageCount, total } = sopTpls_pagination

  const [drawer, setDrawer] = useState(init_drawer)
  const [filters, setFilters] = useState({})
  const [modalProps, setModalProps] = useState({ open: false })

  const fetchSopTpls = useCallback(
    async (payload) => {
      return dispatch(getSopTpl().list(payload))
    },
    [dispatch]
  )

  const fetchSopTplById = useCallback(
    async (sopTplId) => {
      return dispatch(getSopTpl(sopTplId).byId())
    },
    [dispatch]
  )

  const fetchDepartments = useCallback(
    async (payload) => {
      return dispatch(getExtra().departments(payload))
    },
    [dispatch]
  )

  const handleToggleDrawer = (drawerName, open, data = {}) => {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }

      setDrawer({ ...init_drawer, [drawerName]: open, ...data })
    }
  }

  const handleFilters = (values) => {
    const keys = Object.keys(values)

    if (keys.length === 0) {
      setFilters({})
      return fetchSopTpls()
    }

    let filters = {}
    keys.forEach((key) => {
      if (!values[key]) {
        return
      }

      switch (key) {
        case 'name':
          filters = { ...filters, $or: [{ name: { $contains: values[key] } }, { code: { $contains: values[key] } }] }
          break
        case 'department':
          filters = { ...filters, department: values[key] }
          break
        default:
          break
      }
    })

    if (Object.keys(filters).length === 0) {
      setFilters({})
      return fetchSopTpls()
    }

    setFilters(filters)
    return fetchSopTpls({ filters })
  }

  const handleTableChange = ({ page }) => {
    // Do something...
    const pagination = { ...sopTpls_pagination, page }
    fetchSopTpls({ pagination, filters })
  }

  const actionList = (item) => {
    return [
      {
        text: t('view'),
        action: () => {
          navigate(`${pathname}?id=${item.id}&mode=view`)
          handleToggleDrawer('viewSopTpl', true)()
        },
      },
      {
        text: t('Edit'),
        action: () => {
          navigate(`${pathname}?id=${item.id}&mode=edit`)
          handleToggleDrawer('editSopTpl', true)()
        },
      },
    ]
  }

  const transformSopToTable = (data) => {
    const mapDataItem = (item) => {
      const code = (
        <Link to={`${pathname}?id=${item?.id}&mode=view`}>
          <Typography variant="tableLink">{item.code}</Typography>
        </Link>
      )
      const description = <div className="sop-col-description">{item.description}</div>
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={item.status || 'N/A'}
          color={SOP_TEMPLATE.STATUS_CHIP[item.status]}
        />
      )
      const actions = <CollapseMenu list={actionList(item)} />

      return { ...item, code, description, status, actions }
    }

    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const handleCreateSopTplSubmit = (data) => {
    const successfulCreate = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
        return fetchSopTpls()
      },
      onOk() {
        setModalProps({ open: false })
        return fetchSopTpls()
      },
      titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
      titleText: 'SOP Template Created',
      contentText: 'SOP Template has been created successfully.',
    }

    const failedCreate = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
      },
      onOk() {
        setModalProps({ open: false })
      },
      okText: 'Try Again',
      titleIcon: createElement('img', { src: iconFail, width: 48, height: 48, alt: 'i-failed' }),
      titleText: "Couldn't Create SOP Template",
      contentText: 'An error occurred while creating the SOP Template.',
      okProps: { color: 'error' },
    }

    dispatch(postSopTpl().create(data)).then((res) => {
      if (res.type.endsWith('_SUCCESS') && !res.error) {
        handleToggleDrawer('createSopTpl', false)()
        setModalProps(successfulCreate)
        return
      }

      setModalProps(failedCreate)
      return
    })
  }

  const handleEditSopTplSubmit = (data) => {
    const onCloseAndOnOkSuccess = () => {
      setModalProps({ open: false })
      fetchSopTpls()
      fetchSopTplById(sopTplId || drawer?.sopTplId)
      navigate(pathname)
    }

    const successfulEdit = {
      open: true,
      showCancel: false,
      onClose: onCloseAndOnOkSuccess,
      onOk: onCloseAndOnOkSuccess,
      titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
      titleText: 'SOP Template Updated',
      contentText: 'SOP Template has been updated successfully.',
    }

    const failedEdit = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
      },
      onOk() {
        setModalProps({ open: false })
      },
      okText: 'Try Again',
      titleIcon: createElement('img', { src: iconFail, width: 48, height: 48, alt: 'i-failed' }),
      titleText: "Couldn't Update SOP Template",
      contentText: 'An error occurred while updating the SOP Template.',
      okProps: { color: 'error' },
    }

    dispatch(putSopTpl(sopTplId).update(data)).then((res) => {
      if (res.type.endsWith('_SUCCESS') && !res.error) {
        handleToggleDrawer('editSopTpl', false)()
        setModalProps(successfulEdit)
        return
      }

      setModalProps(failedEdit)
      return
    })
  }

  useEffect(() => {
    fetchDepartments()
    fetchSopTpls()
  }, [fetchSopTpls, fetchDepartments])

  useEffect(() => {
    if (sopTplId && mode) {
      switch (mode) {
        case 'edit':
          handleToggleDrawer('editSopTpl', true, { sopTplId: sopTplId })()
          break

        case 'view':
        default:
          handleToggleDrawer('viewSopTpl', true, { sopTplId: sopTplId })()
          break
      }

      return
    }

    handleToggleDrawer('viewSopTpl', false)()
  }, [sopTplId, mode])

  return (
    <TableContainer className="sop-table-container">
      <TableHeader>
        <Stack sx={{ width: '100%' }}>
          <Stack direction="row" sx={{ width: '100%', padding: '1rem 1.5rem', justifyContent: 'space-between' }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <TypographyH3>{t('sop')}</TypographyH3>

              <Chip
                label={total > 1000 ? '999+' : `${total} SOP templates`}
                color="primary"
                sx={{ p: '2px 8px 2px 8px', cursor: 'pointer' }}
              />
            </Stack>
          </Stack>

          <Divider sx={{ borderColor: 'var(--Gray-200)' }} />

          <Stack
            direction="row"
            alignItems="flex-end"
            padding="1rem 1.5rem"
            minHeight="4.875rem"
            flexWrap="wrap"
            columnGap="1rem"
            rowGap="0.5rem"
            justifyContent="space-between"
          >
            <Filter ref={filterRef} onFormChange={handleFilters} />

            <ButtonGroup>
              <Button variant="contained" startIcon={<RiAddLine />} onClick={handleToggleDrawer('createSopTpl', true)}>
                {t('create_new_sop')}
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </TableHeader>

      <TableV3
        data={transformSopToTable(sopTpls)}
        page={page}
        pageCount={pageCount}
        columns={tableColumns('sop')}
        onStateChange={handleTableChange}
      />

      <SidePanelV2
        titleText="Create SOP Template"
        open={drawer.createSopTpl}
        onClose={() => {
          handleToggleDrawer('createSopTpl', false)()
        }}
        footerEl={
          <FooterDrawer>
            <Button className="gray" variant="outlined" onClick={handleToggleDrawer('createSopTpl', false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" onClick={() => createSopTplRef.current.submit()}>
              {t('create')}
            </Button>
          </FooterDrawer>
        }
      >
        <CreateSopTpl ref={createSopTplRef} onSubmit={handleCreateSopTplSubmit} />
      </SidePanelV2>

      <SidePanelV2
        titleText={sopTpl?.name ? `${sopTpl?.name}` : ''}
        supportingText={sopTpl?.code ? `code: ${sopTpl.code}` : ''}
        open={drawer.viewSopTpl}
        onClose={() => {
          handleToggleDrawer('viewSopTpl', false)()
          dispatch(clearSopTpl())
          navigate(pathname)
        }}
        footerEl={null}
      >
        {sopTplId && <ViewSopTpl id={sopTplId || drawer?.sopTplId} readonly />}
      </SidePanelV2>

      <SidePanelV2
        titleText={sopTpl?.name ? `${sopTpl?.name} (${t('Edit')})` : ''}
        supportingText={sopTpl?.code ? `code: ${sopTpl.code}` : ''}
        open={drawer.editSopTpl}
        onClose={() => {
          handleToggleDrawer('editSopTpl', false)()
          dispatch(clearSopTpl())
          navigate(pathname)
        }}
        footerEl={
          <FooterDrawer>
            <Button
              className="gray"
              variant="outlined"
              onClick={() => {
                handleToggleDrawer('editSopTpl', false)
                dispatch(clearSopTpl())
                navigate(pathname)
              }}
            >
              {t('cancel')}
            </Button>
            <Button variant="contained" onClick={() => editSopTplRef.current.submit()}>
              {t('save')}
            </Button>
          </FooterDrawer>
        }
      >
        {sopTplId && (
          <EditSopTpl ref={editSopTplRef} id={sopTplId || drawer?.sopTplId} onSubmit={handleEditSopTplSubmit} />
        )}
      </SidePanelV2>

      <ModalV2 {...modalProps} />
    </TableContainer>
  )
}

export default SopMGTList

const FooterDrawer = styled('footer')({
  display: 'flex',
  width: '100%',
  gap: '1rem',
  justifyContent: 'flex-end',
  padding: '1rem',
})
