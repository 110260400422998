import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  getEndOfWeek,
  getStartOfWeek,
  DateTimeHHmmDisplay,
  DateDisplay,
  getAdmin,
  getCurrentDateTime,
} from 'utils/common'

import styled from 'styled-components'
import { Avatar, Box, Stack } from '@mui/material'
import Button from 'components/form/button/Button'
import TextArea from 'components/form/TextArea'
import FileUploadProgress2 from 'components/form/FileUploadProgress2'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Dropdown from 'components/form/Dropdown'
import Divider from 'components/common/Divider'
import DialogConfirm from 'components/dialog/DialogConfirm'

import { LiaTimesSolid } from 'react-icons/lia'
import fileIcon from 'assets/images/file-question.png'

import { useDispatch, useSelector } from 'react-redux'
import { selectWeeklySummaryReport } from 'redux/selectors'
import { getProjectTasks, setModalInfo, updateWeeklySummaryReport } from 'redux/actions/weeklySummaryReport'
import { getImages } from 'redux/actions/master'

const Div = styled.div`
  position: relative;
  padding: 24px 24px 0;

  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  .highlight-text {
    color: var(--Primary-600);
    font-weight: 700;
  }

  .side-panel-heading {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .icon {
    cursor: pointer;
  }

  .input-heading {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    margin-bottom: 6px;
  }

  .footer-side-panel {
    background-color: var(--Base-White);

    position: sticky;
    bottom: 0;
    width: 100%;
    padding-bottom: 16px;
  }

  .creator-info {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`

function EditWeeklySummaryReport({ onClose }) {
  // external hook
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [startWeek, setStartWeek] = useState('')
  const [endWeek, setEndWeek] = useState('')

  const fetchProjectTask = useCallback(() => {
    dispatch(getProjectTasks())
  }, [dispatch])
  const initialCurrentWeek = () => {
    const currentDate = new Date()
    const startWeek = getStartOfWeek(currentDate)
    const endWeek = getEndOfWeek(currentDate)

    setStartWeek(DateDisplay(startWeek))
    setEndWeek(DateDisplay(endWeek))
  }

  useEffect(() => {
    fetchProjectTask()
    initialCurrentWeek()
  }, [])

  // project task options
  const { projectTasks, weeklySummaryReportInfo } = useSelector(selectWeeklySummaryReport)
  const [projectOptions, setProjectOptions] = useState([])

  useEffect(() => {
    const newProjectOptions = projectTasks?.map((v) => ({
      text: v.name,
      value: v.id,
    }))
    setProjectOptions(newProjectOptions)
  }, [projectTasks])

  // form
  const [form, setForm] = useState()

  const fetchImages = useCallback(
    async (params) => {
      const result = await dispatch(getImages(params))
      return result
    },
    [dispatch]
  )

  const onChangeForm = (name, value) => {
    if (
      value.length <= 2000 &&
      (name === 'what_done_this_week' ||
        name === 'result' ||
        name === 'problems' ||
        name === 'how_problem_was_solved' ||
        name === 'next_week_plan')
    )
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }))
    else if (
      name !== 'what_done_this_week' &&
      name !== 'result' &&
      name !== 'problems' &&
      name !== 'how_problem_was_solved' &&
      name !== 'next_week_plan'
    )
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }))
  }

  useEffect(() => {
    const {
      project_task,
      what_done_this_week,
      result,
      problems,
      how_problem_was_solved,
      next_week_plan,
      weekly_summary_files,
    } = weeklySummaryReportInfo

    if (weekly_summary_files?.length > 0) {
      const params = weekly_summary_files?.map((v, i) => ({ [`filters[id][$in][${i}]`]: v?.file_id }))
      fetchImages(params).then((res) => {
        const initialFiles = res.map((v) => ({
          id: v.id,
          url: v.url,
          name: v.alternativeText,
          size: v.size,
          progress_percent: 100,
          progress_display: 'OK',
          progress_is_error: false,
        }))
        setFiles(initialFiles)
      })
    } else {
      setFiles([])
    }

    setForm((prev) => ({
      ...prev,
      project_task,
      what_done_this_week,
      result,
      problems,
      how_problem_was_solved,
      next_week_plan,
      weekly_summary_files: weekly_summary_files?.map((v) => v.file_id),
    }))
  }, [weeklySummaryReportInfo])

  const acceptList = {
    images: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    files: {
      'text/csv': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.ms-excel': [],
      'application/zip': [],
    },
    videos: {
      'video/x-msvideo': [],
      'video/mp4': [],
      'video/mpeg': [],
      'video/webm': [],
    },
    audios: {
      'audio/mpeg': [],
      'audio/wav': [],
      'audio/webm': [],
    },
  }
  const multiple = true
  const error = false
  const [files, setFiles] = useState([])
  const [acceptTypes, setAcceptTypes] = useState([])
  const [mimeTypes, setMimeTypes] = useState([])

  const initiateFileType = () => {
    const allowedTypes = []
    const acceptType = allowedTypes.reduce((pre, cur) => {
      return {
        ...pre,
        ...acceptList[cur],
      }
    }, {})
    setAcceptTypes(acceptType)
  }
  const initiateMimeTypes = () => {
    const categoryObjects = Object.values(acceptList)
    const mimeTypes = categoryObjects.flatMap((category) => Object.keys(category))

    setMimeTypes(mimeTypes)
  }
  const handleAddFile = (fileId) => {
    setForm((prev) => ({
      ...prev,
      weekly_summary_files: [...prev.weekly_summary_files, fileId],
    }))
  }
  const handleDeleteFile = (id) => {
    setFiles((prev) => prev.filter((v) => v.id !== id))
    setForm((prev) => ({
      ...prev,
      weekly_summary_files: prev.weekly_summary_files.filter((v) => v !== id),
    }))
  }

  useEffect(() => {
    initiateFileType()
    initiateMimeTypes()
  }, [])

  const [isEditMode, setIsEditMode] = useState(false)

  const toggleIsEditMode = () => {
    setIsEditMode(!isEditMode)
  }
  const cancelEdit = () => {
    toggleIsEditMode()
  }

  // confirm edit
  const initialConfirmMessage = {
    heading: '',
    subHeading: '',
  }
  const [confirmMessage, setConfirmMessage] = useState(initialConfirmMessage)
  const [isConfirmEdit, setIsConfirmEdit] = useState(false)

  const getProject = () => {
    const project = projectOptions.find((v) => v.value === form.project_task)
    return project ? project.text : '-'
  }
  const openConfirmEdit = () => {
    setConfirmMessage({
      heading: 'Confirm update weekly summary report?',
      subHeading: (
        <Box>
          <div>Project : {getProject()}</div>
          <div>
            Week : {startWeek} - {endWeek}
          </div>
        </Box>
      ),
    })
    setIsConfirmEdit(true)
  }
  const closeConfirmEdit = () => {
    setIsConfirmEdit(false)
  }
  const onSubmitEdit = () => {
    const { employee_info } = getAdmin()

    const request = {
      data: {
        ...form,
        updated_by: employee_info.id,
        current_date: getCurrentDateTime(),
      },
    }

    dispatch(updateWeeklySummaryReport(weeklySummaryReportInfo.id, request))
    dispatch(
      setModalInfo({
        project_name: getProject(),
        start_week: startWeek,
        end_week: endWeek,
      })
    )
    closeConfirmEdit()
    toggleIsEditMode()
  }

  return (
    <Div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div className="side-panel-heading">Edit Weekly Summary Report</div>
        <LiaTimesSolid className="icon" size={18} onClick={onClose} />
      </Stack>

      <Box sx={{ mb: 3 }}>
        <div>Details of what occurred in this week</div>
        <div className="highlight-text">
          {startWeek} - {endWeek}
        </div>
      </Box>

      <Box sx={{ mb: 1.5 }}>
        <Divider />
      </Box>

      <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center">
          <Avatar
            sx={{ width: 44, height: 44, mr: '8px' }}
            alt="creator weekly summary report"
            src={weeklySummaryReportInfo?.employee_info?.image}
          />
          <Box className="creator-info">
            <div>{weeklySummaryReportInfo?.employee_info?.name}</div>
            <div>{DateTimeHHmmDisplay(weeklySummaryReportInfo?.createdAt)}</div>
          </Box>
        </Stack>
        {!isEditMode && <Button onClick={toggleIsEditMode}>Edit</Button>}
      </Stack>

      <Box sx={{ mb: 2 }}>
        <div className="input-heading">Project</div>
        <Dropdown
          disabled={true}
          value={form?.project_task}
          onChange={(value) => onChangeForm('project_task', value)}
          optionList={projectOptions}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <div className="input-heading">What’s done this week</div>
        <TextArea
          value={form?.what_done_this_week}
          onChange={(event) => onChangeForm('what_done_this_week', event.target.value)}
          placeholder="Enter things occurred in this past week"
          disabled={!isEditMode}
        />
        <p>Count {form?.what_done_this_week?.length} / 2000</p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <div className="input-heading">Result</div>
        <TextArea
          value={form?.result}
          onChange={(event) => onChangeForm('result', event.target.value)}
          placeholder="Enter weekly result"
          disabled={!isEditMode}
        />
        <p>Count {form?.result?.length} / 2000</p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <div className="input-heading">Problems</div>
        <TextArea
          value={form?.problems}
          onChange={(event) => onChangeForm('problems', event.target.value)}
          placeholder="Does this project have any problems?"
          disabled={!isEditMode}
        />
        <p>Count {form?.problems?.length} / 2000</p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <div className="input-heading">How problem was solved?</div>
        <TextArea
          value={form?.how_problem_was_solved}
          onChange={(event) => onChangeForm('how_problem_was_solved', event.target.value)}
          placeholder="How do you solve this problems?"
          disabled={!isEditMode}
        />
        <p>Count {form?.how_problem_was_solved?.length} / 2000</p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <div className="input-heading">Next Week’s Plan</div>
        <TextArea
          value={form?.next_week_plan}
          onChange={(event) => onChangeForm('next_week_plan', event.target.value)}
          placeholder="What is next week’s plan for this project?"
          disabled={!isEditMode}
        />
        <p>Count {form?.next_week_plan?.length} / 2000</p>
      </Box>

      <Box>
        <FileUploadProgress2
          multiple={multiple}
          accept={acceptTypes}
          mimeTypes={mimeTypes}
          file={files.map((v) => ({ ...v, url: `${v.url}` }))}
          onAddFile={handleAddFile}
          onDeleteFile={handleDeleteFile}
          error={error}
          disabled={!isEditMode}
          FileListData={files}
          setFileListData={setFiles}
        />
      </Box>

      {isEditMode && (
        <Box className="footer-side-panel">
          <Box sx={{ mb: 2 }}>
            <Divider />
          </Box>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <ButtonOutline onClick={cancelEdit}>Cancel</ButtonOutline>
            <Button onClick={openConfirmEdit}>Update</Button>
          </Stack>
        </Box>
      )}

      {/* Dialog */}
      <DialogConfirm
        open={isConfirmEdit}
        onClose={closeConfirmEdit}
        onSubmit={onSubmitEdit}
        icon={fileIcon}
        title={confirmMessage.heading}
        description={confirmMessage.subHeading}
        textYes={t('update')}
        textNo={t('cancel')}
      />
    </Div>
  )
}

export default EditWeeklySummaryReport
