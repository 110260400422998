import i18n from 'i18next'
import { CgClapperBoard } from 'react-icons/cg'
import { FaListUl } from 'react-icons/fa6'
import { FiSettings, FiUser } from 'react-icons/fi'
import { LiaLanguageSolid } from 'react-icons/lia'
import { LuClipboardCheck, LuWarehouse } from 'react-icons/lu'
import { MdOutlineAdminPanelSettings } from 'react-icons/md'
import { RiBox3Line, RiGroupLine, RiHome6Line, RiLockPasswordLine, RiLogoutBoxLine } from 'react-icons/ri'
import { TbBuilding, TbCircleFilled, TbDiscount2, TbLayoutGrid, TbMail } from 'react-icons/tb'

export const mappedIcons = (key, mode = 'default') => {
  switch (key) {
    /** 1 */
    case 'home':
      return <RiHome6Line fontSize={24} />

    /** 2 */
    case 'all_service':
      return <TbLayoutGrid fontSize={24} />

    /** 3 */
    case 'new_message':
      return <TbMail fontSize={24} />

    /** 4 */
    case 'daily_work_management':
      return <FaListUl fontSize={24} />

    /** 5 */
    case 'my_task':
      return <LuClipboardCheck fontSize={24} />

    /** 6 */
    case 'department_task':
      return <TbBuilding fontSize={24} />

    /** 7 */
    case 'project_and_planning':
      return <CgClapperBoard fontSize={24} />

    /** 8 */
    // case 'employee_services':
    // NOTE: This icon is not available in the current project.

    /** 9 */
    case 'my_profile':
      return <FiUser fontSize={24} />

    case 'human_resources':
      return <RiGroupLine fontSize={24} />

    case 'system_configuration':
      return <FiSettings fontSize={24} />

    case 'warehouse_management':
      return <LuWarehouse fontSize={24} />

    case 'sales':
      return <TbDiscount2 fontSize={24} />

    case 'system_administration':
      return <MdOutlineAdminPanelSettings fontSize={24} />

    /** Special Icons */
    case 'change_language':
      return <LiaLanguageSolid fontSize={24} />
    case 'change_password':
      return <RiLockPasswordLine fontSize={24} />
    case 'logout':
      return <RiLogoutBoxLine fontSize={24} />

    default:
      return mode !== 'extra' ? <RiBox3Line fontSize={24} /> : <TbCircleFilled opacity={0.5} fontSize={10} />
  }
}

export const profileMenuItems = () => {
  const handleToggleLanguage = (language) => {
    return () => {
      i18n.changeLanguage(language)
      localStorage.setItem('lang', language)
      window.location.reload()
    }
  }

  const extraItems = [
    {
      key: 'change_password',
      group_menu: 'myAccount_group',
      name_th: 'เปลี่ยนรหัสผ่าน',
      name_en: 'Change password',
      pathto: '/change-password',
      children: null,
      permission_tab: null,
      permission_other: null,
    },
    {
      key: 'change_language',
      group_menu: 'myAccount_group',
      name_th: 'เปลี่ยนภาษา',
      name_en: 'Language setting',
      pathto: '/',
      children: [
        {
          key: 'th',
          group_menu: 'myAccount_group',
          name_th: 'ไทย',
          name_en: 'Thai',
          pathto: handleToggleLanguage('th'),
          children: null,
          permission_tab: null,
          permission_other: null,
        },
        {
          key: 'en',
          group_menu: 'myAccount_group',
          name_th: 'อังกฤษ',
          name_en: 'English',
          pathto: handleToggleLanguage('en'),
          children: null,
          permission_tab: null,
          permission_other: null,
        },
      ],
      permission_tab: null,
      permission_other: null,
    },
  ]

  return extraItems
}

export const findMatchedMenu = (menuItems = [], searchValues = []) => {
  const matchedItems = []
  const menuItemsWithTypes = menuItems.map((item) => {
    if (item.pathto) {
      return {
        ...item,
        type: 'page',
      }
    }

    if (item.children) {
      return {
        ...item,
        type: 'main',
      }
    }

    return {
      ...item,
      type: 'none',
    }
  })

  const searchItem = (item, searchValue) => {
    const lowerSearchName = searchValue.name.toLowerCase()
    const lowerNameEn = item.name_en.toLowerCase()
    const nameTh = item.name_th

    if (lowerNameEn.includes(lowerSearchName) || nameTh.includes(searchValue.name)) {
      const matchedItem = matchedItems.find((matchedItem) => matchedItem.id === searchValue.id)
      const generateType = (item) => {
        if (item.pathto) {
          return 'page'
        }

        if (item.children) {
          return 'sub'
        }

        return 'none'
      }

      const itemWithTypes = {
        ...item,
        type: item.type || generateType(item),
      }

      if (matchedItem) {
        matchedItem.result.push(itemWithTypes)
      } else {
        matchedItems.push({
          ...searchValue,
          result: [itemWithTypes],
        })
      }
    }

    if (item.children) {
      item.children.forEach((child) => searchItem(child, searchValue))
    }
  }

  for (let i = 0; i < menuItemsWithTypes.length; i++) {
    const item = menuItemsWithTypes[i]

    for (let j = 0; j < searchValues.length; j++) {
      const searchValue = {
        ...searchValues[j],
      }

      searchItem(item, searchValue)
    }
  }

  return matchedItems
}
