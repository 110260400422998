import { getAPI, postAPI, putAPI } from '../../utils/apiTeam'
import {
  GET_GROUP_TEAM,
  GET_GROUP_TEAM_BY_ID,
  CREATE_GROUP_TEAM,
  EDIT_GROUP_TEAM,
  DELETE_GROUP_TEAM,
  GET_TEAM,
  GET_TEAM_BY_ID,
  CREATE_TEAM,
  EDIT_TEAM,
  DELETE_TEAM,
  GET_MEMBER,
  CREATE_TEAM_MEMBER,
  CREATE_REPRESENTATIVE,
  EDIT_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
} from '../actionTypes'
import { showLoading, hideLoading } from '../actions/loading'

export const getGroupTeam =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_GROUP_TEAM.REQUEST })

      const { token } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: token,
        'Content-type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'filters[status][$eq]': 'active',
        'fields[0]': 'name',
        'fields[1]': 'status',
        sort: 'id:desc',
      }

      const { data, meta } = await getAPI({
        url: `/api/groups`,
        headers,
        params,
      })

      return dispatch({
        type: GET_GROUP_TEAM.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_GROUP_TEAM.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Group Team failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getGroupTeamById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_GROUP_TEAM_BY_ID.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/groups/${id}`,
      headers,
    })

    return dispatch({
      type: GET_GROUP_TEAM_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_GROUP_TEAM_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Group Team by ID failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createGroupTeam = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_GROUP_TEAM.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/groups`,
      data,
      headers,
    })

    return dispatch({
      type: CREATE_GROUP_TEAM.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Group Team Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_GROUP_TEAM.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Group Team Fail',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getGroupTeam())
  }
}

export const editGroupTeam = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_GROUP_TEAM.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/groups/${id}`,
      data,
      headers,
    })

    return dispatch({
      type: EDIT_GROUP_TEAM.SUCCESS,
      showAlert: true,
      alertMessage: 'Edit Group Team Successful',
    })
  } catch (err) {
    return dispatch({
      type: EDIT_GROUP_TEAM.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Edit Group Team Fail',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getGroupTeam())
  }
}

export const deleteGroupTeam = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_GROUP_TEAM.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/delete-group/${id}`,
      data: {},
      headers,
    })

    return dispatch({
      type: DELETE_GROUP_TEAM.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Group Team Successful',
    })
  } catch (err) {
    return dispatch({
      type: DELETE_GROUP_TEAM.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Delete Group Team Fail',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getGroupTeam())
  }
}

export const getTeam =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_TEAM.REQUEST })

      const { token } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: token,
        'Content-type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'filters[group][id]': filter.groupId,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'filters[status][$eq]': 'active',
        'fields[0]': 'team_id',
        'fields[1]': 'name',
        'fields[2]': 'team_number',
        'fields[3]': 'discipline',
        'fields[4]': 'team_type',
        'fields[5]': 'period',
        'fields[6]': 'status',
        'populate[team_members]': '*',
        sort: 'id:desc',
      }
      if (filter.period !== '') params['filters[period][$eq]'] = filter.period

      const { data, meta } = await getAPI({
        url: `/api/teams`,
        headers,
        params,
      })

      return dispatch({
        type: GET_TEAM.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_TEAM.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Team failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getTeamById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TEAM_BY_ID.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-type': 'application/json',
    }
    const params = {
      'populate[group][fields][0]': 'id',
    }

    const { data } = await getAPI({
      url: `/api/teams/${id}`,
      headers,
      params,
    })

    return dispatch({
      type: GET_TEAM_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_TEAM_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Team by ID failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createTeam = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_TEAM.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/teams`,
      data,
      headers,
    })

    return dispatch({
      type: CREATE_TEAM.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Team Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_TEAM.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Team Fail',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getTeam())
  }
}

export const editTeam = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_TEAM.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/teams/${id}`,
      data,
      headers,
    })

    return dispatch({
      type: EDIT_TEAM.SUCCESS,
      showAlert: true,
      alertMessage: 'Edit Team Successful',
    })
  } catch (err) {
    return dispatch({
      type: EDIT_TEAM.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Edit Team Fail',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getTeam())
  }
}

export const deleteTeam = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_TEAM.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/delete-team/${id}`,
      data: {},
      headers,
    })

    return dispatch({
      type: DELETE_TEAM.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Team Successful',
    })
  } catch (err) {
    return dispatch({
      type: DELETE_TEAM.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Delete Team Fail',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getTeam())
  }
}

export const getMember =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MEMBER.REQUEST })

      const { token, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: token,
        'Content-type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'filters[status][$ne]': 'delete',
        'filters[team][id]': filter.teamId,
        'populate[employee]': true,
        'populate[employeecompany]': company_info.id,
        sort: 'id:desc',
      }

      const { data, meta } = await getAPI({
        url: `/api/team-members`,
        headers,
        params,
      })

      return dispatch({
        type: GET_MEMBER.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_MEMBER.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Member failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const createTeamMember = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_TEAM_MEMBER.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/custom-team-member/create-many`,
      data,
      headers,
    })

    return dispatch({
      type: CREATE_TEAM_MEMBER.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Member Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_TEAM_MEMBER.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Member Fail',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getTeam())
  }
}

export const createRepresentative = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_REPRESENTATIVE.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/custom-team-member/create-many`,
      data,
      headers,
    })

    return dispatch({
      type: CREATE_REPRESENTATIVE.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Representative Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_REPRESENTATIVE.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Representative Fail',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getTeam())
  }
}

export const editTeamMember = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_TEAM_MEMBER.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/custom-team-member/update-many`,
      data,
      headers,
    })

    return dispatch({
      type: EDIT_TEAM_MEMBER.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Member Successful',
    })
  } catch (err) {
    return dispatch({
      type: EDIT_TEAM_MEMBER.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update Member Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteTeamMember = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_TEAM_MEMBER.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/custom-team-member/update-many`,
      data,
      headers,
    })

    return dispatch({
      type: DELETE_TEAM_MEMBER.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Member Successful',
    })
  } catch (err) {
    return dispatch({
      type: DELETE_TEAM_MEMBER.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Delete Member Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}
