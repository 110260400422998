import React, { useState, useCallback, useEffect, useRef } from 'react'

import { DateTimeHHmmDisplay } from 'utils/common'

import { useDispatch, useSelector } from 'react-redux'
import { selectProjectTask } from 'redux/selectors'
import { createProjectTaskNote, getProjectTaskNote } from 'redux/actions/projectTask'

import styled from 'styled-components'
import { Avatar, Box, Stack } from '@mui/material'
import Card from 'components/common/Card'
import Button from 'components/form/button/Button'
import UploadFilePaperClip from 'components/form/UploadFilePaperClip'

import { MdOutlineFilePresent } from 'react-icons/md'
import { TbPointFilled } from 'react-icons/tb'
import { GoKebabHorizontal } from 'react-icons/go'

const Div = styled.div`
  margin-top: 1rem;
  .online-icon {
    color: var(--success-500);
  }

  .name-user {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .time-create-note {
    color: var(--Gray-600);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .card-note {
    padding: 10px 14px;

    &.note {
      color: var(--Gray-900);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .pdf-icon {
      width: 40px;
      height: 40px;
    }

    .file-name {
      color: var(--Gray-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .size-file {
      color: var(--Gray-600);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
`
const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;

  .inside-content-text-area {
    position: absolute;
    bottom: 15px;
    right: 10px;
  }

  .icon {
    cursor: pointer;
    color: var(--Gray-500);
  }
`
const TextArea = styled.textarea`
  font-family: 'Inter', 'Noto Sans Thai';
  border-radius: 8px;
  border: 1px solid var(--Gray-300);
  background: var(--Base-White);

  /* padding: 8px 12px; */
  padding: 12px 14px;
  width: 100%;
  height: 130px;
  resize: none;

  &:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    color: var(--Gray-500);
  }
`

function NoteTask({ id }) {
  // external hook
  const dispatch = useDispatch()

  // ref scroll
  const refScroll = useRef(null)

  // project task notes
  const { projectTaskNotes } = useSelector(selectProjectTask)

  const fetchProjectTaskNotes = useCallback(
    (id) => {
      dispatch(getProjectTaskNote(id))
    },
    [dispatch]
  )
  const openFile = (url) => {
    window.open(url, '_blank')
  }

  useEffect(() => {
    fetchProjectTaskNotes(id)
  }, [id])

  // note
  const [note, setNote] = useState('')

  const onChangeNote = (event) => {
    const { value } = event.target
    setNote(value)
  }
  const onSubmitNote = () => {
    if (note !== '') {
      const request = {
        note,
        attach_file: null,
        project_task: id,
      }

      dispatch(createProjectTaskNote(request))
      setNote('')
    }
  }
  const uploadFileForNote = (file) => {
    if (file) {
      const request = {
        note: null,
        attach_file: file.id,
        project_task: id,
      }

      dispatch(createProjectTaskNote(request))
      setNote('')
    }
  }

  // state request
  const { state, isLoading } = useSelector(selectProjectTask)

  const scrollToBottom = () => {
    const currentRefScroll = refScroll.current
    if (currentRefScroll) {
      currentRefScroll.scrollTop = currentRefScroll.scrollHeight
    }
  }

  useEffect(() => {
    if (
      isLoading === false &&
      (state === 'CREATE_PROJECT_TASK_NOTES.SUCCESS' || state === 'CREATE_PROJECT_TASK_NOTES.FAILURE')
    ) {
      scrollToBottom()
    }
  }, [state, isLoading])

  return (
    <Div>
      <Stack direction="column" justifyContent="space-between">
        <Box
          ref={refScroll}
          sx={{
            height: 450,
            overflow: 'auto',
            mb: 2,
          }}
        >
          {projectTaskNotes.map((v) => (
            <Stack key={v.id} direction="row" sx={{ width: '100%', mb: 2 }}>
              <Box sx={{ mr: '12px' }}>
                <Box sx={{ position: 'relative' }}>
                  <Avatar sx={{ width: 44, height: 44, mr: '8px' }} alt="user photo" src={v.creator_photo} />
                  <Box sx={{ position: 'absolute', bottom: -14, right: -1, zIndex: 'tooltip' }}>
                    <TbPointFilled size={30} className="online-icon" />
                  </Box>
                </Box>
              </Box>
              <Box style={{ flexGrow: 1 }}>
                <Stack sx={{ mb: '6px' }} direction="row" justifyContent="space-between">
                  <div className="name-user">{v.creator}</div>
                  <div className="time-create-note">{DateTimeHHmmDisplay(v.createdAt)}</div>
                </Stack>

                <Card className="card-note note">
                  {v.note && <div>{v.note}</div>}
                  {v.attach_file && (
                    <Stack
                      sx={{ cursor: 'pointer' }}
                      direction="row"
                      alignItems="center"
                      onClick={() => openFile(v.attach_file.url)}
                    >
                      <Box sx={{ mr: '12px' }}>
                        <MdOutlineFilePresent size={30} />
                      </Box>

                      <Box>
                        <div className="file-name">
                          {v.attach_file.name}
                          {v.attach_file.ext}
                        </div>
                        <div className="size-file">{v.attach_file.size} KB</div>
                      </Box>
                    </Stack>
                  )}
                </Card>
              </Box>
            </Stack>
          ))}
        </Box>

        <TextAreaWrapper>
          <TextArea value={note} onChange={onChangeNote} placeholder="let note to this project" />
          <Stack className="inside-content-text-area" direction="row" alignItems="center">
            <Box sx={{ mr: '16px' }}>
              <UploadFilePaperClip setAttachFile={uploadFileForNote} />
            </Box>
            <Box sx={{ mr: '16px' }}>
              <GoKebabHorizontal className="icon" size={20} />
            </Box>
            <Button onClick={onSubmitNote}>Send</Button>
          </Stack>
        </TextAreaWrapper>
      </Stack>
    </Div>
  )
}

export default NoteTask
