import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import DialogTaskPreviewer from '../../components/dialog/DialogTaskPreviewer'
import ApproveLanding from '../../components/feature/approvals/ApproveLanding'
import TableApproval from '../../components/feature/table/TableApproval'
import Dropdown from '../../components/form/Dropdown'
import SearchText from '../../components/form/SearchText'
import { getDepartmentsAndPositions } from '../../redux/actions/master'
import { updateRequestApproveItem } from '../../redux/actions/summaryTask'
import { selectMaster } from '../../redux/selectors'
import { getAdminUser,GetPermissionTabList,GetPermissionOther } from '../../utils/common'

import { useTranslation } from 'react-i18next'

import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'
const Div = styled.div`
  .w-100 {
    width: 100%;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  header {
    padding: 24px 14px 0;
    border-radius: 8px;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tab-menu {
    width: fit-content;
    margin: 20px 0;
    display: flex;
    border-bottom: 1px solid var(--Gray-200);

    .btn-menu-style {
      border: none;
      border-bottom: 2px solid transparent;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: var(--Primary-50);
      border-bottom: 2px solid var(--Primary-600) !important;
    }
  }
  .search-input-style {
    width: 100%;
    padding: 10px 12px;
  }
  .drop-down {
    flex: 1;
    margin-right: 10px;
  }
  .filter-header {
    width: 80%;

    .wrp-filter-menu {
      position: relative;
      display: flex;
    }
    .wrp-search-box {
      flex: 1;
      min-width: 350px;
      padding-right: 10px;
    }

    .wrp-filter-btn {
      position: relative;
    }

    .dialog-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 300px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);
      max-height: 300px;

      .wrp-person-list {
        position: relative;
      }
      .person-list {
        height: 220px;
        overflow-y: scroll;
      }

      .person-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 4px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
        }

        .img-profile {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          background-color: var(--Gray-200);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .person-item:hover {
        background-color: var(--Gray-50);
      }
    }

    .filter-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 500px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);

      .wrp-filter-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clear-btn {
          padding: 4px 6px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        .clear-btn:hover {
          background: var(--Primary-25);
        }
      }
      .wrp-filter-option {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .title-option {
          font-weight: 600;
          margin-bottom: 6px;
        }
        .option-item-style {
          border: 1px solid var(--Gray-300);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Gray-50);
        }
        .option-item-style:hover {
          background-color: var(--Gray-100);
        }
        .option-item-active {
          border: 1px solid var(--Primary-600);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Primary-600);
          color: #fff;
        }
      }
    }
  }
`

const Approvals = () => {
  const { t } = useTranslation()
  const initLang = localStorage.getItem('lang')
  const dispatch = useDispatch()
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { departments } = useSelector(selectMaster)
  const user = getAdminUser()

  const [currentMenuTabSelected, setCurrentMenuTabSelected] = useState('Approvals_Received')
  const [menuTabList, setMenuTabList] = useState([])
  //--- ST ส่วนของ Permission LV3-4
  //const { permission } = JSON.parse(localStorage.getItem('admin'))
  useEffect(() => {
    const this_tab_list = GetPermissionTabList('approval');
    if(this_tab_list.length > 0){
      const tab_permission = this_tab_list?.filter((it_2) => it_2.canRead === true)
      let temp_tab = [];
      let index_tab = 0;
      for (const  objtab_permission of tab_permission) {
        temp_tab.push({
          name : initLang == 'th' ? objtab_permission.name_th : objtab_permission.name_en,
          value : objtab_permission.key,
          active_status : index_tab == 0 ? true : false
        })
        
        if(index_tab == 0){
          setCurrentMenuTabSelected(objtab_permission.key)
          
        }
        index_tab = index_tab + 1;
      }
      setMenuTabList(temp_tab)
    }
    /*
    const this_sub_menu_permission = permission['Task Management'].filter((it_2) => it_2.path === '/task/approvals'); //--- Path ของ SubMenu
    if(this_sub_menu_permission.length > 0){
      const tab_permission = this_sub_menu_permission[0].permission_tab?.filter((it_2) => it_2.is_read === true)
      let temp_tab = [];
      let index_tab = 0;
      for (const  objtab_permission of tab_permission) {
        temp_tab.push({
          name : initLang == 'th' ? objtab_permission.menu_setting.name_th : objtab_permission.menu_setting.name_en,
          value : objtab_permission.menu_setting.rout_path,
          active_status : index_tab == 0 ? true : false
        })
        if(index_tab == 0){
          setCurrentMenuTabSelected(objtab_permission.menu_setting.rout_path)       
        }
        index_tab = index_tab + 1;
      }
      setMenuTabList(temp_tab)
    }
    */
  }, [])
  //--- ED ส่วนของ Permission LV3-4

  const [textSearch, setTextSearch] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [filter, setFilter] = useState({
    owner: employee_info?.id || -99,
    name: '',
    type: '',
    department: '',
    request_action: 'request',
  })
  const [departmentSelected, setDepartmentSelected] = useState('')
  const [typeSelected, setTypeSelected] = useState('')
  const [departmentDropdownList, setDepartmentDropdownList] = useState([])
  const [typeDropdownList, setTypeDropdownList] = useState([
    {
      text: t('please_select_type'),
      value: '',
    },
    {
      text: t('document_approval'),
      value: 'Document Approval',
    },
    {
      text: t('task_approval'),
      value: 'Task Approval',
    },
  ])

  const [requestId, setRequestId] = useState()
  const [taskId, setTaskId] = useState()
  const [docType, setDocType] = useState()
  const [isShowApproveLanding, setIsShowApproveLanding] = useState(false)
  const [isShowPreviewDialog, setIsShowPreviewDialog] = useState(false)
  const [docId, setDocId] = useState()
  const [fileData, setFileData] = useState()
  const [taskType, setTaskType] = useState()
  const [failModal, setFailModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)

  useEffect(() => {
    dispatch(getDepartmentsAndPositions())
  }, [dispatch])

  useEffect(() => {
    if (departments?.length > 0) {
      let list = []
      for (let i = 0; i < departments.length; i++) {
        let obj = {
          text: departments[i].name,
          value: departments[i].id,
        }
        list = [...list, obj]
      }
      setDepartmentDropdownList(list)
    }
  }, [departments])

  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
      } else {
        tmpList[i].active_status = false
      }
    }
    setCurrentMenuTabSelected(data.value)
    setMenuTabList([...tmpList])
    const filterText = data.value =="Approvals_Received"?"request":data.value =="Approvals_Approved"?"approve":"reject"
    let tmpFilter = { ...filter }
    tmpFilter.request_action = filterText
    setFilter(tmpFilter)
  }

  const onSubmitTextChange = (value) => {
    let tmpFilter = { ...filter }
    tmpFilter.name = value
    setFilter(tmpFilter)
    setSearchFilter(value)
  }

  const handleClearText = (value) => {
    let tmpFilter = { ...filter }
    tmpFilter.name = ''
    setFilter(tmpFilter)
    setSearchFilter('')
    setTextSearch('')
  }

  const setDepartmentChange = (value) => {
    let tmpFilter = { ...filter }
    tmpFilter.department = value
    setFilter(tmpFilter)
    setDepartmentSelected(value)
  }

  const setTypeChange = (value) => {
    let tmpFilter = { ...filter }
    tmpFilter.type = value
    setFilter(tmpFilter)
    setTypeSelected(value)
  }

  const handleClickView = (type, data) => {
    console.log('type: ' + JSON.stringify(type, null, 2))
    console.log('data: ' + JSON.stringify(data, null, 2))
    if (type == 'task') {
      setRequestId(data.id)
      setTaskId(data.task_id)
      setDocId(data.procurement_id)
      setIsShowPreviewDialog(true)
      if (data.task_ref_type == 'task routine') {
        setTaskType('task_routine')
      } else {
        //task project
        setTaskType('task_milestone')
      }
      setDocType()
    } else {
      setRequestId(data.id)
      if (data.doc_type) {
        setDocType(data.doc_type)
        setDocId(data.doc_id)
      } else {
        setDocType()
        if (data.file_detail) {
          setFileData(data.file_detail)
        } else {
          setFileData()
        }
        setDocId(data.procurement_id)
      }
      setIsShowApproveLanding(true)
    }
  }

  const handleSubmitApprove = (data) => {
    console.log("handleSubmitApprove "+JSON.stringify(data,null,2))
    if (employee_info?.id) {
      setFailModal(false)
      const tmpObj = {
        ...data,
        request_approve: requestId,
        created_by: employee_info?.id,
      }
      dispatch(updateRequestApproveItem({ data: tmpObj })).then(() => {
        setIsShowApproveLanding(false)
        const approveAction = data.action == 'Approve' ? 'Approvals_Approved' : 'Approvals_Rejected'
        handleClickTaskMenuTab({ value: approveAction })
      })
    } else {
      setFailModal(true)
    }
  }

  const handleOnUpdate = () => {
    setIsUpdate(!isUpdate)
  }

  return (
    <Div>
      <>
        <header>
          <div className="content-title">{t('approvals')}</div>
          <div className="wrp-tab-menu">
            {menuTabList?.map((item, index) => (
              <button
                onClick={() => handleClickTaskMenuTab(item)}
                key={'btn-' + index}
                className={item.active_status ? 'menu-active' : 'btn-menu-style'}
              >
                {item.name}
              </button>
            ))}
          </div>
        </header>

        <div className="filter-header mb-1-rem">
          <div className="wrp-filter-menu">
            <div className="wrp-search-box">
              <SearchText
                className={'search-input-style mr-1-rem'}
                placeholder={t('search')}
                value={textSearch}
                onChange={(e) => setTextSearch(e.target.value)}
                onSubmit={(e) => onSubmitTextChange(e)}
                onClear={() => handleClearText()}
              />
            </div>
            <Dropdown
              className="drop-down"
              value={departmentSelected}
              optionList={departmentDropdownList}
              onChange={(value) => setDepartmentChange(value)}
            />
            <Dropdown
              className="drop-down"
              value={typeSelected}
              optionList={typeDropdownList}
              onChange={(value) => setTypeChange(value)}
            />
          </div>
        </div>

        <div className="wrp-table">
          <TableApproval
            isUpdate={isUpdate}
            filters={filter}
            onClickViewTask={(e) => handleClickView('task', e)}
            onClickViewDocument={(e) => handleClickView('doc', e)}
          />
        </div>
      </>
      {isShowApproveLanding && (
        <ApproveLanding
          docType={docType}
          docId={docId}
          file={fileData}
          onClose={() => setIsShowApproveLanding(false)}
          onSubmit={handleSubmitApprove}
        />
      )}
      {isShowPreviewDialog && (
        <DialogTaskPreviewer
          requestId={requestId}
          taskId={taskId}
          open={isShowPreviewDialog}
          onClose={() => setIsShowPreviewDialog(false)}
          menuActive={'sub_task'}
          docId={docId}
          previewType={taskType}
          onUpdate={handleOnUpdate}
        />
      )}
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={t('you_may_not_fully_use_this_feature_since_you_are_not_employee')}
        nameItem={''}
        description={''}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default Approvals
