import { Divider, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Chip from 'components/common/Chip'
import SidePanelV2 from 'components/common/SidePanelV2'
import TableV3 from 'components/common/TableV3'
import Typography from 'components/common/Typography'
import DrawerDetails, { TaskDrawerTitle } from 'components/feature/operation-process/default-cards'
import Tabs from 'components/feature/operation-process/tabs'
import { APPROVER, DOCUMENT_SIGNER, REVIEWER, SW } from 'constants/operation-process'
import { GrStatusGoodSmall } from 'react-icons/gr'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { formatDate } from 'utils/dateHelpers'
import Filters from './filter-form'
import columns from './table-columns'

/** //TODO: seperate TABS enum to other file. */
const TABS = {
  SIGN_DOC: 'sign_doc',
  SOW: 'sow',
  TASK: 'task',
  REVIEW: 'review',
  APPROVAL: 'approval',
}

const init_drawer = {
  'view-sw': false,
  'view-task': false,
  'view-review': false,
  'view-approval': false,
  'view-sw-sign-doc': false,
}

const MainSection = () => {
  const { t } = useTranslation()

  const refSearch = useRef()
  const dispatch = useDispatch()
  const {
    sw: swActions,
    task: taskActions,
    review: reviewActions,
    approval: approvalActions,
    document: documentActions,
  } = opActions()
  const { getSW, clearSW } = swActions()
  const { getTask, clearTask } = taskActions()
  const { getReview, clearReviewer } = reviewActions()
  const { getApproval, clearApproval } = approvalActions()
  const { getDocSigner } = documentActions()

  const opProcSelector = useSelector(selectOpProc)
  const { sws_me, sws_me_pagination, tasks_me, tasks_me_pagination, sw, task: opTask } = opProcSelector
  const { page: sowPage, pageCount: sowPageCount, total: sowTotal } = sws_me_pagination
  const { page: taskPage, pageCount: taskPageCount, total: taskTotal } = tasks_me_pagination

  const { reviewers, reviewers_pagination, reviewer } = opProcSelector
  const { page: reviewersPage, pageCount: reviewersPageCount, total: reviewersTotal } = reviewers_pagination

  const { approvers, approvers_pagination, approver } = opProcSelector
  const { page: approverPage, pageCount: approverPageCount, total: approverTotal } = approvers_pagination

  const { docSigner_me, docSigner_me_pagination } = opProcSelector
  const { page: docSignerPage, pageCount: docSignerPageCount, total: docSignerTotal } = docSigner_me_pagination

  const [tableType, setTableType] = useState(TABS['SOW'])
  const [tableProps, setTableProps] = useState({})
  const [drawer, setDrawer] = useState(init_drawer)
  const [filters, setFilters] = useState({})

  const fetchReviewersOwner = useCallback(
    async (payload) => {
      dispatch(getReview().owner(payload))
    },
    [dispatch]
  )

  const fetchApproversOwner = useCallback(
    async (payload) => {
      dispatch(getApproval().owner(payload))
    },
    [dispatch]
  )

  const fetchTasksMe = useCallback(
    async (payload) => {
      dispatch(getTask().me(payload))
    },
    [dispatch]
  )

  const fetchSWsMe = useCallback(
    async (payload) => {
      dispatch(getSW().me(payload))
    },
    [dispatch]
  )

  const fetchDocSignersOwner = useCallback(
    async (payload) => {
      dispatch(getDocSigner().owner(payload))
    },
    [dispatch]
  )

  const reloadPage = useCallback(() => {
    fetchSWsMe()
    fetchTasksMe()
    fetchReviewersOwner()
    fetchApproversOwner()
    fetchDocSignersOwner()
  }, [fetchSWsMe, fetchTasksMe, fetchReviewersOwner, fetchApproversOwner, fetchDocSignersOwner])

  const initToggleDrawerData = {
    swData: {},
    taskData: {},
    swId: undefined,
    taskId: undefined,
    reviewId: undefined,
    approverId: undefined,
    docSignerId: undefined,
  }

  const handleToggleDrawer = (drawerName, open, data = initToggleDrawerData) => {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }

      setDrawer({ ...init_drawer, [drawerName]: open, ...data })
    }
  }

  const handleSubmitSW = (e) => {
    handleToggleDrawer('view-sw', false)(e)
    fetchSWsMe()
    // reloadPage()
  }

  const handleSubmitTask = (e) => {
    handleToggleDrawer('view-task', false)(e)
    fetchTasksMe()
    // reloadPage()
  }

  const handleUnassignSW = (e) => {
    handleToggleDrawer('view-sw', false)(e)
    fetchSWsMe()
    // reloadPage()
  }

  const handleTaskDrawer = (taskId) => {
    handleToggleDrawer('view-task', true, { taskId })()
  }

  const handleReviewDrawer = (reviewId) => {
    handleToggleDrawer('view-review', true, { reviewId })()
  }

  const handleSubmitReview = (e) => {
    handleToggleDrawer('view-review', false)(e)
    fetchReviewersOwner()
    // reloadPage()
  }

  const handleApprovalDrawer = (approverId) => {
    dispatch(getApproval().byId(approverId)).then((res) => {
      if (res.type.endsWith('_SUCCESS') && !res.error) {
        const data = res.data
        if (!data) {
          return
        }
        handleToggleDrawer('view-approval', true, { approverId })()
      }
      return
    })
  }

  const handleSubmitApproval = (e) => {
    handleToggleDrawer('view-approval', false)(e)
    fetchApproversOwner()
    // reloadPage()
  }

  const handleSubmitDocument = (e) => {
    handleToggleDrawer('view-sw-sign-doc', false)(e)
    fetchDocSignersOwner()
  }

  const handleSubTabChange = (value) => {
    setTableType(value)
    refSearch.current.reset()

    if (value === TABS['SOW']) {
      fetchSWsMe()
    }

    if (value === TABS['TASK']) {
      fetchTasksMe()
    }

    if (value === TABS['REVIEW']) {
      fetchReviewersOwner()
      return
    }

    if (value === TABS['APPROVAL']) {
      fetchApproversOwner()
      return
    }

    if (value === TABS['SIGN_DOC']) {
      fetchDocSignersOwner()
      return
    }
  }

  const transformSWsMeToTable = (data) => {
    if (!data || data.length === 0) {
      console.warn('No data provided or data is empty.')
      return []
    }

    const mapDataItem = (item) => {
      const code = (
        <Typography variant="tableLink" onClick={handleToggleDrawer('view-sw', true, { swId: item.id })}>
          {item.code}
        </Typography>
      )
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={item.status}
          color={SW.STATUS_CHIP[item.status]}
          sx={{ height: '32px' }}
        />
      )

      return { ...item, code, status }
    }

    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const transformTasksMeToTable = (data) => {
    if (!data || data.length === 0) {
      console.warn('No data provided or data is empty.')
      return []
    }

    const mapDataItem = (item) => {
      const code = (
        <Typography variant="tableLink" onClick={() => handleTaskDrawer(item.id)}>
          {item.code}
        </Typography>
      )
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={item.status}
          color={SW.STATUS_CHIP[item.status]}
          sx={{ height: '32px' }}
        />
      )

      return { ...item, code, status }
    }

    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const transformReviewsToTable = (reviewsData) => {
    if (!reviewsData || reviewsData.length === 0) {
      console.warn('No data provided or data is empty.')
      return []
    }

    const mapDataItem = (item) => {
      const code = (
        <Typography variant="tableLink" onClick={() => handleReviewDrawer(item.id)}>
          {item?.code}
        </Typography>
      )
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={item?.status}
          color={REVIEWER.STATUS_CHIP[item?.status]}
          sx={{ height: '32px' }}
        />
      )
      const sp_name = item?.sw?.sp?.name
      const sw_name = item?.sw?.name
      const due_date = item?.due_date
      const created_by = item?.sw?.sp?.employee?.first_name + ' ' + item?.sw?.sp?.employee?.last_name

      return { ...item, code, status, sp_name, sw_name, due_date, created_by }
    }

    const mappedData = reviewsData.map(mapDataItem)

    return mappedData
  }

  const transformApprovalsToTable = (approvalsData) => {
    if (!approvalsData || approvalsData.length === 0) {
      console.warn('No data provided or data is empty.')
      return []
    }

    const mapDataItem = (item) => {
      const code = (
        <Typography variant="tableLink" onClick={() => handleApprovalDrawer(item.id)}>
          {item?.code}
        </Typography>
      )
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={item?.status}
          color={APPROVER.STATUS_CHIP[item?.status]}
          sx={{ height: '32px' }}
        />
      )
      const sp_name = item?.sw?.sp?.name
      const sw_name = item?.sw?.name
      const due_date = item?.due_date
      const created_by = item?.sw?.sp?.employee?.first_name + ' ' + item?.sw?.sp?.employee?.last_name

      return { ...item, code, status, sp_name, sw_name, due_date, created_by }
    }

    const mappedData = approvalsData.map(mapDataItem)

    return mappedData
  }

  const transformSignDocToTable = (data) => {
    if (!data || data.length === 0) {
      console.warn('No data provided or data is empty.')
      return []
    }

    const mapDataItem = (item) => {
      item.document.delivery.sw.due_date = formatDate(item.document.delivery.sw.due_date)
      item.document.delivery.sw.code = (
        <Typography
          variant="tableLink"
          onClick={handleToggleDrawer('view-sw-sign-doc', true, {
            swId: item.document.delivery.sw.id,
            docSignerId: item.id,
          })}
        >
          {item.document.delivery.sw.code}
        </Typography>
      )
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={item.status}
          color={DOCUMENT_SIGNER.STATUS_CHIP[item.status]}
          sx={{ height: '32px' }}
        />
      )
      const created_by = item?.employee?.first_name + ' ' + item?.employee?.last_name

      return { ...item, status, created_by }
    }
    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const getTableProps = () => {
    let resultProps = {}

    switch (tableType) {
      case TABS.REVIEW:
        resultProps = {
          data: transformReviewsToTable(reviewers),
          page: reviewersPage,
          pageCount: reviewersPageCount,
          onStateChange({ page }) {
            const pagination = { ...reviewers_pagination, page }
            fetchReviewersOwner({ filters, pagination })
          },
        }
        break

      case TABS.APPROVAL:
        resultProps = {
          data: transformApprovalsToTable(approvers),
          page: approverPage,
          pageCount: approverPageCount,
          onStateChange({ page }) {
            const pagination = { ...approvers_pagination, page }
            fetchApproversOwner({ filters, pagination })
          },
        }
        break

      case TABS.TASK:
        resultProps = {
          data: transformTasksMeToTable(tasks_me),
          page: taskPage,
          pageCount: taskPageCount,
          onStateChange({ page }) {
            const pagination = { ...tasks_me_pagination, page }
            fetchTasksMe({ filters, pagination })
          },
        }
        break

      case TABS.SOW:
        resultProps = {
          data: transformSWsMeToTable(sws_me),
          page: sowPage,
          pageCount: sowPageCount,
          onStateChange({ page }) {
            const pagination = { ...sws_me_pagination, page }
            fetchSWsMe({ filters, pagination })
          },
        }
        break
      case TABS.SIGN_DOC:
        resultProps = {
          data: transformSignDocToTable(docSigner_me),
          page: docSignerPage,
          pageCount: docSignerPageCount,
          onStateChange({ page }) {
            const pagination = { ...docSigner_me_pagination, page }
            fetchDocSignersOwner({ filters, pagination })
          },
        }
        break

      default:
        break
    }

    return setTableProps(resultProps)
  }

  const handleFilters = (values) => {
    const keys = Object.keys(values)

    let fetchData
    switch (tableType) {
      case TABS.REVIEW:
        fetchData = fetchReviewersOwner
        break
      case TABS.APPROVAL:
        fetchData = fetchApproversOwner
        break
      case TABS.TASK:
        fetchData = fetchTasksMe
        break
      case TABS.SOW:
        fetchData = fetchSWsMe
        break
      case TABS.SIGN_DOC:
        fetchData = fetchDocSignersOwner
        break
      default:
        break
    }

    if (keys.length === 0) {
      setFilters({})
      return fetchData()
    }

    let filters = {}
    keys.forEach((key) => {
      if (!values[key]) {
        return
      }

      switch (key) {
        case 'name':
          filters = { ...filters, $or: [{ name: { $contains: values[key] } }, { code: { $contains: values[key] } }] }
          break
        case 'status':
          filters = { ...filters, status: { $eq: values[key] } }
          break
        default:
          break
      }
    })

    if (Object.keys(filters).length === 0) {
      setFilters({})

      return fetchData()
    }

    setFilters(filters)
    return fetchData({ filters })
  }

  useEffect(() => {
    reloadPage()
  }, [reloadPage])

  useEffect(() => {
    getTableProps()
  }, [
    fetchSWsMe,
    fetchTasksMe,
    fetchReviewersOwner,
    fetchApproversOwner,
    fetchDocSignersOwner,
    tableType,
    reviewers,
    approvers,
    tasks_me,
    sws_me,
    docSigner_me,
  ])

  return (
    <TableContainer key="Table_MyTask">
      <TableHeader>
        <Stack sx={{ width: '100%' }}>
          <Stack direction="row" sx={{ width: '100%', padding: '1rem 1.5rem', justifyContent: 'space-between' }}>
            <Stack direction="row" spacing={2} alignItems="center" minHeight="36.5px">
              <Typography variant="tableTitle">{t('my_task')}</Typography>
            </Stack>
          </Stack>

          <Divider sx={{ borderColor: 'var(--Gray-200)' }} />

          <Stack
            direction="row"
            alignItems="center"
            gap="1rem"
            padding="1rem 1.5rem"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Filters ref={refSearch} onFormChange={handleFilters} type={tableType} />

            <Tabs tabType="modern">
              <Tabs.MyTask
                tabCounts={{
                  sign_doc: docSignerTotal,
                  sow: sowTotal,
                  task: taskTotal,
                  review: reviewersTotal,
                  approval: approverTotal,
                }}
                onChange={handleSubTabChange}
              />
            </Tabs>
          </Stack>
        </Stack>
      </TableHeader>

      <TableV3 {...tableProps} columns={columns(tableType)} />

      <SidePanelV2
        titleText={sw?.name}
        supportingText={`${t('sow_code')}: ${sw?.code}`}
        open={drawer['view-sw']}
        onClose={(event) => {
          dispatch(clearSW())
          handleToggleDrawer('view-sw', false)(event)
        }}
      >
        <DrawerDetails.SoW
          id={drawer?.swId}
          openTask={handleTaskDrawer}
          onSubmit={handleSubmitSW}
          onUnassign={handleUnassignSW}
          onActions={{
            onClickTask: (taskId) => {
              handleTaskDrawer(taskId)
            },
            onCreateDocument: () => fetchSWsMe(),
            onDeleteDocument: () => fetchSWsMe(),
          }}
        />
      </SidePanelV2>

      <SidePanelV2
        titleText={
          <TaskDrawerTitle
            taskData={opTask}
            onClickBreadcrumb={handleToggleDrawer('view-sw', true, { swId: opTask?.sw?.id })}
          />
        }
        supportingText={`${t('task_code')}: ${opTask?.code}`}
        open={drawer['view-task']}
        onClose={(event) => {
          dispatch(clearTask())
          handleToggleDrawer('view-task', false)(event)
        }}
      >
        <DrawerDetails.Task
          taskId={drawer?.taskId}
          onSubmit={handleSubmitTask}
          onViewClick={handleToggleDrawer('view-task', false)}
        />
      </SidePanelV2>

      <SidePanelV2
        titleText={`${t('review')}: ${reviewer?.sw?.name}`}
        supportingText={`${t('code')}: ${reviewer?.code}`}
        open={drawer['view-review']}
        onClose={(event) => {
          dispatch(clearReviewer())
          handleToggleDrawer('view-review', false)(event)
        }}
      >
        <DrawerDetails.Reviewer
          reviewerId={drawer?.reviewId}
          openTask={handleTaskDrawer}
          onSubmit={handleSubmitReview}
        />
      </SidePanelV2>

      <SidePanelV2
        titleText={`${t('approvals')}: ` + approver?.sw?.name}
        supportingText={`${t('code')}: ${approver?.code}`}
        open={drawer['view-approval']}
        onClose={(event) => {
          dispatch(clearApproval())
          handleToggleDrawer('view-approval', false)(event)
        }}
      >
        <DrawerDetails.Approval
          approverId={drawer.approverId}
          openTask={handleTaskDrawer}
          onSubmit={handleSubmitApproval}
        />
      </SidePanelV2>

      <SidePanelV2
        titleText={sw?.name}
        supportingText={`${t('sow_code')}: ${sw?.code}`}
        open={drawer['view-sw-sign-doc']}
        onClose={(event) => {
          dispatch(clearSW())
          handleToggleDrawer('view-sw-sign-doc', false)(event)
        }}
      >
        <DrawerDetails.SoW
          id={drawer?.swId}
          docSignerId={drawer?.docSignerId}
          onSubmit={handleSubmitDocument}
          onUnassign={handleUnassignSW}
          onActions={{
            onReject() {
              // handleToggleDrawer('view-sw-sign-doc', false)()
              fetchDocSignersOwner()
            },
            onSign() {
              // handleToggleDrawer('view-sw-sign-doc', false)()
              fetchDocSignersOwner()
            },
            onClickTask(taskId) {
              handleTaskDrawer(taskId)
            },
          }}
        />
      </SidePanelV2>
    </TableContainer>
  )
}

export default MainSection

const TableContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const TableHeader = styled('div')({
  display: 'flex',
  width: '100%',
  border: '1px solid var(--Gray-200)',
  borderBottom: 'none',
  borderTopLeftRadius: '.5rem' /* 8px */,
  borderTopRightRadius: '.5rem' /* 8px */,
})
