import React from 'react'
import { styled } from '@mui/material/styles'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'

const BorderLinearProgress = styled(LinearProgress)((props) => ({
  height: props?.height || 10,
  borderRadius: 5,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'var(--Gray-200)',
    width: '100%',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'var(--Primary-600)',
  },
}))

export default function Progress({ message, ...props }) {
  return (
    <Wrapper>
      <div className="header">
        {message && <p className="message">{message}</p>}
        <p className="percent">{`${props?.value ? Number(props.value).toFixed(2) : '0'}%`}</p>
      </div>

      <BorderLinearProgress {...props} />
    </Wrapper>
  )
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.25rem;
  flex: 1 0 0;

  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    & > p {
      margin: 0;
      width: 100%;
    }

    & > p.message {
      color: var(--Gray-700);

      /* Text xs/Medium */
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1.125rem; /* 150% */
    }

    & > p.percent {
      color: var(--Gray-700);
      text-align: right;

      /* Text sm/Medium */
      font-size: 0.875rem;
      font-weight: 500;
      line-height: 1.25rem; /* 142.857% */
    }
  }
`
