import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import styled from 'styled-components'
import TextField from '../../../components/form/TextField'
import Button from '../../../components/form/button/Button'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import KPIInput from '../../../components/feature/kpi-management/KPIInput'
import NewAccordion from '../../../components/common/NewAccordion'
import Modal from '../../../components/common/Modal'
import DialogSuccess from '../../dialog/DialogSuccess'
import DialogFail from '../../dialog/DialogFail'

import { TbBuildingBank } from 'react-icons/tb'
import { LiaTimesSolid } from 'react-icons/lia'
import { FaPlus } from 'react-icons/fa6'
import flagIcon from '../../../assets/images/flag-icon.png'
import successIcon from '../../../assets/images/success-icon.png'
import failIcon from '../../../assets/images/fail-icon.png'

import { selectKPI, selectMaster } from '../../../redux/selectors'
import { getDepartmentsAndPositions } from '../../../redux/actions/master'
import { createKPI } from '../../../redux/actions/kpiManagement'

const Div = styled.div`
  padding: 24px;
  // box model style
  .mt-2-px {
    margin-top: 4px;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }
  .mr-12-px {
    margin-right: 12px;
  }
  .mr-16-px {
    margin-right: 16px;
  }

  // typo style
  h2,
  p {
    margin: 0;
  }
  .kpi-heading {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .kpi-des {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  // color
  .icon-color {
    color: var(--Primary-600);
  }
  .icon {
    cursor: pointer;
  }
  .add-kpi-btn {
    border: 1px solid var(--Primary-300);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }
  .save-draft-btn {
    border: 1px solid var(--Primary-300);
    background: var(--Base-White);
    color: var(--Primary-700);
  }

  /* footer */
  .footer-create-kpi {
    position: sticky;
    width: 100%;
    z-index: 99;
    left: 0;
    bottom: 0;

    border-top: 1px solid var(--Gray-200);
    background-color: var(--Base-White);
    padding-top: 16px;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-center {
    align-items: center;
  }
`
const ModalContainer = styled.div`
  padding: 24px;

  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }

  .w-100 {
    width: 100%;
  }
  .w-48 {
    width: 48%;
  }

  .icon {
    cursor: pointer;
  }
  .heading-modal {
    color: var(--Gray-900);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .input-heading {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 6px;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`

function CreateKPIAnnoucement({ open, onClose }) {
  // external hook
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // initiate data
  const fecthDepartment = useCallback(() => {
    dispatch(getDepartmentsAndPositions())
  }, [dispatch])

  useEffect(() => {
    fecthDepartment()
  }, [])

  // form
  const initialForm = {
    name: '',
    company: '',
    status: '',
  }
  const [kpiForm, setKpiForm] = useState(initialForm)

  const onChangeKpiForm = (event) => {
    const { name, value } = event.target
    setKpiForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // kpi organization
  const listKPI = [
    {
      key: uuidv4(),
      kpi_name: '',
      description: '',
      target_type: '',
      target_value: '',
      unit: '',
      start_date: '',
      end_date: '',
    },
  ]
  const [kpiOrganization, setKpiOrganization] = useState(listKPI)

  const onChangeKpiOrganization = (key, event) => {
    const { name, value } = event.target

    setKpiOrganization((prev) => {
      const newKpiOrganization = prev.map((it) => {
        if (it.key === key) {
          return {
            ...it,
            [name]: name === 'target_value' ? value.replace(/[^0-9.]/g, '') : value,
          }
        }

        return it
      })

      return newKpiOrganization
    })
  }
  const onChangeDropdownKpiOrganization = (key, name, value) => {
    setKpiOrganization((prev) => {
      const newKpiOrganization = prev.map((it) => {
        if (it.key === key)
          return {
            ...it,
            [name]: value,
          }
        return it
      })

      return newKpiOrganization
    })
  }
  const onChangeDateKpiOrganization = (key, name, value) => {
    setKpiOrganization((prev) => {
      const newKpiOrganization = prev.map((it) => {
        if (it.key === key)
          return {
            ...it,
            [name]: value,
          }
        return it
      })

      return newKpiOrganization
    })
  }
  const addKPI = () => {
    const newKPI = {
      key: uuidv4(),
      kpi_name: '',
      description: '',
      target_type: '',
      target_value: '',
      unit: '',
      start_date: '',
      end_date: '',
    }

    setKpiOrganization((prev) => [...prev, newKPI])
  }
  const deleteKPI = (key) => {
    setKpiOrganization((prev) => {
      const newKpi = prev.filter((v) => v.key !== key)
      return newKpi
    })
  }

  // kpi department
  const { departments } = useSelector(selectMaster)
  const [kpiDepartmentListForAccordion, setKpiDepartmentListForAccordion] = useState([])
  const [kpiDepartmentListForm, setKpiDepartmentListForm] = useState([])

  const onChangeKpiDepartment = (key, event) => {
    const { name, value } = event.target
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentListForm = prev.map((it) => {
        const isThereKey = it.kpiForm.some((form) => form.key === key)
        if (isThereKey) {
          const newKpiForm = it.kpiForm.map((v) => {
            if (v.key === key)
              return {
                ...v,
                [name]: name === 'target_value' ? value.replace(/[^0-9.]/g, '') : value,
              }
            return v
          })
          return {
            ...it,
            kpiForm: newKpiForm,
          }
        } else {
          return it
        }
      })
      return newKpiDepartmentListForm
    })
  }
  const onChangeDropdownKpiDepartment = (key, name, value) => {
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentListForm = prev.map((it) => {
        const isThereKey = it.kpiForm.some((form) => form.key === key)
        if (isThereKey) {
          const newKpiForm = it.kpiForm.map((v) => {
            if (v.key === key)
              return {
                ...v,
                [name]: value,
              }
            return v
          })
          return {
            ...it,
            kpiForm: newKpiForm,
          }
        } else {
          return it
        }
      })
      return newKpiDepartmentListForm
    })
  }
  const onChangeDateKpiDepartment = (key, name, value) => {
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentListForm = prev.map((it) => {
        const isThereKey = it.kpiForm.some((form) => form.key === key)
        if (isThereKey) {
          const newKpiForm = it.kpiForm.map((v) => {
            if (v.key === key)
              return {
                ...v,
                [name]: value,
              }
            return v
          })
          return {
            ...it,
            kpiForm: newKpiForm,
          }
        } else {
          return it
        }
      })
      return newKpiDepartmentListForm
    })
  }
  const addKPIDepartment = (deptKey) => {
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentListForm = prev.map((dept) => {
        if (dept.departmentKey === deptKey) {
          const newKPI = {
            key: uuidv4(),
            kpi_name: '',
            description: '',
            target_type: '',
            target_value: '',
            unit: '',
            start_date: '',
            end_date: '',
            department: dept.departmentId,
          }
          return {
            ...dept,
            kpiForm: [...dept.kpiForm, newKPI],
          }
        } else return dept
      })

      return newKpiDepartmentListForm
    })
  }
  const deleteKPIDepartment = (departmentKey, key) => {
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentForm = prev.map((dept) => {
        if (dept.departmentKey === departmentKey) {
          const newKpiForm = dept.kpiForm.filter((it) => it.key !== key)
          return {
            ...dept,
            kpiForm: newKpiForm,
          }
        }
        return dept
      })

      return newKpiDepartmentForm
    })
  }

  useEffect(() => {
    if (departments.length > 0) {
      const newKpiDepartmentListForm = departments.map((v) => {
        return {
          departmentId: v.id,
          departmentKey: v.name,
          kpiForm: [],
        }
      })
      setKpiDepartmentListForm(newKpiDepartmentListForm)
    }
  }, [departments])
  useEffect(() => {
    const newKpiDepartmentList = kpiDepartmentListForm.map((v) => {
      return {
        key: v.departmentKey,
        title: v.departmentKey,
        content: (
          <div>
            <KPIInput
              className="mb-16-px"
              list={v.kpiForm}
              onChange={onChangeKpiDepartment}
              onChangeDropdown={onChangeDropdownKpiDepartment}
              onChangeDate={onChangeDateKpiDepartment}
              onDelete={(key) => deleteKPIDepartment(v.departmentKey, key)}
            />
            <ButtonOutline
              className="add-kpi-btn mb-24-px"
              append={<FaPlus />}
              onClick={() => addKPIDepartment(v.departmentKey)}
            >
              Add KPI
            </ButtonOutline>
          </div>
        ),
      }
    })
    setKpiDepartmentListForAccordion(newKpiDepartmentList)
  }, [kpiDepartmentListForm])

  // modal confirm publish
  const [createType, setCreateType] = useState('draft')
  const [openConfirmPublishKPI, setOpenConfirmPublishKPI] = useState(false)

  const openConfirmCreateKPIModal = () => {
    setOpenConfirmPublishKPI(true)
  }
  const closeConfirmCreateKPIModal = () => {
    setOpenConfirmPublishKPI(false)
  }
  const publishKPI = () => {
    const { company_info } = JSON.parse(localStorage.getItem('admin'))
    const request = {
      name: kpiForm?.name,
      company: company_info?.id,
      status: 'publish',
      kpi_organization: kpiOrganization.map((v) => ({
        ...v,
        target_type: v.target_type === '' ? null : v.target_type,
        target_value: v.target_value === '' ? null : v.target_value,
        start_date: v.start_date === '' ? null : v.start_date,
        end_date: v.end_date === '' ? null : v.end_date,
      })),
      kpi_department: kpiDepartmentListForm
        .flatMap((v) => v.kpiForm)
        .map((v) => ({
          ...v,
          target_type: v.target_type === '' ? null : v.target_type,
          target_value: v.target_value === '' ? null : v.target_value,
          start_date: v.start_date === '' ? null : v.start_date,
          end_date: v.end_date === '' ? null : v.end_date,
        })),
    }
    setCreateType('publish')
    dispatch(createKPI(request))
    closeConfirmCreateKPIModal()
  }

  // modal confirm save draft
  const [openConfirmSaveKPI, setOpenConfirmSaveKPI] = useState(false)

  const openConfirmSaveKPIModal = () => {
    setOpenConfirmSaveKPI(true)
  }
  const closeConfirmSaveKPIModal = () => {
    setOpenConfirmSaveKPI(false)
  }
  const draftKPI = () => {
    const { company_info } = JSON.parse(localStorage.getItem('admin'))
    const request = {
      name: kpiForm?.name,
      company: company_info?.id,
      status: 'draft',
      kpi_organization: kpiOrganization.map((v) => ({
        ...v,
        target_type: v.target_type === '' ? null : v.target_type,
        target_value: v.target_value === '' ? null : v.target_value,
        start_date: v.start_date === '' ? null : v.start_date,
        end_date: v.end_date === '' ? null : v.end_date,
      })),
      kpi_department: kpiDepartmentListForm
        .flatMap((v) => v.kpiForm)
        .map((v) => ({
          ...v,
          target_type: v.target_type === '' ? null : v.target_type,
          target_value: v.target_value === '' ? null : v.target_value,
          start_date: v.start_date === '' ? null : v.start_date,
          end_date: v.end_date === '' ? null : v.end_date,
        })),
    }
    setCreateType('draft')
    dispatch(createKPI(request))
    closeConfirmSaveKPIModal()
  }

  // state,isLoading,successModal,
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const { state, isLoading } = useSelector(selectKPI)
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  useEffect(() => {
    if (open) {
      if (state === 'CREATE_KPI.SUCCESS' && isLoading === false) {
        setSuccessMessageModal({
          headline: createType === 'draft' ? 'Save draft successful' : 'KPI has been published',
          message: '',
        })
        setSuccessModal(true)
      }
      if (state === 'CREATE_KPI.FAILURE' && isLoading === false) {
        setFailMessageModal({
          headline: createType === 'draft' ? 'Save draft Fail' : 'KPI has been publised Fail',
          message:
            createType === 'draft'
              ? "You can't save draft more than one kpi for this company"
              : "You can't create more than one kpi for this company",
        })
        setFailModal(true)
      }
    }
  }, [open, state, isLoading])

  return (
    <Div>
      {/* Organization KPI section */}
      <section>
        <FlexContainer className="justify-content-space-between mb-24-px">
          <FlexContainer>
            <TbBuildingBank size={24} className="icon-color mt-2-px mr-16-px" />
            <div>
              <h2 className="kpi-heading mb-8-px">Organization KPI</h2>
              <p className="kpi-des">Organization goals</p>
            </div>
          </FlexContainer>
          <LiaTimesSolid className="icon" size={20} onClick={onClose} />
        </FlexContainer>
        <KPIInput
          className="mb-16-px"
          list={kpiOrganization}
          onChange={onChangeKpiOrganization}
          onChangeDropdown={onChangeDropdownKpiOrganization}
          onChangeDate={onChangeDateKpiOrganization}
          onDelete={deleteKPI}
        />
        <ButtonOutline className="add-kpi-btn mb-24-px" append={<FaPlus />} onClick={addKPI}>
          Add KPI
        </ButtonOutline>
      </section>

      <Divider className="mb-24-px" />

      {/* Department KPI section */}
      <section>
        <FlexContainer className="mb-24-px">
          <TbBuildingBank size={24} className="icon-color mt-2-px mr-16-px" />
          <div>
            <h2 className="kpi-heading mb-8-px">Department KPI</h2>
            <p className="kpi-des">Define department KPIs</p>
          </div>
        </FlexContainer>

        <NewAccordion items={kpiDepartmentListForAccordion} />
      </section>

      {/* footer */}
      <section className="footer-create-kpi">
        <FlexContainer className="justify-content-end">
          <ButtonOutline className="mr-12-px" onClick={onClose}>
            Cancel
          </ButtonOutline>
          <ButtonOutline onClick={openConfirmSaveKPIModal} className="save-draft-btn mr-12-px">
            Save as Draft
          </ButtonOutline>
          <Button onClick={openConfirmCreateKPIModal}>Publish</Button>
        </FlexContainer>
      </section>

      {/* Modal */}
      <Modal open={openConfirmPublishKPI} onClose={closeConfirmCreateKPIModal} disableBackdropClick width={'640px'}>
        <ModalContainer>
          <FlexContainer className="justify-content-end">
            <LiaTimesSolid className="icon" size={20} onClick={closeConfirmCreateKPIModal} />
          </FlexContainer>
          <FlexContainer className="justify-content-center mb-16-px">
            <img src={flagIcon} alt="flag icon" />
          </FlexContainer>
          <FlexContainer className="justify-content-center mb-20-px">
            <div className="heading-modal">Publish</div>
          </FlexContainer>
          <div className="mb-32-px">
            <div className="input-heading">Title Name for this KPI*</div>
            <FlexContainer>
              <TextField name="name" className="w-100" value={kpiForm.name} onChange={onChangeKpiForm} />
            </FlexContainer>
          </div>

          <FlexContainer className="justify-content-space-between">
            <ButtonOutline className="w-48" onClick={closeConfirmCreateKPIModal}>
              Cancel
            </ButtonOutline>
            <Button className="w-48" onClick={publishKPI}>
              Publish
            </Button>
          </FlexContainer>
        </ModalContainer>
      </Modal>
      <Modal open={openConfirmSaveKPI} onClose={closeConfirmSaveKPIModal} disableBackdropClick width={'640px'}>
        <ModalContainer>
          <FlexContainer className="justify-content-end">
            <LiaTimesSolid className="icon" size={20} onClick={closeConfirmSaveKPIModal} />
          </FlexContainer>
          <FlexContainer className="justify-content-center mb-16-px">
            <img src={flagIcon} alt="flag icon" />
          </FlexContainer>
          <FlexContainer className="justify-content-center mb-20-px">
            <div className="heading-modal">Save as Draft</div>
          </FlexContainer>

          <div className="mb-32-px">
            <div className="input-heading">Title Name for this KPI*</div>
            <FlexContainer>
              <TextField name="name" className="w-100" value={kpiForm.name} onChange={onChangeKpiForm} />
            </FlexContainer>
          </div>

          <FlexContainer className="justify-content-space-between">
            <ButtonOutline className="w-48" onClick={closeConfirmSaveKPIModal}>
              Cancel
            </ButtonOutline>
            <Button className="w-48" onClick={draftKPI}>
              Save as Draft
            </Button>
          </FlexContainer>
        </ModalContainer>
      </Modal>

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => {
          setSuccessModal(false)
          onClose()
        }}
        onSubmit={() => {
          setSuccessModal(false)
          onClose()
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default CreateKPIAnnoucement
