import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import DeltaToHtml from '../../../../components/widget/combo/DeltaToHtml'
import MediaFile from '../../../../containers/cms/MediaFile'
import { updateTaskTypeStatusInMilestone } from '../../../../redux/actions/projectTask'
import { updateTaskAction } from '../../../../redux/actions/summaryTask'
import { dlog, generateIconFileByType } from '../../../../utils/helper'

import failIcon from '../../../../assets/images/fail-icon.png'
import Modal from '../../../common/Modal'
import DialogFail from '../../../dialog/DialogFail'
import Dropdown from '../../../form/Dropdown'
import Button from '../../../form/button/Button'
import ButtonOutline from '../../../form/button/ButtonOutline'
import CapsuleStatus from '../../../widget/element/CapsuleStatus'
import TextAreaLimit from '../../../widget/element/TextAreaLimit'
import IconBin from '../../../widget/icon/IconBin'
import IconExpand from '../../../widget/icon/IconExpand'

import IconXClose from '../../../widget/icon/IconXClose'
import IconFilePdf from '../../../widget/icon/iconUpload/IconFilePdf'

const Div = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .mb-1-rem {
    margin-bottom: 16px;
  }
  .w-100 {
    width: 100%;
  }

  .wrp-overview {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }

  .top {
    padding: 0 21px 32px;
    flex: 1;
  }
  .wrp-input-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .wrp-input-left,
  .wrp-input-right {
    width: 100%;
  }
  .wrp-input-right {
    margin-left: 12px;
  }
  .wrp-filed {
    margin-bottom: 24px;
  }
  .title-filed {
    color: var(--Gray-700);
    margin-bottom: 6px;
  }
  .block-show-detail {
    border: 1px solid var(--Gray-300);
    border-radius: 8px;
    padding: 10px 14px;
    background-color: var(--Gray-50);
    display: flex;
  }

  .text-detail {
    width: 100%;
    height: 24px;
    display: flex;
  }
  .text-detail-container {
    width: 100%;
    display: flex;
    max-height: 300px;
    overflow: hidden;
  }
  .text {
    /* background-color: red; */
    flex: 1;
  }
  .text p {
    /* background-color: green; */
    /* display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis; */
  }
  .txt-detail-message {
    flex: 1;
    margin: 5px 0;
  }
  .icon-expand {
    height: fit-content;
    cursor: pointer;
    margin-left: 10px;
  }

  .wrp-explain {
    .title-detail {
      margin-bottom: 6px;
    }
    .txt-detail {
      flex: 1;
    }
    .txt-detail,
    .wrp-task-requirements {
      margin-left: 1rem;
    }
  }
  .wrp-btn-file {
    /* margin-left: 1rem; */
    display: grid;
    grid-template-columns: 49% 49%;
    grid-gap: 10px;
  }

  .bottom-content {
    border: 1px solid var(--Gray-300);
    padding: 24px 21px;
  }
  .wrp-title-action {
    display: flex;
    justify-content: space-between;
  }
  .title-bottom-content {
    font-weight: bold;
    font-size: 22px;
  }
  .action {
    display: flex;
    align-items: center;
  }
  .text-action {
    margin-right: 12px;
  }
  .task-summary-message {
    margin: 24px 0;

    .wrp-comment {
      .title-comment {
        margin-bottom: 6px;
      }
      .text-summary-message {
        padding: 5px;
        border: 1px solid var(--Gray-300);
        border-radius: 8px;
      }
    }
  }

  .wrp-text-area {
  }
  .title-input-text-area {
    margin-bottom: 6px;
  }
  .input-text-area {
    border: 1px solid var(--Gray-300);
    font-size: 14px;
  }
  .count-text-area {
    margin-top: 6px;
    margin-bottom: 24px;
  }
  .wrp-button-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 15px; */
    /* border-top: 1px solid var(--Gray-300); */
    padding: 16px 24px;
  }
  .wrp-btn-options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .wrp-btn-attach-file {
    /* min-width: 120px !important; */
    width: fit-content;
  }
  .wrp-file {
    padding: 10px 8px 10px 16px;
    border: 1px solid #ddd;
    border-radius: 6px;
    position: relative;
    min-width: 180px;
    cursor: pointer;
    width: fit-content;
    display: flex;
  }
  .wrp-file-item {
    display: flex;
    align-items: center;
  }
  .wrp-file-item span {
    margin-left: 10px;
    margin-right: 10px;
  }
  .btn-close {
    /* position: absolute;
    top: 0;
    right: 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
  }
  .dropdown {
    min-width: 127px;
  }
  .btn-cancel {
    margin-right: 12px;
  }

  .style-media-file {
    .upload-zone {
      color: var(--Primary-700);
      background-color: var(--Primary-50);
      border: 1px solid var(--Primary-200);
    }
  }
  .style-dis-media-file {
    .upload-zone {
      color: var(--Gray-300);
      background-color: var(--Base-White);
      border-color: var(--Gray-200);
    }
  }

  //modal-expand
  .wrp-modal-expand {
    width: 640px;
    height: 687px;
    padding: 24px;
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .detail {
      flex: 1;
      border: 1px solid var(--Gray-300);
      border-radius: 8px;
      padding: 14px;
      overflow: auto;
    }
  }

  .ul-file-list {
    max-width: 50%;
    list-style-type: none;
    padding: 0;

    li {
      cursor: pointer;

      .wrp-file-item {
        padding: 10px;
        border: 1px solid var(--Gray-300);
        border-radius: 8px;
        margin-bottom: 10px;

        .detail-btn {
          display: flex;
          flex: 1;
          margin-left: 10px;

          .detail-file {
          }

          .name-file {
            max-width: 300px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          span {
            margin: 0;
          }
        }
      }
    }
  }
`

export default function TaskContentOverview({
  data,
  isPreview = false,
  onTaskAction,
  previewType = 'task_routine',
  onCancel,
}) {
  const dispatch = useDispatch()
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))
  const allowTypes = ['images', 'files']
  const [files, setFiles] = useState([])
  const [statusSelected, setStatusSelected] = useState('')
  const [summaryText, setSummaryText] = useState('')
  const [statusList, setStatusList] = useState([])
  const [isRemoveFileRefer, setIsRemoveFileRefer] = useState(false)
  const [expandDetail, setExpandDetail] = useState(false)
  const [isShowFailedModal, setIsShowFailedModall] = useState(false)

  const [fileList, setFileList] = useState([
    {
      name: 'Screenshot 2566-11-01 at 10.00.42.png',
      size: '39.34',
      ext: '.png',
    },
    {
      name: 'Screenshot 2566-11-01 at 10.00.42.png',
      size: '39.34',
      ext: '.png',
    },
  ])

  useEffect(() => {
    // if (previewType == 'task_routine') {
    if (data) {
      if (data?.status == 'Complete') {
        setStatusList([
          {
            text: <CapsuleStatus status={'Reopen'} />,
            value: 'Reopen',
          },
        ])
      } else if (data?.status == 'Reopen') {
        setStatusList([
          {
            text: <CapsuleStatus status={'Working'} />,
            value: 'Working',
          },
          {
            text: <CapsuleStatus status={'Hold'} />,
            value: 'Hold',
          },
          {
            text: <CapsuleStatus status={'Complete'} />,
            value: 'Complete',
          },
        ])
      } else if (data?.status == 'Working') {
        setStatusList([
          {
            text: <CapsuleStatus status={'Hold'} />,
            value: 'Hold',
          },
          {
            text: <CapsuleStatus status={'Complete'} />,
            value: 'Complete',
          },
        ])
      } else {
        setStatusList([
          {
            text: <CapsuleStatus status={'Working'} />,
            value: 'Working',
          },
          {
            text: <CapsuleStatus status={'Hold'} />,
            value: 'Hold',
          },
          {
            text: <CapsuleStatus status={'Complete'} />,
            value: 'Complete',
          },
        ])
      }
    }
    // } else {
    //   // previewType == 'task_milestone'
    //   dlog(previewType, "previewType")
    // }
  }, [data])

  const handleUpdateTaskAction = async (taskData) => {
    if (Object.keys(employee_info).length > 0) {
      if (previewType == 'task_routine') {
        let tmpTaskId = taskData?.id
        if (statusSelected != '') {
          let tmpObj
          if (statusSelected == 'Complete' || statusSelected == 'Reopen') {
            let fileId = ''
            if (statusSelected == 'Complete') {
              fileId = files[0]?.id || null
            } else {
              if (!isRemoveFileRefer) {
                fileId = taskData?.file_complete_refer?.id || null
              }
            }
            tmpObj = {
              task: tmpTaskId,
              action: statusSelected,
              summary: summaryText,
              created_by: employee_info.id,
              file_id: fileId,
            }
          } else {
            tmpObj = {
              task: tmpTaskId,
              action: statusSelected,
              summary: summaryText,
              created_by: employee_info.id,
            }
          }
          const data = tmpObj
          const request = { data }
          await dispatch(updateTaskAction(request)).then(() => {
            if (onTaskAction) {
              onTaskAction()
            }
          })
        }
      } else {
        // updateTaskMilestone
        // dispatch()

        let tmpObj = {
          milestone_task_type: taskData.id,
          milestone_scope_works: taskData.milestone_scope_work.id,
          milestones: taskData.milestone_scope_work.milestone.id,
          project_task: taskData.milestone_scope_work.milestone.project_task.id,
          action: statusSelected,
          summary: summaryText,
          created_by: employee_info.id,
        }
        let fileId = ''
        if (statusSelected == 'Complete') {
          fileId = files[0]?.id || null
        }
        let tmpTaskActionList = [...taskData?.milestone_task_actions]
        if (statusSelected == 'Reopen') {
          tmpObj.file_id = tmpTaskActionList[tmpTaskActionList.length - 1].file_id
        }
        const data = tmpObj
        const request = { data }
        await dispatch(updateTaskTypeStatusInMilestone(request)).then(() => {
          if (onTaskAction) {
            onTaskAction()
          }
        })
      }
    } else {
      dlog('No_EmployeeId')
      setIsShowFailedModall(true)
    }
  }

  const onChangeUploadFile = (value) => {
    setFiles(value)
  }

  const handleRemoveFile = () => {
    setFiles([])
  }
  const getSummaryTaskMessage = () => {
    if (data) {
      if (previewType == 'task_routine') {
        let tmpActionList = data.task_actions.data
        for (let i = 0; i < tmpActionList.length; i++) {
          if (tmpActionList[i]?.attributes?.action == data.status && tmpActionList[i]?.attributes?.status == 'active') {
            if (tmpActionList[i]?.attributes?.summary) {
              return (
                <div className="wrp-comment">
                  <div className="title-comment">Comment</div>
                  <div className="text-summary-message">{tmpActionList[i]?.attributes?.summary}</div>
                </div>
              )
            }
          }
        }
      } else if (previewType == 'task_milestone') {
        let tmpActionList = [...data.milestone_task_actions]
        if (tmpActionList.length > 0) {
          let lastIndex = tmpActionList.length - 1
          let tmpStatus = ''
          if (data.status == 'Doing') {
            tmpStatus = 'Working'
          } else {
            tmpStatus = data.status
          }
          if (tmpActionList[lastIndex]?.action == tmpStatus) {
            if (tmpActionList[lastIndex]?.summary) {
              return (
                <div className="wrp-comment">
                  <div className="title-comment">Comment</div>
                  <div className="text-summary-message">{tmpActionList[lastIndex]?.summary}</div>
                </div>
              )
            }
          }
        }
      }
      return ''
    }
  }

  const handleClickedDownloadFile = () => {
    if (data?.status == 'Complete') {
      if (previewType == 'task_routine') {
        if (data?.file_complete_refer?.url != '') {
          window.open(data?.file_complete_refer?.url)
        }
      } else {
        // task_milestone
        if (data?.milestone_task_actions?.length > 0) {
          if (
            data?.milestone_task_actions[data?.milestone_task_actions.length - 1].file_id != '' &&
            data?.milestone_task_actions[data?.milestone_task_actions.length - 1].file_id != null
          ) {
            // window.open(data?.milestone_task_actions[data?.milestone_task_actions.length-1].file_id)
            alert("File ID don't have URL")
          }
        }
      }
    }
  }
  const handleRemoveFileRefer = () => {
    if (data?.status == 'Reopen') {
      setIsRemoveFileRefer(true)
    }
  }
  const handleClickBtnCancel = () => {
    if (onCancel) {
      onCancel()
    }
  }

  const convertToDelta = (desc) => {
    if (desc != '') {
      let tmpConvertDeltaContent = desc?.substring(0, 1)
      if (tmpConvertDeltaContent == '[') {
        return <DeltaToHtml contents={desc} />
      }
    }
    return desc
  }

  const handleClickedFileItem = (url) => {
    window.open(url)
  }

  return (
    <Div>
      <div className="wrp-overview">
        <div className="top">
          <div className="wrp-inputs-container">
            <div className="wrp-input-row">
              <div className="wrp-input-left">
                <div className="wrp-filed">
                  <div className="title-filed">Assign to</div>

                  <div className="block-show-detail">
                    {previewType == 'task_routine'
                      ? isPreview
                        ? data?.assign_to_name
                        : data?.assign_to?.data
                        ? data?.assign_to?.data?.attributes?.first_name +
                          ' ' +
                          data?.assign_to?.data?.attributes?.last_name
                        : '-'
                      : data?.assign_to?.first_name + ' ' + data?.assign_to?.last_name}
                  </div>
                </div>
              </div>

              <div className="wrp-input-right">
                <div className="wrp-filed">
                  <div className="title-filed">Assign by</div>

                  <div className="block-show-detail">
                    <div className="text-detail">
                      {previewType == 'task_routine'
                        ? isPreview
                          ? data?.creator
                          : data?.creator?.data
                          ? data?.creator?.data?.attributes?.first_name +
                            ' ' +
                            data?.creator?.data?.attributes?.last_name
                          : '-'
                        : data?.creator?.first_name + ' ' + data?.creator?.last_name}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="wrp-input-row">
              <div className="wrp-input-left">
                <div className="wrp-filed">
                  <div className="title-filed">Project</div>
                  <div className="block-show-detail">
                    <div className="text-detail">
                      {previewType == 'task_routine'
                        ? '-'
                        : data?.milestone_scope_work?.milestone?.project_task?.name || '-'}
                    </div>
                  </div>
                </div>
              </div>

              <div className="wrp-input-right">
                <div className="wrp-filed">
                  {/* <div className="title-filed">Milestone :</div>
                  <div className="block-show-detail">
                    <div className="text-detail">
                      {previewType == 'task_routine'
                        ? isPreview
                          ? data?.milestone_name
                          : data?.milestone != ''
                          ? data?.milestone
                          : '-'
                        : data?.milestone_scope_work?.milestone?.name || '-'}
                    </div>
                  </div> */}

                  <div className="title-filed">Scope of work</div>
                  <div className="block-show-detail">
                    <div className="text-detail">
                      {previewType == 'task_routine'
                        ? isPreview
                          ? data?.scope_name
                          : data?.task_scope?.data.attributes?.name != ''
                          ? data?.task_scope?.data.attributes?.name
                          : '-'
                        : data?.milestone_scope_work?.task_scope?.name || '-'}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="wrp-input-row">
              <div className="wrp-input-left">
                <div className="wrp-filed">
                  <div className="title-filed">Start date</div>
                  <div className="block-show-detail">
                    {/* <div className="text-detail">{data?.due_date != '' ? data?.due_date : '-'}</div> */}
                    <div className="text-detail">{data?.start_date != '' ? data?.start_date : '-'}</div>
                  </div>
                </div>
              </div>

              <div className="wrp-input-right">
                <div className="wrp-filed">
                  {/* <div className="title-filed">Scope of work :</div>
                  <div className="block-show-detail">
                    <div className="text-detail">
                      {previewType == 'task_routine'
                        ? isPreview
                          ? data?.scope_name
                          : data?.task_scope?.data.attributes?.name != ''
                          ? data?.task_scope?.data.attributes?.name
                          : '-'
                        : data?.milestone_scope_work?.task_scope?.name || '-'}
                    </div>
                  </div> */}

                  <div className="title-filed">Due Date</div>
                  <div className="block-show-detail">
                    <div className="text-detail">{data?.due_date != '' ? data?.due_date : '-'}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="wrp-explain mb-1-rem">
            <div className="title-detail">Details</div>
            <div className="block-show-detail">
              <div className="text-detail-container">
                {previewType == 'task_routine' && (
                  <div className="text">{data?.description && <DeltaToHtml contents={data.description} />}</div>
                )}
                {previewType == 'task_milestone' && (
                  <div className="text">
                    {/* <DeltaToHtml contents={data?.detail} /> */}
                    {convertToDelta(data?.detail)}
                  </div>
                )}
                {/* {previewType == 'task_milestone' && <p className="txt-detail-message">{data?.detail}</p>} */}

                <div className="icon-expand" onClick={() => setExpandDetail(true)}>
                  <IconExpand width="24" />
                </div>
              </div>
            </div>
          </div>

          <div className="wrp-item-file-preview">
            {data?.procurement_templates.length > 0 && (
              <ul className={'ul-file-list'}>
                {data?.procurement_templates.map((item, index) => (
                  <li key={'file-item-' + index} onClick={() => handleClickedFileItem(item?.file_detail?.url)}>
                    <div className="wrp-file-item">
                      <div className="icon-file">{generateIconFileByType(item?.file_detail?.ext)}</div>
                      <div className="detail-btn">
                        <div className="detail-file">
                          <div className="name-file">{item?.file_detail?.name}</div>
                          <div className="size-file">
                            <span>{item?.file_detail?.size}</span> KB
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="bottom">
          {!isPreview && (
            <>
              <div className="bottom-content">
                <div className="wrp-title-action">
                  <div className="title-bottom-content">Task Summary</div>
                  <div className="action">
                    <div className="text-action">Action</div>
                    <Dropdown
                      className="dropdown"
                      optionList={statusList}
                      value={statusSelected}
                      onChange={(e) => setStatusSelected(e)}
                    />
                  </div>
                </div>

                {/* ปรับ message */}
                {data && <div className="task-summary-message">{getSummaryTaskMessage()}</div>}

                {/* {data?.status != 'Complete' && ( */}
                <div className="wrp-text-area">
                  <div className="title-input-text-area">Your comment</div>

                  <TextAreaLimit onValueChange={(e) => setSummaryText(e)} />

                  <div className="attach-file">
                    {files?.length == 0 && data?.status != 'Complete' && data?.status != 'Reopen' && (
                      <div className="wrp-btn-attach-file">
                        <MediaFile
                          className={statusSelected != 'Complete' ? 'style-dis-media-file' : 'style-media-file'}
                          multiple={false}
                          allowedTypes={allowTypes}
                          onChange={onChangeUploadFile}
                          inputForm="Attach File"
                          disabled={statusSelected != 'Complete' ? true : false}
                        />
                      </div>
                    )}

                    <div className="wrp-file-container">
                      {files?.length > 0 && (
                        <div className="wrp-file">
                          {files?.map((item, index) => (
                            <div key={'file-' + index} className="wrp-file-item">
                              <IconFilePdf width="20px" color={'#667085'} />
                              <span>{item?.name}</span>
                            </div>
                          ))}
                          <div className="btn-close" onClick={() => handleRemoveFile()}>
                            {/* <img src={iconClose} /> */}
                            <IconBin />
                          </div>
                        </div>
                      )}

                      {previewType == 'task_routine' &&
                        // (data?.status == 'Complete' || data?.status == 'Reopen') &&
                        !isRemoveFileRefer && (
                          <>
                            {data?.file_complete_refer && (
                              <div
                                className="wrp-file"
                                onClick={data?.status == 'Complete' ? handleClickedDownloadFile : null}
                              >
                                <div className="wrp-file-item">
                                  <IconFilePdf width="20px" color={'#667085'} />
                                  <span>{data?.file_complete_refer?.name}</span>
                                </div>
                                {data?.status == 'Reopen' && (
                                  <div className="btn-close" onClick={() => handleRemoveFileRefer()}>
                                    {/* <img src={iconClose} /> */}
                                    <IconBin />
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        )}

                      {previewType == 'task_milestone' &&
                        (data?.status == 'Complete' || data?.status == 'Reopen') &&
                        !isRemoveFileRefer && (
                          <>
                            {data?.milestone_task_actions[data?.milestone_task_actions.length - 1].file_id && (
                              <div
                                className="wrp-file"
                                onClick={data?.status == 'Complete' ? handleClickedDownloadFile : null}
                              >
                                <div className="wrp-file-item">
                                  <IconFilePdf width="20px" color={'#667085'} />
                                  <span>
                                    {data?.milestone_task_actions[data?.milestone_task_actions.length - 1].file_id}
                                  </span>
                                </div>
                                {data?.status == 'Reopen' && (
                                  <div className="btn-close" onClick={() => handleRemoveFileRefer()}>
                                    {/* <img src={iconClose} /> */}
                                    <IconBin />
                                  </div>
                                )}
                              </div>
                            )}
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="wrp-button-bottom">
                <div className="wrp-btn-options">
                  {/* <Dropdown
                className="dropdown"
                optionList={statusList}
                value={statusSelected}
                onChange={(e) => setStatusSelected(e)}
              /> */}

                  <ButtonOutline className="btn-cancel" onClick={() => handleClickBtnCancel()}>
                    Cancel
                  </ButtonOutline>
                  <Button onClick={() => handleUpdateTaskAction(data)} disabled={statusSelected == '' ? true : false}>
                    Update
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>

        {expandDetail && (
          <Modal className="test" open={expandDetail} disableBackdropClick>
            <Div>
              <div className="wrp-modal-expand">
                <div className="title">
                  <div className="title-text">Detail</div>
                  <div className="icon-close" onClick={() => setExpandDetail(false)}>
                    <IconXClose />
                  </div>
                </div>
                <div className="detail">
                  {previewType == 'task_routine' && data?.description && <DeltaToHtml contents={data.description} />}
                  {previewType == 'task_milestone' && <div>{convertToDelta(data?.detail)}</div>}
                </div>
              </div>
            </Div>
          </Modal>
        )}

        {isShowFailedModal && (
          <DialogFail
            open={Boolean(isShowFailedModal)}
            onClose={() => setIsShowFailedModall(false)}
            onSubmit={() => setIsShowFailedModall(false)}
            icon={failIcon}
            title={'You may not fully use this feature, since you are not employee.'}
            nameItem={''}
            description={''}
            textYes="Ok"
          />
        )}
      </div>
    </Div>
  )
}
