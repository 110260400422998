import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import IconSearch from '../icon/IconSearch'

export default function SearchSuggestBox({
  onChange,
  placeHolder = 'search here',
  initValue,
  design = 'outline',
  name,
  className,
  searchWidth = '250px',
  dropdownWidth = '250px',
  dropdownHeight = '400px',
  dropdownTopGap = 5,
  bgColor = '#fff',
  borderRadius = '8px',
  borderColor = '#d0d5dd',
  borderWidth = '1px',
  dropdownViews = [],
  debounceMs = 500,
  onSelected,
  itemHeaderColor = '#efefef',
  minHeight = '70px',
  isDisabled = false,
  isHideIcon = false,
}) {
  const inputReference = useRef(null)
  const [isSearchFocus, setIsSearchFocus] = useState(false)
  const outerRef = useRef()

  const [height, setHeight] = useState(0)
  const [selectValue, setSelectValue] = useState()

  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    function handleClickOutside(event) {
      if (outerRef.current && !outerRef.current.contains(event.target)) {
        setIsSearchFocus(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [outerRef])

  useEffect(() => {
    setHeight(outerRef.current.clientHeight)
  }, [])

  useEffect(() => {
    if (initValue) {
      setInputValue(initValue)
      setIsSearchFocus(false)
    }
  }, [initValue])

  // useEffect(() => {}, [inputValue])

  const onWrpClickHandler = () => {
    if (!isSearchFocus) {
      setIsSearchFocus(true)
      inputReference.current.focus()
    }
  }

  const blurHandler = (e) => {
    // setIsSearchFocus(false);
    // if (onBlur) {
    //   onBlur(e);
    // }
  }

  const debounce = (func, delay = 500) => {
    let timer
    return function (...args) {
      const context = this
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args)
      }, delay)
    }
  }

  const handleSelected = (index) => {
    setSelectValue(dropdownViews[index])
    if (onSelected) {
      onSelected(index)
    }
  }
  const onChangeInput = (v) => {
    setIsSearchFocus(true)
    setInputValue(v)
    debounceText(v)
  }
  const debounceText = useCallback(
    debounce((value) => {
      if (onChange) {
        onChange(value)
      }
    }, debounceMs),
    []
  )

  return (
    <Styled
      className={(design == 'outline' ? 'outline' : 'solid') + (className ? ' ' + className : '')}
      ref={outerRef}
      onClick={onWrpClickHandler}
      searchWidth={searchWidth}
      bgColor={bgColor}
      borderRadius={borderRadius}
      borderColor={borderColor}
      dropdownWidth={dropdownWidth}
      isSearchFocus={isSearchFocus}
      dropdownHeight={dropdownHeight}
      borderWidth={borderWidth}
      listTop={height + dropdownTopGap + 'px'}
      itemHeaderColor={itemHeaderColor}
      minHeight={minHeight}
      isDisabled={isDisabled}
    >
      <div className="wrp-search-input">
        {!isHideIcon && (
          <span className={'icon'}>
            <IconSearch width={'20'} />
          </span>
        )}
        <input
          name={name}
          ref={inputReference}
          onBlur={blurHandler}
          value={inputValue}
          placeholder={placeHolder}
          type="text"
          onChange={(e) => onChangeInput(e.target.value)}
          disabled={isDisabled}
        />
      </div>

      {isSearchFocus && !isDisabled && (
        <ul className="list-dropdown">
          {dropdownViews.length == 0 && (
            <li key={'no-item'} id={'no-item'} className="no-employ">
              ไม่พบข้อมูล
            </li>
          )}

          {dropdownViews.length > 0 &&
            dropdownViews?.map((item, index) => (
              <li
                key={'item-' + index}
                onClick={() => handleSelected(index)}
                id={'suggest-search-item-' + index}
                className={selectValue == item ? 'active' : ''}
              >
                {item}
              </li>
            ))}
        </ul>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  position: relative;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .wrp-search-input {
    position: relative;
    display: flex;
    overflow: hidden;
    border-radius: ${({ borderRadius }) => borderRadius};
    border: ${({ borderWidth }) => borderWidth} solid ${({ borderColor }) => borderColor};
    background-color: ${({ bgColor, isDisabled }) => (!isDisabled ? bgColor : '#eaecf0')};
    width: ${({ searchWidth }) => searchWidth};

    input,
    input:focus-visible,
    input:focus {
      border: none;
      outline: none;
    }

    input {
      display: flex;
      flex: 1;
      padding: 10px;
      font-size: 16px;
      background: none;
      user-select: none;
    }
    .icon {
      position: relative;
      margin-right: 5px;
      top: 5px;
      color: #555;
      margin-left: 10px;
    }
  }
  .list-dropdown {
    position: absolute;
    top: ${({ listTop }) => listTop};
    max-height: 300px;
    /* height: 100%; */
    overflow-y: auto;
    width: ${({ dropdownWidth }) => dropdownWidth};
    border-radius: ${({ borderRadius }) => borderRadius};
    border: ${({ borderWidth }) => borderWidth} solid ${({ borderColor }) => borderColor};
    background-color: ${({ bgColor }) => bgColor};
    z-index: 999;
    min-height: ${({ minHeight }) => minHeight};
    background-color: ${({ bgColor }) => bgColor};

    /* embed style */
    li {
      cursor: pointer;
      user-select: none;
    }

    .header {
      padding: 10px;
      background-color: ${({ itemHeaderColor }) => itemHeaderColor};
      font-weight: bold;
    }
    .item-normal {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      flex-shrink: none;
    }
    .item-img {
      background-color: #ddd;
      width: 50px;
      height: 50px;
      border-radius: 100px;
      margin-right: 10px;
      flex-shrink: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
      }
    }

    .no-employ {
      position: relative;
      top: 23px;
      text-align: center;
    }
  }
`

// export default function ExampleSearchSuggestBox() {

//   const [dropDownViews, setDropDownView] = useState([]);
//   const [searchText, setSearchText] = useState();

//   useEffect(() => {
//     if (searchText) {
//       const tmpData = [
//         { type: "header", value: "Team" },
//         {
//           type: "item-normal",
//           value: {
//             title: "Production Team 1",
//             desc: "Production Department",
//             subDesc: "Position: Sale manager",
//           },
//         },
//         { type: "header", value: "Employee" },
//         {
//           type: "item-image",
//           value: {
//             title: "Production Team 1",
//             desc: "Production Department",
//             image: "",
//           },
//         },
//       ];
//       let views = [];
//       for (let i = 0; i < tmpData.length; i++) {
//         if (tmpData[i]?.type == "header") {
//           views.push(<div className={"header"}>{tmpData[i]?.value}</div>);
//         } else if (
//           tmpData[i]?.type == "item-normal" ||
//           tmpData[i]?.type == "item-image"
//         ) {
//           views.push(
//             <div className={"item-normal"}>
//               {tmpData[i]?.type == "item-image" && (
//                 <div className="item-img"></div>
//               )}
//               <div className="item-texts">
//                 {tmpData[i]?.value?.title && (
//                   <div className="title">{tmpData[i]?.value?.title}</div>
//                 )}
//                 {tmpData[i]?.value?.desc && (
//                   <div className="desc">{tmpData[i]?.value?.desc}</div>
//                 )}
//                 {tmpData[i]?.value?.subDesc && (
//                   <div className="sub-desc">{tmpData[i]?.value?.subDesc}</div>
//                 )}
//               </div>
//             </div>
//           );
//         }
//       }
//       setDropDownView([...views]);
//     } else {
//       setDropDownView([]);
//     }
//   }, [searchText]);

//   return (
//       <SearchSuggestBox
//         onChange={(e) => {
//           setSearchText(e);
//         }}
//         dropdownViews={dropDownViews}
//         onSelected={(index) => dlog(index)}
//       />
//   );
// }
