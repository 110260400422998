import { Stack } from '@mui/material'
import { styled } from '@mui/system'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RiExternalLinkLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { Option, Select } from 'components/form2/form-select'
import { TASK } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { formatDate } from 'utils/dateHelpers'
import { capitalizeEachWord } from 'utils/stringHelpers'
import { Chip, Footer, InfoContainer, TabPanelContainer } from '.'
import Button from 'components/common/Button'
import AssetTab from './asset-tab'
import DeliveryTab from './delivery-tab'
import GuidelineTab from './guide-line-tab'
import LogsTab from './logs-tab'
import NoteTab from './note-tab'
import Tabs from './tabs'

const Task = ({ taskId, onViewClick, onSubmit, onAssign, onUnassign }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const footerRef = useRef(null)
  const [footerHeight, setFooterHeight] = useState(0)

  const { sp: spActions, sw: swActions, task: taskActions, extra: extraActions } = opActions()
  const { getSP } = spActions()
  const { getSW } = swActions()
  const { getTask, postTask } = taskActions()
  const { clearNotes } = extraActions()

  const methods = useForm()
  const opProcSelector = useSelector(selectOpProc)
  const { task, task_employees, task_logs, task_logs_pagination } = opProcSelector

  const [tab, setTab] = useState('guideline')

  const tabItems = [
    { id: 'guideline', label: capitalizeEachWord(t('guideline')) },
    { id: 'delivery', label: capitalizeEachWord(t('delivery')) },
    { id: 'logs', label: t('logs') },
    { id: 'note', label: t('note') },
  ]

  const fetchTaskByID = useCallback(
    async (taskId) => {
      dispatch(getTask().byId(taskId))
    },
    [dispatch]
  )

  const fetchSPByID = useCallback(
    async (id) => {
      dispatch(getSP().byId(id))
    },
    [dispatch]
  )

  const fetchSWByID = useCallback(
    async (id) => {
      dispatch(getSW().byId(id))
    },
    [dispatch]
  )

  const fetchEmployees = useCallback(
    async (id, payload) => {
      dispatch(getTask().employees(id, payload))
    },
    [dispatch]
  )

  const fetchTaskLogs = useCallback(
    async (id, payload) => {
      dispatch(getTask().logs(id, payload))
    },
    [dispatch]
  )

  const handleAssigned = (data) => {
    dispatch(postTask().assign(task?.id, data)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        fetchTaskByID(task.id)
        fetchSWByID(task?.sw?.id)
        fetchSPByID(task?.sw?.sp?.id)
        onAssign && onAssign()
        return
      }
      return
    })
  }

  const handleUnassigned = () => {
    dispatch(postTask().unassign(task.id)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        // Reload
        fetchTaskByID(task.id)
        fetchSWByID(task?.sw?.id)
        fetchSPByID(task?.sw?.sp?.id)
        onUnassign && onUnassign()
        return
      }
      return
    })
  }

  const handleMarkAsCompleted = () => {
    dispatch(postTask().complete(task.id)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        fetchTaskByID(task.id)
        fetchSWByID(task?.sw?.id)
        fetchSPByID(task?.sw?.sp?.id)

        onSubmit && onSubmit()
        return
      }
    })
  }

  const handleViewClick = () => {
    const to = task?.sw?.sp?.id && `/operation-process/department-operation/${task.sw?.sp?.id}`
    const pathname = location.pathname

    if (pathname === to) {
      return onViewClick && onViewClick()
    }

    navigate(to)

    return
  }

  const handleTabChange = (value) => {
    setTab(value)

    switch (value) {
      case 'guideline':
        break
      case 'file':
        break
      case 'logs':
        fetchTaskLogs(taskId)
        break
      case 'note':
        break
      case 'asset':
        break
      default:
        break
    }
  }

  const handleUploadDeliveryCallback = () => {
    fetchTaskByID(taskId)
    dispatch(getSP().files(task?.sw?.sp?.id))
  }

  const handleDeleteDeliveryCallback = () => {
    fetchTaskByID(taskId)
    dispatch(getSP().files(task?.sw?.sp?.id))
  }

  useEffect(() => {
    if (!taskId) return

    fetchEmployees(taskId)
    fetchTaskByID(taskId)
    dispatch(clearNotes())
  }, [dispatch])

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.offsetHeight)
    }
  }, [footerRef.current?.offsetHeight])

  return (
    <>
      <InfoContainer id="info-container">
        <section>
          <label>{t('sop')}:</label>

          <Stack direction="row" gap="0.5rem">
            <p>{task?.sw?.sp?.name}</p>
            <a onClick={handleViewClick} style={{ cursor: 'pointer' }}>
              {t('view')} <RiExternalLinkLine fontSize={14} />
            </a>
          </Stack>
        </section>

        <section>
          <label>{t('scope_of_work')}:</label>

          <Stack direction="row" gap="0.5rem">
            <p>{task?.sw?.name}</p>
          </Stack>
        </section>

        <section>
          <label>{t('assign_to')}:</label>
          <Form onSubmit={methods.handleSubmit(handleAssigned)} style={{ alignItems: 'center' }}>
            {task?.employee?.id ? (
              <p>
                {task?.employee?.first_name} {task?.employee?.last_name}
              </p>
            ) : (
              <>
                <Select
                  {...methods.register('employee.id', {
                    value: task?.employee?.id,
                  })}
                  onChange={(_, data) => methods.setValue('employee.id', data)}
                  value={methods.watch('employee.id')}
                >
                  {task_employees.map((employee) => (
                    <Option
                      key={employee.id}
                      value={employee.id}
                      renderValue={(option) => {
                        if (!option || option === null) {
                          return <div style={{ color: 'var(--Gray-300)' }}>{t('select_assignee')}</div>
                        }
                        return option.label
                      }}
                    >
                      {employee.full_name}
                    </Option>
                  ))}
                </Select>

                <Button type="submit" variant="outlined">
                  {t('assign')}
                </Button>
              </>
            )}
          </Form>
        </section>

        <section>
          <label>{t('due_date')}:</label>
          <p>{formatDate(task?.sw?.due_date)}</p>
        </section>

        <section>
          <label>{t('status')}:</label>
          <Chip label={task?.status} color={TASK.STATUS_CHIP[task?.status]} />
        </section>

        <Tabs items={tabItems} onChange={handleTabChange} />

        <TabPanelContainer>
          {tab === 'guideline' && <GuidelineTab content={task?.description} />}
          {tab === 'delivery' && (
            <DeliveryTab
              deliveries={task?.deliveries}
              onUploadCallback={handleUploadDeliveryCallback}
              onDeleteCallback={handleDeleteDeliveryCallback}
            />
          )}
          {tab === 'logs' && (
            <LogsTab
              items={task_logs}
              onActions={{ onClickTask: () => {} }}
              notFoundDesc="There are no activities found for this task." // Not available yet
            />
          )}
          {tab === 'note' && <NoteTab spId={task?.sw?.sp?.id} />}
          {/* TODO: Asset tab doesn't support for task */}
          {tab === 'asset' && <AssetTab id={task?.id} />}
        </TabPanelContainer>
      </InfoContainer>

      <Footer ref={footerRef}>
        <Button className="gray" variant="outlined" onClick={handleUnassigned} disabled={!task?.employee}>
          {t('return_task')}
        </Button>
        <Button variant="contained" onClick={handleMarkAsCompleted} disabled={!task?.employee}>
          {t('mark_as_completed')}
        </Button>
      </Footer>
    </>
  )
}

export default Task

const Form = styled('form')`
  display: flex;
  gap: 1rem;
`
