const genRequest = (type) => ({
  REQUEST: `${type}_REQUEST`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
})

export const CLEAR_ALERT = 'CLEAR_ALERT'

export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'

export const SET_IS_REDIRECT = 'SET_IS_REDIRECT'

export const SET_IS_REFRESH_NOTI = 'SET_IS_REFRESH_NOTI'

export const LOGIN_ADMIN = genRequest('LOGIN_ADMIN')
export const VERIFY_OTP = genRequest('VERIFY_OTP')
export const RESEND_OTP = genRequest('RESEND_OTP')
export const SET_PASSWORD = genRequest('SET_PASSWORD')
export const RESET_PASSWORD = genRequest('RESET_PASSWORD')
export const FORGOT_PASSWORD = genRequest('FORGOT_PASSWORD')
export const CHANGE_COMPANY = genRequest('CHANGE_COMPANY')
export const GET_IMAGE = genRequest('GET_IMAGE')
export const REFRESH_TOKEN = genRequest('REFRESH_TOKEN')

export const GET_CMS_CONTENT_TYPE = genRequest('GET_CMS_CONTENT_TYPE')

export const GET_CMS_CONTENT_CONFIG = genRequest('GET_CMS_CONTENT_CONFIG')

export const GET_CMS_ITEM_LIST = genRequest('GET_CMS_ITEM_LIST')
export const GET_CMS_ITEM_DETAIL = genRequest('GET_CMS_ITEM_DETAIL')

export const CREATE_CMS_CONTENT = genRequest('CREATE_CMS_CONTENT')
export const UPDATE_CMS_CONTENT = genRequest('UPDATE_CMS_CONTENT')
export const DELETE_CMS_CONTENT = genRequest('DELETE_CMS_CONTENT')

export const GET_MATERIAL_LIST = genRequest('GET_MATERIAL_LIST')
export const GET_MATERIAL_BY_ID = genRequest('GET_MATERIAL_BY_ID')
export const GET_MATERIAL_CATEGORY = genRequest('GET_MATERIAL_CATEGORY')
export const GET_MATERIAL_TYPE = genRequest('GET_MATERIAL_TYPE')
export const GET_SUPPLIER = genRequest('GET_SUPPLIER')
export const GET_MATERIAL_NOTES = genRequest('GET_MATERIAL_NOTES')
export const CREATE_MATERIAL_NOTE = genRequest('CREATE_MATERIAL_NOTE')
export const CREATE_MATERIAL = genRequest('CREATE_MATERIAL')
export const EDIT_MATERIAL = genRequest('EDIT_MATERIAL')
export const UPLOAD_CSV_MATERIAL = genRequest('UPLOAD_CSV_MATERIAL')
export const DELETE_MATERIAL = genRequest('DELETE_MATERIAL')

export const GET_PRODUCTS = genRequest('GET_PRODUCTS')
export const GET_PRODUCT_BY_ID = genRequest('GET_PRODUCT_BY_ID')
export const CREATE_PRODUCT = genRequest('CREATE_PRODUCT')
export const UPDATE_PRODUCT = genRequest('UPDATE_PRODUCT')
export const DELETE_PRODUCT = genRequest('DELETE_PRODUCT')
export const GET_PRODUCTS_SPECIFICATIONS = genRequest('GET_PRODUCTS_SPECIFICATIONS')
export const GET_PRODUCTS_CATEGORIES = genRequest('GET_PRODUCTS_CATEGORIES')
export const GET_PRODUCTS_BRANDS = genRequest('GET_PRODUCTS_BRANDS')
export const GET_PRODUCTS_FORMULAS = genRequest('GET_PRODUCTS_FORMULAS')
export const GET_PRODUCTS_PRICE_STRUCTERS = genRequest('GET_PRODUCTS_PRICE_STRUCTERS')
export const GET_PRODUCTS_PRICE_COSTS = genRequest('GET_PRODUCTS_PRICE_COSTS')
export const GET_PRODUCT_MANAGERS = genRequest('GET_PRODUCT_MANAGERS')
export const GET_PRODUCTS_TYPES = genRequest('GET_PRODUCTS_TYPES')
export const GET_PRODUCTS_SUPPLIERS = genRequest('GET_PRODUCTS_SUPPLIERS')
export const GET_PRODUCTS_SEGMENTS = genRequest('GET_PRODUCTS_SEGMENTS')
export const GET_PRODUCTS_NOTES = genRequest('GET_PRODUCTS_NOTES')
export const CREATE_PRODUCTS_NOTE = genRequest('CREATE_PRODUCTS_NOTE')
export const UPLOAD_CSV_PRODUCT = genRequest('UPLOAD_CSV_PRODUCT')
// user management
export const GET_USERS_LIST = genRequest('GET_USERS_LIST') // users_list
export const CREATE_USERS_MANAGEMENT = genRequest('CREATE_USERS_MANAGEMENT')
export const GET_USERS_MANAGEMENT_DEPARTMENT = genRequest('GET_USERS_MANAGEMENT_DEPARTMENT') // DropDow
export const DELETE_USERS_MANAGEMENT = genRequest('DELETE_USERS_MANAGEMENT')
export const USERS_MANAGEMENT_ACCESS_ROLE = genRequest('USERS_MANAGEMENT_ACCESS_ROLE')
export const USERS_MANAGEMENT_GET_BY_ID = genRequest('USERS_MANAGEMENT_GET_BY_ID')
export const EDIT_USERS_MANAGEMENT = genRequest('EDIT_USERS_MANAGEMENT')
export const EDIT_USERS_MANAGEMENT_AND_EMPLOYEE = genRequest('EDIT_USERS_MANAGEMENT_AND_EMPLOYEE')
export const FINE_EMPLOYEE_USER = genRequest('FINE_EMPLOYEE_USER')
export const SET_EMPLOYEE_USER = 'SET_EMPLOYEE_USER'
// --------
export const VIEW_PRODUCT_RELATION = genRequest('VIEW_PRODUCT_RELATION')
export const UPDATE_PRICE_MODEL = genRequest('UPDATE_PRICE_MODEL')

export const GET_ROLE_LIST = genRequest('GET_ROLE_LIST')
export const GET_PERMISSION_ACTIONS = genRequest('GET_PERMISSION_ACTIONS')
export const GET_PERMISSION_LIST = genRequest('GET_PERMISSION_LIST')
export const CREATE_ROLE = genRequest('CREATE_ROLE')
export const GET_ROLE_BY_ID = genRequest('GET_ROLE_BY_ID')
export const UPDATE_ROLE = genRequest('UPDATE_ROLE')
export const DELETE_ROLE = genRequest('DELETE_ROLE')

export const GET_APPLICATION_FORMS = genRequest('GET_APPLICATION_FORMS')
export const GET_APPLICATION_TYPES = genRequest('GET_APPLICATION_TYPES')
export const GET_APPLICATION_REQUIRES = genRequest('GET_APPLICATION_REQUIRES')
export const CREATE_APPLICATION_FORM = genRequest('CREATE_APPLICATION_FORM')
export const GET_APPLICATION_FORM_BY_ID = genRequest('GET_APPLICATION_FORM_BY_ID')
export const SEND_APPLICATION_FORM_SUBMISSION = genRequest('SEND_APPLICATION_FORM_SUBMISSION')
export const RESEND_APPLICATION_FORM_SUBMISSION = genRequest('RESEND_APPLICATION_FORM_SUBMISSION')
export const UPDATE_APPLICATION_FORM = genRequest('UPDATE_APPLICATION_FORM')
export const CHANGE_APPLICATION_FORM_STATUS = genRequest('CHANGE_APPLICATION_FORM_STATUS')
export const VERIFY_APPLICATION_FORM_TOKEN = genRequest('VERIFY_APPLICATION_FORM_TOKEN')
export const CREATE_EMPLOYEE_WITH_APPLICATION_FORM = genRequest('CREATE_EMPLOYEE_WITH_APPLICATION_FORM')
export const GET_INVITATIONS = genRequest('GET_INVITATIONS')
export const GET_INVITATION_VIEW = genRequest('GET_INVITATION_VIEW')
export const CREATE_INVITATION = genRequest('CREATE_INVITATION')
export const SEND_INVITATION = genRequest('SEND_INVITATION')
export const DELETE_INVITATION = genRequest('DELETE_INVITATION')
export const CREATE_INVITATION_WITH_CSV = genRequest('CREATE_INVITATION_WITH_CSV')

export const GET_COUNTRIES = genRequest('GET_COUNTRIES')
export const GET_BANKS = genRequest('GET_BANKS')
export const GET_NATIONALITIES = genRequest('GET_NATIONALITIES')
export const GET_DEPARTMENT_AND_POSITION = genRequest('GET_DEPARTMENT_AND_POSITION')
export const GET_PROVINCES = genRequest('GET_PROVINCES')
export const GET_DISTRICTS = genRequest('GET_DISTRICTS')
export const GET_SUB_DISTRICTS = genRequest('GET_SUB_DISTRICTS')
export const GET_PROVINCE_OPTIONS = genRequest('GET_PROVINCE_OPTIONS')
export const GET_DISTRICT_OPTIONS = genRequest('GET_DISTRICT_OPTIONS')
export const GET_SUB_DISTRICT_OPTIONS = genRequest('GET_SUB_DISTRICT_OPTIONS')
export const UPLOAD_FILE = genRequest('UPLOAD_FILE')

export const GET_VERIFY_USER_INFO = genRequest('GET_VERIFY_USER_INFO')

export const PUT_SEND_RESET_PASSWORD_PERMISSION = genRequest('PUT_SEND_RESET_PASSWORD_PERMISSION')
export const GET_PERMISSION_RESET_PASSWORD_TOKEN = genRequest('GET_PERMISSION_RESET_PASSWORD_TOKEN')
export const SET_PASSWORD_USERS_CHANGE = genRequest('SET_PASSWORD_USERS_CHANGE')

export const GET_DEPARTMENTS = genRequest('GET_DEPARTMENTS')
export const GET_DEPARTMENT_BY_ID = genRequest('GET_DEPARTMENT_BY_ID')
export const CREATE_DEPARTMENTS = genRequest('CREATE_DEPARTMENTS')
export const UPDATE_DEPARTMENTS = genRequest('UPDATE_DEPARTMENTS')
export const DELETE_DEPARTMENTS = genRequest('DELETE_DEPARTMENTS')

export const GET_SOW_BY_DEPARTMENT_ID = genRequest('GET_SOW_BY_DEPARTMENT_ID')
export const GET_SOW_BY_ID = genRequest('GET_SOW_BY_ID')
export const CREATE_SOW = genRequest('CREATE_SOW')
export const UPDATE_STATUS_SOW = genRequest('UPDATE_STATUS_SOW')
export const DELETE_SOW = genRequest('DELETE_SOW')
export const GET_TASK_TYPES = genRequest('GET_TASK_TYPES')
export const GET_TASK_TYPE_BY_ID = genRequest('GET_TASK_TYPE_BY_ID')
export const CREATE_TASK_TYPE = genRequest('CREATE_TASK_TYPE')
export const UPDATE_TASK_TYPE = genRequest('UPDATE_TASK_TYPE')

export const GET_SOW_BY_DEPARTMENT = genRequest('GET_SOW_BY_DEPARTMENT')
export const GET_POSITIONS = genRequest('GET_POSITIONS')
export const GET_POSITIONS_DROPDOWN = genRequest('GET_POSITIONS_DROPDOWN')
export const GET_POSITION_BY_ID = genRequest('GET_POSITION_BY_ID')
export const CREATE_POSITION = genRequest('CREATE_POSITION')
export const UPDATE_POSITION = genRequest('UPDATE_POSITION')
export const DELETE_POSITION = genRequest('DELETE_POSITION')

// Organization Management
export const GET_PROVINCES_DEPATEMENT = genRequest('GET_PROVINCES_DEPATEMENT') // จังหวัด
export const GET_DISTRICTS_DEPATEMENT = genRequest('GET_DISTRICTS_DEPATEMENT') // อำเภอ
export const GET_SUB_DISTRICTS_DEPATEMENT = genRequest('GET_SUB_DISTRICTS_DEPATEMENT') // ตำบล
// Task Routine
export const GET_SUMMARY_TASK = genRequest('GET_SUMMARY_TASK')
export const GET_SUMMARY_MY_TASK = genRequest('GET_SUMMARY_MY_TASK')
export const GET_TASK_BY_ID = genRequest('GET_TASK_BY_ID')
export const CREATE_TASK = genRequest('CREATE_TASK')
export const EDIT_TASK = genRequest('EDIT_TASK')
export const UPDATE_TASK_ACTION = genRequest('UPDATE_TASK_ACTION')
export const UPDATE_TASK_ROUTINE = genRequest('UPDATE_TASK_ROUTINE')

// sub Task
export const CREATE_SUB_TASK = genRequest('CREATE_SUB_TASK')
export const UPDATE_SUB_TASK_ACTION = genRequest('UPDATE_SUB_TASK_ACTION')
export const EDIT_SUB_TASK = genRequest('EDIT_SUB_TASK')
export const GET_SUB_TASK_BY_ID = genRequest('GET_SUB_TASK_BY_ID')

export const GET_EMPLOYEE_TASK = genRequest('GET_EMPLOYEE_TASK')
export const GET_EMPLOYEE_TABLE_TASK = genRequest('GET_EMPLOYEE_TABLE_TASK')
export const UPDATE_NOTE_ACTION = genRequest('UPDATE_NOTE_ACTION')
// doc task
export const CREATE_DOCUMENT_TASK = genRequest('CREATE_DOCUMENT_TASK')
export const GET_DOCUMENT_TASK = genRequest('GET_DOCUMENT_TASK')
export const UPDATE_DOCUMENT_TASK = genRequest('UPDATE_DOCUMENT_TASK')
export const DELETE_DOCUMENT_TASK = genRequest('DELETE_DOCUMENT_TASK')
// doc item
export const ADD_DOCUMENT_ITEM = genRequest('ADD_DOCUMENT_ITEM')
export const DELETE_DOCUMENT_ITEM = genRequest('DELETE_DOCUMENT_ITEM')
// approve doc
export const CREATE_DOC_REQUEST_APPROVE = genRequest('CREATE_DOC_REQUEST_APPROVE')
export const GET_REQUEST_APPROVES = genRequest('GET_REQUEST_APPROVES')
export const GET_REQUEST_APPROVE_ITEM = genRequest('GET_REQUEST_APPROVE_ITEM')
export const UPDATE_REQUEST_APPROVE_ITEM = genRequest('UPDATE_REQUEST_APPROVE_ITEM')
// province
export const GET_PROVINCE_LIST = genRequest('GET_PROVINCE_LIST')
export const GET_AMPHURE_LIST = genRequest('GET_AMPHURE_LIST')
export const GET_TAMBON_LIST = genRequest('GET_TAMBON_LIST')
// project_task
export const GET_PROJECT_TASK = genRequest('GET_PROJECT_TASK')
export const GET_PROJECT_LIST_DROPDOWN = genRequest('GET_PROJECT_LIST_DROPDOWN')
export const GET_MILESTONE_BY_PROJECT_TASK_ID = genRequest('GET_MILESTONE_BY_PROJECT_TASK_ID')
export const GET_MILESTONE_SCOPE_BY_PROJECT_TASK_ID = genRequest('GET_MILESTONE_SCOPE_BY_PROJECT_TASK_ID')
export const GET_MILESTONE_LIST_BY_PROJECT_TASK_ID = genRequest('GET_MILESTONE_LIST_BY_PROJECT_TASK_ID')
export const GET_MILESTONE_SCOPE_BY_MILESTONE_ID = genRequest('GET_MILESTONE_SCOPE_BY_MILESTONE_ID')
export const GET_MILESTONE_TASK_TYPE_BY_MILESTONE_TASK_SCOPE_ID = genRequest(
  'GET_MILESTONE_TASK_TYPE_BY_MILESTONE_TASK_SCOPE_ID'
)
export const GET_SCOPE_WORK_BY_MILESTONE_ID = genRequest('GET_SCOPE_WORK_BY_MILESTONE_ID')
export const GET_TASK_TYPE_BY_TASK_SCOPE = genRequest('GET_TASK_TYPE_BY_TASK_SCOPE')
export const CREATE_PROJECT_TASK = genRequest('CREATE_PROJECT_TASK')
export const ADD_TASK_TO_PROJECT_TASK = genRequest('ADD_TASK_TO_PROJECT_TASK')
export const GET_PROJECT_TASK_BY_ID = genRequest('GET_PROJECT_TASK_BY_ID')
export const CREATE_TASK_TEMPLATE = genRequest('CREATE_TASK_TEMPLATE')
export const EDIT_PROJECT_TASK = genRequest('EDIT_PROJECT_TASK')
export const UPDATE_STATUS_PROJECT_TASK = genRequest('UPDATE_STATUS_PROJECT_TASK')
export const DELETE_TASK_IN_PROJECT_TASK = genRequest('DELETE_TASK_IN_PROJECT_TASK')
export const UPDATE_SCOPE_STATUS_IN_MILESTONE = genRequest('UPDATE_SCOPE_STATUS_IN_MILESTONE')
export const GET_MILESTONE_TASK_TYPE_INFO_BY_TASK_ID = genRequest('GET_MILESTONE_TASK_TYPE_INFO_BY_TASK_ID')
export const UPDATE_TASK_TYPE_STATUS_IN_MILESTONE = genRequest('UPDATE_TASK_TYPE_STATUS_IN_MILESTONE')
export const GET_MILESTONE_SCOPE_INFO_BY_SCOPE_ID = genRequest('GET_MILESTONE_SCOPE_INFO_BY_SCOPE_ID')
export const GET_SCOPE_OF_WORK = genRequest('GET_SCOPE_OF_WORK')
export const GET_SCOPE_WORK_BY_POSITION_ID = genRequest('GET_SCOPE_WORK_BY_POSITION_ID')
export const GET_TASK_TYPE_BY_SCOPE_ID = genRequest('GET_TASK_TYPE_BY_SCOPE_ID')
export const GET_TASK_TEMPLATE_LIST = genRequest('GET_TASK_TEMPLATE_LIST')
export const GET_TASK_TEMPLATE_BY_ID = genRequest('GET_TASK_TEMPLATE_BY_ID')

export const GET_LATEST_PROGRESS_UPDATE = genRequest('GET_LATEST_PROGRESS_UPDATE')
export const GET_HISTORY_PROGRESS_UPDATE = genRequest('GET_HISTORY_PROGRESS_UPDATE')
export const GET_PROGRESS_UPDATE_BY_ID = genRequest('GET_PROGRESS_UPDATE_BY_ID')
export const CREATE_PROGRESS_UPDATE = genRequest('CREATE_PROGRESS_UPDATE')
export const EDIT_PROGRESS_UPDATE = genRequest('EDIT_PROGRESS_UPDATE')

export const GET_PROJECT_TASK_NOTES = genRequest('GET_PROJECT_TASK_NOTES')
export const CREATE_PROJECT_TASK_NOTES = genRequest('CREATE_PROJECT_TASK_NOTES')

// Milestones
export const CREATE_MILESTONE = genRequest('CREATE_MILESTONE')
export const UPDATE_MILESTONE = genRequest('UPDATE_MILESTONE')
export const GET_MILESTONE_BY_ID = genRequest('GET_MILESTONE_BY_ID')
export const GET_POSITION_BY_DEPARTMENT_ID = genRequest('GET_POSITION_BY_DEPARTMENT_ID')
export const DELETE_MILESTONE = genRequest('DELETE_MILESTONE')
//
export const GET_TASK_EMPLOYEE = genRequest('GET_TASK_EMPLOYEE')

export const GET_ASSIGNED_EMPLOYEE = genRequest('GET_ASSIGNED_EMPLOYEE')

// --------- Employee
export const GET_EMPLOYEE = genRequest('GET_EMPLOYEE')
export const CREATE_EMPLOYEE = genRequest('CREATE_EMPLOYEE')
export const UPDATE_EMPLOYEE = genRequest('UPDATE_EMPLOYEE')
export const GET_EMPLOYEE_BY_ID = genRequest('GET_EMPLOYEE_BY_ID')
export const UPDATE_EMPLOYEE_STATUS = genRequest('UPDATE_EMPLOYEE_STATUS')
export const GET_EmployeeOther = genRequest('GET_EmployeeOther')

// Organization Management ---> tab Company Profile
export const CREATE_COMPANYPROFILE = genRequest('CREATE_COMPANYPROFILE')
export const UPDATE_SUB_COMPANYPROFILE = genRequest('UPDATE_SUB_COMPANYPROFILE')
export const GET_COMPANYPROFILE = genRequest('GET_COMPANYPROFILE')
export const GET_SUBCOMPANYPROFILE = genRequest('GET_SUBCOMPANYPROFILE')
export const UPDATE_COMPANYPROFILE = genRequest('UPDATE_COMPANYPROFILE')
export const GET_BRANCH_COMPANY_LIST = genRequest('GET_BRANCH_COMPANY_LIST')
export const CREATE_BRANCH_COMPANY = genRequest('CREATE_BRANCH_COMPANY')
export const GET_BRANCH_COMPANY_EDIT_BYID = genRequest('GET_BRANCH_COMPANY_EDIT_BYID')
export const UPDATE_BRANCH_COMPANYPROFILE = genRequest('UPDATE_BRANCH_COMPANYPROFILE')
export const DELETE_BRANCH_COMPANY_PROFILE = genRequest('DELETE_BRANCH_COMPANY_PROFILE')

export const GET_ORGANIZATION_CHART = genRequest('GET_ORGANIZATION_CHART')
export const GET_DEPARTMENT_CHART = genRequest('GET_DEPARTMENT_CHART')
export const GET_ORG_CHART = genRequest('GET_ORG_CHART')
// --------- Daily Summary Report
export const GET_DailySummary = genRequest('GET_DailySummary')
export const CREATE_DailySummary = genRequest('CREATE_DailySummary')
export const UPDATE_DailySummary = genRequest('UPDATE_DailySummary')
export const GET_DailySummary_BY_ID = genRequest('GET_DailySummary_BY_ID')
export const UPDATE_DailySummary_STATUS = genRequest('UPDATE_DailySummary_STATUS')
export const GET_DailySummaryReturnData = genRequest('GET_DailySummaryReturnData')

// --------- ActionPlan
export const GET_ActionPlan = genRequest('GET_ActionPlan')
export const CREATE_ActionPlan = genRequest('CREATE_ActionPlan')
export const UPDATE_ActionPlan = genRequest('UPDATE_ActionPlan')
export const GET_ActionPlan_BY_ID = genRequest('GET_ActionPlan_BY_ID')
export const UPDATE_ActionPlan_STATUS = genRequest('UPDATE_ActionPlan_STATUS')
export const GET_ActionPlanReturnData = genRequest('GET_ActionPlanReturnData')
export const GET_OP_TASK_LIST = genRequest('GET_OP_TASK_LIST')
export const GET_OP_REVIEWER_LIST = genRequest('GET_OP_REVIEWER_LIST')
export const GET_OP_APPROVER_LIST = genRequest('GET_OP_APPROVER_LIST')
export const GET_OP_SW_LIST = genRequest('GET_OP_SW_LIST')
export const GET_PROJECT_FOR_ACTION_PLAN = genRequest('GET_PROJECT_FOR_ACTION_PLAN')
export const GET_TASK_FOR_ACTION_PLAN = genRequest('GET_TASK_FOR_ACTION_PLAN')
export const GET_TOTAL_PROJECT_TASK = genRequest('GET_TOTAL_PROJECT_TASK')
export const GET_TOTAL_TASK = genRequest('GET_TOTAL_TASK')
export const GET_OP_COUNT = genRequest('GET_OP_COUNT')

// --------- Meeting
export const GET_MeetingRoomAll = genRequest('GET_MeetingRoomAll')
export const GET_MeetingRoom = genRequest('GET_MeetingRoom')
export const CREATE_MeetingRoom = genRequest('CREATE_MeetingRoom')
export const UPDATE_MeetingRoom = genRequest('UPDATE_MeetingRoom')
export const GET_Meeting = genRequest('GET_Meeting')
export const CREATE_Meeting = genRequest('CREATE_Meeting')
export const UPDATE_Meeting = genRequest('UPDATE_Meeting')
export const GET_Meeting_BY_ID = genRequest('GET_Meeting_BY_ID')
export const GET_MeetingForCheck = genRequest('GET_MeetingForCheck')
export const GET_MeetingAttendee = genRequest('GET_MeetingAttendee')
export const UPDATE_Meeting_attendances = genRequest('UPDATE_Meeting_attendances')
export const UPDATE_ApproveMeetingAttend = genRequest('UPDATE_ApproveMeetingAttend')

// --------- notifications
export const GET_NOTIFICATIONS_LIST_ALL = genRequest('GET_NOTIFICATIONS_LIST_ALL')
export const UPDATE_NOTIFICATIONS = genRequest('UPDATE_NOTIFICATIONS')
export const GET_NOTIFICATIONS_READE_ALL = genRequest('GET_NOTIFICATIONS_READE_ALL')
export const GET_NOTIFICATIONS_UNREADE_ALL = genRequest('GET_NOTIFICATIONS_UNREADE_ALL')

//-------- Project Dashboard
export const GET_PROJECT_DASHBOARD = genRequest('GET_PROJECT_DASHBOARD')
export const GET_PROJECT_MANAGER = genRequest('GET_PROJECT_MANAGER')
export const GET_PROJECT_DASHBOARD_BY_ID = genRequest('GET_PROJECT_DASHBOARD_BY_ID')
export const GET_LOGS = genRequest('GET_LOGS')
//-------- Employee Dashboard
export const GET_EMPLOYEE_DASHBOARD = genRequest('GET_EMPLOYEE_DASHBOARD')
export const GET_EMPLOYEE_DASHBOARD_BY_ID = genRequest('GET_EMPLOYEE_DASHBOARD_BY_ID')
export const GET_SUMMARY_OF_EMPLOYEE_DASHBOARD = genRequest('GET_SUMMARY_OF_EMPLOYEE_DASHBOARD')
export const GET_TASKS_OF_EMPLOYEE_DASHBOARD = genRequest('GET_TASKS_OF_EMPLOYEE_DASHBOARD')
export const GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD = genRequest('GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD')
export const GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD = genRequest(
  'GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD'
)
//-------- MASTER
export const GET_MASTER_EMPLOYEE = genRequest('GET_MASTER_EMPLOYEE')
export const GET_MASTER_EMPLOYEE_2 = genRequest('GET_MASTER_EMPLOYEE_2')
export const GET_ALL_COMPANY_LIST = genRequest('GET_ALL_COMPANY_LIST')
export const GET_ALL_MENU = genRequest('GET_ALL_MENU')

// Shared-Service
export const GET_SHARED_SERVICE_TEMPLATE = genRequest('GET_SHARED_SERVICE_TEMPLATE')
export const GET_SHARED_SERVICE_TEMPLATE_BY_ID = genRequest('GET_SHARED_SERVICE_TEMPLATE_BY_ID')
export const CREATE_DOCUMENT_ACKNOWLEDGEMENT = genRequest('CREATE_DOCUMENT_ACKNOWLEDGEMENT')
export const SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT = genRequest('SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT')
export const GET_DOCUMENT_ACKNOWLEDGE = genRequest('GET_DOCUMENT_ACKNOWLEDGE')
export const GET_DOCUMENT_ACKNOWLEDGE_BY_ID = genRequest('GET_DOCUMENT_ACKNOWLEDGE_BY_ID')
export const DELETE_DOCUMENT_ACKNOWLEDGE = genRequest('DELETE_DOCUMENT_ACKNOWLEDGE')
export const SET_DOCUMENT_FORM = 'SET_DOCUMENT_FORM'
export const SET_SHARED_SERVICE_INFO = 'SET_SHARED_SERVICE_INFO'
export const SET_DOCUMENT_ACKNOWLEDGEMENT_INFO = 'SET_DOCUMENT_ACKNOWLEDGEMENT_INFO'
export const SET_FLAG_DRAFT = 'SET_FLAG_DRAFT'
export const SET_NOTI_EMAIL_DOC_ID = 'SET_NOTI_EMAIL_DOC_ID'
export const SET_ASSIGNMENT_INFO = 'SET_ASSIGNMENT_INFO'

export const GET_PENDING_ASSIGNMENTS = genRequest('GET_PENDING_ASSIGNMENTS')
export const GET_ASSIGNMENT_BY_ID = genRequest('GET_ASSIGNMENT_BY_ID')
export const READING_ASSIGNMENT = genRequest('READING_ASSIGNMENT')
export const ACKNOWLEDGE_ASSIGNMENT = genRequest('ACKNOWLEDGE_ASSIGNMENT')
export const GET_EMPLOYEE_SIGNATURE = genRequest('GET_EMPLOYEE_SIGNATURE')
export const UPLOAD_SIGNATURE = genRequest('UPLOAD_SIGNATURE')
export const SIGN_SIGNATURE = genRequest('SIGN_SIGNATURE')
export const GET_REVIEW_ASSIGNMENTS = genRequest('GET_REVIEW_ASSIGNMENTS')
export const GET_OVER_DUE_ASSIGNMENTS = genRequest('GET_OVER_DUE_ASSIGNMENTS')
export const READ_INFO_ASSIGNMENT = genRequest('READ_INFO_ASSIGNMENT')

export const GET_DOCUMENT_CATEGORY = genRequest('GET_DOCUMENT_CATEGORY')
export const GET_DOCUMENT_CATEGORY_BY_ID = genRequest('GET_DOCUMENT_CATEGORY_BY_ID')
export const CREATE_DOCUMENT_CATEGORY = genRequest('CREATE_DOCUMENT_CATEGORY')
export const EDIT_DOCUMENT_CATEGORY = genRequest('EDIT_DOCUMENT_CATEGORY')
export const DELETE_DOCUMENT_CATEGORY = genRequest('DELETE_DOCUMENT_CATEGORY')

export const GET_DOCUMENT_TEMPLATE = genRequest('GET_DOCUMENT_TEMPLATE')
export const GET_DOCUMENT_TEMPLATE_BY_ID = genRequest('GET_DOCUMENT_TEMPLATE_BY_ID')
export const CREATE_DOCUMENT_TEMPLATE = genRequest('CREATE_DOCUMENT_TEMPLATE')
export const EDIT_DOCUMENT_TEMPLATE = genRequest('EDIT_DOCUMENT_TEMPLATE')
export const DELETE_DOCUMENT_TEMPLATE = genRequest('DELETE_DOCUMENT_TEMPLATE')
export const DUPLICATE_DOCUMENT_TEMPLATE = genRequest('DUPLICATE_DOCUMENT_TEMPLATE')
export const GET_CATEGORIES = genRequest('GET_CATEGORIES')
export const GET_DOCUMENT_INVITE_LIST = genRequest('GET_DOCUMENT_INVITE_LIST')
export const ADD_INVITE = genRequest('ADD_INVITE')
export const DELETE_INVITE = genRequest('DELETE_INVITE')

export const GET_PENDING_ASSIGNMENT_BY_ID = genRequest('GET_PENDING_ASSIGNMENT_BY_ID')
export const GET_REVIEW_ASSIGNMENT_BY_ID = genRequest('GET_REVIEW_ASSIGNMENT_BY_ID')

export const DUPLICATE_DOCUMENT_ACKNOWLEDGE = genRequest('DUPLICATE_DOCUMENT_ACKNOWLEDGE')

export const SEND_DOC_NOTIFICATION = genRequest('SEND_DOC_NOTIFICATION')

export const UPDATE_EMPLOYEE_INFO = genRequest('UPDATE_EMPLOYEE_INFO')

//Document
export const GET_DOCUMENT_LIST = genRequest('GET_DOCUMENT_LIST')
export const GET_QUOTATION_LIST = genRequest('GET_QUOTATION_LIST')
export const CREATE_QUOTATION = genRequest('CREATE_QUOTATION')
export const GET_QUOTATION_BY_ID = genRequest('GET_QUOTATION_BY_ID')
export const EDIT_QUOTATION_DOC = genRequest('EDIT_QUOTATION_DOC')

export const GET_SALE_DOCUMENT_BY_ID = genRequest('GET_SALE_DOCUMENT_BY_ID')
export const EDIT_SALE_DOCUMENT = genRequest('EDIT_SALE_DOCUMENT')
export const CREATE_SALE_DOCUMENT = genRequest('CREATE_SALE_DOCUMENT')

export const GET_PURCHASE_LIST = genRequest('GET_PURCHASE_LIST')
export const CREATE_PURCHASE = genRequest('CREATE_PURCHASE')
export const GET_PURCHASE_BY_ID = genRequest('GET_PURCHASE_BY_ID')
export const EDIT_PURCHASE_DOC = genRequest('EDIT_PURCHASE_DOC')
export const SEND_EMAIL_DOCUMENT = genRequest('SEND_EMAIL_DOCUMENT')
export const SHARE_LINK_DOCUMENT = genRequest('SHARE_LINK_DOCUMENT')

export const GET_CUSTOMER_LIST = genRequest('GET_CUSTOMER_LIST')
export const CREATE_CUSTOMER = genRequest('CREATE_CUSTOMER')
export const REQUEST_DOCUMENT_APPROVE = genRequest('REQUEST_DOCUMENT_APPROVE')
export const GET_COMPANY_INFO_BY_ID = genRequest('GET_COMPANY_INFO_BY_ID')
export const GET_SELLER_LIST = genRequest('GET_SELLER_LIST')
export const CREATE_SELLER = genRequest('CREATE_SELLER')

export const GET_DOCUMENT_SETTING_BY_COMPANY_ID = genRequest('GET_DOCUMENT_SETTING_BY_COMPANY_ID')
export const UPDATE_DOCUMENT_SETTING_BY_COMPANY_ID = genRequest('UPDATE_DOCUMENT_SETTING_BY_COMPANY_ID')
//businessDocument
export const CREATE_BUSINESS_DOCUMENT = genRequest('CREATE_BUSINESS_DOCUMENT')
export const GET_BUSINESS_DOCUMENT_BY_ID = genRequest('GET_BUSINESS_DOCUMENT_BY_ID')
export const EDIT_BUSINESS_DOCUMENT = genRequest('EDIT_BUSINESS_DOCUMENT')

// employee activity
export const GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION = genRequest('GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION')
export const GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT = genRequest('GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT')
export const GET_EMPLOYEE_LIST_ANALYSIS = genRequest('GET_EMPLOYEE_LIST_ANALYSIS')
export const GET_ANALYSIS_EMPLOYEE_INFO = genRequest('GET_ANALYSIS_EMPLOYEE_INFO')

//----- OFM
export const GET_ZoneOFM = genRequest('GET_ZoneOFM')
export const ADD_USERJOB = genRequest('ADD_USERJOB')
export const DEL_USERJOB = genRequest('DEL_USERJOB')
export const UPDATE_ReportOFM = genRequest('UPDATE_ReportOFM')
//----- OFM Dashboard
export const SET_COCK_PIT_DATA = 'SET_COCK_PIT_DATA'
export const GET_ZONE_OFM = genRequest('GET_ZONE_OFM')
export const GET_COCK_PIT = genRequest('GET_COCK_PIT')
export const GET_LOG_HISTORICAL_TASK = genRequest('GET_LOG_HISTORICAL_TASK')
export const GET_LOG_LATEST_TASK = genRequest('GET_LOG_LATEST_TASK')
export const SET_LOG_LATEST_TASK = 'SET_LOG_LATEST_TASK'

// employee profile
export const GET_EMPLOYEE_PROFILE_INFO = genRequest('GET_EMPLOYEE_PROFILE_INFO')
export const REQUEST_EDIT_EMPLOYEE_PROFILE_INFO = genRequest('REQUEST_EDIT_EMPLOYEE_PROFILE_INFO')
export const EDIT_EMPLOYEE_PROFILE_INFO = genRequest('EDIT_EMPLOYEE_PROFILE_INFO')
export const GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE = genRequest('GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE')
export const GET_REQUEST_EDIT_LOGS = genRequest('GET_REQUEST_EDIT_LOGS')
export const GET_REQUEST_EDIT_LOG_BY_ID = genRequest('GET_REQUEST_EDIT_LOG_BY_ID')
export const APPROVE_REQUEST_EDIT = genRequest('APPROVE_REQUEST_EDIT')
export const GET_ARCHIVED_REQUEST = genRequest('GET_ARCHIVED_REQUEST')
export const CHANGE_PASSWORD = genRequest('CHANGE_PASSWORD')
export const VERIFY_PASSWORD = genRequest('VERIFY_PASSWORD')
export const UPLOAD_EMPLOYEE_SIGNATURE = genRequest('UPLOAD_EMPLOYEE_SIGNATURE')
export const UPLOAD_SIGNATURE_IMAGE = genRequest('UPLOAD_SIGNATURE_IMAGE')
export const CLEAR_STATE_EMPLOYEE_PROFILE = 'CLEAR_STATE_EMPLOYEE_PROFILE'
export const CLEAR_RESPONSE_UPLOAD_SIGNATURE = 'CLEAR_RESPONSE_UPLOAD_SIGNATURE'

// Organization Team Management
export const GET_GROUP_TEAM = genRequest('GET_GROUP_TEAM')
export const GET_GROUP_TEAM_BY_ID = genRequest('GET_GROUP_TEAM_BY_ID')
export const CREATE_GROUP_TEAM = genRequest('CREATE_GROUP_TEAM')
export const EDIT_GROUP_TEAM = genRequest('EDIT_GROUP_TEAM')
export const DELETE_GROUP_TEAM = genRequest('DELETE_GROUP_TEAM')

export const GET_TEAM = genRequest('GET_TEAM')
export const GET_TEAM_BY_ID = genRequest('GET_TEAM_BY_ID')
export const CREATE_TEAM = genRequest('CREATE_TEAM')
export const EDIT_TEAM = genRequest('EDIT_TEAM')
export const DELETE_TEAM = genRequest('DELETE_TEAM')

export const GET_MEMBER = genRequest('GET_MEMBER')
export const GET_MEMBER_FOR_ADD = genRequest('GET_MEMBER_FOR_ADD')
export const CREATE_TEAM_MEMBER = genRequest('CREATE_TEAM_MEMBER')
export const CREATE_REPRESENTATIVE = genRequest('CREATE_REPRESENTATIVE')
export const EDIT_TEAM_MEMBER = genRequest('EDIT_TEAM_MEMBER')
export const DELETE_TEAM_MEMBER = genRequest('DELETE_TEAM_MEMBER')

// kpi management
export const GET_KPI_LIST = genRequest('GET_KPI_LIST')
export const GET_KPI_DRAFT_LIST = genRequest('GET_KPI_DRAFT_LIST')
export const GET_KPI_ARCHIVE_LIST = genRequest('GET_KPI_ARCHIVE_LIST')
export const GET_KPI_BY_ID = genRequest('GET_KPI_BY_ID')
export const GET_KPI_HISTORY = genRequest('GET_KPI_HISTORY')
export const GET_KPI_HISTORY_DETAIL = genRequest('GET_KPI_HISTORY_DETAIL')
export const CREATE_KPI = genRequest('CREATE_KPI')
export const EDI_KPI = genRequest('EDI_KPI')
export const PUBLISH_KPI = genRequest('PUBLISH_KPI')
export const DELETE_KPI = genRequest('DELETE_KPI')
export const CLEAR_EDIT_KPI_FORM = 'CLEAR_EDIT_KPI_FORM'

// weekly summary report
export const GET_WEEKLY_SUMMARY_REPORTS = genRequest('GET_WEEKLY_SUMMARY_REPORTS')
export const GET_WEEKLY_SUMMARY_REPORT_BY_ID = genRequest('GET_WEEKLY_SUMMARY_REPORT_BY_ID')
export const CREATE_WEEKLY_SUMMARY_REPORT = genRequest('CREATE_WEEKLY_SUMMARY_REPORT')
export const UPDATE_WEEKLY_SUMMARY_REPORT = genRequest('UPDATE_WEEKLY_SUMMARY_REPORT')
export const DELETE_WEEKLY_SUMMARY_REPORT = genRequest('DELETE_WEEKLY_SUMMARY_REPORT')
export const GET_PROJECT_TASKS = genRequest('GET_PROJECT_TASKS')
export const SET_MODAL_INFO = 'SET_MODAL_INFO'

export const DYNAMIC_FORM = {
  GET: {
    GET_LANDING_PAGE_SCHEMA: genRequest('GET_LANDING_PAGE_SCHEMA'),
    GET_CREATE_SCHEMA: genRequest('GET_CREATE_SCHEMA'),
    GET_EDIT_SCHEMA: genRequest('GET_EDIT_SCHEMA'),
    GET_VIEW_SCHEMA: genRequest('GET_VIEW_SCHEMA'),
    GET_DATA_DYNAMIC_FORM: genRequest('GET_DATA_DYNAMIC_FORM'),
    GET_DATA_DYNAMIC_FORM_BY_ID: genRequest('GET_DATA_DYNAMIC_FORM_BY_ID'),
    GET_DROPDOWN_OPTIONS: genRequest('GET_DROPDOWN_OPTIONS'),
    GET_DROPDOWN_OPTIONS_BY_SPECIFIC: genRequest('GET_DROPDOWN_OPTIONS_BY_SPECIFIC'),
  },
  POST: {
    CREATE_DYNAMIC_FORM: genRequest('CREATE_DYNAMIC_FORM'),
  },
  PUT: {
    UPDATE_DYNAMIC_FORM: genRequest('UPDATE_DYNAMIC_FORM'),
  },
  DELETE: {
    DELETE_DYNAMIC_FORM: genRequest('DELETE_DYNAMIC_FORM'),
  },
  CLEAR: {
    CLEAR_EDIT_SCHEMA: 'CLEAR_EDIT_SCHEMA',
  },
}

export const OP = {
  GET: {
    SP_TEMPLATES: genRequest('GET_OP_SP_TEMPLATES'),
    SP_TEMPLATE_BY_ID: genRequest('GET_OP_SP_TEMPLATE_BY_ID'),
    CL_TEMPLATES: genRequest('GET_OP_CL_TEMPLATES'),
    CL_TEMPLATE_BY_ID: genRequest('GET_OP_CL_TEMPLATE_BY_ID'),

    TAGS: genRequest('GET_OP_TAGS'),
    NOTES: genRequest('GET_OP_NOTES'),
    DEPARTMENTS: genRequest('GET_OP_DEPARTMENTS'),
    DOC_TYPE_LIST: genRequest('GET_OP_DOC_TYPE_LIST'), // 📝 NOTE: Use for get doc type option for SOW Template.

    SPs: genRequest('GET_OP_SPs'),
    SP_BY_ID: genRequest('GET_OP_SP_BY_ID'),
    SP_BY_ID_FILES: genRequest('GET_OP_SP_BY_ID_FILES'),
    SP_BY_ID_DASHBOARD: genRequest('GET_OP_SP_BY_ID_DASHBOARD'),
    SP_BY_ID_LOGS: genRequest('GET_OP_SP_BY_ID_LOGS'),
    SP_BY_ID_DELIVERIES: genRequest('GET_OP_SP_BY_ID_DELIVERIES'),
    SWs_BOARD: genRequest('GET_OP_SWs_BOARD'),
    SWs_ME: genRequest('GET_OP_SWs_ME'),
    SW_BY_ID: genRequest('GET_OP_SW_BY_ID'),
    SW_BY_ID_EMPLOYEES: genRequest('GET_OP_SW_BY_ID_EMPLOYEES'),
    SW_BY_ID_FILES: genRequest('GET_OP_SW_BY_ID_FILES'),
    SW_BY_ID_LOGS: genRequest('GET_OP_SW_BY_ID_LOGS'),
    TASKS_BOARD: genRequest('GET_OP_TASKS_BOARD'),
    TASKS_ME: genRequest('GET_OP_TASKS_ME'),
    TASK_BY_ID: genRequest('GET_OP_TASK_BY_ID'),
    TASK_BY_ID_EMPLOYEES: genRequest('GET_OP_TASK_BY_ID_EMPLOYEES'),
    TASK_BY_ID_FILES: genRequest('GET_OP_TASK_BY_ID_FILES'),
    TASK_BY_ID_LOGS: genRequest('GET_OP_TASK_BY_ID_LOGS'),
    REVIEWERS_OWNER: genRequest('GET_OP_REVIEWERS_OWNER'),
    REVIEWER_BY_ID: genRequest('GET_OP_REVIEWER_BY_ID'),
    REVIEWER_BY_ID_LOGS: genRequest('GET_OP_REVIEWER_BY_ID_LOGS'),
    APPROVERS_OWNER: genRequest('GET_OP_APPROVERS_OWNER'),
    APPROVER_BY_ID: genRequest('GET_OP_APPROVER_BY_ID'),
    APPROVER_BY_ID_LOGS: genRequest('GET_OP_APPROVER_BY_ID_LOGS'),
    CL_LIST: genRequest('GET_OP_CL_LIST'),
    CL_ID: genRequest('GET_OP_CL_ID'),
    CL_ID_FILES: genRequest('GET_OP_CL_ID_FILES'),
    CL_ID_DASHBOARD: genRequest('GET_OP_CL_ID_DASHBOARD'),
    CL_ID_LOGS: genRequest('GET_OP_CL_ID_LOGS'),
    CL_ID_DELIVERIES: genRequest('GET_OP_CL_ID_DELIVERIES'),
    BIZ_DOC_ID: genRequest('GET_OP_BIZ_DOC_ID'),
    DOC_SIGNER_ID_EMPLOYEES: genRequest('GET_OP_DOC_SIGNER_ID_EMPOYEES'),
    DOC_SIGNER_ME: genRequest('GET_OP_DOC_SIGNER_ME'),
    DOC_SIGNER_ID: genRequest('GET_OP_DOC_SIGNER_ID'),
    TASK_TEMP_LIST: genRequest('GET_OP_TASK_TEMP_LIST'),
    TASK_TEMP_BY_ID: genRequest('GET_OP_TASK_TEMP_BY_ID'),
    TASK_TEMP_BY_ID_LOGS: genRequest('GET_OP_TASK_TEMP_BY_ID_LOGS'),
    SOW_TPL_LIST: genRequest('GET_OP_SOW_TPL_LIST'),
    SOW_TPL_BY_ID: genRequest('GET_OP_SOW_TPL_BY_ID'),
    SOW_TPL_BY_ID_LOGS: genRequest('GET_OP_SOW_TPL_BY_ID_LOGS'),
    SOP_TPL_LIST: genRequest('GET_OP_SOP_TPL_LIST'),
    SOP_TPL_BY_ID: genRequest('GET_OP_SOP_TPL_BY_ID'),
    SOP_TPL_BY_ID_LOGS: genRequest('GET_OP_SOP_TPL_BY_ID_LOGS'),
    CL_TPL_LIST: genRequest('GET_OP_CL_TPL_LIST'),
    CL_TPL_BY_ID: genRequest('GET_OP_CL_TPL_BY_ID'),
    CL_TPL_BY_ID_LOGS: genRequest('GET_OP_CL_TPL_BY_ID_LOGS'),
  },
  POST: {
    NOTE: genRequest('POST_OP_NOTE'),
    SP: genRequest('POST_OP_SP'),
    SP_FILE: genRequest('POST_OP_SP_FILE'),
    SP_CANCEL: genRequest('POST_OP_SP_CANCEL'),
    SW_BY_ID_ASSIGN: genRequest('POST_OP_SW_BY_ID_ASSIGN'),
    SW_BY_ID_CHECK_UNASSIGN: genRequest('POST_OP_SW_BY_ID_CHECK_UNASSIGN'),
    SW_BY_ID_UNASSIGN: genRequest('POST_OP_SW_BY_ID_UNASSIGN'),
    SW_BY_ID_COMPLETE: genRequest('POST_OP_SW_BY_ID_COMPLETE'),
    SW_BY_ID_FILE: genRequest('POST_OP_SW_BY_ID_FILE'),
    TASK_BY_ID_ASSIGN: genRequest('POST_OP_TASK_BY_ID_ASSIGN'),
    TASK_BY_ID_CHECK_UNASSIGN: genRequest('POST_OP_TASK_BY_ID_CHECK_UNASSIGN'),
    TASK_BY_ID_UNASSIGN: genRequest('POST_OP_TASK_BY_ID_UNASSIGN'),
    TASK_BY_ID_COMPLETE: genRequest('POST_OP_TASK_BY_ID_COMPLETE'),
    TASK_BY_ID_FILE: genRequest('POST_OP_TASK_BY_ID_FILE'),
    REVIEW_PASS: genRequest('POST_REVIEW_PASS'),
    REVIEW_NOTPASS: genRequest('POST_REVIEW_NOTPASS'),
    APPROVAL_APPROVE: genRequest('POST_APPROVAL_APPROVE'),
    APPROVAL_REJECT: genRequest('POST_APPROVAL_REJECT'),
    CL: genRequest('POST_OP_CL'),
    CL_FILE: genRequest('POST_OP_CL_FILE'),
    CL_CANCEL: genRequest('POST_OP_CL_CANCEL'),
    DELIVERY_ID_FILE: genRequest('POST_OP_DELIVERY_ID_FILE'),
    BIZ_DOC: genRequest('POST_OP_BIZ_DOC'),
    DOC_ID_BIZ_DOC: genRequest('POST_OP_DOC_ID_BIZ_DOC'),
    DOC_ID_REQUEST: genRequest('POST_OP_DOC_ID_REQUEST'),
    DOC_ID_CANCEL: genRequest('POST_OP_DOC_ID_CANCEL'),
    DOC_SIGNER_ID_APPROVE: genRequest('POST_OP_DOC_SIGNER_ID_APPROVE'),
    DOC_SIGNER_ID_REJECT: genRequest('POST_OP_DOC_SIGNER_ID_REJECT'),
    TASK_TEMP: genRequest('POST_OP_TASK_TEMP'),
    TASK_TEMP_ID_ACTIVE: genRequest('POST_OP_TASK_TEMP_ID_ACTIVE'),
    TASK_TEMP_ID_INACTIVE: genRequest('POST_OP_TASK_TEMP_ID_INACTIVE'),
    SOW_TPL: genRequest('POST_OP_SOW_TPL'),
    SOW_TPL_ID_ACTIVE: genRequest('POST_OP_SOW_TPL_ID_ACTIVE'),
    SOW_TPL_ID_INACTIVE: genRequest('POST_OP_SOW_TPL_ID_INACTIVE'),
    SOP_TPL: genRequest('POST_OP_SOP_TPL'),
    SOP_TPL_ID_ACTIVE: genRequest('POST_OP_SOP_TPL_ID_ACTIVE'),
    SOP_TPL_ID_INACTIVE: genRequest('POST_OP_SOP_TPL_ID_INACTIVE'),
    // SOP_TPL_CSV: genRequest('POST_OP_SOP_TPL_CSV'),
    CL_TPL: genRequest('POST_OP_CL_TPL'),
    CL_TPL_ID_ACTIVE: genRequest('POST_OP_CL_TPL_ID_ACTIVE'),
    CL_TPL_ID_INACTIVE: genRequest('POST_OP_CL_TPL_ID_INACTIVE'),
    // CL_TPL_CSV: genRequest('POST_OP_CL_TPL_CSV'),
  },
  PUT: {
    SP: genRequest('PUT_OP_SP'),
    CL: genRequest('PUT_OP_CL'),
    TASK_TEMP: genRequest('PUT_OP_TASK_TEMP'),
    SOW_TPL: genRequest('PUT_OP_SOW_TPL'),
    SOP_TPL: genRequest('PUT_OP_SOP_TPL'),
    CL_TPL: genRequest('PUT_OP_CL_TPL'),
  },
  DELETE: {
    FILE: genRequest('DELETE_OP_FILE'),
    DELIVERY_ID_FILE: genRequest('DELETE_OP_DELIVERY_ID_FILE'),
    DOC_ID_BIZ_DOC: genRequest('DELETE_OP_DOC_ID_BIZ_DOC'),
    TASK_TEMP: genRequest('DELETE_OP_TASK_TEMP'),
    SOW_TPL: genRequest('DELETE_OP_SOW_TPL'),
    SOP_TPL: genRequest('DELETE_OP_SOP_TPL'),
    CL_TPL: genRequest('DELETE_OP_CL_TPL'),
  },
  CLEAR: {
    SP: 'CLEAR_OP_SP',
    SP_TEMPLATE: 'CLEAR_OP_SP_TEMPLATE',
    CL: 'CLEAR_OP_CL',
    SW: 'CLEAR_OP_SW',
    TASK: 'CLEAR_OP_TASK',
    REVIEWER: 'CLEAR_OP_REVIEWER',
    APPROVER: 'CLEAR_OP_APPROVER',
    NOTES: 'CLEAR_OP_NOTES',
    TASK_TEMP: 'CLEAR_OP_TASK_TEMP',
    SOW_TPL: 'CLEAR_OP_SOW_TPL',
    SOP_TPL: 'CLEAR_OP_SOP_TPL',
    CL_TPL: 'CLEAR_OP_CL_TPL',
  },
}

export const DOWNLOAD_REPORT = {
  GET: {
    GET_REPORTS: genRequest('GET_REPORTS'),
  },
  POST: {
    GENERATE_REPORT: genRequest('GENERATE_REPORT'),
  },
}
