import { Divider, Autocomplete as MuiAutocomplete, TextField } from '@mui/material'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { styled } from '@mui/system'
import { DesktopTimePicker as MuiDesktopTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { Controller, useFieldArray } from 'react-hook-form'

import DatePicker from 'components/form2/form-datepicker'
import { Input } from 'components/form2/form-input'
import { Option, Select } from 'components/form2/form-select'
import { Textarea } from 'components/form2/form-textarea'

import 'dayjs/locale/en-gb'
import 'dayjs/locale/th'

export const FieldLabel = ({ key, name, isRequired }) => {
  return (
    <label htmlFor={key}>
      {name || key} {isRequired && <strong>*</strong>}
    </label>
  )
}

export const DynamicFieldArray = ({ name, control, render, actions, isCanDrag = true }) => {
  const fieldArray = useFieldArray({ control, name, keyName: 'fieldId' })

  const handleDragEnd = (result) => {
    if (!result.destination) return

    fieldArray.move(result.source.index, result.destination.index)
  }

  return (
    <div>
      {isCanDrag ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId={`droppable-${name}`}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fieldArray.fields.map((field, index) => {
                  return render({ field, index, ...fieldArray })
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        fieldArray.fields.map((field, index) => {
          return render({ field, index, ...fieldArray })
        })
      )}
      {actions && actions(fieldArray)}
    </div>
  )
}

export const renderFields = (methods, schema, readonly = false) => {
  const { properties, required } = schema
  const fields = []

  const handleAutoCompleteChange = (key) => {
    return (e, selectVal) => {
      const tagsArr = selectVal?.map((tag) => ({ id: tag.id || null, name: tag.name || tag })) || []
      const mergedTags = tagsArr.reduce((acc, tag) => {
        const found = acc.find((t) => t.name === tag.name)

        if (!found) {
          acc.push(tag)
        }

        return acc
      }, [])

      methods.setValue(key, mergedTags || selectVal)
      return mergedTags
    }
  }

  for (const key in properties) {
    const field = properties[key]
    const isRequired = required && Array.isArray(required) && required?.includes(key)

    if (readonly) {
      const fieldValue = methods.getValues(key)

      switch (field.type) {
        case 'divider':
          fields.push(<Divider sx={{ borderColor: 'var(--Gray-200)' }} />)
          break

        case 'fieldArray':
          fields.push(
            <section key={key}>
              {field.label && <FieldLabel key={key} name={field.label} isRequired={isRequired} />}
              <DynamicFieldArray name={key} control={methods.control} {...field?.props} />
            </section>
          )
          break

        case undefined:
          fields.push(field?.component)
          break

        default:
          fields.push(
            <section key={key}>
              <FieldLabel key={key} name={field.label} isRequired={isRequired} />
              <div className="form-field">
                {typeof fieldValue === 'object' ? field?.viewValue?.(fieldValue) : fieldValue}
              </div>
            </section>
          )
      }
    } else {
      switch (field.type) {
        case 'controlled-text':
        case 'text':
          fields.push(
            <section key={key}>
              <FieldLabel key={key} name={field.label} isRequired={isRequired} />
              <div className="form-field">
                <Input
                  {...methods.register(key, {
                    required: isRequired,
                    message: field?.validator?.message,
                    minLength: field.validator?.minLength,
                    maxLength: field.validator?.maxLength,
                  })}
                  placeholder={field.placeholder}
                  onChange={(e) => {
                    if (field.onchange) field.onchange(e)

                    const value = e.target.value
                    methods.setValue(key, value)
                  }}
                  {...field?.props}
                />
              </div>
            </section>
          )
          break

        case 'controlled-textArea':
        case 'textArea':
          fields.push(
            <section key={key}>
              {field.label && <FieldLabel key={key} name={field.label} isRequired={isRequired} />}
              <Textarea
                {...methods.register(key, {
                  required: isRequired,
                  minLength: field.validator?.minLength,
                  maxLength: field.validator?.maxLength,
                })}
                error={methods.formState.errors[key]}
                minRows={field?.props?.minRows || 4}
                maxRows={field?.props?.maxRows || 4}
                placeholder={field?.placeholder || 'Type something...'}
                {...field?.props}
              />
            </section>
          )
          break

        case 'controlled-select':
          fields.push(
            <section key={key}>
              <FieldLabel key={key} name={field.label} isRequired={isRequired} />
              <Controller
                name={key}
                control={methods.control}
                render={({ field: { onChange, value, ref } }) => {
                  return (
                    <Select
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      renderValue={(option) => {
                        if (!option || option === null) {
                          return <div style={{ color: 'var(--Gray-300)' }}>{field?.placeholder}</div>
                        }
                        return option.label || option.name
                      }}
                      {...field?.props}
                    >
                      {field?.options?.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label || option.name}
                        </Option>
                      ))}
                    </Select>
                  )
                }}
              />
            </section>
          )
          break
        case 'select':
          fields.push(
            <section key={key}>
              <FieldLabel key={key} name={field.label} isRequired={isRequired} />
              <Select
                {...methods.register(key, { required: isRequired })}
                onChange={(e, selectVal) => {
                  if (field?.onchange) {
                    field.onchange(e, selectVal)
                  }

                  const value = e.target.value
                  methods.setValue(key, value || selectVal)
                }}
                renderValue={(option) => {
                  if (!option || option === null) {
                    return <div style={{ color: 'var(--Gray-300)' }}>{field?.placeholder}</div>
                  }
                  return option.label
                }}
                {...field?.props}
              >
                {field.options.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </section>
          )
          break

        case 'controlled-autocomplete':
          {
            fields.push(
              <section key={key}>
                <FieldLabel key={key} name={field.label} isRequired={isRequired} />
                <Controller
                  name={key}
                  control={methods.control}
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <Autocomplete
                        onChange={handleAutoCompleteChange(key)} // Add if multiple select is needed onChange={handleAutoCompleteChange(key)}
                        value={value || methods?.watch(key) || []}
                        options={field?.props?.options || []}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} placeholder={field?.placeholder} />}
                        {...field?.props}
                      />
                    )
                  }}
                />
              </section>
            )
          }
          break
        case 'autocomplete':
          {
            fields.push(
              <section key={key}>
                <FieldLabel key={key} name={field.label} isRequired={isRequired} />
                <Autocomplete
                  {...methods.register(key)}
                  options={field?.props?.options || []}
                  onChange={handleAutoCompleteChange(key)}
                  renderInput={(params) => {
                    return <TextField {...params} placeholder={field?.placeholder} />
                  }}
                  {...field?.props}
                />
              </section>
            )
          }
          break

        case 'controlled-date':
        case 'date':
          {
            const { name, onChange, onBlur, ref } = methods.register(key, { required: isRequired })
            fields.push(
              <section key={key}>
                <FieldLabel key={key} name={field.label} isRequired={isRequired} />
                <DatePicker
                  key={key}
                  // onChange={onChange} // ERROR: undefined 'name'
                  onChange={(date) => {
                    if (field?.onchange) {
                      field.onchange(date)
                    } else {
                      methods.setValue(key, date)
                    }
                  }}
                  value={dayjs(methods?.getValues(key))}
                  {...field?.props}
                  slots={{ name, onChange, onBlur, ref }}
                />
              </section>
            )
          }
          break

        case 'controlled-time':
        case 'time':
          {
            const { name, onChange, onBlur, ref } = methods.register(key, { required: isRequired })
            fields.push(
              <section>
                <FieldLabel key={key} name={field.label} isRequired={isRequired} />
                <DesktopTimePicker
                  {...field?.props}
                  key={key}
                  value={dayjs(methods?.getValues(key))}
                  onChange={(time) => {
                    if (field?.onchange) {
                      field.onchange(time)
                      return
                    }

                    methods.setValue(key, time)
                  }}
                  slots={{ name, onChange, onBlur, ref }}
                />
              </section>
            )
          }
          break

        case 'fieldArray':
          fields.push(
            <section key={key}>
              {field.label && <FieldLabel key={key} name={field.label} isRequired={isRequired} />}
              <DynamicFieldArray name={key} control={methods.control} {...field?.props} />
            </section>
          )
          break

        case 'divider':
          fields.push(<Divider sx={{ borderColor: 'var(--Gray-200)' }} />)
          break

        case 'checkbox':
        case 'radio':
        case 'dateTime':
        case 'file':
        case 'image':
        case 'number':
        default:
          fields.push(field?.component)
          break
      }
    }
  }

  return fields
}

export const Autocomplete = styled(MuiAutocomplete)`
  border-color: var(--Gray-200);

  & .MuiAutocomplete-inputRoot {
    font-family: 'Inter', 'Noto Sans Thai';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
  }

  & .MuiOutlinedInput-root {
    border-radius: 0.5rem;
    transition: all 100ms ease-in-out;
    box-shadow: 0px 2px 2px var(--Gray-50);
    padding: 2px 8px;

    & fieldset {
      border-color: var(--Gray-200);
    }

    &.Mui-focused {
      border-color: var(--Primary-300);
      box-shadow: 0 0 0 3px var(--Primary-100);

      & .MuiOutlinedInput-notchedOutline {
        border-color: var(--Primary-300);
      }
    }

    &.Mui-disabled {
      color: var(--Gray-400);
      background: var(--Gray-50);
      border-color: var(--Gray-200);

      & .MuiOutlinedInput-notchedOutline {
        border-color: var(--Gray-200);
      }
    }

    &:not(.Mui-disabled):hover fieldset {
      border-color: var(--Primary-300);
    }

    & input::placeholder {
      color: var(--Gray-400);
      opacity: 1;
      font-weight: 400;
      font-family: 'Inter', 'Noto Sans Thai';
      font-size: 0.875rem;
      line-height: 1.5;
    }

    &.Mui-error {
      border-color: var(--Error-300);

      & .MuiOutlinedInput-notchedOutline {
        border-color: var(--Error-300);
      }
    }
  }
`

const DesktopTimePicker = styled(MuiDesktopTimePicker)`
  & .MuiInputBase-root {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0;
    color: var(--Gray-900);
    background: var(--Base-White);
    border: none;
    box-shadow: 0px 2px 2px var(--Gray-50);

    &:disabled {
      background: var(--Gray-50);
      color: var(--Gray-400);
    }

    &:hover:not(:disabled) {
      border-color: var(--Primary-300);
    }

    &:focus {
      border-color: var(--Primary-300);
      box-shadow: 0 0 0 3px var(--Primary-100) !important;
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }

    // state error input
    &.error {
      border-color: red;
    }

    & .MuiInputBase-root {
      font-family: 'Inter', 'Noto Sans Thai';
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
    }

    & .MuiOutlinedInput-input {
      padding: 8px 12px;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      border-radius: 8px;
      color: var(--Gray-900);
      background: var(--Base-White);
      border: 1px solid var(--Gray-200);
      box-shadow: 0px 2px 2px var(--Gray-50);

      &:disabled {
        background: var(--Gray-50);
        color: var(--Gray-400);
      }

      &:hover:not(:disabled) {
        border-color: var(--Primary-300);
      }

      &:focus {
        border-color: var(--Primary-300);
        box-shadow: 0 0 0 3px var(--Primary-100) !important;
      }

      // firefox
      &:focus-visible {
        outline: 0;
      }

      // state error input
      &.error {
        border-color: red;
      }
    }

    & .MuiOutlinedInput-notchedOutline {
      border: none;
    }
  }
`
