import { styled } from '@mui/system'

import Main from './main'
import MyTask from './my-task'
import TaskBoard from './task-board'
import DepartmentOperation from './department-operation'
import FileSectionTabs from './file-section'
import ManagementTabs from './management'
import ViewTaskTplTabs from './view-task-tpl'
import ViewSowTplTabs from './view-sow-tpl'

const Tabs = ({ children, tabType = 'default', ...props }) => {
  return (
    <TabsWrapper tabType={tabType} {...props}>
      {children}
    </TabsWrapper>
  )
}

Tabs.Main = Main
Tabs.MyTask = MyTask
Tabs.TaskBoard = TaskBoard
Tabs.DepartmentOperation = DepartmentOperation
Tabs.FileSection = FileSectionTabs
Tabs.Management = ManagementTabs
Tabs.ViewTaskTpl = ViewTaskTplTabs
Tabs.ViewSowTpl = ViewSowTplTabs

export default Tabs

export const TabsWrapper = styled('div')`
  ${(props) =>
    props.tabType === 'modern' &&
    `
    display: flex;
    padding: var(--spacing-xs, 0.25rem);
    align-items: center;
    gap: var(--spacing-xs, 0.25rem);
    align-self: ${(props) => props.alignSelf || 'center'};
    min-width: fit-content;

    border-radius: 0.625rem;
    border: 1px solid var(--Gray-200);
    background: var(--Gray-50);
    transition: all 100ms ease-out;

    .tab-item {
      display: flex;
      height: 2.25rem;
      padding: 0.5rem 0.75rem;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      min-width: fit-content;

      border-radius: 0.375rem;

      color: var(--Gray-700);
      /* Text sm/Semibold */
      font-family: 'Inter', 'Noto Sans Thai';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem; /* 142.857% */

      cursor: pointer;

      &.active {
        color: var(--Primary-700);
        background: var(--Base-White);
        /* Shadows/shadow-sm */
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
      }

      &:not(.active) {
        &:hover {
          background: var(--Gray-100);
        }
      }
    }
  `}

  ${(props) =>
    props.tabType === 'default' &&
    `
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    margin-bottom: 1rem;

    transition: all 100ms ease-out;
    border-bottom: 1px solid var(--Gray-200, #eaecf0);

    .tab-item {
      display: flex;
      height: 44px;
      padding: 12px;
      justify-content: center;
      align-items: center;
      gap: 8px;

      color: var(--Gray-700, #344054);
      /* Text sm/Semibold */
      font-family: 'Inter', 'Noto Sans Thai';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.25rem; /* 142.857% */

      cursor: pointer;
      transition: all 100ms ease-out;

      &.active {
        background: var(--Primary-50);
        border-bottom: 2px solid var(--Primary-600);
        color: var(--Primary-700);
      }

      &:not(.active) {
        &:hover {
          background: var(--Primary-50);
        }
      }
    }
  `}
`
