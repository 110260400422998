import FileUpload from '../../components/form/FileUpload'
import { postAPI } from '../../utils/api'
import { BASE_API } from '../../configs/app'
import InputFileUpload from '../../components/form/InputFileUpload'

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
    'application/vnd.ms-powerpoint': ['.ppt','.pptx'],
    'text/plain': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
  appleFiles: {
    'application/vnd.apple.pages': ['.pages'],
    'application/vnd.apple.keynote': ['.key'],
    'application/vnd.apple.numbers': ['.numbers'],
    'application/x-iwork-pages-sffpages': ['.pages'],
    'application/x-iwork-keynote-sffkey': ['.key'],
    'application/x-iwork-numbers-sffnumbers': ['.numbers'],
  }
}

const MediaFile = ({
  multiple = false,
  allowedTypes = [],
  file = [],
  onChange,
  error = false,
  disabled = false,
  inputForm = 'file_upload',
  ...props
}) => {
  const acceptType = allowedTypes.reduce((pre, cur) => {
    return {
      ...pre,
      ...acceptList[cur],
    }
  }, {})

  const handleAddFile = async (value) => {
    const fetchList = []
    value.forEach((v) => {
      const formData = new FormData()
      formData.append('files', v)
      formData.append(
        'fileInfo',
        JSON.stringify({
          name: v.name,
          caption: v.name,
          alternativeText: v.name,
          folder: null,
        })
      )

      fetchList.push(
        postAPI({
          url: '/api/upload',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: null,
          },
          data: formData,
        })
      )
    })

    const res = await Promise.all(fetchList)

    const result = multiple ? [...file, ...res.map((v) => v[0])] : res[0]
    onChange(result)
  }

  const handleDeleteFile = (id) => {
    onChange(file.filter((v) => v.id !== id))
  }

  return (
    <>
      {inputForm == 'file_upload' ? (
        <FileUpload
          multiple={multiple}
          accept={acceptType}
          // file={file.map((v) => ({ ...v, url: `${BASE_API}${v.url}` }))} old
          file={file.map((v) => ({ ...v, url: `${v.url}` }))}
          onAddFile={handleAddFile}
          onDeleteFile={handleDeleteFile}
          error={error}
          disabled={disabled}
        />
      ) : (
        <InputFileUpload
          inputForm={inputForm}
          multiple={multiple}
          accept={acceptType}
          file={file.map((v) => ({ ...v, url: `${v.url}` }))}
          onAddFile={handleAddFile}
          onDeleteFile={handleDeleteFile}
          error={error}
          disabled={disabled}
          {...props}
        />
      )}
    </>
  )
}

export default MediaFile
