import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import PersonalInformation from '../../components/feature/employee-profile/PersonalInformation'
import EmploymentPosition from '../../components/feature/employee-profile/EmploymentPosition'
import ProfessionalBackground from '../../components/feature/employee-profile/ProfessionalBackground'

import { FiUser } from 'react-icons/fi'
import { LuPen, LuClock4 } from 'react-icons/lu'
import { FaCheck, FaTimes } from 'react-icons/fa'
import { GoFileDirectory } from 'react-icons/go'
import { PiCertificateLight } from 'react-icons/pi'
import { BiLockOpenAlt } from 'react-icons/bi'

import ButtonOutline from '../../components/form/button/ButtonOutline'
import Table from '../../components/common/Table'

import { getEmployeeProfileInfo, getEditRequestLogsByEmployee } from '../../redux/actions/employeeProfile'
import { selectEmployeeProfile } from '../../redux/selectors'
import { DateTimeHHmmssDisplay } from '../../utils/common'

// style
const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .mr-4-px {
    margin-right: 4px;
  }
  .mr-2-rem {
    margin-right: 2rem;
  }
  .mr-8-px {
    margin-right: 8px;
  }

  .heading {
    color: var(--Gray-900);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .sub-heading {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .vertical-middle {
    vertical-align: middle;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.align-items-center {
    align-items: center;
  }
  .side-menu-container {
    width: 22%;
  }
  .flex-item-1 {
    flex: 1%;
  }
`
const HeaderContainer = styled.div`
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
  margin-bottom: 2em;
`
const EmployeeInfoAppearanceContainer = styled.div`
  margin-bottom: 2rem;
  .employee-image-container {
    position: relative;
    margin-right: 24px;

    img {
      width: 180px;
      height: 180px;
      border-radius: 50%;
    }
  }
  .employee-name {
    color: var(--Gray-900);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  .employee-dept-pos {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .edit-btn {
    position: absolute;
    bottom: 18px;
    right: -8px;
    padding: 0 10px;
  }
`
const SideMenu = styled.div`
  color: var(--Gray-500);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding: 0.5rem;
  cursor: pointer;

  &.active {
    border-radius: 6px;
    background: var(--Gray-50);

    color: var(--Gray-700);
  }
`
const StatusBadge = styled.div`
  display: inline-block;

  border-radius: 16px;
  border: 1px solid var(--Gray-200);
  background: var(--Gray-50);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  padding: 0 0.375rem;

  color: var(--Gray-700);
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;

  .status-item {
    vertical-align: middle;
  }
  &.approve {
    color: var(--BADGE-4-Font);
    border: 1px solid var(--BADGE-4-Border);
    background: var(--BADGE-4-Bg);
  }
  &.denied {
    color: var(--BADGE-2-Font);
    border: 1px solid var(--BADGE-2-Border);
    background: var(--BADGE-2-Bg);
  }
`

function EmployeeProfile() {
  // column
  const requestEditLogColumn = [
    {
      Header: 'Submission Date',
      accessor: 'submission_date',
      disableSortBy: false,
    },
    {
      Header: 'Detail Changed',
      accessor: 'detail_changed',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: 'Remark',
      accessor: 'remark',
      disableSortBy: false,
    },
  ]
  const dispatch = useDispatch()
  // store reducer
  const { employeeProfileInfo, requestEditLogsByEmployee, page, pageCount, state, isLoading } =
    useSelector(selectEmployeeProfile)
  // state
  const [tab, setTab] = useState('personal-information')
  const [requestEditLog, setRequestEditLog] = useState([])

  const fetchEditRequestLogs = useCallback((filter) => {
    dispatch(getEditRequestLogsByEmployee(filter))
  }, [])

  // useEffect for intial fetching employee profile info
  useEffect(() => {
    dispatch(getEmployeeProfileInfo())
    const filter = {
      page: 1,
      pageSize: 5,
    }
    fetchEditRequestLogs(filter)
  }, [])

  // useEffect for catching up state
  useEffect(() => {
    if (isLoading === false && state === 'REQUEST_EDIT_EMPLOYEE_PROFILE_INFO.SUCCESS') {
      setTimeout(() => {
        const filter = {
          page: 1,
          pageSize: 5,
        }
        fetchEditRequestLogs(filter)
      }, 1000)
    }
  }, [isLoading, state])

  // useEffect for mapping table data
  useEffect(() => {
    const newRequestEditLog = requestEditLogsByEmployee.map((v) => ({
      ...v,
      submission_date: DateTimeHHmmssDisplay(v?.createdAt),
      detail_changed: v?.field_name,
      status: (
        <StatusBadge className={v.status === 'approve' ? 'approve' : v.status === 'deny' ? 'denied' : ''}>
          {v.status === 'approve' ? (
            <FaCheck className="status-item mr-4-px" />
          ) : v.status === 'deny' ? (
            <FaTimes className="status-item mr-4-px" />
          ) : (
            <LuClock4 className="status-item mr-4-px" />
          )}
          <span className="status-item">{v.status}</span>
        </StatusBadge>
      ),
    }))
    setRequestEditLog(newRequestEditLog)
  }, [requestEditLogsByEmployee])

  const toggleTab = (tab) => {
    setTab(tab)
  }

  const onChangePagination = (value) => {
    const filter = {
      page: value.page,
      pageSize: 5,
    }
    fetchEditRequestLogs(filter)
  }

  return (
    <Div>
      <HeaderContainer className="mb-1-rem">
        <div className="content-title">Employee Profile</div>
        <div className="">Manage your profile and personal information</div>
      </HeaderContainer>

      <Divider />

      <EmployeeInfoAppearanceContainer>
        <FlexContainer className="align-items-center">
          <div className="employee-image-container">
            <img src={employeeProfileInfo?.photo} />
            <ButtonOutline className="edit-btn">
              <LuPen size={18} />
            </ButtonOutline>
          </div>
          <div>
            <div className="employee-name">
              {employeeProfileInfo?.first_name} {employeeProfileInfo?.last_name}
            </div>
            <div className="employee-dept-pos">
              {employeeProfileInfo?.department?.name}, {employeeProfileInfo?.position?.name}
            </div>
          </div>
        </FlexContainer>
      </EmployeeInfoAppearanceContainer>

      <FlexContainer className="mb-2-rem">
        <div className="side-menu-container mr-2-rem">
          <SideMenu
            className={tab === 'personal-information' ? 'active' : ''}
            onClick={() => toggleTab('personal-information')}
          >
            <FiUser size={20} className="mr-8-px vertical-middle" />
            <span className="vertical-middle">Personal information</span>
          </SideMenu>
          <SideMenu
            className={tab === 'employment-position' ? 'active' : ''}
            onClick={() => toggleTab('employment-position')}
          >
            <GoFileDirectory size={20} className="mr-8-px vertical-middle" />
            <span className="vertical-middle">Employment Position</span>
          </SideMenu>
          <SideMenu
            className={tab === 'professional-background' ? 'active' : ''}
            onClick={() => toggleTab('professional-background')}
          >
            <PiCertificateLight size={20} className="mr-8-px vertical-middle" />
            <span className="vertical-middle">Professional Background</span>
          </SideMenu>
        </div>

        <div className="flex-item-1">
          {tab === 'personal-information' ? (
            <PersonalInformation />
          ) : tab === 'employment-position' ? (
            <EmploymentPosition />
          ) : tab === 'professional-background' ? (
            <ProfessionalBackground />
          ) : (
            tab
          )}
        </div>
      </FlexContainer>

      <Divider />

      <FlexContainer>
        <div className="side-menu-container mr-2-rem">
          <div className="heading">Request Edit Log</div>
          <div className="sub-heading">Track and review your submitted changes over time</div>
        </div>

        <div className="flex-item-1">
          <Table
            data={requestEditLog}
            columns={requestEditLogColumn}
            page={page}
            pageCount={pageCount}
            onStateChange={onChangePagination}
          />
        </div>
      </FlexContainer>
    </Div>
  )
}

export default EmployeeProfile
