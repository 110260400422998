export default function IconFileWord({ width = '45', color = '#333', style }) {
  return (
    <svg width={width} viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <rect x="8" width="24" height="28" rx="2" fill="url(#paint0_linear_1334_1203)" />
      <path
        d="M8 21H32V26C32 27.1046 31.1046 28 30 28H10C8.89543 28 8 27.1046 8 26V21Z"
        fill="url(#paint1_linear_1334_1203)"
      />
      <rect x="8" y="14" width="24" height="7" fill="url(#paint2_linear_1334_1203)" />
      <rect x="8" y="7" width="24" height="7" fill="url(#paint3_linear_1334_1203)" />
      <path
        d="M8 10C8 8.34315 9.34315 7 11 7H17C18.6569 7 20 8.34315 20 10V22C20 23.6569 18.6569 25 17 25H8V10Z"
        fill="black"
        fillOpacity="0.3"
      />
      <rect y="5" width="18" height="18" rx="2" fill="url(#paint4_linear_1334_1203)" />
      <path
        d="M15 9.01418H13.0523L11.5229 15.539L9.84967 9H8.20261L6.51634 15.539L5 9.01418H3L5.60131 19H7.3268L9 12.6879L10.6732 19H12.3987L15 9.01418Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1334_1203"
          x1="8"
          y1="4.66667"
          x2="32"
          y2="4.66667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2B78B1" />
          <stop offset="1" stopColor="#338ACD" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1334_1203"
          x1="8"
          y1="25.375"
          x2="32"
          y2="25.375"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B366F" />
          <stop offset="1" stopColor="#2657B0" />
        </linearGradient>
        <linearGradient id="paint2_linear_1334_1203" x1="18.5" y1="18" x2="32" y2="18" gradientUnits="userSpaceOnUse">
          <stop stopColor="#20478B" />
          <stop offset="1" stopColor="#2D6FD1" />
        </linearGradient>
        <linearGradient id="paint3_linear_1334_1203" x1="18.5" y1="11" x2="32" y2="11" gradientUnits="userSpaceOnUse">
          <stop stopColor="#215295" />
          <stop offset="1" stopColor="#2E84D3" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_1334_1203"
          x1="3.31137e-08"
          y1="15"
          x2="19"
          y2="15"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#223E74" />
          <stop offset="1" stopColor="#215091" />
        </linearGradient>
      </defs>
    </svg>
  )
}
