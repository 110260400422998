import { useSelector } from 'react-redux'
import { FormProvider, Controller, useForm, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import Field from '../../components/form/Field'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import Checkbox from '../../components/form/Checkbox'
import DateTime from '../../components/form/DateTime'
import Date from '../../components/form/Date'
import Time from '../../components/form/Time'
import MediaFile from './MediaFile'
import RelationDropdown from './RelationDropdown'
import { validateEmail, validateInteger, validateNumber, checkUnique } from '../../utils/validation'
import { FIELD_TYPE } from '../../utils/constants'
import { selectCmsContentType, selectCmsByType } from '../../redux/selectors'

const Div = styled.div`
  max-width: 1000px;

  .row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    button {
      margin-left: 12px;
    }
  }
`

const InputField = ({ uid, name, attribute, metadata }) => {
  const {
    control,
    formState: { dirtyFields, errors },
  } = useFormContext()

  const {
    type,
    relationType,
    minLength,
    maxLength,
    multiple,
    allowedTypes,
    required,
    default: defaultValue = '',
    enum: enumList,
  } = attribute || {}
  const { label, editable, mainField } = metadata || {}

  const errorMessage = errors[name]?.message

  const toArray = (file) => {
    if (Array.isArray(file)) {
      return file
    } else {
      return file ? [file] : []
    }
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        validate: {
          required: (v) => !required || Boolean(v) || 'Require data',
          minLength: (v) => !minLength || v.length >= minLength || `Min length is ${minLength}`,
          maxLength: (v) => !maxLength || v.length <= maxLength || `Max length is ${maxLength}`,
          email: (v) => type !== FIELD_TYPE.email || validateEmail(v) || 'Invalid email',
          unique: async (v) =>
            !v ||
            type !== FIELD_TYPE.uid ||
            !dirtyFields[name] ||
            (await checkUnique(uid, name, v)) ||
            'Data must be unique',
        },
      }}
      render={({ field: { onChange, value } }) => (
        <Field label={label} errorMessage={errorMessage}>
          {(type === FIELD_TYPE.string ||
            type === FIELD_TYPE.text ||
            type === FIELD_TYPE.email ||
            type === FIELD_TYPE.uid) && (
            <TextField
              value={value}
              onChange={(e) => onChange(e.target.value)}
              error={Boolean(errorMessage)}
              disabled={!editable}
            />
          )}
          {(type === FIELD_TYPE.integer || type === FIELD_TYPE.biginteger) && (
            <TextField
              value={value}
              onChange={({ target: { value } }) => (value === '' || validateInteger(value)) && onChange(value)}
              error={Boolean(errorMessage)}
              disabled={!editable}
            />
          )}
          {type === FIELD_TYPE.decimal && (
            <TextField
              value={value}
              onChange={({ target: { value } }) => (value === '' || validateNumber(value)) && onChange(value)}
              error={Boolean(errorMessage)}
              disabled={!editable}
            />
          )}
          {type === FIELD_TYPE.boolean && <Checkbox checked={value} onChange={onChange} disabled={!editable} />}
          {type === FIELD_TYPE.enumeration && (
            <Dropdown
              value={value}
              optionList={enumList.map((v) => ({ text: v, value: v }))}
              onChange={onChange}
              error={Boolean(errorMessage)}
              disabled={!editable}
            />
          )}
          {type === FIELD_TYPE.relation && (
            <RelationDropdown
              multiple={relationType.includes('ToMany')}
              uid={uid}
              name={name}
              mainField={mainField}
              value={value}
              onChange={onChange}
              error={Boolean(errorMessage)}
              disabled={!editable}
            />
          )}
          {type === FIELD_TYPE.media && (
            <MediaFile
              multiple={multiple}
              allowedTypes={allowedTypes}
              file={toArray(value)}
              onChange={onChange}
              error={Boolean(errorMessage)}
              disabled={!editable}
            />
          )}
          {type === FIELD_TYPE.datetime && (
            <DateTime value={value} onChange={onChange} error={Boolean(errorMessage)} disabled={!editable} />
          )}
          {type === FIELD_TYPE.date && (
            <Date value={value} onChange={onChange} error={Boolean(errorMessage)} disabled={!editable} />
          )}
          {type === FIELD_TYPE.time && (
            <Time value={value} onChange={onChange} error={Boolean(errorMessage)} disabled={!editable} />
          )}
        </Field>
      )}
    />
  )
}

const ContentForm = ({ type, defaultValues = {}, submitText, onSubmit, onCancel }) => {
  const { attributes } = useSelector(selectCmsContentType(type))
  const { config } = useSelector(selectCmsByType(type))
  const formMethods = useForm({
    mode: 'onSubmit',
    shouldUnregister: true,
    defaultValues,
  })

  const { contentType } = config || {}
  const { uid, metadatas = {} } = contentType || {}
  const { edit: editLayouts = [] } = contentType?.layouts || {}

  const handleSubmit = (data) => {
    const payload = Object.keys(data).reduce((pre, k) => {
      let value = data[k]

      if (attributes[k]?.type === FIELD_TYPE.relation) {
        if (typeof data[k] !== 'object') {
          // For non mutiple data
          value = value ? [{ value: value }] : []
        }

        value = {
          connect: value.reverse().map((v, i) => {
            const isEnd = i === 0
            const itemBefore = value[i - 1]
            const position = isEnd ? { end: true } : { before: itemBefore.value }

            return {
              id: v.value,
              position,
            }
          }),
          disconnect: [],
        }
      } else if (attributes[k]?.type === FIELD_TYPE.boolean) {
        return { ...pre, [k]: value }
      }

      return { ...pre, [k]: value || undefined }
    }, {})

    onSubmit(payload)
  }

  return (
    <Div>
      <FormProvider {...formMethods}>
        {editLayouts.map((row, i) => (
          <div className="row" key={i}>
            {row.map((field) => {
              return (
                <InputField
                  key={field.name}
                  uid={uid}
                  name={field.name}
                  attribute={attributes[field.name]}
                  metadata={metadatas[field.name]?.edit}
                />
              )
            })}
          </div>
        ))}
        <div className="button-wrapper">
          <ButtonOutline onClick={onCancel}>Cancel</ButtonOutline>
          <Button onClick={formMethods.handleSubmit(handleSubmit)}>{submitText}</Button>
        </div>
      </FormProvider>
    </Div>
  )
}

export default ContentForm
