import React, { forwardRef, useRef, useImperativeHandle } from 'react'
import InputUnstyled from '@mui/base/Input'
import styled from 'styled-components'

const StyledTextField = styled(InputUnstyled)`
  display: inline-flex;
  background: var(--Base-White);
  border: 1px solid var(--Gray-300);
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  height: 40px;

  input {
    border-radius: 8px;
    padding: 10px;
    width: 100%;
    min-width: 0;
    border: none;
    /*font-family: 'Inter';*/
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: var(--Gray-500);
  }

  .prefix {
    margin-right: 10px;
  }

  .postfix {
    /*margin-left: 10px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
  }

  &.Mui-error {
    border-color: red;
  }
`

const TextFieldRef = forwardRef(
  ({ startAdornment, endAdornment, disabled = false, startAdornmentClassName, ...props }, ref) => {
    const inputRef = useRef(null)

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) {
          inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
        }
      },
    }))
    return (
      <StyledTextField
        ref={inputRef}
        disabled={disabled}
        startAdornment={
          startAdornment && (
            <div className={startAdornmentClassName ? startAdornmentClassName : 'prefix'}>{startAdornment}</div>
          )
        }
        endAdornment={endAdornment && <div className="postfix">{endAdornment}</div>}
        {...props}
      />
    )
  }
)

export default TextFieldRef
