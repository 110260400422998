import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { getAPI } from '../../utils/api'
import AutoComplete from '../../components/form/AutoComplete'
import IconButton from '../../components/common/IconButton'

import iconBin from '../../assets/images/icon-bin.svg'

const Div = styled.div`
  .item-list {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 12px;
      padding: 8px 18px;
      font-size: 14px;
      background: var(--Base-White);
      border: 1px solid var(--Gray-300);
      box-shadow: var(--Shadow-xs);
      border-radius: 8px;
    }
  }
`

const arrayMemo = []

const RelationDropdown = ({ uid, name, mainField = 'id', value, onChange, multiple, ...props }) => {
  const [list, setList] = useState([])
  const [inputValue, setInputValue] = useState('')

  const fetchRelations = useCallback(
    (q) => {
      getAPI({
        url: `/content-manager/relations/${uid}/${name}`,
        params: {
          pageSize: 10,
          page: 1,
          _q: q,
          idsToOmit: multiple ? (value || []).map((v) => v.value) : [],
        },
      }).then((res) => {
        const newList = res?.results.map((v) => ({ text: `${v[mainField]}`, value: v.id }))
        setList(newList)
      })
    },
    [mainField, multiple, name, uid, value]
  )

  const handleInputChange = (v) => {
    setInputValue(v)
  }

  const handleValueChange = (v) => {
    onChange([...value, ...v])
  }

  const handleRemoveItem = (key) => {
    onChange(value.filter((v) => v.value !== key))
  }

  useEffect(() => {
    fetchRelations(inputValue)
  }, [fetchRelations, inputValue])

  return (
    <Div>
      <AutoComplete
        multiple={multiple}
        filterOptions={(x) => x}
        optionList={list}
        onInputChange={(e, v) => handleInputChange(v)}
        onChange={multiple ? (e, v) => handleValueChange(v) : onChange}
        value={multiple ? arrayMemo : value}
        {...props}
      />
      {multiple && (
        <ul className="item-list">
          {(value || []).map((v) => (
            <li key={v.value}>
              <span>{v.text}</span>
              <IconButton src={iconBin} onClick={() => handleRemoveItem(v.value)} />
            </li>
          ))}
        </ul>
      )}
    </Div>
  )
}

export default RelationDropdown
