import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_EMPLOYEE_DASHBOARD,
  GET_EMPLOYEE_DASHBOARD_BY_ID,
  GET_SUMMARY_OF_EMPLOYEE_DASHBOARD,
  GET_TASKS_OF_EMPLOYEE_DASHBOARD,
  GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD,
  GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getEmployeeDashboards =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_EMPLOYEE_DASHBOARD.REQUEST })

      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'filters[company][id][$eq]': company_info?.id,
        'populate[department]': true,
        'populate[position]': true,
      }
      if (filter.department !== '') params['filters[department][id]'] = filter.department
      if (filter.position !== '') params['filters[position][id]'] = filter.position
      if (filter.status !== '') params['filters[status][$eq]'] = filter.status

      const { data, meta } = await getAPI({
        url: '/api/employee-dashboards',
        headers,
        params,
      })

      return dispatch({
        type: GET_EMPLOYEE_DASHBOARD.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_EMPLOYEE_DASHBOARD.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Employee Dashboard list failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getEmployeeDashboardById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_EMPLOYEE_DASHBOARD_BY_ID.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-type': 'application/json',
    }

    const params = {
      'fields[0]': 'first_name',
      'fields[1]': 'last_name',
      'populate[department][fields][0]': 'name',
      'populate[position][fields][0]': 'name',
    }

    const { data } = await getAPI({
      url: `/api/employee-dashboards/${id}`,
      headers,
      params,
    })

    return dispatch({
      type: GET_EMPLOYEE_DASHBOARD_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_EMPLOYEE_DASHBOARD_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Employee Dashboard by id failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getSummary = (filter) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SUMMARY_OF_EMPLOYEE_DASHBOARD.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-type': 'application/json',
    }
    const { id, start_date, end_date } = filter
    const params =
      start_date && start_date
        ? {
            start_date,
            end_date,
          }
        : {}

    const { data } = await getAPI({
      url: `/api/employee-dashboards/${id}/summary`,
      headers,
      params,
    })

    return dispatch({
      type: GET_SUMMARY_OF_EMPLOYEE_DASHBOARD.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_SUMMARY_OF_EMPLOYEE_DASHBOARD.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Summary for employee dashboard failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getTask = (filter) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TASKS_OF_EMPLOYEE_DASHBOARD.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-type': 'application/json',
    }
    const { id, page, pageSize, status, start_date, end_date } = filter
    const params = {
      'pagination[page]': page,
      'pagination[pageSize]': pageSize,
    }
    if (status !== '') params['status'] = status
    if (start_date !== '') params['start_date'] = start_date
    if (end_date !== '') params['end_date'] = end_date

    const { data } = await getAPI({
      url: `/api/employee-dashboards/${id}/tasks`,
      headers,
      params,
    })

    return dispatch({
      type: GET_TASKS_OF_EMPLOYEE_DASHBOARD.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_TASKS_OF_EMPLOYEE_DASHBOARD.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Task for employee dashboard failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getCurrentProject = (filter) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-type': 'application/json',
    }
    const { id, page, pageSize } = filter
    const params = {
      'pagination[page]': page,
      'pagination[pageSize]': pageSize,
    }

    const { data } = await getAPI({
      url: `/api/employee-dashboards/${id}/current-projects`,
      headers,
      params,
    })

    return dispatch({
      type: GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Current Project for employee dashboard failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDailySummaryReport = (filter) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-type': 'application/json',
    }
    const { id, page, pageSize } = filter
    const params = {
      'pagination[page]': 1,
      'pagination[pageSize]': 10,
    }

    const { data } = await getAPI({
      url: `/api/employee-dashboards/${id}/daily-summary-reports`,
      headers,
      params,
    })

    return dispatch({
      type: GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Daily Summary Report for employee dashboard failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}
