import React from 'react'

import styled from 'styled-components'
import Dropdown from '../../form/Dropdown'
import TextField from '../../form/TextField'
import Date from '../../form/Date'

import { PiWarningCircleBold } from 'react-icons/pi'
import { BiTrash } from 'react-icons/bi'

const Div = styled.div`
  .mr-4-px {
    margin-right: 4px;
  }
  .mr-12-px {
    margin-right: 12px;
  }

  .icon {
    cursor: pointer;
    color: var(--Gray-600);
  }

  .table-container {
    width: 100%;
    overflow-x: auto;

    textarea,
    select,
    input {
      width: 100%;
      box-sizing: border-box;
    }

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 0;
    }

    thead {
      background: var(--Gray-50);

      th {
        border: 1px solid var(--Gray-200);
        padding: 12px 24px;

        color: var(--Gray-600, #475467);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;

        &:first-child {
          border-top-left-radius: 8px;
        }
        &:last-child {
          border-top-right-radius: 8px;
        }
      }
    }

    tbody {
      .no-text {
        color: var(--Gray-600);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
      }
      td {
        border: 1px solid var(--Gray-200);
        padding: 12px 24px;
      }

      tr {
        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 8px !important;
            }

            &:last-child {
              border-bottom-right-radius: 8px !important;
            }
          }
        }
      }
    }

    .w-text-area {
      width: 345px;
    }
    .w-another-input {
      width: 222px;
    }
    .w-deletion {
      width: 100px;
    }

    .deletion-btn {
      text-align: center;
    }
  }
`
const TextArea = styled.textarea`
  font-family: 'Inter', 'Noto Sans Thai';
  border-radius: 8px;
  border: 1px solid var(--Gray-300);
  background: var(--Base-White);

  padding: 8px 12px;
  width: 345px;
  height: 96px;
  resize: none;

  &:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    color: var(--Gray-500);
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.align-items-center {
    align-items: center;
  }
`

function KPIInput({ list, onChange, onChangeDropdown, onChangeDate, onDelete, disabled = false, ...pros }) {
  const initialTargetType = [
    { text: 'Number', value: 'Number' },
    { text: 'Percent', value: 'Percent' },
  ]

  const deleteKPI = (key) => {
    onDelete(key)
  }
  return (
    <Div {...pros}>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className="w-text-area">
                <FlexContainer className="align-items-center">
                  <span className="mr-4-px">KPIs</span>
                  <PiWarningCircleBold className="icon" />
                </FlexContainer>
              </th>
              <th className="w-text-area">
                <FlexContainer className="align-items-center">
                  <span className="mr-4-px">Description</span>
                  <PiWarningCircleBold className="icon" />
                </FlexContainer>
              </th>
              <th className="w-another-input">
                <FlexContainer className="align-items-center">
                  <span className="mr-4-px">Target Type</span>
                  <PiWarningCircleBold className="icon" />
                </FlexContainer>
              </th>
              <th className="w-another-input">
                <FlexContainer className="align-items-center">
                  <span className="mr-4-px">Target Value</span>
                  <PiWarningCircleBold className="icon" />
                </FlexContainer>
              </th>
              <th className="w-text-area">
                <FlexContainer className="align-items-center">
                  <span className="mr-4-px">Unit</span>
                  <PiWarningCircleBold className="icon" />
                </FlexContainer>
              </th>
              <th className="w-another-input">
                <FlexContainer className="align-items-center">
                  <span className="mr-4-px">Start Date</span>
                  <PiWarningCircleBold className="icon" />
                </FlexContainer>
              </th>
              <th className="w-another-input">
                <FlexContainer className="align-items-center">
                  <span className="mr-4-px">End Date</span>
                  <PiWarningCircleBold className="icon" />
                </FlexContainer>
              </th>
              <th className="w-deletion"></th>
            </tr>
          </thead>
          <tbody>
            {list?.map((v, i) => (
              <tr key={v.key}>
                <td>
                  <FlexContainer className="align-items-center">
                    <span className="no-text mr-12-px">{i + 1}</span>
                    <TextArea
                      name="kpi_name"
                      value={v.kpi_name}
                      onChange={(e) => onChange(v.key, e)}
                      disabled={disabled}
                    />
                  </FlexContainer>
                </td>
                <td>
                  <TextArea
                    name="description"
                    value={v.description}
                    onChange={(e) => onChange(v.key, e)}
                    disabled={disabled}
                  />
                </td>
                <td>
                  <Dropdown
                    optionList={initialTargetType}
                    value={v.target_type}
                    onChange={(value) => onChangeDropdown(v.key, 'target_type', value)}
                    disabled={disabled}
                  />
                </td>
                <td>
                  <TextField
                    name="target_value"
                    value={v.target_value}
                    onChange={(e) => onChange(v.key, e)}
                    disabled={disabled}
                  />
                </td>
                <td>
                  <TextArea name="unit" value={v.unit} onChange={(e) => onChange(v.key, e)} disabled={disabled} />
                </td>
                <td>
                  <Date
                    value={v.start_date}
                    onChange={(value) => onChangeDate(v.key, 'start_date', value)}
                    disabled={disabled}
                  />
                </td>
                <td>
                  <Date
                    value={v.end_date}
                    onChange={(value) => onChangeDate(v.key, 'end_date', value)}
                    disabled={disabled}
                  />
                </td>
                <td className="deletion-btn">
                  {disabled ? (
                    <BiTrash className="icon" />
                  ) : (
                    <BiTrash className="icon" onClick={() => deleteKPI(v.key)} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Div>
  )
}

export default KPIInput
