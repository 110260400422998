import { getAPI,postAPI } from '../../utils/api'
import {
  GET_ORGANIZATION_CHART,
  GET_DEPARTMENT_CHART,
  GET_ORG_CHART
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'


export const getChartDepartMentAll = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DEPARTMENT_CHART.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/departments?pagination[page]=1&pagination[pageSize]=10&type=api&filters[name][$contains]=&filters[status][$ne]=delete`,
      headers: headers,
    })

    return dispatch({
      type: GET_DEPARTMENT_CHART.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_DEPARTMENT_CHART.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Department DropDown Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getOrganizationChart = (id) => async (dispatch) => {
    try {
        dispatch(showLoading())
        dispatch({ type: GET_ORGANIZATION_CHART.REQUEST })
        const { jwt } = JSON.parse(localStorage.getItem('admin'))
        const headers = {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
        }
        const { data, nodes } = await getAPI({
          url: `/api/departments/${id}?filters[organization]=true`,
          headers: headers,
        })
        
        return dispatch({
          type: GET_ORGANIZATION_CHART.SUCCESS,
          data,
          nodes
        })
    } catch (error) {
        return dispatch({ type: GET_ORGANIZATION_CHART.FAILURE, error })
    } finally {
        dispatch(hideLoading())
    }   
}

export const GetOrgChart = (inp_data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_ORG_CHART.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    //alert(JSON.stringify(inp_data))
    const { data } = await postAPI({
      url: `/api/org-chart`,
      data:inp_data,
      headers: headers,
    })
    //alert(JSON.stringify('--- OK ------'))
    //alert(JSON.stringify(data))
    return {type : "GetOrgChart_SUCCESS" , data_info : data};

  } catch (err) {
    //alert(JSON.stringify(err))
    return {type : "GetOrgChart_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const GetEmployeeChart = (inp_data) => async (dispatch) => {
  try {
    
    dispatch(showLoading())
    dispatch({ type: GET_ORG_CHART.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await postAPI({
      url: `/api/employee-chart`,
      data:inp_data,
      headers: headers,
    })
    return {type : "GetEmployeeChart_SUCCESS" , data_info : data};

  } catch (err) {
    return {type : "GetEmployeeChart_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}