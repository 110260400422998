import { Stack } from '@mui/material'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { FiCalendar } from 'react-icons/fi'

import { DOCUMENT_SIGNER } from 'constants/operation-process'
import { Chip } from '.'
import AssignTo from '../assign-to'

export default function DocumentSigners({ data: signer }) {
  const { t } = useTranslation()
  const { employee } = signer || {}
  const { first_name, last_name, position } = employee || {}

  return (
    <>
      <Stack spacing={1}>
        <p className="title">{signer?.label}</p>

        <>
          {/* <Stack direction="row" gap="0.5rem">
              <FiCalendar size={16} />
              <p>{`${t('due_date')}: ${
                due_date && dayjs(due_date).isValid() && dayjs(due_date).format(dateFormat)
              }`}</p>
            </Stack> */}
          <p className="title">
            {first_name && last_name ? `${first_name} ${last_name} ${position && `(${position?.name})`}` : '-'}
          </p>

          {/* <AssignTo
            name={first_name && last_name ? `${first_name} ${last_name} ${position && `(${position?.name})`}` : '-'}
          /> */}
        </>
      </Stack>

      {signer.status && <Chip label={signer.status} color={DOCUMENT_SIGNER.STATUS_CHIP[signer.status]} />}
    </>
  )
}
