import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectDocumentCategory } from '../../../redux/selectors'

import styled from 'styled-components'
import TextField from '../../form/TextField'

import { LiaTimesSolid } from 'react-icons/lia'
import Button from '../../form/button/Button'
import ButtonOutline from '../../form/button/ButtonOutline'

import PropTypes from 'prop-types'

const Wrapper = styled.div`
  padding: 24px;
  height: 100%;

  color: var(--Gray-600);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .icon {
    cursor: pointer;
  }

  .d-flex {
    display: flex;
  }
  .justify-content-end {
    justify-content: flex-end;
  }

  .mr-12-px {
    margin-right: 12px;
  }
  .mt-24-px {
    margin-top: 24px;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }

  .headline-panel {
    color: var(--Gray-900);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
  }

  .sub-headline-panel {
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--Gray-200);
  }
`
const InputContainer = styled.div`
  .width-100 {
    width: 100%;
  }
  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.375rem;
  }
  .error-input {
    border: 1px solid var(--Error-300);
  }
  .error {
    color: var(--Error-500);
  }
`
const initValidate = {
  name: null,
}
function EditCategory({ onSubmit, close }) {
  const { t } = useTranslation()
  const { documentCategoryInfo, isLoading, state } = useSelector(selectDocumentCategory)

  const [name, setName] = useState('')
  // validate
  const [validateForm, setValidateForm] = useState(initValidate)

  useEffect(() => {
    if (documentCategoryInfo?.name) {
      setName(documentCategoryInfo?.name)
    }
  }, [documentCategoryInfo])

  useEffect(() => {
    if (state === 'EDIT_DOCUMENT_CATEGORY.FAILURE' && isLoading === false) {
      let newErrors = {
        ...initValidate,
      }
      newErrors.name = 'This category is already existed.'
      setValidateForm(newErrors)
    }
  }, [state, isLoading])

  const isValid = () => {
    let newErrors = {
      ...initValidate,
    }

    if (name === '') {
      newErrors.name = 'Please input category name'
    }
    setValidateForm(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  const editCategory = () => {
    const isValidate = isValid()
    if (isValidate) {
      const request = {
        data: {
          name: name,
          status: documentCategoryInfo?.status,
        },
      }
      onSubmit(documentCategoryInfo?.id, request)
    }
  }

  return (
    <Wrapper>
      <div className="first-content">
        <div className="d-flex justify-content-end">
          <LiaTimesSolid className="icon" size={25} onClick={close} />
        </div>

        <div className="headline-panel">{t('edit_document_category')}</div>
        <div className="sub-headline-panel">{t('sub_headline_category')}</div>

        <div className="divider mt-24-px mb-24-px"></div>

        <InputContainer>
          <div className="input-header">{t('category')}</div>
          <TextField
            className={`width-100 mb-8-px ${validateForm.name ? 'error-input' : ''}`}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {validateForm.name && <div className="error">{validateForm.name}</div>}
          <div>(e.g. company information)</div>
        </InputContainer>
      </div>
      <div className="second-content">
        <div className="divider mb-24-px"></div>
        <div className="d-flex justify-content-end">
          <ButtonOutline className="mr-12-px" onClick={close}>
            {t('cancel')}
          </ButtonOutline>
          <Button onClick={editCategory}>{t('save')}</Button>
        </div>
      </div>
    </Wrapper>
  )
}

EditCategory.propTypes = {
  onSubmit: PropTypes.func,
  close: PropTypes.func,
}

export default EditCategory
