import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  addDocumentItem,
  deleteDocumentItem,
  getAmphureList,
  getProvinceList,
  getTambonList,
} from '../../../../../redux/actions/summaryTask'
import { selectSummaryTask } from '../../../../../redux/selectors'
import Table from '../../../../common/Table'
import Date from '../../../../form/Date'
import Dropdown from '../../../../form/Dropdown'
import TextField from '../../../../form/TextField'
import Button from '../../../../form/button/Button'
import DropDownSelect from '../../../../widget/element/DropDownSelect'

import { useTranslation } from 'react-i18next'

export default function ProcurementTemplate({ isInvalid = false, onDataChange, isEdit, initData }) {
  const allowTypes = ['images', 'files']
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { provinceListStore, amphureListStore, tambonListStore, documentInfo, documentItem } =
    useSelector(selectSummaryTask)
  const [date, setDate] = useState('')
  // Procurement item
  const [selectedUom, setSelectedUom] = useState('')
  const [selectedType, setSelectedType] = useState('')
  const [itemCode, setItemCode] = useState('')
  const [itemName, setItemName] = useState('')
  const [itemQuantity, setItemQuantity] = useState('')
  const [itemPrice, setItemPrice] = useState('')

  const [isErrorUom, setIsErrorUom] = useState(false)
  const [isErrorType, setIsErrorType] = useState(false)
  const [isErrorItemCode, setIsErrorItemCode] = useState(false)
  const [isErrorItemName, setIsErrorItemName] = useState(false)
  const [isErrorItemQuantity, setIsErrorItemQuantity] = useState(false)
  const [isErrorItemPrice, setIsErrorItemPrice] = useState(false)

  const [procurementList, setProcurementList] = useState([])

  const [isErrorCompany, setIsErrorCompany] = useState(false)
  const [isErrorRefNo, setIsErrorRefNo] = useState(false)
  const [isErrorPurchaseForm, setIsErrorPurchaseForm] = useState(false)
  const [isErrorAddress, setIsErrorAddress] = useState(false)
  const [isErrorProvince, setIsErrorProvince] = useState(false)
  const [isErrorDistrict, setIsErrorDistrict] = useState(false)
  const [isErrorSubDistrict, setIsErrorSubDistrict] = useState(false)
  const [isErrorPostCode, setIsErrorPostCode] = useState(false)
  const [isErrorContactPerson, setIsErrorContactPerson] = useState(false)
  const [isErrorEmail, setIsErrorEmail] = useState(false)
  const [isErrorPhoneNo, setIsErrorPhoneNo] = useState(false)
  const [isErrorDate, setIsErrorDate] = useState(false)
  const [isRoutine, setIsRoutine] = useState(true)
  // const [isSelectAll, setIsSelectAll] = useState(false)
  // const statusList = [
  //   {
  //     text: 'Supplier',
  //     value: 'supplier',
  //   },
  //   {
  //     text: 'Other',
  //     value: 'other',
  //   },
  // ]
  // const selectProduct = [
  //   {
  //     text: 'Select Product',
  //     value: 'select_product',
  //   },
  //   {
  //     text: 'Other',
  //     value: 'other',
  //   },
  // ]
  const uom = [
    {
      text: 'UOM',
      value: 'uom',
    },
    {
      text: 'Other',
      value: 'other',
    },
  ]
  const headerList = [
    {
      Header: t('item_code_sku'),
      accessor: 'item_code_sku',
      disableSortBy: false,
    },
    {
      Header: t('name'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('type'),
      accessor: 'type',
      disableSortBy: false,
    },
    {
      Header: t('uom'),
      accessor: 'uom',
      disableSortBy: false,
    },
    {
      Header: t('quantity'),
      accessor: 'quantity',
      disableSortBy: false,
    },
    {
      Header: t('unit_price'),
      accessor: 'unit_price',
      disableSortBy: false,
    },
    {
      Header: t('total_price'),
      accessor: 'total_price',
      disableSortBy: false,
    },
    {
      Header: t('action'),
      accessor: 'action',
      disableSortBy: false,
    },
  ]

  //list input
  const [companyList, setCompanyList] = useState([
    {
      text: 'สำนักงานใหญ่',
      value: 'main',
    },
  ])
  const [provinceList, setProvinceList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [subDistrictList, setSubDistrictList] = useState([])
  const itemTypeList = [
    {
      text: 'Materials',
      value: 'Materials',
    },
    {
      text: 'Product',
      value: 'Product',
    },
    {
      text: 'Package',
      value: 'Package',
    },
    {
      text: 'Other',
      value: 'Other',
    },
  ]
  const uomList = [
    {
      text: 'Pack',
      value: 'Pack',
    },
  ]
  const tmpData = {
    name: '',
    template: '',
    //-----------------------------
    purchase_from: '',
    address: '',
    reference_number: '',
    contact_person: '',
    email: '',
    phone_no: '',
    purchase_date: '',
    thai_province: '',
    thai_amphure: '',
    thai_tambon: '',
    procurement_item: [],
    //------------------------------
    company: 'main',
    post_code: '',
  }
  const [inputData, setInputData] = useState(tmpData)
  const [dataViews, setDataViews] = useState([])

  useEffect(() => {
    if (isInvalid) {
      checkValidateAllInputs()
    }
  }, [isInvalid])

  useEffect(() => {
    if (procurementList?.length > 0) {
      const viewList = procurementList.map((item, index) => ({
        ...item,
        item_code_sku: item?.item_code,
        name: item?.name,
        type: item?.type,
        uom: item?.UOM,
        quantity: item?.quantity,
        unit_price: item?.unit_price,
        total_price: item?.unit_price * item?.quantity,
        action: <Button onClick={() => handleClickDelete(item, index)}>Delete</Button>,
      }))
      setDataViews(viewList)
    } else {
      setDataViews([])
    }
  }, [procurementList])

  useEffect(() => {
    getProvinceListData()
  }, [])

  useEffect(() => {
    if (provinceListStore?.length > 0) {
      setProvinceList(makeAddressListToDropdownData(provinceListStore))
    }
  }, [provinceListStore])
  useEffect(() => {
    if (documentItem?.id) {
      // dlog('documentItem',documentItem)
      let tmpObj = {
        id: documentItem.id,
        ...documentItem.attributes,
      }
      let tmpList = [...procurementList, tmpObj]
      setProcurementList(tmpList)
    }
  }, [documentItem])

  useEffect(() => {
    if (initData && Object.keys(initData).length > 0) {
      let tmp = { ...initData }
      const province_id_init = tmp.thai_province.data.id
      const amphure_id_init = tmp.thai_amphure.data.id

      dispatch(getAmphureList(province_id_init))
      dispatch(getTambonList(amphure_id_init))

      tmp.thai_province = province_id_init
      setDate(tmp.purchase_date)
      setInputData(tmp)
      const tmpDocList = tmp.procurement_items.data
      if (tmpDocList?.length > 0) {
        let docList = []
        for (let i = 0; i < tmpDocList.length; i++) {
          let docData = {
            id: tmpDocList[i].id,
            ...tmpDocList[i].attributes,
          }
          docList = [...docList, docData]
        }
        setProcurementList(docList)
      }
    } else {
      setProcurementList([])
    }
  }, [initData])

  useEffect(() => {
    if (amphureListStore?.length > 0) {
      setDistrictList(makeAddressListToDropdownData(amphureListStore))
      if (initData && Object.keys(initData).length > 0) {
        let tmp = { ...inputData }
        const amphure_id_init = initData.thai_amphure.data.id
        tmp.thai_amphure = amphure_id_init
        setInputData(tmp)
      }
    }
  }, [amphureListStore])

  useEffect(() => {
    if (tambonListStore?.length > 0) {
      setSubDistrictList(makeAddressListToDropdownData(tambonListStore))
      if (initData && Object.keys(initData).length > 0) {
        let tmp = { ...inputData }
        const tambon_id_init = initData.thai_tambon.data.id
        tmp.thai_tambon = tambon_id_init
        const selectedTambonData = tambonListStore.find((x) => x.id === tambon_id_init)
        if (selectedTambonData) {
          tmp.post_code = selectedTambonData.attributes?.zip_code
        }
        setInputData(tmp)
      }
    }
  }, [tambonListStore])

  const getProvinceListData = useCallback(async () => {
    dispatch(getProvinceList())
  }, [dispatch])

  const makeAddressListToDropdownData = (list) => {
    let dropdownData = []
    for (let i = 0; i < list.length; i++) {
      let tmp = {
        text: list[i].attributes.name_th,
        value: list[i].id,
      }
      dropdownData = [...dropdownData, tmp]
    }
    return dropdownData
  }

  // const onChangeSelectAllCheckBox = (value) => {
  //   setIsSelectAll(value)
  // }
  const checkValidateAllInputs = () => {
    let tmpObj = { ...inputData }

    if (tmpObj?.purchase_from == '') {
      setIsErrorPurchaseForm(true)
    } else {
      setIsErrorPurchaseForm(false)
    }

    if (tmpObj?.address == '') {
      setIsErrorAddress(true)
    } else {
      setIsErrorAddress(false)
    }

    if (tmpObj?.reference_number == '') {
      setIsErrorRefNo(true)
    } else {
      setIsErrorRefNo(false)
    }

    if (tmpObj?.contact_person == '') {
      setIsErrorContactPerson(true)
    } else {
      setIsErrorContactPerson(false)
    }

    if (tmpObj?.email == '') {
      setIsErrorEmail(true)
    } else {
      setIsErrorEmail(false)
    }

    if (tmpObj?.phone_no == '') {
      setIsErrorPhoneNo(true)
    } else {
      setIsErrorPhoneNo(false)
    }

    if (tmpObj?.purchase_date == '') {
      setIsErrorDate(true)
    } else {
      setIsErrorDate(false)
    }

    if (tmpObj?.thai_province == '') {
      setIsErrorProvince(true)
    } else {
      setIsErrorProvince(false)
    }

    if (tmpObj?.thai_amphure == '') {
      setIsErrorDistrict(true)
    } else {
      setIsErrorDistrict(false)
    }

    if (tmpObj?.thai_tambon == '') {
      setIsErrorSubDistrict(true)
    } else {
      setIsErrorSubDistrict(false)
    }

    if (tmpObj?.post_code == '') {
      setIsErrorPostCode(true)
    } else {
      setIsErrorPostCode(false)
    }
  }
  const handleClickDelete = (data, index) => {
    if (isEdit) {
      dispatch(deleteDocumentItem(data.id, true)).then(() => {
        let tmp = [...procurementList]
        tmp.splice(index, 1)
        setProcurementList(tmp)
      })
    } else {
      let tmp = [...procurementList]
      tmp.splice(index, 1)
      setProcurementList(tmp)
      let tmpObj = { ...inputData }
      tmpObj.procurement_item = [...tmp]
      setInputData(tmpObj)
      if (onDataChange) {
        onDataChange(tmpObj)
      }
    }
  }
  const onChangeDate = (value) => {
    setDate(value)
    let tmpObj = { ...inputData }
    tmpObj.purchase_date = value
    setInputData(tmpObj)
    setIsErrorDate(false)
    if (onDataChange) {
      onDataChange(tmpObj)
    }
  }
  // const getHeaderViewList = () => {
  //   let views = []

  //   views.push(
  //     <TableCell key={'header-table-' + 0} align="left" width={10}>
  //       <Checkbox className="check-box-style" checked={isSelectAll} onChange={onChangeSelectAllCheckBox} />
  //     </TableCell>
  //   )

  //   for (let i = 0; i < headerList.length; i++) {
  //     views.push(
  //       <TableCell key={'header-table-' + (i + 1)} align="center">
  //         {headerList[i]?.Header}
  //       </TableCell>
  //     )
  //   }
  //   return views
  // }
  // const getBodyViewList = () => {
  //   let views = []

  //   for (let i = 0; i < dataList.length; i++) {
  //     views.push(
  //       <TableRow key={'row-table-' + i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
  //         <TableCell align="left" width={10}>
  //           {<Checkbox className="check-box-style" checked={isSelectAll} onChange={onChangeSelectAllCheckBox} />}
  //         </TableCell>
  //         <TableCell align="center">{dataList[i]?.product_name}</TableCell>
  //         <TableCell align="center">{dataList[i]?.sku}</TableCell>
  //         <TableCell align="center">{dataList[i]?.uom}</TableCell>
  //         <TableCell align="center">{dataList[i]?.unit_price}</TableCell>
  //         <TableCell align="center">{dataList[i]?.total_price}</TableCell>
  //         <TableCell align="center">{dataList[i]?.action}</TableCell>
  //       </TableRow>
  //     )
  //   }
  //   return views
  // }

  const handleDropdownSelected = (value, type) => {
    let tmpObj = { ...inputData }
    if (type == 'company') {
      tmpObj.company = value // company
    } else if (type == 'province') {
      tmpObj.thai_province = value // จังหวัด
      if (value) {
        dispatch(getAmphureList(value))
        setDistrictList([])
        setSubDistrictList([])
        tmpObj.thai_amphure = ''
        tmpObj.thai_tambon = ''
        tmpObj.post_code = ''
      }
    } else if (type == 'district') {
      tmpObj.thai_amphure = value // อำเภอ
      if (value) {
        dispatch(getTambonList(value))
        setSubDistrictList([])
        tmpObj.thai_tambon = ''
        tmpObj.post_code = ''
      }
    } else if (type == 'subDistrict') {
      tmpObj.thai_tambon = value // ตำบล

      const selectedTambonData = tambonListStore.find((x) => x.id === value)
      if (selectedTambonData) {
        tmpObj.post_code = selectedTambonData.attributes?.zip_code
      }
    }
    setInputData(tmpObj)
    if (onDataChange) {
      onDataChange(tmpObj)
    }
  }
  const handleDropdownSelectedItem = (value, type) => {
    if (type == 'item_type') {
      setSelectedType(value)
      setIsErrorType(false)
    } else if (type == 'item_uom') {
      setSelectedUom(value)
      setIsErrorUom(false)
    }
  }
  const handleChangeInput = (value, typeKey) => {
    let tmpObj = { ...inputData }
    tmpObj[typeKey] = value
    setInputData(tmpObj)
    if (onDataChange) {
      onDataChange(tmpObj)
    }
  }
  const handleClickAdd = () => {
    let tmoObj = {
      item_code: itemCode,
      name: itemName,
      type: selectedType,
      UOM: selectedUom,
      quantity: itemQuantity,
      unit_price: itemPrice,
    }
    let isValid = checkValidateInput(tmoObj)
    if (isValid) {
      if (isEdit) {
        //API ADD DOC ITEM
        tmoObj.procurement_template = documentInfo.id
        dispatch(addDocumentItem({ data: { ...tmoObj } }, true)).then(() => {
          // Clear Data
          setItemCode('')
          setItemName('')
          setItemQuantity('')
          setItemPrice('')
          setSelectedType('')
          setSelectedUom('')
        })
      } else {
        let tmpList = [...procurementList, tmoObj]
        setProcurementList(tmpList)
        let tmpObj = { ...inputData }
        tmpObj.procurement_item = [...tmpList]
        setInputData(tmpObj)

        // Clear Data
        setItemCode('')
        setItemName('')
        setItemQuantity('')
        setItemPrice('')
        setSelectedType('')
        setSelectedUom('')

        // Call back onChangeData
        if (onDataChange) {
          onDataChange(tmpObj)
        }
      }
    } else {
      alert('Please Enter All Input')
    }
  }
  const checkValidateInput = (obj) => {
    let isValidUom = false
    let isValidType = false
    let isValidItemCode = false
    let isValidItemName = false
    let isValidItemQuantity = false
    let isValidItemPrice = false

    if (obj.item_code != '') {
      isValidItemCode = true
      setIsErrorItemCode(false)
    } else {
      isValidItemCode = false
      setIsErrorItemCode(true)
    }

    if (obj.name != '') {
      isValidItemName = true
      setIsErrorItemName(false)
    } else {
      isValidItemName = false
      setIsErrorItemName(true)
    }

    if (obj.quantity != '') {
      isValidItemQuantity = true
      setIsErrorItemQuantity(false)
    } else {
      isValidItemQuantity = false
      setIsErrorItemQuantity(true)
    }

    if (obj.unit_price != '') {
      isValidItemPrice = true
      setIsErrorItemPrice(false)
    } else {
      isValidItemPrice = false
      setIsErrorItemPrice(true)
    }

    if (obj.type != '') {
      isValidType = true
      setIsErrorType(false)
    } else {
      isValidType = false
      setIsErrorType(true)
    }

    if (obj.UOM != '') {
      isValidUom = true
      setIsErrorUom(false)
    } else {
      isValidUom = false
      setIsErrorUom(true)
    }

    if (isValidUom && isValidType && isValidItemCode && isValidItemName && isValidItemQuantity && isValidItemPrice) {
      return true
    }
    return false
  }
  return (
    <Styled>
      <div className="wrp-document-content">
        {/* <div className="title-input">Purcurement</div> */}
        <div className="wrp-input-top">
          {/* row1 */}
          <div className="row">
            <div className="box-input mr-12">
              <div className="text-title-input">{t('select_company')}</div>
              <Dropdown
                optionList={companyList}
                value={inputData.company || 'main'}
                onChange={(e) => {
                  setIsErrorCompany(false)
                  handleDropdownSelected(e, 'company')
                }}
                error={isErrorCompany}
              />
            </div>
            {!isEdit && (
              <div className="box-input">
                <div className="text-title-input">{t('reference_number')}</div>
                <TextField
                  className="text-filed"
                  placeholder={t('reference_number')}
                  value={inputData.reference_number || ''}
                  onChange={(e) => {
                    setIsErrorRefNo(false)
                    handleChangeInput(e.target.value, 'reference_number')
                  }}
                  error={isErrorRefNo}
                />
              </div>
            )}
          </div>

          {/* row2 */}
          <div className="row">
            <div className="w-50">
              <div className="box-input pdr-6">
                <div className="text-title-input">{t('purchase_from')}</div>
                <TextField
                  className="text-filed"
                  placeholder={t('company_name')}
                  value={inputData.purchase_from || ''}
                  onChange={(e) => {
                    setIsErrorPurchaseForm(false)
                    handleChangeInput(e.target.value, 'purchase_from')
                  }}
                  error={isErrorPurchaseForm}
                />
              </div>
            </div>
          </div>

          {/* row3 */}
          <div className="row">
            <div className="box-input">
              <div className="text-title-input">{t('company_address')}</div>
              <TextField
                className="text-filed"
                placeholder={t('address')}
                value={inputData.address || ''}
                onChange={(e) => {
                  setIsErrorAddress(false)
                  handleChangeInput(e.target.value, 'address')
                }}
                error={isErrorAddress}
              />
            </div>
          </div>

          {/* row4 */}
          <div className="row">
            <div className="box-input mr-12">
              <div className="text-title-input">{t('province')}</div>
              <Dropdown
                placeHolder={t('province')}
                optionList={provinceList}
                value={inputData.thai_province || ''}
                onChange={(e) => {
                  setIsErrorProvince(false)
                  handleDropdownSelected(e, 'province')
                }}
                error={isErrorProvince}
              />
            </div>
            <div className="box-input mr-12">
              <div className="text-title-input">{t('district')}</div>
              <DropDownSelect
                dataList={districtList}
                isDisabled={inputData?.thai_province ? false : true}
                onChange={(e) => {
                  setIsErrorDistrict(false)
                  handleDropdownSelected(e, 'district')
                }}
                isError={isErrorDistrict}
                initValue={inputData.thai_amphure || ''}
                placeHolder="District"
              />
            </div>
            <div className="box-input mr-12">
              <div className="text-title-input">{t('sub_district')}</div>
              <DropDownSelect
                dataList={subDistrictList}
                isDisabled={!(inputData && inputData.thai_amphure && inputData.thai_province)}
                onChange={(e) => {
                  setIsErrorSubDistrict(false)
                  handleDropdownSelected(e, 'subDistrict')
                }}
                isError={isErrorSubDistrict}
                initValue={inputData.thai_tambon || ''}
                placeHolder="Sub-District"
              />
            </div>
            <div className="box-input">
              <div className="text-title-input">{t('postcode')}</div>
              <TextField
                className="text-filed"
                placeholder={t('postcode')}
                value={inputData.post_code || ''}
                onChange={(e) => {
                  setIsErrorPostCode(false)
                  handleChangeInput(e.target.value, 'post_code')
                }}
                error={isErrorPostCode}
              />
            </div>
          </div>

          {/* row5 */}
          <div className="row">
            <div className="box-input mr-12">
              <div className="text-title-input">{t('contact_person')}</div>
              <TextField
                className="text-filed"
                placeholder={t('name')}
                value={inputData.contact_person || ''}
                onChange={(e) => {
                  setIsErrorContactPerson(false)
                  handleChangeInput(e.target.value, 'contact_person')
                }}
                error={isErrorContactPerson}
              />
            </div>
            <div className="box-input mr-12">
              <div className="text-title-input">{t('email')}</div>
              <TextField
                className="text-filed"
                placeholder={t('email')}
                value={inputData.email || ''}
                onChange={(e) => {
                  setIsErrorEmail(false)
                  handleChangeInput(e.target.value, 'email')
                }}
                error={isErrorEmail}
              />
            </div>
            <div className="box-input">
              <div className="text-title-input">{t('phone_number')}</div>
              <TextField
                className="text-filed"
                placeholder={t('phone_number')}
                value={inputData.phone_no || ''}
                onChange={(e) => {
                  setIsErrorPhoneNo(false)
                  handleChangeInput(e.target.value, 'phone_no')
                }}
                error={isErrorPhoneNo}
              />
            </div>
          </div>

          {/* row6 */}
          <div className="row">
            <div className="w-271px">
              <div className="box-input ">
                <div className="text-title-input title-left">{t('purchase_date')}</div>
                <Date value={date} onChange={(e) => onChangeDate(e)} error={isErrorDate} />
              </div>
            </div>
          </div>

          {/* row7 */}
        </div>

        <div className="wrp-input-bottom">
          <div className="row">
            <div className="box-input">
              <div className="text-title-input">{t('procurement_items')}</div>
              <div className="inputs">
                <div className="top-inputs">
                  <div className="slot-input mr-12">
                    <Dropdown
                      optionList={itemTypeList}
                      value={selectedType}
                      placeHolder={'กรุณาเลือก Type'}
                      onChange={(e) => handleDropdownSelectedItem(e, 'item_type')}
                      error={isErrorType}
                    />
                  </div>
                  <div className="slot-input mr-12">
                    <TextField
                      className="text-filed"
                      placeholder={t('item_code_sku')}
                      value={itemCode}
                      onChange={(e) => {
                        setItemCode(e.target.value)
                        setIsErrorItemCode(false)
                      }}
                      error={isErrorItemCode}
                    />
                  </div>
                  <div className="slot-input">
                    <TextField
                      className="text-filed"
                      placeholder={t('name')}
                      value={itemName}
                      onChange={(e) => {
                        setItemName(e.target.value)
                        setIsErrorItemName(false)
                      }}
                      error={isErrorItemName}
                    />
                  </div>
                </div>

                <div className="bottom-inputs">
                  <div className="slot-input mr-12">
                    <Dropdown
                      optionList={uomList}
                      placeHolder={'กรุณาเลือก UOM'}
                      value={selectedUom}
                      onChange={(e) => handleDropdownSelectedItem(e, 'item_uom')}
                      error={isErrorUom}
                    />
                  </div>
                  <div className="slot-input mr-12">
                    <TextField
                      className="text-filed"
                      placeholder={t('quantity')}
                      value={itemQuantity}
                      type="number"
                      onChange={(e) => {
                        setItemQuantity(e.target.value)
                        setIsErrorItemQuantity(false)
                      }}
                      error={isErrorItemQuantity}
                    />
                  </div>
                  <div className="slot-input">
                    <TextField
                      className="text-filed"
                      placeholder={t('price')}
                      value={itemPrice}
                      type="number"
                      onChange={(e) => {
                        setItemPrice(e.target.value)
                        setIsErrorItemPrice(false)
                      }}
                      error={isErrorItemPrice}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="btn-add">
            <Button onClick={() => handleClickAdd()}>{t('add')}</Button>
          </div>
        </div>

        <div className="wrp-table">
          <Table columns={headerList} data={dataViews} />
        </div>
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  .dropdown-search {
    display: flex;
    position: relative;
    .MuiSelect-root {
      padding: 0 10px 0 40px;
    }
    .icon-search {
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }
  .inner-row-left > div {
    width: auto;
  }
  .wrp-document-content {
  }

  .wrp-input-top {
    padding: 20px 21px 0;

    .row {
      display: flex;
      margin-bottom: 24px;

      .box-input {
        width: 100%;
        .text-title-input {
          margin-bottom: 6px;
        }
      }
    }
  }

  .wrp-input-bottom {
    border-top: 1px solid var(--Gray-200);
    padding: 20px 21px;

    .row {
      margin-bottom: 24px;

      .box-input {
        width: 100%;
        .text-title-input {
          margin-bottom: 6px;
        }
      }
    }

    .inputs {
      margin-bottom: 24px;

      .top-inputs,
      .bottom-inputs {
        display: flex;
      }
      .top-inputs {
        margin-bottom: 24px;
      }
    }
    .slot-input {
      width: 100%;
    }
    .btn-add {
      display: flex;
      justify-content: flex-end;
    }
  }

  .mr-12 {
    margin-right: 12px;
  }
  .w-50 {
    width: 50%;
  }
  .w-271px {
    width: 271.33px;
  }
  .pdr-6 {
    padding-right: 6px;
  }
  .text-filed {
    width: 100%;
  }
  /* ------- */

  .wrp-table {
  }

  .bottom-content {
    padding: 0 32px 0 0;
    display: flex;
    justify-content: flex-end;
    .btn-save {
      margin-left: 16px;
    }
  }
`
