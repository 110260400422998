import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import InputFile from '../../components/form/InputFile'
import MediaFile from '../../containers/cms/MediaFile'

import logoIcon from '../../assets/images/logo-icon.png'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import iconDelete from '../../assets/images/icon-bin-red.svg'

import { getApplicationFormById, getInvitationView } from '../../redux/actions/applicationForm'
import {
  getCountries,
  getBanks,
  getNationalities,
  getDepartmentsAndPositions,
  getProvinces,
  getDistricts,
  getSubDistricts,
} from '../../redux/actions/master'
import { selectApplicationForm, selectMaster } from '../../redux/selectors'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  padding: 6rem 15rem;
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .align-items-center {
    align-items: center;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-2rem {
    font-size: 1.5rem;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }

  .group-input {
    margin-bottom: 4rem;
  }

  .header-group-input {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--Gray-200, #eaecf0);
  }

  .input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-new-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    /* align-content: center; */
  }

  .input-new-3 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input-new-4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-2 {
    display: grid;
    grid-template-columns: 24rem 1fr;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-header {
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--Gray-700);
    margin-bottom: 5px;
  }

  .button-outline {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }
`
const initApplicationForm = {
  employ_id: '',
  first_name: '',
  last_name: '',
  title: '',
  email: '',
  phone_no: '',
  department: '',
  position: '',
  photo: '',
  martial_status: '',
  nationality: '',
  identification_no: '',
  birth_date: undefined,
  number_of_children: '',
  gender: '',
  passport_no: '',
  address: '',
  thai_province: '',
  thai_amphure: '',
  thai_tambon: '',
  postcode: '',
  bank: '',
  bank_account_no: '',
  emergency_contact: '',
  private_car_plate: '',
  emergency_phone_no: '',
  visa_no: '',
  work_permit_no: '',
  visa_expire_date: undefined,
  workpermit_expire_date: undefined,
  workpermit_file_id: '',
  education: [
    {
      key: uuidv4(),
      school_name: '',
      school_type: 'Elementary school',
      entry_year: '',
      graduate_year: '',
    },
    {
      key: uuidv4(),
      school_name: '',
      school_type: 'Junior high school',
      entry_year: '',
      graduate_year: '',
    },
    {
      key: uuidv4(),
      school_name: '',
      school_type: 'Senior hign school',
      entry_year: '',
      graduate_year: '',
    },
  ],
  university: [],
  resume: [],
}

const initTitleList = [
  { text: 'Mr.', value: 'Mr.' },
  { text: 'Ms.', value: 'Ms.' },
  { text: 'Mrs.', value: 'Mrs.' },
]

const initMartialStatusList = [
  { text: 'Single', value: 'Single' },
  { text: 'Married', value: 'Married' },
  { text: 'Divorced', value: 'Divorced' },
  { text: 'Windowed', value: 'Windowed' },
  { text: 'Separated', value: 'Separated' },
  { text: 'Domestic', value: 'Domestic' },
]

const initGenderList = [
  { text: 'Male', value: 'Male' },
  { text: 'Female', value: 'Female' },
]

const initCertificationLevel = [
  { text: 'ปริญญาตรี', value: 'ปริญญาตรี' },
  { text: 'ปริญญาโท', value: 'ปริญญาโท' },
  { text: 'ปริญญาเอก', value: 'ปริญญาเอก' },
]

const allowTypes = ['images', 'files']

function ViewApplicationFormInfo() {
  const { t } = useTranslation()
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { invitationView } = useSelector(selectApplicationForm)
  const { countries, banks, nationalities, departments, provinces, districts, subdistricts } = useSelector(selectMaster)
  const [formApplication, setFormApplication] = useState(initApplicationForm)
  const [filesPhoto, setFilesPhoto] = useState([])
  const [filesResume, setFilesResume] = useState([])
  const [filesWorkPermit, setFilesWorkPermit] = useState([])
  // dropdown list
  const [listYear, setListYear] = useState([])
  const [countryList, setCountryList] = useState([])
  const [bankList, setBankList] = useState([])
  const [nationalityList, setNationalityList] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  const [positionList, setPositionList] = useState([])
  const [provinceList, setProvinceList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [subDistrictList, setSubDistrictList] = useState([])

  useEffect(() => {
    const { id } = params
    if (id) {
      dispatch(getInvitationView(id))
    }
  }, [params, dispatch])

  useEffect(() => {
    if (invitationView) {
      const {
        title,
        first_name,
        last_name,
        department,
        position,
        phone_no,
        email,
        martial_status,
        number_of_children,
        nationality,
        gender,
        identification_no,
        passport_no,
        birth_date,
        address,
        bank_account_no,
        bank,
        private_car_plate,
        emergency_contact,
        emergency_phone_no,
        visa_no,
        work_permit_no,
        thai_province,
        thai_amphure,
        thai_tambon,
        visa_expire_date,
        workpermit_expire_date,
        employee_educations,
        employee_universities,
      } = invitationView
      setFormApplication((prev) => ({
        ...prev,
        title,
        first_name,
        last_name,
        department: department?.id,
        position: position?.id,
        phone_no,
        email,
        martial_status,
        number_of_children,
        nationality: nationality?.id,
        gender,
        identification_no,
        passport_no,
        birth_date,
        address,
        bank_account_no,
        private_car_plate,
        emergency_contact,
        emergency_phone_no,
        visa_no,
        work_permit_no,
        thai_province: thai_province?.id,
        thai_amphure: thai_amphure?.id,
        thai_tambon: thai_tambon?.id,
        postcode: thai_tambon?.zip_code,
        bank: bank?.id,
        visa_expire_date,
        workpermit_expire_date,
        education: employee_educations,
        university: employee_universities,
      }))
    }
  }, [invitationView])

  useEffect(() => {
    const currentYear = dayjs().year()
    const yearRange = 50
    const yearList = []

    for (let i = currentYear - yearRange; i <= currentYear + yearRange; i++) {
      yearList.push(i)
    }

    const newYearList = yearList?.map((v) => ({
      text: v.toString(),
      value: v,
    }))
    setListYear(newYearList)
  }, [])

  useEffect(() => {
    dispatch(getCountries())
    dispatch(getBanks())
    dispatch(getNationalities())
    dispatch(getDepartmentsAndPositions())
    dispatch(getProvinces())
  }, [dispatch])

  useEffect(() => {
    const newList = countries?.map((v) => ({
      text: v?.name,
      value: v?.id,
    }))
    setCountryList(newList)
  }, [countries])
  useEffect(() => {
    const newList = banks?.map((v) => ({
      text: v?.nam_th,
      value: v?.id,
    }))
    setBankList(newList)
  }, [banks])
  useEffect(() => {
    const newList = nationalities?.map((v) => ({
      text: v?.name,
      value: v?.id,
    }))
    setNationalityList(newList)
  }, [nationalities])
  useEffect(() => {
    const newList = departments?.map((v) => ({
      text: v?.name,
      value: v?.id,
      positions: v?.positions,
    }))
    setDepartmentList(newList)
  }, [departments])
  useEffect(() => {
    const newList = provinces?.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
    }))
    setProvinceList(newList)
  }, [provinces])
  useEffect(() => {
    const newList = districts?.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
    }))
    setDistrictList(newList)
  }, [districts])
  useEffect(() => {
    const newList = subdistricts?.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
      postcode: v?.attributes?.zip_code,
    }))
    setSubDistrictList(newList)
  }, [subdistricts])

  useEffect(() => {
    if (formApplication?.department !== '') {
      const departmentMatch = departmentList.find((v) => v.value == formApplication?.department)
      const newPosition = departmentMatch?.positions?.map((v) => ({
        text: v?.name,
        value: v?.id,
      }))

      setPositionList(newPosition)
    }
  }, [formApplication?.department, departmentList])

  useEffect(() => {
    if (formApplication?.thai_province !== '') {
      dispatch(getDistricts(formApplication?.thai_province))
    }
  }, [formApplication?.thai_province, dispatch])

  useEffect(() => {
    if (formApplication?.thai_amphure !== '') {
      dispatch(getSubDistricts(formApplication?.thai_amphure))
    }
  }, [formApplication?.thai_amphure, dispatch])

  useEffect(() => {
    if (formApplication?.thai_tambon !== '') {
      const subDistrictMatch = subDistrictList.find((v) => v.value == formApplication?.thai_tambon)
      setFormApplication((prev) => ({
        ...prev,
        postcode: subDistrictMatch?.postcode,
      }))
    }
  }, [formApplication?.thai_tambon, subDistrictList])

  const toArray = (file) => {
    if (Array.isArray(file)) {
      return file
    } else {
      return file ? [file] : []
    }
  }

  return (
    <Div>
      <div className="d-flex justify-content-center align-items-center mb-2-rem">
        <img src={logoIcon} />
      </div>
      <div className="header mb-2-rem">{t('employee_data_information')}</div>

      <div className="group-input">
        <div className="header-group-input mb-1-rem">{t('basic_information')}</div>
        <div className="divider"></div>
        <div className="input-new mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('title')}*</div>
            <Dropdown name="title" optionList={initTitleList} value={formApplication.title} disabled={true} />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('first_name')}*</div>
            <TextField name="first_name" value={formApplication.first_name} disabled={true} />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('last_name')}*</div>
            <TextField name="last_name" value={formApplication.last_name} disabled={true} />
          </div>
        </div>

        <div className="input-new mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('departments')}*</div>
            <Dropdown
              name="department"
              optionList={departmentList}
              value={formApplication.department}
              disabled={true}
            />
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('job_position')}*</div>
            <Dropdown name="position" optionList={positionList} value={formApplication.position} disabled={true} />
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('photo')}</div>
            <MediaFile multiple={false} allowedTypes={allowTypes} file={toArray(filesPhoto)} disabled={true} />
          </div>
        </div>

        <div className="input-new mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('phone_number')}*</div>
            <TextField name="phone_no" value={formApplication.phone_no} disabled={true} />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('email')}*</div>
            <TextField name="email" value={formApplication.email} disabled={true} />
          </div>
        </div>
      </div>

      <div className="group-input">
        <div className="header-group-input mb-1-rem">{t('family_citizenship_information')}</div>
        <div className="divider"></div>
        <div className="input-new mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('martial_status')}*</div>
            <Dropdown
              name="martial_status"
              optionList={initMartialStatusList}
              value={formApplication.martial_status}
              disabled={true}
            />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('number_of_children')}*</div>
            <TextField name="number_of_children" value={formApplication.number_of_children} disabled={true} />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('nationality')}*</div>
            <Dropdown
              name="nationality"
              optionList={nationalityList}
              value={formApplication.nationality}
              disabled={true}
            />
          </div>
        </div>

        <div className="input-new mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('gender')}*</div>
            <Dropdown name="gender" optionList={initGenderList} value={formApplication.gender} disabled={true} />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('identification_no')}.*</div>
            <TextField name="identification_no" value={formApplication.identification_no} disabled={true} />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('passport_no')}.</div>
            <TextField name="passport_no" value={formApplication.passport_no} disabled={true} />
          </div>
        </div>

        <div className="input-new mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('date_of_birth')}*</div>
            <Date value={formApplication.birth_date} disabled={true} />
          </div>
        </div>
      </div>

      <div className="group-input">
        <div className="header-group-input mb-1-rem">{t('private_contact')}</div>
        <div className="divider"></div>
        <div className="input-new-2 mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('address')}*</div>
            <TextField name="address" value={formApplication.address} disabled={true} />
          </div>
        </div>
        <div className="input-new-3 mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('province')}*</div>
            <Dropdown
              name="thai_province"
              optionList={provinceList}
              value={formApplication.thai_province}
              disabled={true}
            />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('district')}*</div>
            <Dropdown
              name="thai_amphure"
              optionList={districtList}
              value={formApplication.thai_amphure}
              disabled={true}
            />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('sub_district')}*</div>
            <Dropdown
              name="thai_tambon"
              optionList={subDistrictList}
              value={formApplication.thai_tambon}
              disabled={true}
            />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('postcode')}*</div>
            <TextField name="postcode" disabled={true} value={formApplication?.postcode} />
          </div>
        </div>
        <div className="input-new mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('bank_account')}*</div>
            <Dropdown name="bank" optionList={bankList} value={formApplication.bank} disabled={true} />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('account_number')}*</div>
            <TextField name="bank_account_no" value={formApplication.bank_account_no} disabled={true} />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('private_car_plate')}</div>
            <TextField name="private_car_plate" value={formApplication.private_car_plate} disabled={true} />
          </div>
        </div>
        <div className="input-new mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('emergency_contact')}</div>
            <TextField name="emergency_contact" value={formApplication.emergency_contact} disabled={true} />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('emergency_phone')}</div>
            <TextField name="emergency_phone_no" value={formApplication.emergency_phone_no} disabled={true} />
          </div>
        </div>
      </div>

      <div className="group-input">
        <div className="header-group-input mb-1-rem">{t('education_information')}</div>
        <div className="divider"></div>
        {formApplication?.education?.map((v, i) => (
          <div className="input-new mb-1-rem" key={v.key}>
            <div className="input-new-2">
              <div className="input-header">{v.school_type}</div>
              <TextField name={`school_name_${i}`} value={v.school_name} disabled={true} />
            </div>
            <div className="input-new-2">
              <div className="input-header">{t('entry_year')}</div>
              <Dropdown
                name={`education_entry_year_${i}`}
                placeHolder={t('entry_year')}
                optionList={listYear}
                value={v.entry_year}
                disabled={true}
              />
            </div>
            <div className="input-new-2">
              <div className="input-header">{t('graduate_year')}</div>
              <Dropdown
                name={`education_graduate_year_${i}`}
                placeHolder={t('graduate_year')}
                optionList={listYear}
                value={v.graduate_year}
                disabled={true}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="group-input">
        <div className="header-group-input mb-1-rem">{t('university')}</div>
        <div className="divider"></div>

        {formApplication?.university?.map((v, i) => (
          <div>
            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('certificate_level')}</div>
                <Dropdown
                  name={`certificate_level_${i}`}
                  optionList={initCertificationLevel}
                  value={v.certificate_level}
                  disabled={true}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('Field_of_study')}</div>
                <TextField name={`field_study_${i}`} value={v.field_study} disabled={true} />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('university')}</div>
                <TextField name={`university_name_${i}`} value={v.university_name} disabled={true} />
              </div>
            </div>
            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('entry_year')}</div>
                <Dropdown
                  name={`university_entry_year_${i}`}
                  placeHolder={t('entry_year')}
                  optionList={listYear}
                  value={v.entry_year}
                  disabled={true}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('graduate_year')}</div>
                <Dropdown
                  name={`university_graduate_year_${i}`}
                  placeHolder={t('graduate_year')}
                  optionList={listYear}
                  value={v.graduate_year}
                  disabled={true}
                />
              </div>
              <div className="input-new-2 d-flex align-items-center">
                <div></div>
                <img className="icon" src={iconDelete} title="Delete" />
              </div>
            </div>
          </div>
        ))}

        <ButtonOutline className="button-outline" disabled={true}>
        {t('add_major')}
        </ButtonOutline>
      </div>

      <div className="group-input">
        <div className="header-group-input mb-1-rem">{t('resume')}</div>
        <div className="divider"></div>
        <div className="input-new mb-1-rem">
          <div className="input-new-2">
            <MediaFile multiple={true} allowedTypes={allowTypes} file={toArray(filesResume)} disabled={true} />
          </div>
        </div>
      </div>

      <div className="group-input">
        <div className="header-group-input mb-1-rem">{t('work_permit')}</div>
        <div className="divider"></div>
        <div className="input-new-4 mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('visa_no')}.</div>
            <TextField name="visa_no" value={formApplication.visa_no} disabled={true} />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('visa_expire_date')}</div>
            <Date value={formApplication.visa_expire_date} disabled={true} />
          </div>
        </div>
        <div className="input-new-4 mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('work_permit_no')}.</div>
            <TextField name="work_permit_no" value={formApplication.work_permit_no} disabled={true} />
          </div>
          <div className="input-new-2">
            <div className="input-header">{t('work_permit_expire_date')}</div>
            <Date value={formApplication.workpermit_expire_date} disabled={true} />
          </div>
        </div>

        <div className="input-new-4 mb-1-rem">
          <div className="input-new-2">
            <div className="input-header">{t('upload_file')}</div>
            <MediaFile multiple={true} allowedTypes={allowTypes} file={toArray(filesWorkPermit)} disabled={true} />
          </div>
        </div>
      </div>
    </Div>
  )
}

export default ViewApplicationFormInfo
