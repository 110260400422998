import React from 'react'

import { Stack } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Date from './Date'

function DateRangesPicker2({ startDate, onChangeStartDate, endDate, onChangeEndDate }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Date value={startDate} onChange={onChangeStartDate} />
        <div>-</div>
        <Date value={endDate} onChange={onChangeEndDate} />
      </Stack>
    </LocalizationProvider>
  )
}

export default DateRangesPicker2
