import { useState } from 'react'

import styled from 'styled-components'

import ChequePaymentJournalDocument from '../../../pages/document/purchases/documents/ChequePaymentJournalDocument'
import PaymentJournalDocument from '../../../pages/document/purchases/documents/PaymentJournalDocument'
import PettyCashVoucherDocument from '../../../pages/document/purchases/documents/PettyCashVoucherDocument'
import PurchaseDocument from '../../../pages/document/purchases/documents/PurchaseDocument'
import BillingDocument from '../../../pages/document/sales/documents/BillingDocument'
import InvoiceDocument from '../../../pages/document/sales/documents/InvoiceDocument'
import QuotationDocument from '../../../pages/document/sales/documents/QuotationDocument'
import ReceiptDocument from '../../../pages/document/sales/documents/ReceiptDocument'
import Button from '../../form/button/Button'
import ButtonOutline from '../../form/button/ButtonOutline'
import DialogBox from '../../widget/combo/DialogBox'
import InputTextArea from '../../widget/element/InputTextArea'
import SizeBox from '../../widget/element/SizeBox'
import IconXClose from '../../widget/icon/IconXClose'
import ViewDoc from './ViewDoc'

export default function ApproveLanding({ docType, docId, file, onClose, onSubmit }) {
  const [isShowApproveDialog, setIsShowApproveDialog] = useState(false)
  const [isShowReJectDialog, setIsShowReJectDialog] = useState(false)

  const [comment, setComment] = useState('')

  const handleClickBtnApprove = () => {
    setIsShowApproveDialog(true)
  }
  const handleClickBtnReject = () => {
    setIsShowReJectDialog(true)
  }
  const handleClickBtnCancel = () => {
    setIsShowApproveDialog(false)
    setIsShowReJectDialog(false)
  }
  const handleClickPrint = () => {
    window.print()
  }

  const handleClickConfirm = (status) => {
    let tmpObj = {
      action: status,
      comment: comment,
    }
    onSubmit(tmpObj)
  }

  return (
    <Styled isHaveDocType={docType ? true : false}>
      <div className="wrp-navigation">
        <div className="navigation-right">
          <div className="icon-x-close" onClick={() => onClose()}>
            <IconXClose />
          </div>
        </div>
      </div>

      {!docType && <ViewDoc file={file} docId={docId} />}
      {docType == 'quotation' && (
        <div className="viewSpecialDoc">
          <div className="subViewSpecialDoc">
            <QuotationDocument id={docId} />
          </div>
        </div>
      )}
      {docType == 'billing' && (
        <div className="viewSpecialDoc">
          <div className="subViewSpecialDoc">
            <BillingDocument id={docId} />
          </div>
        </div>
      )}
      {docType == 'invoice' && (
        <div className="viewSpecialDoc">
          <div className="subViewSpecialDoc">
            <InvoiceDocument id={docId} />
          </div>
        </div>
      )}
      {docType == 'receipt' && (
        <div className="viewSpecialDoc">
          <div className="subViewSpecialDoc">
            <ReceiptDocument id={docId} />
          </div>
        </div>
      )}
      {docType == 'purchase_order' && (
        <div className="viewSpecialDoc">
          <div className="subViewSpecialDoc">
            <PurchaseDocument id={docId} />
          </div>
        </div>
      )}
      {docType == 'cheque_payment_journal' && (
        <div className="viewSpecialDoc">
          <div className="subViewSpecialDoc">
            <ChequePaymentJournalDocument id={docId} />
          </div>
        </div>
      )}
      {docType == 'payment_journal' && (
        <div className="viewSpecialDoc">
          <div className="subViewSpecialDoc">
            <PaymentJournalDocument id={docId} />
          </div>
        </div>
      )}
      {docType == 'petty_cash_voucher' && (
        <div className="viewSpecialDoc">
          <div className="subViewSpecialDoc">
            <PettyCashVoucherDocument id={docId} />
          </div>
        </div>
      )}

      <div className="wrp-bottom-bar">
        <div className="wrp-btn-bottom-bar">
          <div className="left-btn">{file?.ext != '.pdf' && <Button onClick={handleClickPrint}>Print</Button>}</div>
          <div className="right-btn">
            <Button className="btn-reject" onClick={() => handleClickBtnReject()}>
              Reject
            </Button>
            <Button onClick={() => handleClickBtnApprove()}>Approve</Button>
          </div>
        </div>
      </div>
      {isShowApproveDialog && (
        <DialogBox
          className={'modal-approve'}
          height={'min-content'}
          title={
            <div className="inner-title">
              <div className="txt-title">Approval Confirmation</div>
              <div className="btn-x-close" onClick={() => handleClickBtnCancel()}>
                <IconXClose color="#667085" />
              </div>
            </div>
          }
          body={
            <InputTextArea
              className={'my-content'}
              placeHolder="Comment"
              borderColor={'var(--Gray-300)'}
              borderWidth="1px"
              onChange={(e) => setComment(e.target.value)}
            />
          }
          isBodyScroll={false}
          footer={
            <>
              <ButtonOutline className={'btn-approval'} onClick={handleClickBtnCancel}>
                Cancel
              </ButtonOutline>
              <SizeBox width={'50px'} />
              <Button className={'btn-approval'} onClick={() => handleClickConfirm('Approve')}>
                Approve
              </Button>
            </>
          }
        />
      )}
      {isShowReJectDialog && (
        <DialogBox
          className={'modal-reject'}
          height={'min-content'}
          title={
            <div className="inner-title">
              <div className="txt-title">Rejection Confirmation</div>
              <div className="btn-x-close" onClick={() => handleClickBtnCancel()}>
                <IconXClose color="#667085" />
              </div>
            </div>
          }
          body={
            <InputTextArea
              className={'my-content'}
              placeHolder="Comment"
              borderColor={'var(--Gray-300)'}
              borderWidth="1px"
              onChange={(e) => setComment(e.target.value)}
            />
          }
          isBodyScroll={false}
          footer={
            <>
              <ButtonOutline className={'btn-approval'} onClick={handleClickBtnCancel}>
                Cancel
              </ButtonOutline>
              <SizeBox width={'50px'} />
              <Button className={'btn-approval'} onClick={() => handleClickConfirm('Reject')}>
                Reject
              </Button>
            </>
          }
        />
      )}
    </Styled>
  )
}
const Styled = styled.div`
  background-color: ${({ isHaveDocType }) => (isHaveDocType ? 'var(--Gray-500)' : 'var(--Base-Main-Bg-Web)')};
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  z-index: 999;
  .wrp-navigation {
    display: flex;
    justify-content: end !important;
    padding: 16px 32px;

    position: fixed;
    right: 0;
    top: 0;
  }
  .navigation-right {
    display: flex;
    align-items: center;
  }
  .icon-x-close {
    display: flex;
    cursor: pointer;
  }

  .wrp-bottom-bar {
    border-top: 1px solid var(--Gray-200);
    background-color: #ffffff;
    padding: 16px 24px;
  }
  .wrp-btn-bottom-bar {
    display: flex;
    justify-content: space-between;
  }
  .btn-reject {
    margin-right: 12px;
    color: var(--Primary-700);
    background-color: var(--Primary-50);
    border: 1px solid var(--Primary-200);
  }
  .wrp-bottom-btn-modal {
    display: flex;
  }

  .btn-approval {
    width: 100%;
    justify-content: center;
  }
  .my-content {
    width: 90%;
  }

  /* modal */
  .modal-reject,
  .modal-approve {
    .title-modal {
      padding: 24px 24px 20px;
      text-align: start;

      .inner-title {
        display: flex;
        justify-content: space-between;

        .txt-title {
          display: flex;
          align-items: center;
        }
        .btn-x-close {
          padding: 5px;
        }
      }
    }

    .content {
      padding: 0;
    }
    .footer {
      padding: 32px 24px 24px;
    }
  }
  .viewSpecialDoc {
    flex: 1 1 0%;
    overflow: hidden;
  }
  .subViewSpecialDoc {
    overflow-y: auto;
    height: 100%;
    padding: 24px;
  }
`
