import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { selectOfmDashboard } from '../../../redux/selectors'
import {
  getCockPit,
  getZoneOFM,
  setCockPitData,
  setLogLatestTaskData,
  getLogHistoricalTask,
  getLogLatestTask,
} from '../../../redux/actions/ofmDashboard'

import styled from 'styled-components'
import Dropdown from '../../form/Dropdown'
import Date from '../../form/Date'
import Time from '../../form/Time'
import CardTaskData1 from './CardTaskData1'
import CardTaskData2 from './CardTaskData2'
import CardTaskData3 from './CardTaskData3'
import CardTaskData4 from './CardTaskData4'
import CardTaskData5 from './CardTaskData5'
import Button from '../../form/button/Button'
import ButtonOutline from '../../form/button/ButtonOutline'
import ButtonOutlineCollapsible from '../../common/ButtonOutlineCollapsible'
import SidePanel from '../../common/SidePanel'
import Table from '../../common/Table'
import ModalViewTaskDetail from '../../../pages/operation-field/ModalViewTaskDetail'

import { TbPointFilled } from 'react-icons/tb'
import { MdOutlineAccessTime } from 'react-icons/md'
import { LiaTimesSolid } from 'react-icons/lia'
import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io'
import { useTranslation } from 'react-i18next'

// styled container
const Div = styled.div`
  .mr-12-px {
    margin-right: 12px;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  h1 {
    color: var(--Gray-900);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  .font-weight-600 {
    font-weight: 600;
  }
  .font-weight-bold {
    font-weight: bold;
  }
  .item-vertical-center {
    vertical-align: middle;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.align-items-center {
    align-items: center;
  }

  .w-24 {
    width: 24%;
  }
  .w-30 {
    width: 30%;
  }
  .w-75 {
    width: 75%;
  }
  .w-100 {
    width: 100%;
  }
`
const SearchContainer = styled.div`
  border-radius: 12px;
  border: 1px solid var(--Gray-200);
  background: var(--Gray-50);
  padding: 0.8rem;
`
const TaskContainer = styled.div`
  .task-heading {
    color: var(--Primary-700);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }
  .task-content-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 10px;
    align-items: start;
  }
  .task-info-text {
    color: var(--Gray-500);
    font-style: normal;
    font-weight: 500;
  }
`
const HistoricalLogTaskContainer = styled.div`
  padding: 2rem;
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .icon {
    cursor: pointer;
  }
  .image-log {
    max-width: 100px;
  }

  .heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
`
const LatestLogTaskContainer = styled.div`
  padding: 2rem;

  .icon {
    cursor: pointer;
  }

  .heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
`
// style component
const TabMenuContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  .tab-menu {
    position: relative;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-500);
    padding: 0.75rem;
    :hover {
      cursor: pointer;
    }
    &.active {
      color: var(--Primary-700);
      border-bottom: 2px solid var(--Primary-700);
      background: var(--Primary-50);
    }
  }

  .nest-collapse {
    position: relative;

    .nested-item {
      position: absolute;
      top: 180%;
    }
  }
`
const InputContainer = styled.div`
  margin-bottom: 1.5rem;

  &.width-10 {
    width: 10%;
  }
  &.width-20 {
    width: 20%;
  }
  .input-group {
    display: flex;
    justify-content: space-between;
  }

  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .checkbox-container {
    display: flex;
    align-items: center;

    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
  }

  .w-input {
    width: 100%;
  }

  .w-30 {
    width: 30%;
  }

  .w-input-30 {
    width: 30%;
  }
`
const CollapsePanel = styled.div`
  z-index: 99;
  border-radius: 8px;
  background: var(--Base-White);
  border: 1px solid var(--Gray-300);
  padding: 1rem;
  color: var(--Gray-700);

  min-width: 200px;
  max-width: auto;

  &.zone-collapse {
    position: absolute;
    top: 180%;
    left: 0;
  }

  .collapse-item {
    padding: 0.5rem;
    border-radius: 8px;

    &.active {
      cursor: pointer;
      background: var(--Gray-200);
    }
    &:hover {
      cursor: pointer;
      background: var(--Gray-200);
    }
  }
  .collapse-heading {
    color: var(--Primary-600);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }
`

const initDataTask1 = [
  {
    task_name: '[หม้อต้มน้ำลูกที่ 1A] P1',
    min: 0,
    max: 20,
    value: 13,
    over: 12,
    standard: '10-12',
    under: 10,
  },
  {
    task_name: '[หม้อต้มน้ำลูกที่ 1A] P2',
    min: 0,
    max: 20,
    value: 7.5,
    over: 7.8,
    standard: '7.4-7.8',
    under: 7.4,
  },
  {
    task_name: '[หม้อต้มน้ำลูกที่ 1A] P3',
    min: 0,
    max: 100,
    value: 71.5,
    over: 75,
    standard: '72-75',
    under: 72,
  },
  {
    task_name: '[หม้อต้มน้ำลูกที่ 1A] P4',
    min: 0,
    max: 20,
    value: 11,
    over: 12,
    standard: '10-12',
    under: 10,
  },
  {
    task_name: '[หม้อต้มน้ำลูกที่ 1A] P1',
    min: 0,
    max: 20,
    value: 13,
    over: 12,
    standard: '10-12',
    under: 10,
  },
  {
    task_name: '[หม้อต้มน้ำลูกที่ 1A] P2',
    min: 0,
    max: 20,
    value: 7.5,
    over: 7.8,
    standard: '7.4-7.8',
    under: 7.4,
  },
  {
    task_name: '[หม้อต้มน้ำลูกที่ 1A] P3',
    min: 0,
    max: 100,
    value: 71.5,
    over: 75,
    standard: '72-75',
    under: 72,
  },
  {
    task_name: '[หม้อต้มน้ำลูกที่ 1A] P4',
    min: 0,
    max: 20,
    value: 11,
    over: 12,
    standard: '10-12',
    under: 10,
  },
]
const initDataTask2 = [
  {
    task_name: 'ชุดกวนถังจ่าย Lime Sucrate',
    min: 0,
    max: 20,
    value: 13,
    over: 12,
    standard: '10-12',
    under: 10,
  },
  {
    task_name: 'ชุดกวนถังจ่าย Lime Sucrate',
    min: 0,
    max: 20,
    value: 7.5,
    over: 7.8,
    standard: '7.4-7.8',
    under: 7.4,
  },
  {
    task_name: 'ชุดกวนถังจ่าย Lime Sucrate',
    min: 0,
    max: 100,
    value: 71.5,
    over: 75,
    standard: '72-75',
    under: 72,
  },
  {
    task_name: 'ชุดกวนถังจ่าย Lime Sucrate',
    min: 0,
    max: 20,
    value: 11,
    over: 12,
    standard: '10-12',
    under: 10,
  },
  {
    task_name: 'ชุดกวนถังจ่าย Lime Sucrate',
    min: 0,
    max: 20,
    value: 13,
    over: 12,
    standard: '10-12',
    under: 10,
  },
  {
    task_name: 'ชุดกวนถังจ่าย Lime Sucrate',
    min: 0,
    max: 20,
    value: 7.5,
    over: 7.8,
    standard: '7.4-7.8',
    under: 7.4,
  },
  {
    task_name: 'ชุดกวนถังจ่าย Lime Sucrate',
    min: 0,
    max: 100,
    value: 71.5,
    over: 75,
    standard: '72-75',
    under: 72,
  },
  {
    task_name: 'ชุดกวนถังจ่าย Lime Sucrate',
    min: 0,
    max: 20,
    value: 11,
    over: 12,
    standard: '10-12',
    under: 10,
  },
]
const initShift = [
  {
    text: '1',
    value: 1,
  },
  {
    text: '2',
    value: 2,
  },
]

function Cockpit() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { company_info } = JSON.parse(localStorage.getItem('admin'))
  const lang = localStorage.getItem('lang')
  const { cockPitData, zoneOFM, historicalLogTask, latestLogTask } = useSelector(selectOfmDashboard)
  const [zoneId, setZoneId] = useState('')
  const [baseSelected, setBaseSelected] = useState('')
  const [zoneSelected, setZoneSelected] = useState('')
  const [tab, setTab] = useState('base')
  const [DataTask1, setDataTask1] = useState([])
  const [DataTask2, setDataTask2] = useState([])
  const [cockpitData, setCockpitData] = useState([])
  // latest log
  const [historicalTaskLogs, setHistoricalTaskLogs] = useState([])
  const [historicalTaskLogColumn, setHistoricalTaskLogColumn] = useState([])
  // list
  const [baseList, setBaseList] = useState([])
  const [taskList, setTaskList] = useState([])
  // filter
  const [isFilter, setIsFilter] = useState(false)
  const [date, setDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [taskSelected, setTaskSelected] = useState('all')
  const [shift, setShift] = useState('')
  // collapse list
  const [openZone, setOpenZone] = useState(false)
  const [openBase, setOpenBase] = useState(false)
  const [openBase2, setOpenBase2] = useState(false)
  // side panel
  const [latestLogSidePanel, setLatestLogSidePanel] = useState(false)
  const [historicalLogSidePanel, setHistoricalLogSidePanel] = useState(false)

  const fetchZoneOFM = useCallback(() => {
    dispatch(getZoneOFM())
  }, [dispatch])
  const fetchCockPit = useCallback(
    (id, filter = {}) => {
      dispatch(getCockPit(id, filter))
    },
    [dispatch]
  )
  const openHistoricalLog = useCallback(
    (id) => {
      dispatch(getLogHistoricalTask(id))
      setHistoricalLogSidePanel(true)
    },
    [dispatch]
  )
  const openLatestLog = useCallback(
    (id) => {
      dispatch(getLogLatestTask(id))
      setLatestLogSidePanel(true)
    },
    [dispatch]
  )

  useEffect(() => {
    fetchZoneOFM()

    return () => {
      dispatch(setCockPitData([]))
    }
  }, [])

  useEffect(() => {
    if (historicalLogTask[0]) {
      // set heading column
      const { documents } = historicalLogTask[0]
      const mapHeaderAndValue = documents.map((v, i) => {
        const { properties } = v.form.json_schema
        const formattedArray = Object.entries(properties).map(([key, value]) => {
          return {
            Header: value.title,
            accessor: key,
          }
        })
        return formattedArray
      })
      const restNestedItemList = mapHeaderAndValue.map((nestedItem, i) => {
        const restNestedItem = nestedItem.map((v) => {
          const { Header, accessor } = v
          return { Header, accessor: `${accessor}_${i}` }
        })

        const finalRestNestedItem = [
          ...restNestedItem,
          { Header: 'หมายเหตุ', accessor: `note_${i}` },
          { Header: 'image', accessor: `images_${i}` },
        ]
        return finalRestNestedItem
      })
      const mergeNestedArray = [].concat(...restNestedItemList)
      setHistoricalTaskLogColumn(mergeNestedArray)

      // set value
      let finalMapValue = []
      for (let historicalLog of historicalLogTask) {
        const mapValue = historicalLog?.documents.map((v) => {
          return v.value
        })

        const completedMapValue = mapValue.map((v, i) => {
          const finalMapValue = {}
          Object.entries(v)?.forEach(([key, value]) => {
            if (key === 'images') {
              finalMapValue[`${key}_${i}`] = value[0] ? (
                <img key={`${key}_${i}`} className="image-log" src={value[0]} />
              ) : (
                '-'
              )
            } else {
              finalMapValue[`${key}_${i}`] = value ? value : '-'
            }
          })

          return finalMapValue
        })

        const result = {}
        completedMapValue.forEach((v) => {
          Object.entries(v).forEach(([key, value]) => {
            result[key] = value
          })
        })
        finalMapValue.push(result)
      }
      setHistoricalTaskLogs(finalMapValue)
    }
  }, [historicalLogTask])

  useEffect(() => {
    const newCockPitData = cockPitData.map((v) => {
      const listAction = [
        {
          text: t('latest_info'),
          action: () => openLatestLog(v.id),
        },
        {
          text: t('historical_info'),
          action: () => openHistoricalLog(v.id),
        },
      ]
      return {
        ...v,
        listAction,
      }
    })
    setCockpitData(newCockPitData)
  }, [cockPitData, openHistoricalLog, openLatestLog])

  useEffect(() => {
    if (isFilter === false) {
      const newTaskList = cockPitData.map((v) => ({
        text: v.name,
        value: v.id,
      }))
      setTaskList([
        {
          text: 'All',
          value: 'all',
        },
        ...newTaskList,
      ])
    }
  }, [cockPitData, isFilter])

  useEffect(() => {
    const newDataTask1 = initDataTask1.map((v, i) => ({
      ...v,
      id: i + 1,
    }))
    setDataTask1(newDataTask1)
  }, [initDataTask1])
  useEffect(() => {
    const newDataTask2 = initDataTask2.map((v, i) => ({
      ...v,
      id: i + 1,
    }))
    setDataTask2(newDataTask2)
  }, [initDataTask2])

  // toggle tab and dropdown
  const toggleTab = (tab) => {
    if (tab === 'overview') {
      dispatch(setCockPitData([]))
      setBaseSelected('')
      clearFilter()
    }
    setOpenZone(false)
    setOpenBase(false)
    setOpenBase2(false)
    setTab(tab)
  }
  const toggleTabCollapseForZone = (tab) => {
    setOpenZone(!openZone)
    setOpenBase(false)
    setOpenBase2(false)
    setTab(tab)
  }
  const toggleTabCollapseForBase = (tab) => {
    setOpenBase(!openBase)
    setOpenBase2(false)
    setOpenZone(false)
    setTab(tab)
  }
  const handleActionZone = (id) => {
    setZoneId(id)
    dispatch(setCockPitData([]))
    setBaseSelected('')
    clearFilter()
    setOpenZone(false)
  }
  const handleActionZoneForBase = (data) => {
    setZoneSelected({
      id: data.id,
      name: data.name,
    })
    setOpenBase2(true)
    setBaseList(data.bases)
  }
  const handleActionBase = (base) => {
    const currentDate = dayjs().format('YYYY-MM-DD')
    setDate(currentDate)
    const filter = {
      date: currentDate,
    }
    fetchCockPit(base.id, filter)
    setBaseSelected(base)
    setTaskSelected('all')
    setIsFilter(false)
    setOpenBase(false)
    setOpenBase2(false)
  }
  const onChangeTaskSelected = (value) => {
    setIsFilter(true)
    if (value === 'all') fetchCockPit(baseSelected.id)
    else {
      const filter = {
        taskId: value,
      }
      fetchCockPit(baseSelected.id, filter)
    }
    setTaskSelected(value)
  }

  // onchange
  const handleDateChange = (value) => {
    setDate(value)
  }
  const handleTimeChangeStartTime = (value) => {
    setStartTime(value)
  }
  const handleTimeChangeEndTime = (value) => {
    setEndTime(value)
  }

  // search
  const onSearch = () => {
    let startT = ''
    let endT = ''
    if (startTime !== '') {
      const parsedTime = dayjs(startTime, 'HH:mm:ss.SSS')
      startT = parsedTime.format('HH:mm:ss')
    }
    if (endTime !== '') {
      const parsedTime = dayjs(endTime, 'HH:mm:ss.SSS')
      endT = parsedTime.format('HH:mm:ss')
    }

    const filter = {
      date: date,
      startTime: startT,
      endTime: endT,
      shift: shift,
    }

    fetchCockPit(baseSelected.id, filter)
  }
  const onClear = () => {
    const currentDate = dayjs().format('YYYY-MM-DD')
    const filter = {
      date: currentDate,
    }
    fetchCockPit(baseSelected.id, filter)
    clearFilter()
  }
  const clearFilter = () => {
    const currentDate = dayjs().format('YYYY-MM-DD')
    setDate(currentDate)
    setStartTime('')
    setEndTime('')
    setShift('')
  }

  return (
    <Div>
      <h1>{t('cockpit')}</h1>

      <TabMenuContainer>
        {/* <div>
          <span className={`tab-menu ${tab === 'overview' ? 'active' : ''}`} onClick={() => toggleTab('overview')}>
            Overview
          </span>
        </div> */}
        {/* <div className="nest-collapse">
          <span
            className={`tab-menu ${tab === 'zone' ? 'active' : ''}`}
            onClick={() => toggleTabCollapseForZone('zone')}
          >
            Zone <IoIosArrowDown className="item-vertical-center" />
          </span>
          {openZone && (
            <CollapsePanel className="zone-collapse">
              {zoneOFM.map((v) => (
                <div key={v.id} className="collapse-item" onClick={() => handleActionZone(v.id)}>
                  {v.name}
                </div>
              ))}
            </CollapsePanel>
          )}
        </div> */}

        <div className="nest-collapse">
          <span
            className={`tab-menu ${tab === 'base' ? 'active' : ''}`}
            onClick={() => toggleTabCollapseForBase('base')}
          >
            {t('base')} {baseSelected != '' ? ` : ${baseSelected.name}` : ''}
            <IoIosArrowDown className="item-vertical-center" />
          </span>
          {openBase && (
            <FlexContainer className="nested-item">
              <CollapsePanel>
                <div className="collapse-heading">{t('scan_qr_code_zone')}</div>
                {zoneOFM.map((v) => (
                  <div key={v.id} className="collapse-item" onClick={() => handleActionZoneForBase(v)}>
                    <FlexContainer className="justify-content-space-between align-items-center">
                      <span>{v.name}</span> <IoIosArrowForward />
                    </FlexContainer>
                  </div>
                ))}
              </CollapsePanel>
              {openBase2 && (
                <CollapsePanel>
                  <div className="collapse-heading">{t('base')}</div>
                  {baseList.map((v) => (
                    <div
                      key={v.id}
                      className={`collapse-item ${v.id === baseSelected.id ? 'active' : ''}`}
                      onClick={() => handleActionBase(v)}
                    >
                      <FlexContainer className="justify-content-space-between align-items-center">
                        <span>{v.name}</span>
                      </FlexContainer>
                    </div>
                  ))}
                </CollapsePanel>
              )}
            </FlexContainer>
          )}
        </div>
      </TabMenuContainer>

      <section>
        <SearchContainer className="mb-1-rem">
          <FlexContainer className="align-items-center">
            <InputContainer className="width-20 mr-12-px">
              <div className="input-header">{t('select_date')}</div>
              <Date className="w-input" value={date} onChange={handleDateChange} />
            </InputContainer>
            <InputContainer className="mr-12-px">
              <div className="input-header">{t('start_time')}</div>
              <Time className="w-input" value={startTime} onChange={handleTimeChangeStartTime} />
            </InputContainer>
            <InputContainer className="mr-12-px">
              <div className="input-header">{t('end_time')}</div>
              <Time className="w-input" value={endTime} onChange={handleTimeChangeEndTime} />
            </InputContainer>
            <InputContainer className="width-10 mr-12-px">
              <div className="input-header">{t('shift_no')}.</div>
              <Dropdown
                className="w-input"
                optionList={initShift}
                value={shift}
                onChange={(value) => setShift(value)}
              />
            </InputContainer>
            {/* <div>
              Local Time: <span className="font-weight-600">2023-10-12 12:32</span>
            </div> */}
            <Button className="mr-12-px" onClick={onSearch}>
              {t('search')}
            </Button>
            <ButtonOutline onClick={onClear}>{t('clear')}</ButtonOutline>
          </FlexContainer>
        </SearchContainer>

        {cockPitData.length > 0 && (
          <React.Fragment>
            <FlexContainer className="justify-content-space-between align-items-center">
              <h2>{baseSelected.name}</h2>
              <InputContainer className="width-20">
                <div className="input-header">{t('jump_to')}</div>
                <Dropdown
                  className="w-input"
                  optionList={taskList}
                  value={taskSelected}
                  onChange={onChangeTaskSelected}
                />
              </InputContainer>
            </FlexContainer>

            {cockpitData.map((ofm) => (
              <TaskContainer key={ofm.id}>
                <FlexContainer className="justify-content-space-between align-items-center">
                  <h2 className="task-heading">
                    <TbPointFilled className="item-vertical-center" />
                    {ofm.name}
                  </h2>

                  <ButtonOutlineCollapsible
                    id="create"
                    text={t('logs')}
                    icon={<MdOutlineAccessTime />}
                    list={ofm.listAction}
                  />
                </FlexContainer>

                <FlexContainer className="mb-1-rem">
                  {ofm.properties
                    .filter((property) => property.value.chart_type === 'text')
                    .map((property, i) => (
                      <div key={property.value.title}>
                        <span className="font-weight-bold">{property.value.title}:</span> {property.value.value}
                        {i != ofm.properties.filter((property) => property.value.chart_type === 'text').length - 1 &&
                          ','}
                      </div>
                    ))}
                </FlexContainer>

                {ofm.properties.length > 0 ? (
                  <React.Fragment>
                    <div className="task-content-container mb-1-rem">
                      {ofm.properties
                        .filter((property) => property.value.chart_type === 'name')
                        .map((property) => {
                          return <CardTaskData4 key={property.key} taskData={property.value} />
                        })}
                    </div>

                    <div className="task-content-container mb-1-rem">
                      {ofm.properties
                        .filter((property) => property.value.chart_type === 'gauge')
                        .map((property) => {
                          return property.value.chart_type === 'gauge' ? (
                            <CardTaskData1 key={property.key} taskData={property.value} />
                          ) : property.value.chart_type === 'score' ? (
                            <CardTaskData5 key={property.key} taskData={property.value} />
                          ) : (
                            <CardTaskData1 key={property.key} taskData={property.value} />
                          )
                        })}
                    </div>

                    <div className="task-content-container mb-1-rem">
                      {ofm.properties
                        .filter((property) => property.value.chart_type === 'score')
                        .map((property) => {
                          return <CardTaskData5 key={property.key} taskData={property.value} />
                        })}
                    </div>

                    <div className="task-content-container mb-1-rem">
                      {ofm.properties
                        .filter((property) => property.value.chart_type === 'status')
                        .map((property) => {
                          return <CardTaskData3 key={property.key} taskData={property.value} />
                        })}
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="task-info-text">{t('no_data_mornitoring')}</div>
                )}
              </TaskContainer>
            ))}

            {/* <TaskContainer>
              <h2 className="task-heading">
                <TbPointFilled className="item-vertical-center" />
                ตรวจสอบเครื่องจักร งานระบบห้องควบคุม
              </h2>
              <div className="task-content-container">
                {DataTask2.map((v, i) => (
                  <CardTaskData2 key={v.id} taskData={v} />
                ))}
              </div>
            </TaskContainer> */}

            {/* <TaskContainer>
              <h2 className="task-heading">
                <TbPointFilled className="item-vertical-center" />
                ตรวจสอบเครื่องจักร งาน ระบบหม้อต้ม
              </h2>
              <div className="task-content-container">
                {DataTask2.map((v, i) => (
                  <CardTaskData3 key={v.id} taskData={v} />
                ))}
              </div>
            </TaskContainer> */}
          </React.Fragment>
        )}
      </section>

      {/* Side panel */}
      <SidePanel isOpen={latestLogSidePanel} setIsOpen={setLatestLogSidePanel} width={800}>
        {latestLogSidePanel && Object.entries(latestLogTask).length > 0 && (
          <ModalViewTaskDetail
            width={800}
            onClose={() => {
              setLatestLogSidePanel(false)
              dispatch(setLogLatestTaskData({}))
            }}
            onSubmit={() => {
              setLatestLogSidePanel(false)
              dispatch(setLogLatestTaskData({}))
            }}
            ReportId={latestLogTask?.report?.id}
            ReportName={latestLogTask?.name}
            ReportInfoFooter={
              <div>
                <span>{lang === 'en' ? company_info?.company_name_EN : company_info?.company_name_TH}</span>,
                <span>{zoneSelected?.name}</span>,<span>{baseSelected?.name}</span>
              </div>
            }
            isEdit={false}
          />
        )}
      </SidePanel>
      <SidePanel isOpen={historicalLogSidePanel} setIsOpen={setHistoricalLogSidePanel} width={900}>
        <HistoricalLogTaskContainer>
          <FlexContainer className="justify-content-space-between align-items-center mb-1-rem">
            <div className="heading">{t('historical_info')}</div>
            <LiaTimesSolid size={20} className="icon" onClick={() => setHistoricalLogSidePanel(false)} />
          </FlexContainer>
          {historicalTaskLogColumn.length > 0 && (
            <Table data={historicalTaskLogs} columns={historicalTaskLogColumn} page={1} pageCount={1} />
          )}
        </HistoricalLogTaskContainer>
      </SidePanel>
    </Div>
  )
}

export default Cockpit
