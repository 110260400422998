import { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import failIcon from '../../../assets/images/fail-icon.png'
import { requestDocumentApprove } from '../../../redux/actions/document'
import { getAdminUser } from '../../../utils/common'
import DialogFail from '../../dialog/DialogFail'
import TextField from '../../form/TextField'
import Button from '../../form/button/Button'
import ButtonOutline from '../../form/button/ButtonOutline'
import IconChevronLeft from '../icon/IconChevronLeft'
import IconXClose from '../icon/IconXClose'
import SearchSuggestEmployee from './SearchSuggestEmployee'

export default function DocumentRequestApprove({
  onClickBtnSubmit,
  onClickNavigationBack,
  id,
  docType = 'quotation',
}) {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))

  const [topic, setTopic] = useState('')
  const [selectedRequestData, setSelectedRequestData] = useState({})
  const [isShowFailedModal, setIsShowFailedModal] = useState(false)

  const handleClickNavigationBack = () => {
    if (onClickNavigationBack) {
      onClickNavigationBack()
    }
  }
  const handleClickBtnSubmit = async () => {
    if (Object.keys(employee_info).length > 0) {
      if (selectedRequestData.id && topic != '') {
        let data = {
          title: topic,
          type: 'Document Approval',
          doc_type: docType,
          reference: id,
          request_to: selectedRequestData.id,
          created_by: employee_info.id,
        }

        const request = { data }

        dispatch(requestDocumentApprove(request)).then(() => {
          if (onClickBtnSubmit) {
            onClickBtnSubmit()
          }
        })
      } else {
        alert('กรุณากรอกข้อมูลให้ครบถ้วน')
      }
    } else {
      setIsShowFailedModal(true)
    }
  }

  return (
    <Styled>
      <div className="wrp-navigation">
        <div className="navigation-left">
          <div className="icon-chevron-left" onClick={() => handleClickNavigationBack()}>
            <IconChevronLeft width="10px" />
          </div>
          <div className="txt-navigation-left">Request Approve</div>
        </div>
        <div className="navigation-right">
          <div className="icon-x-close" onClick={() => handleClickNavigationBack()}>
            <IconXClose />
          </div>
        </div>
      </div>

      <div className="wrp-inputs">
        <div className="wrp-search-suggest">
          <SearchSuggestEmployee placeHolder="Request to" onSelectedSuggest={(data) => setSelectedRequestData(data)} />
          <TextField className="input-email" disabled placeholder="Email" value={selectedRequestData?.email || ''} />
        </div>
        <div className="wrp-input-text-area">
          <TextField
            className={'request-topic'}
            placeholder={'Request Topic'}
            onChange={(e) => setTopic(e.target.value)}
          />
        </div>
      </div>

      <div className="wrp-btn-cancel-submit">
        <ButtonOutline className="btn-cancel" onClick={() => handleClickNavigationBack()}>
          Cancel
        </ButtonOutline>
        <Button onClick={() => handleClickBtnSubmit()}>Submit</Button>
      </div>
      {isShowFailedModal && (
        <DialogFail
          open={Boolean(isShowFailedModal)}
          onClose={() => setIsShowFailedModal(false)}
          onSubmit={() => setIsShowFailedModal(false)}
          icon={failIcon}
          title={'You may not fully use this feature, since you are not employee.'}
          nameItem={''}
          description={''}
          textYes="Ok"
        />
      )}
    </Styled>
  )
}
const Styled = styled.div`
  position: absolute;
  background-color: #fff;
  inset: 0;
  display: flex;
  flex-direction: column;

  .wrp-navigation {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    border-bottom: 1px solid var(--Gray-300);
  }
  .navigation-left {
    display: flex;
  }
  .icon-chevron-left {
    display: flex;
    margin-right: 1rem;
    cursor: pointer;
  }
  .txt-navigation-left {
    color: gray;
    font-weight: bold;
    font-size: 20px;
  }
  .navigation-right {
    display: flex;
    align-items: center;
  }
  .icon-x-close {
    display: flex;
    cursor: pointer;
  }

  .wrp-inputs {
    padding: 32px;
    flex: 1;
  }
  .wrp-search-suggest {
    display: flex;
  }
  .search-suggest {
    margin-right: 10px;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  .dropdown {
    width: 100%;
    margin-right: 16px;
  }

  .wrp-input-text-area {
    margin-top: 16px;
  }

  .wrp-btn-cancel-submit {
    text-align: end;
    border-top: 1px solid var(--Gray-300);
    padding: 16px 32px;
  }
  .btn-cancel {
    margin-right: 16px;
  }

  .input-email {
    width: 100%;
  }
  .request-topic {
    width: 100%;
  }
`
