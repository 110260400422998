import { Stack } from '@mui/material'
import { MdOutlineWorkOutline } from 'react-icons/md'

import { APPROVER } from 'constants/operation-process'
import { Chip } from '.'

export default function Approval({ data: approver }) {
  const { employee } = approver
  const { first_name, last_name, department } = employee || {}
  const { name: departmentName } = department || {}

  return (
    <>
      <Stack>
        <p className="title">{first_name || last_name ? first_name + ' ' + last_name : '-'}</p>
        {/* Position */}
        <Stack direction="row" gap="0.5rem">
          <MdOutlineWorkOutline size={16} />
          <p>{departmentName || '-'}</p>
        </Stack>
      </Stack>

      {approver?.status && <Chip label={approver.status} color={APPROVER.STATUS_CHIP[approver.status]} />}
    </>
  )
}
