import { getAPI, postAPI, putAPI, deleteAPI } from '../../utils/apiofm'
import {
    GET_ZoneOFM,
    ADD_USERJOB,
    DEL_USERJOB,
    UPDATE_ReportOFM
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getZoneReturnData = (filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_ZoneOFM.REQUEST })
      const { token, user } = JSON.parse(localStorage.getItem('admin'))
      
      const headers = {
        Authorization: `${token}`,
        'Content-Type': 'application/json',
      }

      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'fields': 'name',
      }
      /*
      if(filter.active){
        params = {...params,'filters[active]': filter.active}
      }
      if(filter.meeting_id){
        params = {...params,'filters[meeting_id]': filter.meeting_id}
      }
      if(filter.actual_attend){
        params = {...params,'filters[actual_attend]': filter.actual_attend}
      }
      */
      
      const { data, meta } = await getAPI({
        url: '/api/zones',
        headers: headers,
        params,
      })
      return {type : "getZoneReturnData_SUCCESS" , data_info : data , data_meta : meta};
    } catch (error) {
      return {type : "getZoneReturnData_ERROR"};
    } finally {
      dispatch(hideLoading())
    }
} 

export const getBaseByZoneIdReturnData = (id,filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ZoneOFM.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
      'fields': 'name',
      'populate[bases][fields]' : 'name'
    }
    
    const { data, meta } = await getAPI({
      url: `/api/zones/${id}`,
      headers: headers,
      params,
    })
    return {type : "getBaseByZoneIdReturnData_SUCCESS" , data_info : data , data_meta : meta};
  } catch (error) {
    return {type : "getBaseByZoneIdReturnData_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const getTaskByBaseIdReturnData = (id,filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ZoneOFM.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
      'fields': 'name',
      'populate[zone][fields][0]' : 'name',
      'populate[tasks][fields][0]' : 'task_id',
      'populate[tasks][fields][1]' : 'name',
    }
    
    const { data, meta } = await getAPI({
      url: `/api/bases/${id}`,
      headers: headers,
      params,
    })
    return {type : "getTaskByBaseIdReturnData_SUCCESS" , data_info : data , data_meta : meta};
  } catch (error) {
    return {type : "getTaskByBaseIdReturnData_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
} 


export const getEmployeeByTaskReturnData = (id,filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ZoneOFM.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    let search_first_name = '';
    
    if(filter.other_search){
      
      const data_search = filter.other_search.split(',');
      const data_test = data_search.map((v) => `filters[$or][0][first_name][$contains]=${v}`).join('&');
      search_first_name = '?' + data_test;
      /*
      let list_value = {};
      const data_search = filter.other_search.split(',');
      const newState_sub = data_search.map((obj_sub, index_sub) => {
        search_first_name = {...search_first_name ,'filters[$or][0][first_name][$contains]' : obj_sub };
        return {
          [`filters[$or][0][first_name][$contains]`] : obj_sub,
        }
      })
      */
      /*
      for(const item_newState_sub of newState_sub){
        alert('-------')
        alert(JSON.stringify(item_newState_sub))
        list_value = {...list_value , ...item_newState_sub};
      }
      alert('---ENDNDNDNDDNDN---')
      alert(JSON.stringify(list_value))
      alert('---ENDNDNDNDDNDN--ENDNDNDNDDNDNENDNDNDNDDNDNENDNDNDNDDNDNENDNDNDNDDNDN-')
      alert(JSON.stringify(data_search.map((v) => v).join(',')))
      */
    }

    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
      'fields[0]': 'employ_id',
      'fields[1]': 'first_name',
      'fields[2]': 'last_name',
      'fields[3]': 'phone_no',
      'fields[4]': 'email',
      'populate[department][fields]': 'name',
      'populate[position][fields]': 'name',
    }
    //alert(JSON.stringify(params))
    
    const { data, meta } = await getAPI({
      url: `/api/tasks/${id}/employees/new${search_first_name}`,
      headers: headers,
      params,
    })
    return {type : "getEmployeeByTaskReturnData_SUCCESS" , data_info : data , data_meta : meta};
  } catch (error) {
    return {type : "getEmployeeByTaskReturnData_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
} 


export const AddUserToTask = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: ADD_USERJOB.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/tasks/${id}/employees`,
      data,
      headers: headers,
    })

    return {type : "AddUserToTask_SUCCESS"};
  } catch (err) {
    return {type : "AddUserToTask_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const getTaskDetailReturnData = (id,filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ZoneOFM.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
      'fields[0]': 'task_id',
      'fields[1]' : 'name',
      'populate[base][fields]' : 'name',
      'populate[base][populate][zone][fields]' : 'name',
    }
    
    const { data, meta } = await getAPI({
      url: `/api/tasks/${id}`,
      headers: headers,
      params,
    })
    return {type : "getTaskDetailReturnData_SUCCESS" , data_info : data , data_meta : meta};
  } catch (error) {
    return {type : "getTaskDetailReturnData_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
} 

export const getEmployeeListByTaskReturnData = (id,filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ZoneOFM.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
      'fields[0]': 'employ_id',
      'fields[1]': 'first_name',
      'fields[2]': 'last_name',
      'fields[3]': 'phone_no',
      'fields[4]': 'email',
      'fields[5]': 'employee_type',
      'fields[6]': 'evaluation_date',
      'fields[7]': 'status',
      'populate[department][fields]': 'name',
      'populate[position][fields]': 'name',
    }
    
    const { data, meta } = await getAPI({
      url: `/api/tasks/${id}/employees`,
      headers: headers,
      params,
    })
    return {type : "getEmployeeListByTaskReturnData_SUCCESS" , data_info : data , data_meta : meta};
  } catch (error) {
    return {type : "getEmployeeListByTaskReturnData_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteEmployeeTask = (id,employee_id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DEL_USERJOB.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    await deleteAPI({
      url: `/api/tasks/${id}/employees/${employee_id}`,
      headers: headers,
    })

    return {type : "deleteEmployeeTask_SUCCESS"};
  } catch (error) {
    return {type : "deleteEmployeeTask_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}


export const getTaskDetailAllDataReturnData = (id,filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ZoneOFM.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
    }

    if(filter.employee_id){
      params = {...params,'populate[reports][filters][created_by_employee_id]': filter.employee_id}
    }

    if(filter.currentDate){
      params = {...params,'populate[reports][filters][createdAt][$startsWith]': filter.currentDate}
    }


    
    const { data, meta } = await getAPI({
      url: `/api/tasks/${id}?populate[reports][sort][0]=id:desc&fields[0]=task_id&fields[1]=name&populate[forms][populate][machine][fields]=name&populate[reports][fields]=createdAt&populate[reports][filters][status]=active&populate[reports][populate][documents]=true`,
      headers: headers,
      params,
    })
    return {type : "getTaskDetailAllDataReturnData_SUCCESS" , data_info : data , data_meta : meta};
  } catch (error) {
    return {type : "getTaskDetailAllDataReturnData_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const getReportDetailReturnData = (id,filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ZoneOFM.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
    }
    
    const { data, meta } = await getAPI({
      url: `/api/reports/${id}?populate[documents]=true`,
      headers: headers,
      params,
    })
    return {type : "getReportDetailReturnData_SUCCESS" , data_info : data , data_meta : meta};
  } catch (error) {
    return {type : "getReportDetailReturnData_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const getDeletionRequestsReturnData = (id,filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ZoneOFM.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
      /*'filters[report_log][report][task][base][zone]' : id*/
    }

    if(filter.base){
      params = {...params,'filters[report_log][report][task][base]': filter.base}
    }else{
      params = {...params,'filters[report_log][report][task][base][zone]': id}
    }

    if(filter.reason){
      params = {...params,'filters[reason][$contains]': filter.reason}
    }

    if(filter.employee_id){
      params = {...params,'filters[created_by_employee_id]': filter.employee_id}
    }

    if(filter.SubmissionDate){
      params = {...params,'filters[report_log][report][createdAt][$startsWith]': filter.SubmissionDate}
    }

    if(filter.DeletionRequestDate){
      params = {...params,'filters[createdAt][$startsWith]': filter.DeletionRequestDate}
    }
    
    const { data, meta } = await getAPI({
      url: `/api/deletion-requests?fields[0]=createdAt&fields[1]=status&fields[2]=reason&populate[report_log][fields]=id&populate[report_log][populate][report][fields]=id&populate[report_log][populate][report][populate][task][fields][0]=task_id&populate[report_log][populate][report][populate][task][fields][1]=name&populate[report_log][populate][report][populate][task][populate][base][fields]=name&filters[status]=pending&populate[report_log][populate][report][populate][task][populate][base][populate][zone][fields]=name`,
      headers: headers,
      params,
    })
    return {type : "getDeletionRequestsReturnData_SUCCESS" , data_info : data , data_meta : meta};
  } catch (error) {
    return {type : "getDeletionRequestsReturnData_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const DeletionRequestKeep = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: ADD_USERJOB.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/deletion-requests/${id}/keep`,
      headers: headers,
    })

    return {type : "DeletionRequestKeep_SUCCESS"};
  } catch (err) {
    return {type : "DeletionRequestKeep_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}


export const DeletionRequestdelete = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: ADD_USERJOB.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/deletion-requests/${id}/delete`,
      headers: headers,
    })

    return {type : "DeletionRequestKeep_SUCCESS"};
  } catch (err) {
    return {type : "DeletionRequestKeep_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const DeleteReport = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DEL_USERJOB.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }
    await deleteAPI({
      url: `/api/reports/${id}`,
      headers: headers,
    })

    return {type : "DeleteReport_SUCCESS"};
  } catch (err) {
    return {type : "DeleteReport_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const updateReport = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_ReportOFM.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/reports/${id}`,
      data,
      headers: headers,
    })

    return {type : "updateReport_SUCCESS"};
  } catch (err) {
    return {type : "updateReport_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const getArchivedAllDataReturnData = (id,filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ZoneOFM.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
    }

    if(filter.employee_id){
      params = {...params,'filters[created_by_employee_id]': filter.employee_id}
    }

    if(filter.SubmissionDate){
      params = {...params,'filters[createdAt][$startsWith]': filter.SubmissionDate}
    }


    
    const { data, meta } = await getAPI({
      url: `/api/reports?filters[status]=archived&filters[task]=${id}&populate[task]=*`,
      headers: headers,
      params,
    })
    return {type : "getArchivedAllDataReturnData_SUCCESS" , data_info : data , data_meta : meta};
  } catch (error) {
    return {type : "getArchivedAllDataReturnData_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const ArchivedPutBack = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: ADD_USERJOB.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/reports/${id}/put_back`,
      headers: headers,
    })

    return {type : "ArchivedPutBack_SUCCESS"};
  } catch (err) {
    return {type : "ArchivedPutBack_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const getActivityLogReturnData = (id,filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ZoneOFM.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
    }

    const { data, meta } = await getAPI({
      url: `/api/reports/${id}?fields=id&populate[report_logs][populate][deletion_request][fields]=reason&populate[report_logs][sort][0]=id:desc`,
      headers: headers,
      params,
    })
    return {type : "getActivityLogReturnData_SUCCESS" , data_info : data , data_meta : meta};
  } catch (error) {
    return {type : "getActivityLogReturnData_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}


export const getArchivedAllDataReturnDataByZone = (id,filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ZoneOFM.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    
    const headers = {
      Authorization: `${token}`,
      'Content-Type': 'application/json',
    }

    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
    }

    if(filter.employee_id){
      params = {...params,'filters[created_by_employee_id]': filter.employee_id}
    }

    if(filter.SubmissionDate){
      params = {...params,'filters[createdAt][$startsWith]': filter.SubmissionDate}
    }


    
    const { data, meta } = await getAPI({
      url: `/api/reports?filters[status]=archived&filters[task][base][zone]=${id}&populate[task][populate][base][populate][zone][fields]=name`,
      headers: headers,
      params,
    })
    return {type : "getArchivedAllDataReturnData_SUCCESS" , data_info : data , data_meta : meta};
  } catch (error) {
    return {type : "getArchivedAllDataReturnData_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}
