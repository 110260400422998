import { combineReducers } from 'redux'
import { DOWNLOAD_REPORT } from 'redux/actionTypes'

const initialState = {
  reports: [],
  total: 1,
  page: 1,
  pageCount: 1,
  pageSize: 10,
  isLoading: false,
  state: '',
}

const downloadReportReducer = (state = initialState, action = {}) => {
  const { type } = action
  switch (type) {
    case DOWNLOAD_REPORT.GET.GET_REPORTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_REPORTS.REQUEST',
      }
    }
    case DOWNLOAD_REPORT.GET.GET_REPORTS.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        reports: data,
        page: meta?.pagination?.page,
        pageCount: meta?.pagination?.pageCount,
        pageSize: meta?.pagination?.pageSize,
        total: meta?.pagination?.total,
        isLoading: false,
        state: 'GET_REPORTS.SUCCESS',
      }
    }
    case DOWNLOAD_REPORT.GET.GET_REPORTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_REPORTS.FAILURE',
      }
    }
    case DOWNLOAD_REPORT.POST.GENERATE_REPORT.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GENERATE_REPORT.REQUEST',
      }
    }
    case DOWNLOAD_REPORT.POST.GENERATE_REPORT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'GENERATE_REPORT.SUCCESS',
      }
    }
    case DOWNLOAD_REPORT.POST.GENERATE_REPORT.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GENERATE_REPORT.FAILURE',
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default combineReducers({
  downloadReportReducer,
})
