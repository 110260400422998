import { combineReducers } from 'redux'
import {
  GET_DailySummary,
  CREATE_DailySummary,
  UPDATE_DailySummary,
  GET_DailySummary_BY_ID,
  UPDATE_DailySummary_STATUS,

  GET_ActionPlan,
  CREATE_ActionPlan,
  UPDATE_ActionPlan,
  GET_ActionPlan_BY_ID,
  UPDATE_ActionPlan_STATUS,

  GET_MeetingRoom,
  GET_MeetingRoomAll,
  CREATE_MeetingRoom,
  UPDATE_MeetingRoom,
  GET_Meeting,
  CREATE_Meeting,
  UPDATE_Meeting,
  GET_Meeting_BY_ID,
  GET_MeetingAttendee,
  UPDATE_Meeting_attendances,
  UPDATE_ApproveMeetingAttend
} from '../actionTypes'

const initialState = {
  DailySummaryList: [],
  DailySummaryInfo: {},
  ActionPlanList: [],
  ActionPlanInfo: {},
  MeetingRoomList: [],
  MeetingList: [],
  MeetingInfo: {},
  MeetingAttendeeList: [],
  total: 0,
  skip: 0,
  limit: 10,
  MeetingRoomListAll: [],
  total_roomlist: 0,
  skip_roomlist: 0,
  limit_roomlist: 10,
  isLoading: false,
}

const dailySummaryReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_DailySummary.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DailySummary.SUCCESS: {
      const { data, meta, filter } = action
      //
      return {
        ...state,
        DailySummaryList: data,
        total: meta.pagination.total,
        skip: filter.skip,
        limit: filter.limit,
        isLoading: false,
      }
    }
    case GET_DailySummary.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_DailySummary.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_DailySummary.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_DailySummary.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case UPDATE_DailySummary.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_DailySummary.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_DailySummary.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case GET_DailySummary_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DailySummary_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        DailySummaryInfo: data,
        isLoading: false,
      }
    }
    case GET_DailySummary_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case UPDATE_DailySummary_STATUS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_DailySummary_STATUS.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_DailySummary_STATUS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case GET_ActionPlan.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ActionPlan.SUCCESS: {
      const { data, meta, filter } = action
      //
      return {
        ...state,
        ActionPlanList: data,
        total: meta.pagination.total,
        skip: filter.skip,
        limit: filter.limit,
        isLoading: false,
      }
    }
    case GET_ActionPlan.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_ActionPlan.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_ActionPlan.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_ActionPlan.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_ActionPlan.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_ActionPlan.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_ActionPlan.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_ActionPlan_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ActionPlan_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        ActionPlanInfo: data,
        isLoading: false,
      }
    }
    case GET_ActionPlan_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_ActionPlan_STATUS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_ActionPlan_STATUS.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_ActionPlan_STATUS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

case GET_MeetingRoom.REQUEST:
return {
...state,
isLoading: true,
}
case GET_MeetingRoom.SUCCESS: {
const { data, meta, filter } = action
//
return {
...state,
MeetingRoomList: data,
isLoading: false,
}
}
case GET_MeetingRoom.FAILURE:
return {
...state,
isLoading: false,
} 
case CREATE_MeetingRoom.REQUEST:
return {
...state,
isLoading: true,
}
case CREATE_MeetingRoom.SUCCESS:
return {
...state,
isLoading: false,
}
case CREATE_MeetingRoom.FAILURE:
return {
...state,
isLoading: false,
} 
case UPDATE_MeetingRoom.REQUEST:
return {
...state,
isLoading: true,
}
case UPDATE_MeetingRoom.SUCCESS: {
return {
...state,
isLoading: false,
}
}
case UPDATE_MeetingRoom.FAILURE:
return {
...state,
isLoading: false,
}    

case GET_Meeting.REQUEST:
return {
...state,
isLoading: true,
}
case GET_Meeting.SUCCESS: {
const { data, meta, filter } = action
//
return {
...state,
MeetingList: data,
total: meta.pagination.total,
skip: filter.skip,
limit: filter.limit,
isLoading: false,
}
}
case GET_Meeting.FAILURE:
return {
...state,
isLoading: false,
} 
case CREATE_Meeting.REQUEST:
return {
  ...state,
  isLoading: true,
}
case CREATE_Meeting.SUCCESS:
return {
  ...state,
  isLoading: false,
}
case CREATE_Meeting.FAILURE:
return {
  ...state,
  isLoading: false,
} 
case UPDATE_Meeting.REQUEST:
  return {
    ...state,
    isLoading: true,
  }
case UPDATE_Meeting.SUCCESS: {
  return {
    ...state,
    isLoading: false,
  }
}
case UPDATE_Meeting.FAILURE:
return {
...state,
isLoading: false,
}    
case GET_Meeting_BY_ID.REQUEST:
return {
  ...state,
  isLoading: true,
}
case GET_Meeting_BY_ID.SUCCESS: {
const { data } = action
return {
  ...state,
  MeetingInfo: data,
  isLoading: false,
}
}
case GET_Meeting_BY_ID.FAILURE:
return {
  ...state,
  isLoading: false,
}

case GET_MeetingAttendee.REQUEST:
return {
...state,
isLoading: true,
}
case GET_MeetingAttendee.SUCCESS: {
const { data, meta, filter } = action
//
return {
...state,
MeetingAttendeeList: data,
total: meta.pagination.total,
skip: filter.skip,
limit: filter.limit,
isLoading: false,
}
}
case GET_MeetingAttendee.FAILURE:
return {
...state,
isLoading: false,
}

case UPDATE_Meeting_attendances.REQUEST:
  return {
    ...state,
    isLoading: true,
  }
case UPDATE_Meeting_attendances.SUCCESS: {
  return {
    ...state,
    isLoading: false,
  }
}
case UPDATE_Meeting_attendances.FAILURE:
return {
...state,
isLoading: false,
}   

case UPDATE_ApproveMeetingAttend.REQUEST:
  return {
    ...state,
    isLoading: true,
  }
case UPDATE_ApproveMeetingAttend.SUCCESS: {
  return {
    ...state,
    isLoading: false,
  }
}
case UPDATE_ApproveMeetingAttend.FAILURE:
return {
...state,
isLoading: false,
}

case GET_MeetingRoomAll.REQUEST:
return {
...state,
isLoading: true,
}
case GET_MeetingRoomAll.SUCCESS: {
const { data, meta, filter } = action
//
return {
...state,
MeetingRoomListAll: data,
total_roomlist: meta.pagination.total,
skip_roomlist: filter.skip,
limit_roomlist: filter.limit,
isLoading: false,
}
}
case GET_MeetingRoomAll.FAILURE:
return {
...state,
isLoading: false,
} 

    default:
      return state
  }
}

export default combineReducers({
  dailySummaryReducer,
})
