import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import SidePanel from '../../../components/common/SidePanel'
import DialogFail from '../../../components/dialog/DialogFail'
import Date from '../../../components/form/Date'
import TextField from '../../../components/form/TextField'
import Button from '../../../components/form/button/Button'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import DocumentRequestApprove from '../../../components/widget/combo/DocumentRequestApprove'
import DocumentShareAndSendEmail from '../../../components/widget/combo/DoucumentShareAndSendEmail'
import DocumentOptionButton from '../../../components/widget/element/DocumentOptionButton'
import InputTextArea from '../../../components/widget/element/InputTextArea'
import ModalBox from '../../../components/widget/element/ModalBox'
import {
  createPurchase,
  editPurchaseDoc,
  getDocumentSettingByCompanyId,
  getSaleDocumentById,
} from '../../../redux/actions/document'

import DialogDocument from 'pages/document/DialogDocument'
import Table from '../../../components/common/Table'
import Checkbox from '../../../components/form/Checkbox'
import DropdownSelect from '../../../components/widget/element/DropDownSelect'
import IconBin from '../../../components/widget/icon/IconBin'
import { selectDocument } from '../../../redux/selectors'
import { calculatePriceAfterTax, removeArrayItemByIndex } from '../../../utils/helper'

export default function CreatePettyCashVoucher({ onCallback }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const viewType = searchParams.get('type')
  const navigateType = searchParams.get('navigate')
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { saleDocumentData, documentSettingInfo } = useSelector(selectDocument)

  const TABLE_HEADER_COLUMN = [
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'รายละเอียด',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'จำนวนเงิน',
      accessor: 'amount',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'delete',
      disableSortBy: false,
    },
  ]

  const DEFAULT_BODY_ITEM_DATA = {
    no: '',
    details: '',
    amount: 0,
    delete: false,

    //validate
    is_error_details: false,
    is_error_amount: false,
  }

  const [dataCreating, setDataCreating] = useState({
    company_id: company_info?.id,
    doc_type: viewType,
    details: '',
    doc_number: '',
    doc_date: '',
    payment_type: '',
    cheque_number: '',
    cheque_date: '',
    bank_name: '',
    payment_list: [],
    vat: '',
    withholding_tax_percent: '',

    number_of_copy: 1,
    company_logo: '',
    rubber_stamp: '',
    is_external: navigateType == 'external' ? true : false,
  })

  const [paymentTypeList, setPaymentTypeList] = useState([
    {
      text: 'Cash cheque',
      value: 'cash_cheque',
    },
    {
      text: 'Cash',
      value: 'cash',
    },
  ])
  const [priceTaxSelectList, setPriceTaxSelectList] = useState([
    {
      text: 'Include Tax',
      value: true,
    },
    {
      text: 'Not Include Tax',
      value: false,
    },
  ])
  const [uomSelectList, setUomSelectList] = useState([
    {
      text: 'Piece',
      value: 'piece',
    },
    {
      text: 'Box',
      value: 'box',
    },
    {
      text: 'Pack',
      value: 'pack',
    },
  ])
  const [withHoldingTaxList, setWithHoldingTaxList] = useState([
    {
      text: '0.5%',
      value: 0.5,
    },
    {
      text: '0.75%',
      value: 0.75,
    },
    {
      text: '0.1%',
      value: 0.1,
    },
    {
      text: '1.5%',
      value: 1.5,
    },
    {
      text: '2%',
      value: 2,
    },
    {
      text: '3%',
      value: 3,
    },
    {
      text: '5%',
      value: 5,
    },
    {
      text: '10%',
      value: 10,
    },
    {
      text: '15%',
      value: 15,
    },
  ])

  const [bodyTableList, setBodyTableList] = useState([])
  const [isErrorSellerId, setIsErrorSellerId] = useState(false)
  const [isErrorSellerName, setIsErrorSellerName] = useState(false)
  const [validateInputData, setValidateInputData] = useState({
    is_error_details: false,
    is_error_doc_number: false,
    is_error_doc_date: false,
    is_error_payment_type: false,
    is_error_cheque_number: false,
    is_error_cheque_date: false,
    is_error_bank_name: false,
    is_error_payment_list: false,
  })
  const [isShowFailedModal, setIsShowFailedModal] = useState(false)
  const [textSellerId, setTextSellerId] = useState('')
  const [textSellerName, setTextSellerName] = useState('')

  // const [sumTotalPrice, setTotalPrice] = useState(0)
  const [sumTotalPrice, setTotalPrice] = useState(20)

  const [priceDiscount, setPriceDiscount] = useState(0)
  const [priceAfterDiscount, setPriceAfterDiscount] = useState(0)

  const [priceAfterVat, setPriceAfterVat] = useState(0)
  const [priceWithHoldingTax, setPriceWithHoldingTax] = useState(0)
  const [summaryPrice, setSummaryPrice] = useState(0)
  const [priceVat, setPriceVat] = useState(0) //ราคา vat

  const [currentDocId, setCurrentDocId] = useState('')

  const [dialogPurchaseType, setDialogPurchaseType] = useState('')
  const [isShowDialogPreview, setIsShowDialogPreview] = useState(false)
  const [isShowModalDownload, setIsShowModalDownload] = useState(false)
  const [isShowDialogRequestApprove, setIsShowDialogRequestApprove] = useState(false)

  const [isShowModalShare, setIsShowModalShare] = useState(false)
  const [isHasSellerId, setIsHasSellerId] = useState(false)
  const [isHindModalShare, setIsHindModalShare] = useState(false)

  const [isEditPurchase, setIsEditPurchase] = useState(false)

  const [imageForDocument, setImageForDocument] = useState({
    url_company_logo: '',
    url_rubber_stamp: '',
    url_signature_file: '',
  })
  const [textPurchaseCondition, setTextPurchaseCondition] = useState('')
  const [currentDocType, setCurrentDocType] = useState('')

  useEffect(() => {
    ;(async () => {
      let url = window.location.pathname
      const myArray = url.split('/')
      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i] == 'edit') {
          setIsEditPurchase(true)
          // let tmpId = myArray[myArray.length - 1]
          let tmpId = searchParams.get('id')
          setCurrentDocType(viewType)
          setCurrentDocId(tmpId)
          dispatch(getSaleDocumentById(tmpId))
        }
        if (myArray[i] == 'create') {
          setIsEditPurchase(false)
          // let tmpDocType = myArray[myArray.length - 1]
          // console.log('tmpDocType: ' + JSON.stringify(tmpDocType, null, 2))
          // setDataCreating({ ...dataCreating, doc_type: viewType })
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (Object.keys(saleDocumentData).length > 0 && isEditPurchase) {
      let tmpData = {
        doc_type: saleDocumentData?.doc_type,
        details: saleDocumentData?.details,
        payment_list: saleDocumentData?.payment_list,
        doc_number: saleDocumentData?.doc_number,
        doc_date: saleDocumentData?.doc_date,
        payment_type: saleDocumentData?.payment_type,
        cheque_number: saleDocumentData?.cheque_number,
        cheque_date: saleDocumentData?.cheque_date,
        bank_name: saleDocumentData?.bank_name,

        vat: saleDocumentData?.vat,
        withholding_tax_percent: saleDocumentData?.withholding_tax_percent,
        withholding_tax: saleDocumentData?.withholding_tax_percent > 0 ? true : false,
        company_id: company_info?.id,

        number_of_copy: saleDocumentData?.number_of_copy,
        company_logo: saleDocumentData?.company_logo,
        rubber_stamp: saleDocumentData?.rubber_stamp,
      }

      setDataCreating(tmpData)
    }
    dispatch(getDocumentSettingByCompanyId())
  }, [saleDocumentData, isEditPurchase])

  useEffect(() => {
    if (documentSettingInfo) {
      let tmpImageForDocumentObj = { ...imageForDocument }
      // let tmpDocType = dataCreating?.doc_type

      if (viewType != '') {
        tmpImageForDocumentObj.url_company_logo = documentSettingInfo[viewType]?.company_logo
        tmpImageForDocumentObj.url_rubber_stamp = documentSettingInfo[viewType]?.rubber_stamp
        // tmpImageForDocumentObj.url_signature_file = documentSettingInfo[viewType]?.signature

        let tmpDataObj = { ...dataCreating }
        tmpDataObj.signature_list = documentSettingInfo[viewType]?.signature_list
        tmpDataObj.company_logo = documentSettingInfo[viewType]?.company_logo
        tmpDataObj.rubber_stamp = documentSettingInfo[viewType]?.rubber_stamp
        setDataCreating(tmpDataObj)
      }
      setImageForDocument(tmpImageForDocumentObj)
      setTextPurchaseCondition(documentSettingInfo?.purchase_condition)
    }
  }, [documentSettingInfo])

  useEffect(() => {
    if (dataCreating?.payment_list) {
      let tmpData = { ...dataCreating }

      const viewList = tmpData.payment_list.map((item, index) => ({
        no: index + 1,
        detail: (
          <TextField
            className={'input-table'}
            placeholder={'Details'}
            onChange={(e) => handleChangeProductList(e, 'details', index)}
            value={item.details}
            error={item?.is_error_details}
          />
        ),
        amount: (
          <TextField
            className={'input-table'}
            placeholder={'0.00'}
            onChange={(e) => handleChangeProductList(e, 'amount', index)}
            value={convertIntComma(item?.amount || 0)}
            error={item?.is_error_amount}
            pattern={'^[0-9.-/]+$'}
          />
        ),
        delete: (
          <div className="btn-delete" onClick={() => handleClickBtnDelete(index)}>
            <IconBin />
          </div>
        ),
      }))

      // calculate price
      let tmpTotal = calculateAllPayment(tmpData.payment_list)
      setTotalPrice(tmpTotal)

      // // let tmpTotal = calculateSumPrice(tmpData.payment_list)
      // // setTotalPrice(tmpTotal)

      // let { discountPrice, totalPrice } = calculateDiscount(tmpTotal, tmpData?.discount_percent || 0)
      // setPriceDiscount(discountPrice)
      // setPriceAfterDiscount(totalPrice)

      // if (tmpData?.include_tax) {
      //   let { priceAfterVat, priceVat, priceWithHoldingTax, summaryPrice } = calculatePriceAfterTax(
      //     true,
      //     tmpData?.vat,
      //     tmpData?.withholding_tax_percent || 0,
      //     totalPrice
      //   )
      //   setPriceAfterVat(priceAfterVat)
      //   setPriceVat(priceVat)
      //   setPriceWithHoldingTax(priceWithHoldingTax)
      //   setSummaryPrice(summaryPrice)
      // } else {
      let { priceAfterVat, priceVat, priceWithHoldingTax, summaryPrice } = calculatePriceAfterTax(
        false,
        tmpData?.vat,
        tmpData?.withholding_tax_percent || 0,
        tmpTotal
      )
      setPriceAfterVat(priceAfterVat)
      setPriceVat(priceVat)
      setPriceWithHoldingTax(priceWithHoldingTax)
      setSummaryPrice(summaryPrice)
      // }

      setBodyTableList(viewList)
    }
  }, [dataCreating])

  const calculateAllPayment = (paymentList) => {
    let tmpTotalPrice = 0
    for (let i = 0; i < paymentList.length; i++) {
      let price = 0
      price = paymentList[i].amount
      tmpTotalPrice = tmpTotalPrice + price
      console.log('tmpTotalPrice: ' + JSON.stringify(tmpTotalPrice, null, 2))
    }
    return tmpTotalPrice
  }
  const handleChangeData = (e, type) => {
    let tmpObj = { ...dataCreating }

    let tmpValidateObj = { ...validateInputData }

    if (type == 'details') {
      tmpObj.details = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_details = false
      }
    } else if (type == 'doc_number') {
      tmpObj.doc_number = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_doc_number = false
      }
    } else if (type == 'doc_date') {
      tmpObj.doc_date = e
      if (e) {
        tmpValidateObj.is_error_doc_date = false
      }
    } else if (type == 'cheque_number') {
      tmpObj.cheque_number = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_cheque_number = false
      }
    } else if (type == 'cheque_date') {
      tmpObj.cheque_date = e
      if (e) {
        tmpValidateObj.is_error_cheque_date = false
      }
    } else if (type == 'bank_name') {
      tmpObj.bank_name = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_bank_name = false
      }
    } else if (type == 'payment_type') {
      console.log('payment_type: ' + JSON.stringify(e, null, 2))
      tmpObj.payment_type = e
      if (e != '') {
        tmpValidateObj.is_error_payment_type = false
      }
    } else if (type == 'vat') {
      tmpObj.vat = e

      if (!e) {
        setPriceVat(0)
      }
    } else if (type == 'withholding_tax') {
      tmpObj.withholding_tax = e
      if (!e) {
        tmpObj.withholding_tax_percent = 0
        setPriceWithHoldingTax(0)
      }
    } else if (type == 'withholding_tax_percent') {
      tmpObj.withholding_tax_percent = e
    }

    console.log('tmpObj handleChangeData: ' + JSON.stringify(tmpObj, null, 2))
    setDataCreating(tmpObj)
    setValidateInputData(tmpValidateObj)
  }
  const handleChangeProductList = (e, type, index) => {
    let tmpObj = { ...dataCreating }

    if (type == 'details') {
      tmpObj.payment_list[index].details = e.target.value
      if (e.target.value != '') {
        tmpObj.payment_list[index].is_error_details = false
      }
    } else if (type == 'amount') {
      let tmpString = e.target.value
      let tmpInt = tmpString?.replace(/\D/g, '') || 0
      tmpObj.payment_list[index].amount = parseInt(tmpInt)
      if (e.target.value) {
        tmpObj.payment_list[index].is_error_amount = false
      }
    }
    console.log('tmpObj (ProductList): ' + JSON.stringify(tmpObj, null, 2))
    setDataCreating({ ...tmpObj })
  }
  const handleClickBtnDelete = (index) => {
    let tmpBodyDataList = removeArrayItemByIndex(dataCreating?.payment_list, index)
    setDataCreating({ ...dataCreating, payment_list: tmpBodyDataList })
  }
  const handleClickBtnAddList = () => {
    let tmpBodyDataList = [...dataCreating?.payment_list, DEFAULT_BODY_ITEM_DATA]
    setDataCreating({ ...dataCreating, payment_list: tmpBodyDataList })
    setValidateInputData({ ...validateInputData, is_error_payment_list: false })
  }
  const handleClickBtnPreview = () => {
    let isValid = checkInputValidation()
    if (isValid) {
      setIsShowDialogPreview(true)
      setDialogPurchaseType('render_preview')
    }
  }
  const handleClickBtnCreate = async () => {
    let isValid = checkInputValidation()
    if (isValid) {
      if (Object.keys(employee_info).length > 0) {
        let tmpObj = { ...dataCreating }
        tmpObj.creator_id = employee_info.id

        if (isEditPurchase) {
          // Edit Purchase

          let tmpProductList = [...tmpObj.payment_list]
          let tmpNewList = []
          for (let i = 0; i < tmpProductList.length; i++) {
            let tmpProductItem = tmpProductList[i]
            delete tmpProductItem['no']
            delete tmpProductItem['delete']
            delete tmpProductItem['is_error_details']
            delete tmpProductItem['is_error_amount']

            tmpNewList.push(tmpProductItem)
          }
          tmpObj.payment_list = [...tmpNewList]

          delete tmpObj['id']
          delete tmpObj['doc_id']
          delete tmpObj['number_of_copy']
          delete tmpObj['company_logo']
          delete tmpObj['rubber_stamp']
          delete tmpObj['condition']
          delete tmpObj['signature_list']

          if (tmpObj.payment_type == 'cash') {
            delete tmpObj['cheque_number']
            delete tmpObj['cheque_date']
            delete tmpObj['bank_name']
          }

          const request = { data: tmpObj }
          console.log('CREATE_request: ' + JSON.stringify(request, null, 2))
          await dispatch(editPurchaseDoc(currentDocId, request)).then((e) => {
            if (e?.data) {
              if (navigateType == 'external') {
                if (onCallback) {
                  onCallback(e?.data?.id)
                }
              } else {
                handleGoBack()
              }
            } else {
              alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
            }
          })
        } else {
          ///Create Purchase
          tmpObj.seller_id = tmpObj.id
          let tmpProductList = [...tmpObj.payment_list]
          let tmpNewList = []
          for (let i = 0; i < tmpProductList.length; i++) {
            let tmpProductItem = tmpProductList[i]

            // tmpProductItem.discount_percent = parseFloat(tmpProductItem.discount_percent.toString())
            delete tmpProductItem['no']
            delete tmpProductItem['delete']
            delete tmpProductItem['is_error_details']
            delete tmpProductItem['is_error_amount']

            tmpNewList.push(tmpProductItem)
          }
          tmpObj.payment_list = [...tmpNewList]
          delete tmpObj['withholding_tax']
          delete tmpObj['id']
          delete tmpObj['number_of_copy']
          delete tmpObj['company_logo']
          delete tmpObj['rubber_stamp']
          delete tmpObj['condition']
          delete tmpObj['signature_list']
          delete tmpObj['doc_number']

          if (tmpObj.payment_type == 'cash') {
            delete tmpObj['cheque_number']
            delete tmpObj['cheque_date']
            delete tmpObj['bank_name']
          }
          const request = { data: tmpObj }
          console.log('CREATE_request: ' + JSON.stringify(request, null, 2))
          await dispatch(createPurchase(request)).then((e) => {
            if (e?.data) {
              if (navigateType == 'external') {
                if (onCallback) {
                  onCallback(e?.data?.id)
                }
              } else {
                handleGoBack()
              }
            } else {
              alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
            }
          })
        }
      } else {
        setIsShowFailedModal(true)
      }
    } else {
      alert('กรอกข้อมูลให้ครบถ้วน')
    }
  }
  const checkInputValidation = () => {
    let tmpData = { ...dataCreating }
    console.log('tmpData Validate: ' + JSON.stringify(tmpData, null, 2))
    let tmpValidateData = { ...validateInputData }
    // CHECK Valid input

    // if (tmpData.doc_number != '') {
    //   tmpValidateData.is_error_doc_number = false
    // } else {
    //   tmpValidateData.is_error_doc_number = true
    // }

    if (tmpData.doc_date != '') {
      tmpValidateData.is_error_doc_date = false
    } else {
      tmpValidateData.is_error_doc_date = true
    }

    if (tmpData.details != '') {
      tmpValidateData.is_error_details = false
    } else {
      tmpValidateData.is_error_details = true
    }

    if (tmpData.payment_type != '') {
      tmpValidateData.is_error_payment_type = false
      //check cash
      // if (tmpData.payment_type != 'cash') {
      //   if (tmpData.credit_day != '') {
      //     tmpValidateData.is_error_credit_day = false
      //   } else {
      //     tmpValidateData.is_error_credit_day = true
      //   }
      // }

      // Check cash_cheque
      if (tmpData.payment_type == 'cash_cheque') {
        if (tmpData.cheque_number != '') {
          tmpValidateData.is_error_cheque_number = false
        } else {
          tmpValidateData.is_error_cheque_number = true
        }

        if (tmpData.cheque_date != '') {
          tmpValidateData.is_error_cheque_date = false
        } else {
          tmpValidateData.is_error_cheque_date = true
        }

        if (tmpData.bank_name != '') {
          tmpValidateData.is_error_bank_name = false
        } else {
          tmpValidateData.is_error_bank_name = true
        }
      }
    } else {
      tmpValidateData.is_error_payment_type = true
    }

    if (tmpData?.withholding_tax) {
      if (tmpData.withholding_tax_percent != '') {
        tmpValidateData.is_error_withholding_tax_percent = false
      } else {
        tmpValidateData.is_error_withholding_tax_percent = true
      }
    } else {
      tmpValidateData.is_error_withholding_tax_percent = false
    }

    //validate product list
    if (tmpData.payment_list?.length > 0) {
      let tmpProductList = [...tmpData.payment_list]
      let tmpArrayList = []
      let isErrorProductItem = false
      for (let index = 0; index < tmpProductList.length; index++) {
        let productItemObj = tmpProductList[index]

        if (productItemObj.details == '') {
          productItemObj.is_error_details = true
          isErrorProductItem = true
        } else {
          productItemObj.is_error_details = false
        }

        if (productItemObj.amount == '') {
          productItemObj.is_error_amount = true
          isErrorProductItem = true
        } else {
          productItemObj.is_error_amount = false
        }

        tmpArrayList.push(productItemObj)
      }

      tmpValidateData.is_error_payment_list = isErrorProductItem
      tmpData.payment_list = [...tmpArrayList]
    } else {
      tmpValidateData.is_error_payment_list = true
    }

    setDataCreating(tmpData)
    // console.log('tmpValidateData: ' + JSON.stringify(tmpValidateData, null, 2))
    setValidateInputData(tmpValidateData)
    for (const [key, value] of Object.entries(tmpValidateData)) {
      if (value) {
        return false
      }
    }
    return true
  }

  const calculateItemTotalPrice = (pricePerUnit, quantity, discount) => {
    let tmpTotal = convertNumberToCommaTwodecimal(pricePerUnit * quantity - (pricePerUnit * quantity * discount) / 100)
    return tmpTotal
  }
  const convertNumberToCommaTwodecimal = (myNumber) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
    const tmpTotal = Number(myNumber).toLocaleString('en', options)
    return tmpTotal
  }
  const handleGoBack = () => {
    navigate('/document/purchases')
  }

  const handleClickedOption = async (type) => {
    setDialogPurchaseType(type)

    if (type == 'preview' || type == 'print') {
      setIsShowDialogPreview(true)
    } else if (type == 'download_pdf') {
      setIsShowModalDownload(true)
    } else if (type == 'request_approve') {
      setIsShowDialogRequestApprove(true)
    } else {
      setIsShowModalShare(true)
    }
  }

  const handleClickBtnDownload = () => {
    setIsShowModalDownload(false)
    setIsShowDialogPreview(true)
  }

  const handleClickSubmitRequestApproval = () => {
    setIsShowDialogRequestApprove(false)
  }

  const handleClickedPreview = () => {
    setIsHindModalShare(true)
    setDialogPurchaseType('preview')
    setIsShowDialogPreview(true)
  }

  const handleClickClosePreview = () => {
    setIsShowDialogPreview(false)
    setIsHindModalShare(false)
  }

  const convertIntComma = (val) => {
    if (val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }

  return (
    <Styled isHindModalShare={isHindModalShare}>
      <div className="wrp-header-container">
        <div className="header-text">
          <h2>
            {isEditPurchase ? 'Editing' : 'Creating'} document - {viewType.replace(/_/g, ' ')}
          </h2>

          {isEditPurchase && <p>{saleDocumentData?.doc_id}</p>}
        </div>

        {isEditPurchase && (
          <div className="header-right">
            <DocumentOptionButton onClicked={handleClickedOption} />
          </div>
        )}
      </div>

      <div className="content-container">
        <div className="body">
          <div className="body-top">
            <div className="input-left">
              <div className="wrp-input">
                <p>รายละเอียดการจ่ายให้</p>
                <InputTextArea
                  className={'input-text-area-address' + (validateInputData?.is_error_details ? ' error' : '')}
                  onChange={(e) => handleChangeData(e, 'details')}
                  placeHolder="กรอกรายละเอียด"
                  resizeDisable
                  initValue={dataCreating?.details}
                />
              </div>
            </div>

            <div className="input-right">
              {/* <div className="wrp-input">
                <p>เลขที่</p>
                <TextField
                  className={'text-field'}
                  onChange={(e) => handleChangeData(e, 'doc_number')}
                  value={dataCreating?.doc_number}
                  error={validateInputData.is_error_doc_number}
                />
              </div> */}
              <div className="wrp-input">
                <p>วันที่</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'doc_date')}
                  value={dataCreating?.doc_date}
                  error={validateInputData.is_error_doc_date}
                />
              </div>
              <div className="wrp-input">
                <p>Payment Type</p>
                <DropdownSelect
                  className={'dropdown-select'}
                  dataList={paymentTypeList}
                  onChange={(e) => handleChangeData(e, 'payment_type')}
                  initValue={dataCreating?.payment_type}
                  isError={validateInputData.is_error_payment_type}
                />
              </div>

              {dataCreating?.payment_type == 'cash_cheque' && (
                <div className="wrp-cash-cheque">
                  <div className="wrp-input">
                    <p>เช็คเลขที่</p>
                    <TextField
                      className={'text-field'}
                      onChange={(e) => handleChangeData(e, 'cheque_number')}
                      value={dataCreating?.cheque_number}
                      error={validateInputData.is_error_cheque_number}
                    />
                  </div>
                  <div className="wrp-input">
                    <p>เช็คลงวันที่</p>
                    <Date
                      onChange={(e) => handleChangeData(e, 'cheque_date')}
                      value={dataCreating?.cheque_date}
                      error={validateInputData.is_error_cheque_date}
                    />
                  </div>
                  <div className="wrp-input">
                    <p>ธนาคาร</p>
                    <TextField
                      className={'text-field'}
                      onChange={(e) => handleChangeData(e, 'bank_name')}
                      value={dataCreating?.bank_name}
                      error={validateInputData.is_error_bank_name}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="body-bottom">
            <div className={validateInputData?.is_error_payment_list ? 'wrp-table-no-data' : 'wrp-table'}>
              <Table columns={TABLE_HEADER_COLUMN} data={bodyTableList} />
            </div>

            <Button onClick={handleClickBtnAddList}>Add List</Button>

            <div className="wrp-calculate-price">
              <div className="right mr-10">
                <div className="inner-right-top">
                  <div className="row">
                    <p>ยอดรวม</p>
                    <p className="price">{convertNumberToCommaTwodecimal(sumTotalPrice)}</p>
                  </div>

                  {!dataCreating?.include_tax && (
                    <div className="row">
                      <Checkbox
                        className={'checkbox'}
                        onChange={(e) => handleChangeData(e, 'vat')}
                        checked={dataCreating?.vat}
                      />
                      <p>ภาษีมูลค่าเพิ่ม 7%</p>
                      <p className="price">{convertNumberToCommaTwodecimal(priceVat)}</p>
                    </div>
                  )}

                  <div className="row">
                    <p>ยอดรวมหลังหักภาษีมูลค่าเพิ่ม</p>
                    <p className="price">{convertNumberToCommaTwodecimal(sumTotalPrice + priceVat)}</p>
                  </div>

                  {dataCreating?.include_tax && (
                    <div className={'include-vat-container'}>
                      <div className={'row'}>
                        <p>VAT 7%</p>
                        <p className="price">{convertNumberToCommaTwodecimal(priceVat)}</p>
                      </div>
                      <div className={'row'}>
                        <p>Price dose not include VAT</p>
                        <p className="price">{priceAfterVat}</p>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <Checkbox
                      className={'checkbox'}
                      onChange={(e) => handleChangeData(e, 'withholding_tax')}
                      checked={dataCreating?.withholding_tax ? true : false}
                    />
                    <p>หักภาษี ณ ที่จ่าย</p>
                    {dataCreating?.withholding_tax && (
                      <DropdownSelect
                        className={'dropdown-withholding-tax'}
                        dataList={withHoldingTaxList}
                        onChange={(e) => handleChangeData(e, 'withholding_tax_percent')}
                        initValue={dataCreating?.withholding_tax_percent}
                        isError={validateInputData.is_error_withholding_tax_percent}
                      />
                    )}

                    <p className="price">{convertNumberToCommaTwodecimal(priceWithHoldingTax)}</p>
                  </div>
                  <div className="row">
                    <p>ยอดรวมสุทธิ</p>
                    <p className="price">{convertNumberToCommaTwodecimal(summaryPrice)}</p>
                  </div>
                </div>

                <div className="inner-right-bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <ButtonOutline onClick={() => handleGoBack()}>Cancel</ButtonOutline>
        <div className="wrp-btn-right">
          <Button className="btn-preview" onClick={handleClickBtnPreview}>
            Preview
          </Button>
          <Button className="btn-create" onClick={handleClickBtnCreate}>
            {isEditPurchase ? 'Save' : 'Create'}
          </Button>
        </div>
      </div>

      {isShowDialogRequestApprove && (
        <SidePanel isOpen={isShowDialogRequestApprove} onClose={() => setIsShowDialogRequestApprove(false)} width={900}>
          <DocumentRequestApprove
            docType={saleDocumentData?.doc_type}
            onClickNavigationBack={() => setIsShowDialogRequestApprove(false)}
            id={saleDocumentData.id}
            onClickBtnSubmit={() => handleClickSubmitRequestApproval()}
          />
        </SidePanel>
      )}

      {/* preview and print */}
      {isShowDialogPreview && (
        <DialogDocument
          onClose={() => handleClickClosePreview()}
          docType={saleDocumentData?.doc_type || dataCreating?.doc_type}
          previewType={dialogPurchaseType}
          id={saleDocumentData.id}
          onDownloaded={() => setIsShowDialogPreview(false)}
          data={dataCreating}
          linkImage={dataCreating?.signature ? imageForDocument : null}
          textCondition={textPurchaseCondition}
        />
      )}

      {isShowModalDownload && (
        <ModalBox
          className={'modal-download'}
          onClosed={() => setIsShowModalDownload(false)}
          height="200px"
          width="450px"
          title={'Download ' + saleDocumentData?.doc_id}
          body={<div className="body">ใบสำคัญจ่าย-เงินสดย่อย</div>}
          footer={
            <div className="bottom-modal">
              <ButtonOutline className={'footer-btn btn-cancel'} onClick={() => setIsShowModalDownload(false)}>
                Cancel
              </ButtonOutline>
              <Button className={'footer-btn btn-download'} onClick={handleClickBtnDownload}>
                Download
              </Button>
            </div>
          }
        />
      )}

      {isShowModalShare && (
        <div className="document-share-and-send-email">
          <DocumentShareAndSendEmail
            type={currentDocType}
            data={saleDocumentData}
            onClickPreview={() => handleClickedPreview()}
            onClose={() => setIsShowModalShare(false)}
          />
        </div>
      )}

      {isShowFailedModal && (
        <DialogFail
          open={Boolean(isShowFailedModal)}
          onClose={() => setIsShowFailedModal(false)}
          onSubmit={() => setIsShowFailedModal(false)}
          icon={failIcon}
          title={'You may not fully use this feature, since you are not employee.'}
          nameItem={''}
          description={''}
          textYes="Ok"
        />
      )}
    </Styled>
  )
}

const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .mr-10 {
    margin-right: 10px !important;
  }
  .mg-0 {
    margin: 0 !important;
  }
  .wrp-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 0 20px;
    border-bottom: 1px solid #ddd;
    h2 {
      margin: 0;
      font-size: 36px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    p {
      margin: 0;
      color: var(--Gray-600);
      font-size: 16px;
    }
    .header-text {
      text-transform: capitalize;
    }
  }

  .content-container {
    padding: 20px 0;
    flex: 1;
  }

  .w-100 {
    width: 100% !important;
  }

  .checkbox label {
    margin: 0;
  }

  .body {
    .row {
      display: flex;
      .right {
        padding-left: 24px;
      }
    }

    .wrp-input {
      margin-bottom: 24px;
      /* background-color: red; */

      p {
        margin-top: 0;
        margin-bottom: 6px;
      }
      .text-field,
      .dropdown-select,
      .search-text {
        width: 300px;
      }
      .address {
        height: 134px;
      }
    }
    .error {
      border-color: red !important;
    }
    .body-top {
      display: flex;
      justify-content: space-between;

      .input-left {
        .input-text-area-address {
          font-size: 14px;
          height: 134px;
          border-color: var(--Gray-300);
          border-width: 1px;
          width: 800px;
        }
      }

      .input-right {
        margin-left: 24px;
      }
    }
    //////
    .body-bottom {
      padding: 24px 0;

      .input-top {
        width: fit-content;
      }

      .wrp-table {
        margin-bottom: 26px;
        > div {
          overflow-x: inherit;
        }
        .col-no {
          width: 50px;
        }
        .col-amount {
          width: 160px;
        }
        .col-delete {
          width: 50px;
        }
      }

      .wrp-table-no-data {
        margin-bottom: 20px;
        > div {
          overflow-x: inherit;
        }
        thead {
          border-top: 1px solid red;
          border-left: 1px solid red;
          border-right: 1px solid red;
          tr {
            border: none;
          }
        }
        tbody {
          border-left: 1px solid red;
          border-right: 1px solid red;
          border-bottom: 1px solid red;

          tr {
            border: none;
          }
        }
      }
      //table input
      .input-table {
        width: 100%;
      }

      .btn-delete {
        cursor: pointer;
      }

      .wrp-calculate-price {
        margin-top: 31px;
        display: flex;
        justify-content: flex-end;

        .inner-calculate {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 10px;

          .row {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            font-weight: bold;

            p {
              margin: 0;
            }

            .price {
              margin-left: 100px;
            }
          }
        }

        .right {
          .inner-right-top,
          .inner-right-bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .row {
              display: flex;
              align-items: center;
              /* margin-bottom: 5px; */
              p {
                margin: 0;
              }

              .checkbox {
                width: fit-content;
              }
              .price {
                margin-left: 100px;
              }
            }
            .text-field-percent {
              margin: 0 10px;
              width: 50px;
              height: 30px;
            }
          }

          .inner-right-top {
            /* border-bottom: 1px solid var(--Gray-200); */

            .row {
              margin-bottom: 10px;
              align-items: center;
            }

            .include-vat-container {
              border-top: 1px solid var(--Gray-200);
              width: 100%;
              padding-top: 10px;
              align-items: flex-end;
              text-align: end;
              .row {
                width: 100%;
                justify-content: flex-end;
              }
            }

            .dropdown-withholding-tax {
              width: 90px;
              margin-left: 8px;
            }
          }
          .inner-right-bottom {
            .row {
              margin-top: 10px;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--Gray-200);
    padding: 16px 24px;

    .btn-preview {
      margin-right: 12px;
    }
  }

  //Modal
  .modal-download {
    .bottom-modal {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .btn-cancel {
        width: 48%;
        justify-content: center;
      }
      .btn-download {
        width: 48%;
        justify-content: center;
      }
    }
  }

  .document-share-and-send-email {
    display: ${({ isHindModalShare }) => (isHindModalShare ? 'none' : 'block')};
  }
`
