import { initPagination } from 'constants/pagination'
import { OP } from 'redux/actionTypes'
import { deleteAPI, getAPI, postAPI } from 'utils/api'
import { hideLoading, showLoading } from '../loading'

const { GET, POST, DELETE } = OP

const pathnameBiz = '/api/business-docs'
const pathnameDoc = '/api/op-documents'
const pathnameDocSigner = '/api/op-document-signers'

export default function OPDocument({ headers, company_info, employee_info }) {
  const getBizDoc = () => ({
    byId: (bizDocId) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.BIZ_DOC_ID

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data } = await getAPI({
            url: `${pathnameBiz}/${bizDocId}`,
            headers,
          })

          return dispatch({ type: SUCCESS, data })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const getDocSigner = () => ({
    employees: (docSignerId, payload) => {
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }
      const { REQUEST, SUCCESS, FAILURE } = GET.DOC_SIGNER_ID_EMPLOYEES

      return async (dispatch) => {
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: { ...payload?.filters },
            //filters[$or][0][first_name][$contains]:tra
            //filters[$or][1][last_name][$contains]:tra
            sort: ['first_name:asc', 'last_name:asc'],
            pagination: { page, pageSize },
            fields: ['first_name', 'last_name'],
            populate: {
              position: {
                fields: ['id', 'name'],
              },
              department: {
                fields: ['id', 'name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `${pathnameDocSigner}/${docSignerId}/employees`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        }
      }
    },
    owner: (payload) => {
      const { page, pageSize } = payload?.pagination || initPagination
      const { REQUEST, SUCCESS, FAILURE } = GET.DOC_SIGNER_ME

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: { ...payload?.filters },
            sort: ['status:desc'],
            pagination: { page, pageSize },
            populate: {
              document: {
                populate: {
                  delivery: {
                    populate: {
                      sw: {
                        fields: ['id', 'code', 'name', 'due_date'],
                        populate: {
                          sp: {
                            fields: ['id', 'code', 'name'],
                          },
                        },
                      },
                    },
                  },
                },
              },
              employee: {
                fields: ['id', 'first_name', 'last_name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `${pathnameDocSigner}/me/company/${company_info?.id}`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    byId: (docSignerId) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.DOC_SIGNER_ID

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data } = await getAPI({
            url: `${pathnameDocSigner}/${docSignerId}`,
            headers,
          })

          return dispatch({ type: SUCCESS, data })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const postBizDoc = () => ({
    create: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.BIZ_DOC

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data } = await postAPI({
            url: pathnameBiz,
            headers,
            data: {
              ...payload,
              company_id: company_info?.id,
            },
          })

          return dispatch({ type: SUCCESS, data })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const postDoc = () => ({
    create: (documentId, businessDocId) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.DOC_ID_BIZ_DOC

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data } = await postAPI({
            url: `${pathnameDoc}/${documentId}/business-doc`,
            headers,
            data: {
              business_doc: {
                id: businessDocId,
              },
            },
          })

          return dispatch({ type: SUCCESS, data })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    byIdRequest: (documentId, payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.DOC_ID_REQUEST

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data } = await postAPI({
            url: `${pathnameDoc}/${documentId}/request`,
            headers,
            data: payload,
          })

          return dispatch({ type: SUCCESS, data })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    byIdCancel: (documentId) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.DOC_ID_CANCEL

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data } = await postAPI({
            url: `${pathnameDoc}/${documentId}/cancel`,
            headers,
          })

          return dispatch({ type: SUCCESS, data })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const checkHasSignature = async (employeeId) => {
    if (!employeeId) return false

    const empParams = {
      sort: ['id:desc'],
      filters: {
        employee: { id: employeeId },
      },
      populate: {
        signature: {
          fields: ['url'],
        },
      },
    }

    const responseEmp = await getAPI({
      url: `/api/employee-signatures`,
      headers,
      params: empParams,
    })

    return responseEmp?.data?.length > 0
  }

  const postDocSigner = () => ({
    /**
     * Approve document signer
     *
     * @param {number} docSignerId  - ID of the document signer
     * @param {object} payload - Object containing the following properties { note: 'string' }
     * @returns {Promise}
     */
    approve: (docSignerId, payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.DOC_SIGNER_ID_APPROVE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const hasSignature = await checkHasSignature(employee_info?.id) // check if employee has signature

          if (!hasSignature) {
            return dispatch({
              type: SUCCESS,
              meta: { hasSignature: false },
            })
          }

          const response = await postAPI({
            url: `${pathnameDocSigner}/${docSignerId}/approve`,
            headers,
            data: payload,
          })

          return dispatch({ type: SUCCESS, ...response, meta: { hasSignature: true } })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    /**
     * Reject document signer
     *
     * @param {number} docSignerId - ID of the document signer
     * @param {object} payload - Object containing the following properties { note: 'string' }
     * @returns
     */
    reject: (docSignerId, payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.DOC_SIGNER_ID_REJECT

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        /**
         *  payload = {
         *    note: '...string'
         *  }
         */

        try {
          const response = await postAPI({
            url: `${pathnameDocSigner}/${docSignerId}/reject`,
            headers,
            data: payload,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const deleteDoc = () => ({
    byId: (documentId) => {
      const { REQUEST, SUCCESS, FAILURE } = DELETE.DOC_ID_BIZ_DOC

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await deleteAPI({
            url: `${pathnameDoc}/${documentId}/business-doc`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  return {
    getBizDoc,
    getDocSigner,
    postBizDoc,
    postDoc,
    postDocSigner,
    deleteDoc,
  }
}
