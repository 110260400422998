import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'

import successIcon from '../../assets/images/success-icon-2.png'
import failIcon from '../../assets/images/fail-icon.png'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  .mr-1-rem {
    margin-right: 1rem;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  a {
    cursor: pointer;
  }

  .icon {
    width: 2rem;
    height: auto;
    cursor: pointer;
  }

  .container {
    padding: 10rem 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .d-flex {
    display: flex;
  }

  .jt-center {
    justify-content: center;
  }

  .jt-between {
    justify-content: space-between;
  }

  .al-center {
    align-items: center;
  }

  .header {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .logo {
    width: 3rem;
    height: auto;
  }

  .user-name {
    /* text-align: left; */
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .submit-button {
    padding: 10px 20px; /* Adjust the padding as needed */
  }

  .headline {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  .message {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .input-new {
    min-width: 360px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }
`
const initMessage = {
  headline: '',
  message: '',
}
function ResultResetPassword() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const query = new URLSearchParams(useLocation().search)
  const isSuccess = query.get('is_success')
  const type = query.get('type')
  const [success, setSuccess] = useState(true)
  const [message, setMessage] = useState(initMessage)

  useEffect(() => {
    const is_success = isSuccess == 'true' ? true : false
    if (is_success) {
      const headline = type == 'set_password' ? 'Password set' : 'Password reset'
      const message =
        type == 'set_password'
          ? 'Your password has been successfully set. Click below to log in magically.'
          : 'Your password has been successfully reset. Click below to log in magically.'
      setMessage({
        headline,
        message,
      })
      setSuccess(true)
    } else {
      const headline = type == 'set_password' ? 'Password set failed' : 'Password reset failed'
      const message =
        type == 'set_password'
          ? 'Your password has been failedly set. Click below to log in magically.'
          : 'Your password has been failedly reset. Click below to log in magically.'
      setMessage({
        headline,
        message,
      })
      setSuccess(false)
    }
  }, [isSuccess, type])

  const continueNext = () => {
    navigate('/login', {
      replace: true,
    })
  }

  return (
    <Div>
      <div className="container">
        <div className="mb-1-rem">{success ? <img src={successIcon} /> : <img src={failIcon} />}</div>
        <div className="headline mb-1-rem">{message.headline}</div>
        <div className="message mb-2-rem">{message.message}</div>
        <div className="input-new">
          <Button id="create-role" className="d-flex jt-center" onClick={continueNext}>
            Continue
          </Button>
        </div>
      </div>
    </Div>
  )
}

export default ResultResetPassword
