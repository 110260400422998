import { Stack } from '@mui/material'
import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next'

const GuidelineTab = ({ content }) => {
  const { t } = useTranslation()

  return (
    <StyledContainer>
      <h3>{t('op_guidline_header')}: </h3>
      <p>{content || '-'}</p>
    </StyledContainer>
  )
}

export default GuidelineTab

const StyledContainer = styled(Stack)`
  padding: 0 1.25rem 1rem 1.25rem;
  min-height: 100%;
  height: auto;

  // Webkit scrollbar - style #2
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b3b3b3;
  }

  h3,
  p {
    margin: 0;
  }

  & h3 {
    font-size: 14px;
    font-weight: 500;
  }

  & p {
    font-size: 14px;
  }
`
