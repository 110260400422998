import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export function formatDate(date) {
  const now = dayjs()
  const inputDate = dayjs(date)

  if (!inputDate.isValid()) {
    return '-'
  }

  if (inputDate.isSame(now, 'day')) {
    return `วันนี้ ${inputDate.format('HH:mm น.')}` // e.g. วันนี้ 12:34 น.
  } else if (inputDate.isSame(now.subtract(1, 'day'), 'day')) {
    return `เมื่อวาน ${inputDate.format('HH:mm น.')}` // e.g. เมื่อวาน 12:34 น.
  } else if (inputDate.isSame(now.add(1, 'day'), 'day')) {
    return `พรุ่งนี้ ${inputDate.format('HH:mm น.')}` // e.g. พรุ่งนี้ 12:34 น.
  } else if (inputDate.isSame(now, 'year')) {
    return inputDate.locale('th').format('DD MMM HH:mm น.') // e.g. 31 ธ.ค. 12:34 น.
  } else {
    return inputDate.locale('th').format('DD MMM YYYY HH:mm น.') // e.g. 31 ธ.ค. 2568 12:34 น.
  }
}
