import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { getCompanyInfo,GetPermissionOther,GetPermissionTabList } from '../../../utils/common'

import { selectDocumentTemplate } from '../../../redux/selectors'
import {
  getDocumentCategory,
  getDocumentTemplate,
  getDocumentTemplateById,
  createDocumentTemplate,
  editDocumentTemplate,
  deleteDocumentTemplate,
  duplicateDocumentTemplate,
} from '../../../redux/actions/documentTemplate'

import styled from 'styled-components'
import Button from '../../../components/form/button/Button'
import SearchText from '../../../components/form/SearchText'
import Table from '../../../components/common/Table'
import DialogConfirmDelete from '../../../components/dialog/DialogConfirmDelete'
import Dropdown from '../../../components/form/Dropdown'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import DialogSuccess from '../../../components/dialog/DialogSuccess'
import DialogFail from '../../../components/dialog/DialogFail'
import SidePanel from '../../../components/common/SidePanel'
import CreateTemplate from './CreateTemplate'
import EditTemplate from './EditTemplate'
import PreviewTemplate from './PreviewTemplate'

import successIcon from '../../../assets/images/success-icon.png'
import failIcon from '../../../assets/images/fail-icon.png'
import { LiaTimesSolid } from 'react-icons/lia'
import { FiCopy } from 'react-icons/fi'
import { BiTrash } from 'react-icons/bi'
import { LuPen } from 'react-icons/lu'

const Wrapper = styled.div`
  .mr-8-px {
    margin-right: 8px;
  }
  .item-vertical-align-center {
    vertical-align: middle;
  }
  .icon {
    :hover {
      color: var(--Primary-700);
    }
  }
  .document-name {
    color: var(--Primary-700);
    font-style: normal;
    font-weight: 500;
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`
const TableWrapper = styled.div`
  .table-header {
    padding: 1rem 0.875rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: var(--Base-White);
    border: 0.0625rem solid var(--Gray-200);

    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.25rem;

      .table-header-name {
        color: var(--Gray-900);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        margin-right: 0.5rem;
      }
    }

    .search-container {
      display: flex;
      .clear-button-container {
        display: flex;
        align-items: flex-end;
      }
    }
  }
`
const BadgeSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .badge {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`

const initMessageModal = {
  headline: '',
  message: '',
}

const initTypeDropdown = [
  {
    text: 'Info',
    value: 'Info',
  },
  {
    text: 'Acknowledge',
    value: 'Acknowledge',
  },
  // {
  //   text: 'Sign',
  //   value: 'Sign',
  // },
  {
    text: 'Acknowledge and Sign',
    value: 'Acknowledge and Sign',
  },
]

function DocumentTemplate() {
  const { t } = useTranslation()
  const initLang = localStorage.getItem('lang')
  //--- ST ส่วนของ Permission LV3-4
  const permissionNewDocument = GetPermissionOther('Document Template', 'New Document Template')
  const permissionViewDocument = GetPermissionOther('Document Template', 'View Template')
  /*
  const { permission } = JSON.parse(localStorage.getItem('admin'))
  const [permissionNewDocument, setpermissionNewDocument] = useState(null);
  let permissionViewDocument = null;
  useEffect(() => {
    const this_sub_menu_permission = permission['Human Resources & Organization'].filter((it_2) => it_2.path === '/organization-management/acknowledgement-system');
    const tab_permission = this_sub_menu_permission[0].permission_tab?.filter((it_2) => it_2.menu_setting.rout_path === 'AcknowledgementSystem_DocumentTemplate')
    if(tab_permission.length > 0){
      const this_permissionNewDocument = tab_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'AcknowledgementSystem_DocumentTemplate_NewDocumentTemplate');
      if(this_permissionNewDocument){
        setpermissionNewDocument(this_permissionNewDocument)
      }
      const this_permissionViewDocument = tab_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'AcknowledgementSystem_DocumentTemplate_ViewTemplate');
      if(this_permissionViewDocument){
        //setpermissionViewDocument(this_permissionViewDocument)
        permissionViewDocument = this_permissionViewDocument;
      }
    }
  }, [])
  */
  //--- ED ส่วนของ Permission LV3-4
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  // column
  const categoryColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('document_template'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('category'),
      accessor: 'category',
      disableSortBy: false,
    },
    {
      Header: t('type'),
      accessor: 'type',
      disableSortBy: false,
    },
    {
      Header: t('language'),
      accessor: 'doc_lang',
      disableSortBy: false,
    },
    {
      Header: t('topic'),
      accessor: 'topic',
      disableSortBy: false,
    },
    {
      Header: t('created_by'),
      accessor: 'created_by',
      disableSortBy: false,
    },
    {
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const dispatch = useDispatch()
  const { documentTemplates, categories, page, pageCount, total, limit, isLoading, state } =
    useSelector(selectDocumentTemplate)
  const [templateList, setTemplateList] = useState([])
  const [templateId, setTemplateId] = useState('')

  // side panel
  const [createSidePanel, setCreateSidePanel] = useState(false)
  const [editSidePanel, setEditSidePanel] = useState(false)
  const [previewSidePanel, setPreviewSidePanel] = useState(false)
  // dialog
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  // modal message
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  // dropdown list
  const [categoryList, setCategoryList] = useState([])
  // filter
  const [search, setSearch] = useState('')
  const [categorySelected, setCategorySelected] = useState('')
  const [typeSelected, setTypeSelected] = useState('')
  const [searchFilters, setSearchFilters] = useState([])

  const fetchData = useCallback(
    (filter) => {
      dispatch(getDocumentTemplate(filter))
    },
    [dispatch]
  )
  const editTemplate = useCallback(
    (id) => {
      
      if(permissionViewDocument?.canUpdate === true){
        setTemplateId(id)
        dispatch(getDocumentTemplateById(id))
        setEditSidePanel(true)
      }else{
        let message_permission = 'No permission allowed ' + permissionViewDocument?.name_en;
        if(initLang == 'th'){
          message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionViewDocument?.name_th;
        }
        setFailMessageModal({
          headline: message_permission,
          message: '',
        })
        setFailModal(true)
      }
    },
    [dispatch]
  )
  const deleteTemplate = useCallback((id) => {
    setTemplateId(id)
    setConfirmDeleteModal(true)
  }, [])
  const duplidateTemplate = useCallback((id) => {
    setTemplateId(id)
    dispatch(duplicateDocumentTemplate(id))
  }, [])

  useEffect(() => {
    fetchData()
    dispatch(getDocumentCategory())
  }, [])

  useEffect(() => {
    const newCategoryList = documentTemplates?.map((v, i) => {
      const topics =
        v.topics.map((i) => i.title).join(',').length > 30
          ? `${v.topics
              .map((i) => i.title)
              .join(',')
              .slice(0, 30)}...`
          : `${v.topics.map((i) => i.title).join(',')}`

      const lan = v.doc_lang === 'th' ? 'Thai' : '-'
      return {
        ...v,
        no: i + 1,
        name: (
          <div
            className="document-name"
            onClick={() => editTemplate(v.id)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                editTemplate(v.id)
              }
            }}
          >
            {v.name}
          </div>
        ),
        category: v?.category?.name,
        topic: v.topics.length > 0 ? `${topics}` : '',
        created_by: v.creator ? `${v?.creator?.first_name} ${v?.creator?.last_name}` : '-',
        doc_lang: v.doc_lang === 'en' ? 'English' : lan,
        action: (
          <div>
            <FiCopy
              className="icon item-vertical-align-center mr-8-px"
              size={18}
              onClick={() => duplidateTemplate(v.id)}
            />
            <BiTrash
              className="icon item-vertical-align-center mr-8-px"
              size={18}
              onClick={() => deleteTemplate(v.id)}
            />
            <LuPen className="icon item-vertical-align-center" size={18} onClick={() => editTemplate(v.id)} />
          </div>
        ),
      }
    })
    setTemplateList(newCategoryList)
  }, [documentTemplates])

  useEffect(() => {
    const newCategoryList = categories.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setCategoryList(newCategoryList)
  }, [categories])

  useEffect(() => {
    if (state === 'CREATE_DOCUMENT_TEMPLATE.SUCCESS' && isLoading === false) {
      setCreateSidePanel(false)
      setSuccessMessageModal({
        headline: 'Create successful',
        message: '',
      })
      setSuccessModal(true)
    }
    if (state === 'EDIT_DOCUMENT_TEMPLATE.SUCCESS' && isLoading === false) {
      setSuccessMessageModal({
        headline: t('edit_success_text'),
        message: '',
      })
      setSuccessModal(true)
    }
    if (state === 'DELETE_DOCUMENT_TEMPLATE.SUCCESS' && isLoading === false) {
      setSuccessMessageModal({
        headline: t('view_document_template'),
        message: '',
      })
      setSuccessModal(true)
    }
    if (state === 'DELETE_DOCUMENT_TEMPLATE.FAILURE' && isLoading === false) {
      setFailMessageModal({
        headline: 'Error deletion',
        message: '',
      })
      setFailModal(true)
    }
    if (state === 'DUPLICATE_DOCUMENT_TEMPLATE.SUCCESS' && isLoading === false) {
      setSuccessMessageModal({
        headline: t('duplicated_success_text'),
        message: '',
      })
      setSuccessModal(true)
    }
  }, [isLoading, state])

  // filter
  useEffect(() => {
    if (categorySelected !== '' || typeSelected !== '') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))
      const filter = {
        page: 1,
        pageSize: limit,
        type: typeSelected,
        category: categorySelected,
        multipleSearch,
      }
      fetchData(filter)
    }
  }, [categorySelected, typeSelected])
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))

      const filter = {
        page: 1,
        pageSize: limit,
        type: typeSelected,
        category: categorySelected,
        multipleSearch,
      }
      fetchData(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))

    const filter = {
      page: 1,
      pageSize: limit,
      type: typeSelected,
      category: categorySelected,
      multipleSearch,
    }
    fetchData(filter)
    setSearch('')
  }
  const onClearSearch = () => {
    const request = {
      page: 1,
      pageSize: limit,
      type: '',
      category: '',
    }
    fetchData(request)
    clearFilter()
  }
  const clearFilter = () => {
    setSearch('')
    setCategorySelected('')
    setTypeSelected('')
    setSearchFilters([])
  }
  const onChangePagination = async (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    const filter = {
      page: value.page,
      pageSize: limit,
      type: typeSelected,
      category: categorySelected,
      multipleSearch,
    }
    fetchData(filter)
  }

  // side panel
  const openCreateSidePanel = () => {
    
    if(permissionNewDocument?.canUpdate === true){
      setCreateSidePanel(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionNewDocument?.name_en;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionNewDocument?.name_th;
      }
      setFailMessageModal({
        headline: message_permission,
        message: '',
      })
      setFailModal(true)
    }
  }
  const closeCreateSidePanel = () => {
    setCreateSidePanel(false)
  }
  const closeEditSidePanel = () => {
    setEditSidePanel(false)
  }
  const openPreviewSidePanel = () => {
    setPreviewSidePanel(true)
  }
  const closePreviewSidePanel = () => {
    setPreviewSidePanel(false)
  }

  // submit
  const submitCreateTemplate = (data) => {
    dispatch(createDocumentTemplate(data))
  }
  const submitEditCategory = (data) => {
    dispatch(editDocumentTemplate(templateId, data))
  }
  const confirmDelete = () => {
    dispatch(deleteDocumentTemplate(templateId))
    setConfirmDeleteModal(false)
  }

  // modal
  const confirmFailModal = () => {
    setFailModal(false)
  }
  return (
    <Wrapper>
      <TableWrapper>
        <div className="table-header">
          <div className="header-container">
            <div>
              <span className="table-header-name">{t('all_document_template')}</span>
              <span className="badge">
                {total} {t('templates')}
              </span>
            </div>
            {permissionNewDocument?.canRead === true && (
              <Button onClick={openCreateSidePanel}>{t('new_document_template')}</Button>
            )}
          </div>
          <div className="search-container mb-1-rem">
            <div className="input-container">
              <div className="input-header">{t('search')}</div>
              <SearchText
                placeholder={t('search')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSubmit={onSearch}
                onClear={(e) => setSearch('')}
              />
            </div>
            <div className="input-container width-20">
              <div className="input-header">{t('category')}</div>
              <Dropdown
                optionList={categoryList}
                value={categorySelected}
                onChange={(value) => setCategorySelected(value)}
              />
            </div>
            <div className="input-container width-20">
              <div className="input-header">{t('type')}</div>
              <Dropdown
                optionList={initTypeDropdown}
                value={typeSelected}
                onChange={(value) => setTypeSelected(value)}
              />
            </div>
            <div className="clear-button-container">
              <ButtonOutline onClick={onClearSearch}>{t('clear')}</ButtonOutline>
            </div>
          </div>
          <BadgeSearchContainer>
            {searchFilters.map((v) => (
              <div className="badge" key={v.key}>
                <span className="name">{v.name}</span>{' '}
                <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
              </div>
            ))}
          </BadgeSearchContainer>
        </div>
        <Table
          data={templateList}
          columns={categoryColumn}
          onStateChange={onChangePagination}
          page={page}
          pageCount={pageCount}
        />
      </TableWrapper>
      {/* side panel */}
      <SidePanel isOpen={createSidePanel} setIsOpen={openCreateSidePanel} width={400}>
        <CreateTemplate close={closeCreateSidePanel} onSubmit={submitCreateTemplate} />
      </SidePanel>
      <SidePanel isOpen={editSidePanel} width={400}>
        <EditTemplate openPreview={openPreviewSidePanel} close={closeEditSidePanel} onSubmit={submitEditCategory} />
      </SidePanel>
      <SidePanel isOpen={previewSidePanel} width={600}>
        <PreviewTemplate close={closePreviewSidePanel} />
      </SidePanel>
      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => setSuccessModal(false)}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={confirmDelete}
        title={t('delete_document_template')}
        description={t('delete_document_template_desc')}
      />
    </Wrapper>
  )
}

export default DocumentTemplate
