export default function IconArrowLeft({ width = '14', color = '#333', style, onClick }) {
  return (
    <svg
      onClick={onClick}
      width={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path d="M15 8H1M1 8L8 15M1 8L8 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
