import { forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

import { clearAlert } from '../redux/actions/alert'
import { selectAlert } from '../redux/selectors'

const StyldSnackbar = styled(Snackbar)`
  .MuiAlert-message {
    /*font-family: 'Inter';*/
    font-family: inherit;
  }
`

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const Toast = () => {
  const dispatch = useDispatch()
  const { isShow, message, type } = useSelector(selectAlert)

  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      dispatch(clearAlert())
    }
  }

  return (
    <StyldSnackbar
      open={isShow}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </StyldSnackbar>
  )
}

export default Toast
