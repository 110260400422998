import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Table from '../../../components/common/Table'
import Checkbox from '../../../components/form/Checkbox'
import SearchText from '../../../components/form/SearchText'
import Button from '../../../components/form/button/Button'
import DocumentOptionButton from '../../../components/widget/element/DocumentOptionButton'
import DropdownSelect from '../../../components/widget/element/DropDownSelect'
import { getBusinessDocumentList } from '../../../redux/actions/document'
import { selectDocument } from '../../../redux/selectors'
import { convertDateFormat } from '../../../utils/helper'
import DeliveryAppointmentNote from '../business-doc/doc-view/DeliveryAppointmentNote'
import TransferRequest from '../business-doc/doc-view/TransferRequest'

export default function TransportationDocument() {
  const dispatch = useDispatch()
  
  const [searchParams, setSearchParams] = useSearchParams()
  const docType = searchParams.get('doctype')

  const lastEditList = [
    {
      text: 'Today',
      value: 'today',
    },
    {
      text: 'Last 7 days',
      value: 'last7',
    },
    {
      text: 'Last 30 days',
      value: 'last30',
    },
  ]

  const HEADER_COLUMN = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'ประเภทน้ำตาล',
      accessor: 'type',
      disableSortBy: false,
    },
    {
      Header: 'สถานที่ต้นทาง',
      accessor: 'departure',
      disableSortBy: false,
    },
    {
      Header: 'สถานที่ปลายทาง',
      accessor: 'destination',
      disableSortBy: false,
    },
    {
      Header: 'ผู้จัดทำ',
      accessor: 'creator',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const HEADER_COLUMN_DELIVERY_APPOINTMENT_NOTE = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'Customer id',
      accessor: 'customer_id',
      disableSortBy: false,
    },
    {
      Header: 'Customer Name',
      accessor: 'customer_name',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]

  // const HEADER_COLUMN_PAYMENT_JOURNAL = [
  //   {
  //     Header: ' ',
  //     accessor: 'checkbox',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: 'No.',
  //     accessor: 'no',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: 'Date',
  //     accessor: 'date',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: 'Document id',
  //     accessor: 'doc_id',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: 'รายละเอียด สมุดรายวันจ่ายเช็ค',
  //     accessor: 'detail',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: 'Status',
  //     accessor: 'status',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: ' ',
  //     accessor: 'action',
  //     disableSortBy: false,
  //   },
  // ]
  // const HEADER_COLUMN_PETTY_CASH_VOUCHER = [
  //   {
  //     Header: ' ',
  //     accessor: 'checkbox',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: 'No.',
  //     accessor: 'no',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: 'Date',
  //     accessor: 'date',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: 'Document id',
  //     accessor: 'doc_id',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: 'รายละเอียดการจ่ายให้',
  //     accessor: 'detail',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: 'Payment Type',
  //     accessor: 'payment_type',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: 'Status',
  //     accessor: 'status',
  //     disableSortBy: false,
  //   },
  //   {
  //     Header: ' ',
  //     accessor: 'action',
  //     disableSortBy: false,
  //   },
  // ]
  // const MOCKUP_CHEQUE_PAYMENT_JOURNAL = [
  //   {
  //     id: 4,
  //     doc_id: 'PO2024010004',
  //     created_date: '2024-01-10T08:03:59.684Z',
  //     seller: 'Apple',
  //     details: 'Test daily check 1',
  //     net_total: 96.21,
  //     status: 'active',
  //   },
  //   {
  //     id: 3,
  //     doc_id: 'PO2024010003',
  //     created_date: '2024-01-07T07:14:27.328Z',
  //     seller: 'Bug seller man',
  //     details: 'Test daily check 2',
  //     net_total: 936,
  //     status: 'Working',
  //   },
  //   {
  //     id: 2,
  //     doc_id: 'PO2024010002',
  //     created_date: '2024-01-07T06:55:15.567Z',
  //     seller: 'Cookie Lava',
  //     details: 'Test daily check 3',
  //     net_total: 180,
  //     status: 'active',
  //   },
  //   {
  //     id: 1,
  //     doc_id: 'PO2024010001',
  //     created_date: '2024-01-03T08:37:35.073Z',
  //     seller: 'Edit name',
  //     details: '',
  //     net_total: 28.08,
  //     status: 'active',
  //   },
  // ]
  // const MOCKUP_PAYMENT_JOURNAL = [
  //   {
  //     id: 4,
  //     doc_id: 'PO2024010004',
  //     created_date: '2024-01-10T08:03:59.684Z',
  //     seller: 'Apple',
  //     details: 'Test daily pay 1',
  //     net_total: 96.21,
  //     status: 'active',
  //   },
  //   {
  //     id: 3,
  //     doc_id: 'PO2024010003',
  //     created_date: '2024-01-07T07:14:27.328Z',
  //     seller: 'Bug seller man',
  //     details: 'Test daily pay 2',
  //     net_total: 936,
  //     status: 'active',
  //   },
  //   {
  //     id: 2,
  //     doc_id: 'PO2024010002',
  //     created_date: '2024-01-07T06:55:15.567Z',
  //     seller: 'Cookie Lava',
  //     details: 'Test daily pay 3',
  //     net_total: 180,
  //     status: 'Doing',
  //   },
  //   {
  //     id: 1,
  //     doc_id: 'PO2024010001',
  //     created_date: '2024-01-03T08:37:35.073Z',
  //     seller: 'Edit name',
  //     details: ' ',
  //     net_total: 28.08,
  //     status: 'active',
  //   },
  // ]
  // const MOCKUP_PETTY_CASH_VOUCHER = [
  //   {
  //     id: 4,
  //     doc_id: 'PO2024010004',
  //     created_date: '2024-01-10T08:03:59.684Z',
  //     seller: 'Apple',
  //     details: 'Test petty cash 1',
  //     payment_type: 'Cash',
  //     net_total: 96.21,
  //     status: 'active',
  //   },
  //   {
  //     id: 3,
  //     doc_id: 'PO2024010003',
  //     created_date: '2024-01-07T07:14:27.328Z',
  //     seller: 'Bug seller man',
  //     details: 'Test petty cash 2',
  //     payment_type: 'Credit',
  //     net_total: 936,
  //     status: 'active',
  //   },
  //   {
  //     id: 2,
  //     doc_id: 'PO2024010002',
  //     created_date: '2024-01-07T06:55:15.567Z',
  //     seller: 'Cookie Lava',
  //     details: 'Test petty cash 3',
  //     payment_type: 'Credit',
  //     net_total: 180,
  //     status: 'active',
  //   },
  //   {
  //     id: 1,
  //     doc_id: 'PO2024010001',
  //     created_date: '2024-01-03T08:37:35.073Z',
  //     seller: 'Edit name',
  //     details: ' ',
  //     payment_type: 'Credit',
  //     net_total: 28.08,
  //     status: 'hold',
  //   },
  // ]
  const [bodyTableList, setBodyTableList] = useState([])
  const [bodyDataList, setBodyDataList] = useState([])
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [isSetData, setIsSetData] = useState(false)

  const { isLoading, pageCount, documentList } = useSelector(selectDocument)

  const [filter, setFilter] = useState({
    lastEdit: '',
    searchText: '',
    docType: docType || '',
  })

  const [currentPage, setCurrentPage] = useState(1)

  const navigate = useNavigate()
  const [itemSelected, setItemSelected] = useState()
  const [dialogQuotationType, setDialogQuotationType] = useState('')
  const [isShowPreview, setIsShowPreview] = useState(false)
  const [isShowModalDownloadQuotation, setIsShowModalDownloadQuotation] = useState(false)
  const [isShowDialogRequestApprove, setIsShowDialogRequestApprove] = useState(false)

  const [isHindModalShare, setIsHindModalShare] = useState(false)

  const [optionClickStage, setOptionClickStage] = useState()

  const [docTitle, setDocTitle] = useState('')

  useEffect(() => {
    if (docType != '') {
      setFilter({ ...filter, docType: docType })
      titleByDocType(docType)
    }
  }, [docType])

  const titleByDocType = (txtDocType) => {
    if (txtDocType == 'transfer_request') {
      setDocTitle('ใบขอขนย้าย')
    } else if (txtDocType == 'delivery_appointment_note') {
      setDocTitle('ใบนัดส่งสินค้า')
    } else if (txtDocType == 'job_delivery_note') {
      setDocTitle('ใบส่งงาน')
    } else if (txtDocType == 'delivery_note') {
      setDocTitle('ใบส่งสินค้า')
    }
  }

  useEffect(() => {
    ;(async () => {
      dispatch(
        getBusinessDocumentList(
          {
            page: currentPage,
            pageSize: 10,
          },
          filter
        )
      )
    })()
  }, [filter])

  useEffect(() => {
    if (documentList?.length >= 0) {
      let tmpArray = [...documentList]

      for (let i = 0; i < tmpArray.length; i++) {
        tmpArray[i].checked = false
      }
      setBodyDataList(tmpArray) // Keep Original Data
      setIsSetData(false)
    }
  }, [documentList])

  useEffect(() => {
    if (!isSetData) {
      const tmpList = [...bodyDataList]
      let viewList = []

      if (docType == 'transfer_request') {
        viewList = tmpList.map((item, index) => ({
          checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
          no: index + 1,
          date: convertDateFormat(item?.date, 'DD MMM YYYY'),
          doc_id: (
            <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
              {item?.doc_id || '-'}
            </span>
          ),
          type: item?.type || '-',
          departure: item?.departure || '-',
          destination: item?.destination || '-',
          creator: item?.creator || '-',
          action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
        }))
      } else if (docType == 'delivery_appointment_note') {
        viewList = tmpList.map((item, index) => ({
          checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
          no: index + 1,
          date: convertDateFormat(item?.date, 'DD MMM YYYY'),
          doc_id: (
            <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
              {item?.doc_id || '-'}
            </span>
          ),
          customer_id: item?.customer_id || '-',
          customer_name: item?.customer_name || '-',
          status: item?.status || '-',
          action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
        }))
      }
      setBodyTableList(viewList)
    }
  }, [bodyDataList, isSetData])

  const handleClickedOption = async (type, item) => {
    setOptionClickStage(type)
    setItemSelected(item)
  }

  const handleClickBtnCreateNew = () => {
    setItemSelected(null)
    setOptionClickStage('close')
    navigate('/document/transportation/create/?type=' + docType)
  }

  const handleBodyTableChecked = (isChecked, index) => {
    let tmpArray = [...bodyDataList]
    tmpArray[index].checked = isChecked
    setBodyDataList(tmpArray)
    setIsSetData(false)
    if (isChecked == false) {
      setIsSelectAll(false)
    }
  }
  const handleSearchTextChange = (e) => {
    setFilter({ ...filter, searchText: e.target.value })
  }

  const handleClickedPreview = () => {
    setIsHindModalShare(true)
    setDialogQuotationType('preview')
    setIsShowDialogQuotation(true)
  }

  const handleSelectFilter = (e) => {
    setFilter({ ...filter, lastEdit: e })
  }

  const handleClickedDocItem = (itemData) => {
    if (itemData?.id) {
      navigate('/document/transportation/edit/?type=' + docType + '&id=' + itemData.id)
    }
  }

  const onChangePagination = async (value) => {
    const pagination = {
      page: value.page,
      pageSize: 10,
    }
    dispatch(getBusinessDocumentList(pagination, filter))
    setCurrentPage(value.page)
  }

  const handleClickClosePreview = () => {
    setIsShowPreview(false)
    setIsHindModalShare(false)
  }
  const getHeaderByDocType = () => {
    if (docType == 'transfer_request') {
      return HEADER_COLUMN
    } else if (docType == 'delivery_appointment_note') {
      return HEADER_COLUMN_DELIVERY_APPOINTMENT_NOTE
    }
    // else if (docType == 'payment_journal') {
    //   console.log('header payment_journal')
    //   return HEADER_COLUMN_PAYMENT_JOURNAL
    // } else if (docType == 'petty_cash_voucher') {
    //   return HEADER_COLUMN_PETTY_CASH_VOUCHER
    // }
  }

  const handleClosePreview = () => {
    setOptionClickStage('')
  }

  return (
    <Styled isHindModalShare={isHindModalShare}>
      {docType && (
        <div className="wrp-main-page">
          <div className="header">{docTitle}</div>

          <div className="filter-button">
            <div className="left">
              <div className="title">Last edit</div>
              <div className="filter">
                <DropdownSelect
                  className={'dropdown'}
                  dataList={lastEditList}
                  onChange={handleSelectFilter}
                  initValue={filter?.lastEdit}
                />
                <SearchText onChange={handleSearchTextChange} />
              </div>
            </div>
            <div className="right">
              <div className="btn-create-new" onClick={handleClickBtnCreateNew}>
                <Button>Create new</Button>
              </div>
            </div>
          </div>

          <div className="wrp-table">
            <Table
              columns={getHeaderByDocType()}
              data={bodyTableList}
              onStateChange={onChangePagination}
              pageCount={pageCount}
              page={currentPage}
              loading={isLoading}
            />
          </div>

          {optionClickStage && (
            <>
              {docType == 'transfer_request' && (
                <TransferRequest
                  previewType={optionClickStage}
                  currentPage={currentPage}
                  filter={filter}
                  itemSelected={itemSelected}
                  onCloseDialog={handleClosePreview}
                />
              )}

              {docType == 'delivery_appointment_note' && (
                <DeliveryAppointmentNote
                  previewType={optionClickStage}
                  currentPage={currentPage}
                  filter={filter}
                  itemSelected={itemSelected}
                  onCloseDialog={handleClosePreview}
                />
              )}
            </>
          )}
        </div>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  .wrp-main-page {
    .header {
      font-size: 30px;
      font-weight: 600;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--Gray-200);
      margin-bottom: 24px;
    }
  }
  .wrp-table {
    > div {
      overflow-x: inherit;
    }
  }

  .txt-doc-item {
    cursor: pointer;
    :hover {
      color: var(--Primary-600);
    }
  }

  .filter-button {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    .left {
      .title-filter {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 6px;
      }
      .filter {
        display: flex;

        .dropdown {
          margin-right: 12px;
        }
      }
    }
    .right {
      display: flex;
      align-items: end;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .btn-cancel {
    width: 48%;
    justify-content: center;
  }
  .btn-download {
    width: 48%;
    justify-content: center;
  }

  .document-share-and-send-email {
    display: ${({ isHindModalShare }) => (isHindModalShare ? 'none' : 'block')};
  }
`
