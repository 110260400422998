export default function IconCheckList({ width = '30', color = '#333', style }) {
  return (
    <svg width={width} height={width} viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M160 144H448M160 256H448M160 368H448"
        stroke={color}
        strokeWidth="48"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80 160C88.8366 160 96 152.837 96 144C96 135.163 88.8366 128 80 128C71.1634 128 64 135.163 64 144C64 152.837 71.1634 160 80 160Z"
        stroke={color}
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80 272C88.8366 272 96 264.837 96 256C96 247.163 88.8366 240 80 240C71.1634 240 64 247.163 64 256C64 264.837 71.1634 272 80 272Z"
        stroke={color}
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80 384C88.8366 384 96 376.837 96 368C96 359.163 88.8366 352 80 352C71.1634 352 64 359.163 64 368C64 376.837 71.1634 384 80 384Z"
        stroke={color}
        strokeWidth="32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
