import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import styled from 'styled-components'
import TabMenu from '../../components/common/TabMenu'
import KPIOrganization from '../../components/feature/kpi-management/KPIOrganization'
import KPIOrganizationArchive from '../../components/feature/kpi-management/KPIOrganizationArchive'
import KPIDepartment from '../../components/feature/kpi-management/KPIDepartment'

const Div = styled.div`
  // box model style
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }

  // typo style
  h1,
  p {
    margin: 0;
  }
  h1 {
    color: var(--Gray-900);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  p {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`

const tabMenuList = [
  {
    key: 'organization',
    name: 'Organization',
  },
  {
    key: 'my-department',
    name: 'My Department',
  },
  {
    key: 'archive',
    name: 'Archive',
  },
]
function KPIAnnoucement() {
  const [tab, setTab] = useState('organization')
  const [searchParams, setSearchParams] = useSearchParams()

  const handleTab = (newTab) => {
    setSearchParams({ tab: newTab })
  }

  useEffect(() => {
    const currentTab = searchParams.get('tab')
    setTab(currentTab)
  }, [searchParams])

  return (
    <Div>
      <header className="mb-20-px">
        <h1>KPIs Annoucement</h1>
        <p>Manage and setup KPI for company, departments, and employees.</p>
      </header>

      <Divider className="mb-24-px" />

      <TabMenu className="mb-24-px" tabMenuList={tabMenuList} tab={tab} setTab={handleTab} />

      {tab === 'organization' ? (
        <KPIOrganization />
      ) : tab === 'archive' ? (
        <KPIOrganizationArchive />
      ) : tab === 'my-department' ? (
        <KPIDepartment />
      ) : (
        tab
      )}
    </Div>
  )
}

export default KPIAnnoucement
