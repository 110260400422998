import { combineReducers } from 'redux'
import { GET_SOW_BY_DEPARTMENT_ID, CREATE_SOW, UPDATE_STATUS_SOW, GET_SOW_BY_ID, DELETE_SOW } from '../actionTypes'

const initialState = {
  sows: [],
  sowInfo: {},
  total: 1,
  page: 1,
  limit: 10,
  pageCount: 1,
  state: '',
  isLoading: false,
}

const scopeOfWorkReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SOW_BY_DEPARTMENT_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_SOW_BY_DEPARTMENT_ID.REQUEST',
      }
    case GET_SOW_BY_DEPARTMENT_ID.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        sows: data,
        total: meta.pagination.total,
        limit: meta.pagination.pageSize,
        page: meta.pagination.page,
        pageCount: meta.pagination.pageCount,
        isLoading: false,
        state: 'GET_SOW_BY_DEPARTMENT_ID.SUCCESS',
      }
    }
    case GET_SOW_BY_DEPARTMENT_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_SOW_BY_DEPARTMENT_ID.FAILURE',
      }
    case GET_SOW_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_SOW_BY_ID.REQUEST',
      }
    case GET_SOW_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        sowInfo: data,
        isLoading: false,
        state: 'GET_SOW_BY_ID.SUCCESS',
      }
    }
    case GET_SOW_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_SOW_BY_ID.FAILURE',
      }
    case CREATE_SOW.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_SOW.REQUEST',
      }
    case CREATE_SOW.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_SOW.SUCCESS',
      }
    }
    case CREATE_SOW.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_SOW.FAILURE',
      }
    case UPDATE_STATUS_SOW.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'UPDATE_STATUS_SOW.REQUEST',
      }
    case UPDATE_STATUS_SOW.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_STATUS_SOW.SUCCESS',
      }
    }
    case UPDATE_STATUS_SOW.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_STATUS_SOW.FAILURE',
      }
    case DELETE_SOW.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_SOW.REQUEST',
      }
    case DELETE_SOW.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_SOW.SUCCESS',
      }
    }
    case DELETE_SOW.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_SOW.FAILURE',
      }
    default:
      return state
  }
}

export default combineReducers({
  scopeOfWorkReducer,
})
