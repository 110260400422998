import React from 'react'
import styled from 'styled-components'

import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'

const StyledRadio = styled(Radio)`
  &.MuiRadio-colorPrimary.Mui-checked {
    color: var(--Primary-600);
  }
`
const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 0.875rem;
  }
`

function RadioButton({ label, optionList, value, name, handleChange, disabled }) {
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">{label}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-radio-buttons-group-label"
        value={value}
        onChange={handleChange}
        name={name}
      >
        {optionList.map((v, i) => (
          <StyledFormControlLabel
            key={i}
            value={v.value}
            control={<StyledRadio />}
            label={v.text}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default RadioButton
