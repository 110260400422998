import moment from 'moment'
import IconFileCsv from '../components/widget/icon/iconUpload/IconCsv'
import IconFileAi from '../components/widget/icon/iconUpload/IconFileAi'
import IconFileEps from '../components/widget/icon/iconUpload/IconFileEps'
import IconFileFig from '../components/widget/icon/iconUpload/IconFileFig'
import IconFileGeneral from '../components/widget/icon/iconUpload/IconFileGeneral'
import IconFileGif from '../components/widget/icon/iconUpload/IconFileGif'
import IconFileImg from '../components/widget/icon/iconUpload/IconFileImg'
import IconFileIndd from '../components/widget/icon/iconUpload/IconFileIndd'
import IconFileJpeg from '../components/widget/icon/iconUpload/IconFileJpeg'
import IconFileJpg from '../components/widget/icon/iconUpload/IconFileJpg'
import IconFileMp3 from '../components/widget/icon/iconUpload/IconFileMp3'
import IconFileMp4 from '../components/widget/icon/iconUpload/IconFileMp4'
import IconFilePdf from '../components/widget/icon/iconUpload/IconFilePdf'
import IconFilePng from '../components/widget/icon/iconUpload/IconFilePng'
import IconFilePpt from '../components/widget/icon/iconUpload/IconFilePpt'
import IconFilePptx from '../components/widget/icon/iconUpload/IconFilePptx'
import IconFilePsd from '../components/widget/icon/iconUpload/IconFilePsd'
import IconFileRar from '../components/widget/icon/iconUpload/IconFileRar'
import IconFileSvg from '../components/widget/icon/iconUpload/IconFileSvg'
import IconFileTiff from '../components/widget/icon/iconUpload/IconFileTiff'
import IconFileTxt from '../components/widget/icon/iconUpload/IconFileTxt'
import IconFileWebP from '../components/widget/icon/iconUpload/IconFileWebP'
import IconFileWord from '../components/widget/icon/iconUpload/IconFileWord'
import IconFileXd from '../components/widget/icon/iconUpload/IconFileXd'
import IconFileXls from '../components/widget/icon/iconUpload/IconFileXls'
import IconFileXlsx from '../components/widget/icon/iconUpload/IconFileXlsx'
import IconFileZip from '../components/widget/icon/iconUpload/IconFileZip'

export const dlog = (suffix, prefix = '') => {
  const tmpSuffix =
    Array.isArray(suffix) || typeof suffix == 'object' ? JSON.stringify(suffix, null, 2) : suffix.toString()
}

export const removeArrayItemByIndex = (arr, index) => {
  const tmp = [...arr]
  if (index > -1 && index < tmp.length) {
    tmp.splice(index, 1)
  }
  return tmp
}

export const convertDateFormat = (dateTime, format = 'DD MMM YYYY hh:mm A') => {
  const formattedDateTime = moment(dateTime).format(format)
  return formattedDateTime ? formattedDateTime : '-'
}

export const calculateTotalPrice = (productList) => {
  let tmpTotalPrice = 0
  for (let i = 0; i < productList.length; i++) {
    let sumPrice = 0
    sumPrice = productList[i].quantity * productList[i].price
    tmpTotalPrice = tmpTotalPrice + sumPrice
  }
  //
  return tmpTotalPrice
}

export const calculatePurchaseTotalPrice = (productList) => {
  let tmpTotalPrice = 0
  for (let i = 0; i < productList.length; i++) {
    let sumPrice = 0
    sumPrice =
      productList[i].quantity * productList[i].price -
      (productList[i].quantity *
        productList[i].price *
        (productList[i].discount_percent ? productList[i].discount_percent : 0)) /
        100
    tmpTotalPrice = tmpTotalPrice + sumPrice
  }
  //
  return tmpTotalPrice
}

export const calculateDiscount = (totalPrice, discountPercent) => {
  if (discountPercent == 0) {
    return {
      discountPrice: 0,
      totalPrice,
    }
  }
  let tmpDiscountPrice = (totalPrice / (100 / discountPercent)).toFixed(2)

  let currentTotalPrice = totalPrice - tmpDiscountPrice

  return {
    discountPrice: tmpDiscountPrice,
    totalPrice: currentTotalPrice,
  }
}

export const calculatePriceAfterTax = (isIncludeTax, isVat, holdingTaxPercent, totalPrice) => {
  if (isIncludeTax) {
    let tmpPriceVat = (totalPrice * 7) / 107
    let tmpPriceAfterVat = (totalPrice - tmpPriceVat).toFixed(2)
    let priceHoldingTax = 0
    if (holdingTaxPercent > 0) {
      priceHoldingTax = (tmpPriceAfterVat * holdingTaxPercent) / 100
    }

    return {
      priceAfterVat: tmpPriceAfterVat,
      priceVat: tmpPriceVat,
      priceWithHoldingTax: priceHoldingTax.toFixed(2),
      summaryPrice: totalPrice - priceHoldingTax,
    }
  } else {
    //ไม่ include tax
    let tmpPriceVat = 0
    if (isVat) {
      //คิด vat 7%
      tmpPriceVat = totalPrice * 0.07
    } else {
      tmpPriceVat = 0
    }
    let tmpPriceAfterVat = totalPrice + tmpPriceVat
    let priceHoldingTax = 0
    if (holdingTaxPercent > 0) {
      priceHoldingTax = (totalPrice * holdingTaxPercent) / 100
    }

    return {
      priceAfterVat: tmpPriceAfterVat,
      priceVat: tmpPriceVat,
      priceWithHoldingTax: priceHoldingTax,
      summaryPrice: tmpPriceAfterVat - priceHoldingTax,
    }
  }
}

export const convertIntComma = (val) => {
  if (val) {
    let tmpVal = val.toString().replace(/\D/g, '')
    let tmp = (Number(tmpVal) || '').toLocaleString()
    return tmp
  }
}

export const convertNumberToCommaTwodecimal = (myNumber) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
  const tmpTotal = Number(myNumber).toLocaleString('en', options)
  return tmpTotal
}

export const generateIconFileByType = (fileType) => {
  console.log('fileType: ' + JSON.stringify(fileType, null, 2))

  if (fileType == '.pdf') {
    return <IconFilePdf />
  } else if (fileType == '.word') {
    return <IconFileWord />
  } else if (fileType == '.xls') {
    return <IconFileXls />
  } else if (fileType == '.xlsx') {
    return <IconFileXlsx />
  } else if (fileType == '.csv') {
    return <IconFileCsv />
  } else if (fileType == '.ppt') {
    return <IconFilePpt />
  } else if (fileType == '.pptx') {
    return <IconFilePptx />
  } else if (fileType == '.txt') {
    return <IconFileTxt />
  } else if (fileType == '.zip') {
    return <IconFileZip />
  } else if (fileType == '.rar') {
    return <IconFileRar />
  } else if (fileType == '.mp4') {
    return <IconFileMp4 />
  } else if (fileType == '.mp3') {
    return <IconFileMp3 />
  } else if (fileType == '.img') {
    return <IconFileImg />
  } else if (fileType == '.jpg') {
    return <IconFileJpg />
  } else if (fileType == '.png') {
    return <IconFilePng />
  } else if (fileType == '.jpeg') {
    return <IconFileJpeg />
  } else if (fileType == '.tiff') {
    return <IconFileTiff />
  } else if (fileType == '.gif') {
    return <IconFileGif />
  } else if (fileType == '.svg') {
    return <IconFileSvg />
  } else if (fileType == '.eps') {
    return <IconFileEps />
  } else if (fileType == '.webp') {
    return <IconFileWebP />
  } else if (fileType == '.fig') {
    return <IconFileFig />
  } else if (fileType == '.ai') {
    return <IconFileAi />
  } else if (fileType == '.xd') {
    return <IconFileXd />
  } else if (fileType == '.psd') {
    return <IconFilePsd />
  } else if (fileType == '.indd') {
    return <IconFileIndd />
  }

  return <IconFileGeneral />
}

export const generateId = (length) => {
  var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var result = ''
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
  }
  return result
}

export const numberWithCommas = (num) => {
  let parts = num.toString().split(".");
  parts[0] = parts[0].replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};
