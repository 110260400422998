import React, { useState } from 'react'

import styled from 'styled-components'

import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;

  .icon {
    color: var(--Primary-600);
  }
  .item-align-vertical {
    vertical-align: middle;
  }
`

// Accordion Header
const AccordionHeader = styled.div`
  cursor: pointer;
  padding: 15px;
  background-color: var(--Base-White);
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  &:hover {
    background-color: var(--Gray-200);
  }
`

// Accordion Content
const AccordionContent = styled.div`
  overflow: hidden;
  transition: max-height 0.6s ease;
`

// Individual Item
const Item = styled.div``

const NewAccordion = ({ items }) => {
  const [openSections, setOpenSections] = useState({})

  const toggle = (index) => {
    const isOpen = !!openSections[index]
    setOpenSections({
      ...openSections,
      [index]: !isOpen,
    })
  }

  return (
    <AccordionSection>
      {items.map((item, index) => (
        <Item key={index}>
          <AccordionHeader onClick={() => toggle(index)}>
            <MdOutlineKeyboardArrowRight size={24} className="icon item-align-vertical" />
            <span className="item-align-vertical">{item.title}</span>
          </AccordionHeader>
          <AccordionContent style={{ maxHeight: openSections[index] ? '1000px' : '0' }}>
            {item.content}
          </AccordionContent>
        </Item>
      ))}
    </AccordionSection>
  )
}

export default NewAccordion
