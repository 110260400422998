import React from 'react'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'; 
import styled from 'styled-components'
import "quill/dist/quill.snow.css";

export default function DeltaToHtml({ contents}) {
  let tmpConvertDeltaContent = contents?.substring(0, 1) == "[" ? JSON.parse(contents) : contents;
  var converter = new QuillDeltaToHtmlConverter(tmpConvertDeltaContent, {});
  var html = converter.convert(); 

  return (
    <Div>
      <div className="ql-editor">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Div>
  )
}

const Div = styled.div`
  color: var(--Gray-600);
  svg{
    width: auto;
    display: inline;
  }

  .ql-editor{
    padding: 0 !important;
  }

  svg path{
    fill: var(--Gray-600);
  }
`;
