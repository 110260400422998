/**
 * Represents the initial pagination configuration.
 * @typedef {Object} InitPagination
 * @property {number} page - The current page.
 * @property {number} pageSize - The number of items per page.
 * @property {number} pageCount - The total number of pages.
 * @property {number} total - The total number of items.
 */

/**
 * The initial pagination configuration.
 * @type {InitPagination}
 */
export const initPagination = {
  page: 1,
  pageSize: 10,
  pageCount: 0,
  total: 0,
}
