import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { numberWithCommas } from '../../../utils/common'

import styled from 'styled-components'
import { Stack } from '@mui/material'
import Button from '../../form/button/Button'
import ButtonOutline from '../../form/button/ButtonOutline'
import SidePanel from '../../common/SidePanel'
import KPITableDataForDepartment from './KPITableDataForDepartment'
import CreateKPIAssignForDepartment from './CreateKPIAssignForDepartment'
import EditKPIAssignForDepartment from './EditKPIAssignForDepartment'

import { AiOutlineAim, AiOutlineHistory } from 'react-icons/ai'
import { CgFileDocument } from 'react-icons/cg'
import { LuPen } from 'react-icons/lu'
import { FiUser } from 'react-icons/fi'

const Div = styled.div`
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }
  h3,
  p {
    margin: 0;
  }

  h3 {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  p {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .highlight-text {
    color: var(--Primary-700);
  }

  .icon-color {
    color: var(--Primary-600);
  }
`
const KPIDepartmentItem = styled.div`
  margin-bottom: 76px;
`
const NoDataContainer = styled.div`
  margin: 50px 0;
  text-align: center;

  .icon-container {
    display: inline-block;
    border-radius: 10px;
    background-color: var(--Primary-200);
    padding: 12px;
    margin-bottom: 16px;

    .icon-no-data {
      color: var(--Primary-700);
    }
  }
  .no-info-text {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
`

function KPIDepartment() {
  // external hook
  const { t } = useTranslation()
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))

  const [isCreateMode, setIsCreateMode] = useState(true)
  // intiate data
  const initialKpiDepartments = [
    {
      id: 1,
      kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (Demo 1)',
      description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
      target_type: 'Number',
      target_value: 23452,
      unit: 'usd',
      start_date: '2024-02-06',
      end_date: '2024-02-29',
      createdAt: '2024-02-06T08:23:15.931Z',
      updatedAt: '2024-02-06T08:30:24.269Z',
      status: 'active',
      sub_kpi: [
        {
          id: 1,
          kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (sub kpi 1)',
          description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
          target_type: 'Percent',
          target_value: 33,
          unit: 'percentage',
          start_date: '2024-02-06',
          end_date: '2024-02-29',
          employee: 'Jomphop Saibuatong, Fullstack developer',
        },
        {
          id: 2,
          kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (sub kpi 2)',
          description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
          target_type: 'Percent',
          target_value: 33,
          unit: 'percentage',
          start_date: '2024-02-06',
          end_date: '2024-02-29',
          employee: 'Jomphop Saibuatong, Fullstack developer',
        },
      ],
    },
    {
      id: 2,
      kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (Demo 2)',
      description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
      target_type: 'Percent',
      target_value: 33,
      unit: 'percentage',
      start_date: '2024-02-06',
      end_date: '2024-02-29',
      createdAt: '2024-02-06T08:30:24.230Z',
      updatedAt: '2024-02-06T08:30:24.230Z',
      status: 'active',
      sub_kpi: [
        {
          id: 1,
          kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (sub kpi 1)',
          description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
          target_type: 'Percent',
          target_value: 33,
          unit: 'percentage',
          start_date: '2024-02-06',
          end_date: '2024-02-29',
          employee: 'Jomphop Saibuatong, Fullstack developer',
        },
        {
          id: 2,
          kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (sub kpi 2)',
          description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
          target_type: 'Percent',
          target_value: 33,
          unit: 'percentage',
          start_date: '2024-02-06',
          end_date: '2024-02-29',
          employee: 'Jomphop Saibuatong, Fullstack developer',
        },
      ],
    },
    {
      id: 3,
      kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (Demo 3)',
      description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
      target_type: 'Percent',
      target_value: 33,
      unit: 'percentage',
      start_date: '2024-02-06',
      end_date: '2024-02-29',
      createdAt: '2024-02-06T08:30:24.252Z',
      updatedAt: '2024-02-06T08:30:24.252Z',
      status: 'active',
      sub_kpi: [],
    },
  ]

  // dynamic width side panel
  const [widthWindow, setWidthWindow] = useState(window.innerWidth)
  const [widthSidePanel, setWidthSidePanel] = useState()
  const [widthHistoryLogSidePanel, setWidthHistoryLogSidePanel] = useState()

  useEffect(() => {
    const handleResize = () => setWidthWindow(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    // width side panel
    const newWidthSidePanel = widthWindow < 1024 ? widthWindow : widthWindow - 98
    setWidthSidePanel(newWidthSidePanel)

    // width history log side panel
    const newWidthHistoryLogSidePanel = widthWindow < 1024 ? widthWindow : (widthWindow * 35) / 100
    setWidthHistoryLogSidePanel(newWidthHistoryLogSidePanel)
  }, [widthWindow])

  // creation KPI sidepanel
  const [openCreateKPISidePanel, setOpenCreateKPISidePanel] = useState(false)
  const openCreationKPISidePanel = () => {
    setOpenCreateKPISidePanel(true)
  }
  const closeCreationKPISidePanel = () => {
    setOpenCreateKPISidePanel(false)
  }

  // editing KPI sidepanel
  const [openEditKPISidePanel, setOpenEditKPISidePanel] = useState(false)
  const openEditingKPISidePanel = () => {
    setOpenEditKPISidePanel(true)
  }
  const closeEditingKPISidePanel = () => {
    setOpenEditKPISidePanel(false)
  }

  const openHistoryLog = () => {}

  return (
    <Div>
      <Stack className="mb-32-px" direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
        <Button onClick={() => setIsCreateMode(!isCreateMode)}>Toggle Mode</Button>
        <p>2 Jan 2024 18:00 by Oliva Rylm</p>
      </Stack>
      <Stack className="mb-32-px" direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <AiOutlineAim className="icon-color " size={24} />
          <div>
            <h3>{employee_info.department} Department</h3>
            <p>Target assigned to employee within department</p>
          </div>
        </Stack>

        {isCreateMode ? (
          <Stack direction="row" spacing={2}>
            <Button append={<FiUser size={20} />} onClick={openCreationKPISidePanel}>
              Assign KPI
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" spacing={2}>
            <Button append={<LuPen />} onClick={openEditingKPISidePanel}>
              {t('Edit')}
            </Button>
            {/* <ButtonOutline>
              <AiOutlineHistory size={20} onClick={openHistoryLog} />
            </ButtonOutline> */}
          </Stack>
        )}
      </Stack>

      {initialKpiDepartments.map((v, i) => (
        <KPIDepartmentItem key={v.id}>
          <h3>
            {i + 1}. {v.kpi_name}
          </h3>
          <p>
            Target{' '}
            <span className="highlight-text">
              {numberWithCommas(v.target_value)} {v.unit}
            </span>
          </p>
          <p className="mb-20-px">
            Start Date <span className="highlight-text">{v.start_date}</span> End Date{' '}
            <span className="highlight-text">{v.end_date}</span>
          </p>

          {v.sub_kpi.length > 0 ? (
            <KPITableDataForDepartment data={v.sub_kpi} />
          ) : (
            <NoDataContainer>
              <div className="icon-container">
                <CgFileDocument className="icon-no-data" size={28} />
              </div>

              <div className="no-info-text">No information</div>
              <div>Please click “Assign KPI”</div>
            </NoDataContainer>
          )}
        </KPIDepartmentItem>
      ))}

      {/* Side Panel */}
      <SidePanel isOpen={openCreateKPISidePanel} setIsOpen={setOpenCreateKPISidePanel} width={widthSidePanel}>
        <CreateKPIAssignForDepartment open={openCreateKPISidePanel} onClose={closeCreationKPISidePanel} />
      </SidePanel>
      <SidePanel isOpen={openEditKPISidePanel} setIsOpen={setOpenEditKPISidePanel} width={widthSidePanel}>
        <EditKPIAssignForDepartment open={openEditKPISidePanel} onClose={closeEditingKPISidePanel} />
      </SidePanel>
    </Div>
  )
}

export default KPIDepartment
