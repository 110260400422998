import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { getDepartmentsAndPositionsUseAuthen } from '../../redux/actions/master'

import styled from 'styled-components'
import Dashboard from '../../components/feature/dashboard/Dashboard'
import EmployeeList from '../../components/feature/dashboard/EmployeeList'

import { LuAtom } from 'react-icons/lu'

// style
const Wrapper = styled.div`
  .mr-8-px {
    margin-right: 8px;
  }
  .mr-12-px {
    margin-right: 12px;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-10-px {
    margin-bottom: 10px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }
  .header-feature {
    color: var(--Gray-900);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
  }
  .sub-header-feature {
    color: var(--Gray-600);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;

    display: flex;
    justify-content: space-between;

    .powered-by {
      color: var(--Gray-500);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;

      display: flex;
      align-items: center;
    }
  }
  .divider {
    background-color: var(--Gray-200);
    height: 1px;
    margin: 20px 0;
  }
`
const TabMenuContainer = styled.div`
  display: flex;
  align-items: center;
  .tab-item {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-500);

    padding: 0.75rem;
    &.active {
      color: var(--Primary-700);
      border-bottom: 2px solid var(--Primary-700);
      background: var(--Primary-50);
    }
    :hover {
      cursor: pointer;
    }
  }
`

function EmployeeActivity() {
  const dispatch = useDispatch()
  const initLang = localStorage.getItem('lang')
  // data
  const [tabMenu, setTabMenu] = useState('EmpActivities_Dashboard')
  const [menuTabList, setMenuTabList] = useState([])
  //--- ST ส่วนของ Permission LV3-4
  const { permission } = JSON.parse(localStorage.getItem('admin'))
  useEffect(() => {
    const this_sub_menu_permission = permission['Dashboard'].filter((it_2) => it_2.path === '/dashboard/employee-activities'); 
    if(this_sub_menu_permission.length > 0){
      const tab_permission = this_sub_menu_permission[0].permission_tab?.filter((it_2) => it_2.is_read === true)
      let temp_tab = [];
      let index_tab = 0;
      for (const  objtab_permission of tab_permission) {
        temp_tab.push({
          name : initLang == 'th' ? objtab_permission.menu_setting.name_th : objtab_permission.menu_setting.name_en,
          value : objtab_permission.menu_setting.rout_path,
          active_status : index_tab == 0 ? true : false
        })
        
        if(index_tab == 0){
          setTabMenu(objtab_permission.menu_setting.rout_path)
        }
        index_tab = index_tab + 1;
      }
      setMenuTabList(temp_tab)
    }
  }, [])
  //--- ED ส่วนของ Permission LV3-4

  useEffect(() => {
    dispatch(getDepartmentsAndPositionsUseAuthen())
  }, [])

  const toggleMenuTab = (menu) => {
    setTabMenu(menu)
  }

  return (
    <Wrapper>
      <div className="header-feature">Employee Activity</div>
      <div className="sub-header-feature">
        <div>Gain insights into individual and team activities and performance</div>
        <div className="powered-by">
          <LuAtom className="mr-8-px" size={22} />
          Powered by AI
        </div>
      </div>

      <div className="divider"></div>

      <TabMenuContainer className="mb-32-px">
        {
          /*
          <div
            className={`tab-item ${tabMenu === 'dashboard' ? 'active' : ''}`}
            onClick={() => toggleMenuTab('dashboard')}
          >
            Dashboard
          </div>
          <div
            className={`tab-item ${tabMenu === 'employee-list' ? 'active' : ''}`}
            onClick={() => toggleMenuTab('employee-list')}
          >
            Employee list
          </div>
          */
        }


        {menuTabList?.map((item, index) => (
          <div
            className={`tab-item ${tabMenu === item.value ? 'active' : ''}`}
            onClick={() => toggleMenuTab(item.value)}
          >
            {item.name}
          </div>
        ))}  
      </TabMenuContainer>

      {tabMenu === 'EmpActivities_Dashboard' ? <Dashboard /> : <EmployeeList />}
    </Wrapper>
  )
}

export default EmployeeActivity
