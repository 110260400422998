import axios from 'axios'
import { BASE_LOG_API } from '../configs/app'
import { getAdminUser } from './common'
const fetchApi = async (options = {}, doubleBearer = false) => {
  const { url, headers, ...restOptions } = options
  const defaultOptions = {
    baseURL: BASE_LOG_API+"/log",
    timeout: 60000,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {},
    params: {},
  }
  defaultOptions.headers = {
    ...defaultOptions.headers,
    ...headers,
  }
  try {
    const { data } = await axios.request({
      url,
      ...defaultOptions,
      ...restOptions,
    })

    return data
  } catch (error) {

  }
}
const postAPI = (options = {}, ...arg) =>
  fetchApi(
    {
      ...options,
      method: 'POST',
    },
    ...arg
)
export const log_ = (feature,function_name,action,detail) =>  postAPI({
  data : {feature:feature,function_name:function_name,action:action,detail:detail,name:getAdminUser().first_name
+" "+getAdminUser().last_name,userId:getAdminUser().id  },
  headers: {
    'Content-Type': 'application/json',
  },
})
