import React, { useState, useEffect, useRef } from 'react'
import ReactCrop from 'react-image-crop'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import Button from './button/Button'

import { FiZoomIn, FiZoomOut } from 'react-icons/fi'

const Div = styled.div`
  img {
    display: inline-block;
    width: 100%;
    max-height: 250px;
    object-fit: contain;

    border-radius: 12px;
  }
`
const FileInputWrapper = styled.div`
  width: 100%;
  height: 300px;

  border-radius: 12px;
  border: 1px solid var(--Gray-200);
  background: var(--Base-White);

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  margin-bottom: 1rem;

  ${(props) =>
    props.image
      ? `
    background-image: url(${props.image});
    border: none;
  `
      : `
      width: 100%;
    height: 300px;
    `}
`
const HiddenInput = styled.input`
  display: none;
`
const TextPanelUpload = styled.div`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  color: var(--Gray-600);
  text-align: center;

  .highlight {
    color: var(--Primary-700);
    font-weight: 600;
    margin-right: 0.5rem;
  }
  .desc {
    font-size: 0.75rem;
  }
`
const ZoomController = styled.div`
  display: flex;
  justify-content: center;
`
const ZoomSlider = styled.input`
  width: 50vw;
  max-width: 300px;
`
const ReactCropContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`

function ImageUploadCrop({ setCroppedImageUrl }) {
  const { t } = useTranslation()
  const imageRef = useRef(null)
  const [imageSrc, setImageSrc] = useState(null)
  const [crop, setCrop] = useState(null)
  const [zoom, setZoom] = useState(1)

  useEffect(() => {
    if (imageSrc) {
      setCroppedImageUrl(imageSrc)
    }
  }, [imageSrc])

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()

      reader.addEventListener('load', (event) => {
        // Create an Image object
        const img = new Image()
        img.onload = () => {
          // Create a canvas element
          const canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height

          // Draw the image onto the canvas
          const ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)

          // Get the PNG data URL and set it as the image source
          const pngBase64 = canvas.toDataURL('image/png')
          setImageSrc(pngBase64)
          setCrop({ unit: '%', width: 100, height: 100, x: 0, y: 0 })
        }

        // Set the source of the image to the read file
        img.src = event.target.result
      })

      reader.readAsDataURL(e.target.files[0])
    }
  }

  const handleOnCropComplete = (crop) => {
    if (!imageRef.current || !crop.width || !crop.height) return

    const actualCrop = {
      x: crop.x,
      y: crop.y,
      width: crop.width,
      height: crop.height,
    }

    const croppedImageBase64 = getCroppedImg(imageRef.current, actualCrop)
    setCroppedImageUrl(croppedImageBase64)
  }

  // old version
  // const getCroppedImg = (image, crop) => {
  //   const canvas = document.createElement('canvas')
  //   const ctx = canvas.getContext('2d')

  //   // Setting canvas size to the size of the cropping area
  //   canvas.width = crop.width
  //   canvas.height = crop.height

  //   // Correcting the crop dimensions for the image's scaling
  //   const scaleX = image.naturalWidth / image.width
  //   const scaleY = image.naturalHeight / image.height

  //   // Drawing the cropped image to the canvas
  //   ctx.drawImage(
  //     image,
  //     crop.x * scaleX,
  //     crop.y * scaleY,
  //     crop.width * scaleX,
  //     crop.height * scaleY,
  //     0,
  //     0,
  //     crop.width,
  //     crop.height
  //   )

  //   // Converting the canvas to a data URL
  //   return canvas.toDataURL('image/png')
  // }

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    // Adjust crop dimensions based on the zoom level
    const adjustedWidth = (crop.width * scaleX) / zoom
    const adjustedHeight = (crop.height * scaleY) / zoom

    // Adjust crop position based on the zoom level
    const adjustedX = ((crop.x - image.width / 2) * scaleX) / zoom + image.naturalWidth / 2
    const adjustedY = ((crop.y - image.height / 2) * scaleY) / zoom + image.naturalHeight / 2

    canvas.width = adjustedWidth
    canvas.height = adjustedHeight

    const ctx = canvas.getContext('2d')

    ctx.drawImage(image, adjustedX, adjustedY, adjustedWidth, adjustedHeight, 0, 0, adjustedWidth, adjustedHeight)

    return canvas.toDataURL('image/png')
  }

  const clearImage = () => {
    setZoom(1)
    setImageSrc(null)
    setCroppedImageUrl(null)
    setCrop(null)
  }

  const onZoomChange = (e) => {
    const newZoom = Number(e.target.value)
    setZoom(newZoom)
  }

  return (
    <Div>
      {imageSrc ? (
        <React.Fragment>
          <ReactCropContainer>
            <ReactCrop
              crop={crop}
              onChange={(newCrop) => setCrop(newCrop)}
              onComplete={handleOnCropComplete}
              keepSelection={false}
              aspect={16 / 9}
            >
              <img
                ref={imageRef}
                src={imageSrc}
                alt="Cropped Image"
                // style={{ width: '100%' }}
                style={{
                  transform: `scale(${zoom})`,
                  transformOrigin: 'center center',
                }}
              />
            </ReactCrop>
          </ReactCropContainer>

          {/* Zoom controls */}
          <ZoomController>
            <FiZoomOut size={25} />
            <ZoomSlider type="range" min={1} max={2} step={0.1} value={zoom} onChange={onZoomChange} />
            <FiZoomIn size={25} />
          </ZoomController>
        </React.Fragment>
      ) : (
        <FileInputWrapper onClick={() => document.getElementById('fileInput').click()}>
          <TextPanelUpload>
            <div>
              <span className="highlight">{t('drag_drop')}</span>
              <span>{t('drag_drop')}</span>
            </div>
            <div className="desc">SVG, PNG, JPG or GIF (max. 800x400px)</div>
          </TextPanelUpload>
          <HiddenInput id="fileInput" type="file" onChange={onSelectFile} accept="image/*" />
        </FileInputWrapper>
      )}

      <Button onClick={clearImage} disabled={!imageSrc}>
        X {t('clear')}
      </Button>
    </Div>
  )
}

export default ImageUploadCrop
