import { combineReducers } from 'redux'
import {
  GET_ROLE_LIST,
  GET_PERMISSION_ACTIONS,
  GET_PERMISSION_LIST,
  CREATE_ROLE,
  GET_ROLE_BY_ID,
  UPDATE_ROLE,
  DELETE_ROLE,
} from '../actionTypes'

const initialState = {
  accessRoleInfo: {},
  accessRoles: [],
  permissionActions: [],
  permissionList: [],
  total: 0,
  skip: 0,
  limit: 10,
  isLoading: false,
  state: '',
}
const accessRoleReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_ROLE_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_ROLE_LIST.REQUEST',
      }
    case GET_ROLE_LIST.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        accessRoles: data,
        total: meta.total,
        skip: meta.skip,
        limit: meta.limit,
        isLoading: false,
        state: 'GET_ROLE_LIST.SUCCESS',
      }
    }
    case GET_ROLE_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_ROLE_LIST.FAILURE',
      }
    case GET_ROLE_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_ROLE_BY_ID.REQUEST',
      }
    case GET_ROLE_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        accessRoleInfo: data,
        isLoading: false,
        state: 'GET_ROLE_BY_ID.SUCCESS',
      }
    }
    case GET_ROLE_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_ROLE_BY_ID.FAILURE',
      }
    case GET_PERMISSION_ACTIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_PERMISSION_ACTIONS.REQUEST',
      }
    case GET_PERMISSION_ACTIONS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        permissionActions: data,
        isLoading: false,
        state: 'GET_PERMISSION_ACTIONS.SUCCESS',
      }
    }
    case GET_PERMISSION_ACTIONS.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_PERMISSION_ACTIONS.FAILURE',
      }
    case GET_PERMISSION_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_PERMISSION_LIST.REQUEST',
      }
    case GET_PERMISSION_LIST.SUCCESS: {
      const { data } = action
      return {
        ...state,
        permissionList: data,
        isLoading: false,
        state: 'GET_PERMISSION_LIST.SUCCESS',
      }
    }
    case GET_PERMISSION_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_PERMISSION_LIST.FAILURE',
      }
    case CREATE_ROLE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_ROLE.REQUEST',
      }
    case CREATE_ROLE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_ROLE.SUCCESS',
      }
    }
    case CREATE_ROLE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_ROLE.FAILURE',
      }
    case UPDATE_ROLE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'UPDATE_ROLE.REQUEST:',
      }
    case UPDATE_ROLE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_ROLE.SUCCESS',
      }
    }
    case UPDATE_ROLE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_ROLE.FAILURE',
      }
    case DELETE_ROLE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_ROLE.REQUEST',
      }
    case DELETE_ROLE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_ROLE.SUCCESS',
      }
    }
    case DELETE_ROLE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_ROLE.FAILURE',
      }
    default:
      return state
  }
}

export default combineReducers({
  accessRoleReducer,
})
