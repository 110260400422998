import { combineReducers } from 'redux'
import {
  GET_WEEKLY_SUMMARY_REPORTS,
  GET_WEEKLY_SUMMARY_REPORT_BY_ID,
  CREATE_WEEKLY_SUMMARY_REPORT,
  UPDATE_WEEKLY_SUMMARY_REPORT,
  DELETE_WEEKLY_SUMMARY_REPORT,
  GET_PROJECT_TASKS,
  SET_MODAL_INFO,
} from '../actionTypes'

const initialState = {
  weeklySummaryReports: [],
  weeklySummaryReportInfo: {},
  projectTasks: [],
  total: 1,
  page: 1,
  pageCount: 1,
  pageSize: 10,
  isLoading: false,
  state: '',
  errorCase: {},
  modalInfo: {},
}

const weeklySummaryReportReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_WEEKLY_SUMMARY_REPORTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_WEEKLY_SUMMARY_REPORTS.REQUEST',
      }
    }
    case GET_WEEKLY_SUMMARY_REPORTS.SUCCESS: {
      const { data, pagination } = action
      return {
        ...state,
        weeklySummaryReports: data,
        total: pagination.total,
        page: pagination.page,
        pageCount: pagination.pageCount,
        pageSize: pagination.pageSize,
        isLoading: false,
        state: 'GET_WEEKLY_SUMMARY_REPORTS.SUCCESS',
      }
    }
    case GET_WEEKLY_SUMMARY_REPORTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_WEEKLY_SUMMARY_REPORTS.FAILURE',
      }
    }
    case GET_WEEKLY_SUMMARY_REPORT_BY_ID.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_WEEKLY_SUMMARY_REPORT_BY_ID.REQUEST',
      }
    }
    case GET_WEEKLY_SUMMARY_REPORT_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        weeklySummaryReportInfo: data,
        isLoading: false,
        state: 'GET_WEEKLY_SUMMARY_REPORT_BY_ID.SUCCESS',
      }
    }
    case GET_WEEKLY_SUMMARY_REPORT_BY_ID.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_WEEKLY_SUMMARY_REPORT_BY_ID.FAILURE',
      }
    }
    case CREATE_WEEKLY_SUMMARY_REPORT.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_WEEKLY_SUMMARY_REPORT.REQUEST',
      }
    }
    case CREATE_WEEKLY_SUMMARY_REPORT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_WEEKLY_SUMMARY_REPORT.SUCCESS',
      }
    }
    case CREATE_WEEKLY_SUMMARY_REPORT.FAILURE: {
      const { errorCase } = action

      return {
        ...state,
        isLoading: false,
        errorCase,
        state: 'CREATE_WEEKLY_SUMMARY_REPORT.FAILURE',
      }
    }
    case UPDATE_WEEKLY_SUMMARY_REPORT.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'UPDATE_WEEKLY_SUMMARY_REPORT.REQUEST',
      }
    }
    case UPDATE_WEEKLY_SUMMARY_REPORT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_WEEKLY_SUMMARY_REPORT.SUCCESS',
      }
    }
    case UPDATE_WEEKLY_SUMMARY_REPORT.FAILURE: {
      const { errorCase } = action
      return {
        ...state,
        isLoading: false,
        errorCase,
        state: 'UPDATE_WEEKLY_SUMMARY_REPORT.FAILURE',
      }
    }
    case DELETE_WEEKLY_SUMMARY_REPORT.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_WEEKLY_SUMMARY_REPORT.REQUEST',
      }
    }
    case DELETE_WEEKLY_SUMMARY_REPORT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_WEEKLY_SUMMARY_REPORT.SUCCESS',
      }
    }
    case DELETE_WEEKLY_SUMMARY_REPORT.FAILURE: {
      const { errorCase } = action
      return {
        ...state,
        isLoading: false,
        errorCase,
        state: 'DELETE_WEEKLY_SUMMARY_REPORT.FAILURE',
      }
    }
    case GET_PROJECT_TASKS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_PROJECT_TASKS.REQUEST',
      }
    }
    case GET_PROJECT_TASKS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        projectTasks: data,
        isLoading: false,
        state: 'GET_PROJECT_TASKS.SUCCESS',
      }
    }
    case GET_PROJECT_TASKS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_PROJECT_TASKS.FAILURE',
      }
    }
    case SET_MODAL_INFO: {
      const { data } = action
      return {
        ...state,
        modalInfo: data,
        isLoading: false,
      }
    }
    default:
      return state
  }
}

export default combineReducers({
  weeklySummaryReportReducer,
})
