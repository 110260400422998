import { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import Table from '../../components/common/Table'

import { BiEdit } from 'react-icons/bi'
import { FaCircle } from 'react-icons/fa'

import { getProductById, createProductlNote, getProductNotes } from '../../redux/actions/product'
import { selectProduct } from '../../redux/selectors'
import { useTranslation } from 'react-i18next'

const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .text-color-primary {
    color: #7f56d9;
  }

  .font-bold {
    font-weight: bold;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .content-header {
    margin-top: 24px;
  }

  .row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error {
    color: red;
  }

  .tab-highlight {
    display: inline;
    color: #7f56d9;
    font-weight: bold;
    border-bottom: 2px solid #7f56d9;
  }

  .content-container {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;

    .edit-btn-container {
      display: flex;
      justify-content: flex-end;
    }

    .info-container {
      display: flex;

      .info-1 {
        margin-right: 1rem;
      }
      .info-2 {
        flex: 1;
      }
    }

    .flex-container {
      display: flex;

      .product-container {
        flex: 1;

        .profile-display-mock {
          width: 50px;
          height: 50px;
          background: gray;
          border-radius: 50%;
        }
        .product-item {
          padding: 1rem 0;
          border-bottom: 1px solid #eaecf0;
        }
      }
      .note-container {
        flex: 1;
      }
    }
  }

  .data-table {
    display: flex;
    justify-content: space-between;
  }

  .mock-image {
    width: 500px;
    height: 500px;
    background-color: gray;
  }

  .header-box {
    background: #e9d7fe;
    padding: 1rem;
  }

  .badge {
    background: #ecfdf3;
    padding: 0.5rem;
    border-radius: 12px;
  }
`

const noteColumn = [
  {
    Header: 'Time',
    accessor: 'createdAt',
    disableSortBy: false,
  },
  {
    Header: 'Name',
    accessor: 'note_by',
    disableSortBy: false,
  },
  {
    Header: '	Note',
    accessor: 'note',
    disableSortBy: false,
  },
]
const limitLetter = 275

const ProductDetail = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { notes, productInfo } = useSelector(selectProduct)
  const [note, setNote] = useState('')
  const [isSubmitNote, setIsSubmitNote] = useState(false)
  const [limitChar, setLimitChar] = useState(limitLetter)

  useEffect(() => {
    dispatch(getProductNotes(id))
    dispatch(getProductById(id))
  }, [dispatch, id])

  useEffect(() => {
    const restLetter = limitLetter - note.length
    setLimitChar(restLetter)
  }, [note])

  const onSubmitNote = () => {
    setIsSubmitNote(true)
    if (note !== '') {
      const { user } = JSON.parse(localStorage.getItem('admin'))
      const request = {
        data: {
          note: note,
          note_by: `${user.firstname} ${user.lastname}`,
          product: id,
        },
      }
      dispatch(createProductlNote(request))
      setIsSubmitNote(false)
      setNote('')
    }
  }

  const displayDateFormat = (dateTime) => {
    const formattedDateTime = moment(dateTime).format('DD MMM YYYY hh:mm A')
    return formattedDateTime ? formattedDateTime : '-'
  }

  return (
    <Div>
      <header className="mb-1-rem">
        <div className="content-title">{t('product_review')}</div>
        <div className="content-header">
          <div className="tab-highlight">{t('product_information')}</div>
        </div>
      </header>
      <div className="content-container">
        <div className="edit-btn-container mb-1-rem">
          <Link className="create-bt" to={`/inventory-management/products/edit-products/${id}`}>
            <Button append={<BiEdit />}>{t('Edit')}</Button>
          </Link>
        </div>
        <div className="info-container mb-1-rem">
          {productInfo?.product_images && productInfo?.product_images[0]?.url ? (
            <img className="info-1" src={productInfo?.product_images[0]?.url} />
          ) : (
            <div className="mock-image info-1"></div>
          )}

          <div className="info-2">
            <div className="mb-1-rem">
              <span className="text-color-primary font-bold mr-1-rem">{t('status')}</span>
              <span className="badge mr-1-rem">
                <FaCircle size={10} color="#12B76A" />
                {t('sale')}
              </span>
              <span className="text-color-primary font-bold ">{productInfo?.sku}</span>
            </div>
            <div className="font-bold mb-1-rem">{t('wiper_cleaner')}</div>
            <div className="header-box text-color-primary font-bold mb-1-rem">{t('prince_n_a')}</div>
            <div className="data-table">
              <span className="font-bold mb-1-rem">{t('product_prince')}</span>
              <span>N/A</span>
            </div>
            <div className="data-table">
              <span className="font-bold mb-1-rem">{t('vat')} 7%</span>
              <span>N/A</span>
            </div>
            <div className="data-table">
              <span className="font-bold mb-1-rem">{t('tax')}</span>
              <span>N/A</span>
            </div>
            <div className="data-table">
              <span className="font-bold mb-1-rem">{t('total_prince')}</span>
              <span>N/A</span>
            </div>
          </div>
        </div>

        <div className="description-container  mb-1-rem">
          <div className="text-color-primary font-bold">{t('description')}</div>
          <div>{productInfo?.description}</div>
        </div>

        <div className="flex-container">
          <div className="product-container">
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('product_manager')}</div>
              <div className="flex-container">
                <div className="profile-display-mock mr-1-rem"></div>
                <div className="profile-info">
                  <div>{`${productInfo?.product_manager?.first_name} ${productInfo?.product_manager?.last_name}`}</div>
                  <div>{productInfo?.product_manager?.product_manager_id}</div>
                </div>
              </div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('brand')}</div>
              <div>{productInfo?.product_brand?.name}</div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('category')}</div>
              <div>{productInfo?.product_category?.product_category_name}</div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('segment')}</div>
              <div>{productInfo?.product_sagment?.name}</div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('type')}</div>
              <div>{productInfo?.product_type?.product_type_name}</div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('package')}</div>
              <div>{productInfo?.product_type?.product_type_name}</div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('last_update')}</div>
              <div>{displayDateFormat(productInfo?.updatedAt)} </div>
            </div>
          </div>
          <div className="note-container">
            <div className="modal-container">
              <div className="modal-header">{t('note')}</div>
              <div className="mb-1-rem">
                <Table columns={noteColumn} data={notes} />
              </div>
              <textarea
                className={`text-area mb-1-rem ${isSubmitNote && note == '' ? 'error' : ''}`}
                value={note}
                onChange={(e) => setNote(e.target.value.substring(0, 275))}
              ></textarea>
              {isSubmitNote && note == '' && <div className="error">{t('please_enter_a_message')}</div>}
              <div>{limitChar} {t('charater_left')}</div>
              <div className="dialog-button">
                <ButtonOutline
                  onClick={() => {
                    navigate('/inventory-management/products', {
                      replace: true,
                    })
                  }}
                >
                  Cancel
                </ButtonOutline>
                <Button onClick={onSubmitNote}>{t('submit')}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Div>
  )
}

export default ProductDetail
