import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { DateDisplay } from 'utils/common'
import { v4 as uuidv4 } from 'uuid'

import { useDispatch, useSelector } from 'react-redux'
import { selectActionPlan } from 'redux/selectors'
import { getOPSwList, getOPTaskList, getOPReviewerList, getOPApproverList, getOPCount } from 'redux/actions/actionPlan'

import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import SearchText from 'components/form/SearchText'
import TabGroupMenu2 from 'components/common/TabGroupMenu2'
import Dropdown from 'components/form/Dropdown'
import Table from 'components/common/Table'
import Badge from 'components/common/Badge'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Button from 'components/form/button/Button'
import OutlineCheckbox from 'components/form/OutlineCheckbox'
import BadgeSearch from 'components/common/BadgeSearch'

const Div = styled.div`
  .input-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
  }

  .highlight-text {
    color: var(--Primary-600);
    font-weight: 500;
  }

  .time-over {
    color: var(--Error-600);
  }

  .badgej-status-style {
    color: var(--warning-700);
    border-color: var(--warning-200);
    background: var(--warning-50);
  }
`

function OperationProcessDataContent({ add, onClose }) {
  // external hook
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const fetchOpSwList = useCallback(
    (filter = { page: 1, pageSize: 10, status: '' }) => {
      dispatch(getOPSwList(filter))
    },
    [dispatch]
  )
  const fetchOpTaskList = useCallback(
    (filter = { page: 1, pageSize: 10, status: '' }) => {
      dispatch(getOPTaskList(filter))
    },
    [dispatch]
  )
  const fetchOpReviewerList = useCallback(
    (filter = { page: 1, pageSize: 10, status: '' }) => {
      dispatch(getOPReviewerList(filter))
    },
    [dispatch]
  )
  const fetchOpApproverList = useCallback(
    (filter = { page: 1, pageSize: 10, status: '' }) => {
      dispatch(getOPApproverList(filter))
    },
    [dispatch]
  )
  const fetchOPCount = useCallback(() => {
    dispatch(getOPCount())
  }, [dispatch])

  useEffect(() => {
    fetchOpSwList()
    fetchOPCount()
  }, [])

  // tab menu options
  const initialTabMenuOptions = [
    {
      text: 'Scope of Work',
      value: 'scope-of-work',
      number: 6,
    },
    {
      text: 'Task',
      value: 'task',
      number: 4,
    },
    {
      text: 'Review',
      value: 'review',
      number: 2,
    },
    {
      text: 'Approvals',
      value: 'approvals',
      number: 4,
    },
  ]
  const { totalOP } = useSelector(selectActionPlan)
  const [tabMenuOptions, setTabMenuOptions] = useState([])

  useEffect(() => {
    const newTabMenuOptions = initialTabMenuOptions.map((v) => ({
      ...v,
      number:
        v.value === 'scope-of-work'
          ? totalOP?.op_sw_count
          : v.value === 'task'
          ? totalOP?.op_task_count
          : v.value === 'review'
          ? totalOP?.op_reviewer_count
          : totalOP?.op_approver_count,
    }))
    setTabMenuOptions(newTabMenuOptions)
  }, [totalOP])

  // tab menu selected
  const [tabMenu, setTabMenu] = useState('scope-of-work')

  const onChangeTab = (value) => {
    setTabMenu(value)
    if (value === 'scope-of-work') {
      fetchOpSwList()
    } else if (value === 'task') {
      fetchOpTaskList()
    } else if (value === 'review') {
      fetchOpReviewerList()
    } else {
      fetchOpApproverList()
    }
    clearFilter()
  }

  // page,pageCount
  const { page, pageCount, pageSize } = useSelector(selectActionPlan)

  // sow data
  const initialOPSoW = [
    {
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: t('id'),
      accessor: 'code',
      disableSortBy: false,
    },
    {
      Header: t('sop'),
      accessor: 'sop',
      disableSortBy: false,
    },
    {
      Header: t('sow'),
      accessor: 'sow',
      disableSortBy: false,
    },
    {
      Header: t('due_date'),
      accessor: 'due_date',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
  ]
  const { opSwList } = useSelector(selectActionPlan)
  const [sowList, setSowList] = useState([])
  const [sowFormList, setSowFormList] = useState([])

  const onSelectCheckBoxForSoW = (id, event) => {
    const { checked } = event.target
    setSowFormList((prev) =>
      prev.map((v) => {
        if (v.id === id)
          return {
            ...v,
            is_checked: checked,
          }
        else
          return {
            ...v,
            is_checked: false,
          }
      })
    )
  }

  useEffect(() => {
    const newOpFormList = opSwList.map((v) => ({
      id: v.id,
      code: v.code,
      sop: v.sp.name,
      sow: v.name,
      due_date: v.due_date,
      status: v.status,
      is_checked: false,
    }))
    setSowFormList(newOpFormList)
  }, [opSwList])
  useEffect(() => {
    const newOpList = sowFormList.map((v, i) => ({
      ...v,
      checkbox: <OutlineCheckbox checked={v.is_checked} onChange={(event) => onSelectCheckBoxForSoW(v.id, event)} />,
      status: <Badge className="badgej-status-style">{v.status}</Badge>,
      sow: <Box className="highlight-text">{v.sow}</Box>,
      due_date: DateDisplay(v.due_date),
    }))
    setSowList(newOpList)
  }, [sowFormList])

  // task data
  const initialOPTask = [
    {
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: t('id'),
      accessor: 'code',
      disableSortBy: false,
    },
    {
      Header: t('sop'),
      accessor: 'sop',
      disableSortBy: false,
    },
    {
      Header: t('sow'),
      accessor: 'sow',
      disableSortBy: false,
    },
    {
      Header: t('task'),
      accessor: 'task',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('due_date'),
      accessor: 'due_date',
      disableSortBy: false,
    },
    {
      Header: t('created_by'),
      accessor: 'createdBy',
      disableSortBy: false,
    },
  ]
  const { opTaskList } = useSelector(selectActionPlan)
  const [taskList, setTaskList] = useState([])
  const [taskFormList, setTaskFormList] = useState([])

  const onSelectCheckBoxForTask = (id, event) => {
    const { checked } = event.target
    setTaskFormList((prev) =>
      prev.map((v) => {
        if (v.id === id)
          return {
            ...v,
            is_checked: checked,
          }
        else
          return {
            ...v,
            is_checked: false,
          }
      })
    )
  }

  useEffect(() => {
    const newOpFormList = opTaskList.map((v) => ({
      id: v.id,
      code: v?.code,
      task: v?.name,
      sop: v?.sw?.sp?.name,
      sow: v?.sw?.name,
      due_date: v?.sw?.due_date,
      status: v?.status,
      createdBy: `${v?.sw?.sp?.employee?.first_name} ${v?.sw?.sp?.employee?.last_name}`,
      is_checked: false,
    }))
    setTaskFormList(newOpFormList)
  }, [opTaskList])
  useEffect(() => {
    const newOpList = taskFormList.map((v, i) => ({
      ...v,
      checkbox: <OutlineCheckbox checked={v.is_checked} onChange={(event) => onSelectCheckBoxForTask(v.id, event)} />,
      status: <Badge className="badgej-status-style">{v.status}</Badge>,
      sow: <Box className="highlight-text">{v.sow}</Box>,
      due_date: DateDisplay(v.due_date),
    }))
    setTaskList(newOpList)
  }, [taskFormList])

  // task data
  const initialOPReview = [
    {
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: t('id'),
      accessor: 'code',
      disableSortBy: false,
    },
    {
      Header: t('sop'),
      accessor: 'sop',
      disableSortBy: false,
    },
    {
      Header: t('sow'),
      accessor: 'sow',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('due_date'),
      accessor: 'due_date',
      disableSortBy: false,
    },
    {
      Header: t('created_by'),
      accessor: 'createdBy',
      disableSortBy: false,
    },
  ]
  const { opReviewerList } = useSelector(selectActionPlan)
  const [reviewerList, setReviewerList] = useState([])
  const [reviewerFormList, setReviewerFormList] = useState([])

  const onSelectCheckBoxForReview = (id, event) => {
    const { checked } = event.target
    setReviewerFormList((prev) =>
      prev.map((v) => {
        if (v.id === id)
          return {
            ...v,
            is_checked: checked,
          }
        else
          return {
            ...v,
            is_checked: false,
          }
      })
    )
  }

  useEffect(() => {
    const newOpFormList = opReviewerList.map((v) => ({
      id: v.id,
      code: v?.code,
      sop: v?.sw?.sp?.name,
      sow: v?.sw?.name,
      due_date: v?.due_date,
      status: v?.status,
      createdBy: `${v?.sw?.sp?.employee?.first_name} ${v?.sw?.sp?.employee?.last_name}`,
      is_checked: false,
    }))
    setReviewerFormList(newOpFormList)
  }, [opReviewerList])
  useEffect(() => {
    const newOpList = reviewerFormList.map((v, i) => ({
      ...v,
      checkbox: <OutlineCheckbox checked={v.is_checked} onChange={(event) => onSelectCheckBoxForReview(v.id, event)} />,
      status: <Badge className="badgej-status-style">{v.status}</Badge>,
      sow: <Box className="highlight-text">{v.sow}</Box>,
      due_date: DateDisplay(v.due_date),
    }))
    setReviewerList(newOpList)
  }, [reviewerFormList])

  const initialOPApprover = [
    {
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: t('id'),
      accessor: 'code',
      disableSortBy: false,
    },
    {
      Header: t('sop'),
      accessor: 'sop',
      disableSortBy: false,
    },
    {
      Header: t('sow'),
      accessor: 'sow',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('due_date'),
      accessor: 'due_date',
      disableSortBy: false,
    },
    {
      Header: t('created_by'),
      accessor: 'createdBy',
      disableSortBy: false,
    },
  ]
  const { opApproverList } = useSelector(selectActionPlan)
  const [approverList, setApproverList] = useState([])
  const [approverFormList, setApproverFormList] = useState([])

  const onSelectCheckBoxForApproval = (id, event) => {
    const { checked } = event.target
    setApproverFormList((prev) =>
      prev.map((v) => {
        if (v.id === id)
          return {
            ...v,
            is_checked: checked,
          }
        else
          return {
            ...v,
            is_checked: false,
          }
      })
    )
  }

  useEffect(() => {
    const newOpFormList = opApproverList.map((v) => ({
      id: v.id,
      code: v?.code,
      sop: v?.sw?.sp?.name,
      sow: v?.sw?.name,
      due_date: v?.due_date,
      status: v?.status,
      createdBy: `${v?.sw?.sp?.employee?.first_name} ${v?.sw?.sp?.employee?.last_name}`,
      is_checked: false,
    }))
    setApproverFormList(newOpFormList)
  }, [opApproverList])
  useEffect(() => {
    const newOpList = approverFormList.map((v, i) => ({
      ...v,
      checkbox: (
        <OutlineCheckbox checked={v.is_checked} onChange={(event) => onSelectCheckBoxForApproval(v.id, event)} />
      ),
      status: <Badge className="badgej-status-style">{v.status}</Badge>,
      sow: <Box className="highlight-text">{v.sow}</Box>,
      due_date: DateDisplay(v.due_date),
    }))
    setApproverList(newOpList)
  }, [approverFormList])

  // option dropdown
  const initialStatusOptionsForSowAndTask = [
    {
      text: 'Open',
      value: 'Open',
    },
    {
      text: 'In Progress',
      value: 'In Progress',
    },
  ]
  const initialStatusOptionsForReview = [
    {
      text: 'Open',
      value: 'Open',
    },
    {
      text: 'Waiting for Review',
      value: 'Waiting for Review',
    },
  ]
  const initialStatusOptionsForApproval = [
    {
      text: 'Open',
      value: 'Open',
    },
    {
      text: 'Waiting for Approve',
      value: 'Waiting for Approve',
    },
  ]

  // filter
  const initialFilter = {
    search: '',
    status: '',
  }
  const [filter, setFilter] = useState(initialFilter)
  const [searchFilters, setSearchFilters] = useState([])

  const onChangeFilter = (name, value) => {
    const newFilter = {
      ...filter,
      [name]: value,
    }
    setFilter(newFilter)

    if (name === 'status') {
      const multipleSearch =
        tabMenu === 'scope-of-work'
          ? searchFilters.map((v, i) => ({
              [`filters[$and][${i}][$or][0][name][$contains]`]: v.name,
              [`filters[$and][${i}][$or][1][sp][name][$contains]`]: v.name,
            }))
          : tabMenu === 'task'
          ? searchFilters.map((v, i) => ({
              [`filters[$and][${i}][$or][0][name][$contains]`]: v.name,
              [`filters[$and][${i}][$or][1][sw][name][$contains]`]: v.name,
              [`filters[$and][${i}][$or][2][sw][sp][name][$contains]`]: v.name,
            }))
          : tabMenu === 'review'
          ? searchFilters.map((v, i) => ({
              [`filters[$or][${i}][sw][name][$contains]`]: v.name,
            }))
          : searchFilters.map((v, i) => ({
              [`filters[$or][${i}][sw][name][$contains]`]: v.name,
            }))

      const filters = {
        ...filter,
        page: 1,
        pageSize: pageSize,
        status: value,
        multipleSearch,
      }

      if (tabMenu === 'scope-of-work') {
        fetchOpSwList(filters)
      } else if (tabMenu === 'task') {
        fetchOpTaskList(filters)
      } else if (tabMenu === 'review') {
        fetchOpReviewerList(filters)
      } else {
        fetchOpApproverList(filters)
      }
    }
  }
  const onChangePagination = (value) => {
    const multipleSearch =
      tabMenu === 'scope-of-work'
        ? searchFilters.map((v, i) => ({
            [`filters[$and][${i}][$or][0][name][$contains]`]: v.name,
            [`filters[$and][${i}][$or][1][sp][name][$contains]`]: v.name,
          }))
        : tabMenu === 'task'
        ? searchFilters.map((v, i) => ({
            [`filters[$and][${i}][$or][0][name][$contains]`]: v.name,
            [`filters[$and][${i}][$or][1][sw][name][$contains]`]: v.name,
            [`filters[$and][${i}][$or][2][sw][sp][name][$contains]`]: v.name,
          }))
        : tabMenu === 'review'
        ? searchFilters.map((v, i) => ({
            [`filters[$or][${i}][sw][name][$contains]`]: v.name,
          }))
        : searchFilters.map((v, i) => ({
            [`filters[$or][${i}][sw][name][$contains]`]: v.name,
          }))

    const filters = {
      ...filter,
      page: value.page,
      pageSize: pageSize,
      multipleSearch,
    }

    if (tabMenu === 'scope-of-work') {
      fetchOpSwList(filters)
    } else if (tabMenu === 'task') {
      fetchOpTaskList(filters)
    } else if (tabMenu === 'review') {
      fetchOpReviewerList(filters)
    } else {
      fetchOpApproverList(filters)
    }
  }
  const onSearch = () => {
    if (filter.search !== '') {
      const newSearchFilter = {
        key: uuidv4(),
        name: filter.search,
      }
      const multipleSearch =
        tabMenu === 'scope-of-work'
          ? [...searchFilters, newSearchFilter].map((v, i) => ({
              [`filters[$and][${i}][$or][0][name][$contains]`]: v.name,
              [`filters[$and][${i}][$or][1][sp][name][$contains]`]: v.name,
            }))
          : tabMenu === 'task'
          ? [...searchFilters, newSearchFilter].map((v, i) => ({
              [`filters[$and][${i}][$or][0][name][$contains]`]: v.name,
              [`filters[$and][${i}][$or][1][sw][name][$contains]`]: v.name,
              [`filters[$and][${i}][$or][2][sw][sp][name][$contains]`]: v.name,
            }))
          : tabMenu === 'review'
          ? [...searchFilters, newSearchFilter].map((v, i) => ({
              [`filters[$or][${i}][sw][name][$contains]`]: v.name,
            }))
          : [...searchFilters, newSearchFilter].map((v, i) => ({
              [`filters[$or][${i}][sw][name][$contains]`]: v.name,
            }))

      const filters = {
        ...filter,
        page: 1,
        pageSize: pageSize,
        multipleSearch,
      }

      if (tabMenu === 'scope-of-work') {
        fetchOpSwList(filters)
      } else if (tabMenu === 'task') {
        fetchOpTaskList(filters)
      } else if (tabMenu === 'review') {
        fetchOpReviewerList(filters)
      } else {
        fetchOpApproverList(filters)
      }

      setFilter((prev) => ({
        ...prev,
        search: '',
      }))
      setSearchFilters((prev) => [...prev, newSearchFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const currentSearchFilters = searchFilters.filter((v) => v.key !== key)
    setSearchFilters(currentSearchFilters)

    const multipleSearch =
      tabMenu === 'scope-of-work'
        ? currentSearchFilters.map((v, i) => ({
            [`filters[$and][${i}][$or][0][name][$contains]`]: v.name,
            [`filters[$and][${i}][$or][1][sp][name][$contains]`]: v.name,
          }))
        : tabMenu === 'task'
        ? currentSearchFilters.map((v, i) => ({
            [`filters[$and][${i}][$or][0][name][$contains]`]: v.name,
            [`filters[$and][${i}][$or][1][sw][name][$contains]`]: v.name,
            [`filters[$and][${i}][$or][2][sw][sp][name][$contains]`]: v.name,
          }))
        : tabMenu === 'review'
        ? currentSearchFilters.map((v, i) => ({
            [`filters[$or][${i}][sw][name][$contains]`]: v.name,
          }))
        : currentSearchFilters.map((v, i) => ({
            [`filters[$or][${i}][sw][name][$contains]`]: v.name,
          }))

    const filters = {
      ...filter,
      page: 1,
      pageSize: pageSize,
      multipleSearch,
    }

    if (tabMenu === 'scope-of-work') {
      fetchOpSwList(filters)
    } else if (tabMenu === 'task') {
      fetchOpTaskList(filters)
    } else if (tabMenu === 'review') {
      fetchOpReviewerList(filters)
    } else {
      fetchOpApproverList(filters)
    }
  }
  const onClearSearch = () => {
    const filters = {
      ...initialFilter,
      page: 1,
      pageSize: pageSize,
      multipleSearch: [],
    }

    if (tabMenu === 'scope-of-work') {
      fetchOpSwList(filters)
    } else if (tabMenu === 'task') {
      fetchOpTaskList(filters)
    } else if (tabMenu === 'review') {
      fetchOpReviewerList(filters)
    } else {
      fetchOpApproverList(filters)
    }
    clearFilter()
  }
  const clearFilter = () => {
    setFilter(initialFilter)
    setSearchFilters([])
  }

  const cancel = () => {
    onClose()
  }
  const onSubmit = () => {
    if (tabMenu === 'scope-of-work') {
      const selectedItem = sowFormList.find((v) => v.is_checked)
      add('op-sw', selectedItem)
    } else if (tabMenu === 'task') {
      const selectedItem = taskFormList.find((v) => v.is_checked)
      add('op-task', selectedItem)
    } else if (tabMenu === 'review') {
      const selectedItem = reviewerFormList.find((v) => v.is_checked)
      add('op-review', selectedItem)
    } else {
      const selectedItem = approverFormList.find((v) => v.is_checked)
      add('op-approval', selectedItem)
    }

    onClose()
  }

  return (
    <Div>
      <Stack sx={{ mb: 3 }} direction="row" justifyContent="space-between" alignItems="flex-end">
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <Box>
            <div className="input-heading">Search</div>
            <SearchText
              value={filter.search}
              onChange={(event) => onChangeFilter('search', event.target.value)}
              onSubmit={onSearch}
              onClear={() => onChangeFilter('search', '')}
            />
          </Box>
          <Box>
            <div className="input-heading">Status</div>
            {tabMenu === 'review' ? (
              <Dropdown
                optionList={initialStatusOptionsForReview}
                value={filter.status}
                onChange={(value) => onChangeFilter('status', value)}
              />
            ) : tabMenu === 'approvals' ? (
              <Dropdown
                optionList={initialStatusOptionsForApproval}
                value={filter.status}
                onChange={(value) => onChangeFilter('status', value)}
              />
            ) : (
              <Dropdown
                optionList={initialStatusOptionsForSowAndTask}
                value={filter.status}
                onChange={(value) => onChangeFilter('status', value)}
              />
            )}
          </Box>

          <ButtonOutline onClick={onClearSearch}>Clear</ButtonOutline>
        </Stack>

        <Box>
          <TabGroupMenu2 tabMenuOptions={tabMenuOptions} tabMenu={tabMenu} setTabMenu={onChangeTab} />
        </Box>
      </Stack>

      <Box sx={{ mb: 3 }}>
        <BadgeSearch searchFilters={searchFilters} deleteSearchBadge={deleteSearchBadge} />
      </Box>

      <Box>
        {tabMenu === 'scope-of-work' ? (
          <Table
            data={sowList}
            columns={initialOPSoW}
            page={page}
            pageCount={pageCount}
            onStateChange={onChangePagination}
          />
        ) : tabMenu === 'task' ? (
          <Table
            data={taskList}
            columns={initialOPTask}
            page={page}
            pageCount={pageCount}
            onStateChange={onChangePagination}
          />
        ) : tabMenu === 'review' ? (
          <Table
            data={reviewerList}
            columns={initialOPReview}
            page={page}
            pageCount={pageCount}
            onStateChange={onChangePagination}
          />
        ) : (
          <Table
            data={approverList}
            columns={initialOPApprover}
            page={page}
            pageCount={pageCount}
            onStateChange={onChangePagination}
          />
        )}
      </Box>

      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <ButtonOutline onClick={cancel}>{t('cancel')}</ButtonOutline>
        <Button onClick={onSubmit}>{t('confirm')}</Button>
      </Stack>
    </Div>
  )
}

export default OperationProcessDataContent
