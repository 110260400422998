import { Stack } from '@mui/material'
import { MdOutlineWorkOutline } from 'react-icons/md'

import DueDate from 'components/feature/operation-process/sw-timeline/due-date'
import { REVIEWER } from 'constants/operation-process'
import { Chip } from '.'

export default function Reviewer({ data: reviewer }) {
  const { employee } = reviewer
  const { first_name, last_name, department } = employee || {}
  const { name: departmentName } = department || {}

  return (
    <>
      <Stack>
        <p className="title">{first_name || last_name ? first_name + ' ' + last_name : '-'}</p>

        <Stack gap="0.25rem">
          <Stack direction="row" gap="0.5rem">
            <MdOutlineWorkOutline size={16} />
            <p>{(departmentName || 'No department') + ' | ' + (reviewer?.position?.name || 'No position')}</p>
          </Stack>

          <DueDate dueDate={reviewer.due_date} rootProps={{ justifyContent: 'flex-start' }} />
        </Stack>
      </Stack>

      {reviewer?.status && <Chip label={reviewer.status} color={REVIEWER.STATUS_CHIP[reviewer.status]} />}
    </>
  )
}
