import { combineReducers } from 'redux'
import { GET_VERIFY_USER_INFO, SET_PASSWORD, GET_PERMISSION_RESET_PASSWORD_TOKEN } from '../actionTypes'

const initialState = {
  userInfo: {},
  userPermissionInfo: {},
  isLoading: false,
}
const resetPasswordReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_VERIFY_USER_INFO.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_VERIFY_USER_INFO.SUCCESS: {
      const { data, token } = action
      return {
        ...state,
        userInfo: { ...data, token },
        isLoading: false,
      }
    }
    case GET_VERIFY_USER_INFO.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case SET_PASSWORD.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case SET_PASSWORD.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case SET_PASSWORD.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PERMISSION_RESET_PASSWORD_TOKEN.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PERMISSION_RESET_PASSWORD_TOKEN.SUCCESS: {
      const { data } = action
      return {
        ...state,
        userPermissionInfo: data,
        isLoading: false,
      }
    }
    case GET_PERMISSION_RESET_PASSWORD_TOKEN.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  resetPasswordReducer,
})
