import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  padding: 10rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .header {
    font-size: 5rem;
    font-weight: bold;
  }

  .message {
    font-size: 1.5rem;
  }
`

function VerifyError() {
  const { t } = useTranslation()

  const query = new URLSearchParams(useLocation().search)
  const error = query.get('error')
  const [errorDisplay, setErrorDisplay] = useState({
    header: '',
    message: '',
  })

  useEffect(() => {
    switch (error) {
      case 'Application Form Token is invalid':
        setErrorDisplay({
          header: t('system_error_invalid_token_header'),
          message: t('system_error_invalid_token_message_sub'),
        })
        break
      case 'Application Form Not Found':
        setErrorDisplay({
          header: t('the_application_form_not_found_header'),
          message: t('the_application_form_not_found_message_sub'),
        })
        break
      case 'The application form is not yet open.':
        setErrorDisplay({
          header: t('form_access_not_yet_available_header'),
          message: t('form_access_not_yet_available_sub_message'),
        })
        break
      case 'The application form is closed.':
        setErrorDisplay({
          header: t('form_access_expired_header'),
          message: t('form_access_expired_sub_message'),
        })
        break
      default:
        setErrorDisplay({
          header: '',
          message: '',
        })
    }
    setErrorDisplay
  }, [error])

  return (
    <Div>
      <div className="header">{errorDisplay.header}</div>
      <div className="message">{errorDisplay.message}</div>
    </Div>
  )
}

export default VerifyError
