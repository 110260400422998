import { combineReducers } from 'redux'
import {
  GET_APPLICATION_FORMS,
  GET_APPLICATION_TYPES,
  GET_APPLICATION_REQUIRES,
  CREATE_APPLICATION_FORM,
  GET_APPLICATION_FORM_BY_ID,
  SEND_APPLICATION_FORM_SUBMISSION,
  RESEND_APPLICATION_FORM_SUBMISSION,
  UPDATE_APPLICATION_FORM,
  CHANGE_APPLICATION_FORM_STATUS,
  VERIFY_APPLICATION_FORM_TOKEN,
  CREATE_EMPLOYEE_WITH_APPLICATION_FORM,
  GET_INVITATIONS,
  CREATE_INVITATION,
  SEND_INVITATION,
  DELETE_INVITATION,
  CREATE_INVITATION_WITH_CSV,
  GET_INVITATION_VIEW,
} from '../actionTypes'

const initialState = {
  applicationForms: [],
  applicationTypes: [],
  applicationRequires: [],
  applicationInfo: {},
  verifyApplicationInfo: {},
  invitationLogs: [],
  invitationView: {},
  page: 1,
  pageCount: 1,
  total: 1,
  limit: 10,
  isLoading: false,
  state: '',
}
const applicationFormReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_APPLICATION_FORMS.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_APPLICATION_FORMS.REQUEST',
      }
    case GET_APPLICATION_FORMS.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        applicationForms: data,
        page: meta.pagination.page,
        limit: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
        state: 'GET_APPLICATION_FORMS.SUCCESS',
      }
    }
    case GET_APPLICATION_FORMS.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_APPLICATION_FORMS.FAILURE',
      }
    case GET_INVITATIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_INVITATIONS.REQUEST',
      }
    case GET_INVITATIONS.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        invitationLogs: data,
        page: meta.pagination.page,
        limit: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
        state: 'GET_INVITATIONS.SUCCESS',
      }
    }
    case GET_INVITATIONS.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_INVITATIONS.FAILURE',
      }
    case GET_INVITATION_VIEW.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_INVITATION_VIEW.REQUEST',
      }
    case GET_INVITATION_VIEW.SUCCESS: {
      const { data } = action
      return {
        ...state,
        invitationView: data,
        isLoading: false,
        state: 'GET_INVITATION_VIEW.SUCCESS',
      }
    }
    case GET_INVITATION_VIEW.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_INVITATION_VIEW.FAILURE',
      }
    case GET_APPLICATION_TYPES.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_APPLICATION_TYPES.REQUEST',
      }
    case GET_APPLICATION_TYPES.SUCCESS: {
      const { data } = action
      return {
        ...state,
        applicationTypes: data,
        isLoading: false,
        state: 'GET_APPLICATION_TYPES.SUCCESS',
      }
    }
    case GET_APPLICATION_TYPES.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_APPLICATION_TYPES.FAILURE',
      }
    case GET_APPLICATION_REQUIRES.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_APPLICATION_REQUIRES.REQUEST',
      }
    case GET_APPLICATION_REQUIRES.SUCCESS: {
      const { data } = action
      return {
        ...state,
        applicationRequires: data,
        isLoading: false,
        state: 'GET_APPLICATION_REQUIRES.SUCCESS',
      }
    }
    case GET_APPLICATION_REQUIRES.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_APPLICATION_REQUIRES.FAILURE',
      }
    case CREATE_APPLICATION_FORM.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_APPLICATION_FORM.REQUEST',
      }
    case CREATE_APPLICATION_FORM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_APPLICATION_FORM.SUCCESS',
      }
    }
    case CREATE_APPLICATION_FORM.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_APPLICATION_FORM.FAILURE',
      }
    case GET_APPLICATION_FORM_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_APPLICATION_FORM_BY_ID.REQUEST',
      }
    case GET_APPLICATION_FORM_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        applicationInfo: data,
        isLoading: false,
        state: 'GET_APPLICATION_FORM_BY_ID.SUCCESS',
      }
    }
    case GET_APPLICATION_FORM_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_APPLICATION_FORM_BY_ID.FAILURE',
      }
    case SEND_APPLICATION_FORM_SUBMISSION.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'SEND_APPLICATION_FORM_SUBMISSION.REQUEST',
      }
    case SEND_APPLICATION_FORM_SUBMISSION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'SEND_APPLICATION_FORM_SUBMISSION.SUCCESS',
      }
    }
    case SEND_APPLICATION_FORM_SUBMISSION.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'SEND_APPLICATION_FORM_SUBMISSION.FAILURE',
      }
    case RESEND_APPLICATION_FORM_SUBMISSION.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'RESEND_APPLICATION_FORM_SUBMISSION.REQUEST',
      }
    case RESEND_APPLICATION_FORM_SUBMISSION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'RESEND_APPLICATION_FORM_SUBMISSION.SUCCESS',
      }
    }
    case RESEND_APPLICATION_FORM_SUBMISSION.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'RESEND_APPLICATION_FORM_SUBMISSION.FAILURE',
      }
    case UPDATE_APPLICATION_FORM.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'UPDATE_APPLICATION_FORM.REQUEST',
      }
    case UPDATE_APPLICATION_FORM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_APPLICATION_FORM.SUCCESS',
      }
    }
    case UPDATE_APPLICATION_FORM.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_APPLICATION_FORM.FAILURE',
      }
    case CHANGE_APPLICATION_FORM_STATUS.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CHANGE_APPLICATION_FORM_STATUS.REQUEST',
      }
    case CHANGE_APPLICATION_FORM_STATUS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CHANGE_APPLICATION_FORM_STATUS.SUCCESS',
      }
    }
    case CHANGE_APPLICATION_FORM_STATUS.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CHANGE_APPLICATION_FORM_STATUS.FAILURE',
      }
    case VERIFY_APPLICATION_FORM_TOKEN.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'VERIFY_APPLICATION_FORM_TOKEN.REQUEST',
      }
    case VERIFY_APPLICATION_FORM_TOKEN.SUCCESS: {
      const { data } = action
      return {
        ...state,
        verifyApplicationInfo: data,
        isLoading: false,
        state: 'VERIFY_APPLICATION_FORM_TOKEN.SUCCESS',
      }
    }
    case VERIFY_APPLICATION_FORM_TOKEN.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'VERIFY_APPLICATION_FORM_TOKEN.FAILURE',
      }
    case CREATE_EMPLOYEE_WITH_APPLICATION_FORM.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_EMPLOYEE_WITH_APPLICATION_FORM.REQUEST',
      }
    case CREATE_EMPLOYEE_WITH_APPLICATION_FORM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_EMPLOYEE_WITH_APPLICATION_FORM.SUCCESS',
      }
    }
    case CREATE_EMPLOYEE_WITH_APPLICATION_FORM.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_EMPLOYEE_WITH_APPLICATION_FORM.FAILURE',
      }
    case CREATE_INVITATION.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_INVITATION.REQUEST',
      }
    case CREATE_INVITATION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_INVITATION.SUCCESS',
      }
    }
    case CREATE_INVITATION.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_INVITATION.FAILURE',
      }
    case SEND_INVITATION.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'SEND_INVITATION.REQUEST',
      }
    case SEND_INVITATION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'SEND_INVITATION.SUCCESS',
      }
    }
    case SEND_INVITATION.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'SEND_INVITATION.FAILURE',
      }
    case DELETE_INVITATION.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_INVITATION.REQUEST',
      }
    case DELETE_INVITATION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_INVITATION.SUCCESS',
      }
    }
    case DELETE_INVITATION.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_INVITATION.FAILURE',
      }
    case CREATE_INVITATION_WITH_CSV.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_INVITATION_WITH_CSV.REQUEST',
      }
    case CREATE_INVITATION_WITH_CSV.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_INVITATION_WITH_CSV.SUCCESS',
      }
    }
    case CREATE_INVITATION_WITH_CSV.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_INVITATION_WITH_CSV.FAILURE',
      }
    default:
      return state
  }
}

export default combineReducers({
  applicationFormReducer,
})
