import { getAPI, postAPI, putAPI, deleteAPI } from '../../utils/api'
import {
  GET_PENDING_ASSIGNMENTS,
  GET_ASSIGNMENT_BY_ID,
  READING_ASSIGNMENT,
  ACKNOWLEDGE_ASSIGNMENT,
  GET_EMPLOYEE_SIGNATURE,
  UPLOAD_SIGNATURE,
  SIGN_SIGNATURE,
  GET_REVIEW_ASSIGNMENTS,
  GET_OVER_DUE_ASSIGNMENTS,
  READ_INFO_ASSIGNMENT,
  SET_NOTI_EMAIL_DOC_ID,
  SET_ASSIGNMENT_INFO,
  UPDATE_EMPLOYEE_INFO,
  UPLOAD_SIGNATURE_IMAGE,
} from '../actionTypes'
import { showLoading, hideLoading } from '../actions/loading'
import { getAllLocalAdmin } from '../../utils/common'
import dayjs from 'dayjs'

export const getPendingAssignments =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_PENDING_ASSIGNMENTS.REQUEST })
      const { jwt, employee_info } = getAllLocalAdmin()
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }

      const currentDate = dayjs()
      const formattedDate = currentDate.format('YYYY-MM-DD')
      const params = {
        ...multipleSearch,
        'filters[employee][id]': employee_info?.id,
        'filters[status]': 'Wait for response',
        'filters[document_acknow][status]': 'Active',
        'fields[0]': 'status',
        'populate[document_acknow][fields][0]': 'type',
        'populate[document_acknow][fields][1]': 'name',
        'populate[document_acknow][fields][2]': 'version',
        'populate[document_acknow][fields][3]': 'publish_date',
        'populate[document_acknow][fields][4]': 'due_date',
        'populate[document_acknow][fields][5]': 'is_star',
        'populate[document_acknow][populate][category][fields][6]': 'name',
        'populate[document_acknow][populate][files]': '*',
        'filters[document_acknow][due_date][$gte]': formattedDate,
        'sort[0]': 'document_acknow.is_star:desc',
        'sort[1]': 'document_acknow.due_date',
        view: 'employee',
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
      }
      if (filter.category !== '') params['filters[document_acknow][category][id]'] = filter.category
      if (filter.type !== '') params['filters[document_acknow][type]'] = filter.type

      const { data, meta } = await getAPI({
        url: '/api/document-acknow-assignments',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_PENDING_ASSIGNMENTS.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_PENDING_ASSIGNMENTS.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get assignments failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getReviewAssignments =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_REVIEW_ASSIGNMENTS.REQUEST })
      const { jwt, employee_info } = getAllLocalAdmin()
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }

      const params = {
        ...multipleSearch,
        'filters[employee][id]': employee_info?.id,
        'filters[status][$in][0]': 'Read',
        'filters[status][$in][1]': 'Submit',
        'filters[status][$in][2]': 'Accept',
        'filters[status][$in][3]': 'Decline',
        'filters[document_acknow][status]': 'Active',
        'fields[0]': 'status',
        'populate[document_acknow][fields][0]': 'type',
        'populate[document_acknow][fields][1]': 'name',
        'populate[document_acknow][fields][2]': 'version',
        'populate[document_acknow][fields][3]': 'publish_date',
        'populate[document_acknow][fields][4]': 'due_date',
        'populate[document_acknow][fields][5]': 'is_star',
        'populate[document_acknow][populate][category][fields][6]': 'name',
        'populate[document_acknow][populate][files]': '*',
        'sort[0]': 'document_acknow.is_star:desc',
        'sort[1]': 'document_acknow.due_date',
        view: 'employee',
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
      }
      if (filter.category !== '') params['filters[document_acknow][category][id]'] = filter.category
      if (filter.type !== '') params['filters[document_acknow][type]'] = filter.type

      const { data, meta } = await getAPI({
        url: '/api/document-acknow-assignments',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_REVIEW_ASSIGNMENTS.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_REVIEW_ASSIGNMENTS.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Review assignments failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getOverdueAssignments =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_OVER_DUE_ASSIGNMENTS.REQUEST })
      const { jwt, employee_info } = getAllLocalAdmin()
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }

      const currentDate = dayjs()
      const formattedDate = currentDate.format('YYYY-MM-DD')
      const params = {
        ...multipleSearch,
        'filters[employee][id]': employee_info?.id,
        'filters[status]': 'Wait for response',
        'filters[document_acknow][status]': 'Active',
        'fields[0]': 'status',
        'populate[document_acknow][fields][0]': 'type',
        'populate[document_acknow][fields][1]': 'name',
        'populate[document_acknow][fields][2]': 'version',
        'populate[document_acknow][fields][3]': 'publish_date',
        'populate[document_acknow][fields][4]': 'due_date',
        'populate[document_acknow][fields][5]': 'is_star',
        'populate[document_acknow][populate][category][fields][6]': 'name',
        'populate[document_acknow][populate][files]': '*',
        'filters[document_acknow][due_date][$lt]': formattedDate,
        'sort[0]': 'document_acknow.is_star:desc',
        'sort[1]': 'document_acknow.due_date',
        view: 'employee',
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
      }
      if (filter.category !== '') params['filters[document_acknow][category][id]'] = filter.category
      if (filter.type !== '') params['filters[document_acknow][type]'] = filter.type

      const { data, meta } = await getAPI({
        url: '/api/document-acknow-assignments',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_OVER_DUE_ASSIGNMENTS.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_OVER_DUE_ASSIGNMENTS.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Over due assignments failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getAssignmentById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ASSIGNMENT_BY_ID.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const params = {
      'fields[0]': 'status',
      'fields[1]': 'opened_date',
      'fields[2]': 'acknowledged_date',
      'fields[3]': 'signed_date',
      'fields[4]': 'doc_signature',
      'populate[readings][fields][0]': 'topic_id',
      'populate[employee_signature][populate][signature][fields][0]': 'url',
      'populate[document_acknow][fields][0]': 'type',
      'populate[document_acknow][fields][1]': 'name',
      'populate[document_acknow][fields][2]': 'version',
      'populate[document_acknow][fields][3]': 'doc_lang',
      'populate[document_acknow][fields][4]': 'other_topic',
      'populate[document_acknow][populate][topics][populate]': 'images',
      'populate[document_acknow][populate]': 'files',
    }

    const { data } = await getAPI({
      url: `/api/document-acknow-assignments/${id}`,
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_ASSIGNMENT_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_ASSIGNMENT_BY_ID.FAILURE,
      error,
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const readingPendingAssignment = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: READING_ASSIGNMENT.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: `/api/document-acknow-readings`,
      headers: headers,
      data,
    })

    return dispatch({
      type: READING_ASSIGNMENT.SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: READING_ASSIGNMENT.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Reading assignment failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const acknowledgeAssignment = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: ACKNOWLEDGE_ASSIGNMENT.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: `/api/document-acknow-assignments/${id}/acknowledge`,
      headers: headers,
      data,
    })

    return dispatch({
      type: ACKNOWLEDGE_ASSIGNMENT.SUCCESS,
      showAlert: true,
      alertMessage: 'Acknowledge assignment Successful!',
    })
  } catch (error) {
    return dispatch({
      type: ACKNOWLEDGE_ASSIGNMENT.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Acknowledge assignment failed!',
    })
  } finally {
    dispatch(setNotiEmailDocId(null))
    dispatch(hideLoading())
  }
}

export const getEmployeeSignature = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_EMPLOYEE_SIGNATURE.REQUEST })
    const { jwt, employee_info } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const params = {
      'filters[employee][id]': employee_info?.id,
      'populate[signature][fields][0]': 'url',
      'sort[0]': 'id:desc',
    }

    const { data } = await getAPI({
      url: `/api/employee-signatures`,
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_EMPLOYEE_SIGNATURE.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_EMPLOYEE_SIGNATURE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Employee Signature failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const InputUploadFile = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPLOAD_SIGNATURE.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'multipart/form-data',
    }

    await postAPI({
      url: `/api/upload`,
      headers: headers,
      data,
    })

    return dispatch({
      type: UPLOAD_SIGNATURE.SUCCESS,
      showAlert: true,
      alertMessage: 'Upload signature Successful!',
    })
  } catch (error) {
    return dispatch({
      type: UPLOAD_SIGNATURE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Upload signature failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const uploadSignatureAndThenSign = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPLOAD_SIGNATURE.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'multipart/form-data',
    }

    const response = await postAPI({
      url: `/api/upload`,
      headers: headers,
      data,
    })

    if (response[0]?.id) {
      const request = {
        employee_signature: {
          signature: {
            id: response[0]?.id,
          },
        },
        status: 'Accept',
      }
      dispatch(ackAndsignSignature(id, request))
    }
  } catch (error) {
    return dispatch({
      type: UPLOAD_SIGNATURE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Upload signature failed!',
    })
  } finally {
  }
}

export const signSignature = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: SIGN_SIGNATURE.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: `/api/document-acknow-assignments/${id}/sign`,
      headers: headers,
      data,
    })

    return dispatch({
      type: SIGN_SIGNATURE.SUCCESS,
      showAlert: true,
      alertMessage: 'Sign signature Successful!',
    })
  } catch (error) {
    return dispatch({
      type: SIGN_SIGNATURE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Sign signature failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const ackAndsignSignature = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: SIGN_SIGNATURE.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: `/api/document-acknow-assignments/${id}/acknowledge-sign`,
      headers: headers,
      data,
    })

    return dispatch({
      type: SIGN_SIGNATURE.SUCCESS,
      showAlert: true,
      alertMessage: 'Sign signature Successful!',
    })
  } catch (error) {
    return dispatch({
      type: SIGN_SIGNATURE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Sign signature failed!',
    })
  } finally {
    dispatch(setNotiEmailDocId(null))
    dispatch(hideLoading())
  }
}

export const readInfoAssignment = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: READ_INFO_ASSIGNMENT.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'fields[0]': 'status',
      'fields[1]': 'opened_date',
      'fields[2]': 'acknowledged_date',
      'fields[3]': 'signed_date',
      'populate[readings][fields][0]': 'topic_id',
      'populate[employee_signature][populate][signature][fields][0]': 'url',
      'populate[document_acknow][fields][0]': 'type',
      'populate[document_acknow][fields][1]': 'name',
      'populate[document_acknow][fields][2]': 'version',
      'populate[document_acknow][populate][topics][populate]': 'images',
      'populate[document_acknow][populate]': 'images',
    }

    await getAPI({
      url: `/api/document-acknow-assignments/${id}`,
      headers: headers,
      params,
    })

    return dispatch({
      type: READ_INFO_ASSIGNMENT.SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: READ_INFO_ASSIGNMENT.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Read info assignments failed!',
    })
  } finally {
    dispatch(setNotiEmailDocId(null))
    dispatch(hideLoading())
  }
}

export const setNotiEmailDocId = (id) => (dispatch) => {
  dispatch({ type: SET_NOTI_EMAIL_DOC_ID, data: id })
}
export const setAssignmentInfo = (data) => (dispatch) => {
  dispatch({ type: SET_ASSIGNMENT_INFO, data })
}

export const updateEmployeeInfo = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_EMPLOYEE_INFO.REQUEST })
    const { token } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/document-acknow/employees/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_EMPLOYEE_INFO.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Employee Info Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_EMPLOYEE_INFO.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update Employee Info Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const uploadSignature = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPLOAD_SIGNATURE_IMAGE.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'multipart/form-data',
    }

    const response = await postAPI({
      url: `/api/upload`,
      headers: headers,
      data,
    })

    return dispatch({
      type: UPLOAD_SIGNATURE_IMAGE.SUCCESS,
      response,
    })
  } catch (error) {
    return dispatch({
      type: UPLOAD_SIGNATURE_IMAGE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Upload signature image failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}
