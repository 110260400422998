import { combineReducers } from 'redux'
import {
  GET_GROUP_TEAM,
  GET_GROUP_TEAM_BY_ID,
  CREATE_GROUP_TEAM,
  EDIT_GROUP_TEAM,
  DELETE_GROUP_TEAM,
  GET_TEAM,
  GET_TEAM_BY_ID,
  CREATE_TEAM,
  EDIT_TEAM,
  DELETE_TEAM,
  GET_MEMBER,
  GET_MEMBER_FOR_ADD,
  CREATE_TEAM_MEMBER,
  CREATE_REPRESENTATIVE,
  EDIT_TEAM_MEMBER,
  DELETE_TEAM_MEMBER,
} from '../actionTypes'

const initialState = {
  groupList: [],
  groupInfo: {},
  teamList: [],
  teamInfo: {},
  memberList: [],
  employeeList: [],
  page: 1,
  pageCount: 1,
  total: 1,
  limit: 10,
  pageEmployee: 1,
  pageCountEmployee: 1,
  totalEmployee: 1,
  isLoading: false,
  state: '',
}

const organizationTeamReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_GROUP_TEAM.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_GROUP_TEAM.REQUEST',
      }
    }
    case GET_GROUP_TEAM.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        groupList: data,
        page: meta.pagination.page,
        limit: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
        state: 'GET_GROUP_TEAM.SUCCESS',
      }
    }
    case GET_GROUP_TEAM.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_GROUP_TEAM.FAILURE',
      }
    }
    case GET_TEAM.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_TEAM.REQUEST',
      }
    }
    case GET_TEAM.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        teamList: data,
        page: meta.pagination.page,
        limit: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
        state: 'GET_TEAM.SUCCESS',
      }
    }
    case GET_TEAM.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_TEAM.FAILURE',
      }
    }
    case GET_GROUP_TEAM_BY_ID.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_GROUP_TEAM_BY_ID.REQUEST',
      }
    }
    case GET_GROUP_TEAM_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        groupInfo: data,
        isLoading: false,
        state: 'GET_GROUP_TEAM_BY_ID.SUCCESS',
      }
    }
    case GET_GROUP_TEAM_BY_ID.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_GROUP_TEAM_BY_ID.FAILURE',
      }
    }
    case GET_TEAM_BY_ID.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_TEAM_BY_ID.REQUEST',
      }
    }
    case GET_TEAM_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        teamInfo: data,
        isLoading: false,
        state: 'GET_TEAM_BY_ID.SUCCESS',
      }
    }
    case GET_TEAM_BY_ID.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_TEAM_BY_ID.FAILURE',
      }
    }
    case CREATE_GROUP_TEAM.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_GROUP_TEAM.REQUEST',
      }
    }
    case CREATE_GROUP_TEAM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_GROUP_TEAM.SUCCESS',
      }
    }
    case CREATE_GROUP_TEAM.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_GROUP_TEAM.FAILURE',
      }
    }
    case CREATE_TEAM.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_TEAM.REQUEST',
      }
    }
    case CREATE_TEAM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_TEAM.SUCCESS',
      }
    }
    case CREATE_TEAM.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_TEAM.FAILURE',
      }
    }
    case EDIT_GROUP_TEAM.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'EDIT_GROUP_TEAM.REQUEST',
      }
    }
    case EDIT_GROUP_TEAM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_GROUP_TEAM.SUCCESS',
      }
    }
    case EDIT_GROUP_TEAM.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_GROUP_TEAM.FAILURE',
      }
    }
    case EDIT_TEAM.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'EDIT_TEAM.REQUEST',
      }
    }
    case EDIT_TEAM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_TEAM.SUCCESS',
      }
    }
    case EDIT_TEAM.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_TEAM.FAILURE',
      }
    }
    case DELETE_GROUP_TEAM.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_GROUP_TEAM.REQUEST',
      }
    }
    case DELETE_GROUP_TEAM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_GROUP_TEAM.SUCCESS',
      }
    }
    case DELETE_GROUP_TEAM.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_GROUP_TEAM.FAILURE',
      }
    }
    case DELETE_TEAM.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_TEAM.REQUEST',
      }
    }
    case DELETE_TEAM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_TEAM.SUCCESS',
      }
    }
    case DELETE_TEAM.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_TEAM.FAILURE',
      }
    }
    case GET_MEMBER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_MEMBER.REQUEST',
      }
    }
    case GET_MEMBER.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        memberList: data,
        page: meta.pagination.page,
        limit: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
        state: 'GET_MEMBER.SUCCESS',
      }
    }
    case GET_MEMBER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_MEMBER.FAILURE',
      }
    }
    case GET_MEMBER_FOR_ADD.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_MEMBER_FOR_ADD.REQUEST',
      }
    }
    case GET_MEMBER_FOR_ADD.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        employeeList: data,
        pageEmployee: meta.pagination.page,
        limit: meta.pagination.pageSize,
        pageCountEmployee: meta.pagination.pageCount,
        totalEmployee: meta.pagination.total,
        isLoading: false,
        state: 'GET_MEMBER_FOR_ADD.SUCCESS',
      }
    }
    case GET_MEMBER_FOR_ADD.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_MEMBER_FOR_ADD.FAILURE',
      }
    }
    case CREATE_TEAM_MEMBER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_TEAM_MEMBER.REQUEST',
      }
    }
    case CREATE_TEAM_MEMBER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_TEAM_MEMBER.SUCCESS',
      }
    }
    case CREATE_TEAM_MEMBER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_TEAM_MEMBER.FAILURE',
      }
    }
    case CREATE_REPRESENTATIVE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_REPRESENTATIVE.REQUEST',
      }
    }
    case CREATE_REPRESENTATIVE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_REPRESENTATIVE.SUCCESS',
      }
    }
    case CREATE_REPRESENTATIVE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_REPRESENTATIVE.FAILURE',
      }
    }
    case EDIT_TEAM_MEMBER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'EDIT_TEAM_MEMBER.REQUEST',
      }
    }
    case EDIT_TEAM_MEMBER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_TEAM_MEMBER.SUCCESS',
      }
    }
    case EDIT_TEAM_MEMBER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_TEAM_MEMBER.FAILURE',
      }
    }
    case DELETE_TEAM_MEMBER.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_TEAM_MEMBER.REQUEST',
      }
    }
    case DELETE_TEAM_MEMBER.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_TEAM_MEMBER.SUCCESS',
      }
    }
    case DELETE_TEAM_MEMBER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_TEAM_MEMBER.FAILURE',
      }
    }
    default:
      return state
  }
}
export default combineReducers({
  organizationTeamReducer,
})
