import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Table from '../../../components/common/Table'
import Checkbox from '../../../components/form/Checkbox'
import SearchText from '../../../components/form/SearchText'
import Button from '../../../components/form/button/Button'
import CapsuleStatus from '../../../components/widget/element/CapsuleStatus'
import DocumentOptionButton from '../../../components/widget/element/DocumentOptionButton'
import DropdownSelect from '../../../components/widget/element/DropDownSelect'
import { getBusinessDocumentList } from '../../../redux/actions/document'
import { selectDocument } from '../../../redux/selectors'
import { convertDateFormat, convertNumberToCommaTwodecimal } from '../../../utils/helper'
import GoodsReceivedNote from '../business-doc/doc-view/GoodsReceivedNote'

export default function WarehouseDocument() {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const docType = searchParams.get('doctype')

  const lastEditList = [
    {
      text: 'Today',
      value: 'today',
    },
    {
      text: 'Last 7 days',
      value: 'last7',
    },
    {
      text: 'Last 30 days',
      value: 'last30',
    },
  ]

  const HEADER_COLUMN = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'Detail',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'Customer',
      accessor: 'customer',
      disableSortBy: false,
    },
    {
      Header: 'Net total',
      accessor: 'net_total',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'edit_quotation',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const HEADER_COLUMN_CHEQUE_PAYMENT_JOURNAL = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'รายละเอียดการสั่งจ่ายเช็ค',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const HEADER_COLUMN_PAYMENT_JOURNAL = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'รายละเอียด สมุดรายวันจ่ายเช็ค',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const HEADER_COLUMN_PETTY_CASH_VOUCHER = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'รายละเอียดการจ่ายให้',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'Payment Type',
      accessor: 'payment_type',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const MOCKUP_CHEQUE_PAYMENT_JOURNAL = [
    {
      id: 4,
      doc_id: 'PO2024010004',
      created_date: '2024-01-10T08:03:59.684Z',
      seller: 'Apple',
      details: 'Test daily check 1',
      net_total: 96.21,
      status: 'active',
    },
    {
      id: 3,
      doc_id: 'PO2024010003',
      created_date: '2024-01-07T07:14:27.328Z',
      seller: 'Bug seller man',
      details: 'Test daily check 2',
      net_total: 936,
      status: 'Working',
    },
    {
      id: 2,
      doc_id: 'PO2024010002',
      created_date: '2024-01-07T06:55:15.567Z',
      seller: 'Cookie Lava',
      details: 'Test daily check 3',
      net_total: 180,
      status: 'active',
    },
    {
      id: 1,
      doc_id: 'PO2024010001',
      created_date: '2024-01-03T08:37:35.073Z',
      seller: 'Edit name',
      details: '',
      net_total: 28.08,
      status: 'active',
    },
  ]
  const MOCKUP_PAYMENT_JOURNAL = [
    {
      id: 4,
      doc_id: 'PO2024010004',
      created_date: '2024-01-10T08:03:59.684Z',
      seller: 'Apple',
      details: 'Test daily pay 1',
      net_total: 96.21,
      status: 'active',
    },
    {
      id: 3,
      doc_id: 'PO2024010003',
      created_date: '2024-01-07T07:14:27.328Z',
      seller: 'Bug seller man',
      details: 'Test daily pay 2',
      net_total: 936,
      status: 'active',
    },
    {
      id: 2,
      doc_id: 'PO2024010002',
      created_date: '2024-01-07T06:55:15.567Z',
      seller: 'Cookie Lava',
      details: 'Test daily pay 3',
      net_total: 180,
      status: 'Doing',
    },
    {
      id: 1,
      doc_id: 'PO2024010001',
      created_date: '2024-01-03T08:37:35.073Z',
      seller: 'Edit name',
      details: ' ',
      net_total: 28.08,
      status: 'active',
    },
  ]
  const MOCKUP_PETTY_CASH_VOUCHER = [
    {
      id: 4,
      doc_id: 'PO2024010004',
      created_date: '2024-01-10T08:03:59.684Z',
      seller: 'Apple',
      details: 'Test petty cash 1',
      payment_type: 'Cash',
      net_total: 96.21,
      status: 'active',
    },
    {
      id: 3,
      doc_id: 'PO2024010003',
      created_date: '2024-01-07T07:14:27.328Z',
      seller: 'Bug seller man',
      details: 'Test petty cash 2',
      payment_type: 'Credit',
      net_total: 936,
      status: 'active',
    },
    {
      id: 2,
      doc_id: 'PO2024010002',
      created_date: '2024-01-07T06:55:15.567Z',
      seller: 'Cookie Lava',
      details: 'Test petty cash 3',
      payment_type: 'Credit',
      net_total: 180,
      status: 'active',
    },
    {
      id: 1,
      doc_id: 'PO2024010001',
      created_date: '2024-01-03T08:37:35.073Z',
      seller: 'Edit name',
      details: ' ',
      payment_type: 'Credit',
      net_total: 28.08,
      status: 'hold',
    },
  ]
  const [bodyTableList, setBodyTableList] = useState([])
  const [bodyDataList, setBodyDataList] = useState([])
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [isSetData, setIsSetData] = useState(false)

  const { isLoading, pageCount, documentList } = useSelector(selectDocument)

  const [filter, setFilter] = useState({
    lastEdit: '',
    searchText: '',
    docType: docType || '',
  })

  const [currentPage, setCurrentPage] = useState(1)

  const navigate = useNavigate()
  const [itemSelected, setItemSelected] = useState()
  const [dialogQuotationType, setDialogQuotationType] = useState('')
  const [isShowPreview, setIsShowPreview] = useState(false)
  const [isShowModalDownloadQuotation, setIsShowModalDownloadQuotation] = useState(false)
  const [isShowDialogRequestApprove, setIsShowDialogRequestApprove] = useState(false)

  const [isHindModalShare, setIsHindModalShare] = useState(false)

  const [optionClickStage, setOptionClickStage] = useState()

  const [docTitle, setDocTitle] = useState('')

  useEffect(() => {
    if (docType != '') {
      setFilter({ ...filter, docType: docType })
      titleByDocType(docType)
    }
  }, [docType])

  const titleByDocType = (txtDocType) => {
    if (txtDocType == 'warehouse') {
      setDocTitle('ใบรับสินค้าคืน')
    } else if (txtDocType == 'goods_received_note') {
      setDocTitle('ใบรับสินค้า')
    } else if (txtDocType == 'warehouse_3') {
      setDocTitle('ใบสั่งปล่อยสินค้า')
    } else if (txtDocType == 'warehouse_4') {
      setDocTitle('หนังสืออนุมัติจ่าย')
    } else if (txtDocType == 'warehouse_5') {
      setDocTitle('ใบ เบิก/ยืมสินค้า')
    }
  }

  useEffect(() => {
    ;(async () => {
      dispatch(
        getBusinessDocumentList(
          {
            page: currentPage,
            pageSize: 10,
          },
          filter
        )
      )
    })()
  }, [filter])

  useEffect(() => {
    if (documentList?.length >= 0) {
      let tmpArray = [...documentList]

      for (let i = 0; i < tmpArray.length; i++) {
        tmpArray[i].checked = false
      }
      setBodyDataList(tmpArray) // Keep Original Data
      setIsSetData(false)
    }
  }, [documentList])

  useEffect(() => {
    if (!isSetData) {
      const tmpList = [...bodyDataList]
      let viewList = []

      if (docType == 'warehouse') {
        viewList = tmpList.map((item, index) => ({
          checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
          no: index + 1,
          date: convertDateFormat(item?.date, 'DD MMM YYYY'),
          doc_id: (
            <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
              {item?.doc_id || '-'}
            </span>
          ),
          detail: item?.details || '-',
          customer: item?.customer || '-',
          net_total: convertNumberToCommaTwodecimal(item?.net_total) || '-',
          status: <CapsuleStatus status={item.status} />,
          action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
        }))
      } else if (docType == 'goods_received_note' || docType == 'payment_journal') {
        viewList = tmpList.map((item, index) => ({
          checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
          no: index + 1,
          date: convertDateFormat(item?.date, 'DD MMM YYYY'),
          doc_id: (
            <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
              {item?.doc_id || '-'}
            </span>
          ),
          detail: item?.details || '-',
          status: <CapsuleStatus status={item?.status} />,
          action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
        }))
      } else if (docType == 'petty_cash_voucher') {
        viewList = tmpList.map((item, index) => ({
          checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
          no: index + 1,
          date: convertDateFormat(item?.date, 'DD MMM YYYY'),
          doc_id: (
            <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
              {item?.doc_id || '-'}
            </span>
          ),
          detail: item?.details || '-',
          payment_type: item?.payment_type || '-',
          status: item?.status ? <CapsuleStatus status={item?.status} /> : '',
          action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
        }))
      }
      setBodyTableList(viewList)
    }
  }, [bodyDataList, isSetData])

  const handleClickedOption = async (type, item) => {
    setOptionClickStage(type)
    setItemSelected(item)
  }

  const handleClickBtnCreateNew = () => {
    setItemSelected(null)
    setOptionClickStage('close')
    if (docType == 'warehouse') {
      navigate('/document/purchases/create-purchase-doc/' + docType)
    } else if (docType == 'goods_received_note') {
      navigate('/document/warehouse/create/?type=' + docType)
    } else if (docType == 'payment_journal') {
      navigate('/document/purchases/create-payment-journal/' + docType)
    } else if (docType == 'petty_cash_voucher') {
      navigate('/document/purchases/create-petty-cash-voucher/' + docType)
    }
  }

  const handleBodyTableChecked = (isChecked, index) => {
    let tmpArray = [...bodyDataList]
    tmpArray[index].checked = isChecked
    setBodyDataList(tmpArray)
    setIsSetData(false)
    if (isChecked == false) {
      setIsSelectAll(false)
    }
  }
  const handleSearchTextChange = (e) => {
    setFilter({ ...filter, searchText: e.target.value })
  }

  const handleClickedPreview = () => {
    setIsHindModalShare(true)
    setDialogQuotationType('preview')
    setIsShowDialogQuotation(true)
  }

  const handleSelectFilter = (e) => {
    setFilter({ ...filter, lastEdit: e })
  }

  const handleClickedDocItem = (itemData) => {
    if (itemData?.id) {
      if (docType == 'warehouse') {
        navigate('/document/purchases/edit-purchase-doc/' + itemData.id)
      } else if (docType == 'goods_received_note') {
        navigate('/document/warehouse/edit/?type=goods_received_note&id=' + itemData.id)
      } else if (docType == 'payment_journal') {
        navigate('/document/purchases/edit-payment-journal/' + itemData.id)
      } else if (docType == 'petty_cash_voucher') {
        navigate('/document/purchases/edit-petty-cash-voucher/' + itemData.id)
      }
    }
  }

  const onChangePagination = async (value) => {
    const pagination = {
      page: value.page,
      pageSize: 10,
    }
    dispatch(getBusinessDocumentList(pagination, filter))
    setCurrentPage(value.page)
  }

  const handleClickClosePreview = () => {
    setIsShowPreview(false)
    setIsHindModalShare(false)
  }
  const getHeaderByDocType = () => {
    if (docType == 'warehouse') {
      return HEADER_COLUMN
    } else if (docType == 'goods_received_note') {
      return HEADER_COLUMN_CHEQUE_PAYMENT_JOURNAL
    } else if (docType == 'payment_journal') {
      return HEADER_COLUMN_PAYMENT_JOURNAL
    } else if (docType == 'petty_cash_voucher') {
      return HEADER_COLUMN_PETTY_CASH_VOUCHER
    }
  }

  const setTitleFromDocType = () => {
    if (docType != '') {
      if (docType == 'warehouse') {
        return 'ใบสั่งซื้อ'
      } else if (docType == 'goods_received_note') {
        return 'สมุดรายวันจ่ายเช็ค'
      } else if (docType == 'payment_journal') {
        return 'สมุดรายวันจ่าย'
      } else if (docType == 'petty_cash_voucher') {
        return 'ใบสำคัญจ่าย-เงินสดย่อย'
      }
    }
    return ''
  }

  const handleClosePreview = () => {
    setOptionClickStage('')
  }

  return (
    <Styled isHindModalShare={isHindModalShare}>
      {docType && (
        <div className="wrp-main-page">
          <div className="header">{docTitle}</div>

          <div className="filter-button">
            <div className="left">
              <div className="title">Last edit</div>
              <div className="filter">
                <DropdownSelect
                  className={'dropdown'}
                  dataList={lastEditList}
                  onChange={handleSelectFilter}
                  initValue={filter?.lastEdit}
                />
                <SearchText onChange={handleSearchTextChange} />
              </div>
            </div>
            <div className="right">
              <div className="btn-create-new" onClick={handleClickBtnCreateNew}>
                <Button>Create new</Button>
              </div>
            </div>
          </div>

          <div className="wrp-table">
            <Table
              columns={getHeaderByDocType()}
              data={bodyTableList}
              onStateChange={onChangePagination}
              pageCount={pageCount}
              page={currentPage}
              loading={isLoading}
            />
          </div>

          {optionClickStage && docType == 'goods_received_note' && (
            <GoodsReceivedNote
              previewType={optionClickStage}
              currentPage={currentPage}
              filter={filter}
              itemSelected={itemSelected}
              onCloseDialog={handleClosePreview}
            />
          )}
        </div>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  .wrp-main-page {
    .header {
      font-size: 30px;
      font-weight: 600;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--Gray-200);
      margin-bottom: 24px;
    }
  }
  .wrp-table {
    > div {
      overflow-x: inherit;
    }
  }

  .txt-doc-item {
    cursor: pointer;
    :hover {
      color: var(--Primary-600);
    }
  }

  .filter-button {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    .left {
      .title-filter {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 6px;
      }
      .filter {
        display: flex;

        .dropdown {
          margin-right: 12px;
        }
      }
    }
    .right {
      display: flex;
      align-items: end;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .btn-cancel {
    width: 48%;
    justify-content: center;
  }
  .btn-download {
    width: 48%;
    justify-content: center;
  }

  .document-share-and-send-email {
    display: ${({ isHindModalShare }) => (isHindModalShare ? 'none' : 'block')};
  }
`
