import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_KPI_LIST,
  GET_KPI_DRAFT_LIST,
  GET_KPI_ARCHIVE_LIST,
  GET_KPI_BY_ID,
  CREATE_KPI,
  EDI_KPI,
  PUBLISH_KPI,
  DELETE_KPI,
  CLEAR_EDIT_KPI_FORM,
  GET_KPI_HISTORY,
  GET_KPI_HISTORY_DETAIL,
} from '../actionTypes'

import { showLoading, hideLoading } from './loading'

export const getKpiPublishList = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_KPI_LIST.REQUEST })

    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const params = {
      'filters[status]': 'publish',
      'filters[company][id]': company_info.id,
      'fields[0]': 'name',
      'fields[1]': 'status',
      'fields[2]': 'createdAt',
      'populate[kpi_organizations][filters][status]': 'active',
      'populate[kpi_departments][filters][status]': 'active',
      'populate[kpi_departments][populate][department]': 'true',
      'populate[company]': 'true',
      'populate[employee]': 'true',
    }
    const { data } = await getAPI({
      url: '/api/kpis',
      headers,
      params,
    })

    return dispatch({
      type: GET_KPI_LIST.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_KPI_LIST.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get KPI Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getKpiDraftList = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_KPI_DRAFT_LIST.REQUEST })

    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const params = {
      'filters[status]': 'draft',
      'filters[company][id]': company_info.id,
      'fields[0]': 'name',
      'fields[1]': 'status',
      'fields[2]': 'createdAt',
      'populate[kpi_organizations][filters][status]': 'active',
      'populate[kpi_departments][filters][status]': 'active',
      'populate[kpi_departments][populate][department]': 'true',
      'populate[company]': 'true',
      'populate[employee]': 'true',
    }
    const { data } = await getAPI({
      url: '/api/kpis',
      headers,
      params,
    })

    return dispatch({
      type: GET_KPI_DRAFT_LIST.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_KPI_DRAFT_LIST.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get KPI Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getKpiArchiveList =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_KPI_ARCHIVE_LIST.REQUEST })

      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'filters[status]': 'archive',
        'filters[company][id]': company_info.id,
        'fields[0]': 'name',
        'fields[1]': 'status',
        'fields[2]': 'createdAt',
        'fields[3]': 'previous_status',
        'populate[kpi_organizations][filters][status]': 'active',
        'populate[kpi_departments][filters][status]': 'active',
        'populate[kpi_departments][populate][department]': 'true',
        'populate[company]': 'true',
        'populate[employee]': 'true',
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
      }
      if (filter.status !== '') params['filters[previous_status]'] = filter.status
      if (filter.createdBy !== '') params['filters[employee][id]'] = filter.createdBy

      const { data, meta } = await getAPI({
        url: '/api/kpis',
        headers,
        params,
      })

      return dispatch({
        type: GET_KPI_ARCHIVE_LIST.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_KPI_ARCHIVE_LIST.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get KPI Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getKpiById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_KPI_BY_ID.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const params = {
      'populate[kpi_organizations][filters][status]': 'active',
      'populate[kpi_departments][filters][status]': 'active',
      'populate[kpi_departments][populate][department]': 'name',
      'populate[company]': 'true',
      'populate[employee]': 'true',
    }
    const { data } = await getAPI({
      url: `/api/kpis/${id}`,
      headers,
      params,
    })

    return dispatch({
      type: GET_KPI_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_KPI_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get KPI by Id Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createKPI = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_KPI.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: '/api/kpis',
      data,
      headers,
    })

    return dispatch({
      type: CREATE_KPI.SUCCESS,
      showAlert: true,
      alertMessage: 'Create KPI Successful',
    })
  } catch (error) {
    return dispatch({
      type: CREATE_KPI.FAILURE,
      error: error,
      showAlert: true,
      alertMessage: 'Create KPI Failed',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getKpiPublishList())
  }
}

export const editKPI = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDI_KPI.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/kpis/${id}`,
      data,
      headers,
    })

    return dispatch({
      type: EDI_KPI.SUCCESS,
      showAlert: true,
      alertMessage: 'Edit KPI Successful',
    })
  } catch (error) {
    return dispatch({
      type: EDI_KPI.FAILURE,
      error: error,
      showAlert: true,
      alertMessage: 'Edit KPI Failed',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getKpiPublishList())
    dispatch(getKpiDraftList())
  }
}

export const publishKPI = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: PUBLISH_KPI.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/kpis/${id}`,
      data,
      headers,
    })

    return dispatch({
      type: PUBLISH_KPI.SUCCESS,
      showAlert: true,
      alertMessage: 'Publish KPI Successful',
    })
  } catch (error) {
    return dispatch({
      type: PUBLISH_KPI.FAILURE,
      error: error,
      showAlert: true,
      alertMessage: 'Publish KPI Failed',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getKpiDraftList())
  }
}

export const deleteKPI = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_KPI.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/kpis/${id}`,
      data,
      headers,
    })

    return dispatch({
      type: DELETE_KPI.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete KPI Successful',
    })
  } catch (error) {
    return dispatch({
      type: DELETE_KPI.FAILURE,
      error: error,
      showAlert: true,
      alertMessage: 'Delete KPI Failed',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getKpiPublishList())
    dispatch(getKpiDraftList())
  }
}

export const clearEditKpiForm = () => async (dispatch) => {
  dispatch({ type: CLEAR_EDIT_KPI_FORM, data: {} })
}

export const getKpiHistoryLogs = (refId) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_KPI_HISTORY.REQUEST })

    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const params = {
      'filters[company][id]': company_info.id,
      'filters[event_level]': 'INFO',
      'filters[feature]': 'kpi',
      'filters[reference]': refId,
      'sort[0][id]': 'desc',
      'fields[0]': 'event_date',
      'fields[1]': 'meta_data',
      'fields[2]': 'feature',
      'fields[3]': 'reference',
    }
    const { data } = await getAPI({
      url: '/api/kpi-history/history',
      headers,
      params,
    })

    return dispatch({
      type: GET_KPI_HISTORY.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_KPI_HISTORY.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get KPI History List Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getKpiHistoryDetail = (historyId) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_KPI_HISTORY_DETAIL.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const params = {
      'fields[0]': 'event_date',
      'fields[1]': 'meta_data',
      'fields[2]': 'reference',
    }
    const { data } = await getAPI({
      url: `/api/kpi-history/history/${historyId}`,
      headers,
      params,
    })

    return dispatch({
      type: GET_KPI_HISTORY_DETAIL.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_KPI_HISTORY_DETAIL.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get KPI History Detail Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}
