import { combineReducers } from 'redux'
import {
    GET_ORGANIZATION_CHART,
    GET_DEPARTMENT_CHART
} from '../actionTypes'

const initialState = {
    organizationChartData: [],
    organizationChartNodes: [],
    departMentList: [],
    isLoading: false,
}

const organizationChartReducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_ORGANIZATION_CHART.REQUEST:
          return {
            ...state,
            isLoading: true,
          }
        case GET_ORGANIZATION_CHART.SUCCESS: {
          const { data, nodes } = action
          return {
            ...state,
            organizationChartData: data,
            organizationChartNodes: nodes,
            isLoading: false,
          }
        }
        case GET_ORGANIZATION_CHART.FAILURE:
          return {
            ...state,
            isLoading: false,
          }
          case GET_DEPARTMENT_CHART.REQUEST:
          return {
            ...state,
            isLoading: true,
          }
        case GET_DEPARTMENT_CHART.SUCCESS: {
          const { data } = action
          return {
            ...state,
            departMentList: data,
            isLoading: false,
          }
        }
        case GET_DEPARTMENT_CHART.FAILURE:
          return {
            ...state,
            isLoading: false,
          }
        default:
            return state
    }
}

export default combineReducers({
    organizationChartReducer,
})
  