import dayjs from 'dayjs'

export const isInWorkingHours = (date, { start_time, end_time }) => {
  const currentHour = date.hour()
  const currentMinute = date.minute()

  const [startHour, startMinute] = start_time.split(':').map(Number)
  const [endHour, endMinute] = end_time.split(':').map(Number)

  const afterStartTime = currentHour > startHour || (currentHour === startHour && currentMinute >= startMinute)
  const beforeEndTime = currentHour < endHour || (currentHour === endHour && currentMinute < endMinute)

  return afterStartTime && beforeEndTime
}

const getNextWorkingTime = (date, workingTime) => {
  const { start_time } = workingTime
  const [nextHour, nextMinute] = start_time.split(':').map(Number)
  return dayjs(date).set({ hour: nextHour, minute: nextMinute })
}

const findCurrentWorkingIndex = (date, workingTimes) => {
  const currentHour = date.hour()
  const currentMinute = date.minute()

  for (let i = 0; i < workingTimes.length; i++) {
    const { start_time } = workingTimes[i]
    const [startHour, startMinute] = start_time.split(':').map(Number)

    if (currentHour < startHour || (currentHour === startHour && currentMinute < startMinute)) {
      return i
    }
  }

  return 0 // If current time is after all working times, start from the beginning
}

export const addMinutes = (date, minutes, workingTimes) => {
  let addedMinutes = 0
  let currentWorkingIndex = findCurrentWorkingIndex(date, workingTimes)

  while (addedMinutes < minutes) {
    date = date.add(1, 'minute')

    // Skip counting if the day is Saturday or Sunday
    if (date.day() === 0 || date.day() === 6) {
      date = date.add(1, 'day')
      continue
    }

    if (isInWorkingHours(date, workingTimes[currentWorkingIndex])) {
      addedMinutes++
    } else {
      currentWorkingIndex = (currentWorkingIndex + 1) % workingTimes.length

      if (currentWorkingIndex === 0) {
        date = getNextWorkingTime(date, workingTimes[0])
      }

      if (isInWorkingHours(date, workingTimes[currentWorkingIndex])) {
        addedMinutes++
      }

      date = getNextWorkingTime(date, workingTimes[currentWorkingIndex])
    }
  }

  return date
}
