import { useEffect } from 'react'
import styled from 'styled-components'

export default function DialogBox({
  title,
  titleColor = '#444',
  body,
  footer,
  isUnderLine,
  underLineColor = '#444',
  width,
  height,
  maxWidth,
  maxHeight,
  isBackgroundScroll,
  isBodyScroll = true,
  className,
}) {
  useEffect(() => {
    if (isBackgroundScroll) {
      document.body.style.overflow = 'auto'
    } else {
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isBackgroundScroll])

  return (
    <Styled
      titleColor={titleColor}
      underLineColor={underLineColor}
      isUnderLine={isUnderLine}
      isBodyScroll={isBodyScroll}
      className={className}
    >
      <div
        className={'modal'}
        style={{
          width: width,
          height: height,
          maxWidth: maxWidth,
          maxHeight: maxHeight,
        }}
      >
        <div className={title ? 'title-modal underline' : 'non-title-modal underline'}>{title ? title : ''}</div>

        <div className={'content' + ' ' + (title && 'content_padding_top')}>{body}</div>
        {footer && <div className={'footer'}>{footer}</div>}
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  inset: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  user-select: none;

  .wrp_float {
    position: fixed;
    visibility: hidden;
  }
  .modal {
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 480px;
    height: calc(100vh - 64px);
    border-radius: 10px;
    position: relative;
  }
  .title-modal {
    font-size: 24px;
    color: ${({ titleColor }) => titleColor};
    line-height: 32px;
    text-align: center;
    font-weight: bolder;
    padding: 30px 70px 20px 54px;
  }

  .non-title-modal {
    padding: 30px 70px 52px 54px;
  }
  .underline {
    border: ${({ isUnderLine, underLineColor }) => (isUnderLine ? '1px solid ' + underLineColor : 'none')};
  }
  .content_padding_top {
    padding-top: 20px;
  }

  .content {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    overflow: ${({ isBodyScroll }) => (isBodyScroll ? 'auto' : 'inherit')};
    padding: 0 20px 0 20px;
  }
  .nonTitle {
    padding: 83px 20px 20px 20px;
  }
  .footer {
    padding: 25px;
    border-top: ${({ isUnderLine, underLineColor }) => (isUnderLine ? '1px solid ' + underLineColor : 'none')};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer > span {
    margin-right: 5px;
  }
`
