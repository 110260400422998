import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { DateTimeHHmmssDisplay,GetPermissionOther,GetPermissionTabList } from '../../../utils/common'

import { selectDocumentCategory } from '../../../redux/selectors'
import {
  getDocumentCategory,
  getDocumentCategoryById,
  createDocumentCategory,
  editDocumentCategory,
  deleteDocumentCategory,
} from '../../../redux/actions/documentCategory'

import styled from 'styled-components'
import Button from '../../../components/form/button/Button'
import SearchText from '../../../components/form/SearchText'
import Table from '../../../components/common/Table'
import DialogConfirmDelete from '../../../components/dialog/DialogConfirmDelete'
import Dropdown from '../../../components/form/Dropdown'
import Modal from '../../../components/common/Modal'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import DialogSuccess from '../../../components/dialog/DialogSuccess'
import DialogFail from '../../../components/dialog/DialogFail'
import CollapseMenu from '../../../components/common/CollapseMenu'
import SidePanel from '../../../components/common/SidePanel'
import CreateCategory from './CreateCategory'
import EditCategory from './EditCategory'

import { TbPointFilled } from 'react-icons/tb'
import { LiaTimesSolid } from 'react-icons/lia'
import successIcon from '../../../assets/images/success-icon.png'
import failIcon from '../../../assets/images/fail-icon.png'
import changeStatusIcon from '../../../assets/images/change-status.png'

const Wrapper = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .d-flex {
    display: flex;
  }
  .justify-content-center {
    justify-content: center;
  }

  .headline {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-900);
  }
`
const TableWrapper = styled.div`
  .table-header {
    padding: 1rem 0.875rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: var(--Base-White);
    border: 0.0625rem solid var(--Gray-200);

    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.25rem;

      .table-header-name {
        color: var(--Gray-900);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        margin-right: 0.5rem;
      }
    }

    .search-container {
      display: flex;
      .clear-button-container {
        display: flex;
        align-items: flex-end;
      }
    }
  }
`
const StatusBadge = styled.div`
  display: inline-block;

  border-radius: 6px;
  border: 1px solid var(--Gray-300);
  background: var(--Base-White);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  padding: 0 0.375rem;

  color: var(--Gray-700);
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;

  .status-item {
    vertical-align: middle;
  }

  .active-color {
    color: var(--Status-Active);
  }
  .inactive-color {
    color: var(--Status-Inactive);
  }
  .draft-color {
    color: var(--Status-Draft);
  }
`
const BadgeSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .badge {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`
const ChangeStatusContainer = styled.div`
  padding: 20px;
  width: 384px;

  .button-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
`
const InputContainer = styled.div`
  .width-100 {
    width: 100%;
  }
  .width-80 {
    width: 80%;
  }
  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.375rem;
  }
`

const initMessageModal = {
  headline: '',
  message: '',
}

const initStatusDropdown = [
  {
    text: 'Active',
    value: 'Active',
  },
  {
    text: 'Inactive',
    value: 'Inactive',
  },
]

function DocumentCategory() {
  const { t } = useTranslation()
  const initLang = localStorage.getItem('lang')
  //--- ST ส่วนของ Permission LV3-4
  const permissionNewDocument = GetPermissionOther('Document Category', 'New Categoey')
  const permissionDeleteDocument = GetPermissionOther('Document Category', 'Delete Category')
  /*
  const { permission } = JSON.parse(localStorage.getItem('admin'))
  const [permissionNewDocument, setpermissionNewDocument] = useState(null);
  let permissionDeleteDocument = null;
  useEffect(() => {
    const this_sub_menu_permission = permission['Human Resources & Organization'].filter((it_2) => it_2.path === '/organization-management/acknowledgement-system');
    const tab_permission = this_sub_menu_permission[0].permission_tab?.filter((it_2) => it_2.menu_setting.rout_path === 'AcknowledgementSystem_DocumentCategory')
    if(tab_permission.length > 0){
      const this_permissionNewDocument = tab_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'AcknowledgementSystem_DocumentCategory_NewCategory');
      if(this_permissionNewDocument){
        setpermissionNewDocument(this_permissionNewDocument)
      }
      const this_permissionDeleteDocument = tab_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'AcknowledgementSystem_DocumentCategory_DeleteCategory');
      if(this_permissionDeleteDocument){
        //setpermissionDeleteDocument(this_permissionDeleteDocument)
        permissionDeleteDocument = this_permissionDeleteDocument;
      }
    }
  }, [])
  */
  //--- ED ส่วนของ Permission LV3-4

  const dispatch = useDispatch()
  // column
  const categoryColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('category'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('no_document'),
      accessor: 'no_document',
      disableSortBy: false,
    },
    {
      Header: t('created_by'),
      accessor: 'created_by',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const { documentCategories, page, pageCount, total, limit, isLoading, state } = useSelector(selectDocumentCategory)
  const [categoryList, setCategoryList] = useState([])
  const [categoryId, setCategoryId] = useState('')
  const [status, setStatus] = useState('')
  // filter
  const [search, setSearch] = useState('')
  const [statusSelected, setStatusSelected] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  // side panel
  const [createSidePanel, setCreateSidePanel] = useState(false)
  const [editSidePanel, setEditSidePanel] = useState(false)
  // modal
  const [changeStatusModal, setChangeStatusModal] = useState(false)
  // dialog
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  // modal message
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const fetchData = useCallback(
    (filter) => {
      dispatch(getDocumentCategory(filter))
    },
    [dispatch]
  )
  const editCategory = useCallback(
    (id) => {
      dispatch(getDocumentCategoryById(id))
      setCategoryId(id)
      setEditSidePanel(true)
    },
    [dispatch]
  )
  const changeStatusCategory = useCallback((id, name) => {
    setCategoryId(id)
    setStatus(name)
    setChangeStatusModal(true)
  }, [])
  const deleteCategory = useCallback((id) => {
    
    if(permissionDeleteDocument?.canUpdate === true){
      setCategoryId(id)
      setConfirmDeleteModal(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionDeleteDocument?.name_en;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionDeleteDocument?.name_th;
      }
      setFailMessageModal({
        headline: message_permission,
        message: '',
      })
      setFailModal(true)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const newCategoryList = documentCategories.map((v, i) => {
      const list = [
        {
          text: t('Edit'),
          action: () => editCategory(v.id),
        },
        {
          text: t('change_status'),
          action: () => changeStatusCategory(v.id, v.status),
        },
        {
          text: t('delete'),
          action: () => deleteCategory(v.id),
        },
      ]
      const statusStyle = v.status === 'Inactive' ? 'inactive-color' : 'draft-color'
      return {
        ...v,
        no: i + 1,
        no_document: v?.document_acknow_templates?.count,
        created_by: `${v?.creator?.first_name} ${v?.creator?.last_name}`,
        status: (
          <StatusBadge>
            <TbPointFilled
              className={`status-item ${v.status === 'Active' ? 'active-color' : statusStyle}`}
              size={20}
            />
            <span className="status-item">{v.status}</span>
          </StatusBadge>
        ),
        action: <CollapseMenu list={list} />,
      }
    })
    setCategoryList(newCategoryList)
  }, [documentCategories])

  useEffect(() => {
    if (state === 'CREATE_DOCUMENT_CATEGORY.SUCCESS' && isLoading === false) {
      setCreateSidePanel(false)
      setSuccessMessageModal({
        headline: 'Create successful',
        message: '',
      })
      setSuccessModal(true)
    }
    if (state === 'EDIT_DOCUMENT_CATEGORY.SUCCESS' && isLoading === false) {
      setEditSidePanel(false)
      setSuccessMessageModal({
        headline: 'Edit successful',
        message: '',
      })
      setSuccessModal(true)
    }
    if (state === 'DELETE_DOCUMENT_CATEGORY.SUCCESS' && isLoading === false) {
      setSuccessMessageModal({
        headline: 'Delete successful',
        message: '',
      })
      setSuccessModal(true)
    }
    if (state === 'DELETE_DOCUMENT_CATEGORY.FAILURE' && isLoading === false) {
      setFailMessageModal({
        headline: 'This category can not be deleted',
        message: 'There is document created under this category',
      })
      setFailModal(true)
    }
  }, [isLoading, state])

  // filter
  useEffect(() => {
    if (statusSelected !== '') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))
      const filter = {
        page: 1,
        pageSize: limit,
        status: statusSelected,
        multipleSearch,
      }
      fetchData(filter)
    }
  }, [statusSelected])

  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))

      const filter = {
        page: 1,
        pageSize: limit,
        status: statusSelected,
        multipleSearch,
      }
      fetchData(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }

  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))

    const filter = {
      page: 1,
      pageSize: limit,
      status: statusSelected,
      multipleSearch,
    }
    fetchData(filter)
    setSearch('')
  }

  const onClearSearch = () => {
    const request = {
      page: 1,
      pageSize: limit,
      status: '',
    }
    fetchData(request)
    clearFilter()
  }

  const clearFilter = () => {
    setSearch('')
    setStatusSelected('')
    setSearchFilters([])
  }

  const onChangePagination = async (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    const filter = {
      page: value.page,
      pageSize: limit,
      status: statusSelected,
      multipleSearch,
    }
    fetchData(filter)
  }

  // modal
  const confirmFailModal = () => {
    setFailModal(false)
  }

  // side panel
  const openCreateSidePanel = () => {
    
    if(permissionNewDocument?.canUpdate === true){
      setCreateSidePanel(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionNewDocument?.name_en;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionNewDocument?.name_th;
      }
      setFailMessageModal({
        headline: message_permission,
        message: '',
      })
      setFailModal(true)
    }
  }
  const closeCreateSidePanel = () => {
    setCreateSidePanel(false)
  }
  const closeEditSidePanel = () => {
    setEditSidePanel(false)
  }

  // submit
  const submitCreateCategory = (data) => {
    dispatch(createDocumentCategory(data))
  }
  const submitEditCategory = (id, data) => {
    dispatch(editDocumentCategory(id, data))
  }
  const confirmDelete = () => {
    dispatch(deleteDocumentCategory(categoryId))
    setConfirmDeleteModal(false)
  }
  const confirmChangeStatus = () => {
    const request = {
      data: {
        status: status,
      },
    }

    dispatch(editDocumentCategory(categoryId, request))
    setChangeStatusModal(false)
  }

  return (
    <Wrapper>
      <TableWrapper>
        <div className="table-header">
          <div className="header-container">
            <div>
              <span className="table-header-name">{t('all_document_category')}</span>
              <span className="badge">
                {total} {t('categories')}
              </span>
            </div>
            {permissionNewDocument?.canRead === true && (
              <Button onClick={openCreateSidePanel}>{t('new_category')}</Button>
            )}
          </div>
          <div className="search-container mb-1-rem">
            <div className="input-container">
              <div className="input-header">{t('search')}</div>
              <SearchText
                placeholder={t('search')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSubmit={onSearch}
                onClear={() => setSearch('')}
              />
            </div>
            <div className="input-container width-20">
              <div className="input-header">{t('status')}</div>
              <Dropdown
                optionList={initStatusDropdown}
                value={statusSelected}
                onChange={(value) => setStatusSelected(value)}
              />
            </div>
            <div className="clear-button-container">
              <ButtonOutline onClick={onClearSearch}>{t('clear')}</ButtonOutline>
            </div>
          </div>
          <BadgeSearchContainer>
            {searchFilters.map((v) => (
              <div className="badge" key={v.key}>
                <span className="name">{v.name}</span>{' '}
                <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
              </div>
            ))}
          </BadgeSearchContainer>
        </div>
        <Table
          data={categoryList}
          columns={categoryColumn}
          onStateChange={onChangePagination}
          page={page}
          pageCount={pageCount}
        />
      </TableWrapper>
      {/* side panel */}
      <SidePanel isOpen={createSidePanel} setIsOpen={openCreateSidePanel} width={400}>
        <CreateCategory close={closeCreateSidePanel} onSubmit={submitCreateCategory} />
      </SidePanel>
      <SidePanel isOpen={editSidePanel} width={400}>
        <EditCategory close={closeEditSidePanel} onSubmit={submitEditCategory} />
      </SidePanel>
      {/* Modal */}
      <Modal open={Boolean(changeStatusModal)} onClose={() => setChangeStatusModal(false)} disableBackdropClick>
        <ChangeStatusContainer>
          <Wrapper>
            <div className="d-flex justify-content-center mb-1-rem">
              <img src={changeStatusIcon} />
            </div>
            <div className="headline d-flex justify-content-center mb-1-rem">{t('change_status')}</div>
            <InputContainer className="mb-1-rem">
              <Dropdown
                className="width-100"
                isStatus={true}
                placeHolder="Status"
                optionList={initStatusDropdown}
                value={status}
                onChange={(value) => setStatus(value)}
              />
            </InputContainer>
            <div className="button-content">
              <ButtonOutline onClick={() => setChangeStatusModal(false)} className="d-flex justify-content-center">
                {t('cancel')}
              </ButtonOutline>
              <Button onClick={confirmChangeStatus} className="d-flex justify-content-center">
                {t('update')}
              </Button>
            </div>
          </Wrapper>
        </ChangeStatusContainer>
      </Modal>
      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => setSuccessModal(false)}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={confirmDelete}
        title={t('delete_document_category')}
        description={t('delete_document_category_desc')}
      />
    </Wrapper>
  )
}

export default DocumentCategory
