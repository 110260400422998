import { useTranslation } from 'react-i18next'

/**
 *
 * @param {string} type 'sow' | 'task'
 * @returns TableColumn[]
 */
export default function (type = 'sow', otherProps) {
  const { t } = useTranslation()

  switch (type) {
    case 'task':
      return [
        {
          Header: t('id'),
          accessor: 'code',
          disableSortBy: false,
        },
        {
          Header: t('sop'),
          accessor: 'sp_name',
          disableSortBy: false,
        },
        {
          Header: t('scope_of_work'),
          accessor: 'sw_name',
          disableSortBy: false,
        },
        {
          Header: t('task'),
          accessor: 'task_name',
          disableSortBy: false,
        },
        {
          Header: t('status'),
          accessor: 'status',
          disableSortBy: false,
        },
        {
          Header: t('due_date'),
          accessor: 'sw_due_date',
          disableSortBy: false,
        },
        {
          Header: t('created_by'),
          accessor: 'created_by',
          disableSortBy: false,
        },
      ]

    case 'sow':
    default:
      return [
        {
          Header: t('id'),
          accessor: 'code',
          disableSortBy: false,
        },
        {
          Header: t('sop'),
          accessor: 'sp_name',
          disableSortBy: false,
        },
        {
          Header: t('scope_of_work'),
          accessor: 'sw_name',
          disableSortBy: false,
        },
        {
          Header: t('status'),
          accessor: 'status',
          disableSortBy: false,
        },
        {
          Header: t('due_date'),
          accessor: 'due_date',
          disableSortBy: false,
        },
        {
          Header: t('created_by'),
          accessor: 'created_by',
          disableSortBy: false,
        },
      ]
  }
}
