export default function IconXCircle({ width = '14', color = '#333', style, backgroundColor = 'none' }) {
  return (
    <svg width={width} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M14 8L8 14M8 8L14 14M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={backgroundColor}
      />
    </svg>
  )
}
