import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCompany } from '../../redux/actions/master'
import { getAccessRoleById,updateRole } from '../../redux/actions/accessRole'
import { selectAccessRole } from '../../redux/selectors'

import { getCompanyInfo } from '../../utils/common'
import { useTranslation } from 'react-i18next'

import TextField from '../../components/form/TextField'
import InputTextArea from '../../components/widget/element/InputTextArea'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Dropdown from '../../components/form/Dropdown'
import OutlineCheckbox from '../../components/form/OutlineCheckbox'
import SwitchButton from '../../components/form/SwitchButton'


import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import cursorClick from '../../assets/images/cursor-click-01.png'
import chevronRight from '../../assets/images/chevron-right.png'
import chevronDown from '../../assets/images/chevron-down.png'

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  } 
  
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 70px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill{
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
    font-weight: bold;
  }
  .sub_topic_fill{
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .content{
    margin:10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }

  .prefix_icon{
    display: flex;
    align-items: center;
    margin-left: 5px;
  }

  .permission-header {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-items: center; */
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--Primary-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .permission-content {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-items: center; */
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  /* ST CSS NewDesign 17012024 */
  .div_table {
    padding: 0px;
  }
  .resp-table {
    width: 100%;
    display: table;
  }
  .resp-table-body {
    display: table-row-group;
  }
  .resp-table-row {
    display: table-row;
  }
  .table-body-head_cell {
    display: table-cell;
    border: 1px solid #EAECF0;
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    text-align: center;
    background-color: #F9FAFB;
    color: #475467;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .table-body-cell {
    display: table-cell;
    border: 1px solid #EAECF0;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color : #344054;
  }
  .width_expand {
    width: 5%;
  }
  .width_menu {
    text-align: left;
    width: 75%;
  }
  .width_view {
    width: 10%;
  }
  .width_edit {
    width: 10%;
  }
  .border_top_left_radius {
    border-top-left-radius: 8px;
  }
  .border_top_right_radius {
    border-top-right-radius: 8px;
  }
  .border_bottom_left_radius {
    border-bottom-left-radius: 8px;
  }
  .border_bottom_right_radius {
    border-bottom-right-radius: 8px;
  }
  .text-bold{
    font-weight: bold;
  }
  .sub_menu_padding{
    display: grid;
    grid-template-columns: repeat(2, 20px [col-start] auto [col-end]);
    gap: 10px;
  }
  .setting_lv3_text{
    display: grid;
    grid-template-columns: repeat(2, 20px [col-start] auto [col-end]);
    gap: 10px;
    padding-left: 30px;
    
  }
  .setting_lv4_padding{
    display: grid;
    grid-template-columns: repeat(2, 20px [col-start] auto [col-end]);
    gap: 10px;
    padding-left: 60px;
  }

  .collapse {
    display: none
  }

  .collapse.in {
    display: block
  }
  .add_pointer {
    cursor: pointer;
  }

  /* ED CSS NewDesign 17012024 */

  .group_content{
    display: grid;
    grid-template-columns: repeat(2, 40% [ col-start ] 58% [ col-end ]);
    gap: 2%;
  }
  .css_magin_top_16{
    margin-top: 16px;
  }
  .box_all_permission{
    display: flex;
    gap: 10px;
  }
  .text_all_permission{
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    color : #344054;
  }
  .checkbox_permission{
    display: flex;
    align-items: center;
  }
`
const DivContentLeft = styled.div`
font-size: 16px;
font-style: normal;
font-weight: 500;
color : #475467;
.header_menu{
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 4px;
  padding: 0px 12px;
}
.group_menu_list{
  border-radius: 6px;
  border: 1px solid #EAECF0;
  background: #FFFFFF;
  padding: 8px 12px;
  margin-bottom: 4px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  
  &:hover {
    border: 1px solid #F2F4F7;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  }
}
.menu_active{
  background: #E9D7FE;
}
.box_menu_name{
  display: grid;
  grid-template-columns: repeat(2, 20px [ col-start ] auto [ col-end ]);
  gap: 10px;
  cursor: pointer;
}
.box_menu_name_lv2{
  display: grid;
  grid-template-columns: repeat(2, 20px [ col-start ] auto [ col-end ]);
  gap: 10px;
  padding-left: 30px;
  cursor: pointer;
}
.box_menu_name_lv3{
  display: grid;
  grid-template-columns: repeat(2, 20px [ col-start ] auto [ col-end ]);
  gap: 10px;
  padding-left: 60px;
  cursor: pointer;
}
.box_menu_name_lv4{
  display: grid;
  grid-template-columns: repeat(2, 20px [ col-start ] auto [ col-end ]);
  gap: 10px;
  padding-left: 90px;
  cursor: pointer;
}
.text_menu_name{
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color : #344054;
}
.div_collapse{
  display: flex;
  align-items: center;
}
`
const DivContentRight = styled.div`
font-size: 16px;
font-style: normal;
font-weight: 500;
color : #475467;
.text_click_menu{
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color : #101828;
  text-align: center;
}
`

const DivContentOther = styled.div`
font-size: 16px;
font-style: normal;
font-weight: 500;
color : #344054;
.group_header{
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.text_topic{
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color : #344054;
}
.box_all_permission{
  display: flex;
  gap: 10px;
}
.checkbox_permission_other{
  display: flex;
  align-items: center;
}
.border_header {
  border-bottom: 1px solid #EAECF0;
}
.css_magin_top_12{
  margin-top: 12px;
}
`

const DivContentTab = styled.div`
font-size: 16px;
font-style: normal;
font-weight: 500;
color : #344054;
.border_header {
  border-bottom: 1px solid #EAECF0;
}
.css_magin_top_12{
  margin-top: 12px;
}
.group_header{
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.text_topic{
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  color : #344054;
}
.box_all_permission{
  display: flex;
  gap: 10px;
}
.checkbox_permission_other{
  display: flex;
  align-items: center;
}
.box_tab{
  border-radius: 6px;
  border: 1px solid #EAECF0;
  background: #FFFFFF;
  padding: 8px 12px;
  margin-bottom: 4px;
  &:hover {
    border: 1px solid #F2F4F7;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
  }
}
.group_tab_list{
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
}
.box_menu_name{
  display: grid;
  grid-template-columns: repeat(2, 20px [ col-start ] auto [ col-end ]);
  gap: 10px;
  cursor: pointer;
}
.text_menu_name{
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color : #344054;
}
.div_collapse{
  display: flex;
  align-items: center;
}
`

  const intialdata_data = {
    name : null,
    description : null,
    role_company : []
  }

const ViewRole = ({ onClose, onSubmit,IdEdit,...props  }) => {
  const initLang = localStorage.getItem('lang')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const CompanyInfo = getCompanyInfo()
  const alldisabled = true;

  const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
 

  const [CompanyDefault, setCompanyDefault] = useState(null)
  const [CompanyPermission, setCompanyPermission] = useState(null)
  const [Company_option, setCompany_option] = useState([])
  const [Role_Data, setRole_Data] = useState(intialdata_data)



  
  useEffect(() => {
    dispatch(getAllCompany()).then(({ type,data_info,data_meta }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_meta){
                const newCompanyOption = data_info.map((v, index) => ({
                    value : v.id,
                    text : v.company_name_TH
                }))
                setCompany_option(newCompanyOption)
            }
        }
    })
}, [dispatch]) 


  useEffect(() => {
    if(IdEdit){
        
        dispatch(getAccessRoleById(IdEdit))
    }   
  }, [dispatch])

  const { accessRoleInfo } = useSelector(selectAccessRole)

  useEffect(() => {
    if(accessRoleInfo.name){
      //alert(JSON.stringify(accessRoleInfo))
      //console.log(JSON.stringify(accessRoleInfo))
      setCompanyDefault(1)
      setCompanyPermission(1)
      setRole_Data(accessRoleInfo)
    }
  }, [accessRoleInfo])



  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const ConfirmDialog = () => {
    setModalConfirmDialog(true)
  }
  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)
  const CreateNewDailySummary = () => {
    setModalConfirmDialog(false);
    let new_role_company = [];
    for (const role_company_item of Role_Data.role_company) {
      let new_menu_permission = [];
      for (const menu_lv1 of role_company_item.menu_permission) {
        new_menu_permission.push(
          {
            menu_company_id : menu_lv1.menu_company_id,
            menu_setting : menu_lv1.menu_setting,
            is_read: menu_lv1.is_read,
            is_edit: menu_lv1.is_edit,
            is_delete: false
          }
        );
        //---- PermissionTab
        if(menu_lv1.permission_tab){
          GenPermissionTab(menu_lv1.permission_tab,new_menu_permission);
        }
        //---- PermissionOther
        if(menu_lv1.permission_other){
          GenPermissionOther(menu_lv1.permission_other,new_menu_permission);
        }
        
        //---- ST MenuLV
        if(menu_lv1.children){//--- MenuLV2
          for (const menu_lv2 of menu_lv1.children) {
            new_menu_permission.push(
              {
                menu_company_id : menu_lv2.menu_company_id,
                menu_setting : menu_lv2.menu_setting,
                is_read: menu_lv2.is_read,
                is_edit: menu_lv2.is_edit,
                is_delete: false
              }
            );
            //---- PermissionTab
            if(menu_lv2.permission_tab){
              GenPermissionTab(menu_lv2.permission_tab,new_menu_permission);
            }
            //---- PermissionOther
            if(menu_lv2.permission_other){
              GenPermissionOther(menu_lv2.permission_other,new_menu_permission);
            }
            if(menu_lv2.children){//--- MenuLV3
              for (const menu_lv3 of menu_lv2.children) {
                new_menu_permission.push(
                  {
                    menu_company_id : menu_lv3.menu_company_id,
                    menu_setting : menu_lv3.menu_setting,
                    is_read: menu_lv3.is_read,
                    is_edit: menu_lv3.is_edit,
                    is_delete: false
                  }
                );
                //---- PermissionTab
                if(menu_lv3.permission_tab){
                  GenPermissionTab(menu_lv3.permission_tab,new_menu_permission);
                }
                //---- PermissionOther
                if(menu_lv3.permission_other){
                  GenPermissionOther(menu_lv3.permission_other,new_menu_permission);
                }
                if(menu_lv3.children){//--- MenuLV4
                  for (const menu_lv4 of menu_lv3.children) {
                    new_menu_permission.push(
                      {
                        menu_company_id : menu_lv4.menu_company_id,
                        menu_setting : menu_lv4.menu_setting,
                        is_read: menu_lv4.is_read,
                        is_edit: menu_lv4.is_edit,
                        is_delete: false
                      }
                    );
                    //---- PermissionTab
                    if(menu_lv4.permission_tab){
                      GenPermissionTab(menu_lv4.permission_tab,new_menu_permission);
                    }
                    //---- PermissionOther
                    if(menu_lv4.permission_other){
                      GenPermissionOther(menu_lv4.permission_other,new_menu_permission);
                    }

                  }
                }
              }
            }
          }
        }
        //---- ED MenuLV
      }  

      if(new_menu_permission.length > 0){
        new_role_company.push(
          {
            company_id : role_company_item.company_id,
            is_default : role_company_item.is_default,
            role_company_id : role_company_item.role_company_id,
            menu_permission : new_menu_permission
          }
        );
      }
    }
    if(new_role_company.length > 0){
      const sent_data_api = {
        data : {
          name : Role_Data.name,
          description : Role_Data.description,
          role_company : new_role_company
        }
      }
      //console.log(JSON.stringify(sent_data_api));
      //alert('OKOKO')
      
      dispatch(updateRole(IdEdit,sent_data_api)).then(({ type }) => {
        if(type.endsWith('_SUCCESS')){
          setTitlesuccess(t('access_role_update_success'));
          setSubTitlesuccess('');
          setDescriptionsuccess(''); 
          setsuccessModal(true)
        }else{
          setTitleFail(t('update_access_role_failed_headline'));
          setnameItemFail('');
          setDescriptionFail(t('update_access_role_failed_desc')); 
          setFailModal(true)
        }     
      })
      
    }else{
      setTitleFail(t('update_access_role_failed_headline'));
      setnameItemFail('');
      setDescriptionFail(t('update_access_role_failed_desc')); 
      setFailModal(true)
    }
    /*
    const sent_data_api = {
      data: Role_Data,
    }

    dispatch(updateRole(IdEdit,sent_data_api)).then(({ type }) => {
      if(type.endsWith('_SUCCESS')){
        setTitlesuccess(t('access_role_update_success'));
        setSubTitlesuccess('');
        setDescriptionsuccess(''); 
        setsuccessModal(true)
      }else{
        setTitleFail(t('update_access_role_failed_headline'));
        setnameItemFail('');
        setDescriptionFail(t('update_access_role_failed_desc')); 
        setFailModal(true)
      }     
    })
    */
    
    //onSubmit()
  }

  const GenPermissionTab = (inp_permission_tab,ref_data) => {
    for (const permission_tab_item of inp_permission_tab) {
      ref_data.push(
        {
          menu_company_id : permission_tab_item.menu_company_id,
          menu_setting : permission_tab_item.menu_setting,
          is_read: permission_tab_item.is_read,
          is_edit: permission_tab_item.is_edit,
          is_delete: false
        }
      );
      if(permission_tab_item.permission_other){
        GenPermissionOther(permission_tab_item.permission_other,ref_data);
      }
    }
  }

  const GenPermissionOther = (inp_permission_other,ref_data) => {
    for (const permission_other_item of inp_permission_other) {
      ref_data.push(
        {
          menu_company_id : permission_other_item.menu_company_id,
          menu_setting : permission_other_item.menu_setting,
          is_read: permission_other_item.is_read,
          is_edit: permission_other_item.is_edit,
          is_delete: false
        }
      );
    }
  }

  const onDefaultCompany = (v) => {
    setCompanyDefault(v)
    const newState = Role_Data.role_company.map((obj, index) => {
      if (obj.company_id === v) {
        return { ...obj, is_default: true }
      }else{
        return { ...obj, is_default: false }
      }
    })
    //alert(JSON.stringify(newState))
    //
    setRole_Data({ ...Role_Data, role_company: newState })
  }

  const onCompanyPermission = (v) => {
    setCompanyPermission(v)
  }

  const onChangeAllCheckbox = (menu_setting,is_checked) => { 
    const newState = Role_Data.role_company.map((obj, index) => {
      if (obj.company_id === CompanyPermission) {
        const newState_sub = obj.menu_permission.map((obj_sub, index_sub) => {          
          if (obj_sub.ref_menu_id === menu_setting) {
            return { ...obj_sub, is_read: is_checked ? false : true }
          }else if(obj_sub.menu_setting === menu_setting){
            return { ...obj_sub, is_check_all: is_checked ? false : true }
          }
          return obj_sub
        })  
        return { ...obj, menu_permission : newState_sub }
      }

      // 👇️ otherwise return the object as is
      return obj
    })
    setRole_Data({ ...Role_Data, role_company: newState })
  }

  const handleCollapse = (sub_menu_setting,is_checked,index_item) => { 
    const newState = Role_Data.role_company.map((obj, index) => {
      if (obj.company_id === CompanyPermission) {
        const this_index_item = obj.menu_permission.findIndex(obj => obj.menu_setting==sub_menu_setting);
        const newState_sub = obj.menu_permission.map((obj_sub, index_sub) => {
          
          if (index_sub === this_index_item) {
            return { ...obj_sub, is_collapse: is_checked ? false : true }
          }
          return obj_sub
        })  
        return { ...obj, menu_permission : newState_sub }
      }
      return obj
    })
    setRole_Data({ ...Role_Data, role_company: newState })
  }

  const handleChangeCheckBox = (sub_menu_setting,is_checked,index_item) => { 
      const newState = Role_Data.role_company.map((obj, index) => {
        if (obj.company_id === CompanyPermission) {
          const this_index_item = obj.menu_permission.findIndex(obj => obj.menu_setting==sub_menu_setting);
          const newState_sub = obj.menu_permission.map((obj_sub, index_sub) => {
            
            if (index_sub === this_index_item) {
              return { ...obj_sub, is_read: is_checked ? false : true }
            }
            return obj_sub
          })  
          return { ...obj, menu_permission : newState_sub }
        }
        return obj
      })
      setRole_Data({ ...Role_Data, role_company: newState })
  }

  const handleChangeCheckBoxEdit = (sub_menu_setting,is_checked,index_item) => { 
    const newState = Role_Data.role_company.map((obj, index) => {
      if (obj.company_id === CompanyPermission) {
        const this_index_item = obj.menu_permission.findIndex(obj => obj.menu_setting==sub_menu_setting);
        const newState_sub = obj.menu_permission.map((obj_sub, index_sub) => {
          
          if (index_sub === this_index_item) {
            return { ...obj_sub, is_edit: is_checked ? false : true }
          }
          return obj_sub
        })  
        return { ...obj, menu_permission : newState_sub }
      }
      return obj
    })
    setRole_Data({ ...Role_Data, role_company: newState })
  }

  const refreshBineData = () => { 
    setRole_Data({ ...Role_Data, name: Role_Data.name })
  }

  const [MenuActive, setMenuActive] = useState(null)
  const [IndexLv1, setIndexLv1] = useState(-1)
  const [IndexLv2, setIndexLv2] = useState(-1)
  const [IndexLv3, setIndexLv3] = useState(-1)
  const [IndexLv4, setIndexLv4] = useState(-1)
  const [PermissionTab, setPermissionTab] = useState(null)
  const [PermissionOther, setPermissionOther] = useState(null)

  const [isSelectAll, setIsSelectAll] = useState(false)
  
  const onChangeisSelectAll = () => {
    const new_checked = isSelectAll ? false : true;
    
    const newState = Role_Data.role_company.map((obj, index) => {
      if (obj.company_id === CompanyPermission) {

        const newState_sub = obj.menu_permission.map((obj_lv1, index_lv1) => {    

          const newState_Lv2 = obj_lv1.children ? obj_lv1.children.map((obj_lv2, index_lv2) => {

            const newState_Lv3 = obj_lv2.children ? obj_lv2.children.map((obj_lv3, index_lv3) => {

                const newState_Lv4 = obj_lv3.children ? obj_lv3.children.map((obj_lv4, index_lv4) => {
                  const newpermission_tab = SetPermissionTabAll(obj_lv4,new_checked); 

                  const newpermission_other = SetPermissionOtherAll(obj_lv4,new_checked);

                  return { ...obj_lv4,is_collapse : false, is_read: new_checked , is_edit: new_checked , permission_tab :  newpermission_tab , permission_other : newpermission_other }
                }) : null;

                const newpermission_tab = SetPermissionTabAll(obj_lv3,new_checked); 

                const newpermission_other = SetPermissionOtherAll(obj_lv3,new_checked); 
                
                

                return { ...obj_lv3,is_collapse : false, is_read: new_checked , is_edit: new_checked , children: newState_Lv4 , permission_tab :  newpermission_tab , permission_other : newpermission_other }

            }) : null;

            const newpermission_tab = SetPermissionTabAll(obj_lv2,new_checked); 

            const newpermission_other = SetPermissionOtherAll(obj_lv2,new_checked); 

            return { ...obj_lv2,is_collapse : false, is_read: new_checked , is_edit: new_checked , children: newState_Lv3 , permission_tab :  newpermission_tab , permission_other : newpermission_other }

          }) : null; 

          const newpermission_tab = SetPermissionTabAll(obj_lv1,new_checked); 

          const newpermission_other = SetPermissionOtherAll(obj_lv1,new_checked); 

          return { ...obj_lv1,is_collapse : false, is_read: new_checked , is_edit: new_checked , children: newState_Lv2 , permission_tab :  newpermission_tab , permission_other : newpermission_other }
        })  

        return { ...obj, menu_permission : newState_sub }
      }
      return obj
    })

    setPermissionTab(null);
    setPermissionOther(null);
    setRole_Data({ ...Role_Data, role_company: newState });
    setIsSelectAll(new_checked);
  }  

  const SetPermissionTabAll = (inp_obj,new_checked) => {
    
    if(inp_obj.permission_tab){
      //alert(JSON.stringify(inp_obj))
      const newpermission_tab = inp_obj.permission_tab.map((obj_tab, index_tab) => {
        const newState_other = obj_tab.permission_other ? obj_tab.permission_other.map((obj_other, index_other) => {
          return { ...obj_other, is_read : new_checked , is_edit : new_checked }
        }) : null;
        return { ...obj_tab, is_read : new_checked , permission_other: newState_other }
      })
      //console.log(JSON.stringify(newpermission_tab))
      return newpermission_tab;
    }else{
      return null;
    }
  } 
  
  const SetPermissionOtherAll = (inp_obj,new_checked) => {
    if(inp_obj.permission_other){
      const newpermission_other = inp_obj.permission_other.map((obj_other, index_other) => {
        return { ...obj_other, is_read : new_checked , is_edit : new_checked}
      })
      return newpermission_other;
    }else{
      return null;
    }
  } 



  const handleUpdateData = (type_inp,inp_obj,inp_index_lv1,inp_index_lv2,inp_index_lv3 ,inp_index_lv4,display_lv1,topic) => { 
    
    const newState = Role_Data.role_company.map((obj, index) => {
      if (obj.company_id === CompanyPermission) {
        const newState_sub = obj.menu_permission.map((obj_lv1, index_lv1) => {    
          if (index_lv1 === inp_index_lv1) {
            if(inp_index_lv2 >= 0){
              const newState_Lv2 = obj_lv1.children.map((obj_lv2, index_lv2) => {
                if (index_lv2 === inp_index_lv2) {
                  if(inp_index_lv3 >= 0){
                    const newState_Lv3 = obj_lv2.children.map((obj_lv3, index_lv3) => {
                      if (index_lv3 === inp_index_lv3) {
                        if(inp_index_lv4 >= 0){
                          const newState_Lv4 = obj_lv3.children.map((obj_lv4, index_lv4) => {
                            if (index_lv4 === inp_index_lv4) {
                              if(type_inp == 'is_collapse'){
                                return { ...obj_lv4, is_collapse: inp_obj.is_collapse ? false : true }
                              }
                              if(type_inp == 'is_read'){
                                return { ...obj_lv4, is_read: inp_obj.is_read ? false : true }
                              } 
                              if(type_inp == 'is_edit'){
                                return { ...obj_lv4, is_edit: inp_obj.is_edit ? false : true }
                              }
                              if(type_inp == 'permission_tab'){
                                return { ...obj_lv4, permission_tab: inp_obj }
                              }
                              if(type_inp == 'permission_other'){
                                return { ...obj_lv4, permission_other: inp_obj }
                              }
                            }
                            return obj_lv4
                          })
                          return { ...obj_lv3, children: newState_Lv4 }
                        }else{
                          if(type_inp == 'is_collapse'){
                            return { ...obj_lv3, is_collapse: inp_obj.is_collapse ? false : true }
                          }
                          if(type_inp == 'is_read'){
                            return { ...obj_lv3, is_read: inp_obj.is_read ? false : true }
                          } 
                          if(type_inp == 'is_edit'){
                            return { ...obj_lv3, is_edit: inp_obj.is_edit ? false : true }
                          }
                          if(type_inp == 'permission_tab'){
                            return { ...obj_lv3, permission_tab: inp_obj }
                          }
                          if(type_inp == 'permission_other'){
                            return { ...obj_lv3, permission_other: inp_obj }
                          }
                        }
                      }
                      return obj_lv3
                    })
                    return { ...obj_lv2, children: newState_Lv3 }
                  }else{
                    if(type_inp == 'is_collapse'){
                      return { ...obj_lv2, is_collapse: inp_obj.is_collapse ? false : true }
                    }
                    if(type_inp == 'is_read'){
                      return { ...obj_lv2, is_read: inp_obj.is_read ? false : true }
                    } 
                    if(type_inp == 'is_edit'){
                      return { ...obj_lv2, is_edit: inp_obj.is_edit ? false : true }
                    }
                    if(type_inp == 'permission_tab'){
                      return { ...obj_lv2, permission_tab: inp_obj }
                    }
                    if(type_inp == 'permission_other'){
                      return { ...obj_lv2, permission_other: inp_obj }
                    }
                  }
                }
                return obj_lv2
              })
              
              return { ...obj_lv1, children: newState_Lv2 }
            }else{
              if(type_inp == 'is_collapse'){
                return { ...obj_lv1, is_collapse: inp_obj.is_collapse ? false : true }
              }
              if(type_inp == 'is_read'){
                return { ...obj_lv1, is_read: inp_obj.is_read ? false : true }
              } 
              if(type_inp == 'is_edit'){
                return { ...obj_lv1, is_edit: inp_obj.is_edit ? false : true }
              }
              if(type_inp == 'permission_tab'){
                return { ...obj_lv1, permission_tab: inp_obj }
              }
              if(type_inp == 'permission_other'){
                return { ...obj_lv1, permission_other: inp_obj }
              }             
            }
            
          }
          return obj_lv1
        })  
        return { ...obj, menu_permission : newState_sub }
      }
      return obj
    })
    //console.log(JSON.stringify(newState))
    if(type_inp == 'is_collapse' || type_inp == 'is_read' || type_inp == 'is_edit'){
      setMenuActive(inp_obj.key);
      setIndexLv1(inp_index_lv1);
      setIndexLv2(inp_index_lv2);
      setIndexLv3(inp_index_lv3);
      setIndexLv4(inp_index_lv4);
      
      if(inp_obj?.permission_tab){
        const dataPermissionTab = {
          display_lv1 : display_lv1,
          topic : topic,
          is_read : type_inp == 'is_read' ? inp_obj.is_read ? false : true : inp_obj.is_read,
          permission_tab : inp_obj?.permission_tab
        }
        setPermissionTab(dataPermissionTab);
      }else{
        setPermissionTab(null);
      }
  
      if(inp_obj?.permission_other){
        const dataPermissionOther = {
          display_lv1 : display_lv1,
          topic : topic,
          is_read : type_inp == 'is_read' ? inp_obj.is_read ? false : true : inp_obj.is_read,
          permission_other : inp_obj?.permission_other
        }
        setPermissionOther(dataPermissionOther);
      }else{
        setPermissionOther(null);
      }
    }

    
    //console.log(JSON.stringify({ ...Role_Data, role_company: newState }))
    setRole_Data({ ...Role_Data, role_company: newState })
  
  }

  const handleUpdateDataTabCheckBox = (type_inp,inp_checked,inp_index_tab) => { 
    const new_checked = inp_checked ? false : true;
    const newState = PermissionTab.permission_tab.map((obj_tab, index_tab) => {
      if (index_tab === inp_index_tab) {
        const newState_other = obj_tab.permission_other.map((obj_other, index_other) => {
            if(type_inp == 'is_check_all'){
              return { ...obj_other, is_read: new_checked , is_edit: new_checked }
            }
            if(type_inp == 'is_read_all'){
              return { ...obj_other, is_read: new_checked }
            }
        })
        return { ...obj_tab, [type_inp] : new_checked , permission_other: newState_other }
      }
      return obj_tab
    })

    setPermissionTab({ ...PermissionTab, permission_tab: newState })
    handleUpdateData('permission_tab',newState,IndexLv1,IndexLv2,IndexLv3,IndexLv4,'-','-')
  } 

  const handleUpdateDataTab = (type_inp,inp_obj,inp_index_tab,inp_index_other) => { 
    const newState = PermissionTab.permission_tab.map((obj_tab, index_tab) => {
      if (index_tab === inp_index_tab) {
        if(inp_index_other >= 0){
          const newState_other = obj_tab.permission_other.map((obj_other, index_other) => {
            if (index_other === inp_index_other) {
              if(type_inp == 'is_collapse'){
                return { ...obj_other, is_collapse: inp_obj.is_collapse ? false : true }
              }
              if(type_inp == 'is_read'){
                return { ...obj_other, is_read: inp_obj.is_read ? false : true }
              }
              if(type_inp == 'is_edit'){
                return { ...obj_other, is_edit: inp_obj.is_edit ? false : true }
              }
            }
            return obj_other
          })
          return { ...obj_tab, permission_other: newState_other }
        }else{
          if(type_inp == 'is_collapse'){
            return { ...obj_tab, is_collapse: inp_obj.is_collapse ? false : true }
          } 
          if(type_inp == 'is_read'){
            return { ...obj_tab, is_read: inp_obj.is_read ? false : true }
          } 
        }
      }
      return obj_tab
    })
    //console.log(JSON.stringify(newState))
    setPermissionTab({ ...PermissionTab, permission_tab: newState })
    handleUpdateData('permission_tab',newState,IndexLv1,IndexLv2,IndexLv3,IndexLv4,'-','-')
    
  }


  const handleUpdateDataOtherCheckBox = (type_inp,inp_checked) => { 
    const new_checked = inp_checked ? false : true;
    const newState = PermissionOther.permission_other.map((obj_other, index_other) => {
      if(type_inp == 'is_check_all'){
        return { ...obj_other, is_read: new_checked , is_edit: new_checked }
      }
      if(type_inp == 'is_read_all'){
        return { ...obj_other, is_read: new_checked }
      }
    })

    setPermissionOther({...PermissionOther , [type_inp] : new_checked, permission_other: newState });
    handleUpdateData('permission_other',newState,IndexLv1,IndexLv2,IndexLv3,IndexLv4,'-','-')
  }  

  const handleUpdateDataOther = (type_inp,inp_obj,inp_index_other) => { 
    const newState = PermissionOther.permission_other.map((obj_other, index_other) => {
      if (index_other === inp_index_other) {
        if(type_inp == 'is_collapse'){
          return { ...obj_other, is_collapse: inp_obj.is_collapse ? false : true }
        }
        if(type_inp == 'is_read'){
          return { ...obj_other, is_read: inp_obj.is_read ? false : true }
        }
        if(type_inp == 'is_edit'){
          return { ...obj_other, is_edit: inp_obj.is_edit ? false : true }
        }
      }
      return obj_other
    })
    setPermissionOther({ ...PermissionOther, permission_other: newState })
    handleUpdateData('permission_other',newState,IndexLv1,IndexLv2,IndexLv3,IndexLv4,'-','-')
  }

  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">{t('access_role')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <hr />
      </div>
      
      <div className="dialog-content">
        <div className="content">
            <div className="topic_fill">{t('role_name')} *</div>
            <div>
                <TextField
                disabled={alldisabled}
                name="Fullname"
                className="TextField_employee"
                placeholder={t('enter_role_name')}
                value={Role_Data.name}
                onChange={(e) => setRole_Data({ ...Role_Data, name: e.target.value })}
                />
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">{t('description')} *</div>
            <div>
                <InputTextArea
                disabled={alldisabled}
                className="InputTextArea_css"
                initValue={Role_Data.description}
                onChange={(e) => setRole_Data({ ...Role_Data, description: e.target.value })}
                placeHolder={t('enter_a_description')}
                borderColor={'var(--Gray-300)'}
                borderWidth="1px"
                />
            </div>
        </div>

        <div className="content">
            <div className="topic_fill">{t('default_active_company')}</div>
            <div className="sub_topic_fill">{t('if_there_are_multiple_companies_select_which_company_will_be_default_role_when_log_in')}</div>
            <div>
                <Dropdown
                disabled={alldisabled}
                id={`MeetingType`}
                className="dd_employee"
                value={CompanyDefault}
                optionList={Company_option}
                onChange={onDefaultCompany}
                placeHolder={t('select')}               
                />
            </div>
        </div>

        <div className="content">
            <div className="topic_fill">{t('permission_setting')}</div>
            <div className="sub_topic_fill">{t('permission_setting_desc')}</div>
            <div>
                <Dropdown
                id={`MeetingType`}
                className="dd_employee"
                value={CompanyPermission}
                optionList={Company_option}
                onChange={onCompanyPermission}
                placeHolder={t('select')}                
                />
            </div>
        </div>  
        <div className="css_magin_top_16"></div>

        <div className="css_magin_top_16"></div>
        <div className="content">
          <div className="group_content">
            <DivContentLeft>
              <div>
                <div className="header_menu">
                  <div className="box_menu_name">
                    <div className="div_collapse"></div>
                    <div>เมนู</div>
                  </div>
                  <div>เปิดการเข้าถึง</div>
                </div>
                {Role_Data.role_company.filter((it) => it.company_id === CompanyPermission).length > 0 && (
                  <div>
                    {Role_Data.role_company.filter((it) => it.company_id === CompanyPermission)[0].menu_permission.map((v_lv1, index_lv1) => (
                      <div>
                        <div className={`group_menu_list ${MenuActive == v_lv1.key ? 'menu_active' : ''}`}>
                          <div className="box_menu_name" onClick={() => handleUpdateData("is_collapse",v_lv1,index_lv1,-1,-1,-1,initLang == 'th' ? v_lv1.name_th : v_lv1.name_en,initLang == 'th' ? v_lv1.name_th : v_lv1.name_en)}>
                            <div className="div_collapse">
                              {v_lv1.children && (
                                <img src={v_lv1.is_collapse ? chevronDown : chevronRight} className="add_pointer" title="collapse"  />
                              )}
                            </div>
                            <div className="text_menu_name">{initLang == 'th' ? v_lv1.name_th : v_lv1.name_en }</div>
                          </div>
                          <div className="div_collapse">
                            {
                              /*
                              <OutlineCheckbox
                                name={`${CompanyPermission}-${v_lv1.menu_setting}`}
                                key={`${CompanyPermission}-${v_lv1.menu_setting}`}
                                checked={v_lv1.is_read}
                              />
                              */
                            }
                            <SwitchButton
                              value={true}
                              defaultChecked={v_lv1.is_read}
                              handleChange={(event) => handleUpdateData("is_read",v_lv1,index_lv1,-1,-1,-1,initLang == 'th' ? v_lv1.name_th : v_lv1.name_en,initLang == 'th' ? v_lv1.name_th : v_lv1.name_en)}
                              disabled={alldisabled}
                            />
                          </div>
                        </div>
                        {v_lv1.children?.map((v_lv2, index_lv2) => (
                          <div className={"collapse" + (v_lv1.is_collapse  ? ' in' : '')}>
                            <div className={`group_menu_list ${MenuActive == v_lv2.key ? 'menu_active' : ''}`}>
                              <div className="box_menu_name_lv2" onClick={() => handleUpdateData("is_collapse",v_lv2,index_lv1,index_lv2,-1,-1,initLang == 'th' ? v_lv1.name_th : v_lv1.name_en,initLang == 'th' ? v_lv2.name_th : v_lv2.name_en)}>
                                <div className="div_collapse">
                                  {v_lv2.children && (
                                    <img src={v_lv2.is_collapse ? chevronDown : chevronRight} className="add_pointer" title="collapse"  />
                                  )}
                                </div>
                                <div className="text_menu_name">{initLang == 'th' ? v_lv2.name_th : v_lv2.name_en }</div>
                              </div>
                              <div className="div_collapse">
                                <SwitchButton
                                  value={true}
                                  defaultChecked={v_lv2.is_read}
                                  handleChange={(event) => handleUpdateData("is_read",v_lv2,index_lv1,index_lv2,-1,-1,initLang == 'th' ? v_lv1.name_th : v_lv1.name_en,initLang == 'th' ? v_lv2.name_th : v_lv2.name_en)}
                                  disabled={alldisabled}
                                />
                              </div>
                            </div>
                            {v_lv2.children?.map((v_lv3, index_lv3) => (
                              <div className={"collapse" + (v_lv2.is_collapse  ? ' in' : '')}>
                                <div className={`group_menu_list ${MenuActive == v_lv3.key ? 'menu_active' : ''}`}>
                                  <div className="box_menu_name_lv3" onClick={() => handleUpdateData("is_collapse",v_lv3,index_lv1,index_lv2,index_lv3,-1,initLang == 'th' ? v_lv1.name_th : v_lv1.name_en,initLang == 'th' ? v_lv3.name_th : v_lv3.name_en)} >
                                    <div className="div_collapse">
                                      {v_lv3.children && (
                                        <img src={v_lv3.is_collapse ? chevronDown : chevronRight} className="add_pointer" title="collapse"  />
                                      )}
                                    </div>
                                    <div className="text_menu_name">{initLang == 'th' ? v_lv3.name_th : v_lv3.name_en }</div>
                                  </div>
                                  <div className="div_collapse">
                                    <SwitchButton
                                      value={true}
                                      defaultChecked={v_lv3.is_read}
                                      handleChange={(event) => handleUpdateData("is_read",v_lv3,index_lv1,index_lv2,index_lv3,-1,initLang == 'th' ? v_lv1.name_th : v_lv1.name_en,initLang == 'th' ? v_lv3.name_th : v_lv3.name_en)}
                                      disabled={alldisabled}
                                    />
                                  </div>
                                </div>
                                {v_lv3.children?.map((v_lv4, index_lv4) => (
                                  <div className={"collapse" + (v_lv3.is_collapse ? ' in' : '')}>
                                    <div className={`group_menu_list ${MenuActive == v_lv4.key ? 'menu_active' : ''}`}>
                                      <div className="box_menu_name_lv4" onClick={() => handleUpdateData("is_collapse",v_lv4,index_lv1,index_lv2,index_lv3,index_lv4,initLang == 'th' ? v_lv1.name_th : v_lv1.name_en,initLang == 'th' ? v_lv4.name_th : v_lv4.name_en)}>
                                        <div className="div_collapse">
                                          {v_lv4.children && (
                                            <img src={v_lv4.is_collapse ? chevronDown : chevronRight} className="add_pointer" title="collapse"  />
                                          )}
                                        </div>
                                        <div className="text_menu_name">{initLang == 'th' ? v_lv4.name_th : v_lv4.name_en }</div>
                                      </div>
                                      <div className="div_collapse">
                                        <SwitchButton
                                          value={true}
                                          defaultChecked={v_lv4.is_read}
                                          handleChange={(event) => handleUpdateData("is_read",v_lv4,index_lv1,index_lv2,index_lv3,index_lv4,initLang == 'th' ? v_lv1.name_th : v_lv1.name_en,initLang == 'th' ? v_lv4.name_th : v_lv4.name_en)}
                                          disabled={alldisabled}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </DivContentLeft>
            <DivContentRight>
              <div>
                { (!PermissionTab && !PermissionOther) && (
                  <div>
                    { (!MenuActive) && (
                      <div className="text_click_menu">
                        เลือกเมนูซ้ายมือ เพื่อกำหนดสิทธิ์การใช้งาน
                      </div>
                    )}
                    { (MenuActive) && (
                      <div className="text_click_menu">
                        เมนูที่เลือก ไม่พบข้อมูลกำหนดสิทธิ์การใช้งาน
                      </div>
                    )}
                  </div>


                )}
                { PermissionTab && (
                  <div>
                    <DivContentTab>
                      <div>
                        {PermissionTab?.display_lv1}
                      </div>
                      <div className="group_header">
                        <div className="text_topic">
                          {PermissionTab?.topic}
                        </div>
                        <div className="box_all_permission">
                          <div>
                            เปิดการเข้าถึงหน้านี้
                          </div>
                          <div className="checkbox_permission_other">
                            <SwitchButton
                              value={true}
                              defaultChecked={PermissionTab.is_read}
                              handleChange={(event) => handleUpdateData("is_read",PermissionTab,IndexLv1,IndexLv2,IndexLv3,IndexLv4,PermissionTab?.display_lv1,PermissionTab?.topic)}
                              disabled={alldisabled}
                            />
                          </div>                          
                        </div>
                        
                      </div>
                      <div className="css_magin_top_12"></div>
                      <div className="border_header"></div>
                      <div className="css_magin_top_12"></div>
                      <div>หน้านี้มีการแบ่งข้อมูลเป็นแท็บตามรายการด้านล่างนี้ โปรดเปิดการเข้าถึงแท็บ</div>
                      <div className="css_magin_top_12"></div>
                      {PermissionTab?.permission_tab.map((v_tab, index_tab) => (
                        <div className="box_tab">
                          <div className="group_tab_list">
                            <div className="box_menu_name" onClick={() => handleUpdateDataTab("is_collapse",v_tab,index_tab,-1)}>
                              <div className="div_collapse">
                                {v_tab.permission_other && (
                                  <img src={v_tab.is_collapse ? chevronDown : chevronRight} className="add_pointer" title="collapse"  />
                                )}
                              </div>
                              <div className="text_menu_name">{initLang == 'th' ? v_tab.name_th : v_tab.name_en }</div>
                            </div>
                            <div className="div_collapse">
                              <div className="box_all_permission">
                                <div>
                                  เปิดการเข้าถึงแท็บนี้
                                </div>
                                <div className="checkbox_permission_other">
                                  {
                                    /*
                                                                        <OutlineCheckbox
                                      name={`${CompanyPermission}-${v_tab.menu_setting}`}
                                      key={`${CompanyPermission}-${v_tab.menu_setting}`}
                                      checked={v_tab.is_read}
                                  />
                                    */
                                  }

                                  <SwitchButton
                                    value={true}
                                    defaultChecked={v_tab.is_read}
                                    handleChange={(event) => handleUpdateDataTab("is_read",v_tab,index_tab,-1)}
                                    disabled={alldisabled}
                                  />
                                </div>                          
                              </div>
                            </div>
                          </div>
                          <div className={"collapse" + (v_tab.is_collapse ? ' in' : '')}>
                            <div className="css_magin_top_12"></div>   
                            <div className="border_header"></div>
                            <div className="css_magin_top_12"></div> 
                            <DivContentOther>
                              <div>กำหนดสิทธิ์การจัดการข้อมูล และองค์ประกอบบนหน้าจอ</div>
                              <div className="css_magin_top_12"></div>
                              <div className="div_table">
                                <div className="resp-table">
                                  <div className="resp-table-body">
                                    <div className="resp-table-row">
                                      <div className="table-body-head_cell width_menu border_top_left_radius">สิทธิ์ในการจัดการข้อมูล</div>
                                      <div className="table-body-head_cell width_view">เห็น</div>
                                      <div className="table-body-head_cell width_edit border_top_right_radius">คลิก</div>
                                    </div>
                                  </div>
                                </div>
                                {v_tab.permission_other?.map((v_other, index_other) => (
                                  <div className="resp-table">
                                    <div className="resp-table-body">
                                        <div className={`table-body-cell width_menu ${index_other == v_tab.permission_other.length - 1 ? 'border_bottom_left_radius' : ''}`}>
                                          {initLang == 'th' ? v_other.name_th : v_other.name_en}
                                        </div>
                                        <div className="table-body-cell width_view">
                                          <OutlineCheckbox
                                              name={`permissionother-read-${v_other.menu_setting}`}
                                              key={`permissionother-read-${v_other.menu_setting}`}
                                              checked={v_other.is_read}
                                              onChange={(e) => handleUpdateDataTab("is_read",v_other,index_tab,index_other)}
                                              disabled={alldisabled}
                                          />
                                          
                                        </div>
                                        <div className={`table-body-cell width_edit ${index_other == v_tab.permission_other.length - 1 ? 'border_bottom_right_radius' : ''}`}>
                                          <OutlineCheckbox
                                              name={`permissionother-edit-${v_other.menu_setting}`}
                                              key={`permissionother-edit-${v_other.menu_setting}`}
                                              checked={v_other.is_edit}
                                              onChange={(e) => handleUpdateDataTab("is_edit",v_other,index_tab,index_other)}
                                              disabled={alldisabled}
                                          />
                                        </div>
                                    </div>
                                  </div>                                  
                                ))}
                              </div>
                            </DivContentOther>
                          </div>
                        </div>

                      ))} 
                    </DivContentTab>
                  </div>
                )}
                { PermissionOther && (
                  <div>
                    <DivContentOther>
                      <div>
                        {PermissionOther?.display_lv1}
                      </div>
                      <div className="group_header">
                        <div className="text_topic">
                          {PermissionOther?.topic}
                        </div>
                        <div className="box_all_permission">
                          <div>
                            เปิดการเข้าถึงหน้านี้
                          </div>
                          <div className="checkbox_permission_other">
                            <SwitchButton
                              value={true}
                              defaultChecked={PermissionOther.is_read}
                              handleChange={(event) => handleUpdateData("is_read",PermissionOther,IndexLv1,IndexLv2,IndexLv3,IndexLv4,PermissionOther?.display_lv1,PermissionOther?.topic)}
                              disabled={alldisabled}
                            />
                          </div>                          
                        </div>
                        
                      </div>
                      <div className="css_magin_top_12"></div>
                      <div className="border_header"></div>
                      <div className="css_magin_top_12"></div>
                      <div>ให้สิทธิ์การจัดการข้อมูล โดยตั้งค่าการใช้งานองค์ประกอบบนหน้าจอ</div>
                      <div className="css_magin_top_12"></div>
                      <div className="div_table">
                        <div className="resp-table">
                          <div className="resp-table-body">
                            <div className="resp-table-row">
                              <div className="table-body-head_cell width_menu border_top_left_radius">สิทธิ์ในการจัดการข้อมูล</div>
                              <div className="table-body-head_cell width_view">เห็น</div>
                              <div className="table-body-head_cell width_edit border_top_right_radius">คลิก</div>
                            </div>
                          </div>
                        </div>
                        {PermissionOther?.permission_other?.map((v, index) => (
                          <div className="resp-table">
                            <div className="resp-table-body">
                                <div className={`table-body-cell width_menu ${index == PermissionOther?.permission_other.length - 1 ? 'border_bottom_left_radius' : ''}`}>
                                  {initLang == 'th' ? v.name_th : v.name_en}
                                </div>
                                <div className="table-body-cell width_view">
                                  <OutlineCheckbox
                                      name={`permissionother-read-${v.menu_setting}`}
                                      key={`permissionother-read-${v.menu_setting}`}
                                      checked={v.is_read}
                                      onChange={(e) => handleUpdateDataOther("is_read",v,index)}
                                      disabled={alldisabled}
                                  />
                                </div>
                                <div className={`table-body-cell width_edit ${index == PermissionOther?.permission_other.length - 1 ? 'border_bottom_right_radius' : ''}`}>
                                  <OutlineCheckbox
                                      name={`permissionother-edit-${v.menu_setting}`}
                                      key={`permissionother-edit-${v.menu_setting}`}
                                      checked={v.is_edit}
                                      onChange={(e) => handleUpdateDataOther("is_edit",v,index)}
                                      disabled={alldisabled}
                                  />
                                </div>
                            </div>
                          </div>
                         ))} 
                      </div>
                    </DivContentOther>
                  </div>
                )}
              </div>
            </DivContentRight>
          </div>
        </div>     

      </div>
      
      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={onClose}>
          {t('cancel')}
        </ButtonOutline>
      </div>

        <DialogConfirm
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onSubmit={CreateNewDailySummary}
          icon={question}
          title={t('confirm_update_role_headline')}
          description=""
          textYes={t('confirm')}
          textNo={t('back')}
        />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />
    </Div>
  )
}

export default ViewRole
