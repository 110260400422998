import styled from 'styled-components'
export default function ImageButton({ size = 60, src, icon, onClick, iconSize = 20, className }) {
  return (
    <Styled onClick={onClick} iconSize={iconSize} size={size} className={className}>
      {/* {src  === 'string' ? <img src={src}  /> : <>{src}</>} */}
      {src && <img src={src} />}
      {icon && <div>{icon}</div>}
    </Styled>
  )
}
const Styled = styled.div`
  width: ${({ size }) => size + 'px'};
  cursor: pointer;
  user-select: none;
  display: inline-flex;
  height: ${({ size }) => size + 'px'};
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  :active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  img {
    width: ${({ iconSize }) => iconSize + 'px'};
  }
`
