import { combineReducers } from 'redux'

import {
  CREATE_BUSINESS_DOCUMENT,
  EDIT_BUSINESS_DOCUMENT,
  CREATE_CUSTOMER,
  CREATE_PURCHASE,
  CREATE_QUOTATION,
  CREATE_SALE_DOCUMENT,
  EDIT_PURCHASE_DOC,
  EDIT_QUOTATION_DOC,
  EDIT_SALE_DOCUMENT,
  GET_BUSINESS_DOCUMENT_BY_ID,
  GET_COMPANY_INFO_BY_ID,
  GET_CUSTOMER_LIST,
  GET_DOCUMENT_LIST,
  GET_DOCUMENT_SETTING_BY_COMPANY_ID,
  GET_PURCHASE_BY_ID,
  GET_PURCHASE_LIST,
  GET_QUOTATION_BY_ID,
  GET_SALE_DOCUMENT_BY_ID,
  GET_SELLER_LIST,
  REQUEST_DOCUMENT_APPROVE,
  SEND_EMAIL_DOCUMENT,
  SHARE_LINK_DOCUMENT,
  UPDATE_DOCUMENT_SETTING_BY_COMPANY_ID,
  CREATE_SELLER,
} from '../actionTypes'

const initialState = {
  documentList: [],
  quotationList: [],
  customerList: [],
  quotationData: {},
  customerInfo: {},
  sendEmail: {},
  editQuotationDoc: {},
  sellerList: [],
  sellerInfo: {},
  //saleDoc
  saleDocumentData: {},
  editSaleDoc: {},
  documentUrl: {},

  // purchase
  purchaseList: [],
  purchaseData: {},
  editPurchaseDoc: {},
  companyData: {},

  //business document
  businessDocumentData: {},

  documentSettingInfo: {},
  updateDocSettingByCompanyId: {},

  page: 1,
  pageSize: 10,
  pageCount: 0,
  total: 0,
}

const documentReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_DOCUMENT_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DOCUMENT_LIST.SUCCESS: {
      const { data, meta } = action

      return {
        ...state,
        // quotationList: data,
        documentList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_DOCUMENT_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_CUSTOMER_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_CUSTOMER_LIST.SUCCESS: {
      const { data } = action

      return {
        ...state,
        customerList: data,
        isLoading: false,
      }
    }
    case GET_CUSTOMER_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_QUOTATION_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_QUOTATION_BY_ID.SUCCESS: {
      const { data } = action
      //
      return {
        ...state,
        quotationData: data,
        isLoading: false,
      }
    }
    case GET_QUOTATION_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case REQUEST_DOCUMENT_APPROVE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case REQUEST_DOCUMENT_APPROVE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case REQUEST_DOCUMENT_APPROVE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_QUOTATION.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_QUOTATION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case CREATE_QUOTATION.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_CUSTOMER.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_CUSTOMER.SUCCESS: {
      const { data } = action
      return {
        ...state,
        customerInfo: data,
        isLoading: false,
      }
    }
    case CREATE_CUSTOMER.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case EDIT_QUOTATION_DOC.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case EDIT_QUOTATION_DOC.SUCCESS: {
      const { data } = action
      return {
        ...state,
        editQuotationDoc: data,
        isLoading: false,
      }
    }
    case EDIT_QUOTATION_DOC.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case GET_PURCHASE_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PURCHASE_LIST.SUCCESS: {
      const { data, meta } = action

      return {
        ...state,
        purchaseList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_PURCHASE_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_PURCHASE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_PURCHASE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case CREATE_PURCHASE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PURCHASE_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PURCHASE_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        purchaseData: data,
        isLoading: false,
      }
    }
    case GET_PURCHASE_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case EDIT_PURCHASE_DOC.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case EDIT_PURCHASE_DOC.SUCCESS: {
      const { data } = action
      return {
        ...state,
        editPurchaseDoc: data,
        isLoading: false,
      }
    }
    case EDIT_PURCHASE_DOC.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case GET_COMPANY_INFO_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_COMPANY_INFO_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        companyData: data,
        isLoading: false,
      }
    }
    case GET_COMPANY_INFO_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_SELLER_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_SELLER_LIST.SUCCESS: {
      const { data } = action

      return {
        ...state,
        sellerList: data,
        isLoading: false,
      }
    }
    case GET_SELLER_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_SELLER.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_SELLER.SUCCESS: {
      const { data } = action
      return {
        ...state,
        sellerInfo: data,
        isLoading: false,
      }
    }
    case CREATE_SELLER.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case GET_DOCUMENT_SETTING_BY_COMPANY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DOCUMENT_SETTING_BY_COMPANY_ID.SUCCESS: {
      const { data } = action

      return {
        ...state,
        documentSettingInfo: data,
        isLoading: false,
      }
    }
    case GET_DOCUMENT_SETTING_BY_COMPANY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case UPDATE_DOCUMENT_SETTING_BY_COMPANY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_DOCUMENT_SETTING_BY_COMPANY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        updateDocSettingByCompanyId: data,
        isLoading: false,
      }
    }
    case UPDATE_DOCUMENT_SETTING_BY_COMPANY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_SALE_DOCUMENT_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_SALE_DOCUMENT_BY_ID.SUCCESS: {
      const { data } = action
      //

      return {
        ...state,
        saleDocumentData: data,
        isLoading: false,
      }
    }
    case GET_SALE_DOCUMENT_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case EDIT_SALE_DOCUMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case EDIT_SALE_DOCUMENT.SUCCESS: {
      const { data } = action
      return {
        ...state,
        editSaleDoc: data,
        isLoading: false,
      }
    }
    case EDIT_SALE_DOCUMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case CREATE_SALE_DOCUMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_SALE_DOCUMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case CREATE_SALE_DOCUMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case SHARE_LINK_DOCUMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case SHARE_LINK_DOCUMENT.SUCCESS: {
      const { data } = action
      return {
        ...state,
        documentUrl: data,
        isLoading: false,
      }
    }
    case SHARE_LINK_DOCUMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case SEND_EMAIL_DOCUMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case SEND_EMAIL_DOCUMENT.SUCCESS: {
      const { data } = action
      return {
        ...state,
        sendEmail: data,
        isLoading: false,
      }
    }
    case SEND_EMAIL_DOCUMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case CREATE_BUSINESS_DOCUMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_BUSINESS_DOCUMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case CREATE_BUSINESS_DOCUMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
      case EDIT_BUSINESS_DOCUMENT.REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case EDIT_BUSINESS_DOCUMENT.SUCCESS: {
        return {
          ...state,
          isLoading: false,
        }
      }
      case EDIT_BUSINESS_DOCUMENT.FAILURE:
        return {
          ...state,
          isLoading: false,
        }  
    case GET_BUSINESS_DOCUMENT_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_BUSINESS_DOCUMENT_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        businessDocumentData: data,
        isLoading: false,
      }
    }
    case GET_BUSINESS_DOCUMENT_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  documentReducer,
})
