import { Stack } from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PiClockLight } from 'react-icons/pi'
import { RiExternalLinkLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Button,
  Chip,
  Footer,
  Form,
  InfoContainer,
  TabPanelContainer,
} from 'components/feature/operation-process/default-cards'
import AssetTab from 'components/feature/operation-process/default-cards/asset-tab'
import DeliveryTab from 'components/feature/operation-process/default-cards/delivery-tab'
import GuidelineTab from 'components/feature/operation-process/default-cards/guide-line-tab'
import LogsTab from 'components/feature/operation-process/default-cards/logs-tab'
import NoteTab from 'components/feature/operation-process/default-cards/note-tab'
import Tabs from 'components/feature/operation-process/default-cards/tabs'
import TaskTab from 'components/feature/operation-process/default-cards/task-tab'
import DueDate from 'components/feature/operation-process/sw-timeline/due-date'
import { Textarea } from 'components/form2/form-textarea'
import { APPROVER, REVIEWER, SW } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { formatDate } from 'utils/dateHelpers'

const Approval = ({ approverId, openTask, onSubmit, onViewClick }) => {
  const { t } = useTranslation()
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))
  const employeeId = employee_info?.id || null

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)
  const footerRef = useRef(null)
  const [footerHeight, setFooterHeight] = useState(0)

  const { sp: spActions, sw: swActions, approval: approvalActions, extra: extraActions } = opActions()
  const { getSP } = spActions()
  const { getSW } = swActions()
  const { getApproval, postApproval } = approvalActions()
  const { clearNotes } = extraActions()
  const { approver: approverData } = opProcSelector
  const { sw_logs, sw_logs_pagination } = opProcSelector

  const { sw } = approverData || {}
  const { sp } = sw || {}
  const { reviewers } = sw || {}
  const { approver } = sw || {}

  const isEmployeeApprover = approver?.employee?.id === employeeId || false
  const isCanApprove = isEmployeeApprover && approverData?.status === APPROVER.STATUS.WaitingForApprove

  const { register, watch } = useForm()

  const [tab, setTab] = useState('guideline')

  const fetchApproverByID = async () => {
    dispatch(getApproval().byId(approverId))
  }

  const fetchSPByID = async () => {
    dispatch(getSP().byId(approverData?.sw?.sp.id))
  }

  const fetchSWLogs = async (id, payload) => {
    dispatch(getSW().logs(id, payload))
  }

  const reload = () => {
    fetchApproverByID()
    fetchSPByID()
  }

  const handleTabChange = (value) => {
    setTab(value)

    switch (value) {
      case 'guideline':
        break
      case 'task':
        break
      case 'delivery':
        break
      case 'logs':
        fetchSWLogs(sw?.id)
        break
      case 'note':
        break
      case 'asset':
        break
      default:
        break
    }
  }

  const handleApproveStatus = (isPass = false) => {
    const { approve, reject } = postApproval()
    const action = isPass ? approve : reject

    dispatch(action(approverId, watch('note'))).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        reload()
        onSubmit && onSubmit()
        return
      }
    })
  }

  const handleViewClick = (e) => {
    e.preventDefault()

    const to = e.target.getAttribute('to')
    const pathname = location.pathname

    if (pathname === to) {
      return onViewClick && onViewClick()
    }

    navigate(to)

    return
  }

  useEffect(() => {
    if (!approverId) return

    fetchApproverByID(approverId)
    dispatch(clearNotes())
  }, [approverId])

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.offsetHeight)
    }
  }, [footerRef.current?.offsetHeight])

  return (
    <>
      <InfoContainer id="info-container">
        <section>
          <label>{`${t('sop')}:`}</label>

          <Stack direction="row" gap="0.5rem">
            <p>{sw?.sp?.name}</p>
            <a
              to={`/operation-process/department-operation/${sw?.sp?.id}`}
              onClick={handleViewClick}
              style={{ cursor: 'pointer' }}
            >
              {t('view')} <RiExternalLinkLine fontSize={14} />
            </a>
          </Stack>
        </section>

        <section>
          <label>{t('assign_to')}:</label>
          <p>
            {sw?.employee?.first_name} {sw?.employee?.last_name}
          </p>
        </section>

        <section>
          <label>{t('due_date')}:</label>
          <p>{formatDate(sw?.due_date)}</p>
        </section>

        <section style={{ alignItems: 'baseline' }}>
          <label>{t('status')}:</label>
          <Chip label={sw?.status} color={SW.STATUS_CHIP[sw?.status]} />
        </section>

        <Tabs tabCounts={{ task: sw?.tasks?.length }} onChange={handleTabChange} defaultTab="delivery" />

        <TabPanelContainer>
          {tab === 'guideline' && <GuidelineTab content={sw?.description} />}
          {tab === 'task' && <TaskTab items={sw?.tasks || []} openTask={openTask} />}
          {tab === 'delivery' && <DeliveryTab allowUpload={false} deliveries={sw?.deliveries} hideActions />}
          {tab === 'logs' && (
            <LogsTab
              items={sw_logs}
              onActions={{ onClickTask: (taskId) => (event) => openTask(taskId) }}
              notFoundDesc="There are no activities found for this SW."
            />
          )}
          {tab === 'note' && <NoteTab spId={sw?.sp?.id} />}
          {tab === 'asset' && sw?.id && <AssetTab id={sw.id} allowUpload={false} />}
        </TabPanelContainer>
      </InfoContainer>

      {/* Implement reviewer and approver */}
      <DCContainer spacing={2} $bottom={footerHeight > 0 ? `calc(${footerHeight}px)` : `0`}>
        <DueDate dueDate={approverData?.due_date} />

        <Stack direction="row" spacing={2} height="100%" width="100%">
          <ReviewSection>
            <h3>{t('reviewers')}</h3>

            {reviewers?.map((rw, index) => {
              const isShowTimestamp = rw?.status === REVIEWER.STATUS.Passed || rw?.status === REVIEWER.STATUS.NotPass

              return (
                <Stack key={index} width="100%">
                  <Stack spacing={1} direction="row" width="100%" justifyContent="space-between" alignItems="baseline">
                    <p>
                      {index + 1}. {rw?.employee?.first_name} {rw?.employee?.last_name}
                    </p>
                    <Chip label={rw?.status} color={REVIEWER.STATUS_CHIP[rw?.status]} />
                  </Stack>

                  {isShowTimestamp && (
                    <Stack direction="row" width="100%" alignItems="center">
                      <PiClockLight size={16} style={{ marginRight: '0.25rem' }} />
                      <p className="text-timestamp">{formatDate(rw?.updatedAt)}</p>
                    </Stack>
                  )}
                </Stack>
              )
            })}
          </ReviewSection>

          {approver !== null && (
            <ApprovalSection>
              <h3>{t('approver')}</h3>

              <Stack width="100%">
                <Stack spacing={1} direction="row" width="100%" justifyContent="space-between" alignItems="baseline">
                  <p>
                    {approver?.employee?.first_name && approver?.employee?.last_name
                      ? approver?.employee?.first_name + ' ' + approver?.employee?.last_name
                      : '-'}
                  </p>

                  {approver?.status && (
                    <Chip label={approverData.status} color={APPROVER.STATUS_CHIP[approverData.status]} />
                  )}
                </Stack>

                {(approver?.status === APPROVER.STATUS.Approved || approver?.status === APPROVER.STATUS.Rejected) && (
                  <Stack direction="row" width="100%" alignItems="center">
                    <PiClockLight size={16} style={{ marginRight: '0.25rem' }} />
                    <p className="text-timestamp">{formatDate(approver?.updatedAt)}</p>
                  </Stack>
                )}
              </Stack>
            </ApprovalSection>
          )}
        </Stack>

        {isCanApprove && (
          <section
            style={{
              alignItems: 'baseline',
            }}
          >
            <label>{t('note')}:</label>
            <Form
              style={{
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Textarea
                {...register('note')}
                minRows={3}
                maxRows={5}
                placeholder="Type something..."
                multiline
                sx={{
                  width: '100%',
                }}
              />
            </Form>
          </section>
        )}
      </DCContainer>

      {isCanApprove && (
        <Footer hideBorderTop ref={footerRef}>
          <Button
            className="gray"
            variant="outlined"
            onClick={() => handleApproveStatus(false)}
            disabled={employeeId !== approverData?.employee?.id}
          >
            {t('reject')}
          </Button>

          <Button
            variant="contained"
            onClick={() => handleApproveStatus(true)}
            disabled={employeeId !== approverData?.employee?.id}
          >
            {t('approve')}
          </Button>
        </Footer>
      )}
    </>
  )
}

export default Approval

const DCContainer = styled(Stack)`
  width: 100%;
  border: 1px solid var(--Gray-200);
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-bottom: none;
  height: fit-content;
  padding: 1rem;
  position: sticky;
  bottom: ${(props) => (props.$bottom ? props.$bottom : '0')};
  left: 0;
  right: 0;
  z-index: 100;

  background: var(--Base-White);

  & p {
    margin: 0;

    color: var(--Gray-700);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;

    &.text-timestamp {
      color: var(--Gray-500);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  & h3 {
    margin: 0;

    color: var(--Gray-900);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  & section {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;

    & label {
      color: var(--Gray-700);

      /* Text sm/Medium */
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
    }
  }
`

const ReviewSection = styled(Stack)`
  padding: 0.75rem;
  width: 100%;
  height: auto;
  border: 1px solid var(--Gray-200);
  border-radius: 8px;
  gap: 1rem;
`

const ApprovalSection = ReviewSection
