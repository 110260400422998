import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { getMeetingById, getMeetingAttendeeReturnData, updateMeeting } from '../../redux/actions/dailysummary'
import { selectDailySummary } from '../../redux/selectors'
import { DateDisplay, TimeDisplayHHmm, DateTimeHHmmDisplay, DateTimeHHmmssDisplay } from '../../utils/common'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TableV2 from '../../components/common/TableV2'

const DivMain = styled.div`
  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  .css_summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
  }
  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;

      padding: 0px 14px;
      padding-top: 16px;
      border-radius: 8px 8px 0 0;
      background: #ffffff;

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .css_summary_magin_top {
    margin-top: 10px;
  }
  .table-wrapper-summary {
    margin-top: 24px;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;

      padding: 0px 14px;
      padding-top: 16px;
      border-radius: 8px 8px 0 0;
      background: #ffffff;

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .ql-container {
    min-height: 250px;
    font-family: inherit;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    text-align: end;
    /*position: fixed;*/
    right: 0;
    bottom: 70px;
    /*width : 100%;*/
    background-color: #ffffff;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
`
const initialState = {
  summary: null,
}

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link', 'image', 'video'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: 2 }],
    [{ color: [] }, { background: [] }],
    ['formula'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ align: [] }],
    ['clean'],
  ],
}

const TabMeetingSummary = ({ this_meeting_id }) => {
  const { user, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const dispatch = useDispatch()
  useEffect(() => {
    if (this_meeting_id) {
      dispatch(getMeetingById(this_meeting_id))
    }
  }, [this_meeting_id, dispatch])

  const { MeetingInfo } = useSelector(selectDailySummary)

  const [MeetingData, setMeetingData] = useState(initialState)

  useEffect(() => {
    if (MeetingInfo.id) {
      const setData = {
        summary: MeetingInfo.summary,
      }
      setMeetingData(setData)
    }
  }, [MeetingInfo])

  const [limitAttend, setlimitAttend] = useState(10)
  const [pageAttend, setpageAttend] = useState(1)
  const [totalAttend, settotalAttend] = useState(0)
  const [totalPagesAttend, settotalPagesAttend] = useState(0)
  const [AttendanceAttendCellData, setAttendanceAttendCellData] = useState([])
  const [AttendanceAttendCellDataMessage, setAttendanceAttendCellDataMessage] = useState([])
  const TableColumnAttend = [
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Full Name',
      accessor: 'full_name',
      disableSortBy: false,
    },
    {
      Header: 'Department',
      accessor: 'Department',
      disableSortBy: false,
    },
    {
      Header: 'Position in meeting',
      accessor: 'Position',
      disableSortBy: false,
    },
  ]

  const fetchDataAttendeeAttend = useCallback(
    async (filter) => {
      dispatch(getMeetingAttendeeReturnData(filter)).then(({ type, data_info, data_meta }) => {
        if (type.endsWith('_SUCCESS')) {
          if (data_meta) {
            const totalPage = Math.ceil(data_meta.pagination.total / limitAttend)
            settotalAttend(data_meta.pagination.total)
            settotalPagesAttend(totalPage)

            const newTableCellDataAttendance = data_info.map((v, index) => ({
              no: limitAttend * data_meta.pagination.page - (9 - index),
              full_name: v.full_name,
              Department: v.department ? v.department : '-',
              Position: v.position ? v.position : '-',
            }))
            setAttendanceAttendCellData(newTableCellDataAttendance)
          }
        }
      })
    },
    [dispatch]
  )
  useEffect(() => {
    const filter = {
      skip: 1,
      limit: limitAttend,
      active: 1,
      meeting_id: this_meeting_id,
      actual_attend: 'Attend',
    }
    fetchDataAttendeeAttend(filter)
  }, [fetchDataAttendeeAttend])

  const onChangePaginationAttendeeAttend = async (value) => {
    const filter = {
      skip: value.page,
      limit: limitAttend,
      active: 1,
      meeting_id: this_meeting_id,
      actual_attend: 'Attend',
    }
    fetchDataAttendeeAttend(filter)
    setpageAttend(value.page)
  }

  const Savechanges = () => {
    let sent_data_api = {
      data: {
        summary: MeetingData.summary,
      },
    }
    dispatch(updateMeeting(this_meeting_id, sent_data_api)).then(({ type }) => {
      if (type.endsWith('_SUCCESS')) {
      }
    })
  }
  return (
    <DivMain>
      <div className="css_summary">
        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div className="table-title">No. of people attend meeting</div>
              <div className="table-total">{totalAttend} persons</div>
            </div>
          </div>
          <div className="css_summary_magin_top">
            <TableV2
              columns={TableColumnAttend}
              data={AttendanceAttendCellData}
              onStateChange={onChangePaginationAttendeeAttend}
              pageCount={totalPagesAttend}
              page={pageAttend}
              loading={false}
            />
          </div>
        </div>
        <div className="table-wrapper-summary">
          <div className="table-header">
            <div className="group">
              <div className="table-title">Meeting Summary</div>
            </div>
          </div>
          <div className="css_summary_magin_top">
            <ReactQuill
              theme="snow"
              modules={modules}
              value={MeetingData.summary}
              onChange={(e) => setMeetingData({ ...MeetingData, summary: e })}
            />
          </div>
        </div>
      </div>
      <div className="dialog-footer">
        <ButtonOutline className="button_margin">{'Cancel'}</ButtonOutline>
        <Button className="button_margin" onClick={() => Savechanges()}>
          {'Save'}
        </Button>
      </div>
    </DivMain>
  )
}

export default TabMeetingSummary
