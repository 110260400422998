import { useEffect, useState } from 'react'
import styled from 'styled-components'
import TextField from '../../../form/TextField'
import Button from '../../../form/button/Button'
import ButtonOutline from '../../../form/button/ButtonOutline'
import InputTextArea from '../../../widget/element/InputTextArea'
import IconChevronLeft from '../../../widget/icon/IconChevronLeft'
import IconXClose from '../../../widget/icon/IconXClose'
import SearchSuggestEmployee from '../../../widget/combo/SearchSuggestEmployee'
import { useDispatch, useSelector } from 'react-redux'
import { createDocRequestApprove, getEmployee } from '../../../../redux/actions/summaryTask'
import { selectProjectTask, selectSummaryTask } from '../../../../redux/selectors'
import { dlog } from '../../../../utils/helper'
import { getAdminUser } from '../../../../utils/common'

export default function TaskRequestApprove({ onClickBtnSubmit, onClickNavigationBack,isRoutine }) {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { taskInfo, documentInfo } = useSelector(selectSummaryTask)
  const { milestoneTaskTypeInfo } = useSelector(selectProjectTask)
  const [note, setNote] = useState('')
  const [selectedRequestData,setSelectedRequestData]= useState({})

  const handleClickNavigationBack = () => {
    if (onClickNavigationBack) {
      onClickNavigationBack()
    }
  }
  const handleClickBtnSubmit = () => {
    if(selectedRequestData?.id && note){

      let tmpObj = {
        title: documentInfo.attributes.name,
        type: 'Document Approval',
        reference: documentInfo.id,
        request_to: selectedRequestData.id,
        note: note,
        created_by: employee_info?.id // user.id,
      }
      if(isRoutine){
        tmpObj.task = taskInfo.id
      }else{
        tmpObj.milestone_task_type = milestoneTaskTypeInfo.id
      }
      dispatch(createDocRequestApprove({data:tmpObj})).then(()=>{
        onClickNavigationBack()
      })
    }else{
      alert('โปรดเลือก Request to และกรอก Note')
    }
    
    if (onClickBtnSubmit) {
      onClickBtnSubmit()
    }
  }

  return (
    <Styled>
      <div className="wrp-navigation">
        <div className="navigation-left">
          <div className="icon-chevron-left" onClick={() => handleClickNavigationBack()}>
            <IconChevronLeft width="10px" />
          </div>
          <div className="txt-navigation-left">Request Approve</div>
        </div>
        <div className="navigation-right">
          <div className="icon-x-close" onClick={() => handleClickNavigationBack()}>
            <IconXClose />
          </div>
        </div>
      </div>

      <div className="wrp-inputs">
        <div className="wrp-search-suggest">
          <SearchSuggestEmployee
            placeHolder="Request to"
            onSelectedSuggest={(data)=>setSelectedRequestData(data)}
          />
          <TextField className="input-email" disabled placeholder="Email" value={selectedRequestData?.email||''} />
        </div>
        <div className="wrp-input-text-area">
          <InputTextArea width="100%" borderWidth="1px" borderColor="var(--Gray-300)" placeHolder="Note" onChange={(v)=>setNote(v.target.value)} />
        </div>
      </div>

      <div className="wrp-btn-cancel-submit">
        <ButtonOutline className="btn-cancel" onClick={() => handleClickNavigationBack()}>
          Cancel
        </ButtonOutline>
        <Button onClick={() => handleClickBtnSubmit()}>Submit</Button>
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  position: absolute;
  background-color: #fff;
  inset: 0;
  display: flex;
  flex-direction: column;

  .wrp-navigation {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    border-bottom: 1px solid var(--Gray-300);
  }
  .navigation-left {
    display: flex;
  }
  .icon-chevron-left {
    display: flex;
    margin-right: 1rem;
    cursor: pointer;
  }
  .txt-navigation-left {
    color: gray;
    font-weight: bold;
    font-size: 20px;
  }
  .navigation-right {
    display: flex;
    align-items: center;
  }
  .icon-x-close {
    display: flex;
    cursor: pointer;
  }

  .wrp-inputs {
    padding: 32px;
    flex: 1;
  }
  .wrp-search-suggest {
    display: flex;
  }
  .search-suggest {
    margin-right: 10px;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  .dropdown {
    width: 100%;
    margin-right: 16px;
  }

  .wrp-input-text-area {
    margin-top: 16px;
  }

  .wrp-btn-cancel-submit {
    text-align: end;
    border-top: 1px solid var(--Gray-300);
    padding: 16px 32px;
  }
  .btn-cancel {
    margin-right: 16px;
  }

  .input-email {
    width: 300px;
  }
`
