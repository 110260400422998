import styled from 'styled-components'
import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { getCompanyInfo,GetPermissionOther,GetPermissionTabList } from '../../utils/common'

import {
    getPositionLevelReturnData,
    CreatePositionLevel,
    updatePositionLevel,
  } from '../../redux/actions/jobPosition'

import Button from '../../components/form/button/Button'
import TableV2 from '../../components/common/TableV2'
import SearchText from '../../components/form/SearchText'
import CollapseMenu from '../../components/common/CollapseMenu'
import { LiaTimesSolid } from 'react-icons/lia'
import SidePanel from '../../components/common/SidePanel'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TextField from '../../components/form/TextField'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'

import { useTranslation } from 'react-i18next'

const DivMyCalendar = styled.div`
  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }
  .content-header-v2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .border_header{
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .content-fillter-bg{
    min-height: 68px;
    background-color: var(--Gray-50);
    border-radius: 8px;
    margin-bottom: 21px;
    padding: 10px;
  }
  .content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
  
    .search-wrapper {
      margin-right: 12px;
    }
  
    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }
  
    .group_filter {
      display: flex;
      align-items: center;
    }
  }
  .content-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    .search-wrapper {
      margin-right: 12px;
    }

    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }

    .group_filter {
      display: flex;
      align-items: center;
    }
  }
  .box_search {
    margin-top: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #EAECF0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #FFFFFF;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .cursor_pointer {
    cursor: pointer;
  }
  .button_clear_all{
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
  }
  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 10px;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
  .col-no{
    font-size:14px;
    width:5%;
  }
  .col-buttonDelete{
    font-size:14px;
    width:5%;
  }
  .col-linkEdit{
    font-size:14px;
    width:5%;
  }

  .col-Title{
    font-size:14px;
    width:30%;
  }

  .col-Meeting_No{
    font-size:14px;
    width:10%;
  }
  .col-Meeting_Date{
    font-size:14px;
    width:10%;
  }
  .col-Meeting_Time{
    font-size:14px;
    width:10%;
  }
  .col-Meeting_Room{
    font-size:14px;
    width:13%;
  }

  .col-Meeting_link{
    font-size:14px;
    width:7%;
  }

  .col-Meeting_File{
    font-size:14px;
    width:5%;
  }
  
  


  .text-bold {
    font-weight: bold;
  }
  
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .dropdown_css{
    min-width : 220px;
  }
.wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        border-bottom: 2px solid #EAECF0;
        color: #667085;
        font-size: 14px;
    }

    .menu-active {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid var(--Primary-600) !important;
        color: #6941C6;
        background-color: #F9F5FF;
    }
}
.status-badge {
  width: 90px;
  padding: 0.2rem;
  text-align: center;
  border: 1.5px solid var(--Gray-300);
  border-radius: 6px;
  font-weight: 500;

  .status {
    font-size: 0.6rem;
    margin-right: 0.2rem;
    color: #17b26a;

    &.inactive {
      color: red;
    }
  }
}
.col-all_room_no{
  width:5%;
}  
.col-all_room_action{
  width:10%;
}
.col-all_room_Meeting_Room{
  width:50%;
}
.col-all_location{
  width:20%;
}
.col-all_room_status{
  width:15%;
}
.link_view_map{
  color: #344054;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.css_text_not_employee{
  color: red;
}
`

const DivSidePanel = styled.div`
  .text-bold {
    font-weight: bold;
  }
  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .button-footer-company-profile {
    margin-bottom: 25rem;
  }
  .button-footer {
    margin-bottom: 35rem;
  }
  .h-full {
    height: 100%;
  }
  .left-profile {
    margin-left: 0px;
  }
  .left {
    margin-left: 260px;
  }
  .right {
    margin-right: 230px;
  }
  .col-12 {
    width: 100%;
  }
  .input-col {
    display: flex;
    grid-template-columns: repeat(2, 10rem) 8rem;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input-header {
    color: var(--gray-700);
    font-size: 16px;
    font-weight: 500;
  }
  .icon {
    cursor: pointer;
  }
  .divider {
    margin: 2rem 0;
    border: 0.1rem solid #eaecf0;
  }
  .create-access-role-header {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .side-panel-container {
    padding: 2rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .d-flex {
    display: flex;
  }
  .row {
    flex-direction: row;
  }
  .justify-content-flex-start {
    justify-content: flex-start;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .align-items-center {
    align-items: center;
  }
  .content-header {
    margin-top: 24px;
  }
  .float-end {
    float: right;
  }

  .float-start {
    float: left;
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .mt-50 {
    margin-top: 50px;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .w-100 {
    width: 100%;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mb-to-end {
    margin-bottom: 620px;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .date-filter-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .drop-down {
    min-width: 10rem;
  }

  .task-report-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .task-box {
    border: 1px solid black;
    width: 100%;
    margin: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
  }

  .task-box .amount-tasks {
    font-weight: bold;
    text-align: center;
    color: black;
    font-size: 20px;
  }

  .filter-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .wrp-filter-menu {
      position: relative;
      display: flex;
    }

    .wrp-filter-btn {
      position: relative;
    }

    .dialog-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 300px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);
      max-height: 300px;

      .wrp-person-list {
        position: relative;
      }
      .person-list {
        height: 220px;
        overflow-y: scroll;
      }

      .person-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 4px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
        }

        .img-profile {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          background-color: var(--Gray-200);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .person-item:hover {
        background-color: var(--Gray-50);
      }
    }

    .filter-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 500px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);

      .wrp-filter-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clear-btn {
          padding: 4px 6px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        .clear-btn:hover {
          background: var(--Primary-25);
        }
      }
      .wrp-filter-option {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .title-option {
          font-weight: 600;
          margin-bottom: 6px;
        }
        .option-item-style {
          border: 1px solid var(--Gray-300);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Gray-50);
        }
        .option-item-style:hover {
          background-color: var(--Gray-100);
        }
        .option-item-active {
          border: 1px solid var(--Primary-600);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Primary-600);
          color: #fff;
        }
      }
    }
  }

  .wrp-filter-menu {
    display: flex;
    align-items: center;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px solid var(--Doing-Badge-Stroke);
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .error-input {
    border: 1px solid red;
  }

  .error {
    color: red;
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }
`



const PositionLevel = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const CompanyInfo = getCompanyInfo()
    const initLang = localStorage.getItem('lang')
    //--- ST ส่วนของ Permission LV3-4
    const permissionNewPositionLevel = GetPermissionOther('position_level', 'new_position_level');
    const permissionEditPositionLevel = GetPermissionOther('position_level', 'edit_position_level');
    const permissionDeletePositionLevel = GetPermissionOther('position_level', 'delete_position_level');
    /*
    const { permission } = JSON.parse(localStorage.getItem('admin'))
    const [permissionNewPositionLevel, setpermissionNewPositionLevel] = useState(null);
    let permissionEditPositionLevel = null;
    let permissionDeletePositionLevel = null;
    useEffect(() => {
      const this_sub_menu_permission = permission['Human Resources & Organization'].filter((it_2) => it_2.path === '/organization-management/organization?tab=companyprofile');
      const tab_permission = this_sub_menu_permission[0].permission_tab?.filter((it_2) => it_2.menu_setting.rout_path === 'Org_PositionLevel')
      if(tab_permission.length > 0){
        const this_permissionNewPositionLevel = tab_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'Org_PositionLevel_NewPositionLevel');
        if(this_permissionNewPositionLevel){
          setpermissionNewPositionLevel(this_permissionNewPositionLevel)
        }
        const this_permissionEditPositionLevel = tab_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'Org_PositionLevel_EditPositionLevel');
        if(this_permissionEditPositionLevel){
          permissionEditPositionLevel = this_permissionEditPositionLevel;
        }
        const this_permissionDeletePositionLevel = tab_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'Org_PositionLevel_DeletePositionLevel');
        if(this_permissionDeletePositionLevel){
          permissionDeletePositionLevel = this_permissionDeletePositionLevel;
        }
      }
    }, [])
    */
    //--- ED ส่วนของ Permission LV3-4    

    const [limitPosLevel, setlimitPosLevel] = useState(10)
    const [pagePosLevel, setpagePosLevel] = useState(1)
    const [totalPosLevel, settotalPosLevel] = useState(0)
    const [totalPagesPosLevel, settotalPagesPosLevel] = useState(0)
    const [PosLevelCellData ,setPosLevelCellData] = useState([])
    const TableColumnPosLevel = [
        {
          Header: 'No.',
          accessor: 'no',
          disableSortBy: false,
        },
        {
            Header: 'Company',
            accessor: 'company',
            disableSortBy: false,
        },
        {
            Header: 'Position Level',
            accessor: 'position_level',
            disableSortBy: false,
        },
        {
            Header: 'Number of Employee',
            accessor: 'num_employee',
            disableSortBy: false,
        },
        {
            Header: 'Action',
            accessor: 'action',
            disableSortBy: false,
        },
     ]

     const fetchDataPosLevel = useCallback(
        async (filter) => {
            dispatch(getPositionLevelReturnData(filter)).then(({ type,data_info,data_meta }) => {
                if(type.endsWith('_SUCCESS')){
                    if(data_meta){
                        const totalPage = Math.ceil(data_meta.pagination.total / limitPosLevel)
                        settotalPosLevel(data_meta.pagination.total)
                        settotalPagesPosLevel(totalPage)

                        
                        const newTableCellData = data_info.map((v, index) => {
                            /*
                            const list = [
                              {
                                text: 'Edit',
                                action: () => editPositionLevel(v.id,v),
                              },
                              {
                                text: 'Delete',
                                action: () => deletePositionLevel(v.id),
                              }
                            ];
                            */
                            let list = [];
                            if(permissionEditPositionLevel?.canRead === true){
                              list.push({
                                text: 'Edit',
                                action: () => editPositionLevel(v.id,v),
                              });
                            }
                            if(permissionDeletePositionLevel?.canRead === true){
                              list.push({
                                text: 'Delete',
                                action: () => deletePositionLevel(v.id),
                              });
                            }
                            return {
                              ...v,
                              no : limitPosLevel * data_meta.pagination.page - (9 - index),
                              company : v.company_name ? v.company_name : '-',
                              position_level : v.name_th ? v.name_th : '-',
                              num_employee : v.employee_users,
                              action: <CollapseMenu list={list} />,
                            }
                          })

                          setPosLevelCellData(newTableCellData)
                    }
                }
            })
        },
        [dispatch]
    )

    useEffect(() => {
        
        const filter = {
            skip: 1,
            limit: limitPosLevel,
        }
        fetchDataPosLevel(filter)
    }, [fetchDataPosLevel])  


     const [search, setSearch] = useState('')
     const [searchFilters, setSearchFilters] = useState([])



     const onSearchSpace = () => {
        const newFilter = {
          key: uuidv4(),
          name: search,
        }
        const filter = {
          skip: 1,
          limit: limitPosLevel,
          name_th:[...searchFilters, newFilter].map((v) => v.name).join(','),
        }
        fetchDataPosLevel(filter)
        setpagePosLevel(1)
        setSearchFilters((prev) => [...prev, newFilter])
      }

      const onClearSearchSpace = () => {
        setSearch('')
      }

      const deleteSearchBadgeSpace = (key) => {
        const newSearchFilters = searchFilters.filter((v) => v.key != key)
        setSearchFilters(newSearchFilters)
        
        const filter = {
          skip: 1,
          limit: 10,
          name_th: newSearchFilters.map((v) => v.name).join(','),
        }
        fetchDataPosLevel(filter)
        setSearch('')
        setpagePosLevel(1)
        
      }

      const onClearAllSearchSpace = () => {
        setSearchFilters([])
        const filter = {
          skip: 1,
          limit: limitPosLevel,
        }
        fetchDataPosLevel(filter)
        setSearch('')
        setpagePosLevel(1)
      }

      const onChangePagination_Poslevel = async (value) => {
        const filter = {
          skip: value.page,
          limit: limitPosLevel,
          name_th: searchFilters.map((v) => v.name).join(','),
        }
        fetchDataPosLevel(filter)
        setpagePosLevel(value.page)
    }

    
    // side panel
    const [TitleFail, setTitleFail] = useState('')
    const [nameItemFail, setnameItemFail] = useState('')
    const [DescriptionFail, setDescriptionFail] = useState('')
    const [failModal, setFailModal] = useState(false)
    const confirmFailModal = () => {
        setFailModal(false)
    }
    
    const initValidateForm = {
        name: null
    }

    const [errors, setErrors] = useState(initValidateForm);
    const [PositionLevelId, setPositionLevelId] = useState(null)
    const [position_level_th, setposition_level_th] = useState('')
    const [isOpenSidepanelPositionLevel, setIsOpenSidepanelPositionLevel] = useState(false)
    const [isEditOpenSidepanelPositionLevel, setIsEditOpenSidepanelPositionLevel] = useState(false)
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
    const openSidepanelPositionLevel = () => {
        
        if(permissionNewPositionLevel?.canUpdate === true){
          setIsOpenSidepanelPositionLevel(true)
        }else{
          let message_permission = 'No permission allowed ' + permissionNewPositionLevel?.name_en;
          if(initLang == 'th'){
            message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionNewPositionLevel?.name_th;
          }
          setTitleFail(message_permission);
          setnameItemFail('');
          setDescriptionFail(''); 
          setFailModal(true)
        }
    }

    const editPositionLevel = (this_id,all_obj) => {
        
        if(permissionEditPositionLevel?.canUpdate === true){
          setPositionLevelId(this_id)
          setposition_level_th(all_obj.name_th)
          setIsEditOpenSidepanelPositionLevel(true)
        }else{
          let message_permission = 'No permission allowed ' + permissionEditPositionLevel?.name_en;
          if(initLang == 'th'){
            message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionEditPositionLevel?.name_th;
          }
          setTitleFail(message_permission);
          setnameItemFail('');
          setDescriptionFail(''); 
          setFailModal(true)
        }
    }   
    const deletePositionLevel = (this_id) => {
        
        if(permissionDeletePositionLevel?.canUpdate === true){
          setPositionLevelId(this_id)
          setConfirmDeleteModal(true)
        }else{
          let message_permission = 'No permission allowed ' + permissionDeletePositionLevel?.name_en;
          if(initLang == 'th'){
            message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionDeletePositionLevel?.name_th;
          }
          setTitleFail(message_permission);
          setnameItemFail('');
          setDescriptionFail(''); 
          setFailModal(true)
        }
    }

    const openConfirmCreate = () => {
        const isValid = validateForm()
        if (isValid) {
            const request = {
                data: {
                  company : CompanyInfo?.id,
                  name_th: position_level_th,
                },
            }
            dispatch(CreatePositionLevel(request)).then(({ data_result,type }) => {
                if(type.endsWith('_SUCCESS')){
                    setPositionLevelId(null)
                    setposition_level_th('')
                    onClearAllSearchSpace()
                    setIsOpenSidepanelPositionLevel(false)
                }else{
                    setTitleFail('Error Create Position Level');
                    setnameItemFail('');
                    setDescriptionFail(''); 
                    setFailModal(true)
                }
            })
        }
    }

    const openConfirmEdit = () => {
        const isValid = validateForm()
        if (isValid) {
            const request = {
                data: {
                  name_th: position_level_th
                },
            }
            dispatch(updatePositionLevel(PositionLevelId,request)).then(({ data_result,type }) => {
                if(type.endsWith('_SUCCESS')){
                    setPositionLevelId(null)
                    setposition_level_th('')
                    onClearAllSearchSpace()
                    setIsEditOpenSidepanelPositionLevel(false)
                }else{
                    setTitleFail('Error Edit Position Level');
                    setnameItemFail('');
                    setDescriptionFail(''); 
                    setFailModal(true)
                }
            })
        }
    }

    const confirmDelete = () => {
        const request = {
            data: {
                active: 0
            },
        }
        dispatch(updatePositionLevel(PositionLevelId,request)).then(({ data_result,type }) => {
            if(type.endsWith('_SUCCESS')){
                setPositionLevelId(null)
                setposition_level_th('')
                onClearAllSearchSpace()
                setConfirmDeleteModal(false)
            }else{
                setTitleFail('Error Delete Position Level');
                setnameItemFail('');
                setDescriptionFail(''); 
                setFailModal(true)
            }
        })
    }
    

    const validateForm = () => {
        let newErrors = {
          ...initValidateForm,
        }
        
        if (position_level_th == '') {
          newErrors.name = 'Position Level is required'
        }
    
        setErrors(newErrors)
    
        return Object.values(newErrors).every((x) => x === null)
    }



    

    return (
        <DivMyCalendar>
        <div>
          <div className="content-fillter-bg">
            <div className="content-fillter">
              <div className="group_filter">
                <div>
                  <SearchText
                    className="search-wrapper"
                    placeholder={t('search')}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onSubmit={onSearchSpace}
                    onClear={onClearSearchSpace}
                  />
                </div>
                <div>
                  <Button onClick={onClearAllSearchSpace} className="button_clear_all mr-1-rem">
                    {t('clear_all')}
                  </Button>
                </div>
                
              </div>

              <div className="container">
              </div>

            </div>
            <div className="badge-search-content">
                  {searchFilters.map((v) => (
                    <div className="badge" key={v.key}>
                      <span className="name">{v.name}</span>{' '}
                      <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadgeSpace(v.key)} />
                    </div>
                  ))}
            </div>
          </div>

          <div className="table-wrapper">
            <div className="table-header">
              <div className="group">
                <div className="table-title">{t('position_level')}</div>
                <div className="table-total">{totalPosLevel} {t('levels')}</div>
              </div>
              <div>
                {permissionNewPositionLevel?.canRead === true && (
                  <Button onClick={openSidepanelPositionLevel}>
                    {t('new_position_level')}
                  </Button>
                )}
              </div>
            </div>
            <TableV2
              columns={TableColumnPosLevel}
              data={PosLevelCellData}
              onStateChange={onChangePagination_Poslevel}
              pageCount={totalPagesPosLevel}
              page={pagePosLevel}
              loading={false}
            />
          </div>
        </div>

            <SidePanel isOpen={isOpenSidepanelPositionLevel} setIsOpen={setIsEditOpenSidepanelPositionLevel} width={450}>
                <DivSidePanel>
                <div className="side-panel-container">
                    <div>
                    <div className="d-flex justify-content-between align-items-center mb-2-rem">
                        <div className="create-access-role-header">{t('create_position_level')}</div>
                        <LiaTimesSolid size={25} onClick={() => setIsOpenSidepanelPositionLevel(false)} className="icon" />
                    </div>

                    <div className="mb-1-rem">
                        <div className="input-header mb-1-rem">{t('position_level')} *</div>
                        <div className="input-new">
                        <TextField
                            className={`${errors.name == null ? '' : 'error-input'}`}
                            value={position_level_th}
                            onChange={(e) => setposition_level_th(e.target.value)}
                            placeholder="Department name"
                        />
                        {errors.name && <div className="error">{errors.name}</div>}
                        </div>
                    </div>
                    </div>

                    <div>
                    <div className="divider"></div>
                    <div className="d-flex justify-content-between float-end">
                        <ButtonOutline onClick={() => setIsOpenSidepanelPositionLevel(false)} className="mr-1-rem">
                        {t('cancel')}
                        </ButtonOutline>
                        <Button onClick={openConfirmCreate}>{t('create')}</Button>
                    </div>
                    </div>
                </div>
                </DivSidePanel>
            </SidePanel>


            <SidePanel isOpen={isEditOpenSidepanelPositionLevel} setIsOpen={setIsEditOpenSidepanelPositionLevel} width={450}>
                <DivSidePanel>
                <div className="side-panel-container">
                    <div>
                    <div className="d-flex justify-content-between align-items-center mb-2-rem">
                        <div className="create-access-role-header">{t('edit_position_level')}</div>
                        <LiaTimesSolid size={25} onClick={() => setIsEditOpenSidepanelPositionLevel(false)} className="icon" />
                    </div>

                    <div className="mb-1-rem">
                        <div className="input-header mb-1-rem">{t('position_level')} *</div>
                        <div className="input-new">
                        <TextField
                            className={`${errors.name == null ? '' : 'error-input'}`}
                            value={position_level_th}
                            onChange={(e) => setposition_level_th(e.target.value)}
                            placeholder="Department name"
                        />
                        {errors.name && <div className="error">{errors.name}</div>}
                        </div>
                    </div>
                    </div>

                    <div>
                    <div className="divider"></div>
                    <div className="d-flex justify-content-between float-end">
                        <ButtonOutline onClick={() => setIsEditOpenSidepanelPositionLevel(false)} className="mr-1-rem">
                        {t('cancel')}
                        </ButtonOutline>
                        <Button onClick={openConfirmEdit}>{t('save')}</Button>
                    </div>
                    </div>
                </div>
                </DivSidePanel>
            </SidePanel>

            <DialogConfirmDelete
                open={Boolean(confirmDeleteModal)}
                onClose={() => setConfirmDeleteModal(false)}
                onSubmit={confirmDelete}
                title={t('confrim_delete')}
                description={t('would_you_like_to_confirm_delete_this_position_level')}
            />

            <DialogFail
                open={Boolean(failModal)}
                onClose={() => setFailModal(false)}
                onSubmit={confirmFailModal}
                icon={failIcon}
                title={TitleFail}
                nameItem={nameItemFail}
                description={DescriptionFail}
                textYes={t('ok')}
            />
        </DivMyCalendar>
    )
}

export default PositionLevel