import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { DateDisplay } from 'utils/common'
import { v4 as uuidv4 } from 'uuid'

import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import SearchText from 'components/form/SearchText'
import TabGroupMenu2 from 'components/common/TabGroupMenu2'
import Dropdown from 'components/form/Dropdown'
import Table from 'components/common/Table'
import Badge from 'components/common/Badge'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Button from 'components/form/button/Button'
import OutlineCheckbox from 'components/form/OutlineCheckbox'
import BadgeSearch from 'components/common/BadgeSearch'

import { useDispatch, useSelector } from 'react-redux'
import { selectActionPlan } from 'redux/selectors'
import { getProjectCount, getProjectList } from 'redux/actions/actionPlan'

const Div = styled.div`
  .input-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
  }

  .highlight-text {
    color: var(--Primary-600);
    font-weight: 500;
  }

  .time-over {
    color: var(--Error-600);
  }

  .badgej-status-style {
    color: var(--warning-700);
    border-color: var(--warning-200);
    background: var(--warning-50);
  }
`

const initialFilter = {
  search: '',
  sortBy: '',
}
function ProjectDataContent({ add, onClose }) {
  // external hook
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // initiate data
  const fetchProjectList = useCallback(
    (filter = { page: 1, pageSize: 10, status: 'Doing' }) => {
      dispatch(getProjectList(filter))
    },
    [dispatch]
  )
  const fetchProjectCount = useCallback(() => {
    dispatch(getProjectCount())
  }, [dispatch])

  useEffect(() => {
    fetchProjectList()
    fetchProjectCount()
  }, [])

  // tab menu options
  const initialTabMenuOptions = [
    {
      text: 'In progress',
      value: 'Doing',
      number: 6,
    },
    {
      text: 'Open',
      value: 'Open',
      number: 4,
    },
  ]
  const { totolProject } = useSelector(selectActionPlan)
  const [tabMenuOptions, setTabMenuOptions] = useState([])

  useEffect(() => {
    const newTabMenuOptions = initialTabMenuOptions.map((v) => ({
      ...v,
      number: v.value === 'Doing' ? String(totolProject?.totalInprogress) : String(totolProject?.totalOpen),
    }))
    setTabMenuOptions(newTabMenuOptions)
  }, [totolProject])

  // tab menu selected
  const [tabMenu, setTabMenu] = useState('Doing')

  const onChangeTab = (value) => {
    setTabMenu(value)

    const filters = {
      ...initialFilter,
      page: 1,
      pageSize: pageSize,
      status: value,
      multipleSearch: [],
    }
    fetchProjectList(filters)
    clearFilter()
  }

  // table data
  const initialProjectDataColumns = [
    {
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('project'),
      accessor: 'project',
      disableSortBy: false,
    },
    {
      Header: t('task_name'),
      accessor: 'task_name',
      disableSortBy: false,
    },
    {
      Header: t('milestone'),
      accessor: 'milestone',
      disableSortBy: false,
    },
    {
      Header: t('scope_of_work'),
      accessor: 'sow',
      disableSortBy: false,
    },
    {
      Header: t('due_date'),
      accessor: 'due_date',
      disableSortBy: false,
    },
    {
      Header: 'Time left',
      accessor: 'timesLeft',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
  ]

  const { projects, page, pageCount, pageSize, total } = useSelector(selectActionPlan)

  const [projectList, setProjectList] = useState([])
  const [projectForm, setProjectForm] = useState([])

  const onSelectCheckBox = (id, event) => {
    const { checked } = event.target
    setProjectForm((prev) =>
      prev.map((v) => {
        if (v.id === id)
          return {
            ...v,
            is_checked: checked,
          }
        else
          return {
            ...v,
            is_checked: false,
          }
      })
    )
  }

  useEffect(() => {
    const newProjectForm = projects.map((v) => ({
      ...v,
      is_checked: false,
    }))
    setProjectForm(newProjectForm)
  }, [projects])
  useEffect(() => {
    const newOperationProcessList = projectForm.map((v, i) => ({
      ...v,
      checkbox: <OutlineCheckbox checked={v.is_checked} onChange={(event) => onSelectCheckBox(v.id, event)} />,
      no: i + 1,
      timesLeft: <Box className={`${Number(v.timesLeft) < 0 ? 'time-over' : ''}`}>{`${v.timesLeft} hrs`}</Box>,
      status: <Badge className="badgej-status-style">{v.status}</Badge>,
      task_name: <Box className="highlight-text">{v.task_name}</Box>,
      due_date: DateDisplay(v.due_date),
    }))
    setProjectList(newOperationProcessList)
  }, [projectForm])

  // option dropdown
  const initialSortByOptions = [
    {
      text: 'เวลาที่เหลือน้อย ไป มาก',
      value: 'asc',
    },
    {
      text: 'เวลาที่เหลือมาก ไป น้อย',
      value: 'desc',
    },
  ]

  // filter
  const [filter, setFilter] = useState(initialFilter)
  const [searchFilters, setSearchFilters] = useState([])

  const onChangeFilter = (name, value) => {
    const newFilter = {
      ...filter,
      [name]: value,
    }
    setFilter(newFilter)

    if (name === 'sortBy') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[$and][${i}][$or][0][task][task_name][$contains]`]: v.name,
        [`filters[$and][${i}][$or][1][milestone_scope_work][milestone][project_task][name][$contains]`]: v.name,
      }))

      const filters = {
        ...filter,
        page: 1,
        pageSize: pageSize,
        status: tabMenu,
        sortBy: value,
        multipleSearch,
      }

      fetchProjectList(filters)
    }
  }
  const onChangePagination = (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$and][${i}][$or][0][task][task_name][$contains]`]: v.name,
      [`filters[$and][${i}][$or][1][milestone_scope_work][milestone][project_task][name][$contains]`]: v.name,
    }))

    const filters = {
      ...filter,
      page: value.page,
      pageSize: pageSize,
      status: tabMenu,
      multipleSearch,
    }

    fetchProjectList(filters)
  }
  const onSearch = () => {
    if (filter.search !== '') {
      const newSearchFilter = {
        key: uuidv4(),
        name: filter.search,
      }

      const multipleSearch = [...searchFilters, newSearchFilter].map((v, i) => ({
        [`filters[$and][${i}][$or][0][task][task_name][$contains]`]: v.name,
        [`filters[$and][${i}][$or][1][milestone_scope_work][milestone][project_task][name][$contains]`]: v.name,
      }))

      const filters = {
        ...filter,
        page: 1,
        pageSize: pageSize,
        status: tabMenu,
        multipleSearch,
      }

      fetchProjectList(filters)
      setFilter((prev) => ({
        ...prev,
        search: '',
      }))
      setSearchFilters((prev) => [...prev, newSearchFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const currentSearchFilters = searchFilters.filter((v) => v.key !== key)
    const multipleSearch = currentSearchFilters.map((v, i) => ({
      [`filters[$and][${i}][$or][0][task][task_name][$contains]`]: v.name,
      [`filters[$and][${i}][$or][1][milestone_scope_work][milestone][project_task][name][$contains]`]: v.name,
    }))

    const filters = {
      ...filter,
      page: 1,
      pageSize: pageSize,
      status: tabMenu,
      multipleSearch,
    }
    fetchProjectList(filters)
    setSearchFilters(currentSearchFilters)
  }
  const onClearSearch = () => {
    const filters = {
      ...initialFilter,
      page: 1,
      pageSize: pageSize,
      status: tabMenu,
      multipleSearch: [],
    }

    fetchProjectList(filters)
    clearFilter()
  }
  const clearFilter = () => {
    setFilter(initialFilter)
    setSearchFilters([])
  }

  const cancel = () => {
    onClose()
  }
  const onSubmit = () => {
    const selectedItem = projectForm.find((v) => v.is_checked)
    add('project', selectedItem)
    onClose()
  }

  return (
    <Div>
      <Stack sx={{ mb: 3 }} direction="row" justifyContent="space-between" alignItems="flex-end">
        <Stack direction="row" alignItems="flex-end" spacing={1}>
          <Box>
            <div className="input-heading">Search</div>
            <SearchText
              value={filter.search}
              onChange={(event) => onChangeFilter('search', event.target.value)}
              onSubmit={onSearch}
              onClear={() => () => onChangeFilter('search', '')}
            />
          </Box>
          <Box>
            <div className="input-heading">Sort by</div>
            <Dropdown
              optionList={initialSortByOptions}
              value={filter.sortBy}
              onChange={(value) => onChangeFilter('sortBy', value)}
            />
          </Box>
          <ButtonOutline onClick={onClearSearch}>Clear</ButtonOutline>
        </Stack>

        <Box>
          <TabGroupMenu2 tabMenuOptions={tabMenuOptions} tabMenu={tabMenu} setTabMenu={onChangeTab} />
        </Box>
      </Stack>

      <Box sx={{ mb: 3 }}>
        <BadgeSearch searchFilters={searchFilters} deleteSearchBadge={deleteSearchBadge} />
      </Box>

      <Box>
        <Table
          data={projectList}
          columns={initialProjectDataColumns}
          page={page}
          pageCount={pageCount}
          onStateChange={onChangePagination}
        />
      </Box>

      <Stack direction="row" justifyContent="flex-end" spacing={2}>
        <ButtonOutline onClick={cancel}>{t('cancel')}</ButtonOutline>
        <Button onClick={onSubmit}>{t('confirm')}</Button>
      </Stack>
    </Div>
  )
}

export default ProjectDataContent
