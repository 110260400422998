import React, { useCallback, useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { selectActionPlan } from 'redux/selectors'
import { getOPCount, getProjectCount, getTaskCount } from 'redux/actions/actionPlan'

import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import TabGroupMenu from 'components/common/TabGroupMenu'
import OperationProcessDataContent from './OperationProcessDataContent'
import ProjectDataContent from './ProjectDataContent'
import MyTaskDataContent from './MyTaskDataContent'

import { LiaTimesSolid } from 'react-icons/lia'

const Div = styled.div`
  padding: 24px;
  max-height: 80vh;
  overflow: scroll;

  .heading-modal {
    color: var(--Gray-900);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
  }
  .icon {
    cursor: pointer;
  }
`

function MyTaskModalContent({ keyActionPlan, addToAction, onClose }) {
  // external hook
  const dispatch = useDispatch()

  const fetchProjectCount = useCallback(() => {
    dispatch(getProjectCount())
  }, [dispatch])
  const fetchTaskCount = useCallback(() => {
    dispatch(getTaskCount())
  }, [dispatch])
  const fetchOPCount = useCallback(() => {
    dispatch(getOPCount())
  }, [dispatch])

  useEffect(() => {
    fetchProjectCount()
    fetchTaskCount()
    fetchOPCount()
  }, [])

  //tab menu options
  const initialTabMenuOptions = [
    {
      text: 'Operation Process',
      value: 'operation-process',
      number: 10,
    },
    {
      text: 'Project',
      value: 'project',
      number: 20,
    },
    {
      text: 'My Task',
      value: 'my-task',
      number: 30,
    },
  ]
  const { totolProject, totalTask, totalOP } = useSelector(selectActionPlan)
  const [tabMenuOptions, setTabMenuOptions] = useState([])

  useEffect(() => {
    if (Object.keys(totolProject).length > 0 && Object.keys(totalTask).length > 0 && Object.keys(totalOP).length > 0) {
      const newTabMenuOptions = initialTabMenuOptions.map((v) => ({
        ...v,
        number:
          v.value === 'operation-process'
            ? String(totalOP?.total)
            : v.value === 'project'
            ? String(totolProject?.total)
            : String(totalTask?.total),
      }))
      setTabMenuOptions(newTabMenuOptions)
    }
  }, [totolProject, totalTask, totalOP])

  // tab menu select
  const [tabMenu, setTabMenu] = useState('operation-process')

  const add = (type, value) => {
    addToAction(keyActionPlan, type, value)
  }

  return (
    <Div>
      <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
        <div className="heading-modal">My Task (30 Task)</div>
        <LiaTimesSolid size={20} className="icon" onClick={onClose} />
      </Stack>

      <Box sx={{ mb: 3 }}>
        <TabGroupMenu tabMenuOptions={tabMenuOptions} tabMenu={tabMenu} setTabMenu={setTabMenu} />
      </Box>

      {tabMenu === 'operation-process' ? (
        <Box>
          <OperationProcessDataContent add={add} onClose={onClose} />
        </Box>
      ) : tabMenu === 'project' ? (
        <Box>
          <ProjectDataContent add={add} onClose={onClose} />
        </Box>
      ) : (
        <Box>
          <MyTaskDataContent add={add} onClose={onClose} />
        </Box>
      )}
    </Div>
  )
}

export default MyTaskModalContent
