export default function IconClockRefresh({ width = '14', color = '#9E77ED', style }) {
  return (
    <svg width={width} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M13.6348 8.59489C13.4496 10.3348 12.4638 11.9653 10.8325 12.9072C8.12213 14.472 4.65646 13.5433 3.09165 10.833L2.92498 10.5443M2.36346 7.40443C2.54866 5.6645 3.53452 4.03401 5.16585 3.09217C7.87617 1.52736 11.3418 2.45599 12.9067 5.16631L13.0733 5.45499M2.32812 12.0436L2.81616 10.2223L4.63753 10.7103M11.3608 5.28899L13.1822 5.77703L13.6702 3.95566M7.99918 4.99964V7.99964L9.66584 8.99964"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
