import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { DateTimeHHmmDisplay } from '../../../utils/common'
import { v4 as uuidv4 } from 'uuid'

import { selectSharedService, selectMaster } from '../../../redux/selectors'
import { getEmployee } from '../../../redux/actions/master'
import {
  getDocumentInviteList,
  addInvite,
  deleteInvite,
  sendDocNotification,
} from '../../../redux/actions/sharedService'

import styled from 'styled-components'
import Button from '../../../components/form/button/Button'
import SearchText from '../../../components/form/SearchText'
import Dropdown from '../../../components/form/Dropdown'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import OutlineCheckbox from '../../../components/form/OutlineCheckbox'
import AutoComplete from '../../../components/form/AutoComplete'
import Card from '../../../components/common/Card'
import Table from '../../../components/common/Table'
import DialogConfirmDelete from '../../../components/dialog/DialogConfirmDelete'
import DialogSuccess from '../../../components/dialog/DialogSuccess'
import DialogFail from '../../../components/dialog/DialogFail'

import { LiaTimesSolid } from 'react-icons/lia'
import { FiArrowUpRight, FiTrash2 } from 'react-icons/fi'
import successIcon from '../../../assets/images/success-icon.png'
import failIcon from '../../../assets/images/fail-icon.png'
import pdfIcon from '../../../assets/images/pdf-icon.png'

// sass style
const Div = styled.div`
  .badge {
    color: var(--Primary-700);
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;

    border-radius: 1rem;
    border: 0.0625rem solid var(--Primary-200);
    background: var(--Primary-50);

    padding: 0.1875rem;

    &.red {
      border: 0.0625rem solid var(--BADGE-2-Border);
      background: var(--BADGE-2-Bg);
      color: var(--BADGE-2-Font);
    }
    &.pink {
      border: 0.0625rem solid var(--BADGE-12-Border);
      background: var(--BADGE-12-Bg);
      color: var(--BADGE-12-Font);
    }
    &.orange {
      border: 0.0625rem solid var(--BADGE-13-Border);
      background: var(--BADGE-13-Bg);
      color: var(--BADGE-13-Font);
    }
    &.green {
      border: 0.0625rem solid var(--BADGE-14-Border);
      background: var(--BADGE-14-Bg);
      color: var(--BADGE-14-Font);
    }
    &.purple {
      border: 0.0625rem solid var(--BADGE-15-Border);
      background: var(--BADGE-15-Bg);
      color: var(--BADGE-15-Font);
    }
    &.in-use {
      border: 0.0625rem solid var(--BADGE-16-Border);
      background: var(--BADGE-16-Bg);
      color: var(--BADGE-16-Font);
    }
  }
`
const InputContainer = styled.div`
  margin-bottom: 1.5rem;

  &.width-20 {
    width: 20%;
  }
  .input-group {
    display: flex;
    justify-content: space-between;
  }

  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .checkbox-container {
    display: flex;
    align-items: center;

    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
  }

  .w-input {
    width: 100%;
  }

  .w-30 {
    width: 30%;
  }

  .w-input-30 {
    width: 30%;
  }
`
const FlexContainer = styled.div`
  display: flex;
  width: 100%;

  .flex-item-1 {
    flex: 1;
  }

  &.justify-content-end {
    justify-content: flex-end;
  }
`
const CardContainer = styled.div`
  .card-headline {
    color: var(--Gray-900);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .card-sub-headline {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
  .value {
    color: var(--Gray-900);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;

    &.red {
      color: var(--Error-600);
    }
    &.green {
      color: var(--success-600);
    }
    &.orange {
      color: var(--warning-600);
    }
  }
`
const SearchContainer = styled.div`
  display: flex;
  .clear-button-container {
    display: flex;
    align-items: center;
  }
`
const BadgeSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .badge {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`
// init data
const initNotificationChecked = [
  {
    text: 'Email Notification',
    isChecked: false,
  },
  {
    text: 'In-App Notification',
    isChecked: true,
  },
]
const initMessageModal = {
  headline: '',
  message: '',
}
// init dropdown
const initDropdown = [
  {
    text: 'Custom Selection',
    value: 'Custom Selection',
  },
]
const initStatusDropdown = [
  {
    text: 'Wait for response',
    value: 'Wait for response',
  },
  {
    text: 'Submit',
    value: 'Accept',
  },
  {
    text: 'Read',
    value: 'Read',
  },
  {
    text: 'Decline',
    value: 'Decline',
  },
]

function Invitation() {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { t } = useTranslation()
  const employeeColumn = [
    {
      accessor: 'checker',
      disableSortBy: false,
    },
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('name'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('department'),
      accessor: 'department',
      disableSortBy: false,
    },
    {
      Header: t('position'),
      accessor: 'position',
      disableSortBy: false,
    },
    {
      Header: t('invited_date'),
      accessor: 'invited_date',
      disableSortBy: false,
    },
    {
      Header: t('response'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('response_date'),
      accessor: 'response_date',
      disableSortBy: false,
    },
    {
      Header: t('view_document'),
      accessor: 'view_document',
      disableSortBy: false,
    },
    {
      accessor: 'deleteAction',
      disableSortBy: false,
    },
  ]
  // store
  const { employeeList } = useSelector(selectMaster)
  const { documentAcknowledgementInfo, documentInviteList, page, pageCount, limit, isLoading, state } =
    useSelector(selectSharedService)
  // data
  const [notificationChecked, setNotificationChecked] = useState(initNotificationChecked)
  const [publishSelected, setPublishSelected] = useState('Custom Selection')
  const [employeeSelected, setEmployeeSelected] = useState('')
  const [inviteId, setInviteId] = useState('')
  const [inviteSelectedList, setInviteSelectedList] = useState([])
  // filter
  const [search, setSearch] = useState('')
  const [statusSelected, setStatusSelected] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  // table data
  const [inviteList, setInviteList] = useState([])
  // dropdown list
  const [employees, setEmployees] = useState([])
  // modal
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  // modal message
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const fetchData = useCallback(
    (filter) => {
      dispatch(getDocumentInviteList(filter))
    },
    [dispatch]
  )
  const deleteInviteItem = useCallback((id) => {
    setInviteId(id)
    setConfirmDeleteModal(true)
  }, [])
  const onSelectCheckBox = useCallback(
    (event, id) => {
      const isExisted = inviteSelectedList.includes(id)
      if (isExisted) {
        const newInviteSelectedList = inviteSelectedList.filter((v) => v !== id)
        setInviteSelectedList(newInviteSelectedList)
      } else setInviteSelectedList((prev) => [...prev, id])
    },
    [inviteSelectedList, setInviteSelectedList]
  )
  const isSelected = useCallback(
    (id) => {
      return inviteSelectedList.includes(id)
    },
    [inviteSelectedList]
  )

  useEffect(() => {
    const request = {
      limit: 1000,
      skip: 0,
    }
    dispatch(getEmployee(request))
  }, [])

  useEffect(() => {
    if (isLoading === false && state === 'SEND_DOC_NOTIFICATION.SUCCESS') {
      const request = { id: id, page: 1, pageSize: limit }
      fetchData(request)
      setInviteSelectedList([])
      setSuccessMessageModal({
        headline: 'Send notification successful',
        message: '',
      })
      setSuccessModal(true)
    }
    if (isLoading === false && state === 'DELETE_INVITE.SUCCESS') {
      setSuccessMessageModal({
        headline: 'Delete successful',
        message: '',
      })
      setSuccessModal(true)
    }
    if (isLoading === false && state === 'DELETE_INVITE.FAILURE') {
      setFailMessageModal({
        headline: 'This item can not be deleted',
        message: 'This document has been signed',
      })
      setFailModal(true)
    }
    if (isLoading === false && state === 'ADD_INVITE.FAILURE') {
      setFailMessageModal({
        headline: 'Can not add invite',
        message: 'This invite has already existed',
      })
      setFailModal(true)
    }
  }, [isLoading, state])

  useEffect(() => {
    const request = { id: id, page: 1, pageSize: limit }
    fetchData(request)
  }, [id])

  useEffect(() => {
    const newEmployeeList = documentInviteList?.map((v, i) => {
      const { status, id } = v
      const { first_name, last_name, department, position } = v.employee
      const customStatus = status === 'Accept' ? 'Submit' : status
      const isCustom = customStatus === 'Submit' || customStatus === 'Read' ? 'green' : 'red'
      const badgeStyle = customStatus === 'Wait for response' ? 'orange' : isCustom
      return {
        ...v,
        no: i + 1,
        checker: (
          <OutlineCheckbox
            checked={isSelected(id)}
            onChange={(e) => onSelectCheckBox(e, id)}
            disabled={customStatus !== 'Wait for response' && customStatus !== 'Not send'}
          />
        ),
        name: `${first_name} ${last_name}`,
        department: department?.name,
        position: position?.name,
        invited_date: DateTimeHHmmDisplay(v.createdAt),
        status: <div className={`badge ${badgeStyle}`}>{customStatus}</div>,
        response_date:
          v?.document_acknow?.type === 'Info'
            ? DateTimeHHmmDisplay(v?.opened_date)
            : DateTimeHHmmDisplay(v?.acknowledged_date),
        deleteAction: <FiTrash2 className="icon" size={20} onClick={() => deleteInviteItem(v.id)} />,
        view_document: v?.doc_signature ? (
          <img
            className="icon"
            src={pdfIcon}
            onClick={() => openPdfFile(v?.doc_signature)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                openPdfFile(v?.doc_signature)
              }
            }}
          />
        ) : (
          '-'
        ),
      }
    })
    setInviteList(newEmployeeList)
  }, [documentInviteList, deleteInviteItem, onSelectCheckBox, isSelected])

  useEffect(() => {
    const newEmployeeList = employeeList.map((v) => ({
      value: v.id,
      text: `${v.first_name} ${v.last_name},  ${v.department} ${v.position}`,
    }))
    setEmployees(newEmployeeList)
  }, [employeeList])

  useEffect(() => {
    const { email_noti, in_app_noti } = documentAcknowledgementInfo
    const newNotificationChecked = [
      {
        text: 'Email Notification',
        isChecked: email_noti,
      },
      {
        text: 'In-App Notification',
        isChecked: in_app_noti,
      },
    ]
    setNotificationChecked(newNotificationChecked)
  }, [documentAcknowledgementInfo])

  // filter
  useEffect(() => {
    if (statusSelected !== '') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[$or][${i}][$or][0][employee][first_name][$contains]`]: v.name,
        [`filters[$or][${i}][$or][1][employee][last_name][$contains]`]: v.name,
      }))
      const filter = {
        id: id,
        page: 1,
        pageSize: limit,
        status: statusSelected,
        multipleSearch,
      }
      fetchData(filter)
    }
  }, [statusSelected])

  const onChangePagination = (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$or][${i}][$or][0][employee][first_name][$contains]`]: v.name,
      [`filters[$or][${i}][$or][1][employee][last_name][$contains]`]: v.name,
    }))
    const request = { id: id, page: value.page, pageSize: limit, status: statusSelected, multipleSearch }
    fetchData(request)
  }
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`filters[$or][${i}][$or][0][employee][first_name][$contains]`]: v.name,
        [`filters[$or][${i}][$or][1][employee][last_name][$contains]`]: v.name,
      }))

      const filter = {
        id: id,
        page: 1,
        pageSize: limit,
        status: statusSelected,
        multipleSearch,
      }
      fetchData(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][$or][0][employee][first_name][$contains]`]: v.name,
      [`filters[$or][${i}][$or][1][employee][last_name][$contains]`]: v.name,
    }))

    const filter = {
      id: id,
      page: 1,
      pageSize: limit,
      status: statusSelected,
      multipleSearch,
    }
    fetchData(filter)
  }
  const onClearSearch = () => {
    const filter = {
      id: id,
      page: 1,
      pageSize: limit,
      status: '',
      multipleSearch: [],
    }
    fetchData(filter)
    clearFilter()
  }
  const clearFilter = () => {
    setSearch('')
    setStatusSelected('')
    setSearchFilters([])
  }

  // submit
  const addEmployee = () => {
    const request = {
      document_acknow: Number(id),
      employee: employeeSelected,
      is_noti: false,
    }

    dispatch(addInvite(request))
    setEmployeeSelected('')
  }
  const confirmDelete = () => {
    dispatch(deleteInvite(inviteId, id))
    setConfirmDeleteModal(false)
  }
  const publishSendNotification = () => {
    if (inviteSelectedList.length > 0) {
      const request = {
        document_acknow: Number(id),
        assingments: inviteSelectedList?.map((v) => Number(v)),
      }

      dispatch(sendDocNotification(request))
    }
  }

  // onChange
  const handleChangeCheckBox = (event, index) => {
    const { checked } = event.target
    const oldNotificationChecked = [...notificationChecked]
    oldNotificationChecked[index].isChecked = checked

    setNotificationChecked(oldNotificationChecked)
  }

  // general
  const openPdfFile = (url) => {
    window.open(url, '_blank')
  }

  return (
    <Div>
      <FlexContainer>
        <InputContainer className="flex-item-1 mr-12-px">
          <div className="input-header">{t('document_to_publish')}</div>
          <Dropdown
            className="w-input"
            disabled={true}
            optionList={initDropdown}
            value={publishSelected}
            onChange={(value) => setPublishSelected(value)}
          />
        </InputContainer>

        <InputContainer className="flex-item-1">
          <div className="input-header">{t('select_employee')}</div>
          <div className="input-group">
            <AutoComplete
              className="w-input mr-12-px"
              optionList={employees}
              value={employeeSelected}
              onChange={(value) => setEmployeeSelected(value)}
            />

            <Button className="w-input-30" onClick={addEmployee}>
              {t('add_to_list')}
            </Button>
          </div>
        </InputContainer>
      </FlexContainer>

      <InputContainer>
        <div className="input-header">{t('notification_settings')}</div>

        {notificationChecked.map((v, i) => (
          <div className="checkbox-container" key={v.text}>
            <OutlineCheckbox
              className="mr-12-px"
              checked={v.isChecked}
              onChange={(e) => handleChangeCheckBox(e, i)}
              disabled={true}
            />
            <div>{v.text}</div>
          </div>
        ))}
      </InputContainer>

      <FlexContainer className="mb-2-rem">
        <Card className="width-30 mr-12-px">
          <CardContainer>
            <div className="card-headline">Total Invited (Employee)</div>
            <div className="value">{documentAcknowledgementInfo?.total?.total_invite}</div>
          </CardContainer>
        </Card>
        <Card className="width-30 mr-12-px">
          <CardContainer>
            <div className="card-headline">Total Submitted (Employee)</div>
            <div className="value green">{documentAcknowledgementInfo?.total?.total_submit}</div>
          </CardContainer>
        </Card>
        <Card className="width-30 mr-12-px">
          <CardContainer>
            <div className="card-headline">Total Decline (Employee)</div>
            <div className="value red">{documentAcknowledgementInfo?.total?.total_decline}</div>
          </CardContainer>
        </Card>
        <Card className="width-30">
          <CardContainer>
            <div className="card-headline">Wait for response (Employee)</div>
            <div className="value orange">{documentAcknowledgementInfo?.total?.total_wait}</div>
          </CardContainer>
        </Card>
      </FlexContainer>

      <div className="divider mb-24-px"></div>

      <SearchContainer className="search-container mb-1-rem">
        <InputContainer className="mr-12-px">
          <div className="input-header">{t('search')}</div>
          <SearchText
            placeholder={t('search')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSubmit={onSearch}
            onClear={() => setSearch('')}
          />
        </InputContainer>
        <InputContainer className="width-20 mr-12-px">
          <div className="input-header">{t('status')}</div>
          <Dropdown
            optionList={initStatusDropdown}
            value={statusSelected}
            onChange={(value) => setStatusSelected(value)}
          />
        </InputContainer>
        <div className="clear-button-container">
          <ButtonOutline onClick={onClearSearch}>{t('clear')}</ButtonOutline>
        </div>
      </SearchContainer>
      <BadgeSearchContainer>
        {searchFilters.map((v) => (
          <div className="badge" key={v.key}>
            <span className="name">{v.name}</span>{' '}
            <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
          </div>
        ))}
      </BadgeSearchContainer>

      <FlexContainer className="justify-content-end mb-24-px">
        <Button append={<FiArrowUpRight size={20} />} onClick={publishSendNotification}>
          Publish
        </Button>
      </FlexContainer>

      <Table
        data={inviteList}
        columns={employeeColumn}
        onStateChange={onChangePagination}
        page={page}
        pageCount={pageCount}
      />

      {/* Dialog */}
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={confirmDelete}
        title={'Do you want to cancel sending this document to this employee?'}
        description=""
      />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => setSuccessModal(false)}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default Invitation
