import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  createBusinessDocument,
  createCustomer,
  editBusinessDocument,
  editCustomer,
  getBusinessDocById,
  getCompanyInfoById,
  getDocumentSettingByCompanyId,
} from 'redux/actions/document'
import { selectDocument } from 'redux/selectors'
import styled from 'styled-components'
import { generateId } from 'utils/helper'
import CreateDocLayout from '../custom-view/CreateDocLayout'
import DialogDoc from '../custom-view/DialogDoc'

export default function TravelExpenseClaim({ previewType, currentPage, onCloseDialog, filter, itemSelected }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const docId = searchParams?.get('id')
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { businessDocumentData, companyData, documentSettingInfo } = useSelector(selectDocument)

  // for create data
  const docTitle = 'ใบเบิกค่าเดินทาง'
  const docType = 'travel_expense_claim'
  const navigatePath = '/document/transportation?doctype=travel_expense_claim'

  const HEADER_LIST = [
    [
      [
        {
          id: 'hd01',
          position: 'left',
          type: 'search_employee',
          title: 'Employee id',
          placeholder: 'Employee id',
          sub_type: 'employee_id',
        },
        {
          id: 'hd02',
          position: 'left',
          type: 'search_employee',
          title: 'Employee Name',
          placeholder: 'Employee Name',
          sub_type: 'employee_name',
        },
        {
          id: 'hd03',
          position: 'left',
          type: 'input_text', //search_customer
          title: 'ตำแหน่ง',
          placeholder: 'ตำแหน่ง',
          sub_type: 'position',
        },
        {
          id: 'hd04',
          position: 'left',
          type: 'input_text',
          title: 'แผนก',
          placeholder: 'แผนก',
        },
        // {
        //   id: 'hd05',
        //   position: 'left',
        //   type: 'input_text',
        //   title: 'เบอร์โทรศัพท์',
        //   placeholder: '081-111-1111',
        // },
        // {
        //   id: 'hd06',
        //   position: 'right',
        //   type: 'input_text_area',
        //   title: 'Address',
        //   placeholder: 'Address details',
        // },
      ],
    ],
    [
      { id: 'hd07', type: 'input_date', title: 'วันที่เริ่มต้น' },
      { id: 'hd08', type: 'input_date', title: 'วันที่สิ้นสุด' },
    ],
  ]
  const BODY_LIST = [
    [
      {
        id: 'bd03',
        type: 'table',
        title: '',
        class_name_create: 'width_100',
        class_name_preview: 'width_100',
        value: {
          header: [
            { id: 'bd03-hd-1', type: 'text', title: 'No.' },
            { id: 'bd03-hd-2', type: 'text', title: 'วันที่' },
            { id: 'bd03-hd-3', type: 'text', title: 'เดินทางจาก' },
            {
              id: 'bd03-hd-4',
              type: 'text',
              title: 'จุดหมาย',
              // class_name_create: 'width_500px',
              // class_name_preview: 'width_300px',
            },
            { id: 'bd03-hd-5', type: 'text', title: 'วิธีการเดินทาง' },
            { id: 'bd03-hd-6', type: 'text', title: 'หมายเหตุ' },
            { id: 'bd03-hd-7', type: 'text', title: 'จำนวนเงิน' },
            { id: 'bd03-hd-8', type: 'action', title: '' },
          ],
          body: [
            [
              { id: 'bd03-bd-1', type: 'run_number' },
              { id: 'bd03-bd-2', type: 'input_date', placeholder: 'Select Date' },
              { id: 'bd03-bd-3', type: 'input_text', placeholder: 'Details' },
              {
                id: 'bd03-bd-4',
                type: 'input_text',
                placeholder: 'Details',
                // class_name_create: 'width_500px',
                // class_name_preview: 'width_300px',
              },
              { id: 'bd03-bd-5', type: 'input_text', placeholder: 'หน่วย' },
              { id: 'bd03-bd-6', 
                type: 'input_dropdown', 
                placeholder: 'Select', 
                  data_list: [
                  { text: 'รถบรรทุก', value: 'truck' },
                  { text: 'รถยนต์', value: 'car' },
                  { text: 'อื่นๆ', value: 'other' },
                ],
                value: { text: 'cash' },
                view_type: 'hide_show', 
              },
              { id: 'bd03-bd-7', type: 'input_text', placeholder: '0', sub_type: 'number', step: '1' },
              { id: 'bd03-bd-8', type: 'button_delete' },
            ],
          ],
        },
      },
    ],
  ]
  const FOOTER_LIST = [
    { id: 'ft01', position: 'left', type: 'input_text_area', title: 'หมายเหตุ' },
    { id: 'ft02', position: 'right', type: 'sum_text', value: {text: 1000} },
  ]

  const [isBindingDataDone, setIsBindingDataDone] = useState(false)
  const [previewStage, setPreviewStage] = useState(previewType || 'close')
  const [headerList, setHeaderList] = useState(HEADER_LIST)
  const [bodyList, setBodyList] = useState(BODY_LIST)
  const [footerList, setFooterList] = useState(FOOTER_LIST)
  const [documentId, setDocumentId] = useState()
  //optional
  const [companyDocData, setCompanyDocData] = useState()
  const [customerData, setCustomerData] = useState()
  const [totalPrice, setTotalPrice] = useState()

  useEffect(() => {
    dispatch(getCompanyInfoById())
    dispatch(getDocumentSettingByCompanyId())
  }, [])

  useEffect(() => {
    updateFieldByCustomerData()
  }, [customerData])

  useEffect(() => {
    if (docId || itemSelected?.id) {
      let tmpId = itemSelected?.id || docId
      setDocumentId(tmpId)
    }
  }, [docId, itemSelected])

  useEffect(() => {
    if (documentId && documentId != 'undefined') {
      dispatch(getBusinessDocById(documentId))
    }
  }, [documentId])

  useEffect(() => {
    if (documentId && businessDocumentData?.id) {
      bindEditData()
    }
  }, [businessDocumentData])

  useEffect(() => {
    let tmpCompanyData

    if (!documentId) {
      tmpCompanyData = {
        seller_logo: documentSettingInfo[docType]?.company_logo,
        seller_company_name: companyData?.company_name_TH,
        seller_address: companyData?.address,
        seller_tax_id: companyData?.tex_id,
        customer_company_name: customerData?.name,
        customer_address: customerData?.address,
        customer_phone: customerData?.phone,
        customer_tax_id: customerData?.tax_id,
      }
    } else {
      if (businessDocumentData) {
        tmpCompanyData = {
          seller_logo: businessDocumentData?.company_logo,
          seller_company_name: businessDocumentData?.company?.company_name_TH,
          seller_address: businessDocumentData?.company?.address,
          seller_tax_id: businessDocumentData?.company?.tex_id,
          customer_company_name: customerData?.name,
          customer_address: customerData?.address,
          customer_phone: customerData?.phone,
          customer_tax_id: customerData?.tax_id,
          doc_id: businessDocumentData?.doc_id,
        }
      }
    }

    setCompanyDocData(tmpCompanyData)
  }, [companyData, documentId, businessDocumentData, customerData, documentSettingInfo])

  const bindEditData = () => {
    setIsBindingDataDone(false)
    //bindHeader
    let tmpHeaderList = [...headerList]
    for (let colIndex = 0; colIndex < tmpHeaderList.length; colIndex++) {
      let colItem = tmpHeaderList[colIndex]
      //  col left
      if (colIndex == 0) {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]
          for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
            let dataItem = rowItem[dataIndex]
            if (dataItem?.type == 'search_customer') {
              let tmpText = ''
              if (dataItem?.sub_type == 'customer_id') {
                tmpText = businessDocumentData?.customer?.customer_id
              } else if (dataItem?.sub_type == 'customer_name') {
                tmpText = businessDocumentData?.customer?.name
              } else if (dataItem?.sub_type == 'tax_id') {
                tmpText = businessDocumentData?.customer?.tax_id
              }
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                customer_data: businessDocumentData?.customer,
                text: tmpText,
              }
            } else if (dataItem?.id == 'hd04') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                text: businessDocumentData?.customer?.office || '',
              }
            } else if (dataItem?.id == 'hd05') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                text: businessDocumentData?.customer?.phone || '',
              }
            } else if (dataItem?.id == 'hd06') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                text: businessDocumentData?.customer?.address || '',
              }
            }
          }
        }
      }
      //  col right
      else if (colIndex == 1) {
        for (let dataIndex = 0; dataIndex < colItem.length; dataIndex++) {
          let dataItem = colItem[dataIndex]

          if (dataItem?.id == 'hd07') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.doc_date,
            }
          } else if (dataItem?.id == 'hd08') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.delivery_date,
            }
          }
        }
      }
    }

    //bindBody
    let tmpBodyList = JSON.parse(JSON.stringify(bodyList))
    let tmpTableBody
    let tmpSumTotalPrice = 0
    for (let rowIndex = 0; rowIndex < tmpBodyList.length; rowIndex++) {
      let rowItem = tmpBodyList[rowIndex]
      for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
        let dataItem = rowItem[dataIndex]

        if (dataItem?.type == 'table') {
          tmpTableBody = dataItem?.value?.body || []
          let tmpProductList = businessDocumentData?.product_list || []

          let prototypeTableRow = tmpTableBody[0]
          tmpTableBody = []
          for (let productIndex = 0; productIndex < tmpProductList.length; productIndex++) {
            let productItem = tmpProductList[productIndex]
            let tmpTableRowItem = prototypeTableRow

            for (let i = 0; i < tmpTableRowItem.length; i++) {
              tmpTableRowItem[i].id = generateId(6)

              if (prototypeTableRow[i]?.placeholder) {
                tmpTableRowItem[i].placeholder = prototypeTableRow[i].placeholder
              }

              if (i == 1) {
                tmpTableRowItem[i].value = { text: productItem?.sku }
              } else if (i == 2) {
                tmpTableRowItem[i].value = {
                  text: productItem?.details,
                }
              } else if (i == 3) {
                tmpTableRowItem[i].value = {
                  text: productItem?.packing_size,
                }
              } else if (i == 4) {
                tmpTableRowItem[i].value = { text: productItem?.quantity }
              }
            }

            tmpTableBody.push(tmpTableRowItem)
          }
          tmpBodyList[rowIndex][dataIndex].value.body = tmpTableBody
        }
      }
    }

    //footer
    let tmpFooterList = JSON.parse(JSON.stringify(footerList))
    for (let dataIndex = 0; dataIndex < tmpFooterList.length; dataIndex++) {
      let dataItem = tmpFooterList[dataIndex]
      if (dataItem?.id == 'ft01') {
        tmpFooterList[dataIndex].value = { text: businessDocumentData?.note || '' }
      } 
      else if (dataItem?.id == 'ft02') {
        let tmpCalVat = calculateVat(businessDocumentData?.vat, tmpSumTotalPrice)
        let tmpSummationPrice = summationPrice(tmpCalVat, tmpSumTotalPrice)

        tmpFooterList[dataIndex].value = {
          ...tmpFooterList[dataIndex]?.value,
          is_checked: businessDocumentData?.vat,
          price_value_before_tax: tmpSumTotalPrice?.toFixed(2) || '0.00',
          price_select_vat: tmpCalVat?.toFixed(2) || '0.00',
          price_summation: tmpSummationPrice?.toFixed(2) || '0.00',
        }
      }
    }
    setHeaderList(tmpHeaderList)
    setBodyList(tmpBodyList)
    setCustomerData(businessDocumentData?.customer)
    setFooterList(tmpFooterList)
    setTotalPrice(tmpSumTotalPrice)
    setIsBindingDataDone(true)
  }

  const calTotalInRowTable = (e) => {
    let rowIndex = e?.row_index
    let colIndex = e?.col_index
    let rowTableIndex = e?.table_row_index
    if (rowIndex != undefined && colIndex != undefined && rowTableIndex != undefined) {
      let tmpTableValue = e?.data[rowIndex][colIndex]?.value

      // let tmpSumValue =
      //   parseFloat(tmpTableValue?.body[rowTableIndex][3]?.value?.text || 0) *
      //   parseFloat(tmpTableValue?.body[rowTableIndex][4]?.value?.text || 0)

      // tmpTableValue.body[rowTableIndex][5].value = { text: tmpSumValue.toFixed(2) }

      let tmpBodyList = JSON.parse(JSON.stringify(bodyList))
      tmpBodyList[rowIndex][colIndex].value = tmpTableValue
      setBodyList(tmpBodyList)
      // calSumTotalFromTable(tmpTableValue?.body)
    }
    //  else {
    //   calSumTotalFromTable(e?.data[rowIndex][colIndex]?.value?.body)
    // }
  }

  // const calSumTotalFromTable = (tableBodyList) => {
  //   if (tableBodyList?.length > 0) {
  //     let tmpSumTotal = 0

  //     for (let rowIndex = 0; rowIndex < tableBodyList.length; rowIndex++) {
  //       let rowItem = tableBodyList[rowIndex]
  //       tmpSumTotal = tmpSumTotal + parseFloat(rowItem[5]?.value?.text)
  //     }

  //     let tmpFooterList = [...footerList]
  //     for (let i = 0; i < tmpFooterList.length; i++) {
  //       let item = tmpFooterList[i]

  //       // if (item?.type == 'input_calculate_price') {
  //       //   let tmpItemValue = item?.value
  //       //   let tmpCalVat = calculateVat(tmpItemValue?.is_checked, tmpSumTotal)
  //       //   let tmpSummationPrice = summationPrice(tmpCalVat, tmpSumTotal)
  //       //   tmpItemValue.price_value_before_tax = (tmpSumTotal || 0)?.toFixed(2)
  //       //   tmpItemValue.price_select_vat = (tmpCalVat || 0)?.toFixed(2)
  //       //   tmpItemValue.price_summation = (tmpSummationPrice || 0)?.toFixed(2)
  //       //   tmpFooterList[i].value = tmpItemValue

  //       //   break
  //       // }
  //     }
  //     setTotalPrice(tmpSumTotal)
  //     setFooterList(tmpFooterList)
  //   }
  // }

  // const summationPrice = (calVat, dataTotalPrice) => {
  //   let tmpCalVat = parseFloat(calVat)
  //   let tmpDataTotalPrice = parseFloat(dataTotalPrice)
  //   return tmpCalVat + tmpDataTotalPrice
  // }

  // const calculateVat = (isVat, dataTotalPrice) => {
  //   let tmpPriceCalVat = 0
  //   if (isVat) {
  //     tmpPriceCalVat = (dataTotalPrice || 0) * 0.07
  //   }
  //   return parseFloat(tmpPriceCalVat)
  // }

  const updateFooterList = (footerDataList) => {
    let tmpFooterList = [...footerDataList]
    for (let i = 0; i < tmpFooterList.length; i++) {
      let item = tmpFooterList[i]

      if (item?.type == 'input_calculate_price') {
        let tmpItemValue = item?.value

        let tmpCalVat = calculateVat(tmpItemValue?.is_checked, totalPrice)
        tmpItemValue.price_select_vat = tmpCalVat?.toFixed(2)
        tmpItemValue.price_summation = summationPrice(tmpCalVat, totalPrice)

        tmpFooterList[i].value = tmpItemValue
        break
      }
    }
    setFooterList(tmpFooterList)
  }

  const updateFieldByCustomerData = () => {
    let tmpHeaderList = [...headerList]
    let tmpHeaderLeft = tmpHeaderList[0]

    for (let rowIndex = 0; rowIndex < tmpHeaderLeft.length; rowIndex++) {
      let rowItem = tmpHeaderLeft[rowIndex]
      for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
        let dataItem = rowItem[dataIndex]

        if (dataItem?.type == 'search_customer') {
          let tmpText = ''
          if (dataItem?.sub_type == 'customer_id') {
            tmpText = customerData?.customer_id
          } else if (dataItem?.sub_type == 'customer_name') {
            tmpText = customerData?.name
          } else if (dataItem?.sub_type == 'tax_id') {
            tmpText = customerData?.tax_id
          }
          tmpHeaderList[0][rowIndex][dataIndex].value = {
            customer_data: customerData,
            text: tmpText,
          }
        } else if (dataItem?.id == 'hd04') {
          tmpHeaderList[0][rowIndex][dataIndex].value = { text: customerData?.office }
        } else if (dataItem?.id == 'hd05') {
          tmpHeaderList[0][rowIndex][dataIndex].value = { text: customerData?.phone }
        } else if (dataItem?.id == 'hd06') {
          tmpHeaderList[0][rowIndex][dataIndex].value = { text: customerData?.address }
        }
      }
    }

    setHeaderList(tmpHeaderList)
  }

  const getHeaderValues = () => {
    let tmpValues = {}
    let tmpHeaderList = [...headerList]
    let tmpUpdateCustomerData = {}

    for (let colIndex = 0; colIndex < headerList.length; colIndex++) {
      let colItem = headerList[colIndex]
      if (colIndex == 0) {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]

          for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
            let dataItem = rowItem[dataIndex]

            if (dataItem?.id == 'hd01') {
              tmpUpdateCustomerData.customer_id = dataItem?.value?.text || ''
              if (dataItem?.value?.customer_data?.id) {
                tmpValues.customer_id = dataItem.value.customer_data.id
              }
            } else if (dataItem?.id == 'hd02') {
              tmpUpdateCustomerData.name = dataItem?.value?.text || ''
            } else if (dataItem?.id == 'hd03') {
              tmpUpdateCustomerData.tax_id = dataItem?.value?.text || ''
            } else if (dataItem?.id == 'hd04') {
              tmpUpdateCustomerData.office = dataItem?.value?.text || ''
            } else if (dataItem?.id == 'hd05') {
              tmpUpdateCustomerData.phone = dataItem?.value?.text || ''
            } else if (dataItem?.id == 'hd06') {
              tmpUpdateCustomerData.address = dataItem?.value?.text || ''
            }

            if (!dataItem?.value?.text && dataItem?.id != 'hd05') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                ...tmpHeaderList[colIndex][rowIndex][dataIndex]?.value,
                is_error: true,
              }
            }
          }
        }
        tmpValues.update_customer = tmpUpdateCustomerData
      } else {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]

          if (rowItem?.id == 'hd07' && rowItem?.value?.text) {
            tmpValues.doc_date = rowItem.value.text
          } else if (rowItem?.id == 'hd08' && rowItem?.value?.text) {
            tmpValues.delivery_date = rowItem.value.text
          } 
          // else if (rowItem?.id == 'hd09' && rowItem?.value?.id) {
          //   tmpValues.list_creator_employee_id = rowItem.value.id
          // } else if (rowItem?.id == 'hd10' && rowItem?.value?.text) {
          //   tmpValues.delivery_date = rowItem.value.text
          // }

          if (!rowItem?.value?.text ) { // && rowItem?.id != 'hd09'
            tmpHeaderList[colIndex][rowIndex].value = {
              ...tmpHeaderList[colIndex][rowIndex]?.value,
              is_error: true,
            }
          } 
          // else if (rowItem?.id == 'hd09' && !rowItem?.value?.id) {
          //   tmpHeaderList[colIndex][rowIndex].value = {
          //     ...tmpHeaderList[colIndex][rowIndex]?.value,
          //     is_error: true,
          //   }
          // }
        }
      }
    }
    setHeaderList(tmpHeaderList)
    return tmpValues
  }
  const getProductList = () => {
    let tmpBodyList = JSON.parse(JSON.stringify(bodyList))
    let tmpBodyTable
    let tmpProductList = []

    for (let rowIndex = 0; rowIndex < tmpBodyList.length; rowIndex++) {
      let rowItem = tmpBodyList[rowIndex]
      for (let colIndex = 0; colIndex < rowItem.length; colIndex++) {
        let colItem = rowItem[colIndex]

        if (colItem?.type == 'table') {
          tmpBodyTable = colItem?.value?.body
          if (tmpBodyTable?.length > 0) {
            for (let tableRowIndex = 0; tableRowIndex < tmpBodyTable.length; tableRowIndex++) {
              let tableRowItem = tmpBodyTable[tableRowIndex]
              let productItem = {}

              for (let tableColIndex = 0; tableColIndex < tableRowItem.length; tableColIndex++) {
                let tableColItem = tableRowItem[tableColIndex]

                if (tableColIndex == 1 && tableColItem?.value?.text) {
                  productItem.sku = tableColItem.value.text
                } else if (tableColIndex == 2 && tableColItem?.value?.text) {
                  productItem.details = tableColItem.value.text
                } else if (tableColIndex == 3 && tableColItem?.value?.text) {
                  productItem.packing_size = tableColItem.value.text
                } else if (tableColIndex == 4 && tableColItem?.value?.text) {
                  productItem.quantity = parseFloat(tableColItem.value.text)
                }

                if (tableColIndex == 1 || tableColIndex == 2 || tableColIndex == 3 || tableColIndex == 4) {
                  if (!tableColItem?.value?.text) {
                    tmpBodyTable[tableRowIndex][tableColIndex].value = {
                      ...tmpBodyTable[tableRowIndex][tableColIndex]?.value,
                      is_error: true,
                    }
                  }
                }
              }

              tmpProductList.push(productItem)
            }
          }
          tmpBodyList[rowIndex][colIndex].value = { ...tmpBodyList[rowIndex][colIndex]?.value, body: tmpBodyTable }

          setBodyList(tmpBodyList)
          break
        }
      }
    }

    return tmpProductList
  }
  const getFooterValues = () => {
    let tmpFooterValue = {
      note: '',
    }
    for (let i = 0; i < footerList.length; i++) {
      let item = footerList[i]
      if (item?.id == 'ft01' && item?.value?.text) {
        tmpFooterValue.note = item.value.text
      } 
    }
    return tmpFooterValue
  }

  const getIsSameCustomer = () => {
    let isSameCustomer = false
    let headerLeft = [...headerList[0]]
    let sumSameCustomer = 0

    for (let rowIndex = 0; rowIndex < headerLeft.length; rowIndex++) {
      let rowItem = headerLeft[rowIndex]
      for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
        let dataItem = rowItem[dataIndex]

        if (dataItem?.id == 'hd01' && dataItem?.value?.text && dataItem?.value?.text == customerData?.customer_id) {
          sumSameCustomer++
        } else if (dataItem?.id == 'hd02' && dataItem?.value?.text && dataItem?.value?.text == customerData?.name) {
          sumSameCustomer++
        } else if (dataItem?.id == 'hd03' && dataItem?.value?.text && dataItem?.value?.text == customerData?.tax_id) {
          sumSameCustomer++
        }
      }
    }

    if (sumSameCustomer > 0) {
      isSameCustomer = true
    }
    return isSameCustomer
  }

  const updateCustomer = async (isSameCustomer, updatedCustomerData) => {
    let customerId = customerData?.id
    if (customerId && isSameCustomer) {
      await dispatch(editCustomer({ data: updatedCustomerData }, customerId))
      return -1
    } else {
      const res = await dispatch(createCustomer({ data: { ...updatedCustomerData, company_id: company_info?.id } }))
      return res?.data?.id || -1
    }
  }

  const handelCloseDialog = () => {
    setPreviewStage('close')
    if (onCloseDialog) {
      onCloseDialog()
    }
  }

  const onBtnPreviewClicked = () => {
    setPreviewStage('preview_create')
  }
  const onBtnSaveClicked = async () => {
    let tmpSendData = {
      doc_type: docType,
      creator_id: employee_info?.id,
      company_id: company_info?.id,
    }
    let tmpHeaderValues = getHeaderValues()
    let tmpBodyValueList = getProductList()
    let tmpFooterValues = getFooterValues()

    if (
      !tmpHeaderValues?.doc_date ||
      !tmpHeaderValues?.delivery_date ||
      !tmpHeaderValues?.update_customer?.customer_id ||
      !tmpHeaderValues?.update_customer?.name ||
      !tmpHeaderValues?.update_customer?.address ||
      !tmpHeaderValues?.update_customer?.tax_id ||
      !tmpHeaderValues?.update_customer?.office
    ) {
      return
    }

    if (tmpBodyValueList?.length == 0) {
      return
    }

    for (let rowIndex = 0; rowIndex < tmpBodyValueList.length; rowIndex++) {
      let rowItem = tmpBodyValueList[rowIndex]
      if (!rowItem?.quantity || !rowItem?.packing_size || !rowItem?.details || !rowItem?.sku) {
        return
      }
    }

    let isSameCustomer = getIsSameCustomer()
    const customerRes = await updateCustomer(isSameCustomer, tmpHeaderValues?.update_customer)
    delete tmpHeaderValues.update_customer
    if (customerRes != -1) {
      tmpSendData.customer_id = customerRes
    }

    let uploadData = { ...tmpSendData, ...tmpHeaderValues, product_list: tmpBodyValueList, ...tmpFooterValues }

    tmpSendData = {
      type: docType,
      data: uploadData,
    }

    if (!documentId) {
      await dispatch(createBusinessDocument(tmpSendData))
      navigate(navigatePath)
    } else {
      dispatch(editBusinessDocument(tmpSendData, documentId))
    }
  }

  return (
    <Styled>
      {(previewStage == 'close' || previewStage == 'preview_create') && (
        <CreateDocLayout
          isEdit={documentId ? true : false}
          title={docTitle}
          headerList={headerList}
          bodyList={bodyList}
          footerList={footerList}
          onChange={(e) => {
            if (e?.layout_type == 'header_left' || e?.layout_type == 'header_right') {
              setHeaderList(e?.data)
              if (e?.customer_data) {
                setCustomerData(e.customer_data)
              }
            } else if (e?.layout_type == 'body') {
              if (e?.view_type == 'table') {
                calTotalInRowTable(e)
              } else {
                setBodyList(e?.data)
              }
            } else if (e?.layout_type == 'footer') {
              updateFooterList(e?.data)
            }
          }}
          onBtnSaveClicked={onBtnSaveClicked}
          onBtnPreviewClicked={onBtnPreviewClicked}
        />
      )}

      {(previewStage == 'request_approve' ||
        previewStage == 'preview' ||
        previewStage == 'print' ||
        previewStage == 'download_pdf' ||
        previewStage == 'share' ||
        previewStage == 'preview_create') && (
        <DialogDoc
          docType={docType}
          docTitle={docTitle}
          headerList={headerList}
          bodyList={bodyList}
          footerList={footerList}
          companyDocData={companyDocData}
          previewType={previewStage}
          onCloseClicked={handelCloseDialog}
          docId={documentId}
          isBindingDataDone={isBindingDataDone}
          copyNumber={businessDocumentData?.number_of_copy || documentSettingInfo?.delivery_appointment_note?.number_of_copy}
          currentPage={currentPage}
          filter={filter}
          itemSelected={itemSelected}
          rubberStamp={documentSettingInfo?.delivery_appointment_note?.rubber_stamp}
          signatureList={documentSettingInfo?.delivery_appointment_note?.signature_list}
        />
      )}
    </Styled>
  )
}
const Styled = styled.div`
  position: relative;
`
