import styled from 'styled-components'

import dayjs from 'dayjs'

import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDailySummary, getActionPlan, updateDailySummary, updateActionPlan,getMeeting } from '../../redux/actions/dailysummary'

import { selectDailySummary } from '../../redux/selectors'


import Button from '../../components/form/button/Button'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import successIcon from '../../assets/images/success-icon.png'


import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import SidePanel from '../../components/common/SidePanel'

import ModalDailySummary from '../../pages/my-calendar/ModalDailySummary'
import NewDailySummary from '../../pages/my-calendar/NewDailySummary'
import ViewDailySummary from '../../pages/my-calendar/ViewDailySummary'
import EditDailySummary from '../../pages/my-calendar/EditDailySummary'


import ModalActionPlan from '../../pages/my-calendar/ModalActionPlan'
import NewActionPlan from '../../pages/my-calendar/NewActionPlan'
import ViewActionPlan from '../../pages/my-calendar/ViewActionPlan'
import EditActionPlan from '../../pages/my-calendar/EditActionPlan.js'

import NewMeeting from '../../pages/my-calendar/NewMeeting'
import MeetingCalendar from '../../pages/my-calendar/MeetingCalendar'



import ModalMeeting from '../../pages/my-calendar/ModalMeeting'
import ModalMeetingAttendances from '../../pages/my-calendar/ModalMeetingAttendances'

import ModalCalendarAll from '../../pages/my-calendar/ModalCalendarAll'
import ModalCreateEvent from '../../pages/my-calendar/ModalCreateEvent'

import { useTranslation } from 'react-i18next'

import { log_ } from '../../utils/apiLog'

const DivMain = styled.div`
.content-title {
  font-size: 36px;
  font-weight: 600;
  color: var(--Gray-900);
}
.border_header{
  border-bottom: 1px solid rgb(234, 236, 240);
  margin-bottom: 21px;
  margin-top: 21px;
}
.content-fillter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  /*background-color: var(--Gray-50);*/
  border-radius: 8px;
  margin-bottom: 21px;
  padding: 10px;

  .search-wrapper {
    margin-right: 12px;
  }

  .filter-list {
    flex-basis: 100%;
    margin-top: 24px;
  }

  .group_filter {
    display: flex;
    align-items: center;
  }
}
.container {
  position: relative;
  display: inline-block;
}
.dropdown_none{
  display : none;
}
.dropdown {
  position: absolute;
  top: 100%;
  /* left: 0px; */
  right: 0px;
  width: 200px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: rgba(0, 0, 0, 0.14) 0px 16px 24px 2px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  font-size: 16px;
}
.div_ul{
  list-style: none;
  padding: 0;
  margin: 0;
  .div_li{
    padding: 8px 12px;
    :hover {
      cursor: pointer;
      background-color: #F9FAFB;
      border-radius: 8px;
    }
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
li {
  padding: 8px 12px;
}

li:hover {
  cursor: pointer;
  background-color: #F9FAFB;
  border-radius: 8px;
}

.content-fillter-date{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 10px;

  .search-wrapper {
    margin-right: 12px;
  }

  .filter-list {
    flex-basis: 100%;
    margin-top: 24px;
  }

  .group_filter {
    display: flex;
    align-items: center;
  }
}

.wrp-tab-menu {
  /*margin-bottom: 1rem;*/
  display: flex;
  justify-content: end;
  .box_calendar {
    border: 1px solid rgba(224, 224, 224, 1);
    box-shadow: var(--Shadow-xs);
    border-radius: 10px;
  }
  .btn-menu-style {
    border: none;
    border-bottom: 2px solid transparent;
    padding: 0px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
  }

  .menu-active {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-radius: 10px;
    background-color: #7F56D9;
    color: #FFFFFF;
    height: 40px;
  }
}
.css_date_pagitation{
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  .text_date{
    margin: 0px 10px;
  }
  .css_arrow{
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.resp-table {
  width: 100%;
  display: table;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid #EAECF0;
}
.resp-table-body {
  display: table-row-group;
}
.resp-table-row {
  display: table-row;
}
.topicDay-cell {
  float: left;
  height: 60px;
  width: 14.28571428571429%;
  text-align: center;
  padding: 10px;
  background-color: #F2F4F7;
  color: #475467;
  font-size: 16px;
}
.table-body-cell {
  float: left;
  height: 120px;
  width: 14.28571428571429%;
  padding: 10px;
  cursor : pointer;
    :hover {
        background-color: #F2F4F7;
    }
}
.border_right{
  border-right: 1px solid #EAECF0;
}
.border_bottom{
  border-bottom: 1px solid #EAECF0;
}
.css_holiday {
  background-color: #F2F4F7;
}
.css_block_date_now {
  background-color: #E5E7EB;
}
.css_day{
  /*
  margin-top: 0.33em;
  width: 1.72em;
  height: 1.72em;
  line-height: 1.72;
  text-align: center;
  border-radius: 50%;
  cursor: default;
  margin-right: auto;
  margin-left: auto;
  color:#000000;
  */
  width: 16px;
  height: 16px;
  line-height: 1.72;
  text-align: center;
  cursor: default;
  margin-right: auto;
  margin-left: auto;
  color:#000000;
  font-size: 10px;
}
.css_date_now {
  width: 16px;
  height: 16px;
  line-height: 1.72;
  text-align: center;
  cursor: default;
  margin-right: auto;
  margin-left: auto;
  color: rgb(255, 255, 255);
  font-size: 10px;
  background: rgb(105, 65, 198);
  border-radius: 48%;
}
.css_day_not_month{
  width: 16px;
  height: 16px;
  line-height: 1.72;
  text-align: center;
  cursor: default;
  margin-right: auto;
  margin-left: auto;
  color:#BDBDBD;
  font-size: 10px;
}

.css_ActionPlan{
  margin-top: 2px;
  cursor: pointer;
  height: 20px;
  background-color: #F0F9FF;
  border: 1px solid #0BA5EC;
  border-radius: 8px;
  color: #026AA2;
  font-size: 10px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  :hover {
    background-color: #B9E6FE;
  }
  :active {
    background-color: #0BA5EC;
    border: 1px solid #0BA5EC;
    color: #FFFFFF;
  }
}
.css_DailySummary{
  margin-top: 2px;
  cursor: pointer;
  height: 20px;
  background-color: #F9F5FF;
  border: 1px solid #7F56D9;
  border-radius: 8px;
  color: #6941C6;
  font-size: 10px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  :hover {
    background-color: #E9D7FE;
  }
  :active {
    background-color: #7F56D9;
    border: 1px solid #7F56D9;
    color: #FFFFFF;
  }
}

.css_Meeting{
  margin-top: 2px;
  cursor: pointer;
  height: 20px;
  background-color: #FEFBE8;
  border: 1px solid #EAAA08;
  border-radius: 8px;
  color: #A15C07;
  font-size: 10px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  :hover {
    background-color: #FEEE95;
  }
  :active {
    background-color: #EAAA08;
    border: 1px solid #EAAA08;
    color: #FFFFFF;
  }
}

.css_Plus{
  margin-top: 2px;
  cursor: pointer;
  height: 20px;
  background-color: #FFFFFF;
  border: 1px solid #98A2B3;
  border-radius: 8px;
  color: #667085;
  font-size: 10px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  width: 25px;
}
.css_text_not_employee{
  color: red;
}
`

const MyCalendar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [WidthSidePanel, setWidthSidePanel] = useState(window.innerWidth - 98)
  
  const currentDate_now = dayjs().format('YYYY-MM-DD');
  const [currentDate, setcurrentDate] = useState(dayjs(currentDate_now).format('YYYY-MM-DD'));
  const [DateCreateEvent, setDateCreateEvent] = useState(currentDate_now)

  const this_day = dayjs(currentDate).startOf("month").day() ;
  const start_date = dayjs(currentDate).format('YYYY-MM') + '-01';
  const start_date_loop = dayjs(start_date).add(0 - this_day, 'days').format('YYYY-MM-DD');
  
  const end_date = dayjs(currentDate).format('YYYY-MM') + '-' + dayjs(currentDate).daysInMonth();
  const this_day_end = dayjs(end_date).day() ;
  const end_date_loop = dayjs(end_date).add(13 - this_day_end, 'days').format('YYYY-MM-DD');

  const [topicDay, settopicDay] = useState([
    {
      text : t('sun'),
      css : 'topicDay-cell border_right border_bottom'
    },
    {
      text : t('mon'),
      css : 'topicDay-cell border_right border_bottom'
    },
    {
      text : t('tue'),
      css : 'topicDay-cell border_right border_bottom'
    },
    {
      text : t('wed'),
      css : 'topicDay-cell border_right border_bottom'
    },
    {
      text : t('thur'),
      css : 'topicDay-cell border_right border_bottom'
    },
    {
      text : t('fri'),
      css : 'topicDay-cell border_right border_bottom'
    },
    {
      text : t('sat'),
      css : 'topicDay-cell border_right border_bottom'
    },
  ])



  const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
  //alert(JSON.stringify(employee_info))
  const [EmployeeIdCalendar, setEmployeeIdCalendar] = useState(employee_info?.id ? employee_info?.id : -99)
  const [appointments, setappointments] = useState([])
  const { DailySummaryList, ActionPlanList,MeetingList, limit, total, skip, isLoading } = useSelector(selectDailySummary)


  
  const fetchData = useCallback(
    async (filter) => {
      dispatch(getDailySummary(filter))
      dispatch(getActionPlan(filter))
      dispatch(getMeeting(filter))
    },
    [dispatch]
  )

  useEffect(() => {
    log_("MyCalendar","MyCalendar","enter","");
    const filter = {
      skip: 1,
      limit: 10000,
      active : 1,
      EmployeeId: EmployeeIdCalendar,
      start_date : start_date_loop,
      end_date : end_date_loop,
    }
    fetchData(filter)
  }, [fetchData, EmployeeIdCalendar,start_date_loop,end_date_loop])

  const CallfetchData = () => {
    const filter = {
      skip: 1,
      limit: 10000,
      active : 1,
      EmployeeId: EmployeeIdCalendar,
      start_date : start_date_loop,
      end_date : end_date_loop,
    }
    fetchData(filter)
  }
  
  const [dateMonthList, setdateMonth] = useState([])
  useEffect(() => {
    //--- ST bineData API
    
    const Dataappointments = DailySummaryList.map((v) => ({
      id: v.id,
      title: 'Daily Summary Report',
      display_title : t('daily_summary_report'),
      title_for_all: 'Daily Summary Report',
      detail: v.what_done_today,
      by: 'By : ' + v.create_by,
      startDate: dayjs(v.dailysummary_date_start).format('YYYY-MM-DD HH:mm:ss'),
      endDate: dayjs(v.dailysummary_date_end).format('YYYY-MM-DD HH:mm:ss'),
      css: 'css_DailySummary'
    }))
    
    const DataActionPlan = ActionPlanList.map((v) => ({
      id: v.id,
      title: 'Action Plan',
      display_title : t('action_plan'),
      title_for_all: 'Action Plan',
      detail: '',
      by: 'By : ' + v.create_by,
      startDate: dayjs(v.action_plan_date_start).format('YYYY-MM-DD HH:mm:ss'),
      endDate: dayjs(v.action_plan_date_end).format('YYYY-MM-DD HH:mm:ss'),
      css: 'css_ActionPlan'
    }))
    const DataMeeting = MeetingList.map((v, index) => {
      
      const data_meeting_attendances_active = v.meeting_attendances.data?.filter((v) => v.attributes.active == true)
      //
      //
      return {
        id: v.id,
        meeting_attendances : data_meeting_attendances_active,
        title: 'Meeting',
        display_title : v.title ? v.title.length > 35 ? v.title.substring(0,35) + '...' : v.title : '-',
        title_for_all: v.title,
        detail: v.title,
        location : v.meeting_room_name ? v.meeting_room_name : 'Online',
        attendance : data_meeting_attendances_active.map((v_attendance) => ({
          full_name: v_attendance.attributes.full_name
        })),
        by: 'By : ' + v.create_by,
        startDate: dayjs(v.meeting_date_start).format('YYYY-MM-DD HH:mm:ss'),
        endDate: dayjs(v.meeting_date_end).format('YYYY-MM-DD HH:mm:ss'),
        css: 'css_Meeting'
      }
    })
    /*
    const DataMeeting = MeetingList.map((v) => ({
      id: v.id,
      meeting_attendances : v.meeting_attendances,
      title: 'Meeting',
      title_for_all: v.title,
      detail: v.title,
      location : v.meeting_room_name ? v.meeting_room_name : 'Online',
      attendance : v.meeting_attendances?.data.map((v_attendance) => ({
        full_name: v_attendance.attributes.full_name
      })),
      by: 'By : ' + v.create_by,
      startDate: dayjs(v.meeting_date_start).format('YYYY-MM-DD HH:mm:ss'),
      endDate: dayjs(v.meeting_date_end).format('YYYY-MM-DD HH:mm:ss'),
      css: 'css_Meeting'
    }))
    */

    
    const newStateData = [...Dataappointments, ...DataActionPlan, ...DataMeeting]
    //--- ED bineData API

    let tmpList = []
    let num_week = 0;
    const this_end_date_loop = new Date(end_date_loop);
    for (var d = new Date(start_date_loop); d <= this_end_date_loop; d.setDate(d.getDate() + 1)) {
        if(dayjs(d).day() == 0){
          num_week = num_week + 1;
        }
        if(num_week > 6){
          break;
        }
        //
        let tmpObj = {
            this_date: dayjs(d).format('YYYY-MM-DD'),
            css : dayjs(d).day() == 6 ? 'table-body-cell border_bottom' : 'table-body-cell border_right border_bottom',
            css_holiday : dayjs(d).format('YYYY-MM-DD') == currentDate_now? 'css_block_date_now' : dayjs(d).day() == 6 || dayjs(d).day() == 0 ? 'css_holiday' : '',
            css_day : dayjs(d).format('YYYY-MM-DD') == currentDate_now? 'css_date_now' : dayjs(d).format('YYYY-MM') == dayjs(currentDate).format('YYYY-MM') ? 'css_day' : 'css_day_not_month',
            /*
            list_job_day : newStateData.filter((obj) =>{
                  return  d.getTime() >= new Date(dayjs(obj.startDate).format('YYYY-MM-DD') + ' 00:00:01').getTime()  && d.getTime() <= new Date(dayjs(obj.endDate).format('YYYY-MM-DD') + ' 23:59:59').getTime()
            }
            */
            list_job_day : newStateData.filter((obj) =>{ return dayjs(d).format('YYYY-MM-DD') == dayjs(obj.startDate).format('YYYY-MM-DD')}
            )
        }
        tmpList.push(tmpObj)
    }
    setdateMonth([...tmpList])
  }, [start_date_loop, end_date_loop,DailySummaryList,ActionPlanList,MeetingList])
  



  const [DataObj, setDataObj] = useState({})
  const [successModal, setsuccessModal] = useState(false)
  const confirmsuccessModal = () => {
    setsuccessModal(false)
  }
  const [Titlesuccess, setTitlesuccess] = useState(false)
  const [nameItem, setNameItem] = useState('')
  const [Description, setDescription] = useState('')
  //--- ST DailySummary
  const [DailySummaryId, setDailySummaryId] = useState('')
  const [isModalDailySummary, setisModalDailySummary] = useState(false)
  const [ModalNewDailySummary, setModalNewDailySummary] = useState(false)
  const [ModalViewDailySummary, setModalViewDailySummary] = useState(false)
  const [ModalEditDailySummary, setModalEditDailySummary] = useState(false)
  const [ModalConfirmDelDailySummary, setModalConfirmDelDailySummary] = useState(false)
  //--- POPUP DailySummary
  const CloseModalDailySummary = () => {
    setisModalDailySummary(false)
  }
  const OnModalViewDailySummary = (id) => {
    setDailySummaryId(id)
    setisModalDailySummary(false)
    setModalViewDailySummary(true)
  }
  const OnModalEditDailySummary = (id) => {
    setDailySummaryId(id)
    setisModalDailySummary(false)
    setModalViewDailySummary(false)
    setModalEditDailySummary(true)
  }
  const OnDelDailySummary = (DataObj) => {
    setDailySummaryId(DataObj.id)
    setDescription('')
    setisModalDailySummary(false)
    setModalConfirmDelDailySummary(true)
  }
  //--- New DailySummary
  const CloseModalNewDailySummary = () => {
    setModalNewDailySummary(false)
  }

  const SubmitModalNewDailySummary = () => {
    CallfetchData()
    setModalNewDailySummary(false)
    //setTitlesuccess('Daily summary report has been created');
    //setsuccessModal(true)
  }
  //--- Edit DailySummary
  const SubmitModalEditDailySummary = () => {
    CallfetchData()
    setModalEditDailySummary(false)
    //setTitlesuccess('Daily summary report has been updated');
    //setsuccessModal(true)
  }

  const CloseModalEditDailySummary = () => {
    setModalEditDailySummary(false)
  }

  //--- View DailySummary
  const CloseModalViewDailySummary = () => {
    setModalViewDailySummary(false)
  }
  //--- Delete ActionPlan
  const DelupdateDailySummary = () => {
    const sent_data_api = {
      data: { active: 0 },
    }
    dispatch(updateDailySummary(DailySummaryId, sent_data_api))
    setModalConfirmDelDailySummary(false)
  }
  
  //--- ED DailySummary
  //--- ST ActionPlan
  const [ActionPlanId, setActionPlanId] = useState('')
  const [isModalActionPlan, setisModalActionPlan] = useState(false)
  const [ModalNewActionPlan, setModalNewActionPlan] = useState(false)
  const [ModalViewActionPlan, setModalViewActionPlan] = useState(false)
  const [ModalEditActionPlan, setModalEditActionPlan] = useState(false)
  const [ModalConfirmDelActionPlan, setModalConfirmDelActionPlan] = useState(false)
  //POPUP ActionPlan
  const CloseModalActionPlan = () => {
    setisModalActionPlan(false)
  }
  const OnModalViewActionPlan = (id) => {
    setActionPlanId(id)
    setisModalActionPlan(false)
    setModalViewActionPlan(true)
  }
  const OnModalEditActionPlan = (id) => {
    setActionPlanId(id)
    setisModalActionPlan(false)
    setModalViewActionPlan(false)
    setModalEditActionPlan(true)
  }
  const OnDelActionPlan = (DataObj) => {
    setActionPlanId(DataObj.id)
    setDescription(dayjs(DataObj.startDate).format('D MMMM YYYY') + ', ' + dayjs(DataObj.startDate).format('HH.mm') + ' - ' + dayjs(DataObj.endDate).format('HH.mm'))
    setisModalActionPlan(false)
    setModalConfirmDelActionPlan(true)
  }

  //New ActionPlan
  const CloseModalNewActionPlan = () => {
    setModalNewActionPlan(false)
  }

  const SubmitModalNewActionPlan = () => {
    CallfetchData()
    setModalNewActionPlan(false)
    //setTitlesuccess('Action plan has been created');
    //setsuccessModal(true)
  }
   //Edit ActionPlan
   const SubmitModalEditActionPlan = () => {
    CallfetchData()
    setModalEditActionPlan(false)
    //setTitlesuccess('Action plan has been updated');
    //setsuccessModal(true)
  }

  const CloseModalEditActionPlan = () => {
    setModalEditActionPlan(false)
  }
  //View ActionPlan
  const CloseModalViewActionPlan = () => {
    setModalViewActionPlan(false)
  }
  //Delete ActionPlan
  const DelupdateActionPlan = () => {
    const sent_data_api = {
      data: { active: 0 },
    }
    dispatch(updateActionPlan(ActionPlanId, sent_data_api))
    setModalConfirmDelActionPlan(false)
  }
  //--- ED ActionPlan
  //--- ST Meeting
  const [isModalMeeting, setisModalMeeting] = useState(false)
  const [MeetingId, setMeetingId] = useState('')
  const [ModalNewMeeting, setModalNewMeeting] = useState(false)
  const [ModalViewMeeting, setModalViewMeeting] = useState(false)
  const [ModalEditMeeting, setModalEditMeeting] = useState(false)
  const [ModalConfirmDelMeeting, setModalConfirmDelMeeting] = useState(false)
  const [ModalMeetingCalendar, setModalMeetingCalendar] = useState(false)
  const [MeetingData, setMeetingData] = useState({
    meeting_type : null,
    meeting_room : null,
    meeting_space : '',
    title : null,
    meeting_no : null,
    meeting_date : dayjs().format('YYYY-MM-DD'),
    time_start:'00.00',
    time_end:'00.00',
    online_meeting_link : null,
    online_meeting_id : null,
    online_meeting_code : null,
    contact_person : null,
    google_map_link : null,
    note : null,
    project : null,
    agenda_detail : null,
    created_by_id : user.id,
    employee : employee_info?.id
  })
  const CloseModalMeeting = () => {
    setisModalMeeting(false)
  }

  const SubmitModalMeeting = () => {
    setisModalMeeting(false)
  }
  //New Meeting
  const CloseModalNewMeeting = () => {
    setModalNewMeeting(false)
  }

  const SubmitModalNewMeeting = () => {
    CallfetchData()
    setModalNewMeeting(false)
    //setTitlesuccess('Meeting has been created');
    //setsuccessModal(true)
  }
  //OpenMeetingCalendar
  const onOpenMeetingCalendar = () => {
    setModalMeetingCalendar(true)
  }
  const CloseModalMeetingCalendar = () => {
    setModalMeetingCalendar(false)
    setModalNewMeeting(true)
  }
  const SubmitModalMeetingCalendar = () => {
    setModalMeetingCalendar(false)
    setModalNewMeeting(true)
  }
  const [meeting_attendances_id, setmeeting_attendances_id] = useState('')
  const [openModalMeetingAttendances, setopenModalMeetingAttendances] = useState(false)
  const OnModalMeetingAttendances = (obj_meeting_attendances) => {
    //alert(JSON.stringify(obj_meeting_attendances))
    const this_data = obj_meeting_attendances?.filter((v) => v.attributes.employee_id == EmployeeIdCalendar);
    if(this_data.length > 0){
      
      setmeeting_attendances_id(this_data[0].id)
      setisModalMeeting(false)
      setopenModalMeetingAttendances(true)
    }

  }
  const CloseModalMeetingAttendances = () => {
    setopenModalMeetingAttendances(false)
  }
  //--- ED Meeting

  const OpenModal = (data,event) => {
    //alert('OpenModal')
    //const localX = event.clientX - event.target.offsetLeft;
    //const localY = event.clientY - event.target.offsetTop;
    const this_x = window.innerWidth - event.clientX < 400 ? event.clientX - 400 : event.clientX ;
    const localX = this_x;
    //
    
    const this_y = window.innerHeight - event.clientY < 200 ? event.clientY - 200 : event.clientY - 50 ;
    
    const localY = this_y;

    const this_data = {...data,localX: localX, localY: localY}
    
    setDataObj(this_data)
    setisModalActionPlan(false)
    setisModalDailySummary(false)
    setisModalMeeting(false)
    
    if(data.title == 'Action Plan'){
      setisModalActionPlan(true)
    }else if(data.title == 'Daily Summary Report'){
      setisModalDailySummary(true)
    }else if(data.title == 'Meeting'){
      setisModalMeeting(true)
    }
    
  }
  

    //--- ST เปลี่ยนCalendar
    const [ViewScheduler, setViewScheduler] = useState('MonthView')
    const [menuTabList, setMenuTabList] = useState([
      {
        name: 'Month',
        value: 'MonthView',
        active_status: true,
      },
      {
        name: 'Week',
        value: 'WeekView',
        active_status: false,
      },
      {
        name: 'Day',
        value: 'DayView',
        active_status: false,
      },
    ])
  
    const handleClickTaskMenuTab = (data) => {
      let tmpList = [...menuTabList]
      for (let i = 0; i < tmpList.length; i++) {
        if (tmpList[i].value == data.value) {
          tmpList[i].active_status = true
          setViewScheduler(tmpList[i].value)
        } else {
          tmpList[i].active_status = false
        }
      }
      setMenuTabList([...tmpList])
    }
    const DateMonthChange = (data) => {
      if(data =='now'){
        const new_date = dayjs().format('YYYY-MM-DD');
        setcurrentDate(new_date)
      }else if(data =='back'){
        const new_date = dayjs(currentDate).add(-1, 'month').format('YYYY-MM-DD')
        setcurrentDate(new_date)
      }else{
        const new_date = dayjs(currentDate).add(1, 'month').format('YYYY-MM-DD')
        setcurrentDate(new_date)
      }

    }

    const [isDivCreate, setisDivCreate] = useState(false)
    const OpenCreatenewevent = () => {
      setisDivCreate(true)
    }
    const handleClickOutside = (event) => {
      setisDivCreate(false)
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    const [WidthMeetingCalendar, setWidthMeetingCalendar] = useState('1400')
    const CreateModalCalendar = (data) => {
      setisModalCreateEvent(false);
      if(data == 'Action Plan'){
        setModalNewActionPlan(true)
      }else if(data == 'Daily Summary Report'){
        setModalNewDailySummary(true)
      }else if(data == 'Meeting'){
        setWidthMeetingCalendar(window.innerWidth - 80)
        setModalNewMeeting(true)
      }
    }

    const [DataObjOnDay, setDataObjOnDay] = useState({})
    const [isModalCalendar, setisModalCalendar] = useState(false)
    const [isModalCreateEvent, setisModalCreateEvent] = useState(false)
    
    const OpenModalBook = (data,event,this_date,type_click) => {
      //alert('OpenModalBook')
      
      const this_x = window.innerWidth - event.clientX < 400 ? event.clientX - 400 : event.clientX ;
      const localX = this_x;
      const this_y = window.innerHeight - event.clientY < 200 ? event.clientY - 200 : event.clientY - 50 ;
      const localY = this_y;

      if(type_click == 'date'){
          setDateCreateEvent(this_date)
          const this_data = {this_date : this_date,localX: localX, localY: localY,arrdata : data}
          setDataObjOnDay(this_data) 
          if(data.length == 0){
            if(EmployeeIdCalendar > 0){
              setisModalCreateEvent(true)
            }  
          }else{
            setisModalCalendar(true)
          }
      }else{
        const this_data = {...data,localX: localX, localY: localY}
        setDataObj(this_data)
        setisModalActionPlan(false)
        setisModalDailySummary(false)
        setisModalMeeting(false)
        if(data.title == 'Action Plan'){
          setisModalActionPlan(true)
        }else if(data.title == 'Daily Summary Report'){
          setisModalDailySummary(true)
        }else if(data.title == 'Meeting'){
          setisModalMeeting(true)
        }
      }

      
    }

    const onCloseOther = () => {
      setisModalCalendar(false)
    }

    const onBookModalCalendar = () => {
      setisModalCalendar(false)
      if(EmployeeIdCalendar > 0){
        setisModalCreateEvent(true)
      }
    }

    const HideAllModal = () => {
      setisModalCreateEvent(false);
      setisModalCalendar(false);
      //--- Meeting
      setopenModalMeetingAttendances(false);
      setModalMeetingCalendar(false);
      setModalNewMeeting(false);
      setisModalMeeting(false);
      //--- ActionPlan
      setModalEditActionPlan(false);
      setModalViewActionPlan(false);
      setModalNewActionPlan(false);
      setModalConfirmDelActionPlan(false)
      setisModalActionPlan(false);
      //--- DailySummary
      setModalEditDailySummary(false);
      setModalViewDailySummary(false);
      setModalNewDailySummary(false);
      setModalConfirmDelDailySummary(false);
      setisModalDailySummary(false)
    }
    
    const isOnOpenDetail = (Obj) => {
      HideAllModal();
      if(Obj.title == 'Daily Summary Report'){
        setDailySummaryId(Obj.id)
        setModalViewDailySummary(true)
      }else if(Obj.title == 'Action Plan'){
        setActionPlanId(Obj.id)
        setModalViewActionPlan(true)
      }else if(Obj.title == 'Meeting'){
        const this_data = Obj.meeting_attendances?.filter((v) => v.attributes.employee_id == EmployeeIdCalendar);
        if(this_data.length > 0){
          setmeeting_attendances_id(this_data[0].id)
          setopenModalMeetingAttendances(true)
        }
      }

    }


    

  return (
    <DivMain>
        <div className="content-title">{t('feature_calendar_header')}</div>
        <div className="">{t('feature_calendar_sub_header')}</div>
        <div className="border_header"></div>
        <div className="content-fillter-date">
           <div>
            <Button  onClick={() => DateMonthChange('now')}>
              {t('feature_calendar_today')}
            </Button>
           </div>
           <div className="css_date_pagitation">
            <div className="css_arrow"><ArrowBackIosNewIcon onClick={() => DateMonthChange('back')}   /></div>
            <div className="text_date">{dayjs(currentDate).format('MMM YYYY')}</div>
            <div className="css_arrow"><ArrowForwardIosIcon onClick={() => DateMonthChange('next')}   /></div>
           </div>
           <div className="container">
            {EmployeeIdCalendar > 0 && (
              <div>
                <Button  onClick={OpenCreatenewevent} >
                  {t('create_new_event')}
                </Button>
                <div className={isDivCreate ? 'dropdown' : 'dropdown_none'}>
                  <ul>
                    <li onClick={() => CreateModalCalendar('Action Plan')}>{t('action_plan')}</li>
                    <li onClick={() => CreateModalCalendar('Daily Summary Report')} >{t('daily_summary_report')}</li>
                    <li onClick={() => CreateModalCalendar('Meeting')} >{t('meeting')}</li>
                  </ul>
                </div>
              </div>
              )}
              {EmployeeIdCalendar < 0 && (
                <div className="css_text_not_employee">{t('you_may_not_fully_use_this_feature_since_you_are_not_employee')}</div>
              )}
          </div>
        </div>
        <div className="resp-table">
            {topicDay?.map((item, index) => (
                <div className={item.css} key={index}>
                  {item.text}
                </div>
            ))}
            {dateMonthList?.map((item, index) => (
                <div className={`${item.css} ${item.css_holiday}`} key={index}  id={`date_id-${item.this_date}`} onClick={(event) => {if(event.target !== event.currentTarget) return;HideAllModal();OpenModalBook(item.list_job_day,event,item.this_date,'date')} }>
                  <div className={`${item.css_day}`}>{dayjs(item.this_date).format('D')}</div> 
                  {item.list_job_day.length <= 4 && (
                    <div>
                    {item.list_job_day?.map((item_job, index_job) => (
                      <div className={`${item_job.css}`} key={index_job} onClick={(e) => {HideAllModal();OpenModalBook(item_job,e,'','event')}}>{item_job.display_title}</div>
                    ))}
                    </div>
                  )}
                  {item.list_job_day.length > 4 && (
                      <div>
                      {item.list_job_day?.slice(0,3).map((item_job, index_job) => (
                        <div className={`${item_job.css}`} key={index_job} onClick={(e) => {HideAllModal();OpenModalBook(item_job,e,'','event')}}>{item_job.display_title}</div>
                      ))}
                        <div onClick={(event) => {if(event.target !== event.currentTarget) return;HideAllModal();OpenModalBook(item.list_job_day,event,item.this_date,'date')} }>
                          <div className={`css_Plus`} onClick={(e) => {HideAllModal();OpenModalBook(item.list_job_day,e,item.this_date,'date')}}>
                            +{item.list_job_day?.length - 3}
                          </div>   
                        </div>
     
                      </div>
                  )}
                  

                         
                </div>

            ))}
        </div>

        <DialogSuccess
          open={Boolean(successModal)}
          onClose={() => setsuccessModal(false)}
          onSubmit={confirmsuccessModal}
          icon={successIcon}
          title={Titlesuccess}
          textYes="Done"
        />

        <ModalDailySummary
          OpenModal={isModalDailySummary}
          onClose={CloseModalDailySummary}
          onView={OnModalViewDailySummary}
          onEdit={OnModalEditDailySummary}
          onDel={OnDelDailySummary}
          closeOther={onCloseOther}
          DataObj={DataObj}
        />
        <DialogConfirmDelete
          open={Boolean(ModalConfirmDelDailySummary)}
          onClose={() => setModalConfirmDelDailySummary(false)}
          onSubmit={DelupdateDailySummary}
          title={t('confirm_delete_daily_summary_report_header')}
          nameItem={nameItem}
          description={Description}
        />
        <SidePanel isOpen={ModalNewDailySummary} setIsOpen={setModalNewDailySummary} width={400}>
          <NewDailySummary onClose={CloseModalNewDailySummary} onSubmit={SubmitModalNewDailySummary} />
        </SidePanel>
        <SidePanel isOpen={ModalViewDailySummary} setIsOpen={setModalViewDailySummary} width={400}>
          <ViewDailySummary
            onClose={CloseModalViewDailySummary}
            onSubmit={OnModalEditDailySummary}
            DailySummaryId={DailySummaryId}
          />
        </SidePanel>
        <SidePanel isOpen={ModalEditDailySummary} setIsOpen={setModalEditDailySummary} width={400}>
          <EditDailySummary
            onClose={CloseModalEditDailySummary}
            onSubmit={SubmitModalEditDailySummary}
            DailySummaryId={DailySummaryId}
          />
        </SidePanel>

        <ModalActionPlan
          OpenModal={isModalActionPlan}
          onClose={CloseModalActionPlan}
          onView={OnModalViewActionPlan}
          onEdit={OnModalEditActionPlan}
          onDel={OnDelActionPlan}
          DataObj={DataObj}
        />
        <DialogConfirmDelete
          open={Boolean(ModalConfirmDelActionPlan)}
          onClose={() => setModalConfirmDelActionPlan(false)}
          onSubmit={DelupdateActionPlan}
          title={t('confirm_delete_action_plan_header')}
          nameItem={nameItem}
          description={Description}
        />
        <SidePanel isOpen={ModalNewActionPlan} setIsOpen={setModalNewActionPlan} width={WidthSidePanel}>
          <NewActionPlan width={WidthSidePanel} onClose={CloseModalNewActionPlan} onSubmit={SubmitModalNewActionPlan} />
        </SidePanel>
        <SidePanel isOpen={ModalViewActionPlan} setIsOpen={setModalViewActionPlan} width={WidthSidePanel}>
          <ViewActionPlan
            width={WidthSidePanel}
            onClose={CloseModalViewActionPlan}
            onSubmit={OnModalEditActionPlan}
            ActionPlanId={ActionPlanId}
          />
        </SidePanel>
        <SidePanel isOpen={ModalEditActionPlan} setIsOpen={setModalEditActionPlan} width={WidthSidePanel}>
          <EditActionPlan
            width={WidthSidePanel}
            onClose={CloseModalEditActionPlan}
            onSubmit={SubmitModalEditActionPlan}
            ActionPlanId={ActionPlanId}
          />
        </SidePanel>
        
        <ModalMeeting
          OpenModal={isModalMeeting}
          onClose={CloseModalMeeting}
          onSubmit={SubmitModalMeeting}
          onView={OnModalMeetingAttendances}
          DataObj={DataObj}
        />
        <SidePanel isOpen={ModalNewMeeting} setIsOpen={setModalNewMeeting} width={400}>
          <NewMeeting width={400} onClose={CloseModalNewMeeting} onSubmit={SubmitModalNewMeeting} onReserveSpace={onOpenMeetingCalendar} MeetingData={MeetingData} setMeetingData={setMeetingData} />
        </SidePanel>

        <SidePanel isOpen={ModalMeetingCalendar} setIsOpen={setModalMeetingCalendar} width={WidthMeetingCalendar}>
          <MeetingCalendar width={WidthMeetingCalendar} onClose={CloseModalMeetingCalendar} onSubmit={SubmitModalMeetingCalendar} MeetingData={MeetingData} setMeetingData={setMeetingData} />
        </SidePanel>

        <SidePanel isOpen={openModalMeetingAttendances} setIsOpen={setopenModalMeetingAttendances} width={400}>
            <ModalMeetingAttendances width={400} onClose={CloseModalMeetingAttendances} onSubmit={CloseModalMeetingAttendances} meeting_attendances_id={meeting_attendances_id} />
        </SidePanel>

        <ModalCalendarAll
          OpenModal={isModalCalendar}
          onClose={() => setisModalCalendar(false)}
          onBook={onBookModalCalendar}
          onisOnOpenDetail={isOnOpenDetail}
          DataObj={DataObjOnDay}
        />

        <ModalCreateEvent
          OpenModal={isModalCreateEvent}
          onClose={() => setisModalCreateEvent(false)}
          onBook={CreateModalCalendar}
          DataObj={DataObjOnDay}
        />
        
        



    </DivMain>
    
  )
}

export default MyCalendar
