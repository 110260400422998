import React from 'react'

import styled from 'styled-components'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'

const StyledRadio = styled(Radio)`
  &.MuiRadio-colorPrimary.Mui-checked {
    color: var(--Primary-600);
  }
`
const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 0.875rem;
  }
`
function RadioButtonForDynamic({ options, value, onChange }) {
  return (
    <RadioGroup row value={value} onChange={onChange}>
      {options.map((v, i) => (
        <StyledFormControlLabel key={i} value={v.value} control={<StyledRadio />} label={v.text} />
      ))}
    </RadioGroup>
  )
}

export default RadioButtonForDynamic
