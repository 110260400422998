import styled from 'styled-components'

import { TbPointFilled } from 'react-icons/tb'
import { Stack } from '@mui/material'

export default function CapsuleStatus({ status }) {
  return (
    <Styled status={status}>
      <div className="wrp-status">
        <Stack direction="row" alignItems="center" className="text">
          {/* <TbPointFilled size={18} /> */}
          <span>{status == 'Doing' ? 'Working' : status}</span>
        </Stack>
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  .wrp-status {
    width: fit-content;
    padding: 2px 8px;
    border-radius: 16px;
    border: 1px solid;

    border-color: ${({ status }) =>
      status == 'Open'
        ? ' #B2DDFF'
        : status == 'Working' || status == 'Doing'
        ? '#B2DDFF'
        : status == 'Hold'
        ? '#EAECF0'
        : status == 'Complete'
        ? '#ABEFC6'
        : status == 'On Going'
        ? '#FEDF89'
        : status == 'On Hold'
        ? '#EAECF0'
        : status == 'Cancel'
        ? '#EAECF0'
        : status == 'Reopen'
        ? '#FCCEEE'
        : '#ABEFC6'};
    background-color: ${({ status }) =>
      status == 'Open'
        ? ' #EFF8FF'
        : status == 'Working' || status == 'Doing'
        ? '#EFF8FF'
        : status == 'Hold'
        ? '#F9FAFB'
        : status == 'Complete'
        ? '#ECFDF3'
        : status == 'On Going'
        ? '#FFFAEB'
        : status == 'On Hold'
        ? '#F9FAFB'
        : status == 'Cancel'
        ? '#F9FAFB'
        : status == 'Reopen'
        ? '#FDF2FA'
        : '#ECFDF3'};

    .text {
      color: ${({ status }) =>
        status == 'Open'
          ? ' #175CD3'
          : status == 'Working' || status == 'Doing'
          ? '#175CD3'
          : status == 'Hold'
          ? '#344054'
          : status == 'Complete'
          ? '#067647'
          : status == 'On Going'
          ? '#B54708'
          : status == 'On Hold'
          ? '#344054'
          : status == 'Cancel'
          ? '#344054'
          : status == 'Reopen'
          ? '#C11574'
          : '#067647'};
    }
  }
`
