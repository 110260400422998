import { Navigate, RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'

import AppLayout from 'components/layout/AppLayout'
import { getAdminToken } from './utils/common'

import ContentCreateForm from './containers/cms/ContentCreateForm'
import ContentEditForm from './containers/cms/ContentEditForm'
import ContentTable from './containers/cms/ContentTable'
import ForgetPassword from './pages/ForgetPassword'
import Home from './pages/Home'
import Login from './pages/Login'

import CreateMaterial from './pages/material/CreateMaterial'
import EditMaterial from './pages/material/EditMaterial'
import Material from './pages/material/Material'
import MaterialDetail from './pages/material/MaterialDetail'

import CreateProduct from './pages/product/CreateProduct'
import EditProduct from './pages/product/EditProduct'
import Product from './pages/product/Product'
import ProductDetail from './pages/product/ProductDetail'
import Approvals from './pages/task/Approvals'
import MyTask from './pages/task/MyTask'
import MyTaskDocument from './pages/task/MyTaskDocument'
import TaskProject from './pages/task/TaskProject'

import UserList from './pages/users-management/UserList'

import AccessRole from './pages/access-role/AccessRole'

import ApplicationForm from './pages/application-form/ApplicationForm'
import CreateApplicationForm from './pages/application-form/CreateApplicationForm'
import VerifyError from './pages/application-form/VerifyError'
import ViewApplicationForm from './pages/application-form/ViewApplicationForm'
import ViewApplicationFormInfo from './pages/application-form/ViewApplicationFormInfo'
import ViewCreateApplicationForm from './pages/application-form/ViewCreateApplicationForm'

import GetStartPassword from './pages/reset-password/GetStartPassword'
import ResetPasswordToken from './pages/reset-password/ResetPasswordToken'
import ResultResetPassword from './pages/reset-password/ResultResetPassword'
// set password,reset password,forgot password
import ForgotPassword from './pages/reset-password/ForgotPassword'
import ResetPassword from './pages/reset-password/ResetPassword'
import SetPassword from './pages/reset-password/SetPassword'

import DepartmentDetail from './pages/department/DepartmentDetail'
import OrganizationManagement from './pages/department/OrganizationManagement'
import OrganizationMember from './pages/department/OrganizationMember'
import OrganizationTeamDetail from './pages/department/OrganizationTeamDetail'
import Employee from './pages/employee/Employee'

//--- MyCalendar
import WeeklySummaryReport from 'pages/weekly-summary-report/WeeklySummaryReport'
import ActionPlan from './pages/my-calendar/ActionPlan'
import ApproveMeeting from './pages/my-calendar/ApproveMeeting'
import DailySummaryReport from './pages/my-calendar/DailySummaryReport'
import Meeting from './pages/my-calendar/Meeting'
import MeetingForm from './pages/my-calendar/MeetingForm'
import MyCalendar from './pages/my-calendar/MyCalendar'

import Settings from './pages/document/settings/Settings'

import EmployeeActivity from './pages/dashboard/EmployeeActivity'
import EmployeeActivityDetail from './pages/dashboard/EmployeeActivityDetail'
import EmployeeDashboard from './pages/dashboard/EmployeeDashboard'
import EmployeeDashboardDetail from './pages/dashboard/EmployeeDashboardDetail'
import ProjectDashboard from './pages/dashboard/ProjectDashboard'
import ProjectDashboardDetail from './pages/dashboard/ProjectDashboardDetail'

import AcknowledgementSystem from './pages/acknowledgement-system/AcknowledgementSystem'
import CreateDocument from './pages/acknowledgement-system/CreateDocument'
import EditDocument from './pages/acknowledgement-system/EditDocument'
import PublishDocument from './pages/acknowledgement-system/PublishDocument'
import PublishDocumentForDraft from './pages/acknowledgement-system/PublishDocumentForDraft'
import ViewDocument from './pages/acknowledgement-system/ViewDocument'
import AnnouncementShareService from './pages/share-service/AnnouncementShareService'
import CompanyInformationShareServic from './pages/share-service/CompanyInformationShareServic'
import CompensationTaxShareService from './pages/share-service/CompensationTaxShareService'
// import HomeShareService from './pages/share-service/HomeShareService'
import MyFilesShareService from './pages/share-service/MyFilesShareService'
import ReadDocFromEmail from './pages/share-service/ReadDocFromEmail'
import WorkingDetailShareService from './pages/share-service/WorkingDetailShareService'

import PageNotFound from './pages/PageNotFound'

import DocumentNotFound from './pages/DocumentNotFound'
import Dashboardofm from './pages/operation-field/Dashboardofm'
import OperationfieldAll from './pages/operation-field/OperationfieldAll'
import PermissionOFM from './pages/operation-field/PermissionOFM'
import PermissionOFMBase from './pages/operation-field/PermissionOFMBase'
import PermissionOFMJob from './pages/operation-field/PermissionOFMJob'
import PermissionOFMJobDetail from './pages/operation-field/PermissionOFMJobDetail'

import EditRequestApproval from './pages/edit-request-approval/EditRequestApproval'
import EmployeeProfile from './pages/employee-profile/EmployeeProfile'

import KPIOrganizationDetail from './components/feature/kpi-management/KPIOrganizationDetail'
import KPIAnnoucement from './pages/kpi-management/KPIAnnoucement'
import KPIDeclaration from './pages/kpi-management/KPIDeclaration'
import KPIHistoryDetail from './pages/kpi-management/KPIHistoryDetail'
import KPIReport from './pages/kpi-management/KPIReport'

import RootCreateDocument from './pages/document/business-doc/RootCreateDocument'

//* Operation Process
import RootEditDocument from 'pages/document/business-doc/RootEditDocument'
import OPChainLinkDetailsPage from 'pages/operation-process/department-operation/view-chain-link'
import OPManagementListPage from 'pages/operation-process/template-management/view-all'
import OPDepartmentOperationPage from './pages/operation-process/department-operation'
import OPDepartmentOperationDetailsPage from './pages/operation-process/department-operation/view-sp'
import OPMyTaskPage from './pages/operation-process/my-task'
import OPTaskBoardPage from './pages/operation-process/task-board'

import AllServicePage from 'pages/all-service'
import Home2 from './pages/Home2'
// Warehouse
import WarehouseManagement from 'pages/warehouse/WarehouseManagement'
// Dynamic form content
import AccountingDocument from 'pages/document/mainpage/AccountingDocument'
import SaleDocument from 'pages/document/mainpage/SaleDocument'
import DynamicFormContent from 'pages/dynamic-form-content/DynamicFormContent'
// Download Report
import PurchaseOrderDocument from 'pages/document/mainpage/PurchaseOrderDocument'
import TransportationDocument from 'pages/document/mainpage/TransportationDocument'
import WarehouseDocument from 'pages/document/mainpage/WarehouseDocument'
import DownloadReport from 'pages/download-report/DownloadReport'
// change password
import ChangePassword from 'pages/change-password/ChangePassword'

import InternalDocument from 'pages/document/mainpage/InternalDocument'

const genPage = ({ type, path }) => {
  return {
    path,
    children: [
      {
        index: true,
        element: <ContentTable key={path} type={type} />,
      },
      {
        path: 'create',
        element: <ContentCreateForm type={type} />,
      },
      {
        path: ':id/edit',
        element: <ContentEditForm type={type} />,
      },
    ],
  }
}

const checkAuthenticated = () => {
  if (!getAdminToken()) {
    return redirect('/login')
  }
  return null
}

const router = createBrowserRouter([
  {
    path: '/',
    loader: checkAuthenticated,
    children: [
      {
        path: '/',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: 'home2',
            element: <Home2 />,
          },
          {
            path: 'all_service',
            element: <AllServicePage />,
          },
          {
            path: 'operation-process',
            children: [
              {
                index: true,
                element: <Navigate to="/operation-process/department-operation" />,
              },
              {
                path: 'department-operation',
                children: [
                  {
                    index: true,
                    element: <OPDepartmentOperationPage />,
                  },
                  {
                    path: ':id',
                    element: <OPDepartmentOperationDetailsPage />,
                  },
                  {
                    path: 'chain-link/:id',
                    element: <OPChainLinkDetailsPage />,
                  },
                ],
              },
              {
                path: 'task-board',
                element: <OPTaskBoardPage />,
              },
              {
                path: 'my-task',
                element: <OPMyTaskPage />,
              },
            ],
          },
          {
            path: 'MyCalendar',
            children: [
              {
                index: true,
                element: <Navigate to="/MyCalendar/MyCalendar" />,
              },
              {
                path: 'MyCalendar',
                element: <MyCalendar />,
              },
              {
                path: 'ActionPlan',
                element: <ActionPlan />,
              },
              {
                path: 'DailySummaryReport',
                element: <DailySummaryReport />,
              },
              {
                path: 'Meeting',
                element: <Meeting />,
              },
              {
                path: 'meeting-form/:id',
                element: <MeetingForm />,
              },
              {
                path: 'weekly-summary-report',
                element: <WeeklySummaryReport />,
              },
            ],
          },
          {
            path: 'system-configuration',
            children: [
              {
                index: true,
                element: <Navigate to="/system-configuration/users-management" />,
              },
              {
                path: 'users-management',
                element: <UserList />,
              },
              {
                path: 'access-role-management',
                element: <AccessRole />,
              },
              {
                path: 'template-management',
                children: [
                  {
                    index: true,
                    element: <Navigate to="/system-configuration/template-management/chain-link" />,
                  },
                  {
                    path: ':type',
                    element: <OPManagementListPage />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/product',
        element: <AppLayout />,
        children: [
          {
            index: true,
            // element: <Navigate to="/product/product" />,
            element: <Navigate to="/product" />,
          },
          // genPage({ type: 'product', path: '/product/product' }),
          genPage({ type: 'product-type', path: '/product/product-type' }),
          genPage({ type: 'product-category', path: '/product/product-category' }),
        ],
      },
      {
        path: '/inventory-management',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/inventory-management" />,
          },
          {
            path: '/inventory-management/products',
            children: [
              {
                index: true,
                element: <Product />,
              },
              {
                path: 'create-products',
                children: [
                  {
                    index: true,
                    element: <CreateProduct />,
                  },
                ],
              },
              {
                path: 'edit-products/:id',
                children: [
                  {
                    index: true,
                    element: <EditProduct />,
                  },
                ],
              },
              {
                path: 'products-detail/:id',
                children: [
                  {
                    index: true,
                    element: <ProductDetail />,
                  },
                ],
              },
            ],
          },
          {
            path: '/inventory-management/materials',
            children: [
              {
                index: true,
                element: <Material />,
              },
              {
                path: 'create-material',
                children: [
                  {
                    index: true,
                    element: <CreateMaterial />,
                  },
                ],
              },
              {
                path: 'edit-material/:id',
                children: [
                  {
                    index: true,
                    element: <EditMaterial />,
                  },
                ],
              },
              {
                path: 'material-detail/:id',
                children: [
                  {
                    index: true,
                    element: <MaterialDetail />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/task',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/task" />,
          },
          {
            path: '/task/my-task',
            children: [
              {
                index: true,
                element: <MyTask />,
              },
            ],
          },
          {
            path: '/task/MyTaskDocument',
            children: [
              {
                index: true,
                element: <MyTaskDocument />,
              },
            ],
          },
          {
            path: '/task/TaskProject',
            children: [
              {
                index: true,
                element: <TaskProject />,
              },
            ],
          },
          {
            path: '/task/approvals',
            children: [
              {
                index: true,
                element: <Approvals />,
              },
            ],
          },
        ],
      },
      {
        path: '/document',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/document" />,
          },
          {
            path: '/document/sales',
            children: [
              {
                index: true,
                element: <SaleDocument />,
              },
              {
                path: '/document/sales/create',
                children: [
                  {
                    index: true,
                    element: <RootCreateDocument />,
                  },
                ],
              },
              {
                path: '/document/sales/edit',
                children: [
                  {
                    index: true,
                    element: <RootEditDocument />,
                  },
                ],
              },
            ],
          },
          {
            path: '/document/purchases',
            children: [
              {
                index: true,
                element: <PurchaseOrderDocument />,
              },
              {
                path: '/document/purchases/create',
                children: [
                  {
                    index: true,
                    element: <RootCreateDocument />,
                  },
                ],
              },
              {
                path: '/document/purchases/edit',
                children: [
                  {
                    index: true,
                    element: <RootEditDocument />,
                  },
                ],
              },
              // {
              //   path: '/document/purchases/purchase',
              //   children: [
              //     {
              //       index: true,
              //       element: <Purchases type="purchases" />,
              //     },
              //   ],
              // },
            ],
          },
          {
            path: '/document/warehouse',
            children: [
              {
                index: true,
                element: <WarehouseDocument />,
              },
              {
                path: '/document/warehouse/create',
                children: [
                  {
                    index: true,
                    element: <RootCreateDocument />,
                  },
                ],
              },
              {
                path: '/document/warehouse/edit',
                children: [
                  {
                    index: true,
                    element: <RootEditDocument />,
                  },
                ],
              },
            ],
          },
          {
            path: '/document/accounting',
            children: [
              {
                index: true,
                element: <AccountingDocument />,
              },
              {
                path: '/document/accounting/create',
                children: [
                  {
                    index: true,
                    element: <RootCreateDocument />,
                  },
                ],
              },
              {
                path: '/document/accounting/edit',
                children: [
                  {
                    index: true,
                    element: <RootEditDocument />,
                  },
                ],
              },
            ],
          },
          {
            path: '/document/transportation',
            children: [
              {
                index: true,
                element: <TransportationDocument />,
              },
              {
                path: '/document/transportation/create',
                children: [
                  {
                    index: true,
                    element: <RootCreateDocument />,
                  },
                ],
              },
              {
                path: '/document/transportation/edit',
                children: [
                  {
                    index: true,
                    element: <RootEditDocument />,
                  },
                ],
              },
            ],
          },
          {
            path: '/document/internaldocument',
            children: [
              {
                index: true,
                element: <InternalDocument />,
              },
              {
                path: '/document/internaldocument/create',
                children: [
                  {
                    index: true,
                    element: <RootCreateDocument />,
                  },
                ],
              },
              {
                path: '/document/internaldocument/edit',
                children: [
                  {
                    index: true,
                    element: <RootEditDocument />,
                  },
                ],
              },
            ],
          },
          {
            path: '/document/settings',
            children: [
              {
                index: true,
                element: <Settings />,
              },
            ],
          },
        ],
      },
      {
        path: '/organization-management',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/organization-management" />,
          },
          {
            path: 'employee',
            children: [
              {
                index: true,
                element: <Employee />,
              },
            ],
          },
          {
            path: 'organization',
            children: [
              {
                index: true,
                element: <OrganizationManagement />,
              },
              {
                path: 'department-detail/:id',
                children: [
                  {
                    index: true,
                    element: <DepartmentDetail />,
                  },
                ],
              },
              {
                path: 'team-detail/:id',
                children: [
                  {
                    index: true,
                    element: <OrganizationTeamDetail />,
                  },
                ],
              },
              {
                path: 'team-detail/member-detail/:id',
                children: [
                  {
                    index: true,
                    element: <OrganizationMember />,
                  },
                ],
              },
            ],
          },
          {
            path: 'application-form',
            children: [
              {
                index: true,
                element: <ApplicationForm />,
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <ViewApplicationForm />,
                  },
                ],
              },
            ],
          },
          {
            path: 'acknowledgement-system',
            children: [
              {
                index: true,
                element: <AcknowledgementSystem />,
              },
              {
                path: 'create-document/:id',
                children: [
                  {
                    index: true,
                    element: <CreateDocument />,
                  },
                ],
              },
              {
                path: 'publish-document/:id',
                children: [
                  {
                    index: true,
                    element: <PublishDocument />,
                  },
                ],
              },
              {
                path: 'edit-document/:id',
                children: [
                  {
                    index: true,
                    element: <EditDocument />,
                  },
                ],
              },
              {
                path: 'view-document/:id',
                children: [
                  {
                    index: true,
                    element: <ViewDocument />,
                  },
                ],
              },
              {
                path: 'publish-document-draft/:id',
                children: [
                  {
                    index: true,
                    element: <PublishDocumentForDraft />,
                  },
                ],
              },
            ],
          },
          {
            path: 'edit-request-approvals',
            children: [
              {
                index: true,
                element: <EditRequestApproval />,
              },
              // {
              //   path: 'create-document/:id',
              //   children: [
              //     {
              //       index: true,
              //       element: <CreateDocument />,
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
      {
        path: '/dashboard',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/dashboard" />,
          },
          {
            path: 'project-dashboard',
            children: [
              {
                index: true,
                element: <ProjectDashboard />,
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <ProjectDashboardDetail />,
                  },
                ],
              },
            ],
          },
          {
            path: 'employee-dashboard',
            children: [
              {
                index: true,
                element: <EmployeeDashboard />,
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <EmployeeDashboardDetail />,
                  },
                ],
              },
            ],
          },
          {
            path: 'employee-activities',
            children: [
              {
                index: true,
                element: <EmployeeActivity />,
              },
              {
                path: ':id',
                children: [
                  {
                    index: true,
                    element: <EmployeeActivityDetail />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/change-password',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <ChangePassword />,
          },
        ],
      },
      {
        path: '/organization-management/application-form/view-create-application-form',
        element: <ViewCreateApplicationForm />,
      },
      {
        path: '/organization-management/application-form/view-application-form-info/:id',
        element: <ViewApplicationFormInfo />,
      },
      {
        path: '/share-service',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/share-service" />,
          },
          // {
          //   path: '/share-service/home',
          //   children: [
          //     {
          //       index: true,
          //       element: <HomeShareService />,
          //     },
          //   ],
          // },
          {
            path: '/share-service/announcement',
            children: [
              {
                index: true,
                element: <AnnouncementShareService />,
              },
            ],
          },
          {
            path: '/share-service/company-information',
            children: [
              {
                index: true,
                element: <CompanyInformationShareServic />,
              },
            ],
          },
          {
            path: '/share-service/working-detail',
            children: [
              {
                index: true,
                element: <WorkingDetailShareService />,
              },
            ],
          },
          {
            path: '/share-service/compensation-tax',
            children: [
              {
                index: true,
                element: <CompensationTaxShareService />,
              },
            ],
          },
          {
            path: '/share-service/my-files',
            children: [
              {
                index: true,
                element: <MyFilesShareService />,
              },
            ],
          },
        ],
      },
      {
        path: '/operation-field',
        element: <AppLayout />,
        children: [
          /*
          {
            index: true,
            element: <Navigate to="/operationfieldAll/:id" />,
          },
          */
          {
            path: '/operation-field/operationfieldAll/:id',
            children: [
              {
                index: true,
                element: <OperationfieldAll />,
              },
            ],
          },
          {
            path: 'PermissionOFM',
            children: [
              {
                index: true,
                element: <PermissionOFM />,
              },
            ],
          },
          {
            path: '/operation-field/PermissionOFMBase/:id',
            children: [
              {
                index: true,
                element: <PermissionOFMBase />,
              },
            ],
          },
          {
            path: '/operation-field/PermissionOFMJob/:id',
            children: [
              {
                index: true,
                element: <PermissionOFMJob />,
              },
            ],
          },
          {
            path: '/operation-field/PermissionOFMJobDetail/:id',
            children: [
              {
                index: true,
                element: <PermissionOFMJobDetail />,
              },
            ],
          },
          {
            path: 'operationDashboard',
            children: [
              {
                index: true,
                element: <Dashboardofm />,
              },
            ],
          },
        ],
      },
      {
        path: '/employee-profile',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/employee-profile" />,
          },
          {
            path: '/employee-profile/info',
            children: [
              {
                index: true,
                element: <EmployeeProfile />,
              },
            ],
          },
        ],
      },
      {
        path: '/kpis',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/kpis" />,
          },
          {
            path: 'annoucement',
            children: [
              {
                index: true,
                element: <KPIAnnoucement />,
              },
              {
                path: 'kpi-history-detail/:id',
                children: [
                  {
                    index: true,
                    element: <KPIHistoryDetail />,
                  },
                ],
              },
              {
                path: 'kpi-detail/:id',
                children: [
                  {
                    index: true,
                    element: <KPIOrganizationDetail />,
                  },
                ],
              },
            ],
          },
          {
            path: 'declaration',
            children: [
              {
                index: true,
                element: <KPIDeclaration />,
              },
            ],
          },
          {
            path: 'report',
            children: [
              {
                index: true,
                element: <KPIReport />,
              },
            ],
          },
        ],
      },
      {
        path: '/warehouse',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/warehouse/warehouse-management" />,
          },
          {
            path: '/warehouse/warehouse-management',
            children: [
              {
                index: true,
                element: <WarehouseManagement />,
              },
            ],
          },
        ],
      },
      {
        path: '/form',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/form/:name" />,
          },
          {
            path: '/form/:name',
            children: [
              {
                index: true,
                element: <DynamicFormContent />,
              },
            ],
          },
        ],
      },
      {
        path: '/system-admin',
        element: <AppLayout />,
        children: [
          {
            index: true,
            element: <Navigate to="/system-admin/download-report" />,
          },
          {
            path: '/system-admin/download-report',
            children: [
              {
                index: true,
                element: <DownloadReport />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/read-doc-acknowledge/:id',
    element: <ReadDocFromEmail />,
  },
  {
    path: '/regisApplicationForm/:token',
    element: <CreateApplicationForm />,
  },
  {
    path: '/verifyError',
    element: <VerifyError />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/forget-password',
    element: <ForgetPassword />,
  },
  {
    path: '/resetPasswordToken/:token',
    element: <ResetPasswordToken />,
  },
  {
    path: '/verifyAccount/:token',
    element: <GetStartPassword />,
  },
  {
    path: '/set-password/:token',
    element: <SetPassword />,
  },
  {
    path: '/reset-password/:token',
    element: <ResetPassword />,
  },
  {
    path: '/forgot-password/:token',
    element: <ForgotPassword />,
  },
  {
    path: '/result-set-password',
    element: <ResultResetPassword />,
  },
  {
    path: '/ApproveMeeting/:meeting_attendances_id',
    element: <ApproveMeeting />,
  },
  {
    path: '/doc-not-found',
    element: <DocumentNotFound />,
  },
  {
    path: '/page-not-found',
    element: <PageNotFound />,
  },
  {
    path: '/*',
    element: <PageNotFound />,
  },
])

const Router = () => {
  return <RouterProvider router={router} />
}

export default Router
