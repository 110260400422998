import React from 'react'

import styled from 'styled-components'

import { LiaTimesSolid } from 'react-icons/lia'

const BadgeSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .badge {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`

function BadgeSearch({ searchFilters, deleteSearchBadge, ...props }) {
  return (
    <BadgeSearchContainer {...props}>
      {searchFilters.map((v) => (
        <div className="badge" key={v.key}>
          <span className="name">{v.name}</span>{' '}
          <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
        </div>
      ))}
    </BadgeSearchContainer>
  )
}

export default BadgeSearch
