import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getCompanyInfo } from '../../utils/common'
import { v4 as uuidv4 } from 'uuid'

import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import iconPlus from '../../assets/images/icon-plus.svg'

import InputTextArea from '../../components/widget/element/InputTextArea'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import iconDelete from '../../assets/images/trash.png'
import iconDuplicate from '../../assets/images/Duplicate.png'
import iconDragDrop from '../../assets/images/DragDrop.png'

import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import TextField from '../../components/form/TextField'
import TextFieldTime from '../../components/form/TextFieldTime'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'

import { createActionPlan, getActionPlanReturnData } from '../../redux/actions/dailysummary'
import { postAPIdiscord } from '../../utils/apidiscord'
import { getServerTime } from '../../redux/actions/master'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import { useTranslation } from 'react-i18next'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Divider from 'components/common/Divider'
import { Box, Stack } from '@mui/material'

import { FiPlusCircle } from 'react-icons/fi'

import MyTaskModalContent from 'components/feature/my-calendar/MyTaskModalContent'
import Modal from 'components/common/Modal'

import { LiaTimesSolid } from 'react-icons/lia'
import SidePanelV2 from 'components/common/SidePanelV2'
import DrawerDetails from 'components/feature/operation-process/default-cards'
import DialogTaskPreviewer from 'components/dialog/DialogTaskPreviewer'

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: 2 }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
}

const Div = styled.div`
  padding: 24px 32px;
  height: 100%;
  .icon {
    cursor: pointer;
  }
  .text-highlight {
    color: var(--Primary-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .add-from-my-task-btn {
    color: var(--Primary-700);
    font-weight: 600;

    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
  }
  .dd_type_of_activity {
    /*width: 250px;*/
    min-width: 280px;
  }
  .box_type_of_activity {
    margin-top: 10px;
  }
  .css_header_detail_how_to {
    font-weight: bold;
  }
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css {
      color: #7f56d9;
      font-weight: bold;
    }
  }
  .highlight-text {
    color: var(--Primary-600);
    font-weight: 700;
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .content_header {
    padding: 14px 14px;

    width: ${(props) => props.width}px;
    position: fixed;
    background: #ffffff;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 118px;
    padding-bottom: 106px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width: ${(props) => props.width}px;
    background-color: #ffffff;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .div_table {
    padding: 15px;
  }
  .resp-table {
    width: 100%;
    display: table;
  }
  .resp-table-body {
    display: table-row-group;
  }
  .resp-table-row {
    display: table-row;
  }
  .table-body-head_cell {
    display: table-cell;
    border: 1px solid #d0d5dd;
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    text-align: center;
    font-weight: 600;
    background-color: #f2f4f7;
    color: #475467;
    font-size: 12px;
  }
  .table-body-cell {
    display: table-cell;
    border: 1px solid #dddddd;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: center;
  }
  .width_time {
    /*width: 30%;*/
    width: 20%;
  }
  .width_activity {
    width: 37%;
  }
  .width_result {
    width: 37%;
  }
  .width_del {
    width: 6%;
    text-align: center;
  }
  .InputTextArea_css {
    width: 100%;
  }
  .dd_css {
    margin: 0px 5px;
  }
  .basic-Information-detail {
    /*padding: 14px;*/
    padding: 0px 14px;
    width: 50%;
    display: flex;
  }
  .css_calendar {
    margin-left: 15px;
  }
  .padding-detail {
    padding: 0px 14px;
  }
  .border_top_left_radius {
    border-top-left-radius: 8px;
  }
  .border_top_right_radius {
    border-top-right-radius: 8px;
  }
  .border_bottom_left_radius {
    border-bottom-left-radius: 8px;
  }
  .border_bottom_right_radius {
    border-bottom-right-radius: 8px;
  }
  .css_time_text {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
  }
  .TextField_css {
    width: 100%;
  }
  .css_cursor_pointer {
    cursor: pointer;
  }
  /* ST CSS POPUP IMG */
  .dialog-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  /* Style for the dialog content */
  .dialog-img-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    position: relative;
    max-width: 1200px;
    padding: 0 15px;
  }
  /* Style for the close icon */
  .dialog-img-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0;
  }

  .dialog-img-popup-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .dialog-img-popup_header {
    text-align: left;
  }
  .dialog-img-popup_detail {
    text-align: left;
    padding: 10px 0px;
  }
  /* ED CSS POPUP IMG */

  /* ST CSS ส่วนของการกรอกข้อมูล */
  .time_sort {
    padding: 10px 14px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #dddddd;
    width: 150px;
    font-size: 14px;
    font-weight: 700;
  }
  .group_main_box {
    padding: 0px 14px;
    padding-top: 14px;
  }
  .main_box {
    display: grid;
    grid-template-columns: repeat(3, 20% [col-start] 65% [col-middle] 15% [col-end]);
    gap: 10px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .main_box_text_head {
    font-size: 14px;
    font-weight: 600;
    color: var(--Gray-700);
    margin-bottom: 10px;
  }
  .main_box_activity_result {
    display: grid;
    grid-template-columns: repeat(2, 50% [col-start] 50% [col-end]);
    gap: 10px;
  }
  .group_main_box_button {
    margin-left: 10px;
  }
  .main_box_button {
    display: flex;
    gap: 10px;
  }
  .ql-container {
    min-height: 130px;
    font-family: inherit;
  }
  .div_box_time {
    background: var(--Table-Bg-Default);
    /*
    &:hover {
      background: var(--Table-Bg-Hover);
    }
    */
    /*
    &:active {
      background: var(--Table-Bg-Hover);
    }
    */
  }

  @media (max-width: 600px) {
    .css_header_detail {
      flex-direction: column;
      align-items: flex-start;
    }
    header {
      flex-direction: row;
      align-items: flex-start;
    }
    .content_header {
      width: 100%;
      position: relative;
    }
    .dialog-content {
      padding: 0px 14px;
      padding-top: 15px;
      padding-bottom: 106px;
    }
    .dialog-footer {
      width: 100%;
      position: relative;
    }
    .basic-Information-detail {
      flex-direction: row;
      width: 100%;
    }
    .main_box {
      grid-template-columns: 1fr;
    }
    .main_box_activity_result {
      grid-template-columns: 1fr;
    }
    .dialog-img-content {
      width: 90%;
      padding: 0 10px;
    }
    .time_sort {
      width: 46%;
      margin-top: 10px;
    }
    .group_main_box_button,
    .main_box_button {
      width: 100%;
      display: flex;
      justify-content: end;
      position: absolute;
      right: 3%;
    }
    .ql-container {
      min-height: 100px;
    }
  }

  /* ED CSS ส่วนของการกรอกข้อมูล */
`

const NewActionPlan = ({ onClose, onSubmit, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const CompanyInfo = getCompanyInfo()
  const [ServerTime, setServerTime] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'))

  useEffect(() => {
    dispatch(getServerTime()).then(({ type, data_info }) => {
      if (type.endsWith('_SUCCESS')) {
        //alert(JSON.stringify(data_info))
        if (data_info) {
          setServerTime(dayjs(data_info).format('YYYY-MM-DD HH:mm:ss'))
        } else {
          setTitleFail('Error Connect API')
          setnameItemFail('')
          setDescriptionFail('')
          setFailModal(true)
        }
      } else {
        setTitleFail('Error Connect API')
        setnameItemFail('')
        setDescriptionFail('')
        setFailModal(true)
      }
    })
  }, [dispatch])

  const [currentDate, setcurrentDate] = useState(dayjs().format('YYYY-MM-DD'))
  const { user, employee_info } = JSON.parse(localStorage.getItem('admin'))

  const errorMessage = ''
  const editable = true

  const type_of_activity_option = [
    { text: 'Work', value: 'Work' },
    { text: 'CPD (development)', value: 'CPD (development)' },
    { text: 'AI Improvement', value: 'AI Improvement' },
    { text: 'Research', value: 'Research' },
    { text: 'Networking', value: 'Networking' },
    { text: 'ETC', value: 'ETC' },
  ]

  const [NewActionPlanData, setNewActionPlanData] = useState([
    {
      key: uuidv4(),
      time_start: '08.00',
      time_start_error: false,
      time_end: '08.30',
      time_end_error: false,
      activity: '<p></p>',
      result: '<p></p>',
      type_of_activity: '',
      type_of_activity_error: true,
    },
  ])

  const onChange_activity = (index_item, e) => {
    //alert(e)
    const newState = NewActionPlanData.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        //return { ...obj, activity: e.target.value }
        return { ...obj, activity: e }
      }

      // 👇️ otherwise return the object as is
      return obj
    })
    //setUniversitiesList(newState);
    setNewActionPlanData(newState)
  }

  const onChange_result = (index_item, e) => {
    const newState = NewActionPlanData.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        //return { ...obj, result: e.target.value }
        return { ...obj, result: e }
      }

      // 👇️ otherwise return the object as is
      return obj
    })
    //setUniversitiesList(newState);
    setNewActionPlanData(newState)
  }

  const onChange_type_of_activity = (index_item, e) => {
    //alert(e)
    const newState = NewActionPlanData.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        //return { ...obj, result: e.target.value }
        return { ...obj, type_of_activity: e, type_of_activity_error: false }
      }

      // 👇️ otherwise return the object as is
      return obj
    })
    //setUniversitiesList(newState);
    setNewActionPlanData(newState)
  }

  const onChange_time = (index_item, e, type_time) => {
    const newState = NewActionPlanData.map((obj, index) => {
      if (index === index_item) {
        if (type_time == 'time_start') {
          return { ...obj, time_start: e.target.value, time_start_error: !isValidTimeMinute(e.target.value) }
        } else if (type_time == 'time_end') {
          return { ...obj, time_end: e.target.value, time_end_error: !isValidTimeMinute(e.target.value) }
        }
      }
      return obj
    })
    //setUniversitiesList(newState);
    setNewActionPlanData(newState)
    /*
    if(isValidTimeMinute(e.target.value)){  
      const this_new_data = newState.sort((a, b) => {
        return parseInt(a.time_start.replace(".", "")) - parseInt(b.time_start.replace(".", "")) // ascending
        //return parseInt(b.time_start.replace(".", "")) - parseInt(a.time_start.replace(".", "")) // ascending
      })
      setNewActionPlanData(this_new_data)
    }else{
      setNewActionPlanData(newState)
    }
    */
  }

  const isValidTimeMinute = (data_inp) => {
    if (parseInt(data_inp.replace('.', '')) == 0) {
      //--- ไม่ให้กรอก 00.00 หรือ 0.00
      return false
    } else {
      var isValid = /^([0-1]?[0-9]|2[0-4]).([0-5][0-9])(.[0-5][0-9])?$/.test(data_inp)

      return isValid
    }
  }
  const [ModalConfirmDialogLeave, setModalConfirmDialogLeave] = useState(false)
  const ConfirmDialogLeave = () => {
    setModalConfirmDialogLeave(true)
  }

  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [Description, setDescription] = useState('')
  const [Description2, setDescription2] = useState('')
  const [EmployeeIdCalendar, setEmployeeIdCalendar] = useState(employee_info?.id ? employee_info?.id : -99)

  const ConfirmDialog = async () => {
    if (EmployeeIdCalendar < 0) {
      setTitleFail('You may not fully use this feature, since you are not employee.')
      setnameItemFail('')
      setDescriptionFail('')
      setFailModal(true)
    } else {
      //--- ST ส่ง Discord
      try {
        const full_name_employee = user.first_name + ' ' + user.last_name + ' (' + employee_info.employ_id + ')'
        const dis_input_data = JSON.stringify(NewActionPlanData)
        const max_discord_content = 1800
        let begin = 0
        let last_num = max_discord_content
        let data_to_discord = {}
        for (let i = 0; i <= dis_input_data.length; i = i + max_discord_content) {
          //console.log(`----- begin --> ${begin} , last_num --> ${last_num}`)

          if (i == 0) {
            data_to_discord = {
              content: `\n ---------TimeStam : ${ServerTime} -------------- \n ${full_name_employee} ได้สร้าง ActionPlan ของวันที่ ${dayjs(
                currentDate
              ).format('D MMM YYYY')} \n ### input_data ### \n ${dis_input_data.substring(begin, last_num)}`,
            }
            //console.log(data_to_discord)
            await postAPIdiscord({
              url: `https://discord.com/api/webhooks/1192391596952535144/tbMZy1ejPPbjxXAOpDNMfLOnxobZWEMcTid9ZI79uUYso1FuY8Y_VFuZGUzDxpJX7gPV`,
              data: data_to_discord,
              headers: {
                'Content-Type': 'application/json',
              },
            })
          } else {
            data_to_discord = {
              content: `${dis_input_data.substring(begin, last_num)}`,
            }
            //console.log(data_to_discord)
            await postAPIdiscord({
              url: `https://discord.com/api/webhooks/1192391596952535144/tbMZy1ejPPbjxXAOpDNMfLOnxobZWEMcTid9ZI79uUYso1FuY8Y_VFuZGUzDxpJX7gPV`,
              data: data_to_discord,
              headers: {
                'Content-Type': 'application/json',
              },
            })
          }
          begin = begin + max_discord_content
          last_num = last_num + max_discord_content
        }

        /*
        const data_to_discord = {
          "content" : `\n ---------TimeStam : ${ServerTime} -------------- \n ${full_name_employee} ได้แก้ไข ActionPlan (Id : ${This_ActionPlanId}) ของวันที่ ${dayjs(currentDate).format('D MMM YYYY')}  \n ### input_data ### \n [${dis_input_data.substring(0,1800)}]`,
        };
        await postAPIdiscord({
          url: `https://discord.com/api/webhooks/1192391596952535144/tbMZy1ejPPbjxXAOpDNMfLOnxobZWEMcTid9ZI79uUYso1FuY8Y_VFuZGUzDxpJX7gPV`,
          data : data_to_discord,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        */
      } catch (error) {
        console.log(error)
      }

      //--- ED ส่ง Discord

      const ObjTimeError = NewActionPlanData.filter((v) => v.time_start_error == true || v.time_end_error == true)
      const ObjTimeEror0000 = NewActionPlanData.filter((v) => v.time_start == '00.00' && v.time_end == '00.00')
      if (ObjTimeError.length > 0 || ObjTimeEror0000.length > 0) {
        setTitleFail('กรุณาระบุเวลาให้ถูกต้อง')
        setnameItemFail('')
        setDescriptionFail('')
        setFailModal(true)
      } else {
        const Objtype_of_activityError = NewActionPlanData.filter((v) => v.type_of_activity_error == true)
        if (Objtype_of_activityError.length > 0) {
          setTitleFail(t('please_fill_type_of_activity'))
          setnameItemFail('')
          setDescriptionFail('')
          setFailModal(true)
        } else {
          setDescription(user.first_name + ' ' + user.last_name + ' (' + employee_info.employ_id + ')')
          setDescription2(dayjs(currentDate).format('D MMM YYYY'))
          if (dayjs(currentDate).format('YYYY-MM-DD') < dayjs(ServerTime).format('YYYY-MM-DD')) {
            setTitleFail('Action Plan ไม่สามารถสร้างย้อนหลังได้')
            setnameItemFail('')
            setDescriptionFail('')
            setFailModal(true)
          } else {
            const filter = {
              skip: 1,
              limit: 10000,
              active: 1,
              EmployeeId: EmployeeIdCalendar,
              filter_date: currentDate,
            }

            dispatch(getActionPlanReturnData(filter)).then(({ type, data_info }) => {
              if (type.endsWith('_SUCCESS')) {
                if (data_info.length > 0) {
                  const this_id = data_info[0].id
                  setTitleFail('Action Plan ' + dayjs(currentDate).format('D MMM YYYY') + ' ถูกสร้างแล้ว')
                  setnameItemFail('')
                  setDescriptionFail('')
                  setFailModal(true)
                } else {
                  setModalConfirmDialog(true)
                }
              } else {
                setModalConfirmDialog(true)
              }
            })
          }
        }
      }
    }
  }
  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const CreateNewActionPlan = () => {
    const data_action_plan_detail = NewActionPlanData.map((v) => {
      const matchedAddedTask = myTaskSelectedList.find((it) => it.key === v.key)

      if (matchedAddedTask) {
        const { type } = matchedAddedTask
        const result = {
          key: v.key,
          activity: v.activity,
          result: v.result,
          action_plan_detail_date_start: dayjs(currentDate + ' ' + v.time_start.replace('.', ':') + ':00').format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          action_plan_detail_date_end: dayjs(currentDate + ' ' + v.time_end.replace('.', ':') + ':00').format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          type_of_activity: v.type_of_activity,
        }
        if (type === 'project') result['project_task_reference'] = matchedAddedTask.id
        if (type === 'task') result['task_reference'] = matchedAddedTask.task_id
        if (type === 'op-sw') result['op_sw_reference'] = matchedAddedTask.id
        if (type === 'op-task') result['op_task_reference'] = matchedAddedTask.id
        if (type === 'op-review') result['op_reviewer_reference'] = matchedAddedTask.id
        if (type === 'op-approval') result['op_approver_reference'] = matchedAddedTask.id

        return result
      } else {
        return {
          key: v.key,
          activity: v.activity,
          result: v.result,
          action_plan_detail_date_start: dayjs(currentDate + ' ' + v.time_start.replace('.', ':') + ':00').format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          action_plan_detail_date_end: dayjs(currentDate + ' ' + v.time_end.replace('.', ':') + ':00').format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          type_of_activity: v.type_of_activity,
        }
      }
    })
    const minDate = data_action_plan_detail[0].action_plan_detail_date_start
    const maxDate = data_action_plan_detail[data_action_plan_detail.length - 1].action_plan_detail_date_end
    const sent_data_api = {
      data: {
        //action_plan_date : dayjs(minDate).format('YYYY-MM-DD'),
        action_plan_date: currentDate,
        action_plan_date_start: minDate,
        action_plan_date_end: maxDate,
        created_by_id: null,
        employee: employee_info?.id,
        //---- ฟิวใหม่For Phane2 04/09/2023
        company: CompanyInfo?.id,
        action_plan_detail: data_action_plan_detail,
      },
    }

    dispatch(createActionPlan(sent_data_api)).then(({ type }) => {
      if (type.endsWith('_SUCCESS')) {
        setTitlesuccess(t('success_create_action_plan_message'))
        setSubTitlesuccess('')
        setDescriptionsuccess('')
        setsuccessModal(true)
      } else {
        setTitleFail('Error Create')
        setnameItemFail('')
        setDescriptionFail('')
        setFailModal(true)
      }
    })
    setModalConfirmDialog(false)
  }

  const [listHH, setListHH] = useState([])
  useEffect(() => {
    const hhList = []

    for (let i = 0; i <= 23; i++) {
      hhList.push(i)
    }

    const newHHList = hhList.map((v) => ({
      text: v.toString().padStart(2, '0'),
      value: v.toString().padStart(2, '0'),
    }))
    setListHH(newHHList)
  }, [])

  const [listMM, setListMM] = useState([])
  useEffect(() => {
    const mmList = []

    for (let i = 0; i <= 59; i++) {
      mmList.push(i)
    }

    const newMMList = mmList.map((v) => ({
      text: v.toString().padStart(2, '0'),
      value: v.toString().padStart(2, '0'),
    }))
    setListMM(newMMList)
  }, [])

  const RemoveNewActionPlanData = (index_item) => {
    const currentListCopy = [...NewActionPlanData]
    currentListCopy.splice(index_item, 1)
    setNewActionPlanData(currentListCopy)
  }

  const DuplicateActionPlanData = (index_item) => {
    let newDataElement = {
      key: uuidv4(),
      time_start: NewActionPlanData[index_item].time_start,
      time_end: NewActionPlanData[index_item].time_end,
      activity: NewActionPlanData[index_item].activity,
      result: NewActionPlanData[index_item].result,
    }
    const newStateData = [...NewActionPlanData, newDataElement]

    setNewActionPlanData(newStateData)
  }

  function handleAddNewActionPlanData(currentData) {
    let newDataElement = {
      key: uuidv4(),
      time_start: '00.00',
      time_end: '00.00',
      activity: '<p></p>',
      result: '<p></p>',
      type_of_activity: '',
      type_of_activity_error: true,
    }

    const newStateData = [...currentData, newDataElement]

    setNewActionPlanData(newStateData)
  }

  const onChangecurrentDate = (value) => {
    setcurrentDate(value)
  }

  const [isSortedAsc, setisSortedAsc] = useState(true)
  const SortData = (inp_isSortedAsc) => {
    const this_isSortedAsc = !inp_isSortedAsc // true -> false , false -> true
    setisSortedAsc(this_isSortedAsc)

    const this_new_data = NewActionPlanData.sort((a, b) => {
      if (this_isSortedAsc) {
        return parseInt(a.time_start.replace('.', '')) - parseInt(b.time_start.replace('.', '')) // ascending
        //return parseInt(dayjs(a.attributes.action_plan_detail_date_start).format('HH.mm').replace(".", "")) - parseInt(dayjs(b.attributes.action_plan_detail_date_start).format('HH.mm').replace(".", "")) // ASC
      } else {
        return parseInt(b.time_start.replace('.', '')) - parseInt(a.time_start.replace('.', '')) // DESC
        //return parseInt(dayjs(b.attributes.action_plan_detail_date_start).format('HH.mm').replace(".", "")) - parseInt(dayjs(a.attributes.action_plan_detail_date_start).format('HH.mm').replace(".", "")) // DESC
      }
    })

    //
    //console.table(this_new_data)
    setNewActionPlanData(this_new_data)
    //setNewActionPlanData({ ...NewActionPlanData, data_action_plan_detail: this_new_data })
  }

  const [dialog, setDialog] = useState(false)
  const toggleDialog = () => {
    setDialog(true)
  }

  const ClosetoggleDialog = () => {
    setDialog(false)
  }

  //---- Function Move
  const handleOnDragEnd = (result) => {
    if (!result.destination) return
    let _NewActionPlanData = [...NewActionPlanData]
    const [reorderedItem] = _NewActionPlanData.splice(result.source.index, 1)
    _NewActionPlanData.splice(result.destination.index, 0, reorderedItem)
    setNewActionPlanData(_NewActionPlanData)
  }

  // my task selected list
  const [myTaskSelectedList, setMyTaskSelectedList] = useState([])

  const addToAction = (key, type, value) => {
    const isExisted = myTaskSelectedList.some((v) => v.key === key)

    if (isExisted) {
      setMyTaskSelectedList((prev) =>
        prev.map((v) => {
          if (v.key == key)
            return {
              key: key,
              type,
              ...value,
            }
          else return v
        })
      )
    } else {
      setMyTaskSelectedList((prev) => [
        ...prev,
        {
          key,
          type,
          ...value,
        },
      ])
    }
  }
  const deleteTaskActionPlan = (key) => {
    setMyTaskSelectedList((prev) => prev.filter((v) => v.key !== key))
  }

  const [sidePanelData, setSidePanelData] = useState()

  const displayAddedTask = (key) => {
    const taskData = myTaskSelectedList.find((v) => v.key === key)

    return taskData ? (
      <Stack direction="row" alignItems="center" spacing={2}>
        {taskData.type.startsWith('op') ? (
          <>
            <Box className="text-highlight" onClick={() => openTaskSidePanel(taskData)}>
              <Box>SOP: {taskData.sop ?? '-'}</Box>
              <Box>SOW: {taskData.sow ?? '-'}</Box>
              <Box>Task: {taskData.task ?? '-'}</Box>
            </Box>
            <LiaTimesSolid size={18} className="icon" onClick={() => deleteTaskActionPlan(taskData.key)} />
          </>
        ) : (
          <>
            <Box className="text-highlight" onClick={() => openTaskSidePanel(taskData)}>
              <Box>Project: {taskData.project ?? '-'}</Box>
              <Box>Task Name: {taskData.task_name ?? '-'}</Box>
            </Box>
            <LiaTimesSolid size={18} className="icon" onClick={() => deleteTaskActionPlan(taskData.key)} />
          </>
        )}
      </Stack>
    ) : (
      false
    )
  }

  // op sow side panel
  const [isOpenDetailOPSoWSidePanel, setIsOpenDetailOPSoWSidePanel] = useState(false)

  const openDetailOPSoWSidePanel = () => {
    setIsOpenDetailOPSoWSidePanel(true)
  }
  const closeDetailOPSoWSidePanel = () => {
    setIsOpenDetailOPSoWSidePanel(false)
  }

  // op task side panel
  const [isOpenDetailOPTaskSidePanel, setIsOpenDetailOPTaskSidePanel] = useState(false)

  const openDetailOPTaskSidePanel = () => {
    setIsOpenDetailOPTaskSidePanel(true)
  }
  const closeDetailOPTaskSidePanel = () => {
    setIsOpenDetailOPTaskSidePanel(false)
  }

  // op review side panel
  const [isOpenDetailOPReviewSidePanel, setIsOpenDetailOPReviewSidePanel] = useState(false)

  const openDetailOPReviewSidePanel = () => {
    setIsOpenDetailOPReviewSidePanel(true)
  }
  const closeDetailOPReviewSidePanel = () => {
    setIsOpenDetailOPReviewSidePanel(false)
  }

  // op approval side panel
  const [isOpenDetailOPApprovalSidePanel, setIsOpenDetailOPApprovalSidePanel] = useState(false)

  const openDetailOPApprovalSidePanel = () => {
    setIsOpenDetailOPApprovalSidePanel(true)
  }
  const closeDetailOPApprovalSidePanel = () => {
    setIsOpenDetailOPApprovalSidePanel(false)
  }

  // dialog task preview side panel
  const [isShowDialogTaskPreview, setIsShowDialogTaskPreview] = useState(false)

  const openShowDialogTaskPreview = () => {
    setIsShowDialogTaskPreview(true)
  }
  const closeShowDialogTaskPreview = () => {
    setIsShowDialogTaskPreview(false)
  }

  const openTaskSidePanel = (data) => {
    if (data.type === 'task' || data.type === 'project') {
      setSidePanelData({
        ...data,
        type: data.type === 'task' ? 'task_routine' : 'task_milestone',
      })
    } else {
      setSidePanelData(data)
    }
    if (data.type === 'op-sw') {
      openDetailOPSoWSidePanel()
    } else if (data.type === 'op-task') {
      openDetailOPTaskSidePanel()
    } else if (data.type === 'op-review') {
      openDetailOPReviewSidePanel()
    } else if (data.type === 'op-approval') {
      openDetailOPApprovalSidePanel()
    } else {
      openShowDialogTaskPreview()
    }
  }

  // isOpenMyTaskModal
  const [isOpenMyTaskModal, setIsOpenMyTaskModal] = useState(false)
  const [keyActionPlan, setKeyActionPlan] = useState('')

  const openMytaskModal = (key) => {
    setKeyActionPlan(key)
    setIsOpenMyTaskModal(true)
  }
  const closeMyTaskModal = () => {
    setIsOpenMyTaskModal(false)
  }

  // current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [widthModal, setWidthModal] = useState('')

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setWidthModal((windowWidth * 95) / 100)
  }, [windowWidth])

  return (
    <Div {...props}>
      {/* header content */}
      <Box sx={{ mb: 2 }}>
        <header>
          <div className="content-title">{t('create_action_plan_header')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={ConfirmDialogLeave} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">{t('create_action_plan_sub_header')}</div>
        <div className="css_header_detail highlight-text">
          {user.first_name} {user.last_name}
        </div>
        <Box sx={{ mt: '12px' }}>
          <Divider />
        </Box>
      </Box>

      {/* action plan */}
      <Box sx={{ width: 200, mb: 2 }}>
        <Date className="input-calendar" value={currentDate} onChange={onChangecurrentDate} />
      </Box>
      <Box sx={{ mb: 2 }}>
        <div className="css_header_detail_how_to link" onClick={() => toggleDialog()}>
          {t('Read_How_to_fill_in_Action_Plan')}
        </div>
        <div className="css_header_detail">{t('create_action_plan_info')}</div>
      </Box>

      <Box sx={{ mb: 2 }}>
        <div className="time_sort" onClick={() => SortData(isSortedAsc)}>
          Time Reorder
          <span>{isSortedAsc ? ' ↓' : ' ↑'}</span>
        </div>
      </Box>

      <div>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {NewActionPlanData?.map((item, index) => (
                  <Draggable key={`item-${index}`} draggableId={`item-${index}`} index={index}>
                    {(provided) => (
                      <>
                        <div className="div_box_time" {...provided.draggableProps} ref={provided.innerRef}>
                          <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
                            <Stack direction="row" spacing={2}>
                              <Box>
                                <div className="main_box_text_head">
                                  {t('no')}. {index + 1}
                                </div>
                              </Box>
                              <Box>
                                <div className="main_box_text_head">{t('time_start')}</div>
                                <TextFieldTime
                                  name={`time_start_hh_` + index}
                                  className="TextField_css"
                                  value={item.time_start}
                                  onChange={(v) => onChange_time(index, v, 'time_start')}
                                  error={Boolean(item.time_start_error)}
                                  disabled={!editable}
                                />
                              </Box>
                              <Box>
                                <div className="main_box_text_head">{t('time_end')}</div>
                                <TextFieldTime
                                  name={`time_start_mm_` + index}
                                  className="TextField_css"
                                  value={item.time_end}
                                  onChange={(v) => onChange_time(index, v, 'time_end')}
                                  error={Boolean(item.time_end_error)}
                                  disabled={!editable}
                                />
                              </Box>
                              <Box>
                                <div className="main_box_text_head">{t('type_of_activity')}</div>
                                <div className="box_type_of_activity">
                                  <Dropdown
                                    id={`type_of_activity_` + index}
                                    className="dd_type_of_activity"
                                    value={item.type_of_activity}
                                    optionList={type_of_activity_option}
                                    onChange={(v) => onChange_type_of_activity(index, v)}
                                    error={Boolean(item.type_of_activity_error)}
                                    placeHolder={t('please_select')}
                                  />
                                </div>
                              </Box>
                              <Stack justifyContent="flex-end">
                                {displayAddedTask(item.key) ? (
                                  displayAddedTask(item.key)
                                ) : (
                                  <ButtonOutline
                                    className="add-from-my-task-btn"
                                    append={<FiPlusCircle size={20} />}
                                    onClick={() => openMytaskModal(item.key)}
                                  >
                                    Add from My Task
                                  </ButtonOutline>
                                )}
                              </Stack>
                            </Stack>

                            <Stack direction="row" alignItems="center" spacing={1}>
                              <div {...provided.dragHandleProps}>
                                <img src={iconDragDrop} className="css_cursor_pointer" title="Drag" />
                              </div>
                              <div>
                                <img
                                  src={iconDuplicate}
                                  className="css_cursor_pointer"
                                  title="Duplicate"
                                  onClick={() => DuplicateActionPlanData(index)}
                                />
                              </div>
                              <div>
                                <img
                                  src={iconDelete}
                                  className="css_cursor_pointer"
                                  title="Delete"
                                  onClick={() => RemoveNewActionPlanData(index)}
                                />
                              </div>
                            </Stack>
                          </Stack>{' '}
                          <Stack direction="row" spacing={2}>
                            <Box sx={{ width: '50%' }}>
                              <div className="main_box_text_head">{t('create_action_plan_time_table_activity')}</div>
                              <ReactQuill
                                theme="snow"
                                modules={modules}
                                value={item.activity}
                                onChange={(e) => onChange_activity(index, e)}
                              />
                            </Box>
                            <Box sx={{ width: '50%' }}>
                              <div className="main_box_text_head">{t('create_action_plan_time_table_result')}</div>
                              <ReactQuill
                                theme="snow"
                                modules={modules}
                                value={item.result}
                                onChange={(e) => onChange_result(index, e)}
                              />
                            </Box>
                          </Stack>
                          <Box sx={{ mb: 2, mt: 3 }}>
                            <Divider />
                          </Box>
                        </div>

                        {/* <div className="div_box_time" {...provided.draggableProps} ref={provided.innerRef}>
                          <div className="main_box">
                            <div>
                              <div className="main_box_text_head">
                                {index + 1}. {t('time')}
                              </div>

                              <div className="css_time_text">
                                <TextFieldTime
                                  name={`time_start_hh_` + index}
                                  className="TextField_css"
                                  value={item.time_start}
                                  onChange={(v) => onChange_time(index, v, 'time_start')}
                                  error={Boolean(item.time_start_error)}
                                  disabled={!editable}
                                />
                                -
                                <TextFieldTime
                                  name={`time_start_mm_` + index}
                                  className="TextField_css"
                                  value={item.time_end}
                                  onChange={(v) => onChange_time(index, v, 'time_end')}
                                  error={Boolean(item.time_end_error)}
                                  disabled={!editable}
                                />
                              </div>

                              <div className="main_box_text_head box_type_of_activity">{t('type_of_activity')}</div>
                              <div className="box_type_of_activity">
                                <Dropdown
                                  id={`type_of_activity_` + index}
                                  className="dd_type_of_activity"
                                  value={item.type_of_activity}
                                  optionList={type_of_activity_option}
                                  onChange={(v) => onChange_type_of_activity(index, v)}
                                  error={Boolean(item.type_of_activity_error)}
                                  placeHolder={t('please_select')}
                                />
                              </div>
                            </div>

                            <div className="main_box_activity_result">
                              <div>
                                <div className="main_box_text_head">{t('create_action_plan_time_table_activity')}</div>
                                <div>
                                  <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    value={item.activity}
                                    onChange={(e) => onChange_activity(index, e)}
                                  />
                                </div>
                              </div>
                              <div>
                                <div className="main_box_text_head">{t('create_action_plan_time_table_result')}</div>
                                <div>
                                  <ReactQuill
                                    theme="snow"
                                    modules={modules}
                                    value={item.result}
                                    onChange={(e) => onChange_result(index, e)}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="group_main_box_button">
                              <div className="main_box_text_head">&nbsp;</div>
                              <div className="main_box_button">
                                <div {...provided.dragHandleProps}>
                                  <img src={iconDragDrop} className="css_cursor_pointer" title="Drag" />
                                </div>

                                <div>
                                  <img
                                    src={iconDuplicate}
                                    className="css_cursor_pointer"
                                    title="Duplicate"
                                    onClick={() => DuplicateActionPlanData(index)}
                                  />
                                </div>
                                <div>
                                  <img
                                    src={iconDelete}
                                    className="css_cursor_pointer"
                                    title="Delete"
                                    onClick={() => RemoveNewActionPlanData(index)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border_header"></div>
                        </div> */}
                      </>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Button append={<img src={iconPlus} />} onClick={() => handleAddNewActionPlanData(NewActionPlanData)}>
          {t('create_action_plan_time_table_activity')}
        </Button>
      </div>

      <Box sx={{ mt: 2, pb: 2, position: 'sticky', bottom: 0, backgroundColor: 'var(--Base-White)' }}>
        <Box sx={{ mb: 2 }}>
          <Divider />
        </Box>

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <ButtonOutline className="button_margin" onClick={ConfirmDialogLeave}>
            {t('cancel')}
          </ButtonOutline>
          <Button className="button_margin" onClick={ConfirmDialog}>
            {t('submit')}
          </Button>
        </Stack>
      </Box>

      {/* Modal */}
      <Modal open={isOpenMyTaskModal} onClose={closeMyTaskModal} disableBackdropClick width={`${widthModal}px`}>
        <MyTaskModalContent keyActionPlan={keyActionPlan} addToAction={addToAction} onClose={closeMyTaskModal} />
      </Modal>

      <DialogConfirm
        open={Boolean(ModalConfirmDialog)}
        onClose={() => setModalConfirmDialog(false)}
        onSubmit={CreateNewActionPlan}
        icon={question}
        title={t('confirm_create_action_plan_header')}
        description={Description}
        description2={Description2}
        textYes={t('confirm')}
        textNo={t('back')}
      />
      <DialogConfirm
        open={Boolean(ModalConfirmDialogLeave)}
        onClose={() => setModalConfirmDialogLeave(false)}
        onSubmit={onClose}
        icon={question}
        title={t('leave_create_action_plan_header')}
        textYes={t('leave')}
        textNo={t('cancel')}
      />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />

      {dialog && (
        <div className="dialog-img">
          <div className="dialog-img-content">
            <h3 className="dialog-img-popup_header">{t('How_to_fill_in_Action_Plan')}</h3>
            <button className="dialog-img-close-icon" onClick={ClosetoggleDialog}>
              &#10005;
            </button>
            <div className="dialog-img-popup_detail">
              <div>{t('How_to_fill_in_Action_Plan_1')}</div>
              <div>{t('How_to_fill_in_Action_Plan_2')}</div>
              <div>{t('How_to_fill_in_Action_Plan_3')}</div>
              <div>{t('How_to_fill_in_Action_Plan_4')}</div>
              <div>{t('How_to_fill_in_Action_Plan_5')}</div>
              <div>{t('How_to_fill_in_Action_Plan_6')}</div>
            </div>
          </div>
        </div>
      )}

      {/* side panel */}
      <SidePanelV2
        titleText={sidePanelData?.sow}
        supportingText={`SOW Code: ${sidePanelData?.code}`}
        open={isOpenDetailOPSoWSidePanel}
        onClose={(event) => {
          closeDetailOPSoWSidePanel()
          // dispatch(clearSW())
          // handleToggleDrawer('view-sw', false)(event)
        }}
      >
        <DrawerDetails.SoW id={sidePanelData?.id} />
      </SidePanelV2>
      <SidePanelV2
        titleText={sidePanelData?.sow}
        supportingText={`SOW Code: ${sidePanelData?.code}`}
        open={isOpenDetailOPTaskSidePanel}
        onClose={(event) => {
          closeDetailOPTaskSidePanel()
        }}
      >
        <DrawerDetails.Task taskId={sidePanelData?.id} />
      </SidePanelV2>
      <SidePanelV2
        titleText={sidePanelData?.sow}
        supportingText={`SOW Code: ${sidePanelData?.code}`}
        open={isOpenDetailOPReviewSidePanel}
        onClose={(event) => {
          closeDetailOPReviewSidePanel()
        }}
      >
        <DrawerDetails.Reviewer reviewerId={sidePanelData?.id} />
      </SidePanelV2>
      <SidePanelV2
        titleText={sidePanelData?.sow}
        supportingText={`SOW Code: ${sidePanelData?.code}`}
        open={isOpenDetailOPApprovalSidePanel}
        onClose={(event) => {
          closeDetailOPApprovalSidePanel()
        }}
      >
        <DrawerDetails.Approval approverId={sidePanelData?.id} />
      </SidePanelV2>
      {isShowDialogTaskPreview && (
        <DialogTaskPreviewer
          taskId={sidePanelData?.id}
          open={isShowDialogTaskPreview}
          onClose={closeShowDialogTaskPreview}
          previewType={sidePanelData?.type}
        />
      )}
    </Div>
  )
}

export default NewActionPlan
