import { useEffect, useState } from 'react'
import styled from 'styled-components'
import IconXClose from '../../../widget/icon/IconXClose'
import ViewDoc from '../../approvals/ViewDoc'

export default function TaskDocumentView({ onClose, file,docId, isDownload, isDownloaded,isRoutine }) {
  const [isDownloading, setIsDownloading] = useState(false)
  useEffect(() => {
    if (isDownload) {
      setIsDownloading(true)
    }
  }, [])
  return (
    <Styled isDownload={isDownload}>
      {isDownloading && (
        <div className="wrp-download-status">
          <span>Downloading...</span>
        </div>
      )}
      <div className="wrp-navigation">
        <div className="navigation-right">
          <div className="icon-x-close" onClick={() => onClose()}>
            <IconXClose />
          </div>
        </div>
      </div>
      <ViewDoc docId={docId} file={file} isDownload={isDownload} isDownloaded={isDownloaded} isRoutine={isRoutine} />
    </Styled>
  )
}

const Styled = styled.div`
  background-color: #ffffff;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  .wrp-download-status {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrp-navigation {
    display: flex;
    justify-content: end;
    padding: 16px 32px;
  }
  .navigation-right {
    display: flex;
    align-items: center;
  }
  .icon-x-close {
    display: flex;
    cursor: pointer;
  }
  .wrp-content {
    flex: 1;
    padding: 0 24px;
  }
  @media print {
    zoom: 75%;
    @page {
      size: A4;
    }
    .wrp-navigation,
    .wrp-bottom-bar {
      display: none;
    }

    .printarea {
      display: block;
    }
  }
`
