import { initPagination } from 'constants/pagination'
import { combineReducers } from 'redux'
import { OP } from 'redux/actionTypes'

const { GET, CLEAR, DELETE, POST, PUT } = OP

const initSOPState = {
  sps: [],
  sps_pagination: initPagination,
  sp: {},
  sp_files: [],
  sp_files_pagination: initPagination,
  sp_deliveries: [],
  sp_deliveries_pagination: initPagination,
  sp_sw_options: [],
  sp_logs: [],
  sp_logs_pagination: initPagination,
}

const initSOWState = {
  sws: [],
  sws_pagination: initPagination,
  sws_me: [],
  sws_me_pagination: initPagination,
  sw: {},
  sw_employees: [],
  sw_employees_pagination: initPagination,
  sw_files: [],
  sw_files_pagination: initPagination,
  canUnassignSW: false,
  sw_logs: [],
  sw_logs_pagination: initPagination,
}

const initTaskState = {
  tasks: [],
  tasks_pagination: initPagination,
  task: {},
  tasks_me: [],
  tasks_me_pagination: initPagination,
  task_employees: [],
  task_employees_pagination: initPagination,
  task_files: [],
  task_files_pagination: initPagination,
  canUnassignTask: false,
  task_logs: [],
  task_logs_pagination: initPagination,
}

const initReviewerState = {
  reviewer: {},
  reviewers: [],
  reviewers_pagination: initPagination,
}

const initApproverState = {
  approver: {},
  approvers: [],
  approvers_pagination: initPagination,
}

const initChainLinkState = {
  chainLinks: [],
  chainLinks_pagination: initPagination,
  chainLink: {},
  chainLink_files: [],
  chainLink_files_pagination: initPagination,
  chainLink_deliveries: [],
  chainLink_deliveries_pagination: initPagination,
  chainLink_logs: [],
  chainLink_logs_pagination: initPagination,
}

const initChainLinkTempState = {
  chainLinkTpls: [],
  chainLinkTpls_pagination: initPagination,
  chainLinkTpl: {},
  chainLinkTpl_logs: [],
  chainLinkTpl_logs_pagination: initPagination,
}

const initSOPTempState = {
  sopTpls: [],
  sopTpls_pagination: initPagination,
  sopTpl: {},
  sopTpl_logs: [],
  sopTpl_logs_pagination: initPagination,
}

const initSOWTplState = {
  sowTpls: [],
  sowTpls_pagination: initPagination,
  sowTpl: {},
  sowTpl_logs: [],
  sowTpl_logs_pagination: initPagination,
}

const initTaskTplState = {
  taskTemps: [],
  taskTemps_pagination: initPagination,
  taskTemp: {},
  taskTemp_logs: [],
  taskTemp_logs_pagination: initPagination,
}

const initDocumentState = {
  bizDoc: {},
  docSignerEmployees: [],
  docSignerEmployees_pagination: initPagination,
  docSigner_me: [],
  docSigner_me_pagination: initPagination,
  docSigner: {},
}

const initExtraState = {
  spTemplates: [],
  spTemplates_pagination: initPagination,
  clTemplates: [],
  clTemplates_pagination: initPagination,
  tags: [],
  tags_pagination: initPagination,
  notes: [],
  notes_pagination: initPagination,
  departments: [],
  departments_pagination: initPagination,
  docTypes: [],
  docTypes_pagination: initPagination,
}

const initialState = {
  ...initSOPState,
  ...initSOWState,
  ...initTaskState,
  ...initReviewerState,
  ...initApproverState,
  ...initChainLinkState,
  ...initChainLinkTempState,
  ...initSOPTempState,
  ...initSOWTplState,
  ...initTaskTplState,
  ...initDocumentState,
  ...initExtraState,

  isLoading: false,
}

/**
 * Recursively retrieves all values from an object that have keys 'REQUEST' or 'FAILURE'.
 *
 * @param {Object} obj - The object to retrieve values from.
 * @returns {Array} - An array of values that have keys 'REQUEST' or 'FAILURE'.
 */
function getDeepValues(obj) {
  let values = []
  for (let key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      values = values.concat(getDeepValues(obj[key]))
    } else if (key === 'REQUEST' || key === 'FAILURE') {
      values.push(obj[key])
    }
  }
  return values
}

const operationProcessReducer = (state = initialState, action) => {
  const baseActionTypes = [{ ...OP }]

  const loadingActionTypes = getDeepValues(baseActionTypes)

  if (loadingActionTypes.includes(action.type)) {
    return {
      ...state,
      isLoading: true,
    }
  }

  const successNoChangeStateActionTypes = [
    GET.SP_TEMPLATE_BY_ID,
    GET.CL_TEMPLATE_BY_ID,

    POST.SP.SUCCESS,
    POST.SP_FILE.SUCCESS,
    POST.SP_CANCEL.SUCCESS,
    POST.NOTE.SUCCESS,
    PUT.SP.SUCCESS,

    POST.SW_BY_ID_FILE.SUCCESS,
    POST.SW_BY_ID_ASSIGN.SUCCESS,
    POST.SW_BY_ID_UNASSIGN.SUCCESS,
    POST.SW_BY_ID_COMPLETE.SUCCESS,

    POST.TASK_BY_ID_ASSIGN.SUCCESS,
    POST.TASK_BY_ID_UNASSIGN.SUCCESS,
    POST.TASK_BY_ID_COMPLETE.SUCCESS,
    POST.TASK_BY_ID_FILE.SUCCESS,

    POST.REVIEW_PASS.SUCCESS,
    POST.REVIEW_NOTPASS.SUCCESS,

    POST.APPROVAL_APPROVE.SUCCESS,
    POST.APPROVAL_REJECT.SUCCESS,

    POST.CL.SUCCESS,
    POST.CL_FILE.SUCCESS,
    POST.CL_CANCEL.SUCCESS,
    PUT.CL.SUCCESS,

    DELETE.FILE.SUCCESS,

    POST.DELIVERY_ID_FILE.SUCCESS,
    DELETE.DELIVERY_ID_FILE.SUCCESS,

    POST.BIZ_DOC.SUCCESS,
    POST.DOC_ID_BIZ_DOC.SUCCESS,
    DELETE.DOC_ID_BIZ_DOC.SUCCESS,
    POST.DOC_ID_REQUEST.SUCCESS,
    POST.DOC_ID_CANCEL.SUCCESS,
    POST.DOC_SIGNER_ID_APPROVE.SUCCESS,
    POST.DOC_SIGNER_ID_REJECT.SUCCESS,

    PUT.TASK_TEMP.SUCCESS,
    POST.TASK_TEMP.SUCCESS,
    POST.TASK_TEMP_ID_INACTIVE.SUCCESS,
    POST.TASK_TEMP_ID_ACTIVE.SUCCESS,
    DELETE.TASK_TEMP.SUCCESS,

    PUT.SOW_TPL.SUCCESS,
    POST.SOW_TPL_ID_ACTIVE.SUCCESS,
    POST.SOW_TPL_ID_INACTIVE.SUCCESS,
    POST.SOW_TPL.SUCCESS,
    DELETE.SOW_TPL.SUCCESS,

    PUT.SOP_TPL.SUCCESS,
    POST.SOP_TPL_ID_ACTIVE.SUCCESS,
    POST.SOP_TPL_ID_INACTIVE.SUCCESS,
    POST.SOP_TPL.SUCCESS,
    DELETE.SOP_TPL.SUCCESS,

    PUT.CL_TPL.SUCCESS,
    POST.CL_TPL.SUCCESS,
    POST.CL_TPL_ID_ACTIVE.SUCCESS,
    POST.CL_TPL_ID_INACTIVE.SUCCESS,
    DELETE.CL_TPL.SUCCESS,
  ]

  if (successNoChangeStateActionTypes.includes(action.type)) {
    return {
      ...state,
      isLoading: false,
    }
  }

  switch (action.type) {
    /** Begin: Chain Links */
    case GET.CL_TEMPLATES.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return {
        ...state,
        clTemplates: data,
        clTemplates_pagination: pagination,
        isLoading: false,
      }
    }

    case GET.CL_LIST.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, chainLinks: data, chainLinks_pagination: pagination, isLoading: false }
    }

    case GET.CL_ID.SUCCESS: {
      const { data } = action

      return { ...state, chainLink: data, isLoading: false }
    }

    case GET.CL_ID_LOGS.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, chainLink_logs: data, chainLink_logs_pagination: pagination, isLoading: false }
    }

    case GET.CL_ID_FILES.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, chainLink_files: data, chainLink_files_pagination: pagination, isLoading: false }
    }

    case GET.CL_ID_DELIVERIES.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, chainLink_deliveries: data, chainLink_deliveries_pagination: pagination, isLoading: false }
    }

    case CLEAR.CL: {
      return { ...state, chainLink: {}, isLoading: false }
    }
    /** End: Chain Links */

    /** Begin: SOP */
    case GET.SP_TEMPLATES.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return {
        ...state,
        spTemplates: data,
        spTemplates_pagination: pagination,
        isLoading: false,
      }
    }

    case GET.SPs.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      const newStateObj = {
        ...state,
        sps: data,
        sps_pagination: pagination,
        isLoading: false,
      }

      return newStateObj
    }

    case GET.SP_BY_ID.SUCCESS: {
      const { data, options } = action

      return { ...state, sp: data, sp_sw_options: options || [], isLoading: false }
    }

    case GET.SP_BY_ID_FILES.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, sp_files: data, sp_files_pagination: pagination, isLoading: false }
    }

    case GET.SP_BY_ID_DELIVERIES.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, sp_deliveries: data, sp_deliveries_pagination: pagination, isLoading: false }
    }

    case GET.SP_BY_ID_LOGS.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, sp_logs: data, sp_logs_pagination: pagination, isLoading: false }
    }

    case CLEAR.SP: {
      return { ...state, sp: {}, isLoading: false }
    }

    case CLEAR.SP_TEMPLATE: {
      return { ...state, spTemplate: {}, isLoading: false }
    }
    /** End: SOP */

    /** Begin: SOW */
    case GET.SWs_BOARD.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      const newStateObj = {
        ...state,
        sws: data,
        sws_pagination: pagination,
        isLoading: false,
      }

      return newStateObj
    }

    case GET.SWs_ME.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      const newStateObj = {
        ...state,
        sws_me: data,
        sws_me_pagination: pagination,
        isLoading: false,
      }

      return newStateObj
    }

    case GET.SW_BY_ID.SUCCESS: {
      const { data } = action

      return { ...state, sw: data, isLoading: false }
    }

    case GET.SW_BY_ID_EMPLOYEES.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      const newStateObj = {
        ...state,
        sw_employees: data,
        sw_employees_pagination: pagination,
        isLoading: false,
      }

      return newStateObj
    }

    case GET.SW_BY_ID_FILES.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, sw_files: data, sw_files_pagination: pagination, isLoading: false }
    }

    case GET.SW_BY_ID_LOGS.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, sw_logs: data, sw_logs_pagination: pagination, isLoading: false }
    }

    case POST.SW_BY_ID_CHECK_UNASSIGN.SUCCESS: {
      const { canUnassignSW, error } = action

      return { ...state, canUnassignSW: error ? false : canUnassignSW, isLoading: false }
    }

    case CLEAR.SW: {
      return { ...state, sw: {}, isLoading: false }
    }
    /** End: SOW */

    /** Begin: Task */
    case GET.TASKS_BOARD.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      const newStateObj = {
        ...state,
        tasks: data,
        tasks_pagination: pagination,
        isLoading: false,
      }

      return newStateObj
    }

    case GET.TASKS_ME.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      const newStateObj = {
        ...state,
        tasks_me: data,
        tasks_me_pagination: pagination,
        isLoading: false,
      }

      return newStateObj
    }

    case GET.TASK_BY_ID_EMPLOYEES.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      const newStateObj = {
        task_employees: data,
        task_employees_pagination: pagination,
      }

      return { ...state, isLoading: false, ...newStateObj }
    }

    case GET.TASK_BY_ID_FILES.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, task_files: data, task_files_pagination: pagination, isLoading: false }
    }

    case GET.TASK_BY_ID.SUCCESS: {
      const { data } = action

      return { ...state, task: data, isLoading: false }
    }

    case GET.TASK_BY_ID_LOGS.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, task_logs: data, task_logs_pagination: pagination, isLoading: false }
    }

    case POST.TASK_BY_ID_CHECK_UNASSIGN.SUCCESS: {
      const { canUnassignTask, error } = action

      return { ...state, canUnassignTask: error ? false : canUnassignTask, isLoading: false }
    }

    case CLEAR.TASK: {
      return { ...state, task: {}, isLoading: false }
    }
    /** End: Task */

    /** Begin: Reviewer */
    case GET.REVIEWERS_OWNER.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, reviewers: data, reviewers_pagination: pagination, isLoading: false }
    }

    case GET.REVIEWER_BY_ID.SUCCESS: {
      const { data } = action

      return { ...state, reviewer: data, isLoading: false }
    }

    case CLEAR.REVIEWER: {
      return { ...state, reviewer: {}, isLoading: false }
    }
    /** End: Reviewer */

    /** Begin: Approver */
    case GET.APPROVER_BY_ID.SUCCESS: {
      const { data } = action

      return { ...state, approver: data, isLoading: false }
    }

    case GET.APPROVERS_OWNER.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, approvers: data, approvers_pagination: pagination, isLoading: false }
    }

    case CLEAR.APPROVER: {
      return { ...state, approver: {}, isLoading: false }
    }
    /** End: Approver */

    /** Begin: Document */
    case GET.BIZ_DOC_ID.SUCCESS: {
      const { data } = action

      return { ...state, bizDoc: data, isLoading: false }
    }

    case GET.DOC_SIGNER_ID_EMPLOYEES.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, docSignerEmployees: data, docSignerEmployees_pagination: pagination, isLoading: false }
    }

    case GET.DOC_SIGNER_ME.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, docSigner_me: data, docSigner_me_pagination: pagination, isLoading: false }
    }

    case GET.DOC_SIGNER_ID.SUCCESS: {
      const { data } = action

      return { ...state, docSigner: data, isLoading: false }
    }
    /** End: Document */

    /** Begin: Extra */
    case GET.TAGS.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, tags: data, tags_pagination: pagination, isLoading: false }
    }

    case GET.NOTES.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, notes: data, notes_pagination: pagination, isLoading: false }
    }

    case GET.DEPARTMENTS.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, departments: data, departments_pagination: pagination, isLoading: false }
    }

    case GET.DOC_TYPE_LIST.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, docTypes: data, docTypes_pagination: pagination, isLoading: false }
    }

    case CLEAR.NOTES: {
      return { ...state, notes: [], notes_pagination: initPagination, isLoading: false }
    }
    /** End: Extra */

    /** Begin: Task Template MGT. */
    case GET.TASK_TEMP_LIST.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, taskTemps: data, taskTemps_pagination: pagination, isLoading: false }
    }

    case GET.TASK_TEMP_BY_ID.SUCCESS: {
      const { data } = action

      return { ...state, taskTemp: data, isLoading: false }
    }

    case GET.TASK_TEMP_BY_ID_LOGS.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, taskTemp_logs: data, taskTemp_logs_pagination: pagination, isLoading: false }
    }

    case CLEAR.TASK_TEMP: {
      return { ...state, taskTemp: {}, isLoading: false }
    }
    /** End: Task Template MGT. */

    /** Begin: SOW Template MGT. */
    case GET.SOW_TPL_LIST.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, sowTpls: data, sowTpls_pagination: pagination, isLoading: false }
    }

    case GET.SOW_TPL_BY_ID.SUCCESS: {
      const { data } = action

      return { ...state, sowTpl: data, isLoading: false }
    }

    case GET.SOW_TPL_BY_ID_LOGS.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, sowTpl_logs: data, sowTpl_logs_pagination: pagination, isLoading: false }
    }

    case CLEAR.SOW_TPL: {
      return { ...state, sowTpl: {}, isLoading: false }
    }
    /** End: SOW Template MGT. */

    /** Begin: SOP Template MGT. */
    case GET.SOP_TPL_LIST.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, sopTpls: data, sopTpls_pagination: pagination, isLoading: false }
    }

    case GET.SOP_TPL_BY_ID.SUCCESS: {
      const { data } = action

      return { ...state, sopTpl: data, isLoading: false }
    }

    case GET.SOP_TPL_BY_ID_LOGS.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, sopTpl_logs: data, sopTpl_logs_pagination: pagination, isLoading: false }
    }

    case CLEAR.SOP_TPL: {
      return { ...state, sopTpl: {}, isLoading: false }
    }
    /** End: SOP Template MGT. */

    /** Begin: Chain Link Template MGT. */
    case GET.CL_TPL_LIST.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, chainLinkTpls: data, chainLinkTpls_pagination: pagination, isLoading: false }
    }

    case GET.CL_TPL_BY_ID.SUCCESS: {
      const { data } = action

      return { ...state, chainLinkTpl: data, isLoading: false }
    }

    case GET.CL_TPL_BY_ID_LOGS.SUCCESS: {
      const { data, meta } = action
      const { pagination } = meta

      return { ...state, chainLinkTpl_logs: data, chainLinkTpl_logs_pagination: pagination, isLoading: false }
    }

    case CLEAR.CL_TPL: {
      return { ...state, chainLinkTpl: {}, isLoading: false }
    }
    /** End: Chain Link Template MGT. */

    default:
      return state
  }
}

export default combineReducers({
  operationProcessReducer,
})
