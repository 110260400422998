import React from 'react'
import styled from 'styled-components'

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const Icon = styled.svg`
  fill: none;
  stroke: var(--Primary-600);
  stroke-width: 2px;
`

const StyledCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? 'var(--Checkbox-Checked-Bg)' : 'var(--Checkbox-Unchecked-Bg)')};
  border: 1px solid
    ${(props) => (props.checked ? 'var(--Checkbox-Checked-Stroke)' : 'var(--Checkbox-Unchecked-Stroke)')};
  border-radius: 3px;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const Checkbox = ({ className, checked, disabled, ...props }) => (
  <CheckboxContainer className={className} disabled={disabled}>
    <HiddenCheckbox checked={checked} disabled={disabled} {...props} />
    <StyledCheckbox
      checked={checked}
      disabled={disabled}
      onClick={!disabled ? () => props.onChange({ target: { checked: !checked } }) : null}
    >
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default styled(Checkbox)`
  ${HiddenCheckbox}:focus + ${StyledCheckbox} {
    box-shadow: ${(props) => (props.disabled ? 'none' : '0 0 0 3px pink')};
  }

  ${HiddenCheckbox}:hover + ${StyledCheckbox}, ${CheckboxContainer}:hover ${StyledCheckbox} {
    border-color: var(--Primary-600);
  }
`
