import { getAPI, postAPI, putAPI, deleteAPI } from '../../utils/api'
import {
  GET_DOCUMENT_CATEGORY,
  GET_DOCUMENT_CATEGORY_BY_ID,
  CREATE_DOCUMENT_CATEGORY,
  EDIT_DOCUMENT_CATEGORY,
  DELETE_DOCUMENT_CATEGORY,
} from '../actionTypes'
import { showLoading, hideLoading } from '../actions/loading'

export const getDocumentCategory =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_DOCUMENT_CATEGORY.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'fields[0]': 'id',
        'fields[1]': 'name',
        'fields[2]': 'status',
        'populate[creator][fields][0]': 'title',
        'populate[creator][fields][1]': 'first_name',
        'populate[creator][fields][2]': 'last_name',
        'populate[document_acknow_templates][count]': 'true',
        'sort[createdAt]': 'desc',
      }
      if (filter?.status !== '') params['filters[status]'] = filter.status

      const { data, meta } = await getAPI({
        url: '/api/document-acknow-categories',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_DOCUMENT_CATEGORY.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_DOCUMENT_CATEGORY.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Document category failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getDocumentCategoryById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DOCUMENT_CATEGORY_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/document-acknow-categories/${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_DOCUMENT_CATEGORY_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_DOCUMENT_CATEGORY_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Document category by id failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createDocumentCategory = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_DOCUMENT_CATEGORY.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: '/api/document-acknow-categories',
      headers: headers,
      data,
    })

    return dispatch({
      type: CREATE_DOCUMENT_CATEGORY.SUCCESS,
      showAlert: true,
      alertMessage: 'Create document category Successful!',
    })
  } catch (error) {
    return dispatch({
      type: CREATE_DOCUMENT_CATEGORY.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Create document category failed!',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getDocumentCategory())
  }
}

export const editDocumentCategory = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_DOCUMENT_CATEGORY.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/document-acknow-categories/${id}`,
      headers: headers,
      data,
    })

    return dispatch({
      type: EDIT_DOCUMENT_CATEGORY.SUCCESS,
      showAlert: true,
      alertMessage: 'Edit document category Successful!',
    })
  } catch (error) {
    return dispatch({
      type: EDIT_DOCUMENT_CATEGORY.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Edit document category failed!',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getDocumentCategory())
  }
}

export const deleteDocumentCategory = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_DOCUMENT_CATEGORY.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await deleteAPI({
      url: `/api/document-acknow-categories/${id}`,
      headers: headers,
    })

    return dispatch({
      type: DELETE_DOCUMENT_CATEGORY.SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: DELETE_DOCUMENT_CATEGORY.FAILURE,
      error,
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getDocumentCategory())
  }
}
