import React, { useRef } from 'react'

import { useDispatch } from 'react-redux'
import { uploadFileForDynamicService } from 'redux/actions/dynamicForm'

import styled from 'styled-components'
import Button from 'components/form/button/Button'

import { MdOutlineFileUpload } from 'react-icons/md'
import { Box, Stack } from '@mui/material'
import IconButton from 'components/common/IconButton'

import { GrView } from 'react-icons/gr'
import { BiTrash } from 'react-icons/bi'

const InputWrapper = styled.div`
  .icon-button-style {
    display: inline-block;
    padding: 4px;
    border: 1px solid var(--Gray-300);
    border-radius: 4px;
    color: var(--Gray-600);
  }
  .file-name {
    font-weight: 600;
    color: var(--Primary-700);
  }
`
const FileInput = styled.input`
  display: none;
`

function UploadSingleFile({ fileValue, setAttachFile, ...props }) {
  const dispatch = useDispatch()
  const fileInputRef = useRef(null)

  const handleIconClick = () => {
    fileInputRef.current.click()
  }
  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const formData = new FormData()
      formData.append('files', file)

      const response = await dispatch(uploadFileForDynamicService(formData))

      if (response) setAttachFile(response)
      else setAttachFile(null)
    }
  }
  const viewFile = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }
  const clearFile = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }

    setAttachFile('')
  }

  return (
    <InputWrapper {...props}>
      {fileValue && fileValue !== '' ? (
        <>
          <Stack direction="row" spacing={1} alignItems="center">
            <Box className="file-name">{fileValue?.fileName}</Box>
            <IconButton
              type="button"
              className="icon-button-style"
              icon={<GrView size={14} onClick={() => viewFile(fileValue?.url)} />}
            />
            <IconButton type="button" className="icon-button-style" icon={<BiTrash size={16} />} onClick={clearFile} />
          </Stack>
        </>
      ) : (
        <>
          <FileInput type="file" ref={fileInputRef} onChange={handleFileChange} />
          <Button type="button" append={<MdOutlineFileUpload size={20} />} onClick={handleIconClick}>
            Upload File
          </Button>
        </>
      )}
    </InputWrapper>
  )
}

export default UploadSingleFile
