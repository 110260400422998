import dayjs from 'dayjs'

import { initPagination } from 'constants/pagination'
import { OP } from 'redux/actionTypes'
import { getAPI, postAPI, putAPI } from 'utils/api'
import { hideLoading, showLoading } from '../loading'
import { formatDate } from 'utils/dateHelpers'

const { GET, POST, PUT, CLEAR } = OP

const deliveriesParams = {
  populate: {
    files: {
      filters: { status: 'Active' },
      populate: {
        file: {
          fields: ['mime', 'url', 'createdAt'],
        },
        employee: {
          fields: ['first_name', 'last_name'],
        },
      },
    },
    document: {
      populate: {
        business_doc: {
          fields: ['id', 'doc_id'],
        },
        signers: {
          populate: {
            employee: {
              fields: ['first_name', 'last_name'],
              populate: {
                position: {
                  fields: ['name'],
                },
              },
            },
          },
        },
      },
    },
  },
}

export default function OPsp({ headers, company_info, employee_info }) {
  const getSP = () => ({
    list: (payload) => {
      const { page, pageSize } = payload?.pagination || initPagination
      const { REQUEST, SUCCESS, FAILURE } = GET.SPs

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: {
              ...payload?.filters,
              company: { id: company_info?.id },
              department: { id: employee_info?.department_id },
            },
            sort: ['status:desc', 'createdAt:desc'],
            pagination: { page, pageSize },
            fields: ['name', 'code', 'status', 'start_date', 'createdAt'],
            populate: {
              sp_temp: {
                fields: ['name', 'code'],
              },
              employee: {
                fields: ['first_name', 'last_name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: '/api/op-sps',
            headers,
            params,
          })

          let updatedData = {}

          if (data) {
            updatedData = await Promise.all(
              data.map(async (sp) => {
                const { data: dashboard } = await getAPI({
                  url: `/api/op-sps/${sp.id}/dashboard`,
                  headers,
                })

                return {
                  ...sp,
                  due_date: dashboard.due_date,
                  progress: dashboard.progress,
                  created_by: sp.employee.first_name + ' ' + sp.employee.last_name,
                }
              })
            )
          }

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    byId: (spId) => {
      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = GET.SP_BY_ID

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const spParams = {
            fields: ['code', 'name', 'description', 'status', 'start_date'],
            populate: {
              sp_temp: true,
              company: {
                fields: ['id'],
                populate: {
                  working_times: true,
                },
              },
              tags: {
                fields: ['name'],
              },
              sws: {
                populate: {
                  department: {
                    fields: ['name'],
                  },
                  positions: {
                    fields: ['name'],
                  },
                  employee: {
                    fields: ['first_name', 'last_name'],
                  },
                  tasks: {
                    populate: {
                      deliveries: deliveriesParams,
                    },
                  },
                  deliveries: deliveriesParams,
                  reviewers: {
                    fields: ['code', 'hr', 'min', 'due_date', 'status'],
                    populate: {
                      position: {
                        fields: ['name'],
                      },
                      employee: {
                        fields: ['first_name', 'last_name'],
                        populate: {
                          department: {
                            fields: ['name'],
                          },
                        },
                      },
                    },
                  },
                  approver: {
                    fields: ['code', 'hr', 'min', 'due_date', 'status'],
                    populate: {
                      position: {
                        fields: ['name'],
                      },
                      employee: {
                        fields: ['first_name', 'last_name'],
                        populate: {
                          department: {
                            fields: ['name'],
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          }

          const { data } = await getAPI({
            url: `/api/op-sps/${spId}`,
            headers,
            params: spParams,
          })

          if (!data) throw new Error('SP not found!')

          const sws = data.sws.map((sw) => ({ id: sw.id, name: sw.name, code: sw.code, status: sw.status }))

          const { data: dashboard } = await getAPI({
            url: `/api/op-sps/${spId}/dashboard`,
            headers,
          })

          return dispatch({ type: SUCCESS, data: { ...data, dashboard }, options: sws })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    files: (spId, payload) => {
      const { page, pageSize } = payload?.pagination || initPagination

      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = GET.SP_BY_ID_FILES

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: { ...payload?.filters, status: 'active' },
            sort: ['createdAt:desc'],
            pagination: { page, pageSize },
            fields: ['name'],
            populate: {
              file: {
                fields: ['mime', 'url', 'createdAt'],
              },
              employee: {
                fields: ['id', 'first_name', 'last_name'],
              },
              sw: {
                fields: ['name'],
              },
              task: {
                fields: ['name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `/api/op-sps/${spId}/files`,
            headers,
            params,
          })

          const updatedData = data.map((file) => {
            return {
              ...file,
              created_by: file?.employee.first_name + ' ' + file?.employee.last_name,
              createdAt: dayjs(file?.createdAt).format('DD MMM YYYY') || null,
            }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    deliveries: (spId, payload) => {
      const { page, pageSize } = payload?.pagination || initPagination

      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = GET.SP_BY_ID_DELIVERIES

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: { ...payload?.filters, status: 'active' },
            sort: ['createdAt:desc'],
            pagination: { page, pageSize },
            fields: ['name'],
            populate: {
              file: {
                fields: ['mime', 'url', 'createdAt'],
              },
              employee: {
                fields: ['id', 'first_name', 'last_name'],
              },
              sw: {
                fields: ['name'],
              },
              task: {
                fields: ['name'],
              },
              delivery: {
                fields: ['name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `/api/op-sps/${spId}/delivery-files`,
            headers,
            params,
          })

          const updatedData = data.map((file) => {
            return {
              ...file,
              created_by: file?.employee.first_name + ' ' + file?.employee.last_name,
              createdAt: dayjs(file?.createdAt).format('DD MMM YYYY') || null,
            }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    logs: (spId, payload) => {
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }
      const { REQUEST, SUCCESS, FAILURE } = GET.SP_BY_ID_LOGS

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: {
              ...(payload?.filters || {}),
            },
            pagination: { page, pageSize },
            sort: ['createdAt:desc'],
            fields: ['action', 'createdAt'],
            populate: {
              employee: {
                fields: ['first_name', 'last_name'],
              },
              approver: {
                fields: ['id'],
                populate: {
                  position: {
                    fields: ['name'],
                  },
                },
              },
              reviewer: {
                fields: ['id'],
                populate: {
                  position: {
                    fields: ['name'],
                  },
                },
              },
              sw: {
                fields: ['code', 'name'],
              },
              task: {
                fields: ['code', 'name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `/api/op-sps/${spId}/logs`,
            headers,
            params,
          })

          const updatedData = data.map((log) => {
            const created_by = log?.employee ? log.employee?.first_name + ' ' + log.employee?.last_name : null
            const createdAt = formatDate(log?.createdAt) || null

            return {
              ...log,
              created_by,
              createdAt,
            }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const postSP = () => ({
    create: (payload) => {
      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = POST.SP

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const company = { id: company_info?.id }
          const department = { id: employee_info?.department_id }

          const { data, meta } = await postAPI({
            url: '/api/op-sps',
            headers,
            data: {
              ...payload,
              id: payload?.sp_temp?.id,
              company,
              department,
            },
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    file: (spId, payload) => {
      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = POST.SP_FILE

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data, meta } = await postAPI({
            url: `/api/op-sps/${spId}/files`,
            headers,
            data: payload,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    cancel: (spId) => {
      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = POST.SP_CANCEL

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data, meta } = await postAPI({
            url: `/api/op-sps/${spId}/cancel`,
            headers,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const putSP = (spId, payload) => {
    return async (dispatch) => {
      const { REQUEST, SUCCESS, FAILURE } = PUT.SP

      dispatch(showLoading())
      dispatch({ type: REQUEST })

      try {
        const { data, meta } = await putAPI({
          url: `/api/op-sps/${spId}`,
          headers,
          data: payload,
        })

        return dispatch({ type: SUCCESS, data, meta })
      } catch (error) {
        return dispatch({ type: FAILURE, error })
      } finally {
        dispatch(hideLoading())
      }
    }
  }

  const clearSP = () => {
    return (dispatch) => {
      dispatch({ type: CLEAR.SP })
    }
  }

  // const clearSPTemplate = () => {
  //   return (dispatch) => {
  //     dispatch({ type: CLEAR.SP_TEMPLATE })
  //   }
  // }

  return {
    getSP,
    postSP,
    putSP,
    clearSP,
    // clearSPTemplate,
  }
}
