import React from 'react'
import { useTranslation } from 'react-i18next'

import { Stack, useMediaQuery } from '@mui/material'
import PageHeader from 'components/common/PageHeader'
import Tabs from 'components/feature/operation-process/tabs'
import MainSection from './main-section'

const OPMyTaskPage = () => {
  const { t } = useTranslation()

  const desktopScreen = useMediaQuery('(min-width: 768px)')

  return (
    <Stack spacing={desktopScreen ? 3 : 2}>
      <React.Fragment key="Header">
        <PageHeader
          title={t('operation_process')}
          supportingText={t('operation_process_header_description')}
          extra={
            <Tabs alignSelf="stretch">
              <Tabs.Main activeTab="my-task" />
            </Tabs>
          }
        />
      </React.Fragment>

      <React.Fragment key="Body">
        <MainSection />
      </React.Fragment>
    </Stack>
  )
}

export default OPMyTaskPage
