import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { DateDisplay } from '../../utils/common'
import { getCompanyInfo,GetPermissionOther } from '../../utils/common'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import SearchText from '../../components/form/SearchText'
import TextField from '../../components/form/TextField'
import Table from '../../components/common/Table'
import SidePanel from '../../components/common/SidePanel'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import DialogConfirm from '../../components/dialog/DialogConfirm'
import Checkbox from '../../components/form/Checkbox'
import OutlineCheckbox from '../../components/form/OutlineCheckbox'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import CollapseMenu from '../../components/common/CollapseMenu'
import Modal from '../../components/common/Modal'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import DialogSuccess from '../../components/dialog/DialogSuccess'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/icon-edit.svg'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { GiCancel } from 'react-icons/gi'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { LiaTimesSolid } from 'react-icons/lia'
import { IoDocumentTextOutline } from 'react-icons/io5'
import { RiSearchLine } from 'react-icons/ri'
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import changeStatusIcon from '../../assets/images/change-status.png'
import successIcon from '../../assets/images/success-icon.png'

import {
  getApplicationForms,
  getApplicationTypeList,
  getApplicationRequireList,
  createApplicationForm,
  getApplicationFormById,
  updateApplicationForm,
  changeApplicationStatus,
  sendInvite,
  resendInvite,
} from '../../redux/actions/applicationForm'
import { selectApplicationForm } from '../../redux/selectors'

import { useTranslation } from 'react-i18next'

import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .mr-8-px {
    margin-right: 8px;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .align-items-center {
    align-items: center;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 12px;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-2rem {
    font-size: 1.5rem;
  }

  .badge {
    padding: 2px 8px;
    align-items: center;
    color: var(--Gray-blue-700);
    background: var(--Gray-blue-50);
    border: 1px solid var(--Gray-blue-200);
    border-radius: 16px;

    &.recruiter {
      color: var(--Purple-700);
      background: var(--Purple-50);
      border: 1px solid var(--Purple-200);
    }
  }

  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid var(--Status-Icon-Stroke);
    color: var(--Status-Icon-Font);
    background: var(--Status-Icon-Bg);

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: var(--Status-Active);

      &.inactive {
        color: var(--Status-Inactive);
      }

      &.draft {
        color: var(--Status-Draft);
      }
    }
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .search-container {
    display: grid;
    grid-template-columns: repeat(3, 250px) 120px;
    grid-template-rows: auto;
    align-content: center;
    column-gap: 1rem;
    margin-bottom: 1rem;
  }

  .content-header {
    margin-top: 24px;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error-input {
    border: 1px solid red;
  }

  .side-panel-container {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .side-panel-container-2 {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .create-access-role-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-header {
    color: var(--gray-700);
    font-size: 16px;
    font-weight: 500;
  }

  .permission-header {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .permission-content {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .text-underline {
    text-decoration: underline;
  }
  .text-color-primary {
    color: #7f56d9;
  }
  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .tab-content {
    overflow: auto;
    .view-information-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }
  }

  .share-list-search-container {
    display: grid;
    grid-template-columns: repeat(2, 10rem) 8rem;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--Gray-200, #eaecf0);
  }

  .modal-content {
    width: 640px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--Gray-600);
  }

  .change-status-content {
    padding: 20px;
    width: 384px;
  }

  .category-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    height: 500px;

    .category {
      padding: 1rem 2rem;
      border-right: 1px solid var(--Gray-200);
      .category-item {
        display: flex;
        align-items: center;
        padding: 8px;
        border-radius: 8px;
        background: var(--Base-White);
        align-items: center;
        cursor: pointer;

        &.active {
          background: var(--Primary-600);
          color: var(--Base-White);
        }
      }
    }

    .category-view {
      padding: 1rem 2rem;
      .view-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .view-link {
          cursor: pointer;
          color: var(--Primary-700);
          font-weight: 600;
        }
      }
    }
  }

  .search-content {
    padding: 10px;
    border-bottom: 1px solid var(--Gray-200);
  }

  .search-input {
    position: relative;
    color: var(--Gray-500);

    .input-field {
      box-sizing: border-box;
      padding: 10px;
      padding-left: 40px;
      border: none;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .start-icon {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .end-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .preview-button {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }

  .headline {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .error {
    color: var(--Error-600);
  }

  .error-border {
    border: 1px solid var(--Error-300);
  }
`

const initApplicationForm = {
  name: '',
  active_date: undefined,
  expire_date: undefined,
  application_form_type: null,
  application_form_requires: [],
}

const initValidateForm = {
  name: null,
  active_date: null,
  expire_date: null,
}

const initFormInvite = {
  name: '',
  email: '',
}

const initMessageModal = {
  headline: '',
  message: '',
}

const ApplicationForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const CompanyInfo = getCompanyInfo()

  const { t } = useTranslation()
  const initLang = localStorage.getItem('lang')
  //--- ST ส่วนของ Permission LV3-4
  const PermissionNewApplicationForm = GetPermissionOther('application_form', 'new_aplication_form') ? GetPermissionOther('application_form', 'new_aplication_form') : GetPermissionOther('Application form', 'New Application form');
  const PermissionEditApplicationForm = GetPermissionOther('application_form', 'edit_pplication_form') ? GetPermissionOther('application_form', 'edit_pplication_form') : GetPermissionOther('Application form', 'Edit Application form');
  const PermissionDeleteApplicationForm = GetPermissionOther('application_form', 'delete_pplication_form') ? GetPermissionOther('application_form', 'delete_pplication_form') : GetPermissionOther('Application form', 'Delete Application form');
  const PermissionViewApplicationForm = GetPermissionOther('application_form', 'view_application_form') ? GetPermissionOther('application_form', 'view_application_form') : GetPermissionOther('Application form', 'View Application form');
  /*
  const { permission } = JSON.parse(localStorage.getItem('admin'))
  const [PermissionNewApplicationForm, setPermissionNewApplicationForm] = useState(null);
  let PermissionEditApplicationForm = null;
  const [PermissionDeleteApplicationForm, setPermissionDeleteApplicationForm] = useState(null);
  let PermissionViewApplicationForm = null;
  */
  /*
  useEffect(() => {
    const this_sub_menu_permission = permission['Human Resources & Organization'].filter((it_2) => it_2.path === '/organization-management/application-form'); //--- Path ของ SubMenu
    
    if(this_sub_menu_permission.length > 0){
      
      const this_PermissionNewApplicationForm = this_sub_menu_permission[0].permission_other?.find((it_2) => it_2.menu_setting.rout_path === 'ApplicationForm_NewApplicationForm');
      
      if(this_PermissionNewApplicationForm){
        setPermissionNewApplicationForm(this_PermissionNewApplicationForm)
        //alert(JSON.stringify(PermissionNewApplicationForm.is_read))
      }
      const this_PermissionEditApplicationForm = this_sub_menu_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'ApplicationForm_EditApplicationForm');
      if(this_PermissionEditApplicationForm){
        //setPermissionEditApplicationForm(this_PermissionEditApplicationForm)
        PermissionEditApplicationForm = this_PermissionEditApplicationForm;
      }
      const this_PermissionDeleteApplicationForm = this_sub_menu_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'ApplicationForm_DeleteApplicationForm');
      if(this_PermissionDeleteApplicationForm){
        setPermissionDeleteApplicationForm(this_PermissionDeleteApplicationForm)
      }
      const this_PermissionViewApplicationForm = this_sub_menu_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'ApplicationForm_ViewApplicationForm');
      if(this_PermissionViewApplicationForm){
        PermissionViewApplicationForm = this_PermissionViewApplicationForm;
      }
    }
  }, [])
  */
  //--- ED ส่วนของ Permission LV3-4

  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)
  const initStatusDropdown = [
    {
      value: 'active',
      text: t('active'),
    },
    {
      value: 'inactive',
      text: t('inactive'),
    },
  ]

  const shareLinkListColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('email'),
      accessor: 'email',
      disableSortBy: false,
    },
    {
      Header: t('name'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
    },
    {
      Header: t('Invitation_date'),
      accessor: 'createdAt',
    },
    {
      accessor: 'sendInvitationBtn',
      disableSortBy: false,
    },
  ]

  const submissionListColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('first_name'),
      accessor: 'first_name',
      disableSortBy: false,
    },
    {
      Header: t('last_name'),
      accessor: 'last_name',
      disableSortBy: false,
    },
    {
      Header: t('email'),
      accessor: 'email',
      disableSortBy: false,
    },
    {
      Header: t('phone_number'),
      accessor: 'phone_no',
      disableSortBy: false,
    },
    {
      Header: t('submission_date'),
      accessor: 'createdAt',
    },
    {
      accessor: 'viewBtn',
      disableSortBy: false,
    },
  ]
  const {
    applicationForms,
    total,
    limit,
    page,
    pageCount,
    isLoading,
    applicationTypes,
    applicationRequires,
    applicationInfo,
    verifyApplicationInfo,
    state,
  } = useSelector(selectApplicationForm)
  // data

  const applicationFormColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('application_form_id'),
      accessor: 'id',
      disableSortBy: false,
    },
    {
      Header: t('application_form_name'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('type'),
      accessor: 'application_type',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('open_position'),
      accessor: 'open_position',
      disableSortBy: false,
    },
    {
      Header: t('position_no'),
      accessor: 'position_no',
      disableSortBy: false,
    },
    {
      Header: t('submission'),
      accessor: 'submission',
      disableSortBy: false,
    },
    {
      Header: t('created_date'),
      accessor: 'createdAt',
    },
    {
      Header: t('expired_date'),
      accessor: 'expire_date',
      disableSortBy: false,
    },
    {
      Header: t('action'),
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const [applicationFormList, setApplicationFormList] = useState([])
  const [applicationId, setApplicationId] = useState('')
  const [status, setStatus] = useState('')
  const [tabViewMenu, setTabViewMenu] = useState('form-information')
  const [applicationForm, setApplicationForm] = useState(initApplicationForm)
  const [applicationEditForm, setApplicationEditForm] = useState(initApplicationForm)
  const [submissionList, setSubmissionList] = useState([])
  const [shareLinkList, setShareLinkList] = useState([])
  const [viewRequireList, setViewRequireList] = useState([])
  const [formInvite, setFormInvite] = useState(initFormInvite)
  const [tabCategory, setTabCategory] = useState('normal-request')
  const [statusForChange, setStatusForChange] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  const [typeSelected, setTypeSelected] = useState('')
  const [statusSelected, setStatusSelected] = useState('')
  const [applicationName, setApplicationName] = useState('')
  // drop down list
  const [applicationTypeList, setApplicationTypeList] = useState([])
  const [applicationRequireList, setApplicationRequireList] = useState([])
  const [initApplicationRequireList, setInitApplicationRequireList] = useState([])
  // validate
  const [errorsAccessRole, setErrorsAccessRole] = useState(initValidateForm)
  // selected
  const [search, setSearch] = useState('')
  // side panel
  const [isOpenSidePanelCreate, setIsOpenSidePanelCreate] = useState(false)
  const [isOpenSidePanelEdit, setIsOpenSidePanelEdit] = useState(false)
  const [isOpenSidePanelView, setIsOpenSidePanelView] = useState(false)
  // modal
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false)
  const [precreateApplicationModal, setPrecreateApplicationModal] = useState(false)
  const [changeStatusModal, setChangeStatusModal] = useState(false)
  const [confirmCreateModal, setConfirmCreateModal] = useState(false)
  const [confirmChangeStatusModal, setConfirmChangeStatusModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  // modal message
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const fetchData = useCallback(
    async (filter) => {
      dispatch(getApplicationForms(filter))
    },
    [dispatch]
  )

  const viewApplicationForm = useCallback(
    async (id) => {
      
      if(PermissionViewApplicationForm?.canUpdate === true){
        navigate(`/organization-management/application-form/${id}`)
      }else{
        let message_permission = 'No permission allowed ' + PermissionViewApplicationForm?.name_en;
        if(initLang == 'th'){
          message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + PermissionViewApplicationForm?.name_thh;
        }
        setTitleFail(message_permission);
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }
    },
    [dispatch]
  )

  const resentInvite = useCallback(
    async (id) => {
      const request = {
        data: {
          action: 'resentinvite',
        },
      }
      dispatch(resendInvite(applicationId, id, request))
    },
    [dispatch, applicationId]
  )

  const editApplicationForm = useCallback(
    (id) => {
      // dispatch(getApplicationFormById(id))
      // setIsOpenSidePanelEdit(true)
      // setApplicationId(id)

      

      if(PermissionEditApplicationForm?.canUpdate === true){
        navigate(`/organization-management/application-form/${id}`)
      }else{
        let message_permission = 'No permission allowed ' + PermissionEditApplicationForm?.name_en;
        if(initLang == 'th'){
          message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + PermissionEditApplicationForm?.name_th;
        }
        setTitleFail(message_permission);
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }
    },
    [dispatch]
  )

  const deleteApplicationForm = useCallback((id) => {
    setApplicationId(id)
    setConfirmDeleteModal(true)
  }, [])

  const toggleStatus = useCallback((id, name, status) => {
    setApplicationId(id)
    setApplicationName(name)
    setChangeStatusModal(true)
    setStatusForChange(status)
  }, [])

  useEffect(() => {
    if (applicationInfo?.application_form_requires?.data.length > 0) {
      const applicationRequireChecked = applicationInfo?.application_form_requires?.data.map((v) => v.id)
      const newApplicationRequireList = initApplicationRequireList.map((v) => ({
        ...v,
        checked: applicationRequireChecked.includes(v.value),
      }))
      setApplicationRequireList(newApplicationRequireList)
    }

    setApplicationEditForm((prev) => ({
      ...prev,
      name: applicationInfo?.name,
      active_date: applicationInfo?.active_date,
      expire_date: applicationInfo?.expire_date,
      application_form_type: applicationInfo?.application_form_type?.data?.id,
      application_form_requires: [],
    }))
  }, [applicationInfo, initApplicationRequireList])

  useEffect(() => {
    dispatch(getApplicationTypeList())
    dispatch(getApplicationRequireList())
  }, [dispatch])

  useEffect(() => {
    const newApplicationTypes = applicationTypes.map((v) => ({
      text: v.name,
      value: v.id,
    }))
    setApplicationTypeList(newApplicationTypes)
  }, [applicationTypes])

  useEffect(() => {
    const newApplicationRequires = applicationRequires.map((v) => ({
      text: v.name,
      value: v.id,
      checked: true,
      // checked: v.name == 'Information' ? true : false,
    }))
    setApplicationRequireList(newApplicationRequires)
    setInitApplicationRequireList(newApplicationRequires)
  }, [applicationRequires])

  useEffect(() => {
    const newApplicationForms = applicationForms.map((v, i) => {
      let list = [
        {
          text: t('Edit'),
          action: () => editApplicationForm(v.id),
        },
        {
          text: t('change_status'),
          action: () => toggleStatus(v.id, v.name, v.status),
        },
      ]
      return {
        ...v,
        name: (
          <div className="text-bold link" onClick={() => viewApplicationForm(v.id)}>
            {v.name}
          </div>
        ),
        application_type:
          v.application_type != '' ? (
            <span className={`badge ${v.application_type == 'Employee Form' ? '' : 'recruiter'}`}>
              {v.application_type}
            </span>
          ) : (
            <span>-</span>
          ),
        status: (
          <div className="status-badge">
            <GrStatusGoodSmall className={`status ${v.status == 'inactive' ? 'inactive' : ''}`} />
            {v.status}
          </div>
        ),
        action: <CollapseMenu list={list} />,
        createdAt: DateDisplay(v.createdAt),
        expire_date: DateDisplay(v.expire_date),
      }
    })
    setApplicationFormList(newApplicationForms)
  }, [applicationForms, viewApplicationForm, editApplicationForm, toggleStatus])

  useEffect(() => {
    if (applicationInfo?.application_form_requires?.data?.length > 0) {
      const ids = applicationInfo?.application_form_requires?.data?.map((v) => v.id)
      setViewRequireList(ids)
    }
  }, [applicationInfo?.application_form_requires])

  useEffect(() => {
    if (applicationInfo?.share_link?.length > 0) {
      const newShareLinkList = applicationInfo?.share_link?.map((v, i) => ({
        ...v,
        no: i + 1,
        createdAt: displayDate(v.createdAt),
        sendInvitationBtn: <Button onClick={() => resentInvite(v.id)}>Send Invitation</Button>,
      }))
      setShareLinkList(newShareLinkList)
    } else {
      setShareLinkList([])
    }
  }, [applicationInfo?.share_link, resentInvite])

  useEffect(() => {
    if (applicationInfo?.submission_list?.length > 0) {
      const newSubmissionList = applicationInfo?.submission_list?.map((v, i) => ({
        ...v,
        no: i + 1,
        viewBtn: <Button>View</Button>,
      }))
      setSubmissionList(newSubmissionList)
    } else {
      setSubmissionList([])
    }
  }, [applicationInfo?.submission_list])

  useEffect(() => {
    const filter = {
      skip: 1,
      limit: limit,
      filters: searchFilters.map((v) => v.name),
      app_type: typeSelected,
      status: statusSelected,
    }
    fetchData(filter)
  }, [fetchData, typeSelected, statusSelected, limit])

  useEffect(() => {
    if (state == 'CREATE_APPLICATION_FORM.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: t('success_create_application_form_message'),
        message: '',
      })
      setSuccessModal(true)
    }
    if (state == 'UPDATE_APPLICATION_FORM.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: t('success_edit_application_form_message'),
        message: '',
      })
      setSuccessModal(true)
    }
    if (state == 'CHANGE_APPLICATION_FORM_STATUS.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: t('success_change_status_message'),
        message: '',
      })
      setSuccessModal(true)
    }
  }, [state, isLoading])

  // onChangeForm
  const onChangeFormInvite = (event) => {
    const { value, name } = event.target
    setFormInvite((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeApplicationRequirement = (value, id) => {
    const newApplicationRequires = [...applicationRequireList]
    const index = newApplicationRequires.findIndex((v) => v.value == id)
    newApplicationRequires[index].checked = value
    setApplicationRequireList(newApplicationRequires)
  }

  const onChangeApplicationForm = (event) => {
    const { name, value } = event.target
    setApplicationForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeApplicationDropdown = (name, value) => {
    setApplicationForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeApplicationDate = (name, value) => {
    setApplicationForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeApplicationEditForm = (event) => {
    const { name, value } = event.target
    setApplicationEditForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeEditApplicationDropdown = (name, value) => {
    setApplicationEditForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeEditApplicationDate = (name, value) => {
    setApplicationEditForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangePagination = async (value) => {
    const filter = {
      skip: value.page,
      limit: limit,
      filters: searchFilters.map((v) => v.name),
      app_type: typeSelected,
      status: statusSelected,
    }
    fetchData(filter)
  }

  // validate
  const validateForm = () => {
    let newErrors = {
      ...initValidateForm,
    }

    if (!applicationForm.name) {
      newErrors.name = 'Please fill in Form Name'
    }

    setErrorsAccessRole(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  const validateFormEdit = () => {
    let newErrors = {
      ...initValidateForm,
    }

    if (!applicationEditForm.name) {
      newErrors.name = 'Please fill in Form Name'
    }

    setErrorsAccessRole(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  // submit
  const onSubmitCreateApplicationForm = async () => {
    const request = {
      data: {
        ...applicationForm,
        active_date: applicationForm.active_date ? applicationForm.active_date : dayjs().format('YYYY-MM-DD'),
        expire_date: applicationForm.expire_date ? applicationForm.expire_date : dayjs().format('YYYY-MM-DD'),
        application_form_requires: applicationRequireList.filter((v) => v.checked == true).map((v) => v.value),
        //---- ฟิวใหม่For Phane2 04/09/2023
        company : CompanyInfo?.id,
      },
    }
    dispatch(createApplicationForm(request))
    setIsOpenSidePanelCreate(false)
    setConfirmCreateModal(false)
    clearForm()
  }

  const onSubmitEditApplicationForm = () => {
    const isValid = validateFormEdit()

    if (isValid) {
      const request = {
        data: {
          ...applicationEditForm,
          active_date: applicationEditForm.active_date ? applicationEditForm.active_date : dayjs().format('YYYY-MM-DD'),
          expire_date: applicationEditForm.expire_date ? applicationEditForm.expire_date : dayjs().format('YYYY-MM-DD'),
          application_form_requires: applicationRequireList.filter((v) => v.checked == true).map((v) => v.value),
        },
      }
      dispatch(updateApplicationForm(applicationId, request))
      clearForm()
      setIsOpenSidePanelEdit(false)
    }
  }

  const confirmDelete = async () => {
    const request = {
      data: {
        status: 'delete',
      },
    }
    dispatch(changeApplicationStatus(applicationId, request))
    setConfirmDeleteModal(false)
  }

  const confirmToggleStatus = async () => {
    setChangeStatusModal(false)
    const request = {
      data: {
        status: statusForChange,
      },
    }

    dispatch(changeApplicationStatus(applicationId, request))
    setConfirmChangeStatusModal(false)
  }

  const onSearch = () => {
    const newFilter = {
      key: uuidv4(),
      name: search,
    }
    const filter = {
      skip: 1,
      limit: limit,
      filters: [...searchFilters, newFilter].map((v) => v.name),
      app_type: typeSelected,
      status: statusSelected,
    }
    fetchData(filter)
    setSearch('')
    setSearchFilters((prev) => [...prev, newFilter])
  }

  const onClearSearch = () => {
    const filter = {
      skip: 1,
      limit: limit,
      filters: [],
      app_type: '',
      status: '',
    }
    fetchData(filter)
    setSearch('')
    setStatusSelected('')
    setTypeSelected('')
    setSearchFilters([])
  }

  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)

    const filter = {
      skip: 1,
      limit: limit,
      filters: newSearchFilters.map((v) => v.name),
      app_type: typeSelected,
      status: statusSelected,
    }
    fetchData(filter)
    setSearch('')
  }

  const sentInvite = () => {
    const request = {
      data: {
        ...formInvite,
        application_form: applicationId,
      },
    }

    dispatch(sendInvite(request))
    setFormInvite(initFormInvite)
  }

  const clearForm = () => {
    setApplicationForm(initApplicationForm)
    setApplicationEditForm(initApplicationForm)
    const initApplicationRequireList = applicationRequireList.map((v) => ({
      ...v,
      checked: true,
    }))
    setApplicationRequireList(initApplicationRequireList)
    setErrorsAccessRole(initValidateForm)
  }

  // modal
  const confirmSuccess = () => {
    setSuccessModal(false)
  }

  const openConfirmCreateModal = () => {
    const isValid = validateForm()
    if (isValid) {
      setConfirmCreateModal(true)
    }
  }

  const openConfirmChangeStatusModal = () => {
    setConfirmChangeStatusModal(true)
  }

  const newApplicationForm = () => {
    
    if(PermissionNewApplicationForm?.canUpdate === true){
      setPrecreateApplicationModal(true)
    }else{
      let message_permission = 'No permission allowed ' + PermissionNewApplicationForm?.name_en;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + PermissionNewApplicationForm?.name_th;
      }
      setTitleFail(message_permission);
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }
  }

  const openApplicationFormCreate = (application_form_type) => {
    setApplicationForm((prev) => ({
      ...prev,
      application_form_type,
    }))
    setPrecreateApplicationModal(false)
    setIsOpenSidePanelCreate(true)
  }

  const closeCreateRolePanel = () => {
    setIsOpenSidePanelCreate(false)
    clearForm()
  }

  const closeEditRolePanel = () => {
    setIsOpenSidePanelEdit(false)
    clearForm()
  }

  const closeViewRolePanel = () => {
    setIsOpenSidePanelView(false)
    clearForm()
  }

  const closePrecreateApplicationModal = () => {
    setPrecreateApplicationModal(false)
  }

  const closeChangeStatusModal = () => {
    setChangeStatusModal(false)
  }

  // optional
  const toggleTabCategoryRequest = (category) => {
    setTabCategory(category)
  }
  const displayDate = (inputDate) => {
    const date = dayjs(inputDate)
    const formattedDate = date.format('DD/MMMM/YYYY')
    return formattedDate
  }

  return (
    <Div>
      <header>
        <div className="content-title">{t('application_form')}</div>
        <div className="">{t('employee_sub_header')}</div>
        <div className="content-header">
          <div className="search-container">
            <SearchText
              className="mb-1-rem"
              placeholder={t('search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSubmit={onSearch}
              onClear={onClearSearch}
            />
            <Dropdown
              placeHolder={t('type')}
              optionList={applicationTypeList}
              value={typeSelected}
              onChange={(value) => setTypeSelected(value)}
            />
            <Dropdown
              placeHolder={t('status')}
              optionList={initStatusDropdown}
              value={statusSelected}
              onChange={(value) => setStatusSelected(value)}
            />
            <ButtonOutline className="d-flex justify-content-center" onClick={onClearSearch}>
              {t('clear')}
            </ButtonOutline>
          </div>
          <div className="badge-search-content">
            {searchFilters.map((v) => (
              <div className="badge" key={v.key}>
                <span className="name">{v.name}</span>{' '}
                <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
              </div>
            ))}
          </div>
        </div>
      </header>
      <div className="table-wrapper">
        <div className="table-header">
          <div className="group">
            <div className="table-title">{t('application_form_list')}</div>
            <div className="table-total">{total} {t('applications')}</div>
          </div>

          <div>
            {PermissionNewApplicationForm?.canRead === true && (
              <Button onClick={newApplicationForm} append={<img src={iconPlus} />}>
                {t('new_application_form')}
              </Button>
            )}
          </div>
        </div>
        <Table
          columns={applicationFormColumn}
          data={applicationFormList}
          onStateChange={onChangePagination}
          pageCount={pageCount}
          page={page}
        />

        {/* Modal */}
        <Modal open={Boolean(precreateApplicationModal)} onClose={closePrecreateApplicationModal} disableBackdropClick>
          <Div>
            <div className="search-content">
              <div className="search-input">
                <RiSearchLine className="start-icon" />
                <input className="input-field" placeholder={t('search_application_form')} />
                <LiaTimesSolid size={25} onClick={closePrecreateApplicationModal} className="end-icon icon" />
              </div>
            </div>
            <div className="modal-content">
              <div className="category-content">
                <div className="category">
                  <div className="mb-1-rem">{t('category')}</div>
                  <div
                    className={`category-item ${tabCategory == 'normal-request' ? 'active' : ''}`}
                    onClick={() => toggleTabCategoryRequest('normal-request')}
                  >
                    <IoDocumentTextOutline className="mr-8-px" />
                    <span>หมวดคำขอทั่วไป</span>
                  </div>
                </div>
                <div className="category-view">
                  <div className="view-item">
                    <span>แบบฟอร์มข้อมูลพนักงาน</span>
                    <span className="view-link" onClick={() => openApplicationFormCreate(1)}>
                      {t('view')}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Div>
        </Modal>
        <Modal open={Boolean(changeStatusModal)} onClose={closeChangeStatusModal} disableBackdropClick>
          <Div>
            <div className="change-status-content">
              <div className="d-flex justify-content-center mb-1-rem">
                <img src={changeStatusIcon} />
              </div>
              <div className="headline d-flex justify-content-center mb-1-rem">{t('change_status')}</div>
              <div className="input-new mb-1-rem">
                <Dropdown
                  isStatus={true}
                  placeHolder="Status"
                  optionList={initStatusDropdown}
                  value={statusForChange}
                  onChange={(value) => setStatusForChange(value)}
                />
              </div>
              <div className="button-content">
                <ButtonOutline onClick={() => setChangeStatusModal(false)} className="d-flex justify-content-center">
                  {t('cancel')}
                </ButtonOutline>
                <Button onClick={openConfirmChangeStatusModal} className="d-flex justify-content-center">
                  {t('update')}
                </Button>
              </div>
            </div>
          </Div>
        </Modal>
        {/* Dialog */}
        <DialogConfirmDelete
          open={Boolean(confirmDeleteModal)}
          onClose={() => setConfirmDeleteModal(false)}
          onSubmit={confirmDelete}
          title={t('confrim_delete')}
          description={t('do_you_want_to_confirm_the_deletion_of_the_access_role')}
        />
        <DialogConfirm
          open={confirmModal}
          onClose={() => setConfirmModal(false)}
          onSubmit={confirmToggleStatus}
          description={`${t('do_you_want_to_confirm_to')} ${status} ${t('the_application_form')}`}
          textYes={t('confirm')}
          textNo={t('cancel')}
        />
        <DialogConfirm
          open={Boolean(confirmCreateModal)}
          onClose={() => setConfirmCreateModal(false)}
          onSubmit={onSubmitCreateApplicationForm}
          title={t('confirm_create_application_form_header')}
          description={applicationForm.name}
          textYes={t('confirm')}
          textNo={t('back')}
        />
        <DialogConfirm
          open={Boolean(confirmChangeStatusModal)}
          onClose={() => setConfirmChangeStatusModal(false)}
          onSubmit={confirmToggleStatus}
          title={t('confirm_change_status_header')}
          description={applicationName}
          textYes={t('confirm')}
          textNo={t('back')}
        />
        <DialogSuccess
          open={Boolean(successModal)}
          onClose={() => setSuccessModal(false)}
          onSubmit={confirmSuccess}
          icon={successIcon}
          title={successMessageModal.headline}
          description={successMessageModal.message}
          textYes={t('done')}
        />

        {/* Create Application Panel */}
        <SidePanel isOpen={isOpenSidePanelCreate} setIsOpen={setIsOpenSidePanelCreate} width={450}>
          <Div>
            <div className="side-panel-container">
              <div>
                <div className="d-flex justify-content-between align-items-center mb-2-rem">
                  <div className="create-access-role-header">{t('new_application_form')}</div>
                  <LiaTimesSolid size={25} onClick={closeCreateRolePanel} className="icon" />
                </div>

                {/* <div className="mb-1-rem">
                <div className="input-header mb-1-rem">Application Type</div>
                <div className="input-new">
                  <Dropdown
                    optionList={applicationTypeList}
                    value={applicationForm.application_form_type}
                    onChange={(value) => onChangeApplicationDropdown('application_form_type', value)}
                  />
                </div>
              </div> */}

                <div className="mb-1-rem">
                  <div className="input-header mb-1-rem">{t('application_form_name')}</div>
                  <div className="input-new">
                    <TextField
                      className={`${errorsAccessRole.name ? 'error-border' : ''}`}
                      name="name"
                      value={applicationForm.name}
                      onChange={onChangeApplicationForm}
                      placeholder={t('application_form_name')}
                    />
                    {errorsAccessRole.name && <div className="error mt-1-rem">{errorsAccessRole.name}</div>}
                  </div>
                </div>

                <div className="mb-1-rem">
                  <div className="input-header mb-1-rem">{t('active_date')}</div>
                  <Date
                    name="active_date"
                    value={applicationForm.active_date}
                    onChange={(value) => onChangeApplicationDate('active_date', value)}
                  />
                </div>

                <div className="mb-1-rem">
                  <div className="input-header mb-1-rem">{t('expire_date')}</div>
                  <Date
                    name="expire_date"
                    value={applicationForm.expire_date}
                    onChange={(value) => onChangeApplicationDate('expire_date', value)}
                  />
                </div>

                <div className="input-header mb-1-rem">{t('about_this_form')}</div>
                <div className="mb-1-rem">{t('this_form_including')}</div>
                <ul>
                  <li>{t('form_information')}</li>
                  <li>{t('education')}</li>
                  <li>{t('resume')}</li>
                  <li>{t('work_permit')}</li>
                </ul>

                <Link
                  to={`/organization-management/application-form/view-create-application-form?requireList=1,2,3,4`}
                  target="_blank"
                >
                  <ButtonOutline className="preview-button">{t('preview_form')}</ButtonOutline>
                </Link>

                {/* 
              {applicationRequireList.map((v, i) => (
                <div className="d-flex align-items-center mb-1-rem" key={v.value}>
                  <OutlineCheckbox
                    className="mr-1-rem"
                    checked={v?.checked}
                    onChange={() => onChangeApplicationRequirement(!v?.checked, v.value)}
                    disabled={true}
                  />
                  <span>{v.text}</span>
                </div>
              ))} */}
              </div>

              <div>
                <div className="divider"></div>
                <div className="d-flex justify-content-between">
                  <div>{/* <ButtonOutline>Save</ButtonOutline> */}</div>
                  <div>
                    <ButtonOutline onClick={closeCreateRolePanel} className="mr-1-rem">
                      {t('cancel')}
                    </ButtonOutline>
                    <Button onClick={openConfirmCreateModal} id="saveEdit">
                    {t('create')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Div>
        </SidePanel>
        {/* Edit Application Panel */}
        <SidePanel isOpen={isOpenSidePanelEdit} setIsOpen={setIsOpenSidePanelEdit} width={450}>
          <Div>
            <div className="side-panel-container">
              <div className="d-flex justify-content-between align-items-center mb-2-rem">
                <div className="create-access-role-header">{t('edit_application_form')}</div>
                <LiaTimesSolid size={25} onClick={closeEditRolePanel} className="icon" />
              </div>

              {/* <div className="mb-1-rem">
                <div className="input-header mb-1-rem">Application Type</div>
                <div className="input-new">
                  <Dropdown
                    optionList={applicationTypeList}
                    value={applicationEditForm.application_form_type}
                    onChange={(value) => onChangeEditApplicationDropdown('application_form_type', value)}
                  />
                </div>
              </div> */}
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('application_form_name')}</div>
                <div className="input-new">
                  <TextField
                    className={`${errorsAccessRole.name ? 'error-border' : ''}`}
                    name="name"
                    value={applicationEditForm.name}
                    onChange={onChangeApplicationEditForm}
                    placeholder={t('application_form_name')}
                  />
                  {errorsAccessRole.name && <div className="error mt-1-rem">{errorsAccessRole.name}</div>}
                </div>
              </div>
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('active_date')}</div>
                <Date
                  name="active_date"
                  value={applicationEditForm.active_date}
                  onChange={(value) => onChangeEditApplicationDate('active_date', value)}
                />
              </div>
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('expire_date')}</div>
                <Date
                  name="expire_date"
                  value={applicationEditForm.expire_date}
                  onChange={(value) => onChangeEditApplicationDate('expire_date', value)}
                />
              </div>
              <div className="input-header mb-1-rem">{t('about_this_form')}</div>
              <div>
                {applicationRequireList.map((v) => (
                  <div className="d-flex align-items-center mb-1-rem" key={v.value}>
                    <OutlineCheckbox
                      key={v.value}
                      className="mr-1-rem"
                      checked={v?.checked}
                      onChange={() => onChangeApplicationRequirement(!v?.checked, v.value)}
                      disabled={true}
                    />
                    <span>{v.text}</span>
                  </div>
                ))}
              </div>

              <div className="divider"></div>
              <div className="d-flex justify-content-flex-end">
                <ButtonOutline onClick={closeEditRolePanel} className="mr-1-rem">
                  {t('cancel')}
                </ButtonOutline>
                <Button onClick={onSubmitEditApplicationForm} id="saveEdit">
                  {t('save')}
                </Button>
              </div>
            </div>
          </Div>
        </SidePanel>
        {/* View Role Panel */}
        <SidePanel isOpen={isOpenSidePanelView} setIsOpen={setIsOpenSidePanelView} width={800}>
          <Div>
            <div className="side-panel-container">
              <div className="d-flex justify-content-flex-end">
                <GiCancel size={30} onClick={closeViewRolePanel} className="icon" />
              </div>
              <div className="create-access-role-header">{applicationInfo.name}</div>
              <div className="d-flex justify-content-flex-end mb-2-rem">
                <Link
                  to={`/organization-management/application-form/view-create-application-form?requireList=${viewRequireList?.join()}`}
                  target="_blank"
                >
                  <Button className="mr-1-rem">{t('preview_form')}</Button>
                </Link>
                <Button onClick={() => editApplicationForm(applicationId)}>{t('Edit')}</Button>
              </div>
              <div className="tab-menu-container mb-2-rem">
                <div>
                  <span
                    id="form-information"
                    className={`tab-menu ${tabViewMenu === 'form-information' ? 'active' : ''}`}
                    onClick={() => setTabViewMenu('form-information')}
                  >
                    {t('form_information')}
                  </span>
                </div>
                <div>
                  <span
                    id="submission-list"
                    className={`tab-menu ${tabViewMenu === 'submission-list' ? 'active' : ''}`}
                    onClick={() => setTabViewMenu('submission-list')}
                  >
                    {t('submission_list')}
                  </span>
                </div>
                <div>
                  <span
                    id="share-list"
                    className={`tab-menu ${tabViewMenu === 'share-list' ? 'active' : ''}`}
                    onClick={() => setTabViewMenu('share-list')}
                  >
                    {t('share_list')}
                  </span>
                </div>
              </div>

              {tabViewMenu === 'form-information' ? (
                <div className="tab-content">
                  <div className="view-information-container mb-2-rem">
                    <span className="text-color-primary text-bold">{t('application_type')}</span>
                    <span>{applicationInfo?.application_form_type?.data?.attributes?.name}</span>
                    <span className="text-color-primary text-bold">{t('start_active_date')}</span>
                    <span>{displayDate(applicationInfo?.active_date)}</span>
                  </div>
                  <div className="view-information-container">
                    <span className="text-color-primary text-bold">{t('information_requirement')}</span>
                    <div>
                      {applicationRequireList.map((v) => (
                        <div className="d-flex align-items-center mb-1-rem" key={v.value}>
                          <OutlineCheckbox
                            className="mb-1-rem"
                            checked={v?.checked}
                            onChange={(value) => onChangeApplicationRequirement(v?.checked, v.value)}
                            disabled={true}
                          />
                          <span>{v.text}</span>
                        </div>
                      ))}
                    </div>
                    <span className="text-color-primary text-bold">{t('expired_date')}</span>
                    <span>{displayDate(applicationInfo?.expire_date)}</span>
                  </div>
                </div>
              ) : tabViewMenu === 'submission-list' ? (
                <div className="tab-content">
                  <Table columns={submissionListColumn} data={submissionList} />
                </div>
              ) : (
                <div className="tab-content">
                  <div className="share-list-search-container mb-1-rem">
                    <TextField
                      name="email"
                      value={formInvite.email}
                      onChange={onChangeFormInvite}
                      placeholder={t('email')}
                    />
                    <TextField name="name" value={formInvite.name} onChange={onChangeFormInvite} placeholder={t('name')} />
                    <Button onClick={sentInvite}>{t('send_invite')}</Button>
                  </div>
                  <Table columns={shareLinkListColumn} data={shareLinkList} />
                </div>
              )}
            </div>
          </Div>
        </SidePanel>
      </div>
      <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
      />
    </Div>
  )
}

export default ApplicationForm
