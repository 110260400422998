import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { selectDocumentTemplate } from '../../../redux/selectors'

import styled from 'styled-components'
import TextField from '../../form/TextField'

import { LiaTimesSolid } from 'react-icons/lia'
import Button from '../../form/button/Button'
import ButtonOutline from '../../form/button/ButtonOutline'
import Dropdown from '../../form/Dropdown'
import RadioButton from '../../form/RadioButton'

import { BiTrash } from 'react-icons/bi'
import { AiOutlinePlus } from 'react-icons/ai'

import PropTypes from 'prop-types'

const Wrapper = styled.div`
  padding: 24px;
  height: 100%;

  color: var(--Gray-600);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .icon {
    cursor: pointer;
  }

  .d-flex {
    display: flex;
  }
  .justify-content-end {
    justify-content: flex-end;
  }

  .mr-12-px {
    margin-right: 12px;
  }
  .mt-24-px {
    margin-top: 24px;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }

  .headline-panel {
    color: var(--Gray-900);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
  }

  .sub-headline-panel {
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--Gray-200);
  }

  .item-vertical-align-center {
    vertical-align: middle;
  }

  .preview-btn {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Primary-700);
  }
`
const InputContainer = styled.div`
  .width-100 {
    width: 100%;
  }
  .width-80 {
    width: 80%;
  }
  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.375rem;
  }
  .error-input {
    border: 1px solid var(--Error-300);
  }
  .error {
    color: var(--Error-500);
  }
`

const initLangRadioOptionList = [
  {
    text: 'Thai',
    value: 'th',
  },
  {
    text: 'English',
    value: 'en',
  },
]
const initTopicRadioOptionList = [
  {
    text: 'With Topic',
    value: 'with_topic',
  },
  {
    text: 'Without Topic',
    value: 'without_topic',
  },
]
const initTypeDropdown = [
  {
    text: 'Info',
    value: 'Info',
  },
  {
    text: 'Acknowledge',
    value: 'Acknowledge',
  },
  // {
  //   text: 'Sign',
  //   value: 'Sign',
  // },
  {
    text: 'Acknowledge and Sign',
    value: 'Acknowledge and Sign',
  },
]

const initForm = {
  name: '',
  category: '',
  type: '',
  doc_lang: 'th',
  doc_topic: 'with_topic',
  topics: [
    {
      key: uuidv4(),
      title: '',
    },
  ],
}
const initValidate = {
  name: null,
  category: null,
  type: null,
  topics: [null],
}
function EditTemplate({ onSubmit, openPreview, close }) {
  const { t } = useTranslation()
  const { documentTemplateInfo, categories, isLoading, state } = useSelector(selectDocumentTemplate)
  const [isEdit, setIsEdit] = useState(false)
  // form
  const [templateForm, setTemplateForm] = useState(initForm)
  // dropdown list
  const [categoryList, setCategoryList] = useState([])
  // validate
  const [validateForm, setValidateForm] = useState(initValidate)

  useEffect(() => {
    if (state === 'EDIT_DOCUMENT_TEMPLATE.SUCCESS' && isLoading === false) {
      setIsEdit(false)
    }
    if (state === 'EDIT_DOCUMENT_TEMPLATE.FAILURE' && isLoading === false) {
      let newErrors = {
        ...initValidate,
      }
      newErrors.name = 'This document already existed.'
      setValidateForm(newErrors)
    }
  }, [isLoading, state])

  useEffect(() => {
    const newCategoryList = categories.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setCategoryList(newCategoryList)
  }, [categories])

  useEffect(() => {
    const { name, category, type, doc_lang, doc_topic, topics } = documentTemplateInfo
    setTemplateForm((prev) => ({
      ...prev,
      name,
      category: category?.id,
      type,
      doc_lang,
      doc_topic,
      topics:
        topics?.length === 0
          ? [
              {
                key: uuidv4(),
                title: '',
              },
            ]
          : topics?.map((v) => ({
              key: uuidv4(),
              title: v.title,
            })),
    }))
  }, [documentTemplateInfo])

  // on change
  const onChangeForm = (event) => {
    const { name, value } = event.target

    setTemplateForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeDropdown = (name, value) => {
    setTemplateForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeTopic = (key, event) => {
    const { value } = event.target
    const matchedIndex = templateForm.topics.findIndex((v) => v.key === key)
    const copyTopics = [...templateForm.topics]
    copyTopics[matchedIndex].title = value

    setTemplateForm((prev) => ({
      ...prev,
      topics: copyTopics,
    }))
  }
  const addTopic = () => {
    const newTopic = {
      key: uuidv4(),
      title: '',
    }

    const newTopics = [...templateForm.topics, newTopic]
    setTemplateForm((prev) => ({
      ...prev,
      topics: newTopics,
    }))
  }
  const deleteTopic = (key) => {
    if (isEdit) {
      const newTopics = templateForm.topics.filter((v) => v.key !== key)
      setTemplateForm((prev) => ({
        ...prev,
        topics: newTopics,
      }))
    }
  }
  const toggleEdit = () => {
    setIsEdit(true)
  }

  // validate function
  const isValid = () => {
    let newErrors = {
      ...initValidate,
    }

    if (templateForm.name === '') {
      newErrors.name = 'Document template name is required'
    }
    if (templateForm.category === '') {
      newErrors.category = 'Category is required'
    }
    if (templateForm.type === '') {
      newErrors.type = 'Type is required'
    }
    if (templateForm.doc_topic === 'with_topic') {
      let isInvalidTopic = templateForm.topics.map((v) => {
        return v.title === '' ? 'Topic name is required' : null
      })
      newErrors.topics = isInvalidTopic
    }
    setValidateForm(newErrors)

    const { topics, ...restErrors } = newErrors
    return Object.values(restErrors).every((x) => x === null) && topics.every((x) => x === null)
  }

  // submit
  const onSave = () => {
    const isValidate = isValid()
    if (isValidate) {
      const request = {
        data: {
          ...templateForm,
          topics: templateForm.doc_topic === 'with_topic' ? templateForm.topics : [],
        },
      }

      onSubmit(request)
    }
  }

  return (
    <Wrapper>
      <div className="first-content">
        <div className="d-flex justify-content-end">
          <LiaTimesSolid className="icon" size={25} onClick={close} />
        </div>

        <div className="headline-panel">{t('view_document_template')}</div>
        <div className="sub-headline-panel">{t('sub_headline_template')}</div>

        <div className="divider mt-24-px mb-24-px"></div>

        <InputContainer className="mb-24-px">
          <div className="input-header">{t('document_template_name')}</div>
          <TextField
            disabled={!isEdit}
            className={`width-100 mb-8-px ${validateForm.name ? 'error-input' : ''}`}
            name="name"
            value={templateForm.name}
            onChange={onChangeForm}
          />
          {validateForm.name && <div className="error">{validateForm.name}</div>}
          <div>(e.g. company information)</div>
        </InputContainer>
        <InputContainer className="mb-24-px">
          <div className="input-header">{t('category')}</div>
          <Dropdown
            disabled={!isEdit}
            className={`width-100 mb-8-px ${validateForm.category ? 'error-input' : ''}`}
            optionList={categoryList}
            value={templateForm.category}
            onChange={(value) => onChangeDropdown('category', value)}
          />
          {validateForm.category && <div className="error">{validateForm.category}</div>}
        </InputContainer>
        <InputContainer className="mb-24-px">
          <div className="input-header">{t('type')}</div>
          <Dropdown
            disabled={!isEdit}
            className={`width-100 mb-8-px ${validateForm.type ? 'error-input' : ''}`}
            optionList={initTypeDropdown}
            value={templateForm.type}
            onChange={(value) => onChangeDropdown('type', value)}
          />
          {validateForm.type && <div className="error">{validateForm.type}</div>}
        </InputContainer>
        <div className="divider mb-24-px"></div>
        <InputContainer className="mb-24-px">
          <div className="input-header">{t('document_lang_version')}</div>
          <RadioButton
            disabled={!isEdit}
            name="doc_lang"
            optionList={initLangRadioOptionList}
            value={templateForm.doc_lang}
            handleChange={onChangeForm}
          />
        </InputContainer>
        <div className="divider mb-24-px"></div>
        <InputContainer className="mb-24-px">
          <div className="input-header">{t('topic_paragraph_with_document')}</div>
          <RadioButton
            disabled={!isEdit}
            name="doc_topic"
            optionList={initTopicRadioOptionList}
            value={templateForm.doc_topic}
            handleChange={onChangeForm}
          />
        </InputContainer>
        <div className="divider mb-24-px"></div>
        {templateForm.doc_topic === 'with_topic' &&
          templateForm.topics.map((v, i) => (
            <InputContainer className="mb-24-px" key={v.key}>
              <TextField
                disabled={!isEdit}
                className={`width-80 mb-8-px mr-12-px item-vertical-align-center ${
                  validateForm?.topics[i] ? 'error-input' : ''
                }`}
                name="title"
                value={v.title}
                onChange={(e) => onChangeTopic(v.key, e)}
              />
              {i !== 0 && (
                <BiTrash className="icon item-vertical-align-center" size={20} onClick={() => deleteTopic(v.key)} />
              )}
              {validateForm?.topics[i] && <div className="error">{validateForm?.topics[i]}</div>}
            </InputContainer>
          ))}

        <ButtonOutline className="preview-btn mb-24-px" disabled={!isEdit} onClick={addTopic}>
          <AiOutlinePlus className="mr-12-px" size={18} /> {t('more_topic')}
        </ButtonOutline>
      </div>
      <div className="second-content">
        <div className="divider mb-24-px"></div>
        <div className="d-flex justify-content-end mb-24-px">
          <ButtonOutline className="mr-12-px" onClick={close}>
            {t('cancel')}
          </ButtonOutline>
          <ButtonOutline className="preview-btn mr-12-px" onClick={openPreview}>
            {t('preview')}
          </ButtonOutline>

          {isEdit ? <Button onClick={onSave}> {t('save')}</Button> : <Button onClick={toggleEdit}> {t('Edit')}</Button>}
        </div>
      </div>
    </Wrapper>
  )
}

EditTemplate.propTypes = {
  onSubmit: PropTypes.func,
  openPreview: PropTypes.func,
  close: PropTypes.func,
}

export default EditTemplate
