export default function IconPlus({
    width = 14,
    color = "#333",
    style,
    degree = 0,
    className,
  }) {
    return (
      <svg
        width={width}
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ ...style, transform: "rotate(" + degree + "deg)" }}
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M106.5 0.21875C115.337 0.21875 122.5 7.38219 122.5 16.2188V90.5H196.781C205.618 90.5 212.781 97.6634 212.781 106.5C212.781 115.337 205.618 122.5 196.781 122.5H122.5V196.781C122.5 205.618 115.337 212.781 106.5 212.781C97.6634 212.781 90.5 205.618 90.5 196.781V122.5H16.2188C7.38219 122.5 0.21875 115.337 0.21875 106.5C0.21875 97.6634 7.38219 90.5 16.2188 90.5H90.5V16.2188C90.5 7.38219 97.6634 0.21875 106.5 0.21875Z"
          fill={color}
        />
      </svg>
    );
  }