import { getAPI, postAPI, putAPI, deleteAPI } from '../../utils/api'
import { FIELD_TYPE } from '../../utils/constants'
import {
  GET_CMS_ITEM_LIST,
  GET_CMS_CONTENT_TYPE,
  GET_CMS_CONTENT_CONFIG,
  CREATE_CMS_CONTENT,
  GET_CMS_ITEM_DETAIL,
  UPDATE_CMS_CONTENT,
  DELETE_CMS_CONTENT,
} from '../actionTypes'
import { showLoading, hideLoading } from '../actions/loading'
import { selectCmsContentType, selectCmsContentTypeByUid, selectCmsByType } from '../selectors'

export const getCmsContentType = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CMS_CONTENT_TYPE.REQUEST })
    const { data } = await getAPI({
      url: '/content-manager/content-types',
    })

    return dispatch({
      type: GET_CMS_CONTENT_TYPE.SUCCESS,
      data,
    })
  } catch (err) {
    return dispatch({ type: GET_CMS_CONTENT_TYPE.FAILURE, error: err })
  }
}

export const getCmsContentConfig = (contentType) => async (dispatch, getState) => {
  const { uid } = selectCmsContentType(contentType)(getState())
  try {
    dispatch({ type: GET_CMS_CONTENT_CONFIG.REQUEST })
    const { data } = await getAPI({
      url: `/content-manager/content-types/${uid}/configuration`,
    })

    return dispatch({
      type: GET_CMS_CONTENT_CONFIG.SUCCESS,
      contentType,
      config: data,
    })
  } catch (err) {
    return dispatch({ type: GET_CMS_CONTENT_CONFIG.FAILURE, error: err })
  }
}

export const getCmsItemList =
  (contentType, option = {}) =>
  async (dispatch, getState) => {
    const {
      config: stateConfig = {},
      sort: stateSort = {},
      filter: stateFilter = [],
      search: stateSearch = '',
      data: { pagination: { page: statePage } = {} } = {},
    } = selectCmsByType(contentType)(getState())

    const { uid, info: { pluralName } = {} } = selectCmsContentType(contentType)(getState())

    let config = stateConfig

    try {
      dispatch({ type: GET_CMS_ITEM_LIST.REQUEST, contentType })

      if (!stateConfig.contentType) {
        const res = await dispatch(getCmsContentConfig(contentType))
        config = res.config
      }

      const {
        contentType: { settings },
      } = config

      const sortId = option.sort?.id || stateSort.id || settings.defaultSortBy
      const sortOrder = option.sort?.order || stateSort.order || settings.defaultSortOrder
      const filter = option.filter || stateFilter
      const search = option.search ?? stateSearch
      const page = option.page || statePage || 1

      const genObject = (structure, value) => {
        const [key, ...rest] = structure.split('.')
        if (!rest?.length) {
          return { [key]: value }
        }
        return { [key]: genObject(rest.join('.'), value) }
      }
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const data = await getAPI(
        {
          // url: `content-manager/collection-types/${uid}`,
          url: `/api/${pluralName}?populate=*`,
          headers,
          params: {
            'pagination[page]': page,
            'pagination[pageSize]': settings.pageSize,
            sort: `${sortId}:${sortOrder}`,
            _q: search,
            filters: {
              $and: filter.map((v) => genObject(`${v.field}.${v.condition}`, v.value)),
            },
          },
        },
        true
      )

      return dispatch({
        type: GET_CMS_ITEM_LIST.SUCCESS,
        contentType,
        data: {
          results: data.data.map((v) => ({ id: v.id, ...v.attributes })),
          pagination: data.meta.pagination,
        },
        sort: { id: sortId, order: sortOrder },
        filter,
        search,
      })
    } catch (err) {
      return dispatch({ type: GET_CMS_ITEM_LIST.FAILURE, contentType, error: err })
    }
  }

export const getCmsItemDetail = (contentType, id) => async (dispatch, getState) => {
  const { uid, attributes = {} } = selectCmsContentType(contentType)(getState())
  const { config } = selectCmsByType(contentType)(getState())
  const { contentType: { metadatas = {} } = {} } = config || {}

  try {
    dispatch(showLoading())
    dispatch({ type: GET_CMS_ITEM_DETAIL.REQUEST })

    const data = await getAPI({
      url: `/content-manager/collection-types/${uid}/${id}`,
    })

    const fetchList = []
    const relationKey = Object.keys(attributes).filter((k) => attributes[k].type === FIELD_TYPE.relation)

    relationKey.forEach((v) =>
      fetchList.push(
        getAPI({
          url: `/content-manager/relations/${uid}/${id}/${v}`,
          params: {
            pageSize: data[v].count || 1,
            page: 1,
          },
        })
      )
    )

    const res = await Promise.all(fetchList)

    relationKey.forEach((k, i) => {
      if (res[i].data) {
        // For relation type is toOne
        data[k] = res[i].data.id
      } else if (res[i].results) {
        // for relation type is toMany
        const { mainField } = metadatas[k]?.edit || {}
        data[k] = res[i].results.map((v) => ({ text: v[mainField || 'id'], value: v.id }))
      } else {
        data[k] = undefined
      }
    })

    return dispatch({
      type: GET_CMS_ITEM_DETAIL.SUCCESS,
      contentType,
      data,
    })
  } catch (err) {
    return dispatch({ type: GET_CMS_ITEM_DETAIL.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const createCmsContent = (uid, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_CMS_CONTENT.REQUEST })
    const { id } = await postAPI({
      url: `/content-manager/collection-types/${uid}`,
      data,
    })

    await postAPI({
      url: `/content-manager/collection-types/${uid}/${id}/actions/publish`,
    })

    return dispatch({
      type: CREATE_CMS_CONTENT.SUCCESS,
      showAlert: true,
      alertMessage: 'Create content successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_CMS_CONTENT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateCmsContent = (uid, id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_CMS_CONTENT.REQUEST })
    await putAPI({
      url: `/content-manager/collection-types/${uid}/${id}`,
      data,
    })

    return dispatch({
      type: UPDATE_CMS_CONTENT.SUCCESS,
      showAlert: true,
      alertMessage: 'Update content successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_CMS_CONTENT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteCmsContent = (uid, id) => async (dispatch, getState) => {
  try {
    const { apiID } = selectCmsContentTypeByUid(uid)(getState())
    dispatch(showLoading())
    dispatch({ type: DELETE_CMS_CONTENT.REQUEST })
    await deleteAPI({
      url: `/content-manager/collection-types/${uid}/${id}`,
    })

    dispatch(getCmsItemList(apiID))
    return dispatch({
      type: DELETE_CMS_CONTENT.SUCCESS,
    })
  } catch (err) {
    return dispatch({ type: DELETE_CMS_CONTENT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}
