import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyleButton = styled.button`
  display: inline-flex;
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;

  &.Mui-disabled {
    opacity: 0.5;
    cursor: default;
  }
`

const IconButton = ({ src, icon, ...props }) => {
  return <StyleButton {...props}>{src ? <img src={src} /> : icon}</StyleButton>
}

IconButton.propTypes = {
  src: PropTypes.string,
}

export default IconButton
