import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateDisplay } from '../../../utils/common'

import styled from 'styled-components'
import Card from '../../common/Card'

import { selectEmployeeProfile, selectMaster } from '../../../redux/selectors'
import { getEmployeeByIDForProfile } from '../../../redux/actions/employeeProfile'
import { getDepartmentsAndPositionsByCompany } from '../../../redux/actions/master'

const Div = styled.div`
  .mr-12-px {
    margin-right: 12px;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-12-px {
    margin-bottom: 12px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }

  .file-link {
    cursor: pointer;
  }
`
const InfoContainer = styled.div`
  .heading-name {
    color: var(--Gray-900);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
`
const BlogInfo = styled.section`
  margin-bottom: 20px;
  .card-width-100 {
    width: 100%;
  }
  .heading-info {
    color: var(--Gray-900);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }
  .info-heading {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    width: 30%;
  }
  .info {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .info-2 {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    width: 65%;
  }
  .file {
    width: 65%;
  }
  .file-info {
    .name {
      color: var(--Gray-900);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .size {
      color: var(--Gray-600);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .edit-btn {
    padding: 8px;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-end {
    justify-content: flex-end;
  }

  &.align-item-center {
    align-items: center;
  }
`

function EmploymentPosition() {
  const dispatch = useDispatch()
  const { employeeInfo } = useSelector(selectEmployeeProfile)
  const { departments } = useSelector(selectMaster)

  const fetchEmployeeProfileInfo = useCallback(() => {
    dispatch(getEmployeeByIDForProfile())
  }, [dispatch])
  const fetchDepartmentsAndPositionsByCompany = useCallback(
    (company_id) => {
      dispatch(getDepartmentsAndPositionsByCompany())
    },
    [dispatch]
  )

  useEffect(() => {
    const { company_info } = JSON.parse(localStorage.getItem('admin'))
    fetchEmployeeProfileInfo()
    fetchDepartmentsAndPositionsByCompany(company_info?.company_id)
  }, [])

  const displayDepartment = (id) => {
    const departmentMatch = departments.find((v) => v.id === id)
    return departmentMatch?.name
  }

  const displayPosition = (id) => {
    const departmentMatch = departments.find((v) => v.id === employeeInfo?.secondary_job[0]?.department)
    const positionMatch = departmentMatch?.positions?.find((v) => v.id === id)

    return positionMatch?.name
  }

  return (
    <Div>
      {' '}
      <InfoContainer className="mb-32-px">
        <h3 className="heading-name">Position & Projects</h3>
      </InfoContainer>
      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">Employment Details</h4>
        </header>
        <Card className="card-width-100">
          <FlexContainer className="align-item-center mb-12-px">
            <div className="info-heading">Employee Type</div>
            <div className="info-2">{employeeInfo?.employee_type ?? '-'}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Work Start Date</div>
            <div className="info">{DateDisplay(employeeInfo?.work_start_date) ?? '-'}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Evaluation Date</div>
            <div className="info">{DateDisplay(employeeInfo?.evaluation_date) ?? '-'}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Employee Status</div>
            <div className="info">{employeeInfo?.employee_type_status ?? '-'}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Work End Date</div>
            <div className="info">{DateDisplay(employeeInfo?.work_end_date) ?? '-'}</div>
          </FlexContainer>
        </Card>
      </BlogInfo>
      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">Primary Job Position</h4>
        </header>
        <Card className="card-width-100">
          <FlexContainer className="align-item-center mb-12-px">
            <div className="info-heading">Department</div>
            <div className="info-2">{employeeInfo?.department?.data?.attributes?.name ?? '-'}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Job position</div>
            <div className="info">{employeeInfo?.position?.data?.attributes?.name ?? '-'}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Position Level</div>
            <div className="info">{employeeInfo?.position_level?.data?.attributes?.name_th ?? '-'}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Supervisor</div>
            <div className="info">
              {employeeInfo?.supervisors?.data?.map((v) => {
                const { first_name, last_name } = v.attributes
                return `${first_name} ${last_name}`
              })}
            </div>
          </FlexContainer>
        </Card>
      </BlogInfo>
      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">Secondary Job Position</h4>
        </header>
        <Card className="card-width-100">
          <FlexContainer className="align-item-center mb-12-px">
            <div className="info-heading">Department</div>
            <div className="info-2">
              {employeeInfo?.secondary_job?.length > 0
                ? displayDepartment(employeeInfo?.secondary_job[0]?.department)
                : '-'}
            </div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Job position</div>
            <div className="info">
              {employeeInfo?.secondary_job?.length > 0
                ? displayPosition(employeeInfo?.secondary_job[0]?.position)
                : '-'}
            </div>
          </FlexContainer>
        </Card>
      </BlogInfo>
    </Div>
  )
}

export default EmploymentPosition
