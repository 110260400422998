import { Chip, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

/** Component modules */
import Button from 'components/common/Button'
import DynamicDialog from 'components/common/DynamicDialog'
import PageHeader from 'components/common/PageHeader'
import SidePanelV2 from 'components/common/SidePanelV2'
import EditCL from 'components/feature/operation-process/form/chain-link/edit'

/** Redux modules */
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'

import { CHAIN_LINK } from 'constants/operation-process'
import FileSection from './file-section'
import InfoSection from './info-section'

const Tabs = ({ onChange, items = [], defaultTab }) => {
  const [activeTab, setActiveTab] = useState()

  const tabItems = items

  useEffect(() => {
    onChange && onChange(activeTab)
  }, [activeTab])

  useEffect(() => {
    setActiveTab(defaultTab)
  }, [defaultTab])

  return (
    <TabsWrapper>
      {tabItems.map((tab) => (
        <div
          key={tab.id}
          className={`tab-item ${activeTab === tab.id ? 'active' : ''}`}
          onClick={() => setActiveTab(tab.id)}
        >
          {tab.label} {tab.count ? `(${tab.count})` : ''}
        </div>
      ))}
    </TabsWrapper>
  )
}

const initDrawer = {
  editCL: false,
  chainLinkId: undefined,
}

const OPChainLinkDetailsPage = () => {
  const { t } = useTranslation()
  const { id: chainLinkId } = useParams()
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)
  const refEditCL = useRef(null)

  const [currentSP, setCurrentSP] = useState(null)
  const [dialogProps, setDialogProps] = useState({ isOpen: false })
  const [drawer, setDrawer] = useState(initDrawer)

  const { chainLink: chainLinkActions } = opActions()
  const { getCL, postCL, putCL, clearCL } = chainLinkActions()
  const { chainLink } = opProcSelector
  const { code, name, description, start_date, status, chain_link_temp } = chainLink || {}

  const handleToggleDrawer = (drawerName, open, data = {}) => {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }

      setDrawer({ ...initDrawer, [drawerName]: open, ...data })
    }
  }

  const fetchCLById = async (clId) => {
    dispatch(getCL().byId(clId)).then((res) => {
      const { type, error } = res

      if (type.endsWith('_SUCCESS') && !error) {
        setCurrentSP(res?.data?.sps[0]?.id)
        return
      }
    })
  }

  const fetchCLLogs = async (clId) => {
    return dispatch(getCL().logs(clId))
  }

  const confirmedCancelCL = (chainLinkId) => {
    dispatch(postCL().cancel(chainLinkId)).then((res) => {
      const { type, error } = res

      if (type.endsWith('_SUCCESS') && !error) {
        setDialogProps({ isOpen: false })
        fetchCLById(chainLinkId)
        return
      }
    })
  }

  const handleCancelCL = (chainLinkId) => {
    const dialogConfirmCancel = {
      isOpen: true,
      title: 'Please confirm',
      description: 'Do you want to cancel this chain link?',
      actions: (
        <>
          <Button variant="outlined" onClick={() => setDialogProps({ isOpen: false })}>
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={() => confirmedCancelCL(chainLinkId)}>
            {t('confirm')}
          </Button>
        </>
      ),
    }

    setDialogProps(dialogConfirmCancel)
  }

  const handleEditCLSubmit = (data) => {
    const { id: clId } = data

    dispatch(putCL(clId, data)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        fetchCLById(chainLinkId)
        handleToggleDrawer('editCL', false)
        return
      }
    })
  }

  const pageActions = [
    <Button
      key="edit-btn"
      variant="outlined"
      disabled={!(status === CHAIN_LINK.STATUS.InQueue || status === CHAIN_LINK.STATUS.Open)}
      onClick={handleToggleDrawer('editCL', true, { chainLinkId })}
      sx={{ height: 'fit-content' }}
    >
      {t('Edit')}
    </Button>,
    <Button
      key="cancel-cl-btn"
      disabled={status === CHAIN_LINK.STATUS.Canceled || status === CHAIN_LINK.STATUS.Completed}
      variant="contained"
      color="error"
      onClick={() => handleCancelCL(chainLinkId)}
      sx={{ height: 'fit-content' }}
    >
      {t('cancel_chain_link')}
    </Button>,
  ]

  const handleTabChange = (tabId) => {
    setCurrentSP(tabId)
  }

  useEffect(() => {
    dispatch(clearCL())

    fetchCLById(chainLinkId)
    fetchCLLogs(chainLinkId)
  }, [dispatch])

  return (
    <PageContainer>
      <PageHeader
        title={
          <CustomHeader>
            <div className="header-title">
              <Typography variant="h4" fontWeight={600} fontSize={36} color={'var(--Gray-900)'}>
                {name}
              </Typography>

              <CustomBadge label={status || 'N/A'} color={CHAIN_LINK.STATUS_CHIP[status || 'default']} size="medium" />
            </div>
          </CustomHeader>
        }
        supportingText={`${t('code')}: ${code || '-'}`}
        actionProps={{ direction: 'row', justifyContent: 'flex-end', spacing: 2 }}
        actions={pageActions}
      />

      {chainLink?.sps && (
        <Tabs
          items={chainLink?.sps?.map((sp) => ({ id: sp.id, label: sp.name }))}
          onChange={handleTabChange}
          defaultTab={currentSP || chainLink?.sps[0]?.id}
        />
      )}

      {/* Dynamic tabs for displaying SP */}
      {currentSP && chainLink?.sps && chainLink?.sps?.length > 0 && (
        <PageContent sx={{ gap: '2.5rem' }}>
          <InfoSection currentSP={chainLink.sps.filter((sp) => sp?.id === currentSP)[0]} />
          <FileSection />
        </PageContent>
      )}

      <DynamicDialog open={dialogProps.isOpen} onClose={() => setDialogProps({ isOpen: false })} {...dialogProps} />

      <SidePanelV2
        titleText="Edit Chain Link"
        supportingText={`Chain Link Code: ${chainLink?.code || '...'}`}
        open={drawer.editCL}
        onClose={handleToggleDrawer('editCL', false)}
        footerEl={
          (chainLink.status === CHAIN_LINK.STATUS.InQueue || chainLink.status === CHAIN_LINK.STATUS.Open) && (
            <FooterDrawer>
              <Button variant="outlined" onClick={handleToggleDrawer('editCL', false)}>
                {t('cancel')}
              </Button>
              <Button variant="contained" onClick={() => refEditCL.current.submit()}>
                {t('save')}
              </Button>
            </FooterDrawer>
          )
        }
      >
        <EditCL ref={refEditCL} chainLinkId={drawer.chainLinkId} onSubmit={handleEditCLSubmit} />
      </SidePanelV2>
    </PageContainer>
  )
}

export default OPChainLinkDetailsPage

const FooterDrawer = styled('footer')({
  display: 'flex',
  width: '100%',
  gap: '1rem',
  justifyContent: 'flex-end',
  padding: '1rem',
})

const CustomHeader = styled(Stack)`
  align-self: stretch;
  gap: 1rem;

  & > .header-title {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
  }
`

const CustomBadge = styled(Chip)`
  display: flex;
  width: fit-content;
  padding: 0.1rem 0.7rem 0.1rem 0.575rem;
  align-items: center;
  gap: 0.25rem;
  cursor: default;

  & .MuiChip-icon {
    margin-left: 0;
    margin-right: 0.175rem;
  }

  & .MuiChip-label {
    padding: 0;
    text-align: center;

    /* Text xs/Medium */
    font-family: 'Inter', 'Noto Sans Thai';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 150% */
  }

  &.MuiChip-colorSuccess,
  &.MuiChip-colorInfo,
  &.MuiChip-colorWarning,
  &.MuiChip-colorDefault {
    border-radius: 1rem !important;
    color: var(--Gray-700, #344054);
    border: 1px solid var(--Gray-200, #eaecf0);
    background: var(--Gray-50, #f9fafb);
  }

  &.MuiChip-colorSuccess {
    color: var(--Success-700, #067647);
    border: 1px solid var(--Success-200, #abefc6) !important;
    background: var(--Success-50, #ecfdf3) !important;
  }

  &.MuiChip-colorWarning {
    color: var(--Warning-700, #b54708);
    border: 1px solid var(--Warning-200, #fedf89) !important;
    background: var(--Warning-50, #fffaeb) !important;
  }

  &.MuiChip-colorInfo {
    color: var(--Blue-700, #175cd3);
    border: 1px solid var(--Blue-200, #b2ddff) !important;
    background: var(--Blue-50, #eff8ff) !important;
  }
`

const PageContainer = styled(Stack)`
  @media screen and (max-width: 1025px) {
    padding: 1rem;
  }
`

const PageContent = styled(Stack)`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background: var(--Base-White);
`

export const TabsWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 1rem;

  transition: all 100ms ease-out;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);

  .tab-item {
    display: flex;
    height: 44px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: var(--Gray-700, #344054);
    /* Text sm/Semibold */
    font-family: 'Inter', 'Noto Sans Thai';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; /* 142.857% */

    cursor: pointer;
    transition: all 100ms ease-out;

    &.active {
      background: var(--Primary-50);
      border-bottom: 2px solid var(--Primary-600);
      color: var(--Primary-700);
    }

    &:not(.active) {
      &:hover {
        background: var(--Primary-50);
      }
    }
  }
`
