import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { DateDisplay } from '../../utils/common'

import { getAnalysisEmployeeInfo } from '../../redux/actions/employeeActivity'
import { selectEmployeeActivity } from '../../redux/selectors'

import styled from 'styled-components'
import Card from '../../components/common/Card'
import { PieChart, BarChart } from '@mui/x-charts'

import { LuAtom, LuMail, LuPenLine } from 'react-icons/lu'
import { MdKeyboardArrowLeft, MdOutlineArrowDownward, MdOutlineArrowUpward } from 'react-icons/md'
import { TbPointFilled, TbUserPlus } from 'react-icons/tb'

import phoneIcon from '../../assets/images/phone-icon.svg'
import noData from '../../assets/images/no-data.png'

// style
const Wrapper = styled.div`
  .mr-12-px {
    margin-right: 12px;
  }
  .mr-16-px {
    margin-right: 16px;
  }
  .mr-24-px {
    margin-right: 24px;
  }
  .mb-4-px {
    margin-bottom: 4px;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-10-px {
    margin-bottom: 10px;
  }
  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }
  .mt-12-px {
    margin-top: 12px;
  }
  .chart-container {
    position: relative;
    width: 100%;
    .percent-value {
      color: var(--Gray-900);
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 600;

      position: absolute;
      top: 60%;
      left: 33%;
    }
  }
  .chart-label-container {
    position: absolute;
    right: 2%;
    bottom: -15%;
    .pie-chart-color-label-1 {
      color: var(--Chart-Color-1);
    }
    .pie-chart-color-label-2 {
      color: var(--Chart-Color-2);
    }
    .pie-chart-color-label-3 {
      color: var(--Gray-200);
    }
  }
  .header-feature {
    color: var(--Gray-900);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
  }
  .sub-header-feature {
    color: var(--Gray-600);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;

    display: flex;
    justify-content: space-between;

    .powered-by {
      color: var(--Gray-500);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;

      display: flex;
      align-items: center;
    }
  }
  .divider {
    background-color: var(--Gray-200);
    height: 1px;
    margin: 20px 0;
  }
  .back-icon {
    display: inline-block;
    border-radius: 8px;
    border: 1px solid var(--Gray-300);
    background: var(--Base-White);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    padding: 6px 6px 2px 6px;

    cursor: pointer;
  }
  .badge {
    border-radius: 16px;
    border: 1px solid var(--BADGE-1-Border);
    background: var(--BADGE-1-Bg);

    color: var(--BADGE-1-Font);
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;

    display: inline-block;
    padding: 2px 8px;

    &.red-badge {
      border: 1px solid var(--BADGE-2-Border);
      background: var(--BADGE-2-Bg);
      color: var(--BADGE-2-Font);
    }

    &.green-badge {
      border: 1px solid var(--BADGE-4-Border);
      background: var(--BADGE-4-Bg);
      color: var(--BADGE-4-Font);
    }

    &.blue-badge {
      border: 1px solid var(--BADGE-5-Border);
      background: var(--BADGE-5-Bg);
      color: var(--BADGE-5-Font);
    }

    &.pink-badge {
      border: 1px solid var(--BADGE-12-Border);
      background: var(--BADGE-12-Bg);
      color: var(--BADGE-12-Font);
    }
  }
  .outline-btn {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }
  .text-headline {
    color: var(--Gray-900);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .power-by-ai {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
  .warning-text {
    color: var(--warning-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
  }
`
const EmployeeInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-left: 32px;

  .detail-content {
    display: flex;

    .image-container {
      img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
      }
    }
    .info-container {
      .name {
        color: var(--Gray-900);
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 600;
      }
      .department {
        color: var(--Gray-600);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
      }
      .position {
        color: var(--Primary-700);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
      }
      .status {
        color: var(--Gray-600);
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
      }
      .contact-container {
        color: var(--Gray-400);
      }
    }
  }
  .rest-content {
    .button-container {
      margin-bottom: 16px;
    }
    .date-info {
      color: #000;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;

      .highlight {
        font-weight: 600;
      }
    }
  }
`
const CardContainer = styled.div`
  .card-headline {
    color: var(--Gray-900);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .card-sub-headline {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
  .overall-container {
    display: flex;
    justify-content: center;

    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
  }
  .percent-score {
    &.red {
      color: var(--Error-700);
    }
    &.green {
      color: var(--success-700);
    }
  }
  .info-container {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    .value {
      color: var(--Gray-900);
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 600;

      .value-up-down {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 500;

        &.red-color {
          color: var(--Error-700);
        }
        &.green-color {
          color: var(--success-700);
        }
      }
    }
  }
  .dot {
    &.red {
      color: var(--Error-500);
    }
    &.green {
      color: var(--success-500);
    }
    &.orange {
      color: var(--warning-400);
    }
    &.gray {
      color: var(--Gray-300);
    }
  }
  .icon-badge {
    display: inline-block;
    border-radius: 10px;
    background: var(--Primary-200);
    padding: 12px 12px 8px 12px;

    color: var(--Primary-600);
  }
  .analysis-headline {
    color: var(--Gray-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
  }
  .lang-content {
    .lang-item {
      color: var(--Gray-500);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      &.active {
        color: var(--Primary-600);
        font-weight: 700;
      }
      :hover {
        cursor: pointer;
      }
    }
  }
`
const Flex2Content = styled.div`
  display: flex;
  justify-content: space-between;

  .width-30 {
    width: 30%;
  }
  .width-68 {
    width: 68%;
  }
  .width-49 {
    width: 49%;
  }
`
const Flex3Content = styled.div`
  display: flex;
  justify-content: space-between;

  .width-32 {
    width: 32%;
  }
`
const GroupButtonMenuContainer = styled.div`
  .menu-button {
    color: var(--Gray-700);
    font-weight: 600;
    padding: 10px 16px;
    border: 1px solid var(--Gray-300);
    cursor: pointer;
    &.right {
      border-radius: 0 8px 8px 0;
    }
    &.left {
      border-radius: 8px 0 0 8px;
    }
    &.active {
      color: var(--Base-White);
      background: var(--Primary-600);
      border: 1px solid var(--Primary-600);
    }
  }
`
const NoDataChart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 45px;

  .no-data-chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${(props) => props.bgImage});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 200px;
    width: 300px;
  }
  .no-data-chart-text {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
  }
`
// initial data
const xLabels = ['Plan', 'Complete', 'Unique']

function EmployeeActivityDetail() {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // store reducer
  const { analysisActivityEmployeeInfo } = useSelector(selectEmployeeActivity)
  // filter
  const [periodType, setPeriodType] = useState('last30day')
  // lang
  const [analysisLang, setAnalysisLang] = useState('en')
  const [recommendationLang, setRecommendationLang] = useState('en')

  const fetchData = useCallback(
    (filter) => {
      dispatch(getAnalysisEmployeeInfo(filter))
    },
    [dispatch]
  )

  // useEffect for fetching data
  useEffect(() => {
    const filter = {
      period_type: periodType,
      id,
    }
    fetchData(filter)
  }, [id, periodType])

  const filterByDay = (periodDay) => {
    setPeriodType(periodDay)
  }

  const goPreviousPage = () => {
    navigate(-1)
  }

  return (
    <Wrapper>
      <div className="header-feature">Employee Activity</div>
      <div className="sub-header-feature">
        <div>Gain insights into individual and team activities and performance</div>
        <div className="powered-by">
          <LuAtom className="mr-8-px" size={22} />
          Powered by AI
        </div>
      </div>

      <div className="divider"></div>

      <div className="mt-12-px mb-32-px">
        <div className="back-icon" onClick={goPreviousPage}>
          <MdKeyboardArrowLeft size={24} />
        </div>
      </div>

      <EmployeeInfoContainer className="mb-32-px">
        <div className="detail-content">
          <div className="image-container mr-24-px">
            <img src={analysisActivityEmployeeInfo?.employee?.photo} alt="employeeImage" />
          </div>
          <div className="info-container">
            <div className="name mb-4-px">
              {analysisActivityEmployeeInfo?.employee?.first_name} {analysisActivityEmployeeInfo?.employee?.last_name}
            </div>
            <div className="department">{analysisActivityEmployeeInfo?.employee?.department?.name}</div>
            <div className="position mb-8-px">
              Job Position : {analysisActivityEmployeeInfo?.employee?.position?.name}
            </div>
            <div className="status mb-10-px">
              <span>Status: </span>
              <span
                className={`badge ${
                  analysisActivityEmployeeInfo?.employee?.employee_type === 'Permanent' ? 'pink-badge' : 'blue-badge'
                }`}
              >
                {analysisActivityEmployeeInfo?.employee?.employee_type === 'Permanent'
                  ? 'Employee'
                  : analysisActivityEmployeeInfo?.employee?.employee_type}
              </span>
            </div>
            <div className="contact-container">
              <img src={phoneIcon} className="mr-16-px" alt="phoneIcon" />
              <LuMail size={22} />
            </div>
          </div>
        </div>
        <div className="rest-content">
          <GroupButtonMenuContainer className="mb-20-px">
            <span
              className={`menu-button left ${periodType === 'last30day' ? 'active' : ''}`}
              onClick={() => filterByDay('last30day')}
            >
              Last 30 days
            </span>
            <span
              className={`menu-button right ${periodType === 'last7day' ? 'active' : ''}`}
              onClick={() => filterByDay('last7day')}
            >
              Last 7 days
            </span>
          </GroupButtonMenuContainer>
          <div className="date-info">
            <span className="highlight">Data Period :</span>{' '}
            {DateDisplay(analysisActivityEmployeeInfo?.period_start_date)} -{' '}
            {DateDisplay(analysisActivityEmployeeInfo?.period_end_date)}
          </div>
        </div>
      </EmployeeInfoContainer>

      <Flex2Content className="mb-32-px">
        <Card className="width-30">
          <CardContainer>
            <div className="card-headline">Overall Activity Score</div>
            <div className="chart-container">
              {analysisActivityEmployeeInfo?.overall_perf_score ? (
                <>
                  <PieChart
                    colors={['var(--Chart-Color-1)', 'var(--Gray-200)']}
                    series={[
                      {
                        data: [
                          { value: analysisActivityEmployeeInfo?.overall_perf_score },
                          { value: 100 - analysisActivityEmployeeInfo?.overall_perf_score },
                        ],
                        innerRadius: 90,
                        outerRadius: 110,
                        paddingAngle: 0,
                        cornerRadius: 10,
                        startAngle: -90,
                        endAngle: 90,
                        cx: 150,
                        cy: 150,
                      },
                    ]}
                    width={300}
                    height={200}
                    sx={{ width: '100%', height: 'auto' }}
                  />
                  <div className="percent-value">{analysisActivityEmployeeInfo?.overall_perf_score} %</div>
                </>
              ) : (
                <NoDataChart bgImage={noData}>
                  <div className="no-data-chart-container">
                    <div className="no-data-chart-text">No data available</div>
                  </div>
                </NoDataChart>
              )}
            </div>
            <div className="divider"></div>
            {analysisActivityEmployeeInfo?.overall_perf_score && (
              <div className="overall-container">
                <span
                  className={`badge ${
                    analysisActivityEmployeeInfo?.performance_eva === 'Pass' ? 'green-badge' : 'red-badge'
                  }  mr-24-px`}
                >
                  {analysisActivityEmployeeInfo?.performance_eva}
                </span>
                <span
                  className={`percent-score ${
                    analysisActivityEmployeeInfo?.overall_perf_score_old?.status === 'up'
                      ? 'green'
                      : analysisActivityEmployeeInfo?.overall_perf_score_old?.status === 'down'
                      ? 'red'
                      : ''
                  }  mr-8-px`}
                >
                  {analysisActivityEmployeeInfo?.overall_perf_score_old?.status === 'up' ? (
                    <MdOutlineArrowUpward />
                  ) : analysisActivityEmployeeInfo?.overall_perf_score_old?.status === 'down' ? (
                    <MdOutlineArrowDownward />
                  ) : (
                    <span>{analysisActivityEmployeeInfo?.overall_perf_score_old?.status} </span>
                  )}
                  {Number(analysisActivityEmployeeInfo?.overall_perf_score_old?.percent).toFixed(1)}%
                </span>
                <span>last month</span>
              </div>
            )}
          </CardContainer>
        </Card>
        <Card className="width-68">
          <CardContainer>
            <Flex3Content className="mb-32-px">
              <div className="info-container">
                <div>
                  <TbPointFilled size={16} className="dot green" />
                  Complete Activity
                </div>
                <div className="value">
                  <span className="mr-12-px">{analysisActivityEmployeeInfo?.activity_complete ?? 0}</span>
                  {analysisActivityEmployeeInfo?.activity_complete_old && (
                    <span
                      className={`value-up-down ${
                        analysisActivityEmployeeInfo?.activity_complete_old?.status === 'up'
                          ? 'green-color'
                          : analysisActivityEmployeeInfo?.activity_complete_old?.status === 'down'
                          ? 'red-color'
                          : ''
                      }`}
                    >
                      {analysisActivityEmployeeInfo?.activity_complete_old?.status === 'up' ? (
                        <MdOutlineArrowUpward />
                      ) : analysisActivityEmployeeInfo?.activity_complete_old?.status === 'down' ? (
                        <MdOutlineArrowDownward />
                      ) : (
                        <span>{analysisActivityEmployeeInfo?.activity_complete_old?.status} </span>
                      )}
                      {analysisActivityEmployeeInfo?.activity_complete_old?.percent}%
                    </span>
                  )}
                </div>
              </div>
              <div className="info-container">
                <div>
                  <TbPointFilled size={16} className="dot orange" />
                  Job related tasks
                </div>
                <div className="value">
                  <span className="mr-12-px">{analysisActivityEmployeeInfo?.recruitment_task_complete ?? 0}</span>
                  {analysisActivityEmployeeInfo?.recruitment_task_complete_old && (
                    <span
                      className={`value-up-down ${
                        analysisActivityEmployeeInfo?.recruitment_task_complete_old?.status === 'up'
                          ? 'green-color'
                          : analysisActivityEmployeeInfo?.recruitment_task_complete_old?.status === 'down'
                          ? 'red-color'
                          : ''
                      }`}
                    >
                      {analysisActivityEmployeeInfo?.recruitment_task_complete_old?.status === 'up' ? (
                        <MdOutlineArrowUpward />
                      ) : analysisActivityEmployeeInfo?.recruitment_task_complete_old?.status === 'down' ? (
                        <MdOutlineArrowDownward />
                      ) : (
                        <span>{analysisActivityEmployeeInfo?.recruitment_task_complete_old?.status} </span>
                      )}
                      {analysisActivityEmployeeInfo?.recruitment_task_complete_old?.percent}%
                    </span>
                  )}
                </div>
              </div>
              <div className="info-container">
                <div>
                  <TbPointFilled size={16} className="dot gray" />
                  Duplicate activity
                </div>
                <div className="value">
                  <span className="mr-12-px">{analysisActivityEmployeeInfo?.duplicate_activity ?? 0}</span>
                  {analysisActivityEmployeeInfo?.duplicate_activity_old && (
                    <span
                      className={`value-up-down ${
                        analysisActivityEmployeeInfo?.duplicate_activity_old?.status === 'up'
                          ? 'green-color'
                          : analysisActivityEmployeeInfo?.duplicate_activity_old?.status === 'down'
                          ? 'red-color'
                          : ''
                      }`}
                    >
                      {analysisActivityEmployeeInfo?.duplicate_activity_old?.status === 'up' ? (
                        <MdOutlineArrowUpward />
                      ) : analysisActivityEmployeeInfo?.duplicate_activity_old?.status === 'down' ? (
                        <MdOutlineArrowDownward />
                      ) : (
                        <span>{analysisActivityEmployeeInfo?.duplicate_activity_old?.status} </span>
                      )}
                      {analysisActivityEmployeeInfo?.duplicate_activity_old?.percent}%
                    </span>
                  )}
                </div>
              </div>
            </Flex3Content>
            <Flex3Content className="mb-32-px">
              <div className="info-container">
                <div>
                  <TbPointFilled size={16} className="dot red" />
                  Attend Meeting
                </div>
                <div className="value">
                  <span className="mr-12-px">{analysisActivityEmployeeInfo?.meeting_plan ?? 0}</span>
                  {analysisActivityEmployeeInfo?.meeting_plan_old && (
                    <span
                      className={`value-up-down ${
                        analysisActivityEmployeeInfo?.meeting_plan_old?.status === 'up'
                          ? 'green-color'
                          : analysisActivityEmployeeInfo?.meeting_plan_old?.status === 'down'
                          ? 'red-color'
                          : ''
                      }`}
                    >
                      {analysisActivityEmployeeInfo?.meeting_plan_old?.status === 'up' ? (
                        <MdOutlineArrowUpward />
                      ) : analysisActivityEmployeeInfo?.meeting_plan_old?.status === 'down' ? (
                        <MdOutlineArrowDownward />
                      ) : (
                        <span>{analysisActivityEmployeeInfo?.meeting_plan_old?.status} </span>
                      )}
                      {analysisActivityEmployeeInfo?.meeting_plan_old?.percent}%
                    </span>
                  )}
                </div>
              </div>
              <div className="info-container">
                <div>
                  <TbPointFilled size={16} className="dot green" />
                  Morning Meeting
                </div>
                <div className="value">
                  <span className="mr-12-px">{analysisActivityEmployeeInfo?.morning_meeting ?? 0}</span>
                  {analysisActivityEmployeeInfo?.morning_meeting_old && (
                    <span
                      className={`value-up-down ${
                        analysisActivityEmployeeInfo?.morning_meeting_old?.status === 'up'
                          ? 'green-color'
                          : analysisActivityEmployeeInfo?.morning_meeting_old?.status === 'down'
                          ? 'red-color'
                          : ''
                      }`}
                    >
                      {analysisActivityEmployeeInfo?.morning_meeting_old?.status === 'up' ? (
                        <MdOutlineArrowUpward />
                      ) : analysisActivityEmployeeInfo?.morning_meeting_old?.status === 'down' ? (
                        <MdOutlineArrowDownward />
                      ) : (
                        <span>{analysisActivityEmployeeInfo?.morning_meeting_old?.status} </span>
                      )}
                      {analysisActivityEmployeeInfo?.morning_meeting_old?.percent}%
                    </span>
                  )}
                </div>
              </div>
              <div className="info-container">
                <div>
                  <TbPointFilled size={16} className="dot red" />
                  Evening Meeting
                </div>
                <div className="value">
                  <span className="mr-12-px">{analysisActivityEmployeeInfo?.evening_meeting ?? 0}</span>
                  {analysisActivityEmployeeInfo?.evening_meeting_old && (
                    <span
                      className={`value-up-down ${
                        analysisActivityEmployeeInfo?.evening_meeting_old?.status === 'up'
                          ? 'green-color'
                          : analysisActivityEmployeeInfo?.evening_meeting_old?.status === 'down'
                          ? 'red-color'
                          : ''
                      }`}
                    >
                      {analysisActivityEmployeeInfo?.evening_meeting_old?.status === 'up' ? (
                        <MdOutlineArrowUpward />
                      ) : analysisActivityEmployeeInfo?.evening_meeting_old?.status === 'down' ? (
                        <MdOutlineArrowDownward />
                      ) : (
                        <>{analysisActivityEmployeeInfo?.evening_meeting_old?.status} </>
                      )}
                      {analysisActivityEmployeeInfo?.evening_meeting_old?.percent}%
                    </span>
                  )}
                </div>
              </div>
            </Flex3Content>
          </CardContainer>
        </Card>
      </Flex2Content>

      <Flex3Content className="mb-32-px">
        <Card className="width-32">
          <CardContainer>
            <div className="card-headline">Activity Overview</div>
            <div className="card-sub-headline">Stage of activities</div>
            {analysisActivityEmployeeInfo?.total_ac_plan &&
            analysisActivityEmployeeInfo?.activity_complete &&
            analysisActivityEmployeeInfo?.uniq_activity ? (
              <BarChart
                height={300}
                series={[
                  {
                    data: [
                      analysisActivityEmployeeInfo?.total_ac_plan,
                      analysisActivityEmployeeInfo?.activity_complete,
                      analysisActivityEmployeeInfo?.uniq_activity,
                    ],
                    color: ['#9E77ED', '##EAECF0', '#6941C6'],
                  },
                ]}
                xAxis={[{ data: xLabels, scaleType: 'band', categoryGapRatio: 0.7 }]}
                yAxis={[
                  {
                    scaleType: 'linear',
                    min: 0,
                    max: 100,
                  },
                ]}
              />
            ) : (
              <NoDataChart bgImage={noData}>
                <div className="no-data-chart-container">
                  <div className="no-data-chart-text">No data available</div>
                </div>
              </NoDataChart>
            )}
          </CardContainer>
        </Card>
        <Card className="width-32">
          <CardContainer>
            <div className="card-headline">Task Complete Breakdown</div>
            <div className="card-sub-headline">See if task related to job description</div>
            <div className="chart-container">
              {(analysisActivityEmployeeInfo?.recruitment_task_complete_percent == 0 &&
                analysisActivityEmployeeInfo?.collaborative_task_complete_percent == 0) ||
              (!analysisActivityEmployeeInfo?.recruitment_task_complete_percent &&
                !analysisActivityEmployeeInfo?.collaborative_task_complete_percent) ? (
                <NoDataChart bgImage={noData}>
                  <div className="no-data-chart-container">
                    <div className="no-data-chart-text">No data available</div>
                  </div>
                </NoDataChart>
              ) : (
                <>
                  <div className="chart-label-container">
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-1" size={20} />
                      <span>Recruitment Tasks</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-3" size={20} />
                      <span>Collaborative Tasks</span>
                    </div>
                  </div>
                  <PieChart
                    colors={['var(--Chart-Color-1)', 'var(--Chart-Color-2)']}
                    series={[
                      {
                        data: [
                          { value: analysisActivityEmployeeInfo?.recruitment_task_complete_percent },
                          { value: analysisActivityEmployeeInfo?.collaborative_task_complete_percent },
                        ],
                        innerRadius: 45,
                        outerRadius: 82,
                        paddingAngle: 0,
                        cornerRadius: 0,
                        startAngle: -180,
                        endAngle: 180,
                        cx: 150,
                      },
                    ]}
                    width={300}
                    height={200}
                    sx={{ width: '100%', height: 'auto' }}
                  />
                </>
              )}
            </div>
          </CardContainer>
        </Card>
        <Card className="width-32">
          <CardContainer>
            <div className="card-headline">Meeting Attendance</div>
            <div className="card-sub-headline">See if this employee attend meeting</div>
            <div className="chart-container">
              {analysisActivityEmployeeInfo?.meeting_attendance == 0 ||
              !analysisActivityEmployeeInfo?.meeting_attendance ? (
                <NoDataChart bgImage={noData}>
                  <div className="no-data-chart-container">
                    <div className="no-data-chart-text">No data available</div>
                  </div>
                </NoDataChart>
              ) : (
                <>
                  <div className="chart-label-container">
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-1" size={20} />
                      <span>Meeting attendance</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-3" size={20} />
                      <span>Meeting planed</span>
                    </div>
                  </div>
                  <PieChart
                    colors={['var(--Chart-Color-1)', 'var(--Gray-200)']}
                    series={[
                      {
                        data: [
                          { value: analysisActivityEmployeeInfo?.meeting_attendance },
                          { value: 100 - analysisActivityEmployeeInfo?.meeting_attendance },
                        ],
                        innerRadius: 45,
                        outerRadius: 82,
                        paddingAngle: 0,
                        cornerRadius: 0,
                        startAngle: -180,
                        endAngle: 180,
                        cx: 150,
                      },
                    ]}
                    width={300}
                    height={200}
                    sx={{ width: '100%', height: 'auto' }}
                  />
                </>
              )}
            </div>
          </CardContainer>
        </Card>
      </Flex3Content>

      <div className="text-headline">Detailed Analysis & Recommendations</div>
      <div className="power-by-ai mb-10-px">Powered by AI </div>
      <div className="warning-text mb-32-px">
        Warning : This is just analysis and recommendation from AI. Please use discretion to make judgement
      </div>

      <Flex2Content>
        <Card className="width-49">
          <CardContainer>
            <Flex2Content>
              <div className="mr-16-px">
                <div className="icon-badge mb-16-px">
                  <TbUserPlus size={20} />
                </div>
                <div className="lang-content">
                  <span
                    className={`lang-item ${analysisLang === 'th' ? 'active' : ''}`}
                    onClick={() => setAnalysisLang('th')}
                  >
                    TH
                  </span>
                  <span>/</span>
                  <span
                    className={`lang-item ${analysisLang === 'en' ? 'active' : ''}`}
                    onClick={() => setAnalysisLang('en')}
                  >
                    EN
                  </span>
                </div>
              </div>
              <div>
                <div className="analysis-headline">Analysis</div>

                <p>
                  {analysisLang === 'en'
                    ? analysisActivityEmployeeInfo?.detail_analysis
                    : analysisActivityEmployeeInfo?.detail_analysis_th}
                </p>
              </div>
            </Flex2Content>
          </CardContainer>
        </Card>
        <Card className="width-49">
          <CardContainer>
            <Flex2Content>
              <div className="mr-16-px">
                <div className="icon-badge mb-16-px">
                  <LuPenLine size={20} />
                </div>
                <div className="lang-content">
                  <span
                    className={`lang-item ${recommendationLang === 'th' ? 'active' : ''}`}
                    onClick={() => setRecommendationLang('th')}
                  >
                    TH
                  </span>
                  <span>/</span>
                  <span
                    className={`lang-item ${recommendationLang === 'en' ? 'active' : ''}`}
                    onClick={() => setRecommendationLang('en')}
                  >
                    EN
                  </span>
                </div>
              </div>
              <div>
                <div className="analysis-headline">Recommendation</div>
                <p>
                  {recommendationLang === 'en'
                    ? analysisActivityEmployeeInfo?.recommendation
                    : analysisActivityEmployeeInfo?.recommendation_th}
                </p>
              </div>
            </Flex2Content>
          </CardContainer>
        </Card>
      </Flex2Content>
    </Wrapper>
  )
}

export default EmployeeActivityDetail
