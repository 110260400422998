import React, { useState } from 'react'

import styled from 'styled-components'
import SwitchedMenu from '../../common/SwitchedMenu'
import KPIOrganizationPublished from './KPIOrganizationPublished'
import KPIOrganizationDraft from './KPIOrganizationDraft'

const Div = styled.div`
  .mb-32-px {
    margin-bottom: 32px;
  }
`

const menuList = [
  {
    key: 'published',
    name: 'Published',
  },
  {
    key: 'draft',
    name: 'Draft',
  },
]
function KPIOrganization() {
  const [tab, setTab] = useState('published')

  return (
    <Div>
      {/* switched menu section */}
      <div className="mb-32-px">
        <SwitchedMenu tabMenuList={menuList} tab={tab} setTab={setTab} />
      </div>

      {/* published & draft content */}
      {tab === 'published' ? <KPIOrganizationPublished /> : <KPIOrganizationDraft />}
    </Div>
  )
}

export default KPIOrganization
