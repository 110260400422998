import React from 'react'

import styled from 'styled-components'

const Div = styled.div`
  display: inline-block;
  border: 1px solid var(--Gray-300);
  border-radius: 8px;

  .tab-menu {
    display: inline-block;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-500);
    padding: 8px 16px;

    :hover {
      cursor: pointer;
    }
    &.active {
      color: var(--Gray-800, #182230);
      background: var(--Gray-50);
      border-left: 1px solid var(--Gray-300);
      border-right: 1px solid var(--Gray-300);
    }
    &.active-left {
      color: var(--Gray-800, #182230);
      background: var(--Gray-50);
      border-right: 1px solid var(--Gray-300);
      border-radius: 8px 0 0 8px;
    }
    &.active-right {
      color: var(--Gray-800, #182230);
      background: var(--Gray-50);
      border-left: 1px solid var(--Gray-300);
      border-radius: 0 8px 8px 0;
    }
  }
`

function SwitchedMenu({ tabMenuList, tab, setTab, ...props }) {
  const toggleTab = (key) => {
    setTab(key)
  }

  return (
    <Div {...props}>
      {tabMenuList.map((v, i, arr) => {
        return i === 0 ? (
          <span className={`tab-menu ${tab === v.key ? 'active-left' : ''}`} onClick={() => toggleTab(v.key)}>
            {v.name}
          </span>
        ) : i === arr.length - 1 ? (
          <span className={`tab-menu ${tab === v.key ? 'active-right' : ''}`} onClick={() => toggleTab(v.key)}>
            {v.name}
          </span>
        ) : (
          <span className={`tab-menu ${tab === v.key ? 'active' : ''}`} onClick={() => toggleTab(v.key)}>
            {v.name}
          </span>
        )
      })}
    </Div>
  )
}

export default SwitchedMenu
