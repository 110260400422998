import { combineReducers } from 'redux'
import {
  GET_PENDING_ASSIGNMENTS,
  GET_ASSIGNMENT_BY_ID,
  READING_ASSIGNMENT,
  ACKNOWLEDGE_ASSIGNMENT,
  GET_EMPLOYEE_SIGNATURE,
  UPLOAD_SIGNATURE,
  SIGN_SIGNATURE,
  GET_REVIEW_ASSIGNMENTS,
  GET_OVER_DUE_ASSIGNMENTS,
  READ_INFO_ASSIGNMENT,
  SET_NOTI_EMAIL_DOC_ID,
  SET_ASSIGNMENT_INFO,
  UPDATE_EMPLOYEE_INFO,
  UPLOAD_SIGNATURE_IMAGE,
} from '../actionTypes'

const initialState = {
  pendingAssignments: [],
  reviewAssignments: [],
  overdueAssignments: [],
  employeeSignature: [],
  assignmentInfo: {},
  notiEmailDocId: null,
  response: null,
  page: 1,
  pageCount: 1,
  total: 1,
  limit: 10,
  isLoading: false,
  state: '',
}

const sharedServiceEmployeeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PENDING_ASSIGNMENTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_PENDING_ASSIGNMENTS.REQUEST',
      }
    }
    case GET_PENDING_ASSIGNMENTS.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        pendingAssignments: data,
        page: meta?.pagination?.page,
        pageCount: meta?.pagination?.pageCount,
        total: meta?.pagination?.total,
        limit: meta?.pagination?.pageSize,
        isLoading: false,
        state: 'GET_PENDING_ASSIGNMENTS.SUCCESS',
      }
    }
    case GET_PENDING_ASSIGNMENTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_PENDING_ASSIGNMENTS.FAILURE',
      }
    }
    case GET_ASSIGNMENT_BY_ID.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_ASSIGNMENT_BY_ID.REQUEST',
      }
    }
    case GET_ASSIGNMENT_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        assignmentInfo: data,
        isLoading: false,
        state: 'GET_ASSIGNMENT_BY_ID.SUCCESS',
      }
    }
    case GET_ASSIGNMENT_BY_ID.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_ASSIGNMENT_BY_ID.FAILURE',
      }
    }
    case GET_REVIEW_ASSIGNMENTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_REVIEW_ASSIGNMENTS.REQUEST',
      }
    }
    case GET_REVIEW_ASSIGNMENTS.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        reviewAssignments: data,
        page: meta?.pagination?.page,
        pageCount: meta?.pagination?.pageCount,
        total: meta?.pagination?.total,
        limit: meta?.pagination?.pageSize,
        isLoading: false,
        state: 'GET_REVIEW_ASSIGNMENTS.SUCCESS',
      }
    }
    case GET_REVIEW_ASSIGNMENTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_REVIEW_ASSIGNMENTS.FAILURE',
      }
    }
    case GET_OVER_DUE_ASSIGNMENTS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_OVER_DUE_ASSIGNMENTS.REQUEST',
      }
    }
    case GET_OVER_DUE_ASSIGNMENTS.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        overdueAssignments: data,
        page: meta?.pagination?.page,
        pageCount: meta?.pagination?.pageCount,
        total: meta?.pagination?.total,
        limit: meta?.pagination?.pageSize,
        isLoading: false,
        state: 'GET_OVER_DUE_ASSIGNMENTS.SUCCESS',
      }
    }
    case GET_OVER_DUE_ASSIGNMENTS.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_OVER_DUE_ASSIGNMENTS.FAILURE',
      }
    }
    case READING_ASSIGNMENT.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'READING_ASSIGNMENT.REQUEST',
      }
    }
    case READING_ASSIGNMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'READING_ASSIGNMENT.SUCCESS',
      }
    }
    case READING_ASSIGNMENT.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'READING_ASSIGNMENT.FAILURE',
      }
    }
    case ACKNOWLEDGE_ASSIGNMENT.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'ACKNOWLEDGE_ASSIGNMENT.REQUEST',
      }
    }
    case ACKNOWLEDGE_ASSIGNMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'ACKNOWLEDGE_ASSIGNMENT.SUCCESS',
      }
    }
    case ACKNOWLEDGE_ASSIGNMENT.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'ACKNOWLEDGE_ASSIGNMENT.FAILURE',
      }
    }
    case GET_EMPLOYEE_SIGNATURE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_EMPLOYEE_SIGNATURE.REQUEST',
      }
    }
    case GET_EMPLOYEE_SIGNATURE.SUCCESS: {
      const { data } = action
      return {
        ...state,
        employeeSignature: data,
        isLoading: false,
        state: 'GET_EMPLOYEE_SIGNATURE.SUCCESS',
      }
    }
    case GET_EMPLOYEE_SIGNATURE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_EMPLOYEE_SIGNATURE.FAILURE',
      }
    }
    case UPLOAD_SIGNATURE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'UPLOAD_SIGNATURE.REQUEST',
      }
    }
    case UPLOAD_SIGNATURE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'UPLOAD_SIGNATURE.SUCCESS',
      }
    }
    case UPLOAD_SIGNATURE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'UPLOAD_SIGNATURE.FAILURE',
      }
    }
    case SIGN_SIGNATURE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'SIGN_SIGNATURE.REQUEST',
      }
    }
    case SIGN_SIGNATURE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'SIGN_SIGNATURE.SUCCESS',
      }
    }
    case SIGN_SIGNATURE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'SIGN_SIGNATURE.FAILURE',
      }
    }
    case READ_INFO_ASSIGNMENT.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'READ_INFO_ASSIGNMENT.REQUEST',
      }
    }
    case READ_INFO_ASSIGNMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'READ_INFO_ASSIGNMENT.SUCCESS',
      }
    }
    case READ_INFO_ASSIGNMENT.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'READ_INFO_ASSIGNMENT.FAILURE',
      }
    }
    case UPDATE_EMPLOYEE_INFO.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'UPDATE_EMPLOYEE_INFO.REQUEST',
      }
    }
    case UPDATE_EMPLOYEE_INFO.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_EMPLOYEE_INFO.SUCCESS',
      }
    }
    case UPDATE_EMPLOYEE_INFO.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_EMPLOYEE_INFO.FAILURE',
      }
    }
    case UPLOAD_SIGNATURE_IMAGE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'UPLOAD_SIGNATURE_IMAGE.REQUEST',
      }
    }
    case UPLOAD_SIGNATURE_IMAGE.SUCCESS: {
      const { response } = action
      return {
        ...state,
        response: response,
        isLoading: false,
        state: 'UPLOAD_SIGNATURE_IMAGE.SUCCESS',
      }
    }
    case UPLOAD_SIGNATURE_IMAGE.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'UPLOAD_SIGNATURE_IMAGE.FAILURE',
      }
    }
    case SET_NOTI_EMAIL_DOC_ID: {
      const { data } = action
      return {
        ...state,
        notiEmailDocId: data,
        isLoading: false,
        state: 'SET_NOTI_EMAIL_DOC_ID',
      }
    }
    case SET_ASSIGNMENT_INFO: {
      const { data } = action
      return {
        ...state,
        assignmentInfo: data,
        isLoading: false,
        state: 'SET_ASSIGNMENT_INFO',
      }
    }
    default:
      return state
  }
}

export default combineReducers({
  sharedServiceEmployeeReducer,
})
