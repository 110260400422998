import styled from 'styled-components'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Button from 'components/form/button/Button'
import ButtonOutline from 'components/form/button/ButtonOutline'
import iconNext from 'assets/images/icon-next-white.svg'
import iconBack from 'assets/images/icon-back.svg'

// TODO: Implement support responsive pagination
const PaginationV3 = ({ pageCount, page = 1, onPageChange, ...props }) => {
  const { t } = useTranslation() // ✅ DONE

  const nextLabel = (
    <Button prepend={<img src={iconNext} />} className="button_Next">
      {t('next')}
    </Button>
  )
  const previousLabel = (
    <ButtonOutline append={<img src={iconBack} />} className="button_previousLabel">
      {t('previous')}
    </ButtonOutline>
  )

  return (
    <StyledReactPaginate
      breakLabel="..."
      nextLabel={nextLabel}
      onPageChange={({ selected }) => onPageChange(selected + 1)}
      pageRangeDisplayed={3}
      pageCount={pageCount}
      previousLabel={previousLabel}
      forcePage={page - 1}
      renderOnZeroPageCount={null}
      {...props}
    />
  )
}

PaginationV3.propTypes = {
  pageCount: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
}

export default PaginationV3

const StyledReactPaginate = styled(ReactPaginate)`
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;

  a {
    text-decoration: none !important;
  }

  li {
    list-style: none;

    &.previous,
    &.next {
      display: flex;
      width: 100%;
    }

    &.previous {
      justify-content: start;
    }

    &.next {
      justify-content: end;
    }

    &.disabled {
      opacity: 0.4;
      cursor: default;
    }

    &.selected {
      a {
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 50%;
      }
    }
  }

  li:not(.previous, .next) {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Gray-600);
      font-size: 0.875rem;
      font-weight: 500;
      width: 2.25rem;
      height: 2.25rem;
      cursor: pointer;
    }
  }

  .button_previousLabel {
    color: var(--Button-Pagination-Previous);
  }
  .button_Next {
    color: var(--Button-Pagination-Next);
  }
`
