import { getAPI, postAPI, putAPI, deleteAPI } from '../../utils/api'
import {
  GET_EMPLOYEE,
  CREATE_EMPLOYEE,
  UPDATE_EMPLOYEE,
  GET_EMPLOYEE_BY_ID,
  UPDATE_EMPLOYEE_STATUS,
  GET_EmployeeOther,
  GET_MEMBER_FOR_ADD,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getEmployeeReturnData =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_EMPLOYEE.REQUEST })
      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        company_id: company_info?.id ? company_info?.id : null,
      }

      const { data, meta } = await getAPI({
        url: '/api/employee-dropdrow',
        headers: headers,
        params,
      })
      return { type: 'getEmployeeReturnData_SUCCESS', data_info: data, data_meta: meta }
    } catch (error) {
      return { type: 'getEmployeeReturnData_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

export const getEmployee =
  (filter = { limit: 10, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_EMPLOYEE.REQUEST })
      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      //const params = { ...filter }
      //const params = { 'pagination[page]': filter.skip, 'pagination[pageSize]': filter.limit, filters: filter.filters }
      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        company_id: company_info?.id ? company_info?.id : null,
      }

      if (filter.other_search) {
        params = { ...params, other_search: filter.other_search }
      }
      if (filter.department) {
        params = { ...params, department: filter.department }
      }
      if (filter.position) {
        params = { ...params, position: filter.position }
      }
      if (filter.employee_type) {
        params = { ...params, employee_type: filter.employee_type }
      }

      const { data, meta } = await getAPI({
        url: '/api/employees',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_EMPLOYEE.SUCCESS,
        data,
        meta,
        filter,
      })
    } catch (error) {
      return dispatch({ type: GET_EMPLOYEE.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const createEmployee = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_EMPLOYEE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data: data_result } = await postAPI({
      url: `/api/employees`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_EMPLOYEE.SUCCESS,
      data_result,
    })
  } catch (err) {
    return dispatch({ type: CREATE_EMPLOYEE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateEmployee = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_EMPLOYEE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/employees/${id}`,
      data,
      headers: headers,
    })

    dispatch(getEmployee())

    return dispatch({
      type: UPDATE_EMPLOYEE.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Employee Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_EMPLOYEE.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update Employee Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getEmployeeById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_EMPLOYEE_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/employees/${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_EMPLOYEE_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_EMPLOYEE_BY_ID.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const ChangeStatusEmployee = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_EMPLOYEE_STATUS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/employees/${id}`,
      data,
      headers: headers,
    })

    dispatch(getEmployee())

    return dispatch({
      type: UPDATE_EMPLOYEE_STATUS.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_EMPLOYEE_STATUS.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getUserList =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_EmployeeOther.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      /*
    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
    }
    if(filter.email){
      params = {...params,'_q': filter.email}
    }

    
    const { data, meta } = await getAPI({
      url: '/admin/users',
      headers: headers,
      params,
    })
    */
      const params = {
        'pagination[page]': 0,
        'pagination[pageSize]': 10,
        type: 'filter',
        'filters[query]': filter.filters?.length > 0 ? JSON.stringify(filter.filters) : '[]',
      }
      /*
    if(filter.id_card){
      params = {...params,'filters[id_card]': filter.id_card}
    }
    */
      const { data, meta } = await getAPI({
        url: '/api/employee-users',
        headers: headers,
        params,
      })
      return { type: 'getMeetingForCheck_SUCCESS', data_info: data, data_meta: meta }
    } catch (error) {
      return { type: 'getMeetingForCheck_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

export const updateUser = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_EmployeeOther.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/employee-users/${id}`,
      data,
      headers: headers,
    })
    return { type: 'GET_EmployeeOthero_SUCCESS' }
  } catch (err) {
    return { type: 'GET_EmployeeOthero_ERROR' }
  } finally {
    dispatch(hideLoading())
  }
}

export const UpdateUserAllinOne = (inp_data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_EMPLOYEE_STATUS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await postAPI({
      url: `/api/update-user-all`,
      data: inp_data,
      headers: headers,
    })

    return { type: 'UpdateUserAllinOne_SUCCESS', data_info: data }
  } catch (err) {
    return {
      type: 'UpdateUserAllinOne_ERROR',
      error_message: err.response?.data?.error?.message
        ? err.response.data.error.message
        : 'Email นี้มีคนใช้แล้วไม่สามารถใช้ซ้ำกันได้.',
    }
  } finally {
    dispatch(hideLoading())
  }
}

export const getAccountUserList =
  (filter = { limit: 1000, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_EmployeeOther.REQUEST })
      const { token } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
      let params = {
        skip: 0,
        limit: 10,
      }
      if (filter.id_card) {
        params = { ...params, id_card: filter.id_card }
      }
      /*
    if(filter.id_card){
      params = {...params,'filters[id_card]': filter.id_card}
    }
    */
      const { data, meta } = await getAPI({
        url: '/api/mapping-employee-user-list-user',
        headers: headers,
        params,
      })
      return { type: 'getAccountUserList_SUCCESS', data_info: data, data_meta: meta }
    } catch (error) {
      return { type: 'getAccountUserList_ERROR' }
    } finally {
      dispatch(hideLoading())
    }
  }

export const getEmployeeForAddMember =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MEMBER_FOR_ADD.REQUEST })

      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'filters[status][$eq]': 'active',
        'filters[company][id][$eq]': company_info.id,
        'populate[department]': true,
        'populate[position]': true,
        // sort: 'id:desc',
      }
      if (filter.department !== '') params['filters[department][id]'] = filter.department
      if (filter.position !== '') params['filters[position][id]'] = filter.position

      const { data, meta } = await getAPI({
        url: `/api/employee-dashboards`,
        headers,
        params,
      })

      return dispatch({
        type: GET_MEMBER_FOR_ADD.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_MEMBER_FOR_ADD.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Employee failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }
