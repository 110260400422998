import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DateDisplay, getAdmin, getCurrentDateTime, getEndOfWeek, getStartOfWeek } from 'utils/common'

import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import Button from 'components/form/button/Button'
import TextArea from 'components/form/TextArea'
import FileUploadProgress2 from 'components/form/FileUploadProgress2'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Divider from 'components/common/Divider'
import DialogConfirm from 'components/dialog/DialogConfirm'
import AutoComplete from 'components/form/AutoComplete'

import { LiaTimesSolid } from 'react-icons/lia'
import fileIcon from 'assets/images/file-question.png'

import { useDispatch, useSelector } from 'react-redux'
import { selectWeeklySummaryReport } from 'redux/selectors'
import { createWeeklySummaryReport, getProjectTasks, setModalInfo } from 'redux/actions/weeklySummaryReport'

const Div = styled.div`
  position: relative;
  padding: 24px 24px 0;

  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  .error {
    color: var(--Error-600);
  }

  .highlight-text {
    color: var(--Primary-600);
    font-weight: 700;
  }

  .side-panel-heading {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .icon {
    cursor: pointer;
  }

  .input-heading {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    margin-bottom: 6px;
  }

  .footer-side-panel {
    background-color: var(--Base-White);

    position: sticky;
    bottom: 0;
    width: 100%;
    padding-bottom: 16px;
  }
`

function CreateWeeklySummaryReport({ onClose }) {
  // external hook
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [startWeek, setStartWeek] = useState('')
  const [endWeek, setEndWeek] = useState('')

  const fetchProjectTask = useCallback(() => {
    dispatch(getProjectTasks())
  }, [dispatch])
  const initialCurrentWeek = () => {
    const currentDate = new Date()
    const startWeek = getStartOfWeek(currentDate)
    const endWeek = getEndOfWeek(currentDate)

    setStartWeek(DateDisplay(startWeek))
    setEndWeek(DateDisplay(endWeek))
  }

  useEffect(() => {
    fetchProjectTask()
    initialCurrentWeek()
  }, [])

  // project task options
  const { projectTasks } = useSelector(selectWeeklySummaryReport)
  const [projectOptions, setProjectOptions] = useState([])

  useEffect(() => {
    const newProjectOptions = projectTasks.map((v) => ({
      text: v.name,
      value: v.id,
    }))
    setProjectOptions(newProjectOptions)
  }, [projectTasks])

  // form
  const initialForm = {
    what_done_this_week: '',
    result: '',
    problems: '',
    how_problem_was_solved: '',
    next_week_plan: '',
    current_date: '',
    weekly_summary_files: [],
    employee: '',
    company: '',
    project_task: null,
  }
  const [form, setForm] = useState(initialForm)
  const [isValid, setIsValid] = useState(false)

  const onChangeForm = (name, value) => {
    if (
      value.length <= 2000 &&
      (name === 'what_done_this_week' ||
        name === 'result' ||
        name === 'problems' ||
        name === 'how_problem_was_solved' ||
        name === 'next_week_plan')
    )
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }))
    else if (
      name !== 'what_done_this_week' &&
      name !== 'result' &&
      name !== 'problems' &&
      name !== 'how_problem_was_solved' &&
      name !== 'next_week_plan'
    )
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }))
  }

  // file
  const acceptList = {
    images: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    files: {
      'text/csv': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.ms-excel': [],
      'application/zip': [],
    },
    videos: {
      'video/x-msvideo': [],
      'video/mp4': [],
      'video/mpeg': [],
      'video/webm': [],
    },
    audios: {
      'audio/mpeg': [],
      'audio/wav': [],
      'audio/webm': [],
    },
  }
  const multiple = true
  const error = false
  const disabled = false
  const [files, setFiles] = useState([])
  const [acceptTypes, setAcceptTypes] = useState([])
  const [mimeTypes, setMimeTypes] = useState([])

  const initiateFileType = () => {
    const allowedTypes = []
    const acceptType = allowedTypes.reduce((pre, cur) => {
      return {
        ...pre,
        ...acceptList[cur],
      }
    }, {})
    setAcceptTypes(acceptType)
  }
  const initiateMimeTypes = () => {
    const categoryObjects = Object.values(acceptList)
    const mimeTypes = categoryObjects.flatMap((category) => Object.keys(category))

    setMimeTypes(mimeTypes)
  }
  const handleAddFile = (fileId) => {
    setForm((prev) => ({
      ...prev,
      weekly_summary_files: [...prev.weekly_summary_files, fileId],
    }))
  }
  const handleDeleteFile = (id) => {
    setFiles((prev) => prev.filter((v) => v.id !== id))
    setForm((prev) => ({
      ...prev,
      weekly_summary_files: prev.weekly_summary_files.filter((v) => v !== id),
    }))
  }

  useEffect(() => {
    initiateFileType()
    initiateMimeTypes()
  }, [])

  // scroll to top
  const projectPosRef = useRef(null)

  const scrollToElement = () => {
    projectPosRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  // confirm create
  const initialConfirmMessage = {
    heading: '',
    subHeading: '',
  }
  const [confirmMessage, setConfirmMessage] = useState(initialConfirmMessage)
  const [isConfirmCreate, setIsConfirmCreate] = useState(false)
  const [isConfirmLeaveCreate, setIsConfirmLeaveCreate] = useState(false)

  const getProject = () => {
    const project = projectOptions.find((v) => v.value === form.project_task)
    return project ? project.text : '-'
  }
  const openConfirmCreate = () => {
    if (form.project_task) {
      setConfirmMessage({
        heading: 'Confirm create weekly summary report?',
        subHeading: (
          <Box>
            <div>Project : {getProject()} </div>
            <div>
              Week : {startWeek} - {endWeek}
            </div>
          </Box>
        ),
      })
      setIsValid(false)
      setIsConfirmCreate(true)
    } else {
      scrollToElement()
      setIsValid(true)
    }
  }
  const closeConfirmCreate = () => {
    setIsConfirmCreate(false)
  }
  const openConfirmLeaveCreate = () => {
    setConfirmMessage({
      heading: 'Leave create weekly summary report?',
      subHeading: '',
    })
    setIsConfirmLeaveCreate(true)
  }
  const closeConfirmLeaveCreate = () => {
    setIsConfirmLeaveCreate(false)
  }
  const onSubmitCreate = () => {
    const { company_info, employee_info } = getAdmin()
    const request = {
      data: {
        ...form,
        employee: employee_info?.id,
        company: company_info?.id,
        current_date: getCurrentDateTime(),
      },
    }

    dispatch(createWeeklySummaryReport(request))
    dispatch(
      setModalInfo({
        project_name: getProject(),
        start_week: startWeek,
        end_week: endWeek,
      })
    )
    closeConfirmCreate()
    onClose()
  }
  const leaveCreate = () => {
    closeConfirmLeaveCreate()
    onClose()
  }

  return (
    <Div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div className="side-panel-heading">Create Weekly Summary Report</div>
        <LiaTimesSolid className="icon" size={18} onClick={openConfirmLeaveCreate} />
      </Stack>

      <Box sx={{ mb: 3 }}>
        <div>Submit what occurred in this week</div>
        <div className="highlight-text">
          {startWeek} - {endWeek}
        </div>
      </Box>

      <Box sx={{ mb: 1.5 }}>
        <Divider />
      </Box>

      <Box ref={projectPosRef} sx={{ mb: 2 }}>
        <div className="input-heading">Select Project</div>
        <AutoComplete
          error={isValid}
          optionList={projectOptions}
          value={form.project_task}
          onChange={(value) => onChangeForm('project_task', value)}
        />
        {isValid && <p className="error">Please Select Project</p>}
      </Box>
      <Box sx={{ mb: 2 }}>
        <div className="input-heading">What’s done this week</div>
        <TextArea
          value={form.what_done_this_week}
          onChange={(event) => onChangeForm('what_done_this_week', event.target.value)}
          placeholder="Enter things occurred in this past week"
        />
        <p>Count {form?.what_done_this_week?.length} / 2000</p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <div className="input-heading">Result</div>
        <TextArea
          value={form.result}
          onChange={(event) => onChangeForm('result', event.target.value)}
          placeholder="Enter weekly result"
        />
        <p>Count {form?.result?.length} / 2000</p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <div className="input-heading">Problems</div>
        <TextArea
          value={form.problems}
          onChange={(event) => onChangeForm('problems', event.target.value)}
          placeholder="Does this project have any problems?"
        />
        <p>Count {form?.problems?.length} / 2000</p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <div className="input-heading">How problem was solved?</div>
        <TextArea
          value={form.how_problem_was_solved}
          onChange={(event) => onChangeForm('how_problem_was_solved', event.target.value)}
          placeholder="How do you solve this problems?"
        />
        <p>Count {form?.how_problem_was_solved?.length} / 2000</p>
      </Box>
      <Box sx={{ mb: 2 }}>
        <div className="input-heading">Next Week’s Plan</div>
        <TextArea
          value={form.next_week_plan}
          onChange={(event) => onChangeForm('next_week_plan', event.target.value)}
          placeholder="What is next week’s plan for this project?"
        />
        <p>Count {form?.next_week_plan?.length} / 2000</p>
      </Box>

      <Box>
        <FileUploadProgress2
          multiple={multiple}
          accept={acceptTypes}
          mimeTypes={mimeTypes}
          file={files.map((v) => ({ ...v, url: `${v.url}` }))}
          onAddFile={handleAddFile}
          onDeleteFile={handleDeleteFile}
          error={error}
          disabled={disabled}
          FileListData={files}
          setFileListData={setFiles}
        />
      </Box>

      <Box className="footer-side-panel">
        <Box sx={{ mb: 2 }}>
          <Divider />
        </Box>
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
          <ButtonOutline onClick={openConfirmLeaveCreate}>Cancel</ButtonOutline>
          <Button onClick={openConfirmCreate}>Create</Button>
        </Stack>
      </Box>

      {/* Dialog */}
      <DialogConfirm
        open={isConfirmCreate}
        onClose={closeConfirmCreate}
        onSubmit={onSubmitCreate}
        icon={fileIcon}
        title={confirmMessage.heading}
        description={confirmMessage.subHeading}
        textYes={t('create')}
        textNo={t('cancel')}
      />
      <DialogConfirm
        open={isConfirmLeaveCreate}
        onClose={closeConfirmLeaveCreate}
        onSubmit={leaveCreate}
        icon={fileIcon}
        title={confirmMessage.heading}
        description={confirmMessage.subHeading}
        textYes={t('leave')}
        textNo={t('cancel')}
      />
    </Div>
  )
}

export default CreateWeeklySummaryReport
