import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createMeetingRoom } from '../../redux/actions/dailysummary'
import { getCompanyInfo,DateTimeHHmmDisplay,getAdminUser,getEmployeeInfo } from '../../utils/common'

import { getActivityLogReturnData } from '../../redux/actions/ofm'
import { getEmployeeById } from '../../redux/actions/master'

import dayjs from 'dayjs'

import TextField from '../../components/form/TextField'
import InputTextArea from '../../components/widget/element/InputTextArea'
import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconBin from '../../assets/images/icon-bin.svg'
import iconFile from '../../assets/images/icon-file-round.svg'

import FileUpload from '../../components/form/FileUpload'
import { postAPI } from '../../utils/api'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import mapIcon from '../../assets/images/gg_map.png'

import { useTranslation } from 'react-i18next'

import IconSubmit from '../../assets/images/send-03.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import IconKeep from '../../assets/images/check-circle.png'
import IconEdit from '../../assets/images/edit-01.png'
import IconArchive from '../../assets/images/archive.png'
import IconPutBack from '../../assets/images/flip-backward.png'

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  } 
  
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 100px;
    padding-bottom: 170px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    background-color: #FFFFFF;
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }


  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill{
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .content{
    margin:10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }

  .prefix_icon{
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .report_box_form{
    border: 2px solid rgb(208, 213, 221);
    border-radius: 8px;
    padding: 16px 24px;
    margin-bottom:20px;
    background-color: #FCFCFD;
  }
  .report_formname{
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
    /*margin-bottom:16px;*/
  }
  .report_sub_value{
    display: grid;
    /*grid-template-columns: repeat(2, 35% [col-start] 65% [col-end]);*/
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-bottom:16px;
    margin-top:16px;
  }
  .report_sub_value_title{
    /*
    font-size: 16px;
    color: #101828;
    */
    font-size: 16px;
    color: #101828;
    font-weight: 500;
  }
  .report_sub_value_value{
    color: #475467;
    font-size: 16px;
    font-weight: 400;
    
  }
  .report_img{
    display: grid;
    grid-template-columns: repeat(5, 120px);
    gap: 20px;
    margin-bottom:16px;
  }

  .cursor_pointer {
    cursor: pointer;
  }
  .img_in_row{
    width: 120px;
    border-radius: 12px;
  }

  /* ST CSS POPUP IMG */
  .dialog-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  /* Style for the dialog content */
  .dialog-img-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    position: relative;
    max-width: 1200px;
    padding: 0 15px;
  }
  /* Style for the close icon */
  .dialog-img-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0;
  }

  .dialog-img-popup-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  /* ED CSS POPUP IMG */
  .dialog-footer {
    /*margin-bottom: 15px;*/
    /*text-align: end;*/
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .dialog-footer-report{
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .dialog-footer-report-info{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-bottom:16px;
    margin-top:16px;
  }
  .img-profile {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  .user_img_char_1 {
    border-radius: 50%;
    background-color: var(--Main-Button-Bg);
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #FFFFFF;
    cursor: pointer;
  }
  .dialog-footer-report-info-full-name{
    font-size: 18px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .dialog-footer-report-info-position{
    font-size: 16px;
    font-weight: 400;
    color: var(--Primary-700);
  }
  .dialog-footer-report-info-location{
    font-size: 16px;
    font-weight: 500;
    color: var(--Gray-600);
  }
`

const DivChatBox = styled.div`
  .user_img{
    border-radius: 50%;
    background-color: #0079a5;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #ffffff;
  }
  .user_img_reply{
    border-radius: 50%;
    background-color: #e9d7fe;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #000000;
  }
  img{
    /*width : 100%;*/
    height: 48px;
    width: 48px;
    border-radius: 50%;
  }
  .section{
    padding: 10px 0px;
    display : flex;
    position: relative;
  }
  .reply_space{
    width: 72px;
    position: relative;
  }
  
  .section_reply{
    /*padding: 10px 0px;*/
    display : flex;
    
  }
  .comment-body{
    padding-left: 12px;
  }
  .name {
    color:#344054;
    font-size : 14px;
    font-weight: 600;
  }
  .date_sent_message{
    color:#475467;
    font-size:12px;
    font-weight: normal;
  }

  .response{
    color:#667085;
    font-size: 14px;
  }
  .comment{
    color : #344054;
    font-size: 14px;
    border: 1px solid rgb(208, 213, 221);
    border-top-right-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    min-width: 350px;
  }
  .Reply{
    color:#475467;
    font-size:14px;
    font-weight: normal;
    text-align: end;
    cursor: pointer;
    text-decoration: underline;
  }
  .Reply_save{
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }
  .line_1{
    
    position: absolute;
    top: 60px;
    background-color: rgb(208, 213, 221);
    height: calc(100% - 48px);
    width: 2px;
    left: 23px;
    
    /*
    position: relative;
    width: 2px;
    height: auto;
    left: 20px;
    background-color: #000000;
    margin-top: 48px;
    */
  }

  .box_line{
    position: absolute;
    right: 0;
    top: 0;
    width: 52px;
    height: 25px;
    border-bottom: 2px solid #000000;
    border-left: 2px solid #000000;
  }
  .text_css{
    min-width: 350px;
  }
  .margin_right{
    margin-right: 10px;
  }
  .box_display_status{
    display: flex;
    margin: 5px 0px;
    img{
        width: 20px;
        height: 20px;
        margin-right: 5px;
        border-radius: 0px;
    }
    .date_display_type_log{
        color: rgb(127, 86, 217);;
        /*font-size:12px;*/
        font-weight: normal;
        margin-left : 5px;
      }
  }
  .badge {
    /*
    flex: 0 1 auto;
    display: flex;
    */
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;
    margin-right: 2px;
    font-size: 14px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}

const ModalActivityLog = ({ onClose, onSubmit,ReportId,ReportName,ReportInfoFooter,...props  }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = getAdminUser()
  const employee_info = getEmployeeInfo()
  const CompanyInfo = getCompanyInfo()
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const [HeaderReport, setHeaderReport] = useState('Activity log');
  const [HeaderDateReport, setHeaderDateReport] = useState('Track actions and decisions');
  const [ListForm, setListForm] = useState([])

  const fetchDataAll = useCallback(
    async (filter,this_ReportId) => {
        if(this_ReportId){
            
            
            dispatch(getActivityLogReturnData(this_ReportId,filter)).then(({ type,data_info,data_meta }) => {
                if(type.endsWith('_SUCCESS')){
                    let all_new_data = [];
                    let index_item_report_logs = 0;
                    let num_data_report_logs = data_info.report_logs.length;
                    for(const item_report_logs of data_info.report_logs){
                        dispatch(getEmployeeById(item_report_logs.created_by_employee_id)).then(({ type,data_info }) => {
                            if(type.endsWith('_SUCCESS')){
                                let icon_type_log = null;
                                let type_log = '';
                                if(item_report_logs.activity == 'Submit'){
                                    icon_type_log = IconSubmit;
                                    type_log = 'Submit report';
                                }else if(item_report_logs.activity == 'Edited'){
                                    icon_type_log = IconEdit;
                                    type_log = 'Edited the report';
                                }else if(item_report_logs.activity == 'Request to delete'){
                                    icon_type_log = iconDelete;
                                    type_log = 'Send a request to delete the report';
                                }else if(item_report_logs.activity == 'Archive'){
                                    icon_type_log = IconArchive;
                                    type_log = 'Archive the report';
                                }else if(item_report_logs.activity == 'Put back'){
                                    icon_type_log = IconPutBack;
                                    type_log = 'Put back the report';
                                }else if(item_report_logs.activity == 'Keep'){
                                    icon_type_log = IconKeep;
                                    type_log = 'Made the decision to keep the report';
                                }

                                all_new_data.push({
                                    img : data_info.photo_images[0]?.url ? data_info.photo_images[0].url : null,
                                    full_name : `${data_info.first_name} ${data_info.last_name}`,
                                    date_display_name : item_report_logs.createdAt,
                                    icon_type_log : icon_type_log,
                                    type_log : type_log,
                                    date_display_type_log : item_report_logs.createdAt,
                                    reason_del : item_report_logs.deletion_request ? item_report_logs.deletion_request.reason : [],
                                });
                                index_item_report_logs = index_item_report_logs + 1;
                                if(index_item_report_logs == num_data_report_logs){
                                    
                                    
                                    setListForm(all_new_data);
                                }
                            }
                        });
                    }


                }
            })
        }

    },
    [dispatch]
 );

 useEffect(() => {
    
    const filter = {
        skip: 1,
        limit: 1000,
    }
    fetchDataAll(filter,ReportId)
}, [fetchDataAll,ReportId]) 


const [dialog, setDialog] = useState(false);
const [imageSrc, setImageSrc] = useState();
const toggleDialog = (url_img) => {
  //alert(url_img);
  setImageSrc(url_img);
  setDialog(true);
  
};

const ClosetoggleDialog = () => {
  setDialog(false);
  
};

const GotoEdit = () => {
  alert('GoTOEDIT');
}


  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">{HeaderReport}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">
            <div>
              <div>{HeaderDateReport}</div>      
            </div>
            <div>

            </div>
        </div>
        <hr />
      </div>
      
      <div className="dialog-content">
      <DivChatBox>
        {ListForm?.map((item, index) => (
            <div key={'message_' + index}>
                <div className="section" >
                    {index != ListForm.length - 1 && (
                        <div className="line_1"></div>
                    )}
                    <div>
                    {item?.img ? (
                        <img className="img-profile icon" src={item.img} />
                    ) : (
                        <div className="user_img">
                            <h3>{item.full_name.substring(0, 1)}</h3> 
                        </div>
                    )}
                    </div>
                    
                    <div className="comment-body">
                        <div className="name">{item.full_name} <span className="date_sent_message">{DateTimeHHmmDisplay(item.date_display_name)}</span></div>
                        <div className="response">
                            <div className="box_display_status">
                                {item.icon_type_log != null && (
                                    <div>
                                        <img src={item.icon_type_log} />
                                    </div>
                                )}
                                <div>
                                    {item.type_log}
                                    <span className="date_display_type_log">
                                        {DateTimeHHmmDisplay(item.date_display_type_log)}
                                    </span>
                                </div>
                            </div>                           
                        </div>
                        <div>
                        {item?.reason_del.map((item_reason, index_reason) => (
                            <span
                            className="badge"
                            style={{
                            color: '#344054',
                            borderColor: '#EAECF0',
                            backgroundColor: '#F9FAFB',
                            }}
                            >
                                {item_reason}
                            </span>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        ))}
      </DivChatBox>  
      </div>
    </Div>
  )
}

export default ModalActivityLog
