import styled from 'styled-components'
import { useState, useEffect } from 'react'

import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { postAPIdiscord } from '../../utils/apidiscord'

import { useDispatch, useSelector } from 'react-redux'
import { getDailySummaryById, updateDailySummary } from '../../redux/actions/dailysummary'
import { selectDailySummary } from '../../redux/selectors'
import dayjs from 'dayjs'
import Date from '../../components/form/Date'

import InputTextAreaV2024 from '../../components/widget/element/InputTextAreaV2024'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'

import FileUploadProgress from '../../components/form/FileUploadProgress'
import { postAPI } from '../../utils/api'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import { useTranslation } from 'react-i18next'

import { getServerTime } from '../../redux/actions/master'

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  } 
  
  .content_header {
    padding: 14px 14px;
    width: 400px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 97px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
    margin-top: 14px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dialog-button {
    margin-bottom: 15px;
    text-align: end;
    .button_margin {
      margin: 10px;
    }
  }
  .basic-Information-detail {
    padding: 14px;
    width: 50%;
    display: flex;
  }
  .div_box_deatail{
    height: 128px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background-color: #F9FAFB;
    font-size: 14px;
    padding: 10px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : 400px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .css_upload {
    margin-top: 10px;
  }
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}

const EditDailySummary = ({ onClose, onSubmit, DailySummaryId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [ServerTime, setServerTime] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'));

  const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))

  useEffect(() => {
    dispatch(getServerTime()).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info){
          setServerTime(dayjs(data_info).format('YYYY-MM-DD HH:mm:ss'));
        }else{
          setTitleFail('Error Connect API');
          setnameItemFail('');
          setDescriptionFail(''); 
          setFailModal(true)
        }

      }else{
        setTitleFail('Error Connect API');
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }
    })
  }, [dispatch])
  
  useEffect(() => {
    if (DailySummaryId) {
      dispatch(getDailySummaryById(DailySummaryId))
    }
  }, [dispatch, DailySummaryId])
  

  const [currentDate, setcurrentDate] = useState(dayjs().format('YYYY-MM-DD'))
  const { DailySummaryInfo } = useSelector(selectDailySummary)
  const [DailySummaryData, setDailySummaryData] = useState({
    what_done_today: null,
    result: null,
    problems: null,
    how_problem_was_solved: null,
    tomorrow_plan: null,
    file: null, //--- Lise File_ID //-- file: [1]
  })

      //--- ST UPLOAD FILE
      const [file_resume, setFile_resume] = useState([])
      const multiple = true;
      const error = false;
      const disabled = false;
    
      const handleDeleteFile = (id) => {
        setFile_resume(file_resume.filter((v) => v.id !== id))
        setDailySummaryData({ ...DailySummaryData, file: DailySummaryData.file.filter((v) => v !== id) })
        //onChange(file.filter((v) => v.id !== id))
      }
      /*
      const handleAddFile = async (value) => {
        const fetchList = []
        value.forEach((v) => {
          const formData = new FormData()
          formData.append('files', v)
          formData.append(
            'fileInfo',
            JSON.stringify({
              name: v.name,
              caption: v.name,
              alternativeText: v.name,
              folder: null,
            })
          )
    
          fetchList.push(
            postAPI({
              url: '/api/upload',
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: null,
              },
              data: formData,
            })
          )
        })
    
        const res = await Promise.all(fetchList)
    
        const result = multiple ? [...file_resume, ...res.map((v) => v[0])] : res[0]
    
        
        console.table(res.map((v) => v[0].id))
        //setNewDailySummaryData({ ...NewDailySummaryData, file: [...NewDailySummaryData.file,res.map((v) => v[0].id)] })
        
        if (DailySummaryData.file) {
          setDailySummaryData({ ...DailySummaryData, file: [...DailySummaryData.file,...res.map((v) => v[0].id)] })
        } else {
          setDailySummaryData({ ...DailySummaryData, file: res.map((v) => v[0].id) })
        }
        
        setFile_resume(result)
        //onChange(result)
      }
      */

      const handleAddFile = async (file_id) => {
        //setNewDailySummaryData({ ...NewDailySummaryData, file: [...NewDailySummaryData.file,file_id] })
        
        if (DailySummaryData.file) {
          setDailySummaryData({ ...DailySummaryData, file: [...DailySummaryData.file,file_id] })
        } else {
          setDailySummaryData({ ...DailySummaryData, file: [file_id] })
        }
        
      }
      const allowedTypes = [];
      const acceptType = allowedTypes.reduce((pre, cur) => {
        return {
          ...pre,
          ...acceptList[cur],
        }
      }, {})
      //--- ED UPLOAD FILE
  
  useEffect(() => {
    if (DailySummaryInfo.id == DailySummaryId) {
      const setData = {
        what_done_today: DailySummaryInfo.what_done_today,
        result: DailySummaryInfo.result,
        problems: DailySummaryInfo.problems,
        how_problem_was_solved: DailySummaryInfo.how_problem_was_solved,
        tomorrow_plan: DailySummaryInfo.tomorrow_plan,
        display_date: dayjs(DailySummaryInfo.dailysummary_date_start).format('D MMM YYYY'),
        display_report_by: DailySummaryInfo.create_by,
        file: DailySummaryInfo.daily_file.map((v) => v.id),
      }
      setcurrentDate(dayjs(DailySummaryInfo.dailysummary_date_start).format('YYYY-MM-DD'));
      setDailySummaryData(setData)
      setFile_resume(DailySummaryInfo.daily_file.map((v) => v))
    }
  }, [DailySummaryInfo,DailySummaryId])
  

  const onChangecurrentDate = (value) => {
    setcurrentDate(value)
  }

  const [ModalConfirmDialogLeave, setModalConfirmDialogLeave] = useState(false)
  const ConfirmDialogLeave = () => {
    setModalConfirmDialogLeave(true)
  }

  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const ConfirmDialog = async () => {
    //--- ST ส่ง Discord
    try {
      const full_name_employee = user.first_name + ' ' + user.last_name  + ' (' + employee_info.employ_id + ')';
      const text_to_discode = `\n ---------TimeStam : ${ServerTime} -------------- \n ${full_name_employee} ได้แก้ไข Daily Summary Report ของวันที่ ${dayjs(currentDate).format('D MMM YYYY')}  \n ### input_data ### \n [${JSON.stringify(DailySummaryData)}]`;
      const data_to_discord = {
        "content" : text_to_discode.substring(0, 1800),
      };
      await postAPIdiscord({
        url: `https://discord.com/api/webhooks/1192396987446853632/O1IAXMn-k91utBVskLbkXDLf1QUy1sOP0okSNbNLD4DFyjZi8ugBUtkFRMkMOANNlSia`,
        data : data_to_discord,
        headers: {
          'Content-Type': 'application/json',
        },
      })
    } catch (error) {
      console.log(error);
    }

    //--- ED ส่ง Discord

    if(dayjs(currentDate).format('YYYY-MM-DD') < dayjs(ServerTime).format('YYYY-MM-DD')){
      setTitleFail('ไม่สามารถแก้ย้อนหลังได้');
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }else{
      setModalConfirmDialog(true)
    }
    
  }
  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const SaveEditDailySummary = () => {
    setModalConfirmDialog(false)
    const sent_data_api = {
      data: DailySummaryData,
    }
    dispatch(updateDailySummary(DailySummaryId, sent_data_api)).then(({ type }) => {
      if(type.endsWith('_SUCCESS')){
        setTitlesuccess(t('success_update_daily_summary_report_message'));
        setSubTitlesuccess('');
        setDescriptionsuccess(''); 
        setsuccessModal(true)
      }else{
        setTitleFail(t('error_updated'));
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }     
    })

    //onSubmit()
  }



  return (
    <Div>
      <div className="content_header">
        <header>
          <div className="content-title">{t('edit_daily_summary_report_header')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={ConfirmDialogLeave} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">{t('edit_daily_summary_report_sub_header')}</div>
        <div className="css_header_detail">
            <div>{DailySummaryData.display_report_by}</div>
            <div className="date_css">{DailySummaryData.display_date}</div>
        </div>
        <hr />
      </div>
      
      <div className="dialog-content">
        <div className="content">
          <div className="topic_main">{t('feature_daily_summary_report_what_done_today_column_header')}</div>
          <div >
            <InputTextAreaV2024
              className="InputTextArea_css"
              initValue={DailySummaryData.what_done_today}
              onChange={(e) => setDailySummaryData({ ...DailySummaryData, what_done_today: e.target.value })}
              placeHolder="Enter things you have done today"
              borderColor={'var(--Gray-300)'}
              borderWidth="1px"
            />
          </div>
          <div className="topic_main">{t('create_daily_summary_report_result')}</div>
          <div >
            <InputTextAreaV2024
              className="InputTextArea_css"
              initValue={DailySummaryData.result}
              onChange={(e) => setDailySummaryData({ ...DailySummaryData, result: e.target.value })}
              placeHolder="Enter things you have done today"
              borderColor={'var(--Gray-300)'}
              borderWidth="1px"
            />
          </div>
          <div className="topic_main">{t('create_daily_summary_report_problems')}</div>
          <div >
            <InputTextAreaV2024
              className="InputTextArea_css"
              initValue={DailySummaryData.problems}
              onChange={(e) => setDailySummaryData({ ...DailySummaryData, problems: e.target.value })}
              placeHolder="Enter things you have done today"
              borderColor={'var(--Gray-300)'}
              borderWidth="1px"
            />
          </div>
          <div className="topic_main">{t('create_daily_summary_report_how_problem_was_solved')}</div>
          <div >
            <InputTextAreaV2024
              className="InputTextArea_css"
              initValue={DailySummaryData.how_problem_was_solved}
              onChange={(e) => setDailySummaryData({ ...DailySummaryData, how_problem_was_solved: e.target.value })}
              placeHolder="Enter things you have done today"
              borderColor={'var(--Gray-300)'}
              borderWidth="1px"
            />
          </div>
          <div className="topic_main">{t('create_daily_summary_report_tomorrow_plan')}</div>
          <div >
            <InputTextAreaV2024
              className="InputTextArea_css"
              initValue={DailySummaryData.tomorrow_plan}
              onChange={(e) => setDailySummaryData({ ...DailySummaryData, tomorrow_plan: e.target.value })}
              placeHolder="Enter things you have done today"
              borderColor={'var(--Gray-300)'}
              borderWidth="1px"
            />
          </div>
          <div className="css_upload">
            <FileUploadProgress
              multiple={multiple}
              accept={acceptType}
              file={file_resume.map((v) => ({ ...v, url: `${v.url}` }))}
              onAddFile={handleAddFile}
              onDeleteFile={handleDeleteFile}
              error={error}
              disabled={disabled}
              FileListData={file_resume}
              setFileListData={setFile_resume}
            />
          </div>          
        </div>
      </div>

      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={ConfirmDialogLeave}>
          {t('cancel')}
        </ButtonOutline>
        <Button className="button_margin" onClick={ConfirmDialog}>
          {t('update')}
        </Button>
      </div>

      <DialogConfirm
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onSubmit={SaveEditDailySummary}
          icon={question}
          title={t('confirm_update_daily_summary_report_header')}
          description=""
          textYes={t('confirm')}
          textNo={t('back')}
        />

        <DialogConfirm
          open={Boolean(ModalConfirmDialogLeave)}
          onClose={() => setModalConfirmDialogLeave(false)}
          onSubmit={onClose}
          icon={question}
          title={t('leave_update_daily_summary_report_header')}
          textYes={t('leave')}
          textNo={t('cancel')}
        />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />

    </Div>
  )
}

export default EditDailySummary
