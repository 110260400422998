import React, { useRef, useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import styled from 'styled-components'
import ReactQuill from 'react-quill'
import Button from '../../components/form/button/Button'
import FileUploadProgress2 from '../../components/form/FileUploadProgress2'
import TextField from '../../components/form/TextField'
import RadioButton from '../../components/form/RadioButton'
import Dropdown2 from '../../components/form/Dropdown2'
import Date from '../../components/form/Date'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import AutoComplete from '../../components/form/AutoComplete'
import ModalPdpaPreview from '../../components/pdpa/ModalPdpaPreview'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { FiArrowRight } from 'react-icons/fi'
import { LiaTimesSolid } from 'react-icons/lia'
import { GrView } from 'react-icons/gr'
import { TbPointFilled } from 'react-icons/tb'
import { BsStar, BsStarFill } from 'react-icons/bs'
import { PiWarningCircleBold, PiFloppyDiskBold } from 'react-icons/pi'
import userPlus from '../../assets/images/image-user-plus.svg'

import { selectSharedService, selectDocumentTemplate } from '../../redux/selectors'
import {
  setFlagDraft,
  setSharedDocumentForm,
  setSharedServiceTemplateInfo,
  setDocumentAcknowledgementInfo,
  saveDraftDocumentAcknowledge,
  getDocumentAcknowledge,
  getDocumentAcknowledgeById,
} from '../../redux/actions/sharedService'
import { getDocumentTemplateById } from '../../redux/actions/documentTemplate'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  /* shared-layout */
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .mb-10-rem {
    margin-bottom: 10rem;
  }
  .mb-18-px {
    margin-bottom: 18px !important;
  }
  .mb-20-px {
    margin-bottom: 1.25rem;
  }
  .mb-24-px {
    margin-bottom: 1.5rem;
  }
  .mb-32-px {
    margin-bottom: 2rem;
  }
  .mb-64-px {
    margin-bottom: 4rem;
  }
  .mr-12-px {
    margin-right: 0.75rem;
  }
  .mr-20-px {
    margin-right: 1.25rem;
  }
  .d-flex {
    display: flex;
  }
  .jt-end {
    justify-content: flex-end;
  }

  /* shared-typography */
  .header {
    display: flex;
    align-items: center;

    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    .highlight {
      color: var(--Primary-700);
      font-weight: 600;
    }
  }
  .headline {
    color: var(--Gray-900, #101828);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
  }
  .text-center {
    text-align: center;
  }
  .header-step {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-weight: 600;

    &.active {
      color: var(--Primary-700);
    }
  }
  .description-step {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-weight: 400;

    &.active {
      color: var(--Primary-700);
    }
  }

  /* shared component */
  .divider {
    width: 100%;
    height: 1px;
    background: var(--Gray-200);
  }
  .input {
    margin-bottom: 1.5rem;

    .input-header {
      color: var(--Gray-700);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    .w-input {
      width: 100%;
    }
  }
  .add-image-btn {
    border-radius: 8px;
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }
  .step-item {
    width: 30px;
    height: 30px;

    border-radius: 50%;
    border: 1px solid var(--Stepper-Line);

    display: flex;
    justify-content: center;
    align-items: center;

    .step-icon {
      color: var(--Stepper-Icon);

      &.inactive {
        color: var(--Gray-200);
      }
    }

    &.inactive {
      border: 1px solid var(--Gray-200);
    }
  }
  .line-connect {
    width: 2px;
    /* flex-grow: 1; */
    height: 3rem;
    background-color: var(--Gray-200);
    margin: 0 auto;
  }
  .error-border {
    border-color: var(--Error-600);
  }
  .error-text {
    color: var(--Error-600);
  }
  .icon {
    &.yellow {
      color: #fdb022;
    }
  }

  /* general */
  .document-container {
    display: flex;
    justify-content: space-between;

    .step-container {
      width: 30%;

      height: 400px;
      display: flex;

      .step-progress {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-right: 0.75rem;
      }
      .step-progress-2 {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        height: 100%;
      }
    }
    /* .step-container > div:last-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    } */
    .input-container {
      width: 68%;
    }
  }
  .warning-container {
    color: var(--warning-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;

    border-radius: 0.75rem;
    border: 1px solid var(--warning-300);
    background: var(--warning-25);

    padding: 1rem;

    .icon-warning-container {
    }

    .warning-message-container {
      .headline-warning {
        font-weight: 600;
      }
    }
  }
  .text-editor-container {
    box-sizing: border-box;
    padding-bottom: 65px;

    .text-editor-headline {
      color: var(--Gray-700);
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
    }
  }
  .upload-container {
    width: 464px;
  }
  .footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 2rem;
  }
`
// for upload file
const acceptList = {
  // images: {
  //   'image/jpeg': ['.jpeg', '.jpg'],
  //   'image/png': ['.png'],
  // },
  files: {
    // 'text/csv': [],
    'application/pdf': [],
    // 'application/msword': [],
    // 'application/vnd.ms-excel': [],
    // 'application/zip': [],
  },
  // videos: {
  //   'video/x-msvideo': [],
  //   'video/mp4': [],
  //   'video/mpeg': [],
  //   'video/webm': [],
  // },
  // audios: {
  //   'audio/mpeg': [],
  //   'audio/wav': [],
  //   'audio/webm': [],
  // },
}
const multiple = false
const error = false
const disabled = false

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link', 'image', 'video'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: 2 }],
    [{ color: [] }, { background: [] }],
    ['formula'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ align: [] }],
    ['clean'],
  ],
}

const initDocumentForm = {
  creator: {
    id: '',
  },
  template: {
    id: '',
  },
  version: '',
  due_date: null,
  topics: [],
  files: null,
  publish_to: '',
  departments: null,
  employees: null,
  email_noti: false,
  in_app_noti: true,
  // new
  name: '',
  doc_lang: 'th',
  is_star: 0,
  reference: null,
  other_topic: '',
}

const initValidateError = {
  existingName: null,
  name: null,
  version: null,
  due_date: null,
}

function CreateDocument() {
  const { t } = useTranslation()
  const initLangRadioOptionList = [
    {
      text: t('thai'),
      value: 'th',
    },
    {
      text: t('english'),
      value: 'en',
    },
  ]
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams()
  const errorRefName = useRef(null)
  const errorRefVersionAndDueDate = useRef(null)
  // store
  const { documentAcknowledgements, documentAcknowledgementInfo, sharedDocumentForm } = useSelector(selectSharedService)
  const { documentTemplateInfo } = useSelector(selectDocumentTemplate)
  // data
  const [documentForm, setDocumentForm] = useState(initDocumentForm)
  const [versionDigit1, setVersionDigit1] = useState('0')
  const [versionDigit2, setVersionDigit2] = useState('0')
  const [latestVersion, setLatestVersion] = useState('0')
  const [documentNameSelected, setDocumentNameSelected] = useState('')
  const [documentInfo, setDocumentInfo] = useState({})
  // file
  const [file, setFile] = useState([])
  const [acceptTypes, setAcceptTypes] = useState([])
  const [mimeTypes, setMimeTypes] = useState([])
  // validate
  const [errors, setErrors] = useState(initValidateError)
  // dropdown list
  const [versionList, setVersionList] = useState([])
  const [documentNameList, setDocumentNameList] = useState([])
  // modal
  const [previewPdpaModal, setPreviewPdpaModal] = useState(false)

  const fetchDocumentNameList = useCallback(() => {
    const request = {
      page: 1,
      pageSize: 1000,
      status: 'Active',
      template: id,
    }
    dispatch(getDocumentAcknowledge(request))
  }, [dispatch])

  useEffect(() => {
    generateVersionList()
    initFileType()
    fetchDocumentNameList()
    getMimeTypes()
  }, [])

  useEffect(() => {
    dispatch(getDocumentTemplateById(id))
  }, [id])

  useEffect(() => {
    if (Object.keys(documentAcknowledgementInfo).length > 0) {
      setDocumentForm((prev) => ({
        ...prev,
        name: documentAcknowledgementInfo?.name,
        reference: documentAcknowledgementInfo?.id,
        is_star: documentAcknowledgementInfo?.is_star,
        topics: documentAcknowledgementInfo?.topics,
        other_topic: documentAcknowledgementInfo?.other_topic,
        files: documentAcknowledgementInfo?.files?.map((v) => v.id),
        due_date: documentAcknowledgementInfo?.due_date,
      }))
      const newFile = documentAcknowledgementInfo?.files?.map((v) => ({
        id: v?.id,
        url: v?.url,
        name: v?.alternativeText,
        size: v?.size,
        progress_percent: 100,
        progress_display: 'OK',
        progress_is_error: false,
      }))
      setFile(newFile ?? [])

      const version = documentAcknowledgementInfo?.version
        .toString()
        .split('.')
        .map((v) => v)
      setLatestVersion(documentAcknowledgementInfo?.version)
      setVersionDigit1(version[0])
      setVersionDigit2(version[1] ?? '0')
    }
  }, [documentAcknowledgementInfo])
  useEffect(() => {
    const newDocumentNameList = documentAcknowledgements?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    const finalDocumentNameList = [
      ...newDocumentNameList,
      {
        value: 'อื่นๆ',
        text: 'อื่นๆ',
      },
    ]
    setDocumentNameList(finalDocumentNameList)
  }, [documentAcknowledgements])
  useEffect(() => {
    setDocumentInfo({
      name: documentTemplateInfo?.name,
      category: documentTemplateInfo?.category?.name,
      type: documentTemplateInfo?.type,
    })
    if (Object.keys(sharedDocumentForm).length > 0) {
      setDocumentForm((prev) => ({
        ...prev,
        ...sharedDocumentForm,
      }))
      setFile(sharedDocumentForm?.file ?? [])

      setDocumentNameSelected(sharedDocumentForm.reference ?? 'อื่นๆ')

      if (sharedDocumentForm.version) {
        const version = sharedDocumentForm.version
          .toString()
          .split('.')
          .map((v) => v)
        setVersionDigit1(version[0])
        setVersionDigit2(version[1] ?? '0')
      }
    } else if (Object.keys(documentTemplateInfo).length > 0) {
      const { employee_info } = JSON.parse(localStorage.getItem('admin'))
      const { id, topics, document_acknows } = documentTemplateInfo

      if (document_acknows?.length > 0) {
        const latestVersion = document_acknows[document_acknows.length - 1]?.version
        const version = latestVersion
          .toString()
          .split('.')
          .map((v) => v)
        setLatestVersion(latestVersion)
        setVersionDigit1(version[0])
        setVersionDigit2(version[1] ?? '0')
      }

      setDocumentForm((prev) => ({
        ...prev,
        template: {
          id: id,
        },
        creator: {
          id: employee_info.id,
        },
        topics: topics?.map((v) => ({
          id: v.id,
          title: v.title,
          description: '',
          images: null,
        })),
        doc_lang: documentTemplateInfo?.doc_lang,
      }))
    }
  }, [documentTemplateInfo, sharedDocumentForm])
  useEffect(() => {
    if (errors?.name) {
      errorRefName?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else if (errors?.version || errors?.due_date) {
      errorRefVersionAndDueDate?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [errors])
  useEffect(() => {
    setDocumentForm((prev) => ({
      ...prev,
      version: parseFloat(`${versionDigit1}.${versionDigit2}`),
    }))
  }, [versionDigit1, versionDigit2])

  // onChange
  const onChangeForm = (event) => {
    const { value, name } = event.target
    setDocumentForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeTextEditor = (id, value) => {
    const newTopics = [...documentForm?.topics]
    const indexMatch = newTopics.findIndex((v) => v.id === id)
    newTopics[indexMatch].description = value

    setDocumentForm((prev) => ({
      ...prev,
      topics: newTopics,
    }))
  }
  const onChangeTextEditorOtherTopic = (value) => {
    setDocumentForm((prev) => ({
      ...prev,
      other_topic: value,
    }))
  }
  const onChangeDate = (value) => {
    setDocumentForm((prev) => ({
      ...prev,
      due_date: value,
    }))
  }
  const toggleIsStar = () => {
    setDocumentForm((prev) => ({
      ...prev,
      is_star: prev.is_star === 0 ? 1 : 0,
    }))
  }
  const onChangeDocumentName = (value) => {
    if (value === 'อื่นๆ') {
      setDocumentForm((prev) => ({
        ...prev,
        name: '',
        reference: null,
        is_star: 0,
        topics: documentTemplateInfo?.topics?.map((v) => ({
          id: v.id,
          title: v.title,
          description: '',
          images: null,
        })),
        other_topic: '',
        files: null,
        due_date: null,
      }))
      setFile([])

      setVersionDigit1('0')
      setVersionDigit2('0')
      setLatestVersion('0')
    } else if (typeof value === 'number') {
      dispatch(getDocumentAcknowledgeById(value))
    }
    setDocumentNameSelected(value)
  }

  // optional
  const getMimeTypes = () => {
    const categoryObjects = Object.values(acceptList)
    const mimeTypes = categoryObjects.flatMap((category) => Object.keys(category))

    setMimeTypes(mimeTypes)
  }
  const generateVersionList = () => {
    const generatedNumberArr = Array.from({ length: 10 }, (_, i) => i)
    const newVersionList = generatedNumberArr?.map((v) => ({
      text: v.toString(),
      value: v.toString(),
    }))
    setVersionList(newVersionList)
  }
  const validateForm = () => {
    let newErrors = {
      ...initValidateError,
    }

    if (documentNameSelected == '') {
      newErrors.existingName = 'Please select existing document name'
    }
    if (documentForm?.name == '') {
      newErrors.name = 'Please input Document Name'
    }
    if (documentForm?.due_date == null) {
      newErrors.due_date = 'Please select Due date'
    }
    if (documentForm?.version < 1) {
      newErrors.version = 'Version must be more than 1'
    } else if (documentForm?.version <= latestVersion) {
      newErrors.version = `Version must be more than version ${latestVersion}`
    }

    setErrors(newErrors)
    return Object.values(newErrors).every((x) => x === null)
  }

  // file
  const handleAddFile = async (file_id) => {
    setDocumentForm((prev) => ({
      ...prev,
      files: [file_id],
    }))
  }
  const onDeleteFile = (id) => {
    setFile(file.filter((v) => v.id !== id))
    setDocumentForm((prev) => ({
      ...prev,
      files: null,
    }))
  }
  const initFileType = () => {
    const allowedTypes = []
    const acceptType = allowedTypes.reduce((pre, cur) => {
      return {
        ...pre,
        ...acceptList[cur],
      }
    }, {})
    setAcceptTypes(acceptType)
  }

  // navigate
  const backToAcknowledgementPage = () => {
    dispatch(setSharedDocumentForm({}))
    dispatch(
      setFlagDraft({
        draftId: '',
        isDraft: false,
      })
    )
    dispatch(setSharedServiceTemplateInfo({}))
    dispatch(setDocumentAcknowledgementInfo({}))
    navigate('/organization-management/acknowledgement-system?tab=document-management', { replace: true })
  }
  const saveAndNextPublish = () => {
    const isValid = validateForm()
    if (isValid) {
      const finalDocumentForm = {
        ...documentForm,
        file: file,
      }
      dispatch(setSharedDocumentForm(finalDocumentForm))
      navigate(`/organization-management/acknowledgement-system/publish-document/${id}`)
    }
  }

  // submit
  const saveDraft = () => {
    const isValid = validateForm()
    if (isValid) {
      const { publish_to, departments, employees, email_noti, in_app_noti, ...restDocumentForm } = documentForm
      const finalDocumentForm = {
        ...restDocumentForm,
      }
      dispatch(setSharedDocumentForm(documentForm))
      dispatch(saveDraftDocumentAcknowledge(finalDocumentForm))
    }
  }

  // modal
  const previewPada = () => {
    const isValid = validateForm()
    if (isValid) {
      setPreviewPdpaModal(true)
    }
  }
  return (
    <Div>
      <div className="header mb-2-rem">
        <RiHome6Line size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px">{t('hr_organization')}</span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px" onClick={backToAcknowledgementPage}>
          {t('acknowledgement_system')}
        </span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="highlight">{documentInfo?.name}</span>
      </div>

      <div className="headline mb-20-px">{documentInfo?.name}</div>
      <div className="divider mb-24-px"></div>

      <div className="document-container mb-2-rem">
        <div className="step-container">
          <div className="step-progress">
            <div className="step-item">
              <TbPointFilled className="step-icon" size={20} />
            </div>
            <div className="line-connect"></div>
            <div className="step-item inactive">
              <TbPointFilled className="step-icon inactive" size={20} />
            </div>
          </div>
          <div className="step-progress-2">
            <div className="mb-2-rem">
              <div className="header-step active">{t('step_1_content_and_setting')}</div>
              <div className="description-step active">{t('fill_required_fields_to_create_a_new_doc')}</div>
            </div>
            <div>
              <div className="header-step">{t('step_2_publish')}</div>
              <div className="description-step">{t('choose_recipients_and_notification_method')}.</div>
            </div>
          </div>
        </div>
        <div className="input-container">
          <div className="input" ref={errorRefName}>
            <div className="input-header">{t('select_existing_document_name_heading')} *</div>
            <AutoComplete
              className={`w-input mb-1-rem`}
              optionList={documentNameList}
              value={documentNameSelected}
              onChange={onChangeDocumentName}
              error={errors?.existingName ? errors?.existingName : false}
            />
            {errors?.existingName && <p className="error-text">{errors?.existingName}</p>}
            <div>{t('select_existing_document_name_sub_heading')}</div>
          </div>
          <div className="input">
            <div className="input-header">{t('document_name')} *</div>
            <TextField
              className={`w-input ${errors?.name ? 'error-border' : ''}`}
              name="name"
              value={documentForm?.name}
              onChange={onChangeForm}
              disabled={documentNameSelected !== 'อื่นๆ'}
            />
            {errors?.name && <p className="error-text">{errors?.name}</p>}
          </div>
          <div className="input">
            <div className="input-header">{t('document_template')}</div>
            <TextField className="w-input" value={documentInfo?.name} disabled={true} />
          </div>
          <div className="input">
            <div className="input-header">{t('category')}</div>
            <TextField className="w-input" value={documentInfo?.category} disabled={true} />
          </div>
          <div className="input">
            <div className="input-header">{t('type')}</div>
            <TextField className="w-input" value={documentInfo.type} disabled={true} />
          </div>
          <div className="input" ref={errorRefVersionAndDueDate}>
            <div className="input-header">{t('version')} *</div>
            <Dropdown2
              className={`mr-20-px ${errors?.version ? 'error-border' : ''}`}
              optionList={versionList}
              value={versionDigit1}
              onChange={(value) => setVersionDigit1(value)}
            />
            <span className="mr-20-px">.</span>
            <Dropdown2
              className={`mr-20-px ${errors?.version ? 'error-border' : ''}`}
              optionList={versionList}
              value={versionDigit2}
              onChange={(value) => setVersionDigit2(value)}
            />
          </div>
          {errors?.version && <p className="error-text">{errors?.version}</p>}

          <div className="input">
            <div className="input-header">{t('due_date')} *</div>
            <Date className="w-input" value={documentForm?.due_date} onChange={onChangeDate} error={errors?.due_date} />
            {errors?.due_date && <p className="error-text">{errors?.due_date}</p>}
          </div>
          <div className="d-flex">
            <div className="input mr-20-px">
              <div className="input-header">{t('document_lang_version')}</div>
              <RadioButton
                name="doc_lang"
                optionList={initLangRadioOptionList}
                value={documentForm?.doc_lang}
                handleChange={onChangeForm}
                disabled={true}
              />
            </div>
            <div className="input">
              <div className="input-header mb-18-px">{t('star_this_document')}</div>
              {documentForm?.is_star === 0 ? (
                <BsStar onClick={toggleIsStar} className="icon" />
              ) : (
                <BsStarFill className="icon yellow" onClick={toggleIsStar} />
              )}
            </div>
          </div>

          <div className="divider mb-64-px"></div>

          <div className="d-flex jt-end mb-32-px">
            <ButtonOutline append={<GrView />} onClick={previewPada}>
              {t('preview')}
            </ButtonOutline>
          </div>

          <div className="warning-container mb-32-px">
            <div className="d-flex">
              <div className="icon-warning-container">
                <PiWarningCircleBold size={25} />
              </div>
              <div className="warning-message-container">
                <div className="headline-warning">
                  {t('warning_topics_not_filled_in_will_not_appear_on_the_document_page')}
                </div>
                <div>{t('if_no_information_is_provided_for_any_topic_the_field_will_be_left_blank')}</div>
              </div>
            </div>
          </div>

          {documentTemplateInfo?.doc_topic === 'without_topic' && (
            <div className="text-editor-container">
              <ReactQuill
                style={{ height: '226px' }}
                className="mb-1-rem"
                theme="snow"
                modules={modules}
                value={documentForm?.other_topic}
                onChange={(value) => onChangeTextEditorOtherTopic(value)}
              />
            </div>
          )}

          {documentForm?.topics?.map((v, i) => (
            <div key={v.id} className="mb-1-rem">
              <div className="text-editor-container">
                <div className="text-editor-headline mb-1-rem">{v.title}</div>
                <ReactQuill
                  style={{ height: '226px' }}
                  className="mb-1-rem"
                  theme="snow"
                  modules={modules}
                  value={v?.description}
                  onChange={(value) => onChangeTextEditor(v.id, value)}
                />
              </div>
              {/* <ButtonOutline className="add-image-btn" append={<img src={userPlus} />}>
                {t('add_image')}
              </ButtonOutline> */}
            </div>
          ))}

          <FileUploadProgress2
            className="upload-container"
            multiple={multiple}
            accept={acceptTypes}
            mimeTypes={mimeTypes}
            file={file?.map((v) => ({ ...v, url: `${v.url}` }))}
            onAddFile={handleAddFile}
            onDeleteFile={onDeleteFile}
            error={error}
            disabled={disabled}
            FileListData={file}
            setFileListData={setFile}
          />
        </div>
      </div>

      <div className="divider mb-2-rem"></div>
      <div className="footer-container">
        <div>
          <ButtonOutline onClick={backToAcknowledgementPage} append={<LiaTimesSolid size={20} />}>
            {t('cancel')}
          </ButtonOutline>
        </div>
        <div>
          <ButtonOutline className="add-image-btn mr-20-px" append={<PiFloppyDiskBold size={20} />} onClick={saveDraft}>
            {t('save_as_draft')}
          </ButtonOutline>
          <Button append={<FiArrowRight size={20} />} onClick={saveAndNextPublish}>
            {t('save_and_next_to_publish')}
          </Button>
        </div>
      </div>

      <ModalPdpaPreview
        previewData={documentForm}
        isModalOpen={previewPdpaModal}
        onClose={() => setPreviewPdpaModal(false)}
      />
    </Div>
  )
}

export default CreateDocument
