import { useEffect, useState } from 'react'
import styled from 'styled-components'

import InputText from 'components/widget/element/InputText'

import RadioButton from 'components/form/RadioButton'

export default function Finance({ initValue, onChange, isPreview }) {
  const radioList = [
    {
      text: 'เห็นควร',
      value: true,
    },
    {
      text: 'ไม่เห็นควร',
      value: false,
    },
  ]

  const [selectedRadio, setSelectedRadio] = useState(radioList[0])
  const [textOutstandingBalance, setTextOutstandingBalance] = useState('')
  const [textSuggestions, setTextSuggestions] = useState('')

  useEffect(() => {
    if (initValue) {
      if (initValue?.is_agree) {
        setSelectedRadio({ text: radioList[0].text, value: true })
      } else if (!initValue?.is_agree) {
        setSelectedRadio({ text: radioList[1].text, value: false })
      }
      if (initValue?.text_outstanding_balance) {
        setTextOutstandingBalance(initValue.text_outstanding_balance)
      }
      if (initValue?.text_suggestions) {
        setTextSuggestions(initValue.text_suggestions)
      }
    }
  }, [initValue])

  const onRadioChange = (event) => {
    const { name, value } = event.target
    const tmpValueRadio = { text: name, value: value }

    setSelectedRadio(tmpValueRadio)
    if (onChange) {
      onChange({
        is_agree: value === 'true' ? true : false,
        text_outstanding_balance: textOutstandingBalance,
        text_suggestions: textSuggestions,
      })
    }
  }

  const handleOutstandingBalanceChange = (e) => {
    setTextOutstandingBalance(e)
    if (onChange) {
      onChange({
        is_agree: selectedRadio?.value,
        text_outstanding_balance: e,
        text_suggestions: textSuggestions,
      })
    }
  }

  const handleSuggestions = (e) => {
    setTextSuggestions(e)
    if (onChange) {
      onChange({
        is_agree: selectedRadio?.value,
        text_outstanding_balance: textOutstandingBalance,
        text_suggestions: e,
      })
    }
  }

  return (
    <Styled className="finance">
      <div className={'wrp_finance'}>
        {!isPreview && <div className={'title'}>การเงิน</div>}

        <div className={'row'}>
          <div className={'inner_row'}>
            <div className={'inner_row_left'}>
              {!isPreview && <div className={'title_item'}>ยอดคงค้าง (บาท)</div>}

              {!isPreview ? (
                <InputText
                  placeHolder=""
                  onChange={handleOutstandingBalanceChange}
                  type="number"
                  step="0.01"
                  initValue={textOutstandingBalance}
                />
              ) : (
                <div className={'preview_text_outstanding_balance'}>
                  ยอดคงค้าง {initValue?.text_outstanding_balance || '-'} บาท ทางการเงินมีความ
                  <b className={'select_finance_radio'}>{initValue?.is_agree ? 'เห็นควร' : 'ไม่เห็นควร'}</b>
                </div>
              )}
            </div>

            {!isPreview && (
              <div className={'inner_row_right finance_radio'}>
                <RadioButton optionList={radioList} value={selectedRadio?.value} handleChange={onRadioChange} />
              </div>
            )}
          </div>
        </div>

        <div className={'row'}>
          {!isPreview && <div className={'title_item'}>ข้อเสนอแนะ</div>}

          {!isPreview ? (
            <InputText placeHolder="details" onChange={handleSuggestions} initValue={textSuggestions} />
          ) : (
            <div className={'text_text_suggestions'}>ข้อเสนอแนะ {initValue?.text_suggestions || '-'}</div>
          )}
        </div>
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  .wrp_finance {
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid var(--Gray-200);
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    color: var(--Gray-700);
  }

  .row {
    margin-top: 24px;

    .title_item {
      margin-bottom: 6px;
    }

    .inner_row {
      display: flex;
      width: 100%;

      .inner_row_left,
      .inner_row_right {
        width: 100%;
      }
      .inner_row_left {
        margin-right: 16px;
      }
      .inner_row_right {
        display: flex;
        align-items: end;
      }
    }
  }

  .finance_radio {
    position: relative;
    top: 8px;
  }

  .select_finance_radio {
    margin-left: 8px;
  }
`
