import { combineReducers } from 'redux'
import { GET_TASK_TYPES, GET_TASK_TYPE_BY_ID, CREATE_TASK_TYPE, UPDATE_TASK_TYPE } from '../actionTypes'

const initialState = {
  taskTypes: [],
  taskInfo: {},
  total: 1,
  page: 1,
  limit: 10,
  pageCount: 1,
  isLoading: false,
}

const taskTypeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_TASK_TYPES.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_TASK_TYPES.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        taskTypes: data,
        total: meta.pagination.total,
        limit: meta.pagination.pageSize,
        page: meta.pagination.page,
        pageCount: meta.pagination.pageCount,
        isLoading: false,
      }
    }
    case GET_TASK_TYPES.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_TASK_TYPE_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_TASK_TYPE_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        taskInfo: data,
        isLoading: false,
      }
    }
    case GET_TASK_TYPE_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_TASK_TYPE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_TASK_TYPE.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        isLoading: false,
      }
    }
    case CREATE_TASK_TYPE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_TASK_TYPE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_TASK_TYPE.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_TASK_TYPE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  taskTypeReducer,
})
