import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { selectSharedService } from '../../redux/selectors'
import { getDocumentContent } from '../../redux/actions/sharedService'

import styled from 'styled-components'
import DocumentContent from '../../components/feature/acknowledgement-system/DocumentContent'
import Invitation from '../../components/feature/acknowledgement-system/Invitation'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { TbPointFilled } from 'react-icons/tb'

const Div = styled.div`
  /* shared-layout */
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .mb-18-px {
    margin-bottom: 18px !important;
  }
  .mb-20-px {
    margin-bottom: 1.25rem;
  }
  .mb-24-px {
    margin-bottom: 1.5rem;
  }
  .mb-32-px {
    margin-bottom: 2rem;
  }
  .mb-64-px {
    margin-bottom: 4rem;
  }
  .mr-12-px {
    margin-right: 0.75rem;
  }
  .mr-20-px {
    margin-right: 1.25rem;
  }
  .d-flex {
    display: flex;
  }
  .jt-end {
    justify-content: flex-end;
  }

  /* shared-typography */
  .header {
    display: flex;
    align-items: center;

    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    .highlight {
      color: var(--Primary-700);
      font-weight: 600;
    }
  }
  .headline {
    color: var(--Gray-900, #101828);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
  }
  .text-center {
    text-align: center;
  }
  .header-step {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-weight: 600;

    &.active {
      color: var(--Primary-700);
    }
  }
  .description-step {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-weight: 400;

    &.active {
      color: var(--Primary-700);
    }
  }

  /* shared component */
  .divider {
    width: 100%;
    height: 1px;
    background: var(--Gray-200);
  }
  .input {
    margin-bottom: 1.5rem;

    .input-header {
      color: var(--Gray-700);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 0.5rem;
    }

    .w-input {
      width: 100%;
    }
  }
  .add-image-btn {
    border-radius: 8px;
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }
  .step-item {
    width: 30px;
    height: 30px;

    border-radius: 50%;
    border: 1px solid var(--Stepper-Line);

    display: flex;
    justify-content: center;
    align-items: center;

    .step-icon {
      color: var(--Stepper-Icon);

      &.inactive {
        color: var(--Gray-200);
      }
    }

    &.inactive {
      border: 1px solid var(--Gray-200);
    }
  }
  .line-connect {
    width: 2px;
    flex-grow: 1;
    background-color: var(--Gray-200);
    margin: 0 auto;
  }
  .error-border {
    border-color: var(--Error-600);
  }
  .error-text {
    color: var(--Error-600);
  }
  .icon {
    &.yellow {
      color: #fdb022;
    }
  }
  .item-vertical-align-center {
    vertical-align: middle;
  }

  /* general */
  .document-container {
    display: flex;
    justify-content: space-between;

    .step-container {
      width: 30%;

      height: 400px;
      display: flex;

      .step-progress {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-right: 0.75rem;
      }
      .step-progress-2 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
    /* .step-container > div:last-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    } */
    .input-container {
      width: 68%;
    }
  }
  .warning-container {
    color: var(--warning-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;

    border-radius: 0.75rem;
    border: 1px solid var(--warning-300);
    background: var(--warning-25);

    padding: 1rem;

    .icon-warning-container {
    }

    .warning-message-container {
      .headline-warning {
        font-weight: 600;
      }
    }
  }
  .text-editor-container {
    box-sizing: border-box;
    padding-bottom: 65px;

    .text-editor-headline {
      color: var(--Gray-700);
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
    }
  }
  .upload-container {
    width: 464px;
  }
  .footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 2rem;
  }
`
const TabMenuContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  .tab-menu {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-500);
    padding: 0.75rem;
    :hover {
      cursor: pointer;
    }
    &.active {
      color: var(--Primary-700);
      border-bottom: 2px solid var(--Primary-700);
      background: var(--Primary-50);
    }
  }
`
const BadgeStatus = styled.div`
  display: inline-block;
  border-radius: 0.375rem;
  border: 1px solid var(--Gray-300);
  background: var(--Base-White);

  color: var(--Gray-700);
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;

  padding: 0 0.375rem;

  .active-color {
    color: var(--Status-Active);
  }
  .inactive-color {
    color: var(--Status-Inactive);
  }
  .draft-color {
    color: var(--Status-Draft);
  }
`

function ViewDocument() {
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // store
  const { documentAcknowledgementInfo } = useSelector(selectSharedService)
  const [tab, setTab] = useState('document-content')

  const fetchData = useCallback(
    (id) => {
      dispatch(getDocumentContent(id))
    },
    [dispatch]
  )

  useEffect(() => {
    fetchData(id)
  }, [id, tab])

  // toggle menu
  const toggleTab = (tab) => {
    setTab(tab)
  }

  // navigate
  const backToAcknowledgementPage = () => {
    navigate('/organization-management/acknowledgement-system?tab=document-management', { replace: true })
  }
  return (
    <Div>
      <div className="header mb-2-rem">
        <RiHome6Line size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px">{t('hr_organization')}</span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px" onClick={backToAcknowledgementPage}>
          {t('acknowledgement_system')}
        </span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="highlight">{documentAcknowledgementInfo?.name}</span>
      </div>

      <div className="headline mb-20-px">{documentAcknowledgementInfo?.name}</div>
      <div className="divider mb-24-px"></div>

      <TabMenuContainer>
        <span
          className={`tab-menu ${tab === 'document-content' ? 'active' : ''}`}
          onClick={() => toggleTab('document-content')}
        >
          {t('document_content')}
        </span>
        <span className={`tab-menu ${tab === 'invitation' ? 'active' : ''}`} onClick={() => toggleTab('invitation')}>
          {t('invitation')}
        </span>
      </TabMenuContainer>

      <BadgeStatus className="mb-1-rem">
        <TbPointFilled
          className={`${
            documentAcknowledgementInfo?.status === 'Active'
              ? 'active-color'
              : documentAcknowledgementInfo?.status === 'Inactive'
              ? 'inactive-color'
              : 'draft-color'
          } item-vertical-align-center`}
          size={20}
        />
        <span className="item-vertical-align-center">{documentAcknowledgementInfo?.status}</span>
      </BadgeStatus>

      {tab === 'document-content' ? <DocumentContent></DocumentContent> : <Invitation></Invitation>}
    </Div>
  )
}

export default ViewDocument
