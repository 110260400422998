import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import ButtonOutline from '../../form/button/ButtonOutline'

import { LiaTimesSolid } from 'react-icons/lia'
import { AiOutlineHistory } from 'react-icons/ai'

import userDemo from '../../../assets/images/member-img.png'

import { selectKPI } from '../../../redux/selectors'
import { getKpiHistoryLogs } from '../../../redux/actions/kpiManagement'
import { DateTimeHHmmssDisplay } from '../../../utils/common'

const Div = styled.div`
  padding: 24px;

  .mr-8-px {
    margin-right: 8px;
  }
  .mr-12-px {
    margin-right: 12px;
  }
  .mr-16-px {
    margin-right: 16px;
  }
  .mb-12-px {
    margin-bottom: 12px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }

  .icon {
    cursor: pointer;
  }
  .history-heading {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .history-sub-heading {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .inline-block-item {
    display: inline-block;
  }
  .vertical-align-item-middle {
    vertical-align: middle;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-start {
    align-items: flex-start;
  }
  &.align-items-center {
    align-items: center;
  }
`
const HistoryLogItem = styled.div`
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  .history-info {
    color: var(--Gray-600);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .editor-img {
    width: 48px;
    height: 48px;
  }

  .editor-name {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  b {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }
`

function EditingKpiHistoryLog({ refId, open, onClose }) {
  // external hook
  const dispatch = useDispatch()

  // initial data
  const fetchKpiHistoryLogs = useCallback(() => {
    dispatch(getKpiHistoryLogs(refId))
  }, [dispatch])
  useEffect(() => {
    if (open) fetchKpiHistoryLogs()
  }, [open])

  // kpi history logs data
  const { kpiHistoryLogs } = useSelector(selectKPI)

  const navigateToKpiHistoryDetail = (id) => {
    window.open(`annoucement/kpi-history-detail/${id}`, '_blank')
  }

  return (
    <Div>
      <FlexContainer className="justify-content-space-between align-items-start mb-24-px">
        <div>
          <AiOutlineHistory className="inline-block-item vertical-align-item-middle mr-16-px" size={30} />
          <div className="inline-block-item vertical-align-item-middle">
            <div className="history-heading">Version History</div>
            <div className="history-sub-heading">Look back what has changed</div>
          </div>
        </div>
        <LiaTimesSolid className="icon" size={20} onClick={onClose} />
      </FlexContainer>

      {kpiHistoryLogs.map((v) => (
        <HistoryLogItem key={v.id}>
          <FlexContainer className="">
            <img className="editor-img mr-12-px" src={userDemo} alt="user-image" />
            <div className="history-info">
              <div>
                <span className="editor-name mr-8-px">{v.creator}</span>
                <span>{DateTimeHHmmssDisplay(v.event_date)}</span>
              </div>
              <div className="mb-12-px">
                <span>{v.action}</span> <b>KPIs</b> <span>in</span> <b>{v.section}</b>
              </div>
              <ButtonOutline onClick={() => navigateToKpiHistoryDetail(v.id)}>See Version</ButtonOutline>
            </div>
          </FlexContainer>
        </HistoryLogItem>
      ))}
    </Div>
  )
}

export default EditingKpiHistoryLog
