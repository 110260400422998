import { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import Table from '../../components/common/Table'

import { BiEdit } from 'react-icons/bi'

import { getMaterialById, getMaterialNotes, createMaterialNote } from '../../redux/actions/material'
import { selectMaterial } from '../../redux/selectors'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .text-color-primary {
    color: #7f56d9;
  }

  .font-bold {
    font-weight: bold;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .content-header {
    margin-top: 24px;
  }

  .row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error {
    color: red;
  }

  .tab-highlight {
    display: inline;
    color: #7f56d9;
    font-weight: bold;
    border-bottom: 2px solid #7f56d9;
  }

  .content-container {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;

    .edit-btn-container {
      display: flex;
      justify-content: flex-end;
    }

    .info-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 10px;
      padding: 10px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .flex-container {
      display: flex;

      .product-container {
        flex: 1;

        .profile-display-mock {
          width: 50px;
          height: 50px;
          background: gray;
          border-radius: 50%;
        }
        .product-item {
          padding: 1rem 0;
          border-bottom: 1px solid #eaecf0;
        }
      }
      .note-container {
        flex: 1;
      }
    }
  }

  .data-table {
    display: flex;
    justify-content: space-between;
  }

  .mock-image {
    width: 100%;
    height: 500px;
    background-color: gray;
  }

  .header-box {
    background: #e9d7fe;
    padding: 1rem;
  }

  .badge {
    background: #ecfdf3;
    padding: 0.5rem;
    border-radius: 12px;
  }
`

const limitLetter = 275

const MaterialDetail = () => {
  const { t } = useTranslation()
  const noteColumn = [
    {
      Header: t('time'),
      accessor: 'createdAt',
      disableSortBy: false,
    },
    {
      Header: t('note_by'),
      accessor: 'note_by',
      disableSortBy: false,
    },
    {
      Header: t('note'),
      accessor: 'note',
      disableSortBy: false,
    },
  ]
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { notes, materialInfo } = useSelector(selectMaterial)
  const [note, setNote] = useState('')
  const [isSubmitNote, setIsSubmitNote] = useState(false)
  const [limitChar, setLimitChar] = useState(limitLetter)

  useEffect(() => {
    dispatch(getMaterialNotes(id))
    dispatch(getMaterialById(id))
  }, [dispatch, id])

  useEffect(() => {
    const restLetter = limitLetter - note.length
    setLimitChar(restLetter)
  }, [note])

  const onSubmitNote = () => {
    setIsSubmitNote(true)
    if (note !== '') {
      const { user } = JSON.parse(localStorage.getItem('admin'))
      const request = {
        data: {
          note: note,
          note_by: `${user.firstname} ${user.lastname}`,
          material: id,
        },
      }
      dispatch(createMaterialNote(request))
      setIsSubmitNote(false)
      setNote('')
    }
  }

  return (
    <Div>
      <header className="mb-1-rem">
        <div className="content-title">{t('material_review')}</div>
        <div className="content-header">
          <div className="tab-highlight">{t('material_information')}</div>
        </div>
      </header>
      <div className="content-container">
        <div className="edit-btn-container mb-1-rem">
          <Link className="create-bt" to={`/inventory-management/materials/edit-material/${id}`}>
            <Button append={<BiEdit />}>{t('Edit')}</Button>
          </Link>
        </div>
        <div className="info-container mb-1-rem">
          {materialInfo?.material_images?.length > 0 ? (
            <>
              {materialInfo?.material_images?.map((v, i) => (
                <img key={i} src={v.url} />
              ))}
            </>
          ) : (
            <div className="mock-image"></div>
          )}
        </div>

        <div className="flex-container">
          <div className="product-container">
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('material_code')}</div>
              <div>{materialInfo?.code}</div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('material_name')}</div>
              <div>{materialInfo?.material_name}</div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('category')}</div>
              <div>{materialInfo?.material_category?.name}</div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('type')}</div>
              <div>{materialInfo?.material_type?.name}</div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('uom')}</div>
              <div>{materialInfo?.UoM}</div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('supplier')}</div>
              <div>{materialInfo?.supplier?.supplier_name}</div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('cost_per_unit')}</div>
              <div>12 Jan 2023 16:00 PM</div>
            </div>
            <div className="product-item">
              <div className="text-color-primary font-bold">{t('status')}</div>
              <div>{materialInfo?.status}</div>
            </div>
          </div>
          <div className="note-container">
            <div className="modal-container">
              <div className="modal-header">{t('note')}</div>
              <div className="mb-1-rem">
                <Table columns={noteColumn} data={notes} />
              </div>
              <textarea
                className={`text-area mb-1-rem ${isSubmitNote && note == '' ? 'error' : ''}`}
                value={note}
                onChange={(e) => setNote(e.target.value.substring(0, 275))}
              ></textarea>
              {isSubmitNote && note == '' && <div className="error">{t('please_enter_a_message')}</div>}
              <div>{limitChar} {t('charater_left')}</div>
              <div className="dialog-button">
                <ButtonOutline
                  onClick={() => {
                    navigate('/inventory-management/materials', {
                      replace: true,
                    })
                  }}
                >
                  {t('cancel')}
                </ButtonOutline>
                <Button onClick={onSubmitNote}>{t('submit')}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Div>
  )
}

export default MaterialDetail
