import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactQuill from 'react-quill'
import SignatureCanvas from 'react-signature-canvas'
import { getAdminUser, getCompanyInfo, DateTimeHHmmDisplay } from '../../utils/common'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import Modal from '../common/Modal'
import IconButton from '../../components/common/IconButton'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import OutlineCheckbox from '../../components/form/OutlineCheckbox'
import ImageUploadCrop from '../../components/form/ImageUploadCrop'
import DialogWarning from '../../components/dialog/DialogWarning'
import Dropdown from '../form/Dropdown'

import successLogo from '../../assets/images/success-icon-2.png'
import iconSigna from '../../assets/images/icon-signature.svg'
import { IoMdClose } from 'react-icons/io'
import { FiAlertCircle } from 'react-icons/fi'
import { BsCheckCircleFill } from 'react-icons/bs'
import { PiWarningCircleBold } from 'react-icons/pi'
import { AiOutlineCloudDownload } from 'react-icons/ai'
import penToolPlus from '../../assets/images/pen-tool-plus.svg'
import warningIcon from '../../assets/images/warning-icon.png'

import transparentBg from '../../assets/images/overlay.jpg'
import pdfDownloadIcon from '../../assets/images/pdf-download-icon.png'

import { selectSharedServiceEmployee } from '../../redux/selectors'
import {
  acknowledgeAssignment,
  uploadSignatureAndThenSign,
  getEmployeeSignature,
  ackAndsignSignature,
  readInfoAssignment,
  getAssignmentById,
  setNotiEmailDocId,
  updateEmployeeInfo,
} from '../../redux/actions/shareServiceEmployee'
import { getEmployeeById } from '../../redux/actions/master'
import { setIsRefreshNoti } from '../../redux/actions/notifications'
import TextField from '../form/TextField'
import { json } from 'react-router-dom'

// style
const Div = styled.div`
  padding: 0 30px;
  .mr-12-px {
    margin-right: 12px;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .icon {
    cursor: pointer;
  }
  .icon-close-container {
    display: flex;
    justify-content: flex-end;
  }
  .button-w {
    display: flex;
    justify-content: center;
    flex: 1;
    text-align: center;
  }
  .d-flex {
    display: flex;
  }
  .gap {
    gap: 1rem;
  }
  .justify-end {
    justify-content: flex-end;
    width: 110%;
    margin-top: 20px;
  }
  .justify-center {
    justify-content: center;
  }
  .icon {
    margin-top: 10px;
    font-size: 22px;
  }
  .card-modal-container {
    padding: 32px;
    border-radius: 8px;
    overflow: hidden;
  }

  .card-modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 20px;
    h2,
    p {
      margin: 0px;
      padding: 5px 10px;
    }
    p {
      color: #6941c6;
    }
    h2 {
      text-align: center;
    }
  }

  .card-modal-body {
    max-height: 356px;
    overflow-y: auto;
    margin-bottom: 1rem;
  }

  .card-modal-footer {
    padding: 10px 20px;
  }

  /* Styling for the scrollbar */
  .card-modal-body::-webkit-scrollbar {
    width: 6px;
  }

  .card-modal-body::-webkit-scrollbar-track {
    background-color: #f7f7f7;
  }

  .card-modal-body::-webkit-scrollbar-thumb {
    background-color: #b2b2b2;
  }
  .modal-content {
    padding: 20px;
    border-radius: 5px;
    width: 100%;
    max-width: 800px;
    margin: 0;
    text-align: center;
    .d-flex {
      display: flex;
    }
    .justify-end {
      justify-content: flex-end;
      width: 100%;
      margin: 0px;
    }
    h2,
    p {
      margin: 0px;
      padding: 5px 5px;
    }
    .done {
      margin-top: 20px;
    }
  }

  .card-modal-container-signa {
    padding: 10px 20px;
    margin-top: 15px;
    .text-header {
      margin-top: 10px;
      margin-bottom: 10px;
      h4,
      span {
        margin: 0px;
      }
    }
    .justify-between {
      justify-content: space-between;
    }
    .justify-end {
      justify-content: flex-end;
      width: 100%;
      margin-top: 20px;
    }
    .card-modal-header-signa {
      display: block;
      align-items: start;
      padding: 10px 20px;
      margin: -15px;

      h2,
      p {
        margin: 0px;
        padding: 5px 10px;
      }
    }
    .card-modal-body-signa {
      width: 100%;
      max-height: 300px;

      padding: 15px 20px;
      margin-bottom: 5px;

      border: solid 1px hsl(0deg 0% 93.33%);
      border-radius: 10px;

      background-color: hsl(0deg 0% 93.33%);
    }

    .sigCanvas {
      width: 100% !important;
      height: 300px !important;
    }

    .upload-croped-image-body {
    }

    .card-modal-footer-signa {
      padding: 10px 15px;
      margin-bottom: 15px;
    }

    .line {
      border-top: 1px solid #eaecf0;
      padding: 0;
      margin: 0 -20px;
    }
    .btn-signa {
      margin-top: 15px;
    }
    .text-signa {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .wrp-tab-menu {
      width: 100%;
      margin-bottom: 1rem;
      display: flex;

      .btn-menu-style {
        border: none;
        border-bottom: 2px solid transparent;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        border-bottom: 1px solid rgb(94 96 100 / 28%);
      }

      .menu-active {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: #f9f5ff;
        color: #6941c6;
        border-bottom: 2px solid var(--Primary-600) !important;
      }
    }
  }

  .version-content {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    .version-card {
      border-radius: 8px;
      border: 1px solid var(--Gray-300);
      background: #fcfcfd;
      width: 132px;
      padding: 10px 14px;
    }

    .version-no {
      color: var(--Gray-900);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      margin-right: 8px;
    }

    .latest-badge {
      border-radius: 16px;
      border: 1px solid var(--Primary-200);
      background: var(--Primary-50);
      padding: 2px 6px;

      color: var(--Primary-700);
    }
  }

  .mb-0_5-rem {
    margin-bottom: 0.5rem;
  }

  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .topic {
    color: var(--Gray-600);
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }
    .topic-header {
      color: var(--Gray-900);
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
    }
    .ql-editor {
      font-family: 'Inter', 'Noto Sans Thai';

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
    /* p {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
    } */
    .ql-tooltip {
      display: none;
    }
  }

  .checkbox-consent {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    display: flex;
    align-items: center;

    padding: 24px 0;
  }

  .mr-8-px {
    margin-right: 8px;
  }

  .company-name {
    color: var(--Primary-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .btn-outline {
    border-radius: 8px;
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }
  .doc-name-version {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .confirm-desc {
    color: var(--Gray-600, #475467);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`
const CurrentSinatureWrapper = styled.div`
  .img-signature-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 44px 0;
    margin-bottom: 1rem;

    background-image: url(${transparentBg});
    background-size: cover;

    img {
      max-height: 250px;
    }
  }

  .warning-container {
    color: var(--Primary-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;

    display: flex;
    align-items: center;
  }

  .current-signature-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;

    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;

    .headline {
      color: var(--Gray-900);
      font-size: 1rem;
      font-weight: 600;
    }

    .img-container {
      border-radius: 10px;
      border: 1px solid var(--Gray-200);
      background: var(--Base-White);
      padding: 12px 14px 10px 14px;
    }
  }
`
const FlexContainer = styled.div`
  display: flex;
  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-center {
    align-items: center;
  }

  .w-20 {
    width: 20%;
  }
  .w-38 {
    width: 38%;
  }
  .w-50 {
    width: 50%;
  }
  .w-100 {
    width: 100%;
  }
`
const SignContainer = styled.div`
  margin-bottom: 1rem;
  .alert-icon {
    color: var(--Primary-600);
  }
  .description {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .sign-file {
    display: flex;
    align-items: center;

    :hover {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-color: var(--Primary-700);
    }
    .icon-pdf {
      width: 20px;
      height: 20px;
    }
    .text-download {
      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }
`
const DocumentNotFoundContainer = styled.div`
  .mr-12-px {
    margin-right: 0.75rem;
  }

  .highlight-error {
    color: var(--Primary-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }
  .error-headline {
    color: var(--Gray-900);
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  .error-desc {
    color: var(--Gray-600);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 3rem;
  }
`
const ModalContainer = styled.div`
  box-sizing: content-box;
  padding: 32px 100px;
  width: 100%;
  max-width: 800px;

  .mr-12-px {
    margin-right: 12px;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-18-px {
    margin-bottom: 18px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }

  .text-align-center {
    text-align: center;
  }

  h2,
  h3,
  p {
    margin: 0;
  }

  h2 {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }

  p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .done {
    margin-top: 20px;
  }
`
const InputContainer = styled.div`
  .heading {
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 6px;
  }

  .error {
    color: var(--Error-700);
  }
  .error-border {
    border: 1px solid var(--Error-600);
  }
`
const SuggestContainer = styled.div`
  padding: 16px;
  background-color: var(--Primary-50);
  border-radius: 10px;

  b {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`

const ModalPdpa = ({ isModalOpen, onClose }) => {
  const { t } = useTranslation()
  // initial data
  const initTextWarning = {
    headline: t('warning_upload_sign_heading'),
    description: t('warning_upload_sign_sub_heading'),
  }
  const initTabMenuList = [
    {
      name: t('current'),
      value: 'current',
    },
    {
      name: t('draw'),
      value: 'draw',
    },
    {
      name: t('upload'),
      value: 'upload',
    },
  ]
  const initialForm = {
    title: '',
    title_th: '',
    first_name: '',
    last_name: '',
    first_name_th: '',
    last_name_th: '',
  }
  const initTitleEn = [
    {
      text: 'Mr.',
      value: 'Mr.',
    },
    {
      text: 'Mrs.',
      value: 'Mrs.',
    },
    {
      text: 'Miss.',
      value: 'Miss.',
    },
  ]
  const initTitleTh = [
    {
      text: 'นาย',
      value: 'นาย',
    },
    {
      text: 'นางสาว',
      value: 'นางสาว',
    },
    {
      text: 'นาง',
      value: 'นาง',
    },
  ]
  const initValidate = {
    first_name: null,
    last_name: null,
    first_name_th: null,
    last_name_th: null,
  }
  const companyInfo = getCompanyInfo()
  const userInfo = getAdminUser()

  const dispatch = useDispatch()

  // store reducer
  const { assignmentInfo, employeeSignature, isLoading, state } = useSelector(selectSharedServiceEmployee)
  // window width
  const [widthModalPdpa, setWidthModalPdpa] = useState(window.innerWidth)
  // data
  const [isConsent, setIsConsent] = useState(false)
  const [tabactive, setTabactive] = useState('current')
  const [menuTabList] = useState(initTabMenuList)
  const [warningText] = useState(initTextWarning)
  const [typeDoc, setTypeDoc] = useState('')
  const [employeeInfo, setEmployeeInfo] = useState()
  // form
  const [form, setForm] = useState(initialForm)
  // validate
  const [validateForm, setValidateForm] = useState(initValidate)
  // signature draw image
  const sigCanvas = useRef(null)
  // const [fileSignature, setFileSignature] = useState([])
  // croped image
  const [croppedImageUrl, setCroppedImageUrl] = useState(null)
  // modal
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  const [isModalSignatureOpen, setModalSignatureOpen] = useState(false)
  const [isWarningModal, setIsWarningModal] = useState(false)
  const [isOpenedCheckInfoModal, setIsOpenedCheckInfoModal] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      const widthModal = (window.innerWidth * 75) / 100
      setWidthModalPdpa(widthModal)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (employeeInfo) {
      const { title, first_name, last_name, first_name_th, last_name_th } = employeeInfo
      setForm((prev) => ({
        ...prev,
        title,
        title_th: title == 'Mr.' ? 'นาย' : title == 'Mrs.' ? 'นาง' : 'นางสาว',
        first_name,
        last_name,
        first_name_th: first_name_th ? first_name_th : '',
        last_name_th: last_name_th ? last_name_th : '',
      }))
    }
  }, [employeeInfo])

  useEffect(() => {
    if (isModalOpen) dispatch(getEmployeeSignature())
  }, [isModalOpen, dispatch])

  useEffect(() => {
    if (assignmentInfo?.document_acknow) {
      const { type } = assignmentInfo?.document_acknow
      if (type === 'Info' && assignmentInfo?.status === 'Wait for response') {
        dispatch(readInfoAssignment(assignmentInfo?.id))
      }
      setTypeDoc(type)
    }
  }, [assignmentInfo])

  useEffect(() => {
    if (tabactive === 'draw' || tabactive === 'current') {
      // setFileSignature([])
      setCroppedImageUrl(null)
    } else {
      handleClear()
    }
  }, [tabactive])

  useEffect(() => {
    if (isLoading === false && state === 'ACKNOWLEDGE_ASSIGNMENT.SUCCESS') {
      dispatch(getAssignmentById(assignmentInfo?.id))
      dispatch(setIsRefreshNoti(true))
      closeModalPdpa()
      setIsModalSuccessOpen(true)
    }
    if (isLoading === false && state === 'SIGN_SIGNATURE.SUCCESS') {
      dispatch(getAssignmentById(assignmentInfo?.id))
      dispatch(setIsRefreshNoti(true))
      closeSignSignature()
      setIsModalSuccessOpen(true)
    }
    if (isLoading === false && state === 'UPDATE_EMPLOYEE_INFO.SUCCESS') {
      setIsOpenedCheckInfoModal(false)
      setModalSignatureOpen(true)
    }
  }, [isLoading, state])

  // about draw signature
  const convertBase64ToImageFile = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1])
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const int8Array = new Uint8Array(arrayBuffer)

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i)
    }

    const blob = new Blob([int8Array], { type: 'image/png' })
    return blob
  }
  const handleClear = () => {
    sigCanvas?.current?.clear()
  }

  // submit
  const confirmSignAcknowledge = () => {
    if (tabactive === 'current') {
      if (employeeSignature && employeeSignature.length > 0) {
        const request = {
          employee_signature: {
            id: employeeSignature[0]?.id,
          },
          status: 'Accept',
        }
        dispatch(ackAndsignSignature(assignmentInfo?.id, request))
      } else {
        setIsWarningModal(true)
      }
    } else if (tabactive === 'draw') {
      if (!sigCanvas.current.isEmpty()) {
        const signatureBase64 = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
        const blobData = convertBase64ToImageFile(signatureBase64)
        const formData = new FormData()
        formData.append('files', blobData, 'signature.png')
        dispatch(uploadSignatureAndThenSign(assignmentInfo?.id, formData))
      } else {
        setIsWarningModal(true)
      }
    } else {
      if (croppedImageUrl) {
        const blobData = convertBase64ToImageFile(croppedImageUrl)
        const formData = new FormData()
        formData.append('files', blobData, 'crop-sinature.png')
        dispatch(uploadSignatureAndThenSign(assignmentInfo?.id, formData))
      } else {
        setIsWarningModal(true)
      }
    }
  }
  const confirmAcknowledge = () => {
    if (typeDoc === 'Acknowledge') {
      const request = {
        status: 'Accept',
      }
      dispatch(acknowledgeAssignment(assignmentInfo?.id, request))
    } else if (typeDoc === 'Acknowledge and Sign') {
      const { employee_info } = JSON.parse(localStorage.getItem('admin'))
      dispatch(getEmployeeById(employee_info?.id)).then((data) => {
        setEmployeeInfo(data.data_info)
      })
      closeModalPdpa()
      setIsOpenedCheckInfoModal(true)
    } else {
      closeModalPdpa()
      setModalSignatureOpen(true)
    }
  }
  const declineAcknowledge = () => {
    if (typeDoc === 'Acknowledge' && assignmentInfo?.status === 'Wait for response') {
      const request = {
        status: 'Decline',
      }
      dispatch(acknowledgeAssignment(assignmentInfo?.id, request))
    } else {
      onClose()
    }
  }
  const proceedToSign = () => {
    if (isValid()) {
      const request = {
        data: {
          title: form.title,
          first_name: form.first_name,
          last_name: form.last_name,
          first_name_th: form.first_name_th,
          last_name_th: form.last_name_th,
        },
      }

      dispatch(updateEmployeeInfo(employeeInfo.id, request))
    }
  }
  const isValid = () => {
    let newErrors = {
      ...initValidate,
    }

    if (form.first_name_th === '') {
      newErrors.first_name_th = 'Please input first name (TH)'
    }
    if (form.last_name_th === '') {
      newErrors.last_name_th = 'Please input last name (TH)'
    }
    if (form.first_name === '') {
      newErrors.first_name = 'Please input first name (EN)'
    }
    if (form.last_name === '') {
      newErrors.last_name = 'Please input last name (EN)'
    }

    setValidateForm(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  // modal
  const closeModalPdpa = () => {
    setIsConsent(false)
    handleClear()
    // setFileSignature([])
    setTabactive('current')
    setCroppedImageUrl(null)
    onClose()
    setValidateForm(initValidate)
    dispatch(setNotiEmailDocId(null))
  }
  const closeSignSignature = () => {
    setTabactive('current')
    setCroppedImageUrl(null)
    setModalSignatureOpen(false)
    dispatch(setNotiEmailDocId(null))
  }
  const backToOpenModalPdpa = () => {
    setIsOpenedCheckInfoModal(false)
    closeModalPdpa()
  }

  // onChange
  const onChangeForm = (event) => {
    const { name, value } = event.target
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeDropdownForm = (value) => {
    setForm((prev) => ({
      ...prev,
      title: value,
      title_th: value == 'Mr.' ? 'นาย' : value == 'Mrs.' ? 'นาง' : 'นางสาว',
    }))
  }

  // general
  const openPdfFile = (url) => {
    window.open(url, '_blank')
  }
  const handleClickTaskMenuTab = (data) => {
    setTabactive(data)
  }

  return (
    <Div>
      <Modal open={Boolean(isModalOpen)} disableBackdropClick width={`${widthModalPdpa}px`}>
        <Div>
          {Object.keys(assignmentInfo).length > 0 ? (
            <div className="card-modal-container">
              <FlexContainer className="justify-content-space-between align-items-center mb-24-px">
                <div className="version-content">
                  <div className="mb-0_5-rem">{t('version')}</div>
                  <div className="version-card">
                    <span className="version-no">{assignmentInfo?.document_acknow?.version.toFixed(1)}</span>
                    <span className="latest-badge">{t('latest')}</span>
                  </div>
                </div>
                <div className="card-modal-header">
                  <div className="company-name">
                    {assignmentInfo?.document_acknow?.doc_lang === 'en'
                      ? companyInfo?.company_name_EN
                      : companyInfo?.company_name_TH}
                  </div>
                  <h2>{assignmentInfo?.document_acknow?.name}</h2>
                </div>
                <IoMdClose className="icon" onClick={closeModalPdpa} />
              </FlexContainer>

              {assignmentInfo?.document_acknow?.topics?.length > 0 ? (
                <div className="card-modal-body">
                  {assignmentInfo?.document_acknow?.topics?.map((v) => (
                    <div className="topic" key={v?.id}>
                      <div className="topic-header">{v?.title}</div>
                      <ReactQuill value={v?.description} readOnly={true} theme={'bubble'} />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="card-modal-body">
                  {assignmentInfo?.document_acknow?.other_topic && (
                    <div className="topic">
                      <ReactQuill
                        value={assignmentInfo?.document_acknow?.other_topic}
                        readOnly={true}
                        theme={'bubble'}
                      />
                    </div>
                  )}
                </div>
              )}

              {typeDoc === 'Acknowledge and Sign' && assignmentInfo?.status !== 'Wait for response' && (
                <SignContainer>
                  <div className="description">
                    <FiAlertCircle className="alert-icon mr-12-px" size={20} />
                    {t('sign_already_text_1')} {userInfo.first_name} {userInfo.last_name}
                    {t('sign_already_text_2')} {DateTimeHHmmDisplay(assignmentInfo?.signed_date)}
                  </div>
                  {assignmentInfo?.doc_signature && (
                    <div className="sign-file" onClick={() => openPdfFile(assignmentInfo?.doc_signature)}>
                      <img className="mr-12-px icon-pdf " src={pdfDownloadIcon} />
                      <div className="text-download"> {t('open_sign_already_text')}</div>
                    </div>
                  )}
                </SignContainer>
              )}

              {assignmentInfo?.status !== 'Wait for response' ? (
                <div className="card-modal-footer">
                  <div className="d-flex justify-center gap">
                    <Button className="button-w" onClick={closeModalPdpa}>
                      {t('done')}
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  {typeDoc !== 'Info' && (
                    <div className="checkbox-consent">
                      <OutlineCheckbox
                        className="mr-8-px"
                        checked={isConsent}
                        onChange={(event) => setIsConsent(event.target.checked)}
                        disabled={assignmentInfo?.status !== 'Wait for response'}
                      />
                      <span>{t('check_box_ack_text')}</span>
                    </div>
                  )}

                  <div className="card-modal-footer">
                    <div className="d-flex justify-center gap">
                      {typeDoc === 'Info' ? (
                        <Button className="button-w" onClick={closeModalPdpa}>
                          {t('done')}
                        </Button>
                      ) : (
                        <>
                          {assignmentInfo?.status !== 'Wait for response' && typeDoc === 'Acknowledge and Sign' ? (
                            <ButtonOutline className="button-w" onClick={declineAcknowledge} disabled={true}>
                              {t('decline')}
                            </ButtonOutline>
                          ) : typeDoc === 'Acknowledge and Sign' ? (
                            <ButtonOutline className="button-w" onClick={declineAcknowledge}>
                              {t('decline')}
                            </ButtonOutline>
                          ) : (
                            <></>
                          )}

                          {assignmentInfo?.status !== 'Wait for response' ? (
                            <Button className="button-w" onClick={confirmAcknowledge} disabled={true}>
                              {t('acknowledge_text')}
                            </Button>
                          ) : (
                            <Button className="button-w" onClick={confirmAcknowledge} disabled={!isConsent}>
                              {t('acknowledge_text')}
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="card-modal-container">
              <DocumentNotFoundContainer>
                <div className="highlight-error">{t('404_error')}</div>
                <div className="error-headline">{t('404_error_heading')}</div>
                <div className="error-desc">{t('404_error_description')}</div>
              </DocumentNotFoundContainer>
              <FlexContainer>
                <Button className="button-w" onClick={closeModalPdpa}>
                  {t('ok')}
                </Button>
              </FlexContainer>
            </div>
          )}
        </Div>
      </Modal>

      <Modal open={Boolean(isModalSignatureOpen)} disableBackdropClick>
        <Div>
          <div className="card-modal-container-signa">
            <div className="d-flex justify-between">
              <div>
                <img src={iconSigna} alt="iconSigna" />
              </div>
              <div>
                <IoMdClose className="icon" onClick={closeSignSignature} />
              </div>
            </div>
            <div className="card-modal-header-signa">
              <div className="text-header">
                <h4>{t('signature_needed')}</h4>
                <span>{t('signature_needed_text')}</span>
              </div>
              <div className="wrp-tab-menu">
                {menuTabList?.map((item, index) => (
                  <button
                    id={'this_name-' + item.name}
                    key={'btn-' + index}
                    className={tabactive === item.value ? 'menu-active' : 'btn-menu-style'}
                    onClick={() => handleClickTaskMenuTab(item.value)}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </div>

            {tabactive === 'current' ? (
              <CurrentSinatureWrapper>
                {employeeSignature[0]?.signature?.url ? (
                  <React.Fragment>
                    <div className="img-signature-container">
                      <img src={employeeSignature[0]?.signature?.url} alt="current-signature" />
                    </div>
                    <div className="warning-container">
                      <PiWarningCircleBold className="mr-8-px" size={20} />
                      <span>{t('sign_recommendation')}</span>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="current-signature-container">
                    <div className="img-container mb-16-px">
                      <img src={penToolPlus} alt="pen-tool-plus" />
                    </div>
                    <div className="headline">{t('no_sign_headline')}</div>
                    <div className="mb-24-px">{t('no_sign_sub_headline')}</div>
                    <div>
                      <Button className="mr-12-px" onClick={() => handleClickTaskMenuTab('draw')}>
                        {t('draw')}
                      </Button>
                      <Button onClick={() => handleClickTaskMenuTab('upload')}>{t('upload')}</Button>
                    </div>
                  </div>
                )}
              </CurrentSinatureWrapper>
            ) : tabactive === 'draw' ? (
              <>
                <div className="card-modal-body-signa">
                  <SignatureCanvas canvasProps={{ className: 'sigCanvas' }} ref={sigCanvas} />
                </div>
                <div className="btn-signa">
                  <Button onClick={handleClear}>X {t('clear')}</Button>
                </div>
              </>
            ) : (
              <div className="upload-croped-image-body">
                <ImageUploadCrop setCroppedImageUrl={setCroppedImageUrl} />
              </div>
            )}

            <div className="text-signa">{t('no_sign_description')}</div>
            <div className="line"></div>
            <div className="card-modal-footer-signa">
              <div className="d-flex justify-end gap">
                <ButtonOutline onClick={closeSignSignature}>{t('cancel')}</ButtonOutline>
                <Button onClick={confirmSignAcknowledge}>{t('accept_and_sign')}</Button>
              </div>
            </div>
          </div>
        </Div>
      </Modal>

      <Modal open={Boolean(isModalSuccessOpen)} disableBackdropClick>
        <Div>
          <div className="modal-content">
            <div className="d-flex justify-end">
              <IoMdClose className="icon" onClick={() => setIsModalSuccessOpen(false)} />
            </div>
            <IconButton className="logo" src={successLogo} />
            <h2> {t('confirmation')}</h2>
            <div className="confirm-desc mb-20-px">{t('confirmation_success_text')}:</div>
            <FlexContainer className="justify-content-center align-items-center">
              <BsCheckCircleFill className="mr-12-px" color="green" />
              <div className="doc-name-version">
                {assignmentInfo?.document_acknow?.name} {t('version')}{' '}
                {assignmentInfo?.document_acknow?.version.toFixed(1)}
              </div>
            </FlexContainer>
            <div className="d-flex done">
              {typeDoc === 'Acknowledge and Sign' && (
                <ButtonOutline
                  append={<AiOutlineCloudDownload size={20} />}
                  className="button-w btn-outline mr-12-px"
                  onClick={() => openPdfFile(assignmentInfo?.doc_signature)}
                >
                  {t('download_pdf')}
                </ButtonOutline>
              )}

              <Button className="button-w" onClick={() => setIsModalSuccessOpen(false)}>
                {t('done')}
              </Button>
            </div>
          </div>
        </Div>
      </Modal>

      <Modal open={Boolean(isOpenedCheckInfoModal)} disableBackdropClick>
        <ModalContainer>
          <h2 className="text-align-center">Please check personal information before signing</h2>
          <p className="text-align-center mb-32-px">Review and edit name and surname both in Thai and English</p>

          <FlexContainer className="mb-18-px">
            <InputContainer className="w-20 mr-12-px">
              <h3 className="heading">{t('title')} *</h3>
              <Dropdown disabled={true} optionList={initTitleTh} value={form.title_th} />
            </InputContainer>
            <InputContainer className="w-38 mr-12-px">
              <h3 className="heading">{t('first_name_th')} *</h3>
              <TextField
                className={`w-100 ${validateForm.first_name_th ? 'error-border' : ''}`}
                name="first_name_th"
                value={form.first_name_th}
                onChange={onChangeForm}
              />
              <p className="error">{validateForm.first_name_th}</p>
            </InputContainer>
            <InputContainer className="w-38">
              <h3 className="heading">{t('last_name_th')} *</h3>
              <TextField
                className={`w-100 ${validateForm.last_name_th ? 'error-border' : ''}`}
                name="last_name_th"
                value={form.last_name_th}
                onChange={onChangeForm}
              />
              <p className="error">{validateForm.last_name_th}</p>
            </InputContainer>
          </FlexContainer>

          <FlexContainer className="mb-18-px">
            <InputContainer className="w-20 mr-12-px">
              <h3 className="heading">{t('title')} *</h3>
              <Dropdown optionList={initTitleEn} value={form.title} onChange={onChangeDropdownForm} />
            </InputContainer>
            <InputContainer className="w-38 mr-12-px">
              <h3 className="heading">{t('first_name')} *</h3>
              <TextField
                className={`w-100 ${validateForm.first_name ? 'error-border' : ''}`}
                name="first_name"
                value={form.first_name}
                onChange={onChangeForm}
              />
              <p className="error">{validateForm.first_name}</p>
            </InputContainer>
            <InputContainer className="w-38">
              <h3 className="heading">{t('last_name')} *</h3>
              <TextField
                className={`w-100 ${validateForm.last_name ? 'error-border' : ''}`}
                name="last_name"
                value={form.last_name}
                onChange={onChangeForm}
              />
              <p className="error">{validateForm.last_name}</p>
            </InputContainer>
          </FlexContainer>

          <FlexContainer className="mb-24-px">
            <InputContainer className="w-50 mr-12-px">
              <h3 className="heading">{t('department')}</h3>
              <TextField className="w-100" disabled={true} value={employeeInfo?.department?.data?.attributes.name} />
            </InputContainer>
            <InputContainer className="w-50">
              <h3 className="heading">{t('job_position')}</h3>
              <TextField className="w-100" disabled={true} value={employeeInfo?.position?.data?.attributes?.name} />
            </InputContainer>
          </FlexContainer>

          <SuggestContainer className="mb-16-px">
            <b> Reminder :</b>
            <p>
              Accurate personal information is needed for the signed document. Information could not be changed once the
              document is signed.
              <br />
              <br />
              If your information about your employment is incorrect, please contact HR team before proceeding.
            </p>
          </SuggestContainer>

          <FlexContainer className="justify-content-center done">
            <ButtonOutline className="button-w btn-outline mr-12-px" onClick={backToOpenModalPdpa}>
              {t('back')}
            </ButtonOutline>
            <Button className="button-w" onClick={proceedToSign}>
              Proceed to Sign
            </Button>
          </FlexContainer>
        </ModalContainer>
      </Modal>

      <DialogWarning
        open={Boolean(isWarningModal)}
        onClose={() => setIsWarningModal(false)}
        onSubmit={() => setIsWarningModal(false)}
        icon={warningIcon}
        title={warningText.headline}
        description={warningText.description}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default ModalPdpa
