import { useEffect } from 'react'
import styled from 'styled-components'
import iconClose from '../../../assets/images/icon-close.svg'
import ImageButton from '../element/ImageButton'

export default function DialogRight({ headerView, bodyView, onClose }) {
  //animation_slide
  useEffect(() => {
    setTimeout(() => {
      document.querySelector('#dialogSlide').classList.add('slideToLeft')
    }, 10)
  }, [])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  return (
    <Styled>
      <div className="dialog" id="dialogSlide">
        <div className="dialog-inner">
          <div className="outer-header">
            {headerView}

            <div className="wrp_btn_close">
              <ImageButton
                className={'btn_close'}
                onClick={() => {
                  // animation_slide
                  document.querySelector('#dialogSlide').classList.add('slideToRight')
                  setTimeout(() => {
                    if (onClose) {
                      onClose()
                    }
                  }, 800)
                }}
                size={50}
                src={iconClose}
                iconSize={20}
              />
            </div>
          </div>
          <div className="outer-body">{bodyView}</div>
        </div>
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  position: fixed;
  z-index: 999;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(52, 64, 84, 0.6);

  .dialog {
    overflow: hidden;
    display: flex;
    right: 0;
    position: absolute;
    background-color: var(--Base-White);
    width: 1000px;
    height: 100%;
  }
  //animation_slide
  #dialogSlide {
    right: -1000px;
    transition: 0.8s;
    transition-timing-function: ease;
  }
  .slideToLeft {
    right: 0 !important;
  }
  .slideToRight {
    transition: 0.3s;
    right: -1000px !important;
  }
  .dialog-inner {
    position: relative;
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  .outer-header {
    display: flex;
    justify-content: start;
    width: 100%;
    padding: 20px;
  }

  .wrp_btn_close {
    margin-left: auto;
  }
  .outer-body {
    flex: 1;
  }
`
