import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import SidePanel from '../../../components/common/SidePanel'
import DialogFail from '../../../components/dialog/DialogFail'
import Date from '../../../components/form/Date'
import TextField from '../../../components/form/TextField'
import Button from '../../../components/form/button/Button'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import DocumentRequestApprove from '../../../components/widget/combo/DocumentRequestApprove'
import DocumentShareAndSendEmail from '../../../components/widget/combo/DoucumentShareAndSendEmail'
import DocumentOptionButton from '../../../components/widget/element/DocumentOptionButton'
import InputTextArea from '../../../components/widget/element/InputTextArea'
import ModalBox from '../../../components/widget/element/ModalBox'
import {
  createPurchase,
  editPurchaseDoc,
  getDocumentSettingByCompanyId,
  getSaleDocumentById,
} from '../../../redux/actions/document'

import DialogDocument from 'pages/document/DialogDocument'
import Table from '../../../components/common/Table'
import IconBin from '../../../components/widget/icon/IconBin'
import { selectDocument } from '../../../redux/selectors'
import { removeArrayItemByIndex } from '../../../utils/helper'

export default function CreateChequePaymentJournal({ onCallback }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const viewType = searchParams.get('type')
  const navigateType = searchParams.get('navigate')
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { saleDocumentData, documentSettingInfo } = useSelector(selectDocument)

  const TABLE_HEADER_COLUMN = [
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'เลขบัญชี',
      accessor: 'account_number',
      disableSortBy: false,
    },
    {
      Header: 'รายละเอียด',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'เดบิท',
      accessor: 'debit',
      disableSortBy: false,
    },
    {
      Header: 'เครดิต',
      accessor: 'credit',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'delete',
      disableSortBy: false,
    },
  ]

  const DEFAULT_BODY_ITEM_DATA = {
    no: '',
    account_number: '',
    details: '',
    debit: 0,
    credit: 0,
    delete: false,

    //validate
    is_error_account_number: false,
    is_error_details: false,
    is_error_debit: false,
    is_error_credit: false,
  }

  const [dataCreating, setDataCreating] = useState({
    payment_list: [],
    company_id: company_info?.id,
    doc_type: viewType,
    details: '',
    doc_number: '',
    doc_date: '',
    transport_date: '',
    arrival_date: '',
    carrier: '',

    number_of_copy: 1,
    company_logo: '',
    rubber_stamp: '',
    is_external: navigateType == 'external' ? true : false,
  })

  const [bodyTableList, setBodyTableList] = useState([])
  const [validateInputData, setValidateInputData] = useState({
    is_error_details: false,
    is_error_doc_number: false,
    is_error_doc_date: false,
    is_error_transport_date: false,
    is_error_arrival_date: false,
    is_error_carrier: false,
    is_error_payment_list: false,
  })
  const [isShowFailedModal, setIsShowFailedModal] = useState(false)
  const [currentDocId, setCurrentDocId] = useState('')

  const [dialogPurchaseType, setDialogPurchaseType] = useState('')
  const [isShowDialogPreview, setIsShowDialogPreview] = useState(false)
  const [isShowModalDownload, setIsShowModalDownload] = useState(false)
  const [isShowDialogRequestApprove, setIsShowDialogRequestApprove] = useState(false)

  const [isShowModalShare, setIsShowModalShare] = useState(false)
  const [isHindModalShare, setIsHindModalShare] = useState(false)
  const [isEditPurchase, setIsEditPurchase] = useState(false)
  const [imageForDocument, setImageForDocument] = useState({
    url_company_logo: '',
    url_rubber_stamp: '',
    url_signature_file: '',
  })
  const [textPurchaseCondition, setTextPurchaseCondition] = useState('')
  const [debitSummary, setDebitSummary] = useState(0)
  const [creditSummary, setCreditSummary] = useState(0)
  const [currentDocType, setCurrentDocType] = useState('')

  useEffect(() => {
    ;(async () => {
      let url = window.location.pathname
      const myArray = url.split('/')
      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i] == 'edit') {
          setIsEditPurchase(true)
          // let tmpId = myArray[myArray.length - 1]
          let tmpId = searchParams.get('id')
          setCurrentDocType(viewType)
          setCurrentDocId(tmpId)
          dispatch(getSaleDocumentById(tmpId))
        }
        if (myArray[i] == 'create') {
          setIsEditPurchase(false)
          // let tmpDocType = myArray[myArray.length - 1]
          // console.log('tmpDocType: ' + JSON.stringify(tmpDocType, null, 2))
          // setDataCreating({ ...dataCreating, doc_type: viewType })
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (Object.keys(saleDocumentData).length > 0 && isEditPurchase) {
      let tmpData = {
        number_of_copy: saleDocumentData?.number_of_copy,
        company_logo: saleDocumentData?.company_logo,
        rubber_stamp: saleDocumentData?.rubber_stamp,
        condition: saleDocumentData?.condition,

        doc_type: saleDocumentData?.doc_type,
        doc_group: saleDocumentData?.doc_group,
        doc_id: saleDocumentData?.doc_id,
        doc_number: saleDocumentData?.doc_number,
        doc_date: saleDocumentData?.doc_date,
        transport_date: saleDocumentData?.transport_date,
        arrival_date: saleDocumentData?.arrival_date,
        payment_list: saleDocumentData?.payment_list,
        carrier: saleDocumentData?.carrier,
        details: saleDocumentData?.details,
      }

      setDataCreating(tmpData)
    }
    dispatch(getDocumentSettingByCompanyId())
  }, [saleDocumentData, isEditPurchase])

  useEffect(() => {
    if (documentSettingInfo) {
      let tmpImageForDocumentObj = { ...imageForDocument }
      // let tmpDocType = dataCreating?.doc_type

      if (viewType != '') {
        tmpImageForDocumentObj.url_company_logo = documentSettingInfo[viewType]?.company_logo
        tmpImageForDocumentObj.url_rubber_stamp = documentSettingInfo[viewType]?.rubber_stamp
        // tmpImageForDocumentObj.url_signature_file = documentSettingInfo[viewType]?.signature

        let tmpDataObj = { ...dataCreating }
        tmpDataObj.signature_list = documentSettingInfo[viewType]?.signature_list
        tmpDataObj.company_logo = documentSettingInfo[viewType]?.company_logo
        tmpDataObj.rubber_stamp = documentSettingInfo[viewType]?.rubber_stamp
        setDataCreating(tmpDataObj)
      }

      setImageForDocument(tmpImageForDocumentObj)
      setTextPurchaseCondition(documentSettingInfo?.purchase_condition)
    }
  }, [documentSettingInfo])

  useEffect(() => {
    if (dataCreating?.payment_list) {
      let tmpData = { ...dataCreating }

      const viewList = tmpData.payment_list.map((item, index) => ({
        no: index + 1,
        account_number: (
          <TextField
            className={'input-table'}
            placeholder={'account number'}
            onChange={(e) => handleChangePaymentList(e, 'account_number', index)}
            value={item.account_number}
            error={item?.is_error_account_number}
            pattern={'^[0-9.-/]+$'}
          />
        ),
        detail: (
          <TextField
            className={'input-table'}
            placeholder={'Details'}
            onChange={(e) => handleChangePaymentList(e, 'details', index)}
            value={item.details}
            error={item?.is_error_details}
          />
        ),
        debit: (
          <TextField
            className={'input-table'}
            placeholder={'0.00'}
            onChange={(e) => handleChangePaymentList(e, 'debit', index)}
            value={convertIntComma(item?.debit || 0)}
            error={item?.is_error_debit}
            pattern={'^[0-9.-/]+$'}
          />
        ),
        credit: (
          <TextField
            className={'input-table'}
            placeholder={'0.00'}
            onChange={(e) => handleChangePaymentList(e, 'credit', index)}
            value={convertIntComma(item?.credit || 0)}
            error={item?.is_error_credit}
            pattern={'^[0-9.-/]+$'}
          />
        ),
        delete: (
          <div className="btn-delete" onClick={() => handleClickBtnDelete(index)}>
            <IconBin />
          </div>
        ),
      }))

      calculateDebitAndCreditSummary(tmpData.payment_list)
      setBodyTableList(viewList)
    }
  }, [dataCreating])

  const calculateDebitAndCreditSummary = (list) => {
    if (list.length >= 0) {
      let sumDebit = 0
      let sumCredit = 0
      for (let i = 0; i < list.length; i++) {
        sumDebit = sumDebit + list[i].debit
        sumCredit = sumCredit + list[i].credit
      }
      setDebitSummary(sumDebit)
      setCreditSummary(sumCredit)
    }
  }

  const handleChangeData = (e, type) => {
    let tmpObj = { ...dataCreating }
    let tmpValidateObj = { ...validateInputData }

    if (type == 'details') {
      tmpObj.details = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_details = false
      }
    } else if (type == 'doc_number') {
      tmpObj.doc_number = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_doc_number = false
      }
    } else if (type == 'doc_date') {
      tmpObj.doc_date = e
      if (e) {
        tmpValidateObj.is_error_doc_date = false
      }
    } else if (type == 'transport_date') {
      tmpObj.transport_date = e
      if (e) {
        tmpValidateObj.is_error_transport_date = false
      }
    } else if (type == 'arrival_date') {
      tmpObj.arrival_date = e
      if (e) {
        tmpValidateObj.is_error_arrival_date = false
      }
    } else if (type == 'carrier') {
      tmpObj.carrier = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_carrier = false
      }
    }
    console.log('tmpObj handleChangeData: ' + JSON.stringify(tmpObj, null, 2))
    setDataCreating(tmpObj)
    setValidateInputData(tmpValidateObj)
  }
  const handleChangePaymentList = (e, type, index) => {
    let tmpObj = { ...dataCreating }
    if (type == 'account_number') {
      tmpObj.payment_list[index].account_number = e.target.value
      if (e.target.value != '') {
        tmpObj.payment_list[index].is_error_account_number = false
      }
    } else if (type == 'details') {
      tmpObj.payment_list[index].details = e.target.value
      if (e.target.value != '') {
        tmpObj.payment_list[index].is_error_details = false
      }
    } else if (type == 'debit') {
      let tmpString = e.target.value
      let tmpInt = tmpString?.replace(/\D/g, '') || 0
      tmpObj.payment_list[index].debit = parseInt(tmpInt)
      if (e.target.value) {
        tmpObj.payment_list[index].is_error_debit = false
      }
    } else if (type == 'credit') {
      let tmpString = e.target.value
      let tmpInt = tmpString?.replace(/\D/g, '') || 0
      tmpObj.payment_list[index].credit = parseInt(tmpInt)
      if (e.target.value) {
        tmpObj.payment_list[index].is_error_credit = false
      }
    }
    console.log('tmpObj (ProductList): ' + JSON.stringify(tmpObj, null, 2))
    setDataCreating({ ...tmpObj })
  }
  const handleClickBtnDelete = (index) => {
    let tmpBodyDataList = removeArrayItemByIndex(dataCreating?.payment_list, index)
    setDataCreating({ ...dataCreating, payment_list: tmpBodyDataList })
  }
  const handleClickBtnAddList = () => {
    let tmpBodyDataList = [...dataCreating?.payment_list, DEFAULT_BODY_ITEM_DATA]
    setDataCreating({ ...dataCreating, payment_list: tmpBodyDataList })
    setValidateInputData({ ...validateInputData, is_error_payment_list: false })
  }
  const handleClickBtnPreview = () => {
    let isValid = checkInputValidation()
    if (isValid) {
      setIsShowDialogPreview(true)
      setDialogPurchaseType('render_preview')
    }
  }
  const handleClickBtnCreate = async () => {
    let isValid = checkInputValidation()
    if (isValid) {
      if (Object.keys(employee_info).length > 0) {
        let tmpObj = { ...dataCreating }
        tmpObj.creator_id = employee_info.id

        if (isEditPurchase) {
          // Edit Purchase
          let tmpPaymentList = [...tmpObj.payment_list]
          let tmpNewList = []
          for (let i = 0; i < tmpPaymentList.length; i++) {
            let tmpProductItem = tmpPaymentList[i]
            delete tmpProductItem['no']
            delete tmpProductItem['delete']
            delete tmpProductItem['is_error_account_number']
            delete tmpProductItem['is_error_details']
            delete tmpProductItem['is_error_debit']
            delete tmpProductItem['is_error_credit']

            tmpNewList.push(tmpProductItem)
          }
          tmpObj.payment_list = [...tmpNewList]

          delete tmpObj['id']
          delete tmpObj['doc_id']
          delete tmpObj['name']
          delete tmpObj['number_of_copy']
          delete tmpObj['company_logo']
          delete tmpObj['rubber_stamp']
          delete tmpObj['condition']

          const request = { data: tmpObj }
          await dispatch(editPurchaseDoc(currentDocId, request)).then((e) => {
            if (e?.data) {
              if (navigateType == 'external') {
                if (onCallback) {
                  onCallback(e?.data?.id)
                }
              } else {
                navigate('/document/purchases')
              }
            } else {
              alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
            }
          })
        } else {
          ///Create Purchase
          let tmpPaymentList = [...tmpObj.payment_list]
          let tmpNewList = []
          for (let i = 0; i < tmpPaymentList.length; i++) {
            let tmpProductItem = tmpPaymentList[i]

            delete tmpProductItem['no']
            delete tmpProductItem['delete']
            delete tmpProductItem['is_error_account_number']
            delete tmpProductItem['is_error_details']
            delete tmpProductItem['is_error_debit']
            delete tmpProductItem['is_error_credit']

            tmpNewList.push(tmpProductItem)
          }
          tmpObj.payment_list = [...tmpNewList]
          delete tmpObj['id']
          delete tmpObj['number_of_copy']
          delete tmpObj['company_logo']
          delete tmpObj['rubber_stamp']
          delete tmpObj['doc_number']

          const request = { data: tmpObj }
          console.log('request: ' + JSON.stringify(request, null, 2))
          await dispatch(createPurchase(request)).then((e) => {
            if (e?.data) {
              if (navigateType == 'external') {
                if (onCallback) {
                  onCallback(e?.data?.id)
                }
              } else {
                handleGoBack()
              }
            } else {
              alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
            }
          })
        }
      } else {
        setIsShowFailedModal(true)
      }
    } else {
      alert('กรอกข้อมูลให้ครบถ้วน')
    }
  }
  const checkInputValidation = () => {
    let tmpData = { ...dataCreating }
    let tmpValidateData = { ...validateInputData }
    console.log('tmpData Validate: ' + JSON.stringify(tmpData, null, 2))
    console.log('tmpValidateData: ' + JSON.stringify(tmpValidateData, null, 2))
    // CHECK Valid input
    if (tmpData.details != '') {
      tmpValidateData.is_error_details = false
    } else {
      tmpValidateData.is_error_details = true
    }

    // if (tmpData.doc_number != '') {
    //   tmpValidateData.is_error_doc_number = false
    // } else {
    //   tmpValidateData.is_error_doc_number = true
    // }

    if (tmpData.doc_date != '') {
      tmpValidateData.is_error_doc_date = false
    } else {
      tmpValidateData.is_error_doc_date = true
    }

    if (tmpData.transport_date != '') {
      tmpValidateData.is_error_transport_date = false
    } else {
      tmpValidateData.is_error_transport_date = true
    }

    if (tmpData.arrival_date != '') {
      tmpValidateData.is_error_arrival_date = false
    } else {
      tmpValidateData.is_error_arrival_date = true
    }

    if (tmpData.carrier != '') {
      tmpValidateData.is_error_carrier = false
    } else {
      tmpValidateData.is_error_carrier = true
    }

    //validate product list
    if (tmpData.payment_list?.length > 0) {
      let tmpProductList = [...tmpData.payment_list]
      let tmpArrayList = []
      let isErrorProductItem = false
      for (let index = 0; index < tmpProductList.length; index++) {
        let productItemObj = tmpProductList[index]

        if (productItemObj.account_number == '') {
          productItemObj.is_error_account_number = true
          isErrorProductItem = true
        } else {
          productItemObj.is_error_account_number = false
        }

        if (productItemObj.details == '') {
          productItemObj.is_error_details = true
          isErrorProductItem = true
        } else {
          productItemObj.is_error_details = false
        }

        if (productItemObj?.debit >= 0) {
          productItemObj.is_error_debit = false
        } else {
          productItemObj.is_error_debit = true
          isErrorProductItem = true
        }

        if (productItemObj?.credit >= 0) {
          productItemObj.is_error_credit = false
        } else {
          productItemObj.is_error_credit = true
          isErrorProductItem = true
        }

        tmpArrayList.push(productItemObj)
      }

      tmpValidateData.is_error_payment_list = isErrorProductItem
      tmpData.payment_list = [...tmpArrayList]
    } else {
      tmpValidateData.is_error_payment_list = true
    }
    setDataCreating(tmpData)
    setValidateInputData(tmpValidateData)
    for (const [key, value] of Object.entries(tmpValidateData)) {
      if (value) {
        return false
      }
    }
    return true
  }
  const convertNumberToCommaTwodecimal = (myNumber) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
    const tmpTotal = Number(myNumber).toLocaleString('en', options)
    return tmpTotal
  }
  const handleGoBack = () => {
    navigate('/document/purchases')
  }

  const handleClickedOption = async (type) => {
    setDialogPurchaseType(type)

    if (type == 'preview' || type == 'print') {
      setIsShowDialogPreview(true)
    } else if (type == 'download_pdf') {
      setIsShowModalDownload(true)
    } else if (type == 'request_approve') {
      setIsShowDialogRequestApprove(true)
    } else {
      setIsShowModalShare(true)
    }
  }

  const handleClickBtnDownload = () => {
    setIsShowModalDownload(false)
    setIsShowDialogPreview(true)
  }

  const handleClickSubmitRequestApproval = () => {
    setIsShowDialogRequestApprove(false)
  }

  const handleClickedPreview = () => {
    setIsHindModalShare(true)
    setDialogPurchaseType('preview')
    setIsShowDialogPreview(true)
  }

  const handleClickClosePreview = () => {
    setIsShowDialogPreview(false)
    setIsHindModalShare(false)
  }

  const convertIntComma = (val) => {
    if (val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }

  return (
    <Styled isHindModalShare={isHindModalShare}>
      <div className="wrp-header-container">
        <div className="header-text">
          <h2>
            {isEditPurchase ? 'Editing' : 'Creating'} document - {viewType.replace(/_/g, ' ')}
          </h2>
          {isEditPurchase && <p>{saleDocumentData?.doc_id}</p>}
        </div>

        {isEditPurchase && (
          <div className="header-right">
            <DocumentOptionButton onClicked={handleClickedOption} />
          </div>
        )}
      </div>

      <div className="content-container">
        <div className="body">
          <div className="body-top">
            <div className="input-left">
              <div className="wrp-input">
                <p>รายละเอียด สมุดรายวันจ่ายเช็ค</p>
                <InputTextArea
                  className={'input-text-area-address' + (validateInputData?.is_error_details ? ' error' : '')}
                  onChange={(e) => handleChangeData(e, 'details')}
                  placeHolder="กรอกรายละเอียด"
                  resizeDisable
                  initValue={dataCreating?.details}
                />
              </div>
            </div>

            <div className="input-right">
              {/* <div className="wrp-input">
                <p>เลขที่</p>
                <TextField
                  className={'text-field'}
                  onChange={(e) => {
                    handleChangeData(e, 'doc_number')
                  }}
                  value={dataCreating?.doc_number}
                  error={validateInputData.is_error_doc_number}
                />
              </div> */}
              <div className="wrp-input">
                <p>วันที่</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'doc_date')}
                  value={dataCreating?.doc_date}
                  error={validateInputData.is_error_doc_date}
                />
              </div>
              <div className="wrp-input">
                <p>วันที่ขนส่ง</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'transport_date')}
                  value={dataCreating?.transport_date}
                  error={validateInputData.is_error_transport_date}
                />
              </div>
              <div className="wrp-input">
                <p>ถึงวันที่</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'arrival_date')}
                  value={dataCreating?.arrival_date}
                  error={validateInputData.is_error_arrival_date}
                />
              </div>
              <div className="wrp-input">
                <p>ผู้ขนส่ง</p>
                <TextField
                  className={'text-field'}
                  pattern={'^[0-9]*[.,]?[0-9]*$'}
                  onChange={(e) => {
                    handleChangeData(e, 'carrier')
                  }}
                  value={dataCreating?.carrier}
                  error={validateInputData.is_error_carrier}
                />
              </div>
            </div>
          </div>

          <div className="body-bottom">
            <div className={validateInputData?.is_error_payment_list ? 'wrp-table-no-data' : 'wrp-table'}>
              <Table columns={TABLE_HEADER_COLUMN} data={bodyTableList} />
            </div>

            <Button onClick={handleClickBtnAddList}>Add List</Button>

            <div className="wrp-calculate-price">
              <div className="inner-calculate">
                <div className="row">
                  <p>Total Price Debit</p>
                  <p className="price">{convertNumberToCommaTwodecimal(debitSummary)}</p>
                </div>

                <div className="row mg-0">
                  <p>Total Price Credit</p>
                  <p className="price">{convertNumberToCommaTwodecimal(creditSummary)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <ButtonOutline onClick={() => handleGoBack()}>Cancel</ButtonOutline>
        <div className="wrp-btn-right">
          <Button className="btn-preview" onClick={handleClickBtnPreview}>
            Preview
          </Button>
          <Button className="btn-create" onClick={handleClickBtnCreate}>
            {isEditPurchase ? 'Save' : 'Create'}
          </Button>
        </div>
      </div>

      {isShowDialogRequestApprove && (
        <SidePanel isOpen={isShowDialogRequestApprove} onClose={() => setIsShowDialogRequestApprove(false)} width={900}>
          <DocumentRequestApprove
            docType={saleDocumentData?.doc_type}
            onClickNavigationBack={() => setIsShowDialogRequestApprove(false)}
            id={saleDocumentData.id}
            onClickBtnSubmit={() => handleClickSubmitRequestApproval()}
          />
        </SidePanel>
      )}

      {/* preview and print */}
      {isShowDialogPreview && (
        <DialogDocument
          onClose={() => handleClickClosePreview()}
          docType={saleDocumentData?.doc_type || dataCreating?.doc_type}
          previewType={dialogPurchaseType}
          id={saleDocumentData.id}
          onDownloaded={() => setIsShowDialogPreview(false)}
          data={dataCreating}
          linkImage={dataCreating?.signature ? imageForDocument : null}
          textCondition={textPurchaseCondition}
        />
      )}

      {isShowModalDownload && (
        <ModalBox
          className={'modal-download'}
          onClosed={() => setIsShowModalDownload(false)}
          height="200px"
          width="450px"
          title={'Download ' + saleDocumentData?.doc_id}
          body={<div className="body">ใบสั่งซื้อ</div>}
          footer={
            <div className="bottom-modal">
              <ButtonOutline className={'footer-btn btn-cancel'} onClick={() => setIsShowModalDownload(false)}>
                Cancel
              </ButtonOutline>
              <Button className={'footer-btn btn-download'} onClick={handleClickBtnDownload}>
                Download
              </Button>
            </div>
          }
        />
      )}

      {isShowModalShare && (
        <div className="document-share-and-send-email">
          <DocumentShareAndSendEmail
            type={currentDocType}
            data={saleDocumentData}
            onClickPreview={() => handleClickedPreview()}
            onClose={() => setIsShowModalShare(false)}
          />
        </div>
      )}

      {isShowFailedModal && (
        <DialogFail
          open={Boolean(isShowFailedModal)}
          onClose={() => setIsShowFailedModal(false)}
          onSubmit={() => setIsShowFailedModal(false)}
          icon={failIcon}
          title={'You may not fully use this feature, since you are not employee.'}
          nameItem={''}
          description={''}
          textYes="Ok"
        />
      )}
    </Styled>
  )
}

const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .mr-10 {
    margin-right: 10px !important;
  }
  .mg-0 {
    margin: 0 !important;
  }
  .wrp-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 0 20px;
    border-bottom: 1px solid #ddd;
    h2 {
      margin: 0;
      font-size: 36px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    p {
      margin: 0;
      color: var(--Gray-600);
      font-size: 16px;
    }
    .header-text {
      text-transform: capitalize;
    }
  }

  .content-container {
    padding: 20px 0;
    flex: 1;
  }

  .w-100 {
    width: 100% !important;
  }

  .checkbox label {
    margin: 0;
  }

  .body {
    .row {
      display: flex;
      .right {
        padding-left: 24px;
      }
    }

    .wrp-input {
      margin-bottom: 24px;
      /* background-color: red; */

      p {
        margin-top: 0;
        margin-bottom: 6px;
      }
      .text-field,
      .dropdown-select,
      .search-text {
        width: 300px;
      }
      .address {
        height: 134px;
      }
    }
    .error {
      border-color: red !important;
    }
    .body-top {
      display: flex;
      justify-content: space-between;

      .input-left {
        .input-text-area-address {
          font-size: 14px;
          height: 134px;
          border-color: var(--Gray-300);
          border-width: 1px;
          width: 800px;
        }
      }

      .input-right {
        margin-left: 24px;
      }
    }
    //////
    .body-bottom {
      padding: 24px 0;

      .input-top {
        width: fit-content;
      }

      .wrp-table {
        margin-bottom: 26px;
        > div {
          overflow-x: inherit;
        }
        .col-no {
          width: 50px;
        }
        .col-account_number,
        .col-debit,
        .col-credit {
          width: 160px;
        }
        .col-delete {
          width: 50px;
        }
      }

      .wrp-table-no-data {
        margin-bottom: 20px;
        > div {
          overflow-x: inherit;
        }
        thead {
          border-top: 1px solid red;
          border-left: 1px solid red;
          border-right: 1px solid red;
          tr {
            border: none;
          }
        }
        tbody {
          border-left: 1px solid red;
          border-right: 1px solid red;
          border-bottom: 1px solid red;

          tr {
            border: none;
          }
        }
      }
      //table input
      .input-table {
        width: 100%;
      }

      .btn-delete {
        cursor: pointer;
      }

      .wrp-calculate-price {
        margin-top: 31px;
        display: flex;
        justify-content: flex-end;

        .inner-calculate {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 10px;

          .row {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            font-weight: bold;

            p {
              margin: 0;
            }

            .price {
              margin-left: 100px;
            }
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--Gray-200);
    padding: 16px 24px;

    .btn-preview {
      margin-right: 12px;
    }
  }

  //Modal
  .modal-download {
    .bottom-modal {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .btn-cancel {
        width: 48%;
        justify-content: center;
      }
      .btn-download {
        width: 48%;
        justify-content: center;
      }
    }
  }

  .document-share-and-send-email {
    display: ${({ isHindModalShare }) => (isHindModalShare ? 'none' : 'block')};
  }
`
