import { useState } from 'react'
import styled from 'styled-components'

export default function TextAreaLimit({ limit = 255, onValueChange, initValue = '', className }) {
  const [content, setContent] = useState(initValue.slice(0, limit))

  const setFormattedContent = (text) => {
    let tmpText = text.slice(0, limit)

    if (onValueChange) {
      setContent(tmpText)
      onValueChange(tmpText)
    }
  }

  return (
    <Styled className={className}>
      <textarea
        className="inputText"
        rows="5"
        onChange={(event) => setFormattedContent(event.target.value)}
        value={content}
      />
      <p className="limitText">{limit - content.length} characters left</p>
    </Styled>
  )
}
const Styled = styled.div`
  .inputText {
    border-color: var(--Gray-300);
    border-radius: 8px;
    width: 100%;
    resize: none;
    padding: 12px 14px;
    border: 1px solid var(--Gray-300);
    font-family: 'Inter','Noto Sans Thai';
  }

  .limitText {
    margin-top: 6px;
  }
`
