import { useEffect, useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { BsBox } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  getMilestoneListByProjectId,
  getProjectTask,
  getScopeWorkByMilestoneId,
  getProjectListDropdown,
} from '../../../redux/actions/projectTask'
import { getTaskEmployee } from '../../../redux/actions/taskEmployee'
import { selectProjectTask, selectTaskEmployee, selectSummaryTask } from '../../../redux/selectors'
import { getAdminUser } from '../../../utils/common'
import { dlog } from '../../../utils/helper'
import DialogTaskEditor from '../../dialog/DialogTaskEditor'
import DialogTaskPreviewer from '../../dialog/DialogTaskPreviewer'
import SearchText from '../../form/SearchText'
import Button from '../../form/button/Button'
import ButtonOutline from '../../form/button/ButtonOutline'
import { default as DropDownSelect, default as DropdownSelect } from '../../widget/element/DropDownSelect'
import TableMyTask from '../table/TableMyTask'
import { getEmployeeTable } from '../../../redux/actions/summaryTask'

import { useTranslation } from 'react-i18next'
import Date from '../../form/Date'

export default function MyTaskOverView() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const user = getAdminUser()
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { taskEmployeeList } = useSelector(selectTaskEmployee)
  const { employeeListTable } = useSelector(selectSummaryTask)
  const { projectList, milestoneScopeList, taskTypeDataList, projectListDropdown } = useSelector(selectProjectTask)
  const [taskId, setTaskId] = useState('')
  const [currentTaskType, setCurrentTaskType] = useState('')
  const [isDataChange, setIsDataChange] = useState(false)

  //list

  const [searchTable, setSearchTable] = useState({
    // owner: user?.id,
    owner: employee_info?.id || -99,
    search: '',
    projectSelected: '',
    milestoneSelected: '',
    scopeOfWorkSelected: '',
    statusSelected: '',
    createBySelected: '',
    assignToSelected: '',
    dueDateSelected: ''
  })

  const pagination = {
    page: 1,
    pageSize: 1000,
    pageCount: 0,
    total: 0,
  }

  const statusList = [
    {
      text: 'All Status',
      value: -1,
    },
    {
      text: 'Open',
      value: 'Open',
    },
    {
      text: 'Working',
      value: 'Doing',
    },
    {
      text: 'Complete',
      value: 'Complete',
    },
  ]

  //modal
  const [isShowNewTaskModal, setIsShowNewTaskModal] = useState(false)
  const [isShowPreviewDialog, setIsShowPreviewDialog] = useState(false)

  const [projectListState, setProjectListState] = useState([])
  const [milestoneList, setMilestoneList] = useState([])
  const [scopeOfWorkList, setScopeOfWorkList] = useState([])
  const [createdByList, setCreatedByList] = useState([])

  const [isGetProjectTask, setIsGetProjectTask] = useState(false)
  const [isGetEmployee, setIsGetEmployee] = useState(false)
  const [isSetEmployeeCreateBy, setIsSetEmployeeCreateBy] = useState(false)

  const [totalSummaryData, setTotalSummaryData] = useState(false)
  const [currentPreviewType, setCurrentPreviewType] = useState('')

  useEffect(() => {(
    async () => {
      if (!isGetProjectTask) {
        await dispatch(getProjectListDropdown())
        setIsGetProjectTask(true)
      }
    })()
  }, [])

  useEffect(() => {
    if (!isGetEmployee) {
      // dispatch(getTaskEmployee()) // แบบเก่า
      
      const pagination = {
        page: 1,
        pageSize: 1000,
      }
      let filters = null
      dispatch(getEmployeeTable(filters, pagination))
      setIsGetEmployee(true)
    }
  }, [dispatch])

  useEffect(() => {
    if (projectListDropdown?.length >= 0) {
      let tmpText = "All projects"
      if(projectListDropdown?.length == 0) {
        tmpText = "No project"
      }
      let tmpList = [{ text: tmpText, value: -1 }]
      for (let i = 0; i < projectListDropdown.length; i++) {
        let tmpObj = {
          text: projectListDropdown[i].name,
          value: projectListDropdown[i].id,
        }
        tmpList.push(tmpObj)
      }
      setProjectListState([...tmpList])
    }
  }, [projectListDropdown])

  useEffect(() => {
    let tmpList = [{ text: 'All milestones', value: -1 }]
    if (milestoneScopeList?.length > 0) {
      for (let i = 0; i < milestoneScopeList.length; i++) {
        // let tmpTaskScope = milestoneScopeList[i]?.milestone // เก่าแบบ getMilestoneScopeByProjectId
        let tmpTaskScope = milestoneScopeList[i]
        let tmpObj = {
          text: tmpTaskScope?.name,
          value: tmpTaskScope?.id,
        }
        tmpList.push(tmpObj)
      }
    }
    setMilestoneList([...tmpList])
  }, [milestoneScopeList])

  useEffect(() => {
    let tmpList = [{ text: 'All scope of works', value: -1 }]
    if (taskTypeDataList?.length > 0) {
      for (let i = 0; i < taskTypeDataList.length; i++) {
        let tmpObj = {
          text: taskTypeDataList[i]?.task_scope?.name,
          value: taskTypeDataList[i]?.id,
        }
        tmpList.push(tmpObj)
      }
    }
    setScopeOfWorkList([...tmpList])
  }, [taskTypeDataList])

  useEffect(() => {
    //taskEmployeeList
    if (employeeListTable?.length > 0 && !isSetEmployeeCreateBy) {
      let tmpList = [{ text: 'All Assign to', value: -1 }]
      for (let i = 0; i < employeeListTable.length; i++) {
        let tmpObj = {
          text: employeeListTable[i].first_name + ' ' + employeeListTable[i].last_name,
          value: employeeListTable[i].id,
        }
        tmpList.push(tmpObj)
      }
      setCreatedByList([...tmpList])
      setIsSetEmployeeCreateBy(true)
    }
  }, [employeeListTable, isSetEmployeeCreateBy])

  const handleDropdownSelected = (value, type) => {
    if (type == 'project') {
      setSearchTable({ ...searchTable, projectSelected: value, milestoneSelected: '', scopeOfWorkSelected: '' })
      if (value >= 0) {
        // dispatch(getMilestoneScopeByProjectId(value)) // แบบเก่า
        dispatch(getMilestoneListByProjectId(value))
      }
      setMilestoneList([{ text: 'All milestones', value: -1 }])
      setScopeOfWorkList([{ text: 'All scope of works', value: -1 }])
    } else if (type == 'milestone') {
      setSearchTable({ ...searchTable, milestoneSelected: value, scopeOfWorkSelected: '' })
      if (value >= 0) {
        // dispatch(getTaskTypeByTaskScope(value)) // แบบเก่า
        dispatch(getScopeWorkByMilestoneId(value))
      }
      setScopeOfWorkList([{ text: 'All scope of works', value: -1 }])
    } else if (type == 'scope_of_work') {
      setSearchTable({ ...searchTable, scopeOfWorkSelected: value })
    } else if (type == 'status') {
      setSearchTable({ ...searchTable, statusSelected: value })
    // } else if (type == 'created_by') {
    //   setSearchTable({ ...searchTable, createBySelected: value }) 
    } else if (type == 'assign_to') {
      setSearchTable({ ...searchTable, assignToSelected: value })
    } else if (type == 'due_date') {
      setSearchTable({ ...searchTable, dueDateSelected: value })
    }
    setIsDataChange(!isDataChange)
  }

  const handleClickedTask = (taskData) => {
    setTaskId(taskData.id_ref)
    if (taskData.task_type == 'routine') {
      // TASK_ROUTINE
      setCurrentPreviewType('task_routine')
    } else {
      // TASK_MILESTONE
      setCurrentPreviewType('task_milestone')
    }
    setIsShowPreviewDialog(true)
  }

  const handleDataChange = async () => {
    setIsDataChange(!isDataChange)
    setIsShowNewTaskModal(false)
    setIsShowPreviewDialog(false)
    await dispatch(getProjectTask(pagination))
  }

  const handleClickBtnNewTask = () => {
    setCurrentTaskType('new_task')
    setIsShowNewTaskModal(true)
  }

  const handleSetSummaryData = (data) => {
    setTotalSummaryData(data)
  }

  const handleClickedClearAll = () => {
    let tmpObj = {
      // owner: user?.id,
      owner: employee_info?.id || -99,
      search: '',
      projectSelected: '',
      milestoneSelected: '',
      scopeOfWorkSelected: '',
      statusSelected: '',
      createBySelected: '',
      assignToSelected: '',
      dueDateSelected: ''
    }
    setSearchTable(tmpObj)
    setIsDataChange(!isDataChange)
  }

  return (
    <Styled>
      <div className="task-report-container">
        <div className="task-box">
          <div className="task-box-row">
            <div className="left">
              <div className="card">
                <BsBox className="card-icon" />
              </div>
              <span className="task-box-text">{t('my_task')}</span>
            </div>
            <div className="right">
              <span className="icon-task-rigth">
                <BiDotsVerticalRounded className="icon" />
              </span>
            </div>
          </div>
          <div className="amount-task">{totalSummaryData?.total_not_complete || '-'}</div>
        </div>

        <div className="task-box box-center">
          <div className="task-box-row">
            <div className="left">
              <div className="card">
                <BsBox className="card-icon" />
              </div>
              <span className="task-box-text">{t('completed_task')}</span>
            </div>
            <div className="right">
              <span className="icon-task-rigth">
                <BiDotsVerticalRounded className="icon" />
              </span>
            </div>
          </div>
          <div className="amount-task">{totalSummaryData?.total_complete || '-'}</div>
        </div>

        <div className="task-box">
          <div className="task-box-row">
            <div className="left">
              <div className="card">
                <BsBox className="card-icon" />
              </div>
              <span className="task-box-text">{t('total_task')}</span>
            </div>
            <div className="right">
              <span className="icon-task-rigth">
                <BiDotsVerticalRounded className="icon" />
              </span>
            </div>
          </div>
          <div className="amount-task">{totalSummaryData?.total_all || '-'}</div>
        </div>
      </div>

      <div className="top-table">
        <div className="left">
          <div className="title">{t('my_task')}</div>
          <div>{t('stay_on_top')}</div>
        </div>
        <div className="right">
          <Button
            className="btn-new-task"
            onClick={() => {
              handleClickBtnNewTask()
            }}
          >
            New Task
          </Button>
        </div>
      </div>

      <div className="wrp-table">
        <div className="filter-header">
          <div className="wrp-filter-menu">
            <div className="top">
              <SearchText
                className="search-text"
                placeholder={t('search_task_or_keyword')}
                value={searchTable.search}
                onChange={(e) => setSearchTable({ ...searchTable, search: e.target.value })}
                onSubmit={(e) => setIsDataChange(!isDataChange)}
                onClear={() => {
                  setSearchTable({ ...searchTable, search: '' })
                  setIsDataChange(!isDataChange)
                }}
              ></SearchText>

              <ButtonOutline className="btn-clear-all" onClick={() => handleClickedClearAll()}>
                {t('clear_all')}
              </ButtonOutline>
            </div>

            <div className="bottom">
              <div className="wrp-dropdown">
                <div className="title-dropdown">{t('project')}</div>
                <DropDownSelect
                  className="dropdown"
                  dataList={projectListState}
                  initValue={searchTable.projectSelected}
                  onChange={(e) => handleDropdownSelected(e, 'project')}
                  placeHolder={t('project')}
                />
              </div>
              <div className="wrp-dropdown">
                <div className="title-dropdown">{t('miestone')}</div>
                <DropDownSelect
                  className="dropdown"
                  dataList={milestoneList}
                  initValue={searchTable.milestoneSelected}
                  onChange={(e) => handleDropdownSelected(e, 'milestone')}
                  placeHolder={t('miestone')}
                  isDisabled={searchTable.projectSelected ? false : true}
                />
              </div>

              <div className="wrp-dropdown">
                <div className="title-dropdown">{t('scope_of_work')}</div>
                <DropDownSelect
                  className="dropdown"
                  dataList={scopeOfWorkList}
                  initValue={searchTable.scopeOfWorkSelected}
                  onChange={(e) => handleDropdownSelected(e, 'scope_of_work')}
                  placeHolder={t('scope_of_work')}
                  isDisabled={searchTable.milestoneSelected ? false : true}
                />
              </div>

              <div className="wrp-dropdown">
                <div className="title-dropdown">{t('status')}</div>
                <DropdownSelect
                  className="dropdown"
                  dataList={statusList}
                  initValue={searchTable.statusSelected}
                  onChange={(e) => handleDropdownSelected(e, 'status')}
                  placeHolder={t('status')}
                />
              </div>
              {/* <div className="wrp-dropdown last">
                <div className="title-dropdown">{t('created_by')}</div>
                <DropdownSelect
                  className="dropdown"
                  dataList={createdByList}
                  initValue={searchTable.createBySelected}
                  onChange={(e) => handleDropdownSelected(e, 'created_by')}
                  placeHolder={t('created_by')}
                />
              </div> */}
              <div className="wrp-dropdown">
                <div className="title-dropdown">{t('assign_to')}</div>
                <DropdownSelect
                  className="dropdown"
                  dataList={createdByList}
                  initValue={searchTable.assignToSelected}
                  onChange={(e) => handleDropdownSelected(e, 'assign_to')}
                  placeHolder={t('assign_to')}
                />
              </div>
              <div  className="wrp-dropdown last">
                <div className="title-dropdown">{t('due_date')}</div>
                <Date
                  className={'input-calendar'}
                  value={searchTable.dueDateSelected}
                  onChange={(e) => handleDropdownSelected(e, 'due_date')}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="wrp-paginate-table">
          <TableMyTask
            isUpdate={isDataChange}
            filter={searchTable}
            onClickTableItem={(e) => handleClickedTask(e)}
            onTotalSummaryData={(e) => handleSetSummaryData(e)}
          />
        </div>
      </div>

      {isShowPreviewDialog && (
        <DialogTaskPreviewer
          taskId={taskId}
          open={isShowPreviewDialog}
          onClose={() => setIsShowPreviewDialog(false)}
          onDataChange={() => handleDataChange()}
          previewType={currentPreviewType}
          onEditTask={() => {
            setCurrentTaskType('edit_task')
            setIsShowPreviewDialog(false)
            setIsShowNewTaskModal(true)
          }}
        />
      )}

      {isShowNewTaskModal && (
        <DialogTaskEditor
          taskId={currentTaskType == 'edit_task' && taskId}
          type={currentTaskType}
          open={isShowNewTaskModal}
          onClose={() => {setIsDataChange(!isDataChange), setIsShowNewTaskModal(false)}}
          onSuccess={() => handleDataChange()}
        />
      )}
    </Styled>
  )
}

const Styled = styled.div`
  .task-report-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 32px 0;
  }

  .task-box {
    border: 1px solid black;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
  }

  .box-center {
    margin: 0 24px;
  }

  .task-box .amount-tasks {
    font-weight: bold;
    text-align: center;
    color: black;
    font-size: 20px;
  }
  .first-box {
    margin-left: 0;
  }
  .last-box {
    margin-right: 0;
  }

  .wrp-table {
    border: 1px solid var(--Gray-300);
    border-radius: 12px;
  }

  .filter-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .wrp-filter-menu {
      position: relative;
      width: 100%;
      /* display: flex; */
      align-items: center;
      flex-wrap: wrap;

      .top {
        width: 50%;
        display: flex;
        align-items: center;

        .search-text {
          /* min-width: 156px; */
          flex: 1;
          margin: 0 !important;
        }
        .search-text .prefix {
          margin-left: 10px;
          margin-right: 0;
        }

        .btn-clear-all {
          margin-left: 18px;
        }
      }
      .bottom {
        display: flex;
        width: 100%;
        margin-top: 12px;
        flex-direction: row;
        flex-wrap: wrap;

        .wrp-dropdown {
          flex: 1;
          padding-right: 12px;

          .title-dropdown {
            margin-bottom: 6px;
          }
          .dropdown {
            width: 100%;
          }
        }
        .last {
          padding: 0;
        }
      }
    }

    .wrp-filter-btn {
      position: relative;
    }

    .dialog-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 300px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);
      max-height: 300px;

      .wrp-person-list {
        position: relative;
      }
      .person-list {
        height: 220px;
        overflow-y: scroll;
      }

      .person-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 4px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
        }

        .img-profile {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          background-color: var(--Gray-200);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .person-item:hover {
        background-color: var(--Gray-50);
      }
    }

    .filter-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 500px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);

      .wrp-filter-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clear-btn {
          padding: 4px 6px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        .clear-btn:hover {
          background: var(--Primary-25);
        }
      }
      .wrp-filter-option {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .title-option {
          font-weight: 600;
          margin-bottom: 6px;
        }
        .option-item-style {
          border: 1px solid var(--Gray-300);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Gray-50);
        }
        .option-item-style:hover {
          background-color: var(--Gray-100);
        }
        .option-item-active {
          border: 1px solid var(--Primary-600);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Primary-600);
          color: #fff;
        }
      }
    }
    .btn-new-task {
      width: max-content;
    }
  }

  .table-wrapper {
    margin-top: 24px;
    overflow-x: hidden;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .wrp-paginate-table {
    margin: 16px 0 0 0;
  }

  .dropdown {
    min-width: 156px;
  }

  //side panel
  .wrp-navigation {
    background-color: red;
    display: flex;
    flex-direction: row;
  }

  .task-box {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    padding: 20px;
    /* margin-bottom: 20px; */

    .icon-task-rigth {
      text-align: right;
    }

    .task-box-row {
      display: flex;
      justify-content: space-between;

      .left {
        display: flex;
      }
    }

    .task-box-text {
      font-size: 16px;
      font-weight: bold;
      color: #000;
      margin-left: 15px;
      margin-top: 10px;
    }

    .amount-task {
      margin-top: 10px;
      font-size: 32px;
      font-weight: bold;
      color: #000;
    }

    .card {
      width: 50px;
      height: 50px;
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.1);
      /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .card-icon {
      width: 50%;
      height: 50%;
      color: rgb(158, 119, 237);
    }
  }

  .top-table {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .title {
      font-weight: 600;
    }
  }
`
