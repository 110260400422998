import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import SidePanel from '../../../components/common/SidePanel'
import DialogFail from '../../../components/dialog/DialogFail'
import Date from '../../../components/form/Date'
import TextField from '../../../components/form/TextField'
import Button from '../../../components/form/button/Button'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import DocumentRequestApprove from '../../../components/widget/combo/DocumentRequestApprove'
import DocumentShareAndSendEmail from '../../../components/widget/combo/DoucumentShareAndSendEmail'
import DocumentOptionButton from '../../../components/widget/element/DocumentOptionButton'
import ModalBox from '../../../components/widget/element/ModalBox'
import {
  createPurchase,
  editPurchaseDoc,
  getDocumentSettingByCompanyId,
  getSaleDocumentById,
} from '../../../redux/actions/document'

import SearchSuggestSeller from 'components/widget/combo/SearchSuggestSeller'
import SearchSuggestSellerEmployee from 'components/widget/combo/SearchSuggestSellerEmployee'
import InputTextArea from 'components/widget/element/InputTextArea'
import DialogDocument from 'pages/document/DialogDocument'
import Table from '../../../components/common/Table'
import IconBin from '../../../components/widget/icon/IconBin'
import { selectDocument } from '../../../redux/selectors'
import { removeArrayItemByIndex } from '../../../utils/helper'

export default function CreatePurchaseRequest({ onCallback }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const viewType = searchParams.get('type')
  const navigateType = searchParams.get('navigate')
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { saleDocumentData, documentSettingInfo } = useSelector(selectDocument)

  const TABLE_HEADER_COLUMN = [
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Product Name / Details',
      accessor: 'product_name',
      disableSortBy: false,
    },
    {
      Header: 'จำนวน',
      accessor: 'quantity',
      disableSortBy: false,
    },
    {
      Header: 'ราคาต่อหน่วย',
      accessor: 'price',
      disableSortBy: false,
    },
    {
      Header: 'Total Price',
      accessor: 'total',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'delete',
      disableSortBy: false,
    },
  ]

  const DEFAULT_BODY_ITEM_DATA = {
    no: '',
    product_name: '',
    price: 0,
    quantity: 0,
    total: 0,
    delete: false,

    //validate
    is_error_product_name: false,
    is_error_price: false,
    is_error_quantity: false,
  }

  const [dataCreating, setDataCreating] = useState({
    id: '',
    seller_id: '',
    seller_name: '',
    address: '',
    seller_phone: '',
    seller_fax: '',

    product_list: [],
    company_id: company_info?.id,
    doc_type: viewType,
    details: '',
    doc_date: '',
    delivery_date: '',
    delivery_location: '',
    purchaser_employee_id: '',
    job_date: '',

    number_of_copy: 1,
    company_logo: '',
    rubber_stamp: '',
    purchaser_name: '',
    is_external: navigateType == 'external' ? true : false,
  })

  const [bodyTableList, setBodyTableList] = useState([])
  const [validateInputData, setValidateInputData] = useState({
    is_error_seller_id: false,
    is_error_seller_name: false,
    is_error_address: false,
    is_error_seller_fax: false,
    is_error_seller_phone: false,

    is_error_details: false,
    is_error_doc_date: false,
    is_error_delivery_date: false,
    is_error_delivery_location: false,
    is_error_purchaser_employee_id: false,
    is_error_job_date: false,
    is_error_product_list: false,
  })
  const [isShowFailedModal, setIsShowFailedModal] = useState(false)
  const [currentDocId, setCurrentDocId] = useState('')

  const [dialogPurchaseType, setDialogPurchaseType] = useState('')
  const [isShowDialogPreview, setIsShowDialogPreview] = useState(false)
  const [isShowModalDownload, setIsShowModalDownload] = useState(false)
  const [isShowDialogRequestApprove, setIsShowDialogRequestApprove] = useState(false)

  const [isShowModalShare, setIsShowModalShare] = useState(false)
  const [isHindModalShare, setIsHindModalShare] = useState(false)
  const [isEditPurchase, setIsEditPurchase] = useState(false)
  const [imageForDocument, setImageForDocument] = useState({
    url_company_logo: '',
    url_rubber_stamp: '',
    url_signature_file: '',
  })
  const [textPurchaseCondition, setTextPurchaseCondition] = useState('')
  // const [debitSummary, setDebitSummary] = useState(0)
  // const [creditSummary, setCreditSummary] = useState(0)
  const [currentDocType, setCurrentDocType] = useState('')
  const [isErrorSellerId, setIsErrorSellerId] = useState(false)
  const [isErrorSellerName, setIsErrorSellerName] = useState(false)
  const [isHasSellerId, setIsHasSellerId] = useState(false)

  useEffect(() => {
    ;(async () => {
      let url = window.location.pathname
      const myArray = url.split('/')
      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i] == 'edit') {
          setIsEditPurchase(true)
          // let tmpId = myArray[myArray.length - 1]
          let tmpId = searchParams.get('id')
          setCurrentDocType(viewType)
          setCurrentDocId(tmpId)
          dispatch(getSaleDocumentById(tmpId))
        }
        if (myArray[i] == 'create') {
          setIsEditPurchase(false)
          // let tmpDocType = myArray[myArray.length - 1]
          // setDataCreating({ ...dataCreating, doc_type: viewType })
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (Object.keys(saleDocumentData).length > 0 && isEditPurchase) {
      console.log('saleDocumentData: ' + JSON.stringify(saleDocumentData, null, 2))

      let tmpData = {
        number_of_copy: saleDocumentData?.number_of_copy,
        company_logo: saleDocumentData?.company_logo,
        rubber_stamp: saleDocumentData?.rubber_stamp,
        condition: saleDocumentData?.condition,

        id: saleDocumentData?.seller?.id,
        seller_id: saleDocumentData?.seller?.id,
        seller_name: saleDocumentData?.seller?.name,
        address: saleDocumentData?.seller?.address,
        seller_phone: saleDocumentData?.seller?.phone,
        seller_fax: saleDocumentData?.seller?.fax,

        doc_type: saleDocumentData?.doc_type,
        doc_group: saleDocumentData?.doc_group,
        doc_id: saleDocumentData?.doc_id,
        doc_date: saleDocumentData?.doc_date,
        details: saleDocumentData?.details,
        product_list: saleDocumentData?.product_list,

        delivery_date: saleDocumentData?.delivery_date,
        delivery_location: saleDocumentData?.delivery_location,
        purchaser_employee_id: saleDocumentData?.purchaser_employee_id,
        purchaser_name: saleDocumentData?.purchaser_name,
        job_date: saleDocumentData?.job_date,
      }

      setDataCreating(tmpData)
    }
    dispatch(getDocumentSettingByCompanyId())
  }, [saleDocumentData, isEditPurchase])

  useEffect(() => {
    if (documentSettingInfo) {
      let tmpImageForDocumentObj = { ...imageForDocument }
      // let tmpDocType = dataCreating?.doc_type

      if (viewType != '') {
        tmpImageForDocumentObj.url_company_logo = documentSettingInfo[viewType]?.company_logo
        tmpImageForDocumentObj.url_rubber_stamp = documentSettingInfo[viewType]?.rubber_stamp
        // tmpImageForDocumentObj.url_signature_file = documentSettingInfo[viewType]?.signature

        let tmpDataObj = { ...dataCreating }
        tmpDataObj.signature_list = documentSettingInfo[viewType]?.signature_list
        tmpDataObj.company_logo = documentSettingInfo[viewType]?.company_logo
        tmpDataObj.rubber_stamp = documentSettingInfo[viewType]?.rubber_stamp
        setDataCreating(tmpDataObj)
      }
      setImageForDocument(tmpImageForDocumentObj)
      setTextPurchaseCondition(documentSettingInfo?.purchase_condition)
    }
  }, [documentSettingInfo])

  useEffect(() => {
    if (dataCreating?.product_list) {
      let tmpData = { ...dataCreating }

      const viewList = tmpData.product_list.map((item, index) => ({
        no: index + 1,
        product_name: (
          <TextField
            className={'input-table'}
            placeholder={'Details'}
            onChange={(e) => handleChangePaymentList(e, 'product_name', index)}
            value={item.product_name}
            error={item?.is_error_product_name}
          />
        ),
        quantity: (
          <TextField
            className={'input-table'}
            placeholder={'0'}
            onChange={(e) => handleChangePaymentList(e, 'quantity', index)}
            value={item.quantity}
            error={item?.is_error_quantity}
            pattern={'^[0-9.-/]+$'}
          />
        ),
        price: (
          <TextField
            className={'input-table'}
            placeholder={'0.00'}
            onChange={(e) => handleChangePaymentList(e, 'price', index)}
            value={convertIntComma(item?.price || 0)}
            error={item?.is_error_price}
            pattern={'^[0-9.-/]+$'}
          />
        ),
        total: calculateItemTotalPrice(item.price, item.quantity),
        delete: (
          <div className="btn-delete" onClick={() => handleClickBtnDelete(index)}>
            <IconBin />
          </div>
        ),
      }))

      // calculateDebitAndCreditSummary(tmpData.product_list)
      setBodyTableList(viewList)
    }
  }, [dataCreating])

  const calculateItemTotalPrice = (pricePerUnit, quantity) => {
    let tmpTotal = convertNumberToCommaTwodecimal(pricePerUnit * quantity)
    return tmpTotal
  }

  // const calculateDebitAndCreditSummary = (list) => {
  //   if (list.length >= 0) {
  //     let sumDebit = 0
  //     let sumCredit = 0
  //     for (let i = 0; i < list.length; i++) {
  //       sumDebit = sumDebit + list[i].debit
  //       sumCredit = sumCredit + list[i].credit
  //     }
  //     setDebitSummary(sumDebit)
  //     setCreditSummary(sumCredit)
  //   }
  // }

  const handleSelectSeller = (value) => {
    console.log('handleSelectSeller_value: ' + JSON.stringify(value, null, 2))
    let tmpObj = {
      ...dataCreating,
      seller_name: value?.name,
      seller_phone: value?.phone,
      seller_fax: value?.fax,
      ...value,
    }
    let tmpValidateObj = { ...validateInputData }
    tmpValidateObj.is_error_seller_id = false
    tmpValidateObj.is_error_seller_name = false
    tmpValidateObj.is_error_address = false
    tmpValidateObj.is_error_seller_phone = false
    tmpValidateObj.is_error_seller_fax = false
    setIsErrorSellerName(false)
    setIsErrorSellerId(false)

    setDataCreating(tmpObj)
    setValidateInputData(tmpValidateObj)
    setIsHasSellerId(true)
  }

  const handleChangeData = (e, type) => {
    let tmpObj = { ...dataCreating }
    let tmpValidateObj = { ...validateInputData }

    if (type == 'details') {
      tmpObj.details = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_details = false
      }
    } else if (type == 'doc_date') {
      tmpObj.doc_date = e
      if (e) {
        tmpValidateObj.is_error_doc_date = false
      }
    } else if (type == 'delivery_date') {
      tmpObj.delivery_date = e
      if (e) {
        tmpValidateObj.is_error_delivery_date = false
      }
    } else if (type == 'delivery_location') {
      tmpObj.delivery_location = e.target.value
      if (e) {
        tmpValidateObj.is_error_delivery_location = false
      }
    } else if (type == 'purchaser_employee_id') {
      console.log('purchaser_employee_id: ' + JSON.stringify(e, null, 2))
      tmpObj.purchaser_employee_id = e.id
      tmpObj.purchaser_name = e?.first_name + ' ' + e?.last_name
      if (e) {
        tmpValidateObj.is_error_purchaser_employee_id = false
      }
    } else if (type == 'seller_fax') {
      let tmpString = e.target.value
      tmpObj.seller_fax = tmpString?.replace(/\D/g, '')
      if (e.target.value != '') {
        tmpValidateObj.is_error_seller_fax = false
      }
    } else if (type == 'seller_phone') {
      let tmpString = e.target.value
      tmpObj.seller_phone = tmpString?.replace(/\D/g, '')
      if (e.target.value != '') {
        tmpValidateObj.is_error_seller_phone = false
      }
    } else if (type == 'job_date') {
      tmpObj.job_date = e
      if (e) {
        tmpValidateObj.is_error_job_date = false
      }
    }
    console.log('tmpObj handleChangeData: ' + JSON.stringify(tmpObj, null, 2))
    setDataCreating(tmpObj)
    setValidateInputData(tmpValidateObj)
  }
  const handleChangePaymentList = (e, type, index) => {
    let tmpObj = { ...dataCreating }
    if (type == 'product_name') {
      tmpObj.product_list[index].product_name = e.target.value
      if (e.target.value != '') {
        tmpObj.product_list[index].is_error_product_name = false
      }
      // } else if (type == 'details') {
      //   tmpObj.product_list[index].details = e.target.value
      //   if (e.target.value != '') {
      //     tmpObj.product_list[index].is_error_details = false
      //   }
    } else if (type == 'quantity') {
      let tmpString = e.target.value
      let tmpInt = tmpString?.replace(/\D/g, '') || 0
      tmpObj.product_list[index].quantity = parseInt(tmpInt)
      if (e.target.value) {
        tmpObj.product_list[index].is_error_quantity = false
      }
    } else if (type == 'price') {
      let tmpString = e.target.value
      let tmpInt = tmpString?.replace(/\D/g, '') || 0
      tmpObj.product_list[index].price = parseInt(tmpInt)
      if (e.target.value) {
        tmpObj.product_list[index].is_error_price = false
      }
    }
    console.log('tmpObj (ProductList): ' + JSON.stringify(tmpObj, null, 2))
    setDataCreating({ ...tmpObj })
  }
  const handleClickBtnDelete = (index) => {
    let tmpBodyDataList = removeArrayItemByIndex(dataCreating?.product_list, index)
    setDataCreating({ ...dataCreating, product_list: tmpBodyDataList })
  }
  const handleClickBtnAddList = () => {
    let tmpBodyDataList = [...dataCreating?.product_list, DEFAULT_BODY_ITEM_DATA]
    setDataCreating({ ...dataCreating, product_list: tmpBodyDataList })
    setValidateInputData({ ...validateInputData, is_error_product_list: false })
  }
  const handleClickBtnPreview = () => {
    let isValid = checkInputValidation()
    if (isValid) {
      setIsShowDialogPreview(true)
      setDialogPurchaseType('render_preview')
    }
  }
  const handleClickBtnCreate = async () => {
    let isValid = checkInputValidation()
    if (isValid) {
      if (Object.keys(employee_info).length > 0) {
        let tmpObj = { ...dataCreating }
        tmpObj.creator_id = employee_info.id

        if (isEditPurchase) {
          // Edit Purchase
          let tmpPaymentList = [...tmpObj.product_list]
          let tmpNewList = []
          for (let i = 0; i < tmpPaymentList.length; i++) {
            let tmpProductItem = tmpPaymentList[i]
            delete tmpProductItem['no']
            delete tmpProductItem['delete']
            delete tmpProductItem['is_error_product_name']
            delete tmpProductItem['is_error_quatity']
            delete tmpProductItem['is_error_price']

            tmpNewList.push(tmpProductItem)
          }
          tmpObj.product_list = [...tmpNewList]

          delete tmpObj['id']
          delete tmpObj['doc_id']
          delete tmpObj['name']
          delete tmpObj['number_of_copy']
          delete tmpObj['company_logo']
          delete tmpObj['rubber_stamp']
          delete tmpObj['condition']

          const request = { data: tmpObj }
          await dispatch(editPurchaseDoc(currentDocId, request)).then((e) => {
            if (e?.data) {
              if (navigateType == 'external') {
                if (onCallback) {
                  onCallback(e?.data?.id)
                }
              } else {
                navigate('/document/purchases')
              }
            } else {
              alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
            }
          })
        } else {
          ///Create Purchase
          if (isHasSellerId) {
            tmpObj.seller_id = tmpObj.id
            let tmpPaymentList = [...tmpObj.product_list]
            let tmpNewList = []
            for (let i = 0; i < tmpPaymentList.length; i++) {
              let tmpProductItem = tmpPaymentList[i]

              delete tmpProductItem['no']
              delete tmpProductItem['delete']
              delete tmpProductItem['is_error_product_name']
              delete tmpProductItem['is_error_quatity']
              delete tmpProductItem['is_error_price']

              tmpNewList.push(tmpProductItem)
            }
            tmpObj.product_list = [...tmpNewList]
            delete tmpObj['id']
            delete tmpObj['number_of_copy']
            delete tmpObj['company_logo']
            delete tmpObj['rubber_stamp']

            delete tmpObj['seller_name']
            delete tmpObj['address']
            delete tmpObj['seller_phone']
            delete tmpObj['seller_fax']
            delete tmpObj['phone']
            delete tmpObj['fax']
            delete tmpObj['name']

            const request = { data: tmpObj }
            await dispatch(createPurchase(request)).then((e) => {
              if (e?.data) {
                if (navigateType == 'external') {
                  if (onCallback) {
                    onCallback(e?.data?.id)
                  }
                } else {
                  handleGoBack()
                }
              } else {
                alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
              }
            })
          } else {
            let data = {
              seller_id: textSellerId,
              name: textSellerName,
              address: tmpObj.address,
              company_id: company_info.id,
              phone: tmpObj.seller_phone,
              fax: tmpObj.seller_fax,
            }
            const request = { data }
            dispatch(createSeller(request)).then((e) => {
              if (e?.data) {
                let tmpInfo = { ...e.data }

                tmpObj.seller_id = tmpInfo.id
                tmpObj.seller_name = tmpInfo.name
                tmpObj.address = tmpInfo.address
                // tmpObj.phone = tmpInfo.phone
                // tmpObj.fax = tmpInfo.fax

                let tmpProductList = [...tmpObj.product_list]
                let tmpNewList = []
                for (let i = 0; i < tmpProductList.length; i++) {
                  let tmpProductItem = tmpProductList[i]
                  delete tmpProductItem['no']
                  delete tmpProductItem['delete']
                  delete tmpProductItem['is_error_product_name']
                  delete tmpProductItem['is_error_quatity']
                  delete tmpProductItem['is_error_price']
                  tmpNewList.push(tmpProductItem)
                }
                tmpObj.product_list = [...tmpNewList]
                delete tmpObj['seller_name']
                delete tmpObj['name']
                delete tmpObj['address']
                delete tmpObj['id']

                delete tmpObj['doc_group']
                delete tmpObj['details']
                delete tmpObj['signature_list']
                delete tmpObj['number_of_copy']
                delete tmpObj['company_logo']
                delete tmpObj['rubber_stamp']
                delete tmpObj['condition']

                delete tmpObj['seller_phone']
                delete tmpObj['seller_fax']
                delete tmpObj['phone']
                delete tmpObj['fax']

                let tmpRequest = { data: { ...tmpObj } }
                dispatch(createPurchase(tmpRequest)).then((e) => {
                  if (e?.data) {
                    if (navigateType == 'external') {
                      if (onCallback) {
                        onCallback(e?.data?.id)
                      }
                    } else {
                      handleGoBack()
                    }
                  } else {
                    alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
                  }
                })
              }
            })
          }
        }
      } else {
        setIsShowFailedModal(true)
      }
    } else {
      alert('กรอกข้อมูลให้ครบถ้วน')
    }
  }
  const checkInputValidation = () => {
    let tmpData = { ...dataCreating }
    let tmpValidateData = { ...validateInputData }
    // CHECK Valid input
    if (tmpData.details != '') {
      tmpValidateData.is_error_details = false
    } else {
      tmpValidateData.is_error_details = true
    }

    if (tmpData.doc_date != '') {
      tmpValidateData.is_error_doc_date = false
    } else {
      tmpValidateData.is_error_doc_date = true
    }

    if (tmpData.delivery_date != '') {
      tmpValidateData.is_error_delivery_date = false
    } else {
      tmpValidateData.is_error_delivery_date = true
    }

    if (tmpData.delivery_location != '') {
      tmpValidateData.is_error_delivery_location = false
    } else {
      tmpValidateData.is_error_delivery_location = true
    }

    if (tmpData.purchaser_employee_id != '') {
      tmpValidateData.is_error_purchaser_employee_id = false
    } else {
      tmpValidateData.is_error_purchaser_employee_id = true
    }

    if (tmpData.job_date != '') {
      tmpValidateData.is_error_job_date = false
    } else {
      tmpValidateData.is_error_job_date = true
    }

    //validate product list
    if (tmpData.product_list?.length > 0) {
      let tmpProductList = [...tmpData.product_list]
      let tmpArrayList = []
      let isErrorProductItem = false
      for (let index = 0; index < tmpProductList.length; index++) {
        let productItemObj = tmpProductList[index]

        if (productItemObj.product_name == '') {
          productItemObj.is_error_product_name = true
          isErrorProductItem = true
        } else {
          productItemObj.is_error_product_name = false
        }

        // if (productItemObj.details == '') {
        //   productItemObj.is_error_details = true
        //   isErrorProductItem = true
        // } else {
        //   productItemObj.is_error_details = false
        // }

        if (productItemObj?.quantity >= 0) {
          productItemObj.is_error_quantity = false
        } else {
          productItemObj.is_error_quantity = true
          isErrorProductItem = true
        }

        if (productItemObj?.price >= 0) {
          productItemObj.is_error_price = false
        } else {
          productItemObj.is_error_price = true
          isErrorProductItem = true
        }

        tmpArrayList.push(productItemObj)
      }

      tmpValidateData.is_error_product_list = isErrorProductItem
      tmpData.product_list = [...tmpArrayList]
    } else {
      tmpValidateData.is_error_product_list = true
    }
    setDataCreating(tmpData)
    setValidateInputData(tmpValidateData)
    for (const [key, value] of Object.entries(tmpValidateData)) {
      if (value) {
        return false
      }
    }
    return true
  }
  const convertNumberToCommaTwodecimal = (myNumber) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
    const tmpTotal = Number(myNumber).toLocaleString('en', options)
    return tmpTotal
  }
  const handleGoBack = () => {
    navigate('/document/purchases')
  }

  const handleClickedOption = async (type) => {
    setDialogPurchaseType(type)

    if (type == 'preview' || type == 'print') {
      setIsShowDialogPreview(true)
    } else if (type == 'download_pdf') {
      setIsShowModalDownload(true)
    } else if (type == 'request_approve') {
      setIsShowDialogRequestApprove(true)
    } else {
      setIsShowModalShare(true)
    }
  }

  const handleClickBtnDownload = () => {
    setIsShowModalDownload(false)
    setIsShowDialogPreview(true)
  }

  const handleClickSubmitRequestApproval = () => {
    setIsShowDialogRequestApprove(false)
  }

  const handleClickedPreview = () => {
    setIsHindModalShare(true)
    setDialogPurchaseType('preview')
    setIsShowDialogPreview(true)
  }

  const handleClickClosePreview = () => {
    setIsShowDialogPreview(false)
    setIsHindModalShare(false)
  }

  const convertIntComma = (val) => {
    if (val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }

  return (
    <Styled isHindModalShare={isHindModalShare}>
      <div className="wrp-header-container">
        <div className="header-text">
          <h2>
            {isEditPurchase ? 'Editing' : 'Creating'} document - {viewType.replace(/_/g, ' ')}
          </h2>
          {isEditPurchase && <p>{saleDocumentData?.doc_id}</p>}
        </div>

        {isEditPurchase && (
          <div className="header-right">
            <DocumentOptionButton onClicked={handleClickedOption} />
          </div>
        )}
      </div>

      <div className="content-container">
        <div className="body">
          <div className="body-top">
            <div className="input-left">
              <div className="input-left-top">
                <div className="row">
                  <div className="left">
                    <div className="wrp-input">
                      <p>Seller Id {dataCreating.id}</p>
                      <SearchSuggestSeller
                        placeHolder="Seller id"
                        type={'seller_id'}
                        selectedId={dataCreating.id}
                        onSelectedSuggest={(e) => handleSelectSeller(e, 'seller_id')}
                        onTextChange={(e) => handleTextChange(e, 'seller_id')}
                        isError={isErrorSellerId}
                      />
                    </div>
                    <div className="wrp-input">
                      <p>Seller Name</p>
                      <SearchSuggestSeller
                        placeHolder="Seller name"
                        type={'seller_name'}
                        selectedId={dataCreating.id}
                        onSelectedSuggest={(e) => handleSelectSeller(e, 'seller_name')}
                        onTextChange={(e) => handleTextChange(e, 'seller_name')}
                        isError={isErrorSellerName}
                      />
                    </div>
                    <div className="wrp-input">
                      <p>เบอร์โทรศัพท์</p>
                      <TextField
                        className={'text-field'}
                        pattern={'^[0-9]*[.,]?[0-9]*$'}
                        onChange={(e) => handleChangeData(e, 'seller_phone')}
                        value={dataCreating?.seller_phone}
                        error={validateInputData.is_error_seller_phone}
                      />
                    </div>
                    <div className="wrp-input">
                      <p>FAX</p>
                      <TextField
                        className={'text-field'}
                        pattern={'^[0-9]*[.,]?[0-9]*$'}
                        onChange={(e) => handleChangeData(e, 'seller_fax')}
                        value={dataCreating?.seller_fax}
                        error={validateInputData.is_error_seller_fax}
                      />
                    </div>
                  </div>
                  <div className="right">
                    <div className="wrp-input">
                      <p>Seller Address</p>
                      <InputTextArea
                        className={'input-text-area-address' + (validateInputData?.is_error_address ? ' error' : '')}
                        onChange={(e) => handleChangeData(e, 'address')}
                        placeHolder="Address details"
                        resizeDisable
                        initValue={dataCreating?.address}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="input-right">
              <div className="wrp-input">
                <p>วันที่</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'doc_date')}
                  value={dataCreating?.doc_date}
                  error={validateInputData.is_error_doc_date}
                />
              </div>
              <div className="wrp-input">
                <p>วันที่ต้องการส่งมอบ</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'delivery_date')}
                  value={dataCreating?.delivery_date}
                  error={validateInputData.is_error_delivery_date}
                />
              </div>

              <div className="wrp-input">
                <p>สถานที่ส่งมอบ</p>
                <TextField
                  className={'text-field'}
                  // pattern={'^[0-9]*[.,]?[0-9]*$'}
                  onChange={(e) => {
                    handleChangeData(e, 'delivery_location')
                  }}
                  value={dataCreating?.delivery_location}
                  error={validateInputData.is_error_delivery_location}
                />
              </div>
              <div className="line-border"></div>
              <div className="wrp-input">
                <p>แผนกจัดซื้อ</p>
                <SearchSuggestSellerEmployee
                  placeHolder="Search Purchaser Name"
                  onSelectedSuggest={(e) => handleChangeData(e, 'purchaser_employee_id')}
                  isError={validateInputData.is_error_purchaser_employee_id}
                  isDisabled={false}
                  selectedId={dataCreating?.purchaser_employee_id}
                />
              </div>
              <div className="wrp-input">
                <p>วันที่รับงาน</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'job_date')}
                  value={dataCreating?.job_date}
                  error={validateInputData.is_error_job_date}
                />
              </div>
            </div>
          </div>

          <div className="body-bottom">
            <div className="input-top w-60">
              <div className="wrp-input">
                <p>เรื่องที่ต้องการ</p>
                <TextField
                  className={'text-field w-100'}
                  onChange={(e) => handleChangeData(e, 'details')}
                  value={dataCreating?.details}
                  placeHolder={'Details'}
                />
              </div>
            </div>

            <div className={validateInputData?.is_error_product_list ? 'wrp-table-no-data' : 'wrp-table'}>
              <Table columns={TABLE_HEADER_COLUMN} data={bodyTableList} />
            </div>

            <Button onClick={handleClickBtnAddList}>Add List</Button>

            {/* <div className="wrp-calculate-price">
              <div className="inner-calculate">
                <div className="row">
                  <p>Total Price Debit</p>
                  <p className="price">{convertNumberToCommaTwodecimal(debitSummary)}</p>
                </div>

                <div className="row mg-0">
                  <p>Total Price Credit</p>
                  <p className="price">{convertNumberToCommaTwodecimal(creditSummary)}</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      <div className="bottom">
        <ButtonOutline onClick={() => handleGoBack()}>Cancel</ButtonOutline>
        <div className="wrp-btn-right">
          <Button className="btn-preview" onClick={handleClickBtnPreview}>
            Preview
          </Button>
          <Button className="btn-create" onClick={handleClickBtnCreate}>
            {isEditPurchase ? 'Save' : 'Create'}
          </Button>
        </div>
      </div>

      {isShowDialogRequestApprove && (
        <SidePanel isOpen={isShowDialogRequestApprove} onClose={() => setIsShowDialogRequestApprove(false)} width={900}>
          <DocumentRequestApprove
            docType={saleDocumentData?.doc_type}
            onClickNavigationBack={() => setIsShowDialogRequestApprove(false)}
            id={saleDocumentData.id}
            onClickBtnSubmit={() => handleClickSubmitRequestApproval()}
          />
        </SidePanel>
      )}

      {/* preview and print */}
      {isShowDialogPreview && (
        <DialogDocument
          onClose={() => handleClickClosePreview()}
          docType={saleDocumentData?.doc_type || dataCreating?.doc_type}
          previewType={dialogPurchaseType}
          id={saleDocumentData.id}
          onDownloaded={() => setIsShowDialogPreview(false)}
          data={dataCreating}
          linkImage={dataCreating?.signature ? imageForDocument : null}
          textCondition={textPurchaseCondition}
        />
      )}

      {isShowModalDownload && (
        <ModalBox
          className={'modal-download'}
          onClosed={() => setIsShowModalDownload(false)}
          height="200px"
          width="450px"
          title={'Download ' + saleDocumentData?.doc_id}
          body={<div className="body">ใบขอซื้อ</div>}
          footer={
            <div className="bottom-modal">
              <ButtonOutline className={'footer-btn btn-cancel'} onClick={() => setIsShowModalDownload(false)}>
                Cancel
              </ButtonOutline>
              <Button className={'footer-btn btn-download'} onClick={handleClickBtnDownload}>
                Download
              </Button>
            </div>
          }
        />
      )}

      {isShowModalShare && (
        <div className="document-share-and-send-email">
          <DocumentShareAndSendEmail
            type={currentDocType}
            data={saleDocumentData}
            onClickPreview={() => handleClickedPreview()}
            onClose={() => setIsShowModalShare(false)}
          />
        </div>
      )}

      {isShowFailedModal && (
        <DialogFail
          open={Boolean(isShowFailedModal)}
          onClose={() => setIsShowFailedModal(false)}
          onSubmit={() => setIsShowFailedModal(false)}
          icon={failIcon}
          title={'You may not fully use this feature, since you are not employee.'}
          nameItem={''}
          description={''}
          textYes="Ok"
        />
      )}
    </Styled>
  )
}

const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .mr-10 {
    margin-right: 10px !important;
  }
  .mg-0 {
    margin: 0 !important;
  }
  .wrp-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 0 20px;
    border-bottom: 1px solid #ddd;
    h2 {
      margin: 0;
      font-size: 36px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    p {
      margin: 0;
      color: var(--Gray-600);
      font-size: 16px;
    }
    .header-text {
      text-transform: capitalize;
    }
  }

  .content-container {
    padding: 20px 0;
    flex: 1;
  }

  .w-100 {
    width: 100% !important;
  }

  .w-60 {
    width: 60% !important;
  }

  .checkbox label {
    margin: 0;
  }

  .body {
    .row {
      display: flex;
      .right {
        padding-left: 24px;
      }
    }

    .wrp-input {
      margin-bottom: 24px;
      /* background-color: red; */

      p {
        margin-top: 0;
        margin-bottom: 6px;
      }
      .text-field,
      .dropdown-select,
      .search-text {
        width: 300px;
      }
      .address {
        height: 134px;
      }
    }
    .error {
      border-color: red !important;
    }
    .body-top {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--Gray-200);

      .input-left {
        .input-text-area-address {
          font-size: 14px;
          height: 134px;
          border-color: var(--Gray-300);
          border-width: 1px;
          width: 300px;
        }
      }

      .input-right {
        margin-left: 24px;
      }
    }
    //////
    .body-bottom {
      padding: 24px 0;

      .input-top {
        width: fit-content;
      }

      .wrp-table {
        margin-bottom: 26px;
        > div {
          overflow-x: inherit;
        }
        .col-no {
          width: 50px;
        }
        .col-account_number,
        .col-debit,
        .col-credit {
          width: 160px;
        }
        .col-delete {
          width: 50px;
        }
      }

      .wrp-table-no-data {
        margin-bottom: 20px;
        > div {
          overflow-x: inherit;
        }
        thead {
          border-top: 1px solid red;
          border-left: 1px solid red;
          border-right: 1px solid red;
          tr {
            border: none;
          }
        }
        tbody {
          border-left: 1px solid red;
          border-right: 1px solid red;
          border-bottom: 1px solid red;

          tr {
            border: none;
          }
        }
      }
      //table input
      .input-table {
        width: 100%;
      }

      .btn-delete {
        cursor: pointer;
      }

      .wrp-calculate-price {
        margin-top: 31px;
        display: flex;
        justify-content: flex-end;

        .inner-calculate {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 10px;

          .row {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            font-weight: bold;

            p {
              margin: 0;
            }

            .price {
              margin-left: 100px;
            }
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--Gray-200);
    padding: 16px 24px;

    .btn-preview {
      margin-right: 12px;
    }
  }

  //Modal
  .modal-download {
    .bottom-modal {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .btn-cancel {
        width: 48%;
        justify-content: center;
      }
      .btn-download {
        width: 48%;
        justify-content: center;
      }
    }
  }

  .document-share-and-send-email {
    display: ${({ isHindModalShare }) => (isHindModalShare ? 'none' : 'block')};
  }
  .line-border {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }
`
