import { useTranslation } from 'react-i18next'

export default function (type = 'delivery') {
  const { t } = useTranslation()

  switch (type) {
    case 'delivery':
      return [
        {
          Header: t('no'),
          accessor: 'no',
          disableSortBy: false,
        },
        {
          Header: t('file'),
          accessor: 'name',
          disableSortBy: false,
        },
        {
          Header: t('sop'),
          accessor: 'sp.name',
          disableSortBy: false,
        },
        {
          Header: t('scope_of_work'),
          accessor: 'sw',
          disableSortBy: false,
        },
        {
          Header: t('task'),
          accessor: 'task',
          disableSortBy: false,
        },
        {
          Header: t('work'),
          accessor: 'delivery.name',
          disableSortBy: false,
        },
        {
          Header: t('uploaded_by'),
          accessor: 'created_by',
          disableSortBy: false,
        },
        {
          Header: t('created_date'),
          accessor: 'createdAt',
          disableSortBy: false,
        },
        {
          accessor: 'actions',
          disableSortBy: false,
        },
      ]

    case 'asset':
      return [
        {
          Header: t('no'),
          accessor: 'no',
          disableSortBy: false,
        },
        {
          Header: t('file'),
          accessor: 'name',
          disableSortBy: false,
        },
        {
          Header: t('sop'),
          accessor: 'sp.name',
          disableSortBy: false,
        },
        {
          Header: t('scope_of_work'),
          accessor: 'sw',
          disableSortBy: false,
        },
        {
          Header: t('task'),
          accessor: 'task',
          disableSortBy: false,
        },
        {
          Header: t('uploaded_by'),
          accessor: 'created_by',
          disableSortBy: false,
        },
        {
          Header: t('created_date'),
          accessor: 'createdAt',
          disableSortBy: false,
        },
        {
          accessor: 'actions',
          disableSortBy: false,
        },
      ]
  }
}
