import { useState } from 'react'
import styled from 'styled-components'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import DocumentSetting from './document-setting/DocumentSetting'

export default function Settings({}) {
  const DOC_TYPE_ARRAY = [
    {
      title: "Sale Documents",
      docTypeList: [
        {
          name: "Quotation",
          value: "quotation",
          active: true,
        },
        {
          name: "Billing",
          value: "billing",
          active: true,
        },
        {
          name: "Invoice",
          value: "invoice",
          active: true,
        },
        {
          name: "Receipt",
          value: "receipt",
          active: true,
        },
        {
          name: "Debit Note",
          value: "debit_note",
          active: true,
        },
        {
          name: "Pledge Product Certification Request",
          value: "pledge_product_certification_request",
          active: false,
        },
      ]
    },
    {
      title: "Purchase Documents",
      docTypeList: [
        {
          name: "Purchase Order",
          value: "purchase_order",
          active: true,
        },
        {
          name: "Purchase Request",
          value: "purchase_request",
          active: true,
        },
        {
          name: "Cheque Payment Journal",
          value: "cheque_payment_journal",
          active: true,
        },
        {
          name: "Payment Journal",
          value: "payment_journal",
          active: true,
        },
        {
          name: "Petty Cash Voucher",
          value: "petty_cash_voucher",
          active: true,
        },
        {
          name: "Petty Cash Clearance",
          value: "petty_cash_clearance",
          active: false,
        },
        {
          name: "Comparison Schedule",
          value: "comparison_schedule",
          active: true,
        },
      ]
    },
    {
      title: "Transportation Documents",
      docTypeList: [
        {
          name: "Transfer Request",
          value: "transfer_request",
          active: false,
        },
        {
          name: "Delivery Appointment Note",
          value: "delivery_appointment_note",
          active: false,
        },
        {
          name: "Job Delivery Note",
          value: "job_delivery_note",
          active: false,
        },
        {
          name: "Delivery Note",
          value: "delivery_note",
          active: false,
        },
      ]
    },
    {
      title: "Accounting Documents",
      docTypeList: [
        {
          name: "Credit Adjustment Document",
          value: "credit_adjustment_document",
          active: false,
        },
        {
          name: "Receipt Voucher",
          value: "receipt_voucher",
          active: false,
        },
        {
          name: "Credit Note/Goods Receipt",
          value: "credit_note_goods_receipt",
          active: false,
        },
        {
          name: "Tax Invoice",
          value: "tax_invoice",
          active: false,
        },
        {
          name: "Tax Withholding Certificate",
          value: "tax_withholding_certificate",
          active: false,
        },
      ]
    },
    {
      title: "Warehouse Documents",
      docTypeList: [
        {
          name: "Return Goods",
          value: "return_goods",
          active: false,
        },
        {
          name: "Goods Return Note",
          value: "goods_return_note",
          active: false,
        },
        {
          name: "Goods Release Note",
          value: "goods_release_note",
          active: false,
        },
        {
          name: "Payment Approval Letter",
          value: "payment_approval_letter",
          active: false,
        },
        {
          name: "Goods Withdrawal/Borrowing Slip",
          value: "goods_withdrawal_borrowing_slip",
          active: false,
        },
      ]
    },
    {
      title: "Internal Documents",
      docTypeList: [
        {
          name: "Internal Memo",
          value: "internal_memo",
          active: false,
        },
        {
          name: "Travel Expense Claim",
          value: "travel_expense_claim",
          active: false,
        },
        {
          name: "Per Diem Claim",
          value: "per_diem_claim",
          active: false,
        },
        {
          name: "Internal Note",
          value: "internal_note",
          active: false,
        },
      ]
    },
  ]

  const [currentPage, setCurrentPage] = useState('setting')
  const [currentDocTypeSelected, setCurrentDocTypeSelected] = useState('')
  

  const handleClickBtn = (type) => {
    if (type) {
      setCurrentDocTypeSelected(type)
      setCurrentPage('document_setting_detail')
    }
  }

  return (
    <Styled className={'wrp-styled-setting'}>
      <div className="wrp-page-content">
        {currentPage == 'setting' && (
          <div className="wrp-setting">
            <div className="header">Document Setting</div>
            <div className='wrp-doc-type-list'>
              {DOC_TYPE_ARRAY?.map((item, index) => (
                <div key={"doc-title-"+index} className='item-card'>
                  <div className="title-sale-document">{item?.title}</div>
                  <div className="wrp-btn-sale-document">
                    {item?.docTypeList?.map((docType, docTypeIndex) => (
                    <ButtonOutline key={"doc-type-"+docTypeIndex} className={'btn-document'} disabled={!docType?.active} onClick={() => handleClickBtn(docType.value)}>
                      {docType.name}
                    </ButtonOutline>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        
        {currentPage == 'document_setting_detail' && (
          <DocumentSetting type={currentDocTypeSelected} onClickedBack={() => setCurrentPage('setting')} />
        )}
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  .wrp-page-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .wrp-setting {

    .header {
      font-size: 30px;
      font-weight: 600;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--Gray-200);
      margin-bottom: 24px;
    }

    .wrp-doc-type-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
    .item-card {
      width: 32%;
      border-radius: 8px;
      border: 1px solid var(--Gray-200);
      padding: 10px 20px;
      margin-bottom: 30px;
      box-shadow: 0px 2px 4px 1px rgba(16, 24, 40, 0.1);
    }
    .item-card:hover {
      transform: scale(1.01);

      .title-sale-document {
        color: var(--Primary-500);
      }
    }

    .wrp-btn-sale-document,
    .wrp-btn-purchase-document {
      margin: 8px 0px 8px;
      display: flex;
      flex-direction: column;
      width: 100%;

      .btn-document {
        margin-bottom: 4px;
        &.last {
          margin-bottom: 0px;
        }
      }
    }

    .title-sale-document {
      font-weight: bold;
      font-size: 20px;
      color: var(--Gray-700);
      text-align: center;
      margin-bottom: 20px;
    }

  }
`
