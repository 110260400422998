import { useEffect, useState } from 'react'
import styled from 'styled-components'
import SearchSuggestBox from './SearchSuggestBox'
import { useDispatch, useSelector } from 'react-redux'
import { selectSummaryTask, selectProjectTask } from '../../../redux/selectors'
import { getTaskTemplateList } from '../../../redux/actions/projectTask'
import { dlog } from '../../../utils/helper'

export default function SearchSuggestTemplate({ 
  selectedId, 
  placeHolder,
  onSelectedSuggest, 
  isError = false,
  isDisabled = false
}) {
  const dispatch = useDispatch()

  // const { employeeList } = useSelector(selectSummaryTask)
  const { taskTemplateList } = useSelector(selectProjectTask)
  const [dropDownViews, setDropDownView] = useState([])
  const [searchText, setSearchText] = useState('')
  const [selectedName, setSelectedName] = useState('')
  const [isOnBlur, setIsOnBlur] = useState(false)

  useEffect(() => {(async () => {
    // const pagination = {
    //   page: 1,
    //   pageSize: 1000,
    // }
    let filters = {
      searchText: searchText
    };
    // dispatch(getTaskTemplateList(filters, pagination))
    const taskTemplateRes = await dispatch(getTaskTemplateList(filters))
    if(taskTemplateRes.data) {
      let taskTemplateDataList = taskTemplateRes.data
      if (taskTemplateDataList?.length >= 0) {
        let tmpData = []
        let tmpDefault = {
          type: 'item-image',
          value: {
            title: 'Blank Project',
            // deparment: taskTemplateDataList[i].department?.name || '',
            // position: taskTemplateDataList[i].positions?.name || '',
          },
        }
        tmpData.push(tmpDefault)
        if(taskTemplateDataList?.length > 0) {
          for (let i = 0; i < taskTemplateDataList.length; i++) {
            let tmp = {
              type: 'item-image',
              value: {
                title: taskTemplateDataList[i].name || '',
                // deparment: taskTemplateDataList[i].department?.name || '',
                // position: taskTemplateDataList[i].positions?.name || '',
              },
            }
            tmpData = [...tmpData, tmp]
          }
        }
        
        let views = []
        for (let i = 0; i < tmpData.length; i++) {
          if (tmpData[i]?.type == 'header') {
            views.push(<div className={'header'}>{tmpData[i]?.value}</div>)
          } else if (tmpData[i]?.type == 'item-normal' || tmpData[i]?.type == 'item-image') {
            views.push(
              <div className={'item-normal'}>
                <div className="item-texts">
                  {tmpData[i]?.value?.title && <div className="title">{tmpData[i]?.value?.title}</div>}
                  {/* {tmpData[i]?.value?.deparment && <div className="desc">Department : {tmpData[i]?.value?.deparment}</div>}
                  {tmpData[i]?.value?.position && <div className="desc">Position : {tmpData[i]?.value?.position}</div>} */}
                </div>
              </div>
            )
          }
        }
        setDropDownView([...views])
      } else {
        setDropDownView([])
      }
    }
  })()}, [searchText])

  // useEffect(() => {
  //   if (taskTemplateList?.length > 0) {
  //     let tmpData = []
  //     let tmpDefault = {
  //       type: 'item-image',
  //       value: {
  //         title: 'Blank Project',
  //         // deparment: taskTemplateList[i].department?.name || '',
  //         // position: taskTemplateList[i].positions?.name || '',
  //       },
  //     }
  //     tmpData.push(tmpDefault)
  //     for (let i = 0; i < taskTemplateList.length; i++) {
  //       let tmp = {
  //         type: 'item-image',
  //         value: {
  //           title: taskTemplateList[i].name || '',
  //           // deparment: taskTemplateList[i].department?.name || '',
  //           // position: taskTemplateList[i].positions?.name || '',
  //         },
  //       }
  //       tmpData = [...tmpData, tmp]
  //     }
  //     let views = []
  //     for (let i = 0; i < tmpData.length; i++) {
  //       if (tmpData[i]?.type == 'header') {
  //         views.push(<div className={'header'}>{tmpData[i]?.value}</div>)
  //       } else if (tmpData[i]?.type == 'item-normal' || tmpData[i]?.type == 'item-image') {
  //         views.push(
  //           <div className={'item-normal'}>
  //             <div className="item-texts">
  //               {tmpData[i]?.value?.title && <div className="title">{tmpData[i]?.value?.title}</div>}
  //               {/* {tmpData[i]?.value?.deparment && <div className="desc">Department : {tmpData[i]?.value?.deparment}</div>}
  //               {tmpData[i]?.value?.position && <div className="desc">Position : {tmpData[i]?.value?.position}</div>} */}
  //             </div>
  //           </div>
  //         )
  //       }
  //     }
  //     setDropDownView([...views])
  //   } else {
  //     setDropDownView([])
  //   }
  // }, [taskTemplateList])

  useEffect(() => {
    if (taskTemplateList?.length > 0 && selectedId) {
      for(let i = 0; i < taskTemplateList.length; i++) {
        if(taskTemplateList[i].id == selectedId) {
          setSelectedName(taskTemplateList[i].name)
        }
      }
    }
  }, [taskTemplateList, selectedId])

  const onSelectSuggest = (index) => {
    if(index == 0) {
      setSelectedName("Blank Project")
      onSelectedSuggest("General")
    } else {
      const data = taskTemplateList[index-1]
      setSelectedName(data.name)
      onSelectedSuggest(data)
    }
    setIsOnBlur(true)
  }
  return (
    <Styled>
      <SearchSuggestBox
        onChange={(e) => {
          if(e == "") {
            setSelectedName("")
            onSelectedSuggest("General")
          }
          setSearchText(e)
        }}
        dropdownViews={dropDownViews}
        onSelected={(index) => onSelectSuggest(index)}
        className={'search-suggest'}
        searchWidth="100%"
        dropdownWidth="100%"
        placeHolder={placeHolder}
        initValue={selectedName}
        borderColor={isError ? "#d92d20": "#d0d5dd"}
        isDisabled={isDisabled}
        isHideIcon={true}
        minHeight={"40px"}
      />
    </Styled>
  )
}

const Styled = styled.div`
  .desc {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .title {
    font-size: 14px;
  }
  .desc {
    font-size: 14px;
  }
`
