import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { postAPI } from '../../../utils/api'

const Div = styled.div`
  .upload-zone {
    padding: 9px 14px;
    color: var(--Gray-600);
    font-size: 14px;
    color: var(--Base-White);
    background: var(--Primary-600);
    /* border: 1px solid ${({ $error }) => ($error ? 'red' : 'var(--Gray-300)')}; */
    /* box-shadow: var(--Shadow-xs); */
    border-radius: 8px;
    text-align: center;
    cursor: pointer;

    img {
      width: 40px;
    }

    p {
      margin: 0;

      b {
        color: var(--Primary-700);
      }
    }
  }

  .upload-list {
    .file {
      width: 100%;
      display: inline-flex;
      padding: 14px;
      margin-top: 16px;
      background: var(--Base-White);
      border: 1px solid var(--Gray-300);
      box-shadow: var(--Shadow-xs);
      border-radius: 8px;
    }

    .icon-wrapper {
      width: 32px;
    }

    .detail-wrapper {
      flex: 1;
      margin: 0 14px;
      font-size: 14px;
      overflow: hidden;

      .file-name {
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;

        a {
          font-weight: 500;
          color: var(--Gray-700);
        }
      }
    }
  }
  .txt-btn-name {
    text-transform: capitalize;
    font-weight: bold;
  }
`
const acceptList = {
  jpg: {
    'image/jpeg': ['.jpeg', '.jpg'],
  },
  png: {
    'image/png': ['.png'],
  },
  gif: {
    'image/gif': ['.gif'],
  },
  csv: {
    'text/csv': ['.csv'],
  },
  pdf: {
    'application/pdf': ['.pdf'],
  },
  word: {
    'application/msword': [],
  },
  txt: {
    'text/plain': [],
  },
  excel: {
    'application/vnd.ms-excel': [],
  },
  ppt: {
    'application/vnd.ms-powerpoint': ['.ppt','.pptx'],
  },
  xmls: {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  },
  zip: {
    'application/zip': [],
  },
  vdoms: {
    'video/x-msvideo': [],
  },
  mp4: {
    'video/mp4': [],
  },
  vdompeg: {
    'video/mpeg': [],
  },
  vdowebm: {
    'video/webm': [],
  },
  adompeg: {
    'audio/mpeg': [],
  },
  wav: {
    'audio/wav': [],
  },
  adowebm: {
    'audio/webm': [],
  },
  appleFiles: {
    'application/vnd.apple.pages': ['.pages'],
    'application/vnd.apple.keynote': ['.key'],
    'application/vnd.apple.numbers': ['.numbers'],
    'application/x-iwork-pages-sffpages': ['.pages'],
    'application/x-iwork-keynote-sffkey': ['.key'],
    'application/x-iwork-numbers-sffnumbers': ['.numbers'],
  }
}

const InputUploadFile = ({
  multiple = false,
  allowedTypes = [],
  file = [],
  onChange,
  error = false,
  disabled = false,
  placeHolder,
  ...props
}) => {
  const acceptType = allowedTypes.reduce((pre, cur) => {
    return {
      ...pre,
      ...acceptList[cur],
    }
  }, {})

  const fileValidator = () => {
    const fileObj = file.map((v) => ({ ...v, url: `${v.url}` }))
    if (fileObj.size > 100000000) {
      alert('file is larger than 100MB')
    } else {
      return null
    }
  }
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: multiple ? 0 : 1,
    accept:acceptType,
    onDropAccepted: (value) => {
      value.forEach((v) => (v.id = Math.random().toString().replace('0.', '')))
      handleAddFile(value)
    },
    disabled,
    validator: fileValidator,
  })
  const handleAddFile = async (value) => {
    const fetchList = []
    value.forEach((v) => {
      const formData = new FormData()
      formData.append('files', v)
      formData.append(
        'fileInfo',
        JSON.stringify({
          name: v.name,
          caption: v.name,
          alternativeText: v.name,
          folder: null,
        })
      )

      fetchList.push(
        postAPI({
          url: '/api/upload',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: null,
          },
          data: formData,
        })
      )
    })

    const res = await Promise.all(fetchList)

    const result = multiple ? [...file, ...res.map((v) => v[0])] : res[0]
    onChange(result)
  }

  const handleDeleteFile = (id) => {
    onChange(file.filter((v) => v.id !== id))
  }
  return (
    <Div $error={error} {...props}>
      <div {...getRootProps({ className: 'upload-zone' })}>
        <input {...getInputProps()} />
        <span className="txt-btn-name">{placeHolder ? placeHolder : 'Attach File'}</span>
      </div>
    </Div>
  )
}

export default InputUploadFile
