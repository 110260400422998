import styled from 'styled-components'

const Div = styled.div`
  display: flex;
  flex-direction: column;

  > label {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 6px;
  }

  > .error-message {
    padding-top: 6px;
    font-size: 14px;
    color: red;
  }
`

const Field = ({ label, errorMessage, children, ...props }) => {
  return (
    <Div {...props}>
      {label && <label>{label}</label>}
      {children}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </Div>
  )
}

export default Field
