import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { createSubTask, editSubTask, getSubTaskById } from '../../../../redux/actions/summaryTask'
import { selectSummaryTask } from '../../../../redux/selectors'
import { getAdminUser } from '../../../../utils/common'
import { dlog } from '../../../../utils/helper'
import DialogConfirm from '../../../dialog/DialogConfirm'
import Date from '../../../form/Date'
import TextField from '../../../form/TextField'
import Button from '../../../form/button/Button'
import ButtonOutline from '../../../form/button/ButtonOutline'
import SearchSuggestEmployee from '../../../widget/combo/SearchSuggestEmployee'
import DropdownSelect from '../../../widget/element/DropDownSelect'
import InputTextArea from '../../../widget/element/InputTextArea'
import IconChevronLeft from '../../../widget/icon/IconChevronLeft'

export default function SubTaskCreateItem({ data, editIndex, onClickBack, onSuccess, isRoutine }) {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { subTaskInfo } = useSelector(selectSummaryTask)
  const defaultData = {
    topic: '',
    subtask_type: 'General',
    assign_to: '', // id
    subtask_detail: '',
    due_date: '',
    file_id: '', // file id
    created_by: employee_info?.id, // user?.id, แบบเก่า
  }
  const [topic, setTopic] = useState('')
  const [subTaskData, setSubTaskData] = useState()
  const allowTypes = ['images', 'files']
  const [files, setFiles] = useState([])
  const [subTaskTypeSelected, setSubTaskTypeSelected] = useState('')
  const [subTaskTypeList, setSubTaskTypeList] = useState([
    {
      text: 'General',
      value: 'General',
    },
    {
      text: 'Request Approve',
      value: 'Request Approve',
    },
  ])
  const [isShowConfirmCommentDialog, setIsShowConfirmCommentDialog] = useState(false)

  const [isErrorSubTaskTopic, setIsErrorSubTaskTopic] = useState(false)
  const [isErrorSubTaskType, setIsErrorSubTaskType] = useState(false)
  const [isErrorAssignTo, setIsErrorAssignTo] = useState(false)
  const [isErrorSubTaskDetail, setIsErrorSubTaskDetail] = useState(false)
  const [isErrorDueDate, setIsErrorDueDate] = useState(false)

  useEffect(() => {
    if (editIndex >= 0) {
      let tmp = data.sub_tasks[editIndex]
      if (tmp) {
        let subtaskId = tmp.id
        dispatch(getSubTaskById(subtaskId))
        const tmpData = {
          subtask_type: tmp.subtask_type,
          assign_to: tmp.assign_to?.data?.id || tmp.assign_to?.id || '',
          subtask_detail: tmp.subtask_detail,
          due_date: tmp.due_date,
          file_id: tmp.file_id,
        }
        setTopic(tmp.topic)
        setSubTaskTypeSelected(tmp.subtask_type)
        setSubTaskData({ ...defaultData, ...tmpData })
      }
    } else {
      setSubTaskTypeSelected('General')
      setSubTaskData(defaultData)
    }
  }, [data])
  useEffect(() => {
    if (editIndex >= 0 && subTaskInfo.file_detail) {
      setFiles([subTaskInfo.file_detail])
    }
  }, [subTaskInfo])
  const onChangeInput = (e, type) => {
    let tmpValue = e.target.value
    let tmpData = subTaskData
    if (type == 'topic') {
      tmpData.topic = tmpValue
    } else {
      tmpData.subtask_detail = tmpValue
    }
    setSubTaskData(tmpData)
  }
  const onChangeDropdown = (val) => {
    let tmpData = subTaskData
    tmpData.subtask_type = val
    setSubTaskTypeSelected(val)
    setSubTaskData(tmpData)
  }
  const onChangeDate = (value) => {
    let tmpData = subTaskData
    tmpData.due_date = value
    setSubTaskData(tmpData)
  }
  const handleClickSelectEmployee = (value) => {
    setIsErrorAssignTo(false)
    let tmpData = subTaskData
    tmpData.assign_to = value.id
    setSubTaskData(tmpData)
  }
  const onChangeUploadFile = (value) => {
    let tmpData = subTaskData
    tmpData.file_id = value[0].id
    setSubTaskData(tmpData)
    setFiles(value)
  }
  const handleRemoveFile = () => {
    let tmpData = subTaskData
    tmpData.file_id = ''
    setSubTaskData(tmpData)
    setFiles([])
  }
  const handleClickCreateSubTask = () => {
    let isValid = checkValidateInputs()
    if (isValid) {
      let tmp = { ...subTaskData }
      if (isRoutine) {
        tmp.task = data?.id
      } else {
        tmp.milestone_task_type = data?.id
      }
      if (editIndex >= 0) {
        let subtaskId = data.sub_tasks[editIndex].id
        let editData = {
          topic: topic,
          assign_to: tmp.assign_to,
          subtask_detail: tmp.subtask_detail,
          due_date: tmp.due_date,
          created_by: employee_info?.id
        }
        if (isRoutine) {
          editData.task = data?.id
        } else {
          editData.milestone_task_type = data?.id
        }
        if (tmp.file_id) {
          editData.file_id = tmp.file_id
        }

        dispatch(editSubTask(subtaskId, { data: editData })).then(() => {
          if (onSuccess) {
            onSuccess()
          }
        })
      } else {
        if (tmp.subtask_type == 'Request Approve') {
          tmp.status = 'Requesting Approval'
          tmp.is_subtask_request = true
        }

        if (!tmp.file_id) {
          delete tmp.file_id
        }
        const data = { ...tmp }
        const request = { data }
        dispatch(createSubTask(request)).then(() => {
          if (onSuccess) {
            onSuccess()
          }
        })
      }
    }
  }
  const checkValidateInputs = () => {
    let isValidSubTaskTopic = false
    let isValidSubTaskType = false
    let isValidAssignTo = false
    let isValidSubTaskDetail = false
    let isValidDueDate = false
    let tmpData = subTaskData
    if (editIndex >= 0) {
      if (topic != '') {
        isValidSubTaskTopic = true
      } else {
        isValidSubTaskTopic = false
        setIsErrorSubTaskTopic(true)
      }
    } else {
      if (tmpData?.topic != '') {
        isValidSubTaskTopic = true
      } else {
        isValidSubTaskTopic = false
        setIsErrorSubTaskTopic(true)
      }
    }
    if (tmpData?.subtask_type != '') {
      isValidSubTaskType = true
    } else {
      isValidSubTaskType = false
      setIsErrorSubTaskType(true)
    }
    if (tmpData?.assign_to != '') {
      isValidAssignTo = true
    } else {
      isValidAssignTo = false
      setIsErrorAssignTo(true)
    }
    if (tmpData?.subtask_detail != '') {
      isValidSubTaskDetail = true
    } else {
      isValidSubTaskDetail = false
      setIsErrorSubTaskDetail(true)
    }
    if (tmpData?.due_date != '') {
      isValidDueDate = true
    } else {
      isValidDueDate = false
      setIsErrorDueDate(true)
    }

    if (isValidSubTaskTopic && isValidSubTaskType && isValidAssignTo && isValidSubTaskDetail && isValidDueDate) {
      return true
    } else {
      return false
    }
  }
  return (
    <Styled>
      <div className="wrp-navigation">
        <div className="navigation-left">
          <ButtonOutline onClick={onClickBack} className="btn-outline" append={<IconChevronLeft />}></ButtonOutline>
          <span>{editIndex >= 0 ? 'Edit Sub Task' : 'New Sub Task'}</span>
        </div>
      </div>

      <div className="wrp-content">
        <div className="wrp-input-row top">
          <div className="left">
            <div className="txt-title-row">Sub Task Topic</div>
            {editIndex >= 0 ? (
              <TextField
                className="txt-field"
                value={topic}
                onChange={(e) => {
                  setIsErrorSubTaskTopic(false)
                  onChangeInput(e, 'topic')
                  setTopic(e.target.value)
                }}
                placeholder={'Please Enter Topic'}
                error={isErrorSubTaskTopic}
              />
            ) : (
              <TextField
                className="txt-field"
                defaultValue={subTaskData?.topic}
                onChange={(e) => {
                  setIsErrorSubTaskTopic(false)
                  onChangeInput(e, 'topic')
                }}
                placeholder={'Please Enter Topic'}
                error={isErrorSubTaskTopic}
              />
            )}
          </div>
          <div className="right">
            <div className="txt-title-row">Sub Task Type</div>
            <DropdownSelect
              className={'dropdown'}
              dataList={subTaskTypeList}
              placeHolder="Request Approve"
              initValue={subTaskTypeSelected}
              isDisabled={editIndex >= 0}
              onChange={(e) => {
                setIsErrorSubTaskType(false)
                onChangeDropdown(e)
              }}
              width="250px"
            />
          </div>
        </div>

        <div className="wrp-input-row">
          <div className="left">
            <div className="txt-title-row">Assign to</div>
            <div className="wrp-search-employee ">
              <SearchSuggestEmployee
                // className={'search-suggest'}
                placeHolder="Select"
                onSelectedSuggest={(e) => handleClickSelectEmployee(e)}
                isError={isErrorAssignTo}
                selectedId={subTaskData?.assign_to || ''}
              />
            </div>
          </div>
        </div>

        <div className="wrp-input-row-detail">
          <div className="txt-title-row">Task Details</div>
          <div className="wrp-text-area">
            <InputTextArea
              className={`text-area ${isErrorSubTaskDetail && subTaskData?.subtask_detail == '' ? 'error' : ''}`}
              initValue={subTaskData?.subtask_detail}
              onChange={(e) => {
                setIsErrorSubTaskDetail(false)
                onChangeInput(e, 'detail')
              }}
              error={isErrorSubTaskDetail}
            />
          </div>
        </div>

        {/* <div className="attachment">
          {files.length == 0 && (
            <MediaFile
              multiple={false}
              allowedTypes={allowTypes}
              onChange={onChangeUploadFile}
              inputForm="Attach File"
            />
          )}
          {files?.length > 0 && (
            <div className="wrp-file-container">
              <div className="wrp-file-item">
                <img src={iconFile} />
                <div className="wrp-txt-file-name">
                  <span className="txt-file-name">{files[0].name}</span>
                </div>
              </div>
              <div className="btn-delete-file">
                <img src={iconDelete} title="Delete" onClick={() => handleRemoveFile()} />
              </div>
            </div>
          )}
        </div> */}

        <div className="wrp-input-row">
          <div className="left">
            <div className="txt-title-row">Due Date</div>
            <Date
              className="btn-calendar"
              value={subTaskData?.due_date}
              onChange={(e) => {
                setIsErrorDueDate(false)
                onChangeDate(e)
              }}
              error={isErrorDueDate}
            />
          </div>
        </div>

        <DialogConfirm
          className={'confirm-style-dialog'}
          open={isShowConfirmCommentDialog}
          onClose={() => setIsShowConfirmCommentDialog(false)}
          description={'Would you like to confirm the deletion of this comment ?'}
          textYes={'confirm'}
          textNo={'cancel'}
        />
      </div>

      <div className="wrp-btn-container">
        <ButtonOutline className={'btn-cancel'} onClick={onClickBack}>
          Cancel
        </ButtonOutline>
        <Button onClick={handleClickCreateSubTask}>{editIndex >= 0 ? 'Save' : 'Create'}</Button>
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .wrp-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 40px;

    .navigation-left {
      display: flex;
      align-items: center;

      .btn-outline {
        cursor: pointer;
        margin-right: 15px;
        border-radius: 25px;
        border: none;
        padding: 5px;
        height: min-content;
        span {
          margin: 0;
        }
      }
      span {
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
  .btn-outline {
    cursor: pointer;
    margin-right: 0.5rem;
    border-radius: 25px;
    border: none;
    padding: 5px;
    height: min-content;
    span {
      margin: 0;
    }
  }

  .wrp-input {
    display: flex;
    align-items: center;

    span {
      margin-right: 1rem;
    }
  }

  .wrp-content {
    flex: 1;
    padding: 20px 40px 10px;

    .wrp-input-row {
      display: flex;
      margin-bottom: 24px;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
        margin-left: 12px;
      }
    }
    .top {
      margin-bottom: 40px;
    }
    .txt-field,
    .dropdown {
      width: 100%;
    }
    .wrp-search-employee {
      width: 50%;
      padding-right: 6px;
    }

    .wrp-input-row .left .wrp-search-employee .wrp-search-input {
      width: 100% !important;
      padding-right: 10px;
    }

    /* .txt-field,
    .btn-dropdown,
    .wrp-input-row .wrp-search-employee .wrp-search-input {
      width: 250px !important;
    } */

    .attachment {
      width: 100px;
      margin: 0 0 15px 160px;
    }
    .wrp-file-container {
      display: flex;
      align-items: center;
    }
    .wrp-file-item {
      min-width: 200px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding: 4px;
      border: 1px solid var(--Gray-300);

      .wrp-txt-file-name {
        width: 150px;
        overflow: hidden;
        position: relative;
      }

      .txt-file-name {
        margin-left: 4px;
        font-size: 14px;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .btn-attachment {
      cursor: pointer;
      border: none;
      box-shadow: none;
    }
    .btn-delete-file {
      cursor: pointer;
      padding: 10px 10px;
      height: 100%;
      border-radius: 50%;
    }

    .txt-title-row {
      margin-bottom: 6px;
    }

    .wrp-input-row-detail {
      margin-bottom: 29px;

      .wrp-text-area {
        flex: 1;
      }
    }
  }
  .wrp-btn-container {
    padding: 15px 40px;
    border-top: 1px solid var(--Gray-300);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-cancel {
      margin-right: 10px;
    }
  }

  .text-field {
    width: 100%;
    height: 200px;
  }
  .btn-calendar {
    width: 200px;
  }

  .text-area {
    width: 100%;
    min-height: 150px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    padding: 10px;
    resize: none;

    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .confirm-style-dialog {
    text-align: center !important;
  }

  .wrp-txt-title {
    display: flex;
    justify-content: end;
    align-items: center;
  }
`
