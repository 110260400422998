import styled from 'styled-components'

import Dropdown from './Dropdown'

const Div = styled.div`
  display: inline-flex;

  > div {
    width: 82px;

    + div {
      margin-left: 4px;
    }
  }
`

const formatTime = (v) => v.toString().padStart(2, '0')

const hourList = new Array(24).fill().map((v, i) => ({ text: formatTime(i), value: formatTime(i) }))
const minuteList = new Array(60).fill().map((v, i) => ({ text: formatTime(i), value: formatTime(i) }))

const Time = ({ value, error, disabled, onChange, ...props }) => {
  const [hour, minute] = (value || '').split(':')

  const onChangeHour = (v) => {
    onChange(`${v}:${minute || '00'}:00.000`)
  }
  const onChangeMinute = (v) => {
    onChange(`${hour || '00'}:${v}:00.000`)
  }

  return (
    <Div {...props}>
      <Dropdown
        optionList={[{ text: '-', value: '' }, ...hourList]}
        value={hour || ''}
        onChange={onChangeHour}
        error={error}
        disabled={disabled}
      />
      <Dropdown
        optionList={[{ text: '-', value: '' }, ...minuteList]}
        value={minute || ''}
        onChange={onChangeMinute}
        error={error}
        disabled={disabled}
      />
    </Div>
  )
}

export default Time
