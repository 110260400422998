import styled from 'styled-components'
import { useState, useEffect,useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getMeetingRoom,getMeeting,getMeetingForCheck,MeetingCheckRoom } from '../../redux/actions/dailysummary'
import { selectDailySummary } from '../../redux/selectors'

import dayjs from 'dayjs'
import { DateDisplay,TimeDisplayHHmm } from '../../utils/common'


import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import iconPlus from '../../assets/images/icon-plus.svg'

import InputTextArea from '../../components/widget/element/InputTextArea'
import Button from '../../components/form/button/Button'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ButtonOutline from '../../components/form/button/ButtonOutline'
import iconDelete from '../../assets/images/icon-bin.svg'
import Dropdown from '../../components/form/Dropdown'

import TextField from '../../components/form/TextField'
import DatePick from '../../components/form/Date'
import TextFieldTime from '../../components/form/TextFieldTime'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'



import ModalMeetingCalendar from '../../pages/my-calendar/ModalMeetingCalendar'
import SidePanel from '../../components/common/SidePanel'


import userIconQuesTion from '../../assets/images/question_icon.png'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'
import { useTranslation } from 'react-i18next'
const Div = styled.div`
.css_header_detail {
display: flex;
-webkit-box-pack: justify;
justify-content: space-between;
font-size: 14px;
    .date_css{
        color: #7F56D9;
        font-weight: bold;
    }
} 
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .content_header {
    padding: 14px 14px;

    width: ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 100px;
    padding-bottom: 106px;
    .title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
    }
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .div_table {
    padding: 15px;
  }
  .resp-table {
    width: 100%;
    display: table;
  }
  .resp-table-body {
    display: table-row-group;
  }
  .resp-table-row {
    display: table-row;
  }
  .table-body-head_cell {
    display: table-cell;
    border: 1px solid #D0D5DD;
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    text-align: center;
    font-weight: 600;
    background-color: #F2F4F7;
    color: #475467;
    font-size: 12px;
  }
  .table-body-cell {
    display: table-cell;
    border: 1px solid #dddddd;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: center;
    cursor : pointer;

  }
  .width_time {
    /*width: 30%;*/
    width: 20%;
  }
  .width_activity {
    width: 37%;
  }
  .width_result {
    width: 37%;
  }
  .width_del {
    width: 6%;
    text-align: center;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dd_css {
    margin: 0px 5px;
  }
  .basic-Information-detail {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    padding-top: 10px;
    .topic_fill{
      font-size: 14px;
      color: #212121;
      margin-bottom: 5px;
    }
    .topic_detail{
      font-size: 14px;
      color: #757575;
    }
  }
  .css_calendar {
    margin-left: 15px;
  }
  .padding-detail{
    padding: 0px 14px;
  }
  .border_top_left_radius{
    border-top-left-radius: 8px;
  }
  .border_top_right_radius{
    border-top-right-radius: 8px;
  }
  .border_bottom_left_radius{
    border-bottom-left-radius:8px
  }
  .border_bottom_right_radius{
    border-bottom-right-radius: 8px;
  }
  .css_time_text{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
  }
  .TextField_css{
    width: 100%;
  }
.wrp-tab-menu {
width: 100%;
margin-bottom: 1rem;
margin-top: 1rem;
display: flex;

.btn-menu-style {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    border-bottom: 2px solid #EAECF0;
    color: #667085;
    font-size: 14px;
}

.menu-active {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid var(--Primary-600) !important;
    color: #6941C6;
    background-color: #F9F5FF;
}
}
.input-new-2 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr repeat(1, auto);
    grid-template-rows: auto;
    align-content: center;
}
.TextField_employee{
    width : 100%;
  }
.content-fillter-bg{
  min-height: 68px;
  background-color: var(--Gray-50);
  border-radius: 8px;
  margin-bottom: 21px;
  padding: 10px;
}
.content-fillter-date{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 10px;

  .search-wrapper {
    margin-right: 12px;
  }

  .filter-list {
    flex-basis: 100%;
    margin-top: 24px;
  }

  .group_filter {
    display: flex;
    align-items: center;
  }

  .css_date_pagitation{
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    .text_date{
      margin: 0px 10px;
    }
    .css_arrow{
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
}

.content-fillter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  

  .search-wrapper {
    margin-right: 12px;
  }

  .filter-list {
    flex-basis: 100%;
    margin-top: 24px;
  }

  .group_filter {
    display: flex;
    align-items: center;
  }
}
.button_clear_all{
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
}
.mr-1-rem {
  margin-right: 1rem;
}
.dropdown_css{
  min-width : 220px;
}
.topic_fill{
  font-size: 14px;
  color: #212121;
  margin-bottom: 5px;
}
`
const DivCalendar = styled.div`
.resp-table {
    width: 100%;
    display: table;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    border: 1px solid #EAECF0;
}
.topicDay-cell {
    float: left;
    height: 60px;
    width: 14.28571428571429%;
    text-align: center;
    padding: 10px;
    background-color: #F2F4F7;
    color: #475467;
    font-size: 16px;
}
.table-body-cell {
    float: left;
    height: 120px;
    width: 14.28571428571429%;
    padding: 10px;
    cursor : pointer;
    :hover {
        background-color: #F2F4F7;
    }
}
.border_right{
border-right: 1px solid #EAECF0;
}
.border_bottom{
border-bottom: 1px solid #EAECF0;
}
.css_holiday {
background-color: #F2F4F7;
}
.css_block_date_now {
background-color: #E5E7EB;
}
.css_day{
width: 16px;
height: 16px;
line-height: 1.72;
text-align: center;
cursor: default;
margin-right: auto;
margin-left: auto;
color:#000000;
font-size: 10px;
}
.css_date_now {
width: 16px;
height: 16px;
line-height: 1.72;
text-align: center;
cursor: default;
margin-right: auto;
margin-left: auto;
color: rgb(255, 255, 255);
font-size: 10px;
background: rgb(105, 65, 198);
border-radius: 48%;
}
.css_day_not_month{
width: 16px;
height: 16px;
line-height: 1.72;
text-align: center;
cursor: default;
margin-right: auto;
margin-left: auto;
color:#BDBDBD;
font-size: 10px;
}
.css_Meeting{
    /*
    margin-top: 2px;
    cursor: pointer;
    height: 20px;
    background-color: #FEFBE8;
    border: 1px solid #EAAA08;
    border-radius: 8px;
    color: #A15C07;
    font-size: 10px;
    padding-left: 5px;
    display: flex;
    align-items: center;
    */
    margin-top: 2px;
    cursor: pointer;
    height: 20px;
    background-color: #F9FAFB;
    border: 1px solid #98A2B3;
    border-radius: 8px;
    color: #344054;
    font-size: 10px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    
    /*
    :hover {
      background-color: #FEEE95;
    }
    :active {
      background-color: #EAAA08;
      border: 1px solid #EAAA08;
      color: #FFFFFF;
    }
    */
}
.css_Plus{
margin-top: 2px;
cursor: pointer;
height: 20px;
background-color: #FFFFFF;
border: 1px solid #98A2B3;
border-radius: 8px;
color: #667085;
font-size: 10px;
padding-left: 5px;
display: flex;
align-items: center;
width: 25px;
}
`

const DivBook = styled.div`
.css_header_detail {
display: flex;
-webkit-box-pack: justify;
justify-content: space-between;
font-size: 14px;
    .date_css{
        color: #7F56D9;
        font-weight: bold;
    }
} 
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .content_header {
    padding: 14px 14px;

    width: ${({width}) => width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 90px;
    padding-bottom: 106px;
    .title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
    }
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${({width}) => width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .div_table {
    padding: 15px;
  }
  .resp-table {
    width: 100%;
    display: table;
  }
  .resp-table-body {
    display: table-row-group;
  }
  .resp-table-row {
    display: table-row;
  }
  .table-body-head_cell {
    display: table-cell;
    border: 1px solid #D0D5DD;
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    text-align: center;
    font-weight: 600;
    background-color: #F2F4F7;
    color: #475467;
    font-size: 12px;
  }
  .table-body-cell {
    display: table-cell;
    border: 1px solid #dddddd;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: center;
  }
  .width_time {
    /*width: 30%;*/
    width: 20%;
  }
  .width_activity {
    width: 37%;
  }
  .width_result {
    width: 37%;
  }
  .width_del {
    width: 6%;
    text-align: center;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dd_css {
    margin: 0px 5px;
  }
  .basic-Information-detail {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    padding-top: 10px;
    .topic_fill{
      font-size: 14px;
      color: #212121;
      margin-bottom: 5px;
    }
    .topic_detail{
      font-size: 14px;
      color: #757575;
    }
  }
  .css_calendar {
    margin-left: 15px;
  }
  .padding-detail{
    padding: 0px 14px;
  }
  .border_top_left_radius{
    border-top-left-radius: 8px;
  }
  .border_top_right_radius{
    border-top-right-radius: 8px;
  }
  .border_bottom_left_radius{
    border-bottom-left-radius:8px
  }
  .border_bottom_right_radius{
    border-bottom-right-radius: 8px;
  }
  .css_time_text{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
  }
  .TextField_css{
    width: 100%;
  }
.wrp-tab-menu {
width: 100%;
margin-bottom: 1rem;
margin-top: 1rem;
display: flex;

.btn-menu-style {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    background-color: transparent;
    border-bottom: 2px solid #EAECF0;
    color: #667085;
    font-size: 14px;
}

.menu-active {
    border: none;
    padding: 10px 18px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid var(--Primary-600) !important;
    color: #6941C6;
    background-color: #F9F5FF;
}
}
.input-new-2 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr repeat(1, auto);
    grid-template-rows: auto;
    align-content: center;
}
.TextField_employee{
    width : 100%;
  }
`

const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 14px;
  color: #F04438;
`

const MeetingCalendar = ({ onClose, onSubmit , MeetingData,setMeetingData,...props  }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const errorMessage = '';
  const editable = true;
  const { user } = JSON.parse(localStorage.getItem('admin'))

  const [MeetingListCalendar, setMeetingListCalendar] = useState([])

  const { MeetingRoomList,MeetingList, limit, total, skip, isLoading } = useSelector(selectDailySummary)

  useEffect(() => {
    dispatch(getMeetingRoom())
  }, [dispatch])



  const currentDate_now = dayjs().format('YYYY-MM-DD');
  const [currentDate, setcurrentDate] = useState(dayjs(currentDate_now).format('YYYY-MM-DD'));

  const this_day = dayjs(currentDate).startOf("month").day() ;
  const start_date = dayjs(currentDate).format('YYYY-MM') + '-01';
  const start_date_loop = dayjs(start_date).add(0 - this_day, 'days').format('YYYY-MM-DD');
  
  const end_date = dayjs(currentDate).format('YYYY-MM') + '-' + dayjs(currentDate).daysInMonth();
  const this_day_end = dayjs(end_date).day() ;
  const end_date_loop = dayjs(end_date).add(13 - this_day_end, 'days').format('YYYY-MM-DD');

  const fetchData = useCallback(
    async (filter) => {
      dispatch(getMeetingForCheck(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          setMeetingListCalendar(data_info)
        }
      })
    },
    [dispatch]
  )
  useEffect(() => {
    const filter = {
      skip: 1,
      limit: 1000,
      meeting_room_is_not_null : true,
      start_date : start_date_loop,
      end_date : end_date_loop,
    }
    fetchData(filter)
  }, [fetchData,start_date_loop,end_date_loop])

  const [topicDay, settopicDay] = useState([
    {
      text : t('sun'),
      css : 'topicDay-cell border_right border_bottom'
    },
    {
      text : t('mon'),
      css : 'topicDay-cell border_right border_bottom'
    },
    {
      text : t('tue'),
      css : 'topicDay-cell border_right border_bottom'
    },
    {
      text : t('wed'),
      css : 'topicDay-cell border_right border_bottom'
    },
    {
      text : t('thur'),
      css : 'topicDay-cell border_right border_bottom'
    },
    {
      text : t('fri'),
      css : 'topicDay-cell border_right border_bottom'
    },
    {
      text : t('sat'),
      css : 'topicDay-cell border_right border_bottom'
    },
  ])
  //---ST Call API MeetingList
  /*
  const MeetingSpace_option = [
    { text: 'Meeting Room A', value: 1 },
    { text: 'Meeting Room B', value: 2 },
    { text: 'Meeting Room C', value: 3 },
    { text: 'Meeting Room D', value: 4 },
  ]
  */
  const [MeetingSpace_option, setMeetingSpace_option] = useState([])

  useEffect(() => {
    const newProvinces = MeetingRoomList.map((v) => ({
      text: v.name_th,
      value: v.id,
    }))
    setMeetingSpace_option([...newProvinces])
  }, [MeetingRoomList])
  //---ED Call API MeetingList

  const [dateMonthList, setdateMonth] = useState([])
  useEffect(() => {
    //--- ST bineData API
    
    
    const Dataappointments = MeetingListCalendar.map((v) => ({
      id: v.id,
      title: v.title,
      meeting_room_name: v.meeting_room_name,
      by: 'By : ' + v.create_by,
      startDate: dayjs(v.meeting_date_start).format('YYYY-MM-DD HH:mm:ss'),
      endDate: dayjs(v.meeting_date_end).format('YYYY-MM-DD HH:mm:ss'),
      css: 'css_Meeting'
    }))
    
    
    
    const newStateData = [...Dataappointments]
    //const newStateData = []
    //--- ED bineData API
    
    let tmpList = []
    let num_week = 0;
    const this_end_date_loop = new Date(end_date_loop);
    
    for (var d = new Date(start_date_loop); d <= this_end_date_loop; d.setDate(d.getDate() + 1)) {
        if(dayjs(d).day() == 0){
          num_week = num_week + 1;
        }
        if(num_week > 6){
          break;
        }
        //
        let tmpObj = {
            this_date: dayjs(d).format('YYYY-MM-DD'),
            css : dayjs(d).day() == 6 ? 'table-body-cell border_bottom' : 'table-body-cell border_right border_bottom',
            css_holiday : dayjs(d).format('YYYY-MM-DD') == currentDate_now? 'css_block_date_now' : dayjs(d).day() == 6 || dayjs(d).day() == 0 ? 'css_holiday' : '',
            css_day : dayjs(d).format('YYYY-MM-DD') == currentDate_now? 'css_date_now' : dayjs(d).format('YYYY-MM') == dayjs(currentDate).format('YYYY-MM') ? 'css_day' : 'css_day_not_month',
            list_job_day : newStateData.filter((obj) =>{ return dayjs(d).format('YYYY-MM-DD') == dayjs(obj.startDate).format('YYYY-MM-DD')}
            )
        }
        tmpList.push(tmpObj)
    }
    setdateMonth([...tmpList])
  }, [start_date_loop, end_date_loop,MeetingListCalendar])

  const [DataObj, setDataObj] = useState({})
  const [isModalMeetingCalendar, setisModalMeetingCalendar] = useState(false)
  const OpenModal = (data,event) => {
    const this_x = window.innerWidth - event.clientX < 400 ? event.clientX - 400 : event.clientX ;
    const localX = this_x;
    const this_y = window.innerHeight - event.clientY < 200 ? event.clientY - 200 : event.clientY - 50 ;
    const localY = this_y;

    const this_data = {...data,localX: localX, localY: localY}
    setDataObj(this_data) 
    
  }
  const OpenModalBook = (data,event,this_date) => {
    //alert('AAAA')
    const this_x = window.innerWidth - event.clientX < 400 ? event.clientX - 400 : event.clientX ;
    const localX = this_x;
    const this_y = window.innerHeight - event.clientY < 200 ? event.clientY - 200 : event.clientY - 50 ;
    const localY = this_y;

    const this_data = {this_date : this_date,localX: localX, localY: localY,arrdata : data}
    setDataObj(this_data) 
    if(data.length == 0){
      onBookModalMeetingCalendar(this_data.this_date)
      setModalNewDailySummary(true)
    }else{
      setisModalMeetingCalendar(true)
    }
    
  }
  const [ModalNewDailySummary, setModalNewDailySummary] = useState(false)
  const CloseModalMeetingCalendar = () => {
    setisModalMeetingCalendar(false)
  }
  const onBookModalMeetingCalendar = (this_date) => {
    setMeetingData({ ...MeetingData, meeting_date: this_date })
    setisModalMeetingCalendar(false)
    setModalNewDailySummary(true)
  }
  const CloseModalNewDailySummary = () => {
    setModalNewDailySummary(false)
  }
  const SubmitModalNewDailySummary = () => {
    setModalNewDailySummary(false)
    //---- ส่งกลับไปที่หน้าสร้าง meeting
    //setMeetingData({...MeetingData , meeting_space : '9999'})
    onSubmit()
  }
  const onChangecurrentDate = (value) => {
    setMeetingData({ ...MeetingData, meeting_date: value })
  }
  const onMeetingSpace = (v_inp) => {
    const this_name_room = MeetingRoomList.filter((v) => v.id == v_inp)
    if(this_name_room.length > 0){
      setMeetingData({ ...MeetingData, meeting_space: this_name_room[0].name_th , meeting_room : v_inp , google_map_link: this_name_room[0].location })
    }
    
  }
  const [fillterMeetingSpace, setfillterMeetingSpace] = useState(null)
  const onfillterMeetingSpace = (v) => {
    setfillterMeetingSpace(v)
    const filter = {
      skip: 1,
      limit: 1000,
      meeting_room : v,
      start_date : start_date_loop,
      end_date : end_date_loop,
    }
    
    fetchData(filter)
  }
  
  const onClearAllSearch = () => {
    setfillterMeetingSpace(null)
    const filter = {
      skip: 1,
      limit: 1000,
      meeting_room_is_not_null : true,
      start_date : start_date_loop,
      end_date : end_date_loop,
    }
    
    fetchData(filter)
  }
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [Modal_title, setModal_title] = useState('')
  const [Modal_NameItem, setModal_NameItem] = useState('')
  const [Modal_Description, setModal_Description] = useState('')
  const [TitleFail, setTitleFail] = useState(t('this_meeting_space_has_been_booked'))
  const [nameItemFail, setnameItemFail] = useState('')
  const [nameItemFail2, setnameItemFail2] = useState('')
  
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const ConfirmDialog = () => {
    if (isValid().length == 0) {
            //--- เช็คห้องประชุมว่ามีว่างไหม
            const meeting_date_start = dayjs(MeetingData.meeting_date  + ' ' + MeetingData.time_start.replace(".", ":") + ':00').format('YYYY-MM-DD HH:mm:ss');
            const meeting_date_end = dayjs(MeetingData.meeting_date  + ' ' + MeetingData.time_end.replace(".", ":") + ':00').format('YYYY-MM-DD HH:mm:ss');
            
            //(('${meeting_start_time}' BETWEEN mt.meeting_start_time and mt.meeting_end_time) or  ('${meeting_end_time}' BETWEEN mt.meeting_start_time and mt.meeting_end_time) or ('${meeting_start_time}' < mt.meeting_start_time and '${meeting_end_time}' > mt.meeting_start_time))
            const sent_data_api = {
              data: {
                meeting_room: MeetingData.meeting_room,
                meeting_date: MeetingData.meeting_date,
                meeting_date_start: meeting_date_start,
                meeting_date_end: meeting_date_end,
              },
            }
            //alert(JSON.stringify(sent_data_api))
            dispatch(MeetingCheckRoom(sent_data_api)).then(({ type,data_info }) => {
              //alert(type)
              if(type.endsWith('_SUCCESS')){
                if(data_info.status == 200){
                  setModal_title('Booking ' + MeetingData.meeting_space)
                  setModal_NameItem(DateDisplay(MeetingData.meeting_date ))
                  setModal_Description(MeetingData.time_start + ' - ' + MeetingData.time_end)
                  setModalConfirmDialog(true)
                }else{
                  const temp_nameItemFail2 = DateDisplay(data_info.data_info[0].meeting_date) + ' ' + TimeDisplayHHmm(data_info.data_info[0].meeting_date_start) + ' - ' + TimeDisplayHHmm(data_info.data_info[0].meeting_date_end);
                  setTitleFail(t('this_meeting_space_has_been_booked'));
                  setnameItemFail(data_info.data_info[0].meeting_room_name);
                  setnameItemFail2(temp_nameItemFail2)
                  setDescriptionFail(t('please_make_change')); 
                  setFailModal(true)
                }
              }else{
                setTitleFail(t('meeting_checkroom_error'));
                setnameItemFail('');
                setnameItemFail2('')
                setDescriptionFail(''); 
                setFailModal(true)
              }
            })

            /*
            const this_filter = {
              meeting_room : MeetingData.meeting_room,
              filter_date : MeetingData.meeting_date 
            }
            dispatch(getMeetingForCheck(this_filter)).then(({ type,data_info }) => {
              if(type.endsWith('_SUCCESS')){
                const newSearchFilters_1 = data_info.filter((v) =>  dayjs(meeting_date_start).unix() >= dayjs(v.meeting_date_start).unix() && dayjs(meeting_date_start).unix() <= dayjs(v.meeting_date_end).unix()) 
                const newSearchFilters_2 = data_info.filter((v) =>  dayjs(meeting_date_end).unix() >= dayjs(v.meeting_date_start).unix() && dayjs(meeting_date_end).unix() <= dayjs(v.meeting_date_end).unix()) 
                const newSearchFilters_3 = data_info.filter((v) =>  dayjs(meeting_date_start).unix() < dayjs(v.meeting_date_start).unix() && dayjs(meeting_date_end).unix() > dayjs(v.meeting_date_start).unix()) 
                if(newSearchFilters_1.length > 0 || newSearchFilters_2.length > 0 || newSearchFilters_3.length > 0){
                  //
                  const newStateData = [...newSearchFilters_1, ...newSearchFilters_2, ...newSearchFilters_3]
                  //
                  const temp_nameItemFail2 = DateDisplay(newStateData[0].meeting_date) + ' ' + TimeDisplayHHmm(newStateData[0].meeting_date_start) + ' - ' + TimeDisplayHHmm(newStateData[0].meeting_date_end);
                  setTitleFail('This meeting space has been booked');
                  setnameItemFail(newStateData[0].meeting_room_name);
                  setnameItemFail2(temp_nameItemFail2)
                  setDescriptionFail('please make change'); 
                  setFailModal(true)
                }else{
                  setModal_title('Booking ' + MeetingData.meeting_space)
                  setModal_NameItem(DateDisplay(MeetingData.meeting_date ))
                  setModal_Description(MeetingData.time_start + ' - ' + MeetingData.time_end)
                  setModalConfirmDialog(true)
                }
                
              }
            })
            */
    }
  }
  const [MeetingDataError, setMeetingDataError] = useState({

    meeting_room: {
      show_errorMessage: false,
      errorMessage: 'Please fill Meeting Room',
    },
    
  })
  const isValid = () => {
    let data_valid = MeetingData
    let data_check_valid = MeetingDataError
    let errors = []
    Object.keys(MeetingDataError).forEach((k) => {
      let data = data_valid[k]
      if(data_check_valid[k].errorMessage != ''){
        if (!data || data < 0) {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
        } else {
          data_check_valid[k].show_errorMessage = false
        }
      }

    })
    setMeetingDataError({ ...MeetingDataError })
    return errors
  }

  const DateMonthChange = (data) => {
    if(data =='now'){
      const new_date = dayjs().format('YYYY-MM-DD');
      setcurrentDate(new_date)
    }else if(data =='back'){
      const new_date = dayjs(currentDate).add(-1, 'month').format('YYYY-MM-DD')
      setcurrentDate(new_date)
    }else{
      const new_date = dayjs(currentDate).add(1, 'month').format('YYYY-MM-DD')
      setcurrentDate(new_date)
    }

  }
  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">{t('reserve_meeting_space')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">{t('check_out_available_room_easily_to_reserve_meeting_space')}</div>
        <hr />
      </div>
      <div className="dialog-content">
        <div className="content-fillter-bg">
          <div className="content-fillter">
            <div className="group_filter">
              <div>
                  
                  <Dropdown
                      id={`fillterMeetingSpace`}
                      className="dropdown_css mr-1-rem"
                      value={fillterMeetingSpace}
                      optionList={MeetingSpace_option}
                      onChange={onfillterMeetingSpace}
                      disabled={!editable}
                      placeHolder={t('all_room')}
                      ValueplaceHolderIsNull={true}
                    />
              </div>
              <div>
                <Button onClick={onClearAllSearch} className="button_clear_all mr-1-rem">
                  {t('clear_all')}
                </Button>
              </div>
              
            </div>
            <div className="container">
              <Button onClick={() => setModalNewDailySummary(true)}>
                {t('book_meeting_space')}
              </Button>
            </div>
          </div>

        </div>
        <div className="content-fillter-date">
           <div>
            <Button  onClick={() => DateMonthChange('now')}>
              {t('feature_calendar_today')}
            </Button>
           </div>
           <div className="css_date_pagitation">
            <div className="css_arrow"><ArrowBackIosNewIcon onClick={() => DateMonthChange('back')}   /></div>
            <div className="text_date">{dayjs(currentDate).format('MMM YYYY')}</div>
            <div className="css_arrow"><ArrowForwardIosIcon onClick={() => DateMonthChange('next')}   /></div>
           </div>
           <div className="container"></div>
        </div>
        <DivCalendar>
            <div className="resp-table">
                {topicDay?.map((item, index) => (
                    <div className={item.css} key={index}>
                    {item.text}
                    </div>
                ))}
                {dateMonthList?.map((item, index) => (
                    <div className={`${item.css} ${item.css_holiday}`} key={index}  id={`date_id-${item.this_date}`} onClick={(e) => OpenModalBook(item.list_job_day,e,item.this_date)}>
                        <div className={`${item.css_day}`}>{dayjs(item.this_date).format('D')}</div>
                        {item.list_job_day.length <= 4 && (
                          <div>
                            {item.list_job_day?.map((item_job, index_job) => (
                                <div className={`${item_job.css}`} key={index_job} onClick={(e) => OpenModal(item_job,e)}>
                                  
                                  <div>{item_job.meeting_room_name}</div>
                                  <div>{dayjs(item_job.startDate).format('HH.mm')} - {dayjs(item_job.endDate).format('HH.mm')}</div>
                                </div>
                            ))} 
                          </div>
                        )}

                        {item.list_job_day.length > 4 && (
                          <div>
                            {item.list_job_day?.slice(0,3).map((item_job, index_job) => (
                              <div className={`${item_job.css}`} key={index_job} onClick={(e) => OpenModal(item_job,e)}>                                    
                                <div>{item_job.meeting_room_name}</div>
                                <div>{dayjs(item_job.startDate).format('HH.mm')} - {dayjs(item_job.endDate).format('HH.mm')}</div>
                              </div>
                            ))}
                            <div className={`css_Plus`} >+{item.list_job_day?.length - 3}</div>        
                          </div>
                        )}

                                      
                    </div>

                ))}
            </div>
        </DivCalendar>
      </div>
      <ModalMeetingCalendar
          OpenModal={isModalMeetingCalendar}
          onClose={CloseModalMeetingCalendar}
          onBook={onBookModalMeetingCalendar}
          DataObj={DataObj}
        />
      <SidePanel isOpen={ModalNewDailySummary} setIsOpen={setModalNewDailySummary} width={400}>
          <DivBook width={400}>
            <div className="content_header">
                <header>
                    <div className="content-title">{t('booking_meeting_space')}</div>
                    <div className="wrp-tool-right">
                        <CloseOutlinedIcon size={30} onClick={CloseModalNewDailySummary} className="icon" />
                    </div>                
                </header>
                <div className="css_header_detail">{t('complete_booking_easily')}</div>
                <hr />
            </div>
            <div className="dialog-content">
                <div className="basic-Information-detail">
                    <div>
                        <div className="topic_fill">{t('meeting_room')}</div>
                        <div >
                          <Dropdown
                            id={`MeetingSpace`}
                            className="dd_employee"
                            value={MeetingData.meeting_room}
                            optionList={MeetingSpace_option}
                            onChange={onMeetingSpace}
                            disabled={!editable}
                            ValueplaceHolderIsNull={true}
                            error={Boolean(MeetingDataError.meeting_room.show_errorMessage)}
                          />
                          {MeetingDataError.meeting_room.show_errorMessage == true && (
                          <DivErrorMessage>{MeetingDataError.meeting_room.errorMessage}</DivErrorMessage>
                        )}
                        </div>
                    </div>
                </div>
                <div className="basic-Information-detail">
                    <div>
                        <div className="topic_fill">{t('meeting_date_time_header')}</div>
                        <div >
                            <DatePick className={'input-calendar'} value={MeetingData.meeting_date} onChange={onChangecurrentDate} />
                        </div>
                    </div>
                    <div></div>
                </div>
                <div className="basic-Information-detail">
                    <div>
                        <div className="topic_fill">{t('time_start')} *</div>
                        <div >
                            <TextField
                            name="time_start"
                            className="TextField_employee"
                            placeholder={t('time_start')}
                            value={MeetingData.time_start}
                            onChange={(e) => setMeetingData({ ...MeetingData, time_start: e.target.value })}
                            disabled={!editable}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="topic_fill">{t('time_end')} *</div>
                        <div >
                            <TextField
                            name="time_end"
                            className="TextField_employee"
                            placeholder={t('time_end')}
                            value={MeetingData.time_end}
                            onChange={(e) => setMeetingData({ ...MeetingData, time_end: e.target.value })}
                            disabled={!editable}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="dialog-footer">
                <hr />
                <ButtonOutline className="button_margin" onClick={CloseModalNewDailySummary}>
                {t('cancel')}
                </ButtonOutline>
                <Button className="button_margin" onClick={ConfirmDialog}>
                {t('confirm_booking')}
                </Button>
            </div>
          </DivBook>                   
      </SidePanel>
      <DialogConfirmV2
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onNo={() => setModalConfirmDialog(false)}
          onSubmit={SubmitModalNewDailySummary}
          icon={userIconQuesTion}
          title={Modal_title}
          nameItem={Modal_NameItem}  
          description={Modal_Description}
          textYes={t('book')}
          textNo={t('cancel')}
        />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          nameItem2={nameItemFail2}
          description={DescriptionFail}
          textYes={t('back')}
        />
    </Div>
  )
}

export default MeetingCalendar
