import { getAPI, postAPI, putAPI, deleteAPI } from '../../utils/api'
import {
  GET_SHARED_SERVICE_TEMPLATE,
  GET_SHARED_SERVICE_TEMPLATE_BY_ID,
  CREATE_DOCUMENT_ACKNOWLEDGEMENT,
  SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT,
  GET_DOCUMENT_ACKNOWLEDGE,
  GET_DOCUMENT_ACKNOWLEDGE_BY_ID,
  DELETE_DOCUMENT_ACKNOWLEDGE,
  SET_DOCUMENT_FORM,
  SET_SHARED_SERVICE_INFO,
  SET_DOCUMENT_ACKNOWLEDGEMENT_INFO,
  SET_FLAG_DRAFT,
  GET_DOCUMENT_INVITE_LIST,
  ADD_INVITE,
  DELETE_INVITE,
  DUPLICATE_DOCUMENT_ACKNOWLEDGE,
  SEND_DOC_NOTIFICATION,
} from '../actionTypes'
import { showLoading, hideLoading } from '../actions/loading'

export const getSharedServiceTemplate =
  (category = '') =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_SHARED_SERVICE_TEMPLATE.REQUEST })
      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const params = {
        'fields[0]': 'name',
        'populate[document_acknows][fields][0]': 'version',
        'populate[document_acknows][filters][status]': 'Active',
        'populate[document_acknows][filters][creator][company][id]': company_info.id,
      }
      if (category !== 'all') params['filters[category][$eq]'] = category

      const { data } = await getAPI({
        url: '/api/document-acknow-templates',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_SHARED_SERVICE_TEMPLATE.SUCCESS,
        data,
      })
    } catch (error) {
      return dispatch({
        type: GET_SHARED_SERVICE_TEMPLATE.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Shared service template failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getSharedServiceTemplateById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SHARED_SERVICE_TEMPLATE_BY_ID.REQUEST })
    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const params = {
      'fields[0]': 'name',
      'fields[1]': 'category',
      'fields[2]': 'type',
      'populate[topics][fields][0]': 'title',
      'populate[document_acknows][fields][0]': 'status',
      'populate[document_acknows][fields][1]': 'version',
      'populate[document_acknows][filters][status][$ne]': 'Draft',
      'populate[document_acknows][filters][creator][company][id]': company_info.id,
    }

    const { data } = await getAPI({
      url: `/api/document-acknow-templates/${id}`,
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_SHARED_SERVICE_TEMPLATE_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_SHARED_SERVICE_TEMPLATE_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Shared service template by id failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDocumentAcknowledge =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_DOCUMENT_ACKNOWLEDGE.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'populate[topics][fields][0]': 'title',
        'populate[creator][fields][0]': 'first_name',
        'populate[creator][fields][1]': 'last_name',
        'populate[category][fields][0]': 'name',
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'sort[updatedAt]': 'desc',
      }
      if (filter.template) params['filters[template][id][0]'] = filter.template
      if (filter.category) params['filters[category][id][$eq]'] = filter.category
      if (filter.status) params['filters[status][$eq]'] = filter.status

      const { data, meta } = await getAPI({
        url: '/api/document-acknows',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_DOCUMENT_ACKNOWLEDGE.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_DOCUMENT_ACKNOWLEDGE.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Document acknowledge failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getDocumentAcknowledgeById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DOCUMENT_ACKNOWLEDGE_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'fields[0]': 'version',
      'fields[1]': 'due_date',
      'fields[2]': 'name',
      'fields[3]': 'type',
      'fields[4]': 'doc_lang',
      'fields[5]': 'is_star',
      'fields[6]': 'status',
      'fields[7]': 'other_topic',
      'populate[parent]': '*',
      'populate[template][fields][0]': 'name',
      'populate[template][fields][1]': 'type',
      'populate[category][fields][0]': 'name',
      'populate[template][populate][topics][fields][0]': 'title',
      'populate[template][populate][document_acknows][fields][0]': 'version',
      'populate[template][populate][document_acknows][filters][status]': 'Active',
      'populate[topics][populate][images]': '*',
      'populate[files]': '*',
    }

    const { data } = await getAPI({
      url: `/api/document-acknows/${id}`,
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_DOCUMENT_ACKNOWLEDGE_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_DOCUMENT_ACKNOWLEDGE_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Document acknowledge by id failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDocumentContent = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DOCUMENT_ACKNOWLEDGE_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'fields[0]': 'version',
      'fields[1]': 'due_date',
      'fields[2]': 'name',
      'fields[3]': 'type',
      'fields[4]': 'doc_lang',
      'fields[5]': 'is_star',
      'fields[6]': 'status',
      'fields[7]': 'other_topic',
      'fields[8]': 'email_noti',
      'fields[9]': 'in_app_noti',
      'populate[template][fields][0]': 'name',
      'populate[template][fields][1]': 'type',
      'populate[template][populate][topics][fields][0]': 'title',
      'populate[template][populate][document_acknows][fields][0]': 'version',
      'populate[template][populate][document_acknows][filters][status]': 'Active',
      'populate[topics][populate][images]': '*',
      'populate[creator][fields][0]': 'first_name',
      'populate[creator][fields][1]': 'last_name',
      'populate[creator][fields][2]': 'photo',
      'populate[category][fields][0]': 'name',
      'populate[template]': '*',
      'populate[files]': '*',
      'populate[assignments][fields][0]': 'opened_date',
      'populate[assignments][fields][1]': 'acknowledged_date',
      'populate[assignments][fields][2]': 'signed_date',
      'populate[assignments][fields][3]': 'status',
      'populate[assignments][populate][employee][fields][0]': 'employ_id',
      'populate[assignments][populate][employee][fields][1]': 'title',
      'populate[assignments][populate][employee][fields][2]': 'first_name',
      'populate[assignments][populate][employee][fields][3]': 'last_name',
      'populate[assignments][populate][employee][fields][4]': 'photo',
      'populate[assignments][populate][employee][populate][department][fields][0]': 'name',
      'populate[assignments][populate][employee][populate][position][fields][0]': 'name',
      'populate[assignments][populate][employee_signature][populate][signature]': '*',
      'populate[assignments][populate][doc_signature]': '*',
    }

    const { data } = await getAPI({
      url: `/api/document-acknows/${id}`,
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_DOCUMENT_ACKNOWLEDGE_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_DOCUMENT_ACKNOWLEDGE_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Document acknowledge by id failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDocumentInviteList = (filter) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DOCUMENT_INVITE_LIST.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    let multipleSearch = {}
    if (filter.multipleSearch) {
      multipleSearch = Object.assign({}, ...filter.multipleSearch)
    }

    const params = {
      ...multipleSearch,
      'populate[employee][fields][0]': 'employ_id',
      'populate[employee][fields][1]': 'title',
      'populate[employee][fields][2]': 'first_name',
      'populate[employee][fields][3]': 'last_name',
      'populate[employee][fields][4]': 'photo',
      'populate[employee][populate][department][fields][0]': 'name',
      'populate[employee][populate][position][fields][0]': 'name',
      'populate[employee][fields][5]': 'email',
      'filters[document_acknow][id]': filter.id,
      'populate[document_acknow][fields][0]': 'type',
      'pagination[page]': filter.page,
      'pagination[pageSize]': filter.pageSize,
    }
    if (filter.status) params['filters[status][$eq]'] = filter.status

    const { data, meta } = await getAPI({
      url: `/api/document-acknow-assignments`,
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_DOCUMENT_INVITE_LIST.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_DOCUMENT_INVITE_LIST.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Document Invite list failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createDocumentAcknowledge = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_DOCUMENT_ACKNOWLEDGEMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: '/api/document-acknows',
      headers: headers,
      data,
    })

    return dispatch({
      type: CREATE_DOCUMENT_ACKNOWLEDGEMENT.SUCCESS,
      showAlert: true,
      alertMessage: 'Create document acknowledge Successful!',
    })
  } catch (error) {
    return dispatch({
      type: CREATE_DOCUMENT_ACKNOWLEDGEMENT.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Create document acknowledge failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createDocumentAcknowledgeWithDraft = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_DOCUMENT_ACKNOWLEDGEMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/document-acknows/${id}`,
      headers: headers,
      data,
    })

    return dispatch({
      type: CREATE_DOCUMENT_ACKNOWLEDGEMENT.SUCCESS,
      showAlert: true,
      alertMessage: 'Create document acknowledge Successful!',
    })
  } catch (error) {
    return dispatch({
      type: CREATE_DOCUMENT_ACKNOWLEDGEMENT.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Create document acknowledge failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const saveDraftDocumentAcknowledge = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const response = await postAPI({
      url: '/api/document-acknows/draft',
      headers: headers,
      data,
    })

    dispatch(
      setFlagDraft({
        draftId: response?.data?.id,
        isDraft: true,
      })
    )

    return dispatch({
      type: SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT.SUCCESS,
      showAlert: true,
      alertMessage: 'Save draft document acknowledge Successful!',
    })
  } catch (error) {
    return dispatch({
      type: SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Save draft document acknowledge failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const duplicateDocumentAcknowledge = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DUPLICATE_DOCUMENT_ACKNOWLEDGE.REQUEST })
    const { jwt, employee_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'fields[0]': 'version',
      'fields[1]': 'due_date',
      'fields[2]': 'name',
      'fields[3]': 'type',
      'fields[4]': 'doc_lang',
      'fields[5]': 'is_star',
      'fields[6]': 'status',
      'fields[7]': 'other_topic',
      'populate[parent]': '*',
      'populate[template][fields][0]': 'name',
      'populate[template][fields][1]': 'type',
      'populate[category][fields][0]': 'name',
      'populate[template][populate][topics][fields][0]': 'title',
      'populate[template][populate][document_acknows][fields][0]': 'version',
      'populate[template][populate][document_acknows][filters][status]': 'Active',
      'populate[topics][populate][images]': '*',
      'populate[files]': '*',
    }

    const { data } = await getAPI({
      url: `/api/document-acknows/${id}`,
      headers: headers,
      params,
    })

    const newDuplicatedAcknowledgeDocument = {
      creator: {
        id: employee_info?.id,
      },
      template: {
        id: data?.template?.id,
      },
      version: data?.version,
      due_date: data?.due_date,
      topics: data?.topics,
      files: data?.files,
      name: `Copy of ${data?.name}`,
      doc_lang: data?.doc_lang,
      is_star: data?.is_star,
      reference: null,
      other_topic: data?.other_topic,
    }

    await postAPI({
      url: '/api/document-acknows/draft',
      headers: headers,
      data: newDuplicatedAcknowledgeDocument,
    })

    return dispatch({
      type: DUPLICATE_DOCUMENT_ACKNOWLEDGE.SUCCESS,
      showAlert: true,
      alertMessage: 'Duplicate document acknowledge Successful!',
    })
  } catch (error) {
    return dispatch({
      type: DUPLICATE_DOCUMENT_ACKNOWLEDGE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Duplicate document acknowledge failed!',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getDocumentAcknowledge())
  }
}

export const updateDraftDocumentAcknowledge = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/document-acknows/${id}/draft`,
      headers: headers,
      data,
    })

    return dispatch({
      type: SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT.SUCCESS,
      showAlert: true,
      alertMessage: 'Save draft document acknowledge Successful!',
    })
  } catch (error) {
    return dispatch({
      type: SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Save draft document acknowledge failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteDocumentAcknowledge = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_DOCUMENT_ACKNOWLEDGE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await deleteAPI({
      url: `/api/document-acknows/${id}`,
      headers: headers,
    })

    return dispatch({
      type: DELETE_DOCUMENT_ACKNOWLEDGE.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete document acknowledge Successful!',
    })
  } catch (error) {
    return dispatch({
      type: DELETE_DOCUMENT_ACKNOWLEDGE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Delete document acknowledge failed!',
    })
  } finally {
    dispatch(getDocumentAcknowledge())
    dispatch(hideLoading())
  }
}

export const setSharedDocumentForm = (data) => (dispatch) => {
  dispatch({ type: SET_DOCUMENT_FORM, data })
}

export const setSharedServiceTemplateInfo = (data) => (dispatch) => {
  dispatch({ type: SET_SHARED_SERVICE_INFO, data })
}

export const setDocumentAcknowledgementInfo = (data) => (dispatch) => {
  dispatch({ type: SET_DOCUMENT_ACKNOWLEDGEMENT_INFO, data })
}

export const setFlagDraft = (data) => (dispatch) => {
  dispatch({ type: SET_FLAG_DRAFT, data })
}

export const addInvite = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: ADD_INVITE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: '/api/document-acknow-assignments',
      headers: headers,
      data,
    })

    return dispatch({
      type: ADD_INVITE.SUCCESS,
      showAlert: true,
      alertMessage: 'Add invite Successful!',
    })
  } catch (error) {
    return dispatch({
      type: ADD_INVITE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Add invite failed!',
    })
  } finally {
    const request = {
      id: data.document_acknow,
      page: 1,
      pageSize: 10,
    }
    dispatch(getDocumentInviteList(request))
    dispatch(getDocumentContent(data.document_acknow))
    dispatch(hideLoading())
  }
}

export const deleteInvite = (id, docId) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_INVITE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await deleteAPI({
      url: `/api/document-acknow-assignments/${id}`,
      headers: headers,
    })

    return dispatch({
      type: DELETE_INVITE.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete invite Successful!',
    })
  } catch (error) {
    return dispatch({
      type: DELETE_INVITE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Delete invite failed!',
    })
  } finally {
    const request = {
      id: docId,
      page: 1,
      pageSize: 10,
    }
    dispatch(getDocumentInviteList(request))
    dispatch(getDocumentContent(docId))
    dispatch(hideLoading())
  }
}

export const sendDocNotification = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: SEND_DOC_NOTIFICATION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: '/api/document-acknow-assignments-resend-noti',
      headers: headers,
      data,
    })

    return dispatch({
      type: SEND_DOC_NOTIFICATION.SUCCESS,
      showAlert: true,
      alertMessage: 'Send document acknowledge notification Successful!',
    })
  } catch (error) {
    return dispatch({
      type: SEND_DOC_NOTIFICATION.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Send document acknowledge notification failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}
