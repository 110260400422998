import { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Button from '../../../components/form/button/Button'

import { useTranslation } from 'react-i18next'
import DropdownSelect from '../../../components/widget/element/DropDownSelect'
import InputTextArea from '../../../components/widget/element/InputTextArea'
import IconPdf from '../../../components/widget/icon/IconPdf'
import IconXClose from '../../../components/widget/icon/IconXClose'
import MediaFile from '../../../containers/cms/MediaFile'
import { updateScopeStatusInMilestone } from '../../../redux/actions/projectTask'

export default function ItemScope({ data, projectName, isPreview = false, onTaskAction }) {
  const { t } = useTranslation()

  const itemDetail = [
    {
      task: 'Identify Target Market',
      due_date: '10 / Oct / 2090',
      assign_to: 'Pichayut Prasertwit',
      status: 'Working',
    },
    {
      task: 'Develop lead generation strategy',
      due_date: '10 / Oct / 2090',
      assign_to: 'Pichayut Prasertwit',
      status: 'Working',
    },
  ]
  const tmpData = {
    project: 'ERP Phase 1',
    to: 'Pichayut Prasertwit',
    milestone: 'สร้าง Lead ให้กับงาน',
    by: 'Somchai Prasertkul',
    due_date: '10 / Oct / 2090',
    task_list: [itemDetail],
  }

  const statusList = [
    // {
    //   text: 'Working',
    //   value: 'Working',
    // },
    // {
    //   text: 'Hold',
    //   value: 'Hold',
    // },
    // {
    //   text: 'Complete',
    //   value: 'Complete',
    // },
    {
      text: t('open'),
      value: 'Open',
    },
    {
      text: t('doing'),
      value: 'Doing',
    },
    {
      text: t('complete'),
      value: 'Complete',
    },
  ]
  const allowTypes = ['images', 'files']

  const dispatch = useDispatch()
  const [files, setFiles] = useState([])
  const [statusSelected, setStatusSelected] = useState('')
  const [summaryText, setSummaryText] = useState('')

  // updateTaskProjectAction
  const handleUpdateScopeAction = () => {
    let tmpScopeId = data?.id

    if (statusSelected != '') {
      let tmpObj
      if (statusSelected == 'Complete') {
        tmpObj = {
          // task: tmpScopeId,
          status: statusSelected,
          summary: summaryText,
          file_id: files[0]?.id,
        }
      } else {
        tmpObj = {
          // task: tmpScopeId,
          status: statusSelected,
          summary: summaryText,
        }
      }
      const data = tmpObj
      const request = { data }
      dispatch(updateScopeStatusInMilestone(tmpScopeId, request)).then(() => {
        if (onTaskAction) {
          onTaskAction()
        }
      })
    }
  }

  const onChangeUploadFile = (value) => {
    setFiles(value)
  }

  const handleRemoveFile = () => {
    setFiles([])
  }

  return (
    <Div>
      <div className="wrp-overview">
        <div className="wrp-inputs-container">
          <div className="wrp-input-row">
            <div className="wrp-input-left">
              <div className="wrp-filed mb-1-rem">
                <div className="title-filed">{t('project')} : </div>
                <div className="text-detail">{data?.milestone?.project_task?.name || '-'}</div>
              </div>
            </div>
            <div className="wrp-input-right">
              <div className="wrp-filed mb-1-rem">
                <div className="title-filed">{t('to')} : </div>
                <div className="text-detail">{data?.assign_to?.first_name + ' ' + data?.assign_to?.last_name}</div>
              </div>
            </div>
          </div>
          <div className="wrp-input-row">
            <div className="wrp-input-left">
              <div className="wrp-filed mb-1-rem">
                <div className="title-filed">{t('miestone')} : </div>
                <div className="text-detail">{data?.task_scope?.name || '-'}</div>
              </div>
            </div>
            <div className="wrp-input-right">
              <div className="wrp-filed mb-1-rem">
                <div className="title-filed">{t('by')} : </div>
                <div className="text-detail">{data?.creator?.first_name + ' ' + data?.creator?.last_name}</div>

              </div>
            </div>
          </div>
          <div className="wrp-input-row">
            <div className="wrp-input-left">
              <div className="wrp-filed mb-1-rem"></div>
            </div>

            <div className="wrp-input-right">
              <div className="wrp-filed mb-1-rem">
                <div className="title-filed">{t('due_date')} : </div>
                <div className="text-detail">{data?.due_date}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="wrp-explain mb-1-rem">
          <div className="title-detail">{t('meeting_details')}</div>

          {data?.milestone_task_types?.length > 0 && (
            <>
              {data?.milestone_task_types?.map((itemTask, index) => (
                <div key={'item-task' + index}>
                  <div className="scope">
                    <div className="left">
                      <div className="detail-scope">
                        <div className="col">
                          <div className="title">{t('task')} :</div>
                          <div className="body-text-detail">{itemTask?.task_type?.name}</div>
                        </div>
                        <div className="col">
                          <div className="title">{t('due_date')} : </div>
                          <div className="body-text-detail">{itemTask?.due_date}</div>
                        </div>
                        <div className="col">
                          <div className="title">{t('assign_to')} : </div>
                          <div className="body-text-detail">
                            {itemTask?.assign_to?.first_name + ' ' + itemTask?.assign_to?.last_name}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <div className="status">
                        {t('status')} : {itemTask?.status}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>
      {!isPreview && (
        <div className="bottom-content">
          <div className="title-bottom-content">{t('scope_summary')}</div>
          <InputTextArea
            className={'input-text-area'}
            width="100%"
            initValue={summaryText}
            onChange={(e) => {
              setSummaryText(e.target.value)
            }}
          />

          <div className="wrp-button-bottom">
            <div>
              {files?.length > 0 && (
                <div className="wrp-file">
                  <div className="btn-delete-file" onClick={() => handleRemoveFile()}>
                    <IconXClose />
                  </div>
                  {files?.map((item, index) => (
                    <div key={'file-' + index} className="wrp-file-item">
                      <IconPdf width="20px" color={'#667085'} />
                      <span>{item?.name}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="wrp-btn-options">
              {statusSelected == 'Complete' && (
                <MediaFile
                  multiple={false}
                  allowedTypes={allowTypes}
                  onChange={onChangeUploadFile}
                  inputForm="Attach File"
                />
              )}
              <DropdownSelect
                className="dropdown"
                dataList={statusList}
                initValue={statusSelected}
                onChange={(e) => setStatusSelected(e)}
              />
              <Button onClick={() => handleUpdateScopeAction()} disabled={statusSelected == '' ? true : false}>
                {t('update')}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Div>
  )
}

const Div = styled.div`
  padding: 0 32px 32px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .mb-1-rem {
    margin-bottom: 16px;
  }
  .w-100 {
    width: 100%;
  }
  .wrp-overview {
    flex: 1;
  }

  .wrp-inputs-container {
    margin-top: 16px;
  }
  .wrp-input-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .wrp-input-left,
  .wrp-input-right {
    width: 100%;
  }
  .wrp-filed {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .title-filed {
    font-weight: bold;
    color: var(--Gray-600);
  }
  .text-detail {
    margin-left: 5px;
  }
  .body-text-detail {
    margin-left: 5px;
  }

  .wrp-explain {
    .title-detail {
      font-weight: bold;
      margin-bottom: 16px;
    }
    .txt-detail {
      flex: 1;
    }
    .txt-detail,
    .wrp-task-requirements {
      margin-left: 1rem;
    }
  }
  .wrp-btn-file {
    /* margin-left: 1rem; */
    display: grid;
    grid-template-columns: 49% 49%;
    grid-gap: 10px;
  }

  //bottom content
  .bottom-content {
    display: flex;
    flex-direction: column;
  }
  .title-bottom-content {
    font-weight: bold;
    font-size: 22px;
  }
  .input-text-area {
    margin: 16px 0;
    border: 1px solid var(--Gray-300);
  }
  .wrp-button-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .wrp-btn-options {
    display: flex;
    align-items: center;
  }
  .wrp-file {
    padding: 10px 25px 10px 16px;
    border: 1px solid #ddd;
    border-radius: 6px;
    position: relative;
    min-width: 180px;
  }
  .wrp-file-item {
    display: flex;
    align-items: center;
  }
  .wrp-file-item span {
    margin-left: 10px;
  }
  .btn-delete-file {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
  }
  .dropdown {
    margin: 0 8px;
  }

  //Scope
  .scope {
    border: 1px solid var(--Gray-300);
    margin-bottom: 16px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    .left {
      display: flex;
      align-items: center;

      .col {
        display: flex;
      }
    }
    .title,
    .status {
      font-weight: bold;
    }
  }
`
