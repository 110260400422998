import * as React from 'react'
import PropTypes from 'prop-types'
import {
  Select as BaseSelect,
  selectClasses,
  Option as BaseOption,
  optionClasses,
  Popper as BasePopper,
} from '@mui/base'
import { styled } from '@mui/system'
import UnfoldMoreRoundedIcon from '@mui/icons-material/UnfoldMoreRounded'

export const Select = React.forwardRef(function CustomSelect(props, ref) {
  const slots = {
    root: Button,
    listbox: Listbox,
    popper: Popper,
    ...props.slots,
  }

  return <BaseSelect {...props} ref={ref} slots={slots} />
})

Select.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popper: PropTypes.func,
    root: PropTypes.elementType,
  }),
}

const Button = React.forwardRef(function Button(props, ref) {
  const { ownerState, ...other } = props
  return (
    <StyledButton type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </StyledButton>
  )
})

Button.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
}

const StyledButton = styled('button', { shouldForwardProp: () => true })(
  ({ theme }) => `
  height: 40px;
  font-family: 'Inter','Noto Sans Thai';
  font-size: 0.875rem;
  box-sizing: border-box;
  min-width: 320px;
  padding: 8px 12px;
  border-radius: 8px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === 'dark' ? 'var(--Gray-900)' : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? 'var(--Gray-700)' : 'var(--Gray-200)'};
  color: var(--Gray-900);
  position: relative;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:not(.${selectClasses.disabled}):hover {
    border-color: var(--Primary-300);
    cursor: pointer;
  }

  &.${selectClasses.focusVisible} {
    outline: 0;
    border-color: var(--Primary-400);
    box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? 'var(--Primary-700)' : 'var(--Primary-200)'};
  }

  &.${selectClasses.disabled} {
    background: var(--Gray-50);
    color: var(--Gray-400);

    & > svg {
      color: var(--Gray-400);
    }
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%;
    top: 0;
    right: 10px;
  }
  `
)

const Listbox = styled('ul')`
  font-family: 'Inter', 'Noto Sans Thai';
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 320px;
  max-height: 380px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: var(--Base-White);
  border: 1px solid var(--Gray-200);
  color: var(--Gray-900);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    background: var(--Gray-200); /* make scrollbar visible on hover and active */
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    opacity: 0.5;
  }

  &:active::-webkit-scrollbar-thumb {
    scale: 1.05 !important;
    background: var(--Gray-500);
    transition: background 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
`

export const Option = styled(BaseOption)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? 'var(--Primary-900)' : 'var(--Primary-100)'};
    color: ${theme.palette.mode === 'dark' ? 'var(--Primary-100)' : 'var(--Primary-900)'};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === 'dark' ? 'var(--Gray-800)' : 'var(--Gray-100)'};
    color: ${theme.palette.mode === 'dark' ? 'var(--Gray-300)' : 'var(--Gray-900)'};
  }

  &:focus-visible {
    outline: 3px solid ${theme.palette.mode === 'dark' ? 'var(--Primary-600)' : 'var(--Primary-200)'};
  }
  
  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === 'dark' ? 'var(--Primary-900)' : 'var(--Primary-100)'};
    color: ${theme.palette.mode === 'dark' ? 'var(--Primary-100)' : 'var(--Primary-900)'};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? 'var(--Gray-700)' : 'var(--Gray-400)'};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? 'var(--Gray-800)' : 'var(--Gray-100)'};
    color: ${theme.palette.mode === 'dark' ? 'var(--Gray-300)' : 'var(--Gray-900)'};
  }
  `
)

const Popper = styled(BasePopper)`
  z-index: 9999;
`
