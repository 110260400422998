import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { capitalizeEachWord } from 'utils/stringHelpers'

export default function Tabs({ tabCounts = { task: 0 }, onChange, defaultTab = 'guideline', items }) {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(defaultTab)

  const tabItems = items || [
    { id: 'guideline', label: capitalizeEachWord(t('guideline')) },
    { id: 'task', label: t('task'), count: tabCounts.task },
    { id: 'delivery', label: capitalizeEachWord(t('delivery')) },
    { id: 'logs', label: t('logs') },
    { id: 'note', label: t('note') },
    { id: 'asset', label: capitalizeEachWord(t('asset')) },
  ]

  useEffect(() => {
    onChange && onChange(activeTab)
  }, [activeTab])

  return (
    <TabsWrapper id="tabs-wrapper">
      {tabItems.map((tab) => (
        <div
          key={tab.id}
          className={`tab-item ${activeTab === tab.id ? 'active' : ''}`}
          onClick={() => setActiveTab(tab.id)}
        >
          {`${tab.label}${tab.count ? ` (${tab.count})` : ''}`}
        </div>
      ))}
    </TabsWrapper>
  )
}

export const TabsWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 1rem;
  position: sticky;
  top: -2px;
  z-index: 99;

  transition: all 100ms ease-out;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White);

  &::-webkit-scrollbar {
    display: none;
  }

  .tab-item {
    display: flex;
    height: 44px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;
    text-wrap: nowrap;

    color: var(--Gray-700, #344054);
    /* Text sm/Semibold */
    font-family: 'Inter', 'Noto Sans Thai';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; /* 142.857% */

    cursor: pointer;
    transition: all 100ms ease-out;

    &.active {
      background: var(--Primary-50);
      border-bottom: 2px solid var(--Primary-600);
      color: var(--Primary-700);
    }

    &:not(.active) {
      &:hover {
        background: var(--Primary-50);
      }
    }
  }
`
