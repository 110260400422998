import { IconButton, Tooltip } from '@mui/joy'
import { Stack, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { forwardRef, useCallback, useEffect } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { RiAddLine, RiDeleteBinLine } from 'react-icons/ri'

import Button from 'components/common/Button'
import DynamicForm from 'components/form2/dynamic-form'
import Input from 'components/form2/form-input'
import { Textarea } from 'components/form2/form-textarea'
import { Autocomplete } from 'components/form2/renderFields'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'

const CreateTaskTPL = forwardRef(function CreateTaskTPL(props, ref) {
  const { onSubmit, id, readonly, containerProps } = props
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)

  const { extra: extraActions, taskTemp: taskTPLActions } = opActions()
  const { departments, taskTemp } = opProcSelector

  const { getExtra } = extraActions()
  const { getTaskTemp } = taskTPLActions()

  const fetchTaskTempByID = async (taskTempId) => {
    return dispatch(getTaskTemp(taskTempId).byId())
  }

  const fetchDepartments = useCallback(
    async (payload) => {
      return dispatch(getExtra().departments(payload))
    },
    [dispatch]
  )

  const defaultValues = {
    name: '',
    description: '',
    department: undefined,
    deliveries: [],
  }

  const methods = useForm({
    defaultValues: async () => {
      if (id) {
        await fetchDepartments()

        const response = await fetchTaskTempByID(id)

        if (response?.data) {
          return response.data
        }

        return defaultValues
      }

      return defaultValues
    },
  })

  useEffect(() => {
    fetchDepartments()
  }, [fetchDepartments])

  const formSchema = {
    properties: {
      'section-1': {
        component: (
          <HeaderSection>
            <TypographyH3>{t('detail')}</TypographyH3>
            <TypographyP>{t('task_tpl_detail_subtitle')}</TypographyP>
          </HeaderSection>
        ),
      },
      'divider-1': { type: 'divider' },
      name: {
        type: 'text',
        label: t('task_tpl_name'),
        placeholder: t('task_tpl_name_placeholder'),
        validator: {
          required: true,
          message: 'This field is required',
          minLength: 1,
          maxLength: 50,
        },
      },
      ['description']: {
        type: 'textArea',
        label: t('task_tpl_guideline'),
        props: {
          minRows: 3,
          maxRows: 5,
          multiline: true,
        },
      },
      ['department']: {
        type: 'controlled-autocomplete',
        viewValue: (value) => value?.name,
        label: t('department'),
        placeholder: t('task_tpl_department_placeholder'),
        props: {
          options: departments?.map((dept) => ({ id: dept.id, name: dept.name })) || [],
          onChange(_event, selectVal) {
            methods.setValue('department', selectVal || undefined)
          },
          getOptionLabel(option) {
            return option.name || option
          },
          ...(id && {
            disabled: true,
          }),
        },
      },
      'divider-2': { type: 'divider' },
      'section-2': {
        component: (
          <HeaderSection>
            <TypographyH3>{t('delivery_subject_task_title')}</TypographyH3>
            <TypographyP>{t('delivery_subject_task_subtitle')}</TypographyP>
          </HeaderSection>
        ),
      },
      deliveries: {
        type: 'fieldArray',
        props: {
          render({ field, index, remove }) {
            const key = `deliveries.${index}`

            return (
              <Draggable
                key={field.fieldId}
                draggableId={`delivery-item-${field.fieldId}`}
                index={index}
                isDragDisabled={readonly}
              >
                {(provided, snapshot) => {
                  const { isDragging } = snapshot || {}

                  return (
                    <ContentList
                      ref={provided.innerRef}
                      className={`${isDragging ? 'dragging' : ''}`}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <section key="name" style={{ marginBottom: readonly ? '0' : '1rem' }}>
                        <label>{t('delivery_name')}:</label>
                        <Stack gap="0.5rem">
                          <Stack direction="row" gap="1rem" alignItems="center">
                            {readonly ? (
                              methods.getValues(`${key}.name`)
                            ) : (
                              <Input
                                {...methods.register(`${key}.name`, { required: `กรุณากรอก${field.label}` })}
                                placeholder={t('delivery_name_placeholder')}
                                onChange={(e) => {
                                  const value = e.target.value
                                  methods.setValue(`${key}.name`, value)
                                }}
                                sx={{ width: '100%' }}
                              />
                            )}
                          </Stack>
                        </Stack>
                      </section>

                      <section key="info" style={{ marginBottom: readonly ? '0' : '1rem' }}>
                        <label>{t('delivery_info')}:</label>
                        <Stack gap="0.5rem">
                          <Stack direction="row" gap="1rem" alignItems="center">
                            {readonly ? (
                              methods.getValues(`${key}.info`)
                            ) : (
                              <Textarea
                                {...methods.register(`${key}.info`, { required: `กรุณากรอก${field.label}` })}
                                placeholder={t('delivery_info_placeholder')}
                                minRows={3} // FIXME: It's not working.
                                maxRows={5} // FIXME: It's not working.
                                sx={{ width: '100%' }}
                                onChange={(e) => {
                                  const value = e.target.value
                                  methods.setValue(`${key}.info`, value)
                                }}
                              />
                            )}
                          </Stack>
                        </Stack>
                      </section>

                      <section key="type" style={{ marginBottom: readonly ? '0' : '1rem' }}>
                        <label>{t('delivery_type')}:</label>
                        <Stack gap="0.5rem">
                          <Stack direction="row" gap="1rem" alignItems="center">
                            {readonly ? (
                              methods.getValues(`${key}.type`)
                            ) : (
                              <Autocomplete
                                {...methods.register(`${key}.type`, { required: `กรุณาเลือก${field.label}` })}
                                disabled
                                defaultValue={'Upload File'} // For Task Template, it's always 'Upload File'.
                                options={['Upload File']}
                                onChange={(val, selectVal) => {
                                  methods.setValue(`${key}.type`, selectVal)
                                }}
                                renderOption={(props, option, { selected }) => (
                                  <AutocompleteOption {...props}>
                                    <p className="title">{`${option}`}</p>
                                  </AutocompleteOption>
                                )}
                                renderInput={(params) => (
                                  <TextField {...params} placeholder={t('delivery_type_placeholder')} />
                                )}
                                sx={{ width: '100%' }}
                              />
                            )}
                          </Stack>
                        </Stack>
                      </section>

                      {!readonly && (
                        <Tooltip title={t('remove_delivery')}>
                          <IconButton
                            className="remove-button"
                            size="sm"
                            onClick={() => {
                              const deliveries = methods.getValues('deliveries')

                              remove(index)
                              methods.setValue(
                                'deliveries',
                                deliveries.filter((_, i) => i !== index)
                              )
                            }}
                          >
                            <RiDeleteBinLine fontSize={16} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ContentList>
                  )
                }}
              </Draggable>
            )
          },
          ...(!readonly && {
            actions({ append }) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    gap: '1rem',
                    marginTop: '1rem',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      append({ name: '', info: '', type: 'Upload File' })
                    }}
                    startIcon={<RiAddLine />}
                  >
                    {t('add_delivery_work')}
                  </Button>
                </Stack>
              )
            },
          }),
        },
      },
    },
    required: !readonly ? ['name', 'department'] : [],
  }

  return (
    <Container sx={{ ...containerProps }}>
      <DynamicForm ref={ref} methods={methods} onSubmit={onSubmit} schema={formSchema} readonly={readonly} />
    </Container>
  )
})

export default CreateTaskTPL

const HeaderSection = styled('section')`
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  margin-top: 1rem;
`

const TypographyH3 = styled('h3')`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  color: var(--Gray-700);
  margin: 0;
`

const TypographyP = styled('p')`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  color: var(--Gray-600);
  margin: 0;
`

const Container = styled('div')`
  height: auto;
  padding: 0 1.25rem 1rem 1.25rem;

  &::-webkit-scrollbar {
    width: 0.75rem;
    background: rgba(0, 0, 0, 0.25); /* make scrollbar invisible by default */
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    opacity: 0.5;
  }

  &:active::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-track {
    background: var(--Base-White);
    border: 1px solid var(--Gray-200);
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    margin: 0 0.5rem;
  }
`

const AutocompleteOption = styled('li')`
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 0 0.75rem !important;
  gap: 0.5rem;

  .title {
    font-size: 13px;
    font-weight: 600;
    line-height: 1.25rem;
  }

  .supporting-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.25rem;
  }
`

const ContentList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  background-color: var(--Base-White);
  padding: 1.5rem 3rem 1rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid var(--Gray-200);
  position: relative;
  transition: all 100ms ease-in-out;

  &.dragging {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.8);
  }

  .remove-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    scale: 0;
    transition: all 100ms ease-in-out;
  }

  &:hover {
    scale: 1.005;

    .remove-button {
      scale: 1;
    }
  }

  @media not all and (min-width: 640px) {
    padding: 1rem 1.5rem 1rem 1.5rem;
  }
`
