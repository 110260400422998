import React, { useEffect, useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { selectDynamicForm } from 'redux/selectors'
import { getLandingPageSchema } from 'redux/actions/dynamicForm'

import styled from 'styled-components'
import { Box } from '@mui/material'
import HeaderPage from 'components/common/HeaderPage'
import DynamicLandingPage from 'components/dynamic-form/DynamicLandingPage'

const Div = styled.div``

function WarehouseManagement() {
  // external hook
  const dispatch = useDispatch()

  // schema landing page
  const { landingPageSchema } = useSelector(selectDynamicForm)

  // initiate data
  const fetchLandingPageSchema = useCallback(
    (name) => {
      dispatch(getLandingPageSchema(name))
    },
    [dispatch]
  )

  useEffect(() => {
    fetchLandingPageSchema('Warehouse')
  }, [])

  return (
    <Div>
      <Box sx={{ mb: 3 }}>
        <HeaderPage heading={landingPageSchema?.title} subHeading={landingPageSchema?.description} />
      </Box>

      <DynamicLandingPage name="Warehouse" />
    </Div>
  )
}

export default WarehouseManagement
