import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_WEEKLY_SUMMARY_REPORTS,
  GET_WEEKLY_SUMMARY_REPORT_BY_ID,
  CREATE_WEEKLY_SUMMARY_REPORT,
  UPDATE_WEEKLY_SUMMARY_REPORT,
  DELETE_WEEKLY_SUMMARY_REPORT,
  GET_PROJECT_TASKS,
  SET_MODAL_INFO,
} from '../actionTypes'

import { showLoading, hideLoading } from './loading'

export const getWeeklySummaryReports =
  (
    filter = {
      page: 1,
      pageSize: 10,
    }
  ) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_WEEKLY_SUMMARY_REPORTS.REQUEST })

      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }

      const params = {
        ...multipleSearch,
        populate: '*',
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        sort: 'createdAt:desc',
        'filters[active][$eq]': true,
      }

      if (filter.project_task !== '') params['filters[project_task][id][$eq]'] = filter.project_task
      if (filter.created_by !== '') params['filters[employee][id][$eq]'] = filter.created_by
      if (filter.selected_date !== '') {
        params['filters[start_date][$lte]'] = filter.selected_date
        params['filters[end_date][$gte]'] = filter.selected_date
      }

      const { data, pagination } = await getAPI({
        url: '/api/get-weekly-summary-reports',
        headers,
        params,
      })

      return dispatch({
        type: GET_WEEKLY_SUMMARY_REPORTS.SUCCESS,
        data,
        pagination,
      })
    } catch (error) {
      return dispatch({
        type: GET_WEEKLY_SUMMARY_REPORTS.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Weekly Summary Reports Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getWeeklySummaryReportById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_WEEKLY_SUMMARY_REPORT_BY_ID.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const data = await getAPI({
      url: `/api/get-weekly-summary-reports/${id}`,
      headers,
    })

    return dispatch({
      type: GET_WEEKLY_SUMMARY_REPORT_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_WEEKLY_SUMMARY_REPORT_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Weekly Summary Report Id Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createWeeklySummaryReport = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_WEEKLY_SUMMARY_REPORT.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: '/api/create-weekly-summary-reports',
      data,
      headers,
    })

    return dispatch({
      type: CREATE_WEEKLY_SUMMARY_REPORT.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Weekly Summary Report Successful',
    })
  } catch (error) {
    const errorCase = error?.response?.data?.error?.details

    return dispatch({
      type: CREATE_WEEKLY_SUMMARY_REPORT.FAILURE,
      error: error,
      errorCase,
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getWeeklySummaryReports())
  }
}

export const updateWeeklySummaryReport = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_WEEKLY_SUMMARY_REPORT.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/edit-weekly-summary-reports/${id}`,
      data,
      headers,
    })

    return dispatch({
      type: UPDATE_WEEKLY_SUMMARY_REPORT.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Weekly Summary Report Successful',
    })
  } catch (error) {
    const errorCase = error?.response?.data?.error?.details

    return dispatch({
      type: UPDATE_WEEKLY_SUMMARY_REPORT.FAILURE,
      error: error,
      errorCase,
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getWeeklySummaryReports())
  }
}

export const deleteWeeklySummaryReport = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_WEEKLY_SUMMARY_REPORT.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/edit-weekly-summary-reports/${id}`,
      data,
      headers,
    })

    return dispatch({
      type: DELETE_WEEKLY_SUMMARY_REPORT.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Weekly Summary Report Successful',
    })
  } catch (error) {
    return dispatch({
      type: DELETE_WEEKLY_SUMMARY_REPORT.FAILURE,
      error: error,
      showAlert: true,
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getWeeklySummaryReports())
  }
}

export const getProjectTasks = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PROJECT_TASKS.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'pagination[page]': 1,
      'pagination[pageSize]': 1000,
      'fields[0]': 'id',
      'fields[1]': 'name',
      sort: 'createdAt:desc',
    }

    const { results } = await getAPI({
      url: '/api/get-project-tasks',
      headers,
      params,
    })

    return dispatch({
      type: GET_PROJECT_TASKS.SUCCESS,
      data: results,
    })
  } catch (error) {
    return dispatch({
      type: GET_PROJECT_TASKS.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Project Tasks Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const setModalInfo = (data) => (dispatch) => {
  dispatch({ type: SET_MODAL_INFO, data })
}
