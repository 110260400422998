import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import ContentForm from './ContentForm'
import { getCmsContentConfig, getCmsItemDetail, updateCmsContent } from '../../redux/actions/cms'
import { selectCmsContentType, selectCmsByType } from '../../redux/selectors'

const Div = styled.div`
  .content-title {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
    margin-bottom: 24px;
  }
`

const ContentEditForm = ({ type }) => {
  const { id } = useParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { info } = useSelector(selectCmsContentType(type))
  const { config } = useSelector(selectCmsByType(type))
  const [data, setData] = useState(null)

  const { displayName } = info || {}
  const { contentType } = config || {}
  const { uid } = contentType || {}

  const goToContentList = () => {
    navigate(pathname.replace(`/${id}/edit`, ''), {
      replace: true,
    })
  }

  const updateContent = (data) => {
    dispatch(updateCmsContent(uid, id, data))
  }

  useEffect(() => {
    if (!config) {
      dispatch(getCmsContentConfig(type))
    } else {
      dispatch(getCmsItemDetail(type, id)).then(({ data }) => data && setData(data))
    }
  }, [config, dispatch, id, type])

  return (
    <Div>
      <div className="content-title">Edit {displayName}</div>
      {data && (
        <ContentForm
          type={type}
          defaultValues={data}
          submitText="Update"
          onSubmit={updateContent}
          onCancel={goToContentList}
        />
      )}
    </Div>
  )
}

export default ContentEditForm
