import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useTable, useSortBy } from 'react-table'
import PaginationV3 from './PaginationV3'
import Loading from './Loading'
import PropTypes from 'prop-types'

const TableV3 = ({
  columns = [],
  data = [],
  onStateChange = () => {},
  enabledSort = false,
  initSort = {},
  pageCount = 0,
  loading = false,
  page,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, state } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: enabledSort
          ? [
              {
                id: initSort?.id,
                desc: initSort?.order === 'DESC',
              },
            ]
          : [],
      },
      disableSortBy: !enabledSort,
      manualSortBy: true,
      disableMultiSort: true,
      disableSortRemove: true,
    },
    useSortBy
  )

  const { sortBy } = state

  useEffect(() => {
    if (sortBy.length) {
      const { id, desc } = sortBy[0]
      onStateChange({ sort: { id, order: desc ? 'DESC' : 'ASC' } })
    }
  }, [onStateChange, sortBy])

  return (
    <Div>
      <TableDiv>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={JSON.stringify(headerGroup)} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const isSortedDesc = column.isSortedDesc ? ' ↓' : ' ↑'
                  return (
                    <th
                      key={column.id}
                      className={`col-${column.id}`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      {<span>{column.isSorted ? isSortedDesc : ''}</span>}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <TableBody
            prepareRow={prepareRow}
            headerGroups={headerGroups}
            rows={rows}
            loading={loading}
            {...getTableBodyProps()}
          />
        </table>
      </TableDiv>

      <PaginationV3
        className="pagination"
        pageCount={pageCount}
        page={page}
        onPageChange={(page) => onStateChange({ page })}
      />
    </Div>
  )
}

TableV3.propTypes = {
  columns: PropTypes.any,
  data: PropTypes.any,
  onStateChange: PropTypes.func,
  enabledSort: PropTypes.bool,
  initSort: PropTypes.func,
  pageCount: PropTypes.number,
  loading: PropTypes.bool,
  page: PropTypes.number,
}

export default TableV3

const Div = styled.div`
  width: 100%;
  position: relative;

  .content-wrapper {
    text-align: center;
  }

  .pagination {
    display: flex;
    width: 100%;
    padding: 1rem;
    border: 1px solid var(--Gray-200);
    border-top: none;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;

    position: sticky;
    background: var(--Base-White);
    bottom: 0;
    left: 0;
    right: 0;

    @media screen and (min-width: 768px) {
      padding: 1rem 1.5rem;
    }
  }
`

const TableDiv = styled.div`
  overflow-x: auto;
  border: 1px solid var(--Gray-200);
  border-top: none;
  border-bottom: none;

  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: var(--Shadow-xs);

    tr {
      border: 1px solid var(--Gray-200);
      border-left: none;
      border-right: none;
    }

    th,
    td {
      padding: 16px 14px;
      text-align: left;
      font-size: 14px;
      color: var(--Table-Font-Default);
      white-space: nowrap;
      background: var(--Table-Bg-Default);
    }

    th {
      padding: 12x 14px;
      font-weight: 500;
      background: var(--Gray-50);
    }

    tr:hover {
      td {
        background: var(--Table-Bg-Hover);
        color: var(--Table-Font-Hover);
      }
    }
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--Gray-200);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: var(--Base-White);
  }
`

const TableBody = ({ prepareRow, headerGroups, rows, loading, ...props }) => {
  if (loading) {
    return (
      <tbody {...props}>
        <tr>
          <td colSpan={headerGroups[0].headers.length}>
            <div className="content-wrapper">
              <Loading />
            </div>
          </td>
        </tr>
      </tbody>
    )
  }

  return (
    <tbody {...props}>
      {rows?.length ? (
        rows.map((row) => {
          prepareRow(row)
          return (
            <tr key={row.id} {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td key={cell.column.id} className={`col-${cell.column.id}`} {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })
      ) : (
        <tr>
          <td colSpan={headerGroups[0].headers.length}>
            <div className="content-wrapper">No data</div>
          </td>
        </tr>
      )}
    </tbody>
  )
}

TableBody.propTypes = {
  prepareRow: PropTypes.any,
  headerGroups: PropTypes.any,
  rows: PropTypes.any,
  loading: PropTypes.any,
}
