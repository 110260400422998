export default function IconBin({ width = '37', color = '#667085', style }) {
  return (
    <svg width={width} viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g clipPath="url(#clip0_7693_8520)">
        <path
          d="M21.3333 13.9491V13.2824C21.3333 12.349 21.3333 11.8823 21.1517 11.5257C20.9919 11.2121 20.7369 10.9572 20.4233 10.7974C20.0668 10.6157 19.6001 10.6157 18.6667 10.6157H17.3333C16.3999 10.6157 15.9332 10.6157 15.5767 10.7974C15.2631 10.9572 15.0081 11.2121 14.8483 11.5257C14.6667 11.8823 14.6667 12.349 14.6667 13.2824V13.9491M16.3333 18.5324V22.6991M19.6667 18.5324V22.6991M10.5 13.9491H25.5M23.8333 13.9491V23.2824C23.8333 24.6825 23.8333 25.3826 23.5608 25.9174C23.3212 26.3878 22.9387 26.7702 22.4683 27.0099C21.9335 27.2824 21.2335 27.2824 19.8333 27.2824H16.1667C14.7665 27.2824 14.0665 27.2824 13.5317 27.0099C13.0613 26.7702 12.6788 26.3878 12.4392 25.9174C12.1667 25.3826 12.1667 24.6825 12.1667 23.2824V13.9491"
          stroke={color}
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7693_8520">
          <rect width="20" height="20" fill="white" transform="translate(8 8.94922)" />
        </clipPath>
      </defs>
    </svg>
  )
}
