import { Divider, IconButton, Stack, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiDeleteBinLine, RiDownloadLine, RiEyeLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import TableV3 from 'components/common/TableV3'
import Typography from 'components/common/Typography'
import Tabs from 'components/feature/operation-process/tabs'
import FileUpload from 'components/form2/form-file-upload'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import SearchBar from './filter-form'
import tableColumns from './table-columns'

const ActionCell = ({ items = [] }) => {
  return (
    <Stack direction="row" sx={{ gap: '0.5rem' }}>
      {items.map((item, index) => (
        <Tooltip key={index} title={item?.text}>
          <IconButton onClick={item?.action} size="small">
            {item?.icon}
          </IconButton>
        </Tooltip>
      ))}
    </Stack>
  )
}

const FileSection = () => {
  const { t } = useTranslation()
  const refSearchForm = useRef()
  const { id } = useParams()
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)

  const { chainLink: clActions, extra: extraActions } = opActions()
  const { getCL, postCL } = clActions()
  const { deleteExtra } = extraActions()
  const { chainLink_files, chainLink_files_pagination } = opProcSelector
  const { chainLink_deliveries, chainLink_deliveries_pagination } = opProcSelector
  const { page, pageCount, pageSize, total } = chainLink_files_pagination
  const {
    page: deliveryPage,
    pageCount: deliveryPageCount,
    pageSize: deliveryPageSize,
    total: deliveryTotal,
  } = chainLink_deliveries_pagination

  const [fileFilters, setFileFilters] = useState({})
  const [tableType, setTab] = useState('delivery')
  const [tableProps, setTableProps] = useState({})

  const fetchCLLogs = async (spId) => {
    dispatch(getCL().logs(spId))
  }

  const fetchCLFiles = useCallback(
    async (payload) => {
      dispatch(getCL().files(id, payload))
    },
    [dispatch]
  )

  const fetchCLDeliveryFiles = useCallback(
    async (payload) => {
      dispatch(getCL().deliveries(id, payload))
    },
    [dispatch]
  )

  const actionOptions = (fileUrl, fileName, fileId) => {
    const isDelivery = tableType === 'delivery'

    return [
      {
        icon: <RiEyeLine />,
        text: t('preview'),
        action: () => {
          window.open(fileUrl, '_blank')
        },
      },
      {
        icon: <RiDownloadLine />,
        text: t('download'),
        action: () => {
          fetch(fileUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = fileName
              a.click()

              window.URL.revokeObjectURL(url) // free up storage--no longer needed.
            })
            .catch((error) => console.error(error))
        },
      },
      {
        icon: <RiDeleteBinLine />,
        text: t('delete'),
        action: () => {
          const deleteAction = isDelivery ? deleteExtra().delivery : deleteExtra().file

          dispatch(deleteAction(fileId)).then((res) => {
            if (res.type.endsWith('_SUCCESS') && !res.error) {
              isDelivery ? fetchCLDeliveryFiles() : fetchCLFiles()
            }
          })
        },
      },
    ]
  }

  const transformCLDeliveryFilesToTable = (data) => {
    if (!data || data.length === 0) {
      console.warn('No data provided or data is empty.')
      return []
    }

    const mapDataItem = (item, index) => {
      const actions = <ActionCell items={actionOptions(item?.file?.url, item?.name, item?.id)} />
      const task = item.task?.name || '-'
      const sw = item.sw?.name || '-'
      const no = (page - 1) * pageSize + index + 1

      return {
        ...item,
        no,
        task,
        actions,
        sw,
      }
    }

    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const transformCLFilesToTable = (data) => {
    if (!data || data.length === 0) {
      console.warn('No data provided or data is empty.')
      return []
    }

    const mapDataItem = (item, index) => {
      const actions = <ActionCell items={actionOptions(item?.file?.url, item?.name, item?.id)} />
      const task = item.task?.name || '-'
      const sw = item.sw?.name || '-'
      const no = (page - 1) * pageSize + index + 1

      return {
        ...item,
        no,
        task,
        actions,
        sw,
      }
    }

    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const handleUploadFiles = async (files) => {
    if (files) {
      await Promise.all(
        files.map((file) => {
          const objData = {
            file: {
              id: file.id,
            },
          }

          return dispatch(postCL().file(id, objData))
            .then((res) => {
              const { data, type } = res

              if (type.endsWith('_SUCCESS') && !res.error) {
                return res
              }
            })
            .catch((error) => {
              console.error('Error uploading file:', error)
            })
        })
      )

      fetchCLFiles()
      fetchCLLogs(id)
    }
  }

  const handleFilters = (values) => {
    const keys = Object.keys(values)

    if (keys.length === 0) {
      setFileFilters({})
      return fetchCLFiles()
    }

    let filters = {}
    keys.forEach((key) => {
      if (!values[key]) {
        return
      }

      switch (key) {
        case 'name':
          filters = { ...filters, name: { $contains: values[key] } }
          break
        case 'sow':
          filters = { ...filters, sw: { id: { $eq: values[key] } } }
          break
        default:
          break
      }
    })

    if (Object.keys(filters).length === 0) {
      setFileFilters({})
      return fetchCLFiles()
    }

    setFileFilters(filters)
    return fetchCLFiles({ filters })
  }

  const handleTabChange = (tabValue) => {
    setTab(tabValue)

    switch (tabValue) {
      case 'delivery':
        return fetchCLDeliveryFiles()

      case 'asset':
        return fetchCLFiles()

      default:
        return
    }
  }

  const getTableProps = () => {
    let resultProps = {}

    switch (tableType) {
      case 'delivery':
        resultProps = {
          data: transformCLDeliveryFilesToTable(chainLink_deliveries),
          page: deliveryPage || 1,
          pageCount: deliveryPageCount || 0,
          onStateChange({ page }) {
            const updatedPagination = { ...chainLink_deliveries_pagination, page }
            fetchCLDeliveryFiles({ pagination: updatedPagination, filters: { ...fileFilters } })
          },
        }
        break

      case 'asset':
        resultProps = {
          data: transformCLFilesToTable(chainLink_files),
          page: page || 1,
          pageCount: pageCount || 0,
          onStateChange({ page }) {
            const updatedPagination = { ...chainLink_files_pagination, page }
            fetchCLFiles({ pagination: updatedPagination, filters: { ...fileFilters } })
          },
        }
        break

      default:
        break
    }

    return setTableProps(resultProps)
  }

  useEffect(() => {
    getTableProps()
  }, [chainLink_files, chainLink_deliveries, tableType])

  return (
    <Stack>
      <Tabs>
        <Tabs.FileSection onChange={handleTabChange} tabCounts={{ delivery: deliveryTotal, asset: total }} />
      </Tabs>

      <TableContainer>
        {/* Header section */}
        <TableHeader>
          <Stack direction="row" sx={{ width: '100%', padding: '1rem 1.5rem', justifyContent: 'space-between' }}>
            <Stack direction="row" sx={{ width: '100%' }}>
              <Stack spacing="4px">
                <Typography variant="tableTitle">{t(tableType)}</Typography>
                <Typography variant="tableSubtitle">
                  {tableType === 'delivery' ? t('delivery_table_subtitle') : t('chain_link_asset_table_subtitle')}
                </Typography>
              </Stack>
            </Stack>

            <ButtonGroup>
              <FileUpload id="sp-upload-btn" onUploadCallback={handleUploadFiles} />
            </ButtonGroup>
          </Stack>
          <Divider sx={{ borderColor: 'var(--Gray-200)' }} />

          {/** Search & Filter section. */}
          <SearchBar ref={refSearchForm} onFormChange={handleFilters} />
        </TableHeader>

        {/* Body section */}
        <TableV3 {...tableProps} columns={tableColumns(tableType)} />
      </TableContainer>
    </Stack>
  )
}

export default FileSection

/* TODO: This code is repeated in multiple places */
const ButtonGroup = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignSelf: 'center',
  gap: '0.5rem',
})

/* TODO: This code is repeated in multiple places */
const TableContainer = styled(Stack)`
  width: 100%;
  border-radius: 0.5rem;
  background: var(--Base-White);
`

/* TODO: This code is repeated in multiple places */
const TableHeader = styled(Stack)({
  width: '100%',
  border: '1px solid var(--Gray-200)',
  borderBottom: 'none',
  borderTopLeftRadius: '.5rem' /* 8px */,
  borderTopRightRadius: '.5rem' /* 8px */,
})
