import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { getMeetingAttendee,getMeetingAttendeeReturnData,sendtEmail,updateMeeting,updateMeetingAttendances } from '../../redux/actions/dailysummary'
import { selectDailySummary } from '../../redux/selectors'
import { DateDisplay,TimeDisplayHHmm,DateTimeHHmmDisplay,DateTimeHHmmssDisplay } from '../../utils/common'

import SearchText from '../../components/form/SearchText'
import { v4 as uuidv4 } from 'uuid'
import { LiaTimesSolid } from 'react-icons/lia'
import Button from '../../components/form/button/Button'
import XLSX from "xlsx";
import SidePanel from '../../components/common/SidePanel'
import ModalAttendances from '../../pages/my-calendar/ModalAttendances'
import Checkbox from '../../components/form/Checkbox'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import PaginationV2 from '../../components/common/PaginationV2'
import iconEdit from '../../assets/images/icon-edit.svg'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import ModalEditAttendances from '../../pages/my-calendar/ModalEditAttendances'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'
import { useTranslation } from 'react-i18next'
const DivMain = styled.div`
color: var(--Gray-600);
font-size: 14px;
font-style: normal;
font-weight: 500;
.content-fillter-bg{
    min-height: 68px;
    background-color: var(--Gray-50);
    border-radius: 8px;
    margin-bottom: 21px;
    padding: 10px;
}
.content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;


    .search-wrapper {
        margin-right: 12px;
    }

    .filter-list {
        flex-basis: 100%;
        margin-top: 24px;
    }

    .group_filter {
        display: flex;
        align-items: center;
    }
}
.button_clear_all{
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
}
.badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 10px;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
.table-wrapper {
    margin-top: 24px;
    border: 1px solid #EAECF0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #FFFFFF;

    .table-header {
        
        display: flex;
        justify-content: space-between;
        
        padding: 0px 14px;
        padding-top: 16px;
        border-radius: 8px 8px 0 0;
        background: #FFFFFF;

        .group {
            display: flex;
            align-items: center;
        }

        .table-title {
            font-size: 18px;
            font-weight: 600;
            color: var(--Gray-900);
        }

        .table-total {
            margin-left: 16px;
            font-size: 12px;
            font-weight: 500;
            padding: 2px 8px;
            color: var(--Primary-700);
            background: var(--Primary-50);
            border-radius: 16px;
            border: 1px var(--Primary-200) solid;
        }
    }

    .create-bt {
        text-decoration: none;
    }
} 
.button_Excel{
    color: #000000;
    background-color: #FFFFFF;
    border: 0px solid #EAECF0;
    box-shadow: none;
}
.margin_right_5{
    margin-right: 5px;
}
.button_Edit{
    color: #6941C6;
    background-color: #F9F5FF;
    border: 1px solid #EAECF0;
}

.button_Cancel{
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
    margin-right: 1rem;
}
.css_header_detail {
    font-size: 14px;
    color: #667085;
    padding: 5px 14px;
} 
`

const DivTableAtten = styled.div`
  width: 100%;
  overflow-x: auto;
  .text_css{
    min-width: 200px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: var(--Shadow-xs);

    tr {
      border: 1px solid var(--Gray-200);
    }

    th,
    td {
      padding: 16px 14px;
      text-align: left;
      font-size: 14px;
      color: var(--Gray-600);
      white-space: nowrap;
      background: var(--Base-White);
    }

    th {
      padding: 12x 14px;
      font-weight: 500;
      background: var(--Gray-50);
    }

    tr:hover {
      td {
        background: var(--Table-Bg-Hover);
        color: var(--Table-Font-Hover);
      }
    }
  }

  .content-wrapper {
    text-align: center;
  }

  .pagination {
    margin: 30px 24px;
  }

  .cursor_pointer {
    cursor: pointer;
  }
  .col-checkbox{
    position: sticky;
    left: 0;
    z-index: 50;
  }
  .col-no{
    position: sticky;
    left: 70px;
    z-index: 50;
  }
  .col-full_name{
    position: sticky;
    left: 120px;
    z-index: 50;
  }

  .detail-checkbox{
    position: sticky;
    left: 0;
    z-index: 50;
  }
  .detail-no{
    position: sticky;
    left: 70px;
    z-index: 50;
  }
  .detail-full_name{
    position: sticky;
    left: 120px;
    z-index: 50;
  }

`

const TabMeetingAttendee = ({ this_meeting_id }) => {
    const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const fetchDataAttendee = useCallback(
        async (filter) => {
        dispatch(getMeetingAttendee(filter))
        },
        [dispatch]
    )
    useEffect(() => {
        
        const filter = {
        skip: 1,
        limit: 10,
        meeting_id : this_meeting_id
        }
        fetchDataAttendee(filter)
    }, [fetchDataAttendee])

    const { MeetingAttendeeList,limit, total, skip } = useSelector(selectDailySummary)
    const [page, setPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0)
    const [search, setSearch] = useState('')
    const [searchFilters, setSearchFilters] = useState([])
    const onSearch = () => {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const filter = {
        skip: 1,
        limit: limit,
        meeting_id : this_meeting_id,
        other_search:[...searchFilters, newFilter].map((v) => v.name).join(','),
      }
      fetchDataAttendee(filter)
      setPage(1)
      setSearchFilters((prev) => [...prev, newFilter])
    }
    const deleteSearchBadge = (key) => {
      const newSearchFilters = searchFilters.filter((v) => v.key != key)
      setSearchFilters(newSearchFilters)
      
      const filter = {
        skip: 1,
        limit: limit,
        meeting_id : this_meeting_id,
        other_search: newSearchFilters.map((v) => v.name).join(',')
      }
      fetchDataAttendee(filter)
      setSearch('')
      setPage(1)
      
    }
    const onClearSearch = () => {
      const filter = {
        skip: 1,
        limit: limit,
        meeting_id : this_meeting_id,
      }
      fetchDataAttendee(filter)
      setSearch('')
      setPage(1)
    }
    const onClearAllSearch = () => {
      setSearchFilters([])
      const filter = {
        skip: 1,
        limit: limit,
        meeting_id : this_meeting_id,
      }
      fetchDataAttendee(filter)
      setSearch('')
      setPage(1)
    }

    const onChangePagination = async (value) => {
        
        const filter = {
          skip: value.page,
          limit: limit,
          meeting_id : this_meeting_id
        }
        fetchDataAttendee(filter)
        setPage(value.page)
      }

      useEffect(() => {
        const totalPage = Math.ceil(total / limit)
        setTotalPages(totalPage)
      }, [total, skip, limit])
      
    const [EditAttendee, setEditAttendee] = useState(false)

    const loadExcel = () => {
        
        const filter = {
            skip: 1,
            limit: 10000,
            meeting_id : this_meeting_id
        }
        dispatch(getMeetingAttendeeReturnData(filter)).then(({ type,data_info,data_meta }) => {
            if(type.endsWith('_SUCCESS')){
                if(data_meta){
                    const resultToxlsx = data_info.map((v,index) => {
                        return {
                            "No." : index + 1,
                            "Full Name" : v.full_name,
                            "Department" : v.department,
                            "Position in meeting" : v.position ? v.position : '-',
                            "Company" : v.company ? v.company : '-',
                            "Type" : v.employee_id ? 'Employee' : 'Non-Employee',
                            "Email address" : v.email ? v.email : '-',
                            "Tel" : v.phone_no ? v.phone_no : '-',
                            "Response" : v.response ? v.response : 'Waiting for response',
                            "Email Status" : v.email_date_sent ? 'send' : 'not send',
                            "Email Sent" : v.email_date_sent ? DateTimeHHmmDisplay(v.email_date_sent) : '-',
                            "Actual Response" :v.actual_attend ?  v.actual_attend : '-',
                            "Note" : v.note ? v.note : '',
                            "Full Name (Delegate)" : v.delegate_full_name ? v.delegate_full_name : '',
                        };
                    });
                    const dataWS = XLSX.utils.json_to_sheet(resultToxlsx);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, dataWS);
                    XLSX.writeFile(wb, "MeetingAttendee.xlsx");
                }
            }
        })
    }

    const [WidthMeetingCalendar, setWidthMeetingCalendar] = useState('1400')
    const [onModalAttendances, setonModalAttendances] = useState(false)
    const onOpenGuest = () => {
      setWidthMeetingCalendar(window.innerWidth - 80)
      setonModalAttendances(true)
    }
    const CloseModalAttendances = () => {
      setonModalAttendances(false)
    }
    const SubmitModalAttendances = () => {
      setonModalAttendances(false)
      onClearAllSearch()
    }

    const [Titlesuccess, setTitlesuccess] = useState('')
    const [SubTitlesuccess, setSubTitlesuccess] = useState('')
    const [Descriptionsuccess, setDescriptionsuccess] = useState('')
    const [successModal, setsuccessModal] = useState(false)
    const [TitleFail, setTitleFail] = useState('')
    const [nameItemFail, setnameItemFail] = useState('')
    const [DescriptionFail, setDescriptionFail] = useState('')
    const [failModal, setFailModal] = useState(false)
    const [SentMailMeeting_attendance_id, setSentMailMeeting_attendance_id] = useState([])
    const onSendEmail = () => {
      if(SentMailMeeting_attendance_id.length > 0){
          let sent_data_api = {
              data: {
                  meeting_id:this_meeting_id,
                  meeting_attendance_id : SentMailMeeting_attendance_id
              },
          }
          dispatch(sendtEmail(sent_data_api)).then(({ type , data_info }) => {
              if(type.endsWith('_SUCCESS')){
                  setTitlesuccess(t('send_email_success'));
                  setSubTitlesuccess('');
                  setDescriptionsuccess(''); 
                  setsuccessModal(true)
                  onClearAllSearch()
              }else{
                  setTitleFail(t('send_email_error'));
                  setnameItemFail('');
                  setDescriptionFail(''); 
                  setFailModal(true)
              }
          })
      }else{
          setTitleFail(t('please_select_the_person_you_want_to_send_to_email'));
          setnameItemFail('');
          setDescriptionFail(''); 
          setFailModal(true)
      }
    } 
    const TableColumn = [
        {
             Header: t('checkbox'),
             accessor: 'checkbox',
             disableSortBy: false,
       },
       {
         Header: t('no'),
         accessor: 'no',
         disableSortBy: false,
       },
       {
         Header: t('full_name'),
         accessor: 'full_name',
         disableSortBy: false,
       },
       {
         Header: t('department'),
         accessor: 'Department',
         disableSortBy: false,
       },
       {
         Header: t('position_in_meeting'),
         accessor: 'Position',
         disableSortBy: false,
       },
       {
         Header: t('company'),
         accessor: 'Company',
         disableSortBy: false,
       },
       {
         Header: t('type'),
         accessor: 'type_attendee',
         disableSortBy: false,
       },
       {
         Header: t('email_address'),
         accessor: 'email',
         disableSortBy: false,
       },
       {
         Header: t('tel'),
         accessor: 'phone_no',
         disableSortBy: false,
       },
       {
         Header: t('response'),
         accessor: 'Response',
         disableSortBy: false,
       },
       {
        Header: t('full_name_delegate'),
        accessor: 'delegate_full_name',
        disableSortBy: false,
      },
       {
         Header: t('email_status'),
         accessor: 'Email_Status',
         disableSortBy: false,
       },
       {
         Header: t('email_sent'),
         accessor: 'Email_Sent',
         disableSortBy: false,
       },
       {
         Header: t('actual_response'),
         accessor: 'Actual_Response',
         disableSortBy: false,
       },
       {
         Header: t('note'),
         accessor: 'Note',
         disableSortBy: false,
       },
       {
         accessor: 'buttonDelete',
         disableSortBy: true,
       },
       {
         accessor: 'linkEdit',
         disableSortBy: true,
       },
     ]
    const [AttendanceCellData ,setAttendanceCellData] = useState([])
    
    useEffect(() => {
        const newTableCellDataAttendance = MeetingAttendeeList.map((v, index) => ({
            isChecked : false,
            id : v.id,
            full_name : v.full_name,
            department : v.department,
            position_display : v.position,
            position : v.position,
            company_display : v.company,
            company : v.company,
            type_attendee : v.employee_id ? 'Employee' : 'Non-Employee',
            email : v.email,
            phone_no : v.phone_no,
            response_display : v.response,
            response : v.response,
            Email_Status : v.email_date_sent ? 'send' : 'not send',
            Email_Sent : v.email_date_sent ? DateTimeHHmmDisplay(v.email_date_sent) : '-',
            actual_attend_display : v.actual_attend,
            actual_attend : v.actual_attend,
            note_display : v.note,
            note : v.note,
            delegate_full_name : v.delegate_full_name ? v.delegate_full_name : '-',
          }))
        setAttendanceCellData(newTableCellDataAttendance)
      }, [MeetingAttendeeList])

      const [isSelectAll, setIsSelectAll] = useState(false)
      const onChangeSelectAllCheckBox = () => {
        if(isSelectAll){
            setIsSelectAll(false)
            setSentMailMeeting_attendance_id([])
        }else{
            setIsSelectAll(true)
            const filter = {
                skip: 1,
                limit: 10000,
                meeting_id : this_meeting_id
            }
            dispatch(getMeetingAttendeeReturnData(filter)).then(({ type,data_info,data_meta }) => {
                if(type.endsWith('_SUCCESS')){
                    if(data_meta){
                        const data_check_box = data_info.map((v) => (v.id))
                        setSentMailMeeting_attendance_id(data_check_box)
                    }
                }
            })
        }
      }
      const handleChangeCheckBox = (e,v_inp,index) => {
        if(SentMailMeeting_attendance_id.filter((v) => v == v_inp.id).length > 0){
            setSentMailMeeting_attendance_id(SentMailMeeting_attendance_id.filter((v) => v !== v_inp.id))
        }else{
            setSentMailMeeting_attendance_id(current => [...current, v_inp.id]); 
        }             
      }

      const onChangeTextGuest = (index_item, e,type_inp) => {
        const newState = AttendanceCellData.map((obj, index) => {
          // 👇️ if id equals 2, update country property
          if (index === index_item) {
            if(type_inp == 'full_name'){
                return { ...obj, full_name: e.target.value }
            }else if(type_inp == 'company'){
                return { ...obj, company: e.target.value }
            }else if(type_inp == 'position'){
                return { ...obj, position: e.target.value }
            }else if(type_inp == 'email'){
                return { ...obj, email: e.target.value }
            }else if(type_inp == 'phone_no'){
                return { ...obj, phone_no: e.target.value }
            }else if(type_inp == 'response'){
                return { ...obj, response: e}
            }else if(type_inp == 'actual_attend'){
                return { ...obj, actual_attend: e }
            }else if(type_inp == 'note'){
                return { ...obj, note: e.target.value }
            }
            
          }
          return obj
        })
    
        setAttendanceCellData(newState)
      }     
      
    const response_option = [
        { text: 'Waiting for response', value: 'Waiting for response' },
        { text: 'yes, join in meeting', value: 'yes, join in meeting' },
        { text: 'yes, join virtually', value: 'yes, join virtually' },
        { text: 'Yes, by someone else', value: 'Yes, by someone else' },
        { text: 'no', value: 'no' },
    ]
    const actual_attend_option = [
        { text: 'Attend', value: 'Attend' },
        { text: 'Not Attend', value: 'Not Attend' },
    ]
    const [Meeting_attendances_id, setMeeting_attendances_id] = useState('')
    const [ModalConfirmDel, setModalConfirmDel] = useState(false)
    const DelData = (data) => {
        setMeeting_attendances_id(data)
        setModalConfirmDel(true)
    }

    const SubmitModalConfirmDel = () => {
        setModalConfirmDel(false)
        const sent_data_api = {
          data: { active: 0 },
        }
        dispatch(updateMeetingAttendances(Meeting_attendances_id, sent_data_api)).then(({ type }) => {
            if(type.endsWith('_SUCCESS')){
                onClearAllSearch()
            }
        })
        
       
    }
    const [DataEdit, setDataEdit] = useState('')
    const [ModalEditData, setModalEditData] = useState(false)
    const EditData = (data) => {
        setDataEdit(data)
        setModalEditData(true)
    }
    const SubmitModalEditData = () => {
        onClearAllSearch()
        setModalEditData(false)
    }
    const CloseModalEditData = () => {
        setModalEditData(false)
      }

    const Savechanges = () => {
        let sent_data_api = {
            data: {
                is_update_attendance : true,
                attendance: AttendanceCellData
            },
        }
        dispatch(updateMeeting(this_meeting_id, sent_data_api)).then(({ type }) => {
          if(type.endsWith('_SUCCESS')){
            onClearAllSearch()
            setEditAttendee(false)     
          }   
        })
    }
    return (
        <DivMain>
            <div className="content-fillter-bg">
                <div className="content-fillter">
                    <div className="group_filter">
                    <div>
                        <SearchText
                        className="search-wrapper"
                        placeholder={t('search')}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        onSubmit={onSearch}
                        onClear={onClearSearch}
                        />
                    </div>
                    <div>
                        <Button onClick={onClearAllSearch} className="button_clear_all">
                        {t('clear_all')}
                        </Button>
                    </div>
                    
                    </div>
                </div>
                <div className="badge-search-content">
                    {searchFilters.map((v) => (
                        <div className="badge" key={v.key}>
                        <span className="name">{v.name}</span>{' '}
                        <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
                        </div>
                    ))}
                </div>
            </div>
            <div className="table-wrapper">
                <div className="table-header">
                    <div className="group">
                        <div className="table-title">{t('attendee_list')}</div>
                        <div className="table-total">{total} {t('persons')}</div>
                    </div>
                    {EditAttendee == false && (
                        <div>
                            <Button onClick={() => loadExcel()} className="button_Excel margin_right_5">
                                {t('download_excel')}
                            </Button>
                            <Button onClick={() => onOpenGuest()} className="button_Edit margin_right_5">
                                {t('add_attendee')}
                            </Button>
                            <Button onClick={() => onSendEmail()} className="margin_right_5">
                                {t('send_email')}
                            </Button>
                            <Button onClick={() => setEditAttendee(true)} >
                                {t('Edit')}
                            </Button>
                        </div>
                    )}
                    {EditAttendee == true && (
                        <div>
                            <Button onClick={() => setEditAttendee(false)} className="button_Cancel">
                                {t('cancel')}
                            </Button>
                            <Button onClick={() => Savechanges()} >
                                {t('save_changes')}
                            </Button>
                        </div>
                    )}
                </div>
                <div className="css_header_detail">({SentMailMeeting_attendance_id.length} Selected) {t('edit_position_in_meeting_response_and_actual_attend')}</div>
                <DivTableAtten>
                    <table >
                        <thead>
                            <tr >
                                {TableColumn.map((column) => (
                                    <th
                                        key={column.accessor}
                                        className={`col-${column.accessor}`}
                                    >
                                        {column.Header == 'checkbox' && (
                                            <Checkbox  checked={isSelectAll} onChange={onChangeSelectAllCheckBox} />
                                        )}
                                        {column.Header != 'checkbox' && (
                                            <div>{column.Header ? column.Header : ''}</div>                                           
                                        )}                                        
                                    </th>
                                ))}
                            </tr>
                            {AttendanceCellData?.map((item, index) => (
                                <tr key={index}>
                                    <td className="detail-checkbox">
                                        <Checkbox 
                                            key={item.id} 
                                            label={''} 
                                            checked={SentMailMeeting_attendance_id.filter((v) => v == item.id).length > 0 ? true : false} 
                                            onChange={(e) => handleChangeCheckBox(e,item,index)} 
                                        />
                                    </td>
                                    <td className="detail-no">
                                        {limit * page - (9 - index)}
                                    </td>
                                    <td className="detail-full_name">
                                        <div className="text-bold">
                                            {item.full_name}
                                        </div>
                                    </td>
                                    <td >
                                        {item.department}
                                    </td>
                                    <td >
                                        {EditAttendee == true && (
                                            <TextField
                                            name={`position_` + index}
                                            placeholder=""
                                            className="text_css"
                                            value={item.position}
                                            onChange={(e) => onChangeTextGuest(index, e,"position")}
                                            />
                                        )}
                                        {EditAttendee == false && (
                                            <div>
                                                {item.position_display ? item.position_display : '-'}
                                            </div>                                            
                                        )}                                            
                                    </td>
                                    <td >
                                        {EditAttendee == true && (
                                            <TextField
                                            name={`company_` + index}
                                            placeholder=""
                                            className="text_css"
                                            value={item.company}
                                            onChange={(e) => onChangeTextGuest(index, e,"company")}
                                            />
                                        )}
                                        {EditAttendee == false && (
                                            <div>
                                                {item.company_display ? item.company_display : '-'}
                                            </div>                                            
                                        )}                                            
                                    </td>
                                    <td >
                                        {item.type_attendee}
                                    </td>
                                    <td >
                                        {item.email ? item.email : '-'}
                                    </td>
                                    <td >
                                        {item.phone_no ? item.phone_no : '-'}
                                    </td>
                                    <td >
                                        {EditAttendee == true && (
                                            <Dropdown
                                                id={`response_` + index}
                                                className="text_css"
                                                value={item.response}
                                                optionList={response_option}
                                                onChange={(e) => onChangeTextGuest(index, e,"response")}
                                                disabled={false}
                                                placeHolder="Select"
                                                ValueplaceHolderIsNull={true}
                                                isShowDisable={false}
                                            />
                                        )}
                                        {EditAttendee == false && (
                                            <div>
                                                {item.response_display ? item.response_display : 'Waiting for response'}
                                            </div>                                            
                                        )}                                            
                                    </td>
                                    <td >
                                        {item.delegate_full_name ? item.delegate_full_name : '-'}
                                    </td>
                                    <td >
                                        {item.Email_Status ? item.Email_Status : '-'}
                                    </td>
                                    <td >
                                        {item.Email_Sent ? item.Email_Sent : '-'}
                                    </td>
                                    <td >
                                        {EditAttendee == true && (
                                            <Dropdown
                                                id={`actual_attend_` + index}
                                                className="text_css"
                                                value={item.actual_attend}
                                                optionList={actual_attend_option}
                                                onChange={(e) => onChangeTextGuest(index, e,"actual_attend")}
                                                disabled={false}
                                                placeHolder="Select"
                                                ValueplaceHolderIsNull={true}
                                                isShowDisable={false}
                                            />
                                        )}
                                        {EditAttendee == false && (
                                            <div>
                                                {item.actual_attend_display ?  item.actual_attend_display : '-' }
                                            </div>                                            
                                        )}                                            
                                    </td>
                                    <td >
                                        {EditAttendee == true && (
                                            <TextField
                                            name={`note_` + index}
                                            placeholder=""
                                            className="text_css"
                                            value={item.note}
                                            onChange={(e) => onChangeTextGuest(index, e,"note")}
                                            
                                            />
                                        )}
                                        {EditAttendee == false && (
                                            <div>
                                                {item.note_display ? item.note_display : ''}
                                            </div>                                            
                                        )}  
                                    </td>
                                    <td>
                                        <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(item.id)} />
                                    </td>
                                    <td>
                                        <img className="cursor_pointer" src={iconEdit} title="Edit" onClick={() => EditData(item)} />
                                    </td>
                                </tr>
                            ))}
                        </thead>
                    </table>
                    <PaginationV2
                        className="pagination"
                        pageCount={totalPages}
                        page={page}
                        onPageChange={(page) => onChangePagination({ page })}
                    />
                </DivTableAtten>
            </div>
            <SidePanel isOpen={onModalAttendances} setIsOpen={setonModalAttendances} width={WidthMeetingCalendar}>
                <ModalAttendances width={WidthMeetingCalendar} onClose={CloseModalAttendances} onSubmit={SubmitModalAttendances} MeetingID={this_meeting_id} />
            </SidePanel>

            <SidePanel isOpen={ModalEditData} setIsOpen={setModalEditData} width={400}>
                <ModalEditAttendances width={400} onClose={CloseModalEditData} onSubmit={SubmitModalEditData} DataEdit={DataEdit} />
            </SidePanel>

            <DialogSuccess
                open={Boolean(successModal)}
                onClose={() => setsuccessModal(false)}
                onSubmit={() => setsuccessModal(false)}
                icon={successIcon}
                title={Titlesuccess}
                subtitle={SubTitlesuccess}
                description={Descriptionsuccess}
                textYes={t('done')}
            />

            <DialogFail
                open={Boolean(failModal)}
                onClose={() => setFailModal(false)}
                onSubmit={() => setFailModal(false)}
                icon={failIcon}
                title={TitleFail}
                nameItem={nameItemFail}
                description={DescriptionFail}
                textYes={t('ok')}
            />    

            <DialogConfirmDelete
            open={Boolean(ModalConfirmDel)}
            onClose={() => setModalConfirmDel(false)}
            onSubmit={SubmitModalConfirmDel}
            title={t('confirm_delete_attendee_header')}
            nameItem=""
            description=""
            />        
        </DivMain>
        
    )
}

export default TabMeetingAttendee
