import { Chip as MuiChip, Stack } from '@mui/material'
import { styled } from '@mui/system'

import Approval from './approval'
import Reviewer from './reviewer'
import Task from './task'
import DocumentSigners from './document-signers'

const Card = ({ children, ...props }) => {
  return (
    <StyledStack {...props} direction="row" justifyContent="space-between">
      {children}
    </StyledStack>
  )
}

const StyledStack = styled(Stack)`
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid var(--Gray-200);
  background-color: var(--Base-White);
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  transform: scale(0.98);
  transition: all 0.3s ease;

  &:hover {
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04); */
    transform: scale(1);
    background-color: var(--Gray-50);
  }

  & .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0.5rem !important;
    color: inherit;
  }
`

Card.DocumentSigners = DocumentSigners
Card.Task = Task
Card.Approval = Approval
Card.Reviewer = Reviewer

export default Card

/** Additional styles */
export const Chip = styled(MuiChip)`
  font-family: 'Inter', 'Noto Sans Thai';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 150% */
  color: var(--Gray-700);

  display: flex;
  padding: 0.1875rem 0.5rem;
  justify-content: center;
  align-items: center;

  border-radius: 1rem;
  border: 1px solid var(--Gray-300);
  background: var(--Gray-50);

  & .MuiChip-icon {
    margin-left: 0;
    margin-right: 0.5rem;
  }

  & .MuiChip-label {
    padding: 0;
  }

  &.MuiChip-colorSuccess,
  &.MuiChip-colorInfo,
  &.MuiChip-colorWarning,
  &.MuiChip-colorDefault {
    /* border-radius: 1rem !important; */
  }

  &.MuiChip-colorSuccess {
    color: var(--Success-700, #067647);
    border: 1px solid var(--Success-200, #abefc6) !important;
    background: var(--Success-50, #ecfdf3) !important;
  }

  &.MuiChip-colorWarning {
    color: var(--Warning-700, #b54708);
    border: 1px solid var(--Warning-200, #fedf89);
    background: var(--Warning-50, #fffaeb);
  }

  &.MuiChip-colorInfo {
    color: var(--Blue-700, #175cd3);
    border: 1px solid var(--Blue-200, #b2ddff) !important;
    background: var(--Blue-50, #eff8ff) !important;
  }

  &.MuiChip-colorError {
    color: var(--Error-700, #d32f2f);
    border: 1px solid var(--Error-200, #ffcdd2);
    background: var(--Error-50, #ffebee);
  }
`
