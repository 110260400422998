import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import { useDispatch, useSelector } from 'react-redux'
import { getProjectTask, updateStatusProjectTask } from '../../../../redux/actions/projectTask'
import { selectProjectTask } from '../../../../redux/selectors'

import { convertDateFormat } from '../../../../utils/helper'

import styled from 'styled-components'
import Modal from '../../../common/Modal'
import Table from '../../../common/Table'
import Button from '../../../form/button/Button'
import ButtonOutline from '../../../form/button/ButtonOutline'
import CapsuleStatus from '../../../widget/element/CapsuleStatus'
import DropdownSelect from '../../../widget/element/DropDownSelect'
import DialogProject from './DialogProject'
import DialogFail from '../../../dialog/DialogFail'
import { Box, Stack, Typography } from '@mui/material'
import SearchText from 'components/form/SearchText'
import Dropdown from 'components/form/Dropdown'
import Progress from 'components/common/Progress'
import DateRangesPicker2 from 'components/form/DateRangesPicker2'

import failIcon from '../../../../assets/images/fail-icon.png'
import { LiaTimesSolid } from 'react-icons/lia'

import { GetPermissionOther } from '../../../../utils/common'

export default function Project({ onClickTableItem }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const initLang = localStorage.getItem('lang')
  //--- ST ส่วนของ Permission LV3-4
  const permissionNewProject = GetPermissionOther('project','Task_Project_NewProject');
  const permissionUpdateStatus = GetPermissionOther('project','Task_Project_UpdateStatus');
  const permissionProjectName = GetPermissionOther('project','Task_Project_ProjectName');
  //--- ED ส่วนของ Permission LV3-4
  const [isShowPersonPopup, setIsShowPersonPopup] = useState(false)
  const [isShowFilterPopup, setIsShowFilterPopup] = useState(false)

  const [filterPriorityList, setFilterPriorityList] = useState([
    {
      name: 'Low',
      value: 'low',
      active_status: false,
    },
    {
      name: 'Medium',
      value: 'medium',
      active_status: false,
    },
    {
      name: 'High',
      value: 'high',
      active_status: false,
    },
  ])

  //modal
  const [isShowPreviewDialog, setIsShowPreviewDialog] = useState(false)
  const [isShowModalChangeStatus, setIsShowModalChangeStatus] = useState(false)

  //table
  const [headerTableList] = useState([
    {
      Header: t('no'),
      accessor: 'rowNumber',
      disableSortBy: false,
    },
    {
      Header: t('project_name'),
      accessor: 'project_name',
      disableSortBy: false,
    },
    {
      Header: t('start_date'),
      accessor: 'start_date',
      disableSortBy: false,
    },
    {
      Header: t('end_date'),
      accessor: 'end_date',
      disableSortBy: false,
    },
    {
      Header: t('project_manager'),
      accessor: 'project_manager',
      disableSortBy: false,
    },
    {
      Header: t('progress'),
      accessor: 'progress',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('action'),
      accessor: 'action',
      disableSortBy: false,
    },
  ])
  const [bodyTableList, setBodyTableList] = useState([])
  // Data Table
  const { projectList, page, pageSize, pageCount } = useSelector(selectProjectTask)
  const [isDataUpdate, setIsDataUpdate] = useState(false)

  const [statusList, setStatusList] = useState([])

  const [projectInfoSelected, setProjectInfoSelected] = useState()
  const [projectStatusSelected, setProjectStatusSelected] = useState()

  useEffect(() => {
    ;(async () => {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[name][${i}]`]: v.name,
      }))
      const filter = {
        page: page,
        pageSize: 10,
        status: statusSelected,
        start_date: startDate,
        end_date: endDate,
        multipleSearch,
      }

      getData(filter)
    })()
  }, [isDataUpdate])

  useEffect(() => {
    const viewList = projectList.map((item, i) => ({
      ...item,
      no: i + 1,
      project_name: (
        <span className="txt-project-name" onClick={() => handleClickProjectName(item)}>
          {item?.name}
        </span>
      ),
      start_date: convertDateFormat(item?.start_date, 'DD MMM YYYY'),
      end_date: convertDateFormat(item?.end_date, 'DD MMM YYYY'),
      project_manager: item?.project_manager,
      next_milestone: item?.next_milestone || '-',
      progress: <Progress variant="determinate" value={item?.progress} />,
      status: <CapsuleStatus status={item.status} />,
      action:
        permissionUpdateStatus?.canRead === true ? (
          <Button className="btn-action" onClick={() => handleClickBtnColAction(item)}>
            {item?.status == 'closed' ? 'Evaluation' : 'Change Status'}
          </Button>
        ) : (
          '-'
        ),
    }))
    setBodyTableList(viewList)
  }, [projectList])

  const onChangePagination = async (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[name][${i}]`]: v.name,
    }))
    const filter = {
      page: value.page,
      pageSize: pageSize,
      status: statusSelected,
      start_date: startDate,
      end_date: endDate,
      multipleSearch,
    }
    getData(filter)
  }

  const getData = useCallback(
    (filter) => {
      dispatch(getProjectTask(filter))
    },
    [dispatch]
  )

  const handleClickProjectName = (itemData) => {
    if (permissionProjectName?.canUpdate === true) {
      if (onClickTableItem) {
        onClickTableItem(itemData.id)
      }
    } else {
      let message_permission = 'No permission allowed ' + permissionProjectName?.name_en
      if (initLang == 'th') {
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionProjectName?.name_th
      }
      setTitleFail(message_permission)
      setnameItemFail('')
      setDescriptionFail('')
      setFailModal(true)
    }
  }

  const handleClickBtnColAction = (item) => {
    if (permissionUpdateStatus?.canUpdate === true) {
      setProjectInfoSelected(item)
      setIsShowModalChangeStatus(true)

      if (item?.status == 'Complete') {
        setStatusList([
          {
            text: 'Reopen',
            value: 'Reopen',
          },
        ])
      } else if (item?.status == 'On Going') {
        setStatusList([
          {
            text: 'On Hold',
            value: 'On Hold',
          },
          {
            text: 'Cancel',
            value: 'Cancel',
          },
          {
            text: 'On Going',
            value: 'On Going',
          },
          {
            text: 'Complete',
            value: 'Complete',
          },
        ])
      } else {
        setStatusList([
          {
            text: 'Open',
            value: 'Open',
          },
          {
            text: 'On Hold',
            value: 'On Hold',
          },
          {
            text: 'Cancel',
            value: 'Cancel',
          },
          {
            text: 'On Going',
            value: 'On Going',
          },
          {
            text: 'Complete',
            value: 'Complete',
          },
        ])
      }
    } else {
      let message_permission = 'No permission allowed ' + permissionNewProject?.name_en
      if (initLang == 'th') {
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionNewProject?.name_th
      }
      setTitleFail(message_permission)
      setnameItemFail('')
      setDescriptionFail('')
      setFailModal(true)
    }
  }
  const handleChangeStatus = (value) => {
    setProjectStatusSelected(value)
  }
  const handleClickedConfirm = () => {
    // dispatch To Update Status Project
    const data = {
      id: projectInfoSelected.id,
      status: projectStatusSelected,
    }
    const request = { data }
    dispatch(updateStatusProjectTask(projectInfoSelected.id, request)).then(() => {
      setIsShowModalChangeStatus(false)
      const filter = {
        page: page,
        pageSize: pageSize,
        status: statusSelected,
      }
      dispatch(getProjectTask(filter))
    })
  }

  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const OpenNewProject = () => {
    if (permissionNewProject?.canUpdate === true) {
      setIsShowPreviewDialog(true)
    } else {
      let message_permission = 'No permission allowed ' + permissionNewProject?.name_en
      if (initLang == 'th') {
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionNewProject?.name_th
      }
      setTitleFail(message_permission)
      setnameItemFail('')
      setDescriptionFail('')
      setFailModal(true)
    }
  }

  //filter
  const initialStatusList = [
    {
      text: 'Open',
      value: 'Open',
    },
    {
      text: 'On Going',
      value: 'On Going',
    },
    {
      text: 'Complete',
      value: 'Complete',
    },
    {
      text: 'On Hold',
      value: 'On Hold',
    },
    {
      text: 'Cancel',
      value: 'Cancel',
    },
    {
      text: 'Reopen',
      value: 'Reopen',
    },
  ]
  const [search, setSearch] = useState('')
  const [statusSelected, setStatusSelected] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [searchFilters, setSearchFilters] = useState([])

  const onChangeSearch = (event) => {
    const { value } = event.target
    setSearch(value)
  }
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`filters[name][${i}]`]: v.name,
      }))

      const filter = {
        page: 1,
        pageSize: pageSize,
        status: statusSelected,
        start_date: startDate,
        end_date: endDate,
        multipleSearch,
      }

      getData(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)

    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[name][${i}]`]: v.name,
    }))

    const filter = {
      page: 1,
      pageSize: pageSize,
      status: statusSelected,
      start_date: startDate,
      end_date: endDate,
      multipleSearch,
    }
    getData(filter)
  }
  const onChangeStatus = (value) => {
    setStatusSelected(value)

    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[name][${i}]`]: v.name,
    }))
    const filter = {
      page: 1,
      pageSize: pageSize,
      status: value,
      start_date: startDate,
      end_date: endDate,
      multipleSearch,
    }
    getData(filter)
  }
  const onChangeStartDate = (value) => {
    setStartDate(value)

    if (value !== '' && endDate !== '') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[name][${i}]`]: v.name,
      }))
      const filter = {
        page: 1,
        pageSize: pageSize,
        status: statusSelected,
        start_date: value,
        end_date: endDate,
        multipleSearch,
      }
      getData(filter)
    }
  }
  const onChangeEndDate = (value) => {
    setEndDate(value)

    if (value !== '' && startDate !== '') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[name][${i}]`]: v.name,
      }))
      const filter = {
        page: 1,
        pageSize: pageSize,
        status: statusSelected,
        start_date: startDate,
        end_date: value,
        multipleSearch,
      }
      getData(filter)
    }
  }
  const onClearSearch = () => {
    const request = {
      page: 1,
      pageSize: pageSize,
      status: '',
      start_date: '',
      end_date: '',
      multipleSearch: [],
    }
    getData(request)
    clearFilter()
  }
  const clearFilter = () => {
    setSearch('')
    setStatusSelected('')
    setStartDate('')
    setEndDate('')
    setSearchFilters([])
  }

  return (
    <Styled>
      <Box
        sx={{
          borderRadius: '8px 8px 0 0',
          borderColor: '#eaecf0',
          borderWidth: '1px',
          borderStyle: 'solid',
          marginTop: '10px',
          padding: '20px',
        }}
      >
        <Stack mt={1} mb={1} direction="row" justifyContent="space-between" alignItems="flex-end">
          <Stack direction="row" alignItems="flex-end" spacing={2}>
            <Box>
              <Typography className="filter-heading" variant="h6">
                Search
              </Typography>
              <SearchText
                placeholder={t('search')}
                value={search}
                onChange={onChangeSearch}
                onSubmit={onSearch}
                onClear={() => setSearch('')}
              />
            </Box>
            <Box>
              <Typography className="filter-heading" variant="h6">
                Status
              </Typography>
              <Dropdown optionList={initialStatusList} value={statusSelected} onChange={onChangeStatus} />
            </Box>
            <Box>
              <Typography className="filter-heading" variant="h6">
                Date Selection (End Date)
              </Typography>

              <DateRangesPicker2
                startDate={startDate}
                onChangeStartDate={onChangeStartDate}
                endDate={endDate}
                onChangeEndDate={onChangeEndDate}
              />
            </Box>
            <Button onClick={onSearch}>Search</Button>
            <ButtonOutline onClick={onClearSearch}>Clear</ButtonOutline>
          </Stack>
          {permissionNewProject?.canRead === true && <Button onClick={() => OpenNewProject()}>New Project</Button>}
        </Stack>

        <BadgeSearchContainer>
          {searchFilters.map((v) => (
            <div className="badge" key={v.key}>
              <span className="name">{v.name}</span>{' '}
              <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
            </div>
          ))}
        </BadgeSearchContainer>
      </Box>

      <div className="wrp-table">
        <Table
          columns={headerTableList}
          data={bodyTableList}
          onStateChange={onChangePagination}
          pageCount={pageCount}
          page={page}
        />
      </div>

      {isShowPreviewDialog && (
        <DialogProject
          type={'new_project'}
          onClose={() => {
            setIsDataUpdate(!isDataUpdate)
            setIsShowPreviewDialog(false)
          }}
        />
      )}

      {isShowModalChangeStatus && (
        <Modal open={isShowModalChangeStatus} disableBackdropClick>
          <Div>
            <div className="modal-container">
              <div className="inner-modal-change-status">
                <div className="modal-header">{t('change_status')}</div>

                <DropdownSelect
                  className={'dropdown-select'}
                  dataList={statusList} //data.status !== "completed" ?  statusList : completeList
                  initValue={projectStatusSelected}
                  onChange={(e) => handleChangeStatus(e)}
                  placeHolder="เลือก Status"
                />

                <div className="wrp-button">
                  <ButtonOutline className={'btn-modal'} onClick={() => setIsShowModalChangeStatus(false)}>
                    {t('cancel')}
                  </ButtonOutline>
                  <Button className={'btn-modal'} onClick={handleClickedConfirm}>
                    {t('confirm')}
                  </Button>
                </div>
              </div>
            </div>
          </Div>
        </Modal>
      )}

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />
    </Styled>
  )
}

const Styled = styled.div`
  .filter-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .wrp-filter-menu {
      position: relative;
      display: flex;
      align-items: center;
    }

    .wrp-filter-btn {
      position: relative;
    }

    .dialog-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 300px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);
      max-height: 300px;

      .wrp-person-list {
        position: relative;
      }
      .person-list {
        height: 220px;
        overflow-y: scroll;
      }

      .person-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 4px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
        }

        .img-profile {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          background-color: var(--Gray-200);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .person-item:hover {
        background-color: var(--Gray-50);
      }
    }

    .filter-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 500px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);

      .wrp-filter-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clear-btn {
          padding: 4px 6px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        .clear-btn:hover {
          background: var(--Primary-25);
        }
      }
      .wrp-filter-option {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .title-option {
          font-weight: 600;
          margin-bottom: 6px;
        }
        .option-item-style {
          border: 1px solid var(--Gray-300);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Gray-50);
        }
        .option-item-style:hover {
          background-color: var(--Gray-100);
        }
        .option-item-active {
          border: 1px solid var(--Primary-600);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Primary-600);
          color: #fff;
        }
      }
    }
  }

  .wrp-paginate-table {
    margin-top: 16px;
    .btn-action-project {
      min-width: 140px;
      justify-content: center;
      text-align: center;
    }
  }

  .wrp-table {
    overflow-x: scroll;
  }
  .txt-project-name {
    :hover {
      color: var(--Primary-700);
      font-weight: bold;
      cursor: pointer;
    }
  }

  .filter-heading {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`
const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .content-header {
    margin-top: 24px;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    padding: 24px;
    min-width: 400px;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error {
    color: red;
  }

  //Modal change status

  .inner-modal-change-status {
    display: flex;
    flex-direction: column;

    .wrp-modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .modal-header {
      color: #6941c6;
      font-weight: bold;
      font-size: 24px;
    }
    .dropdown-select {
      margin: 16px 0;
      width: 100%;
    }
    .wrp-button {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
    }
    .btn-modal {
      width: 49%;
      justify-content: center;
    }
  }
`
const BadgeSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .badge {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`
