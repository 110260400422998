import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useNavigate, NavLink } from 'react-router-dom'

import { getMeetingById } from '../../redux/actions/dailysummary'
import { selectDailySummary } from '../../redux/selectors'
import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

const DivMain = styled.div`
color: var(--Gray-600);
font-size: 14px;
font-style: normal;
font-weight: 500;
.header {
    display: flex;
    align-items: center;

    .highlight {
        color: var(--Primary-700);
        font-weight: 600;
    }
}
.mb-2-rem {
    margin-bottom: 2rem;
}
.mr-8-px {
    margin-right: 8px;
}
.icon {
    cursor: pointer;
}
.content-title {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
}
.border_header{
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
}
.wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        border-bottom: 0px solid #EAECF0;
        color: #667085;
        font-size: 14px;
    }

    .menu-active {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid var(--Primary-600) !important;
        color: #6941C6;
        background-color: #F9F5FF;
    }
}
`

import TabMeetingInfomation from '../../pages/my-calendar/TabMeetingInfomation'
import TabMeetingAttendee from '../../pages/my-calendar/TabMeetingAttendee'
import TabMeetingFile from '../../pages/my-calendar/TabMeetingFile'
import TabMeetingSummary from '../../pages/my-calendar/TabMeetingSummary'
import TabMeetingNote from '../../pages/my-calendar/TabMeetingNote'

import { useTranslation } from 'react-i18next'


function MeetingForm() {
  const params = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { id:this_meeting_id } = params

  const dispatch = useDispatch()
  useEffect(() => {
    if (this_meeting_id) {
      dispatch(getMeetingById(this_meeting_id))
    }
  }, [this_meeting_id, dispatch])

  const { MeetingInfo } = useSelector(selectDailySummary)

  const backToMeeting = () => {
    navigate('/MyCalendar/Meeting')
  }

  const [HeaderName,setHeaderName] = useState('');

  useEffect(() => {
    if (MeetingInfo.id) {
        setHeaderName(MeetingInfo.title)
    }
  }, [MeetingInfo])
  const [tabactive, setTabactive] = useState('Information')
  const [menuTabList, setMenuTabList] = useState([
    {
      name: t('information'),
      value: 'Information',
      active_status: true,
    },
    {
      name: t('attendee'),
      value: 'Attendee',
      active_status: false,
    },
    {
      name: t('file'),
      value: 'File',
      active_status: false,
    },
    {
      name: t('summary'),
      value: 'Summary',
      active_status: false,
    },
    {
        name: t('note'),
        value: 'Note',
        active_status: false,
    },
  ])
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }
  return (
    <DivMain>
      <div className="header mb-2-rem">
        <RiHome6Line size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px" onClick={backToMeeting}>
          Meeting
        </span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="highlight">{HeaderName}</span>
      </div>
      <div className="content-title">{HeaderName}</div>
      <div className="">{t('edit_meeting_sub_header')}</div>
      <div className="border_header"></div>
      <div className="wrp-tab-menu">
        {menuTabList?.map((item, index) => (
            <button
            id={'this_name-' + item.name}
            key={'btn-' + index}
            className={item.active_status ? 'menu-active' : 'btn-menu-style'}
            onClick={() => handleClickTaskMenuTab(item)}
            >
            {item.name}
            </button>
        ))}
       </div>

       {tabactive == 'Information' && (
        <div>
            <TabMeetingInfomation this_meeting_id={this_meeting_id} />
        </div>
       )}
       {tabactive == 'Attendee' && (
        <div>
            <TabMeetingAttendee this_meeting_id={this_meeting_id} />
        </div>
       )} 
       {tabactive == 'File' && (
        <div>
            <TabMeetingFile this_meeting_id={this_meeting_id} />
        </div>
       )} 
       {tabactive == 'Summary' && (
        <div>
            <TabMeetingSummary this_meeting_id={this_meeting_id} />
        </div>
       )} 
       {tabactive == 'Note' && (
        <div>
            <TabMeetingNote this_meeting_id={this_meeting_id} />
        </div>
       )} 
    </DivMain>
    
  )
}

export default MeetingForm
