export default function IconFileRar({ width = '40', color = '#D0D5DD', style }) {
  return (
    <svg width={width} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <path
        d="M7.75 4C7.75 2.20508 9.20508 0.75 11 0.75H27C27.1212 0.75 27.2375 0.798159 27.3232 0.883885L38.1161 11.6768C38.2018 11.7625 38.25 11.8788 38.25 12V36C38.25 37.7949 36.7949 39.25 35 39.25H11C9.20507 39.25 7.75 37.7949 7.75 36V4Z"
        fill="white"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M27 0.5V8C27 10.2091 28.7909 12 31 12H38.5" stroke={color} strokeWidth="1.5" />
      <rect x="1" y="18" width="27" height="16" rx="2" fill="#344054" />
      <path
        d="M4.82937 30V22.7273H7.69869C8.24793 22.7273 8.71668 22.8255 9.10494 23.022C9.49556 23.2161 9.79267 23.492 9.99627 23.8494C10.2022 24.2045 10.3052 24.6224 10.3052 25.103C10.3052 25.5859 10.2011 26.0014 9.99272 26.3494C9.78439 26.6951 9.48254 26.9602 9.08718 27.1449C8.69419 27.3295 8.21834 27.4219 7.65962 27.4219H5.73846V26.1861H7.41104C7.7046 26.1861 7.94845 26.1458 8.14258 26.0653C8.33671 25.9848 8.48112 25.8641 8.57582 25.7031C8.67288 25.5421 8.72141 25.3421 8.72141 25.103C8.72141 24.8615 8.67288 24.6579 8.57582 24.4922C8.48112 24.3265 8.33552 24.201 8.13903 24.1158C7.9449 24.0282 7.69987 23.9844 7.40394 23.9844H6.36701V30H4.82937ZM8.75692 26.6903L10.5645 30H8.86701L7.09854 26.6903H8.75692ZM12.6454 30H10.9977L13.5083 22.7273H15.4899L17.997 30H16.3493L14.5275 24.3892H14.4707L12.6454 30ZM12.5424 27.1413H16.4345V28.3416H12.5424V27.1413ZM18.8723 30V22.7273H21.7417C22.2909 22.7273 22.7596 22.8255 23.1479 23.022C23.5385 23.2161 23.8356 23.492 24.0392 23.8494C24.2452 24.2045 24.3482 24.6224 24.3482 25.103C24.3482 25.5859 24.244 26.0014 24.0357 26.3494C23.8274 26.6951 23.5255 26.9602 23.1301 27.1449C22.7372 27.3295 22.2613 27.4219 21.7026 27.4219H19.7814V26.1861H21.454C21.7476 26.1861 21.9914 26.1458 22.1855 26.0653C22.3797 25.9848 22.5241 25.8641 22.6188 25.7031C22.7158 25.5421 22.7644 25.3421 22.7644 25.103C22.7644 24.8615 22.7158 24.6579 22.6188 24.4922C22.5241 24.3265 22.3785 24.201 22.182 24.1158C21.9879 24.0282 21.7428 23.9844 21.4469 23.9844H20.41V30H18.8723ZM22.7999 26.6903L24.6074 30H22.91L21.1415 26.6903H22.7999Z"
        fill="white"
      />
    </svg>
  )
}
