import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/system'

const Button = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>
}

export default Button

const StyledButton = styled(MuiButton)`
  &.MuiButton-root {
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    font-family: 'Inter', 'Noto Sans Thai';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-transform: none;
  }

  &.MuiButton-sizeLarge {
    padding: 0.75rem 2rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  &.MuiButton-contained {
    color: var(--Base-White);
    background-color: var(--Primary-500);
    border: 1px solid var(--Primary-500);
    box-shadow: none;

    &:hover {
      background-color: var(--Primary-700);
      border-color: var(--Primary-700);
    }
  }

  &.MuiButton-outlined {
    color: var(--Primary-500);
    border: 1px solid var(--Primary-500);
    box-shadow: none;

    &:hover {
      background-color: var(--Primary-50);
      border-color: var(--Primary-700);
    }
  }

  &.MuiButton-text {
    color: var(--Primary-500);
    border: none;
    box-shadow: none;

    &:hover {
      background-color: var(--Primary-50);
    }
  }

  &.MuiButton-text.Mui-disabled {
    color: var(--Gray-400);
  }

  &.MuiButton-contained.Mui-disabled {
    color: var(--Base-White);
    background-color: var(--Primary-200);
    border: 1px solid var(--Primary-200);
  }

  &.MuiButton-outlined.Mui-disabled {
    color: var(--Gray-300);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-contained.gray {
    color: var(--Gray-900);
    background-color: var(--Base-White);
    border: 1px solid var(--Gray-200);

    &:hover {
      background-color: var(--Gray-50);
    }
  }

  &.MuiButton-outlined.gray {
    color: var(--Gray-900);
    border: 1px solid var(--Gray-200);

    &:hover {
      background-color: var(--Gray-50);
    }
  }

  &.MuiButton-text.gray {
    color: var(--Gray-900);

    &:hover {
      background-color: var(--Gray-50);
    }
  }

  &.MuiButton-contained.gray.Mui-disabled {
    color: var(--Gray-300);
    background-color: var(--Gray-50);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-outlined.gray.Mui-disabled {
    color: var(--Gray-300);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-text.gray.Mui-disabled {
    color: var(--Gray-300);
  }

  &.MuiButton-contained.secondary {
    color: var(--Base-White);
    background-color: var(--Secondary-500);
    border: 1px solid var(--Secondary-500);

    &:hover {
      background-color: var(--Secondary-700);
      border-color: var(--Secondary-700);
    }
  }

  &.MuiButton-outlined.secondary {
    color: var(--Secondary-500);
    border: 1px solid var(--Secondary-500);

    &:hover {
      background-color: var(--Secondary-50);
      border-color: var(--Secondary-700);
    }
  }

  &.MuiButton-text.secondary {
    color: var(--Secondary-500);
    border: none;

    &:hover {
      background-color: var(--Secondary-50);
    }
  }

  &.MuiButton-contained.secondary.Mui-disabled {
    color: var(--Gray-300);
    background-color: var(--Gray-50);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-outlined.secondary.Mui-disabled {
    color: var(--Gray-300);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-text.secondary.Mui-disabled {
    color: var(--Gray-300);
  }

  &.MuiButton-contained.success {
    color: var(--Base-White);
    background-color: var(--Success-500);
    border: 1px solid var(--Success-500);

    &:hover {
      background-color: var(--Success-700);
      border-color: var(--Success-700);
    }
  }

  &.MuiButton-outlined.success {
    color: var(--Success-500);
    border: 1px solid var(--Success-500);

    &:hover {
      background-color: var(--Success-50);
      border-color: var(--Success-700);
    }
  }

  &.MuiButton-text.success {
    color: var(--Success-500);
    border: none;

    &:hover {
      background-color: var(--Success-50);
    }
  }

  &.MuiButton-contained.success.Mui-disabled {
    color: var(--Gray-300);
    background-color: var(--Gray-50);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-outlined.success.Mui-disabled {
    color: var(--Gray-300);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-text.success.Mui-disabled {
    color: var(--Gray-300);
  }

  &.MuiButton-contained.warning {
    color: var(--Base-White);
    background-color: var(--Warning-500);
    border: 1px solid var(--Warning-500);

    &:hover {
      background-color: var(--Warning-700);
      border-color: var(--Warning-700);
    }
  }

  &.MuiButton-outlined.warning {
    color: var(--Warning-500);
    border: 1px solid var(--Warning-500);

    &:hover {
      background-color: var(--Warning-50);
      border-color: var(--Warning-700);
    }
  }

  &.MuiButton-text.warning {
    color: var(--Warning-500);
    border: none;

    &:hover {
      background-color: var(--Warning-50);
    }
  }

  &.MuiButton-contained.warning.Mui-disabled {
    color: var(--Gray-300);
    background-color: var(--Gray-50);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-outlined.warning.Mui-disabled {
    color: var(--Gray-300);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-text.warning.Mui-disabled {
    color: var(--Gray-300);
  }

  &.MuiButton-contained.info {
    color: var(--Base-White);
    background-color: var(--Info-500);
    border: 1px solid var(--Info-500);

    &:hover {
      background-color: var(--Info-700);
      border-color: var(--Info-700);
    }
  }

  &.MuiButton-outlined.info {
    color: var(--Info-500);
    border: 1px solid var(--Info-500);

    &:hover {
      background-color: var(--Info-50);
      border-color: var(--Info-700);
    }
  }

  &.MuiButton-text.info {
    color: var(--Info-500);
    border: none;

    &:hover {
      background-color: var(--Info-50);
    }
  }

  &.MuiButton-contained.info.Mui-disabled {
    color: var(--Gray-300);
    background-color: var(--Gray-50);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-outlined.info.Mui-disabled {
    color: var(--Gray-300);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-text.info.Mui-disabled {
    color: var(--Gray-300);
  }

  &.MuiButton-contained.danger {
    color: var(--Base-White);
    background-color: var(--Danger-500);
    border: 1px solid var(--Danger-500);

    &:hover {
      background-color: var(--Danger-700);
      border-color: var(--Danger-700);
    }
  }

  &.MuiButton-outlined.danger {
    color: var(--Danger-500);
    border: 1px solid var(--Danger-500);

    &:hover {
      background-color: var(--Danger-50);
      border-color: var(--Danger-700);
    }
  }

  &.MuiButton-text.danger {
    color: var(--Danger-500);
    border: none;

    &:hover {
      background-color: var(--Danger-50);
    }
  }

  &.MuiButton-contained.danger.Mui-disabled {
    color: var(--Gray-300);
    background-color: var(--Gray-50);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-outlined.danger.Mui-disabled {
    color: var(--Gray-300);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-text.danger.Mui-disabled {
    color: var(--Gray-300);
  }

  // For older Material-UI versions

  // For Material-UI v5.15.14 and above
  &.MuiButton-contained.MuiButton-containedError,
  &.MuiButton-contained.MuiButton-colorError {
    color: var(--Base-White);
    background-color: var(--Error-500);
    border: 1px solid var(--Errorr-500);

    &:hover {
      background-color: var(--Error-700);
      border-color: var(--Error-700);
    }
  }

  &.MuiButton-outlined.MuiButton-outlinedError,
  &.MuiButton-outlined.MuiButton-colorError {
    color: var(--Error-500);
    border: 1px solid var(--Error-500);

    &:hover {
      background-color: var(--Error-50);
      border-color: var(--Error-700);
    }
  }

  &.MuiButton-text.MuiButton-textError,
  &.MuiButton-text.MuiButton-colorError {
    color: var(--Error-500);
    border: none;

    &:hover {
      background-color: var(--Error-50);
    }
  }

  &.MuiButton-contained.MuiButton-containedError.Mui-disabled,
  &.MuiButton-contained.MuiButton-colorError.Mui-disabled {
    color: var(--Gray-300);
    background-color: var(--Gray-50);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-outlined.MuiButton-outlinedError.Mui-disabled,
  &.MuiButton-outlined.MuiButton-colorError.Mui-disabled {
    color: var(--Gray-300);
    border: 1px solid var(--Gray-200);
  }

  &.MuiButton-text.MuiButton-textError.Mui-disabled,
  &.MuiButton-text.MuiButton-colorError.Mui-disabled {
    color: var(--Gray-300);
  }
`

// const Button = styled(MuiButton)`
//   color: var(--Main-Button-Font);
//   background-color: var(--Main-Button-Bg);
//   border-radius: 0.5rem;
//   text-transform: capitalize;
//   box-shadow: none;
//   height: fit-content;
//   border: 1px solid var(--Main-Button-Bg);

//   &:hover {
//     color: var(--Main-Button-Font-Hover);
//     background-color: var(--Main-Button-Bg-Hover);
//     border: 1px solid var(--Main-Button-Bg);
//   }

//   &.Mui-disabled {
//     color: var(--Gray-300);
//     background-color: var(--Gray-50);
//     border: 1px solid var(--Gray-200);
//   }

//   &.gray {
//     color: var(--Gray-900);
//     background-color: var(--Base-White);
//     border: 1px solid var(--Gray-200);

//     &.Mui-disabled {
//       color: var(--Gray-300);
//       background-color: var(--Gray-50);
//       border: 1px solid var(--Gray-200);
//     }

//     &:hover {
//       color: var(--Gray-900);
//       background-color: var(--Gray-50);
//       box-shadow: 0 2px 2px var(--Gray-50);
//     }
//   }
// `
