import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ReactQuill from 'react-quill'

import { selectDocumentTemplate } from '../../../redux/selectors'

import styled from 'styled-components'
import TextField from '../../form/TextField'
import RadioButton from '../../form/RadioButton'
import FileUpload from '../../../components/form/FileUpload'

import { LiaTimesSolid } from 'react-icons/lia'

import PropTypes from 'prop-types'

const Wrapper = styled.div`
  padding: 24px;
  height: 100%;

  color: var(--Gray-600);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;

  .icon {
    cursor: pointer;
  }

  .d-flex {
    display: flex;
  }
  .justify-content-end {
    justify-content: flex-end;
  }

  .mr-12-px {
    margin-right: 12px;
  }
  .mt-24-px {
    margin-top: 24px;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .pb-24-px {
    padding-bottom: 24px;
  }

  .headline-panel {
    color: var(--Gray-900);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
  }

  .sub-headline-panel {
  }

  .divider {
    width: 100%;
    height: 1px;
    background: var(--Gray-200);
  }

  .item-vertical-align-center {
    vertical-align: middle;
  }

  .preview-btn {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: var(--Primary-700);
  }
`
const InputContainer = styled.div`
  .width-100 {
    width: 100%;
  }
  .width-80 {
    width: 80%;
  }
  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.375rem;
  }
`
const Badge = styled.div`
  display: inline-block;
  border-radius: 16px;
  border: 1.5px solid var(--BADGE-2-Border);
  background: var(--BADGE-2-Bg);
  color: var(--BADGE-2-Font);

  padding: 4px 12px;
`
const TextEditor = styled.div`
  box-sizing: border-box;
  padding-bottom: 65px;

  .text-editor-headline {
    color: var(--Gray-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
  }
`

const initLangRadioOptionList = [
  {
    text: 'Thai',
    value: 'th',
  },
  {
    text: 'English',
    value: 'en',
  },
]

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link', 'image', 'video'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: 2 }],
    [{ color: [] }, { background: [] }],
    ['formula'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ align: [] }],
    ['clean'],
  ],
}
function PreviewTemplate({ close }) {
  const { t } = useTranslation()
  const { documentTemplateInfo } = useSelector(selectDocumentTemplate)

  return (
    <Wrapper>
      <div className="d-flex justify-content-end">
        <LiaTimesSolid className="icon" size={25} onClick={close} />
      </div>

      <div className="headline-panel">{t('preview_of_document_form')}</div>
      <div className="sub-headline-panel">{t('sub_headline_preview_of_document_form')}</div>

      <div className="divider mt-24-px mb-24-px"></div>

      <Badge className="mb-8-px">Preview</Badge>

      <InputContainer className="mb-24-px">
        <div className="input-header">{t('document_name')}</div>
        <TextField className="width-100 mb-8-px" value={documentTemplateInfo?.name} disabled={true} />
      </InputContainer>
      <InputContainer className="mb-24-px">
        <div className="input-header">{t('category')}</div>
        <TextField className="width-100 mb-8-px" value={documentTemplateInfo?.category?.name} disabled={true} />
      </InputContainer>
      <InputContainer className="mb-24-px">
        <div className="input-header">{t('type')}</div>
        <TextField className="width-100 mb-8-px" value={documentTemplateInfo?.type} disabled={true} />
      </InputContainer>
      <InputContainer className="mb-24-px">
        <div className="input-header">{t('document_lang_version')}</div>
        <RadioButton
          name="lang"
          optionList={initLangRadioOptionList}
          value={documentTemplateInfo?.doc_lang}
          disabled={true}
        />
      </InputContainer>
      <div className="divider mt-24-px mb-24-px"></div>
      <div className="headline-panel mb-24-px">[Name of document to be filled]</div>
      {documentTemplateInfo?.topics.map((v) => (
        <TextEditor key={v.id}>
          <div className="text-editor-headline mb-8-px">{v.title}</div>
          <ReactQuill
            style={{ height: '226px' }}
            className="mb-8-px"
            theme="snow"
            modules={modules}
            dis
            // value={documentForm?.topics[i]?.description}
            // onChange={(value) => onChangeTextEditor(v.id, value)}
          />
        </TextEditor>
      ))}

      <InputContainer className="pb-24-px">
        <FileUpload />
      </InputContainer>
    </Wrapper>
  )
}

PreviewTemplate.propTypes = {
  close: PropTypes.func,
}

export default PreviewTemplate
