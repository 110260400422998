import { getAPI, postAPI } from '../../utils/api'
import { removeAdminData, setAdminData } from '../../utils/common'
import { LOGIN_ADMIN, FORGOT_PASSWORD, CHANGE_COMPANY, VERIFY_OTP, RESEND_OTP } from '../actionTypes'
import { showLoading, hideLoading } from '../../redux/actions/loading'
import { VERSION } from '../../configs/app'

export const loginAdmin = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_ADMIN.REQUEST })
    const { data } = await postAPI({
      url: '/admin/login',
      data: {
        email,
        password,
      },
    })
    const headers = {
      Authorization: `${data.token}`,
      'Content-Type': 'application/json',
    }
    const response = await getAPI({
      url: `/api/user-permission-list/${data.user.id}`,
      headers,
    })
    const { apitoken, permission, employee_info, company_info, menu_list } = response.data
    const mockData = {
      ...data,
      menu_list,
      permission,
      jwt: apitoken,
      employee_info,
      company_info,
    }
    setAdminData(mockData)
    localStorage.setItem('lang', 'th')
    localStorage.setItem('version', VERSION)
    return dispatch({ type: LOGIN_ADMIN.SUCCESS, mockData, showAlert: true, alertMessage: 'Login successful' })
  } catch (err) {
    return dispatch({ type: LOGIN_ADMIN.FAILURE, error: err })
  }
}

export const loginUser = (email, password) => async (dispatch) => {
  //--- ตัว login version ใหม่
  try {
    dispatch(showLoading())
    dispatch({ type: LOGIN_ADMIN.REQUEST })
    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    const { user, jwt, is_otp, is_otp_block, refreshToken, jwtExpiresDate, data } = await postAPI({
      url: '/api/auth/local',
      data: {
        // identifier: email,
        username: email,
        password: password,
      },
      headers,
    })

    if (is_otp === true) {
      return {
        type: LOGIN_ADMIN.SUCCESS,
        user: data,
        is_otp,
        is_otp_block,
        showAlert: true,
        alertMessage: 'Login successful',
      }
    } else {
      const data = {
        token: jwt,
        user: user,
      }
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const response = await getAPI({
        url: `/api/user-permission-auth-permission`,
        headers,
      })
      // apitoken doesn't use anymore
      const { permission, employee_info, company_info, menu_list } = response.data
      const userData = {
        ...data,
        menu_list,
        permission,
        jwt: jwt,
        employee_info,
        company_info,
        refreshToken,
        jwtExpiresDate,
      }
      setAdminData(userData)
      localStorage.setItem('lang', 'th')
      localStorage.setItem('version', VERSION)

      // return dispatch({ type: LOGIN_ADMIN.SUCCESS, userData, showAlert: true, alertMessage: 'Login successful' })
      return {
        type: LOGIN_ADMIN.SUCCESS,
        userData,
        is_otp,
        is_otp_block,
        showAlert: true,
        alertMessage: 'Login successful',
      }
    }
  } catch (err) {
    if (err?.message === 'Network Error') {
      return dispatch({
        type: LOGIN_ADMIN.FAILURE,
        error: err,
        showAlert: true,
        alertMessage: err?.message,
      })
    } else {
      return dispatch({
        type: LOGIN_ADMIN.FAILURE,
        error: err,
        showAlert: true,
        alertMessage: 'Incorrect Email/Password',
      })
    }
  } finally {
    dispatch(hideLoading())
  }
}

export const verifyOtpForLogin = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: VERIFY_OTP.REQUEST })
    const headersForOtp = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    const { jwt, user } = await postAPI({
      url: '/api/users-permissions/verify-otp',
      data,
      headers: headersForOtp,
    })

    const dataForPermission = {
      token: jwt,
      user: user,
    }
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const response = await getAPI({
      url: `/api/user-permission-auth-permission`,
      headers,
    })

    const { permission, employee_info, company_info } = response.data
    const userData = {
      ...dataForPermission,
      permission,
      jwt: jwt,
      employee_info,
      company_info,
    }
    setAdminData(userData)
    localStorage.setItem('lang', 'th')
    localStorage.setItem('version', VERSION)

    return {
      type: VERIFY_OTP.SUCCESS,
    }
  } catch (err) {
    return dispatch({
      type: VERIFY_OTP.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: err?.message,
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const resendOTP = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: RESEND_OTP.REQUEST })
    const headersForOtp = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    const response = await postAPI({
      url: '/api/users-permissions/resend-otp',
      data,
      headers: headersForOtp,
    })

    return {
      type: RESEND_OTP.SUCCESS,
      response: response?.data,
    }
  } catch (err) {
    return dispatch({
      type: RESEND_OTP.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: err?.message,
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const ChangeCompany = (company_id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CHANGE_COMPANY.REQUEST })
    const { token, user } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    const params = {
      id: company_id,
    }

    const response = await getAPI({
      url: '/api/user-permission-auth-permission',
      headers: headers,
      params,
    })

    const data = {
      token: token,
      user: user,
    }
    const { apitoken, permission, employee_info, company_info } = response.data
    const mockData = {
      ...data,
      permission,
      jwt: apitoken,
      employee_info,
      company_info,
    }
    setAdminData(mockData)

    return { type: 'ChangeCompany_SUCCESS' }
  } catch (error) {
    return { type: 'ChangeCompany_ERROR' }
  } finally {
    dispatch(hideLoading())
  }
}

export const logoutAdmin = () => () => {
  removeAdminData()
  window.location.href = '/login'
}

export const requestForgotPassword = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: FORGOT_PASSWORD.REQUEST })
    const headers = {
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/mapping-employee-user-request-forgotpassword`,
      data,
      headers: headers,
    })

    return dispatch({
      type: FORGOT_PASSWORD.SUCCESS,
      showAlert: true,
      alertMessage: 'Request forgot password Successful',
    })
  } catch (err) {
    return dispatch({
      type: FORGOT_PASSWORD.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Request forgot password Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}
