import { Alert, AlertTitle } from '@mui/material'
import { styled } from '@mui/system'
import dayjs from 'dayjs'
import React, { forwardRef, useCallback, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Empty from 'components/common/Empty'
import Typography from 'components/common/Typography'
import { SWTimelinePreview } from 'components/feature/operation-process'
import DynamicForm from 'components/form2/dynamic-form'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { addMinutes, isInWorkingHours } from 'utils/timeUtils'

const langLocale = 'th'

const CreateSP = forwardRef(function CreateSP(props, ref) {
  const { t } = useTranslation()
  const { onSubmit } = props

  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)

  const { extra: extraActions } = opActions()
  const { getExtra } = extraActions()
  const { spTemplates, spTemplates_pagination, tags } = opProcSelector

  const defaultValues = {
    sp_temp: { id: null },
    name: '',
    description: '',
    tags: [],
    milestones: [],
    working_times: [],
    start_date: dayjs().hour(8).minute(0).locale(langLocale).format(),
    start_time: dayjs().hour(8).minute(0).locale(langLocale).format(),
  }
  const methods = useForm({ defaultValues })

  const watchSPTempId = methods.watch('sp_temp.id')
  const watchMilestones = methods.watch('milestones')
  const workingTimes = methods.watch('working_times')

  const fetchSPTemplates = useCallback(
    async (payload) => {
      dispatch(getExtra().spTemplates(payload))
    },
    [dispatch]
  )

  const fetchSPTemplateById = useCallback(
    async (spTempId) => {
      return dispatch(getExtra().spTemplateById(spTempId))
    },
    [dispatch]
  )

  const fetchTags = useCallback(
    async (filters) => {
      dispatch(getExtra().tags({ filters }))
    },
    [dispatch]
  )

  const calculateDueDate = (sop = [], start_date, working_times = workingTimes) => {
    let currentDate = dayjs(start_date).locale(langLocale).set('second', 0).set('millisecond', 0)

    if (sop.length > 0) {
      sop.forEach((milestone) => {
        const sw_hour = milestone?.sw?.hr || 0
        const sw_minute = milestone?.sw?.min || 0
        const sw_estimated_time = sw_hour * 60 + sw_minute

        currentDate = addMinutes(currentDate, sw_estimated_time, working_times)

        milestone.sw.due_date = dayjs(currentDate).locale(langLocale).set({ second: 0, millisecond: 0 }).format()

        // console.log(`${milestone.sw.code} due date:`, currentDate.format())

        if (milestone?.reviewers && Array.isArray(milestone.reviewers) && milestone.reviewers.length > 0) {
          milestone.reviewers.forEach((reviewer) => {
            const reviewer_hour = reviewer?.hr || 0
            const reviewer_minute = reviewer?.min || 0
            const reviewer_estimated_time = reviewer_hour * 60 + reviewer_minute

            currentDate = addMinutes(currentDate, reviewer_estimated_time, working_times)

            reviewer.due_date = dayjs(currentDate).locale(langLocale).format()
          })
        }

        if (milestone?.approver && milestone.approver !== null) {
          const approver_hour = milestone.approver?.hr || 0
          const approver_minute = milestone.approver?.min || 0
          const approver_estimated_time = approver_hour * 60 + approver_minute

          currentDate = addMinutes(currentDate, approver_estimated_time, working_times)

          milestone.approver.due_date = dayjs(currentDate).locale(langLocale).format()
        }
      })
      return sop
    }
    return []
  }

  useEffect(() => {
    fetchSPTemplates()
    fetchTags()
  }, [dispatch])

  const formSchema = {
    properties: {
      'section-1': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('detail')}</Typography>
            <Typography variant="sectionText">{t('detail_sop_desc')}</Typography>
          </HeaderSection>
        ),
      },
      'divider-1': { type: 'divider' },
      ['sp_temp.id']: {
        type: 'select',
        options: spTemplates.map((spTemp) => ({ value: spTemp.id, label: spTemp.name })),
        label: t('sop_template'),
        placeholder: t('select_sop_template'),
        onchange: async (e, selectVal) => {
          const spTempId = e.target.value || selectVal
          const startDate = methods.getValues('start_date') || dayjs().format()
          const spTemp = await fetchSPTemplateById(spTempId)
          const milestones = spTemp?.data?.milestones || []
          const spTempWorkingTimes = spTemp?.data?.company?.working_times || []

          const calculatedMilestones = calculateDueDate(milestones, startDate, spTempWorkingTimes)
          methods.setValue('working_times', spTempWorkingTimes)
          methods.setValue('milestones', calculatedMilestones)
        },
      },
      ['name']: {
        type: 'text',
        label: t('sop_name'),
        placeholder: t('enter_sop_name'),
        validator: {
          required: true,
          message: 'This field is required',
          minLength: 1,
          maxLength: 50,
        },
      },
      ['description']: {
        type: 'textArea',
        label: t('description'),
        props: {
          minRows: 3,
          maxRows: 5,
          multiline: true,
        },
      },
      ['tags']: {
        type: 'autocomplete',
        label: t('tags'),
        placeholder: t('select_a_tag_or_press'),
        props: {
          freeSolo: true,
          multiple: true,
          options: tags,
          getOptionLabel: (option) => {
            return option.name || option
          },
        },
      },
      'divider-2': { type: 'divider' },
      'section-2': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('timeline')}</Typography>
            <Typography variant="sectionText">{t('timeline_sop_description')}</Typography>
          </HeaderSection>
        ),
      },
      'divider-3': { type: 'divider' },
      ['start_date']: {
        type: 'date',
        label: t('start_date'),
        props: {
          disabled: methods.watch('sp_temp.id') === null,
        },
        onchange: (date) => {
          // console.log('start date changes!!')
          const time = methods.getValues('start_time') || workingTimes[0].start_time

          const formattedDate = dayjs(date)
            .set('hour', dayjs(time).get('hour') || 0)
            .set('minute', dayjs(time).get('minute') || 0)
            .locale(langLocale)
            .format()

          methods.setValue('start_date', formattedDate)

          const calculatedMilestones = calculateDueDate(watchMilestones, formattedDate)
          methods.setValue('milestones', calculatedMilestones) // Update the due date of milestones

          return date
        },
      },
      ['start_time']: {
        type: 'time',
        label: t('start_time'),
        props: {
          disabled: methods.watch('sp_temp.id') === null,
        },
        onchange: (time) => {
          // console.log('start time changes!!')
          const startDate = methods.getValues('start_date') || dayjs().format()
          const date = startDate || dayjs().format()

          const formattedDate = dayjs(date)
            .set('hour', dayjs(time).get('hour') || 0)
            .set('minute', dayjs(time).get('minute') || 0)
            .locale(langLocale)
            .format()

          methods.setValue('start_date', formattedDate)
          methods.setValue('start_time', time)

          const calculatedMilestones = calculateDueDate(watchMilestones, formattedDate)
          methods.setValue('milestones', calculatedMilestones) // Update the due date of milestones

          return time
        },
      },
      'divider-4': { type: 'divider' },
      'workingTimes-section': {
        component: watchSPTempId && (
          <>
            <Alert severity="info">
              <AlertTitle sx={{ fontFamily: "'Inter', 'Noto Sans Thai'" }}>{t('working_times')}</AlertTitle>
              {workingTimes.map((working_time, index) => {
                return (
                  <Typography variant="subtitle2" key={working_time.id}>
                    {'• '} {dayjs(`1970-01-01T${working_time.start_time}`).locale(langLocale).format('HH:mm')} -{' '}
                    {dayjs(`1970-01-01T${working_time.end_time}`).locale(langLocale).format('HH:mm')}
                  </Typography>
                )
              })}
            </Alert>

            {/* {spTemplate?.company?.working_times && spTemplate?.company?.working_times.length > 0 ? (
              spTemplate?.company?.working_times.map((working_time, index) => {
              return (
                <TypographyP key={working_time.id}>
                  {index + 1}: {dayjs(`1970-01-01T${working_time.start_time}`).locale(langLocal).format('HH:mm')} -{' '}
                  {dayjs(`1970-01-01T${working_time.end_time}`).locale(langLocal).format('HH:mm')}
                </TypographyP>
              )})
            : (
            <TypographyP>No working times found.</TypographyP>
            )}
            } */}
          </>
        ),
      },
      ['sws-preview']: {
        component:
          watchMilestones.length > 0 ? (
            <SWTimelinePreview items={watchMilestones} noOppositeContent />
          ) : (
            <Empty title={t('no_template_not_found')} description={t('empty_sows_subtitle')} />
          ),
      },
    },
    required: ['sp_temp.id', 'name', 'start_date'],
  }

  return (
    <Container>
      <DynamicForm ref={ref} methods={methods} onSubmit={onSubmit} schema={formSchema} />
    </Container>
  )
})

export default CreateSP

const HeaderSection = styled('section')`
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  margin-top: 1rem;
`

const Container = styled('div')`
  height: auto;
  min-height: 100%;
  padding: 0 1.25rem 1rem 1.25rem;

  // Webkit scrollbar - style #1
  &::-webkit-scrollbar {
    width: 0.75rem;
    background: rgba(0, 0, 0, 0.25); /* make scrollbar invisible by default */
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    opacity: 0.5;
  }

  &:active::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-track {
    background: var(--Base-White);
    border: 1px solid var(--Gray-200);
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    margin: 0 0.5rem;
  }
`
