import { Stack, useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'
import i18n from 'i18next'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import { BsArrowUpRightCircle } from 'react-icons/bs'
import { FiBookOpen } from 'react-icons/fi'

import Loading from 'components/common/Loading'
import DialogTaskEditor from 'components/dialog/DialogTaskEditor'
import DialogTaskPreviewer from 'components/dialog/DialogTaskPreviewer'
import NotificationSide from 'components/notifications/NotificationSide'
import { BASE_WEB_SOCKET, VERSION } from 'configs/app'
import { CONFIG_WEBSOCKET } from 'configs/websocket'
import { setIsRedirect } from 'redux/actions/master'
import { getNotificationsUnReade } from 'redux/actions/notifications'
import { selectNotificatons } from 'redux/selectors'
import { getAdminUser, getAllLocalAdmin, getCompanyInfo } from 'utils/common'
import Sidebar from './Sidebar'

const AppLayout = () => {
  const { sendJsonMessage, readyState } = useWebSocket(BASE_WEB_SOCKET, CONFIG_WEBSOCKET)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const isMobileAndTabletScreen = useMediaQuery('(max-width:1023px)')

  const user = getAdminUser()
  const CompanyInfo = getCompanyInfo()

  const { isRefreshNotification } = useSelector(selectNotificatons)
  const [permissions, setPermissions] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [widthNonificationSide, setwidthNonificationSide] = useState(0)
  const [openNoti, setOpenNoti] = useState(false)
  const [lang, setLang] = useState()
  const [Company_option, setCompany_option] = useState([])

  const [isShowDialogPreviewer, setIsShowDialogPreviewer] = useState(false)
  const [isShowDialogTaskEditor, setIsShowDialogTaskEditor] = useState(false)
  const [currentPreviewType, setCurrentPreviewType] = useState('')
  const [currentTaskType, setCurrentTaskType] = useState('edit_task')
  const [taskId, setTaskId] = useState('')
  const [requestId, setRequestId] = useState('')
  const [menu, setMenu] = useState('')

  useEffect(() => {
    const initLang = localStorage.getItem('lang')
    setLang(initLang)
    let LocalAdminData = getAllLocalAdmin()
    const Company_option = LocalAdminData?.company_info?.company_list
      ? LocalAdminData?.company_info?.company_list.map((item) => ({
          value: item?.id,
          text: item?.company_name_TH,
        }))
      : []
    setCompany_option(Company_option)

    initData()
    getPermissions()
  }, [])

  // trigger every route has changed.
  useEffect(() => {
    const version = localStorage.getItem('version')

    if (version) {
      const compareVersion = compareVersions(version, VERSION)
      if (compareVersion === -1) {
        autoLogout()
      }
    } else {
      autoLogout()
    }
  }, [location])

  const autoLogout = () => {
    dispatch(setIsRedirect(null))
    localStorage.removeItem('admin')
    navigate('/login', { replace: true })
  }

  function compareVersions(v1, v2) {
    const parts1 = v1.split('.').map(Number)
    const parts2 = v2.split('.').map(Number)

    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
      const num1 = parts1[i] || 0
      const num2 = parts2[i] || 0

      if (num1 < num2) return -1
      if (num1 > num2) return 1
    }

    return 0
  }

  useEffect(() => {
    if (user?.id && readyState === ReadyState.OPEN) {
      sendJsonMessage({
        user_id: user?.id,
        name: user?.first_name + ' ' + user?.last_name,
        type: 'userevent',
      })
    }
  }, [user, sendJsonMessage, readyState])

  const isNotiEvent = (message) => {
    const evt = JSON.parse(message.data)
    return evt.type === 'notification'
  }

  const { lastJsonMessage } = useWebSocket(BASE_WEB_SOCKET, {
    share: true,
    filter: isNotiEvent,
  })

  useEffect(() => {
    dispatch(getNotificationsUnReade())
  }, [lastJsonMessage, dispatch])

  useEffect(() => {
    if (isRefreshNotification) dispatch(getNotificationsUnReade())
  }, [isRefreshNotification, dispatch])

  useEffect(() => {
    dispatch(getNotificationsUnReade())
  }, [dispatch])

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang)
      localStorage.setItem('lang', lang)
    }
  }, lang)

  // initialize data
  const initData = async () => {
    try {
      setIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const getPermissions = () => {
    const { permission } = JSON.parse(localStorage.getItem('admin'))
    setPermissions(permission)
  }

  const handleOpenNotification = () => {
    setOpenNoti(true)

    // if (windowWidth < 1024) {
    //   return setwidthNonificationSide(windowWidth)
    // }

    return setwidthNonificationSide(488)
  }

  const handleCloseNotification = (data) => {
    setOpenNoti(false)
    setwidthNonificationSide(0)
    if (data) {
      if (data.task_type == 'routine') {
        setCurrentPreviewType('task_routine')
      } else {
        setCurrentPreviewType('task_milestone')
      }
      setTaskId(data.task_id)
      setMenu(data.menu || '')
      setRequestId(data.request_approve_id || '')
      setIsShowDialogPreviewer(true)
    }
  }

  const openUserGuide = () => {
    window.open(
      'https://daffodil-wave-c5a.notion.site/CUBE-ERP-System-9677acddf9df453d838bdf80c5560d84',
      '_blank',
      'noopener,noreferrer'
    )
  }

  const openWhatNew = () => {
    window.open(
      'https://daffodil-wave-c5a.notion.site/CUBE-ERP-System-Release-Note-f37677232fb14cfd9f341bd7db463f4f',
      '_blank',
      'noopener,noreferrer'
    )
  }

  return (
    <StyledApp>
      <Sidebar onNotificationClick={handleOpenNotification} />

      <main className="erp_body">
        <div className="erp_body_content">{isLoading ? <Loading /> : <Outlet />}</div>

        {isMobileAndTabletScreen ? (
          <footer className="footer-mobile-tablet">
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <section>
                You are now active in{' '}
                <span className="hightlight">
                  {lang === 'en' ? CompanyInfo?.company_name_EN : CompanyInfo?.company_name_TH}
                </span>
              </section>

              <section className="what-new-user-guide-container">
                <div className="user-guide mr-2-rem" onClick={openUserGuide}>
                  <FiBookOpen className="mr-8-px" size={25} />
                  <span>User Guide</span>
                </div>
                <div className="mr-2-rem">
                  <span className="what-new" onClick={openWhatNew}>
                    <BsArrowUpRightCircle className="mr-8-px" size={25} />
                    <span>What’s New</span>
                  </span>
                </div>
                <span>version {VERSION}</span>
              </section>

              <section>© 2023 CUBE ERP System</section>
            </Stack>
          </footer>
        ) : (
          <footer className="footer">
            <div>
              <div>
                You are now active in{' '}
                <span className="hightlight">
                  {lang === 'en' ? CompanyInfo?.company_name_EN : CompanyInfo?.company_name_TH}
                </span>
              </div>
            </div>
            <div>© 2023 CUBE ERP System</div>

            <div className="d-flex align-items-center">
              <div className="user-guide mr-2-rem" onClick={openUserGuide}>
                <FiBookOpen className="mr-8-px" size={25} />
                <div>User Guide</div>
              </div>
              <div className="what-new mr-2-rem" onClick={openWhatNew}>
                <BsArrowUpRightCircle className="mr-8-px" size={25} />
                <div>What’s New</div>
              </div>
              <div>version {VERSION}</div>
            </div>
          </footer>
        )}
      </main>

      {openNoti && (
        <div
          className={`${widthNonificationSide > 0 ? 'backdrop_NotificationSide' : 'backgroup_NotificationSide close'}`}
        >
          <NotificationSide isOpen={openNoti} width={widthNonificationSide} onClose={handleCloseNotification} />
        </div>
      )}

      {isShowDialogPreviewer && (
        <DialogTaskPreviewer
          taskId={taskId}
          open={isShowDialogPreviewer}
          onClose={() => setIsShowDialogPreviewer(false)}
          previewType={currentPreviewType}
          onEditTask={() => {
            setCurrentTaskType('edit_task')
            setIsShowDialogPreviewer(false)
            setIsShowDialogTaskEditor(true)
          }}
          requestId={requestId}
          menuActive={menu}
        />
      )}

      {isShowDialogTaskEditor && (
        <DialogTaskEditor
          taskId={currentTaskType == 'edit_task' && taskId}
          type={currentTaskType}
          open={isShowDialogTaskEditor}
          onClose={() => setIsShowDialogTaskEditor(false)}
          onSuccess={() => setIsShowDialogTaskEditor(false)}
        />
      )}
    </StyledApp>
  )
}

export default AppLayout

const StyledApp = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  height: 100%;
  overflow: hidden;

  main.erp_body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    min-height: 0;
    height: 100%;
    position: relative;
    margin-top: 70px;

    overflow-y: auto;

    > .erp_body_content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-bottom: 1rem;
      padding: 0.75rem;
    }

    > .footer {
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;

      background: var(--Base-White);
      border-top: 1px solid var(--Gray-200);
      color: var(--Gray-500);
      font-size: 0.875rem;

      display: flex;
      justify-content: space-between;
      flex-shrink: 0;

      padding: 1.5rem;
    }

    > .footer-mobile-tablet {
      position: relative;

      background: var(--Base-White);
      border-top: 1px solid var(--Gray-200);
      color: var(--Gray-500);
      font-size: 0.875rem;

      padding: 1.5rem;

      .what-new-user-guide-container {
        display: flex;
        align-items: center;
      }

      .user-guide {
        color: #0086c9;
        font-weight: 600;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .what-new {
        color: #444ce7;
        font-weight: 600;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .load-more-btn {
    margin-top: 70px;
    padding: 10px 30px;
    align-items: center;
    color: #6941c6;
    border: 1px solid #e9d7fe;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background: #f9f5ff;
    width: 128px;
    height: 36px;
    cursor: pointer;
  }
  .badge-for-noti {
    padding: 2px 8px;
    align-items: center;
    color: var(--Pink-700);
    background: var(--Pink-50);
    border: 1px solid var(--Pink-200);
    border-radius: 16px;
    margin-left: 60px;
  }
  .icon {
    cursor: pointer;
  }
  .mr-2-rem {
    margin-right: 2rem;
  }
  .mr-8-px {
    margin-right: 8px;
  }

  .side-panel-container {
    display: block;
    padding: 2rem;
    min-width: 100%;
  }
  .d-flex {
    display: flex;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .align-items-center {
    align-items: center;
  }

  .create-access-role-header {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .icons {
    height: 100%;
    width: 70%;
    cursor: pointer;
  }

  .mb-1-rem {
    margin-bottom: 1rem;
  }

  > .side-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid var(--Gray-200);

    height: 100%;
    overflow-y: auto;

    box-sizing: border-box;

    .top-wrapper {
      flex: 1;
      display: flex;

      .main-menu {
        padding: 24px 16px;
        background: var(--Side-Menu-Bg);

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .logo {
          width: 100%;
          justify-content: center;
          margin-bottom: 16px;
        }

        .menu {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 16px;
          width: 48px;
          height: 48px;
          border-radius: 6px;

          &:last-child {
            margin-bottom: 0;
          }

          .icon-default {
            color: var(--Side-Menu-Icon);
          }

          &.active {
            background: var(--Side-Menu-Icon-Select-Bg);

            path {
              /* stroke: var(--Side-Menu-Icon-Select); */
            }

            .icon-default {
              color: var(--Side-Menu-Icon-Select);
            }
          }
        }
        .menu-notifications {
          position: relative;
          .notification-icon {
            color: var(--Side-Menu-Icon) !important;
          }
          .notification-badge {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background-color: red;
            border-radius: 50%;
            font-size: 10px;
            font-weight: bold;
            color: #ffffff;

            position: absolute;
            right: 0;
            top: 4px;
          }
        }
      }

      .sub-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding: 24px 16px;
        background: var(--Side-Menu-Open-Bg-Default);
        width: ${(props) => props.widthSideMenu ?? 340}px;

        .header-menu {
          display: flex;
          align-items: center;
          gap: 12px;
          color: var(--Side-Menu-Open-Font-Default);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;

          .icon-header {
            font-size: 24px;
          }
        }

        .menu {
          cursor: pointer;
          display: block;
          margin-top: 4px;
          padding: 8px 12px;
          border-radius: 6px;
          color: var(--Side-Menu-Open-Font-Default);
          font-size: 16;
          font-weight: 600;
          text-decoration: none !important;

          &.active {
            color: var(--Side-Menu-Open-Font-Active);
            background: var(--Side-Menu-Open-Bg-Active);
          }
          &:hover {
            background: var(--Side-Menu-Open-Bg-Hover);
          }
        }
        .div_company_change {
          width: 100%;
          margin: 15px 0px;
        }
        .current-user {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: var(--Base-White);
          bottom: 0;

          .img-profile {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            object-fit: cover;
          }

          .detail {
            flex: 1;
            font-size: 14px;

            .email {
              color: var(--Primary-200);
              font-weight: 400;
            }
          }

          .logout {
            color: var(--Primary-300);
          }
        }
      }
    }
  }

  > .main-content {
    position: relative;

    flex: 1;
    padding: 24px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    box-sizing: border-box;

    > .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      background: var(--Base-White);
      border-top: 1px solid var(--Gray-200);
      color: var(--Gray-500);
      font-size: 0.875rem;

      display: flex;
      justify-content: space-between;
      flex-shrink: 0;

      padding: 1.5rem;
    }

    > .footer-mobile-tablet {
      position: relative;

      background: var(--Base-White);
      border-top: 1px solid var(--Gray-200);
      color: var(--Gray-500);
      font-size: 0.875rem;

      padding: 1.5rem;

      .what-new-user-guide-container {
        display: flex;
        align-items: center;
      }

      .user-guide {
        color: #0086c9;
        font-weight: 600;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .what-new {
        color: #444ce7;
        font-weight: 600;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  .content-block {
    flex: 1 1 auto;
    overflow-y: auto;
    margin-bottom: 4rem;
  }
  .mt-5 {
    margin-top: 5px;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .tab-container {
    display: flex;
    border-bottom: 2px solid rgb(234, 236, 240);
    width: 35%;
    .tab-item {
      cursor: pointer;
      margin-right: 2rem;
      padding-bottom: 1rem;
      padding: 10px;
      &.active {
        color: #7f56d9;
        font-weight: 600;
        border-bottom: 2px solid #7f56d9;
        background-color: rgb(127 86 217 / 13%);
        padding: 10px;
      }
    }
  }

  .notifications {
    border: 1px solid rgb(245 245 245);
    margin: 0px -30px 0px;
  }

  .list-group {
    list-style: none;
    padding: 0;
  }

  .list-group-item {
    border: none;
    background-color: rgb(255 255 255);
    padding: 10px;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  .list-group-item:hover {
    background-color: #f3f3f3;
  }

  .notification-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .notification-sender {
    font-weight: bold;
    margin-right: 10px;
  }

  .notification-action {
    font-size: 14px;
    margin-right: 10px;
  }

  .notification-due-date {
    font-size: 14px;
    span {
      color: #7f56d9;
      font-weight: 600;
    }
  }

  .notification-body {
    margin-bottom: 10px;
  }

  .notification-task,
  .notification-project {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
  }

  .notification-footer {
    color: #999;
    font-size: 14px;
  }

  .notification-date {
    font-weight: bold;
  }

  .img-profile {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  .user_img_char_1 {
    border-radius: 50%;
    background-color: #ffffff;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #000000;
    cursor: pointer;
  }
  .backdrop_NotificationSide {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }

  .lang-container {
    border: 1px solid var(--Base-White);
    border-radius: 8px;
    padding: 4px;
    color: var(--Base-White);

    .divide {
      display: inline-block;
      width: 1px;
      height: 15px;
      background-color: var(--Base-White);
      margin: 0 4px;
    }

    .selector {
      cursor: pointer;

      &.active {
        background: var(--Base-White);
        color: var(--Primary-600);
        border-radius: 4px;
        padding: 2px;
      }
    }
  }

  .hightlight {
    color: var(--Primary-700);
    font-weight: 800;
  }

  .user-guide {
    color: #0086c9;
    font-weight: 600;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .what-new {
    color: #444ce7;
    font-weight: 600;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .group-side-menu-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 1023px) {
    .visible {
      display: block !important;
    }

    .logo {
      display: none;
    }

    .side-menu {
      display: none;

      .top-wrapper {
        .main-menu {
          padding: 70px 8px 24px;
          min-height: 100vh;
        }

        .sub-menu {
          position: fixed;
          left: 82px;
          height: 100%;

          z-index: 98;
          padding: 90px 16px 24px;
        }
      }
    }

    .main-content {
      padding: 0;
      margin-top: 64px;
    }

    .left-0 {
      left: 0;
    }
  }

  @media screen and (max-width: 434px) {
    .main-content {
      > .footer-mobile-tablet {
        font-size: 11px;
      }
    }
  }

  @media (min-width: 640px) {
    main.erp_body {
      > .erp_body_content {
        padding: 1rem;
      }
    }
  }

  // Breakpoint "lg".
  @media (min-width: 1024px) {
    flex-direction: row;

    main.erp_body {
      margin-top: 0;

      > .erp_body_content {
        padding: 2rem;
      }
    }
  }
`
