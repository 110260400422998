import styled from 'styled-components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMeetingAttendances } from '../../redux/actions/dailysummary'

import dayjs from 'dayjs'

import TextField from '../../components/form/TextField'
import InputTextArea from '../../components/widget/element/InputTextArea'
import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconBin from '../../assets/images/icon-bin.svg'
import iconFile from '../../assets/images/icon-file-round.svg'

import FileUpload from '../../components/form/FileUpload'
import { postAPI } from '../../utils/api'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'
import { useTranslation } from 'react-i18next'

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  } 
  
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 90px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill{
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .content{
    margin:10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}

const ModalEditAttendances = ({ onClose, onSubmit,DataEdit,...props  }) => {
  const dispatch = useDispatch()
  const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { t } = useTranslation()
  const [EditData, setEditData] = useState(DataEdit)

  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const ConfirmDialog = () => {
    setModalConfirmDialog(true)
  }
  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)
  const CreateNewDailySummary = () => {
    setModalConfirmDialog(false);
    const sent_data_api = {
      data: EditData,
    }
    dispatch(updateMeetingAttendances(EditData.id, sent_data_api)).then(({ type }) => {
      if(type.endsWith('_SUCCESS')){
        setTitlesuccess(t('success_update_message'));
        setSubTitlesuccess('');
        setDescriptionsuccess(''); 
        setsuccessModal(true)
      }else{
        setTitleFail(t('error_updated'));
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }     
    })
    
    //onSubmit()
  }


  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">{t('edit_attendee_information_header')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">{t('edit_attendee_information_sub_header')}</div>
        <hr />
      </div>
      
      <div className="dialog-content">
        <div className="content">
            <div className="topic_fill">{t('full_name')} *</div>
            <div>
                <TextField
                name="Fullname"
                className="TextField_employee"
                placeholder={t('full_name')}
                value={EditData.full_name}
                onChange={(e) => setEditData({ ...EditData, full_name: e.target.value })}
                />
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">{t('company')} *</div>
            <div>
                <TextField
                name="Fullname"
                className="TextField_employee"
                placeholder={t('company')}
                value={EditData.company}
                onChange={(e) => setEditData({ ...EditData, company: e.target.value })}
                />
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">{t('positions')}</div>
            <div>
                <TextField
                name="Fullname"
                className="TextField_employee"
                placeholder={t('positions')}
                value={EditData.position}
                onChange={(e) => setEditData({ ...EditData, position: e.target.value })}
                />
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">{t('email')}</div>
            <div>
                <TextField
                name="Fullname"
                className="TextField_employee"
                placeholder={t('email')}
                value={EditData.email}
                onChange={(e) => setEditData({ ...EditData, email: e.target.value })}
                />
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">{t('tel')}</div>
            <div>
                <TextField
                name="Fullname"
                className="TextField_employee"
                placeholder={t('tel')}
                value={EditData.phone_no}
                onChange={(e) => setEditData({ ...EditData, phone_no: e.target.value })}
                />
            </div>
        </div>
        <div className="content">
            <div className="topic_fill">{t('note')}</div>
            <div>
                <InputTextArea
                  className="InputTextArea_css"
                  initValue={EditData.note}
                  onChange={(e) => setEditData({ ...EditData, note: e.target.value })}                        
                  placeHolder={t('meeting_note_to_attendee_placeholder')}
                  borderColor={'var(--Gray-300)'}
                  borderWidth="1px"
              />
            </div>
        </div>
      </div>
      
      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={onClose}>
          {t('cancel')}
        </ButtonOutline>
        <Button className="button_margin" onClick={ConfirmDialog}>
          {t('submit')}
        </Button>
      </div>

        <DialogConfirm
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onSubmit={CreateNewDailySummary}
          icon={question}
          title={t('confirm_update_header')}
          description=""
          textYes={t('confirm')}
          textNo={t('back')}
        />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />
    </Div>
  )
}

export default ModalEditAttendances
