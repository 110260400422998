import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useNavigate, NavLink } from 'react-router-dom'
import { getCompanyInfo } from '../../utils/common'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

import { getBaseByZoneIdReturnData,getTaskByBaseIdReturnData } from '../../redux/actions/ofm'

const DivMain = styled.div`
color: var(--Gray-600);
font-size: 14px;
font-style: normal;
font-weight: 500;
.header {
    display: flex;
    align-items: center;

    .highlight {
        color: var(--Primary-700);
        font-weight: 600;
    }
}
.mb-2-rem {
    margin-bottom: 2rem;
}
.mr-8-px {
    margin-right: 8px;
}
.icon {
    cursor: pointer;
}
.content-title {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
}
.border_header{
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
}
.wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        border-bottom: 0px solid #EAECF0;
        color: #667085;
        font-size: 14px;
    }

    .menu-active {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid var(--Primary-600) !important;
        color: #6941C6;
        background-color: #F9F5FF;
    }
}
`

import TabDeletionRequest from '../../pages/operation-field/TabDeletionRequest'
import TabBase from './TabBase'
import TabArchived from '../../pages/operation-field/TabArchived'


function OperationfieldAll() {
    const params = useParams();
    const dispatch = useDispatch()
    const CompanyInfo = getCompanyInfo()
    const { id:zone_id } = params;
    const navigate = useNavigate();

    const [HeaderName,setHeaderName] = useState('');
    
    const [tabactive, setTabactive] = useState('Deletion_Request_Log')
    const [menuTabList, setMenuTabList] = useState([
        {
            name: 'Deletion Request Log',
            value: 'Deletion_Request_Log',
            active_status: true,
        },
        {
            name: 'Archived Data',
            value: 'Archived_Data',
            active_status: false,
        },
    ]);

    const fetchDataAllBase = useCallback(
        async (filter,this_zone_id) => {
            if(this_zone_id){
                dispatch(getBaseByZoneIdReturnData(this_zone_id,filter)).then(({ type,data_info,data_meta }) => {
                    if(type.endsWith('_SUCCESS')){
                        if(data_info){
                            setHeaderName(data_info.name);
                            
                            const newBasesCellData = data_info.bases?.map((v, index) => ({
                                name : v.name,
                                value : v.id,
                                active_status : false,
                            }))
                            setMenuTabList([{name: 'Deletion Request Log' , value: 'Deletion_Request_Log', active_status: true},...newBasesCellData , {name: 'Archived Data' , value: 'Archived_Data', active_status: false}])
                            
                        }
                    }
                })
            }
    
        },
        [dispatch]
     );
    
     useEffect(() => {
        
        setTabactive('Deletion_Request_Log');
        const filter = {
            skip: 1,
            limit: 1000,
        }
        fetchDataAllBase(filter,zone_id)
    }, [fetchDataAllBase,zone_id]) 

    const [BaseId,setBaseId] = useState('');
    const [taskOption, settaskOption] = useState([])
    const fetchDataAllTask = useCallback(
        async (filter) => {
            if(BaseId){
                dispatch(getTaskByBaseIdReturnData(BaseId,filter)).then(({ type,data_info,data_meta }) => {
                    if(type.endsWith('_SUCCESS')){
                        if(data_info){
                            const newtaskOption = data_info.tasks?.map((v, index) => ({
                                value: v.id,
                                text: v.name,
                            }))
                            settaskOption(newtaskOption)
                            
                        }
                    }
                })
            }
    
        },
        [dispatch]
     );

    const handleClickTaskMenuTab = (data) => {
        let tmpList = [...menuTabList]
        for (let i = 0; i < tmpList.length; i++) {
            if (tmpList[i].value == data.value) {
                tmpList[i].active_status = true
                setTabactive(tmpList[i].value)
                if(tmpList[i].value != '' && tmpList[i].value != ''){
                    setBaseId(tmpList[i].value);
                    /*
                    const filter = {
                        skip: 1,
                        limit: 1000,
                    }
                    fetchDataAllTask(filter)
                    */
                }

            } else {
                tmpList[i].active_status = false
            }
        }
        setMenuTabList([...tmpList])
    }

  return (
    <DivMain>
        <div className="header mb-2-rem">
            <RiHome6Line size={20} className="mr-8-px" />
            <MdOutlineKeyboardArrowRight className="mr-8-px" />
            <span className="icon mr-8-px" >
            Operation field
            </span>
            <MdOutlineKeyboardArrowRight className="mr-8-px" />
            <span className="highlight">Production department-Zone : {HeaderName}</span>
        </div>
        <div className="content-title">Zone : {HeaderName}</div>
        <div className="">All your data, organized and ready for review</div>
        <div className="border_header"></div>
        <div className="wrp-tab-menu">
        {menuTabList?.map((item, index) => (
            <button
            id={'this_name-' + item.name}
            key={'btn-' + index}
            className={item.active_status ? 'menu-active' : 'btn-menu-style'}
            onClick={() => handleClickTaskMenuTab(item)}
            >
            {item.name}
            </button>
        ))}
       </div>
       {tabactive == 'Deletion_Request_Log' && (
        <div>
            <TabDeletionRequest ZoneId={zone_id} />
        </div>
       )} 
       {tabactive != 'Deletion_Request_Log' && tabactive != 'Archived_Data' && (
        <div>
            <TabBase BaseId={BaseId} />
        </div>
       )} 
       {tabactive == 'Archived_Data' && (
        <div>
            <TabArchived ZoneId={zone_id} />
        </div>
       )} 
    </DivMain>
  )
}

export default OperationfieldAll