import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../form/button/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import styled from 'styled-components'

const Div = styled.div``

const ButtonCollapsible = ({ id, text, list, icon }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (action) => {
    action()
    setAnchorEl(null)
  }

  return (
    <Div>
      <Button onClick={handleClick} id={id} append={icon ? <img src={icon} /> : <></>}>
        {text}
      </Button>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={() => setAnchorEl(null)}>
        {list?.map((v, i) => (
          <MenuItem id={v.id ? v.id : i} key={v.text} onClick={() => handleClose(v?.action)}>
            {v?.text}
          </MenuItem>
        ))}
      </Menu>
    </Div>
  )
}

ButtonCollapsible.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string.isRequired,
      action: PropTypes.func,
    })
  ),
  icon: PropTypes.string,
}

export default ButtonCollapsible
