import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import Card from '../../common/Card'

import { selectEmployeeProfile } from '../../../redux/selectors'
import { getEmployeeByIDForProfile } from '../../../redux/actions/employeeProfile'

const Div = styled.div`
  .mr-12-px {
    margin-right: 12px;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-12-px {
    margin-bottom: 12px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }

  .file-link {
    cursor: pointer;
  }
`
const InfoContainer = styled.div`
  .heading-name {
    color: var(--Gray-900);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
`
const BlogInfo = styled.section`
  margin-bottom: 20px;
  .card-width-100 {
    width: 100%;
  }
  .heading-info {
    color: var(--Gray-900);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }
  .info-heading {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    width: 30%;
  }
  .info {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .info-2 {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    width: 65%;
  }
  .file {
    width: 65%;
  }
  .file-info {
    .name {
      color: var(--Gray-900);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .size {
      color: var(--Gray-600);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .edit-btn {
    padding: 8px;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-end {
    justify-content: flex-end;
  }

  &.align-item-center {
    align-items: center;
  }
`

function ProfessionalBackground() {
  const dispatch = useDispatch()
  const { employeeInfo } = useSelector(selectEmployeeProfile)

  const fetchEmployeeProfileInfo = useCallback(() => {
    dispatch(getEmployeeByIDForProfile())
  }, [dispatch])

  useEffect(() => {
    fetchEmployeeProfileInfo()
  }, [])

  return (
    <Div>
      <InfoContainer className="mb-32-px">
        <h3 className="heading-name">Professional Background</h3>
      </InfoContainer>
      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">Education Information</h4>
        </header>
        <Card className="card-width-100">
          {employeeInfo?.employee_educations?.data.map((educationData) => {
            const { school_type, school_name, entry_year, graduate_year } = educationData.attributes
            const schoolType =
              school_type === 'elementary school'
                ? 'Elementary school'
                : school_type === 'junior high school'
                ? 'Junior high school'
                : 'Senior hign school'
            return (
              <React.Fragment key={educationData.id}>
                <FlexContainer className="align-item-center mb-12-px">
                  <div className="info-heading">{schoolType}</div>
                  <div className="info-2">{school_name ?? '-'}</div>
                </FlexContainer>
                <FlexContainer className="mb-12-px">
                  <div className="info-heading">Entry year</div>
                  <div className="info">{entry_year ?? '-'}</div>
                </FlexContainer>
                <FlexContainer className="mb-12-px">
                  <div className="info-heading">Graduate year</div>
                  <div className="info">{graduate_year ?? '-'}</div>
                </FlexContainer>
              </React.Fragment>
            )
          })}
        </Card>
      </BlogInfo>
      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">University</h4>
        </header>
        <Card className="card-width-100">
          {employeeInfo?.employee_universities?.data.map((educationData) => {
            const { certificate_level, field_study, university_name, entry_year, graduate_year } =
              educationData.attributes
            return (
              <React.Fragment>
                <FlexContainer className="align-item-center mb-12-px">
                  <div className="info-heading">Certificate level</div>
                  <div className="info-2">
                    {certificate_level === '' || certificate_level === null || certificate_level === undefined
                      ? '-'
                      : certificate_level}
                  </div>
                </FlexContainer>
                <FlexContainer className="mb-12-px">
                  <div className="info-heading">Field of study</div>
                  <div className="info">{field_study ?? '-'}</div>
                </FlexContainer>
                <FlexContainer className="mb-12-px">
                  <div className="info-heading">University</div>
                  <div className="info">{university_name ?? '-'}</div>
                </FlexContainer>
                <FlexContainer className="mb-12-px">
                  <div className="info-heading">Entry year</div>
                  <div className="info">{entry_year ?? '-'}</div>
                </FlexContainer>
                <FlexContainer className="mb-12-px">
                  <div className="info-heading">Graduate year</div>
                  <div className="info">{graduate_year ?? '-'}</div>
                </FlexContainer>
              </React.Fragment>
            )
          })}
        </Card>
      </BlogInfo>
    </Div>
  )
}

export default ProfessionalBackground
