import { useState } from 'react'
import styled from 'styled-components'
import OptionBox from '../combo/OptionBox'

export default function DocumentOptionButton({ onClicked }) {
  const [optionList, setOptionList] = useState([
    { id: 'request_approve', text: 'Request Approve' },
    { id: 'preview', text: 'Preview' },
    { id: 'download_pdf', text: 'Download PDF' },
    { id: 'print', text: 'Print' },
    { id: 'share', text: 'Share' },
  ])

  const handleSelectMenu = async (selectIndex) => {
    if (optionList[selectIndex]?.id == 'request_approve') {
      if (onClicked) {
        onClicked('request_approve')
      }
    } else if (optionList[selectIndex]?.id == 'preview') {
      if (onClicked) {
        onClicked('preview')
      }
    } else if (optionList[selectIndex]?.id == 'download_pdf') {
      if (onClicked) {
        onClicked('download_pdf')
      }
    } else if (optionList[selectIndex]?.id == 'print') {
      if (onClicked) {
        onClicked('print')
      }
    } else if (optionList[selectIndex]?.id == 'share') {
      if (onClicked) {
        onClicked('share')
      }
    }
  }

  return (
    <Styled>
      <OptionBox
        isVertical
        className={'option-box'}
        datalist={optionList}
        onSelected={(selectIndex) => handleSelectMenu(selectIndex)}
      />
    </Styled>
  )
}

const Styled = styled.div``
