import { Button, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RiSearchLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'

import { Input } from 'components/form2/form-input'
import { Option, Select } from 'components/form2/form-select'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'

const defaultValues = {
  name: '',
  department: {
    id: null,
  },
}

const SopTplFilter = forwardRef(function SopTplFIlter(props, ref) {
  const { t } = useTranslation()
  const { onFormChange } = props

  const form = useForm({ defaultValues })
  const opProcSelector = useSelector(selectOpProc)

  const { departments } = opProcSelector

  const { control, watch, reset } = form
  const watchDepartment = watch('department.id')
  const watchAllFields = watch()

  useImperativeHandle(ref, () => ({
    reset() {
      form.reset(defaultValues)
    },
  }))

  const handleClear = () => {
    form.reset(defaultValues)
    onFormChange && typeof onFormChange === 'function' && onFormChange(defaultValues)
  }

  useEffect(() => {
    onFormChange && typeof onFormChange === 'function' && onFormChange(watchAllFields)
  }, [watchDepartment])

  return (
    <Stack sx={{ width: 'auto', overflow: 'overlay' }}>
      <Form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()

            form.setFocus('department.id')
            onFormChange && typeof onFormChange === 'function' && onFormChange(watchAllFields)
          }
        }}
      >
        <section>
          <label>Search for name or code</label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  placeholder="Type to search name or code"
                  sx={{ minWidth: '320px' }}
                  startAdornment={<RiSearchLine fontSize={16} style={{ margin: '0 0 0 0.75rem' }} />}
                />
              )
            }}
          />
        </section>

        <section>
          <label>{t('department')}</label>
          <Controller
            name="department.id"
            control={control}
            render={({ field }) => {
              const handleChange = (event, value) => {
                field.onChange(value)
              }

              const renderValue = (option) => {
                if (!option || option === null) {
                  return <div style={{ color: 'var(--Gray-400)' }}>{t('department_placeholder')}</div>
                }

                return option?.label
              }

              return (
                <Select {...field} onChange={handleChange} renderValue={renderValue}>
                  {departments?.map((option) => {
                    const { id: value, name: label } = option || {}

                    return (
                      <Option key={value} value={value}>
                        {label}
                      </Option>
                    )
                  })}
                </Select>
              )
            }}
          />
        </section>

        <section className="filter-actions">
          <MuiButton variant="contained" className="gray" onClick={handleClear}>
            {t('clear')}
          </MuiButton>
        </section>
      </Form>
    </Stack>
  )
})

export default SopTplFilter

const Form = styled('form')`
  display: flex;
  /* flex-direction: column; */
  gap: 1rem;
  width: auto;

  section {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &.filter-actions {
      align-items: flex-end;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  section > label {
    color: 'var(--Gray-900)';
    font-size: 14px;

    & .error {
      color: red !important;
    }
  }
`

const MuiButton = styled(Button)`
  font-family: 'Inter', 'Noto Sans Thai';
  color: var(--Main-Button-Font);
  background-color: var(--Main-Button-Bg);
  border-radius: 0.5rem;
  text-transform: capitalize;
  box-shadow: none;
  height: fit-content;
  padding: 0.5rem 1rem;

  &:hover {
    color: var(--Main-Button-Font-Hover);
    background-color: var(--Main-Button-Bg-Hover);
  }

  &.gray {
    color: var(--Gray-900);
    background-color: var(--Base-White);
    border: 1px solid var(--Gray-200);

    &:hover {
      color: var(--Gray-900);
      background-color: var(--Gray-50);
      box-shadow: 0px 2px 2px var(--Gray-50);
    }
  }
`
