import { DialogTitle, IconButton, Modal, ModalClose, ModalDialog, Tooltip } from '@mui/joy'
import { Checkbox, Container as MuiContainer, Stack, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { BsPip } from 'react-icons/bs'
import { CgArrowsExpandLeft } from 'react-icons/cg'
import { RiAddLine, RiDeleteBinLine, RiEyeLine } from 'react-icons/ri'

import Button from 'components/common/Button'
import Chip from 'components/common/Chip'
import Tabs from 'components/common/Tabs'
import Typography from 'components/common/Typography'
import ViewSowTpl from 'components/feature/operation-process/form/sow-tpl/view'
import DynamicForm from 'components/form2/dynamic-form'
import { Autocomplete, DynamicFieldArray, FieldLabel } from 'components/form2/renderFields'
import { SOP_TEMPLATE } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'

const initModalProps = {
  sow: {
    id: null,
    open: false,
    layout: 'center',
  },
}

const CreateSopTpl = forwardRef(function CreateSopTpl(props, formRef) {
  const { onSubmit, id, readonly, containerProps, noExtraActions } = props
  const lang = localStorage.getItem('lang') || 'en'
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [approverPosOptions, setApproverPosOptions] = useState([])
  const [previewModalProps, setPreviewModalProps] = useState(initModalProps)

  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)

  const { extra: extraActions, sopTpl: sopTplActions, sowTpl: sowTplActions } = opActions()
  const { departments, sopTpl, sowTpls, sowTpl } = opProcSelector

  const { getExtra } = extraActions()
  const { getSopTpl, postSopTpl, deleteSopTpl, clearSopTpl } = sopTplActions()
  const { getSowTpl, clearSowTpl } = sowTplActions()

  const fetchSopTplByID = async (sopTplId) => {
    return dispatch(getSopTpl(sopTplId).byId())
  }

  const fetchDepartments = useCallback(
    async (payload) => {
      return dispatch(getExtra().departments(payload))
    },
    [dispatch]
  )

  const fetchSopTpls = useCallback(
    async (payload) => {
      return dispatch(getSopTpl().list(payload))
    },
    [dispatch]
  )

  const fetchSowTpls = useCallback(
    async (payload) => {
      return dispatch(getSowTpl().list({ ...payload, pagination: { page: 1, pageSize: 1000 } }))
    },
    [dispatch]
  )

  const postActiveSopTpl = async (sopTplId) => {
    return dispatch(postSopTpl(sopTplId).active())
  }

  const postInActiveSopTpl = async (sopTplId) => {
    return dispatch(postSopTpl(sopTplId).inactive())
  }

  const defaultValues = {
    name: '',
    description: '',
    department: undefined,
    milestones: [],
  }

  const methods = useForm({
    defaultValues: async () => {
      await fetchDepartments().then((res) => {
        if (res?.data) {
          const allPositions = res?.data?.reduce((acc, curr) => {
            return acc.concat(curr.positions)
          }, [])

          setApproverPosOptions(allPositions)
        }
      })
      await fetchSowTpls()

      if (id) {
        const response = await fetchSopTplByID(id)

        if (response?.data) {
          return response.data
        }

        return defaultValues
      }

      return defaultValues
    },
  })

  const handleToggleModal = (modalKey, isOpen) => {
    setPreviewModalProps((prev) => ({
      ...prev,
      [modalKey]: {
        ...prev[modalKey],
        open: isOpen || !prev[modalKey].open,
      },
    }))
  }

  const handlePreviewSow = (sowId) => {
    setPreviewModalProps((prev) => {
      return {
        ...prev,
        sow: {
          ...prev.sow,
          id: sowId,
          open: true,
        },
      }
    })
  }

  const formSchema = {
    properties: {
      'section-1': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('detail')}</Typography>
            <Typography variant="sectionText">{t('sop_tpl_detail_subtitle')}</Typography>
          </HeaderSection>
        ),
      },
      'divider-1': { type: 'divider' },
      name: {
        type: 'text',
        label: t('sop_tpl_name'),
        placeholder: t('sop_tpl_name_placeholder'),
        validator: {
          required: true,
          message: 'This field is required',
          minLength: 1,
          maxLength: 50,
        },
      },
      description: {
        type: 'textArea',
        label: t('sop_tpl_guideline'),
        props: {
          minRows: 3,
          maxRows: 5,
          multiline: true,
        },
      },
      department: {
        type: 'controlled-autocomplete',
        viewValue: (value) => value?.name,
        label: t('department'),
        placeholder: t('department_placeholder'),
        props: {
          options: departments?.map((dept) => ({ id: dept.id, name: dept.name })) || [],
          onChange(_event, selectVal) {
            methods.setValue('department', selectVal || undefined)
            methods.setValue('positions', [])
          },
          getOptionLabel(option) {
            return option.name || option
          },
          ...(id && {
            disabled: true,
          }),
        },
      },
    },
    required: !readonly ? ['name', 'department'] : [],
  }

  const formSchema_2 = {
    properties: {
      'section-2': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('sow_list_title')}</Typography>
            <Typography variant="sectionText">{t('sow_list_subtitle')}</Typography>
          </HeaderSection>
        ),
      },
      'divider-2': { type: 'divider' },
      milestones: {
        type: 'fieldArray',
        props: {
          render({ field, index, remove }) {
            const key = `milestones.${index}`
            const sowSelectedId = methods.getValues(`${key}.sw.id`)
            // console.log(index, field)
            // console.log(`watch "${key}.sw.department.id"`, methods.watch(`${key}.sw.department.id`))

            return (
              <Draggable
                key={field.fieldId}
                draggableId={`milestones-item-${field.fieldId}`}
                index={index}
                isDragDisabled={readonly}
              >
                {(provided, snapshot) => {
                  const { isDragging } = snapshot || {}

                  return (
                    <ContentList
                      ref={provided.innerRef}
                      className={`${isDragging ? 'dragging' : ''} ${
                        methods.formState.errors?.milestones?.[index] ? 'error' : ''
                      } `}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Stack
                        className="sow-item-title"
                        sx={{
                          padding: '0.5rem 0.75rem',
                          borderRadius: '0.5rem',
                          background: 'var(--Primary-50)',
                          border: '1px solid var(--Primary-200)',
                          marginBottom: '1rem',
                          width: 'fit-content',
                        }}
                      >
                        <Typography variant="cardTitle" sx={{ color: 'var(--Primary-700)' }}>{`${t('sow')} – ${
                          index + 1
                        }`}</Typography>
                      </Stack>

                      <section key={`${key}.sw.department.id`}>
                        <label>{t('department')}:</label>
                        <Stack gap="0.5rem">
                          <Stack direction="row" gap="1rem" alignItems="center">
                            {readonly ? (
                              methods.getValues(`${key}.sw.department.name`)
                            ) : (
                              <Controller
                                control={methods.control}
                                name={`${key}.sw.department`}
                                rules={{ required: `กรุณาเลือก department` }}
                                defaultValue={field?.sw?.department || undefined}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    onChange={(val, selectVal) => {
                                      if (!selectVal) {
                                        methods.setValue(`${key}.sw`, null)
                                      }

                                      methods.setValue(`${key}.sw.department`, selectVal)
                                      methods.setValue(`${key}.sw.id`, null)
                                      methods.setValue(`${key}.sw.name`, null)

                                      field.onChange(selectVal)
                                    }}
                                    value={field.value || methods.watch(`${key}.sw.department`)}
                                    options={departments || []}
                                    getOptionLabel={(option) => option?.name || ''}
                                    renderOption={(props, option, { selected }) => (
                                      <AutocompleteOption {...props}>
                                        <p className="title">{`${option?.name}`}</p>
                                      </AutocompleteOption>
                                    )}
                                    isOptionEqualToValue={(option, value) => {
                                      return option?.id === value?.id
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} placeholder={t('department_placeholder')} />
                                    )}
                                    sx={{ width: '100%' }}
                                  />
                                )}
                              />
                            )}
                          </Stack>
                        </Stack>
                      </section>

                      <section key={`${key}.sw`}>
                        <label>{t('sow')}:</label>
                        <Stack gap="0.5rem">
                          <Stack direction="row" gap="1rem" alignItems="center">
                            {readonly ? (
                              methods.getValues(`${key}.sw.name`)
                            ) : (
                              <Controller
                                control={methods.control}
                                name={`${key}.sw`}
                                rules={{ required: `กรุณาเลือก${field?.label}` }}
                                defaultValue={field?.sw || undefined}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    disabled={!methods.watch(`${key}.sw.department.id`)}
                                    onChange={(val, selectVal) => {
                                      methods.setValue(`${key}.sw.id`, selectVal?.id)
                                      methods.setValue(`${key}.sw.name`, selectVal?.name)
                                      methods.setValue(`${key}.sw.code`, selectVal?.code)

                                      field.onChange(selectVal)
                                    }}
                                    value={methods.watch(`${key}.sw`)}
                                    options={
                                      sowTpls?.filter(
                                        (i) => i?.department?.id === methods.watch(key)?.sw?.department?.id
                                      ) || []
                                    }
                                    getOptionLabel={(option) => {
                                      if (option?.id && option?.name) {
                                        return `${option.name} - ${option.code}`
                                      }

                                      return ''
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                      <AutocompleteOption {...props}>
                                        <p className="title">{`${option?.name}`}</p>
                                        <p className="supporting-text">{`${option?.code}`}</p>
                                      </AutocompleteOption>
                                    )}
                                    isOptionEqualToValue={(option, value) => {
                                      return option?.id === value?.id
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} placeholder={t('sow_placeholder')} />
                                    )}
                                    sx={{ width: '100%' }}
                                  />
                                )}
                              />
                            )}

                            {methods.watch('milestones')?.[index]?.sw?.department?.id && (
                              <IconButton
                                onClick={() => sowSelectedId && handlePreviewSow(sowSelectedId)}
                                size="sm"
                                variant="outlined"
                              >
                                <RiEyeLine />
                              </IconButton>
                            )}
                          </Stack>
                        </Stack>
                      </section>

                      <section>
                        <FieldLabel></FieldLabel>
                        <Stack sx={{ gap: '0.5rem' }} direction="row">
                          <Stack sx={{ gap: '0.25rem' }} direction="row" alignItems="center">
                            <Controller
                              name={`${key}.requiredReviewer`}
                              control={methods.control}
                              defaultValue={methods.watch(`${key}.reviewers`)?.length > 0}
                              render={({ field }) => (
                                <Checkbox
                                  {...field}
                                  disabled={readonly}
                                  checked={field.value}
                                  onChange={(e) => {
                                    const value = e.target.checked

                                    if (!value) {
                                      methods.setValue(`${key}.reviewers`, [])
                                    } else {
                                      methods.setValue(`${key}.reviewers`, [{ position: null }])
                                    }

                                    field.onChange(e)
                                  }}
                                />
                              )}
                            />

                            <Typography variant="fieldLabel">{t('require_review')}</Typography>
                          </Stack>

                          <Stack sx={{ gap: '0.25rem' }} direction="row" alignItems="center">
                            <Controller
                              name={`${key}.requiredApprover`}
                              control={methods.control}
                              defaultValue={methods.getValues(`${key}.approver`)}
                              render={({ field }) => (
                                <Checkbox
                                  {...field}
                                  disabled={readonly}
                                  checked={field.value}
                                  onChange={(e) => {
                                    const value = e.target.checked

                                    if (!value) {
                                      methods.setValue(`${key}.approver`, null)
                                    }

                                    field.onChange(e)
                                  }}
                                />
                              )}
                            />

                            <Typography variant="fieldLabel">{t('require_approve')}</Typography>
                          </Stack>
                        </Stack>
                      </section>

                      {methods.watch(`${key}.requiredReviewer`) && (
                        <section key={`${key}.reviewers`} className="section-card-type">
                          <label>{t('reviewers')}</label>
                          <DynamicFieldArray
                            name={`${key}.reviewers`}
                            control={methods.control}
                            isCanDrop={false}
                            render={({ field, index, remove }) => {
                              const reviewerKey = `${key}.reviewers.${index}`
                              const reviewerPositionName = methods.getValues(`${reviewerKey}.position.name`)
                              const departmentId = methods.watch(`${key}.sw.department.id`)

                              return (
                                <PositionContainer
                                  className={`position-field-container ${index === 0 ? 'first-item' : ''} ${
                                    readonly ? 'readonly' : 'not-readonly'
                                  }`}
                                >
                                  {readonly ? (
                                    <Chip label={reviewerPositionName} color="primary" sx={{ p: '4px 8px' }} />
                                  ) : (
                                    <>
                                      <Autocomplete
                                        key={field.id}
                                        {...methods.register(`${reviewerKey}.position`, {
                                          required: `กรุณาเลือก${field.label}`,
                                        })}
                                        id={key}
                                        options={departments?.find((i) => i.id === departmentId)?.positions || []}
                                        defaultValue={field?.position}
                                        onChange={(val, selectVal) => {
                                          methods.setValue(`${reviewerKey}.position`, selectVal)
                                          methods.setValue(`${reviewerKey}.hr`, 0)
                                        }}
                                        getOptionLabel={(option) => {
                                          return option?.name ? `${option.name}` : ''
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                          <AutocompleteOption {...props}>
                                            <p className="title">{`${option?.name}`}</p>
                                          </AutocompleteOption>
                                        )}
                                        renderInput={(params) => (
                                          <TextField {...params} placeholder={t('position_placeholder')} />
                                        )}
                                        sx={{ width: '100%' }}
                                      />

                                      <Tooltip title={t('remove_position')} placement="right">
                                        <IconButton
                                          className="remove-button"
                                          size="sm"
                                          onClick={() => {
                                            const reviewers = methods.watch(`${key}.reviewers`)

                                            remove(index)
                                            methods.setValue(
                                              `${key}.reviewers`,
                                              reviewers.filter((_, i) => i !== index)
                                            )
                                          }}
                                        >
                                          <RiDeleteBinLine fontSize={16} />
                                        </IconButton>
                                      </Tooltip>
                                    </>
                                  )}
                                </PositionContainer>
                              )
                            }}
                            actions={({ append }) => {
                              if (readonly) {
                                return null
                              }

                              return (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    append({ position: null })
                                  }}
                                  startIcon={<RiAddLine />}
                                  sx={{ marginTop: methods.watch(`${key}.reviewers`)?.length > 0 && '1rem' }}
                                >
                                  {t('add_more_reviewer')}
                                </Button>
                              )
                            }}
                          />
                        </section>
                      )}

                      {methods.watch(`${key}.requiredApprover`) && (
                        <section key={`${key}.approver.position`} className="section-card-type">
                          <label>{t('approver')}</label>
                          <Stack gap="0.5rem">
                            <Stack direction="row" gap="1rem" alignItems="center">
                              {readonly ? (
                                <Chip
                                  label={methods.getValues(`${key}.approver.position.name`)}
                                  color="primary"
                                  sx={{ p: '4px 8px' }}
                                />
                              ) : (
                                <Controller
                                  control={methods.control}
                                  name={`${key}.approver.position`}
                                  rules={{ required: `กรุณาเลือก approver's position` }}
                                  defaultValue={field?.approver?.position || undefined}
                                  render={({ field }) => (
                                    <Autocomplete
                                      {...field}
                                      onChange={(val, selectVal) => {
                                        methods.setValue(`${key}.approver.position`, selectVal)
                                        methods.setValue(`${key}.approver.hr`, 0)
                                      }}
                                      value={field.value || methods.watch(`${key}.approver.position`)}
                                      options={approverPosOptions}
                                      getOptionLabel={(option) => option?.name || ''}
                                      renderOption={(props, option, { selected }) => (
                                        <AutocompleteOption {...props}>
                                          <p className="title">{`${option?.name}`}</p>
                                        </AutocompleteOption>
                                      )}
                                      isOptionEqualToValue={(option, value) => {
                                        return option?.id === value?.id
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} placeholder={t('position_placeholder')} />
                                      )}
                                      sx={{ width: '100%' }}
                                    />
                                  )}
                                />
                              )}
                            </Stack>
                          </Stack>
                        </section>
                      )}

                      {!readonly && (
                        <Tooltip title={'remove_sow'}>
                          <IconButton
                            className="remove-button"
                            size="sm"
                            onClick={() => {
                              const milestones = methods.watch('milestones')

                              remove(index)
                              methods.setValue(
                                'milestones',
                                milestones.filter((_, i) => i !== index)
                              )
                            }}
                          >
                            <RiDeleteBinLine fontSize={16} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ContentList>
                  )
                }}
              </Draggable>
            )
          },
          ...(!readonly && {
            actions({ append }) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    marginTop: methods.getValues('milestones')?.length > 0 && '1rem',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      append({ sw: { department: { id: null }, id: null } })
                    }}
                    startIcon={<RiAddLine />}
                  >
                    {t('add_sow')}
                  </Button>
                </Stack>
              )
            },
          }),
        },
      },
    },
  }

  const tabItems = [
    {
      key: 'overview',
      label: t('overview'),
      content: (
        <DynamicForm
          ref={formRef}
          methods={methods}
          onSubmit={onSubmit}
          schema={formSchema}
          readonly={readonly}
          formProps={{
            className: 'sop-tpl-create-form',
          }}
        />
      ),
    },
    {
      key: 'sow',
      label: t('sow'),
      content: (
        <DynamicForm
          ref={formRef}
          methods={methods}
          onSubmit={onSubmit}
          schema={formSchema_2}
          readonly={readonly}
          formProps={{
            className: 'sop-tpl-create-form',
          }}
        />
      ),
    },
  ]

  const readonlyTabItems = [
    {
      key: 'logs',
      label: t('logs'),
      content: 'Not available yet.',
    },
  ]

  const handleEditClick = () => {
    if (sopTpl?.id) {
      navigate(`${location.pathname}?id=${sopTpl.id}&mode=edit`)
    }
  }

  const handleToggleStatus = () => {
    if (sopTpl) {
      switch (sopTpl.status) {
        case SOP_TEMPLATE.STATUS.Active:
          postInActiveSopTpl(sopTpl.id).then(() => {
            dispatch(getSopTpl(sopTpl.id).byId())
            fetchSopTpls()
          })
          break

        case SOP_TEMPLATE.STATUS.Inactive:
          postActiveSopTpl(sopTpl.id).then(() => {
            dispatch(getSopTpl(sopTpl.id).byId())
            fetchSopTpls()
          })
          break

        default:
          break
      }
    }
  }

  const handleDeleteSowTpl = () => {
    if (sopTpl?.id) {
      dispatch(deleteSopTpl(sopTpl.id)).then(() => {
        navigate(location.pathname)
        clearSopTpl()
        fetchSopTpls()
      })
    }
  }

  const renderExtraButton = () => {
    let componentsArray = []

    if (sopTpl?.status === SOP_TEMPLATE.STATUS.Inactive) {
      componentsArray.push(
        <Button variant="outlined" onClick={handleToggleStatus}>
          {t('active')}
        </Button>,
        <Button variant="outlined" color="error" onClick={handleDeleteSowTpl}>
          {t('delete')}
        </Button>
      )
    }

    if (sopTpl?.status === SOP_TEMPLATE.STATUS.Active) {
      componentsArray.push(
        <Button variant="outlined" color="error" onClick={handleToggleStatus}>
          {t('in_active')}
        </Button>
      )
    }

    return (
      <>
        {componentsArray.map((component, index) => {
          return component
        })}
        <Button variant="outlined" color="primary" onClick={handleEditClick}>
          {t('Edit')}
        </Button>
      </>
    )
  }

  useEffect(() => {
    if (methods.formState.errors) {
      // console.log(methods.formState.errors)
    }
  }, [methods.formState])

  return (
    <>
      <FormProvider {...methods}>
        <Container className="sow-tpl-form-container" sx={{ ...containerProps }}>
          <Tabs
            items={[...tabItems, ...(readonly ? readonlyTabItems : [])]}
            defaultActiveKey="overview"
            sx={{ marginTop: '0.5rem' }}
            tabBarExtraContent={
              readonly && !noExtraActions && <TabBarExtraContainer>{renderExtraButton()}</TabBarExtraContainer>
            }
          />
        </Container>
      </FormProvider>

      <Modal
        open={previewModalProps.sow.open}
        onClose={() => {
          handleToggleModal('sow', false)
          dispatch(clearSowTpl())
        }}
      >
        <ModalDialog
          layout={previewModalProps.sow.layout}
          maxWidth={previewModalProps.sow.layout === 'fullscreen' ? '100vw' : 1440}
          minWidth={1440}
          sx={{
            padding: '0',
            background: 'var(--Base-White)',
          }}
        >
          <ModalClose />
          <DialogTitle
            sx={{
              justifyContent: 'flex-end',
              marginInlineEnd: '2.5rem',
              marginInlineStart: '1.5rem',
              paddingTop: '0.5rem',
              alignItems: 'center',
            }}
          >
            <Typography variant="sidepanelTitle" sx={{ flex: 'auto' }}>
              {t('preview')}: {sowTpl?.code}
            </Typography>

            {previewModalProps.sow.layout === 'center' ? (
              <Tooltip title={t('expand_fullscreen')}>
                <IconButton
                  onClick={() =>
                    setPreviewModalProps((prev) => {
                      return {
                        ...prev,
                        sow: {
                          ...prev.sow,
                          layout: 'fullscreen',
                        },
                      }
                    })
                  }
                  size="sm"
                >
                  <CgArrowsExpandLeft fontSize={13} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t('minimize_to_center')}>
                <IconButton
                  onClick={() =>
                    setPreviewModalProps((prev) => {
                      return {
                        ...prev,
                        sow: {
                          ...prev.sow,
                          layout: 'center',
                        },
                      }
                    })
                  }
                  size="sm"
                >
                  <BsPip fontSize={16} />
                </IconButton>
              </Tooltip>
            )}
          </DialogTitle>

          <Stack
            sx={{
              height: '100%',
              overflow: 'overlay',
              alignItems: 'center',
              mx: 'calc(-1 * var(--ModalDialog-padding))',
              px: 'var(--ModalDialog-padding)',
            }}
          >
            <MuiContainer maxWidth="xl">
              <ViewSowTpl id={previewModalProps.sow.id} noExtraActions readonly />
            </MuiContainer>
          </Stack>
        </ModalDialog>
      </Modal>
    </>
  )
})

export default CreateSopTpl

const TabBarExtraContainer = styled('div')`
  display: flex;
  gap: 0.5rem;
`

const PositionContainer = styled('div')`
  display: flex;
  gap: 0.25rem;
  position: relative;

  & > button.remove-button {
    position: static;
    scale: 1;
  }

  &.position-field-container {
    &:not(.first-item) {
      margin-top: 0.5rem;
    }

    &.readonly {
    }

    &.not-readonly {
    }
  }

  @media (min-width: 640px) {
    &.position-field-container {
      > .MuiAutocomplete-root {
        max-width: 300px;
      }
    }
  }
`

const HeaderSection = styled('section')`
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  margin-top: 1rem;
`

const Container = styled('div')`
  height: auto;
  padding: 0 1.25rem 1rem 1.25rem;
  overflow &::-webkit-scrollbar {
    width: 0.75rem;
    background: rgba(0, 0, 0, 0.25); /* make scrollbar invisible by default */
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    opacity: 0.5;
  }

  &:active::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-track {
    background: var(--Base-White);
    border: 1px solid var(--Gray-200);
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    margin: 0 0.5rem;
  }
`

const AutocompleteOption = styled('li')`
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 0 0.75rem !important;
  gap: 0.5rem;

  .title {
    font-size: 14px;
    font-weight: 500;
    color: var(--Gray-700);
    line-height: 20px;
  }

  .supporting-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--Gray-500);
  }
`

const ContentList = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  background-color: var(--Base-White);
  padding: 1.5rem 3rem 1rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid var(--Gray-200);
  position: relative;
  transition: all 100ms ease-in-out;
  gap: 1rem;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

  &.error {
    border-color: var(--Error-500);
  }

  section.section-card-type {
    padding: 1rem 1.5rem;
    border: 1px solid var(--Gray-200);
    border-radius: 0.5rem;
  }

  &.dragging {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.8);
  }

  .remove-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    scale: 0;
    transition: all 100ms ease-in-out;
  }

  &:hover {
    scale: 1.005;

    .remove-button {
      scale: 1;
    }
  }

  @media not all and (min-width: 640px) {
    padding: 1rem 1.5rem 1rem 1.5rem;
    gap: 0.75rem;
  }
`
