import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import ContentForm from './ContentForm'
import { getCmsContentConfig, createCmsContent } from '../../redux/actions/cms'
import { selectCmsContentType, selectCmsByType } from '../../redux/selectors'

const Div = styled.div`
  .content-title {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
    margin-bottom: 24px;
  }
`

const ContentCreateForm = ({ type }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { info } = useSelector(selectCmsContentType(type))
  const { config } = useSelector(selectCmsByType(type))

  const { displayName } = info || {}
  const { contentType } = config || {}
  const { uid } = contentType || {}

  const goToContentList = () => {
    navigate(pathname.replace('/create', ''), {
      replace: true,
    })
  }

  const createContent = (data) => {
    dispatch(createCmsContent(uid, data)).then(({ error }) => {
      if (!error) {
        goToContentList()
      }
    })
  }

  useEffect(() => {
    if (!config) {
      dispatch(getCmsContentConfig(type))
    }
  }, [config, dispatch, type])

  return (
    <Div>
      <div className="content-title">Add New {displayName}</div>
      <ContentForm type={type} submitText="Create" onSubmit={createContent} onCancel={goToContentList} />
    </Div>
  )
}

export default ContentCreateForm
