import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DateTimeHHmmssDisplay } from '../../../utils/common'

import styled from 'styled-components'
import KPITableData from './KPITableData'
import NewAccordion from '../../common/NewAccordion'

import { TbBuildingBank } from 'react-icons/tb'
import { ImTree } from 'react-icons/im'
import { CgFileDocument } from 'react-icons/cg'

import { selectKPI } from '../../../redux/selectors'
import { getKpiById } from '../../../redux/actions/kpiManagement'

const Div = styled.div`
  // box model style
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }
  .mr-10-px {
    margin-right: 10px;
  }
  .mr-12-px {
    margin-right: 12px;
  }
  .mr-16-px {
    margin-right: 16px;
  }

  // typo style
  h1,
  h2,
  p {
    margin: 0;
  }
  h1 {
    color: var(--Gray-900);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  p {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .kpi-name {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .highlight-text {
    color: var(--Primary-600);
  }
  .date-text {
    color: var(--Gray-900);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .kpi-heading {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .kpi-des {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  // color
  .icon-color {
    color: var(--Primary-600);
  }
  .published-btn {
    border: 1px solid var(--success-600);
    background: var(--success-600);
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-center {
    align-items: center;
  }
`
const Badge = styled.div`
  display: inline-block;

  border-radius: 16px;
  border: 1px solid var(--success-200);
  background: var(--success-50);

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: var(--success-700);
  text-align: center;

  padding: 0 12px;

  &.draft {
    border: 1px solid var(--warning-200);
    background: var(--warning-50);
    color: var(--warning-700);
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`
const NoDataContainer = styled.div`
  margin: 50px 0;
  text-align: center;

  .icon-container {
    display: inline-block;
    border-radius: 10px;
    background-color: var(--Primary-200);
    padding: 12px;
    margin-bottom: 16px;

    .icon-no-data {
      color: var(--Primary-700);
    }
  }
  .no-info-text {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
`

function KPIOrganizationDetail() {
  // external hook
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useDispatch()

  const fetchKpiDetail = useCallback(
    (id) => {
      dispatch(getKpiById(id))
    },
    [dispatch]
  )

  useEffect(() => {
    fetchKpiDetail(id)
  }, [id])

  // kpi data
  const { kpiEditForm } = useSelector(selectKPI)
  const [kpiData, setKpiData] = useState()
  const [kpiOrganization, setKpiOrganization] = useState([])
  const [kpiDepartment, setKpiDepartment] = useState([])

  useEffect(() => {
    if (Object.keys(kpiEditForm).length > 0) {
      const { id, name, previous_status, createdAt, employee, kpi_organizations, kpi_departments } = kpiEditForm

      setKpiData({
        id,
        name,
        status: previous_status,
        createdAt,
        createdBy: `${employee?.first_name} ${employee?.last_name}`,
      })
      setKpiOrganization(kpi_organizations)

      const newKpiDepartment = Object.entries(kpi_departments).map(([key, value]) => ({
        title: key,
        content:
          value.length > 0 ? (
            <KPITableData data={value} hasHeading={key} />
          ) : (
            <NoDataContainer>
              <div className="icon-container">
                <CgFileDocument className="icon-no-data" size={28} />
              </div>

              <div className="no-info-text">No information</div>
            </NoDataContainer>
          ),
        kpiList: value,
      }))
      setKpiDepartment(newKpiDepartment)
    } else {
      setKpiData()
      setKpiOrganization([])
      setKpiDepartment([])
    }
  }, [kpiEditForm])

  return (
    <Div>
      <header className="mb-20-px">
        <h1>KPIs Annoucement</h1>
        <p>Manage and setup KPI for company, departments, and employees.</p>
      </header>

      <Divider className="mb-24-px" />
      {/* header section */}
      <FlexContainer className="justify-content-space-between align-items-center mb-32-px">
        <h2 className="kpi-name">KPI Name : {kpiData && <span className="highlight-text">{kpiData?.name}</span>}</h2>
        {kpiData && (
          <div>
            <Badge className={`${kpiData?.status === 'draft' ? 'draft' : ''} mr-10-px `}>{kpiData?.status}</Badge>
            <span>
              <b className="date-text">{DateTimeHHmmssDisplay(kpiData.createdAt)}</b> by {kpiData.createdBy}
            </span>
          </div>
        )}
      </FlexContainer>

      {/* Organization KPI section */}
      <section className="mb-32-px">
        <FlexContainer className="justify-content-space-between mb-24-px">
          <FlexContainer className="align-items-center">
            <TbBuildingBank size={24} className="icon-color mr-16-px" />
            <div>
              <h2 className="kpi-heading mb-8-px">Organization KPI</h2>
              <p className="kpi-des">Organization goals</p>
            </div>
          </FlexContainer>
        </FlexContainer>

        {kpiOrganization.length > 0 ? (
          <KPITableData data={kpiOrganization} />
        ) : (
          <NoDataContainer>
            <div className="icon-container">
              <CgFileDocument className="icon-no-data" size={28} />
            </div>

            <div className="no-info-text">No information</div>
          </NoDataContainer>
        )}
      </section>

      {/* Department KPI section */}
      <section>
        <FlexContainer className="align-items-center mb-24-px">
          <ImTree size={24} className="icon-color mr-16-px" />
          <div>
            <h2 className="kpi-heading mb-8-px">Department KPI</h2>
            <p className="kpi-des">Department goals</p>
          </div>
        </FlexContainer>

        {kpiDepartment.length > 0 ? (
          <NewAccordion items={kpiDepartment} />
        ) : (
          <NoDataContainer>
            <div className="icon-container">
              <CgFileDocument className="icon-no-data" size={28} />
            </div>

            <div className="no-info-text">No information</div>
          </NoDataContainer>
        )}
      </section>
    </Div>
  )
}

export default KPIOrganizationDetail
