import { useTranslation } from 'react-i18next'

const DailySummary = () => {
  const { t } = useTranslation()
  return (
    <div>
      <div>{t('daily_summary')}</div>
    </div>
  )
}

export default DailySummary
