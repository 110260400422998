import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FiCalendar } from 'react-icons/fi'
import { formatDate } from 'utils/dateHelpers'

const format = 'DD MMM YYYY'

export default function DueDate({ dueDate, rootProps, dateFormat = format }) {
  const { t } = useTranslation()

  return (
    <Stack direction="row" gap="0.5rem" width="100%" justifyContent="flex-end" marginRight="1rem" {...rootProps}>
      <FiCalendar size={16} />
      <p>{`${t('due_date')}: ${dueDate ? formatDate(dueDate) : '-'}`}</p>
    </Stack>
  )
}
