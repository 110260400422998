import React from 'react'

import styled from 'styled-components'

const Div = styled.div`
  display: flex;
  border-bottom: 1px solid var(--Gray-200);

  .tab-menu {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-500);
    padding: 0.75rem;
    :hover {
      cursor: pointer;
    }
    &.active {
      color: var(--Primary-700);
      border-bottom: 2px solid var(--Primary-700);
      background: var(--Primary-50);
    }
  }
`

function TabMenu({ tabMenuList, tab, setTab, ...props }) {
  const toggleTab = (key) => {
    setTab(key)
  }

  return (
    <Div {...props}>
      {tabMenuList.map((v) => (
        <span className={`tab-menu ${tab === v.key ? 'active' : ''}`} onClick={() => toggleTab(v.key)}>
          {v.name}
        </span>
      ))}
    </Div>
  )
}

export default TabMenu
