import styled from 'styled-components'

import Modal from '../../pages/my-calendar/Modal'
import IconButton from '../../components/common/IconButton'

import iconClose from '../../assets/images/icon-close.svg'

import { GiCancel } from 'react-icons/gi'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditNoteIcon from '@mui/icons-material/EditNote'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'

const Div = styled.div`
  position: relative;
  /*padding: 24px;*/
  width: 100%;
  width: 400px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  border-radius: 8px;

  .header-content {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 40px;
    background-color: #0BA5EC;
    padding-left: 10px;
    padding-right: 10px;
    color: rgb(255, 255, 255);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    align-items: center;
  }
  .action_icon{
    display: flex;
    align-items: center;
  }
  .Header-line{
    background-color: #FFFFFF;
    height: 16px;
    margin-right: 8px;
    width: 1px;
  }
  .icon_css {
    height: 16px;
    width: 16px;
    cursor: pointer;
    
  }
  .content{
    padding: 10px;
    background-color: #FFFFFF;
    /*
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    */
  }
  .h_topic{
    color: #0086C9;
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .date_topic{
    color: #0086C9;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .time_topic{
    color:#475467;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .footer_topic{
    color: rgb(71, 84, 103);
    font-size: 14px;
    height: 44px;
    display: flex;
    align-items: center;
    border-top: 1px solid #EAECF0;
    background-color: #FFFFFF;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: space-between
  }
  .css_view_detail{
    color: #0086C9;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
  .mg_right_10 {
    margin-right: 10px;
  }
  .mg_right_8 {
    margin-right: 10px;
  }



`

const ModalActionPlan = ({ OpenModal, onClose, onView,onEdit, onDel, DataObj }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  return (
    <Modal open={OpenModal} onClose={onClose} xxoffset={DataObj.localX} yyoffset={DataObj.localY} disableBackdropClick>
      <Div>
        <div className="header-content">
            <div >{t('action_plan')}</div>
            <div className="action_icon">
                <EditNoteIcon onClick={() => onEdit(DataObj.id)}  className="icon_css mg_right_8" />
                <DeleteOutlineIcon onClick={() => onDel(DataObj)} className="icon_css mg_right_8" />
                <div className="Header-line"></div>
                <GiCancel size={30} onClick={onClose} className="icon_css" />
            </div>
        </div>
        <div className="content">
            <div className="date_topic">{dayjs(DataObj.startDate).format('D MMM YYYY')}</div>
            <div className="time_topic"><AccessTimeOutlinedIcon className="icon_css mg_right_8" />
              {dayjs(DataObj.startDate).format('HH.mm')} - {dayjs(DataObj.endDate).format('HH.mm')}
            </div>
            
        </div>
        <div className="footer_topic">
            <div>
              {DataObj.by}
            </div>
            <div className="css_view_detail" onClick={() => onView(DataObj.id)}>
              {t('view_detail')}
            </div>
            
        </div>
      </Div>
    </Modal>
  )
}

export default ModalActionPlan
