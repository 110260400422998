import Checkbox from 'components/form/Checkbox'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { numberWithCommas } from 'utils/helper'
export default function CalculatePrice({ initValue, onChange, isPreview }) {
  const [isVatChecked, setIsVatChecked] = useState(false)
  const [textPriceValue, setTextPriceValue] = useState('')

  useEffect(() => {
    if (initValue?.is_checked) {
      setIsVatChecked(initValue?.is_checked)
    }
  }, [initValue])

  const convertToFloatText = (price) => {
    let tmpPrice = price || 0
    tmpPrice = parseFloat(tmpPrice)

    return tmpPrice.toFixed(2)
  }
  return (
    <Styled className="input_calculate_price">
      <div className={'wrp_calculate' + (isPreview ? ' preview' : '')}>
        <div className={'col_left'}>
          <div className={'title_price'}>{initValue?.text_value_before_tax}</div>
          <div className={'wrp_checkbox_title_text'}>
            {!isPreview && (
              <Checkbox
                className={'checkbox'}
                checked={isVatChecked}
                onChange={(e) => {
                  if (onChange) {
                    onChange({ is_checked: e })
                  }
                  setIsVatChecked(e)
                }}
              />
            )}
            <div className={'title_price'}>{initValue?.text_select_vat}</div>
          </div>
          <div className={'title_price font_weight_bold'}>{initValue?.text_summation}</div>
        </div>
        <div className={'col_right'}>
          <div className={'text_value'}>
            {numberWithCommas(convertToFloatText(initValue?.price_value_before_tax) || 0)}
          </div>
          <div className={'text_value'}>{numberWithCommas(convertToFloatText(initValue?.price_select_vat) || 0)}</div>
          <div className={'text_value font_weight_bold'}>
            {numberWithCommas(convertToFloatText(initValue?.price_summation) || 0)}
          </div>
        </div>
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  .wrp_calculate {
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    .col_left {
      margin-right: 20px;
      text-align: right;
    }
    .col_right {
      min-width: 200px;
    }
  }
  .wrp_checkbox_title_text {
    display: flex;
    justify-content: flex-end;
  }
  .checkbox {
    width: fit-content;
  }
  .checkbox label {
    margin: 0;
  }

  .text_value {
    text-align: end;
  }
  .font_weight_bold {
    font-weight: bold;
  }

  .wrp_calculate.preview {
    .col_left {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      text-align: left;
    }
    .wrp_checkbox_title_text {
      justify-content: flex-start;
    }
  }
`
