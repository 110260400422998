import { getAPI, postAPI, putAPI } from '../../utils/api'
import { GET_TASK_TYPES, GET_TASK_TYPE_BY_ID, CREATE_TASK_TYPE, UPDATE_TASK_TYPE } from '../actionTypes'

import { showLoading, hideLoading } from './loading'

export const getTaskTypes =
  (filter = { limit: 10, skip: 1 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_TASK_TYPES.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const params = {
        type: 'api',
        'filters[task_scope][id]': filter.taskScopeId,
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'filters[query]': filter.filters?.length > 0 ? JSON.stringify(filter.filters) : '[]',
      }
      if (filter.status != '') params['filters[status]'] = filter.status

      const { data, meta } = await getAPI({
        url: '/api/task-types',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_TASK_TYPES.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_TASK_TYPES.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Task Type List Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getTaskTypeById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TASK_TYPE_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/task-types/${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_TASK_TYPE_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_TASK_TYPE_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Task Type By Id Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createTaskType = (data, id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_TASK_TYPE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/task-types`,
      data,
      headers: headers,
    })

    const filter = { limit: 10, skip: 1, taskScopeId: id }
    dispatch(getTaskTypes(filter))

    return dispatch({
      type: CREATE_TASK_TYPE.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Task Type Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_TASK_TYPE.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Task Type Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateTaskType = (id, taskScopeId, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_TASK_TYPE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `api/task-types/${id}`,
      data,
      headers: headers,
    })

    const filter = { limit: 10, skip: 1, taskScopeId }
    dispatch(getTaskTypes(filter))

    return dispatch({
      type: UPDATE_TASK_TYPE.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Task Type Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_TASK_TYPE.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update Task Type Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}
