import styled from 'styled-components'
import { dlog } from '../../../utils/helper'

export default function ProcessingBar({ itemList, className }) {
  // dlog(itemList, 'itemList')
  return (
    <Styled
      className={className}
      activePercent={() => {
        const index = itemList.findIndex((item) => item?.status === 'active')
        if (index > -1) {
          const tmpPercent = (100 / (itemList.length - 1)) * index
          return tmpPercent + '%'
        } else {
          return '0%'
        }
      }}
    >
      <div className="wrp">
        <div className="line-bg">
          <div className="inner-wrp">{itemList[1].status == 'active' && <div className="line-active"></div>}</div>
        </div>

        <ul>
          {itemList.map((item, index) => (
            <li
              key={'item-' + index}
              className={'item' + (item.status == 'done' ? ' done' : item.status == 'active' ? ' active' : ' default')}
            >
              <div className="bullet">
                {item.status == 'done' && <div className="stage1">{index + 1}</div>}
                {item.status == 'active' && <div className="stage1">{index + 1}</div>}
                {item.status == 'wait' && <div className="stage2">{index + 1}</div>}

                <div className={'bullet-label' + (item.status == 'wait' ? ' label-wait' : '')}>{item.text}</div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 80px;
  overflow-x: hidden;
  margin: 0 auto;

  .wrp {
    width: 100%;
    position: relative;
    padding-bottom: 50px;
  }
  ul {
    list-style-type: none;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  li {
    list-style-type: none;
    position: relative;
    text-align: center;
  }

  .bullet {
    width: 40px;
    height: 40px;
    /* background-color: #ddd; */
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .stage1 {
    width: 40px;
    height: 40px;
    color: white;
    background-color: var(--Primary-700);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .stage2 {
    width: 40px;
    height: 40px;
    color: white;
    background-color: var(--Gray-300);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .bullet-label {
    position: absolute;
    width: 200px; //
    bottom: -30px;
    font-weight: bold;
    color: var(--Primary-700);
  }
  .label-wait {
    color: var(--Primary-700);
  }

  .line-bg {
    background-color: var(--Gray-300);
    height: 2px;
    position: absolute;
    width: calc(100% - 40px);
    left: 40px;
    top: 34px;
  }

  .inner-wrp,
  .line-active {
    position: relative;
    width: 100%;
    height: 2px;
  }

  .line-active {
    background-color: var(--Primary-700);
  }
`
