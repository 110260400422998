import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DateTimeHHmmDisplay,getCompanyInfo,numberWithCommas } from '../../utils/common'

import { getEmployeeDropdrow } from '../../redux/actions/master'

import { selectMaster } from '../../redux/selectors'
import { getTaskDetailAllDataReturnData,getTaskByBaseIdReturnData,DeleteReport } from '../../redux/actions/ofm'

import AutoComplete from '../../components/form/AutoComplete'
import Date from '../../components/form/Date'

import Button from '../../components/form/button/Button'
import Dropdown from '../../components/form/Dropdown'
import TableV2 from '../../components/common/TableV2'

import Checkbox from '../../components/form/Checkbox'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import iconEdit from '../../assets/images/edit-icon.png'
import iconClockFast from '../../assets/images/clock-fast-forward.png'

import SidePanel from '../../components/common/SidePanel'
import ModalViewTaskDetail from '../../pages/operation-field/ModalViewTaskDetail'
import ModalEditTaskDetail from '../../pages/operation-field/ModalEditTaskDetail'
import ModalActivityLog from '../../pages/operation-field/ModalActivityLog'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'


const DivMain = styled.div`
color: var(--Gray-600);
font-size: 14px;
font-style: normal;
font-weight: 500;
.content-fillter-bg{
    min-height: 68px;
    background-color: var(--Gray-50);
    border-radius: 8px;
    margin-bottom: 21px;
    padding: 10px;
    margin-top: 15px;
  }
  .content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
  
    .search-wrapper {
      /*margin-right: 12px;*/
    }
  
    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }
  
    .group_filter {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }
  }
.button_clear_all{
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
}
.mr-1-rem {
    margin-right: 1rem;
  }
.badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 10px;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
.table-wrapper {
    /*margin-top: 24px;*/
    border: 1px solid #EAECF0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #FFFFFF;

    .table-header {
        
        display: flex;
        justify-content: space-between;
        
        padding: 0px 14px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-radius: 8px 8px 0 0;
        background: #FFFFFF;

        .group {
            display: flex;
            align-items: center;
        }

        .table-title {
            font-size: 18px;
            font-weight: 600;
            color: var(--Gray-900);
        }

        .table-total {
            margin-left: 16px;
            font-size: 12px;
            font-weight: 500;
            padding: 2px 8px;
            color: var(--Primary-700);
            background: var(--Primary-50);
            border-radius: 16px;
            border: 1px var(--Primary-200) solid;
        }
    }

    .create-bt {
        text-decoration: none;
    }
} 
.button_view{
    color: #000000;
    background-color: #FFFFFF;
    border: 0px solid #EAECF0;
    box-shadow: none;
}
.margin_right_5{
    margin-right: 5px;
}
.button_Edit{
    color: #6941C6;
    background-color: #F9F5FF;
    border: 1px solid #EAECF0;
}
.button_del_sel{
    color: #FFFFFF;
    background-color: #d92d20;
    border: 1px solid #d92d20;
}
.button_keep{
    color: #3538cd;
    background-color: #c7d7fe;
    border: 1px solid #eef4ff;
}

.button_Cancel{
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
    margin-right: 1rem;
}
.css_header_detail {
    font-size: 14px;
    color: #667085;
    padding: 5px 14px;
} 
.border_header_tab{
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 0px;
}

.col-checkbox{
    width:30px;
} 
.col-no{
    width:30px;
} 
.col-Delete_Request_date{
    width:175px;
}
.col-Delete_Request{
    width:150px;
}
.col-Reason_for_deletion{
    width:200px;
}
.col-Base{
    width:150px;
}
.col-Delete_view{
    width:100px;
}
.col-Delete_keep{
    width:100px;
}
.col-Delete_del{
    width:100px;
}

.badge {
    padding: 2px 8px;
    align-items: center;
    color: var(--Pink-700);
    background: var(--Pink-50);
    border: 1px solid var(--Pink-200);
    border-radius: 16px;
    margin-right : 5px;
}
.div_img_row{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    column-gap: 1rem;
}
.img_in_row{
    width: 75px;
}
.text-bold {
    font-weight: bold;
}

.cursor_pointer {
    cursor: pointer;
}

.dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  /* Style for the dialog content */
  .dialog-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    position: relative;
    max-width: 1200px;
    padding: 0 15px;
  }
  /* Style for the close icon */
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0;
  }

  .popup-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .dd_css {
    /*width: 250px;*/
    min-width: 280px;
  }
  .title-filter{
    margin-bottom: 6px;
  }
  .no-title-filter{
    display: inline-block;
    align-self: flex-end;
  }
  .css_autocom{
    min-width: 250px;
    margin-right: 10px;
  }
  .css_value_warning{
    color : red;
  }
`



const TabBase = ({ BaseId }) => {
    const CompanyInfo = getCompanyInfo()
    
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [total, settotal] = useState(0);
    const [totalPages, settotalPages] = useState(1);
    const [page, setpage] = useState(1);
    const [zoneSelected, setzoneSelected] = useState('');
    const [baseSelected, setbaseSelected] = useState('');
    //const [lang, setLang] = useState()
    const initLang = localStorage.getItem('lang')
    //setLang(initLang)

    useEffect(() => {
        const filter = {
          skip: 1,
          limit: 10000
        }
        dispatch(getEmployeeDropdrow(filter))
      }, [dispatch])
      const { employeeList } = useSelector(selectMaster)
      const [employeeOption, setemployeeOption] = useState([])
      const [employee, setemployee] = useState('')
    
      useEffect(() => {
        const newEmployee = employeeList.map((v) => ({
          text: v?.first_name + ' ' + v?.last_name,
          value: v?.id,
        }))
        setemployeeOption([...newEmployee])
      }, [employeeList])

      const [currentDate, setcurrentDate] = useState(null);

    const zone_option = [
        { text: 'A', value: 'A' },
    ]
    const base_option = [
        { text: 'Extraction', value: 'Extraction' },
    ]

    const [TaskId,setTaskId] = useState(null);
    const [task_option, settask_option] = useState([]);
    /*
    const [TableColumn, setTableColumn] = useState( [
        {
            Header: <Checkbox checked={isSelectAll} label={'no'} onChange={onChangeSelectAllCheckBox} />,
            accessor: 'checkbox',
            disableSortBy: false,
        },
        
        {
            Header: 'Report Record',
            accessor: 'createdAt',
            disableSortBy: false,
        },
    ]);
    */
    const [TableColumn, setTableColumn] = useState([
        {
            Header: 'No.',
            accessor: 'no',
            disableSortBy: false,
        },
    ]);
    const [TableOFMData, setTableOFMData] = useState([]);
    const [UserEffectActive, setUserEffectActive] = useState(true);
    const [TaskDisplay, setTaskDisplay] = useState('');
    const [ReportName, setReportName] = useState('')
    const [ReportId, setReportId] = useState('')
    //const [ZoneName,setZoneName] = useState('');
    //const [BaseName,setBaseName] = useState('');
    const [ReportInfoFooter, setReportInfoFooter] = useState('')

    const fetchDataAllBase = useCallback(
        async (filter,this_BaseId) => {
            if(this_BaseId){
                dispatch(getTaskByBaseIdReturnData(this_BaseId,filter)).then(({ type,data_info,data_meta }) => {
                    if(type.endsWith('_SUCCESS')){
                        if(data_info){
                            //setZoneName(data_info.zone.name);
                            //setBaseName(data_info.name);
                            setReportInfoFooter(`${initLang === 'en' ? CompanyInfo?.company_name_EN : CompanyInfo?.company_name_TH}, ${data_info.zone.name}, ${data_info.name}`);
                            const newBasesCellData = data_info.tasks?.map((v, index) => ({
                                value: v.id,
                                text: v.name,
                            }))
                            settask_option(newBasesCellData)
                            
                        }
                    }
                })
            }
    
        },
        [dispatch]
     );
    
     useEffect(() => {
        
        setTaskId(null);
        setTableColumn([
            {
                Header: 'No.',
                accessor: 'no',
                disableSortBy: false,
            },
        ]);
        setTableOFMData([]);
        setTaskDisplay('');
        const filter = {
            skip: 1,
            limit: 1000,
        }
        fetchDataAllBase(filter,BaseId)
    }, [fetchDataAllBase,BaseId]) 
    



    const onSearchbaseSelected = (value) => {
        setbaseSelected(value)
    }

    const onChangePagination = async (value) => {
        /*
        const filter = {
            //skip: value.page * limit - limit,
            skip: value.page,
            limit: limit,
            meeting_id : MeetingID,
            other_search:searchFilters.map((v) => v.name).join(','),
            department : departmentSelected,
            position : positionSelected
        }
        fetchData(filter)
        */
        setpage(value.page)
    }

    const [isSelectAll, setIsSelectAll] = useState(false)

    const onChangeSelectAllCheckBox = (e) => {
        
      }

      const handleChangeCheckBox = (e,v_inp,index_item) => {
        
      }

    



    const fetchDataAll = useCallback(
        async (filter,this_TaskId) => {
            if(this_TaskId){
                dispatch(getTaskDetailAllDataReturnData(this_TaskId,filter)).then(({ type,data_info,data_meta }) => {
                    if(type.endsWith('_SUCCESS')){
                        if(data_info){
                            setTaskDisplay(data_info.task_id + ' : ' + data_info.name);
                            setReportName(data_info.name);
                            //---- ST <TH>
                            const newTHData = data_info.forms?.map((v, index) => {
                                /*
                                Object.keys(v.json_schema.properties).forEach((k) => {
                                    return {
                                        Header : '',
                                        accessor: '',
                                        disableSortBy: false,
                                    }
                                })
                                */
                               const all_properties = {
                                ...v.json_schema.properties,
                                "note": {
                                    "title": "หมายเหตุ"
                                },
                                "images": {
                                    "title": "images"
                                },
                               }

                                return Object.keys(all_properties).map(key => {
                                    let this_header = '';
                                    if(all_properties[key].description){
                                        this_header = <div>{all_properties[key].title}<div>({all_properties[key].description})</div></div>;
                                    }else{
                                        this_header = <div>{all_properties[key].title}</div>;
                                    }
                                    return {
                                        Header : this_header,
                                        accessor: key + '_' + index,
                                        disableSortBy: false,
                                    }
                                });
                                 
                            })
                            //let final_column_value = {};
                            let new_arr_column = [
                                {
                                    Header: 'No.',
                                    accessor: 'no',
                                    disableSortBy: false,
                                },
                                {
                                    Header: 'Report Record',
                                    accessor: 'createdAt',
                                    disableSortBy: false,
                                },
                            ];
                            for(const item_newTHData of newTHData){
                                //
                                //
                                for(const item_sub_newTHData of item_newTHData){
                                    //
                                    //
                                    new_arr_column.push(item_sub_newTHData);
                                }
                            }
                            new_arr_column.push({
                                accessor: 'this_time',
                                disableSortBy: false,
                            });
                            new_arr_column.push({
                                accessor: 'this_del',
                                disableSortBy: false,
                            });
                            new_arr_column.push({
                                accessor: 'this_edit',
                                disableSortBy: false,
                            });
                            
                            //
                            //console.table(new_arr_column);
                            
                            setTableColumn(new_arr_column);                           
                            //---- ED <TH>
                            //---- ST <TD>
                            let allTDData = [];
                            let index_report = 0;
                            for(const item_reports of data_info.reports){
                                let index_document = 0;
                                let final_data_value_document = {};
                                for(const item_documents of item_reports.documents){
                                    const all_data_properties = data_info.forms[index_document].json_schema.properties;
                                    
                                    
                                    const all_data_value = {
                                        ...item_documents.value,
                                    }
                                    const list_value =  Object.keys(all_data_value).map(key => {
                                        const data_value = all_data_value[key];
                                        const type_data = all_data_properties[key]?.type ? all_data_properties[key]?.type : 'String';
                                        const minimum = all_data_properties[key]?.minimum ? all_data_properties[key]?.minimum : null;
                                        const maximum = all_data_properties[key]?.maximum ? all_data_properties[key]?.maximum : null;
                                        const key_accessor = `${key}_${index_document}`;
                                        if(key_accessor == 'no'){
                                            return {
                                                [`${key_accessor}`]: index_report + 1
                                            }
                                            
                                        }else if(key_accessor == 'createdAt'){
                                            return {
                                                [`${key_accessor}`]: <div className="text-bold link" onClick={() => ViewTask(item_reports)}>{DateTimeHHmmDisplay(data_value)}</div>
                                            }
                                        }else if(key_accessor == 'this_time'){
                                            return {
                                                [`${key_accessor}`]: <img className="cursor_pointer" src={iconClockFast} title="Clock" onClick={() => ViewModalViewActivityLog(item_reports)} />
                                            }
                                        }else if(key_accessor == 'this_del'){
                                            return {
                                                [`${key_accessor}`]: <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(item_reports)} />
                                            }
                                        }else if(key_accessor == 'this_edit'){
                                            return {
                                                [`${key_accessor}`]: <img className="cursor_pointer" src={iconEdit} title="Edit" onClick={() => EditData(item_reports)} />
                                            }
                                        }else if(key_accessor == `images_${index_document}`){
                                            //
                                            //
                                            let img_data = '';
                                            try {
                                                //
                                                //
                                                img_data = data_value.map((v_sub,index_sub) => {
                                                    return v_sub != '' ? <div className='cursor_pointer' onClick={() => toggleDialog(v_sub)}>
                                                        <img className='img_in_row'  src={v_sub} />
                                                    </div> : '';
                                                }); 
                                            } catch (error) {
                                                img_data = '';
                                            }
    
                                            
                                            //const img_data = '';
                                            return {
                                                [`${key_accessor}`]:  <div className="div_img_row">{img_data}</div>
                                            }
                                        }else{
                                            if(type_data == 'integer' || type_data == 'number'){
                                                if(minimum && maximum){
                                                    if(parseFloat(data_value) < parseFloat(minimum) || parseFloat(data_value) > parseFloat(maximum)){
                                                        return {
                                                            [`${key_accessor}`]: <div className="css_value_warning">{numberWithCommas(data_value)}</div>
                                                        }
                                                    }else{
                                                        return {
                                                            [`${key_accessor}`]: <div >{numberWithCommas(data_value)}</div>
                                                        }
                                                    }
                                                    
                                                }else{
                                                    return {
                                                        [`${key_accessor}`]: <div >{numberWithCommas(data_value)}</div>
                                                    }
                                                }
                                            }else{
                                                return {
                                                    [`${key_accessor}`]: <div >{data_value}</div>
                                                }
                                            }


                                        }
    
                                    });
                                    index_document = index_document + 1;
                                    for(const item_list_value of list_value){
                                        final_data_value_document = {...final_data_value_document , ...item_list_value}
                                    }
                                }
                                
                                
                                const all_data_value_final = {
                                    no : index_report + 1,
                                    createdAt : <div className="text-bold link" onClick={() => ViewTask(item_reports)}>{DateTimeHHmmDisplay(item_reports.createdAt)}</div>,
                                    this_time : <img className="cursor_pointer" src={iconClockFast} title="Clock" onClick={() => ViewModalViewActivityLog(item_reports)} />,
                                    this_del : <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(item_reports)} />,
                                    this_edit : <img className="cursor_pointer" src={iconEdit} title="Edit" onClick={() => EditData(item_reports)} />,
                                    ...final_data_value_document,
                                };
                                
                                //
                                //
                                allTDData.push(all_data_value_final);
                                index_report = index_report + 1;
                            }
                            
                            //
                            //console.table(allTDData);
                            setTableOFMData(allTDData);
                            settotal(index_report);
                            
                            //---- ED <TD>
                            
                            
                            
                            
                        }
                    }
                })
            }
    
        },
        [dispatch]
     );

     const onSearchTaskSelected = (value) => {
        setTaskId(value);
        const filter = {
            skip: 1,
            limit: 1000,
        }
        fetchDataAll(filter,value);
    }

    const onSearchEmployee = (v) => {
        setemployee(v)
        const filter = {
            skip: 1,
            limit: 1000,
            employee_id : v,
            currentDate : currentDate,

        }
        fetchDataAll(filter,TaskId);
        setpage(1);
    }
    const onChangecurrentDate = (value) => {
        setcurrentDate(value)
        const filter = {
            skip: 1,
            limit: 1000,
            employee_id : employee,
            currentDate : value,

        }
        fetchDataAll(filter,TaskId);
        setpage(1);
      }

      const onClearAllSearch = () => {
        setemployee('');
        setcurrentDate(null);
        setTaskId(null);
        setTableColumn([
            {
                Header: 'No.',
                accessor: 'no',
                disableSortBy: false,
            },
        ]);
        setTableOFMData([]);
        setTaskDisplay('');
      }

      
      const [ModalViewActivityLog, setModalViewActivityLog] = useState(false)
      const ViewModalViewActivityLog = (Obj) => {
        setReportId(Obj.id);
        //setReportName(Obj.name);
        setModalViewActivityLog(true);
      }

      const CloseModalViewActivityLog = () => {
        setReportId('');
        setModalViewActivityLog(false)
      }
    
      const SubmitModalViewActivityLog= () => {
        setModalViewActivityLog(false);
      }

      const [ModalViewTask, setModalViewTask] = useState(false)
      
      

      const ViewTask = (Obj) => {
        setReportId(Obj.id);
        //setReportName(Obj.name);
        setModalViewTask(true);
      }

      const CloseModalViewTask = () => {
        setReportId('');
        setModalViewTask(false)
      }
    
      const SubmitModalViewTask = () => {
        setModalViewTask(false);
        setModalEditTask(true);
      }

      const [Titlesuccess, setTitlesuccess] = useState('')
      const [SubTitlesuccess, setSubTitlesuccess] = useState('')
      const [Descriptionsuccess, setDescriptionsuccess] = useState('')
      const [successModal, setsuccessModal] = useState(false)
      const [TitleFail, setTitleFail] = useState('')
      const [nameItemFail, setnameItemFail] = useState('')
      const [DescriptionFail, setDescriptionFail] = useState('')
      const [failModal, setFailModal] = useState(false)


      const [ModalConfirmDel, setModalConfirmDel] = useState(false)
      const [ConfirmDenameItem, setConfirmDenameItem] = useState('')
      const DelData = (Obj) => {
        setReportId(Obj.id);
        setConfirmDenameItem(DateTimeHHmmDisplay(Obj.createdAt));
        setModalConfirmDel(true);
      }

      const SubmitModalConfirmDel = () => {
        setModalConfirmDel(false);
        dispatch(DeleteReport(ReportId,null)).then(({ type }) => {
          if(type.endsWith('_SUCCESS')){
            onClearAllSearch();
            setTitlesuccess('Delete Successful');
            setSubTitlesuccess('');
            setDescriptionsuccess(''); 
            setsuccessModal(true)
          }else{
            setTitleFail('Error Delete');
            setnameItemFail('');
            setDescriptionFail(''); 
            setFailModal(true)
          }    
        })
      }

      const [ModalEditTask, setModalEditTask] = useState(false)
      const EditData = (Obj) => {
        setReportId(Obj.id);
        setModalEditTask(true);
      }

      const CloseModalEditTask = () => {
        setReportId('');
        setModalEditTask(false)
      }
    
      const SubmitModalEditTask = () => {
        setReportId('');
        setModalEditTask(false)
        const filter = {
            skip: 1,
            limit: 1000,
            employee_id : employee,
            currentDate : currentDate,
        }
        fetchDataAll(filter,TaskId);
        setpage(1);
      }

      const [dialog, setDialog] = useState(false);
      const [imageSrc, setImageSrc] = useState();
      const toggleDialog = (url_img) => {
        //alert(url_img);
        setImageSrc(url_img);
        setDialog(true);
        
      };

      const ClosetoggleDialog = () => {
        setDialog(false);
        
      };
    
    return (
        <DivMain>
            <div>
                <div className="content-fillter-bg">
                    <div className="content-fillter">
                        <div className="group_filter">
                            <div>
                                <div className="title-filter">Task</div>
                                <Dropdown
                                    placeHolder="Select Task"
                                    className="dd_css"
                                    optionList={task_option}
                                    value={TaskId}
                                    onChange={(value) => onSearchTaskSelected(value)}
                                    ValueplaceHolderIsNull={true}
                                />
                            </div>
                            <div>
                                <div className="title-filter">Report By</div>
                                <div className="css_autocom">
                                    <AutoComplete
                                        placeHolder='Report By'
                                        optionList={employeeOption}
                                        value={employee}         
                                        onChange={onSearchEmployee}                     
                                    />
                                </div>

                            </div>
                            <div>
                                <div className="title-filter">Report record</div>
                                <Date className={'mr-1-rem'} value={currentDate} onChange={onChangecurrentDate} />
                            </div>
                            <div className="no-title-filter">                                
                                <Button onClick={onClearAllSearch} className="button_clear_all mr-1-rem">
                                {t('clear_all')}
                                </Button>
                            </div>
                            
                        </div>

                        <div className="container">
                        </div>

                    </div>
                </div>
            </div>
            {TaskId != null && (
                <div className="table-wrapper">
                    <div className="table-header">
                        <div className="group">
                            <div className="table-title">{TaskDisplay}</div>
                            <div className="table-total">{total} records</div>
                        </div>
                        <div>
                                {
                                /*
                                    <ButtonOutline  className="button_del_sel margin_right_5">
                                        Delete Selected record
                                    </ButtonOutline>
                                */
                            }
                        </div>
                    </div>
                    <div className="border_header_tab"></div>
                    <TableV2
                        columns={TableColumn}
                        data={TableOFMData}
                        onStateChange={onChangePagination}
                        pageCount={totalPages}
                        page={page}
                        loading={false}
                    />              
                </div>
            )} 
            {dialog && (
                <div className="dialog">
                <div className="dialog-content">
                    <h3>OFM</h3>
                    <button className="close-icon" onClick={ClosetoggleDialog}>&#10005;</button>
                    <img className="popup-image" src={imageSrc} alt="Popup Image" />
                </div>
                </div>
            )}

            <SidePanel isOpen={ModalViewTask} setIsOpen={setModalViewTask} width={800}>
                <ModalViewTaskDetail width={800} onClose={CloseModalViewTask} onSubmit={SubmitModalViewTask} ReportId={ReportId} ReportName={ReportName} ReportInfoFooter={ReportInfoFooter} />
            </SidePanel>
            <SidePanel isOpen={ModalEditTask} setIsOpen={setModalEditTask} width={800}>
                <ModalEditTaskDetail width={800} onClose={CloseModalEditTask} onSubmit={SubmitModalEditTask} ReportId={ReportId} ReportName={ReportName} ReportInfoFooter={ReportInfoFooter} />
            </SidePanel>

            
            <SidePanel isOpen={ModalViewActivityLog} setIsOpen={setModalViewActivityLog} width={400}>
                <ModalActivityLog width={400} onClose={CloseModalViewActivityLog} onSubmit={SubmitModalViewActivityLog} ReportId={ReportId} ReportName={ReportName} ReportInfoFooter={ReportInfoFooter} />
            </SidePanel>

            <DialogSuccess
                open={Boolean(successModal)}
                onClose={() => setsuccessModal(false)}
                onSubmit={() => setsuccessModal(false)}
                icon={successIcon}
                title={Titlesuccess}
                subtitle={SubTitlesuccess}
                description={Descriptionsuccess}
                textYes={t('done')}
            />

            <DialogFail
                open={Boolean(failModal)}
                onClose={() => setFailModal(false)}
                onSubmit={() => setFailModal(false)}
                icon={failIcon}
                title={TitleFail}
                nameItem={nameItemFail}
                description={DescriptionFail}
                textYes={t('ok')}
            />  

            <DialogConfirmDelete
                open={Boolean(ModalConfirmDel)}
                onClose={() => setModalConfirmDel(false)}
                onSubmit={SubmitModalConfirmDel}
                title={'Delete Report Record?'}
                nameItem={ConfirmDenameItem}
                description={`Are you sure you want to delete this record?`}
                description2={`This action cannot be undone.`}
            />
        </DivMain>
    )
}

export default TabBase