import { Divider, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { createElement, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { RiAddLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import iconFail from 'assets/images/fail-icon.png'
import iconSuccess2 from 'assets/images/success-icon-2.png'
import Button from 'components/common/Button'
import Chip from 'components/common/Chip'
import CollapseMenu from 'components/common/CollapseMenu'
import ModalV2 from 'components/common/ModalV2'
import SidePanelV2 from 'components/common/SidePanelV2'
import TableV3 from 'components/common/TableV3'
import Typography from 'components/common/Typography'
import CreateSowTpl from 'components/feature/operation-process/form/sow-tpl/create'
import EditSowTpl from 'components/feature/operation-process/form/sow-tpl/edit'
import ViewSowTpl from 'components/feature/operation-process/form/sow-tpl/view'
import Filter from 'components/feature/operation-process/form/sow-tpl/filters'
import { SOW_TEMPLATE } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { ButtonGroup, TableContainer, TableHeader, TypographyH3 } from '.'
import tableColumns from './table-columns'

const init_drawer = {
  createSowTpl: false,
  viewSowTpl: false,
  editSowTpl: false,
  sowTplId: undefined,
}

const SowMGTList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const sowTplId = searchParams.get('id')
  const mode = searchParams.get('mode') || 'view'

  const filterRef = useRef(null)
  const createSowTplRef = useRef(null)
  const editSowTplRef = useRef(null)
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)

  const { sowTpl: sowTplActions, extra: extraActions } = opActions()
  const { getExtra } = extraActions()
  const { getSowTpl, postSowTpl, putSowTpl, deleteSowTpl, clearSowTpl } = sowTplActions()

  const { sowTpls, sowTpls_pagination, sowTpl } = opProcSelector
  const { page, pageCount, total } = sowTpls_pagination

  const [drawer, setDrawer] = useState(init_drawer)
  const [filters, setFilters] = useState({})
  const [modalProps, setModalProps] = useState({ open: false })

  const fetchSowTpls = useCallback(
    async (payload) => {
      return dispatch(getSowTpl().list(payload))
    },
    [dispatch]
  )

  const fetchSowTplById = useCallback(
    async (sowTplId) => {
      return dispatch(getSowTpl(sowTplId).byId())
    },
    [dispatch]
  )

  const fetchDepartments = useCallback(
    async (payload) => {
      return dispatch(getExtra().departments(payload))
    },
    [dispatch]
  )

  const handleToggleDrawer = (drawerName, open, data = {}) => {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }

      setDrawer({ ...init_drawer, [drawerName]: open, ...data })
    }
  }

  const handleFilters = (values) => {
    const keys = Object.keys(values)

    if (keys.length === 0) {
      setFilters({})
      return fetchSowTpls()
    }

    let filters = {}
    keys.forEach((key) => {
      if (!values[key]) {
        return
      }

      switch (key) {
        case 'name':
          filters = { ...filters, $or: [{ name: { $contains: values[key] } }, { code: { $contains: values[key] } }] }
          break
        case 'department':
          filters = { ...filters, department: values[key] }
          break
        default:
          break
      }
    })

    if (Object.keys(filters).length === 0) {
      setFilters({})
      return fetchSowTpls()
    }

    setFilters(filters)
    return fetchSowTpls({ filters })
  }

  const handleTableChange = ({ page }) => {
    // Do something...
    const pagination = { ...sowTpls_pagination, page }
    fetchSowTpls({ pagination, filters })
  }

  const actionList = (item) => {
    return [
      {
        text: t('view'),
        action: () => {
          navigate(`${pathname}?id=${item.id}&mode=view`)
          handleToggleDrawer('viewSowTpl', true)()
        },
      },
      {
        text: t('Edit'),
        action: () => {
          navigate(`${pathname}?id=${item.id}&mode=edit`)
          handleToggleDrawer('editSowTpl', true)()
        },
      },
    ]
  }

  const transformSowToTable = (data) => {
    const mapDataItem = (item) => {
      const code = (
        <Link to={`${pathname}?id=${item?.id}&mode=view`}>
          <Typography variant="tableLink">{item.code}</Typography>
        </Link>
      )
      const description = <div className="sow-col-description">{item.description}</div>
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={item.status || 'N/A'}
          color={SOW_TEMPLATE.STATUS_CHIP[item.status]}
        />
      )
      const actions = <CollapseMenu list={actionList(item)} />

      return { ...item, code, description, status, actions }
    }

    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const handleCreateSowTplSubmit = (data) => {
    const successfulCreate = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
        return fetchSowTpls()
      },
      onOk() {
        setModalProps({ open: false })
        return fetchSowTpls()
      },
      titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
      titleText: 'SOW Template Created',
      contentText: 'SOW Template has been created successfully.',
    }

    const failedCreate = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
      },
      onOk() {
        setModalProps({ open: false })
      },
      okText: 'Try Again',
      titleIcon: createElement('img', { src: iconFail, width: 48, height: 48, alt: 'i-failed' }),
      titleText: "Couldn't Create SOW Template",
      contentText: 'An error occurred while creating the SOW Template.',
      okProps: { color: 'error' },
    }

    dispatch(postSowTpl().create(data)).then((res) => {
      if (res.type.endsWith('_SUCCESS') && !res.error) {
        handleToggleDrawer('createSowTpl', false)()
        setModalProps(successfulCreate)
        return
      }

      setModalProps(failedCreate)
      return
    })
  }

  const handleEditSowTplSubmit = (data) => {
    const onCloseAndOnOkSuccess = () => {
      setModalProps({ open: false })
      fetchSowTpls()
      fetchSowTplById(sowTplId || drawer?.sowTplId)
      navigate(pathname)
    }

    const successfulEdit = {
      open: true,
      showCancel: false,
      onClose: onCloseAndOnOkSuccess,
      onOk: onCloseAndOnOkSuccess,
      titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
      titleText: 'SOW Template Updated',
      contentText: 'SOW Template has been updated successfully.',
    }

    const failedEdit = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
      },
      onOk() {
        setModalProps({ open: false })
      },
      okText: 'Try Again',
      titleIcon: createElement('img', { src: iconFail, width: 48, height: 48, alt: 'i-failed' }),
      titleText: "Couldn't Update SOW Template",
      contentText: 'An error occurred while updating the SOW Template.',
      okProps: { color: 'error' },
    }

    dispatch(putSowTpl(sowTplId).update(data)).then((res) => {
      if (res.type.endsWith('_SUCCESS') && !res.error) {
        handleToggleDrawer('editSowTpl', false)()
        setModalProps(successfulEdit)
        return
      }

      setModalProps(failedEdit)
      return
    })
  }

  useEffect(() => {
    fetchDepartments()
    fetchSowTpls()
  }, [fetchSowTpls, fetchDepartments])

  useEffect(() => {
    if (sowTplId) {
      switch (mode) {
        case 'edit':
          handleToggleDrawer('editSowTpl', true, { sowTplId: sowTplId })()
          break

        case 'view':
        default:
          handleToggleDrawer('viewSowTpl', true, { sowTplId: sowTplId })()
          break
      }

      return
    }

    handleToggleDrawer('viewSowTpl', false)()
  }, [sowTplId, mode])

  return (
    <TableContainer className="sow-table-container">
      <TableHeader>
        <Stack sx={{ width: '100%' }}>
          <Stack direction="row" sx={{ width: '100%', padding: '1rem 1.5rem', justifyContent: 'space-between' }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <TypographyH3>{t('sow')}</TypographyH3>

              <Chip
                label={total > 1000 ? '999+' : `${total} SOW templates`}
                color="primary"
                sx={{ p: '2px 8px 2px 8px', cursor: 'pointer' }}
              />
            </Stack>
          </Stack>

          <Divider sx={{ borderColor: 'var(--Gray-200)' }} />

          <Stack
            direction="row"
            alignItems="flex-end"
            padding="1rem 1.5rem"
            minHeight="4.875rem"
            flexWrap="wrap"
            columnGap="1rem"
            rowGap="0.5rem"
            justifyContent="space-between"
          >
            <Filter ref={filterRef} onFormChange={handleFilters} />

            <ButtonGroup>
              <Button variant="contained" startIcon={<RiAddLine />} onClick={handleToggleDrawer('createSowTpl', true)}>
                {t('create_new_sow_tpl')}
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </TableHeader>

      <TableV3
        data={transformSowToTable(sowTpls)}
        page={page}
        pageCount={pageCount}
        columns={tableColumns('sow')}
        onStateChange={handleTableChange}
      />

      <SidePanelV2
        titleText="Create SOW Template"
        open={drawer.createSowTpl}
        onClose={() => {
          handleToggleDrawer('createSowTpl', false)()
        }}
        footerEl={
          <FooterDrawer>
            <Button className="gray" variant="outlined" onClick={handleToggleDrawer('createSowTpl', false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" onClick={() => createSowTplRef.current.submit()}>
              {t('create')}
            </Button>
          </FooterDrawer>
        }
      >
        <CreateSowTpl ref={createSowTplRef} onSubmit={handleCreateSowTplSubmit} />
      </SidePanelV2>

      <SidePanelV2
        titleText={`${sowTpl?.name}: ${sowTpl?.code}`}
        open={drawer.viewSowTpl}
        onClose={() => {
          handleToggleDrawer('viewSowTpl', false)()
          clearSowTpl()
          navigate(pathname)
        }}
        footerEl={null}
      >
        <ViewSowTpl id={sowTplId || drawer?.sowTplId} readonly />
      </SidePanelV2>

      <SidePanelV2
        titleText={`${sowTpl?.name}: ${sowTpl?.code}`}
        open={drawer.editSowTpl}
        onClose={() => {
          handleToggleDrawer('editSowTpl', false)()
          clearSowTpl()
          navigate(pathname)
        }}
        footerEl={
          <FooterDrawer>
            <Button
              className="gray"
              variant="outlined"
              onClick={() => {
                handleToggleDrawer('editSowTpl', false)
                clearSowTpl()
                navigate(pathname)
              }}
            >
              {t('cancel')}
            </Button>
            <Button variant="contained" onClick={() => editSowTplRef.current.submit()}>
              {t('save')}
            </Button>
          </FooterDrawer>
        }
      >
        <EditSowTpl ref={editSowTplRef} onSubmit={handleEditSowTplSubmit} id={sowTplId || drawer?.sowTplId} />
      </SidePanelV2>

      <ModalV2 {...modalProps} />
    </TableContainer>
  )
}

export default SowMGTList

const FooterDrawer = styled('footer')({
  display: 'flex',
  width: '100%',
  gap: '1rem',
  justifyContent: 'flex-end',
  padding: '1rem',
})
