import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import PasswordField from '../../components/form/PasswordInput'
import Button from '../../components/form/button/Button'

import resetPasswordIcon from '../../assets/images/reset-password-icon.png'
import unCheckIcon from '../../assets/images/uncheck-icon.png'
import checkIcon from '../../assets/images/check-icon.png'

import { ForgotPasswordUser } from '../../redux/actions/resetpassword'
import { selectFormPassword } from '../../redux/selectors'

const Div = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  .mr-1-rem {
    margin-right: 1rem;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .text-align-center {
    text-align: center;
  }

  .d-flex {
    display: flex;
  }

  .jt-center {
    justify-content: center;
  }

  .jt-between {
    justify-content: space-between;
  }

  .al-center {
    align-items: center;
  }
  .container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 100px 0;
  }

  .icon {
    width: 5rem;
    height: auto;
    cursor: pointer;
  }

  .icon-2 {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }

  .logo {
    width: 100px; /* Adjust the width as needed */
    height: auto;
    margin-bottom: 20px; /* Adjust the margin as needed */
  }
  .font-size-header {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }

  .bordered-text {
    border: 1px solid black; /* Adjust the border style as needed */
    padding: 20px 40px; /* Adjust the padding as needed */
    margin-bottom: 20px; /* Adjust the margin as needed */
  }

  .form-container {
  }

  .input {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .text-field {
    width: 350px; /* Adjust the width as needed */
    /* height: 50px; */
    padding: 10px; /* Adjust the padding as needed */
    margin-bottom: 10px; /* Adjust the margin as needed */
  }
  .font-size {
    font-size: 15px;
    text-align: center;
  }

  .submit-button {
    text-align: center;
  }
  .text-danger {
    color: var(--Error-500);
  }

  .input-header {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .error {
    border: 1px solid var(--Error-300);
  }

  @media screen and (max-width: 440px) {
    font-size: 14px;

    .container {
      max-width: 300px;
    }

    .font-size-header {
      font-size: 26px;
    }

    .input-header {
    }
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-center {
    justify-content: center;
  }
`

const initValidateCollection = {
  isLeast8: false,
  isContainSpecialCharacter: false,
  isUpperLower: false,
  isNumber: false,
}

const ForgotPassword = () => {
  const { t } = useTranslation()
  const { token } = useParams()
  const navigate = useNavigate()
  const { userInfo } = useSelector(selectFormPassword)

  const dispatch = useDispatch()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordMatch, setPasswordMatch] = useState(true)
  const [passwordValid, setPasswordValid] = useState(true)
  const [isValid, setIsValid] = useState(true)
  const [validateCollection, setValidateCollection] = useState(initValidateCollection)

  useEffect(() => {
    const regexAtleast8 = /^.{8,}$/
    const regexSpecialCharacter = /[^A-Za-z0-9]/
    const regexUpperLowerCaseNumber = /^(?=.*[a-z])(?=.*[A-Z]).*$/
    const regexNumber = /\d/

    setValidateCollection({
      isLeast8: regexAtleast8.test(password),
      isContainSpecialCharacter: regexSpecialCharacter.test(password),
      isUpperLower: regexUpperLowerCaseNumber.test(password),
      isNumber: regexNumber.test(password),
    })
  }, [password])

  useEffect(() => {
    if (confirmPassword !== password && confirmPassword != '') setPasswordMatch(false)
    else setPasswordMatch(true)
  }, [confirmPassword, password])

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
    setIsValid(true)
  }

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value)
    setIsValid(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (password === confirmPassword) {
      setPasswordMatch(true)
      const regexAtleast8 = /^.{8,}$/
      const regexSpecialCharacter = /[^A-Za-z0-9]/
      const regexUpperLowerCaseNumber = /^(?=.*[a-z])(?=.*[A-Z]).*$/
      const regexNumber = /\d/
      if (
        regexAtleast8.test(password) &&
        regexSpecialCharacter.test(password) &&
        regexUpperLowerCaseNumber.test(password) &&
        regexNumber.test(password)
      ) {
        setPasswordValid(true)
        const res = {
          data: {
            forgotPasswordToken: token,
            password: password,
          },
        }

        dispatch(ForgotPasswordUser(res))
          .then(() => {
            // result
            navigate('/result-set-password/?type=set_password&is_success=true', {
              replace: true,
            })
          })
          .catch(() => {
            navigate('/result-set-password/?type=set_password&is_success=false', {
              replace: true,
            })
          })
      } else {
        setPasswordValid(false)
      }
    } else {
      setPasswordMatch(false)
    }
  }

  return (
    <Div>
      <form onSubmit={handleSubmit}>
        <div className="container">
          <FlexContainer className="justify-content-center mb-1-rem">
            <img className="icon" src={resetPasswordIcon} />
          </FlexContainer>
          <div className="font-size-header text-align-center mb-1-rem">{t('set_password')}</div>
          <div className="text-align-center mb-2-rem">{t('set_password_sub')}</div>
          <div className="form-container">
            <div className="mb-1-rem">
              <div className="input-header">{t('password')}</div>
              <div className="input">
                <PasswordField type="password" value={password} onChange={handlePasswordChange} />
              </div>
            </div>
            <div className="mb-1-rem">
              <div className="input-header">{t('confirm_password')}</div>
              <div className="input">
                <PasswordField
                  className={!passwordMatch ? 'error' : ''}
                  type="password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                />
              </div>
              {!passwordMatch && <p className="text-danger">{t('the_password_entered_does_not_match')}</p>}
            </div>

            <div className="d-flex al-center mb-1-rem">
              {validateCollection.isLeast8 ? (
                <img className="icon-2 mr-1-rem" src={checkIcon} />
              ) : (
                <img className="icon-2 mr-1-rem" src={unCheckIcon} />
              )}
              {t('isLeast8')}
            </div>
            <div className="d-flex al-center mb-1-rem">
              {validateCollection.isContainSpecialCharacter ? (
                <img className="icon-2 mr-1-rem" src={checkIcon} />
              ) : (
                <img className="icon-2 mr-1-rem" src={unCheckIcon} />
              )}
              {t('must_contain_one_special_character')}
            </div>
            <div className="d-flex al-center mb-1-rem">
              {validateCollection.isUpperLower ? (
                <img className="icon-2 mr-1-rem" src={checkIcon} />
              ) : (
                <img className="icon-2 mr-1-rem" src={unCheckIcon} />
              )}
              {t('uppercase_letter_and_lowercase_letter')}
            </div>
            <div className="d-flex al-center mb-2-rem">
              {validateCollection.isNumber ? (
                <img className="icon-2 mr-1-rem" src={checkIcon} />
              ) : (
                <img className="icon-2 mr-1-rem" src={unCheckIcon} />
              )}
              {t('must_contain_at_least_1_number')}
            </div>
            <div className="input">
              <Button id="create-role" className="d-flex jt-center al-center" type="submit">
                {t('set_password')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Div>
  )
}

export default ForgotPassword
