import { combineReducers } from 'redux'
import {
  GET_DOCUMENT_TEMPLATE,
  GET_DOCUMENT_TEMPLATE_BY_ID,
  CREATE_DOCUMENT_TEMPLATE,
  EDIT_DOCUMENT_TEMPLATE,
  DELETE_DOCUMENT_TEMPLATE,
  GET_CATEGORIES,
  DUPLICATE_DOCUMENT_TEMPLATE,
} from '../actionTypes'

const initialState = {
  documentTemplates: [],
  documentTemplateInfo: {},
  categories: [],
  page: 1,
  pageCount: 1,
  total: 1,
  limit: 10,
  isLoading: false,
  state: '',
}

const documentTemplateReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_DOCUMENT_TEMPLATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_DOCUMENT_TEMPLATE.REQUEST',
      }
    case GET_DOCUMENT_TEMPLATE.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        documentTemplates: data,
        page: meta?.pagination?.page,
        pageCount: meta?.pagination?.pageCount,
        total: meta?.pagination?.total,
        limit: meta?.pagination?.pageSize,
        isLoading: false,
        state: 'GET_DOCUMENT_TEMPLATE.SUCCESS',
      }
    }
    case GET_DOCUMENT_TEMPLATE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_DOCUMENT_TEMPLATE.FAILURE',
      }
    case GET_DOCUMENT_TEMPLATE_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_DOCUMENT_TEMPLATE_BY_ID.REQUEST',
      }
    case GET_DOCUMENT_TEMPLATE_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        documentTemplateInfo: data,
        isLoading: false,
        state: 'GET_DOCUMENT_TEMPLATE_BY_ID.SUCCESS',
      }
    }
    case GET_DOCUMENT_TEMPLATE_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_DOCUMENT_TEMPLATE_BY_ID.FAILURE',
      }
    case GET_CATEGORIES.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_CATEGORIES.REQUEST',
      }
    case GET_CATEGORIES.SUCCESS: {
      const { data } = action
      return {
        ...state,
        categories: data,
        isLoading: false,
        state: 'GET_CATEGORIES.SUCCESS',
      }
    }
    case GET_CATEGORIES.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_CATEGORIES.FAILURE',
      }
    case CREATE_DOCUMENT_TEMPLATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_DOCUMENT_TEMPLATE.REQUEST',
      }
    case CREATE_DOCUMENT_TEMPLATE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_DOCUMENT_TEMPLATE.SUCCESS',
      }
    case CREATE_DOCUMENT_TEMPLATE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_DOCUMENT_TEMPLATE.FAILURE',
      }
    case EDIT_DOCUMENT_TEMPLATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'EDIT_DOCUMENT_TEMPLATE.REQUEST',
      }
    case EDIT_DOCUMENT_TEMPLATE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_DOCUMENT_TEMPLATE.SUCCESS',
      }
    case EDIT_DOCUMENT_TEMPLATE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_DOCUMENT_TEMPLATE.FAILURE',
      }
    case DELETE_DOCUMENT_TEMPLATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_DOCUMENT_TEMPLATE.REQUEST',
      }
    case DELETE_DOCUMENT_TEMPLATE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_DOCUMENT_TEMPLATE.SUCCESS',
      }
    case DELETE_DOCUMENT_TEMPLATE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_DOCUMENT_TEMPLATE.FAILURE',
      }
    case DUPLICATE_DOCUMENT_TEMPLATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'DUPLICATE_DOCUMENT_TEMPLATE.REQUEST',
      }
    case DUPLICATE_DOCUMENT_TEMPLATE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        state: 'DUPLICATE_DOCUMENT_TEMPLATE.SUCCESS',
      }
    case DUPLICATE_DOCUMENT_TEMPLATE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'DUPLICATE_DOCUMENT_TEMPLATE.FAILURE',
      }
    default:
      return state
  }
}
export default combineReducers({
  documentTemplateReducer,
})
