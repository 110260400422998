import styled from 'styled-components'

const FileInputWrapper = styled.div`
  display: flex;
  align-items: center;
`

const FileNameInput = styled.input.attrs({ readOnly: true })`
  flex-grow: 1;
  margin-right: 8px;
  padding: 8px 16px;
  font-size: 16px;
  border: 1px solid #d0d5dd;
  border-radius: 4px;

  &.error {
    border-color: red;
    color: #222 !important;
  }
`

const HiddenInput = styled.input.attrs({ type: 'file' })`
  display: none;
`

const ChooseFileButton = styled.label`
  display: inline-block;
  padding: 8px 16px;
  background-color: #7f56d9;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`

const InputFile = ({ file, error, onChange, ...props }) => {
  return (
    <FileInputWrapper {...props}>
      <FileNameInput
        className={error ? 'error' : ''}
        value={file?.name ?? 'No file chosen'}
        placeholder="No file chosen"
      />
      <HiddenInput id="custom-file-input" onChange={onChange} />
      <ChooseFileButton htmlFor="custom-file-input">Choose File</ChooseFileButton>
    </FileInputWrapper>
  )
}

export default InputFile
