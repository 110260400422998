import React from 'react'
import { styled } from '@mui/system'

import LogList from './log-list'

const ActivityLogs = ({ children, ...props }) => {
  return <LogsStreamContainer {...props}>{children}</LogsStreamContainer>
}

ActivityLogs.LogList = LogList

export default ActivityLogs

const LogsStreamContainer = styled('ul')`
  display: flex;
  flex-direction: column-reverse;
  align-self: stretch;
  background: var(--Base-White);
  padding: 0 0.75rem;
  margin: 0;
  min-height: 0;
  overflow-y: auto;
  position: relative;

  &::-webkit-scrollbar {
    width: 0.4em;
    position: absolute;
    left: 0;
    z-index: 1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--Gray-400);
    border-radius: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--Gray-100);
    border-radius: 0.25rem;
  }

  & li:first-child {
    border-bottom: none !important;
  }

  & li {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    row-gap: 0.5rem;
    padding: 0.75rem 0.25rem;
    border-bottom: 1px solid var(--Gray-200);

    & .content {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      & p {
        color: var(--Gray-700);
        font-size: 14px;
        font-weight: 500;
        line-height: 1.14;
        white-space: nowrap;
        transition: none;
        margin: 0;

        &.action {
          line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        &.link {
          color: var(--Blue-500, #2e90fa);
          cursor: pointer;
          transition: all 0.3s ease;
          width: fit-content;

          &:hover,
          &:focus {
            color: var(--Blue-700, #175cd3);
          }
        }
      }
    }

    & p.author {
      margin: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-flex;
      transition: none;
      color: var(--Gray-700);
      width: 100%;
      min-height: 20px;
      gap: 0.25rem;
    }

    & div.date {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1.2;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 0;
      width: 100%;
      color: var(--Gray-400);
    }
  }
`
