import { Menu, MenuItem } from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import iconClose from '../../assets/images/icon-close.svg'
import iconDotsHorizontal from '../../assets/images/icon-dots-horizontal.svg'
import ImageButton from '../../components/widget/element/ImageButton'
import IconChevronLeft from '../../components/widget/icon/IconChevronLeft'
import { getMileStoneScopeInfoByScopeId } from '../../redux/actions/projectTask'
import { selectProjectTask } from '../../redux/selectors'
import ItemScope from './item-preview/ItemScope'

import { useTranslation } from 'react-i18next'

export default function PreviewItemMilestone({ scopeId, projectName, onClose, menuActive, onDataChange }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { milestoneScopeInfo } = useSelector(selectProjectTask)
  const [milestoneScopeData, setMilestoneScopeData] = useState()
  const [menuList, setMenuList] = useState([
    {
      name: t('overview'),
      value: 'overview',
      active_status: true,
    },
  ])
  const [isDataUpdated, setIsDataUpdated] = useState(false)
  const [currentMenuSelected, setCurrentMenuSelected] = useState('overview')

  const [actionEl, setActionEl] = useState(null)
  const openMenu = Boolean(actionEl)

  useEffect(() => {
    if (scopeId) {
      dispatch(getMileStoneScopeInfoByScopeId(scopeId))
    }
  }, [scopeId])

  useEffect(() => {
    if (Object.keys(milestoneScopeInfo).length > 0) {
      setMilestoneScopeData(milestoneScopeInfo)
    }
  }, [milestoneScopeInfo])

  useEffect(() => {
    if (menuActive) {
      handleClickTabMenu({ value: menuActive })
    }
  }, [menuActive])

  const handleActonClick = (event) => {
    setActionEl(event.currentTarget)
  }
  const handleActionMenu = (type) => {
    setActionEl(null)
    if (type == 'report') {
    } else if (type == 'pdf') {
    }
  }

  const handleClickTabMenu = (data) => {
    let tmpList = [...menuList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setCurrentMenuSelected(data.value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuList([...tmpList])
  }

  // const handleClickLink = () => {

  // }

  const handleOnStatusChange = () => {
    if (onDataChange) {
      onDataChange()
    }
  }

  return (
    <Styled>
      <div className="header">
        <div className="left">
          <div className="wrp-btn-back">
            <div className="btn-back" onClick={() => onClose()}>
              <IconChevronLeft />
            </div>
            <div className="txt-title">{milestoneScopeData?.task_scope?.name || 'Scope Name'}</div>
          </div>
        </div>
        <div className="right">
          {/* <ImageButton src={iconLink} onClick={() => handleClickLink()} /> */}
          <ImageButton src={iconDotsHorizontal} onClick={handleActonClick} />
          <Menu anchorEl={actionEl} open={openMenu} onClose={handleActionMenu}>
            <MenuItem onClick={() => handleActionMenu('report')}>Download Summary Report</MenuItem>
            <MenuItem onClick={() => handleActionMenu('pdf')}>Download PDF</MenuItem>
          </Menu>
          <ImageButton src={iconClose} onClick={() => onClose()} />
        </div>
      </div>

      <div className="body">
        <div className="wrp-txt-title">
          <div className="wrp-tab-menu">
            {menuList?.map((item, index) => (
              <button
                onClick={() => handleClickTabMenu(item)}
                key={'btn-' + index}
                className={item.active_status ? 'menu-active' : 'btn-menu-style'}
              >
                {item.name}
              </button>
            ))}
          </div>

          <div className="status-project">{milestoneScopeData?.status}</div>
        </div>

        <div className="wrp-content-container">
          {currentMenuSelected == 'overview' && (
            <ItemScope
              projectName={projectName}
              data={milestoneScopeData}
              onTaskAction={() => handleOnStatusChange()}
            />
          )}
        </div>
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  overflow: hidden;
  height: 100%;

  .body {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem 0.5rem;
  }

  .done {
    justify-content: flex-end !important;
  }

  .header {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wrp-tools {
    display: flex;
    align-items: center;
  }
  .wrp-btn-back {
    display: flex;
    align-items: center;
    padding-left: 30px;
  }
  .btn-back {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
  }
  .txt-title {
    margin-left: 10px;
  }

  .wrp-txt-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 2rem;
  }
  .txt-title {
    font-size: 24px;
    font-weight: 600;
  }

  .wrp-tab-menu {
    width: 100%;
    display: flex;

    .btn-menu-style {
      border: none;
      border-bottom: 2px solid transparent;
      padding: 10px 8px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
    }

    .menu-active {
      border: none;
      padding: 10px 8px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      border-bottom: 2px solid var(--Primary-600) !important;
    }
  }

  .wrp-task-tools-container {
    display: flex;
    align-items: center;

    img {
      padding: 0.5rem;
      cursor: pointer;
      margin-left: 0.5rem;
      width: 35px;
      height: 35px;
    }

    .icon {
      width: 22px;
      height: 22px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .status-project {
    font-weight: bold;
    text-transform: capitalize;
  }
  .wrp-content-container {
    height: 100%;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .right {
    display: flex;
    align-items: center;
  }
`
