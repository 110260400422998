import { combineReducers } from 'redux'
import {
  GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION,
  GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT,
  GET_EMPLOYEE_LIST_ANALYSIS,
  GET_ANALYSIS_EMPLOYEE_INFO,
} from '../actionTypes'

const initialState = {
  analysisActivityEmployee: {},
  analysisActivityEmployeeInfo: {},
  employeeList: [],
  page: 1,
  pageCount: 1,
  total: 1,
  limit: 10,
  isLoading: false,
  state: '',
}

const employeeActivityReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION.REQUEST',
      }
    case GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION.SUCCESS: {
      const { data } = action
      return {
        ...state,
        analysisActivityEmployee: data,
        isLoading: false,
        state: 'GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION.SUCCESS',
      }
    }
    case GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION.FAILURE',
      }
    case GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT.REQUEST',
      }
    case GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT.SUCCESS: {
      const { data } = action
      return {
        ...state,
        analysisActivityEmployee: data,
        isLoading: false,
        state: 'GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT.SUCCESS',
      }
    }
    case GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT.FAILURE',
      }
    case GET_EMPLOYEE_LIST_ANALYSIS.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_EMPLOYEE_LIST_ANALYSIS.REQUEST',
      }
    case GET_EMPLOYEE_LIST_ANALYSIS.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        employeeList: data,
        page: meta.page,
        limit: meta.pageSize,
        pageCount: meta.pageCount,
        total: meta.total,
        isLoading: false,
        state: 'GET_EMPLOYEE_LIST_ANALYSIS.SUCCESS',
      }
    }
    case GET_EMPLOYEE_LIST_ANALYSIS.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_EMPLOYEE_LIST_ANALYSIS.FAILURE',
      }
    case GET_ANALYSIS_EMPLOYEE_INFO.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_ANALYSIS_EMPLOYEE_INFO.REQUEST',
      }
    case GET_ANALYSIS_EMPLOYEE_INFO.SUCCESS: {
      const { data } = action
      return {
        ...state,
        analysisActivityEmployeeInfo: data,
        isLoading: false,
        state: 'GET_ANALYSIS_EMPLOYEE_INFO.SUCCESS',
      }
    }
    case GET_ANALYSIS_EMPLOYEE_INFO.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_ANALYSIS_EMPLOYEE_INFO.FAILURE',
      }
    default:
      return state
  }
}

export default combineReducers({
  employeeActivityReducer,
})
