import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { capitalizeEachWord } from 'utils/stringHelpers'

export default function ViewTaskTplTabs({ onChange = (tab) => {} }) {
  const { t } = useTranslation()
  const items = [
    { id: 'overview', label: capitalizeEachWord(t('overview')) },
    { id: 'logs', label: capitalizeEachWord(t('logs')) },
  ]

  const [currentTab, setCurrentTab] = useState(items[0].id)

  const handleChangeTab = (tab) => {
    setCurrentTab(tab)
    onChange?.(tab)
  }

  return items.map((tab) => {
    const isActive = currentTab === tab.id

    return (
      <div
        key={tab.id}
        className={`tab-item ${isActive ? 'active' : ''}`}
        onClick={() => currentTab !== tab.id && handleChangeTab(tab.id)}
      >
        {tab.label}
      </div>
    )
  })
}
