import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'

import Button from '../../components/form/button/Button'
import Table from '../../components/common/Table'
import SearchField from './SearchField'
import FilterButton from './FilterButton'
import FilterList from './FilterList'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import { BASE_API } from '../../configs/app'
import { getCmsItemList, deleteCmsContent } from '../../redux/actions/cms'
import { selectCmsContentType, selectCmsByType } from '../../redux/selectors'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/icon-edit.svg'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { useTranslation } from 'react-i18next'
const Div = styled.div`
  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .content-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;

    .search-wrapper {
      margin-right: 12px;
    }

    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }
  }

  .col-buttonDelete {
    text-align: center;

    img {
      cursor: pointer;
    }
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
`

const FileWrapper = styled.div`
  display: flex;

  img,
  a {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--Gray-200);
    color: var(--Gray-700);
  }

  > * + * {
    margin-left: -15px;
  }
`

const formatValue = (structure, value) => {
  const { t } = useTranslation()

  if (value == null) {
    return '-'
  }

  switch (structure.type) {
    case 'datetime':
      return dayjs(value).format('YYYY-MM-DD HH:mm')
    case 'date':
      return dayjs(value).format('YYYY-MM-DD')
    case 'time':
      return dayjs(`${dayjs().format('YYYY-MM-DD')}:${value}`).format('HH:mm')
    case 'media': {
      if (!value.data) {
        return null
      }

      const listValue = value.data.attributes ? [value.data.attributes] : value.data.map((v) => v.attributes)
      return (
        <FileWrapper>
          {listValue?.map((v) => {
            if (v.mime.startsWith('image')) {
              return <img key={v.url} src={`${BASE_API}${v.formats?.thumbnail?.url || v.url}`} />
            }
            return (
              <a key={v.url} href={`${BASE_API}${v.url}`} target="_blank" rel="noreferrer">
                {v.ext}
              </a>
            )
          })}
        </FileWrapper>
      )
    }
    case 'relation': {
      const { relationType, mainField } = structure
      if (relationType === 'oneToOne' || relationType === 'manyToOne') {
        return value.data?.attributes[mainField] || value.data?.id || '-'
      } else if (relationType === 'oneToMany' || relationType === 'manyToMany') {
        return value.data?.map((v) => v?.attributes[mainField] || value.data?.id).join(', ') || '-'
      } else {
        return '-'
      }
    }
    default:
      return value.toString()
  }
}

const mapType =
  (type, metadatas = {}) =>
  (v) => {
    return Object.keys(type).reduce((pre, cur) => {
      return {
        ...pre,
        [cur]: formatValue({ ...type[cur], mainField: metadatas[cur]?.list?.mainField }, v[cur]),
      }
    }, {})
  }

const ContentTable = ({ type }) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { uid, attributes, info } = useSelector(selectCmsContentType(type))
  const { isLoading, config, data, sort, filter } = useSelector(selectCmsByType(type))
  const [deleteItem, setDeleteItem] = useState(null)

  const { displayName } = info || {}
  const { contentType } = config || {}
  const { results = [], pagination } = data || {}
  const { page, pageCount, total } = pagination || {}

  const { metadatas = {}, settings = {} } = contentType || {}
  const { list: columnList = [] } = contentType?.layouts || {}

  const columns = columnList?.map((v) => ({
    accessor: v,
    Header: metadatas[v]?.list?.label || v,
    disableSortBy: Boolean(!metadatas[v]?.list?.sortable),
  }))

  const mergeColumns = [
    ...columns,
    {
      accessor: 'buttonDelete',
      disableSortBy: true,
    },
    {
      accessor: 'linkEdit',
      disableSortBy: true,
    },
  ]

  const mergeResults = results?.map((v) => {
    return {
      ...mapType(attributes, metadatas)(v),
      buttonDelete: <img src={iconDelete} title="Delete" onClick={() => setDeleteItem(v)} />,
      linkEdit: (
        <Link to={`${pathname}/${v.id}/edit`}>
          <img src={iconEdit} title="Edit" />
        </Link>
      ),
    }
  })

  const handleAddFilter = (data) => {
    dispatch(getCmsItemList(type, { page: 1, filter: [...filter, data] }))
  }

  const handleRemoveFilter = (index) => {
    dispatch(getCmsItemList(type, { page: 1, filter: filter.filter((v, i) => index !== i) }))
  }

  const handleDeleteItem = () => {
    dispatch(deleteCmsContent(uid, deleteItem.id))
    setDeleteItem(null)
  }

  const fetchData = useCallback(
    (state = {}) => {
      const { sort } = state
      const { mainField } = contentType?.metadatas[sort?.id]?.list || {}

      if (sort?.id && mainField) {
        sort.id += `.${mainField}`
      }

      dispatch(getCmsItemList(type, state))
    },
    [contentType, dispatch, type]
  )

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Div>
      <header>
        <div className="content-title">{displayName} list</div>
        <div className="content-header">
          {settings.searchable && <SearchField className="search-wrapper" type={type} />}
          {settings.filterable && (
            <>
              <FilterButton className="filter-wrapper" type={type} onSubmit={handleAddFilter} />
              <FilterList className="filter-list" type={type} onRemove={handleRemoveFilter} />
            </>
          )}
        </div>
      </header>
      {sort?.id && (
        <div className="table-wrapper">
          <div className="table-header">
            <div className="group">
              <div className="table-title">{displayName}</div>
              <div className="table-total">{total} items</div>
            </div>
            <Link className="create-bt" to={`${pathname}/create`}>
              <Button append={<img src={iconPlus} />}>New {displayName}</Button>
            </Link>
          </div>
          <Table
            columns={mergeColumns}
            data={mergeResults}
            onStateChange={fetchData}
            initSort={sort}
            enabledSort
            pageCount={pageCount}
            page={page}
            loading={isLoading}
          />
        </div>
      )}
      <DialogConfirmDelete
        open={Boolean(deleteItem)}
        onClose={() => setDeleteItem(null)}
        onSubmit={handleDeleteItem}
        title={t('confrim_delete')}
        description="Are you sure you want to delete this item? This action cannot be undone."
      />
    </Div>
  )
}

export default ContentTable
