import Button from 'components/form/button/Button'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { generateId, removeArrayItemByIndex } from 'utils/helper'
import ViewByType from './ViewByType'
export default function DynamicTable({ initValue, className, onChange, onTableChange, isPreview }) {
  const [tableData, setTableData] = useState()
  const [mockupItem, setMockupItem] = useState()

  useEffect(() => {
    if (initValue) {
    
      setTableData(initValue)
      const tmpBodyZero = initValue?.body[0]

      setMockupItem(tmpBodyZero)
    }
  }, [initValue])

  const getClassByViewItem = (viewItem) => {
    if (!isPreview) {
      return viewItem?.class_name_create ? ' ' + viewItem.class_name_create : ''
    }
    return viewItem?.class_name_preview ? ' ' + viewItem.class_name_preview : ''
  }
  const handleClickAddList = () => {
    const tmpItem = getTmpItem()
    let tmpTable = JSON.parse(JSON.stringify(tableData))
    tmpTable.body = [...tmpTable.body, tmpItem]
    setTableData(tmpTable)
    if (onTableChange) {
      onTableChange(tmpTable)
    }
  }
  const handleClickRemoveItem = (rowIndex) => {
    if (tableData?.body?.length > 1) {
      let tmpTable = JSON.parse(JSON.stringify(tableData))
      let tmpBody = removeArrayItemByIndex(tmpTable.body, rowIndex)

      tmpTable.body = tmpBody

      setTableData(tmpTable)
      if (onTableChange) {
        onTableChange(tmpTable)
      }
    }
  }

  const getTmpItem = () => {
    const tmpMockItem = JSON.parse(JSON.stringify(mockupItem))

    for (let i = 0; i < tmpMockItem.length; i++) {
      tmpMockItem[i].id = generateId(6)
      delete tmpMockItem[i].value
    }

    return tmpMockItem
  }

  return (
    <Styled className={className}>
      <div className={'wrp_table' + (isPreview ? ' wrp_table_preview' : '')}>
        <table className={'table'}>
          <thead>
            <tr>
              {tableData?.header?.map((headerItem, headerIndex) => {
                if (isPreview && headerItem?.type == 'action') {
                  return
                }
                return (
                  <th
                    key={'header-item-' + headerIndex}
                    style={headerItem?.style}
                    className={'th' + getClassByViewItem(headerItem)}
                  >
                    {/* <div className={'th_inner'}>{headerItem?.title || ''}</div> */}
                    <ViewByType dataItem={{ ...headerItem, headerIndex }} isPreview={isPreview} />
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {tableData?.body?.map((rowItem, rowIndex) => (
              <tr key={'row-item-' + rowItem[0].id}>
                {rowItem?.map((colItem, colIndex) => {
                  if (isPreview && colItem?.type == 'button_delete') {
                    return
                  }
                  return (
                    <td
                      key={'col-item-' + colIndex}
                      style={colItem?.style}
                      className={'td' + getClassByViewItem(colItem)}
                    >
                      <div className={'td_inner'}>
                        {colItem?.type && (
                          <ViewByType
                            dataItem={{ ...colItem, rowIndex }}
                            onChange={(e) => {
                              if (onChange) {
                                onChange({ id: colItem.id, value: e })
                              }
                            }}
                            onClickDelete={() => {
                              handleClickRemoveItem(rowIndex)
                            }}
                            isPreview={isPreview}
                          />
                        )}
                      </div>
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {!isPreview && (
        <Button className={'add_list_button'} onClick={handleClickAddList}>
          Add List
        </Button>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  position: relative;
  /* margin-top: 20px; */
  width: 100%;
  overflow: hidden;

  .wrp_table {
    overflow-x: auto;
    border: 1px solid var(--Gray-200);
    border-radius: 10px;
  }

  table {
    border-spacing: 0;
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
    /* table-layout: fixed; */
  }
  th:first-child,
  td:first-child {
    width: 20px;
  }

  th,
  td {
    border-collapse: collapse;
    text-align: left;
    font-size: 14px;
    padding: 12px;
    color: var(--Table-Font-Default);
    /* min-width: 100px; */

    background-color: var(--Table-Bg-Default);
  }

  table tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }

  th {
    background: var(--Gray-50);
  }

  .input_text_table_long {
    width: 500px;
  }
  .btn-delete {
    cursor: pointer;
  }
  .text_field {
    width: 100%;
  }

  .add_list_button {
    margin-top: 24px;
  }
`
