import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { DateTimeHHmmssDisplay } from '../../utils/common'

import styled from 'styled-components'
import KPITableData from '../../components/feature/kpi-management/KPITableData'

import { TbBuildingBank } from 'react-icons/tb'
import { ImTree } from 'react-icons/im'

import { selectKPI } from '../../redux/selectors'
import { getKpiHistoryDetail } from '../../redux/actions/kpiManagement'

const Div = styled.div`
  // box model style
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }
  .mr-10-px {
    margin-right: 10px;
  }
  .mr-16-px {
    margin-right: 16px;
  }

  // typo style
  h1,
  h2,
  p {
    margin: 0;
  }
  h1 {
    color: var(--Gray-900);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  p {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .kpi-name {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .highlight-text {
    color: var(--Primary-600);
  }
  .date-text {
    color: var(--Gray-900);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .kpi-heading {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .kpi-des {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  // color
  .icon-color {
    color: var(--Primary-600);
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-center {
    align-items: center;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`
const Badge = styled.div`
  display: inline-block;

  border-radius: 16px;
  border: 1px solid #000;
  background: #000;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: var(--Base-White);
  text-align: center;

  padding: 0 12px;
`

function KPIHistoryDetail() {
  // external hook
  const { id } = useParams()
  const dispatch = useDispatch()

  // initiate data
  const fetchHistoryDetail = useCallback(
    (id) => {
      dispatch(getKpiHistoryDetail(id))
    },
    [dispatch]
  )
  useEffect(() => {
    fetchHistoryDetail(id)
  }, [id])

  // kpi history detail
  const { kpiHistoryDetail } = useSelector(selectKPI)
  const [kpiHistoryData, setKpiHistoryData] = useState()
  const [kpiOrganization, setKpiOrganization] = useState([])
  const [kpiDepartment, setKpiDepartment] = useState([])

  useEffect(() => {
    if (kpiHistoryDetail) {
      setKpiHistoryData({
        name: kpiHistoryDetail?.kpi?.name,
        createdAt: kpiHistoryDetail?.kpi?.createdAt,
        createdBy: kpiHistoryDetail?.creator,
      })

      if (kpiHistoryDetail?.kpi?.kpi_organizations && kpiHistoryDetail?.kpi?.kpi_organizations?.length > 0)
        setKpiOrganization(kpiHistoryDetail?.kpi.kpi_organizations)
      if (kpiHistoryDetail?.kpi?.kpi_departments?.length > 0) setKpiDepartment(kpiHistoryDetail?.kpi?.kpi_departments)
    }
  }, [kpiHistoryDetail])

  return (
    <Div>
      <header className="mb-20-px">
        <h1>KPIs Annoucement</h1>
        <p>Manage and setup KPI for company, departments, and employees.</p>
      </header>

      <Divider className="mb-24-px" />

      {/* header section */}
      <FlexContainer className="justify-content-space-between align-items-center mb-32-px">
        <h2 className="kpi-name">
          KPI Name : {kpiHistoryData && <span className="highlight-text">{kpiHistoryData?.name}</span>}
        </h2>
        {kpiHistoryData && (
          <div>
            <Badge className="mr-10-px">History Version</Badge>
            <span>
              <b className="date-text">{DateTimeHHmmssDisplay(kpiHistoryData.createdAt)}</b> by{' '}
              {kpiHistoryData.createdBy}
            </span>
          </div>
        )}
      </FlexContainer>

      {/* Organization KPI section */}
      {kpiOrganization.length > 0 && (
        <section className="mb-32-px">
          <FlexContainer className="align-items-center mb-24-px">
            <TbBuildingBank size={24} className="icon-color mr-16-px" />
            <div>
              <h2 className="kpi-heading mb-8-px">Organization KPI</h2>
              <p className="kpi-des">Organization goals</p>
            </div>
          </FlexContainer>

          <KPITableData data={kpiOrganization} />
        </section>
      )}

      {/* Organization KPI section */}
      {kpiDepartment.length > 0 && (
        <section className="mb-32-px">
          <FlexContainer className="align-items-center mb-24-px">
            <ImTree size={24} className="icon-color mr-16-px" />
            <div>
              <h2 className="kpi-heading mb-8-px">Department KPI: {kpiHistoryDetail?.department_name}</h2>
              <p className="kpi-des">Department goals</p>
            </div>
          </FlexContainer>

          <KPITableData data={kpiDepartment} />
        </section>
      )}
    </Div>
  )
}

export default KPIHistoryDetail
