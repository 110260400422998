import { DialogContent } from '@mui/joy'
import { Alert, AlertTitle, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { createElement, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RiExternalLinkLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import iconFail from 'assets/images/fail-icon.png'
import iconSuccess2 from 'assets/images/success-icon-2.png'
import Button from 'components/common/Button'
import ModalV2 from 'components/common/ModalV2'
import DynamicForm from 'components/form2/dynamic-form'
import { Option, Select } from 'components/form2/form-select'
import { APPROVER, DELIVERY, DOCUMENT, DOCUMENT_SIGNER, REVIEWER, SW } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { formatDate } from 'utils/dateHelpers'
import { Chip, Footer, InfoContainer, TabPanelContainer } from '.'
import AssetTab from './asset-tab'
import DeliveryTab from './delivery-tab'
import GuidelineTab from './guide-line-tab'
import LogsTab from './logs-tab'
import NoteTab from './note-tab'
import Tabs from './tabs'
import TaskTab from './task-tab'

const SoW = ({
  id: swId,
  docSignerId,
  openTask, // Deprecated prop. Use onActions.onClickTask instead of this. Will be removed in the future.
  onViewClick, // Deprecated prop. Use onActions.onViewSOP instead of this. Will be removed in the future.
  onSubmit,
  onAssign,
  onUnassign,
  onActions = {
    onSign: () => {},
    onReject: () => {},
    onClickTask: (taskId) => {},
    onCreateDocument: ({ uploadFile = false }) => {},
    onDeleteDocument: ({ uploadFile = false }) => {},
    onViewSOP: () => {},
    onUploadAssets: () => {},
    onDeleteAssets: () => {},
  },
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)
  const footerRef = useRef(null)
  const methods = useForm({ defaultValues: { note: '' } })

  const { sw: swActions, sp: spActions, extra: extraActions, document: documentActions } = opActions()
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { department_id, id: employeeId } = employee_info || {}

  const { getSW, postSW } = swActions()
  const { getSP } = spActions()
  const { getDocSigner, postDocSigner } = documentActions()
  const { clearNotes } = extraActions()

  const { sw, sw_employees } = opProcSelector
  const { sw_logs } = opProcSelector
  const { docSigner } = opProcSelector
  const { canUnassignSW } = opProcSelector

  const isDefaultSOW = !docSignerId
  const isSignDocument = !!docSignerId

  const isRejectReason =
    (sw?.reviewers || []).some((rev) => rev.status === REVIEWER.STATUS.NotPass) ||
    sw?.approver?.status === APPROVER.STATUS.Rejected

  const isAllCompleted = (sw?.deliveries || []).every((delivery) => delivery.is_completed === true)

  const documentData =
    Array.isArray(sw?.deliveries) &&
    sw.deliveries.length > 0 &&
    sw?.deliveries?.find((delivery) => delivery.type === DELIVERY.TYPE.Document)

  const canMarkAsCompleted = isAllCompleted
  const canSignDocument = documentData?.document?.status === DOCUMENT.STATUS.Pending

  const { setValue, handleSubmit, register, watch } = useForm()

  const [tab, setTab] = useState(isSignDocument ? 'delivery' : 'guideline')
  const [modalProps, setModalProps] = useState({ open: false })

  const isOwner = sw?.employee?.id === employeeId || false

  const permissionUnassign = async (id) => {
    dispatch(postSW().checkUnassign(id))
  }

  const fetchSWByID = async (id) => {
    dispatch(getSW().byId(id))
  }

  const fetchDocSignerByID = async (id) => {
    return dispatch(getDocSigner().byId(id))
  }

  const fetchEmployees = async (id, filters) => {
    dispatch(getSW().employees(id, { filters }))
  }

  const fetchSPByID = async () => {
    dispatch(getSP().byId(sw?.sp.id))
  }

  const fetchSWLogs = async (id, payload) => {
    dispatch(getSW().logs(id, payload))
  }

  const handleTabChange = (value) => {
    setTab(value)

    switch (value) {
      case 'guideline':
        break
      case 'task':
        break
      case 'delivery':
        break
      case 'logs':
        fetchSWLogs(swId)
        break
      case 'note':
        break
      case 'asset':
        break
      default:
        break
    }
  }

  const handleAssigned = (data) => {
    dispatch(postSW().assign(swId, data)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        fetchSWByID(swId)
        fetchSPByID()
        onAssign && onAssign()
        return
      }
      return
    })
  }

  const handleUnassigned = () => {
    dispatch(postSW().unassign(swId)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        fetchSWByID(swId)
        fetchSPByID()
        onUnassign && onUnassign()
        return
      }
    })
  }

  const handleMarkAsCompleted = () => {
    dispatch(postSW().complete(swId)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        onSubmit && onSubmit() // Close 'view-sw' drawer
        return
      }
    })
  }

  const formSchema = {
    properties: {
      ['note']: {
        type: 'textArea',
        props: {
          minRows: 3,
          maxRows: 5,
          multiline: true,
          error: methods.formState.errors.note && 'Please provide a reason for rejecting the document.',
        },
      },
    },
    required: ['note'],
  }

  useEffect(() => {
    // console.log(methods.formState.errors)
  }, [methods.formState])

  const handleSubmitRejectDocument = (data) => {
    const rejectSuccessModal = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
        // reload
        return
      },
      onOk() {
        setModalProps({ open: false })
        // reload
        return
      },
      titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
      titleText: 'Document rejected',
      contentText: 'The document has been successfully rejected.',
    }

    dispatch(postDocSigner().reject(docSigner?.id, data)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        setModalProps(rejectSuccessModal)
        fetchDocSignerByID(docSignerId)
        fetchSWByID(swId)
        onActions?.onReject && onActions.onReject()
        return
      }
    })
  }

  const handleRejectDocument = () => {
    const rejectDocumentModal = {
      open: true,
      titleText: 'Reject document',
      dialogContent: (
        <DialogContent sx={{ textAlign: 'center' }}>
          Please provide a reason for rejecting the document.
          <DynamicForm methods={methods} schema={formSchema} onSubmit={handleSubmitRejectDocument} />
        </DialogContent>
      ),
      okText: 'Reject',
      onOk() {
        methods.handleSubmit(handleSubmitRejectDocument)()
      },
      onCancel() {
        setModalProps({ open: false })
        methods.reset()
        return
      },
      onClose() {
        setModalProps({ open: false })
        methods.reset()
        return
      },
    }

    setModalProps(rejectDocumentModal)
  }

  const handleSignDocument = () => {
    const noSignatureModal = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
      },
      onOk() {
        setModalProps({ open: false })
      },
      titleIcon: createElement('img', { src: iconFail, width: 48, height: 48, alt: 'i-alert' }),
      titleText: 'No signature',
      dialogContent: (
        <DialogContent sx={{ textAlign: 'center' }}>
          ท่านยังไม่ได้อัพโหลดลายเซ็นลงในโปรไฟล์ กรุณาอัพโหลดลายเซ็นก่อนทำการเซ็น
          <Link to="/employee-profile/info">ไปที่โปรไฟล์</Link>
        </DialogContent>
      ),
      okProps: { color: 'error' },
    }

    const successSignModal = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
      },
      onOk() {
        setModalProps({ open: false })
        fetchDocSignerByID(docSignerId)
        fetchSWByID(swId)
        onActions?.onSign && onActions.onSign()
      },
      titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
      titleText: 'Document signed',
      contentText: 'The document has been successfully signed.',
    }

    const confirmSignModal = {
      open: true,
      // titleIcon: createElement('img', { src: iconFail, width: 48, height: 48, alt: 'i-alert' }),
      titleText: 'Confirm to sign document?',
      contentText:
        "Once you've signed the document, you can't undo this action.<br />Are you sure you want to proceed?",
      okText: 'Sign',
      onOk() {
        dispatch(postDocSigner().approve(docSigner?.id, { note: '' })).then((res) => {
          const { data, type, meta } = res
          const { hasSignature } = meta || {}

          if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
            if (!hasSignature) {
              return setModalProps(noSignatureModal)
            }

            return setModalProps(successSignModal)
          }
        })
      },
      onCancel() {
        setModalProps({ open: false })
      },
      onClose() {
        setModalProps({ open: false })
      },
    }

    setModalProps(confirmSignModal)
  }

  const handleViewClick = () => {
    const to = sw?.sp?.id && `/operation-process/department-operation/${sw.sp.id}`
    const pathname = location.pathname

    if (pathname === to) {
      onViewClick && onViewClick()
      onActions?.onViewSOP && onActions.onViewSOP()
      return
    }

    navigate(to)

    return
  }

  const reload = () => {
    fetchSWByID(swId)
  }

  const handleUploadDeliveryCallback = (props) => {
    reload()
    onActions?.onCreateDocument && onActions.onCreateDocument({ uploadFile: !!props?.uploadFile })
  }

  const handleDeleteDeliveryCallback = (props) => {
    reload()
    onActions?.onDeleteDocument && onActions.onDeleteDocument({ uploadFile: !!props?.uploadFile })
  }

  const handleUploadAssets = () => {
    reload()
    onActions?.onUploadAssets && onActions.onUploadAssets()
  }

  const handleDeleteAssets = () => {
    reload()
    onActions?.onDeleteAssets && onActions.onDeleteAssets()
  }

  const handleTaskTab = () => {
    reload()
  }

  useEffect(() => {
    if (!swId) return

    fetchSWByID(swId)
    fetchEmployees(swId)
    dispatch(clearNotes())

    if (docSignerId) {
      fetchDocSignerByID(docSignerId)
    }
  }, [swId, docSignerId])

  useEffect(() => {
    if (!sw) return

    setValue('employee.id', sw?.employee?.id)

    sw?.employee?.id && permissionUnassign(sw.id)
  }, [sw])

  return (
    <>
      <InfoContainer id="info-container">
        <section>
          <label>SOP:</label>

          <Stack direction="row" gap="0.5rem">
            <p>{sw?.sp?.name}</p>
            <a onClick={handleViewClick} style={{ cursor: 'pointer' }}>
              {t('view')} <RiExternalLinkLine fontSize={14} />
            </a>
          </Stack>
        </section>

        <section
          style={{
            alignItems: 'baseline',
          }}
        >
          <label>{t('assign_to')}:</label>
          {sw?.department?.id === department_id || !sw?.department || sw?.department === null ? (
            <Form
              onSubmit={handleSubmit(!sw?.employee?.id ? handleAssigned : handleUnassigned)}
              style={{
                alignItems: 'center',
              }}
            >
              {sw?.employee?.id ? (
                <>
                  <p>
                    {sw?.employee?.first_name} {sw?.employee?.last_name}
                  </p>

                  {/* Hide unassign button if status is completed */}
                  {sw?.status !== SW.STATUS.Completed && canUnassignSW && (
                    <Button type="submit" variant="outlined" disabled={isSignDocument}>
                      {t('unassign')}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Select
                    {...register('employee.id', {
                      value: sw?.employee?.id,
                    })}
                    onChange={(_, data) => setValue('employee.id', data)}
                    value={watch('employee.id')}
                  >
                    {sw_employees.map((employee) => (
                      <Option
                        key={employee.id}
                        value={employee.id}
                        renderValue={(option) => {
                          if (!option || option === null) {
                            return <div style={{ color: 'var(--Gray-300)' }}>{t('select_assignee')}</div>
                          }
                          return option.label
                        }}
                      >
                        {employee.full_name}
                      </Option>
                    ))}
                  </Select>

                  <Button type="submit" variant="outlined">
                    {t('assign')}
                  </Button>
                </>
              )}
            </Form>
          ) : (
            <p>-</p>
          )}
        </section>

        <section>
          <label>{t('due_date')}:</label>
          <p>{formatDate(sw?.due_date)}</p>
        </section>

        <section style={{ alignItems: 'baseline' }}>
          <label>{t('status')}:</label>
          <Chip label={sw.status} color={SW.STATUS_CHIP[sw.status]} />
        </section>

        <Tabs tabCounts={{ task: sw?.tasks?.length }} onChange={handleTabChange} defaultTab={tab} />

        <TabPanelContainer>
          {tab === 'guideline' && <GuidelineTab content={sw.description} />}
          {tab === 'task' && (
            <TaskTab
              items={sw.tasks}
              openTask={(taskId) => {
                openTask && openTask(taskId)
                onActions?.onClickTask && onActions.onClickTask(taskId)
              }}
              taskCallback={handleTaskTab}
              hideActions={isSignDocument}
            />
          )}
          {tab === 'delivery' && (
            <DeliveryTab
              deliveries={sw?.deliveries}
              onUploadCallback={handleUploadDeliveryCallback}
              onDeleteCallback={handleDeleteDeliveryCallback}
              hideActions={isSignDocument}
            />
          )}
          {tab === 'logs' && (
            <LogsTab
              items={sw_logs}
              notFoundDesc="There are no activities found for this SW."
              onActions={{
                onClickTask: (taskId) => (event) => {
                  openTask && openTask(taskId)
                  onActions?.onClickTask && onActions.onClickTask(taskId)
                },
              }}
            />
          )}
          {tab === 'note' && <NoteTab spId={sw?.sp?.id} />}
          {tab === 'asset' && (
            <AssetTab
              id={sw.id}
              allowUpload={!isSignDocument}
              onActions={{
                onUploadFile: handleUploadAssets,
                onDeleteFile: handleDeleteAssets,
              }}
            />
          )}
        </TabPanelContainer>
      </InfoContainer>

      {isRejectReason && (
        <Stack id="sow-dc-reject-msg" sx={{ width: '100%', marginBottom: '1rem', padding: '0 0.75rem' }} spacing={2}>
          {sw?.reviewers
            ?.filter((rev) => rev.status === REVIEWER.STATUS.NotPass)
            .map((reviewer) => (
              <Alert key={reviewer.id} severity="warning">
                <AlertTitle>
                  {t('review_not_pass_by')}: {reviewer?.employee?.first_name} {reviewer?.employee?.last_name}
                </AlertTitle>
                {t('rejected_reason')}: {reviewer?.note}
              </Alert>
            ))}

          {sw?.approver?.status === APPROVER.STATUS.Rejected && (
            <Alert severity="warning">
              <AlertTitle>
                {t('approval_rejected_by')}: {sw?.approver?.employee?.first_name} {sw?.approver?.employee?.last_name}
              </AlertTitle>
              {t('rejected_reason')}: {sw?.approver?.note}
            </Alert>
          )}
        </Stack>
      )}

      {documentData?.document?.status === DOCUMENT.STATUS.Rejected && (
        <Stack id="sow-dc-reject-msg" sx={{ width: '100%', marginBottom: '1rem', padding: '0 0.75rem' }} spacing={2}>
          {documentData.document.signers
            .filter((i) => i.note)
            .map((signer) => (
              <Alert key={signer.id} severity="warning">
                <AlertTitle>
                  {t('document_rejected_by')}: {signer?.employee?.first_name} {signer?.employee?.last_name}
                </AlertTitle>
                {t('rejected_reason')}: {signer?.note}
              </Alert>
            ))}
        </Stack>
      )}

      {isDefaultSOW && !(sw?.status === SW.STATUS.Completed || sw?.status === SW.STATUS.InQueue) && isOwner && (
        <Footer id="sow-dc-footer" ref={footerRef}>
          <Button variant="contained" onClick={handleMarkAsCompleted} disabled={!sw?.employee || !canMarkAsCompleted}>
            {t('mark_as_completed')}
          </Button>
        </Footer>
      )}

      {isSignDocument && (
        <Footer id="sow-dc-footer" ref={footerRef}>
          <Button
            variant="outlined"
            onClick={handleRejectDocument}
            disabled={!canSignDocument || docSigner?.status !== DOCUMENT_SIGNER.STATUS.WaitingForApprove}
          >
            {t('reject')}
          </Button>
          <Button
            variant="contained"
            onClick={handleSignDocument}
            disabled={!canSignDocument || docSigner?.status !== DOCUMENT_SIGNER.STATUS.WaitingForApprove}
          >
            {t('sign')}
          </Button>
        </Footer>
      )}

      <ModalV2
        {...modalProps}
        modalDialogProps={{
          sx: {
            p: '1.5rem',
          },
        }}
      />
    </>
  )
}

export default SoW

const Form = styled('form')`
  display: flex;
  gap: 1rem;
`
