import React from 'react'
import { Controller } from 'react-hook-form'

import styled from 'styled-components'
import { Box, Grid } from '@mui/material'
import TextField from 'components/form/TextField'
import TextArea from 'components/form/TextArea'
import Dropdown from 'components/form/Dropdown'
import Date from 'components/form/Date'
import DateTime from 'components/form/DateTime'
import Time from 'components/form/Time'
import RadioButtonForDynamic from './RadioButtonForDynamic'
import UploadSingleFile from './UploadSingleFile'

const Div = styled.div`
  .input-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 6px;
  }
  .w-100 {
    width: 100%;
  }
  .error {
    margin: 0;
    color: var(--Error-500);
  }
`

function DynamicForm({ formFields, control, setNewFormField, setAttachFile }) {
  const formatInteger = (input) => {
    // Remove any non-numeric characters from the input
    let integer = input.replace(/[^0-9]/g, '')

    // Add commas as thousand separators
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    return integer
  }
  const formatDecimalNumber = (input) => {
    // Split the input into integer and decimal parts based on the decimal point
    let [integer, decimal = ''] = input.split('.')

    // Remove non-numeric characters from integer part
    integer = integer.replace(/[^0-9]/g, '')

    // Add comma as thousands separator
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    // If there is a decimal part, and it's not just an empty string after a decimal point
    if (decimal !== '') {
      // Limit the decimal part to two digits
      decimal = decimal.substring(0, 2)
      return `${integer}.${decimal}`
    } else if (input.includes('.')) {
      // This checks if the user has just typed the decimal point
      return `${integer}.`
    }

    return integer
  }
  const renderInput = (key, value) => {
    const { title, type, attribute, reset_value, ref_fetch, ref_generate_number_array } = value
    const { disabled, options, placeholder } = attribute

    const rules = Object.entries(attribute)
      .filter(([key]) => key !== 'placeholder' && key !== 'options' && key !== 'disabled')
      .reduce((acc, atr) => {
        const [key, value] = atr
        if (value) return { ...acc, [key]: value }
        return acc
      }, {})

    switch (type) {
      case 'text-field-string': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    <TextField
                      className="w-100"
                      value={field.value}
                      onChange={field.onChange}
                      error={error}
                      disabled={disabled}
                      placeholder={placeholder}
                    />
                    {error && <p className="error">{error.message}</p>}
                  </>
                )
              }}
            />
          </Box>
        )
      }
      case 'text-field-integer': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <TextField
                    className="w-100"
                    value={value}
                    onChange={(event) => {
                      const { value } = event.target
                      const formattedValue = formatInteger(value)
                      onChange(formattedValue)
                    }}
                    error={error}
                    disabled={disabled}
                    placeholder={placeholder}
                  />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      case 'text-field-decimal': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <>
                  <TextField
                    className="w-100"
                    value={value}
                    onChange={(event) => {
                      const { value } = event.target
                      const formattedValue = formatDecimalNumber(value)
                      onChange(formattedValue)
                    }}
                    error={error}
                    disabled={disabled}
                    placeholder={placeholder}
                  />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      case 'textarea': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    <TextArea
                      value={field.value}
                      onChange={field.onChange}
                      error={error}
                      disabled={disabled}
                      placeholder={placeholder}
                    />
                    {error && <p className="error">{error.message}</p>}
                  </>
                )
              }}
            />
          </Box>
        )
      }
      case 'dropdown': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Dropdown
                    value={field.value}
                    onChange={field.onChange}
                    error={error}
                    disabled={disabled}
                    optionList={options}
                    placeHolder={placeholder}
                  />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      case 'dropdown-api': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Dropdown
                    value={field.value}
                    onChange={(value) => {
                      field.onChange(value)
                      setNewFormField(key, value, ref_fetch, reset_value, ref_generate_number_array)
                    }}
                    error={error}
                    disabled={disabled}
                    optionList={options}
                    placeHolder={placeholder}
                  />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      case 'date': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Date value={field.value} onChange={field.onChange} error={error} disabled={disabled} />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      case 'date-time': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <DateTime value={field.value} onChange={field.onChange} error={error} disabled={disabled} />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      case 'time': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Time value={field.value} onChange={field.onChange} error={error} disabled={disabled} />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      case 'radio': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <RadioButtonForDynamic options={options} value={field.value} onChange={field.onChange} />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      case 'upload-single': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <>
                  <UploadSingleFile
                    fileValue={value}
                    setAttachFile={(file) => {
                      setAttachFile(file, onChange)
                    }}
                  />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      default: {
        return <Box key={key}>can't map type render {type}</Box>
      }
    }
  }

  return (
    <Div>
      <Grid sx={{ pb: 2 }} container alignItems="flex-start" spacing={2}>
        {Object.entries(formFields).map(([key, value]) => (
          <Grid item {...value.view} key={key}>
            {renderInput(key, value)}
          </Grid>
        ))}
      </Grid>
    </Div>
  )
}

export default DynamicForm
