import React from 'react'
import styled from 'styled-components'

const DividerContainer = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`
function Divider({ ...props }) {
  return <DividerContainer {...props} />
}

export default Divider
