import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import SidePanel from '../../../components/common/SidePanel'
import DialogFail from '../../../components/dialog/DialogFail'
import Date from '../../../components/form/Date'
import TextField from '../../../components/form/TextField'
import Button from '../../../components/form/button/Button'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import DocumentRequestApprove from '../../../components/widget/combo/DocumentRequestApprove'
import DocumentShareAndSendEmail from '../../../components/widget/combo/DoucumentShareAndSendEmail'
import DocumentOptionButton from '../../../components/widget/element/DocumentOptionButton'
import ModalBox from '../../../components/widget/element/ModalBox'
import {
  createPurchase,
  editPurchaseDoc,
  getDocumentSettingByCompanyId,
  getSaleDocumentById,
} from '../../../redux/actions/document'

// import Table from '../../../../components/common/Table'
import DialogDocument from 'pages/document/DialogDocument'
import { selectDocument } from '../../../redux/selectors'

export default function CreateComparisonSchedule({ onCallback }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const viewType = searchParams.get('type')
  const navigateType = searchParams.get('navigate')
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { saleDocumentData, documentSettingInfo } = useSelector(selectDocument)
  const [supplierList, setSupplierList] = useState([
    // {
    //   company_name: 'Oppo Corp.',
    //   brand: 'Oppo1',
    //   model: 'Reno1',
    //   spec: 'จอภาพ : จอโค้ง 3D AMOLED ขนาด 6.7 นิ้ว',
    //   warranty: '1 ปี',
    //   price: 19990,
    //   shipping_cost: 0,
    //   discount: 0,
    //   duration: '3-5 วันทำการ',
    //   payment: 'เงินสด',

    //   price_vat: 0,

    //   is_error_company_name: false,
    //   is_error_brand: false,
    //   is_error_model: false,
    //   is_error_spec: false,
    //   is_error_warranty: false,
    //   is_error_price: false,
    //   is_error_shipping_cost: false,
    //   is_error_discount: false,
    //   is_error_duration: false,
    //   is_error_payment: false,
    // },
    // {
    //   company_name: 'Oppo2 Company',
    //   brand: 'Oppo2',
    //   model: 'Reno2',
    //   spec: 'จอภาพ : จอโค้ง 3D AMOLED ขนาด 6.7 นิ้ว',
    //   warranty: '2 ปี',
    //   price: 29990,
    //   shipping_cost: 10,
    //   discount: 0,
    //   duration: '5-7 วันทำการ',
    //   payment: 'เงินสด',

    //   price_vat: 0,

    //   is_error_company_name: false,
    //   is_error_brand: false,
    //   is_error_model: false,
    //   is_error_spec: false,
    //   is_error_warranty: false,
    //   is_error_price: false,
    //   is_error_shipping_cost: false,
    //   is_error_discount: false,
    //   is_error_duration: false,
    //   is_error_payment: false,
    // },
    // {
    //   company_name: 'Oppo3',
    //   brand: 'Oppo3',
    //   model: 'Reno3',
    //   spec: 'จอภาพ : จอโค้ง 3D AMOLED ขนาด 6.7 นิ้ว',
    //   warranty: '3 ปี',
    //   price: 39990,
    //   shipping_cost: 100,
    //   discount: 1000,
    //   duration: '7-10 วันทำการ',
    //   payment: 'เงินสด',

    //   price_vat: 0,

    //   is_error_company_name: false,
    //   is_error_brand: false,
    //   is_error_model: false,
    //   is_error_spec: false,
    //   is_error_warranty: false,
    //   is_error_price: false,
    //   is_error_shipping_cost: false,
    //   is_error_discount: false,
    //   is_error_duration: false,
    //   is_error_payment: false,
    // },

    {
      company_name: '',
      brand: '',
      model: '',
      spec: '',
      warranty: '',
      price: 0,
      shipping_cost: 0,
      discount: 0,
      duration: '',
      payment: '',

      price_vat: 0,

      is_error_company_name: false,
      is_error_brand: false,
      is_error_model: false,
      is_error_spec: false,
      is_error_warranty: false,
      is_error_price: false,
      is_error_shipping_cost: false,
      is_error_discount: false,
      is_error_duration: false,
      is_error_payment: false,
    },
    {
      company_name: '',
      brand: '',
      model: '',
      spec: '',
      warranty: '',
      price: 0,
      shipping_cost: 0,
      discount: 0,
      duration: '',
      payment: '',

      price_vat: 0,

      is_error_company_name: false,
      is_error_brand: false,
      is_error_model: false,
      is_error_spec: false,
      is_error_warranty: false,
      is_error_price: false,
      is_error_shipping_cost: false,
      is_error_discount: false,
      is_error_duration: false,
      is_error_payment: false,
    },
    {
      company_name: '',
      brand: '',
      model: '',
      spec: '',
      warranty: '',
      price: 0,
      shipping_cost: 0,
      discount: 0,
      duration: '',
      payment: '',

      price_vat: 0,

      is_error_company_name: false,
      is_error_brand: false,
      is_error_model: false,
      is_error_spec: false,
      is_error_warranty: false,
      is_error_price: false,
      is_error_shipping_cost: false,
      is_error_discount: false,
      is_error_duration: false,
      is_error_payment: false,
    },
  ])

  const TABLE_HEADER_COLUMN = [
    {
      Header: 'งบประมาณ:',
      accessor: 'budget',
      disableSortBy: false,
    },
    {
      Header: 'Supplier 1',
      accessor: 'supplier_1',
      disableSortBy: false,
    },
    {
      Header: 'Supplier 2',
      accessor: 'supplier_2',
      disableSortBy: false,
    },
    {
      Header: 'Supplier 3',
      accessor: 'supplier_3',
      disableSortBy: false,
    },
    // {
    //   Header: 'Total Price',
    //   accessor: 'total',
    //   disableSortBy: false,
    // },
    // {
    //   Header: '',
    //   accessor: 'delete',
    //   disableSortBy: false,
    // },
  ]

  const TABLE_BODY = [
    {
      title: 'ชื่อบริษัท',
      key: 'company_name',
    },
    {
      title: 'ยี่ห้อ',
      key: 'brand',
    },
    {
      title: 'รุ่น',
      key: 'model',
    },
    {
      title: 'รายละเอียด สเปค',
      key: 'spec',
    },
    {
      title: 'รับประกัน',
      key: 'warranty',
    },
    {
      title: 'ราคา',
      key: 'price',
    },
    {
      title: 'ค่าจัดส่ง',
      key: 'shipping_cost',
    },
    {
      title: 'ส่วนลด',
      key: 'discount',
    },
    {
      title: 'ราคารวม vat7%',
      key: 'price_vat',
    },
    {
      title: 'ระยะเวลาดำเนินการ',
      key: 'duration',
    },
    {
      title: 'การชำระเงิน',
      key: 'payment',
    },
  ]

  // const DEFAULT_BODY_ITEM_DATA = {
  //   no: '',
  //   product_name: '',
  //   price: 0,
  //   quantity: 0,
  //   total: 0,
  //   delete: false,

  //   //validate
  //   is_error_product_name: false,
  //   is_error_price: false,
  //   is_error_quantity: false,
  // }

  const [dataCreating, setDataCreating] = useState({
    id: '',

    supplier_list: [],
    company_id: company_info?.id,
    doc_type: viewType,
    budget: '',
    doc_date: '',
    conclusion: '',

    number_of_copy: 1,
    company_logo: '',
    rubber_stamp: '',
    // purchaser_name: '',
    is_external: navigateType == 'external' ? true : false,
  })

  // const [bodyTableList, setBodyTableList] = useState([])
  const [validateInputData, setValidateInputData] = useState({
    is_error_conclusion: false,
    is_error_doc_date: false,
    is_error_budget: false,
    is_error_supplier_list: false,
  })
  const [isShowFailedModal, setIsShowFailedModal] = useState(false)
  const [currentDocId, setCurrentDocId] = useState('')

  const [dialogPurchaseType, setDialogPurchaseType] = useState('')
  const [isShowDialogPreview, setIsShowDialogPreview] = useState(false)
  const [isShowModalDownload, setIsShowModalDownload] = useState(false)
  const [isShowDialogRequestApprove, setIsShowDialogRequestApprove] = useState(false)

  const [isShowModalShare, setIsShowModalShare] = useState(false)
  const [isHindModalShare, setIsHindModalShare] = useState(false)
  const [isEditPurchase, setIsEditPurchase] = useState(false)
  const [imageForDocument, setImageForDocument] = useState({
    url_company_logo: '',
    url_rubber_stamp: '',
    url_signature_file: '',
  })
  const [textPurchaseCondition, setTextPurchaseCondition] = useState('')
  // const [debitSummary, setDebitSummary] = useState(0)
  // const [creditSummary, setCreditSummary] = useState(0)
  const [currentDocType, setCurrentDocType] = useState('')
  // const [isErrorSellerId, setIsErrorSellerId] = useState(false)
  // const [isErrorSellerName, setIsErrorSellerName] = useState(false)
  // const [isHasSellerId, setIsHasSellerId] = useState(false)

  useEffect(() => {
    ;(async () => {
      let url = window.location.pathname
      const myArray = url.split('/')
      for (let i = 0; i < myArray.length; i++) {
        if (myArray[i] == 'edit') {
          setIsEditPurchase(true)
          // let tmpId = myArray[myArray.length - 1]
          let tmpId = searchParams.get('id')
          setCurrentDocType(viewType)
          setCurrentDocId(tmpId)
          dispatch(getSaleDocumentById(tmpId))
        }
        if (myArray[i] == 'create') {
          setIsEditPurchase(false)
          // let tmpDocType = myArray[myArray.length - 1]
          // setDataCreating({ ...dataCreating, doc_type: tmpDocType })
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (Object.keys(saleDocumentData).length > 0 && isEditPurchase) {
      let tmpSupplierList = []
      if (saleDocumentData?.supplier_list?.length > 0) {
        let tmpList = saleDocumentData?.supplier_list
        for (let i = 0; i < tmpList.length; i++) {
          let tmpObj = {
            ...tmpList[i],
            price_vat: tmpList[i]?.price + tmpList[i]?.price * 0.07 || 0,
            is_error_company_name: false,
            is_error_brand: false,
            is_error_model: false,
            is_error_spec: false,
            is_error_warranty: false,
            is_error_price: false,
            is_error_shipping_cost: false,
            is_error_discount: false,
            is_error_duration: false,
            is_error_payment: false,
          }
          tmpSupplierList.push(tmpObj)
        }
        setSupplierList(tmpSupplierList)
      }

      let tmpData = {
        number_of_copy: saleDocumentData?.number_of_copy,
        company_logo: saleDocumentData?.company_logo,
        rubber_stamp: saleDocumentData?.rubber_stamp,
        condition: saleDocumentData?.condition,

        id: saleDocumentData?.seller?.id,
        doc_type: saleDocumentData?.doc_type,
        doc_group: saleDocumentData?.doc_group,
        doc_id: saleDocumentData?.doc_id,
        doc_date: saleDocumentData?.doc_date,
        supplier_list: tmpSupplierList,

        budget: saleDocumentData?.budget,
        conclusion: saleDocumentData?.conclusion,
      }
      setDataCreating(tmpData)
    }
    dispatch(getDocumentSettingByCompanyId())
  }, [saleDocumentData, isEditPurchase])

  useEffect(() => {
    if (documentSettingInfo) {
      let tmpImageForDocumentObj = { ...imageForDocument }
      // let tmpDocType = dataCreating?.doc_type

      if (viewType != '') {
        tmpImageForDocumentObj.url_company_logo = documentSettingInfo[viewType]?.company_logo
        tmpImageForDocumentObj.url_rubber_stamp = documentSettingInfo[viewType]?.rubber_stamp
        // tmpImageForDocumentObj.url_signature_file = documentSettingInfo[viewType]?.signature

        let tmpDataObj = { ...dataCreating }
        tmpDataObj.signature_list = documentSettingInfo[viewType]?.signature_list
        tmpDataObj.company_logo = documentSettingInfo[viewType]?.company_logo
        tmpDataObj.rubber_stamp = documentSettingInfo[viewType]?.rubber_stamp
        setDataCreating(tmpDataObj)
      }
      setImageForDocument(tmpImageForDocumentObj)
      setTextPurchaseCondition(documentSettingInfo?.purchase_condition)
    }
  }, [documentSettingInfo])

  // useEffect(() => {
  //   if (dataCreating?.product_list) {
  //     let tmpData = { ...dataCreating }

  //     const viewList = tmpData.product_list.map((item, index) => ({
  //       no: index + 1,
  //       product_name: (
  //         <TextField
  //           className={'input-table'}
  //           placeholder={'Details'}
  //           onChange={(e) => handleChangePaymentList(e, 'product_name', index)}
  //           value={item.product_name}
  //           error={item?.is_error_product_name}
  //         />
  //       ),
  //       quantity: (
  //         <TextField
  //           className={'input-table'}
  //           placeholder={'0'}
  //           onChange={(e) => handleChangePaymentList(e, 'quantity', index)}
  //           value={item.quantity}
  //           error={item?.is_error_quantity}
  //           pattern={'^[0-9.-/]+$'}
  //         />
  //       ),
  //       price: (
  //         <TextField
  //           className={'input-table'}
  //           placeholder={'0.00'}
  //           onChange={(e) => handleChangePaymentList(e, 'price', index)}
  //           value={convertIntComma(item?.price || 0)}
  //           error={item?.is_error_price}
  //           pattern={'^[0-9.-/]+$'}
  //         />
  //       ),
  //       total: calculateItemTotalPrice(item.price, item.quantity),
  //       delete: (
  //         <div className="btn-delete" onClick={() => handleClickBtnDelete(index)}>
  //           <IconBin />
  //         </div>
  //       ),
  //     }))

  //     // calculateDebitAndCreditSummary(tmpData.product_list)
  //     setBodyTableList(viewList)
  //   }
  // }, [dataCreating])

  const handleChangeData = (e, type) => {
    let tmpObj = { ...dataCreating }
    let tmpValidateObj = { ...validateInputData }

    if (type == 'conclusion') {
      tmpObj.conclusion = e.target.value
      if (e.target.value != '') {
        tmpValidateObj.is_error_conclusion = false
      }
    } else if (type == 'doc_date') {
      tmpObj.doc_date = e
      if (e) {
        tmpValidateObj.is_error_doc_date = false
      }
    } else if (type == 'budget') {
      let tmpString = e.target.value
      let tmpInt = tmpString?.replace(/\D/g, '') || 0
      tmpObj.budget = parseInt(tmpInt)
      if (e.target.value != '') {
        tmpValidateObj.is_error_budget = false
      }
    }
    console.log('tmpObj handleChangeData: ' + JSON.stringify(tmpObj, null, 2))
    setDataCreating(tmpObj)
    setValidateInputData(tmpValidateObj)
  }
  const handleChangeInputData = (e, key, index) => {
    let tmpSupplierList = supplierList
    let tmpData = tmpSupplierList[index]
    let tmpValue = e.target.value
    if (key == 'price' || key == 'shipping_cost' || key == 'discount') {
      let tmpInt = tmpValue?.replace(/\D/g, '') || 0
      tmpValue = parseInt(tmpInt)
    }
    tmpData[key] = tmpValue
    if (key == 'price') {
      let tmpPriceVat = 0
      tmpPriceVat = tmpValue * 0.07
      tmpData['price_vat'] = tmpValue + tmpPriceVat
    }
    if (tmpValue != '') {
      let tmpkey = 'is_error_' + key
      tmpData[tmpkey] = false
    }
    tmpSupplierList[index] = tmpData
    setSupplierList([...tmpSupplierList])
  }
  // const handleChangePaymentList = (e, type, index) => {
  //   let tmpObj = { ...dataCreating }
  //   if (type == 'product_name') {
  //     tmpObj.product_list[index].product_name = e.target.value
  //     if (e.target.value != '') {
  //       tmpObj.product_list[index].is_error_product_name = false
  //     }
  //     // } else if (type == 'details') {
  //     //   tmpObj.product_list[index].details = e.target.value
  //     //   if (e.target.value != '') {
  //     //     tmpObj.product_list[index].is_error_details = false
  //     //   }
  //   } else if (type == 'quantity') {
  //     let tmpString = e.target.value
  //     let tmpInt = tmpString?.replace(/\D/g, '') || 0
  //     tmpObj.product_list[index].quantity = parseInt(tmpInt)
  //     if (e.target.value) {
  //       tmpObj.product_list[index].is_error_quantity = false
  //     }
  //   } else if (type == 'price') {
  //     let tmpString = e.target.value
  //     let tmpInt = tmpString?.replace(/\D/g, '') || 0
  //     tmpObj.product_list[index].price = parseInt(tmpInt)
  //     if (e.target.value) {
  //       tmpObj.product_list[index].is_error_price = false
  //     }
  //   }
  //   console.log('tmpObj (ProductList): ' + JSON.stringify(tmpObj, null, 2))
  //   setDataCreating({ ...tmpObj })
  // }

  // const handleClickBtnDelete = (index) => {
  //   let tmpBodyDataList = removeArrayItemByIndex(dataCreating?.product_list, index)
  //   setDataCreating({ ...dataCreating, product_list: tmpBodyDataList })
  // }

  // const handleClickBtnAddList = () => {
  //   let tmpBodyDataList = [...dataCreating?.product_list, DEFAULT_BODY_ITEM_DATA]
  //   setDataCreating({ ...dataCreating, product_list: tmpBodyDataList })
  //   setValidateInputData({ ...validateInputData, is_error_product_list: false })
  // }
  const handleClickBtnPreview = () => {
    let isValid = checkInputValidation()
    if (isValid) {
      let tmpDataCreating = { ...dataCreating }
      tmpDataCreating.supplier_list = supplierList
      setDataCreating(tmpDataCreating)
      setIsShowDialogPreview(true)
      setDialogPurchaseType('render_preview')
    }
  }
  const handleClickBtnCreate = async () => {
    let isValid = checkInputValidation()
    if (isValid) {
      if (Object.keys(employee_info).length > 0) {
        let tmpObj = { ...dataCreating }
        tmpObj.creator_id = employee_info.id

        if (isEditPurchase) {
          // Edit Purchase
          let tmpList = [...supplierList]
          let tmpNewList = []
          for (let i = 0; i < tmpList.length; i++) {
            let tmpItem = tmpList[i]
            delete tmpItem['price_vat']
            delete tmpItem['is_error_company_name']
            delete tmpItem['is_error_brand']
            delete tmpItem['is_error_model']
            delete tmpItem['is_error_spec']
            delete tmpItem['is_error_warranty']
            delete tmpItem['is_error_price']
            delete tmpItem['is_error_discount']
            delete tmpItem['is_error_duration']
            delete tmpItem['is_error_payment']

            tmpNewList.push(tmpItem)
          }
          tmpObj.supplier_list = [...tmpNewList]

          delete tmpObj['id']
          delete tmpObj['doc_id']
          delete tmpObj['number_of_copy']
          delete tmpObj['company_logo']
          delete tmpObj['rubber_stamp']
          delete tmpObj['condition']
          delete tmpObj['signature_list']

          const request = { data: tmpObj }
          await dispatch(editPurchaseDoc(currentDocId, request)).then((e) => {
            if (e?.data) {
              if (navigateType == 'external') {
                if (onCallback) {
                  onCallback(e?.data?.id)
                }
              } else {
                navigate('/document/purchases')
              }
            } else {
              alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
            }
          })
        } else {
          ///Create Purchase
          let tmpList = [...supplierList]
          let tmpNewList = []
          for (let i = 0; i < tmpList.length; i++) {
            let tmpItem = tmpList[i]
            delete tmpItem['price_vat']
            delete tmpItem['is_error_company_name']
            delete tmpItem['is_error_brand']
            delete tmpItem['is_error_model']
            delete tmpItem['is_error_spec']
            delete tmpItem['is_error_warranty']
            delete tmpItem['is_error_price']
            delete tmpItem['is_error_discount']
            delete tmpItem['is_error_duration']
            delete tmpItem['is_error_payment']

            tmpNewList.push(tmpItem)
          }
          tmpObj.supplier_list = [...tmpNewList]
          delete tmpObj['id']
          delete tmpObj['doc_id']
          delete tmpObj['number_of_copy']
          delete tmpObj['company_logo']
          delete tmpObj['rubber_stamp']
          delete tmpObj['condition']
          delete tmpObj['signature_list']

          const request = { data: tmpObj }
          console.log('request_CREATE: ' + JSON.stringify(request, null, 2))
          await dispatch(createPurchase(request)).then((e) => {
            if (e?.data) {
              if (navigateType == 'external') {
                if (onCallback) {
                  onCallback(e?.data?.id)
                }
              } else {
                handleGoBack()
              }
            } else {
              alert('กรุณาตรวจสอบข้อมูลให้ถูกต้อง')
            }
          })
        }
      } else {
        setIsShowFailedModal(true)
      }
    } else {
      alert('กรอกข้อมูลให้ครบถ้วน')
    }
  }
  const checkInputValidation = () => {
    let tmpData = { ...dataCreating }
    let tmpValidateData = { ...validateInputData }
    let tmpSupplierList = supplierList
    // CHECK Valid input
    if (tmpData.budget != '') {
      tmpValidateData.is_error_budget = false
    } else {
      tmpValidateData.is_error_budget = true
    }

    if (tmpData.doc_date != '') {
      tmpValidateData.is_error_doc_date = false
    } else {
      tmpValidateData.is_error_doc_date = true
    }

    if (tmpData.conclusion != '') {
      tmpValidateData.is_error_conclusion = false
    } else {
      tmpValidateData.is_error_conclusion = true
    }

    //validate product list
    if (tmpSupplierList?.length > 0) {
      // let tmpSupplierList = [...tmpData.product_list]
      let tmpArrayList = []
      let isError = false
      for (let index = 0; index < tmpSupplierList.length; index++) {
        let itemObj = tmpSupplierList[index]
        console.log('itemObj: ' + JSON.stringify(itemObj, null, 2))

        if (itemObj.company_name == '') {
          itemObj.is_error_company_name = true
          isError = true
        } else {
          itemObj.is_error_company_name = false
        }

        if (itemObj.brand == '') {
          itemObj.is_error_brand = true
          isError = true
        } else {
          itemObj.is_error_brand = false
        }

        if (itemObj.model == '') {
          itemObj.is_error_model = true
          isError = true
        } else {
          itemObj.is_error_model = false
        }

        if (itemObj.spec == '') {
          itemObj.is_error_spec = true
          isError = true
        } else {
          itemObj.is_error_spec = false
        }

        if (itemObj.warranty == '') {
          itemObj.is_error_warranty = true
          isError = true
        } else {
          itemObj.is_error_warranty = false
        }

        if (itemObj.warranty == '') {
          itemObj.is_error_warranty = true
          isError = true
        } else {
          itemObj.is_error_warranty = false
        }

        if (itemObj?.price >= 0) {
          itemObj.is_error_price = false
        } else {
          itemObj.is_error_price = true
          isError = true
        }

        if (itemObj?.shipping_cost >= 0) {
          itemObj.is_error_shipping_cost = false
        } else {
          itemObj.is_error_shipping_cost = true
          isError = true
        }

        if (itemObj?.discount >= 0) {
          itemObj.is_error_discount = false
        } else {
          itemObj.is_error_discount = true
          isError = true
        }

        if (itemObj.duration == '') {
          itemObj.is_error_duration = true
          isError = true
        } else {
          itemObj.is_error_duration = false
        }

        if (itemObj.payment == '') {
          itemObj.is_error_payment = true
          isError = true
        } else {
          itemObj.is_error_payment = false
        }

        tmpArrayList.push(itemObj)
      }

      tmpValidateData.is_error_supplier_list = isError
      setSupplierList(tmpArrayList)
    } else {
      tmpValidateData.is_error_supplier_list = true
    }
    setDataCreating(tmpData)
    setValidateInputData(tmpValidateData)
    for (const [key, value] of Object.entries(tmpValidateData)) {
      if (value) {
        return false
      }
    }
    return true
  }
  // const convertNumberToCommaTwodecimal = (myNumber) => {
  //   const options = {
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2,
  //   }
  //   const tmpTotal = Number(myNumber).toLocaleString('en', options)
  //   return tmpTotal
  // }
  const handleGoBack = () => {
    navigate('/document/purchases')
  }

  const handleClickedOption = async (type) => {
    setDialogPurchaseType(type)

    if (type == 'preview' || type == 'print') {
      setIsShowDialogPreview(true)
    } else if (type == 'download_pdf') {
      setIsShowModalDownload(true)
    } else if (type == 'request_approve') {
      setIsShowDialogRequestApprove(true)
    } else {
      setIsShowModalShare(true)
    }
  }

  const handleClickBtnDownload = () => {
    setIsShowModalDownload(false)
    setIsShowDialogPreview(true)
  }

  const handleClickSubmitRequestApproval = () => {
    setIsShowDialogRequestApprove(false)
  }

  const handleClickedPreview = () => {
    setIsHindModalShare(true)
    setDialogPurchaseType('preview')
    setIsShowDialogPreview(true)
  }

  const handleClickClosePreview = () => {
    setIsShowDialogPreview(false)
    setIsHindModalShare(false)
  }

  // const convertIntComma = (val) => {
  //   if (val) {
  //     return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  //   }
  // }

  const convertText = (text) => {
    let textArray = text.split('_')
    let string = textArray.join(' ')
    return string
  }

  return (
    <Styled isHindModalShare={isHindModalShare}>
      <div className="wrp-header-container">
        <div className="header-text">
          <h2>
            {isEditPurchase ? 'Editing' : 'Creating'} document - {viewType.replace(/_/g, ' ')}
          </h2>
          {isEditPurchase && <p>{saleDocumentData?.doc_id}</p>}
        </div>

        {isEditPurchase && (
          <div className="header-right">
            <DocumentOptionButton onClicked={handleClickedOption} />
          </div>
        )}
      </div>

      <div className="content-container">
        <div className="body">
          <div className="body-top">
            <div className="input-right">
              <div className="wrp-input">
                <p>วันที่</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'doc_date')}
                  value={dataCreating?.doc_date}
                  error={validateInputData.is_error_doc_date}
                />
              </div>
            </div>
          </div>

          {supplierList?.length > 0 && (
            <div className="body-bottom">
              <table className="wrp-table">
                <thead className="table-header">
                  <tr>
                    {TABLE_HEADER_COLUMN?.map((item, index) => {
                      return (
                        <th
                          key={'head' + index}
                          className={item?.accessor != 'budget' ? 'wrp-item-container' : 'wrp-item-title'}
                        >
                          {item?.accessor != 'budget' ? (
                            <span className="title-header-name">{convertText(item?.accessor)}</span>
                          ) : (
                            <div className="wrp-item-budget">
                              <div>งบประมาณ: </div>
                              <div className="wrp-input-budget">
                                <TextField
                                  className={'input-table'}
                                  placeholder={'0'}
                                  onChange={(e) => handleChangeData(e, 'budget')}
                                  value={dataCreating?.budget}
                                  error={validateInputData.is_error_budget}
                                  pattern={'^[0-9.-/]+$'}
                                />
                              </div>
                            </div>
                          )}
                        </th>
                      )
                    })}
                  </tr>
                </thead>

                <tbody className="table-body">
                  {TABLE_BODY.map((row, index) => {
                    return (
                      <tr className="wrp-row-body" key={'body-' + index}>
                        <td>{row?.title}</td>
                        {supplierList.map((item, itemIndex) => {
                          return (
                            <td key={'item-' + itemIndex}>
                              <TextField
                                key={'input-' + row?.key + '-' + itemIndex}
                                className={'text-field'}
                                // pattern={'^[0-9]*[.,]?[0-9]*$'}
                                onChange={(e) => handleChangeInputData(e, row?.key, itemIndex)}
                                value={item?.[row?.key]}
                                error={item?.['is_error_' + row?.key]}
                                disabled={row?.key == 'price_vat' ? true : false}
                              />
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                  <tr>
                    <td>สรุปการเปรียบเทียบ</td>
                    <td colspan="3">
                      <TextField
                        className={'text-field'}
                        key={'input-compare'}
                        onChange={(e) => handleChangeData(e, 'conclusion')}
                        value={dataCreating?.conclusion}
                        error={validateInputData.is_error_conclusion}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      <div className="bottom">
        <ButtonOutline onClick={() => handleGoBack()}>Cancel</ButtonOutline>
        <div className="wrp-btn-right">
          <Button className="btn-preview" onClick={handleClickBtnPreview}>
            Preview
          </Button>
          <Button className="btn-create" onClick={handleClickBtnCreate}>
            {isEditPurchase ? 'Save' : 'Create'}
          </Button>
        </div>
      </div>

      {isShowDialogRequestApprove && (
        <SidePanel isOpen={isShowDialogRequestApprove} onClose={() => setIsShowDialogRequestApprove(false)} width={900}>
          <DocumentRequestApprove
            docType={viewType}
            onClickNavigationBack={() => setIsShowDialogRequestApprove(false)}
            id={saleDocumentData.id}
            onClickBtnSubmit={() => handleClickSubmitRequestApproval()}
          />
        </SidePanel>
      )}

      {/* preview and print */}
      {isShowDialogPreview && (
        <DialogDocument
          onClose={() => handleClickClosePreview()}
          docType={viewType}
          previewType={dialogPurchaseType}
          id={saleDocumentData.id}
          onDownloaded={() => setIsShowDialogPreview(false)}
          data={dataCreating}
          linkImage={dataCreating?.signature ? imageForDocument : null}
          textCondition={textPurchaseCondition}
        />
      )}

      {isShowModalDownload && (
        <ModalBox
          className={'modal-download'}
          onClosed={() => setIsShowModalDownload(false)}
          height="200px"
          width="450px"
          title={'Download ' + saleDocumentData?.doc_id}
          body={<div className="body">ใบเทียบราคาสินค้า</div>}
          footer={
            <div className="bottom-modal">
              <ButtonOutline className={'footer-btn btn-cancel'} onClick={() => setIsShowModalDownload(false)}>
                Cancel
              </ButtonOutline>
              <Button className={'footer-btn btn-download'} onClick={handleClickBtnDownload}>
                Download
              </Button>
            </div>
          }
        />
      )}

      {isShowModalShare && (
        <div className="document-share-and-send-email">
          <DocumentShareAndSendEmail
            type={currentDocType}
            data={saleDocumentData}
            onClickPreview={() => handleClickedPreview()}
            onClose={() => setIsShowModalShare(false)}
          />
        </div>
      )}

      {isShowFailedModal && (
        <DialogFail
          open={Boolean(isShowFailedModal)}
          onClose={() => setIsShowFailedModal(false)}
          onSubmit={() => setIsShowFailedModal(false)}
          icon={failIcon}
          title={'You may not fully use this feature, since you are not employee.'}
          nameItem={''}
          description={''}
          textYes="Ok"
        />
      )}
    </Styled>
  )
}

const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .mr-10 {
    margin-right: 10px !important;
  }
  .mg-0 {
    margin: 0 !important;
  }
  .wrp-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 0 20px;
    border-bottom: 1px solid #ddd;
    h2 {
      margin: 0;
      font-size: 36px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    p {
      margin: 0;
      color: var(--Gray-600);
      font-size: 16px;
    }
    .header-text {
      text-transform: capitalize;
    }
  }

  .content-container {
    padding: 20px 0;
    flex: 1;
  }

  .w-100 {
    width: 100% !important;
  }

  .w-60 {
    width: 60% !important;
  }

  .checkbox label {
    margin: 0;
  }

  .body {
    .row {
      display: flex;
      .right {
        padding-left: 24px;
      }
    }

    .wrp-input {
      margin-bottom: 24px;
      /* background-color: red; */

      p {
        margin-top: 0;
        margin-bottom: 6px;
      }
      .dropdown-select,
      .search-text {
        width: 300px;
      }
      .address {
        height: 134px;
      }
    }
    .error {
      border-color: red !important;
    }
    .body-top {
      display: flex;
      justify-content: space-between;
      /* border-bottom: 1px solid var(--Gray-200); */

      .input-left {
        .input-text-area-address {
          font-size: 14px;
          height: 134px;
          border-color: var(--Gray-300);
          border-width: 1px;
          width: 300px;
        }
      }

      .input-right {
        margin-left: auto;
      }
    }
    //////
    .body-bottom {
      padding: 24px 0;

      .input-top {
        width: fit-content;
      }
      .wrp-table-no-data {
        margin-bottom: 20px;
        > div {
          overflow-x: inherit;
        }
        thead {
          border-top: 1px solid red;
          border-left: 1px solid red;
          border-right: 1px solid red;
          tr {
            border: none;
          }
        }
        tbody {
          border-left: 1px solid red;
          border-right: 1px solid red;
          border-bottom: 1px solid red;

          tr {
            border: none;
          }
        }
      }
      //table input
      .input-table {
        width: 100%;
      }

      .btn-delete {
        cursor: pointer;
      }

      .wrp-calculate-price {
        margin-top: 31px;
        display: flex;
        justify-content: flex-end;

        .inner-calculate {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 10px;

          .row {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            font-weight: bold;

            p {
              margin: 0;
            }

            .price {
              margin-left: 100px;
            }
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--Gray-200);
    padding: 16px 24px;

    .btn-preview {
      margin-right: 12px;
    }
  }

  //Modal
  .modal-download {
    .bottom-modal {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .btn-cancel {
        width: 48%;
        justify-content: center;
      }
      .btn-download {
        width: 48%;
        justify-content: center;
      }
    }
  }

  .document-share-and-send-email {
    display: ${({ isHindModalShare }) => (isHindModalShare ? 'none' : 'block')};
  }
  .line-border {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
  }

  .table-container {
    /* border: 1px solid var(--Gray-200);
    border-radius: 20px; */
  }

  .wrp-table {
    width: 100%;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: 1px solid var(--Gray-200);
    border-spacing: 0;
    border-collapse: collapse;
    margin-bottom: 30px;
  }
  .wrp-table thead {
    background-color: var(--Gray-100);
  }
  .table-header {
    font-weight: 600;
  }
  .table-header th {
    text-align: start;
  }
  .table-header th,
  .table-body td {
    padding: 10px 5px 10px 15px;
  }
  .table-body td {
    border-bottom: 1px solid var(--Gray-200);
  }
  /* .table-header tr {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--Gray-100);
  } */
  .wrp-item-title {
    width: 300px;
  }
  .wrp-item-budget {
    display: flex;
    align-items: center;
  }
  .wrp-row-body {
    padding: 5px;
  }
  .text-field {
    width: 100%;
  }
  .title-header-name {
    text-transform: capitalize;
    text-align: start;
  }
  /* .wrp-item-container {
    flex: 1;
  }
  
  
  .wrp-input-budget {
    margin-left: 4px;
  }
  .wrp-item-container,
  .wrp-item-title {
    padding: 5px;
  }
  

  .table-body {
    border: 1px solid var(--Gray-200);
    background-color: var(--Gray-50);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
  } */
`
