import { Chip, Divider, Breadcrumbs as MuiBreadcrumbs, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { RiHome6Line } from 'react-icons/ri'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const Breadcrumbs = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const lastPart = location.pathname.split('/').pop()

  const generateBreadcrumbs = (pathname) => {
    const parts = pathname.split('/')
    const result = parts.map((part, index) => {
      const formattedLabel = part
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

      return {
        label: formattedLabel,
        href: lastPart === part ? pathname : `/${parts.slice(1, index + 1).join('/')}`,
      }
    })

    return result
  }

  useEffect(() => {
    setBreadcrumbs(generateBreadcrumbs(location?.pathname))
  }, [location])

  return (
    <MuiBreadcrumbs aria-label="custom-breadcrumbs" separator=">">
      <HomeBreadcrumb href="/">
        <RiHome6Line fontSize={'1.25rem'} />
      </HomeBreadcrumb>

      {breadcrumbs
        .filter((breadcrumb) => breadcrumb.label !== '')
        .map((breadcrumb) => (
          <StyledBreadcrumb
            key={breadcrumb.href}
            component="div"
            onClick={
              breadcrumb.href === location.pathname
                ? (event) => event.preventDefault()
                : (event) => {
                    event.preventDefault()
                    navigate(breadcrumb.href)
                  }
            }
            className={`${location.pathname === breadcrumb.href ? 'active' : 'inactive'}`}
            {...breadcrumb}
          />
        ))}
    </MuiBreadcrumbs>
  )
}

/**
 * Renders a page header component.
 *
 * @param {string|ReactElement} title - The title of the page header.
 * @param {string|ReactElement} supportingText - The supporting text of the page header.
 * @param {boolean} hiddenBorder - Whether to hide the border around the page header.
 * @param {ReactElement} extra - Extra content to be included in the page header.
 *
 * @return {ReactElement} The rendered page header component.
 */
const PageHeader = ({ title, supportingText, extra, actions, actionProps }) => {
  return (
    <HeaderWrapper>
      <Breadcrumbs />

      <div className="content-section">
        <div className="title-and-supporting-texts">
          <React.Fragment key="Title">
            {typeof title === 'string' ? <TypographyH2>{title}</TypographyH2> : title}
          </React.Fragment>

          <React.Fragment key="Supporting-Text">
            {typeof supportingText === 'string' ? (
              <TextMutedForeground>{supportingText}</TextMutedForeground>
            ) : (
              supportingText
            )}
          </React.Fragment>
        </div>

        {actions && (
          <Actions {...actionProps}>{Array.isArray(actions) ? actions.map((action) => action) : actions}</Actions>
        )}
      </div>

      {extra && <React.Fragment key="Extra">{extra}</React.Fragment>}
    </HeaderWrapper>
  )
}

export default PageHeader

const HeaderWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  /* padding: ${(props) => (props.hiddenBorder ? '1rem 0' : '2rem 1rem')}; */
  /* border: ${(props) => (props.hiddenBorder ? 'none' : '1px solid var(--Gray-200)')}; */
  border-radius: 0.5rem;
  gap: 1.25rem;

  & h2,
  p {
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    /* padding: ${(props) => (props.hiddenBorder ? '2rem 0' : '2rem')}; */
  }

  & .content-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 4rem;
    row-gap: 1rem;

    & .title-and-supporting-texts {
      display: flex;
      flex-direction: column;
      align-self: stretch;
      gap: 0.5rem;
      min-width: fit-content;
    }
  }
`

const TypographyH2 = styled('h2')`
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.375rem; /* 126.667% */

  color: var(--Gray-900);
`

const TextMutedForeground = styled('p')`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */

  color: var(--Gray-600);
`

const StyledBreadcrumb = styled(Chip)`
  font-family: 'Inter', 'Noto Sans Thai' !important;
  /* Text sm/Medium */
  font-size: 0.875rem !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 1.25rem !important; /* 142.857% */
  color: var(--Gray-600) !important;
  border-radius: 0.375rem !important;
  align-items: center;

  &.MuiChip-colorDefault {
    background: var(--Base-White);
    cursor: pointer;
  }

  &:hover,
  &:focus {
    background-color: var(--Gray-50) !important;
    text-decoration: none !important;
  }

  &.active {
    background-color: var(--Gray-100) !important;
    cursor: default;
  }
`

const HomeBreadcrumb = styled('a')`
  display: flex;
  padding: 0.375rem;
  align-items: flex-start;
  cursor: pointer;

  border-radius: 0.375rem;
  color: var(--Gray-500);

  &:hover {
    background-color: var(--Gray-50);
  }
`

const Actions = styled(Stack)`
  justify-items: end;
  align-self: stretch;
  min-width: fit-content;
  justify-self: flex-end;
`
