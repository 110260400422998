import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import CreateChequePaymentJournal from '../purchases/CreateChequePaymentJournal'
import CreateComparisonSchedule from '../purchases/CreateComparisonSchedule'
import CreatePaymentJournal from '../purchases/CreatePaymentJournal'
import CreatePettyCashVoucher from '../purchases/CreatePettyCashVoucher'
import CreatePurchaseOrder from '../purchases/CreatePurchaseOrder'
import CreatePurchaseRequest from '../purchases/CreatePurchaseRequest'
import CreateDebitNote from '../sales/CreateDebitNote'
import CreatePledgeProductCertificationRequest from '../sales/CreatePledgeProductCertificationRequest'
import CreateSaleDocument from '../sales/CreateSaleDocument'
// business-doc
import CreditAdjustmentDocument from './doc-view/CreditAdjustmentDocument'
import DeliveryAppointmentNote from './doc-view/DeliveryAppointmentNote'
import GoodsReceivedNote from './doc-view/GoodsReceivedNote'
import PaymentVoucher from './doc-view/PaymentVoucher'
import ReceiptVoucher from './doc-view/ReceiptVoucher'
import TransferRequest from './doc-view/TransferRequest'
import InternalMemo from './doc-view/InternalMemo'
import TravelExpenseClaim from './doc-view/TravelExpenseClaim'

export default function RootEditDocument() {
  const [searchParams, setSearchParams] = useSearchParams()
  const viewType = searchParams.get('type')

  return (
    <Styled>
      {/* ------------ BusinessDoc ------------- */}
      {viewType == 'goods_received_note' && <GoodsReceivedNote />}
      {viewType == 'payment_voucher' && <PaymentVoucher />}
      {viewType == 'transfer_request' && <TransferRequest />}
      {viewType == 'delivery_appointment_note' && <DeliveryAppointmentNote />}
      {viewType == 'credit_adjustment_document' && <CreditAdjustmentDocument />}
      {viewType == 'receipt_voucher' && <ReceiptVoucher />}
      {viewType == 'internal_memo' && <InternalMemo />}
      {viewType == 'travel_expense_claim' && <TravelExpenseClaim />}

      {/* ------ PURCHASE ------ */}
      {viewType == 'purchase_order' && <CreatePurchaseOrder />}
      {viewType == 'cheque_payment_journal' && <CreateChequePaymentJournal />}
      {viewType == 'payment_journal' && <CreatePaymentJournal />}
      {viewType == 'petty_cash_voucher' && <CreatePettyCashVoucher />}
      {viewType == 'purchase_request' && <CreatePurchaseRequest />}
      {viewType == 'comparison_schedule' && <CreateComparisonSchedule />}

      {/* ------------ SALES ---------------- */}
      {(viewType == 'quotation' || viewType == 'billing' || viewType == 'invoice' || viewType == 'receipt') && (
        <CreateSaleDocument />
      )}
      {viewType == 'debit_note' && <CreateDebitNote />}
      {viewType == 'pledge_product_certification_request' && <CreatePledgeProductCertificationRequest />}
    </Styled>
  )
}
const Styled = styled.div`
  /* height: 100%; */
`
