import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createMeetingRoom } from '../../redux/actions/dailysummary'
import {
  getCompanyInfo,
  DateTimeHHmmDisplay,
  getAdminUser,
  getEmployeeInfo,
  numberWithCommas,
} from '../../utils/common'

import { getReportDetailReturnData } from '../../redux/actions/ofm'
import { getEmployeeById } from '../../redux/actions/master'

import dayjs from 'dayjs'

import TextField from '../../components/form/TextField'
import InputTextArea from '../../components/widget/element/InputTextArea'
import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconBin from '../../assets/images/icon-bin.svg'
import iconFile from '../../assets/images/icon-file-round.svg'

import FileUpload from '../../components/form/FileUpload'
import { postAPI } from '../../utils/api'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import mapIcon from '../../assets/images/gg_map.png'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css {
      color: #7f56d9;
      font-weight: bold;
    }
  }

  .content_header {
    padding: 14px 14px;

    width: ${(props) => props.width}px;
    position: fixed;
    background: #ffffff;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    /*
    padding: 0px 14px;
    padding-top: 130px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    background-color: #F9FAFB;
    height: 100vh;
    */
    padding: 0px 14px;
    padding-top: 150px;
    padding-bottom: 170px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    background-color: #f9fafb;
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill {
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .content {
    margin: 10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }

  .prefix_icon {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .report_box_form {
    border: 2px solid rgb(208, 213, 221);
    border-radius: 8px;
    padding: 16px 24px;
    margin-bottom: 20px;
    background-color: #fcfcfd;
  }
  .report_formname {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
    /*margin-bottom:16px;*/
  }
  .report_sub_value {
    display: grid;
    /*grid-template-columns: repeat(2, 35% [col-start] 65% [col-end]);*/
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .report_sub_value_title {
    /*
    font-size: 16px;
    color: #101828;
    */
    font-size: 16px;
    color: #101828;
    font-weight: 500;
  }
  .report_sub_value_value {
    color: #475467;
    font-size: 16px;
    font-weight: 400;
  }
  .report_sub_value_unit {
    color: #475467;
    font-size: 16px;
    font-weight: 400;
  }
  .report_img {
    display: grid;
    grid-template-columns: repeat(5, 120px);
    gap: 20px;
    margin-bottom: 16px;
  }

  .cursor_pointer {
    cursor: pointer;
  }
  .img_in_row {
    width: 120px;
    border-radius: 12px;
  }

  /* ST CSS POPUP IMG */
  .dialog-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  /* Style for the dialog content */
  .dialog-img-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    position: relative;
    max-width: 1200px;
    padding: 0 15px;
  }
  /* Style for the close icon */
  .dialog-img-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0;
  }

  .dialog-img-popup-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  /* ED CSS POPUP IMG */
  .dialog-footer {
    /*margin-bottom: 15px;*/
    /*text-align: end;*/
    position: fixed;
    right: 0;
    bottom: 0;
    width: ${(props) => props.width}px;
    background-color: #ffffff;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .dialog-footer-report {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .dialog-footer-report-info {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .img-profile {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  .user_img_char_1 {
    border-radius: 50%;
    background-color: var(--Main-Button-Bg);
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #ffffff;
    cursor: pointer;
  }
  .dialog-footer-report-info-full-name {
    font-size: 18px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .dialog-footer-report-info-position {
    font-size: 16px;
    font-weight: 400;
    color: var(--Primary-700);
  }
  .dialog-footer-report-info-location {
    font-size: 16px;
    font-weight: 500;
    color: var(--Gray-600);
  }

  /* ST CSS TABLE */
  .div_table {
    padding: 10px 0px;
  }
  .resp-table {
    width: 100%;
    display: table;
  }
  .resp-table-body {
    display: table-row-group;
  }
  .resp-table-row {
    display: table-row;
  }
  .table-body-head_cell {
    display: table-cell;
    border: 1px solid #d0d5dd;
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    text-align: center;
    font-weight: 600;
    background-color: #f2f4f7;
    color: #475467;
    font-size: 12px;
  }
  .table-body-cell {
    display: table-cell;
    border: 1px solid #dddddd;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: left;
    font-size: 12px;
  }
  .width_title {
    width: 60%;
    white-space: pre-wrap;
    line-break: anywhere;
  }
  .width_value {
    width: 30%;
    white-space: pre-wrap;
    line-break: anywhere;
  }
  .width_value_2 {
    width: 40%;
    white-space: pre-wrap;
    line-break: anywhere;
  }
  .width_unit {
    width: 10%;
    white-space: pre-wrap;
    line-break: anywhere;
  }
  /* ED CSS TABLE */
  .css_value_warning {
    color: red;
  }
  .cccc {
    clear: both;
  }
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}

const ModalViewTaskDetail = ({
  onClose,
  onSubmit,
  ReportId,
  ReportName,
  ReportInfoFooter,
  isEdit = true,
  ...props
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = getAdminUser()
  const employee_info = getEmployeeInfo()
  const CompanyInfo = getCompanyInfo()
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const [HeaderReport, setHeaderReport] = useState(ReportName)
  const [HeaderDateReport, setHeaderDateReport] = useState('')
  const [latitude, setlatitude] = useState('')
  const [longitude, setlongitude] = useState('')
  /*
  const [defaultProps, setdefaultProps] = useState({
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  });
  */

  const [ListForm, setListForm] = useState([])
  const [ReportByInfo, setReportByInfo] = useState({
    photo: null,
    first_name: '-',
    last_name: '-',
    department: '',
    position: '',
  })
  const fetchDataAll = useCallback(
    async (filter, this_ReportId) => {
      if (this_ReportId) {
        dispatch(getReportDetailReturnData(this_ReportId, filter)).then(({ type, data_info, data_meta }) => {
          if (type.endsWith('_SUCCESS')) {
            if (data_info) {
              //
              setHeaderDateReport(DateTimeHHmmDisplay(data_info.createdAt))
              setlatitude(data_info.latitude)
              setlongitude(data_info.longitude)

              const newTHData = data_info.documents?.map((v, index) => {
                const all_properties = {
                  MachineId: {
                    title: 'MachineId',
                  },
                  formname: {
                    title: 'formname',
                  },
                  ...v.form.json_schema.properties,
                  operator_name: {
                    title: 'พนักงานปฏิบัติงาน',
                  },
                  note: {
                    title: 'หมายเหตุ',
                  },
                  images: {
                    title: 'images',
                  },
                }

                return Object.keys(all_properties).map((key) => {
                  if (key == 'MachineId') {
                    const this_Machine_name = v.form.machine ? v.form.machine['name'] : ''
                    return {
                      title: 'MachineId',
                      description: null,
                      unit: null,
                      value: this_Machine_name,
                    }
                  } else if (key == 'formname') {
                    const this_form_name = v.form.json_schema['title'] ? v.form.json_schema['title'] : '-'
                    return {
                      title: 'formname',
                      description: null,
                      unit: null,
                      value: this_form_name,
                    }
                  } else {
                    const type_data = all_properties[key]?.type ? all_properties[key]?.type : 'String'
                    const minimum = all_properties[key]?.minimum ? all_properties[key]?.minimum : null
                    const maximum = all_properties[key]?.maximum ? all_properties[key]?.maximum : null
                    const real_value = v.value[`${key}`]
                    let this_value = ''
                    if (type_data == 'integer' || type_data == 'number') {
                      if (minimum && maximum) {
                        if (
                          parseFloat(real_value) < parseFloat(minimum) ||
                          parseFloat(real_value) > parseFloat(maximum)
                        ) {
                          this_value = <div className="css_value_warning">{numberWithCommas(real_value)}</div>
                        } else {
                          this_value = numberWithCommas(real_value)
                        }
                      } else {
                        this_value = numberWithCommas(real_value)
                      }
                    } else {
                      this_value = real_value
                    }

                    return {
                      title: all_properties[key].title,
                      description: all_properties[key].description,
                      unit: all_properties[key].unit,
                      value: this_value,
                    }
                  }
                })
              })
              //
              //

              let list_all_form = []
              for (const item_newTHData of newTHData) {
                //
                //
                list_all_form.push(item_newTHData)
              }

              console.table(list_all_form)
              setListForm(list_all_form)

              dispatch(getEmployeeById(data_info.created_by_employee_id)).then(({ type, data_info }) => {
                if (type.endsWith('_SUCCESS')) {
                  setReportByInfo({
                    photo: data_info.photo_images[0]?.url ? data_info.photo_images[0].url : null,
                    first_name: data_info.first_name,
                    last_name: data_info.last_name,
                    department: data_info.department?.data?.attributes?.name
                      ? data_info.department?.data?.attributes?.name
                      : null,
                    position: data_info.position?.data?.attributes?.name
                      ? data_info.position?.data?.attributes?.name
                      : null,
                  })
                }
              })
            }
          }
        })
      }
    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 1,
      limit: 1000,
    }
    fetchDataAll(filter, ReportId)
  }, [fetchDataAll, ReportId])

  const [dialog, setDialog] = useState(false)
  const [imageSrc, setImageSrc] = useState()
  const toggleDialog = (url_img) => {
    //alert(url_img);
    setImageSrc(url_img)
    setDialog(true)
  }

  const ClosetoggleDialog = () => {
    setDialog(false)
  }

  const GotoEdit = () => {
    alert('GoTOEDIT')
  }

  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">{HeaderReport}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">
          <div>
            <div>{HeaderDateReport}</div>
          </div>
          {isEdit && (
            <div>
              <Button onClick={onSubmit}>{t('Edit')}</Button>
            </div>
          )}
        </div>
        <hr />
      </div>

      <div className="dialog-content">
        {ListForm?.map((item, index) => (
          <div key={index} className="report_box_form">
            {item
              .filter((v) => v.title == 'MachineId')
              .map((item_sub, index_sub_MachineId) => (
                <div key={index_sub_MachineId} className="report_formname">
                  Machine Id : {item_sub.value}
                </div>
              ))}
            {item
              .filter((v) => v.title == 'formname')
              .map((item_sub, index_sub_formname) => (
                <div key={index_sub_formname} className="report_formname">
                  Form : {item_sub.value}
                </div>
              ))}
            <div className="div_table">
              <div className="resp-table">
                {/* จุด Loop */}
                {item
                  .filter(
                    (v) =>
                      v.title != 'MachineId' &&
                      v.title != 'formname' &&
                      v.title != 'images' &&
                      v.title != 'พนักงานปฏิบัติงาน' &&
                      v.title != 'หมายเหตุ'
                  )
                  .map((item_sub, index_sub_title) => (
                    <div className="resp-table-body" key={index_sub_title}>
                      <div className={`table-body-cell width_title`}>
                        <div className="report_sub_value_title">{item_sub.title}</div>
                        {item_sub.description && <div>{item_sub.description}</div>}
                      </div>

                      <div className={`table-body-cell width_value report_sub_value_value`}>{item_sub.value}</div>
                      {item_sub.title != 'พนักงานปฏิบัติงาน' && item_sub.title != 'หมายเหตุ' && (
                        <div className={`table-body-cell width_unit report_sub_value_unit`}>
                          {item_sub.unit != null ? item_sub.unit : ''}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              <div className="resp-table">
                {item
                  .filter((v) => v.title == 'พนักงานปฏิบัติงาน' || v.title == 'หมายเหตุ')
                  .map((item_sub, index_sub_title) => (
                    <div className="resp-table-body" key={index_sub_title}>
                      <div className={`table-body-cell width_title`}>
                        <div className="report_sub_value_title">{item_sub.title}</div>
                        {item_sub.description && <div>{item_sub.description}</div>}
                      </div>

                      <div className={`table-body-cell width_value_2 report_sub_value_value`}>{item_sub.value}</div>
                    </div>
                  ))}
              </div>
            </div>
            {/*
                    <div className="report_sub_value">
                      <div>
                          {item.filter((v) => v.title != 'MachineId' && v.title != 'formname' && v.title != 'images').map((item_sub, index_sub_title) => (
                            <div key={index_sub_title} className="report_sub_value_title">{item_sub.title}</div>
                          ))}  
                      </div>
                      <div>
                          {item.filter((v) => v.title != 'MachineId' && v.title != 'formname' && v.title != 'images').map((item_sub, index_sub_value) => (
                            <div key={index_sub_value} className="report_sub_value_value">: {item_sub.value}</div>
                          ))} 
                      </div>
                    </div>
                  */}

            {item
              .filter((v) => v.title == 'images')
              .map((item_sub, index_sub_images) => (
                <div key={index_sub_images} className="report_img">
                  {item_sub.value?.map((item_img, index_img) => (
                    <div key={index_img}>
                      <div className="cursor_pointer" onClick={() => toggleDialog(item_img)}>
                        <img className="img_in_row" src={item_img} />
                      </div>
                    </div>
                  ))}
                </div>
              ))}

            {/*
                   {item?.map((item_sub, index_sub) => (
                    <div  key={index_sub}>
                        {item_sub.title == 'formname' && (
                            <div className="report_formname">{item_sub.value}</div>
                        )}
                        {item_sub.title == 'images' && (
                            <div className="report_img">
                                {item_sub.value?.map((item_img, index_img) => (
                                    <div  key={index_img}>
                                        <div className='cursor_pointer' onClick={() => toggleDialog(item_img)}>
                                            <img className='img_in_row'  src={item_img} />
                                        </div>
                                    </div>
                                ))}    
                            </div>
                        )}
                        {item_sub.title != 'formname' && item_sub.title != 'images' && (
                            <div className="report_sub_value">
                                <div className="report_sub_value_title">{item_sub.title}</div>
                                <div className="report_sub_value_value">: {item_sub.value}</div>
                            </div>
                            
                        )}
                    </div>
                ))} 
                  */}
          </div>
        ))}
      </div>

      <div className="dialog-footer">
        <hr />
        <div className="dialog-footer-report">รายงานโดย</div>
        <div className="dialog-footer-report-info">
          <div>
            {ReportByInfo?.photo ? (
              <img className="img-profile icon" src={ReportByInfo.photo} />
            ) : (
              <div className="user_img_char_1">
                <h3>{ReportByInfo.first_name ? ReportByInfo.first_name.substring(0, 1) : 'A'}</h3>
              </div>
            )}
          </div>
          <div>
            <div className="dialog-footer-report-info-full-name">
              {ReportByInfo.first_name} {ReportByInfo.last_name}
            </div>
            <div className="dialog-footer-report-info-position">
              {ReportByInfo?.department ? ReportByInfo?.department : '-'},{' '}
              {ReportByInfo?.position ? ReportByInfo?.position : '-'}
            </div>
            <div className="dialog-footer-report-info-location">{ReportInfoFooter}</div>
          </div>
        </div>
      </div>

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />

      {dialog && (
        <div className="dialog-img">
          <div className="dialog-img-content">
            <h3>OFM</h3>
            <button className="dialog-img-close-icon" onClick={ClosetoggleDialog}>
              &#10005;
            </button>
            <img className="dialog-img-popup-image" src={imageSrc} alt="Popup Image" />
          </div>
        </div>
      )}
    </Div>
  )
}

export default ModalViewTaskDetail
