import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import Button from '../../components/form/button/Button'
import { verifyAccountGetUserInfo } from '../../redux/actions/resetpassword'
import { selectFormPassword } from '../../redux/selectors'

import logo from '../../../src/assets/images/logo-icon.png'
import twitterIcon from '../../../src/assets/images/twitter-icon.png'
import facebookIcon from '../../../src/assets/images/facebook-icon.png'
import igIcon from '../../../src/assets/images/ig-icon.png'
import setPasswordBanner from '../../../src/assets/images/set-pass-banner.png'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  .mr-1-rem {
    margin-right: 1rem;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .mb-4-rem {
    margin-bottom: 4rem;
  }

  .t-align-center {
    text-align: center;
  }

  a {
    cursor: pointer;
  }

  .icon {
    width: 2rem;
    height: auto;
    cursor: pointer;
  }

  .container {
    padding: 5rem 20rem;
  }

  .d-flex {
    display: flex;
  }

  .jt-center {
    justify-content: center;
  }

  .jt-between {
    justify-content: space-between;
  }

  .al-center {
    align-items: center;
  }

  .header {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .logo {
    width: 3rem;
    height: auto;
  }

  .user-name {
    /* text-align: left; */
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .submit-button {
    padding: 10px 20px; /* Adjust the padding as needed */
  }

  .headline {
    color: var(--Gray-900);
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }

  .divider {
    margin: 5rem 0;
    border: 1px solid var(--Gray-200, #eaecf0);
  }

  .link {
    text-decoration: underline;
    cursor: pointer;
  }
`

const GetStartPassword = () => {
  const { t } = useTranslation()
  const { token } = useParams()
  const dispatch = useDispatch()

  const { userInfo } = useSelector(selectFormPassword)

  useEffect(() => {
    let params = {
      registrationToken: token,
    }
    dispatch(verifyAccountGetUserInfo(params))
  }, [dispatch, token])

  const navigate = useNavigate()
  const resetPassword = () => {
    navigate('/set-password', {
      replace: false,
    })
  }

  return (
    <Div>
      <div className="container">
        <div className="header mb-2-rem">
          <img className="logo mr-1-rem" src={logo} />
          <span className="title">Untitle UI</span>
        </div>
        <div className="headline mb-2-rem">
          <img src={setPasswordBanner} />
        </div>
        <div className="headline mb-1-rem">{t('welcome_to_erp_system')}!</div>
        <div className="mb-2-rem">
          Hi {userInfo.firstname} {userInfo.lastname}, Your account is now linked with: <a>{userInfo.email}</a> Please
          set your password by click at the set password button. Once you set your password. Use your password to login
          into the ERP system.
        </div>
        <div className="headline mb-2-rem">
          <Button type="button" onClick={resetPassword}>
            {t('set_password')}
          </Button>
        </div>

        <div className="divider mt-4-rem mb-4-rem"></div>

        <div className="mb-4-rem">
          You’re receiving this email because you subscribed to receive marketing emails. If you’d prefer to not receive
          these emails, please <a>unsubscribe.</a>
        </div>

        <div className="d-flex jt-between al-center mb-4-rem">
          <div className="link">Terms</div>
          <div className="link">Privacy</div>
          <div className="link">Cookies</div>
          <div className="link">Contact us</div>
        </div>

        <div className="t-align-center mb-1-rem">© 2077 Untitled UI</div>
        <div className="t-align-center mb-1-rem">100 Smith Street, Melbourne VIC 3000</div>
      </div>
    </Div>
  )
}

export default GetStartPassword
