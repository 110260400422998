import { zodResolver } from '@hookform/resolvers/zod'
import { Stack } from '@mui/material'
import { styled } from '@mui/system'
import { useCallback, useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import Button from 'components/common/Button'
import { Form } from 'components/feature/operation-process/default-cards'
import NoteItem from 'components/feature/operation-process/default-cards/note-item'
import { Textarea } from 'components/form2/form-textarea'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { initPagination } from 'constants/pagination'

const fieldsSchema = z.object({
  sp: z.object({
    id: z.number(),
  }),
  message: z.string().min(1, 'Message is required'),
})

const NoteTab = ({ spId = 0 }) => {
  const { t } = useTranslation()
  const defaultValues = {
    sp: { id: spId },
    message: '',
  }

  const noteStreamContainerRef = useRef(null)
  const form = useForm({
    defaultValues,
    resolver: zodResolver(fieldsSchema),
  })
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)
  const { extra: extraActions } = opActions()
  const { getExtra, postExtra } = extraActions()
  const { notes, notes_pagination } = opProcSelector

  const isShowMore = notes_pagination?.pageSize < notes_pagination?.total

  form.register('sp.id')

  const fetchNotes = useCallback(
    async (payload) => {
      dispatch(getExtra().notes(spId, payload))
    },
    [dispatch]
  )

  const handleShowMoreNotes = () => {
    const { page, pageSize } = notes_pagination
    fetchNotes({ pagination: { page: initPagination.page, pageSize: pageSize + pageSize } })
  }

  const onSubmit = (data) => {
    form.setValue('message', '')

    dispatch(postExtra().note(spId, data)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        fetchNotes({ pagination: initPagination })
        return
      }
    })
  }

  useEffect(() => {
    fetchNotes({ pagination: initPagination })
  }, [fetchNotes])

  useEffect(() => {
    // console.log('form.formState.errors', form.formState.errors)
  }, [form.formState.errors])

  useEffect(() => {
    const { current } = noteStreamContainerRef

    if (current) {
      if (notes_pagination?.pageSize === initPagination.pageSize) {
        current.scrollTop = current.scrollHeight
      }
    }
  }, [notes])

  return (
    <Stack
      sx={{
        height: '100%',
        padding: '0 1.25rem 1rem 1.25rem',
        paddingBottom: '1rem',
      }}
    >
      <NoteStreamContainer key="note-stream_section" ref={noteStreamContainerRef}>
        {isShowMore && (
          <span className="showmore_button" style={{ marginTop: 'auto', order: 9999 }} onClick={handleShowMoreNotes}>
            {t('show_more')}
          </span>
        )}
        {notes?.map((note) => (
          <NoteItem key={note.id} data={note} />
        ))}
      </NoteStreamContainer>

      <Form onSubmit={form.handleSubmit(onSubmit)}>
        <CommentWrapper key="comment_section">
          <Controller
            name="message"
            control={form.control}
            render={({ field }) => {
              return (
                <Textarea
                  {...field}
                  placeholder={`${t('type_something')}...`}
                  multiline
                  sx={{ height: '180px', maxHeight: '180px', '& textarea': { resize: 'none', borderRadius: '0.5rem' } }}
                />
              )
            }}
          />

          <Button
            type="submit"
            variant="contained"
            onClick={() => {}}
            sx={{
              position: 'absolute',
              bottom: '0.5rem',
              right: '0.75rem',
            }}
          >
            {t('send')}
          </Button>
        </CommentWrapper>
      </Form>
    </Stack>
  )
}

export default NoteTab

const NoteStreamContainer = styled(Stack)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  overflow-y: overlay;
  overflow-x: hidden;
  flex-grow: 1;
  flex-direction: column-reverse;
  height: auto;

  // Webkit scrollbar - style #2
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b3b3b3;
  }

  & .showmore_button {
    cursor: pointer;
    color: var(--Blue-500, #2e90fa);
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    transition: all 0.3s ease;

    &:hover {
      background-color: var(--Gray-100);
      color: var(--Blue-700, #175cd3);
    }
  }
`

const CommentWrapper = styled(Stack)`
  position: relative;
  width: 100%;
`
