import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import Card from '../../common/Card'

import { PiWarningCircleBold } from 'react-icons/pi'

const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-center {
    align-items: center;
  }

  .w-30 {
    width: 33%;
  }
`
const TaskContent = styled.div`
  .task-heading-field {
    color: var(--Gray-900);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
  .task-status {
    color: var(--Gray-900);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
  }
  .task-unit {
    color: var(--Gray-500);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .status-icon {
    width: 30px;
    height: 30px;
  }
  .warning-icon {
    cursor: pointer;
    color: var(--warning-600);
  }
  .text-under-pie-chart {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .text-value {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    .bold {
      color: var(--Gray-900);
      font-size: 18px;
      font-weight: 600;
    }
    .green {
      color: var(--success-600);
      font-size: 18px;
      font-weight: 600;
    }
    .red {
      color: var(--Error-700);
      font-size: 18px;
      font-weight: 600;
    }
  }
`

function CardTaskData5({ taskData, ...props }) {
  return (
    <Card {...props}>
      <TaskContent>
        <FlexContainer className="justify-content-space-between align-items-center mb-1-rem">
          <h3 className="task-heading-field">{taskData.title}</h3>
          <PiWarningCircleBold className="warning-icon" size={20} />
        </FlexContainer>

        <FlexContainer className="justify-content-space-between align-items-center">
          <div className="task-status">{taskData.value}</div>
          <div className="task-unit">{taskData.unit}</div>
        </FlexContainer>
      </TaskContent>
    </Card>
  )
}

export default CardTaskData5
