import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider } from 'react-hook-form'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import Dropdown from '../../components/form/Dropdown'
import TextField from '../../components/form/TextField'
import MediaFile from '../../containers/cms/MediaFile'

import { getMaterialCategory, getMaterialType, getSuppliers, createMaterial } from '../../redux/actions/material'
import { selectMaterial } from '../../redux/selectors'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .content-title {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
    margin-bottom: 24px;
  }

  .input-container {
    max-width: 1000px;

    .input {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }

    .input-file {
      display: grid;
      grid-template-columns: 15rem 30rem;
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }

    .input-header {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  .divider {
    margin: 2rem 0;
    border: 0.1rem solid #eaecf0;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    button {
      margin-left: 12px;
    }
  }
`

const initUom = [
  {
    text: 'Pieces (pcs)',
    value: 'Pieces (pcs)',
  },
  {
    text: 'Meters (m)',
    value: 'Meters (m)',
  },
  {
    text: 'Kilograms (kg)',
    value: 'Kilograms (kg)',
  },
  {
    text: 'Liters (L)',
    value: 'Liters (L)',
  },
  {
    text: 'Square Meters (sqm)',
    value: 'Square Meters (sqm)',
  },
]

const initMaterialForm = {
  material_name: '',
  UoM: '',
  price_per_unit: '',
  supplier: '',
  material_category: '',
  material_type: '',
  attach_image: [33, 35],
}

const allowTypes = ['images']

const CreateMaterial = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { materialCategory, materialType, suppliers } = useSelector(selectMaterial)
  const [materialForm, setMaterialForm] = useState(initMaterialForm)
  const [files, setFiles] = useState([])
  // dropdown list
  const [categoryList, setCategoryList] = useState([])
  const [typeList, setTypeList] = useState([])
  const [supplierList, setSupplierList] = useState([])
  const [uomList] = useState(initUom)
  // selected
  const [categorySelected, setCategorySelected] = useState('')
  const [typeSelected, setTypeSelected] = useState('')
  const [supplierSelected, setSupplierSelected] = useState('')
  const [uomSelected, setUomSelected] = useState('')

  useEffect(() => {
    dispatch(getMaterialCategory())
    dispatch(getMaterialType())
    dispatch(getSuppliers())
  }, [dispatch])

  useEffect(() => {
    setMaterialForm((prev) => ({ ...prev, material_category: categorySelected }))
  }, [categorySelected])
  useEffect(() => {
    setMaterialForm((prev) => ({ ...prev, material_type: typeSelected }))
  }, [typeSelected])
  useEffect(() => {
    setMaterialForm((prev) => ({ ...prev, supplier: supplierSelected }))
  }, [supplierSelected])
  useEffect(() => {
    setMaterialForm((prev) => ({ ...prev, UoM: uomSelected }))
  }, [uomSelected])

  useEffect(() => {
    const newMaterialCategories = materialCategory.map((v) => ({
      text: v.attributes.name,
      value: v.id,
    }))
    setCategoryList([...newMaterialCategories])
  }, [materialCategory])

  useEffect(() => {
    const newMaterialTypes = materialType.map((v) => ({
      text: v.attributes.name,
      value: v.id,
    }))
    setTypeList([...newMaterialTypes])
  }, [materialType])

  useEffect(() => {
    const newSuppliers = suppliers.map((v) => ({
      text: v.attributes.supplier_name,
      value: v.id,
    }))
    setSupplierList([...newSuppliers])
  }, [suppliers])

  const toArray = (file) => {
    if (Array.isArray(file)) {
      return file
    } else {
      return file ? [file] : []
    }
  }

  const onChangeUploadFile = (value) => {
    setFiles(value)
  }

  const onChangeMaterialForm = (event) => {
    const { name, value } = event.target
    setMaterialForm((prevState) => ({
      ...prevState,
      [name]:
        name == 'time_deliver' || name == 'replenishment_min' || name == 'replenishment_max' || name == 'price_per_unit'
          ? value.replace(/[^0-9]/g, '')
          : value,
    }))
  }

  const onSubmit = () => {
    const finalform = {
      ...materialForm,
      material_category: parseInt(materialForm['material_category']),
      material_type: parseInt(materialForm['material_type']),
      price_per_unit: parseInt(materialForm['price_per_unit']),
      supplier: parseInt(materialForm['supplier']),
      attach_image: files.map((v) => v.id),
    }

    const request = {
      data: finalform,
    }
    dispatch(createMaterial(request))
    navigate('/inventory-management/materials', {
      replace: true,
    })
  }

  return (
    <Div>
      <div className="content-title">{t('register_new_material')}</div>
      <div className="input-container">
        <FormProvider>
          <div className="input">
            <div className="input-header">{t('material_name')}</div>
            <TextField name="material_name" value={materialForm.material_name} onChange={onChangeMaterialForm} />
          </div>
          <div className="divider"></div>
          <div className="input">
            <div className="input-header">{t('supplier')}</div>
            <Dropdown
              value={supplierSelected}
              optionList={supplierList}
              onChange={(value) => setSupplierSelected(value)}
            />
          </div>
          <div className="divider"></div>
          <div className="input">
            <div className="input-header">{t('category')}</div>
            <Dropdown
              value={categorySelected}
              optionList={categoryList}
              onChange={(value) => setCategorySelected(value)}
            />
          </div>
          <div className="divider"></div>
          <div className="input">
            <div className="input-header">{t('type')}</div>
            <Dropdown value={typeSelected} optionList={typeList} onChange={(value) => setTypeSelected(value)} />
          </div>
          <div className="divider"></div>
          <div className="input">
            <div className="input-header">{t('uom')}</div>
            <Dropdown value={uomSelected} optionList={uomList} onChange={(value) => setUomSelected(value)} />
          </div>
          <div className="divider"></div>

          <div className="input">
            <div className="input-header">{t('price_per_unit')}</div>
            <TextField name="price_per_unit" value={materialForm.price_per_unit} onChange={onChangeMaterialForm} />
          </div>
          <div className="divider"></div>
          <div className="input-file">
            <div className="input-header">{t('upload_meterial_picture')}</div>
            <MediaFile multiple={true} allowedTypes={allowTypes} file={toArray(files)} onChange={onChangeUploadFile} />
          </div>
          <div className="divider"></div>
          <div className="button-wrapper">
            <ButtonOutline
              onClick={() =>
                navigate('/inventory-management/materials', {
                  replace: true,
                })
              }
            >
              {t('cancel')}
            </ButtonOutline>
            <Button onClick={onSubmit}>{t('create')}</Button>
          </div>
        </FormProvider>
      </div>
    </Div>
  )
}

export default CreateMaterial
