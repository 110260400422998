import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION,
  GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT,
  GET_EMPLOYEE_LIST_ANALYSIS,
  GET_ANALYSIS_EMPLOYEE_INFO,
} from '../actionTypes'
import { showLoading, hideLoading } from '../actions/loading'
import dayjs from 'dayjs'

export const getAnalysisEmployeeByOrganization =
  (filter = { period_type: 'last30day' }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION.REQUEST })

      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-type': 'application/json',
      }

      const currentDate = dayjs()
      const formattedDate = currentDate.format('YYYY-MM-DD')
      const params = {
        'filters[period_type]': filter.period_type,
        'filters[date]': formattedDate,
      }

      const response = await getAPI({
        url: `/api/employee-activity-byOrganization/${company_info?.id}`,
        headers,
        params,
      })

      return dispatch({
        type: GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION.SUCCESS,
        data: response,
      })
    } catch (error) {
      return dispatch({
        type: GET_ANALYSIS_EMPLOYEE_BY_ORGANIZATION.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Analysis employee by organization failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getAnalysisEmployeeByDepartment = (filter) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-type': 'application/json',
    }

    const currentDate = dayjs()
    const formattedDate = currentDate.format('YYYY-MM-DD')
    const params = {
      'filters[period_type]': filter.period_type,
      'filters[date]': formattedDate,
    }

    const response = await getAPI({
      url: `/api/employee-activity-byDepartment/${filter?.id}`,
      headers,
      params,
    })

    return dispatch({
      type: GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT.SUCCESS,
      data: response,
    })
  } catch (error) {
    return dispatch({
      type: GET_ANALYSIS_EMPLOYEE_BY_DEPARTMENT.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Analysis employee by department failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getEmployeeList =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_EMPLOYEE_LIST_ANALYSIS.REQUEST })

      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-type': 'application/json',
      }

      const params = {
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
      }
      if (filter.name !== '') params['filters[name]'] = filter.name
      if (filter.period_type !== '') params['filters[period_type]'] = filter.period_type
      if (filter.department !== '') params['filters[department]'] = filter.department
      if (filter.position !== '') params['filters[position]'] = filter.position
      if (filter.employee_type !== '') params['filters[employee_type]'] = filter.employee_type
      if (filter.evaluation !== '') params['filters[evaluation]'] = filter.evaluation

      const { employee_activity_summary, pagination } = await getAPI({
        url: `/api/employee-activity-byDepartmentEmployee`,
        headers,
        params,
      })

      return dispatch({
        type: GET_EMPLOYEE_LIST_ANALYSIS.SUCCESS,
        data: employee_activity_summary,
        meta: pagination,
      })
    } catch (error) {
      return dispatch({
        type: GET_EMPLOYEE_LIST_ANALYSIS.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get employee list analysis failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getAnalysisEmployeeInfo = (filter) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ANALYSIS_EMPLOYEE_INFO.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-type': 'application/json',
    }

    const currentDate = dayjs()
    const formattedDate = currentDate.format('YYYY-MM-DD')
    const params = {
      'filters[period_type]': filter.period_type,
      'filters[date]': formattedDate,
    }

    const data = await getAPI({
      url: `/api/employee-activity-employeeInfo/${filter.id}`,
      headers,
      params,
    })

    return dispatch({
      type: GET_ANALYSIS_EMPLOYEE_INFO.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_ANALYSIS_EMPLOYEE_INFO.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Analysis employee info failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}
