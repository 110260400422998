import { combineReducers } from 'redux'
import {
  GET_NOTIFICATIONS_LIST_ALL,
  GET_NOTIFICATIONS_READE_ALL,
  GET_NOTIFICATIONS_UNREADE_ALL,
  UPDATE_NOTIFICATIONS,
  SET_IS_REFRESH_NOTI,
} from '../actionTypes'

const initialState = {
  notificationList: [],
  notificationReade: [],
  notificationUnReade: [],
  notificationUnReadTotal: 0,
  meta: {},
  isRefreshNotification: false,
  isLoading: false,
}

const notificationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_LIST_ALL.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_NOTIFICATIONS_LIST_ALL.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        notificationList: data,
        meta,
      }
    }
    case GET_NOTIFICATIONS_LIST_ALL.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_NOTIFICATIONS_READE_ALL.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_NOTIFICATIONS_READE_ALL.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        notificationReade: data,
      }
    }
    case GET_NOTIFICATIONS_READE_ALL.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_NOTIFICATIONS_UNREADE_ALL.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_NOTIFICATIONS_UNREADE_ALL.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        notificationUnReade: data,
        notificationUnReadTotal: meta.pagination.total, // จำนวนยังไม่ได้อ่านทั้งหมด
      }
    }
    case GET_NOTIFICATIONS_UNREADE_ALL.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_NOTIFICATIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_NOTIFICATIONS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_NOTIFICATIONS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case SET_IS_REFRESH_NOTI: {
      const { data } = action
      return {
        ...state,
        isRefreshNotification: data,
      }
    }
    default:
      return state
  }
}

export default combineReducers({
  notificationsReducer,
})
