import React, { useEffect, useState, useRef } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { NumberFormatter } from 'react-number-formatter'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import MediaFile from '../../containers/cms/MediaFile'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import DialogFail from '../../components/dialog/DialogFail'
import failIcon from '../../assets/images/fail-icon.png'

import iconDelete from '../../assets/images/icon-bin-red.svg'

import { verifyApplicationFormToken, createEmployeeWithApplicationForm } from '../../redux/actions/applicationForm'
import {
  getCountries,
  getBanks,
  getNationalities,
  getDepartmentsAndPositions,
  getDepartmentsAndPositionsByCompany,
  getProvinces,
  getDistricts,
  getSubDistricts,
} from '../../redux/actions/master'
import { selectApplicationForm, selectMaster } from '../../redux/selectors'
import { useTranslation } from 'react-i18next'
const Div = styled.div`
  padding: 6rem 15rem;
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .align-items-center {
    align-items: center;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-2rem {
    font-size: 1.5rem;
  }

  .icon {
    cursor: pointer;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }

  .group-input {
    margin-bottom: 4rem;
  }

  .header-group-input {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--Gray-200, #eaecf0);
  }

  .input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-new-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    /* align-content: center; */
  }

  .input-new-3 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input-new-4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-2 {
    display: grid;
    grid-template-columns: 24rem 1fr;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-header {
    font-family: inherit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--Gray-700);
    margin-bottom: 5px;
  }

  .button-outline {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }

  .error-input {
    border: 1px solid red;
  }

  .error {
    color: red;
  }
  .react-number-formatter-dropbtn {
    height: 40px;
  }
`
const initApplicationForm = {
  employ_id: '',
  first_name: '',
  last_name: '',
  first_name_th: '',
  last_name_th: '',
  title: '',
  email: '',
  phone_no: '',
  department: '',
  position: '',
  photo: '',
  martial_status: '',
  nationality: '',
  identification_no: '',
  birth_date: null,
  number_of_children: '',
  // country: '',
  gender: '',
  passport_no: '',
  address: '',
  thai_province: '',
  thai_amphure: '',
  thai_tambon: '',
  postcode: '',
  bank: '',
  bank_account_no: '',
  emergency_contact: '',
  private_car_plate: '',
  emergency_phone_no: '',
  visa_no: '',
  work_permit_no: '',
  visa_expire_date: null,
  workpermit_expire_date: null,
  workpermit_file_id: '',
  education: [
    {
      key: uuidv4(),
      school_name: '',
      school_type: 'elementary school',
      entry_year: '',
      graduate_year: '',
    },
    {
      key: uuidv4(),
      school_name: '',
      school_type: 'junior high school',
      entry_year: '',
      graduate_year: '',
    },
    {
      key: uuidv4(),
      school_name: '',
      school_type: 'senior hign school',
      entry_year: '',
      graduate_year: '',
    },
  ],
  university: [],
  resume: [],
  application_token: '',
}

const initTitleList = [
  { text: 'Mr.', value: 'Mr.' },
  { text: 'Ms.', value: 'Ms.' },
  { text: 'Mrs.', value: 'Mrs.' },
]

const initMartialStatusList = [
  { text: 'Single', value: 'Single' },
  { text: 'Married', value: 'Married' },
  { text: 'Divorced', value: 'Divorced' },
  { text: 'Windowed', value: 'Windowed' },
  { text: 'Separated', value: 'Separated' },
  { text: 'Domestic', value: 'Domestic' },
]

const initGenderList = [
  { text: 'Male', value: 'Male' },
  { text: 'Female', value: 'Female' },
]

const initCertificationLevel = [
  { text: 'ปริญญาตรี', value: 'ปริญญาตรี' },
  { text: 'ปริญญาโท', value: 'ปริญญาโท' },
  { text: 'ปริญญาเอก', value: 'ปริญญาเอก' },
]

const initValidateForm = {
  title: null,
  first_name: null,
  last_name: null,
  first_name_th: null,
  last_name_th: null,
  email: null,
  identification_no: null,
}

const allowTypes = ['images', 'files']

function CreateApplicationForm() {
  const { t } = useTranslation()
  const { token } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { verifyApplicationInfo } = useSelector(selectApplicationForm)
  const { countries, banks, nationalities, departments, provinces, districts, subdistricts } = useSelector(selectMaster)
  const refScroll1 = useRef(null)
  const refScroll2 = useRef(null)
  const [formRequire, setFormRequire] = useState([])
  const [formApplication, setFormApplication] = useState(initApplicationForm)
  const [filesResume, setFilesResume] = useState([])
  const [filesWorkPermit, setFilesWorkPermit] = useState([])
  const [filesPhoto, setFilesPhoto] = useState([])
  const [company_id, setcompany_id] = useState(1)
  // dropdown list
  const [listYear, setListYear] = useState([])
  const [countryList, setCountryList] = useState([])
  const [bankList, setBankList] = useState([])
  const [nationalityList, setNationalityList] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  const [positionList, setPositionList] = useState([])
  const [provinceList, setProvinceList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [subDistrictList, setSubDistrictList] = useState([])
  // validate
  const [errors, setErrors] = useState(initValidateForm)

  const [failModal, setFailModal] = useState(false)
  const [failMessageModal, setFailMessageModal] = useState({
    headline: '',
    message: '',
  })

  useEffect(() => {
    const currentYear = dayjs().year()
    const yearRange = 50
    const yearList = []

    for (let i = currentYear - yearRange; i <= currentYear + yearRange; i++) {
      yearList.push(i)
    }

    const newYearList = yearList.map((v) => ({
      text: v.toString(),
      value: v,
    }))
    setListYear(newYearList)
  }, [])
  useEffect(() => {
    dispatch(getCountries())
    dispatch(getBanks())
    dispatch(getNationalities())
    //dispatch(getDepartmentsAndPositions())
    dispatch(getProvinces())
  }, [dispatch])
  useEffect(() => {
    const newList = countries.map((v) => ({
      text: v?.name,
      value: v?.id,
    }))
    setCountryList(newList)
  }, [countries])
  useEffect(() => {
    const newList = banks.map((v) => ({
      text: v?.nam_th,
      value: v?.id,
    }))
    setBankList(newList)
  }, [banks])
  useEffect(() => {
    const newList = nationalities.map((v) => ({
      text: v?.name,
      value: v?.id,
    }))
    setNationalityList(newList)
  }, [nationalities])
  useEffect(() => {
    const newList = departments.map((v) => ({
      text: v?.name,
      value: v?.id,
      positions: v?.positions,
    }))
    setDepartmentList(newList)
  }, [departments])
  useEffect(() => {
    const newList = provinces.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
    }))
    setProvinceList(newList)
  }, [provinces])
  useEffect(() => {
    const newList = districts.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
    }))
    setDistrictList(newList)
  }, [districts])
  useEffect(() => {
    const newList = subdistricts.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
      postcode: v?.attributes?.zip_code,
    }))
    setSubDistrictList(newList)
  }, [subdistricts])

  useEffect(() => {
    const params = {
      token: token,
    }
    dispatch(verifyApplicationFormToken(params)).then((res) => {
      if (res.error) {
        // const errorMessage = res?.error?.response?.data?.error?.message
        // navigate(`/verifyError/?error=${errorMessage}`, {
        //   replace: true,
        // })
      }
    })
  }, [token, dispatch, navigate])
  useEffect(() => {
    //alert(JSON.stringify(verifyApplicationInfo))
    if (verifyApplicationInfo.form_require?.length > 0) {
      setFormRequire(verifyApplicationInfo.form_require)
    }

    if (verifyApplicationInfo.company_id) {
      setcompany_id(verifyApplicationInfo.company_id)
      dispatch(getDepartmentsAndPositionsByCompany(verifyApplicationInfo.company_id))
    }

    if (verifyApplicationInfo.email) {
      setFormApplication((prev) => ({
        ...prev,
        email: verifyApplicationInfo.email,
      }))
    }
  }, [verifyApplicationInfo])
  useEffect(() => {
    if (formApplication?.department !== '') {
      const departmentMatch = departmentList.find((v) => v.value == formApplication?.department)
      const newPosition = departmentMatch?.positions.map((v) => ({
        text: v.name,
        value: v.id,
      }))
      setPositionList(newPosition)

      setFormApplication((prev) => ({
        ...prev,
        position: '',
      }))
    }
  }, [formApplication?.department, departmentList])
  useEffect(() => {
    if (formApplication?.thai_province !== '') {
      dispatch(getDistricts(formApplication?.thai_province))
      setFormApplication((prev) => ({
        ...prev,
        thai_amphure: '',
        thai_tambon: '',
        postcode: '',
      }))
    }
  }, [formApplication?.thai_province, dispatch])
  useEffect(() => {
    if (formApplication?.thai_amphure !== '') {
      dispatch(getSubDistricts(formApplication?.thai_amphure))
      setFormApplication((prev) => ({
        ...prev,
        thai_tambon: '',
        postcode: '',
      }))
    }
  }, [formApplication?.thai_amphure, dispatch])
  useEffect(() => {
    if (formApplication?.thai_tambon !== '') {
      const subDistrictMatch = subDistrictList.find((v) => v.value == formApplication?.thai_tambon)
      setFormApplication((prev) => ({
        ...prev,
        postcode: subDistrictMatch.postcode,
      }))
    }
  }, [formApplication?.thai_tambon, subDistrictList])
  useEffect(() => {
    if (
      errors?.title ||
      errors?.first_name ||
      errors?.last_name ||
      errors?.first_name_th ||
      errors?.last_name_th ||
      errors?.email
    ) {
      refScroll1?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    } else if (errors?.identification_no) {
      refScroll2?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [errors])

  // onChange
  const onChangeApplicationForm = (event) => {
    const { name, value } = event.target
    setFormApplication((prev) => ({
      ...prev,
      [name]:
        name === 'phone_no' ||
        name === 'number_of_children' ||
        name === 'identification_no' ||
        name === 'bank_account_no' ||
        name === 'emergency_phone_no'
          ? value.replace(/[^0-9]/g, '')
          : value,
    }))
  }

  const onChangeValueApplicationForm = (name, value) => {
    setFormApplication((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeEducation = (key, name, value) => {
    const educationForms = [...formApplication.education]
    const index = educationForms.findIndex((v) => v.key == key)
    educationForms[index][name] = value
    setFormApplication((prev) => ({ ...prev, education: educationForms }))
  }

  const onChangeUniversity = (key, name, value) => {
    const universityForms = [...formApplication.university]
    const index = universityForms.findIndex((v) => v.key == key)
    universityForms[index][name] = value
    setFormApplication((prev) => ({ ...prev, university: universityForms }))
  }

  const addMajor = () => {
    const newMajor = {
      key: uuidv4(),
      certificate_level: '',
      field_study: '',
      university_name: '',
      entry_year: '',
      graduate_year: '',
    }

    const newUniversities = [...formApplication.university, newMajor]

    setFormApplication((prev) => ({
      ...prev,
      university: newUniversities,
    }))
  }

  const deleteUniversity = (key) => {
    const newUniversities = formApplication.university.filter((v) => v.key != key)
    setFormApplication((prev) => ({ ...prev, university: newUniversities }))
  }

  const onChangeUploadResumeFile = (value) => {
    setFilesResume(value)
  }

  const onChangeUploadPhotoFile = (value) => {
    setFilesPhoto(value)
  }

  const onChangeUploadWorkPermitFile = (value) => {
    setFilesWorkPermit(value)
  }

  // submit
  const onSubmitForm = () => {
    const isValid = validateForm()
    if (isValid) {
      //---- เพิ่มการตรวจสอบว่า เลขบัตร ปชช นี้มีใน Employee ของ Company นี้หรือยัง
      const { country, ...rest } = formApplication
      const requestForm = {
        ...formApplication,
      }
      for (let [key, value] of Object.entries(formApplication)) {
        if (Array.isArray(formApplication[key])) {
          formApplication[key] = value
        } else if (formApplication[key] == '')
          requestForm[key] = key == 'employ_id' || key == 'martial_status' || key == 'gender' ? '' : null
        else formApplication[key] = value
      }

      const data = {
        ...requestForm,
        education: formRequire.some((v) => v == 'Education')
          ? requestForm.education.map((v) => ({
              ...v,
              school_name: v.school_name === '' ? null : v.school_name,
              entry_year: v.entry_year === '' ? null : v.entry_year,
              graduate_year: v.graduate_year === '' ? null : v.graduate_year,
            }))
          : [],
        university:
          requestForm.university.length === 0
            ? [
                {
                  certificate_level: '',
                  field_study: null,
                  university_name: null,
                  entry_year: null,
                  graduate_year: null,
                },
              ]
            : requestForm.university.map((v) => ({
                ...v,
                field_study: v.field_study === '' ? null : v.field_study,
                university_name: v.university_name === '' ? null : v.university_name,
                entry_year: v.entry_year === '' ? null : v.entry_year,
                graduate_year: v.graduate_year === '' ? null : v.graduate_year,
              })),
        photo: filesPhoto.map((v) => v.id)[0] ?? null,
        resume: filesResume.length > 0 ? filesResume.map((v) => v.id) : null,
        workpermit_file_id: filesWorkPermit.length == 0 ? null : filesWorkPermit.map((v) => v.id),
        application_token: token,
        company: company_id,
      }
      const request = { data }

      dispatch(createEmployeeWithApplicationForm(request)).then((res) => {
        if (res.type == 'CREATE_EMPLOYEE_WITH_APPLICATION_FORM_SUCCESS') {
          navigate('/login', {
            replace: true,
          })
        } else {
          setFailMessageModal({
            headline: 'Error Create user',
            message: 'This ID is already existed',
          })
          setFailModal(true)
        }
      })
    }
  }

  // optional
  const validateForm = () => {
    let newErrors = {
      ...initValidateForm,
    }

    if (formApplication?.title == '') {
      newErrors.title = 'Please select Title'
    }
    if (formApplication?.first_name == '') {
      newErrors.first_name = 'Please fill in First Name'
    }
    if (formApplication?.last_name == '') {
      newErrors.last_name = 'Please fill in Last Name'
    }
    if (formApplication?.first_name_th == '') {
      newErrors.first_name_th = 'Please fill in First Name (TH)'
    }
    if (formApplication?.last_name_th == '') {
      newErrors.last_name_th = 'Please fill in Last Name (TH)'
    }
    if (formApplication?.email == '') {
      newErrors.email = 'Please fill in Email'
    }
    if (formApplication?.identification_no == '') {
      newErrors.identification_no = 'Please fill in Identification No'
    }

    setErrors(newErrors)
    return Object.values(newErrors).every((x) => x === null)
  }

  const toArray = (file) => {
    if (Array.isArray(file)) {
      return file
    } else {
      return file ? [file] : []
    }
  }

  return (
    <Div>
      <div className="header mb-2-rem">{t('employee_data_information')}</div>

      {formRequire.some((v) => v == 'Information') && (
        <>
          <div className="group-input">
            <div className="header-group-input mb-1-rem">{t('basic_information')}</div>
            <div className="divider"></div>
            <div className="input-new mb-1-rem" ref={refScroll1}>
              <div className="input-new-2">
                <div className="input-header">{t('title')}*</div>
                <Dropdown
                  className={`${errors.title == null ? '' : 'error-input'}`}
                  name="title"
                  optionList={initTitleList}
                  value={formApplication.title}
                  onChange={(value) => onChangeValueApplicationForm('title', value)}
                />
                {errors.title && <div className="error">{errors.title}</div>}
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('first_name')}*</div>
                <TextField
                  className={`${errors.first_name == null ? '' : 'error-input'}`}
                  name="first_name"
                  value={formApplication.first_name}
                  onChange={onChangeApplicationForm}
                />
                {errors.first_name && <div className="error">{errors.first_name}</div>}
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('last_name')}*</div>
                <TextField
                  className={`${errors.last_name == null ? '' : 'error-input'}`}
                  name="last_name"
                  value={formApplication.last_name}
                  onChange={onChangeApplicationForm}
                />
                {errors.last_name && <div className="error">{errors.last_name}</div>}
              </div>
            </div>
            {/* <div className="input-new mb-1-rem">
              <div>{errors.title && <p className="error">{errors.title}</p>}</div>
              <div>{errors.first_name && <p className="error">{errors.first_name}</p>}</div>
              <div>{errors.last_name && <p className="error">{errors.last_name}</p>}</div>
            </div> */}

            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('first_name_th')}*</div>
                <TextField
                  className={`${errors.first_name_th == null ? '' : 'error-input'}`}
                  name="first_name_th"
                  value={formApplication.first_name_th}
                  onChange={onChangeApplicationForm}
                />
                {errors.first_name_th && <div className="error">{errors.first_name_th}</div>}
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('last_name_th')}*</div>
                <TextField
                  className={`${errors.last_name_th == null ? '' : 'error-input'}`}
                  name="last_name_th"
                  value={formApplication.last_name_th}
                  onChange={onChangeApplicationForm}
                />
                {errors.last_name_th && <div className="error">{errors.last_name_th}</div>}
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('photo')}</div>
                <MediaFile
                  multiple={false}
                  allowedTypes={allowTypes}
                  file={toArray(filesPhoto)}
                  onChange={onChangeUploadPhotoFile}
                />
              </div>
            </div>

            {/* <div className="input-new mb-1-rem">
              <div>{errors.first_name_th && <p className="error">{errors.first_name_th}</p>}</div>
              <div>{errors.last_name_th && <p className="error">{errors.last_name_th}</p>}</div>
              <div></div>
            </div> */}

            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('departments')}</div>
                <Dropdown
                  name="department"
                  optionList={departmentList}
                  value={formApplication.department}
                  onChange={(value) => onChangeValueApplicationForm('department', value)}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('job_position')}</div>
                <Dropdown
                  name="position"
                  optionList={positionList}
                  value={formApplication.position}
                  onChange={(value) => onChangeValueApplicationForm('position', value)}
                />
              </div>
            </div>

            <div className="input-new">
              <div className="input-new-2">
                <div className="input-header">{t('phone_number')}</div>
                {/*
                  <TextField name="phone_no" value={formApplication.phone_no} onChange={onChangeApplicationForm} />
                  */}

                <NumberFormatter
                  onlyCountries="THA"
                  defaultCountry="TH"
                  placeholder={'+66 (000) 000 0000'}
                  value={formApplication.phone_no}
                  initialFormat={true}
                  getValue={(n) => setFormApplication({ ...formApplication, phone_no: n })}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('email')}*</div>
                <TextField
                  className={`${errors.email == null ? '' : 'error-input'}`}
                  name="email"
                  value={formApplication.email}
                  onChange={onChangeApplicationForm}
                  disabled={true}
                />
              </div>
            </div>

            <div className="input-new mb-1-rem">
              <div></div>
              <div>{errors.email && <p className="error">{errors.email}</p>}</div>
              <div></div>
            </div>
          </div>

          <div className="group-input">
            <div className="header-group-input mb-1-rem">{t('family_citizenship_information')}</div>
            <div className="divider"></div>

            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('martial_status')}</div>
                <Dropdown
                  name="martial_status"
                  optionList={initMartialStatusList}
                  value={formApplication.martial_status}
                  onChange={(value) => onChangeValueApplicationForm('martial_status', value)}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('number_of_children')}</div>
                <TextField
                  name="number_of_children"
                  value={formApplication.number_of_children}
                  onChange={onChangeApplicationForm}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('nationality')}</div>
                <Dropdown
                  name="nationality"
                  optionList={nationalityList}
                  value={formApplication.nationality}
                  onChange={(value) => onChangeValueApplicationForm('nationality', value)}
                />
              </div>
            </div>

            <div className="input-new" ref={refScroll2}>
              <div className="input-new-2">
                <div className="input-header">{t('gender')}</div>
                <Dropdown
                  name="gender"
                  optionList={initGenderList}
                  value={formApplication.gender}
                  onChange={(value) => onChangeValueApplicationForm('gender', value)}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('identification_no')}.*</div>
                <TextField
                  className={`${errors.identification_no == null ? '' : 'error-input'}`}
                  name="identification_no"
                  value={formApplication.identification_no}
                  onChange={onChangeApplicationForm}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('passport_no')}.</div>
                <TextField name="passport_no" value={formApplication.passport_no} onChange={onChangeApplicationForm} />
              </div>
            </div>

            <div className="input-new mb-1-rem">
              <div></div>
              <div> {errors.identification_no && <p className="error">{errors.identification_no}</p>}</div>
              <div></div>
            </div>

            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('date_of_birth')}</div>
                <Date
                  value={formApplication.birth_date}
                  onChange={(value) => onChangeValueApplicationForm('birth_date', value)}
                />
              </div>
            </div>
          </div>

          <div className="group-input">
            <div className="header-group-input mb-1-rem">{t('private_contact')}</div>
            <div className="divider"></div>
            <div className="input-new-2 mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('address')}</div>
                <TextField name="address" value={formApplication.address} onChange={onChangeApplicationForm} />
              </div>
            </div>
            <div className="input-new-3 mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('province')}</div>
                <Dropdown
                  name="thai_province"
                  optionList={provinceList}
                  value={formApplication.thai_province}
                  onChange={(value) => onChangeValueApplicationForm('thai_province', value)}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('district')}</div>
                <Dropdown
                  name="thai_amphure"
                  optionList={districtList}
                  value={formApplication.thai_amphure}
                  onChange={(value) => onChangeValueApplicationForm('thai_amphure', value)}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('sub_district')}</div>
                <Dropdown
                  name="thai_tambon"
                  optionList={subDistrictList}
                  value={formApplication.thai_tambon}
                  onChange={(value) => onChangeValueApplicationForm('thai_tambon', value)}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('postcode')}</div>
                <TextField
                  name="postcode"
                  disabled={true}
                  value={formApplication.postcode}
                  onChange={onChangeApplicationForm}
                />
              </div>
            </div>
            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('bank_account')}</div>
                <Dropdown
                  name="bank"
                  optionList={bankList}
                  value={formApplication.bank}
                  onChange={(value) => onChangeValueApplicationForm('bank', value)}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('account_number')}</div>
                <TextField
                  name="bank_account_no"
                  value={formApplication.bank_account_no}
                  onChange={onChangeApplicationForm}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('private_car_plate')}</div>
                <TextField
                  name="private_car_plate"
                  value={formApplication.private_car_plate}
                  onChange={onChangeApplicationForm}
                />
              </div>
            </div>

            <div className="input-new mb-1-rem">
              <div className="input-new-2">
                <div className="input-header">{t('emergency_contact')}</div>
                <TextField
                  name="emergency_contact"
                  value={formApplication.emergency_contact}
                  onChange={onChangeApplicationForm}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('emergency_phone')}</div>
                <TextField
                  name="emergency_phone_no"
                  value={formApplication.emergency_phone_no}
                  onChange={onChangeApplicationForm}
                />
              </div>
            </div>
          </div>
        </>
      )}

      {formRequire.some((v) => v == 'Education') && (
        <div className="group-input">
          <div className="header-group-input mb-1-rem">{t('education_information')}</div>
          <div className="divider"></div>

          {formApplication.education.map((v, i) => (
            <div className="input-new mb-1-rem" key={v.key}>
              <div className="input-new-2">
                <div className="input-header">{v.school_type}</div>
                <TextField
                  name={`school_name_${i}`}
                  value={v.school_name}
                  onChange={(event) => onChangeEducation(v.key, 'school_name', event.target.value)}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('entry_year')}</div>
                <Dropdown
                  name={`education_entry_year_${i}`}
                  placeHolder={t('entry_year')}
                  optionList={listYear}
                  value={v.entry_year}
                  onChange={(value) => onChangeEducation(v.key, 'entry_year', value)}
                />
              </div>
              <div className="input-new-2">
                <div className="input-header">{t('graduate_year')}</div>
                <Dropdown
                  name={`education_graduate_year_${i}`}
                  placeHolder={t('graduate_year')}
                  optionList={listYear}
                  value={v.graduate_year}
                  onChange={(value) => onChangeEducation(v.key, 'graduate_year', value)}
                />
              </div>
            </div>
          ))}

          <div className="group-input">
            <div className="header-group-input mb-1-rem">{t('university')}</div>
            <div className="divider"></div>

            {formApplication.university.map((v, i) => (
              <div>
                <div className="input-new mb-1-rem">
                  <div className="input-new-2">
                    <div className="input-header">{t('certificate_level')}</div>
                    <Dropdown
                      name={`certificate_level_${i}`}
                      optionList={initCertificationLevel}
                      value={v.certificate_level}
                      onChange={(value) => onChangeUniversity(v.key, 'certificate_level', value)}
                    />
                  </div>
                  <div className="input-new-2">
                    <div className="input-header">{t('Field_of_study')}</div>
                    <TextField
                      name={`field_study_${i}`}
                      value={v.field_study}
                      onChange={(event) => onChangeUniversity(v.key, 'field_study', event.target.value)}
                    />
                  </div>
                  <div className="input-new-2">
                    <div className="input-header">{t('university')}</div>
                    <TextField
                      name={`university_name_${i}`}
                      value={v.university_name}
                      onChange={(event) => onChangeUniversity(v.key, 'university_name', event.target.value)}
                    />
                  </div>
                </div>
                <div className="input-new mb-1-rem">
                  <div className="input-new-2">
                    <div className="input-header">{t('entry_year')}</div>
                    <Dropdown
                      name={`university_entry_year_${i}`}
                      placeHolder={t('entry_year')}
                      optionList={listYear}
                      value={v.entry_year}
                      onChange={(value) => onChangeUniversity(v.key, 'entry_year', value)}
                    />
                  </div>
                  <div className="input-new-2">
                    <div className="input-header">{t('graduate_year')}</div>
                    <Dropdown
                      name={`university_graduate_year_${i}`}
                      placeHolder={t('graduate_year')}
                      optionList={listYear}
                      value={v.graduate_year}
                      onChange={(value) => onChangeUniversity(v.key, 'graduate_year', value)}
                    />
                  </div>
                  <div className="input-new-2 d-flex align-items-center">
                    <div></div>
                    <img className="icon" src={iconDelete} title="Delete" onClick={() => deleteUniversity(v.key)} />
                  </div>
                </div>
              </div>
            ))}

            <ButtonOutline className="button-outline" onClick={addMajor}>
              {t('add_major')}
            </ButtonOutline>
          </div>
        </div>
      )}

      {formRequire.some((v) => v == 'Resume') && (
        <div className="group-input">
          <div className="header-group-input mb-1-rem">{t('resume')}</div>
          <div className="divider"></div>

          <div className="input-new mb-1-rem">
            <div className="input-new-2">
              <MediaFile
                multiple={true}
                allowedTypes={allowTypes}
                file={toArray(filesResume)}
                onChange={onChangeUploadResumeFile}
              />
            </div>
          </div>
        </div>
      )}

      {formRequire.some((v) => v == 'Work Permit') && (
        <div className="group-input">
          <div className="header-group-input mb-1-rem">{t('work_permit')}</div>
          <div className="divider"></div>
          <div className="input-new-4 mb-1-rem">
            <div className="input-new-2">
              <div className="input-header">{t('visa_no')}.</div>
              <TextField name="visa_no" value={formApplication.visa_no} onChange={onChangeApplicationForm} />
            </div>
            <div className="input-new-2">
              <div className="input-header">{t('visa_expire_date')}</div>
              <Date
                value={formApplication.visa_expire_date}
                onChange={(value) => onChangeValueApplicationForm('visa_expire_date', value)}
              />
            </div>
          </div>
          <div className="input-new-4 mb-1-rem">
            <div className="input-new-2">
              <div className="input-header">{t('work_permit_no')}.</div>
              <TextField
                name="work_permit_no"
                value={formApplication.work_permit_no}
                onChange={onChangeApplicationForm}
              />
            </div>
            <div className="input-new-2">
              <div className="input-header">{t('work_permit_expire_date')}</div>
              <Date
                value={formApplication.workpermit_expire_date}
                onChange={(value) => onChangeValueApplicationForm('workpermit_expire_date', value)}
              />
            </div>
          </div>

          <div className="input-new-4 mb-1-rem">
            <div className="input-new-2">
              <div className="input-header">{t('upload_file')}</div>
              <MediaFile
                multiple={true}
                allowedTypes={allowTypes}
                file={toArray(filesWorkPermit)}
                onChange={onChangeUploadWorkPermitFile}
              />
            </div>
          </div>
        </div>
      )}

      <div className="d-flex justify-content-center align-items-center">
        <Button onClick={onSubmitForm}>{t('submit_form')}</Button>
      </div>

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes="Ok"
      />
    </Div>
  )
}

export default CreateApplicationForm
