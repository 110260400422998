import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { updateRequestApproveItem, updateSubTaskAction } from '../../../../redux/actions/summaryTask'
import { selectProjectTask, selectSummaryTask } from '../../../../redux/selectors'
import { getAdminUser } from '../../../../utils/common'
import { dlog } from '../../../../utils/helper'
import Button from '../../../form/button/Button'
import DropdownSelect from '../../../widget/element/DropDownSelect'
import IconEditing from '../../../widget/icon/IconEditing'
import IconXClose from '../../../widget/icon/IconXClose'
import ApproveLanding from '../../approvals/ApproveLanding'
import ViewDoc from '../../approvals/ViewDoc'

export default function SubTaskShowItem({ data, onUpdate, isSelected, onComplete, onClickEdit, isRoutine,taskId }) {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { taskInfo } = useSelector(selectSummaryTask)
  const { milestoneTaskTypeInfo } = useSelector(selectProjectTask)
  const [isClickedSubTaskItem, setIsClickedSubTaskItem] = useState(isSelected)
  const [actionSelected, setActionSelected] = useState('')
  const [isSubmitNote, setIsSubmitNote] = useState(false)
  const [note, setNote] = useState('')
  const [subTaskData, setSubTaskData] = useState({})
  const [isShowDocApprove, setIsShowDocApprove] = useState(false)
  const [isShowDocOnly, setIsShowDocOnly] = useState(false)
  const [isAssignToMe, setIsAssignToMe] = useState(false)
  const [fileDoc, setFileDoc] = useState()
  const [actionList, setActionList] = useState([
    {
      text: 'Working',
      value: 'Working',
    },
    {
      text: 'Complete',
      value: 'Complete',
    },
  ])

  const [actionEl, setActionEl] = useState(null)
  const open = Boolean(actionEl)

  useEffect(() => {
    setNote('')
    setIsClickedSubTaskItem(isSelected)
  }, [isSelected])

  useEffect(() => {
    if (taskInfo && subTaskData.procurement_ref_id) {
      const docId = subTaskData.procurement_ref_id
      const docList = isRoutine ? taskInfo.procurement_templates : milestoneTaskTypeInfo.procurement_templates

      const findDoc = docList.find((v) => v.id == docId)
      if (findDoc?.file_detail) {
        // dlog('FINDED')
        setFileDoc(findDoc.file_detail)
      }
    }
  }, [taskInfo, subTaskData])

  useEffect(() => {
    if (data) {
      // dlog(data, 'SUB TASK ITEM')
      if (data.status == 'Working') {
        setActionList([
          {
            text: 'Complete',
            value: 'Complete',
          },
        ])
        setActionSelected('')
      }
      if (data.assign_to?.data?.attributes?.email == user?.email || data.assign_to?.email == user?.email) {
        setIsAssignToMe(true)
      } else {
        setIsAssignToMe(false)
      }
      setSubTaskData(data)
    }
  }, [data])

  const displayDateFormat = (dateTime) => {
    const formattedDateTime = moment(dateTime).format('DD/MMM/YYYY')
    return formattedDateTime ? formattedDateTime : '-'
  }

  const handleSubmitApprove = (data) => {
    const tmpObj = {
      ...data,
      request_approve: subTaskData.request_approve_id,
      created_by: employee_info?.id
    }
    dispatch(updateRequestApproveItem({ data: tmpObj })).then(() => {
      setIsShowDocApprove(false)
      onUpdate()
    })
  }
  const onClickSubmit = () => {
    if (actionSelected == 'Working' || actionSelected == 'Complete') {
      let obj = {
        sub_task: subTaskData.id,
        action: actionSelected,
        created_by: employee_info?.id
      }
      if (isRoutine) {
        obj.task = taskId
      } else {
        obj.milestone_task_type = taskId
      }
      if (actionSelected == 'Complete') {
        if (note) {
          obj.note = note
        }
        dispatch(updateSubTaskAction({ data: obj })).then(() => {
          if (onComplete) {
            onComplete(actionSelected, note)
          }
        })
      } else if (actionSelected == 'Working') {
        dispatch(updateSubTaskAction({ data: obj })).then(() => {
          if (onComplete) {
            onComplete(actionSelected, note)
          }
          setActionList([
            {
              text: 'Complete',
              value: 'Complete',
            },
          ])
          setActionSelected('')
        })
      }
    } else {
      alert('Plese select action')
    }
  }
  const handleClickReopen = () => {
    let obj = {
      sub_task: subTaskData.id,
      action: 'Reopen',
      note: '',
      created_by: employee_info?.id
    }
    if (isRoutine) {
      obj.task = taskId
    } else {
      obj.milestone_task_type = taskId
    }
    dispatch(updateSubTaskAction({ data: obj })).then(() => {
      if (onComplete) {
        onComplete('Working', '')
      }
      setActionSelected('')
    })
  }
  const onClickAprroveOrRejectSubTask = (subtaskstatus) => {
    let obj = {
      sub_task: subTaskData.id,
      action: subtaskstatus,
      created_by: employee_info?.id
    }
    if (note) {
      obj.note = note
    }
    if (isRoutine) {
      obj.task = taskId
    } else {
      obj.milestone_task_type = taskId
    }
    dispatch(updateSubTaskAction({ data: obj })).then(() => {
      onUpdate()
    })
  }

  return (
    <Styled isClickedSubTaskItem={isClickedSubTaskItem} className="subtask-show-item">
      <div className="content-txt">
        <div className="wrp-title">
          <div className="title">
            {(subTaskData.subtask_type == 'Request Approve' && subTaskData.subtask_detail == 'Document request approve'
              ? subTaskData?.subtask_detail
              : subTaskData?.topic || '-') + (' #' + subTaskData?.no || '-')}
            {subTaskData.subtask_detail != 'Document request approve' &&
              (subTaskData.creator?.data?.id == employee_info.id || subTaskData.creator?.id == employee_info.id) && (
                <div onClick={onClickEdit}>
                  <IconEditing width="16px" />
                </div>
              )}
          </div>

          <div className="description">
            <div className="txt-left">
              {!isClickedSubTaskItem && (
                <div>
                  To {subTaskData?.assign_to_name || '-'} | Due Date{' '}
                  {subTaskData?.due_date ? displayDateFormat(subTaskData.due_date) : '-'}
                </div>
              )}
              {isClickedSubTaskItem && (
                <div className="expand-detail-to">
                  <div className="title-detail">Details</div>
                  <div className="detail">
                    {subTaskData.subtask_type == 'Request Approve' &&
                    subTaskData.subtask_detail == 'Document request approve'
                      ? subTaskData?.request_approve_notes?.data?.[0]?.attributes?.note || ''
                      : subTaskData?.subtask_detail || ''}
                  </div>

                  <div className="wrp-assignment-detail">
                    <div>
                      To {subTaskData?.assign_to_name || '-'} | Due Date{' '}
                      {subTaskData?.due_date ? displayDateFormat(subTaskData.due_date) : '-'}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="txt-right">
              <div className="status-item">
                <div className="status">Status - {subTaskData.status}</div>

                <div className="txt-assign">
                  {subTaskData.subtask_type == 'Request Approve' ? 'Requested by' : 'Assigned by'}{' '}
                  {subTaskData?.assign_by_name || ''}
                </div>
              </div>
            </div>
          </div>
        </div>

        {isClickedSubTaskItem && (
          <>
            <div className="wrp-expand">
              <div className="wrp-detail">
                {(subTaskData?.status == 'Approve' || subTaskData?.status == 'Reject') && (
                  <div className="detail-inner">
                    <div className="title-detail mt-1-rem">
                      {subTaskData?.status == 'Approve' ? 'Approved' : 'Rejected'} Note
                    </div>
                    <div className="detail">{subTaskData.comment || '-'}</div>
                    <div className="wrp-assignment-detail">
                      <div>
                        {subTaskData.status} by {subTaskData.request_to_name || ''} |{' '}
                        {subTaskData?.approve_time ? displayDateFormat(subTaskData.approve_time) : '-'}
                      </div>
                    </div>
                  </div>
                )}
                {(subTaskData?.status == 'Approved' || subTaskData?.status == 'Rejected') && (
                  <div className="detail-inner">
                    <div className="title-detail mt-1-rem">{subTaskData.status} Note</div>
                    <div className="detail">
                      {subTaskData.sub_task_actions?.data?.[0]?.attributes?.note ||
                        subTaskData.sub_task_actions[0]?.note ||
                        ''}
                    </div>
                    <div className="wrp-assignment-detail">
                      <div>
                        {subTaskData.status} by {subTaskData.assign_to_name || ''} |{' '}
                        {subTaskData.sub_task_actions?.data?.[0]?.attributes?.createdAt
                          ? displayDateFormat(subTaskData.sub_task_actions.data[0].attributes.createdAt)
                          : subTaskData.sub_task_actions[0]?.createdAt
                          ? displayDateFormat(subTaskData.sub_task_actions[0]?.createdAt)
                          : '-'}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {subTaskData.subtask_type == 'Request Approve' &&
                subTaskData.subtask_detail == 'Document request approve' &&
                (subTaskData?.status == 'Approve' || subTaskData?.status == 'Reject') && (
                  <div className="wrp-bottom-right-corner">
                    <div className="btn-view-doc">
                      <Button
                        onClick={() => {
                          setIsShowDocOnly(true)
                        }}
                      >
                        View Document
                      </Button>
                    </div>
                  </div>
                )}

              {subTaskData.subtask_type == 'Request Approve' &&
                subTaskData.subtask_detail == 'Document request approve' &&
                !(subTaskData?.status == 'Approve' || subTaskData?.status == 'Reject') && (
                  <div className="wrp-bottom-right-corner">
                    <div className="btn-view-doc">
                      <Button
                        onClick={() => {
                          setIsShowDocApprove(true)
                        }}
                      >
                        View Document
                      </Button>
                    </div>
                  </div>
                )}
            </div>
            {isAssignToMe && subTaskData.status == 'Requesting Approval' && (
              <div className="wrp-note-text-area wrp-reject-approve-btn">
                <textarea
                  className={`text-area ${isSubmitNote && note == '' ? 'error' : ''}`}
                  placeholder="Note"
                  value={note}
                  onChange={(e) => setNote(e.target.value.substring(0, 275))}
                  onClick={(e) => e.stopPropagation()}
                />

                <div className="wrp-approve-reject-btn">
                  <Button className="mr-1-rem reject-btn" onClick={() => onClickAprroveOrRejectSubTask('Rejected')}>
                    Reject
                  </Button>
                  <Button onClick={() => onClickAprroveOrRejectSubTask('Approved')}>Approve</Button>
                </div>
              </div>
            )}

            {isAssignToMe &&
              subTaskData.subtask_type != 'Request Approve' &&
              subTaskData.subtask_detail != 'Document request approve' &&
              subTaskData.status != 'Complete' && (
                <>
                  <div className="change-status-task">
                    <DropdownSelect
                      className={'dropdown-select'}
                      dataList={actionList}
                      initValue={actionSelected}
                      placeHolder={'Action'}
                      onChange={(v, e) => {
                        e.stopPropagation()
                        setActionSelected(v)
                      }}
                      onClick={(e) => e.stopPropagation()}
                    />
                    <div className="wrp-submit-btn">
                      <Button className="mb-1-rem" onClick={onClickSubmit}>
                        Submit
                      </Button>
                    </div>
                  </div>

                  {actionSelected == 'Complete' && (
                    <div className="wrp-note-text-area">
                      <textarea
                        className={`text-area ${isSubmitNote && note == '' ? 'error' : ''}`}
                        placeholder="Note"
                        value={note}
                        onChange={(e) => setNote(e.target.value.substring(0, 275))}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                  )}
                </>
              )}
            {subTaskData.subtask_type != 'Request Approve' && subTaskData.status == 'Complete' && (
              <div className="wrp-reopen-btn">
                <div className="title-detail">Complete Task Note</div>
                <div className="detail">{subTaskData?.complete_task_note || '-'}</div>
                <Button className="btn-reopen" onClick={handleClickReopen}>
                  Reopen
                </Button>
              </div>
            )}
          </>
        )}
      </div>

      {isShowDocApprove && (
        <ApproveLanding
          docId={subTaskData.procurement_ref_id}
          file={fileDoc}
          onClose={() => setIsShowDocApprove(false)}
          onSubmit={handleSubmitApprove}
        />
      )}
      {isShowDocOnly && (
        <div className="preview-doc">
          <div className="wrp-navigation">
            <div className="navigation-right">
              <div className="icon-x-close" onClick={() => setIsShowDocOnly(false)}>
                <IconXClose />
              </div>
            </div>
          </div>
          <ViewDoc file={fileDoc} docId={subTaskData.procurement_ref_id} />
        </div>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  .preview-doc {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 1;
    .wrp-navigation {
      display: flex;
      justify-content: end;
      padding: 16px 32px;
      border: none;
    }
    .navigation-right {
      display: flex;
      align-items: center;
    }
    .icon-x-close {
      display: flex;
      cursor: pointer;
    }
  }
  .mt-1-rem {
    margin-top: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .mb-1rem {
    margin-bottom: 1rem;
  }
  .ml-1-rem {
    margin-left: 1rem;
  }

  border: 1px solid var(--Gray-600);
  border-radius: 15px;
  cursor: pointer;

  .wrp-title {
    padding: 16px;
    .title {
      font-weight: bold;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
    }

    .description {
      display: flex;
      align-items: start;
      justify-content: space-between;
      /* align-items: ${({ isClickedSubTaskItem }) => (isClickedSubTaskItem ? 'flex-start' : 'center')}; */
      .txt-left {
        .title {
          font-size: 20px;
          font-weight: 600;
        }
      }
      .txt-right {
        text-align: end;
      }
    }
  }

  .wrp-assignment-detail {
    display: flex;
    align-items: end;
    justify-content: space-between;
  }
  .txt-assign {
    display: flex;
    justify-content: end;
    font-size: 14px;
  }

  .wrp-note {
    margin-left: 1rem;
    .wrp-upload-file {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .icon-file-btn {
        padding: 1rem;
        border: 1px solid var(--Gray-300);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .name-file {
          margin-top: 0.5rem;
        }
      }
    }
  }

  .wrp-box-comment {
    padding: 1rem;
    background-color: var(--Gray-600);

    .box-comment {
      background-color: #fff;
      border-radius: 0;
    }
  }

  .user-comment {
    display: flex;
    flex-direction: row;
    align-items: start;
    flex-wrap: wrap;
  }
  .icon-user-circle {
    width: 30px;
    height: 30px;
    margin-right: 0.5rem;
  }

  .wrp-detail-comment {
    flex: 1;
  }
  .user-comment-detail {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .user-detail {
    color: #fff;
    font-weight: bold;
    font-size: 20px;

    min-width: 0;
    flex-basis: 50%;

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .icon-dot {
    cursor: pointer;
  }
  .txt-comment {
    color: #fff;
  }

  .wrp-reject-approve-btn {
    flex-direction: column;
    margin-top: 16px;
  }

  .wrp-note-text-area {
    display: flex;

    .text-area {
      flex: 1;

      height: 100px;
      border: 1px solid #ced4da;
      border-radius: 0.375rem;
      padding: 16px;

      font-family: inherit;
      color: var(--Gray-500);
      font-size: 14px;
      line-height: 1.5;

      resize: none;

      margin: 0 16px 16px;

      &.error {
        border-color: red;
      }
    }
  }
  .wrp-submit-btn {
    text-align: end;
  }

  .wrp-detail {
    .detail-inner {
      padding: 16px;
    }
  }
  .wrp-bottom-right-corner {
    display: flex;
    flex-direction: column;
    justify-content: end;
    border-top: 1px solid var(--Gray-600);
    .btn-view-doc {
      padding: 16px;
    }
  }
  .wrp-bottom-right-corner > div {
    text-align: right;
  }

  .change-status-task {
    padding: 16px;
    display: flex;
    justify-content: space-between;
  }
  .wrp-approve-reject-btn {
    display: flex;
    justify-content: right;
    padding: 0 16px 16px;
  }
  .reject-btn {
    background-color: #fff;
    color: var(--Primary-600);
  }
  .dropdown-select {
    width: 200px;
  }
  .wrp-reopen-btn {
    padding: 16px;
    .btn-reopen {
      margin-top: 16px;
    }
  }
`
