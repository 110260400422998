import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getBusinessDocById, getCompanyInfoById, getDocumentSettingByCompanyId, createBusinessDocument, editBusinessDocument } from 'redux/actions/document'
import { selectDocument } from 'redux/selectors'
import styled from 'styled-components'
import { generateId } from 'utils/helper'
import CreateDocLayout from '../custom-view/CreateDocLayout'
import DialogDoc from '../custom-view/DialogDoc'
export default function TransferRequest({ previewType, currentPage, onCloseDialog, filter, itemSelected, onCallback }) {
  const docTitle = 'ใบขออนุญาตขนย้ายน้ำตาลทราย'
  const docType = 'transfer_request'
  const navigatePath = '/document/transportation'

  const HEADER_LIST = [
    [
      [
        {
          id: 'hd01',
          position: 'left',
          type: 'input_text',
          title: 'Company Name',
          placeholder: 'Company Name',
          class_name_create: '',
          class_name_preview: '',
        },
        {
          id: 'hd02',
          position: 'left',
          type: 'input_text',
          title: 'ทะเบียนพาณิชย์',
          placeholder: 'ทะเบียนพาณิชย์',
          class_name_create: '',
          class_name_preview: '',
        },
        {
          id: 'hd03',
          position: 'right',
          type: 'input_text_area',
          title: 'address',
          placeholder: 'address details',
          class_name_create: '',
          class_name_preview: '',
        },
      ],
    ],
    [
      // { id: 'hd04', type: 'input_text', title: 'เลขที่รับ', placeholder: '00000000' },
      { id: 'hd04', type: 'input_date', title: 'วันที่รับ', placeholder: 'select' },
      { id: 'hd05', type: 'input_text', title: 'ผู้รับ', placeholder: 'ชื่อผู้รับ' },
    ],
  ]
  const BODY_LIST = [
    [
      {
        id: 'bd01',
        type: 'table',
        title: 'รายละเอียดน้ำตาลทราย',
        class_name_create: 'width_100',
        class_name_preview: 'width_100',
        value: {
          header: [
            { id: 'bd02-hd-0', type: 'text', title: 'No.' },
            { id: 'bd02-hd-1', type: 'text', title: 'ฤดูกาลผลิต' },
            {
              id: 'bd02-hd-2',
              type: 'text',
              title: 'ประเภท',
              placeholder: 'Enter Text',
              // class_name_create: 'col_width_100',
              // class_name_preview: 'width_300px',
            },
            { id: 'bd02-hd-3', type: 'text', title: 'ชนิด' },
            { id: 'bd02-hd-4', type: 'text', title: 'จำนวน (กระสอบ/เมตริกตัน) ' },
            { id: 'bd02-hd-5', type: 'action', title: '' },
          ],
          body: [
            [
              { id: 'bd03-bd-1', type: 'run_number' },
              { id: 'bd02-bd-1', type: 'input_text', placeholder: 'Enter Text' },
              {
                id: 'bd02-bd-2',
                type: 'input_text',
                placeholder: 'Enter Text',
                // class_name_create: 'width_100',
                // class_name_preview: 'width_300px',
              },
              { id: 'bd02-bd-3', type: 'input_text', placeholder: 'Enter Text' },
              { id: 'bd02-bd-4', type: 'input_text', placeholder: '0', sub_type: 'number' },
              { id: 'bd02-bd-5', type: 'button_delete' },
            ],
          ],
        },
      },
    ],
    [
      {
        id: 'bd02',
        type: 'input_transfer_request_information',
        class_name_create: 'width_100',
        class_name_preview: 'width_100',
        is_error: false,
        value: {
          from: {
            name: '',
            address: '',
            province: '',
            district: '',
            sub_district: '',
          },
          to: {
            name: '',
            address: '',
            province: '',
            district: '',
            sub_district: '',
          },
          vehicle_type: '',
          vehicle_name: '',
          start_date: '',
          end_date: '',
          note: '',
        },
      },
    ],
  ]

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const docId = searchParams?.get('id')
  const navigateType = searchParams.get('navigate')

  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { businessDocumentData, companyData, documentSettingInfo } = useSelector(selectDocument)

  const [isBindingDataDone, setIsBindingDataDone] = useState(false)
  const [previewStage, setPreviewStage] = useState(previewType || 'close')
  const [documentId, setDocumentId] = useState()

  const [headerList, setHeaderList] = useState(HEADER_LIST)
  const [bodyList, setBodyList] = useState(BODY_LIST)
  const [companyDocData, setCompanyDocData] = useState()

  useEffect(() => {
    dispatch(getCompanyInfoById())
    dispatch(getDocumentSettingByCompanyId())
  }, [])

  useEffect(() => {
    if (docId || itemSelected?.id) {
      let tmpId = itemSelected?.id || docId
      setDocumentId(tmpId)
    }
  }, [docId, itemSelected])

  useEffect(() => {
    if (documentId && documentId != 'undefined') {
      dispatch(getBusinessDocById(documentId))
    }
  }, [documentId])

  useEffect(() => {
    // console.log('businessDocumentData: '+JSON.stringify(businessDocumentData,null,2))
    if (documentId && businessDocumentData?.id) {
      bindEditData()
    }
  }, [businessDocumentData])

  useEffect(() => {
    let tmpCompanyData

    if (!documentId) {
      tmpCompanyData = {
        seller_logo: documentSettingInfo[docType]?.company_logo,
        seller_company_name: companyData?.company_name_TH,
        seller_address: companyData?.address,
        seller_tax_id: companyData?.tex_id,
        // customer_company_name: customerData?.name,
        // customer_address: customerData?.address,
        // customer_phone: customerData?.phone,
        // customer_tax_id: customerData?.tax_id,
      }
    } else {
      if (businessDocumentData) {
        tmpCompanyData = {
          seller_logo: businessDocumentData?.company_logo,
          seller_company_name: businessDocumentData?.company?.company_name_TH,
          seller_address: businessDocumentData?.company?.address,
          seller_tax_id: businessDocumentData?.company?.tex_id,
          // customer_company_name: customerData?.name,
          // customer_address: customerData?.address,
          // customer_phone: customerData?.phone,
          // customer_tax_id: customerData?.tax_id,
          doc_id: businessDocumentData?.doc_id,
        }
      }
    }
    // console.log('tmpCompanyData: '+JSON.stringify(tmpCompanyData,null,2))
    setCompanyDocData(tmpCompanyData)
  }, [companyData, documentId, businessDocumentData, documentSettingInfo])

  const bindEditData = () => {
    setIsBindingDataDone(false)
    //bindHeader
    let tmpHeaderList = [...headerList]
    for (let colIndex = 0; colIndex < tmpHeaderList.length; colIndex++) {
      let colItem = tmpHeaderList[colIndex]
      //  col left
      if (colIndex == 0) {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]
          for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
            let dataItem = rowItem[dataIndex]
            if (dataItem?.id == 'hd01') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                text: businessDocumentData?.company_name || '',
              }
            } else if (dataItem?.id == 'hd02') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                text: businessDocumentData?.business_registration_no || '',
              }
            } else if (dataItem?.id == 'hd03') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                text: businessDocumentData?.company_address || '',
              }
            }

            // if (dataItem?.type == 'search_customer') {
            //   let tmpText = ''
            //   if (dataItem?.sub_type == 'customer_id') {
            //     tmpText = businessDocumentData?.customer?.customer_id
            //   } else if (dataItem?.sub_type == 'customer_name') {
            //     tmpText = businessDocumentData?.customer?.name
            //   } else if (dataItem?.sub_type == 'tax_id') {
            //     tmpText = businessDocumentData?.customer?.tax_id
            //   }
            //   tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
            //     customer_data: businessDocumentData?.customer,
            //     text: tmpText,
            //   }
            // } else if (dataItem?.id == 'hd04') {
            //   tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
            //     text: businessDocumentData?.customer?.office || '',
            //   }
            // } else if (dataItem?.id == 'hd05') {
            //   tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
            //     text: businessDocumentData?.customer?.phone || '',
            //   }
            // } else if (dataItem?.id == 'hd06') {
            //   tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
            //     text: businessDocumentData?.customer?.address || '',
            //   }
            // }
          }
        }
      }

      //  col right
      else if (colIndex == 1) {
        for (let dataIndex = 0; dataIndex < colItem.length; dataIndex++) {
          let dataItem = colItem[dataIndex]

          if (dataItem?.id == 'hd04') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.doc_date,
            }
          } else if (dataItem?.id == 'hd05') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.receiver || '-',
            }
          }

          // if (dataItem?.id == 'hd07') {
          //   tmpHeaderList[colIndex][dataIndex].value = {
          //     text: businessDocumentData?.doc_date,
          //   }
          // } else if (dataItem?.id == 'hd08') {
          //   tmpHeaderList[colIndex][dataIndex].value = {
          //     text: businessDocumentData?.ref_number,
          //   }
          // } else if (dataItem?.id == 'hd09') {
          //   tmpHeaderList[colIndex][dataIndex].value = {
          //     id: businessDocumentData?.list_creator_employee_id,
          //   }
          // } else if (dataItem?.id == 'hd10') {
          //   tmpHeaderList[colIndex][dataIndex].value = {
          //     text: businessDocumentData?.transport_date,
          //   }
          // }
        }
      }
    }

    //bindBody
    let tmpBodyList = JSON.parse(JSON.stringify(bodyList))
    let tmpTableBody
    // let tmpSumTotalPrice = 0
    for (let rowIndex = 0; rowIndex < tmpBodyList.length; rowIndex++) {
      let rowItem = tmpBodyList[rowIndex]

      for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
        let dataItem = rowItem[dataIndex]
        // console.log('BODY_dataItem: ' + JSON.stringify(dataItem, null, 2))

        if (dataItem?.type == 'table') {
          tmpTableBody = dataItem?.value?.body || []
          let tmpItemList = businessDocumentData?.item_list || []

          let prototypeTableRow = tmpTableBody[0]
          tmpTableBody = []
          for (let productIndex = 0; productIndex < tmpItemList.length; productIndex++) {
            let productItem = tmpItemList[productIndex]
            let tmpTableRowItem = prototypeTableRow
            for (let i = 0; i < tmpTableRowItem.length; i++) {
              tmpTableRowItem[i].id = generateId(6)

              if (prototypeTableRow[i]?.placeholder) {
                tmpTableRowItem[i].placeholder = prototypeTableRow[i].placeholder
              }
              // console.log('productItem: '+JSON.stringify(productItem,null,2))
              if (i == 1) {
                tmpTableRowItem[i].value = { text: productItem?.season }
              } else if (i == 2) {
                tmpTableRowItem[i].value = {
                  text: productItem?.class,
                }
              } else if (i == 3) {
                tmpTableRowItem[i].value = {
                  text: productItem?.type,
                }
              } else if (i == 4) {
                tmpTableRowItem[i].value = { text: productItem?.amount }
              }
            }
            console.log('tmpTableRowItem: '+JSON.stringify(tmpTableRowItem,null,2))
            let tmpObj = tmpTableRowItem
            tmpTableBody.push(tmpObj)
          }
          console.log('tmpTableBody: '+JSON.stringify(tmpTableBody,null,2))
          tmpBodyList[rowIndex][dataIndex].value.body = tmpTableBody
        }

        if (dataItem?.type == 'input_transfer_request_information') {
          tmpTableBody = dataItem?.value?.body || []
          let tmpObj = {
            from: {
              ...businessDocumentData?.from,
              province: businessDocumentData?.from?.province?.id,
              district: businessDocumentData?.from?.district?.id,
              sub_district: businessDocumentData?.from?.sub_district?.id,
            },
            to: {
              ...businessDocumentData?.to,
              province: businessDocumentData?.to?.province?.id,
              district: businessDocumentData?.to?.district?.id,
              sub_district: businessDocumentData?.to?.sub_district?.id,
            },
            vehicle_type: businessDocumentData?.vehicle_type || '',
            vehicle_name: businessDocumentData?.vehicle_name || '',
            start_date: businessDocumentData?.start_date || '',
            end_date: businessDocumentData?.end_date || '',
            note: businessDocumentData?.note || '',
          }
          tmpBodyList[rowIndex][dataIndex].value = tmpObj
        }
      }
    }

    setHeaderList(tmpHeaderList)
    setBodyList(tmpBodyList)
    setIsBindingDataDone(true)
  }

  const onBtnPreviewClicked = () => {
    setPreviewStage('preview_create')
  }

  const handelCloseDialog = () => {
    setPreviewStage('close')
    if (onCloseDialog) {
      onCloseDialog()
    }
  }

  const onBtnSaveClicked = async () => {
    let tmpSendData = {
      doc_type: docType,
      creator_id: employee_info?.id,
      company_id: company_info?.id,
    }
    let tmpHeaderValues = getHeaderValues()
    let tmpBodyValues = getBodyValues()
    // let tmpFooterValues = getFooterValues()

    if (
      !tmpHeaderValues?.company_name ||
      !tmpHeaderValues?.business_registration_no ||
      !tmpHeaderValues?.company_address ||
      !tmpHeaderValues?.doc_date ||
      !tmpHeaderValues?.receiver
    ) {
      return
    }

    if (tmpBodyValues?.item_list?.length == 0 ||
      !tmpBodyValues?.vehicle_type ||
      !tmpBodyValues?.start_date ||
      !tmpBodyValues?.end_date ||
      // !tmpBodyValues?.note ||
      !tmpBodyValues?.from?.name ||
      !tmpBodyValues?.from?.address ||
      !tmpBodyValues?.from?.province ||
      !tmpBodyValues?.from?.district ||
      !tmpBodyValues?.from?.sub_district ||
      !tmpBodyValues?.to?.name ||
      !tmpBodyValues?.to?.address ||
      !tmpBodyValues?.to?.province ||
      !tmpBodyValues?.to?.district ||
      !tmpBodyValues?.to?.sub_district ||
      (tmpBodyValues?.vehicle_type == 'อื่นๆ' && !tmpBodyValues?.vehicle_name)
      ) {
      return
    }

    for (let rowIndex = 0; rowIndex < tmpBodyValues?.item_list.length; rowIndex++) {
      let tmpBodyList = tmpBodyValues?.item_list
      let rowItem = tmpBodyList[rowIndex]
      if (!rowItem?.season || !rowItem?.class || !rowItem?.type || !rowItem?.amount) {
        return
      }
    }

    let uploadData = {
      ...tmpSendData, 
      ...tmpHeaderValues, 
      ...tmpBodyValues, 
      is_external: navigateType == 'external' ? true : false, 
    }

    let tmpSendRequestData = {
      type: docType,
      data: uploadData,
    }

    if (!documentId) {
      await dispatch(createBusinessDocument(tmpSendRequestData)).then((e)=>{
        console.log('e: '+JSON.stringify(e,null,2))
        if (e?.data) {
          if(navigateType == 'external') {
            if(onCallback) {
              onCallback(e?.data?.id)
            }
          } else {
            navigate(navigatePath)
          }
        } else {

        }
      })
      
    } else {
      dispatch(editBusinessDocument(tmpSendRequestData, documentId))
    }
  }

  const getHeaderValues = () => {
    let tmpValues = {}
    let tmpHeaderList = [...headerList]

    for (let colIndex = 0; colIndex < headerList.length; colIndex++) {
      let colItem = headerList[colIndex]
      if (colIndex == 0) {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]

          for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
            let dataItem = rowItem[dataIndex]

            if (dataItem?.id == 'hd01') {
              tmpValues.company_name = dataItem?.value?.text || ''
            } else if (dataItem?.id == 'hd02') {
              tmpValues.business_registration_no = dataItem?.value?.text || ''
            } else if (dataItem?.id == 'hd03') {
              tmpValues.company_address = dataItem?.value?.text || ''
            } 

            if (!dataItem?.value?.text) {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                ...tmpHeaderList[colIndex][rowIndex][dataIndex]?.value,
                is_error: true,
              }
            }
          }
        }
        // tmpValues.update_customer = tmpUpdateCustomerData
      } else {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]

          if (rowItem?.id == 'hd04' && rowItem?.value?.text) {
            tmpValues.doc_date = rowItem.value.text
          } else if (rowItem?.id == 'hd05' && rowItem?.value?.text) {
            tmpValues.receiver = rowItem.value.text
          }

          if (!rowItem?.value?.text) {
            tmpHeaderList[colIndex][rowIndex].value = {
              ...tmpHeaderList[colIndex][rowIndex]?.value,
              is_error: true,
            }
          }
        }
      }
    }

    setHeaderList(tmpHeaderList)
    return tmpValues
  }

  const getBodyValues = () => {
    let tmpBodyList = JSON.parse(JSON.stringify(bodyList))
    let tmpBodyTable
    let tmpProductList = []
    let tmpValue = {}
    let isError = false;

    for (let rowIndex = 0; rowIndex < tmpBodyList.length; rowIndex++) {
      let rowItem = tmpBodyList[rowIndex]
      for (let colIndex = 0; colIndex < rowItem.length; colIndex++) {
        let colItem = rowItem[colIndex]

        if (colItem?.type == 'table') {
          tmpBodyTable = colItem?.value?.body
          if (tmpBodyTable?.length > 0) {
            for (let tableRowIndex = 0; tableRowIndex < tmpBodyTable.length; tableRowIndex++) {
              let tableRowItem = tmpBodyTable[tableRowIndex]
              let productItem = {}

              for (let tableColIndex = 0; tableColIndex < tableRowItem.length; tableColIndex++) {
                let tableColItem = tableRowItem[tableColIndex]

                if (tableColIndex == 1 && tableColItem?.value?.text) {
                  productItem.season = tableColItem.value.text
                } else if (tableColIndex == 2 && tableColItem?.value?.text) {
                  productItem.class = tableColItem.value.text
                } else if (tableColIndex == 3 && tableColItem?.value?.text) {
                  productItem.type = tableColItem.value.text
                } else if (tableColIndex == 4 && tableColItem?.value?.text) {
                  productItem.amount = parseFloat(tableColItem.value.text)
                }

                if (tableColIndex == 1 || tableColIndex == 2 || tableColIndex == 3 || tableColIndex == 4) {
                  if (!tableColItem?.value?.text) {
                    tmpBodyTable[tableRowIndex][tableColIndex].value = {
                      ...tmpBodyTable[tableRowIndex][tableColIndex]?.value,
                      is_error: true,
                    }
                    isError = true;
                  }
                }
              }

              tmpProductList.push(productItem)
            }
          }
          tmpBodyList[rowIndex][colIndex].value = { ...tmpBodyList[rowIndex][colIndex]?.value, body: tmpBodyTable }

          // setBodyList(tmpBodyList)
          // break
        } else if(colItem?.type == 'input_transfer_request_information') {
          let tmpData = colItem?.value

          if(tmpData?.from?.name && 
            tmpData?.from?.address && 
            tmpData?.from?.province && 
            tmpData?.from?.district && 
            tmpData?.from?.sub_district &&
            tmpData?.to?.name && 
            tmpData?.to?.address && 
            tmpData?.to?.province && 
            tmpData?.to?.district && 
            tmpData?.to?.sub_district &&
            tmpData?.vehicle_type &&
            tmpData?.start_date &&
            tmpData?.end_date 
            ) {
              if(tmpData?.vehicle_type != 'อื่นๆ') {
                tmpValue = tmpData;
              } else {
                if(tmpData?.vehicle_name) {
                  tmpValue = tmpData;
                }else {
                  isError = true;
                }
              }
          } else {
            isError = true;
          }
        }
        tmpBodyList[rowIndex][colIndex].is_error = true;
      }
    }

    setBodyList(tmpBodyList)

    tmpValue = {
      ...tmpValue,
      item_list: tmpProductList,
    }
    if(!isError) {
      return tmpValue
    }
    return null
  }

  return (
    <Styled>
      {(previewStage == 'close' || previewStage == 'preview_create') && (
        <CreateDocLayout
          isEdit={documentId ? true : false}
          title={docTitle}
          headerList={headerList}
          bodyList={bodyList}
          onChange={(e) => {
            if (e?.layout_type == 'header_left' || e?.layout_type == 'header_right') {
              setHeaderList(e?.data)
            } else if (e?.layout_type == 'body') {
              setBodyList(e?.data)
            }
          }}
          onBtnSaveClicked={onBtnSaveClicked}
          onBtnPreviewClicked={onBtnPreviewClicked}
          docType={docType}
        />
      )}

      {(previewStage == 'request_approve' ||
        previewStage == 'preview' ||
        previewStage == 'print' ||
        previewStage == 'download_pdf' ||
        previewStage == 'share' ||
        previewStage == 'preview_create') && (
        <DialogDoc
          docType={docType}
          docTitle={docTitle}
          headerList={headerList}
          bodyList={bodyList}
          // footerList={footerList}
          companyDocData={companyDocData}
          previewType={previewStage}
          onCloseClicked={handelCloseDialog}
          docId={documentId}
          isBindingDataDone={isBindingDataDone}
          copyNumber={businessDocumentData?.number_of_copy || documentSettingInfo?.transfer_request?.number_of_copy}
          currentPage={currentPage}
          filter={filter}
          itemSelected={itemSelected}
          rubberStamp={documentSettingInfo?.transfer_request?.rubber_stamp}
          signatureList={documentSettingInfo?.transfer_request?.signature_list}
        />
      )}
    </Styled>
  )
}
const Styled = styled.div``
