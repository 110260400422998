import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import Button from '../../form/button/Button'
import Dropdown from '../../form/Dropdown'
import TextField from '../../form/TextField'
import FileUploadProgress2 from '../../form/FileUploadProgress2'
import ButtonOutline from '../../form/button/ButtonOutline'
import DialogConfirm from '../../dialog/DialogConfirm'
import DialogFail from '../../dialog/DialogFail'

import { LiaTimesSolid } from 'react-icons/lia'
import { PiWarningCircleBold } from 'react-icons/pi'
import confirmIcon from '../../../assets/images/confirm-icon.png'
import failIcon from '../../../assets/images/fail-icon.png'

import { selectEmployeeProfile, selectMaster } from '../../../redux/selectors'
import { getBanks } from '../../../redux/actions/master'
import { requestEditEmployeeProfileInfo } from '../../../redux/actions/employeeProfile'

const Div = styled.div`
  padding: 2rem;

  .mr-12-px {
    margin-right: 12px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }

  .icon {
    cursor: pointer;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
  margin-bottom: 2em;
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-between {
    justify-content: space-between;
  }
  &.align-item-center {
    align-items: center;
  }
`
const HeaderContainer = styled.header`
  .heading-modal {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
  .sub-heading-modal {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`
const WarningContainer = styled.div`
  color: var(--warning-700);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;

  border-radius: 0.75rem;
  border: 1px solid var(--warning-300);
  background: var(--warning-25);

  padding: 1rem;

  .icon-warning-container {
    margin-right: 1rem;
  }

  .warning-message-container {
    .headline-warning {
      font-weight: 600;
    }
  }
`
const InputSection = styled.section`
  p {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .highlight {
    color: var(--Primary-700);
  }
`
const InputContainer = styled.div`
  &.width-23 {
    width: 23%;
  }
  &.width-32 {
    width: 32%;
  }
  &.width-49 {
    width: 49%;
  }
  &.width-100 {
    width: 100%;
  }
  .width-100 {
    width: 100%;
  }
  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.375rem;
  }
  .error-input {
    border: 1px solid var(--Error-300);
  }
  .error {
    color: var(--Error-500);
  }
`
const FooterFig = styled.div``

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}
const multiple = false
const error = false
const disabled = false

const initMessageModal = {
  headline: '',
  message: '',
}

const initForm = {
  bank: '',
  bank_account_no: '',
}
function EditBankInfo({ closeModal }) {
  const { t } = useTranslation()
  const lang = localStorage.getItem('lang')
  const dispatch = useDispatch()
  const { banks } = useSelector(selectMaster)
  const { employeeProfileInfo } = useSelector(selectEmployeeProfile)
  // form
  const [form, setForm] = useState(initForm)
  const [initialForm, setInitialForm] = useState(initForm)
  // dropdown list
  const [bankList, setBankList] = useState([])
  // file
  const [file, setFile] = useState([])
  const [acceptTypes, setAcceptTypes] = useState([])
  const [mimeTypes, setMimeTypes] = useState([])
  // dialog
  const [confirmEditModal, setConfirmEditModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  // message dialog
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const requestBanks = useCallback(() => {
    dispatch(getBanks())
  }, [dispatch])

  useEffect(() => {
    initFileType()
    getMimeTypes()
    requestBanks()
  }, [])

  useEffect(() => {
    const { bank, bank_account_no } = employeeProfileInfo
    const initForm = {
      bank: bank?.id,
      bank_account_no,
    }
    setForm(initForm)
    setInitialForm(initForm)
  }, [employeeProfileInfo])

  useEffect(() => {
    const bankList = banks.map((v) => ({
      text: lang === 'en' ? v?.name_en : v?.nam_th,
      value: v?.id,
    }))
    setBankList(bankList)
  }, [banks])

  // file
  const handleAddFile = async (file_id) => {
    setForm((prev) => ({
      ...prev,
      attach_file: file_id,
    }))
  }
  const onDeleteFile = (id) => {
    setFile(file.filter((v) => v.id !== id))
    setForm((prev) => ({
      ...prev,
      attach_file: null,
    }))
  }
  const initFileType = () => {
    const allowedTypes = []
    const acceptType = allowedTypes.reduce((pre, cur) => {
      return {
        ...pre,
        ...acceptList[cur],
      }
    }, {})
    setAcceptTypes(acceptType)
  }
  const getMimeTypes = () => {
    const categoryObjects = Object.values(acceptList)
    const mimeTypes = categoryObjects.flatMap((category) => Object.keys(category))

    setMimeTypes(mimeTypes)
  }

  // modal
  const openConfirmModal = () => {
    if (file.length > 0) {
      setConfirmEditModal(true)
    } else {
      setFailMessageModal({
        headline: 'File Required!',
        message: `Please attach the necessary evidence to support your edit request. This step is essential for verification purposes.`,
      })
      setFailModal(true)
    }
  }
  // onchange
  const onChangeForm = (event) => {
    const { value, name } = event.target
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeDropdown = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  // submit
  const submit = () => {
    const { employee_info } = JSON.parse(localStorage.getItem('admin'))
    const request_details = comparePropertyChange()
    const request = {
      requestor: employee_info.id,
      employee_requestted: employeeProfileInfo?.id,
      attach_file: file[0].id,
      request_details,
    }

    dispatch(requestEditEmployeeProfileInfo(request))
    setConfirmEditModal(false)
    closeModal()
  }

  const comparePropertyChange = () => {
    let requestDetails = []
    for (let [key, value] of Object.entries(form)) {
      if (key === 'attach_file' || key === 'postcode') continue
      if (value !== initialForm[key]) {
        const requestEdit = {
          field_name: key,
          field_value: value,
        }
        requestDetails.push(requestEdit)
      }
    }

    return requestDetails
  }

  return (
    <Div>
      <FlexContainer className="justify-content-between">
        <HeaderContainer>
          <h2 className="heading-modal">Request Personal Information Edit</h2>
          <p className="sub-heading-modal">
            Please fill in the details you'd like to edit and attach the required evidence for verification.
          </p>
        </HeaderContainer>
        <LiaTimesSolid className="icon" size={20} onClick={closeModal} />
      </FlexContainer>

      <WarningContainer>
        <FlexContainer>
          <div className="icon-warning-container">
            <PiWarningCircleBold size={25} />
          </div>
          <div className="warning-message-container">
            <div className="headline-warning">Bank Account Details</div>
            <div>
              Your banking information is crucial for processing payments, reimbursements, and other financial
              transactions. Please ensure the details below are up-to-date and match those in your official bank
              records. For any changes, upload a clear scan or photo of your bank book's front page for verification.
            </div>
          </div>
        </FlexContainer>
      </WarningContainer>

      <InputSection>
        <h3>Bank Account</h3>
        <Divider />
        <FlexContainer className="justify-content-between mb-24-px">
          <InputContainer className="width-49">
            <div className="input-header">Bank account</div>
            <Dropdown
              className="width-100"
              optionList={bankList}
              value={form?.bank}
              onChange={(value) => onChangeDropdown('bank', value)}
            />
          </InputContainer>
          <InputContainer className="width-49">
            <div className="input-header">Bank account no.</div>
            <TextField
              className="width-100"
              name="bank_account_no"
              value={form?.bank_account_no}
              onChange={onChangeForm}
            />
          </InputContainer>
        </FlexContainer>
      </InputSection>

      <InputSection>
        <h3>Upload Your Bank Book Details</h3>
        <Divider />
        <p>
          To ensure smooth and timely financial transactions, please upload a clear scan or photo of your bank book's
          front page. This provides us with accurate bank account details, ensuring all transactions are processed
          correctly. Ensure the image is legible, with your account number and name clearly visible.
        </p>
        <p className="highlight">*If there is more than one copy, combine them into one file.</p>

        <FileUploadProgress2
          className="upload-container"
          multiple={multiple}
          accept={acceptTypes}
          mimeTypes={mimeTypes}
          file={file?.map((v) => ({ ...v, url: `${v.url}` }))}
          onAddFile={handleAddFile}
          onDeleteFile={onDeleteFile}
          error={error}
          disabled={disabled}
          FileListData={file}
          setFileListData={setFile}
        />
      </InputSection>

      <FooterFig>
        <Divider />
        <FlexContainer className="justify-content-end">
          <ButtonOutline className="mr-12-px" onClick={closeModal}>
            Cancel
          </ButtonOutline>
          <Button onClick={openConfirmModal}>Submit Request</Button>
        </FlexContainer>
      </FooterFig>

      {/* Dialog */}
      <DialogConfirm
        open={confirmEditModal}
        onClose={() => setConfirmEditModal(false)}
        onSubmit={submit}
        icon={confirmIcon}
        title={'Confirm Edit Request'}
        description={`You're about to submit a request to edit your details. Ensure all changes are accurate before proceeding. Do you wish to continue?`}
        textNo={t('cancel')}
        textYes={t('confirm')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default EditBankInfo
