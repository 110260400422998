import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DateTimeHHmmDisplay,getCompanyInfo } from '../../utils/common'

import { getBaseByZoneIdReturnData,getDeletionRequestsReturnData,DeletionRequestKeep,DeletionRequestdelete } from '../../redux/actions/ofm'

import { getEmployeeDropdrow } from '../../redux/actions/master'

import { selectMaster } from '../../redux/selectors'

import AutoComplete from '../../components/form/AutoComplete'
import Date from '../../components/form/Date'

import Button from '../../components/form/button/Button'
import Dropdown from '../../components/form/Dropdown'
import TableV2 from '../../components/common/TableV2'

import Checkbox from '../../components/form/Checkbox'
import iconDelete from '../../assets/images/icon-bin-white.png'
import iconKeep from '../../assets/images/icon-keep.png'
import ButtonOutline from '../../components/form/button/ButtonOutline'

import SidePanel from '../../components/common/SidePanel'
import ModalViewTaskDetail from '../../pages/operation-field/ModalViewTaskDetail'
import ModalEditTaskDetail from '../../pages/operation-field/ModalEditTaskDetail'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import IconKeep from '../../assets/images/keep-icon.png'

const DivMain = styled.div`
color: var(--Gray-600);
font-size: 14px;
font-style: normal;
font-weight: 500;
.content-fillter-bg{
    min-height: 68px;
    background-color: var(--Gray-50);
    border-radius: 8px;
    margin-bottom: 21px;
    padding: 10px;
  }
  .content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
  
    .search-wrapper {
      /*margin-right: 12px;*/
    }
  
    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }
  
    .group_filter {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }
  }
.button_clear_all{
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
}
.mr-1-rem {
    margin-right: 1rem;
  }
.badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 10px;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
.table-wrapper {
    /*margin-top: 24px;*/
    border: 1px solid #EAECF0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #FFFFFF;

    .table-header {
        
        display: flex;
        justify-content: space-between;
        
        padding: 0px 14px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-radius: 8px 8px 0 0;
        background: #FFFFFF;

        .group {
            display: flex;
            align-items: center;
        }

        .table-title {
            font-size: 18px;
            font-weight: 600;
            color: var(--Gray-900);
        }

        .table-total {
            margin-left: 16px;
            font-size: 12px;
            font-weight: 500;
            padding: 2px 8px;
            color: var(--Primary-700);
            background: var(--Primary-50);
            border-radius: 16px;
            border: 1px var(--Primary-200) solid;
        }
    }

    .create-bt {
        text-decoration: none;
    }
} 
.button_view{
    color: #475467;
    background-color: #FFFFFF;
    border: 0px solid #EAECF0;
    box-shadow: none;
}
.margin_right_5{
    margin-right: 5px;
}
.button_Edit{
    color: #6941C6;
    background-color: #F9F5FF;
    border: 1px solid #EAECF0;
}
.button_del_sel{
    color: #FFFFFF;
    background-color: #d92d20;
    border: 1px solid #d92d20;
}
.button_keep{
    color: #004EEB;
    background-color: #EFF8FF;
    border: 1px solid #B2DDFF;
}

.button_Cancel{
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
    margin-right: 1rem;
}
.css_header_detail {
    font-size: 14px;
    color: #667085;
    padding: 5px 14px;
} 
.border_header_tab{
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 0px;
}

.col-checkbox{
    width:30px;
} 
.col-no{
    width:30px;
} 
.col-Delete_Request_date{
    width:200px;
}

.col-Delete_Request{
    width:150px;
}
/*
.col-Reason_for_deletion{
    width:200px;
}
.col-Base{
    width:200px;
}
*/
.col-Delete_view{
    width:100px;
}
.col-Delete_keep{
    width:100px;
}
.col-Delete_del{
    width:100px;
}

.badge {
    padding: 2px 8px;
    align-items: center;
    color: var(--Pink-700);
    background: var(--Pink-50);
    border: 1px solid var(--Pink-200);
    border-radius: 16px;
    margin-right : 5px;
    font-size : 14px;
    margin-top : 5px;
}
.text-bold {
    font-weight: bold;
  }

  .dd_css {
    /*width: 250px;*/
    min-width: 280px;
  }
  .title-filter{
    margin-bottom: 6px;
  }
  .no-title-filter{
    display: inline-block;
    align-self: flex-end;
  }
  .css_autocom{
    min-width: 250px;
    margin-right: 10px;
  }
`





const TabDeletionRequest = ({ ZoneId }) => {
    //alert(ParentZoneName);
    const CompanyInfo = getCompanyInfo()
    const initLang = localStorage.getItem('lang')
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const [limitOFM, setlimitOFM] = useState(10)
    const [total, settotal] = useState(0);
    const [totalPages, settotalPages] = useState(1);
    const [page, setpage] = useState(1);
    const [ReasonSelected, setReasonSelected] = useState(null);
    const [baseSelected, setbaseSelected] = useState(null);

    const Reason_option = [
        { text: 'กรอกข้อมูลไม่ครบ', value: 'กรอกข้อมูลไม่ครบ' },
        { text: 'กรอกข้อมูลผิดพลาด', value: 'กรอกข้อมูลผิดพลาด' },
        { text: 'อื่นๆ', value: 'อื่นๆ' },
    ]

    const [base_option, setbase_option] = useState([]);

    useEffect(() => {
        const filter = {
          skip: 1,
          limit: 10000
        }
        dispatch(getEmployeeDropdrow(filter))
      }, [dispatch])
      const { employeeList } = useSelector(selectMaster)
      const [employeeOption, setemployeeOption] = useState([])
      const [employee, setemployee] = useState('')
    
      useEffect(() => {
        const newEmployee = employeeList.map((v) => ({
          text: v?.first_name + ' ' + v?.last_name,
          value: v?.id,
        }))
        setemployeeOption([...newEmployee])
      }, [employeeList])

      const [SubmissionDate, setSubmissionDate] = useState(null);
      const [DeletionRequestDate, setDeletionRequestDate] = useState(null);
      

    const fetchDataAllBase = useCallback(
        async (filter,this_zone_id) => {
            if(this_zone_id){
                dispatch(getBaseByZoneIdReturnData(this_zone_id,filter)).then(({ type,data_info,data_meta }) => {
                    if(type.endsWith('_SUCCESS')){
                        if(data_info){
                            const newBasesCellData = data_info.bases?.map((v, index) => ({
                                value: v.id,
                                text: v.name,
                            }))
                            setbase_option(newBasesCellData); 
                        }
                    }
                })
            }
    
        },
        [dispatch]
     );

     useEffect(() => {
        
        const filter = {
            skip: 1,
            limit: 1000,
        }
        fetchDataAllBase(filter,ZoneId)
    }, [fetchDataAllBase,ZoneId]) 












    const [isSelectAll, setIsSelectAll] = useState(false)

    const onChangeSelectAllCheckBox = (e) => {
        
        /*
        if(isSelectAll){
            setIsSelectAll(false)
            setAttendance([])
            const newState = newsetUpData.map((obj, index) => {
                return { ...obj, isChecked: false }
            })
          
            setnewsetUpData(newState)
        }else{
            setIsSelectAll(true)
    
            const data_check_box = newsetUpData.map((obj, index) => {
                return { 
                  full_name: obj.full_name,
                  email: obj.email_address,
                  phone_no: obj.phone_no,
                  employee_id: obj.id,
                  employee: obj.id,
                  meeting_id : MeetingID,
                  company: null,
                  position: null,
                  note: null,
                  response: null,
                  actual_attend: null 
              }  
            })
            setAttendance(data_check_box)
    
            const newState = newsetUpData.map((obj, index) => {
                return { ...obj, isChecked: true }
            })
          
            setnewsetUpData(newState)
        }
        */
      }

      const handleChangeCheckBox = (e,v_inp,index_item) => {
        //alert(newsetUpData[index].isChecked)
        
        /*
        if(newsetUpData[index_item].isChecked){
          //newsetUpData[index].isChecked = false;
    
          const newState = newsetUpData.map((obj, index) => {
            // 👇️ if id equals 2, update country property
            if (index === index_item) {
              return { ...obj, isChecked: false }  
            }
            return obj
          })
          setnewsetUpData(newState)
          setAttendance(Attendance.filter((v) => v.employee_id !== v_inp.id));
        }else{
          const newState = newsetUpData.map((obj, index) => {
            // 👇️ if id equals 2, update country property
            if (index === index_item) {
              return { ...obj, isChecked: true }  
            }
            return obj
          })
          setnewsetUpData(newState)
          
          let newDataElement = {
              full_name: v_inp.full_name,
              email: v_inp.email_address,
              phone_no: v_inp.phone_no,
              employee_id: v_inp.id,
              employee: v_inp.id,
              meeting_id : MeetingID,
              company: null,
              position: null,
              note: null,
              response: null,
              actual_attend: null
          }
          setAttendance(current => [...current, newDataElement]);
        }
        */
      }

    const TableColumn = [
        {
            Header: <Checkbox checked={isSelectAll} label={'no'} onChange={onChangeSelectAllCheckBox} />,
            accessor: 'checkbox',
            disableSortBy: false,
        },
        {
            Header: 'Delete Request',
            accessor: 'Delete_Request_date',
            disableSortBy: false,
        },
        {
            accessor: 'Delete_Request',
            disableSortBy: false,
        },
        {
            Header: 'Reason for deletion',
            accessor: 'Reason_for_deletion',
            disableSortBy: false,
        },
        {
            Header: 'Base',
            accessor: 'Base',
            disableSortBy: false,
        },
        {
            Header: 'Task',
            accessor: 'Task',
            disableSortBy: false,
        },
        {
            accessor: 'Delete_view',
            disableSortBy: false,
        },
        {
            accessor: 'Delete_keep',
            disableSortBy: false,
        },
        {
            accessor: 'Delete_del',
            disableSortBy: false,
        },
    ];
    const [TableOFMData, setTableOFMData] = useState([]);
    const [UserEffectActive, setUserEffectActive] = useState(true);

      const fetchDataDeletionRequests = useCallback(
        async (filter,this_zone_id) => {
            if(this_zone_id){
                dispatch(getDeletionRequestsReturnData(this_zone_id,filter)).then(({ type,data_info,data_meta }) => {
                    if(type.endsWith('_SUCCESS')){
                        if(data_info){
                            const totalPage = Math.ceil(data_meta.pagination.total / limitOFM)
                            settotal(data_meta.pagination.total)
                            settotalPages(totalPage)

                            //console.table(data_info);

                            const newEmployee = data_info.map((v, index) => {    

                                return {
                                    checkbox: <Checkbox key={v.id} checked={v.isChecked} label={index + 1} onChange={(e) => handleChangeCheckBox(e,v,index)} />,
                                    no: limitOFM * page - (9 - index),
                                    Delete_Request_date : <div className="text-bold">{DateTimeHHmmDisplay(v.createdAt)}</div>,
                                    Delete_Request : <span
                                        className="badge"
                                        style={{
                                        color: '#B42318',
                                        borderColor: '#FECDCA',
                                        backgroundColor: '#FEF3F2',
                                        }}
                                    >
                                        แจ้งลบ
                                    </span>,
                                    Reason_for_deletion : v.reason.map((v_sub,index_sub) => {
                                      return <div
                                          className="badge"
                                          style={{
                                          color: '#344054',
                                          borderColor: '#EAECF0',
                                          backgroundColor: '#F9FAFB',
                                          }}
                                      >
                                          {v_sub}
                                      </div>
                                    }),
                                    Base : <span
                                        className="badge"
                                        style={{
                                        color: '#B54708',
                                        borderColor: '#FEDF89',
                                        backgroundColor: '#FFFAEB',
                                        }}
                                    >
                                        {v.report_log?.report?.task?.base?.name ? v.report_log?.report?.task?.base?.name : '-'}
                                    </span>,
                                    Task : <span
                                          className="badge"
                                          style={{
                                          color: '#026AA2',
                                          borderColor: '#B9E6FE',
                                          backgroundColor: '#F0F9FF',
                                          }}
                                      >
                                          { v.report_log?.report?.task?.name ? v.report_log?.report?.task?.name : '-' }
                                      </span>,
                                    Delete_view : <Button onClick={() => ViewTask(v)} className="button_view margin_right_5">
                                        View
                                    </Button>,
                                    Delete_keep : <Button append={<img src={iconKeep} />}  onClick={() => Keepdata(v)} className="button_keep margin_right_5">
                                        Keep
                                    </Button>,
                                    Delete_del : <Button append={<img src={iconDelete} />}  onClick={() => Deldata(v)} className="button_del_sel margin_right_5">
                                        Delete
                                    </Button>
                                  }
                            })
                            setTableOFMData(newEmployee);
                        }
                    }
                })
            }
    
        },
        [dispatch]
     );

     useEffect(() => {
        
        const filter = {
            skip: 1,
            limit: limitOFM,
        }
        fetchDataDeletionRequests(filter,ZoneId)
    }, [fetchDataDeletionRequests,ZoneId]) 

    const onSearchbaseSelected = (value) => {
        setbaseSelected(value)
        const filter = {
            skip: 1,
            limit: limitOFM,
            base : value,
            reason : ReasonSelected,
            employee_id : employee,
            SubmissionDate : SubmissionDate,
            DeletionRequestDate : DeletionRequestDate
        }
        fetchDataDeletionRequests(filter,ZoneId);
        setpage(1);
    }

    const onSearchReasonSelected = (value) => {
        setReasonSelected(value)
        const filter = {
            skip: 1,
            limit: limitOFM,
            base : baseSelected,
            reason : value,
            employee_id : employee,
            SubmissionDate : SubmissionDate,
            DeletionRequestDate : DeletionRequestDate
        }
        fetchDataDeletionRequests(filter,ZoneId);
        setpage(1);
    }

    const onSearchEmployee = (v) => {
        setemployee(v)
        const filter = {
            skip: 1,
            limit: limitOFM,
            base : baseSelected,
            reason : ReasonSelected,
            employee_id : v,
            SubmissionDate : SubmissionDate,
            DeletionRequestDate : DeletionRequestDate
        }
        fetchDataDeletionRequests(filter,ZoneId);
        setpage(1);
    }

    const onChangeSubmissionDate = (value) => {
        setSubmissionDate(value)
        const filter = {
            skip: 1,
            limit: limitOFM,
            base : baseSelected,
            reason : ReasonSelected,
            employee_id : employee,
            SubmissionDate : value,
            DeletionRequestDate : DeletionRequestDate
        }
        fetchDataDeletionRequests(filter,ZoneId);
        setpage(1);
    }

    const onChangeDeletionRequestDate = (value) => {
        setDeletionRequestDate(value)
        const filter = {
            skip: 1,
            limit: limitOFM,
            base : baseSelected,
            reason : ReasonSelected,
            employee_id : employee,
            SubmissionDate : SubmissionDate,
            DeletionRequestDate : value
        }
        fetchDataDeletionRequests(filter,ZoneId);
        setpage(1);
    }

    const onChangePagination = async (value) => {
        const filter = {
            skip: value.page,
            limit: limitOFM,
            base : baseSelected,
            reason : ReasonSelected,
            employee_id : employee,
            SubmissionDate : SubmissionDate,
            DeletionRequestDate : DeletionRequestDate
        }
        fetchDataDeletionRequests(filter,ZoneId);
        setpage(value.page)
    }

    const onClearAllSearch = () => {
        setbaseSelected(null);
        setReasonSelected(null);
        setemployee('');
        setSubmissionDate(null);
        setDeletionRequestDate(null);
        const filter = {
          skip: 1,
          limit: limitOFM,
        }
        fetchDataDeletionRequests(filter,ZoneId);
        setpage(1)
      }




    const [Titlesuccess, setTitlesuccess] = useState('')
    const [SubTitlesuccess, setSubTitlesuccess] = useState('')
    const [Descriptionsuccess, setDescriptionsuccess] = useState('')
    const [successModal, setsuccessModal] = useState(false)
    const [TitleFail, setTitleFail] = useState('')
    const [nameItemFail, setnameItemFail] = useState('')
    const [DescriptionFail, setDescriptionFail] = useState('')
    const [failModal, setFailModal] = useState(false)

    const [ModalViewTask, setModalViewTask] = useState(false)
    const [ReportId, setReportId] = useState('')
    const [ReportName, setReportName] = useState('')

    const [RequestId, setRequestId] = useState('')
    const [ReportInfoFooter, setReportInfoFooter] = useState('')

    const ViewTask = (Obj) => {
      //alert(Obj.report_log?.report?.id);
      setReportId(Obj.report_log?.report?.id);
      setReportName(Obj.report_log?.report?.task?.name ? Obj.report_log?.report?.task?.name : '-');
      const this_base_name = Obj.report_log?.report?.task?.base?.name ? Obj.report_log?.report?.task?.base?.name : '-';
      const this_zone_name = Obj.report_log?.report?.task?.base?.zone?.name ? Obj.report_log?.report?.task?.base?.zone?.name : '-';
      setReportInfoFooter(`${initLang === 'en' ? CompanyInfo?.company_name_EN : CompanyInfo?.company_name_TH}, ${this_zone_name}, ${this_base_name}`);
      setModalViewTask(true);
    }

    const CloseModalViewTask = () => {
      setReportId('');
      setModalViewTask(false)
    }

    const [ModalEditTask, setModalEditTask] = useState(false)
    const SubmitModalViewTask = () => {
      //setReportId('');
      setModalViewTask(false)
      setModalEditTask(true);
    }

    
    const CloseModalEditTask = () => {
      setReportId('');
      setModalEditTask(false)
    }
  
    const SubmitModalEditTask = () => {
      setReportId('');
      setModalEditTask(false)
      /*
      const filter = {
          skip: 1,
          limit: 1000,
          employee_id : employee,
          currentDate : currentDate,
      }
      fetchDataAll(filter,TaskId);
      setpage(1);
      */
    }

    const [ModalConfirmKeep, setModalConfirmKeep] = useState(false)
    const [ConfirmKeepnameItem, setConfirmKeepnameItem] = useState('')
    const Keepdata = (Obj) => {
        //alert(JSON.stringify(Obj.id));
        setRequestId(Obj.id);
        setConfirmKeepnameItem(DateTimeHHmmDisplay(Obj.createdAt));
        setModalConfirmKeep(true);
    }

    const SubmitModalConfirmKeep = () => {
        setModalConfirmKeep(false);
        dispatch(DeletionRequestKeep(RequestId, null)).then(({ type }) => {
            if(type.endsWith('_SUCCESS')){
              onClearAllSearch();
              setTitlesuccess('Keep Successful');
              setSubTitlesuccess('');
              setDescriptionsuccess(''); 
              setsuccessModal(true)
            }else{
              setTitleFail('Error Keep');
              setnameItemFail('');
              setDescriptionFail(''); 
              setFailModal(true)
            }    
        })
      }

    const [ModalConfirmDel, setModalConfirmDel] = useState(false)
    const [ConfirmDenameItem, setConfirmDenameItem] = useState('')
    const Deldata = (Obj) => {
        //alert(JSON.stringify(Obj.id));
        setRequestId(Obj.id);
        setConfirmDenameItem(DateTimeHHmmDisplay(Obj.createdAt));
        setModalConfirmDel(true);
    }

    const SubmitModalConfirmDel = () => {
        setModalConfirmDel(false);
        dispatch(DeletionRequestdelete(RequestId,null)).then(({ type }) => {
          if(type.endsWith('_SUCCESS')){
            onClearAllSearch();
            setTitlesuccess('Delete Successful');
            setSubTitlesuccess('');
            setDescriptionsuccess(''); 
            setsuccessModal(true)
          }else{
            setTitleFail('Error Delete');
            setnameItemFail('');
            setDescriptionFail(''); 
            setFailModal(true)
          }    
        })
      }
    
    return (
        <DivMain>
            <div className="table-wrapper">
                <div className="table-header">
                    <div className="group">
                        <div className="table-title">Deletion Request Log</div>
                        <div className="table-total">{total} requests</div>
                    </div>
                    <div>
                        <ButtonOutline append={<img src={iconDelete} />}  className="button_del_sel margin_right_5">
                            Delete Selected record
                        </ButtonOutline>
                        
                    </div>
                </div>
                <div className="border_header_tab"></div>

                <div className="content-fillter-bg">
                    <div className="content-fillter">
                        <div className="group_filter">
                            <div>
                                <div className="title-filter">Base</div>
                                <Dropdown
                                placeHolder="Select Base"
                                className="dd_css"
                                optionList={base_option}
                                value={baseSelected}
                                onChange={(value) => onSearchbaseSelected(value)}
                                ValueplaceHolderIsNull={true}
                                />

                            </div>
                            <div>
                                <div className="title-filter">Reason for Deletion</div>
                                <Dropdown
                                placeHolder="Select Reason for Deletion"
                                className="dd_css"
                                optionList={Reason_option}
                                value={ReasonSelected}
                                onChange={(value) => onSearchReasonSelected(value)}
                                ValueplaceHolderIsNull={true}
                                />
                            </div>
                            <div>
                                <div className="title-filter">Requested By</div>
                                <div className="css_autocom">
                                    <AutoComplete
                                        placeHolder='Requested By'
                                        optionList={employeeOption}
                                        value={employee}         
                                        onChange={onSearchEmployee}                     
                                    />
                                </div>

                            </div>
                            <div>
                                <div className="title-filter">Submission Date</div>
                                <Date className={'mr-1-rem'} value={SubmissionDate} onChange={onChangeSubmissionDate} />
                            </div>
                            <div>
                                <div className="title-filter">Deletion Request Date</div>
                                <Date className={'mr-1-rem'} value={DeletionRequestDate} onChange={onChangeDeletionRequestDate} />
                            </div>
                            <div className="no-title-filter">
                                <Button onClick={onClearAllSearch} className="button_clear_all mr-1-rem">
                                {t('clear_all')}
                                </Button>
                            </div>
                            
                        </div>

                        <div className="container">
                        </div>

                    </div>
                </div>

                <TableV2
                    columns={TableColumn}
                    data={TableOFMData}
                    onStateChange={onChangePagination}
                    pageCount={totalPages}
                    page={page}
                    loading={false}
                />
            </div>

            <SidePanel isOpen={ModalViewTask} setIsOpen={setModalViewTask} width={800}>
                <ModalViewTaskDetail width={800} onClose={CloseModalViewTask} onSubmit={SubmitModalViewTask} ReportId={ReportId} ReportName={ReportName} ReportInfoFooter={ReportInfoFooter} />
            </SidePanel>
            <SidePanel isOpen={ModalEditTask} setIsOpen={setModalEditTask} width={800}>
                <ModalEditTaskDetail width={800} onClose={CloseModalEditTask} onSubmit={SubmitModalEditTask} ReportId={ReportId} ReportName={ReportName} ReportInfoFooter={ReportInfoFooter} />
            </SidePanel>

            <DialogSuccess
                open={Boolean(successModal)}
                onClose={() => setsuccessModal(false)}
                onSubmit={() => setsuccessModal(false)}
                icon={successIcon}
                title={Titlesuccess}
                subtitle={SubTitlesuccess}
                description={Descriptionsuccess}
                textYes={t('done')}
            />

            <DialogFail
                open={Boolean(failModal)}
                onClose={() => setFailModal(false)}
                onSubmit={() => setFailModal(false)}
                icon={failIcon}
                title={TitleFail}
                nameItem={nameItemFail}
                description={DescriptionFail}
                textYes={t('ok')}
            />  

            <DialogConfirmDelete
                open={Boolean(ModalConfirmDel)}
                onClose={() => setModalConfirmDel(false)}
                onSubmit={SubmitModalConfirmDel}
                title={'Delete Report Record?'}
                nameItem={ConfirmDenameItem}
                description={`Are you sure you want to delete this record?`}
                description2={`This action cannot be undone.`}
            />

            <DialogConfirmV2
                open={Boolean(ModalConfirmKeep)}
                onClose={() => setModalConfirmKeep(false)}
                onNo={() => setModalConfirmKeep(false)}
                onSubmit={SubmitModalConfirmKeep}
                icon={IconKeep}
                title={'Keep this record?'}
                nameItem={ConfirmKeepnameItem}  
                description={'Are you sure you want to keep this record?'}
                textYes={'Keep'}
                textNo={t('cancel')}
            />
        </DivMain>
    )
}

export default TabDeletionRequest