import styled from 'styled-components'
import React, { useCallback, useEffect, useState,useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createMeetingRoom } from '../../redux/actions/dailysummary'
import { getCompanyInfo,DateTimeHHmmDisplay,getAdminUser,getEmployeeInfo } from '../../utils/common'

import { getReportDetailReturnData,updateReport } from '../../redux/actions/ofm'
import { getEmployeeById,getEmployeeDropdrow } from '../../redux/actions/master'

import { selectMaster } from '../../redux/selectors'

import dayjs from 'dayjs'

import TextField from '../../components/form/TextField'
import InputTextArea from '../../components/widget/element/InputTextArea'
import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Date from '../../components/form/Date'


import RadioButton from '../../components/form/RadioButton'
import AutoComplete from '../../components/form/AutoComplete'


import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import iconwaning from '../../assets/images/warning-icon.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconBin from '../../assets/images/icon-bin.svg'
import iconFile from '../../assets/images/icon-file-round.svg'

import FileUpload from '../../components/form/FileUpload'
import { postAPI } from '../../utils/api'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import mapIcon from '../../assets/images/gg_map.png'

import iconPhotoUpload from '../../assets/images/photoupload-icon.png'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  } 
  
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    /*
    padding: 0px 14px;
    padding-top: 130px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    background-color: #F9FAFB;
    height: 100vh;
    */
    padding: 0px 14px;
    padding-top: 150px;
    padding-bottom: 170px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    background-color: #F9FAFB;
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }


  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill{
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .content{
    margin:10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }

  .prefix_icon{
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .report_box_form{
    border: 2px solid rgb(208, 213, 221);
    border-radius: 8px;
    padding: 16px 24px;
    margin-bottom:20px;
    background-color: #FCFCFD;
  }
  .report_formname{
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
    /*margin-bottom:16px;*/
  }
  .report_sub_value{
    /*
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    */
    margin-bottom:16px;
    margin-top:16px;
  }
  .report_sub_value_title{
    /*
    font-size: 16px;
    color: #101828;
    */
    font-size: 16px;
    color: #101828;
    font-weight: 500;
  }
  .report_sub_value_description{
    font-size: 12px;
  }
  .report_sub_value_value{
    color: #475467;
    font-size: 16px;
    font-weight: 400;
    
  }
  .TextField_report{
    width : 100%;
  }
  .value_warning{
    border: 1px solid red;
  }
  .report_img{
    display: grid;
    grid-template-columns: repeat(5, 120px);
    gap: 20px;
    margin-bottom:16px;
  }

  .cursor_pointer {
    cursor: pointer;
  }
  .img_in_row{
    width: 120px;
    border-radius: 12px;
  }

  /* ST CSS POPUP IMG */
  .dialog-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  /* Style for the dialog content */
  .dialog-img-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    position: relative;
    max-width: 1200px;
    padding: 0 15px;
  }
  /* Style for the close icon */
  .dialog-img-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0;
  }

  .dialog-img-popup-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  /* ED CSS POPUP IMG */
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .dialog-footer-report{
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .dialog-footer-report-info{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-bottom:16px;
    margin-top:16px;
  }
  .img-profile {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  .user_img_char_1 {
    border-radius: 50%;
    background-color: var(--Main-Button-Bg);
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #FFFFFF;
    cursor: pointer;
  }
  .dialog-footer-report-info-full-name{
    font-size: 18px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .dialog-footer-report-info-position{
    font-size: 16px;
    font-weight: 400;
    color: var(--Primary-700);
  }
  .dialog-footer-report-info-location{
    font-size: 16px;
    font-weight: 500;
    color: var(--Gray-600);
  }

  .button_upload {
    width: 100%;
    display: flex;
    justify-content: center;
    color: #6941C6;
    background-color: #F9F5FF;
    border: 1px solid #E9D7FE;
  }
`

const HiddenInput = styled.input.attrs({ type: 'file' })`
  display: none;
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}

const dataFortest = {
  "id": 1,
  "status": "active",
  "latitude": 210.123,
  "longitude": 210.123,
  "created_by_employee_id": 2,
  "updated_by_employee_id": null,
  "createdAt": "2023-10-15T11:11:32.011Z",
  "updatedAt": "2023-10-15T11:11:32.011Z",
  "documents": [
      {
          "id": 1,
          "document_id": "652bc8e3de2f2d5d8f4184fb",
          "form": {
              "id": 1,
              "json_schema": {
                  "type": "object",
                  "properties": {
                      "turbine_speed_t1": {
                          "title": "Turbine Speed T1",
                          "type": "integer"
                      },
                      "turbine_speed_t2": {
                          "title": "Turbine Speed T2",
                          "type": "integer"
                      },
                      "turbine_speed_t3": {
                          "title": "Turbine Speed T3",
                          "type": "integer"
                      },
                      "turbine_speed_t4": {
                          "title": "Turbine Speed T4",
                          "type": "integer"
                      },
                      "turbine_speed_t5": {
                          "title": "Turbine Speed T5",
                          "type": "integer"
                      },
                      "turbine_speed_t6": {
                          "title": "Turbine Speed T6",
                          "type": "integer"
                      }
                  }
              },
              "machine": {
                  "id": 1,
                  "name": "A"
              }
          },
          "value": {
              "_id": "652bc8e3de2f2d5d8f4184fb",
              "turbine_speed_t1": 1,
              "turbine_speed_t2": 2,
              "turbine_speed_t3": 3,
              "turbine_speed_t4": 4,
              "turbine_speed_t5": 5,
              "turbine_speed_t6": 6,
              "note": "test",
              "images": [
                  "https://2bsimplesystem.s3.ap-southeast-1.amazonaws.com/Screenshot_2566_09_30_at_23_18_44_f2de7c4475.png"
              ],
              "latitude": 210.123,
              "longitude": 210.123,
              "status": "active",
              "created_by_employee_id": 2,
              "createdAt": "2023-10-15T11:11:31.571Z"
          }
      }
  ]
}

const ModalEditTaskDetail = ({ onClose, onSubmit,ReportId,ReportName,ReportInfoFooter,...props  }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = getAdminUser()
  const employee_info = getEmployeeInfo()
  const CompanyInfo = getCompanyInfo()
  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const [HeaderReport, setHeaderReport] = useState(ReportName);
  const [HeaderDateReport, setHeaderDateReport] = useState('');
  const [ListForm, setListForm] = useState([])
  const [ReportByInfo, setReportByInfo] = useState({
    photo : null,
    first_name : '-',
    last_name : '-',
    department : '',
    position : '',   
  });
  const fetchDataAll = useCallback(
    async (filter,this_ReportId) => {
        if(this_ReportId){
            
            dispatch(getReportDetailReturnData(this_ReportId,filter)).then(({ type,data_info,data_meta }) => {
                if(type.endsWith('_SUCCESS')){
                    if(data_info){

                        //
                        setHeaderDateReport(DateTimeHHmmDisplay(data_info.createdAt));
                        
                        const newTHData = data_info.documents?.map((v, index) => {  
                            const all_properties = {
                              "FormId" : {
                                  "title": "FormId"
                              },
                              "MachineId" : {
                                  "title": "MachineId"
                              },
                              "formname" : {
                                  "title": "formname"
                              },
                              ...v.form.json_schema.properties,
                              "operator_id": {
                                "title": "พนักงานปฏิบัติงาน"
                              },
                              "note": {
                                  "title": "หมายเหตุ"
                              },
                              "images": {
                                  "title": "images"
                              },
                            }

                            return Object.keys(all_properties).map(key => {
                              
                              
                              let type_input = 'TextField';
                              let optionlist = [];
                              if(key == 'note'){
                                type_input = 'InputTextArea';
                              }else if(key == 'operator_id'){
                                type_input = 'DropdrowEmployee';
                              }else if(all_properties[key].format){
                                if(all_properties[key].format == 'date'){
                                  type_input = 'InputDatePicker';
                                }
                              }else if(all_properties[key].enum){
                                if(all_properties[key].enum.length == 2){
                                  optionlist = all_properties[key].enum?.map((v, index) => ({
                                    value: v,
                                    text: v,
                                  }))
                                  type_input = 'InputRadio';
                                }
                              }
                              if(key == 'FormId'){
                                return {
                                    key : null,
                                    title : 'FormId',
                                    description : null,
                                    value: v.form['id'],
                                    typeinput : type_input
                                }
                              }else if(key == 'MachineId'){
                                  const this_Machine_name = v.form.machine['name'];
                                  return {
                                      key : null,
                                      title : 'MachineId',
                                      description : null,
                                      value: this_Machine_name,
                                      typeinput : type_input
                                  }
                              }else if(key == 'formname'){
                                const this_form_name = v.form.json_schema['title'] ? v.form.json_schema['title'] : '-';
                                return {
                                    key : null,
                                    title : 'formname',
                                    description : null,
                                    value: this_form_name,
                                    typeinput : type_input
                                }
                              }else{
                                  const minimum = all_properties[key]?.minimum ? all_properties[key]?.minimum : null;
                                  const maximum = all_properties[key]?.maximum ? all_properties[key]?.maximum : null;
                                  const real_value = v.value[`${key}`];
                                  let is_warning = false;
                                  if(minimum && maximum){
                                    if(parseFloat(real_value) < parseFloat(minimum) || parseFloat(real_value) > parseFloat(maximum)){
                                      is_warning = true;
                                    }
                                  }
                                  return {
                                      key : key,
                                      title : all_properties[key].title,
                                      description : all_properties[key].description,
                                      value: real_value,
                                      typeinput : type_input,
                                      is_warning : is_warning,
                                      minimum : minimum,
                                      maximum : maximum,
                                      unit : all_properties[key].unit ? all_properties[key].unit : null,
                                      optionlist : optionlist
                                  }
                              }

                            });
                        });  
                        //
                        //
                        
                        let list_all_form = [];
                        for(const item_newTHData of newTHData){
                            //
                            //
                            list_all_form.push(item_newTHData);
                        }
                        
                        console.table(list_all_form);
                        setListForm(list_all_form);

                        dispatch(getEmployeeById(data_info.created_by_employee_id)).then(({ type,data_info }) => {
                          if(type.endsWith('_SUCCESS')){
                            
                            
                            setReportByInfo({
                              photo : data_info.photo_images[0]?.url ? data_info.photo_images[0].url : null,
                              first_name : data_info.first_name,
                              last_name : data_info.last_name,
                              department : data_info.department?.data?.attributes?.name ? data_info.department?.data?.attributes?.name : null,
                              position : data_info.position?.data?.attributes?.name ? data_info.position?.data?.attributes?.name : null,   
                            })
                          }
                        })
                    }
                }
            })
        }

    },
    [dispatch]
 );

 useEffect(() => {
    
    const filter = {
        skip: 1,
        limit: 1000,
    }
    fetchDataAll(filter,ReportId)
}, [fetchDataAll,ReportId]) 

const [employeeOption, setemployeeOption] = useState([])
useEffect(() => {
  const filter = {
    skip: 1,
    limit: 10000
  }
  dispatch(getEmployeeDropdrow(filter))
}, [dispatch])
const { employeeList } = useSelector(selectMaster)
useEffect(() => {
  const newEmployee = employeeList.map((v) => ({
    text: v?.first_name + ' ' + v?.last_name,
    value: v?.id.toString(),
  }))
  setemployeeOption([...newEmployee])
}, [employeeList])


const [dialog, setDialog] = useState(false);
const [imageSrc, setImageSrc] = useState();
const toggleDialog = (url_img) => {
  //alert(url_img);
  setImageSrc(url_img);
  setDialog(true);
  
};

const ClosetoggleDialog = () => {
  setDialog(false);
  
};

const GotoEdit = () => {
  alert('GoTOEDIT');
}

const onChangeField = (index_form,index_item, e) => {
  const newState = ListForm.map((obj, index) => {
    if (index === index_form) {
      const newState_sub = obj.map((obj_sub, index_sub) => {
        if (index_sub === index_item) {
          let this_is_warning = false;
          if(obj_sub.minimum && obj_sub.maximum){
            if(parseFloat(e.target.value) < parseFloat(obj_sub.minimum) || parseFloat(e.target.value) > parseFloat(obj_sub.maximum)){
              this_is_warning = true;
            }

          }
          return { ...obj_sub, value: e.target.value , is_warning: this_is_warning }
        }
        return obj_sub
      })
      return newState_sub
    }

    return obj
  })
  console.table(newState);
  setListForm(newState);
}

const onChangeFieldEValue = (index_form,index_item, e) => {
  const newState = ListForm.map((obj, index) => {
    if (index === index_form) {
      const newState_sub = obj.map((obj_sub, index_sub) => {
        if (index_sub === index_item) {
          let this_is_warning = false;
          return { ...obj_sub, value: e , is_warning: this_is_warning }
        }
        return obj_sub
      })
      return newState_sub
    }

    return obj
  })
  console.table(newState);
  setListForm(newState);
}

const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
const ConfirmDialog = () => {
  setModalConfirmDialog(true)
}

const SaveEdit = () => {
  setModalConfirmDialog(false)
  const newState = ListForm.map((obj, index) => {
    let list_value = {};
    const form_id = obj.filter((v) => v.title == 'FormId')[0]['value'];
    //
    //
    const newState_sub = obj.filter((v) => v.key != null).map((obj_sub, index_sub) => {
      if(obj_sub.key == 'operator_id'){
        //alert(JSON.stringify( employeeOption.filter((v) => v.value == obj_sub.value) ));
        const data_employee = employeeOption.filter((v) => v.value == obj_sub.value);
        const operator_name = data_employee.length > 0 ? data_employee[0]['text'] : '';
        //alert(operator_name);
        return {
          [`${obj_sub.key}`] : obj_sub.value,
          [`operator_name`] : operator_name
        }
      }else{
        return {
          [`${obj_sub.key}`] : obj_sub.value,
        }
      }

    })
    //
    //
    for(const item_newState_sub of newState_sub){
      //
      //
      //alert(JSON.stringify(item_newState_sub))
      list_value = {...list_value , ...item_newState_sub};
    }
    const final_data = {
      id : form_id,
      value : list_value
    }
    
    
    return final_data;
  })
  //
  
  //
  
  const sent_data_api = {
    forms: newState,
  }
  
  
  
  dispatch(updateReport(ReportId, sent_data_api)).then(({ type }) => {
    if(type.endsWith('_SUCCESS')){
      setTitlesuccess('Changes Saved');
      setSubTitlesuccess('Your edits have been successfully update.');
      setDescriptionsuccess(''); 
      setsuccessModal(true)
    }else{
      setTitleFail('Update Error');
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }     
  })
  

  //onSubmit()
}

const inputFileRef = useRef(null);
const [indexUploadForm, setindexUploadForm] = useState(0);
const OpenInputFile = (index_form) => {
  setindexUploadForm(index_form);
  inputFileRef.current.click();
}

const onChangeFilePhoto = async (event) => {
  const file = event.target.files[0]
  const fetchList = []
  const formData = new FormData()
  formData.append('files', file)
  formData.append(
    'fileInfo',
    JSON.stringify({
      name: file.name,
      caption: file.name,
      alternativeText: file.name,
      folder: null,
    })
  )

  fetchList.push(
    postAPI({
      url: '/api/upload',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: null,
      },
      data: formData,
    })
  )
  const res = await Promise.all(fetchList)
  //alert(indexUploadForm);
  //alert(JSON.stringify(res[0][0].url));
  const newState = ListForm.map((obj, index) => {
    if (index === indexUploadForm) {

      const newState_sub = obj.map((obj_sub, index_sub) => {
        if (obj_sub.title === 'images') {
          return { ...obj_sub, value: [...obj_sub.value,res[0][0].url] }
        }
        return obj_sub
      })

      return newState_sub
    }

    return obj
  })
  setListForm(newState);
  //setImgPerson(res[0][0].url)
  //setNewEmployeeData({ ...NewEmployeeData, photo: res[0][0].id })
}


  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">{HeaderReport}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">
            <div>
              <div>{HeaderDateReport}</div>      
            </div>
            <div></div>
        </div>
        <hr />
      </div>
      
      <div className="dialog-content">
        {ListForm?.map((item, index) => (
            <div  key={index} className="report_box_form">
                {item.filter((v) => v.title == 'MachineId').map((item_sub, index_sub_MachineId) => (
                    <div key={index_sub_MachineId} className="report_formname">Machine Id : {item_sub.value}</div>
                ))}
                {item.filter((v) => v.title == 'formname').map((item_sub, index_sub_formname) => (
                    <div key={index_sub_formname} className="report_formname">Form : {item_sub.value}</div>
                ))}

                {item.map((item_sub, index_sub) => (                  
                  <div key={index_sub} >
                    {item_sub.title != 'FormId' && item_sub.title != 'MachineId' && item_sub.title != 'formname' && item_sub.title != 'images' && (
                      <div className="report_sub_value">
                        <div className="report_sub_value_title">{item_sub.title}</div>
                        {item_sub.description && (
                          <div className="report_sub_value_description">{item_sub.description}</div>
                        )}
                        
                        <div className="report_sub_value_value">
                          {item_sub.typeinput == 'TextField' && item_sub.unit != null  && (
                            <TextField
                              className={`TextField_report ${item_sub.is_warning ? 'value_warning' : ''}`}
                              placeholder={`${item_sub.value}`}
                              value={item_sub.value}
                              onChange={(e) => onChangeField(index,index_sub, e)}
                              endAdornment={`${item_sub.unit}`}
                            />
                          )}
                          {item_sub.typeinput == 'TextField' && item_sub.unit == null  && (
                            <TextField
                              className={`TextField_report ${item_sub.is_warning ? 'value_warning' : ''}`}
                              placeholder={`${item_sub.value}`}
                              value={item_sub.value}
                              onChange={(e) => onChangeField(index,index_sub, e)}
                            />
                          )}
                          {item_sub.typeinput == 'InputTextArea' && (
                            <InputTextArea
                              className="InputTextArea_css"
                              initValue={item_sub.value}
                              onChange={(e) => onChangeField(index,index_sub, e)}
                              placeHolder={`${item_sub.value}`}
                              borderColor={'var(--Gray-300)'}
                              borderWidth="1px"
                            />
                          )}
                          {item_sub.typeinput == 'InputDatePicker' && (
                            <Date className={'mr-1-rem'} value={item_sub.value} onChange={(e) => onChangeFieldEValue(index,index_sub, e)} />
                          )}
                          {item_sub.typeinput == 'InputRadio' && (
                            <div>
                              <RadioButton
                                name="doc_lang"
                                optionList={item_sub.optionlist}
                                value={item_sub.value}
                                handleChange={(e) => onChangeField(index,index_sub, e)}
                              />
                            </div>
                          )}  
                          {item_sub.typeinput == 'DropdrowEmployee' && (
                            <div>
                              <AutoComplete
                                placeHolder='พนักงานปฏิบัติงาน'
                                optionList={employeeOption}
                                value={item_sub.value}         
                                onChange={(e) => onChangeFieldEValue(index,index_sub, e)}                     
                              />
                            </div>

                          )}
                        </div>
                      </div>
                    )}
                  </div>



                ))} 
                
                {item.filter((v) => v.title == 'images').map((item_sub, index_sub_images) => (
                    <div key={index_sub_images} className="report_img">
                        {item_sub.value?.map((item_img, index_img) => (
                            <div  key={index_img}>
                                <div className='cursor_pointer' onClick={() => toggleDialog(item_img)}>
                                    <img className='img_in_row'  src={item_img} />
                                </div>
                            </div>
                        ))} 
                    </div>
                ))}
                <div>
                  <Button onClick={() => OpenInputFile(index)} append={<img src={iconPhotoUpload} />} className="button_upload">
                    เพิ่มรูปถ่าย
                  </Button>
                  <HiddenInput ref={inputFileRef} id="custom-file-input" onChange={onChangeFilePhoto} />
                </div>

            </div>
        ))}
      </div>
        <div className="dialog-footer">
          <hr />
          <ButtonOutline className="button_margin" onClick={onClose}>
            {t('cancel')}
          </ButtonOutline>
          <Button className="button_margin" onClick={ConfirmDialog}>
            {t('save')}
          </Button>
        </div>
      
        <DialogConfirm
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onSubmit={SaveEdit}
          icon={iconwaning}
          title={'Save Your Work'}
          description={'Your recent changes have not been saved. To keep you work, please click the "Save" button'}
          textYes={t('confirm')}
          textNo={t('cancel')}
        />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />


        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />

            {dialog && (
                <div className="dialog-img">
                    <div className="dialog-img-content">
                        <h3>OFM</h3>
                        <button className="dialog-img-close-icon" onClick={ClosetoggleDialog}>&#10005;</button>
                        <img className="dialog-img-popup-image" src={imageSrc} alt="Popup Image" />
                    </div>
                </div>
            )}
    </Div>
  )
}

export default ModalEditTaskDetail
