import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch, useSelector } from 'react-redux'
import { selectEmployeeProfile } from 'redux/selectors'
import { changePassword, verifyPassword } from 'redux/actions/employeeProfile'

import styled from 'styled-components'
import { Box } from '@mui/material'
import HeaderPage from 'components/common/HeaderPage'
import PasswordField from 'components/form/PasswordInput'
import Button from 'components/form/button/Button'
import ButtonOutline from 'components/form/button/ButtonOutline'
import DialogSuccess from 'components/dialog/DialogSuccess'
import DialogFail from 'components/dialog/DialogFail'

import unCheckIcon from 'assets/images/uncheck-icon.png'
import checkIcon from 'assets/images/check-icon.png'
import successIcon from 'assets/images/success-icon.png'
import failIcon from 'assets/images/fail-icon.png'

const Wrapper = styled.div`
  width: ${(props) => `${props.width}px`};
  .mr-1-rem {
    margin-right: 1rem;
  }
  .mb-6-px {
    margin-bottom: 6px;
  }
  .mb-12-px {
    margin-bottom: 12px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .mr-12-px {
    margin-right: 12px;
  }
  h2,
  p {
    margin: 0;
  }

  h2 {
    color: var(--Gray-900);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }

  p {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`
const InputContainer = styled.div`
  .heading-input {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 6px;
  }
  .w-100 {
    width: 100%;
  }

  .error {
    border: 1px solid var(--Error-300);
  }

  .text-danger {
    color: var(--Error-500);
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-end {
    justify-content: flex-end;
  }

  &.align-item-center {
    align-items: center;
  }
`
const ChangePasswordContainer = styled.div`
  .icon-2 {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`

const initValidateCollection = {
  isLeast8: false,
  isContainSpecialCharacter: false,
  isUpperLower: false,
  isNumber: false,
}
const initMessageModal = {
  headline: '',
  message: '',
}

function ChangePassword() {
  // external hook
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // store reducer
  const { state, isLoading, response } = useSelector(selectEmployeeProfile)

  // width window
  const [widthWindow, setWidthWindow] = useState(window.innerWidth)
  const [widthContent, setWidthContent] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidthWindow(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    const newWidth = widthWindow < 1024 ? (widthWindow * 96) / 100 : (widthWindow * 40) / 100
    setWidthContent(newWidth)
  }, [widthWindow])

  // value
  const [defaultPassword, setDefaultPassword] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  // validate
  const [isEdit, setIsEdit] = useState(false)
  const [validateCollection, setValidateCollection] = useState(initValidateCollection)
  const [passwordMatch, setPasswordMatch] = useState(true)
  // modal message
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  // modal
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)

  useEffect(() => {
    if (isLoading === false && state === 'CHANGE_PASSWORD.SUCCESS') {
      setSuccessMessageModal({
        headline: 'Change Password Successful',
        message: 'You can now log-in with new password.',
      })
      setSuccessModal(true)
    }
    if (isLoading === false && state === 'VERIFY_PASSWORD.SUCCESS' && response) {
      const { valid_password } = response
      if (valid_password === false) {
        setFailMessageModal({
          headline: 'Incorrect Password',
          message: 'Please try again',
        })
        setFailModal(true)
      } else {
        toggleEditMode()
      }
    }
  }, [state, isLoading, response])
  useEffect(() => {
    const regexAtleast8 = /^.{8,}$/
    const regexSpecialCharacter = /[^A-Za-z0-9]/
    const regexUpperLowerCase = /^(?=.*[a-z])(?=.*[A-Z]).*$/
    const regexNumber = /\d/

    setValidateCollection({
      isLeast8: regexAtleast8.test(password),
      isContainSpecialCharacter: regexSpecialCharacter.test(password),
      isUpperLower: regexUpperLowerCase.test(password),
      isNumber: regexNumber.test(password),
    })
  }, [password])
  useEffect(() => {
    if (confirmPassword !== password && confirmPassword != '') setPasswordMatch(false)
    else setPasswordMatch(true)
  }, [confirmPassword, password])

  // onChange
  const onChangeDefaultPassword = (event) => {
    const { value } = event.target
    setDefaultPassword(value)
  }
  const onChangePassword = (event) => {
    const { value } = event.target
    setPassword(value)
  }
  const onChangeConfirmPassword = (event) => {
    const { value } = event.target
    setConfirmPassword(value)
  }

  // toggle mode
  const toggleEditMode = () => {
    setIsEdit(!isEdit)
  }
  const editChangePassword = () => {
    if (defaultPassword !== '') {
      const request = {
        data: {
          password: defaultPassword,
        },
      }
      dispatch(verifyPassword(request))
    }
  }
  const updateChangePassword = () => {
    if (
      passwordMatch &&
      validateCollection.isLeast8 &&
      validateCollection.isNumber &&
      validateCollection.isUpperLower &&
      validateCollection.isContainSpecialCharacter
    ) {
      const request = {
        data: {
          password,
        },
      }
      dispatch(changePassword(request))

      toggleEditMode()
      resetDefaultPassword()
    }
  }
  const cancelEditChangePassword = () => {
    toggleEditMode()
    resetDefaultPassword()
  }
  const resetDefaultPassword = () => {
    setDefaultPassword('')
    setPassword('')
    setConfirmPassword('')
  }

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <HeaderPage heading={'เปลี่ยนรหัสผ่าน'} subHeading={'ตั้งค่าเปลี่ยนรหัสผ่าน'} />
      </Box>
      <Wrapper width={widthContent}>
        <h2>Password</h2>
        <p className="mb-20-px">Please enter your current password to change your password.</p>

        <Divider className="mb-24-px" />

        {!isEdit ? (
          <InputContainer className="mb-24-px">
            <div className="heading-input">Current Password</div>
            <PasswordField className="w-100" value={defaultPassword} onChange={onChangeDefaultPassword} />
          </InputContainer>
        ) : (
          <ChangePasswordContainer className="mb-20-px">
            <InputContainer className="mb-24-px">
              <div className="heading-input">New Password</div>
              <PasswordField className="w-100" value={password} onChange={onChangePassword} />
            </InputContainer>

            <FlexContainer className="align-item-center mb-12-px">
              {validateCollection.isLeast8 ? (
                <img className="icon-2 mr-1-rem" src={checkIcon} />
              ) : (
                <img className="icon-2 mr-1-rem" src={unCheckIcon} />
              )}
              {t('isLeast8')}
            </FlexContainer>
            <FlexContainer className="align-item-center mb-12-px">
              {validateCollection.isContainSpecialCharacter ? (
                <img className="icon-2 mr-1-rem" src={checkIcon} />
              ) : (
                <img className="icon-2 mr-1-rem" src={unCheckIcon} />
              )}
              {t('must_contain_one_special_character')}
            </FlexContainer>
            <FlexContainer className="align-item-center mb-12-px">
              {validateCollection.isUpperLower ? (
                <img className="icon-2 mr-1-rem" src={checkIcon} />
              ) : (
                <img className="icon-2 mr-1-rem" src={unCheckIcon} />
              )}
              {t('uppercase_letter_and_lowercase_letter')}
            </FlexContainer>
            <FlexContainer className="align-item-center mb-20-px">
              {validateCollection.isNumber ? (
                <img className="icon-2 mr-1-rem" src={checkIcon} />
              ) : (
                <img className="icon-2 mr-1-rem" src={unCheckIcon} />
              )}
              {t('must_contain_at_least_1_number')}
            </FlexContainer>

            <InputContainer className="mb-24-px">
              <div className="heading-input">Confirm New Password</div>
              <PasswordField
                className={`w-100 ${!passwordMatch ? 'error' : ''}`}
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
              />
              {!passwordMatch && <p className="text-danger">{t('the_password_entered_does_not_match')}</p>}
            </InputContainer>
          </ChangePasswordContainer>
        )}

        <Divider className="mb-20-px" />

        <FlexContainer className="justify-content-end">
          <ButtonOutline className="mr-12-px" onClick={cancelEditChangePassword}>
            {t('cancel')}
          </ButtonOutline>
          {isEdit ? (
            <Button onClick={updateChangePassword}>Update Password</Button>
          ) : (
            <Button onClick={editChangePassword}>{t('next')}</Button>
          )}
        </FlexContainer>

        <DialogSuccess
          open={Boolean(successModal)}
          onClose={() => setSuccessModal(false)}
          onSubmit={() => setSuccessModal(false)}
          icon={successIcon}
          title={successMessageModal.headline}
          description={successMessageModal.message}
          textYes={t('done')}
        />
        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={failMessageModal.headline}
          description={failMessageModal.message}
          textYes={t('ok')}
        />
      </Wrapper>
    </Box>
  )
}

export default ChangePassword
