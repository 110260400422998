import { combineReducers } from 'redux'
import {
  GET_COUNTRIES,
  GET_BANKS,
  GET_NATIONALITIES,
  GET_DEPARTMENT_AND_POSITION,
  GET_PROVINCES,
  GET_DISTRICTS,
  GET_SUB_DISTRICTS,
  UPLOAD_FILE,
  GET_MASTER_EMPLOYEE,
  GET_MASTER_EMPLOYEE_2,
  GET_IMAGE,
  SET_IS_REDIRECT,
  GET_COMPANY_INFO_BY_ID,
} from '../actionTypes'

const initialState = {
  companyInfo: {},
  countries: [],
  banks: [],
  nationalities: [],
  departments: [],
  provinces: [],
  districts: [],
  subdistricts: [],
  file: {},
  isLoading: false,
  employeeList: [],
  employeeList2: [],
  total: 0,
  skip: 0,
  limit: 10,
  page: 1,
  pageCount: 1,
  pageSize: 10,
  totalEmployee: 1,
  imageList: [],
  isRedirect: null,
}
const masterReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_COUNTRIES.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_COUNTRIES.SUCCESS: {
      const { data } = action
      return {
        ...state,
        countries: data,
        isLoading: false,
      }
    }
    case GET_COUNTRIES.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_BANKS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_BANKS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        banks: data,
        isLoading: false,
      }
    }
    case GET_BANKS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_NATIONALITIES.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_NATIONALITIES.SUCCESS: {
      const { data } = action
      return {
        ...state,
        nationalities: data,
        isLoading: false,
      }
    }
    case GET_NATIONALITIES.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_DEPARTMENT_AND_POSITION.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DEPARTMENT_AND_POSITION.SUCCESS: {
      const { data } = action
      return {
        ...state,
        departments: data,
        isLoading: false,
      }
    }
    case GET_DEPARTMENT_AND_POSITION.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PROVINCES.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PROVINCES.SUCCESS: {
      const { data } = action
      return {
        ...state,
        provinces: data,
        isLoading: false,
      }
    }
    case GET_PROVINCES.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_DISTRICTS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DISTRICTS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        districts: data,
        isLoading: false,
      }
    }
    case GET_DISTRICTS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_SUB_DISTRICTS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_SUB_DISTRICTS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        subdistricts: data,
        isLoading: false,
      }
    }
    case GET_SUB_DISTRICTS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPLOAD_FILE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPLOAD_FILE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPLOAD_FILE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case GET_MASTER_EMPLOYEE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MASTER_EMPLOYEE.SUCCESS: {
      const { data, meta, filter } = action
      return {
        ...state,
        employeeList: data,
        total: meta.pagination.total,
        skip: filter.skip,
        limit: filter.limit,
        isLoading: false,
      }
    }
    case GET_MASTER_EMPLOYEE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MASTER_EMPLOYEE_2.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MASTER_EMPLOYEE_2.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        employeeList2: data,
        page: meta?.pagination?.page,
        pageCount: meta?.pagination?.pageCount,
        pageSize: meta?.pagination?.pageSize,
        totalEmployee: meta?.pagination?.total,
        isLoading: false,
      }
    }
    case GET_MASTER_EMPLOYEE_2.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_IMAGE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_IMAGE.SUCCESS: {
      const { data } = action
      return {
        ...state,
        imageList: data,
        isLoading: false,
      }
    }
    case GET_IMAGE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case SET_IS_REDIRECT: {
      const { data } = action
      return {
        ...state,
        isRedirect: data,
      }
    }
    case GET_COMPANY_INFO_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_COMPANY_INFO_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        companyInfo: data,
        isLoading: false,
      }
    }
    case GET_COMPANY_INFO_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  masterReducer,
})
