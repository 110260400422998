import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateDisplay } from 'utils/common'
import { useForm, Controller } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'
import { selectDownloadReport } from 'redux/selectors'
import { getReports, generateReport } from 'redux/actions/downloadReport'

import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import HeaderPage from 'components/common/HeaderPage'
import Badge from 'components/common/Badge'
import Divider from 'components/common/Divider'
import Button from 'components/form/button/Button'
import Dropdown from 'components/form/Dropdown'
import Date from 'components/form/Date'
import Table from 'components/common/Table'
import ButtonOutline from 'components/form/button/ButtonOutline'

const Div = styled.div`
  .error {
    margin: 0;
    font-size: 0.875rem;
    color: var(--Error-500);
  }
`
const TableWrapper = styled.div`
  .link {
    font-weight: bold;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .icon {
    cursor: pointer;
  }

  .table-header {
    padding: 1.25rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: var(--Base-White);
    border: 1px solid var(--Gray-200);
  }

  .input-filter-heading {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .total-heading {
    color: var(--Gray-900);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
`

function DownloadReport() {
  // external hook
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // initiate data
  const fetchReports = useCallback(
    (filter) => {
      dispatch(getReports(filter))
    },
    [dispatch]
  )
  useEffect(() => {
    fetchReports()
  }, [])

  // table data
  const reportColumns = [
    // {
    //   Header: t('no'),
    //   accessor: 'no',
    //   disableSortBy: false,
    // },
    {
      Header: t('topics'),
      accessor: 'topic',
      disableSortBy: false,
    },
    {
      Header: t('start_date'),
      accessor: 'start_date',
      disableSortBy: false,
    },
    {
      Header: t('end_date'),
      accessor: 'end_date',
      disableSortBy: false,
    },
    {
      Header: t('created_date'),
      accessor: 'createdAt',
      disableSortBy: false,
    },
    {
      Header: t('created_by'),
      accessor: 'created_by',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
  ]
  const { reports, total, page, pageCount, pageSize } = useSelector(selectDownloadReport)
  const [reportList, setReportList] = useState([])

  const downloadReport = (url) => {
    window.open(url, '_blank')
  }
  const refreshReportData = () => {
    fetchReports()
  }

  useEffect(() => {
    const newReports = reports.map((v, i) => ({
      ...v,
      // no: i + 1,
      start_date: DateDisplay(v.start_date),
      end_date: DateDisplay(v.end_date),
      createdAt: DateDisplay(v.createdAt),
      created_by: `${v.creator.first_name} ${v.creator.last_name}`,
      status:
        v.status === 'download' ? <Button onClick={() => downloadReport(v.download_url)}>Download</Button> : v.status,
    }))
    setReportList(newReports)
  }, [reports])

  // form
  const initialForm = {
    topic: '',
    start_date: '',
    end_date: '',
  }
  const { control, handleSubmit, watch, setValue, reset } = useForm({
    defaultValues: initialForm,
  })

  const onSubmit = (data) => {
    const { employee_info } = JSON.parse(localStorage.getItem('admin'))
    const request = {
      ...data,
      creator: employee_info?.id,
    }

    dispatch(generateReport(request))
  }
  const onError = (errors) => {
    console.log(errors)
  }

  // filter
  const initialDropdown = [
    {
      text: 'Employee Activities Report',
      value: 'Employee Activities Report',
    },
  ]

  const onChangePagination = (value) => {
    const filters = {
      page: value.page,
      pageSize: pageSize,
    }
    fetchReports(filters)
  }

  return (
    <Div>
      <Box sx={{ mb: 3 }}>
        <HeaderPage heading="Download Report" subHeading="" />
      </Box>

      <TableWrapper>
        <div className="table-header">
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1}>
              <div className="total-heading">Report</div>
              <Badge>{total} reports</Badge>
            </Stack>
          </Stack>
          <Box sx={{ my: 2 }}>
            <Divider />
          </Box>

          <Box>
            <form onSubmit={handleSubmit(onSubmit, onError)}>
              <Stack sx={{ mb: 1 }} direction="row" alignItems="flex-end" spacing={1}>
                <Box sx={{ width: '20%' }}>
                  <div className="input-filter-heading">หัวข้อ</div>
                  <Controller
                    name="topic"
                    control={control}
                    rules={{ required: 'กรุณาเลือกหัวข้อ' }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <>
                          <Dropdown
                            optionList={initialDropdown}
                            value={field.value}
                            onChange={field.onChange}
                            error={error}
                          />
                        </>
                      )
                    }}
                  />
                </Box>
                <Box sx={{ width: '20%' }}>
                  <div className="input-filter-heading">Start date</div>
                  <Controller
                    name="start_date"
                    control={control}
                    rules={{ required: 'กรุณาเลือก Start date' }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <>
                          <Date value={field.value} onChange={field.onChange} error={error} />
                        </>
                      )
                    }}
                  />
                </Box>
                <Box sx={{ width: '20%' }}>
                  <div className="input-filter-heading">End date</div>
                  <Controller
                    name="end_date"
                    control={control}
                    rules={{ required: 'กรุณาเลือก End date' }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <>
                          <Date value={field.value} onChange={field.onChange} error={error} />
                        </>
                      )
                    }}
                  />
                </Box>
                <Button type="submit">Generate</Button>
                <ButtonOutline onClick={refreshReportData}>Refresh</ButtonOutline>
              </Stack>
              <Stack sx={{ mb: 1 }} direction="row" alignItems="flex-end" spacing={1}>
                <Box sx={{ width: '20%' }}>
                  <Controller
                    name="topic"
                    control={control}
                    rules={{ required: 'กรุณาเลือกหัวข้อ' }}
                    render={({ field, fieldState: { error } }) => {
                      return <>{error && <p className="error">{error.message}</p>}</>
                    }}
                  />
                </Box>
                <Box sx={{ width: '20%' }}>
                  <Controller
                    name="start_date"
                    control={control}
                    rules={{ required: 'กรุณาเลือก Start date' }}
                    render={({ field, fieldState: { error } }) => {
                      return <>{error && <p className="error">{error.message}</p>}</>
                    }}
                  />
                </Box>
                <Box sx={{ width: '20%' }}>
                  <Controller
                    name="end_date"
                    control={control}
                    rules={{ required: 'กรุณาเลือก End date' }}
                    render={({ field, fieldState: { error } }) => {
                      return <>{error && <p className="error">{error.message}</p>}</>
                    }}
                  />
                </Box>
              </Stack>
            </form>
          </Box>
        </div>

        <Table
          columns={reportColumns}
          data={reportList}
          onStateChange={onChangePagination}
          page={page}
          pageCount={pageCount}
        />
      </TableWrapper>
    </Div>
  )
}

export default DownloadReport
