import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import { DateTimeHHmmDisplay } from '../../utils/common'
import { getCompanyInfo } from '../../utils/common'

import { getDepartmentsAndPositionsUseAuthen } from '../../redux/actions/master'

import {
  getPositions,
  getPositionsDropdown,
  getPositionById,
  getPositionByIdReturnData,
  getSowByDepartment,
  createPosition,
  updatePosition,
  deletePositionStatus,
} from '../../redux/actions/jobPosition'
import { selectJobPosition, selectMaster } from '../../redux/selectors'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import SearchText from '../../components/form/SearchText'
import Table from '../../components/common/Table'
import SidePanel from '../../components/common/SidePanel'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import CollapseMenu from '../../components/common/CollapseMenu'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import DialogConfirm from '../../components/dialog/DialogConfirm'
import DialogSuccess from '../../components/dialog/DialogSuccess'

import { GiCancel } from 'react-icons/gi'
import { BiEdit } from 'react-icons/bi'
import { LiaTimesSolid } from 'react-icons/lia'
import successIcon from '../../assets/images/success-icon.png'
import { useTranslation } from 'react-i18next'
const Div = styled.div`
  .button-ml {
    margin-left: 250px;
  }
  .tab-container {
    display: flex;

    .tab-item {
      cursor: pointer;
      margin-right: 2rem;
      padding-bottom: 1rem;
      &.active {
        color: #7f56d9;
        font-weight: 600;
        border-bottom: 2px solid #7f56d9;
      }
    }
  }
  .button-footer-company-profile {
    margin-bottom: 20rem;
  }
  .button-footer {
    margin-bottom: 35rem;
  }
  .h-full {
    height: 100%;
  }
  .left-profile {
    margin-left: 0px;
  }
  .left {
    margin-left: 260px;
  }
  .right {
    margin-right: 230px;
  }
  .col-12 {
    width: 100%;
  }
  .input-col {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .icon {
    cursor: pointer;
  }
  .divider {
    margin: 2rem 0;
    border: 1px solid var(--Gray-200, #eaecf0);
  }
  .create-access-role-header {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .side-panel-container {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      font-size: 20px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .side-panel-container-2 {
    padding: 2rem;

    .header {
      font-size: 20px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .d-flex {
    display: flex;
  }
  .row {
    flex-direction: row;
  }
  .justify-content-flex-start {
    justify-content: flex-start;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .align-items-center {
    align-items: center;
  }
  .content-header {
    margin-top: 24px;
  }
  .float-end {
    float: right;
  }

  .float-start {
    float: left;
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .mt-50 {
    margin-top: 50px;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .w-100 {
    width: 100%;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .date-filter-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .drop-down {
    min-width: 10rem;
  }

  .task-report-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .task-box {
    border: 1px solid black;
    width: 100%;
    margin: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
  }

  .task-box .amount-tasks {
    font-weight: bold;
    text-align: center;
    color: black;
    font-size: 20px;
  }

  .filter-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .wrp-filter-menu {
      position: relative;
      display: flex;
    }

    .wrp-filter-btn {
      position: relative;
    }

    .dialog-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 300px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);
      max-height: 300px;

      .wrp-person-list {
        position: relative;
      }
      .person-list {
        height: 220px;
        overflow-y: scroll;
      }

      .person-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 4px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
        }

        .img-profile {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          background-color: var(--Gray-200);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .person-item:hover {
        background-color: var(--Gray-50);
      }
    }

    .filter-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 500px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);

      .wrp-filter-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clear-btn {
          padding: 4px 6px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        .clear-btn:hover {
          background: var(--Primary-25);
        }
      }
      .wrp-filter-option {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .title-option {
          font-weight: 600;
          margin-bottom: 6px;
        }
        .option-item-style {
          border: 1px solid var(--Gray-300);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Gray-50);
        }
        .option-item-style:hover {
          background-color: var(--Gray-100);
        }
        .option-item-active {
          border: 1px solid var(--Primary-600);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Primary-600);
          color: #fff;
        }
      }
    }
  }

  .wrp-filter-menu {
    display: flex;
    align-items: center;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header-2 {
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .ml-auto {
    margin-left: auto;
  }
  .ml-col {
    margin: auto;
    weight: 100%;
  }
  .text-area {
    width: 100%;
    height: 8rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error-input {
    border: 1px solid red;
  }

  .error {
    color: red;
  }

  .search-header {
    display: flex;
    justify-content: space-between;

    .group {
      display: flex;
      align-items: center;
    }
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .input-header-2 {
    display: flex;
    justify-content: space-between;

    .group {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;
      align-content: center;
      column-gap: 1rem;
    }

    .input-width {
      width: 600px;
    }
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }
  .input-new-2 {
    display: grid;
    grid-template-columns: repeat(1, 1fr) 80px;
    grid-template-rows: auto;
    align-content: center;
  }

  .input-header {
    color: var(--gray-700);
    font-size: 14px;
    font-weight: 500;
  }

  .mr-8-px {
    margin-right: 8px;
  }
`

const initStatusDropdown = [
  {
    value: 'active',
    text: 'Active',
  },
  {
    value: 'inactive',
    text: 'Inactive',
  },
]

const initSowForm = [
  {
    key: uuidv4(),
    sowId: '',
  },
]

const initValidateForm = {
  name: null,
  name_th: null,
  responsibility: null,
  ref_position_id: null,
  sow: [{ key: uuidv4(), sowId: null }],
}

const initMessageModal = {
  headline: '',
  message: '',
}

function JobPosition({ id, department_name }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const CompanyInfo = getCompanyInfo()
  const initPositionForm = {
    company: CompanyInfo?.id ? CompanyInfo?.id : null,
    name: '',
    name_th: '',
    responsibility: '',
    department: '',
    owner: '',
    parents: '',
    task_scopes: [],
    lv: 1,
    ref_position_id: null,
    ref_other_departments_id: null,
    ref_other_position_id: null,
  }

  const jobPositionFormColumn = [
    {
      Header: t('position_id'),
      accessor: 'position_id',
      disableSortBy: false,
    },
    {
      Header: t('job_position_name'),
      accessor: 'position_name',
      disableSortBy: false,
    },
    {
      Header: t('job_position_name_th'),
      accessor: 'position_name_th',
      disableSortBy: false,
    },
    {
      Header: t('position_level'),
      accessor: 'level',
      disableSortBy: false,
    },
    {
      Header: t('under_position'),
      accessor: 'under_position',
      disableSortBy: false,
    },
    {
      Header: t('created_date'),
      accessor: 'created_date',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('action'),
      accessor: 'action',
      disableSortBy: false,
    },
  ]

  // data
  const { positions, positionsDropdown, positionInfo, sows, total, page, limit, pageCount, isLoading, state } =
    useSelector(selectJobPosition)
  const [positionForm, setPositionForm] = useState(initPositionForm)
  const [positionEditForm, setPositionEditForm] = useState(initPositionForm)

  const [positionEditFormV2, setPositionEditFormV2] = useState({
    id: null,
    name: '',
    name_th: '',
    responsibility: '',
    parents: '',
    task_scopes: [],
    status: null,
    lv: 1,
    ref_position_id: null,
    ref_other_departments_id: null,
    ref_other_position_id: null,
  })
  const [positionId, setPositionId] = useState()
  const [statusSelected, setStatusSelected] = useState('')
  const [jobPositionFormList, setJobPositionFormList] = useState([])
  const [sowForm, setSowForm] = useState(initSowForm)
  const [sowEditForm, setSowEditForm] = useState(initSowForm)
  // search
  const [search, setSearch] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  // dropdown
  const [sowList, setSowList] = useState([])
  const [underPositionList, setUnderPositionList] = useState([])
  // side panel
  const [isOpenSidepanelJobPosition, setIsOpenSidepanelJobPosition] = useState(false)
  const [isEditOpenSidepanelJobPosition, setIsEditOpenSidepanelJobPosition] = useState(false)
  const [isOpenSidepanelTableJobPosition, setIsOpenSidepanelTableJobPosition] = useState(false)
  // modal
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [confirmCreateJobPositionModal, setConfirmCreateJobPositionModal] = useState(false)
  const [confirmUpdateJobPositionModal, setConfirmUpdateJobPositionModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  // modal message
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  // validate
  const [errors, setErrors] = useState(initValidateForm)

  // New Parameter 04092023
  const [master_departmentList, setmaster_departmentList] = useState([])
  const [master_PositionList, setmaster_PositionList] = useState([])

  useEffect(() => {
    dispatch(getDepartmentsAndPositionsUseAuthen())
  }, [dispatch])

  const { departments: master_departments } = useSelector(selectMaster)

  useEffect(() => {
    const newList = master_departments.map((v) => ({
      text: v?.name,
      value: v?.id,
      lv: v?.lv,
      positions: v?.positions,
    }))
    setmaster_departmentList(newList)
  }, [master_departments])

  const [tabMenu, setTabMenu] = useState('information')

  const fetchData = useCallback(
    (filter) => {
      dispatch(getPositions(filter))
    },
    [dispatch]
  )

  const fetchPositionDropdown = useCallback(
    (filter) => {
      dispatch(getPositionsDropdown(filter))
    },
    [dispatch]
  )

  const viewsJobPosition = useCallback((posId) => {
    setPositionId(posId)
    dispatch(getPositionById(posId))
    setIsOpenSidepanelTableJobPosition(true)
    setTabMenu('information')
    // connect api
  }, [])

  const editPosition = useCallback((posId) => {
    setPositionId(posId)
    /*
    dispatch(getPositionById(posId))
    const filter = {
      skip: 1,
      limit: 10000,
      name: '',
      status: '',
      departmentId: id,
      id: posId,
    }
    fetchPositionDropdown(filter)
    setIsEditOpenSidepanelJobPosition(true)
    */
    dispatch(getPositionByIdReturnData(posId)).then(({ type, data_info }) => {
      //alert(JSON.stringify(type))
      if (type.endsWith('_SUCCESS')) {
        if (data_info.id) {
          const newEditPositionForm = {
            id: data_info.id,
            name: data_info?.name,
            responsibility: data_info.responsibility,
            parents: data_info.parents,
            task_scopes: data_info.task_scopes,
            status: data_info.status,
            lv: data_info.lv,
            ref_position_id: data_info.ref_position_id,
            ref_other_departments_id: data_info.ref_other_departments_id,
            ref_other_position_id: data_info.ref_other_position_id,
          }

          setPositionEditForm(newEditPositionForm)

          if (data_info.ref_other_departments_id) {
            const departmentMatch = master_departmentList.find((v) => v.value == data_info.ref_other_departments_id)

            const newPosition = departmentMatch?.positions.map((v) => ({
              text: v.name,
              value: v.id,
            }))
            setmaster_PositionList(newPosition)
          }

          const newSowEditForm = data_info.task_scopes.map((v) => ({
            key: uuidv4(),
            sowId: v,
          }))
          setSowEditForm(newSowEditForm)

          setIsEditOpenSidepanelJobPosition(true)
        }
      }
    })
  }, [])

  const deletePosition = useCallback((posId) => {
    setPositionId(posId)
    setConfirmDeleteModal(true)
  }, [])

  useEffect(() => {
    if (state == 'CREATE_POSITION.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: t('success_create_job_position_message'),
        message: '',
      })
      setSuccessModal(true)
    }
    if (state == 'UPDATE_POSITION.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: t('success_update_job_position_message'),
        message: '',
      })
      setSuccessModal(true)
    }
    if (state == 'DELETE_POSITION.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: t('success_delete_job_position_message'),
        message: '',
      })
      setSuccessModal(true)
    }
  }, [state, isLoading])

  useEffect(() => {
    const filter = {
      skip: 1,
      limit: limit,
      filters: searchFilters.map((v) => v.name),
      status: statusSelected,
      departmentId: id,
    }
    fetchData(filter)
  }, [fetchData, limit, id, statusSelected])

  useEffect(() => {
    dispatch(getSowByDepartment(id))
  }, [dispatch, id])

  useEffect(() => {
    const newPositionUnderList = positionsDropdown.map((v) => ({
      value: v.id,
      text: v.name,
      lv: v.lv,
    }))
    setUnderPositionList(newPositionUnderList)
  }, [positionsDropdown])
  /*
  useEffect(() => {
    if (positionInfo?.name) {
      const newEditPositionForm = {
        id: positionInfo.id,
        name: positionInfo?.name,
        responsibility: positionInfo.responsibility,
        parents: positionInfo.parents,
        task_scopes: positionInfo.task_scopes,
        status: positionInfo.status,
        lv : positionInfo.lv,
        ref_position_id : positionInfo.ref_position_id,
        ref_other_departments_id : positionInfo.ref_other_departments_id,
        ref_other_position_id : positionInfo.ref_other_position_id,
      }
      setPositionEditForm(newEditPositionForm)

      if(positionInfo.ref_other_departments_id){
        const departmentMatch = master_departmentList.find((v) => v.value == positionInfo.ref_other_departments_id)

        const newPosition = departmentMatch?.positions.map((v) => ({
          text: v.name,
          value: v.id,
        }))
        setmaster_PositionList(newPosition)
      }

      const newSowEditForm = positionInfo.task_scopes.map((v) => ({
        key: uuidv4(),
        sowId: v,
      }))
      setSowEditForm(newSowEditForm)
    }
  }, [positionInfo])
  */

  useEffect(() => {
    const newSows = sows.map((v) => ({
      text: v.scope_work_name,
      value: v.id,
    }))
    setSowList(newSows)
  }, [sows])

  useEffect(() => {
    const newPositionFormList = positions.map((v) => {
      const list = [
        {
          text: 'Edit',
          action: () => editPositionNewVersion(v.id, 'edit'),
        },
        {
          text: 'Delete',
          action: () => deletePosition(v.id),
        },
      ]
      return {
        ...v,
        position_name: (
          <div className="text-bold link" onClick={() => editPositionNewVersion(v.id, 'view')}>
            {v.name}
          </div>
        ),
        position_name_th: <div>{v.name_th}</div>,
        level: v.lv ? v.lv : '-',
        under_position: v.ref_position_name ? v.ref_position_name : '-',
        created_date: DateTimeHHmmDisplay(v.createdAt),
        action: <CollapseMenu list={list} />,
      }
    })
    setJobPositionFormList(newPositionFormList)
  }, [positions, editPosition, deletePosition, viewsJobPosition])

  const editPositionNewVersion = (posId, type_open) => {
    setIsOpenSidepanelTableJobPosition(false)
    setIsEditOpenSidepanelJobPosition(false)
    setPositionId(posId)
    const filter = {
      skip: 1,
      limit: 10000,
      name: '',
      status: '',
      departmentId: id,
      id: posId,
    }
    fetchPositionDropdown(filter)

    //----แบบ Call Api
    dispatch(getPositionByIdReturnData(posId)).then(({ type, data_info }) => {
      if (type.endsWith('_SUCCESS')) {
        if (data_info.id) {
          if (data_info.ref_other_departments_id) {
            const departmentMatch = master_departmentList.find((v) => v.value == data_info.ref_other_departments_id)

            const newPosition = departmentMatch?.positions.map((v) => ({
              text: v.name,
              value: v.id,
            }))
            setmaster_PositionList(newPosition)
          }

          const newSowEditForm = data_info.task_scopes.map((v) => ({
            key: uuidv4(),
            sowId: v,
          }))
          setSowEditForm(newSowEditForm)

          const newEditPositionForm = {
            id: data_info.id,
            name: data_info?.name,
            name_th: data_info?.name_th,
            responsibility: data_info.responsibility,
            parents: data_info.parents,
            task_scopes: data_info.task_scopes,
            status: data_info.status,
            lv: data_info.lv,
            ref_position_id: data_info.ref_position_id,
            ref_other_departments_id: data_info.ref_other_departments_id,
            ref_other_position_id: data_info.ref_other_position_id,
          }
          setPositionEditFormV2(newEditPositionForm)

          if (type_open == 'view') {
            setIsOpenSidepanelTableJobPosition(true)
          } else {
            setIsEditOpenSidepanelJobPosition(true)
          }
        }
      }
    })
  }

  // onChange
  const onChangePositionForm = (event) => {
    const { name, value } = event.target
    setPositionForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const onChangeDropdownPositionForm = (name, value) => {
    if (name == 'ref_other_departments_id') {
      const departmentMatch = master_departmentList.find((v) => v.value == value)

      const newPosition = departmentMatch?.positions.map((v) => ({
        text: v.name,
        value: v.id,
      }))
      setmaster_PositionList(newPosition)

      setPositionForm((prev) => ({
        ...prev,
        [name]: value,
      }))
    } else if (name == 'ref_position_id') {
      //alert(v_inp)
      const PositionMatch = underPositionList.find((v) => v.value == value)

      setPositionForm((prev) => ({
        ...prev,
        [name]: value,
        lv: PositionMatch ? parseInt(PositionMatch.lv) + 1 : 0,
      }))
    } else {
      setPositionForm((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  const onChangeSow = (key, value) => {
    let newSowForm = [...sowForm]
    const indexMatch = sowForm.findIndex((v) => v.key == key)
    newSowForm[indexMatch]['sowId'] = value
    setSowForm(newSowForm)
  }

  const onChangePositionEditForm = (event) => {
    /*
    const { name, value } = event.target
    setPositionEditForm((prev) => ({
      ...prev,
      [name]: value,
    }))
    */
    const { name, value } = event.target
    setPositionEditFormV2((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const onChangeDropdownPositionEditForm = (name, value) => {
    if (name == 'ref_other_departments_id') {
      if (value) {
        const departmentMatch = master_departmentList.find((v) => v.value == value)

        const newPosition = departmentMatch?.positions.map((v) => ({
          text: v.name,
          value: v.id,
        }))
        setmaster_PositionList(newPosition)
        setPositionEditFormV2((prev) => ({
          ...prev,
          [name]: value,
        }))
      }
    } else if (name == 'ref_position_id') {
      if (value) {
        const PositionMatch = underPositionList.find((v) => v.value == value)
        setPositionEditFormV2((prev) => ({
          ...prev,
          [name]: value,
          lv: PositionMatch ? parseInt(PositionMatch.lv) + 1 : 0,
        }))
      }
    } else {
      if (value) {
        setPositionEditFormV2((prev) => ({
          ...prev,
          [name]: value,
        }))
      }
    }
  }

  const onChangeSowEdit = (key, value) => {
    let newSowForm = [...sowEditForm]
    const indexMatch = sowEditForm.findIndex((v) => v.key == key)
    newSowForm[indexMatch]['sowId'] = value
    setSowEditForm(newSowForm)
  }

  const addSow = () => {
    const newSow = {
      key: uuidv4(),
      sowId: '',
    }
    const newSowForm = [...sowForm, newSow]
    setSowForm(newSowForm)
  }

  const deleteSow = (key) => {
    const newSowForm = sowForm.filter((v) => v.key !== key)
    setSowForm(newSowForm)
  }

  const addSowEdit = () => {
    const newSow = {
      key: uuidv4(),
      sowId: '',
    }
    const newSowForm = [...sowEditForm, newSow]
    setSowEditForm(newSowForm)
  }

  const deleteSowEdit = (key) => {
    const newSowForm = sowEditForm.filter((v) => v.key !== key)
    setSowEditForm(newSowForm)
  }

  // modal
  const openSidepanelJobPosition = () => {
    const filter = {
      skip: 1,
      limit: 10000,
      name: '',
      status: '',
      departmentId: id,
      id: '',
    }
    fetchPositionDropdown(filter)
    setIsOpenSidepanelJobPosition(true)
  }

  const cancelSidepanelJobPosition = () => {
    setIsOpenSidepanelJobPosition(false)
    setErrors(initValidateForm)
    clearForm()
  }

  const cancelSidepanelTableJobPosition = () => {
    setIsOpenSidepanelTableJobPosition(false)
    setTabMenu('information')
  }

  const cancelEditSidepanelJobPosition = () => {
    setIsEditOpenSidepanelJobPosition(false)
    setTabMenu('information')
  }

  const openConfirmCreateJobPosition = () => {
    const isValid = validateForm()
    if (isValid) setConfirmCreateJobPositionModal(true)
  }

  const openConfirmUpdateJobPosition = () => {
    const isValid = validateEditForm()
    if (isValid) setConfirmUpdateJobPositionModal(true)
  }

  const confirmSuccess = () => {
    setSuccessModal(false)
  }

  // submit
  const onSubmitCreatePosition = () => {
    const { user } = JSON.parse(localStorage.getItem('admin'))
    const request = {
      data: {
        ...positionForm,
        department: id,
        owner: user.id,
        parents: positionForm.parents == '' ? {} : positionForm.parents,
        task_scopes: sowForm.map((v) => v.sowId),
      },
    }

    dispatch(createPosition(request, id)).then(({ data_result, type }) => {
      if (type.endsWith('_SUCCESS')) {
        setIsOpenSidepanelJobPosition(false)
        setConfirmCreateJobPositionModal(false)
        clearForm()
      }
    })
    /*
    dispatch(createPosition(request, id))
    setIsOpenSidepanelJobPosition(false)
    setConfirmCreateJobPositionModal(false)
    clearForm()
    */
  }

  const onSubmitEditPosition = () => {
    const request = {
      data: {
        name: positionEditFormV2?.name,
        name_th: positionEditFormV2?.name_th,
        responsibility: positionEditFormV2.responsibility,
        parents: positionEditFormV2.parents == '' ? {} : positionEditFormV2.parents,
        task_scopes: sowEditForm.map((v) => v.sowId),
        status: positionEditFormV2?.status,
        lv: positionEditFormV2?.lv,
        ref_position_id: positionEditFormV2?.ref_position_id,
        ref_other_departments_id: positionEditFormV2?.ref_other_departments_id,
        ref_other_position_id: positionEditFormV2?.ref_other_position_id,
      },
    }
    dispatch(updatePosition(positionEditFormV2.id, id, request)).then(({ data_result, type }) => {
      if (type.endsWith('_SUCCESS')) {
        setIsEditOpenSidepanelJobPosition(false)
        setConfirmUpdateJobPositionModal(false)
        clearForm()
      }
    })
    /*
    dispatch(updatePosition(positionEditForm.id, id, request))

    setIsEditOpenSidepanelJobPosition(false)
    setConfirmUpdateJobPositionModal(false)
    clearForm()
    */
  }

  const confirmDelete = () => {
    const request = {
      data: {
        status: 'delete',
      },
    }

    dispatch(deletePositionStatus(positionId, id, request))
    setConfirmDeleteModal(false)
  }

  // request
  const onChangePagination = async (value) => {
    const filter = {
      skip: value.page,
      limit: limit,
      filters: searchFilters.map((v) => v.name),
      status: statusSelected,
      departmentId: id,
    }
    fetchData(filter)
  }

  const onSearch = () => {
    const newFilter = {
      key: uuidv4(),
      name: search,
    }
    const filter = {
      skip: 1,
      limit: limit,
      filters: [...searchFilters, newFilter].map((v) => v.name),
      status: statusSelected,
      departmentId: id,
    }
    fetchData(filter)
    setSearch('')
    setSearchFilters((prev) => [...prev, newFilter])
  }

  const onClearSearch = () => {
    const filter = {
      skip: 1,
      limit: limit,
      filters: [],
      status: '',
      departmentId: id,
    }
    fetchData(filter)
    setSearch('')
    setStatusSelected('')
    setSearchFilters([])
  }

  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)

    const filter = {
      skip: 1,
      limit: limit,
      filters: newSearchFilters.map((v) => v.name),
      status: statusSelected,
      departmentId: id,
    }
    fetchData(filter)
    setSearch('')
  }

  // optional
  const clearForm = () => {
    const initForm = {
      name: '',
      responsibility: '',
      department: '',
      owner: '',
      parents: '',
      task_scopes: [],
      lv: 1,
      ref_position_id: null,
      ref_other_departments_id: null,
      ref_other_position_id: null,
    }
    const initSow = [
      {
        key: uuidv4(),
        sowId: '',
      },
    ]
    setPositionForm(initForm)
    setSowForm(initSow)
    setPositionEditForm(initForm)
    setSowEditForm(initSow)
  }

  const validateForm = () => {
    let newErrors = {
      ...initValidateForm,
    }

    if (positionForm?.name == '') {
      newErrors.name = 'Job Name is required'
    }
    if (positionForm?.name_th == '') {
      newErrors.name_th = 'Job Name (TH) is required'
    }
    if (positionForm?.responsibility == '') {
      newErrors.responsibility = 'Responsibility is required'
    }
    /* //--- ปิด By Jame 16/11/2023 ตาม TASK 278
    if (underPositionList.length > 0) {
      if (positionForm?.ref_position_id == null) {
        newErrors.ref_position_id = 'Under position is required'
      }
    } else {
      newErrors.ref_position_id = null
    }
    */

    if (sowForm.length > 0) {
      const validateSowForm = sowForm.map((v) => ({
        key: v.key,
        sowId: v.sowId == '' ? 'Please select Scope of work' : null,
      }))
      newErrors.sow = validateSowForm
    }

    setErrors(newErrors)
    const { sow, ...restProperties } = newErrors
    return Object.values(restProperties).every((x) => x === null) && sow.every((x) => x.sowId === null)
  }

  const validateEditForm = () => {
    let newErrors = {
      ...initValidateForm,
    }

    if (positionEditFormV2?.name == '') {
      newErrors.name = 'Job Name is required'
    }
    if (positionEditFormV2?.responsibility == '') {
      newErrors.responsibility = 'Responsibility is required'
    }

    if (positionEditFormV2.lv > 1) {
      if (positionEditFormV2?.ref_position_id == null) {
        newErrors.ref_position_id = 'Under position is required'
      }
    } else {
      newErrors.ref_position_id = null
    }

    if (sowEditForm.length > 0) {
      const validateSowForm = sowEditForm.map((v) => ({
        key: v.key,
        sowId: v.sowId == '' ? 'Please select Scope of work' : null,
      }))
      newErrors.sow = validateSowForm
    }
    setErrors(newErrors)
    const { sow, ...restProperties } = newErrors
    return Object.values(restProperties).every((x) => x === null) && sow.every((x) => x.sowId === null)
  }

  const isErrorForSowForm = (key) => {
    const errorMatch = errors.sow.find((v) => v.key == key)
    return errorMatch?.sowId
  }

  const displaySow = (taskScopes) => {
    const dataMatch = sowList.find((v) => v.value == taskScopes)
    return dataMatch?.text
  }

  return (
    <Div>
      <div className="table-wrapper">
        <div className="table-header-2">
          <div className="group mb-1-rem">
            <div className="table-title">{t('job_position')}</div>
            <div className="table-total">
              {total} {t('positions')}
            </div>
          </div>

          <div className="input-header-2 mb-1-rem">
            <div className="group">
              <SearchText
                className="input-width"
                placeholder={t('search_for_job_position')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSubmit={onSearch}
                onClear={onClearSearch}
              />
              <Dropdown
                className="mr-1-rem"
                optionList={initStatusDropdown}
                value={statusSelected}
                onChange={(value) => setStatusSelected(value)}
              />
              <ButtonOutline className="d-flex justify-content-center" onClick={onClearSearch}>
                {t('clear')}
              </ButtonOutline>
            </div>
            <Button className="ml-auto" onClick={openSidepanelJobPosition}>
              {t('new_job_position')}
            </Button>
          </div>
          <div className="badge-search-content">
            {searchFilters.map((v) => (
              <div className="badge" key={v.key}>
                <span className="name">{v.name}</span>{' '}
                <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
              </div>
            ))}
          </div>
        </div>

        <Table
          columns={jobPositionFormColumn}
          data={jobPositionFormList}
          onStateChange={onChangePagination}
          pageCount={pageCount}
          page={page}
        />
      </div>

      {/* modal */}
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={confirmDelete}
        title={t('confrim_delete')}
        description={t('confirm_delete_position_sub_header')}
      />
      <DialogConfirm
        open={Boolean(confirmCreateJobPositionModal)}
        onClose={() => setConfirmCreateJobPositionModal(false)}
        onSubmit={onSubmitCreatePosition}
        title={t('confirm_create_job_position_header')}
        description={positionForm?.name}
        textYes={t('confirm')}
        textNo={t('back')}
      />
      <DialogConfirm
        open={Boolean(confirmUpdateJobPositionModal)}
        onClose={() => setConfirmUpdateJobPositionModal(false)}
        onSubmit={onSubmitEditPosition}
        title={t('confirm_update_job_position_header')}
        description={positionEditForm?.name}
        textYes={t('confirm')}
        textNo={t('back')}
      />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />

      {/* side panel */}
      <SidePanel isOpen={isOpenSidepanelJobPosition} setIsOpen={setIsOpenSidepanelJobPosition} width={600}>
        <Div>
          <div className="side-panel-container">
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="header">{t('create_new_position')}</div>
                <LiaTimesSolid size={25} onClick={cancelSidepanelJobPosition} className="icon" />
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">
                  {t('department')} : {department_name}
                </div>
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('job_position_name')}</div>
                <div className="input-new">
                  <TextField
                    className={`${errors.name == null ? '' : 'error-input'}`}
                    value={positionForm?.name}
                    onChange={onChangePositionForm}
                    name="name"
                    placeholder={t('job_position_name')}
                  />
                  {errors.name && <p className="error">{errors.name}</p>}
                </div>
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('job_position_name_th')}</div>
                <div className="input-new">
                  <TextField
                    className={`${errors.name_th == null ? '' : 'error-input'}`}
                    value={positionForm?.name_th}
                    onChange={onChangePositionForm}
                    name="name_th"
                    placeholder={t('job_position_name_th')}
                  />
                  {errors.name_th && <p className="error">{errors.name_th}</p>}
                </div>
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('responsibility')}</div>
                <div className="input-new">
                  <textarea
                    className={`text-area ${errors.responsibility == null ? '' : 'error-input'}`}
                    value={positionForm.responsibility}
                    onChange={onChangePositionForm}
                    name="responsibility"
                    placeholder={t('description')}
                  ></textarea>
                  {errors.responsibility && <p className="error">{errors.responsibility}</p>}
                </div>
              </div>
              {sowForm.map((v, i) => (
                <>
                  <div className="mb-1-rem" key={v.key}>
                    <div className="input-header mb-1-rem">{i == 0 ? 'Scope of work' : ''}</div>
                    <div className="input-new-2">
                      <Dropdown
                        className={`${isErrorForSowForm(v.key) == null ? '' : 'error-input'} mr-8-px`}
                        optionList={sowList}
                        value={v.sowId}
                        onChange={(value) => onChangeSow(v.key, value)}
                      />
                      {i !== 0 && <Button onClick={() => deleteSow(v.key)}>{t('delete')}</Button>}
                    </div>
                  </div>
                  {isErrorForSowForm(v.key) != null && <p className="error">{isErrorForSowForm(v.key)}</p>}
                </>
              ))}

              <div className="mb-1-rem">
                <div className="d-flex justify-content-flex-start align-items-center">
                  <Button onClick={addSow}>{t('add')}</Button>
                </div>
              </div>
              {underPositionList.length > 0 && (
                <div className="mb-1-rem">
                  <div className="input-header mb-1-rem">{t('under_position')}</div>
                  <div className="input-new">
                    <Dropdown
                      optionList={underPositionList}
                      className={`${errors.ref_position_id == null ? '' : 'error-input'}`}
                      value={positionForm.ref_position_id}
                      onChange={(value) => onChangeDropdownPositionForm('ref_position_id', value)}
                      ValueplaceHolderIsNull={true}
                    />
                    {errors.ref_position_id && <div className="error">{errors.ref_position_id}</div>}
                  </div>
                </div>
              )}
              <hr />
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">
                  {t('is_this_position_under_other_position_in_other_department')}
                </div>
              </div>
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('under_other_department')}</div>
                <div className="input-new">
                  <Dropdown
                    optionList={master_departmentList}
                    value={positionForm.ref_other_departments_id}
                    onChange={(value) => onChangeDropdownPositionForm('ref_other_departments_id', value)}
                    ValueplaceHolderIsNull={true}
                  />
                </div>
              </div>
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('under_which_position')}</div>
                <div className="input-new">
                  <Dropdown
                    optionList={master_PositionList}
                    value={positionForm.ref_other_position_id}
                    onChange={(value) => onChangeDropdownPositionForm('ref_other_position_id', value)}
                    ValueplaceHolderIsNull={true}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="divider"></div>
              <div className="d-flex justify-content-flex-end">
                <ButtonOutline onClick={cancelSidepanelJobPosition} className="mr-1-rem">
                  {t('cancel')}
                </ButtonOutline>
                <Button onClick={openConfirmCreateJobPosition}>{t('create')}</Button>
              </div>
            </div>
          </div>
        </Div>
      </SidePanel>

      <SidePanel isOpen={isEditOpenSidepanelJobPosition} setIsOpen={setIsEditOpenSidepanelJobPosition} width={600}>
        <Div>
          <div className="side-panel-container">
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2-rem">
                <div className="header">{t('edit_job_position')}</div>
                <LiaTimesSolid size={25} onClick={cancelEditSidepanelJobPosition} className="icon" />
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('job_position_name')}</div>
                <div className="input-new">
                  <TextField
                    className={`${errors.name == null ? '' : 'error-input'}`}
                    value={positionEditFormV2?.name}
                    onChange={onChangePositionEditForm}
                    name="name"
                    placeholder={t('job_position_name')}
                  />
                  {errors.name && <p className="error">{errors.name}</p>}
                </div>
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('job_position_name_th')}</div>
                <div className="input-new">
                  <TextField
                    className={`${errors.name_th == null ? '' : 'error-input'}`}
                    value={positionEditFormV2?.name_th}
                    onChange={onChangePositionEditForm}
                    name="name_th"
                    placeholder={t('job_position_name_th')}
                  />
                  {errors.name_th && <p className="error">{errors.name_th}</p>}
                </div>
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('responsibility')}</div>
                <div className="input-new">
                  <textarea
                    className={`text-area ${errors.responsibility == null ? '' : 'error-input'}`}
                    value={positionEditFormV2.responsibility}
                    onChange={onChangePositionEditForm}
                    name="responsibility"
                    placeholder={t('description')}
                  ></textarea>
                  {errors.responsibility && <p className="error">{errors.responsibility}</p>}
                </div>
              </div>

              {sowEditForm.map((v, i) => (
                <>
                  <div className="mb-1-rem" key={v.key}>
                    <div className="input-header mb-1-rem">{i == 0 ? 'Scope of work' : ''}</div>
                    <div className="input-new-2">
                      <Dropdown
                        className={`${isErrorForSowForm(v.key) == null ? '' : 'error-input'} mr-8-px`}
                        optionList={sowList}
                        value={v.sowId}
                        onChange={(value) => onChangeSowEdit(v.key, value)}
                      />
                      {i !== 0 && <Button onClick={() => deleteSowEdit(v.key)}>{t('delete')}</Button>}
                    </div>
                    {isErrorForSowForm(v.key) != null && <p className="error">{isErrorForSowForm(v.key)}</p>}
                  </div>
                </>
              ))}
              <div className="mb-1-rem">
                <div className="d-flex justify-content-flex-start align-items-center">
                  <Button onClick={addSowEdit}>{t('add')}</Button>
                </div>
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('under_position')}</div>
                <div className="input-new">
                  <Dropdown
                    optionList={underPositionList}
                    value={positionEditFormV2.ref_position_id}
                    onChange={(value) => onChangeDropdownPositionEditForm('ref_position_id', value)}
                    ValueplaceHolderIsNull={true}
                  />
                </div>
              </div>

              <hr />
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">
                  {t('is_this_position_under_other_position_in_other_department')}
                </div>
              </div>
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('under_other_department')}</div>
                <div className="input-new">
                  <Dropdown
                    optionList={master_departmentList}
                    value={positionEditFormV2.ref_other_departments_id}
                    onChange={(value) => onChangeDropdownPositionEditForm('ref_other_departments_id', value)}
                    ValueplaceHolderIsNull={true}
                  />
                </div>
              </div>
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('under_which_position')}</div>
                <div className="input-new">
                  <Dropdown
                    optionList={master_PositionList}
                    value={positionEditFormV2.ref_other_position_id}
                    onChange={(value) => onChangeDropdownPositionEditForm('ref_other_position_id', value)}
                    ValueplaceHolderIsNull={true}
                  />
                </div>
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('status')}</div>
                <div className="input-new">
                  <Dropdown
                    name="status"
                    optionList={initStatusDropdown}
                    value={positionEditFormV2?.status}
                    onChange={(value) => onChangeDropdownPositionEditForm('status', value)}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="divider"></div>
              <div className="d-flex justify-content-flex-end">
                <ButtonOutline onClick={cancelEditSidepanelJobPosition} className="mr-1-rem">
                  {t('cancel')}
                </ButtonOutline>
                <Button onClick={openConfirmUpdateJobPosition}>{t('save')}</Button>
              </div>
            </div>
          </div>
        </Div>
      </SidePanel>

      <SidePanel isOpen={isOpenSidepanelTableJobPosition} setIsOpen={setIsOpenSidepanelTableJobPosition} width={600}>
        <Div>
          <div className="side-panel-container-2">
            <div className="d-flex justify-content-between align-items-center mb-2-rem">
              <div className="header">{t('job_position')}</div>
              <LiaTimesSolid size={25} onClick={cancelSidepanelTableJobPosition} className="icon" />
            </div>

            <div className="mb-1-rem">
              <ButtonOutline className="mr-8-px">...</ButtonOutline>
              <Button onClick={() => editPositionNewVersion(positionId, 'edit')}>{t('Edit')}</Button>
            </div>

            <div className="mb-1-rem">
              <div className="input-header mb-1-rem">{t('job_position_name')}</div>
              <div className="input-new">
                <TextField
                  value={positionEditFormV2?.name}
                  name="name"
                  placeholder={t('job_position_name')}
                  disabled={true}
                />
              </div>
            </div>

            <div className="mb-1-rem">
              <div className="input-header mb-1-rem">{t('job_position_name_th')}</div>
              <div className="input-new">
                <TextField
                  value={positionEditFormV2?.name_th}
                  name="name_th"
                  placeholder={t('job_position_name_th')}
                  disabled={true}
                />
              </div>
            </div>

            <div className="mb-1-rem">
              <div className="input-header mb-1-rem">{t('responsibility')}</div>
              <div className="input-new">
                <textarea
                  className={`text-area`}
                  value={positionEditFormV2.responsibility}
                  name="responsibility"
                  placeholder={t('description')}
                  disabled={true}
                ></textarea>
              </div>
            </div>

            {sowEditForm.map((v, i) => (
              <>
                <div className="mb-1-rem" key={v.key}>
                  <div className="input-header mb-1-rem">{i == 0 ? 'Scope of work' : ''}</div>
                  <div className="input-new-2">
                    <Dropdown
                      className={`${isErrorForSowForm(v.key) == null ? '' : 'error-input'} mr-8-px`}
                      optionList={sowList}
                      value={v.sowId}
                      disabled={true}
                    />
                  </div>
                  {isErrorForSowForm(v.key) != null && <p className="error">{isErrorForSowForm(v.key)}</p>}
                </div>
              </>
            ))}
            <div className="mb-1-rem">
              <div className="input-header mb-1-rem">{t('under_position')}</div>
              <div className="input-new">
                <Dropdown
                  optionList={underPositionList}
                  value={positionEditFormV2.ref_position_id}
                  ValueplaceHolderIsNull={true}
                  disabled={true}
                />
              </div>
            </div>
            <hr />
            <div className="mb-1-rem">
              <div className="input-header mb-1-rem">
                Is this position under other position in other department? If yes, please select
              </div>
            </div>
            <div className="mb-1-rem">
              <div className="input-header mb-1-rem">Under other department</div>
              <div className="input-new">
                <Dropdown
                  optionList={master_departmentList}
                  value={positionEditFormV2.ref_other_departments_id}
                  ValueplaceHolderIsNull={true}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mb-1-rem">
              <div className="input-header mb-1-rem">Under which position</div>
              <div className="input-new">
                <Dropdown
                  optionList={master_PositionList}
                  value={positionEditFormV2.ref_other_position_id}
                  ValueplaceHolderIsNull={true}
                  disabled={true}
                />
              </div>
            </div>
            <div className="mb-1-rem">
              <div className="input-header mb-1-rem">Status</div>
              <div className="input-new">
                <Dropdown
                  name="status"
                  optionList={initStatusDropdown}
                  value={positionEditFormV2?.status}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </Div>
      </SidePanel>
    </Div>
  )
}

export default JobPosition
