import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_PRODUCTS,
  GET_PRODUCTS_SPECIFICATIONS,
  GET_PRODUCTS_CATEGORIES,
  GET_PRODUCTS_BRANDS,
  GET_PRODUCTS_FORMULAS,
  GET_PRODUCTS_PRICE_STRUCTERS,
  GET_PRODUCTS_PRICE_COSTS,
  GET_PRODUCT_MANAGERS,
  GET_PRODUCTS_TYPES,
  GET_PRODUCTS_SUPPLIERS,
  GET_PRODUCTS_SEGMENTS,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  CREATE_PRODUCTS_NOTE,
  GET_PRODUCTS_NOTES,
  GET_PRODUCT_BY_ID,
  UPDATE_PRODUCT,
  UPLOAD_CSV_PRODUCT,
  VIEW_PRODUCT_RELATION,
  UPDATE_PRICE_MODEL,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getProductList =
  (filter = { limit: 10, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_PRODUCTS.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const params = { ...filter }
      const { data, meta } = await getAPI({
        url: '/api/product-list',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_PRODUCTS.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({ type: GET_PRODUCTS.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getProductById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PRODUCT_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/product-list-by-id?id=${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_PRODUCT_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_PRODUCT_BY_ID.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProductSpecifications = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PRODUCTS_SPECIFICATIONS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/product-specifications',
      headers: headers,
    })

    return dispatch({
      type: GET_PRODUCTS_SPECIFICATIONS.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_PRODUCTS_SPECIFICATIONS.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProductCategories = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PRODUCTS_CATEGORIES.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/product-categories',
      headers: headers,
    })

    return dispatch({
      type: GET_PRODUCTS_CATEGORIES.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_PRODUCTS_CATEGORIES.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProductBrands = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PRODUCTS_BRANDS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/product-brands',
      headers: headers,
    })

    return dispatch({
      type: GET_PRODUCTS_BRANDS.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_PRODUCTS_BRANDS.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProductFormulas = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PRODUCTS_FORMULAS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/product-formulas',
      headers: headers,
    })

    return dispatch({
      type: GET_PRODUCTS_FORMULAS.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_PRODUCTS_FORMULAS.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProductPriceStructures = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PRODUCTS_PRICE_STRUCTERS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/product-price-structures',
      headers: headers,
    })

    return dispatch({
      type: GET_PRODUCTS_PRICE_STRUCTERS.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_PRODUCTS_PRICE_STRUCTERS.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProductCosts = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PRODUCTS_PRICE_COSTS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/product-costs',
      headers: headers,
    })

    return dispatch({
      type: GET_PRODUCTS_PRICE_COSTS.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_PRODUCTS_PRICE_COSTS.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProductManagers = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PRODUCT_MANAGERS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/product-managers',
      headers: headers,
    })

    return dispatch({
      type: GET_PRODUCT_MANAGERS.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_PRODUCT_MANAGERS.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProductTypes = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PRODUCTS_TYPES.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/product-types',
      headers: headers,
    })

    return dispatch({
      type: GET_PRODUCTS_TYPES.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_PRODUCTS_TYPES.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getSuppliers = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PRODUCTS_SUPPLIERS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/suppliers',
      headers: headers,
    })

    return dispatch({
      type: GET_PRODUCTS_SUPPLIERS.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_PRODUCTS_SUPPLIERS.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getSegments = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PRODUCTS_SEGMENTS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/product-sagments',
      headers: headers,
    })

    return dispatch({
      type: GET_PRODUCTS_SEGMENTS.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_PRODUCTS_SEGMENTS.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const createProduct = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_PRODUCT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/products`,
      data,
      headers: headers,
    })

    dispatch(getProductList())

    return dispatch({
      type: CREATE_PRODUCT.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Product Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_PRODUCT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateProduct = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_PRODUCT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/product-list-update`,
      data,
      headers: headers,
    })

    dispatch(getProductList())

    return dispatch({
      type: UPDATE_PRODUCT.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Product Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_PRODUCT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteProduct = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_PRODUCT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/products/${id}`,
      data,
      headers: headers,
    })

    dispatch(getProductList())

    return dispatch({
      type: DELETE_PRODUCT.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Product Successful',
    })
  } catch (err) {
    return dispatch({ type: DELETE_PRODUCT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProductNotes = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PRODUCTS_NOTES.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/product-list-notelist?id=${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_PRODUCTS_NOTES.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_PRODUCTS_NOTES.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const createProductlNote = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_PRODUCTS_NOTE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/product-notes`,
      data,
      headers: headers,
    })

    dispatch(getProductList())
    dispatch(getProductNotes(data.data.product))

    return dispatch({
      type: CREATE_PRODUCTS_NOTE.SUCCESS,
      showAlert: true,
      alertMessage: 'Create note successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_PRODUCTS_NOTE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const uploadCSVProduct = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPLOAD_CSV_PRODUCT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/product-list-product-upload-csv`,
      data,
      headers: headers,
    })

    dispatch(getProductList())

    return dispatch({
      type: UPLOAD_CSV_PRODUCT.SUCCESS,
      showAlert: true,
      alertMessage: 'Upload CSV Successful',
    })
  } catch (err) {
    return dispatch({ type: UPLOAD_CSV_PRODUCT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const viewProductRelation = (params) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: VIEW_PRODUCT_RELATION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const response = await getAPI({
      url: `/api/product-list-view-specification`,
      headers: headers,
      params,
    })

    return dispatch({
      type: VIEW_PRODUCT_RELATION.SUCCESS,
      data: response,
    })
  } catch (error) {
    return dispatch({ type: VIEW_PRODUCT_RELATION.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const updatePriceModel = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_PRICE_MODEL.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/price-models`,
      headers: headers,
      data,
    })

    dispatch(getProductList())

    return dispatch({
      type: UPDATE_PRICE_MODEL.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Price model successful',
    })
  } catch (error) {
    return dispatch({ type: UPDATE_PRICE_MODEL.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}
