import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import styled from 'styled-components'
import Popper from '@mui/material/Popper'
import dayjs from 'dayjs'

const StyledPopper = styled(Popper)`
  z-index: 2000;

  .MuiPaper-root {
    border: 1px solid var(--Gray-300);
    background: var(--Base-White);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  * {
    /*font-family: 'Inter';*/
    font-family: inherit;

    &.Mui-selected {
      background: var(--Primary-600) !important;
    }
  }

  .MuiTouchRipple-root {
    display: none;
  }
`

const Div = styled.div`
  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    display: flex;
    align-items: center;
    padding: 10px 18px !important;
    width: 100%;
    min-width: 0;
    height: 40px;
    /*font-family: 'Inter';*/
    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: var(--Gray-500);
    border: 1px solid ${({ $error }) => ($error ? 'red' : 'var(--Gray-300)')};
    background: var(--Base-White);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    cursor: pointer;

    .MuiInputBase-input {
      flex: 1;
      padding: 0 !important;
    }

    .MuiInputAdornment-root {
      .MuiButtonBase-root {
        background: none !important;
      }
    }
  }

  .MuiTouchRipple-root,
  fieldset {
    display: none;
  }
`

const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';

const Date = ({ value, error, disabled, onChange, className, ...props }) => {
  const onChangeDate = (v) => {
    onChange(dayjs(v.$d).locale(lang).format('YYYY-MM-DD'))
  }

  return (
    <Div className={className} $error={error}>
      <DesktopDatePicker
        format="YYYY-MM-DD"
        value={dayjs(value).locale(lang)}
        onChange={onChangeDate}
        disabled={disabled}
        slots={{
          popper: StyledPopper,
        }}
        {...props}
      />
    </Div>
  )
}

export default Date
