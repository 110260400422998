import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import SidePanel from '../../../components/common/SidePanel'
import Table from '../../../components/common/Table'
import Checkbox from '../../../components/form/Checkbox'
import SearchText from '../../../components/form/SearchText'
import Button from '../../../components/form/button/Button'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import DocumentRequestApprove from '../../../components/widget/combo/DocumentRequestApprove'
import DocumentShareAndSendEmail from '../../../components/widget/combo/DoucumentShareAndSendEmail'
import CapsuleStatus from '../../../components/widget/element/CapsuleStatus'
import DocumentOptionButton from '../../../components/widget/element/DocumentOptionButton'
import DropdownSelect from '../../../components/widget/element/DropDownSelect'
import ModalBox from '../../../components/widget/element/ModalBox'
import { getBusinessDocumentList } from '../../../redux/actions/document'
import { selectDocument } from '../../../redux/selectors'
import { convertDateFormat, convertNumberToCommaTwodecimal } from '../../../utils/helper'
import DialogDocument from '../DialogDocument'

export default function SaleDocument() {
  const [searchParams, setSearchParams] = useSearchParams()
  const docType = searchParams.get('doctype')
  const lastEditList = [
    {
      text: 'Today',
      value: 'today',
    },
    {
      text: 'Last 7 days',
      value: 'last7',
    },
    {
      text: 'Last 30 days',
      value: 'last30',
    },
  ]
  const HEADER_COLUMN = [
    {
      // Header: <Checkbox checked={isSelectAll} onChange={(e) => handleHeaderTableChecked(e)} />,
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'Detail',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'Customer',
      accessor: 'customer',
      disableSortBy: false,
    },
    {
      Header: 'Net total',
      accessor: 'net_total',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'edit_quotation',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const HEADER_COLUMN_DEBIT_NOTE = [
    {
      // Header: <Checkbox checked={isSelectAll} onChange={(e) => handleHeaderTableChecked(e)} />,
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'Customer id',
      accessor: 'customer_id',
      disableSortBy: false,
    },
    {
      Header: 'Customer',
      accessor: 'customer',
      disableSortBy: false,
    },
    {
      Header: 'Seller Employee',
      accessor: 'seller_employee',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const HEADER_COLUMN_PLEDGE_PRODUCT_CERTIFICATION = [
    {
      // Header: <Checkbox checked={isSelectAll} onChange={(e) => handleHeaderTableChecked(e)} />,
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'ชนิดสินค้าจำนำ',
      accessor: 'product',
      disableSortBy: false,
    },
    {
      Header: 'ธนาคารที่จำนำ',
      accessor: 'bank',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: '',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  // MOCKUP_DEBIT_NOTE -----
  const MOCKUP_DEBIT_NOTE_LIST = [
    {
      id: 999,
      doc_id: 'DN2024010004',
      created_date: '2024-01-18T10:39:33.509Z',
      customer: 'Customer A',
      customer_id: 'CUSTOMER_A',
      seller_employee: 'Online-สำนักงานกลาง',
      status: 'approved',
    },
    {
      id: 998,
      doc_id: 'DN2024010003',
      created_date: '2024-01-15T09:34:14.757Z',
      customer: 'สมชาย',
      customer_id: 'SOMCHAI',
      seller_employee: 'ป๋อง ลิงลม',
      status: 'wait_approve',
    },
  ]
  // MOCKUP_DEBIT_NOTE -----
  const MOCKUP_PLEDGE_PRODUCT_CERTIFICATION_LIST = [
    {
      id: 1001,
      doc_id: 'PCR2024010004',
      created_date: '2024-01-18T10:39:33.509Z',
      product: 'น้ำตาลทรายดิน',
      bank: 'ธนาคารกรุงเทพ',
      status: 'approved',
    },
    {
      id: 1000,
      doc_id: 'PCR2024010003',
      created_date: '2024-01-15T09:34:14.757Z',
      product: 'กากน้ำตาล',
      bank: 'ธนาคารเพื่อการเกษตร',
      status: 'wait_approve',
    },
  ]
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [bodyTableList, setBodyTableList] = useState([])
  const [bodyDataList, setBodyDataList] = useState([])
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [isSetData, setIsSetData] = useState(false)

  const { documentList, isLoading, pageSize, page, pageCount } = useSelector(selectDocument)

  const [filter, setFilter] = useState({
    lastEdit: '',
    searchText: '',
    docType: docType,
  })

  // const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const [saleDocSelected, setSaleDocSelected] = useState()
  const [saleDocType, setSaleDocType] = useState('')
  const [isShowDialog, setIsShowDialog] = useState(false)
  const [isShowModalDownload, setIsShowModalDownload] = useState(false)
  const [isShowDialogRequestApprove, setIsShowDialogRequestApprove] = useState(false)
  const [isShowModalShare, setIsShowModalShare] = useState(false)

  const [isHindModalShare, setIsHindModalShare] = useState(false)
  const [docTitle, setDocTitle] = useState('')

  useEffect(() => {
    if (docType) {
      setFilter({ ...filter, docType: docType })
      titleByDocType(docType)
    }
  }, [docType])

  useEffect(() => {
    ;(async () => {
      dispatch(
        getBusinessDocumentList(
          {
            page: currentPage,
            pageSize: 10,
          },
          filter
        )
      )
    })()
  }, [filter])

  useEffect(() => {
    if (documentList?.length >= 0) {
      let tmpArray = [...documentList]

      for (let i = 0; i < tmpArray.length; i++) {
        tmpArray[i].checked = false
      }
      setBodyDataList(tmpArray) // Keep Original Data
      setIsSetData(false)
    }
  }, [documentList])

  useEffect(() => {
    if (!isSetData) {
      const tmpList = [...bodyDataList]
      if (tmpList?.length >= 0) {
        let viewList = []
        if (docType == 'debit_note') {
          viewList = tmpList.map((item, index) => ({
            checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
            no: index + 1,
            date: convertDateFormat(item?.doc_date, 'DD MMM YYYY'),
            doc_id: (
              <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
                {item?.doc_id || '-'}
              </span>
            ),
            customer_id: item?.customer_id || '-',
            customer: item?.customer || '-',
            seller_employee: item?.seller_employee || '-',
            status: <CapsuleStatus status={getStatusDoc(item.status)} />,
            action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
          }))
        } else if (docType == 'pledge_product_certification_request') {
          viewList = tmpList.map((item, index) => ({
            checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
            no: index + 1,
            date: convertDateFormat(item?.doc_date, 'DD MMM YYYY'),
            doc_id: (
              <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
                {item?.doc_id || '-'}
              </span>
            ),
            product: item?.product || '-',
            bank: item?.bank || '-',
            status: <CapsuleStatus status={getStatusDoc(item.status)} />,
            action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
          }))
        } else {
          viewList = tmpList.map((item, index) => ({
            checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
            no: index + 1,
            date: convertDateFormat(item?.doc_date, 'DD MMM YYYY'),
            doc_id: (
              <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
                {item?.doc_id || '-'}
              </span>
            ),
            detail: item?.details || '-',
            customer: item?.customer || '-',
            net_total: convertNumberToCommaTwodecimal(item?.net_total) || '-',
            status: <CapsuleStatus status={item.status} />,
            // edit_quotation: <IconEditing onClick={() => handleClickedDocItem(item)} />,
            action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
          }))
        }
        setBodyTableList(viewList)
      }
      setIsSetData(true)
    }
  }, [bodyDataList, isSetData])

  const titleByDocType = (txtDocType) => {
    if (txtDocType == 'quotation') {
      setDocTitle('ใบเสนอราคา')
    } else if (txtDocType == 'billing') {
      setDocTitle('ใบวางบิล')
    } else if (txtDocType == 'invoice') {
      setDocTitle('ใบแจ้งหนี้')
    } else if (txtDocType == 'receipt') {
      setDocTitle('ใบเสร็จรับเงิน')
    } else if (txtDocType == 'debit_note') {
      setDocTitle('ใบเพิ่มหนี้')
    } else if (txtDocType == 'pledge_product_certification_request') {
      setDocTitle('หนังสือขอรับสินค้าจำนำ')
    }
  }

  const getStatusDoc = (status) => {
    if (status == 'approved') {
      return 'อนุมัติ'
    } else if (status == 'wait_approve') {
      return 'รออนุมัติ'
    } else {
      return status
    }
  }

  const handleClickedOption = async (type, item) => {
    setSaleDocType(type)
    setSaleDocSelected(item)

    if (type == 'preview' || type == 'print') {
      setIsShowDialog(true)
    } else if (type == 'download_pdf') {
      setIsShowModalDownload(true)
    } else if (type == 'request_approve') {
      setIsShowDialogRequestApprove(true)
    } else {
      setIsShowModalShare(true)
    }
  }

  const handleClickBtnDownloadQuotation = () => {
    // setIsDownload(true)
    setIsShowModalDownload(false)
    setIsShowDialog(true)
  }

  const handleClickSubmitRequestApproval = () => {
    dispatch(getBusinessDocumentList({ page: currentPage, pageSize: 10 }, filter))
    setIsShowDialogRequestApprove(false)
  }

  const handleClickBtnCreateNew = () => {
    navigate('/document/sales/create/?type=' + docType)
  }
  const handleHeaderTableChecked = (e) => {
    setIsSelectAll((prev) => !prev)
    const newState = bodyDataList.map((obj, index) => {
      return { ...obj, checked: e }
    })
    setBodyDataList(newState)
  }
  const handleBodyTableChecked = (isChecked, index) => {
    let tmpArray = [...bodyDataList]
    tmpArray[index].checked = isChecked
    setBodyDataList(tmpArray)
    setIsSetData(false)
    if (isChecked == false) {
      setIsSelectAll(false)
    }
  }
  const handleSearchTextChange = (e) => {
    setFilter({ ...filter, searchText: e.target.value })
  }

  const handleClickedPreview = () => {
    setIsHindModalShare(true)
    setSaleDocType('preview')
    setIsShowDialog(true)
  }

  const handleSelectFilter = (e) => {
    setFilter({ ...filter, lastEdit: e })
  }

  const handleClickedDocItem = (itemData) => {
    if (itemData?.id) {
      navigate('/document/sales/edit/?type=' + docType + '&id=' + itemData.id)
    }
  }

  const onChangePagination = async (value) => {
    const pagination = {
      page: value.page,
      pageSize: 10,
    }
    dispatch(getBusinessDocumentList(pagination, filter))
    setCurrentPage(value.page)
  }

  const handleClickClosePreview = () => {
    setIsShowDialog(false)
    setIsHindModalShare(false)
  }

  const handleDownloaded = () => {
    setIsShowDialog(false)
    setSaleDocSelected()
    setSaleDocType()
  }

  const getHeaderTable = () => {
    if (docType == 'debit_note') {
      return HEADER_COLUMN_DEBIT_NOTE
    } else if (docType == 'pledge_product_certification_request') {
      return HEADER_COLUMN_PLEDGE_PRODUCT_CERTIFICATION
    } else {
      return HEADER_COLUMN
    }
  }

  return (
    <Styled isHindModalShare={isHindModalShare}>
      {docType && (
        <div className="wrp-main-page">
          <div className="header">{docTitle}</div>

          <div className="filter-button">
            <div className="left">
              <div className="title">Last edit</div>
              <div className="filter">
                <DropdownSelect
                  className={'dropdown'}
                  dataList={lastEditList}
                  onChange={handleSelectFilter}
                  initValue={filter?.lastEdit}
                />
                <SearchText onChange={handleSearchTextChange} />
              </div>
            </div>
            <div className="right">
              <div className="btn-create-new" onClick={handleClickBtnCreateNew}>
                <Button>Create new</Button>
              </div>
            </div>
          </div>

          <div className="wrp-table">
            <Table
              columns={getHeaderTable()}
              data={bodyTableList}
              onStateChange={onChangePagination}
              pageCount={pageCount}
              page={currentPage}
              loading={isLoading}
            />
          </div>

          {isShowDialogRequestApprove && (
            <SidePanel
              isOpen={isShowDialogRequestApprove}
              onClose={() => setIsShowDialogRequestApprove(false)}
              width={900}
            >
              <DocumentRequestApprove
                docType={docType}
                onClickNavigationBack={() => setIsShowDialogRequestApprove(false)}
                id={saleDocSelected.id}
                onClickBtnSubmit={() => handleClickSubmitRequestApproval()}
              />
            </SidePanel>
          )}

          {/* preview and print */}
          {isShowDialog && (
            <DialogDocument
              onClose={() => handleClickClosePreview()}
              docType={docType}
              previewType={saleDocType}
              id={saleDocSelected.id}
              docId={saleDocSelected.doc_id} // use for Download (file_name)
              onDownloaded={() => handleDownloaded()}
            />
          )}

          {isShowModalDownload && (
            <ModalBox
              className={'modal-download'}
              onClosed={() => setIsShowModalDownload(false)}
              height="200px"
              width="450px"
              title={'Download ' + saleDocSelected?.doc_id}
              body={<div className="body">ใบเสนอราคา</div>}
              footer={
                <div className="bottom-modal">
                  <ButtonOutline className={'footer-btn btn-cancel'} onClick={() => setIsShowModalDownload(false)}>
                    Cancel
                  </ButtonOutline>
                  <Button className={'footer-btn btn-download'} onClick={handleClickBtnDownloadQuotation}>
                    Download
                  </Button>
                </div>
              }
            />
          )}

          {isShowModalShare && (
            <div className="document-share-and-send-email">
              <DocumentShareAndSendEmail
                type={'quotation'}
                data={saleDocSelected}
                onClickPreview={() => handleClickedPreview()}
                onClose={() => setIsShowModalShare(false)}
              />
            </div>
          )}
        </div>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  .wrp-main-page {
    .header {
      font-size: 30px;
      font-weight: 600;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--Gray-200);
      margin-bottom: 24px;
    }
  }
  .wrp-table {
    > div {
      overflow-x: inherit;
    }
  }
  .col-detail {
    width: 50%;
  }

  .txt-doc-item {
    cursor: pointer;
    :hover {
      color: var(--Primary-600);
    }
  }
  .filter-button {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    .left {
      .title-filter {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 6px;
      }
      .filter {
        display: flex;

        .dropdown {
          margin-right: 12px;
        }
      }
    }
    .right {
      display: flex;
      align-items: end;
    }
  }

  //Modal
  .modal-download {
    .bottom-modal {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .btn-cancel {
        width: 48%;
        justify-content: center;
      }
      .btn-download {
        width: 48%;
        justify-content: center;
      }
    }
  }

  .document-share-and-send-email {
    display: ${({ isHindModalShare }) => (isHindModalShare ? 'none' : 'block')};
  }
`
