import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next'

import GroupMenu from 'components/feature/all-service/GroupMenu'
import ToggleCompany from 'components/feature/all-service/ToggleCompany'
import { profileMenuItems } from 'utils/sideNavHelpers'

const AllServicePage = () => {
  const { t } = useTranslation()
  const myAccountItems = profileMenuItems()
  const { menu_list } = JSON.parse(localStorage.getItem('admin')) || {}
  const menuItems = [...menu_list, ...myAccountItems] || []

  const groupMenu = [
    {
      title: 'งานของฉัน',
      items: menuItems
        ?.filter((item) => item.group_menu === 'group_1' && item.key !== 'all_service')
        .map((item) => {
          if (item.key === 'new_message') {
            item.pathto = () => {
              console.log('Should be open new message drawer!')
            }
          }

          return item
        }),
    },
    {
      title: 'บริการทั้งหมด',
      items: menuItems?.filter((item) => item.group_menu === 'group_2'),
    },
    {
      title: 'บัญชีของฉัน',
      items: menuItems?.filter((item) => item.group_menu === 'myAccount_group'),
    },
  ]

  return (
    <StyledContainer className="erp-all-service-page">
      <StyledPageHeader className="erp-all-service-page-header">
        <h3 className="all-service-page-title">บริการและงานทั้งหมด</h3>

        <div className="erp-company-selection">
          <label>{t('company')}</label>
          <ToggleCompany onChange={() => {}} />
        </div>
      </StyledPageHeader>

      <StyledPageContent className="erp-all-service-page-content">
        {groupMenu.map((group, index) => (
          <GroupMenu key={index} title={group.title} items={group.items} />
        ))}
      </StyledPageContent>
    </StyledContainer>
  )
}

export default AllServicePage

const StyledPageContent = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`

const StyledPageHeader = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  color: var(--Gray-900);

  > h3.all-service-page-title {
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    margin-bottom: 0;
  }

  > .erp-company-selection {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
    justify-content: stretch;
    width: 347px;

    label {
      font-size: 14px;
      line-height: 20px;
      color: var(--Gray-700);
      font-weight: 500;
    }
  }
`

const StyledContainer = styled('div')`
  display: flex;
  flex-direction: column;

  & .erp-all-service-page {
  }
`
