import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_APPLICATION_FORMS,
  GET_APPLICATION_TYPES,
  GET_APPLICATION_REQUIRES,
  CREATE_APPLICATION_FORM,
  GET_APPLICATION_FORM_BY_ID,
  SEND_APPLICATION_FORM_SUBMISSION,
  RESEND_APPLICATION_FORM_SUBMISSION,
  UPDATE_APPLICATION_FORM,
  CHANGE_APPLICATION_FORM_STATUS,
  VERIFY_APPLICATION_FORM_TOKEN,
  CREATE_EMPLOYEE_WITH_APPLICATION_FORM,
  GET_INVITATIONS,
  CREATE_INVITATION,
  SEND_INVITATION,
  DELETE_INVITATION,
  CREATE_INVITATION_WITH_CSV,
  GET_INVITATION_VIEW,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getApplicationForms =
  (filter = { limit: 10, skip: 1 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_APPLICATION_FORMS.REQUEST })
      const { jwt,company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const params = {
        type: 'filter',
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'filters[company_id]': company_info?.id ? company_info?.id : null,
        'filters[query]': filter.filters?.length > 0 ? JSON.stringify(filter.filters) : '[]',
      }
      if (filter.app_type != '') params['filters[app_type]'] = filter.app_type
      if (filter.status != '') params['filters[status]'] = filter.status
      const { data, meta } = await getAPI({
        url: '/api/application-forms',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_APPLICATION_FORMS.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_APPLICATION_FORMS.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Application Forms List Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getInvitationLogs =
  (id, filter = { limit: 10, skip: 1 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_INVITATIONS.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const params = {
        type: 'filter',
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'filters[query]': filter.filters?.length > 0 ? JSON.stringify(filter.filters) : '[]',
        'filters[application_form]': id,
      }
      if (filter.status != '') params['filters[status]'] = filter.status
      const { data, meta } = await getAPI({
        url: '/api/invitation-logs',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_INVITATIONS.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_INVITATIONS.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Invitation List Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getApplicationTypeList = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_APPLICATION_TYPES.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: '/api/application-form-types?pagination[page]=1&pagination[pageSize]=10000',
      headers: headers,
    })

    return dispatch({
      type: GET_APPLICATION_TYPES.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_APPLICATION_TYPES.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Application Type List Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getApplicationRequireList = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_APPLICATION_REQUIRES.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: '/api/application-form-requires?pagination[page]=1&pagination[pageSize]=10000',
      headers: headers,
    })

    return dispatch({
      type: GET_APPLICATION_REQUIRES.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_APPLICATION_REQUIRES.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Application Require List Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createApplicationForm = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_APPLICATION_FORM.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/application-forms`,
      data,
      headers: headers,
    })

    dispatch(getApplicationForms())

    return dispatch({
      type: CREATE_APPLICATION_FORM.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Application Form Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_APPLICATION_FORM.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Application Form Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createInvitation = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_INVITATION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/invitation-logs`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_INVITATION.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Invitation Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_INVITATION.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Invitation Fail',
    })
  } finally {
    const { application_form } = data.data
    dispatch(getInvitationLogs(application_form))
    dispatch(hideLoading())
  }
}

export const createInvitationCsv = (application_form, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_INVITATION_WITH_CSV.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/create-invitation-csv`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_INVITATION_WITH_CSV.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Invitation Csv Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_INVITATION_WITH_CSV.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Invitation Csv Fail',
    })
  } finally {
    dispatch(getInvitationLogs(application_form))
    dispatch(hideLoading())
  }
}

export const sendInvitation = (application_form_id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: SEND_INVITATION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/send-invitaion`,
      data,
      headers: headers,
    })

    return dispatch({
      type: SEND_INVITATION.SUCCESS,
      showAlert: true,
      alertMessage: 'Send Invitation Successful',
    })
  } catch (err) {
    return dispatch({
      type: SEND_INVITATION.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Send Invitation Fail',
    })
  } finally {
    dispatch(getInvitationLogs(application_form_id))
    dispatch(hideLoading())
  }
}

export const deleteInvitation = (id, application_form_id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_INVITATION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/invitation-logs/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: DELETE_INVITATION.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Invitation Successful',
    })
  } catch (err) {
    return dispatch({
      type: DELETE_INVITATION.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Delete Invitation Fail',
    })
  } finally {
    dispatch(getInvitationLogs(application_form_id))
    dispatch(hideLoading())
  }
}

export const getInvitationView = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_INVITATION_VIEW.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/invitation-logs/${id}?type=view`,
      headers: headers,
    })

    return dispatch({
      type: GET_INVITATION_VIEW.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_INVITATION_VIEW.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Application Form By Id Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getApplicationFormById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_APPLICATION_FORM_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/application-forms/${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_APPLICATION_FORM_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_APPLICATION_FORM_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Application Form By Id Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const sendInvite = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: SEND_APPLICATION_FORM_SUBMISSION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/application-form-submissions`,
      data,
      headers: headers,
    })

    dispatch(getApplicationFormById(data?.data?.application_form))

    return dispatch({
      type: SEND_APPLICATION_FORM_SUBMISSION.SUCCESS,
      showAlert: true,
      alertMessage: 'Send Application Form Submission Successful',
    })
  } catch (err) {
    return dispatch({
      type: SEND_APPLICATION_FORM_SUBMISSION.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Send Application Form Submission Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const resendInvite = (applicationId, id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: RESEND_APPLICATION_FORM_SUBMISSION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/application-form-submissions/${id}`,
      data,
      headers: headers,
    })

    dispatch(getApplicationFormById(applicationId))

    return dispatch({
      type: RESEND_APPLICATION_FORM_SUBMISSION.SUCCESS,
      showAlert: true,
      alertMessage: 'resend Application Form Submission Successful',
    })
  } catch (err) {
    return dispatch({
      type: RESEND_APPLICATION_FORM_SUBMISSION.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'resend Application Form Submission Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateApplicationForm = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_APPLICATION_FORM.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/application-forms/${id}`,
      data,
      headers: headers,
    })

    dispatch(getApplicationForms())
    dispatch(getApplicationFormById(id))

    return dispatch({
      type: UPDATE_APPLICATION_FORM.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Aplication Form Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_APPLICATION_FORM.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update Aplication Form Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const changeApplicationStatus = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CHANGE_APPLICATION_FORM_STATUS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/application-forms/${id}`,
      data,
      headers: headers,
    })

    dispatch(getApplicationForms())

    return dispatch({
      type: CHANGE_APPLICATION_FORM_STATUS.SUCCESS,
      showAlert: true,
      alertMessage: 'Change Aplication Form Status Successful',
    })
  } catch (err) {
    return dispatch({
      type: CHANGE_APPLICATION_FORM_STATUS.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Change Aplication Form Status Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const verifyApplicationFormToken = (params) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: VERIFY_APPLICATION_FORM_TOKEN.REQUEST })
    // const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const data = await getAPI({
      url: `/api/invitation-logs?type=validate-token&token=${params.token}`,
      headers: headers,
    })

    return dispatch({
      type: VERIFY_APPLICATION_FORM_TOKEN.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: VERIFY_APPLICATION_FORM_TOKEN.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Verify Application Form Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createEmployeeWithApplicationForm = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_EMPLOYEE_WITH_APPLICATION_FORM.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }
    await postAPI({
      url: `/api/employee-custom-create`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_EMPLOYEE_WITH_APPLICATION_FORM.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Employee With Application Form Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_EMPLOYEE_WITH_APPLICATION_FORM.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Employee With Application Form Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}
