import React, { useState } from 'react'

import NotificationsAll from '../notifications/notificationsAll'
import NotificationsRead from '../notifications/notificationsRead'
import NotificationsUnread from '../notifications/notificationsUnread'

import styled from 'styled-components'

const Div = styled.div`
  .tab-container {
    display: flex;
    border-bottom: 2px solid rgb(234, 236, 240);
    width: 35%;
    .tab-item {
      cursor: pointer;
      margin-right: 2rem;
      padding-bottom: 1rem;
      padding: 10px;
      &.active {
        color: #7f56d9;
        font-weight: 600;
        border-bottom: 2px solid #7f56d9;
        background-color: rgb(127 86 217 / 13%);
        padding: 10px;
      }
    }
  }
  .wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      /*border-bottom: 0px solid #EAECF0;*/
      border-bottom: 2px solid #eaecf0 !important;
      color: #667085;
      font-size: 14px;
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      border-bottom: 2px solid var(--Primary-600) !important;
      color: #6941c6;
      background-color: #f9f5ff;
    }
  }
`

const Notification = () => {
  const [tabViewMenu, setTabViewMenu] = useState('all')
  const [menuTabList, setMenuTabList] = useState([
    {
      name: 'All',
      value: 'all',
      active_status: true,
    },
    {
      name: 'Read',
      value: 'read',
      active_status: false,
    },
    {
      name: 'Unread',
      value: 'unread',
      active_status: false,
    },
  ])
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabViewMenu(tmpList[i].value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }

  return (
    <Div>
      <div className="wrp-tab-menu">
        {menuTabList?.map((item, index) => (
          <button
            id={'this_name-' + item.name}
            key={'btn-' + index}
            className={item.active_status ? 'menu-active' : 'btn-menu-style'}
            onClick={() => handleClickTaskMenuTab(item)}
          >
            {item.name}
          </button>
        ))}
      </div>

      {tabViewMenu == 'all' ? (
        <NotificationsAll />
      ) : tabViewMenu == 'read' ? (
        <NotificationsRead />
      ) : (
        <NotificationsUnread />
      )}
    </Div>
  )
}

export default Notification
