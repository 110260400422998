import { OP } from 'redux/actionTypes'

import { initPagination } from 'constants/pagination'
import { deleteAPI, getAPI, postAPI, putAPI } from 'utils/api'
import { formatDate } from 'utils/dateHelpers'
import { hideLoading, showLoading } from '../loading'

const { GET, POST, PUT, DELETE, CLEAR } = OP

const pathname = '/api/op-sp-temps'

const milestonesParams = {
  fields: ['id'],
  populate: {
    sw: {
      fields: ['code', 'name', 'description', 'hr', 'min', 'status'],
      populate: {
        department: {
          fields: ['id', 'name'],
        },
        positions: {
          fields: ['id', 'name'],
        },
        deliveries: {
          fields: ['name', 'info', 'type'],
          populate: {
            document: {
              fields: ['type'],
            },
          },
        },
        tasks: {
          fields: ['code', 'name', 'description', 'status'],
          populate: {
            department: {
              fields: ['id'],
            },
            deliveries: {
              fields: ['name', 'info'],
            },
          },
        },
      },
    },
    reviewers: {
      fields: ['hr', 'min'],
      populate: {
        position: { fields: ['id', 'name'] },
      },
    },
    approver: {
      fields: ['hr', 'min'],
      populate: {
        position: { fields: ['id', 'name'] },
      },
    },
  },
}

export default function OPSopTpl({ headers, company_info, employee_info }) {
  const getSopTpl = (sopTplId) => ({
    list: (payload) => {
      const { page, pageSize } = payload?.pagination || initPagination
      const { REQUEST, SUCCESS, FAILURE } = GET.SOP_TPL_LIST

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            sort: ['updatedAt:desc'],
            fields: ['code', 'name', 'description', 'status', 'updatedAt'],
            pagination: { page, pageSize },
            filters: {
              company: {
                id: company_info?.id,
              },
              ...payload?.filters,
            },
            populate: {
              department: {
                fields: ['name'],
              },
            },
          }

          const response = await getAPI({
            url: `${pathname}`,
            headers,
            params,
          })

          const updatedData = response?.data?.map((item) => {
            const updatedAt = formatDate(item?.updatedAt)

            return { ...item, updatedAt }
          })

          return dispatch({ type: SUCCESS, ...response, data: updatedData })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    byId: () => {
      const { REQUEST, SUCCESS, FAILURE } = GET.SOP_TPL_BY_ID

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            fields: ['code', 'name', 'description', 'status'],
            populate: {
              company: {
                field: ['id'],
                populate: {
                  working_times: true,
                },
              },
              department: {
                fields: ['name'],
              },
              milestones: milestonesParams,
            },
          }

          const response = await getAPI({
            url: `${pathname}/${sopTplId}`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    logs: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.SOP_TPL_BY_ID_LOGS
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            pagination: { page, pageSize },
            sort: ['id:desc'],
          }

          const response = await getAPI({
            url: `${pathname}/${sopTplId}/logs`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const postSopTpl = (sopTplId) => ({
    create: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.SOP_TPL

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `${pathname}`,
            headers,
            data: {
              ...payload,
              company: {
                id: company_info?.id,
              },
            },
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    active: () => {
      const { REQUEST, SUCCESS, FAILURE } = POST.SOP_TPL_ID_ACTIVE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `${pathname}/${sopTplId}/active`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    inactive: () => {
      const { REQUEST, SUCCESS, FAILURE } = POST.SOP_TPL_ID_INACTIVE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `${pathname}/${sopTplId}/inactive`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const putSopTpl = (sopTplId) => ({
    update: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = PUT.SOP_TPL

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await putAPI({
            url: `${pathname}/${sopTplId}`,
            headers,
            data: { ...payload },
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const deleteSopTpl = (sopTplId) => {
    const { REQUEST, SUCCESS, FAILURE } = DELETE.SOP_TPL

    return async (dispatch) => {
      dispatch(showLoading())
      dispatch({ type: REQUEST })

      try {
        const response = await deleteAPI({
          url: `${pathname}/${sopTplId}`,
          headers,
        })

        return dispatch({ type: SUCCESS, ...response })
      } catch (error) {
        return dispatch({ type: FAILURE, error })
      } finally {
        dispatch(hideLoading())
      }
    }
  }

  const clearSopTpl = () => {
    return (dispatch) => {
      return dispatch({ type: CLEAR.SOP_TPL })
    }
  }

  return {
    getSopTpl,
    postSopTpl,
    putSopTpl,
    deleteSopTpl,
    clearSopTpl,
  }
}
