import { combineReducers } from 'redux'
import {
  GET_SHARED_SERVICE_TEMPLATE,
  GET_SHARED_SERVICE_TEMPLATE_BY_ID,
  CREATE_DOCUMENT_ACKNOWLEDGEMENT,
  SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT,
  GET_DOCUMENT_ACKNOWLEDGE,
  GET_DOCUMENT_ACKNOWLEDGE_BY_ID,
  DELETE_DOCUMENT_ACKNOWLEDGE,
  SET_DOCUMENT_FORM,
  SET_SHARED_SERVICE_INFO,
  SET_DOCUMENT_ACKNOWLEDGEMENT_INFO,
  SET_FLAG_DRAFT,
  GET_DOCUMENT_INVITE_LIST,
  ADD_INVITE,
  DELETE_INVITE,
  DUPLICATE_DOCUMENT_ACKNOWLEDGE,
  SEND_DOC_NOTIFICATION,
} from '../actionTypes'

const initialState = {
  sharedServiceTemplates: [],
  sharedServiceTemplateInfo: {},
  documentAcknowledgements: [],
  documentAcknowledgementInfo: {},
  sharedDocumentForm: {},
  documentInviteList: [],
  draftInfo: {
    draftId: '',
    isDraft: false,
  },
  page: 1,
  pageCount: 1,
  total: 1,
  limit: 10,
  isLoading: false,
  state: '',
}

const sharedServiceReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SHARED_SERVICE_TEMPLATE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_SHARED_SERVICE_TEMPLATE.REQUEST',
      }
    case GET_SHARED_SERVICE_TEMPLATE.SUCCESS: {
      const { data } = action
      return {
        ...state,
        sharedServiceTemplates: data,
        isLoading: false,
        state: 'GET_SHARED_SERVICE_TEMPLATE.SUCCESS',
      }
    }
    case GET_SHARED_SERVICE_TEMPLATE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_SHARED_SERVICE_TEMPLATE.FAILURE',
      }
    case GET_SHARED_SERVICE_TEMPLATE_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_SHARED_SERVICE_TEMPLATE_BY_ID.REQUEST',
      }
    case GET_SHARED_SERVICE_TEMPLATE_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        sharedServiceTemplateInfo: data,
        isLoading: false,
        state: 'GET_SHARED_SERVICE_TEMPLATE_BY_ID.SUCCESS',
      }
    }
    case GET_SHARED_SERVICE_TEMPLATE_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_SHARED_SERVICE_TEMPLATE_BY_ID.FAILURE',
      }
    case CREATE_DOCUMENT_ACKNOWLEDGEMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_DOCUMENT_ACKNOWLEDGEMENT.REQUEST',
      }
    case CREATE_DOCUMENT_ACKNOWLEDGEMENT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_DOCUMENT_ACKNOWLEDGEMENT.SUCCESS',
      }
    case CREATE_DOCUMENT_ACKNOWLEDGEMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_DOCUMENT_ACKNOWLEDGEMENT.FAILURE',
      }
    case SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT.REQUEST',
      }
    case SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        state: 'SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT.SUCCESS',
      }
    case SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'SAVE_DRAFT_DOCUMENT_ACKNOWLEDGEMENT.FAILURE',
      }
    case DUPLICATE_DOCUMENT_ACKNOWLEDGE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'DUPLICATE_DOCUMENT_ACKNOWLEDGE.REQUEST',
      }
    case DUPLICATE_DOCUMENT_ACKNOWLEDGE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        state: 'DUPLICATE_DOCUMENT_ACKNOWLEDGE.SUCCESS',
      }
    case DUPLICATE_DOCUMENT_ACKNOWLEDGE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'DUPLICATE_DOCUMENT_ACKNOWLEDGE.FAILURE',
      }
    case GET_DOCUMENT_ACKNOWLEDGE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_DOCUMENT_ACKNOWLEDGE.REQUEST',
      }
    case GET_DOCUMENT_ACKNOWLEDGE.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        documentAcknowledgements: data,
        page: meta?.pagination?.page,
        pageCount: meta?.pagination?.pageCount,
        total: meta?.pagination?.total,
        limit: meta?.pagination?.pageSize,
        isLoading: false,
        state: 'GET_DOCUMENT_ACKNOWLEDGE.SUCCESS',
      }
    }
    case GET_DOCUMENT_ACKNOWLEDGE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_DOCUMENT_ACKNOWLEDGE.FAILURE',
      }
    case GET_DOCUMENT_ACKNOWLEDGE_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_DOCUMENT_ACKNOWLEDGE_BY_ID.REQUEST',
      }
    case GET_DOCUMENT_ACKNOWLEDGE_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        documentAcknowledgementInfo: data,
        isLoading: false,
        state: 'GET_DOCUMENT_ACKNOWLEDGE_BY_ID.SUCCESS',
      }
    }
    case GET_DOCUMENT_ACKNOWLEDGE_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_DOCUMENT_ACKNOWLEDGE_BY_ID.FAILURE',
      }
    case DELETE_DOCUMENT_ACKNOWLEDGE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_DOCUMENT_ACKNOWLEDGE.REQUEST',
      }
    case DELETE_DOCUMENT_ACKNOWLEDGE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_DOCUMENT_ACKNOWLEDGE.SUCCESS',
      }
    case DELETE_DOCUMENT_ACKNOWLEDGE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_DOCUMENT_ACKNOWLEDGE.FAILURE',
      }
    case GET_DOCUMENT_INVITE_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_DOCUMENT_INVITE_LIST.REQUEST',
      }
    case GET_DOCUMENT_INVITE_LIST.SUCCESS:
      const { data, meta } = action
      return {
        ...state,
        isLoading: false,
        documentInviteList: data,
        page: meta?.pagination?.page,
        pageCount: meta?.pagination?.pageCount,
        total: meta?.pagination?.total,
        limit: meta?.pagination?.pageSize,
        state: 'GET_DOCUMENT_INVITE_LIST.SUCCESS',
      }
    case GET_DOCUMENT_INVITE_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_DOCUMENT_INVITE_LIST.FAILURE',
      }
    case SET_DOCUMENT_FORM: {
      const { data } = action
      return {
        ...state,
        sharedDocumentForm: data,
        state: 'SET_DOCUMENT_FORM',
      }
    }
    case SET_SHARED_SERVICE_INFO: {
      const { data } = action
      return {
        ...state,
        sharedServiceTemplateInfo: data,
        state: 'SET_SHARED_SERVICE_INFO',
      }
    }
    case SET_DOCUMENT_ACKNOWLEDGEMENT_INFO: {
      const { data } = action
      return {
        ...state,
        documentAcknowledgementInfo: data,
        state: 'SET_DOCUMENT_ACKNOWLEDGEMENT_INFO',
      }
    }
    case SET_FLAG_DRAFT: {
      const { data } = action
      return {
        ...state,
        draftInfo: data,
        state: 'SET_FLAG_DRAFT',
      }
    }
    case ADD_INVITE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'ADD_INVITE.REQUEST',
      }
    case ADD_INVITE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        state: 'ADD_INVITE.SUCCESS',
      }
    case ADD_INVITE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'ADD_INVITE.FAILURE',
      }
    case DELETE_INVITE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_INVITE.REQUEST',
      }
    case DELETE_INVITE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_INVITE.SUCCESS',
      }
    case DELETE_INVITE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_INVITE.FAILURE',
      }
    case SEND_DOC_NOTIFICATION.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'SEND_DOC_NOTIFICATION.REQUEST',
      }
    case SEND_DOC_NOTIFICATION.SUCCESS:
      return {
        ...state,
        isLoading: false,
        state: 'SEND_DOC_NOTIFICATION.SUCCESS',
      }
    case SEND_DOC_NOTIFICATION.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'SEND_DOC_NOTIFICATION.FAILURE',
      }
    default:
      return state
  }
}

export default combineReducers({
  sharedServiceReducer,
})
