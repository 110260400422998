import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import Field from '../components/form/Field'
import TextField from '../components/form/TextField'
import Button from '../components/form/button/Button'
import { validateEmail } from '../utils/validation'
import { requestForgotPassword } from '../redux/actions/admin'

import keyIcon from '../assets/images/key-icon.png'
import { HiArrowLeft } from 'react-icons/hi'

const Div = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  .img-container {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;

    img {
      width: 48px;
      height: 48px;
    }
  }

  .title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    color: var(--Gray-900);
  }

  .desc {
    text-align: center;
    margin-top: 12px;
  }

  .login-form {
    margin-top: 32px;

    .field {
      margin-top: 20px;
    }

    .link-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }

    button {
      display: block;
      width: 100%;
      margin-top: 24px;
    }
  }

  .hightlight {
    color: var(--Primary-700);
    font-weight: 800;
  }

  .copyright {
    color: var(--Gray-500);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 180px;
  }

  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mr-8-px {
    margin-right: 8px;
  }

  .back-to-log-in-container {
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    :hover {
      cursor: pointer;
    }
  }

  @media screen and (max-width: 440px) {
    max-width: 320px;
    .title {
      font-size: 20px;
    }

    .desc {
      font-size: 14px;
    }
  }
`

const ForgetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')

  const handleForgetPassword = () => {
    event.preventDefault()

    const request = {
      data: {
        email,
      },
    }

    dispatch(requestForgotPassword(request)).then((res) => {
      if (res.type === 'FORGOT_PASSWORD_SUCCESS') {
        navigate('/login', {
          replace: true,
        })
      }
    })
  }

  const backToLogin = () => {
    navigate('/login')
  }

  return (
    <Div>
      <form>
        <div className="img-container">
          <img src={keyIcon} />
        </div>
        <div className="title">Forgot password?</div>
        <div className="desc">No worries, we’ll send you reset instructions.</div>
        <div className="login-form">
          <Field className="field" label="Email">
            <TextField placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </Field>

          <Button className="mb-1-rem" type="submit" onClick={handleForgetPassword} disabled={!validateEmail(email)}>
            Reset password
          </Button>
          <div className="back-to-log-in-container" onClick={backToLogin}>
            <HiArrowLeft className="mr-8-px" />
            Back to log in
          </div>
        </div>
      </form>
    </Div>
  )
}

export default ForgetPassword
