import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import Chip from 'components/common/Chip'
import { capitalizeEachWord } from 'utils/stringHelpers'

export default function ManagementTabs({ tabCounts = { ['chain-link']: 0, sow: 0, sop: 0, task: 0 } }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const rootPath = '/system-configuration/template-management'
  const currentTab = location.pathname.split('/').pop() // Get the last part of the pathname e.g. /management/sow -> sow

  const items = [
    { id: 'chain-link', label: capitalizeEachWord(t('chain_link')) },
    { id: 'sop', label: capitalizeEachWord(t('sop')) },
    { id: 'sow', label: capitalizeEachWord(t('sow')) },
    { id: 'task', label: capitalizeEachWord(t('task')) },
  ]

  const handleChangeTab = (tab) => {
    navigate(`${rootPath}/${tab}`)
  }

  return items.map((tab) => {
    const isActive = currentTab === tab.id

    return (
      <div
        key={tab.id}
        className={`tab-item ${isActive ? 'active' : ''}`}
        onClick={() => currentTab !== tab.id && handleChangeTab(tab.id)}
      >
        {tab.label}{' '}
        <Chip
          label={tabCounts[tab.id] > 1000 ? '999+' : tabCounts[tab.id]}
          color={isActive ? 'primary' : 'default'}
          sx={{ p: '2px 8px 2px 8px', cursor: 'pointer' }}
        />
      </div>
    )
  })
}
