import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useNavigate, NavLink } from 'react-router-dom'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

import { getTaskByBaseIdReturnData } from '../../redux/actions/ofm'

import Button from '../../components/form/button/Button'
import QRCode from "react-qr-code";
import dayjs from 'dayjs'
import { DateDisplay } from '../../utils/common'
import { getCompanyInfo } from '../../utils/common'
import html2canvas from 'html2canvas'

import ModalQR from '../../pages/operation-field/ModalQR'

const DivMain = styled.div`
color: var(--Gray-600);
font-size: 14px;
font-style: normal;
font-weight: 500;
.header {
    display: flex;
    align-items: center;

    .highlight {
        color: var(--Primary-700);
        font-weight: 600;
    }
}
.mb-2-rem {
    margin-bottom: 2rem;
}
.mr-8-px {
    margin-right: 8px;
}
.icon {
    cursor: pointer;
}
.content-title {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
}
.border_header{
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
}
.wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        border-bottom: 0px solid #EAECF0;
        color: #667085;
        font-size: 14px;
    }

    .menu-active {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid var(--Primary-600) !important;
        color: #6941C6;
        background-color: #F9F5FF;
    }
}

.css_half{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
}
.zone_box{
    border: 1px solid rgb(208, 213, 221);
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 18px;
    margin-bottom: 24px;
    font-size: 14px;
    min-height: 40px;
    color: #344054;
    background-color: #FFFFFF;
    :hover {
        color: #6941C6;
        background-color: #F9F5FF;
        border: 1px solid #E9D7FE;
    }
}
.add_cursor{
    cursor: pointer;
}
.head_select{
    font-size: 18px;
    font-weight: 600;
    color: var(--Gray-900);
    margin-bottom: 15px;
}
/* ST CSS ALL QR CODE */
.dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  /* Style for the dialog content */
  .dialog-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    position: relative;
    width: 400px;
    padding: 0 15px;
  }
  /* Style for the close icon */
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0;
  }

  .popup-image {
    width: 256px;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .dialog-qr-footer {
    /*margin-bottom: 15px;*/
    /*
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    */
    width : 100%;
    background-color : #FFFFFF;
    /*padding: 14px;*/
    .button_margin {
      margin: 10px;
    }
  }
  .box_qr_code{
    display: flex;
    justify-content: center;
  }
  .dialog-conten-createdate{
    margin-top: 15px;
    text-align: end;
  }
  .box_DowloadQRIMG{
    padding: 10px;
  }
  .box_qr_header{
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .box_qr_header_content{
    font-size: 16px;
    font-weight: 600;
    color: var(--Gray-900);
    text-align: left;
  }
  /* ED CSS ALL QR CODE */
`


function PermissionOFMJob() {
  const CompanyInfo = getCompanyInfo()
  const params = useParams();
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const { id:base_id } = params;
  const [ZoneId,setZoneId] = useState(0);
  const [BaseId,setBaseId] = useState(base_id);
  const [ZoneName,setZoneName] = useState('');
  const [BaseName,setBaseName] = useState('');
  

  
  const [BasesCellData ,setBasesCellData] = useState([]);
  const fetchDataAllBase = useCallback(
    async (filter) => {
        if(BaseId){
            dispatch(getTaskByBaseIdReturnData(BaseId,filter)).then(({ type,data_info,data_meta }) => {
                if(type.endsWith('_SUCCESS')){
                    if(data_info){
                        /*
                        const totalPage = Math.ceil(data_meta.pagination.total / limitAttend)
                        settotalAttend(data_meta.pagination.total)
                        settotalPagesAttend(totalPage)
                        */
                        
                        setZoneId(data_info.zone.id);
                        setZoneName(data_info.zone.name);
                        setBaseName(data_info.name);
                        
                        const newBasesCellData = data_info.tasks?.map((v, index) => ({
                            task_id : v.id,
                            display_name : v.name,
                        }))
                        setBasesCellData(newBasesCellData)
                        
                    }
                }
            })
        }

    },
    [dispatch]
 );

 useEffect(() => {
    
    const filter = {
        skip: 1,
        limit: 1000,
    }
    fetchDataAllBase(filter)
}, [fetchDataAllBase]) 



  const GotoTaskDetail = (data) => {
    navigate(`/operation-field/PermissionOFMJobDetail/${data}`)
  }

  const backToPermissionOFM = () => {
    navigate('/operation-field/PermissionOFM')
  }

  const backToBase = () => {
    navigate(`/operation-field/PermissionOFMBase/${ZoneId}`)
  }
  const [dialogQR, setdialogQR] = useState(false);
  const [QRDisplay, setQRDisplay] = useState({});
  const [valueQR, setvalueQR] = useState({});
  const [QRDate, setQRDate] = useState('');
  const OpenQRCode = (item) => {
      //setImageSrc(url_img);
    const currentDate_now = dayjs().format('YYYY-MM-DD HH:mm:ss');
    setQRDate('Created at : ' + DateDisplay(currentDate_now));
    const data_qr_display = {
        "company_id" : CompanyInfo?.id,
        "zone" : ZoneId,
        "display_zone" : ZoneName,
        "base" : BaseId,
        "display_base" : BaseName,
        "task" : item.task_id,
        "display_task" : item.display_name,
        "created" : 'Created at : ' + DateDisplay(currentDate_now)
    };
    setQRDisplay(data_qr_display);

    const data_qr = {
        "company_id" : CompanyInfo?.id,
        "zone" : ZoneId,
        "base" : BaseId,
        "task" : item.task_id
    };
    setvalueQR(data_qr);
    setdialogQR(true);
  }

  const ClosetoggleDialogQR = () => {
      setdialogQR(false);
  
  };

  const DowloadQR = async () => {
    //alert('DowloadQR');
    const element = document.getElementById('DowloadQRIMG'),
    canvas = await html2canvas(element),
    data = canvas.toDataURL('image/jpg'),
    link = document.createElement('a');

    link.href = data;
    link.download = 'QRCode.jpg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  return (
    <DivMain>
        <div className="header mb-2-rem">
            <RiHome6Line size={20} className="mr-8-px" />
            <MdOutlineKeyboardArrowRight className="mr-8-px" />
            <span className="icon mr-8-px" >
            Operation field
            </span>
            <MdOutlineKeyboardArrowRight className="mr-8-px" />
            <span className="add_cursor" onClick={() => backToPermissionOFM()}>Production Permission</span>
            <MdOutlineKeyboardArrowRight className="mr-8-px" />
            <span className="add_cursor" onClick={() => backToBase()}>Zone : {ZoneName}</span>
            <MdOutlineKeyboardArrowRight className="mr-8-px" />
            <span className="highlight">Base : {BaseName}</span>
        </div>
        <div className="content-title">Base : {BaseName}</div>
        <div className="border_header"></div>
        <div className="css_half">
            <div>

                <div className="head_select">Select Task</div>

                {BasesCellData?.map((item, index) => (
                    <div key={index} className="css_half">
                        <div className="zone_box" onClick={() => GotoTaskDetail(item.task_id)}>
                            {item.display_name}
                        </div>
                        <div>
                            <Button onClick={() => OpenQRCode(item)} className="margin_right_5">
                                {'Generate Task QR Code'}
                            </Button>
                        </div>
                    </div>
                ))}            
            </div>
            <div>
            </div>
        </div>
        <ModalQR
          open={Boolean(dialogQR)}
          onClose={() => setdialogQR(false)}
          QRDisplay={QRDisplay}
          valueQR={valueQR}
        />
        {
            /*
                    {dialogQR && (
            <div className="dialog">
            <div className="dialog-content">
                <div>
                    <button className="close-icon" onClick={ClosetoggleDialogQR}>&#10005;</button>
                </div>
                <div id="DowloadQRIMG" className="box_DowloadQRIMG">
                    <div className="box_qr_header">
                        <div className="box_qr_header_content">
                            {QRDisplay.display_zone}
                        </div>
                        <div className="box_qr_header_content">
                            {QRDisplay.display_base}
                        </div>
                        <div className="box_qr_header_content">
                            {QRDisplay.display_task}
                        </div>                 
                    </div>
                    <div className="box_qr_code">
                        <div className="popup-image">
                            <QRCode
                                id="QRCode" 
                                title="OFM"
                                value={JSON.stringify(valueQR)}
                                
                            />
                        </div>
                    </div>
                    <div>
                        <div className="dialog-conten-createdate">
                            {QRDate}
                        </div>
                    </div>
                </div>


                <div className="dialog-qr-footer">
                    <Button className="button_margin" onClick={DowloadQR}>
                        {'Download'}
                    </Button>
                </div>
                
            </div>
            </div>
        )}  
            */
        }

    </DivMain>
  )
}

export default PermissionOFMJob