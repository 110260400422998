import { styled } from '@mui/system'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import DropdownTree from 'components/feature/all-service/GroupMenu/DropdownTree'
import { mappedIcons } from 'utils/sideNavHelpers'

function addLevel(data, level = 0) {
  return data.map((item) => {
    let newItem = { ...item, level }
    if (item.children) {
      newItem.children = addLevel(item.children, level + 1)
    }
    return newItem
  })
}

const GroupMenu = ({ title: groupTitle = '', items: groupItems = [], ...props }) => {
  const lang = localStorage.getItem('lang') || 'en'
  const newGroupItems = addLevel(groupItems)
  const navigate = useNavigate()

  const [menuKey, setMenuKey] = useState([])

  const createHandleLeaveMenu = (key, level) => {
    const handleSetMenuKey = (prev) => {
      const sliceMenuKey = prev.slice(0, level)
      const newArray = Array.from(new Set([...sliceMenuKey, key]))

      return newArray
    }

    setMenuKey(handleSetMenuKey)
  }

  return (
    <StyledGroupMenu {...props} className="all-service-group-menu-container">
      <h3 className="group-title">{groupTitle}</h3>
      <ol className="group-menu">
        {newGroupItems.map((item, index) => {
          const title = lang === 'en' ? item.name_en : item.name_th
          const description = lang === 'en' ? item.description_en || '' : item.description_th || ''
          const itemChildren = item?.children || []
          const menuLabelKey = lang === 'en' ? 'name_en' : 'name_th'

          return (
            <li key={index} className="menu-item-container">
              <DropdownTree
                menuItems={itemChildren}
                labelKey={menuLabelKey}
                menuKey={menuKey}
                open={menuKey.includes(item.key)}
                onOpenChange={(_, isOpen) => isOpen && setMenuKey([item.key])}
                onSubmenuMouseEnter={createHandleLeaveMenu}
                menuButtonProps={{
                  onClick() {
                    if (item.children && item.children.length > 0) {
                      setMenuKey([item.key])
                      return
                    }

                    if (item.pathto) {
                      if (typeof item.pathto === 'function') {
                        item.pathto()
                      } else {
                        navigate(item.pathto)
                      }
                    }
                  },
                }}
              >
                <StyledMenuItem className={`menu-item${menuKey.includes(item.key) ? ' active' : ' in-active'}`}>
                  <div className="menu-icon">{mappedIcons(item.key)}</div>
                  <div className="menu-information">
                    <p className="menu-item-title">{title}</p>
                    <span className="menu-item-description">{description}</span>
                  </div>
                </StyledMenuItem>
              </DropdownTree>
            </li>
          )
        })}
      </ol>
    </StyledGroupMenu>
  )
}

export default GroupMenu

const StyledGroupMenu = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 22px;
  color: var(--Gray-900);
  width: 100%;

  &.all-service-group-menu-container {
    > h3.group-title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  > ol {
    &.group-menu {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
      list-style: none;
      position: relative;
      padding-inline-start: 0;
      margin: 0;
    }
  }
`

const StyledMenuItem = styled('div')`
  display: flex;
  position: relative;
  height: 68px;
  padding: 0 12px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid var(--Gray-100);
  gap: 12px;

  &.menu-item {
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.6s ease-in-out;

    &:hover,
    &.active {
      background-color: var(--Primary-50);
      border-color: var(--Primary-200);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    }

    &:hover:not(.active) {
      scale: 1.05;
    }

    &.active {
      outline: 2px solid var(--Primary-300);
    }

    > .menu-icon {
      display: flex;
      flex: none;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 6px;
      background-color: var(--Primary-600);
      color: var(--Base-White);
    }

    > .menu-information {
      display: flex;
      flex-direction: column;
      width: 100%;
      overflow: hidden;

      > .menu-item-title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        margin: 0;
        color: var(--Gray-900);

        -webkit-box-flex: 1;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      > .menu-item-description {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: var(--Gray-900);

        -webkit-box-flex: 1;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`
