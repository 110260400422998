import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_DEPARTMENTS,
  CREATE_DEPARTMENTS,
  GET_DEPARTMENT_BY_ID,
  UPDATE_DEPARTMENTS,
  DELETE_DEPARTMENTS,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getDepartments =
  (filter = { limit: 10, skip: 1 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_DEPARTMENTS.REQUEST })
      const { jwt,company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const params = {
        type: 'filter',
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'filters[query]': filter.filters?.length > 0 ? JSON.stringify(filter.filters) : '[]',
        'filters[company_id]': company_info?.id ? company_info?.id : null
      }
      const { data, meta } = await getAPI({
        url: '/api/departments?',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_DEPARTMENTS.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_DEPARTMENTS.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Department List Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getDepartmentById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DEPARTMENT_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/departments/${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_DEPARTMENT_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_DEPARTMENT_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Department By Id Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createDepartment = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_DEPARTMENTS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/departments`,
      data,
      headers: headers,
    })

    dispatch(getDepartments())

    return dispatch({
      type: CREATE_DEPARTMENTS.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Department Form Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_DEPARTMENTS.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Department Form Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}
export const updateDepartment = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_DEPARTMENTS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/departments/${id}`,
      data,
      headers: headers,
    })

    dispatch(getDepartments())

    return dispatch({
      type: UPDATE_DEPARTMENTS.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Department Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_DEPARTMENTS.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update Department Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteDepartment = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_DEPARTMENTS.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/departments/${id}`,
      data,
      headers: headers,
    })

    dispatch(getDepartments())

    return dispatch({
      type: DELETE_DEPARTMENTS.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Department Successful',
    })
  } catch (err) {
    return dispatch({
      type: DELETE_DEPARTMENTS.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Delete Department Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}
