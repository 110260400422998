import styled from 'styled-components'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { BASE_API } from '../../configs/app'
import { useTranslation } from 'react-i18next'

import { useDropzone } from 'react-dropzone'

import { CreateFileUserUpload } from '../../redux/actions/dailysummary'

import IconButton from '../common/IconButton'

import iconUpload from '../../assets/images/icon-upload-round.svg'
import iconFile from '../../assets/images/icon-file-round.svg'
import iconBin from '../../assets/images/icon-bin.svg'

import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import { DateTimeHHmmssDisplay } from '../../utils/common'

const Div = styled.div`
  .upload-zone {
    padding: 14px;
    color: var(--Gray-600);
    font-size: 14px;
    background: var(--Base-White);
    border: 1px solid ${({ $error }) => ($error ? 'red' : 'var(--Gray-300)')};
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    text-align: center;
    cursor: pointer;

    img {
      width: 40px;
    }

    p {
      margin: 0;

      b {
        color: var(--Primary-700);
      }
    }
  }

  .upload-list {
    .file {
      width: 100%;
      display: inline-flex;
      padding: 14px;
      margin-top: 16px;
      background: var(--Base-White);
      border: 1px solid var(--Gray-300);
      box-shadow: var(--Shadow-xs);
      border-radius: 8px;
    }

    .icon-wrapper {
      width: 32px;
    }

    .detail-wrapper {
      flex: 1;
      margin: 0 14px;
      font-size: 14px;
      overflow: hidden;

      .file-name {
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;

        a {
          font-weight: 500;
          color: var(--Gray-700);
        }
      }
    }
  }
`

const DivTable = styled.div`
  width: 100%;
  overflow-x: auto;
  margin-bottom: 20px;
  .text_css {
    min-width: 200px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: var(--Shadow-xs);

    tr {
      /*border: 1px solid var(--Gray-200);*/
    }

    th,
    td {
      padding: 16px 14px;
      text-align: left;
      font-size: 14px;
      color: var(--Gray-600);
      white-space: nowrap;
      background: var(--Base-White);
    }

    th {
      padding: 12x 14px;
      font-weight: 500;
      background: var(--Gray-50);
    }
    /*
    tr:hover {
      td {
        background: var(--Primary-50);
        color: var(--Primary-700);
      }
    }
    */
  }

  .col-File {
    width: 50%;
    border-bottom: 1px solid var(--Gray-200);
  }
  .col-Timestamp {
    width: 25%;
    border-bottom: 1px solid var(--Gray-200);
  }
  .col-UploadBy {
    width: 25%;
    border-bottom: 1px solid var(--Gray-200);
  }

  .content-wrapper {
    text-align: center;
  }

  .pagination {
    margin: 30px 0;
  }

  .cursor_pointer {
    cursor: pointer;
  }
  .upload-list {
    padding: 16px 0px;
    .file {
      width: 100%;
      display: inline-flex;
      padding: 14px;
      margin-top: 16px;
      background: var(--Base-White);
      border: 1px solid var(--Gray-300);
      box-shadow: var(--Shadow-xs);
      border-radius: 8px;
    }

    .icon-wrapper {
      width: 32px;
    }

    .detail-wrapper {
      flex: 1;
      margin: 0 14px;
      font-size: 14px;
      overflow: hidden;

      .file-name {
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;

        a {
          font-weight: 500;
          color: var(--Gray-700);
        }
      }
    }
  }
  .upload-list-zone {
    padding: 16px 0px;
    .upload-zone {
      padding: 14px;
      color: var(--Gray-600);
      font-size: 14px;
      background: var(--Base-White);
      border: 1px solid ${({ $error }) => ($error ? 'red' : 'var(--Gray-300)')};
      box-shadow: var(--Shadow-xs);
      border-radius: 8px;
      text-align: center;
      cursor: pointer;

      img {
        width: 40px;
      }

      p {
        margin: 0;

        b {
          color: var(--Primary-700);
        }
      }
    }
  }
  .text_css_error {
    color: red;
  }
`

import axios from 'axios'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled as styled_mui } from '@mui/material/styles'
const BorderLinearProgress = styled_mui(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}))

const FileUploadProgress = ({
  multiple = false,
  accept = {},
  file = [],
  onAddFile,
  onDeleteFile,
  error = false,
  disabled = false,
  FileListData,
  setFileListData,
  isShowTimeStampAndUploadBy = false,
  limitFileSize = 300,
  ...props
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  /*
  const [FileList, setFileList] = useState([
    {
      id : 0,
      url : 'https://google.com',
      name : 'this is name',
      size : '1234',
      progress_percent : 75,
      progress_display : '-'
    }
  ])
  */
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    /*maxFiles: multiple ? 0 : 1,*/
    maxFiles: 1, //--- By James Fix เป็นแบบ File เดี่ยวก่อนเพราะยังไม่ได้เทสแบบหลายๆ File
    accept,
    onDropAccepted: (value) => {
      /* //--- Old Version 
      value.forEach((v) => (v.id = Math.random().toString().replace('0.', '')))
      onAddFile(value)
      */

      value.forEach((v, index_data) => {
        const this_index_file_list = FileList.length + (index_data + 1)
        //alert(this_index_file_list)
        if (parseInt(v.size) > parseInt(limitFileSize * 1024 * 1024)) {
          setTitleFail('ไฟล์มีขนาดใหญ่เกินกว่ากำหนด')
          setnameItemFail('')
          setDescriptionFail('')
          setFailModal(true)
        } else {
          let file_data = {
            id: null,
            url: null,
            name: v.name,
            size: v.size,
            progress_percent: 0,
            progress_display: `-`,
            progress_is_error: false,
          }

          const formData = new FormData()
          formData.append('files', v)
          formData.append(
            'fileInfo',
            JSON.stringify({
              name: v.name,
              caption: v.name,
              alternativeText: v.name,
              folder: null,
            })
          )

          const options = {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent
              let percent = Math.floor((loaded * 100) / total)

              if (percent < 100) {
                file_data = {
                  id: null,
                  url: null,
                  name: v.name,
                  size: v.size,
                  progress_percent: percent,
                  progress_display: `${convertByteToKB(loaded)}KB of ${convertByteToKB(total)}KB | ${percent}%`,
                  progress_is_error: false,
                }
                setFileListData([...FileListData, file_data])
              } else {
                file_data = {
                  id: null,
                  url: null,
                  name: v.name,
                  size: v.size,
                  progress_percent: percent,
                  progress_display: `กำลังรวบรวมไฟล์`,
                  progress_is_error: false,
                }
                setFileListData([...FileListData, file_data])
              }
            },
          }

          const data = formData
          axios
            .post(BASE_API + '/api/upload', data, options)
            .then((res) => {
              //
              //alert(JSON.stringify(res.data[0].id))
              //alert(JSON.stringify(res.data[0].url))
              dispatch(CreateFileUserUpload(res.data[0].id))
              onAddFile(res.data[0].id)
              file_data = {
                id: res.data[0].id,
                url: res.data[0].url,
                name: v.name,
                size: convertByteToKB(v.size),
                progress_percent: 100,
                progress_display: `OK`,
                progress_is_error: false,
              }
              setFileListData([...FileListData, file_data])
            })
            .catch((error) => {
              file_data = {
                id: null,
                url: null,
                name: v.name,
                size: convertByteToKB(v.size),
                progress_percent: 100,
                progress_display: `ไม่สามารถอัพโหลดไฟล์ได้`,
                progress_is_error: true,
              }
              setFileListData([...FileListData, file_data])
            })
        }
      })
    },
    disabled,
  })

  const convertByteToKB = (byte_value) => {
    return (byte_value / 1024).toFixed(2)
  }

  return (
    <Div $error={error} {...props}>
      <DivTable>
        {isShowTimeStampAndUploadBy && (
          <table>
            <thead>
              <tr>
                <th className={`col-File`}>File</th>
                <th className={`col-Timestamp`}>Timestamp</th>
                <th className={`col-UploadBy`}>Upload by</th>
              </tr>
              {FileListData.map((v_file_list, index_file_list) => (
                <tr key={index_file_list}>
                  <td className="upload-list">
                    {v_file_list.url == null && (
                      <div className="file">
                        <div className="icon-wrapper">
                          <img src={iconFile} />
                        </div>
                        <div className="detail-wrapper">
                          <div className="file-name">{v_file_list.name}</div>
                          <div className="file-progress">
                            <BorderLinearProgress variant="determinate" value={v_file_list.progress_percent} />
                          </div>
                          <div className={v_file_list.progress_is_error ? 'file-size text_css_error' : 'file-size '}>
                            {v_file_list.progress_display}
                          </div>
                        </div>
                      </div>
                    )}
                    {v_file_list.url != null && (
                      <div className="file" key={v_file_list.id}>
                        <div className="icon-wrapper">
                          <img src={iconFile} />
                        </div>
                        <div className="detail-wrapper">
                          <div className="file-name">
                            <a href={v_file_list.url} target="_blank" rel="noreferrer">
                              {v_file_list.name}
                            </a>
                          </div>
                          <div className="file-size">{v_file_list.size} KB</div>
                        </div>
                        <div className="action-wrapper">
                          <IconButton src={iconBin} title="Delete" onClick={() => onDeleteFile(v_file_list.id)} />
                        </div>
                      </div>
                    )}
                  </td>
                  <td>{v_file_list.create_by ? DateTimeHHmmssDisplay(v_file_list.createdAt) : '-'}</td>
                  <td>{v_file_list.create_by ? v_file_list.create_by : '-'}</td>
                </tr>
              ))}
              <tr>
                <td className="upload-list-zone">
                  <div {...getRootProps({ className: 'upload-zone' })}>
                    <input {...getInputProps()} />
                    <img src={iconUpload} />
                    <p>
                      <b>{t('click_to_upload')}</b> {t('drag_drop')}
                    </p>
                  </div>
                </td>
                <td></td>
                <td></td>
              </tr>
            </thead>
          </table>
        )}
        {!isShowTimeStampAndUploadBy && (
          <div>
            <div {...getRootProps({ className: 'upload-zone' })}>
              <input {...getInputProps()} />
              <img src={iconUpload} />
              <p>
                <b>{t('click_to_upload')}</b> {t('drag_drop')}
              </p>
            </div>
            <div className="upload-list">
              {FileListData.map((v_file_list) => (
                <div>
                  {v_file_list.url != null && (
                    <div className="file" key={v_file_list.id}>
                      <div className="icon-wrapper">
                        <img src={iconFile} />
                      </div>
                      <div className="detail-wrapper">
                        <div className="file-name">
                          <a href={v_file_list.url} target="_blank" rel="noreferrer">
                            {v_file_list.name}
                          </a>
                        </div>
                        <div className="file-size">{v_file_list.size} KB</div>
                      </div>
                      <div className="action-wrapper">
                        <IconButton src={iconBin} title="Delete" onClick={() => onDeleteFile(v_file_list.id)} />
                      </div>
                    </div>
                  )}
                  {v_file_list.url == null && (
                    <div className="file">
                      <div className="icon-wrapper">
                        <img src={iconFile} />
                      </div>
                      <div className="detail-wrapper">
                        <div className="file-name">{v_file_list.name}</div>
                        <div className="file-progress">
                          <BorderLinearProgress variant="determinate" value={v_file_list.progress_percent} />
                        </div>
                        <div className={v_file_list.progress_is_error ? 'file-size text_css_error' : 'file-size '}>
                          {v_file_list.progress_display}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}
      </DivTable>
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes="Ok"
      />
    </Div>
  )
}

export default FileUploadProgress
