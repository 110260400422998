import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_MATERIAL_LIST,
  GET_MATERIAL_BY_ID,
  GET_MATERIAL_CATEGORY,
  GET_MATERIAL_TYPE,
  GET_SUPPLIER,
  GET_MATERIAL_NOTES,
  CREATE_MATERIAL_NOTE,
  CREATE_MATERIAL,
  EDIT_MATERIAL,
  DELETE_MATERIAL,
  UPLOAD_CSV_MATERIAL,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getMaterialList =
  (filter = { limit: 10, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MATERIAL_LIST.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const params = { ...filter }
      const { data, meta } = await getAPI({
        url: '/api/material-custom-material-list',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_MATERIAL_LIST.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({ type: GET_MATERIAL_LIST.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getMaterialById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MATERIAL_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/material-custom-material-byid?id=${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_MATERIAL_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_MATERIAL_BY_ID.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMaterialCategory = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MATERIAL_CATEGORY.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/material-categories',
      headers: headers,
    })

    return dispatch({
      type: GET_MATERIAL_CATEGORY.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_MATERIAL_CATEGORY.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMaterialType = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MATERIAL_TYPE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/material-types',
      headers: headers,
    })

    return dispatch({
      type: GET_MATERIAL_TYPE.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_MATERIAL_TYPE.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getSuppliers = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SUPPLIER.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/suppliers',
      headers: headers,
    })

    return dispatch({
      type: GET_SUPPLIER.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_SUPPLIER.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMaterialNotes = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MATERIAL_NOTES.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/material-custom-material-notelist?id=${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_MATERIAL_NOTES.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_MATERIAL_NOTES.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const createMaterialNote = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_MATERIAL_NOTE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/material-notes`,
      data,
      headers: headers,
    })

    const filter = {
      skip: 0,
      limit: 10,
      category: null,
      type: null,
      supplier: null,
    }
    dispatch(getMaterialList(filter))
    dispatch(getMaterialNotes(data.data.material))

    return dispatch({
      type: CREATE_MATERIAL_NOTE.SUCCESS,
      showAlert: true,
      alertMessage: 'Create note successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_MATERIAL_NOTE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const createMaterial = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_MATERIAL.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/materials`,
      data,
      headers: headers,
    })

    const filter = {
      skip: 0,
      limit: 10,
      category: null,
      type: null,
      supplier: null,
    }
    dispatch(getMaterialList(filter))

    return dispatch({
      type: CREATE_MATERIAL.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Material Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_MATERIAL.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const editMaterial = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_MATERIAL.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/material-custom-material-update`,
      data,
      headers: headers,
    })

    const filter = {
      skip: 0,
      limit: 10,
      category: null,
      type: null,
      supplier: null,
    }
    dispatch(getMaterialList(filter))

    return dispatch({
      type: EDIT_MATERIAL.SUCCESS,
      showAlert: true,
      alertMessage: 'Edit Material Successful',
    })
  } catch (err) {
    return dispatch({ type: EDIT_MATERIAL.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const uploadCSVMaterial = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPLOAD_CSV_MATERIAL.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/material-custom-material-upload-csv`,
      data,
      headers: headers,
    })

    const filter = {
      skip: 0,
      limit: 10,
      category: null,
      type: null,
      supplier: null,
    }
    dispatch(getMaterialList(filter))

    return dispatch({
      type: UPLOAD_CSV_MATERIAL.SUCCESS,
      showAlert: true,
      alertMessage: 'Upload CSV Successful',
    })
  } catch (err) {
    return dispatch({ type: UPLOAD_CSV_MATERIAL.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteMaterial = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_MATERIAL.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/materials/${id}`,
      data,
      headers: headers,
    })

    const filter = {
      skip: 0,
      limit: 10,
      category: null,
      type: null,
      supplier: null,
    }
    dispatch(getMaterialList(filter))

    return dispatch({
      type: DELETE_MATERIAL.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Material Successful',
    })
  } catch (err) {
    return dispatch({ type: DELETE_MATERIAL.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}
