import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { capitalizeEachWord } from 'utils/stringHelpers'

export default function FileSectionTabs({ tabCounts = { delivery: 0, asset: 0 }, onChange }) {
  const { t } = useTranslation()

  const items = [
    { id: 'delivery', label: capitalizeEachWord(t('delivery')) },
    { id: 'asset', label: capitalizeEachWord(t('asset')) },
  ]

  const [activeTab, setActiveTab] = useState(items[0].id)

  useEffect(() => {
    onChange && onChange(activeTab)
  }, [activeTab])

  return items.map((tab) => (
    <div
      key={tab.id}
      className={`tab-item ${activeTab === tab.id ? 'active' : ''}`}
      onClick={() => setActiveTab(tab.id)}
    >
      {tab.label} {tabCounts[tab.id] > 0 ? `(${tabCounts[tab.id]})` : '(0)'}
    </div>
  ))
}
