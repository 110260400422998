import { getAPI, postAPI, putAPI, deleteAPI } from '../../utils/api'
import {
  GET_DOCUMENT_TEMPLATE,
  GET_DOCUMENT_TEMPLATE_BY_ID,
  CREATE_DOCUMENT_TEMPLATE,
  EDIT_DOCUMENT_TEMPLATE,
  DELETE_DOCUMENT_TEMPLATE,
  GET_CATEGORIES,
  DUPLICATE_DOCUMENT_TEMPLATE,
} from '../actionTypes'
import { showLoading, hideLoading } from '../actions/loading'

export const getDocumentCategory =
  (filter = { page: 1, pageSize: 100 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_CATEGORIES.REQUEST })
      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      const params = {
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'fields[0]': 'id',
        'fields[1]': 'name',
        'fields[2]': 'status',
        'filters[$and][0][company][id][$eq]': company_info?.id,
        'filters[$and][1][status][$eq]': 'Active',
      }

      const { data } = await getAPI({
        url: '/api/document-acknow-categories',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_CATEGORIES.SUCCESS,
        data,
      })
    } catch (error) {
      return dispatch({
        type: GET_CATEGORIES.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get category failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getDocumentTemplate =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_DOCUMENT_TEMPLATE.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'fields[0]': 'name',
        'fields[1]': 'type',
        'fields[2]': 'doc_lang',
        'fields[3]': 'doc_topic',
        'populate[category][fields][0]': 'name',
        'populate[topics]': '*',
        'populate[creator][fields][1]': 'title',
        'populate[creator][fields][1]': 'first_name',
        'populate[creator][fields][3]': 'last_name',
        'sort[createdAt]': 'desc',
      }
      if (filter?.category !== '') params['filters[category]'] = filter.category
      if (filter?.type !== '') params['filters[type]'] = filter.type

      const { data, meta } = await getAPI({
        url: '/api/document-acknow-templates',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_DOCUMENT_TEMPLATE.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_DOCUMENT_TEMPLATE.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Document template failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getDocumentTemplateById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DOCUMENT_TEMPLATE_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'populate[category][fields][0]': 'name',
      'populate[topics]': '*',
      'fields[0]': 'name',
      'fields[1]': 'type',
      'fields[2]': 'doc_lang',
      'fields[3]': 'doc_topic',
    }
    const { data } = await getAPI({
      url: `/api/document-acknow-templates/${id}`,
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_DOCUMENT_TEMPLATE_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_DOCUMENT_TEMPLATE_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Document template by id failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createDocumentTemplate = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_DOCUMENT_TEMPLATE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: '/api/document-acknow-templates',
      headers: headers,
      data,
    })

    return dispatch({
      type: CREATE_DOCUMENT_TEMPLATE.SUCCESS,
      showAlert: true,
      alertMessage: 'Create document template Successful!',
    })
  } catch (error) {
    return dispatch({
      type: CREATE_DOCUMENT_TEMPLATE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Create document template failed!',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getDocumentTemplate())
  }
}

export const editDocumentTemplate = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_DOCUMENT_TEMPLATE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/document-acknow-templates/${id}`,
      headers: headers,
      data,
    })

    return dispatch({
      type: EDIT_DOCUMENT_TEMPLATE.SUCCESS,
      showAlert: true,
      alertMessage: 'Edit document template Successful!',
    })
  } catch (error) {
    return dispatch({
      type: EDIT_DOCUMENT_TEMPLATE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Edit document template failed!',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getDocumentTemplate())
  }
}

export const deleteDocumentTemplate = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_DOCUMENT_TEMPLATE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await deleteAPI({
      url: `/api/document-acknow-templates/${id}`,
      headers: headers,
    })

    return dispatch({
      type: DELETE_DOCUMENT_TEMPLATE.SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: DELETE_DOCUMENT_TEMPLATE.FAILURE,
      error,
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getDocumentTemplate())
  }
}

export const duplicateDocumentTemplate = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DUPLICATE_DOCUMENT_TEMPLATE.REQUEST })
    const { jwt, employee_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'populate[category][fields][0]': 'name',
      'populate[topics]': '*',
      'fields[0]': 'name',
      'fields[1]': 'type',
      'fields[2]': 'doc_lang',
      'fields[3]': 'doc_topic',
    }
    const { data } = await getAPI({
      url: `/api/document-acknow-templates/${id}`,
      headers: headers,
      params,
    })
    const newDuplicatedTemplate = {
      data: {
        name: `Copy of ${data.name}`,
        category: data.category.id,
        type: data.type,
        doc_lang: data.doc_lang,
        doc_topic: data.doc_topic,
        topics: data.topics.map((v) => ({
          title: v.title,
        })),
        creator: employee_info?.id,
      },
    }

    await postAPI({
      url: '/api/document-acknow-templates',
      headers: headers,
      data: newDuplicatedTemplate,
    })

    return dispatch({
      type: DUPLICATE_DOCUMENT_TEMPLATE.SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: DUPLICATE_DOCUMENT_TEMPLATE.FAILURE,
      error,
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getDocumentTemplate())
  }
}
