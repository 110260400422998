import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'

import IconButton from '../common/IconButton'

import iconUpload from '../../assets/images/icon-upload-round.svg'
import iconFile from '../../assets/images/icon-file-round.svg'
import iconBin from '../../assets/images/icon-bin.svg'

const Div = styled.div`
  .upload-zone {
    padding: 9px 14px;
    color: var(--Gray-600);
    font-size: 14px;
    color: var(--Base-White);
    background: var(--Primary-600);
    /* border: 1px solid ${({ $error }) => ($error ? 'red' : 'var(--Gray-300)')}; */
    /* box-shadow: var(--Shadow-xs); */
    border-radius: 8px;
    text-align: center;
    cursor: pointer;

    img {
      width: 40px;
    }

    p {
      margin: 0;

      b {
        color: var(--Primary-700);
      }
    }
  }

  .upload-list {
    .file {
      width: 100%;
      display: inline-flex;
      padding: 14px;
      margin-top: 16px;
      background: var(--Base-White);
      border: 1px solid var(--Gray-300);
      box-shadow: var(--Shadow-xs);
      border-radius: 8px;
    }

    .icon-wrapper {
      width: 32px;
    }

    .detail-wrapper {
      flex: 1;
      margin: 0 14px;
      font-size: 14px;
      overflow: hidden;

      .file-name {
        margin-bottom: 2px;
        text-overflow: ellipsis;
        overflow: hidden;

        a {
          font-weight: 500;
          color: var(--Gray-700);
        }
      }
    }
  }
  .txt-btn-name {
    text-transform: capitalize;
  }
`

const InputFileUpload = ({
  multiple = false,
  accept = {},
  file = [],
  onAddFile,
  onDeleteFile,
  error = false,
  disabled = false,
  inputForm,
  ...props
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: multiple ? 0 : 1,
    accept,
    onDropAccepted: (value) => {
      value.forEach((v) => (v.id = Math.random().toString().replace('0.', '')))
      onAddFile(value)
    },
    disabled,
  })

  return (
    <Div $error={error} {...props}>
      <div {...getRootProps({ className: 'upload-zone' })}>
        <input {...getInputProps()} />
        <span className="txt-btn-name">{inputForm != '' ? inputForm : 'Attach File'}</span>
      </div>
      {/* <div className="upload-list">
        {file.map((v) => (
          <div className="file" key={v.id}>
            <div className="icon-wrapper">
              <img src={iconFile} />
            </div>
            <div className="detail-wrapper">
              <div className="file-name">
                <a href={v.url} target="_blank" rel="noreferrer">
                  {v.name}
                </a>
              </div>
              <div className="file-size">{v.size} KB</div>
            </div>
            <div className="action-wrapper">
              <IconButton src={iconBin} title="Delete" onClick={() => onDeleteFile(v.id)} />
            </div>
          </div>
        ))}
      </div> */}
    </Div>
  )
}

export default InputFileUpload
