import { combineReducers } from 'redux'
import { DYNAMIC_FORM } from 'redux/actionTypes'

const initialState = {
  createSchema: {},
  editSchema: {},
  viewSchema: {},
  landingPageSchema: {},
  dynamicDatas: [],
  dynamicInfo: {},
  dynamicFormDataId: '',
  total: 1,
  page: 1,
  pageCount: 1,
  pageSize: 10,
  isLoading: false,
  state: '',
}

const dynamicFormReducer = (state = initialState, action = {}) => {
  const { type } = action
  switch (type) {
    case DYNAMIC_FORM?.GET?.GET_LANDING_PAGE_SCHEMA?.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_LANDING_PAGE_SCHEMA.REQUEST',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_LANDING_PAGE_SCHEMA?.SUCCESS: {
      const { data } = action
      const { LANDINGSchema } = data

      return {
        ...state,
        landingPageSchema: LANDINGSchema,
        isLoading: false,
        state: 'GET_LANDING_PAGE_SCHEMA.SUCCESS',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_LANDING_PAGE_SCHEMA?.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_LANDING_PAGE_SCHEMA.FAILURE',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_CREATE_SCHEMA?.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_CREATE_SCHEMA.REQUEST',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_CREATE_SCHEMA?.SUCCESS: {
      const { data } = action
      const { CREATESchema } = data

      return {
        ...state,
        createSchema: CREATESchema,
        isLoading: false,
        state: 'GET_CREATE_SCHEMA.SUCCESS',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_CREATE_SCHEMA?.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_CREATE_SCHEMA.FAILURE',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_EDIT_SCHEMA?.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_EDIT_SCHEMA.REQUEST',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_EDIT_SCHEMA?.SUCCESS: {
      const { data } = action
      const { EDITSchema } = data

      return {
        ...state,
        editSchema: EDITSchema,
        isLoading: false,
        state: 'GET_EDIT_SCHEMA.SUCCESS',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_EDIT_SCHEMA?.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_EDIT_SCHEMA.FAILURE',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_VIEW_SCHEMA?.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_VIEW_SCHEMA.REQUEST',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_VIEW_SCHEMA?.SUCCESS: {
      const { data } = action
      const { VIEWSchema } = data

      return {
        ...state,
        viewSchema: VIEWSchema,
        isLoading: false,
        state: 'GET_VIEW_SCHEMA.SUCCESS',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_VIEW_SCHEMA?.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_VIEW_SCHEMA.FAILURE',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_DATA_DYNAMIC_FORM?.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_DATA_DYNAMIC_FORM.REQUEST',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_DATA_DYNAMIC_FORM?.SUCCESS: {
      const { data, meta } = action

      return {
        ...state,
        dynamicDatas: data,
        page: meta?.pagination?.page,
        pageCount: meta?.pagination?.pageCount,
        pageSize: meta?.pagination?.pageSize,
        total: meta?.pagination?.total,
        isLoading: false,
        state: 'GET_DATA_DYNAMIC_FORM.SUCCESS',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_DATA_DYNAMIC_FORM?.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_DATA_DYNAMIC_FORM.FAILURE',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_DATA_DYNAMIC_FORM_BY_ID?.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_DATA_DYNAMIC_FORM_BY_ID.REQUEST',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_DATA_DYNAMIC_FORM_BY_ID?.SUCCESS: {
      const { data } = action
      return {
        ...state,
        dynamicInfo: data,
        isLoading: false,
        state: 'GET_DATA_DYNAMIC_FORM_BY_ID.SUCCESS',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_DATA_DYNAMIC_FORM_BY_ID?.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_DATA_DYNAMIC_FORM_BY_ID.FAILURE',
      }
    }
    case DYNAMIC_FORM?.POST?.CREATE_DYNAMIC_FORM?.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_DYNAMIC_FORM.REQUEST',
      }
    }
    case DYNAMIC_FORM?.POST?.CREATE_DYNAMIC_FORM?.SUCCESS: {
      const { data } = action

      return {
        ...state,
        dynamicFormDataId: data?.id,
        isLoading: false,
        state: 'CREATE_DYNAMIC_FORM.SUCCESS',
      }
    }
    case DYNAMIC_FORM?.POST?.CREATE_DYNAMIC_FORM?.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_DYNAMIC_FORM.FAILURE',
      }
    }
    case DYNAMIC_FORM?.PUT?.UPDATE_DYNAMIC_FORM?.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'UPDATE_DYNAMIC_FORM.REQUEST',
      }
    }
    case DYNAMIC_FORM?.PUT?.UPDATE_DYNAMIC_FORM?.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_DYNAMIC_FORM.SUCCESS',
      }
    }
    case DYNAMIC_FORM?.PUT?.UPDATE_DYNAMIC_FORM?.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_DYNAMIC_FORM.FAILURE',
      }
    }
    case DYNAMIC_FORM?.DELETE?.DELETE_DYNAMIC_FORM?.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_DYNAMIC_FORM.REQUEST',
      }
    }
    case DYNAMIC_FORM?.DELETE?.DELETE_DYNAMIC_FORM?.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_DYNAMIC_FORM.SUCCESS',
      }
    }
    case DYNAMIC_FORM?.DELETE?.DELETE_DYNAMIC_FORM?.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_DYNAMIC_FORM.FAILURE',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_DROPDOWN_OPTIONS?.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_DROPDOWN_OPTIONS.REQUEST',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_DROPDOWN_OPTIONS?.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_DROPDOWN_OPTIONS.SUCCESS',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_DROPDOWN_OPTIONS?.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_DROPDOWN_OPTIONS.FAILURE',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_DROPDOWN_OPTIONS_BY_SPECIFIC?.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_DROPDOWN_OPTIONS_BY_SPECIFIC.REQUEST',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_DROPDOWN_OPTIONS_BY_SPECIFIC?.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_DROPDOWN_OPTIONS_BY_SPECIFIC.SUCCESS',
      }
    }
    case DYNAMIC_FORM?.GET?.GET_DROPDOWN_OPTIONS_BY_SPECIFIC?.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_DROPDOWN_OPTIONS_BY_SPECIFIC.FAILURE',
      }
    }
    case DYNAMIC_FORM?.CLEAR.CLEAR_EDIT_SCHEMA: {
      return {
        ...state,
        editSchema: {},
        isLoading: false,
        state: 'CLEAR_EDIT_SCHEMA',
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default combineReducers({
  dynamicFormReducer,
})
