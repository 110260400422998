import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import styled from 'styled-components'
import Table from '../../common/Table'
import ButtonOutline from '../../form/button/ButtonOutline'
import Button from '../../form/button/Button'
import Date from '../../form/Date'
import SearchText from '../../form/SearchText'
import SidePanel from '../../common/SidePanel'
import TextField from '../../form/TextField'
import RadioButton from '../../form/RadioButton'

import { FiArrowRight } from 'react-icons/fi'
import { LiaTimesSolid } from 'react-icons/lia'
import { IoOpenOutline } from 'react-icons/io5'

import { selectEmployeeProfile } from '../../../redux/selectors'
import { getArchivedRequest, getRequestEditLogById } from '../../../redux/actions/employeeProfile'
import { DateTimeHHmmssDisplay, DateDisplay } from '../../../utils/common'

const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-12-px {
    margin-right: 12px;
  }
  .view-request-btn {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }
  .highlight {
    color: var(--Primary-700);
    font-weight: 600;
  }
  .link {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .user-img-container {
    .item {
      vertical-align: middle;
    }
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
`
const SearchContainer = styled.div`
  display: flex;
  align-items: flex-end;
`
const BadgeSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .badge {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`
const InputContainer = styled.div`
  &.width-20 {
    width: 20%;
  }
  .w-input-100 {
    width: 100%;
  }
  .input-group {
    display: flex;
    justify-content: space-between;
  }

  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .checkbox-container {
    display: flex;
    align-items: center;

    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
  }

  .w-input {
    width: 100%;
  }

  .w-30 {
    width: 30%;
  }

  .w-input-30 {
    width: 30%;
  }
`
const TableWrapper = styled.div`
  .table-header {
    padding: 1rem 0.875rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: var(--Base-White);
    border: 0.0625rem solid var(--Gray-200);

    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.25rem;

      .table-header-name {
        color: var(--Gray-900);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        margin-right: 0.5rem;
      }
    }

    .search-container {
      display: flex;
      .clear-button-container {
        display: flex;
        align-items: flex-end;
      }
    }
  }
`
const Badge = styled.div`
  display: inline-block;

  border-radius: 16px;
  border: 1px solid var(--Primary-200);
  background: var(--Primary-50);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: var(--Primary-700);

  padding: 0.4rem;
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`
const SidePanelContent = styled.div`
  padding: 24px;

  .mb-1-rem {
    margin-bottom: 1rem;
  }
`
const RequestContent = styled.div`
  .icon {
    cursor: pointer;
  }
  .mr-12-px {
    margin-right: 12px;
  }
  .pb-12-px {
    padding-bottom: 12px;
  }
  .heading {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .submission-date {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    .date {
      color: var(--Primary-600);
    }
  }
  .input-heading {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
  .opened-link {
    color: var(--Primary-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .item-vertical-center {
    vertical-align: middle;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }

  .w-30 {
    width: 30%;
  }
  .w-68 {
    width: 68%;
  }
`
const TextAreaContainer = styled.div`
  font-family: inherit;
  padding: 20px;
  border-radius: 12px;
  background: var(--Gray-50);
  border-radius: 8px;

  .remark-text {
    color: var(--Error-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  textarea {
    width: 100%;
    height: 104px;
    border-radius: 8px;
    border: 1px solid var(--Gray-300);
    background: var(--Base-White);
  }
`

const editRequestLogColumn = [
  {
    Header: 'no',
    accessor: 'no',
    disableSortBy: false,
  },
  {
    Header: 'Request ID',
    accessor: 'request_no',
    disableSortBy: false,
  },
  {
    Header: 'Employee Name',
    accessor: 'employee_name',
    disableSortBy: false,
  },
  {
    Header: 'Submission Date',
    accessor: 'submission_date',
    disableSortBy: false,
  },
  {
    Header: 'Review Date',
    accessor: 'review_date',
    disableSortBy: false,
  },
  {
    Header: 'Review By',
    accessor: 'review_by',
    disableSortBy: false,
  },
  {
    accessor: 'view_request',
    disableSortBy: false,
  },
]
const initialRadioOption = [
  {
    text: 'Approve',
    value: 'approve',
  },
  {
    text: 'Deny',
    value: 'deny',
  },
]

function ArchivedDataContent() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const lang = localStorage.getItem('lang')
  const { employeeDashboardInfo, archivedRequest, page, pageCount, limit, total, isLoading, state } =
    useSelector(selectEmployeeProfile)
  const [editRequestLogs, setEditRequestLogs] = useState([])
  const [requestId, setRequestId] = useState('')
  const [requestDetails, setRequestDetails] = useState([])
  // filter
  const [search, setSearch] = useState('')
  const [submitDate, setSubmitDate] = useState('')
  const [reviewDate, setReviewDate] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  // side panel
  const [viewRequestSidePanel, setViewRequestSidePanel] = useState(false)

  const viewRequestLogInfo = useCallback(
    (id) => {
      setRequestId(id)
      dispatch(getRequestEditLogById(id))
      setViewRequestSidePanel(true)
    },
    [setViewRequestSidePanel, dispatch]
  )

  const fetchArchivedRequest = useCallback(
    (filter) => {
      dispatch(getArchivedRequest(filter))
    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      page: 1,
      pageSize: 10,
    }
    fetchArchivedRequest(filter)
  }, [])

  useEffect(() => {
    const newEditRequestLogs = archivedRequest.map((v, i) => ({
      ...v,
      no: i + 1,
      employee_name: `${v?.employee_requestted?.first_name} ${v?.employee_requestted?.last_name}`,
      submission_date: DateTimeHHmmssDisplay(v.createdAt),
      review_date: DateTimeHHmmssDisplay(v.approve_date),
      view_request: (
        <ButtonOutline
          onClick={() => viewRequestLogInfo(v.id)}
          className="view-request-btn"
          append={<FiArrowRight size={20} />}
        >
          View Detail
        </ButtonOutline>
      ),
      review_by: (
        <div className="user-img-container">
          <img className="item mr-12-px" src={v?.approver?.photo} />
          <span className="item">
            {v?.approver?.first_name} {v?.approver?.last_name}
          </span>
        </div>
      ),
    }))
    setEditRequestLogs(newEditRequestLogs)
  }, [archivedRequest, viewRequestLogInfo])

  useEffect(() => {
    if (employeeDashboardInfo?.profile_details.length > 0) {
      const newRequestDetails = employeeDashboardInfo?.profile_details?.map((v) => {
        let newValue = ''
        let oldValue = ''
        if (
          v.field_name === 'thai_province' ||
          v.field_name === 'thai_amphure' ||
          v.field_name === 'thai_tambon' ||
          v.field_name === 'nationality' ||
          v.field_name === 'bank'
        ) {
          newValue = lang === 'en' ? v.field_value.name_en : v.field_value.name_th
          oldValue = lang === 'en' ? v.o_value.name_en : v.o_value.name_th
        } else {
          newValue = v.field_value
          oldValue = v.o_value
        }
        return {
          id: v.id,
          field_name: v.field_name,
          new_value: newValue,
          old_value: oldValue,
          status: v.status,
          remark: v.remark == null ? '' : v.remark,
        }
      })
      setRequestDetails(newRequestDetails)
    } else {
      setRequestDetails()
    }
  }, [employeeDashboardInfo])

  useEffect(() => {
    if (submitDate !== '' || reviewDate !== '') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[$or][${i}][$or][0][employee_requestted][first_name][$contains]`]: v.name,
        [`filters[$or][${i}][$or][1][employee_requestted][last_name][$contains]`]: v.name,
      }))
      const filter = {
        page: 1,
        pageSize: limit,
        multipleSearch,
        submit_date: submitDate,
        review_date: reviewDate,
      }
      fetchArchivedRequest(filter)
    }
  }, [submitDate, reviewDate])

  // filter
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`filters[$or][${i}][$or][0][employee_requestted][first_name][$contains]`]: v.name,
        [`filters[$or][${i}][$or][1][employee_requestted][last_name][$contains]`]: v.name,
      }))

      const filter = {
        page: 1,
        pageSize: limit,
        multipleSearch,
        submit_date: submitDate,
        review_date: reviewDate,
      }
      fetchArchivedRequest(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][$or][0][employee_requestted][first_name][$contains]`]: v.name,
      [`filters[$or][${i}][$or][1][employee_requestted][last_name][$contains]`]: v.name,
    }))
    const filter = {
      page: 1,
      pageSize: limit,
      multipleSearch,
      submit_date: submitDate,
      review_date: reviewDate,
    }
    fetchArchivedRequest(filter)
    setSearch('')
  }
  const onClearSearch = () => {
    const filter = {
      page: 1,
      pageSize: limit,
      multipleSearch: [],
      submit_date: '',
      review_date: '',
    }
    fetchArchivedRequest(filter)
    clearData()
  }
  const clearData = () => {
    setSearch('')
    setSubmitDate('')
    setReviewDate('')
    setSearchFilters([])
  }
  const onChangePagination = (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$or][${i}][$or][0][employee_requestted][first_name][$contains]`]: v.name,
      [`filters[$or][${i}][$or][1][employee_requestted][last_name][$contains]`]: v.name,
    }))
    const filter = {
      page: value.page,
      pageSize: limit,
      multipleSearch,
      submit_date: submitDate,
      review_date: reviewDate,
    }
    fetchArchivedRequest(filter)
  }

  // side panel
  const closeViewRequest = () => {
    setViewRequestSidePanel(false)
  }

  // onchange
  const onChangeForm = (id, event) => {
    const { name, value } = event.target
    const copiedRequestDetails = [...requestDetails]

    const matchRequestDetailIndex = copiedRequestDetails.findIndex((v) => v.id == id)
    copiedRequestDetails[matchRequestDetailIndex][name] = value
    setRequestDetails(copiedRequestDetails)
  }

  const openNewTab = (url) => {
    window.open(url, '_blank')
  }

  return (
    <Div>
      <TableWrapper>
        <div className="table-header">
          <div className="header-container">
            <div>
              <span className="table-header-name">Archived Request Log</span>
              <Badge>
                {editRequestLogs.length} {t('archived')}
              </Badge>
            </div>
          </div>
          <Divider className="mb-1-rem" />
          <SearchContainer className="search-container mb-1-rem">
            <InputContainer className="mr-12-px">
              <div className="input-header">{t('search')}</div>
              <SearchText
                placeholder={t('search')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSubmit={onSearch}
                onClear={() => setSearch('')}
              />
            </InputContainer>
            <InputContainer className="width-20 mr-12-px">
              <div className="input-header">Submission Date</div>
              <Date value={submitDate} onChange={(value) => setSubmitDate(value)} />
            </InputContainer>
            <InputContainer className="width-20 mr-12-px">
              <div className="input-header">Review Date</div>
              <Date value={reviewDate} onChange={(value) => setReviewDate(value)} />
            </InputContainer>
            <ButtonOutline append={<LiaTimesSolid />} onClick={onClearSearch}>
              {t('clear')}
            </ButtonOutline>
          </SearchContainer>
          <BadgeSearchContainer>
            {searchFilters.map((v) => (
              <div className="badge" key={v.key}>
                <span className="name">{v.name}</span>{' '}
                <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
              </div>
            ))}
          </BadgeSearchContainer>
        </div>
        <Table
          data={editRequestLogs}
          columns={editRequestLogColumn}
          page={page}
          pageCount={pageCount}
          onStateChange={onChangePagination}
        />
      </TableWrapper>
      {/* Side panel */}
      <SidePanel isOpen={viewRequestSidePanel} setIsOpen={() => setViewRequestSidePanel(true)} width={800}>
        <SidePanelContent>
          <RequestContent>
            <header className="mb-1-rem">
              <FlexContainer className="justify-content-space-between">
                <div className="heading">
                  {employeeDashboardInfo?.employee_requestted?.first_name}{' '}
                  {employeeDashboardInfo?.employee_requestted?.last_name}
                </div>
                <LiaTimesSolid className="icon" size={20} onClick={closeViewRequest} />
              </FlexContainer>

              <FlexContainer className="justify-content-space-between">
                <div className="submission-date">
                  Submission Date : <span className="date">{DateDisplay(employeeDashboardInfo?.submit_date)}</span>
                </div>
                <div className="submission-date">
                  Review Date : <span className="date">{DateDisplay(employeeDashboardInfo?.approve_date)}</span>
                </div>
                <div className="submission-date">
                  Review by :{' '}
                  <span className="date">
                    {employeeDashboardInfo?.approver?.first_name} {employeeDashboardInfo?.approver?.last_name}
                  </span>
                </div>
              </FlexContainer>
            </header>
            <Divider className="mb-1-rem" />

            <section>
              <FlexContainer className="justify-content-space-between mb-1-rem">
                <div className="w-30">
                  <div className="input-heading">Uploaded file</div>
                </div>
                <div className="w-68">
                  <div>{employeeDashboardInfo?.attach_file?.alternativeText}</div>
                  <div className="opened-link" onClick={() => openNewTab(employeeDashboardInfo?.attach_file?.url)}>
                    <IoOpenOutline size={20} className="item-vertical-center mr-12-px" />
                    <span className="item-vertical-center p pb-12-px">Open file in new window</span>
                  </div>
                </div>
              </FlexContainer>
            </section>
            <Divider className="mb-1-rem" />

            {requestDetails?.map((v, i) => (
              <React.Fragment key={v.id}>
                <section>
                  <div className="input-heading mb-1-rem">{v.field_name}</div>
                  <FlexContainer className="justify-content-space-between mb-1-rem">
                    <div className="w-30">ข้อมูลเดิม</div>
                    <div className="w-68">{v.old_value}</div>
                  </FlexContainer>
                  <FlexContainer className="justify-content-space-between mb-1-rem">
                    <div className="w-30">
                      <div>ข้อมูลที่ขอแก้ไข</div>
                    </div>
                    <div className="w-68">
                      <InputContainer>
                        <TextField className="w-input-100" value={v.new_value} disabled={true} />
                      </InputContainer>
                    </div>
                  </FlexContainer>
                  <FlexContainer className="justify-content-space-between mb-1-rem">
                    <div className="w-30"></div>
                    <div className="w-68">
                      <RadioButton
                        name="status"
                        optionList={initialRadioOption}
                        value={v.status}
                        handleChange={(event) => onChangeForm(v.id, event)}
                        disabled={true}
                      />
                    </div>
                  </FlexContainer>
                  {v.status === 'deny' && (
                    <FlexContainer className="justify-content-space-between mb-1-rem">
                      <div className="w-30"></div>
                      <div className="w-68">
                        <TextAreaContainer>
                          <div className="remark-text">Remark*</div>
                          <textarea
                            name="remark"
                            value={v.remark}
                            onChange={(event) => onChangeForm(v.id, event)}
                            disabled
                          />
                        </TextAreaContainer>
                      </div>
                    </FlexContainer>
                  )}
                </section>
                <Divider className="mb-1-rem" />
              </React.Fragment>
            ))}

            <FlexContainer className="justify-content-end">
              <Button onClick={closeViewRequest}>Close</Button>
            </FlexContainer>
          </RequestContent>
        </SidePanelContent>
      </SidePanel>
    </Div>
  )
}

export default ArchivedDataContent
