import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import SidePanel from '../../components/common/SidePanel'
import Button from '../../components/form/button/Button'
import ImageButton from '../../components/widget/element/ImageButton'
import IconXClose from '../../components/widget/icon/IconXClose'
import { getMilestoneById } from '../../redux/actions/projectTask'
import { selectProjectTask } from '../../redux/selectors'
import { convertDateFormat } from '../../utils/helper'
import MileStoneEditor from './MilestoneEditor'
import PreviewItemMilestone from './PreviewItemMilestone'

import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import { GetPermissionOther } from '../../utils/common'

export default function MilestoneSidePanel({
  isOpen,
  onClose,
  projectId,
  projectData,
  milestoneId,
  milestoneTemplateData,
  type = 'create_milestone',
  onEditMilestone,
  onDataChange,
  onClickedTask,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { milestoneData } = useSelector(selectProjectTask)

  //--- ST ส่วนของ Permission LV3-4
  const initLang = localStorage.getItem('lang')
  const permissionEditMilestone = GetPermissionOther('project','edit_milestone');
  //--- ED ส่วนของ Permission LV3-4

  const [dataMileStone, setDataMileStone] = useState()
  const [milestoneScopeList, setMilestoneScopeList] = useState([])
  const [isShowAddInputScopeOfWork, setIsShowAddInputScopeOfWork] = useState(false)

  const [stateCreateNewMileStone, setStateCreateNewMileStone] = useState('milestone-information')

  const [isShowPreviewItemMileStone, setIsShowPreviewItemMileStone] = useState(false)
  const [isGetDataMileStone, setIsGetDataMileStone] = useState(false)
  const [currentScopeId, setCurrentScopeId] = useState(false)
  const [isShowPreviewDialog, setIsShowPreviewDialog] = useState(false)
  const [currentTaskIdSelected, setCurrentTaskIdSelected] = useState()

  useEffect(() => {
    ;(async () => {
      if (type == 'view_milestone_template') {
        // VIEW_MILESTONE_TEMPLATE

        if (Object.keys(milestoneTemplateData).length > 0) {
          setDataMileStone(milestoneTemplateData)
          setMilestoneScopeList([...milestoneTemplateData?.milestone_scope_works])
        }
      } else {
        if (milestoneId != '' && !isGetDataMileStone) {
          await dispatch(getMilestoneById(milestoneId))
        }
      }
    })()
  }, [type, milestoneId])

  useEffect(() => {
    if (type != 'view_milestone_template') {
      setDataMileStone(milestoneData)
      setMilestoneScopeList(milestoneData?.milestone_scope_works)
      setIsGetDataMileStone(true)
    }
  }, [milestoneData])

  const handleClickBtnExit = () => {
    if (onClose) {
      // setStateCreateNewMileStone('milestone-information')
      // setIsShowAddInputScopeOfWork(false)
      clearData()
      onClose()
    }
  }

  const clearData = () => {
    setDataMileStone()
    setMilestoneScopeList([])
    setIsGetDataMileStone(false)
  }

  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const handleClickBtnEdit = () => {
    
    if(permissionEditMilestone?.canUpdate === true){
      if (onEditMilestone) {
        onEditMilestone()
      }
    }else{
      let message_permission = 'No permission allowed ' + permissionEditMilestone?.name_en ;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionEditMilestone?.name_th;
      }
      setTitleFail(message_permission);
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }
  }

  const handleClickScope = (scopeItem) => {
    setCurrentScopeId(scopeItem?.id)
    setIsShowPreviewItemMileStone(true)
  }
  const handleClickTask = (taskItem) => {
    if (onClickedTask) {
      onClickedTask(taskItem.id)
      onClose()
    }
    // setCurrentTaskIdSelected(taskItem.id)
    // setIsShowPreviewDialog(true)
  }
  const handleSuccessChange = () => {
    // set for update to Milestone page
    if (onDataChange) {
      onDataChange()
    }
  }

  const handleDataChange = () => {
    dispatch(getMilestoneById(milestoneId))
    setIsShowPreviewItemMileStone(false)
  }

  return (
    <SidePanel isOpen={isOpen} width={1000}>
      <SidePanelStyle>
        {!isShowPreviewItemMileStone && (
          <div className="wrp-add-milestone">
            <div className="wrp-navigation">
              <div className="navigation-left">
                <div className="txt-navigation-left">
                  {type == 'create_milestone'
                    ? 'New Milestone'
                    : type == 'edit_project'
                    ? 'Edit project'
                    : 'Milestone : ' + dataMileStone?.name}
                </div>
              </div>
              <div className="navigation-right">
                {/* {type == 'view_milestone' && <ImageButton className={'btn-link'} src={iconLink} />} */}
                <ImageButton className={'btn-close'} icon={<IconXClose />} onClick={() => handleClickBtnExit()} />
              </div>
            </div>

            {(type == 'create_milestone' || type == 'edit_milestone' || type == 'edit_milestone_template') && (
              <MileStoneEditor
                projectId={projectId}
                type={type}
                milestoneData={dataMileStone}
                onCancel={() => onClose()}
                onSuccess={() => handleSuccessChange()}
              />
            )}

            {(type == 'view_milestone' || type == 'view_milestone_template') && (
              <>
                <div className="preview-item-milestone">
                  <div className="top-detail">
                    <div className="left">
                      <div className="col">
                        <div className="title">{t('due_date')} :</div>
                        <div className="top-text-detail">
                          {convertDateFormat(dataMileStone?.due_date, 'DD MMM YYYY')}
                        </div>
                      </div>
                      <div className="col">
                        <div className="title">{t('status')} :</div>
                        <div className="top-text-detail">{dataMileStone?.status || '-'}</div>
                      </div>
                    </div>
                    <div className="right">
                      {permissionEditMilestone?.canRead === true && (
                        <Button onClick={handleClickBtnEdit}>{t('Edit')}</Button>
                      )}
                    </div>
                  </div>

                  <div className="body-detail">
                    {milestoneScopeList?.length > 0 && (
                      <>
                        {milestoneScopeList?.map((scopeItem, scopeIndex) => (
                          <div className="wrp-scope-task" key={'scope-item-' + scopeIndex}>
                            <div
                              className="scope"
                              onClick={() => {
                                if (type != 'view_milestone_template') {
                                  handleClickScope(scopeItem)
                                }
                              }}
                            >
                              <div className="left">
                                <div className="number-scope">{scopeIndex + 1}</div>
                                <div className="detail-scope">
                                  <div className="col">
                                    <div className="title">{t('scope_of_work')} :</div>
                                    <div className="body-text-detail">{scopeItem?.task_scope?.name}</div>
                                  </div>
                                  <div className="col">
                                    <div className="title">{t('due_date')} : </div>
                                    <div className="body-text-detail">
                                      {scopeItem?.due_date != ''
                                        ? convertDateFormat(scopeItem?.due_date, 'DD / MMM / YYYY')
                                        : '-'}
                                    </div>
                                  </div>
                                  <div className="col">
                                    <div className="title">{t('assign_to')} : </div>
                                    <div className="body-text-detail">
                                      {scopeItem?.assign_to
                                        ? scopeItem?.assign_to?.first_name + ' ' + scopeItem?.assign_to?.last_name
                                        : '-'}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="right">
                                <div className="status">
                                  {t('status')} : {scopeItem?.status ? scopeItem?.status : '-'}
                                </div>
                              </div>
                            </div>

                            {scopeItem?.milestone_task_types?.length > 0 && (
                              <>
                                <ul className="tree">
                                  {scopeItem?.milestone_task_types.map((taskItem, taskIndex) => (
                                    <li key={'milestone-item-' + taskIndex}>
                                      <div
                                        className="task"
                                        key={'task-item-' + taskIndex}
                                        onClick={() => {
                                          if (type != 'view_milestone_template') {
                                            handleClickTask(taskItem)
                                          }
                                        }}
                                      >
                                        <div className="left">
                                          <div className="detail-scope">
                                            <div className="col">
                                              <div className="title">{t('task')} :</div>
                                              <div className="body-text-detail">
                                                {type == 'view_milestone_template'
                                                  ? taskItem?.name
                                                  : taskItem?.task_type?.name}
                                              </div>
                                            </div>
                                            <div className="col">
                                              <div className="title">{t('due_date')} : </div>
                                              <div className="body-text-detail">
                                                {taskItem?.due_date
                                                  ? convertDateFormat(taskItem?.due_date, 'DD / MMM / YYYY')
                                                  : '-'}
                                              </div>
                                            </div>
                                            <div className="col">
                                              <div className="title">{t('assign_to')} : </div>
                                              <div className="body-text-detail">
                                                {taskItem?.assign_to
                                                  ? taskItem?.assign_to?.first_name +
                                                    ' ' +
                                                    taskItem?.assign_to?.last_name
                                                  : '-'}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="right">
                                          <div className="status">
                                            {t('status')} : {taskItem?.status ? taskItem?.status : '-'}
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </>
                            )}
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {isShowPreviewItemMileStone && (
          <PreviewItemMilestone
            projectName={projectData?.name}
            scopeId={currentScopeId}
            onClose={() => setIsShowPreviewItemMileStone(false)}
            onDataChange={() => {
              handleDataChange()
            }}
          />
        )}

        {/* {isShowPreviewDialog && (
          <DialogTaskPreviewer
            taskId={currentTaskIdSelected}
            open={isShowPreviewDialog}
            onClose={() => setIsShowPreviewDialog(false)}
            previewType={'task_milestone'}
            // onDataChange={() => handleDataChange()}
            // onEditTask={() => {
            //   setCurrentTaskType('edit_task')
            //   setIsShowPreviewDialog(false)
            //   setIsShowNewTaskModal(true)
            // }}
          />
        )} */}
        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />
      </SidePanelStyle>
    </SidePanel>
  )
}

const SidePanelStyle = styled.div`
  height: 100%;

  .wrp-add-milestone {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .wrp-navigation {
    display: flex;
    justify-content: space-between;
    padding: 16px 32px;
    border-bottom: 1px solid var(--Gray-300);
  }
  .navigation-left {
    display: flex;
  }
  .icon-chevron-left {
    display: flex;
    margin-right: 1rem;
    cursor: pointer;
  }
  .txt-navigation-left {
    color: gray;
    font-weight: bold;
    font-size: 20px;
  }
  .navigation-right {
    display: flex;
    align-items: center;
  }
  .btn-link {
    width: 30px;
    height: 30px;
  }
  .btn-close {
    margin-left: 8px;
    width: 30px;
    height: 30px;
    svg {
      position: relative;
      top: 3px;
      width: 18px;
    }
  }

  //preview item milestone
  .preview-item-milestone {
    flex: 1;
    padding: 32px;
    .title,
    .status {
      font-weight: bold;
    }
    .top-text-detail,
    .body-text-detail {
      margin-left: 6px;
    }
    .top-text-detail {
      font-weight: bold;
    }
    .col {
      display: flex;
    }
    .top-detail {
      display: flex;
      justify-content: space-between;
    }
    .scope,
    .task {
      border: 1px solid var(--Gray-300);
      border-radius: 8px;
      margin: 16px 0;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .left {
        display: flex;
        align-items: center;
        .number-scope {
          font-size: 40px;
          margin-left: 16px;
          margin-right: 32px;
        }
      }
    }
  }

  /// tree
  .body-detail {
    .top {
      border: 1px solid #ddd;
      padding: 10px;
    }
    * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

    ul {
      margin-left: 50px;
      counter-reset: item;
    }

    .tree li {
      list-style-type: none;
      margin: 10px 0 10px 10px;
      position: relative;
    }
    .tree li:before {
      content: '';
      counter-increment: item;
      position: absolute;
      top: -16px;
      left: -30px;
      border-left: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      width: 30px;
      height: 69px;
      border-bottom-left-radius: 5px;
    }
    .tree li:after {
      position: absolute;
      content: '';
      top: 51px;
      left: -30px;
      border-left: 1px solid #ddd;

      width: 30px;
      height: 100%;
    }
    .tree li:last-child:after {
      display: none;
    }
    .tree li span {
      display: block;
      border: 1px solid #ddd;
      padding: 10px;
      color: #666666;
      text-decoration: none;
    }
    .tree li span:before {
      content: counters(item, '.') ' ';
    }

    .display {
      margin-right: 12px;
      font-weight: bold;
    }

    input,
    label {
      margin: 12px 0px 20px 0px;
    }

    label {
      padding-left: 6px;
      padding-right: 12px;
    }

    label ~ ul li ul li {
      display: none;
    }
  }
`
