import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateDisplay } from 'utils/common'
import { v4 as uuidv4 } from 'uuid'

import styled from 'styled-components'
import Table from 'components/common/Table'
import { Box, Stack } from '@mui/material'
import SearchText from 'components/form/SearchText'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Button from 'components/form/button/Button'
import SidePanel from 'components/common/SidePanel'
import Divider from 'components/common/Divider'
import CreateWeeklySummaryReport from 'components/feature/weekly-summary-report/CreateWeeklySummaryReport'
import EditWeeklySummaryReport from 'components/feature/weekly-summary-report/EditWeeklySummaryReport'
import DialogSuccess from 'components/dialog/DialogSuccess'
import DialogFail from 'components/dialog/DialogFail'
import Date from 'components/form/Date'
import BadgeSearch from 'components/common/BadgeSearch'
import AutoComplete from 'components/form/AutoComplete'
import DialogConfirmDelete from 'components/dialog/DialogConfirmDelete'

import { BiTrash } from 'react-icons/bi'
import { LuPen } from 'react-icons/lu'
import { FaPlus } from 'react-icons/fa'
import successIcon from 'assets/images/success-icon.png'
import failIcon from 'assets/images/fail-icon.png'

import { useDispatch, useSelector } from 'react-redux'
import { selectMaster, selectWeeklySummaryReport } from 'redux/selectors'
import {
  getWeeklySummaryReportById,
  getWeeklySummaryReports,
  getProjectTasks,
  deleteWeeklySummaryReport,
} from 'redux/actions/weeklySummaryReport'
import { getEmployeeDropdrow } from 'redux/actions/master'

const Div = styled.div`
  .feature-header {
    color: var(--Gray-900);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
  }
  .feature-description {
    color: var(--Gray-600);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 1.25em;
  }
`
const TableWrapper = styled.div`
  .link {
    font-weight: bold;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .icon {
    cursor: pointer;
  }

  .table-header {
    padding: 1.25rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: var(--Base-White);
    border: 1px solid var(--Gray-200);
  }

  .input-filter-heading {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .total-heading {
    color: var(--Gray-900);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
`
const Badge = styled.div`
  display: inline-block;

  border-radius: 16px;
  border: 1px solid var(--Primary-200);
  background: var(--Primary-50);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: var(--Primary-700);

  padding: 0.4rem;
`

function WeeklySummaryReport() {
  // external hook
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // intiate data
  const fetchWeeklySummaryReports = useCallback(
    (filter = { page: 1, pageSize: 10 }) => {
      dispatch(getWeeklySummaryReports(filter))
    },
    [dispatch]
  )
  const fetchEmployeeDropdown = useCallback(() => {
    dispatch(getEmployeeDropdrow())
  }, [dispatch])
  const fetchProjectTasks = useCallback(() => {
    dispatch(getProjectTasks())
  }, [dispatch])

  useEffect(() => {
    fetchWeeklySummaryReports()
    fetchEmployeeDropdown()
    fetchProjectTasks()
  }, [])

  // table data
  const { weeklySummaryReports, total, page, pageCount, pageSize } = useSelector(selectWeeklySummaryReport)
  const weeklySummaryReportColumns = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Report of the week',
      accessor: 'report_of_the_week',
      disableSortBy: false,
    },
    {
      Header: t('project'),
      accessor: 'project_task',
      disableSortBy: false,
    },
    {
      Header: "What's done this week?",
      accessor: 'what_done_this_week',
      disableSortBy: false,
    },
    {
      Header: 'Created by',
      accessor: 'created_by',
      disableSortBy: false,
    },
    {
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const [weeklySummaryReportList, setWeeklySummaryReportList] = useState([])

  const truncateStringOver60 = (str) => {
    return str.length > 60 ? `${str.slice(0, 60)}...` : str
  }

  useEffect(() => {
    const newWeeklySummaryReportList = weeklySummaryReports.map((v) => ({
      ...v,
      report_of_the_week: (
        <div>
          {DateDisplay(v.start_date)} - {DateDisplay(v.end_date)}
        </div>
      ),
      what_done_this_week: (
        <div className="link" onClick={() => openEditSidePanel(v.id)}>
          {truncateStringOver60(v.what_done_this_week)}
        </div>
      ),
      action: (
        <Stack direction="row" alignItems="center" spacing={2}>
          <BiTrash className="icon" size={18} onClick={() => openConfirmDelete(v.id)} />
          <LuPen className="icon" size={18} onClick={() => openEditSidePanel(v.id)} />
        </Stack>
      ),
    }))
    setWeeklySummaryReportList(newWeeklySummaryReportList)
  }, [weeklySummaryReports])

  // project options dropdown
  const { projectTasks } = useSelector(selectWeeklySummaryReport)
  const [projectOptions, setProjectOptions] = useState([])

  useEffect(() => {
    const newProjectOptions = projectTasks.map((v) => ({
      text: v.name,
      value: v.id,
    }))
    setProjectOptions(newProjectOptions)
  }, [projectTasks])

  // created by options dropdown
  const { employeeList } = useSelector(selectMaster)
  const [creatorOptions, setCreatorOptions] = useState([])

  useEffect(() => {
    const newCreatorOptions = employeeList.map((v) => ({
      text: `${v.first_name} ${v.last_name}`,
      value: v.id,
    }))
    setCreatorOptions(newCreatorOptions)
  }, [employeeList])

  // filter
  const initialFilter = {
    project_task: '',
    created_by: '',
    selected_date: '',
    search: '',
  }
  const [filter, setFilter] = useState(initialFilter)
  const [searchFilters, setSearchFilters] = useState([])

  const onChangePagination = (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$or][${i}][what_done_this_week][$contains]`]: v.name,
    }))

    const filters = {
      ...filter,
      page: value.page,
      pageSize: pageSize,
      multipleSearch,
    }
    fetchWeeklySummaryReports(filters)
  }
  const onChangeFilter = (name, value) => {
    const newFilter = {
      ...filter,
      [name]: value,
    }
    setFilter(newFilter)

    if (name !== 'search') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[$or][${i}][what_done_this_week][$contains]`]: v.name,
      }))
      const filters = {
        ...newFilter,
        page: 1,
        pageSize,
        multipleSearch,
      }
      fetchWeeklySummaryReports(filters)
    }
  }
  const onSearch = () => {
    if (filter.search !== '') {
      const newSearchFilter = {
        key: uuidv4(),
        name: filter.search,
      }

      const multipleSearch = [...searchFilters, newSearchFilter].map((v, i) => ({
        [`filters[$or][${i}][what_done_this_week][$contains]`]: v.name,
      }))

      const filters = {
        ...filter,
        page: 1,
        pageSize: pageSize,
        multipleSearch,
      }

      fetchWeeklySummaryReports(filters)
      setFilter((prev) => ({
        ...prev,
        search: '',
      }))
      setSearchFilters((prev) => [...prev, newSearchFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const currentSearchFilters = searchFilters.filter((v) => v.key !== key)
    const multipleSearch = currentSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][what_done_this_week][$contains]`]: v.name,
    }))

    const filters = {
      ...filter,
      page: 1,
      pageSize: pageSize,
      multipleSearch,
    }
    fetchWeeklySummaryReports(filters)
    setSearchFilters(currentSearchFilters)
  }
  const onClearSearch = () => {
    const filters = {
      ...initialFilter,
      page: 1,
      pageSize: pageSize,
      multipleSearch: [],
    }

    fetchWeeklySummaryReports(filters)
    clearFilter()
  }
  const clearFilter = () => {
    setFilter(initialFilter)
    setSearchFilters([])
  }

  // side panel create
  const [isOpenCreateSidePanel, setIsOpenCreateSidePanel] = useState(false)

  const openCreateSidePanel = () => {
    setIsOpenCreateSidePanel(true)
  }
  const closeCreateSidePanel = () => {
    setIsOpenCreateSidePanel(false)
  }

  // side panel edit
  const [isOpenEditSidePanel, setIsOpenEditSidePanel] = useState(false)

  const openEditSidePanel = (id) => {
    dispatch(getWeeklySummaryReportById(id))
    setIsOpenEditSidePanel(true)
  }
  const closeEditSidePanel = () => {
    setIsOpenEditSidePanel(false)
  }

  // success/fail dialog
  const initialDialogMessage = {
    heading: '',
    subHeading: '',
  }
  const [successMessage, setSuccessMessage] = useState(initialDialogMessage)
  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false)

  const [failMessage, setFailMessage] = useState(initialDialogMessage)
  const [isOpenFailDialog, setIsOpenFailDialog] = useState(false)

  const { modalInfo } = useSelector(selectWeeklySummaryReport)

  // success dialog function
  const openSuccessDialogCaseCreate = () => {
    setSuccessMessage({
      heading: 'Week summary report has been created',
      subHeading: (
        <Box>
          <div>Project : {modalInfo.project_name}</div>
          <div>
            Week : {modalInfo.start_week} - {modalInfo.end_week}
          </div>
        </Box>
      ),
    })
    setIsOpenSuccessDialog(true)
  }
  const openSuccessDialogCaseEdit = () => {
    setSuccessMessage({
      heading: 'Week summary report has been updated',
      subHeading: (
        <Box>
          <div>Project : {modalInfo.project_name}</div>
          <div>
            Week : {modalInfo.start_week} - {modalInfo.end_week}
          </div>
        </Box>
      ),
    })
    setIsOpenSuccessDialog(true)
  }
  const closeSuccessDialog = () => {
    setIsOpenSuccessDialog(false)
  }
  // fail dialog function
  const openFailDialogCaseIsExisted = () => {
    setFailMessage({
      heading: 'Week summary report already existed',
      subHeading: (
        <Box>
          <div>Project : {modalInfo.project_name}</div>
          <div>
            Week : {modalInfo.start_week} - {modalInfo.end_week}
          </div>
        </Box>
      ),
    })
    setIsOpenFailDialog(true)
  }
  const openFailDialogCaseCreateOnlyThisWeek = () => {
    setFailMessage({
      heading: 'Week summary report can only create in this week',
      subHeading: (
        <Box>
          <div>Project : {modalInfo.project_name}</div>
          <div>
            Week : {modalInfo.start_week} - {modalInfo.end_week}
          </div>
        </Box>
      ),
    })
    setIsOpenFailDialog(true)
  }
  const openFailDialogCaseCreateUnknow = () => {
    setFailMessage({
      heading: 'Create Week summary report error!',
      subHeading: 'Please Contact us',
    })
    setIsOpenFailDialog(true)
  }
  const openFailDialogCaseBeNotCreator = () => {
    setFailMessage({
      heading: 'You are not allowed to edit this project',
      subHeading: '',
    })
    setIsOpenFailDialog(true)
  }
  const openFailDialogCaseEditOnlyThisWeek = () => {
    setFailMessage({
      heading: 'Cannot be edit retrospectively',
      subHeading: '',
    })
    setIsOpenFailDialog(true)
  }
  const openFailDialogCaseEditUnknow = () => {
    setFailMessage({
      heading: 'Edit Week summary report error!',
      subHeading: 'Please Contact us',
    })
    setIsOpenFailDialog(true)
  }
  const closeFailDialog = () => {
    setIsOpenFailDialog(false)
  }

  // confirm delete
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false)
  const [weeklySummaryReportId, setWeeklySummaryReportId] = useState('')

  const openConfirmDelete = (id) => {
    setWeeklySummaryReportId(id)
    setIsOpenConfirmDelete(true)
  }
  const closeConfirmDelete = () => {
    setIsOpenConfirmDelete(false)
  }
  const onSubmitDelete = () => {
    const request = {
      data: {
        active: false,
        is_delete: true,
      },
    }
    dispatch(deleteWeeklySummaryReport(weeklySummaryReportId, request))
    closeConfirmDelete()
  }

  // state,isLoading
  const { isLoading, state, errorCase } = useSelector(selectWeeklySummaryReport)

  useEffect(() => {
    if (state === 'CREATE_WEEKLY_SUMMARY_REPORT.SUCCESS' && isLoading === false) {
      openSuccessDialogCaseCreate()
    }
    if (state === 'UPDATE_WEEKLY_SUMMARY_REPORT.SUCCESS' && isLoading === false) {
      openSuccessDialogCaseEdit()
    }
    if (state === 'CREATE_WEEKLY_SUMMARY_REPORT.FAILURE' && isLoading === false) {
      const { error_case } = errorCase

      if (error_case === 'create-project-per-week') openFailDialogCaseIsExisted()
      if (error_case === 'create-only-this-week') openFailDialogCaseCreateOnlyThisWeek()
      if (error_case === 'create-project-error') openFailDialogCaseCreateUnknow()
    }
    if (state === 'UPDATE_WEEKLY_SUMMARY_REPORT.FAILURE' && isLoading === false) {
      const { error_case } = errorCase

      if (error_case === 'you-are-not-creator') openFailDialogCaseBeNotCreator()
      if (error_case === 'update-only-this-week') openFailDialogCaseEditOnlyThisWeek()
      if (error_case === 'update-project-error') openFailDialogCaseEditUnknow()
    }
  }, [state, isLoading, errorCase])

  return (
    <Div>
      <Box sx={{ mb: '24px' }}>
        <div className="feature-header">Weekly Summary Report</div>
        <div className="feature-description">Summarize key updates of projects in every weeks</div>
        <Divider />
      </Box>

      <TableWrapper>
        <div className="table-header">
          <Box sx={{ mb: 5 }}>
            <Stack sx={{ mb: 1 }} direction="row" alignItems="flex-end" spacing={1}>
              <Box sx={{ width: '240px' }}>
                <div className="input-filter-heading">Select Project</div>
                <AutoComplete
                  placeHolder="Select Project"
                  optionList={projectOptions}
                  value={filter.project_task}
                  onChange={(value) => onChangeFilter('project_task', value)}
                />
              </Box>
              <Box sx={{ width: '240px' }}>
                <div className="input-filter-heading">Created by</div>
                <AutoComplete
                  placeHolder="Created by"
                  optionList={creatorOptions}
                  value={filter.created_by}
                  onChange={(value) => onChangeFilter('created_by', value)}
                />
              </Box>
              <Box>
                <div className="input-filter-heading">Select Week</div>
                <Date value={filter.selected_date} onChange={(value) => onChangeFilter('selected_date', value)} />
              </Box>
              <Box>
                <div className="input-filter-heading">Search Topic</div>
                <SearchText
                  placeHolder="Select Week"
                  value={filter.search}
                  onChange={(event) => onChangeFilter('search', event.target.value)}
                  onSubmit={onSearch}
                  onClear={() => onChangeFilter('search', '')}
                />
              </Box>
              <ButtonOutline onClick={onClearSearch}>Clear</ButtonOutline>
            </Stack>

            <BadgeSearch searchFilters={searchFilters} deleteSearchBadge={deleteSearchBadge} />
          </Box>

          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1}>
              <div className="total-heading">Total Weekly Summary Report</div>
              <Badge>{total} items</Badge>
            </Stack>
            <Button append={<FaPlus />} onClick={openCreateSidePanel}>
              Create
            </Button>
          </Stack>
        </div>
        <Table
          columns={weeklySummaryReportColumns}
          data={weeklySummaryReportList}
          onStateChange={onChangePagination}
          page={page}
          pageCount={pageCount}
        />
      </TableWrapper>

      {/* side panel */}
      <SidePanel isOpen={isOpenCreateSidePanel} setIsOpen={setIsOpenCreateSidePanel} width={500}>
        <CreateWeeklySummaryReport onClose={closeCreateSidePanel} />
      </SidePanel>
      <SidePanel isOpen={isOpenEditSidePanel} setIsOpen={setIsOpenEditSidePanel} width={500}>
        <EditWeeklySummaryReport onClose={closeEditSidePanel} />
      </SidePanel>

      {/* Dialog */}
      <DialogSuccess
        open={isOpenSuccessDialog}
        onClose={closeSuccessDialog}
        onSubmit={closeSuccessDialog}
        icon={successIcon}
        title={successMessage.heading}
        description={successMessage.subHeading}
        textYes={t('done')}
      />
      <DialogFail
        open={isOpenFailDialog}
        onClose={closeFailDialog}
        onSubmit={closeFailDialog}
        icon={failIcon}
        title={failMessage.heading}
        description={failMessage.subHeading}
        textYes={t('ok')}
      />
      <DialogConfirmDelete
        open={isOpenConfirmDelete}
        onClose={closeConfirmDelete}
        onSubmit={onSubmitDelete}
        icon={failIcon}
        title="Confirm delete weekly summary report"
      />
    </Div>
  )
}

export default WeeklySummaryReport
