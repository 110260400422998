import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { TbPointFilled } from 'react-icons/tb'
import { BiTrash } from 'react-icons/bi'
import { LuPen } from 'react-icons/lu'
import { LiaTimesSolid } from 'react-icons/lia'
import userIcon from '../../assets/images/user-icon.png'
import successIcon from '../../assets/images/success-icon.png'

import styled from 'styled-components'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import TextField from '../../components/form/TextField'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import Table from '../../components/common/Table'
import Button from '../../components/form/button/Button'
import SidePanel from '../../components/common/SidePanel'
import DialogConfirm from '../../components/dialog/DialogConfirm'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'

import { selectOrgTeam } from '../../redux/selectors'
import {
  getGroupTeamById,
  getTeam,
  createTeam,
  editTeam,
  getTeamById,
  deleteTeam,
} from '../../redux/actions/OrganizationTeam'

// container style
const Wrapper = styled.div`
  .mb-6-px {
    margin-bottom: 6px;
  }
  .mb-12-px {
    margin-bottom: 12px;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }

  .mr-8-px {
    margin-right: 8px;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }

  .icon {
    cursor: pointer;
  }

  .highlight {
    color: var(--Primary-700);
    font-weight: 600;
  }

  .heading {
    color: var(--Gray-900);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  .name {
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.align-items-center {
    align-items: center;
  }

  .w-20 {
    width: 20%;
  }
  .w-30 {
    width: 30%;
  }
  .w-50 {
    width: 50%;
  }
`
const FilterContainer = styled.div`
  border-radius: 12px;
  background: var(--Gray-50);
  padding: 12px 16px;

  .heading-search {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .item-vertical {
    vertical-align: middle;
  }

  .green-outline-btn {
    border-radius: 8px;
    border: 1px solid var(--success-200);
    background: var(--success-50);
    color: var(--success-700);
  }
`
const BadgeSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .badge {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`
const TableWrapper = styled.div`
  .table-headers {
    padding: 1rem 0.875rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: var(--Base-White);
    border: 0.0625rem solid var(--Gray-200);

    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .table-header-name {
        color: var(--Gray-900);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        margin-right: 0.5rem;
      }

      .badge {
        border-radius: 16px;
        border: 1px solid var(--Doing-Badge-Stroke);

        color: var(--Doing-Badge-Font);
        text-align: center;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;

        display: inline-block;
        padding: 2px 8px;
      }
    }
  }
`
const SidePanelContainer = styled.div`
  padding: 24px;
  /* height: 100vh; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: var(--Gray-500);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  .highlight-text {
    color: var(--Primary-700);
  }

  .mb-5-px {
    margin-bottom: 5px;
  }
  .mb-6-px {
    margin-bottom: 6px;
  }
  .mb-12-px {
    margin-bottom: 12px;
  }
  .mb-14-px {
    margin-bottom: 14px;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .mr-20-px {
    margin-right: 20px;
  }

  .icon {
    cursor: pointer;
  }

  .heading {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .heading-2 {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .heading-3 {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`
const InputContainer = styled.div`
  .heading-input {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .w-100 {
    width: 100%;
  }
`
// component style
const BadgeStatus = styled.div`
  border-radius: 16px;

  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;

  display: inline-block;
  padding: 2px 8px;

  &.green-badge {
    border: 1px solid var(--BADGE-4-Border);
    background: var(--BADGE-4-Bg);
    color: var(--BADGE-4-Font);
  }
  &.red-badge {
    border: 1px solid var(--BADGE-2-Border);
    background: var(--BADGE-2-Bg);
    color: var(--BADGE-2-Font);
  }

  .item-vertical {
    vertical-align: middle;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`
// column
const teamColumn = [
  {
    Header: 'No',
    accessor: 'no',
    disableSortBy: false,
  },
  {
    Header: 'Team Name',
    accessor: 'name',
    disableSortBy: false,
  },
  {
    Header: 'Team ID',
    accessor: 'team_id',
    disableSortBy: false,
  },
  {
    Header: 'Team Number',
    accessor: 'team_number',
    disableSortBy: false,
  },
  {
    Header: 'Discipline',
    accessor: 'discipline',
    disableSortBy: false,
  },
  {
    Header: 'Team Type',
    accessor: 'team_type',
    disableSortBy: false,
  },
  {
    Header: 'Period',
    accessor: 'period',
    disableSortBy: false,
  },
  {
    Header: 'No. of Member',
    accessor: 'no_of_member',
    disableSortBy: false,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: false,
  },
  {
    accessor: 'action',
    disableSortBy: false,
  },
]
// initial data
const initialDialogText = {
  heading: '',
  desc: '',
}
const initialForm = {
  name: '',
  team_number: '',
  discipline: '',
  team_type: '',
  period: '',
  group: '',
}
// initial list
const initialPeriodList = [
  {
    text: '7',
    value: 7,
  },
  {
    text: '8',
    value: 8,
  },
  {
    text: '9',
    value: 9,
  },
  {
    text: '10',
    value: 10,
  },
]
const initialTeamTypeList = [
  {
    text: 'คณะกรรมการ',
    value: 'คณะกรรมการ',
  },
  {
    text: 'คณะอนุกรรมการ',
    value: 'คณะอนุกรรมการ',
  },
  {
    text: 'คณะทำงาน',
    value: 'คณะทำงาน',
  },
]
const initialDisciplineList = [
  {
    text: 'เคมี',
    value: 'เคมี',
  },
  {
    text: 'เครื่องกล',
    value: 'เครื่องกล',
  },
  {
    text: 'ไฟฟ้ากำลัง',
    value: 'ไฟฟ้ากำลัง',
  },
  {
    text: 'ไฟฟ้าสื่อสาร',
    value: 'ไฟฟ้าสื่อสาร',
  },
  {
    text: 'โยธา',
    value: 'โยธา',
  },
  {
    text: 'สิ่งแวดล้อม',
    value: 'สิ่งแวดล้อม',
  },
  {
    text: 'เหมืองแร่งานโลหการ',
    value: 'เหมืองแร่งานโลหการ',
  },
  {
    text: 'เหมืองแร่งานงานเหมืองแร่',
    value: 'เหมืองแร่งานงานเหมืองแร่',
  },
  {
    text: 'อุตสาหการ',
    value: 'อุตสาหการ',
  },
]
function OrganizationTeamDetail() {
  const { t } = useTranslation()
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { groupInfo, teamList, teamInfo, page, pageCount, limit, total, isLoading, state } = useSelector(selectOrgTeam)
  const [teams, setTeams] = useState([])
  const [teamId, setTeamId] = useState('')
  // form
  const [formCreateTeam, setFormCreateTeam] = useState(initialForm)
  const [formEditTeam, setFormEditTeam] = useState(initialForm)
  // filter
  const [searchFilters, setSearchFilters] = useState([])
  const [search, setSearch] = useState('')
  const [period, setPeriod] = useState('')
  // side panel
  const [isOpenCreatePanel, setIsOpenCreatePanel] = useState(false)
  const [isOpenEditPanel, setIsOpenEditPanel] = useState(false)
  // dialog
  const [isOpenConfirmCreateDialog, setIsOpenConfirmCreateDialog] = useState(false)
  const [isOpenConfirmEditDialog, setIsOpenConfirmEditDialog] = useState(false)
  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false)
  const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false)
  // dialog text
  const [dialogText, setDialogText] = useState(initialDialogText)

  const fetchTeamData = useCallback(
    (filter) => {
      dispatch(getTeam(filter))
    },
    [dispatch]
  )
  const openEditSidePanel = useCallback((id) => {
    dispatch(getTeamById(id))
    setIsOpenEditPanel(true)
  }, [])
  const openConfirmDelete = useCallback(
    (id) => {
      setTeamId(id)
      setDialogText({
        heading: 'Confirm delete this Team?',
        desc: '',
      })
      setIsOpenConfirmDeleteDialog(true)
    },
    [dispatch]
  )

  useEffect(() => {
    if (isLoading === false && state === 'CREATE_TEAM.SUCCESS') {
      setDialogText({
        heading: 'Create New Team Success',
        desc: '',
      })
      setIsOpenSuccessDialog(true)
      setFormCreateTeam(initialForm)
    }
    if (isLoading === false && state === 'EDIT_TEAM.SUCCESS') {
      setDialogText({
        heading: 'Edit Team Success',
        desc: '',
      })
      setIsOpenSuccessDialog(true)
      setFormCreateTeam(initialForm)
    }
  }, [isLoading, state])

  useEffect(() => {
    dispatch(getGroupTeamById(id))
    const request = {
      groupId: id,
      page: 1,
      pageSize: limit,
    }
    fetchTeamData(request)
  }, [id])

  useEffect(() => {
    const initTeamData = teamList.map((v, i) => ({
      ...v,
      no: i + 1,
      name: (
        <div className="name" onClick={() => gotoMemberDetail(v.id)}>
          {v.name}
        </div>
      ),
      status: (
        <BadgeStatus className={`${v.status === 'active' ? 'green-badge' : 'red-badge'}`}>
          <TbPointFilled className="item-vertical" />
          <span className="item-vertical"> {v.status}</span>
        </BadgeStatus>
      ),
      action: (
        <div>
          <BiTrash className="mr-1-rem icon" size={18} onClick={() => openConfirmDelete(v.id)} />
          <LuPen className="icon" size={18} onClick={() => openEditSidePanel(v.id)} />
        </div>
      ),
    }))

    setTeams(initTeamData)
  }, [teamList, openEditSidePanel, openConfirmDelete])

  useEffect(() => {
    setFormEditTeam((prev) => ({
      ...prev,
      name: teamInfo.name,
      team_number: teamInfo.team_number,
      discipline: teamInfo.discipline,
      team_type: teamInfo.team_type,
      period: teamInfo.period,
      group: id,
    }))
  }, [teamInfo])

  useEffect(() => {
    if (period !== '') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))
      const filter = {
        groupId: id,
        page: 1,
        pageSize: limit,
        multipleSearch,
        period: period,
      }
      fetchTeamData(filter)
    }
  }, [period])
  // navigate
  const gotoMemberDetail = (id) => {
    navigate(`/organization-management/organization/team-detail/member-detail/${id}`)
  }
  const backToPrevious = () => {
    navigate(-1)
  }

  // side panel
  const openCreateSidePanel = () => {
    setIsOpenCreatePanel(true)
  }
  const closeCreateSidePanel = () => {
    setIsOpenCreatePanel(false)
  }
  const closeEditSidePanel = () => {
    setIsOpenEditPanel(false)
  }
  const openConfirmCreateTeam = () => {
    setDialogText({
      heading: 'Confirm create New Team?',
      desc: '',
    })
    setIsOpenConfirmCreateDialog(true)
  }
  const openConfirmEditTeam = () => {
    setDialogText({
      heading: 'Confirm edit New Team?',
      desc: '',
    })
    setIsOpenConfirmEditDialog(true)
  }

  // submit
  const onSubmitCreate = () => {
    const request = { ...formCreateTeam, group: id }
    dispatch(createTeam(request))
    setIsOpenCreatePanel(false)
    setIsOpenConfirmCreateDialog(false)
  }
  const onSubmitEdit = () => {
    const request = { ...formEditTeam }
    dispatch(editTeam(teamInfo.id, request))
    setIsOpenEditPanel(false)
    setIsOpenConfirmEditDialog(false)
  }
  const onSubmitDelete = () => {
    dispatch(deleteTeam(teamId))
    setIsOpenConfirmDeleteDialog(false)
  }

  // filter
  const onChangePagination = async (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    const filter = {
      groupId: id,
      page: value.page,
      pageSize: limit,
      multipleSearch,
      period: period,
    }
    fetchTeamData(filter)
  }
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))
      const filter = {
        groupId: id,
        page: 1,
        pageSize: limit,
        multipleSearch,
        period: period,
      }
      fetchTeamData(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    const filter = {
      groupId: id,
      page: 1,
      pageSize: limit,
      multipleSearch,
      period: period,
    }
    fetchTeamData(filter)
    setSearch('')
  }
  const onClearSearch = () => {
    const filter = {
      groupId: id,
      page: 1,
      pageSize: limit,
      multipleSearch: [],
      period: '',
    }
    fetchTeamData(filter)
    clearData()
  }
  const clearData = () => {
    setSearch('')
    setPeriod('')
    setSearchFilters([])
  }

  // onChange
  const onChangeForm = (event) => {
    const { name, value } = event.target
    setFormCreateTeam((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeDropdown = (name, value) => {
    setFormCreateTeam((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeEditForm = (event) => {
    const { name, value } = event.target
    setFormEditTeam((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeEditDropdown = (name, value) => {
    setFormEditTeam((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  return (
    <Wrapper>
      <FlexContainer className="align-items-center mb-1-rem">
        <RiHome6Line size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px">{t('organization')}</span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px" onClick={backToPrevious}>
          Group
        </span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="highlight">{groupInfo?.name}</span>
      </FlexContainer>

      <div className="heading mb-24-px">{groupInfo?.name}</div>
      <Divider className="mb-1-rem" />

      <FilterContainer className="mb-1-rem">
        <FlexContainer className="mb-6-px">
          <div className="w-30 heading-search mr-1-rem">Search</div>
          <div className="w-30 heading-search">Period</div>
        </FlexContainer>

        <FlexContainer className="mb-12-px">
          <SearchText
            className="w-30 item-vertical mr-1-rem"
            placeholder="Search Title / Attendee"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSubmit={onSearch}
            onClear={() => setSearch('')}
          />
          <div className="w-20 mr-1-rem">
            <Dropdown optionList={initialPeriodList} value={period} onChange={(v) => setPeriod(v)} />
          </div>
          <ButtonOutline className="item-vertical" onClick={onClearSearch}>
            {t('clear_all')}
          </ButtonOutline>
        </FlexContainer>
        <BadgeSearchContainer>
          {searchFilters.map((v) => (
            <div className="badge" key={v.key}>
              <span className="name">{v.name}</span>{' '}
              <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
            </div>
          ))}
        </BadgeSearchContainer>
      </FilterContainer>

      <TableWrapper>
        <div className="table-headers">
          <div className="header-container">
            <div>
              <span className="table-header-name">Team</span>
              <span className="badge">{total} team</span>
            </div>
            <Button onClick={openCreateSidePanel}>New Team</Button>
          </div>
        </div>
        <Table columns={teamColumn} data={teams} page={page} pageCount={pageCount} onStateChange={onChangePagination} />
      </TableWrapper>

      {/* side panel */}
      <SidePanel isOpen={isOpenCreatePanel} setIsOpen={setIsOpenCreatePanel} width={400}>
        <SidePanelContainer>
          <div>
            <FlexContainer className="justify-content-space-between align-items-center mb-12-px">
              <div className="heading">Create New Team</div>
              <LiaTimesSolid className="icon" onClick={() => setIsOpenCreatePanel(!isOpenCreatePanel)} />
            </FlexContainer>
            <Divider className="mb-12-px" />

            <InputContainer className="mb-1-rem">
              <div className="heading-input mb-6-px">Team Name *</div>
              <TextField className="w-100" name="name" value={formCreateTeam.name} onChange={onChangeForm} />
            </InputContainer>
            <InputContainer className="mb-1-rem">
              <div className="heading-input mb-6-px">Team Number</div>
              <TextField
                className="w-100"
                name="team_number"
                value={formCreateTeam.team_number}
                onChange={onChangeForm}
              />
            </InputContainer>
            <InputContainer className="mb-1-rem">
              <div className="heading-input mb-6-px">Period</div>
              <TextField className="w-100" name="period" value={formCreateTeam.period} onChange={onChangeForm} />
            </InputContainer>
            <InputContainer className="mb-1-rem">
              <div className="heading-input mb-6-px">Team Type *</div>
              <Dropdown
                className="w-100"
                name="team_type"
                optionList={initialTeamTypeList}
                value={formCreateTeam.team_type}
                onChange={(value) => onChangeDropdown('team_type', value)}
              />
            </InputContainer>
            <InputContainer className="mb-1-rem">
              <div className="heading-input mb-6-px">Select Engineer Discipline *</div>
              <Dropdown
                className="w-100"
                name="discipline"
                optionList={initialDisciplineList}
                value={formCreateTeam.discipline}
                onChange={(value) => onChangeDropdown('discipline', value)}
              />
            </InputContainer>
          </div>

          <div>
            <Divider className="mb-12-px" />
            <FlexContainer className="justify-content-end">
              <ButtonOutline className="mr-20-px" onClick={closeCreateSidePanel}>
                {t('cancel')}
              </ButtonOutline>
              <Button onClick={openConfirmCreateTeam}>{t('create')}</Button>
            </FlexContainer>
          </div>
        </SidePanelContainer>
      </SidePanel>
      <SidePanel isOpen={isOpenEditPanel} setIsOpen={setIsOpenEditPanel} width={400}>
        <SidePanelContainer>
          <div>
            <FlexContainer className="justify-content-space-between align-items-center mb-12-px">
              <div className="heading">Edit Team</div>
              <LiaTimesSolid className="icon" onClick={() => setIsOpenEditPanel(!isOpenEditPanel)} />
            </FlexContainer>
            <Divider className="mb-12-px" />

            <InputContainer className="mb-1-rem">
              <div className="heading-input mb-6-px">Team Name *</div>
              <TextField className="w-100" name="name" value={formEditTeam.name} onChange={onChangeEditForm} />
            </InputContainer>
            <InputContainer className="mb-1-rem">
              <div className="heading-input mb-6-px">Team Number</div>
              <TextField
                className="w-100"
                name="team_number"
                value={formEditTeam.team_number}
                onChange={onChangeEditForm}
              />
            </InputContainer>
            <InputContainer className="mb-1-rem">
              <div className="heading-input mb-6-px">Period</div>
              <TextField className="w-100" name="period" value={formEditTeam.period} onChange={onChangeEditForm} />
            </InputContainer>
            <InputContainer className="mb-1-rem">
              <div className="heading-input mb-6-px">Team Type *</div>
              <Dropdown
                className="w-100"
                name="team_type"
                optionList={initialTeamTypeList}
                value={formEditTeam.team_type}
                onChange={(value) => onChangeEditDropdown('team_type', value)}
              />
            </InputContainer>
            <InputContainer className="mb-1-rem">
              <div className="heading-input mb-6-px">Select Engineer Discipline *</div>
              <Dropdown
                className="w-100"
                name="discipline"
                optionList={initialDisciplineList}
                value={formEditTeam.discipline}
                onChange={(value) => onChangeEditDropdown('discipline', value)}
              />
            </InputContainer>
          </div>

          <div>
            <Divider className="mb-12-px" />
            <FlexContainer className="justify-content-end">
              <ButtonOutline className="mr-20-px" onClick={closeEditSidePanel}>
                {t('cancel')}
              </ButtonOutline>
              <Button onClick={openConfirmEditTeam}>{t('update')}</Button>
            </FlexContainer>
          </div>
        </SidePanelContainer>
      </SidePanel>
      {/* dialog */}
      <DialogConfirm
        open={Boolean(isOpenConfirmCreateDialog)}
        onClose={() => setIsOpenConfirmCreateDialog(false)}
        onSubmit={onSubmitCreate}
        icon={userIcon}
        title={dialogText.heading}
        description={dialogText.desc}
        textYes={t('confirm')}
        textNo={t('Back')}
      />
      <DialogConfirm
        open={Boolean(isOpenConfirmEditDialog)}
        onClose={() => setIsOpenConfirmEditDialog(false)}
        onSubmit={onSubmitEdit}
        icon={userIcon}
        title={dialogText.heading}
        description={dialogText.desc}
        textYes={t('confirm')}
        textNo={t('Back')}
      />
      <DialogConfirmDelete
        open={Boolean(isOpenConfirmDeleteDialog)}
        onClose={() => setIsOpenConfirmDeleteDialog(false)}
        onSubmit={onSubmitDelete}
        title={dialogText.heading}
        description={dialogText.desc}
      />
      <DialogSuccess
        open={Boolean(isOpenSuccessDialog)}
        onClose={() => setIsOpenSuccessDialog(false)}
        onSubmit={() => setIsOpenSuccessDialog(false)}
        icon={successIcon}
        title={dialogText.heading}
        description={dialogText.desc}
        textYes={t('done')}
      />
    </Wrapper>
  )
}

export default OrganizationTeamDetail
