import { DialogActions, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog } from '@mui/joy'
import { Grow } from '@mui/material'
import { createElement } from 'react'
import Button from './Button'

/**
 * Represents a customizable modal component.
 *
 * @example
 * // Usage example
 * const [open, setOpen] = useState(false);
 *
 * const handleModalClose = () => {
 *   // Logic to handle modal close
 * };
 *
 * const handleOkClick = () => {
 *   // Logic to handle "Ok" button click
 * };
 *
 * const handleCancelClick = () => {
 *   // Logic to handle "Cancel" button click
 * };
 *
 * const modalProps = {
 *   open,
 *   onClose: handleModalClose,
 *   onOk: handleOkClick,
 *   onCancel: handleCancelClick,
 *   okType: 'contained',
 *   okText: 'Confirm',
 *   cancelText: 'Cancel',
 *   titleIcon: <Icon name="info" />,
 *   titleText: 'Modal Title',
 *   contentText: 'This is the modal content.',
 *   modalDialogProps: { maxWidth: 'sm' },
 * };
 *
 * <ModalV2 {...modalProps} />
 */
export default function ModalV2({
  open = false,
  onClose,
  onOk,
  onCancel,
  okType,
  okText,
  okProps,
  showCancel = true,
  cancelType,
  cancelText,
  cancelProps,
  dialogTitle,
  titleIcon,
  titleText = '',
  dialogContent,
  contentText = '',
  footer,
  modalDialogProps = {},
}) {
  return (
    <Modal open={open} onClose={onClose}>
      <Grow in={open} style={{ transformOrigin: 'center', transform: 'revert-layer' }}>
        <ModalDialog {...modalDialogProps}>
          <ModalClose />
          {dialogTitle ? (
            createElement(dialogTitle)
          ) : (
            <DialogTitle sx={{ flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: '1rem' }}>
              {titleIcon}
              <div dangerouslySetInnerHTML={{ __html: titleText }}></div>
            </DialogTitle>
          )}

          {/* // createElement(dialogContent?.type, dialogContent?.props) */}
          {dialogContent ? (
            dialogContent
          ) : (
            <DialogContent sx={{ textAlign: 'center' }}>
              <div dangerouslySetInnerHTML={{ __html: contentText }}></div>
            </DialogContent>
          )}

          {footer === null ? null : footer !== undefined ? (
            createElement(footer)
          ) : (
            <DialogActions>
              <Button
                {...okProps}
                size="large"
                variant={okType || 'contained'}
                onClick={() => {
                  onOk && onOk()
                }}
                sx={{ width: '100%' }}
              >
                {okText || 'Ok'}
              </Button>
              {showCancel && (
                <Button
                  {...cancelProps}
                  size="large"
                  variant={cancelType || 'outlined'}
                  onClick={() => {
                    onCancel && onCancel()
                  }}
                  sx={{ width: '100%' }}
                >
                  {cancelText || 'Cancel'}
                </Button>
              )}
            </DialogActions>
          )}
        </ModalDialog>
      </Grow>
    </Modal>
  )
}
