import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import SearchText from '../components/form/SearchText'
import Button from '../components/form/button/Button'
import ButtonOutline from '../components/form/button/ButtonOutline'
import { default as DropDownSelect, default as DropdownSelect } from '../components/widget/element/DropDownSelect'
import { dlog } from '../utils/helper'
import { getAdminUser, getEmployeeInfo } from '../utils/common'
import { BiDotsVerticalRounded, BiHourglass, BiCheckSquare } from 'react-icons/bi'
import { BsBox } from 'react-icons/bs'
import { AiOutlineFile } from 'react-icons/ai'
import DialogTaskEditor from '../components/dialog/DialogTaskEditor'
import Notification from '../components/notifications/notifications'
import { useTranslation } from 'react-i18next'
import { log_ } from '../utils/apiLog'

const Div = styled.div`
  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }
  .d-flex {
    display: flex;
  }
  .from-flex {
    display: grid;
    grid-template-columns: repeat(5, 250px) 120px;
    grid-template-rows: auto;
    align-content: center;
    column-gap: 1rem;
    margin-bottom: 1rem;
  }
  .d-flex-between {
    display: flex;
    justify-content: space-between; /* Adjust if needed */
    align-items: center; /* Align items vertically in the center */
  }
  .justify-content-flex-start {
    display: flex;
    align-items: center;
  }
  .icon {
    cursor: pointer;
    font-size: 22px;
  }
  .w-100 {
    width: 100%;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mt-1-rem {
    margin-top: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .txt-center {
    text-align: center;
  }

  .header-card {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .content-title-text {
    font-size: 18px;
  }

  .content-header {
    display: flex;
    margin-top: 40px;
  }
  .content-left {
    width: 50%;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;
    margin-right: 5px;

    .task-box {
      display: flex;
      flex-direction: column;
      background-color: #ffffff;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
      padding: 20px;
      margin-bottom: 20px;

      .icon-task-rigth {
        text-align: right;
      }

      .task-box-row {
        display: flex;
      }

      .task-box-text {
        font-size: 16px;
        font-weight: bold;
        color: #000;
        margin-left: 15px;
        margin-top: 10px;
      }

      .amount-task {
        margin-top: 10px;
        font-size: 32px;
        font-weight: bold;
        color: #000;
      }

      .card {
        width: 50px;
        height: 50px;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .card-icon {
        width: 50%;
        height: 50%;
        color: rgb(158, 119, 237);
      }
    }
  }

  .content-right {
    width: 50%;
    margin-left: 5px;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;

    display: flex;
    flex-direction: column;
    height: 100%;

    .wrp-noti-item {
      border: 1px solid gray;
      padding: 10px;
      cursor: pointer;
    }
    .wrp-noti-item:hover {
      background-color: var(--Gray-100);
    }

    .wrp-noti-item-list {
      overflow-y: scroll;
      height: 265px;
    }
    .notifications {
      border: 1px solid rgb(245 245 245);
      margin: 0px;
    }

    .list-group {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .list-group-item {
      border: none;
      background-color: rgb(255 255 255);
      padding: 10px;
      transition: background-color 0.3s;
      cursor: pointer;
    }
    .list-group-item:hover {
      background-color: #f3f3f3;
    }

    .notification-header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .notification-sender {
      font-weight: bold;
      margin-right: 10px;
    }

    .notification-action {
      font-size: 14px;
      margin-right: 10px;
    }

    .notification-due-date {
      font-size: 14px;
      span {
        color: #7f56d9;
        font-weight: 600;
      }
    }

    .notification-body {
      margin-bottom: 10px;
    }

    .notification-row {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    .notification-task-and-project {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 60%;
    }

    .circle-home::after {
      /* position: absolute; */
      content: '';
      display: inline-block;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: rgb(255 255 255);
      border: 1px solid #dbdbdb;
      margin-left: 50px;
      vertical-align: middle;
    }

    .notification-footer {
      color: #999;
      font-size: 14px;
    }

    .notification-date {
      font-weight: bold;
    }
  }

  .title-table {
    font-size: 24px;
    font-weight: bold;
  }
  .title-table-text {
    font-size: 18px;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      /* background: var(--Primary-25); */
    }
  }
  .check-box-style {
    width: 50px;
  }
  .check-box-style .MuiCheckbox-colorPrimary {
    margin: 0;
    padding: 0;
  }

  .filter-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;

    .wrp-filter-menu {
      position: relative;
      display: flex;
    }

    .wrp-filter-btn {
      position: relative;
    }

    .dialog-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 300px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);
      max-height: 300px;

      .wrp-person-list {
        position: relative;
      }
      .person-list {
        height: 220px;
        overflow-y: scroll;
      }

      .person-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 4px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
        }

        .img-profile {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          background-color: var(--Gray-200);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .person-item:hover {
        background-color: var(--Gray-50);
      }
    }

    .filter-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 500px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);

      .wrp-filter-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clear-btn {
          padding: 4px 6px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        .clear-btn:hover {
          background: var(--Primary-25);
        }
      }
      .wrp-filter-option {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .title-option {
          font-weight: 600;
          margin-bottom: 6px;
        }
        .option-item-style {
          border: 1px solid var(--Gray-300);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Gray-50);
        }
        .option-item-style:hover {
          background-color: var(--Gray-100);
        }
        .option-item-active {
          border: 1px solid var(--Primary-600);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Primary-600);
          color: #fff;
        }
      }
    }
  }
  .wrp-tab-header {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    margin: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }
  .wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;
    .btn-menu-style {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      /*border-bottom: 0px solid #EAECF0;*/
      border-bottom: 2px solid #eaecf0 !important;
      color: #667085;
      font-size: 14px;
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      border-bottom: 2px solid var(--Primary-600) !important;
      color: #6941c6;
      background-color: #f9f5ff;
    }
    /*
    .btn-menu-style {
      border: none;
      border-bottom: 2px solid transparent;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: rgb(127 86 217 / 13%);
      border-bottom: 2px solid #7f56d9;
    }
    */
  }

  .txt-noti-title {
    font-weight: bold;
    color: #000;
  }
`
import SidePanel from '../components/common/SidePanel'
import NewDailySummary from '../pages/my-calendar/NewDailySummary'
import NewActionPlan from '../pages/my-calendar/NewActionPlan'

import {
  getMilestoneScopeByProjectId,
  getProjectTaskDropdown,
  getTaskTypeByTaskScope,
} from '../redux/actions/projectTask'
import { getTaskEmployee } from '../redux/actions/taskEmployee'
import { selectProjectTask, selectTaskEmployee } from '../redux/selectors'

const DivMyTask = styled.div`
  padding: 16px 14px;
  border-radius: 8px 8px 0px 0px;
  .box_task_info {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }
  .filter-header-task {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .wrp-filter-menu {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > div {
        margin-right: 10px !important;
        margin-bottom: 10px !important;
      }
    }

    .wrp-filter-btn {
      position: relative;
    }

    .dialog-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 300px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);
      max-height: 300px;

      .wrp-person-list {
        position: relative;
      }
      .person-list {
        height: 220px;
        overflow-y: scroll;
      }

      .person-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 4px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
        }

        .img-profile {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          background-color: var(--Gray-200);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .person-item:hover {
        background-color: var(--Gray-50);
      }
    }

    .filter-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 500px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);

      .wrp-filter-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clear-btn {
          padding: 4px 6px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        .clear-btn:hover {
          background: var(--Primary-25);
        }
      }
      .wrp-filter-option {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .title-option {
          font-weight: 600;
          margin-bottom: 6px;
        }
        .option-item-style {
          border: 1px solid var(--Gray-300);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Gray-50);
        }
        .option-item-style:hover {
          background-color: var(--Gray-100);
        }
        .option-item-active {
          border: 1px solid var(--Primary-600);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Primary-600);
          color: #fff;
        }
      }
    }
    .btn-new-task {
      width: max-content;
    }
  }
  .wrp-paginate-table {
    margin: 1rem 0 0 0;
  }

  .css_task_status {
    font-size: 12px;
    width: 67px;
    height: 22px;
    padding: 0.2rem;
    border: 1.5px solid var(--Gray-300);
    border-radius: 6px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    &.complete {
      border: 1px solid #abefc6;
      border-radius: 16px;
      color: #067647;
      background-color: #ecfdf3;
    }
    &.open {
      border: 1px solid #fedf89;
      border-radius: 16px;
      color: #b54708;
      background-color: #fffaeb;
    }
    &.reopen {
      border: 1px solid #abefc6;
      border-radius: 16px;
      color: #067647;
      background-color: #ecfdf3;
    }
    &.working {
      border: 1px solid #b2ccff;
      border-radius: 16px;
      color: #004eeb;
      background-color: #eff4ff;
    }
  }

  @media (max-width: 600px) {
    .css_header_detail {
      flex-direction: column;
      align-items: flex-start;
    }
    header {
      flex-direction: row;
      align-items: flex-start;
    }
    .content_header {
      width: 100%; 
      position: relative; 
    }
    .dialog-content {
      padding: 0px 14px;
      padding-top: 15px;
      padding-bottom: 106px;
    }
    .dialog-footer {
      width: 100%;
      position: relative;
    }
    .basic-Information-detail {
      flex-direction: row;
      width: 100%;
    }
    .main_box {
      grid-template-columns: 1fr;
    }
    .main_box_activity_result {
      grid-template-columns: 1fr;
    }
    .dialog-img-content {
      width: 90%; 
      padding: 0 10px;
    }
    .time_sort {
      width: 46%;
      margin-top: 10px; 
    } 
    .group_main_box_button, .main_box_button {
      width: 100%;
      display: flex;
      justify-content: end;
      position: absolute;
      right: 3%;
    }
    .ql-container {
      min-height: 100px; 
    }
  }
`

import { getHomeReturnData } from '../redux/actions/notifications'
import TableV2 from '../components/common/TableV2'
const Home = () => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [WidthSidePanel, setWidthSidePanel] = useState(window.innerWidth - 98)
  
  const user = getAdminUser()
  const employee_info = getEmployeeInfo()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  //--- ST ดึงข้อมูล Home
  const [currentMenuTabSelected, setCurrentMenuTabSelected] = useState('ongoing')
  const [menuTabList, setMenuTabList] = useState([
    {
      name: t('on_going_task'),
      value: 'ongoing',
      active_status: true,
    },
    {
      name: t('upcoming_task'),
      value: 'upcoming',
      active_status: false,
    },
    {
      name: t('complete'),
      value: 'complete',
      active_status: false,
    },
    {
      name: t('delayed_task'),
      value: 'delayed',
      active_status: false,
    },
  ])

  const initialStateSearch = {
    search: '',
    projectSelected: '',
    milestoneSelected: '',
    scopeOfWorkSelected: '',
    statusSelected: '',
    createBySelected: '',
  }
  const [searchTable, setSearchTable] = useState(initialStateSearch)

  const [HomeOwner, setHomeOwner] = useState(employee_info?.id ? employee_info?.id : -99)
  const [limitHome, setlimitHome] = useState(10)
  const [pageHome, setpageHome] = useState(1)
  const [totalHome, settotalHome] = useState(0)
  const [totalPagesHome, settotalPagesHome] = useState(0)

  const [OnGoingTask, setOnGoingTask] = useState('-')
  const [DelayedTask, setDelayedTask] = useState('-')
  const [UpcomingTask, setUpcomingTask] = useState('-')
  const [CompleteTask, setCompleteTask] = useState('-')

  const TableColumnTask = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('task_id'),
      accessor: 'task_id',
      disableSortBy: false,
    },
    {
      Header: t('task_name'),
      accessor: 'task_name',
      disableSortBy: false,
    },
    {
      Header: t('project'),
      accessor: 'project',
      disableSortBy: false,
    },
    {
      Header: t('miestone'),
      accessor: 'milestone',
      disableSortBy: false,
    },
    {
      Header: t('scope_of_work'),
      accessor: 'scoopt_of_work',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
  ]
  const [TaskList, setTaskList] = useState([])

  const fetchDataHome = useCallback(
    async (filter) => {
      dispatch(getHomeReturnData(filter)).then(({ type, data_info }) => {
        if (type.endsWith('_SUCCESS')) {
          if (data_info) {
            setOnGoingTask(data_info.total_task.onGoing)
            setDelayedTask(data_info.total_task.delayed)
            setUpcomingTask(data_info.total_task.upComing)
            setCompleteTask(data_info.total_task.complete)

            const totalPage = Math.ceil(data_info.meta.total / limitHome)
            settotalHome(data_info.meta.total)
            settotalPagesHome(totalPage)

            const newTableCellDataTask = data_info.list_task.map((v, index) => ({
              no: limitHome * pageHome - (9 - index),
              task_id: v.task_id ? v.task_id : '-',
              task_name: v.task_name ? v.task_name : '-',
              project: v.project ? v.project : '-',
              milestone: v.milestone ? v.milestone : '-',
              scoopt_of_work: v.scoopt_of_work ? v.scoopt_of_work : '-',
              status: v.status ? (
                <div
                  className={`css_task_status ${
                    v.status === 'Open'
                      ? 'open'
                      : v.status === 'Complete'
                      ? 'complete'
                      : v.status === 'Reopen'
                      ? 'reopen'
                      : v.status === 'Working'
                      ? 'working'
                      : ''
                  }`}
                >
                  {v.status}
                </div>
              ) : (
                '-'
              ),
            }))
            setTaskList(newTableCellDataTask)
          }
        }
      })
    },
    [dispatch]
  )

  useEffect(() => {
    log_("home","home","enter","");
    const filter = {
      skip: 0,
      limit: limitHome,
      owner: HomeOwner, //employee_info?.id ? employee_info?.id : -99,
      tab: currentMenuTabSelected,
    }
    fetchDataHome(filter)
  }, [fetchDataHome])

  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
      } else {
        tmpList[i].active_status = false
      }
    }
    setCurrentMenuTabSelected(data.value)
    setMenuTabList([...tmpList])
    const filter = {
      skip: 0,
      limit: limitHome,
      owner: HomeOwner,
      tab: data.value,
    }
    fetchDataHome(filter)
    setpageHome(1)
    setSearchTable(initialStateSearch)
  }

  const onSearchTaskData = async (value, type) => {
    const filter = {
      skip: 0,
      limit: limitHome,
      owner: HomeOwner,
      tab: currentMenuTabSelected,
      name: type == 'name' ? value : searchTable.search,
      project: type == 'project' ? value : searchTable.projectSelected,
      milestone: type == 'milestone' ? value : searchTable.milestoneSelected,
      scope_of_work: type == 'scope_of_work' ? value : searchTable.scopeOfWorkSelected,
      status: type == 'status' ? value : searchTable.statusSelected,
      create_by: type == 'create_by' ? value : searchTable.createBySelected,
    }
    fetchDataHome(filter)
    setpageHome(1)
  }

  const onChangePaginationHome = async (value) => {
    const filter = {
      skip: (value.page - 1) * limitHome,
      limit: limitHome,
      owner: HomeOwner,
      tab: currentMenuTabSelected,
      name: searchTable.search,
      project: searchTable.projectSelected,
      milestone: searchTable.milestoneSelected,
      scope_of_work: searchTable.scopeOfWorkSelected,
      status: searchTable.statusSelected,
      create_by: searchTable.createBySelected,
    }
    fetchDataHome(filter)
    setpageHome(value.page)
  }

  //--- ED ดึงข้อมูล Home

  const { taskEmployeeList } = useSelector(selectTaskEmployee)
  const { projectList, milestoneScopeList, taskTypeDataList } = useSelector(selectProjectTask)
  const [createdByList, setCreatedByList] = useState([])
  const [isGetEmployee, setIsGetEmployee] = useState(false)
  useEffect(() => {
    if (!isGetEmployee) {
      dispatch(getTaskEmployee())
      setIsGetEmployee(true)
    }
  }, [dispatch])

  useEffect(() => {
    if (taskEmployeeList?.length > 0) {
      let tmpList = [{ text: 'All created by', value: -1 }]
      for (let i = 0; i < taskEmployeeList.length; i++) {
        let tmpObj = {
          text: taskEmployeeList[i].first_name + ' ' + taskEmployeeList[i].last_name,
          value: taskEmployeeList[i].id,
        }
        tmpList.push(tmpObj)
      }
      setCreatedByList([...tmpList])
    }
  }, [taskEmployeeList])

  const pagination = {
    page: 1,
    pageSize: 1000,
    pageCount: 0,
    total: 0,
  }

  const [isGetProjectTask, setIsGetProjectTask] = useState(false)
  useEffect(() => {
    if (!isGetProjectTask) {
      dispatch(getProjectTaskDropdown(pagination))
      setIsGetProjectTask(true)
    }
  }, [dispatch])

  const [notiMenuSelected, setNotiMenuSelected] = useState('all')
  const initStatusDropdown = useState([
    {
      value: 'active',
      text: 'Active',
    },
    {
      value: 'inactive',
      text: 'Inactive',
    },
  ])

  const [notiMenuList, setNotiMenuList] = useState([
    {
      name: 'All',
      value: 'all',
      active_status: true,
    },
    {
      name: 'Read',
      value: 'read',
      active_status: false,
    },
  ])
  const [notiList, setNotiList] = useState([
    {
      topic: 'work 1',
      date: '1/5/2023',
      id: '',
      status: 'read',
    },
    {
      topic: 'work 2',
      date: '2/5/2023',
      id: '',
      status: 'read',
    },
    {
      topic: 'work 3',
      date: '3/5/2023',
      id: '',
      status: 'read',
    },

    {
      topic: 'work 1',
      date: '1/5/2023',
      id: '',
      status: 'read',
    },
    {
      topic: 'work 2',
      date: '2/5/2023',
      id: '',
      status: 'read',
    },
    {
      topic: 'work 3',
      date: '3/5/2023',
      id: '',
      status: 'read',
    },
    {
      topic: 'work 1',
      date: '1/5/2023',
      id: '',
      status: 'read',
    },
    {
      topic: 'work 2',
      date: '2/5/2023',
      id: '',
      status: 'read',
    },
    {
      topic: 'work 3',
      date: '3/5/2023',
      id: '',
      status: 'read',
    },
  ])

  const [isShowNewTaskModal, setIsShowNewTaskModal] = useState(false)
  const [currentTaskType, setCurrentTaskType] = useState('')

  const [isShowPersonPopup, setIsShowPersonPopup] = useState(false)
  const [isShowFilterPopup, setIsShowFilterPopup] = useState(false)
  const [isDataChange, setIsDataChange] = useState(false)

  // Filters
  const [filterPriorityList, setFilterPriorityList] = useState([
    {
      name: 'Low',
      value: 'low',
      active_status: false,
    },
    {
      name: 'Medium',
      value: 'medium',
      active_status: false,
    },
    {
      name: 'High',
      value: 'high',
      active_status: false,
    },
  ])
  const [filterStatusList, setFilterStatusList] = useState([
    {
      name: 'Open',
      value: 'open',
      active_status: false,
    },
    {
      name: 'Working',
      value: 'working',
      active_status: false,
    },
    {
      name: 'Done',
      value: 'done',
      active_status: false,
    },
  ])
  const [filterAgingList, setFilterAgingList] = useState([
    {
      name: '1-3 Days',
      value: '1',
      active_status: false,
    },
    {
      name: '4-7 Days',
      value: '4',
      active_status: false,
    },
    {
      name: '> 7 Days',
      value: '8',
      active_status: false,
    },
  ])

  const handleClickNotiMenu = (data) => {
    let tmpList = [...notiMenuList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setNotiMenuSelected(data.value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setNotiMenuList([...tmpList])
  }
  const handleClickNotiItem = (data) => {}
  //--- ST DailySummary
  const [ModalNewDailySummary, setModalNewDailySummary] = useState(false)
  const handleClickDailySummaryBtn = () => {
    setModalNewDailySummary(true)
  }
  const CloseModalNewDailySummary = () => {
    setModalNewDailySummary(false)
  }
  const SubmitModalNewDailySummary = () => {
    setModalNewDailySummary(false)
  }
  //--- ED DailySummary
  //--- ST ActionPlan
  const [ModalNewActionPlan, setModalNewActionPlan] = useState(false)
  const handleClickActionPlanBtn = () => {
    setModalNewActionPlan(true)
  }
  const CloseModalNewActionPlan = () => {
    setModalNewActionPlan(false)
  }
  const SubmitModalNewActionPlan = () => {
    setModalNewActionPlan(false)
  }
  //--- ED ActionPlan

  const handleClickPersonBtn = () => {
    setIsShowPersonPopup(!isShowPersonPopup)
    setIsShowFilterPopup(false)
  }
  const handleClickSelectPerson = (personData) => {}
  const handleClickFilterBtn = () => {
    setIsShowFilterPopup(!isShowFilterPopup)
    setIsShowPersonPopup(false)
  }
  const handleClickSelectFilterOption = (type, val) => {
    if (type == 'priority') {
      let tmpList = [...filterPriorityList]
      for (let i = 0; i < tmpList.length; i++) {
        if (tmpList[i].value == val.value) {
          tmpList[i].active_status = true
        } else {
          tmpList[i].active_status = false
        }
      }
      setPrioritySelected(val.value)
      setFilterPriorityList([...tmpList])
    }

    if (type == 'status') {
      let tmpList = [...filterStatusList]
      for (let i = 0; i < tmpList.length; i++) {
        if (tmpList[i].value == val.value) {
          tmpList[i].active_status = true
        } else {
          tmpList[i].active_status = false
        }
      }
      setStatusSelected(val.value)
      setFilterStatusList([...tmpList])
    }

    if (type == 'aging') {
      let tmpList = [...filterAgingList]
      for (let i = 0; i < tmpList.length; i++) {
        if (tmpList[i].value == val.value) {
          tmpList[i].active_status = true
        } else {
          tmpList[i].active_status = false
        }
      }
      setAgingSelected(val.value)
      setFilterAgingList([...tmpList])
    }
  }
  const handleClickClearAllOption = () => {
    let tmpPriorityList = [...filterPriorityList]
    for (let i = 0; i < tmpPriorityList.length; i++) {
      tmpPriorityList[i].active_status = false
    }
    setFilterPriorityList([...tmpPriorityList])
    setPrioritySelected('')

    let tmpStatusList = [...filterStatusList]
    for (let i = 0; i < tmpStatusList.length; i++) {
      tmpStatusList[i].active_status = false
    }
    setFilterStatusList([...tmpStatusList])
    setStatusSelected('')

    let tmpAgingList = [...filterAgingList]
    for (let i = 0; i < tmpAgingList.length; i++) {
      tmpAgingList[i].active_status = false
    }
    setFilterAgingList([...tmpAgingList])
    setAgingSelected('')
  }

  const handleClickedTask = (id) => {
    setTaskId(id)
    setIsShowPreviewDialog(true)
  }

  const handleDataChange = () => {
    setIsDataChange(!isDataChange)
    const filter = {
      skip: 0,
      limit: limitHome,
      owner: HomeOwner,
      tab: currentMenuTabSelected,
    }
    fetchDataHome(filter)
    setpageHome(1)
    setSearchTable(initialStateSearch)
    setIsShowNewTaskModal(false)
  }

  const [totalSummaryData, setTotalSummaryData] = useState(false)
  const [isDataChangeV2, setisDataChangeV2] = useState(false)
  const [milestoneList, setMilestoneList] = useState([])
  const [projectListState, setProjectListState] = useState([])
  const [scopeOfWorkList, setScopeOfWorkList] = useState([])
  const statusList = [
    {
      text: 'All Status',
      value: -1,
    },
    {
      text: 'Open',
      value: 'Open',
    },
    {
      text: 'Working',
      value: 'Working',
    },
    {
      text: 'Complete',
      value: 'Complete',
    },
  ]

  useEffect(() => {
    if (projectList?.length > 0) {
      let tmpList = [{ text: 'All projects', value: -1 }]
      for (let i = 0; i < projectList.length; i++) {
        let tmpObj = {
          text: projectList[i].name,
          value: projectList[i].id,
        }
        tmpList.push(tmpObj)
      }
      setProjectListState([...tmpList])
    }
  }, [projectList])

  useEffect(() => {
    let tmpList = [{ text: 'All milestones', value: -1 }]
    if (milestoneScopeList?.length > 0) {
      for (let i = 0; i < milestoneScopeList.length; i++) {
        let tmpTaskScope = milestoneScopeList[i]?.milestone
        let tmpObj = {
          text: tmpTaskScope?.name,
          value: tmpTaskScope?.id,
        }
        tmpList.push(tmpObj)
      }
    }
    setMilestoneList([...tmpList])
  }, [milestoneScopeList])

  useEffect(() => {
    let tmpList = [{ text: 'All scope of works', value: -1 }]
    if (taskTypeDataList?.length > 0) {
      for (let i = 0; i < taskTypeDataList.length; i++) {
        let tmpObj = {
          text: taskTypeDataList[i]?.attributes?.name,
          value: taskTypeDataList[i].id,
        }
        tmpList.push(tmpObj)
      }
    }
    setScopeOfWorkList([...tmpList])
  }, [taskTypeDataList])

  const handleDropdownSelected = (value, type) => {
    if (type == 'project') {
      setSearchTable({ ...searchTable, projectSelected: value, milestoneSelected: '', scopeOfWorkSelected: '' })
      if (value >= 0) {
        dispatch(getMilestoneScopeByProjectId(value))
      }
      setMilestoneList([{ text: 'All milestones', value: -1 }])
      setScopeOfWorkList([{ text: 'All scope of works', value: -1 }])
      onSearchTaskData(value, 'project')
    } else if (type == 'milestone') {
      setSearchTable({ ...searchTable, milestoneSelected: value, scopeOfWorkSelected: '' })
      if (value >= 0) {
        dispatch(getTaskTypeByTaskScope(value))
      }
      setScopeOfWorkList([{ text: 'All scope of works', value: -1 }])
      onSearchTaskData(value, 'milestone')
    } else if (type == 'scope_of_work') {
      setSearchTable({ ...searchTable, scopeOfWorkSelected: value })
      onSearchTaskData(value, 'scope_of_work')
    } else if (type == 'status') {
      setSearchTable({ ...searchTable, statusSelected: value })
      onSearchTaskData(value, 'status')
    } else if (type == 'create_by') {
      setSearchTable({ ...searchTable, createBySelected: value })
      onSearchTaskData(value, 'create_by')
    }
    setisDataChangeV2(!isDataChangeV2)
  }

  const handleDataChangeV2 = async () => {
    dlog('handleDataChangeV2 !!!')
    setisDataChangeV2(!isDataChangeV2)
    setIsShowNewTaskModal(false)
    setIsShowPreviewDialog(false)
    let tmpPagination = { ...pagination }
    tmpPagination.pageSize = 10
    dispatch(getProjectTaskDropdown(tmpPagination))
  }

  const handleSetSummaryData = (data) => {
    setTotalSummaryData(data)
  }

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (windowWidth < 1024) {
      setWidthSidePanel(windowWidth)
    } else setWidthSidePanel(windowWidth - 98)
  }, [windowWidth])

  return (
    <Div>
      <header>
        <div className="content-title">
          {user?.first_name} {user?.last_name}
        </div>
        <div className="content-title-text">
          {t('departments')}: {employee_info?.department ? employee_info?.department : '-'} {t('position')}:{' '}
          {employee_info?.position ? employee_info?.position : '-'}
        </div>
        <div className="content-header">
          <div className="content-left">
            <div className="task-box">
              <span className="icon-task-rigth">
                <BiDotsVerticalRounded className="icon" />
              </span>
              <div className="task-box-row">
                <div className="card">
                  <BsBox className="card-icon" />
                </div>
                <span className="task-box-text">{t('on_going_task')}</span>
              </div>
              <div className="amount-task">{OnGoingTask}</div>
            </div>

            <div className="task-box">
              <span className="icon-task-rigth">
                <BiDotsVerticalRounded className="icon" />
              </span>
              <div className="task-box-row">
                <div className="card">
                  <AiOutlineFile className="card-icon" />
                </div>
                <span className="task-box-text">{t('upcoming_task')}</span>
              </div>
              <div className="amount-task">{UpcomingTask}</div>
            </div>

            <div className="task-box">
              <span className="icon-task-rigth">
                <BiDotsVerticalRounded className="icon" />
              </span>
              <div className="task-box-row">
                <div className="card">
                  <BiHourglass className="card-icon" />
                </div>
                <span className="task-box-text">{t('delayed_task')}</span>
              </div>
              <div className="amount-task">{DelayedTask}</div>
            </div>

            <div className="task-box">
              <span className="icon-task-rigth">
                <BiDotsVerticalRounded className="icon" />
              </span>
              <div className="task-box-row">
                <div className="card">
                  <BiCheckSquare className="card-icon" />
                </div>
                <span className="task-box-text">{t('complete_task')}</span>
              </div>
              <div className="amount-task">{CompleteTask}</div>
            </div>
          </div>
          <div className="content-right">
            <div className="wrp-tab-header">{t('update')}</div>
            <Notification />
          </div>
        </div>
      </header>

      <div className="d-flex-between">
        <div className="title-table">{t('task')}</div>
        <div className="justify-content-flex-start">
          <ButtonOutline onClick={handleClickDailySummaryBtn} className="mr-1-rem">
            {t('new_daily_summary_report')}
          </ButtonOutline>
          <ButtonOutline
            onClick={() => {
              setCurrentTaskType('new_task')
              setIsShowNewTaskModal(true)
            }}
            className="mr-1-rem"
          >
            {t('new_task')}
          </ButtonOutline>
          <Button className="mr-1-rem" onClick={handleClickActionPlanBtn}>
            {t('action_plan')}
          </Button>
        </div>
      </div>
      {/* ST TASK DATA */}
      <div className="title-table-text">{t('stay_on_top')}</div>
      <div className="table-wrapper">
        <div className="table-header">
          <div className="wrp-tab-menu">
            {menuTabList?.map((item, index) => (
              <button
                onClick={() => handleClickTaskMenuTab(item)}
                key={'btn-' + index}
                className={item.active_status ? 'menu-active' : 'btn-menu-style'}
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </div>
      <DivMyTask>
        <div className="box_task_info">
          <div className="filter-header-task">
            <div className="wrp-filter-menu">
              <SearchText
                className="search-text"
                placeholder={t('search')}
                value={searchTable.search}
                onChange={(e) => setSearchTable({ ...searchTable, search: e.target.value })}
                onSubmit={(e) => onSearchTaskData(e, 'name')}
                onClear={() => {
                  setSearchTable({ ...searchTable, search: '' })
                  onSearchTaskData('', 'name')
                }}
              ></SearchText>
              <DropDownSelect
                className="dropdown"
                dataList={projectListState}
                initValue={searchTable.projectSelected}
                onChange={(e) => handleDropdownSelected(e, 'project')}
                placeHolder={t('project')}
              />
              <DropDownSelect
                className="dropdown"
                dataList={milestoneList}
                initValue={searchTable.milestoneSelected}
                onChange={(e) => handleDropdownSelected(e, 'milestone')}
                placeHolder={t('miestone')}
                isDisabled={searchTable.projectSelected ? false : true}
              />
              <DropDownSelect
                className="dropdown"
                dataList={scopeOfWorkList}
                initValue={searchTable.scopeOfWorkSelected}
                onChange={(e) => handleDropdownSelected(e, 'scope_of_work')}
                placeHolder={t('scope_of_work')}
                isDisabled={searchTable.milestoneSelected ? false : true}
              />
              <DropdownSelect
                className="dropdown"
                dataList={statusList}
                initValue={searchTable.statusSelected}
                onChange={(e) => handleDropdownSelected(e, 'status')}
                placeHolder={t('status')}
              />
              <DropdownSelect
                className="dropdown"
                dataList={createdByList}
                initValue={searchTable.createBySelected}
                onChange={(e) => handleDropdownSelected(e, 'create_by')}
                placeHolder={t('created_by')}
              />
            </div>
          </div>

          <div className="wrp-paginate-table">
            <TableV2
              columns={TableColumnTask}
              data={TaskList}
              onStateChange={onChangePaginationHome}
              pageCount={totalPagesHome}
              page={pageHome}
              loading={false}
            />
          </div>
        </div>
      </DivMyTask>
      {isShowNewTaskModal && (
        <DialogTaskEditor
          taskId={currentTaskType == 'edit_task' && taskId}
          type={currentTaskType}
          open={isShowNewTaskModal}
          onClose={() => setIsShowNewTaskModal(false)}
          onSuccess={() => handleDataChange()}
        />
      )}

      <SidePanel isOpen={ModalNewDailySummary} setIsOpen={setModalNewDailySummary} width={400}>
        <NewDailySummary onClose={CloseModalNewDailySummary} onSubmit={SubmitModalNewDailySummary} />
      </SidePanel>
      
      <SidePanel isOpen={ModalNewActionPlan} setIsOpen={setModalNewActionPlan} width={WidthSidePanel}>
        <NewActionPlan width={WidthSidePanel} onClose={CloseModalNewActionPlan} onSubmit={SubmitModalNewActionPlan} />
      </SidePanel>
    </Div>
  )
}

export default Home
