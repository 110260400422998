import {
  Tooltip,
  IconButton,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Accordion as MuiAccordion,
  Stack,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material'
import { styled } from '@mui/system'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { LuClipboardList, LuFlag } from 'react-icons/lu'
import { MdExpandMore, MdOutlineContentPasteSearch } from 'react-icons/md'
import { RiDownloadLine, RiEyeLine } from 'react-icons/ri'
import { LiaFileSolid } from 'react-icons/lia'

import Button from 'components/common/Button'
import Empty from 'components/common/Empty'
import { Option, Select } from 'components/form2/form-select'
import { TASK } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { Link } from '@mui/joy'

const ActionCell = ({ items = [] }) => {
  return (
    <Stack direction="row" sx={{ gap: '0.5rem' }}>
      {items.map((item, index) => (
        <Tooltip key={index} title={item?.text}>
          <IconButton onClick={item?.action} size="small">
            {item?.icon}
          </IconButton>
        </Tooltip>
      ))}
    </Stack>
  )
}

/**
 * Renders a tab for displaying and managing tasks.
 *
 * @component
 * @param {Object[]} items - An array of task items.
 * @param {Function} openTask - A callback function to open a task.
 * @param {Function} taskCallback - A callback function to handle task actions.
 * @returns {JSX.Element} The rendered TaskTab component.
 */
const TaskTab = ({ items = [], openTask, taskCallback, hideActions }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)
  const { setValue, handleSubmit, register, watch } = useForm()

  const { task: taskActions } = opActions()
  const { getTask, postTask } = taskActions()
  const { task_employees, task_employees_pagination } = opProcSelector

  const fetchTaskEmployees = useCallback(
    async (taskId) => {
      dispatch(getTask().employees(taskId))
    },
    [dispatch, getTask]
  )

  const handleAssignTaskToEmployee = (taskId, data) => {
    const { employee } = data
    const findData = employee ? employee.find((d) => d?.task_id === taskId) : null

    if (!findData) {
      return
    }

    const assignData = {
      employee: { id: findData.id },
    }

    dispatch(postTask().assign(taskId, assignData)).then((res) => {
      try {
        const { data: res_data, type } = res

        if (type.endsWith('_SUCCESS') && res_data !== null && !res.error) {
          taskCallback && taskCallback()
          return
        }
        return
      } catch (err) {
        console.error(err)
      }
    })
  }

  const handleOpenTask = (taskId) => {
    openTask(taskId)
  }

  const actionOptions = (fileUrl, fileName) => {
    return [
      {
        icon: <RiEyeLine />,
        text: t('preview'),
        action: () => {
          window.open(fileUrl, '_blank')
        },
      },
      {
        icon: <RiDownloadLine />,
        text: t('download'),
        action: () => {
          fetch(fileUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = fileName
              a.click()

              window.URL.revokeObjectURL(url) // free up storage--no longer needed.
            })
            .catch((error) => console.error(error))
        },
      },
    ]
  }

  if (!items.length) {
    return <Empty title={t('sow_empty_task_title')} />
  }

  return (
    <TaskWrapper>
      {items.map((item, taskIndex) => {
        const {
          code: taskCode,
          description: taskDescription,
          employee: taskEmployee,
          id: taskId,
          name: taskName,
          status: taskStatus,
          department: taskDepartment,
          deliveries: taskDeliveries,
        } = item

        return (
          <>
            <TaskCardContainer key={taskId}>
              <TaskCardHeader>
                <h3 className="task-title" onClick={() => handleOpenTask(taskId)}>
                  {taskName}
                </h3>
                <StyledChip label={taskStatus} color={TASK.STATUS_CHIP[taskStatus]} />
              </TaskCardHeader>

              <section>
                <label>{t('department')}:</label>
                <p>{taskDepartment?.name}</p>
              </section>

              <section>
                <label>{t('task_code')}:</label>
                <p>{taskCode}</p>
              </section>

              {taskDescription && (
                <section>
                  <label>{t('description')}:</label>
                  <p>{taskDescription}</p>
                </section>
              )}

              <section
                style={{
                  marginBottom:
                    taskDeliveries && Array.isArray(taskDeliveries) && taskDeliveries.length > 0 ? '1.25rem' : '',
                }}
              >
                <label>{t('assign_to')}</label>
                {!taskEmployee ? (
                  <Form onSubmit={handleSubmit((formValues) => handleAssignTaskToEmployee(taskId, formValues))}>
                    <Select
                      {...register(`employee.${taskIndex}.id`)}
                      onChange={(_, data) => {
                        setValue(`employee.${taskIndex}.id`, data)
                        setValue(`employee.${taskIndex}.task_id`, taskId)
                      }}
                      value={watch(`employee.${taskIndex}.id`)}
                      onListboxOpenChange={async (open) => {
                        if (open) {
                          fetchTaskEmployees(taskId)
                          return
                        }
                      }}
                      disabled={hideActions}
                    >
                      {task_employees.map((employee) => (
                        <Option
                          key={employee.id}
                          value={employee.id}
                          renderValue={(option) => {
                            if (!option || option === null) {
                              return <div style={{ color: 'var(--Gray-300)' }}>Select assignee</div>
                            }
                            return option.label
                          }}
                        >
                          {employee.full_name}
                        </Option>
                      ))}
                    </Select>

                    <Button type="submit" variant="outlined" disabled={hideActions}>
                      {t('assign')}
                    </Button>
                  </Form>
                ) : (
                  <Stack direction="row" spacing={2}>
                    <p>
                      {taskEmployee.first_name} {taskEmployee.last_name}
                    </p>
                  </Stack>
                )}
              </section>

              {taskDeliveries && Array.isArray(taskDeliveries) && taskDeliveries.length > 0 && (
                <Accordion>
                  <AccordionSummary
                    aria-controls={`content1d-${taskIndex}`}
                    id={`header1d-${taskIndex}`}
                    expandIcon={<MdExpandMore />}
                    sx={{ marginBottom: 0 }}
                  >
                    <LuClipboardList size={20} style={{ marginRight: '0.5rem' }} />
                    <h3>{`${t('work')} (${taskDeliveries.length})`}</h3>
                  </AccordionSummary>

                  <AccordionDetails>
                    <List dense>
                      {taskDeliveries.map((delivery, index) => (
                        <ListItem
                          key={delivery.id}
                          secondaryAction={
                            delivery?.files &&
                            Array.isArray(delivery.files) &&
                            delivery.files.length > 0 && (
                              <Stack direction="row" spacing={2}>
                                <Link
                                  level="body-sm"
                                  onClick={() => window.open(delivery?.files[0]?.file.url, '_blank')}
                                >
                                  {delivery?.files[0]?.name}
                                </Link>
                                <ActionCell
                                  items={actionOptions(delivery?.files[0]?.file.url, delivery?.files[0]?.name)}
                                />
                              </Stack>
                            )
                          }
                        >
                          <ListItemIcon>
                            <LuFlag size={20} />
                          </ListItemIcon>

                          <ListItemText
                            primary={<h3>{delivery.name}</h3>}
                            secondary={delivery.is_completed ? 'ส่งงานแล้ว' : 'ยังไม่ได้ส่งงาน'}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              )}
            </TaskCardContainer>
          </>
        )
      })}
    </TaskWrapper>
  )
}

export default TaskTab

const TaskWrapper = styled(Stack)`
  height: auto;
  padding: 0 1.25rem;

  // Webkit scrollbar - style #2
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #b3b3b3;
  }
`

const TaskCardContainer = styled(Stack)`
  padding: 20px 0 20px 0;
  border: 1px solid var(--Gray-200);
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  background-color: var(--Base-White);
  width: 100%;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
    transition: box-shadow 0.3s ease 0s;
  }

  p,
  h3,
  label {
    margin: 0;

    /* Text sm/Medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;

    color: var(--Gray-700);
  }

  h3 {
    font-size: 16px;
    font-weight: 600;

    color: var(--Gray-900);
    margin-bottom: 1rem;

    &.task-title {
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: var(--Primary-600);
      }
    }
  }

  & section {
    display: flex;
    gap: 1rem;
    padding: 0 20px;
    margin-bottom: 0.5rem;

    & > label {
      width: 100px;
      font-weight: 600;
    }

    & p {
      margin: 0;
      font-weight: 400 !important;
    }
  }
`

/** TODO: This code is repeated in multiple places */
const StyledChip = styled(Chip)`
  font-family: 'Inter', 'Noto Sans Thai';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 150% */
  color: var(--Gray-700);

  display: flex;
  padding: 0.1875rem 0.5rem;
  justify-content: center;
  align-items: center;

  border-radius: 1rem;
  border: 1px solid var(--Gray-300);
  background: var(--Gray-50);

  & .MuiChip-icon {
    margin-left: 0;
    margin-right: 0.5rem;
  }

  & .MuiChip-label {
    padding: 0;
  }

  &.MuiChip-colorSuccess,
  &.MuiChip-colorInfo,
  &.MuiChip-colorWarning,
  &.MuiChip-colorDefault {
    /* border-radius: 1rem !important; */
  }

  &.MuiChip-colorSuccess {
    color: var(--Success-700, #067647);
    border: 1px solid var(--Success-200, #abefc6) !important;
    background: var(--Success-50, #ecfdf3) !important;
  }

  &.MuiChip-colorWarning {
    color: var(--Warning-700, #b54708);
    border: 1px solid var(--Warning-200, #fedf89);
    background: var(--Warning-50, #fffaeb);
  }

  &.MuiChip-colorInfo {
    color: var(--Blue-700, #175cd3);
    border: 1px solid var(--Blue-200, #b2ddff) !important;
    background: var(--Blue-50, #eff8ff) !important;
  }
`

const Form = styled('form')`
  display: flex;
  gap: 1rem;
`

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid var(--Gray-200)`,
  borderBottom: 0,
  borderLeft: 0,
  borderRight: 0,
  color: 'inherit',
  marginTop: '0.75rem',

  '&:last-child': {
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
  },

  '&::before': {
    display: 'none',
  },

  h3: {
    fontSize: '16px',
    fontWeight: 600,
    margin: '0 !important',
    lineHeight: '1.25rem',

    color: 'var(--Gray-900)',
    marginBottom: '1rem',
  },
}))

const TaskCardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 1.25rem 0 1.25rem;
`
