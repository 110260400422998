import React, { useEffect, useState, useCallback } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import EditRequestLogContent from '../../components/feature/edit-request-log/EditRequestLogContent'
import ArchivedDataContent from '../../components/feature/edit-request-log/ArchivedDataContent'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { TbPointFilled } from 'react-icons/tb'

const Div = styled.div`
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }

  .headline {
    color: var(--Gray-900, #101828);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
  }
`
const HeadingMenu = styled.div`
  display: flex;
  align-items: center;

  color: var(--Gray-600);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;

  .highlight {
    color: var(--Primary-700);
    font-weight: 600;
  }
  .text-center {
    text-align: center;
  }
  .header-step {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-weight: 600;

    &.active {
      color: var(--Primary-700);
    }
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`
const TabMenuContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  .tab-menu {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-500);
    padding: 0.75rem;
    :hover {
      cursor: pointer;
    }
    &.active {
      color: var(--Primary-700);
      border-bottom: 2px solid var(--Primary-700);
      background: var(--Primary-50);
    }
  }
`
function EditRequestApproval() {
  const { t } = useTranslation()
  const [tab, setTab] = useState('edit-request-log')

  const toggleTab = (tab) => {
    setTab(tab)
  }

  return (
    <Div>
      <HeadingMenu className="mb-2-rem">
        <RiHome6Line size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px">{t('hr_organization')}</span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="highlight">Edit Request Approvals</span>
      </HeadingMenu>

      <div className="headline mb-20-px">Edit Request Approvals</div>
      <div className="mb-24-px">Review, Verify, and Action Pending Changes</div>
      <Divider className="mb-24-px" />

      <TabMenuContainer>
        <span
          className={`tab-menu ${tab === 'edit-request-log' ? 'active' : ''}`}
          onClick={() => toggleTab('edit-request-log')}
        >
          Edit Request Log
        </span>
        <span
          className={`tab-menu ${tab === 'archived-data' ? 'active' : ''}`}
          onClick={() => toggleTab('archived-data')}
        >
          Archived data
        </span>
      </TabMenuContainer>

      {tab === 'edit-request-log' ? <EditRequestLogContent /> : <ArchivedDataContent />}
    </Div>
  )
}

export default EditRequestApproval
