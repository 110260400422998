import { getAPI, postAPI } from 'utils/api'
import { DOWNLOAD_REPORT } from 'redux/actionTypes'
import { showLoading, hideLoading } from 'redux/actions/loading'

export const getReports =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: DOWNLOAD_REPORT.GET.GET_REPORTS.REQUEST })

      const params = {
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        sort: 'id:desc',
        'fields[0]': 'topic',
        'fields[1]': 'start_date',
        'fields[2]': 'end_date',
        'fields[3]': 'createdAt',
        'fields[4]': 'status',
        'fields[5]': 'download_url',
        'populate[creator][fields][0]': 'first_name',
        'populate[creator][fields][1]': 'last_name',
      }

      const { data, meta } = await getAPI({
        url: '/api/download-reports',
        params,
      })

      return dispatch({ type: DOWNLOAD_REPORT.GET.GET_REPORTS.SUCCESS, data, meta })
    } catch (error) {
      return dispatch({ type: DOWNLOAD_REPORT.GET.GET_REPORTS.FAILURE })
    } finally {
      dispatch(hideLoading())
    }
  }

export const generateReport = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DOWNLOAD_REPORT.POST.GENERATE_REPORT.REQUEST })

    await postAPI({
      url: '/api/download-reports',
      data,
    })

    return dispatch({
      type: DOWNLOAD_REPORT.POST.GENERATE_REPORT.SUCCESS,
      showAlert: true,
      alertMessage: 'Generate Report has succeeded!',
    })
  } catch (error) {
    return dispatch({
      type: DOWNLOAD_REPORT.POST.GENERATE_REPORT.FAILURE,
      showAlert: true,
      alertMessage: 'Generate Report has failed!',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getReports())
  }
}
