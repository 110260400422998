import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectSharedService } from '../../../redux/selectors'

import styled from 'styled-components'
import ReactQuill from 'react-quill'
import TextField from '../../../components/form/TextField'
import RadioButton from '../../../components/form/RadioButton'
import Dropdown2 from '../../../components/form/Dropdown2'
import Date from '../../../components/form/Date'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import ModalPdpaPreview from '../../../components/pdpa/ModalPdpaPreview'
import FileUploadProgress2 from '../../form/FileUploadProgress2'

import { GrView } from 'react-icons/gr'

const Div = styled.div``
const InputContainer = styled.div`
  margin-bottom: 1.5rem;

  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .w-input {
    width: 100%;
  }

  .w-30 {
    width: 30%;
  }
`

const TextEditorContainer = styled.div`
  box-sizing: border-box;
  padding-bottom: 65px;

  .text-editor-headline {
    color: var(--Gray-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
  }
`
const FlexContainer = styled.div`
  display: flex;
  width: 100%;

  .flex-item-1 {
    flex: 1;
  }
`

const initLangRadioOptionList = [
  {
    text: 'Thai',
    value: 'th',
  },
  {
    text: 'English',
    value: 'en',
  },
]

const initDocumentForm = {
  creator: {
    id: '',
  },
  template: {
    id: '',
  },
  version: '',
  due_date: null,
  topics: [],
  files: null,
  publish_to: '',
  departments: null,
  employees: null,
  email_noti: false,
  in_app_noti: true,
  // new
  name: '',
  doc_lang: 'th',
  is_star: 0,
  reference: null,
  other_topic: '',
}

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    ['link', 'image', 'video'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: 2 }],
    [{ color: [] }, { background: [] }],
    ['formula'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ align: [] }],
    ['clean'],
  ],
}
const acceptList = {
  files: {
    'application/pdf': [],
  },
}
const multiple = false
const error = false
const disabled = true

function DocumentContent() {
  const { t } = useTranslation()
  // store
  const { documentAcknowledgementInfo } = useSelector(selectSharedService)
  // data
  const [documentForm, setDocumentForm] = useState(initDocumentForm)
  const [versionDigit1, setVersionDigit1] = useState('0')
  const [versionDigit2, setVersionDigit2] = useState('0')
  // file
  const [file, setFile] = useState([])
  const [acceptTypes, setAcceptTypes] = useState([])
  const [mimeTypes, setMimeTypes] = useState([])
  // dropdown list
  const [versionList, setVersionList] = useState([])
  // modal
  const [previewPdpaModal, setPreviewPdpaModal] = useState(false)

  useEffect(() => {
    generateVersionList()
    initFileType()
    getMimeTypes()
  }, [])

  useEffect(() => {
    if (Object.keys(documentAcknowledgementInfo).length > 0) {
      setDocumentForm((prev) => ({
        ...prev,
        name: documentAcknowledgementInfo?.name,
        reference: documentAcknowledgementInfo?.id,
        is_star: documentAcknowledgementInfo?.is_star,
        topics: documentAcknowledgementInfo.topics,
        due_date: documentAcknowledgementInfo.due_date,
        template: documentAcknowledgementInfo?.template,
        other_topic: documentAcknowledgementInfo?.other_topic,
        files: documentAcknowledgementInfo?.files?.map((v) => v.id),
      }))
      const newFile = documentAcknowledgementInfo?.files?.map((v) => ({
        id: v?.id,
        url: v?.url,
        name: v?.alternativeText,
        size: v?.size,
        progress_percent: 100,
        progress_display: 'OK',
        progress_is_error: false,
      }))
      setFile(newFile ?? [])

      const version = documentAcknowledgementInfo?.version
        .toString()
        .split('.')
        .map((v) => v)
      setVersionDigit1(version[0])
      setVersionDigit2(version[1] ?? '0')
    }
  }, [documentAcknowledgementInfo])

  // modal
  const previewPada = () => {
    setPreviewPdpaModal(true)
  }

  // optional
  const generateVersionList = () => {
    const generatedNumberArr = Array.from({ length: 10 }, (_, i) => i)
    const newVersionList = generatedNumberArr?.map((v) => ({
      text: v.toString(),
      value: v.toString(),
    }))
    setVersionList(newVersionList)
  }
  // file
  const handleAddFile = async (file_id) => {
    setDocumentForm((prev) => ({
      ...prev,
      files: [file_id],
    }))
  }
  const onDeleteFile = (id) => {
    setFile(file.filter((v) => v.id !== id))
    setDocumentForm((prev) => ({
      ...prev,
      files: null,
    }))
  }
  const initFileType = () => {
    const acceptType = [].reduce((pre, cur) => {
      return {
        ...pre,
        ...acceptList[cur],
      }
    }, {})
    setAcceptTypes(acceptType)
  }

  // init data
  const getMimeTypes = () => {
    const categoryObjects = Object.values(acceptList)
    const mimeTypes = categoryObjects.flatMap((category) => Object.keys(category))

    setMimeTypes(mimeTypes)
  }

  return (
    <Div>
      <InputContainer>
        <div className="input-header">{t('document_name')} *</div>
        <TextField className={`w-input`} name="name" disabled={true} value={documentForm?.name} />
      </InputContainer>

      <FlexContainer>
        <InputContainer className="flex-item-1 mr-12-px">
          <div className="input-header">{t('document_template')}</div>
          <TextField className="w-input" value={documentAcknowledgementInfo?.template?.name} disabled={true} />
        </InputContainer>
        <InputContainer className="flex-item-1 mr-12-px">
          <div className="input-header">{t('category')}</div>
          <TextField className="w-input" value={documentAcknowledgementInfo?.category?.name} disabled={true} />
        </InputContainer>
        <InputContainer className="flex-item-1">
          <div className="input-header">{t('type')}</div>
          <TextField className="w-input" value={documentAcknowledgementInfo?.type} disabled={true} />
        </InputContainer>
      </FlexContainer>

      <FlexContainer>
        <InputContainer className="flex-item-1 mr-12-px">
          <div className="input-header">{t('version')} *</div>
          <Dropdown2 className={`mr-20-px`} optionList={versionList} value={versionDigit1} disabled={true} />
          <span className="mr-20-px">.</span>
          <Dropdown2 className={`mr-20-px`} optionList={versionList} value={versionDigit2} disabled={true} />
        </InputContainer>
        <InputContainer className="flex-item-1 mr-12-px">
          <div className="input-header">{t('due_date')} *</div>
          <Date className="w-input" value={documentForm?.due_date} disabled={true} />
        </InputContainer>
        <InputContainer className="flex-item-1">
          <div className="input-header">Document language version</div>
          <RadioButton
            name="doc_lang"
            optionList={initLangRadioOptionList}
            value={documentForm?.doc_lang}
            disabled={true}
          />
        </InputContainer>
      </FlexContainer>

      <div className="divider mb-24-px"></div>

      <div className="d-flex mb-32-px">
        <ButtonOutline append={<GrView />} onClick={previewPada}>
          {t('preview')}
        </ButtonOutline>
      </div>

      {documentForm?.topics?.length === 0 && (
        <TextEditorContainer>
          <ReactQuill
            style={{ height: '226px' }}
            theme="snow"
            modules={modules}
            value={documentForm?.other_topic}
            readOnly={true}
          />
        </TextEditorContainer>
      )}
      {documentForm?.topics?.map((v, i) => (
        <div className="mb-1-rem" key={v.id}>
          <TextEditorContainer>
            <div className="text-editor-headline mb-1-rem">{v?.title}</div>
            <ReactQuill
              style={{ height: '226px' }}
              className="mb-1-rem"
              theme="snow"
              modules={modules}
              value={v?.description}
              readOnly={true}
            />
          </TextEditorContainer>
          {/* <ButtonOutline className="add-image-btn" append={<img src={userPlus} />}>
                {t('add_image')}
              </ButtonOutline> */}
        </div>
      ))}

      <FileUploadProgress2
        className="upload-container"
        multiple={multiple}
        accept={acceptTypes}
        mimeTypes={mimeTypes}
        file={file?.map((v) => ({ ...v, url: `${v.url}` }))}
        onAddFile={handleAddFile}
        onDeleteFile={onDeleteFile}
        error={error}
        disabled={disabled}
        FileListData={file}
        setFileListData={setFile}
      />

      <ModalPdpaPreview
        previewData={documentForm}
        isModalOpen={previewPdpaModal}
        onClose={() => setPreviewPdpaModal(false)}
      />
    </Div>
  )
}

export default DocumentContent
