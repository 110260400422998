import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ButtonOutline from '../form/button/ButtonOutline'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import styled from 'styled-components'

const Div = styled.div``

const ButtonOutlineCollapsible = ({ id, text, list, icon, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (action) => {
    action()
    setAnchorEl(null)
  }

  return (
    <Div {...props}>
      {text ? (
        <ButtonOutline onClick={handleClick} id={id} append={icon}>
          {text}
        </ButtonOutline>
      ) : (
        <ButtonOutline onClick={handleClick} id={id}>
          {icon}
        </ButtonOutline>
      )}

      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={() => setAnchorEl(null)}>
        {list?.map((v, i) => (
          <MenuItem id={v.id ? v.id : i} key={v.text} onClick={() => handleClose(v?.action)}>
            {v?.text}
          </MenuItem>
        ))}
      </Menu>
    </Div>
  )
}

ButtonOutlineCollapsible.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string.isRequired,
      action: PropTypes.func,
    })
  ),
  icon: PropTypes.string,
}

export default ButtonOutlineCollapsible
