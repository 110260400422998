import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getSellerList } from '../../../redux/actions/document'
import { selectDocument } from '../../../redux/selectors'
import useOutsideClick from '../helper/useOutsideClick'
import SearchSuggestBox from './SearchSuggestBox'
export default function SearchSuggestSeller({
  selectedId,
  placeHolder,
  onSelectedSuggest,
  isError = false,
  departmentId,
  isFilterByDepartment = false,
  isDisabled = false,
  isDisableOwnerId = false,
  type = 'seller_id',
  onTextChange,
}) {
  const dispatch = useDispatch()

  const { sellerList } = useSelector(selectDocument)
  const [dropDownViews, setDropDownView] = useState([])
  const [searchText, setSearchText] = useState('')
  const [selectedName, setSelectedName] = useState('')
  const [isOnBlur, setIsOnBlur] = useState(false)
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))

  const outerRef = useOutsideClick(() => {
    setSearchText('')
  })

  useEffect(() => {
    let filters
    if ((type = 'seller_id')) {
      filters = {
        sellerId: searchText,
      }
    } else {
      filters = {
        sellerName: searchText,
      }
    }
    dispatch(getSellerList(filters))
  }, [searchText])

  useEffect(() => {
    if (sellerList?.length > 0) {
      let tmpData = []
      for (let i = 0; i < sellerList.length; i++) {
        let tmp = {
          type: 'item-image',
          value: {
            title: sellerList[i]?.name || '',
            desc: sellerList[i]?.seller_id || '',
          },
        }
        tmpData = [...tmpData, tmp]
      }

      let views = []
      for (let i = 0; i < tmpData.length; i++) {
        if (tmpData[i]?.type == 'header') {
          views.push(<div className={'header'}>{tmpData[i]?.value}</div>)
        } else if (tmpData[i]?.type == 'item-normal' || tmpData[i]?.type == 'item-image') {
          views.push(
            <div className={'item-normal'}>
              {/* {tmpData[i]?.type == 'item-image' && (
                <div className="item-img">
                  {tmpData[i]?.value?.image ? <img src={tmpData[i].value.image} alt="search user image" /> : ''}
                </div>
              )} */}
              <div className="item-texts">
                {tmpData[i]?.value?.title && <div className="title">{tmpData[i]?.value?.title}</div>}
                {tmpData[i]?.value?.desc && <div className="desc">{tmpData[i]?.value?.desc}</div>}
              </div>
            </div>
          )
        }
      }
      setDropDownView([...views])
    } else {
      setDropDownView([])
    }
  }, [sellerList])

  useEffect(() => {
    if (sellerList?.length > 0 && selectedId) {
      // 
      for (let i = 0; i < sellerList.length; i++) {
        if (sellerList[i].id == selectedId) {
          if (type == 'seller_id') {
            setSelectedName(sellerList[i].seller_id)
          } else {
            setSelectedName(sellerList[i].name)
          }
        }
      }
    }
  }, [sellerList, selectedId])

  const onSelectSuggest = (index) => {
    const data = sellerList[index]

    if (type == 'seller_id') {
      setSelectedName(data.seller_id)
    } else {
      setSelectedName(data.name)
    }
    onSelectedSuggest(data)
    setIsOnBlur(true)
  }
  return (
    <Styled ref={outerRef}>
      <SearchSuggestBox
        onChange={(e) => {
          setSearchText(e)
          if (onTextChange) {
            onTextChange(e)
          }
        }}
        dropdownViews={dropDownViews}
        onSelected={(index) => onSelectSuggest(index)}
        className={'search-suggest'}
        searchWidth="300px"
        dropdownWidth="300px"
        placeHolder={placeHolder}
        initValue={selectedName}
        borderColor={isError ? '#d92d20' : '#d0d5dd'}
        isDisabled={isDisabled}
        isHideIcon
      />
    </Styled>
  )
}

const Styled = styled.div`
  .title {
    font-weight: bold;
  }
  .title,
  .desc {
    /* width: 200px; */
    width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .title {
    margin-right: 10px;
    font-size: 18px;
  }
  .item-texts {
    display: flex;
    align-items: center;
    width: 310px;
    text-overflow: ellipsis;
  }
  .list-dropdown {
    min-height: 50px;
    .no-employ {
      top: 12px !important;
    }
  }
`
