import styled from 'styled-components'
import * as React from 'react'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { GetPermissionOther } from '../../utils/common'
import { getActionPlan, updateActionPlan } from '../../redux/actions/dailysummary'
import { getEmployeeDropdrow } from '../../redux/actions/master'
import { selectDailySummary, selectMaster } from '../../redux/selectors'

import Button from '../../components/form/button/Button'
import TableV2 from '../../components/common/TableV2'
import AutoComplete from '../../components/form/AutoComplete'
import SidePanel from '../../components/common/SidePanel'
import NewActionPlan from '../../pages/my-calendar/NewActionPlan'
import EditActionPlan from '../../pages/my-calendar/EditActionPlan.js'
import ViewActionPlan from '../../pages/my-calendar/ViewActionPlan'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import Date from '../../components/form/Date'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { LiaTimesSolid } from 'react-icons/lia'

import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import { log_ } from '../../utils/apiLog'

const DivMyCalendar = styled.div`
  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .content-fillter-bg {
    min-height: 68px;
    background-color: var(--Gray-50);
    border-radius: 8px;
    margin-bottom: 21px;
    padding: 10px;
  }
  .content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
      margin-right: 12px;
    }

    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }

    .group_filter {
      display: flex;
      align-items: center;
    }
  }
  .content-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    .search-wrapper {
      margin-right: 12px;
    }

    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }

    .group_filter {
      display: flex;
      align-items: center;
    }
  }
  .box_search {
    margin-top: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .cursor_pointer {
    cursor: pointer;
  }
  .button_clear_all {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #eaecf0;
  }
  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 10px;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
  .col-no {
    font-size: 14px;
    width: 6%;
  }
  .col-Action_Plan_Date {
    font-size: 14px;
    width: 8%;
  }
  .col-Action_Plan_By {
    font-size: 14px;
    width: 15%;
  }
  .col-activity {
    font-size: 14px;
    width: 22%;
  }
  .col-result {
    font-size: 14px;
    width: 22%;
  }
  .col-type_of_activity {
    font-size: 14px;
    width: 15%;
  }
  .col-buttonDelete {
    font-size: 14px;
    width: 6%;
  }
  .col-linkEdit {
    font-size: 14px;
    width: 6%;
  }

  .text-bold {
    font-weight: bold;
  }

  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .css_text_not_employee {
    color: red;
  }
  .css_autocom {
    min-width: 250px;
    margin-right: 10px;
  }
`
const ActionPlan = () => {
  const initLang = localStorage.getItem('lang')
  //--- ST ส่วนของ Permission LV3-4
  const permissionDel = GetPermissionOther('action_plan', 'delete')
  //--- ST ส่วนของ Permission LV3-4
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { user, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { ActionPlanList, limit, total, skip, isLoading } = useSelector(selectDailySummary)
  const { employeeList } = useSelector(selectMaster)

  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [WidthSidePanel, setWidthSidePanel] = useState(window.innerWidth - 98)
  const [currentDate, setcurrentDate] = useState(dayjs().format('YYYY-MM-DD'))
  const [EmployeeIdCalendar, setEmployeeIdCalendar] = useState(employee_info?.id ? employee_info?.id : -99)
  const [employeeOption, setemployeeOption] = useState([])
  const [employee, setemployee] = useState('')

  const [TypeDel, setTypeDel] = useState('Action Plan')
  const [ThisId, setThisId] = useState('')

  const [ActionPlanId, setActionPlanId] = useState('')

  // modal
  const [ModalCreate, setModalCreate] = useState(false)
  const [ModalConfirmDel, setModalConfirmDel] = useState(false)
  const [ModalEditData, setModalEditData] = useState(false)
  const [ModalViewActionPlan, setModalViewActionPlan] = useState(false)

  //-- ST Filter
  const [search, setSearch] = useState('')
  const [searchFilters, setSearchFilters] = useState([])

  //-- ED Filter
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('date'),
      accessor: 'Action_Plan_Date',
      disableSortBy: false,
    },
    {
      Header: t('action_plan'),
      accessor: 'Action_Plan_By',
      disableSortBy: false,
    },
    {
      Header: t('create_action_plan_time_table_activity'),
      accessor: 'activity',
      disableSortBy: false,
    },
    {
      Header: t('create_action_plan_time_table_result'),
      accessor: 'result',
      disableSortBy: false,
    },
    {
      Header: t('type_of_activity'),
      accessor: 'type_of_activity',
      disableSortBy: false,
    },
    {
      accessor: 'buttonDelete',
      disableSortBy: true,
    },
    {
      accessor: 'linkEdit',
      disableSortBy: true,
    },
  ]

  // เรียกใช้งาน API ผ่าน dispatch เช่น dispatch(getEmployee(filter))
  const fetchData = useCallback(
    async (filter) => {
      dispatch(getActionPlan(filter))
    },
    [dispatch]
  )

  useEffect(() => {
    log_('MyCalendar', 'ActionPlan', 'enter', '')
    const handleResize = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (windowWidth < 1024) {
      setWidthSidePanel(windowWidth)
    } else setWidthSidePanel(windowWidth - 98)
  }, [windowWidth])

  // ตัวแปรจาก STORE และตัวที่เก็บข้อมูลจากการเรียก API
  useEffect(() => {
    const filter = {
      skip: 1,
      limit: 10000,
    }
    dispatch(getEmployeeDropdrow(filter))
  }, [dispatch])
  useEffect(() => {
    const newEmployee = employeeList.map((v) => ({
      text: v?.first_name + ' ' + v?.last_name,
      value: v?.id,
    }))
    setemployeeOption([...newEmployee])
  }, [employeeList])
  useEffect(() => {
    const filter = {
      skip: 1,
      limit: 10,
      filter_date: currentDate,
    }
    fetchData(filter)
  }, [fetchData, limit])
  // bind ข้อมูลที่ได้จาก API โดยรับมาจาก ตัวแปรของ Store ที่ประกาศไว้
  useEffect(() => {
    const regex = /(<([^>]+)>)/gi
    const newTableCellData = ActionPlanList.map((v, index) => ({
      ...v,
      no: index + 1,
      Action_Plan_Date: dayjs(v.action_plan_date_start).format('D MMM YYYY'),
      Action_Plan_By: (
        <div className="text-bold link" onClick={() => OnModalViewActionPlan(v.id)}>
          {v.create_by}
        </div>
      ),
      activity: v.action_plan_details?.data
        ? v.action_plan_details?.data[0]?.attributes?.activity
          ? v.action_plan_details.data[0].attributes.activity.replace(regex, ' ').length > 50
            ? v.action_plan_details.data[0].attributes.activity.replace(regex, ' ').substring(0, 50) + '...'
            : v.action_plan_details.data[0].attributes.activity.replace(regex, ' ')
          : '-'
        : '-',
      result: v.action_plan_details?.data
        ? v.action_plan_details?.data[0]?.attributes?.result
          ? v.action_plan_details.data[0].attributes.result.replace(regex, ' ').length > 50
            ? v.action_plan_details.data[0].attributes.result.replace(regex, ' ').substring(0, 50) + '...'
            : v.action_plan_details.data[0].attributes.result.replace(regex, ' ')
          : '-'
        : '-',
      type_of_activity: v.action_plan_details?.data
        ? v.action_plan_details?.data[0]?.attributes?.type_of_activity
          ? v.action_plan_details?.data[0]?.attributes?.type_of_activity
          : '-'
        : '-',
      buttonDelete:
        permissionDel?.canRead === true ? (
          <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(v.id)} />
        ) : (
          '-'
        ),
      linkEdit: <img className="cursor_pointer" src={iconEdit} title="Edit" onClick={() => EditData(v.id)} />,
    }))
    setTableCellData(newTableCellData)
  }, [ActionPlanList])
  useEffect(() => {
    const totalPage = Math.ceil(total / limit)
    setTotalPages(totalPage)
  }, [total, skip, limit])

  // search
  const onSearch = () => {
    const newFilter = {
      key: uuidv4(),
      name: search,
    }
    const filter = {
      skip: 1,
      limit: limit,
      action_plan: [...searchFilters, newFilter].map((v) => v.name).join(','),
      filter_date: currentDate,
    }

    fetchData(filter)
    setPage(1)
    setSearchFilters((prev) => [...prev, newFilter])
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)

    const filter = {
      skip: 1,
      limit: limit,
      action_plan: newSearchFilters.map((v) => v.name).join(','),
      filter_date: currentDate,
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
  }
  const onSearchEmployee = (v) => {
    setemployee(v)
    const filter = {
      skip: 1,
      limit: limit,
      filter_date: currentDate,
      EmployeeId: v,
    }
    fetchData(filter)
    setPage(1)
  }
  const onClearAllSearch = () => {
    setSearchFilters([])
    setemployee('')
    setcurrentDate(null)
    const filter = {
      skip: 1,
      limit: limit,
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
  }
  const onChangecurrentDate = (value) => {
    setcurrentDate(value)
    const filter = {
      skip: 1,
      limit: limit,
      action_plan: searchFilters.map((v) => v.name).join(','),
      filter_date: value,
      EmployeeId: employee,
    }

    fetchData(filter)
    setPage(1)
  }

  const EditData = (data) => {
    setThisId(data)

    setModalViewActionPlan(false)
    setModalEditData(true)
  }
  const DelData = (data) => {
    //alert(data)

    if (permissionDel?.canUpdate === true) {
      setThisId(data)
      setModalConfirmDel(true)
    } else {
      let message_permission = 'No permission allowed ' + permissionDel?.name_en
      if (initLang == 'th') {
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionDel?.name_th
      }
      setTitleFail(message_permission)
      setnameItemFail('')
      setDescriptionFail('')
      setFailModal(true)
    }
  }
  const CloseModalConfirmDel = () => {
    setModalConfirmDel(false)
  }
  const SubmitModalConfirmDel = () => {
    setModalConfirmDel(false)
    const sent_data_api = {
      data: { active: 0 },
    }
    log_('MyCalendar', 'del ActionPlan', 'click', '{id:' + ThisId + '}')
    //dispatch(updateActionPlan(ThisId, sent_data_api));
    dispatch(updateActionPlan(ThisId, sent_data_api)).then(({ type }) => {
      if (type.endsWith('_SUCCESS')) {
        onClearAllSearch()
      }
    })
  }

  const SubmitModalEditData = () => {
    log_('MyCalendar', 'edit ActionPlan', 'click', '{id:' + ThisId + '}')
    onClearAllSearch()
    setModalEditData(false)
  }
  const CloseModalEditData = () => {
    setModalEditData(false)
  }
  const OpenCreateModal = () => {
    log_('MyCalendar', 'create ActionPlan', 'click', '')
    setModalCreate(true)
  }
  const CloseModalCreate = () => {
    log_('MyCalendar', 'cancel ActionPlan', 'click', '')
    setModalCreate(false)
  }
  const SubmitModalCreate = () => {
    onClearAllSearch()
    log_('MyCalendar', 'submit ActionPlan', 'click', '')
    setModalCreate(false)
  }

  //--- View ActionPlan
  const OnModalViewActionPlan = (id) => {
    setActionPlanId(id)
    log_('MyCalendar', 'view ActionPlan', 'click', '{id:' + id + '}')
    setModalEditData(false)
    setModalViewActionPlan(true)
  }
  const CloseModalViewActionPlan = () => {
    setModalViewActionPlan(false)
  }

  // Function การใช้งานอื่นๆ
  const onChangePagination = async (value) => {
    const filter = {
      skip: value.page,
      limit: limit,
      filter_date: currentDate,
      EmployeeId: employee,
    }
    fetchData(filter)
    setPage(value.page)
  }

  return (
    <DivMyCalendar>
      <div className="content-title">{t('action_plan')}</div>
      <div className="">{t('feature_action_plan_sub_header')}</div>
      <div className="border_header"></div>
      <div className="content-fillter-bg">
        <div className="content-fillter">
          <div className="group_filter">
            <div className="css_autocom">
              <AutoComplete
                placeHolder="Created by"
                optionList={employeeOption}
                value={employee}
                onChange={onSearchEmployee}
              />
            </div>
            <div>
              <Date className={'mr-1-rem'} value={currentDate} onChange={onChangecurrentDate} />
            </div>
            <div>
              <Button onClick={onClearAllSearch} className="button_clear_all mr-1-rem">
                {t('clear_all')}
              </Button>
            </div>
          </div>

          <div className="container">
            {EmployeeIdCalendar > 0 && (
              <Button append={<img src={iconPlus} />} onClick={OpenCreateModal}>
                {t('create_my_plan')}
              </Button>
            )}
            {EmployeeIdCalendar < 0 && (
              <div className="css_text_not_employee">
                {t('you_may_not_fully_use_this_feature_since_you_are_not_employee')}
              </div>
            )}
          </div>
        </div>
        <div className="badge-search-content">
          {searchFilters.map((v) => (
            <div className="badge" key={v.key}>
              <span className="name">{v.name}</span>{' '}
              <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
            </div>
          ))}
        </div>
      </div>

      <div className="table-wrapper">
        <div className="table-header">
          <div className="group">
            <div className="table-title">{t('action_plan')}</div>
            <div className="table-total">
              {total} {t('items')}
            </div>
          </div>
        </div>
        <TableV2
          columns={TableColumn}
          data={TableCellData}
          onStateChange={onChangePagination}
          pageCount={totalPages}
          page={page}
          loading={isLoading}
        />
      </div>

      {/* side panel */}
      <SidePanel isOpen={ModalCreate} setIsOpen={setModalCreate} width={WidthSidePanel}>
        <NewActionPlan width={WidthSidePanel} onClose={CloseModalCreate} onSubmit={SubmitModalCreate} />
      </SidePanel>
      <SidePanel isOpen={ModalEditData} setIsOpen={setModalEditData} width={WidthSidePanel}>
        <EditActionPlan
          width={WidthSidePanel}
          onClose={CloseModalEditData}
          onSubmit={SubmitModalEditData}
          ActionPlanId={ThisId}
        />
      </SidePanel>
      <SidePanel isOpen={ModalViewActionPlan} setIsOpen={setModalViewActionPlan} width={WidthSidePanel}>
        <ViewActionPlan
          width={WidthSidePanel}
          onClose={CloseModalViewActionPlan}
          onSubmit={EditData}
          ActionPlanId={ActionPlanId}
        />
      </SidePanel>

      {/* dialog */}
      <DialogConfirmDelete
        open={Boolean(ModalConfirmDel)}
        onClose={() => setModalConfirmDel(false)}
        onSubmit={SubmitModalConfirmDel}
        title={t('confirm_delete_action_plan_header')}
        nameItem=""
        description=""
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />
    </DivMyCalendar>
  )
}

export default ActionPlan
