import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export default function InputText({
  className,
  placeHolder = 'type here',
  initValue,
  onChange,
  onBlur,
  name,
  padding = '10px',
  margin = '0',
  width,
  borderWidth = '1px',
  borderColor = 'var(--Gray-300)',
  borderRadius = '10px',
  backgroundColor = 'transparent',
  fontSize = '14px',
  fontColor = 'var(--Gray-500)',
  isError = false,
  pattern,
  disabled,
  isLowerCase,
  step,
  type = 'text',
}) {
  const inputReference = useRef(null)
  const [isInputFocus, setIsInputFocus] = useState(false)

  const [txt, setTxt] = useState('')

  useEffect(() => {
    setTxt(initValue)
  }, [initValue])

  const onWrpClickHandler = () => {
    if (!isInputFocus) {
      setIsInputFocus(true)
      inputReference.current.focus()
    }
  }

  const handleOnChange = (e) => {
    let tmpText = e.target.value
    tmpText = isLowerCase ? tmpText?.toLowerCase() : tmpText
    setTxt(tmpText)
    if (!disabled) {
      if (onChange) {
        onChange(tmpText)
      }
    }
  }

  const blurHandler = (e) => {
    setIsInputFocus(false)
    if (onBlur) {
      onBlur(e)
    }
    handleOnChange(e);
  }

  return (
    <Styled
      className={className}
      padding={padding}
      margin={margin}
      width={width}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      borderColor={!isError ? borderColor : '#d92d20 !important'}
      backgroundColor={backgroundColor}
      fontSize={fontSize}
      fontColor={fontColor}
      onClick={onWrpClickHandler}
    >
      <input
        name={name}
        ref={inputReference}
        defaultValue={txt}
        placeholder={placeHolder}
        onBlur={blurHandler}
        type={type}
        step={step}
        pattern={pattern}
        onChange={handleOnChange}
        disabled={disabled}
      />
    </Styled>
  )
}

const Styled = styled.div`
  position: relative;
  display: flex;
  width: ${({ width }) => width};
  overflow: hidden;

  margin: ${({ margin }) => margin};
  border-width: ${({ borderWidth }) => borderWidth};
  border-color: ${({ borderColor }) => borderColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  border-style: solid;
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ fontColor }) => fontColor};

  input,
  input:focus-visible,
  input:focus {
    border: none;
    outline: none;
  }

  input {
    display: flex;
    flex: 1;
    padding: ${({ padding }) => padding};
    font-size: 16px;
    background: none;
    user-select: none;
    margin: 0;
  }
  .icon {
    position: relative;
    margin-right: 5px;
    top: 10px;
    color: #555;
  }

  .sf_icon {
    position: relative;
    margin-left: 5px;
    top: 10px;
  }
  .sf_btn {
    position: relative;
  }
  .icon_eye {
    padding: 5px;
    cursor: pointer;
    margin-right: 5px;
  }
  .icon_eye svg {
    position: relative;
    top: 4px;
  }
`
