import React from 'react'

import styled from 'styled-components'
import { Box, Divider } from '@mui/material'

const Header = styled.header`
  h1,
  p {
    margin: 0;
  }

  h1 {
    font-size: 1.875rem;
    color: var(--COE-Main-CI);
    font-style: normal;
    font-weight: 600;
  }
  p {
    color: var(--Base-Font-Title-SUB-Color);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }
`

function HeaderPage({ heading, subHeading, className, ...props }) {
  return (
    <Header className={className} {...props}>
      <h1>{heading}</h1>
      <Box sx={{ marginBottom: '20px' }}>
        <p>{subHeading}</p>
      </Box>
      <Divider />
    </Header>
  )
}

export default HeaderPage
