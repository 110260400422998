import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledCard = styled.div`
  border-radius: 12px;
  border: 1px solid var(--Gray-200);
  background: var(--Base-White);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  display: inline-block;
  padding: 24px;
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
`

const Card = ({ children, width, ...props }) => {
  return (
    <StyledCard maxWidth={width} {...props}>
      {children}
    </StyledCard>
  )
}

Card.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
}

export default Card
