import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { getMeetingMessage,createMeetingMessage } from '../../redux/actions/dailysummary'
import { selectDailySummary } from '../../redux/selectors'
import { DateDisplay,TimeDisplayHHmm,DateTimeHHmmDisplay,DateTimeHHmmssDisplay } from '../../utils/common'

import TextField from '../../components/form/TextField'
import iconChatbox from '../../assets/images/icon_chatbox.png'
import { useTranslation } from 'react-i18next'

const DivMain = styled.div`
color: var(--Gray-600);
font-size: 14px;
font-style: normal;
font-weight: 500;
.css_summary{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
}  
.title_chat_message{
    font-size: 18px;
    font-weight: 600;
    color: var(--Gray-900);
}
.css_summary_magin_top{
    margin-top : 10px;
}
.border_header{
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
}

.group_chatbox_new {
    display: flex;
    align-items: center;
    gap: 10px;
    .text_css{
        min-width: 350px;
    }
    .Reply{
        color:#475467;
        font-size:14px;
        font-weight: normal;
        text-align: end;
        cursor: pointer;
        text-decoration: underline;
        padding-left: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        /*background-color: antiquewhite;*/
        display: flex;
        align-items: center;
        :hover{
            background-color:#e4e4e7;
        }
    }
    .img-user{
        height: 25px;
        width: 25px; 
    }
}
`

const DivChatBox = styled.div`
  .user_img{
    border-radius: 50%;
    background-color: #0079a5;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #ffffff;
  }
  .user_img_reply{
    border-radius: 50%;
    background-color: #e9d7fe;
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #000000;
  }
  img{
    /*width : 100%;*/
    height: 48px;
    width: 48px;
    border-radius: 50%;
  }
  .section{
    padding: 10px 0px;
    display : flex;
    position: relative;
  }
  .reply_space{
    width: 72px;
    position: relative;
  }
  
  .section_reply{
    /*padding: 10px 0px;*/
    display : flex;
    
  }
  .comment-body{
    padding-left: 12px;
  }
  .name {
    color:#344054;
    font-size : 14px;
    font-weight: 600;
  }
  .date_sent_message{
    color:#475467;
    font-size:12px;
    font-weight: normal;
  }
  .response{
    color:#667085;
    font-size: 14px;
  }
  .comment{
    color : #344054;
    font-size: 14px;
    border: 1px solid rgb(208, 213, 221);
    border-top-right-radius: 8px;
    padding: 10px;
    margin-top: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    min-width: 350px;
  }
  .Reply{
    color:#475467;
    font-size:14px;
    font-weight: normal;
    text-align: end;
    cursor: pointer;
    text-decoration: underline;
  }
  .Reply_save{
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
  }
  .line_1{
    
    position: absolute;
    top: 60px;
    background-color: rgb(0, 0, 0);
    height: calc(100% - 48px);
    width: 2px;
    left: 20px;
    
    /*
    position: relative;
    width: 2px;
    height: auto;
    left: 20px;
    background-color: #000000;
    margin-top: 48px;
    */
  }

  .box_line{
    position: absolute;
    right: 0;
    top: 0;
    width: 52px;
    height: 25px;
    border-bottom: 2px solid #000000;
    border-left: 2px solid #000000;
  }
  .text_css{
    min-width: 350px;
  }
  .margin_right{
    margin-right: 10px;
  }
`

const TabMeetingNote = ({ this_meeting_id }) => {
    const { t } = useTranslation()
    const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
    const dispatch = useDispatch()

    const [AttendanceAttendCellDataMessage ,setAttendanceAttendCellDataMessage] = useState([])

    const fetchDataAttendeeAttendMessage = useCallback(
        async () => {
            const filter = {
                meeting_id : this_meeting_id
            }
            dispatch(getMeetingMessage(filter)).then(({ type,data_info,data_meta }) => {
                if(type.endsWith('_SUCCESS')){
                    const this_data_parent_0 = data_info.filter((v) => v.parent_meeting_message_id == 0)
                    const newTableCellDataAttendanceMessage = this_data_parent_0.map((v, index) => ({
                        id : v.id,
                        full_name : v.full_name,
                        from : v.from,
                        note : v.message,
                        date_sent_message : v.createdAt,
                        data_reply : data_info.filter((v_sub) => v_sub.parent_meeting_message_id == v.id),
                        is_show_reply : false,
                        message_reply : null,
                    }))
                    setAttendanceAttendCellDataMessage(newTableCellDataAttendanceMessage)
                }
            })
        },
        [dispatch]
    )

    useEffect(() => {
        fetchDataAttendeeAttendMessage()
    }, [fetchDataAttendeeAttendMessage])

    const handleClickShowReply = (index_item,is_show) => {
        const newState = AttendanceAttendCellDataMessage.map((obj, index) => {
            if (index === index_item) {
                return { ...obj, is_show_reply: is_show }              
            }
            return obj
          })
      
          setAttendanceAttendCellDataMessage(newState)
    }

    const onChangeTextReply = (index_item, e) => {
        const newState = AttendanceAttendCellDataMessage.map((obj, index) => {
            if (index === index_item) {
                return { ...obj, message_reply: e.target.value }              
            }
            return obj
          })
      
          setAttendanceAttendCellDataMessage(newState)
    }

    const [Message_reply, setMessage_reply] = useState(null)
    const AddMeetingMessage = (v_data,index_inp) => {
        let sent_data_api = {};
        if(index_inp < 0){
            sent_data_api = {
                data: {
                  meeting_id : this_meeting_id,
                  full_name : user.firstname + ' ' + user.lastname,
                  message : Message_reply,
                  from : "From เจ้าหน้าที่",
                  parent_meeting_message_id : 0
                },
            }
        }else{
            sent_data_api = {
                data: {
                  meeting_id : this_meeting_id,
                  full_name : user.firstname + ' ' + user.lastname,
                  message : v_data.message_reply,
                  from : "From เจ้าหน้าที่",
                  parent_meeting_message_id : v_data.id
                },
            }
        }       
        dispatch(createMeetingMessage(sent_data_api)).then(({ type }) => {
          if(type.endsWith('_SUCCESS')){
            fetchDataAttendeeAttendMessage()
            setMessage_reply(null)
            handleClickShowReply(index_inp,false)
          }else{
            handleClickShowReply(index_inp,false)
          }     
        })
      }

    return (
        <DivMain>
            <div className="css_summary">
                <div >
                    <div className="title_chat_message">Messages</div>
                    <div className="css_summary_magin_top">
                        <DivChatBox>
                            {AttendanceAttendCellDataMessage?.map((item, index) => (
                                <div key={'message_' + index}>
                                    <div className="section" >
                                        {item.data_reply.length > 0 && (
                                            <div className="line_1"></div>
                                        )} 
                                        {item.is_show_reply == true && (
                                            <div className="line_1"></div>
                                        )} 
                                        <div className="user_img">
                                            <h3>{item.full_name.substring(0, 1)}</h3> 
                                        </div>
                                        <div className="comment-body">
                                            
                                            <div className="name">{item.full_name} <span className="date_sent_message">{DateTimeHHmmDisplay(item.date_sent_message)}</span></div>
                                            <div className="response">({item.from})</div>
                                            <div className="comment">{item.note}</div>
                                            {(item.data_reply.length == 0 && item.is_show_reply == false) && (
                                                <div className="Reply" onClick={() => handleClickShowReply(index,true)}>Reply</div>
                                            )} 

                                        </div>                                
                                    </div>
                                    {item.data_reply?.map((item_2, index_2) => (
                                        <div className="section">
                                            <div className="reply_space">
                                                <div className="box_line"></div>
                                            </div>                                 
                                            <div className="section_reply" key={'message_sub_' + index_2}>
                                                <div className="user_img_reply">
                                                    <h3>{item_2.full_name.substring(0, 1)}</h3> 
                                                </div>
                                                <div className="comment-body">                                                
                                                    <div className="name">{item_2.full_name} <span className="date_sent_message">{DateTimeHHmmDisplay(item_2.createdAt)}</span></div>
                                                    <div className="response">({item_2.from})</div>
                                                    <div className="comment">{item_2.message}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {item.is_show_reply == true && (
                                    
                                        <div className="section">
                                            <div className="reply_space">
                                                <div className="box_line"></div>
                                            </div>
                                            <div  >
                                                <div>
                                                    <TextField
                                                        name={`section_reply_` + index}
                                                        placeholder="ระบุ.."
                                                        className="text_css"
                                                        value={item.message_reply}
                                                        onChange={(e) => onChangeTextReply(index, e)}
                                                    />
                                                </div>
                                                <div className="Reply_save" >
                                                    <div></div>
                                                    <div className="section_reply">
                                                        <div className="Reply margin_right" onClick={() => handleClickShowReply(index,false)}>cancle</div>
                                                        <div className="Reply" onClick={() => AddMeetingMessage(item,index)}>save</div> 
                                                    </div>                           
                                                </div>
                                            </div>
                                            
                                        </div>
                                        
                                    )} 
                                </div>
                            ))}
                            <div className="border_header"></div>     
                        </DivChatBox>
                        <div className="group_chatbox_new">
                            <div>
                                <TextField
                                    name={`create_section_reply`}
                                    placeholder="ระบุ.."
                                    className="text_css"
                                    value={Message_reply}
                                    onChange={(e) => setMessage_reply(e.target.value)}
                                />
                            </div>
                            <div >
                                <div className="Reply" onClick={() => AddMeetingMessage('',-1)}>
                                    <img className="img-user" src={iconChatbox} />  
                                </div>                          
                            </div>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>            
        </DivMain>
        
    )
}

export default TabMeetingNote
