import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import failIcon from '../../../../assets/images/fail-icon.png'
import successIcon from '../../../../assets/images/success-icon-2.png'
import DialogFail from '../../../../components/dialog/DialogFail'
import DialogSuccess from '../../../../components/dialog/DialogSuccess'
import Button from '../../../../components/form/button/Button'
import IconChevronLeft from '../../../../components/widget/icon/IconChevronLeft'
import { getDocumentSettingByCompanyId, updateDocSettingByCompanyId } from '../../../../redux/actions/document'
import { selectDocument } from '../../../../redux/selectors'
import DocumentDetails from './DocumentDetails'
import LogoRubberStamp from './LogoRubberStamp'
import Signature from './Signature'

export default function DocumentSetting({ onClickedBack, type }) {
  const dispatch = useDispatch()
  const { documentSettingInfo } = useSelector(selectDocument)
  const [documentSettingData, setDocumentSettingData] = useState()

  const [menuTabList, setMenuTabList] = useState([
    {
      name: 'Document Details',
      value: 'document_details',
      active_status: true,
    },
    {
      name: 'Logo & Rubber Stamp',
      value: 'logo_rubber_stamp',
      active_status: false,
    },
    // {
    //   name: 'Condition',
    //   value: 'condition',
    //   active_status: false,
    // },
    {
      name: 'Signature',
      value: 'signature',
      active_status: false,
    },
  ])
  const [currentMenuTabSelected, setCurrentMenuTabSelected] = useState('document_details')
  const [docSettingObj, setDocSettingObj] = useState()
  const [isShowModalSuccess, setIsShowModalSuccess] = useState(false)
  const [isEditSuccess, setIsEditSuccess] = useState(false)
  const [isShowModalFailed, setIsShowModalFailed] = useState(false)
  const [isCanSave,setIsCanSave] = useState(false)

  useEffect(() => {
    const load = async () => {
      dispatch(getDocumentSettingByCompanyId())
    }
    load()
  }, [])
  useEffect(() => {
    if (documentSettingInfo) {
      setDocumentSettingData(documentSettingInfo)
      setIsEditSuccess(false)
    }
  }, [documentSettingInfo])

  const handleClickTaskMenuTab = (item) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == item.value) {
        tmpList[i].active_status = true
      } else {
        tmpList[i].active_status = false
      }
    }
    setCurrentMenuTabSelected(item.value)
    setMenuTabList([...tmpList])
  }
  const setHeaderName = (type) => {
    let textArray = type.split('_')
    let string = textArray.join(' ')
    return string
  }
  const handleClickBtnSave = () => {
    // if (Object.keys(docSettingObj).length > 0) {
      if(type != "") {
        console.log('handleDataChange_objData[type]: ' + JSON.stringify(documentSettingData[type], null, 2))
        console.log('LENGTH: ' + JSON.stringify(Object.keys(documentSettingData[type]).length, null, 2))
        
        let tmpObj = {}
        if (Object.keys(documentSettingData[type]).length > 0) {
          Object.entries(documentSettingData[type]).forEach(([key, value]) => {
            // if(key != "signature_list") {
              tmpObj[type+"_"+key] = value;
            // }
          })
        }
        console.log('tmpObj: ' + JSON.stringify(tmpObj, null, 2))

        dispatch(updateDocSettingByCompanyId({data: tmpObj})).then((res) => {
          if (res?.data) {
            setIsShowModalSuccess(true)
            setIsEditSuccess(true)
          } else {
            setIsShowModalFailed(true)
            setIsEditSuccess(false)
          }
        })
      }
      
    // }
  }
  const handleDataChange = (objData, menuType) => {
    console.log('handleDataChange: ' + JSON.stringify(objData, null, 2))
    
    // setDocSettingObj({ ...docSettingObj, ...objData })
    setDocumentSettingData({ ...documentSettingData, ...objData })
    setIsCanSave(true)
  }

  // const handleCheckDocumentData = () => {
  //   if (docSettingObj && Object.keys(docSettingObj).length > 0) {
  //     return false
  //   }
  //   return true
  // }

  return (
    <Styled>
      <div className="header">
        <div className="icon-back" onClick={onClickedBack}>
          <IconChevronLeft />
        </div>
        <span className="title-name">{setHeaderName(type)}</span>
      </div>

      <div className="wrp-tab-menu">
        {menuTabList?.map((item, index) => (
          <button
            onClick={() => handleClickTaskMenuTab(item)}
            key={'btn-' + index}
            className={item.active_status ? 'menu-active' : 'btn-menu-style'}
          >
            {item.name}
          </button>
        ))}
      </div>

      <div className="wrp-content-tab-detail">
        {currentMenuTabSelected == 'document_details' && (
          <DocumentDetails
            docType={type}
            isReset={isEditSuccess}
            settingData={documentSettingData}
            onDataChange={(e) => handleDataChange(e, 'document_details')}
          />
        )}
        {currentMenuTabSelected == 'logo_rubber_stamp' && (
          <LogoRubberStamp
            docType={type}
            settingData={documentSettingData}
            onDataChange={(e) => handleDataChange(e, 'logo_rubber_stamp')}
          />
        )}
        {/* {currentMenuTabSelected == 'condition' && (
          <Condition
            docType={type}
            settingData={documentSettingData}
            onDataChange={(e) => handleDataChange(e, 'condition')}
          />
        )} */}
        {currentMenuTabSelected == 'signature' && (
          <Signature
            docType={type}
            settingData={documentSettingData}
            onDataChange={(e) => handleDataChange(e, 'condition')}
          />
        )}
      </div>

      <div className="footer-DocumentSetting">
        {/* {currentMenuTabSelected == 'logo_rubber_stamp' && (
          <ButtonOutline onClick={handleClickCancel}>Cancel</ButtonOutline>
        )} */}
        <Button className={'btn-save'} onClick={handleClickBtnSave} disabled={!isCanSave}>
          Save
        </Button>
      </div>
      {isShowModalSuccess && (
        <DialogSuccess
          open={isShowModalSuccess}
          onClose={() => setIsShowModalSuccess(false)}
          onSubmit={() => setIsShowModalSuccess(false)}
          icon={successIcon}
          title={'Success'}
          textYes={'Accept'}
        />
      )}
      {isShowModalFailed && (
        <DialogFail
          open={isShowModalFailed}
          onClose={() => setIsShowModalFailed(false)}
          onSubmit={() => setIsShowModalFailed(false)}
          icon={failIcon}
          title={'Something went wrong'}
          description={'Please try again'}
          textYes="Accept"
        />
      )}
    </Styled>
  )
}

const Styled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex: 1;

  .header {
    display: flex;
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--Gray-200);
    margin-bottom: 24px;

    .title-name {
      text-transform: capitalize;
    }

    .icon-back {
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .wrp-tab-menu {
    width: fit-content;
    display: flex;
    border-bottom: 1px solid var(--Gray-200);
    margin-bottom: 24px;

    .btn-menu-style {
      border: none;
      border-bottom: 2px solid transparent;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      color: var(--Gray-500);
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: var(--Primary-50);
      border-bottom: 2px solid var(--Primary-700) !important;
      color: var(--Primary-700);
    }
  }

  .wrp-content-tab-detail {
    flex: 1;
    display: flex;
  }

  .footer-DocumentSetting {
    display: flex;
    justify-content: flex-end;
    padding: 16px 24px 0;
    border-top: 1px solid var(--Gray-200);
    .btn-save {
      margin-left: 10px;
    }
  }
`
