import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import dayjs from 'dayjs'

import Dropdown from '../../components/form/Dropdown'
import TextField from '../../components/form/TextField'
import InputTextArea from '../../components/widget/element/InputTextArea'
import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next'


import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import { DateDisplay,TimeDisplayHHmm,DateTimeHHmmDisplay } from '../../utils/common'

import { GetMeetingInfo,ApproveMeetingAttend } from '../../redux/actions/dailysummary'

import { selectDailySummary } from '../../redux/selectors'


const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  } 
  
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 65px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill{
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .content{
    margin:10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .meeting_title{
    font-size: 16px;
    font-weight: 600;
    color : #CA8504
  }
  .detail{
    display: grid;
    grid-template-columns: repeat(2, 25% [col-start] 75% [col-end]);
    margin: 10px 0px;
    .topic{
      font-size: 14px;
      font-weight: 600;
      color:#344054;
    }
    .topic_detail{
      font-size: 14px;
      color:#344054;
      white-space:pre-wrap;
      line-break: anywhere;
    }
  }

  .menu-active {
    border: none;
    padding: 10px 18px;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid var(--Primary-600) !important;
    color: #6941C6;
    background-color: #F9F5FF;
    margin-top: 20px;
  }
  .topic_css{
    margin: 10px 0px;
    color: #344054;
    font-size: 14px;
    font-weight: 600;
  }
  .topic_sub_css{
    color: #475467;
    font-size: 14px;
  }
  .link_view_map{
    color: #344054;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}

const ModalMeetingAttendances = ({ onClose, onSubmit,meeting_attendances_id,...props  }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { MeetingList,MeetingRoomList, limit, total, skip, isLoading } = useSelector(selectDailySummary)
  const [MeetingData, setMeetingData] = useState({
    meeting_id : null,
    response : null,
    full_name : null,
    position : null,
    email: null,
    phone_no: null,
    delegate_full_name : null,
    delegate_position : null,
    delegate_email: null,
    delegate_phone_no: null,
    note:null
  })

  const [Title, setTitle] = useState('')
  const [meeting_date, setmeeting_date] = useState(null)
  const [meeting_date_start, setmeeting_date_start] = useState(null)
  const [meeting_date_end, setmeeting_date_end] = useState(null)
  const [meeting_room_name, setmeeting_room_name] = useState(null)
  const [google_map_link, setgoogle_map_link] = useState(null)

  const [online_meeting_link, setonline_meeting_link] = useState(null)
  const [online_meeting_id, setonline_meeting_id] = useState(null)
  const [online_meeting_code, setonline_meeting_code] = useState(null)

  const [Attendee, setAttendee] = useState(null)
  const [project, setproject] = useState(null)
  const [agenda_detail, setagenda_detail] = useState(null)
  //const [EditData, setEditData] = useState(DataEdit)
  /*
  useEffect(() => {
    const filter = {
        skip: 1,
        limit: 10,
        meeting_attendances_id : meeting_attendances_id
    }
    dispatch(getMeetingForCheck(filter)).then(({ type,data_info,data_meta }) => {
        if(type.endsWith('_SUCCESS')){
            if(data_meta){
                const this_data = data_info[0];
      
                setTitle(this_data.title)
                setmeeting_date(this_data.meeting_date)
                setmeeting_date_start(this_data.meeting_date_start)
                setmeeting_date_end(this_data.meeting_date_end)
                setmeeting_room_name(this_data.meeting_room_name)
                setgoogle_map_link(this_data.google_map_link)
                setAttendee(this_data.meeting_attendances.data?.map((v_data) => v_data.attributes.full_name).join(','))
                setproject(this_data.project)
                setagenda_detail(this_data.agenda_detail)
                const this_meeting_attendances = this_data.meeting_attendances?.data?.filter((v) => v.id == meeting_attendances_id);
                
                if(this_meeting_attendances.length > 0){
                  const this_data_meeting_attendances = this_meeting_attendances[0].attributes;
                  
                  setMeetingData({
                    response : this_data_meeting_attendances.response,
                    full_name : this_data_meeting_attendances.full_name,
                    position : this_data_meeting_attendances.position,
                    email: this_data_meeting_attendances.email,
                    phone_no: this_data_meeting_attendances.phone_no,
                    note: this_data_meeting_attendances.note,
                  })
                }               
            }
        }
    })
  }, [dispatch])

  */

  useEffect(() => {
    const sent_data_api = {
      data: {
        meeting_attendance_id: meeting_attendances_id,
      },
    }
    dispatch(GetMeetingInfo(sent_data_api)).then(({ type,data_info,data_meta }) => {
      if(type.endsWith('_SUCCESS')){
        setTitle(data_info.title)
        setmeeting_date(data_info.meeting_date)
        setmeeting_date_start(data_info.meeting_date_start)
        setmeeting_date_end(data_info.meeting_date_end)
        setmeeting_room_name(data_info.meeting_room_name)
        setgoogle_map_link(data_info.google_map_link)

        setonline_meeting_link(data_info.online_meeting_link)
        setonline_meeting_id(data_info.online_meeting_id)
        setonline_meeting_code(data_info.online_meeting_code)

        setAttendee(data_info.meeting_attendances?.map((v_data) => v_data.full_name).join('<br/>'))
        setproject(data_info.project)
        setagenda_detail(data_info.agenda_detail)
        setMeetingData({
          meeting_id : data_info.meeting_id,
          response : data_info.attendance_response,
          full_name : data_info.attendance_full_name,
          position : data_info.attendance_position,
          email: data_info.attendance_email,
          phone_no: data_info.attendance_phone_no,
          note: data_info.attendance_note,
          delegate_full_name : data_info.delegate_full_name,
          delegate_position : data_info.delegate_position,
          delegate_email: data_info.delegate_email,
          delegate_phone_no: data_info.delegate_phone_no,
        })
      }
    })
  }, [dispatch])
  
  
  const response_option = [
    { text: 'Waiting for response', value: 'Waiting for response' },
    { text: 'yes, join in meeting', value: 'yes, join in meeting' },
    { text: 'yes, join virtually', value: 'yes, join virtually' },
    { text: 'Yes, by someone else', value: 'Yes, by someone else' },
    { text: 'no', value: 'no' },
  ]

  const onResponse = (v_inp) => {
    setMeetingData({ ...MeetingData, response: v_inp })
  }

  const OnLinkUrl = (Link_URL) => {
    //alert('OnLinkUrl' + Link_URL)
    window.open(Link_URL, '_blank', 'noreferrer');
  }
  


  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const ConfirmDialog = () => {
    setModalConfirmDialog(true)
  }
  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)
  const CreateNewDailySummary = () => {
    setModalConfirmDialog(false);
    const sent_data_api = {
        data: {
          meeting_id: MeetingData.meeting_id,
          meeting_attendances_id: meeting_attendances_id,
          response : MeetingData.response,
          full_name : MeetingData.response === 'Yes, by someone else' ? MeetingData.delegate_full_name : MeetingData.full_name,
          delegate_full_name : MeetingData.response === 'Yes, by someone else' ? MeetingData.delegate_full_name : null ,
          delegate_position : MeetingData.response === 'Yes, by someone else' ? MeetingData.delegate_position : null,
          delegate_email: MeetingData.response === 'Yes, by someone else' ? MeetingData.delegate_email : null,
          delegate_phone_no: MeetingData.response === 'Yes, by someone else' ? MeetingData.delegate_phone_no : null,
          note:MeetingData.note,
          from:"From Internal"
        },
    }
    dispatch(ApproveMeetingAttend(sent_data_api)).then(({ type }) => {
      if(type.endsWith('_SUCCESS')){
        setTitlesuccess(t('submitted_successfully'));
        setSubTitlesuccess('');
        setDescriptionsuccess(''); 
        setsuccessModal(true)
      }else{
        setTitleFail(t('error_submitted'));
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }     
    })
    
    //onSubmit()
  }


  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">{t('meeting_details')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <hr />
      </div>
      
      <div className="dialog-content">
        <div className="content meeting_title">
            {Title}
        </div>
        <div className="content">
            <div className="detail">
                <div className="topic">{t('date')}</div>
                <div className="topic_detail">{DateDisplay(meeting_date)}</div>
            </div>
        </div>
        <div className="content">
            <div className="detail">
                <div className="topic">{t('time')}</div>
                <div className="topic_detail">{TimeDisplayHHmm(meeting_date_start)} - {TimeDisplayHHmm(meeting_date_end)}</div>
            </div>
        </div>
        <div className="content">
            <div className="detail">
                <div className="topic">{t('location')}</div>
                <div className="topic_detail">
                <div>{meeting_room_name ? meeting_room_name : '-'}</div>
                {google_map_link && (
                    <div className="link_view_map" onClick={() => OnLinkUrl(google_map_link)}>view map</div>
                )}
                {online_meeting_link && (
                  <div className="link_view_map" onClick={() => OnLinkUrl(online_meeting_link)}>Meeting Link</div>
                )}
                {online_meeting_id && (
                  <div>ID : {online_meeting_id ? online_meeting_id : '-'}</div>
                )}
                {online_meeting_code && (
                  <div>Passcode : {online_meeting_code ? online_meeting_code : '-'}</div>
                )}
                </div>
            </div>
        </div>
        <div className="content">
            <div className="detail">
              <div className="topic">{t('attendee')}</div>
              <div className="topic_detail"><div dangerouslySetInnerHTML={{ __html: Attendee }}></div></div>
            </div>
        </div>
        <div className="content">
          <div className="detail">
              <div className="topic">{t('meeting_agenda_header')}</div>
              <div className="topic_detail">
                <div>{project ? project : '-'}</div>
                              
                <div dangerouslySetInnerHTML={{ __html: agenda_detail }}></div>
              </div>
          </div>
        </div>
        <div className="content">
            <div >
              <button className="menu-active">
                {t('response')}
              </button>
            </div>
        </div>
        <div className="content">
          <div className="topic_css">
            1. {t('can_you_attend_this_meeting')}
          </div>
          <div className="topic_sub_css">
            {t('if_no_please_specify')}
          </div>
        </div>
        <div className="content">
            <Dropdown
                id={`MeetingSpace`}
                className="dd_employee"
                value={MeetingData.response}
                optionList={response_option}
                onChange={onResponse}
                disabled={false}
                placeHolder={t('select')}
                ValueplaceHolderIsNull={true}
            />
        </div>
        {MeetingData.response == 'Yes, by someone else' && (  
            <div>
                <div className="content">
                    <div className="topic_fill">{t('full_name')} *</div>
                    <div>
                        <TextField
                        name="Fullname"
                        className="TextField_employee"
                        placeholder={t('full_name')}
                        value={MeetingData.delegate_full_name}
                        onChange={(e) => setMeetingData({ ...MeetingData, delegate_full_name: e.target.value })}
                        />
                    </div>
                </div>
                <div className="content">
                    <div className="topic_fill">{t('email')} *</div>
                    <div>
                        <TextField
                        name="Email"
                        className="TextField_employee"
                        placeholder={t('email')}
                        value={MeetingData.delegate_email}
                        onChange={(e) => setMeetingData({ ...MeetingData, delegate_email: e.target.value })}
                        />
                    </div>
                </div>
                <div className="content">
                    <div className="topic_fill">{t('tel')} *</div>
                    <div>
                        <TextField
                            name="Tel"
                            className="TextField_employee"
                            placeholder={t('tel')}
                            value={MeetingData.delegate_phone_no}
                            onChange={(e) => setMeetingData({ ...MeetingData, delegate_phone_no: e.target.value })}
                        />
                    </div>
                </div>
            </div>
        )}
        <div className="content">
            <div className="topic_css">
                2. {t('additional_note_to_attendee')}
            </div>
            <div className="topic_sub_css">
                {t('if_you_have_additional_question')}
            </div>
        </div>
        <div className="content">
            <InputTextArea
                className="InputTextArea_css"
                initValue={MeetingData.note}
                onChange={(e) => setMeetingData({ ...MeetingData, note: e.target.value })}                        
                placeHolder={t('enter_a_note')}
                borderColor={'var(--Gray-300)'}
                borderWidth="1px"
            />
        </div>

      </div>
      
      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={onClose}>
          {t('cancel')}
        </ButtonOutline>
        <Button className="button_margin" onClick={CreateNewDailySummary}>
          {t('submit')}
        </Button>
      </div>

        <DialogConfirm
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onSubmit={CreateNewDailySummary}
          icon={question}
          title={t('confirm_update_header')}
          description=""
          textYes={t('confirm')}
          textNo={t('back')}
        />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />
    </Div>
  )
}

export default ModalMeetingAttendances
