import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DateTimeHHmmssDisplay } from '../../../utils/common'
import { v4 as uuidv4 } from 'uuid'

import styled from 'styled-components'
import Table from '../../common/Table'
import ButtonOutline from '../../form/button/ButtonOutline'
import Dropdown from '../../form/Dropdown'
import SearchText from '../../form/SearchText'

import { LiaTimesSolid } from 'react-icons/lia'

import { selectKPI, selectMaster } from '../../../redux/selectors'
import { getKpiArchiveList } from '../../../redux/actions/kpiManagement'
import { getEmployeeDropdrow } from '../../../redux/actions/master'

const Div = styled.div`
  .mr-12-px {
    margin-right: 12px;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .kpi-name {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`
const SearchContainer = styled.div`
  display: flex;
  align-items: flex-end;
  .clear-button-container {
    display: flex;
    align-items: center;
  }
`
const InputContainer = styled.div`
  &.width-20 {
    width: 20%;
  }
  .input-group {
    display: flex;
    justify-content: space-between;
  }

  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  .checkbox-container {
    display: flex;
    align-items: center;

    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
  }

  .w-input {
    width: 100%;
  }

  .w-30 {
    width: 30%;
  }

  .w-input-30 {
    width: 30%;
  }
`
const BadgeSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .badge {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`
const TableWrapper = styled.div`
  .table-headers {
    padding: 1rem 0.875rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: var(--Base-White);
    border: 0.0625rem solid var(--Gray-200);

    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .table-header-name {
        color: var(--Gray-900);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        margin-right: 0.5rem;
      }

      .badge {
        border-radius: 16px;
        border: 1px solid var(--Doing-Badge-Stroke);

        color: var(--Doing-Badge-Font);
        text-align: center;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;

        display: inline-block;
        padding: 2px 8px;
      }
    }
  }
`

function KPIOrganizationArchive() {
  // external hook
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // initiate data
  const fetchKpiArchives = useCallback(
    (filter) => {
      dispatch(getKpiArchiveList(filter))
    },
    [dispatch]
  )
  const fetchEmployeeDropdown = useCallback(() => {
    const request = {
      limit: 10000,
      skip: 0,
    }
    dispatch(getEmployeeDropdrow(request))
  }, [dispatch])
  useEffect(() => {
    fetchKpiArchives()
    fetchEmployeeDropdown()
  }, [])

  // table data
  const kpiArchiveColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'KPI name',
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: 'Previous Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('created_by'),
      accessor: 'createdBy',
      disableSortBy: false,
    },
    {
      Header: 'Created at',
      accessor: 'createdAt',
      disableSortBy: false,
    },
  ]
  const { kpiArchives, page, pageCount, total, limit } = useSelector(selectKPI)
  const [kpiArchiveList, setKpiArchiveList] = useState([])

  const navigateToKpiDetail = (id) => {
    navigate(`/kpis/annoucement/kpi-detail/${id}`)
  }

  useEffect(() => {
    const newKpiArchives = kpiArchives?.map((archive, i) => ({
      no: i + 1,
      name: (
        <div className="kpi-name" onClick={() => navigateToKpiDetail(archive.id)}>
          {archive.name}
        </div>
      ),
      status: archive.previous_status,
      createdBy: `${archive.employee.first_name} ${archive.employee.last_name}`,
      createdAt: DateTimeHHmmssDisplay(archive.createdAt),
    }))

    setKpiArchiveList(newKpiArchives)
  }, [kpiArchives])

  // created by list
  const { employeeList } = useSelector(selectMaster)
  const [createdByList, setCreatedByList] = useState([])

  useEffect(() => {
    const newCreatedByList = employeeList.map((v) => ({
      text: `${v.first_name} ${v.last_name}`,
      value: v.id,
    }))
    setCreatedByList(newCreatedByList)
  }, [employeeList])

  // filter
  const initialStatusList = [
    {
      text: 'Publish',
      value: 'publish',
    },
    {
      text: 'Draft',
      value: 'draft',
    },
  ]
  const [search, setSearch] = useState('')
  const [createdBySelected, setCreatedBySelected] = useState('')
  const [statusSelected, setStatusSelected] = useState('')
  const [searchFilters, setSearchFilters] = useState([])

  const onChangePagination = (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    const filter = {
      page: value.page,
      pageSize: limit,
      status: statusSelected,
      createdBy: createdBySelected,
      multipleSearch,
    }
    fetchKpiArchives(filter)
  }
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))

      const filter = {
        page: 1,
        pageSize: limit,
        status: statusSelected,
        createdBy: createdBySelected,
        multipleSearch,
      }
      fetchKpiArchives(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    const filter = {
      page: 1,
      pageSize: limit,
      status: statusSelected,
      createdBy: createdBySelected,
      multipleSearch,
    }
    fetchKpiArchives(filter)
  }
  const onClearSearch = () => {
    const filter = {
      page: 1,
      pageSize: limit,
      status: '',
      createdBy: '',
      multipleSearch: [],
    }
    fetchKpiArchives(filter)
    clearFilter()
  }
  const clearFilter = () => {
    setSearch('')
    setStatusSelected('')
    setCreatedBySelected('')
    setSearchFilters([])
  }

  useEffect(() => {
    if (createdBySelected !== '' || statusSelected !== '') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))

      const filter = {
        page: 1,
        pageSize: limit,
        status: statusSelected,
        createdBy: createdBySelected,
        multipleSearch,
      }
      fetchKpiArchives(filter)
    }
  }, [createdBySelected, statusSelected])

  return (
    <Div>
      {/* Search Section */}
      <SearchContainer className="search-container mb-1-rem">
        <InputContainer className="mr-12-px">
          <div className="input-header">{t('search')}</div>
          <SearchText
            placeholder={t('search')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSubmit={onSearch}
            onClear={() => setSearch('')}
          />
        </InputContainer>
        <InputContainer className="width-20 mr-12-px">
          <div className="input-header">{t('created_by')}</div>
          <Dropdown
            optionList={createdByList}
            value={createdBySelected}
            onChange={(value) => setCreatedBySelected(value)}
          />
        </InputContainer>
        <InputContainer className="width-20 mr-12-px">
          <div className="input-header">{t('status')}</div>
          <Dropdown
            optionList={initialStatusList}
            value={statusSelected}
            onChange={(value) => setStatusSelected(value)}
          />
        </InputContainer>
        <div className="clear-button-container">
          <ButtonOutline onClick={onClearSearch}>{t('clear')}</ButtonOutline>
        </div>
      </SearchContainer>
      <BadgeSearchContainer className="mb-1-rem">
        {searchFilters.map((v) => (
          <div className="badge" key={v.key}>
            <span className="name">{v.name}</span>{' '}
            <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
          </div>
        ))}
      </BadgeSearchContainer>

      {/* Table Section */}
      <TableWrapper>
        <div className="table-headers">
          <div className="header-container">
            <div>
              <span className="table-header-name">Total KPI Archive</span>
              <span className="badge">{total} items</span>
            </div>
          </div>
        </div>
        <Table
          columns={kpiArchiveColumn}
          data={kpiArchiveList}
          page={page}
          pageCount={pageCount}
          onStateChange={onChangePagination}
        />
      </TableWrapper>
    </Div>
  )
}

export default KPIOrganizationArchive
