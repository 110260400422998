import { OP } from 'redux/actionTypes'

import { initPagination } from 'constants/pagination'
import { deleteAPI, getAPI, postAPI, putAPI } from 'utils/api'
import { formatDate } from 'utils/dateHelpers'
import { hideLoading, showLoading } from '../loading'

const { GET, POST, PUT, DELETE, CLEAR } = OP

const pathname = '/api/op-chain-link-temps'

const milestonesParams = {
  fields: ['id'],
  populate: {
    sw: {
      fields: ['code', 'name', 'description', 'hr', 'min', 'status'],
      populate: {
        department: {
          fields: ['id', 'name'],
        },
        positions: {
          fields: ['id', 'name'],
        },
        deliveries: {
          fields: ['name', 'info', 'type'],
          populate: {
            document: {
              fields: ['type'],
            },
          },
        },
        tasks: {
          fields: ['code', 'name', 'description', 'status'],
          populate: {
            department: {
              fields: ['id'],
            },
            deliveries: {
              fields: ['name', 'info'],
            },
          },
        },
      },
    },
    reviewers: {
      fields: ['hr', 'min'],
      populate: {
        position: { fields: ['id', 'name'] },
      },
    },
    approver: {
      fields: ['hr', 'min'],
      populate: {
        position: { fields: ['id', 'name'] },
      },
    },
  },
}

export default function OPChainLinkTpl({ headers, company_info, employee_info }) {
  const getChainLinkTpl = (chainLinkTplId) => ({
    list: (payload) => {
      const { page, pageSize } = payload?.pagination || initPagination
      const { REQUEST, SUCCESS, FAILURE } = GET.CL_TPL_LIST

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            sort: ['updatedAt:desc'],
            fields: ['code', 'name', 'description', 'status', 'updatedAt'],
            pagination: { page, pageSize },
            filters: {
              ...payload?.filters,
            },
          }

          const response = await getAPI({
            url: `${pathname}`,
            headers,
            params,
          })

          const updatedData = response?.data?.map((item) => {
            const updatedAt = formatDate(item?.updatedAt)

            return { ...item, updatedAt }
          })

          return dispatch({ type: SUCCESS, ...response, data: updatedData })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    byId: () => {
      const { REQUEST, SUCCESS, FAILURE } = GET.CL_TPL_BY_ID

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            fields: ['code', 'name', 'description', 'status'],
            populate: {
              company: {
                field: ['id'],
                populate: {
                  working_times: true,
                },
              },
              chains: {
                fields: ['condition'],
                populate: {
                  sp: {
                    fields: ['code', 'name', 'description', 'status'],
                    populate: {
                      department: {
                        fields: ['id', 'name'],
                      },
                      milestones: milestonesParams,
                    },
                  },
                },
              },
            },
          }

          const response = await getAPI({
            url: `${pathname}/${chainLinkTplId}`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    logs: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.CL_TPL_BY_ID_LOGS
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            pagination: { page, pageSize },
            sort: ['id:desc'],
          }

          const response = await getAPI({
            url: `${pathname}/${chainLinkTplId}/logs`,
            headers,
            params,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const postChainLinkTpl = (chainLinkTplId) => ({
    create: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = POST.CL_TPL

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `${pathname}`,
            headers,
            data: {
              ...payload,
              company: {
                id: company_info?.id,
              },
            },
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    active: () => {
      const { REQUEST, SUCCESS, FAILURE } = POST.CL_TPL_ID_ACTIVE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `${pathname}/${chainLinkTplId}/active`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    inactive: () => {
      const { REQUEST, SUCCESS, FAILURE } = POST.CL_TPL_ID_INACTIVE

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await postAPI({
            url: `${pathname}/${chainLinkTplId}/inactive`,
            headers,
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const putChainLinkTpl = (chainLinkTplId) => ({
    update: (payload) => {
      const { REQUEST, SUCCESS, FAILURE } = PUT.CL_TPL

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const response = await putAPI({
            url: `${pathname}/${chainLinkTplId}`,
            headers,
            data: { ...payload },
          })

          return dispatch({ type: SUCCESS, ...response })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const deleteChainLinkTpl = (chainLinkTplId) => {
    const { REQUEST, SUCCESS, FAILURE } = DELETE.CL_TPL

    return async (dispatch) => {
      dispatch(showLoading())
      dispatch({ type: REQUEST })

      try {
        const response = await deleteAPI({
          url: `${pathname}/${chainLinkTplId}`,
          headers,
        })

        return dispatch({ type: SUCCESS, ...response })
      } catch (error) {
        return dispatch({ type: FAILURE, error })
      } finally {
        dispatch(hideLoading())
      }
    }
  }

  const clearChainLinkTpl = () => {
    return (dispatch) => dispatch({ type: CLEAR.CL_TPL })
  }

  return {
    getChainLinkTpl,
    postChainLinkTpl,
    putChainLinkTpl,
    deleteChainLinkTpl,
    clearChainLinkTpl,
  }
}
