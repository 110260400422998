import React, { useEffect, useState } from 'react'

import styled from 'styled-components'
import Button from '../../form/button/Button'
import Table from '../../../components/common/Table'

import memberImg from '../../../assets/images/member-img.png'

import { LiaTimesSolid } from 'react-icons/lia'

import PropTypes from 'prop-types'

const Div = styled.div`
  padding: 2rem;

  width: 900px;

  .mr-12-px {
    margin-right: 0.75rem;
  }

  .icon {
    cursor: pointer;
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .close-icon-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.625rem;
  }

  .employee-name-info-container {
    display: flex;
    align-items: center;

    .employee-name {
      color: var(--Gray-900);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
    }

    .img-user {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .table-container {
    height: 500px;
    overflow: auto;
  }
`

function PublishShowAllModal({ employeeColumn, data, page, pageCount, onChangePagination, closeModal }) {
  const [dataTable, setDataTable] = useState([])

  useEffect(() => {
    const newDataTable = data.map((v, i) => {
      const { first_name, last_name, department, position } = v
      return {
        ...v,
        no: i + 1,
        name: (
          <div className="employee-name-info-container">
            {v.phoho_url ? (
              <img className="mr-12-px img-user" src={v.phoho_url} alt={`${v.id}-img`} />
            ) : (
              <img className="mr-12-px img-user" src={memberImg} alt={`${v.id}-img`} />
            )}
            <span className="employee-name">{`${first_name} ${last_name}`}</span>
          </div>
        ),
        department: department?.name,
        position: position?.name,
      }
    })
    setDataTable(newDataTable)
  }, [data])

  return (
    <Div>
      <div className="close-icon-container">
        <LiaTimesSolid className="icon" size={20} onClick={closeModal} />
      </div>
      <div className="table-container">
        <Table
          columns={employeeColumn}
          data={dataTable}
          page={page}
          pageCount={pageCount}
          onStateChange={onChangePagination}
        />
      </div>

      <div className="button-container">
        <Button onClick={closeModal}>Close</Button>
      </div>
    </Div>
  )
}

PublishShowAllModal.propTypes = {
  employeeColumn: PropTypes.any,
  data: PropTypes.any,
  page: PropTypes.any,
  pageCount: PropTypes.any,
  onChangePagination: PropTypes.any,
  closeModal: PropTypes.any,
}

export default PublishShowAllModal
