import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SearchText from '../../components/form/SearchText'
import { getCmsItemList } from '../../redux/actions/cms'
import { selectCmsByType } from '../../redux/selectors'

const SearchField = ({ type, ...props }) => {
  const dispatch = useDispatch()
  const { search } = useSelector(selectCmsByType(type))
  const [text, setText] = useState(search || '')
  const [isSubmit, setIsSubmit] = useState(text ? true : false)

  const handleClear = () => {
    if (isSubmit) {
      dispatch(
        getCmsItemList(type, {
          search: '',
        })
      )
    }
    setIsSubmit(false)
    setText('')
  }

  const handleSearch = (v) => {
    setIsSubmit(true)
    dispatch(
      getCmsItemList(type, {
        search: v,
      })
    )
  }

  return (
    <SearchText
      placeholder="Search"
      value={text}
      onChange={(e) => setText(e.target.value)}
      onClear={handleClear}
      onSubmit={handleSearch}
      {...props}
    />
  )
}

export default SearchField
