import axios from 'axios'
import Button from 'components/common/Button'
import { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RiUpload2Line } from 'react-icons/ri'

import { BASE_API } from 'configs/app'

const FileUpload = ({ id, inputFileProps, buttonProps, onUploadCallback, ...props }) => {
  const { t } = useTranslation() // ✅ DONE
  const fileInputRef = useRef(null)
  const { register, watch, setValue } = useForm()
  const files = watch('file')

  const handleButtonClick = (event) => {
    event.preventDefault()

    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const createFileData = (v, percent, display, isError) => ({
    id: null,
    url: null,
    name: v.name,
    size: v.size,
    progress_percent: percent,
    progress_display: display,
    progress_is_error: isError,
  })

  useEffect(() => {
    register('file')
  }, [register])

  useEffect(() => {
    if (files) {
      const uploadPromises = Array.from(files).map((file) => {
        return uploadFile(file)
      })

      Promise.all(uploadPromises)
        .then((uploadedFiles) => {
          if (onUploadCallback && typeof onUploadCallback === 'function' && uploadedFiles?.length > 0) {
            onUploadCallback(uploadedFiles)
          }
        })
        .catch((error) => console.error(error))
    }
  }, [files])

  const uploadFile = async (file) => {
    const formData = new FormData()
    formData.append('files', file)

    let file_data = createFileData(file, 0, `-`, false)

    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent
        let percent = Math.floor((loaded * 100) / total)
        let display = percent < 100 ? `${loaded}KB of ${total}KB | ${percent}%` : `กำลังรวบรวมไฟล์`

        file_data = createFileData(file, percent, display, false)
      },
    }

    try {
      const response = await axios.post(BASE_API + '/api/upload', formData, options)

      if (!response.data) throw new Error('No response received from the server.')

      file_data = createFileData(file, 100, `OK`, false)

      return response.data[0]
    } catch (error) {
      let errorMessage = 'ไม่สามารถอัพโหลดไฟล์ได้'
      if (error.response) {
        errorMessage = error.response.data.message
      } else if (error.request) {
        errorMessage = 'No response received from the server.'
      } else {
        errorMessage = error.message
      }
      console.error(errorMessage)
      file_data = createFileData(file, 100, errorMessage, true)
      return null
    }
  }

  return (
    <div>
      <Button variant="contained" startIcon={<RiUpload2Line />} onClick={handleButtonClick} {...buttonProps}>
        {t('upload')}
      </Button>

      <input
        {...register('file')}
        style={{ display: 'none' }}
        id={id}
        type="file"
        multiple
        ref={(e) => {
          fileInputRef.current = e
        }}
        onChange={(e) => {
          setValue('file', e.target.files)
        }}
        {...inputFileProps}
      />
    </div>
  )
}

export default FileUpload
