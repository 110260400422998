export default function IconFileXd({ width = '40', color = '#D0D5DD', style }) {
  return (
    <svg width={width} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <rect width="28" height="28" fill="#FF26BE" />
      <rect x="1" y="1" width="26" height="26" fill="#2E001F" />
      <path
        d="M14.5726 20.8066L11.1662 13.9972L14.4195 7.18041C14.4808 7.07732 14.444 7 14.346 7H12.3724C12.2543 7 12.2039 7.07987 12.1518 7.16752L9.89633 12.322L7.57959 7.15463C7.53055 7.03866 7.48152 7.01289 7.35894 7.01289H5.25058C5.17704 7.01289 5.10349 7.0902 5.17704 7.19329L8.42538 13.9972L5.03599 20.8195C4.9747 20.8968 4.98696 20.9999 5.10954 20.9999H7.13209C7.23016 20.9999 7.29145 20.9612 7.35274 20.8582L9.73698 15.5178L12.1088 20.8453C12.1701 20.9741 12.2314 20.9999 12.3662 20.9999H14.4991C14.5972 20.9999 14.6339 20.9097 14.5726 20.8066Z"
        fill="#FF26BE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.1194 10.5305C17.1039 10.5305 15.204 12.8758 15.204 15.8783C15.204 19.3833 17.0802 21.0128 19.7401 20.9999C20.7698 20.9999 21.8723 20.8652 22.9019 20.3498C22.9755 20.3111 23 20.2853 23 20.208C22.9387 19.7312 22.9387 19.2931 22.9387 18.9452V7.11566C22.9387 7.05123 22.9142 7.01257 22.8161 7.01257H20.8794C20.8181 7.01257 20.7568 7.06412 20.7568 7.14143V10.5563C20.5117 10.5305 20.3768 10.5305 20.1194 10.5305ZM20.0458 12.4505C20.34 12.4505 20.5852 12.5021 20.7568 12.5794V18.9581C20.5239 19.0612 20.2052 19.0998 19.911 19.0998C18.5259 19.0998 17.4227 18.1462 17.4227 15.7752C17.4227 13.7005 18.5013 12.4505 20.0458 12.4505Z"
        fill="#FF26BE"
      />
    </svg>
  )
}
