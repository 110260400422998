import { getAPI, postAPI, putAPI } from '../../utils/api'
import { setAdminData } from '../../utils/common'

import {
  GET_COMPANYPROFILE,
  UPDATE_COMPANYPROFILE,
  CREATE_BRANCH_COMPANY,
  GET_BRANCH_COMPANY_LIST,
  GET_BRANCH_COMPANY_EDIT_BYID,
  UPDATE_BRANCH_COMPANYPROFILE,
  DELETE_BRANCH_COMPANY_PROFILE,
  CREATE_COMPANYPROFILE,
  UPDATE_SUB_COMPANYPROFILE,
  GET_SUBCOMPANYPROFILE,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getCompanyProfileById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_COMPANYPROFILE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    }

    const { data } = await getAPI({
      url: `/api/companies/${id}?populate=*`,
      headers: headers,
    })

    return dispatch({
      type: GET_COMPANYPROFILE.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_COMPANYPROFILE.FAILURE,
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateCompanyProfile = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_COMPANYPROFILE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/companies/${id}`,
      data,
      headers: headers,
    })

    dispatch(getCompanyProfileById(id))

    const adminInfo = JSON.parse(localStorage.getItem('admin'))
    const { company_info } = adminInfo
    const newAdminInfo = {
      ...adminInfo,
      company_info: { ...company_info, theme: data.data.theme },
    }
    setAdminData(newAdminInfo)

    return dispatch({
      type: UPDATE_COMPANYPROFILE.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Company Profile Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_COMPANYPROFILE.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update Company Profile Fail',
    })
  } finally {
    dispatch(hideLoading())
    window.location.href = '/organization-management/organization?tab=companyprofile'
  }
}

// branch create , update ,delete and  Get List branch

export const getTableDataBranchList =
  (filter = { limit: 10, skip: 0 }, id = 1) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_BRANCH_COMPANY_LIST.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const params = {
        ...filter,
      }
      //
      const { data, meta } = await getAPI({
        url: `/api/branches?filters[company][id]=${id}&populate=*`,
        headers: headers,
        // params,
      })

      return dispatch({
        type: GET_BRANCH_COMPANY_LIST.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_BRANCH_COMPANY_LIST.FAILURE,
        error: error,
        showAlert: true,
        alertMessage: 'Get Branch Company Profile Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const createBranchCompany = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_BRANCH_COMPANY.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/branches`,
      data,
      headers: headers,
    })

    const filter = {
      skip: 0,
      limit: 10,
    }
    // dispatch(getUserList(filter))

    return dispatch({
      type: CREATE_BRANCH_COMPANY.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Branch Company Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_BRANCH_COMPANY.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Branch Company Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getBranchCompanyProfileByID = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_BRANCH_COMPANY_EDIT_BYID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/branches/${id}?populate=*`,
      headers: headers,
    })

    return dispatch({
      type: GET_BRANCH_COMPANY_EDIT_BYID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_BRANCH_COMPANY_EDIT_BYID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Branch Company Profile Edit Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateBranchCompany = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_BRANCH_COMPANYPROFILE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/branches/${id}`,
      data,
      headers: headers,
    })

    // dispatch(getCompanyProfileById(id))

    return dispatch({
      type: UPDATE_BRANCH_COMPANYPROFILE.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Branch Company Profile Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_BRANCH_COMPANYPROFILE.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update Branch Company Profile Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteBranchCompany = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_BRANCH_COMPANY_PROFILE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/branches/${id}`,
      data,
      headers: headers,
    })

    // dispatch(getUserList())

    return dispatch({
      type: DELETE_BRANCH_COMPANY_PROFILE.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Branch Company Profile Successful',
    })
  } catch (err) {
    return dispatch({ type: DELETE_BRANCH_COMPANY_PROFILE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

//----- Sub Company Phase2 By Jame 07/09/2023

export const CreateCompany = (inp_data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_COMPANYPROFILE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    //alert(JSON.stringify(inp_data))
    const { data } = await postAPI({
      url: `/api/companies`,
      data: inp_data,
      headers: headers,
    })

    return { type: 'CreateCompany_SUCCESS', data_info: data }
  } catch (err) {
    return { type: 'CreateCompany_ERROR' }
  } finally {
    dispatch(hideLoading())
  }
}

export const updateSubCompany = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_SUB_COMPANYPROFILE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/companies/${id}`,
      data,
      headers: headers,
    })

    // dispatch(getCompanyProfileById(id))

    return { type: 'updateSubCompany_SUCCESS' }
  } catch (err) {
    return { type: 'updateSubCompany_ERROR' }
  } finally {
    dispatch(hideLoading())
  }
}

export const getTableSubCompany =
  (filter = { limit: 10, skip: 0 }, id = 1) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_BRANCH_COMPANY_LIST.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const params = {
        ...filter,
      }
      //
      const { data, meta } = await getAPI({
        url: `/api/companies?filters[lv]=2&filters[status][$ne]=delete&populate=*`,
        headers: headers,
        // params,
      })

      return dispatch({
        type: GET_BRANCH_COMPANY_LIST.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_BRANCH_COMPANY_LIST.FAILURE,
        error: error,
        showAlert: true,
        alertMessage: 'Get Branch Company Profile Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getSubCompanyProfileById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SUBCOMPANYPROFILE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`,
    }

    const { data } = await getAPI({
      url: `/api/companies/${id}?populate=*`,
      headers: headers,
    })

    return dispatch({
      type: GET_SUBCOMPANYPROFILE.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_SUBCOMPANYPROFILE.FAILURE,
    })
  } finally {
    dispatch(hideLoading())
  }
}
