import React, { useState, useEffect } from 'react'
import { PieChart } from '@mui/x-charts'

import styled from 'styled-components'
import Card from '../../common/Card'

import { PiWarningCircleBold } from 'react-icons/pi'

const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-center {
    align-items: center;
  }

  .w-30 {
    width: 20%;
  }
`
const TaskContent = styled.div`
  .task-heading-field {
    color: var(--Gray-900);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
  .chart-container {
    position: relative;
    width: 100%;
  }
  .warning-icon {
    cursor: pointer;
    color: var(--warning-600);
  }
  .text-under-pie-chart {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .text-value {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    .bold {
      color: var(--Gray-900);
      font-size: 18px;
      font-weight: 600;
    }
    .green {
      color: var(--success-600);
      font-size: 18px;
      font-weight: 600;
    }
    .red {
      color: var(--Error-700);
      font-size: 18px;
      font-weight: 600;
    }
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`
const PieChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  position: relative;

  .percent-value {
    color: var(--Gray-900);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;

    position: absolute;
    top: 60%;
  }
`
function CardTaskData1({ taskData, ...props }) {
  return (
    <Card {...props}>
      <TaskContent>
        <FlexContainer className="justify-content-space-between align-items-center">
          <h3 className="task-heading-field">{taskData.title}</h3>
          <PiWarningCircleBold className="warning-icon" size={20} />
        </FlexContainer>

        <div className="chart-container mb-1-rem">
          <PieChartContainer>
            <PieChart
              colors={
                taskData.value_valid === 'normal'
                  ? ['var(--success-600)', 'var(--Gray-200)']
                  : ['var(--Error-600)', 'var(--Gray-200)']
              }
              series={[
                {
                  data: [{ value: Number(taskData.value) }, { value: taskData.max - Number(taskData.value) }],
                  innerRadius: 90,
                  outerRadius: 110,
                  paddingAngle: 0,
                  cornerRadius: 10,
                  startAngle: -90,
                  endAngle: 90,
                  cx: 150,
                  cy: 150,
                },
              ]}
              width={300}
              height={200}
              sx={{ width: '100%', height: 'auto' }}
            />
            <div className="percent-value">{taskData.value}</div>
          </PieChartContainer>

          <FlexContainer className="justify-content-space-between task-info-text mb-1-rem">
            <div className="text-under-pie-chart">{taskData.min}</div>
            <div className="text-under-pie-chart">Be’</div>
            <div className="text-under-pie-chart">{taskData.max}</div>
          </FlexContainer>
          <FlexContainer className="justify-content-center task-info-text">
            <div className="text-under-pie-chart">
              ค่ามาตรฐาน {taskData.min_control}-{taskData.max_control}
            </div>
          </FlexContainer>
        </div>
        <Divider className="mb-1-rem" />
        <FlexContainer className="text-value justify-content-space-between">
          <div className="w-30">Over</div>
          <div className="bold">
            {`>`} {taskData.max_control}
          </div>
          <div>{taskData.unit}</div>
        </FlexContainer>
        <FlexContainer className="text-value justify-content-space-between">
          <div className="w-30">Standard</div>
          <div className="bold">
            {taskData.min_control}-{taskData.max_control}
          </div>
          <div>{taskData.unit}</div>
        </FlexContainer>
        <FlexContainer className="text-value justify-content-space-between">
          <div className="w-30">Under</div>
          <div className="bold">
            {`<`} {taskData.min_control}
          </div>
          <div>{taskData.unit}</div>
        </FlexContainer>
        <FlexContainer className="text-value justify-content-space-between">
          <div className="w-30">Actual</div>
          <div className={`${taskData.value_valid === 'normal' ? 'green' : 'red'}`}>{taskData.value}</div>
          <div>{taskData.unit}</div>
        </FlexContainer>
      </TaskContent>
    </Card>
  )
}

export default CardTaskData1
