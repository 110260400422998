import styled from 'styled-components'

import Modal from '../../components/common/Modal'
import IconButton from '../../components/common/IconButton'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'

import iconClose from '../../assets/images/icon-close.svg'

import { useState, useEffect } from 'react'
import Dropdown from '../../components/form/Dropdown'

import { useDispatch } from 'react-redux'
import { ChangeStatusEmployee } from '../../redux/actions/employee'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  position: relative;
  padding: 24px;
  width: 100%;
  width: 400px;

  .dialog-content {
    img {
      margin-right: 16px;
      margin-bottom: 8px;
    }

    .content {
      flex: 1;
      min-width: 270px;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }

    .description {
      font-size: 14px;
      margin-top: 4px;
      color: var(--Gray-600);
    }
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .bt-close {
    position: absolute;
    right: 18px;
    top: 18px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .header_message {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
  }
  .dd_employee {
    width: 250px;
  }
  .dd_changestatus {
    display: flex;
    justify-content: center;
    padding: 10px 10px;
  }
`
import userIconPlus from '../../assets/images/user-icon-plus.png'
const DivV2023 = styled.div`
  position: relative;
  padding: 24px;
  width: 100%;
  max-width: 400px;

  .dialog-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    img {
      margin-right: 16px;
      margin-bottom: 8px;
    }

    .content {
      flex: 1;
      min-width: 270px;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
      text-align: center;
    }
    .name-item {
        color: var(--gray-700, #344054);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        text-align: center;
        /*margin-bottom: 1rem;*/
      }
    .description {
      font-size: 14px;
      /*margin-top: 4px;*/
      color: var(--Gray-600);
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .close-modal-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .icon-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .bt-close {
    img {
      width: 16px;
      height: 16px;
    }
  }
  .topic_fill{
    font-size: 14px;
    color: #212121;
  }
  .address_file_dd{
    margin-top:15px;
  }
`

const DialogEmployeeStatus = ({ OpenModal, onClose, onSubmit, EmployeeId,ThisStatus, ...props }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const errorMessage = ''
  const editable = true
  //alert(ThisStatus)
  const [ChangeStatus, setChangeStatus] = useState(ThisStatus)
  /*
  useEffect(() => {
    setChangeStatus(ThisStatus)
  }, [ThisStatus])
  */
  //setChangeStatus(ThisStatus)
  const Status_option = [
    {
      value: 'active',
      text: t('active'),
    },
    {
      value: 'inactive',
      text: t('inactive'),
    },
    {
      value: 'draft',
      text: t('draft'),
    },
  ]
  const onChangeStatus = (v) => {
    setChangeStatus(v)
  }

  const SaveStausEmployee = () => {
    //alert('----' + EmployeeId)

    const sent_data_api = {
      data: {
        status: ChangeStatus,
      },
    }
    dispatch(ChangeStatusEmployee(EmployeeId, sent_data_api))
    onSubmit()
  }
  return (
    <Modal open={OpenModal} onClose={onClose} disableBackdropClick>
      <DivV2023 {...props}>
        <div className="close-modal-content">
          <IconButton className="bt-close" src={iconClose} onClick={onClose} />
        </div>
        <div className="icon-content">
          <img src={userIconPlus} />
        </div>
        <div className="dialog-content">
          <div className="content">
            <div className="title">{t('change_status')}</div>
            <div>
                <div>
                    <Dropdown
                    isStatus={true}
                    name="status"
                    className="address_file_dd"
                    value={ChangeStatus}
                    optionList={Status_option}
                    onChange={onChangeStatus}
                  />
                </div>
            </div>
          </div>
        </div>
        <div className="dialog-button">
          <ButtonOutline onClick={onClose}>{t('cancel')}</ButtonOutline>
          <Button onClick={SaveStausEmployee}>{t('update')}</Button>
        </div>
      </DivV2023>
    </Modal>
  )
}

export default DialogEmployeeStatus
