import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { getCompanyInfo,GetPermissionOther,GetPermissionTabList } from '../../utils/common'

import styled from 'styled-components'
import SearchText from '../../components/form/SearchText'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import TextField from '../../components/form/TextField'
import Table from '../../components/common/Table'
import CollapseMenu from '../../components/common/CollapseMenu'
import SidePanel from '../../components/common/SidePanel'
import DialogConfirm from '../../components/dialog/DialogConfirm'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import DialogSuccess from '../../components/dialog/DialogSuccess'

import Dropdown from '../../components/form/Dropdown'

import { GiCancel } from 'react-icons/gi'
import { LiaTimesSolid } from 'react-icons/lia'
import successIcon from '../../assets/images/success-icon.png'
import iconPlus from '../../assets/images/icon-plus.svg'

import {
  getDepartmentsAndPositionsUseAuthen
} from '../../redux/actions/master'

import {
  getDepartments,
  getDepartmentById,
  updateDepartment,
  createDepartment,
  deleteDepartment,
} from '../../redux/actions/department'
import { selectDepartMent,selectMaster } from '../../redux/selectors'

import { useTranslation } from 'react-i18next'

import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

const Div = styled.div`
  .text-bold {
    font-weight: bold;
  }
  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .button-footer-company-profile {
    margin-bottom: 25rem;
  }
  .button-footer {
    margin-bottom: 35rem;
  }
  .h-full {
    height: 100%;
  }
  .left-profile {
    margin-left: 0px;
  }
  .left {
    margin-left: 260px;
  }
  .right {
    margin-right: 230px;
  }
  .col-12 {
    width: 100%;
  }
  .input-col {
    display: flex;
    grid-template-columns: repeat(2, 10rem) 8rem;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input-header {
    color: var(--gray-700);
    font-size: 16px;
    font-weight: 500;
  }
  .icon {
    cursor: pointer;
  }
  .divider {
    margin: 2rem 0;
    border: 0.1rem solid #eaecf0;
  }
  .create-access-role-header {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .side-panel-container {
    padding: 2rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .d-flex {
    display: flex;
  }
  .row {
    flex-direction: row;
  }
  .justify-content-flex-start {
    justify-content: flex-start;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .align-items-center {
    align-items: center;
  }
  .content-header {
    margin-top: 24px;
  }
  .float-end {
    float: right;
  }

  .float-start {
    float: left;
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .mt-50 {
    margin-top: 50px;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .w-100 {
    width: 100%;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mb-to-end {
    margin-bottom: 620px;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .date-filter-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .drop-down {
    min-width: 10rem;
  }

  .task-report-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .task-box {
    border: 1px solid black;
    width: 100%;
    margin: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
  }

  .task-box .amount-tasks {
    font-weight: bold;
    text-align: center;
    color: black;
    font-size: 20px;
  }

  .filter-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .wrp-filter-menu {
      position: relative;
      display: flex;
    }

    .wrp-filter-btn {
      position: relative;
    }

    .dialog-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 300px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);
      max-height: 300px;

      .wrp-person-list {
        position: relative;
      }
      .person-list {
        height: 220px;
        overflow-y: scroll;
      }

      .person-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 4px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
        }

        .img-profile {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          background-color: var(--Gray-200);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .person-item:hover {
        background-color: var(--Gray-50);
      }
    }

    .filter-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 500px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);

      .wrp-filter-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clear-btn {
          padding: 4px 6px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        .clear-btn:hover {
          background: var(--Primary-25);
        }
      }
      .wrp-filter-option {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .title-option {
          font-weight: 600;
          margin-bottom: 6px;
        }
        .option-item-style {
          border: 1px solid var(--Gray-300);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Gray-50);
        }
        .option-item-style:hover {
          background-color: var(--Gray-100);
        }
        .option-item-active {
          border: 1px solid var(--Primary-600);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Primary-600);
          color: #fff;
        }
      }
    }
  }

  .wrp-filter-menu {
    display: flex;
    align-items: center;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px solid var(--Doing-Badge-Stroke);
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .error-input {
    border: 1px solid red;
  }

  .error {
    color: red;
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }
`
const userDepartMentColumn = [
  {
    Header: 'No',
    accessor: 'no',
    disableSortBy: false,
  },
  {
    Header: 'Company',
    accessor: 'company',
    disableSortBy: false,
  },
  {
    Header: 'Department ID',
    accessor: 'id',
    disableSortBy: false,
  },
  {
    Header: 'Department Name',
    accessor: 'name',
    disableSortBy: false,
  },
  {
    Header: 'Level',
    accessor: 'level',
    disableSortBy: false,
  },
  {
    Header: 'Under Department',
    accessor: 'under_department',
    disableSortBy: false,
  },
  {
    Header: 'Number of Employee',
    accessor: 'employee_users',
    disableSortBy: false,
  },
  {
    accessor: 'action',
    disableSortBy: false,
  },
]

const initValidateForm = {
  name: null,
  underdepartmentId : null,
}

const initMessageModal = {
  headline: '',
  message: '',
}

const DepartmentPosition = () => {
  const { t } = useTranslation()
  const CompanyInfo = getCompanyInfo()
  const initLang = localStorage.getItem('lang')
  //--- ST ส่วนของ Permission LV3-4
  const permissionNewDepartment = GetPermissionOther('department', 'new_department');
  const permissionDepartmentName = GetPermissionOther('department', 'view_department');
  /*
  const { permission } = JSON.parse(localStorage.getItem('admin'))
  const [permissionNewDepartment, setpermissionNewDepartment] = useState(null);
  let permissionDepartmentName = null;
  useEffect(() => {
    const this_sub_menu_permission = permission['Human Resources & Organization'].filter((it_2) => it_2.path === '/organization-management/organization?tab=companyprofile');
    const tab_permission = this_sub_menu_permission[0].permission_tab?.filter((it_2) => it_2.menu_setting.rout_path === 'Org_Department')
    if(tab_permission.length > 0){
      const this_permissionNewDepartment = tab_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'Org_Department_NewDepartment');
      if(this_permissionNewDepartment){
        setpermissionNewDepartment(this_permissionNewDepartment)
      }
      const this_permissionDepartmentName = tab_permission[0].permission_other?.find(item => item.menu_setting.rout_path === 'Org_Department_DepartmentName');
      if(this_permissionDepartmentName){
        permissionDepartmentName = this_permissionDepartmentName;
      }
    }
  }, [])
  */
  //--- ED ส่วนของ Permission LV3-4
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { departments, departmentInfo, total, limit, page, pageCount, isLoading, state } = useSelector(selectDepartMent)
  const [departmentList, setDepartmentList] = useState()
  const [departmentName, setDepartmentName] = useState('')
  const [underdepartmentId, setunderdepartmentId] = useState(null)
  const [departmenLv, setdepartmenLv] = useState(1)
  const [departmentId, setDepartmentId] = useState()
  // search
  const [search, setSearch] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  // modal
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [confirmCreateModal, setConfirmCreateModal] = useState(false)
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  // modal message
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  // side panel
  const [isOpenSidepanelDepartMent, setIsOpenSidepanelDepartMent] = useState(false)
  const [isOpenSidepanelDepartMentEdit, setIsOpenSidepanelDepartMentEdit] = useState(false)
  // validate
  const [errors, setErrors] = useState(initValidateForm)

  const [master_departmentList, setmaster_departmentList] = useState([])
  
  useEffect(() => {
    dispatch(getDepartmentsAndPositionsUseAuthen())
  }, [dispatch])

  const { departments:master_departments } = useSelector(selectMaster)
  

  useEffect(() => {
    const newList = master_departments.map((v) => ({
      text: v?.name,
      value: v?.id,
      lv: v?.lv
    }))
    setmaster_departmentList(newList)
  }, [master_departments])

  const onDepartment = (v_inp) => {
    //alert(v_inp)
    const departmentMatch = master_departmentList.find((v) => v.value == v_inp)
    //alert(JSON.stringify(departmentMatch))
    if(departmentMatch){
      setdepartmenLv(parseInt(departmentMatch.lv) + 1)
    }
    setunderdepartmentId(v_inp)
}


  const fetchData = useCallback(
    async (filter) => {
      dispatch(getDepartments(filter))
    },
    [dispatch]
  )
  const viewDepartment = useCallback(
    (id) => {
      if(permissionDepartmentName?.canUpdate === true){
        navigate(`/organization-management/organization/department-detail/${id}`)
      }else{
        let message_permission = 'No permission allowed ' + permissionDepartmentName?.name_en;
        if(initLang == 'th'){
          message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionDepartmentName?.name_th;
        }
        setTitleFail(message_permission);
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }
    },
    [navigate]
  )

  const editDepartment = useCallback(
    (id) => {
      setDepartmentId(id)
      dispatch(getDepartmentById(id))
      setIsOpenSidepanelDepartMentEdit(true)
    },
    [dispatch]
  )

  const deleteScopeOfWork = useCallback((id, name) => {
    setDepartmentId(id)
    setConfirmDeleteModal(true)
  }, [])

  useEffect(() => {
    if (state == 'CREATE_DEPARTMENTS.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: t('success_create_department_message'),
        message: '',
      })
      setSuccessModal(true)
    }
    if (state == 'UPDATE_DEPARTMENTS.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: t('success_update_department_message'),
        message: '',
      })
      setSuccessModal(true)
    }
    if (state == 'DELETE_DEPARTMENTS.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: t('success_delete_department_message'),
        message: '',
      })
      setSuccessModal(true)
    }
  }, [state, isLoading])

  useEffect(() => {
    if (departmentInfo?.attributes) {
      const { name,lv,ref_departments_id } = departmentInfo?.attributes
      setDepartmentName(name)
      setdepartmenLv(lv)
      setunderdepartmentId(ref_departments_id)
    }
  }, [departmentInfo])

  useEffect(() => {
    const filter = {
      skip: 1,
      limit: limit,
      name: search,
    }
    fetchData(filter)
  }, [fetchData, limit])

  useEffect(() => {
    const newDepartment = departments.map((v) => {
      const list = [
        {
          text: 'Edit',
          action: () => editDepartment(v.id),
        },
        {
          text: 'Delete',
          action: () => deleteScopeOfWork(v.id, v.scope_work_name),
        },
      ]
      return {
        ...v,
        company : v.company?.data?.attributes?.company_name_TH ? v.company?.data?.attributes?.company_name_TH : '-',
        name: (
         <div className="text-bold link" onClick={() => viewDepartment(v.id)}>
            {v.name}
          </div>
        ),
        level : v.lv ? v.lv : '-',
        under_department : v.ref_departments_name ? v.ref_departments_name : '-',
        action: <CollapseMenu list={list} />,
      }
    })

    setDepartmentList(newDepartment)
  }, [departments, viewDepartment])

  // request
  const onSearch = () => {
    const newFilter = {
      key: uuidv4(),
      name: search,
    }
    const filter = {
      skip: 1,
      limit: limit,
      name: search,
      filters: [...searchFilters, newFilter].map((v) => v.name),
    }
    fetchData(filter)
    setSearch('')
    setSearchFilters((prev) => [...prev, newFilter])
  }

  const onClearSearch = () => {
    const filter = {
      skip: 1,
      limit: limit,
      name: '',
      filters: [],
    }
    fetchData(filter)
    setSearch('')
    setSearchFilters([])
  }

  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)

    const filter = {
      skip: 1,
      limit: limit,
      name: search,
      filters: newSearchFilters.map((v) => v.name),
    }
    fetchData(filter)
    setSearch('')
  }

  // onChange
  const onChangePagination = async (value) => {
    const filter = {
      skip: value.page,
      limit: limit,
      name: search,
    }
    fetchData(filter)
  }

  // submit
  const onSubmitCreateDepartment = () => {
    const { user } = JSON.parse(localStorage.getItem('admin'))
    const request = {
      data: {
        company : CompanyInfo?.id,
        name: departmentName,
        lv : departmenLv,
        ref_departments_id : underdepartmentId,
        owner: user.id,
      },
    }

    //dispatch(createDepartment(request))
    dispatch(createDepartment(request)).then(({ data_result,type }) => {
      if(type.endsWith('_SUCCESS')){
        dispatch(getDepartmentsAndPositionsUseAuthen())
        closeOpenSidepanelDepartMent()
        setConfirmCreateModal(false)
      }
    })

    



  }

  const onSubmitEditDepartment = () => {
    const request = {
      data: {
        name: departmentName,
        lv : departmenLv,
        ref_departments_id : underdepartmentId,
      },
    }
    dispatch(updateDepartment(departmentId, request)).then(({ data_result,type }) => {
      if(type.endsWith('_SUCCESS')){
        closeOpenSidepanelDepartMentEdit()
        setConfirmUpdateModal(false)
      }
    })
  }

  const confirmDelete = () => {
    const request = {
      data: {
        status: 'delete',
      },
    }
    dispatch(deleteDepartment(departmentId, request))
    setConfirmDeleteModal(false)
  }

  // modal
  const closeOpenSidepanelDepartMentEdit = () => {
    setDepartmentName('')
    setIsOpenSidepanelDepartMentEdit(false)
    setErrors(initValidateForm)
  }

  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const openNewDepartment = () => {
    
    if(permissionNewDepartment?.canUpdate === true){
      setIsOpenSidepanelDepartMent(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionNewDepartment?.name_en;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionNewDepartment?.name_th;
      }
      setTitleFail(message_permission);
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }
    
  }

  const closeOpenSidepanelDepartMent = () => {
    setDepartmentName('')
    setIsOpenSidepanelDepartMent(false)
    setErrors(initValidateForm)
  }

  const openConfirmCreate = () => {
    const isValid = validateForm()
    if (isValid) setConfirmCreateModal(true)
  }

  const openConfirmUpdate = () => {
    const isValid = validateUpdateForm()
    if (isValid) setConfirmUpdateModal(true)
  }

  const confirmSuccess = () => {
    setSuccessModal(false)
  }

  // optional
  const validateForm = () => {
    let newErrors = {
      ...initValidateForm,
    }
    
    if (departmentName == '') {
      newErrors.name = t('department_name_is_required')
    }

    if(master_departmentList.length > 0){
      
      if(underdepartmentId == null){
        newErrors.underdepartmentId = t('under_other_department_is_required')
      }
    }else{
      newErrors.underdepartmentId = null;
    }

    setErrors(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  const validateUpdateForm = () => {
    let newErrors = {
      ...initValidateForm,
    }
    
    if (departmentName == '') {
      newErrors.name = t('department_name_is_required')
    }

    if(departmenLv > 1){
      
      if(underdepartmentId == null){
        newErrors.underdepartmentId = t('under_other_department_is_required')
      }
    }else{
      newErrors.underdepartmentId = null;
    }

    setErrors(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  return (
    <Div>
      <div className="d-flex justify-content-between">
        <div className="d-flex mb-1-rem">
          <SearchText
            className="mr-1-rem"
            placeholder={t('search')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSubmit={onSearch}
            onClear={onClearSearch}
          />
          <ButtonOutline className="d-flex justify-content-center" onClick={onClearSearch}>
            {t('clear')}
          </ButtonOutline>
        </div>
      </div>
      <div className="badge-search-content mb-1-rem">
        {searchFilters.map((v) => (
          <div className="badge" key={v.key}>
            <span className="name">{v.name}</span>{' '}
            <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
          </div>
        ))}
      </div>

      <div className="table-wrapper">
        <div className="table-header">
          <div className="group">
            <div className="table-title">{t('department')}</div>
            <div className="table-total">{total} {t('department')}</div>
          </div>

          <div>
            {permissionNewDepartment?.canRead === true && (
              <Button append={<img src={iconPlus} />} onClick={openNewDepartment}>
                {t('new_department')}
              </Button>
            )}
          </div>
        </div>
        <Table
          columns={userDepartMentColumn}
          data={departmentList}
          onStateChange={onChangePagination}
          pageCount={pageCount}
          page={page}
        />
      </div>

      {/* Modal */}
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={confirmDelete}
        title={t('confrim_delete')}
        description={t('confirm_delete_department_sub_header')}
      />
      <DialogConfirm
        open={Boolean(confirmCreateModal)}
        onClose={() => setConfirmCreateModal(false)}
        onSubmit={onSubmitCreateDepartment}
        title={t('confirm_create_department_header')}
        description={departmentName}
        textYes={t('confirm')}
        textNo={t('back')}
      />
      <DialogConfirm
        open={Boolean(confirmUpdateModal)}
        onClose={() => setConfirmUpdateModal(false)}
        onSubmit={onSubmitEditDepartment}
        title={t('confirm_update_department_header')}
        description={departmentName}
        textYes={t('confirm')}
        textNo={t('back')}
      />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />

      {/* Side panel */}
      <SidePanel isOpen={isOpenSidepanelDepartMent} setIsOpen={setIsOpenSidepanelDepartMent} width={450}>
        <Div>
          <div className="side-panel-container">
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2-rem">
                <div className="create-access-role-header">{t('create_department')}</div>
                <LiaTimesSolid size={25} onClick={closeOpenSidepanelDepartMent} className="icon" />
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('department_name')} *</div>
                <div className="input-new">
                  <TextField
                    className={`${errors.name == null ? '' : 'error-input'}`}
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                    placeholder={t('department_name')}
                  />
                  {errors.name && <div className="error">{errors.name}</div>}
                </div>
              </div>
              {master_departmentList.length > 0 && (
                <div className="mb-1-rem">
                  <div className="input-header mb-1-rem">{t('under_other_department')}</div>
                  <div className="input-new">
                        <Dropdown
                          id={`department`}
                          className={`${errors.underdepartmentId == null ? '' : 'error-input'}`}
                          value={underdepartmentId}
                          optionList={master_departmentList}
                          onChange={onDepartment}
                          ValueplaceHolderIsNull={true}
                        />
                        {errors.underdepartmentId && <div className="error">{errors.underdepartmentId}</div>}
                  </div>
                </div>
              )}

            </div>

            <div>
              <div className="divider"></div>
              <div className="d-flex justify-content-between float-end">
                <ButtonOutline onClick={closeOpenSidepanelDepartMent} className="mr-1-rem">
                  {t('cancel')}
                </ButtonOutline>
                <Button onClick={openConfirmCreate}>{t('create')}</Button>
              </div>
            </div>
          </div>
        </Div>
      </SidePanel>
      <SidePanel isOpen={isOpenSidepanelDepartMentEdit} setIsOpen={setIsOpenSidepanelDepartMentEdit} width={450}>
        <Div>
          <div className="side-panel-container">
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2-rem">
                <div className="create-access-role-header">{t('edit_Department')}</div>
                <LiaTimesSolid size={25} onClick={closeOpenSidepanelDepartMentEdit} className="icon" />
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('edit_Department')} *</div>
                <div className="input-new">
                  <TextField
                    className={`${errors.name == null ? '' : 'error-input'}`}
                    value={departmentName}
                    onChange={(e) => setDepartmentName(e.target.value)}
                    placeholder="Name"
                  />
                  {errors.name && <div className="error">{errors.name}</div>}
                </div>
              </div>
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('under_other_department')}</div>
                <div className="input-new">
                      <Dropdown
                        id={`department`}
                        className={`${errors.underdepartmentId == null ? '' : 'error-input'}`}
                        value={underdepartmentId}
                        optionList={master_departmentList}
                        onChange={onDepartment}
                        ValueplaceHolderIsNull={true}
                      />
                      {errors.underdepartmentId && <div className="error">{errors.underdepartmentId}</div>}
                </div>
              </div>
            </div>

            <div>
              <div className="divider"></div>
              <div className="d-flex justify-content-between float-end">
                <ButtonOutline onClick={closeOpenSidepanelDepartMentEdit} className="mr-1-rem">
                  {t('cancel')}
                </ButtonOutline>
                <Button onClick={openConfirmUpdate}>{t('save')}</Button>
              </div>
            </div>
          </div>
        </Div>
      </SidePanel>

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default DepartmentPosition
