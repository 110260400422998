import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import Card from '../../common/Card'
import CollapseMenu from '../../common/CollapseMenu'

import { PiWarningCircleBold } from 'react-icons/pi'
import alertIcon from '../../../assets/images/alert-icon.png'
import standardIcon from '../../../assets/images/standard-icon.png'

const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-center {
    align-items: center;
  }

  .w-30 {
    width: 33%;
  }
`
const TaskContent = styled.div`
  .mr-8-px {
    margin-right: 8px;
  }
  .task-heading-field {
    color: var(--Gray-900);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
  .task-status {
    color: var(--Gray-900);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
  }
  .status-icon {
    width: 30px;
    height: 30px;
  }
  .warning-icon {
    cursor: pointer;
    color: var(--warning-600);
  }
  .text-under-pie-chart {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .text-value {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    .bold {
      color: var(--Gray-900);
      font-size: 18px;
      font-weight: 600;
    }
    .green {
      color: var(--success-600);
      font-size: 18px;
      font-weight: 600;
    }
    .red {
      color: var(--Error-700);
      font-size: 18px;
      font-weight: 600;
    }
  }
`

function CardTaskData3({ taskData, ...props }) {
  const list = [
    {
      text: 'Action 1',
      action: () => alert('action 1 something'),
    },
    {
      text: 'Action 2',
      action: () => alert('action 2 something'),
    },
  ]

  return (
    <Card {...props}>
      <TaskContent>
        <FlexContainer className="justify-content-space-between align-items-center mb-1-rem">
          <FlexContainer className="align-items-center">
            <img className="status-icon mr-8-px" src={`${taskData.value === 'ปกติ' ? standardIcon : alertIcon}`} />
            <div className="task-heading-field">{taskData.title}</div>
          </FlexContainer>

          {taskData.value === 'ปกติ' ? (
            <CollapseMenu list={list} />
          ) : (
            <PiWarningCircleBold className="warning-icon" size={20} />
          )}
        </FlexContainer>

        <div className="task-status">{taskData.value}</div>
      </TaskContent>
    </Card>
  )
}

export default CardTaskData3
