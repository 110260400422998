export const SP = {
  STATUS_CHIP: {
    Open: 'info',
    'In Progress': 'warning',
    'In Queue': 'default',
    Completed: 'success',
    Canceled: 'default',
  },
  STATUS: {
    Open: 'Open',
    InProgress: 'In Progress',
    InQueue: 'In Queue',
    Completed: 'Completed',
    Canceled: 'Canceled',
  },
}

export const CHAIN_LINK = {
  STATUS_CHIP: {
    Open: 'info',
    'In Progress': 'warning',
    'In Queue': 'default',
    Completed: 'success',
    Canceled: 'default',
  },
  STATUS: {
    Open: 'Open',
    InProgress: 'In Progress',
    InQueue: 'In Queue',
    Completed: 'Completed',
    Canceled: 'Canceled',
  },
}

export const SW = {
  STATUS_CHIP: {
    Open: 'info',
    'In Progress': 'warning',
    'In Queue': 'default',
    Completed: 'success',
    Canceled: 'default',
  },
  STATUS: {
    Open: 'Open',
    InProgress: 'In Progress',
    InQueue: 'In Queue',
    Completed: 'Completed',
    Canceled: 'Canceled',
  },
}

export const TASK = {
  STATUS_CHIP: {
    Open: 'info',
    'In Progress': 'warning',
    'In Queue': 'default',
    Completed: 'success',
    Canceled: 'default',
  },
  STATUS: {
    Open: 'Open',
    InProgress: 'In Progress',
    InQueue: 'In Queue',
    Completed: 'Completed',
    Canceled: 'Canceled',
  },
}

export const REVIEWER = {
  STATUS_CHIP: {
    'In Queue': 'default',
    'Waiting for Review': 'warning',
    Passed: 'success',
    'Not Pass': 'error',
    Canceled: 'default',
  },
  STATUS: {
    InQueue: 'In Queue',
    WaitingForReview: 'Waiting for Review',
    Passed: 'Passed',
    NotPass: 'Not Pass',
    Canceled: 'Canceled',
  },
}

export const APPROVER = {
  STATUS_CHIP: {
    'In Queue': 'default',
    'Waiting for Approve': 'warning',
    Approved: 'success',
    Rejected: 'error',
    Canceled: 'default',
  },
  STATUS: {
    InQueue: 'In Queue',
    WaitingForApprove: 'Waiting for Approve',
    Approved: 'Approved',
    Rejected: 'Rejected',
    Canceled: 'Canceled',
  },
}

export const DELIVERY = {
  TYPE: {
    UploadFile: 'Upload File',
    Document: 'Document',
  },
}

export const DOCUMENT = {
  TYPE: {
    GoodsReceivedNote: 'goods_received_note',
    PurchaseOrder: 'purchase_order',
    ChequePaymentJournal: 'cheque_payment_journal',
    PaymentJournal: 'payment_journal',
    PettyCashVoucher: 'petty_cash_voucher',
    PurchaseRequest: 'purchase_request',
    ComparisonSchedule: 'comparison_schedule',
    Quotation: 'quotation',
    Billing: 'billing',
    Invoice: 'invoice',
    Receipt: 'receipt',
    DebitNote: 'debit_note',
    PledgeProductCertificationRequest: 'pledge_product_certification_request',
  },
  STATUS: {
    Created: 'Created',
    Pending: 'Pending',
    Completed: 'Completed',
    Rejected: 'Rejected',
    Canceled: 'Canceled',
  },
  STATUS_CHIP: {
    Created: 'default',
    Pending: 'warning',
    Completed: 'success',
    Rejected: 'error',
    Canceled: 'default',
  },
}

export const DOCUMENT_SIGNER = {
  STATUS: {
    InQueue: 'In Queue',
    WaitingForApprove: 'Waiting for Approve',
    Approved: 'Approved',
    Rejected: 'Rejected',
    Canceled: 'Canceled',
  },
  STATUS_CHIP: {
    'In Queue': 'default',
    'Waiting for Approve': 'warning',
    Approved: 'success',
    Rejected: 'error',
    Canceled: 'default',
  },
}

export const TASK_TEMPLATE = {
  STATUS: {
    Active: 'Active',
    Inactive: 'Inactive',
    Deleted: 'Deleted',
  },
  STATUS_CHIP: {
    Active: 'success',
    Inactive: 'default',
    Deleted: 'error',
  },
}
export const SOW_TEMPLATE = TASK_TEMPLATE
export const SOP_TEMPLATE = TASK_TEMPLATE
export const CHAIN_LINK_TEMPLATE = TASK_TEMPLATE
