import { combineReducers } from 'redux'
import { GET_CMS_ITEM_LIST, GET_CMS_CONTENT_TYPE, GET_CMS_CONTENT_CONFIG } from '../actionTypes'

const initialState = {
  contentType: [],
  content: {},
}

const contentType = (state = initialState.contentType, action = {}) => {
  switch (action.type) {
    case GET_CMS_CONTENT_TYPE.SUCCESS:
      return action.data
    default:
      return state
  }
}

const content = (state = initialState.content, action = {}) => {
  const { contentType } = action
  switch (action.type) {
    case GET_CMS_ITEM_LIST.REQUEST:
      return {
        ...state,
        [contentType]: {
          ...state?.[contentType],
          isLoading: true,
        },
      }
    case GET_CMS_ITEM_LIST.SUCCESS:
      return {
        ...state,
        [contentType]: {
          ...state?.[contentType],
          isLoading: false,
          data: action.data,
          sort: action.sort,
          filter: action.filter,
          search: action.search,
        },
      }
    case GET_CMS_CONTENT_CONFIG.SUCCESS:
      return {
        ...state,
        [contentType]: {
          ...state?.[contentType],
          isLoading: false,
          config: action.config,
        },
      }
    case GET_CMS_ITEM_LIST.FAILURE:
      return {
        ...state,
        [contentType]: {
          ...state?.[contentType],
          isLoading: false,
        },
      }
    default:
      return state
  }
}

export default combineReducers({
  contentType,
  content,
})
