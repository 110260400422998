import { Button, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RiSearchLine } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'

import { APPROVER, DOCUMENT_SIGNER, REVIEWER, SW, TASK } from 'constants/operation-process'
import { Input } from 'components/form2/form-input'
import { Option, Select } from 'components/form2/form-select'

const defaultValues = {
  name: '',
  status: '',
}

const statuses = {
  sign_doc: [
    { id: DOCUMENT_SIGNER.STATUS.InQueue, label: 'In Queue' },
    { id: DOCUMENT_SIGNER.STATUS.WaitingForApprove, label: 'Waiting for Approve' },
    { id: DOCUMENT_SIGNER.STATUS.Approved, label: 'Approved' },
    { id: DOCUMENT_SIGNER.STATUS.Rejected, label: 'Rejected' },
    { id: DOCUMENT_SIGNER.STATUS.Canceled, label: 'Canceled' },
  ],
  sow: [
    { id: SW.STATUS.Open, label: 'Open' },
    { id: SW.STATUS.InProgress, label: 'In Progress' },
    { id: SW.STATUS.InQueue, label: 'In Queue' },
    { id: SW.STATUS.Completed, label: 'Completed' },
    { id: SW.STATUS.Canceled, label: 'Canceled' },
  ],
  task: [
    { id: TASK.STATUS.Open, label: 'Open' },
    { id: TASK.STATUS.InProgress, label: 'In Progress' },
    { id: TASK.STATUS.InQueue, label: 'In Queue' },
    { id: TASK.STATUS.Completed, label: 'Completed' },
    { id: TASK.STATUS.Canceled, label: 'Canceled' },
  ],
  review: [
    { id: REVIEWER.STATUS.InQueue, label: 'In Queue' },
    { id: REVIEWER.STATUS.WaitingForReview, label: 'Waiting for Review' },
    { id: REVIEWER.STATUS.Passed, label: 'Passed' },
    { id: REVIEWER.STATUS.NotPass, label: 'Not Pass' },
    { id: REVIEWER.STATUS.Canceled, label: 'Canceled' },
  ],
  approval: [
    { id: APPROVER.STATUS.InQueue, label: 'In Queue' },
    { id: APPROVER.STATUS.WaitingForApprove, label: 'Waiting for Approve' },
    { id: APPROVER.STATUS.Approved, label: 'Approved' },
    { id: APPROVER.STATUS.Rejected, label: 'Rejected' },
    { id: APPROVER.STATUS.Canceled, label: 'Canceled' },
  ],
}

const FilterMyTask = forwardRef(function FilterMyTask(props, ref) {
  const { t } = useTranslation()
  const { onFormChange, type } = props

  const form = useForm({ defaultValues })

  const { control, watch } = form
  const watchStatus = watch('status')
  const watchAllFields = watch()

  useImperativeHandle(ref, () => ({
    reset() {
      form.reset(defaultValues)
    },
  }))

  const handleClear = () => {
    form.reset(defaultValues)
    onFormChange && typeof onFormChange === 'function' && onFormChange(defaultValues)
  }

  useEffect(() => {
    onFormChange && typeof onFormChange === 'function' && onFormChange(watchAllFields)
  }, [watchStatus])

  return (
    <Stack>
      <Form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()

            form.setFocus('status')
            onFormChange && typeof onFormChange === 'function' && onFormChange(watchAllFields)
          }
        }}
      >
        <section>
          <Controller
            name="name"
            control={control}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  placeholder={t('op_department_operation_search')}
                  sx={{ minWidth: '500px' }}
                  startAdornment={<RiSearchLine fontSize={16} style={{ margin: '0 0 0 0.75rem' }} />}
                />
              )
            }}
          />
        </section>

        <section>
          <Controller
            name="status"
            control={control}
            render={({ field }) => {
              const handleStatusChange = (_, values) => {
                field.onChange(values)
                return values
              }

              return (
                <Select
                  {...field}
                  sx={{ minWidth: '220px' }}
                  onChange={handleStatusChange}
                  renderValue={(option) => {
                    if (!option || option === null) {
                      return <div style={{ color: 'var(--Gray-300)' }}>{t('status')}</div>
                    }
                    return option.label
                  }}
                >
                  {statuses[type].map((status) => (
                    <Option key={status.id} value={status.id}>
                      {status.label}
                    </Option>
                  ))}
                </Select>
              )
            }}
          />
        </section>

        <section className="filter-actions">
          <MuiButton variant="contained" className="gray" onClick={handleClear}>
            {t('clear')}
          </MuiButton>
        </section>
      </Form>
    </Stack>
  )
})

export default FilterMyTask

const Form = styled('form')`
  display: flex;
  /* flex-direction: column; */
  gap: 1rem;
  flex-wrap: wrap;

  section {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &.filter-actions {
      align-items: flex-end;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  section > label {
    color: 'var(--Gray-900)';
    font-size: 14px;

    & .error {
      color: red !important;
    }
  }
`

const MuiButton = styled(Button)`
  font-family: 'Inter', 'Noto Sans Thai';
  color: var(--Main-Button-Font);
  background-color: var(--Main-Button-Bg);
  border-radius: 0.5rem;
  text-transform: capitalize;
  box-shadow: none;
  height: fit-content;
  padding: 0.5rem 1rem;

  &:hover {
    color: var(--Main-Button-Font-Hover);
    background-color: var(--Main-Button-Bg-Hover);
  }

  &.gray {
    color: var(--Gray-900);
    background-color: var(--Base-White);
    border: 1px solid var(--Gray-200);

    &:hover {
      color: var(--Gray-900);
      background-color: var(--Gray-50);
      box-shadow: 0px 2px 2px var(--Gray-50);
    }
  }
`
