import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { getCompanyInfo,GetPermissionOther,GetPermissionTabList } from '../../utils/common'

import styled from 'styled-components'
import CompanyProfile from './CompanyProfile'
import DepartmentPosition from './DepartmentPosition'
import OrganizationChart from './OrganizationChart'
import PositionLevel from './PositionLevel'
import OrganizationGroup from './OrganizationGroup'

const Div = styled.div`
  .tab-container-organization {
    display: flex;

    .tab-item {
      cursor: pointer;
      padding: 10px;
      color: var(--Tab-Font-Default);
      background-color: var(--Tab-Bg-Default);

      &.active {
        color: var(--Tab-Font-Current);
        font-weight: 600;
        border-bottom: 2px solid var(--Tab-Underline-Current);
        background-color: var(--Tab-Bg-Current);
      }
    }
  }
  .button-footer-company-profile {
    margin-bottom: 25rem;
  }
  .button-footer {
    margin-bottom: 20rem;
  }
  .h-full {
    height: 100%;
  }
  .left-profile {
    margin-left: 0px;
  }
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
  .col-12 {
    width: 100%;
  }
  .input-col {
    display: flex;
    grid-template-columns: repeat(2, 10rem) 8rem;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input-header {
    color: #7f56d9;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .icon {
    cursor: pointer;
  }
  .divider {
    margin: 2rem 0;
    border: 0.1rem solid #eaecf0;
  }
  .create-access-role-header {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .side-panel-container {
    padding: 2rem;
  }
  .d-flex {
    display: flex;
  }
  .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 10px;
  }
  .column {
    flex: 1;
  }
  .justify-content-flex-start {
    justify-content: flex-start;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .content-header {
    margin-top: 24px;
  }
  .float-end {
    float: right;
  }

  .float-start {
    float: left;
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .mt-50 {
    margin-top: 50px;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .w-100 {
    width: 100%;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .text-header {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .date-filter-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .drop-down {
    min-width: 10rem;
  }

  .task-report-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .task-box {
    border: 1px solid black;
    width: 100%;
    margin: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
  }

  .task-box .amount-tasks {
    font-weight: bold;
    text-align: center;
    color: black;
    font-size: 20px;
  }

  .filter-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .wrp-filter-menu {
      position: relative;
      display: flex;
    }

    .wrp-filter-btn {
      position: relative;
    }

    .dialog-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 300px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);
      max-height: 300px;

      .wrp-person-list {
        position: relative;
      }
      .person-list {
        height: 220px;
        overflow-y: scroll;
      }

      .person-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 4px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
        }

        .img-profile {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          background-color: var(--Gray-200);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .person-item:hover {
        background-color: var(--Gray-50);
      }
    }

    .filter-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 500px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);

      .wrp-filter-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clear-btn {
          padding: 4px 6px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        .clear-btn:hover {
          background: var(--Primary-25);
        }
      }
      .wrp-filter-option {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .title-option {
          font-weight: 600;
          margin-bottom: 6px;
        }
        .option-item-style {
          border: 1px solid var(--Gray-300);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Gray-50);
        }
        .option-item-style:hover {
          background-color: var(--Gray-100);
        }
        .option-item-active {
          border: 1px solid var(--Primary-600);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Primary-600);
          color: #fff;
        }
      }
    }
  }

  .wrp-filter-menu {
    display: flex;
    align-items: center;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  /* .column div {
    margin-bottom: 20px;
  } */
  .colors {
    background-color: #7f56d9 !important;
  }
  .flex-container {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .mb {
    margin-bottom: 15px;
  }
`

const Department = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const initLang = localStorage.getItem('lang')

  const [tabViewMenu, setTabViewMenu] = useState('company_profile')
  const [menuTabList, setMenuTabList] = useState([])
  //--- ST ส่วนของ Permission LV3-4
  const { permission } = JSON.parse(localStorage.getItem('admin'))
  useEffect(() => {
    /*
    const this_sub_menu_permission = permission['Human Resources & Organization'].filter((it_2) => it_2.path === '/organization-management/organization?tab=companyprofile'); 
    if(this_sub_menu_permission.length > 0){
      const tab_permission = this_sub_menu_permission[0].permission_tab?.filter((it_2) => it_2.is_read === true)
      let temp_tab = [];
      let index_tab = 0;
      for (const  objtab_permission of tab_permission) {
        temp_tab.push({
          name : initLang == 'th' ? objtab_permission.menu_setting.name_th : objtab_permission.menu_setting.name_en,
          value : objtab_permission.menu_setting.rout_path,
          active_status : index_tab == 0 ? true : false
        })
        
        if(index_tab == 0){
          setTabViewMenu(objtab_permission.menu_setting.rout_path)
        }
        index_tab = index_tab + 1;
      }
      setMenuTabList(temp_tab)
    }
    */

    const this_tab_list = GetPermissionTabList('organization');
    if(this_tab_list.length > 0){
      const tab_permission = this_tab_list?.filter((it_2) => it_2.canRead === true)
      let temp_tab = [];
      let index_tab = 0;
      for (const  objtab_permission of tab_permission) {
        temp_tab.push({
          name : initLang == 'th' ? objtab_permission.name_th : objtab_permission.name_en,
          value : objtab_permission.key,
          active_status : index_tab == 0 ? true : false
        })
        
        if(index_tab == 0){
          setTabViewMenu(objtab_permission.key)
          
        }
        index_tab = index_tab + 1;
      }
      setMenuTabList(temp_tab)
    }
  }, [])
  //--- ED ส่วนของ Permission LV3-4

  /*
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const tabMenu = searchParams.get('tab')
    setTabViewMenu(tabMenu)
  }, [location.search])
  */

  // optional
  const toggleTabMenu = (tab) => {
    //navigate(`/organization-management/organization?tab=${tab}`)
    setTabViewMenu(tab)
  }

  return (
    <Div>
      <header>
        <div className="content-title">{t('organization_management')}</div>
        <span>{t('organization_management_sub_header')}</span>
        <div className="divider"></div>
        <div className="tab-container-organization">
          {menuTabList?.map((item, index) => (
            <div
              id={`${item.value}`}
              className={`tab-item ${tabViewMenu === item.value ? 'active' : ''}`}
              onClick={() => toggleTabMenu(item.value)}
            >
              {item.name}
            </div>
          ))} 
          {/*
          <div
            id="companyprofile"
            className={`tab-item ${tabViewMenu === 'companyprofile' ? 'active' : ''}`}
            onClick={() => toggleTabMenu('companyprofile')}
          >
            {t('company_profile')}
          </div>
          <div
            id="departmentposition"
            className={`tab-item  ${tabViewMenu === 'departmentposition' ? 'active' : ''}`}
            onClick={() => toggleTabMenu('departmentposition')}
          >
            {t('department')}
          </div>
          <div
            id="positionlevel"
            className={`tab-item  ${tabViewMenu === 'positionlevel' ? 'active' : ''}`}
            onClick={() => toggleTabMenu('positionlevel')}
          >
            {t('position_level')}
          </div>
          <div
            id="organizationchart"
            className={`tab-item ${tabViewMenu === 'organizationchart' ? 'active' : ''}`}
            onClick={() => toggleTabMenu('organizationchart')}
          >
            {t('organization')}
          </div>
           <div
            id="annualholiday"
            className={`tab-item  ${tabViewMenu === 'annualholiday' ? 'active' : ''}`}
            onClick={() => toggleTabMenu('annualholiday')}
          >
            {t('annual_holiday')}
          </div>
          <div
            id="group"
            className={`tab-item  ${tabViewMenu === 'group' ? 'active' : ''}`}
            onClick={() => toggleTabMenu('group')}
          >
            Group
          </div> 
          */}
          
        </div>
      </header>

      <div className="table-wrapper">
        {tabViewMenu === 'company_profile' ? (
          <CompanyProfile companyID={1} />
        ) : tabViewMenu === 'department' ? (
          <DepartmentPosition />
        ) : tabViewMenu === '--OrganizationChart' ? (
          <OrganizationChart />
        ) : tabViewMenu === 'position_level' ? (
          <PositionLevel />
        ) : tabViewMenu === 'team' ? (
          <OrganizationGroup />
        ) : (
          ''
        )}
      </div>
    </Div>
  )
}

export default Department
