import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { useEffect, useRef, useState } from 'react'
import { pdfjs } from 'react-pdf'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import THBText from 'thai-baht-text'
import SignatureListView from '../../../../components/feature/document-setting/SignatureListView'
import IconXClose from '../../../../components/widget/icon/IconXClose'
import { getCompanyInfoById, getSaleDocumentById } from '../../../../redux/actions/document'
import { selectDocument } from '../../../../redux/selectors'

export default function PaymentJournalDocument({
  onClose,
  docType,
  type,
  id,
  docId,
  onDownloaded,
  renderData,
  linkImage,
  textCondition = '',
  settingData,
}) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const mockupData = {
    id: 14,
    doc_group: 'purchases',
    doc_type: 'payment_journal',
    doc_id: 'PJ2024020001',
    details: 'รายละเอียด',
    seller: null,
    customer: null,
    employee_seller: null,
    purchaser: null,
    company: {
      id: 1,
      company_name_TH: 'บริษัท ทูบีซิมเปิ้ล จำกัด',
      company_name_EN: '2BSIMPLE',
      address: 'No.5 soi taweecherdchoo',
      contact_name: 'Tadswan Chanyarakskul',
      contact_number: '0865684670',
      postcode: '10400',
      tex_id: '0105554145191',
      createdAt: '2023-08-18T02:09:39.582Z',
      updatedAt: '2023-09-16T16:46:05.510Z',
      publishedAt: '2023-08-18T02:09:41.904Z',
      prefix_employee_id: 'E',
      num_pad_employee_id: 5,
      lv: 1,
      sub_company_no: null,
      status: 'active',
      address_EN: null,
      contact_name_EN: null,
      url_api_ofm: null,
      theme: null,
      username_field: null,
      thai_province: {
        id: 1,
        name_th: 'กรุงเทพมหานคร',
        name_en: 'Bangkok',
        geography_id: 2,
        deleted_at: null,
        createdAt: '2019-08-08T20:33:09.000Z',
        updatedAt: '2022-05-15T23:31:03.000Z',
        publishedAt: '2023-04-07T09:35:27.000Z',
      },
      thai_amphure: {
        id: 1026,
        name_th: 'เขตดินแดง',
        name_en: 'Khet Din Daeng',
        province_id: 1,
        deleted_at: null,
        createdAt: '2019-08-08T20:33:09.000Z',
        updatedAt: '2022-05-15T23:31:26.000Z',
        publishedAt: '2023-04-07T09:36:36.000Z',
      },
      thai_tambon: {
        id: 102601,
        zip_code: '10400',
        name_th: 'ดินแดง',
        name_en: 'Din Daeng',
        amphure_id: 1026,
        deleted_at: null,
        createdAt: '2019-08-08T20:33:09.000Z',
        updatedAt: '2022-05-15T23:31:31.000Z',
        publishedAt: '2023-04-07T09:37:04.000Z',
      },
    },
    number_of_copy: 1,
    company_logo: 'https://cdn.pixabay.com/photo/2016/11/07/13/04/yoga-1805784_1280.png',
    rubber_stamp: 'https://cdn.pixabay.com/photo/2013/07/13/11/53/best-seller-158885_1280.png',
    condition: '',
    signature_list: [],
    doc_number: 'ID1234',
    doc_date: '2023-09-28',
    payment_list: [
      {
        account_number: '1234',
        details: 'Detail a',
        debit: 1,
        credit: 0,
      },
      {
        account_number: '5678',
        details: 'Detail b',
        debit: 0,
        credit: 1,
      },
    ],
  }

  const { saleDocumentData, companyData } = useSelector(selectDocument)
  const dispatch = useDispatch()
  const HEADER_TABLE_LIST = ['No.', 'เลขที่บัญชี', 'รายละเอียด', 'เดบิท', 'เครดิต']
  const [purchaseInfoData, setPurchaseInfoData] = useState()
  const [totalPrice, setTotalPrice] = useState(0) //ราคารวมสุทธิ
  const [discountPrice, setDiscountPrice] = useState(0) //ราคาส่วนลด
  const [priceAfterDiscount, setPriceAfterDiscount] = useState(0) //ราคาหลังส่วนลด
  const [priceVat, setPriceVat] = useState(0) //ราคา vat
  const [priceAfterVat, setPriceAfterVat] = useState(0)
  const [priceWithHoldingTax, setPriceWithHoldingTax] = useState(0)
  const [summaryPrice, setSummaryPrice] = useState(0)
  const [isSetDataDone, setIsSetDataDone] = useState(false)

  const [summaryDebitPrice, setSummaryDebitPrice] = useState(0)
  const [summaryCreditPrice, setSummaryCreditPrice] = useState(0)

  const pdfRef = useRef([]) // change to array ref
  const [copy, setCopy] = useState(1)

  useEffect(() => {
    if (id && type != 'render_preview') {
      dispatch(getSaleDocumentById(id))
    } else {
    }
  }, [id])
  useEffect(() => {
    ;(async () => {
      if (Object.keys(saleDocumentData).length > 0 && type != 'render_preview') {
        calculateDebitAndCreditSummary(saleDocumentData)
        setCopy(saleDocumentData?.number_of_copy)
        setPurchaseInfoData(saleDocumentData)
        setIsSetDataDone(true)
      }
    })()
  }, [saleDocumentData])

  useEffect(() => {
    ;(async () => {
      if (type == 'render_preview' && renderData) {
        dispatch(getCompanyInfoById())

        calculateDebitAndCreditSummary(renderData)
        setPurchaseInfoData(renderData)
        setIsSetDataDone(true)
      } else if (type == 'document_setting') {
        calculateDebitAndCreditSummary(mockupData)
        setPurchaseInfoData(mockupData)
        setCopy(1)
        setIsSetDataDone(true)
      }
    })()
  }, [])

  const calculateDebitAndCreditSummary = (docData) => {
    if (docData?.payment_list?.length > 0) {
      let tmpPaymentList = [...docData?.payment_list]

      if (tmpPaymentList?.length >= 0) {
        let sumDebit = 0
        let sumCredit = 0
        for (let i = 0; i < tmpPaymentList.length; i++) {
          sumDebit = sumDebit + tmpPaymentList[i].debit
          sumCredit = sumCredit + tmpPaymentList[i].credit
        }
        setSummaryDebitPrice(sumDebit)
        setSummaryCreditPrice(sumCredit)
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      if (type == 'download_pdf' && pdfRef && docId && isSetDataDone) {
        setTimeout(() => {
          handleDownloadPdf()
        }, 800)
      }
    })()
  }, [pdfRef, isSetDataDone])

  const handleDownloadPdf = async () => {
    const element = pdfRef.current
    if (element) {
      const pdf = new jsPDF()

      for (let i = 0; i < copy; i++) {
        if (i > 0) {
          pdf.addPage()
        }

        const canvas = await html2canvas(element[i], {
          logging: true,
          letterRendering: 1,
          allowTaint: false,
          useCORS: true,
        })

        const data = canvas.toDataURL('image/png')

        const imgProperties = pdf.getImageProperties(data)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight)
      }
      pdf.save(docId + '.pdf')
      if (onDownloaded) {
        setPurchaseInfoData()
        onDownloaded()
      }
    }
  }

  const handleClickClose = () => {
    if (onClose) {
      onClose()
    }
  }
  const convertPriceToText = (price) => {
    if (price) {
      return THBText(price)
    }
    return ''
  }

  const convertIntComma = (val) => {
    if (val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
  const convertNumberToCommaTwoDecimal = (myNumber) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
    const tmpTotal = Number(myNumber).toLocaleString('en', options)
    return tmpTotal
  }

  const combineAddressCompany = (company) => {
    let tmpAddress = ''
    tmpAddress =
      company?.address +
      ', ' +
      (company?.thai_province
        ? (company?.thai_province?.name_en == 'Bangkok' ? 'แขวง' : 'ตำบล ') + company?.thai_tambon?.name_th + ', '
        : '') +
      (company?.thai_amphure ? company?.thai_amphure?.name_th + ', ' : '') +
      (company?.thai_province ? company?.thai_province?.name_th + ' ' : '') +
      company?.postcode

    return tmpAddress
  }
  const formatDate = (date) => {
    const arr = date.split('-')
    return arr[2] + '-' + arr[1] + '-' + arr[0]
  }

  return (
    <Styled
      isHaveBorder={type == 'document_setting' ? true : false}
      isDownload={type == 'download_pdf' ? true : false}
      isLineBreakBorder={type == 'print' || type == 'document_setting' ? false : true}
    >
      {purchaseInfoData && (
        <>
          {(type == 'preview' || type == 'share' || type == 'render_preview') && (
            <div className="wrp-btn-close">
              <div className="btn-close" onClick={handleClickClose}>
                <IconXClose />
              </div>
            </div>
          )}

          <div className="printarea">
            {[...Array(copy)].map(
              (
                item,
                index //map array number of copy
              ) => (
                <div
                  className={'main-content ' + (type == 'download_pdf' ? 'download-pdf-styled' : '')}
                  ref={(element) => {
                    pdfRef.current[index] = element
                  }}
                  key={'pdf' + index}
                >
                  <div className="container-top">
                    <div className="number-of-copy-bill">{index + 1 + '/' + copy}</div>
                    <div className="header-top">
                      <div className="text-header">
                        {linkImage?.url_company_logo ? (
                          <img className="image-company-logo" src={linkImage?.url_company_logo} />
                        ) : (
                          <>
                            {purchaseInfoData?.company_logo ? (
                              <img
                                className="image-company-logo"
                                src={purchaseInfoData?.company_logo + '?' + Math.random()}
                                crossOrigin="anonymous"
                              />
                            ) : (
                              <>
                                {type == 'render_preview'
                                  ? companyData?.company_name_EN
                                  : purchaseInfoData?.company?.company_name_EN}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      <div className="header-type-bill">สมุดรายวันจ่าย</div>
                    </div>
                    <div className="header-detail">
                      <div className="header-detail-left">
                        <div className="header-company">
                          <div className="name-seller">
                            {type == 'render_preview'
                              ? companyData?.company_name_TH
                              : purchaseInfoData?.company?.company_name_TH}
                          </div>
                          <div className="address">
                            {type == 'render_preview'
                              ? combineAddressCompany(companyData)
                              : combineAddressCompany(purchaseInfoData?.company)}
                          </div>
                          <div className="tax-identification-number">
                            เลขประจำตัวผู้เสียภาษี{' '}
                            <span>
                              {type == 'render_preview' ? companyData?.tex_id : purchaseInfoData?.company?.tex_id}
                            </span>
                          </div>
                        </div>
                        <div className="seller">
                          <div className="distributor">สมุดรายวันจ่าย</div>

                          <div>
                            <span>รายละเอียด</span>
                          </div>
                          <div>
                            <span>{purchaseInfoData?.details}</span>
                          </div>
                        </div>
                      </div>

                      <div className="header-detail-right">
                        <div className="detail-quotation">
                          <div className="top-detail-quotation">
                            <div className="contact-detail">
                              <div className="contact-detail-left">เลขที่</div>
                              <div className="contact-detail-right">{purchaseInfoData?.doc_number}</div>
                            </div>
                            <div className="contact-detail">
                              <div className="contact-detail-left">วันที่</div>
                              <div className="contact-detail-right">
                                {purchaseInfoData?.doc_date ? formatDate(purchaseInfoData.doc_date) : '-'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <table>
                      <thead>
                        <tr>
                          {HEADER_TABLE_LIST?.length > 0 &&
                            HEADER_TABLE_LIST.map((item, index) => (
                              <th className={'text-center'} key={'header-table-' + index}>
                                {item}
                              </th>
                            ))}
                        </tr>
                      </thead>
                      <tbody className="tbody">
                        {purchaseInfoData?.payment_list?.length > 0 &&
                          purchaseInfoData?.payment_list?.map((item, index) => (
                            <tr key={'item-' + index}>
                              <td>{index + 1 || '-'}</td>
                              <td className={'text-center'}>{item?.account_number || '-'}</td>
                              <td>{item?.details || '-'}</td>
                              <td className={'text-right'}>{convertNumberToCommaTwoDecimal(item?.debit) || '-'}</td>
                              <td className={'text-right'}>{convertNumberToCommaTwoDecimal(item?.credit) || '-'}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>

                    <div className="calculate">
                      <div className="inner-calculate-left">
                        {/* <div className="wrp-note">
                          <div className="note">หมายเหตุ</div>
                          <div className="note-detail">{purchaseInfoData?.note}</div>
                        </div>

                        {!isNaN(summaryPrice) && summaryPrice != 0 && (
                          <div className="text-thai">({convertPriceToText(summaryPrice)})</div>
                        )} */}

                        <div className="thai-price">({convertPriceToText(1)})</div>
                        <div className="thai-price">({convertPriceToText(2)})</div>
                      </div>
                      <div className="inner-calculate-right">
                        <div className="summary">
                          {/* <div className="top-summary">
                            <div className="detail">
                              <div className="detail-left">รวมเป็นเงิน</div>
                              <div className="detail-right">
                                {convertNumberToCommaTwoDecimal(totalPrice)}
                                <div className="text-bath">บาท</div>
                              </div>
                            </div>
                            {purchaseInfoData.discount_percent > 0 && (
                              <div className="detail">
                                <div className="detail-left">ส่วนลด {purchaseInfoData.discount_percent} %</div>
                                <div className="detail-right">
                                  {convertNumberToCommaTwoDecimal(discountPrice)}
                                  <div className="text-bath">บาท</div>
                                </div>
                              </div>
                            )}
                            {purchaseInfoData.discount_percent > 0 && (
                              <div className="detail">
                                <div className="detail-left">ราคาหลังส่วนลด</div>
                                <div className="detail-right">
                                  {convertNumberToCommaTwoDecimal(priceAfterDiscount)}
                                  <div className="text-bath">บาท</div>
                                </div>
                              </div>
                            )}
                            {purchaseInfoData?.vat && (
                              <div className="detail">
                                <div className="detail-left">ภาษีมูลค่าเพิ่ม 7%</div>
                                <div className="detail-right">
                                  {convertNumberToCommaTwoDecimal(priceVat)}
                                  <div className="text-bath">บาท</div>
                                </div>
                              </div>
                            )}

                            <div className="detail">
                              <div className="detail-left">
                                {purchaseInfoData?.include_tax ? 'ราคาไม่รวมภาษีมูลค่าเพิ่ม' : 'รวมจำนวนเงินทั้งสิ้น'}
                              </div>
                              <div className="detail-right">
                                {convertNumberToCommaTwoDecimal(priceAfterVat)}
                                <div className="text-bath">บาท</div>
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="bottom-summary">
                            {purchaseInfoData?.withholding_tax_percent > 0 && (
                              <div className="detail">
                                <div className="detail-left">
                                  หักภาษี ณ ที่จ่าย {purchaseInfoData?.withholding_tax_percent} %
                                </div>
                                <div className="detail-right">
                                  {convertNumberToCommaTwoDecimal(priceWithHoldingTax)}
                                  <div className="text-bath">บาท</div>
                                </div>
                              </div>
                            )}
                            <div className="detail">
                              <div className="detail-left">ยอดชำระ</div>
                              <div className="detail-right">
                                {convertNumberToCommaTwoDecimal(summaryPrice)}
                                <div className="text-bath">บาท</div>
                              </div>
                            </div>
                          </div> */}
                          <div className="bottom-summary">
                            {/* {purchaseInfoData?.withholding_tax_percent > 0 && (
                              <div className="detail">
                                <div className="detail-left">
                                  หักภาษี ณ ที่จ่าย {purchaseInfoData?.withholding_tax_percent} %
                                </div>
                                <div className="detail-right">
                                  {convertNumberToCommaTwoDecimal(priceWithHoldingTax)}
                                  <div className="text-bath">บาท</div>
                                </div>
                              </div>
                            )} */}
                            <div className="detail">
                              <div className="detail-left">ยอดรวมเดบิท</div>
                              <div className="detail-right">{convertNumberToCommaTwoDecimal(summaryDebitPrice)}</div>
                            </div>
                            <div className="detail">
                              <div className="detail-left">ยอดรวมเครดิต</div>
                              <div className="detail-right">{convertNumberToCommaTwoDecimal(summaryCreditPrice)}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div>เงื่อนไขอื่นๆ</div>
                    <div className="wrp-condition-detail">
                      <div className="convert-html">
                        {type == 'render_preview' || type == 'document_setting'
                          ? textCondition
                          : purchaseInfoData?.condition}
                      </div>
                    </div> */}
                  </div>

                  <div className="container-bottom">
                    <SignatureListView
                      rubberStamp={
                        type == 'document_setting' ? settingData[docType]?.rubber_stamp : purchaseInfoData?.rubber_stamp
                      }
                      signatureList={
                        type == 'document_setting'
                          ? settingData[docType]?.signature_list
                          : purchaseInfoData?.signature_list
                      }
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  background-color: #fff;
  width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: ${({ isHaveBorder }) => (isHaveBorder ? '1px solid var(--Gray-300)' : 'none')};

  .wrp-btn-close {
    display: flex;
    justify-content: flex-end;
    .btn-close {
      cursor: pointer;
      padding: 5px;
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    -webkit-print-color-adjust: exact;
    min-height: 1280px;

    border-bottom: ${({ isLineBreakBorder }) => (isLineBreakBorder ? '1px solid var(--Gray-600)' : 'none')};

    .container-top {
      padding: 24px;
    }
  }
  .download-pdf-styled {
    padding: 24px !important;
  }
  .container-top {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .number-of-copy-bill {
    text-align: end;
  }
  .header-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .image-company-logo {
    /* width: 50px; */
    height: 100px;
  }

  .header-detail {
    display: flex;
    justify-content: space-between;

    .header-detail-left {
      flex: 1;
      margin-right: 50px;
      .header-company {
        .name-seller,
        .address,
        .tax-identification-number {
          padding: 5px 12px 0;
        }
      }
      .text-header {
        color: var(--Gray-700);
        font-size: 18px;
        font-weight: 600;
      }
      .text-title {
        color: var(--Gray-700);
        font-size: 12px;
        font-weight: 400;
      }
    }

    .seller {
      margin-top: 20px;
      div {
        padding: 5px 12px 0;
      }
      .distributor {
        width: 228px;
        padding: 0 12px;
        background-color: var(--Primary-700);
        color: #fff;
      }
      .name-seller,
      .address,
      .tax-identification-number {
        color: black;
      }
      .tax-identification-number {
        min-height: 60px;
      }
    }

    .header-detail-right {
      width: 350px;

      .top-detail-quotation {
        width: 100%;
        /* border-bottom: 1px solid var(--Gray-200); */
        padding-bottom: 8px;
      }
      .bottom-detail-quotation {
        width: 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
      }
      .contact-detail {
        display: flex;

        .contact-detail-left {
          width: 150px;
        }
        .contact-detail-right {
          flex: 1;
          word-break: break-all;
        }
      }
    }
  }

  table {
    /* background-color: red; */
    width: 100%;
    margin: 19px 0 23px;

    &,
    th,
    td {
      border: 1px solid var(--Gray-200);
      border-collapse: collapse;
    }
    th {
      background-color: var(--Primary-700);
      color: #fff;
    }
    th,
    td {
      text-align: start;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: end;
  }
  .tbody {
    color: black;
  }

  .calculate {
    display: flex;
    justify-content: space-between;

    .inner-calculate-left {
      /* display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 20px;

      color: black;
      .wrp-note {
        .note-detail {
          margin-bottom: 20px;
        }
      } */
      .thai-price {
        margin-top: 5px;
      }
    }
    .inner-calculate-right {
      /* width: 300px; */

      color: black;
      .summary {
        .top-summary {
          border-bottom: 1px solid var(--Gray-200);
          .detail {
            margin-bottom: 5px;
          }
        }
        .bottom-summary {
          .detail {
            margin-top: 5px;
          }
        }

        .detail {
          display: flex;

          .detail-left {
            display: flex;
            margin-right: 20px;
          }
          .detail-right {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            .text-bath {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .wrp-condition-detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .convert-html {
    white-space: pre;
  }

  .container-bottom {
    .wrp-signature-date {
      /* display: flex;
      justify-content: space-between;
      margin: 90px 0 34px; */

      .signature-date {
        /* background-color: red; */
        display: flex;
        flex-direction: column;
        align-items: center;

        .wrp-signature {
          position: relative;
          .wrp-image-rubber-stamp {
            position: absolute;
            top: -85px;
            left: 56px;
            z-index: 999;
            display: flex;
            justify-content: center;
            width: 123px;

            .image-rubber-stamp {
              max-width: 100px;
              max-height: 100px;
              opacity: 0.5;
            }
          }

          .signature {
            position: absolute;
            top: -4px;
            left: 56px;
            width: 123px;
            margin: auto;
            text-align: center;
            z-index: 9999;
          }
        }
        .wrp-name-surname {
          position: relative;
          .name-surname {
            position: absolute;
            top: -4px;
            right: 0;
            left: 0;
            margin: auto;
            width: 158px;
            text-align: center;
          }
        }
        .wrp-date {
          position: relative;
          .date {
            position: absolute;
            top: -4px;
            width: 120px;
            left: 38px;
            text-align: center;
          }
        }
      }
    }

    /* old */
    /* .date-and-signature {
      .row {
        display: flex;
        justify-content: space-between;
        position: relative;

        .left,
        .right {
          flex: 1;
          flex-direction: column;
          width: fit-content;
          text-align: center;

          color: black;
        }

        .right {
          position: relative;

          .verified-by {
            position: absolute;
            left: 41%;
            bottom: 70%;
            width: 160px;
            max-height: 100px;

            display: flex;
            align-items: flex-end;
            justify-content: center;
          }
          .image-signature-file {
            max-width: 100px;
            max-height: 100px;
          }
        }

        .center {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;

          left: 0;
          right: 0;
          top: -40px;

          .wrp-image-rubber-stamp {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .image-rubber-stamp {
            max-width: 100px;
            max-height: 100px;
          }
        }
      }
    } */
  }

  canvas {
    width: 100% !important;
    height: auto !important;
  }
  .printarea {
    min-height: 1300px;
    display: flex;
    flex-direction: column;
  }
`
