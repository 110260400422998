import axios from 'axios'

import { BASE_API_DYNAMIC_FORM } from '../configs/app'
import { getAdminToken, removeAdminData, setAdminData } from './common'

const fetchApi = async (options = {}, doubleBearer = false) => {
  const { url, headers, ...restOptions } = options
  const token = getAdminToken()
  const defaultOptions = {
    baseURL: BASE_API_DYNAMIC_FORM,
    timeout: 60000,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    data: {},
    params: {},
  }

  defaultOptions.headers = {
    ...defaultOptions.headers,
    ...headers,
  }
  if (defaultOptions.headers.Authorization !== null) {
    // ------ ถ้าไม่ต้องการใช้ token ให้ส่งมาเป็น null เพราะ fetchApi set default token ให้ทุกครั้งที่มีการ call api ถ้าไม่อยากใช้ token ให้ส่งมาเป็น null
    //---- By jame ให้เข้ากระบวนการ refresh token เฉพาะ API ที่ใช้ token ส่วน API อื่นๆ เช่น servertime จะไม่ได้ใช้ token
    if (token) {
      const isTokenExpired = checkExpireToken()

      if (isTokenExpired) {
        const newToken = await getRefreshToken()
        defaultOptions.headers.Authorization = `${newToken}`
      } else {
        defaultOptions.headers.Authorization = `${token}`
        if (doubleBearer) {
          defaultOptions.headers.Authorization = defaultOptions.headers.Authorization
        }
      }
    }
  }

  try {
    const { data } = await axios.request({
      url,
      ...defaultOptions,
      ...restOptions,
    })

    return data
  } catch (error) {
    if (error.response?.status === 401) {
      removeAdminData()
      window.location.href = '/login'
      return null
    }
    return Promise.reject(error)
  }
}
const checkExpireToken = () => {
  const { jwtExpiresDate } = JSON.parse(localStorage.getItem('admin'))

  const expiryDateUTC = new Date(jwtExpiresDate)
  // const expiryDateUTC = new Date('2024-03-14T07:11:36.000Z')
  const currentDateLocal = new Date()

  if (currentDateLocal > expiryDateUTC) return true

  return false
}
const getRefreshToken = async () => {
  const admin = JSON.parse(localStorage.getItem('admin'))
  const headers = {
    'Content-Type': 'application/json',
    Authorization: null,
  }
  const request = {
    refreshToken: admin?.refreshToken,
  }

  const { data } = await axios.request({
    method: 'post',
    baseURL: BASE_API_DYNAMIC_FORM,
    url: '/api/token/refresh',
    headers,
    data: request,
  })

  const { jwt, refreshToken, jwtExpiresDate } = data

  if (jwt && refreshToken && jwtExpiresDate) {
    const oldAdminStore = JSON.parse(localStorage.getItem('admin'))
    const newAdminStore = {
      ...oldAdminStore,
      jwt,
      refreshToken,
      jwtExpiresDate,
    }

    setAdminData(newAdminStore)
  }

  return jwt
}

export const getAPI = (options = {}, ...arg) => fetchApi(options, ...arg)
export const postAPI = (options = {}, ...arg) =>
  fetchApi(
    {
      ...options,
      method: 'POST',
    },
    ...arg
  )
export const putAPI = async (options = {}, ...arg) =>
  fetchApi(
    {
      ...options,
      method: 'PUT',
    },
    ...arg
  )
export const patchAPI = async (options = {}, ...arg) =>
  fetchApi(
    {
      ...options,
      method: 'PATCH',
    },
    ...arg
  )
export const deleteAPI = async (options = {}, ...arg) =>
  fetchApi(
    {
      ...options,
      method: 'DELETE',
    },
    ...arg
  )
