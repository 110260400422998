import styled from 'styled-components'

export default function SignatureListView({ signatureList, rubberStamp }) {
  return (
    <Styled signatureLength={signatureList?.length || 0}>
      <div className="wrp-signature-date">
        {signatureList?.map((item, index) => (
          <div key={'signature-item-' + index} className="signature-date">
            <div className="wrp-signature">
              {item?.rubber_stamp && rubberStamp && (
                <div className="wrp-image-rubber-stamp">
                  <img className="image-rubber-stamp" src={rubberStamp} />
                </div>
              )}
              <div className="signature">
                {(item?.status == 'approved' && item?.signature_image) ? (
                  <div className="wrp-image-signature">
                    <img
                      className="image-signature-file"
                      src={item?.signature_image + '?' + Math.random()}
                      crossOrigin="anonymous"
                    />
                  </div>
                ) : (
                  <div className="signature">{item?.signature || ''}</div>
                )}
              </div>
              <div className="form">{item?.label}............................</div>
            </div>

            <div className="wrp-name-surname">
              {item?.status == 'approved' && <div className="name-surname">{item?.name || ''}</div>}
              <div className="form">(.........................................)</div>
            </div>

            <div className="wrp-date">
              <div className="date">{item?.date || ''}</div>
              <div className="form">วันที่..................................</div>
            </div>
          </div>
        ))}
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  padding: 24px;
  width: 100%;

  .wrp-signature-date {
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0;
    flex-wrap: wrap;

    .signature-date {
      width: ${({ signatureLength }) =>
        signatureLength == 3 || signatureLength == 5 || signatureLength == 6 ? '30%' : '25%'};
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 100px;

      .wrp-signature {
        position: relative;
        .wrp-image-rubber-stamp {
          position: absolute;
          top: -85px;
          left: 56px;
          z-index: 999;
          display: flex;
          justify-content: center;
          width: 123px;

          .image-rubber-stamp {
            max-width: 100px;
            max-height: 100px;
            opacity: 0.3;
          }
        }

        .signature {
          position: absolute;
          top: -4px;
          left: 75px;
          margin-left: 10px;
          /* width: 123px; */
          margin: auto;
          text-align: center;
          z-index: 9999;
        }
        .wrp-image-signature {
          position: absolute;
          top: -80px;
          left: -30px;
          z-index: 999;
          display: flex;
          justify-content: center;
          width: 123px;

          .image-signature-file {
            max-width: 110px;
            /* max-height: 100px; */
          }
        }
      }
      .wrp-name-surname {
        position: relative;
        .name-surname {
          position: absolute;
          top: -2px;
          right: 0;
          left: 0;
          margin: auto;
          width: 158px;
          text-align: center;
          font-size: 13px;
        }
      }
      .wrp-date {
        position: relative;
        .date {
          position: absolute;
          top: -4px;
          width: 120px;
          left: 38px;
          text-align: center;
        }
      }
    }
  }
`
