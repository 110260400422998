import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  createBusinessDocument,
  editBusinessDocument,
  getBusinessDocById,
  getDocumentSettingByCompanyId,
} from 'redux/actions/document'
import { getCompanyInfoById } from 'redux/actions/master'
import { selectDocument } from 'redux/selectors'
import styled from 'styled-components'
import { generateId } from 'utils/helper'
import CreateDocLayout from '../custom-view/CreateDocLayout'
import DialogDoc from '../custom-view/DialogDoc'

export default function PaymentVoucher({ previewType, currentPage, onCloseDialog, filter, itemSelected }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const docId = searchParams?.get('id')
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { businessDocumentData, companyData, documentSettingInfo } = useSelector(selectDocument)

  const MOCKUP_DATA_FOR_CREATE = {
    data: {
      doc_type: 'payment_voucher',
      creator_id: 2, //employee id ของคนสร้าง doc
      details: 'รายละเอียด',

      doc_date: '2023-09-28',
      payment_type: 'cash_cheque', //cash,cash_cheque,bank_account
      cheque_number: 'c123', //for cash_cheque type
      cheque_date: '2023-09-28', //for cash_cheque type
      bank_name: 'Bank A', //for cash_cheque and bank_account type
      account_number: '123-456-789', // for bank_account type
      account_date: '2023-09-28', // for bank_account type
      account_type: 'ออมทรัพย์', // for bank_account type
      payment_list: [
        { details: 'Detail a', amount: 1 },
        { details: 'Detail b', amount: 2 },
      ],
      account_list: [
        { account_number: '1234', account_name: 'ABC', debit: 1, credit: 0 },
        { account_number: '5678', account_name: 'DEF', debit: 0, credit: 1 },
      ],
      company_id: 1, //company id ของคนสร้าง doc
    },
  }

  const docTitle = 'ใบสำคัญจ่าย'
  const docType = 'payment_voucher'
  const navigatePath = '/document/accounting'

  const HEADER_LIST = [
    [
      [
        {
          id: 'hd01',
          position: 'left',
          type: 'input_text_area',
          title: 'รายระเอียดการจ่าย',
          placeholder: 'กรอกรายละเอียด',
          class_name_create: '',
          class_name_preview: '',
        },
      ],
    ],
    [
      { id: 'hd02', type: 'input_date', title: 'วันที่', placeholder: 'select' },
      {
        id: 'hd03',
        type: 'input_dropdown',
        title: 'Payment Type',
        place_holder: 'Cash',
        data_list: [
          { text: 'Cash', value: 'cash' },
          { text: 'Cash Cheque', value: 'cash_cheque' },
          { text: 'Bank Account', value: 'bank_account' },
        ],
        value: { text: 'cash' },
        view_type: 'hide_show',
      },
      // Cheque
      { id: 'hd04', type: 'input_text', title: 'เช็คเลขที่', select_type: 'cash_cheque', is_hidden: true },
      { id: 'hd05', type: 'input_date', title: 'เช็คลงวันที่', select_type: 'cash_cheque', is_hidden: true },
      {
        id: 'hd06',
        type: 'input_text',
        title: 'ธนาคาร',
        placeholder: 'ไทยพาณิชย์',
        select_type: 'cash_cheque',
        is_hidden: true,
      },
      //Bank account
      {
        id: 'hd06-1',
        type: 'input_text',
        title: 'ธนาคาร',
        placeholder: 'ไทยพาณิชย์',
        select_type: 'bank_account',
        is_hidden: true,
      },
      {
        id: 'hd07',
        type: 'input_text',
        title: 'เลขที่บัญชี',
        placeholder: '000000000',
        select_type: 'bank_account',
        is_hidden: true,
      },
      { id: 'hd08', type: 'input_date', title: 'วันที่', select_type: 'bank_account', is_hidden: true },
      {
        id: 'hd09',
        type: 'input_dropdown',
        title: 'ประเภทการนำฝาก',
        place_holder: 'ออมทรัพย์',
        data_list: [
          { text: 'สะสมทรัพย์', value: 'สะสมทรัพย์' },
          { text: 'กระแสรายวัน', value: 'กระแสรายวัน' },
        ],
        value: { text: 'สะสมทรัพย์' },
        select_type: 'bank_account',
        is_hidden: true,
      },
    ],
  ]
  const BODY_LIST = [
    [
      {
        id: 'bd01',
        type: 'table',
        title: '',
        class_name_create: 'width_100',
        class_name_preview: 'width_100',
        value: {
          header: [
            {
              id: 'bd01-hd-1',
              type: 'text',
              title: 'รายละเอียด',
              class_name_create: 'col_width_100',
              class_name_preview: 'width_80',
            },
            {
              id: 'bd01-hd-2',
              type: 'text',
              title: 'จำนวนเงิน',
            },
            { id: 'bd01-hd-3', type: 'action', title: '' },
          ],
          body: [
            [
              {
                id: 'bd01-bd-1',
                type: 'input_text',
                placeholder: 'Details',
                class_name_create: '',
                class_name_preview: 'width_80 padding_left_right_10px',
              },
              {
                id: 'bd01-bd-2',
                type: 'input_text',
                placeholder: '0.00',
                sub_type: 'number',
                step: '0.01',
              },
              { id: 'bd01-bd-3', type: 'button_delete' },
            ],
          ],
        },
      },
    ],
    [
      {
        id: 'bd02',
        type: 'table',
        title: '',
        class_name_create: 'width_100',
        class_name_preview: 'width_100',
        value: {
          header: [
            { id: 'bd02-hd-1', type: 'text', title: 'รหัสบัญชี', class_name_preview: 'width_10' },
            {
              id: 'bd02-hd-2',
              type: 'text',
              title: 'ชื่อบัญชี',
              class_name_create: 'col_width_100',
              class_name_preview: '',
            },
            { id: 'bd02-hd-3', type: 'text', title: 'จำนวนเงิน เดบิท', class_name_preview: 'width_20' },
            { id: 'bd02-hd-4', type: 'text', title: 'จำนวนเงิน เครดิต', class_name_preview: 'width_20' },
            { id: 'bd02-hd-5', type: 'action', title: '' },
          ],
          body: [
            [
              { id: 'bd02-bd-1', type: 'input_text', placeholder: '0-00-0000', class_name_preview: 'width_10' },
              {
                id: 'bd02-bd-2',
                type: 'input_text',
                placeholder: 'Details',
                class_name_create: 'width_100',
                class_name_preview: 'text_align_left padding_left_right_10px',
              },
              {
                id: 'bd02-bd-3',
                type: 'input_text',
                placeholder: '0.00',
                sub_type: 'number',
                step: '0.01',
                class_name_preview: 'width_20',
              },
              {
                id: 'bd02-bd-4',
                type: 'input_text',
                placeholder: '0.00',
                sub_type: 'number',
                step: '0.01',
                class_name_preview: 'width_20',
              },
              { id: 'bd02-bd-5', type: 'button_delete' },
            ],
          ],
        },
      },
    ],
  ]

  const [isBindingDataDone, setIsBindingDataDone] = useState(false)
  const [previewStage, setPreviewStage] = useState(previewType || 'close')
  const [documentId, setDocumentId] = useState()
  const [headerList, setHeaderList] = useState(HEADER_LIST)
  const [bodyList, setBodyList] = useState(BODY_LIST)

  const [companyDocData, setCompanyDocData] = useState()

  useEffect(() => {
    dispatch(getCompanyInfoById())
    dispatch(getDocumentSettingByCompanyId())
  }, [])

  useEffect(() => {
    if (docId || itemSelected?.id) {
      let tmpId = docId || itemSelected?.id
      setDocumentId(tmpId)
    }
  }, [docId, itemSelected])

  useEffect(() => {
    if (documentId && documentId != 'undefined') {
      dispatch(getBusinessDocById(documentId))
    }
  }, [documentId])

  useEffect(() => {
    if (documentId && businessDocumentData?.id) {
      bindEditData()
    }
  }, [businessDocumentData])

  //เรียกข้อมูลฝั่งซ้ายของ header
  useEffect(() => {
    let tmpCompanyData

    if (!documentId) {
      tmpCompanyData = {
        seller_logo: documentSettingInfo[docType]?.company_logo,
        seller_company_name: companyData?.company_name_TH,
        seller_address: companyData?.address,
        seller_tax_id: companyData?.tex_id,
        payment_detail: headerList[0][0][0]?.value?.text || '',
      }
    } else {
      if (businessDocumentData) {
        tmpCompanyData = {
          seller_logo: businessDocumentData?.company_logo,
          seller_company_name: businessDocumentData?.company?.company_name_TH,
          seller_address: businessDocumentData?.company?.address,
          seller_tax_id: businessDocumentData?.company?.tex_id,
          payment_detail: headerList[0][0][0]?.value?.text || '',
          doc_id: businessDocumentData?.doc_id,
        }
      }
    }
    setCompanyDocData(tmpCompanyData)
  }, [companyData, documentId, businessDocumentData, documentSettingInfo])

  const bindEditData = () => {
    setIsBindingDataDone(false)
    //bindHeader
    let tmpHeaderList = [...headerList]
    for (let colIndex = 0; colIndex < tmpHeaderList.length; colIndex++) {
      let colItem = tmpHeaderList[colIndex]
      //  col left
      if (colIndex == 0) {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]
          for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
            let dataItem = rowItem[dataIndex]

            if (dataItem?.id == 'hd01') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = { text: businessDocumentData?.details }
            }
          }
        }
      }
      //  col right
      else if (colIndex == 1) {
        for (let dataIndex = 0; dataIndex < colItem.length; dataIndex++) {
          let dataItem = colItem[dataIndex]

          if (dataItem?.id == 'hd02') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.doc_date,
            }
          } else if (dataItem?.id == 'hd03') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.payment_type,
            }
            if (businessDocumentData?.payment_type == 'cash') {
              tmpHeaderList[colIndex][2].is_hidden = true
              tmpHeaderList[colIndex][3].is_hidden = true
              tmpHeaderList[colIndex][4].is_hidden = true
              tmpHeaderList[colIndex][5].is_hidden = true
              tmpHeaderList[colIndex][6].is_hidden = true
              tmpHeaderList[colIndex][7].is_hidden = true
              tmpHeaderList[colIndex][8].is_hidden = true
            } else if (businessDocumentData?.payment_type == 'cash_cheque') {
              tmpHeaderList[colIndex][2].is_hidden = false
              tmpHeaderList[colIndex][3].is_hidden = false
              tmpHeaderList[colIndex][4].is_hidden = false
              tmpHeaderList[colIndex][5].is_hidden = true
              tmpHeaderList[colIndex][6].is_hidden = true
              tmpHeaderList[colIndex][7].is_hidden = true
              tmpHeaderList[colIndex][8].is_hidden = true
            } else if (businessDocumentData?.payment_type == 'bank_account') {
              tmpHeaderList[colIndex][2].is_hidden = true
              tmpHeaderList[colIndex][3].is_hidden = true
              tmpHeaderList[colIndex][4].is_hidden = true
              tmpHeaderList[colIndex][5].is_hidden = false
              tmpHeaderList[colIndex][6].is_hidden = false
              tmpHeaderList[colIndex][7].is_hidden = false
              tmpHeaderList[colIndex][8].is_hidden = false
            }
          } else if (dataItem?.id == 'hd04') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.cheque_number,
            }
          } else if (dataItem?.id == 'hd05') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.cheque_date,
            }
          } else if (dataItem?.id == 'hd06') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.bank_name,
            }
          } else if (dataItem?.id == 'hd06-1') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.bank_name,
            }
          } else if (dataItem?.id == 'hd07') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.account_number,
            }
          } else if (dataItem?.id == 'hd08') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.account_date,
            }
          } else if (dataItem?.id == 'hd09') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.account_type,
            }
          }
        }
      }
    }

    //bindBody
    let tmpBodyList = JSON.parse(JSON.stringify(bodyList))
    let tmpTableBody

    for (let rowIndex = 0; rowIndex < tmpBodyList.length; rowIndex++) {
      let rowItem = tmpBodyList[rowIndex]

      for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
        let dataItem = rowItem[dataIndex]

        if (dataItem?.id == 'bd01') {
          tmpTableBody = dataItem?.value?.body || []

          let tmpDataList = businessDocumentData?.payment_list || []

          let prototypeTableRow = tmpTableBody[0]
          tmpTableBody = []
          for (let dataIndex = 0; dataIndex < tmpDataList.length; dataIndex++) {
            let dataItem = tmpDataList[dataIndex]
            let tmpTableRowItem = prototypeTableRow

            for (let i = 0; i < tmpTableRowItem.length; i++) {
              tmpTableRowItem[i].id = generateId(6)

              if (prototypeTableRow[i]?.placeholder) {
                tmpTableRowItem[i].placeholder = prototypeTableRow[i].placeholder
              }

              if (i == 0) {
                tmpTableRowItem[i].value = { text: dataItem?.details }
              } else if (i == 1) {
                tmpTableRowItem[i].value = {
                  text: dataItem?.amount,
                }
              }
            }

            tmpTableBody.push(tmpTableRowItem)
          }
          tmpBodyList[rowIndex][dataIndex].value.body = tmpTableBody
        } else if (dataItem?.id == 'bd02') {
          tmpTableBody = dataItem?.value?.body || []

          let tmpDataList = businessDocumentData?.account_list || []

          let prototypeTableRow = tmpTableBody[0]
          tmpTableBody = []
          for (let dataIndex = 0; dataIndex < tmpDataList.length; dataIndex++) {
            let dataItem = tmpDataList[dataIndex]
            let tmpTableRowItem = prototypeTableRow

            for (let i = 0; i < tmpTableRowItem.length; i++) {
              tmpTableRowItem[i].id = generateId(6)

              if (prototypeTableRow[i]?.placeholder) {
                tmpTableRowItem[i].placeholder = prototypeTableRow[i].placeholder
              }

              if (i == 0) {
                tmpTableRowItem[i].value = { text: dataItem?.account_number }
              } else if (i == 1) {
                tmpTableRowItem[i].value = {
                  text: dataItem?.account_name,
                }
              } else if (i == 2) {
                tmpTableRowItem[i].value = {
                  text: dataItem?.debit,
                }
              } else if (i == 3) {
                tmpTableRowItem[i].value = {
                  text: dataItem?.credit,
                }
              }
            }

            tmpTableBody.push(tmpTableRowItem)
          }
          tmpBodyList[rowIndex][dataIndex].value.body = tmpTableBody
        }
      }
    }

    setHeaderList(tmpHeaderList)
    setBodyList(tmpBodyList)
    setIsBindingDataDone(true)
  }

  const getHeaderValues = () => {
    let tmpValues = {}
    let tmpHeaderList = [...headerList]

    for (let colIndex = 0; colIndex < tmpHeaderList.length; colIndex++) {
      let colItem = tmpHeaderList[colIndex]

      if (colIndex == 0) {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]
          for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
            let dataItem = rowItem[dataIndex]
            if (dataItem?.id == 'hd01' && dataItem?.value?.text) {
              tmpValues.details = dataItem?.value?.text
            }
            if (!dataItem?.value?.text) {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                ...tmpHeaderList[colIndex][dataIndex]?.value,
                is_error: true,
              }
            }
          }
        }
      } else {
        for (let dataIndex = 0; dataIndex < colItem.length; dataIndex++) {
          let dataItem = colItem[dataIndex]

          if (dataItem?.id == 'hd02' && dataItem?.value?.text) {
            tmpValues.doc_date = dataItem?.value?.text
          }
          //cash,cash_cheque,bank_account
          else if (dataItem?.id == 'hd03' && dataItem?.value?.text) {
            tmpValues.payment_type = dataItem?.value?.text
          }

          //cash_cheque
          else if (dataItem?.id == 'hd04' && dataItem?.value?.text) {
            tmpValues.cheque_number = dataItem?.value?.text
          } else if (dataItem?.id == 'hd05' && dataItem?.value?.text) {
            tmpValues.cheque_date = dataItem?.value?.text
          }

          //for cash_cheque and bank_account type
          else if ((dataItem?.id == 'hd06' || dataItem?.id == 'hd06-1') && dataItem?.value?.text) {
            tmpValues.bank_name = dataItem?.value?.text
          } else if (dataItem?.id == 'hd07' && dataItem?.value?.text) {
            tmpValues.account_number = dataItem?.value?.text
          } else if (dataItem?.id == 'hd08' && dataItem?.value?.text) {
            tmpValues.account_date = dataItem?.value?.text
          } else if (dataItem?.id == 'hd09' && dataItem?.value?.text !== 'all') {
            tmpValues.account_type = dataItem?.value?.text
          }

          //delete value not use
          if (dataItem[1]?.value?.text == 'cash') {
            delete tmpValues.cheque_number
            delete tmpValues.cheque_date
            delete tmpValues.bank_name
            delete tmpValues.account_number
            delete tmpValues.account_date
            delete tmpValues.account_type
          } else if (dataItem[1]?.value?.text == 'cash_cheque') {
            delete tmpValues.account_number
            delete tmpValues.account_date
            delete tmpValues.account_type
          } else if (dataItem[1]?.value?.text == 'bank_account') {
            delete tmpValues.cheque_number
            delete tmpValues.cheque_date
          }

          if (!dataItem?.value?.text) {
            tmpHeaderList[colIndex][dataIndex].value = {
              ...tmpHeaderList[colIndex][dataIndex]?.value,
              is_error: true,
            }
          }
        }
      }
    }
    setHeaderList(tmpHeaderList)

    return tmpValues
  }
  const getBodyValues = () => {
    let tmpBodyList = JSON.parse(JSON.stringify(bodyList))
    let tmpPaymentList = []
    let tmpAccountList = []

    for (let rowIndex = 0; rowIndex < tmpBodyList.length; rowIndex++) {
      let rowItem = tmpBodyList[rowIndex]
      for (let colIndex = 0; colIndex < rowItem.length; colIndex++) {
        let colItem = rowItem[colIndex]

        if (colItem?.id == 'bd01') {
          let tmpBodyTable = colItem?.value?.body || []

          for (let tableRowIndex = 0; tableRowIndex < tmpBodyTable.length; tableRowIndex++) {
            let tableRowItem = tmpBodyTable[tableRowIndex]
            let item = {}

            for (let tableColIndex = 0; tableColIndex < tableRowItem.length; tableColIndex++) {
              let tableColItem = tableRowItem[tableColIndex]

              if (tableColIndex == 0 && tableColItem?.value?.text) {
                item.details = tableColItem.value.text
              } else if (tableColIndex == 1 && tableColItem?.value?.text) {
                item.amount = parseInt(tableColItem.value.text)
              }

              if (tableColIndex == 0 || tableColIndex == 1) {
                if (!tableColItem?.value?.text) {
                  tmpBodyTable[tableRowIndex][tableColIndex].value = {
                    ...tmpBodyTable[tableRowIndex][tableColIndex]?.value,
                    is_error: true,
                  }
                }
              }
            }
            tmpPaymentList.push(item)
          }
          tmpBodyList[rowIndex][colIndex].value = { ...tmpBodyList[rowIndex][colIndex]?.value, body: tmpBodyTable }
        }

        if (colItem?.id == 'bd02') {
          let tmpBodyTable = colItem?.value?.body || []

          for (let tableRowIndex = 0; tableRowIndex < tmpBodyTable.length; tableRowIndex++) {
            let tableRowItem = tmpBodyTable[tableRowIndex]
            let item = {}

            for (let tableColIndex = 0; tableColIndex < tableRowItem.length; tableColIndex++) {
              let tableColItem = tableRowItem[tableColIndex]

              if (tableColIndex == 0 && tableColItem?.value?.text) {
                item.account_number = tableColItem.value.text
              } else if (tableColIndex == 1 && tableColItem?.value?.text) {
                item.account_name = tableColItem.value.text
              } else if (tableColIndex == 2 && tableColItem?.value?.text) {
                item.debit = parseInt(tableColItem.value.text)
              } else if (tableColIndex == 3 && tableColItem?.value?.text) {
                item.credit = parseInt(tableColItem.value.text)
              }

              if (tableColIndex == 0 || tableColIndex == 1 || tableColIndex == 2 || tableColIndex == 3) {
                if (!tableColItem?.value?.text) {
                  tmpBodyTable[tableRowIndex][tableColIndex].value = {
                    ...tmpBodyTable[tableRowIndex][tableColIndex]?.value,
                    is_error: true,
                  }
                }
              }
            }
            tmpAccountList.push(item)
          }
          tmpBodyList[rowIndex][colIndex].value = { ...tmpBodyList[rowIndex][colIndex]?.value, body: tmpBodyTable }
        }
      }
    }
    setBodyList(tmpBodyList)
    return { payment_list: tmpPaymentList, account_list: tmpAccountList }
  }

  const handelCloseDialog = () => {
    setPreviewStage('close')
    if (onCloseDialog) {
      onCloseDialog()
    }
  }

  const onBtnPreviewClicked = () => {
    setPreviewStage('preview_create')
  }

  const handleDetailChange = (headerLeftData) => {
    let tmpCompanyData = { ...companyDocData }

    tmpCompanyData.payment_detail = headerLeftData[0][0][0]?.value?.text || ''
    setCompanyDocData(tmpCompanyData)
  }

  const onBtnSaveClicked = async () => {
    let tmpSendData = {
      doc_type: docType,
      creator_id: employee_info?.id,
      company_id: company_info?.id,
    }

    let tmpHeaderValues = getHeaderValues()
    let tmpBodyValues = getBodyValues()

    if (!tmpHeaderValues?.details || !tmpHeaderValues?.doc_date) {
      return
    }
    if (
      tmpHeaderValues?.payment_type == 'cash_cheque' &&
      (!tmpHeaderValues?.cheque_number || !tmpHeaderValues?.cheque_date || !tmpHeaderValues?.bank_name)
    ) {
      return
    }
    if (
      tmpHeaderValues?.payment_type == 'bank_account' &&
      (!tmpHeaderValues?.account_number || !tmpHeaderValues?.account_date || !tmpHeaderValues?.account_type)
    ) {
      return
    }

    if (tmpBodyValues?.payment_list?.length == 0 || tmpBodyValues?.account_list?.length == 0) {
      return
    }

    for (let rowIndex = 0; rowIndex < tmpBodyValues?.payment_list.length; rowIndex++) {
      let rowItem = tmpBodyValues.payment_list[rowIndex]
      if (!rowItem?.details || !rowItem?.amount) {
        return
      }
    }

    for (let rowIndex = 0; rowIndex < tmpBodyValues?.account_list.length; rowIndex++) {
      let rowItem = tmpBodyValues.account_list[rowIndex]
      if (!rowItem?.account_number || !rowItem?.account_name || !rowItem?.debit || !rowItem?.credit) {
        return
      }
    }

    let uploadData = { ...tmpSendData, ...tmpHeaderValues, ...tmpBodyValues }

    tmpSendData = {
      type: docType,
      data: uploadData,
    }

    if (!documentId) {
      await dispatch(createBusinessDocument(tmpSendData))
      navigate(navigatePath)
    } else {
      dispatch(editBusinessDocument(tmpSendData, documentId))
    }
  }

  return (
    <Styled>
      {(previewStage == 'close' || previewStage == 'preview_create') && (
        <CreateDocLayout
          isEdit={documentId ? true : false}
          title={docTitle}
          headerList={headerList}
          bodyList={bodyList}
          onChange={(e) => {
            if (e?.layout_type == 'header_left' && e?.data) {
              handleDetailChange(e.data)
              setHeaderList(e?.data)
            } else if (e?.layout_type == 'header_right') {
              setHeaderList(e?.data)
            } else if (e?.layout_type == 'body') {
              setBodyList(e?.data)
            }
          }}
          onBtnSaveClicked={onBtnSaveClicked}
          onBtnPreviewClicked={onBtnPreviewClicked}
          docType={docType}
        />
      )}

      {(previewStage == 'request_approve' ||
        previewStage == 'preview' ||
        previewStage == 'print' ||
        previewStage == 'download_pdf' ||
        previewStage == 'share' ||
        previewStage == 'preview_create') && (
        <DialogDoc
          docType={docType}
          previewType={previewStage}
          headerList={headerList}
          bodyList={bodyList}
          onCloseClicked={handelCloseDialog}
          companyDocData={companyDocData}
          docId={documentId}
          docTitle={docTitle}
          copyNumber={documentSettingInfo?.payment_voucher?.number_of_copy}
          isBindingDataDone={isBindingDataDone}
          // onDownloaded={}
          currentPage={currentPage}
          filter={filter}
          itemSelected={itemSelected}
          rubberStamp={documentSettingInfo?.payment_voucher?.rubber_stamp}
          signatureList={documentSettingInfo?.payment_voucher?.signature_list}
        />
      )}
    </Styled>
  )
}
const Styled = styled.div``
