import { combineReducers } from 'redux'
import {
  GET_OP_TASK_LIST,
  GET_OP_REVIEWER_LIST,
  GET_OP_APPROVER_LIST,
  GET_OP_SW_LIST,
  GET_PROJECT_FOR_ACTION_PLAN,
  GET_TASK_FOR_ACTION_PLAN,
  GET_TOTAL_PROJECT_TASK,
  GET_TOTAL_TASK,
  GET_OP_COUNT,
} from 'redux/actionTypes'

const initialState = {
  opTaskList: [],
  opReviewerList: [],
  opApproverList: [],
  opSwList: [],
  projects: [],
  taskList: [],
  totolProject: {},
  totalTask: {},
  totalOP: {},
  total: 1,
  page: 1,
  pageCount: 1,
  pageSize: 10,
  isLoading: false,
  state: '',
}

const actionPlanReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_OP_TASK_LIST.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_OP_TASK_LIST.REQUEST',
      }
    }
    case GET_OP_TASK_LIST.SUCCESS: {
      const { data, meta } = action

      return {
        ...state,
        isLoading: false,
        opTaskList: data,
        page: meta.pagination.page,
        pageCount: meta.pagination.pageCount,
        pageSize: meta.pagination.pageSize,
        total: meta.pagination.total,
        state: 'GET_OP_TASK_LIST.SUCCESS',
      }
    }
    case GET_OP_TASK_LIST.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_OP_TASK_LIST.FAILURE',
      }
    }
    case GET_OP_REVIEWER_LIST.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_OP_REVIEWER_LIST.REQUEST',
      }
    }
    case GET_OP_REVIEWER_LIST.SUCCESS: {
      const { data, meta } = action

      return {
        ...state,
        isLoading: false,
        opReviewerList: data,
        page: meta.pagination.page,
        pageCount: meta.pagination.pageCount,
        pageSize: meta.pagination.pageSize,
        total: meta.pagination.total,
        state: 'GET_OP_REVIEWER_LIST.SUCCESS',
      }
    }
    case GET_OP_REVIEWER_LIST.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_OP_REVIEWER_LIST.FAILURE',
      }
    }
    case GET_OP_APPROVER_LIST.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_OP_APPROVER_LIST.REQUEST',
      }
    }
    case GET_OP_APPROVER_LIST.SUCCESS: {
      const { data, meta } = action

      return {
        ...state,
        isLoading: false,
        opApproverList: data,
        page: meta.pagination.page,
        pageCount: meta.pagination.pageCount,
        pageSize: meta.pagination.pageSize,
        total: meta.pagination.total,
        state: 'GET_OP_APPROVER_LIST.SUCCESS',
      }
    }
    case GET_OP_APPROVER_LIST.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_OP_APPROVER_LIST.FAILURE',
      }
    }
    case GET_OP_SW_LIST.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_OP_SW_LIST.REQUEST',
      }
    }
    case GET_OP_SW_LIST.SUCCESS: {
      const { data, meta } = action

      return {
        ...state,
        isLoading: false,
        opSwList: data,
        page: meta.pagination.page,
        pageCount: meta.pagination.pageCount,
        pageSize: meta.pagination.pageSize,
        total: meta.pagination.total,
        state: 'GET_OP_SW_LIST.SUCCESS',
      }
    }
    case GET_OP_SW_LIST.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_OP_SW_LIST.FAILURE',
      }
    }
    case GET_PROJECT_FOR_ACTION_PLAN.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_PROJECT_FOR_ACTION_PLAN.REQUEST',
      }
    }
    case GET_PROJECT_FOR_ACTION_PLAN.SUCCESS: {
      const { data, meta } = action

      return {
        ...state,
        isLoading: false,
        projects: data,
        page: meta.pagination.page,
        pageCount: meta.pagination.pageCount,
        pageSize: meta.pagination.pageSize,
        total: meta.pagination.total,
        state: 'GET_PROJECT_FOR_ACTION_PLAN.SUCCESS',
      }
    }
    case GET_PROJECT_FOR_ACTION_PLAN.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_PROJECT_FOR_ACTION_PLAN.FAILURE',
      }
    }
    case GET_TASK_FOR_ACTION_PLAN.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_TASK_FOR_ACTION_PLAN.REQUEST',
      }
    }
    case GET_TASK_FOR_ACTION_PLAN.SUCCESS: {
      const { data, meta } = action

      return {
        ...state,
        isLoading: false,
        taskList: data,
        page: meta.pagination.page,
        pageCount: meta.pagination.pageCount,
        pageSize: meta.pagination.pageSize,
        total: meta.pagination.total,
        state: 'GET_TASK_FOR_ACTION_PLAN.SUCCESS',
      }
    }
    case GET_TASK_FOR_ACTION_PLAN.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_TASK_FOR_ACTION_PLAN.FAILURE',
      }
    }
    case GET_TOTAL_PROJECT_TASK.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_TOTAL_PROJECT_TASK.REQUEST',
      }
    }
    case GET_TOTAL_PROJECT_TASK.SUCCESS: {
      const { data } = action
      return {
        ...state,
        totolProject: data,
        isLoading: false,
        state: 'GET_TOTAL_PROJECT_TASK.SUCCESS',
      }
    }
    case GET_TOTAL_PROJECT_TASK.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_TOTAL_PROJECT_TASK.FAILURE',
      }
    }
    case GET_TOTAL_TASK.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_TOTAL_TASK.REQUEST',
      }
    }
    case GET_TOTAL_TASK.SUCCESS: {
      const { data } = action
      return {
        ...state,
        totalTask: data,
        isLoading: false,
        state: 'GET_TOTAL_TASK.SUCCESS',
      }
    }
    case GET_TOTAL_TASK.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_TOTAL_TASK.FAILURE',
      }
    }
    case GET_OP_COUNT.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_OP_COUNT.REQUEST',
      }
    }
    case GET_OP_COUNT.SUCCESS: {
      const { data } = action
      return {
        ...state,
        totalOP: data,
        isLoading: false,
        state: 'GET_OP_COUNT.SUCCESS',
      }
    }
    case GET_OP_COUNT.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_OP_COUNT.FAILURE',
      }
    }
    default:
      return state
  }
}

export default combineReducers({
  actionPlanReducer,
})
