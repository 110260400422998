import ComparisonScheduleDocument from 'pages/document/purchases/documents/ComparisonScheduleDocument'
import PurchaseRequestDocument from 'pages/document/purchases/documents/PurchaseRequestDocument'
import DebitNoteDocument from 'pages/document/sales/documents/DebitNoteDocument'
import { useState } from 'react'
import styled from 'styled-components'
import ChequePaymentJournalDocument from '../../../pages/document/purchases/documents/ChequePaymentJournalDocument'
import PaymentJournalDocument from '../../../pages/document/purchases/documents/PaymentJournalDocument'
import PettyCashVoucherDocument from '../../../pages/document/purchases/documents/PettyCashVoucherDocument'
import PurchaseDocument from '../../../pages/document/purchases/documents/PurchaseDocument'
import BillingDocument from '../../../pages/document/sales/documents/BillingDocument'
import InvoiceDocument from '../../../pages/document/sales/documents/InvoiceDocument'
import QuotationDocument from '../../../pages/document/sales/documents/QuotationDocument'
import ReceiptDocument from '../../../pages/document/sales/documents/ReceiptDocument'

export default function DocumentSettingPreview({ docType, settingData, imageUrlObj, condition }) {
  const [type, setType] = useState('document_setting')

  return (
    <Styled>
      {/* SALES */}
      {docType == 'quotation' && (
        <QuotationDocument docType={docType} settingData={settingData} type={type} linkImage={imageUrlObj} />
      )}
      {docType == 'billing' && (
        <BillingDocument docType={docType} settingData={settingData} type={type} linkImage={imageUrlObj} />
      )}
      {docType == 'invoice' && (
        <InvoiceDocument docType={docType} settingData={settingData} type={type} linkImage={imageUrlObj} />
      )}
      {docType == 'receipt' && (
        <ReceiptDocument docType={docType} settingData={settingData} type={type} linkImage={imageUrlObj} />
      )}
      {docType == 'debit_note' && (
        <DebitNoteDocument docType={docType} settingData={settingData} type={type} linkImage={imageUrlObj} />
      )}

      {/* PURCHASE */}
      {docType == 'purchase_order' && (
        <PurchaseDocument
          docType={docType}
          settingData={settingData}
          type={type}
          linkImage={imageUrlObj}
          textCondition={condition}
        />
      )}
      {docType == 'cheque_payment_journal' && (
        <ChequePaymentJournalDocument
          docType={docType}
          settingData={settingData}
          type={type}
          linkImage={imageUrlObj}
          textCondition={condition}
        />
      )}
      {docType == 'payment_journal' && (
        <PaymentJournalDocument
          docType={docType}
          settingData={settingData}
          type={type}
          linkImage={imageUrlObj}
          textCondition={condition}
        />
      )}
      {docType == 'petty_cash_voucher' && (
        <PettyCashVoucherDocument
          docType={docType}
          settingData={settingData}
          type={type}
          linkImage={imageUrlObj}
          textCondition={condition}
        />
      )}
      {docType == 'purchase_request' && (
        <PurchaseRequestDocument
          docType={docType}
          settingData={settingData}
          type={type}
          linkImage={imageUrlObj}
          textCondition={condition}
        />
      )}
      {docType == 'comparison_schedule' && (
        <ComparisonScheduleDocument
          docType={docType}
          settingData={settingData}
          type={type}
          linkImage={imageUrlObj}
          textCondition={condition}
        />
      )}
    </Styled>
  )
}
const Styled = styled.div`
  width: 100%;
`
