import { getAPI, postAPI, putAPI } from '../../utils/api'
import { getAllLocalAdmin } from '../../utils/common'
import {
  GET_EMPLOYEE_PROFILE_INFO,
  REQUEST_EDIT_EMPLOYEE_PROFILE_INFO,
  EDIT_EMPLOYEE_PROFILE_INFO,
  GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE,
  GET_REQUEST_EDIT_LOGS,
  GET_REQUEST_EDIT_LOG_BY_ID,
  APPROVE_REQUEST_EDIT,
  GET_ARCHIVED_REQUEST,
  GET_EMPLOYEE_BY_ID,
  CHANGE_PASSWORD,
  VERIFY_PASSWORD,
  UPLOAD_EMPLOYEE_SIGNATURE,
  GET_EMPLOYEE_SIGNATURE,
  UPLOAD_SIGNATURE_IMAGE,
  CLEAR_STATE_EMPLOYEE_PROFILE,
  CLEAR_RESPONSE_UPLOAD_SIGNATURE,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

import dayjs from 'dayjs'

export const getEmployeeProfileInfo = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_EMPLOYEE_PROFILE_INFO.REQUEST })

    const { jwt, employee_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-type': 'application/json',
    }

    const params = {
      'populate[department][fields][0]': 'name',
      'populate[position][fields][0]': 'name',
      'populate[thai_province][fields][0]': 'name_th',
      'populate[thai_amphure][fields][0]': 'name_th',
      'populate[thai_tambon][fields][0]': 'name_th',
      'populate[nationality][fields][0]': 'name',
      'populate[bank][fields][0]': 'nam_th',
      'populate[bank][fields][1]': 'name_en',
      'populate[employee_resumes][fields][0]': 'file_id',
      'fields[0]': 'employ_id',
      'fields[1]': 'title',
      'fields[2]': 'first_name',
      'fields[3]': 'last_name',
      'fields[4]': 'first_name_th',
      'fields[5]': 'last_name_th',
      'fields[6]': 'address',
      'fields[7]': 'birth_date',
      'fields[8]': 'identification_no',
      'fields[9]': 'passport_no',
      'fields[10]': 'visa_expire_date',
      'fields[11]': 'phone_no',
      'fields[12]': 'email',
      'fields[13]': 'emergency_contact',
      'fields[14]': 'emergency_phone_no',
      'fields[15]': 'martial_status',
      'fields[16]': 'number_of_children',
      'fields[17]': 'bank_account_no',
      'fields[18]': 'visa_no',
      'fields[19]': 'visa_expire_date',
      'fields[20]': 'work_permit_no',
      'fields[21]': 'workpermit_file_id',
      'fields[22]': 'workpermit_expire_date',
      'fields[23]': 'photo',
      'fields[24]': 'title_th',
    }

    const { data } = await getAPI({
      url: `/api/employee-profile/employee/${employee_info.id}`,
      headers,
      params,
    })

    return dispatch({
      type: GET_EMPLOYEE_PROFILE_INFO.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_EMPLOYEE_PROFILE_INFO.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Employee Profile Info failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getEmployeeByIDForProfile = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_EMPLOYEE_BY_ID.REQUEST })

    const { jwt, employee_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/employees/${employee_info.id}`,
      headers,
    })

    return dispatch({
      type: GET_EMPLOYEE_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_EMPLOYEE_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Employee Profile Info failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const requestEditEmployeeProfileInfo = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: REQUEST_EDIT_EMPLOYEE_PROFILE_INFO.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: `/api/request-profile-headers`,
      headers: headers,
      data,
    })

    return dispatch({
      type: REQUEST_EDIT_EMPLOYEE_PROFILE_INFO.SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: REQUEST_EDIT_EMPLOYEE_PROFILE_INFO.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Request edit employee info failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const editEmployeeProfileInfo = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_EMPLOYEE_PROFILE_INFO.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/employees/${id}`,
      headers: headers,
      data,
    })

    return dispatch({
      type: EDIT_EMPLOYEE_PROFILE_INFO.SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: EDIT_EMPLOYEE_PROFILE_INFO.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Request edit employee info failed!',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getEmployeeProfileInfo())
  }
}

export const getEditRequestLogsByEmployee =
  (filter = { page: 1, pageSize: 5 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE.REQUEST })
      const { jwt, employee_info } = getAllLocalAdmin()
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      const params = {
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'populate[profile_header][fields][0]': 'submit_date',
        'filters[profile_header][employee_requestted][id]': employee_info.id,
        'fields[0]': 'field_name',
        'fields[1]': 'field_value',
        'fields[2]': 'status',
        'fields[3]': 'remark',
        'fields[4]': 'action_date',
        'sort[createdAt]': 'desc',
      }

      const { data, meta } = await getAPI({
        url: '/api/request-profile-details',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_REQUEST_EDIT_LOGS_BY_EMPLOYEE.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Request Edit Logs failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getEditRequestLogs =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_REQUEST_EDIT_LOGS.REQUEST })
      const { jwt } = getAllLocalAdmin()
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'fields[0]': 'submit_date',
        'fields[1]': 'request_no',
        'fields[2]': 'approve_date',
        'fields[3]': 'createdAt',
        'populate[employee_requestted][fields][0]': 'first_name',
        'populate[employee_requestted][fields][1]': 'last_name',
        'populate[employee_requestted][fields][2]': 'title',
        'filters[approve_date][$null]': true,
        'sort[createdAt]': 'desc',
      }
      if (filter.submit_date !== '') {
        params['filters[submit_date][$eq]'] = filter.submit_date
      }

      const { data, meta } = await getAPI({
        url: '/api/request-profile-headers',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_REQUEST_EDIT_LOGS.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_REQUEST_EDIT_LOGS.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Request Edit Logs failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getRequestEditLogById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_REQUEST_EDIT_LOG_BY_ID.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'populate[profile_details][fields][0]': 'field_name',
      'populate[profile_details][fields][1]': 'field_value',
      'populate[profile_details][fields][2]': 'status',
      'populate[profile_details][fields][3]': 'remark',
      'populate[profile_details][fields][4]': 'o_value',
      'populate[approver][fields][0]': 'first_name',
      'populate[approver][fields][1]': 'last_name',
      'populate[attach_file]': '*',
      'populate[employee_requestted][fields][0]': 'first_name',
      'populate[employee_requestted][fields][1]': 'last_name',
    }

    const { data } = await getAPI({
      url: `/api/request-profile-headers/${id}`,
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_REQUEST_EDIT_LOG_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_REQUEST_EDIT_LOG_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Request Edit Log By Id failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const approveRequestEdit = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: APPROVE_REQUEST_EDIT.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/request-profile-headers/${id}`,
      headers: headers,
      data,
    })

    return dispatch({
      type: APPROVE_REQUEST_EDIT.SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: APPROVE_REQUEST_EDIT.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Approve request edit failed!',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getEditRequestLogs())
  }
}

export const getArchivedRequest =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_ARCHIVED_REQUEST.REQUEST })
      const { jwt } = getAllLocalAdmin()
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'fields[0]': 'submit_date',
        'fields[1]': 'request_no',
        'fields[2]': 'approve_date',
        'fields[3]': 'createdAt',
        'populate[employee_requestted][fields][0]': 'first_name',
        'populate[employee_requestted][fields][1]': 'last_name',
        'populate[employee_requestted][fields][2]': 'title',
        'populate[approver][fields][0]': 'first_name',
        'populate[approver][fields][1]': 'last_name',
        'populate[approver][fields][2]': 'photo',
        'filters[approve_date][$null]': false,
        'sort[createdAt]': 'desc',
      }
      if (filter.submit_date !== '') {
        params['filters[submit_date][$eq]'] = filter.submit_date
      }
      if (filter.review_date !== '') {
        const date = dayjs(filter.review_date)
        const newDate = date.add(1, 'day').format('YYYY-MM-DD')
        params['filters[$or][0][$and][0][approve_date][$gte]'] = filter.review_date
        params['filters[$or][0][$and][1][approve_date][$lt]'] = newDate
      }

      const { data, meta } = await getAPI({
        url: '/api/request-profile-headers',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_ARCHIVED_REQUEST.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_ARCHIVED_REQUEST.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Archived Request Logs failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const changePassword = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CHANGE_PASSWORD.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: `/api/mapping-employee-user-resetpassword`,
      headers: headers,
      data,
    })

    return dispatch({
      type: CHANGE_PASSWORD.SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: CHANGE_PASSWORD.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Change Password failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}
export const resetNewPassword = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CHANGE_PASSWORD.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: `/api/mapping-employee-user-setNewpassword`,
      headers: headers,
      data,
    })

    return dispatch({
      type: CHANGE_PASSWORD.SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: CHANGE_PASSWORD.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Change Password failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}
export const verifyPassword = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: VERIFY_PASSWORD.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const response = await postAPI({
      url: `/api/mapping-employee-user-verify-pwd`,
      headers: headers,
      data,
    })

    return dispatch({
      type: VERIFY_PASSWORD.SUCCESS,
      response: response.data,
    })
  } catch (error) {
    return dispatch({
      type: VERIFY_PASSWORD.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Verify Password failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getEmployeeSignature = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_EMPLOYEE_SIGNATURE.REQUEST })
    const { jwt, employee_info } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const params = {
      'filters[employee][id]': employee_info?.id,
      'populate[signature][fields][0]': 'url',
      'sort[0]': 'id:desc',
    }

    const { data } = await getAPI({
      url: `/api/employee-signatures`,
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_EMPLOYEE_SIGNATURE.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_EMPLOYEE_SIGNATURE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Employee Signature failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const uploadSignature = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPLOAD_SIGNATURE_IMAGE.REQUEST })
    const { jwt, employee_info } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'multipart/form-data',
    }

    const response = await postAPI({
      url: `/api/upload`,
      headers: headers,
      data,
    })

    const request = {
      employee: employee_info?.id,
      signature: response[0]?.id,
    }

    dispatch(uploadEmployeeSignature(request))

    return dispatch({
      type: UPLOAD_SIGNATURE_IMAGE.SUCCESS,
    })
  } catch (error) {
    return dispatch({
      type: UPLOAD_SIGNATURE_IMAGE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Upload signature failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const uploadEmployeeSignature = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPLOAD_EMPLOYEE_SIGNATURE.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const response = await postAPI({
      url: `/api/employee-signatures`,
      headers: headers,
      data,
    })

    console.log('response', response)

    return dispatch({
      type: UPLOAD_EMPLOYEE_SIGNATURE.SUCCESS,
      response,
    })
  } catch (error) {
    return dispatch({
      type: UPLOAD_EMPLOYEE_SIGNATURE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Upload Employee Signature failed!',
    })
  } finally {
    dispatch(hideLoading())
    dispatch(getEmployeeSignature())
  }
}

export const uploadSignatureForForm = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPLOAD_SIGNATURE_IMAGE.REQUEST })
    const { jwt } = getAllLocalAdmin()
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'multipart/form-data',
    }

    const response = await postAPI({
      url: `/api/upload`,
      headers: headers,
      data,
    })

    return dispatch({
      type: UPLOAD_SIGNATURE_IMAGE.SUCCESS,
      response,
    })
  } catch (error) {
    return dispatch({
      type: UPLOAD_SIGNATURE_IMAGE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Upload signature failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const clearStateEmployeeProfile = () => async (dispatch) => {
  return dispatch({
    type: CLEAR_STATE_EMPLOYEE_PROFILE,
  })
}

export const clearResponseUploadSignature = () => async (dispatch) => {
  return dispatch({
    type: CLEAR_RESPONSE_UPLOAD_SIGNATURE,
  })
}
