import styled from 'styled-components'

import Modal from '../../pages/my-calendar/Modal'
import Button from '../../components/form/button/Button'
import IconButton from '../../components/common/IconButton'

import iconClose from '../../assets/images/icon-close.svg'

import { GiCancel } from 'react-icons/gi'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import EditNoteIcon from '@mui/icons-material/EditNote'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';


import { useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import iconPlus from '../../assets/images/icon-plus.svg'

const Div = styled.div`
  position: relative;
  /*padding: 24px;*/
  width: 100%;
  width: 400px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  border-radius: 8px;
  z-index: 2000;

  .header-content {
    /*
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    */
    height: 56px;
    background-color: #EAECF0;
    padding-left: 10px;
    padding-right: 10px;
    color: #000000;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 12px;
    .css_header_detail {
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      font-size: 12px;
      .title{
        font-weight: bold;
      }
      .date_css{
        color: #7F56D9;
        font-weight: bold;
      }
    }
  }

  .action_icon{
    display: flex;
    align-items: center;
  }
  .Header-line{
    background-color: #FFFFFF;
    height: 16px;
    margin-right: 8px;
    width: 1px;
  }
  .icon_css {
    height: 16px;
    width: 16px;
    cursor: pointer;
    
  }
  .content{
    /*padding: 10px;*/
    background-color: #FFFFFF;
    /*
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    */
   .sub-content{
    border-bottom: 1px solid #EAECF0;
    padding: 10px;
    .div_sub{
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      font-size: 12px;
      .sub_title{
        color: #344054;
        font-weight: bold;
      }
      .sub_detail{
        color: #475467;
      }
    }
   }
  }
  .h_topic{
    color: #7F56D9;
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .detail{
    color: #475467;
    font-size: 14px;
  }
  .date_topic{
    color: #7F56D9;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .time_topic{
    color:#475467;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .footer_topic{
    /*
    color: rgb(71, 84, 103);
    font-size: 14px;
    height: 44px;
    display: flex;
    align-items: center;
    /*border-top: 1px solid #EAECF0;*/
    background-color: #FFFFFF;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    
    color: rgb(71, 84, 103);
    font-size: 14px;
    height: 60px;
    border-top: 1px solid rgb(234, 236, 240);
    background-color: rgb(255, 255, 255);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 10px;
    text-align: center;
  }
  .css_view_detail{
    color: #7F56D9;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
  }
  .mg_right_10 {
    margin-right: 10px;
  }
  .mg_right_8 {
    margin-right: 10px;
  }
  .css_button_book{
    /*
    width : 100%;
    display: block;
    */
    font-size: 12px;
  }


`

const ModalMeetingCalendar = ({ OpenModal, onClose, onBook, DataObj }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  
  return (
    <Modal open={OpenModal} onClose={onClose} xxoffset={DataObj.localX} yyoffset={DataObj.localY} disableBackdropClick>
      <Div>
        <div className="header-content">
          <div className="css_header_detail">
            <div className="title">{t('meeting_space_booking_schedule')}</div>
            <div className="action_icon">
                <GiCancel size={30} onClick={onClose} className="icon_css" />
            </div>
          </div>
          <div className="css_header_detail">
            <div className="date_css">{t('all_room')}</div>
            <div className="date_css">{dayjs(DataObj.this_date).format('D MMM YYYY')}</div>
          </div>
        </div>
        <div className="content">
            {DataObj.arrdata?.map((item, index) => (
                <div className="sub-content" key={index}>
                    <div className="div_sub">
                        <div className="sub_title">{item.title}</div>
                    </div>
                    <div className="div_sub">
                      <div className="sub_detail">{item.meeting_room_name}</div>
                      <div className="sub_detail">{dayjs(item.startDate).format('HH.mm')} - {dayjs(item.endDate).format('HH.mm')}</div>
                    </div>                    
                </div>
            ))}
        </div>
        <div className="footer_topic"> 
            <Button append={<img src={iconPlus} />} onClick={() => onBook(DataObj.this_date)} className="css_button_book">
                {t('book_meeting_space')}
            </Button>       
        </div>
      </Div>
    </Modal>
  )
}

export default ModalMeetingCalendar
