import { combineReducers } from 'redux'

import {
  GET_COCK_PIT,
  GET_LOG_HISTORICAL_TASK,
  GET_LOG_LATEST_TASK,
  GET_ZONE_OFM,
  SET_COCK_PIT_DATA,
  SET_LOG_LATEST_TASK,
} from '../actionTypes'

const initialState = {
  cockPitData: [],
  zoneOFM: [],
  historicalLogTask: [],
  latestLogTask: {},
  page: 1,
  pageCount: 1,
  total: 1,
  limit: 10,
  isLoading: false,
  state: '',
}

const ofmDashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_COCK_PIT.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_COCK_PIT.REQUEST',
      }
    case GET_COCK_PIT.SUCCESS: {
      const { data } = action
      return {
        ...state,
        cockPitData: data,
        isLoading: false,
        state: 'GET_COCK_PIT.SUCCESS',
      }
    }
    case GET_COCK_PIT.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_COCK_PIT.FAILURE',
      }
    case GET_ZONE_OFM.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_ZONE_OFM.REQUEST',
      }
    case GET_ZONE_OFM.SUCCESS: {
      const { data } = action
      return {
        ...state,
        zoneOFM: data,
        isLoading: false,
        state: 'GET_ZONE_OFM.SUCCESS',
      }
    }
    case GET_ZONE_OFM.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_ZONE_OFM.FAILURE',
      }
    case GET_LOG_HISTORICAL_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_LOG_HISTORICAL_TASK.REQUEST',
      }
    case GET_LOG_HISTORICAL_TASK.SUCCESS: {
      const { data } = action
      return {
        ...state,
        historicalLogTask: data,
        isLoading: false,
        state: 'GET_LOG_HISTORICAL_TASK.SUCCESS',
      }
    }
    case GET_LOG_HISTORICAL_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_LOG_HISTORICAL_TASK.FAILURE',
      }
    case GET_LOG_LATEST_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_LOG_LATEST_TASK.REQUEST',
      }
    case GET_LOG_LATEST_TASK.SUCCESS: {
      const { data } = action
      return {
        ...state,
        latestLogTask: data,
        isLoading: false,
        state: 'GET_LOG_LATEST_TASK.SUCCESS',
      }
    }
    case GET_LOG_LATEST_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_LOG_LATEST_TASK.FAILURE',
      }
    case SET_COCK_PIT_DATA: {
      const { data } = action
      return {
        ...state,
        cockPitData: data,
        isLoading: false,
        state: 'SET_COCK_PIT_DATA',
      }
    }
    case SET_LOG_LATEST_TASK: {
      const { data } = action
      return {
        ...state,
        latestLogTask: data,
        isLoading: false,
        state: 'SET_LOG_LATEST_TASK',
      }
    }
    default:
      return state
  }
}
export default combineReducers({
  ofmDashboardReducer,
})
