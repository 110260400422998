import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { TbPointFilled } from 'react-icons/tb'
import { BiTrash } from 'react-icons/bi'
import { LuPen } from 'react-icons/lu'
import { LiaTimesSolid } from 'react-icons/lia'
import userIcon from '../../assets/images/user-icon.png'
import successIcon from '../../assets/images/success-icon.png'

import styled from 'styled-components'
import SearchText from '../../components/form/SearchText'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import Table from '../../components/common/Table'
import Button from '../../components/form/button/Button'
import SidePanel from '../../components/common/SidePanel'
import TextField from '../../components/form/TextField'
import DialogConfirm from '../../components/dialog/DialogConfirm'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import DialogSuccess from '../../components/dialog/DialogSuccess'

import { selectOrgTeam } from '../../redux/selectors'
import {
  createGroupTeam,
  editGroupTeam,
  deleteGroupTeam,
  getGroupTeam,
  getGroupTeamById,
} from '../../redux/actions/OrganizationTeam'

// container style
const Wrapper = styled.div`
  .mb-6-px {
    margin-bottom: 6px;
  }
  .mb-12-px {
    margin-bottom: 12px;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }

  .icon {
    cursor: pointer;
  }
  .name {
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.align-items-center {
    align-items: center;
  }

  .w-50 {
    width: 50%;
  }
`
const FilterContainer = styled.div`
  border-radius: 12px;
  background: var(--Gray-50);
  padding: 12px 16px;

  .heading-search {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .item-vertical {
    vertical-align: middle;
  }

  .green-outline-btn {
    border-radius: 8px;
    border: 1px solid var(--success-200);
    background: var(--success-50);
    color: var(--success-700);
  }
`
const TableWrapper = styled.div`
  .table-headers {
    padding: 1rem 0.875rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: var(--Base-White);
    border: 0.0625rem solid var(--Gray-200);

    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .table-header-name {
        color: var(--Gray-900);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        margin-right: 0.5rem;
      }

      .badge {
        border-radius: 16px;
        border: 1px solid var(--Doing-Badge-Stroke);

        color: var(--Doing-Badge-Font);
        text-align: center;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;

        display: inline-block;
        padding: 2px 8px;
      }
    }
  }
`
const BadgeSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .badge {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`
const SidePanelContainer = styled.div`
  padding: 24px;
  /* height: 100vh; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: var(--Gray-500);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  .highlight-text {
    color: var(--Primary-700);
  }

  .mb-5-px {
    margin-bottom: 5px;
  }
  .mb-6-px {
    margin-bottom: 6px;
  }
  .mb-12-px {
    margin-bottom: 12px;
  }
  .mb-14-px {
    margin-bottom: 14px;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .mr-20-px {
    margin-right: 20px;
  }

  .icon {
    cursor: pointer;
  }

  .heading {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .heading-2 {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .heading-3 {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`
const InputContainer = styled.div`
  .heading-input {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .w-100 {
    width: 100%;
  }
`
// component style
const BadgeStatus = styled.div`
  border-radius: 16px;

  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;

  display: inline-block;
  padding: 2px 8px;

  &.green-badge {
    border: 1px solid var(--BADGE-4-Border);
    background: var(--BADGE-4-Bg);
    color: var(--BADGE-4-Font);
  }
  &.red-badge {
    border: 1px solid var(--BADGE-2-Border);
    background: var(--BADGE-2-Bg);
    color: var(--BADGE-2-Font);
  }

  .item-vertical {
    vertical-align: middle;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`

const groupTeamColumn = [
  {
    Header: 'No',
    accessor: 'no',
    disableSortBy: false,
  },
  {
    Header: 'Group Name',
    accessor: 'name',
    disableSortBy: false,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: false,
  },
  {
    accessor: 'action',
    disableSortBy: false,
  },
]

const initialDialogText = {
  heading: '',
  desc: '',
}
const initialForm = {
  name: '',
}
function OrganizationGroup() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { groupList, groupInfo, limit, page, pageCount, total, isLoading, state } = useSelector(selectOrgTeam)
  const [groupTeams, setGroupTeams] = useState([])
  const [groupId, setGroupId] = useState('')
  // form
  const [formGroupTeam, setFormGroupTeam] = useState(initialForm)
  const [formEditGroupTeam, setFormEditGroupTeam] = useState(initialForm)
  // filter
  const [searchFilters, setSearchFilters] = useState([])
  const [search, setSearch] = useState('')
  // side panel
  const [isOpenCreatePanel, setIsOpenCreatePanel] = useState(false)
  const [isOpenEditPanel, setIsOpenEditPanel] = useState(false)
  const [isOpenCheckMemberAssignmentPanel, setIsOpenCheckMemberAssignmentPanel] = useState(false)
  // dialog
  const [isOpenConfirmCreateDialog, setIsOpenConfirmCreateDialog] = useState(false)
  const [isOpenConfirmEditDialog, setIsOpenConfirmEditDialog] = useState(false)
  const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false)
  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false)
  // dialog text
  const [dialogText, setDialogText] = useState(initialDialogText)

  const fetchGroupTeamData = useCallback(
    (filter) => {
      dispatch(getGroupTeam(filter))
    },
    [dispatch]
  )
  const openEditSidePanel = useCallback(
    (id) => {
      dispatch(getGroupTeamById(id))
      setIsOpenEditPanel(true)
    },
    [dispatch]
  )
  const deleteGroup = useCallback(
    (id) => {
      setGroupId(id)
      setDialogText({
        heading: 'Confirm delete this Group Team?',
        desc: '',
      })
      setIsOpenConfirmDeleteDialog(true)
    },
    [dispatch]
  )

  useEffect(() => {
    fetchGroupTeamData()
  }, [])

  useEffect(() => {
    if (isLoading === false && state === 'CREATE_GROUP_TEAM.SUCCESS') {
      setDialogText({
        heading: 'Create Group Success',
        desc: '',
      })
      setIsOpenSuccessDialog(true)
    }
    if (isLoading === false && state === 'EDIT_GROUP_TEAM.SUCCESS') {
      setDialogText({
        heading: 'Edit Group Name Success',
        desc: '',
      })
      setIsOpenSuccessDialog(true)
    }
  }, [isLoading, state])

  useEffect(() => {
    const initilaGroupList = groupList.map((v, i) => ({
      ...v,
      no: i + 1,
      name: (
        <div className="name" onClick={() => gotoTeamDetail(v.id)}>
          {v.name}
        </div>
      ),
      status: (
        <BadgeStatus className={`${v.status === 'active' ? 'green-badge' : 'red-badge'}`}>
          <TbPointFilled className="item-vertical" />
          <span className="item-vertical"> {v.status}</span>
        </BadgeStatus>
      ),
      action: (
        <div>
          <BiTrash className="mr-1-rem icon" size={18} onClick={() => deleteGroup(v.id)} />
          <LuPen className="icon" size={18} onClick={() => openEditSidePanel(v.id)} />
        </div>
      ),
    }))
    setGroupTeams(initilaGroupList)
  }, [groupList])

  useEffect(() => {
    setFormEditGroupTeam((prev) => ({
      ...prev,
      name: groupInfo.name,
    }))
  }, [groupInfo])

  // navigate
  const gotoTeamDetail = (id) => {
    navigate(`team-detail/${id}`)
  }

  // sidepanel
  const openCreateSidePanel = () => {
    setIsOpenCreatePanel(true)
  }
  const closeCreateSidePanel = () => {
    setIsOpenCreatePanel(false)
  }
  const createGroup = () => {
    setDialogText({
      heading: 'Confirm create Group?',
      desc: '',
    })
    setIsOpenConfirmCreateDialog(true)
  }
  const closeEditSidePanel = () => {
    setIsOpenEditPanel(false)
  }
  const editGroup = () => {
    setDialogText({
      heading: 'Confirm Edit Group Name?',
      desc: '',
    })
    setIsOpenConfirmEditDialog(true)
  }
  const openCheckMemberAssignment = () => {
    setIsOpenCheckMemberAssignmentPanel(true)
  }

  // submit
  const onSubmitCreate = () => {
    const request = { ...formGroupTeam }
    dispatch(createGroupTeam(request))
    setIsOpenCreatePanel(false)
    setIsOpenConfirmCreateDialog(false)
    setFormGroupTeam(initialForm)
  }
  const onSubmitEdit = () => {
    const request = { ...formEditGroupTeam }
    dispatch(editGroupTeam(groupInfo.id, request))
    setIsOpenEditPanel(false)
    setIsOpenConfirmEditDialog(false)
    setFormEditGroupTeam(initialForm)
  }
  const onSubmitDelete = () => {
    dispatch(deleteGroupTeam(groupId))
    setIsOpenConfirmDeleteDialog(false)
  }

  // filter
  const onChangePagination = async (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    const filter = {
      page: value.page,
      pageSize: limit,
      multipleSearch,
    }
    fetchGroupTeamData(filter)
  }
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))
      const filter = {
        page: 1,
        pageSize: limit,
        multipleSearch,
      }
      fetchGroupTeamData(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    const filter = {
      page: 1,
      pageSize: limit,
      multipleSearch,
    }
    fetchGroupTeamData(filter)
    setSearch('')
  }
  const onClearSearch = () => {
    const filter = {
      page: 1,
      pageSize: limit,
      multipleSearch: [],
    }
    fetchGroupTeamData(filter)
    clearData()
  }
  const clearData = () => {
    setSearch('')
    setSearchFilters([])
  }

  // onchange
  const onChangeForm = (event) => {
    const { name, value } = event.target
    setFormGroupTeam((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeEditForm = (event) => {
    const { name, value } = event.target
    setFormEditGroupTeam((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <Wrapper>
      <FilterContainer className="mb-1-rem">
        <div className="heading-search mb-6-px">Search</div>
        <FlexContainer className="justify-content-space-between mb-6-px">
          <div>
            <SearchText
              className="item-vertical mr-1-rem"
              placeholder="Search Title / Attendee"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSubmit={onSearch}
              onClear={() => setSearch('')}
            />
            <ButtonOutline className="item-vertical" onClick={onClearSearch}>
              {t('clear_all')}
            </ButtonOutline>
          </div>
          <ButtonOutline className="green-outline-btn  item-vertical" onClick={openCheckMemberAssignment}>
            Check member assignment
          </ButtonOutline>
        </FlexContainer>
        <BadgeSearchContainer>
          {searchFilters.map((v) => (
            <div className="badge" key={v.key}>
              <span className="name">{v.name}</span>{' '}
              <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
            </div>
          ))}
        </BadgeSearchContainer>
      </FilterContainer>

      <TableWrapper>
        <div className="table-headers">
          <div className="header-container">
            <div>
              <span className="table-header-name">Group</span>
              <span className="badge">{total} group</span>
            </div>
            <Button onClick={openCreateSidePanel}>New Group</Button>
          </div>
        </div>
        <Table
          columns={groupTeamColumn}
          data={groupTeams}
          page={page}
          pageCount={pageCount}
          onStateChange={onChangePagination}
        />
      </TableWrapper>
      {/* side panel */}
      <SidePanel isOpen={isOpenCreatePanel} setIsOpen={setIsOpenCreatePanel} width={400}>
        <SidePanelContainer>
          <div>
            <FlexContainer className="justify-content-space-between align-items-center mb-12-px">
              <div className="heading">Create Group</div>
              <LiaTimesSolid className="icon" onClick={() => setIsOpenCreatePanel(!isOpenCreatePanel)} />
            </FlexContainer>
            <Divider className="mb-12-px" />

            <InputContainer>
              <div className="heading-input mb-6-px">Group Name *</div>
              <TextField className="w-100" name="name" value={formGroupTeam.name} onChange={onChangeForm} />
            </InputContainer>
          </div>

          <div>
            <Divider className="mb-12-px" />
            <FlexContainer className="justify-content-end">
              <ButtonOutline className="mr-20-px" onClick={closeCreateSidePanel}>
                {t('cancel')}
              </ButtonOutline>
              <Button onClick={createGroup}>{t('create')}</Button>
            </FlexContainer>
          </div>
        </SidePanelContainer>
      </SidePanel>
      <SidePanel isOpen={isOpenEditPanel} setIsOpen={setIsOpenEditPanel} width={400}>
        <SidePanelContainer>
          <div>
            <FlexContainer className="justify-content-space-between align-items-center mb-12-px">
              <div className="heading">Edit Group</div>
              <LiaTimesSolid className="icon" onClick={() => setIsOpenEditPanel(!isOpenEditPanel)} />
            </FlexContainer>
            <Divider className="mb-12-px" />

            <InputContainer>
              <div className="heading-input mb-6-px">Group Name *</div>
              <TextField className="w-100" name="name" value={formEditGroupTeam.name} onChange={onChangeEditForm} />
            </InputContainer>
          </div>

          <div>
            <Divider className="mb-12-px" />
            <FlexContainer className="justify-content-end">
              <ButtonOutline className="mr-20-px" onClick={closeEditSidePanel}>
                {t('cancel')}
              </ButtonOutline>
              <Button onClick={editGroup}>{t('Edit')}</Button>
            </FlexContainer>
          </div>
        </SidePanelContainer>
      </SidePanel>
      <SidePanel isOpen={isOpenCheckMemberAssignmentPanel} setIsOpen={setIsOpenCheckMemberAssignmentPanel} width={400}>
        <SidePanelContainer>
          <div>
            <FlexContainer className="justify-content-space-between align-items-center mb-12-px">
              <div className="heading">Check member assignment</div>
              <LiaTimesSolid
                className="icon"
                onClick={() => setIsOpenCheckMemberAssignmentPanel(!isOpenCheckMemberAssignmentPanel)}
              />
            </FlexContainer>
            <Divider className="mb-12-px" />

            <InputContainer className="mb-1-rem">
              <div className="heading-input mb-6-px">Search</div>
              <SearchText className="w-100" />
            </InputContainer>
            <FlexContainer className="justify-content-end mb-1-rem">
              <Button>{t('search')}</Button>
            </FlexContainer>

            <section className="mb-1-rem">
              <div className="heading-2 mb-14-px">Result</div>
              <div>E0002673 นาย อดิศักดิ์ หวังพงษ์สวัสดิ์</div>
            </section>

            <Divider className="mb-12-px" />

            <section className="mb-24-px">
              <div className="heading-2 mb-14-px">Appointment Result</div>
              <div className="highlight-text">E0002673 นาย อดิศักดิ์ หวังพงษ์สวัสดิ์</div>
            </section>

            <section className="mb-1-rem">
              <div className="heading-3 mb-5-px">Team Name</div>
              <div>ผู้ตรวจประเมินมาตรฐานคุณภาพการศึกษาวิศวกรรมศาสตร์ (TABEE)</div>
            </section>
            <section className="mb-1-rem">
              <FlexContainer>
                <div className="w-50">
                  <div className="heading-3 mb-5-px">Discipline</div>
                  <div>โยธา</div>
                </div>
                <div className="w-50">
                  <div className="heading-3 mb-5-px">Position</div>
                  <div>ผู้ตรวจประเมิน</div>
                </div>
              </FlexContainer>
            </section>
            <section className="mb-1-rem">
              <FlexContainer>
                <div className="w-50">
                  <div className="heading-3 mb-5-px">Start Date</div>
                  <div>11/11/2565</div>
                </div>
                <div className="w-50">
                  <div className="heading-3 mb-5-px">Period</div>
                  <div>7</div>
                </div>
              </FlexContainer>
            </section>
            <section className="mb-1-rem">
              <FlexContainer>
                <div className="w-50">
                  <div className="heading-3 mb-5-px">Appointment Order</div>
                  <div>53/2565</div>
                </div>
                <div className="w-50">
                  <div className="heading-3 mb-5-px">Status</div>
                  <BadgeStatus className={`green-badge`}>
                    <TbPointFilled className="item-vertical" />
                    <span className="item-vertical">active</span>
                  </BadgeStatus>
                </div>
              </FlexContainer>
            </section>
            <Divider className="mb-12-px" />

            <section className="mb-1-rem">
              <div className="heading-3 mb-5-px">Team Name</div>
              <div>ผู้ตรวจประเมินมาตรฐานคุณภาพการศึกษาวิศวกรรมศาสตร์ (TABEE)</div>
            </section>
            <section className="mb-1-rem">
              <FlexContainer>
                <div className="w-50">
                  <div className="heading-3 mb-5-px">Discipline</div>
                  <div>โยธา</div>
                </div>
                <div className="w-50">
                  <div className="heading-3 mb-5-px">Position</div>
                  <div>ผู้ตรวจประเมิน</div>
                </div>
              </FlexContainer>
            </section>
            <section className="mb-1-rem">
              <FlexContainer>
                <div className="w-50">
                  <div className="heading-3 mb-5-px">Start Date</div>
                  <div>11/11/2565</div>
                </div>
                <div className="w-50">
                  <div className="heading-3 mb-5-px">Period</div>
                  <div>7</div>
                </div>
              </FlexContainer>
            </section>
            <section className="mb-1-rem">
              <FlexContainer>
                <div className="w-50">
                  <div className="heading-3 mb-5-px">Appointment Order</div>
                  <div>53/2565</div>
                </div>
                <div className="w-50">
                  <div className="heading-3 mb-5-px">Status</div>
                  <BadgeStatus className={`green-badge`}>
                    <TbPointFilled className="item-vertical" />
                    <span className="item-vertical">active</span>
                  </BadgeStatus>
                </div>
              </FlexContainer>
            </section>
          </div>
        </SidePanelContainer>
      </SidePanel>
      {/* dialog */}
      <DialogConfirm
        open={Boolean(isOpenConfirmCreateDialog)}
        onClose={() => setIsOpenConfirmCreateDialog(false)}
        onSubmit={onSubmitCreate}
        icon={userIcon}
        title={dialogText.heading}
        description={dialogText.desc}
        textYes={t('confirm')}
        textNo={t('Back')}
      />
      <DialogConfirm
        open={Boolean(isOpenConfirmEditDialog)}
        onClose={() => setIsOpenConfirmEditDialog(false)}
        onSubmit={onSubmitEdit}
        icon={userIcon}
        title={dialogText.heading}
        description={dialogText.desc}
        textYes={t('confirm')}
        textNo={t('Back')}
      />
      <DialogConfirmDelete
        open={Boolean(isOpenConfirmDeleteDialog)}
        onClose={() => setIsOpenConfirmDeleteDialog(false)}
        onSubmit={onSubmitDelete}
        title={dialogText.heading}
        description={dialogText.desc}
      />
      <DialogSuccess
        open={Boolean(isOpenSuccessDialog)}
        onClose={() => setIsOpenSuccessDialog(false)}
        onSubmit={() => setIsOpenSuccessDialog(false)}
        icon={successIcon}
        title={dialogText.heading}
        description={dialogText.desc}
        textYes={t('done')}
      />
    </Wrapper>
  )
}

export default OrganizationGroup
