import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useNavigate, NavLink } from 'react-router-dom'
import { DateTimeHHmmDisplay,getCompanyInfo,DateDisplay } from '../../utils/common'


import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { getZoneReturnData,getBaseByZoneIdReturnData,getTaskByBaseIdReturnData } from '../../redux/actions/ofm'
import dayjs from 'dayjs'

import TableV2 from '../../components/common/TableV2'
import iconQR from '../../assets/images/qr-code-01.png'
import iconPermission from '../../assets/images/user-add.png'

import ModalQR from '../../pages/operation-field/ModalQR'
import ModalPermission from './ModalPermission'
import SidePanel from '../../components/common/SidePanel'

const DivMain = styled.div`
color: var(--Gray-600);
font-size: 14px;
font-style: normal;
font-weight: 500;
.header {
    display: flex;
    align-items: center;

    .highlight {
        color: var(--Primary-700);
        font-weight: 600;
    }
}
.mb-2-rem {
    margin-bottom: 2rem;
}
.mr-8-px {
    margin-right: 8px;
}
.icon {
    cursor: pointer;
}
.content-title {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
}
.content-title-detail {
    font-size: 16px;
    font-weight: 400;
    color: #475467;
}
.border_header{
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
}
.wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        /*border-bottom: 0px solid #EAECF0;*/
        color: #667085;
        font-size: 14px;
        border-bottom: 2px solid rgb(234, 236, 240) !important;
    }

    .menu-active {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid var(--Primary-600) !important;
        color: #6941C6;
        background-color: #F9F5FF;
    }
}

.css_half{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
}
.zone_box{
    border: 1px solid rgb(208, 213, 221);
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 18px;
    margin-bottom: 24px;
    font-size: 16px;
    color: #344054;
    background-color: #FFFFFF;
    min-height: 46px;
    :hover {
        color: #6941C6;
        background-color: #F9F5FF;
        border: 1px solid #E9D7FE;
    }
}
.head_select{
    font-size: 18px;
    font-weight: 600;
    color: var(--Gray-900);
    margin-bottom: 15px;
}
.content-zone-name{
    font-size: 20px;
    font-weight: bold;
    color: #344054;
}
.table-data{
    margin : 24px 0px;
}
.col-SetPermission{
    width : 10%
}
.col-Base{
    width : 25%
}
.col-Task{
    width : 45%
}
.col-GetQRCode{
    width : 10%;
    text-align: center;
}
.col-SetPermission{
    width : 10%;
    text-align: center;
}
.cursor_pointer{
    cursor : pointer;
}
`


function PermissionOFM() {
    const CompanyInfo = getCompanyInfo()
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [HeaderName,setHeaderName] = useState('Production Permission');

  const GotoBase = (data) => {
    navigate(`/operation-field/PermissionOFMBase/${data}`)
  }
  const [menuTabList, setMenuTabList] = useState([]);
  const [ZoneCellData ,setZoneCellData] = useState([])

  const [tabactive, setTabactive] = useState('')
  const [ZoneName,setZoneName] = useState('');
  

  const fetchDataAllZone = useCallback(
    async (filter) => {
        dispatch(getZoneReturnData(filter)).then(({ type,data_info,data_meta }) => {
            if(type.endsWith('_SUCCESS')){
                if(data_meta){
                    
                    
                    //setZoneCellData(newZoneCellData)
                    const newZoneCellData = data_info.map((v, index) => { 
                        if(index == 0){
                            setTabactive(v.id);
                            setZoneName(v.name);
                            const filter = {
                                skip: 1,
                                limit: 1000,
                            }
                            fetchDataTable(filter,v.id)
                        }
                        return {
                            name : v.name,
                            value : v.id,
                            active_status : index == 0 ? true : false,
                        }
                    })
                    setMenuTabList(newZoneCellData);

                }
            }
        })
    },
    [dispatch]
 );

 useEffect(() => {
    
    const filter = {
        skip: 1,
        limit: 1000,
    }
    fetchDataAllZone(filter)
}, [fetchDataAllZone]) 





const TableColumn = [
    {
        Header: 'No',
        accessor: 'No',
        disableSortBy: false,
    },
    {
        Header: 'Base',
        accessor: 'Base',
        disableSortBy: false,
    },
    {
        Header: 'Task',
        accessor: 'Task',
        disableSortBy: false,
    },
    {
        Header: 'Get QR Code',
        accessor: 'GetQRCode',
        disableSortBy: false,
    },
    {
        Header: 'Set Permission',
        accessor: 'SetPermission',
        disableSortBy: false,
    },
];
const [TableOFMData, setTableOFMData] = useState([]);


const fetchDataTable = useCallback(
    async (filter,this_zone_id) => {
        if(this_zone_id){
            dispatch(getBaseByZoneIdReturnData(this_zone_id,filter)).then(({ type,data_info,data_meta }) => {
                if(type.endsWith('_SUCCESS')){
                    if(data_info){
                        //alert(ZoneName);
                        const zone_id = data_info.id;
                        const zone_name = data_info.name;
                        
                        const newEmployee = data_info.bases.map(async (item_base, index_base) => { 
                            dispatch(getTaskByBaseIdReturnData(item_base.id,filter)).then(({ type,data_info,data_meta }) => {
                                if(type.endsWith('_SUCCESS')){
                                    if(data_info){
                                        const this_data_base = {
                                            No : index_base + 1,
                                            Base : item_base.name,
                                            GetQRCode : <img className="cursor_pointer" src={iconQR} title="QR Code" onClick={() => OpenQRCode(item_base,'base',zone_id,zone_name,'','')} />,
                                        };
                                        setTableOFMData(oldArray => [...oldArray, this_data_base]);
                                        let index_task = 0;
                                        for(const item_task of data_info.tasks){
                                            const this_data = {
                                                Task : item_task.name,
                                                GetQRCode : <img className="cursor_pointer" src={iconQR} title="QR Code" onClick={() => OpenQRCode(item_task,'task',zone_id,zone_name,item_base.id,item_base.name)} />,
                                                SetPermission : <img className="cursor_pointer" src={iconPermission} title="Set Permission" onClick={() => OpenPermission(item_task)}  />,
                                            };
                                            index_task = index_task + 1;
                                            setTableOFMData(oldArray => [...oldArray, this_data]);
                                        }
                                    }
                                }
                            });
                            return null;
                        })
                        
                        //setTableOFMData(oldArray => [...oldArray, this_data]);
                        /*
                        let index_base = 0;
                        const zone_id = data_info.id;
                        const zone_name = data_info.name;
                        const num_bases = data_info.bases.length;
                        let addDataTable = [];
                        for(const item_base of data_info.bases){
                            
                            dispatch(getTaskByBaseIdReturnData(item_base.id,filter)).then(({ type,data_info,data_meta }) => {
                                if(type.endsWith('_SUCCESS')){
                                    if(data_info){
                                        
                                        const this_data_base = {
                                            No : index_base + 1,
                                            Base : item_base.name,
                                            GetQRCode : <img className="cursor_pointer" src={iconQR} title="QR Code" onClick={() => OpenQRCode(item_base,'base',zone_id,zone_name,'','')} />,
                                        };
                                        //index_base = index_base + 1;
                                        //setTableOFMData(oldArray => [...oldArray, this_data_base]);
                                        let index_task = 0;
                                        let num_task = data_info.tasks.length;
                                        let allDataTableTask = [];

                                        for(const item_task of data_info.tasks){
                                            const this_data = {
                                                Task : item_task.name,
                                                GetQRCode : <img className="cursor_pointer" src={iconQR} title="QR Code" onClick={() => OpenQRCode(item_task,'task',zone_id,zone_name,item_base.id,item_base.name)} />,
                                                SetPermission : <img className="cursor_pointer" src={iconPermission} title="Set Permission" onClick={() => OpenPermission(item_task)}  />,
                                            };
                                            //setTableOFMData(oldArray => [...oldArray, this_data]);
                                            index_task = index_task + 1;
                                            allDataTableTask.push(this_data);
                                        }

                                        if(index_task == num_task){
                                            index_base = index_base + 1;
                                            //addDataTable.push(this_data_base);
                                            addDataTable = [...addDataTable,this_data_base,...allDataTableTask]
                                            
                                            console.table(addDataTable);
                                        }
                                        if(index_base == num_bases){
                                            //alert(index_base);
                                            setTableOFMData(addDataTable);
                                        }

                                    }
                                }
                            });
                        }
                        */
                    }
                }
            })
        }

    },
    [dispatch]
 );

 const fetchDataTableTask = useCallback(
    async (filter,this_base_id) => {
    },
    [dispatch]
 );   
 
 const [dialogQR, setdialogQR] = useState(false);
 const [QRDisplay, setQRDisplay] = useState({});
 const [valueQR, setvalueQR] = useState({});
 const OpenQRCode = (item,type_qr,zone_id,zone_name,base_id,base_name) => {
    //alert(JSON.stringify(zone_id));
    //alert(JSON.stringify(menuTabList));
    
    const currentDate_now = dayjs().format('YYYY-MM-DD HH:mm:ss');
    if(type_qr == 'base'){
        const data_qr_display = {
            "company_id" : CompanyInfo?.id,
            "zone" : zone_id,
            "display_zone" : zone_name,
            "base" : item.id,
            "display_base" : item.name,
            "created" : 'Created at : ' + DateDisplay(currentDate_now)
        };
        setQRDisplay(data_qr_display);
      
        const data_qr = {
            "company_id" : CompanyInfo?.id,
            "zone" : zone_id,
            "base" : item.id
        };
        setvalueQR(data_qr);
        setdialogQR(true);
    }else if(type_qr == 'task'){
        const data_qr_display = {
            "company_id" : CompanyInfo?.id,
            "zone" : zone_id,
            "display_zone" : zone_name,
            "base" : base_id,
            "display_base" : base_name,
            "task" : item.id,
            "display_task" : item.name,
            "created" : 'Created at : ' + DateDisplay(currentDate_now)
        };
        setQRDisplay(data_qr_display);
    
        const data_qr = {
            "company_id" : CompanyInfo?.id,
            "zone" : zone_id,
            "base" : base_id,
            "task" : item.id,
        };
        //alert(JSON.stringify(data_qr));
        setvalueQR(data_qr);
        setdialogQR(true);
    }
}

const [WidthMeetingCalendar, setWidthMeetingCalendar] = useState('1400')
const [onModalPermission, setonModalPermission] = useState(false)
const [TaskId,setTaskId] = useState(null);
const OpenPermission = (item) => {
    setTaskId(item.id);
    setWidthMeetingCalendar(window.innerWidth - 80)
    //setWidthMeetingCalendar(830);
    setonModalPermission(true)
}
const CloseModalPermission = () => {
    setonModalPermission(false)
}
const SubmitModalPermission  = () => {
    setonModalPermission(false)
    //onClearAllSearch()
}


 const handleClickTaskMenuTab = (data) => {
    //alert(JSON.stringify(data));
    let tmpList = [...menuTabList]
    setTableOFMData([]);
    for (let i = 0; i < tmpList.length; i++) {
        if (tmpList[i].value == data.value) {
            tmpList[i].active_status = true
            setTabactive(tmpList[i].value);
            setZoneName(tmpList[i].name);
            const filter = {
                skip: 1,
                limit: 1000,
            }
            fetchDataTable(filter,tmpList[i].value)

        } else {
            tmpList[i].active_status = false
        }
    }
    setMenuTabList([...tmpList])
}


const onChangePagination = async (value) => {
    
}

  return (
    <DivMain>
        <div className="header mb-2-rem">
            <RiHome6Line size={20} className="mr-8-px" />
            <MdOutlineKeyboardArrowRight className="mr-8-px" />
            <span className="icon mr-8-px" >
            Operation field
            </span>
            <MdOutlineKeyboardArrowRight className="mr-8-px" />
            <span className="highlight">Production Permission Setting</span>
        </div>
        <div className="content-title">Production Permission Setting</div>
        <div className="content-title-detail">Get QR Code and set permission of production in each zone, base, and task</div>
        
        <div className="border_header"></div>
        <div className="wrp-tab-menu">
            {menuTabList?.map((item, index) => (
                <button
                id={'this_name-' + item.name}
                key={'btn-' + index}
                className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                onClick={() => handleClickTaskMenuTab(item)}
                >
                {item.name}
                </button>
            ))}
       </div>
       <div className="content-zone-name">{ZoneName}</div>
       <div className="table-data">
            {ZoneName && (
                <TableV2
                    columns={TableColumn}
                    data={TableOFMData}
                    onStateChange={onChangePagination}
                    pageCount={0}
                    page={0}
                    loading={false}
                />
            )}

       </div>
        <ModalQR
          open={Boolean(dialogQR)}
          onClose={() => setdialogQR(false)}
          QRDisplay={QRDisplay}
          valueQR={valueQR}
        />

        <SidePanel isOpen={onModalPermission} setIsOpen={setonModalPermission} width={WidthMeetingCalendar}>
            <ModalPermission width={WidthMeetingCalendar} onClose={CloseModalPermission} onSubmit={SubmitModalPermission} TaskId={TaskId} />
        </SidePanel>
    </DivMain>
  )
}

export default PermissionOFM