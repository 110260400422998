import { combineReducers } from 'redux'
import {
  GET_USERS_LIST,
  CREATE_USERS_MANAGEMENT,
  GET_USERS_MANAGEMENT_DEPARTMENT,
  USERS_MANAGEMENT_ACCESS_ROLE,
  USERS_MANAGEMENT_GET_BY_ID,
  EDIT_USERS_MANAGEMENT,
  EDIT_USERS_MANAGEMENT_AND_EMPLOYEE,
  PUT_SEND_RESET_PASSWORD_PERMISSION,
  DELETE_USERS_MANAGEMENT,
  FINE_EMPLOYEE_USER,
  SET_EMPLOYEE_USER,
} from '../actionTypes'

const initialState = {
  usersInfo: {},
  usersList: [],
  employeeUser: {},
  departmentList: [],
  accessroleList: [],
  page: 1,
  pageCount: 1,
  total: 0,
  limit: 10,
  isLoading: false,
  state: '',
}

const usersReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // case GET_USERS_LIST.REQUEST:
    //   return {
    //     ...state,
    //     isLoading: true,
    //     state: 'GET_USERS_LIST.REQUEST',
    //   }
    // case GET_USERS_LIST.SUCCESS: {
    //   const { data, meta } = action
    //   return {
    //     ...state,
    //     usersList: data,
    //     page: meta.pagination.page,
    //     limit: meta.pagination.pageSize,
    //     pageCount: meta.pagination.pageCount,
    //     total: meta.pagination.total,
    //     isLoading: false,
    //     state: 'GET_USERS_LIST.SUCCESS',
    //   }
    // }
    // case GET_USERS_LIST.FAILURE:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     state: 'GET_USERS_LIST.FAILURE',
    //   }
    case GET_USERS_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_USERS_LIST.REQUEST',
      }
    case GET_USERS_LIST.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        usersList: data,
        page: meta.skip + 1,
        limit: meta.limit,
        pageCount: 1,
        total: meta.total,
        isLoading: false,
        state: 'GET_USERS_LIST.SUCCESS',
      }
    }
    case GET_USERS_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_USERS_LIST.FAILURE',
      }
    case GET_USERS_MANAGEMENT_DEPARTMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_USERS_MANAGEMENT_DEPARTMENT.REQUEST',
      }
    case GET_USERS_MANAGEMENT_DEPARTMENT.SUCCESS: {
      const { data } = action
      return {
        ...state,
        departmentList: data,
        isLoading: false,
        state: 'GET_USERS_MANAGEMENT_DEPARTMENT.SUCCESS',
      }
    }
    case GET_USERS_MANAGEMENT_DEPARTMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_USERS_MANAGEMENT_DEPARTMENT.FAILURE',
      }
    case CREATE_USERS_MANAGEMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_USERS_MANAGEMENT.REQUEST',
      }
    case CREATE_USERS_MANAGEMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_USERS_MANAGEMENT.SUCCESS',
      }
    }
    case CREATE_USERS_MANAGEMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_USERS_MANAGEMENT.FAILURE',
      }
    case USERS_MANAGEMENT_ACCESS_ROLE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'USERS_MANAGEMENT_ACCESS_ROLE.REQUEST',
      }
    case USERS_MANAGEMENT_ACCESS_ROLE.SUCCESS: {
      const { data } = action
      return {
        ...state,
        accessroleList: data,
        isLoading: false,
        state: 'USERS_MANAGEMENT_ACCESS_ROLE.SUCCESS',
      }
    }
    case USERS_MANAGEMENT_ACCESS_ROLE.FAILURE:
      return {
        ...state,
        isLoading: true,
        state: 'USERS_MANAGEMENT_ACCESS_ROLE.FAILURE',
      }
    case USERS_MANAGEMENT_GET_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'USERS_MANAGEMENT_GET_BY_ID.REQUEST',
      }
    case USERS_MANAGEMENT_GET_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        usersInfo: data,
        isLoading: false,
        state: 'USERS_MANAGEMENT_GET_BY_ID.SUCCESS',
      }
    }
    case USERS_MANAGEMENT_GET_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: true,
        state: 'USERS_MANAGEMENT_GET_BY_ID.FAILURE',
      }
    case EDIT_USERS_MANAGEMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'EDIT_USERS_MANAGEMENT.REQUEST',
      }
    case EDIT_USERS_MANAGEMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_USERS_MANAGEMENT.SUCCESS',
      }
    }
    case EDIT_USERS_MANAGEMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_USERS_MANAGEMENT.FAILURE',
      }
    case EDIT_USERS_MANAGEMENT_AND_EMPLOYEE.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'EDIT_USERS_MANAGEMENT_AND_EMPLOYEE.REQUEST',
      }
    case EDIT_USERS_MANAGEMENT_AND_EMPLOYEE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_USERS_MANAGEMENT_AND_EMPLOYEE.SUCCESS',
      }
    }
    case EDIT_USERS_MANAGEMENT_AND_EMPLOYEE.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'EDIT_USERS_MANAGEMENT_AND_EMPLOYEE.FAILURE',
      }
    case DELETE_USERS_MANAGEMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_USERS_MANAGEMENT.REQUEST',
      }
    case DELETE_USERS_MANAGEMENT.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_USERS_MANAGEMENT.SUCCESS',
      }
    }
    case DELETE_USERS_MANAGEMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_USERS_MANAGEMENT.FAILURE',
      }
    case PUT_SEND_RESET_PASSWORD_PERMISSION.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'PUT_SEND_RESET_PASSWORD_PERMISSION.REQUEST',
      }
    case PUT_SEND_RESET_PASSWORD_PERMISSION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'PUT_SEND_RESET_PASSWORD_PERMISSION.SUCCESS',
      }
    }
    case PUT_SEND_RESET_PASSWORD_PERMISSION.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'PUT_SEND_RESET_PASSWORD_PERMISSION.FAILURE',
      }
    case FINE_EMPLOYEE_USER.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'FINE_EMPLOYEE_USER.REQUEST',
      }
    case FINE_EMPLOYEE_USER.SUCCESS: {
      const { data } = action
      return {
        ...state,
        employeeUser: data[0],
        isLoading: false,
        state: 'FINE_EMPLOYEE_USER.SUCCESS',
      }
    }
    case FINE_EMPLOYEE_USER.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'FINE_EMPLOYEE_USER.FAILURE',
      }
    case SET_EMPLOYEE_USER:
      return {
        ...state,
        employeeUser: action.data,
      }
    default:
      return state
  }
}

export default combineReducers({
  usersReducer,
})
