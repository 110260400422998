import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import styled from 'styled-components'
import TextField from '../../form/TextField'
import Button from '../../form/button/Button'
import ButtonOutline from '../../form/button/ButtonOutline'
import KPIInput from './KPIInput'
import NewAccordion from '../../common/NewAccordion'
import Modal from '../../common/Modal'
import DialogSuccess from '../../dialog/DialogSuccess'
import DialogFail from '../../dialog/DialogFail'

import { TbBuildingBank } from 'react-icons/tb'
import { LiaTimesSolid } from 'react-icons/lia'
import { FaPlus } from 'react-icons/fa6'
import { LuPen, LuSave } from 'react-icons/lu'
import flagIcon from '../../../assets/images/flag-icon.png'
import successIcon from '../../../assets/images/success-icon.png'
import failIcon from '../../../assets/images/fail-icon.png'

import { selectKPI, selectMaster } from '../../../redux/selectors'
import { getDepartmentsAndPositions } from '../../../redux/actions/master'
import { clearEditKpiForm, editKPI, getKpiById } from '../../../redux/actions/kpiManagement'

const Div = styled.div`
  padding: 24px;
  // box model style
  .mt-2-px {
    margin-top: 4px;
  }
  .mt-10-px {
    margin-top: 10px;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-10-px {
    margin-bottom: 10px;
  }
  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }
  .mr-10-px {
    margin-right: 10px;
  }
  .mr-12-px {
    margin-right: 12px;
  }
  .mr-16-px {
    margin-right: 16px;
  }

  // typo style
  h2,
  p {
    margin: 0;
  }
  .kpi-heading {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .kpi-des {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  // color
  .icon-color {
    color: var(--Primary-600);
  }
  .icon {
    cursor: pointer;
  }
  .add-kpi-btn {
    border: 1px solid var(--Primary-300);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }
  .save-draft-btn {
    border: 1px solid var(--Primary-300);
    background: var(--Base-White);
    color: var(--Primary-700);
  }

  /* footer */
  .footer-create-kpi {
    position: sticky;
    width: 100%;
    z-index: 99;
    left: 0;
    bottom: 0;

    border-top: 1px solid var(--Gray-200);
    background-color: var(--Base-White);
    padding-top: 16px;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-center {
    align-items: center;
  }
`
const ModalContainer = styled.div`
  padding: 24px;

  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }

  .w-100 {
    width: 100%;
  }
  .w-48 {
    width: 48%;
  }

  .icon {
    cursor: pointer;
  }
  .heading-modal {
    color: var(--Gray-900);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  .input-heading {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 6px;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`

function EditKPIAnnoucement({ open, onClose }) {
  // external hook
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // initiate data
  const fecthDepartment = useCallback(() => {
    dispatch(getDepartmentsAndPositions())
  }, [dispatch])

  useEffect(() => {
    fecthDepartment()
  }, [])

  // intiail form edit
  const { departments } = useSelector(selectMaster)
  const { kpiEditForm } = useSelector(selectKPI)
  const [defaultKpiOrganization, setDefaultKpiOrganization] = useState([])
  useEffect(() => {
    const { kpi_organizations, kpi_departments } = kpiEditForm
    if (kpi_organizations && kpi_organizations.length > 0) {
      const initialKpiOrganizations = kpi_organizations.map((kpi) => ({
        key: uuidv4(),
        id: kpi.id,
        kpi_name: kpi.kpi_name,
        description: kpi.description,
        target_type: kpi.target_type,
        target_value: kpi.target_value,
        unit: kpi.unit,
        start_date: kpi.start_date,
        end_date: kpi.end_date,
      }))
      setKpiOrganization(initialKpiOrganizations)
      setDefaultKpiOrganization(initialKpiOrganizations)
    } else {
      setKpiOrganization([])
    }
    if (kpi_departments && Object.keys(kpi_departments).length > 0) {
      const newKpiDepartmentListForm = Object.entries(kpi_departments).map(([key, value]) => ({
        departmentId: departments.find((v) => v.name == key).id,
        departmentKey: key,
        isEdit: false,
        kpiForm: value.map((it) => ({
          key: uuidv4(),
          id: it.id,
          kpi_name: it.kpi_name,
          description: it.description,
          target_type: it.target_type,
          target_value: it.target_value,
          unit: it.unit,
          start_date: it.start_date,
          end_date: it.end_date,
          department: it?.department?.id,
        })),
      }))

      setKpiDepartmentListForm(newKpiDepartmentListForm)
      setDefaultKpiDepartmentListForm(newKpiDepartmentListForm)
    } else {
      setKpiDepartmentListForm([])
      setDefaultKpiDepartmentListForm([])
    }
  }, [kpiEditForm])

  // kpi organization
  const [kpiOrganization, setKpiOrganization] = useState([])
  const [isEditKpiOrganization, setIsEditKpiOrganization] = useState(false)

  const toggleEditOrganization = () => {
    setIsEditKpiOrganization(!isEditKpiOrganization)
  }
  const cancelEditOrganization = () => {
    setKpiOrganization(defaultKpiOrganization)
    toggleEditOrganization()
  }
  const saveChangeEditKpiOrganization = () => {
    const addedKpi = kpiOrganization.filter((it) => !it.id)
    const editedKpi = kpiOrganization.filter((it) => it.id)
    const idDefaultList = defaultKpiOrganization.map((v) => v.id)
    const idNewList = editedKpi.map((v) => v.id)
    const deletedIds = idDefaultList.filter((v) => !idNewList.includes(v))

    const request = {
      kpi_organization: {
        add: addedKpi?.map((v) => ({
          ...v,
          target_type: v.target_type === '' ? null : v.target_type,
          target_value: v.target_value === '' ? null : v.target_value,
          start_date: v.start_date === '' ? null : v.start_date,
          end_date: v.end_date === '' ? null : v.end_date,
        })),
        edit: editedKpi?.map((v) => ({
          ...v,
          target_type: v.target_type === '' ? null : v.target_type,
          target_value: v.target_value === '' ? null : v.target_value,
          start_date: v.start_date === '' ? null : v.start_date,
          end_date: v.end_date === '' ? null : v.end_date,
        })),
        remove: deletedIds,
      },
    }
    dispatch(editKPI(kpiEditForm.id, request))
    toggleEditOrganization()
  }
  const onChangeKpiOrganization = (key, event) => {
    const { name, value } = event.target

    setKpiOrganization((prev) => {
      const newKpiOrganization = prev.map((it) => {
        if (it.key === key) {
          return {
            ...it,
            [name]: name === 'target_value' ? value.replace(/[^0-9.]/g, '') : value,
          }
        }

        return it
      })

      return newKpiOrganization
    })
  }
  const onChangeDropdownKpiOrganization = (key, name, value) => {
    setKpiOrganization((prev) => {
      const newKpiOrganization = prev.map((it) => {
        if (it.key === key)
          return {
            ...it,
            [name]: value,
          }
        return it
      })

      return newKpiOrganization
    })
  }
  const onChangeDateKpiOrganization = (key, name, value) => {
    setKpiOrganization((prev) => {
      const newKpiOrganization = prev.map((it) => {
        if (it.key === key)
          return {
            ...it,
            [name]: value,
          }
        return it
      })

      return newKpiOrganization
    })
  }
  const addKPI = () => {
    const newKPI = {
      key: uuidv4(),
      kpi_name: '',
      description: '',
      target_type: '',
      target_value: '',
      unit: '',
      start_date: '',
      end_date: '',
    }

    setKpiOrganization((prev) => [...prev, newKPI])
  }
  const deleteKPI = (key) => {
    setKpiOrganization((prev) => {
      const newKpi = prev.filter((v) => v.key !== key)
      return newKpi
    })
  }

  // kpi department
  const [kpiDepartmentListForAccordion, setKpiDepartmentListForAccordion] = useState([])
  const [kpiDepartmentListForm, setKpiDepartmentListForm] = useState([])
  const [defaultKpiDepartmentListForm, setDefaultKpiDepartmentListForm] = useState([])

  const onChangeKpiDepartment = (key, event) => {
    const { name, value } = event.target
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentListForm = prev.map((it) => {
        const isThereKey = it.kpiForm.some((form) => form.key === key)
        if (isThereKey) {
          const newKpiForm = it.kpiForm.map((v) => {
            if (v.key === key)
              return {
                ...v,
                [name]: name === 'target_value' ? value.replace(/[^0-9.]/g, '') : value,
              }
            return v
          })
          return {
            ...it,
            kpiForm: newKpiForm,
          }
        } else {
          return it
        }
      })
      return newKpiDepartmentListForm
    })
  }
  const onChangeDropdownKpiDepartment = (key, name, value) => {
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentListForm = prev.map((it) => {
        const isThereKey = it.kpiForm.some((form) => form.key === key)
        if (isThereKey) {
          const newKpiForm = it.kpiForm.map((v) => {
            if (v.key === key)
              return {
                ...v,
                [name]: value,
              }
            return v
          })
          return {
            ...it,
            kpiForm: newKpiForm,
          }
        } else {
          return it
        }
      })
      return newKpiDepartmentListForm
    })
  }
  const onChangeDateKpiDepartment = (key, name, value) => {
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentListForm = prev.map((it) => {
        const isThereKey = it.kpiForm.some((form) => form.key === key)
        if (isThereKey) {
          const newKpiForm = it.kpiForm.map((v) => {
            if (v.key === key)
              return {
                ...v,
                [name]: value,
              }
            return v
          })
          return {
            ...it,
            kpiForm: newKpiForm,
          }
        } else {
          return it
        }
      })
      return newKpiDepartmentListForm
    })
  }
  const addKPIDepartment = (deptKey) => {
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentListForm = prev.map((dept) => {
        if (dept.departmentKey === deptKey) {
          const newKPI = {
            key: uuidv4(),
            kpi_name: '',
            description: '',
            target_type: '',
            target_value: '',
            unit: '',
            start_date: '',
            end_date: '',
            department: dept.departmentId,
          }
          return {
            ...dept,
            kpiForm: [...dept.kpiForm, newKPI],
          }
        } else return dept
      })

      return newKpiDepartmentListForm
    })
  }
  const deleteKPIDepartment = (departmentKey, key) => {
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentForm = prev.map((dept) => {
        if (dept.departmentKey === departmentKey) {
          const newKpiForm = dept.kpiForm.filter((it) => it.key !== key)
          return {
            ...dept,
            kpiForm: newKpiForm,
          }
        }
        return dept
      })

      return newKpiDepartmentForm
    })
  }
  const toggleEditDepartment = (departmentKey) => {
    setKpiDepartmentListForm((prev) =>
      prev.map((it) => {
        if (it.departmentKey === departmentKey)
          return {
            ...it,
            isEdit: !it.isEdit,
          }

        return it
      })
    )
  }
  const cancelEditDepartment = (departmentKey) => {
    setKpiDepartmentListForm((prev) =>
      prev.map((it) => {
        if (it.departmentKey === departmentKey) {
          const matchedDepartment = defaultKpiDepartmentListForm.find((dept) => dept.departmentKey === departmentKey)
          return {
            ...it,
            isEdit: !it.isEdit,
            kpiForm: matchedDepartment.kpiForm,
          }
        }

        return it
      })
    )
  }
  const saveChangeEditKpiDepartment = (departmentKey) => {
    const { kpiForm } = kpiDepartmentListForm.find((dept) => dept.departmentKey === departmentKey)
    const addedKpi = kpiForm.filter((it) => !it.id)
    const editedKpi = kpiForm.filter((it) => it.id)
    const idDefaultList = defaultKpiDepartmentListForm
      .find((it) => it.departmentKey === departmentKey)
      .kpiForm.map((v) => v.id)
    const idNewList = editedKpi.map((v) => v.id)
    const deletedIds = idDefaultList.filter((v) => !idNewList.includes(v))

    const request = {
      kpi_department: {
        add: addedKpi?.map((v) => ({
          ...v,
          target_type: v.target_type === '' ? null : v.target_type,
          target_value: v.target_value === '' ? null : v.target_value,
          start_date: v.start_date === '' ? null : v.start_date,
          end_date: v.end_date === '' ? null : v.end_date,
        })),
        edit: editedKpi?.map((v) => ({
          ...v,
          target_type: v.target_type === '' ? null : v.target_type,
          target_value: v.target_value === '' ? null : v.target_value,
          start_date: v.start_date === '' ? null : v.start_date,
          end_date: v.end_date === '' ? null : v.end_date,
        })),
        remove: deletedIds,
      },
    }
    dispatch(editKPI(kpiEditForm.id, request))
    toggleEditDepartment(departmentKey)
  }

  useEffect(() => {
    console.log('kpiDepartmentListForm', kpiDepartmentListForm)
    const newKpiDepartmentList = kpiDepartmentListForm.map((v) => {
      return {
        key: v.departmentKey,
        title: v.departmentKey,
        content: (
          <div>
            <FlexContainer className="justify-content-end mt-10-px mb-10-px">
              {v.isEdit ? (
                <FlexContainer className="justify-content-end mb-10-px">
                  <ButtonOutline className="mr-10-px" onClick={() => cancelEditDepartment(v.departmentKey)}>
                    {t('cancel')}
                  </ButtonOutline>
                  <Button append={<LuSave sizie={20} />} onClick={() => saveChangeEditKpiDepartment(v.departmentKey)}>
                    {t('save_changes')}
                  </Button>
                </FlexContainer>
              ) : (
                <FlexContainer className="justify-content-end mb-10-px">
                  <ButtonOutline
                    className="add-kpi-btn"
                    append={<LuPen />}
                    onClick={() => toggleEditDepartment(v.departmentKey)}
                  >
                    {t('edit')}
                  </ButtonOutline>
                </FlexContainer>
              )}
            </FlexContainer>
            <KPIInput
              className="mb-16-px"
              list={v.kpiForm}
              onChange={onChangeKpiDepartment}
              onChangeDropdown={onChangeDropdownKpiDepartment}
              onChangeDate={onChangeDateKpiDepartment}
              onDelete={(key) => deleteKPIDepartment(v.departmentKey, key)}
              disabled={!v.isEdit}
            />
            {v.isEdit && (
              <ButtonOutline
                className="add-kpi-btn mb-24-px"
                append={<FaPlus />}
                onClick={() => addKPIDepartment(v.departmentKey)}
              >
                Add KPI
              </ButtonOutline>
            )}
          </div>
        ),
      }
    })
    setKpiDepartmentListForAccordion(newKpiDepartmentList)
  }, [kpiDepartmentListForm])

  // state,isLoading,successModal,
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const { state, isLoading } = useSelector(selectKPI)
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  useEffect(() => {
    if (open) {
      if (state === 'EDI_KPI.SUCCESS' && isLoading === false) {
        setSuccessMessageModal({
          headline: 'Updated Done',
          message: '',
        })
        setSuccessModal(true)
        dispatch(getKpiById(kpiEditForm.id))
      }
      if (state === 'EDI_KPI.FAILURE' && isLoading === false) {
        setFailMessageModal({
          headline: 'Updated Fail',
          message: '',
        })
        setFailModal(true)
        setKpiOrganization(defaultKpiOrganization)
      }
    }
  }, [open, state, isLoading])
  useEffect(() => {
    if (!open) dispatch(clearEditKpiForm())
  }, [open])

  return (
    <Div>
      {/* Organization KPI section */}
      <section>
        <FlexContainer className="justify-content-space-between mb-24-px">
          <FlexContainer>
            <TbBuildingBank size={24} className="icon-color mt-2-px mr-16-px" />
            <div>
              <h2 className="kpi-heading mb-8-px">Organization KPI</h2>
              <p className="kpi-des">Organization goals</p>
            </div>
          </FlexContainer>
          <LiaTimesSolid className="icon" size={20} onClick={onClose} />
        </FlexContainer>

        {isEditKpiOrganization ? (
          <FlexContainer className="justify-content-end mb-10-px">
            <ButtonOutline className="mr-10-px" onClick={cancelEditOrganization}>
              {t('cancel')}
            </ButtonOutline>
            <Button append={<LuSave sizie={20} />} onClick={saveChangeEditKpiOrganization}>
              {t('save_changes')}
            </Button>
          </FlexContainer>
        ) : (
          <FlexContainer className="justify-content-end mb-10-px">
            <ButtonOutline className="add-kpi-btn" append={<LuPen />} onClick={toggleEditOrganization}>
              {t('edit')}
            </ButtonOutline>
          </FlexContainer>
        )}

        <KPIInput
          className="mb-16-px"
          list={kpiOrganization}
          onChange={onChangeKpiOrganization}
          onChangeDropdown={onChangeDropdownKpiOrganization}
          onChangeDate={onChangeDateKpiOrganization}
          onDelete={deleteKPI}
          disabled={!isEditKpiOrganization}
        />
        {isEditKpiOrganization && (
          <ButtonOutline className="add-kpi-btn mb-24-px" append={<FaPlus />} onClick={addKPI}>
            Add KPI
          </ButtonOutline>
        )}
      </section>

      <Divider className="mb-24-px" />

      {/* Department KPI section */}
      <section>
        <FlexContainer className="mb-24-px">
          <TbBuildingBank size={24} className="icon-color mt-2-px mr-16-px" />
          <div>
            <h2 className="kpi-heading mb-8-px">Department KPI</h2>
            <p className="kpi-des">Define department KPIs</p>
          </div>
        </FlexContainer>

        <NewAccordion items={kpiDepartmentListForAccordion} />
      </section>

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => {
          setSuccessModal(false)
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default EditKPIAnnoucement
