import Date from 'components/form/Date'
import TextField from 'components/form/TextField'
import DropdownSelect from 'components/widget/element/DropDownSelect'
import InputTextArea from 'components/widget/element/InputTextArea'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDistricts, getProvinces, getSubDistricts } from 'redux/actions/master'
import { selectMaster } from 'redux/selectors'
import styled from 'styled-components'
import { convertDateFormat } from 'utils/helper'
export default function TransferRequestInformation({ initValue, onChange, isPreview, isError }) {
  const dispatch = useDispatch()
  const DEFAULT_VALUE = {}
  const VEHICLE_LIST = [
    {
      text: 'รถบรรทุก',
      value: 'รถบรรทุก',
    },
    {
      text: 'รถไฟ',
      value: 'รถไฟ',
    },
    {
      text: 'อื่นๆ',
      value: 'อื่นๆ',
    },
  ]
  const [transferData, setTransferData] = useState()

  const { provinces, districts, subdistricts } = useSelector(selectMaster)
  const [provinceList, setProvinceList] = useState([])

  // ต้นทาง
  const [departureDistrictList, setDepartureDistrictList] = useState([])
  const [departureSubDistrictList, setDepartureSubDistrictList] = useState([])
  // ปลายทาง
  const [destinationDistrictList, setDestinationDistrictList] = useState([])
  const [destinationSubDistrictList, setDestinationSubDistrictList] = useState([])

  const [validateInput, setValidateInput] = useState({
    is_error_from_name: false,
    is_error_from_address: false,
    is_error_from_province: false,
    is_error_from_district: false,
    is_error_from_sub_district: false,
    is_error_to_name: false,
    is_error_to_address: false,
    is_error_to_province: false,
    is_error_to_district: false,
    is_error_to_sub_district: false,
    is_error_vehicle_type: false,
    is_error_vehicle_name: false,
    is_error_start_date: false,
    is_error_end_date: false,
  })

  useEffect(() => {
    dispatch(getProvinces())
  }, [])

  useEffect(() => {
    const newList = provinces.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
      // text_en: v?.attributes?.name_en,
    }))
    setProvinceList(newList)
  }, [provinces])

  useEffect(() => {
    if (initValue) {
      // ----- ต้นทาง -----
      if (initValue?.from?.province) {
        getDistrictListByType(initValue?.from?.province, 'from')
      }
      if (initValue?.from?.district) {
        getSubDistrictListByType(initValue?.from?.district, 'from')
      }

      // ----- ปลายทาง -----
      if (initValue?.to?.province) {
        getDistrictListByType(initValue?.to?.province, 'to')
      }
      if (initValue?.to?.district) {
        getSubDistrictListByType(initValue?.to?.district, 'to')
      }

      setTransferData(initValue)
    }
  }, [initValue])

  useEffect(() => {
    if(isError && transferData) {
      checkValidate()
    }
  }, [isError])

  const checkValidate=()=>{
    let tmpData = transferData;
    if(tmpData) {
      let tmpValidObj = {...validateInput};

      if(!tmpData?.from?.name) {
        tmpValidObj.is_error_from_name = true;
      } else {
        tmpValidObj.is_error_from_name = false;
      }

      if(!tmpData?.from?.address) {
        tmpValidObj.is_error_from_address = true;
      } else {
        tmpValidObj.is_error_from_address = false;
      }

      if(!tmpData?.from?.province) {
        tmpValidObj.is_error_from_province = true;
      } else {
        tmpValidObj.is_error_from_province = false;
      }

      if(!tmpData?.from?.district) {
        tmpValidObj.is_error_from_district = true;
      } else {
        tmpValidObj.is_error_from_district = false;
      }

      if(!tmpData?.from?.sub_district) {
        tmpValidObj.is_error_from_sub_district = true;
      } else {
        tmpValidObj.is_error_from_sub_district = false;
      }

      if(!tmpData?.to?.name) {
        tmpValidObj.is_error_to_name = true;
      } else {
        tmpValidObj.is_error_to_name = false;
      }

      if(!tmpData?.to?.address) {
        tmpValidObj.is_error_to_address = true;
      } else {
        tmpValidObj.is_error_to_address = false;
      }

      if(!tmpData?.to?.province) {
        tmpValidObj.is_error_to_province = true;
      } else {
        tmpValidObj.is_error_to_province = false;
      }

      if(!tmpData?.to?.district) {
        tmpValidObj.is_error_to_district = true;
      } else {
        tmpValidObj.is_error_to_district = false;
      }

      if(!tmpData?.to?.sub_district) {
        tmpValidObj.is_error_to_sub_district = true;
      } else {
        tmpValidObj.is_error_to_sub_district = false;
      }

      if(!tmpData?.vehicle_type) {
        tmpValidObj.is_error_vehicle_type = true;
      } else {
        tmpValidObj.is_error_vehicle_type = false;
      }

      if(tmpData?.vehicle_type == 'อื่นๆ' && !tmpData?.vehicle_name) {
        tmpValidObj.is_error_vehicle_name = true;
      } else {
        tmpValidObj.is_error_vehicle_name = false;
      }

      if(!tmpData?.start_date) {
        tmpValidObj.is_error_start_date = true;
      } else {
        tmpValidObj.is_error_start_date = false;
      }

      if(!tmpData?.end_date) {
        tmpValidObj.is_error_end_date = true;
      } else {
        tmpValidObj.is_error_end_date = false;
      }

      setValidateInput(tmpValidObj)
    }
  }

  const getDistrictListByType=(provinceId, type)=>{
    if(provinceId && type) {
      dispatch(getDistricts(provinceId)).then((e) => {
        if (e?.data) {
          let tmpList = e?.data
          let districtList = tmpList.map((v) => ({
            text: v?.attributes?.name_th,
            value: v?.id,
            text_en: v?.attributes?.name_en,
          }))
          if(type == 'from') {
            setDepartureDistrictList(districtList)
          } else {
            setDestinationDistrictList(districtList)
          }
        }
      })
    }
  }

  const getSubDistrictListByType=(districtId, type)=>{
    if(districtId && type) {
      dispatch(getSubDistricts(districtId)).then((e) => {
        if (e?.data) {
          let tmpList = e?.data
          let subDistrictList = tmpList.map((v) => ({
            text: v?.attributes?.name_th,
            value: v?.id,
            postcode: v?.attributes?.zip_code,
            text_en: v?.attributes?.name_en,
          }))
          if(type == 'from') {
            setDepartureSubDistrictList(subDistrictList)
          } else {
            setDestinationSubDistrictList(subDistrictList)
          }
        }
      })
    }
  }

  const handleChangeData = (e, type, key) => {
    let tmpData = { ...transferData }
    let tmpValidateData = { ...validateInput }
    if (type == 'input_text') {
      if (key == 'note') {
        tmpData[key] = e.target.value
      }
      if (key == 'transport_vehicle') {
        tmpData.transport_vehicle.note = e.target.value
      }
      if (key == 'departure_name') {
        tmpData.from.name = e.target.value
        if(e.target.value) {
          tmpValidateData.is_error_from_name = false;
        }
      }
      if (key == 'departure_address') {
        tmpData.from.address = e.target.value
        if(e.target.value) {
          tmpValidateData.is_error_from_address = false;
        }
      }
      if (key == 'destination_name') {
        tmpData.to.name = e.target.value
        if(e.target.value) {
          tmpValidateData.is_error_to_name = false;
        }
      }
      if (key == 'destination_address') {
        tmpData.to.address = e.target.value
        if(e.target.value) {
          tmpValidateData.is_error_to_address = false;
        }
      }
    } else if (type == 'input_select') {
      if (key == 'province_from') {
        tmpData.from.province = e
        if(e) {
          tmpValidateData.is_error_from_province = false;
        }
        getDistrictListByType(e, 'from')
      }
      if (key == 'district_from') {
        tmpData.from.district = e
        if(e) {
          tmpValidateData.is_error_from_district = false;
        }
        getSubDistrictListByType(e, 'from')
      }
      if (key == 'subdistrict_from') {
        tmpData.from.sub_district = e
        if(e) {
          tmpValidateData.is_error_from_sub_district = false;
        }
      }

      if (key == 'province_to') {
        tmpData.to.province = e
        if(e) {
          tmpValidateData.is_error_to_province = false;
        }
        getDistrictListByType(e, 'to')
      }
      if (key == 'district_to') {
        tmpData.to.district = e
        if(e) {
          tmpValidateData.is_error_to_district = false;
        }
        getSubDistrictListByType(e, 'to')
      }
      if (key == 'subdistrict_to') {
        tmpData.to.sub_district = e
        if(e) {
          tmpValidateData.is_error_to_sub_district = false;
        }
      }

      if (key == 'transport_vehicle') {
        tmpData.vehicle_type = e
        if(e) {
          tmpValidateData.is_error_vehicle_type = false;
        }
      }
    } else if(type == 'input_date') {
      tmpData[key] = e
      let tmpKey = 'is_error_'+key
      if(e) {
        tmpValidateData[tmpKey] = false;
      }
    }
    setTransferData(tmpData)
    setValidateInput(tmpValidateData)
    if (onChange) {
      onChange(tmpData)
    }
  }
  const setVehicleName = (val) => {
    if (val) {
      let tmpObj = VEHICLE_LIST.find((e) => e.value === val)
      console.log('tmpObj: ' + JSON.stringify(tmpObj, null, 2))
      return tmpObj?.text
    }
    return ''
  }
  const convertAddress = (location, type) => {
    if (location && type) {
      let tmpDistrictList = []
      let tmpSubDistrictList = []
      let tmpProvince = ''
      let tmpDistrict = ''
      let tmpSubDistrict = ''

      if(type == 'from') {
        tmpDistrictList = [...departureDistrictList]
        tmpSubDistrictList = [...departureSubDistrictList]
      } else {
        tmpDistrictList = [...destinationDistrictList]
        tmpSubDistrictList = [...destinationSubDistrictList]
      }

      if(location?.province) {
        let tmpObj = provinceList.find(x => x.value === location?.province);
        tmpProvince = tmpObj?.text
      }

      if(location?.district) {
        let tmpObj = tmpDistrictList.find(x => x.value === location?.district);
        tmpDistrict = tmpObj?.text
        if(location?.province != 1) {
          tmpDistrict = 'อำเภอ'+tmpObj?.text
        } else {
          tmpDistrict = 'เขต'+tmpObj?.text
        }
      }

      if(location?.sub_district) {
        let tmpObj = tmpSubDistrictList.find(x => x.value === location?.sub_district);
        if(location?.province != 1) {
          tmpSubDistrict = 'ตำบล'+tmpObj?.text
        } else {
          tmpSubDistrict = 'แขวง'+tmpObj?.text
        }
      }


      
      let tmpAddress = <><p>{location?.name}</p><p>{location?.address+ " " +tmpSubDistrict}</p><p>{tmpDistrict+ " จังหวัด" +tmpProvince}</p></>
      return tmpAddress
    }
    return ''
  }
  return (
    <Styled className="input_transfer_request_information" isPreview={isPreview}>
      <div className={'wrp_label' + (isPreview ? ' label_preview' : '')}>การขนย้าย</div>
      <div className={'wrp_input_container' + (isPreview ? ' preview' : '')}>
        <div className={'col_left'}>
          <div className="txt-title-header">สถานที่ต้นทาง</div>
          {isPreview ? (
            <div>{convertAddress(transferData?.from, 'from')}</div>
          ) : (
            <div>
              <div className="wrp-input">
                <p>ชื่อสถานที่ต้นทาง</p>
                <TextField
                  className={'text-field'}
                  // pattern={'^[0-9]*[.,]?[0-9]*$'}
                  placeholder={'Address'}
                  onChange={(e) => handleChangeData(e, 'input_text', 'departure_name')}
                  value={transferData?.from?.name}
                  error={validateInput.is_error_from_name}
                />
              </div>
              <div className="wrp-input">
                <p>Address</p>
                <TextField
                  className={'text-field'}
                  // pattern={'^[0-9]*[.,]?[0-9]*$'}
                  placeholder={'Address'}
                  onChange={(e) => handleChangeData(e, 'input_text', 'departure_address')}
                  value={transferData?.from?.address}
                  error={validateInput.is_error_from_address}
                />
              </div>
              <div className="row">
                <div className="wrp-input mr_5">
                  <p>Province</p>
                  <DropdownSelect
                    className={'dropdown-select'}
                    dataList={provinceList}
                    onChange={(e) => handleChangeData(e, 'input_select', 'province_from')}
                    initValue={transferData?.from?.province}
                    isError={validateInput.is_error_from_province}
                  />
                </div>

                <div className="wrp-input ml_5">
                  <p>District</p>
                  <DropdownSelect
                    className={'dropdown-select'}
                    dataList={departureDistrictList}
                    onChange={(e) => handleChangeData(e, 'input_select', 'district_from')}
                    initValue={transferData?.from?.district}
                    isError={validateInput.is_error_from_district}
                  />
                </div>
              </div>
              <div className="row w_50">
                <div className="wrp-input mr_5">
                  <p>Sub-district</p>
                  <DropdownSelect
                    className={'dropdown-select'}
                    dataList={departureSubDistrictList}
                    onChange={(e) => handleChangeData(e, 'input_select', 'subdistrict_from')}
                    initValue={transferData?.from?.sub_district}
                    isError={validateInput.is_error_from_sub_district}
                  />
                </div>

                {/* <div className="wrp-input ml_5">
                  <p>Postcode</p>
                  <TextField
                    className={'text-field'}
                    pattern={'^[0-9]*[.,]?[0-9]*$'}
                    placeholder={'Postcode'}
                    disabled
                    // onChange={(e) => handleChangeData(e, 'seller_fax')}
                    value={transferData?.from?.post_code}
                    // error={validateInputData.is_error_seller_fax}
                  />
                </div> */}
              </div>
            </div>
          )}
        </div>
        <div>-</div>
        <div className={'col_right'}>
          <div className="txt-title-header">สถานที่ปลายทาง</div>
          {isPreview ? (
            <p>{convertAddress(transferData?.to, 'to')}</p>
          ) : (
            <div>
              <div className="wrp-input">
                <p>ชื่อสถานที่ปลายทาง</p>
                <TextField
                  className={'text-field'}
                  // pattern={'^[0-9]*[.,]?[0-9]*$'}
                  placeholder={'Address'}
                  onChange={(e) => handleChangeData(e, 'input_text', 'destination_name')}
                  value={transferData?.to?.name}
                  error={validateInput.is_error_to_name}
                />
              </div>
              <div className="wrp-input">
                <p>Address</p>
                <TextField
                  className={'text-field'}
                  // pattern={'^[0-9]*[.,]?[0-9]*$'}
                  placeholder={'Address'}
                  onChange={(e) => handleChangeData(e, 'input_text', 'destination_address')}
                  value={transferData?.to?.address}
                  error={validateInput.is_error_to_address}
                />
              </div>
              <div className="row">
                <div className="wrp-input mr_5">
                  <p>Province</p>
                  <DropdownSelect
                    className={'dropdown-select'}
                    dataList={provinceList}
                    onChange={(e) => handleChangeData(e, 'input_select', 'province_to')}
                    initValue={transferData?.to?.province}
                    isError={validateInput.is_error_to_province}
                  />
                </div>

                <div className="wrp-input ml_5">
                  <p>District</p>
                  <DropdownSelect
                    className={'dropdown-select'}
                    dataList={destinationDistrictList}
                    onChange={(e) => handleChangeData(e, 'input_select', 'district_to')}
                    initValue={transferData?.to?.district}
                    isError={validateInput.is_error_to_district}
                  />
                </div>
              </div>
              <div className="row w_50">
                <div className="wrp-input mr_5">
                  <p>Sub-district</p>
                  <DropdownSelect
                    className={'dropdown-select'}
                    dataList={destinationSubDistrictList}
                    onChange={(e) => handleChangeData(e, 'input_select', 'subdistrict_to')}
                    initValue={transferData?.to?.sub_district}
                    isError={validateInput.is_error_to_sub_district}
                  />
                </div>

                {/* <div className="wrp-input ml_5">
                  <p>Postcode</p>
                  <TextField
                    className={'text-field'}
                    pattern={'^[0-9]*[.,]?[0-9]*$'}
                    placeholder={'Postcode'}
                    disabled
                    // onChange={(e) => handleChangeData(e, 'seller_fax')}
                    value={transferData?.to?.post_code}
                    // error={validateInputData.is_error_seller_fax}
                  />
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="wrp-info-contnainer">
        <div className={'wrp_label' + (isPreview ? ' label_preview' : '')}>พาหนะและช่วงเวลาขนย้าย</div>
        {isPreview ? (
          <div className="wrp-transfering-info">
            <div>
              ยานพาหนะ {setVehicleName(transferData?.vehicle_type)}{' '}
              {transferData?.vehicle_type == 'อื่นๆ' ? transferData?.vehicle_name : ''}
            </div>
            <div>วันที่เริ่มต้นการขนย้าย {convertDateFormat(transferData?.start_date, 'DD-MM-YYYY')}</div>
            <div>วันที่สิ้นสุดการขนย้าย {convertDateFormat(transferData?.end_date, 'DD-MM-YYYY')}</div>
          </div>
        ) : (
          <div>
            <div className="row">
              <div className="w_50">
                <div className="wrp-input mr_5">
                  <p>พาหนะ</p>
                  <DropdownSelect
                    className={'dropdown-select'}
                    dataList={VEHICLE_LIST}
                    onChange={(e) => handleChangeData(e, 'input_select', 'transport_vehicle')}
                    initValue={transferData?.vehicle_type}
                    isError={validateInput.is_error_vehicle_type}
                  />
                </div>
              </div>

              {transferData?.vehicle_type == 'อื่นๆ' && (
                <div className="wrp-input mr_5">
                  <p>ระบุพาหนะ</p>
                  <TextField
                    className={'text-field'}
                    placeholder={'ระบุพาหนะ'}
                    // disabled={transferData?.transportation?.vehicle_type == 'อื่นๆ' ? false : true}
                    onChange={(e) => handleChangeData(e, 'input_text', 'vehicle_note')}
                    value={transferData?.vehicle_type == 'อื่นๆ' ? transferData?.vehicle_name : ''}
                    // error={validateInputData.is_error_seller_fax}
                  />
                </div>
              )}
            </div>

            <div className="row">
              <div className="wrp-input mr_5">
                <p>วันที่เริ่มต้นขนย้าย</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'input_date', 'start_date')}
                  value={transferData?.start_date}
                  error={validateInput.is_error_start_date}
                />
              </div>

              <div className="wrp-input mr_5">
                <p>วันที่สิ้นสุดขนย้าย</p>
                <Date
                  onChange={(e) => handleChangeData(e, 'input_date', 'end_date')}
                  value={transferData?.end_date}
                  error={validateInput.is_error_end_date}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {!isPreview ? (
        <div className="wrp-info-contnainer bd-top">
          <div className={'wrp_label'}>
            คำชี้แจงและเหตุผลในการขอรับหนังสืออนุญาตขนย้ายน้ำตาลทรายเพื่อเปลี่ยนสถานที่เก็บรักษา พร้อมหลักฐานประกอบ
            (ถ้ามี)
          </div>
          <p>หมายเหตุ</p>
          <InputTextArea
            className={'input-text-area'} //+ (validateInputData?.is_error_address ? ' error' : '')
            onChange={(e) => handleChangeData(e, 'input_text', 'note')}
            placeHolder="รายละเอียด"
            resizeDisable
            initValue={transferData?.note || ''}
          />
        </div>
      ) : (
        <div className="wrp-message">
          {transferData?.note && <div className="txt-note">{transferData?.note}</div>}
          <div>
            ขอยื่นคำขอนี้เพื่อขอรับหนังสืออนุญาตขนย้ายน้ำตาลทรายเพื่อเปลี่ยนสถานที่เก็บรักษา
            และขอรับรองว่าข้อความดังกล่าวข้างต้นถูกต้องและเป็นจริงทุกประการ
          </div>
        </div>
      )}
    </Styled>
  )
}
const Styled = styled.div`
  border-top: 1px solid var(--Gray-300);
  padding-top: 20px;
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .wrp_label {
    margin-bottom: 10px;
    font-weight: 600;
  }
  .label_preview {
    width: 40%;
    padding: 5px 10px;
    background-color: var(--Primary-700);
    color: var(--Base-White);
  }
  .wrp_input_container {
    width: 100%;
    display: flex;
    align-items: center;
    /* flex-shrink: 0;
    justify-content: flex-end; */
    .col_left {
      flex: 1;
      border: ${({ isPreview }) => (isPreview ? 'none' : '1px solid var(--Gray-300)')};
      border-radius: 8px;
      padding: 10px;
      margin-right: 10px;
    }
    .col_right {
      flex: 1;
      border: ${({ isPreview }) => (isPreview ? 'none' : '1px solid var(--Gray-300)')};
      border-radius: 8px;
      padding: 10px;
      margin-left: 10px;
    }
    .txt-title-header {
      font-weight: 600;
    }
    .wrp-input,
    .text-field,
    .dropdown-select {
      width: 100%;
    }
  }
  .wrp_checkbox_title_text {
    display: flex;
    justify-content: flex-end;
  }
  .checkbox {
    width: fit-content;
  }
  .checkbox label {
    margin: 0;
  }

  .text_value {
    text-align: end;
  }
  .font_weight_bold {
    font-weight: bold;
  }

  .wrp_calculate.preview {
    .col_left {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      text-align: left;
    }
    .wrp_checkbox_title_text {
      justify-content: flex-start;
    }
  }
  .row {
    display: flex;
    .wrp-input {
      flex: 1;
    }
    .dropdown-select,
    .text-field {
      width: 100%;
    }
  }
  .mr_5 {
    margin-right: 5px;
  }
  .ml_5 {
    margin-left: 5px;
  }
  .wrp-info-contnainer {
    margin-top: 20px;
    padding-top: 20px;
  }
  .input-text-area {
    font-size: 14px;
    height: 134px;
    border-color: var(--Gray-300);
    border-width: 1px;
    width: 420px;
  }
  .bd-top {
    border-top: 1px solid var(--Gray-300);
  }
  .wrp-transfering-info {
    padding: 10px;
  }
  .wrp-message {
    margin-top: 20px;
    padding: 10px;
  }
  .txt-note {
    margin-bottom: 10px;
  }
  .w_50 {
    width: 50% !important;
  }
`
