export default function IconFileTxt({ width = '40', color = '#D0D5DD', style }) {
  return (
    <svg width={width} viewBox="2 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
      <g filter="url(#filter0_d_4916_411647)">
        <path
          d="M10.75 4C10.75 2.20508 12.2051 0.75 14 0.75H30C30.1212 0.75 30.2375 0.798159 30.3232 0.883885L41.1161 11.6768C41.2018 11.7625 41.25 11.8788 41.25 12V36C41.25 37.7949 39.7949 39.25 38 39.25H14C12.2051 39.25 10.75 37.7949 10.75 36V4Z"
          fill="white"
          stroke={color}
          strokeWidth="1.5"
        />
        <path d="M30 0.5V8C30 10.2091 31.7909 12 34 12H41.5" stroke={color} strokeWidth="1.5" />
        <rect x="4" y="18" width="27" height="16" rx="2" fill="#344054" />
        <path
          d="M7.60121 23.995V22.7273H13.5742V23.995H11.3477V30H9.82777V23.995H7.60121ZM15.9996 22.7273L17.4663 25.206H17.5231L18.9968 22.7273H20.7333L18.5138 26.3636L20.783 30H19.0146L17.5231 27.5178H17.4663L15.9748 30H14.2134L16.4897 26.3636L14.256 22.7273H15.9996ZM21.4293 23.995V22.7273H27.4023V23.995H25.1758V30H23.6559V23.995H21.4293Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4916_411647"
          x="-1"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4916_411647" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4916_411647" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}
