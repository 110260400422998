import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DateDisplay } from '../../../utils/common'

import styled from 'styled-components'
import Card from '../../common/Card'
import SidePanel from '../../common/SidePanel'
import ButtonOutline from '../../form/button/ButtonOutline'
import EditBasicInfo from './EditBasicInfo'
import EditContactFamilyInfo from './EditContactFamilyInfo'
import EditBankInfo from './EditBankInfo'
import EditWorkPermitInfo from './EditWorkPermitInfo'

import DialogSuccess from '../../dialog/DialogSuccess'
import DialogFail from '../../dialog/DialogFail'
import DialogWarning from '../../dialog/DialogWarning'

import EmployeeSignatureModal from '../../signature-modal/EmployeeSignatureModal'

import pdfIcon from '../../../assets/images/pdf-icon.png'
import successIcon from '../../../assets/images/success-icon.png'
import failIcon from '../../../assets/images/fail-icon.png'
import signatureIcon from '../../../assets/images/signature-icon.png'
import warningIcon from '../../../assets/images/warning-icon.png'

import { LuPen } from 'react-icons/lu'
import { GrView } from 'react-icons/gr'

import { selectEmployeeProfile, selectSharedServiceEmployee } from '../../../redux/selectors'
import { getEmployeeSignature } from '../../../redux/actions/employeeProfile'

const Div = styled.div`
  .mr-10-px {
    margin-right: 10px;
  }
  .mr-12-px {
    margin-right: 12px;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mb-12-px {
    margin-bottom: 12px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }

  .file-link {
    cursor: pointer;
  }
`
const InfoContainer = styled.div`
  .heading-name {
    color: var(--Gray-900);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
`
const BlogInfo = styled.section`
  margin-bottom: 20px;
  .card-width-100 {
    width: 100%;
  }
  .heading-info {
    color: var(--Gray-900);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
  }
  .info-heading {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    width: 30%;
  }
  .info {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .info-2 {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    width: 65%;
  }
  .file {
    width: 65%;
  }
  .file-info {
    .name {
      color: var(--Gray-900);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .size {
      color: var(--Gray-600);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .edit-btn {
    padding: 8px 8px 6px;
  }
  .item-vertical-center {
    vertical-align: middle;
  }
  .signature-content {
    display: inline-block;

    .heading {
      color: var(--Gray-900);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .sub-heading {
      color: var(--Gray-600);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .image-container {
    height: 128px;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.align-item-center {
    align-items: center;
  }

  .flex-1 {
    flex: 1;
  }
`

function PersonalInformation() {
  // external hook
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const lang = localStorage.getItem('lang')

  // initiate data
  const fetchSignature = useCallback(() => {
    dispatch(getEmployeeSignature())
  }, [dispatch])
  useEffect(() => {
    fetchSignature()
  }, [])

  // employee profile info
  const { employeeProfileInfo, employeeSignature } = useSelector(selectEmployeeProfile)

  // isLoading & state & success,fail modal
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const { isLoading, state } = useSelector(selectEmployeeProfile)
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  useEffect(() => {
    if (isLoading === false && state === 'REQUEST_EDIT_EMPLOYEE_PROFILE_INFO.SUCCESS') {
      setSuccessMessageModal({
        headline: 'Edit request successfully sent',
        message: `Your edits are under review. The verification process ensures accuracy and security. Please allow some time for our Human Resources Department to validate and approve your changes`,
      })
      setSuccessModal(true)
    }
    if (isLoading === false && state === 'EDIT_EMPLOYEE_PROFILE_INFO.SUCCESS') {
      setSuccessMessageModal({
        headline: 'Edit Successful',
        message: `Your changes have been saved successfully. Review your updated details to ensure accuracy`,
      })
      setSuccessModal(true)
    }

    if (isLoading === false && state === 'UPLOAD_EMPLOYEE_SIGNATURE.SUCCESS') {
      setSuccessMessageModal({
        headline: 'Upload Completed',
        message: `You have successfully created signature`,
      })
      setSuccessModal(true)
      setSignatureModal(false)
    }
    if (
      (isLoading === false && state === 'UPLOAD_EMPLOYEE_SIGNATURE.FAILURE') ||
      state === 'UPLOAD_SIGNATURE_IMAGE.FAILURE'
    ) {
      setFailMessageModal({
        headline: 'Upload Failed',
        message: ``,
      })
      setFailModal(true)
      setSignatureModal(false)
    }
  }, [isLoading, state])

  // edit basic info modal
  const [editBasicInfoModal, setEditBasicInfoModal] = useState(false)

  const openEditBasicInfoModal = () => {
    setEditBasicInfoModal(true)
  }
  const closeEditBasicInfoModal = () => {
    setEditBasicInfoModal(false)
  }

  // edit contact family info modal
  const [editContactFamilyInfoModal, setEditContactFamilyInfoModal] = useState(false)

  const openEditContactFamilyModal = () => {
    setEditContactFamilyInfoModal(true)
  }
  const closeEditContactFamilyModal = () => {
    setEditContactFamilyInfoModal(false)
  }

  // edit bank info modal
  const [editBankInfoModal, setEditBankInfoModal] = useState(false)

  const openEditBankInfoModal = () => {
    setEditBankInfoModal(true)
  }
  const closeEditBankInfoModal = () => {
    setEditBankInfoModal(false)
  }

  // edit work permit info modal
  const [editWorkPermitInfoModal, setEditWorkPermitInfoModal] = useState(false)

  const openEditWorkPermitInfoModal = () => {
    setEditWorkPermitInfoModal(true)
  }
  const closeEditWorkPermitInfoModal = () => {
    setEditWorkPermitInfoModal(false)
  }

  // signature modal
  const [signatureModal, setSignatureModal] = useState(false)

  const openSignatureModal = () => {
    setSignatureModal(true)
  }
  const closeSignatureModal = () => {
    setSignatureModal(false)
  }

  // warning modal
  const initTextWarning = {
    headline: t('warning_upload_sign_heading'),
    description: t('warning_upload_sign_sub_heading'),
  }
  const [warningText] = useState(initTextWarning)
  const [isWarningModal, setIsWarningModal] = useState(false)

  // other function
  const openNewTab = (url) => {
    window.open(url, '_blank')
  }

  return (
    <Div>
      <InfoContainer className="mb-32-px">
        <h3 className="heading-name">Personal information</h3>
        <div>View and update your details using the Edit button.</div>
      </InfoContainer>

      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">Basic Information</h4>
        </header>
        <Card className="card-width-100">
          <FlexContainer className="align-item-center mb-12-px">
            <div className="info-heading">Employee ID</div>
            <div className="info-2">{employeeProfileInfo?.employ_id}</div>
            <ButtonOutline className="edit-btn" onClick={openEditBasicInfoModal}>
              <LuPen size={18} />
            </ButtonOutline>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Title</div>
            <div className="info">{employeeProfileInfo?.title}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">First name(EN)</div>
            <div className="info">{employeeProfileInfo?.first_name}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Last name(EN)</div>
            <div className="info">{employeeProfileInfo?.last_name}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">First name(TH)</div>
            <div className="info">{employeeProfileInfo?.first_name_th ?? '-'}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Last name(TH)</div>
            <div className="info">{employeeProfileInfo?.last_name_th ?? '-'}</div>
          </FlexContainer>
          <FlexContainer>
            <div className="info-heading">Address</div>
            <div className="info">{employeeProfileInfo?.address ?? '-'}</div>
          </FlexContainer>
        </Card>
      </BlogInfo>

      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">Citizenship Information</h4>
        </header>
        <Card className="card-width-100">
          <FlexContainer className="align-item-center mb-12-px">
            <div className="info-heading">Nationality</div>
            <div className="info-2">{employeeProfileInfo?.nationality?.name ?? '-'}</div>
            <ButtonOutline className="edit-btn" onClick={openEditBasicInfoModal}>
              <LuPen size={18} />
            </ButtonOutline>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Date of Birth</div>
            <div className="info">{DateDisplay(employeeProfileInfo?.birth_date)}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Identification no.</div>
            <div className="info">{employeeProfileInfo?.identification_no ?? '-'}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Passport no.</div>
            <div className="info">{employeeProfileInfo?.passport_no ?? '-'}</div>
          </FlexContainer>
          <FlexContainer>
            <div className="info-heading">Passport expire date</div>
            <div className="info">{DateDisplay(employeeProfileInfo?.passport_expired) ?? '-'}</div>
          </FlexContainer>
        </Card>
      </BlogInfo>

      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">Contact Information</h4>
        </header>
        <Card className="card-width-100">
          <FlexContainer className="align-item-center mb-12-px">
            <div className="info-heading">Phone number</div>
            <div className="info-2">{employeeProfileInfo?.phone_no ?? '-'}</div>
            <ButtonOutline className="edit-btn" onClick={openEditContactFamilyModal}>
              <LuPen size={18} />
            </ButtonOutline>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Email</div>
            <div className="info">{employeeProfileInfo?.email ?? '-'}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Emergency contact</div>
            <div className="info">{employeeProfileInfo?.emergency_contact ?? '-'}</div>
          </FlexContainer>
          <FlexContainer>
            <div className="info-heading">Emergency Phone</div>
            <div className="info">{employeeProfileInfo?.emergency_phone_no ?? '-'}</div>
          </FlexContainer>
        </Card>
      </BlogInfo>

      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">Family Information</h4>
        </header>
        <Card className="card-width-100">
          <FlexContainer className="align-item-center mb-12-px">
            <div className="info-heading">Marital status</div>
            <div className="info-2">{employeeProfileInfo?.martial_status ?? '-'}</div>
            <ButtonOutline className="edit-btn" onClick={openEditContactFamilyModal}>
              <LuPen size={18} />
            </ButtonOutline>
          </FlexContainer>
          <FlexContainer>
            <div className="info-heading">Number of children</div>
            <div className="info">{employeeProfileInfo?.number_of_children ?? '-'}</div>
          </FlexContainer>
        </Card>
      </BlogInfo>

      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">Bank Account</h4>
        </header>
        <Card className="card-width-100">
          <FlexContainer className="align-item-center mb-12-px">
            <div className="info-heading">Bank account</div>
            <div className="info-2">
              {lang === 'en' ? employeeProfileInfo?.bank?.name_en : employeeProfileInfo?.bank?.nam_th}
            </div>
            <ButtonOutline className="edit-btn" onClick={openEditBankInfoModal}>
              <LuPen size={18} />
            </ButtonOutline>
          </FlexContainer>
          <FlexContainer>
            <div className="info-heading">Bank account no.</div>
            <div className="info">{employeeProfileInfo?.bank_account_no ?? '-'}</div>
          </FlexContainer>
        </Card>
      </BlogInfo>

      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">Work Permit</h4>
        </header>
        <Card className="card-width-100">
          <FlexContainer className="align-item-center mb-12-px">
            <div className="info-heading">Visa no.</div>
            <div className="info-2">{employeeProfileInfo?.visa_no ?? '-'}</div>
            <ButtonOutline className="edit-btn" onClick={openEditWorkPermitInfoModal}>
              <LuPen size={18} />
            </ButtonOutline>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Visa expire date</div>
            <div className="info">{DateDisplay(employeeProfileInfo?.visa_expire_date) ?? '-'}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Work permit no.</div>
            <div className="info">{employeeProfileInfo?.work_permit_no ?? '-'}</div>
          </FlexContainer>
          <FlexContainer className="mb-12-px">
            <div className="info-heading">Work permit expire date</div>
            <div className="info">{DateDisplay(employeeProfileInfo?.workpermit_expire_date) ?? '-'}</div>
          </FlexContainer>
          <FlexContainer>
            <div className="info-heading">File</div>
            {employeeProfileInfo?.workpermit_file ? (
              <FlexContainer
                className="file-link"
                onClick={() => openNewTab(employeeProfileInfo?.workpermit_file?.url)}
              >
                <img className="mr-12-px" src={pdfIcon} alt="employee-file" />
                <div className="file-info">
                  <div className="name">{employeeProfileInfo?.workpermit_file?.alternativeText}</div>
                  <div className="size">{employeeProfileInfo?.workpermit_file?.size} MB</div>
                </div>
              </FlexContainer>
            ) : (
              '-'
            )}
          </FlexContainer>
        </Card>
      </BlogInfo>

      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">Resume</h4>
        </header>
        <Card className="card-width-100">
          <FlexContainer className="align-item-center">
            <div className="info-heading">Resume</div>
            {employeeProfileInfo?.employee_resumes ? (
              <React.Fragment>
                <FlexContainer className="file">
                  <img className="mr-12-px" src={pdfIcon} alt="employee-file" />
                  <div className="file-info">
                    <div className="name">{employeeProfileInfo?.employee_resumes?.alternativeText}</div>
                    <div className="size">{employeeProfileInfo?.employee_resumes?.size} MB</div>
                  </div>
                </FlexContainer>
                <ButtonOutline onClick={() => openNewTab(employeeProfileInfo?.employee_resumes?.url)}>
                  <GrView />
                </ButtonOutline>
              </React.Fragment>
            ) : (
              '-'
            )}
          </FlexContainer>
        </Card>
      </BlogInfo>

      <BlogInfo>
        <header className="mb-8-px">
          <h4 className="heading-info">Signature</h4>
        </header>
        <Card className="card-width-100">
          <FlexContainer className="align-item-center">
            <div className="info-heading">Signature</div>
            <FlexContainer className="flex-1 justify-content-space-between">
              {employeeSignature[0]?.signature?.url ? (
                <img className="image-container" src={employeeSignature[0]?.signature?.url} alt="current-signature" />
              ) : (
                <div>
                  <img className="item-vertical-center mr-10-px" src={signatureIcon} alt="signature icon" />
                  <div className="signature-content item-vertical-center">
                    <div className="heading">No signature found</div>
                    <div className="sub-heading">Start making new one</div>
                  </div>
                </div>
              )}

              <ButtonOutline className="edit-btn" onClick={openSignatureModal}>
                <LuPen size={18} />
              </ButtonOutline>
            </FlexContainer>
          </FlexContainer>
        </Card>
      </BlogInfo>

      {/* Side panel */}
      <SidePanel isOpen={editBasicInfoModal} setIsOpen={openEditBasicInfoModal} width={900}>
        <EditBasicInfo closeModal={closeEditBasicInfoModal} />
      </SidePanel>
      <SidePanel isOpen={editContactFamilyInfoModal} setIsOpen={openEditContactFamilyModal} width={900}>
        <EditContactFamilyInfo closeModal={closeEditContactFamilyModal} />
      </SidePanel>
      <SidePanel isOpen={editBankInfoModal} setIsOpen={openEditBankInfoModal} width={900}>
        <EditBankInfo closeModal={closeEditBankInfoModal} />
      </SidePanel>
      <SidePanel isOpen={editWorkPermitInfoModal} setIsOpen={openEditWorkPermitInfoModal} width={900}>
        <EditWorkPermitInfo closeModal={closeEditWorkPermitInfoModal} />
      </SidePanel>

      {/* Dialog */}
      <DialogSuccess
        open={successModal}
        onClose={() => setSuccessModal(false)}
        onSubmit={() => setSuccessModal(false)}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
      <DialogWarning
        open={Boolean(isWarningModal)}
        onClose={() => setIsWarningModal(false)}
        onSubmit={() => setIsWarningModal(false)}
        icon={warningIcon}
        title={warningText.headline}
        description={warningText.description}
        textYes={t('ok')}
      />

      {/* Modal */}
      <EmployeeSignatureModal
        open={signatureModal}
        onClose={closeSignatureModal}
        setIsWarningModal={setIsWarningModal}
      />
    </Div>
  )
}

export default PersonalInformation
