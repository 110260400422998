import { useState } from 'react'
import styled from 'styled-components'
import Table from '../../../../common/Table'
import Date from '../../../../form/Date'
import Dropdown from '../../../../form/Dropdown'
import Button from '../../../../form/button/Button'
import ButtonOutline from '../../../../form/button/ButtonOutline'
import IconSearch from '../../../../widget/icon/IconSearch'
import { useTranslation } from 'react-i18next'
export default function PurchaseOrderTemplate() {
  const { t } = useTranslation()
  const [statusSelected, setStatusSelected] = useState('supplier')
  const [date, setDate] = useState()
  const [selectUom, setSelectUom] = useState('uom')
  const [selectedProduct, setSelectedProduct] = useState('select_product')
  const [isSelectAll, setIsSelectAll] = useState(false)

  const statusList = [
    {
      text: 'Supplier',
      value: 'supplier',
    },
    {
      text: 'Other',
      value: 'other',
    },
  ]
  const selectProduct = [
    {
      text: 'Select Product',
      value: 'select_product',
    },
    {
      text: 'Other',
      value: 'other',
    },
  ]
  const uom = [
    {
      text: 'UOM',
      value: 'uom',
    },
    {
      text: 'Other',
      value: 'other',
    },
  ]

  const onChangeSelectAllCheckBox = (value) => {
    
    setIsSelectAll(value)
  }

  const headerList = [
    {
      Header: 'Product Name',
      accessor: 'product_name',
      disableSortBy: false,
    },
    {
      Header: 'SKU',
      accessor: 'sku',
      disableSortBy: false,
    },
    {
      Header: 'UOM',
      accessor: 'uom',
      disableSortBy: false,
    },
    {
      Header: 'Unit Price',
      accessor: 'unit_price',
      disableSortBy: false,
    },
    {
      Header: 'Total Price',
      accessor: 'total_price',
      disableSortBy: false,
    },
    {
      Header: 'Action',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const [dataList, setDataList] = useState([
    {
      id: '1',
      product_name: 'xxx',
      sku: 'yyy',
      uom: 'true',
      unit_price: 'zzz',
      total_price: 'xxx',
      action: 'xxx',
    },
  ])
  const onChangeDate = (value) => {
    setDate(value)
  }

  return (
    <Styled>
      <div className="wrp-document-content">
        <div className="wrp-tool-purchase-order">
          <div className="title-tool-select">{t('procurement')}</div>

          <div className="wrp-select-class-date">
            <div className="txt-select-class-date">{t('warehouse')}</div>
            <Dropdown optionList={statusList} value={statusSelected} onChange={(value) => setStatusSelected(value)} />

            <div className="txt-select-class-date">{t('shipping_date')}</div>
            <Date value={date} onChange={onChangeDate} />

            <div className="txt-select-class-date">{t('supplier')}</div>
            <Dropdown optionList={statusList} value={statusSelected} onChange={(value) => setStatusSelected(value)} />

            <div className="txt-select-class-date">{t('receiving_date')}</div>
            <Date value={date} onChange={onChangeDate} />
          </div>

          <div className="wrp-tool">
            <div className="left-tool">
              <div className="dropdown-search">
                <Dropdown
                  optionList={selectProduct}
                  value={selectedProduct}
                  onChange={(value) => setSelectedProduct(value)}
                />
                <div className="icon-search">
                  <IconSearch width="20px" color="#888" />
                </div>
              </div>

              <Dropdown optionList={uom} value={selectUom} onChange={(value) => setSelectUom(value)} />
              <ButtonOutline>{t('quantity')}</ButtonOutline>
              <ButtonOutline>{t('unit_price')}</ButtonOutline>
              <Button>{t('add_item')}</Button>
            </div>
            <div className="right-tool">
              <Button className="btn-preview">{t('preview')}</Button>
              <Button>{t('generate_document')}</Button>
            </div>
          </div>
        </div>

        <div className="wrp-table">

          {/* แก้ Table */}
          <Table columns={headerList} data={dataList} />
        </div>
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  .dropdown-search {
    display: flex;
    position: relative;
    .MuiSelect-root {
      padding: 0 10px 0 40px;
    }
    .icon-search {
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }

  .wrp-tool-purchase-order {
    padding: 1rem 2rem;
  }
  .title-tool-select {
    font-weight: bold;
    font-size: 18px;
  }
  .wrp-select-class-date {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem 1rem;
    align-items: center;

    margin: 1rem 0;
  }
  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .icon-search {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left-tool {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-column-gap: 0.5rem;
  }
  .right-tool {
    .btn-preview {
      margin-right: 0.5rem;
    }
  }
  .wrp-table {
    /* margin: 1rem 0; */
  }
`
