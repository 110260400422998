import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { selectMaster, selectEmployeeActivity } from '../../../redux/selectors'
import { getEmployeeList } from '../../../redux/actions/employeeActivity'
import { getDepartmentsAndPositionsUseAuthen } from '../../../redux/actions/master'

import styled from 'styled-components'
import Table from '../../common/Table'
import ButtonOutline from '../../form/button/ButtonOutline'
import Dropdown from '../../form/Dropdown'
import SearchText from '../../form/SearchText'

import { LiaTimesSolid } from 'react-icons/lia'

// style
const Wrapper = styled.div`
  .icon {
    cursor: pointer;
  }
  .badge {
    border-radius: 16px;
    border: 1px solid var(--BADGE-1-Border);
    background: var(--BADGE-1-Bg);

    color: var(--BADGE-1-Font);
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;

    display: inline-block;
    padding: 2px 8px;

    &.pink-badge {
      border: 1px solid var(--BADGE-12-Border);
      background: var(--BADGE-12-Bg);
      color: var(--BADGE-12-Font);
    }

    &.blue-badge {
      border: 1px solid var(--BADGE-5-Border);
      background: var(--BADGE-5-Bg);
      color: var(--BADGE-5-Font);
    }

    &.green-badge {
      border: 1px solid var(--BADGE-4-Border);
      background: var(--BADGE-4-Bg);
      color: var(--BADGE-4-Font);
    }
    &.red-badge {
      border: 1px solid var(--BADGE-2-Border);
      background: var(--BADGE-2-Bg);
      color: var(--BADGE-2-Font);
    }
  }
  .table-wrapper {
    .table-header {
      padding: 1rem 0.875rem;
      border-radius: 0.5rem 0.5rem 0 0;
      background: var(--Base-White);
      border: 0.0625rem solid var(--Gray-200);

      .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.25rem;

        .table-header-name {
          color: var(--Gray-900);
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          margin-right: 0.5rem;
        }
      }

      .search-container {
        display: flex;
        .clear-button-container {
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }
  .img-container {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  .name-employee {
    color: var(--Gray-900);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`
const InputContainer = styled.div`
  margin-right: 0.75rem;
  &.width-20 {
    width: 20%;
  }
  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.375rem;
  }
`
const BadgeSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .badge {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`

// initial data
const initEmployeeTypeList = [
  {
    value: 'Permanent',
    text: 'Employee',
  },
  {
    value: 'Probation',
    text: 'Probation',
  },
]
const initEvaluationResultList = [
  {
    value: 'Pass',
    text: 'Pass',
  },
  {
    value: 'Not Pass',
    text: 'Not Pass',
  },
]

function EmployeeList() {
  // column
  const employeeListColumn = [
    {
      Header: 'No',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: 'Department',
      accessor: 'department',
      disableSortBy: false,
    },
    {
      Header: 'Position',
      accessor: 'position',
      disableSortBy: false,
    },
    {
      Header: 'Employee type',
      accessor: 'employee_type',
      disableSortBy: false,
    },
    {
      Header: 'Score',
      accessor: 'overall_perf_score',
      disableSortBy: false,
    },
    {
      Header: 'Evaluate result',
      accessor: 'performance_eva',
      disableSortBy: false,
    },
    {
      Header: 'Analysis and Recommendation',
      accessor: 'detail_analysis',
      disableSortBy: false,
    },
    {
      accessor: 'action',
      disableSortBy: false,
    },
  ]

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // store reducer
  const { employeeList, page, pageCount, total, limit } = useSelector(selectEmployeeActivity)
  const { departments } = useSelector(selectMaster)

  // data
  const [employees, setEmployees] = useState([])
  // dropdown list
  const [departmentList, setDepartmentList] = useState([])
  const [positionList, setPositionList] = useState([])
  // filter
  const [search, setSearch] = useState('')
  const [department, setDepartment] = useState('')
  const [position, setPosition] = useState('')
  const [employeeType, setEmployeeType] = useState('')
  const [evaluationResult, setEvaluationResult] = useState('')
  const [searchFilters, setSearchFilters] = useState([])

  const fetchData = useCallback(
    (filter) => {
      dispatch(getEmployeeList(filter))
    },
    [dispatch]
  )

  // useEffect for fetching data
  useEffect(() => {
    const filterForEmployeeList = {
      page: 1,
      pageSize: limit,
    }
    fetchData(filterForEmployeeList)
    dispatch(getDepartmentsAndPositionsUseAuthen())
  }, [])

  // useEffect for mapping employee table data
  useEffect(() => {
    const newEmployees = employeeList.map((v, i) => ({
      ...v,
      no: i + 1,
      name: (
        <div className="img-container">
          <img className="mr-8-px" src={v.photo} alt={`member-img-${v.id}`} />
          <span className="name-employee" onClick={() => goToEmployeeDetail(v.id)}>
            {v.first_name} {v.last_name}
          </span>
        </div>
      ),
      employee_type: (
        <div className={`badge ${v.employee_type === 'Probation' ? 'blue-badge' : 'pink-badge'}`}>
          {v.employee_type === 'Permanent' ? 'Employee' : v.employee_type}
        </div>
      ),
      performance_eva: v.performance_eva ? (
        <div className={`badge ${v.performance_eva === 'Pass' ? 'green-badge' : 'red-badge'}`}>{v.performance_eva}</div>
      ) : (
        '-'
      ),
      detail_analysis: truncateText(v.detail_analysis, 30),
    }))

    setEmployees(newEmployees)
  }, [employeeList])

  // useEffect for mapping department option list dropdown
  useEffect(() => {
    const newDepartmentList = departments.map((v) => ({
      value: v.id,
      text: v.name,
      positions: v.positions.map((v2) => ({
        text: v2.name,
        value: v2.id,
      })),
    }))
    setDepartmentList(newDepartmentList)
  }, [departments])

  // useEffect for mapping position option list dropdown
  useEffect(() => {
    if (department !== '') {
      const { positions } = departmentList.find((v) => v.value === department)
      setPositionList(positions)
    }
  }, [department])

  // useEffect for filtering by dropdown
  useEffect(() => {
    if (department !== '' || position !== '' || employeeType !== '' || evaluationResult !== '') {
      const multipleSearch = searchFilters.map((v) => v.name).join(',')
      const request = {
        page: 1,
        pageSize: limit,
        department: department,
        position: position,
        employee_type: employeeType,
        evaluation: evaluationResult,
        name: multipleSearch,
      }
      fetchData(request)
    }
  }, [department, position, employeeType, evaluationResult])

  // filter
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v) => v.name).join(',')
      const filter = {
        page: 1,
        pageSize: limit,
        department: department,
        position: position,
        employee_type: employeeType,
        evaluation: evaluationResult,
        name: multipleSearch,
      }
      fetchData(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v) => v.name).join(',')
    const filter = {
      page: 1,
      pageSize: limit,
      department: department,
      position: position,
      employee_type: employeeType,
      evaluation: evaluationResult,
      name: multipleSearch,
    }
    fetchData(filter)
    setSearch('')
  }
  const onClearSearch = () => {
    const request = {
      page: 1,
      pageSize: limit,
      department: '',
      position: '',
      employee_type: '',
      evaluation: '',
    }
    fetchData(request)
    clearFilter()
  }
  const clearFilter = () => {
    setSearch('')
    setDepartment('')
    setPosition('')
    setEmployeeType('')
    setEvaluationResult('')
    setSearchFilters([])
  }
  const onChangePagination = async (value) => {
    const multipleSearch = searchFilters.map((v) => v.name).join(',')
    const filter = {
      page: value.page,
      pageSize: limit,
      department: department,
      position: position,
      employee_type: employeeType,
      evaluation: evaluationResult,
      name: multipleSearch,
    }
    fetchData(filter)
  }

  // general
  const goToEmployeeDetail = (id) => {
    navigate(`/dashboard/employee-activities/${id}`)
  }
  const truncateText = (text, length) => {
    if (text.length > length) {
      return text.substring(0, length) + '...'
    } else {
      return text
    }
  }

  return (
    <Wrapper>
      <div className="table-wrapper">
        <div className="table-header">
          <div className="header-container">
            <div>
              <span className="table-header-name">Employee list</span>
              <span className="badge">{total} users</span>
            </div>
          </div>
          <div className="search-container mb-1-rem">
            <InputContainer>
              <div className="input-header">Search</div>
              <SearchText
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSubmit={onSearch}
                onClear={() => setSearch('')}
              />
            </InputContainer>
            <div className="clear-button-container">
              <ButtonOutline onClick={onClearSearch}>Clear all</ButtonOutline>
            </div>
          </div>

          <div className="search-container mb-1-rem">
            <InputContainer className="width-20">
              <div className="input-header">Department</div>
              <Dropdown optionList={departmentList} value={department} onChange={(value) => setDepartment(value)} />
            </InputContainer>
            <InputContainer className="width-20">
              <div className="input-header">Position</div>
              <Dropdown optionList={positionList} value={position} onChange={(value) => setPosition(value)} />
            </InputContainer>
            <InputContainer className="width-20">
              <div className="input-header">Employee type</div>
              <Dropdown
                optionList={initEmployeeTypeList}
                value={employeeType}
                onChange={(value) => setEmployeeType(value)}
              />
            </InputContainer>
            <InputContainer className="width-20">
              <div className="input-header">Evaluation result</div>
              <Dropdown
                optionList={initEvaluationResultList}
                value={evaluationResult}
                onChange={(value) => setEvaluationResult(value)}
              />
            </InputContainer>
          </div>
          <BadgeSearchContainer>
            {searchFilters.map((v) => (
              <div className="badge" key={v.key}>
                <span className="name">{v.name}</span>{' '}
                <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
              </div>
            ))}
          </BadgeSearchContainer>
        </div>
        <Table
          columns={employeeListColumn}
          data={employees}
          page={page}
          pageCount={pageCount}
          onStateChange={onChangePagination}
        />
      </div>
    </Wrapper>
  )
}

export default EmployeeList
