import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getCompanyInfo,DateDisplay,TimeDisplayHHmm } from '../../utils/common'

import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import iconPlus from '../../assets/images/icon-plus.svg'
import userIconQuesTion from '../../assets/images/question_icon.png'

import InputTextAreaV2024 from '../../components/widget/element/InputTextAreaV2024'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import iconDelete from '../../assets/images/icon-bin.svg'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import TextField from '../../components/form/TextField'
import TextFieldTime from '../../components/form/TextFieldTime'
import question from '../../assets/images/question.png'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'



import { createMeeting,MeetingCheckRoom } from '../../redux/actions/dailysummary'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'
import LinkIcon from '../../assets/images/link_meeting.png'
import mapIcon from '../../assets/images/gg_map.png'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
.css_header_detail {
display: flex;
-webkit-box-pack: justify;
justify-content: space-between;
font-size: 14px;
    .date_css{
        color: #7F56D9;
        font-weight: bold;
    }
} 
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .content_header {
    /*padding: 14px 14px;*/
    padding-left: 14px;
    padding-right: 14px;
    padding-top: 14px;

    width: ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 140px;
    padding-bottom: 106px;
    .title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
    }
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .div_table {
    padding: 15px;
  }
  .resp-table {
    width: 100%;
    display: table;
  }
  .resp-table-body {
    display: table-row-group;
  }
  .resp-table-row {
    display: table-row;
  }
  .table-body-head_cell {
    display: table-cell;
    border: 1px solid #D0D5DD;
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    text-align: center;
    font-weight: 600;
    background-color: #F2F4F7;
    color: #475467;
    font-size: 12px;
  }
  .table-body-cell {
    display: table-cell;
    border: 1px solid #dddddd;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: center;
  }
  .width_time {
    /*width: 30%;*/
    width: 20%;
  }
  .width_activity {
    width: 37%;
  }
  .width_result {
    width: 37%;
  }
  .width_del {
    width: 6%;
    text-align: center;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dd_css {
    margin: 0px 5px;
  }
  .basic-Information-detail {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    padding-top: 10px;
    .topic_fill{
      font-size: 14px;
      color: #212121;
      margin-bottom: 5px;
    }
    .topic_detail{
      font-size: 14px;
      color: #757575;
    }
  }
  .css_calendar {
    margin-left: 15px;
  }
  .padding-detail{
    padding: 0px 14px;
  }
  .border_top_left_radius{
    border-top-left-radius: 8px;
  }
  .border_top_right_radius{
    border-top-right-radius: 8px;
  }
  .border_bottom_left_radius{
    border-bottom-left-radius:8px
  }
  .border_bottom_right_radius{
    border-bottom-right-radius: 8px;
  }
  .css_time_text{
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
  }
  .TextField_css{
    width: 100%;
  }
.wrp-tab-menu {
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: flex;

  .btn-menu-style {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      border-bottom: 2px solid #EAECF0;
      color: #667085;
      font-size: 14px;
  }

  .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      border-bottom: 2px solid var(--Primary-600) !important;
      color: #6941C6;
      background-color: #F9F5FF;
  }
}
.input-new-2 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr repeat(1, auto);
    grid-template-rows: auto;
    align-content: center;
}
.TextField_employee{
    width : 100%;
  }
.box_reserve_meeting{
  background-color:#F2F4F7;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 16px;
}
.topic_fill_warning{
  font-size: 14px;
  color: #475467;
}
.prefix_icon{
  display: flex;
  align-items: center;
  margin-left: 5px;
}
`

const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 14px;
  color: #F04438;
`

const NewMeeting = ({ onClose, onSubmit,onReserveSpace,MeetingData,setMeetingData,...props }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const CompanyInfo = getCompanyInfo()

  const [currentDate, setcurrentDate] = useState(dayjs().format('YYYY-MM-DD'))
  const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
  const [EmployeeIdCalendar, setEmployeeIdCalendar] = useState(employee_info?.id ? employee_info?.id : -99)

  const errorMessage = '';
  const editable = true;

  //--- ST TabMenu
  const [tabactive, setTabactive] = useState('information')
  const [menuTabList, setMenuTabList] = useState([
    {
      name: t('information'),
      value: 'information',
      active_status: true,
    },
  ])
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }
  //--- ED TabMenu
  //const [MeetingData, setMeetingData] = useState(DataMeeting);
  const MeetingType_option = [
    { text: 'Online', value: 'Online' },
    { text: 'Onsite', value: 'Onsite' },
    { text: 'Customer Onsite', value: 'Customer Onsite' },
  ]
  const contact_person_option = [
    { text: 'Mr.A', value: 'Mr.A' },
    { text: 'Mr.B', value: 'Mr.B' },
  ]
  const Project_option = [
    { text: 'ProjectA', value: 'ProjectA' },
    { text: 'ProjectB', value: 'ProjectB' },
  ]
  const onMeetingType = (v) => {
    setMeetingData({ ...MeetingData, meeting_type: v })
  }
  const OpenModalReserve = () => {
    onReserveSpace()
  }
  const onChangecurrentDate = (value) => {
    setMeetingData({ ...MeetingData, meeting_date: value })
  }
  const onContactPerson = (v) => {
    setMeetingData({ ...MeetingData, contact_person: v })
  }
  const onProject = (v) => {
    setMeetingData({ ...MeetingData, project: v })
  }
  const OpenModalInviteGuest = () => {
    
    alert('OpenModalInviteGuest')
  }


  //--- ST Submit
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [Description, setDescription] = useState('')
  const ConfirmDialog = () => {

    if(EmployeeIdCalendar < 0){
      setTitleFail(t('you_may_not_fully_use_this_feature_since_you_are_not_employee'));
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }else{
      if (isValid().length == 0) {
         //--- เช็คห้องประชุมว่ามีว่างไหม
         if(MeetingData.meeting_room){
          const meeting_date_start = dayjs(MeetingData.meeting_date  + ' ' + MeetingData.time_start.replace(".", ":") + ':00').format('YYYY-MM-DD HH:mm:ss');
          const meeting_date_end = dayjs(MeetingData.meeting_date  + ' ' + MeetingData.time_end.replace(".", ":") + ':00').format('YYYY-MM-DD HH:mm:ss');
          const sent_data_api = {
            data: {
              meeting_room: MeetingData.meeting_room,
              meeting_date: MeetingData.meeting_date,
              meeting_date_start: meeting_date_start,
              meeting_date_end: meeting_date_end,
            },
          }
          dispatch(MeetingCheckRoom(sent_data_api)).then(({ type,data_info }) => {
            //alert(type)
            if(type.endsWith('_SUCCESS')){
              if(data_info.status == 200){
                setDescription(dayjs(currentDate).format('D MMM YYYY'))
                setModal_NameItem(MeetingData.title)
                setModal_Description(dayjs(MeetingData.meeting_date).format('D MMM YYYY') + ' ' + MeetingData.time_start + ' - ' + MeetingData.time_end)
                setModalConfirmDialog(true)
              }else{
                const temp_nameItemFail2 = DateDisplay(data_info.data_info[0].meeting_date) + ' ' + TimeDisplayHHmm(data_info.data_info[0].meeting_date_start) + ' - ' + TimeDisplayHHmm(data_info.data_info[0].meeting_date_end);
                setTitleFail(t('this_meeting_space_has_been_booked'));
                setnameItemFail(data_info.data_info[0].meeting_room_name);
                setnameItemFail2(temp_nameItemFail2)
                setDescriptionFail(t('please_make_change')); 
                setFailModal(true)
              }
            }else{
              setTitleFail(t('meeting_checkroom_error'));
              setnameItemFail('');
              setnameItemFail2('')
              setDescriptionFail(''); 
              setFailModal(true)
            }
          })
         }else{
          setDescription(dayjs(currentDate).format('D MMM YYYY'))
          setModal_NameItem(MeetingData.title)
          setModal_Description(dayjs(MeetingData.meeting_date).format('D MMM YYYY') + ' ' + MeetingData.time_start + ' - ' + MeetingData.time_end)
          setModalConfirmDialog(true)
         }



      }
    }

    
  }

  const [MeetingDataError, setMeetingDataError] = useState({

    title: {
      show_errorMessage: false,
      errorMessage: t('please_fill_in_tiltle_of_this_meeting'),
    },
    
  })

  const isValid = () => {
    let data_valid = MeetingData
    let data_check_valid = MeetingDataError
    let errors = []
    Object.keys(MeetingDataError).forEach((k) => {
      let data = data_valid[k]
      if(data_check_valid[k].errorMessage != ''){
        if (!data || data < 0) {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
        } else {
          data_check_valid[k].show_errorMessage = false
        }
      }

    })
    setMeetingDataError({ ...MeetingDataError })
    return errors
  }

  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [nameItemFail2, setnameItemFail2] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)
  const [Modal_title, setModal_title] = useState(t('confirm_create_meeting_header'))
  const [Modal_NameItem, setModal_NameItem] = useState('')
  const [Modal_Description, setModal_Description] = useState('')

  const onCreateMeeting = () => {
    setModalConfirmDialog(false)
    //alert(JSON.stringify(MeetingData))
    //--- CALL API Create
    const sent_data_api = {
      data: {
        meeting_type : MeetingData.meeting_type,
        meeting_room_id : MeetingData.meeting_room,
        meeting_room : MeetingData.meeting_room,
        title : MeetingData.title,
        meeting_no : MeetingData.meeting_no,
        meeting_date : MeetingData.meeting_date,
        meeting_date_start : dayjs(MeetingData.meeting_date  + ' ' + MeetingData.time_start.replace(".", ":") + ':00').format('YYYY-MM-DD HH:mm:ss'),
        meeting_date_end : dayjs(MeetingData.meeting_date  + ' ' + MeetingData.time_end.replace(".", ":") + ':00').format('YYYY-MM-DD HH:mm:ss'),
        online_meeting_link : MeetingData.online_meeting_link,
        online_meeting_id : MeetingData.online_meeting_id,
        online_meeting_code : MeetingData.online_meeting_code,
        contact_person : MeetingData.contact_person,
        google_map_link : MeetingData.google_map_link,
        note : MeetingData.note,
        project : MeetingData.project,
        agenda_detail : MeetingData.agenda_detail,
        created_by_id : null,
        employee : employee_info?.id,
        company : CompanyInfo?.id
      },
    }
    dispatch(createMeeting(sent_data_api)).then(({ type }) => {
      if(type.endsWith('_SUCCESS')){
        setTitlesuccess(t('success_create_meeting_message'));
        setSubTitlesuccess('');
        setDescriptionsuccess(''); 
        setsuccessModal(true)
      }else{
        setTitleFail(t('error_create_meeting'));
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }     
    })
    //onSubmit()
  }
  //--- ED Submit

  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">{t('create_meeting_header')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">{t('create_meeting_sub_header')}</div>
        <hr />
        <div className="wrp-tab-menu">
            {menuTabList?.map((item, index) => (
              <button
                id={'this_name-' + item.name}
                key={'btn-' + index}
                className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                onClick={() => handleClickTaskMenuTab(item)}
              >
                {item.name}
              </button>
            ))}
        </div>
      </div>
      <div className="dialog-content">
        {tabactive == 'information' && (
        <div>
            <div className="box_reserve_meeting">
              <div className="basic-Information-detail">
                  <div>
                        <div className="topic_fill">{t('meeting_type')}</div>
                        <div>
                          <Dropdown
                            id={`MeetingType`}
                            className="dd_employee"
                            value={MeetingData.meeting_type}
                            optionList={MeetingType_option}
                            onChange={onMeetingType}
                            disabled={!editable}
                            placeHolder="Select"
                            ValueplaceHolderIsNull={true}
                          />
                        </div>
                  </div>
              </div>
              <div className="basic-Information-detail">
                  <div>
                      <div className="topic_fill">{t('meeting_space')}</div>
                      <div className="input-new-2">
                          <TextField
                          name="meeting_space"
                          className="TextField_employee"
                          placeholder={t('reserve')}
                          value={MeetingData.meeting_space}
                          onChange={(e) => setMeetingData({ ...MeetingData, meeting_space: e.target.value })}
                          disabled={true}
                          />
                          <Button onClick={OpenModalReserve}>
                              {t('reserve')}
                          </Button>
                      </div>
                  </div>
              </div>
              <div className="basic-Information-detail">
                <div className="topic_fill_warning">{t('meeting_space_info')}</div>
              </div>
            </div>
            <div className="basic-Information-detail">
                <div>
                    <div className="topic_fill">{t('title')} *</div>
                    <div >
                        <TextField
                        name="title"
                        className="TextField_employee"
                        placeholder={t('meeting_title_placeholder')}
                        value={MeetingData.title}
                        onChange={(e) => setMeetingData({ ...MeetingData, title: e.target.value })}
                        disabled={!editable}
                        error={Boolean(MeetingDataError.title.show_errorMessage)}
                        />
                        {MeetingDataError.title.show_errorMessage == true && (
                          <DivErrorMessage>{MeetingDataError.title.errorMessage}</DivErrorMessage>
                        )}
                    </div>
                </div>
            </div>
            <div className="basic-Information-detail">
                <div>
                    <div className="topic_fill">{t('create_meeting_meeting_no')}</div>
                    <div >
                        <TextField
                        name="meeting_no"
                        className="TextField_employee"
                        placeholder={t('meeting_no_placeholder')}
                        value={MeetingData.meeting_no}
                        onChange={(e) => setMeetingData({ ...MeetingData, meeting_no: e.target.value })}
                        disabled={!editable}
                        />
                    </div>
                </div>
            </div>
            <div className="basic-Information-detail">
                <div>
                    <div className="topic_fill">{t('meeting_date')}</div>
                    <div >
                        <Date className={'input-calendar'} value={MeetingData.meeting_date} onChange={onChangecurrentDate} />
                    </div>
                </div>
                <div></div>
            </div>
            <div className="basic-Information-detail">
                <div>
                    <div className="topic_fill">{t('time_start')} *</div>
                    <div >
                        <TextField
                        name="time_start"
                        className="TextField_employee"
                        placeholder={t('time_start')}
                        value={MeetingData.time_start}
                        onChange={(e) => setMeetingData({ ...MeetingData, time_start: e.target.value })}
                        disabled={!editable}
                        />
                    </div>
                </div>
                <div>
                    <div className="topic_fill">{t('time_end')} *</div>
                    <div >
                        <TextField
                        name="time_end"
                        className="TextField_employee"
                        placeholder={t('time_end')}
                        value={MeetingData.time_end}
                        onChange={(e) => setMeetingData({ ...MeetingData, time_end: e.target.value })}
                        disabled={!editable}
                        />
                    </div>
                </div>
            </div>
            <div className="title">
                <hr />
            </div>
            <div className="box_reserve_meeting">
              <div className="basic-Information-detail">
                  <div className="topic_fill">
                      {t('set_up_online_meeting_link')}
                  </div>
              </div>
              <div className="basic-Information-detail">
                  <div>
                      <div className="topic_fill">{t('meeting_link')}</div>
                      <div >
                          <TextField
                          startAdornment={<img src={LinkIcon} />}
                          startAdornmentClassName="prefix_icon"
                          name="online_meeting_link"
                          className="TextField_employee"
                          placeholder={t('meeting_link_placeholder')}
                          value={MeetingData.online_meeting_link}
                          onChange={(e) => setMeetingData({ ...MeetingData, online_meeting_link: e.target.value })}
                          disabled={!editable}
                          />
                      </div>
                  </div>
              </div>
              <div className="basic-Information-detail">
                  <div>
                      <div className="topic_fill">{t('meeting_id')}</div>
                      <div >
                          <TextField
                          name="online_meeting_id"
                          className="TextField_employee"
                          placeholder={t('meeting_id')}
                          value={MeetingData.online_meeting_id}
                          onChange={(e) => setMeetingData({ ...MeetingData, online_meeting_id: e.target.value })}
                          disabled={!editable}
                          />
                      </div>
                  </div>
              </div>
              <div className="basic-Information-detail">
                  <div>
                      <div className="topic_fill">{t('meeting_code')}</div>
                      <div >
                          <TextField
                          name="online_meeting_code"
                          className="TextField_employee"
                          placeholder={t('meeting_code_placeholder')}
                          value={MeetingData.online_meeting_code}
                          onChange={(e) => setMeetingData({ ...MeetingData, online_meeting_code: e.target.value })}
                          disabled={!editable}
                          />
                      </div>
                  </div>
              </div>
            </div>
            <div className="title">
                <hr />
            </div>
            <div className="basic-Information-detail">
                <div>
                    <div className="topic_fill">{t('for_other_location')}</div>
                    <div >
                        <TextField
                        startAdornment={<img src={mapIcon} />}
                        startAdornmentClassName="prefix_icon"
                        name="google_map_link"
                        className="TextField_employee"
                        placeholder={t('google_map_link')}
                        value={MeetingData.google_map_link}
                        onChange={(e) => setMeetingData({ ...MeetingData, google_map_link: e.target.value })}
                        disabled={!editable}
                        />
                    </div>
                </div>
            </div>
            <div className="basic-Information-detail">
                <div>
                    <div className="topic_fill">{t('meeting_note_to_attendee')}</div>
                    <div >
                        <InputTextAreaV2024
                            className="InputTextArea_css"
                            initValue={MeetingData.note}
                            onChange={(e) => setMeetingData({ ...MeetingData, note: e.target.value })}                        
                            placeHolder={t('meeting_note_to_attendee_placeholder')}
                            borderColor={'var(--Gray-300)'}
                            borderWidth="1px"
                        />

                    </div>
                </div>
            </div>
        </div>
        )}
      </div>

      
      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={onClose}>
          {t('cancel')}
        </ButtonOutline>
        <Button className="button_margin" onClick={ConfirmDialog}>
          {t('submit')}
        </Button>
      </div>


        <DialogConfirmV2
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onNo={() => setModalConfirmDialog(false)}
          onSubmit={onCreateMeeting}
          icon={userIconQuesTion}
          title={Modal_title}
          nameItem={Modal_NameItem}  
          description={Modal_Description}
          textYes={t('confirm')}
          textNo={t('cancel')}
        />

        <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          nameItem2={nameItemFail2}
          description={DescriptionFail}
          textYes={t('ok')}
        />
    </Div>
  )
}

export default NewMeeting
