import * as React from 'react'

import { 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  Divider, 
  Tabs, 
  Tab, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Box,
  AvatarGroup,
  Avatar
} from '@mui/material';



import BasicDateCalendar from '../components/date-calendar/BasicDateCalendar'

import styled from 'styled-components'
import { RiHome6Line } from 'react-icons/ri'
import { CiMail } from 'react-icons/ci'
import { FiUsers } from 'react-icons/fi'
import { LuBell } from 'react-icons/lu'
import { IoCalendarClearOutline } from 'react-icons/io5'
import { BsFileText } from 'react-icons/bs'
import { TbUsersMinus } from 'react-icons/tb'
import { HiDotsVertical } from 'react-icons/hi'
import { FaChevronUp } from 'react-icons/fa'
import { IoIosAdd } from 'react-icons/io'

import { FaChevronLeft } from 'react-icons/fa'
import { FaChevronRight } from 'react-icons/fa'

import SearchText from '../components/form/SearchText'
import { useState } from 'react'
import { IoVideocam } from "react-icons/io5";
import ButtonOutline from '../components/form/button/ButtonOutline'


import TodayCard from '../components/layout/Today'
import ActionPlanCard from '../components/layout/ActionPlan'
import { useTranslation } from 'react-i18next'

import Table from '../components/common/Table';

import MyTask from '../components/layout/Mytask'

const Div = styled.div`
  .d-flex {
    display: flex;
  }

  .wrp-table {
    > div {
      overflow-x: inherit;
    }
  }
  .wrp-filter-menu {
      position: relative;
      display: flex;
  }
  .scroll {
    height: 980px; /* Adjust based on your preference */
    width: 100%;
    position: absolute;
    overflow-y: scroll;
    padding: 10px;
    top: 0px;
    right: 0px;
  }
  .gap-10 {
    gap: 10px;
  }
  .gap-8 {
    gap: 8px;
  }

  .mt-20 {
    margin-top: 20px;
  }
  
  .flex-col {
    flex-direction: column;
    align-items: flex-start;
  }
  .flex-row {
    flex-direction: row; 
    /* justify-content: center;
    align-items: center; */
  }

  .flex-start {
    justify-content: flex-start;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-between {
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .header-home {
    position: absolute;
    width: 100%;
    height: 660px;
    top: 0px;
    right: 0px;
    background: linear-gradient(180deg, #fbc2eb 0%, #a18cd1 105.25%);
  }
  .icon-home {
    margin: 10px;
    padding: 0 15px;
  }
  .icon-home .icon-card {
    width: 45px;
    height: 35px;
    background-color: rgba(249, 250, 251, 0.5);
    border-radius: 5px;
  }
  .header-title {
    margin: 10px;
    padding: 0 15px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    color: #fff;
    span {
      color: rgb(120 81 210);
    }
  }
  .search-form {
    margin: 15px;
    padding: 0 10px;
    .search-input {
      width: 100%;
    }
  }

  .icon-between {
    padding: var(--spacing-md, 8px) var(--spacing-lg, 50px);
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
  }

  .content-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .icon-card {
    width: 50px;
    height: 50px;
    background-color: rgba(249, 250, 251, 0.5);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .name-card {
    color: #333;
    font-size: 12px;
    margin-top: 5px;
  }

  .layout {
    padding: 10px;
    flex-direction: row;
    align-items: flex-start;
    /* align-self: stretch; */
  }

  .container {
    width: 100%;
    height: 100vh;
  }

  .card-blur {
    margin-bottom: 5px;
    margin: 0 15px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(4px);
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 65%;
  }

  .card-date {
    margin: 0 15px;
    border-radius: 8px;
    background: #fff;
    padding: 15px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    border-radius: 8px;
    margin-bottom: 15px;
    height: 494px;
  }

  .card-today {
    margin: 0px 10px;
    margin-top: 15px;
    border-radius: 8px;
    background: #fff;
    padding: 20px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    border-radius: 8px;
    /* height: 100%; */
    width: 24%;

    .card-today-body {
      padding: 10px;
    }
    .card-today-time {
      color: var(--Gray-700, #344054);
      /* Text sm/Semibold */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }
    .card-today-toppic{
      margin-top: 5px;
      overflow: hidden;
      color: var(--Gray-700, #344054);
      text-overflow: ellipsis;

      /* Text sm/Medium */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
    }
    .card-today-description {
      overflow: hidden;
      color: var(--Gray-500, #667085);
      text-overflow: ellipsis;

      /* Text xs/Regular */
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      margin-bottom: 10px;
    }
    .line {
      height: 2px;
      width: 350px;
      margin-top: 20px;
      background: var(--colors-gray-light-mode-200, #EAECF0);
    }
  }

  .card-header-title {
    color: #fff;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  .card-header-icon {
    color: #fff;
  }

  .card-8 {
    flex: 0 0 75.75%;
  }

  .card-4 {
    flex: 0 1 33.33%;
  }

  .card-body {
    margin-top: 126px;
  }

  .card {
    margin-top: 15px;
    background-color: #6c7a89;
    color: #fff;
    border-radius: 4px;
    width: 430px;
    height: 246px;
    padding: 10px;
  }

  .card-task {
    margin: 0 15px;
    margin-top: 15px;
    border: 1px solid var(--Gray-200, #eaecf0);
    background-color: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    width: 98%;
    height: 503px;
    padding: 10px;
    margin-bottom: 100px;

    .card-task-header {
      padding: 15px;

    }
    .line {
      position: absolute;
      height: 2px;
      width: 73%;
      margin-top: 20px;
      background: var(--colors-gray-light-mode-200, #EAECF0);
    }
  }
  
  .card-action-plan {
    margin: 0 15px;
    margin-top: 25px;
    border: 1px solid var(--Gray-200, #eaecf0);
    background-color: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    width: 98%;
    height: 137px;
    padding: 8px;

    .card-action-plan-title {
      color: var(--Gray-800, #182230);
      /* Text md/Semibold */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
    }
    .card-action-plan-sub {
      color: var(--Gray-700, #344054);
      /* Text xs/Regular */
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
    .card-action-plan-body {
      padding: 6px;
      color: #000;
    }

  }

  .card-title {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.5rem;

    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
  }

  .card-subtitle {
    font-size: 0.875rem;
    margin-bottom: 1rem;
  }

  .card-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
  }

  .card-avatar img {
    width: 100%;
    height: auto;
    display: block;
  }

  .card-icon {
    font-size: 1.5rem;
    color: #fff;
  }

  .slider {
    position: relative;
    overflow: hidden;
  }

  .control-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    border-radius: 0px 6px 6px 0px;
    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
  }

  .prevBtn {
    left: 0;
    color: #fff;
  }

  .nextBtn {
    right: 0;
    color: #fff;
  }

  .avatar-name {
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .table-wrapper {
    margin: 0 15px;
    margin-top: 15px;
    width: 1785px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: #fff;
      border: 1px solid var(--Gray-200);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }
    .table-body {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      background: #fff;
      border: 1px solid var(--Gray-200);
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .overflow-x {
    overflow-x: auto;
    gap: 5px; 
    width: 100%;

  }


@media screen and (min-width: 1280px) {
  .col-1 {
    width: 79%;
  }
}

@media screen and (min-width: 736px) and (max-width: 1112px) {
  .col-1 {
    width: 55%;
  }
  .card-date {
    margin: 0px 15px 15px;
    background: rgb(255, 255, 255);
    padding: 10px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    box-shadow: rgba(16, 24, 40, 0.06) 0px 1px 2px 0px, rgba(16, 24, 40, 0.1) 0px 1px 3px 0px;
    border-radius: 8px;
    height: 502px;
  }
  .card-today {
    margin: 15px 15px 0px;
    background: rgb(255, 255, 255);
    padding: 20px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    box-shadow: rgba(16, 24, 40, 0.06) 0px 1px 2px 0px, rgba(16, 24, 40, 0.1) 0px 1px 3px 0px;
    border-radius: 8px;
    width: 70%;
  }
  .table-wrapper {
    margin: 0 15px;
    margin-top: 15px;
    width: 97%;
  }
  .card-blur {
    margin: 0px 15px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(4px);
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px;
    height: 66%;
    width: 98%;
  }
}

@media screen and (min-width: 989px) and (max-width: 1280px) {
  .col-1, .col-2 {
    width: 65%; 
  }
  .card-action-plan {
    margin: 0 15px;
    margin-top: 25px;
    border: 1px solid var(--Gray-200, #eaecf0);
    background-color: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    width: 96%;
    height: 137px;
    padding: 10px;
  }
  .table-wrapper {
    margin: 0 15px;
    margin-top: 15px;
    width: 97%;
  }
  .card-today {
    margin: 15px 15px 0px;
    background: rgb(255, 255, 255);
    padding: 20px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    box-shadow: rgba(16, 24, 40, 0.06) 0px 1px 2px 0px, rgba(16, 24, 40, 0.1) 0px 1px 3px 0px;
    border-radius: 8px;
    width: 50%;
  }
}

.banner-container {
  margin: 20px;
  width: 98%;
  border-radius: 8px;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  border: 1px solid var(--Gray-300, #D0D5DD);
  background: rgba(255, 255, 255, 0.50);
  padding: 10px;
  position: relative;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.banner-text {
  display: inline-block;
  padding-left: 20%;
  animation: scroll-left 20s linear infinite;
}

@keyframes scroll-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}


`

const Home2 = () => {
  const {t} = useTranslation()
  const UsersColumn = [
    {
      Header: '',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('SOP'),
      accessor: 'sop',
      disableSortBy: false,
    },
    {
      Header: t('SOW'),
      accessor: 'sow',
      disableSortBy: false,
    },
    {
      Header: t('Task'),
      accessor: 'task',
      disableSortBy: false,
    },
    {
      Header: t('Due Date'),
      accessor: 'due_date',
      disableSortBy: false,
    },
    {
      Header: t('Status'),
      accessor: 'status',
      disableSortBy: false,
    }
    
  ]

  const [Annoucements, setAnnoucements] = useState([
    {
      title: 'CEO Corner: Creating a Custom something :',
      avatar:
        'https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png',
      name: 'Techin Seoikrathok',
    },
    {
      title: 'CEO Corner: Creating a Custom something :',
      avatar:
        'https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png',
      name: 'Techin Seoikrathok',
    },
    {
      title: 'CEO Corner: Creating a Custom something :',
      avatar:
        'https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png',
      name: 'Techin Seoikrathok',
    },
  ])


  const [users, setUsers] = useState([
    {number:1},
    {number:2},
    {number:3}
  ])

  // const UsersColumn = [
  //   { id: 'col0', label: '' },
  //   { id: 'col1', label: 'SOP' },
  //   { id: 'col2', label: 'SOW' },
  //   { id: 'col3', label: 'Task' },
  //   { id: 'col4', label: 'Due Date' },
  //   { id: 'col5', label: 'Status' },
  // ];

  const usersData = [
    { no: 1, sop: 'วางแผนและพัฒนากลยุทธ์', sow: 'กำหนดเป้าหมายกลยุทธ์', task: 'Business Model Canvas', due_date: '24 Jan 2024', status: 'Doing' },
    { no: 2, sop: 'วางแผนและพัฒนากลยุทธ์', sow: 'กำหนดเป้าหมายกลยุทธ์', task: 'Business Model Canvas', due_date: '24 Jan 2024', status: 'Doing' },
    { no: 3, sop: 'วางแผนและพัฒนากลยุทธ์', sow: 'กำหนดเป้าหมายกลยุทธ์', task: 'Business Model Canvas', due_date: '24 Jan 2024', status: 'Doing' },
    { no: 4, sop: 'วางแผนและพัฒนากลยุทธ์', sow: 'กำหนดเป้าหมายกลยุทธ์', task: 'Business Model Canvas', due_date: '24 Jan 2024', status: 'Doing' },
    { no: 5, sop: 'วางแผนและพัฒนากลยุทธ์', sow: 'กำหนดเป้าหมายกลยุทธ์', task: 'Business Model Canvas', due_date: '24 Jan 2024', status: 'Doing' },
    { no: 6, sop: 'วางแผนและพัฒนากลยุทธ์', sow: 'กำหนดเป้าหมายกลยุทธ์', task: 'Business Model Canvas', due_date: '24 Jan 2024', status: 'Doing' },
    { no: 7, sop: 'วางแผนและพัฒนากลยุทธ์', sow: 'กำหนดเป้าหมายกลยุทธ์', task: 'Business Model Canvas', due_date: '24 Jan 2024', status: 'Doing' },
    { no: 8, sop: 'วางแผนและพัฒนากลยุทธ์', sow: 'กำหนดเป้าหมายกลยุทธ์', task: 'Business Model Canvas', due_date: '24 Jan 2024', status: 'Doing' },
    { no: 9, sop: 'วางแผนและพัฒนากลยุทธ์', sow: 'กำหนดเป้าหมายกลยุทธ์', task: 'Business Model Canvas', due_date: '24 Jan 2024', status: 'Doing' },
    { no: 10, sop: 'วางแผนและพัฒนากลยุทธ์', sow: 'กำหนดเป้าหมายกลยุทธ์', task: 'Business Model Canvas', due_date: '24 Jan 2024', status: 'Doing' }
  ];


  return (
    <Div>
        <div className="scroll">
        <header>
        <div className="header-home">
          <div className="icon-home">
            <RiHome6Line className="icon-card" size={28} />
          </div>
          <div className="header-title">
            <h1>
              Good afternoon, <span>Cindy</span>
            </h1>
          </div>

          <div className="d-flex justify-content-center">
            <div className="banner-container">
              <div className="banner-text">
                Each document contains a set of key-value pairs. Cloud Firestore is optimized for storing large collections of small documents.
              </div>
            </div>
          </div>

          <div className="d-flex icon-between">
            <div className="content-icon">
              <div className="icon-card">
                <CiMail size={28} />
              </div>
              <div className="name-card">Inbox</div>
            </div>
            <div className="content-icon">
              <div className="icon-card">
                <FiUsers size={28} />
              </div>
              <div className="name-card">Meeting</div>
            </div>
            <div className="content-icon">
              <div className="icon-card">
                <LuBell size={28} />
              </div>
              <div className="name-card">Notification</div>
            </div>
            <div className="content-icon">
              <div className="icon-card">
                <IoCalendarClearOutline className="icon-default" size={28} />
              </div>
              <div className="name-card">Calendar</div>
            </div>
            <div className="content-icon">
              <div className="icon-card">
                <RiHome6Line className="icon-default" size={28} />
              </div>
              <div className="name-card">Action Plan</div>
            </div>
            <div className="content-icon">
              <div className="icon-card">
                <BsFileText className="icon-default" size={28} />
              </div>
              <div className="name-card">Daily Summary</div>
            </div>
            <div className="content-icon">
              <div className="icon-card">
                <TbUsersMinus className="icon-default" size={28} />
              </div>
              <div className="name-card">Absence</div>
            </div>
            <div className="content-icon">
              <div className="icon-card">
                <HiDotsVertical className="icon-default" size={28} />
              </div>
            </div>
          </div>

          <div className="d-flex mt-20">
            <div className="col-1">
              <div className="card-blur">
                <div className="d-flex justify-content-between">
                  <div className="card-header-title">Annoucements</div>
                  <div className="card-header-icon">
                    <FaChevronUp size={15} />
                  </div>
                </div>

                <div id="slider" className="slider">
                  <div className="prevBtn control-btn">
                    <FaChevronLeft size={15} />
                  </div>

                  <div className="d-flex justify-content-between">
                    {Annoucements.map((data, i) => (
                      <div className="card" key={i}>
                        <div className="card-body">
                          <h2 className="card-title">{data.title}</h2>
                          <div className="d-flex gap-10">
                            <div className="card-avatar">
                              <img src={data.avatar} alt={data.name} />
                            </div>
                            <p className="avatar-name">{data.name}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="nextBtn control-btn">
                    <FaChevronRight size={15} />
                  </div>
                </div>
              </div>


              <div className="d-flex flex-col">
                <div className="card-action-plan">
                  <div className="card-action-plan-title">บันทึกประจำวัน</div>
                  <div className="card-action-plan-sub">วันที่ 26 มกราคม 2567</div>
                  <div className="d-flex flex-col  card-action-plan-body gap-8">
                    <div className="">
                      1. <Button variant="outlined" startIcon={<IoIosAdd />} size="medium" color="secondary">Action Plan</Button>
                    </div>
                    <div className="">2.Daily Summary: 26 Jan 2024</div>
                  </div>
                </div>
              </div>


            </div>

            <div className="col-2">
            <Box sx={{ maxWidth: 445}}>
              <Box className="card-date" sx={{ mb: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'between' }}>
                  <BasicDateCalendar />
                </Box>
              </Box>

              {/* <Box className="card-today" sx={{ maxWidth: 445, m: 2, maxHeight: '70%' }}>
                <TodayCard users={users} />
              </Box> */}
            </Box>
            </div>

          </div>

          <div className="d-flex flex-row overflow-x">
                <div className="card-today">
                  <div>TODAY 16 Jan 2024</div>
                  <div className="d-flex flex-col card-today-body gap-8">
                      <div className="card-today-time">8:30 - 9:30 AM</div>
                      <div className="card-today-toppic">[Interview] Amy Adamson - Software Engineer</div>
                      <div className="card-today-description">Acme Worldwide HQ-1-Acme(20)</div>
                      <div className="d-flex">
                        <div>
                          <Button color="secondary" variant="contained" startIcon={<IoVideocam />}>Join</Button>
                        </div>
                        <div>
                          <AvatarGroup total={9}>
                          <Avatar alt="Remy Sharp" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Travis Howard" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Agnes Walker" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Trevor Henderson" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                        </AvatarGroup>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="card-today">
                  <div>TODAY 16 Jan 2024</div>
                  <div className="d-flex flex-col card-today-body gap-8">
                      <div className="card-today-time">8:30 - 9:30 AM</div>
                      <div className="card-today-toppic">[Interview] Amy Adamson - Software Engineer</div>
                      <div className="card-today-description">Acme Worldwide HQ-1-Acme(20)</div>
                      <div className="d-flex">
                        <div>
                          <Button color="secondary" variant="contained" startIcon={<IoVideocam />}>Join</Button>
                        </div>
                        <div>
                          <AvatarGroup total={9}>
                          <Avatar alt="Remy Sharp" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Travis Howard" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Agnes Walker" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Trevor Henderson" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                        </AvatarGroup>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="card-today">
                  <div>TODAY 16 Jan 2024</div>
                  <div className="d-flex flex-col card-today-body gap-8">
                      <div className="card-today-time">8:30 - 9:30 AM</div>
                      <div className="card-today-toppic">[Interview] Amy Adamson - Software Engineer</div>
                      <div className="card-today-description">Acme Worldwide HQ-1-Acme(20)</div>
                      <div className="d-flex">
                        <div>
                          <Button color="secondary" variant="contained" startIcon={<IoVideocam />}>Join</Button>
                        </div>
                        <div>
                          <AvatarGroup total={9}>
                          <Avatar alt="Remy Sharp" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Travis Howard" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Agnes Walker" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Trevor Henderson" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                        </AvatarGroup>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="card-today">
                  <div>TODAY 16 Jan 2024</div>
                  <div className="d-flex flex-col card-today-body gap-8">
                      <div className="card-today-time">8:30 - 9:30 AM</div>
                      <div className="card-today-toppic">[Interview] Amy Adamson - Software Engineer</div>
                      <div className="card-today-description">Acme Worldwide HQ-1-Acme(20)</div>
                      <div className="d-flex">
                        <div>
                          <Button color="secondary" variant="contained" startIcon={<IoVideocam />}>Join</Button>
                        </div>
                        <div>
                          <AvatarGroup total={9}>
                          <Avatar alt="Remy Sharp" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Travis Howard" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Agnes Walker" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Trevor Henderson" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                        </AvatarGroup>
                        </div>
                      </div>
                  </div>
                </div>
                <div className="card-today">
                  <div>TODAY 16 Jan 2024</div>
                  <div className="d-flex flex-col card-today-body gap-8">
                      <div className="card-today-time">8:30 - 9:30 AM</div>
                      <div className="card-today-toppic">[Interview] Amy Adamson - Software Engineer</div>
                      <div className="card-today-description">Acme Worldwide HQ-1-Acme(20)</div>
                      <div className="d-flex">
                        <div>
                          <Button color="secondary" variant="contained" startIcon={<IoVideocam />}>Join</Button>
                        </div>
                        <div>
                          <AvatarGroup total={9}>
                          <Avatar alt="Remy Sharp" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Travis Howard" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Agnes Walker" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                          <Avatar alt="Trevor Henderson" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                        </AvatarGroup>
                        </div>
                      </div>
                  </div>
                </div>
          </div>



          <div className="table-wrapper">
            <div className="table-header">
              <div className="group">
                <div className="table-title">My Tasks</div>
                <div className="table-total">
                  20 task
                </div>
              </div>

              <div>
                view all
              </div>
            </div>
            <div className="table-body">
              <div>
              <Tabs aria-label="task tabs">
                <Tab label="Doing" />
                <Tab label="Upcoming" />
                <Tab label="Done" />
              </Tabs>
              </div>
              <div>
              <ButtonOutline>Add Task</ButtonOutline>
              </div>
            </div>
            
            <Table
              columns={UsersColumn}
              data={usersData}
            />
            
          </div>
            
        </div>
        </header>
        </div>
    </Div>
  )
}

export default Home2
