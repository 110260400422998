import { Stack } from '@mui/material'
import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import PageHeader from 'components/common/PageHeader'
import PageLayout from 'components/layout/PageLayout'

import ChainLinkMGTList from './chain-link'
import SopMGTList from './sop'
import SowMGTList from './sow'
import TaskMGTList from './task'

const templates = ['task', 'sow', 'sop', 'chain-link']

const RenderBody = ({ currentTab, ...props }) => {
  switch (currentTab) {
    case 'task':
      return <TaskMGTList {...props} />
    case 'sow':
      return <SowMGTList {...props} />
    case 'sop':
      return <SopMGTList {...props} />
    case 'chain-link':
      return <ChainLinkMGTList {...props} />
    default:
      return
  }
}

export default function OPManagementListPage() {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { type: currentTab } = useParams()

  if (templates.includes(currentTab)) {
    return (
      <PageLayout>
        <PageHeader
          title={`${t('operation_process')}: ${currentTab.toUpperCase().replace('-', ' ')} - Template Management `}
          supportingText={`Manage your operation process templates here.`}
        />
        <RenderBody currentTab={currentTab} />
      </PageLayout>
    )
  }

  return navigate(`${location.pathname.split('/').slice(0, -1).join('/')}/chain-link`)
}

export const ButtonGroup = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: '0.5rem',
})

export const TableContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.chainLink-table-container,
  &.sop-table-container,
  &.sow-table-container,
  &.task-table-container {
    table {
      td {
        > div.chainLink-col-description,
        > div.sop-col-description,
        > div.sow-col-description,
        > div.task-col-description {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
        }
      }
    }
  }
`

export const TableHeader = styled('div')({
  display: 'flex',
  width: '100%',
  border: '1px solid var(--Gray-200)',
  borderBottom: 'none',
  borderTopLeftRadius: '.5rem' /* 8px */,
  borderTopRightRadius: '.5rem' /* 8px */,
})

export const TypographyH3 = styled('h3')`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: var(--Gray-700);
  margin: 0;
`
