import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { DateDisplay } from '../../utils/common'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import Table from '../../components/common/Table'
import { LinearProgress } from '@mui/material'

import { BsGraphUpArrow } from 'react-icons/bs'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

import {
  getEmployeeDashboardById,
  getSummary,
  getTask,
  getCurrentProject,
  getDailySummaryReport,
} from '../../redux/actions/employeeDashboard'
import { selectEmployeeDashboard } from '../../redux/selectors'
import DialogTaskPreviewer from '../../components/dialog/DialogTaskPreviewer'
import DialogTaskEditor from '../../components/dialog/DialogTaskEditor'

// style
const Div = styled.div`
  .header {
    display: flex;
    align-items: center;
    color: var(--Gray-600);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    .highlight {
      color: var(--Primary-700);
      font-weight: 600;
    }
  }

  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .mr-8-px {
    margin-right: 8px;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .align-items-center {
    align-items: center;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 12px;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-2rem {
    font-size: 1.5rem;
  }

  .badge {
    padding: 2px 8px;
    align-items: center;
    color: var(--BADGE-4-Font);
    background: var(--BADGE-4-Bg);
    border: 1px solid var(--BADGE-4-Border);
    border-radius: 16px;

    &.hr {
      color: var(--BADGE-5-Font);
      background: var(--BADGE-5-Bg);
      border: 1px solid var(--BADGE-5-Border);
    }
    &.product {
      color: var(--BADGE-11-Font);
      background: var(--BADGE-11-Bg);
      border: 1px solid var(--BADGE-11-Border);
    }
    &.marketing {
      color: var(--BADGE-12-Font);
      background: var(--BADGE-12-Bg);
      border: 1px solid var(--BADGE-12-Border);
    }
    &.logistic {
      color: var(--BADGE-14-Font);
      background: var(--BADGE-14-Bg);
      border: 1px solid var(--BADGE-14-Border);
    }
  }

  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid var(--Status-Icon-Stroke);
    color: var(--Status-Icon-Font);
    background: var(--Status-Icon-Bg);

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: var(--Status-Active);

      &.inactive {
        color: var(--Status-Inactive);
      }

      &.draft {
        color: var(--Status-Draft);
      }
    }
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .search-container {
    display: grid;
    grid-template-columns: repeat(4, 250px) 120px;
    grid-template-rows: auto;
    align-content: center;
    column-gap: 1rem;
    /* margin-bottom: 1rem; */
  }

  .content-header {
    /* margin-bottom: 24px; */
    background: var(--Gray-50);
    border-radius: 8px;
    padding: 12px 16px;
  }

  .table-wrapper {
    width: 68%;

    .txt-task-name {
      cursor: pointer;
    }
    .txt-task-name:hover {
      cursor: pointer;
      color: var(--Primary-700);
    }

    .table-header {
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Base-White);
      border: 1px solid var(--Gray-200);

      .header {
        .group {
          display: flex;
          align-items: center;
        }
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .table-wrapper-2 {
    width: 100%;

    .table-header {
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Base-White);
      border: 1px solid var(--Gray-200);

      .header {
        .group {
          display: flex;
          align-items: center;
        }
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .summary-wrapper {
    width: 30%;
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error-input {
    border: 1px solid red;
  }

  .side-panel-container {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .side-panel-container-2 {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .create-access-role-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-header {
    color: var(--gray-700);
    font-size: 16px;
    font-weight: 500;
  }

  .permission-header {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .permission-content {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .text-underline {
    text-decoration: underline;
  }
  .text-color-primary {
    color: #7f56d9;
  }
  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .tab-content {
    overflow: auto;
    .view-information-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }
  }

  .share-list-search-container {
    display: grid;
    grid-template-columns: repeat(2, 10rem) 8rem;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--gray-200, #eaecf0);
  }

  .modal-content {
    width: 640px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--Gray-600);
  }

  .change-status-content {
    padding: 20px;
    width: 384px;
  }

  .category-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    height: 500px;

    .category {
      padding: 1rem 2rem;
      border-right: 1px solid var(--Gray-200);
      .category-item {
        display: flex;
        align-items: center;
        padding: 8px;
        border-radius: 8px;
        background: var(--Base-White);
        align-items: center;
        cursor: pointer;

        &.active {
          background: var(--Primary-600);
          color: var(--Base-White);
        }
      }
    }

    .category-view {
      padding: 1rem 2rem;
      .view-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .view-link {
          cursor: pointer;
          color: var(--Primary-700);
          font-weight: 600;
        }
      }
    }
  }

  .search-content {
    padding: 10px;
    border-bottom: 1px solid var(--Gray-200);
  }

  .search-input {
    position: relative;
    color: var(--Gray-500);

    .input-field {
      box-sizing: border-box;
      padding: 10px;
      padding-left: 40px;
      border: none;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .start-icon {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .end-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .preview-button {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }

  .headline {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .error {
    color: var(--Error-600);
  }

  .error-border {
    border: 1px solid var(--Error-300);
  }

  .card-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .card {
    border-radius: 12px;
    border: 1px solid var(--Gray-200);
    background: var(--Base-White);
    padding: 20px 16px;
    width: 19%;

    &.bg-1 {
      background: var(--Base-White);
    }
    &.bg-2 {
      background: var(--Primary-600);
    }

    .name {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 8px;
      &.color-1 {
        color: var(--Gray-600);
      }
      &.color-2 {
        color: var(--Base-White);
      }
    }

    .value {
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: 38px;

      &.color-1 {
        color: var(--Gray-900);
      }
      &.color-2 {
        color: var(--Base-White);
      }
    }
  }

  .data-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .card-container {
    .card-header {
      padding: 20px 24px;
      border-radius: 8px 8px 0 0;
      border: 2px solid var(--Gray-200);
      border-bottom: 0;
      .title-header {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;

        &.color-1 {
          color: var(--Base-White);
        }

        &.color-2 {
          color: var(--Gray-900);
        }
      }
      .sub-header {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        &.color-1 {
          color: var(--Gray-50);
        }

        &.color-2 {
          color: var(--Gray-600);
        }
      }
      &.bg-1 {
        background: var(--Header-Bg);
      }
      &.bg-2 {
        background: var(--Gray-50);
        border: 1px solid var(--Gray-200);
      }
    }

    .card-content {
      position: relative;
      padding: 24px;
      border-radius: 0 0 8px 8px;
      border: 2px solid var(--Gray-200);

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: var(--Gray-500);

      .highlight {
        color: var(--Highlight-Text);
        font-weight: 600;
        font-size: 16px;

        &.color-1 {
          color: var(--Gray-700);
        }
      }

      .progress-bar-widht {
        width: 50%;
      }
      .percent-width {
        width: 50%;
      }
    }
  }

  .tab-container-table {
    display: flex;

    .tab {
      color: var(--Gray-500);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      margin-right: 12px;

      :hover {
        cursor: pointer;
      }

      &.active {
        color: var(--Primary-700);
        padding-bottom: 10px;
        border-bottom: 2px solid var(--Primary-700);
      }

      .badge-count {
        color: var(--Gray-700);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        background: var(--Gray-100);
        padding: 5px 8px;
        border-radius: 16px;

        &.active {
          color: var(--Primary-700);
          background: var(--Primary-50);
        }
      }
    }
  }

  .row-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--Gray-200);
    padding: 2em 0;
    .name-content {
      width: 60%;
    }
    .progress-content {
      width: 30%;
      display: flex;
      align-items: center;
    }

    .name {
      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }
`
const CustomProgress = styled(LinearProgress)`
  width: 100%;
  &.MuiLinearProgress-root {
    background-color: var(--Gray-200);
    height: 8px;
    border-radius: 8px;
  }
  .MuiLinearProgress-bar {
    background-color: var(--Primary-600);
  }
`

function EmployeeDashboardDetail() {
  const { t } = useTranslation()

  // column
  const dailySummaryReportColumn = [
    {
      Header: 'Submit date',
      accessor: 'dailysummary_date',
      disableSortBy: false,
    },
    {
      Header: "Whate's done today",
      accessor: 'what_done_today',
      disableSortBy: false,
      Cell: ({ value }) => {
        const maxLength = 30
        return <div>{value.length > maxLength ? `${value.slice(0, maxLength)}...` : value}</div>
      },
    },
  ]
  const taskColumn = [
    {
      Header: t('task_id'),
      accessor: 'id',
      disableSortBy: false,
    },
    {
      Header: t('task_name'),
      accessor: 'task_name',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('project_name'),
      accessor: 'project_name',
      disableSortBy: false,
    },
    {
      Header: t('milestone_name'),
      accessor: 'milestone_name',
      disableSortBy: false,
    },
    {
      Header: t('scope_of_work'),
      accessor: 'scope_of_work_name',
      disableSortBy: false,
    },
  ]
  const currentProjectColumn = [
    {
      Header: t('project'),
      accessor: 'name',
      disableSortBy: false,
      Cell: ({ value }) => {
        const maxLength = 30
        return <div>{value.length > maxLength ? `${value.slice(0, maxLength)}...` : value}</div>
      },
    },
    {
      Header: t('progress'),
      accessor: 'progress',
      disableSortBy: false,
    },
  ]

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()

  // store reducer
  const { employeeDashboardInfo, summary, tasks, currentProjects, dailySummaryReports } =
    useSelector(selectEmployeeDashboard)

  // state
  const [employeeDashboardId, setEmployeeDashboardId] = useState()
  const [taskData, setTaskData] = useState([])
  const [currentProject, setCurrentProject] = useState([])
  const [dailySummaryReport, setDailySummaryReport] = useState([])
  const [tab, setTab] = useState('on-going')
  const [isShowDialogPreviewer, setIsShowDialogPreviewer] = useState(false)
  const [isShowDialogTaskEditor, setIsShowDialogTaskEditor] = useState(false)
  const [currentPreviewType, setcurrentPreviewType] = useState('')
  const [currentTaskType, setCurrentTaskType] = useState('edit_task')
  const [taskId, setTaskId] = useState('')

  // useEffect for fetching data
  useEffect(() => {
    const { id } = params
    if (id) {
      const filter = {
        id,
      }
      setEmployeeDashboardId(id)

      dispatch(getEmployeeDashboardById(id))
      dispatch(getSummary(filter))
      dispatch(getCurrentProject(filter))
      dispatch(getDailySummaryReport(filter))
    }
  }, [params, dispatch])

  // useEffect for fetching task data
  useEffect(() => {
    const filter = {
      id: employeeDashboardId,
      status:
        tab === 'on-going'
          ? 'on_going'
          : tab === 'upcoming'
          ? 'upcoming'
          : tab === 'complete'
          ? 'completed'
          : 'delayed',
    }
    dispatch(getTask(filter))
  }, [tab, employeeDashboardId])

  // useEffect for mapping task table data
  useEffect(() => {
    const newTask = tasks.map((v) => ({
      ...v,
      task_name: (
        <span className="txt-task-name" onClick={() => handleClickedTaskName(v)}>
          {v.task_name}
        </span>
      ),
      status:
        v.status != '' ? (
          <span
            className={`badge ${
              v.status == 'HR'
                ? 'hr'
                : v.status == 'Product'
                ? 'product'
                : v.status == 'Marketing'
                ? 'marketing'
                : v.status == 'Logistic'
                ? 'logistic'
                : ''
            }`}
          >
            {v.status}
          </span>
        ) : (
          <span>-</span>
        ),
    }))
    setTaskData(newTask)
  }, [tasks])

  // useEffect for mapping current projects table data
  useEffect(() => {
    const newCurrentProject = currentProjects.map((v) => ({
      ...v,
      progress: (
        <div className="progress-content">
          <CustomProgress className="mr-8-px" variant="determinate" value={v.progress} />
          <div>{v.progress}%</div>
        </div>
      ),
    }))
    setCurrentProject(newCurrentProject)
  }, [currentProjects])

  // useEffect for mapping daily summary report table data
  useEffect(() => {
    const newDailySummaryReport = dailySummaryReports.map((v) => ({
      ...v,
      dailysummary_date: DateDisplay(v.dailysummary_date),
    }))
    setDailySummaryReport(newDailySummaryReport)
  }, [dailySummaryReports])

  const backToEmployeeDashboard = () => {
    navigate('/dashboard/employee-dashboard')
  }
  const handleClickedTaskName = (data) => {
    if (data) {
      if (data.type == 'routine') {
        setcurrentPreviewType('task_routine')
      } else {
        setcurrentPreviewType('task_milestone')
      }
      setTaskId(data.reference_task_id)
      setIsShowDialogPreviewer(true)
    }
  }

  return (
    <Div>
      <div className="header mb-2-rem">
        <BsGraphUpArrow size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight size={20} className="mr-8-px" />
        <span className="icon mr-8-px" onClick={backToEmployeeDashboard}>
          {t('employee_dashboard')}
        </span>
        <MdOutlineKeyboardArrowRight size={20} className="mr-8-px" />
        <span className="highlight">
          {employeeDashboardInfo?.first_name} {employeeDashboardInfo?.last_name}
        </span>
      </div>

      <div className="mb-1-rem">
        <div className="content-title">
          {employeeDashboardInfo?.first_name} {employeeDashboardInfo?.last_name}
        </div>
        <div>
          {t('departments')} : {employeeDashboardInfo?.department?.name} | {t('positions')} :{' '}
          {employeeDashboardInfo?.position?.name}
        </div>
      </div>

      <div className="card-wrapper">
        <div className="card bg-1">
          <div className="name color-1">{t('on_going_task')}</div>
          <div className="value color-1">{summary.on_going_task}</div>
        </div>
        <div className="card bg-1">
          <div className="name color-1">{t('upcoming_task')}</div>
          <div className="value color-1">{summary.upcoming_task}</div>
        </div>
        <div className="card bg-1">
          <div className="name color-1">{t('completed_task')}</div>
          <div className="value color-1">{summary.completed_task}</div>
        </div>
        <div className="card bg-1">
          <div className="name color-1">{t('delayed_task')}</div>
          <div className="value color-1">{summary.delayed_task}</div>
        </div>
        <div className="card bg-2">
          <div className="name color-2">{t('total_value')}</div>
          <div className="value color-2">{summary.total_value}</div>
        </div>
      </div>

      <div className="data-wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <div className="header mb-1-rem">
              <div className="group">
                <div className="table-title">{t('task')}</div>
              </div>
            </div>

            <div className="tab-container-table">
              <div className={`tab ${tab === 'on-going' ? 'active' : ''}`} onClick={() => setTab('on-going')}>
                {t('on_going')}{' '}
                <span className={`badge-count ${tab === 'on-going' ? 'active' : ''}`}>{summary.on_going_task}</span>
              </div>
              <div className={`tab ${tab === 'upcoming' ? 'active' : ''}`} onClick={() => setTab('upcoming')}>
                {t('upcoming')}{' '}
                <span className={`badge-count ${tab === 'upcoming' ? 'active' : ''}`}>{summary.upcoming_task}</span>
              </div>
              <div className={`tab ${tab === 'complete' ? 'active' : ''}`} onClick={() => setTab('complete')}>
                {t('complete')}{' '}
                <span className={`badge-count ${tab === 'complete' ? 'active' : ''}`}>{summary.completed_task}</span>
              </div>
              <div className={`tab ${tab === 'delayed' ? 'active' : ''}`} onClick={() => setTab('delayed')}>
                {t('delayed')}{' '}
                <span className={`badge-count ${tab === 'delayed' ? 'active' : ''}`}>{summary.delayed_task}</span>
              </div>
            </div>
          </div>
          <Table columns={taskColumn} data={taskData} page={1} pageCount={1} />
        </div>
        <div className="summary-wrapper">
          <div className="table-wrapper-2 mb-1-rem">
            <div className="table-header">
              <div className="header">
                <div className="group">
                  <div className="table-title">{t('current_project')}</div>
                </div>
              </div>
            </div>
            <Table columns={currentProjectColumn} data={currentProject} />
          </div>

          <div className="table-wrapper-2 mb-1-rem">
            <div className="table-header">
              <div className="header">
                <div className="group">
                  <div className="table-title">{t('daily_summary_report')}</div>
                </div>
              </div>
            </div>
            <Table columns={dailySummaryReportColumn} data={dailySummaryReport} />
          </div>
        </div>
      </div>

      {isShowDialogPreviewer && (
        <DialogTaskPreviewer
          taskId={taskId}
          open={isShowDialogPreviewer}
          onClose={() => setIsShowDialogPreviewer(false)}
          previewType={currentPreviewType}
          onEditTask={() => {
            setCurrentTaskType('edit_task')
            setIsShowDialogPreviewer(false)
            setIsShowDialogTaskEditor(true)
          }}
        />
      )}

      {isShowDialogTaskEditor && (
        <DialogTaskEditor
          taskId={currentTaskType == 'edit_task' && taskId}
          type={currentTaskType}
          open={isShowDialogTaskEditor}
          onClose={() => setIsShowDialogTaskEditor(false)}
          onSuccess={() => setIsShowDialogTaskEditor(false)}
        />
      )}
    </Div>
  )
}

export default EmployeeDashboardDetail
