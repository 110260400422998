import { combineReducers } from 'redux'
import { GET_TASK_EMPLOYEE } from '../actionTypes'

const initialState = {
  taskEmployeeList: [],
}

const taskEmployeeReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_TASK_EMPLOYEE.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        taskEmployeeList: data,
        isLoading: false,
      }
    }

    default:
      return state
  }
}

export default combineReducers({
  taskEmployeeReducer,
})
