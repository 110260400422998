import ButtonUnstyled from '@mui/base/Button'
import styled from 'styled-components'

const StyledButton = styled(ButtonUnstyled)`
  display: inline-flex;
  align-items: center;
  padding: 10px 18px;
  /*font-family: 'Inter';*/
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  height: 40px;
  text-align: center;
  color: var(--Main-Button-Font);
  background: var(--Main-Button-Bg);
  border: 1px solid var(--Main-Button-Bg);
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    color: var(--Main-Button-Font-Hover);
    background: var(--Main-Button-Bg-Hover);
  }

  .append,
  .prepend {
    display: flex;
    align-items: center;
  }

  .append {
    margin-right: 10px;
  }

  .prepend {
    margin-left: 10px;
  }

  &.Mui-disabled {
    opacity: 0.4;
    cursor: default;
  }

  .children-content {
    width: 100%;
  }
`

const Button = ({ append, prepend, children, ...props }) => {
  return (
    <StyledButton {...props}>
      {append && <span className="append">{append}</span>}
      <div className="children-content">{children}</div>
      {prepend && <span className="prepend">{prepend}</span>}
    </StyledButton>
  )
}

export default Button
