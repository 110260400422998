import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { DateDisplay } from '../../../utils/common'

import { selectMaster, selectEmployeeActivity } from '../../../redux/selectors'
import {
  getAnalysisEmployeeByOrganization,
  getAnalysisEmployeeByDepartment,
  getEmployeeList,
} from '../../../redux/actions/employeeActivity'

import styled from 'styled-components'
import Dropdown from '../../form/Dropdown'
import ButtonOutline from '../../form/button/ButtonOutline'
import Card from '../../common/Card'
import Table from '../../common/Table'
import { PieChart, BarChart } from '@mui/x-charts'
import SearchText from '../../form/SearchText'

import { TbPointFilled } from 'react-icons/tb'
import { BsGraphUpArrow } from 'react-icons/bs'
import { MdOutlineArrowDownward, MdOutlineArrowUpward } from 'react-icons/md'
import { FiUsers } from 'react-icons/fi'
import { LiaTimesSolid } from 'react-icons/lia'

import noData from '../../../assets/images/no-data.png'

// style
const Wrapper = styled.div`
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    color: #000;
    font-size: 0.875rem;
    font-style: normal;
  }
  .select-view-container {
    display: flex;
    align-items: flex-end;
  }
  .chart-container {
    position: relative;
    width: 100%;
    height: 300px;
  }
  .chart-label-container {
    position: absolute;
    right: 2%;
    top: 10%;
    .pie-chart-color-label-1 {
      color: var(--Chart-Color-1);
    }
    .pie-chart-color-label-2 {
      color: var(--Chart-Color-2);
    }
    .pie-chart-color-label-3 {
      color: var(--Gray-200);
    }
  }
  .highlight-black {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
  .headline {
    color: var(--Gray-900);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
  }
  .badge {
    border-radius: 16px;
    border: 1px solid var(--BADGE-1-Border);
    background: var(--BADGE-1-Bg);

    color: var(--BADGE-1-Font);
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;

    display: inline-block;
    padding: 2px 8px;

    &.pink-badge {
      border: 1px solid var(--BADGE-12-Border);
      background: var(--BADGE-12-Bg);
      color: var(--BADGE-12-Font);
    }

    &.blue-badge {
      border: 1px solid var(--BADGE-5-Border);
      background: var(--BADGE-5-Bg);
      color: var(--BADGE-5-Font);
    }

    &.green-badge {
      border: 1px solid var(--BADGE-4-Border);
      background: var(--BADGE-4-Bg);
      color: var(--BADGE-4-Font);
    }

    &.red-badge {
      border: 1px solid var(--BADGE-2-Border);
      background: var(--BADGE-2-Bg);
      color: var(--BADGE-2-Font);
    }
  }
  .img-container {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  .name-employee {
    color: var(--Gray-900);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .period-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`
const InputContainer = styled.div`
  margin-right: 0.75rem;
  &.width-20 {
    width: 20%;
  }
  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.375rem;
  }
`
const Flex3Content = styled.div`
  display: flex;
  justify-content: space-between;

  .flex-item {
    width: 32%;
  }
`
const Flex2Content = styled.div`
  display: flex;
  justify-content: space-between;

  .flex-item-1 {
    width: 32%;
  }
  .flex-item-2 {
    width: 66%;
  }
`
const CardContent = styled.div`
  .card-headline {
    color: var(--Gray-900);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .card-sub-headline {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
  .icon-badge {
    color: var(--Primary-600);
    border-radius: 10px;
    background: var(--Primary-200);
    padding: 12px 12px 8px 12px;

    display: inline-block;
  }
  .over-all-text {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
  }
  .percent-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .percent-value {
      color: var(--Gray-900);
      font-size: 3rem;
      font-style: normal;
      font-weight: 600;
    }
    .percent-compare {
      font-size: 0.875rem;
      color: var(--Gray-600);
      font-style: normal;
      font-weight: 500;

      .highlight {
        &.red-highlight {
          color: var(--Error-700);
        }
        &.green-highlight {
          color: var(--success-700);
        }
      }
    }
  }
  .info-container {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    .value {
      color: var(--Gray-900);
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 600;
    }
  }
  .dot {
    &.pink {
      color: #ee46bc;
    }
    &.blue {
      color: #2970ff;
    }
  }
`
const EmployeeActivitySummaryContainer = styled.div`
  .employee-activity-summary-headline {
    color: var(--Gray-900);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .employee-activity-summary-sub-headline {
    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
  .table-wrapper {
    .table-header {
      padding: 1rem 0.875rem;
      border-radius: 0.5rem 0.5rem 0 0;
      background: var(--Base-White);
      border: 0.0625rem solid var(--Gray-200);

      .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.25rem;

        .table-header-name {
          color: var(--Gray-900);
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          margin-right: 0.5rem;
        }
      }

      .search-container {
        display: flex;
        .clear-button-container {
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }
`
const GroupButtonMenuContainer = styled.div`
  .menu-button {
    color: var(--Gray-700);
    font-weight: 600;
    padding: 10px 16px;
    border: 1px solid var(--Gray-300);
    cursor: pointer;
    &.right {
      border-radius: 0 8px 8px 0;
    }
    &.left {
      border-radius: 8px 0 0 8px;
    }
    &.active {
      color: var(--Base-White);
      background: var(--Primary-600);
      border: 1px solid var(--Primary-600);
    }
  }
`
const NoDataChart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 45px;

  .no-data-chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${(props) => props.bgImage});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 200px;
    width: 300px;
  }
  .no-data-chart-text {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
  }
`
const BadgeSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .badge {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`

// initial data
const initEmployeeTypeList = [
  {
    value: 'Permanent',
    text: 'Employee',
  },
  {
    value: 'Probation',
    text: 'Probation',
  },
]
const initEvaluationResultList = [
  {
    value: 'Pass',
    text: 'Pass',
  },
  {
    value: 'Not Pass',
    text: 'Not Pass',
  },
]
function Dashboard() {
  // column
  const departmentActivityColumn = [
    {
      Header: 'Department',
      accessor: 'department',
      disableSortBy: false,
    },
    {
      Header: 'Total Task',
      accessor: 'total_task',
      disableSortBy: false,
    },
    {
      Header: 'Complete',
      accessor: 'complete',
      disableSortBy: false,
    },
    {
      Header: '%Complete',
      accessor: 'percent_complete',
      disableSortBy: false,
    },
  ]
  const positionActivityColumn = [
    {
      Header: 'Position',
      accessor: 'position',
      disableSortBy: false,
    },
    {
      Header: 'Total Task',
      accessor: 'total_task',
      disableSortBy: false,
    },
    {
      Header: 'Complete',
      accessor: 'complete',
      disableSortBy: false,
    },
    {
      Header: '%Complete',
      accessor: 'percent_complete',
      disableSortBy: false,
    },
  ]
  const topEmployeeColumn = [
    {
      Header: 'Name',
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: 'Department',
      accessor: 'department_name',
      disableSortBy: false,
    },
    {
      Header: 'Position',
      accessor: 'position_name',
      disableSortBy: false,
    },
    {
      Header: 'Employee type',
      accessor: 'employee_type',
      disableSortBy: false,
    },
    {
      Header: 'Score',
      accessor: 'overall_perf_score',
      disableSortBy: false,
    },
  ]
  const employeeListColumn = [
    {
      Header: 'No',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Name',
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: 'Position',
      accessor: 'position',
      disableSortBy: false,
    },
    {
      Header: 'Employee type',
      accessor: 'employee_type',
      disableSortBy: false,
    },
    {
      Header: 'Score',
      accessor: 'overall_perf_score',
      disableSortBy: false,
    },
    {
      Header: 'Evaluate result',
      accessor: 'performance_eva',
      disableSortBy: false,
    },
    {
      Header: 'Analysis and Recommendation',
      accessor: 'detail_analysis',
      disableSortBy: false,
    },
    {
      accessor: 'action',
      disableSortBy: false,
    },
  ]

  const dispatch = useDispatch()

  // store reducer
  const { departments } = useSelector(selectMaster)
  const { analysisActivityEmployee, employeeList, page, pageCount, limit } = useSelector(selectEmployeeActivity)
  // data table
  const [topEmployeeList, setTopEmployeeList] = useState([])
  const [employees, setEmployees] = useState([])
  // bar chart data
  const [barChartData, setBarChartData] = useState([1])
  const [barChartName, setBarChartName] = useState(['data'])
  // dropdown list
  const [departmentList, setDepartmentList] = useState([])
  const [positionList, setPositionList] = useState([])
  // filter
  const [selectViewSelected, setSelectViewSelected] = useState()
  const [periodType, setPeriodType] = useState('last30day')
  const [search, setSearch] = useState('')
  const [position, setPosition] = useState('')
  const [employeeType, setEmployeeType] = useState('')
  const [evaluationResult, setEvaluationResult] = useState('')
  const [searchFilters, setSearchFilters] = useState([])

  const fetchEmployeeList = useCallback(
    (filter) => {
      dispatch(getEmployeeList(filter))
    },
    [dispatch]
  )

  // useEffect for mapping data table of Top Employees
  useEffect(() => {
    if (analysisActivityEmployee?.top_ten_score?.length > 0) {
      const newTopEmployeeList = analysisActivityEmployee?.top_ten_score?.map((v) => ({
        ...v,
        name: (
          <div className="img-container">
            <img className="mr-8-px" src={v.photo} alt={`member-img-${v.id}`} />
            <span className="name-employee">
              {v.first_name} {v.last_name}
            </span>
          </div>
        ),
        employee_type: (
          <div className={`badge ${v.employee_type === 'Probation' ? 'blue-badge' : 'pink-badge'}`}>
            {v.employee_type === 'Permanent' ? 'Employee' : v.employee_type}
          </div>
        ),
      }))
      setTopEmployeeList(newTopEmployeeList)
    } else {
      setTopEmployeeList([])
    }
  }, [analysisActivityEmployee?.top_ten_score])

  // useEffect for filtering by select view
  useEffect(() => {
    if (selectViewSelected === 1 && analysisActivityEmployee?.job_department_activity?.length > 0) {
      const barChartNameList = analysisActivityEmployee?.job_department_activity.map((v) => v.department)
      const barChartDataList = analysisActivityEmployee?.job_department_activity.map((v) => Number(v.percent_complete))
      setBarChartName(barChartNameList)
      setBarChartData(barChartDataList)
    } else if (selectViewSelected !== 1 && analysisActivityEmployee?.job_position_activity?.length > 0) {
      const barChartNameList = analysisActivityEmployee?.job_position_activity?.map((v) => v.position)
      const barChartDataList = analysisActivityEmployee?.job_position_activity?.map((v) => Number(v.percent_complete))
      setBarChartName(barChartNameList)
      setBarChartData(barChartDataList)
    } else {
      setBarChartName(null)
      setBarChartData(null)
    }
  }, [
    analysisActivityEmployee?.job_department_activity,
    analysisActivityEmployee?.job_position_activity,
    selectViewSelected,
  ])

  // useEffect for toggle set default select view when there are elements in departmentList
  useEffect(() => {
    if (departmentList.length > 0) {
      setSelectViewSelected(1)
    }
  }, [departmentList])

  // useEffect for mapping employee data table
  useEffect(() => {
    const newEmployees = employeeList.map((v, i) => ({
      ...v,
      no: i + 1,
      name: (
        <div className="img-container">
          <img className="mr-8-px" src={v.photo} alt={`member-img-${v.id}`} />
          <span className="name-employee">
            {v.first_name} {v.last_name}
          </span>
        </div>
      ),
      employee_type: (
        <div className={`badge ${v.employee_type === 'Probation' ? 'blue-badge' : 'pink-badge'}`}>
          {v.employee_type === 'Permanent' ? 'Employee' : v.employee_type}
        </div>
      ),
      performance_eva: v.performance_eva ? (
        <div className={`badge ${v.performance_eva === 'Pass' ? 'green-badge' : 'red-badge'}`}>{v.performance_eva}</div>
      ) : (
        '-'
      ),
      detail_analysis: truncateText(v.detail_analysis, 30),
    }))
    setEmployees(newEmployees)
  }, [employeeList])

  // useEffect for dispatching follow select view selected
  useEffect(() => {
    if (selectViewSelected === 1) {
      const filter = {
        period_type: periodType,
      }
      dispatch(getAnalysisEmployeeByOrganization(filter))
    } else if (selectViewSelected) {
      const filter = {
        period_type: periodType,
        id: selectViewSelected,
      }
      dispatch(getAnalysisEmployeeByDepartment(filter))
      const filterForEmployeeList = {
        page: 1,
        pageSize: limit,
        period_type: periodType,
        department: selectViewSelected,
      }
      fetchEmployeeList(filterForEmployeeList)
      const { positions } = departments.find((v) => v.id === selectViewSelected)
      const newPositionList = positions.map((v) => ({
        text: v.name,
        value: v.id,
      }))
      setPositionList(newPositionList)
    }
  }, [selectViewSelected, periodType])

  // useEffect for mapping department option list dropdown
  useEffect(() => {
    if (departments.length > 0) {
      const newDepartmentList = departments.map((v) => ({
        value: v.id,
        text: v.name,
      }))
      setDepartmentList([{ text: 'Organization', value: 1 }, ...newDepartmentList])
    }
  }, [departments])

  // useEffect for filter by dropdown
  useEffect(() => {
    if (selectViewSelected !== 1 || position !== '' || employeeType !== '' || evaluationResult !== '') {
      const multipleSearch = searchFilters.map((v) => v.name).join(',')
      const request = {
        page: 1,
        pageSize: limit,
        department: selectViewSelected,
        position: position,
        employee_type: employeeType,
        evaluation: evaluationResult,
        period_type: periodType,
        name: multipleSearch,
      }
      fetchEmployeeList(request)
    }
  }, [selectViewSelected, position, employeeType, evaluationResult])

  // filter
  const filterByDay = (periodDay) => {
    setPeriodType(periodDay)
  }
  const onChangePagination = async (value) => {
    const multipleSearch = searchFilters.map((v) => v.name).join(',')
    const filter = {
      page: value.page,
      pageSize: limit,
      department: department,
      position: position,
      employee_type: employeeType,
      evaluation: evaluationResult,
      name: multipleSearch,
    }
    fetchData(filter)
  }
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v) => v.name).join(',')
      const filter = {
        page: 1,
        pageSize: limit,
        department: selectViewSelected,
        position: position,
        employee_type: employeeType,
        evaluation: evaluationResult,
        name: multipleSearch,
      }
      fetchEmployeeList(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)

    const multipleSearch = newSearchFilters.map((v) => v.name).join(',')
    const filter = {
      page: 1,
      pageSize: limit,
      department: selectViewSelected,
      position: position,
      employee_type: employeeType,
      evaluation: evaluationResult,
      name: multipleSearch,
    }
    fetchEmployeeList(filter)
    setSearch('')
  }
  const onClearSearch = () => {
    const request = {
      page: 1,
      pageSize: limit,
      department: selectViewSelected,
      position: '',
      employee_type: '',
      evaluation: '',
    }
    fetchEmployeeList(request)
    clearFilter()
  }
  const clearFilter = () => {
    setSearch('')
    setPosition('')
    setEmployeeType('')
    setEvaluationResult('')
    setSearchFilters([])
  }

  const truncateText = (text, length) => {
    if (text.length > length) {
      return text.substring(0, length) + '...'
    } else {
      return text
    }
  }

  return (
    <Wrapper>
      <div className="header-container mb-32-px">
        <div className="select-view-container">
          <InputContainer className="mr-12-px">
            <div className="input-header">Select view</div>
            <Dropdown
              optionList={departmentList}
              value={selectViewSelected}
              onChange={(value) => {
                setSelectViewSelected(value)
                clearFilter()
              }}
            />
          </InputContainer>
        </div>

        <div className="period-container">
          <GroupButtonMenuContainer className="mb-20-px">
            <span
              className={`menu-button left ${periodType === 'last30day' ? 'active' : ''}`}
              onClick={() => filterByDay('last30day')}
            >
              Last 30 days
            </span>
            <span
              className={`menu-button right ${periodType === 'last7day' ? 'active' : ''}`}
              onClick={() => filterByDay('last7day')}
            >
              Last 7 days
            </span>
          </GroupButtonMenuContainer>
          <div>
            <span className="highlight-black">Data Period</span> :{' '}
            {DateDisplay(analysisActivityEmployee?.period_start_date)} -{' '}
            {DateDisplay(analysisActivityEmployee?.period_end_date)}
          </div>
        </div>
      </div>

      <div className="headline mb-32-px">Activity Overview : {analysisActivityEmployee?.company_name}</div>

      <Flex3Content className="mb-32-px">
        <Card className="flex-item">
          <CardContent>
            <div className="icon-badge mb-20-px">
              <BsGraphUpArrow size={25} />
            </div>
            <div className="over-all-text mb-8-px">Overall Activity Score</div>
            <div className="percent-info-container">
              <div className="percent-value">{analysisActivityEmployee?.overall_activity_score_percent ?? 0} %</div>
              {analysisActivityEmployee?.overall_activity_score_percent_old && (
                <div className="percent-compare">
                  {analysisActivityEmployee?.overall_activity_score_percent_old?.status === 'up' ? (
                    <MdOutlineArrowUpward className="highlight green-highlight" />
                  ) : analysisActivityEmployee?.overall_activity_score_percent_old?.status === 'down' ? (
                    <MdOutlineArrowDownward className="highlight red-highlight" />
                  ) : (
                    <span>{analysisActivityEmployee?.overall_activity_score_percent_old?.status} </span>
                  )}
                  <span
                    className={`highlight mr-8-px ${
                      analysisActivityEmployee?.overall_activity_score_percent_old?.status === 'up'
                        ? 'green-highlight'
                        : analysisActivityEmployee?.overall_activity_score_percent_old?.status === 'down'
                        ? 'red-highlight'
                        : ''
                    }`}
                  >
                    {analysisActivityEmployee?.overall_activity_score_percent_old?.percent}%
                  </span>
                  <span>vs last month</span>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
        <Card className="flex-item">
          <CardContent>
            <div className="icon-badge mb-20-px">
              <FiUsers size={25} />
            </div>
            <div className="over-all-text mb-8-px">Total Employee</div>
            <div className="percent-info-container">
              <div className="percent-value">{analysisActivityEmployee?.total_employee ?? 0}</div>
              {analysisActivityEmployee?.total_employee_old && (
                <div className="percent-compare">
                  {analysisActivityEmployee?.total_employee_old?.status === 'up' ? (
                    <MdOutlineArrowUpward className="highlight green-highlight" />
                  ) : analysisActivityEmployee?.total_employee_old?.status === 'down' ? (
                    <MdOutlineArrowDownward className="highlight red-highlight" />
                  ) : (
                    <span>{analysisActivityEmployee?.total_employee_old?.status} </span>
                  )}
                  <span
                    className={`highlight mr-8-px ${
                      analysisActivityEmployee?.total_employee_old?.status === 'up'
                        ? 'green-highlight'
                        : analysisActivityEmployee?.total_employee_old?.status === 'down'
                        ? 'red-highlight'
                        : ''
                    }`}
                  >
                    {analysisActivityEmployee?.total_employee_old?.percent}%
                  </span>
                  <span>vs last month</span>
                </div>
              )}
            </div>
          </CardContent>
        </Card>
        <Card className="flex-item">
          <CardContent>
            <Flex2Content className="mb-32-px">
              <div className="info-container">
                <div>
                  <TbPointFilled size={16} className="dot pink" />
                  Permanent employee
                </div>
                <div className="value">
                  {analysisActivityEmployee?.overall_activity_score_percent_permanent?.employee ?? 0}
                </div>
              </div>
              <div className="info-container">
                <div>Avg. score</div>
                <div className="value">
                  {analysisActivityEmployee?.overall_activity_score_percent_permanent?.avg_scort ?? 0}%
                </div>
              </div>
            </Flex2Content>
            <Flex2Content>
              <div className="info-container">
                <div>
                  <TbPointFilled size={16} className="dot blue" />
                  Probation employee
                </div>
                <div className="value">
                  {analysisActivityEmployee?.overall_activity_score_percent_probation?.employee ?? 0}
                </div>
              </div>
              <div className="info-container">
                <div>Avg. score</div>
                <div className="value">
                  {analysisActivityEmployee?.overall_activity_score_percent_probation?.avg_scort ?? 0}%
                </div>
              </div>
            </Flex2Content>
          </CardContent>
        </Card>
      </Flex3Content>

      <Flex3Content className="mb-32-px">
        <Card className="flex-item">
          <CardContent>
            <div className="card-headline">All Employee Evaluation Overview</div>
            <div className="card-sub-headline">Proportion of employee who pass the score (%)</div>
            <div className="chart-container">
              {(analysisActivityEmployee?.all_employee_eva?.pass === 0 &&
                analysisActivityEmployee?.all_employee_eva?.not_pass === 0) ||
              !analysisActivityEmployee?.all_employee_eva ? (
                <NoDataChart bgImage={noData}>
                  <div className="no-data-chart-container">
                    <div className="no-data-chart-text">No data available</div>
                  </div>
                </NoDataChart>
              ) : (
                <>
                  <div className="chart-label-container">
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-1" size={20} />
                      <span>Pass</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-3" size={20} />
                      <span>Did not pass</span>
                    </div>
                  </div>
                  <PieChart
                    colors={['var(--Chart-Color-1)', 'var(--Gray-200)']}
                    series={[
                      {
                        data: [
                          { value: analysisActivityEmployee?.all_employee_eva?.pass },
                          { value: analysisActivityEmployee?.all_employee_eva?.not_pass },
                        ],
                        innerRadius: 45,
                        outerRadius: 82,
                        paddingAngle: 0,
                        cornerRadius: 0,
                        startAngle: -180,
                        endAngle: 180,
                        cx: 150,
                      },
                    ]}
                    sx={{ width: '100%', height: 'auto' }}
                  />
                </>
              )}
            </div>
          </CardContent>
        </Card>
        <Card className="flex-item">
          <CardContent>
            <div className="card-headline">Permanent Employee Evaluation</div>
            <div className="card-sub-headline">Proportion of employee who pass the score (%)</div>
            <div className="chart-container">
              {(analysisActivityEmployee?.permanent_employee_eva?.pass === 0 &&
                analysisActivityEmployee?.permanent_employee_eva?.not_pass === 0) ||
              !analysisActivityEmployee?.permanent_employee_eva ? (
                <NoDataChart bgImage={noData}>
                  <div className="no-data-chart-container">
                    <div className="no-data-chart-text">No data available</div>
                  </div>
                </NoDataChart>
              ) : (
                <>
                  <div className="chart-label-container">
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-1" size={20} />
                      <span>Pass</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-3" size={20} />
                      <span>Did not pass</span>
                    </div>
                  </div>
                  <PieChart
                    colors={['var(--Chart-Color-1)', 'var(--Gray-200)']}
                    series={[
                      {
                        data: [
                          { value: analysisActivityEmployee?.permanent_employee_eva?.pass },
                          { value: analysisActivityEmployee?.permanent_employee_eva?.not_pass },
                        ],
                        innerRadius: 45,
                        outerRadius: 82,
                        paddingAngle: 0,
                        cornerRadius: 0,
                        startAngle: -180,
                        endAngle: 180,
                        cx: 150,
                      },
                    ]}
                    sx={{ width: '100%', height: 'auto' }}
                  />
                </>
              )}
            </div>
          </CardContent>
        </Card>
        <Card className="flex-item">
          <CardContent>
            <div className="card-headline">Probation Employee Evaluation</div>
            <div className="card-sub-headline">Proportion of employee who pass the score (%)</div>
            <div className="chart-container">
              {(analysisActivityEmployee?.probation_employee_eva?.pass === 0 &&
                analysisActivityEmployee?.probation_employee_eva?.not_pass === 0) ||
              !analysisActivityEmployee?.probation_employee_eva ? (
                <NoDataChart bgImage={noData}>
                  <div className="no-data-chart-container">
                    <div className="no-data-chart-text">No data available</div>
                  </div>
                </NoDataChart>
              ) : (
                <>
                  <div className="chart-label-container">
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-1" size={20} />
                      <span>Pass</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-3" size={20} />
                      <span>Did not pass</span>
                    </div>
                  </div>
                  <PieChart
                    colors={['var(--Chart-Color-1)', 'var(--Gray-200)']}
                    series={[
                      {
                        data: [
                          { value: analysisActivityEmployee?.probation_employee_eva?.pass },
                          { value: analysisActivityEmployee?.probation_employee_eva?.not_pass },
                        ],
                        innerRadius: 45,
                        outerRadius: 82,
                        paddingAngle: 0,
                        cornerRadius: 0,
                        startAngle: -180,
                        endAngle: 180,
                        cx: 150,
                      },
                    ]}
                    sx={{ width: '100%', height: 'auto' }}
                  />
                </>
              )}
            </div>
          </CardContent>
        </Card>
      </Flex3Content>

      <Flex2Content className="mb-32-px">
        <Card className="flex-item-1">
          <CardContent>
            <div className="card-headline">
              {selectViewSelected === 1 ? 'Department Activity Tracking' : 'Job Positioning Activity Tracking'}
            </div>
            <div className="card-sub-headline mb-20-px">
              Activities and performance by {selectViewSelected === 1 ? 'department' : 'job position'}
            </div>
            {selectViewSelected === 1 ? (
              <Table columns={departmentActivityColumn} data={analysisActivityEmployee?.job_department_activity} />
            ) : (
              <Table columns={positionActivityColumn} data={analysisActivityEmployee?.job_position_activity} />
            )}
          </CardContent>
        </Card>
        <Card className="flex-item-2">
          <CardContent>
            <div className="card-headline">
              Average {selectViewSelected === 1 ? 'Department' : 'Job Position'} Activity Score
            </div>
            <div className="card-sub-headline">
              Compare activity score by {selectViewSelected === 1 ? 'department' : 'job position'}
            </div>
            {barChartData && barChartName ? (
              <BarChart
                height={300}
                series={[{ data: barChartData, color: '#9E77ED' }]}
                xAxis={[{ data: barChartName, scaleType: 'band', categoryGapRatio: 0.7 }]}
                yAxis={[
                  {
                    scaleType: 'linear',
                    min: 0,
                    max: 100,
                  },
                ]}
              />
            ) : (
              <NoDataChart bgImage={noData}>
                <div className="no-data-chart-container">
                  <div className="no-data-chart-text">No data available</div>
                </div>
              </NoDataChart>
            )}
          </CardContent>
        </Card>
      </Flex2Content>
      <Flex2Content className="mb-32-px">
        <Card className="flex-item-1">
          <CardContent>
            <div className="card-headline">Task Complete Overview</div>
            <div className="card-sub-headline">Overall % task complete in the company</div>
            <div className="chart-container">
              {(analysisActivityEmployee?.task_complete_overview?.complete === 0 &&
                analysisActivityEmployee?.task_complete_overview?.inprogress === 0 &&
                analysisActivityEmployee?.task_complete_overview?.incomplete === 0) ||
              !analysisActivityEmployee?.task_complete_overview ? (
                <NoDataChart bgImage={noData}>
                  <div className="no-data-chart-container">
                    <div className="no-data-chart-text">No data available</div>
                  </div>
                </NoDataChart>
              ) : (
                <>
                  <div className="chart-label-container">
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-1" size={20} />
                      <span>Complete</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-2" size={20} />
                      <span>In progress</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <TbPointFilled className="pie-chart-color-label-3" size={20} />
                      <span>Incomplete</span>
                    </div>
                  </div>
                  <PieChart
                    colors={['var(--Chart-Color-1)', 'var(--Chart-Color-2)', 'var(--Gray-200)']}
                    series={[
                      {
                        data: [
                          { value: analysisActivityEmployee?.task_complete_overview?.complete },
                          { value: analysisActivityEmployee?.task_complete_overview?.inprogress },
                          { value: analysisActivityEmployee?.task_complete_overview?.incomplete },
                        ],
                        innerRadius: 45,
                        outerRadius: 82,
                        paddingAngle: 0,
                        cornerRadius: 0,
                        startAngle: -180,
                        endAngle: 180,
                        cx: 150,
                      },
                    ]}
                    sx={{ width: '100%', height: 'auto' }}
                  />
                </>
              )}
            </div>
          </CardContent>
        </Card>
        <Card className="flex-item-2">
          <CardContent>
            <div className="card-headline">Top Employee</div>
            <div className="card-sub-headline mb-20-px">
              The best perform employee in this company (by the highest score)
            </div>
            <Table columns={topEmployeeColumn} data={topEmployeeList} />
          </CardContent>
        </Card>
      </Flex2Content>

      {selectViewSelected !== 1 && (
        <EmployeeActivitySummaryContainer>
          <div className="employee-activity-summary-headline">Employee Activity Summary</div>
          <div className="employee-activity-summary-sub-headline mb-32-px">See how everyone perform.</div>
          <div className="table-wrapper">
            <div className="table-header">
              <div className="header-container">
                <div>
                  <span className="table-header-name">{analysisActivityEmployee?.department_name} Employee list</span>
                  <span className="badge">{employees.length} users</span>
                </div>
              </div>
              <div className="search-container mb-1-rem">
                <InputContainer>
                  <div className="input-header">Search</div>
                  <SearchText
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onSubmit={onSearch}
                    onClear={() => setSearch('')}
                  />
                </InputContainer>
                <InputContainer className="width-20">
                  <div className="input-header">Position</div>
                  <Dropdown optionList={positionList} value={position} onChange={(value) => setPosition(value)} />
                </InputContainer>
                <InputContainer className="width-20">
                  <div className="input-header">Employee type</div>
                  <Dropdown
                    optionList={initEmployeeTypeList}
                    value={employeeType}
                    onChange={(value) => setEmployeeType(value)}
                  />
                </InputContainer>
                <InputContainer className="width-20">
                  <div className="input-header">Evaluation result</div>
                  <Dropdown
                    optionList={initEvaluationResultList}
                    value={evaluationResult}
                    onChange={(value) => setEvaluationResult(value)}
                  />
                </InputContainer>
                <div className="clear-button-container">
                  <ButtonOutline onClick={onClearSearch}>Clear all</ButtonOutline>
                </div>
              </div>

              <BadgeSearchContainer>
                {searchFilters.map((v) => (
                  <div className="badge" key={v.key}>
                    <span className="name">{v.name}</span>{' '}
                    <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
                  </div>
                ))}
              </BadgeSearchContainer>
            </div>
            <Table
              columns={employeeListColumn}
              data={employees}
              page={page}
              pageCount={pageCount}
              onStateChange={onChangePagination}
            />
          </div>
        </EmployeeActivitySummaryContainer>
      )}
    </Wrapper>
  )
}

export default Dashboard
