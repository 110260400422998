import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import Dropdown from '../../components/form/Dropdown'
import TextField from '../../components/form/TextField'
import MediaFile from '../../containers/cms/MediaFile'
import { useTranslation } from 'react-i18next'
import { selectProduct, selectMaterial } from '../../redux/selectors'
import {
  getProductSpecifications,
  getProductBrands,
  getProductCategories,
  getProductTypes,
  getSegments,
  getProductCosts,
  getProductPriceStructures,
  getProductManagers,
  getSuppliers,
  getProductById,
  updateProduct,
} from '../../redux/actions/product'
import { getMaterialList } from '../../redux/actions/material'

const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .content-title {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
    margin-bottom: 24px;
  }

  .input-container {
    /* max-width: 1000px; */

    .input {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }

    .input-text-area {
      display: grid;
      grid-template-columns: 15rem 40rem;
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }

    .input-file {
      display: grid;
      grid-template-columns: 15rem 30rem;
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }

    .input-header {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  .divider {
    margin: 2rem 0;
    border: 0.1rem solid #eaecf0;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;

    button {
      margin-left: 12px;
    }
  }

  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }
`

const initProductUnits = [
  {
    value: 'นิ้ว',
    text: 'นิ้ว',
  },
  {
    value: 'เมตร',
    text: 'เมตร',
  },
  {
    value: 'กิโลกรัม',
    text: 'กิโลกรัม',
  },
]

const initProductForm = {
  product_name: '',
  description: '',
  product_category: '',
  product_brand: '',
  product_type: '',
  product_sagment: '',
  product_manager: '',
  supplier: '',
  product_costs: [
    {
      key: uuidv4(),
      id: '',
      cost_value: '',
    },
  ],
  product_specifications: [
    {
      key: uuidv4(),
      id: '',
      value: '',
      unit: '',
    },
  ],
  product_formulas: [
    {
      key: uuidv4(),
      id: '',
      quantity: '',
    },
  ],
  product_price_structure: [
    {
      key: uuidv4(),
      id: '',
      value: '',
      percent: '',
    },
  ],
  images: [33, 35],
}

const allowTypes = ['images']

const EditProduct = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  // data
  const {
    productInfo,
    specifications,
    brands,
    categories,
    types,
    segments,
    costs,
    priceStructures,
    suppliers,
    productManagers,
  } = useSelector(selectProduct)
  const { materialList } = useSelector(selectMaterial)
  const [productForm, setProductForm] = useState(initProductForm)
  const [files, setFiles] = useState([])
  // selected
  const [brandSelected, setBrandSelected] = useState('')
  const [categorySelected, setCategorySelected] = useState('')
  const [typeSelected, setTypeSelected] = useState('')
  const [segmentSelected, setSegmentSelected] = useState('')
  const [supplierSelected, setSupplierSelected] = useState('')
  const [productManagerSelected, setProductManagerSelected] = useState('')
  // dropdown list
  const [specificationList, setSpecificationList] = useState([])
  const [brandList, setBrandList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [typeList, setTypeList] = useState([])
  const [segmentList, setSegmentList] = useState([])
  const [formulaList, setFormulaList] = useState([])
  const [costList, setCostList] = useState([])
  const [priceStructureList, setPriceStructureList] = useState([])
  const [supplierList, setSupplierList] = useState([])
  const [productManagerList, setProductManagerList] = useState([])
  const [productUnitList] = useState(initProductUnits)

  useEffect(() => {
    dispatch(getProductSpecifications())
    dispatch(getProductBrands())
    dispatch(getProductCategories())
    dispatch(getProductTypes())
    dispatch(getSegments())
    dispatch(getProductCosts())
    dispatch(getProductPriceStructures())
    dispatch(getProductManagers())
    dispatch(getSuppliers())
    const filter = {
      skip: 0,
      limit: 1000,
      status: 'active',
    }
    dispatch(getMaterialList(filter))
  }, [dispatch])

  useEffect(() => {
    dispatch(getProductById(id))
  }, [dispatch, id])

  useEffect(() => {
    const newProductSpecifications =
      productInfo.product_specification_details?.length > 0
        ? productInfo.product_specification_details?.map((v) => ({
            key: uuidv4(),
            id: v.product_specification?.id,
            value: v.value,
            unit: v.unit,
          }))
        : [
            {
              key: uuidv4(),
              id: '',
              value: '',
              unit: '',
            },
          ]
    const newProductCosts =
      productInfo.product_cost_details?.length > 0
        ? productInfo.product_cost_details?.map((v) => ({
            key: uuidv4(),
            id: v.product_cost?.id,
            cost_value: v.cost_value,
          }))
        : [
            {
              key: uuidv4(),
              id: '',
              cost_value: '',
            },
          ]
    const newProductFormulaDetails =
      productInfo.product_formula_details?.length > 0
        ? productInfo.product_formula_details?.map((v) => ({
            key: uuidv4(),
            id: v.material?.id,
            quantity: v.quantity,
          }))
        : [
            {
              key: uuidv4(),
              id: '',
              quantity: '',
            },
          ]
    const newProductPriceStructureDetails =
      productInfo.product_price_structure_details?.length > 0
        ? productInfo.product_price_structure_details?.map((v) => ({
            key: uuidv4(),
            id: v.product_price_struc.id,
            value: v.value,
            percent: v.percent,
          }))
        : [
            {
              key: uuidv4(),
              id: '',
              value: '',
              percent: '',
            },
          ]
    setProductForm((prev) => ({
      ...prev,
      product_name: productInfo.product_name,
      description: productInfo.description,
      product_category: productInfo.product_category?.id,
      product_brand: productInfo.product_brand?.id,
      product_type: productInfo.product_type?.id,
      product_sagment: productInfo.product_sagment?.id,
      product_manager: productInfo.product_manager?.id,
      supplier: productInfo.supplier?.id,
      product_costs: newProductCosts,
      product_specifications: newProductSpecifications,
      product_formulas: newProductFormulaDetails,
      product_price_structure: newProductPriceStructureDetails,
    }))

    setFiles(productInfo.product_images)

    setBrandSelected(productInfo.product_brand?.id)
    setCategorySelected(productInfo.product_category?.id)
    setTypeSelected(productInfo.product_type?.id)
    setSegmentSelected(productInfo.product_sagment?.id)
    setSupplierSelected(productInfo.supplier?.id)
    setProductManagerSelected(productInfo.product_manager?.id)
  }, [productInfo])

  // useEffect selected
  useEffect(() => {
    setProductForm((prev) => ({
      ...prev,
      product_brand: brandSelected,
    }))
  }, [brandSelected])
  useEffect(() => {
    setProductForm((prev) => ({
      ...prev,
      product_category: categorySelected,
    }))
  }, [categorySelected])
  useEffect(() => {
    setProductForm((prev) => ({
      ...prev,
      product_type: typeSelected,
    }))
  }, [typeSelected])
  useEffect(() => {
    setProductForm((prev) => ({
      ...prev,
      product_sagment: segmentSelected,
    }))
  }, [segmentSelected])
  useEffect(() => {
    setProductForm((prev) => ({
      ...prev,
      supplier: supplierSelected,
    }))
  }, [supplierSelected])
  useEffect(() => {
    setProductForm((prev) => ({
      ...prev,
      product_manager: productManagerSelected,
    }))
  }, [productManagerSelected])

  // useEffect dropdown list
  useEffect(() => {
    const newSpecifications = specifications?.map((v) => ({
      text: v.attributes.name,
      value: v.id,
    }))
    setSpecificationList(newSpecifications)
  }, [specifications])
  useEffect(() => {
    const newBrands = brands?.map((v) => ({
      text: v.attributes.name,
      value: v.id,
    }))
    setBrandList(newBrands)
  }, [brands])
  useEffect(() => {
    const newCategories = categories?.map((v) => ({
      text: v.attributes.product_category_name,
      value: v.id,
    }))
    setCategoryList(newCategories)
  }, [categories])
  useEffect(() => {
    const newTypes = types?.map((v) => ({
      text: v.attributes.product_type_name,
      value: v.id,
    }))
    setTypeList(newTypes)
  }, [types])
  useEffect(() => {
    const newSegments = segments?.map((v) => ({
      text: v.attributes.name,
      value: v.id,
    }))
    setSegmentList(newSegments)
  }, [segments])
  useEffect(() => {
    const newMaterials = materialList?.map((v) => ({
      text: v.material_name,
      value: v.id,
    }))
    setFormulaList(newMaterials)
  }, [materialList])
  useEffect(() => {
    const newCosts = costs?.map((v) => ({
      text: v.attributes.product_cost_name,
      value: v.id,
    }))
    setCostList(newCosts)
  }, [costs])
  useEffect(() => {
    const newPriceStructures = priceStructures?.map((v) => ({
      text: v.attributes.name,
      value: v.id,
    }))
    setPriceStructureList(newPriceStructures)
  }, [priceStructures])
  useEffect(() => {
    const newSuppliers = suppliers?.map((v) => ({
      text: v.attributes.supplier_name,
      value: v.id,
    }))
    setSupplierList(newSuppliers)
  }, [suppliers])
  useEffect(() => {
    const newProductManagers = productManagers?.map((v) => ({
      text: `${v.attributes.first_name} ${v.attributes.last_name}`,
      value: v.id,
    }))
    setProductManagerList(newProductManagers)
  }, [productManagers])

  const toArray = (file) => {
    if (Array.isArray(file)) {
      return file
    } else {
      return file ? [file] : []
    }
  }

  const onChangeUploadFile = (value) => {
    setFiles(value)
  }

  const onChangeForm = (event) => {
    const { name, value } = event.target
    setProductForm((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  // specifications dynamic form
  const onChangeSpecificationForm = (event, index) => {
    const { name, value } = event.target
    productForm.product_specifications[index][name] = value.replace(/[^0-9]/g, '')
    const newValue = productForm.product_specifications
    setProductForm((prev) => ({ ...prev, product_specifications: newValue }))
  }

  const onChangeSpecificationDropdown = (value, index, name) => {
    productForm.product_specifications[index][name] = value
    const newValue = productForm.product_specifications
    setProductForm((prev) => ({ ...prev, product_specifications: newValue }))
  }

  const addProductSpecification = () => {
    const newProductSpecifications = [
      ...productForm.product_specifications,
      {
        key: uuidv4(),
        id: '',
        value: '',
        unit: '',
      },
    ]
    setProductForm((prev) => ({ ...prev, product_specifications: newProductSpecifications }))
  }

  const deleteProductSpecification = (key) => {
    const newProductSpecifications = productForm.product_specifications.filter((v) => v.key != key)
    setProductForm((prev) => ({ ...prev, product_specifications: newProductSpecifications }))
  }

  // formula dynamic form
  const onChangeFormulaForm = (event, index) => {
    const { name, value } = event.target
    productForm.product_formulas[index][name] = value.replace(/[^0-9]/g, '')
    const newValue = productForm.product_formulas
    setProductForm((prev) => ({ ...prev, product_formulas: newValue }))
  }

  const onChangeFormulaDropdown = (value, index, name) => {
    productForm.product_formulas[index][name] = value
    const newValue = productForm.product_formulas
    setProductForm((prev) => ({ ...prev, product_formulas: newValue }))
  }

  const addFormula = () => {
    const newFormulas = [
      ...productForm.product_formulas,
      {
        key: uuidv4(),
        id: '',
        quantity: '',
      },
    ]
    setProductForm((prev) => ({ ...prev, product_formulas: newFormulas }))
  }

  const deleteFormula = (key) => {
    const newFormulas = productForm.product_formulas.filter((v) => v.key != key)
    setProductForm((prev) => ({ ...prev, product_formulas: newFormulas }))
  }

  // cost dynamic form
  const onChangeCostForm = (event, index) => {
    const { name, value } = event.target
    productForm.product_costs[index][name] = value.replace(/[^0-9]/g, '')
    const newValue = productForm.product_costs
    setProductForm((prev) => ({ ...prev, product_costs: newValue }))
  }

  const onChangeCostDropdown = (value, index, name) => {
    productForm.product_costs[index][name] = value
    const newValue = productForm.product_costs
    setProductForm((prev) => ({ ...prev, product_costs: newValue }))
  }

  const addCost = () => {
    const newFormulas = [
      ...productForm.product_costs,
      {
        key: uuidv4(),
        id: '',
        cost_value: '',
      },
    ]
    setProductForm((prev) => ({ ...prev, product_costs: newFormulas }))
  }

  const deleteCost = (key) => {
    const newFormulas = productForm.product_costs.filter((v) => v.key != key)
    setProductForm((prev) => ({ ...prev, product_costs: newFormulas }))
  }

  // price structure dynamic form
  const onChangePriceStructureForm = (event, index) => {
    const { name, value } = event.target
    productForm.product_price_structure[index][name] = value.replace(/[^0-9]/g, '')
    const newValue = productForm.product_price_structure
    setProductForm((prev) => ({ ...prev, product_price_structure: newValue }))
  }

  const onChangePriceStructureDropdown = (value, index, name) => {
    productForm.product_price_structure[index][name] = value
    const newValue = productForm.product_price_structure
    setProductForm((prev) => ({ ...prev, product_price_structure: newValue }))
  }

  const addPriceStructure = () => {
    const newFormulas = [
      ...productForm.product_price_structure,
      {
        key: uuidv4(),
        id: '',
        value: '',
        percent: '',
      },
    ]
    setProductForm((prev) => ({ ...prev, product_price_structure: newFormulas }))
  }

  const deletePriceStructure = (key) => {
    const newFormulas = productForm.product_price_structure.filter((v) => v.key != key)
    setProductForm((prev) => ({ ...prev, product_price_structure: newFormulas }))
  }

  const onSubmit = () => {
    const data = {
      ...productForm,
      id: parseInt(id),
      product_costs: productForm.product_costs?.map((v) => ({ id: v.id, cost_value: parseInt(v.cost_value) })),
      product_formulas: productForm.product_formulas?.map((v) => ({ id: v.id, quantity: parseInt(v.quantity) })),
      product_price_structure: productForm.product_price_structure?.map((v) => ({
        id: v.id,
        percent: parseInt(v.percent),
        value: parseInt(v.value),
      })),
      product_specifications: productForm.product_specifications?.map((v) => ({
        id: v.id,
        unit: v.unit,
        value: parseInt(v.value),
      })),
      images: files.map((v) => v.id),
    }
    const request = { data }
    dispatch(updateProduct(request))
    navigate('/inventory-management/products', {
      replace: true,
    })
  }
  return (
    <Div>
      <div className="content-title">{t('edit_product')}</div>
      <div className="input-container">
        <FormProvider>
          <div className="input">
            <div className="input-header">{t('product_name')}</div>
            <TextField
              placeholder={t('product_name')}
              name="product_name"
              value={productForm.product_name}
              onChange={onChangeForm}
            />
          </div>
          <div className="divider"></div>
          {productForm.product_specifications?.map((v, i) => (
            <div className="input mb-1-rem" key={v.key}>
              <div className="input-header"> {i == 0 ? 'Product Specification' : ''}</div>
              <Dropdown
                id={`specificiation-type-${v.key}`}
                optionList={specificationList}
                value={v.id}
                onChange={(value) => onChangeSpecificationDropdown(value, i, 'id')}
              />
              <TextField
                id={`specificiation-value-${v.key}`}
                name="value"
                placeholder="Value"
                value={v.value}
                onChange={(event) => onChangeSpecificationForm(event, i)}
              />
              <Dropdown
                id={`specificiation-unit-${v.key}`}
                optionList={productUnitList}
                value={v.unit}
                onChange={(value) => onChangeSpecificationDropdown(value, i, 'unit')}
              />
              {i != 0 && (
                <Button id={`delete-specification-${v.key}`} onClick={() => deleteProductSpecification(v.key)}>
                  Delete
                </Button>
              )}
            </div>
          ))}

          <div className="input">
            <div className="input-header"></div>
            <Button id="add-specification" onClick={addProductSpecification}>
              Add Attribute
            </Button>
          </div>
          <div className="divider"></div>
          <div className="input">
            <div className="input-header">{t('brand')}</div>
            <Dropdown
              id={`brand`}
              optionList={brandList}
              value={brandSelected}
              onChange={(value) => setBrandSelected(value)}
            />
          </div>
          <div className="divider"></div>
          <div className="input">
            <div className="input-header">{t('category')}</div>
            <Dropdown
              id={`category`}
              optionList={categoryList}
              value={categorySelected}
              onChange={(value) => setCategorySelected(value)}
            />
          </div>
          <div className="divider"></div>
          <div className="input">
            <div className="input-header">{t('type')}</div>
            <Dropdown
              id={`type`}
              optionList={typeList}
              value={typeSelected}
              onChange={(value) => setTypeSelected(value)}
            />
          </div>
          <div className="divider"></div>
          <div className="input">
            <div className="input-header">{t('segment')}</div>
            <Dropdown
              id={`segment`}
              optionList={segmentList}
              value={segmentSelected}
              onChange={(value) => setSegmentSelected(value)}
            />
          </div>
          <div className="divider"></div>
          {productForm.product_formulas?.map((v, i) => (
            <div className="input mb-1-rem" key={v.key}>
              <div className="input-header">{i == 0 ? 'Formula' : ''}</div>
              <Dropdown
                id={`product-formulas-type-${v.key}`}
                optionList={formulaList}
                value={v.id}
                onChange={(value) => onChangeFormulaDropdown(value, i, 'id')}
              />
              <TextField
                id={`product-formulas-quantity-${v.key}`}
                name="quantity"
                placeholder="Quantity"
                value={v.quantity}
                onChange={(event) => onChangeFormulaForm(event, i)}
              />
              {i != 0 && (
                <Button id={`delete-formula-${v.key}`} onClick={() => deleteFormula(v.key)}>
                  Delete
                </Button>
              )}
            </div>
          ))}
          <div className="input">
            <div className="input-header"></div>
            <Button id="add-formula" onClick={addFormula}>
              {t('add_attribute')}
            </Button>
          </div>
          <div className="divider"></div>
          {productForm.product_costs?.map((v, i) => (
            <div className="input mb-1-rem" key={v.key}>
              <div className="input-header">{i == 0 ? 'Cost' : ''}</div>
              <Dropdown
                id={`product-costs-type-${v.key}`}
                optionList={costList}
                value={v.id}
                onChange={(value) => onChangeCostDropdown(value, i, 'id')}
              />
              <TextField
                id={`product-costs-cost-value-${v.key}`}
                name="cost_value"
                placeholder="Price"
                value={v.cost_value}
                onChange={(event) => onChangeCostForm(event, i)}
              />
              {i != 0 && (
                <Button id={`delete-cost-${v.key}`} onClick={() => deleteCost(v.key)}>
                  {t('delete')}
                </Button>
              )}
            </div>
          ))}
          <div className="input">
            <div className="input-header"></div>
            <Button id="add-cost" onClick={addCost}>
            {t('add_attribute')}
            </Button>
          </div>
          <div className="divider"></div>
          {productForm.product_price_structure?.map((v, i) => (
            <div key={v.key}>
              <div className="input mb-1-rem">
                <div className="input-header">{i == 0 ? 'Price Structure' : ''}</div>
                <Dropdown
                  id={`product-price-structure-type-${v.key}`}
                  optionList={priceStructureList}
                  value={v.id}
                  onChange={(value) => onChangePriceStructureDropdown(value, i, 'id')}
                />
                <TextField
                  id={`product-price-structure-percent-${v.key}`}
                  name="percent"
                  placeholder="Value %"
                  value={v.percent}
                  onChange={(event) => onChangePriceStructureForm(event, i)}
                />
                {i != 0 && (
                  <Button id={`delete-price-structure-${v.key}`} onClick={() => deletePriceStructure(v.key)}>
                    {t('delete')}
                  </Button>
                )}
              </div>
              <div className="input mb-1-rem">
                <div className="input-header"></div>
                <TextField
                  id={`product-price-structure-value-${v.key}`}
                  name="value"
                  placeholder="Price"
                  value={v.value}
                  onChange={(event) => onChangePriceStructureForm(event, i)}
                />
              </div>
            </div>
          ))}

          <div className="input">
            <div className="input-header"></div>
            <Button id="add-price-structure" onClick={addPriceStructure}>
            {t('add_attribute')}
            </Button>
          </div>
          <div className="divider"></div>
          <div className="input">
            <div className="input-header">{t('supplier')}</div>
            <Dropdown
              id={`supplier`}
              optionList={supplierList}
              value={supplierSelected}
              onChange={(value) => setSupplierSelected(value)}
            />
          </div>
          <div className="divider"></div>
          <div className="input">
            <div className="input-header">{t('product_manager')}</div>
            <Dropdown
              id={`product-manager`}
              optionList={productManagerList}
              value={productManagerSelected}
              onChange={(value) => setProductManagerSelected(value)}
            />
          </div>
          <div className="divider"></div>
          <div className="input-text-area">
            <div className="input-header">{t('description')}</div>
            <textarea
              name="description"
              value={productForm.description}
              onChange={onChangeForm}
              className={`text-area mb-1-rem`}
            ></textarea>
          </div>
          <div className="divider"></div>
          <div className="input-file">
            <div className="input-header">{t('upload_product_picture')}</div>
            <MediaFile multiple={true} allowedTypes={allowTypes} file={toArray(files)} onChange={onChangeUploadFile} />
          </div>
          <div className="divider"></div>
          <div className="button-wrapper">
            <ButtonOutline
              onClick={() =>
                navigate('/inventory-management/products', {
                  replace: true,
                })
              }
            >
              {t('cancel')}
            </ButtonOutline>
            <Button onClick={onSubmit}>{t('save')}</Button>
          </div>
        </FormProvider>
      </div>
    </Div>
  )
}

export default EditProduct
