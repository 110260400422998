import { Stack } from '@mui/material'
import { styled } from '@mui/system'
import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Empty from 'components/common/Empty'
import Progress from 'components/common/Progress'
import SidePanelV2 from 'components/common/SidePanelV2'
import Typography from 'components/common/Typography'
import { SWTimeline } from 'components/feature/operation-process'
import ActivityLogs from 'components/feature/operation-process/activity-logs'
import DrawerDetails, { TaskDrawerTitle } from 'components/feature/operation-process/default-cards'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { formatDate } from 'utils/dateHelpers'

const StatTaskCard = ({ title, value }) => {
  return (
    <StyledStatTaskCard>
      <p>{title}</p>
      <h1 style={{ color: 'var(--Gray-900)', fontSize: '24px' }}>{value}</h1>
    </StyledStatTaskCard>
  )
}

const DueDateCard = ({ dueDate: due_date, dayLeft: total_day_left }) => {
  const { t } = useTranslation()

  return (
    <StyledDueDateCard>
      <p>{`${t('due_date')}: ${dayjs(due_date).isValid() ? formatDate(due_date) : due_date}`}</p>

      <div className="day-left">
        <h2>{t('total_day_left')}</h2>
        <h1>{`${total_day_left} ${t('day')}`}</h1>
      </div>
    </StyledDueDateCard>
  )
}

const init_drawer = {
  'view-sw': false,
  'view-task': false,
  'view-review': false,
  'view-approval': false,
}

export default function InfoSection() {
  const { t } = useTranslation()
  const logRef = useRef()
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)

  const { sw: swActions, task: taskActions } = opActions()
  const { clearSW } = swActions()
  const { clearTask } = taskActions()
  const { sp, sp_logs, sp_logs_pagination, task } = opProcSelector
  const { sw } = opProcSelector
  const { dashboard } = sp || {}

  const [drawer, setDrawer] = useState(init_drawer)

  const statTasks =
    (dashboard &&
      Object.keys(dashboard).length > 0 && [
        { title: t('on_going'), value: dashboard?.incompleted },
        { title: t('completed'), value: dashboard?.completed },
        { title: t('total'), value: dashboard?.total },
      ]) ||
    []

  const initToggleDrawerData = {
    swData: {},
    taskData: {},
    swId: undefined,
    taskId: undefined,
  }

  const handleToggleDrawer = (drawerName, open, data = initToggleDrawerData) => {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }

      setDrawer({ ...init_drawer, [drawerName]: open, ...data })
    }
  }

  const handleLogsSWDrawer = (swId) => {
    return (event) => {
      if (swId) {
        handleToggleDrawer('view-sw', true, { swId })(event)
        return
      }
    }
  }

  const handleTaskDrawer = (taskId) => {
    handleToggleDrawer('view-task', true, { taskId })()
  }

  useEffect(() => {
    const { current } = logRef
    if (current) {
      current.scrollTop = current.scrollHeight
    }
  }, [sp_logs])

  return (
    <Container>
      <div className="information-1">
        <div className="progress-bar-container">
          <Progress
            variant="determinate"
            value={dashboard?.progress}
            message={`${t('progress')} (${dashboard?.completed}/${dashboard?.total})`}
          />
        </div>

        <div className="box-task-info">
          {dashboard &&
            statTasks.map((statTask, index) => (
              <StatTaskCard key={index} value={statTask.value} title={statTask.title} />
            ))}
        </div>

        <DueDateCard dueDate={dashboard?.due_date} dayLeft={dashboard?.total_day_left} />

        <RecentActivityContainer width="100%">
          <RecentActivityHeader direction="row">
            <Typography variant="cardTitle">{t('logs')}</Typography>
          </RecentActivityHeader>

          {sp_logs.length > 0 ? (
            <ActivityLogs>
              {sp_logs.map((log) => (
                <ActivityLogs.LogList
                  key={log.id}
                  log={log}
                  onClickSW={handleLogsSWDrawer}
                  onClickTask={(taskId) => () => handleTaskDrawer(taskId)}
                />
              ))}
            </ActivityLogs>
          ) : (
            <ActivityLogs>
              <Empty title="No Activity Found" description="There are no activities found for this SOP." />
            </ActivityLogs>
          )}
        </RecentActivityContainer>
      </div>

      <div className="information-2 milestone-wrapper">
        <SWTimeline items={sp} noOppositeContent />
      </div>

      <React.Fragment key="Drawer_Components">
        <SidePanelV2
          titleText={sw?.name}
          supportingText={sw?.code}
          open={drawer['view-sw']}
          onClose={(event) => {
            dispatch(clearSW())
            handleToggleDrawer('view-sw', false)(event)
          }}
        >
          <DrawerDetails.SoW
            id={drawer?.swId}
            openTask={handleTaskDrawer}
            // onSubmit={handleSubmitSW}
            onViewClick={() => handleToggleDrawer('view-sw', false)()}
          />
        </SidePanelV2>

        <SidePanelV2
          titleText={
            <TaskDrawerTitle
              taskData={task}
              onClickBreadcrumb={handleToggleDrawer('view-sw', true, { swId: task?.sw?.id })}
            />
          }
          supportingText={`${t('task_code')}: ${task?.code}`}
          open={drawer['view-task']}
          onClose={(event) => {
            dispatch(clearTask())
            handleToggleDrawer('view-task', false)(event)
          }}
        >
          <DrawerDetails.Task taskId={drawer?.taskId} onViewClick={handleToggleDrawer('view-task', false)} />
        </SidePanelV2>
      </React.Fragment>
    </Container>
  )
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-none, 0rem);
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  padding-top: 1rem;
  max-height: none;

  & p,
  & h1,
  & h3 {
    margin: 0 !important;
  }

  & .information-1 {
    display: flex;
    padding: var(--spacing-none, 0rem);
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    flex: none;
    width: 100%;
    max-height: 45rem;
  }

  & .information-2.milestone-wrapper {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    overflow-y: scroll;
    border-radius: 0.5rem;

    &::-webkit-scrollbar {
      width: 0.4em;
    }
  }

  @media screen and (min-width: 768px) {
    gap: 1.5rem;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: row;

    & .information-1 {
      width: 23.75rem;
      height: 100%;
    }

    & .information-2.milestone-wrapper {
      max-height: 45rem;
    }
  }

  & div.progress-bar-container {
    display: flex;
    padding: 1rem;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;

    border-radius: 0.5rem;
    border: 1px solid var(--Gray-200, #eaecf0);
  }

  & div.box-task-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
  }
`

const StyledStatTaskCard = styled('div')`
  /** Layout */
  display: flex;
  min-width: 7rem;
  padding: 0.5rem 0.75rem;
  justify-content: space-between;

  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-md, 0.5rem);

  flex: 1 0 0;

  /** Style */
  border-radius: 0.5rem;
  border: 1px solid var(--Gray-200, #eaecf0);

  & p {
    color: var(--Gray-700);

    /* Text sm/Medium */
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem; /* 142.857% */
  }
`

const StyledDueDateCard = styled(StyledStatTaskCard)`
  width: 100%;
  height: fit-content;
  flex: none;
  padding: 0.75rem;

  & p {
    font-size: 14px;
    font-weight: 500;
    color: var(--Gray-600);
  }

  & .day-left {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    & h1 {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 0;
      color: var(--Gray-900);
    }

    & h2 {
      font-size: 1.25rem;
      font-weight: semibold;
      margin: 0;
      color: var(--Gray-700);
    }
  }
`

const RecentActivityContainer = styled(Stack)`
  height: 330px;
  align-items: center;
  align-self: stretch;

  background: var(--Base-White);
  border: 1px solid var(--Gray-200);
  border-radius: 0.5rem;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    height: 100%;
  }
`

const RecentActivityHeader = styled(Stack)`
  padding: 1rem;
  gap: 1rem;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  border-bottom: 1px solid var(--Gray-200);

  & > h3 {
    color: var(--Gray-900, #101828);

    /* Text lg/Semibold */
    font-family: Inter;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.75rem; /* 155.556% */
  }

  & > .dropdown-actions {
    display: flex;
    padding: var(--spacing-none, 0rem);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-none, 0rem);
  }
`
