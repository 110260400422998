import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import { numberWithCommas } from '../../../utils/common'

import styled from 'styled-components'
import { Stack } from '@mui/material'
import TextField from '../../form/TextField'
import Button from '../../form/button/Button'
import ButtonOutline from '../../form/button/ButtonOutline'
import KPIInputForDepartment from './KPIInputForDepartment'
import NewAccordion from '../../common/NewAccordion'
import Modal from '../../common/Modal'
import DialogSuccess from '../../dialog/DialogSuccess'
import DialogFail from '../../dialog/DialogFail'

import { TbBuildingBank } from 'react-icons/tb'
import { LiaTimesSolid } from 'react-icons/lia'
import { FaPlus } from 'react-icons/fa6'
import flagIcon from '../../../assets/images/flag-icon.png'
import successIcon from '../../../assets/images/success-icon.png'
import failIcon from '../../../assets/images/fail-icon.png'

const Div = styled.div`
  padding: 24px;

  .mr-12-px {
    margin-right: 12px;
  }
  .mr-16-px {
    margin-right: 16px;
  }
  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }

  h3,
  p {
    margin: 0;
  }
  h3 {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  p {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .highlight-text {
    color: var(--Primary-700);
  }

  .number-badge {
    display: inline-block;
    border-radius: 50%;
    color: var(--Base-White);
    background-color: var(--Gray-600);
    padding: 0 10px;
  }
  .add-kpi-btn {
    border: 1px solid var(--Primary-300);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }

  /* footer */
  .footer-create-kpi {
    position: sticky;
    width: 100%;
    z-index: 99;
    left: 0;
    bottom: 0;

    border-top: 1px solid var(--Gray-200);
    background-color: var(--Base-White);
    padding-top: 16px;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`
const KPIDepartmentItem = styled.div`
  margin-bottom: 24px;
`

function EditKPIAssignForDepartment({ open, onClose }) {
  // external hook
  const { t } = useTranslation()
  // initiate data
  const initialKpiDepartments = [
    {
      id: 1,
      kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (Demo 1)',
      description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
      target_type: 'Number',
      target_value: 23452,
      unit: 'usd',
      start_date: '2024-02-06',
      end_date: '2024-02-29',
      createdAt: '2024-02-06T08:23:15.931Z',
      updatedAt: '2024-02-06T08:30:24.269Z',
      status: 'active',
      sub_kpi: [
        {
          id: 1,
          kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (sub kpi 1)',
          description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
          target_type: 'Percent',
          target_value: 33,
          unit: 'percentage',
          start_date: '2024-02-06',
          end_date: '2024-02-29',
          employee: 'Jomphop Saibuatong, Fullstack developer',
        },
        {
          id: 2,
          kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (sub kpi 2)',
          description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
          target_type: 'Percent',
          target_value: 33,
          unit: 'percentage',
          start_date: '2024-02-06',
          end_date: '2024-02-29',
          employee: 'Jomphop Saibuatong, Fullstack developer',
        },
      ],
    },
    {
      id: 2,
      kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (Demo 2)',
      description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
      target_type: 'Percent',
      target_value: 33,
      unit: 'percentage',
      start_date: '2024-02-06',
      end_date: '2024-02-29',
      createdAt: '2024-02-06T08:30:24.230Z',
      updatedAt: '2024-02-06T08:30:24.230Z',
      status: 'active',
      sub_kpi: [
        {
          id: 1,
          kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (sub kpi 1)',
          description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
          target_type: 'Percent',
          target_value: 33,
          unit: 'percentage',
          start_date: '2024-02-06',
          end_date: '2024-02-29',
          employee: 'Jomphop Saibuatong, Fullstack developer',
        },
        {
          id: 2,
          kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (sub kpi 2)',
          description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
          target_type: 'Percent',
          target_value: 33,
          unit: 'percentage',
          start_date: '2024-02-06',
          end_date: '2024-02-29',
          employee: 'Jomphop Saibuatong, Fullstack developer',
        },
      ],
    },
    {
      id: 3,
      kpi_name: 'Brand Awareness: Increase brand awareness for sustainable products by 10%. (Demo 3)',
      description: 'Brand Awareness: Increase brand awareness for sustainable products by 10%.',
      target_type: 'Percent',
      target_value: 33,
      unit: 'percentage',
      start_date: '2024-02-06',
      end_date: '2024-02-29',
      createdAt: '2024-02-06T08:30:24.252Z',
      updatedAt: '2024-02-06T08:30:24.252Z',
      status: 'active',
      sub_kpi: [],
    },
  ]

  useEffect(() => {
    const newListKpiDeparmentForm = initialKpiDepartments.map((v) => ({
      id: v.id,
      kpi_name: v.kpi_name,
      target_value: v.target_value,
      unit: v.unit,
      start_date: v.start_date,
      end_date: v.end_date,
      kpiForm: v.sub_kpi.map((it) => ({
        ...it,
        key: uuidv4(),
        id: it.id,
        kpi_name: it.kpi_name,
        description: it.description,
        target_type: it.target_type,
        target_value: it.target_value,
        unit: it.unit,
        start_date: it.start_date,
        end_date: it.end_date,
        employee: '',
      })),
    }))

    setKpiDepartmentListForm(newListKpiDeparmentForm)
  }, [])

  const [kpiDepartmentListForm, setKpiDepartmentListForm] = useState([])

  const onChangeKpiDepartment = (key, event) => {
    const { name, value } = event.target
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentListForm = prev.map((it) => {
        const isThereKey = it.kpiForm.some((form) => form.key === key)
        if (isThereKey) {
          const newKpiForm = it.kpiForm.map((v) => {
            if (v.key === key)
              return {
                ...v,
                [name]: name === 'target_value' ? value.replace(/[^0-9.]/g, '') : value,
              }
            return v
          })
          return {
            ...it,
            kpiForm: newKpiForm,
          }
        } else {
          return it
        }
      })
      return newKpiDepartmentListForm
    })
  }
  const onChangeDropdownKpiDepartment = (key, name, value) => {
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentListForm = prev.map((it) => {
        const isThereKey = it.kpiForm.some((form) => form.key === key)
        if (isThereKey) {
          const newKpiForm = it.kpiForm.map((v) => {
            if (v.key === key)
              return {
                ...v,
                [name]: value,
              }
            return v
          })
          return {
            ...it,
            kpiForm: newKpiForm,
          }
        } else {
          return it
        }
      })
      return newKpiDepartmentListForm
    })
  }
  const onChangeDateKpiDepartment = (key, name, value) => {
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentListForm = prev.map((it) => {
        const isThereKey = it.kpiForm.some((form) => form.key === key)
        if (isThereKey) {
          const newKpiForm = it.kpiForm.map((v) => {
            if (v.key === key)
              return {
                ...v,
                [name]: value,
              }
            return v
          })
          return {
            ...it,
            kpiForm: newKpiForm,
          }
        } else {
          return it
        }
      })
      return newKpiDepartmentListForm
    })
  }
  const addKPIDepartment = (id) => {
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentListForm = prev.map((dept) => {
        if (dept.id === id) {
          const newKPI = {
            key: uuidv4(),
            kpi_name: '',
            description: '',
            target_type: '',
            target_value: '',
            unit: '',
            start_date: '',
            end_date: '',
            employee: '',
            // department: dept.departmentId,
          }
          return {
            ...dept,
            kpiForm: [...dept.kpiForm, newKPI],
          }
        } else return dept
      })
      return newKpiDepartmentListForm
    })
  }
  const deleteKPIDepartment = (id, key) => {
    setKpiDepartmentListForm((prev) => {
      const newKpiDepartmentForm = prev.map((dept) => {
        if (dept.id === id) {
          const newKpiForm = dept.kpiForm.filter((it) => it.key !== key)
          return {
            ...dept,
            kpiForm: newKpiForm,
          }
        }
        return dept
      })
      return newKpiDepartmentForm
    })
  }

  useEffect(() => {
    console.log('kpiDepartmentListForm', kpiDepartmentListForm)
  }, [kpiDepartmentListForm])

  // state,isLoading,successModal,
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [successModal, setSuccessModal] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)

  const onSubmitUpdate = () => {
    setSuccessMessageModal({
      headline: 'Update Successful',
      message: '',
    })
    setSuccessModal(true)
  }

  return (
    <Div>
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <LiaTimesSolid className="icon" size={20} onClick={onClose} />
      </Stack>

      {kpiDepartmentListForm.map((v, i) => (
        <KPIDepartmentItem key={v.id}>
          <Stack className="mb-24-px" direction="row" alignContent="center">
            <h3 className="mr-16-px">
              <span className="number-badge">{i + 1}</span>
            </h3>

            <div>
              <h3>{v.kpi_name}</h3>
              <p>
                Target{' '}
                <span className="highlight-text mr-16-px">
                  {numberWithCommas(v.target_value)} {v.unit}
                </span>
                Start Date <span className="highlight-text">{v.start_date}</span> End Date{' '}
                <span className="highlight-text">{v.end_date}</span>
              </p>
            </div>
          </Stack>

          <div>
            <KPIInputForDepartment
              className="mb-16-px"
              list={v.kpiForm}
              onChange={onChangeKpiDepartment}
              onChangeDropdown={onChangeDropdownKpiDepartment}
              onChangeDate={onChangeDateKpiDepartment}
              onDelete={(key) => deleteKPIDepartment(v.id, key)}
            />
            <ButtonOutline className="add-kpi-btn mb-24-px" append={<FaPlus />} onClick={() => addKPIDepartment(v.id)}>
              Add KPI
            </ButtonOutline>
          </div>
          <Divider className="mb-24-px" />
        </KPIDepartmentItem>
      ))}

      {/* footer */}
      <section className="footer-create-kpi">
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <ButtonOutline className="mr-12-px" onClick={onClose}>
            Cancel
          </ButtonOutline>
          <Button onClick={onSubmitUpdate}>Update</Button>
        </Stack>
      </section>

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => {
          setSuccessModal(false)
          onClose()
        }}
        onSubmit={() => {
          setSuccessModal(false)
          onClose()
        }}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
    </Div>
  )
}

export default EditKPIAssignForDepartment
