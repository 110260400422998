import { Avatar } from '@mui/material'
import systemLogo from 'assets/images/logo-cube-erp.png'

const LogList = ({ log, onClickSW, onClickTask, ...props }) => {
  return (
    <li key={props?.key}>
      <div className="date">{log?.createdAt}</div>

      <div className="content">
        {log?.task?.name ? (
          <p className="link" onClick={onClickTask && onClickTask(log?.task?.id)}>{`${log.task.name}: `}</p>
        ) : (
          log?.sw?.name && <p className="link" onClick={onClickSW && onClickSW(log?.sw?.id)}>{`${log.sw.name}: `}</p>
        )}
        <p className="action">{log?.action}</p>
      </div>

      {log.created_by ? (
        <p className="author">
          <Avatar sx={{ width: 20, height: 20 }} src={log?.created_by?.employee_profile_img} />
          {log.created_by}
        </p>
      ) : (
        <p className="author">
          <Avatar sx={{ width: 20, height: 20 }} src={systemLogo} />
          CUBE ERP System
        </p>
      )}
    </li>
  )
}

export default LogList
