import { combineReducers } from 'redux'
import {
    GET_COMPANYPROFILE,
    UPDATE_COMPANYPROFILE,
    CREATE_BRANCH_COMPANY,
    GET_BRANCH_COMPANY_LIST,
    GET_BRANCH_COMPANY_EDIT_BYID,
    UPDATE_BRANCH_COMPANYPROFILE,
    DELETE_BRANCH_COMPANY_PROFILE,
    GET_SUBCOMPANYPROFILE
} from '../actionTypes'

const initialState = {
  companyList: {},
  branchList: [],
  branchInfo: {},
  subcompanyList: {},
  total: 1,
  page: 1,
  limit: 10,
  pageCount: 1,
  isLoading: false,
}

const companyReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_COMPANYPROFILE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_COMPANYPROFILE.SUCCESS: {
      const { data, meta } = action
      // 
      return {
        ...state,
        companyList: data,
      }
    }
    case GET_COMPANYPROFILE.FAILURE:
      return {
        ...state,
        isLoading: false,
    }

    case GET_SUBCOMPANYPROFILE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_SUBCOMPANYPROFILE.SUCCESS: {
      const { data, meta } = action
      // 
      return {
        ...state,
        subcompanyList: data,
      }
    }
    case GET_SUBCOMPANYPROFILE.FAILURE:
      return {
        ...state,
        isLoading: false,
    }

      case UPDATE_COMPANYPROFILE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_COMPANYPROFILE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_COMPANYPROFILE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    // New Branch Company Profile 
    case GET_BRANCH_COMPANY_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_BRANCH_COMPANY_LIST.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        branchList: data,
        total: meta.pagination.total,
        limit: meta.pagination.pageSize,
        page: meta.pagination.page,
        pageCount: meta.pagination.pageCount,
        isLoading: false,
      }
    }
    case GET_BRANCH_COMPANY_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_BRANCH_COMPANY.REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case CREATE_BRANCH_COMPANY.SUCCESS: {
        return {
          ...state,
          isLoading: false,
        }
      }
      case CREATE_BRANCH_COMPANY.FAILURE:
        return {
          ...state,
          isLoading: false,
        }
        case GET_BRANCH_COMPANY_EDIT_BYID.REQUEST:
          return {
            ...state,
            isLoading: true,
          }
        case GET_BRANCH_COMPANY_EDIT_BYID.SUCCESS: {
          const { data } = action
          return {
            ...state,
            branchInfo: data,
            isLoading: false,
          }
        }
    case GET_BRANCH_COMPANY_EDIT_BYID.FAILURE:
          return {
            ...state,
            isLoading: false,
          }
    case UPDATE_BRANCH_COMPANYPROFILE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_BRANCH_COMPANYPROFILE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_BRANCH_COMPANYPROFILE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
      case DELETE_BRANCH_COMPANY_PROFILE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case DELETE_BRANCH_COMPANY_PROFILE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case DELETE_BRANCH_COMPANY_PROFILE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  companyReducer,
})

