import { Input as BaseInput, inputClasses } from '@mui/base'
import { styled } from '@mui/system'
import { forwardRef } from 'react'

export const Input = forwardRef(function CustomInput(props, ref) {
  const { slots, ...other } = props

  return (
    <BaseInput
      slots={{
        root: InputRoot,
        input: InputElement,
        ...slots,
      }}
      {...other}
      ref={ref}
    />
  )
})

export default Input

const InputRoot = styled('div')(
  () => `
  font-family: 'Inter','Noto Sans Thai';
  font-weight: 400;
  border-radius: 8px;
  color: var(--Gray-500);
  background: var(--Base-White);
  border: 1px solid var(--Gray-200);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;


  &.${inputClasses.focused} {
    border-color: var(--Primary-300);
    box-shadow: 0 0 0 3px var(--Primary-200);
  }

  &.${inputClasses.disabled} {
    color: var(--Gray-400);
    background: var(--Gray-50);
    border-color: var(--Gray-200);
  }

  &:not(.${inputClasses.disabled}):hover {
    border: 1px solid var(--Primary-300);
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
)

const InputElement = styled('input')`
  width: 100%;
  font-family: 'Inter', 'Noto Sans Thai';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: var(--Gray-900);
  background: inherit;
  border: none;

  &::placeholder {
    color: var(--Gray-400);
    opacity: 1;
    font-weight: 400;
    font-family: 'Inter', 'Noto Sans Thai';
    font-size: 0.875rem;
    line-height: 1.5;
  }

  &:focus {
    border: none;
    box-shadow: 0 0 0 3px var(--Primary-100);
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }

  &:disabled {
    color: var(--Gray-400);
    background: var(--Gray-50);
    border-color: var(--Gray-200);
  }

  // state error input
  &.error {
    border-color: red;
  }
`

export const InputAdornment = styled('div')`
  margin: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`
