import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  ADD_TASK_TO_PROJECT_TASK,
  CREATE_MILESTONE,
  CREATE_PROJECT_TASK,
  CREATE_TASK_TEMPLATE,
  DELETE_MILESTONE,
  DELETE_TASK_IN_PROJECT_TASK,
  EDIT_PROJECT_TASK,
  GET_MILESTONE_BY_ID,
  GET_MILESTONE_BY_PROJECT_TASK_ID,
  GET_MILESTONE_SCOPE_BY_MILESTONE_ID,
  GET_MILESTONE_SCOPE_BY_PROJECT_TASK_ID,
  GET_MILESTONE_LIST_BY_PROJECT_TASK_ID,
  GET_MILESTONE_TASK_TYPE_BY_MILESTONE_TASK_SCOPE_ID,
  GET_MILESTONE_TASK_TYPE_INFO_BY_TASK_ID,
  GET_POSITION_BY_DEPARTMENT_ID,
  GET_PROJECT_TASK,
  GET_PROJECT_TASK_BY_ID,
  GET_TASK_TYPE_BY_TASK_SCOPE,
  GET_SCOPE_WORK_BY_MILESTONE_ID,
  UPDATE_SCOPE_STATUS_IN_MILESTONE,
  UPDATE_STATUS_PROJECT_TASK,
  UPDATE_TASK_TYPE_STATUS_IN_MILESTONE,
  GET_MILESTONE_SCOPE_INFO_BY_SCOPE_ID,
  GET_SCOPE_OF_WORK,
  GET_SCOPE_WORK_BY_POSITION_ID,
  GET_TASK_TYPE_BY_SCOPE_ID,
  UPDATE_MILESTONE,
  GET_TASK_TEMPLATE_LIST,
  GET_TASK_TEMPLATE_BY_ID,
  GET_PROJECT_LIST_DROPDOWN,
  GET_LATEST_PROGRESS_UPDATE,
  GET_HISTORY_PROGRESS_UPDATE,
  GET_PROGRESS_UPDATE_BY_ID,
  CREATE_PROGRESS_UPDATE,
  EDIT_PROGRESS_UPDATE,
  GET_PROJECT_TASK_NOTES,
  CREATE_PROJECT_TASK_NOTES,
  GET_ASSIGNED_EMPLOYEE,
} from '../actionTypes'

import { hideLoading, showLoading } from './loading'

// new enhancement
export const getLastestProgressUpdate = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_LATEST_PROGRESS_UPDATE.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'sort[0]': 'id:desc',
      'filters[project_task][id]': id,
      'populate[creator]': true,
      lastest: true,
    }

    const { data } = await getAPI({
      url: `/api/project-progress-updates`,
      params,
      headers: headers,
    })

    return dispatch({
      type: GET_LATEST_PROGRESS_UPDATE.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_LATEST_PROGRESS_UPDATE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Latest Progress Update Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getHistoryProgressUpdate = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_HISTORY_PROGRESS_UPDATE.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'sort[0]': 'id:desc',
      'filters[project_task][id]': id,
      'populate[creator]': true,
      history: true,
      'fields[0]': 'createdAt',
    }

    const { data } = await getAPI({
      url: `/api/project-progress-updates`,
      params,
      headers: headers,
    })

    return dispatch({
      type: GET_HISTORY_PROGRESS_UPDATE.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_HISTORY_PROGRESS_UPDATE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get History Progress Update Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProgressUpdateById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PROGRESS_UPDATE_BY_ID.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'populate[creator]': true,
    }

    const { data } = await getAPI({
      url: `/api/project-progress-updates/${id}`,
      params,
      headers: headers,
    })

    return dispatch({
      type: GET_PROGRESS_UPDATE_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_PROGRESS_UPDATE_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Progress Update by id Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createProgressUpdate = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_PROGRESS_UPDATE.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: `/api/project-progress-updates`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_PROGRESS_UPDATE.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Progress Update Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_PROGRESS_UPDATE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
    dispatch(getLastestProgressUpdate(data?.project_task))
  }
}

export const editProgressUpdate = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_PROGRESS_UPDATE.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/project-progress-updates/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: EDIT_PROGRESS_UPDATE.SUCCESS,
      showAlert: true,
      alertMessage: 'Edit Progress Update Successful',
    })
  } catch (err) {
    return dispatch({ type: EDIT_PROGRESS_UPDATE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
    dispatch(getLastestProgressUpdate(data?.project_task))
  }
}

export const getProjectTaskNote = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PROJECT_TASK_NOTES.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'filters[project_task][id]': id,
      'populate[attach_file]': true,
      'populate[creator]': true,
    }

    const { data } = await getAPI({
      url: `/api/project-task-note-lists`,
      params,
      headers: headers,
    })

    return dispatch({
      type: GET_PROJECT_TASK_NOTES.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_PROJECT_TASK_NOTES.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Project Task Notes Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createProjectTaskNote = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_PROJECT_TASK_NOTES.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: `/api/project-task-note-lists`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_PROJECT_TASK_NOTES.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Project task note Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_PROJECT_TASK_NOTES.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
    dispatch(getProjectTaskNote(data.project_task))
  }
}

export const getAssignedEmployeeByProjectTask = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ASSIGNED_EMPLOYEE.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      project_task_id: id,
    }

    const { data } = await getAPI({
      url: `/api/assigned-employee-dropdrow`,
      params,
      headers: headers,
    })

    return dispatch({
      type: GET_ASSIGNED_EMPLOYEE.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_ASSIGNED_EMPLOYEE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Assigned Employee Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

// old
export const getProjectTask = (filter) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PROJECT_TASK.REQUEST })

    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    let multipleSearch = {}
    if (filter.multipleSearch) {
      multipleSearch = Object.assign({}, ...filter.multipleSearch)
    }

    const params = {
      ...multipleSearch,
      'pagination[page]': filter.page,
      'pagination[pageSize]': filter.pageSize,
      'filters[company]': company_info?.id,
    }

    if (filter?.status !== '') params['filters[status]'] = filter.status
    if (filter?.start_date !== '' && filter?.end_date !== '') {
      params['filters[end_date][$gte]'] = filter.start_date
      params['filters[end_date][$lte]'] = filter.end_date
    }

    const { data, meta } = await getAPI({
      url: `/api/project-tasks`,
      params,
      headers: headers,
    })

    return dispatch({
      type: GET_PROJECT_TASK.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_PROJECT_TASK.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Project Task Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProjectListDropdown = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PROJECT_LIST_DROPDOWN.REQUEST })
    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      url: `/api/project-task-dropdown?fields[0]=name&sort[id]=DESC&filters[$and][0][status][$ne]=Delete&filters[$and][1][company][id][$eq]=${company_info?.id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_PROJECT_LIST_DROPDOWN.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_PROJECT_LIST_DROPDOWN.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Projects Dropdown Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMilestoneByProjectId = (id, filters) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MILESTONE_BY_PROJECT_TASK_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    let filterPath = ''
    if (filters?.page) {
      filterPath = '&pagination[page]=' + filters.page + '&pagination[pageSize]=' + filters.pageSize
    }
    const { data, meta } = await getAPI({
      url: `/api/milestones?filters[project_task][id]=${id}${filterPath}`,
      headers: headers,
    })

    return dispatch({
      type: GET_MILESTONE_BY_PROJECT_TASK_ID.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_MILESTONE_BY_PROJECT_TASK_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Milestone By Project ID Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMilestoneScopeByProjectId = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MILESTONE_SCOPE_BY_PROJECT_TASK_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      url: `/api/milestone-scope-works?filters[milestone][project_task][id]=${id}&populate=*`,
      headers: headers,
    })

    return dispatch({
      type: GET_MILESTONE_SCOPE_BY_PROJECT_TASK_ID.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_MILESTONE_SCOPE_BY_PROJECT_TASK_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Milestone Scope By Project ID Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMilestoneListByProjectId = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MILESTONE_LIST_BY_PROJECT_TASK_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      url: `/api/milestones?filters[project_task][id]=${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_MILESTONE_LIST_BY_PROJECT_TASK_ID.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_MILESTONE_LIST_BY_PROJECT_TASK_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Milestone List By Project ID Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMilestoneScopeByMilestoneId = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MILESTONE_SCOPE_BY_MILESTONE_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      url: `/api/milestone-scope-works?filters[milestone][id]=${id}&populate=*`, // &populate[0]=task_scope
      headers: headers,
    })

    return dispatch({
      type: GET_MILESTONE_SCOPE_BY_MILESTONE_ID.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_MILESTONE_SCOPE_BY_MILESTONE_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Milestone Scope By Milestone ID Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMilestoneTaskTypeByMilestoneTaskScopeId = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MILESTONE_TASK_TYPE_BY_MILESTONE_TASK_SCOPE_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      url: `/api/milestone-task-types?pagination[page]=1&pagination[pageSize]=100&filters[milestone_scope_work][id]=${id}&populate[0]=task_type`,
      headers: headers,
    })

    return dispatch({
      type: GET_MILESTONE_TASK_TYPE_BY_MILESTONE_TASK_SCOPE_ID.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_MILESTONE_TASK_TYPE_BY_MILESTONE_TASK_SCOPE_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Milestone TaskType By Milestone Task Scope ID Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getTaskTypeByTaskScope = (id, filter) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TASK_TYPE_BY_TASK_SCOPE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    let filterPath = ''
    if (filter?.page) {
      filterPath = '&pagination[page]=' + filter.page + '&pagination[pageSize]=' + filter.pageSize
    }
    const { data, meta } = await getAPI({
      url: `/api/task-types?filters[task_scope][id]=${id}${filterPath}`,
      headers: headers,
    })

    return dispatch({
      type: GET_TASK_TYPE_BY_TASK_SCOPE.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_TASK_TYPE_BY_TASK_SCOPE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Task Types By Scope ID Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getScopeWorkByMilestoneId = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SCOPE_WORK_BY_MILESTONE_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      // url: `/api/milestone-scope-works?populate[0]=task_scope&filters[milestone][id]=${id}`,
      url: `/api/milestone-scope-works?populate[0]=task_scope&populate[5]=creator&filters[milestone][id]=${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_SCOPE_WORK_BY_MILESTONE_ID.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_SCOPE_WORK_BY_MILESTONE_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Scope Work By Milestone ID Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createProjectTask = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_PROJECT_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/project-tasks`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_PROJECT_TASK.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Project TASK Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_PROJECT_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const addTaskToProjectTask = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: ADD_TASK_TO_PROJECT_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: `/api/milestone-task-types`,
      data,
      headers: headers,
    })

    return dispatch({
      type: ADD_TASK_TO_PROJECT_TASK.SUCCESS,
      showAlert: true,
      alertMessage: 'Add Task to Project TASK Successful',
    })
  } catch (err) {
    return dispatch({ type: ADD_TASK_TO_PROJECT_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProjectTaskById = (id, filters) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PROJECT_TASK_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    let filterPath = ''
    if (filters != null) {
      if (filters?.milestoneSelected != '' && filters?.milestoneSelected != -1) {
        let path = `&filters[milestone]=${filters?.milestoneSelected}`
        filterPath = filterPath + path
      }
      if (filters?.search != '') {
        let path = `&filters[task_name]=${filters?.search}`
        filterPath = filterPath + path
      }
      if (filters?.scopeOfWorkSelected != '' && filters?.scopeOfWorkSelected != -1) {
        let path = `&filters[scope_work]=${filters?.scopeOfWorkSelected}`
        filterPath = filterPath + path
      }
      if (filters?.statusSelected != '' && filters?.statusSelected != -1) {
        let path = `&filters[task_status]=${filters?.statusSelected}`
        filterPath = filterPath + path
      }
      if (filters?.createBySelected != '' && filters?.createBySelected != -1) {
        let path = `&filters[create_by]=${filters?.createBySelected}`
        filterPath = filterPath + path
      }
      if (filters?.assignTo != '' && filters?.assignTo != -1) {
        let path = `&filters[assign_to]=${filters?.assignTo}`
        filterPath = filterPath + path
      }
    }

    const { data } = await getAPI({
      url: `/api/project-tasks/${id}?populate=*` + filterPath,
      headers: headers,
    })

    return dispatch({
      type: GET_PROJECT_TASK_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_PROJECT_TASK_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Project Task Data By ID Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMilestoneById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MILESTONE_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      url: `/api/milestones/${id}?type=custom`, // &populate=*
      headers: headers,
    })

    return dispatch({
      type: GET_MILESTONE_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_MILESTONE_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Milestone Data Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getPositionByDepartmentId = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_POSITION_BY_DEPARTMENT_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      url: `/api/positions/${id}?populate=*`,
      headers: headers,
    })

    return dispatch({
      type: GET_POSITION_BY_DEPARTMENT_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_POSITION_BY_DEPARTMENT_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Position Data Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createMilestone = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_MILESTONE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/milestones`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_MILESTONE.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Milestone Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_MILESTONE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteMilestone = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_MILESTONE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/milestones/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: DELETE_MILESTONE.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Milestone Successful',
    })
  } catch (err) {
    return dispatch({ type: DELETE_MILESTONE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const createTaskTemplate = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_TASK_TEMPLATE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/task-templates`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_TASK_TEMPLATE.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Task Template Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_TASK_TEMPLATE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const editProjectTask = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_PROJECT_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/project-tasks/${id}?populate=*`,
      data,
      headers: headers,
    })

    return dispatch({
      type: EDIT_PROJECT_TASK.SUCCESS,
      showAlert: true,
      alertMessage: 'Edit Project TASK Successful',
    })
  } catch (err) {
    return dispatch({ type: EDIT_PROJECT_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateStatusProjectTask = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_STATUS_PROJECT_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/project-tasks/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_STATUS_PROJECT_TASK.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Status Project TASK Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_STATUS_PROJECT_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteTaskInProjectTask = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_TASK_IN_PROJECT_TASK.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/milestone-task-types/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: DELETE_TASK_IN_PROJECT_TASK.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Task In Project Task Successful',
    })
  } catch (err) {
    return dispatch({ type: DELETE_TASK_IN_PROJECT_TASK.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateScopeStatusInMilestone = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_SCOPE_STATUS_IN_MILESTONE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/milestone-scope-works/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_SCOPE_STATUS_IN_MILESTONE.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Scope Status In Milestone Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_SCOPE_STATUS_IN_MILESTONE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMileStoneTaskTypeInfoByTaskId = (id, filters) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MILESTONE_TASK_TYPE_INFO_BY_TASK_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    let filterPath = ''
    if (filters?.searchText != null) {
      let path = `&filters[document_name]=${filters?.searchText}`
      filterPath = filterPath + path
    }

    if (filters?.typeSelected != null) {
      let path = `&filters[document_type]=${filters?.typeSelected}`
      filterPath = filterPath + path
    }

    if (filters?.statusSelected != null) {
      let path = `&filters[document_status]=${filters?.statusSelected}`
      filterPath = filterPath + path
    }
    if (filters?.subTaskStatus != null) {
      let path = `&filters[subtask_status]=${filters?.subTaskStatus}`
      filterPath = filterPath + path
    }
    const { data, meta } = await getAPI({
      url: `/api/milestone-task-types/${id}?type=custom` + filterPath,
      headers: headers,
    })

    return dispatch({
      type: GET_MILESTONE_TASK_TYPE_INFO_BY_TASK_ID.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_MILESTONE_TASK_TYPE_INFO_BY_TASK_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Milestone Task Type Info By Task Id Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateTaskTypeStatusInMilestone = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_TASK_TYPE_STATUS_IN_MILESTONE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: `/api/milestone-task-actions`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_TASK_TYPE_STATUS_IN_MILESTONE.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Task Type Status In Milestone Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_TASK_TYPE_STATUS_IN_MILESTONE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getMileStoneScopeInfoByScopeId = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_MILESTONE_SCOPE_INFO_BY_SCOPE_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      url: `/api/milestone-scope-works/${id}?populate[0]=task_scope&populate[1]=assign_to&populate[2]=milestone_task_types&populate[3]=milestone_task_types.assign_to&populate[4]=milestone_task_types.task_type&populate[5]=creator&populate[6]=milestone&populate[7]=milestone.project_task`,
      headers: headers,
    })

    return dispatch({
      type: GET_MILESTONE_SCOPE_INFO_BY_SCOPE_ID.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_MILESTONE_SCOPE_INFO_BY_SCOPE_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Milestone Scope Info By Scope Id Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getScopeWork = (filters, pagination) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SCOPE_OF_WORK.REQUEST })
    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      url: `/api/task-scopes?type=custom&pagination[page]=${pagination.page}&pagination[pageSize]=${pagination.pageSize}&filters[status][$ne]=delete&filters[name][$contains]=${filters?.searchText}&populate[0]=department&populate[1]=positions&filters[department][company][id]=${company_info.id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_SCOPE_OF_WORK.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_SCOPE_OF_WORK.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Scope of Work Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getScopeWorkByPositionId = (id, pagination) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SCOPE_WORK_BY_POSITION_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      url: `/api/positions?filters[status][$ne]=delete&pagination[page]=${pagination.page}&pagination[pageSize]=${pagination.pageSize}&filters[id]=${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_SCOPE_WORK_BY_POSITION_ID.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_SCOPE_WORK_BY_POSITION_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Scope of Work By Position id Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getTaskTypeByScopeId = (id, filter) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TASK_TYPE_BY_SCOPE_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    let filterPath = ''
    if (filter?.page) {
      filterPath = '&pagination[page]=' + filter.page + '&pagination[pageSize]=' + filter.pageSize
    }
    const { data, meta } = await getAPI({
      url: `/api/task-types?type=api${filterPath}&filters[task_scope][id]=${id}&filters[status][$ne]=delete`,
      headers: headers,
    })

    return dispatch({
      type: GET_TASK_TYPE_BY_SCOPE_ID.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_TASK_TYPE_BY_SCOPE_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Task Types By Scope ID Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateMilestoneProjectTask = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_MILESTONE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await putAPI({
      url: `/api/milestones/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_MILESTONE.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Milestone Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_MILESTONE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getTaskTemplateList = (filters) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TASK_TEMPLATE_LIST.REQUEST })
    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    let filterPath = ''
    if (filters) {
      if (filters?.searchText != '') {
        filterPath = filterPath + '&filters[name][$contains]=' + filters.searchText
      }
    }
    const { data, meta } = await getAPI({
      url: `/api/task-templates?filters[status][$ne]=delete&filters[company][id]=${company_info?.id}` + filterPath,
      headers: headers,
    })

    return dispatch({
      type: GET_TASK_TEMPLATE_LIST.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_TASK_TEMPLATE_LIST.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Task Template list Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getTaskTemplateById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TASK_TEMPLATE_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      url: `/api/task-templates/${id}?type=custom`,
      headers: headers,
    })

    return dispatch({
      type: GET_TASK_TEMPLATE_BY_ID.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_TASK_TEMPLATE_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Task Template By ID Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProjectTaskDropdown = (pagination) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PROJECT_TASK.REQUEST })
    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data, meta } = await getAPI({
      url: `/api/project-task-dropdown?fields[0]=name&sort[id]=DESC&filters[$and][0][status][$ne]=Delete&filters[$and][1][company][id][$eq]=${company_info?.id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_PROJECT_TASK.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_PROJECT_TASK.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Project Task Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}
