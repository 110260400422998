import { combineReducers } from 'redux'
import {
  GET_PROVINCES_DEPATEMENT,
  GET_DISTRICTS_DEPATEMENT,
  GET_SUB_DISTRICTS_DEPATEMENT,
  GET_DEPARTMENTS,
  CREATE_DEPARTMENTS,
  GET_DEPARTMENT_BY_ID,
  UPDATE_DEPARTMENTS,
  DELETE_DEPARTMENTS,
} from '../actionTypes'

const initialState = {
  departments: [],
  departmentInfo: {},
  provinces: [],
  districts: [],
  subdistricts: [],
  total: 1,
  limit: 10,
  page: 1,
  pageCount: 1,
  state: '',
  isLoading: false,
}
const departmentReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PROVINCES_DEPATEMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_PROVINCES_DEPATEMENT.REQUEST',
      }
    case GET_PROVINCES_DEPATEMENT.SUCCESS: {
      const { data } = action
      return {
        ...state,
        provinces: data,
        isLoading: false,
        state: 'GET_PROVINCES_DEPATEMENT.SUCCESS',
      }
    }
    case GET_PROVINCES_DEPATEMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_PROVINCES_DEPATEMENT.FAILURE',
      }
    case GET_DISTRICTS_DEPATEMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_DISTRICTS_DEPATEMENT.REQUEST',
      }
    case GET_DISTRICTS_DEPATEMENT.SUCCESS: {
      const { data } = action
      return {
        ...state,
        districts: data,
        isLoading: false,
        state: 'GET_DISTRICTS_DEPATEMENT.SUCCESS',
      }
    }
    case GET_DISTRICTS_DEPATEMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_DISTRICTS_DEPATEMENT.FAILURE',
      }
    case GET_SUB_DISTRICTS_DEPATEMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_SUB_DISTRICTS_DEPATEMENT.REQUEST',
      }
    case GET_SUB_DISTRICTS_DEPATEMENT.SUCCESS: {
      const { data } = action
      return {
        ...state,
        subdistricts: data,
        isLoading: false,
        state: 'GET_SUB_DISTRICTS_DEPATEMENT.SUCCESS',
      }
    }
    case GET_SUB_DISTRICTS_DEPATEMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_SUB_DISTRICTS_DEPATEMENT.FAILURE',
      }
    case GET_DEPARTMENTS.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_DEPARTMENTS.REQUEST',
      }
    case GET_DEPARTMENTS.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        departments: data,
        total: meta.pagination.total,
        limit: meta.pagination.pageSize,
        page: meta.pagination.page,
        pageCount: meta.pagination.pageCount,
        isLoading: false,
        state: 'GET_DEPARTMENTS.SUCCESS',
      }
    }
    case GET_DEPARTMENTS.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_DEPARTMENTS.FAILURE',
      }
    case GET_DEPARTMENT_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_DEPARTMENT_BY_ID.REQUEST',
      }
    case GET_DEPARTMENT_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        departmentInfo: data,
        isLoading: false,
        state: 'GET_DEPARTMENT_BY_ID.SUCCESS',
      }
    }
    case GET_DEPARTMENT_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_DEPARTMENT_BY_ID.FAILURE',
      }
    case CREATE_DEPARTMENTS.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_DEPARTMENTS.REQUEST',
      }
    case CREATE_DEPARTMENTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_DEPARTMENTS.SUCCESS',
      }
    }
    case CREATE_DEPARTMENTS.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_DEPARTMENTS.FAILURE',
      }
    case UPDATE_DEPARTMENTS.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'UPDATE_DEPARTMENTS.REQUEST',
      }
    case UPDATE_DEPARTMENTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_DEPARTMENTS.SUCCESS',
      }
    }
    case UPDATE_DEPARTMENTS.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_DEPARTMENTS.FAILURE',
      }
    case DELETE_DEPARTMENTS.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_DEPARTMENTS.REQUEST',
      }
    case DELETE_DEPARTMENTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_DEPARTMENTS.SUCCESS',
      }
    }
    case DELETE_DEPARTMENTS.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_DEPARTMENTS.FAILURE',
      }
    default:
      return state
  }
}

export default combineReducers({
  departmentReducer,
})
