import { useState, useEffect, useCallback, useRef } from 'react'
import { NumberFormatter } from 'react-number-formatter'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { getCompanyInfo } from '../../utils/common'
import { postAPI } from '../../utils/api'
import { validateEmail } from '../../utils/validation'

import styled from 'styled-components'
import AutoComplete from '../../components/form/AutoComplete'
import Dropdown from '../../components/form/Dropdown'
import TextField from '../../components/form/TextField'
import TextFieldRef from '../../components/form/TextFieldRef'
import IconButton from '../../components/common/IconButton'
import Button from '../../components/form/button/Button'
import Date from '../../components/form/Date'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import FileUpload from '../../components/form/FileUpload'
import FileUploadProgress from '../../components/form/FileUploadProgress'
import DialogConfirmV2 from '../../components/dialog/DialogConfirmV2'
import DialogAccessRole from '../../pages/employee/DialogAccessRole'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import CollapseMenu from '../../components/common/CollapseMenu'
import EmployeeSignatureForFormModal from '../../components/signature-modal/EmployeeSignatureForFormModal'
import DialogWarning from '../../components/dialog/DialogWarning'

import iconPerson from '../../assets/images/icon-person.svg'
import iconBin from '../../assets/images/icon-bin.svg'
import iconFile from '../../assets/images/icon-file-round.svg'
import iconDelete from '../../assets/images/icon-bin.svg'
import warningIcon from '../../assets/images/warning-icon.png'
import userIconPlus from '../../assets/images/user-icon-plus.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import uploadIcon from '../../assets/images/icon-upload-round.svg'

import { useDispatch, useSelector } from 'react-redux'
import { selectMaster, selectEmployee, selectEmployeeProfile } from '../../redux/selectors'
import {
  createEmployee,
  getAccountUserList,
  updateUser,
  getEmployeeReturnData,
  getEmployee,
} from '../../redux/actions/employee'
import { createAccountUser, createAccountUserWithPassword } from '../../redux/actions/userlist'
import { getCompanyInfoById, getDistricts, getSubDistricts } from '../../redux/actions/master'
import { getPositionLevelReturnData } from '../../redux/actions/jobPosition'
import { clearResponseUploadSignature, clearStateEmployeeProfile } from '../../redux/actions/employeeProfile'

const DivUploadList = styled.div`
  .file {
    width: 100%;
    display: inline-flex;
    padding: 14px;
    margin-top: 16px;
    background: var(--Base-White);
    border: 1px solid var(--Gray-300);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .icon-wrapper {
    width: 32px;
  }

  .detail-wrapper {
    flex: 1;
    margin: 0 14px;
    font-size: 14px;
    overflow: hidden;

    .file-name {
      margin-bottom: 2px;
      text-overflow: ellipsis;
      overflow: hidden;

      a {
        font-weight: 500;
        color: var(--Gray-700);
      }
    }
  }
`
const HiddenInput = styled.input.attrs({ type: 'file' })`
  display: none;
`
const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}
const multiple = false
const error = false
const disabled = false
const EmployeeDiv = styled.div`
  .mr-24-px {
    margin-right: 24px;
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .css_bin_secondary {
    display: inline-block;
    align-self: flex-end;
  }
  .sub_title {
    font-size: 14px;
    color: #000000;
    font-weight: normal;
  }
  .content_header {
    padding: 14px 14px;

    width: 940px;
    position: fixed;
    background: #ffffff;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }

  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }

  .wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      border-bottom: 2px solid #eaecf0;
      color: #667085;
      font-size: 14px;
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      border-bottom: 2px solid var(--Primary-600) !important;
      color: #6941c6;
      background-color: #f9f5ff;
    }
  }

  .dialog-content {
    padding: 0px 14px;
    padding-top: 128px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }

  .basic-Information-detail {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    margin-bottom: 10px;

    .topic_fill {
      color: var(--Gray-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .topic_detail {
      font-size: 14px;
      color: #757575;
    }
  }
  .basic-Information-detail-v3 {
    display: grid;
    grid-template-columns: repeat(3, 55% [col-start] 20% [col-middle] 20% [col-end]);
    gap: 20px;
    padding-top: 10px;
    .topic_fill {
      color: var(--Gray-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .text_css {
      width: 100%;
    }
  }
  .topic_fill {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .TextField_employee {
    width: 100%;
  }
  .info-detail {
    width: 66%;
  }
  .img-name-member-no {
    width: 34%;
  }
  .photo_padding {
    padding-left: 20px;
  }
  .group_photo {
    display: flex;
  }
  .mg_top_20 {
    margin-top: 20px;
  }
  .address_fill {
    width: 100%;
  }
  .dd_css {
    width: 100%;
  }

  .button_add_major {
    color: #6941c6;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
  }

  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 940px;
    background-color: #ffffff;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .div_img_profile {
    /*text-align: center;*/
    display: flex;
    justify-content: center;
    .box_img_profile {
      height: 128px;
      width: 128px;
      position: relative;
    }
    .img-user {
      height: 128px;
      width: 128px;
      border-radius: 50%;
    }
    .box_edit_profile {
      position: absolute;
      bottom: -10px;
      right: 0;
      width: 20px;
    }
  }
  .css_autocom_address {
    width: 100%;
  }
  .react-number-formatter-dropbtn {
    height: 40px;
  }
  .w-100 {
    width: 100%;
  }
`
const AdditionInfo = styled.div`
  p {
    margin: 0;
    color: var(--Gray-600);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`
const FlexContainer = styled.div`
  display: flex;
`
const SignaturePanel = styled.div`
  .heading {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    margin-bottom: 10px;
  }

  .image-container {
    height: 128px;
    border: 1px solid var(--Gray-200);
    border-radius: 8px;
    padding: 10px;

    img {
      max-height: 100%;
    }

    .action {
      display: inline-block;
      position: relative;
      right: 0;
    }
  }

  .panel {
    padding: 14px;
    color: var(--Gray-600);
    font-size: 14px;
    background: var(--Base-White);
    border: 1px solid var(--Gray-200);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    text-align: center;
    &:hover {
      cursor: pointer;
    }

    .text {
      color: var(--Gray-600);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
    }

    .highlight {
      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }
`

const NewEmployeeModal = ({ open, onClose, onSubmit }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { companyInfo } = useSelector(selectMaster)

  const fetchCompanyInfo = useCallback(() => {
    const { company_info } = JSON.parse(localStorage.getItem('admin'))
    dispatch(getCompanyInfoById(company_info.id))
  }, [dispatch])
  useEffect(() => {
    fetchCompanyInfo()
  }, [])

  const userNameInput = useRef(null)

  const CompanyInfo = getCompanyInfo()

  const initialStateEmployee = {
    employ_id: '', //-- "employ_id": "",
    title: '', //-- "title": "นาย",
    department: null, //-- "department": 1,
    first_name: null, //-- "first_name": "malik",
    last_name: null, //-- "last_name": "srirat",
    first_name_th: null, //-- "first_name": "malik",
    last_name_th: null, //-- "last_name": "srirat",
    photo: null, //-- "photo": 1,
    position: null, //-- "position": 1,
    email: null, //-- "email": "srirat.malik@gmail.com",
    phone_no: null, //-- "phone_no": "0850840288",
    martial_status: '', //-- "martial_status": "single",
    number_of_children: null, //-- "number_of_children": 3,
    nationality: null, //-- "nationality": 1,
    gender: '', //-- "gender": "male",
    birth_date: null, //-- "birth_date": "2023-05-01"
    identification_no: null, //-- "identification_no": "12345678901236",
    passport_no: null, //-- "passport_no": "123456",
    bank: null, //--- "bank": 1,
    private_car_plate: null, //-- "private_car_plate": null,
    bank_account_no: null, //-- "bank_account_no": null,
    emergency_contact: null, //-- "emergency_contact": null,
    emergency_phone_no: null, //-- "emergency_phone_no": null,
    visa_no: null, //-- "visa_no": null,
    work_permit_no: null, //-- "work_permit_no": null,
    visa_expire_date: null, //-- "visa_expire_date": null,
    workpermit_expire_date: null, //-- "workpermit_expire_date": null,
    workpermi_no: null, //-- "workpermi_no": null,
    workpermit_file_id: null, //-- "workpermit_file_id": null,
    address: null, //-- "address": "49/13",
    thai_province: null, //-- "thai_province": 1,
    thai_amphure: null, //-- "thai_amphure": 1001,
    thai_tambon: null, //-- "thai_tambon": 100101,
    postcode: null, //-- "postcode": "43160",
    education: [
      {
        school_name: null,
        school_type: 'elementary school',
        entry_year: null,
        graduate_year: null,
      },
      {
        school_name: null,
        school_type: 'junior high school',
        entry_year: null,
        graduate_year: null,
      },
      {
        school_name: null,
        school_type: 'senior hign school',
        entry_year: null,
        graduate_year: null,
      },
    ],
    university: [
      {
        certificate_level: '',
        field_study: null,
        university_name: null,
        entry_year: null,
        graduate_year: null,
      },
    ],
    resume: null, //--- Lise File_ID //-- resume: [1]

    //---- ฟิวใหม่For Phane2 04/09/2023
    company: CompanyInfo?.id,
    supervisors: null,
    position_level: null,
    secondary_job: [],
    //---- ฟิวใหม่ 02/10/2023
    employee_type: null,
    evaluation_date: null,
    employee_type_status: null,
    work_start_date: null,
    work_end_date: null,
    company_employee_id: '',

    company_employee_id: '',
    is_email_invite: true,

    signature: null,
  }

  const errorMessage = ''
  const editable = true

  const [NewEmployeeData, setNewEmployeeData] = useState(initialStateEmployee)

  const [NewEmployeeError, setNewEmployeeError] = useState({
    title: {
      show_errorMessage: false,
      errorMessage: 'Fill Title',
    },
    first_name: {
      show_errorMessage: false,
      errorMessage: 'Fill First Name',
    },
    last_name: {
      show_errorMessage: false,
      errorMessage: 'Fill Last Name',
    },
    first_name_th: {
      show_errorMessage: false,
      errorMessage: 'Fill First Name',
    },
    last_name_th: {
      show_errorMessage: false,
      errorMessage: 'Fill Last Name',
    },
    email: {
      show_errorMessage: false,
      errorMessage: 'Fill Email',
    },
    identification_no: {
      show_errorMessage: false,
      errorMessage: 'Fill Identification No.',
    },

    department: {
      show_errorMessage: false,
      errorMessage: '',
    },
    position: {
      show_errorMessage: false,
      errorMessage: '',
    },
    phone_no: {
      show_errorMessage: false,
      errorMessage: '',
    },
    martial_status: {
      show_errorMessage: false,
      errorMessage: '',
    },
    nationality: {
      show_errorMessage: false,
      errorMessage: '',
    },
    gender: {
      show_errorMessage: false,
      errorMessage: '',
    },
    birth_date: {
      show_errorMessage: false,
      errorMessage: '',
    },
    address: {
      show_errorMessage: false,
      errorMessage: '',
    },
    bank: {
      show_errorMessage: false,
      errorMessage: '',
    },
    bank_account_no: {
      show_errorMessage: false,
      errorMessage: '',
    },
  })
  const [errorMessageUserName, setErrorMessageUserName] = useState(null)

  const [provinces_option, setProvinces_option] = useState([])
  const [amphure_option, setAmphure_option] = useState([])
  const [tambon_option, setTambon_option] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  const [positionList, setPositionList] = useState([])
  const [nationalityList, setNationalityList] = useState([])
  const [bankList, setBankList] = useState([])

  const { banks, nationalities, departments, provinces, districts, subdistricts } = useSelector(selectMaster)

  const resetState = () => {
    setNewEmployeeData(initialStateEmployee)
  }

  const [positionLevelList, setpositionLevelList] = useState([])
  const [supervisorList, setsupervisorList] = useState([])
  useEffect(() => {
    const filter = {
      skip: 1,
      limit: 1000,
    }
    dispatch(getPositionLevelReturnData(filter)).then(({ type, data_info, data_meta }) => {
      if (type.endsWith('_SUCCESS')) {
        if (data_meta) {
          const newpositionLevelList = data_info.map((v) => ({
            text: v?.name_th,
            value: v?.id,
          }))
          setpositionLevelList(newpositionLevelList)
        }
      }
    })

    dispatch(getEmployeeReturnData(filter)).then(({ type, data_info, data_meta }) => {
      if (type.endsWith('_SUCCESS')) {
        if (data_meta) {
          const newsupervisorList = data_info.map((v) => ({
            text: v?.first_name + ' ' + v?.last_name,
            value: v?.id,
          }))
          setsupervisorList(newsupervisorList)
        }
      }
    })
  }, [])

  useEffect(() => {
    const newList = departments.map((v) => ({
      text: v?.name,
      value: v?.id,
      positions: v?.positions,
    }))
    setDepartmentList(newList)
    const newNationalityList = nationalities.map((v) => ({
      text: v?.name,
      value: v?.id,
    }))
    setNationalityList(newNationalityList)
    const newbanksList = banks.map((v) => ({
      text: v?.nam_th,
      value: v?.id,
    }))
    setBankList(newbanksList)
    const newProvinces = provinces.map((v) => ({
      text: v.attributes.name_en,
      value: v.id,
    }))
    setProvinces_option([...newProvinces])
  }, [departments, nationalities, banks, provinces])

  /*
  useEffect(() => {
    const newList = nationalities.map((v) => ({
      text: v?.name,
      value: v?.id,
    }))
    setNationalityList(newList)
  }, [nationalities])

  
  useEffect(() => {
    const newList = banks.map((v) => ({
      text: v?.nam_th,
      value: v?.id,
    }))
    setBankList(newList)
  }, [banks])
  

  useEffect(() => {
    const newProvinces = provinces.map((v) => ({
      text: v.attributes.name_en,
      value: v.id,
    }))
    setProvinces_option([...newProvinces])
  }, [provinces])
  */

  const onChangeProvince = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, thai_province: v })
    //--- CallAPI Get อำเภอ
    CallGetAmphure(v)
    //--- Clear ค่าอำเภอกับตำบล
    setAmphure_option([])
    setTambon_option([])
  }
  const CallGetAmphure = useCallback(
    async (provinces_id) => {
      dispatch(getDistricts(provinces_id))
    },
    [dispatch]
  )

  useEffect(() => {
    const newAmphure = districts.map((v) => ({
      text: v.attributes.name_en,
      value: v.id,
    }))
    setAmphure_option([...newAmphure])
  }, [districts])

  const onChangeAmphure = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, thai_amphure: v })
    //--- CallAPI Get ตำบล
    CallGetTambon(v)
    //--- Clear ค่าอำเภอกับตำบล
    setTambon_option([])
  }
  const CallGetTambon = useCallback(
    async (amphure_id) => {
      dispatch(getSubDistricts(amphure_id))
    },
    [dispatch]
  )
  useEffect(() => {
    const newTambon = subdistricts.map((v) => ({
      text: v.attributes.name_en,
      value: v.id,
    }))
    setTambon_option([...newTambon])
  }, [subdistricts])

  const onChangeTambon = (v) => {
    //setNewEmployeeData({...NewEmployeeData, thai_tambon: v});
    const thai_tambon_id = v
    const this_tambon = subdistricts.filter((v) => v.id == thai_tambon_id)
    //alert(JSON.stringify(this_tambon[0].attributes.zip_code))
    setNewEmployeeData({
      ...NewEmployeeData,
      thai_tambon: thai_tambon_id,
      postcode: this_tambon[0].attributes.zip_code,
    })
  }

  //---- ST Defalse

  const onChangeBirth_date = (value) => {
    setNewEmployeeData({ ...NewEmployeeData, birth_date: value })
  }
  const onChangeWorkPermitExpirationDate = (value) => {
    setNewEmployeeData({ ...NewEmployeeData, workpermit_expire_date: value })
  }
  const onChangeVisaExpireDate = (value) => {
    setNewEmployeeData({ ...NewEmployeeData, visa_expire_date: value })
  }
  const onTitle = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, title: v })
  }
  //---- ST Position -> Employment Details
  const employee_type_option = [
    { text: 'Permanent', value: 'Permanent' },
    { text: 'Part-Time', value: 'Part-Time' },
    { text: 'Probation', value: 'Probation' },
  ]
  const employee_type_status_option = [
    { text: 'Active', value: 'Active' },
    { text: 'Resign', value: 'Resign' },
  ]
  //---- ED Position -> Employment Details
  const onEmployee_type = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, employee_type: v })
  }
  const onChangeEvaluation_date = (value) => {
    setNewEmployeeData({ ...NewEmployeeData, evaluation_date: value })
  }
  const onChangework_start_date = (value) => {
    setNewEmployeeData({ ...NewEmployeeData, work_start_date: value })
  }
  const onemployee_type_status = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, employee_type_status: v })
  }
  const onChangework_end_date = (value) => {
    setNewEmployeeData({ ...NewEmployeeData, work_end_date: value })
  }
  const onDepartment = (inp_v) => {
    //alert()
    const departmentMatch = departmentList.find((v) => v.value == inp_v)

    const newPosition = departmentMatch?.positions.map((v) => ({
      text: v.name,
      value: v.id,
    }))
    setPositionList(newPosition)
    setNewEmployeeData({ ...NewEmployeeData, department: inp_v, position: '' })
  }
  const onJobPosition = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, position: v })
  }
  const onChangeMartialStatus = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, martial_status: v })
  }
  const onChangeNationality = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, nationality: v })
  }
  const onChangeGender = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, gender: v })
  }
  const onChangeBankAccount = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, bank: v })
  }

  const onChangeposition_level = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, position_level: v })
  }

  const onChangeSupervisor = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, supervisors: v })
  }

  const onChangeEmployeeForm = (event) => {
    const { name, value } = event.target
    setNewEmployeeData((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  //--- ST Education Information
  const onChangeEducationGraduate_year = (index_item, v) => {
    const newState = NewEmployeeData.education.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      // alert(index + ' === ' + index_item )

      if (index === index_item) {
        //alert(JSON.stringify(obj))
        return { ...obj, graduate_year: v }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, education: newState })
  }

  const onChangeEducationEntry_year = (index_item, v) => {
    const newState = NewEmployeeData.education.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, entry_year: v }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, education: newState })
  }

  const onChangeEducationSchool_name = (index_item, e) => {
    const newState = NewEmployeeData.education.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, school_name: e.target.value }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, education: newState })
  }
  //--- ED Education Information

  //--- ST University
  const onChangeCertificate_level = (index_item, v) => {
    const newState = NewEmployeeData.university.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, certificate_level: v }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, university: newState })
  }

  const onChangeField_study = (index_item, e) => {
    const newState = NewEmployeeData.university.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, field_study: e.target.value }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, university: newState })
  }

  const onChangeUniversity_name = (index_item, e) => {
    const newState = NewEmployeeData.university.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, university_name: e.target.value }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, university: newState })
  }

  const onChangeEntry_year = (index_item, v) => {
    const newState = NewEmployeeData.university.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, entry_year: v }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, university: newState })
  }

  const onChangeGraduate_year = (index_item, v) => {
    const newState = NewEmployeeData.university.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, graduate_year: v }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, university: newState })
  }

  const RemoveUniversities = (index_item) => {
    const currentListCopy = [...NewEmployeeData.university]
    currentListCopy.splice(index_item, 1)
    setNewEmployeeData({ ...NewEmployeeData, university: currentListCopy })
  }

  function handleAddUniversities(currentData) {
    // build a new element with a new ID based off the last element in the array
    let newDataElement = {
      certificate_level: '',
      field_study: null,
      university_name: null,
      entry_year: null,
      graduate_year: null,
    }

    // build a new state object
    //alert(JSON.stringify(currentData))
    const newStateData = [...currentData, newDataElement]

    // update the state
    //setUniversitiesList(newStateData);
    //alert(JSON.stringify(newStateData))
    setNewEmployeeData({ ...NewEmployeeData, university: newStateData })
  }
  //--- ED University

  //--- ST Secondary Job
  const onChangeSecondaryDepartment = (index_item, inp_v) => {
    const departmentMatch = departmentList.find((v) => v.value == inp_v)

    const newPosition = departmentMatch?.positions.map((v) => ({
      text: v.name,
      value: v.id,
    }))

    const newState = NewEmployeeData.secondary_job.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, department: inp_v, positionList: newPosition }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    setNewEmployeeData({ ...NewEmployeeData, secondary_job: newState })
  }

  const onChangeSecondaryPositon = (index_item, inp_v) => {
    const newState = NewEmployeeData.secondary_job.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, position: inp_v }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    setNewEmployeeData({ ...NewEmployeeData, secondary_job: newState })
  }

  function handleAddSecondary_job(currentData) {
    // build a new element with a new ID based off the last element in the array
    let newDataElement = {
      department: null,
      positionList: [],
      position: null,
    }

    // build a new state object
    //alert(JSON.stringify(currentData))
    const newStateData = [...currentData, newDataElement]

    // update the state
    //setUniversitiesList(newStateData);
    //alert(JSON.stringify(newStateData))
    setNewEmployeeData({ ...NewEmployeeData, secondary_job: newStateData })
  }

  const RemoveSecondary_job = (index_item) => {
    const currentListCopy = [...NewEmployeeData.secondary_job]
    currentListCopy.splice(index_item, 1)
    setNewEmployeeData({ ...NewEmployeeData, secondary_job: currentListCopy })
  }

  //--- ED Secondary Job

  //--- ST File Resume
  const [file_resume, setFile_resume] = useState([])
  const onChangeFileResume = async (event) => {
    const file = event.target.files[0]
    //alert('onChangeFileResume')

    const fetchList = []
    const formData = new FormData()
    formData.append('files', file)
    formData.append(
      'fileInfo',
      JSON.stringify({
        name: file.name,
        caption: file.name,
        alternativeText: file.name,
        folder: null,
      })
    )

    fetchList.push(
      postAPI({
        url: '/api/upload',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: null,
        },
        data: formData,
      })
    )
    const res = await Promise.all(fetchList)
    //alert(res[0])
    //alert(res[0][0].id)
    if (NewEmployeeData.resume) {
      setNewEmployeeData({ ...NewEmployeeData, resume: [...NewEmployeeData.resume, res[0][0].id] })
    } else {
      setNewEmployeeData({ ...NewEmployeeData, resume: [res[0][0].id] })
    }

    setFile_resume([...file_resume, ...res.map((v) => v[0])])
  }

  const onDeleteFile = (id) => {
    setFile_resume(file_resume.filter((v) => v.id !== id))
    setNewEmployeeData({ ...NewEmployeeData, resume: NewEmployeeData.resume.filter((v) => v !== id) })
  }
  //--- ED File Resume

  //--- ST FILE WorkPermitNo
  const onChangeFilework_permit = async (event) => {
    const file = event.target.files[0]
    const fetchList = []
    const formData = new FormData()
    formData.append('files', file)
    formData.append(
      'fileInfo',
      JSON.stringify({
        name: file.name,
        caption: file.name,
        alternativeText: file.name,
        folder: null,
      })
    )

    fetchList.push(
      postAPI({
        url: '/api/upload',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: null,
        },
        data: formData,
      })
    )
    const res = await Promise.all(fetchList)
    setNewEmployeeData({ ...NewEmployeeData, workpermit_file_id: res[0][0].id })
  }
  //--- ED FILE WorkPermitNo

  const [tabactive, setTabactive] = useState('information')
  const [menuTabList, setMenuTabList] = useState([
    {
      name: t('information'),
      value: 'information',
      active_status: true,
    },
    {
      name: t('positions'),
      value: 'position',
      active_status: false,
    },
    {
      name: t('education'),
      value: 'education',
      active_status: false,
    },
    {
      name: t('resume'),
      value: 'resume',
      active_status: false,
    },
    {
      name: t('work_permit'),
      value: 'work_permit',
      active_status: false,
    },
  ])
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }
  //---- ST INFORMATION
  const Title_option = [
    { text: 'Mr.', value: 'Mr.' },
    { text: 'Mrs.', value: 'Mrs.' },
    { text: 'Miss.', value: 'Miss.' },
  ]

  const MartialStatus_option = [
    { text: 'Single', value: 'Single' },
    { text: 'Married', value: 'Married' },
    { text: 'Divorced', value: 'Divorced' },
    { text: 'Windowed', value: 'Windowed' },
    { text: 'Separated', value: 'Separated' },
    { text: 'Domestic', value: 'Domestic' },
  ]
  const Gender_option = [
    { text: 'Male', value: 'Male' },
    { text: 'Female', value: 'Female' },
  ]
  //---- ED INFORMATION
  //---- ST EDUCATION
  const [listYear, setListYear] = useState([])
  useEffect(() => {
    const currentYear = dayjs().year()
    const yearRange = 50
    const yearList = []

    for (let i = currentYear - yearRange; i <= currentYear + yearRange; i++) {
      yearList.push(i)
    }

    const newYearList = yearList.map((v) => ({
      text: v.toString(),
      value: v,
    }))
    setListYear(newYearList)
  }, [])

  const Certificate_Level_option = [
    /*
    { text: "Bachelor's Degree", value: "Bachelor's Degree" },
    { text: "Master's Degree", value: "Master's Degree" },
    { text: 'Doctorate Degree', value: 'Doctorate Degree' },
    */
    { text: 'ปริญญาตรี', value: 'ปริญญาตรี' },
    { text: 'ปริญญาโท', value: 'ปริญญาโท' },
    { text: 'ปริญญาเอก', value: 'ปริญญาเอก' },
  ]
  //---- ED EDUCATION
  const allowedTypes = []
  const acceptType = allowedTypes.reduce((pre, cur) => {
    return {
      ...pre,
      ...acceptList[cur],
    }
  }, {})

  //--- ST File Photo
  const [file_photo, setFile_photo] = useState([])
  const [ImgPerson, setImgPerson] = useState(iconPerson)

  const handleDeleteFilePhoto = (id) => {}
  const handleAddFilePhoto = async (value) => {
    const fetchList = []

    //const base64 = await convertBase64(value)
    //setImgPerson(base64)
    value.forEach((v) => {
      const formData = new FormData()
      formData.append('files', v)
      formData.append(
        'fileInfo',
        JSON.stringify({
          name: v.name,
          caption: v.name,
          alternativeText: v.name,
          folder: null,
        })
      )

      fetchList.push(
        postAPI({
          url: '/api/upload',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: null,
          },
          data: formData,
        })
      )
    })

    const res = await Promise.all(fetchList)

    setImgPerson(res[0][0].url)
    setNewEmployeeData({ ...NewEmployeeData, photo: res[0][0].id })
  }
  const inputFileRef = useRef(null)
  const list = [
    {
      text: 'Edit',
      action: () => OpenInputFile(),
    },
  ]
  const OpenInputFile = () => {
    inputFileRef.current.click()
  }
  const onChangeFilePhoto = async (event) => {
    const file = event.target.files[0]
    //
    //const base64 = await convertBase64(file)
    //
    //setImgPerson(base64)
    //setFile(file)
    const fetchList = []
    const formData = new FormData()
    formData.append('files', file)
    formData.append(
      'fileInfo',
      JSON.stringify({
        name: file.name,
        caption: file.name,
        alternativeText: file.name,
        folder: null,
      })
    )

    fetchList.push(
      postAPI({
        url: '/api/upload',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: null,
        },
        data: formData,
      })
    )
    const res = await Promise.all(fetchList)
    //
    setImgPerson(res[0][0].url)
    setNewEmployeeData({ ...NewEmployeeData, photo: res[0][0].id })
  }
  //--- ED File Photo
  /*
  const handleAddFileResume = async (value) => {
    const fetchList = []
    value.forEach((v) => {
      const formData = new FormData()
      formData.append('files', v)
      formData.append(
        'fileInfo',
        JSON.stringify({
          name: v.name,
          caption: v.name,
          alternativeText: v.name,
          folder: null,
        })
      )

      fetchList.push(
        postAPI({
          url: '/api/upload',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: null,
          },
          data: formData,
        })
      )
    })

    const res = await Promise.all(fetchList)
    if (NewEmployeeData.resume) {
      setNewEmployeeData({ ...NewEmployeeData, resume: [...NewEmployeeData.resume, res[0][0].id] })
    } else {
      setNewEmployeeData({ ...NewEmployeeData, resume: [res[0][0].id] })
    }

    setFile_resume([...file_resume, ...res.map((v) => v[0])])
  }
  */

  const handleAddFileResume = async (file_id) => {
    //setNewDailySummaryData({ ...NewDailySummaryData, file: [...NewDailySummaryData.file,file_id] })

    if (NewEmployeeData.resume) {
      setNewEmployeeData({ ...NewEmployeeData, resume: [...NewEmployeeData.resume, file_id] })
    } else {
      setNewEmployeeData({ ...NewEmployeeData, resume: [file_id] })
    }
  }

  const onDeleteFileResume = (id) => {
    setFile_resume(file_resume.filter((v) => v.id !== id))
    setNewEmployeeData({ ...NewEmployeeData, resume: NewEmployeeData.resume.filter((v) => v !== id) })
  }

  const [file_Work_permit, setFile_Work_permit] = useState([])
  const handleAddFileWork_permit = async (value) => {
    const fetchList = []
    value.forEach((v) => {
      const formData = new FormData()
      formData.append('files', v)
      formData.append(
        'fileInfo',
        JSON.stringify({
          name: v.name,
          caption: v.name,
          alternativeText: v.name,
          folder: null,
        })
      )

      fetchList.push(
        postAPI({
          url: '/api/upload',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: null,
          },
          data: formData,
        })
      )
    })

    const res = await Promise.all(fetchList)
    setNewEmployeeData({ ...NewEmployeeData, workpermit_file_id: res[0][0].id })

    setFile_Work_permit([...file_Work_permit, ...res.map((v) => v[0])])
  }

  const onDeleteFileWork_permit = (id) => {
    setFile_Work_permit(file_Work_permit.filter((v) => v.id !== id))
    setNewEmployeeData({ ...NewEmployeeData, workpermit_file_id: file_Work_permit.filter((v) => v.id !== id) })
  }

  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [successModal2, setsuccessModal2] = useState(false)
  const confirmsuccessModal = () => {
    setsuccessModal(false)
    resetState()
    onSubmit()
    dispatch(getEmployee())
  }
  const confirmsuccessModal2 = () => {
    setsuccessModal2(false)
  }

  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)
  const confirmFailModal = () => {
    setFailModal(false)
  }

  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [ModalConfirmDialog2, setModalConfirmDialog2] = useState(false)
  const [Modal_NameItem, setModal_NameItem] = useState('Candice Wu')
  const [Modal_Description, setModal_Description] = useState('candice@untitledui.com')

  //--- Modal ยืนยันการสร้าง Employee
  /*
  ขั้นตอนหลังกด Create Employee
  1.เปิด Modal ยืนยันการสร้างข้อมูล โดยจะมี 2 คำถามคือ 1.สร้าง Employee และ สร้าง User เพื่อใช้ Login 2.สร้าง Employee [setModalConfirmDialog(true)] ** OK **
  1.1 เลือกสร้าง Employee และ สร้าง User [const ConfirmDialogAccessRole = ()] ** OK **
  1.1.1 เปิด Modal เลือก Role [setModalDialogAccessRole(true)] ** OK **
  1.1.2 ยืนยันการเลือก Role แล้ว และทำให้สร้าง Employee [const CreateEmployeeAndUserAccessRole = (data)]
  1.1.3 เมื่อสร้าง Employee เสร็จแล้ว เอา Email ไปเช็คกับ API : User ว่ามีข้อมูลหรือยัง
  1.1.4 ถ้าไปเช็คแล้วพบว่าไม่มี ก็ดำเนินการสร้าง user ต่อได้เลย
  1.1.5 ถ้าไปเช็คแล้วว่ามี จะแสดง Modal เพื่อถามว่า ต้องการผูก employee กับ user นี้หรือไม่ [setModalConfirmDialog2(true)] 
  1.1.5.1 ถ้าใช่ก็ดำเนินการเรียก API เพื่อผูก 
  1.1.5.2 ถ้าไม่ก็จบกระบวนการ
  1.2 เลือกสร้างแค่ Employee [const CreateEmployee_DoitLater = ()] ** OK **
  */
  const ConfirmDialog = () => {
    if (isValid().length == 0) {
      setModal_NameItem(NewEmployeeData.first_name + ' ' + NewEmployeeData.last_name)
      setModal_Description(`Email: ${NewEmployeeData.email}`)
      setModalConfirmDialog(true)
    }
  }

  const CreateEmployee_DoitLater = () => {
    setModalConfirmDialog(false)
    const sent_data_api = {
      data: NewEmployeeData,
    }
    //
    dispatch(createEmployee(sent_data_api)).then(({ type }) => {
      if (type.endsWith('_SUCCESS')) {
        setTitlesuccess('Create user successful')
        setSubTitlesuccess('')
        setDescriptionsuccess('')
        setsuccessModal(true)
      } else {
        setTitleFail('Error Create user')
        setnameItemFail('')
        setDescriptionFail('This ID is already existed')
        setFailModal(true)
      }
    })
  }
  const { employeeInfo } = useSelector(selectEmployee)

  const [ModalDialogAccessRole, setModalDialogAccessRole] = useState(false)
  const ConfirmDialogAccessRole = () => {
    setModalConfirmDialog(false)
    /*
    const newFilter = {
      name: NewEmployeeData.identification_no,
    }
    const filter = {
      filters: [...searchFilters, newFilter].map((v) => v.name)
    }
    */
    const data_to_api = {
      id_card: NewEmployeeData.identification_no,
    }
    dispatch(getAccountUserList(data_to_api)).then(({ type, data_info, data_meta }) => {
      //---- เพิ่มการตรวจสอบว่า เลขบัตร ปชช นี้ได้ทำการมีบัญชี Login หรือยัง
      if (type.endsWith('_SUCCESS')) {
        if (data_info.length > 0) {
          //--- email มีในระบบแล้ว
          if (data_info[0].is_employee) {
            //--- เลขบัตร ปชช ได้มีในบัญชี Login แล้ว และได้ทำการผูก Employee แล้ว
            CreateEmployee_DoitLater()
          } else {
            //--- เลขบัตร ปชช ได้มีในบัญชี Login แล้ว แต่ยังไม่ผูก Employee ก็จะไปเลือก Role และผูกให้
            setModalDialogAccessRole(true)
          }
        } else {
          //--- เลขบัตร ปชช ยังไม่มีในบัญชี Login
          setModalDialogAccessRole(true)
        }
      } else {
        //--- เลขบัตร ปชช ยังไม่มีในบัญชี Login
        setModalDialogAccessRole(true)
      }
    })
  }

  const [ThisUserID, setThisUserID] = useState(null)
  const [ThisUserRoles, setThisUserRoles] = useState(null)
  const [ThisEmployeeNo, setThisEmployeeNo] = useState(null)
  const [searchFilters, setSearchFilters] = useState([])
  const CreateEmployeeAndUserAccessRole = (data, type, password) => {
    if (type === 'email-invitation') {
      /*
      1.สร้าง Employee
      2.หลังสร้างแล้วเอา Email ไปเช็คกับ User ว่ามีหรือยัง
      2.1 ถ้ามีแล้วจะแสดง Modal [setModalConfirmDialog2(true)]
      2.2 ถ้ายังไม่มีก็ สร้าง user
      */

      setModalDialogAccessRole(false)
      setThisUserRoles(data)

      //--- สร้าง Employee
      const sent_data_api_employee = {
        data: { ...NewEmployeeData },
      }
      dispatch(createEmployee(sent_data_api_employee)).then(({ data_result, type }) => {
        //--- ส่ง Email ไปเช็คกับ user ว่ามีหรือยัง
        //--- ถ้ามีแล้วก็จะเปิด Modal เพิ่มยื่นยันว่าต้องการผูกหรือไม่
        //setModalConfirmDialog2(true)
        //--- ถ้ายังไม่มีก็ไปสร้าง User

        if (type.endsWith('_SUCCESS')) {
          const sent_data_api = {
            data: {
              email: data_result.attributes.email,
              firstname: data_result.attributes.first_name,
              lastname: data_result.attributes.last_name,
              employee_no: data_result.attributes.employ_id,
              id_card: data_result.attributes.identification_no,
              phone_no: data_result.attributes.phone_no,
              department: NewEmployeeData.department,
              position: NewEmployeeData.position,
              roles: data,
              company: CompanyInfo?.id,
            },
          }

          dispatch(createAccountUser(sent_data_api)).then(({ type }) => {
            if (type.endsWith('_SUCCESS')) {
              setTitlesuccess('Create user successful')
              setSubTitlesuccess('user can now log-in with 1st time password')
              setDescriptionsuccess('')
              setsuccessModal(true)
            } else {
              //--- สร้างไม่สำเร็จอาจจะเป็นเคสมีการใช้ email แล้ว
              setTitleFail('Error Create user')
              setnameItemFail('')
              setDescriptionFail('')
              setFailModal(true)
            }
          })
        } else {
          setTitleFail('Error Create Employee')
          setnameItemFail('')
          setDescriptionFail('This ID is already existed')
          setFailModal(true)
        }
      })
    } else {
      const sent_data_api_employee = {
        data: { ...NewEmployeeData, is_email_invite: false },
      }
      dispatch(createEmployee(sent_data_api_employee)).then(({ data_result, type }) => {
        if (type.endsWith('_SUCCESS')) {
          const sent_data_api = {
            data: {
              email: data_result.attributes.email,
              firstname: data_result.attributes.first_name,
              lastname: data_result.attributes.last_name,
              employee_no: data_result.attributes.employ_id,
              id_card: data_result.attributes.identification_no,
              phone_no: data_result.attributes.phone_no,
              department: NewEmployeeData.department,
              position: NewEmployeeData.position,
              roles: data,
              company: CompanyInfo?.id,
              // new
              password: password,
              username: NewEmployeeData.company_employee_id,
            },
          }

          dispatch(createAccountUserWithPassword(sent_data_api)).then(({ type }) => {
            if (type.endsWith('_SUCCESS')) {
              setTitlesuccess('Create user successful')
              setSubTitlesuccess('user can now log-in with 1st time password')
              setDescriptionsuccess('')
              setsuccessModal(true)
            } else {
              //--- สร้างไม่สำเร็จอาจจะเป็นเคสมีการใช้ email แล้ว
              setTitleFail('Error Create user')
              setnameItemFail('')
              setDescriptionFail('')
              setFailModal(true)
            }
          })
        } else {
          setTitleFail('Username is empty')
          setnameItemFail('')
          setDescriptionFail('Please input username before proceeding')
          setFailModal(true)

          // focus user name input
          handleClickTaskMenuTab({
            name: 'positions',
            value: 'position',
            active_status: true,
          })
          setModalDialogAccessRole(false)

          if (userNameInput.current) {
            userNameInput?.current.focus()
            setErrorMessageUserName('Fill Company Employee ID')
          }
        }
      })
    }
  }

  const ConfirmChangeUserType = () => {
    setModalConfirmDialog2(false)
    const sent_data_api = {
      data: {
        email: NewEmployeeData.email,
        firstname: NewEmployeeData.first_name,
        lastname: NewEmployeeData.last_name,
        employee_no: ThisEmployeeNo,
        id_card: NewEmployeeData.identification_no,
        phone_no: NewEmployeeData.phone_no,
        department: NewEmployeeData.department,
        position: NewEmployeeData.position,
        roles: ThisUserRoles,
      },
    }
    dispatch(updateUser(ThisUserID, sent_data_api)).then(({ type }) => {
      if (type.endsWith('_SUCCESS')) {
        setTitlesuccess('Create user successful')
        setSubTitlesuccess('')
        setDescriptionsuccess('')
        setsuccessModal(true)
      } else {
        setTitleFail('Error Create user')
        setnameItemFail('')
        setDescriptionFail('')
        setFailModal(true)
      }
    })
    //alert('เรียก API ผูก employee กับ user เข้่าด้วยกัน')
  }

  const isValid = () => {
    let data_valid = NewEmployeeData
    let data_check_valid = NewEmployeeError
    let errors = []
    Object.keys(NewEmployeeError).forEach((k) => {
      let data = data_valid[k]
      if (data_check_valid[k].errorMessage != '') {
        if (!data || data < 0) {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
          if (k == 'email') {
            data_check_valid[k].errorMessage = 'Fill Email'
          }
        } else {
          if (k == 'email') {
            //--- valid email
            if (!validateEmail(data)) {
              errors.push(data_check_valid[k].errorMessage)
              data_check_valid[k].errorMessage = 'Email is invalid'
              data_check_valid[k].show_errorMessage = true
            } else {
              data_check_valid[k].show_errorMessage = false
            }
          } else {
            data_check_valid[k].show_errorMessage = false
          }
        }
      }
    })
    setNewEmployeeError({ ...NewEmployeeError })
    return errors
  }

  // signature modal
  const listAction = [
    {
      text: 'Edit',
      action: () => openSignatureModal(),
    },
  ]
  const [signatureModal, setSignatureModal] = useState(false)

  const openSignatureModal = () => {
    setSignatureModal(true)
  }
  const closeSignatureModal = () => {
    setSignatureModal(false)
  }

  // warning modal
  const initTextWarning = {
    headline: t('warning_upload_sign_heading'),
    description: t('warning_upload_sign_sub_heading'),
  }
  const [warningText] = useState(initTextWarning)
  const [isWarningModal, setIsWarningModal] = useState(false)

  // title th
  const initTitleTh = [
    {
      text: 'นาย',
      value: 'Mr.',
    },
    {
      text: 'นางสาว',
      value: 'Miss.',
    },
    {
      text: 'นาง',
      value: 'Mrs.',
    },
  ]

  // employee profile store
  const { response, state, isLoading } = useSelector(selectEmployeeProfile)
  const [signatureImage, setSignatureImage] = useState(null)

  useEffect(() => {
    if (open) {
      if (isLoading === false && state === 'UPLOAD_SIGNATURE_IMAGE.SUCCESS') {
        closeSignatureModal()
        setTitlesuccess('Upload done')
        setSubTitlesuccess('')
        setDescriptionsuccess('')
        setsuccessModal2(true)

        if (response[0]) {
          const { url, id } = response[0]
          setSignatureImage(url)
          setNewEmployeeData((prev) => ({
            ...prev,
            signature: id,
          }))
        }
      }
      if (isLoading === false && state === 'UPLOAD_SIGNATURE_IMAGE.FAILURE') {
        closeSignatureModal()
        setTitleFail('Upload Fail')
        setnameItemFail('')
        setDescriptionFail('')
        setFailModal(true)

        setSignatureImage(null)
        setNewEmployeeData((prev) => ({
          ...prev,
          signature: null,
        }))
      }
      dispatch(clearStateEmployeeProfile())
    } else {
      dispatch(clearResponseUploadSignature())
      dispatch(clearStateEmployeeProfile())
    }
  }, [open, state, isLoading])

  return (
    <EmployeeDiv>
      <div className="content_header">
        <header>
          <div className="content-title">{t('new_employee')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="wrp-tab-menu">
          {menuTabList?.map((item, index) => (
            <button
              id={'this_name-' + item.name}
              key={'btn-' + index}
              className={item.active_status ? 'menu-active' : 'btn-menu-style'}
              onClick={() => handleClickTaskMenuTab(item)}
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>
      <div className="dialog-content">
        {tabactive == 'information' && (
          <div>
            <div>
              <div className="title">
                <div>{t('basic_information')}</div>
                <hr />
              </div>

              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('title')} (EN)*</div>
                  <div>
                    <Dropdown
                      id={`title`}
                      className="dd_employee"
                      value={NewEmployeeData.title}
                      optionList={Title_option}
                      onChange={onTitle}
                      error={Boolean(NewEmployeeError.title.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.title.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.title.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>

                <div>
                  <div className="topic_fill">{t('first_name')}</div>
                  <div>
                    <TextField
                      name="first_name"
                      className="TextField_employee"
                      placeholder={t('first_name')}
                      value={NewEmployeeData.first_name}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, first_name: e.target.value })}
                      error={Boolean(NewEmployeeError.first_name.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.first_name.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.first_name.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>

                <div>
                  <div className="topic_fill">{t('last_name')}</div>
                  <div>
                    <TextField
                      name="last_name"
                      className="TextField_employee"
                      placeholder={t('last_name')}
                      value={NewEmployeeData.last_name}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, last_name: e.target.value })}
                      error={Boolean(NewEmployeeError.last_name.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.last_name.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.last_name.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>

              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('title')} (TH)*</div>
                  <div>
                    <Dropdown
                      id={`title`}
                      className="dd_employee"
                      value={NewEmployeeData.title}
                      optionList={initTitleTh}
                      onChange={onTitle}
                      error={Boolean(NewEmployeeError.title.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.title.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.title.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>

                <div>
                  <div className="topic_fill">{t('first_name_th')}</div>
                  <div>
                    <TextField
                      name="first_name_th"
                      className="TextField_employee"
                      placeholder={t('first_name_th')}
                      value={NewEmployeeData.first_name_th}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, first_name_th: e.target.value })}
                      error={Boolean(NewEmployeeError.first_name_th.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.first_name_th.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.first_name_th.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>

                <div>
                  <div className="topic_fill">{t('last_name_th')}</div>
                  <div>
                    <TextField
                      name="last_name_th"
                      className="TextField_employee"
                      placeholder={t('last_name_th')}
                      value={NewEmployeeData.last_name_th}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, last_name_th: e.target.value })}
                      error={Boolean(NewEmployeeError.last_name_th.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.last_name_th.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.last_name_th.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>

              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('phone_number')}</div>
                  <div>
                    <NumberFormatter
                      onlyCountries="THA"
                      defaultCountry="TH"
                      placeholder={'+66 (000) 000 0000'}
                      value={NewEmployeeData.phone_no}
                      initialFormat={true}
                      getValue={(n) => setNewEmployeeData({ ...NewEmployeeData, phone_no: n })}
                    />
                    {NewEmployeeError.phone_no.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.phone_no.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('email')}</div>
                  <div>
                    <TextField
                      name="email"
                      className="TextField_employee"
                      placeholder={t('email')}
                      value={NewEmployeeData.email}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, email: e.target.value })}
                      error={Boolean(NewEmployeeError.email.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.email.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.email.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('identification_no')}</div>
                  <div>
                    <TextField
                      name="identification_no"
                      className="TextField_employee"
                      placeholder={t('identification_no')}
                      value={NewEmployeeData.identification_no}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, identification_no: e.target.value })}
                      error={Boolean(NewEmployeeError.identification_no.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.identification_no.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.identification_no.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mg_top_20">
              <div className="title">
                <div>Photo and Signature</div>
                <hr />
              </div>
              <FlexContainer>
                <div className="mr-24-px">
                  <div className="topic_fill">{t('profile_photo')}</div>
                  {NewEmployeeData.photo && (
                    <div className="div_img_profile">
                      <div className="box_img_profile">
                        <img className="img-user" src={ImgPerson} />
                        <div className="box_edit_profile">
                          <CollapseMenu list={list} />
                        </div>
                        <HiddenInput ref={inputFileRef} id="custom-file-input" onChange={onChangeFilePhoto} />
                      </div>
                    </div>
                  )}
                  {!NewEmployeeData.photo && (
                    <div>
                      <FileUpload
                        multiple={multiple}
                        accept={acceptType}
                        file={file_photo.map((v) => ({ ...v, url: `${v.url}` }))}
                        onAddFile={handleAddFilePhoto}
                        onDeleteFile={handleDeleteFilePhoto}
                        error={error}
                        disabled={disabled}
                      />
                    </div>
                  )}
                </div>

                <SignaturePanel>
                  <div className="heading">Signature</div>

                  {signatureImage ? (
                    <div className="image-container">
                      <img src={signatureImage} alt="signature image" />
                      <CollapseMenu className="action" list={listAction} />
                    </div>
                  ) : (
                    <div className="panel" onClick={openSignatureModal}>
                      <img src={uploadIcon} alt="upload icon" />
                      <p className="text">
                        <b className="highlight">Click to upload</b> or drag and drop
                      </p>
                    </div>
                  )}
                </SignaturePanel>
              </FlexContainer>
            </div>

            <div className="mg_top_20">
              <div className="title">
                <div>{t('family_citizenship_information')}</div>
                <hr />
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('martial_status')}</div>
                  <div>
                    <Dropdown
                      id={`martial_status`}
                      className="dd_employee"
                      value={NewEmployeeData.martial_status}
                      optionList={MartialStatus_option}
                      onChange={onChangeMartialStatus}
                      error={Boolean(NewEmployeeError.martial_status.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.martial_status.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.martial_status.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('number_of_children')}</div>
                  <div>
                    <TextField
                      name="number_of_children"
                      className="TextField_employee"
                      placeholder={t('number_of_children')}
                      value={NewEmployeeData.number_of_children}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, number_of_children: e.target.value })}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('nationality')}</div>
                  <div>
                    <Dropdown
                      id={`nationality`}
                      className="dd_employee"
                      value={NewEmployeeData.nationality}
                      optionList={nationalityList}
                      onChange={onChangeNationality}
                      error={Boolean(NewEmployeeError.nationality.show_errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                    {NewEmployeeError.nationality.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.nationality.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('gender')}</div>
                  <div>
                    <Dropdown
                      id={`gender`}
                      className="dd_employee"
                      value={NewEmployeeData.gender}
                      optionList={Gender_option}
                      onChange={onChangeGender}
                      error={Boolean(NewEmployeeError.gender.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.gender.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.gender.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('identification_no')}</div>
                  <div>
                    <TextField
                      name="identification_no"
                      className="TextField_employee"
                      placeholder={t('identification_no')}
                      value={NewEmployeeData.identification_no}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, identification_no: e.target.value })}
                      error={Boolean(NewEmployeeError.identification_no.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.identification_no.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.identification_no.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('passport_no')}</div>
                  <div>
                    <TextField
                      name="passport_no"
                      className="TextField_employee"
                      placeholder={t('passport_no')}
                      value={NewEmployeeData.passport_no}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, passport_no: e.target.value })}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('date_of_birth')}</div>
                  <div>
                    <Date
                      className={'input-calendar'}
                      value={NewEmployeeData.birth_date}
                      onChange={onChangeBirth_date}
                      error={Boolean(NewEmployeeError.birth_date.show_errorMessage)}
                    />
                    {NewEmployeeError.birth_date.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.birth_date.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mg_top_20">
              <div className="title">
                <div>{t('private_contact')}</div>
                <hr />
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('address')}</div>
                  <div>
                    <TextField
                      name="address"
                      placeholder={t('address')}
                      className="address_fill"
                      value={NewEmployeeData.address}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, address: e.target.value })}
                      error={Boolean(NewEmployeeError.address.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.address.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.address.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('province')}</div>
                  <div className="css_autocom_address">
                    <AutoComplete
                      optionList={provinces_option}
                      value={NewEmployeeData.thai_province}
                      error={Boolean(errorMessage)}
                      onChange={onChangeProvince}
                    />
                    {/*
                      <Dropdown
                        id={`thai_province`}
                        className="address_file_dd"
                        value={NewEmployeeData.thai_province}
                        optionList={provinces_option}
                        onChange={onChangeProvince}
                        error={Boolean(errorMessage)}
                        disabled={!editable}
                        ValueplaceHolderIsNull={true}
                      />
                     */}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('district')}</div>
                  <div className="css_autocom_address">
                    <AutoComplete
                      optionList={amphure_option}
                      value={NewEmployeeData.thai_amphure}
                      error={Boolean(errorMessage)}
                      onChange={onChangeAmphure}
                    />
                    {/*
                    <Dropdown
                        id={`thai_amphure`}
                        className="address_file_dd"
                        value={NewEmployeeData.thai_amphure}
                        optionList={amphure_option}
                        onChange={onChangeAmphure}
                        error={Boolean(errorMessage)}
                        disabled={!editable}
                        ValueplaceHolderIsNull={true}
                    />
                    */}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('sub_district')}</div>
                  <div className="css_autocom_address">
                    <AutoComplete
                      optionList={tambon_option}
                      value={NewEmployeeData.thai_tambon}
                      error={Boolean(errorMessage)}
                      onChange={onChangeTambon}
                    />
                    {/*
                      <Dropdown
                        id={`thai_tambon`}
                        className="address_file_dd"
                        value={NewEmployeeData.thai_tambon}
                        optionList={tambon_option}
                        onChange={onChangeTambon}
                        error={Boolean(errorMessage)}
                        disabled={!editable}
                        ValueplaceHolderIsNull={true}
                      />
                      */}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('postcode')}</div>
                  <div>
                    <TextField
                      name="postcode"
                      placeholder={t('postcode')}
                      className="address_fill"
                      value={NewEmployeeData.postcode}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, postcode: e.target.value })}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('bank_account')}</div>
                  <div>
                    <Dropdown
                      id={`bank`}
                      className="dd_employee"
                      value={NewEmployeeData.bank}
                      optionList={bankList}
                      onChange={onChangeBankAccount}
                      error={Boolean(NewEmployeeError.bank.show_errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                    {NewEmployeeError.bank.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.bank.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('account_number')}</div>
                  <div>
                    <TextField
                      name="bank_account_no"
                      className="TextField_employee"
                      placeholder={t('account_number')}
                      value={NewEmployeeData.bank_account_no}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, bank_account_no: e.target.value })}
                      error={Boolean(NewEmployeeError.bank_account_no.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.bank_account_no.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.bank_account_no.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('private_car_plate')}</div>
                  <div>
                    <TextField
                      name="private_car_plate"
                      className="TextField_employee"
                      placeholder={t('private_car_plate')}
                      value={NewEmployeeData.private_car_plate}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, private_car_plate: e.target.value })}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('emergency_contact')}</div>
                  <div>
                    <TextField
                      name="emergency_contact"
                      className="TextField_employee"
                      placeholder={t('emergency_contact')}
                      value={NewEmployeeData.emergency_contact}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, emergency_contact: e.target.value })}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('emergency_phone')}</div>
                  <div>
                    <TextField
                      name="emergency_phone_no"
                      className="TextField_employee"
                      placeholder={t('emergency_phone')}
                      value={NewEmployeeData.emergency_phone_no}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, emergency_phone_no: e.target.value })}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        )}
        {tabactive == 'position' && (
          <div>
            <div>
              <div className="title">
                <div>Employment Details</div>
                <div className="sub_title">Details of emploment</div>
                <hr />
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Company Employee ID</div>
                  <div>
                    <TextFieldRef
                      ref={userNameInput}
                      name="company_employee_id"
                      placeholder={'Company Employee ID'}
                      className="w-100"
                      value={NewEmployeeData.company_employee_id}
                      onChange={onChangeEmployeeForm}
                      error={Boolean(errorMessageUserName)}
                      disabled={!editable}
                    />
                    {errorMessageUserName && <DivErrorMessage>{errorMessageUserName}</DivErrorMessage>}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Employee Type</div>
                  <div>
                    <Dropdown
                      id={`employee_type`}
                      className="dd_employee"
                      value={NewEmployeeData.employee_type}
                      optionList={employee_type_option}
                      onChange={onEmployee_type}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Work Start Date</div>
                  <div>
                    <Date
                      className={'input-calendar'}
                      value={NewEmployeeData.work_start_date}
                      onChange={onChangework_start_date}
                      error={false}
                    />
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Evaluation Date</div>
                  <div>
                    <Date
                      className={'input-calendar'}
                      value={NewEmployeeData.evaluation_date}
                      onChange={onChangeEvaluation_date}
                      error={false}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Employee Status</div>
                  <div>
                    <Dropdown
                      id={`employee_type_status`}
                      className="dd_employee"
                      value={NewEmployeeData.employee_type_status}
                      optionList={employee_type_status_option}
                      onChange={onemployee_type_status}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Work End Date</div>
                  <div>
                    <Date
                      className={'input-calendar'}
                      value={NewEmployeeData.work_end_date}
                      onChange={onChangework_end_date}
                      error={false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mg_top_20">
              <div className="title">
                <div>{t('primary_job_position')}</div>
                <div className="sub_title">{t('Your_main_job_in_this_company')}</div>
                <hr />
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('department')}</div>
                  <div>
                    <Dropdown
                      id={`department`}
                      className="dd_employee"
                      value={NewEmployeeData.department}
                      optionList={departmentList}
                      onChange={onDepartment}
                      error={Boolean(NewEmployeeError.department.show_errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                    {NewEmployeeError.department.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.department.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('job_position')}</div>
                  <div>
                    <Dropdown
                      id={`position`}
                      className="dd_employee"
                      value={NewEmployeeData.position}
                      optionList={positionList}
                      onChange={onJobPosition}
                      error={Boolean(NewEmployeeError.position.show_errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                    {NewEmployeeError.position.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.position.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('position_level')}</div>
                  <div>
                    <Dropdown
                      id={`department`}
                      className="dd_employee"
                      value={NewEmployeeData.position_level}
                      optionList={positionLevelList}
                      onChange={onChangeposition_level}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('supervisor')}</div>
                  <div>
                    <AutoComplete
                      optionList={supervisorList}
                      value={NewEmployeeData.supervisors}
                      error={Boolean(errorMessage)}
                      onChange={onChangeSupervisor}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mg_top_20">
              <div className="title">
                <div>{t('secondary_job_position_If_any')}</div>
                <div className="sub_title">{t('your_second_position_in_this_company')}</div>
                <hr />
              </div>
              {NewEmployeeData.secondary_job?.map((item, index) => (
                <div key={index}>
                  <div className="basic-Information-detail">
                    <div>
                      <div className="topic_fill">{t('department')}</div>
                      <div>
                        <Dropdown
                          id={`Department_` + index}
                          className="dd_css"
                          value={item.department}
                          optionList={departmentList}
                          onChange={(v) => onChangeSecondaryDepartment(index, v)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                          ValueplaceHolderIsNull={true}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">{t('job_position')}</div>
                      <div>
                        <Dropdown
                          id={`Position_` + index}
                          className="dd_css"
                          value={item.position}
                          optionList={item.positionList}
                          onChange={(v) => onChangeSecondaryPositon(index, v)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                          ValueplaceHolderIsNull={true}
                        />
                      </div>
                    </div>
                    <div className="css_bin_secondary">
                      <img src={iconDelete} title="Delete" onClick={() => RemoveSecondary_job(index)} />
                    </div>
                  </div>
                </div>
              ))}
              <div className="basic-Information-detail">
                <div>
                  <Button
                    className="button_add_major"
                    onClick={() => handleAddSecondary_job(NewEmployeeData.secondary_job)}
                  >
                    + {t('more_position')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {tabactive == 'education' && (
          <div>
            <div>
              <div className="title">
                <div>{t('education_information')}</div>
                <hr />
              </div>
              <div className="basic-Information-detail-v3">
                <div>
                  <div className="topic_fill">{t('elementary_school')}</div>
                  <div>
                    <TextField
                      name="elementary_school"
                      placeholder={t('elementary_school')}
                      className="text_css"
                      value={NewEmployeeData.education[0].school_name}
                      onChange={(e) => onChangeEducationSchool_name(0, e)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('entry_year')}</div>
                  <div>
                    <Dropdown
                      id={`elementary_school_entry_year`}
                      className="dropdrow_css"
                      value={NewEmployeeData.education[0].entry_year}
                      optionList={listYear}
                      onChange={(v) => onChangeEducationEntry_year(0, v)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('graduate_year')}</div>
                  <div>
                    <Dropdown
                      id={`elementary_school_graduate_year`}
                      className="dropdrow_css"
                      value={NewEmployeeData.education[0].graduate_year}
                      optionList={listYear}
                      onChange={(v) => onChangeEducationGraduate_year(0, v)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail-v3">
                <div>
                  <div className="topic_fill">{t('junior_high_school')}</div>
                  <div>
                    <TextField
                      name="junior_school"
                      placeholder={t('junior_high_school')}
                      className="text_css"
                      value={NewEmployeeData.education[1].school_name}
                      onChange={(e) => onChangeEducationSchool_name(1, e)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('entry_year')}</div>
                  <div>
                    <Dropdown
                      id={`junior_school_entry_year`}
                      className="dropdrow_css"
                      value={NewEmployeeData.education[1].entry_year}
                      optionList={listYear}
                      onChange={(v) => onChangeEducationEntry_year(1, v)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('graduate_year')}</div>
                  <div>
                    <Dropdown
                      id={`junior_school_graduate_year`}
                      className="dropdrow_css"
                      value={NewEmployeeData.education[1].graduate_year}
                      optionList={listYear}
                      onChange={(v) => onChangeEducationGraduate_year(1, v)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail-v3">
                <div>
                  <div className="topic_fill">{t('senior_high_school')}</div>
                  <div>
                    <TextField
                      name="senior_school"
                      placeholder={t('senior_high_school')}
                      className="text_css"
                      value={NewEmployeeData.education[2].school_name}
                      onChange={(e) => onChangeEducationSchool_name(2, e)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('entry_year')}</div>
                  <div>
                    <Dropdown
                      id={`senior_school_entry_year`}
                      className="dropdrow_css"
                      value={NewEmployeeData.education[2].entry_year}
                      optionList={listYear}
                      onChange={(v) => onChangeEducationEntry_year(2, v)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('graduate_year')}</div>
                  <div>
                    <Dropdown
                      id={`senior_school_graduate_year`}
                      className="dropdrow_css"
                      value={NewEmployeeData.education[2].graduate_year}
                      optionList={listYear}
                      onChange={(v) => onChangeEducationGraduate_year(2, v)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mg_top_20">
              <div className="title">
                <div>{t('university')}</div>
                <hr />
              </div>
              {NewEmployeeData.university?.map((item, index) => (
                <div key={index}>
                  <div className="basic-Information-detail">
                    <div>
                      <div className="topic_fill">{t('certificate_level')}</div>
                      <div>
                        <Dropdown
                          id={`certificate_level_` + index}
                          className="dd_css"
                          value={item.certificate_level}
                          optionList={Certificate_Level_option}
                          onChange={(v) => onChangeCertificate_level(index, v)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">{t('Field_of_study')}</div>
                      <div>
                        <TextField
                          name={`field_study_` + index}
                          placeholder={t('Field_of_study')}
                          className="dd_css"
                          value={item.field_study}
                          onChange={(e) => onChangeField_study(index, e)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">{t('university')}</div>
                      <div>
                        <TextField
                          name={`university_name_` + index}
                          placeholder={t('university')}
                          className="dd_css"
                          value={item.university_name}
                          onChange={(e) => onChangeUniversity_name(index, e)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                          ValueplaceHolderIsNull={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="basic-Information-detail">
                    <div>
                      <div className="topic_fill">{t('entry_year')}</div>
                      <div>
                        <Dropdown
                          id={`university_entry_year_` + index}
                          className="dd_css"
                          value={item.entry_year}
                          optionList={listYear}
                          onChange={(v) => onChangeEntry_year(index, v)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                          ValueplaceHolderIsNull={true}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">{t('graduate_year')}</div>
                      <div>
                        <Dropdown
                          id={`university_graduate_year_` + index}
                          className="dd_css"
                          value={item.graduate_year}
                          optionList={listYear}
                          onChange={(v) => onChangeGraduate_year(index, v)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                          ValueplaceHolderIsNull={true}
                        />
                      </div>
                    </div>
                    <div className="css_bin_secondary">
                      <img src={iconDelete} title="Delete" onClick={() => RemoveUniversities(index)} />
                    </div>
                  </div>
                </div>
              ))}
              <div className="basic-Information-detail">
                <div>
                  <Button
                    className="button_add_major"
                    onClick={() => handleAddUniversities(NewEmployeeData.university)}
                  >
                    {t('add_major')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {tabactive == 'resume' && (
          <div>
            <div className="title">
              <div>{t('resume')}</div>
              <hr />
            </div>
            <div className="basic-Information-detail">
              <div>
                <FileUploadProgress
                  multiple={multiple}
                  accept={acceptType}
                  file={file_resume.map((v) => ({ ...v, url: `${v.url}` }))}
                  onAddFile={handleAddFileResume}
                  onDeleteFile={onDeleteFileResume}
                  error={error}
                  disabled={disabled}
                  FileListData={file_resume}
                  setFileListData={setFile_resume}
                />
              </div>
              <div></div>
            </div>
          </div>
        )}
        {tabactive == 'work_permit' && (
          <div>
            <div className="title">
              <div>{t('work_permit')}</div>
              <hr />
            </div>
            <div className="basic-Information-detail">
              <div>
                <div className="topic_fill">{t('visa_no')}.</div>
                <div>
                  <TextField
                    name="visa_no"
                    placeholder={t('visa_no')}
                    className="TextField_employee"
                    value={NewEmployeeData.visa_no}
                    onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, visa_no: e.target.value })}
                    error={Boolean(errorMessage)}
                    disabled={!editable}
                  />
                </div>
              </div>
              <div>
                <div className="topic_fill">{t('visa_expire_date')}</div>
                <div>
                  <Date
                    className={'input-calendar'}
                    value={NewEmployeeData.visa_expire_date}
                    onChange={onChangeVisaExpireDate}
                  />
                </div>
              </div>
            </div>
            <div className="basic-Information-detail">
              <div>
                <div className="topic_fill">{t('work_permit_no')}.</div>
                <div>
                  <TextField
                    name="work_permit_no"
                    placeholder={t('work_permit_no')}
                    className="TextField_employee"
                    value={NewEmployeeData.work_permit_no}
                    onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, work_permit_no: e.target.value })}
                    error={Boolean(errorMessage)}
                    disabled={!editable}
                  />
                </div>
              </div>
              <div>
                <div className="topic_fill">{t('work_permit_expire_date')}</div>
                <div>
                  <Date
                    className={'input-calendar'}
                    value={NewEmployeeData.workpermit_expire_date}
                    onChange={onChangeWorkPermitExpirationDate}
                  />
                </div>
              </div>
            </div>
            <div className="basic-Information-detail">
              <div>
                <div className="topic_fill">{t('upload_file')}</div>
                <div className="topic_detail">หากมีมากกว่า 1 ฉบับ ให้รวมเป็นไฟล์เดียว</div>
                {file_Work_permit.length > 0 && (
                  <div>
                    <DivUploadList>
                      {file_Work_permit?.map((v) => (
                        <div className="file" key={v.id}>
                          <div className="icon-wrapper">
                            <img src={iconFile} />
                          </div>
                          <div className="detail-wrapper">
                            <div className="file-name">
                              <a href={v.url} target="_blank" rel="noreferrer">
                                {v.name}
                              </a>
                            </div>
                            <div className="file-size">{v.size} KB</div>
                          </div>
                          <div className="action-wrapper">
                            <IconButton src={iconBin} title="Delete" onClick={() => onDeleteFileWork_permit(v.id)} />
                          </div>
                        </div>
                      ))}
                    </DivUploadList>
                  </div>
                )}
                {file_Work_permit.length == 0 && (
                  <div>
                    <FileUpload
                      multiple={multiple}
                      accept={acceptType}
                      file={file_Work_permit.map((v) => ({ ...v, url: `${v.url}` }))}
                      onAddFile={handleAddFileWork_permit}
                      onDeleteFile={onDeleteFileWork_permit}
                      error={error}
                      disabled={disabled}
                    />
                  </div>
                )}
              </div>
              <div></div>
            </div>
          </div>
        )}
      </div>
      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={onClose}>
          {t('cancel')}
        </ButtonOutline>
        <Button className="button_margin" onClick={ConfirmDialog}>
          {t('create')}
        </Button>
      </div>

      {/* The first modal confirm */}
      <DialogConfirmV2
        open={Boolean(ModalConfirmDialog)}
        onClose={() => setModalConfirmDialog(false)}
        onNo={CreateEmployee_DoitLater}
        onSubmit={ConfirmDialogAccessRole}
        icon={userIconPlus}
        title={t('create_user_for_employee')}
        nameItem={Modal_NameItem}
        description={Modal_Description}
        html={
          <AdditionInfo>
            <p>Username: {NewEmployeeData.company_employee_id === '' ? '-' : NewEmployeeData.company_employee_id}</p>
            <br />
            <p>
              Username is set to be <b>{companyInfo.username_field}</b>
            </p>
          </AdditionInfo>
        }
        textYes={t('create_user')}
        textNo={t('do_it_later')}
      />
      <DialogConfirmV2
        open={Boolean(ModalConfirmDialog2)}
        onClose={() => setModalConfirmDialog2(false)}
        onNo={() => setModalConfirmDialog2(false)}
        onSubmit={ConfirmChangeUserType}
        icon={userIconPlus}
        title={t('this_employee_already_has_user_account')}
        nameItem={Modal_NameItem}
        description={Modal_Description}
        html={
          <>
            <div className="description margin_bottom">non-employee</div>
            <div className="name-item">{t('do_you_want_to_change_user_type_to_employee')}</div>
          </>
        }
        textYes={t('yes')}
        textNo={t('no')}
      />

      {/* The second modal confirm for select how to create account */}
      <DialogAccessRole
        open={Boolean(ModalDialogAccessRole)}
        onClose={() => setModalDialogAccessRole(false)}
        onSubmit={CreateEmployeeAndUserAccessRole}
        icon={userIconPlus}
        title={'How to create account for this user?'}
        nameItem={Modal_NameItem}
        description={Modal_Description}
        userName={NewEmployeeData.company_employee_id}
        textYes={t('create_user')}
      />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => {
          dispatch(getEmployee())
          setsuccessModal(false)
        }}
        onSubmit={confirmsuccessModal}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />
      <DialogSuccess
        open={Boolean(successModal2)}
        onClose={() => setsuccessModal2(false)}
        onSubmit={confirmsuccessModal2}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />
      <DialogWarning
        open={Boolean(isWarningModal)}
        onClose={() => setIsWarningModal(false)}
        onSubmit={() => setIsWarningModal(false)}
        icon={warningIcon}
        title={warningText.headline}
        description={warningText.description}
        textYes={t('ok')}
      />

      {/* Modal */}
      <EmployeeSignatureForFormModal
        open={signatureModal}
        onClose={closeSignatureModal}
        setIsWarningModal={setIsWarningModal}
      />
    </EmployeeDiv>
  )
}

export default NewEmployeeModal
