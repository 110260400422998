import { Box, Divider, Stack } from '@mui/material'
import { styled } from '@mui/system'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiAddLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import Button from 'components/common/Button'
import Chip from 'components/common/Chip'
import CollapseMenu from 'components/common/CollapseMenu'
import Progress from 'components/common/Progress'
import SidePanelV2 from 'components/common/SidePanelV2'
import TableV3 from 'components/common/TableV3'
import Typography from 'components/common/Typography'
import CreateCL from 'components/feature/operation-process/form/chain-link/create'
import EditCL from 'components/feature/operation-process/form/chain-link/edit'
import CreateSP from 'components/feature/operation-process/form/sop/create'
import EditSP from 'components/feature/operation-process/form/sop/edit'
import Tabs from 'components/feature/operation-process/tabs'
import { CHAIN_LINK, SP } from 'constants/operation-process'
import { GrStatusGoodSmall } from 'react-icons/gr'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { formatDate } from 'utils/dateHelpers'
import SearchSPForm from './SearchSPForm'
import tableColumns from './table-columns'

const TABS = {
  CL: 'chain-link',
  SOP: 'sop',
}

const init_drawer = {
  createSP: false,
  editSP: false,
  createCL: false,
  editCL: false,
  spId: undefined,
  chainLinkId: undefined,
}

const { STATUS_CHIP } = SP

export default function MainSection() {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const refSPCreate = useRef()
  const refSPEdit = useRef()
  const refCreateCL = useRef()
  const refEditCL = useRef()
  const refSearchForm = useRef()
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)

  const [tableType, setTableType] = useState(TABS['SOP'])
  const [tableProps, setTableProps] = useState({})
  const [drawer, setDrawer] = useState(init_drawer)
  const [filters, setFilters] = useState({})

  const { sp: spActions, chainLink: chainLinkActions } = opActions()
  const { getSP, postSP, putSP, clearSP } = spActions()
  const { getCL, postCL, putCL, clearCL } = chainLinkActions()

  const { sps, sp, sps_pagination } = opProcSelector
  const { chainLinks, chainLink, chainLinks_pagination } = opProcSelector

  const isCLTable = tableType === TABS.CL

  const fetchSPs = useCallback(
    async (payload) => {
      dispatch(getSP().list(payload))
    },
    [dispatch]
  )

  const fetchChainLinks = useCallback(
    async (payload) => {
      dispatch(getCL().list(payload))
    },
    [dispatch]
  )

  const handleToggleDrawer = (drawerName, open, data = {}) => {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }

      setDrawer({ ...init_drawer, [drawerName]: open, ...data })
    }
  }

  const handleCancel = (recId) => {
    const postAction = isCLTable ? postCL().cancel : postSP().cancel

    dispatch(postAction(recId)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        isCLTable ? fetchChainLinks() : fetchSPs()
        return
      }
    })
  }

  const action_list = (recordObj = {}, type = TABS.SOP) => {
    const isCL = type === TABS.CL
    return [
      {
        text: t('view'),
        action: () => {
          const to = isCL ? `${pathname}/chain-link/${recordObj?.chainLinkId}` : `${pathname}/${recordObj?.spId}`

          return navigate(to)
        },
      },
      {
        text: t('Edit'),
        action: () => handleToggleDrawer(isCL ? 'editCL' : 'editSP', true, recordObj)(),
      },
      {
        text: t('canceled'),
        action: () => handleCancel(isCL ? recordObj?.chainLinkId : recordObj?.spId),
      },
    ]
  }

  const handleSPSubmit = (data) => {
    dispatch(postSP().create(data)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        handleToggleDrawer('createSP', false)()

        fetchSPs()
        return
      }
    })
  }

  const handleCLSubmit = (data) => {
    dispatch(postCL().create(data)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        handleToggleDrawer('createCL', false)()

        fetchChainLinks()
        return
      }
    })
  }

  const handleEditSPSubmit = (data) => {
    const { id: spId } = data

    dispatch(putSP(spId, data)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        fetchSPs()
        return
      }
    })
  }

  const handleEditCLSubmit = (data) => {
    const { id: clId } = data

    dispatch(putCL(clId, data)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        fetchChainLinks()
        return
      }
    })
  }

  const handleSubTabChange = (tabValue) => {
    setTableType(tabValue)
    refSearchForm.current.reset()

    switch (tabValue) {
      case TABS.CL:
        fetchChainLinks() // fetchChainLinks({ filters })
        break

      case TABS.SOP:
        fetchSPs()
        break

      default:
        break
    }
  }

  const transformSPToTable = () => {
    if (!sps || sps.length === 0) return []

    const mapDataItem = (sp) => {
      const sp_temp_name = sp?.sp_temp?.name || 'N/A'
      const name = (
        <Link to={`${pathname}/${sp?.id}`}>
          <Typography variant="tableLink">{sp.name}</Typography>
        </Link>
      )
      const progress = (
        <Box sx={{ display: 'flex', minWidth: '200px', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <Progress variant="determinate" value={sp?.progress} height={7} />
          </Box>
        </Box>
      )
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={sp?.status}
          color={STATUS_CHIP[sp?.status]}
          sx={{ height: '32px' }}
        />
      )
      const createdAt = formatDate(sp?.createdAt)
      const start_date = formatDate(sp?.start_date)
      const due_date = formatDate(sp?.due_date)
      const actions = <CollapseMenu list={action_list({ spId: sp?.id }, TABS.SOP)} />

      return { ...sp, sp_temp_name, name, progress, status, createdAt, start_date, due_date, actions }
    }

    const mappedData = sps.map(mapDataItem)

    return mappedData
  }

  const transformCLToTable = () => {
    if (!chainLinks || chainLinks.length === 0) return []

    const mapDataItem = (cl) => {
      const chain_link_temp = cl?.chain_link_temp?.name || 'N/A'
      const code = (
        <Link to={`${pathname}/chain-link/${cl.id}`}>
          <Typography variant="tableLink">{cl.code}</Typography>
        </Link>
      )
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={cl.status}
          color={CHAIN_LINK.STATUS_CHIP[cl.status]}
          sx={{ height: '32px' }}
        />
      )
      const progress = (
        <Box sx={{ display: 'flex', minWidth: '200px', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <Progress variant="determinate" value={cl?.progress} height={7} />
          </Box>
        </Box>
      )
      const createdAt = formatDate(cl?.createdAt)
      const start_date = formatDate(cl?.start_date)
      const due_date = formatDate(cl?.due_date)
      const actions = <CollapseMenu list={action_list({ chainLinkId: cl?.id }, TABS.CL)} />

      return { ...cl, chain_link_temp, code, status, createdAt, start_date, due_date, actions, progress }
    }

    const mappedData = chainLinks.map(mapDataItem)

    return mappedData
  }

  const getTableProps = () => {
    let resultProps = {}

    switch (tableType) {
      case TABS.CL:
        resultProps = {
          data: transformCLToTable(chainLinks),
          page: chainLinks_pagination.page,
          pageCount: chainLinks_pagination.pageCount,
          onStateChange({ page }) {
            const pagination = { ...sps_pagination, page }
            fetchChainLinks({ pagination, filters })
          },
        }
        break

      case TABS.SOP:
        resultProps = {
          data: transformSPToTable(sps),
          page: sps_pagination.page,
          pageCount: sps_pagination.pageCount,
          onStateChange({ page }) {
            const pagination = { ...sps_pagination, page }
            fetchSPs({ pagination, filters })
          },
        }
        break

      default:
        break
    }

    return setTableProps(resultProps)
  }

  const handleFilters = (values) => {
    const keys = Object.keys(values)

    if (keys.length === 0) {
      setFilters({})
      return isCLTable ? fetchChainLinks() : fetchSPs()
    }

    let filters = {}
    keys.forEach((key) => {
      if (!values[key]) {
        return
      }

      switch (key) {
        case 'text_search':
          filters = { ...filters, $or: [{ name: { $contains: values[key] } }, { code: { $contains: values[key] } }] }
          break
        case 'status':
          filters = { ...filters, status: { $eq: values[key] } }
          break
        default:
          break
      }
    })

    if (Object.keys(filters).length === 0) {
      setFilters({})
      return isCLTable ? fetchChainLinks() : fetchSPs()
    }

    setFilters(filters)
    return isCLTable ? fetchChainLinks({ filters }) : fetchSPs({ filters })
  }

  useEffect(() => {
    fetchSPs()
    fetchChainLinks()
  }, [fetchSPs, fetchChainLinks])

  useEffect(() => {
    getTableProps()
  }, [sps, chainLinks, tableType])

  return (
    <Stack spacing={2}>
      <TableContainer key="Table_SP">
        <TableHeader>
          <Stack sx={{ width: '100%' }}>
            <Stack direction="row" sx={{ width: '100%', padding: '1rem 1.5rem', justifyContent: 'space-between' }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="tableTitle">{t('department_operation')}</Typography>
              </Stack>

              <Tabs tabType="modern">
                <Tabs.DepartmentOperation
                  tabCounts={{ [TABS.CL]: chainLinks_pagination?.total, [TABS.SOP]: sps_pagination?.total }}
                  onChange={handleSubTabChange}
                />
              </Tabs>
            </Stack>

            <Divider sx={{ borderColor: 'var(--Gray-200)' }} />

            <Stack
              direction="row"
              alignItems="center"
              padding="1rem 1.5rem"
              minHeight="4.875rem"
              flexWrap="wrap"
              columnGap="1rem"
              rowGap="0.5rem"
              justifyContent="space-between"
            >
              <SearchSPForm ref={refSearchForm} onFormChange={handleFilters} />

              <ButtonGroup>
                <Button
                  variant="contained"
                  startIcon={<RiAddLine />}
                  onClick={handleToggleDrawer(tableType === TABS.CL ? 'createCL' : 'createSP', true)}
                >
                  {tableType === TABS.CL ? t('create_new_chain_link') : t('create_new_sop')}
                </Button>
              </ButtonGroup>
            </Stack>
          </Stack>
        </TableHeader>

        <TableV3 {...tableProps} columns={tableColumns(tableType)} />
      </TableContainer>

      <React.Fragment key="Drawer_Components">
        <SidePanelV2
          titleText="Create SOP"
          open={drawer.createSP}
          onClose={() => {
            // dispatch(clearSPTemplate())
            handleToggleDrawer('createSP', false)()
          }}
          footerEl={
            <FooterDrawer>
              <Button className="gray" variant="outlined" onClick={handleToggleDrawer('createSP', false)}>
                {t('cancel')}
              </Button>
              <Button variant="contained" onClick={() => refSPCreate.current.submit()}>
                {t('create')}
              </Button>
            </FooterDrawer>
          }
        >
          <CreateSP ref={refSPCreate} onSubmit={handleSPSubmit} />
        </SidePanelV2>

        <SidePanelV2
          titleText="Edit SOP"
          supportingText={`SOP Code: ${sp?.code || '...'}`}
          open={drawer['editSP']}
          onClose={() => {
            dispatch(clearSP())
            handleToggleDrawer('editSP', false)()
          }}
          footerEl={
            (sp.status === SP.STATUS.InQueue || sp.status === SP.STATUS.Open) && (
              <FooterDrawer>
                <Button className="gray" variant="outlined" onClick={handleToggleDrawer('editSP', false)}>
                  {t('cancel')}
                </Button>
                <Button variant="contained" onClick={() => refSPEdit.current.submit()}>
                  {t('save')}
                </Button>
              </FooterDrawer>
            )
          }
        >
          <EditSP ref={refSPEdit} spId={drawer.spId} onSubmit={handleEditSPSubmit} />
        </SidePanelV2>

        <SidePanelV2
          titleText="Create Chain Link"
          open={drawer.createCL}
          onClose={handleToggleDrawer('createCL', false)}
          footerEl={
            <FooterDrawer>
              <Button className="gray" variant="outlined" onClick={handleToggleDrawer('createCL', false)}>
                {t('cancel')}
              </Button>
              <Button variant="contained" onClick={() => refCreateCL.current.submit()}>
                {t('create')}
              </Button>
            </FooterDrawer>
          }
        >
          <CreateCL ref={refCreateCL} onSubmit={handleCLSubmit} />
        </SidePanelV2>

        <SidePanelV2
          titleText="Edit Chain Link"
          supportingText={`Chain Link Code: ${chainLink?.code || '...'}`}
          open={drawer.editCL}
          onClose={() => {
            dispatch(clearCL())
            handleToggleDrawer('editCL', false)()
          }}
          footerEl={
            (chainLink.status === CHAIN_LINK.STATUS.InQueue || chainLink.status === CHAIN_LINK.STATUS.Open) && (
              <FooterDrawer>
                <Button className="gray" variant="outlined" onClick={handleToggleDrawer('editCL', false)}>
                  {t('cancel')}
                </Button>
                <Button variant="contained" onClick={() => refEditCL.current.submit()}>
                  {t('save')}
                </Button>
              </FooterDrawer>
            )
          }
        >
          <EditCL ref={refEditCL} chainLinkId={drawer.chainLinkId} onSubmit={handleEditCLSubmit} />
        </SidePanelV2>
      </React.Fragment>
    </Stack>
  )
}

const ButtonGroup = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignSelf: 'center',
  gap: '0.5rem',
})

const TableContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const TableHeader = styled('div')({
  display: 'flex',
  width: '100%',
  border: '1px solid var(--Gray-200)',
  borderBottom: 'none',
  borderTopLeftRadius: '.5rem' /* 8px */,
  borderTopRightRadius: '.5rem' /* 8px */,
})

const FooterDrawer = styled('footer')({
  display: 'flex',
  width: '100%',
  gap: '1rem',
  justifyContent: 'flex-end',
  padding: '1rem',
})
