import React from 'react'
import PropTypes from 'prop-types'

import { DateTimeHHmmssDisplay, numberWithCommas } from '../../../utils/common'

import styled from 'styled-components'

import { PiWarningCircleBold } from 'react-icons/pi'

const Div = styled.div`
  background-color: var(--Gray-50);
  padding: 12px 32px;

  .mr-4-px {
    margin-right: 4px;
  }
  .mb-16-px {
    margin-bottom: 16px;
  }

  .highlight-text {
    color: var(--Primary-600);
  }

  .table-container {
    width: 100%;
    overflow-x: auto;

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: separate;
      border-spacing: 0;
    }

    thead {
      .table-column {
        color: var(--Gray-600);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;

        text-align: left;

        .icon {
          color: var(--Gray-400);
        }
      }

      th {
        padding: 12px 0;
        border-bottom: 1px solid var(--Gray-200);
      }
    }

    tbody {
      td {
        padding: 16px 0;
        border-bottom: 1px solid var(--Gray-200);
      }
    }
  }
`

const column = [
  {
    header: 'KPIs',
    key: 'kpi',
  },
  {
    header: 'Description',
    key: 'description',
  },
  {
    header: 'Target Value',
    key: 'target_value',
  },
  {
    header: 'Unit',
    key: 'unit',
  },
  {
    header: 'Start Date',
    key: 'start_date',
  },
  {
    header: 'End Date',
    key: 'end_date',
  },
]
function KPITableData({ data, hasHeading }) {
  return (
    <Div>
      {hasHeading && <h3 className="highlight-text">แผนก : {hasHeading}</h3>}
      <div className="table-container">
        <table>
          <thead>
            <tr>
              {column.map((v) => (
                <th className="table-column" key={v.key}>
                  <span className="mr-4-px">{v.header}</span>
                  <PiWarningCircleBold className="icon" />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((v, i) => (
              <tr key={i}>
                <td>{v.kpi_name}</td>
                <td>{v.description}</td>
                <td>{numberWithCommas(v.target_value)}</td>
                <td>{v.unit}</td>
                <td>{DateTimeHHmmssDisplay(v.start_date)}</td>
                <td>{DateTimeHHmmssDisplay(v.end_date)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Div>
  )
}

KPITableData.propTypes = {
  data: PropTypes.array,
  hasHeading: PropTypes.string,
}

export default KPITableData
