import React from 'react'

import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import iconDropdown from '../../assets/images/icon-dropdown.svg'

const StyledPaperComponent = styled.div`
  .MuiAutocomplete-noOptions {
    padding: 0;
  }

  ul {
    padding: 0;
    margin: 0;
    font-family: inherit;
    font-size: 14px;
    list-style: none;
    background: var(--Base-White);
    border: 1px solid var(--Gray-300);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    max-height: 300px;
    overflow: auto;
    cursor: pointer;
  }

  li {
    padding: 8px 18px !important;
    color: var(--Gray-500);
    background: none !important;

    &:hover {
      background: var(--Gray-100) !important;
    }

    &.MuiAutocomplete-option[aria-selected='true'] {
      color: var(--Primary-700);
      font-weight: 700;
    }
  }
`

const StyledAutocomplete = styled(Autocomplete)`
  .MuiInputBase-root {
    display: flex;
    align-items: center;
    padding: 10px 18px !important;

    width: 100%;
    height: 40px;

    font-family: inherit;
    font-size: 14px;
    line-height: 1.5;
    color: var(--Gray-500);
    border: 1px solid ${({ $error }) => ($error ? 'red' : 'var(--Gray-300)')};
    background: var(--Base-White);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    cursor: pointer;

    .MuiInputBase-input {
      flex: 1;
      padding: 0 !important;
    }

    .MuiAutocomplete-endAdornment {
      position: relative;
      height: 8px;
      margin-left: 12px;
      right: 0 !important;
      top: 0;

      button {
        display: block;
        width: 12px;
        height: 8px;
        margin: 0;
        padding: 0 !important;
        background: url(${iconDropdown}) no-repeat center center;
        transform: none !important;
      }

      svg,
      .MuiTouchRipple-root {
        display: none;
      }
    }
  }

  .MuiButtonBase-root,
  fieldset {
    display: none;
  }

  .MuiFormLabel-root {
    font-size: 14px;
    font-family: inherit;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    position: absolute;
    left: 0;
    top: -5px;
    :active {
      color: #ffffff;
    }
  }
`

function AutoCompleteCustomText({
  optionList = [],
  value,
  error,
  onChange,
  ValueplaceHolderIsNull = null,
  placeHolder = 'Please select',
  getOptionLabel,
  renderOption,
  ...props
}) {
  const handleChange = (event, newValue) => {
    onChange(newValue)
  }

  const isOptionEqualToValue = (option, value) => {
    return option.value === value.value
  }

  return (
    <StyledAutocomplete
      options={optionList}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => <TextField {...params} placeholder={placeHolder} />}
      renderOption={renderOption}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={handleChange}
      value={value}
      noOptionsText={
        <ul>
          <li>No option</li>
        </ul>
      }
      disableClearable
      PaperComponent={StyledPaperComponent}
      $error={error}
      {...props}
    />
  )
}

export default AutoCompleteCustomText
