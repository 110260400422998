import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Option, Select } from 'components/form2/form-select'
import { ChangeCompany } from 'redux/actions/admin'
import { getAllLocalAdmin, getCompanyInfo } from 'utils/common'

const ToggleCompany = ({ onChange }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id: companyId } = getCompanyInfo() || {}
  const { company_info } = getAllLocalAdmin() || {}
  const { company_list } = company_info || {}
  const companyOptions = company_list.map((company) => ({ value: company.id, label: company.company_name_TH }))
  const initCompany = companyOptions.find((company) => company.value === companyId)
  const [company, setCompany] = useState(initCompany?.value || undefined)

  const handleChangeCompany = (_e, selectValue) => {
    setCompany(selectValue)
    dispatch(ChangeCompany(selectValue)).then(({ error }) => {
      if (!error) {
        // getPermissions() => will be re-load menu items.
        onChange && onChange(selectValue)
        navigate('/')
      }
    })
  }

  return (
    <Select defaultValue={initCompany?.value} value={company?.value} onChange={handleChangeCompany}>
      {companyOptions.map((comp) => (
        <Option key={comp.value} value={comp.value}>
          {comp.label}
        </Option>
      ))}
    </Select>
  )
}

export default ToggleCompany
