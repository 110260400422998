import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { DateTimeHHmmDisplay } from '../../utils/common'
import { useTranslation } from 'react-i18next'

import { GetPermissionOther } from '../../utils/common'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import SearchText from '../../components/form/SearchText'
import TextField from '../../components/form/TextField'
import Table from '../../components/common/Table'
import SidePanel from '../../components/common/SidePanel'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import Checkbox from '../../components/form/Checkbox'
import OutlineCheckbox from '../../components/form/OutlineCheckbox'
import Dropdown from '../../components/form/Dropdown'
import CollapseMenu from '../../components/common/CollapseMenu'
import DialogConfirm from '../../components/dialog/DialogConfirm'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import iconPlus from '../../assets/images/icon-plus.svg'
import { LiaTimesSolid } from 'react-icons/lia'
import { GrStatusGoodSmall } from 'react-icons/gr'
import userIcon from '../../assets/images/user-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import deleteIcon from '../../assets/images/delete-icon.png'

import NewRole from '../../pages/access-role/NewRole'
import EditRole from '../../pages/access-role/EditRole'
import ViewRole from '../../pages/access-role/ViewRole'

import {
  getAccessRoles,
  getPermissionActions,
  getPermissionList,
  createRole,
  getAccessRoleById,
  updateRole,
  deleteRole,
} from '../../redux/actions/accessRole'
import { selectAccessRole } from '../../redux/selectors'

const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .align-items-center {
    align-items: center;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-1rem {
    font-size: 1rem;
  }
  .text-1_5rem {
    font-size: 1.5rem;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .content-header {
    margin-top: 24px;
  }

  .search-container {
    display: grid;
    grid-template-columns: repeat(3, 15rem);
    grid-template-rows: auto;
    align-content: center;
    column-gap: 1rem;
    margin-bottom: 1rem;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);
      border: 1px solid var(--Gray-200);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 10rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error-input {
    border: 1px solid red;
  }

  .error {
    color: red;
  }

  .side-panel-container {
    padding: 2rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .side-panel-container-2 {
    padding: 2rem;
    height: 100vh;
  }

  .create-access-role-header {
    font-size: 25px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .input-header {
    color: var(--gray-700);
    font-size: 14px;
    font-weight: 500;
  }

  .permission-header {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-items: center; */
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--Primary-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .permission-content {
    /* display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-items: center; */
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .text-underline {
    text-decoration: underline;
  }
  .text-color-primary {
    color: #7f56d9;
  }
  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-items: center;

    border-radius: 8px;
    border: 1px solid var(--gray-300, #d0d5dd);

    .tab-menu {
      padding: 10px;
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      text-align: center;
      font-weight: 600;

      color: var(--Tab-Font-Default);

      &.active-1 {
        background: var(--Tab-Bg-Current);
        color: var(--Tab-Font-Current);
        border-radius: 8px 0 0 8px;
      }
      &.active-2 {
        background: var(--Tab-Bg-Current);
        color: var(--Tab-Font-Current);
        font-weight: bold;
        border-radius: 0;
      }
      &.active-3 {
        background: var(--Tab-Bg-Current);
        color: var(--Tab-Font-Current);
        font-weight: bold;
        border-radius: 0 8px 8px 0;
      }
    }
  }
  .tab-content {
    overflow: auto;
    .view-information-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }
  }
  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border: 1px solid var(--Status-Icon-Stroke);
    color: var(--Status-Icon-Font);
    background: var(--Status-Icon-Bg);
    border-radius: 6px;
    font-weight: 500;

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: var(--Status-Active);

      &.inactive {
        color: var(--Status-Inactive);
      }

      &.draft {
        color: var(--Status-Draft);
      }
    }
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--gray-200, #eaecf0);
  }
`

const initPermissionForm = {
  name: '',
  description: '',
}

const initValidateForm = {
  name: null,
  description: null,
}

const initStatusDropdown = [
  {
    value: 'active',
    text: 'Active',
  },
  {
    value: 'inactive',
    text: 'Inactive',
  },
]

const initMessageModal = {
  headline: '',
  message: '',
}

const AccessRole = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const initLang = localStorage.getItem('lang')
  //--- ST ส่วนของ Permission LV3-4
  const permissionCreateNewRole = GetPermissionOther('access_role_management','AccessRoleManagement_CreateNewRole');
  const permissionViewRole = GetPermissionOther('access_role_management','AccessRoleManagement_ViewRole');
  const permissionEditRole = GetPermissionOther('access_role_management','AccessRoleManagement_EditRole');
  const permissionDeleteRole = GetPermissionOther('access_role_management','AccessRoleManagement_DeleteRole');
  
  //--- ST ส่วนของ Permission LV3-4

  const { accessRoleInfo, accessRoles, permissionActions, permissionList, total, skip, limit, isLoading, state } =
    useSelector(selectAccessRole)
  // data
  const [accessRoleList, setAccessRoleList] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [totalPagesUserActive, setTotalPagesUserActive] = useState(0)
  const [page, setPage] = useState(1)
  const [pageUserActive, setPageUserActive] = useState(1)
  const [accessRoleId, setAccessRoleId] = useState('')
  const [tabViewMenu, setTabViewMenu] = useState('information')
  const [permissions, setPermissions] = useState([])
  const [permissionForm, setPermissionForm] = useState(initPermissionForm)
  const [permissionEditForm, setPermissionEditForm] = useState(initPermissionForm)
  const [permissionFormCheckbox, setPermissionFormCheckbox] = useState([])
  const [initPermissionFormCheckbox, setInitPermissionFormCheckbox] = useState([])
  const [userActiveList, setUserActiveList] = useState([])
  const [userActiveListDisplay, setUserActiveListDisplay] = useState([])
  const [nameItem, setNameItem] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  const [isCheckedAll, setIsCheckedAll] = useState({})
  // search
  const [search, setSearch] = useState('')
  const [departmentSelected, setDepartmentSelected] = useState('')
  const [positionSelected, setPositionSelected] = useState('')
  // validate
  const [errorsAccessRole, setErrorsAccessRole] = useState(initValidateForm)
  // side panel
  const [isOpenSidePanelCreate, setIsOpenSidePanelCreate] = useState(false)
  const [isOpenSidePanelEdit, setIsOpenSidePanelEdit] = useState(false)
  const [isOpenSidePanelView, setIsOpenSidePanelView] = useState(false)
  // modal
  const [confirmCreateModal, setConfirmCreateModal] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [successDeleteModal, setSuccessDeleteModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  // modal message
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const accessRoleColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('role_name'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('description'),
      accessor: 'description',
      disableSortBy: false,
    },
    {
      Header: t('active_user'),
      accessor: 'userActive',
      disableSortBy: false,
    },
    {
      Header: t('last_update'),
      accessor: 'lastUpdate',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
    },
    {
      Header: t('action'),
      accessor: 'action',
      disableSortBy: false,
    },
  ]

  const userActiveColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('employee_no'),
      accessor: 'employee_no',
      disableSortBy: false,
    },
    {
      Header: t('first_name'),
      accessor: 'first_name',
      disableSortBy: false,
    },
    {
      Header: t('last_name'),
      accessor: 'last_name',
      disableSortBy: false,
    },
    {
      Header: t('email'),
      accessor: 'email',
      disableSortBy: false,
    },
    {
      Header: t('mobile_phone'),
      accessor: 'phone_no',
    },
    {
      Header: t('department'),
      accessor: 'department',
      disableSortBy: false,
    },
    {
      Header: t('role'),
      accessor: 'access_role',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
  ]

  const fetchData = useCallback(
    async (filter) => {
      dispatch(getAccessRoles(filter))
    },
    [dispatch]
  )

  const viewAccessRole = useCallback(
    async (id) => {
      if(permissionViewRole?.canUpdate === true){
        setIsOpenSidePanelView(true)
        //setTabViewMenu('information')
        //dispatch(getAccessRoleById(id))
        setAccessRoleId(id)
      }else{
        let message_permission = 'No permission allowed ' + permissionViewRole?.name_en ;
        if(initLang == 'th'){
          message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionViewRole?.name_th;
        }
        setFailMessageModal({
          headline: message_permission,
          message: '',
        })
        setFailModal(true)
      }
    },
    [dispatch]
  ) 

  const editAccessRole = useCallback(
    (id) => {
      //dispatch(getAccessRoleById(id))

      if(permissionEditRole?.canUpdate === true){
        setIsOpenSidePanelEdit(true)
        setAccessRoleId(id)
      }else{
        let message_permission = 'No permission allowed ' + permissionEditRole?.name_en ;
        if(initLang == 'th'){
          message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionEditRole?.name_th;
        }
        setFailMessageModal({
          headline: message_permission,
          message: '',
        })
        setFailModal(true)
      }
    },
    [dispatch]
  )

  const AccessRoleEdit = (id) => {
    alert(id)
  }

  const deleteAccessRole = useCallback((id, name) => {

    if(permissionDeleteRole?.canUpdate === true){
      setAccessRoleId(id)
      setNameItem(name)
      setConfirmDeleteModal(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionDeleteRole?.name_en ;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionDeleteRole?.name_th;
      }
      setFailMessageModal({
        headline: message_permission,
        message: '',
      })
      setFailModal(true)
    }
  }, [])

  useEffect(() => {
    if (accessRoleInfo?.permissions?.length > 0) {
      const initPermissionCheckbox = [...initPermissionFormCheckbox]
      let permissionsSelected = []
      for (let permission of accessRoleInfo.permissions) {
        Object.entries(permission).forEach(([key, data]) => {
          const groupKeyPermission = data.flat().map((v) => {
            const { permission_action, subject } = v
            const permissionSelected = permission_action.map((val) => ({
              id: val?.id,
              subject: subject,
              action_permission: val.action_permission,
            }))

            return permissionSelected
          })

          const flattenedArray = groupKeyPermission.reduce((acc, curr) => {
            return [...acc, ...curr]
          }, [])

          permissionsSelected.push(...flattenedArray)
        })
      }

      const newPermissionSelected = initPermissionCheckbox.map((v) => {
        return {
          ...v,
          checked: permissionsSelected.some(
            (it) => it.subject == v.subject && it.action_permission == v.action_permission
          ),
        }
      })
      setPermissionFormCheckbox(newPermissionSelected)
    }

    if (accessRoleInfo?.users?.length > 0) {
      const usersActive = accessRoleInfo?.users.map((v, i) => ({
        ...v,
        no: i + 1,
      }))
      setUserActiveList(usersActive)

      const userActiveDisplay = [...usersActive].slice(0, 10)
      setUserActiveListDisplay(userActiveDisplay)
    }

    setPermissionEditForm((prev) => ({
      ...prev,
      name: accessRoleInfo?.name,
      description: accessRoleInfo?.description,
      status: accessRoleInfo?.status,
    }))
  }, [accessRoleInfo, initPermissionFormCheckbox])

  useEffect(() => {
    let newPermissions = []
    let newIsCheckedAll = []
    for (let permission of permissionList) {
      Object.entries(permission).forEach(([key, data]) => {
        newPermissions.push({
          key,
          data,
        })
        newIsCheckedAll.push({
          [key]: {
            isCheckedAll: false,
          },
        })
      })
    }

    setPermissions(newPermissions)
    if (newIsCheckedAll.length > 0) {
      const finalIsCheckedAll = newIsCheckedAll.reduce((acc, cur) => {
        const key = Object.keys(cur)[0]
        acc[key] = cur[key]
        return acc
      })
      setIsCheckedAll(finalIsCheckedAll)
    }
  }, [permissionList])

  useEffect(() => {
    const copiedIsCheckedAll = { ...isCheckedAll }
    for (let key in copiedIsCheckedAll) {
      const isCheckAll = permissionFormCheckbox.filter((v) => v.key === key).every((v) => v.checked)
      copiedIsCheckedAll[key].isCheckedAll = isCheckAll
    }

    setIsCheckedAll(copiedIsCheckedAll)
  }, [permissionFormCheckbox])

  useEffect(() => {
    let newPermissionForm = []
    for (let permission of permissions) {
      const { key, data } = permission
      const newPermissionForms = data.map((v) => {
        const permissionFromMap = permissionActions
          .filter((v) => v.action_name === 'View')
          .map((v2) => ({
            id: v.id,
            key,
            subject: v.subject,
            action_permission: v2.action_permission,
            action_name: v2.action_name,
          }))

        return permissionFromMap
      })
      newPermissionForm.push(...newPermissionForms)
    }

    const newData = newPermissionForm.flat().map((v) => ({ ...v, checked: false }))
    setPermissionFormCheckbox(newData)
    setInitPermissionFormCheckbox(newData)
  }, [permissions, permissionActions])

  useEffect(() => {
    dispatch(getAccessRoles())
    //dispatch(getPermissionActions())
    //dispatch(getPermissionList())
  }, [dispatch])

  useEffect(() => {
    const newAccessRole = accessRoles.map((v, i) => {
      /*
      const list = [
        {
          text: t('edit'),
          action: () => editAccessRole(v.id),
        },
        {
          text: t('delete'),
          action: () => deleteAccessRole(v.id, v.name),
        },
      ]
      */
      let listpermission = [];
      if(permissionEditRole?.canRead === true){
        listpermission = [
          ...listpermission , 
          {
            text: t('edit'),
            action: () => editAccessRole(v.id),
          }
        ]
      }
      if(permissionDeleteRole?.canRead === true){
        listpermission = [
          ...listpermission , 
          {
            text: t('delete'),
            action: () => deleteAccessRole(v.id, v.name),
          }
        ]
      }
      return {
        ...v,
        name: (
          permissionViewRole?.canRead === true ? <div className="text-bold link" onClick={() => viewAccessRole(v.id)}>
            {v.name}
          </div> : <div className="text-bold">
            {v.name}
          </div>
        ),
        lastUpdate: DateTimeHHmmDisplay(v.updatedAt),
        userActive : v.userActive ? v.userActive : 0,
        /*
        status: (
          <div className="status-badge">
            <GrStatusGoodSmall className={`status ${v.status == 'inactive' ? 'inactive' : ''}`} />
            {v.status}
          </div>
        ),
        */
        status: (
          <div className="status-badge">
            <GrStatusGoodSmall className={`status`} />
            active
          </div>
        ),
        action: <CollapseMenu list={listpermission} />,
      }
    })
    setAccessRoleList(newAccessRole)
  }, [accessRoles, viewAccessRole, editAccessRole, deleteAccessRole])

  useEffect(() => {
    const totalPage = Math.ceil(total / limit)
    setTotalPages(totalPage)
  }, [total, limit])

  useEffect(() => {
    const totalPage = Math.ceil(userActiveList.length / limit)
    setTotalPagesUserActive(totalPage)
  }, [userActiveList, limit])

  useEffect(() => {
    // success
    /*
    if (state == 'CREATE_ROLE.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: nameItem,
        message: t('access_role_create_success'),
      })
      setSuccessModal(true)
    }
    */
    /*
    if (state == 'UPDATE_ROLE.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: nameItem,
        message: t('access_role_update_success'),
      })
      setSuccessModal(true)
    }
    */
    if (state == 'DELETE_ROLE.SUCCESS' && isLoading == false) {
      setSuccessDeleteModal(true)
    }
    // fail
    /*
    if (state == 'CREATE_ROLE.FAILURE' && isLoading == false) {
      setFailMessageModal({
        headline: t('cannot_create_headline'),
        message: t('cannot_create_desc'),
      })
      setFailModal(true)
    }
    */
   /*
    if (state == 'UPDATE_ROLE.FAILURE' && isLoading == false) {
      setFailMessageModal({
        headline: t('update_access_role_failed_headline'),
        message: t('update_access_role_failed_desc'),
      })
      setFailModal(true)
    }
    */
    if (state == 'DELETE_ROLE.FAILURE' && isLoading == false) {
      setFailMessageModal({
        headline: t('cannot_delete_headline'),
        message: t('cannot_delete_desc'),
      })
      setFailModal(true)
    }
  }, [state, isLoading])

  // onSubmit
  const onSubmitCreateAccessRole = async () => {
    const request = {
      data: {
        ...permissionForm,
        permissions: permissionFormCheckbox
          .filter((v) => v.checked == true)
          .map((v) => ({
            action: v.action_permission,
            subject: v.subject,
          })),
      },
    }
    setNameItem(permissionForm.name)
    dispatch(createRole(request))
    setIsOpenSidePanelCreate(false)
    setConfirmCreateModal(false)
    clearForm()
  }

  const onSubmitEditAccessRole = () => {
    const request = {
      data: {
        ...permissionEditForm,
        permissions: permissionFormCheckbox
          .filter((v) => v.checked == true)
          .map((v) => ({
            action: v.action_permission,
            subject: v.subject,
          })),
      },
    }
    setNameItem(permissionEditForm.name)
    dispatch(updateRole(accessRoleId, request))
    setTabViewMenu('information')
    setIsOpenSidePanelEdit(false)
    setConfirmUpdateModal(false)
    clearForm()
  }

  const confirmDelete = async () => {
    dispatch(deleteRole(accessRoleId))
    setConfirmDeleteModal(false)
    setPage(1)
  }

  // request
  const onSearch = () => {
    if (searchFilters.length < 10) {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const filter = {
        skip: 0,
        limit: limit,
        name: [...searchFilters, newFilter].map((v) => v.name).join(','),
      }
      fetchData(filter)
      setSearch('')
      setPage(1)
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }

  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)

    const filter = {
      skip: 0,
      limit: limit,
      name: newSearchFilters.map((v) => v.name).join(','),
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
  }

  const onClearSearch = () => {
    const filter = {
      skip: 0,
      limit: limit,
    }
    fetchData(filter)
    setSearch('')
    setDepartmentSelected('')
    setPositionSelected('')
    setPage(1)
  }

  // modal
  const openConfirmCreate = () => {
    const isValid = validateForm()
    if (isValid) {
      setConfirmCreateModal(true)
    }
  }

  const openConfirmEdit = () => {
    const isValid = validateFormEdit()
    if (isValid) {
      setConfirmUpdateModal(true)
    }
  }

  const confirmSuccess = async () => {
    setSuccessModal(false)
  }

  const confirmSuccessDelete = async () => {
    setSuccessDeleteModal(false)
  }

  const confirmFailModal = async () => {
    setFailModal(false)
  }

  const closeCreateRolePanel = () => {
    setIsOpenSidePanelCreate(false)
    clearForm()
  }

  const closeEditRolePanel = () => {
    setIsOpenSidePanelEdit(false)
    clearForm()
  }

  const closeViewRolePanel = () => {
    setPageUserActive(1)
    setIsOpenSidePanelView(false)
    clearForm()
  }

  const [WidthSidePanel, setWidthSidePanel] = useState(window.innerWidth * 0.7) //--- 70% ของจอ

  const createAccessRole = () => {
    if(permissionCreateNewRole?.canUpdate === true){
      clearForm()
      setIsOpenSidePanelCreate(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionCreateNewRole?.name_en ;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionCreateNewRole?.name_th;
      }
      setFailMessageModal({
        headline: message_permission,
        message: '',
      })
      setFailModal(true)
    }
    
  }

  const CloseModalCreateRole = () => {
    setIsOpenSidePanelCreate(false)
  }

  const SubmitModalCreateRole = () => {
    setIsOpenSidePanelCreate(false)
  }

  const SubmitModalEditRole = () => {
    setAccessRoleId('')
    setIsOpenSidePanelEdit(false)
  }

  const CloseModalEditRole = () => {
    setIsOpenSidePanelEdit(false)
  }

  const SubmitModalViewRole = () => {
    setAccessRoleId('')
    setIsOpenSidePanelView(false)
  }

  const CloseModalViewRole = () => {
    setIsOpenSidePanelView(false)
  }

  // onChange
  const onChangePermissionChecked = (value, id, subject, action) => {
    const newPermissionForm = [...permissionFormCheckbox]
    const index = newPermissionForm.findIndex(
      (v) => v.id == id && v.subject == subject && v.action_permission == action
    )

    newPermissionForm[index].checked = value
    setPermissionFormCheckbox(newPermissionForm)
  }

  const onChangePermissionForm = (event) => {
    const { name, value } = event.target
    setPermissionForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangePermissionEditForm = (event) => {
    const { name, value } = event.target
    setPermissionEditForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeDropdownPermissionEditForm = (name, value) => {
    setPermissionEditForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangePagination = async (value) => {
    const filter = {
      skip: value.page * limit - limit,
      limit: limit,
      name: searchFilters.map((v) => v.name).join(','),
    }
    fetchData(filter)
    setPage(value.page)
  }

  const onChangePaginationUserActive = async (value) => {
    let skip = (value.page - 1) * limit
    const userActiveDisplay = userActiveList.slice(skip, skip + limit)
    setUserActiveListDisplay(userActiveDisplay)
    setPageUserActive(value.page)
  }

  const onChangeCheckbox = (key, event) => {
    const { checked } = event.target
    const copiedPermissionFormCheckbox = [...permissionFormCheckbox]
    const newPermissionForm = copiedPermissionFormCheckbox.map((v) => ({
      ...v,
      checked: v.key === key ? checked : v.checked,
    }))
    setPermissionFormCheckbox(newPermissionForm)
  }

  // optional
  const clearForm = () => {
    setPermissionForm(initPermissionForm)
    setPermissionEditForm(initPermissionForm)
    const initPermission = initPermissionFormCheckbox.map((v) => ({ ...v, checked: false }))
    setPermissionFormCheckbox(initPermission)
    setErrorsAccessRole(initValidateForm)
  }

  const validateForm = () => {
    let newErrors = {
      ...initValidateForm,
    }

    if (!permissionForm.name) {
      newErrors.name = 'Access Role Name is required'
    }

    setErrorsAccessRole(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  const validateFormEdit = () => {
    let newErrors = {
      ...initValidateForm,
    }

    if (!permissionEditForm.name) {
      newErrors.name = 'Access Role Name is required'
    }

    setErrorsAccessRole(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  return (
    <Div>
      <header>
        <div className="content-title">{t('access_role_management')}</div>
        <div className="">{t('access_role_management_sub_headline')}</div>
        <div className="content-header">
          <div className="search-container">
            <SearchText
              placeholder={t('search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSubmit={onSearch}
              onClear={onClearSearch}
            />
          </div>
          <div className="badge-search-content">
            {searchFilters.map((v) => (
              <div className="badge" key={v.key}>
                <span className="name">{v.name}</span>{' '}
                <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
              </div>
            ))}
          </div>
        </div>
      </header>
      <div className="table-wrapper">
        <div className="table-header">
          <div className="group">
            <div className="table-title">{t('access_role')}</div>
            <div className="table-total">
              {total} {t('roles')}
            </div>
          </div>

          <div>
            
            {permissionCreateNewRole?.canRead === true && (
              <Button onClick={createAccessRole} append={<img src={iconPlus} />}>
                {t('create_new_role')}
              </Button>
            )}
          </div>
        </div>
        <Table
          columns={accessRoleColumn}
          data={accessRoleList}
          onStateChange={onChangePagination}
          pageCount={totalPages}
          page={page}
          // loading={isLoading}
        />
      </div>

      {/* Modal */}
      <DialogConfirm
        open={Boolean(confirmCreateModal)}
        onClose={() => setConfirmCreateModal(false)}
        onSubmit={onSubmitCreateAccessRole}
        icon={userIcon}
        title={t('confirm_create_role_headline')}
        description={permissionForm.name}
        textYes={t('confirm')}
        textNo={t('back')}
      />
      <DialogConfirm
        open={Boolean(confirmUpdateModal)}
        onClose={() => setConfirmUpdateModal(false)}
        onSubmit={onSubmitEditAccessRole}
        icon={userIcon}
        title={t('confirm_update_role_headline')}
        description={permissionEditForm.name}
        textYes={t('confirm')}
        textNo={t('back')}
      />
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={confirmDelete}
        title={t('delete_access_role')}
        nameItem={nameItem}
        description={t('delete_access_role_desc')}
      />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogSuccess
        open={Boolean(successDeleteModal)}
        onClose={() => setSuccessDeleteModal(false)}
        onSubmit={confirmSuccessDelete}
        icon={deleteIcon}
        title={t('access_role_delete_success')}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={failMessageModal.headline}
        nameItem={nameItem}
        description={failMessageModal.message}
        textYes={t('ok')}
      />

      {/* Create Role Panel */}
      <SidePanel isOpen={isOpenSidePanelCreate} setIsOpen={setIsOpenSidePanelCreate} width={WidthSidePanel}>
          <NewRole width={WidthSidePanel} onClose={CloseModalCreateRole} onSubmit={SubmitModalCreateRole} />
      </SidePanel>

      <SidePanel isOpen={isOpenSidePanelEdit} setIsOpen={setIsOpenSidePanelEdit} width={WidthSidePanel}>
          <EditRole width={WidthSidePanel} onClose={CloseModalEditRole} onSubmit={SubmitModalEditRole} IdEdit={accessRoleId} />
      </SidePanel>
      
      <SidePanel isOpen={isOpenSidePanelView} setIsOpen={setIsOpenSidePanelView} width={WidthSidePanel}>
          <ViewRole width={WidthSidePanel} onClose={CloseModalViewRole} onSubmit={SubmitModalViewRole} IdEdit={accessRoleId} />
      </SidePanel>

    
    </Div>
  )
}

export default AccessRole
