import { useState } from 'react'
import styled from 'styled-components'
import MyTaskOverView from '../../components/feature/jobOrder/MyTaskOverView'
import Project from '../../components/feature/jobOrder/project/Project'
import DailySummary from './DailySummary'
import MileStone from './Milestone'

import { useTranslation } from 'react-i18next'

import { useEffect } from 'react'

const TaskProject = () => {
  //Filter selections
  const { t } = useTranslation()
  const initLang = localStorage.getItem('lang')

  const [onSelectItemTable, setOnSelectItemTable] = useState(false)
  const [milestoneId, setMilestoneId] = useState('')
  const [currentMenuTabSelected, setCurrentMenuTabSelected] = useState('Task_Project')
  const [menuTabList, setMenuTabList] = useState([])

  // Filters
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
      } else {
        tmpList[i].active_status = false
      }
    }
    setCurrentMenuTabSelected(data.value)
    setMenuTabList([...tmpList])
  }

  const handleClickTabItem = (id) => {
    setOnSelectItemTable(true)
    setCurrentMenuTabSelected('milestone')
    setMilestoneId(id)
  }
  const handleTitlePage = () => {
    for (let i = 0; i < menuTabList.length; i++) {
      if (menuTabList[i].active_status) {
        if (menuTabList[i].value == 'Task_MyTask') {
          return 'My Task & Document'
        } else {
          return menuTabList[i].name
        }
      }
    }
    return 'My Task & Document'
  }

  return (
    <Div>
      {!onSelectItemTable && (
        <header>
          <div className="content-title">Project</div>
          <div>{t('project_sub_header')}</div>
          <div className="date-filter-container mt-1-rem">
            <div className="wrp-tab-menu">
              {menuTabList?.map((item, index) => (
                <button
                  onClick={() => handleClickTaskMenuTab(item)}
                  key={'btn-' + index}
                  className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        </header>
      )}
      {currentMenuTabSelected == 'Task_MyTask' && <MyTaskOverView />}
      {currentMenuTabSelected == 'Task_Project' && <Project onClickTableItem={(e) => handleClickTabItem(e)} />}
      {currentMenuTabSelected == 'daily_summary_report' && <DailySummary />}
      {currentMenuTabSelected == 'milestone' && (
        <MileStone
          id={milestoneId}
          onClickBack={() => {
            setOnSelectItemTable(false)
            setCurrentMenuTabSelected('Task_Project')
          }}
        />
      )}
    </Div>
  )
}

export default TaskProject

const Div = styled.div`
  .w-100 {
    width: 100%;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mt-1-rem {
    margin-top: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .date-filter-container {
    width: 100%;
    /* display: flex;
    justify-content: space-between; */
  }

  .drop-down {
    min-width: 10rem;
  }

  .wrp-tab-menu {
    width: fit-content;
    display: flex;
    border-bottom: 1px solid var(--Gray-200);

    .btn-menu-style {
      border: none;
      border-bottom: 2px solid transparent;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      color: var(--Gray-500);
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: var(--Primary-50);
      border-bottom: 2px solid var(--Primary-700) !important;
      color: var(--Primary-700);
    }
  }
`
