import { Alert, AlertTitle } from '@mui/material'
import { styled } from '@mui/system'
import dayjs from 'dayjs'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Empty from 'components/common/Empty'
import Typography from 'components/common/Typography'
import { SWTimelinePreview } from 'components/feature/operation-process'
import DynamicForm from 'components/form2/dynamic-form'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { addMinutes } from 'utils/timeUtils'

const langLocale = 'th'

const Tabs = ({ onChange, items = [], defaultTab }) => {
  const [activeTab, setActiveTab] = useState(defaultTab)

  const tabItems = items

  useEffect(() => {
    onChange && onChange(activeTab)
  }, [activeTab])

  return (
    <TabsWrapper>
      {tabItems.map((tab, index) => (
        <div
          key={tab.id}
          className={`tab-item ${activeTab === tab.id ? 'active' : ''}`}
          onClick={() => setActiveTab(tab.id)}
        >
          {`(${index + 1}).`} {tab.label} {tab.count ? `(${tab.count})` : ''}
        </div>
      ))}
    </TabsWrapper>
  )
}

const CreateCL = forwardRef(function CreateCL(props, ref) {
  const { t } = useTranslation()
  const { onSubmit } = props

  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)
  const [currentChain, setCurrentChain] = useState(null)

  const { extra: extraActions } = opActions()
  const { getExtra } = extraActions()
  const { clTemplates, tags } = opProcSelector

  const defaultValues = {
    chain_link_temp: { id: null },
    name: '',
    description: '',
    tags: [],
    chains: [],
    working_times: [],
    start_date: dayjs().hour(8).minute(0).locale(langLocale).format(),
    start_time: dayjs().hour(8).minute(0).locale(langLocale).format(),
  }
  const methods = useForm({ defaultValues })

  const watchCLTempId = methods.watch('chain_link_temp.id')
  const watchChains = methods.watch('chains')
  const workingTimes = methods.watch('working_times')

  const fetchCLTemplates = useCallback(
    async (payload) => {
      dispatch(getExtra().clTemplates(payload))
    },
    [dispatch]
  )

  const fetchCLTemplateById = useCallback(
    async (clTempId) => {
      return dispatch(getExtra().clTemplateById(clTempId))
    },
    [dispatch]
  )

  const fetchTags = useCallback(
    async (filters) => {
      dispatch(getExtra().tags({ filters }))
    },
    [dispatch]
  )

  const handleTabChange = (tabId) => {
    setCurrentChain(tabId)
  }

  const calculateDueDate = (chains = [], start_date, working_times = workingTimes) => {
    let currentDate = dayjs(start_date).locale(langLocale).set('second', 0).set('millisecond', 0)

    if (chains.length > 0) {
      chains.forEach((chain, chainIndex) => {
        const milestones = chain?.sp?.milestones || []

        milestones.forEach((milestone, milestoneIndex) => {
          const sw_hour = milestone?.sw?.hr || 0
          const sw_minute = milestone?.sw?.min || 0
          const sw_estimated_time = sw_hour * 60 + sw_minute

          currentDate = addMinutes(currentDate, sw_estimated_time, working_times)

          milestone.sw.due_date = dayjs(currentDate).locale(langLocale).set({ second: 0, millisecond: 0 }).format()

          const logData = [
            {
              type: 'SW',
              hours: sw_hour,
              minutes: sw_minute,
              due_date: dayjs(milestone.sw.due_date).format('MMM DD HH:mm'),
            },
          ]

          if (milestone?.reviewers && Array.isArray(milestone.reviewers) && milestone.reviewers.length > 0) {
            milestone.reviewers.forEach((reviewer, reviewerIndex) => {
              const reviewer_hour = reviewer?.hr || 0
              const reviewer_minute = reviewer?.min || 0
              const reviewer_estimated_time = reviewer_hour * 60 + reviewer_minute

              currentDate = addMinutes(currentDate, reviewer_estimated_time, working_times)

              reviewer.due_date = dayjs(currentDate).locale(langLocale).format()

              logData.push({
                type: `Reviewer [${reviewerIndex}]`,
                hours: reviewer_hour,
                minutes: reviewer_minute,
                due_date: dayjs(reviewer.due_date).format('MMM DD HH:mm'),
              })
            })
          }

          if (milestone?.approver && milestone.approver !== null) {
            const approver_hour = milestone.approver?.hr || 0
            const approver_minute = milestone.approver?.min || 0
            const approver_estimated_time = approver_hour * 60 + approver_minute

            currentDate = addMinutes(currentDate, approver_estimated_time, working_times)

            milestone.approver.due_date = dayjs(currentDate).locale(langLocale).format()

            logData.push({
              type: 'Approver',
              hours: approver_hour,
              minutes: approver_minute,
              due_date: dayjs(milestone.approver.due_date).format('MMM DD HH:mm'),
            })
          }

          // console.table(logData)
        })
      })
      return chains
    }
    return []
  }

  useEffect(() => {
    fetchCLTemplates()
    fetchTags()
  }, [dispatch])

  const formSchema = {
    properties: {
      'section-1': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('detail')}</Typography>
            <Typography variant="sectionText">{t('detail_chain_link_desc')}</Typography>
          </HeaderSection>
        ),
      },
      'divider-1': { type: 'divider' },
      ['chain_link_temp.id']: {
        type: 'select',
        options: clTemplates.map((clTemp) => ({ value: clTemp.id, label: clTemp.name })),
        label: t('chain_link_template'),
        placeholder: t('select_chain_link_template'),
        onchange: async (e, selectVal) => {
          const clTempId = e.target.value || selectVal
          const startDate = methods.getValues('start_date') || dayjs().format()
          const clTemp = await fetchCLTemplateById(clTempId)
          const chains = clTemp?.data?.chains || []
          const clTempWorkingTimes = clTemp?.data?.company?.working_times || []

          const newChains = calculateDueDate(chains, startDate, clTempWorkingTimes)
          methods.setValue('working_times', clTempWorkingTimes)
          methods.setValue('chains', newChains)
        },
      },
      ['name']: {
        type: 'text',
        label: t('chain_link_name'),
        placeholder: t('enter_chain_link_name'),
        validator: {
          required: true,
          message: 'This field is required',
          minLength: 1,
          maxLength: 50,
        },
      },
      ['description']: {
        type: 'textArea',
        label: t('description'),
        props: {
          minRows: 3,
          maxRows: 5,
          multiline: true,
        },
      },
      ['tags']: {
        type: 'autocomplete',
        label: t('tags'),
        placeholder: t('select_a_tag_or_press'),
        props: {
          freeSolo: true,
          multiple: true,
          options: tags,
          getOptionLabel: (option) => {
            return option.name || option
          },
        },
      },
      'divider-2': { type: 'divider' },
      'section-2': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('timeline')}</Typography>
            <Typography variant="sectionText">{t('timeline_chain_link_description')}</Typography>
          </HeaderSection>
        ),
      },
      'divider-3': { type: 'divider' },
      ['start_date']: {
        type: 'date',
        label: t('start_date'),
        props: {
          disabled: methods.watch('chain_link_temp.id') === null,
        },
        onchange: (date) => {
          // console.log('start date changes!!')
          const time = methods.getValues('start_time') || workingTimes[0].start_time

          const formattedDate = dayjs(date)
            .set('hour', dayjs(time).get('hour') || 0)
            .set('minute', dayjs(time).get('minute') || 0)
            .locale(langLocale)
            .format()

          methods.setValue('start_date', formattedDate)

          // Add chains
          const newChains = calculateDueDate(watchChains, formattedDate)
          methods.setValue('chains', newChains) // Update the due date of milestones
        },
      },
      ['start_time']: {
        type: 'time',
        label: t('start_time'),
        props: {
          disabled: methods.watch('chain_link_temp.id') === null,
        },
        onchange: (time) => {
          // console.log('start time changes!!')
          const startDate = methods.getValues('start_date') || dayjs().format()
          const date = startDate || dayjs().format()

          const formattedDate = dayjs(date)
            .set('hour', dayjs(time).get('hour') || 0)
            .set('minute', dayjs(time).get('minute') || 0)
            .locale(langLocale)
            .format()

          methods.setValue('start_date', formattedDate)
          methods.setValue('start_time', time)

          // Add chains
          const newChains = calculateDueDate(watchChains, formattedDate)
          methods.setValue('chains', newChains) // Update the due date of milestones
        },
      },
      'divider-4': { type: 'divider' },
      'workingTimes-section': {
        component: watchCLTempId && (
          <>
            <Alert severity="info">
              <AlertTitle sx={{ fontFamily: "'Inter', 'Noto Sans Thai'" }}>{t('working_times')}</AlertTitle>
              {workingTimes.map((working_time, index) => {
                return (
                  <Typography variant="subtitle2" key={working_time.id}>
                    {'• '} {dayjs(`1970-01-01T${working_time.start_time}`).locale(langLocale).format('HH:mm')} -{' '}
                    {dayjs(`1970-01-01T${working_time.end_time}`).locale(langLocale).format('HH:mm')}
                  </Typography>
                )
              })}
            </Alert>

            {/* {spTemplate?.company?.working_times && spTemplate?.company?.working_times.length > 0 ? (
              spTemplate?.company?.working_times.map((working_time, index) => {
              return (
                <TypographyP key={working_time.id}>
                  {index + 1}: {dayjs(`1970-01-01T${working_time.start_time}`).locale(langLocal).format('HH:mm')} -{' '}
                  {dayjs(`1970-01-01T${working_time.end_time}`).locale(langLocal).format('HH:mm')}
                </TypographyP>
              )})
            : (
            <TypographyP>No working times found.</TypographyP>
            )}
            } */}
          </>
        ),
      },
      'chains-preview': {
        component:
          watchChains.length > 0 ? (
            <>
              <Tabs
                items={watchChains?.map((chain) => ({ id: chain?.id, label: chain?.sp?.name }))}
                onChange={handleTabChange}
                defaultTab={watchChains[0]?.id}
              />

              <SWTimelinePreview
                items={
                  watchChains.filter((chain) => {
                    return chain?.id === currentChain
                  })[0]?.sp?.milestones || []
                }
                noOppositeContent
              />
            </>
          ) : (
            <Empty title={t('no_template_not_found')} description={t('chain_link_no_template_description')} />
          ),
      },
    },
    required: ['chain_link_temp.id', 'name', 'start_date'],
  }

  return (
    <Container>
      <DynamicForm ref={ref} methods={methods} onSubmit={onSubmit} schema={formSchema} />
    </Container>
  )
})

export default CreateCL

const HeaderSection = styled('section')`
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  margin-top: 1rem;
`

export const TabsWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 1rem;

  transition: all 100ms ease-out;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);

  .tab-item {
    display: flex;
    height: 44px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: var(--Gray-700, #344054);
    /* Text sm/Semibold */
    font-family: 'Inter', 'Noto Sans Thai';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; /* 142.857% */

    cursor: pointer;
    transition: all 100ms ease-out;

    &.active {
      background: var(--Primary-50);
      border-bottom: 2px solid var(--Primary-600);
      color: var(--Primary-700);
    }

    &:not(.active) {
      &:hover {
        background: var(--Primary-50);
      }
    }
  }
`

const Container = styled('div')`
  height: auto;
  min-height: 100%;
  padding: 0 1.25rem 1rem 1.25rem;

  &::-webkit-scrollbar {
    width: 0.75rem;
    background: rgba(0, 0, 0, 0.25); /* make scrollbar invisible by default */
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    opacity: 0.5;
  }

  &:active::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-track {
    background: var(--Base-White);
    border: 1px solid var(--Gray-200);
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    margin: 0 0.5rem;
  }
`
