import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { dlog } from '../../../../utils/helper'

import { useDispatch } from 'react-redux'
import { createProjectTask, editProjectTask } from '../../../../redux/actions/projectTask'
import Button from '../../../form/button/Button'
import ButtonOutline from '../../../form/button/ButtonOutline'
import DialogRight from '../../../widget/combo/DialogRight'
import ProjectDetail from './ProjectDetail'
import Team from './Team'
import DialogFail from '../../../dialog/DialogFail'
import failIcon from '../../../../assets/images/fail-icon.png'

export default function DialogProject({ type, projectInfoData, onDataChange, onClose }) {
  const dispatch = useDispatch()
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))
  const [currentMenuSelected, setCurrentMenuSelected] = useState('project_details')
  const [menuList, setMenuList] = useState([
    {
      name: 'Project Details',
      value: 'project_details',
      active_status: true,
    },
    {
      name: 'Team',
      value: 'team',
      active_status: false,
    },
  ])
  const [projectData, setProjectData] = useState({})
  const [invalidProjectData, setInvalidProjectData] = useState(false)
  const [projectEditData, setProjectEditData] = useState({})

  const [isEditTeam, setIsEditTeam] = useState(false)
  const [isShowFailedModal, setIsShowFailedModal] = useState(false)
  

  useEffect(() => {
    if (type == 'edit_project' && projectInfoData) {
      // dlog(type, "CURRENT_TYPE")
      // dlog(projectInfoData, "projectInfoData_EDITING")
      setProjectData(projectInfoData)
    }
  }, [])

  const handleClickTabMenu = (data) => {
    let tmpList = [...menuList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setCurrentMenuSelected(data.value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuList([...tmpList])
  }
  const handleClickBtnCancel = () => {
    onClose()
  }
  const handleClickBtnCreate = async () => {
    if(Object.keys(employee_info).length > 0) {
      if (type == 'new_project') {
        // CREATE -------------------------------
        let isValid = checkValidateProjectData()
        dlog(isValid, "isValid_new_project")
        if (isValid) {
          let tmpData = projectData
          let tmpTeamList = [...tmpData.task_teams]
          let tmpTeamMembers = []
          if (tmpTeamList.length > 0) {
            for (let i = 0; i < tmpTeamList.length; i++) {
              let memberId = tmpTeamList[i].member_id
              tmpTeamMembers.push(memberId)
            }
          }
          tmpData.task_teams = [...tmpTeamMembers]
          const data = { ...tmpData }
          const request = { data }
          await dispatch(createProjectTask(request)).then(() => {
            onClose()
          })
        }
      } else {
        // EDIT -------------------------------
        // dlog(projectData, 'OLD_DATA_PROJECT')
        // dlog(projectEditData, 'EDIT_PROJECT')
        if (Object.keys(projectEditData).length > 0) {
          // KPIS
          let tmpOldKpiList = [...projectData?.project_task_kpis]
          let tmpKpiList = [...projectEditData?.task_kpis]
          let tmpAddList = []
          let tmpUpdateList = []
          let tmpDeleteList = []
          for (let i = 0; i < tmpKpiList.length; i++) {
            if (tmpKpiList[i].isAdd) {
              let tmpObj = {
                name: tmpKpiList[i].name,
                description: tmpKpiList[i].description,
              }
              tmpAddList.push(tmpObj)
            }
  
            if (tmpKpiList[i].isEdit && tmpKpiList[i].id) {
              let tmpObj = {
                id: tmpKpiList[i].id,
                name: tmpKpiList[i].name,
                description: tmpKpiList[i].description,
              }
              tmpUpdateList.push(tmpObj)
            } else if (tmpKpiList[i].isEdit && !tmpKpiList[i].id) {
              let tmpObj = {
                name: tmpKpiList[i].name,
                description: tmpKpiList[i].description,
              }
              tmpAddList.push(tmpObj)
            }
          }
  
          let deletedList = tmpOldKpiList.filter((o1) => !tmpKpiList.some((o2) => o1.id === o2.id))
          if (deletedList.length > 0) {
            for (let i = 0; i < deletedList.length; i++) {
              tmpDeleteList.push(deletedList[i].id)
            }
          }
  
          // TEAM_MEMBERS
          let tmpOldTeamList = [...projectData?.project_task_teams]
          let tmpTeamList = [...projectEditData?.task_teams]
          let tmpAddTeamMemberList = []
          let tmpDeleteTeamMemberList = []
          // dlog(tmpOldTeamList.length, 'OldTeam_length')
          // dlog(tmpTeamList.length, 'Team_length')
          if (tmpTeamList.length > 0) {
            let tmpOldMembers = []
            for (let i = 0; i < tmpOldTeamList.length; i++) {
              tmpOldMembers.push(tmpOldTeamList[i].employees[0].id)
            }
            let tmpTeamMembers = []
            for (let i = 0; i < tmpTeamList.length; i++) {
              tmpTeamMembers.push(tmpTeamList[i].member_id)
            }
  
            let deleteList = tmpOldMembers.filter((x) => !tmpTeamMembers.includes(x))
            // dlog(deleteList, 'deleteList')
            tmpDeleteTeamMemberList = [...deleteList]
  
            let addingList = tmpTeamMembers.filter((x) => !tmpOldMembers.includes(x))
            // dlog(addingList, 'addingList')
            tmpAddTeamMemberList = [...addingList]
          }
  
          let tmpObjEdit = {
            name: projectEditData.name,
            type: projectEditData.type,
            description: projectEditData.description,
            start_date: projectEditData.start_date,
            end_date: projectEditData.end_date,
            project_manager: projectEditData.project_manager,
            task_kpis: {
              add: [...tmpAddList],
              update: [...tmpUpdateList],
              delete: [...tmpDeleteList],
            },
            task_teams: {
              add: [...tmpAddTeamMemberList],
              delete: [...tmpDeleteTeamMemberList],
            },
          }
          // dlog(tmpObjEdit, 'EDIT_OBJ_BEFORE_UPDATE')
          const data = { ...tmpObjEdit }
          const request = { data }
          await dispatch(editProjectTask(projectData?.id, request)).then(() => {
            if (onDataChange) {
              onDataChange()
            }
          })
        }
      }
    } else {
      setIsShowFailedModal(true)
    }
  }

  const checkValidateProjectData = () => {
    let tmpProjectData = projectData
    if (Object.values(tmpProjectData).length > 0) {
      // dlog(tmpProjectData, 'tmpProjectData')
      let isValidName = false
      let isValidType = false
      let isValidDesc = false
      let isValidStartDate = false
      let isValidEndDate = false
      let isValidProjectManager = false

      if (tmpProjectData?.name != '') {
        isValidName = true
      } else {
        isValidName = false
      }

      if (tmpProjectData?.type != '') {
        isValidType = true
      } else {
        isValidType = false
      }

      if (tmpProjectData?.description != '') {
        isValidDesc = true
      } else {
        isValidDesc = false
      }

      if (tmpProjectData?.start_date != '') {
        isValidStartDate = true
      } else {
        isValidStartDate = false
      }

      if (tmpProjectData?.end_date != '') {
        isValidEndDate = true
      } else {
        isValidEndDate = false
      }

      if (tmpProjectData?.isValidProjectManager != '') {
        isValidProjectManager = true
      } else {
        isValidProjectManager = false
      }

      if (isValidName && isValidType && isValidDesc && isValidStartDate && isValidEndDate && isValidProjectManager) {
        setInvalidProjectData(false)
        return true
      } else {
        setInvalidProjectData(true)
        return false
      }
    } else {
      setInvalidProjectData(true)
      return false
    }
  }
  const handleOnDataChange = (val, typeSelected) => {
    if (val) {
      // dlog(val, "DATA CHANGED")
      let tmpData
      let tmpTeam = []
      if (type == 'edit_project' && typeSelected == 'team') {
        if (Object.keys(projectEditData).length > 0) {
          // dlog('projectEditData LENGTH > 0')
          tmpData = projectEditData
        } else {
          // dlog('projectEditData LENGTH == 0')

          let tmpProjectKpiList = [...projectData?.project_task_kpis]
          let tmpKpiList = []
          if (projectData?.project_task_kpis.length > 0) {
            for (let i = 0; i < tmpProjectKpiList.length; i++) {
              let tmpObj = {
                id: tmpProjectKpiList[i].id,
                name: tmpProjectKpiList[i].name,
                description: tmpProjectKpiList[i].description,
              }
              tmpKpiList.push(tmpObj)
            }
          }

          tmpData = {
            name: projectData.name,
            type: projectData.type,
            description: projectData.description,
            start_date: projectData.start_date,
            end_date: projectData.end_date,
            project_manager: projectData.project_manager?.id,
            task_kpis: [...tmpKpiList],
          }
          dlog(tmpData, 'tmpData in team tab')
        }
      } else {
        tmpData = projectData
        if (tmpData.task_teams) {
          tmpTeam = [...tmpData?.task_teams]
        }
      }

      if (typeSelected == 'project_details') {
        tmpData = val
        tmpData.task_teams = tmpTeam
      } else {
        // team
        tmpData.task_teams = [...val]
      }

      // dlog(tmpData, "handleOnDataChange")
      if (type == 'new_project') {
        setProjectData(tmpData)
      } else {
        setProjectEditData(tmpData)
      }
    }
  }

  return (
    <Styled>
      <DialogRight
        onClose={() => {
          onClose()
        }}
        headerView={
          <div className="header">
            {type == 'new_project' && 'New Project'}
            {type == 'edit_project' && 'Edit project'}
          </div>
        }
        bodyView={
          <div className="body">
            <div className="wrp-top">
              <div className="wrp-project-menu">
                <div className="tab-menu">
                  {menuList?.map((item, index) => (
                    <button
                      onClick={() => handleClickTabMenu(item)}
                      key={'btn-' + index}
                      className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                    >
                      {item.name}
                    </button>
                  ))}
                </div>
              </div>

              <div className="wrp-content-container">
                {currentMenuSelected == 'project_details' && (
                  <ProjectDetail
                    type={type}
                    isInvalid={invalidProjectData}
                    data={projectData}
                    onDataChange={(e) => handleOnDataChange(e, 'project_details')}
                  />
                )}

                {currentMenuSelected == 'team' && (
                  <Team type={type} data={projectData} onDataChange={(e) => handleOnDataChange(e, 'team')} />
                )}
              </div>
            </div>

            <div className="wrp-bottom-content">
              <ButtonOutline className="btn-cancel" onClick={() => handleClickBtnCancel()}>
                Cancel
              </ButtonOutline>
              <Button
                onClick={() => handleClickBtnCreate()}
                disabled={Object.keys(projectEditData).length == 0 && type == 'edit_project' ? true : false}
              >
                {type == 'new_project' ? 'Create' : 'Save'}
              </Button>
            </div>
          </div>
        }
      />
      {isShowFailedModal &&
        <DialogFail
          open={Boolean(isShowFailedModal)}
          onClose={() => setIsShowFailedModal(false)}
          onSubmit={() => setIsShowFailedModal(false)}
          icon={failIcon}
          title={'You may not fully use this feature, since you are not employee.'}
          nameItem={''}
          description={''}
          textYes="Ok"
        />
      }
    </Styled>
  )
}

const Styled = styled.div`
  .header {
    font-weight: bold;
    font-size: 32px;
  }
  .body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .wrp-top {
    flex: 1;
    border-top: 1px solid var(--Gray-300);
    border-bottom: 1px solid var(--Gray-300);
  }

  .wrp-project-menu {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;

    .tab-menu {
      border-bottom: 1px solid var(--Gray-200);
    }

    .btn-menu-style {
      border: none;
      border-bottom: 2px solid transparent;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: var(--Primary-50);
      border-bottom: 2px solid var(--Primary-600) !important;
      color: var(--Primary-700);
    }
  }

  .wrp-bottom-content {
    display: flex;
    justify-content: flex-end;
    padding: 16px;

    .btn-cancel {
      margin-right: 8px;
    }
  }
`
