import { Chip } from '@mui/joy'
import { useMediaQuery } from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AiOutlineMenu, AiOutlineMenuUnfold } from 'react-icons/ai'
import { IoChevronBack, IoChevronDown } from 'react-icons/io5'
import { RiCloseLine } from 'react-icons/ri'
import { TbAppWindow } from 'react-icons/tb'

import Footer from 'components/layout/Sidebar/Footer'
import Header from 'components/layout/Sidebar/Header'
import Menu from 'components/layout/Sidebar/Menu'
import { findMatchedMenu } from 'utils/sideNavHelpers'

const Sidebar = ({ onNotificationClick = () => {} }) => {
  const location = useLocation()
  const refHeader = useRef(null)
  const refFooter = useRef(null)
  const breakpointXL = useMediaQuery('(min-width: 1280px)')
  const breakpointLG = useMediaQuery('(min-width: 1024px)')

  const { menu_list: menuItems } = JSON.parse(localStorage.getItem('admin')) || {}
  const lang = localStorage.getItem('lang') || 'en'

  const [openExtra, setOpenExtra] = useState({})
  const [collapseProps, setCollapseProps] = useState({})
  const [searchResult, setSearchResult] = useState([])
  const [searchShown, setSearchShown] = useState([])
  const [openMenu, setOpenMenu] = useState(false)
  const isCollapse = Object.keys(collapseProps).length > 0

  const handleExtraMenu = (extraItems) => {
    setOpenMenu(true)

    if (Object.keys(openExtra).length > 0) {
      setOpenExtra({})

      setTimeout(() => {
        setOpenExtra(extraItems)
      }, 300)

      return
    }

    setOpenExtra(extraItems)
  }

  const handleCollapseMenu = () => {
    setOpenExtra({})
    setCollapseProps({
      width: '80px !important',
      minWidth: '80px',
    })
  }

  const handleExpand = () => {
    setCollapseProps({})
  }

  const headerProps = {
    onSearchEnter(searchValues) {
      setCollapseProps({})

      if (searchValues.length > 0) {
        // const results = [...findMatchedMenu(menuItems, searchValues[searchValues.length - 1])]
        const results = findMatchedMenu(menuItems, [searchValues[searchValues.length - 1]])
        setSearchResult((prev) => {
          return [...results, ...prev]
        })
        setSearchShown((prev) => {
          if (results.length === 0) {
            return prev
          }

          return [
            ...prev,
            {
              id: searchValues[searchValues.length - 1].id,
              name: searchValues[searchValues.length - 1].name,
              open: true,
            },
          ]
        })
      }
    },
    onSearchClear() {
      setSearchResult([])
      setSearchShown([])
    },
  }

  const handleToggleResult = (id) => {
    return () => {
      const newSearchShown = searchShown.map((item) => {
        if (item.id === id) {
          return { ...item, open: !item.open }
        }

        return item
      })

      setSearchShown(newSearchShown)
    }
  }

  const handleRemoveSearch = (id) => {
    return () => {
      const newSearchResult = searchResult.filter((item) => item.id !== id)
      const newSearchShown = searchShown.filter((item) => item.id !== id)

      setSearchResult(newSearchResult)
      setSearchShown(newSearchShown)
    }
  }

  const handleOpenMenu = () => {
    setOpenMenu(!openMenu)
    setOpenExtra({})
  }

  useEffect(() => {
    refHeader.current.setNewSearchValues?.(searchResult.map((item) => ({ id: item.id, name: item.name })))
  }, [searchResult])

  useEffect(() => {
    if (breakpointXL) {
      setOpenMenu(false)
      setCollapseProps((prev) => ({ ...prev }))
    } else if (breakpointLG) {
      setOpenMenu(false)
      setCollapseProps({
        width: '80px !important',
        minWidth: '80px',
      })
    } else {
      setCollapseProps({})
      setOpenExtra({})
      setOpenMenu(false)
    }
  }, [breakpointLG, breakpointXL])

  useEffect(() => {
    setOpenMenu(false)
    setOpenExtra({})
  }, [location.pathname])

  return (
    <>
      <StyledAside sx={{ ...collapseProps }}>
        <StyledContainer className={`erp-menu${isCollapse ? ' erp-menu-collapse' : ''}`}>
          <Header
            ref={refHeader}
            onCollapse={handleCollapseMenu}
            onExpand={handleExpand}
            isCollapse={isCollapse}
            {...headerProps}
          />

          {(breakpointLG || breakpointXL) && !isCollapse && searchResult.length > 0 ? (
            <StyledSearchContainer>
              {searchResult.map((result) => {
                const resultByType = result?.result?.reduce((acc, item) => {
                  if (item.type) {
                    if (acc[item.type]) {
                      acc[item.type].push(item)
                    } else {
                      acc[item.type] = [item]
                    }
                  }
                  return acc
                }, {})
                const resultByTypeKeys = Object.keys(resultByType)

                return (
                  <div
                    key={result.id}
                    className={`search-result-container ${
                      searchShown.find((item) => item.id === result.id)?.open ? 'open' : 'close'
                    }`}
                  >
                    <div className="search-result-title">
                      {`${result?.result?.length} ผลลัพธ์ใน "${result?.name}"`}
                      <span className="toggle-collapse-icon" onClick={handleToggleResult(result.id)}>
                        <IoChevronDown fontSize={24} />
                      </span>

                      <span className="close-icon" onClick={handleRemoveSearch(result.id)}>
                        <RiCloseLine fontSize={24} onClick={() => {}} />
                      </span>
                    </div>

                    <StyledSearchResultMenuWrapper className="search-result-menu-wrapper">
                      {resultByTypeKeys.map((type) => {
                        const mappedTypeObj = () => {
                          switch (type) {
                            case 'page':
                              return { prefix: <TbAppWindow fontSize={24} />, name: 'หน้า' }
                            case 'sub':
                              return { prefix: <AiOutlineMenuUnfold fontSize={24} />, name: 'เมนูย่อย' }
                            case 'main':
                              return { prefix: <AiOutlineMenu fontSize={24} />, name: 'เมนูหลัก' }
                            default:
                              return { name: 'ไม่พบประเภท' }
                          }
                        }

                        const typeObj = mappedTypeObj()

                        return (
                          <StyledSearchGroupTypeContainer key={type} className="saerch-group-type-container">
                            <div className="group-type-header">
                              <span className="erp-menu-icon">{typeObj?.prefix}</span>
                              <span className="menu-type-title">{typeObj.name}</span>
                              <span className="menu-type-count">
                                <Chip
                                  color="var(--Primary-200)"
                                  variant="solid"
                                  sx={{
                                    fontFamily: "'Inter', 'Noto Sans Thai'",
                                    fontSize: '12px',
                                    color: 'var(--Primary-700)',
                                    fontWeight: 600,
                                  }}
                                >
                                  {resultByType[type].length}
                                </Chip>
                              </span>
                            </div>

                            <Menu
                              items={resultByType[type]}
                              onExtra={handleExtraMenu}
                              mode="search"
                              rootStyle={{ paddingTop: 0, overflowY: 'hidden', flex: 'none' }}
                              onNotificationClick={() => {
                                onNotificationClick?.()
                              }}
                            />
                          </StyledSearchGroupTypeContainer>
                        )
                      })}
                    </StyledSearchResultMenuWrapper>
                  </div>
                )
              })}

              <div className="search-clear-container">
                <button className="search-clear-btn" onClick={() => refHeader?.current?.onSearchClear?.()}>
                  ล้างการค้นหา
                </button>
              </div>
            </StyledSearchContainer>
          ) : (
            <Menu
              items={menuItems || []}
              onExtra={handleExtraMenu}
              isCollapse={isCollapse}
              onNotificationClick={() => {
                onNotificationClick?.()
              }}
              rootProps={{ className: `${openMenu ? 'open' : 'close'}` }}
            />
          )}

          <Footer
            ref={refFooter}
            isCollapse={isCollapse}
            isOpenMenu={openMenu}
            onProfileClick={handleExtraMenu}
            onBurgerClick={handleOpenMenu}
          />
        </StyledContainer>
      </StyledAside>

      <StyledAside className={`erp-extra-aside${Object.keys(openExtra).length > 0 ? ' erp-open-extra' : ''}`}>
        {Object.keys(openExtra).length > 0 && (
          <>
            <StyledExtraHeader sx={{ height: 'fit-content' }}>
              <StyledSubmenuTitle className={`erp-submenu-title`}>
                {lang === 'en' ? openExtra?.name_en : openExtra?.name_th}
              </StyledSubmenuTitle>
              <span
                className={`erp-menu-extra-collapse-icon`}
                onClick={() => {
                  setOpenExtra({})
                  !breakpointLG && setOpenMenu(true)
                }}
              >
                <IoChevronBack fontSize={24} />
              </span>
            </StyledExtraHeader>

            <Menu
              mode="extra"
              items={openExtra?.children}
              rootProps={{
                className: 'erp-extra-menu',
              }}
            />
          </>
        )}
      </StyledAside>
    </>
  )
}

export default Sidebar

const StyledSearchGroupTypeContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: auto;
  transition: all 0.3s ease;
  padding: 0.5rem;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 0 1px var(--Primary-500) inset;
  margin-bottom: 0.25rem;
`

const StyledSearchResultMenuWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  height: auto;
  transition: all 0.3s ease;

  .group-type-header {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    font-family: 'Inter', 'Noto Sans Thai';
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      color: var(--Primary-50);
      background-color: rgba(0, 0, 0, 0.05);
    }

    > .erp-menu-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 6px;
      color: inherit;
      width: 24px;
    }

    > .menu-type-title {
      display: flex;
      padding: 0.25rem 0.75rem;
      font-family: 'Inter', 'Noto Sans Thai';
      font-size: 16px;
      font-weight: 600;
      border-radius: 6px;
    }

    > .menu-type-count {
      margin-left: auto;
    }
  }
`

const StyledSearchContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
  position: relative;
  width: 100%;

  flex: 1 1 0%;
  flex-grow: 1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .search-clear-container {
    display: flex;
    flex-direction: column;
    position: sticky;
    bottom: -16px;
    background-color: var(--Primary-600);
    padding: 1rem 0.5rem 0.5rem 0.5rem;
    z-index: 999;

    > .search-clear-btn {
      position: sticky;
      font-family: 'Inter', 'Noto Sans Thai';
      background-color: var(--Primary-600);
      color: var(--Primary-50);
      border-radius: 6px;
      cursor: pointer;
      margin-bottom: 0.5rem;
      text-align: center;
      border: none;
      box-shadow: 0 0 0 1px var(--Primary-500) inset;
      padding: 8px 0;
      transition: all 0.3s ease;

      bottom: 0;

      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(6px);
        color: var(--Base-White);
      }
    }
  }

  > .search-result-container {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: 6px;
    background-color: var(--Primary-600);

    &.close {
      .search-result-menu-wrapper {
        display: none;
      }

      .toggle-collapse-icon {
        rotate: -90deg;
      }
    }

    > .search-result-title {
      display: flex;
      padding: 0.25rem 0.75rem;
      font-family: 'Inter', 'Noto Sans Thai';
      font-size: 16px;
      font-weight: 600;
      border-radius: 6px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        color: var(--Primary-50);
        background-color: rgba(0, 0, 0, 0.05);

        .close-icon {
          scale: 1;
          width: 24px;
        }
      }

      .toggle-collapse-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        border-radius: 6px;
        transition: all 0.3s ease;
      }

      .close-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 0;
        height: 24px;
        margin-left: 4px;
        scale: 0;
        overflow: hidden;
        border-radius: 6px;
        transition: all 0.3s ease;
      }

      .close-icon:hover,
      .toggle-collapse-icon:hover {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
`

const StyledAside = styled('aside')`
  position: fixed;
  border: 1px solid var(--Primary-500);
  border-width: 0 0 1px 0;
  width: 100vw;
  min-width: 312px;
  min-height: 70px;
  height: 70px;
  color: var(--Primary-100);
  top: 0;
  z-index: 9;

  background-color: var(--Primary-600);

  &.erp-extra-aside {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100vw;
    min-width: 312px;
    height: 0;
    min-height: 0;
    background-color: var(--Primary-700);
    top: 70px;
    border: none;

    &.erp-open-extra {
      height: 100vh;
      min-height: 100vh;
      transition: height 0.3s ease;

      .erp-extra-menu {
        position: relative;
        height: auto;
        top: 16px;
        background-color: inherit;
        transition: all 0.3s ease;
      }
    }
  }

  @media (min-width: 1024px) {
    position: relative;
    border-width: 0 1px 0 0;
    width: 312px;
    height: 100vh;
    transition: all 0.3s ease;

    &.erp-extra-aside {
      overflow-x: hidden;
      overflow-y: auto;
      width: 0;
      min-width: 0;
      height: 100vh;
      min-height: 100vh;
      background-color: var(--Primary-700);
      top: 0;

      &.erp-open-extra {
        width: 312px;
        min-width: 312px;
        transition: all 0.3s ease;
      }
    }
  }
`

const StyledContainer = styled('div')`
  position: relative;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  z-index: 3;

  &.erp-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > footer {
      gap: 6px;
      padding: 0 16px;
    }

    > footer > .erp-profile-header-nav {
      padding: 0;
      margin: 0;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;

      > .erp-profile-avatar,
      > .erp-burger-icon {
        margin-right: 0;
      }
    }
  }

  &.erp-menu.erp-menu-collapse {
    .erp-menu-header {
      flex-direction: column;
      padding-bottom: 0.5rem;
      transition: all 0.3s;

      .erp-menu-collapse-icon,
      .erp-menu-search {
        width: auto;
        margin-top: 0.5rem;
        padding: 0.5rem;
        cursor: pointer;
        transition: all 0.3s;
        border-radius: 6px;

        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
          color: var(--Primary-50);
        }
      }
    }

    .erp-menu-title {
      display: none;
    }

    .erp-submenu-root > .erp-menu-button > .erp-submenu-expand-icon {
      position: absolute;
      right: 0;
    }

    .erp-profile {
      justify-content: center;

      > .erp-profile-avatar {
        margin-right: 0;
      }

      > .erp-profile-info {
        display: none;
      }
    }
  }

  & .erp-menu-expand-button {
    display: flex;
    position: absolute;
    right: 0;
    top: -50%;
    background-color: var(--Primary-500);
    color: var(--Primary-50);
    padding: 0.5rem;
    border-radius: 6px;
    cursor: pointer;
    z-index: 999;
    transform: scale(0);
    transition: all 0.3s;

    &.is-collapse {
      background-color: var(--Primary-600);
      transform: scale(1);
    }
  }

  @media (min-width: 1024px) {
    &.erp-menu {
      flex-direction: column;

      > footer {
        padding: 0.5rem 0;
        width: 100%;
        border-style: solid;
        border-color: var(--Primary-500);
        border-width: 1px 0 0 0;

        .erp-profile {
          width: 100%;
        }
      }
    }
  }
`

const StyledExtraHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--Primary-600);
  color: var(--Primary-100);

  & > .erp-menu-extra-collapse-icon {
    display: flex;
    transition: all 0.3s;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 6px;
    width: 36px;
    height: 36px;
    align-items: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
      color: var(--Primary-50);
    }
  }
`

const StyledSubmenuTitle = styled('span')`
  font-size: 16px;
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
`
