import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab'
import { AccordionDetails, AccordionSummary, Chip, Accordion as MuiAccordion, Stack } from '@mui/material'
import { styled } from '@mui/system'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LiaUserCheckSolid } from 'react-icons/lia'
import { LuClipboardList, LuFlag } from 'react-icons/lu'
import { MdExpandMore, MdOutlineContentPasteSearch } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import SidePanelV2 from 'components/common/SidePanelV2'
import DrawerDetails, { TaskDrawerTitle } from 'components/feature/operation-process/default-cards'
import { APPROVER, DELIVERY, REVIEWER, SW } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import AssignTo from './assign-to'
import Card from './cards'
import DueDate from './due-date'

const init_drawer = {
  'view-sw': false,
  'view-task': false,
  'view-review': false,
  'view-approval': false,
  'view-sign-doc': false,
}

export default function SWTimeline({ position = 'right', items = [], noOppositeContent = false, ...props }) {
  const { t } = useTranslation()

  const location = useLocation()
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)
  const {
    chainLink: clActions,
    sp: spActions,
    sw: swActions,
    task: taskActions,
    review: reviewActions,
    approval: approvalActions,
  } = opActions()
  const { getCL } = clActions()
  const { getSP } = spActions()
  const { clearSW } = swActions()
  const { clearTask } = taskActions()
  const { clearReviewer } = reviewActions()
  const { clearApproval } = approvalActions()
  const { sp, task: taskData, sw: swData, chainLink } = opProcSelector
  const { reviewer, approver } = opProcSelector

  const [drawer, setDrawer] = useState(init_drawer)

  const initToggleDrawerData = {
    swId: undefined,
    approverId: undefined,
    reviewerId: undefined,
    taskId: undefined,
    docSignerId: undefined,
  }

  const reloadPage = (isUploadFile) => {
    if (location.pathname.includes('chain-link')) {
      dispatch(getCL().byId(chainLink?.id)) // reload CL page
      dispatch(getCL().logs(chainLink?.id))
      dispatch(getCL().files(chainLink?.id))
      !!isUploadFile && dispatch(getCL().deliveries(chainLink?.id))
      return
    }
    dispatch(getSP().byId(sp?.id)) // reload SP page
    dispatch(getSP().logs(sp?.id))
    dispatch(getSP().files(sp?.id))
    !!isUploadFile && dispatch(getSP().deliveries(sp?.id))
  }

  const handleToggleDrawer = (drawerName, open, data = initToggleDrawerData) => {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }

      setDrawer({ ...init_drawer, [drawerName]: open, ...data })
    }
  }

  const handleTaskDrawer = (taskId) => {
    handleToggleDrawer('view-task', true, { taskId })()
  }

  const handleReviewDrawer = (reviewerId) => {
    handleToggleDrawer('view-review', true, { reviewerId })()
  }

  const handleSubmitSW = () => {
    handleToggleDrawer('view-sw', false)()
    reloadPage()
  }

  const handleAssignChange = () => {
    reloadPage()
  }

  const handleSubmitReview = () => {
    handleToggleDrawer('view-review', false)()
    reloadPage()
  }

  const handleApprovalDrawer = (approverId) => {
    handleToggleDrawer('view-approval', true, { approverId })()
  }

  const handleSubmitApproval = () => {
    handleToggleDrawer('view-approval', false)()
    reloadPage()
  }

  const handleDocSignersDrawer = (swId, docSignerId) => {
    handleToggleDrawer('view-sign-doc', true, { swId, docSignerId })()
  }

  const timelineContentStyles = {
    p: 0,
    m: '0.5rem 0 0.5rem 1.5rem',
    borderRadius: '0.5rem',
    border: '1px solid var(--Gray-200)',
    transition: 'all 0.3 ease',
    color: 'var(--Gray-700)',

    '& h3': {
      color: 'inherit',

      /* Text md/Semibold */
      fontFamily: `'Inter', 'Noto Sans Thai'`,
      fontSize: '14px',
      lineHeight: '20px',

      '&.sw-link': {
        cursor: 'pointer',
        transition: 'color 0.3s ease',

        '&:hover': {
          color: 'var(--Primary-700)',
          textDecoration: 'underline',
        },
      },

      '&.title-section': {
        fontWeight: 500,
        width: '100%',
      },
    },

    '& p': {
      color: 'var(--Gray-600)',

      /* Text xs/Regular */
      fontFamily: `'Inter', 'Noto Sans Thai'`,
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
    },
  }

  let prefixNo = 0 // Prefix number for each SW
  return (
    <>
      <Timeline
        position={position}
        sx={{
          [`& .${timelineItemClasses.root}:before`]: noOppositeContent && {
            flex: 0,
            padding: 0,
          },
          alignSelf: 'stretch',
          margin: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
        {...props}
      >
        {items?.sws &&
          items.sws.map((item, index) => {
            const isFirstIndex = index === 0
            const isLastIndex = index === items.sws.length - 1
            prefixNo++

            const {
              id: swId,
              name: swName,
              status: swStatus,
              department: swDepartment,
              positions: swPositions,
              due_date: swDueDate,
              hr,
              min,
              tasks,
              reviewers,
              approver,
              deliveries,
            } = item || {} // Set default value as an empty object to avoid undefined
            const deliveryDoc = deliveries?.find((delivery) => delivery.type === DELIVERY.TYPE.Document)

            const joinedPositions = swPositions?.map((position) => position.name).join(', ') || 'Non-specific position.'
            const isActiveSW =
              swStatus === SW.STATUS.InProgress || swStatus === SW.STATUS.Completed || swStatus === SW.STATUS.Open
            const isSomeReviewNotPass = (reviewers || []).some(
              (reviewer) => reviewer?.status === REVIEWER.STATUS.NotPass
            )
            const isActiveApprove =
              approver?.status === APPROVER.STATUS.WaitingForApprove || approver?.status === APPROVER.STATUS.Approved

            return (
              <React.Fragment key={index}>
                <TimelineItem key={swId}>
                  <TimelineSeparator key={`separator-${index}`}>
                    <TimelineConnector
                      sx={{
                        backgroundColor: isActiveSW ? 'var(--Primary-600)' : 'var(--Gray-200)',
                        visibility: isFirstIndex ? 'hidden' : 'visible',
                      }}
                    />

                    {/* Icon */}
                    <TimelineDot
                      sx={{
                        boxShadow: 'none',
                        padding: '0.75rem',
                        borderRadius: '0.5rem',

                        '&.MuiTimelineDot-filled': isActiveSW
                          ? {
                              backgroundColor: 'var(--Primary-50)',
                              border: '1px solid var(--Primary-600)',
                              color: 'var(--Primary-600)',
                            }
                          : {
                              backgroundColor: 'var(--Base-White)',
                              border: '1px solid var(--Gray-200)',
                              color: 'var(--Gray-700)',
                            },
                      }}
                    >
                      <LuFlag />
                    </TimelineDot>

                    <TimelineConnector
                      sx={{
                        backgroundColor: isActiveSW ? 'var(--Primary-600)' : 'var(--Gray-200)',
                        visibility: isLastIndex ? 'hidden' : 'visible',
                      }}
                    />
                  </TimelineSeparator>

                  <TimelineContent
                    sx={{
                      ...timelineContentStyles,
                      ...((swStatus === SW.STATUS.InProgress || swStatus === SW.STATUS.Open) && {
                        borderColor: 'var(--Primary-600)',
                        boxShadow: '0px 4px 8px var(--Gray-50)',
                        color: 'var(--Primary-700)',
                        '& p': {
                          ...timelineContentStyles['& p'],
                          color: 'var(--Primary-600)',
                        },
                      }),
                    }}
                  >
                    <Stack direction="row" justifyContent="space-between" p="1rem 1.5rem">
                      <Stack spacing={2}>
                        <Stack spacing={1}>
                          <h3 className="sw-link" onClick={handleToggleDrawer('view-sw', true, { swId: item?.id })}>
                            {prefixNo + '. ' + swName}
                          </h3>
                          <p>{(swDepartment?.name || 'Non-specific postion.') + ' | ' + joinedPositions}</p>
                          {swDueDate && <DueDate dueDate={swDueDate} rootProps={{ justifyContent: 'flex-start' }} />}

                          <AssignTo
                            name={
                              item?.employee?.first_name &&
                              item?.employee?.last_name &&
                              `${item.employee.first_name} ${item.employee.last_name}`
                            }
                          />
                        </Stack>
                      </Stack>

                      {swStatus && <StyledChip label={swStatus} color={SW.STATUS_CHIP[swStatus]} />}
                    </Stack>

                    {tasks && Array.isArray(tasks) && tasks.length > 0 && (
                      <Accordion defaultExpanded={false}>
                        <AccordionSummary
                          aria-controls={`content1d-${index}`}
                          id={`header1d-${index}`}
                          expandIcon={<MdExpandMore />}
                        >
                          <LuClipboardList size={20} style={{ marginRight: '0.5rem' }} />
                          <h3 className="title-section">{`${t('task')} (${tasks.length})`}</h3>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Stack spacing={1} padding="0 0 0 1rem">
                            {tasks.map((task) => (
                              <Card key={'task-' + task.id} onClick={() => handleTaskDrawer(task.id)}>
                                <Card.Task data={task} due_date={swDueDate} dateFormat="DD MMM YYYY, hh:mm a" />
                              </Card>
                            ))}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    )}

                    {deliveryDoc && deliveryDoc?.document?.signers?.length > 0 && (
                      <Accordion defaultExpanded={true}>
                        <AccordionSummary
                          aria-controls={`content2d-${index}`}
                          id={`header2d-${index}`}
                          expandIcon={<MdExpandMore />}
                        >
                          <LuClipboardList size={20} style={{ marginRight: '0.5rem' }} />
                          <h3 className="title-section">{`${t('document')}: ${
                            deliveryDoc?.document?.business_doc?.doc_id
                          } (${deliveryDoc?.document?.signers?.length})`}</h3>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Stack spacing={1} padding="0 0 0 1rem">
                            {deliveryDoc?.document?.signers?.map((signer) => (
                              <Card
                                key={'document-' + signer.id}
                                onClick={() => handleDocSignersDrawer(swId, signer.id)}
                              >
                                <Card.DocumentSigners data={signer} />
                              </Card>
                            ))}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </TimelineContent>
                </TimelineItem>

                {reviewers &&
                  Array.isArray(reviewers) &&
                  reviewers.length > 0 &&
                  reviewers?.map((reviewer) => {
                    const isActiveReviews =
                      swStatus === SW.STATUS.Completed &&
                      (reviewer?.status === REVIEWER.STATUS.WaitingForReview ||
                        reviewer?.status === REVIEWER.STATUS.Passed)
                    return (
                      <TimelineItem key={`review-${reviewer.id}`}>
                        <TimelineSeparator key={`separator-review-${reviewer.id}`}>
                          <TimelineConnector
                            sx={{
                              backgroundColor:
                                isActiveReviews && !isSomeReviewNotPass ? 'var(--Primary-600)' : 'var(--Gray-200)',
                            }}
                          />

                          <TimelineDot
                            sx={{
                              boxShadow: 'none',
                              padding: '0.75rem',
                              borderRadius: '0.5rem',
                              rotate: '45deg',

                              '&.MuiTimelineDot-filled':
                                isActiveReviews && !isSomeReviewNotPass
                                  ? {
                                      backgroundColor: 'var(--Primary-50)',
                                      border: '1px solid var(--Primary-600)',
                                      color: 'var(--Primary-600)',
                                    }
                                  : {
                                      backgroundColor: 'var(--Base-White)',
                                      border: '1px solid var(--Gray-200)',
                                      color: 'var(--Gray-700)',
                                    },

                              '& svg': {
                                rotate: '-45deg',
                              },
                            }}
                          >
                            <MdOutlineContentPasteSearch />
                          </TimelineDot>

                          <TimelineConnector
                            sx={{
                              backgroundColor:
                                isActiveReviews && !isSomeReviewNotPass ? 'var(--Primary-600)' : 'var(--Gray-200)',
                              visibility: isLastIndex ? 'hidden' : 'visible',
                            }}
                          />
                        </TimelineSeparator>

                        <TimelineContent
                          sx={{
                            ...timelineContentStyles,
                            ...(reviewer?.status === REVIEWER.STATUS.WaitingForReview && {
                              borderColor: 'var(--Primary-600)',
                              boxShadow: '0px 4px 8px var(--Gray-50)',
                              color: 'var(--Primary-700)',
                              '& p': {
                                ...timelineContentStyles['& p'],
                                color: 'var(--Primary-600)',
                              },
                            }),
                          }}
                        >
                          <Stack>
                            <Stack spacing={1} sx={{ padding: '1rem 1.5rem 0 1.5rem' }}>
                              <h3 className="title-section" style={{ fontWeight: 600 }}>
                                {`${++prefixNo}. ${t('review')}: ${item?.name}`}
                              </h3>
                            </Stack>

                            <Card
                              key={'reviewer-' + reviewer?.id}
                              onClick={() => handleReviewDrawer(reviewer?.id)}
                              sx={{ border: 'none', padding: '1rem 1.5rem' }}
                            >
                              <Card.Reviewer data={reviewer} />
                            </Card>
                          </Stack>
                        </TimelineContent>
                      </TimelineItem>
                    )
                  })}

                {approver && (
                  <TimelineItem key={`approver-${swId}`}>
                    <TimelineSeparator key={`separator-approver-${index}`}>
                      <TimelineConnector
                        sx={{ backgroundColor: isActiveApprove ? 'var(--Primary-600)' : 'var(--Gray-200)' }}
                      />

                      <TimelineDot
                        sx={{
                          boxShadow: 'none',
                          padding: '0.75rem',
                          borderRadius: '0.5rem',
                          rotate: '45deg',

                          '&.MuiTimelineDot-filled': isActiveApprove
                            ? {
                                backgroundColor: 'var(--Primary-50)',
                                border: '1px solid var(--Primary-600)',
                                color: 'var(--Primary-600)',
                              }
                            : {
                                backgroundColor: 'var(--Base-White)',
                                border: '1px solid var(--Gray-200)',
                                color: 'var(--Gray-700)',
                              },

                          '& svg': {
                            rotate: '-45deg',
                          },
                        }}
                      >
                        <LiaUserCheckSolid />
                      </TimelineDot>

                      <TimelineConnector
                        sx={{
                          backgroundColor: isActiveApprove ? 'var(--Primary-600)' : 'var(--Gray-200)',
                          visibility: isLastIndex ? 'hidden' : 'visible',
                        }}
                      />
                    </TimelineSeparator>

                    <TimelineContent
                      sx={{
                        ...timelineContentStyles,
                        ...(approver?.status === APPROVER.STATUS.WaitingForApprove && {
                          borderColor: 'var(--Primary-600)',
                          boxShadow: '0px 4px 8px var(--Gray-50)',
                          color: 'var(--Primary-700)',
                          '& p': {
                            ...timelineContentStyles['& p'],
                            color: 'var(--Primary-600)',
                          },
                        }),
                      }}
                    >
                      <Stack>
                        <Stack spacing={1} sx={{ padding: '1rem 1.5rem 0 1.5rem' }}>
                          <h3 className="title-section" style={{ fontWeight: 600 }}>
                            {`${++prefixNo}. ${t('approvals')}: ${swName}`}
                          </h3>

                          {approver?.due_date && (
                            <DueDate dueDate={approver.due_date} rootProps={{ justifyContent: 'flex-start' }} />
                          )}
                        </Stack>

                        <Card
                          onClick={() => handleApprovalDrawer(approver?.id)}
                          sx={{ border: 'none', padding: '1rem 1.5rem' }}
                        >
                          <Card.Approval data={approver || {}} />
                        </Card>
                      </Stack>
                    </TimelineContent>
                  </TimelineItem>
                )}
              </React.Fragment>
            )
          })}
      </Timeline>

      <React.Fragment key="Drawer_Components">
        <SidePanelV2
          titleText={swData?.name}
          supportingText={`SOW Code: ${swData?.code}`}
          open={drawer['view-sw']}
          onClose={(event) => {
            dispatch(clearSW())
            handleToggleDrawer('view-sw', false)(event)
          }}
        >
          <DrawerDetails.SoW
            id={drawer?.swId}
            onSubmit={handleSubmitSW}
            onAssign={handleAssignChange}
            onUnassign={handleAssignChange}
            onActions={{
              onClickTask: (taskId) => handleTaskDrawer(taskId),
              onCreateDocument: ({ uploadFile }) => reloadPage(uploadFile),
              onDeleteDocument: ({ uploadFile }) => reloadPage(uploadFile),
              onViewSOP: () => handleToggleDrawer(false)(),
              onUploadAssets: () => reloadPage(),
              onDeleteAssets: () => reloadPage(),
            }}
          />
        </SidePanelV2>

        <SidePanelV2
          titleText={'Review: ' + reviewer?.sw?.name}
          supportingText={`Code: ${reviewer?.code}`}
          open={drawer['view-review']}
          onClose={(event) => {
            dispatch(clearReviewer())
            handleToggleDrawer('view-review', false)(event)
          }}
        >
          <DrawerDetails.Reviewer
            reviewerId={drawer?.reviewerId}
            openTask={handleTaskDrawer}
            onSubmit={handleSubmitReview}
            onViewClick={handleToggleDrawer('view-review', false)}
          />
        </SidePanelV2>

        <SidePanelV2
          titleText={`${t('approvals')}: ` + approver?.sw?.name}
          supportingText={`Code: ${approver?.code}`}
          open={drawer['view-approval']}
          onClose={(event) => {
            dispatch(clearApproval())
            handleToggleDrawer('view-approval', false)(event)
          }}
        >
          <DrawerDetails.Approval
            approverId={drawer?.approverId}
            openTask={handleTaskDrawer}
            onSubmit={handleSubmitApproval}
            onViewClick={handleToggleDrawer('view-approval', false)}
          />
        </SidePanelV2>

        <SidePanelV2
          titleText={
            <TaskDrawerTitle
              taskData={taskData}
              onClickBreadcrumb={handleToggleDrawer('view-sw', true, { swId: taskData?.sw?.id })}
            />
          }
          supportingText={`Task Code: ${taskData?.code}`}
          open={drawer['view-task']}
          onClose={(event) => {
            dispatch(clearTask())
            handleToggleDrawer('view-task', false)(event)
          }}
        >
          <DrawerDetails.Task taskId={drawer?.taskId} onViewClick={handleToggleDrawer('view-task', false)} />
        </SidePanelV2>

        <SidePanelV2
          titleText={swData?.name}
          supportingText={`SOW Code: ${swData?.code}`}
          open={drawer['view-sign-doc']}
          onClose={(event) => {
            dispatch(clearSW())
            handleToggleDrawer('view-sign-doc', false)(event)
          }}
        >
          <DrawerDetails.SoW
            id={drawer?.swId}
            docSignerId={drawer?.docSignerId}
            onActions={{
              onSign: () => reloadPage(),
              onReject: () => reloadPage(),
              onClickTask: (taskId) => handleToggleDrawer('view-task', true, { taskId })(),
              onViewSOP: () => handleToggleDrawer(false)(),
              onCreateDocument: ({ uploadFile }) => reloadPage(uploadFile),
              onDeleteDocument: ({ uploadFile }) => reloadPage(uploadFile),
            }}
          />
        </SidePanelV2>
      </React.Fragment>
    </>
  )
}

/** TODO: This code is repeated in multiple places */
const StyledChip = styled(Chip)`
  font-family: 'Inter', 'Noto Sans Thai';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 150% */
  color: var(--Gray-700);

  display: flex;
  padding: 0.1875rem 0.5rem;
  justify-content: center;
  align-items: center;

  border-radius: 1rem;
  border: 1px solid var(--Gray-300);
  background: var(--Gray-50);

  & .MuiChip-icon {
    margin-left: 0;
    margin-right: 0.5rem;
  }

  & .MuiChip-label {
    padding: 0;
  }

  &.MuiChip-colorSuccess,
  &.MuiChip-colorInfo,
  &.MuiChip-colorWarning,
  &.MuiChip-colorDefault {
    /* border-radius: 1rem !important; */
  }

  &.MuiChip-colorSuccess {
    color: var(--Success-700, #067647);
    border: 1px solid var(--Success-200, #abefc6) !important;
    background: var(--Success-50, #ecfdf3) !important;
  }

  &.MuiChip-colorWarning {
    color: var(--Warning-700, #b54708);
    border: 1px solid var(--Warning-200, #fedf89);
    background: var(--Warning-50, #fffaeb);
  }

  &.MuiChip-colorInfo {
    color: var(--Blue-700, #175cd3);
    border: 1px solid var(--Blue-200, #b2ddff) !important;
    background: var(--Blue-50, #eff8ff) !important;
  }

  &.MuiChip-colorError {
    color: var(--Error-700, #d32f2f);
    border: 1px solid var(--Error-200, #ffcdd2);
    background: var(--Error-50, #ffebee);
  }
`

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid var(--Gray-200)`,
  borderBottom: 0,
  borderLeft: 0,
  borderRight: 0,
  color: 'inherit',

  '&:last-child': {
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
  },

  '&::before': {
    display: 'none',
  },
}))
