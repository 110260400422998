import styled from 'styled-components'
import * as React from 'react'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateDisplay,GetPermissionOther } from '../../utils/common'

import { getDailySummary, updateDailySummary } from '../../redux/actions/dailysummary'
import { getEmployeeDropdrow } from '../../redux/actions/master'

import { selectDailySummary,selectMaster } from '../../redux/selectors'

import SearchText from '../../components/form/SearchText'
import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import Button from '../../components/form/button/Button'
import TableV2 from '../../components/common/TableV2'
import AutoComplete from '../../components/form/AutoComplete'

import SidePanel from '../../components/common/SidePanel'
import NewDailySummary from '../../pages/my-calendar/NewDailySummary'
import EditDailySummary from '../../pages/my-calendar/EditDailySummary'
import ViewDailySummary from '../../pages/my-calendar/ViewDailySummary'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import Date from '../../components/form/Date'
import { LiaTimesSolid } from 'react-icons/lia'

import { useTranslation } from 'react-i18next'

import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import { log_ } from '../../utils/apiLog'

const DivMyCalendar = styled.div`
  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .border_header{
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .content-fillter-bg{
    min-height: 68px;
    background-color: var(--Gray-50);
    border-radius: 8px;
    margin-bottom: 21px;
    padding: 10px;
  }
  .content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
  
    .search-wrapper {
      margin-right: 12px;
    }
  
    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }
  
    .group_filter {
      display: flex;
      align-items: center;
    }
  }
  .container {
    position: relative;
    display: inline-block;
  }

  .content-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    .search-wrapper {
      margin-right: 12px;
    }

    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }

    .group_filter {
      display: flex;
      align-items: center;
    }
  }
  .box_search {
    margin-top: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #EAECF0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #FFFFFF;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .cursor_pointer {
    cursor: pointer;
  }
  .col-no{
    font-size:14px;
    width:6%;
  }
  .col-Report_Date{
    font-size:14px;
    width:12%;
  }
  .col-what_done_today{
    font-size:14px;
    width:50%;
  }
  .col-created_by{
    font-size:14px;
    width:20%;
  }
  .col-buttonDelete{
    font-size:14px;
    width:6%;
  }
  .col-linkEdit{
    font-size:14px;
    width:6%;
  }
  .button_clear_all{
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
  }
  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 10px;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .text-bold {
    font-weight: bold;
  }
  
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .css_text_not_employee{
    color: red;
  }
  .css_autocom{
    min-width: 250px;
    margin-right: 10px;
  }
`

const DailySummaryReport = () => {
  const initLang = localStorage.getItem('lang')
  //--- ST ส่วนของ Permission LV3-4
  const permissionDel = GetPermissionOther('daily_summary_report', 'delete')
  //--- ST ส่วนของ Permission LV3-4

  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [currentDate, setcurrentDate] = useState(null);
  const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
  const [EmployeeIdCalendar, setEmployeeIdCalendar] = useState(employee_info?.id ? employee_info?.id : -99)
  // ตัวแปรจาก STORE และตัวที่เก็บข้อมูลจากการเรียก API

  useEffect(() => {
    const filter = {
      skip: 1,
      limit: 10000
    }
    dispatch(getEmployeeDropdrow(filter))
  }, [dispatch])
  const { employeeList } = useSelector(selectMaster)
  const [employeeOption, setemployeeOption] = useState([])
  const [employee, setemployee] = useState('')

  useEffect(() => {
    const newEmployee = employeeList.map((v) => ({
      text: v?.first_name + ' ' + v?.last_name,
      value: v?.id,
    }))
    setemployeeOption([...newEmployee])
  }, [employeeList])

  const { DailySummaryList, limit, total, skip, isLoading } = useSelector(selectDailySummary)

  // เรียกใช้งาน API ผ่าน dispatch เช่น dispatch(getEmployee(filter))
  const fetchData = useCallback(
    async (filter) => {
      dispatch(getDailySummary(filter))
    },
    [dispatch]
  )

  const [search, setSearch] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  const onSearchEmployee = (v) => {
    setemployee(v)
    const filter = {
      skip: 1,
      limit: limit,
      what_done_today:searchFilters.map((v) => v.name).join(','),
      filter_date : currentDate,
      EmployeeId : v
    }
    fetchData(filter)
    setPage(1)
  }

  const onSearch = () => {
    const newFilter = {
      key: uuidv4(),
      name: search,
    }
    const filter = {
      skip: 1,
      limit: limit,
      what_done_today:[...searchFilters, newFilter].map((v) => v.name).join(','),
      filter_date : currentDate,
      EmployeeId : employee
    }
    
    fetchData(filter)
    setPage(1)
    setSearchFilters((prev) => [...prev, newFilter])
  }
  const onClearAllSearch = () => {
    setSearchFilters([])
    setemployee('')
    const filter = {
      skip: 1,
      limit: limit,
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
  }
  const onClearSearch = () => {
    const filter = {
      skip: 1,
      limit: limit,
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
  }

  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    
    const filter = {
      skip: 1,
      limit: limit,
      what_done_today: newSearchFilters.map((v) => v.name).join(','),
      filter_date : currentDate,
      EmployeeId : employee
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
    
  }

  // Function การใช้งานอื่นๆ
  const onChangePagination = async (value) => {
    const filter = {
      skip: value.page,
      limit: limit,
      what_done_today:searchFilters.map((v) => v.name).join(','),
      filter_date : currentDate,
      EmployeeId : employee
    }
    fetchData(filter)
    setPage(value.page)
  }

  const onChangecurrentDate = (value) => {
    setcurrentDate(value)
    const filter = {
      skip: 1,
      limit: limit,
      what_done_today:searchFilters.map((v) => v.name).join(','),
      filter_date : value,
      EmployeeId : employee
    }
    
    fetchData(filter)
    setPage(1)
  }

  useEffect(() => {
    const filter = {
      skip: 1,
      limit: 10,
    }
    fetchData(filter)
  }, [fetchData, limit])

  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('feature_daily_summary_report_report_date_column_header'),
      accessor: 'Report_Date',
      disableSortBy: false,
    },
    {
      Header: t('feature_daily_summary_report_what_done_today_column_header'),
      accessor: 'what_done_today',
      disableSortBy: false,
    },
    {
      Header: t('created_by'),
      accessor: 'created_by',
      disableSortBy: false,
    },
    {
      accessor: 'buttonDelete',
      disableSortBy: true,
    },
    {
      accessor: 'linkEdit',
      disableSortBy: true,
    },
  ]

  // bind ข้อมูลที่ได้จาก API โดยรับมาจาก ตัวแปรของ Store ที่ประกาศไว้
  useEffect(() => {
    const newTableCellData = DailySummaryList.map((v, index) => ({
      ...v,
      no: index + 1,
      what_done_today : (
        <div className="text-bold link" onClick={() => OnModalViewDailySummary(v.id)}>
          {v.what_done_today ? v.what_done_today.length > 100 ? v.what_done_today.substring(0,100) + '...' : v.what_done_today : '-'}
        </div>
      ),
      created_by : v.create_by ? v.create_by : '-',
      Report_Date: DateDisplay(v.dailysummary_date_start),
      buttonDelete: permissionDel?.canRead === true ? <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(v.id)}  /> : '-',
      linkEdit: <img className="cursor_pointer" src={iconEdit} title="Edit" onClick={() => EditData(v.id)} />,
    }))
    setTableCellData(newTableCellData)
  }, [DailySummaryList])

  const [TypeDel, setTypeDel] = useState('Daily Summary Report')
  const [ThisId, setThisId] = useState('')
  const [ModalConfirmDel, setModalConfirmDel] = useState(false)
  const [ModalEditData, setModalEditData] = useState(false)
  const EditData = (data) => {
    //alert(data)
    setThisId(data)
    setModalEditData(true)
  }
  const DelData = (data) => {
    //alert(data)
    if(permissionDel?.canUpdate === true){
      setThisId(data)
      setModalConfirmDel(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionDel?.name_en;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionDel?.name_th;
      }
      setTitleFail(message_permission);
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }
    
  }
  const CloseModalConfirmDel = () => {
    setModalConfirmDel(false)
  }
  const SubmitModalConfirmDel = () => {
    const sent_data_api = {
      data: { active: 0 },
    }
    dispatch(updateDailySummary(ThisId, sent_data_api))
    setModalConfirmDel(false)
  }

  const SubmitModalEditData = () => {
    log_("MyCalendar","edit DailySummaryReport","click","{id:"+ThisId+"}");
    setModalEditData(false)
  }

  const CloseModalEditData = () => {
    setModalEditData(false)
  }

  useEffect(() => {
    const totalPage = Math.ceil(total / limit)
    setTotalPages(totalPage)
  }, [total, skip, limit])



  const [ModalCreate, setModalCreate] = useState(false)
  const OpenCreateModal = () => {
    log_("MyCalendar","create DailySummaryReport","click","");
    setModalCreate(true)
  }

  const CloseModalCreate = () => {
    log_("MyCalendar","cancel DailySummaryReport","click","");
    setModalCreate(false)
  }

  const SubmitModalCreate = () => {
    log_("MyCalendar","submit DailySummaryReport","click","");
    onClearAllSearch()
    setModalCreate(false)
  }

  const [DailySummaryId, setDailySummaryId] = useState('')
  const [ModalViewDailySummary, setModalViewDailySummary] = useState(false)
  const OnModalViewDailySummary = (id) => {
    setDailySummaryId(id)
    log_("MyCalendar","view DailySummaryReport","click","{id:"+id+"}");
    setModalViewDailySummary(true)
  }
  const CloseModalViewDailySummary = () => {
    setModalViewDailySummary(false)
  }

  

  return (
    <DivMyCalendar>
      
        <div className="content-title">{t('daily_summary_report')}</div>
        <div className="">{t('feature_daily_summary_report_sub_header')}</div>
        <div className="border_header"></div>
        <div className="content-fillter-bg">
          <div className="content-fillter">
            <div className="group_filter">
              <div className="css_autocom">
                  <AutoComplete
                      placeHolder='Created by'
                      optionList={employeeOption}
                      value={employee}         
                      onChange={onSearchEmployee}                     
                    />
              </div>
              <div>
                <SearchText
                  className="search-wrapper"
                  placeholder={t('search')}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  onSubmit={onSearch}
                  onClear={onClearSearch}
                />
              </div>
              <div>
                <Date className={'mr-1-rem'} value={currentDate} onChange={onChangecurrentDate} />
              </div>
              <div>
                <Button onClick={onClearAllSearch} className="button_clear_all mr-1-rem">
                  {t('clear_all')}
                </Button>
              </div>
              
            </div>

            <div className="container">
              {EmployeeIdCalendar > 0 && (
                <Button append={<img src={iconPlus} />} onClick={OpenCreateModal}>
                  {t('create')}
                </Button>
              )}
              {EmployeeIdCalendar < 0 && (
                <div className="css_text_not_employee">{t('you_may_not_fully_use_this_feature_since_you_are_not_employee')}</div>
              )}
            </div>

          </div>
          <div className="badge-search-content">
                {searchFilters.map((v) => (
                  <div className="badge" key={v.key}>
                    <span className="name">{v.name}</span>{' '}
                    <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
                  </div>
                ))}
              </div>
        </div>


      <div className="table-wrapper">
        <div className="table-header">
          <div className="group">
            <div className="table-title">{t('feature_daily_summary_report_total')}</div>
            <div className="table-total">{total} {t('items')}</div>
          </div>
        </div>
        <TableV2
          columns={TableColumn}
          data={TableCellData}
          onStateChange={onChangePagination}
          pageCount={totalPages}
          page={page}
          loading={isLoading}
        />
      </div>

      <SidePanel isOpen={ModalCreate} setIsOpen={setModalCreate} width={400}>
        <NewDailySummary onClose={CloseModalCreate} onSubmit={SubmitModalCreate} />
      </SidePanel>
      <SidePanel isOpen={ModalEditData} setIsOpen={setModalEditData} width={400}>
        <EditDailySummary onClose={CloseModalEditData} onSubmit={SubmitModalEditData} DailySummaryId={ThisId} />
      </SidePanel>
      <SidePanel isOpen={ModalViewDailySummary} setIsOpen={setModalViewDailySummary} width={400}>
          <ViewDailySummary
            onClose={CloseModalViewDailySummary}
            onSubmit={EditData}
            DailySummaryId={DailySummaryId}
          />
        </SidePanel>

        <DialogConfirmDelete
          open={Boolean(ModalConfirmDel)}
          onClose={() => setModalConfirmDel(false)}
          onSubmit={SubmitModalConfirmDel}
          title={t('confirm_delete_daily_summary_report_header')}
          nameItem=""
          description=""
        />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />
    </DivMyCalendar>
  )
}

export default DailySummaryReport
