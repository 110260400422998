import { Typography as MuiTypography } from '@mui/material'
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles'

const theme = createTheme({
  fontFamily: "'Inter', 'Noto Sans Thai' !important",
  typography: {
    // @ts-ignore
    tableTitle: {
      fontSize: '18px',
      lineHeight: '28px',
      color: 'var(--Gray-900)',
      fontWeight: 600,
    },
    tableSubtitle: {
      fontSize: '14px',
      lineHeight: '20px',
      color: 'var(--Gray-600)',
      fontWeight: 400,
    },
    tableLink: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 600,
      color: 'var(--Primary-700)',
      cursor: 'pointer',

      '&:hover': {
        textDecoration: 'underline',
      },
    },
    cardTitle: {
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--Gray-900)',
      fontWeight: 600,
    },
    sectionTitle: {
      fontSize: '16px',
      lineHeight: '24px',
      color: 'var(--Gray-900)',
      fontWeight: 600,
    },
    sectionText: {
      fontSize: '14px',
      lineHeight: '20px',
      color: 'var(--Gray-600)',
      fontWeight: 400,
    },
    sidePanelTitle: {
      fontSize: '18px',
      lineHeight: '28px',
      color: 'var(--Gray-900)',
      fontWeight: 600,
    },
    sidePanelSubtitle: {
      fontSize: '14px',
      lineHeight: '20px',
      color: 'var(--Gray-600)',
      fontWeight: 400,
    },
    emptyTitle: {
      fontSize: '18px',
      lineHeight: '28px',
      color: 'var(--Gray-900)',
      fontWeight: 600,
    },
    emptyText: {
      fontSize: '14px',
      lineHeight: '20px',
      color: 'var(--Gray-600)',
      fontWeight: 400,
      textAlign: 'center',
    },
    fieldLabel: {
      fontSize: '14px',
      lineHeight: '20px',
      color: 'var(--Gray-700)',
      fontWeight: 600,
    },
    fieldSubtitle: {
      fontSize: '14px',
      lineHeight: '20px',
      color: 'var(--Gray-600)',
      fontWeight: 400,
    },
  },
})

const Typography = ({ variant, children, ...props }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <MuiTypography variant={variant} {...props}>
        {children}
      </MuiTypography>
    </MuiThemeProvider>
  )
}

export default Typography
