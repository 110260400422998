import styled from 'styled-components'
import CircularProgress from '@mui/material/CircularProgress'

import PropTypes from 'prop-types'

const StyleCircularProgress = styled(CircularProgress)`
  svg circle {
    stroke: var(--Primary-700);
  }
`

const Loading = ({ size = 40, ...props }) => {
  return <StyleCircularProgress size={size} {...props} />
}

Loading.propTypes = {
  size: PropTypes.number,
}

export default Loading
