import React, { useState, useEffect, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { formatNumber } from 'utils/common'

import { useDispatch, useSelector } from 'react-redux'
import { selectDynamicForm } from 'redux/selectors'
import {
  getDynamicDropdownOptions,
  getDynamicDropdownOptionsWithSpecificId,
  getEditSchema,
  updateDynamicForm,
  clearEditSchema,
} from 'redux/actions/dynamicForm'

import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import Button from 'components/form/button/Button'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Divider from 'components/common/Divider'
import DynamicForm from 'components/dynamic-form/DynamicForm'

import { LiaTimesSolid } from 'react-icons/lia'

const Div = styled.div`
  position: relative;
  padding: 24px 24px 0;

  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

  .error {
    color: var(--Error-600);
  }

  .highlight-text {
    color: var(--Primary-600);
    font-weight: 700;
  }

  .side-panel-heading {
    color: var(--Gray-900);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
  }
  .side-panel-sub-heading {
    color: var(--Gray-600);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
  }

  .icon {
    cursor: pointer;
  }

  .input-heading {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    margin-bottom: 6px;
  }

  .footer-side-panel {
    background-color: var(--Base-White);

    position: sticky;
    bottom: 0;
    width: 100%;
    padding-bottom: 16px;
  }
`

function EditDynamicContent({ id, name, onClose }) {
  // external hook
  const dispatch = useDispatch()

  // initiate data
  const fetchEditSchema = useCallback(
    (id, name) => {
      dispatch(getEditSchema(id, name))
    },
    [dispatch]
  )

  useEffect(() => {
    fetchEditSchema(id, name)
  }, [id, name])

  // intiate form
  const { editSchema } = useSelector(selectDynamicForm)
  const [formFields, setFormFields] = useState()
  const { control, setValue, handleSubmit, reset } = useForm({
    defaultValues: {},
  })

  // submit function
  const onSubmit = (data) => {
    const { endpoint } = editSchema?.submit_button
    const mapNewData = Object.entries(data)
      .map(([key, value]) => ({
        key,
        value,
      }))
      .reduce((acc, cur) => {
        const { key, value } = cur
        const newValue =
          value === ''
            ? null
            : formFields[key]?.type === 'text-field-integer' || formFields[key]?.type === 'text-field-decimal'
            ? parseFloat(String(value)?.replace(/,/g, ''))
            : formFields[key]?.type === 'upload-single'
            ? value?.id
            : value
        acc[key] = newValue

        return acc
      }, {})

    const request = {
      data: mapNewData,
    }
    dispatch(updateDynamicForm(id, request, endpoint))
    close()
  }
  const onError = (errors) => {
    console.log('errors:', errors)
  }

  // fetch options data
  const fetchDropdownOptions = async (endpoint) => {
    const result = await dispatch(getDynamicDropdownOptions(endpoint))
    return result
  }
  const fetchDropdownOptionsSpecific = async (id, endpoint) => {
    const result = await dispatch(getDynamicDropdownOptionsWithSpecificId(id, endpoint))
    return result
  }
  const initiateDropdownApi = async (initialForms, formFields) => {
    const allDropdownAPI = Object.entries(formFields)
      .filter(([_, value]) => {
        return value.type === 'dropdown-api'
      })
      .map(([key, value]) => ({
        key,
        value,
      }))

    if (allDropdownAPI.length > 0) {
      const options = {}
      for (let item of allDropdownAPI) {
        const { key, value } = item
        const { endpoint, ref_value, ref_generate_number_array } = value

        const isSpecific = endpoint.includes(':id')
        if (isSpecific) {
          const id = initialForms[ref_value]
          const newDropdownOptions = await fetchDropdownOptionsSpecific(id, endpoint)
          options[key] = newDropdownOptions

          if (ref_generate_number_array) {
            const matchedOption = newDropdownOptions.find((v) => v.value === initialForms[key])

            if (matchedOption) {
              for (let ref of ref_generate_number_array) {
                const rangeValue = matchedOption[ref]

                const newOptions = Array(rangeValue)
                  .fill()
                  .map((_, index) => ({
                    value: index + 1,
                    text: `${index + 1}`,
                  }))
                options[ref] = newOptions
              }
            }
          }
        } else {
          const newDropdownOptions = await fetchDropdownOptions(endpoint)
          options[key] = newDropdownOptions
        }
      }

      const changedFormField = {}
      for (let [key, value] of Object.entries(options)) {
        changedFormField[key] = {
          ...formFields[key],
          attribute: {
            ...formFields[key].attribute,
            options: value,
          },
        }
      }

      const initialFormFields = {
        ...editSchema?.properties,
        ...changedFormField,
      }
      setFormFields(initialFormFields)
    } else {
      setFormFields(formFields)
    }
  }

  // sew new options and reset value for dropdown ref
  const setNewFormField = async (key, value, ref_fetch, reset_value, ref_generate_number_array) => {
    // set new value for ref key
    for (let item of reset_value) {
      const { key, refKey } = item

      if (refKey === null) setValue(key, '')
      else {
        const { key_ref, key_value } = refKey
        const { options } = formFields[key_ref]?.attribute
        const matchedValue = options.find((v) => v.value === value)

        setValue(key, matchedValue ? matchedValue[key_value] : '')
      }
    }
    // fetch new options for ref key
    if (ref_fetch) {
      const { endpoint } = formFields[ref_fetch]
      const newOptions = await fetchDropdownOptionsSpecific(value, endpoint)

      setNewOptionsForFormFields(ref_fetch, newOptions)
    }
    // generated options
    if (ref_generate_number_array) {
      const { options } = formFields[key]?.attribute
      const matchedOption = options.find((v) => v.value === value)
      if (matchedOption) {
        for (let ref of ref_generate_number_array) {
          const rangeValue = matchedOption[ref]

          const newOptions = Array(rangeValue)
            .fill()
            .map((_, index) => ({
              value: index + 1,
              text: `${index + 1}`,
            }))
          setNewOptionsForFormFields(ref, newOptions)
        }
      }
    }
  }
  const setNewOptionsForFormFields = async (key, newOptions) => {
    setFormFields((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        attribute: {
          ...prev[key]?.attribute,
          options: newOptions,
        },
      },
    }))
  }

  useEffect(() => {
    if (Object.keys(editSchema).length > 0) {
      const initialForm = Object.keys(editSchema?.properties).reduce((acc, key) => {
        acc[key] =
          editSchema?.data_value[key] &&
          (editSchema?.properties[key]?.type === 'text-field-integer' ||
            editSchema?.properties[key]?.type === 'text-field-decimal')
            ? formatNumber(editSchema?.data_value[key])
            : editSchema?.properties[key]?.type === 'upload-single'
            ? {
                id: editSchema?.data_value[key]?.id,
                fileName: `${editSchema?.data_value[key]?.name}${editSchema?.data_value[key]?.ext}`,
                url: editSchema?.data_value[key]?.url,
              }
            : editSchema?.data_value[key]
            ? editSchema?.data_value[key]
            : ''
        return acc
      }, {})

      initiateDropdownApi(initialForm, editSchema?.properties).then((_) => {
        reset(initialForm)
      })
    }
  }, [editSchema, reset])

  // upload single file
  const setAttachFile = (file, onChange) => {
    if (file && file !== '' && typeof file === 'object') {
      const { id, name, ext, url } = file
      const uploadedFile = {
        id,
        fileName: `${name}${ext}`,
        url,
      }

      onChange(uploadedFile)
    } else onChange('')
  }

  const close = () => {
    dispatch(clearEditSchema())
    onClose()
  }

  return (
    <Div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <div className="side-panel-heading">{editSchema?.title?.replace('{name}', editSchema?.data_value?.name)}</div>
          <div className="side-panel-sub-heading">
            {editSchema?.description?.replace('{code}', editSchema?.data_value?.code)}
          </div>
        </Box>

        <LiaTimesSolid className="icon" size={18} onClick={close} />
      </Stack>

      <Box sx={{ my: 2 }}>
        <Divider />
      </Box>

      <form onSubmit={handleSubmit(onSubmit, onError)}>
        {formFields && (
          <DynamicForm
            formFields={formFields}
            control={control}
            setNewFormField={setNewFormField}
            setAttachFile={setAttachFile}
          />
        )}

        <Box className="footer-side-panel">
          <Box sx={{ mb: 2 }}>
            <Divider />
          </Box>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <ButtonOutline onClick={close}>{editSchema?.cancel_button?.text}</ButtonOutline>
            <Button type="submit">{editSchema?.submit_button?.text}</Button>
          </Stack>
        </Box>
      </form>
    </Div>
  )
}

export default EditDynamicContent
