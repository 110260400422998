import ChequePaymentJournalDocument from 'pages/document/purchases/documents/ChequePaymentJournalDocument'
import PaymentJournalDocument from 'pages/document/purchases/documents/PaymentJournalDocument'
import PettyCashVoucherDocument from 'pages/document/purchases/documents/PettyCashVoucherDocument'
import PurchaseDocument from 'pages/document/purchases/documents/PurchaseDocument'
import PurchaseRequestDocument from 'pages/document/purchases/documents/PurchaseRequestDocument'
import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ComparisonScheduleDocument from './purchases/documents/ComparisonScheduleDocument'
import BillingDocument from './sales/documents/BillingDocument'
import DebitNoteDocument from './sales/documents/DebitNoteDocument'
import InvoiceDocument from './sales/documents/InvoiceDocument'
import PledgeProductCertificationRequestDocument from './sales/documents/PledgeProductCertificationRequestDocument'
import QuotationDocument from './sales/documents/QuotationDocument'
import ReceiptDocument from './sales/documents/ReceiptDocument'

export default function DialogDocument({
  onClose,
  docType,
  previewType,
  docId,
  id,
  onDownloaded,
  data,
  linkImage,
  textCondition,
}) {
  const handleClickCancel = () => {
    if (onClose) {
      onClose()
    }
  }
  const handleClickBtnPrint = () => {
    window.print()
  }

  return (
    <Styled>
      {previewType == 'download_pdf' ? (
        <>
          <div className="wrp-download-status">
            <span>Downloading...</span>
          </div>
          {docType == 'quotation' && (
            <QuotationDocument
              onClose={handleClickCancel}
              type={previewType}
              id={id}
              docId={docId}
              onDownloaded={onDownloaded}
              renderData={data}
              linkImage={linkImage}
            />
          )}
          {docType == 'billing' && (
            <BillingDocument
              onClose={handleClickCancel}
              type={previewType}
              id={id}
              docId={docId}
              onDownloaded={onDownloaded}
              renderData={data}
              linkImage={linkImage}
            />
          )}
          {docType == 'invoice' && (
            <InvoiceDocument
              onClose={handleClickCancel}
              type={previewType}
              id={id}
              docId={docId}
              onDownloaded={onDownloaded}
              renderData={data}
              linkImage={linkImage}
            />
          )}
          {docType == 'receipt' && (
            <ReceiptDocument
              onClose={handleClickCancel}
              type={previewType}
              id={id}
              docId={docId}
              onDownloaded={onDownloaded}
              renderData={data}
              linkImage={linkImage}
            />
          )}
          {docType == 'debit_note' && (
            <DebitNoteDocument
              onClose={handleClickCancel}
              type={previewType}
              id={id}
              docId={docId}
              onDownloaded={onDownloaded}
              renderData={data}
              linkImage={linkImage}
            />
          )}
          {docType == 'pledge_product_certification_request' && (
            <PledgeProductCertificationRequestDocument
              onClose={handleClickCancel}
              type={previewType}
              id={id}
              docId={docId}
              onDownloaded={onDownloaded}
              renderData={data}
              linkImage={linkImage}
            />
          )}

          {/* PURCHASE */}
          {docType == 'purchase_order' && (
            <PurchaseDocument
              onClose={handleClickCancel}
              type={previewType}
              id={id}
              docId={docId}
              onDownloaded={onDownloaded}
              renderData={data}
              textCondition={textCondition}
              linkImage={linkImage}
            />
          )}
          {docType == 'cheque_payment_journal' && (
            <ChequePaymentJournalDocument
              onClose={handleClickCancel}
              type={previewType}
              id={id}
              docId={docId}
              onDownloaded={onDownloaded}
              renderData={data}
              textCondition={textCondition}
              linkImage={linkImage}
            />
          )}
          {docType == 'payment_journal' && (
            <PaymentJournalDocument
              onClose={handleClickCancel}
              type={previewType}
              id={id}
              docId={docId}
              onDownloaded={onDownloaded}
              renderData={data}
              textCondition={textCondition}
              linkImage={linkImage}
            />
          )}
          {docType == 'petty_cash_voucher' && (
            <PettyCashVoucherDocument
              onClose={handleClickCancel}
              type={previewType}
              id={id}
              docId={docId}
              onDownloaded={onDownloaded}
              renderData={data}
              textCondition={textCondition}
              linkImage={linkImage}
            />
          )}
          {docType == 'purchase_request' && (
            <PurchaseRequestDocument
              onClose={handleClickCancel}
              type={previewType}
              id={id}
              docId={docId}
              onDownloaded={onDownloaded}
              renderData={data}
              textCondition={textCondition}
              linkImage={linkImage}
            />
          )}
          {docType == 'comparison_schedule' && (
            <ComparisonScheduleDocument
              onClose={handleClickCancel}
              type={previewType}
              id={id}
              docId={docId}
              onDownloaded={onDownloaded}
              renderData={data}
              textCondition={textCondition}
              linkImage={linkImage}
            />
          )}
        </>
      ) : (
        <div className="body">
          <div className="inner-body">
            {/* SALES */}
            {docType == 'quotation' && (
              <QuotationDocument
                onClose={handleClickCancel}
                type={previewType}
                id={id}
                onDownloaded={onDownloaded}
                renderData={data}
                linkImage={linkImage}
              />
            )}
            {docType == 'billing' && (
              <BillingDocument
                onClose={handleClickCancel}
                type={previewType}
                id={id}
                onDownloaded={onDownloaded}
                renderData={data}
                linkImage={linkImage}
              />
            )}
            {docType == 'invoice' && (
              <InvoiceDocument
                onClose={handleClickCancel}
                type={previewType}
                id={id}
                onDownloaded={onDownloaded}
                renderData={data}
                linkImage={linkImage}
              />
            )}
            {docType == 'receipt' && (
              <ReceiptDocument
                onClose={handleClickCancel}
                type={previewType}
                id={id}
                onDownloaded={onDownloaded}
                renderData={data}
                linkImage={linkImage}
              />
            )}
            {docType == 'debit_note' && (
              <DebitNoteDocument
                onClose={handleClickCancel}
                type={previewType}
                id={id}
                onDownloaded={onDownloaded}
                renderData={data}
                linkImage={linkImage}
              />
            )}
            {docType == 'pledge_product_certification_request' && (
              <PledgeProductCertificationRequestDocument
                onClose={handleClickCancel}
                type={previewType}
                id={id}
                onDownloaded={onDownloaded}
                renderData={data}
                linkImage={linkImage}
              />
            )}

            {/* PUSCHASE */}
            {docType == 'purchase_order' && (
              <PurchaseDocument
                onClose={handleClickCancel}
                type={previewType}
                id={id}
                onDownloaded={onDownloaded}
                renderData={data}
                textCondition={textCondition}
                linkImage={linkImage}
              />
            )}
            {docType == 'cheque_payment_journal' && (
              <ChequePaymentJournalDocument
                onClose={handleClickCancel}
                type={previewType}
                id={id}
                onDownloaded={onDownloaded}
                renderData={data}
                textCondition={textCondition}
                linkImage={linkImage}
              />
            )}
            {docType == 'payment_journal' && (
              <PaymentJournalDocument
                onClose={handleClickCancel}
                type={previewType}
                id={id}
                onDownloaded={onDownloaded}
                renderData={data}
                textCondition={textCondition}
                linkImage={linkImage}
              />
            )}
            {docType == 'petty_cash_voucher' && (
              <PettyCashVoucherDocument
                onClose={handleClickCancel}
                type={previewType}
                id={id}
                onDownloaded={onDownloaded}
                renderData={data}
                textCondition={textCondition}
                linkImage={linkImage}
              />
            )}
            {docType == 'purchase_request' && (
              <PurchaseRequestDocument
                onClose={handleClickCancel}
                type={previewType}
                id={id}
                onDownloaded={onDownloaded}
                renderData={data}
                textCondition={textCondition}
                linkImage={linkImage}
              />
            )}
            {docType == 'comparison_schedule' && (
              <ComparisonScheduleDocument
                onClose={handleClickCancel}
                type={previewType}
                id={id}
                onDownloaded={onDownloaded}
                renderData={data}
                textCondition={textCondition}
                linkImage={linkImage}
              />
            )}
          </div>
        </div>
      )}

      {previewType == 'print' && (
        <div className="footer">
          <ButtonOutline onClick={handleClickCancel}>Cancel</ButtonOutline>
          <Button className={'btn-print'} onClick={handleClickBtnPrint}>
            Print
          </Button>
        </div>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  z-index: 999;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
    position: relative;
    display: flex;
    overflow-y: auto;

    .inner-body {
      margin: 0 auto;
      /* background-color: #fff; */
      display: flex;
      flex-direction: column;
    }
  }

  .wrp-download-status {
    background-color: #ffffff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    border-top: 1px solid var(--Gray-200);
    padding: 16px 24px;
    .btn-print {
      margin-left: 12px;
    }
  }
`
