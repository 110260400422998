import React from 'react';
import { Box, Typography, Button, Avatar, AvatarGroup } from '@mui/material';
import { Videocam as IoVideocam } from '@mui/icons-material';

const TodayCard = ({ users }) => {
  return (
    
      <Box sx={{ display: 'flex', flexDirection: 'column'}}>
        <Typography variant="h6" component="div" sx={{ m: 1 }}>
          TODAY 16 Jan 2024
        </Typography>
        {users.map((item, i) => (
          <Box key={i} sx={{ p: 2 }}>
            <Typography variant="body2" color="text.secondary">
              8:30 - 9:30 AM
            </Typography>
            <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
              [Interview] Amy Adamson - Software Engineer
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Acme Worldwide HQ-1-Acme(20)
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, gap: 1 }}>
              <Button color="secondary" variant="contained" startIcon={<IoVideocam />}>
                Join
              </Button>
              <AvatarGroup total={9}>
                <Avatar alt="Remy Sharp" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                <Avatar alt="Travis Howard" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                <Avatar alt="Agnes Walker" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
                <Avatar alt="Trevor Henderson" src="https://png.pngtree.com/png-vector/20220709/ourmid/pngtree-businessman-user-avatar-wearing-suit-with-red-tie-png-image_5809521.png" />
              </AvatarGroup>
            </Box>
            <Box sx={{ width: '100%', my: 1, borderBottom: 1, borderColor: 'divider' }}></Box>
          </Box>
        ))}
      </Box>
  );
};

export default TodayCard;
