import styled from 'styled-components'
import iconClose from '../../assets/images/icon-close.svg'
import IconButton from '../../components/common/IconButton'
import Modal from '../../components/common/Modal'
import Button from '../../components/form/button/Button'

import Dropdown from '../../components/form/Dropdown'
import RadioButton from '../../components/form/RadioButton'

import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/selectors'
import { useTranslation } from 'react-i18next'
import TextField from '../../components/form/TextField'
import PasswordField from '../../components/form/PasswordInput'
import ButtonOutline from '../../components/form/button/ButtonOutline'

import { FiCopy } from 'react-icons/fi'

const Div = styled.div`
  position: relative;
  padding: 24px;
  width: 100%;
  max-width: 400px;

  p {
    margin: 0;
  }

  .dialog-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    img {
      margin-right: 16px;
      margin-bottom: 8px;
    }

    .content {
      flex: 1;
      min-width: 270px;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
      text-align: center;
    }
    .name-item {
      color: var(--gray-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      text-align: center;
      /*margin-bottom: 1rem;*/
    }
    .description {
      font-size: 14px;
      color: var(--Gray-600);
      text-align: center;
    }
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .close-modal-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .icon-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .bt-close {
    img {
      width: 16px;
      height: 16px;
    }
  }
  .topic_fill {
    font-size: 16px;
    color: #212121;
  }

  .mb-24-px {
    margin-bottom: 24px;
  }

  .mb-48-px {
    margin-bottom: 48px;
  }
`
const FlexContainer = styled.div`
  display: flex;
  &.align-items-center {
    align-items: center;
  }
`

const initLangRadioOptionList = [
  {
    text: 'Email Invitation',
    value: 'email-invitation',
  },
  {
    text: 'Create password',
    value: 'create-password',
  },
]

const DialogAccessRole = ({
  open,
  onClose,
  icon,
  title,
  email,
  userName,
  nameItem,
  description,
  textYes,
  textNo,
  onSubmit,
  ...props
}) => {
  const errorMessage = ''
  const editable = true
  const [Access_Role, setAccess_Role] = useState('')
  const [Access_Role_option, setAccess_Role_option] = useState([])
  const { accessroleList } = useSelector(selectUser)
  const [typeCreation, setTypeCreation] = useState('email-invitation')
  const [password, setPassword] = useState('')

  const { t } = useTranslation()

  useEffect(() => {
    if (typeCreation === 'email-invitation') {
      setPassword('')
    } else {
      const generatedPassword = generateRandomString()
      setPassword(generatedPassword)
    }
  }, [typeCreation, open])

  useEffect(() => {
    const newaccessroleList = accessroleList?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setAccess_Role_option(newaccessroleList)
  }, [accessroleList])

  const generateRandomString = () => {
    const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz'
    const numbers = '0123456789'
    const specialChars = '!@#$%^&*()_+{}:"<>?|[];,./`~'

    const getRandomChar = (chars) => chars[Math.floor(Math.random() * chars.length)]

    const randomString = [
      getRandomChar(upperCaseChars),
      getRandomChar(lowerCaseChars),
      getRandomChar(numbers),
      getRandomChar(specialChars),
    ]

    const allChars = upperCaseChars + lowerCaseChars + numbers + specialChars
    while (randomString.length < 8) {
      randomString.push(getRandomChar(allChars))
    }

    for (let i = randomString.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[randomString[i], randomString[j]] = [randomString[j], randomString[i]]
    }

    return randomString.join('')
  }

  const onChangeAccess_Role = (v) => {
    setAccess_Role(v)
  }
  const onChangeRadio = (event) => {
    const { value } = event.target
    setTypeCreation(value)
  }
  const CreateAccess_Role = () => {
    onSubmit(Access_Role, typeCreation, password)
  }

  const copyPassword = () => {
    navigator.clipboard
      .writeText(password)
      .then(() => {
        alert('Text copied to clipboard')
      })
      .catch((err) => {
        alert('Failed to copy text')
      })
  }
  return (
    <Modal open={open} onClose={onClose} disableBackdropClick>
      <Div {...props}>
        <div className="close-modal-content">
          <IconButton className="bt-close" src={iconClose} onClick={onClose} />
        </div>
        <div className="icon-content">
          <img src={icon} />
        </div>
        <div className="dialog-content">
          <div className="content">
            <div className="title mb-48-px">{title}</div>
            {nameItem && <div className="name-item">{nameItem}</div>}
            <div className="description">{description}</div>
            <div className="description mb-24-px">Username: {userName === '' ? '-' : userName}</div>
            <div className="mb-24-px">
              <div className="topic_fill">Select how to create account</div>
              <RadioButton optionList={initLangRadioOptionList} value={typeCreation} handleChange={onChangeRadio} />
            </div>

            {typeCreation === 'create-password' && (
              <div className="mb-24-px">
                <div className="topic_fill">Password for 1st time log-in*</div>
                <FlexContainer className="align-items-center">
                  <PasswordField value={password} disabled={true} />
                  <ButtonOutline append={<FiCopy />} onClick={copyPassword}>
                    Copy
                  </ButtonOutline>
                </FlexContainer>
                <p>Pasword is autogenerated</p>
              </div>
            )}

            <div>
              <div className="topic_fill">{t('access_role')}*</div>
              <div>
                <Dropdown
                  id={`Access_Role`}
                  className="address_file_dd"
                  value={Access_Role}
                  optionList={Access_Role_option}
                  onChange={onChangeAccess_Role}
                  error={Boolean(errorMessage)}
                  disabled={!editable}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="dialog-button">
          <Button onClick={CreateAccess_Role}>{textYes || 'Yes'}</Button>
        </div>
      </Div>
    </Modal>
  )
}

export default DialogAccessRole
