import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DateDisplay, DateTimeHHmmssDisplay } from '../../utils/common'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { MdOutlineNotificationsActive } from 'react-icons/md'

import { getNotificationsReturnData, updateNotifications } from '../../redux/actions/notifications'
import { setNotiEmailDocId } from '../../redux/actions/shareServiceEmployee'

import styled from 'styled-components'

const Div = styled.div`
  width: ${(props) => props.width}px;
  position: fixed;
  z-index: 1;
  height: 100%;
  top: 0;
  left: 99px;
  overflow-x: hidden;
  transition: 0.5s;
  background-color: #ffffff;

  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css {
      color: #7f56d9;
      font-weight: bold;
    }
  }
  .content_header {
    padding: 0px 14px;
    padding-top: 14px;
    width: ${(props) => props.width}px;
    position: ${(props) => (props.width > 0 ? 'fixed' : 'relative')};
    transition: 0.5s;
    background: #ffffff;
    /*
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    z-index: 1;
    */
    /*left: 80px;*/
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    /*padding: 0px 14px;*/
    display: ${(props) => (props.width ? 'block' : 'none')};
    padding-top: 110px;
    padding-bottom: 10px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
    display: flex;
    align-items: center;
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width: ${(props) => props.width}px;
    background-color: #ffffff;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill {
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .box_content {
    cursor: pointer;
    border-bottom: 1px solid rgb(234, 236, 240);
    :hover {
      background-color: #f3f3f3;
    }
  }
  .content {
    margin: 0px 14px;
    padding: 10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .prefix_icon {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      border-bottom: 2px solid #eaecf0;
      color: #667085;
      font-size: 14px;
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      border-bottom: 2px solid var(--Primary-600) !important;
      color: #6941c6;
      background-color: #f9f5ff;
    }
  }
  .notification_row1 {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 2px 0px;
    .notification_row1_name {
      font-size: 14px;
      margin-right: 10px;
      font-weight: bold;
    }
    .notification_row1_message {
      font-size: 14px;
      margin-right: 10px;
    }
    .notification_row1_date {
      font-size: 12px;
      margin-right: 10px;
      font-weight: 600;
      &.send_task {
        color: #7f56d9;
      }
      &.close_task {
        color: #7f56d9;
      }
      &.want_approve {
        color: #7f56d9;
      }
      &.due_date {
        color: #d92d20;
      }
    }
  }
  .notification_row2 {
    display: grid;
    grid-template-columns: repeat(3, 75% [col-start] 15% [col-middle] 5% [col-end]);
    gap: 10px;
    padding: 2px 0px;
    .notification_row2_message {
      font-size: 14px;
    }
    .notification_row2_status {
      font-size: 12px;
      width: 67px;
      height: 22px;
      padding: 0.2rem;
      border: 1.5px solid var(--Gray-300);
      border-radius: 6px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      .status {
        font-size: 0.6rem;
        margin-right: 0.2rem;
        color: #17b26a;
        &.approve {
          color: green;
        }
        &.rejected {
          color: red;
        }
        &.due_date {
          color: yellow;
        }
      }
      &.approve {
        border: 1px solid #abefc6;
        border-radius: 16px;
        color: #067647;
        background-color: #ecfdf3;
      }
      &.rejected {
        border: 1px solid #fedf89;
        border-radius: 16px;
        color: #b54708;
        background-color: #fffaeb;
      }
      &.due_date {
        border: 1px solid #d92d20;
        border-radius: 16px;
        color: #b42318;
        background-color: #ffffff;
      }
    }
    .notification_row2_circle {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(219, 219, 219);
      vertical-align: middle;
    }
  }
  .notification_row3 {
    font-size: 14px;
    color: #344054;
  }

  @media screen and (max-width: 1023px) {
    padding-top: 64px;
    left: 0;
  }
`

const limit = 10
const NotificationSide = ({ isOpen, onClose, ...props }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const admin = JSON.parse(localStorage.getItem('admin'))

  const [tabactive, setTabactive] = useState('unread')
  const [menuTabList, setMenuTabList] = useState([
    {
      name: 'Unread',
      value: 'unread',
      active_status: true,
    },
    {
      name: 'Read',
      value: 'read',
      active_status: false,
    },
    {
      name: 'All',
      value: 'all',
      active_status: false,
    },
  ])

  const [notificationList, setNotificationList] = useState([])

  // for lazy loading
  const notiContainer = useRef(null)
  const [page, setPage] = useState(1)
  const [totalNoti, setTotalNoti] = useState(0)

  const fetchDataNotifications = useCallback(
    async (filter) => {
      dispatch(getNotificationsReturnData(filter))
        .then(({ type, data_info, data_meta }) => {
          if (type.endsWith('_SUCCESS')) {
            if (data_meta) {
              const { pagination } = data_meta

              setNotificationList(data_info)
              setTotalNoti(pagination?.total)
            }
          }
        })
        .catch((error) => {
          console.log('error fetch noti', error)
        })
    },
    [dispatch]
  )
  const fetchDataNotificationsForFilter = useCallback(
    async (filter) => {
      dispatch(getNotificationsReturnData(filter))
        .then(({ type, data_info, data_meta }) => {
          if (type.endsWith('_SUCCESS')) {
            if (data_meta) {
              const { pagination } = data_meta

              setNotificationList((prevNoti) => [...prevNoti, ...data_info])
              setTotalNoti(pagination?.total)
            }
          }
        })
        .catch((error) => {
          console.log('error fetch noti', error)
        })
    },
    [dispatch]
  )
  function debounce(func, wait) {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }

      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const scrollToTop = () => {
    if (notiContainer.current) {
      notiContainer.current.scrollTop = 0
    }
  }

  const checkBottom = useCallback(
    debounce(() => {
      if (!notiContainer.current) return

      const scrolledToBottom =
        notiContainer.current.scrollTop + notiContainer.current.clientHeight >= notiContainer.current.scrollHeight

      if (scrolledToBottom) {
        if (notificationList.length < totalNoti) {
          setPage((prevPage) => prevPage + 1)
        }
      }
    }, 250),
    [notiContainer, notificationList, totalNoti]
  )

  useEffect(() => {
    const container = notiContainer.current
    if (container) {
      container.addEventListener('scroll', checkBottom)
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', checkBottom)
      }
    }
  }, [checkBottom])

  useEffect(() => {
    if (isOpen) {
      const filter = {
        skip: page,
        limit: limit,
        owner: admin?.employee_info?.id ? admin?.employee_info?.id : -99,
        is_read: tabactive,
      }
      if (page === 1) fetchDataNotifications(filter)
      else fetchDataNotificationsForFilter(filter)
    }
  }, [tabactive, page, isOpen])

  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    setPage(1)
    scrollToTop()
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value === data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }

  const onClickupdateRead = (item) => {
    const update = {
      data: {
        is_read: true,
      },
    }
    dispatch(updateNotifications(item?.id, update)).then(() => {
      if (item.type === 'task') {
        onClose(item.metadata)
      } else if (item.type === 'doc') {
        if (item.metadata.page == 'approval') {
          navigate('/task/approvals', { replace: true })
        }
        if (
          item.metadata.page == 'quotation' ||
          item.metadata.page == 'billing' ||
          item.metadata.page == 'invoice' ||
          item.metadata.page == 'receipt'
        ) {
          navigate('/document/sales', { replace: true, state: { doc_type: item.metadata.page } })
        }
        if (
          item.metadata.page == 'purchase_order' ||
          item.metadata.page == 'cheque_payment_journal' ||
          item.metadata.page == 'payment_journal' ||
          item.metadata.page == 'petty_cash_voucher'
        ) {
          navigate('/document/purchases', { replace: true, state: { doc_type: item.metadata.page } })
        }
        onClose()
      } else if (item.type === 'sharedoc') {
        const { metadata } = item
        dispatch(setNotiEmailDocId(metadata?.assignment))
        navigate('/share-service/company-information')
        onClose()
      }
    })
  }

  return (
    <Div {...props} ref={notiContainer}>
      <div className="content_header">
        <header>
          <div className="content-title">
            <MdOutlineNotificationsActive className="icon" color="#000" /> Notifications
          </div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="wrp-tab-menu">
          {menuTabList?.map((item, index) => (
            <button
              id={'this_name-' + item.name}
              key={'btn-' + index}
              className={item.active_status ? 'menu-active' : 'btn-menu-style'}
              onClick={() => handleClickTaskMenuTab(item)}
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>

      <div className="dialog-content">
        {notificationList?.map((item, index) => (
          <div className="box_content" key={index} onClick={() => onClickupdateRead(item)}>
            <div className="content">
              <div className="notification_row1">
                <div className="notification_row1_name"> {item.title} </div>
              </div>
              <div className="notification_row1">
                <div className="notification_row1_message"> {item.message}</div>
                {item.message === 'ส่งงานให้คุณ ภายในวันที่' ? (
                  <div className="notification_row1_date send_task">
                    {item?.metadata?.due_date ? DateDisplay(item?.metadata?.due_date) : ''}
                  </div>
                ) : item.message === 'ส่งเอกสารให้คุณ ภายในวันที่' ? (
                  <div className="notification_row1_date send_task">
                    {item?.metadata?.due_date ? DateDisplay(item?.metadata?.due_date) : ''}
                  </div>
                ) : item.message === 'ปิดงาน วันที่' ? (
                  <div className="notification_row1_date close_task">{DateDisplay(item.createdAt)}</div>
                ) : item.message === 'ขออนุมัติงาน ภายในวันที่' ? (
                  <div className="notification_row1_date want_approve">
                    {item?.metadata?.due_date ? DateDisplay(item?.metadata?.due_date) : ''}
                  </div>
                ) : item.message === 'แจ้งเตือนครบกำหนด ภายในวันที่' ? (
                  <div className="notification_row1_date due_date">
                    {item?.metadata?.due_date ? DateDisplay(item?.metadata?.due_date) : ''}
                  </div>
                ) : (
                  <div className="notification_row1_date"></div>
                )}
              </div>

              <div className="notification_row2">
                {item.type === 'sharedoc' ? (
                  <div className="notification_row2_message">
                    <b>Task:</b>{' '}
                    {item?.metadata?.doc_name?.length > 25
                      ? `${item?.metadata?.doc_name?.slice(0, 25)}...`
                      : item?.metadata?.doc_name}{' '}
                    <b>Version {item?.metadata?.doc_version?.toFixed(1)}</b>
                    <br />
                    <b>Type:</b> {item?.metadata?.doc_type}
                  </div>
                ) : (
                  <div className="notification_row2_message">
                    <b>Task:</b> {item?.metadata?.task} {' | '} <b>Project:</b> {item?.metadata?.project}
                  </div>
                )}

                {item.tag && (
                  <div
                    className={`notification_row2_status ${
                      item.tag === 'Approved'
                        ? 'approve'
                        : item.tag === 'Rejected'
                        ? 'rejected'
                        : item.tag === 'Due date'
                        ? 'due_date'
                        : ''
                    }`}
                  >
                    {item.tag}
                  </div>
                )}
                {item.tag === null && <div></div>}
              </div>
              <div className="notification_row3">{DateTimeHHmmssDisplay(item.updatedAt)}</div>
            </div>
          </div>
        ))}
      </div>
    </Div>
  )
}

export default NotificationSide
