import { getAPI, postAPI, putAPI } from 'utils/api'
import {
  GET_OP_TASK_LIST,
  GET_OP_REVIEWER_LIST,
  GET_OP_APPROVER_LIST,
  GET_OP_SW_LIST,
  GET_PROJECT_FOR_ACTION_PLAN,
  GET_TASK_FOR_ACTION_PLAN,
  GET_TOTAL_PROJECT_TASK,
  GET_TOTAL_TASK,
  GET_OP_COUNT,
} from 'redux/actionTypes'
import { showLoading, hideLoading } from 'redux/actions/loading'

export const getOPTaskList =
  (filter = { page: 1, pageSize: 10, status: '' }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_OP_TASK_LIST.REQUEST })

      const { company_info } = JSON.parse(localStorage.getItem('admin'))

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'fields[0]': 'name',
        'fields[1]': 'status',
        'fields[2]': 'code',
        'populate[sw][fields][0]]': 'name',
        'populate[sw][fields][1]': 'code',
        'populate[sw][fields][2]': 'due_date',
        'populate[sw][populate][sp][fields][0]': 'name',
        'populate[sw][populate][sp][fields][1]': 'code',
        'populate[sw][populate][sp][populate][employee][fields][0]': 'first_name',
        'populate[sw][populate][sp][populate][employee][fields][1]': 'last_name',
      }

      if (filter.status !== '') {
        params['filters[status]'] = filter.status
      } else {
        params['filters[$or][0][status]'] = 'Open'
        params['filters[$or][1][status]'] = 'In Progress'
      }

      const { data, meta } = await getAPI({
        url: `/api/op-tasks/me/company/${company_info.id}`,
        params,
      })

      return dispatch({ type: GET_OP_TASK_LIST.SUCCESS, data, meta })
    } catch (error) {
      return dispatch({
        type: GET_OP_TASK_LIST.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get OP Task Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getOPReviewerList =
  (filter = { page: 1, pageSize: 10, status: '' }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_OP_REVIEWER_LIST.REQUEST })

      const { company_info } = JSON.parse(localStorage.getItem('admin'))

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'fields[0]': 'code',
        'fields[1]': 'due_date',
        'fields[2]': 'status',
        'populate[sw][fields][0]]': 'code',
        'populate[sw][fields][1]': 'name',
        'populate[sw][populate][sp][fields][0]': 'name',
        'populate[sw][populate][sp][populate][employee][fields][0]': 'first_name',
        'populate[sw][populate][sp][populate][employee][fields][1]': 'last_name',
      }

      if (filter.status !== '') {
        params['filters[status]'] = filter.status
      } else {
        params['filters[status][$in]'] = ['Open', 'Waiting for Review']
      }

      const { data, meta } = await getAPI({
        url: `/api/op-reviewers/me/company/${company_info.id}`,
        params,
      })

      return dispatch({ type: GET_OP_REVIEWER_LIST.SUCCESS, data, meta })
    } catch (error) {
      return dispatch({
        type: GET_OP_REVIEWER_LIST.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get OP Reviewer Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getOPApproverList =
  (filter = { page: 1, pageSize: 10, status: '' }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_OP_APPROVER_LIST.REQUEST })

      const { company_info } = JSON.parse(localStorage.getItem('admin'))

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'fields[0]': 'code',
        'fields[1]': 'due_date',
        'fields[2]': 'status',
        'populate[sw][fields][0]]': 'code',
        'populate[sw][fields][1]': 'name',
        'populate[sw][populate][sp][fields][0]': 'name',
        'populate[sw][populate][sp][populate][employee][fields][0]': 'first_name',
        'populate[sw][populate][sp][populate][employee][fields][1]': 'last_name',
      }

      if (filter.status !== '') {
        params['filters[status]'] = filter.status
      } else {
        params['filters[status][$in]'] = ['Open', 'Waiting for Approve']
      }

      const { data, meta } = await getAPI({
        url: `/api/op-approvers/me/company/${company_info.id}`,
        params,
      })

      return dispatch({ type: GET_OP_APPROVER_LIST.SUCCESS, data, meta })
    } catch (error) {
      return dispatch({
        type: GET_OP_APPROVER_LIST.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get OP Approver Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getOPSwList =
  (filter = { page: 1, pageSize: 10, status: '' }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_OP_SW_LIST.REQUEST })

      const { company_info } = JSON.parse(localStorage.getItem('admin'))

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'fields[0]': 'code',
        'fields[1]': 'name',
        'fields[2]': 'due_date',
        'fields[3]': 'status',
        'populate[sp][fields][0]': 'name',
      }

      if (filter.status !== '') {
        params['filters[status]'] = filter.status
      } else {
        params['filters[$or][0][status]'] = 'Open'
        params['filters[$or][1][status]'] = 'In Progress'
      }

      const { data, meta } = await getAPI({
        url: `/api/op-sws/me/company/${company_info.id}`,
        params,
      })

      return dispatch({ type: GET_OP_SW_LIST.SUCCESS, data, meta })
    } catch (error) {
      return dispatch({ type: GET_OP_SW_LIST.FAILURE, error, showAlert: true, alertMessage: 'Get OP SW Fail' })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getProjectList =
  (filter = { page: 1, pageSize: 10, status: 'Doing', sort: '' }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_PROJECT_FOR_ACTION_PLAN.REQUEST })

      const { employee_info } = JSON.parse(localStorage.getItem('admin'))

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'filters[assign_to][id]': employee_info.id,
        'filters[status]': filter.status,
      }
      if (filter.sortBy !== '') params['sort[due_date]'] = filter.sortBy

      const { data, meta } = await getAPI({
        url: '/api/get-project-tasks-action-plan',
        params,
      })
      return dispatch({ type: GET_PROJECT_FOR_ACTION_PLAN.SUCCESS, data, meta })
    } catch (error) {
      return dispatch({
        type: GET_PROJECT_FOR_ACTION_PLAN.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Project for action plan Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getTaskList =
  (filter = { page: 1, pageSize: 10, status: 'Working', sort: '' }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_TASK_FOR_ACTION_PLAN.REQUEST })

      const { employee_info } = JSON.parse(localStorage.getItem('admin'))

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'filters[task][type]': 'routine',
        'filters[task][assign_to][id]': employee_info.id,
      }

      if (filter.sortBy !== '') params['sort[task][due_date]'] = filter.sortBy
      if (filter.status !== '') params['filters[action]'] = filter.status

      const { data, meta } = await getAPI({
        url: '/api/get-tasks-action-plan',
        params,
      })
      return dispatch({ type: GET_TASK_FOR_ACTION_PLAN.SUCCESS, data, meta })
    } catch (error) {
      return dispatch({
        type: GET_TASK_FOR_ACTION_PLAN.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Task For action plan Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getProjectCount = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TOTAL_PROJECT_TASK.REQUEST })

    const { employee_info } = JSON.parse(localStorage.getItem('admin'))
    const params = {
      'pagination[page]': 1,
      'pagination[pageSize]': 10,
      'filters[assign_to][id]': employee_info.id,
      'filters[$and][0][$or][0][status]': 'Open',
      'filters[$and][0][$or][1][status]': 'Doing',
    }

    const { data } = await getAPI({
      url: `/api/get-project-tasks-action-plan-count`,
      params,
    })

    return dispatch({ type: GET_TOTAL_PROJECT_TASK.SUCCESS, data })
  } catch (error) {
    return dispatch({
      type: GET_TOTAL_PROJECT_TASK.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Total Project Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getTaskCount = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_TOTAL_TASK.REQUEST })

    const { employee_info } = JSON.parse(localStorage.getItem('admin'))
    const params = {
      'pagination[page]': 1,
      'pagination[pageSize]': 10,
      'filters[task][assign_to][id]': employee_info.id,
      'filters[task][type]': 'routine',
      'filters[$and][0][$or][0][action]': 'Open',
      'filters[$and][0][$or][1][action]': 'Working',
    }

    const { data } = await getAPI({
      url: `/api/get-tasks-action-plan-count`,
      params,
    })

    return dispatch({ type: GET_TOTAL_TASK.SUCCESS, data })
  } catch (error) {
    return dispatch({
      type: GET_TOTAL_TASK.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Total Task Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getOPCount = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_OP_COUNT.REQUEST })

    const { company_info } = JSON.parse(localStorage.getItem('admin'))
    const params = {
      'pagination[page]': 1,
      'pagination[pageSize]': 10,
    }

    const { data } = await getAPI({
      url: `/api/get-operation-process-count/${company_info.id}`,
      params,
    })

    return dispatch({ type: GET_OP_COUNT.SUCCESS, data })
  } catch (error) {
    return dispatch({
      type: GET_OP_COUNT.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Total OP Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}
