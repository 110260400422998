import React, { useRef } from 'react'

import { useDispatch } from 'react-redux'
import { uploadFile } from 'redux/actions/master'

import styled from 'styled-components'

import { GoPaperclip } from 'react-icons/go'

const InputWrapper = styled.div``
const FileInput = styled.input`
  display: none;
`
const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  &:hover {
    background-color: var(--Gray-200);
  }
`

function UploadFilePaperClip({ setAttachFile, ...props }) {
  const dispatch = useDispatch()
  const fileInputRef = useRef(null)

  const handleIconClick = () => {
    fileInputRef.current.click()
  }
  const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const formData = new FormData()
      formData.append('files', file)

      const response = await dispatch(uploadFile(formData))

      if (response) setAttachFile(response)
      else setAttachFile(null)
    }
  }

  return (
    <InputWrapper {...props}>
      <FileInput type="file" ref={fileInputRef} onChange={handleFileChange} />
      <IconButton onClick={handleIconClick}>
        <GoPaperclip size={20} />
      </IconButton>
    </InputWrapper>
  )
}

export default UploadFilePaperClip
