import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Papa from 'papaparse'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import SearchText from '../../components/form/SearchText'
import Date from '../../components/form/Date'
import Table from '../../components/common/Table'
import Modal from '../../components/common/Modal'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import InputFile from '../../components/form/InputFile'
import TextField from '../../components/form/TextField'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/icon-edit.svg'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import {
  getProductList,
  deleteProduct,
  createProductlNote,
  getProductNotes,
  getProductBrands,
  getProductCategories,
  getProductTypes,
  uploadCSVProduct,
  viewProductRelation,
  updatePriceModel,
} from '../../redux/actions/product'
import { selectProduct } from '../../redux/selectors'

const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .link-view {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .content-header {
    margin-top: 24px;
  }

  .row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 20px;
    margin-bottom: 20px;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dialog-button-3 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error {
    color: red;
  }

  .table-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-gap: 10px; */
    padding: 10px;

    .header {
      background: #6941c6;
      color: #ffffff;
      padding: 1rem;
    }

    .content {
      padding: 1rem;
    }
    /* 
    .grid-item {
      background-color: #f1f1f1;
      padding: 20px;
      text-align: center;
      border: 1px solid #ccc;
      box-sizing: border-box;
    } */
  }
`
const productColumn = [
  {
    Header: 'Product Id',
    accessor: 'id',
    disableSortBy: false,
  },
  {
    Header: 'SKU',
    accessor: 'sku',
    disableSortBy: false,
  },
  {
    Header: 'Product Name',
    accessor: 'product_name',
    disableSortBy: false,
  },
  {
    Header: 'Product Specification',
    accessor: 'productSpecBtn',
    disableSortBy: false,
  },
  {
    Header: 'Brand',
    accessor: 'product_brand',
    disableSortBy: false,
  },
  {
    Header: 'Category',
    accessor: 'category',
    disableSortBy: false,
  },
  {
    Header: 'Formula',
    accessor: 'formalaBtn',
    disableSortBy: false,
  },
  {
    Header: 'Cost',
    accessor: 'procuct_cost',
    disableSortBy: false,
  },
  {
    Header: 'Retail Price Model',
    accessor: 'retail',
    disableSortBy: false,
  },
  {
    Header: 'Price Structure',
    accessor: 'priceStructureBtn',
    disableSortBy: false,
  },
  {
    Header: 'Supplier',
    accessor: 'supplier',
    disableSortBy: false,
  },
  {
    Header: 'Segment',
    accessor: 'product_sagment',
    disableSortBy: false,
  },
  {
    Header: 'Type',
    accessor: 'product_type',
    disableSortBy: false,
  },
  {
    Header: 'PM',
    accessor: 'product_manager',
    disableSortBy: false,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: false,
  },
  {
    Header: 'Created Date',
    accessor: 'publishedDate',
    disableSortBy: false,
  },
  {
    Header: 'Note',
    accessor: 'noteBtn',
    disableSortBy: false,
  },
  {
    accessor: 'deleteBtn',
    disableSortBy: false,
  },
  {
    accessor: 'editBtn',
    disableSortBy: false,
  },
]

const csvColumn = [
  {
    Header: 'Product Name',
    accessor: 'product_name',
    disableSortBy: false,
  },
  {
    Header: 'Brand',
    accessor: 'Brand',
    disableSortBy: false,
  },
  {
    Header: 'Category',
    accessor: 'Category',
    disableSortBy: false,
  },
  {
    Header: 'Type',
    accessor: 'Type',
    disableSortBy: false,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: false,
  },
  {
    accessor: 'deleteBtn',
    disableSortBy: false,
  },
]

const noteColumn = [
  {
    Header: 'Time',
    accessor: 'createdAt',
    disableSortBy: false,
  },
  {
    Header: 'Name',
    accessor: 'note_by',
    disableSortBy: false,
  },
  {
    Header: '	Note',
    accessor: 'note',
    disableSortBy: false,
  },
]

const specificationColumn = [
  {
    Header: 'Spec',
    accessor: 'spec',
    disableSortBy: false,
  },
  {
    Header: 'Value',
    accessor: 'value',
    disableSortBy: false,
  },
]

const formulaColumn = [
  {
    Header: 'Name',
    accessor: 'material',
    disableSortBy: false,
  },
  {
    Header: 'UoM',
    accessor: 'uom',
    disableSortBy: false,
  },
  {
    Header: 'Cost per unit',
    accessor: 'cost_per_unit',
    disableSortBy: false,
  },
  {
    Header: 'Quantity',
    accessor: 'quantity',
    disableSortBy: false,
  },
  {
    Header: 'Total',
    accessor: 'total',
    disableSortBy: false,
  },
]

const priceStructureColumn = [
  {
    Header: 'Name',
    accessor: 'name',
    disableSortBy: false,
  },
  {
    Header: 'Value',
    accessor: 'value',
    disableSortBy: false,
  },
  {
    Header: 'Percent',
    accessor: 'percent',
    disableSortBy: false,
  },
  {
    Header: 'Total',
    accessor: 'total',
    disableSortBy: false,
  },
]

const costColumn = [
  {
    Header: 'Cost',
    accessor: 'cost',
    disableSortBy: false,
  },
  {
    Header: 'Value',
    accessor: 'value',
    disableSortBy: false,
  },
]

const initPriceModelForm = {
  product_price_excl_vat: 0,
  product_price_inc_vat: '0',
  retailer_margin_percent: 0,
  retailer_margin_excl_vat: 0,
  price_to_retailers_excl_vat: 0,
  price_to_retailers_inc_vat: 0,
  add_wholesaler_margin_percent: 0,
  add_wholesaler_margin_excl_vat: 0,
  price_to_wholesalers_excl_vat: 0,
  price_to_wholesalers_inc_vat: 0,
  volume_rebate_percent: 0,
  volume_rebate_excl_vat: 0,
  net_ex_factory_price_excl_vat: 0,
  net_ex_factory_price_inc_vat: 0,
}
const limitLetter = 275

const Product = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { productList, isLoading, total, skip, limit, notes, brands, categories, types, viewProductRelations } =
    useSelector(selectProduct)
  const [search, setSearch] = useState('')
  const [date, setDate] = useState()
  const [products, setProducts] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [page, setPage] = useState(1)
  const [fileCsv, setFileCsv] = useState()
  const [csvData, setCsvData] = useState([])
  const [errorUpload, setErrorUpload] = useState(false)
  const [note, setNote] = useState('')
  const [productId, setProductId] = useState('')
  const [isSubmitNote, setIsSubmitNote] = useState(false)
  const [limitChar, setLimitChar] = useState(limitLetter)
  const [priceModelForm, setPriceModelForm] = useState(initPriceModelForm)
  const [recalculate, setRecalculate] = useState(false)

  const [uploadCsvModal, setUploadCsvModal] = useState(false)
  const [uploadCsvSuccessModal, setUploadCsvSuccessModal] = useState(false)
  const [noteModal, setNoteModal] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [productSpecificationModal, setProductSpecificationModal] = useState(false)
  const [formulaModal, setFormulaModal] = useState(false)
  const [priceStructureModal, setPriceStructureModal] = useState(false)
  const [priceModelModal, setPriceModelModal] = useState(false)
  const [costModal, setCostModal] = useState(false)
  const [errorAlertModal, setErrorAlertModal] = useState(false)

  const [brandList, setBrandList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [typeList, setTypeList] = useState([])

  const fetchData = useCallback(
    async (filter) => {
      dispatch(getProductList(filter))
    },
    [dispatch]
  )

  const noteProduct = useCallback(
    async (id) => {
      dispatch(getProductNotes(id))
      setProductId(id)
      setNoteModal(true)
    },
    [dispatch]
  )

  const productSpecView = useCallback(
    async (id) => {
      setProductId(id)
      setProductSpecificationModal(true)
      const request = {
        id,
        view_type: 'specification',
      }
      dispatch(viewProductRelation(request))
    },
    [dispatch]
  )

  const formulaView = useCallback(
    async (id) => {
      setProductId(id)
      setFormulaModal(true)
      const request = {
        id,
        view_type: 'material',
      }
      dispatch(viewProductRelation(request))
    },
    [dispatch]
  )

  const priceStructureView = useCallback(
    async (id) => {
      setProductId(id)
      setPriceStructureModal(true)
      const request = {
        id,
        view_type: 'price_structure',
      }
      dispatch(viewProductRelation(request))
    },
    [dispatch]
  )

  const priceModelView = useCallback(
    async (id) => {
      setProductId(id)
      setPriceModelModal(true)
      const request = {
        id,
        view_type: 'price_model',
      }
      dispatch(viewProductRelation(request))
    },
    [dispatch]
  )

  const costView = useCallback(
    async (id) => {
      setProductId(id)
      setCostModal(true)
      const request = {
        id,
        view_type: 'cost',
      }
      dispatch(viewProductRelation(request))
    },
    [dispatch]
  )

  const deleteProductCsv = useCallback((id) => {
    setCsvData((csvData) => csvData.filter((v) => v.id !== id))
  }, [])

  useEffect(() => {
    if (viewProductRelations?.data?.product_price) {
      const {
        product_price,
        add_wholesaler_margin,
        net_ex_factory_price,
        price_to_retailers,
        price_to_wholesalers,
        retailer_margin,
        volume_rebate,
      } = viewProductRelations.data
      const newPriceModelForm = {
        product_price_excl_vat: product_price.exclude,
        product_price_inc_vat: product_price.include
          .toString()
          .replace(/\D/g, '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        retailer_margin_percent: retailer_margin.include,
        retailer_margin_excl_vat: retailer_margin.exclude,
        price_to_retailers_excl_vat: price_to_retailers.exclude,
        price_to_retailers_inc_vat: price_to_retailers.include,
        add_wholesaler_margin_percent: add_wholesaler_margin.include,
        add_wholesaler_margin_excl_vat: add_wholesaler_margin.exclude,
        price_to_wholesalers_excl_vat: price_to_wholesalers.exclude,
        price_to_wholesalers_inc_vat: price_to_wholesalers.include,
        volume_rebate_percent: volume_rebate.include,
        volume_rebate_excl_vat: volume_rebate.exclude,
        net_ex_factory_price_excl_vat: net_ex_factory_price.exclude,
        net_ex_factory_price_inc_vat: net_ex_factory_price.include,
      }
      setPriceModelForm(newPriceModelForm)
    } else {
      setPriceModelForm(initPriceModelForm)
    }
  }, [viewProductRelations])

  useEffect(() => {
    dispatch(getProductBrands())
    dispatch(getProductCategories())
    dispatch(getProductTypes())
  }, [dispatch])

  useEffect(() => {
    const filter = {
      skip: 1 * limit - limit,
      limit: limit,
    }
    fetchData(filter)
  }, [fetchData, limit])

  useEffect(() => {
    const restLetter = limitLetter - note.length
    setLimitChar(restLetter)
  }, [note])

  useEffect(() => {
    const newProducts = productList.map((v) => ({
      ...v,
      id: <Link to={`/inventory-management/products/products-detail/${v.id}`}>{v.id}</Link>,
      productSpecBtn: <Button onClick={() => productSpecView(v.id)}>View</Button>,
      formalaBtn: <Button onClick={() => formulaView(v.id)}>View</Button>,
      procuct_cost: (
        <div className="link-view" onClick={() => costView(v.id)}>
          {v.procuct_cost}
        </div>
      ),
      priceStructureBtn: <Button onClick={() => priceStructureView(v.id)}>{t('view')}</Button>,
      retail: <Button onClick={() => priceModelView(v.id)}>{t('view')}</Button>,
      noteBtn: <Button onClick={() => noteProduct(v.id)}>{t('note')}</Button>,
      deleteBtn: <img className="icon" src={iconDelete} title="Delete" onClick={() => deleteMaterialItem(v.id)} />,
      editBtn: (
        <Link to={`/inventory-management/products/edit-products/${v.id}`}>
          <img src={iconEdit} title="Edit" />
        </Link>
      ),
    }))
    setProducts(newProducts)
  }, [productList, noteProduct, productSpecView, formulaView, priceStructureView, priceModelView, costView])

  useEffect(() => {
    const totalPage = Math.ceil(total / limit)
    setTotalPages(totalPage)
  }, [total, skip, limit])

  useEffect(() => {
    if (fileCsv) {
      Papa.parse(fileCsv, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          const data = results.data
          const finalData = data.map((v, i) => {
            const product_brand = brandList.find((i) => i.text == v.Brand)
            const product_category = categoryList.find((i) => i.text == v.Category)
            const product_type = typeList.find((i) => i.text == v.Type)

            return {
              id: i,
              ...v,
              product_name: v['Product Name'],
              product_brand: product_brand != undefined ? product_brand['value'] : null,
              product_category: product_category != undefined ? product_category['value'] : null,
              product_type: product_type != undefined ? product_type['value'] : null,
              status:
                product_category == null || product_type == null || product_brand == null
                  ? 'ข้อมูลไม่ถูก Format'
                  : 'ผ่าน',
              deleteBtn: (
                <img
                  id={`csv-data-${i}`}
                  className="icon"
                  src={iconDelete}
                  title="Delete"
                  onClick={() => deleteProductCsv(i)}
                />
              ),
            }
          })

          setCsvData(finalData)
        },
        error: function (err) {
          alert(`Error while parsing CSV: ${err}`)
        },
      })
    }
  }, [fileCsv, categoryList, typeList, brandList, deleteProductCsv])

  useEffect(() => {
    const newBrandList = brands.map((v) => ({
      value: v.id,
      text: v.attributes.name,
    }))
    setBrandList(newBrandList)
  }, [brands])
  useEffect(() => {
    const newCategoryList = categories.map((v) => ({
      value: v.id,
      text: v.attributes.product_category_name,
    }))
    setCategoryList(newCategoryList)
  }, [categories])
  useEffect(() => {
    const newTypeList = types.map((v) => ({
      value: v.id,
      text: v.attributes.product_type_name,
    }))
    setTypeList(newTypeList)
  }, [types])

  const calculatePriceModel = useCallback(() => {
    let new_product_price_excl_vat =
      parseFloat(priceModelForm.product_price_inc_vat.toString().replace(/,/g, '')) / 1.07
    let new_retailer_margin_excl_vat = (new_product_price_excl_vat * priceModelForm?.retailer_margin_percent) / 100

    const diffWholeSaler = new_product_price_excl_vat - new_retailer_margin_excl_vat
    let new_price_to_retailers_excl_vat = diffWholeSaler
    let new_price_to_retailers_inc_vat = diffWholeSaler * 1.07

    let new_add_wholesaler_margin_excl_vat =
      (new_price_to_retailers_excl_vat * priceModelForm?.add_wholesaler_margin_percent) / 100

    const diffAddWholeSaler = new_price_to_retailers_excl_vat - new_add_wholesaler_margin_excl_vat
    let new_price_to_wholesalers_excl_vat = diffAddWholeSaler
    let new_price_to_wholesalers_inc_vat = diffAddWholeSaler * 1.07

    let new_volume_rebate_excl_vat = (priceModelForm?.volume_rebate_percent * new_price_to_wholesalers_excl_vat) / 100

    const diffVolumeRebate = new_price_to_wholesalers_excl_vat - new_volume_rebate_excl_vat
    let new_net_ex_factory_price_excl_vat = diffVolumeRebate
    let new_net_ex_factory_price_inc_vat = diffVolumeRebate * 1.07

    setPriceModelForm((prev) => ({
      ...prev,
      product_price_excl_vat: new_product_price_excl_vat,
      retailer_margin_excl_vat: new_retailer_margin_excl_vat,
      price_to_retailers_excl_vat: new_price_to_retailers_excl_vat,
      price_to_retailers_inc_vat: new_price_to_retailers_inc_vat,
      add_wholesaler_margin_excl_vat: new_add_wholesaler_margin_excl_vat,
      price_to_wholesalers_excl_vat: new_price_to_wholesalers_excl_vat,
      price_to_wholesalers_inc_vat: new_price_to_wholesalers_inc_vat,
      volume_rebate_excl_vat: new_volume_rebate_excl_vat,
      net_ex_factory_price_excl_vat: new_net_ex_factory_price_excl_vat,
      net_ex_factory_price_inc_vat: new_net_ex_factory_price_inc_vat,
    }))
  }, [priceModelForm])

  useEffect(() => {
    if (priceModelForm && recalculate) {
      calculatePriceModel()
      setRecalculate(false)
    }
  }, [priceModelForm, recalculate, calculatePriceModel])

  const displayNumberWithComma = (no) => {
    const result = Number(parseFloat(no).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 2,
    })
    return result
  }

  const onChangeFile = (event) => {
    const file = event.target.files[0]
    setFileCsv(file)
  }

  const onChangePagination = async (value) => {
    const filter = {
      skip: value.page * limit - limit,
      limit: limit,
    }
    fetchData(filter)
    setPage(value.page)
  }

  const deleteMaterialItem = (id) => {
    setProductId(id)
    setConfirmDeleteModal(true)
  }
  const onChangeDate = (value) => {
    setDate(value)
  }

  const onSubmitSearch = () => {
    // const filter = {
    //   skip: 0,
    //   limit: limit,
    //   category: categorySelected == '' ? null : categorySelected,
    //   type: typeSelected == '' ? null : typeSelected,
    //   supplier: supplierSelected == '' ? null : supplierSelected,
    //   status: statusSelected == '' ? null : statusSelected,
    // }
    // fetchData(filter)
    // setPage(1)
  }

  const onClearSearch = () => {
    // const filter = {
    //   skip: 0,
    //   limit: limit,
    //   category: null,
    //   type: null,
    //   supplier: null,
    // }
    // fetchData(filter)
    // setCategorySelected('')
    // setTypeSelected('')
    // setSupplierSelected('')
    // setStatusSelected('')
    // setPage(1)
  }

  const uploadCsv = () => {
    if (csvData.length > 0) {
      setUploadCsvModal(false)
      setUploadCsvSuccessModal(true)
    } else {
      setErrorUpload(true)
    }
  }

  const onSubmitUploadCsv = () => {
    if (!csvData.every((v) => v.status == 'ผ่าน')) {
      setErrorAlertModal(true)
    } else {
      const data = csvData.map((v) => ({
        product_name: v.product_name,
        product_category: v.product_category,
        product_brand: v.product_brand,
        product_type: v.product_type,
      }))
      const request = { data }
      dispatch(uploadCSVProduct(request))
    }
    setUploadCsvSuccessModal(false)
    clearUploadCsv()
    setPage(1)
  }

  const closeUploadCsvModal = () => {
    clearUploadCsv()
    setUploadCsvModal(false)
  }

  const closeUploadCsvSuccessModal = () => {
    clearUploadCsv()
    setUploadCsvSuccessModal(false)
  }

  const clearUploadCsv = () => {
    setFileCsv()
    setCsvData([])
    setErrorUpload(false)
  }

  const onSubmitNote = () => {
    setIsSubmitNote(true)
    if (note !== '') {
      const { user } = JSON.parse(localStorage.getItem('admin'))
      const request = {
        data: {
          note: note,
          note_by: `${user.firstname} ${user.lastname}`,
          product: productId,
        },
      }
      dispatch(createProductlNote(request))
      setIsSubmitNote(false)
      setNote('')
    }
  }

  const confirmDelete = async () => {
    const request = {
      data: {
        status: 'delete',
      },
    }
    dispatch(deleteProduct(productId, request))
    setConfirmDeleteModal(false)
    setPage(1)
  }

  const onChangePriceModelForm = (event) => {
    const { name, value } = event.target
    setPriceModelForm((prev) => ({
      ...prev,
      [name]:
        name === 'product_price_inc_vat'
          ? value && value !== ''
            ? value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            : ''
          : parseFloat(value && value !== '' ? value : '0'),
    }))
    setRecalculate(true)
  }

  const savePriceModel = () => {
    const request = {
      data: {
        ...priceModelForm,
        product_price_inc_vat: parseFloat(priceModelForm.product_price_inc_vat.replace(/,/g, '')),
        product_id: productId,
      },
    }

    dispatch(updatePriceModel(request))
    setPriceModelModal(false)
    setPriceModelForm(initPriceModelForm)
  }

  return (
    <Div>
      <header>
        <div className="content-title">{t('product_management')}</div>
        <div className="content-header">
          <div className="row">
            <SearchText
              className="mb-1-rem"
              placeholder={t('search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Date value={date} onChange={onChangeDate} />
          </div>

          <div>
            <Button onClick={onClearSearch} className="mr-1-rem">
              Clear
            </Button>
            <Button onClick={onSubmitSearch}>{t('search')}</Button>
          </div>
        </div>
      </header>

      <div className="table-wrapper">
        <div className="table-header">
          <div className="group">
            <div className="table-title">{t('product_list')}</div>
            <div className="table-total">{total} {t('items')}</div>
          </div>

          <div>
            <Button onClick={() => setUploadCsvModal(true)} className="mr-1-rem" append={<AiOutlineCloudUpload />}>
            {t('import_from_file')}
            </Button>
            <Link className="create-bt" to={`/inventory-management/products/create-products`}>
              <Button append={<img src={iconPlus} />}>{t('register_new_product')}</Button>
            </Link>
          </div>
        </div>
        <Table
          columns={productColumn}
          data={products}
          onStateChange={onChangePagination}
          pageCount={totalPages}
          page={page}
          loading={isLoading}
        />
      </div>
      {/* Modal */}
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={confirmDelete}
        title={t('confrim_delete')}
        description={t('delete_item_description')}
      />
      <Modal open={Boolean(uploadCsvModal)} onClose={closeUploadCsvModal} disableBackdropClick>
        <Div>
          <div className="modal-container">
            <div className="modal-header">{t('csv_upload')}</div>
            <InputFile error={errorUpload} file={fileCsv} onChange={onChangeFile} />
            {errorUpload && <div className="error">{t('please_select_file')}</div>}
            <a
              href="https://2bsimplesystem.s3.ap-southeast-1.amazonaws.com/product_csv_ec29d0a4bf.csv"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('download_example_csv')}
            </a>
            <div className="dialog-button">
              <ButtonOutline onClick={closeUploadCsvModal}>{t('cancel')}</ButtonOutline>
              <Button onClick={uploadCsv}>{t('upload_file')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
      <Modal open={Boolean(uploadCsvSuccessModal)} onClose={closeUploadCsvSuccessModal} disableBackdropClick>
        <Div>
          <div className="modal-container">
            <div className="modal-header">{t('csv_upload_success')}</div>
            <Table columns={csvColumn} data={csvData} />
            <div className="dialog-button">
              <ButtonOutline onClick={closeUploadCsvSuccessModal}>{t('cancel')}</ButtonOutline>
              <Button onClick={onSubmitUploadCsv}>{t('upload_file')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
      <Modal open={Boolean(noteModal)} onClose={() => setNoteModal(false)} disableBackdropClick>
        <Div>
          <div className="modal-container">
            <div className="modal-header">{t('note')}</div>
            <div className="mb-1-rem">
              <Table columns={noteColumn} data={notes} />
            </div>
            <textarea
              className={`text-area mb-1-rem ${isSubmitNote && note == '' ? 'error' : ''}`}
              value={note}
              onChange={(e) => setNote(e.target.value.substring(0, 275))}
            ></textarea>
            {isSubmitNote && note == '' && <div className="error">{t('please_enter_a_message')}</div>}
            <div>{limitChar} charater left</div>
            <div className="dialog-button">
              <ButtonOutline onClick={() => setNoteModal(false)}>Cancel</ButtonOutline>
              <Button onClick={onSubmitNote}>{t('submit')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
      <Modal
        open={Boolean(productSpecificationModal)}
        onClose={() => setProductSpecificationModal(false)}
        disableBackdropClick
      >
        <Div>
          <div className="modal-container">
            <div className="modal-header">{t('product_specification')}</div>
            <div className="mb-1-rem">
              <Table
                columns={specificationColumn}
                data={viewProductRelations?.data?.length > 0 ? viewProductRelations?.data : []}
              />
            </div>
            <div className="dialog-button-2">
              <Button onClick={() => setProductSpecificationModal(false)}>{t('close')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
      <Modal open={Boolean(formulaModal)} onClose={() => setFormulaModal(false)} disableBackdropClick>
        <Div>
          <div className="modal-container">
            <div className="modal-header">{t('rormula_list')}</div>
            <div className="mb-1-rem">
              <Table
                columns={formulaColumn}
                data={viewProductRelations?.data?.length > 0 ? viewProductRelations?.data : []}
              />
            </div>
            <div className="dialog-button-2">
              <Button onClick={() => setFormulaModal(false)}>{t('close')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
      <Modal open={Boolean(priceStructureModal)} onClose={() => setPriceStructureModal(false)} disableBackdropClick>
        <Div>
          <div className="modal-container">
            <div className="modal-header">{t('price_structure')}</div>
            <div className="mb-1-rem">
              <Table
                columns={priceStructureColumn}
                data={viewProductRelations?.data?.length > 0 ? viewProductRelations?.data : []}
              />
            </div>
            <div className="dialog-button-2">
              <Button onClick={() => setPriceStructureModal(false)}>{t('close')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
      <Modal open={Boolean(costModal)} onClose={() => setCostModal(false)} disableBackdropClick>
        <Div>
          <div className="modal-container">
            <div className="modal-header">{t('cost')}</div>
            <div className="mb-1-rem">
              <Table
                columns={costColumn}
                data={viewProductRelations?.data?.length > 0 ? viewProductRelations?.data : []}
              />
            </div>
            <div className="dialog-button-2">
              <Button onClick={() => setCostModal(false)}>{t('close')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
      <Modal open={Boolean(priceModelModal)} onClose={() => setPriceModelModal(false)} disableBackdropClick>
        <Div>
          <div className="modal-container">
            <div className="modal-header">{t('price_model')}</div>
            <div className="mb-1-rem">
              <div className="table-row">
                <div className="header">{t('type')}</div>
                <div className="header">%</div>
                <div className="header">{t('exclude_vat')}</div>
                <div className="header">{t('include_vat')}</div>
                <div className="content">{t('product_price')}</div>
                <div className="content"></div>
                <div className="content">{displayNumberWithComma(priceModelForm.product_price_excl_vat)}</div>
                <div className="content">
                  <TextField
                    placeholder={t('product_name')}
                    name="product_price_inc_vat"
                    value={priceModelForm.product_price_inc_vat}
                    onChange={onChangePriceModelForm}
                  />
                </div>
                <div className="content">{t('retailer_margin')}</div>
                <div className="content">
                  <TextField
                    placeholder={t('product_name')}
                    name="retailer_margin_percent"
                    value={priceModelForm?.retailer_margin_percent}
                    onChange={onChangePriceModelForm}
                  />
                </div>
                <div className="content">{displayNumberWithComma(priceModelForm.retailer_margin_excl_vat)}</div>
                <div className="content"></div>
                <div className="content">{t('price_to_retailers')}</div>
                <div className="content"></div>
                <div className="content">{displayNumberWithComma(priceModelForm.price_to_retailers_excl_vat)}</div>
                <div className="content">{displayNumberWithComma(priceModelForm.price_to_retailers_inc_vat)}</div>
                <div className="content">{t('add_wholesaler_margin')}</div>
                <div className="content">
                  <TextField
                    placeholder={t('product_name')}
                    name="add_wholesaler_margin_percent"
                    value={priceModelForm?.add_wholesaler_margin_percent}
                    onChange={onChangePriceModelForm}
                  />
                </div>
                <div className="content">{displayNumberWithComma(priceModelForm.add_wholesaler_margin_excl_vat)}</div>
                <div className="content"></div>
                <div className="content">{t('price_to_wholesalers')}</div>
                <div className="content"></div>
                <div className="content">{displayNumberWithComma(priceModelForm.price_to_wholesalers_excl_vat)}</div>
                <div className="content">{displayNumberWithComma(priceModelForm.price_to_wholesalers_inc_vat)}</div>
                <div className="content">{t('volume_rebate')}</div>
                <div className="content">
                  <TextField
                    placeholder="Product Name"
                    name="volume_rebate_percent"
                    value={priceModelForm?.volume_rebate_percent}
                    onChange={onChangePriceModelForm}
                  />
                </div>
                <div className="content">{displayNumberWithComma(priceModelForm.volume_rebate_excl_vat)}</div>
                <div className="content"></div>
                <div className="content">{t('net_ex_factory_price')}</div>
                <div className="content"></div>
                <div className="content">{displayNumberWithComma(priceModelForm.net_ex_factory_price_excl_vat)}</div>
                <div className="content">{displayNumberWithComma(priceModelForm.net_ex_factory_price_inc_vat)}</div>
              </div>
            </div>
            <div className="dialog-button-3">
              <Button className="mr-1-rem" onClick={() => setPriceModelModal(false)}>
                {t('close')}
              </Button>
              <Button onClick={savePriceModel}>{t('save')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
      <Modal open={Boolean(errorAlertModal)} onClose={() => setErrorAlertModal(false)} disableBackdropClick>
        <Div>
          <div className="modal-container">
            <div className="modal-header">
              {t('not_format_upload')}
            </div>
            <div className="dialog-button-2">
              <Button onClick={() => setErrorAlertModal(false)}>{t('close')}</Button>
            </div>
          </div>
        </Div>
      </Modal>
    </Div>
  )
}

export default Product
