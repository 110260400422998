export default function IconChevronLeft({ width = '14', color = '#333', onClick,degree = "0" }) {
  return (
    <svg
      onClick={onClick}
      width={width}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: "rotate(" + degree + "deg)" }}
    >
      <path d="M7 13L1 7L7 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
