import { useCallback, useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { getCompanyInfo } from '../../utils/common'

import { useSelector, useDispatch } from 'react-redux'
import { selectUser, selectMaster } from '../../redux/selectors'

import {
  getUserListNew,
  deleteUsersManagement,
  createUserManagement,
  accessRole,
  getUserManagementByid,
  editUserManagement,
  sendRequestResetPassword,
  fineEmployeeUser,
  setEmployeeUser,
  createAccountUserWithPassword,
} from '../../redux/actions/userlist'
import { getAccountUserList } from '../../redux/actions/employee'
import { resetNewPassword } from '../../redux/actions/employeeProfile'
import { getCompanyInfoById, getDepartmentsAndPositionsUseAuthen } from '../../redux/actions/master'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import SearchText from '../../components/form/SearchText'
import Table from '../../components/common/Table'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import DialogConfirm from '../../components/dialog/DialogConfirm'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'
import SidePanel from '../../components/common/SidePanel'
import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import CollapseMenu from '../../components/common/CollapseMenu'
import ButtonCollapsible from '../../components/common/ButtonCollapsible'
import PasswordField from '../../components/form/PasswordInput'
import RadioButton from '../../components/form/RadioButton'

import iconPlus from '../../assets/images/icon-plus.svg'

import userIcon from '../../assets/images/user-icon.png'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import deleteIcon from '../../assets/images/delete-icon.png'
import resetIcon from '../../assets/images/reset-icon.png'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { LiaTimesSolid } from 'react-icons/lia'
import { FiCopy } from 'react-icons/fi'

const Div = styled.div`
  p {
    margin: 0;
    font-size: 14px;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  .mt-1-rem {
    margin-top: 0.5rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }
  .ml-1-rem {
    margin-left: 1rem;
  }

  .d-flex {
    display: flex;
  }

  .f-wrap {
    flex-wrap: wrap;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .justify-content-center {
    justify-content: center;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .align-items-center {
    align-items: center;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-2rem {
    font-size: 1.5rem;
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--Gray-200, #eaecf0);
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .content-header {
    margin-top: 24px;
  }

  .search-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr) 120px;
    grid-template-rows: auto;
    align-content: center;
    column-gap: 1rem;
    margin-bottom: 1rem;
  }

  .badge {
    padding: 2px 8px;
    align-items: center;
    color: var(--Pink-700);
    background: var(--Pink-50);
    border: 1px solid var(--Pink-200);
    border-radius: 16px;
  }

  .badge-status {
    padding: 2px 8px;
    align-items: center;
    color: var(--Approved-Badge-Font);
    border: 1px solid var(--Approved-Badge-Stroke);
    background: var(--Approved-Badge-Bg);
    border-radius: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #17b26a;

      &.inactive {
        color: red;
      }
    }
  }

  .font-pimary {
    color: var(--primary-600, #7f56d9);
  }

  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid var(--Status-Icon-Stroke);
    color: var(--Status-Icon-Font);
    background: var(--Status-Icon-Bg);

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: var(--Status-Active);

      &.inactive {
        color: var(--Status-Inactive);
      }

      &.draft {
        color: var(--Status-Draft);
      }
    }
  }

  .badge-user-type {
    padding: 2px 8px;
    align-items: center;
    color: var(--Doing-Badge-Font);
    border: 1px solid var(--Doing-Badge-Stroke);
    border-radius: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;

    &.non-employee {
      color: var(--Rejected-Badge-Font);
      border: 1px solid var(--Rejected-Badge-Stroke);
      background: var(--Rejected-Badge-Bg);
    }
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .table-wrapper {
    width: 100%;
    // Optional, if you want to limit the maximum width
    max-width: 100%;
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 10rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error {
    color: var(--Error-600);
  }

  .error-border {
    border: 1px solid var(--Error-300);
  }

  .side-panel-container {
    height: 100vh;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .side-panel-container-2 {
    padding: 2rem;
  }

  .create-access-role-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .input-new-2 {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr repeat(1, auto);
    grid-template-rows: auto;
    align-content: center;
  }

  .input-header {
    color: var(--gray-700);
    font-size: 14px;
    font-weight: 500;
  }

  .permission-header {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .permission-content {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .text-underline {
    text-decoration: underline;
  }
  .text-color-primary {
    color: #7f56d9;
  }
  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .tab-content {
    overflow: auto;
    .view-information-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }
  }
  .button-ml {
    margin-right: 10px; /* เพิ่มระยะห่างทางด้านขวาของ div */
  }

  .bg-cover-info {
    height: 150px;
    background: var(--gradient-linear-color-01, linear-gradient(180deg, #a5c0ee 0%, #fbc5ec 100%));
  }
`
const FlexContainer = styled.div`
  display: flex;
  &.align-items-center {
    align-items: center;
  }
`

const initUserManagementForm = {
  email: '',
  firstname: '',
  lastname: '',
  employee_no: '',
  id_card: '',
  phone_no: '',
  department: '',
  display_department: '',
  position: '',
  display_position: '',
  roles: '',
  username: '',
}
const initMessageModal = {
  headline: '',
  message: '',
}
const initLangRadioOptionList = [
  {
    text: 'Email Invitation',
    value: 'email-invitation',
  },
  {
    text: 'Create password',
    value: 'create-password',
  },
]

const initStatusDropdown = [
  {
    value: 'active',
    text: 'Active',
  },
  {
    value: 'inactive',
    text: 'Inactive',
  },
  {
    value: 'draft',
    text: 'Draft',
  },
]

const initValidateEmployeeForm = {
  email: null,
  firstname: null,
  lastname: null,
  employee_no: null,
  id_card: null,
  phone_no: null,
  department: null,
  position: null,
  roles: null,
  username: null,
}

const UsersList = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { companyInfo } = useSelector(selectMaster)
  const CompanyInfo = getCompanyInfo()

  const fetchCompanyInfo = useCallback(() => {
    const { company_info } = JSON.parse(localStorage.getItem('admin'))
    dispatch(getCompanyInfoById(company_info.id))
  }, [dispatch])
  useEffect(() => {
    fetchCompanyInfo()
  }, [])

  const UsersColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('employee_id'),
      accessor: 'employee_no',
      disableSortBy: false,
    },
    {
      Header: t('first_name'),
      accessor: 'first_name',
      disableSortBy: false,
    },
    {
      Header: t('last_name'),
      accessor: 'last_name',
      disableSortBy: false,
    },
    {
      Header: t('user_type'),
      accessor: 'user_type',
      disableSortBy: false,
    },
    {
      Header: t('email'),
      accessor: 'email',
      disableSortBy: false,
    },
    {
      Header: t('mobile_phone'),
      accessor: 'phone_no',
      disableSortBy: false,
    },
    // {
    //   Header: t('department'),
    //   accessor: 'department',
    //   disableSortBy: false,
    // },
    // {
    //   Header: t('positions'),
    //   accessor: 'position',
    //   disableSortBy: false,
    // },
    {
      Header: t('access_role'),
      accessor: 'access_role',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      accessor: 'editBtn',
      disableSortBy: false,
    },
    {
      accessor: 'deleteBtn',
      disableSortBy: false,
    },
    {
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const {
    usersList,
    employeeUser,
    isLoading,
    /*departmentList,*/
    accessroleList,
    usersInfo,
    state,
    page,
    limit,
    pageCount,
    total,
  } = useSelector(selectUser)

  const { departments: departmentList } = useSelector(selectMaster)

  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [userManagementForm, setUserManagementForm] = useState(initUserManagementForm)
  const [userManagementFormEdit, setUserManagementFormEdit] = useState(initUserManagementForm)
  const [userManagementFormView, setUserManagementFormView] = useState(initUserManagementForm)
  const [usersManagementId, setusersManagementId] = useState('')
  const [users, setUsersList] = useState([])
  const [searchFilters, setSearchFilters] = useState([])
  const [departmentSelected, setDepartmentSelected] = useState('')
  const [positionSelected, setPositionSelected] = useState('')
  const [roleSelected, setRoleSelected] = useState('')
  const [search, setSearch] = useState('')
  const [typeForm, setTypeForm] = useState('')
  const [type, setType] = useState('')

  const [typeCreation, setTypeCreation] = useState('email-invitation')
  const [password, setPassword] = useState('')

  // validate form
  const [validateEmployee, setValidateEmployee] = useState(initValidateEmployeeForm)
  // dropdown
  const [departments, setDepartments] = useState([])
  const [positions, setPositions] = useState([])
  // const [PositionSelected, setPositionSelected] = useState('')
  const [roles, setRoles] = useState([])
  //models
  const [isOpenSidePanelCreate, setIsOpenSidePanelCreate] = useState(false)
  const [isOpenSidePanelEdit, setIsOpenSidePanelEdit] = useState(false)
  const [confirmCreateModal, setConfirmCreateModal] = useState(false)
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false)
  const [isOpenSidePanelView, setIsOpenSidePanelView] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [successDeleteModal, setSuccessDeleteModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [confirmSentResetPasswordModal, setConfirmSentResetPasswordModal] = useState(false)
  const [successResetPassword, setSuccessResetPassword] = useState(false)
  const listAction = [
    {
      text: t('for_employee'),
      action: () => createUsersManagement('employee'),
    },
    {
      text: t('for_non_employee'),
      action: () => createUsersManagement('non-employee'),
    },
  ]
  // modal message
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const deleteUsersManagements = useCallback((id) => {
    setusersManagementId(id)
    setConfirmDeleteModal(true)
  }, [])
  const editUsersManagement = useCallback(
    (id) => {
      setusersManagementId(id)
      setIsOpenSidePanelEdit(true)
      dispatch(getUserManagementByid(id))
      setType('edit')
    },
    [dispatch]
  )
  const viewUsersManagement = useCallback(
    (id) => {
      setusersManagementId(id)
      setIsOpenSidePanelView(true)
      dispatch(getUserManagementByid(id))
      setType('view')
    },
    [dispatch]
  )
  const fetchData = useCallback(
    async (filter) => {
      dispatch(getUserListNew(filter))
    },
    [dispatch]
  )

  useEffect(() => {
    const generatedPassword = generateRandomString()
    setPassword(generatedPassword)
  }, [isOpenSidePanelCreate])

  useEffect(() => {
    if (employeeUser) {
      const { employee_user, department_ref, position_ref } = employeeUser
      if (employee_user?.data) {
        setValidateEmployee((prev) => ({
          ...prev,
          employee_no: 'This employee id already has user account',
        }))
        setUserManagementForm((prev) => ({
          ...prev,
          email: '',
          firstname: '',
          lastname: '',
          id_card: '',
          phone_no: '',
          department: '',
          position: '',
          roles: '',
          username: '',
        }))
      } else {
        setValidateEmployee((prev) => ({
          ...prev,
          employee_no: null,
        }))
        setUserManagementForm((prev) => ({
          ...prev,
          email: employeeUser?.email,
          firstname: employeeUser?.first_name,
          lastname: employeeUser?.last_name,
          employee_no: employeeUser?.employ_id,
          id_card: employeeUser?.identification_no,
          phone_no: employeeUser?.phone_no,
          department: department_ref?.data?.id,
          position: position_ref?.data?.id,
          username: employeeUser?.company_employee_id,
        }))
      }
    } else {
      setValidateEmployee((prev) => ({
        ...prev,
        employee_no: 'This employee id does not exist',
      }))
      setUserManagementForm((prev) => ({
        ...prev,
        email: '',
        firstname: '',
        lastname: '',
        id_card: '',
        phone_no: '',
        department: '',
        position: '',
        roles: '',
        username: '',
      }))
    }
  }, [employeeUser])

  useEffect(() => {
    if (state == 'CREATE_USERS_MANAGEMENT.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: 'Invitation sent to an user',
        message: '',
      })
      setSuccessModal(true)
    }
    if (state == 'EDIT_USERS_MANAGEMENT.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: 'User has been updated',
        message: '',
      })
      setSuccessModal(true)
      dispatch(getUserManagementByid(usersManagementId))
      setType('view')
    }
    if (state == 'DELETE_USERS_MANAGEMENT.SUCCESS' && isLoading == false) {
      setSuccessDeleteModal(true)
    }
    if (state == 'PUT_SEND_RESET_PASSWORD_PERMISSION.SUCCESS' && isLoading == false) {
      setSuccessResetPassword(true)
    }
  }, [state, isLoading])

  useEffect(() => {
    if (type == 'edit') {
      setUserManagementFormEdit((prev) => ({
        ...prev,
        id: usersInfo.id,
        admin_user_id: usersInfo.admin_user_id,
        status: usersInfo.status,
        email: usersInfo.email,
        firstname: usersInfo.first_name,
        lastname: usersInfo.last_name,
        employee_no: usersInfo.employee_no,
        id_card: usersInfo.id_card,
        phone_no: usersInfo.phone_no,
        // department: usersInfo.department,
        // position: usersInfo.position,
        // roles: usersInfo.roles,
        department: usersInfo?.department_ref?.id ?? '',
        display_department: usersInfo?.department ?? '',
        position: usersInfo?.position_ref?.id ?? '',
        display_position: usersInfo?.position ?? '',
        roles: usersInfo?.role_id ?? '',
        username: usersInfo.username,
        password: '',
      }))
      //alert(JSON.stringify(usersInfo))
      setTypeForm(usersInfo.employee_no == '' || usersInfo.employee_no == null ? 'non-employee' : 'employee')
    } else {
      setUserManagementFormView((prev) => ({
        ...prev,
        id: usersInfo.id,
        admin_user_id: usersInfo.admin_user_id,
        status: usersInfo.status,
        email: usersInfo.email,
        firstname: usersInfo.first_name,
        lastname: usersInfo.last_name,
        employee_no: usersInfo.employee_no,
        id_card: usersInfo.id_card,
        phone_no: usersInfo.phone_no,
        // department: usersInfo.department,
        // position: usersInfo.position,
        // roles: usersInfo.roles,
        department: usersInfo?.department_ref?.id ?? '',
        position: usersInfo?.position_ref?.id ?? '',
        // roles: usersInfo?.role_ref?.id ?? '',
        roles: usersInfo?.role_id,
        username: usersInfo.username,
      }))
    }

    // const department = departmentList?.find((v) => v.id == usersInfo.department)
    const department = departmentList?.find((v) => v.id == usersInfo?.department_ref?.id)
    const newPositions = department?.positions?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setPositions(newPositions)
  }, [usersInfo, type, departmentList])

  useEffect(() => {
    dispatch(accessRole())
    dispatch(getDepartmentsAndPositionsUseAuthen())
  }, [dispatch])

  useEffect(() => {
    const newDepartmentList = departmentList?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setDepartments(newDepartmentList)
  }, [departmentList])

  useEffect(() => {
    const department = departmentList?.find((v) => v.id == userManagementForm.department)
    const newPositions = department?.positions?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setPositions(newPositions)
  }, [userManagementForm.department, departmentList])

  useEffect(() => {
    const department = departmentList?.find((v) => v.id == userManagementFormEdit.department)
    const newPositions = department?.positions?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setPositions(newPositions)
  }, [userManagementFormEdit.department, departmentList])

  useEffect(() => {
    const department = departmentList?.find((v) => v.id == departmentSelected)
    const newPositions = department?.positions?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setPositions(newPositions)
  }, [departmentSelected, departmentList])

  useEffect(() => {
    const newaccessroleList = accessroleList?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setRoles(newaccessroleList)
  }, [accessroleList])

  useEffect(() => {
    const newUserList = usersList.map((value, index) => {
      const { access_role, ...rest } = value
      const list = [
        {
          text: 'Edit',
          action: () => editUsersManagement(value.id),
        },
        {
          text: 'Delete',
          action: () => deleteUsersManagements(value.id),
        },
      ]
      const deptColor = value.dept_color ? JSON.parse(value.dept_color) : null
      return {
        ...rest,
        no: limit * page - (99 - index),
        employee_no: value.employee_no == '' || value.employee_no == null ? '-' : value.employee_no,
        // access_role: access_role ? access_role.name : '',
        //access_role: access_role ? access_role : '',
        access_role: value.user_role_name ? value.user_role_name : '',
        email: (
          <div className="col-email text-bold link" onClick={() => viewUsersManagement(value.id)}>
            {value.email}
          </div>
        ),
        department:
          value.department != '' ? (
            <span
              className="badge"
              style={{
                color: deptColor?.text,
                borderColor: deptColor?.border,
                backgroundColor: deptColor?.background,
              }}
            >
              {value.department}
            </span>
          ) : (
            <span>-</span>
          ),
        position: value.position != '' ? value.position : '-',
        action: <CollapseMenu list={list} />,
        status: (
          <div className="status-badge">
            <GrStatusGoodSmall className={`status ${value.status == 'inactive' ? 'inactive' : ''}`} />
            {value.status}
          </div>
        ),
        user_type: value.employee_no == '' || value.employee_no == null ? 'Non-Employee' : 'Employee',
      }
    })

    setUsersList(newUserList)
  }, [usersList, limit, page, viewUsersManagement, deleteUsersManagements, editUsersManagement])

  useEffect(() => {
    const filter = {
      // skip: 1,
      skip: 0,
      limit: limit,
      filters: searchFilters.map((v) => v.name),
      name: searchFilters.map((v) => v.name).join(','),
      department: departmentSelected,
      position: positionSelected,
      role: roleSelected,
    }
    fetchData(filter)
  }, [fetchData, departmentSelected, positionSelected, roleSelected, limit])

  // onChange
  const onChangePagination = async (value) => {
    const filter = {
      skip: value.page,
      limit: limit,
      filters: searchFilters.map((v) => v.name),
      name: searchFilters.map((v) => v.name).join(','),
      department: departmentSelected,
      position: positionSelected,
      role: roleSelected,
    }
    fetchData(filter)
  }
  const onChangeUserManagementForm = (event) => {
    const { name, value } = event.target
    setUserManagementForm((prevState) => ({
      ...prevState,
      [name]: name == 'phone_no' ? value.replace(/[^0-9]/g, '') : value,
    }))
  }
  const onChangeUserManagementFormEdit = (event) => {
    const { name, value } = event.target
    setUserManagementFormEdit((prevState) => ({
      ...prevState,
      [name]: name == 'phone_no' ? value.replace(/[^0-9]/g, '') : value,
    }))
  }
  const onChangeDropdownEdit = (key, value) => {
    setUserManagementFormEdit((prev) => ({
      ...prev,
      [key]: value,
    }))
  }
  const onChangeDropdown = (key, value) => {
    setUserManagementForm((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }
  const onChangeRadio = (event) => {
    const { value } = event.target
    setTypeCreation(value)
  }

  // submit
  const onSubmitUser = () => {
    if (typeCreation === 'email-invitation') {
      const finalform =
        typeForm == 'employee'
          ? {
              data: {
                ...userManagementForm,
                employee_id_ref: employeeUser.id,
              },
            }
          : {
              data: {
                employee_no: '',
                email: userManagementForm.email,
                firstname: userManagementForm.firstname,
                id_card: userManagementForm.id_card,
                lastname: userManagementForm.lastname,
                phone_no: userManagementForm.phone_no,
                roles: userManagementForm.roles,
                company: CompanyInfo?.id,
              },
            }
      dispatch(createUserManagement(finalform))
      closeViewRolePanel()
      setConfirmCreateModal(false)
      clearForm()
    } else {
      const finalForm = {
        data: {
          ...userManagementForm,
          employee_id_ref: employeeUser.id,
          password: password,
        },
      }

      dispatch(createAccountUserWithPassword(finalForm))
      closeViewRolePanel()
      setConfirmCreateModal(false)
      clearForm()
    }
  }
  const onSubmitUpdateUser = () => {
    const finalform =
      typeForm == 'employee'
        ? {
            data: {
              ...userManagementFormEdit,
            },
          }
        : {
            data: {
              /*status: userManagementFormEdit.status,*/
              /*email: userManagementFormEdit.email,*/
              firstname: userManagementFormEdit.firstname,
              lastname: userManagementFormEdit.lastname,
              id_card: userManagementFormEdit.id_card,
              phone_no: userManagementFormEdit.phone_no,
              roles: userManagementFormEdit.roles,
            },
          }

    dispatch(editUserManagement(usersInfo.id, finalform)).then((res) => {
      if (res.type === 'EDIT_USERS_MANAGEMENT_SUCCESS') {
        const { id, employee_no, admin_user_id, firstname, lastname, id_card, email, phone_no, department, position } =
          userManagementFormEdit
        const request = {
          data: {
            employee_user_id: id,
            employee_no: employee_no,
            admin_user_id: admin_user_id,
            first_name: firstname,
            last_name: lastname,
            id_card: id_card,
            email: email,
            phone_no: phone_no,
            department: typeof department === 'object' ? null : department,
            position: typeof position === 'object' ? null : position,
          },
        }
      }
    })
    closeViewUserPanelEdit()
    setConfirmUpdateModal(false)
  }

  // request
  const fetchEmployeeData = () => {
    if (userManagementForm.employee_no && userManagementForm.employee_no != '')
      dispatch(fineEmployeeUser(userManagementForm.employee_no))
    else {
      setValidateEmployee((prev) => ({
        ...prev,
        employee_no: 'Please fill in Employee ID',
      }))
    }
  }

  const onSearch = () => {
    if (searchFilters?.length < 10) {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const filter = {
        // skip: 1,
        skip: 0,
        limit: limit,
        filters: [...searchFilters, newFilter].map((v) => v.name),
        name: [...searchFilters, newFilter].map((v) => v.name).join(','),
        department: departmentSelected,
        position: positionSelected,
        role: roleSelected,
      }
      fetchData(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }

  const onClearSearch = () => {
    const filter = {
      // skip: 1,
      skip: 0,
      limit: limit,
      filters: [],
      department: '',
      position: '',
      role: '',
    }
    fetchData(filter)
    setSearch('')
    setDepartmentSelected('')
    setPositionSelected('')
    setRoleSelected('')
    setSearchFilters([])
  }

  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)

    const filter = {
      // skip: 1,
      skip: 0,
      limit: limit,
      filters: newSearchFilters.map((v) => v.name),
      name: newSearchFilters.map((v) => v.name).join(','),
      department: departmentSelected,
      position: positionSelected,
      role: roleSelected,
    }
    fetchData(filter)
    setSearch('')
  }

  const SendResetPassword = () => {
    const { email } = usersInfo
    const request = {
      data: {
        email,
      },
    }
    dispatch(sendRequestResetPassword(request))
    setIsOpenSidePanelView(false)
    closeViewUserPanelEdit()
    setConfirmSentResetPasswordModal(false)
  }

  const confirmDelete = async () => {
    const request = {
      data: {
        status: 'delete',
      },
    }
    dispatch(deleteUsersManagement(usersManagementId, request))
    setConfirmDeleteModal(false)
  }

  // modal
  const openConfirmCreateModal = () => {
    const isValid = typeForm === 'employee' ? validateFormEmployee() : validateFormNonEmployee()
    if (isValid) {
      //---- เพิ่ม valid บัตรปชช ว่าต้องยังไม่มีใน บัญชี login
      const data_to_api = {
        id_card: userManagementForm.id_card,
      }
      dispatch(getAccountUserList(data_to_api)).then(({ type, data_info, data_meta }) => {
        //---- เพิ่มการตรวจสอบว่า เลขบัตร ปชช นี้ได้ทำการมีบัญชี Login หรือยัง
        if (type.endsWith('_SUCCESS')) {
          if (data_info.length > 0) {
            //--- เลขบัตร ปชช  มีในระบบแล้ว
            //alert(JSON.stringify(data_info[data_info.length - 1].company_ref.company_name_EN))
            const this_company_name = data_info[data_info.length - 1]?.company_ref?.company_name_TH
              ? data_info[data_info.length - 1]?.company_ref?.company_name_TH
              : ''
            setFailMessageModal({
              headline: 'Error Create user',
              message: 'This user already has account in ' + this_company_name,
            })
            setFailModal(true)
          } else {
            //--- เลขบัตร ปชช ยังไม่มีในบัญชี Login
            setConfirmCreateModal(true)
          }
        } else {
          //--- เลขบัตร ปชช ยังไม่มีในบัญชี Login
          setConfirmCreateModal(true)
        }
      })
    }
  }
  const openConfirmEditModal = () => {
    const isValid = typeForm === 'employee' ? validateFormEditEmployee() : validateFormEditNonEmployee()

    if (isValid) setConfirmUpdateModal(true)
  }

  const createUsersManagement = (type) => {
    setTypeForm(type)
    setIsOpenSidePanelCreate(true)
  }

  const confirmSendResetPassword = () => {
    setConfirmSentResetPasswordModal(true)
  }

  const confirmSuccess = async () => {
    setSuccessModal(false)
  }

  const confirmSuccessDelete = async () => {
    setSuccessDeleteModal(false)
  }

  const confirmSuccessResetPassword = async () => {
    setSuccessResetPassword(false)
  }

  const confirmFailModal = async () => {
    setFailModal(false)
  }

  const closeViewInformation = () => {
    setIsOpenSidePanelView(false)
    clearForm()
  }

  const closeViewRolePanel = () => {
    setIsOpenSidePanelCreate(false)
    clearForm()
  }

  const closeViewUserPanelEdit = () => {
    setIsOpenSidePanelEdit(false)
    clearForm()
  }

  // optional
  const validateFormEmployee = () => {
    let newErrors = {
      ...initValidateEmployeeForm,
    }

    Object.entries(userManagementForm).forEach(([key, value]) => {
      if (key === 'employee_no' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Employee ID'
      if (key === 'roles' && (value == undefined || value == '')) newErrors[key] = 'Please select Access Role'
      if (key === 'email' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Email'
      if (key === 'firstname' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Firstname'
      if (key === 'lastname' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Lastname'
      if (key === 'id_card' && (value == undefined || value == '')) newErrors[key] = 'Please fill in ID card'
      // if (key === 'phone_no' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Mobile'
      // if (key === 'department' && (value == undefined || value == '')) newErrors[key] = 'Please select Department'
      // if (key === 'position' && (value == undefined || value == '')) newErrors[key] = 'Please select Position'
    })
    setValidateEmployee(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  const validateFormNonEmployee = () => {
    let newErrors = {
      ...initValidateEmployeeForm,
    }

    Object.entries(userManagementForm).forEach(([key, value]) => {
      if (key === 'roles' && (value == undefined || value == '')) newErrors[key] = 'Please select Access Role'
      if (key === 'email' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Email'
      if (key === 'firstname' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Firstname'
      if (key === 'lastname' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Lastname'
      if (key === 'id_card' && (value == undefined || value == '')) newErrors[key] = 'Please fill in ID card'
      if (key === 'phone_no' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Mobile'
    })
    setValidateEmployee(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  const validateFormEditEmployee = () => {
    let newErrors = {
      ...initValidateEmployeeForm,
    }

    Object.entries(userManagementFormEdit).forEach(([key, value]) => {
      if (key === 'employee_no' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Employee ID'
      if (key === 'roles' && (value == undefined || value == '')) newErrors[key] = 'Please select Access Role'
      if (key === 'email' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Email'
      if (key === 'firstname' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Firstname'
      if (key === 'lastname' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Lastname'
      if (key === 'id_card' && (value == undefined || value == '')) newErrors[key] = 'Please fill in ID card'
      if (key === 'phone_no' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Mobile'
      //if (key === 'department' && (value == undefined || value == '')) newErrors[key] = 'Please select Department'
      //if (key === 'position' && (value == undefined || value == '')) newErrors[key] = 'Please select Position'
    })
    setValidateEmployee(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  const validateFormEditNonEmployee = () => {
    let newErrors = {
      ...initValidateEmployeeForm,
    }

    Object.entries(userManagementFormEdit).forEach(([key, value]) => {
      if (key === 'roles' && (value == undefined || value == '')) newErrors[key] = 'Please select Access Role'
      if (key === 'email' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Email'
      if (key === 'firstname' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Firstname'
      if (key === 'lastname' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Lastname'
      if (key === 'id_card' && (value == undefined || value == '')) newErrors[key] = 'Please fill in ID card'
      if (key === 'phone_no' && (value == undefined || value == '')) newErrors[key] = 'Please fill in Mobile'
    })
    setValidateEmployee(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  const clearForm = () => {
    setUserManagementForm(initUserManagementForm)
    setUserManagementFormEdit(initUserManagementForm)
    setValidateEmployee(initValidateEmployeeForm)
    setTypeCreation('email-invitation')
    setPasswordForReset('')
    dispatch(setEmployeeUser({}))
  }

  const generateRandomString = () => {
    const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz'
    const numbers = '0123456789'
    const specialChars = '!@#$%^&*()_+{}:"<>?|[];,./`~'

    const getRandomChar = (chars) => chars[Math.floor(Math.random() * chars.length)]

    const randomString = [
      getRandomChar(upperCaseChars),
      getRandomChar(lowerCaseChars),
      getRandomChar(numbers),
      getRandomChar(specialChars),
    ]

    const allChars = upperCaseChars + lowerCaseChars + numbers + specialChars
    while (randomString.length < 8) {
      randomString.push(getRandomChar(allChars))
    }

    for (let i = randomString.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1))
      ;[randomString[i], randomString[j]] = [randomString[j], randomString[i]]
    }

    return randomString.join('')
  }

  const regeneratePassword = () => {
    const generatedPassword = generateRandomString()
    setPassword(generatedPassword)
  }

  const copyPassword = () => {
    navigator.clipboard
      .writeText(password)
      .then(() => {
        alert('Text copied to clipboard')
      })
      .catch((err) => {
        alert('Failed to copy text')
      })
  }

  // password for reset
  const [passwordForReset, setPasswordForReset] = useState('')

  const regeneratePasswordAndReset = () => {
    const generatedPassword = generateRandomString()
    const request = {
      data: {
        user_id: userManagementFormView.id,
        password: generatedPassword,
      },
    }
    dispatch(resetNewPassword(request))
    setPasswordForReset(generatedPassword)
  }
  const copyNewPassword = () => {
    navigator.clipboard
      .writeText(passwordForReset)
      .then(() => {
        alert('Text copied to clipboard')
      })
      .catch((err) => {
        alert('Failed to copy text')
      })
  }

  const regeneratePasswordForEditForm = () => {
    const generatedPassword = generateRandomString()
    setUserManagementFormEdit((prev) => ({
      ...prev,
      password: generatedPassword,
    }))
  }
  const copyNewPasswordForEdit = () => {
    navigator.clipboard
      .writeText(userManagementFormEdit.password)
      .then(() => {
        alert('Text copied to clipboard')
      })
      .catch((err) => {
        alert('Failed to copy text')
      })
  }

  return (
    <Div>
      <header>
        <div className="content-title">{t('users_management')}</div>
        <div className="">{t('users_management_sub_header')}</div>
        <div className="content-header">
          <div className="search-container">
            <SearchText
              className="mb-1-rem"
              placeholder={t('search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSubmit={onSearch}
              onClear={onClearSearch}
            />
            <Dropdown
              placeHolder={t('department')}
              optionList={departments}
              value={departmentSelected}
              onChange={(value) => setDepartmentSelected(value)}
            />
            <Dropdown
              placeHolder={t('position')}
              optionList={positions}
              value={positionSelected}
              onChange={(value) => setPositionSelected(value)}
            />
            <Dropdown
              placeHolder={t('access_role')}
              optionList={roles}
              value={roleSelected}
              onChange={(value) => setRoleSelected(value)}
            />
            <ButtonOutline className="d-flex justify-content-center" onClick={onClearSearch}>
              {t('clear')}
            </ButtonOutline>
          </div>
          <div className="badge-search-content">
            {searchFilters.map((v) => (
              <div className="badge" key={v.key}>
                <span className="name">{v.name}</span>{' '}
                <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
              </div>
            ))}
          </div>
        </div>
      </header>
      <div className="table-wrapper">
        <div className="table-header">
          <div className="group">
            <div className="table-title">{t('team_members')}</div>
            <div className="table-total">
              {total} {t('users')}
            </div>
          </div>

          <div>
            {/* <Button onClick={createUsersManagement} id="create" append={<img src={iconPlus} />}>
              Create New User
            </Button> */}
            <ButtonCollapsible id="create" text={t('create_new_user')} icon={iconPlus} list={listAction} />
          </div>
        </div>

        <Table
          columns={UsersColumn}
          data={users}
          onStateChange={onChangePagination}
          pageCount={pageCount}
          page={page}
        />
      </div>

      {/* modal */}
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={confirmDelete}
        title={t('confirm_delete_user_header')}
        description={t('confirm_delete_user_sub_header')}
      />
      <DialogConfirm
        open={Boolean(confirmCreateModal)}
        onClose={() => setConfirmCreateModal(false)}
        onSubmit={onSubmitUser}
        icon={userIcon}
        title={t('confirm_create_user_header')}
        nameItem={`${userManagementForm.firstname} ${userManagementForm.lastname}`}
        description={userManagementForm.email}
        textYes={t('confirm')}
        textNo={t('back')}
      />
      <DialogConfirm
        open={Boolean(confirmUpdateModal)}
        onClose={() => setConfirmUpdateModal(false)}
        onSubmit={onSubmitUpdateUser}
        icon={userIcon}
        title={t('confirm_update_user_header')}
        nameItem={`${userManagementFormEdit.firstname} ${userManagementFormEdit.lastname}`}
        description={userManagementFormEdit.email}
        textYes={t('confirm')}
        textNo={t('back')}
      />
      <DialogConfirm
        open={Boolean(confirmSentResetPasswordModal)}
        onClose={() => setConfirmSentResetPasswordModal(false)}
        onSubmit={SendResetPassword}
        icon={resetIcon}
        title={t('send_reset_password')}
        subTitle={t('reset_password_subTitle')}
        nameItem={`${userManagementFormView.firstname} ${userManagementFormView.lastname}`}
        description={userManagementFormView.email}
        textYes={t('confirm')}
        textNo={t('back')}
      />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogSuccess
        open={Boolean(successDeleteModal)}
        onClose={() => setSuccessDeleteModal(false)}
        onSubmit={confirmSuccessDelete}
        icon={deleteIcon}
        title={t('success_delete_user_message')}
        textYes={t('done')}
      />
      <DialogSuccess
        open={Boolean(successResetPassword)}
        onClose={() => setSuccessResetPassword(false)}
        onSubmit={confirmSuccessResetPassword}
        icon={resetIcon}
        title={t('reset_link_sent_to_an_user')}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />

      {/* Side panel */}
      <SidePanel isOpen={isOpenSidePanelCreate} setIsOpen={setIsOpenSidePanelCreate} width={450}>
        <Div>
          <div className="side-panel-container">
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2-rem">
                <div className="create-access-role-header">{t('create_user')}</div>
                <LiaTimesSolid size={25} onClick={closeViewRolePanel} className="icon" />
              </div>
              <div className="divider"></div>
              <div className="mb-2-rem">
                <span className="input-header mr-1-rem">{t('user_type')}</span>
                {typeForm === 'employee' ? (
                  <span className="badge-user-type">{t('employee')}</span>
                ) : (
                  <span className="badge-user-type non-employee">{t('non-employee')}</span>
                )}
              </div>

              {typeForm == 'employee' ? (
                <>
                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('employee_id')} *</div>
                    <div className="input-new-2">
                      <TextField
                        className={`${validateEmployee.employee_no ? 'error-border' : ''}`}
                        name="employee_no"
                        placeholder={t('employee_id')}
                        value={userManagementForm.employee_no}
                        onChange={onChangeUserManagementForm}
                      />
                      <ButtonOutline onClick={fetchEmployeeData}>{t('search')}</ButtonOutline>
                    </div>
                    {validateEmployee.employee_no && (
                      <div className="error mt-1-rem">{validateEmployee.employee_no}</div>
                    )}
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">Select how to create account</div>
                    <div className="input-new">
                      <RadioButton
                        optionList={initLangRadioOptionList}
                        value={typeCreation}
                        handleChange={onChangeRadio}
                      />
                    </div>
                  </div>

                  {typeCreation === 'create-password' && (
                    <div className="mb-1-rem">
                      <div className="input-header mb-1-rem">Password for 1st time log-in*</div>
                      <div className="input-new">
                        <FlexContainer className="align-items-center">
                          <PasswordField value={password} disabled={true} optionalFunc={regeneratePassword} />
                          <ButtonOutline append={<FiCopy />} onClick={copyPassword}>
                            Copy
                          </ButtonOutline>
                        </FlexContainer>
                        <p>Pasword is autogenerated</p>
                      </div>
                    </div>
                  )}

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('access_role')} *</div>
                    <div className="input-new">
                      <Dropdown
                        id="roles"
                        className={`${validateEmployee.roles ? 'error-border' : ''}`}
                        value={userManagementForm.roles}
                        optionList={roles}
                        onChange={(value) => onChangeDropdown('roles', value)}
                      />
                      {validateEmployee.roles && <div className="error mt-1-rem">{validateEmployee.roles}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">Username *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.username ? 'error-border' : ''}`}
                        name="username"
                        placeholder={'Username'}
                        value={userManagementForm.username}
                        onChange={onChangeUserManagementForm}
                        // disabled={true}
                      />
                      <p>
                        Username is set to be <b>{companyInfo.username_field}</b>
                      </p>
                      {validateEmployee.username && <div className="error mt-1-rem">{validateEmployee.username}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('email')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.email ? 'error-border' : ''}`}
                        name="email"
                        placeholder={t('email')}
                        value={userManagementForm.email}
                        onChange={onChangeUserManagementForm}
                        disabled={true}
                      />
                      {validateEmployee.email && <div className="error mt-1-rem">{validateEmployee.email}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('first_name')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.firstname ? 'error-border' : ''}`}
                        name="firstname"
                        placeholder={t('first_name')}
                        value={userManagementForm.firstname}
                        onChange={onChangeUserManagementForm}
                        disabled={true}
                      />
                      {validateEmployee.firstname && <div className="error mt-1-rem">{validateEmployee.firstname}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('last_name')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.lastname ? 'error-border' : ''}`}
                        name="lastname"
                        placeholder={t('last_name')}
                        value={userManagementForm.lastname}
                        onChange={onChangeUserManagementForm}
                        disabled={true}
                      />
                      {validateEmployee.lastname && <div className="error mt-1-rem">{validateEmployee.lastname}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('id_card')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.id_card ? 'error-border' : ''}`}
                        name="id_card"
                        placeholder={t('id_card')}
                        value={userManagementForm.id_card}
                        onChange={onChangeUserManagementForm}
                        disabled={true}
                      />
                      {validateEmployee.id_card && <div className="error mt-1-rem">{validateEmployee.id_card}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('mobile')}</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.phone_no ? 'error-border' : ''}`}
                        name="phone_no"
                        placeholder={t('mobile')}
                        value={userManagementForm.phone_no}
                        onChange={onChangeUserManagementForm}
                        disabled={true}
                      />
                      {validateEmployee.phone_no && <div className="error mt-1-rem">{validateEmployee.phone_no}</div>}
                    </div>
                  </div>

                  {/* <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('departments')} *</div>
                    <div className="input-new">
                      <Dropdown
                        id="department"
                        className={`${validateEmployee.department ? 'error-border' : ''}`}
                        value={userManagementForm.department}
                        optionList={departments}
                        onChange={(value) => onChangeDropdown('department', value)}
                        disabled={true}
                      />
                      {validateEmployee.department && (
                        <div className="error mt-1-rem">{validateEmployee.department}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('positions')} *</div>
                    <div className="input-new">
                      <Dropdown
                        id="position"
                        className={`${validateEmployee.position ? 'error-border' : ''}`}
                        value={userManagementForm.position}
                        optionList={positions}
                        onChange={(value) => onChangeDropdown('position', value)}
                        disabled={true}
                      />
                      {validateEmployee.position && <div className="error mt-1-rem">{validateEmployee.position}</div>}
                    </div>
                  </div> */}
                </>
              ) : (
                <>
                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('email')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.email ? 'error-border' : ''}`}
                        name="email"
                        placeholder={t('email')}
                        value={userManagementForm.email}
                        onChange={onChangeUserManagementForm}
                      />
                      {validateEmployee.email && <div className="error mt-1-rem">{validateEmployee.email}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('first_name')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.firstname ? 'error-border' : ''}`}
                        name="firstname"
                        placeholder={t('first_name')}
                        value={userManagementForm.firstname}
                        onChange={onChangeUserManagementForm}
                      />
                      {validateEmployee.firstname && <div className="error mt-1-rem">{validateEmployee.firstname}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('last_name')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.lastname ? 'error-border' : ''}`}
                        name="lastname"
                        placeholder={t('last_name')}
                        value={userManagementForm.lastname}
                        onChange={onChangeUserManagementForm}
                      />
                      {validateEmployee.lastname && <div className="error mt-1-rem">{validateEmployee.lastname}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('id_card')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.id_card ? 'error-border' : ''}`}
                        name="id_card"
                        placeholder={t('id_card')}
                        value={userManagementForm.id_card}
                        onChange={onChangeUserManagementForm}
                      />
                      {validateEmployee.id_card && <div className="error mt-1-rem">{validateEmployee.id_card}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('mobile')}</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.phone_no ? 'error-border' : ''}`}
                        name="phone_no"
                        placeholder={t('mobile')}
                        value={userManagementForm.phone_no}
                        onChange={onChangeUserManagementForm}
                      />
                      {validateEmployee.phone_no && <div className="error mt-1-rem">{validateEmployee.phone_no}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('access_role')} *</div>
                    <div className="input-new">
                      <Dropdown
                        className={`${validateEmployee.roles ? 'error-border' : ''}`}
                        id="roles"
                        value={userManagementForm.roles}
                        optionList={roles}
                        onChange={(value) => onChangeDropdown('roles', value)}
                      />
                      {validateEmployee.roles && <div className="error mt-1-rem">{validateEmployee.roles}</div>}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div>
              <div className="divider"></div>
              <div className="d-flex justify-content-flex-end">
                <ButtonOutline onClick={closeViewRolePanel} className="mr-1-rem">
                  {t('cancel')}
                </ButtonOutline>
                <Button onClick={openConfirmCreateModal} id="createSidePanel">
                  {t('create')}
                </Button>
              </div>
            </div>
          </div>
        </Div>
      </SidePanel>

      <SidePanel isOpen={isOpenSidePanelEdit} setIsOpen={setIsOpenSidePanelEdit} width={450}>
        <Div>
          <div className="side-panel-container">
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2-rem">
                <div className="create-access-role-header">{t('edit_employee')}</div>
                <LiaTimesSolid size={25} onClick={closeViewUserPanelEdit} className="icon" />
              </div>
              <div className="divider"></div>
              <div className="mb-2-rem">
                <span className="input-header mr-1-rem">{t('user_type')}</span>
                {typeForm === 'employee' ? (
                  <span className="badge-user-type">{t('employee')}</span>
                ) : (
                  <span className="badge-user-type non-employee">{t('non-employee')}</span>
                )}
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">{t('status')}</div>
                <div className="input-new">
                  <Dropdown
                    isStatus={true}
                    name="status"
                    optionList={initStatusDropdown}
                    value={userManagementFormEdit?.status}
                    onChange={(value) => onChangeDropdownEdit('status', value)}
                  />
                </div>
              </div>

              {typeForm == 'employee' ? (
                <>
                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('employee_id')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.employee_no ? 'error-border' : ''}`}
                        name="employee_no"
                        placeholder={t('employee_id')}
                        value={userManagementFormEdit.employee_no}
                        onChange={onChangeUserManagementFormEdit}
                        disabled={true}
                      />
                      {validateEmployee.employee_no && (
                        <div className="error mt-1-rem">{validateEmployee.employee_no}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('email')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.email ? 'error-border' : ''}`}
                        name="email"
                        placeholder={t('email')}
                        value={userManagementFormEdit.email}
                        onChange={onChangeUserManagementFormEdit}
                      />
                      {validateEmployee.email && <div className="error mt-1-rem">{validateEmployee.email}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">Username *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.username ? 'error-border' : ''}`}
                        name="username"
                        placeholder={'Username'}
                        value={userManagementFormEdit.username}
                        disabled={true}
                      />
                      <p>
                        Username is set to be <b>{companyInfo.username_field}</b>
                      </p>
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">Reset Password * on Edit</div>
                    <div className="input-new">
                      <FlexContainer className="align-items-center">
                        <PasswordField
                          value={userManagementFormEdit.password}
                          disabled={true}
                          optionalFunc={regeneratePasswordForEditForm}
                        />
                        <ButtonOutline append={<FiCopy />} onClick={copyNewPasswordForEdit}>
                          Copy
                        </ButtonOutline>
                      </FlexContainer>
                      <p>Pasword is autogenerated</p>
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('first_name')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.firstname ? 'error-border' : ''}`}
                        name="firstname"
                        placeholder={t('first_name')}
                        value={userManagementFormEdit.firstname}
                        onChange={onChangeUserManagementFormEdit}
                      />
                      {validateEmployee.firstname && <div className="error mt-1-rem">{validateEmployee.firstname}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('last_name')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.lastname ? 'error-border' : ''}`}
                        name="lastname"
                        placeholder={t('last_name')}
                        value={userManagementFormEdit.lastname}
                        onChange={onChangeUserManagementFormEdit}
                      />
                      {validateEmployee.lastname && <div className="error mt-1-rem">{validateEmployee.lastname}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('id_card')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.id_card ? 'error-border' : ''}`}
                        name="id_card"
                        placeholder={t('id_card')}
                        value={userManagementFormEdit.id_card}
                        onChange={onChangeUserManagementFormEdit}
                      />
                      {validateEmployee.id_card && <div className="error mt-1-rem">{validateEmployee.id_card}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('mobile')}</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.phone_no ? 'error-border' : ''}`}
                        name="phone_no"
                        placeholder={t('mobile')}
                        value={userManagementFormEdit.phone_no}
                        onChange={onChangeUserManagementFormEdit}
                      />
                      {validateEmployee.phone_no && <div className="error mt-1-rem">{validateEmployee.phone_no}</div>}
                    </div>
                  </div>

                  {/* <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('department')}</div>
                    <div className="input-new">
                      <TextField
                        name="department"
                        placeholder="department"
                        value={userManagementFormEdit.display_department}
                        disabled={true}
                      />
                    </div>
                  </div> */}

                  {/* <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('positions')}</div>
                    <div className="input-new">
                      <TextField
                        name="position"
                        placeholder="position"
                        value={userManagementFormEdit.display_position}
                        disabled={true}
                      />
                    </div>
                  </div> */}

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('access_role')}</div>
                    <div className="input-new">
                      <Dropdown
                        className={`${validateEmployee.roles ? 'error-border' : ''}`}
                        value={userManagementFormEdit.roles}
                        optionList={roles}
                        onChange={(value) => onChangeDropdownEdit('roles', value)}
                      />
                      {validateEmployee.roles && <div className="error mt-1-rem">{validateEmployee.roles}</div>}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('email')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.email ? 'error-border' : ''}`}
                        name="email"
                        placeholder={t('email')}
                        value={userManagementFormEdit.email}
                        onChange={onChangeUserManagementFormEdit}
                        disabled={true}
                      />
                      {validateEmployee.email && <div className="error mt-1-rem">{validateEmployee.email}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('first_name')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.firstname ? 'error-border' : ''}`}
                        name="firstname"
                        placeholder={t('first_name')}
                        value={userManagementFormEdit.firstname}
                        onChange={onChangeUserManagementFormEdit}
                      />
                      {validateEmployee.firstname && <div className="error mt-1-rem">{validateEmployee.firstname}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('last_name')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.lastname ? 'error-border' : ''}`}
                        name="lastname"
                        placeholder={t('last_name')}
                        value={userManagementFormEdit.lastname}
                        onChange={onChangeUserManagementFormEdit}
                      />
                      {validateEmployee.lastname && <div className="error mt-1-rem">{validateEmployee.lastname}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('id_card')} *</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.id_card ? 'error-border' : ''}`}
                        name="id_card"
                        placeholder={t('id_card')}
                        value={userManagementFormEdit.id_card}
                        onChange={onChangeUserManagementFormEdit}
                      />
                      {validateEmployee.id_card && <div className="error mt-1-rem">{validateEmployee.id_card}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('mobile')}</div>
                    <div className="input-new">
                      <TextField
                        className={`${validateEmployee.phone_no ? 'error-border' : ''}`}
                        name="phone_no"
                        placeholder={t('mobile')}
                        value={userManagementFormEdit.phone_no}
                        onChange={onChangeUserManagementFormEdit}
                      />
                      {validateEmployee.phone_no && <div className="error mt-1-rem">{validateEmployee.phone_no}</div>}
                    </div>
                  </div>

                  <div className="mb-1-rem">
                    <div className="input-header mb-1-rem">{t('access_role')}</div>
                    <div className="input-new">
                      <Dropdown
                        className={`${validateEmployee.roles ? 'error-border' : ''}`}
                        value={userManagementFormEdit.roles}
                        optionList={roles}
                        onChange={(value) => onChangeDropdownEdit('roles', value)}
                      />
                      {validateEmployee.roles && <div className="error mt-1-rem">{validateEmployee.roles}</div>}
                    </div>
                  </div>
                </>
              )}
            </div>

            <div>
              <div className="divider"></div>
              <div className="d-flex justify-content-flex-end">
                <ButtonOutline onClick={closeViewUserPanelEdit} className="mr-1-rem">
                  {t('cancel')}
                </ButtonOutline>
                <Button onClick={openConfirmEditModal} id="saveEdit">
                  {t('save')}
                </Button>
              </div>
            </div>
          </div>
        </Div>
      </SidePanel>

      <SidePanel isOpen={isOpenSidePanelView} setIsOpen={setIsOpenSidePanelView} width={450}>
        <Div>
          <div className="side-panel-container-2">
            <div className="d-flex justify-content-between align-items-center mb-2-rem">
              <div className="create-access-role-header">{t('member_information')}</div>
              <LiaTimesSolid size={25} onClick={closeViewInformation} className="icon" />
            </div>
          </div>
          <div className="bg-cover-info"></div>
          <div className="side-panel-container-2">
            <div className="create-access-role-header mb-1-rem d-flex f-wrap align-items-center">
              <span className="mr-1-rem">
                {userManagementFormView.firstname} {userManagementFormView.lastname}
              </span>
              <span className="badge-status">
                <GrStatusGoodSmall
                  className={`status ${userManagementFormView.status == 'inactive' ? 'inactive' : ''}`}
                />
                {userManagementFormView.status}
              </span>
            </div>

            <div className="mb-1-rem">
              {t('employee_id')} : <span className="font-pimary">{userManagementFormView.employee_no}</span>
            </div>

            <div className="d-flex justify-content-flex-start mb-1-rem">
              <ButtonOutline className="button-ml" onClick={confirmSendResetPassword}>
                {t('send_reset_password')}
              </ButtonOutline>
              <Button onClick={() => editUsersManagement(userManagementFormView.id)}>{t('Edit')}</Button>
            </div>

            <div className="mb-1-rem">
              <div className="input-header">{t('email')}</div>
              <div className="input-new">
                <TextField name="email" placeholder="Email" value={userManagementFormView.email} disabled={true} />
              </div>
            </div>

            <div className="mb-1-rem">
              <div className="input-header">{t('username')} *</div>
              <div className="input-new">
                <TextField
                  name="username"
                  placeholder="username"
                  value={userManagementFormView.username}
                  disabled={true}
                />
                <p>
                  Username is set to be <b>{companyInfo.username_field}</b>
                </p>
              </div>
            </div>

            <div className="mb-1-rem">
              <div className="input-header">Reset Password *</div>
              <div className="input-new">
                <FlexContainer className="align-items-center">
                  <PasswordField value={passwordForReset} disabled={true} optionalFunc={regeneratePasswordAndReset} />
                  <ButtonOutline append={<FiCopy />} onClick={copyNewPassword}>
                    Copy
                  </ButtonOutline>
                </FlexContainer>
                <p>Pasword is autogenerated</p>
              </div>
            </div>

            <div className="mb-1-rem">
              <div className="input-header">{t('mobile')}</div>
              <div className="input-new">
                <TextField
                  name="phone_no"
                  placeholder="Mobile"
                  value={userManagementFormView.phone_no}
                  disabled={true}
                />
              </div>
            </div>

            <div className="mb-1-rem">
              <div className="input-header">{t('access_role')}</div>
              <div className="input-new">
                <Dropdown value={userManagementFormView.roles} optionList={roles} disabled={true} />
              </div>
            </div>

            {/* <div className="mb-1-rem">
              <div className="input-header">{t('department_id')}</div>
              <div className="input-new">
                <Dropdown value={userManagementFormView.department} optionList={departments} disabled={true} />
              </div>
            </div>

            <div className="mb-1-rem">
              <div className="input-header">{t('positions')}</div>
              <div className="input-new">
                <Dropdown value={userManagementFormView.position} optionList={positions} disabled={true} />
              </div>
            </div> */}
          </div>
        </Div>
      </SidePanel>
    </Div>
  )
}

export default UsersList
