import TextField from './TextField'
import styled from 'styled-components'

import iconSearch from 'assets/images/icon-search.svg'
import iconClose from 'assets/images/icon-close.svg'

const StyledTextField = styled(TextField)`
  padding: 10px;
  .prefix,
  .postfix {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .postfix {
    cursor: pointer;
  }
`

const SearchText = ({ value, onSubmit = () => {}, onClear = () => {}, ...props }) => {
  const handleSubmit = (e) => {
    if (e.keyCode === 13) {
      onSubmit(value)
    }
  }

  return (
    <StyledTextField
      startAdornment={<img src={iconSearch} />}
      endAdornment={value ? <img src={iconClose} onClick={onClear} /> : null}
      value={value}
      onKeyDown={handleSubmit}
      {...props}
    />
  )
}

export default SearchText
