import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

export default function InputTextArea({
  className,
  placeHolder = 'type here',
  initValue,
  onChange,
  onBlur,
  name,
  padding = '5px 15px',
  margin = '0',
  width = '400px',
  height = '100px',
  borderWidth = '2px',
  borderColor = '#444',
  borderRadius = '10px',
  backgroundColor = 'transparent',
  fontSize = '18px',
  fontColor = '#444',
  fontFamily = 'inherit',
  resizeDisable,
  disabled,
  style,
  isError = false,
}) {
  const inputReference = useRef(null)
  const [isInputFocus, setIsInputFocus] = useState(false)

  const [txt, setTxt] = useState('')

  useEffect(() => {
    setTxt(initValue)
  }, [initValue])

  const onWrpClickHandler = () => {
    if (!isInputFocus) {
      setIsInputFocus(true)
      inputReference.current.focus()
    }
  }

  const blurHandler = (e) => {
    setIsInputFocus(false)
    if (onBlur) {
      onBlur(e)
    }
  }

  return (
    <Styled
      className={className}
      padding={padding}
      margin={margin}
      width={width}
      height={height}
      borderRadius={borderRadius}
      borderWidth={borderWidth}
      borderColor={!isError ? borderColor : '#d92d20 !important'}
      backgroundColor={backgroundColor}
      fontSize={fontSize}
      fontColor={fontColor}
      onClick={onWrpClickHandler}
      // value={txt}
      defaultValue={txt}
      name={name}
      ref={inputReference}
      placeholder={placeHolder}
      onBlur={blurHandler}
      onChange={(e) => onChange && onChange(e)}
      fontFamily={fontFamily}
      resizeDisable={resizeDisable}
      disabled={disabled}
      style={style}
    />
  )
}

const Styled = styled.textarea`
  position: relative;
  display: flex;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  border-width: ${({ borderWidth }) => borderWidth};
  border-color: ${({ borderColor }) => borderColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  border-style: solid;
  background-color: ${({ backgroundColor }) => backgroundColor};
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ fontColor }) => fontColor};
  resize: ${({ resizeDisable }) => (resizeDisable ? 'none' : 'both')};
  font-family: ${({ fontFamily }) => (fontFamily ? fontFamily : '')};

  input,
  input:focus-visible,
  input:focus {
    border: none;
    outline: none;
  }

  input {
    display: flex;
    flex: 1;
    padding: 10px;
    font-size: 16px;
    background: none;
    user-select: none;
  }
  .icon {
    position: relative;
    margin-right: 5px;
    top: 10px;
    color: #555;
  }

  .sf_icon {
    position: relative;
    margin-left: 5px;
    top: 10px;
  }
  .sf_btn {
    position: relative;
  }
  .icon_eye {
    padding: 5px;
    cursor: pointer;
    margin-right: 5px;
  }
  .icon_eye svg {
    position: relative;
    top: 4px;
  }
`
