import { useSelector } from 'react-redux'
import styled from 'styled-components'
import dayjs from 'dayjs'

import { FIELD_TYPE, MAP_FILTER_CONDIION_WORD } from '../../utils/constants'
import { getFirstSplit } from '../../utils/common'
import { selectCmsContentType, selectCmsByType } from '../../redux/selectors'

import iconClose from '../../assets/images/icon-close.svg'

const Div = styled.div`
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: -6px;
    padding: 0;
    overflow: hidden;

    li {
      display: flex;
      align-items: center;
      margin: 6px;
      padding: 10px 16px;
      font-size: 14px;
      font-weight: 600;
      background: var(--Primary-50);
      color: var(--Primary-700);
      box-shadow: var(--Shadow-xs);
      border-radius: 6px;

      img {
        margin-left: 12px;
        color: var(--Primary-700);
        cursor: pointer;
      }
    }
  }
`

const FilterList = ({ type, onRemove, ...props }) => {
  const { attributes } = useSelector(selectCmsContentType(type))
  const { filter } = useSelector(selectCmsByType(type))

  const format = (field, value) => {
    if (attributes[field].type === FIELD_TYPE.datetime) {
      return dayjs(value).format('YYYY-MM-DD HH:mm')
    } else if (attributes[field].type === FIELD_TYPE.time) {
      const [hour, minute] = value.split(':')
      return `${hour}:${minute}`
    } else {
      return value
    }
  }

  if (!filter?.length) {
    return null
  }

  return (
    <Div {...props}>
      <ul>
        {filter?.map((v, i) => {
          const field = getFirstSplit(v.field)
          const condition = MAP_FILTER_CONDIION_WORD[v.condition]
          const value = format(field, v.value)

          return (
            <li key={i}>
              <span>{`${field} ${condition} ${value}`}</span>
              <img src={iconClose} onClick={() => onRemove(i)} />
            </li>
          )
        })}
      </ul>
    </Div>
  )
}

export default FilterList
