import { Button, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { RiSearchLine } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'

import { Input } from 'components/form2/form-input'

const defaultValues = {
  name: '',
  status: '',
}

const FilterTaskBoard = forwardRef(function FilterTaskBoard(props, ref) {
  const { t } = useTranslation()
  const { onFormChange } = props

  const form = useForm({ defaultValues })

  const { control, watch } = form
  // const watchStatus = watch('status')
  const watchAllFields = watch()

  useImperativeHandle(ref, () => ({
    reset() {
      form.reset(defaultValues)
    },
  }))

  const handleClear = () => {
    form.reset(defaultValues)
    onFormChange && typeof onFormChange === 'function' && onFormChange(defaultValues)
  }

  useEffect(() => {
    // onFormChange && typeof onFormChange === 'function' && onFormChange(watchAllFields)
  }, [])

  return (
    <Stack>
      <Form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()

            onFormChange && typeof onFormChange === 'function' && onFormChange(watchAllFields)
          }
        }}
      >
        <section>
          <Controller
            name="name"
            control={control}
            render={({ field }) => {
              return (
                <Input
                  {...field}
                  placeholder={t('op_department_operation_search')}
                  sx={{ minWidth: '500px' }}
                  startAdornment={<RiSearchLine fontSize={16} style={{ margin: '0 0 0 0.75rem' }} />}
                />
              )
            }}
          />
        </section>

        <section className="filter-actions">
          <MuiButton variant="contained" className="gray" onClick={handleClear}>
            {t('clear')}
          </MuiButton>
        </section>
      </Form>
    </Stack>
  )
})

export default FilterTaskBoard

const Form = styled('form')`
  display: flex;
  /* flex-direction: column; */
  gap: 1rem;
  flex-wrap: wrap;

  section {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &.filter-actions {
      align-items: flex-end;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  section > label {
    color: 'var(--Gray-900)';
    font-size: 14px;

    & .error {
      color: red !important;
    }
  }
`

const MuiButton = styled(Button)`
  font-family: 'Inter', 'Noto Sans Thai';
  color: var(--Main-Button-Font);
  background-color: var(--Main-Button-Bg);
  border-radius: 0.5rem;
  text-transform: capitalize;
  box-shadow: none;
  height: fit-content;
  padding: 0.5rem 1rem;

  &:hover {
    color: var(--Main-Button-Font-Hover);
    background-color: var(--Main-Button-Bg-Hover);
  }

  &.gray {
    color: var(--Gray-900);
    background-color: var(--Base-White);
    border: 1px solid var(--Gray-200);

    &:hover {
      color: var(--Gray-900);
      background-color: var(--Gray-50);
      box-shadow: 0px 2px 2px var(--Gray-50);
    }
  }
`
