import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import SidePanel from '../../../components/common/SidePanel'
import Table from '../../../components/common/Table'
import Checkbox from '../../../components/form/Checkbox'
import SearchText from '../../../components/form/SearchText'
import Button from '../../../components/form/button/Button'
import ButtonOutline from '../../../components/form/button/ButtonOutline'
import DocumentRequestApprove from '../../../components/widget/combo/DocumentRequestApprove'
import DocumentShareAndSendEmail from '../../../components/widget/combo/DoucumentShareAndSendEmail'
import CapsuleStatus from '../../../components/widget/element/CapsuleStatus'
import DocumentOptionButton from '../../../components/widget/element/DocumentOptionButton'
import DropdownSelect from '../../../components/widget/element/DropDownSelect'
import ModalBox from '../../../components/widget/element/ModalBox'
import { getBusinessDocumentList } from '../../../redux/actions/document'
import { selectDocument } from '../../../redux/selectors'
import { convertDateFormat } from '../../../utils/helper'
import DialogDocument from '../DialogDocument'

export default function PurchaseOrderDocument() {
  const [searchParams, setSearchParams] = useSearchParams()
  const docType = searchParams.get('doctype')
  const dispatch = useDispatch()
  const lastEditList = [
    {
      text: 'Today',
      value: 'today',
    },
    {
      text: 'Last 7 days',
      value: 'last7',
    },
    {
      text: 'Last 30 days',
      value: 'last30',
    },
  ]

  const HEADER_COLUMN = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'Seller Name',
      accessor: 'seller',
      disableSortBy: false,
    },
    {
      Header: 'Attn',
      accessor: 'contact_person',
      disableSortBy: false,
    },
    // {
    //   Header: 'Net total',
    //   accessor: 'net_total',
    //   disableSortBy: false,
    // },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    // {
    //   Header: '',
    //   accessor: 'edit_quotation',
    //   disableSortBy: false,
    // },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const HEADER_COLUMN_CHEQUE_PAYMENT_JOURNAL = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'รายละเอียดการสั่งจ่ายเช็ค',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const HEADER_COLUMN_PAYMENT_JOURNAL = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'รายละเอียด สมุดรายวันจ่ายเช็ค',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const HEADER_COLUMN_PETTY_CASH_VOUCHER = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'รายละเอียดการจ่ายให้',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'Payment Type',
      accessor: 'payment_type',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const HEADER_COLUMN_PURCHASE_REQUEST = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'เรื่องที่ต้องการขอซื้อ',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'Buyer Employee',
      accessor: 'employee',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const HEADER_COLUMN_COMPARISION_SCHEDULE = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'No.',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'date',
      disableSortBy: false,
    },
    {
      Header: 'Document id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'งบประมาณ',
      accessor: 'budget',
      disableSortBy: false,
    },
    {
      Header: 'Supplier Name 1',
      accessor: 'supplier_1',
      disableSortBy: false,
    },
    {
      Header: 'Supplier Name 2',
      accessor: 'supplier_2',
      disableSortBy: false,
    },
    {
      Header: 'Supplier Name 3',
      accessor: 'supplier_3',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const [bodyTableList, setBodyTableList] = useState([])
  const [bodyDataList, setBodyDataList] = useState([])
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [isSetData, setIsSetData] = useState(false)
  const [docTitle, setDocTitle] = useState('')

  const { isLoading, pageSize, page, pageCount, documentList } = useSelector(selectDocument)

  const [filter, setFilter] = useState({
    lastEdit: '',
    searchText: '',
    docType: docType || '',
  })

  const [currentPage, setCurrentPage] = useState(1)

  const navigate = useNavigate()
  const [purchaseSelected, setPurchaseSelected] = useState()
  const [dialogQuotationType, setDialogQuotationType] = useState('')
  const [isShowDialogQuotation, setIsShowDialogQuotation] = useState(false)
  const [isShowModalDownloadQuotation, setIsShowModalDownloadQuotation] = useState(false)
  const [isShowDialogRequestApprove, setIsShowDialogRequestApprove] = useState(false)
  const [isShowModalShareQuotation, setIsShowModalShareQuotation] = useState(false)

  const [isHindModalShare, setIsHindModalShare] = useState(false)

  useEffect(() => {
    if (docType != '') {
      setFilter({ ...filter, docType: docType })
      titleByDocType(docType)
    }
  }, [docType])

  useEffect(() => {
    ;(async () => {
      dispatch(
        getBusinessDocumentList(
          {
            page: currentPage,
            pageSize: 10,
          },
          filter
        )
      )
    })()
  }, [filter])

  useEffect(() => {
    if (documentList?.length >= 0) {
      let tmpArray = [...documentList]

      for (let i = 0; i < tmpArray.length; i++) {
        tmpArray[i].checked = false
      }
      setBodyDataList(tmpArray) // Keep Original Data
      setIsSetData(false)
    }
  }, [documentList])

  useEffect(() => {
    if (!isSetData) {
      const tmpList = [...bodyDataList]
      let viewList = []

      if (docType == 'purchase_order') {
        viewList = tmpList.map((item, index) => ({
          checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
          no: index + 1,
          date: convertDateFormat(item?.doc_date, 'DD MMM YYYY'),
          doc_id: (
            <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
              {item?.doc_id || '-'}
            </span>
          ),
          seller: <div className="w-200">{item?.seller || '-'}</div>,
          contact_person: item?.contact_person || '-',
          // net_total: convertNumberToCommaTwodecimal(item?.net_total) || '-',
          status: <CapsuleStatus status={item.status} />,
          action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
        }))
      } else if (docType == 'cheque_payment_journal' || docType == 'payment_journal') {
        viewList = tmpList.map((item, index) => ({
          checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
          no: index + 1,
          date: convertDateFormat(item?.doc_date, 'DD MMM YYYY'),
          doc_id: (
            <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
              {item?.doc_id || '-'}
            </span>
          ),
          detail: item?.details || '-',
          status: <CapsuleStatus status={item?.status} />,
          action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
        }))
      } else if (docType == 'petty_cash_voucher') {
        viewList = tmpList.map((item, index) => ({
          checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
          no: index + 1,
          date: convertDateFormat(item?.doc_date, 'DD MMM YYYY'),
          doc_id: (
            <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
              {item?.doc_id || '-'}
            </span>
          ),
          detail: item?.details || '-',
          payment_type: (
            <span className="txt-payment-type">
              {item?.payment_type ? convertPaymentType(item?.payment_type) : '-'}
            </span>
          ),
          status: item?.status ? <CapsuleStatus status={item?.status} /> : '',
          action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
        }))
      } else if (docType == 'purchase_request') {
        viewList = tmpList.map((item, index) => ({
          checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
          no: index + 1,
          date: convertDateFormat(item?.doc_date, 'DD MMM YYYY'),
          doc_id: (
            <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
              {item?.doc_id || '-'}
            </span>
          ),
          detail: item?.details || '-',
          employee: (
            <span className="txt-payment-type">{item?.employee ? convertPaymentType(item?.payment_type) : '-'}</span>
          ),
          status: item?.status ? <CapsuleStatus status={item?.status} /> : '',
          action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
        }))
      } else if (docType == 'comparison_schedule') {
        viewList = tmpList.map((item, index) => ({
          checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
          no: index + 1,
          date: convertDateFormat(item?.doc_date, 'DD MMM YYYY'),
          doc_id: (
            <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
              {item?.doc_id || '-'}
            </span>
          ),
          budget: item?.budget || '-',
          supplier_1: item?.supplier_list[0] || '-',
          supplier_2: item?.supplier_list[1] || '-',
          supplier_3: item?.supplier_list[2] || '-',
          status: item?.status ? <CapsuleStatus status={item?.status} /> : '',
          action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
        }))
      }

      setBodyTableList(viewList)
    }
  }, [bodyDataList, isSetData])

  const titleByDocType = (txtDocType) => {
    if (txtDocType == 'purchase_order') {
      setDocTitle('ใบสั่งซื้อสินค้า')
    } else if (txtDocType == 'purchase_request') {
      setDocTitle('ใบขอซื้อ')
    } else if (txtDocType == 'cheque_payment_journal') {
      setDocTitle('สมุดรายวันจ่ายเช็ค')
    } else if (txtDocType == 'payment_journal') {
      setDocTitle('สมุดรายวันจ่าย')
    } else if (txtDocType == 'petty_cash_voucher') {
      setDocTitle('ใบสำคัญจ่าย-เงินสดย่อย')
    } else if (txtDocType == 'comparison_schedule') {
      setDocTitle('ใบเทียบราคาสินค้า')
    }
  }

  const convertPaymentType = (type) => {
    let textArray = type.split('_')
    let string = textArray.join(' ')
    return string
  }

  const handleClickedOption = async (type, item) => {
    setDialogQuotationType(type)
    setPurchaseSelected(item)
    if (type == 'preview' || type == 'print') {
      setIsShowDialogQuotation(true)
    } else if (type == 'download_pdf') {
      setIsShowModalDownloadQuotation(true)
    } else if (type == 'request_approve') {
      setIsShowDialogRequestApprove(true)
    } else {
      setIsShowModalShareQuotation(true)
    }
  }

  const handleClickBtnDownloadQuotation = () => {
    setIsShowModalDownloadQuotation(false)
    setIsShowDialogQuotation(true)
  }

  const handleClickSubmitRequestApproval = () => {
    dispatch(getBusinessDocumentList({ page: currentPage, pageSize: 10 }, filter))
    setIsShowDialogRequestApprove(false)
  }

  const handleClickBtnCreateNew = () => {
    navigate('/document/purchases/create/?type=' + docType)
  }

  const handleBodyTableChecked = (isChecked, index) => {
    let tmpArray = [...bodyDataList]
    tmpArray[index].checked = isChecked
    setBodyDataList(tmpArray)
    setIsSetData(false)
    if (isChecked == false) {
      setIsSelectAll(false)
    }
  }
  const handleSearchTextChange = (e) => {
    setFilter({ ...filter, searchText: e.target.value })
  }

  const handleClickedPreview = () => {
    setIsHindModalShare(true)
    setDialogQuotationType('preview')
    setIsShowDialogQuotation(true)
  }

  const handleSelectFilter = (e) => {
    setFilter({ ...filter, lastEdit: e })
  }

  const handleClickedDocItem = (itemData) => {
    if (itemData?.id) {
      navigate('/document/purchases/edit/?type=' + docType + '&id=' + itemData.id)
    }
  }

  const onChangePagination = async (value) => {
    const pagination = {
      page: value.page,
      pageSize: 10,
    }
    dispatch(getBusinessDocumentList(pagination, filter))
    setCurrentPage(value.page)
  }

  const handleDownloaded = () => {
    setIsShowDialogQuotation(false)
    setPurchaseSelected()
    setDialogQuotationType()
  }

  const handleClickClosePreview = () => {
    setIsShowDialogQuotation(false)
    setIsHindModalShare(false)
  }
  const getHeaderByDocType = () => {
    if (docType == 'purchase_order') {
      return HEADER_COLUMN
    } else if (docType == 'cheque_payment_journal') {
      return HEADER_COLUMN_CHEQUE_PAYMENT_JOURNAL
    } else if (docType == 'payment_journal') {
      return HEADER_COLUMN_PAYMENT_JOURNAL
    } else if (docType == 'petty_cash_voucher') {
      return HEADER_COLUMN_PETTY_CASH_VOUCHER
    } else if (docType == 'purchase_request') {
      return HEADER_COLUMN_PURCHASE_REQUEST
    } else if (docType == 'comparison_schedule') {
      return HEADER_COLUMN_COMPARISION_SCHEDULE
    }
  }

  const setTitleFromDocType = () => {
    if (docType != '') {
      if (docType == 'purchase_order') {
        return 'ใบสั่งซื้อ'
      } else if (docType == 'cheque_payment_journal') {
        return 'สมุดรายวันจ่ายเช็ค'
      } else if (docType == 'payment_journal') {
        return 'สมุดรายวันจ่าย'
      } else if (docType == 'petty_cash_voucher') {
        return 'ใบสำคัญจ่าย-เงินสดย่อย'
      }
    }
    return ''
  }

  return (
    <Styled isHindModalShare={isHindModalShare}>
      {docType && (
        <div className="wrp-main-page">
          <div className="header">{docTitle}</div>

          <div className="filter-button">
            <div className="left">
              <div className="title">Last edit</div>
              <div className="filter">
                <DropdownSelect
                  className={'dropdown'}
                  dataList={lastEditList}
                  onChange={handleSelectFilter}
                  initValue={filter?.lastEdit}
                />
                <SearchText onChange={handleSearchTextChange} />
              </div>
            </div>
            <div className="right">
              <div className="btn-create-new" onClick={handleClickBtnCreateNew}>
                <Button>Create new</Button>
              </div>
            </div>
          </div>

          <div className="wrp-table">
            <Table
              columns={getHeaderByDocType()}
              data={bodyTableList}
              onStateChange={onChangePagination}
              pageCount={pageCount}
              page={currentPage}
              loading={isLoading}
            />
          </div>
          {isShowDialogQuotation && (
            <DialogDocument
              onClose={handleClickClosePreview}
              previewType={dialogQuotationType}
              id={purchaseSelected.id}
              docId={purchaseSelected.doc_id}
              onDownloaded={() => handleDownloaded()}
              docType={docType}
            />
          )}

          {isShowModalDownloadQuotation && (
            <ModalBox
              className={'wrp-modal'}
              onClosed={() => setIsShowModalDownloadQuotation(false)}
              height="200px"
              width="450px"
              title={'Download ' + purchaseSelected?.doc_id}
              body={<div className="body">{setTitleFromDocType()}</div>}
              footer={
                <div className="bottom">
                  <ButtonOutline
                    className={'footer-btn btn-cancel'}
                    onClick={() => setIsShowModalDownloadQuotation(false)}
                  >
                    Cancel
                  </ButtonOutline>
                  <Button className={'footer-btn btn-download'} onClick={handleClickBtnDownloadQuotation}>
                    Download
                  </Button>
                </div>
              }
            />
          )}

          {isShowDialogRequestApprove && (
            <SidePanel
              isOpen={isShowDialogRequestApprove}
              onClose={() => setIsShowDialogRequestApprove(false)}
              width={900}
            >
              <DocumentRequestApprove
                docType={docType}
                onClickNavigationBack={() => setIsShowDialogRequestApprove(false)}
                id={purchaseSelected.id}
                onClickBtnSubmit={() => handleClickSubmitRequestApproval()}
              />
            </SidePanel>
          )}

          {isShowModalShareQuotation && (
            <div className="document-share-and-send-email">
              <DocumentShareAndSendEmail
                type={docType}
                data={purchaseSelected}
                onClickPreview={() => handleClickedPreview()}
                onClose={() => setIsShowModalShareQuotation(false)}
              />
            </div>
          )}
        </div>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  .wrp-main-page {
    .header {
      font-size: 30px;
      font-weight: 600;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--Gray-200);
      margin-bottom: 24px;
    }
  }
  .wrp-table {
    > div {
      overflow-x: inherit;
    }
  }

  .txt-doc-item {
    cursor: pointer;
    :hover {
      color: var(--Primary-600);
    }
  }

  .filter-button {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    .left {
      .title-filter {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 6px;
      }
      .filter {
        display: flex;

        .dropdown {
          margin-right: 12px;
        }
      }
    }
    .right {
      display: flex;
      align-items: end;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .btn-cancel {
    width: 48%;
    justify-content: center;
  }
  .btn-download {
    width: 48%;
    justify-content: center;
  }

  .document-share-and-send-email {
    display: ${({ isHindModalShare }) => (isHindModalShare ? 'none' : 'block')};
  }
  .txt-payment-type {
    text-transform: capitalize;
  }

  .w-200 {
    min-width: 200px !important;
  }
`
