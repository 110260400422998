import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import Checkbox from '../../components/form/Checkbox'
import Date from '../../components/form/Date'
import TextField from '../../components/form/TextField'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import ProcessingBar from '../../components/widget/combo/ProcessingBar'
import SearchSuggestEmployee from '../../components/widget/combo/SearchSuggestEmployee'
import SearchSuggestScopeWork from '../../components/widget/combo/SearchSuggestScopeWork'
import DropDownSelect from '../../components/widget/element/DropDownSelect'
import ImageButton from '../../components/widget/element/ImageButton'
import InputTextArea from '../../components/widget/element/InputTextArea'
import IconBin from '../../components/widget/icon/IconBin'
import { getDepartmentsAndPositions } from '../../redux/actions/master'
import {
  createMilestone,
  getPositionByDepartmentId,
  getScopeWorkByPositionId,
  getTaskTypeByScopeId,
  updateMilestoneProjectTask,
} from '../../redux/actions/projectTask'
import { selectMaster, selectProjectTask } from '../../redux/selectors'
import { getAdminUser } from '../../utils/common'
import { removeArrayItemByIndex } from '../../utils/helper'

import { useTranslation } from 'react-i18next'

export default function MileStoneEditor({ projectId, type = 'create_milestone', milestoneData, onCancel, onSuccess }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { departments } = useSelector(selectMaster)
  const { positionData, taskTypeDataList, scopeWorkByPositionList } = useSelector(selectProjectTask)
  const tmpData = {
    name: '',
    due_date: '',
    scope_works: [], // set from scopeWorkList
    project_task: projectId || '',
  }
  const [currentMilestoneId, setCurrentMilestoneId] = useState('')
  const [dataMileStone, setDataMileStone] = useState(tmpData)
  const [departmentList, setDepartmentList] = useState([]) // keep data department & position
  // const [departmentData, setDepartmentData] = useState({
  //   value: '',
  //   is_selected: false,
  //   list: [],
  // })

  // const [positionInfoData, setPositionInfoData] = useState({
  //   value: '',
  //   is_selected: false,
  //   list: [],
  // })

  // const [scopeOfWorkInfoData, setScopeOfWorkInfoData] = useState({
  //   value: '',
  //   is_selected: false,
  //   list: [],
  // })

  const defaultInfoData = {
    value: '',
    is_selected: false,
    is_error: false,
    list: [],
  }

  const taskTypeInfoData = {
    title: '',
    // value: '',
    is_selected: false,
    list: [],
  }
  const [departmentDropdownList, setDepartmentDropdownList] = useState([])
  const [scopeWorkList, setScopeWorkList] = useState([]) // Data Keeper
  // const [taskTypeList, setTaskTypeList] = useState([])
  const [currentIndex, setCurrentIndex] = useState(0)

  const [currentMilestoneState, setCurrentMilestoneState] = useState('milestone-information')
  const [bulletList, setBulletList] = useState([
    {
      text: t('milestone_information'),
      status: 'active',
      value: 'MilestoneInformation',
    },
    {
      text: t('assign_task'),
      status: 'wait',
      value: 'AssignTask',
    },
  ])
  const [isShowAddInputScopeOfWork, setIsShowAddInputScopeOfWork] = useState(false)
  const [dropDownViews, setDropDownView] = useState([])
  const [searchTextByIndex, setSearchTextByIndex] = useState({
    index: 0,
    value: '',
  })

  // Create Milestone
  const [isErrorMilestoneName, setIsErrorMilestoneName] = useState(false)
  const [isErrorDueDate, setIsErrorDueDate] = useState(false)

  // Edit_Nilestone
  const [deleteScopeWorkList, setDeleteScopeWorkList] = useState([])
  const [isCanGetScopeWork, setIsCanGetScopeWork] = useState(false)
  const [isSetDataMilestoneTemplate, setIsSetDataMilestoneTemplate] = useState(false)

  useEffect(() => {
    getDepartmentAndPostionData()
  }, [])

  useEffect(() => {
    if (departments) {
      let tmpList = []
      if (departments.length > 0) {
        for (let i = 0; i < departments.length; i++) {
          let tmpObj = {
            text: departments[i].name,
            value: departments[i].id,
          }
          tmpList.push(tmpObj)
        }
      }
      // let tmpDepartmentData = defaultInfoData;
      // setDepartmentData(tmpDepartmentData)
      setDepartmentDropdownList([...tmpList])
      setDepartmentList([...departments])
    }
  }, [departments])

  useEffect(() => {
    // หลังจากเลือก DEPARTMENT และ POSITION แล้ว
    if (positionData && scopeWorkList[currentIndex]?.position_info && isCanGetScopeWork) {
      let tmpScopeWorkList = [...scopeWorkList]

      let tmpList = []
      if (positionData?.task_scopes.length > 0) {
        let tmpTaskScopeList = positionData?.task_scopes

        for (let i = 0; i < tmpTaskScopeList.length; i++) {
          let tmpObj = {
            text: tmpTaskScopeList[i].name,
            value: tmpTaskScopeList[i].id,
          }
          tmpList.push(tmpObj)
        }
      }

      tmpScopeWorkList[currentIndex].scope_of_work_info.list = [...tmpList]

      setScopeWorkList([...tmpScopeWorkList])
    }
  }, [positionData])

  // useEffect(() => {

  //   if(taskTypeDataList?.length > 0 && scopeWorkList[currentIndex]?.scope_of_work_info) {
  //     let tmpScopeWorkList = [...scopeWorkList];
  //     let tmpList = []
  //     for(let i = 0; i < taskTypeDataList.length; i++) {
  //       let tmpObj = {
  //           text: taskTypeDataList[i].name,
  //           is_checked: false,

  //           task_type: taskTypeDataList[i].id,
  //           assign_to: '',
  //           cc: '',
  //           due_date: '',
  //           detail:'',
  //           value: '',

  //           is_error_assign_to: false,
  //           is_error_cc: false,
  //           is_error_value: false,
  //           is_error_due_date:false,
  //           is_error_detail:false,
  //       }
  //       tmpList.push(tmpObj)
  //     }
  //     let tmpListReverse = tmpList.reverse()
  //     tmpScopeWorkList[currentIndex].task_type_info.list = [...tmpListReverse]
  //     tmpScopeWorkList[currentIndex].task_type_info.title = taskTypeDataList[0]?.task_scope?.name

  //     setTaskTypeList([...taskTypeDataList])
  //     setScopeWorkList([...tmpScopeWorkList])
  //   }
  // }, [taskTypeDataList])

  useEffect(() => {
    // หลังจากเลือก SCOPE_WORK แล้ว
    if (taskTypeDataList?.length > 0 && scopeWorkList[currentIndex]?.scope_of_work_info && isCanGetScopeWork) {
      let tmpScopeWorkList = [...scopeWorkList]
      let tmpList = []
      for (let i = 0; i < taskTypeDataList.length; i++) {
        let tmpObj = {
          text: taskTypeDataList[i].task_type_name,
          is_checked: false,

          task_type: taskTypeDataList[i].id,
          assign_to: '',
          cc: '',
          due_date: '',
          detail: '',
          value: '',

          is_error_assign_to: false,
          is_error_cc: false,
          is_error_value: false,
          is_error_due_date: false,
          is_error_detail: false,
        }
        tmpList.push(tmpObj)
      }
      tmpScopeWorkList[currentIndex].task_type_info.list = [...tmpList]
      tmpScopeWorkList[currentIndex].task_type_info.title = taskTypeDataList[0]?.task_scope?.name
      setScopeWorkList([...tmpScopeWorkList])
    }
  }, [taskTypeDataList])

  useEffect(() => {
    if (scopeWorkByPositionList?.length > 0 && scopeWorkList[currentIndex]?.scope_of_work_info) {
      let tmpScopeWorkByPositionList = scopeWorkByPositionList[0]?.task_scopes
      let tmpScopeWorkList = [...scopeWorkList]
      let tmpList = []
      for (let i = 0; i < tmpScopeWorkByPositionList.length; i++) {
        let tmpObj = {
          text: tmpScopeWorkByPositionList[i]?.name,
          value: tmpScopeWorkByPositionList[i].id,
        }
        tmpList.push(tmpObj)
      }
      tmpScopeWorkList[currentIndex].scope_of_work_info.list = [...tmpList]
      setScopeWorkList([...tmpScopeWorkList])
    }
  }, [scopeWorkByPositionList])

  useEffect(() => {
    if (searchTextByIndex) {
      const mockData = [
        {
          title: 'Generate Leads',
          desc: 'Department : Sales',
          subDesc: 'Position: Sale manager',
        },
      ]
      let views = []
      for (let i = 0; i < mockData.length; i++) {
        views.push(
          <div className={'item-search'} key={'item search' + i}>
            {mockData[i]?.title && <div className="title">{mockData[i]?.title}</div>}
            {mockData[i]?.desc && <div className="desc">{mockData[i]?.desc}</div>}
            {mockData[i]?.subDesc && <div className="sub-desc">{mockData[i]?.subDesc}</div>}
          </div>
        )
      }
      setDropDownView([...views])
    } else {
      setDropDownView([])
    }
  }, [searchTextByIndex])

  const getDepartmentAndPostionData = useCallback(async () => {
    dispatch(getDepartmentsAndPositions())
  }, [dispatch])

  useEffect(() => {
    ;(async () => {
      if (milestoneData && type == 'edit_milestone' && departmentList.length > 0) {
        let tmpScopeWorkList = [...milestoneData?.milestone_scope_works]
        if (milestoneData.id) {
          setCurrentMilestoneId(milestoneData.id)
        }
        let tmpDepartments = departmentList
        let mainScopeWorkDataList = []

        for (let i = 0; i < tmpScopeWorkList.length; i++) {
          let tmpDepartmentInfoData
          let tmpPositionInfoData
          let tmpScopeWorkInfoData
          let tmpTaskTypeInfoData

          let tmpPositionDropdownList = []
          let tmpScopeWorkId = tmpScopeWorkList[i].id

          if (tmpScopeWorkList[i]?.position?.department?.id) {
            // SET_DEPARTMENT ---
            let departmentId = tmpScopeWorkList[i].position.department.id
            tmpDepartmentInfoData = {
              value: departmentId,
              is_selected: true,
              is_error: false,
              list: [...departmentDropdownList],
            }

            let departmentSelectedObj = tmpDepartments.find((o) => o.id === departmentId)
            if (departmentSelectedObj?.positions) {
              let tmpPostions = [...departmentSelectedObj.positions]

              if (tmpPostions?.length > 0) {
                for (let i = 0; i < tmpPostions.length; i++) {
                  let tmpObj = {
                    text: tmpPostions[i].name,
                    value: tmpPostions[i].id,
                  }
                  tmpPositionDropdownList.push(tmpObj)
                }
              }
            }
          }

          let scopeWorkRes
          let tmpScopeDropdownList = []
          if (tmpScopeWorkList[i]?.position?.id) {
            scopeWorkRes = await dispatch(getPositionByDepartmentId(tmpScopeWorkList[i].position.id))
          }
          if (scopeWorkRes) {
            if (scopeWorkRes?.data?.task_scopes?.length > 0) {
              let tmpTaskScopeList = scopeWorkRes.data.task_scopes
              for (let i = 0; i < tmpTaskScopeList.length; i++) {
                let tmpObj = {
                  text: tmpTaskScopeList[i].name,
                  value: tmpTaskScopeList[i].id,
                }
                tmpScopeDropdownList.push(tmpObj)
              }
            }
          }

          // SET_POSITION ---
          tmpPositionInfoData = {
            value: tmpScopeWorkList[i]?.position?.id,
            is_selected: true,
            is_error: false,
            list: [...tmpPositionDropdownList],
          }

          let taskTypeRes
          if (tmpScopeWorkList[i]?.task_scope?.id) {
            let scopeId = tmpScopeWorkList[i].task_scope.id
            let scopeTitle = tmpScopeWorkList[i].task_scope.name
            // SET_SCOPE_WORKS ---
            tmpScopeWorkInfoData = {
              milestone_scope_works_id: tmpScopeWorkId,
              is_edit_scope: false,
              title: scopeTitle,
              value: scopeId,
              assign_to: tmpScopeWorkList[i].assign_to.id || '',
              cc: tmpScopeWorkList[i]?.cc || '',
              value_input: tmpScopeWorkList[i]?.value || '',
              due_date: tmpScopeWorkList[i]?.due_date || '',
              is_selected: true,
              is_error: false,
              list: [...tmpScopeDropdownList],
            }

            const filter = {
              page: 1,
              pageSize: 500,
            }
            taskTypeRes = await dispatch(getTaskTypeByScopeId(scopeId, filter))
          }
          if (taskTypeRes) {
            if (taskTypeRes?.data) {
              let tmpTaskTypes = [...taskTypeRes.data]
              let mainTaskTypeSelectedList = [...tmpScopeWorkList[i]?.milestone_task_types]
              let tmpTaskTypeCheckboxList = []
              for (let indexTask = 0; indexTask < tmpTaskTypes.length; indexTask++) {
                let tmpObj = {
                  text: tmpTaskTypes[indexTask].task_type_name,
                  title: tmpTaskTypes[indexTask].task_type_name,
                  is_checked: false,

                  task_type: tmpTaskTypes[indexTask].id,
                  assign_to: '',
                  cc: '',
                  due_date: '',
                  detail: '',
                  value: '',

                  is_error_assign_to: false,
                  is_error_cc: false,
                  is_error_value: false,
                  is_error_due_date: false,
                  is_error_detail: false,
                  is_error_value_input: false,

                  value_input: '',
                }
                tmpTaskTypeCheckboxList.push(tmpObj)
              }

              for (let indexMainTask = 0; indexMainTask < mainTaskTypeSelectedList.length; indexMainTask++) {
                let taskTypeData = mainTaskTypeSelectedList[indexMainTask]
                let idSelected = mainTaskTypeSelectedList[indexMainTask].task_type.id
                let taskId = mainTaskTypeSelectedList[indexMainTask].id

                for (let indexCheckbox = 0; indexCheckbox < tmpTaskTypeCheckboxList.length; indexCheckbox++) {
                  if (tmpTaskTypeCheckboxList[indexCheckbox].task_type == idSelected) {
                    tmpTaskTypeCheckboxList[indexCheckbox].is_checked = true
                    tmpTaskTypeCheckboxList[indexCheckbox].is_checked_old = true
                    tmpTaskTypeCheckboxList[indexCheckbox].assign_to = taskTypeData?.assign_to?.id
                    tmpTaskTypeCheckboxList[indexCheckbox].cc = taskTypeData?.cc
                    tmpTaskTypeCheckboxList[indexCheckbox].due_date = taskTypeData?.due_date
                    tmpTaskTypeCheckboxList[indexCheckbox].detail = taskTypeData?.detail
                    tmpTaskTypeCheckboxList[indexCheckbox].value = taskTypeData?.value
                    tmpTaskTypeCheckboxList[indexCheckbox].id = taskId // ใช้ id นี้ ในการ update
                  }
                }
              }

              // SET_TASK_TYPE ---
              tmpTaskTypeInfoData = {
                // title: '',
                is_selected: true,
                list: [...tmpTaskTypeCheckboxList],
              }
            }
          }

          // SUMMARY_ALL
          let tmpMainObj = {
            // current_status: '',
            department_info: tmpDepartmentInfoData,
            position_info: tmpPositionInfoData,
            scope_of_work_info: tmpScopeWorkInfoData,
            task_type_info: tmpTaskTypeInfoData,
          }
          mainScopeWorkDataList.push(tmpMainObj)
        }
        setScopeWorkList([...mainScopeWorkDataList])

        let tmpDataMilestone = dataMileStone
        tmpDataMilestone.name = milestoneData.name
        tmpDataMilestone.due_date = milestoneData.due_date
        tmpDataMilestone.scope_works = [...mainScopeWorkDataList]
        setDataMileStone({ ...tmpDataMilestone })
      } else if (
        milestoneData &&
        type == 'edit_milestone_template' &&
        departmentList.length > 0 &&
        !isSetDataMilestoneTemplate
      ) {
        let tmpScopeWorkList = [...milestoneData?.milestone_scope_works]

        let tmpDepartments = departmentList
        let mainScopeWorkDataList = []

        //--------------------------------
        for (let i = 0; i < tmpScopeWorkList.length; i++) {
          let tmpDepartmentInfoData
          let tmpPositionInfoData
          let tmpScopeWorkInfoData
          let tmpTaskTypeInfoData

          let tmpPositionDropdownList = []
          let tmpScopeWorkId = tmpScopeWorkList[i].id

          if (tmpScopeWorkList[i]?.position?.department?.id) {
            // SET_DEPARTMENT ---
            let departmentId = tmpScopeWorkList[i].position.department.id
            tmpDepartmentInfoData = {
              value: departmentId,
              is_selected: true,
              is_error: false,
              list: [...departmentDropdownList],
            }

            let departmentSelectedObj = tmpDepartments.find((o) => o.id === departmentId)
            if (departmentSelectedObj?.positions) {
              let tmpPostions = [...departmentSelectedObj.positions]

              if (tmpPostions?.length > 0) {
                for (let i = 0; i < tmpPostions.length; i++) {
                  let tmpObj = {
                    text: tmpPostions[i].name,
                    value: tmpPostions[i].id,
                  }
                  tmpPositionDropdownList.push(tmpObj)
                }
              }
            }
          }

          let scopeWorkRes
          let tmpScopeDropdownList = []
          if (tmpScopeWorkList[i]?.position?.id) {
            scopeWorkRes = await dispatch(getPositionByDepartmentId(tmpScopeWorkList[i].position.id))
          }
          if (scopeWorkRes) {
            if (scopeWorkRes?.data?.task_scopes?.length > 0) {
              let tmpTaskScopeList = scopeWorkRes.data.task_scopes
              for (let i = 0; i < tmpTaskScopeList.length; i++) {
                let tmpObj = {
                  text: tmpTaskScopeList[i].name,
                  value: tmpTaskScopeList[i].id,
                }
                tmpScopeDropdownList.push(tmpObj)
              }
            }
          }

          // SET_POSITION ---
          tmpPositionInfoData = {
            value: tmpScopeWorkList[i]?.position?.id,
            is_selected: true,
            is_error: false,
            list: [...tmpPositionDropdownList],
          }

          let taskTypeRes
          if (tmpScopeWorkList[i]?.task_scope?.id) {
            let scopeId = tmpScopeWorkList[i].task_scope.id
            let scopeTitle = tmpScopeWorkList[i].task_scope.name
            // SET_SCOPE_WORKS ---
            tmpScopeWorkInfoData = {
              milestone_scope_works_id: tmpScopeWorkId,
              is_edit_scope: false,
              title: scopeTitle,
              value: scopeId,
              assign_to: tmpScopeWorkList[i].assign_to ? tmpScopeWorkList[i].assign_to?.id : '',
              cc: tmpScopeWorkList[i]?.cc || '',
              value_input: tmpScopeWorkList[i]?.value || '',
              due_date: tmpScopeWorkList[i]?.due_date || '',
              is_selected: true,
              is_error: false,
              list: [...tmpScopeDropdownList],
            }

            const filter = {
              page: 1,
              pageSize: 500,
            }
            taskTypeRes = await dispatch(getTaskTypeByScopeId(scopeId, filter))
          }
          if (taskTypeRes) {
            if (taskTypeRes?.data) {
              let tmpTaskTypes = [...taskTypeRes.data]
              let mainTaskTypeSelectedList = [...tmpScopeWorkList[i]?.milestone_task_types]
              let tmpTaskTypeCheckboxList = []
              for (let indexTask = 0; indexTask < tmpTaskTypes.length; indexTask++) {
                let tmpObj = {
                  text: tmpTaskTypes[indexTask].task_type_name,
                  title: tmpTaskTypes[indexTask].task_type_name,
                  is_checked: false,

                  task_type: tmpTaskTypes[indexTask].id,
                  assign_to: '',
                  cc: '',
                  due_date: '',
                  detail: '',
                  value: '',

                  is_error_assign_to: false,
                  is_error_cc: false,
                  is_error_value: false,
                  is_error_due_date: false,
                  is_error_detail: false,
                  is_error_value_input: false,

                  value_input: '',
                }
                tmpTaskTypeCheckboxList.push(tmpObj)
              }

              for (let indexMainTask = 0; indexMainTask < mainTaskTypeSelectedList.length; indexMainTask++) {
                let taskTypeData = mainTaskTypeSelectedList[indexMainTask]
                let idSelected = mainTaskTypeSelectedList[indexMainTask].id
                let taskId = mainTaskTypeSelectedList[indexMainTask].id

                for (let indexCheckbox = 0; indexCheckbox < tmpTaskTypeCheckboxList.length; indexCheckbox++) {
                  if (tmpTaskTypeCheckboxList[indexCheckbox].task_type == idSelected) {
                    tmpTaskTypeCheckboxList[indexCheckbox].is_checked = true
                    tmpTaskTypeCheckboxList[indexCheckbox].is_checked_old = true
                    tmpTaskTypeCheckboxList[indexCheckbox].assign_to = taskTypeData?.assign_to?.id
                    tmpTaskTypeCheckboxList[indexCheckbox].cc = taskTypeData?.cc
                    tmpTaskTypeCheckboxList[indexCheckbox].due_date = taskTypeData?.due_date
                    tmpTaskTypeCheckboxList[indexCheckbox].detail = taskTypeData?.detail
                    tmpTaskTypeCheckboxList[indexCheckbox].value = taskTypeData?.value
                    tmpTaskTypeCheckboxList[indexCheckbox].id = taskId // ใช้ id นี้ ในการ update
                  }
                }
              }

              // SET_TASK_TYPE ---
              tmpTaskTypeInfoData = {
                // title: '',
                is_selected: true,
                list: [...tmpTaskTypeCheckboxList],
              }
            }
          }

          // SUMMARY_ALL
          let tmpMainObj = {
            // current_status: '',
            department_info: tmpDepartmentInfoData,
            position_info: tmpPositionInfoData,
            scope_of_work_info: tmpScopeWorkInfoData,
            task_type_info: tmpTaskTypeInfoData,
          }
          mainScopeWorkDataList.push(tmpMainObj)
        }
        //--------------------------------

        setScopeWorkList([...mainScopeWorkDataList])

        let tmpDataMilestone = dataMileStone
        tmpDataMilestone.name = milestoneData.name
        tmpDataMilestone.due_date = milestoneData.due_date
        tmpDataMilestone.scope_works = [...mainScopeWorkDataList]

        setDataMileStone({ ...tmpDataMilestone }) // milestone_name & due_date
        setIsSetDataMilestoneTemplate(true)
      }
    })()
  }, [milestoneData, departmentList])

  // ------------: Bullet 1 :------------
  const onTextChange = (e, type) => {
    if (type == 'milestone_name') {
      setDataMileStone((prevState) => ({ ...prevState, name: e.target.value }))
    } else if (type == 'search') {
      setDataMileStone((prevState) => ({ ...prevState, search_text: e.target.value }))
    }
  }
  const handleSelectDate = (value) => {
    setDataMileStone((prevState) => ({ ...prevState, due_date: value }))
  }
  // const handleSelectSearch = (index, e) => {
  //
  //   let tmpMileStone = { ...dataMileStone }
  //   tmpMileStone.scope_works[index]['scope_of_work'].value = e
  //   setDataMileStone(tmpMileStone)
  // }
  // const handleSearchTextChange = (index, e) => {
  //
  //   setSearchTextByIndex({ index, value: e })
  // }
  const handleSelectDropdown = async (index, value, dropdownType) => {
    let tmpScopeWorkList = [...scopeWorkList]
    if (dropdownType == 'department') {
      let tmpDepartmentData = tmpScopeWorkList[index].department_info
      tmpDepartmentData.value = value
      tmpDepartmentData.is_selected = true
      tmpDepartmentData.is_error = false

      // find position
      let tmpDepartmentList = departmentList
      let tmpPositionList = []
      let tmpList = []
      for (let i = 0; i < tmpDepartmentList.length; i++) {
        if (tmpDepartmentList[i].id == value) {
          tmpPositionList = [...tmpDepartmentList[i]?.positions]
        }
      }
      if (tmpPositionList.length > 0) {
        for (let i = 0; i < tmpPositionList.length; i++) {
          let tmpObj = {
            text: tmpPositionList[i]?.name,
            value: tmpPositionList[i]?.id,
          }
          tmpList.push(tmpObj)
        }
      }
      tmpScopeWorkList[index].position_info = defaultInfoData
      tmpScopeWorkList[index].position_info.list = [...tmpList]

      tmpScopeWorkList[index].scope_of_work_info = defaultInfoData
      tmpScopeWorkList[index].scope_of_work_info.is_selected = false
      setScopeWorkList([...tmpScopeWorkList])
    } else if (dropdownType == 'position') {
      let tmpPositionObj = tmpScopeWorkList[index].position_info
      tmpPositionObj.value = value
      tmpPositionObj.is_selected = true
      tmpPositionObj.is_error = false
      tmpScopeWorkList[index].position_info = tmpPositionObj

      tmpScopeWorkList[index].scope_of_work_info = defaultInfoData

      setCurrentIndex(index)
      setIsCanGetScopeWork(true)
      setScopeWorkList([...tmpScopeWorkList])
      dispatch(getPositionByDepartmentId(value))
    } else if (dropdownType == 'scope_of_work') {
      let tmpScopeObj = tmpScopeWorkList[index].scope_of_work_info
      if (type == 'edit_milestone' && !tmpScopeWorkList[index].is_add) {
        tmpScopeObj.is_edit_scope = true
        // tmpScopeObj.is_scope_change = true;
        let deleteList = []
        if (tmpScopeWorkList[index]?.task_type_info) {
          let tmpOldTaskTypeList = [...tmpScopeWorkList[index].task_type_info?.list]

          for (let i = 0; i < tmpOldTaskTypeList.length; i++) {
            if (tmpOldTaskTypeList[i].is_checked) {
              deleteList.push(tmpOldTaskTypeList[i].task_type)
            }
          }
        }
        // let taskEditData = {
        //   add: [],
        //   update: [],
        //   delete: [...deleteList] // list เก่า
        // }
        tmpScopeWorkList[index].task_type_info.delete_old_task_list = [...deleteList]
      }
      tmpScopeObj.value = value
      tmpScopeObj.is_selected = true
      tmpScopeObj.is_error = false

      let tmpSOWList = tmpScopeWorkList[index].scope_of_work_info.list
      for (let i = 0; i < tmpSOWList.length; i++) {
        if (tmpSOWList[i].value == value) {
          tmpScopeObj.title = tmpSOWList[i].text
        }
      }

      // เพิ่มเพื่อเก็บค่าหน้า 2
      tmpScopeObj.assign_to = ''
      tmpScopeObj.cc = ''
      tmpScopeObj.due_date = ''
      tmpScopeObj.value_input = ''
      tmpScopeObj.is_error_assign_to = false
      tmpScopeObj.is_error_cc = false
      tmpScopeObj.is_error_due_date = false
      tmpScopeObj.is_error_value_input = false

      tmpScopeWorkList[index].scope_of_work_info = tmpScopeObj
      setCurrentIndex(index)
      setIsCanGetScopeWork(true)
      setScopeWorkList([...tmpScopeWorkList])
      const filter = {
        page: 1,
        pageSize: 500,
      }
      dispatch(getTaskTypeByScopeId(value, filter))
    }
  }
  const onChangeSelectCheckBox = (value, indexScope, indexTask) => {
    let tmpScopeWorkList = [...scopeWorkList]
    let tmpScopeWorkInfo = tmpScopeWorkList[indexScope].scope_of_work_info
    let tmpTaskTypeInfo = tmpScopeWorkList[indexScope].task_type_info
    let tmpTaskTypeList = tmpTaskTypeInfo?.list

    if (
      type == 'edit_milestone' &&
      tmpScopeWorkInfo?.milestone_scope_works_id &&
      !tmpScopeWorkList[indexScope].is_add
    ) {
      tmpScopeWorkInfo.is_edit_scope = true
      if (tmpTaskTypeList[indexTask]?.is_checked_old) {
        tmpTaskTypeInfo.is_edit_task = true
      }
    }

    tmpTaskTypeList[indexTask].is_checked = value // set ค่า checkbox
    let isSelected = false
    for (let i = 0; i < tmpTaskTypeList.length; i++) {
      if (tmpTaskTypeList[i].is_checked) {
        isSelected = true
      }
    }
    tmpTaskTypeInfo.is_selected = isSelected
    tmpTaskTypeInfo.list = [...tmpTaskTypeList]
    tmpScopeWorkList[indexScope].task_type_info = tmpTaskTypeInfo
    setScopeWorkList([...tmpScopeWorkList])
  }
  const handleClickAddScopeWork = () => {
    let tmpObj = {
      department_info: defaultInfoData,
      position_info: defaultInfoData,
      scope_of_work_info: defaultInfoData,
      task_type_info: taskTypeInfoData,
    }

    if (type == 'edit_milestone') {
      tmpObj.is_add = true
    }
    let tmpList = [...scopeWorkList]
    tmpList.push(tmpObj)
    setScopeWorkList([...tmpList])
    setIsShowAddInputScopeOfWork(true)
  }
  const handleClickBtnDelScopeOfWork = (index) => {
    if (scopeWorkList?.length > 0) {
      let tmpScopeWorkList = [...scopeWorkList]

      if (type == 'edit_milestone' && tmpScopeWorkList[index]?.scope_of_work_info) {
        if (tmpScopeWorkList[index]?.scope_of_work_info?.milestone_scope_works_id) {
          let tmpDeleteScopeId = tmpScopeWorkList[index].scope_of_work_info.milestone_scope_works_id
          setDeleteScopeWorkList([...deleteScopeWorkList, tmpDeleteScopeId])
        }
      }
      let tmpList = removeArrayItemByIndex(tmpScopeWorkList, index)
      setScopeWorkList(tmpList)
    }
  }

  const checkValidationMilestoneInfo = () => {
    let tmpData = dataMileStone
    let isValidMilestoneName = false
    let isValidDueDate = false

    if (tmpData?.name != '') {
      isValidMilestoneName = true
    } else {
      isValidMilestoneName = false
      setIsErrorMilestoneName(true)
    }

    if (tmpData?.due_date != '') {
      isValidDueDate = true
    } else {
      isValidDueDate = false
      setIsErrorDueDate(true)
    }

    if (isValidMilestoneName && isValidDueDate) {
      return true
    }
    return false
  }
  const checkValidationScopeWorkInfo = () => {
    let tmpScopeWorkList = [...scopeWorkList]
    if (tmpScopeWorkList.length == 0) {
      return true
    } else {
      let validateArray = []
      for (let i = 0; i < tmpScopeWorkList.length; i++) {
        if (
          tmpScopeWorkList[i].department_info.is_selected &&
          tmpScopeWorkList[i].position_info.is_selected &&
          tmpScopeWorkList[i].scope_of_work_info.is_selected
        ) {
          validateArray.push(true)
        } else {
          validateArray.push(false)
        }
      }

      let isValid = true
      for (let i = 0; i < validateArray.length; i++) {
        if (!validateArray[i]) {
          isValid = false
        }
      }
      if (isValid) {
        return true
      } else {
        return false
      }
    }
  }

  // ------------: Bullet 2 :------------
  const handleClickBtnBottomLeft = () => {
    if (onCancel && currentMilestoneState == 'milestone-information') {
      setIsShowAddInputScopeOfWork(false)
      onCancel()
    } else if (currentMilestoneState == 'assign-task') {
      let tmpBulletList = [...bulletList]
      tmpBulletList[1].status = 'wait'
      setBulletList([...tmpBulletList])
      setCurrentMilestoneState('milestone-information')
    }
  }
  const handleClickBtnBottomRight = () => {
    if (currentMilestoneState == 'milestone-information') {
      // PAGE_1
      let isValidMilestone = checkValidationMilestoneInfo()
      let isValidScopeWork = checkValidationScopeWorkInfo()
      if (isValidMilestone && isValidScopeWork) {
        let tmpBulletList = [...bulletList]
        tmpBulletList[1].status = 'active'
        setBulletList([...tmpBulletList])
        setCurrentMilestoneState('assign-task')
      } else {
        let tmpScopeWorkList = [...scopeWorkList]
        if (!isValidScopeWork) {
          for (let i = 0; i < tmpScopeWorkList.length; i++) {
            if (!tmpScopeWorkList[i].department_info.is_selected) {
              tmpScopeWorkList[i].department_info.is_error = true
            }
            if (!tmpScopeWorkList[i].position_info.is_selected) {
              tmpScopeWorkList[i].position_info.is_error = true
            }
            if (!tmpScopeWorkList[i].scope_of_work_info.is_selected) {
              tmpScopeWorkList[i].scope_of_work_info.is_error = true
            }
          }
          setScopeWorkList([...tmpScopeWorkList])
        }
        alert('Please Enter Your Information')
      }
    } else if (currentMilestoneState == 'assign-task') {
      // PAGE_2
      let tmpDataMilestone = dataMileStone
      let tmpScopeWorkList = [...scopeWorkList]
      let mainScopeList = []

      let isValid = checkValidationAssignTask()

      if (isValid) {
        if (type == 'create_milestone' || type == 'edit_milestone_template') {
          for (let i = 0; i < tmpScopeWorkList.length; i++) {
            let tmpTaskSelectedList = []
            if (tmpScopeWorkList[i].task_type_info.list.length > 0) {
              let tmpList = tmpScopeWorkList[i].task_type_info.list

              for (let indexTask = 0; indexTask < tmpList.length; indexTask++) {
                if (tmpList[indexTask].is_checked) {
                  let tmpTaskObj = {
                    task_type: tmpList[indexTask].task_type,
                    // assign_to: tmpList[indexTask].assign_to,
                    cc: tmpList[indexTask].cc,
                    value: tmpList[indexTask].value,
                    // due_date: tmpList[indexTask].due_date,
                    detail: tmpList[indexTask].detail,
                  }
                  if (tmpList[indexTask].assign_to != '') {
                    tmpTaskObj.assign_to = tmpList[indexTask].assign_to
                  }
                  if (tmpList[indexTask].assign_to != '') {
                    tmpTaskObj.due_date = tmpList[indexTask].due_date
                  }
                  tmpTaskSelectedList.push(tmpTaskObj)
                }
              }
            }

            let tmpScopeObj = {
              task_scope: tmpScopeWorkList[i].scope_of_work_info.value,
              // assign_to: tmpScopeWorkList[i].scope_of_work_info.assign_to,
              cc: tmpScopeWorkList[i].scope_of_work_info.cc,
              value: tmpScopeWorkList[i].scope_of_work_info.value_input,
              // due_date: tmpScopeWorkList[i].scope_of_work_info.due_date,
              task_types: [...tmpTaskSelectedList],
              position: tmpScopeWorkList[i].position_info.value,
            }

            if (tmpScopeWorkList[i].scope_of_work_info.assign_to != '') {
              tmpScopeObj.assign_to = tmpScopeWorkList[i].scope_of_work_info.assign_to
            }

            if (tmpScopeWorkList[i].scope_of_work_info.due_date != '') {
              tmpScopeObj.due_date = tmpScopeWorkList[i].scope_of_work_info.due_date
            }
            mainScopeList.push(tmpScopeObj)
          }
          tmpDataMilestone.scope_works = [...mainScopeList]
          tmpDataMilestone.created_by = employee_info?.id
          tmpDataMilestone.company_id = company_info?.id
          if (type == 'edit_milestone_template') {
            tmpDataMilestone.template_milestone = milestoneData.id
          }
          const data = { ...tmpDataMilestone }
          const request = { data }

          dispatch(createMilestone(request)).then(() => {
            if (onSuccess) {
              onSuccess()
            }
          })
        } else {
          let addingScopeList = []
          let updateScopeList = []
          for (let i = 0; i < tmpScopeWorkList.length; i++) {
            let tmpScopeItemData = tmpScopeWorkList[i]
            // ------ ADD SCOPE -----
            if (tmpScopeItemData.is_add) {
              let tmpAddingTaskTypeList = []
              if (tmpScopeItemData?.task_type_info) {
                let tmpTaskTypeList = [...tmpScopeItemData?.task_type_info?.list]
                if (tmpTaskTypeList?.length > 0) {
                  for (let index = 0; index < tmpTaskTypeList.length; index++) {
                    if (tmpTaskTypeList[index].is_checked) {
                      let tmpTaskObj = {
                        task_type: tmpTaskTypeList[index]?.task_type,
                        assign_to: tmpTaskTypeList[index]?.assign_to,
                        cc: tmpTaskTypeList[index]?.cc || '',
                        value: tmpTaskTypeList[index]?.value || '',
                        due_date: tmpTaskTypeList[index]?.due_date,
                        detail: tmpTaskTypeList[index]?.detail || '',
                      }
                      tmpAddingTaskTypeList.push(tmpTaskObj)
                    }
                  }
                }
              }

              let tmpObj
              if (tmpScopeItemData?.scope_of_work_info) {
                tmpObj = {
                  task_scope: tmpScopeItemData?.scope_of_work_info?.value,
                  assign_to: tmpScopeItemData?.scope_of_work_info?.assign_to,
                  cc: tmpScopeItemData?.scope_of_work_info?.cc,
                  value: tmpScopeItemData?.scope_of_work_info?.value_input,
                  due_date: tmpScopeItemData?.scope_of_work_info?.due_date,
                  task_types: [...tmpAddingTaskTypeList],
                  position: tmpScopeItemData?.position_info?.value,
                }
              }

              addingScopeList.push(tmpObj)
            }

            // ------ UPDATE SCOPE -----
            if (!tmpScopeItemData.is_add && tmpScopeItemData.scope_of_work_info.is_edit_scope) {
              let tmpObj
              let taskUpdateList = []
              let taskAddingList = []
              let taskDeleteList = []
              // SCOPE -----> is_edit_scope   ==> (sow มีการเปลี่ยนค่่า) || UPDATE ||
              // SCOPE -----> is_scope_change ==> (เปลี่ยน dropdown sow) || ADD , DELETE ||

              // TASK  -----> is_checked_old  ==> (taskเดิม ที่เคยเลือกมาแล้ว นำมา compare กับ is_checked)
              // TASK  -----> task_edit_data  ==> (จะมีก็ต่อเมื่อ is_scope_change=true -> เปลี่ยน dropdown sow จะมี data ลบ task ของเก่าออก)
              // TASK  -----> is_task_change  ==> (เปลี่ยน info ของ task)

              // if(tmpScopeItemData.scope_of_work_info.is_scope_change) {

              //   // ADD TASK NEW

              //   // SET DELETE OLD TASK
              //   if(tmpScopeItemData?.task_type_info?.delete_old_task_list?.length > 0) {
              //     taskDeleteList = [...tmpScopeItemData?.task_type_info?.delete_old_task_list]
              //   }
              // } else {
              if (tmpScopeItemData?.task_type_info && tmpScopeItemData?.task_type_info?.is_edit_task) {
                if (tmpScopeItemData?.task_type_info?.delete_old_task_list?.length > 0) {
                  taskDeleteList = [...tmpScopeItemData?.task_type_info?.delete_old_task_list]
                }
                let tmpTaskList = [...tmpScopeItemData?.task_type_info.list]
                for (let indexTask = 0; indexTask < tmpTaskList.length; indexTask++) {
                  if (tmpTaskList[indexTask]?.is_checked_old) {
                    if (!tmpTaskList[indexTask].is_checked) {
                      taskDeleteList.push(tmpTaskList[indexTask].id)
                    } else {
                      if (tmpTaskList[indexTask].is_task_change) {
                        let tmpTaskTypeEditInfoObj = {
                          id: tmpTaskList[indexTask].id,
                          cc: tmpTaskList[indexTask].cc,
                          value: tmpTaskList[indexTask].value,
                          due_date: tmpTaskList[indexTask].due_date,
                          detail: tmpTaskList[indexTask].detail,
                          assign_to: tmpTaskList[indexTask].assign_to,
                        }
                        taskUpdateList.push(tmpTaskTypeEditInfoObj)
                      }
                    }
                  } else {
                    if (tmpTaskList[indexTask].is_checked) {
                      let tmpTaskTypeObj = {
                        task_type: tmpTaskList[indexTask].task_type,
                        cc: tmpTaskList[indexTask].cc,
                        value: tmpTaskList[indexTask].value,
                        due_date: tmpTaskList[indexTask].due_date,
                        detail: tmpTaskList[indexTask].detail,
                        assign_to: tmpTaskList[indexTask].assign_to,
                      }
                      taskAddingList.push(tmpTaskTypeObj)
                    }
                  }
                }
              }
              // }

              if (tmpScopeItemData?.scope_of_work_info) {
                tmpObj = {
                  id: tmpScopeItemData?.scope_of_work_info?.milestone_scope_works_id,
                  assign_to: tmpScopeItemData?.scope_of_work_info?.assign_to,
                  cc: tmpScopeItemData?.scope_of_work_info?.cc,
                  value: tmpScopeItemData?.scope_of_work_info?.value_input,
                  due_date: tmpScopeItemData?.scope_of_work_info?.due_date,
                  task_types: {
                    add: [...taskAddingList],
                    update: [...taskUpdateList],
                    delete: [...taskDeleteList],
                  },
                }
              }

              updateScopeList.push(tmpObj)
            }
          }

          let scopeWorkObj = {
            add: [...addingScopeList],
            update: [...updateScopeList],
            delete: [...deleteScopeWorkList],
          }

          const data = {
            name: tmpDataMilestone.name,
            due_date: tmpDataMilestone.due_date,
            scope_works: scopeWorkObj,
            created_by: employee_info.id,
          }

          const request = { data }
          dispatch(updateMilestoneProjectTask(currentMilestoneId, request)).then(() => {
            if (onSuccess) {
              onSuccess()
            }
          })
        }
      }
    }
  }
  const checkValidationAssignTask = () => {
    let tmpScopeWorkList = [...scopeWorkList]
    let isValidScope = true
    let isValidTask = true

    for (let i = 0; i < tmpScopeWorkList.length; i++) {
      // CHECK SCOPE VALIDATE
      if (tmpScopeWorkList[i].scope_of_work_info) {
        let tmpScopeObj = tmpScopeWorkList[i].scope_of_work_info

        if (tmpScopeObj.assign_to != '') {
          tmpScopeObj.is_error_assign_to = false
        } else {
          tmpScopeObj.is_error_assign_to = true
          isValidScope = false
        }

        if (tmpScopeObj.due_date != '') {
          tmpScopeObj.is_error_due_date = false
        } else {
          tmpScopeObj.is_error_due_date = true
          isValidScope = false
        }

        if (tmpScopeObj.value_input != '') {
          tmpScopeObj.is_error_value_input = false
        } else {
          tmpScopeObj.is_error_value_input = true
          isValidScope = false
        }
      }

      // CHECK TASK VALIDATE
      if (tmpScopeWorkList[i]?.task_type_info?.list?.length > 0) {
        let tmpTaskList = tmpScopeWorkList[i].task_type_info.list

        for (let indexTask = 0; indexTask < tmpTaskList.length; indexTask++) {
          if (tmpTaskList[indexTask].is_checked) {
            if (tmpTaskList[indexTask].assign_to != '') {
              tmpTaskList[indexTask].is_error_assign_to = false
            } else {
              isValidTask = false
              tmpTaskList[indexTask].is_error_assign_to = true
            }

            if (tmpTaskList[indexTask].value != '') {
              tmpTaskList[indexTask].is_error_value = false
            } else {
              isValidTask = false
              tmpTaskList[indexTask].is_error_value = true
            }

            if (tmpTaskList[indexTask].due_date != '') {
              tmpTaskList[indexTask].is_error_due_date = false
            } else {
              isValidTask = false
              tmpTaskList[indexTask].is_error_due_date = true
            }
          }
        }
      }
    }

    if (isValidScope && isValidTask) {
      return true
    } else {
      setScopeWorkList([...tmpScopeWorkList])
      return false
    }
  }
  const handleScopeChange = (e, indexScope, scopeType) => {
    let tmpScopeWorkList = [...scopeWorkList]
    if (scopeType == 'assign_to') {
      tmpScopeWorkList[indexScope].scope_of_work_info.assign_to = e.id
      tmpScopeWorkList[indexScope].scope_of_work_info.is_error_assign_to = false
    } else if (scopeType == 'due_date') {
      tmpScopeWorkList[indexScope].scope_of_work_info.due_date = e
      tmpScopeWorkList[indexScope].scope_of_work_info.is_error_due_date = false
    } else if (scopeType == 'cc') {
      tmpScopeWorkList[indexScope].scope_of_work_info.cc = e.target.value
      tmpScopeWorkList[indexScope].scope_of_work_info.is_error_cc = false
    } else if (scopeType == 'value') {
      tmpScopeWorkList[indexScope].scope_of_work_info.value_input = e.target.value
      tmpScopeWorkList[indexScope].scope_of_work_info.is_error_value_input = false
    }

    if (type == 'edit_milestone' && !tmpScopeWorkList[indexScope].is_add) {
      tmpScopeWorkList[indexScope].scope_of_work_info.is_edit_scope = true
    }
    setScopeWorkList((prevState) => [...tmpScopeWorkList])
  }
  const handleSelectAssignTo = (e, indexScope, indexTask) => {
    let tmpScopeWorkList = [...scopeWorkList]
    tmpScopeWorkList[indexScope].task_type_info.list[indexTask].assign_to = e.id
    tmpScopeWorkList[indexScope].task_type_info.list[indexTask].is_error_assign_to = false
    if (type == 'edit_milestone' && !tmpScopeWorkList[indexScope].is_add) {
      tmpScopeWorkList[indexScope].scope_of_work_info.is_edit_scope = true
      tmpScopeWorkList[indexScope].task_type_info.is_edit_task = true
      if (tmpScopeWorkList[indexScope].task_type_info.list[indexTask].is_checked_old) {
        tmpScopeWorkList[indexScope].task_type_info.list[indexTask].is_task_change = true
      }
    }

    setScopeWorkList((prevState) => [...tmpScopeWorkList])
  }
  const handleTaskChange = (e, indexScope, indexTask, itemType) => {
    let tmpScopeWorkList = [...scopeWorkList]
    if (itemType == 'cc') {
      tmpScopeWorkList[indexScope].task_type_info.list[indexTask].cc = e.target.value
      tmpScopeWorkList[indexScope].task_type_info.list[indexTask].is_error_cc = false
    } else if (itemType == 'value') {
      tmpScopeWorkList[indexScope].task_type_info.list[indexTask].value = e.target.value
      tmpScopeWorkList[indexScope].task_type_info.list[indexTask].is_error_value = false
    } else if (itemType == 'detail') {
      tmpScopeWorkList[indexScope].task_type_info.list[indexTask].detail = e.target.value
      tmpScopeWorkList[indexScope].task_type_info.list[indexTask].is_error_detail = false
    }
    if (type == 'edit_milestone' && !tmpScopeWorkList[indexScope].is_add) {
      tmpScopeWorkList[indexScope].scope_of_work_info.is_edit_scope = true
      tmpScopeWorkList[indexScope].task_type_info.is_edit_task = true
      if (tmpScopeWorkList[indexScope].task_type_info.list[indexTask].is_checked_old) {
        tmpScopeWorkList[indexScope].task_type_info.list[indexTask].is_task_change = true
      }
    }
    setScopeWorkList((prevState) => [...tmpScopeWorkList])
  }
  const handleSelectTaskDueDate = (value, indexScope, indexTask) => {
    let tmpScopeWorkList = [...scopeWorkList]
    tmpScopeWorkList[indexScope].task_type_info.list[indexTask].due_date = value
    tmpScopeWorkList[indexScope].task_type_info.list[indexTask].is_error_due_date = false
    if (type == 'edit_milestone' && !tmpScopeWorkList[indexScope].is_add) {
      tmpScopeWorkList[indexScope].scope_of_work_info.is_edit_scope = true
      tmpScopeWorkList[indexScope].task_type_info.is_edit_task = true
      if (tmpScopeWorkList[indexScope].task_type_info.list[indexTask].is_checked_old) {
        tmpScopeWorkList[indexScope].task_type_info.list[indexTask].is_task_change = true
      }
    }
    setScopeWorkList((prevState) => [...tmpScopeWorkList])
  }
  const handleSelectSearchTextChange = (indexScope, value) => {
    let tmpData = value
    let tmpScopeWorkList = [...scopeWorkList]
    if (tmpData?.department?.id) {
      // ----- Set Department -----
      let tmpDepartmentData = tmpScopeWorkList[indexScope].department_info
      tmpDepartmentData.value = tmpData?.department?.id
      tmpDepartmentData.is_selected = true
      tmpDepartmentData.is_error = false

      // find position
      let tmpDepartmentList = departmentList
      let tmpPositionList = []
      let tmpList = []
      for (let i = 0; i < tmpDepartmentList.length; i++) {
        if (tmpDepartmentList[i].id == tmpData?.department?.id) {
          tmpPositionList = [...tmpDepartmentList[i]?.positions]
        }
      }
      if (tmpPositionList.length > 0) {
        for (let i = 0; i < tmpPositionList.length; i++) {
          let tmpObj = {
            text: tmpPositionList[i]?.name,
            value: tmpPositionList[i]?.id,
          }
          tmpList.push(tmpObj)
        }
      }
      tmpScopeWorkList[indexScope].position_info = defaultInfoData
      tmpScopeWorkList[indexScope].position_info.list = [...tmpList]

      // ----- Set Position -----
      if (tmpData?.positions?.id) {
        let tmpPositionObj = tmpScopeWorkList[indexScope].position_info
        tmpPositionObj.value = tmpData?.positions?.id
        tmpPositionObj.is_selected = true
        tmpPositionObj.is_error = false

        tmpScopeWorkList[indexScope].position_info = tmpPositionObj
      }

      setCurrentIndex(indexScope)

      // tmpScopeWorkList[indexScope].scope_of_work_info = defaultInfoData;

      setScopeWorkList([...tmpScopeWorkList])
      const pagination = {
        page: 1,
        pageSize: 1000,
      }
      dispatch(getScopeWorkByPositionId(tmpData?.positions?.id, pagination))
    }
  }

  const convertIntComma = (val) => {
    if (val) {
      let tmp = (Number(val.replace(/\D/g, '')) || '').toLocaleString()
      return tmp
    }
  }

  return (
    <Style>
      <div className="process-status">
        <ProcessingBar className={'bullet-box'} itemList={bulletList} />
      </div>

      {/* BULLET 1 */}
      {currentMilestoneState == 'milestone-information' && (
        <div className="wrp-milestone-information">
          <div className="wrp-input">
            <div className="row">
              <div className="wrp-input-field pdr-10">
                <div className="title-input">{t('milestone_name')}</div>
                <TextField
                  className="text-filed"
                  value={dataMileStone.name}
                  onChange={(e) => {
                    setIsErrorMilestoneName(false)
                    onTextChange(e, 'milestone_name')
                  }}
                  error={isErrorMilestoneName}
                  placeholder={t('please_enter_milestone_name')}
                />
              </div>
              <div className="wrp-input-field pdl-10">
                <div className="title-input">{t('due_date')}</div>
                <Date
                  className={'date'}
                  value={dataMileStone.due_date}
                  onChange={(e) => {
                    setIsErrorDueDate(false)
                    handleSelectDate(e)
                  }}
                  error={isErrorDueDate}
                />
              </div>
            </div>

            <div className="title-input">{t('scope_of_work')}</div>
            <div className={isShowAddInputScopeOfWork ? 'row long' : 'row'}>
              <div className="wrp-input-scope-of-work">
                {scopeWorkList?.length > 0 && (
                  <>
                    {scopeWorkList.map((itemScope, indexScope) => (
                      <div className={'item-scope-of-work '} key={'itemScope-' + indexScope}>
                        <div className="inner-item-scope ">
                          <div className="wrp-input-scope-item-search pdr-10">
                            <div className="title-input">{t('search_scope')}</div>
                            <SearchSuggestScopeWork
                              className={'search-suggest-box'}
                              placeHolder={t('search_scope')}
                              searchWidth="180px"
                              onSelectedSuggest={(e) => handleSelectSearchTextChange(indexScope, e)}
                            />
                          </div>
                          <div className="wrp-input-scope-item pdr-10">
                            <div className="title-input">{t('department')}</div>
                            <DropDownSelect
                              className="dropdown"
                              width="150px"
                              dataList={departmentDropdownList}
                              initValue={itemScope?.department_info?.value}
                              onChange={(e) => handleSelectDropdown(indexScope, e, 'department')}
                              isError={itemScope?.department_info?.is_error}
                              isEllipsis
                            />
                          </div>
                          <div className="wrp-input-scope-item pdr-10">
                            <div className="title-input">{t('positions')}</div>
                            <DropDownSelect
                              className="dropdown"
                              width="150px"
                              dataList={itemScope?.position_info?.list}
                              initValue={itemScope?.position_info?.value}
                              onChange={(e) => handleSelectDropdown(indexScope, e, 'position')}
                              isError={itemScope?.position_info?.is_error}
                              isEllipsis
                              isDisabled={!itemScope?.department_info?.is_selected}
                            />
                          </div>
                          <div className="wrp-input-scope-item">
                            <div className="title-input">{t('scope_of_work')}</div>
                            <DropDownSelect
                              className="dropdown"
                              width="150px"
                              dataList={itemScope?.scope_of_work_info?.list}
                              initValue={itemScope?.scope_of_work_info?.value}
                              onChange={(e) => handleSelectDropdown(indexScope, e, 'scope_of_work')}
                              isError={itemScope?.scope_of_work_info?.is_error}
                              isEllipsis
                              isDisabled={!itemScope?.position_info?.is_selected}
                            />
                          </div>

                          {scopeWorkList?.length > 0 && (
                            <ImageButton
                              className={'btn-del-add-input-scope-of-work'}
                              icon={
                                // <IconXCircle width="25" color="white" backgroundColor={'#7f56d9'} />
                                <IconBin />
                              }
                              size={38}
                              onClick={() => handleClickBtnDelScopeOfWork(indexScope)}
                            />
                          )}
                        </div>
                        {itemScope?.scope_of_work_info?.is_selected && itemScope?.task_type_info?.list.length > 0 && (
                          <div className="bottom">
                            <div className="content-selected">
                              <div className="selected-view">
                                <div className="header-content">{itemScope?.task_type_info?.title}</div>
                                <div className="title">Task Selective</div>

                                {itemScope?.task_type_info?.list?.length > 0 &&
                                  itemScope?.task_type_info?.list.map((itemTask, indexTask) => (
                                    <div className="wrp-choice" key={'indexTask ' + indexTask}>
                                      <Checkbox
                                        className="check-box-style"
                                        key={'checkbox-number' + indexTask}
                                        label={indexTask + 1 + '.' + itemTask.text}
                                        checked={itemTask.is_checked}
                                        onChange={(e) => onChangeSelectCheckBox(e, indexScope, indexTask)}
                                      />
                                    </div>
                                  ))}
                              </div>
                              {/* <div className="add-task">
                                <TextField placeholder={'Task Name'} />
                                <Button className="btn-add-task">Add Task</Button>
                              </div>
                              <div className="note-text">*Add new task if you want to add more</div> */}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </>
                )}

                <Button onClick={() => handleClickAddScopeWork()}>
                  {scopeWorkList.length > 0 ? t('add_new_scope') : t('add_new')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* BULLET 2 */}
      {currentMilestoneState == 'assign-task' && (
        <div className="wrp-assign-task">
          {scopeWorkList.length > 0 ? (
            <div className="wrp-input">
              {scopeWorkList.map((itemScope, indexScope) => (
                <div className="item-input" key={'scope-item-' + indexScope}>
                  <div className="header">
                    {'#' + (indexScope + 1) + ' Scope of work : ' + itemScope.scope_of_work_info?.title}
                  </div>
                  {/* SCOPE OF WORK */}
                  <div className="row">
                    <div className="wrp-search-for-assign">
                      <div className="title-header">{t('assign_to')}</div>
                      <SearchSuggestEmployee
                        placeHolder={t('assign_to')}
                        selectedId={itemScope.scope_of_work_info.assign_to}
                        onSelectedSuggest={(e) => handleScopeChange(e, indexScope, 'assign_to')}
                        isError={itemScope.scope_of_work_info.is_error_assign_to}
                      />
                    </div>
                    {/* <TextField
                      className="text-field st2"
                      placeholder="cc"
                      value={itemScope.scope_of_work_info?.cc}
                      onChange={(e) => handleScopeChange(e, indexScope, 'cc')}
                      // error={itemScope.scope_of_work_info.is_error_cc}
                    /> */}
                    <div className="wrp-input-field">
                      <div className="title-header">{t('value_baht')}</div>
                      <TextField
                        className="text-field"
                        placeholder={t('value_baht')}
                        startAdornment={'THB'}
                        startAdornmentClassName={'prefix'}
                        // value={itemScope.scope_of_work_info?.value_input}
                        value={convertIntComma(itemScope.scope_of_work_info?.value_input)}
                        pattern={'^[0-9]*[.,]?[0-9]*$'}
                        onChange={(e) => handleScopeChange(e, indexScope, 'value')}
                        error={itemScope.scope_of_work_info.is_error_value_input}
                      />
                    </div>
                    <div className="wrp-input-field pdl-10">
                      <div className="title-header">{t('due_date')}</div>
                      <Date
                        className={'date-state_2'}
                        value={itemScope.scope_of_work_info?.due_date}
                        onChange={(e) => handleScopeChange(e, indexScope, 'due_date')}
                        error={itemScope.scope_of_work_info.is_error_due_date}
                      />
                    </div>
                  </div>

                  {/* TASK from Checkbox */}
                  {itemScope.task_type_info?.is_selected && (
                    <div>
                      {itemScope.task_type_info?.list.map((itemTask, indexTask) => (
                        <div className="wrp-task-item" key={'task-item-' + indexTask}>
                          {itemTask.is_checked && (
                            <div>
                              <div className="title">
                                {t('task')} : {itemTask.text}
                              </div>
                              <div className="row two-col">
                                <div className="col-top">
                                  <div className="wrp-search-for-assign">
                                    <div className="title-header">{t('assign_to')}</div>
                                    <SearchSuggestEmployee
                                      placeHolder={t('assign_to')}
                                      selectedId={itemTask.assign_to}
                                      onSelectedSuggest={(e) => handleSelectAssignTo(e, indexScope, indexTask)}
                                      isError={itemTask.is_error_assign_to}
                                    />
                                  </div>
                                  {/* <TextField
                                    className="text-field st2"
                                    placeholder="cc"
                                    value={itemTask.cc}
                                    onChange={(e) => handleTaskChange(e, indexScope, indexTask, 'cc')}
                                    error={itemTask.is_error_cc}
                                  /> */}
                                  <div className="wrp-input-field">
                                    <div className="title-header">{t('value_baht')}</div>
                                    <TextField
                                      className="text-field"
                                      placeholder={t('value_baht')}
                                      startAdornment={'THB'}
                                      startAdornmentClassName={'prefix'}
                                      // value={itemTask.value}
                                      value={convertIntComma(itemTask.value)}
                                      pattern={'^[0-9]*[.,]?[0-9]*$'}
                                      onChange={(e) => handleTaskChange(e, indexScope, indexTask, 'value')}
                                      error={itemTask.is_error_value}
                                    />
                                  </div>
                                  <div className="wrp-input-field pdl-10">
                                    <div className="title-header">{t('due_date')}</div>
                                    <Date
                                      className={'date-state_2'}
                                      value={itemTask.due_date}
                                      onChange={(e) => handleSelectTaskDueDate(e, indexScope, indexTask)}
                                      error={itemTask.is_error_due_date}
                                    />
                                  </div>
                                </div>
                                <div className="col-bottom">
                                  <div className="title-header">{t('detail')}</div>
                                  <InputTextArea
                                    className={'input-text-area'}
                                    placeHolder={t('detail')}
                                    initValue={itemTask.detail}
                                    onChange={(e) => handleTaskChange(e, indexScope, indexTask, 'detail')}
                                    borderColor={itemTask.is_error_detail ? '#d92d20' : '#d0d5dd'}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="wrp-no-sow">{t('there_is_no_selected_scope_of_work')}</div>
          )}
        </div>
      )}

      <div className="bottom-content">
        <ButtonOutline onClick={handleClickBtnBottomLeft}>
          {currentMilestoneState == 'milestone-information' ? t('cancel') : t('back')}
        </ButtonOutline>

        <Button className="btn-next-create" onClick={handleClickBtnBottomRight}>
          {currentMilestoneState == 'milestone-information'
            ? 'Next'
            : // (type == 'create_milestone' ? 'Next' : 'Save')
            type == 'create_milestone'
            ? t('create')
            : t('save')}
        </Button>
      </div>
    </Style>
  )
}
const Style = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  .pdr-10 {
    padding-right: 10px;
  }
  .pdl-10 {
    padding-left: 10px;
  }

  //processing bar
  .bullet-box {
    width: 50%;
    /* background-color: #816e93; */
    margin-top: 50px;
  }

  //milestone-information
  .wrp-milestone-information {
    flex: 1;
    display: flex;
    .wrp-input {
      flex: 1;
      padding: 16px 32px;
      .row {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .wrp-input-field {
          flex: 1;
        }

        .title-input {
          margin-bottom: 6px;
        }
        .text-filed {
          width: 100%;
          /* min-width: 232px; */
        }
        /* .date {
          min-width: 232px;
        } */

        .wrp-input-scope-of-work {
          width: 100%;
          margin-top: 10px;
        }
        .item-scope-of-work {
          display: flex;
          flex-direction: column;
          margin-bottom: 16px;
          border: 1px solid var(--Gray-300);
          border-radius: 8px;
          padding: 16px;
          .inner-item-scope {
            display: flex;
            /* align-items: center; */
            align-items: flex-end;
            .wrp-input-scope-item {
              flex: 1;
              max-width: 200px;
            }
            .pdr-10 {
              padding-right: 10px;
            }
            .pdl-10 {
              padding-left: 10px;
            }
            .wrp-input-scope-item-search {
              flex: 1;
              min-width: 250px;
              .wrp-search-input {
                width: 100%;
                .icon {
                  top: 10px;
                }
              }
            }
            .search-suggest-box {
              flex: 1;
            }
            .dropdown {
              width: 100%;
            }
          }
          .bottom {
            .content-selected {
              margin-top: 16px;
              padding: 0 32px;
              .header-content {
                font-weight: bold;
                font-size: 20px;
              }
              .title {
                font-weight: bold;
              }
              .wrp-choice {
                margin: 16px 0;
                .check-box-style {
                  display: flex;
                  align-items: center;
                  margin-bottom: 8px;
                  label {
                    margin-right: 0;
                    display: flex;
                    align-items: center;

                    .MuiTypography-root {
                      font-size: 16px;
                    }
                  }
                }
              }
              .btn-add-task {
                margin-left: 8px;
              }
              .note-text {
                margin-top: 8px;
                font-size: 14px;
                color: var(--Gray-300);
              }
            }
          }

          .btn-del-add-input-scope-of-work {
            margin-left: 10px;
          }
        }
      }
      .long {
        align-items: flex-start;
        /* .title-input {
          position: relative;
          display: flex;
          align-items: center;
        } */
      }
    }
  }

  /* bottom content */
  .bottom-content {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    border: 1px solid var(--Gray-300);
    .btn-next-create {
      margin-left: 16px;
    }
  }

  //assign task
  .wrp-assign-task {
    flex: 1;
    display: flex;
    .wrp-input {
      flex: 1;
      .item-input {
        border: 1px solid var(--Gray-300);
        border-radius: 8px;
        margin: 0 36px 16px;
        padding: 16px 36px;

        .header {
          font-weight: bold;
          font-size: 18px;
        }
        .row {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          margin-top: 16px;
          .wrp-input-field {
            flex: 1;
          }
          .title-header {
            margin-bottom: 6px;
          }
          .text-field {
            width: 100%;
            .prefix {
              border-right: 1px solid var(--Gray-300);
              padding: 8px 12px 10px 14px;
            }
          }
        }
        .title {
          font-weight: bold;
        }
        .two-col {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .col-top {
            width: 100%;
            display: flex;
            margin-bottom: 16px;
          }
          .col-bottom {
            width: 100%;
          }
        }
        .wrp-search-for-assign {
          flex: 1;
          margin-right: 8px;
          .wrp-search-input {
            width: 100% !important;
            .icon {
              top: 10px;
            }
          }
        }
        .text-field {
          flex: 1;
          margin-right: 8px;
        }
        .st2 {
          flex: 2;
        }
        .date-state_2 {
          flex: 1;
        }
        .input-text-area {
          width: 100%;
          border: 1px solid var(--Gray-300);
          font-size: 14px;
          resize: none;
        }
      }
    }
  }

  .item-search {
    padding: 10px;
    .title {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 5px;
    }
    .desc {
      font-size: 14px;
    }
    .sub-desc {
      font-size: 14px;
    }
  }

  .wrp-no-sow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`
