import { combineReducers } from 'redux'
import {
  GET_MATERIAL_LIST,
  GET_MATERIAL_BY_ID,
  GET_MATERIAL_CATEGORY,
  GET_MATERIAL_TYPE,
  GET_SUPPLIER,
  GET_MATERIAL_NOTES,
  CREATE_MATERIAL_NOTE,
  CREATE_MATERIAL,
  EDIT_MATERIAL,
  DELETE_MATERIAL,
  UPLOAD_CSV_MATERIAL,
} from '../actionTypes'

const initialState = {
  materialInfo: {},
  materialList: [],
  materialCategory: [],
  materialType: [],
  suppliers: [],
  notes: [],
  total: 0,
  skip: 0,
  limit: 10,
  isLoading: false,
}

const materialReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_MATERIAL_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MATERIAL_LIST.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        materialList: data,
        total: meta.total,
        skip: meta.skip,
        limit: meta.limit,
        isLoading: false,
      }
    }
    case GET_MATERIAL_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MATERIAL_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MATERIAL_BY_ID.SUCCESS:
      return {
        ...state,
        materialInfo: action.data,
        isLoading: false,
      }
    case GET_MATERIAL_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MATERIAL_CATEGORY.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MATERIAL_CATEGORY.SUCCESS:
      return {
        ...state,
        materialCategory: action.data,
        isLoading: false,
      }
    case GET_MATERIAL_CATEGORY.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MATERIAL_TYPE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MATERIAL_TYPE.SUCCESS:
      return {
        ...state,
        materialType: action.data,
        isLoading: false,
      }
    case GET_MATERIAL_TYPE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_SUPPLIER.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_SUPPLIER.SUCCESS:
      return {
        ...state,
        suppliers: action.data,
        isLoading: false,
      }
    case GET_SUPPLIER.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MATERIAL_NOTES.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MATERIAL_NOTES.SUCCESS:
      return {
        ...state,
        notes: action.data,
        isLoading: false,
      }
    case GET_MATERIAL_NOTES.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_MATERIAL_NOTE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_MATERIAL_NOTE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_MATERIAL_NOTE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_MATERIAL.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_MATERIAL.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_MATERIAL.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case EDIT_MATERIAL.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case EDIT_MATERIAL.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case EDIT_MATERIAL.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_MATERIAL.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case DELETE_MATERIAL.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_MATERIAL.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPLOAD_CSV_MATERIAL.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPLOAD_CSV_MATERIAL.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPLOAD_CSV_MATERIAL.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  materialReducer,
})
