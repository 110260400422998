import { forwardRef } from 'react'
import styled from 'styled-components'
import ModalUnstyled from '@mui/base/Modal'
import Fade from '@mui/material/Fade'

const StyledModal = styled(ModalUnstyled)`
  /*
  position: fixed;
  z-index: 2000;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  */
`

const Div = styled.div`
  /*background: var(--Base-White);*/
  border-radius: 8px;
  margin: 12px;
  min-width: 0;
`

let Backdrop = (props, ref) => {
  const { open, /*omit*/ ownerState, ...other } = props
  return (
    <Fade in={open}>
      <div ref={ref} {...other} />
    </Fade>
  )
}
Backdrop = forwardRef(Backdrop)

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  /*background-color: rgba(52, 64, 84, 0.6);*/
  -webkit-tap-highlight-color: transparent;
`

const Modal = ({ open, onClose, disableBackdropClick, children,xxoffset,yyoffset, ...props }) => {
  const state = {
    xoffset: xxoffset,
    yoffset: yyoffset,
    delta: 10,
  };
  return (
    <StyledModal
      open={open}
      onClose={disableBackdropClick ? null : onClose}
      slots={{ backdrop: StyledBackdrop }}
      closeAfterTransition
      {...props}
      style={{
        position: "absolute",
        left: `${xxoffset}px`,
        top: `${yyoffset}px`,
      }}
    >
      <Fade in={open}>
        <Div className="modal-content">{children}</Div>
      </Fade>
    </StyledModal>
  )
}

export default Modal
