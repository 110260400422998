import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import Button from '../../components/form/button/Button'
import { resetPasswordTokenUser } from '../../redux/actions/resetpassword'
import { selectFormPassword } from '../../redux/selectors'

import logo from '../../../src/assets/images/logo-icon.png'
import twitterIcon from '../../../src/assets/images/twitter-icon.png'
import facebookIcon from '../../../src/assets/images/facebook-icon.png'
import igIcon from '../../../src/assets/images/ig-icon.png'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  .mr-1-rem {
    margin-right: 1rem;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  a {
    cursor: pointer;
  }

  .icon {
    width: 2rem;
    height: auto;
    cursor: pointer;
  }

  .container {
    padding: 5rem 20rem;
  }

  .d-flex {
    display: flex;
  }

  .jt-center {
    justify-content: center;
  }

  .jt-between {
    justify-content: space-between;
  }

  .al-center {
    align-items: center;
  }

  .header {
    display: flex;
    align-items: center;
  }

  .title {
    font-size: 20px;
    font-weight: bold;
  }

  .logo {
    width: 3rem;
    height: auto;
  }

  .user-name {
    /* text-align: left; */
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .submit-button {
    padding: 10px 20px; /* Adjust the padding as needed */
  }
`

const ResetPasswordToken = () => {
  const { t } = useTranslation()
  const { token } = useParams()
  const dispatch = useDispatch()

  const { userPermissionInfo } = useSelector(selectFormPassword)

  useEffect(() => {
    dispatch(resetPasswordTokenUser(token))
  }, [dispatch, token])

  const navigate = useNavigate()
  const resetPasswordToken = () => {
    navigate('/reset-password', {
      replace: false,
    })
  }

  return (
    <Div>
      <div className="container">
        <div className="header mb-2-rem">
          <img className="logo mr-1-rem" src={logo} />
          <span className="title">Untitle UI</span>
        </div>
        <div className="mb-1-rem">
          Dear {userPermissionInfo.firstname} {userPermissionInfo.lastname},
        </div>
        <div className="mb-2-rem">
          Dear Olivia, We've received a request to reset the password for the [ERP System] account associated with [
          <a>{userPermissionInfo.email}</a>]. Use the button below to reset it. This password reset is only valid for
          the next 24 hours.
        </div>
        <div className="mb-2-rem">
          <Button type="button" onClick={resetPasswordToken}>
            Reset your password
          </Button>
        </div>
        <div className="mb-2-rem">
          If you did not request a new password, please ignore this email or <a>contact support</a> if you have
          questions.
        </div>
        <div className="mb-2-rem">The team</div>
        <div className="mb-2-rem">© 2077 Untitled UI, 100 Smith Street, Melbourne VIC 3000</div>
        <div className="d-flex jt-between al-center">
          <div className="d-flex al-center">
            <img className="icon mr-1-rem" src={logo} />
            <span className="title">Untitle UI</span>
          </div>
          <div>
            <img className="icon mr-1-rem" src={twitterIcon} />
            <img className="icon mr-1-rem" src={facebookIcon} />
            <img className="icon mr-1-rem" src={igIcon} />
          </div>
        </div>
      </div>
    </Div>
  )
}

export default ResetPasswordToken
