import { styled } from '@mui/system'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import Button from 'components/common/Button'
import Tabs from 'components/common/Tabs'
import { TASK_TEMPLATE } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import CreateTaskTPL from './create'

const ViewTaskTPL = ({ id, noExtraActions }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const opProcSelector = useSelector(selectOpProc)
  const { taskTemp: taskTplActions } = opActions()

  const { getTaskTemp, postTaskTemp, deleteTaskTemp, clearTaskTemp } = taskTplActions()
  const { taskTemp } = opProcSelector

  const handleEditClick = () => {
    if (taskTemp?.id) {
      navigate(`${location.pathname}?id=${taskTemp.id}&mode=edit`)
    }
  }

  const fetchTaskTPLs = useCallback(
    async (payload) => {
      return dispatch(getTaskTemp().list(payload))
    },
    [dispatch]
  )

  const postActiveTaskTemp = async (taskTempId) => {
    return dispatch(postTaskTemp(taskTempId).active())
  }

  const postInActiveTaskTemp = async (taskTempId) => {
    return dispatch(postTaskTemp(taskTempId).inactive())
  }

  const handleToggleStatus = () => {
    if (taskTemp) {
      switch (taskTemp.status) {
        case TASK_TEMPLATE.STATUS.Active:
          postInActiveTaskTemp(taskTemp.id).then(() => {
            dispatch(getTaskTemp(taskTemp.id).byId())
            fetchTaskTPLs()
          })
          break

        case TASK_TEMPLATE.STATUS.Inactive:
          postActiveTaskTemp(taskTemp.id).then(() => {
            dispatch(getTaskTemp(taskTemp.id).byId())
            fetchTaskTPLs()
          })
          break
        default:
          break
      }
    }
  }

  const handleDeleteTaskTemp = () => {
    if (taskTemp?.id) {
      dispatch(deleteTaskTemp(taskTemp.id)).then(() => {
        navigate(location.pathname)
        clearTaskTemp()
        fetchTaskTPLs()
      })
    }
  }

  const tabItems = [
    {
      key: 'overview',
      label: t('overview'),
      content: <CreateTaskTPL id={id} readonly />,
    },
    {
      key: 'logs',
      label: t('logs'),
      content: <div>Logs is not available for now.</div>,
    },
  ]

  const renderExtraButton = () => {
    let componentsArray = []

    if (taskTemp?.status === TASK_TEMPLATE.STATUS.Inactive) {
      componentsArray.push(
        <Button variant="outlined" onClick={handleToggleStatus}>
          {t('active')}
        </Button>,
        <Button variant="outlined" color="error" onClick={handleDeleteTaskTemp}>
          {t('delete')}
        </Button>
      )
    }

    if (taskTemp?.status === TASK_TEMPLATE.STATUS.Active) {
      componentsArray.push(
        <Button variant="outlined" color="error" onClick={handleToggleStatus}>
          {t('in_active')}
        </Button>
      )
    }

    return (
      <>
        {componentsArray.map((component, index) => {
          return component
        })}
        <Button variant="outlined" color="primary" onClick={handleEditClick}>
          {t('Edit')}
        </Button>
      </>
    )
  }

  return (
    <Container>
      <Tabs
        items={tabItems}
        defaultActiveKey="overview"
        sx={{ marginTop: '0.5rem' }}
        tabBarExtraContent={!noExtraActions && <TabBarExtraContainer>{renderExtraButton()}</TabBarExtraContainer>}
      />
    </Container>
  )
}

export default ViewTaskTPL

const TabBarExtraContainer = styled('div')`
  display: flex;
  gap: 0.5rem;
`

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
`
