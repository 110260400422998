import React from 'react'
import styled from 'styled-components'

const BadgeContainer = styled.div`
  display: inline-block;
  color: var(--Primary-700);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;

  border-radius: 16px;
  border: 1px solid var(--Primary-200);
  background: var(--Primary-50);

  padding: 4px 8px;
`

function Badge({ children, className, props }) {
  return (
    <BadgeContainer className={className} {...props}>
      {children}
    </BadgeContainer>
  )
}

export default Badge
