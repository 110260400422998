import { getAPI, postAPI } from '../../utils/api'
import { showLoading, hideLoading } from '../actions/loading'
import {
  GET_VERIFY_USER_INFO,
  GET_PERMISSION_RESET_PASSWORD_TOKEN,
  SET_PASSWORD_USERS_CHANGE,
  SET_PASSWORD,
  RESET_PASSWORD,
  FORGOT_PASSWORD,
} from '../actionTypes'

export const verifyAccountGetUserInfo = (params) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_VERIFY_USER_INFO.REQUEST })
    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }
    const { data } = await getAPI({
      url: `/admin/registration-info`,
      params,
      headers,
    })

    return dispatch({
      type: GET_VERIFY_USER_INFO.SUCCESS,
      data,
      token: params.registrationToken,
    })
  } catch (error) {
    return dispatch({
      type: GET_VERIFY_USER_INFO.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Verify Account Error',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const setPasswordUser = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: SET_PASSWORD.REQUEST })
    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }
    await postAPI({
      url: `/api/mapping-employee-user-setpassword`,
      data,
      headers,
    })

    return dispatch({
      type: SET_PASSWORD.SUCCESS,
      showAlert: true,
      alertMessage: 'Set Password Account Done',
    })
  } catch (error) {
    return dispatch({
      type: SET_PASSWORD.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Set Password Account Error',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const ResetPasswordUser = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: RESET_PASSWORD.REQUEST })
    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }
    await postAPI({
      url: `/api/mapping-employee-user-submit-resetpassword`,
      data,
      headers,
    })

    return dispatch({
      type: RESET_PASSWORD.SUCCESS,
      showAlert: true,
      alertMessage: 'Reset password User Done',
    })
  } catch (error) {
    return dispatch({
      type: RESET_PASSWORD.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Reset password User Done Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const ForgotPasswordUser = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: FORGOT_PASSWORD.REQUEST })
    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }
    await postAPI({
      url: `/api/mapping-employee-user-submit-forgotpassword`,
      data,
      headers,
    })

    return dispatch({
      type: FORGOT_PASSWORD.SUCCESS,
      showAlert: true,
      alertMessage: 'Set password for Forgot password User Done',
    })
  } catch (error) {
    return dispatch({
      type: FORGOT_PASSWORD.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Set password for Forgot password User Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const resetPasswordTokenUser = (params) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PERMISSION_RESET_PASSWORD_TOKEN.REQUEST })
    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }
    const { data } = await getAPI({
      url: `/api/user-permission-reset-password-token/${params}`,
      headers,
    })

    return dispatch({
      type: GET_PERMISSION_RESET_PASSWORD_TOKEN.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_PERMISSION_RESET_PASSWORD_TOKEN.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Verify Account Error',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const SetPasswordUsersChange = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: SET_PASSWORD_USERS_CHANGE.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }
    await postAPI({
      url: `/api/user-permission-reset-password-user`,
      data,
      headers,
    })

    return dispatch({
      type: SET_PASSWORD_USERS_CHANGE.SUCCESS,
      showAlert: true,
      alertMessage: 'Change Users Done',
    })
  } catch (error) {
    return dispatch({
      type: SET_PASSWORD_USERS_CHANGE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Set Password Account Error',
    })
  } finally {
    dispatch(hideLoading())
  }
}
