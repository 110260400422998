import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useNavigate, NavLink } from 'react-router-dom'
import dayjs from 'dayjs'
import { displayDate } from '../../utils/common'
import { v4 as uuidv4 } from 'uuid'
import Papa from 'papaparse'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import SearchText from '../../components/form/SearchText'
import TextField from '../../components/form/TextField'
import Table from '../../components/common/Table'
import SidePanel from '../../components/common/SidePanel'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import DialogConfirm from '../../components/dialog/DialogConfirm'
import Checkbox from '../../components/form/Checkbox'
import OutlineCheckbox from '../../components/form/OutlineCheckbox'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import CollapseMenu from '../../components/common/CollapseMenu'
import Modal from '../../components/common/Modal'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import MediaFile from '../../containers/cms/MediaFile'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { FiTrash2 } from 'react-icons/fi'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import { LiaTimesSolid } from 'react-icons/lia'

import {
  getApplicationTypeList,
  getApplicationRequireList,
  getApplicationFormById,
  updateApplicationForm,
  sendInvite,
  resendInvite,
  getInvitationLogs,
  createInvitation,
  deleteInvitation,
  createInvitationCsv,
  sendInvitation,
} from '../../redux/actions/applicationForm'
import { selectApplicationForm } from '../../redux/selectors'
import axios from 'axios'
import { showLoading, hideLoading } from '../../redux/actions/loading'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .mr-8-px {
    margin-right: 8px;
  }
  .mr-12-px {
    margin-right: 12px;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .align-items-center {
    align-items: center;
  }

  .table-header {
    padding: 16px 14px;
    border-radius: 8px 8px 0 0;
    background: var(--Primary-25);

    .group {
      display: flex;
      align-items: center;
    }

    .table-title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }

    .table-total {
      margin-left: 16px;
      font-size: 12px;
      font-weight: 500;
      padding: 2px 8px;
      color: var(--Doing-Badge-Font);
      border-radius: 16px;
      border: 1px var(--Doing-Badge-Stroke) solid;
    }
  }

  .icon {
    cursor: pointer;
  }

  .tab-content {
    max-width: 500px;
  }

  .header {
    display: flex;
    align-items: center;

    .highlight {
      color: var(--Primary-700);
      font-weight: 600;
    }
  }

  .headline {
    color: var(--Gray-900);
    font-size: 30px;
    font-weight: 600;
  }

  .headline-2 {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--Gray-200, #eaecf0);
  }

  .input-header {
    color: var(--Gray-700);
    font-size: 16px;
    font-weight: 500;
  }

  .tab-menu-content {
    display: flex;
    align-items: center;

    .menu {
      padding: 12px;
      font-weight: 600;
      cursor: pointer;
      background: var(--Tab-Bg-Default);
      color: var(--Tab-Font-Default);

      &.active {
        color: var(--Tab-Font-Current);
        border-bottom: 2px solid var(--Tab-Underline-Current);
        background: var(--Tab-Bg-Current);
      }
    }
  }

  .link {
    color: var(--Primary-700);
    font-weight: 600;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .delete-btn {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }

  .search-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    column-gap: 12px;
    /* column-gap: 1rem;
    margin-bottom: 1rem; */
  }

  .modal-content {
    padding: 1rem;
    width: 480px;
  }

  .button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 12px;
  }

  .headline-modal {
    color: var(--Gray-900);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }

  .sub-headline-modal {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  .view-link {
    color: var(--Primary-700);
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .badge {
    padding: 2px 8px;
    align-items: center;
    color: var(--Gray-700);
    background: var(--Gray-50);
    border: 1px solid var(--Gray-200);
    border-radius: 16px;

    &.wait-for-response {
      color: var(--Rejected-Badge-Font);
      background: var(--Rejected-Badge-Bg);
      border: 1px solid var(--Rejected-Badge-Stroke);
    }

    &.submit {
      color: var(--Approved-Badge-Font);
      background: var(--Approved-Badge-Bg);
      border: 1px solid var(--Approved-Badge-Stroke);
    }
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .error {
    color: var(--Error-600);
  }

  .error-border {
    border: 1px solid var(--Error-300);
  }
`

const DisabledLink = styled.div`
  cursor: not-allowed;
  color: grey;
`

const initApplicationForm = {
  name: '',
  active_date: undefined,
  expire_date: undefined,
  application_form_type: null,
  application_form_requires: [],
}

const initStatusDropdown = [
  {
    value: 'active',
    text: 'Active',
  },
  {
    value: 'inactive',
    text: 'Inactive',
  },
]
const initStatusInvitationDropdown = [
  {
    value: 'not-send',
    text: 'not send',
  },
  {
    value: 'wait-for-response',
    text: 'wait for response',
  },
  {
    value: 'submit',
    text: 'submit',
  },
]
const initFormInvite = {
  name: '',
  email: '',
}

const initMessageModal = {
  headline: '',
  message: '',
}

const initValidateForm = {
  name: null,
  active_date: null,
  expire_date: null,
}

const allowedTypes = ['files']

function ViewApplicationForm() {
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const {
    applicationForms,
    total,
    limit,
    page,
    pageCount,
    isLoading,
    applicationTypes,
    applicationRequires,
    applicationInfo,
    verifyApplicationInfo,
    invitationLogs,
    state,
  } = useSelector(selectApplicationForm)
  const [selectedAll, setSelectedAll] = useState(false)
  const [applicationId, setApplicationId] = useState('')
  const [invitationId, setInvitationId] = useState('')
  const [applicationFormInit, setApplicationFormInit] = useState(initApplicationForm)
  const [applicationForm, setApplicationForm] = useState(initApplicationForm)
  const [applicationRequireList, setApplicationRequireList] = useState([])
  const [initApplicationRequireList, setInitApplicationRequireList] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [tabMenu, setTabMenu] = useState('form-information')
  const [shareLinkList, setShareLinkList] = useState([])
  const [formInvite, setFormInvite] = useState(initFormInvite)
  const [files, setFiles] = useState()
  const [csvData, setCsvData] = useState([])
  const [invitationLogList, setInvitationLogList] = useState([])
  const [invitationLogList2, setInvitationLogList2] = useState([])
  const [search, setSearch] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  const [loading, setLoading] = useState(true)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  // validate
  const [errorsAccessRole, setErrorsAccessRole] = useState(initValidateForm)
  // selected dropdown
  const [statusSelected, setStatusSelected] = useState('')
  // modal
  const [uploadModal, setUploadModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [confirmUpdateModal, setConfirmUpdateModal] = useState(false)
  const [confirmSendInvitationModal, setConfirmSendInvitationModal] = useState(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  // modal message
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const selectAll = (event) => {
    const { checked } = event.target
    if (checked) {
      const newInvitationSelected = [...invitationLogList].map((v) => ({
        ...v,
        isSelected: checked,
      }))
      setInvitationLogList(newInvitationSelected)
    } else {
      const newInvitationSelected = [...invitationLogList].map((v) => ({
        ...v,
        isSelected: checked,
      }))
      setInvitationLogList(newInvitationSelected)
    }
    setSelectedAll(checked)
  }

  const invitationColumn = [
    {
      Header: (
        <div className="d-flex align-items-center">
          <OutlineCheckbox className="mr-8-px" checked={selectedAll} onChange={selectAll} />
          <span>{t('no')}</span>
        </div>
      ),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('name'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('email'),
      accessor: 'email',
      disableSortBy: false,
    },
    {
      Header: t('invited_date'),
      accessor: 'invited_date',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('submit_date'),
      accessor: 'submitted_date',
      disableSortBy: false,
    },
    {
      Header: t('view_response'),
      accessor: 'view',
      disableSortBy: false,
    },
    {
      accessor: 'deleteAction',
      disableSortBy: false,
    },
  ]

  const resentInvite = useCallback(
    async (id) => {
      const request = {
        data: {
          action: 'resentinvite',
        },
      }
      dispatch(resendInvite(applicationId, id, request))
    },
    [dispatch, applicationId]
  )

  const deleteInvite = useCallback(async (id, name, email) => {
    setInvitationId(id)
    setName(name)
    setEmail(email)
    setConfirmDeleteModal(true)
  }, [])

  const deleteInvitations = () => {
    const request = {
      data: {
        status: 'delete',
      },
    }
    dispatch(deleteInvitation(invitationId, applicationId, request))
    setConfirmDeleteModal(false)
  }

  const selectInvitation = useCallback(
    (id, event) => {
      const newInvitationSelected = [...invitationLogList]
      const indexMatch = newInvitationSelected.findIndex((v) => v.id == id)
      newInvitationSelected[indexMatch].isSelected = event.target.checked
      setInvitationLogList(newInvitationSelected)
    },
    [invitationLogList]
  )

  useEffect(() => {
    if (invitationLogList.every((v) => v.isSelected)) {
      setSelectedAll(true)
    } else {
      setSelectedAll(false)
    }
  }, [invitationLogList])

  useEffect(() => {
    if (files && files.length === 0) {
      setCsvData([])
    }
  }, [files])

  useEffect(() => {
    if (state === 'CREATE_INVITATION_WITH_CSV.SUCCESS' && isLoading === false) {
      setSuccessMessageModal({
        headline: 'Import Successful',
        message: '',
      })
      setSuccessModal(true)
    }
    if (state === 'GET_INVITATIONS.SUCCESS' && isLoading === false) {
      setSelectedAll(false)
    }
    if (state == 'UPDATE_APPLICATION_FORM.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: 'Edit application form succeed.',
        message: '',
      })
      setSuccessModal(true)
    }
    if (state == 'SEND_INVITATION.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: 'Sent invitation succeed',
        message: '',
        // message: '{amount} error',
      })
      setSuccessModal(true)
    }
    if (state == 'DELETE_INVITATION.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: 'Delete invitaion succeed.',
        message: '',
      })
      setSuccessModal(true)
    }
  }, [state, isLoading])

  useEffect(() => {
    const newInvitationLogs = invitationLogs.map((v, i) => {
      const { id, no, isSelected, attributes } = v
      const { name, email, invited_date, status, submitted_date } = attributes
      return {
        isSelected,
        no,
        id,
        name,
        email,
        view:
          status === 'submit' ? (
            <NavLink target="_blank" to={`/organization-management/application-form/view-application-form-info/${id}`}>
              view
            </NavLink>
          ) : (
            <DisabledLink>view</DisabledLink>
          ),
        invited_date: displayDate(invited_date),
        submitted_date: displayDate(submitted_date),
        status: (
          <span
            className={`badge ${
              status === 'wait-for-response' ? 'wait-for-response' : status === 'submit' ? 'submit' : ''
            }`}
          >
            {status}
          </span>
        ),
        status2: status,
        deleteAction: (
          <img className="icon" src={iconDelete} title="Delete" onClick={() => deleteInvite(id, name, email)} />
        ),
      }
    })

    setInvitationLogList(newInvitationLogs)
  }, [invitationLogs])

  useEffect(() => {
    const newInvitationLogList = invitationLogList.map((v) => ({
      ...v,
      no: (
        <div className="d-flex align-items-center">
          <OutlineCheckbox
            className="mr-8-px"
            checked={v?.isSelected}
            onChange={(event) => selectInvitation(v.id, event)}
            disabled={v.status2 == 'submit' ? true : false}
          />
          {v.no}
        </div>
      ),
    }))

    setInvitationLogList2(newInvitationLogList)
  }, [invitationLogList, selectInvitation])

  useEffect(() => {
    dispatch(getApplicationTypeList())
    dispatch(getApplicationRequireList())
  }, [dispatch])

  useEffect(() => {
    const { id } = params
    if (id) {
      dispatch(getApplicationFormById(id))
      setApplicationId(id)
    }
  }, [params, dispatch])

  useEffect(() => {
    const filter = {
      skip: 1,
      limit: limit,
      filters: searchFilters.map((v) => v.name),
      status: statusSelected,
    }
    dispatch(getInvitationLogs(applicationId, filter))
  }, [dispatch, applicationId, statusSelected])

  useEffect(() => {
    if (applicationInfo?.application_form_requires?.data.length > 0) {
      const applicationRequireChecked = applicationInfo?.application_form_requires?.data.map((v) => v.id)
      const newApplicationRequireList = initApplicationRequireList.map((v) => ({
        ...v,
        checked: applicationRequireChecked.includes(v.value),
      }))
      setApplicationRequireList(newApplicationRequireList)
    }

    setApplicationForm((prev) => ({
      ...prev,
      name: applicationInfo?.name,
      active_date: applicationInfo?.active_date,
      expire_date: applicationInfo?.expire_date,
      application_form_type: applicationInfo?.application_form_type?.data?.id,
      application_form_requires: [],
      status: applicationInfo?.status,
    }))
    setApplicationFormInit((prev) => ({
      ...prev,
      name: applicationInfo?.name,
      active_date: applicationInfo?.active_date,
      expire_date: applicationInfo?.expire_date,
      application_form_type: applicationInfo?.application_form_type?.data?.id,
      application_form_requires: [],
      status: applicationInfo?.status,
    }))
  }, [applicationInfo, initApplicationRequireList])

  useEffect(() => {
    if (applicationInfo?.share_link?.length > 0) {
      const newShareLinkList = applicationInfo?.share_link?.map((v, i) => ({
        ...v,
        no: i + 1,
        createdAt: displayDate(v.createdAt),
        sendInvitationBtn: <Button onClick={() => resentInvite(v.id)}>Send Invitation</Button>,
      }))
      setShareLinkList(newShareLinkList)
    } else {
      setShareLinkList([])
    }
  }, [applicationInfo?.share_link, resentInvite])

  useEffect(() => {
    const newApplicationRequires = applicationRequires.map((v) => ({
      text: v.name,
      value: v.id,
      checked: v.name == 'Information' ? true : false,
    }))
    setInitApplicationRequireList(newApplicationRequires)
  }, [applicationRequires])

  // onChange
  const onChangeApplicationForm = (event) => {
    const { name, value } = event.target
    setApplicationForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeDropdownApplicationForm = (name, value) => {
    setApplicationForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeApplicationRequirement = (value, id) => {
    const newApplicationRequires = [...applicationRequireList]
    const index = newApplicationRequires.findIndex((v) => v.value == id)
    newApplicationRequires[index].checked = value
    setApplicationRequireList(newApplicationRequires)
  }

  const onChangeApplicationDate = (name, value) => {
    setApplicationForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeFormInvite = (event) => {
    const { value, name } = event.target
    setFormInvite((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeUploadFile = (value) => {
    if (value[0]) {
      dispatch(showLoading())
      const { ext, url } = value[0]

      if (ext && ext === '.csv') {
        axios(url).then(async (res) => {
          const csvData = Papa.parse(res.data, { header: true })
          const { data } = csvData
          const keys = Object.keys(data[0])

          const isValidTemplate = keys[0].toLocaleLowerCase() === 'email' && keys[1].toLocaleLowerCase() === 'name'
          if (isValidTemplate) {
            setFiles(value)
            setCsvData(data)
            dispatch(hideLoading())
          } else {
            setFailMessageModal({
              headline: 'File cannot be uploaded',
              message: 'File template is incorrect',
            })
            setFailModal(true)
            dispatch(hideLoading())
          }
        })
      } else {
        setFailMessageModal({
          headline: 'File cannot be uploaded',
          message: 'File format is incorrect',
        })
        setFailModal(true)
        dispatch(hideLoading())
      }
    } else {
      setFiles(value)
    }
  }

  // submit
  const onSubmitEditApplicationForm = () => {
    const request = {
      data: {
        ...applicationForm,
        active_date: applicationForm.active_date ? applicationForm.active_date : dayjs().format('YYYY-MM-DD'),
        expire_date: applicationForm.expire_date ? applicationForm.expire_date : dayjs().format('YYYY-MM-DD'),
        application_form_requires: applicationRequireList.filter((v) => v.checked == true).map((v) => v.value),
      },
    }
    dispatch(updateApplicationForm(applicationId, request))
    setIsEdit(false)
    setConfirmUpdateModal(false)
  }

  const sentInvite = () => {
    const request = {
      data: {
        ...formInvite,
        application_form: Number(applicationId),
      },
    }

    dispatch(createInvitation(request))
    setFormInvite(initFormInvite)
  }

  const uploadCsv = () => {
    if (csvData.length > 0) {
      const request = csvData.map((v) => ({
        name: v.Name,
        email: v.Email,
        application_form: Number(applicationId),
      }))

      const finalData = {
        data: request,
      }
      dispatch(createInvitationCsv(applicationId, finalData))
      closeUploadModal()
    } else {
      setFailMessageModal({
        headline: 'Please upload file',
        message: '',
      })
      setFailModal(true)
    }
  }

  const onSearch = () => {
    const newFilter = {
      key: uuidv4(),
      name: search,
    }
    const filter = {
      skip: 1,
      limit: limit,
      filters: [...searchFilters, newFilter].map((v) => v.name),
      status: statusSelected,
    }
    dispatch(getInvitationLogs(applicationId, filter))
    setSearch('')
    setSearchFilters((prev) => [...prev, newFilter])
  }

  const onClearSearch = () => {
    const filter = {
      skip: 1,
      limit: limit,
      filters: [],
      status: '',
    }
    dispatch(getInvitationLogs(applicationId, filter))
    setSearch('')
    setStatusSelected('')
    setSearchFilters([])
  }

  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)

    const filter = {
      skip: 1,
      limit: limit,
      filters: newSearchFilters.map((v) => v.name),
      status: statusSelected,
    }
    dispatch(getInvitationLogs(applicationId, filter))
    setSearch('')
  }

  const onChangePagination = async (value) => {
    const filter = {
      skip: value.page,
      limit: limit,
      filters: searchFilters.map((v) => v.name),
      status: statusSelected,
    }
    dispatch(getInvitationLogs(applicationId, filter))
  }
  const sendInvitations = async () => {
    const request = {
      data: {
        list_invitation: invitationLogList2.filter((v) => v.isSelected).map((v) => v.id),
      },
    }
    dispatch(sendInvitation(applicationId, request))
    setConfirmSendInvitationModal(false)
  }

  // optional
  const validateFormEdit = () => {
    let newErrors = {
      ...initValidateForm,
    }

    if (!applicationForm.name) {
      newErrors.name = 'Please fill in Form Name'
    }

    setErrorsAccessRole(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  const toggleEdit = () => {
    setIsEdit(true)
  }

  const cancleEdit = () => {
    setErrorsAccessRole(initValidateForm)
    setIsEdit(false)
    setApplicationForm(applicationFormInit)
    if (applicationInfo?.application_form_requires?.data.length > 0) {
      const applicationRequireChecked = applicationInfo?.application_form_requires?.data.map((v) => v.id)
      const newApplicationRequireList = initApplicationRequireList.map((v) => ({
        ...v,
        checked: applicationRequireChecked.includes(v.value),
      }))
      setApplicationRequireList(newApplicationRequireList)
    }
  }

  const toggleTabMenu = (tab) => {
    setTabMenu(tab)
  }

  const backToApplicationForm = () => {
    navigate('/organization-management/application-form')
  }

  const toArray = (file) => {
    if (Array.isArray(file)) {
      return file
    } else {
      return file ? [file] : []
    }
  }

  // modal
  const openConfirmSendInvitaionModal = () => {
    const isSelected = invitationLogList2.some((v) => v.isSelected)
    if (isSelected) {
      setConfirmSendInvitationModal(true)
    }
  }

  const openConfirmUpdateModal = () => {
    const isValid = validateFormEdit()
    if (isValid) setConfirmUpdateModal(true)
  }

  const closeUploadModal = () => {
    setUploadModal(false)
    setFiles()
    setCsvData([])
  }

  const confirmSuccess = async () => {
    setSuccessModal(false)
  }

  const confirmFail = async () => {
    setFailModal(false)
  }

  return (
    <Div>
      <div className="header mb-2-rem">
        <RiHome6Line size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px" onClick={backToApplicationForm}>
          {t('application_form')}
        </span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="highlight">แบบสำรวจข้อมูลพนักงาน</span>
      </div>

      <div className="d-flex justify-content-between">
        <span className="headline">แบบสำรวจข้อมูลพนักงาน</span>
        <Link
          to={`/organization-management/application-form/view-create-application-form?requireList=1,2,3,4`}
          target="_blank"
        >
          <ButtonOutline>{t('preview_form')}</ButtonOutline>
        </Link>
      </div>
      <div>{t('users_management_sub_header')}</div>
      <div className="divider"></div>
      <div className="tab-menu-content mb-2-rem">
        <div
          className={`menu ${tabMenu === 'form-information' ? 'active' : ''}`}
          onClick={() => toggleTabMenu('form-information')}
        >
          {t('form_information')}
        </div>
        <div className={`menu ${tabMenu === 'invitation' ? 'active' : ''}`} onClick={() => toggleTabMenu('invitation')}>
          {t('invitation')}
        </div>
      </div>

      {tabMenu === 'form-information' ? (
        <div className="tab-content">
          <div className="mb-1-rem">
            <div className="input-header mb-1-rem">{t('application_form_name')}</div>
            <div className="input-new">
              <TextField
                className={`${errorsAccessRole.name ? 'error-border' : ''}`}
                name="name"
                value={applicationForm.name}
                onChange={onChangeApplicationForm}
                placeholder={t('application_form_name')}
                disabled={!isEdit}
              />
              {errorsAccessRole.name && <div className="error mt-1-rem">{errorsAccessRole.name}</div>}
            </div>
          </div>

          <div className="mb-1-rem">
            <div className="input-header mb-1-rem">{t('active_date')}</div>
            <Date
              name="active_date"
              value={applicationForm.active_date}
              onChange={(value) => onChangeApplicationDate('active_date', value)}
              disabled={!isEdit}
            />
          </div>

          <div className="mb-1-rem">
            <div className="input-header mb-1-rem">{t('expire_date')}</div>
            <Date
              name="expire_date"
              value={applicationForm.expire_date}
              onChange={(value) => onChangeApplicationDate('expire_date', value)}
              disabled={!isEdit}
            />
          </div>

          <div className="input-header mb-1-rem">{t('additional_information_requirment')}</div>

          {applicationRequireList.map((v, i) => (
            <div className="d-flex align-items-center mb-1-rem" key={v.value}>
              <OutlineCheckbox
                className="mr-1-rem"
                checked={v?.checked}
                onChange={() => onChangeApplicationRequirement(!v?.checked, v.value)}
                disabled={!isEdit || v?.text == 'Information' ? true : false}
              />
              <span>{v.text}</span>
            </div>
          ))}

          <div className="mb-2-rem">
            <div className="input-header mb-1-rem">{t('status')}</div>
            <Dropdown
              isStatus={true}
              name="status"
              optionList={initStatusDropdown}
              value={applicationForm.status}
              onChange={(value) => onChangeDropdownApplicationForm('status', value)}
              disabled={!isEdit}
            />
          </div>

          <div>
            {isEdit ? (
              <>
                <ButtonOutline className="mr-8-px" onClick={cancleEdit}>
                  {t('cancel')}
                </ButtonOutline>
                <Button onClick={openConfirmUpdateModal}>{t('save')}</Button>
              </>
            ) : (
              <Button onClick={toggleEdit}>{t('edit')}</Button>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="headline-2">1. {t('add_employee')}</div>
          <div className="mb-1-rem">{t('users_management_sub_header')}</div>

          <div className="mb-1-rem">
            <TextField
              className="mr-8-px"
              name="email"
              value={formInvite.email}
              onChange={onChangeFormInvite}
              placeholder={t('email')}
            />
            <TextField
              className="mr-8-px"
              name="name"
              value={formInvite.name}
              onChange={onChangeFormInvite}
              placeholder={t('name')}
            />
            <Button onClick={sentInvite}>{t('add_to_list')}</Button>
          </div>
          <div className="mb-1-rem">
            <span className="link" onClick={() => setUploadModal(true)}>
              {t('import_from_file')}
            </span>
          </div>

          <div className="headline-2">2. {t('send_invitation')}</div>
          <div className="mb-1-rem">{t('users_management_sub_header')}</div>

          <div className="table-header">
            <div className="group mb-1-rem">
              <div className="table-title">{t('name_list')}</div>
              <div className="table-total">{total} {t('people')}</div>
            </div>
            <div className="search-container mb-1-rem">
              <SearchText
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSubmit={onSearch}
                onClear={onClearSearch}
                placeholder={t('search_name_or_email')}
              />
              <Dropdown
                optionList={initStatusInvitationDropdown}
                value={statusSelected}
                onChange={(value) => setStatusSelected(value)}
              />
              <ButtonOutline onClick={onClearSearch}>{t('clear')}</ButtonOutline>
              <div></div>
              {/* <ButtonOutline className="d-flex justify-content-center delete-btn" append={<FiTrash2 />}>
                Delete selected
              </ButtonOutline> */}
              <Button className="d-flex justify-content-center" onClick={openConfirmSendInvitaionModal}>
                {t('send_invitation')}
              </Button>
            </div>
            <div className="badge-search-content">
              {searchFilters.map((v) => (
                <div className="badge" key={v.key}>
                  <span className="name">{v.name}</span>{' '}
                  <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
                </div>
              ))}
            </div>
          </div>

          <Table
            columns={invitationColumn}
            data={invitationLogList2}
            page={page}
            pageCount={pageCount}
            onStateChange={onChangePagination}
          />
        </div>
      )}

      {/* Modal */}
      <Modal open={Boolean(uploadModal)} onClose={closeUploadModal} disableBackdropClick>
        <Div>
          <div className="modal-content">
            <div className="headline-modal">{t('upload_file')}</div>
            <div className="mb-1-rem sub-headline-modal">Upload excel or csv file</div>
            <div className="mb-1-rem">
              <MediaFile
                multiple={false}
                allowedTypes={allowedTypes}
                file={toArray(files)}
                onChange={onChangeUploadFile}
              />
            </div>

            <div className="button-content">
              <ButtonOutline onClick={closeUploadModal} className="d-flex justify-content-center">
                {t('cancel')}
              </ButtonOutline>
              <Button onClick={uploadCsv} className="d-flex justify-content-center">
                {t('upload')}
              </Button>
            </div>
          </div>
        </Div>
      </Modal>
      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFail}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
      <DialogConfirm
        open={Boolean(confirmUpdateModal)}
        onClose={() => setConfirmUpdateModal(false)}
        onSubmit={onSubmitEditApplicationForm}
        title={t('confirm_edit_application_form_header')}
        description={applicationForm.name}
        textYes={t('confirm')}
        textNo={t('back')}
      />
      <DialogConfirm
        open={Boolean(confirmSendInvitationModal)}
        onClose={() => setConfirmSendInvitationModal(false)}
        onSubmit={sendInvitations}
        title={t('confirm_send_invitation_header')}
        description={`total ${invitationLogList2.filter((v) => v.isSelected).length} employees`}
        textYes={t('confirm')}
        textNo={t('back')}
      />
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={deleteInvitations}
        title={t('confirm_delete_invitation_header')}
        nameItem={name}
        description={email}
      />
    </Div>
  )
}

export default ViewApplicationForm
