import { Button as MuiButton } from '@mui/material'
import { styled } from '@mui/system'
import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { RiSearchLine } from 'react-icons/ri'

import { Input } from 'components/form2/form-input'
import { Option, Select } from 'components/form2/form-select'
import { SP } from 'constants/operation-process'
import { Controller, useForm } from 'react-hook-form'

const defaultValues = {
  status: '',
  text_search: '',
}

/** //TODO: set label values ใหม่ */
const statuses = [
  { id: SP.STATUS.Open, label: 'Open' },
  { id: SP.STATUS.InProgress, label: 'In Progress' },
  { id: SP.STATUS.InQueue, label: 'In Queue' },
  { id: SP.STATUS.Completed, label: 'Completed' },
  { id: SP.STATUS.Canceled, label: 'Canceled' },
]

const SearchSPForm = forwardRef(function SearchSPForm(props, ref) {
  const { t } = useTranslation()
  const { onSubmit, onFormChange } = props
  const form = useForm({ defaultValues })
  const { handleSubmit, control, watch, reset } = form

  const watchStatus = watch('status')
  const watchAllFields = watch()

  useImperativeHandle(ref, () => ({
    reset() {
      form.reset(defaultValues)
    },
    submit: onSubmit && handleSubmit(onSubmit)(),
  }))

  const handleClear = () => {
    form.reset(defaultValues)
    onFormChange && typeof onFormChange === 'function' && onFormChange(defaultValues)
  }

  useEffect(() => {
    onFormChange && typeof onFormChange === 'function' && onFormChange(watchAllFields)
  }, [watchStatus])

  return (
    <Form
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault()

          form.setFocus('status')
          onFormChange && typeof onFormChange === 'function' && onFormChange(watchAllFields)
        }
      }}
    >
      <section>
        <Controller
          name="text_search"
          control={control}
          render={({ field }) => {
            return (
              <Input
                {...field}
                placeholder={t('op_department_operation_search')}
                sx={{ minWidth: '500px' }}
                startAdornment={<RiSearchLine fontSize={16} style={{ margin: '0 0 0 0.75rem' }} />}
              />
            )
          }}
        />
      </section>

      <section>
        <Controller
          name="status"
          control={control}
          render={({ field }) => {
            const handleStatusChange = (_, values) => {
              field.onChange(values)
              return values
            }

            return (
              <Select
                {...field}
                sx={{ minWidth: '120px' }}
                onChange={handleStatusChange}
                renderValue={(option) => {
                  if (!option || option === null) {
                    return <div style={{ color: 'var(--Gray-300)' }}>{t('status')}</div>
                  }
                  return option.label
                }}
              >
                {statuses.map((status) => (
                  <Option key={status.id} value={status.id}>
                    {status.label}
                  </Option>
                ))}
              </Select>
            )
          }}
        />
      </section>

      <section className="filter-actions">
        <Button variant="contained" className="gray" onClick={handleClear}>
          {t('clear')}
        </Button>
      </section>
    </Form>
  )
})

export default SearchSPForm

const Form = styled('form')`
  display: flex;
  /* flex-direction: column; */
  gap: 1rem;

  section {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &.filter-actions {
      align-items: flex-end;
      justify-content: flex-end;
      gap: 1rem;
    }
  }

  section > label {
    color: 'var(--Gray-900)';
    font-size: 14px;

    & .error {
      color: red !important;
    }
  }
`

const Button = styled(MuiButton)`
  font-family: 'Inter', 'Noto Sans Thai';
  color: var(--Main-Button-Font);
  background-color: var(--Main-Button-Bg);
  border-radius: 0.5rem;
  text-transform: capitalize;
  box-shadow: none;
  height: fit-content;
  padding: 0.5rem 1rem;

  &:hover {
    color: var(--Main-Button-Font-Hover);
    background-color: var(--Main-Button-Bg-Hover);
  }

  &.gray {
    color: var(--Gray-900);
    background-color: var(--Base-White);
    border: 1px solid var(--Gray-200);

    &:hover {
      color: var(--Gray-900);
      background-color: var(--Gray-50);
      box-shadow: 0px 2px 2px var(--Gray-50);
    }
  }
`
