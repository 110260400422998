import { styled } from '@mui/system'
import { useState } from 'react'

const Tabs = ({ tabBarExtraContent, items, defaultActiveKey, sx, ...props }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveKey || items[0].key)

  return (
    <TabsContainer className={`erp-tabs`} sx={{ ...sx }}>
      <TabsNav className={`erp-tabs-nav`}>
        <TabsNavWrap className={`erp-tabs-nav-wrap`}>
          <TabsNavList className={`erp-tabs-nav-list`}>
            {items.map(({ key, label }) => (
              <TabsTabItem
                key={key}
                className={`erp-tabs-tab${activeTab === key ? ' erp-tabs-tab-active' : ''}`}
                onClick={() => setActiveTab(key)}
              >
                {label}
              </TabsTabItem>
            ))}
          </TabsNavList>
        </TabsNavWrap>

        {tabBarExtraContent && (
          <TabsExtraContent className={`erp-tabs-extra-content`}>{tabBarExtraContent}</TabsExtraContent>
        )}
      </TabsNav>

      <TabsContentHolder className={`erp-tabs-content-holder`}>
        <TabsContent className={`erp-tabs-content`}>
          {items.map(({ key, content }) => (
            <TabPane
              key={key}
              className={`erp-tabs-tabpane ${
                activeTab === key ? 'erp-tabs-tabpane-active' : 'erp-tabs-tabpane-hidden'
              }`}
            >
              {content}
            </TabPane>
          ))}
        </TabsContent>
      </TabsContentHolder>
    </TabsContainer>
  )
}

export default Tabs

const TabPane = styled('div')`
  &.erp-tabs-tabpane {
    outline: none;
  }

  &.erp-tabs-tabpane-hidden {
    display: none;
  }
`

const TabsContent = styled('div')`
  &.erp-tabs-content {
    position: relative;
    width: 100%;
  }
`

const TabsContentHolder = styled('div')`
  &.erp-tabs-content-holder {
    flex: auto;
    min-width: 0;
    min-height: 0;
  }
`

const TabsExtraContent = styled('div')`
  &.erp-tabs-extra-content {
    flex: none;
  }
`

const TabsTabItem = styled('div')`
  &.erp-tabs-tab {
    position: relative;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-flex;
    align-items: center;
    padding: 12px;
    font-size: 14px;
    background: transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    color: var(--Gray-900);
    transition: all 100ms ease-out;
    border-bottom: 2px solid transparent;

    &:not(.erp-tabs-tab-active):hover {
      background: var(--Gray-100);
      color: var(--Primary-600);
    }

    &.erp-tabs-tab-active {
      font-weight: 500;
      color: var(--Primary-600);
      background: var(--Primary-50);
      border-bottom: 2px solid var(--Primary-600);
    }
  }
`

const TabsNavList = styled('div')`
  &.erp-tabs-nav-list {
    position: relative;
    display: flex;
    transition: opacity 0.3s;
  }
`

const TabsNavWrap = styled('div')`
  &.erp-tabs-nav-wrap {
    position: relative;
    display: flex;
    flex: auto;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;
    transform: translate(0);
  }
`

const TabsNav = styled('div')`
  &.erp-tabs-nav {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid var(--Gray-200);
  }
`

const TabsContainer = styled('div')`
  margin: 0;
  padding: 0;
  flex-direction: column;

  &.erp-tabs {
    box-sizing: border-box;
    color: var(--Gray-900);
    font-size: 14px;
    line-height: 20px;
    list-style: none;
    font-family: var(--erp-font-family);
    display: flex;
  }
`
