import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Table from '../../../components/common/Table'
import Checkbox from '../../../components/form/Checkbox'
import SearchText from '../../../components/form/SearchText'
import Button from '../../../components/form/button/Button'
import CapsuleStatus from '../../../components/widget/element/CapsuleStatus'
import DocumentOptionButton from '../../../components/widget/element/DocumentOptionButton'
import DropdownSelect from '../../../components/widget/element/DropDownSelect'
import { getBusinessDocumentList } from '../../../redux/actions/document'
import { selectDocument } from '../../../redux/selectors'
import { convertDateFormat } from '../../../utils/helper'
import CreditAdjustmentDocument from '../business-doc/doc-view/CreditAdjustmentDocument'
import PaymentVoucher from '../business-doc/doc-view/PaymentVoucher'
import ReceiptVoucher from '../business-doc/doc-view/ReceiptVoucher'

export default function AccountingDocument() {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const docType = searchParams.get('doctype')

  const lastEditList = [
    {
      text: 'Today',
      value: 'today',
    },
    {
      text: 'Last 7 days',
      value: 'last7',
    },
    {
      text: 'Last 30 days',
      value: 'last30',
    },
  ]

  const HEADER_COLUMN_PAYMENT_VOUCHER = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'doc_date',
      disableSortBy: false,
    },
    {
      Header: 'Document Id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'รายละเอียดการจ่าย',
      accessor: 'detail',
      disableSortBy: false,
    },
    {
      Header: 'Payment Type ',
      accessor: 'payment_type',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const HEADER_COLUMN_CREDIT_ADJUSTMENT_DOCUMENT = [
    {
      Header: ' ',
      accessor: 'checkbox',
      disableSortBy: false,
    },
    {
      Header: 'Date',
      accessor: 'doc_date',
      disableSortBy: false,
    },
    {
      Header: 'Document Id',
      accessor: 'doc_id',
      disableSortBy: false,
    },
    {
      Header: 'Customer Id',
      accessor: 'customer_id',
      disableSortBy: false,
    },
    {
      Header: 'Customer Name',
      accessor: 'customer_name',
      disableSortBy: false,
    },
    {
      Header: 'วงเงินปัจจุบัน',
      accessor: 'current_limit',
      disableSortBy: false,
    },
    {
      Header: 'วงเงินปรับใหม่',
      accessor: 'new_current_limit',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: ' ',
      accessor: 'action',
      disableSortBy: false,
    },
  ]

  const [bodyTableList, setBodyTableList] = useState([])
  const [bodyDataList, setBodyDataList] = useState([])
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [isSetData, setIsSetData] = useState(false)

  const { isLoading, pageCount, documentList } = useSelector(selectDocument)

  const [filter, setFilter] = useState({
    lastEdit: '',
    searchText: '',
    docType: docType || '',
  })

  const [currentPage, setCurrentPage] = useState(1)

  const navigate = useNavigate()
  const [itemSelected, setItemSelected] = useState()
  const [dialogQuotationType, setDialogQuotationType] = useState('')
  const [isShowPreview, setIsShowPreview] = useState(false)
  const [isShowModalDownloadQuotation, setIsShowModalDownloadQuotation] = useState(false)
  const [isShowDialogRequestApprove, setIsShowDialogRequestApprove] = useState(false)

  const [isHindModalShare, setIsHindModalShare] = useState(false)

  const [optionClickStage, setOptionClickStage] = useState()

  const [docTitle, setDocTitle] = useState('')

  useEffect(() => {
    if (docType != '') {
      setFilter({ ...filter, docType: docType })
      titleByDocType(docType)
    }
  }, [docType])

  const titleByDocType = (txtDocType) => {
    if (txtDocType == 'credit_adjustment_document') {
      setDocTitle('เอกสารปรับปรุงสินเชื่อ')
    } else if (txtDocType == 'receipt_voucher') {
      setDocTitle('ใบสำคัญรับ')
    } else if (txtDocType == 'credit_note_goods_receipt') {
      setDocTitle('ใบลดหนี้/ใบรับสินค้า')
    } else if (txtDocType == 'tax_withholding_certificate') {
      setDocTitle('หนังสือรับรองการหักภาษี ณ ที่จ่าย')
    } else if (txtDocType == 'tax_invoice') {
      setDocTitle('ใบกำกับภาษี')
    } else if (txtDocType == 'payment_voucher') {
      setDocTitle('ใบสำคัญจ่าย')
    }
  }

  useEffect(() => {
    ;(async () => {
      dispatch(
        getBusinessDocumentList(
          {
            page: currentPage,
            pageSize: 10,
          },
          filter
        )
      )
    })()
  }, [filter])

  useEffect(() => {
    if (documentList?.length >= 0) {
      let tmpArray = [...documentList]

      for (let i = 0; i < tmpArray.length; i++) {
        tmpArray[i].checked = false
      }
      setBodyDataList(tmpArray) // Keep Original Data
      setIsSetData(false)
    }
  }, [documentList])

  useEffect(() => {
    if (!isSetData) {
      const tmpList = [...bodyDataList]
      let viewList = []

      if (docType == 'payment_voucher') {
        viewList = tmpList.map((item, index) => ({
          checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
          no: index + 1,
          doc_date: convertDateFormat(item?.doc_date, 'DD MMM YYYY'),
          doc_id: (
            <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
              {item?.doc_id || '-'}
            </span>
          ),
          detail: item?.details || '-',
          payment_type: getPaymentStringFromType(item?.payment_type),
          status: item?.status ? <CapsuleStatus status={item?.status} /> : '',
          action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
        }))
      } else if (docType == 'credit_adjustment_document') {
        viewList = tmpList.map((item, index) => ({
          checkbox: <Checkbox checked={item?.checked} onChange={(e) => handleBodyTableChecked(e, index)} />,
          no: index + 1,
          doc_date: convertDateFormat(item?.doc_date, 'DD MMM YYYY'),
          doc_id: (
            <span className="txt-doc-item" onClick={() => handleClickedDocItem(item)}>
              {item?.doc_id || '-'}
            </span>
          ),
          detail: item?.details || '-',
          payment_type: getPaymentStringFromType(item?.payment_type),
          status: item?.status ? <CapsuleStatus status={item?.status} /> : '',
          action: <DocumentOptionButton data={item} onClicked={(e) => handleClickedOption(e, item)} />,
        }))
      }

      setBodyTableList(viewList)
    }
  }, [bodyDataList, isSetData])

  const getPaymentStringFromType = (type) => {
    if (!type) {
      return '-'
    } else if (type == 'cash') {
      return 'Cash'
    } else if (type == 'cash_cheque') {
      return 'Cash Cheque'
    }
    return 'Bank Account'
  }

  const handleClickedOption = async (type, item) => {
    console.log('type: ' + JSON.stringify(type, null, 2))

    setOptionClickStage(type)
    setItemSelected(item)
  }

  const handleClickBtnCreateNew = () => {
    setItemSelected(null)
    setOptionClickStage('close')

    navigate('/document/accounting/create/?type=' + docType)
  }

  const handleBodyTableChecked = (isChecked, index) => {
    let tmpArray = [...bodyDataList]
    tmpArray[index].checked = isChecked
    setBodyDataList(tmpArray)
    setIsSetData(false)
    if (isChecked == false) {
      setIsSelectAll(false)
    }
  }
  const handleSearchTextChange = (e) => {
    setFilter({ ...filter, searchText: e.target.value })
  }

  const handleClickedPreview = () => {
    setIsHindModalShare(true)
    setDialogQuotationType('preview')
    setIsShowDialogQuotation(true)
  }

  const handleSelectFilter = (e) => {
    setFilter({ ...filter, lastEdit: e })
  }

  const handleClickedDocItem = (itemData) => {
    if (itemData?.id) {
      if (docType == 'warehouse') {
        navigate('/document/purchases/edit-purchase-doc/' + itemData.id)
      } else if (docType == 'goods_received_note') {
        navigate('/document/warehouse/edit/?type=goods_received_note&id=' + itemData.id)
      } else if (docType == 'payment_journal') {
        navigate('/document/purchases/edit-payment-journal/' + itemData.id)
      } else if (docType == 'petty_cash_voucher') {
        navigate('/document/purchases/edit-petty-cash-voucher/' + itemData.id)
      } else if (docType == 'payment_voucher') {
        navigate('/document/accounting/edit/?type=payment_voucher&id=' + itemData.id)
      } else if (docType == 'credit_adjustment_document') {
        navigate('/document/accounting/edit/?type=credit_adjustment_document&id=' + itemData.id)
      }
    }
  }

  const onChangePagination = async (value) => {
    const pagination = {
      page: value.page,
      pageSize: 10,
    }
    dispatch(getBusinessDocumentList(pagination, filter))
    setCurrentPage(value.page)
  }

  const handleClickClosePreview = () => {
    setIsShowPreview(false)
    setIsHindModalShare(false)
  }
  const getHeaderByDocType = () => {
    if (docType == 'payment_voucher') {
      return HEADER_COLUMN_PAYMENT_VOUCHER
    } else if (docType == 'credit_adjustment_document') {
      return HEADER_COLUMN_CREDIT_ADJUSTMENT_DOCUMENT
    }
  }

  const handleClosePreview = () => {
    setOptionClickStage('')
  }

  return (
    <Styled isHindModalShare={isHindModalShare}>
      {docType && (
        <div className="wrp-main-page">
          <div className="header">{docTitle}</div>

          <div className="filter-button">
            <div className="left">
              <div className="title">Last edit</div>
              <div className="filter">
                <DropdownSelect
                  className={'dropdown'}
                  dataList={lastEditList}
                  onChange={handleSelectFilter}
                  initValue={filter?.lastEdit}
                />
                <SearchText onChange={handleSearchTextChange} />
              </div>
            </div>
            <div className="right">
              <div className="btn-create-new" onClick={handleClickBtnCreateNew}>
                <Button>Create new</Button>
              </div>
            </div>
          </div>

          <div className="wrp-table">
            <Table
              columns={getHeaderByDocType()}
              data={bodyTableList}
              onStateChange={onChangePagination}
              pageCount={pageCount}
              page={currentPage}
              loading={isLoading}
            />
          </div>

          {optionClickStage && docType == 'payment_voucher' && (
            <PaymentVoucher
              previewType={optionClickStage}
              currentPage={currentPage}
              filter={filter}
              itemSelected={itemSelected}
              onCloseDialog={handleClosePreview}
            />
          )}
          {optionClickStage && docType == 'credit_adjustment_document' && (
            <CreditAdjustmentDocument
              previewType={optionClickStage}
              currentPage={currentPage}
              filter={filter}
              itemSelected={itemSelected}
              onCloseDialog={handleClosePreview}
            />
          )}
          {optionClickStage && docType == 'credit_note_goods_receipt' && (
            <ReceiptVoucher
              previewType={optionClickStage}
              currentPage={currentPage}
              filter={filter}
              itemSelected={itemSelected}
              onCloseDialog={handleClosePreview}
            />
          )}
        </div>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  .wrp-main-page {
    .header {
      font-size: 30px;
      font-weight: 600;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--Gray-200);
      margin-bottom: 24px;
    }
  }
  .wrp-table {
    > div {
      overflow-x: inherit;
    }
  }

  .txt-doc-item {
    cursor: pointer;
    :hover {
      color: var(--Primary-600);
    }
  }

  .filter-button {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    .left {
      .title-filter {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 6px;
      }
      .filter {
        display: flex;

        .dropdown {
          margin-right: 12px;
        }
      }
    }
    .right {
      display: flex;
      align-items: end;
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .btn-cancel {
    width: 48%;
    justify-content: center;
  }
  .btn-download {
    width: 48%;
    justify-content: center;
  }

  .document-share-and-send-email {
    display: ${({ isHindModalShare }) => (isHindModalShare ? 'none' : 'block')};
  }
`
