import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useNavigate, NavLink } from 'react-router-dom'

import styled from 'styled-components'
import Cockpit from '../../components/feature/operation-field/Cockpit'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'

const DivMain = styled.div`
  color: var(--Gray-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  .header {
    display: flex;
    align-items: center;

    .highlight {
      color: var(--Primary-700);
      font-weight: 600;
    }
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .mr-8-px {
    margin-right: 8px;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      border-bottom: 0px solid #eaecf0;
      color: #667085;
      font-size: 14px;
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      border-bottom: 2px solid var(--Primary-600) !important;
      color: #6941c6;
      background-color: #f9f5ff;
    }
  }

  .css_half {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
  }
  .zone_box {
    border: 1px solid rgb(208, 213, 221);
    border-radius: 8px;
    cursor: pointer;
    padding: 20px;
    margin-bottom: 24px;
    font-size: 16px;
    color: #344054;
    background-color: #ffffff;
    :hover {
      color: #6941c6;
      background-color: #f9f5ff;
      border: 1px solid #e9d7fe;
    }
  }
`
const TabMenuContainer = styled.div`
  display: flex;

  .tab-menu {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-500);
    padding: 0.75rem;
    :hover {
      cursor: pointer;
    }
    &.active {
      color: var(--Primary-700);
      border-bottom: 2px solid var(--Primary-700);
      background: var(--Primary-50);
    }
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`
function Dashboardofm() {
  const { t } = useTranslation()
  const [tab, setTab] = useState('cockpit')

  const toggleTab = (tab) => {
    setTab(tab)
  }

  return (
    <DivMain>
      <div className="header mb-2-rem">
        <RiHome6Line size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px">{t('operation_field')}</span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="highlight">{t('ofm_dashboard_heading')}</span>
      </div>
      <div className="content-title">{t('ofm_dashboard_heading')}</div>
      <div className="mb-2-rem">{t('ofm_dashboard_sub_heading')}</div>

      <TabMenuContainer>
        {/* <span className={`tab-menu ${tab === 'througput' ? 'active' : ''}`} onClick={() => toggleTab('througput')}>
          Throughput (P/L)
        </span> */}
        <span className={`tab-menu ${tab === 'cockpit' ? 'active' : ''}`} onClick={() => toggleTab('cockpit')}>
          {t('cockpit')}
        </span>
        {/* <span className={`tab-menu ${tab === 'insight' ? 'active' : ''}`} onClick={() => toggleTab('insight')}>
          Insight
        </span> */}
      </TabMenuContainer>
      <Divider className="mb-2-rem" />

      {tab === 'througput' ? '' : tab === 'cockpit' ? <Cockpit /> : ''}
    </DivMain>
  )
}

export default Dashboardofm
