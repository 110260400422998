import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_USERS_LIST,
  CREATE_USERS_MANAGEMENT,
  GET_USERS_MANAGEMENT_DEPARTMENT,
  DELETE_USERS_MANAGEMENT,
  USERS_MANAGEMENT_ACCESS_ROLE,
  USERS_MANAGEMENT_GET_BY_ID,
  EDIT_USERS_MANAGEMENT,
  EDIT_USERS_MANAGEMENT_AND_EMPLOYEE,
  PUT_SEND_RESET_PASSWORD_PERMISSION,
  FINE_EMPLOYEE_USER,
  SET_EMPLOYEE_USER,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getUserList =
  (filter = { limit: 10, skip: 1 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_USERS_LIST.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      const params = {
        type: 'filter',
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'filters[query]': filter.filters?.length > 0 ? JSON.stringify(filter.filters) : '[]',
      }
      if (filter.department != '') params['filters[department]'] = filter.department
      if (filter.position != '') params['filters[position]'] = filter.position
      // if (filter.role != '') params['filters[role]'] = filter.role

      const { data, meta } = await getAPI({
        url: '/api/employee-users',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_USERS_LIST.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({ type: GET_USERS_LIST.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getUserListNew =
  (filter = { limit: 100, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_USERS_LIST.REQUEST })
      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let params = {
        type: 'filter',
        skip: filter.skip,
        limit: 100,
        company_id: company_info?.id ? company_info?.id : null,
        //'filters[query]': filter.filters?.length > 0 ? JSON.stringify(filter.filters) : '[]',
        //'name': filter.filters?.length > 0 ? JSON.stringify(filter.filters) : '[]',
      }
      if (filter.department) {
        params = { ...params, department: filter.department }
      }
      if (filter.position) {
        params = { ...params, position: filter.position }
      }
      if (filter.role) {
        params = { ...params, role: filter.role }
      }
      if (filter.name) {
        params = { ...params, name: filter.name }
        //params = {...params,'name': filter.filters.map((v) => v.name).join(',')}
      }
      // if (filter.department != '') params['filters[department]'] = filter.department
      // if (filter.position != '') params['filters[position]'] = filter.position
      // if (filter.role != '') params['filters[role]'] = filter.role

      const { data, meta } = await getAPI({
        url: '/api/mapping-employee-user-list-user',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_USERS_LIST.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({ type: GET_USERS_LIST.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const createUserManagement = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_USERS_MANAGEMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/mapping-employee-user`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_USERS_MANAGEMENT.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Users Management Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_USERS_MANAGEMENT.FAILURE, error: err })
  } finally {
    const filter = {
      // skip: 1,
      skip: 0,
      limit: 100,
    }
    // dispatch(getUserList(filter))
    dispatch(getUserListNew(filter))
    dispatch(hideLoading())
  }
}

export const getUserManagementDepartment = () => async (dispatch) => {
  // DropDow
  try {
    dispatch(showLoading())
    dispatch({ type: GET_USERS_MANAGEMENT_DEPARTMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/departments?populate=*',
      headers: headers,
    })

    return dispatch({
      type: GET_USERS_MANAGEMENT_DEPARTMENT.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: GET_USERS_MANAGEMENT_DEPARTMENT.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteUsersManagement = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_USERS_MANAGEMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/employee-users/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: DELETE_USERS_MANAGEMENT.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Users Management Successful',
    })
  } catch (err) {
    return dispatch({ type: DELETE_USERS_MANAGEMENT.FAILURE, error: err })
  } finally {
    // dispatch(getUserList())
    dispatch(getUserListNew())
    dispatch(hideLoading())
  }
}

export const accessRole = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: USERS_MANAGEMENT_ACCESS_ROLE.REQUEST })
    /*   //---- ของเก่า ByJame 12/09/2023
    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/admin/roles',
      headers: headers,
    })
    */
    const headers = {
      Authorization: null,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: '/api/user-permission-role-list-role',
      headers: headers,
    })
    return dispatch({
      type: USERS_MANAGEMENT_ACCESS_ROLE.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: USERS_MANAGEMENT_ACCESS_ROLE.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const getUserManagementByid = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: USERS_MANAGEMENT_GET_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/mapping-employee-user-id-user/${id}`,
      headers: headers,
    })

    return dispatch({
      type: USERS_MANAGEMENT_GET_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: USERS_MANAGEMENT_GET_BY_ID.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const editUserManagement = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_USERS_MANAGEMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    /*
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/employee-users/${id}`,
      data,
      headers: headers,
    })
    */
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/mapping-employee-user-edit-user/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: EDIT_USERS_MANAGEMENT.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Users Management Successful',
    })
  } catch (err) {
    return dispatch({ type: EDIT_USERS_MANAGEMENT.FAILURE, error: err })
  } finally {
    const filter = {
      // skip: 1,
      skip: 0,
      limit: 100,
    }
    // dispatch(getUserList(filter))
    dispatch(getUserListNew(filter))
    dispatch(hideLoading())
  }
}

export const editUserManagementAndEmployee = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_USERS_MANAGEMENT_AND_EMPLOYEE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/update-user-all`,
      data,
      headers: headers,
    })

    return dispatch({
      type: EDIT_USERS_MANAGEMENT_AND_EMPLOYEE.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Users Management Successful',
    })
  } catch (err) {
    return dispatch({ type: EDIT_USERS_MANAGEMENT_AND_EMPLOYEE.FAILURE, error: err })
  } finally {
    const filter = {
      // skip: 1,
      skip: 0,
      limit: 100,
    }
    // dispatch(getUserList(filter))
    dispatch(getUserListNew(filter))
    dispatch(hideLoading())
  }
}

export const resetPasswordPermission = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: PUT_SEND_RESET_PASSWORD_PERMISSION.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: token,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/user-permission-reset-password/${id}`,
      headers: headers,
    })

    return dispatch({
      type: PUT_SEND_RESET_PASSWORD_PERMISSION.SUCCESS,
      showAlert: true,
      alertMessage: 'Send Permission Reset Password Successful',
    })
  } catch (err) {
    return dispatch({ type: PUT_SEND_RESET_PASSWORD_PERMISSION.FAILURE, error: err })
  } finally {
    const filter = {
      // skip: 1,
      skip: 0,
      limit: 100,
    }
    // dispatch(getUserList(filter))
    dispatch(getUserListNew(filter))
    dispatch(hideLoading())
  }
}

export const sendRequestResetPassword = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: PUT_SEND_RESET_PASSWORD_PERMISSION.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/mapping-employee-user-request-resetpassword`,
      data,
      headers: headers,
    })

    return dispatch({
      type: PUT_SEND_RESET_PASSWORD_PERMISSION.SUCCESS,
      showAlert: true,
      alertMessage: 'Send Permission Reset Password Successful',
    })
  } catch (err) {
    return dispatch({ type: PUT_SEND_RESET_PASSWORD_PERMISSION.FAILURE, error: err })
  } finally {
    const filter = {
      // skip: 1,
      skip: 0,
      limit: 100,
    }
    // dispatch(getUserList(filter))
    dispatch(getUserListNew(filter))
    dispatch(hideLoading())
  }
}

export const fineEmployeeUser = (employeeId) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: FINE_EMPLOYEE_USER.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const params = {
      'pagination[page]': 1,
      'pagination[pageSize]': 1,
      'filters[employ_id]': employeeId,
    }
    const { data } = await getAPI({
      url: '/api/employees',
      headers: headers,
      params,
    })

    return dispatch({
      type: FINE_EMPLOYEE_USER.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({ type: FINE_EMPLOYEE_USER.FAILURE, error })
  } finally {
    dispatch(hideLoading())
  }
}

export const setEmployeeUser = (data) => async (dispatch) => {
  dispatch({ type: SET_EMPLOYEE_USER, data })
}

export const createAccountUser = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_USERS_MANAGEMENT.REQUEST })
    const { token } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/mapping-employee-user`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_USERS_MANAGEMENT.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Users Management Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_USERS_MANAGEMENT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const createAccountUserWithPassword = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_USERS_MANAGEMENT.REQUEST })
    const { token } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/mapping-employee-user-active-user`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_USERS_MANAGEMENT.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Users Management Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_USERS_MANAGEMENT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}
