import { combineReducers } from 'redux'
import {
  GET_KPI_LIST,
  GET_KPI_DRAFT_LIST,
  GET_KPI_ARCHIVE_LIST,
  GET_KPI_BY_ID,
  CREATE_KPI,
  EDI_KPI,
  PUBLISH_KPI,
  DELETE_KPI,
  CLEAR_EDIT_KPI_FORM,
  GET_KPI_HISTORY,
  GET_KPI_HISTORY_DETAIL,
} from '../actionTypes'

const initialState = {
  kpiInfo: {},
  kpiInfoDraft: {},
  kpiArchives: [],
  kpiEditForm: {},
  kpiHistoryLogs: [],
  kpiHistoryDetail: {},
  total: 1,
  page: 1,
  pageCount: 1,
  limit: 10,
  isLoading: false,
  state: '',
}

const kpiManagementReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_KPI_LIST.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_KPI_LIST.REQUEST',
      }
    }
    case GET_KPI_LIST.SUCCESS: {
      const { data } = action
      return {
        ...state,
        kpiInfo: data,
        isLoading: false,
        state: 'GET_KPI_LIST.SUCCESS',
      }
    }
    case GET_KPI_LIST.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_KPI_LIST.FAILURE',
      }
    }
    case GET_KPI_DRAFT_LIST.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_KPI_DRAFT_LIST.REQUEST',
      }
    }
    case GET_KPI_DRAFT_LIST.SUCCESS: {
      const { data } = action
      return {
        ...state,
        kpiInfoDraft: data,
        isLoading: false,
        state: 'GET_KPI_DRAFT_LIST.SUCCESS',
      }
    }
    case GET_KPI_DRAFT_LIST.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_KPI_DRAFT_LIST.FAILURE',
      }
    }
    case GET_KPI_ARCHIVE_LIST.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_KPI_ARCHIVE_LIST.REQUEST',
      }
    }
    case GET_KPI_ARCHIVE_LIST.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        kpiArchives: data,
        page: meta?.pagination?.page,
        limit: meta?.pagination?.pageSize,
        pageCount: meta?.pagination?.pageCount,
        total: meta?.pagination?.total,
        isLoading: false,
        state: 'GET_KPI_ARCHIVE_LIST.SUCCESS',
      }
    }
    case GET_KPI_ARCHIVE_LIST.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_KPI_ARCHIVE_LIST.FAILURE',
      }
    }
    case GET_KPI_BY_ID.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_KPI_BY_ID.REQUEST',
      }
    }
    case GET_KPI_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        kpiEditForm: data,
        isLoading: false,
        state: 'GET_KPI_BY_ID.SUCCESS',
      }
    }
    case GET_KPI_BY_ID.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_KPI_BY_ID.FAILURE',
      }
    }
    case CREATE_KPI.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_KPI.REQUEST',
      }
    }
    case CREATE_KPI.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_KPI.SUCCESS',
      }
    }
    case CREATE_KPI.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_KPI.FAILURE',
      }
    }
    case EDI_KPI.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'EDI_KPI.REQUEST',
      }
    }
    case EDI_KPI.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'EDI_KPI.SUCCESS',
      }
    }
    case EDI_KPI.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'EDI_KPI.FAILURE',
      }
    }
    case PUBLISH_KPI.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'PUBLISH_KPI.REQUEST',
      }
    }
    case PUBLISH_KPI.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'PUBLISH_KPI.SUCCESS',
      }
    }
    case PUBLISH_KPI.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'PUBLISH_KPI.FAILURE',
      }
    }
    case DELETE_KPI.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_KPI.REQUEST',
      }
    }
    case DELETE_KPI.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_KPI.SUCCESS',
      }
    }
    case DELETE_KPI.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_KPI.FAILURE',
      }
    }
    case GET_KPI_HISTORY.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_KPI_HISTORY.REQUEST',
      }
    }
    case GET_KPI_HISTORY.SUCCESS: {
      const { data } = action
      return {
        ...state,
        kpiHistoryLogs: data,
        isLoading: false,
        state: 'GET_KPI_HISTORY.SUCCESS',
      }
    }
    case GET_KPI_HISTORY.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_KPI_HISTORY.FAILURE',
      }
    }
    case GET_KPI_HISTORY_DETAIL.REQUEST: {
      return {
        ...state,
        isLoading: true,
        state: 'GET_KPI_HISTORY_DETAIL.REQUEST',
      }
    }
    case GET_KPI_HISTORY_DETAIL.SUCCESS: {
      const { data } = action
      return {
        ...state,
        kpiHistoryDetail: data,
        isLoading: false,
        state: 'GET_KPI_HISTORY_DETAIL.SUCCESS',
      }
    }
    case GET_KPI_HISTORY_DETAIL.FAILURE: {
      return {
        ...state,
        isLoading: false,
        state: 'GET_KPI_HISTORY_DETAIL.FAILURE',
      }
    }
    case CLEAR_EDIT_KPI_FORM: {
      const { data } = action
      return {
        ...state,
        kpiEditForm: data,
      }
    }
    default:
      return state
  }
}

export default combineReducers({
  kpiManagementReducer,
})
