import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import { Tree, TreeNode } from 'react-organizational-chart'

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;

  .no-children > .css-to7de5 {
    display: none;
  }

  .Tree > .TreeNode.no-children::before,
  .Tree > .TreeNode.no-children > .TreeNodeLabel::before {
    content: none;
  }
`

const StyledNode = styled.div`
  display: inline-block;
  background: var(--Gray-50);
  padding: 24px;
  cursor: pointer;
  width: 100%;
  min-width: 400px;
  max-width: 403px;
  width: 100%;
  box-sizing: border-box;

  .node-content {
    display: flex;
    flex-wrap: wrap; // allow wrapping for smaller screens
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .img-profile {
    width: 60px; // reduced from 100px
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center; // centered for better mobile view

    .name {
      color: var(--Gray-900);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
    }
    .department {
      color: var(--Primary-700);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 8px;
    }
    .position {
      color: var(--Gray-600);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }
  }

  @media (max-width: 768px) {
    .img-profile {
      width: 50px;
      height: 50px;
    }
    .node-content {
      flex-direction: column; // stack on top of each other on mobile
    }
  }
`


const OrgChartEmployee = ({ chartData }) => {
    const [data, setData] = useState(chartData)

    useEffect(() => {
      // 
    }, [data])
  
    const renderTreeNodes = (nodeData) => (
      <TreeNode
        className={!nodeData?.isExpanded || nodeData?.underPosition?.length === 0 ? 'no-children' : ''}
        label={
          <StyledNode onClick={() => toggleNode(nodeData?.head?.name)}>
            <div className="node-content">
              <img className="img-profile" src={nodeData?.head?.img} />
              <div className="info">
                <div className="name">{nodeData?.head?.name}</div>
                <div className="department">{nodeData?.head?.department}</div>
                <div className="position">{nodeData?.head?.position}</div>
              </div>
            </div>
          </StyledNode>
        }
      >
        {nodeData?.isExpanded &&
          nodeData?.underPosition.length > 0 &&
          nodeData?.underPosition?.map((child) => renderTreeNodes(child))}
      </TreeNode>
    )
  
    const OrganizationalChart = (data) => (
      <Tree
        lineWidth={'2px'}
        lineColor={'#98A2B3'}
        lineBorderRadius={'0'}
        label={
          <StyledNode
            className={!data?.isExpanded || data?.underPosition?.length === 0 ? 'no-children' : ''}
            onClick={() => toggleNode(data?.head?.name)}
          >
            <div className="node-content">
              <img className="img-profile" src={data?.head?.img} />
              <div className="info">
                <div className="name">{data?.head?.name}</div>
                <div className="department">{data?.head?.department}</div>
                <div className="position">{data?.head?.position}</div>
              </div>
            </div>
          </StyledNode>
        }
      >
        {data?.isExpanded &&
          data?.underPosition.length > 0 &&
          data?.underPosition?.map((child) => renderTreeNodes(child))}
      </Tree>
    )
  
    const toggleNode = (nodeName) => {
      const toggleRecursively = (node) => {
        if (node.head.name === nodeName) {
          return {
            ...node,
            isExpanded: !node?.isExpanded,
          }
        } else {
          return {
            ...node,
            underPosition: node?.underPosition?.map((child) => toggleRecursively(child)),
          }
        }
      }
  
      if (data.head.name === nodeName) {
        setData((prevData) => ({
          ...prevData,
          isExpanded: !prevData?.isExpanded,
        }))
      } else {
        setData((prevData) => toggleRecursively({ ...prevData }))
      }
    }
  
    return <Wrapper>{OrganizationalChart(data)}</Wrapper>
}

export default OrgChartEmployee