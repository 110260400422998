/* eslint-disable react/display-name */
import * as React from 'react'
import { styled } from '@mui/system'
import dayjs from 'dayjs'

import Box from '@mui/material/Box'
import { unstable_useForkRef as useForkRef } from '@mui/utils'
import { unstable_useDateField as useDateField } from '@mui/x-date-pickers/DateField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { useClearableField } from '@mui/x-date-pickers/hooks'

const BrowserField = React.forwardRef((props, ref) => {
  const {
    disabled,
    id,
    label,
    inputRef,
    InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
    // extracting `error`, 'focused', and `ownerState` as `input` does not support those props
    error,
    focused,
    ownerState,
    sx,
    ...other
  } = props

  const handleRef = useForkRef(containerRef, ref)

  return (
    <Box sx={{ ...(sx || {}), display: 'flex', alignItems: 'center' }} id={id} ref={handleRef}>
      {startAdornment}
      <InputElement disabled={disabled} ref={inputRef} {...other} />
      {endAdornment}
    </Box>
  )
})

const BrowserDateField = React.forwardRef((props, ref) => {
  const { inputRef: externalInputRef, slots, slotProps, ...textFieldProps } = props

  const {
    onClear,
    clearable,
    ref: inputRef,
    ...fieldProps
  } = useDateField({
    props: textFieldProps,
    inputRef: externalInputRef,
  })

  /* If you don't need a clear button, you can skip the use of this hook */
  const { InputProps: ProcessedInputProps, fieldProps: processedFieldProps } = useClearableField({
    onClear,
    clearable,
    fieldProps,
    InputProps: fieldProps.InputProps,
    slots,
    slotProps,
  })

  return <BrowserField ref={ref} inputRef={inputRef} {...processedFieldProps} InputProps={ProcessedInputProps} />
})

const BrowserDatePicker = React.forwardRef((props, ref) => {
  const langLocal = localStorage.getItem('lang') || 'en'
  return (
    <DatePicker
      {...props}
      ref={ref}
      format={props.format || 'DD MMM YYYY'}
      slots={{ field: BrowserDateField, ...props.slots }}
    />
  )
})

export default BrowserDatePicker

const InputElement = styled('input')`
  width: 100%;
  font-family: 'Inter', 'Noto Sans Thai';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding: 8px 12px;
  border-radius: 8px;
  color: var(--Gray-900);
  background: var(--Base-White);
  border: 1px solid var(--Gray-200);
  box-shadow: 0px 2px 2px var(--Gray-50);

  &:disabled {
    background: var(--Gray-50);
    color: var(--Gray-400);
  }

  &:hover:not(:disabled) {
    border-color: var(--Primary-300);
  }

  &:focus {
    border-color: var(--Primary-300);
    box-shadow: 0 0 0 3px var(--Primary-100) !important;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }

  // state error input
  &.error {
    border-color: red;
  }
`
