import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { dlog } from '../../../utils/helper'
import Table from '../../common/Table'
import { getRequestApprove } from '../../../redux/actions/summaryTask'
import { selectSummaryTask } from '../../../redux/selectors'
import moment from 'moment'
import Button from '../../form/button/Button'

import { useTranslation } from 'react-i18next'
import DialogFail from '../../dialog/DialogFail'
import failIcon from '../../../assets/images/fail-icon.png'
export default function TableApproval({ filters, isUpdate, onClickViewTask, onClickViewDocument }) {
  const { t } = useTranslation()
  const [isHeaderBuilded, setIsHeaderBuilded] = useState(false)
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [taskColumn, setTaskColumn] = useState([])
  const [dataViews, setDataViews] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const { approveList, isLoading, total, skip, limit, pageSize, page, pageCount } = useSelector(selectSummaryTask)
  const dispatch = useDispatch()
  const [failModal, setFailModal] = useState(false)
  
  useEffect(() => {
    getHeaderTableList()
  }, [])

  const fetchData = useCallback(
    async (filters, pagination) => {
      dispatch(getRequestApprove(filters, pagination))
    },
    [dispatch]
  )

  useEffect(() => {
    if(filters.owner!=-99){
      setFailModal(false)
      setCurrentPage(1)
      fetchData(filters, {
        page: 1,
        pageSize: pageSize,
        pageCount: pageCount,
        total: total,
      })
    }else{
      setFailModal(true)
    }
  }, [filters,isUpdate])

  useEffect(() => {
    const viewList = approveList.map((item) => ({
      ...item,
      project: item.project_refer,
      department: item.department,
      titles: item.title,
      type: getDocTypeString(item.doc_type,item.type),
      request_by: item.request_by,
      action: (
        <div>
          {!item.doc_type && <Button onClick={() => handleClickViewTask(item)} className="mr-1-rem">
            {t('view_task')}
          </Button>}
          {item.type == 'Document Approval' && (
            <Button onClick={() => handleClickViewDocument(item)} className="mr-1-rem">
              {t('view_document')}
            </Button>
          )}
        </div>
      ),
    }))
    setDataViews(viewList)
    
  }, [approveList])

  const getDocTypeString = (doc_type,type) => {
    let tmp = '';
    if(doc_type){
      switch (doc_type){
        case "quotation" : tmp="ใบเสนอราคา";break;
        case "billing" : tmp="ใบวางบิล";break;
        case "invoice" : tmp="ใบเสร็จรับเงิน";break;
        case "receipt" : tmp="ใบแจ้งหนี้";break;
        case "purchase_order" : tmp="ใบสั่งซื้อ";break;
        case "cheque_payment_journal" : tmp="สมุดรายวันจ่ายเช็ค";break;
        case "payment_journal" : tmp="สมุดรายวันจ่าย";break;
        case "petty_cash_voucher" : tmp="ใบสำคัญจ่าย-เงินสดย่อย";break;
      }
    }else{
      tmp = type
    }
    return tmp
  }
  
  const onChangePagination = async (value) => {
    if(filters.owner!=-99){
      setFailModal(false)
      const pagination = {
        page: value.page,
        pageSize: pageSize,
        pageCount: pageCount,
        total: total,
      }
      fetchData(filters,pagination)
      setCurrentPage(value.page)
    }else{
      setFailModal(true)
    }
  }

  const handleClickViewTask = (itemData) => {
    if (onClickViewTask) {
      onClickViewTask(itemData)
    }
  }

  const handleClickViewDocument = (itemData) => {
    console.log('itemData: '+JSON.stringify(itemData,null,2))
    if (onClickViewDocument) {
      onClickViewDocument(itemData)
    }
  }

  const getHeaderTableList = async () => {
    let tmpArr = [
      {
        Header: t('project'),
        accessor: 'project',
        disableSortBy: false,
      },
      {
        Header: t('department'),
        accessor: 'department',
        disableSortBy: false,
      },
      {
        Header: t('title'),
        accessor: 'titles',
        disableSortBy: false,
      },
      {
        Header: t('type'),
        accessor: 'type',
        disableSortBy: false,
      },
      {
        Header: t('requested_by'),
        accessor: 'request_by',
        disableSortBy: false,
      },
      {
        Header: t('action'),
        accessor: 'action',
        disableSortBy: false,
      },
    ]
    setTaskColumn([...tmpArr])
    setIsHeaderBuilded(true)
  }
  const onChangeSelectAllCheckBox = (e) => {
    // TODO: add checked or de checked
    setIsSelectAll(e)
  }

  const displayDateFormat = (dateTime) => {
    const formattedDateTime = moment(dateTime).format('DD MMM YYYY')
    return formattedDateTime ? formattedDateTime : '-'
  }

  return (
    <Styled>
      {isHeaderBuilded && (
        <Table
          columns={taskColumn}
          data={dataViews}
          onStateChange={onChangePagination}
          pageCount={pageCount}
          page={currentPage}
          loading={isLoading}
        />
        
      )}
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={"You may not fully use this feature, since you are not employee."}
        nameItem={''}
        description={''}
        textYes="Ok"
      />
    </Styled>
  )
}
const Styled = styled.div`
table {
  
}
`
