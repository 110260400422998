import { useCallback, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DateDisplay,GetPermissionOther } from '../../utils/common'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import styled from 'styled-components'
import SearchText from '../../components/form/SearchText'
import Button from '../../components/form/button/Button'
import NewEmployeeModal from '../../pages/employee/NewEmployeeModal'
import ViewEmployeeModal from '../../pages/employee/ViewEmployeeModal'
import EditEmployeeModal from '../../pages/employee/EditEmployeeModal'
import DialogEmployeeStatus from '../../pages/employee/DialogEmployeeStatus'
import Dropdown from '../../components/form/Dropdown'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import Table from '../../components/common/Table'
import SidePanel from '../../components/common/SidePanel'
import CollapseMenu from '../../components/common/CollapseMenu'

import { selectEmployee, selectMaster } from '../../redux/selectors'
import { getEmployee } from '../../redux/actions/employee'
import {
  getCountries,
  getBanks,
  getNationalities,
  getDepartmentsAndPositionsUseAuthen,
  getProvinces,
} from '../../redux/actions/master'
import { accessRole } from '../../redux/actions/userlist'

import iconPlus from '../../assets/images/icon-plus.svg'
import successIcon from '../../assets/images/success-icon.png'
import { LiaTimesSolid } from 'react-icons/lia'
import { GrStatusGoodSmall } from 'react-icons/gr'

import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

const EmployeeDIV = styled.div`
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .content-fillter-bg {
    min-height: 68px;
    background-color: var(--Gray-50);
    border-radius: 8px;
    margin-bottom: 21px;
    padding: 10px;
  }
  .content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .search-wrapper {
      /*margin-right: 12px;*/
    }

    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }

    .group_filter {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }
  }
  .button_clear_all {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #eaecf0;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .container {
    position: relative;
    display: inline-block;
  }
  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 10px;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #eaecf0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #ffffff;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .cursor_pointer {
    cursor: pointer;
  }
  .text-bold {
    font-weight: bold;
  }

  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .dd_css {
    min-width: 200px;
  }
  .badge {
    padding: 2px 8px;
    align-items: center;
    color: var(--Pink-700);
    background: var(--Pink-50);
    border: 1px solid var(--Pink-200);
    border-radius: 16px;
  }
  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border: 1.5px solid var(--Gray-300);
    border-radius: 6px;
    font-weight: 500;

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #17b26a;

      &.inactive {
        color: red;
      }
    }
  }
`
const Employee = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const initLang = localStorage.getItem('lang')
  //--- ST ส่วนของ Permission LV3-4
  const permissionNewEmployee = GetPermissionOther('employee', 'add_new_employee');
  const permissionViewEmployee = GetPermissionOther('employee', 'view_employee');
  const permissionEditEmployee = GetPermissionOther('employee', 'edit_employee');
  //--- ED ส่วนของ Permission LV3-4

  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  useEffect(() => {
    dispatch(getCountries())
    dispatch(getBanks())
    dispatch(getNationalities())
    dispatch(getDepartmentsAndPositionsUseAuthen())
    dispatch(getProvinces())
    dispatch(accessRole())
  }, [dispatch])

  const [search, setSearch] = useState('')

  //--- ST CreateEmployee
  const [ModalEmployee, setModalEmployee] = useState(false)
  const OpenNewEmployeeModal = () => {
    if (permissionNewEmployee?.canUpdate === true) {
      setModalEmployee(true)
    } else {
      let message_permission = 'No permission allowed ' + permissionNewEmployee?.name_en
      if (initLang == 'th') {
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionNewEmployee?.name_th
      }
      setTitleFail(message_permission)
      setnameItemFail('')
      setDescriptionFail('')
      setFailModal(true)
    }
  }
  const CloseNewEmployeeModal = () => {
    setModalEmployee(false)
  }
  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const confirmsuccessModal = () => {
    setsuccessModal(false)
  }
  const SubmitCreateEmployee = (data) => {
    setModalEmployee(false)
    /*
    if(data == 1){
      setTitlesuccess('Create user successful');
      setDescriptionsuccess('');
    }else{
      setTitlesuccess('Create user successful');
      setSubTitlesuccess('Invitation to set password has been sent to user');
      setDescriptionsuccess('Please check email');
    }
    setsuccessModal(true)
    */
  }
  //--- ED CreateEmployee
  //--- ST DataEmployee
  // กำหนดตัวแปร
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [employeeData, setEmployeeData] = useState([])

  const EmployeeColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('employee_id'),
      accessor: 'employ_id',
      disableSortBy: false,
    },
    {
      Header: 'Company Employee ID',
      accessor: 'company_employee_id',
      disableSortBy: false,
    },
    {
      Header: t('full_name'),
      accessor: 'full_name',
      disableSortBy: false,
    },
    {
      Header: t('mobile'),
      accessor: 'phone_no',
      disableSortBy: false,
    },
    {
      Header: t('email_address'),
      accessor: 'email_address',
      disableSortBy: false,
    },
    {
      Header: t('department'),
      accessor: 'department',
      disableSortBy: false,
    },
    {
      Header: t('job_position'),
      accessor: 'job_position',
      disableSortBy: false,
    },
    {
      Header: 'Employee Type',
      accessor: 'employee_type',
      disableSortBy: false,
    },
    {
      Header: 'Evaluation Date',
      accessor: 'display_evaluation_date',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('action'),
      accessor: 'action',
      disableSortBy: false,
    },
  ]

  // ตัวแปรจาก STORE และตัวที่เก็บข้อมูลจากการเรียก API
  const { employeeList, limit, total, skip, isLoading } = useSelector(selectEmployee)

  // เรียกใช้งาน API ผ่าน dispatch เช่น dispatch(getEmployee(filter))
  const fetchData = useCallback(
    async (filter) => {
      dispatch(getEmployee(filter))
    },
    [dispatch]
  )

  const [searchFilters, setSearchFilters] = useState([])
  const onSearch = () => {
    const newFilter = {
      key: uuidv4(),
      name: search,
    }
    const filter = {
      skip: 1,
      limit: limit,
      other_search: [...searchFilters, newFilter].map((v) => v.name).join(','),
      department: departmentSelected,
      position: positionSelected,
      employee_type: employeetypeSelected,
    }
    fetchData(filter)
    setPage(1)
    setSearchFilters((prev) => [...prev, newFilter])
  }

  const onSearchdepartmentSelected = (value) => {
    setDepartmentSelected(value)
    const filter = {
      skip: 1,
      limit: limit,
      other_search: searchFilters.map((v) => v.name).join(','),
      department: value,
      position: positionSelected,
      employee_type: employeetypeSelected,
    }

    fetchData(filter)
    setPage(1)
  }

  const onSearchpositionSelected = (value) => {
    setPositionSelected(value)
    const filter = {
      skip: 1,
      limit: limit,
      other_search: searchFilters.map((v) => v.name).join(','),
      department: departmentSelected,
      position: value,
      employee_type: employeetypeSelected,
    }

    fetchData(filter)
    setPage(1)
  }

  const onSearchemployeetypeSelected = (value) => {
    setemployeetypeSelected(value)
    const filter = {
      skip: 1,
      limit: limit,
      other_search: searchFilters.map((v) => v.name).join(','),
      department: departmentSelected,
      position: positionSelected,
      employee_type: value,
    }

    fetchData(filter)
    setPage(1)
  }

  const onClearAllSearch = () => {
    setSearchFilters([])
    setDepartmentSelected('')
    setPositionSelected('')
    setemployeetypeSelected('')

    const filter = {
      skip: 1,
      limit: limit,
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
  }

  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)

    const filter = {
      skip: 1,
      limit: limit,
      other_search: newSearchFilters.map((v) => v.name).join(','),
      department: departmentSelected,
      position: positionSelected,
      employee_type: employeetypeSelected,
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
  }

  useEffect(() => {
    const filter = {
      //skip: 1 * limit - limit,
      skip: 1,
      limit: limit,
    }
    fetchData(filter)
  }, [fetchData, limit])

  // bind ข้อมูลที่ได้จาก API โดยรับมาจาก ตัวแปรของ Store ที่ประกาศไว้
  useEffect(() => {
    const newEmployee = employeeList.map((v, index) => {
      const list =
        permissionEditEmployee?.canRead === true
          ? [
              {
                text: 'Edit',
                action: () => SubmitEditEmployee(v.id),
              },
              {
                text: 'Change Status',
                action: () => ChangeStatus(v.id, v.status),
              },
            ]
          : [
              {
                text: 'Change Status',
                action: () => ChangeStatus(v.id, v.status),
              },
            ]
      const deptColor = v.dept_color ? JSON.parse(v.dept_color) : null
      return {
        company_employee_id: v.company_employee_id,
        no: index + 1,
        employ_id: <div className="text-bold">{v.employ_id}</div>,
        full_name: (
          <div className="text-bold link" onClick={() => ViewEmployeeItem(v.id)}>
            {v.first_name + ' ' + v.last_name}
          </div>
        ),
        phone_no: <div>{v.phone_no ? v.phone_no : '-'}</div>,
        email_address: <div>{v.email ? v.email : '-'}</div>,
        department:
          v.department != '' ? (
            <span
              className="badge"
              style={{
                color: deptColor?.text,
                borderColor: deptColor?.border,
                backgroundColor: deptColor?.background,
              }}
            >
              {v.department}
            </span>
          ) : (
            <span>-</span>
          ),
        job_position: <div>{v.position ? v.position : '-'}</div>,
        employee_type: <div>{v.employee_type ? v.employee_type : '-'}</div>,
        display_evaluation_date: <div>{v.evaluation_date ? DateDisplay(v.evaluation_date) : '-'}</div>,
        status: (
          <div className="status-badge">
            <GrStatusGoodSmall className={`status ${v.status == 'inactive' ? 'inactive' : ''}`} />
            {v.status}
          </div>
        ),
        action: <CollapseMenu list={list} />,
      }
    })

    setEmployeeData(newEmployee)
  }, [employeeList])

  useEffect(() => {
    const totalPage = Math.ceil(total / limit)
    setTotalPages(totalPage)
  }, [total, skip, limit])

  const onClearSearch = () => {
    const filter = {
      skip: 1,
      limit: limit,
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
  }

  // Function การใช้งานอื่นๆ
  const onChangePagination = async (value) => {
    const filter = {
      //skip: value.page * limit - limit,
      skip: value.page,
      limit: limit,
      other_search: searchFilters.map((v) => v.name).join(','),
      department: departmentSelected,
      position: positionSelected,
      employee_type: employeetypeSelected,
      // category: categorySelected == '' ? null : categorySelected,
      // type: typeSelected == '' ? null : typeSelected,
      // supplier: supplierSelected == '' ? null : supplierSelected,
      // status: statusSelected == '' ? null : statusSelected,
    }
    fetchData(filter)
    setPage(value.page)
  }
  const [ModalViewEmployee, setModalViewEmployee] = useState(false)
  const [employeeId, setEmployeeId] = useState('')
  const ViewEmployeeItem = (id) => {
    if (permissionViewEmployee?.canUpdate === true) {
      setEmployeeId(id)
      setModalViewEmployee(true)
    } else {
      let message_permission = 'No permission allowed ' + permissionViewEmployee?.name_en
      if (initLang == 'th') {
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionViewEmployee?.name_th
      }
      setTitleFail(message_permission)
      setnameItemFail('')
      setDescriptionFail('')
      setFailModal(true)
    }
  }
  const [employeeStatus, setemployeeStatus] = useState('')
  const ChangeStatus = (id, data_status) => {
    //

    setEmployeeId(id)
    setemployeeStatus(data_status)
    setModalChangeStatusEmployee(true)
  }

  const CloseViewEmployeeModal = () => {
    setModalViewEmployee(false)
  }

  const SubmitEditEmployee = (data) => {
    if (permissionEditEmployee?.canUpdate === true) {
      setEmployeeId(data)

      setModalViewEmployee(false)
      setModalEditEmployee(true)
    } else {
      let message_permission = 'No permission allowed ' + permissionEditEmployee?.name_en
      if (initLang == 'th') {
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionEditEmployee?.name_th
      }
      setTitleFail(message_permission)
      setnameItemFail('')
      setDescriptionFail('')
      setFailModal(true)
    }
  }

  const [ModalEditEmployee, setModalEditEmployee] = useState(false)
  const CloseEditEmployeeModal = () => {
    setModalEditEmployee(false)
  }

  const SubmitEditEmployeeProcess = () => {
    setModalEditEmployee(false)
  }

  const [ModalChangeStatusEmployee, setModalChangeStatusEmployee] = useState(false)
  const CloseChangeStatusEmployeeModal = () => {
    setModalChangeStatusEmployee(false)
  }

  const SubmitChangeStatusEmployeeProcess = () => {
    setModalChangeStatusEmployee(false)
  }

  //--- ED DataEmployee
  const [departmentSelected, setDepartmentSelected] = useState('')
  const [positionSelected, setPositionSelected] = useState('')
  const [departments, setDepartments] = useState([])
  const [positions, setPositions] = useState([])
  const [employeetypeSelected, setemployeetypeSelected] = useState('')
  const employee_type_option = [
    { text: 'Permanent', value: 'Permanent' },
    { text: 'Part-Time', value: 'Part-Time' },
    { text: 'Probation', value: 'Probation' },
  ]

  const { departments: departmentList } = useSelector(selectMaster)

  useEffect(() => {
    const newDepartmentList = departmentList?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setDepartments(newDepartmentList)
  }, [departmentList])

  useEffect(() => {
    const department = departmentList?.find((v) => v.id == departmentSelected)
    const newPositions = department?.positions?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setPositions(newPositions)
  }, [departmentSelected, departmentList])

  return (
    <EmployeeDIV>
      <div className="content-title">{t('employee')}</div>
      <div className="">{t('employee_sub_header')}</div>
      <div className="border_header"></div>
      <div className="content-fillter-bg">
        <div className="content-fillter">
          <div className="group_filter">
            <div>
              <SearchText
                className="search-wrapper"
                placeholder={t('search')}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onSubmit={onSearch}
                onClear={onClearSearch}
              />
            </div>
            <div>
              <Dropdown
                placeHolder={t('department')}
                className="dd_css"
                optionList={departments}
                value={departmentSelected}
                onChange={(value) => onSearchdepartmentSelected(value)}
              />
            </div>
            <div>
              <Dropdown
                placeHolder={t('positions')}
                className="dd_css"
                optionList={positions}
                value={positionSelected}
                onChange={(value) => onSearchpositionSelected(value)}
              />
            </div>
            <div>
              <Dropdown
                placeHolder={'Employee Type'}
                className="dd_css"
                optionList={employee_type_option}
                value={employeetypeSelected}
                onChange={(value) => onSearchemployeetypeSelected(value)}
              />
            </div>
            <div>
              <Button onClick={onClearAllSearch} className="button_clear_all mr-1-rem">
                {t('clear_all')}
              </Button>
            </div>
          </div>

          <div className="container">
            {permissionNewEmployee?.canRead === true && (
              <Button append={<img src={iconPlus} />} onClick={OpenNewEmployeeModal}>
                {t('new_employee')}
              </Button>
            )}
          </div>
        </div>
        <div className="badge-search-content">
          {searchFilters.map((v) => (
            <div className="badge" key={v.key}>
              <span className="name">{v.name}</span>{' '}
              <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
            </div>
          ))}
        </div>
      </div>
      <div className="table-wrapper">
        <div className="table-header">
          <div className="group">
            <div className="table-title">{t('total_employee')}</div>
            <div className="table-total">
              {total} {t('items')}
            </div>
          </div>
        </div>
        <Table
          columns={EmployeeColumn}
          data={employeeData}
          onStateChange={onChangePagination}
          pageCount={totalPages}
          page={page}
          loading={false}
        />
      </div>

      <SidePanel isOpen={ModalEmployee} setIsOpen={setModalEmployee} width={940}>
        <NewEmployeeModal open={ModalEmployee} onClose={CloseNewEmployeeModal} onSubmit={SubmitCreateEmployee} />
      </SidePanel>

      <SidePanel isOpen={ModalViewEmployee} setIsOpen={setModalViewEmployee} width={940}>
        <ViewEmployeeModal onClose={CloseViewEmployeeModal} onSubmit={SubmitEditEmployee} EmployeeId={employeeId} />
      </SidePanel>
      <SidePanel isOpen={ModalEditEmployee} setIsOpen={setModalEditEmployee} width={940}>
        <EditEmployeeModal
          open={ModalEditEmployee}
          onClose={CloseEditEmployeeModal}
          onSubmit={SubmitEditEmployeeProcess}
          EmployeeId={employeeId}
        />
      </SidePanel>

      <DialogEmployeeStatus
        OpenModal={ModalChangeStatusEmployee}
        onClose={CloseChangeStatusEmployeeModal}
        onSubmit={SubmitChangeStatusEmployeeProcess}
        EmployeeId={employeeId}
        ThisStatus={employeeStatus}
      />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={confirmsuccessModal}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />
    </EmployeeDIV>
  )
}

export default Employee
