import { getAPI, postAPI, putAPI } from 'utils/apiDynamicForm'
import { DYNAMIC_FORM, UPLOAD_FILE } from 'redux/actionTypes'
import { showLoading, hideLoading } from 'redux/actions/loading'

export const getLandingPageSchema = (name) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DYNAMIC_FORM?.GET?.GET_LANDING_PAGE_SCHEMA?.REQUEST })

    const { data } = await getAPI({
      url: `/api/content-management-landing-schema/${name}/true`,
    })

    return dispatch({ type: DYNAMIC_FORM?.GET?.GET_LANDING_PAGE_SCHEMA?.SUCCESS, data })
  } catch (error) {
    window.location.href = '/page-not-found'
  } finally {
    dispatch(hideLoading())
  }
}

export const getCreateSchema = (name) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DYNAMIC_FORM?.GET?.GET_CREATE_SCHEMA?.REQUEST })

    const { data } = await getAPI({
      url: `/api/content-management-create-schema/${name}`,
    })

    return dispatch({ type: DYNAMIC_FORM?.GET?.GET_CREATE_SCHEMA?.SUCCESS, data })
  } catch (error) {
    return dispatch({ type: DYNAMIC_FORM?.GET?.GET_CREATE_SCHEMA?.FAILURE })
  } finally {
    dispatch(hideLoading())
  }
}

export const getEditSchema = (id, name) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DYNAMIC_FORM?.GET?.GET_EDIT_SCHEMA?.REQUEST })

    const { data } = await getAPI({
      url: `/api/content-management-edit-schema/${name}/${id}`,
    })

    return dispatch({ type: DYNAMIC_FORM?.GET?.GET_EDIT_SCHEMA?.SUCCESS, data })
  } catch (error) {
    return dispatch({ type: DYNAMIC_FORM?.GET?.GET_EDIT_SCHEMA?.FAILURE })
  } finally {
    dispatch(hideLoading())
  }
}

export const getViewSchema = (id, name) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DYNAMIC_FORM?.GET?.GET_VIEW_SCHEMA?.REQUEST })

    const { data } = await getAPI({
      url: `/api/content-management-view-schema/${name}/${id}`,
    })

    return dispatch({ type: DYNAMIC_FORM?.GET?.GET_VIEW_SCHEMA?.SUCCESS, data })
  } catch (error) {
    return dispatch({ type: DYNAMIC_FORM?.GET?.GET_VIEW_SCHEMA?.FAILURE })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDataDynamicForm =
  (name, filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: DYNAMIC_FORM?.GET?.GET_DATA_DYNAMIC_FORM?.REQUEST })

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }
      const usedFilters = Object.entries(filter)
        .map(([key, value]) => ({
          key,
          value,
        }))
        .filter((v) => v.key !== 'page' && v.key !== 'pageSize' && v.key !== 'multipleSearch')
        .reduce((acc, cur) => {
          const { key, value } = cur
          acc[key] = value
          return acc
        }, {})

      const params = {
        ...multipleSearch,
        ...usedFilters,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
      }

      const { data, meta } = await getAPI({
        url: `/api/content-management-landing-schema/${name}/false`,
        params,
      })

      return dispatch({ type: DYNAMIC_FORM?.GET?.GET_DATA_DYNAMIC_FORM?.SUCCESS, data, meta })
    } catch (error) {
      return dispatch({ type: DYNAMIC_FORM?.GET?.GET_DATA_DYNAMIC_FORM?.FAILURE })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getDataDynamicFormBYId = (id, endpoint) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DYNAMIC_FORM?.GET?.GET_DATA_DYNAMIC_FORM_BY_ID?.REQUEST })

    const url = endpoint.replace(':id', id)
    const { data } = await getAPI({
      url,
    })

    return dispatch({ type: DYNAMIC_FORM?.GET?.GET_DATA_DYNAMIC_FORM_BY_ID?.SUCCESS, data })
  } catch (error) {
    return dispatch({ type: DYNAMIC_FORM?.GET?.GET_DATA_DYNAMIC_FORM_BY_ID?.FAILURE })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDynamicDropdownOptions = (endpoint) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DYNAMIC_FORM?.GET?.GET_DROPDOWN_OPTIONS?.REQUEST })

    const { data } = await getAPI({
      url: endpoint,
    })

    dispatch({ type: DYNAMIC_FORM?.GET?.GET_DROPDOWN_OPTIONS?.SUCCESS })

    return data
  } catch (error) {
    return dispatch({ type: DYNAMIC_FORM?.GET?.GET_DROPDOWN_OPTIONS?.FAILURE })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDynamicDropdownOptionsWithSpecificId = (id, endpoint) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DYNAMIC_FORM?.GET?.GET_DROPDOWN_OPTIONS_BY_SPECIFIC?.REQUEST })

    const url = endpoint.replace(':id', id)
    const { data } = await getAPI({
      url,
    })

    dispatch({ type: DYNAMIC_FORM?.GET?.GET_DROPDOWN_OPTIONS_BY_SPECIFIC?.SUCCESS })

    return data
  } catch (error) {
    return dispatch({ type: DYNAMIC_FORM?.GET?.GET_DROPDOWN_OPTIONS_BY_SPECIFIC?.FAILURE })
  } finally {
    dispatch(hideLoading())
  }
}

export const createDynamicForm = (data, endpoint) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DYNAMIC_FORM?.POST?.CREATE_DYNAMIC_FORM?.REQUEST })

    const response = await postAPI({
      url: `${endpoint}`,
      data,
    })

    return dispatch({ type: DYNAMIC_FORM?.POST?.CREATE_DYNAMIC_FORM?.SUCCESS, data: response?.data })
  } catch (error) {
    return dispatch({ type: DYNAMIC_FORM?.POST?.CREATE_DYNAMIC_FORM?.FAILURE })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateDynamicForm = (id, data, endpoint) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DYNAMIC_FORM?.PUT?.UPDATE_DYNAMIC_FORM?.REQUEST })

    const url = endpoint.replace(':id', id)
    await putAPI({
      url,
      data,
    })

    return dispatch({ type: DYNAMIC_FORM?.PUT?.UPDATE_DYNAMIC_FORM?.SUCCESS })
  } catch (error) {
    return dispatch({ type: DYNAMIC_FORM?.PUT?.UPDATE_DYNAMIC_FORM?.FAILURE })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteDynamicForm = (id, data, endpoint) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DYNAMIC_FORM?.DELETE?.DELETE_DYNAMIC_FORM?.REQUEST })

    const url = endpoint.replace(':id', id)
    await putAPI({
      url,
      data,
    })

    return dispatch({ type: DYNAMIC_FORM?.DELETE?.DELETE_DYNAMIC_FORM?.SUCCESS })
  } catch (error) {
    return dispatch({ type: DYNAMIC_FORM?.DELETE?.DELETE_DYNAMIC_FORM?.FAILURE })
  } finally {
    dispatch(hideLoading())
  }
}

export const clearEditSchema = () => async (dispatch) => {
  return dispatch({ type: DYNAMIC_FORM?.CLEAR?.CLEAR_EDIT_SCHEMA })
}

export const uploadFileForDynamicService = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPLOAD_FILE.REQUEST })

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: null,
    }
    const response = await postAPI({
      url: '/api/upload',
      headers,
      data,
    })

    dispatch({ type: UPLOAD_FILE.SUCCESS, showAlert: true, alertMessage: 'Upload file successful!' })
    return response[0]
  } catch (error) {
    dispatch({ type: UPLOAD_FILE.FAILURE, showAlert: true, alertMessage: 'Upload file Failure!' })
    return null
  } finally {
    dispatch(hideLoading())
  }
}
