import { Divider, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { createElement, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { RiAddLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import iconFail from 'assets/images/fail-icon.png'
import iconSuccess2 from 'assets/images/success-icon-2.png'
import Button from 'components/common/Button'
import Chip from 'components/common/Chip'
import CollapseMenu from 'components/common/CollapseMenu'
import ModalV2 from 'components/common/ModalV2'
import SidePanelV2 from 'components/common/SidePanelV2'
import TableV3 from 'components/common/TableV3'
import Typography from 'components/common/Typography'
import CreateTaskTPL from 'components/feature/operation-process/form/task-tpl/create'
import EditTaskTPL from 'components/feature/operation-process/form/task-tpl/edit'
import Filter from 'components/feature/operation-process/form/task-tpl/filters'
import ViewTaskTPL from 'components/feature/operation-process/form/task-tpl/view'
import { TASK_TEMPLATE } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { ButtonGroup, TableContainer, TableHeader, TypographyH3 } from '.'
import tableColumns from './table-columns'

const init_drawer = {
  createTaskTPL: false,
  viewTaskTPL: false,
  editTaskTPL: false,
  taskTplId: undefined,
}

const TaskMGTList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const taskTplId = searchParams.get('id')
  const mode = searchParams.get('mode') || 'view'

  const filterRef = useRef(null)
  const createTaskTplRef = useRef(null)
  const editTaskTplRef = useRef(null)
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)

  const { taskTemp: taskTplActions, extra: extraActions } = opActions()
  const { getExtra } = extraActions()
  const {
    getTaskTemp: getTaskTpl,
    postTaskTemp: postTaskTpl,
    putTaskTemp: putTaskTpl,
    clearTaskTemp: clearTaskTpl,
  } = taskTplActions()

  const { taskTemps, taskTemps_pagination, taskTemp } = opProcSelector
  const { page, pageCount, total } = taskTemps_pagination

  const [drawer, setDrawer] = useState(init_drawer)
  const [filters, setFilters] = useState({})
  const [modalProps, setModalProps] = useState({ open: false })

  const fetchTaskTPLs = useCallback(
    async (payload) => {
      return dispatch(getTaskTpl().list(payload))
    },
    [dispatch]
  )

  const fetchTaskTplById = useCallback(
    async (taskTplId) => {
      return dispatch(getTaskTpl(taskTplId).byId())
    },
    [dispatch]
  )

  const fetchDepartments = useCallback(
    async (payload) => {
      return dispatch(getExtra().departments(payload))
    },
    [dispatch]
  )

  const handleToggleDrawer = (drawerName, open, data = {}) => {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }

      setDrawer({ ...init_drawer, [drawerName]: open, ...data })
    }
  }

  const handleFilters = (values) => {
    const keys = Object.keys(values)

    if (keys.length === 0) {
      setFilters({})
      return fetchTaskTPLs()
    }

    let filters = {}
    keys.forEach((key) => {
      if (!values[key]) {
        return
      }

      switch (key) {
        case 'name':
          filters = { ...filters, $or: [{ name: { $contains: values[key] } }, { code: { $contains: values[key] } }] }
          break
        case 'department':
          filters = { ...filters, department: values[key] }
          break
        default:
          break
      }
    })

    if (Object.keys(filters).length === 0) {
      setFilters({})
      return fetchTaskTPLs()
    }

    setFilters(filters)
    return fetchTaskTPLs({ filters })
  }

  const handleTableChange = ({ page }) => {
    // Do something...
    const pagination = { ...taskTemps_pagination, page }
    fetchTaskTPLs({ pagination, filters })
  }

  const actionList = (item) => {
    return [
      {
        text: t('view'),
        action: () => {
          navigate(`${pathname}?id=${item.id}&mode=view`)
          handleToggleDrawer('viewTaskTPL', true)()
        },
      },
      {
        text: t('Edit'),
        action: () => {
          navigate(`${pathname}?id=${item.id}&mode=edit`)
          handleToggleDrawer('editTaskTPL', true)()
        },
      },
    ]
  }

  const transformTaskToTable = (data) => {
    const mapDataItem = (item) => {
      const code = (
        <Link to={`${pathname}?id=${item?.id}&mode=view`}>
          <Typography variant="tableLink">{item.code}</Typography>
        </Link>
      )
      const description = <div className="task-col-description">{item.description}</div>
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={item.status || 'N/A'}
          color={TASK_TEMPLATE.STATUS_CHIP[item.status]}
        />
      )
      const actions = <CollapseMenu list={actionList(item)} />

      return { ...item, code, description, status, actions }
    }

    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const handleCreateTaskTPLSubmit = (data) => {
    const successfulCreate = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
        return fetchTaskTPLs()
      },
      onOk() {
        setModalProps({ open: false })
        return fetchTaskTPLs()
      },
      titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
      titleText: 'Task Template Created',
      contentText: 'Task Template has been created successfully.',
    }

    const failedCreate = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
      },
      onOk() {
        setModalProps({ open: false })
      },
      okText: 'Try Again',
      titleIcon: createElement('img', { src: iconFail, width: 48, height: 48, alt: 'i-failed' }),
      titleText: "Couldn't Create Task Template",
      contentText: 'An error occurred while creating the Task Template.',
      okProps: { color: 'error' },
    }

    dispatch(postTaskTpl().create(data)).then((res) => {
      if (res.type.endsWith('_SUCCESS') && !res.error) {
        handleToggleDrawer('createTaskTPL', false)()
        setModalProps(successfulCreate)
        return
      }

      setModalProps(failedCreate)
      return
    })
  }

  const handleEditTaskTPLSubmit = (data) => {
    const onCloseAndOnOkSuccess = () => {
      setModalProps({ open: false })
      fetchTaskTPLs()
      fetchTaskTplById(taskTplId || drawer?.taskTplId)
      navigate(pathname)
    }

    const successfulEdit = {
      open: true,
      showCancel: false,
      onClose: onCloseAndOnOkSuccess,
      onOk: onCloseAndOnOkSuccess,
      titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
      titleText: 'Task Template Updated',
      contentText: 'Task Template has been updated successfully.',
    }

    const failedEdit = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
      },
      onOk() {
        setModalProps({ open: false })
      },
      okText: 'Try Again',
      titleIcon: createElement('img', { src: iconFail, width: 48, height: 48, alt: 'i-failed' }),
      titleText: "Couldn't Update Task Template",
      contentText: 'An error occurred while updating the Task Template.',
      okProps: { color: 'error' },
    }

    dispatch(putTaskTpl(taskTplId).update(data)).then((res) => {
      if (res.type.endsWith('_SUCCESS') && !res.error) {
        handleToggleDrawer('editTaskTPL', false)()
        setModalProps(successfulEdit)
        return
      }

      setModalProps(failedEdit)
      return
    })
  }

  useEffect(() => {
    fetchDepartments()
    fetchTaskTPLs()
  }, [fetchTaskTPLs, fetchDepartments])

  useEffect(() => {
    if (taskTplId) {
      switch (mode) {
        case 'edit':
          handleToggleDrawer('editTaskTPL', true, { taskTplId })()
          break

        case 'view':
        default:
          handleToggleDrawer('viewTaskTPL', true, { taskTplId })()
          break
      }

      return
    }

    handleToggleDrawer('viewTaskTPL', false)()
  }, [taskTplId, mode])

  return (
    <TableContainer className="task-table-container">
      <TableHeader>
        <Stack sx={{ width: '100%' }}>
          <Stack direction="row" sx={{ width: '100%', padding: '1rem 1.5rem', justifyContent: 'space-between' }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <TypographyH3>{t('task')}</TypographyH3>

              <Chip
                label={total > 1000 ? '999+' : `${total} Task templates`}
                color="primary"
                sx={{ p: '2px 8px 2px 8px', cursor: 'pointer' }}
              />
            </Stack>
          </Stack>

          <Divider sx={{ borderColor: 'var(--Gray-200)' }} />

          <Stack
            direction="row"
            alignItems="flex-end"
            padding="1rem 1.5rem"
            minHeight="4.875rem"
            flexWrap="wrap"
            columnGap="1rem"
            rowGap="0.5rem"
            justifyContent="space-between"
          >
            <Filter ref={filterRef} onFormChange={handleFilters} />

            <ButtonGroup>
              <Button variant="contained" startIcon={<RiAddLine />} onClick={handleToggleDrawer('createTaskTPL', true)}>
                {t('create_new_task_tpl')}
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </TableHeader>

      <TableV3
        data={transformTaskToTable(taskTemps)}
        page={page}
        pageCount={pageCount}
        columns={tableColumns('task')}
        onStateChange={handleTableChange}
      />

      <SidePanelV2
        titleText="Create Task Template"
        open={drawer.createTaskTPL}
        onClose={() => {
          handleToggleDrawer('createTaskTPL', false)()
        }}
        footerEl={
          <FooterDrawer>
            <Button className="gray" variant="outlined" onClick={handleToggleDrawer('createTaskTPL', false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" onClick={() => createTaskTplRef.current.submit()}>
              {t('create')}
            </Button>
          </FooterDrawer>
        }
      >
        <CreateTaskTPL ref={createTaskTplRef} onSubmit={handleCreateTaskTPLSubmit} />
      </SidePanelV2>

      <SidePanelV2
        titleText={`${taskTemp?.name}: ${taskTemp?.code}`}
        open={drawer.viewTaskTPL}
        onClose={() => {
          handleToggleDrawer('viewTaskTPL', false)()
          clearTaskTpl()
          navigate(pathname)
        }}
        footerEl={null}
      >
        {taskTplId && <ViewTaskTPL id={taskTplId || drawer?.taskTplId} />}
      </SidePanelV2>

      <SidePanelV2
        titleText={`${taskTemp?.name}: ${taskTemp?.code}`}
        open={drawer.editTaskTPL}
        onClose={() => {
          handleToggleDrawer('editTaskTPL', false)()
          clearTaskTpl()
          navigate(pathname)
        }}
        footerEl={
          <FooterDrawer>
            <Button
              className="gray"
              variant="outlined"
              onClick={() => {
                handleToggleDrawer('editTaskTPL', false)
                clearTaskTpl()
                navigate(pathname)
              }}
            >
              {t('cancel')}
            </Button>
            <Button variant="contained" onClick={() => editTaskTplRef.current.submit()}>
              {t('save')}
            </Button>
          </FooterDrawer>
        }
      >
        <EditTaskTPL ref={editTaskTplRef} onSubmit={handleEditTaskTPLSubmit} id={taskTplId || drawer?.taskTplId} />
      </SidePanelV2>

      <ModalV2 {...modalProps} />
    </TableContainer>
  )
}

export default TaskMGTList

const FooterDrawer = styled('footer')({
  display: 'flex',
  width: '100%',
  gap: '1rem',
  justifyContent: 'flex-end',
  padding: '1rem',
})
