import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import Card from '../../common/Card'

import demoImg from '../../../assets/images/employee-img.png'

const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-center {
    align-items: center;
  }

  .w-30 {
    width: 33%;
  }
`
const TaskContent = styled.div`
  .mr-1-rem {
    margin-right: 1rem;
  }
  .task-heading-field {
    color: var(--Gray-900);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0 0 1rem 0;
  }
  .task-status {
    color: var(--Gray-900);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
  }
  .status-icon {
    width: 30px;
    height: 30px;
  }
  .warning-icon {
    cursor: pointer;
    color: var(--warning-600);
  }
  .text-under-pie-chart {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .text-value {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    .bold {
      color: var(--Gray-900);
      font-size: 18px;
      font-weight: 600;
    }
    .green {
      color: var(--success-600);
      font-size: 18px;
      font-weight: 600;
    }
    .red {
      color: var(--Error-700);
      font-size: 18px;
      font-weight: 600;
    }
  }
  .employee-container {
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`

function CardTaskData4({ taskData, ...props }) {
  return (
    <Card {...props}>
      <TaskContent>
        <h3 className="task-heading-field">{taskData.title}</h3>
        <div className="employee-container">
          <FlexContainer className="align-items-center">
            <img className="mr-1-rem" src={demoImg} />
            <div className="text-value">{taskData.value}</div>
          </FlexContainer>
        </div>
      </TaskContent>
    </Card>
  )
}

export default CardTaskData4
