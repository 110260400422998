import SearchSuggestCustomer from 'components/widget/combo/SearchSuggestCustomer'
import SearchSuggestEmployee from 'components/widget/combo/SearchSuggestEmployee'
import InputText from 'components/widget/element/InputText'
import { t } from 'i18next'
import styled from 'styled-components'

import Date from 'components/form/Date'
import Button from 'components/form/button/Button'
import DropdownSelect from 'components/widget/element/DropDownSelect'
import InputTextArea from 'components/widget/element/InputTextArea'
import IconBin from 'components/widget/icon/IconBin'
import { convertDateFormat, numberWithCommas } from 'utils/helper'
import CalculatePrice from './CalculatePrice'
import DynamicTable from './DynamicTable'
import Finance from './Finance'
import TransferRequestInformation from './TransferRequestInformation'

export default function ViewByType({
  dataItem,
  onChange,
  onTableChange,
  onClickDelete,
  isPreview = false,
  layoutType,
}) {
  const getItemViewByType = () => {
    if (dataItem?.type == 'input_text') {
      if (!isPreview) {
        return (
          <div className="input_text">
            {dataItem?.title && <div className={'title'}>{dataItem.title}</div>}

            <InputText
              placeHolder={dataItem?.placeholder || ''}
              className={'text_field'}
              onChange={(e) => {
                if (onChange) {
                  onChange({
                    id: dataItem.id,
                    value: { text: e },
                  })
                }
              }}
              initValue={dataItem?.value?.text || ''}
              isError={dataItem?.value?.is_error}
              pattern={dataItem?.pattern}
              type={dataItem?.sub_type}
              step={dataItem?.step}
            />
          </div>
        )
      } else if (layoutType == 'header_right') {
        return (
          <>
            <div className={'inner_row_left'}>{dataItem?.title && <div className={'title'}>{dataItem.title}</div>}</div>
            <div className={'inner_row_right'}>{dataItem?.value?.text}</div>
          </>
        )
      }
      return (
        <div className={'wrp_text_preview'}>
          {dataItem?.preview_title ? (
            <div className={'title'}>{dataItem.preview_title}</div>
          ) : (
            <div className={'title'}>{dataItem.title}</div>
          )}
          <div className="input_text_preview">
            {dataItem?.value?.text ? dataItem?.value?.text : '-'}
            <div className={'text_preview_unit'}>{dataItem?.preview_unit}</div>
          </div>
        </div>
      )
    } else if (dataItem?.type == 'input_text_area') {
      if (!isPreview) {
        return (
          <div className={'input_text_area_wrp'}>
            {dataItem?.title && <div className={'title'}>{dataItem.title}</div>}
            <InputTextArea
              placeHolder={dataItem?.placeholder || ''}
              resizeDisable
              className={'input_text_area'}
              initValue={dataItem?.value?.text || ''}
              isError={dataItem?.value?.is_error}
              onChange={(e) => {
                if (onChange) {
                  onChange({ id: dataItem.id, value: { text: e.target.value } })
                }
              }}
            />
          </div>
        )
      }
      return (
        <div className={'input_text_area_wrp_preview'}>
          {dataItem?.title && <div className={'text_area_title'}>{dataItem.title}</div>}
          {dataItem?.value?.text && <div className={'text_area_detail'}>{dataItem.value.text}</div>}
        </div>
      )
    } else if (dataItem?.type == 'input_date') {
      if (!isPreview) {
        return (
          <div className={'input_date'}>
            {dataItem?.title && <div className={'title'}>{dataItem.title}</div>}
            <Date
              className={'input_date'}
              onChange={(e) => {
                if (onChange) {
                  onChange({ id: dataItem.id, value: { text: e } })
                }
              }}
              value={dataItem?.value?.text}
              error={dataItem?.value?.is_error}
            />
          </div>
        )
      }
      return (
        <>
          <div className={'inner_row_left'}>{dataItem?.title && <div className={'title'}>{dataItem.title}</div>}</div>
          <div className={'inner_row_right'}>{convertDateFormat(dataItem?.value?.text)}</div>
        </>
      )
    } else if (dataItem?.type == 'input_dropdown') {
      if (!isPreview) {
        return (
          <div className={'input_dropdown'}>
            {dataItem?.title && <div className={'title'}>{dataItem.title}</div>}
            <DropdownSelect
              className={'dropdown_select'}
              placeHolder={dataItem?.place_holder}
              dataList={dataItem?.data_list}
              onChange={(e) => {
                if (onChange) {
                  onChange({
                    id: dataItem.id,
                    value: { text: e },
                    view_type: dataItem?.view_type ? dataItem.view_type : '',
                  })
                }
              }}
              initValue={dataItem?.value?.text}
            />
          </div>
        )
      }
      return (
        <>
          <div className={'inner_row_left'}>{dataItem?.title && <div className={'title'}>{dataItem.title}</div>}</div>
          <div className={'inner_row_right'}>{dataItem?.value?.text}</div>
        </>
      )
    } else if (dataItem?.type == 'table') {
      return (
        <div className={'table'}>
          {/* {dataItem?.title && <div>{dataItem.title}</div>} */}
          <DynamicTable
            initValue={dataItem?.value}
            onTableChange={(e) => {
              if (onTableChange) {
                onTableChange(e, dataItem?.id)
              }
            }}
            onChange={(e) => {
              if (onChange) {
                onChange({ id: dataItem.id, sub_id: e?.id, value: e?.value?.value })
              }
            }}
            isPreview={isPreview}
          />
        </div>
      )
    } else if (dataItem?.type == 'button') {
      return (
        <div className={'button'}>
          <Button className={'button'}>{dataItem?.text}</Button>
        </div>
      )
    } else if (dataItem?.type == 'button_delete') {
      if (!isPreview) {
        return (
          <div
            className="btn-delete"
            onClick={() => {
              if (onClickDelete) {
                onClickDelete()
              }
            }}
          >
            <IconBin />
          </div>
        )
      }
      return <div className={'no_btn'}></div>
    } else if (dataItem?.type == 'input_calculate_price') {
      return (
        <CalculatePrice
          initValue={dataItem?.value}
          onChange={(e) => {
            if (onChange) {
              let tmpValue = dataItem.value
              tmpValue.is_checked = e?.is_checked || false
              onChange({ id: dataItem.id, value: tmpValue })
            }
          }}
          isPreview={isPreview}
        />
      )
    } else if (dataItem?.type == 'run_number') {
      return <div className={'run_number'}>{dataItem?.rowIndex + 1}</div>
    } else if (dataItem?.type == 'sum_text') {
      return <div className="text">{numberWithCommas(dataItem?.value?.text || '0.00')}</div>
    } else if (dataItem?.type == 'search_employee') {
      if (!isPreview) {
        return (
          <div className={'wrp_search_employee'}>
            {dataItem?.title && <div className={'title'}>{dataItem.title}</div>}
            <SearchSuggestEmployee
              placeHolder={t(dataItem?.placeholder)}
              onTextChange={(e) => {
                if (!e && onChange) {
                  onChange({
                    id: dataItem?.id,
                    value: { id: null },
                  })
                }
              }}
              onSelectedSuggest={(e) => {
                if (onChange) {
                  onChange({
                    id: dataItem?.id,
                    value: { id: e?.id },
                  })
                }
              }}
              isError={dataItem?.value?.is_error}
              isDisabled={dataItem?.value?.is_disable}
              selectedId={dataItem?.value?.id}
            />
          </div>
        )
      }
      return (
        <>
          <div className={'inner_row_left'}>{dataItem?.title && <div className={'title'}>{dataItem.title}</div>}</div>
          <div className={'inner_row_right'}>
            <SearchSuggestEmployee selectedId={dataItem?.value?.id} isPreview={true} />
          </div>
        </>
      )
    } else if (dataItem?.type == 'search_customer') {
      return (
        <div className={'wrp_search_customer'}>
          {dataItem?.title && <div className={'title'}>{dataItem.title}</div>}
          <SearchSuggestCustomer
            placeHolder={dataItem?.placeholder}
            type={dataItem?.sub_type}
            selectedId={dataItem?.value?.customer_data?.id}
            selectedIdName={dataItem?.value?.customer_data?.customer_id}
            onSelectedSuggest={(e) => {
              if (onChange) {
                let tmpText = ''
                if (dataItem?.sub_type == 'customer_id') {
                  tmpText = e?.customer_id
                } else if (dataItem?.sub_type == 'customer_name') {
                  tmpText = e?.name
                } else if (dataItem?.sub_type == 'tax_id') {
                  tmpText = e?.tax_id
                }

                onChange({ id: dataItem?.id, value: { customer_data: e, event: 'select', text: tmpText } })
              }
            }}
            onTextChange={(e) => {
              if (onChange) {
                onChange({ id: dataItem?.id, value: { text: e, event: 'text' } })
              }
            }}
            isError={dataItem?.value?.is_error}
          />
        </div>
      )
    } else if (dataItem?.type == 'input_transfer_request_information') {
      return (
        <TransferRequestInformation
          initValue={dataItem?.value}
          isError={dataItem?.is_error}
          isPreview={isPreview}
          onChange={(e) => {
            onChange({ id: dataItem.id, value: e })
          }}
        />
      )
    } else if (dataItem?.type == 'finance') {
      return (
        <Finance
          initValue={dataItem?.value}
          onChange={(e) => {
            onChange({ id: dataItem.id, value: e })
          }}
          isPreview={isPreview}
        />
      )
    }
    return <div className="text">{dataItem?.title}</div>
  }

  const getClassByDataItem = () => {
    let tmpClassName = ''
    if (!isPreview) {
      if (dataItem?.class_name_create) {
        tmpClassName = ' ' + dataItem.class_name_create
      }
      if (dataItem?.is_hidden) {
        tmpClassName = tmpClassName + ' hidden'
      }
    } else {
      if (dataItem?.class_name_preview) {
        tmpClassName = ' ' + dataItem.class_name_preview
      }
      if (dataItem?.is_hidden) {
        tmpClassName = tmpClassName + ' hidden'
      }
    }
    return tmpClassName
  }

  return (
    <Styled className={'view_item' + (dataItem?.type != 'table' ? getClassByDataItem() : ' table_item')}>
      {getItemViewByType()}
    </Styled>
  )
}
const Styled = styled.div`
  position: relative;

  .no_btn {
    display: none;
  }

  /* tr > * {
    //เอาทุกตัวที่เป็นลูกของ tr
  }
  * {
    background-color: red;
    font-style: xxx;
    // ทุกตัว
  } */

  &.table_item {
    // ใช้ตอนที่เป็น Root
    width: 100% !important;
    flex: 1;
  }

  &.hidden {
    display: none;
  }
`
