import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import SignatureCanvas from 'react-signature-canvas'

import styled from 'styled-components'
import Button from '../form/button/Button'
import ButtonOutline from '../form/button/ButtonOutline'
import Modal from '../common/Modal'
import ImageUploadCrop from '../form/ImageUploadCrop'

import { selectEmployeeProfile } from '../../redux/selectors'
import { uploadSignature } from '../../redux/actions/employeeProfile'

import { IoMdClose } from 'react-icons/io'
import { PiWarningCircleBold } from 'react-icons/pi'
import iconSigna from '../../assets/images/icon-signature.svg'
import penToolPlus from '../../assets/images/pen-tool-plus.svg'
import transparentBg from '../../assets/images/overlay.jpg'

const Div = styled.div`
  padding: 24px;
  .mr-8-px {
    margin-right: 8px;
  }
  .mr-12-px {
    margin-right: 12px;
  }
  .mb-16-px {
    margin-bottom: 16px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .mt-24-px {
    margin-top: 24px;
  }

  .icon {
    cursor: pointer;
  }
  .card-modal-container-signa {
    /* padding: 24px; */
    /* padding: 10px 20px; */
    .text-header {
      margin-top: 10px;
      margin-bottom: 10px;
      h4,
      span {
        margin: 0px;
      }
    }
    .justify-between {
      justify-content: space-between;
    }
    .justify-end {
      justify-content: flex-end;
      width: 100%;
      margin-top: 20px;
    }
    .card-modal-header-signa {
      display: block;
      align-items: start;
      padding: 0 24px;
      /* padding: 10px 20px; */

      margin: -15px;

      h2,
      p {
        margin: 0px;
        padding: 5px 10px;
      }
    }
    .card-modal-body-signa {
      width: 100%;
      max-height: 300px;

      padding: 15px 20px;
      margin-bottom: 5px;

      border: solid 1px hsl(0deg 0% 93.33%);
      border-radius: 10px;

      background-color: hsl(0deg 0% 93.33%);
    }

    .sigCanvas {
      width: 100% !important;
      height: 300px !important;
    }

    .upload-croped-image-body {
      margin-top: 24px;
    }

    .card-modal-footer-signa {
      padding: 10px 15px;
      margin-bottom: 15px;
    }

    .line {
      border-top: 1px solid #eaecf0;
      padding: 0;
      margin: 0 -20px;
    }
    .btn-signa {
      margin-top: 15px;
    }
    .text-signa {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .wrp-tab-menu {
      width: 100%;
      display: flex;

      .btn-menu-style {
        border: none;
        border-bottom: 2px solid transparent;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        border-bottom: 1px solid rgb(94 96 100 / 28%);
      }

      .menu-active {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: #f9f5ff;
        color: #6941c6;
        border-bottom: 2px solid var(--Primary-600) !important;
      }
    }
  }
`
const FlexContainer = styled.div`
  display: flex;
  &.gap {
    gap: 1rem;
  }
  &.justify-space-between {
    justify-content: space-between;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.align-items-center {
    align-items: center;
  }
`
const CurrentSinatureWrapper = styled.div`
  margin-top: 24px;
  .img-signature-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 44px 0;
    margin-bottom: 1rem;

    background-image: url(${transparentBg});
    background-size: cover;

    img {
      max-height: 250px;
    }
  }

  .warning-container {
    color: var(--Primary-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;

    display: flex;
    align-items: center;
  }

  .current-signature-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 48px;

    color: var(--Gray-600);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;

    .headline {
      color: var(--Gray-900);
      font-size: 1rem;
      font-weight: 600;
    }

    .img-container {
      border-radius: 10px;
      border: 1px solid var(--Gray-200);
      background: var(--Base-White);
      padding: 12px 14px 10px 14px;
    }
  }
`

function EmployeeSignatureModal({ open, onClose, setIsWarningModal, ...props }) {
  // external hook
  const { t } = useTranslation()
  const dispatch = useDispatch()

  // employee signature
  const { employeeSignature } = useSelector(selectEmployeeProfile)

  // tab
  const initTabMenuList = [
    {
      name: t('current'),
      value: 'current',
    },
    {
      name: t('draw'),
      value: 'draw',
    },
    {
      name: t('upload'),
      value: 'upload',
    },
  ]
  const [menuTabList] = useState(initTabMenuList)
  const [tabactive, setTabactive] = useState('current')

  const handleClickTaskMenuTab = (data) => {
    setTabactive(data)
  }

  // signature draw image
  const sigCanvas = useRef(null)
  const [croppedImageUrl, setCroppedImageUrl] = useState(null)

  const handleClear = () => {
    sigCanvas?.current?.clear()
  }
  const convertBase64ToImageFile = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1])
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const int8Array = new Uint8Array(arrayBuffer)

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i)
    }

    const blob = new Blob([int8Array], { type: 'image/png' })
    return blob
  }
  const onSubmitUploadEmployeeSignature = () => {
    if (tabactive === 'current') {
      if (employeeSignature && employeeSignature.length > 0) {
        setTabactive('current')
        onClose()
      } else {
        setIsWarningModal(true)
      }
    } else if (tabactive === 'draw') {
      if (!sigCanvas.current.isEmpty()) {
        const signatureBase64 = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
        const blobData = convertBase64ToImageFile(signatureBase64)
        const formData = new FormData()
        formData.append('files', blobData, 'signature.png')
        dispatch(uploadSignature(formData))
        setTabactive('current')
      } else {
        setIsWarningModal(true)
      }
    } else {
      if (croppedImageUrl) {
        const blobData = convertBase64ToImageFile(croppedImageUrl)
        const formData = new FormData()
        formData.append('files', blobData, 'crop-sinature.png')
        dispatch(uploadSignature(formData))
        setTabactive('current')
      } else {
        setIsWarningModal(true)
      }
    }
  }

  return (
    <Modal open={open} onClose={onClose} disableBackdropClick>
      <Div {...props}>
        <div className="card-modal-container-signa">
          <FlexContainer className="justify-space-between align-items-center">
            <img src={iconSigna} alt="iconSigna" />
            <IoMdClose size={20} className="icon" onClick={onClose} />
          </FlexContainer>
          <div className="card-modal-header-signa">
            <div className="text-header">
              <h4>{t('signature_needed')}</h4>
              <span>{t('signature_needed_text')}</span>
            </div>
            <div className="wrp-tab-menu">
              {menuTabList?.map((item, index) => (
                <button
                  id={'this_name-' + item.name}
                  key={'btn-' + index}
                  className={tabactive === item.value ? 'menu-active' : 'btn-menu-style'}
                  onClick={() => handleClickTaskMenuTab(item.value)}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>

          {tabactive === 'current' ? (
            <CurrentSinatureWrapper className="mb-16-px">
              {employeeSignature[0]?.signature?.url ? (
                <React.Fragment>
                  <div className="img-signature-container">
                    <img src={employeeSignature[0]?.signature?.url} alt="current-signature" />
                  </div>
                  <div className="warning-container">
                    <PiWarningCircleBold className="mr-8-px" size={20} />
                    <span>{t('sign_recommendation')}</span>
                  </div>
                </React.Fragment>
              ) : (
                <div className="current-signature-container">
                  <div className="img-container mb-16-px">
                    <img src={penToolPlus} alt="pen-tool-plus" />
                  </div>
                  <div className="headline">{t('no_sign_headline')}</div>
                  <div className="mb-24-px">{t('no_sign_sub_headline')}</div>
                  <div>
                    <Button className="mr-12-px" onClick={() => handleClickTaskMenuTab('draw')}>
                      {t('draw')}
                    </Button>
                    <Button onClick={() => handleClickTaskMenuTab('upload')}>{t('upload')}</Button>
                  </div>
                </div>
              )}
            </CurrentSinatureWrapper>
          ) : tabactive === 'draw' ? (
            <div className="mt-24-px mb-16-px">
              <div className="card-modal-body-signa">
                <SignatureCanvas canvasProps={{ className: 'sigCanvas' }} ref={sigCanvas} />
              </div>
              <div className="btn-signa">
                <Button onClick={handleClear}>X {t('clear')}</Button>
              </div>
            </div>
          ) : (
            <div className="upload-croped-image-body mb-16-px">
              <ImageUploadCrop setCroppedImageUrl={setCroppedImageUrl} />
            </div>
          )}

          <div className="line"></div>
          <div className="card-modal-footer-signa">
            <FlexContainer className="justify-end gap">
              <ButtonOutline onClick={onClose}>{t('cancel')}</ButtonOutline>
              <Button onClick={onSubmitUploadEmployeeSignature}>{t('create')}</Button>
            </FlexContainer>
          </div>
        </div>
      </Div>
    </Modal>
  )
}

export default EmployeeSignatureModal
