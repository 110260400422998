import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'

import App from './App'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './redux/store'
import i18n from './i18n'

import './assets/css/global.css'
import 'react-image-crop/dist/ReactCrop.css'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
