import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { getMeetingById,CreateFileUserUpload,updateMeeting } from '../../redux/actions/dailysummary'
import { selectDailySummary } from '../../redux/selectors'
import { DateDisplay,TimeDisplayHHmm,DateTimeHHmmDisplay,DateTimeHHmmssDisplay } from '../../utils/common'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import FileUploadProgress from '../../components/form/FileUploadProgress'
import { useTranslation } from 'react-i18next'

const acceptList = {
    images: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    files: {
      'text/csv': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.ms-excel': [],
      'application/zip': [],
    },
    videos: {
      'video/x-msvideo': [],
      'video/mp4': [],
      'video/mpeg': [],
      'video/webm': [],
    },
    audios: {
      'audio/mpeg': [],
      'audio/wav': [],
      'audio/webm': [],
    },
}
const multiple = false;
const error = false;
const disabled = false;
import { postAPI } from '../../utils/api'

const DivMain = styled.div`
color: var(--Gray-600);
font-size: 14px;
font-style: normal;
font-weight: 500;
.box_file_meeting {
    padding: 14px 14px;
    /*
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    */
}
.dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    text-align: end;
    /*position: fixed;*/
    right: 0;
    bottom: 70px;
    /*width : 100%;*/
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
}
`

const initialState = {
    file_meeting : null
}

const TabMeetingFile = ({ this_meeting_id }) => {
    const { t } = useTranslation()
    const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
    const dispatch = useDispatch()
    useEffect(() => {
        if (this_meeting_id) {
            dispatch(getMeetingById(this_meeting_id))
        }
    }, [this_meeting_id, dispatch])

    const { MeetingInfo } = useSelector(selectDailySummary)
    const [MeetingData, setMeetingData] = useState(initialState)

    const allowedTypes = [];
    const acceptType = allowedTypes.reduce((pre, cur) => {
      return {
        ...pre,
        ...acceptList[cur],
      }
    }, {})

    const [file_meeting, setfile_meeting] = useState([])

    useEffect(() => {
        if (MeetingInfo.id) {
            const setData = {
                file_meeting: MeetingInfo.meeting_full_file.map((v) => v.id)
            }
            setMeetingData(setData)
            setfile_meeting(MeetingInfo.meeting_full_file.map((v) => v))
        }
      }, [MeetingInfo])

      /*
      const handleAddFileMeeting = async (value) => {
        const fetchList = []
        value.forEach((v) => {
          const formData = new FormData()
          formData.append('files', v)
          formData.append(
            'fileInfo',
            JSON.stringify({
              name: v.name,
              caption: v.name,
              alternativeText: v.name,
              folder: null,
            })
          )
    
          fetchList.push(
            postAPI({
              url: '/api/upload',
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: null,
              },
              data: formData,
            })
          )
        })
    
        const res = await Promise.all(fetchList)
        dispatch(CreateFileUserUpload(res[0][0].id));
        if (MeetingData.file_meeting) {
            setMeetingData({ ...MeetingData, file_meeting: [...MeetingData.file_meeting, res[0][0].id] })
        } else {
            setMeetingData({ ...MeetingData, file_meeting: [res[0][0].id] })
        }
    
        setfile_meeting([...file_meeting, ...res.map((v) => v[0])])
      }
      */

      const handleAddFileMeeting = async (file_id) => {
        //alert(JSON.stringify(file_id))
        //const this_aa_bb = [...file_meeting, file_id]
        //alert(JSON.stringify(this_aa_bb))
        if (MeetingData.file_meeting) {
          setMeetingData({ ...MeetingData, file_meeting: [...MeetingData.file_meeting, file_id] })
        } else {
          setMeetingData({ ...MeetingData, file_meeting: [file_id] })
        }
        
      }
    
      const onDeleteFileMeeting = (id) => {
        setfile_meeting(file_meeting.filter((v) => v.id !== id))
        setMeetingData({ ...MeetingData, file_meeting: MeetingData.file_meeting.filter((v) => v !== id) })
      }

      const Savechanges = () => {
        let sent_data_api = {
          data: {
              file_meeting: MeetingData.file_meeting
          },
        }
        dispatch(updateMeeting(this_meeting_id, sent_data_api)).then(({ type }) => {
          if(type.endsWith('_SUCCESS')){
            
          }   
        })
    }
    return (
        <DivMain>
            <div className="box_file_meeting">
                <div>
                    <FileUploadProgress
                        multiple={multiple}
                        accept={acceptType}
                        file={file_meeting.map((v) => ({ ...v, url: `${v.url}` }))}                      
                        onAddFile={handleAddFileMeeting}
                         onDeleteFile={onDeleteFileMeeting}                      
                        error={error}
                        disabled={disabled}
                        FileListData={file_meeting}
                        setFileListData={setfile_meeting}
                        isShowTimeStampAndUploadBy={true}
                    />  
                </div>
                <div></div>
            </div>
            <div className="dialog-footer">
                <ButtonOutline className="button_margin" >
                {t('cancel')}
                </ButtonOutline>
                <Button className="button_margin" onClick={() => Savechanges()}>
                {t('save')}
                </Button>
            </div>            
        </DivMain>
        
    )
}

export default TabMeetingFile
