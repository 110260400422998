import { combineReducers } from 'redux'
import {
  GET_PRODUCTS,
  GET_PRODUCTS_SPECIFICATIONS,
  GET_PRODUCTS_CATEGORIES,
  GET_PRODUCTS_BRANDS,
  GET_PRODUCTS_FORMULAS,
  GET_PRODUCTS_PRICE_STRUCTERS,
  GET_PRODUCTS_PRICE_COSTS,
  GET_PRODUCT_MANAGERS,
  GET_PRODUCTS_TYPES,
  GET_PRODUCTS_SUPPLIERS,
  GET_PRODUCTS_SEGMENTS,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  CREATE_PRODUCTS_NOTE,
  GET_PRODUCTS_NOTES,
  GET_PRODUCT_BY_ID,
  UPDATE_PRODUCT,
  UPLOAD_CSV_PRODUCT,
  VIEW_PRODUCT_RELATION,
  UPDATE_PRICE_MODEL,
} from '../actionTypes'

const initialState = {
  productInfo: {},
  productList: [],
  specifications: [],
  brands: [],
  categories: [],
  types: [],
  segments: [],
  formulas: [],
  costs: [],
  priceStructures: [],
  suppliers: [],
  productManagers: [],
  total: 0,
  skip: 0,
  limit: 10,
  notes: [],
  viewProductRelations: {},
  isLoading: false,
}
const productReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PRODUCTS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCTS.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        productList: data,
        total: meta.total,
        skip: meta.skip,
        limit: meta.limit,
        isLoading: false,
      }
    }
    case GET_PRODUCTS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PRODUCTS_SPECIFICATIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCTS_SPECIFICATIONS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        specifications: data,
        isLoading: false,
      }
    }
    case GET_PRODUCTS_SPECIFICATIONS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PRODUCTS_CATEGORIES.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCTS_CATEGORIES.SUCCESS: {
      const { data } = action
      return {
        ...state,
        categories: data,
        isLoading: false,
      }
    }
    case GET_PRODUCTS_CATEGORIES.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PRODUCTS_BRANDS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCTS_BRANDS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        brands: data,
        isLoading: false,
      }
    }
    case GET_PRODUCTS_BRANDS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PRODUCTS_FORMULAS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCTS_FORMULAS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        formulas: data,
        isLoading: false,
      }
    }
    case GET_PRODUCTS_FORMULAS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PRODUCTS_PRICE_STRUCTERS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCTS_PRICE_STRUCTERS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        priceStructures: data,
        isLoading: false,
      }
    }
    case GET_PRODUCTS_PRICE_STRUCTERS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PRODUCTS_PRICE_COSTS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCTS_PRICE_COSTS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        costs: data,
        isLoading: false,
      }
    }
    case GET_PRODUCTS_PRICE_COSTS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PRODUCT_MANAGERS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCT_MANAGERS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        productManagers: data,
        isLoading: false,
      }
    }
    case GET_PRODUCT_MANAGERS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PRODUCTS_TYPES.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCTS_TYPES.SUCCESS: {
      const { data } = action
      return {
        ...state,
        types: data,
        isLoading: false,
      }
    }
    case GET_PRODUCTS_TYPES.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PRODUCTS_SUPPLIERS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCTS_SUPPLIERS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        suppliers: data,
        isLoading: false,
      }
    }
    case GET_PRODUCTS_SUPPLIERS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PRODUCTS_SEGMENTS.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCTS_SEGMENTS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        segments: data,
        isLoading: false,
      }
    }
    case GET_PRODUCTS_SEGMENTS.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_PRODUCT.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_PRODUCT.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_PRODUCT.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_PRODUCT.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case DELETE_PRODUCT.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_PRODUCT.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_PRODUCTS_NOTE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_PRODUCTS_NOTE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_PRODUCTS_NOTE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PRODUCTS_NOTES.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCTS_NOTES.SUCCESS:
      return {
        ...state,
        notes: action.data,
        isLoading: false,
      }
    case GET_PRODUCTS_NOTES.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PRODUCT_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PRODUCT_BY_ID.SUCCESS:
      return {
        ...state,
        productInfo: action.data,
        isLoading: false,
      }
    case GET_PRODUCT_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_PRODUCT.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_PRODUCT.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_PRODUCT.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPLOAD_CSV_PRODUCT.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPLOAD_CSV_PRODUCT.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPLOAD_CSV_PRODUCT.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case VIEW_PRODUCT_RELATION.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case VIEW_PRODUCT_RELATION.SUCCESS: {
      const { data } = action
      return {
        ...state,
        viewProductRelations: data,
        isLoading: false,
      }
    }
    case VIEW_PRODUCT_RELATION.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_PRICE_MODEL.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_PRICE_MODEL.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_PRICE_MODEL.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return state
  }
}

export default combineReducers({
  productReducer,
})
