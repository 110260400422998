import { Dialog, DialogActions, DialogContent, IconButton } from '@mui/material'
import { styled } from '@mui/system'
import { RiCloseFill } from 'react-icons/ri'

/**
 * A customizable and dynamic dialog component using Material-UI.
 *
 * @component
 * @example
 * // Example usage of DynamicDialog component
 * const MyComponent = () => {
 *   const [isOpen, setIsOpen] = useState(false);
 *   const handleClose = () => setIsOpen(false);
 *
 *   return (
 *     <DynamicDialog
 *       open={isOpen}
 *       onClose={handleClose}
 *       icon="path/to/icon.png"
 *       title="Dialog Title"
 *       description="This is a sample dialog description."
 *       actions={<Button onClick={handleClose}>Close</Button>}
 *     />
 *   );
 * };
 *
 * @param {Object} props - Component props
 * @param {string} [props.icon] - The path to the icon image.
 * @param {string} [props.title] - The title of the dialog.
 * @param {string} [props.description] - The description or content of the dialog.
 * @param {ReactNode} [props.actions] - The actions or buttons for the dialog.
 * @param {boolean} props.open - Determines whether the dialog is open or closed.
 * @param {function} props.onClose - Callback function to handle dialog closure.
 * @returns {ReactNode} JSX element representing the DynamicDialog component.
 */
export default function DynamicDialog({ icon, title, description, actions, ...props }) {
  const { open, onClose } = props

  return (
    <CustomDialog open={open} onClose={onClose} {...props}>
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'var(--Gray-500)',
          }}
        >
          <RiCloseFill />
        </IconButton>

        <ContentWrapper>
          {icon && <img src={icon} width={48} height={48} alt="icon_image" />}
          <TypographyH3>{title}</TypographyH3>
          <TypographyP>{description}</TypographyP>
        </ContentWrapper>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </CustomDialog>
  )
}

const CustomDialog = styled(Dialog)`
  & .MuiDialogContent-root {
    display: flex;
    max-width: 25rem;
    padding: ${(props) => props.padding || '1.5rem'};
    border-radius: 0.75rem;
  }

  & .MuiDialogActions-root {
    padding: ${(props) => props.paddingActions || '0 1.5rem 1.5rem 1.5rem'};
  }
`

const TypographyH3 = styled('h3')`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  color: var(--Gray-700);
  margin: 0;
`

const TypographyP = styled('p')`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  color: var(--Gray-600);
  margin: 0;
`

const ContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;

  width: 400px;
  height: fit-content;

  & img {
    margin-bottom: 1rem;
  }
`
