import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function MainTabs({ activeTab = 'my-task' }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <div
        id="my-task"
        className={`tab-item  ${activeTab === 'my-task' ? 'active' : ''}`}
        onClick={() => activeTab !== 'my-task' && navigate(`/operation-process/my-task`)}
      >
        {t('my_task')}
      </div>
      <div
        id="department-operation"
        className={`tab-item ${activeTab === 'department-operation' ? 'active' : ''}`}
        onClick={() => activeTab !== 'department-operation' && navigate(`/operation-process/department-operation`)}
      >
        {t('department_operation')}
      </div>
      <div
        id="task-board"
        className={`tab-item  ${activeTab === 'task-board' ? 'active' : ''}`}
        onClick={() => activeTab !== 'task-board' && navigate(`/operation-process/task-board`)}
      >
        {t('task_board')}
      </div>
    </>
  )
}
