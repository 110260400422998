import { useEffect } from 'react'
import styled from 'styled-components'
import IconPlus from '../icon/IconPlus'

export default function ModalBox({
  body,
  title,
  footer,
  onClosed,
  width = '390px',
  maxHeight = 'calc(100vh - 64px)',
  children,
  className,
  style,
  backgroundModal = '#fff',
  fontColor = '#444',
  backdropColor = 'rgba(0, 0, 0, 0.5)',
  borderColor = '#efefef',
  closeIconBackgroundColor = 'transparent',
  closeIconColor = '#444',
  hideCloseButton,
  hideModalBorder = true,
  titleAlign = 'left',
  paddingGap = '20px',
}) {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.removeAttribute('style')
    }
  }, [])

  return (
    <Styled
      className={className}
      style={style}
      $width={width}
      $backdropColor={backdropColor}
      $backgroundModal={backgroundModal}
      $fontColor={fontColor}
      $borderColor={borderColor}
      $hideModalBorder={hideModalBorder}
      $closeIconBackgroundColor={closeIconBackgroundColor}
      $titleAlign={titleAlign}
      $paddingGap={paddingGap}
      $maxHeight={maxHeight}
      $title={title}
    >
      {children ? (
        children
      ) : (
        <div className={'modal'}>
          {!hideCloseButton && (
            <div
              className={'close-icon'}
              onClick={() => {
                if (onClosed) {
                  onClosed()
                }
              }}
            >
              <IconPlus width="20" degree="45" color={closeIconColor} />
            </div>
          )}
          {title && <div className={'title-modal'}>{title}</div>}
          <div className={'content'}>{body}</div>
          {footer && <div className={'footer'}>{footer}</div>}
        </div>
      )}
    </Styled>
  )
}
const Styled = styled.div`
  position: fixed;
  background-color: ${({ $backdropColor }) => $backdropColor};
  inset: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  color: ${({ $fontColor }) => $fontColor};
  .wrp_float {
    position: fixed;
    visibility: hidden;
  }
  .modal {
    background-color: ${({ $backgroundModal }) => $backgroundModal};
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${({ $width }) => $width};
    max-height: ${({ $maxHeight }) => $maxHeight};
    border-radius: 10px;
    position: relative;
  }
  .title-modal {
    font-size: 20px;
    line-height: 32px;
    text-align: ${({ $titleAlign }) => $titleAlign};
    font-weight: bolder;
    padding: ${({ $paddingGap }) => $paddingGap};
    border-bottom: ${({ $hideModalBorder }) => (!$hideModalBorder ? '1px' : 0)} solid
      ${({ $borderColor }) => $borderColor};
  }

  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 40px;
    height: 40px;
    background-color: ${({ $closeIconBackgroundColor }) => $closeIconBackgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    cursor: pointer;
  }

  .content {
    flex: 1;
    overflow: auto;
    padding-left: ${({ $paddingGap }) => $paddingGap};
    padding-right: ${({ $paddingGap }) => $paddingGap};
    padding-top: ${({ $title, $paddingGap }) => ($title ? '0' : $paddingGap)};
  }
  .footer {
    padding: ${({ $paddingGap }) => $paddingGap};
    border-top: ${({ $hideModalBorder }) => (!$hideModalBorder ? '1px' : 0)} solid ${({ $borderColor }) => $borderColor};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer > span {
    margin-right: 5px;
  }
`
