import { getAPI, postAPI, putAPI, deleteAPI } from '../../utils/apiofm'

import {
  GET_COCK_PIT,
  GET_LOG_LATEST_TASK,
  GET_LOG_HISTORICAL_TASK,
  GET_ZONE_OFM,
  SET_COCK_PIT_DATA,
  SET_LOG_LATEST_TASK,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getZoneOFM = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_ZONE_OFM.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-type': 'application/json',
    }

    const params = {
      'fields[0]': 'name',
      'populate[bases][[fields]0]': 'name',
    }

    const { data } = await getAPI({
      url: `/api/zones`,
      headers,
      params,
    })

    return dispatch({
      type: GET_ZONE_OFM.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_ZONE_OFM.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Zone OFM failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getCockPit =
  (id, filter = {}) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_COCK_PIT.REQUEST })

      const { token } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `${token}`,
        'Content-type': 'application/json',
      }

      const params = {
        'sort[0]': 'id',
      }
      if (filter?.date !== '') params['filters[date]'] = filter.date
      if (filter?.shift !== '') params['filters[shift]'] = filter.shift
      if (filter?.startTime !== '' && filter.endTime !== '') {
        params['filters[time][$gte]'] = filter.startTime
        params['filters[time][$lte]'] = filter.endTime
      }
      if (filter?.taskId) params['filters[id]'] = filter.taskId

      const { data } = await getAPI({
        url: `/api/ofm-dashboard/${id}/cockpit/task`,
        headers,
        params,
      })

      return dispatch({
        type: GET_COCK_PIT.SUCCESS,
        data,
      })
    } catch (error) {
      return dispatch({
        type: GET_COCK_PIT.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Cock pit failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getLogHistoricalTask = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_LOG_HISTORICAL_TASK.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-type': 'application/json',
    }

    const params = {
      'filters[status]': 'active',
      'populate[documents][populate][form]': '*',
    }

    const { data } = await getAPI({
      url: `/api/ofm-dashboard/${id}/cockpit/taskhistory`,
      headers,
      params,
    })

    return dispatch({
      type: GET_LOG_HISTORICAL_TASK.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_LOG_HISTORICAL_TASK.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Log Historical Task failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getLogLatestTask = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_LOG_LATEST_TASK.REQUEST })

    const { token } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `${token}`,
      'Content-type': 'application/json',
    }

    const params = {
      'filters[status]': 'active',
    }

    const { data } = await getAPI({
      url: `/api/ofm-dashboard/cockpit/taskhistory/last/${id}`,
      headers,
      params,
    })

    return dispatch({
      type: GET_LOG_LATEST_TASK.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_LOG_LATEST_TASK.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Log latest Task failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const setCockPitData = (data) => async (dispatch) => {
  dispatch({ type: SET_COCK_PIT_DATA, data })
}
export const setLogLatestTaskData = (data) => async (dispatch) => {
  dispatch({ type: SET_LOG_LATEST_TASK, data })
}
