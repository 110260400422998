import { Breadcrumbs as MuiBreadcrumbs, Stack, Chip } from '@mui/material'
import { styled } from '@mui/system'

const Breadcrumbs = ({ sw, task, onClick }) => {
  return (
    <MuiBreadcrumbs aria-label="custom-breadcrumbs" separator=">" sx={{ marginLeft: '-10px !important' }}>
      <StyledBreadcrumb key={'sw'} component="a" className="inactive" label={sw} onClick={onClick} />
      <StyledBreadcrumb key={'task'} component="a" className="active" label={task} />
    </MuiBreadcrumbs>
  )
}

const TaskDrawerTitle = ({ taskData, onClickBreadcrumb }) => {
  return (
    // <Stack direction="row" spacing={1}>
    <TaskDrawerContainer spacing={1}>
      <Breadcrumbs sw={taskData?.sw?.name} task={taskData?.code} onClick={onClickBreadcrumb} />
      <h3>{taskData?.name}</h3>
    </TaskDrawerContainer>
    // </Stack>
  )
}

export default TaskDrawerTitle

const TaskDrawerContainer = styled(Stack)`
  /* padding: 1rem;
  gap: 1rem;
  width: 100%;
  height: 100%; */

  & > h3 {
    font-family: 'Inter', 'Noto Sans Thai';
    font-size: 18px;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
    color: var(--Gray-700);
    margin: 0;
  }
`

const StyledBreadcrumb = styled(Chip)`
  font-family: 'Inter', 'Noto Sans Thai' !important;
  /* Text sm/Medium */
  font-size: 10px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 0.75rem !important; /* 142.857% */
  color: var(--Gray-600) !important;
  border-radius: 1rem !important;
  align-items: center;

  &.MuiChip-colorDefault {
    background: var(--Base-White);
    cursor: pointer;
  }

  &:hover,
  &:focus {
    background-color: var(--Gray-50) !important;
    text-decoration: none !important;
  }

  &.active {
    background-color: var(--Gray-100) !important;
    cursor: default;
  }
`
