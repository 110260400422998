import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import ScopeOfWork from './ScopeOfWork'
import JobPosition from './JobPosition'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import backIcon from '.././../assets/images/back-icon.png'
import editIcon from '.././../assets/images/edit-icon.png'

import { getDepartmentById } from '../../redux/actions/department'
import { selectDepartMent } from '../../redux/selectors'
import { useTranslation } from 'react-i18next'
const Div = styled.div`
  .header {
    display: flex;
    align-items: center;

    .highlight {
      color: var(--Primary-700);
      font-weight: 600;
    }
  }

  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mb-2-rem {
    margin-bottom: 2rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .mr-8-px {
    margin-right: 8px;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-1_5rem {
    font-size: 1.5rem;
  }

  .text-30-px {
    font-size: 30px;
  }

  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .d-flex {
    display: flex;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .align-items-center {
    align-items: center;
  }

  .tab-container-department-detail {
    display: flex;

    .tab-item {
      cursor: pointer;
      margin-right: 2rem;
      padding: 10px;
      color: var(--Tab-Font-Default);
      background-color: var(--Tab-Bg-Default);

      &.active {
        font-weight: 600;
        border-bottom: 2px solid var(--Tab-Underline-Current);
        color: var(--Tab-Font-Current);
        background-color: var(--Tab-Bg-Current);
      }
    }
  }

  .button-footer-company-profile {
    margin-bottom: 25rem;
  }
  .button-footer {
    margin-bottom: 35rem;
  }
  .h-full {
    height: 100%;
  }
  .left-profile {
    margin-left: 0px;
  }
  .left {
    margin-left: 260px;
  }
  .right {
    margin-right: 230px;
  }
  .col-12 {
    width: 100%;
  }
  .input-col {
    display: flex;
    grid-template-columns: repeat(2, 10rem) 8rem;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input-header {
    color: #7f56d9;
    font-size: 1.2rem;
    font-weight: 500;
  }
  .icon {
    cursor: pointer;
  }
  .divider {
    margin: 2rem 0;
    border: 0.1rem solid #eaecf0;
  }
  .create-access-role-header {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .side-panel-container {
    padding: 2rem;
  }
  .d-flex {
    display: flex;
  }
  .row {
    flex-direction: row;
  }
  .justify-content-flex-start {
    justify-content: flex-start;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .content-header {
    margin-top: 24px;
  }
  .float-end {
    float: right;
  }

  .float-start {
    float: left;
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .mt-50 {
    margin-top: 50px;
  }

  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .w-100 {
    width: 100%;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .date-filter-container {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .drop-down {
    min-width: 10rem;
  }

  .task-report-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .task-box {
    border: 1px solid black;
    width: 100%;
    margin: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    font-weight: 600;
  }

  .task-box .amount-tasks {
    font-weight: bold;
    text-align: center;
    color: black;
    font-size: 20px;
  }

  .filter-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .wrp-filter-menu {
      position: relative;
      display: flex;
    }

    .wrp-filter-btn {
      position: relative;
    }

    .dialog-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 300px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);
      max-height: 300px;

      .wrp-person-list {
        position: relative;
      }
      .person-list {
        height: 220px;
        overflow-y: scroll;
      }

      .person-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 4px;
        margin-bottom: 8px;

        span {
          text-transform: capitalize;
        }

        .img-profile {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          background-color: var(--Gray-200);
          border-radius: 50%;
          padding: 5px;
        }
      }
      .person-item:hover {
        background-color: var(--Gray-50);
      }
    }

    .filter-popup {
      position: absolute;
      background-color: #fff !important;
      min-width: 500px;
      padding: 1rem;
      border-radius: 4px;
      box-shadow: 1px 1px 5px var(--Gray-200);

      .wrp-filter-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .clear-btn {
          padding: 4px 6px;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        .clear-btn:hover {
          background: var(--Primary-25);
        }
      }
      .wrp-filter-option {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;

        .title-option {
          font-weight: 600;
          margin-bottom: 6px;
        }
        .option-item-style {
          border: 1px solid var(--Gray-300);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Gray-50);
        }
        .option-item-style:hover {
          background-color: var(--Gray-100);
        }
        .option-item-active {
          border: 1px solid var(--Primary-600);
          border-radius: 4px;
          margin-bottom: 6px;
          padding: 6px 10px;
          background-color: var(--Primary-600);
          color: #fff;
        }
      }
    }
  }

  .wrp-filter-menu {
    display: flex;
    align-items: center;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
`
function DepartmentDetail() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { id } = useParams()
  const { departmentInfo } = useSelector(selectDepartMent)
  const [tabMenu, setTabMenu] = useState('scope_of_work')

  useEffect(() => {
    dispatch(getDepartmentById(id))
  }, [dispatch, id])

  const goBack = () => {
    navigate(-1)
  }

  const goBackOrganization = () => {
    navigate('/organization-management/organization?tab=companyprofile')
  }

  return (
    <Div>
      <div className="header mb-2-rem">
        <RiHome6Line size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px" onClick={goBackOrganization}>
          {t('organization')}
        </span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px" onClick={goBack}>
          {t('department')}
        </span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="highlight">{departmentInfo?.attributes?.name}</span>
      </div>

      <img src={backIcon} className="link mb-1-rem" onClick={goBack} />
      <div className="d-flex align-items-center mb-2-rem">
        <div className="text-bold text-30-px mr-8-px">{departmentInfo?.attributes?.name}</div>
        <img src={editIcon} />
      </div>
      <div className="tab-container-department-detail mb-2-rem">
        <div
          className={`tab-item ${tabMenu == 'scope_of_work' ? 'active' : ''}`}
          onClick={() => setTabMenu('scope_of_work')}
        >
          {t('scope_of_work')}
        </div>
        <div
          className={`tab-item ${tabMenu == 'job_position' ? 'active' : ''}`}
          onClick={() => setTabMenu('job_position')}
        >
          {t('job_position')}
        </div>
        <div className={`tab-item ${tabMenu == 'team' ? 'active' : ''}`} onClick={() => setTabMenu('team')}>
          {t('team')}
        </div>
      </div>

      {tabMenu === 'scope_of_work' ? (
        <ScopeOfWork id={id} />
      ) : tabMenu === 'job_position' ? (
        <JobPosition id={id} department_name={departmentInfo?.attributes?.name} />
      ) : (
        <></>
      )}
    </Div>
  )
}

export default DepartmentDetail
