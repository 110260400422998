import * as React from 'react'
import { Input as BaseInput, inputClasses } from '@mui/base'
import { styled } from '@mui/system'

export const Textarea = React.forwardRef(function CustomInput(props, ref) {
  return (
    <BaseInput
      slots={{
        root: RootDiv,
        input: 'input',
        textarea: TextareaElement,
      }}
      {...props}
      multiline
      ref={ref}
    />
  )
})

const RootDiv = styled('div')`
  display: flex;
  max-width: 100%;
`

const TextareaElement = styled('textarea', {
  shouldForwardProp: (prop) => !['ownerState', 'minRows', 'maxRows'].includes(prop.toString()),
})(
  ({ width }) => `
  width: ${width ? width : '100%'};
  max-width: 100%;
  font-family: 'Inter','Noto Sans Thai';
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem;
  padding: 8px 12px;
  border-radius: 8px 8px 0 8px;
  color: var(--Gray-900);
  background: var(--Base-White);
  border: 1px solid var(--Gray-200);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);

  &.${inputClasses.disabled} {
    color: var(--Gray-400);
    background: var(--Gray-50);
    border-color: var(--Gray-200);
  }

  &:not(.${inputClasses.disabled}):hover {
    border: 1px solid var(--Primary-300);
  }

  &.${inputClasses.focused} {
    border-color: var(--Primary-300);
    box-shadow: 0 0 0 3px var(--Primary-200);
  }

  &::placeholder {
    color: var(--Gray-300);
  }

  &:is(.${inputClasses.disabled})::placeholder {
    color: var(--Gray-400);
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
)
