import { useEffect, useState } from 'react'
import styled from 'styled-components'
import iconPlus from '../../../../assets/images/icon-plus.svg'
import Dropdown from '../../../form/Dropdown'
import SearchText from '../../../form/SearchText'
import Button from '../../../form/button/Button'
import SubTaskCreateItem from './SubTaskCreateItem'
import SubTaskList from './SubTaskList'

export default function TaskContentSubTask({
  requestId,
  taskId,
  data,
  onUpdate,
  docId,
  onViewChange,
  onSuccess,
  onSearchFilterChange,
  isRoutine,
}) {
  const tmpDataSearch = {
    searchText: '',
    subTaskStatus: '',
  }
  const [isNewSubtask, setIsNewSubtask] = useState(false)
  const [dataSearch, setDataSearch] = useState(tmpDataSearch)
  const [searchText, setSearchText] = useState('')
  const [subTaskList, setSubTaskList] = useState([])
  const [editIndex, setEditIndex] = useState()
  const [subTaskStatusList, setSubTaskStatusList] = useState([
    {
      text: 'Open',
      value: 'Open',
    },
    {
      text: 'Working',
      value: 'Working',
    },
    {
      text: 'Hold',
      value: 'Hold',
    },
    {
      text: 'Complete',
      value: 'Complete',
    },
    {
      text: 'Requesting for Approve',
      value: 'Requesting for Approve',
    },
    {
      text: 'Approved',
      value: 'Approved',
    },
    {
      text: 'Rejected',
      value: 'Rejected',
    },
    {
      text: 'Reopen',
      value: 'Reopen',
    },
  ])

  useEffect(() => {
    if (data) {
      let tmpSubTaskList = data?.sub_tasks || []
      setSubTaskList([...tmpSubTaskList])
    }
  }, [data])

  useEffect(() => {
    if (onSearchFilterChange) {
      onSearchFilterChange(dataSearch)
    }
  }, [dataSearch])

  const handleClickNewSubTask = () => {
    setEditIndex()
    setIsNewSubtask(true)
    if (onViewChange) {
      onViewChange(true)
    }
  }
  const handleClickBack = () => {
    setIsNewSubtask(false)
    if (onViewChange) {
      onViewChange(false)
    }
  }

  const handleSearchSelected = (value, type) => {
    if (type == 'text-search') {
      setDataSearch({ ...dataSearch, searchText: value })
    } else if (type == 'status') {
      setDataSearch({ ...dataSearch, subTaskStatus: value })
    }
  }
  const handleOnSuccess = () => {
    setIsNewSubtask(false)
    setSubTaskList([])
    if (onSuccess) {
      onSuccess()
    }
  }
  const handleOnClickEdit = (index) => {
    setEditIndex(index)
    setIsNewSubtask(true)
  }
  return (
    <Div>
      {!isNewSubtask && (
        <div className="normal-subtask">
          <div className="wrp-tool mb-1-rem">
            <div className="tool-left">
              <SearchText
                className="search-box"
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onSubmit={(e) => handleSearchSelected(e, 'text-search')}
                onClear={() => {
                  setSearchText('')
                  handleSearchSelected('', 'text-search')
                }}
              />
              <div className="wrp-dropdown">
                <div className="title-dropdown">Status</div>
                <Dropdown
                  optionList={subTaskStatusList}
                  value={dataSearch.subTaskStatus}
                  onChange={(e) => handleSearchSelected(e, 'status')}
                  placeHolder="Status"
                />
              </div>
            </div>
            <div className="tool-right">
              <Button onClick={handleClickNewSubTask} append={<img src={iconPlus} />}>
                New Sub Task
              </Button>
            </div>
          </div>
          <div className="wrp-sub-task-list">
            <SubTaskList
              requestId={requestId}
              dataList={subTaskList}
              onUpdate={onUpdate}
              docId={docId}
              onClickEdit={handleOnClickEdit}
              isRoutine={isRoutine}
              taskId={taskId}
            />
          </div>
        </div>
      )}

      {isNewSubtask && (
        <SubTaskCreateItem
          data={data}
          editIndex={editIndex}
          onClickBack={handleClickBack}
          onSuccess={handleOnSuccess}
          isRoutine={isRoutine}
        />
      )}
    </Div>
  )
}

const Div = styled.div`
  width: 100%;
  height: 100%;

  .normal-subtask {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 30px 0;
  }
  .wrp-sub-task-list {
    flex: 1;
    /* overflow-y: auto; */

    ul {
      margin: 0;
      padding: 0;
    }
    li {
    }
  }
  .wrp-tool {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;

    .tool-left {
      display: flex;
      align-items: flex-end;
    }
  }
  .search-box {
    margin-right: 0.5rem;
  }
  .btn-tool {
    margin-right: 0.5rem;
  }

  .wrp-dropdown {
    .title-dropdown {
      margin-bottom: 6px;
    }
  }
`
