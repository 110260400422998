import { combineReducers } from 'redux'
import { GET_PROJECT_DASHBOARD, GET_PROJECT_MANAGER, GET_PROJECT_DASHBOARD_BY_ID, GET_LOGS } from '../actionTypes'

const initialState = {
  projectDashboardList: [],
  projectDashboardInfo: {},
  projectManagers: [],
  projectLogs: [],
  page: 1,
  pageCount: 1,
  total: 1,
  limit: 10,
  isLoading: false,
  state: '',
}
const projectDashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_PROJECT_DASHBOARD.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_PROJECT_DASHBOARD.REQUEST',
      }
    case GET_PROJECT_DASHBOARD.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        projectDashboardList: data,
        page: meta.pagination.page,
        limit: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
        state: 'GET_PROJECT_DASHBOARD.SUCCESS',
      }
    }
    case GET_PROJECT_DASHBOARD.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_PROJECT_DASHBOARD.FAILURE',
      }
    case GET_PROJECT_DASHBOARD_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_PROJECT_DASHBOARD_BY_ID.REQUEST',
      }
    case GET_PROJECT_DASHBOARD_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        projectDashboardInfo: data,
        isLoading: false,
        state: 'GET_PROJECT_DASHBOARD_BY_ID.SUCCESS',
      }
    }
    case GET_PROJECT_DASHBOARD_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_PROJECT_DASHBOARD_BY_ID.FAILURE',
      }
    case GET_PROJECT_MANAGER.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_PROJECT_MANAGER.REQUEST',
      }
    case GET_PROJECT_MANAGER.SUCCESS:
      const { data } = action
      return {
        ...state,
        projectManagers: data,
        isLoading: false,
        state: 'GET_PROJECT_MANAGER.SUCCESS',
      }
    case GET_PROJECT_MANAGER.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_PROJECT_MANAGER.FAILURE',
      }
    case GET_LOGS.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_LOGS.REQUEST',
      }
    case GET_LOGS.SUCCESS: {
      const { data } = action
      return {
        ...state,
        projectLogs: data,
        isLoading: false,
        state: 'GET_LOGS.SUCCESS',
      }
    }
    case GET_LOGS.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_LOGS.FAILURE',
      }
    default:
      return state
  }
}

export default combineReducers({
  projectDashboardReducer,
})
