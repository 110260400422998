import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import SearchText from '../../components/form/SearchText'
import Table from '../../components/common/Table'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import { LinearProgress } from '@mui/material'

import { BsGraphUpArrow } from 'react-icons/bs'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { LiaTimesSolid } from 'react-icons/lia'

import { getProjectDashboards, getProjectManagers } from '../../redux/actions/projectDashboard'
import { selectProjectDashboard } from '../../redux/selectors'

// style
const Div = styled.div`
  .header {
    display: flex;
    align-items: center;
    color: var(--Gray-600);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    .highlight {
      color: var(--Primary-700);
      font-weight: 600;
    }
  }

  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .mr-8-px {
    margin-right: 8px;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .align-items-center {
    align-items: center;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 12px;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-2rem {
    font-size: 1.5rem;
  }

  .badge {
    padding: 2px 8px;
    align-items: center;
    color: var(--Gray-blue-700);
    background: var(--Gray-blue-50);
    border: 1px solid var(--Gray-blue-200);
    border-radius: 16px;

    &.recruiter {
      color: var(--Purple-700);
      background: var(--Purple-50);
      border: 1px solid var(--Purple-200);
    }
  }

  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid var(--Status-Icon-Stroke);
    color: var(--Status-Icon-Font);
    background: var(--Status-Icon-Bg);

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: var(--Status-Active);

      &.inactive {
        color: var(--Status-Inactive);
      }

      &.draft {
        color: var(--Status-Draft);
      }
    }
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .search-container {
    /* display: grid;
    grid-template-columns: repeat(5, 250px) 120px;
    grid-template-rows: auto;
    align-content: center;
    column-gap: 1rem; */

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    .input-item {
      width: 18%;
      .inputs {
        max-width: 100%;
      }
      .inputs-search {
        width: 100%;
      }
    }
  }

  .content-header {
    /* margin-bottom: 24px; */
    background: var(--Gray-50);
    border-radius: 8px;
    padding: 12px 16px;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Base-White);
      border: 1px solid var(--Gray-200);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error-input {
    border: 1px solid red;
  }

  .side-panel-container {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .side-panel-container-2 {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .create-access-role-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-header {
    color: var(--gray-700);
    font-size: 16px;
    font-weight: 500;
  }

  .permission-header {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .permission-content {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .text-underline {
    text-decoration: underline;
  }
  .text-color-primary {
    color: #7f56d9;
  }
  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .tab-content {
    overflow: auto;
    .view-information-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }
  }

  .share-list-search-container {
    display: grid;
    grid-template-columns: repeat(2, 10rem) 8rem;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--gray-200, #eaecf0);
  }

  .modal-content {
    width: 640px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--Gray-600);
  }

  .change-status-content {
    padding: 20px;
    width: 384px;
  }

  .category-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    height: 500px;

    .category {
      padding: 1rem 2rem;
      border-right: 1px solid var(--Gray-200);
      .category-item {
        display: flex;
        align-items: center;
        padding: 8px;
        border-radius: 8px;
        background: var(--Base-White);
        align-items: center;
        cursor: pointer;

        &.active {
          background: var(--Primary-600);
          color: var(--Base-White);
        }
      }
    }

    .category-view {
      padding: 1rem 2rem;
      .view-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .view-link {
          cursor: pointer;
          color: var(--Primary-700);
          font-weight: 600;
        }
      }
    }
  }

  .search-content {
    padding: 10px;
    border-bottom: 1px solid var(--Gray-200);
  }

  .search-input {
    position: relative;
    color: var(--Gray-500);

    .input-field {
      box-sizing: border-box;
      padding: 10px;
      padding-left: 40px;
      border: none;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .start-icon {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .end-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .preview-button {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }

  .headline {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .error {
    color: var(--Error-600);
  }

  .error-border {
    border: 1px solid var(--Error-300);
  }

  .progress-bar-container {
    display: flex;
    align-items: center;
    width: 100%;
  }
`
const CustomProgress = styled(LinearProgress)`
  width: 100%;
  &.MuiLinearProgress-root {
    background-color: var(--Gray-200);
    height: 8px;
    border-radius: 8px;
  }
  .MuiLinearProgress-bar {
    background-color: var(--Primary-600);
  }
`
// initail value
const initStatusList = [
  { text: 'Open', value: 'Open' },
  { text: 'On Going', value: 'On Going' },
  { text: 'Completed', value: 'Completed' },
  { text: 'Reopen', value: 'Reopen' },
]

function ProjectDashboard() {
  const { t } = useTranslation()
  const projectDashboardColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('project_name'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('project_manager'),
      accessor: 'project_manager',
      disableSortBy: false,
    },
    {
      Header: t('start_date'),
      accessor: 'start_date',
      disableSortBy: false,
    },
    {
      Header: t('end_date'),
      accessor: 'end_date',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: t('progress'),
      accessor: 'progress',
      disableSortBy: false,
    },
  ]
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // store reducer
  const { projectDashboardList, projectManagers, total, page, limit, pageCount } = useSelector(selectProjectDashboard)
  // data
  const [projectDashboards, setProjectDashboard] = useState([])
  // filter
  const [search, setSearch] = useState('')
  const [projectManagerSelected, setProjectManagerSelected] = useState('')
  const [statusSelected, setStatusSelected] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  // dropdown
  const [projectManagerList, setProjectManagerList] = useState()

  // useCallback
  const viewProjectDashboard = useCallback(async (id) => {
    navigate(`/dashboard/project-dashboard/${id}`)
  }, [])
  const fetchData = useCallback((filter) => {
    dispatch(getProjectDashboards(filter))
  }, [])

  // useEffect for request api fetch data
  useEffect(() => {
    // fetch Project managers data
    dispatch(getProjectManagers())
    // fetch Project dashboard data for table
    dispatch(getProjectDashboards())
  }, [dispatch])

  // useEffect for table data
  useEffect(() => {
    const newProjectDashboardList = projectDashboardList.map((v, i) => ({
      ...v,
      no: i + 1,
      name: (
        <div className="text-bold link" onClick={() => viewProjectDashboard(v.id)}>
          {v.name}
        </div>
      ),
      project_manager: `${v.project_manager.first_name} ${v.project_manager.last_name}`,
      progress: (
        <div className="progress-bar-container">
          <CustomProgress className="mr-8-px" variant="determinate" value={v.progress} />
          <span>{v.progress}%</span>
        </div>
      ),
      status: (
        <div className="status-badge">
          <GrStatusGoodSmall className={`status ${v.status == 'Complete' ? 'draft' : ''}`} />
          {v.status}
        </div>
      ),
    }))
    setProjectDashboard(newProjectDashboardList)
  }, [projectDashboardList])

  // useEffect for initilize project manager dropdow list
  useEffect(() => {
    const newProjectManagerList = projectManagers.map((v) => ({
      text: v.name,
      value: v.id,
    }))
    setProjectManagerList(newProjectManagerList)
  }, [projectManagers])

  // useEffect for filter by dropdown and date
  useEffect(() => {
    if (projectManagerSelected !== '' || statusSelected !== '' || startDate !== '' || endDate !== '') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))
      const filter = {
        page: 1,
        pageSize: limit,
        multipleSearch,
        project_manager: projectManagerSelected,
        status: statusSelected,
        start_date: startDate,
        end_date: endDate,
      }
      fetchData(filter)
    }
  }, [projectManagerSelected, statusSelected, startDate, endDate])

  // filter
  const onChangePagination = async (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))
    const filter = {
      page: value.page,
      pageSize: limit,
      multipleSearch,
      project_manager: projectManagerSelected,
      status: statusSelected,
      start_date: startDate,
      end_date: endDate,
    }
    fetchData(filter)
  }
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`filters[$or][${i}][name][$contains]`]: v.name,
      }))

      const filter = {
        page: 1,
        pageSize: limit,
        multipleSearch,
        project_manager: projectManagerSelected,
        status: statusSelected,
        start_date: startDate,
        end_date: endDate,
      }
      fetchData(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][name][$contains]`]: v.name,
    }))

    const filter = {
      page: 1,
      pageSize: limit,
      multipleSearch,
      project_manager: projectManagerSelected,
      status: statusSelected,
      start_date: startDate,
      end_date: endDate,
    }
    fetchData(filter)
    setSearch('')
  }
  const onClearSearch = () => {
    const filter = {
      page: 1,
      pageSize: limit,
      multipleSearch: [],
      project_manager: '',
      status: '',
      start_date: '',
      end_date: '',
    }
    fetchData(filter)
    clearData()
  }
  const clearData = () => {
    setSearch('')
    setProjectManagerSelected('')
    setStatusSelected('')
    setStartDate('')
    setEndDate('')
    setSearchFilters([])
  }

  return (
    <Div>
      <div className="header mb-2-rem">
        <BsGraphUpArrow size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight size={20} className="mr-8-px" />
        <span className="highlight">{t('project_dashboard')}</span>
      </div>
      <div className="mb-1-rem">
        <div className="content-title">{t('project_dashboard')}</div>
        <div>{t('keep_track_of_every_project_progress_on_sight')}</div>
      </div>
      <div className="divider"></div>
      <div className="content-header">
        <div className="search-container mb-1-rem">
          <div className="input-item">
            <div className="input-header">{t('search')}</div>
            <SearchText
              className="inputs-search"
              placeholder={t('search')}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              onSubmit={onSearch}
              onClear={onClearSearch}
            />
          </div>
          <div className="input-item">
            <div className="input-header">{t('project_manager')}</div>
            <Dropdown
              className="inputs"
              placeHolder={t('project_manager')}
              optionList={projectManagerList}
              value={projectManagerSelected}
              onChange={(value) => setProjectManagerSelected(value)}
            />
          </div>

          <div className="input-item">
            <div className="input-header">{t('status')}</div>
            <Dropdown
              className="inputs"
              placeHolder={t('status')}
              optionList={initStatusList}
              value={statusSelected}
              onChange={(value) => setStatusSelected(value)}
            />
          </div>

          <div className="input-item">
            <div className="input-header">{t('start_date')}</div>
            <Date className="inputs" value={startDate} onChange={(value) => setStartDate(value)} />
          </div>

          <div className="input-item">
            <div className="input-header">{t('end_date')}</div>
            <Date className="inputs" value={endDate} onChange={(value) => setEndDate(value)} />
          </div>

          <ButtonOutline className="d-flex justify-content-center" onClick={onClearSearch}>
            {t('clear')}
          </ButtonOutline>
        </div>
        <div className="badge-search-content">
          {searchFilters.map((v) => (
            <div className="badge" key={v.key}>
              <span className="name">{v.name}</span>{' '}
              <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
            </div>
          ))}
        </div>
      </div>
      <div className="table-wrapper">
        <div className="table-header">
          <div className="group">
            <div className="table-title">{t('total_projects')}</div>
            <div className="table-total">
              {total} {t('project')}
            </div>
          </div>

          {/* <div>
            <Button onClick={newApplicationForm} append={<img src={iconPlus} />}>
              New Project
            </Button>
          </div> */}
        </div>
        <Table
          onStateChange={onChangePagination}
          columns={projectDashboardColumn}
          data={projectDashboards}
          page={page}
          pageCount={pageCount}
        />
      </div>
    </Div>
  )
}

export default ProjectDashboard
