import { getAPI, postAPI, putAPI } from '../../utils/api'
import { hideLoading, showLoading } from '../actions/loading'
import {
  CREATE_BUSINESS_DOCUMENT,
  CREATE_CUSTOMER,
  CREATE_PURCHASE,
  CREATE_QUOTATION,
  CREATE_SALE_DOCUMENT,
  CREATE_SELLER,
  EDIT_BUSINESS_DOCUMENT,
  EDIT_PURCHASE_DOC,
  EDIT_SALE_DOCUMENT,
  GET_BUSINESS_DOCUMENT_BY_ID,
  GET_COMPANY_INFO_BY_ID,
  GET_CUSTOMER_LIST,
  GET_DOCUMENT_LIST,
  GET_DOCUMENT_SETTING_BY_COMPANY_ID,
  GET_PURCHASE_BY_ID,
  GET_PURCHASE_LIST,
  GET_QUOTATION_BY_ID,
  GET_SALE_DOCUMENT_BY_ID,
  GET_SELLER_LIST,
  REQUEST_DOCUMENT_APPROVE,
  SEND_EMAIL_DOCUMENT,
  SHARE_LINK_DOCUMENT,
  UPDATE_DOCUMENT_SETTING_BY_COMPANY_ID,
} from '../actionTypes'

export const getBusinessDocumentList = (pagination, filters) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DOCUMENT_LIST.REQUEST })
    const { jwt, company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    let employeeId = -99
    if (Object.keys(employee_info).length > 0) {
      employeeId = employee_info.id
    }
    // dlog(filterPath, "filterPath")
    let filterPath = ''
    if (employeeId) {
      let tmpPath = `filters[creatorid]=${employeeId}`
      filterPath = filterPath + tmpPath
    }
    if (filters?.searchText != '' && filters?.searchText != undefined) {
      let tmpPath = `&filters[search]=${filters?.searchText}`
      filterPath = filterPath + tmpPath
    }
    if (filters?.lastEdit != '' && filters?.lastEdit != undefined) {
      let tmpPath = `&filters[lastedit]=${filters?.lastEdit}`
      filterPath = filterPath + tmpPath
    }
    if (filters?.docType != '' && filters?.docType != undefined) {
      let tmpPath = `&filters[doctype]=${filters?.docType}`
      filterPath = filterPath + tmpPath
    }
    if (company_info?.id) {
      let tmpPath = `&filters[companyid]=${company_info?.id}`
      filterPath = filterPath + tmpPath
    }

    let paginatePath = ''
    if (pagination?.page != '' && pagination?.page != undefined) {
      let tmpPath = `&pagination[page]=${pagination?.page}`
      paginatePath = paginatePath + tmpPath
    }
    if (pagination?.pageSize != '' && pagination?.pageSize != undefined) {
      let tmpPath = `&pagination[pageSize]=${pagination?.pageSize}`
      paginatePath = paginatePath + tmpPath
    }

    const { data, meta } = await getAPI({
      url: `/api/business-docs?` + filterPath + paginatePath,
      headers: headers,
    })
    return dispatch({
      type: GET_DOCUMENT_LIST.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_DOCUMENT_LIST.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Document Data Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getCustomerList = (filters) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_CUSTOMER_LIST.REQUEST })
    const { jwt, company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    let employeeId = -99
    if (Object.keys(employee_info).length > 0) {
      employeeId = employee_info.id
    }

    let filterPath = ''
    if (filters?.customerName != undefined) {
      let tmpPath = `&filters[name]=${filters?.customerName}`
      filterPath = filterPath + tmpPath
    }
    if (company_info) {
      let tmpPath = `&filters[companyid]=${company_info?.id}`
      filterPath = filterPath + tmpPath
    }
    if (filters?.customerId != undefined) {
      let tmpPath = `&filters[customerid]=${filters?.customerId}`
      filterPath = filterPath + tmpPath
    }
    if (filters?.taxId != undefined) {
      let tmpPath = `&filters[taxid]=${filters?.taxId}`
      filterPath = filterPath + tmpPath
    }
    if(filters?.select_id_name){
      let tmpPath = `&filters[customerid]=${filters?.select_id_name}`
      filterPath = filterPath + tmpPath
    }

    const { data } = await getAPI({
      url: `/api/customers?` + filterPath,
      headers: headers,
    }) 
    return dispatch({
      type: GET_CUSTOMER_LIST.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_CUSTOMER_LIST.FAILURE,
      error,
      showAlert: false,
      alertMessage: 'Get Customer List Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getQuotationById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_QUOTATION_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/doc-quotations/${id}`,
      headers: headers,
    })
    
    return dispatch({
      type: GET_QUOTATION_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_QUOTATION_BY_ID.FAILURE,
      error,
      showAlert: false,
      alertMessage: 'Get Quotation Data Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const requestDocumentApprove = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: REQUEST_DOCUMENT_APPROVE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    await postAPI({
      url: `/api/request-approves`,
      headers: headers,
      data,
    })
    return dispatch({
      type: REQUEST_DOCUMENT_APPROVE.SUCCESS,
      showAlert: true,
      alertMessage: 'Request Approve Success',
    })
  } catch (error) {
    return dispatch({
      type: REQUEST_DOCUMENT_APPROVE.FAILURE,
      error,
      showAlert: false,
      alertMessage: 'Request Approve Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createQuotation = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_QUOTATION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const createQuotationRes = await postAPI({
      url: `/api/doc-quotations`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_QUOTATION.SUCCESS,
      data: createQuotationRes.data,
      showAlert: true,
      alertMessage: 'Create Quotation Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_QUOTATION.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const createCustomer = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_CUSTOMER.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const customerRes = await postAPI({
      url: `/api/customers`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_CUSTOMER.SUCCESS,
      data: customerRes.data,
      showAlert: true,
      alertMessage: 'Create Customer Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_CUSTOMER.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}
export const editCustomer = (data, id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_CUSTOMER.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const customerRes = await putAPI({
      url: `/api/customers/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_CUSTOMER.SUCCESS,
      data: customerRes.data,
      showAlert: true,
      alertMessage: 'Edit Customer Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_CUSTOMER.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

// ---- PURCHASE ----
export const getPurchaseList = (pagination, filters) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PURCHASE_LIST.REQUEST })
    const { jwt, company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    let employeeId = -99
    if (Object.keys(employee_info).length > 0) {
      employeeId = employee_info.id
    }

    let filterPath = ''
    if (filters?.searchText != '' && filters?.searchText != undefined) {
      let tmpPath = `&filters[search]=${filters?.searchText}`
      filterPath = filterPath + tmpPath
    }
    if (filters?.lastEdit != '' && filters?.lastEdit != undefined) {
      let tmpPath = `&filters[lastedit]=${filters?.lastEdit}`
      filterPath = filterPath + tmpPath
    }
    if (filters?.docType != '' && filters?.docType != undefined) {
      let tmpPath = `&filters[doctype]=${filters?.docType}`
      filterPath = filterPath + tmpPath
    }

    let paginatePath = ''
    if (pagination?.page != '' && pagination?.page != undefined) {
      let tmpPath = `&pagination[page]=${pagination?.page}`
      paginatePath = paginatePath + tmpPath
    }
    if (pagination?.pageSize != '' && pagination?.pageSize != undefined) {
      let tmpPath = `&pagination[pageSize]=${pagination?.pageSize}`
      paginatePath = paginatePath + tmpPath
    }



    const { data, meta } = await getAPI({
      url:
        `/api/doc-buys?filters[creatorid]=${employeeId}&filters[companyid]=${company_info?.id}` +
        filterPath +
        paginatePath,
      headers: headers,
    })
    return dispatch({
      type: GET_PURCHASE_LIST.SUCCESS,
      data,
      meta,
    })
  } catch (error) {
    return dispatch({
      type: GET_PURCHASE_LIST.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Pucharse List Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createPurchase = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_PURCHASE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const creationRes = await postAPI({
      url: `/api/business-docs`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_PURCHASE.SUCCESS,
      data: creationRes.data,
      showAlert: true,
      alertMessage: 'Create Purchase Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_PURCHASE.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getPurchaseById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PURCHASE_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/doc-buys/${id}`,
      headers: headers,
    })
    return dispatch({
      type: GET_PURCHASE_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_PURCHASE_BY_ID.FAILURE,
      error,
      showAlert: false,
      alertMessage: 'Get Purchase Data Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const editPurchaseDoc = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_PURCHASE_DOC.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const dataRes = await putAPI({
      url: `/api/business-docs/${id}`,
      data,
      headers: headers,
    })

    let tmpData = dataRes.data
    return dispatch({
      type: EDIT_PURCHASE_DOC.SUCCESS,
      data: tmpData,
      showAlert: true,
      alertMessage: 'Edit Purchase Doc Successful',
    })
  } catch (err) {
    return dispatch({ type: EDIT_PURCHASE_DOC.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getCompanyInfoById = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_COMPANY_INFO_BY_ID.REQUEST })
    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/companies/${company_info?.id}?populate=*`,
      headers: headers,
    })
    return dispatch({
      type: GET_COMPANY_INFO_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_COMPANY_INFO_BY_ID.FAILURE,
      error,
      showAlert: false,
      alertMessage: 'Get Company Info Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getSellerList = (filters) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SELLER_LIST.REQUEST })
    const { jwt, company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    let employeeId = -99
    if (Object.keys(employee_info).length > 0) {
      employeeId = employee_info.id
    }


    let filterPath = ''
    if (filters?.sellerName != undefined) {
      let tmpPath = `&filters[name]=${filters?.sellerName}`
      filterPath = filterPath + tmpPath
    }
    if (filters?.sellerId != undefined) {
      let tmpPath = `&filters[sellerid]=${filters?.sellerId}`
      filterPath = filterPath + tmpPath
    }

    const { data } = await getAPI({
      url: `/api/sellers?` + filterPath + `&filters[companyid]=${company_info?.id}`,
      headers: headers,
    })
    return dispatch({
      type: GET_SELLER_LIST.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_SELLER_LIST.FAILURE,
      error,
      showAlert: false,
      alertMessage: 'Get Sellers List Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createSeller = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_SELLER.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const sellerRes = await postAPI({
      url: `/api/sellers`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_SELLER.SUCCESS,
      data: sellerRes.data,
      showAlert: true,
      alertMessage: 'Create Seller Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_SELLER.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDocumentSettingByCompanyId = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DOCUMENT_SETTING_BY_COMPANY_ID.REQUEST })
    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/business-doc-settings/company/${company_info.id}`,
      headers: headers,
    })
    return dispatch({
      type: GET_DOCUMENT_SETTING_BY_COMPANY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_DOCUMENT_SETTING_BY_COMPANY_ID.FAILURE,
      error,
      showAlert: false,
      alertMessage: 'Get Document Setting Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateDocSettingByCompanyId = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_DOCUMENT_SETTING_BY_COMPANY_ID.REQUEST })
    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const updateDocumentSettingRes = await putAPI({
      url: `/api/business-doc-settings/company/${company_info.id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: UPDATE_DOCUMENT_SETTING_BY_COMPANY_ID.SUCCESS,
      data: updateDocumentSettingRes,
      showAlert: true,
      alertMessage: 'Update Document Setting Successful',
    })
  } catch (err) {
    return dispatch({ type: UPDATE_DOCUMENT_SETTING_BY_COMPANY_ID.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getSaleDocumentById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SALE_DOCUMENT_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }


    const { data } = await getAPI({
      url: `/api/business-docs/${id}`,
      headers: headers,
    })
    

    return dispatch({
      type: GET_SALE_DOCUMENT_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_SALE_DOCUMENT_BY_ID.FAILURE,
      error,
      showAlert: false,
      alertMessage: 'Get Sale Document By Id Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const editSaleDocument = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_SALE_DOCUMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const dataRes = await putAPI({
      url: `/api/business-docs/${id}`,
      data,
      headers: headers,
    })

    let tmpData = dataRes.data
    return dispatch({
      type: EDIT_SALE_DOCUMENT.SUCCESS,
      data: tmpData,
      showAlert: true,
      alertMessage: 'Edit Sale Doc Successful',
    })
  } catch (err) {
    return dispatch({ type: EDIT_SALE_DOCUMENT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const createSaleDocument = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_SALE_DOCUMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const createDocRes = await postAPI({
      url: `/api/business-docs`,
      data,
      headers: headers,
    })

    return dispatch({
      type: CREATE_SALE_DOCUMENT.SUCCESS,
      data: createDocRes.data,
      showAlert: true,
      alertMessage: 'Create Document Successful',
    })
  } catch (err) {
    return dispatch({ type: CREATE_SALE_DOCUMENT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getShareDocumentUrl = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: SHARE_LINK_DOCUMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/business-docs/sharelink/${id}`,
      headers: headers,
    })
    return dispatch({
      type: SHARE_LINK_DOCUMENT.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: SHARE_LINK_DOCUMENT.FAILURE,
      error,
      showAlert: false,
      alertMessage: 'Share Document Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const sendEmailDocument = (data, id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: SEND_EMAIL_DOCUMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const shareEmailRes = await postAPI({
      url: `/api/business-docs/sendemail/${id}`,
      data,
      headers: headers,
    })

    return dispatch({
      type: SEND_EMAIL_DOCUMENT.SUCCESS,
      data: shareEmailRes,
      showAlert: true,
      alertMessage: 'Send Email Customer Successful',
    })
  } catch (err) {
    return dispatch({ type: SEND_EMAIL_DOCUMENT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const createBusinessDocument = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_BUSINESS_DOCUMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    let tmpReq = { ...data }
    delete tmpReq.type

    const dataRes = await postAPI({
      url: `/api/business-docs`,
      data: tmpReq,
      headers: headers,
    })

    return dispatch({
      type: CREATE_BUSINESS_DOCUMENT.SUCCESS,
      data: dataRes.data,
      showAlert: true,
      alertMessage: 'Create Business Document Successful',
    })
  } catch (err) {

    return dispatch({ type: CREATE_BUSINESS_DOCUMENT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}
export const editBusinessDocument = (data, id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: EDIT_BUSINESS_DOCUMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    let tmpReq = { ...data }
    delete tmpReq.type

    const dataRes = await putAPI({
      url: `/api/business-docs/${id}`,
      data: tmpReq,
      headers: headers,
    })

    return dispatch({
      type: EDIT_BUSINESS_DOCUMENT.SUCCESS,
      data: dataRes.data,
      showAlert: true,
      alertMessage: 'Edit Business Document Successful',
    })
  } catch (err) {

    return dispatch({ type: EDIT_BUSINESS_DOCUMENT.FAILURE, error: err })
  } finally {
    dispatch(hideLoading())
  }
}

export const getBusinessDocById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_BUSINESS_DOCUMENT_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/business-docs/${id}`,
      headers: headers,
    })
    return dispatch({
      type: GET_BUSINESS_DOCUMENT_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_BUSINESS_DOCUMENT_BY_ID.FAILURE,
      error,
      showAlert: false,
      alertMessage: 'Get Sale Document By Id Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}
