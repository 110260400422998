import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import Button from '../../form/button/Button'
import Dropdown from '../../form/Dropdown'
import AutoComplete from '../../form/AutoComplete'
import Date from '../../form/Date'
import TextField from '../../form/TextField'
import FileUploadProgress2 from '../../form/FileUploadProgress2'
import ButtonOutline from '../../form/button/ButtonOutline'
import DialogConfirm from '../../dialog/DialogConfirm'
import DialogFail from '../../dialog/DialogFail'

import { LiaTimesSolid } from 'react-icons/lia'
import { PiWarningCircleBold } from 'react-icons/pi'
import confirmIcon from '../../../assets/images/confirm-icon.png'
import failIcon from '../../../assets/images/fail-icon.png'

import { selectEmployeeProfile, selectMaster } from '../../../redux/selectors'
import { getProvinces, getDistricts, getSubDistricts, getNationalities } from '../../../redux/actions/master'
import { requestEditEmployeeProfileInfo } from '../../../redux/actions/employeeProfile'

const Div = styled.div`
  padding: 2rem;

  .mr-12-px {
    margin-right: 12px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }

  .icon {
    cursor: pointer;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
  margin-bottom: 2em;
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-between {
    justify-content: space-between;
  }
  &.align-item-center {
    align-items: center;
  }
`
const HeaderContainer = styled.header`
  .heading-modal {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
  .sub-heading-modal {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`
const WarningContainer = styled.div`
  color: var(--warning-700);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;

  border-radius: 0.75rem;
  border: 1px solid var(--warning-300);
  background: var(--warning-25);

  padding: 1rem;

  .icon-warning-container {
    margin-right: 1rem;
  }

  .warning-message-container {
    .headline-warning {
      font-weight: 600;
    }
  }
`
const InputSection = styled.section`
  p {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .highlight {
    color: var(--Primary-700);
  }
`
const InputContainer = styled.div`
  &.width-23 {
    width: 23%;
  }
  &.width-32 {
    width: 32%;
  }
  &.width-100 {
    width: 100%;
  }
  .width-100 {
    width: 100%;
  }
  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.375rem;
  }
  .error-input {
    border: 1px solid var(--Error-300);
  }
  .error {
    color: var(--Error-500);
  }
`
const FooterFig = styled.div``

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}
const multiple = false
const error = false
const disabled = false

const initMessageModal = {
  headline: '',
  message: '',
}

const initTitleEn = [
  {
    text: 'Mr.',
    value: 'Mr.',
  },
  {
    text: 'Mrs.',
    value: 'Mrs.',
  },
  {
    text: 'Miss.',
    value: 'Miss.',
  },
]
const initTitleTh = [
  {
    text: 'นาย',
    value: 'นาย',
  },
  {
    text: 'นางสาว',
    value: 'นางสาว',
  },
  {
    text: 'นาง',
    value: 'นาง',
  },
]

const initForm = {
  title: '',
  title_th: '',
  first_name: '',
  last_name: '',
  first_name_th: '',
  last_name_th: '',
  address: '',
  thai_province: '',
  thai_amphure: '',
  postcode: '',
  thai_tambon: '',
  nationality: '',
  identification_no: '',
  birth_date: '',
  passport_no: '',
  attach_file: null,
}
function EditBasicInfo({ closeModal }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const lang = localStorage.getItem('lang')
  const { employeeProfileInfo } = useSelector(selectEmployeeProfile)
  const { provinces, districts, subdistricts, nationalities } = useSelector(selectMaster)
  // form
  const [form, setForm] = useState(initForm)
  const [initialForm, setInitialForm] = useState(initForm)
  // dropdown list
  const [provinceList, setProvinceList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [subDistrictList, setSubDistrictList] = useState([])
  const [nationalityList, setNationalityList] = useState([])
  // file
  const [file, setFile] = useState([])
  const [acceptTypes, setAcceptTypes] = useState([])
  const [mimeTypes, setMimeTypes] = useState([])
  // dialog
  const [confirmEditModal, setConfirmEditModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  // message dialog
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)

  const requestProvinces = useCallback(() => {
    dispatch(getProvinces())
  }, [dispatch])
  const requestDistricts = useCallback(
    (provinceId) => {
      dispatch(getDistricts(provinceId))
    },
    [dispatch]
  )
  const requestSubDistricts = useCallback(
    (districtId) => {
      dispatch(getSubDistricts(districtId))
    },
    [dispatch]
  )
  const requestNationalities = useCallback(() => {
    dispatch(getNationalities())
  }, [dispatch])

  useEffect(() => {
    initFileType()
    getMimeTypes()
    requestProvinces()
    requestNationalities()
  }, [])

  useEffect(() => {
    const {
      title,
      first_name,
      last_name,
      first_name_th,
      last_name_th,
      address,
      thai_province,
      thai_amphure,
      thai_tambon,
      nationality,
      identification_no,
      birth_date,
      passport_no,
    } = employeeProfileInfo
    const initForm = {
      title,
      title_th: title === 'Mr.' ? 'นาย' : title === 'Mrs.' ? 'นาง' : 'นางสาว',
      first_name,
      last_name,
      first_name_th,
      last_name_th,
      address,
      thai_province: thai_province.id,
      thai_amphure: thai_amphure.id,
      thai_tambon: thai_tambon.id,
      nationality: nationality.id,
      identification_no,
      birth_date,
      passport_no,
    }
    setForm(initForm)
    setInitialForm(initForm)
    requestDistricts(thai_province.id)
    requestSubDistricts(thai_amphure.id)
  }, [employeeProfileInfo])

  // init dropdown list data
  useEffect(() => {
    const provinceList = provinces.map((v) => ({
      text: lang === 'en' ? v.attributes.name_en : v.attributes.name_th,
      value: v.id,
    }))
    setProvinceList(provinceList)
  }, [provinces])
  useEffect(() => {
    const districtList = districts.map((v) => ({
      text: lang === 'en' ? v.attributes.name_en : v.attributes.name_th,
      value: v.id,
    }))
    setDistrictList(districtList)
  }, [districts])
  useEffect(() => {
    const subDistrictList = subdistricts.map((v) => ({
      text: lang === 'en' ? v.attributes.name_en : v.attributes.name_th,
      value: v.id,
      postcode: v.attributes.zip_code,
    }))
    setSubDistrictList(subDistrictList)
    setForm((prev) => ({
      ...prev,
      postcode: subDistrictList[0]?.postcode,
    }))
  }, [subdistricts])
  useEffect(() => {
    const nationalityList = nationalities.map((v) => ({
      text: v.name,
      value: v.id,
    }))
    setNationalityList(nationalityList)
  }, [nationalities])

  // file
  const handleAddFile = async (file_id) => {
    setForm((prev) => ({
      ...prev,
      attach_file: file_id,
    }))
  }
  const onDeleteFile = (id) => {
    setFile(file.filter((v) => v.id !== id))
    setForm((prev) => ({
      ...prev,
      attach_file: null,
    }))
  }
  const initFileType = () => {
    const allowedTypes = []
    const acceptType = allowedTypes.reduce((pre, cur) => {
      return {
        ...pre,
        ...acceptList[cur],
      }
    }, {})
    setAcceptTypes(acceptType)
  }
  const getMimeTypes = () => {
    const categoryObjects = Object.values(acceptList)
    const mimeTypes = categoryObjects.flatMap((category) => Object.keys(category))

    setMimeTypes(mimeTypes)
  }

  // modal
  const openConfirmModal = () => {
    if (file.length > 0) {
      setConfirmEditModal(true)
    } else {
      setFailMessageModal({
        headline: 'File Required!',
        message: `Please attach the necessary evidence to support your edit request. This step is essential for verification purposes.`,
      })
      setFailModal(true)
    }
  }

  // onChangeForm
  const onChangeForm = (event) => {
    const { value, name } = event.target
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeDropdown = (name, value) => {
    if (name === 'title_th') {
      setForm((prev) => ({
        ...prev,
        [name]: value,
        title: value === 'นาย' ? 'Mr.' : value === 'นางสาว' ? 'Miss.' : 'Mrs.',
      }))
    } else {
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }
  const onChangeProvince = (value) => {
    setForm((prev) => ({
      ...prev,
      thai_province: value,
      thai_amphure: '',
      thai_tambon: '',
      postcode: '',
    }))
    requestDistricts(value)
    setSubDistrictList([])
  }
  const onChangeDistrict = (value) => {
    setForm((prev) => ({
      ...prev,
      thai_amphure: value,
      thai_tambon: '',
      postcode: '',
    }))
    requestSubDistricts(value)
  }
  const onChangeSubDistrict = (value) => {
    const matchSubDistrict = subDistrictList.find((v) => v.value == value)
    setForm((prev) => ({
      ...prev,
      thai_tambon: value,
      postcode: matchSubDistrict?.postcode,
    }))
  }
  const onChangeDate = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  // submit
  const submit = () => {
    const { employee_info } = JSON.parse(localStorage.getItem('admin'))
    const request_details = comparePropertyChange()
    const request = {
      requestor: employee_info.id,
      employee_requestted: employeeProfileInfo?.id,
      attach_file: file[0].id,
      request_details,
    }

    dispatch(requestEditEmployeeProfileInfo(request))
    setConfirmEditModal(false)
    closeModal()
  }

  const comparePropertyChange = () => {
    let requestDetails = []
    for (let [key, value] of Object.entries(form)) {
      if (key === 'attach_file' || key === 'postcode' || key === 'title_th') continue
      if (value !== initialForm[key]) {
        const requestEdit = {
          field_name: key,
          field_value: value,
        }
        requestDetails.push(requestEdit)
      }
    }

    return requestDetails
  }

  return (
    <Div>
      <FlexContainer className="justify-content-between">
        <HeaderContainer>
          <h2 className="heading-modal">Request Personal Information Edit</h2>
          <p className="sub-heading-modal">
            Please fill in the details you'd like to edit and attach the required evidence for verification.
          </p>
        </HeaderContainer>
        <LiaTimesSolid className="icon" size={20} onClick={closeModal} />
      </FlexContainer>

      <WarningContainer>
        <FlexContainer>
          <div className="icon-warning-container">
            <PiWarningCircleBold size={25} />
          </div>
          <div className="warning-message-container">
            <div className="headline-warning">Personal Details Verification</div>
            <div>
              For changes to certain personal details, please upload a clear and legible copy of your ID card. If you're
              updating your passport number, please include relevant pages of your passport as well. These documents are
              crucial for the verification process. The Human Resources Department will review the provided information
              and, upon validation, approve the requested amendments.
            </div>
          </div>
        </FlexContainer>
      </WarningContainer>

      <InputSection>
        <h3>Basic information</h3>
        <Divider />
        <FlexContainer className="justify-content-between mb-24-px">
          <InputContainer className="width-32">
            <div className="input-header">Title (TH)</div>
            <Dropdown
              className="width-100"
              optionList={initTitleTh}
              value={form?.title_th}
              onChange={(value) => onChangeDropdown('title_th', value)}
            />
          </InputContainer>
          <InputContainer className="width-32">
            <div className="input-header">First name (TH)</div>
            <TextField className="width-100" name="first_name_th" value={form?.first_name_th} onChange={onChangeForm} />
          </InputContainer>
          <InputContainer className="width-32">
            <div className="input-header">Last name (TH)</div>
            <TextField className="width-100" name="last_name_th" value={form?.last_name_th} onChange={onChangeForm} />
          </InputContainer>
        </FlexContainer>
        <FlexContainer className="justify-content-between mb-24-px">
          <InputContainer className="width-32">
            <div className="input-header">Title (EN)</div>
            <Dropdown className="width-100" optionList={initTitleEn} value={form?.title} disabled={true} />
          </InputContainer>
          <InputContainer className="width-32">
            <div className="input-header">First name (EN)</div>
            <TextField className="width-100" name="first_name" value={form?.first_name} onChange={onChangeForm} />
          </InputContainer>
          <InputContainer className="width-32">
            <div className="input-header">Last name (EN)</div>
            <TextField className="width-100" name="last_name" value={form?.last_name} onChange={onChangeForm} />
          </InputContainer>
        </FlexContainer>
        <FlexContainer className="justify-content-between mb-24-px">
          <InputContainer className="width-100">
            <div className="input-header">Address</div>
            <TextField className="width-100" name="address" value={form?.address} onChange={onChangeForm} />
          </InputContainer>
        </FlexContainer>
        <FlexContainer className="justify-content-between mb-24-px">
          <InputContainer className="width-23">
            <div className="input-header">Province</div>
            <AutoComplete
              className="width-100"
              optionList={provinceList}
              value={form?.thai_province}
              onChange={onChangeProvince}
            />
          </InputContainer>
          <InputContainer className="width-23">
            <div className="input-header">District</div>
            <AutoComplete
              className="width-100"
              optionList={districtList}
              value={form?.thai_amphure}
              onChange={onChangeDistrict}
            />
          </InputContainer>
          <InputContainer className="width-23">
            <div className="input-header">Sub-district</div>
            <AutoComplete
              className="width-100"
              optionList={subDistrictList}
              value={form?.thai_tambon}
              onChange={onChangeSubDistrict}
            />
          </InputContainer>
          <InputContainer className="width-23">
            <div className="input-header">Postcode</div>
            <TextField className="width-100" disabled={true} value={form?.postcode} />
          </InputContainer>
        </FlexContainer>
      </InputSection>

      <InputSection>
        <h3>Citizenship Information</h3>
        <Divider />
        <FlexContainer className="justify-content-between mb-24-px">
          <InputContainer className="width-32">
            <div className="input-header">Nationality</div>
            <AutoComplete
              className="width-100"
              optionList={nationalityList}
              value={form?.nationality}
              onChange={(value) => onChangeDropdown('nationality', value)}
            />
          </InputContainer>
          <InputContainer className="width-32">
            <div className="input-header">Identification no.</div>
            <TextField
              className="width-100"
              name="identification_no"
              value={form?.identification_no}
              onChange={onChangeForm}
            />
          </InputContainer>
          <InputContainer className="width-32">
            <div className="input-header">Date of Birth</div>
            <Date
              className="width-100"
              value={form?.birth_date}
              onChange={(value) => onChangeDate('birth_date', value)}
            />
          </InputContainer>
        </FlexContainer>
        <FlexContainer className="justify-content-between mb-24-px">
          <InputContainer className="width-32">
            <div className="input-header">Passport no.</div>
            <TextField className="width-100" name="passport_no" value={form?.passport_no} onChange={onChangeForm} />
          </InputContainer>
          {/* <InputContainer className="width-32">
            <div className="input-header">Passport expire date</div>
            <Date className="width-100" disabled={true} />
          </InputContainer> */}
          <InputContainer className="width-32"></InputContainer>
          <InputContainer className="width-32"></InputContainer>
        </FlexContainer>
      </InputSection>

      <InputSection>
        <h3>Upload Verification Documents</h3>
        <Divider />
        <p>
          If you are making changes to your personal details, please provide a clear and legible copy of your ID card
          for verification. If you're updating your passport number, you must also attach relevant pages of your
          passport. This step is essential to ensure the accuracy and security of your information. Click on the
          'Upload' button below, and select the appropriate files from your device. Ensure the images are clear and all
          details are visible
        </p>
        <p className="highlight">*If there is more than one copy, combine them into one file.</p>

        <FileUploadProgress2
          className="upload-container"
          multiple={multiple}
          accept={acceptTypes}
          mimeTypes={mimeTypes}
          file={file?.map((v) => ({ ...v, url: `${v.url}` }))}
          onAddFile={handleAddFile}
          onDeleteFile={onDeleteFile}
          error={error}
          disabled={disabled}
          FileListData={file}
          setFileListData={setFile}
        />
      </InputSection>

      <FooterFig>
        <Divider />
        <FlexContainer className="justify-content-end">
          <ButtonOutline className="mr-12-px" onClick={closeModal}>
            Cancel
          </ButtonOutline>
          <Button onClick={openConfirmModal}>Submit Request</Button>
        </FlexContainer>
      </FooterFig>

      {/* Dialog */}
      <DialogConfirm
        open={confirmEditModal}
        onClose={() => setConfirmEditModal(false)}
        onSubmit={submit}
        icon={confirmIcon}
        title={'Confirm Edit Request'}
        description={`You're about to submit a request to edit your details. Ensure all changes are accurate before proceeding. Do you wish to continue?`}
        textNo={t('cancel')}
        textYes={t('confirm')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default EditBasicInfo
