import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import Card from '../../common/Card'
import CollapseMenu from '../../common/CollapseMenu'

import { PiWarningCircleBold } from 'react-icons/pi'
import alertIcon from '../../../assets/images/alert-icon.png'
import standardIcon from '../../../assets/images/standard-icon.png'

const FlexContainer = styled.div`
  display: flex;

  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-center {
    align-items: center;
  }

  .w-30 {
    width: 30%;
  }
`
const TaskContent = styled.div`
  .task-heading-field {
    color: var(--Gray-900);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
  .task-status {
    color: var(--Gray-900);
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
  }
  .status-icon {
    width: 30px;
    height: 30px;
  }
  .warning-icon {
    cursor: pointer;
    color: var(--warning-600);
  }
  .text-under-pie-chart {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .text-value {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;

    .bold {
      color: var(--Gray-900);
      font-size: 18px;
      font-weight: 600;
    }
    .green {
      color: var(--success-600);
      font-size: 18px;
      font-weight: 600;
    }
    .red {
      color: var(--Error-700);
      font-size: 18px;
      font-weight: 600;
    }
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`

function CardTaskData2({ taskData, ...props }) {
  const list = [
    {
      text: 'Action 1',
      action: () => alert('action 1 something'),
    },
    {
      text: 'Action 2',
      action: () => alert('action 2 something'),
    },
  ]
  const [typeRange, setTypeRange] = useState('standard')

  useEffect(() => {
    if (taskData.value <= taskData.over && taskData.value >= taskData.under) setTypeRange('standard')
    else if (taskData.value > taskData.over) setTypeRange('over')
    else if (taskData.value < taskData.under) setTypeRange('under')
  }, [taskData])

  return (
    <Card {...props}>
      <TaskContent>
        <FlexContainer className="justify-content-space-between align-items-center mb-1-rem">
          <img className="status-icon" src={`${typeRange === 'standard' ? standardIcon : alertIcon}`} />
          <h3 className="task-heading-field">{taskData.task_name}</h3>
          {typeRange === 'standard' ? (
            <CollapseMenu list={list} />
          ) : (
            <PiWarningCircleBold className="warning-icon" size={20} />
          )}
        </FlexContainer>

        <div className="task-status">{typeRange === 'standard' ? 'ปกติ' : 'ผิดปกติ'}</div>

        <Divider className="mb-1-rem" />
        <FlexContainer className="text-value justify-content-space-between">
          <div className="w-30">Over</div>
          <div className="bold">
            {`>`} {taskData.over}
          </div>
          <div>Unit</div>
        </FlexContainer>
        <FlexContainer className="text-value justify-content-space-between">
          <div className="w-30">Standard</div>
          <div className="bold">{taskData.standard}</div>
          <div>Unit</div>
        </FlexContainer>
        <FlexContainer className="text-value justify-content-space-between">
          <div className="w-30">Under</div>
          <div className="bold">
            {`<`} {taskData.under}
          </div>
          <div>Unit</div>
        </FlexContainer>
        <FlexContainer className="text-value justify-content-space-between">
          <div className="w-30">Actual</div>
          <div className={`${typeRange === 'standard' ? 'green' : 'red'}`}>{taskData.value}</div>
          <div>Unit</div>
        </FlexContainer>
      </TaskContent>
    </Card>
  )
}

export default CardTaskData2
