import { styled } from '@mui/system'

import iconSearch01 from 'assets/images/icon-search-01.svg'
import Typography from 'components/common/Typography'

const Empty = ({ title, description, icon = iconSearch01 }) => {
  return (
    <StyledEmpty>
      {icon && <img src={icon} width={48} height={48} alt="icon_image" />}

      <Typography variant="emptyTitle">{title}</Typography>

      <Typography variant="emptyText">{description}</Typography>
    </StyledEmpty>
  )
}

export default Empty

const StyledEmpty = styled('div')`
  display: flex;
  flex-direction: column;
  /* min-height: fit-content; */
  height: 100%;
  margin: auto;
  padding: 4rem 0;

  justify-content: center;
  align-items: center;

  & img {
    margin-bottom: 1rem;
  }
`
