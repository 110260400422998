import { Alert, AlertTitle } from '@mui/material'
import { styled } from '@mui/system'
import dayjs from 'dayjs'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Empty from 'components/common/Empty'
import Typography from 'components/common/Typography'
import { SWTimelinePreview } from 'components/feature/operation-process'
import DynamicForm from 'components/form2/dynamic-form'
import { CHAIN_LINK } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { addMinutes } from 'utils/timeUtils'

const langLocale = 'th'

const Tabs = ({ onChange, items = [], defaultTab }) => {
  const [activeTab, setActiveTab] = useState(defaultTab)

  const tabItems = items

  useEffect(() => {
    onChange && onChange(activeTab)
  }, [activeTab])

  return (
    <TabsWrapper>
      {tabItems.map((tab, index) => (
        <div
          key={tab.id}
          className={`tab-item ${activeTab === tab.id ? 'active' : ''}`}
          onClick={() => setActiveTab(tab.id)}
        >
          {`(${index + 1}).`} {tab.label} {tab.count ? `(${tab.count})` : ''}
        </div>
      ))}
    </TabsWrapper>
  )
}

const EditCL = forwardRef(function EditCL({ onSubmit, chainLinkId }, ref) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)
  const [allowEdit, setAllowEdit] = useState(false)
  const [currentChain, setCurrentChain] = useState(null)

  const { chainLink: clActions, extra: extraActions } = opActions()
  const { getExtra } = extraActions()
  const { getCL } = clActions()
  const { clTemplates, clTemplates_pagination, tags } = opProcSelector

  const handleTabChange = (tabId) => {
    setCurrentChain(tabId)
  }

  const fetchCLByID = async (clId) => {
    return dispatch(getCL().byId(clId))
  }

  const fetchTags = useCallback(
    async (filters) => {
      dispatch(getExtra().tags({ filters }))
    },
    [dispatch]
  )

  const fetchCLTemplates = useCallback(
    async (payload) => {
      dispatch(getExtra().clTemplates(payload))
    },
    [dispatch]
  )

  const defaultValues = async () => {
    await fetchTags()
    await fetchCLTemplates()

    const response = await fetchCLByID(chainLinkId)
    const data = response?.data || {}

    if (data?.status === CHAIN_LINK.STATUS.Open || data?.status === CHAIN_LINK.STATUS.InQueue) {
      setAllowEdit(true)
    }

    const defaultValues = {
      ...data,
      'chain_link_temp.id': data?.chain_link_temp?.id,
      start_date: dayjs(data?.start_date).locale(langLocale).format(),
      start_time: dayjs(data?.start_date).locale(langLocale).format(),
    }

    return defaultValues
  }

  const methods = useForm({ defaultValues })

  const watchCLTemp = methods.watch('chain_link_temp.id')
  const watchSPs = methods.watch('sps') || []
  const workingTimes = methods.watch('company.working_times')

  const calculateDueDate = (sps = [], start_date, working_times = workingTimes) => {
    let currentDate = dayjs(start_date).locale(langLocale).set('second', 0).set('millisecond', 0)

    if (sps.length > 0) {
      sps.forEach((sp, spIndex) => {
        const sws = sp?.sws || []

        sws.forEach((sw, swIndex) => {
          const sw_hour = sw?.hr || 0
          const sw_minute = sw?.min || 0
          const sw_estimated_time = sw_hour * 60 + sw_minute

          currentDate = addMinutes(currentDate, sw_estimated_time, working_times)

          sw.due_date = dayjs(currentDate).locale(langLocale).set({ second: 0, millisecond: 0 }).format()

          const logData = [
            {
              type: 'SW',
              hours: sw_hour,
              minutes: sw_minute,
              due_date: dayjs(sw.due_date).format('MMM DD HH:mm'),
            },
          ]

          if (sw?.reviewers && Array.isArray(sw.reviewers) && sw.reviewers.length > 0) {
            sw.reviewers.forEach((reviewer, reviewerIndex) => {
              const reviewer_hour = reviewer?.hr || 0
              const reviewer_minute = reviewer?.min || 0
              const reviewer_estimated_time = reviewer_hour * 60 + reviewer_minute

              currentDate = addMinutes(currentDate, reviewer_estimated_time, working_times)

              reviewer.due_date = dayjs(currentDate).locale(langLocale).format()

              logData.push({
                type: `Reviewer [${reviewerIndex}]`,
                hours: reviewer_hour,
                minutes: reviewer_minute,
                due_date: dayjs(reviewer.due_date).format('MMM DD HH:mm'),
              })
            })
          }

          if (sw?.approver && sw.approver !== null) {
            const approver_hour = sw.approver?.hr || 0
            const approver_minute = sw.approver?.min || 0
            const approver_estimated_time = approver_hour * 60 + approver_minute

            currentDate = addMinutes(currentDate, approver_estimated_time, working_times)

            sw.approver.due_date = dayjs(currentDate).locale(langLocale).format()

            logData.push({
              type: 'Approver',
              hours: approver_hour,
              minutes: approver_minute,
              due_date: dayjs(sw.approver.due_date).format('MMM DD HH:mm'),
            })
          }

          // console.table(logData)
        })
      })
      return sps
    }
    return []
  }

  const formSchema = {
    properties: {
      'section-1': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('detail')}</Typography>
            <Typography variant="sectionText">{t('detail_chain_link_desc')}</Typography>
          </HeaderSection>
        ),
      },
      'divider-1': { type: 'divider' },
      ['chain_link_temp.id']: {
        type: 'controlled-select',
        options: clTemplates?.map((spTemp) => ({ value: spTemp.id, label: spTemp.name })),
        label: t('chain_link_template'),
        placeholder: t('select_chain_link_template'),
        props: {
          disabled: true,
        },
      },
      ['name']: {
        type: 'text',
        label: t('chain_link_name'),
        placeholder: t('enter_chain_link_name'),
        validator: {
          required: true,
          message: 'This field is required',
          minLength: 1,
          maxLength: 50,
        },
        props: {
          disabled: !allowEdit,
        },
      },
      ['description']: {
        type: 'textArea',
        label: t('description'),
        placeholder: allowEdit ? 'Enter Chain Link Description...' : 'No description available.',
        props: {
          disabled: !allowEdit,
        },
      },
      ['tags']: {
        type: 'controlled-autocomplete',
        label: t('tags'),
        placeholder: !allowEdit ? '' : t('select_a_tag_or_press'),
        onChangeAttribute: 'tags',
        props: {
          freeSolo: true,
          multiple: true,
          options: tags,
          getOptionLabel: (option) => option.label || option.name,
          disabled: !allowEdit,
        },
      },
      'divider-2': { type: 'divider' },
      'section-2': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('timeline')}</Typography>
            <Typography variant="sectionText">{t('timeline_chain_link_description')}</Typography>
          </HeaderSection>
        ),
      },
      'divider-3': { type: 'divider' },
      ['start_date']: {
        type: 'date',
        label: t('start_date'),
        props: {
          disabled: !allowEdit,
        },
        onchange: (date) => {
          // console.log('start date changes!!')
          const time = methods.getValues('start_time') || workingTimes[0].start_time

          const formattedDate = dayjs(date)
            .set('hour', dayjs(time).get('hour') || 0)
            .set('minute', dayjs(time).get('minute') || 0)
            .locale(langLocale)
            .format()

          methods.setValue('start_date', formattedDate)

          const calculatedMilestones = calculateDueDate(watchSPs, formattedDate)
          methods.setValue('sps', calculatedMilestones) // Update the due date of milestones

          return date
        },
      },
      ['start_time']: {
        type: 'time',
        label: t('start_time'),
        props: {
          disabled: !allowEdit,
        },
        onchange: (time) => {
          // console.log('start time changes!!')
          const startDate = methods.getValues('start_date') || dayjs().format()
          const date = startDate || dayjs().format()

          const formattedDate = dayjs(date)
            .set('hour', dayjs(time).get('hour') || 0)
            .set('minute', dayjs(time).get('minute') || 0)
            .locale(langLocale)
            .format()

          methods.setValue('start_date', formattedDate)
          methods.setValue('start_time', time)

          const calculatedMilestones = calculateDueDate(watchSPs, formattedDate)
          methods.setValue('sps', calculatedMilestones) // Update the due date of milestones

          return time
        },
      },
      'divider-4': { type: 'divider' },
      'workingTimes-section': {
        component: watchCLTemp && (
          <>
            <Alert severity="info">
              <AlertTitle sx={{ fontFamily: "'Inter', 'Noto Sans Thai'" }}>{t('working_times')}</AlertTitle>
              {workingTimes?.map((working_time, index) => {
                return (
                  <Typography variant="subtitle2" key={working_time.id}>
                    {'• '} {dayjs(`1970-01-01T${working_time.start_time}`).locale(langLocale).format('HH:mm')} -{' '}
                    {dayjs(`1970-01-01T${working_time.end_time}`).locale(langLocale).format('HH:mm')}
                  </Typography>
                )
              })}
            </Alert>

            {/* {spTemplate?.company?.working_times && spTemplate?.company?.working_times.length > 0 ? (
              spTemplate?.company?.working_times.map((working_time, index) => {
              return (
                <TypographyP key={working_time.id}>
                  {index + 1}: {dayjs(`1970-01-01T${working_time.start_time}`).locale(langLocal).format('HH:mm')} -{' '}
                  {dayjs(`1970-01-01T${working_time.end_time}`).locale(langLocal).format('HH:mm')}
                </TypographyP>
              )})
            : (
            <TypographyP>No working times found.</TypographyP>
            )}
            } */}
          </>
        ),
      },
      'chains-preview': {
        component:
          watchSPs && watchSPs?.length > 0 ? (
            <>
              <Tabs
                items={watchSPs?.map((sp) => ({ id: sp?.id, label: sp?.name }))}
                onChange={handleTabChange}
                defaultTab={watchSPs[0]?.id}
              />

              <SWTimelinePreview
                items={
                  watchSPs.filter((sp) => {
                    return sp?.id === currentChain
                  })[0]?.sws || []
                }
                noOppositeContent
              />
            </>
          ) : (
            <Empty title={t('no_template_not_found')} description={t('chain_link_no_template_description')} />
          ),
      },
    },
    required: ['chain_link_temp.id', 'name', 'start_date', 'start_time'],
  }

  if (methods.formState.isLoading) {
    return <Empty title="Loading..." description="Please wait while the data is being loaded." />
  }

  return (
    <Container>
      <DynamicForm ref={ref} methods={methods} onSubmit={onSubmit} schema={formSchema} />
    </Container>
  )
})

export default EditCL

const HeaderSection = styled('section')`
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  margin-top: 1rem;
`

export const TabsWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 1rem;

  transition: all 100ms ease-out;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);

  .tab-item {
    display: flex;
    height: 44px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: var(--Gray-700, #344054);
    /* Text sm/Semibold */
    font-family: 'Inter', 'Noto Sans Thai';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem; /* 142.857% */

    cursor: pointer;
    transition: all 100ms ease-out;

    &.active {
      background: var(--Primary-50);
      border-bottom: 2px solid var(--Primary-600);
      color: var(--Primary-700);
    }

    &:not(.active) {
      &:hover {
        background: var(--Primary-50);
      }
    }
  }
`

const Container = styled('div')`
  height: auto;
  min-height: 100%;
  padding: 0 1.25rem 1rem 1.25rem;

  &::-webkit-scrollbar {
    width: 0.75rem;
    background: rgba(0, 0, 0, 0.25); /* make scrollbar invisible by default */
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    opacity: 0.5;
  }

  &:active::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-track {
    background: var(--Base-White);
    border: 1px solid var(--Gray-200);
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    margin: 0 0.5rem;
  }
`
