import { setAdminData } from 'utils/common'
import { getAPI, postAPI } from '../../utils/api'
import {
  GET_COUNTRIES,
  GET_BANKS,
  GET_NATIONALITIES,
  GET_DEPARTMENT_AND_POSITION,
  GET_PROVINCES,
  GET_DISTRICTS,
  GET_SUB_DISTRICTS,
  GET_MASTER_EMPLOYEE,
  GET_ALL_COMPANY_LIST,
  GET_MASTER_EMPLOYEE_2,
  GET_IMAGE,
  GET_EMPLOYEE_BY_ID,
  SET_IS_REDIRECT,
  GET_COMPANY_INFO_BY_ID,
  UPLOAD_FILE,
  REFRESH_TOKEN,
  GET_PROVINCE_OPTIONS,
  GET_DISTRICT_OPTIONS,
  GET_SUB_DISTRICT_OPTIONS,
} from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getCountries = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_COUNTRIES.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const { data } = await getAPI({
      url: '/api/countries?pagination[page]=1&pagination[pageSize]=10000',
      headers: headers,
    })

    return dispatch({
      type: GET_COUNTRIES.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_COUNTRIES.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Countries Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getBanks = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_BANKS.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const { data } = await getAPI({
      url: '/api/banks?pagination[page]=1&pagination[pageSize]=10000',
      headers: headers,
    })

    return dispatch({
      type: GET_BANKS.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_BANKS.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Banks Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getNationalities = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_NATIONALITIES.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const { data } = await getAPI({
      url: '/api/nationalities?pagination[page]=1&pagination[pageSize]=10000',
      headers: headers,
    })

    return dispatch({
      type: GET_NATIONALITIES.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_NATIONALITIES.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Nationalities Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDepartmentsAndPositions = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DEPARTMENT_AND_POSITION.REQUEST })
    const { company_info } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const { data } = await getAPI({
      url: `/api/departments?populate=*&filters[company][id]=${company_info.id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_DEPARTMENT_AND_POSITION.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_DEPARTMENT_AND_POSITION.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Departments and Positions Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProvinces = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PROVINCES.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const { data } = await getAPI({
      url: '/api/thai-provinces?pagination[page]=1&pagination[pageSize]=100',
      headers: headers,
    })

    return dispatch({
      type: GET_PROVINCES.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_PROVINCES.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Provinces Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDistricts = (provinceId) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DISTRICTS.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const { data } = await getAPI({
      url: `/api/thai-amphure?filters[thai_province]=${provinceId}&pagination[page]=1&pagination[pageSize]=1000`,
      headers: headers,
    })

    return dispatch({
      type: GET_DISTRICTS.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_DISTRICTS.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Districts Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getSubDistricts = (districtId) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SUB_DISTRICTS.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const { data } = await getAPI({
      url: `/api/thai-tambon?filters[thai_amphure]=${districtId}&pagination[page]=1&pagination[pageSize]=1000`,
      headers: headers,
    })

    return dispatch({
      type: GET_SUB_DISTRICTS.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_SUB_DISTRICTS.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get SubDistricts Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDepartmentsAndPositionsByCompany = (company_id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DEPARTMENT_AND_POSITION.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const params = {
      'filters[company][id]': company_id ? company_id : 1,
    }

    const { data } = await getAPI({
      url: '/api/departments?populate=*',
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_DEPARTMENT_AND_POSITION.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_DEPARTMENT_AND_POSITION.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Departments and Positions Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getDepartmentsAndPositionsUseAuthen = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DEPARTMENT_AND_POSITION.REQUEST })
    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const params = {
      type: 'filter',
      'pagination[page]': 0,
      'pagination[pageSize]': 1000,
      'filters[company_id]': company_info?.id ? company_info?.id : null,
    }

    const { data } = await getAPI({
      url: '/api/departments?populate=*',
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_DEPARTMENT_AND_POSITION.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_DEPARTMENT_AND_POSITION.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Departments and Positions Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getEmployee =
  (filter = { limit: 10, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MASTER_EMPLOYEE.REQUEST })
      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let params = {
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        company_id: company_info?.id ? company_info?.id : null,
        status: 'active',
      }

      if (filter.other_search) {
        params = { ...params, other_search: filter.other_search }
      }
      if (filter.department) {
        params = { ...params, department: filter.department }
      }
      if (filter.position) {
        params = { ...params, position: filter.position }
      }
      if (filter.meeting_id) {
        params = { ...params, meeting_id: filter.meeting_id }
      }

      const { data, meta } = await getAPI({
        url: '/api/employees',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_MASTER_EMPLOYEE.SUCCESS,
        data,
        meta,
        filter,
      })
    } catch (error) {
      return dispatch({ type: GET_MASTER_EMPLOYEE.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getAllCompany =
  (filter = { limit: 10, skip: 0 }, id = 1) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_ALL_COMPANY_LIST.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const { data, meta } = await getAPI({
        url: `/api/companies?filters[status][$ne]=delete&populate=*`,
        headers: headers,
      })

      return { type: 'getAllCompany_SUCCESS', data_info: data, data_meta: meta }
    } catch (error) {
      return { type: 'getAllCompany_SUCCESS' }
    } finally {
      dispatch(hideLoading())
    }
  }

export const getEmployee2 =
  (filter = { limit: 10, skip: 1 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MASTER_EMPLOYEE_2.REQUEST })
      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.departmentIds) {
        multipleSearch = Object.assign({}, ...filter.departmentIds)
      }
      if (filter.employeeIds) {
        multipleSearch = Object.assign({}, ...filter.employeeIds)
      }
      let params = {
        ...multipleSearch,
        'fields[0]': 'first_name',
        'fields[1]': 'last_name',
        'fields[2]': 'photo',
        'filters[company][id]': company_info.id,
        'populate[department][fields][0]': 'name',
        'populate[position][fields][0]': 'name',
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
      }

      const { data, meta } = await getAPI({
        url: '/api/document-acknow/employees',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_MASTER_EMPLOYEE_2.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({ type: GET_MASTER_EMPLOYEE_2.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getImage = (params) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_IMAGE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    let multipleParams = {}
    if (params) {
      multipleParams = Object.assign({}, ...params)
    }

    const data = await getAPI({
      url: `/api/upload/files`,
      headers: headers,
      params: {
        ...multipleParams,
      },
    })

    return dispatch({
      type: GET_IMAGE.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_IMAGE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Image failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getImages = (params) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_IMAGE.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    let multipleParams = {}
    if (params) {
      multipleParams = Object.assign({}, ...params)
    }

    const data = await getAPI({
      url: `/api/upload/files`,
      headers: headers,
      params: {
        ...multipleParams,
      },
    })

    dispatch({
      type: GET_IMAGE.SUCCESS,
    })

    return data
  } catch (error) {
    dispatch({
      type: GET_IMAGE.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Image failed!',
    })

    return []
  } finally {
    dispatch(hideLoading())
  }
}

export const getEmployeeDropdrow =
  (filter = { limit: 10, skip: 0 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_MASTER_EMPLOYEE.REQUEST })

      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }

      let params = {
        company_id: company_info?.id ? company_info?.id : null,
        department: filter?.department,
      }
      const { data, meta } = await getAPI({
        url: '/api/employee-dropdrow',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_MASTER_EMPLOYEE.SUCCESS,
        data,
        meta,
        filter,
      })
    } catch (error) {
      return dispatch({ type: GET_MASTER_EMPLOYEE.FAILURE, error })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getEmployeeById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_EMPLOYEE_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/employees/${id}`,
      headers: headers,
    })

    return { type: 'getEmployeeById_SUCCESS', data_info: data }
  } catch (error) {
    return { type: 'getEmployeeById_ERROR' }
  } finally {
    dispatch(hideLoading())
  }
}

export const getServerTime = () => async (dispatch) => {
  try {
    dispatch({ type: GET_COUNTRIES.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const data = await getAPI({
      url: '/api/servertime',
      headers: headers,
    })

    return { type: 'getServerTime_SUCCESS', data_info: data }
  } catch (error) {
    return { type: 'getServerTime_ERROR' }
  } finally {
  }
}

export const setIsRedirect = (data) => (dispatch) => {
  dispatch({
    type: SET_IS_REDIRECT,
    data,
  })
}

export const getCompanyInfoById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_COMPANY_INFO_BY_ID.REQUEST })
    const headers = {
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/companies/${id}`,
      headers: headers,
    })
    return dispatch({
      type: GET_COMPANY_INFO_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_COMPANY_INFO_BY_ID.FAILURE,
      error,
      showAlert: false,
      alertMessage: 'Get Company Info Failed',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const uploadFile = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPLOAD_FILE.REQUEST })

    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: null,
    }
    const response = await postAPI({
      url: '/api/upload',
      headers,
      data,
    })

    dispatch({ type: UPLOAD_FILE.SUCCESS, showAlert: true, alertMessage: 'Upload file successful!' })
    return response[0]
  } catch (error) {
    dispatch({ type: UPLOAD_FILE.FAILURE, showAlert: true, alertMessage: 'Upload file Failure!' })
    return null
  } finally {
    dispatch(hideLoading())
  }
}

export const requestRefreshToken = (data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: REFRESH_TOKEN.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }
    const { jwt, refreshToken, jwtExpiresDate } = await postAPI({
      url: '/api/token/refresh',
      headers,
      data,
    })

    if (jwt && refreshToken && jwtExpiresDate) {
      const oldAdminStore = JSON.parse(localStorage.getItem('admin'))
      const newAdminStore = {
        ...oldAdminStore,
        jwt,
        refreshToken,
        jwtExpiresDate,
      }

      setAdminData(newAdminStore)
    }

    return dispatch({ type: REFRESH_TOKEN.SUCCESS })
  } catch (error) {
    return dispatch({ type: REFRESH_TOKEN.FAILURE, showAlert: true, alertMessage: 'Refresh Token Failure!' })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProvinceOptions = () => async (dispatch) => {
  try {
    console.log('getProvinceOptions')
    dispatch(showLoading())
    dispatch({ type: GET_PROVINCE_OPTIONS.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const { data } = await getAPI({
      url: '/api/thai-provinces?pagination[page]=1&pagination[pageSize]=100',
      headers: headers,
    })

    const result = data.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
    }))

    dispatch({
      type: GET_PROVINCE_OPTIONS.SUCCESS,
    })

    return result
  } catch (error) {
    dispatch({
      type: GET_PROVINCE_OPTIONS.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Province Options Fail',
    })

    return []
  } finally {
    dispatch(hideLoading())
  }
}

export const getDistrictOptions = (provinceId) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DISTRICT_OPTIONS.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const { data } = await getAPI({
      url: `/api/thai-amphure?filters[thai_province]=${provinceId}&pagination[page]=1&pagination[pageSize]=1000`,
      headers: headers,
    })

    dispatch({
      type: GET_DISTRICT_OPTIONS.SUCCESS,
    })

    return data
  } catch (error) {
    dispatch({
      type: GET_DISTRICT_OPTIONS.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get District Options Fail',
    })

    return []
  } finally {
    dispatch(hideLoading())
  }
}

export const getSubDistrictOptions = (districtId) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SUB_DISTRICT_OPTIONS.REQUEST })

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const { data } = await getAPI({
      url: `/api/thai-tambon?filters[thai_amphure]=${districtId}&pagination[page]=1&pagination[pageSize]=1000`,
      headers: headers,
    })

    dispatch({
      type: GET_SUB_DISTRICT_OPTIONS.SUCCESS,
    })

    return data
  } catch (error) {
    dispatch({
      type: GET_SUB_DISTRICT_OPTIONS.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get SubDistrict Options Fail',
    })

    return []
  } finally {
    dispatch(hideLoading())
  }
}

export const getDepartmentsAndPositionsOptions = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_DEPARTMENT_AND_POSITION.REQUEST })
    const { company_info } = JSON.parse(localStorage.getItem('admin'))

    const headers = {
      'Content-Type': 'application/json',
      Authorization: null,
    }

    const { data } = await getAPI({
      url: `/api/departments?populate=*&filters[company][id]=${company_info.id}`,
      headers: headers,
    })

    dispatch({
      type: GET_DEPARTMENT_AND_POSITION.SUCCESS,
    })

    return data
  } catch (error) {
    dispatch({
      type: GET_DEPARTMENT_AND_POSITION.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Departments and Positions Fail',
    })
    return []
  } finally {
    dispatch(hideLoading())
  }
}
