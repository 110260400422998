import { useSelector } from 'react-redux'
import styled from 'styled-components'

import Modal from '../components/common/Modal'
import Loading from '../components/common/Loading'
import { selectLoading } from '../redux/selectors'

const StyleModal = styled(Modal)`
  > .modal-content {
    background: none;

    svg circle {
      stroke: var(--Base-White);
    }
  }
`

const GlobalLoading = () => {
  const loading = useSelector(selectLoading)
  return (
    <StyleModal open={loading}>
      <Loading size={64} />
    </StyleModal>
  )
}

export default GlobalLoading
