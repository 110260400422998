import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { getCompanyInfo,GetPermissionOther,GetPermissionTabList } from '../../utils/common'

import styled from 'styled-components'
import DocumentManagement from '../../components/feature/acknowledgement-system/DocumentManagement'
import DocumentTemplate from '../../components/feature/acknowledgement-system/DocumentTemplate'
import DocumentCategory from '../../components/feature/acknowledgement-system/DocumentCategory'

const Div = styled.div`
  // shared margin,padding
  .mb-1-rem {
    margin-bottom: 1rem;
  }

  // shared-component
  .icon {
    cursor: pointer;
  }
  .badge {
    color: var(--Primary-700);
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;

    border-radius: 1rem;
    border: 0.0625rem solid var(--Primary-200);
    background: var(--Primary-50);

    padding: 0.1875rem;

    &.pink {
      border: 0.0625rem solid var(--BADGE-12-Border);
      background: var(--BADGE-12-Bg);
      color: var(--BADGE-12-Font);
    }
    &.orange {
      border: 0.0625rem solid var(--BADGE-13-Border);
      background: var(--BADGE-13-Bg);
      color: var(--BADGE-13-Font);
    }
    &.green {
      border: 0.0625rem solid var(--BADGE-14-Border);
      background: var(--BADGE-14-Bg);
      color: var(--BADGE-14-Font);
    }
    &.purple {
      border: 0.0625rem solid var(--BADGE-15-Border);
      background: var(--BADGE-15-Bg);
      color: var(--BADGE-15-Font);
    }
    &.in-use {
      border: 0.0625rem solid var(--BADGE-16-Border);
      background: var(--BADGE-16-Bg);
      color: var(--BADGE-16-Font);
    }
  }
  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
  .status-badge {
    display: flex;
    align-items: center;
    border-radius: 0.375rem;
    border: 1px solid var(--Gray-300);
    background: var(--Base-White);

    color: var(--Gray-700);
    text-align: center;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;

    padding: 0 0.375rem;

    .active-color {
      color: var(--Status-Active);
    }
    .inactive-color {
      color: var(--Status-Inactive);
    }
    .draft-color {
      color: var(--Status-Draft);
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background: var(--Gray-200);
    margin-bottom: 2em;
  }

  // typography
  .feature-header {
    color: var(--Gray-900);
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
  }
  .feature-description {
    color: var(--Gray-600);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 1.25em;
  }
  .document-name {
    color: var(--Primary-700);
    font-style: normal;
    font-weight: 500;
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  // layout
  .tabmenu-container {
    display: flex;
    margin-bottom: 1.5rem;
    .tab-menu {
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      color: var(--Gray-500);
      padding: 0.75rem;
      :hover {
        cursor: pointer;
      }
      &.active {
        color: var(--Primary-700);
        border-bottom: 2px solid var(--Primary-700);
        background: var(--Primary-50);
      }
    }
  }
  .table-wrapper {
    .table-header {
      padding: 1rem 0.875rem;
      border-radius: 0.5rem 0.5rem 0 0;
      background: var(--Base-White);
      border: 0.0625rem solid var(--Gray-200);

      .header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1.25rem;

        .table-header-name {
          color: var(--Gray-900);
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          margin-right: 0.5rem;
        }
      }

      .search-container {
        display: flex;
        .clear-button-container {
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }
  .modal-content {
    width: 800px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--Gray-600);

    .category-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      height: 500px;

      .category {
        padding: 1rem 2rem;
        border-right: 1px solid var(--Gray-200);
        .category-item {
          display: flex;
          align-items: center;
          padding: 8px;
          border-radius: 8px;
          background: var(--Base-White);
          align-items: center;
          cursor: pointer;
          color: var(--Gray-900);
          font-weight: 500;

          &.active {
            background: var(--Primary-600);
            color: var(--Base-White);
          }
        }
      }

      .category-view {
        padding: 1rem 2rem;
        .view-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;

          color: var(--Gray-900);
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 500;

          .view-link {
            cursor: pointer;
            color: var(--Primary-700);
            font-weight: 600;
          }
        }
      }
    }
  }
  .input-container {
    margin-right: 0.75rem;
    &.width-20 {
      width: 20%;
    }
    .input-header {
      color: var(--Gray-700);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 0.375rem;
    }
  }
  .action-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .action-item {
      margin-right: 0.75rem;
      color: var(--Gray-600);
      cursor: pointer;
    }
  }
  .search-content {
    padding: 10px;
    border-bottom: 1px solid var(--Gray-200);

    .search-input {
      position: relative;
      color: var(--Gray-500);

      .input-field {
        box-sizing: border-box;
        padding: 10px;
        padding-left: 40px;
        border: none;

        font-size: 16px;
        font-style: normal;
        font-weight: 400;
      }

      .start-icon {
        position: absolute;
        top: 10px;
        left: 10px;
      }

      .end-icon {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
`

function AcknowledgementSystem() {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [tab, setTab] = useState('Document Management')

  const [menuTabList, setMenuTabList] = useState([])
  const initLang = localStorage.getItem('lang')
  //--- ST ส่วนของ Permission LV3-4

  //const { permission } = JSON.parse(localStorage.getItem('admin'));
  useEffect(() => {
    /*
    const this_sub_menu_permission = permission['Human Resources & Organization'].filter((it_2) => it_2.path === '/organization-management/acknowledgement-system'); 
    if(this_sub_menu_permission.length > 0){
      const tab_permission = this_sub_menu_permission[0].permission_tab?.filter((it_2) => it_2.is_read === true)
      let temp_tab = [];
      let index_tab = 0;
      for (const  objtab_permission of tab_permission) {
        temp_tab.push({
          name : initLang == 'th' ? objtab_permission.menu_setting.name_th : objtab_permission.menu_setting.name_en,
          value : objtab_permission.menu_setting.rout_path,
          active_status : index_tab == 0 ? true : false
        })
        
        if(index_tab == 0){
          setTab(objtab_permission.menu_setting.rout_path)
        }
        
        index_tab = index_tab + 1;
      }
      setMenuTabList(temp_tab)
    }
    */

    const this_tab_list = GetPermissionTabList('Acknowledgement System');
    if(this_tab_list.length > 0){
      const tab_permission = this_tab_list?.filter((it_2) => it_2.canRead === true)
      let temp_tab = [];
      let index_tab = 0;
      for (const  objtab_permission of tab_permission) {
        temp_tab.push({
          name : initLang == 'th' ? objtab_permission.name_th : objtab_permission.name_en,
          value : objtab_permission.key,
          active_status : index_tab == 0 ? true : false
        })
        
        if(index_tab == 0){
          setTab(objtab_permission.key)
          
        }
        index_tab = index_tab + 1;
      }
      setMenuTabList(temp_tab)
    }
  }, [])

  //--- ED ส่วนของ Permission LV3-4

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const tabQuery = queryParams.get('tab')
    if(tabQuery){
      setTab(tabQuery)
    }
    
  }, [location.search])

  const toggleTab = (tab) => {
    navigate(`/organization-management/acknowledgement-system?tab=${tab}`)
  }

  return (
    <Div>
      <div className="feature-header">{t('acknowledgement_system')}</div>
      <div className="feature-description">{t('acknowledgement_system_sub')}</div>
      <div className="divider"></div>

      <div className="tabmenu-container">
          {menuTabList?.map((item, index) => (
            <span
              id={`${item.value}`}
              className={`tab-menu ${tab === item.value ? 'active' : ''}`}
              onClick={() => toggleTab(item.value)}
            >
              {item.name}
            </span>
          ))} 
        {
          /*
          <span
            className={`tab-menu ${tab === 'document-management' ? 'active' : ''}`}
            onClick={() => toggleTab('document-management')}
          >
            {t('document_management')}
          </span>
          <span
            className={`tab-menu ${tab === 'document-template' ? 'active' : ''}`}
            onClick={() => toggleTab('document-template')}
          >
            {t('document_template')}
          </span>
          <span
            className={`tab-menu ${tab === 'document-category' ? 'active' : ''}`}
            onClick={() => toggleTab('document-category')}
          >
            {t('document_category')}
          </span>
          */
        }


      </div>

      {tab === 'Document Management' ? (
        <DocumentManagement></DocumentManagement>
      ) : tab === 'Document Template' ? (
        <DocumentTemplate></DocumentTemplate>
      ) : (
        <DocumentCategory></DocumentCategory>
      )}
    </Div>
  )
}

export default AcknowledgementSystem
