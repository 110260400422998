import { Divider, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { createElement, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { RiAddLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import iconFail from 'assets/images/fail-icon.png'
import iconSuccess2 from 'assets/images/success-icon-2.png'
import Button from 'components/common/Button'
import Chip from 'components/common/Chip'
import CollapseMenu from 'components/common/CollapseMenu'
import ModalV2 from 'components/common/ModalV2'
import SidePanelV2 from 'components/common/SidePanelV2'
import TableV3 from 'components/common/TableV3'
import Typography from 'components/common/Typography'
import CreateChainLinkTpl from 'components/feature/operation-process/form/chain-link-tpl/create'
import EditChainLinkTpl from 'components/feature/operation-process/form/chain-link-tpl/edit'
import ViewChainLinkTpl from 'components/feature/operation-process/form/chain-link-tpl/view'
import Filter from 'components/feature/operation-process/form/chain-link-tpl/filters'
import { CHAIN_LINK_TEMPLATE } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { ButtonGroup, TableContainer, TableHeader, TypographyH3 } from '.'
import tableColumns from './table-columns'

const init_drawer = {
  createChainLinkTpl: false,
  viewChainLinkTpl: false,
  editChainLinkTpl: false,
  chainLinkTplId: undefined,
}

const ChainLinkMGTList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const chainLinkTplId = searchParams.get('id')
  const mode = searchParams.get('mode') || 'view'

  const filterRef = useRef(null)
  const createChainLinkTplRef = useRef(null)
  const editChainLinkTplRef = useRef(null)
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)

  const { chainLinkTpl: chainLinkTplActions, extra: extraActions } = opActions()
  const { getExtra } = extraActions()
  const { getChainLinkTpl, postChainLinkTpl, putChainLinkTpl, deleteChainLinkTpl, clearChainLinkTpl } =
    chainLinkTplActions()

  const { chainLinkTpls, chainLinkTpls_pagination, chainLinkTpl } = opProcSelector
  const { page, pageCount, total } = chainLinkTpls_pagination

  const [drawer, setDrawer] = useState(init_drawer)
  const [filters, setFilters] = useState({})
  const [modalProps, setModalProps] = useState({ open: false })

  const fetchChainLinkTpls = useCallback(
    async (payload) => {
      return dispatch(getChainLinkTpl().list(payload))
    },
    [dispatch]
  )

  const fetchChainLinkTplById = useCallback(
    async (chainLinkTplId) => {
      return dispatch(getChainLinkTpl(chainLinkTplId).byId())
    },
    [dispatch]
  )

  const fetchDepartments = useCallback(
    async (payload) => {
      return dispatch(getExtra().departments(payload))
    },
    [dispatch]
  )

  const handleToggleDrawer = (drawerName, open, data = {}) => {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }

      setDrawer({ ...init_drawer, [drawerName]: open, ...data })
    }
  }

  const handleFilters = (values) => {
    const keys = Object.keys(values)

    if (keys.length === 0) {
      setFilters({})
      return fetchChainLinkTpls()
    }

    let filters = {}
    keys.forEach((key) => {
      if (!values[key]) {
        return
      }

      switch (key) {
        case 'name':
          filters = { ...filters, $or: [{ name: { $contains: values[key] } }, { code: { $contains: values[key] } }] }
          break
        default:
          break
      }
    })

    if (Object.keys(filters).length === 0) {
      setFilters({})
      return fetchChainLinkTpls()
    }

    setFilters(filters)
    return fetchChainLinkTpls({ filters })
  }

  const handleTableChange = ({ page }) => {
    // Do something...
    const pagination = { ...chainLinkTpls_pagination, page }
    fetchChainLinkTpls({ pagination, filters })
  }

  const actionList = (item) => {
    return [
      {
        text: t('view'),
        action: () => {
          navigate(`${pathname}?id=${item.id}&mode=view`)
          handleToggleDrawer('viewChainLinkTpl', true)()
        },
      },
      {
        text: t('Edit'),
        action: () => {
          navigate(`${pathname}?id=${item.id}&mode=edit`)
          handleToggleDrawer('editChainLinkTpl', true)()
        },
      },
    ]
  }

  const transformChainLinkToTable = (data) => {
    const mapDataItem = (item) => {
      const code = (
        <Link to={`${pathname}?id=${item?.id}&mode=view`}>
          <Typography variant="tableLink">{item.code}</Typography>
        </Link>
      )
      const description = <div className="chainLink-col-description">{item.description}</div>
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={item.status || 'N/A'}
          color={CHAIN_LINK_TEMPLATE.STATUS_CHIP[item.status]}
        />
      )
      const actions = <CollapseMenu list={actionList(item)} />

      return { ...item, code, description, status, actions }
    }

    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const handleCreateChainLinkTplSubmit = (data) => {
    const successfulCreate = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
        return fetchChainLinkTpls()
      },
      onOk() {
        setModalProps({ open: false })
        return fetchChainLinkTpls()
      },
      titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
      titleText: 'Chain Link Template Created',
      contentText: 'Chain Link Template has been created successfully.',
    }

    const failedCreate = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
      },
      onOk() {
        setModalProps({ open: false })
      },
      okText: 'Try Again',
      titleIcon: createElement('img', { src: iconFail, width: 48, height: 48, alt: 'i-failed' }),
      titleText: "Couldn't Create Chain Link Template",
      contentText: 'An error occurred while creating the Chain Link Template.',
      okProps: { color: 'error' },
    }

    dispatch(postChainLinkTpl().create(data)).then((res) => {
      if (res.type.endsWith('_SUCCESS') && !res.error) {
        handleToggleDrawer('createChainLinkTpl', false)()
        setModalProps(successfulCreate)
        return
      }

      setModalProps(failedCreate)
      return
    })
  }

  const handleEditChainLinkTplSubmit = (data) => {
    const onCloseAndOnOkSuccess = () => {
      setModalProps({ open: false })
      fetchChainLinkTpls()
      fetchChainLinkTplById(chainLinkTplId || drawer?.chainLinkTplId)
      navigate(pathname)
    }

    const successfulEdit = {
      open: true,
      showCancel: false,
      onClose: onCloseAndOnOkSuccess,
      onOk: onCloseAndOnOkSuccess,
      titleIcon: createElement('img', { src: iconSuccess2, width: 48, height: 48, alt: 'i-successful' }),
      titleText: 'Chain Link Template Updated',
      contentText: 'Chain Link Template has been updated successfully.',
    }

    const failedEdit = {
      open: true,
      showCancel: false,
      onClose() {
        setModalProps({ open: false })
      },
      onOk() {
        setModalProps({ open: false })
      },
      okText: 'Try Again',
      titleIcon: createElement('img', { src: iconFail, width: 48, height: 48, alt: 'i-failed' }),
      titleText: "Couldn't Update Chain Link Template",
      contentText: 'An error occurred while updating the Chain Link Template.',
      okProps: { color: 'error' },
    }

    dispatch(putChainLinkTpl(chainLinkTplId).update(data)).then((res) => {
      if (res.type.endsWith('_SUCCESS') && !res.error) {
        handleToggleDrawer('editChainLinkTpl', false)()
        setModalProps(successfulEdit)
        return
      }

      setModalProps(failedEdit)
      return
    })
  }

  useEffect(() => {
    fetchDepartments()
    fetchChainLinkTpls()
  }, [fetchChainLinkTpls, fetchDepartments])

  useEffect(() => {
    if (chainLinkTplId) {
      switch (mode) {
        case 'edit':
          handleToggleDrawer('editChainLinkTpl', true, { chainLinkTplId: chainLinkTplId })()
          break

        case 'view':
        default:
          handleToggleDrawer('viewChainLinkTpl', true, { chainLinkTplId: chainLinkTplId })()
          break
      }

      return
    }

    handleToggleDrawer('viewChainLinkTpl', false)()
  }, [chainLinkTplId, mode])

  return (
    <TableContainer className="chainLink-table-container">
      <TableHeader>
        <Stack sx={{ width: '100%' }}>
          <Stack direction="row" sx={{ width: '100%', padding: '1rem 1.5rem', justifyContent: 'space-between' }}>
            <Stack direction="row" spacing={2} alignItems="center">
              <TypographyH3>{t('chain_link')}</TypographyH3>

              <Chip
                label={total > 1000 ? '999+' : `${total} Chain Link templates`}
                color="primary"
                sx={{ p: '2px 8px 2px 8px', cursor: 'pointer' }}
              />
            </Stack>
          </Stack>

          <Divider sx={{ borderColor: 'var(--Gray-200)' }} />

          <Stack
            direction="row"
            alignItems="flex-end"
            padding="1rem 1.5rem"
            minHeight="4.875rem"
            flexWrap="wrap"
            columnGap="1rem"
            rowGap="0.5rem"
            justifyContent="space-between"
          >
            <Filter ref={filterRef} onFormChange={handleFilters} />

            <ButtonGroup>
              <Button
                variant="contained"
                startIcon={<RiAddLine />}
                onClick={handleToggleDrawer('createChainLinkTpl', true)}
              >
                {t('create_new_chainLink')}
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </TableHeader>

      <TableV3
        data={transformChainLinkToTable(chainLinkTpls)}
        page={page}
        pageCount={pageCount}
        columns={tableColumns('chain-link')}
        onStateChange={handleTableChange}
      />

      <SidePanelV2
        titleText="Create Chain Link Template"
        open={drawer.createChainLinkTpl}
        onClose={() => {
          handleToggleDrawer('createChainLinkTpl', false)()
        }}
        footerEl={
          <FooterDrawer>
            <Button className="gray" variant="outlined" onClick={handleToggleDrawer('createChainLinkTpl', false)}>
              {t('cancel')}
            </Button>
            <Button variant="contained" onClick={() => createChainLinkTplRef.current.submit()}>
              {t('create')}
            </Button>
          </FooterDrawer>
        }
      >
        <CreateChainLinkTpl ref={createChainLinkTplRef} onSubmit={handleCreateChainLinkTplSubmit} />
      </SidePanelV2>

      <SidePanelV2
        titleText={chainLinkTpl?.name ? `${chainLinkTpl?.name}` : ''}
        supportingText={chainLinkTpl?.code ? `code: ${chainLinkTpl.code}` : ''}
        open={drawer.viewChainLinkTpl}
        onClose={() => {
          handleToggleDrawer('viewChainLinkTpl', false)()
          dispatch(clearChainLinkTpl())
          navigate(pathname)
        }}
        footerEl={null}
      >
        {chainLinkTplId && <ViewChainLinkTpl id={chainLinkTplId || drawer?.chainLinkTplId} />}
      </SidePanelV2>

      <SidePanelV2
        titleText={chainLinkTpl?.name ? `${chainLinkTpl?.name} (${t('Edit')})` : ''}
        supportingText={chainLinkTpl?.code ? `code: ${chainLinkTpl.code}` : ''}
        open={drawer.editChainLinkTpl}
        onClose={() => {
          handleToggleDrawer('editChainLinkTpl', false)()
          dispatch(clearChainLinkTpl())
          navigate(pathname)
        }}
        footerEl={
          <FooterDrawer>
            <Button
              className="gray"
              variant="outlined"
              onClick={() => {
                handleToggleDrawer('editChainLinkTpl', false)
                clearChainLinkTpl()
                navigate(pathname)
              }}
            >
              {t('cancel')}
            </Button>
            <Button variant="contained" onClick={() => editChainLinkTplRef.current.submit()}>
              {t('save')}
            </Button>
          </FooterDrawer>
        }
      >
        {chainLinkTplId && (
          <EditChainLinkTpl
            ref={editChainLinkTplRef}
            onSubmit={handleEditChainLinkTplSubmit}
            id={chainLinkTplId || drawer?.chainLinkTplId}
          />
        )}
      </SidePanelV2>

      <ModalV2 {...modalProps} />
    </TableContainer>
  )
}

export default ChainLinkMGTList

const FooterDrawer = styled('footer')({
  display: 'flex',
  width: '100%',
  gap: '1rem',
  justifyContent: 'flex-end',
  padding: '1rem',
})
