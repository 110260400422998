import { getAPI, postAPI, putAPI } from '../../utils/api'
import { GET_SOW_BY_DEPARTMENT_ID, CREATE_SOW, UPDATE_STATUS_SOW, GET_SOW_BY_ID, DELETE_SOW } from '../actionTypes'

import { showLoading, hideLoading } from './loading'

export const getScopeOfWorks =
  (filter = { limit: 10, skip: 1 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_SOW_BY_DEPARTMENT_ID.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const params = {
        type: 'api',
        'filters[department][id]': filter.departmentId,
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        'filters[query]': filter.filters?.length > 0 ? JSON.stringify(filter.filters) : '[]',
      }
      if (filter.status != '') params['filters[status]'] = filter.status
      
      const { data, meta } = await getAPI({
        url: '/api/task-scopes',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_SOW_BY_DEPARTMENT_ID.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_SOW_BY_DEPARTMENT_ID.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Scope of work List Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getScopeOfWorkById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SOW_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/task-scopes/${id}`,
      headers: headers,
    })

    return dispatch({
      type: GET_SOW_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_SOW_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Scope of work By Id Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createSow = (data, id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_SOW.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/task-scopes`,
      data,
      headers: headers,
    })

    const filter = { limit: 10, skip: 1, departmentId: id }
    dispatch(getScopeOfWorks(filter))

    return dispatch({
      type: CREATE_SOW.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Scope of work Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_SOW.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Scope of work Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const updateSow = (id, departmentId, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_STATUS_SOW.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `api/task-scopes/${id}`,
      data,
      headers: headers,
    })

    const filter = { limit: 10, skip: 1, departmentId: departmentId }
    dispatch(getScopeOfWorks(filter))

    return dispatch({
      type: UPDATE_STATUS_SOW.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Scope of work Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_STATUS_SOW.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update Scope of work Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const deleteSow = (id, departmentId, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_SOW.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `api/task-scopes/${id}`,
      data,
      headers: headers,
    })

    const filter = { limit: 10, skip: 1, departmentId: departmentId }
    dispatch(getScopeOfWorks(filter))

    return dispatch({
      type: DELETE_SOW.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Scope of work Successful',
    })
  } catch (err) {
    return dispatch({
      type: DELETE_SOW.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Delete Scope of work Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}
