import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getSummaryMyTask } from '../../../redux/actions/summaryTask'
import { selectSummaryTask } from '../../../redux/selectors'
// import Table from '../../common/Table'
import { convertDateFormat } from '../../../utils/helper'
import CapsuleStatus from '../../widget/element/CapsuleStatus'
import Table from '../../widget/element/Table'

export default function TableMyTask({ isUpdate = false, filter, onClickTableItem, onTotalSummaryData }) {
  const taskColumn = [
    {
      Header: 'No',
      accessor: 'no',
      disableSortBy: true,
    },
    {
      Header: 'Task Id',
      accessor: 'task_id',
      disableSortBy: false,
    },
    {
      Header: 'Task Name',
      accessor: 'task_name',
      disableSortBy: false,
    },
    {
      Header: 'Project',
      accessor: 'project',
      disableSortBy: false,
    },
    {
      Header: 'Milestone',
      accessor: 'milestone',
      disableSortBy: false,
    },
    {
      Header: 'Scope of work',
      accessor: 'scope_of_work',
      disableSortBy: false,
    },
    {
      Header: 'Assign To',
      accessor: 'assign_to',
      disableSortBy: false,
    },
    {
      Header: 'Due Date',
      accessor: 'due_date',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    // {
    //   Header: 'Created by',
    //   accessor: 'created_by',
    //   disableSortBy: false,
    // },
    // {
    //   Header: 'Closed Date',
    //   accessor: 'closed_date',
    //   disableSortBy: false,
    // },
  ]

  const [dataViews, setDataViews] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [isSetData, setIsSetData] = useState(false)

  const { taskList, isLoading, total, skip, limit, pageSize, page, pageCount, totals } = useSelector(selectSummaryTask)
  const dispatch = useDispatch()

  const fetchData = useCallback(
    async (pagination, filter) => {
      dispatch(getSummaryMyTask(pagination, filter))
    },
    [dispatch]
  )

  useEffect(() => {
    ;(async () => {
      const pagination = {
        page: 1,
        pageSize: 10,
      }
      const totalPage = Math.ceil(total / 10)
      setTotalPages(totalPage)
      setIsSetData(false)
      setCurrentPage(1)
      await fetchData(pagination, filter)
    })()
  }, [isUpdate])

  useEffect(() => {
    ;(async () => {
      const totalPage = Math.ceil(total / 10)
      setTotalPages(totalPage)
    })()
  }, [total])

  useEffect(() => {

    let viewList = []
    if (taskList?.length >= 0) {
      viewList = taskList.map((item, index) => ({
        ...item,
        no: index + 1,
        task_id: item.task_id,
        task_name: (
          <span className="txt-product-name" onClick={() => handleClickProductName(item)}>
            {item.task_name}
          </span>
        ),
        project: item.project || '-',
        milestone: item.milestone,
        scope_of_work: item.scoopt_of_work,
        assign_to: item.assign_to,
        due_date: convertDateFormat(item?.due_date, 'DD MMM YYYY'),
        status: <CapsuleStatus status={item.status} />,
        // created_by: item.create_by,
        // closed_date: item.closed_date != '' ? item.closed_date : '-',
      }))
    }
    setDataViews([...viewList])
    // setIsSetData(true)
  }, [taskList])

  useEffect(() => {
    if (totals?.total_all && onTotalSummaryData) {
      onTotalSummaryData(totals)
    }
  }, [totals])

  const handleClickProductName = (itemData) => {
    if (onClickTableItem) {
      // onClickTableItem(itemData.id)
      onClickTableItem(itemData)
    }
  }

  const onChangePagination = async (value) => {
    console.log('pagination: '+JSON.stringify(value,null,2))
    const pagination = {
      // skip: (value.page - 1) * 10,
      // limit: 10,
      page: value.page,
      pageSize: 10,
      total: totalPages,
    }
    await fetchData(pagination, filter)
    setCurrentPage(value.page)
    setIsSetData(false)
  }

  return (
    <Styled>
      <Table
        columns={taskColumn}
        data={dataViews}
        onStateChange={onChangePagination}
        pageCount={totalPages}
        page={currentPage}
        loading={isLoading}
      />
    </Styled>
  )
}
const Styled = styled.div`
  .txt-product-name {
    cursor: pointer;
    :hover {
      /* color: red; */
      font-weight: bold;
    }
  }
`
