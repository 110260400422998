import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { DateDisplay, displayDate } from '../../utils/common'
import InfiniteScroll from 'react-infinite-scroll-component'

import styled from 'styled-components'
import { LinearProgress } from '@mui/material'
import { PieChart } from '@mui/x-charts'

import { useTranslation } from 'react-i18next'

import { BsGraphUpArrow, BsCheckLg } from 'react-icons/bs'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { TbPointFilled } from 'react-icons/tb'

import { getProjectDashboardById, getLogs } from '../../redux/actions/projectDashboard'
import { getImage } from '../../redux/actions/master'
import { selectProjectDashboard, selectMaster } from '../../redux/selectors'
import DialogTaskPreviewer from '../../components/dialog/DialogTaskPreviewer'

// style
const Div = styled.div`
  .text-bold {
    font-weight: bold;
  }
  .header {
    display: flex;
    align-items: center;
    color: var(--Gray-600);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    .highlight {
      color: var(--Primary-700);
      font-weight: 600;
    }
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .mr-8-px {
    margin-right: 8px;
  }
  .d-flex {
    display: flex;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .justify-content-between {
    justify-content: space-between;
  }
  .justify-content-around {
    justify-content: space-around;
  }
  .justify-content-evenly {
    justify-content: space-evenly;
  }
  .align-items-center {
    align-items: center;
  }
  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }
  .button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 12px;
  }
  .text-bold {
    font-weight: bold;
  }
  .text-2rem {
    font-size: 1.5rem;
  }
  .badge {
    padding: 2px 8px;
    align-items: center;
    color: var(--BADGE-1-Font);
    background: var(--BADGE-1-Bg);
    border: 1px solid var(--BADGE-1-Border);
    border-radius: 16px;
    display: inline-block;
  }
  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid var(--Status-Icon-Stroke);
    color: var(--Status-Icon-Font);
    background: var(--Status-Icon-Bg);

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: var(--Status-Active);

      &.inactive {
        color: var(--Status-Inactive);
      }

      &.draft {
        color: var(--Status-Draft);
      }
    }
  }
  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .search-container {
    display: grid;
    grid-template-columns: repeat(4, 250px) 120px;
    grid-template-rows: auto;
    align-content: center;
    column-gap: 1rem;
  }
  .content-header {
    background: var(--Gray-50);
    border-radius: 8px;
    padding: 12px 16px;
  }
  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Base-White);
      border: 1px solid var(--Gray-200);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .drop-down {
    min-width: 10rem;
  }
  .icon {
    cursor: pointer;
  }
  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }
  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }
  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }
  .error-input {
    border: 1px solid red;
  }
  .side-panel-container {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .side-panel-container-2 {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }
  .create-access-role-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }
  .input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .input-header {
    color: var(--gray-700);
    font-size: 16px;
    font-weight: 500;
  }
  .permission-header {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .permission-content {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .text-underline {
    text-decoration: underline;
  }
  .text-color-primary {
    color: #7f56d9;
  }
  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .tab-content {
    overflow: auto;
    .view-information-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }
  }
  .share-list-search-container {
    display: grid;
    grid-template-columns: repeat(2, 10rem) 8rem;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .divider {
    margin: 2rem 0;
    border: 1px solid var(--gray-200, #eaecf0);
  }
  .modal-content {
    width: 640px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--Gray-600);
  }
  .change-status-content {
    padding: 20px;
    width: 384px;
  }
  .category-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    height: 500px;

    .category {
      padding: 1rem 2rem;
      border-right: 1px solid var(--Gray-200);
      .category-item {
        display: flex;
        align-items: center;
        padding: 8px;
        border-radius: 8px;
        background: var(--Base-White);
        align-items: center;
        cursor: pointer;

        &.active {
          background: var(--Primary-600);
          color: var(--Base-White);
        }
      }
    }

    .category-view {
      padding: 1rem 2rem;
      .view-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .view-link {
          cursor: pointer;
          color: var(--Primary-700);
          font-weight: 600;
        }
      }
    }
  }
  .search-content {
    padding: 10px;
    border-bottom: 1px solid var(--Gray-200);
  }
  .search-input {
    position: relative;
    color: var(--Gray-500);

    .input-field {
      box-sizing: border-box;
      padding: 10px;
      padding-left: 40px;
      border: none;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .start-icon {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .end-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  .preview-button {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }
  .headline {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
  .error {
    color: var(--Error-600);
  }
  .error-border {
    border: 1px solid var(--Error-300);
  }
  .card-container {
    .card-header {
      padding: 20px 24px;
      border-radius: 8px 8px 0 0;
      .title-header {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 28px;

        &.color-1 {
          color: var(--Base-White);
        }

        &.color-2 {
          color: var(--Gray-900);
        }
      }
      .sub-header {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        &.color-1 {
          color: var(--Gray-50);
        }

        &.color-2 {
          color: var(--Gray-600);
        }
      }
      &.bg-1 {
        background: var(--Header-Bg);
      }
      &.bg-2 {
        background: var(--Gray-50);
        border: 1px solid var(--Gray-200);
      }
    }

    .card-content {
      position: relative;
      padding: 24px;
      border-radius: 0 0 8px 8px;
      border: 2px solid var(--Gray-200);

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: var(--Gray-500);

      width: 100%;

      .highlight {
        color: var(--Highlight-Text);
        font-weight: 600;
        font-size: 16px;

        &.color-1 {
          color: var(--Gray-700);
        }
      }

      .progress-bar-widht {
        width: 50%;
      }
      .percent-width {
        width: 50%;
      }
    }
    .card-content-2 {
      max-height: 600px;
      overflow: auto;

      padding: 24px;
      border-radius: 0 0 8px 8px;
      border: 2px solid var(--Gray-200);

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: var(--Gray-500);

      .highlight {
        color: var(--Highlight-Text);
        font-weight: 600;
        font-size: 16px;

        &.color-1 {
          color: var(--Gray-700);
        }
      }

      .progress-bar-widht {
        width: 50%;
      }
      .percent-width {
        width: 50%;
      }
    }
  }
  .stp-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    width: 100%;
    white-space: nowrap;
  }
  .stp-info {
    flex: 0 0 auto;
  }
  .stp-connect-line {
    flex: 0 0 auto;
    height: 2px;
    background-color: var(--Gray-200);
    background-color: var(--Stepper-Line);
    width: 18%;
    vertical-align: middle;
    margin-top: 15px;
  }
  .step-item {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid var(--Stepper-Line);

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 1rem auto;

    .step-icon {
      color: var(--Stepper-Icon);

      &.inactive {
        color: var(--Gray-200);
      }
    }

    &.inactive {
      border: 1px solid var(--Gray-200);
    }
  }
  .milestone-info {
    text-align: center;
    color: var(--Gray-700);
    font-weight: 400;

    &.last-milestone-info {
      margin-right: 0;
    }
    &.active {
      color: var(--Highlight-Text);
    }
    .milestone-name {
      font-weight: 600;
    }
  }
  .three-column-container {
    display: flex;
    justify-content: space-between;

    .item {
      width: 30%;
    }
  }
  .member {
    display: flex;
    align-items: center;

    img {
      margin-right: 12px;
      width: 48px;
      border-radius: 50%;
    }

    .name {
      color: var(--Gray-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    .position {
      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .chart-container {
    width: 100%;
    height: 300px;
  }
  .chart-label-container {
    position: absolute;
    right: 5em;
    top: 2em;
  }
  .pie-chart-color-label-1 {
    color: var(--Chart-Color-1);
  }
  .pie-chart-color-label-2 {
    color: var(--Chart-Color-2);
  }
  .pie-chart-color-label-3 {
    color: var(--Chart-Color-3);
  }
  .log-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      margin-right: 12px;
      border-radius: 50%;
      width: 48px;
      height: 48px;
    }
    .name {
      color: var(--Gray-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-right: 8px;
    }
    .time-active {
      color: var(--Gray-600);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }

    .task {
      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
  .vertical-line {
    width: 2px;
    background: var(--Gray-200);
    height: 3rem;
    margin: 1rem 0 1rem 1.2rem;
  }
`
const CustomProgress = styled(LinearProgress)`
  width: 100%;
  &.MuiLinearProgress-root {
    background-color: var(--Gray-200);
    height: 8px;
    border-radius: 8px;
  }
  .MuiLinearProgress-bar {
    background-color: var(--Primary-600);
  }
`
function ProjectDashboardDetail() {
  const { t } = useTranslation()
  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // store reducer
  const { projectDashboardInfo } = useSelector(selectProjectDashboard)
  const { imageList } = useSelector(selectMaster)

  // state
  const [isShowDialogPreviewer, setIsShowDialogPreviewer] = useState(false)
  const [currentPreviewType, setcurrentPreviewType] = useState('')
  const [taskId, setTaskId] = useState('')

  // useEffect for fetching Project Dashboard by id and logs
  useEffect(() => {
    const { id } = params
    if (id) {
      dispatch(getProjectDashboardById(id))
    }
  }, [])

  // useEffect for getting image list
  useEffect(() => {
    const { project_task_teams } = projectDashboardInfo
    if (project_task_teams?.length > 0) {
      const params = project_task_teams.map((v, i) => ({ [`filters[id][$in][${i}]`]: v?.employees[0].photo }))
      dispatch(getImage(params))
    }
  }, [projectDashboardInfo])

  const calculatePercentTask = (task) => {
    const total = projectDashboardInfo?.task_complete + projectDashboardInfo?.task_remaining
    const result = total > 0 ? (task * 100) / total : 0
    return result
  }

  const calculatePercentTime = (task) => {
    const total = projectDashboardInfo?.time_consuming + projectDashboardInfo?.time_remaining
    const result = total > 0 ? (task * 100) / total : 0
    return result
  }

  const backToProjectDashboard = () => {
    navigate('/dashboard/project-dashboard')
  }

  const urlImage = (id) => {
    return imageList.find((v) => v.id === id)?.url
  }

  const handleClickedLogItem = (data) => {
    if (data) {
      setcurrentPreviewType('task_milestone')
      setTaskId(data.id)
      setIsShowDialogPreviewer(true)
    }
  }

  const [logs, setLogs] = useState([])
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const itemsPerPage = 10

  const fetchMoreData = () => {
    const { id } = params
    const filter = {
      page: page,
      pageSize: itemsPerPage,
    }

    dispatch(getLogs(id, filter))
      .then(({ data, meta }) => {
        if (data.length === 0 || logs.length > meta.pagination.total) {
          setHasMore(false)
          return
        }

        setLogs((prevLogs) => [...prevLogs, ...data])
        setPage((prevPage) => prevPage + 1)
      })
      .catch((error) => {
        setHasMore(false)
      })
  }

  useEffect(() => {
    fetchMoreData()
  }, [])

  return (
    <Div>
      <div className="header mb-2-rem">
        <BsGraphUpArrow size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight size={20} className="mr-8-px" />
        <span className="icon mr-8-px" onClick={backToProjectDashboard}>
          {t('project_dashboard')}
        </span>
        <MdOutlineKeyboardArrowRight size={20} className="mr-8-px" />
        <span className="highlight">{projectDashboardInfo?.name}</span>
      </div>

      <div className="mb-1-rem">
        <div className="content-title">{projectDashboardInfo?.name}</div>
        <div>{t('project_dashboard')}</div>
      </div>

      <div className="card-container mb-1-rem">
        <div className="card-header bg-1">
          <div className="title-header color-1">{t('project_detail')}</div>
        </div>
        <div className="card-content">
          <div className="d-flex justify-content-between">
            <div>
              <div className="mb-8-px">{t('project_status')}</div>
              <div className="badge">{projectDashboardInfo?.status}</div>
            </div>
            <div>
              <div className="mb-8-px">{t('project_manager')}</div>
              <div className="highlight">{`${projectDashboardInfo?.project_manager?.first_name} ${projectDashboardInfo?.project_manager?.last_name}`}</div>
            </div>
            <div>
              <div className="mb-8-px">{t('project_start_date')}</div>
              <div className="highlight color-1">{DateDisplay(projectDashboardInfo?.start_date)}</div>
            </div>
            <div>
              <div className="mb-8-px">{t('project_due_date')}</div>
              <div className="highlight color-1">{DateDisplay(projectDashboardInfo?.end_date)}</div>
            </div>
            <div>
              <div className="mb-8-px">{t('project_overall_progress')}</div>
              <div className="d-flex align-items-center">
                <CustomProgress className="mr-8-px" variant="determinate" value={projectDashboardInfo?.progress} />
                <div className="percent-width"> {projectDashboardInfo?.progress} %</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-container mb-1-rem">
        <div className="card-header bg-1">
          <div className="title-header color-1">
            {t('milestone_progress')} {projectDashboardInfo?.progress}%
          </div>
          <div className="sub-header color-1">
            {t('current_milestone')} : {projectDashboardInfo?.current_milestone}
          </div>
        </div>
        <div className="card-content">
          <div className="stp-container">
            {projectDashboardInfo?.milestones?.map((v, i) => (
              <React.Fragment key={v?.id}>
                <div className="stp-info">
                  <div className={`step-item ${v.status === 'Open' ? 'inactive' : ''}`}>
                    {v.status === 'Complete' ? (
                      <BsCheckLg className="step-icon" size={20} />
                    ) : v.status === 'Doing' ? (
                      <TbPointFilled className="step-icon" size={20} />
                    ) : (
                      <TbPointFilled className="step-icon inactive" size={20} />
                    )}
                  </div>
                  <div
                    className={`milestone-info ${
                      i === projectDashboardInfo?.milestones?.length - 1 ? 'last-milestone-info' : ''
                    } ${v.status === 'Doing' ? 'active' : ''}`}
                  >
                    <div className="milestone-name">{v?.name}</div>
                    <div>
                      {t('due')}: {v?.due_date}
                    </div>
                  </div>
                </div>
                {i !== projectDashboardInfo?.milestones?.length - 1 && <div className="stp-connect-line"></div>}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      <div className="three-column-container">
        <div className="card-container item">
          <div className="card-header bg-2">
            <div className="title-header color-2">{t('team_members')}</div>
            <div className="sub-header color-2">{t('who_is_involved_in_this_project')}</div>
          </div>
          <div className="card-content">
            {projectDashboardInfo?.project_task_teams?.map((v, i) => (
              <div className="member mb-1-rem" key={v?.id}>
                <img className="" src={urlImage(v?.employees[0]?.photo)} />
                <div>
                  <div className="name">
                    {v?.employees[0]?.first_name} {v?.employees[0]?.last_name}
                  </div>
                  <div className="position">{v?.employees[0]?.department?.name}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="item">
          <div className="card-container mb-1-rem">
            <div className="card-header bg-2">
              <div className="title-header color-2">{t('complete_task')}</div>
              <div className="sub-header color-2">
                Complete {projectDashboardInfo?.task_complete} of{' '}
                {projectDashboardInfo?.task_remaining + projectDashboardInfo?.task_complete} tasks.
              </div>
            </div>
            <div className="card-content">
              <div className="chart-label-container">
                <div className="d-flex align-items-center">
                  <TbPointFilled className="pie-chart-color-label-1" size={20} />
                  <span>{t('task_complete')}</span>
                </div>
                <div className="d-flex align-items-center">
                  <TbPointFilled className="pie-chart-color-label-2" size={20} />
                  <span>{t('task_remaining')}</span>
                </div>
              </div>
              <div className="chart-container">
                <PieChart
                  colors={['var(--Chart-Color-1)', 'var(--Chart-Color-2)', 'var(--Chart-Color-3)']}
                  series={[
                    {
                      data: [
                        { value: calculatePercentTask(projectDashboardInfo?.task_complete) },
                        { value: calculatePercentTask(projectDashboardInfo?.task_remaining) },
                      ],
                      innerRadius: 45,
                      outerRadius: 82,
                      paddingAngle: 0,
                      cornerRadius: 0,
                      startAngle: -180,
                      endAngle: 180,
                      cx: 150,
                    },
                  ]}
                  sx={{ width: '100%', height: 'auto' }}
                />
              </div>
            </div>
          </div>

          <div className="card-container mb-1-rem">
            <div className="card-header bg-2">
              <div className="title-header color-2">{t('time_out_monitoring')}</div>
              <div className="sub-header color-2">{t('time_have_you_spent')}</div>
            </div>
            <div className="card-content">
              <div className="chart-label-container">
                <div className="d-flex align-items-center">
                  <TbPointFilled className="pie-chart-color-label-1" size={20} />
                  <span>{t('time_consuming')}</span>
                </div>
                <div className="d-flex align-items-center">
                  <TbPointFilled className="pie-chart-color-label-2" size={20} />
                  <span>{t('time_remaining')}</span>
                </div>
              </div>
              <div className="chart-container">
                <PieChart
                  colors={['var(--Chart-Color-1)', 'var(--Chart-Color-2)', 'var(--Chart-Color-3)']}
                  series={[
                    {
                      data: [
                        { value: calculatePercentTime(projectDashboardInfo?.time_consuming) },
                        { value: calculatePercentTime(projectDashboardInfo?.time_remaining) },
                      ],
                      innerRadius: 45,
                      outerRadius: 82,
                      paddingAngle: 0,
                      cornerRadius: 0,
                      startAngle: -180,
                      endAngle: 180,
                      cx: 150,
                    },
                  ]}
                  sx={{ width: '100%', height: 'auto' }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card-container item">
          <div className="card-header bg-2">
            <div className="title-header color-2">{t('logs')}</div>
            <div className="sub-header color-2">{t('updating_progress_with_recent_activity')}.</div>
          </div>

          <div id="logs" className="card-content-2">
            {logs?.length > 0 ? (
              <InfiniteScroll
                dataLength={logs?.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                scrollableTarget="logs"
              >
                {logs?.map((v, i) => (
                  <React.Fragment key={v?.id}>
                    <div className="log-container" onClick={() => handleClickedLogItem(v?.meta_data)}>
                      <img src={v?.meta_data?.photo} />
                      <div>
                        <div>
                          <span className="name">{v?.meta_data?.creator}</span>
                          <span className="time-active">{displayDate(v?.event_date)}</span>
                        </div>
                        <div>
                          <span className="mr-8-px">{v?.meta_data?.action}</span>
                          <span className="task">Task #{v?.meta_data?.task_id}</span>
                        </div>
                      </div>
                    </div>
                    {i !== logs.length - 1 && <div className="vertical-line"></div>}
                  </React.Fragment>
                ))}
              </InfiniteScroll>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {isShowDialogPreviewer && (
        <DialogTaskPreviewer
          taskId={taskId}
          open={isShowDialogPreviewer}
          onClose={() => setIsShowDialogPreviewer(false)}
          previewType={currentPreviewType}
        />
      )}
    </Div>
  )
}

export default ProjectDashboardDetail
