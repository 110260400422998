import { Chip, Dropdown, Menu as JoyMenu, MenuButton, MenuItem } from '@mui/joy'
import { Badge, Divider, Tooltip } from '@mui/material'
import { styled } from '@mui/system'
import { createElement, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useWebSocket, { ReadyState } from 'react-use-websocket'

// Icons
import { IoChevronForward } from 'react-icons/io5'
import { VscCircleLargeFilled } from 'react-icons/vsc'

import { BASE_WEB_SOCKET } from 'configs/app'
import { CONFIG_WEBSOCKET } from 'configs/websocket'
import { getNotificationsUnReade } from 'redux/actions/notifications'
import { selectNotificatons } from 'redux/selectors'
import { mappedIcons } from 'utils/sideNavHelpers'

const modifiers = [
  {
    name: 'offset',
    options: {
      offset: ({ placement }) => {
        if (placement.includes('end')) {
          return [8, 20]
        }
        return [-8, 20]
      },
    },
  },
]

const lastJsonMessageOptions = {
  share: true,
  filter(message) {
    const evt = JSON.parse(message.data)
    return evt.type === 'notification'
  },
}

const Menu = ({
  mode = 'default',
  items = [],
  onExtra = (childKey) => {},
  rootStyle = {},
  isCollapse,
  onNotificationClick,
  rootProps = {},
}) => {
  const dispatch = useDispatch()
  const { sendJsonMessage, readyState } = useWebSocket(BASE_WEB_SOCKET, CONFIG_WEBSOCKET)
  const { lastJsonMessage } = useWebSocket(BASE_WEB_SOCKET, lastJsonMessageOptions)
  const { isRefreshNotification } = useSelector(selectNotificatons)

  const { user } = JSON.parse(localStorage.getItem('admin')) || {}

  const group_1 = items.filter((item) => item.group_menu === 'group_1' || item.group_menu === 'myAccount_group') || []
  const group_2 = items.filter((item) => item.group_menu === 'group_2') || []

  const hasGroup_1 = group_1.length > 0
  const hasGroup_2 = group_2.length > 0

  const groupedNavUlProps = { items, isCollapse, mode, onExtra }

  useEffect(() => {
    if (user?.id && readyState === ReadyState.OPEN) {
      sendJsonMessage({
        user_id: user?.id,
        name: user?.first_name + ' ' + user?.last_name,
        type: 'userevent',
      })
    }
  }, [user, sendJsonMessage, readyState])

  useEffect(() => {
    dispatch(getNotificationsUnReade())
  }, [lastJsonMessage, dispatch])

  useEffect(() => {
    if (isRefreshNotification) {
      dispatch(getNotificationsUnReade())
    }
  }, [isRefreshNotification, dispatch])

  useEffect(() => {
    dispatch(getNotificationsUnReade())
  }, [dispatch])

  return (
    <StyledMenu sx={{ ...rootStyle }} {...rootProps}>
      <StyledNavContainer className="erp-menu-root">
        {mode === 'search' ? (
          <GroupedNavUl {...groupedNavUlProps} onNotificationClick={() => onNotificationClick?.()} />
        ) : (
          <>
            {hasGroup_1 && (
              <GroupedNavUl
                {...groupedNavUlProps}
                items={group_1}
                onNotificationClick={() => onNotificationClick?.()}
              />
            )}

            {hasGroup_2 && (
              <>
                {hasGroup_1 &&
                  createElement(Divider, { sx: { margin: '0.5rem 0', borderColor: 'var(--Primary-500)' } })}

                <GroupedNavUl {...groupedNavUlProps} items={group_2} />
              </>
            )}
          </>
        )}
      </StyledNavContainer>
    </StyledMenu>
  )
}

export default Menu

const GroupedNavUl = ({
  items = [],
  isCollapse = false,
  mode = 'default',
  onExtra = (childKey) => {},
  onNotificationClick,
}) => {
  const lang = localStorage.getItem('lang') || 'en'

  const navigate = useNavigate()
  const refSubNavUl = useRef([])
  const { notificationUnReadTotal } = useSelector(selectNotificatons)

  const [open, setOpen] = useState(items.map((item) => ({ key: item.key, open: false })))
  const [menuKey, setMenuKey] = useState(null)

  const convertNotificationToString = () => {
    if (notificationUnReadTotal > 0) {
      if (notificationUnReadTotal > 99) {
        return '99+'
      }

      return notificationUnReadTotal.toString()
    }

    return null
  }

  const createHandleLeaveMenu = (key) => {
    return () => {
      setMenuKey((prevKey) => {
        if (key === prevKey) {
          return null
        }
        return prevKey
      })
    }
  }

  useEffect(() => {
    if (isCollapse) {
      setOpen(items.map((item) => ({ key: item.key, open: false })))
      setMenuKey(null)
    }
  }, [isCollapse])

  return (
    <StyledNavUl>
      {items.map((item, index) => {
        const { key, name_th, name_en, pathto, children, permission_tab, permission_other } = item // latest JSON schema ⭐️
        const title = lang === 'en' ? name_en : name_th

        const isKeyOpen = open.find((o) => o.key === key)?.open
        const isInbox = key === 'new_message' // NOTE: This is a special case for the Inbox menu item.

        const handleToggleOpen = () => {
          setMenuKey(null)

          if (!children || children.length === 0) {
            if (typeof pathto === 'function') {
              return pathto()
            }

            return pathto && navigate(pathto)
          }

          setOpen((prev) => {
            return prev.map((o) => {
              if (o.key === key) {
                return { key, open: !o.open }
              }
              return o
            })
          })
        }

        return (
          <StyledNavLi
            key={key}
            className={`erp-menuitem-root ${children ? 'erp-submenu-root' : ''} ${isKeyOpen ? 'erp-open' : ''}`}
          >
            <Dropdown open={menuKey === key} onOpenChange={(_, isOpen) => isOpen && setMenuKey(key)}>
              <MenuButton slots={{ root: 'div' }} onClick={() => setMenuKey(key)} onMouseLeave={() => setMenuKey(null)}>
                <Tooltip arrow disableHoverListener={!isCollapse} title={title} placement="right">
                  <a
                    className={`erp-menu-button ${isKeyOpen ? 'erp-open' : ''}`}
                    onClick={(event) => {
                      if (isInbox) {
                        return onNotificationClick?.()
                      }

                      return handleToggleOpen(event)
                    }}
                    style={{ marginBottom: '0.5rem' }}
                  >
                    {mode !== 'search' && (
                      <span className="erp-menu-icon">
                        {isCollapse && isInbox && notificationUnReadTotal && notificationUnReadTotal > 0 ? (
                          <Badge badgeContent={notificationUnReadTotal} color="error">
                            {mappedIcons(key, mode)}
                          </Badge>
                        ) : (
                          mappedIcons(key, mode)
                        )}
                      </span>
                    )}
                    <StyledNavMenuLabel className={`erp-menu-label`}>{title}</StyledNavMenuLabel>

                    {isInbox && !isCollapse && (
                      <span className={`erp-submenu-expand-icon ${isKeyOpen ? 'erp-open' : ''}`}>
                        {convertNotificationToString() !== null && (
                          <Chip
                            color="var(--Primary-200)"
                            variant="solid"
                            sx={{ fontFamily: "'Inter', 'Noto Sans Thai'", fontSize: '12px' }}
                          >
                            {convertNotificationToString()}
                          </Chip>
                        )}
                      </span>
                    )}

                    {children && children.length > 0 && (
                      <span className={`erp-submenu-expand-icon ${isKeyOpen ? 'erp-open' : ''}`}>
                        {isCollapse ? <VscCircleLargeFilled fontSize={8} /> : <IoChevronForward fontSize={24} />}
                      </span>
                    )}
                  </a>
                </Tooltip>

                {isCollapse && children && children.length > 0 && (
                  <JoyMenu
                    onClose={() => setMenuKey(null)}
                    modifiers={modifiers}
                    placement="right-start"
                    sx={{ width: 288 }}
                    onMouseLeave={createHandleLeaveMenu(key)}
                  >
                    {children?.map((child) => {
                      const {
                        key: childKey,
                        name_th: childNameTh,
                        name_en: childNameEn,
                        pathto: childPathto,
                        children: childChildren,
                        permission_tab: childPermTab,
                        permission_other: childPermOther,
                      } = child // latest JSON schema ⭐️

                      const childTitle = lang === 'en' ? childNameEn : childNameTh

                      const handleToggleOpenSub = () => {
                        if (!childChildren || childChildren.length === 0) {
                          if (typeof childPathto === 'function') {
                            return childPathto()
                          }

                          return childPathto && navigate(childPathto)
                        }

                        mode !== 'extra' && onExtra && onExtra(child)
                      }

                      return (
                        <MenuItem
                          key={childKey}
                          onClick={handleToggleOpenSub}
                          sx={{ fontFamily: "'Inter', 'Noto Sans Thai'" }}
                        >
                          {childTitle}
                        </MenuItem>
                      )
                    })}
                  </JoyMenu>
                )}
              </MenuButton>
            </Dropdown>

            {!isCollapse && children && children.length > 0 && (
              <StyledSubmenu
                className={`erp-submenu-content${isKeyOpen && !isCollapse ? ' erp-open' : ''}`}
                height={refSubNavUl.current[index]?.scrollHeight + 'px'}
              >
                <StyledNavUl ref={(ref) => (refSubNavUl.current[index] = ref)}>
                  {children.map((child) => {
                    const {
                      key: childKey,
                      name_th: childNameTh,
                      name_en: childNameEn,
                      pathto: childPathto,
                      children: childChildren,
                      permission_tab: childPermTab,
                      permission_other: childPermOther,
                    } = child // latest JSON schema ⭐️

                    const childTitle = lang === 'en' ? childNameEn : childNameTh

                    const handleToggleOpenSub = () => {
                      if (!childChildren || childChildren.length === 0) {
                        if (typeof childPathto === 'function') {
                          return childPathto()
                        }

                        return childPathto && navigate(childPathto)
                      }

                      mode !== 'extra' && onExtra && onExtra(child)
                    }

                    return (
                      <StyledNavLi key={childKey} className={`erp-menuitem-root`}>
                        <a className={`erp-menu-button`} onClick={handleToggleOpenSub}>
                          <StyledNavMenuLabel className={`erp-menu-label`}>{childTitle}</StyledNavMenuLabel>
                        </a>
                      </StyledNavLi>
                    )
                  })}
                </StyledNavUl>
              </StyledSubmenu>
            )}
          </StyledNavLi>
        )
      })}
    </StyledNavUl>
  )
}

const StyledSubmenu = styled('div')`
  height: 0px;
  overflow: hidden;
  z-index: 999;
  transition: height 300ms ease 0s;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  position: static !important;
  transform: none !important;

  &.erp-open {
    height: ${(props) => (props.height ? props.height : 'auto')};
    transition: height 300ms ease 0s;
  }

  &.erp-submenu-content {
    & .erp-menu-button {
      padding-left: 30px;
    }
  }
`

const StyledNavMenuLabel = styled('span')`
  -webkit-box-flex: 1;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
`

const StyledNavLi = styled('li')`
  position: relative;
  width: 100%;
  font-size: 16px;
  font-weight: 600;

  .erp-menu-button {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 40px;
    text-decoration: none;
    color: inherit;
    box-sizing: border-box;
    cursor: pointer;
    padding-right: 18px;
    padding-left: 18px;
    border-radius: 6px;
    transition: background-color 0.3s, color 0.3s;
    background: inherit;

    &:hover {
      background-color: var(--Primary-500);
      color: var(--Primary-50);
      transition: background-color 0.3s, color 0.3s;
    }
  }

  .erp-menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    color: inherit;
    width: 24px;
  }

  .erp-submenu-expand-icon {
    display: flex;
    transform: rotate(0);
    transition: transform 0.3s;

    &.erp-open {
      transform: rotate(90deg);
      transition: transform 0.3s;
    }
  }
`

const StyledNavUl = styled('ul')`
  list-style-type: none;
  padding: 0px;
  margin: 0px;
`

const StyledNavContainer = styled('nav')`
  color: inherit;

  &.erp-menu-root {
    width: 100vw;
    max-width: 600px;
    padding: 0 0.5rem;
  }

  @media (min-width: 1024px) {
    &.erp-menu-root {
      width: 100%;
      max-width: none;
      padding: 0;
    }
  }
`

const StyledMenu = styled('div')`
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 0;
  padding: 0 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
  background-color: var(--Primary-600);
  top: 70px;
  left: 0;
  right: 0;
  width: 100%;

  flex: 1 1 0%;
  flex-grow: 1;
  transition: height 0.3s ease;

  &.open {
    height: calc(100vh - 70px);
    padding: 0.5rem;
    transition: all 0.3s ease;
  }

  @media (min-width: 1024px) {
    position: relative;
    height: auto;
    top: 0;
    padding: 0.5rem;
    background-color: inherit;

    &.open {
      height: 100%;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`
