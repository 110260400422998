import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams, useNavigate, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import QRCode from "react-qr-code";
import dayjs from 'dayjs'
import { DateDisplay } from '../../utils/common'
import { getCompanyInfo } from '../../utils/common'
import html2canvas from 'html2canvas'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { v4 as uuidv4 } from 'uuid'

import { LiaTimesSolid } from 'react-icons/lia'
import Button from '../../components/form/button/Button'
import SearchText from '../../components/form/SearchText'
import TableV2 from '../../components/common/TableV2'
import SidePanel from '../../components/common/SidePanel'

import ModalEmployee from './ModalEmployee'

import { getTaskDetailReturnData,getEmployeeListByTaskReturnData,deleteEmployeeTask } from '../../redux/actions/ofm'

import { GrStatusGoodSmall } from 'react-icons/gr'

import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import ModalQR from '../../pages/operation-field/ModalQR'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  } 
  
  .content_header {
    padding: 14px 14px;
    
    width : ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 100px;
    padding-bottom: 170px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    background-color: #FFFFFF;
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }


  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill{
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .content{
    margin:10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }

  .prefix_icon{
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .report_box_form{
    border: 2px solid rgb(208, 213, 221);
    border-radius: 8px;
    padding: 16px 24px;
    margin-bottom:20px;
    background-color: #FCFCFD;
  }
  .report_formname{
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
    /*margin-bottom:16px;*/
  }
  .report_sub_value{
    display: grid;
    /*grid-template-columns: repeat(2, 35% [col-start] 65% [col-end]);*/
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-bottom:16px;
    margin-top:16px;
  }
  .report_sub_value_title{
    /*
    font-size: 16px;
    color: #101828;
    */
    font-size: 16px;
    color: #101828;
    font-weight: 500;
  }
  .report_sub_value_value{
    color: #475467;
    font-size: 16px;
    font-weight: 400;
    
  }
  .report_img{
    display: grid;
    grid-template-columns: repeat(5, 120px);
    gap: 20px;
    margin-bottom:16px;
  }

  .cursor_pointer {
    cursor: pointer;
  }
  .img_in_row{
    width: 120px;
    border-radius: 12px;
  }

  /* ST CSS POPUP IMG */
  .dialog-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  /* Style for the dialog content */
  .dialog-img-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    position: relative;
    max-width: 1200px;
    padding: 0 15px;
  }
  /* Style for the close icon */
  .dialog-img-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0;
  }

  .dialog-img-popup-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  /* ED CSS POPUP IMG */
  .dialog-footer {
    /*margin-bottom: 15px;*/
    /*text-align: end;*/
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .dialog-footer-report{
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .dialog-footer-report-info{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-bottom:16px;
    margin-top:16px;
  }
  .img-profile {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  .user_img_char_1 {
    border-radius: 50%;
    background-color: var(--Main-Button-Bg);
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #FFFFFF;
    cursor: pointer;
  }
  .dialog-footer-report-info-full-name{
    font-size: 18px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .dialog-footer-report-info-position{
    font-size: 16px;
    font-weight: 400;
    color: var(--Primary-700);
  }
  .dialog-footer-report-info-location{
    font-size: 16px;
    font-weight: 500;
    color: var(--Gray-600);
  }

  .content-fillter-bg{
    min-height: 68px;
    background-color: var(--Gray-50);
    border-radius: 8px;
    margin-bottom: 21px;
    padding: 10px;
  }
  .content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
  
    .search-wrapper {
      /*margin-right: 12px;*/
    }
  
    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }
  
    .group_filter {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }
  }

  .table-wrapper {
    /*margin-top: 24px;*/
    border: 1px solid #EAECF0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #FFFFFF;

    .table-header {
        
        display: flex;
        justify-content: space-between;
        
        padding: 0px 14px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-radius: 8px 8px 0 0;
        background: #FFFFFF;

        .group {
            display: flex;
            align-items: center;
        }

        .table-title {
            font-size: 18px;
            font-weight: 600;
            color: var(--Gray-900);
        }

        .table-total {
            margin-left: 16px;
            font-size: 12px;
            font-weight: 500;
            padding: 2px 8px;
            color: var(--Primary-700);
            background: var(--Primary-50);
            border-radius: 16px;
            border: 1px var(--Primary-200) solid;
        }
    }

    .create-bt {
        text-decoration: none;
    }
} 

.text-bold {
    font-weight: bold;
}
.badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 10px;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border: 1.5px solid var(--Gray-300);
    border-radius: 6px;
    font-weight: 500;
  
    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #17b26a;
  
      &.inactive {
        color: red;
      }
    }
  }
  .button_del_sel{
    color: #FFFFFF;
    background-color: #d92d20;
    border: 1px solid #d92d20;
}
`

const DivMain = styled.div`
color: var(--Gray-600);
font-size: 14px;
font-style: normal;
font-weight: 500;
.header {
    display: flex;
    align-items: center;

    .highlight {
        color: var(--Primary-700);
        font-weight: 600;
    }
}
.mb-2-rem {
    margin-bottom: 2rem;
}
.mr-8-px {
    margin-right: 8px;
}
.icon {
    cursor: pointer;
}
.content-title {
    font-size: 30px;
    font-weight: 600;
    color: var(--Gray-900);
}
.border_header{
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
}
.wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        border-bottom: 0px solid #EAECF0;
        color: #667085;
        font-size: 14px;
    }

    .menu-active {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid var(--Primary-600) !important;
        color: #6941C6;
        background-color: #F9F5FF;
    }
}

.css_half{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
}
.zone_box{
    border: 1px solid rgb(208, 213, 221);
    border-radius: 8px;
    cursor: pointer;
    padding: 20px;
    margin-bottom: 24px;
    font-size: 16px;
    color: #344054;
    background-color: #FFFFFF;
    :hover {
        color: #6941C6;
        background-color: #F9F5FF;
        border: 1px solid #E9D7FE;
    }
}
.add_cursor{
    cursor: pointer;
}
.content-fillter-bg{
    min-height: 68px;
    background-color: var(--Gray-50);
    border-radius: 8px;
    margin-bottom: 21px;
    padding: 10px;
}
.content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
  
    .search-wrapper {
      /*margin-right: 12px;*/
    }
  
    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }
  
    .group_filter {
      display: flex;
      align-items: center;
      column-gap: 1rem;
    }
  }
  .button_clear_all{
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 10px;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
.margin_right_5{
    margin-right: 5px;
}
.mr-1-rem {
    margin-right: 1rem;
}
.table-wrapper {
    /*margin-top: 24px;*/
    border: 1px solid #EAECF0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #FFFFFF;

    .table-header {
        
        display: flex;
        justify-content: space-between;
        
        padding: 0px 14px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-radius: 8px 8px 0 0;
        background: #FFFFFF;

        .group {
            display: flex;
            align-items: center;
        }

        .table-title {
            font-size: 18px;
            font-weight: 600;
            color: var(--Gray-900);
        }

        .table-total {
            margin-left: 16px;
            font-size: 12px;
            font-weight: 500;
            padding: 2px 8px;
            color: var(--Primary-700);
            background: var(--Primary-50);
            border-radius: 16px;
            border: 1px var(--Primary-200) solid;
        }
    }

    .create-bt {
        text-decoration: none;
    }
} 
/* ST CSS ALL QR CODE */
.dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  /* Style for the dialog content */
  .dialog-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    position: relative;
    width: 400px;
    padding: 0 15px;
  }
  /* Style for the close icon */
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0;
  }

  .popup-image {
    width: 256px;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .dialog-qr-footer {
    /*margin-bottom: 15px;*/
    /*
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    */
    width : 100%;
    background-color : #FFFFFF;
    /*padding: 14px;*/
    .button_margin {
      margin: 10px;
    }
  }
  .box_qr_code{
    display: flex;
    justify-content: center;
  }
  .dialog-conten-createdate{
    margin-top: 15px;
    text-align: end;
  }
  .box_DowloadQRIMG{
    padding: 10px;
  }
  .box_qr_header{
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .box_qr_header_content{
    font-size: 16px;
    font-weight: 600;
    color: var(--Gray-900);
    text-align: left;
  }
  /* ED CSS ALL QR CODE */
.button_del_sel{
    color: #FFFFFF;
    background-color: #d92d20;
    border: 1px solid #d92d20;
}
.text-bold {
    font-weight: bold;
}
.status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border: 1.5px solid var(--Gray-300);
    border-radius: 6px;
    font-weight: 500;
  
    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #17b26a;
  
      &.inactive {
        color: red;
      }
    }
  }
`


function ModalPermission({ onClose, onSubmit,TaskId,...props }) {
  const dispatch = useDispatch()
  const CompanyInfo = getCompanyInfo()
  const { t } = useTranslation() 
  const params = useParams();
  //const { id:task_id } = params;
  
  const navigate = useNavigate();

  const [ZoneId,setZoneId] = useState(1);  
  const [ZoneName,setZoneName] = useState('');
  const [BaseId,setBaseId] = useState(1);
  const [BaseName,setBaseName] = useState('');
  //const [TaskId,setTaskId] = useState(task_id);
  const [TaskName,setTaskName] = useState('');

  const fetchDataAllJob = useCallback(
    async (filter) => {
        if(TaskId){
            dispatch(getTaskDetailReturnData(TaskId,filter)).then(({ type,data_info,data_meta }) => {
                if(type.endsWith('_SUCCESS')){
                    if(data_info){

                        
                        setZoneId(data_info.base.zone.id);
                        setZoneName(data_info.base.zone.name);

                        setBaseId(data_info.base.id);
                        setBaseName(data_info.base.name);

                        setTaskName(data_info.name);
                        
                        
                    }
                }
            })
        }

    },
    [dispatch]
 );

 useEffect(() => {
    
    const filter = {
        skip: 1,
        limit: 1000,
    }
    fetchDataAllJob(filter)
}, [fetchDataAllJob]) 



  const backToPermissionOFM = () => {
    navigate('/operation-field/PermissionOFM')
  }

  const backToBase = () => {
    navigate(`/operation-field/PermissionOFMBase/${ZoneId}`)
  }

  const backToJob = () => {
    navigate(`/operation-field/PermissionOFMJob/${BaseId}`)
  }

  const [total, settotal] = useState(0);
  const [totalPages, settotalPages] = useState(1);
  const [page, setpage] = useState(1);
  const [limitEmployee, setlimitEmployee] = useState(10)

  const fetchData = useCallback(
    async (filter) => {
      //dispatch(getEmployee(filter))
      if(TaskId){
        dispatch(getEmployeeListByTaskReturnData(TaskId,filter)).then(({ type,data_info,data_meta }) => {
            if(type.endsWith('_SUCCESS')){
                if(data_info){
                    
                    const totalPage = Math.ceil(data_meta.pagination.total / limitEmployee)
                    settotal(data_meta.pagination.total)
                    settotalPages(totalPage)
                    
                    
                    const newsetUpData = data_info.map((v, index) => {
                    const deptColor = v.dept_color ? JSON.parse(v.dept_color) : null;
                      return {
                        no : limitEmployee * page - (9 - index),
                        employee_id : <div className="text-bold">{v.employ_id}</div>,
                        full_name: (
                            <div className="text-bold">
                              {v.first_name} {v.last_name}
                            </div>
                        ),
                        mobile : <div>{v.phone_no ? v.phone_no : '-'}</div>,
                        email : <div>{v.email ? v.email : '-'}</div>,
                        department:
                        v.department ? (
                          <span
                            className="badge"
                            style={{
                              color: deptColor?.text,
                              borderColor: deptColor?.border,
                              backgroundColor: deptColor?.background,
                            }}
                          >
                            {v.department.name}
                          </span>
                        ) : (
                          <span>-</span>
                        ),
                        job_position : <div>{v.position ? v.position.name : '-'}</div>,
                        employee_type : <div>{v.employee_type ? v.employee_type : '-'}</div>,
                        evaluation_date : <div>{v.evaluation_date ? DateDisplay(v.evaluation_date) : '-'}</div>,
                        status: (
                            <div className="status-badge">
                              <GrStatusGoodSmall className={`status ${v.status == 'inactive' ? 'inactive' : ''}`} />
                              {v.status}
                            </div>
                        ),
                        del : <Button  onClick={() => DelData(v)} className="button_del_sel margin_right_5">
                            Delete
                        </Button>
                      }
                    })
                
                    setTableOFMData(newsetUpData)
                    
                    
                }
            }
        })
    }
    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 1,
      limit: limitEmployee,
      /*meeting_id : MeetingID*/
    }
    
    fetchData(filter)
  }, [fetchData])

  const TableOFMColumn = [
    {
        Header: 'No',
        accessor: 'no',
        disableSortBy: false,
    },
    {
        Header: 'Employee id',
        accessor: 'employee_id',
        disableSortBy: false,
    },
    {
        Header: 'Full name',
        accessor: 'full_name',
        disableSortBy: false,
    }, 
    {
        Header: 'Mobile',
        accessor: 'mobile',
        disableSortBy: false,
    },
    {
        Header: 'Email Address',
        accessor: 'email',
        disableSortBy: false,
    },
    {
        Header: 'Department',
        accessor: 'department',
        disableSortBy: false,
    },
    {
        Header: 'Job position',
        accessor: 'job_position',
        disableSortBy: false,
    },
    {
        Header: 'Employee Type',
        accessor: 'employee_type',
        disableSortBy: false,
    },
    {
        Header: 'Evaluation Date',
        accessor: 'evaluation_date',
        disableSortBy: false,
    },
    {
        Header: 'Status',
        accessor: 'status',
        disableSortBy: false,
    },
    {
        accessor: 'del',
        disableSortBy: false,
    },
];
const [TableOFMData, setTableOFMData] = useState([]);  

  const [search, setSearch] = useState('')
  const [searchFilters, setSearchFilters] = useState([]);

  const onSearch = () => {
    const newFilter = {
      key: uuidv4(),
      name: search,
    }
    /*
    const filter = {
      skip: 1,
      limit: limitEmployee,
      other_search :[...searchFilters, newFilter].map((v) => v.name).join(','),
      department : departmentSelected,
      position : positionSelected,
      employee_type : employeetypeSelected
    }
    fetchData(filter)
    setpage(1)
    */
    setSearchFilters((prev) => [...prev, newFilter])
  }

  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    /*
    const filter = {
      skip: 1,
      limit: limitEmployee,
      other_search: newSearchFilters.map((v) => v.name).join(','),
      department : departmentSelected,
      position : positionSelected,
      employee_type : employeetypeSelected
    }
    fetchData(filter)
    setSearch('')
    setpage(1)
    */
  }
  const onClearSearch = () => {
    /*
    const filter = {
      skip: 1,
      limit: limitEmployee,
    }
    fetchData(filter)
    
    setpage(1)
    */
    setSearch('')
  }

  const onClearAllSearch = () => {
    setSearchFilters([]);
    setSearch('')
    const filter = {
      skip: 1,
      limit: limitEmployee,
    }
    fetchData(filter)
    setpage(1)
    /*
    setDepartmentSelected('');
    setPositionSelected('');
    setemployeetypeSelected('');

    const filter = {
      skip: 1,
      limit: limitEmployee,
    }
    fetchData(filter)
    setpage(1)
    */
  }

  const onChangePagination = async (value) => {
    /*
    const filter = {
        //skip: value.page * limitEmployee - limitEmployee,
        skip: value.page,
        limit: limitEmployee,
        meeting_id : MeetingID,
        other_search:searchFilters.map((v) => v.name).join(','),
        department : departmentSelected,
        position : positionSelected
    }
    fetchData(filter)
    */
    const filter = {
      skip: value.page,
      limit: limitEmployee,
    }
    fetchData(filter)
    setpage(value.page)
}
const [dialogQR, setdialogQR] = useState(false);
const [QRDisplay, setQRDisplay] = useState({});
const [valueQR, setvalueQR] = useState({});
const [QRDate, setQRDate] = useState('');
    const OpenQRCode = () => {

      const currentDate_now = dayjs().format('YYYY-MM-DD HH:mm:ss');
      setQRDate('Created at : ' + DateDisplay(currentDate_now));
      const data_qr_display = {
          "company_id" : CompanyInfo?.id,
          "zone" : ZoneId,
          "display_zone" : ZoneName,
          "base" : BaseId,
          "display_base" : BaseName,
          "task" : TaskId,
          "display_task" : TaskName,
          "created" : 'Created at : ' + DateDisplay(currentDate_now)
      };
      setQRDisplay(data_qr_display);
  
      const data_qr = {
          "company_id" : CompanyInfo?.id,
          "zone" : ZoneId,
          "base" : BaseId,
          "task" : TaskId
      };
      setvalueQR(data_qr);
      setdialogQR(true);
    }

    const ClosetoggleDialogQR = () => {
        setdialogQR(false);
    
    };


    const [WidthMeetingCalendar, setWidthMeetingCalendar] = useState('1400')
    const [onModalAttendances, setonModalAttendances] = useState(false)
    const OpenAddUser = () => {
        setWidthMeetingCalendar(window.innerWidth - 80)
        //setWidthMeetingCalendar(830);
        setonModalAttendances(true)
    }
    const CloseModalAddUser = () => {
        setonModalAttendances(false)
    }
    const SubmitModalAddUser = () => {
        setonModalAttendances(false)
        onClearAllSearch()
    }

    const DowloadQR = async () => {
        //alert('DowloadQR');
        const element = document.getElementById('DowloadQRIMG'),
        canvas = await html2canvas(element),
        data = canvas.toDataURL('image/jpg'),
        link = document.createElement('a');
    
        link.href = data;
        link.download = 'QRCode.jpg';
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const [ModalConfirmDel, setModalConfirmDel] = useState(false)
    const [ThisId, setThisId] = useState('')
    const DelData = (data) => {
      //alert(JSON.stringify(data))
      setThisId(data.id)
      setModalConfirmDel(true)
    }

    const [Titlesuccess, setTitlesuccess] = useState('')
    const [SubTitlesuccess, setSubTitlesuccess] = useState('')
    const [Descriptionsuccess, setDescriptionsuccess] = useState('')
    const [successModal, setsuccessModal] = useState(false)
    const [TitleFail, setTitleFail] = useState('')
    const [nameItemFail, setnameItemFail] = useState('')
    const [DescriptionFail, setDescriptionFail] = useState('')
    const [failModal, setFailModal] = useState(false)
    
    const SubmitModalConfirmDel = () => {
      /*
      const sent_data_api = {
        data: { active: 0 },
      }
      dispatch(updateDailySummary(ThisId, sent_data_api))
      
      setModalConfirmDel(false)
      */
     
      setModalConfirmDel(false);
      dispatch(deleteEmployeeTask(TaskId, ThisId)).then(({ type }) => {
        if(type.endsWith('_SUCCESS')){
          onClearAllSearch();
          setTitlesuccess('Delete User Success');
          setSubTitlesuccess('');
          setDescriptionsuccess(''); 
          setsuccessModal(true)
        }else{
          setTitleFail('Error Delete User');
          setnameItemFail('');
          setDescriptionFail(''); 
          setFailModal(true)
        }    
      })
    }

  return (
    <Div {...props}>
        <div className="content_header">
            <header>
            <div className="content-title">Task : {TaskName}</div>
            <div className="wrp-tool-right">
                <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
            </div>
            </header>
            <hr />
        </div>

        
        {
            /*
            <div className="content-title">Task : {TaskName}</div>
            <div className="border_header"></div>
            */
        }
        <div className="dialog-content">
            <div className="content-fillter-bg">
                <div className="content-fillter">
                    <div className="group_filter">
                        <div>
                            <SearchText
                                className="search-wrapper"
                                placeholder={t('search')}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onSubmit={onSearch}
                                onClear={onClearSearch}
                            />                                              
                        </div>
                        <div>
                            <Button onClick={onClearAllSearch} className="button_clear_all mr-1-rem">
                            {t('clear_all')}
                            </Button>
                        </div>
                    </div>
                    <div className="container">

                        <div>
                            <Button onClick={OpenAddUser}>
                                {'Add User to List'}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="badge-search-content">
                    {searchFilters.map((v) => (
                    <div className="badge" key={v.key}>
                        <span className="name">{v.name}</span>{' '}
                        <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
                    </div>
                    ))}
                </div>
            </div>
            <div className="table-wrapper">
                <div className="table-header">
                    <div className="group">
                        <div className="table-title">List of employee with permission</div>
                        <div className="table-total">{total} item</div>
                    </div>
                    <div>
                    </div>
                </div>
                <TableV2
                    columns={TableOFMColumn}
                    data={TableOFMData}
                    onStateChange={onChangePagination}
                    pageCount={totalPages}
                    page={page}
                    loading={false}
                />          
            </div>
        </div>



        <SidePanel isOpen={onModalAttendances} setIsOpen={setonModalAttendances} width={WidthMeetingCalendar}>
            <ModalEmployee width={WidthMeetingCalendar} onClose={CloseModalAddUser} onSubmit={SubmitModalAddUser} TaskId={TaskId} TaskName={TaskName} />
        </SidePanel>
        <ModalQR
          open={Boolean(dialogQR)}
          onClose={() => setdialogQR(false)}
          QRDisplay={QRDisplay}
          valueQR={valueQR}
        />
        <DialogConfirmDelete
          open={Boolean(ModalConfirmDel)}
          onClose={() => setModalConfirmDel(false)}
          onSubmit={SubmitModalConfirmDel}
          title={'Delete'}
          nameItem={'Do you want to delete this user from'}
          description={`Zone : ${ZoneName} | Base : ${BaseName}`}
        /> 

        <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => setsuccessModal(false)}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />           
    </Div>
  )
}

export default ModalPermission