import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IoPersonOutline } from 'react-icons/io5'

export default function AssignTo({ name, rootProps, prefix = true }) {
  const { t } = useTranslation()

  return (
    <Stack direction="row" spacing={1} {...rootProps}>
      <Stack>
        <IoPersonOutline size={16} />
      </Stack>
      <Stack>
        <p>{`${prefix && `${t('assign_to')}:`} ${name || '-'}`}</p>
      </Stack>
    </Stack>
  )
}
