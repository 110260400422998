import React from 'react'
import Drawer from '@mui/material/Drawer'
import styled from 'styled-components'

import PropTypes from 'prop-types'

const StyledDrawer = styled(Drawer)`
  /* .MuiDrawer-paper {
    width: ${(props) => props.width}px;
  } */
`

const SidePanel = ({ children, isOpen, setIsOpen, position = 'right', width = 250, ...props }) => {
  const drawerStyle = position === 'left' ? { left: '80px', width: `${width}px` } : { width: `${width}px` }

  const toggleDrawer = (open) => (event) => {
    setIsOpen(open)
  }

  return (
    <div>
      <StyledDrawer
        anchor={position}
        open={isOpen}
        onClose={toggleDrawer(false)}
        ModalProps={{
          BackdropProps: {
            onClick: (e) => e.stopPropagation(),
          },
        }}
        // width={width}
        PaperProps={{ style: drawerStyle }}
        {...props}
      >
        {children}
      </StyledDrawer>
    </div>
  )
}

SidePanel.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  position: PropTypes.string,
  width: PropTypes.number,
}

export default SidePanel
