import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import SearchText from '../../components/form/SearchText'
import Table from '../../components/common/Table'
import Dropdown from '../../components/form/Dropdown'
import ButtonOutline from '../../components/form/button/ButtonOutline'

import { BsGraphUpArrow } from 'react-icons/bs'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { GrStatusGoodSmall } from 'react-icons/gr'
import { LiaTimesSolid } from 'react-icons/lia'

import { getEmployeeDashboards } from '../../redux/actions/employeeDashboard'
import { getUserManagementDepartment } from '../../redux/actions/userlist'
import { selectEmployeeDashboard, selectUser } from '../../redux/selectors'

// style
const Div = styled.div`
  .header {
    display: flex;
    align-items: center;
    color: var(--Gray-600);

    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    .highlight {
      color: var(--Primary-700);
      font-weight: 600;
    }
  }

  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .mb-8-px {
    margin-bottom: 8px;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .mr-8-px {
    margin-right: 8px;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .align-items-center {
    align-items: center;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 12px;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-2rem {
    font-size: 1.5rem;
  }

  .badge {
    padding: 2px 8px;
    align-items: center;
    color: var(--BADGE-1-Font);
    background: var(--BADGE-1-Bg);
    border: 1px solid var(--BADGE-1-Border);
    border-radius: 16px;

    &.hr {
      color: var(--BADGE-5-Font);
      background: var(--BADGE-5-Bg);
      border: 1px solid var(--BADGE-5-Border);
    }
    &.product {
      color: var(--BADGE-11-Font);
      background: var(--BADGE-11-Bg);
      border: 1px solid var(--BADGE-11-Border);
    }
    &.marketing {
      color: var(--BADGE-12-Font);
      background: var(--BADGE-12-Bg);
      border: 1px solid var(--BADGE-12-Border);
    }
    &.logistic {
      color: var(--BADGE-14-Font);
      background: var(--BADGE-14-Bg);
      border: 1px solid var(--BADGE-14-Border);
    }
  }

  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid var(--Status-Icon-Stroke);
    color: var(--Status-Icon-Font);
    background: var(--Status-Icon-Bg);

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: var(--Status-Active);

      &.inactive {
        color: var(--Status-Inactive);
      }

      &.draft {
        color: var(--Status-Draft);
      }
    }
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .search-container {
    display: grid;
    grid-template-columns: repeat(4, 250px) 120px;
    grid-template-rows: auto;
    align-content: center;
    column-gap: 1rem;
    /* margin-bottom: 1rem; */
  }

  .content-header {
    /* margin-bottom: 24px; */
    background: var(--Gray-50);
    border-radius: 8px;
    padding: 12px 16px;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Base-White);
      border: 1px solid var(--Gray-200);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error-input {
    border: 1px solid red;
  }

  .side-panel-container {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .side-panel-container-2 {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .create-access-role-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-header {
    color: var(--gray-700);
    font-size: 16px;
    font-weight: 500;
  }

  .permission-header {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .permission-content {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .text-underline {
    text-decoration: underline;
  }
  .text-color-primary {
    color: #7f56d9;
  }
  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab-menu-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding-bottom: 0.5rem;
      &.active {
        color: #7f56d9;
        border-bottom: 2px solid #7f56d9;
        font-weight: bold;
      }
    }
  }
  .tab-content {
    overflow: auto;
    .view-information-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }
  }

  .share-list-search-container {
    display: grid;
    grid-template-columns: repeat(2, 10rem) 8rem;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--gray-200, #eaecf0);
  }

  .modal-content {
    width: 640px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--Gray-600);
  }

  .change-status-content {
    padding: 20px;
    width: 384px;
  }

  .category-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    height: 500px;

    .category {
      padding: 1rem 2rem;
      border-right: 1px solid var(--Gray-200);
      .category-item {
        display: flex;
        align-items: center;
        padding: 8px;
        border-radius: 8px;
        background: var(--Base-White);
        align-items: center;
        cursor: pointer;

        &.active {
          background: var(--Primary-600);
          color: var(--Base-White);
        }
      }
    }

    .category-view {
      padding: 1rem 2rem;
      .view-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .view-link {
          cursor: pointer;
          color: var(--Primary-700);
          font-weight: 600;
        }
      }
    }
  }

  .search-content {
    padding: 10px;
    border-bottom: 1px solid var(--Gray-200);
  }

  .search-input {
    position: relative;
    color: var(--Gray-500);

    .input-field {
      box-sizing: border-box;
      padding: 10px;
      padding-left: 40px;
      border: none;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .start-icon {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .end-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .preview-button {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }

  .headline {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .error {
    color: var(--Error-600);
  }

  .error-border {
    border: 1px solid var(--Error-300);
  }

  .progress-bar-container {
    display: flex;
    align-items: center;
    width: 100%;
  }
`

// initial data
const initStatusList = [
  { text: 'Active', value: 'active' },
  { text: 'In-Active', value: 'inactive' },
]

function EmployeeDashboard() {
  const { t } = useTranslation()
  const employeeDashboardColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('employ_id'),
      accessor: 'employ_id',
      disableSortBy: false,
    },
    {
      Header: t('first_name'),
      accessor: 'first_name',
      disableSortBy: false,
    },
    {
      Header: t('last_name'),
      accessor: 'last_name',
      disableSortBy: false,
    },
    {
      Header: t('department'),
      accessor: 'department',
      disableSortBy: false,
    },
    {
      Header: t('position'),
      accessor: 'position',
      disableSortBy: false,
    },
    {
      Header: t('status'),
      accessor: 'status',
      disableSortBy: false,
    },
  ]
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // store reducer
  const { employeeDashboardList, total, page, limit, pageCount } = useSelector(selectEmployeeDashboard)
  const { departmentList } = useSelector(selectUser)
  // data
  const [projectDashboards, setProjectDashboards] = useState([])
  // filter
  const [search, setSearch] = useState('')
  const [departmentSelected, setDepartmentSelected] = useState('')
  const [positionSelected, setPositionSelected] = useState('')
  const [statusSelected, setStatusSelected] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  // dropdown
  const [departments, setDepartments] = useState([])
  const [positions, setPositions] = useState([])

  const viewEmployeeDashboard = useCallback(async (id) => {
    navigate(`/dashboard/employee-dashboard/${id}`)
  }, [])
  const fetchData = useCallback((filter) => {
    dispatch(getEmployeeDashboards(filter))
  }, [])

  // useEffect for fetching data
  useEffect(() => {
    dispatch(getEmployeeDashboards())
    dispatch(getUserManagementDepartment())
  }, [dispatch])

  // useEffect for mapping table data
  useEffect(() => {
    const newEmployeeDashboardList = employeeDashboardList.map((v, i) => {
      const color = JSON.parse(v.department?.color)
      return {
        ...v,
        no: i + 1,
        employ_id: (
          <div className="text-bold link" onClick={() => viewEmployeeDashboard(v.id)}>
            {v.employ_id}
          </div>
        ),
        position: v.position?.name ?? '-',
        department:
          v.department && v.department?.name != '' ? (
            <span
              style={{
                color: color.text,
                borderColor: color.border,
                background: color.background,
              }}
              className={`badge`}
            >
              {v.department?.name}
            </span>
          ) : (
            <span>-</span>
          ),
        status: (
          <div className="status-badge">
            <GrStatusGoodSmall className={`status ${v.status == 'inactive' ? 'inactive' : ''}`} />
            {v.status}
          </div>
        ),
      }
    })

    setProjectDashboards(newEmployeeDashboardList)
  }, [employeeDashboardList])

  // useEffect for mapping department option list of dropdown
  useEffect(() => {
    const newDepartments = departmentList.map((v) => ({
      text: v.name,
      value: v.id,
      positions: v.positions,
    }))
    setDepartments(newDepartments)
  }, [departmentList])

  // useEffect for mapping position option list of dropdown
  useEffect(() => {
    if (departmentSelected !== '') {
      const departmentMatch = departments.find((v) => v.value == departmentSelected)
      const newPositions = departmentMatch.positions.map((v) => ({
        text: v.name,
        value: v.id,
      }))
      setPositions(newPositions)
      setPositionSelected('')
    }
  }, [departmentSelected])

  // useEffect for filter by dropdown and date
  useEffect(() => {
    if (departmentSelected !== '' || positionSelected !== '' || statusSelected !== '') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`filters[$or][${i}][$or][0][first_name][$contains]`]: v.name,
        [`filters[$or][${i}][$or][1][last_name][$contains]`]: v.name,
        [`filters[$or][${i}][$or][2][employ_id][$contains]`]: v.name,
      }))
      const filter = {
        page: 1,
        pageSize: limit,
        multipleSearch,
        department: departmentSelected,
        position: positionSelected,
        status: statusSelected,
      }
      fetchData(filter)
    }
  }, [departmentSelected, positionSelected, statusSelected])

  // filter
  const onChangePagination = async (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`filters[$or][${i}][$or][0][first_name][$contains]`]: v.name,
      [`filters[$or][${i}][$or][1][last_name][$contains]`]: v.name,
      [`filters[$or][${i}][$or][2][employ_id][$contains]`]: v.name,
    }))
    const filter = {
      page: value.page,
      pageSize: limit,
      multipleSearch,
      department: departmentSelected,
      position: positionSelected,
      status: statusSelected,
    }
    fetchData(filter)
  }
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`filters[$or][${i}][$or][0][first_name][$contains]`]: v.name,
        [`filters[$or][${i}][$or][1][last_name][$contains]`]: v.name,
        [`filters[$or][${i}][$or][2][employ_id][$contains]`]: v.name,
      }))

      const filter = {
        page: 1,
        pageSize: limit,
        multipleSearch,
        department: departmentSelected,
        position: positionSelected,
        status: statusSelected,
      }
      fetchData(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${i}][$or][0][first_name][$contains]`]: v.name,
      [`filters[$or][${i}][$or][1][last_name][$contains]`]: v.name,
      [`filters[$or][${i}][$or][2][employ_id][$contains]`]: v.name,
    }))

    const filter = {
      page: 1,
      pageSize: limit,
      multipleSearch,
      department: departmentSelected,
      position: positionSelected,
      status: statusSelected,
    }
    fetchData(filter)
    setSearch('')
  }
  const onClearSearch = () => {
    const filter = {
      page: 1,
      pageSize: limit,
      multipleSearch: [],
      department: '',
      position: '',
      status: '',
    }
    fetchData(filter)
    clearData()
  }
  const clearData = () => {
    setSearch('')
    setDepartmentSelected('')
    setPositionSelected('')
    setStatusSelected('')
    setSearchFilters([])
  }

  return (
    <Div>
      <div className="header mb-2-rem">
        <BsGraphUpArrow size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight size={20} className="mr-8-px" />
        <span className="highlight">{t('employee_dashboard')}</span>
      </div>
      <div className="mb-1-rem">
        <div className="content-title">{t('employee_dashboard')}</div>
        <div>{t('keep_track_of_everyone_progress_on_sight')}.</div>
      </div>
      <div className="divider"></div>
      <div className="content-header">
        <div className="search-container mb-8-px">
          <div className="input-header">{t('search')}</div>
          <div className="input-header">{t('departments')}</div>
          <div className="input-header">{t('positions')}</div>
          <div className="input-header">{t('status')}</div>
        </div>
        <div className="search-container">
          <SearchText
            className="mb-1-rem"
            placeholder={t('search')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSubmit={onSearch}
            onClear={onClearSearch}
          />
          <Dropdown
            placeHolder={t('departments')}
            optionList={departments}
            value={departmentSelected}
            onChange={(value) => setDepartmentSelected(value)}
          />
          <Dropdown
            placeHolder={t('positions')}
            optionList={positions}
            value={positionSelected}
            onChange={(value) => setPositionSelected(value)}
          />
          <Dropdown
            placeHolder={t('status')}
            optionList={initStatusList}
            value={statusSelected}
            onChange={(value) => setStatusSelected(value)}
          />
          <ButtonOutline className="d-flex justify-content-center" onClick={onClearSearch}>
            {t('clear')}
          </ButtonOutline>
        </div>
        <div className="badge-search-content">
          {searchFilters.map((v) => (
            <div className="badge" key={v.key}>
              <span className="name">{v.name}</span>{' '}
              <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
            </div>
          ))}
        </div>
      </div>
      <div className="table-wrapper">
        <div className="table-header">
          <div className="group">
            <div className="table-title">{t('team_members')}</div>
            <div className="table-total">
              {total} {t('users')}
            </div>
          </div>
        </div>
        <Table
          onStateChange={onChangePagination}
          columns={employeeDashboardColumn}
          data={projectDashboards}
          page={page}
          pageCount={pageCount}
        />
      </div>
    </Div>
  )
}

export default EmployeeDashboard
