import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'
import Field from '../components/form/Field'
import TextField from '../components/form/TextField'
import Button from '../components/form/button/Button'
// import { validateEmail, validateNumber } from '../utils/validation'
import { loginUser, verifyOtpForLogin, resendOTP } from '../redux/actions/admin'

import logoIcon from '../assets/images/logo-icon.png'
import { VERSION } from '../configs/app'

import { selectMaster } from '../redux/selectors'
import { getCompanyInfoById, getServerTime } from '../redux/actions/master'

import PasswordField from '../components/form/PasswordInput'
import Modal from '../components/common/Modal'
import ButtonOutline from '../components/form/button/ButtonOutline'
import OtpInputComponent from '../components/form/OtpInputComponent'
import DialogSuccess from '../components/dialog/DialogSuccess'
import DialogFail from '../components/dialog/DialogFail'

import { IoMdClose } from 'react-icons/io'
import successIcon from '../assets/images/success-icon.png'
import failIcon from '../assets/images/fail-icon.png'
import otpIcon from '../assets/images/opt-icon.png'

const Div = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;

  .img-container {
    padding-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
    }
  }

  .title {
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    font-style: normal;
    color: var(--Gray-900);
  }

  .desc {
    text-align: center;
    margin-top: 12px;
  }

  .login-form {
    margin-top: 32px;

    .field {
      margin-top: 20px;
    }

    .link-wrapper {
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;

      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      :hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .button-login {
      display: block;
      width: 100%;
      margin-top: 24px;
    }
  }

  .hightlight {
    color: var(--Primary-700);
    font-weight: 800;
  }

  .copyright {
    color: var(--Gray-500);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-top: 180px;
  }

  @media screen and (max-width: 440px) {
    max-width: 300px;
    .title {
      font-size: 20px;
    }

    .desc {
      font-size: 14px;
    }
  }
`
const OTPContainer = styled.div`
  padding: 24px;

  .mr-12-px {
    margin-right: 12px;
  }
  .mb-20-px {
    margin-bottom: 20px;
  }
  .mb-32-px {
    margin-bottom: 32px;
  }

  .heading {
    color: var(--Gray-900);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
  p {
    margin: 0;
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
  }
  .highlight-text {
    text-decoration-line: underline;
    cursor: pointer;
    color: #0086c9;
  }
  .icon {
    cursor: pointer;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }

  .w-48 {
    width: 48%;
  }
`

const Login = () => {
  // external hook
  const { t } = useTranslation()
  const lang = localStorage.getItem('lang')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // initiate data
  const fetchCompanyInfo = useCallback(
    (id) => {
      dispatch(getCompanyInfoById(id))
    },
    [dispatch]
  )
  useEffect(() => {
    fetchCompanyInfo(1)
  }, [])

  // master store
  const { isRedirect, companyInfo } = useSelector(selectMaster)
  // state input about log in
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginResponse, setLoginResponse] = useState()
  const [fullOtp, setFullOtp] = useState([])

  // time counter otp modal
  const initialTimeCounterForResendOtp = 60
  const initialTimeCounterForOtpModal = {
    minutes: 5,
    seconds: 0,
  }
  const [otpModal, setOtpModal] = useState(false)
  const [minutes, setMinutes] = useState(initialTimeCounterForOtpModal.minutes)
  const [seconds, setSeconds] = useState(initialTimeCounterForOtpModal.seconds)

  const openOtpModal = () => {
    setOtpModal(true)
  }
  const closeOtpModal = () => {
    setOtpModal(false)
  }

  useEffect(() => {
    let countdown

    if (otpModal) {
      countdown = setInterval(() => {
        if (minutes === 0 && seconds === 0) {
          setOtpModal(false)
          clearInterval(countdown)
        } else {
          if (seconds === 0) {
            setMinutes(minutes - 1)
            setSeconds(59)
          } else {
            setSeconds(seconds - 1)
          }
        }
      }, 1000)
    } else {
      setTimeout(() => {
        setMinutes(initialTimeCounterForOtpModal.minutes)
        setSeconds(initialTimeCounterForOtpModal.seconds)

        setIsCountingDown(false)
        setTimeCounter(initialTimeCounterForResendOtp)
      }, 1000)
    }

    return () => {
      clearInterval(countdown)
    }
  }, [otpModal, minutes, seconds])

  // time counter for resending otp
  const [timeCounter, setTimeCounter] = useState(initialTimeCounterForResendOtp)
  const [isCountingDown, setIsCountingDown] = useState(false)

  useEffect(() => {
    let timerId
    if (isCountingDown && timeCounter > 0) {
      timerId = setTimeout(() => setTimeCounter((c) => c - 1), 1000)
    } else if (timeCounter <= 0) {
      setIsCountingDown(false)
    }

    return () => clearTimeout(timerId)
  }, [timeCounter, isCountingDown])

  const [targetTime, setTargetTime] = useState(null)
  const [timeDifference, setTimeDifference] = useState('')

  useEffect(() => {
    if (targetTime) {
      const updateTimeDifference = () => {
        dispatch(getServerTime()).then(({ type, data_info }) => {
          // current time server
          const now = type.endsWith('_SUCCESS') ? new Date(data_info) : new Date()
          const target = new Date(targetTime)
          const difference = target - now
          const minutes = Math.floor((difference / 1000 / 60) % 60)
          const seconds = Math.floor((difference / 1000) % 60)
          const formattedMinutes = minutes.toString().padStart(2, '0')
          const formattedSeconds = seconds.toString().padStart(2, '0')
          setTimeDifference(`${formattedMinutes}:${formattedSeconds}`)
        })
      }
      updateTimeDifference()
      const intervalId = setInterval(updateTimeDifference, 1000)
      return () => clearInterval(intervalId)
    }
  }, [targetTime])

  useEffect(() => {
    if (timeDifference === '00:00') {
      setTargetTime(null)
      setFailModal2(false)
    }
  }, [timeDifference])

  // success,fail modal
  const initMessageModal = {
    headline: '',
    message: '',
  }
  const [successModal, setSuccessModal] = useState(false)
  const [failModal, setFailModal] = useState(false)
  const [failModal2, setFailModal2] = useState(false)
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)
  const [failMessageModal, setFailMessageModal] = useState(initMessageModal)
  const [failMessageModal2, setFailMessageModal2] = useState(initMessageModal)

  const confirmSuccess = async () => {
    setSuccessModal(false)

    if (isRedirect) {
      window.location.href = isRedirect
    } else {
      window.location.href = '/'
    }
  }
  const confirmFailModal = async () => {
    setFailModal(false)
  }
  const confirmFailModal2 = async () => {
    setTargetTime(null)
    setFailModal2(false)
  }

  // submit function
  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(loginUser(email, password)).then((res) => {
      const { error, is_otp, is_otp_block, user } = res

      if (!error && !is_otp_block) {
        if (is_otp === true) {
          setLoginResponse(user)
          openOtpModal()
        } else {
          if (isRedirect) {
            window.location.href = isRedirect
          } else {
            window.location.href = '/'
          }
        }
      } else if (error?.response) {
        const { data } = error?.response
        if (data.error.message === 'sending OTP Error') {
          setFailMessageModal({
            headline: 'Incorrect Mobile Number Format',
            message: (
              <p>
                Can not send OTP code
                <br />
                Please contact HR admin to make change mobile no.
              </p>
            ),
          })
        } else if (
          data.error.message === 'Invalid identifier or password' ||
          data.error.message === 'Incorrect username or password'
        ) {
          setFailMessageModal({
            headline: 'Incorrect Email/Password',
            message: '',
          })
        } else {
          setFailMessageModal({
            headline: 'Something went wrong',
            message: 'Please contact admin.',
          })
        }

        setFailModal(true)
      }

      if (is_otp_block) {
        if (user?.otp_unlock) setTargetTime(user?.otp_unlock)

        setFailMessageModal2({
          headline: 'Too many attempts',
          message: '',
        })
        setFailModal2(true)
      }
    })
  }

  // function about otp
  const verifyOtp = () => {
    if (fullOtp.length === 6) {
      const request = {
        user_id: loginResponse.user_id,
        otp_id: loginResponse.token,
        otp_code: fullOtp.join(''),
      }
      dispatch(verifyOtpForLogin(request)).then((res) => {
        const { type, error } = res

        if (type === 'VERIFY_OTP_SUCCESS') {
          closeOtpModal()

          if (isRedirect) {
            window.location.href = isRedirect
          } else {
            window.location.href = '/'
          }
        } else if (error?.response?.data?.error?.details?.otp_unlock) {
          setTargetTime(error?.response?.data?.error?.details?.otp_unlock)
          setFailMessageModal2({
            headline: 'Too many attempts',
            message: '',
          })
          setFailModal2(true)
        } else {
          setFailMessageModal({
            headline: 'Incorrect OTP code',
            message: 'Please try again',
          })
          setFailModal(true)
        }
      })
    }
  }
  const resendOtp = () => {
    const request = {
      user_id: loginResponse.user_id,
    }

    dispatch(resendOTP(request)).then((res) => {
      const { response, type } = res
      if (type === 'RESEND_OTP_SUCCESS') {
        setLoginResponse((prev) => ({
          ...prev,
          token: response.token,
          refno: response.refno,
        }))
      }
    })
    setTimeCounter(initialTimeCounterForResendOtp)
    setIsCountingDown(true)
  }

  // other function
  const gotoForgetPassword = () => {
    navigate('/forget-password')
  }
  const formattedTime = (time) => {
    return `${String(time).padStart(2, '0')}`
  }

  return (
    <Div>
      <form>
        <div className="img-container">
          <img src={logoIcon} alt="loco icon" />
        </div>
        <div className="title">Log in to your account</div>
        <div className="desc">
          Welcome back to{' '}
          <span className="hightlight">
            {lang === 'en' ? companyInfo?.company_name_EN : companyInfo?.company_name_TH}
          </span>{' '}
          ERP System! <br /> Please enter your details.
        </div>

        <div className="login-form">
          <Field className="field" label="Username or Email">
            <TextField
              placeholder="Enter your Username or email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Field>
          <Field className="field" label="Password">
            <PasswordField value={password} onChange={(e) => setPassword(e.target.value)} />
          </Field>

          <div className="link-wrapper" onClick={gotoForgetPassword}>
            Forgot password
          </div>
          <Button
            className="button-login"
            type="submit"
            onClick={handleLogin}
            // disabled={(!validateEmail(email) && !validateNumber(email)) || !password}
            disabled={!email || !password}
          >
            Sign in
          </Button>
        </div>

        <div className="copyright">© 2023 CUBE ERP. All rights reserved.</div>
        <div className="copyright">version {VERSION}</div>
      </form>

      {/* Modal */}
      <Modal open={otpModal} disableBackdropClick>
        <OTPContainer>
          <FlexContainer className="justify-content-end">
            <IoMdClose size={20} className="icon" onClick={closeOtpModal} />
          </FlexContainer>
          <FlexContainer className="justify-content-center">
            <img src={otpIcon} alt="otp icon" />
          </FlexContainer>
          <h2 className="heading">Please check your SMS.</h2>
          <p>
            We've sent a code to <b>{loginResponse?.phone_no}</b>
          </p>
          <p>(Ref: {loginResponse?.refno})</p>
          <p className="mb-20-px">
            Valid for {formattedTime(minutes)}:{formattedTime(seconds)} minutes
          </p>

          <OtpInputComponent setFullOtp={setFullOtp} />

          <p className="mb-32-px">
            Didn’t get a code?{' '}
            {isCountingDown ? (
              <span>You can get code in {timeCounter} seconds</span>
            ) : (
              <span className="highlight-text" onClick={resendOtp}>
                Click to resend.
              </span>
            )}
          </p>

          <FlexContainer className="justify-content-space-between">
            <ButtonOutline className="w-48" onClick={closeOtpModal}>
              Cancel
            </ButtonOutline>
            <Button className="w-48" onClick={verifyOtp}>
              Verify
            </Button>
          </FlexContainer>
        </OTPContainer>
      </Modal>

      {/* Dialog */}
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />
      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={failMessageModal.headline}
        description={failMessageModal.message}
        textYes={t('ok')}
      />
      <DialogFail
        open={Boolean(failModal2)}
        onClose={() => {
          setTargetTime(null)
          setFailModal2(false)
        }}
        onSubmit={confirmFailModal2}
        icon={failIcon}
        title={failMessageModal2.headline}
        description={`Please try again in ${timeDifference} minutes`}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default Login
