import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectDocumentTemplate } from '../../../redux/selectors'

import styled from 'styled-components'
import Button from '../../form/button/Button'
import ButtonOutline from '../../form/button/ButtonOutline'

import iconSuccess from '../../../assets/images/success-icon-2.png'
import memberImg from '../../../assets/images/member-img.png'

import PropTypes from 'prop-types'

const Div = styled.div`
  padding: 2rem;

  width: 795px;

  /* typography */
  .confirm-headline {
    color: var(--Gray-900);
    text-align: center;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
  .confirm-desc {
    color: var(--Gray-600);
    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;

    margin-bottom: 1.5rem;
  }
  .show-all {
    color: var(--Primary-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;

    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  /* shared component */
  .badge-for-confirm-publish {
    border-radius: 1rem;
    border: 1px solid var(--Gray-200);
    background: var(--Gray-50);

    padding: 0.25rem 0.625rem;
    margin-bottom: 0.75rem;

    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
  }

  .badge {
    color: var(--BADGE-12-Font);
    border: 1px solid var(--BADGE-12-Border);
    background: var(--BADGE-12-Bg);
    border-radius: 16px;
    padding: 6px;
  }
  /* layout */
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1.75rem;
  }
  .row-content {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;

    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;

    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--Gray-200);

    .w-50 {
      width: 50%;
    }

    .row-headline {
      font-weight: 600;
    }
  }
  .confirm-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* general */
  .mr-6-px {
    margin-right: 0.375rem;
  }
  .mr-12-px {
    margin-right: 0.75rem;
  }
  .mb-12-px {
    margin-bottom: 0.75rem;
  }
  .mb-32-px {
    margin-bottom: 2rem;
  }
`

function PublishConfirmModal({
  publishTo,
  notificationType,
  specificDepartments,
  specificEmployees,
  closeModal,
  submitConfirm,
  openShowAll,
  // sharedServiceTemplateInfo,
  sharedDocumentForm,
}) {
  const { t } = useTranslation()
  const { documentTemplateInfo } = useSelector(selectDocumentTemplate)
  const publishToText = () => {
    let textPublishTo

    if (publishTo === 1) {
      textPublishTo = 'All Employee'
    } else if (publishTo === 2) {
      textPublishTo = 'Specific Departments'
    } else {
      textPublishTo = `${specificEmployees.length} Selected Employees`
    }

    return textPublishTo
  }
  return (
    <Div>
      <div className="icon-container">
        <img src={iconSuccess} alt="iconSuccess" />
      </div>
      <div className="confirm-headline">{t('confirm_publish_heading')}</div>
      <div className="confirm-desc">{t('confirm_publish_sub_heading')}</div>
      <div className="row-content">
        <div className="row-headline w-50">{t('document_name')}:</div>
        <div>{sharedDocumentForm?.name}</div>
      </div>
      <div className="row-content">
        <div className="row-headline w-50">{t('confirm_publish_sub_heading')}:</div>
        <div>{documentTemplateInfo?.name}</div>
      </div>
      <div className="row-content">
        <div className="row-headline w-50">{t('version')}:</div>
        <div>
          <span className="mr-12-px">{sharedDocumentForm?.version?.toFixed(1)}</span>{' '}
          <span className="badge">{documentTemplateInfo?.doc_lang == 'th' ? t('thai') : t('english')}</span>
        </div>
      </div>
      <div className="row-content">
        <div className="row-headline w-50">{t('type')}:</div>
        <div>{documentTemplateInfo?.type}</div>
      </div>
      <div className="row-content">
        <div className="row-headline w-50">{t('publish_to')}:</div>
        <div>
          <div className="mb-12-px">{publishToText()}</div>
          {publishTo === 2 ? (
            <>
              {specificDepartments.slice(0, 4).map((v, i) => (
                <div className="badge-for-confirm-publish" key={v.text}>
                  {v.text}
                </div>
              ))}
            </>
          ) : publishTo === 3 ? (
            <>
              {specificEmployees.slice(0, 4).map((v) => {
                return (
                  <div className="badge-for-confirm-publish" key={v.id}>
                    {v.photo_url ? (
                      <img className="mr-6-px" src={v.photo_url} alt={`${v.id}-img`} />
                    ) : (
                      <img className="mr-6-px" src={memberImg} alt={`${v.id}-img`} />
                    )}
                    <span>
                      {v.first_name} {v.last_name}
                    </span>
                  </div>
                )
              })}
            </>
          ) : null}

          {(publishTo === 1 || specificEmployees.length > 0 || specificDepartments.length > 0) && (
            <div
              className="show-all"
              onClick={openShowAll}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  openShowAll()
                }
              }}
            >
              {t('show_all')}
            </div>
          )}
        </div>
      </div>
      <div className="row-content mb-32-px">
        <div className="row-headline w-50">{t('notification_type')}:</div>
        <div>
          {notificationType
            .filter((v) => v.isChecked)
            .map((v) => v.text)
            .join(',')}
        </div>
      </div>
      <div className="confirm-button-container">
        <ButtonOutline className="mr-12-px" onClick={closeModal}>
          {t('cancel')}
        </ButtonOutline>
        <Button onClick={submitConfirm}>{t('confirm_and_publish')}</Button>
      </div>
    </Div>
  )
}

PublishConfirmModal.propTypes = {
  publishTo: PropTypes.any,
  notificationType: PropTypes.any,
  specificDepartments: PropTypes.any,
  specificEmployees: PropTypes.any,
  closeModal: PropTypes.any,
  submitConfirm: PropTypes.any,
  openShowAll: PropTypes.any,
  sharedDocumentForm: PropTypes.any,
}
export default PublishConfirmModal
