import React, { useState } from 'react'
import PropTypes from 'prop-types'

import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import styled from 'styled-components'

const Div = styled.div`
  .icon-collapse {
    padding: 0;
  }
`

function CollapseMenu({ list, ...props }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (action) => {
    action()
    setAnchorEl(null)
  }

  return (
    <Div {...props}>
      <IconButton
        className="icon-collapse"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={() => setAnchorEl(null)}>
        {list?.map((v, i) => (
          <MenuItem
            sx={v?.text.toLowerCase() === 'edit' ? { color: 'var(--Table-Action-Edit-Font)' } : {}}
            id={v.id ? v.id : i}
            key={v.text}
            onClick={() => handleClose(v?.action)}
          >
            {v?.text}
          </MenuItem>
        ))}
      </Menu>
    </Div>
  )
}

CollapseMenu.propTypes = {
  list: PropTypes.array,
}

export default CollapseMenu
