import approval from './op-approval'
import extra from './op-extra'
import review from './op-review'
import sp from './op-sp'
import sw from './op-sw'
import task from './op-task'
import chainLink from './op-chain-link'
import document from './op-document'
import taskTemp from './op-task-temp'
import sowTpl from './op-sow-tpl'
import sopTpl from './op-sop-tpl'
import chainLinkTpl from './op-chain-link-tpl'

export default function OPServices() {
  const { token, company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  const commonParams = { headers, company_info, employee_info }

  return {
    extra: () => extra(commonParams),
    sp: () => sp(commonParams),
    sw: () => sw(commonParams),
    task: () => task(commonParams),
    review: () => review(commonParams),
    approval: () => approval(commonParams),
    chainLink: () => chainLink(commonParams),
    document: () => document(commonParams),
    taskTemp: () => taskTemp(commonParams),
    sowTpl: () => sowTpl(commonParams),
    sopTpl: () => sopTpl(commonParams),
    chainLinkTpl: () => chainLinkTpl(commonParams),
  }
}
