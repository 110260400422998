import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import styled from 'styled-components'
import Button from '../../form/button/Button'
import Dropdown from '../../form/Dropdown'
import TextField from '../../form/TextField'
import ButtonOutline from '../../form/button/ButtonOutline'

import { LiaTimesSolid } from 'react-icons/lia'

import { selectEmployeeProfile } from '../../../redux/selectors'
import { editEmployeeProfileInfo } from '../../../redux/actions/employeeProfile'

const Div = styled.div`
  padding: 2rem;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .mr-12-px {
    margin-right: 12px;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }

  .icon {
    cursor: pointer;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
  margin-bottom: 2em;
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-between {
    justify-content: space-between;
  }
  &.align-item-center {
    align-items: center;
  }
`
const HeaderContainer = styled.header`
  .heading-modal {
    color: var(--Gray-900);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
  }
  .sub-heading-modal {
    color: var(--Gray-600);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`
const InputSection = styled.section`
  p {
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  .highlight {
    color: var(--Primary-700);
  }
`
const InputContainer = styled.div`
  &.width-23 {
    width: 23%;
  }
  &.width-32 {
    width: 32%;
  }
  &.width-49 {
    width: 49%;
  }
  &.width-100 {
    width: 100%;
  }
  .width-100 {
    width: 100%;
  }
  .input-header {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0.375rem;
  }
  .error-input {
    border: 1px solid var(--Error-300);
  }
  .error {
    color: var(--Error-500);
  }
`
const FooterFig = styled.div``

const initForm = {
  email: '',
  phone_no: '',
  emergency_contact: '',
  emergency_phone_no: '',
  martial_status: '',
  number_of_children: '',
}

const maritalStatusList = [
  { text: 'Single', value: 'Single' },
  { text: 'Married', value: 'Married' },
  { text: 'Divorced', value: 'Divorced' },
  { text: 'Windowed', value: 'Windowed' },
  { text: 'Separated', value: 'Separated' },
  { text: 'Domestic', value: 'Domestic' },
]
function EditContactFamilyInfo({ closeModal }) {
  const dispatch = useDispatch()
  const { employeeProfileInfo } = useSelector(selectEmployeeProfile)
  // form
  const [form, setForm] = useState(initForm)

  useEffect(() => {
    const { email, phone_no, emergency_contact, emergency_phone_no, martial_status, number_of_children } =
      employeeProfileInfo
    const initForm = {
      email,
      phone_no,
      emergency_contact,
      emergency_phone_no,
      martial_status,
      number_of_children,
    }
    setForm(initForm)
  }, [employeeProfileInfo])

  // onChange
  const onChangeForm = (event) => {
    const { value, name } = event.target
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }
  const onChangeDropdown = (name, value) => {
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const submit = () => {
    const request = {
      data: {
        ...form,
      },
    }

    dispatch(editEmployeeProfileInfo(employeeProfileInfo?.id, request))
    closeModal()
  }

  return (
    <Div>
      <div>
        <FlexContainer className="justify-content-between">
          <HeaderContainer>
            <h2 className="heading-modal">Request Personal Information Edit</h2>
            <p className="sub-heading-modal">
              Please fill in the details you'd like to edit and attach the required evidence for verification.
            </p>
          </HeaderContainer>
          <LiaTimesSolid className="icon" size={20} onClick={closeModal} />
        </FlexContainer>

        <InputSection>
          <h3>Contact Information</h3>
          <Divider />
          <FlexContainer className="justify-content-between mb-24-px">
            <InputContainer className="width-49">
              <div className="input-header">Email</div>
              <TextField
                className="width-100"
                name="email"
                value={form?.email}
                onChange={onChangeForm}
                disabled={true}
              />
            </InputContainer>
            <InputContainer className="width-49">
              <div className="input-header">Phone number</div>
              <TextField className="width-100" name="phone_no" value={form?.phone_no} onChange={onChangeForm} />
            </InputContainer>
          </FlexContainer>
          <FlexContainer className="justify-content-between mb-24-px">
            <InputContainer className="width-49">
              <div className="input-header">Emergency contact</div>
              <TextField
                className="width-100"
                name="emergency_contact"
                value={form?.emergency_contact}
                onChange={onChangeForm}
              />
            </InputContainer>
            <InputContainer className="width-49">
              <div className="input-header">Emergency phone</div>
              <TextField
                className="width-100"
                name="emergency_phone_no"
                value={form?.emergency_phone_no}
                onChange={onChangeForm}
              />
            </InputContainer>
          </FlexContainer>
        </InputSection>

        <InputSection>
          <h3>Family Information</h3>
          <Divider />
          <FlexContainer className="justify-content-between mb-24-px">
            <InputContainer className="width-49">
              <div className="input-header">Marital status</div>
              <Dropdown
                className="width-100"
                optionList={maritalStatusList}
                value={form?.martial_status}
                onChange={(value) => onChangeDropdown('martial_status', value)}
              />
            </InputContainer>
            <InputContainer className="width-49">
              <div className="input-header">Number of children</div>
              <TextField
                className="width-100"
                name="number_of_children"
                value={form?.number_of_children}
                onChange={onChangeForm}
              />
            </InputContainer>
          </FlexContainer>
        </InputSection>
      </div>

      <FooterFig>
        <Divider />
        <FlexContainer className="justify-content-end">
          <ButtonOutline className="mr-12-px" onClick={closeModal}>
            Cancel
          </ButtonOutline>
          <Button onClick={submit}>Save</Button>
        </FlexContainer>
      </FooterFig>
    </Div>
  )
}

export default EditContactFamilyInfo
