import React from 'react'
import { useNavigate } from 'react-router-dom'

import styled from 'styled-components'
import Button from '../components/form/button/Button'
import ButtonOutline from '../components/form/button/ButtonOutline'

const Div = styled.div`
  padding: 96px;

  .mr-12-px {
    margin-right: 0.75rem;
  }

  .highlight-error {
    color: var(--Primary-700);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }
  .error-headline {
    color: var(--Gray-900);
    font-size: 3.75rem;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 1.5rem;
  }
  .error-desc {
    color: var(--Gray-600);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 3rem;
  }
  .button-container {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
  }
`

function PageNotFound() {
  const navigate = useNavigate()

  const gotoHome = () => {
    navigate('/', { replace: true })
  }

  const gotoPrevious = () => {
    navigate(-1, { replace: true })
  }
  return (
    <Div>
      <div className="highlight-error">404 error</div>
      <div className="error-headline">We can't find that page</div>
      <div className="error-desc">Sorry, the page you are looking for doesn't exist or has been moved.</div>
      <div className="button-container">
        <ButtonOutline className="mr-12-px" onClick={gotoPrevious}>
          Go back
        </ButtonOutline>
        <Button onClick={gotoHome}>Home</Button>
      </div>
    </Div>
  )
}

export default PageNotFound
