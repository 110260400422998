import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from '@mui/lab'
import { AccordionDetails, AccordionSummary, Accordion as MuiAccordion, Stack } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LiaUserCheckSolid } from 'react-icons/lia'
import { LuClipboardList, LuFlag } from 'react-icons/lu'
import { MdExpandMore, MdOutlineContentPasteSearch } from 'react-icons/md'
import { PiClockLight } from 'react-icons/pi'

import Card from './cards'
import DueDate from './due-date'

export default function SWTimelinePrview({ position = 'right', items = [], noOppositeContent = false, ...props }) {
  const { t } = useTranslation()

  const timelineContentStyles = {
    p: 0,
    m: '0.5rem 0 0.5rem 1.5rem',
    borderRadius: '0.5rem',
    border: '1px solid var(--Gray-200)',
    transition: 'all 0.3 ease',
    color: 'var(--Gray-700)',

    'p, h3, strong': {
      margin: 0,
    },

    '& h3': {
      color: 'inherit',

      /* Text md/Semibold */
      fontFamily: `'Inter', 'Noto Sans Thai'`,
      fontSize: '14px',
      lineHeight: '20px',

      '&.sw-link': {
        cursor: 'pointer',
        transition: 'color 0.3s ease',

        '&:hover': {
          color: 'var(--Primary-700)',
          textDecoration: 'underline',
        },
      },

      '&.title-section': {
        fontWeight: 500,
        width: '100%',
      },
    },

    '& p': {
      color: 'var(--Gray-600)',

      /* Text xs/Regular */
      fontFamily: `'Inter', 'Noto Sans Thai'`,
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
    },
  }

  let prefixNo = 0 // Prefix number for each SW
  return (
    <Timeline
      position={position}
      sx={{
        [`& .${timelineItemClasses.root}:before`]: noOppositeContent && {
          flex: 0,
          padding: 0,
        },
        alignSelf: 'stretch',
        margin: 0,
        paddingTop: 0,
        paddingBottom: 0,
      }}
      {...props}
    >
      {(items || []).map((item, index) => {
        const { id, sw, reviewers, approver } = item
        const isFirstIndex = index === 0
        const isLastIndex = index === items.length - 1
        prefixNo++

        const {
          id: swId,
          name: swName,
          hr,
          min,
          department: swDepartment,
          positions: swPositions,
          tasks: swTasks,
          due_date: swDueDate,
        } = sw || item || {} // Set default value as an empty object to avoid undefined

        const joinedPositions = swPositions?.map((position) => position.name).join(', ') || 'Non-specific position.'

        return (
          <React.Fragment key={index}>
            <TimelineItem key={swId}>
              <TimelineSeparator key={`separator-${index}`}>
                <TimelineConnector
                  sx={{
                    backgroundColor: 'var(--Gray-200)',
                    visibility: isFirstIndex ? 'hidden' : 'visible',
                  }}
                />

                {/* Icon */}
                <TimelineDot
                  sx={{
                    boxShadow: 'none',
                    padding: '0.75rem',
                    borderRadius: '0.5rem',

                    '&.MuiTimelineDot-filled': {
                      backgroundColor: 'var(--Base-White)',
                      border: '1px solid var(--Gray-200)',
                      color: 'var(--Gray-700)',
                    },
                  }}
                >
                  <LuFlag />
                </TimelineDot>

                <TimelineConnector
                  sx={{
                    backgroundColor: 'var(--Gray-200)',
                    visibility: isLastIndex ? 'hidden' : 'visible',
                  }}
                />
              </TimelineSeparator>

              <TimelineContent
                sx={{
                  ...timelineContentStyles,
                  borderColor: 'var(--Gray-200)',
                  boxShadow: 'none',
                  color: 'var(--Gray-700)',
                  '& p': {
                    ...timelineContentStyles['& p'],
                    color: 'var(--Gray-600)',
                  },
                }}
              >
                <Stack direction="row" justifyContent="space-between" p="1rem 1.5rem">
                  <Stack spacing={2}>
                    <Stack sx={{ gap: '0.25rem' }}>
                      <h3>{prefixNo + '. ' + swName}</h3>

                      <p>{(swDepartment?.name || t('non_specific_position')) + ' | ' + joinedPositions}</p>

                      <Stack direction="row" gap="0.5rem" width="100%">
                        <PiClockLight size={16} />
                        <p>{`${t('duration')}: ${hr || 0} ${t('time_hr')} ${min || 0} ${t('time_min')}`}</p>
                      </Stack>

                      {swDueDate && <DueDate dueDate={swDueDate} rootProps={{ justifyContent: 'flex-start' }} />}
                    </Stack>
                  </Stack>
                </Stack>

                {swTasks && Array.isArray(swTasks) && swTasks.length > 0 && (
                  <Accordion defaultExpanded={false}>
                    <AccordionSummary
                      aria-controls={`content1d-${index}`}
                      id={`header1d-${index}`}
                      expandIcon={<MdExpandMore />}
                    >
                      <LuClipboardList size={20} style={{ marginRight: '0.5rem' }} />
                      <h3 className="title-section">{`${t('task')} (${swTasks.length})`}</h3>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Stack spacing={1}>
                        {swTasks?.map((task) => (
                          <Card key={'task-' + task.id}>
                            <Card.Task data={task} titleOnly />
                          </Card>
                        ))}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                )}
              </TimelineContent>
            </TimelineItem>

            {reviewers &&
              Array.isArray(reviewers) &&
              reviewers.length > 0 &&
              reviewers?.map((reviewer, reviewerIndex) => (
                <TimelineItem key={`review-${reviewer?.id}`}>
                  <TimelineSeparator key={`separator-review-${reviewer?.id}`}>
                    <TimelineConnector sx={{ backgroundColor: 'var(--Gray-200)' }} />

                    <TimelineDot
                      sx={{
                        boxShadow: 'none',
                        padding: '0.75rem',
                        borderRadius: '0.5rem',
                        rotate: '45deg',

                        '&.MuiTimelineDot-filled': {
                          backgroundColor: 'var(--Base-White)',
                          border: '1px solid var(--Gray-200)',
                          color: 'var(--Gray-700)',
                        },

                        '& svg': {
                          rotate: '-45deg',
                        },
                      }}
                    >
                      <MdOutlineContentPasteSearch />
                    </TimelineDot>

                    <TimelineConnector
                      sx={{
                        backgroundColor: 'var(--Gray-200)',
                        visibility: isLastIndex ? 'hidden' : 'visible',
                      }}
                    />
                  </TimelineSeparator>

                  <TimelineContent sx={timelineContentStyles}>
                    <Stack>
                      <Stack spacing={1} sx={{ padding: '1rem 1.5rem' }}>
                        <h3 className="title-section" style={{ fontWeight: 600 }}>
                          {`${++prefixNo}. ${t('review')}: ${swName}`}
                        </h3>

                        <Stack sx={{ gap: '0.25rem' }}>
                          <Stack direction="row" gap="0.5rem" width="100%">
                            <PiClockLight size={16} />
                            <p>{`Duration: ${reviewer?.hr || 0} hr. ${reviewer?.min || 0} min.`}</p>
                          </Stack>
                          {reviewer?.due_date && (
                            <DueDate dueDate={reviewer.due_date} rootProps={{ justifyContent: 'flex-start' }} />
                          )}
                        </Stack>
                      </Stack>
                    </Stack>
                  </TimelineContent>
                </TimelineItem>
              ))}

            {approver && (
              <TimelineItem key={`approver-${swId}`}>
                <TimelineSeparator key={`separator-approver-${index}`}>
                  <TimelineConnector sx={{ backgroundColor: 'var(--Gray-200)' }} />

                  <TimelineDot
                    sx={{
                      boxShadow: 'none',
                      padding: '0.75rem',
                      borderRadius: '0.5rem',
                      rotate: '45deg',

                      '&.MuiTimelineDot-filled': {
                        backgroundColor: 'var(--Base-White)',
                        border: '1px solid var(--Gray-200)',
                        color: 'var(--Gray-700)',
                      },

                      '& svg': {
                        rotate: '-45deg',
                      },
                    }}
                  >
                    <LiaUserCheckSolid />
                  </TimelineDot>

                  <TimelineConnector
                    sx={{
                      backgroundColor: 'var(--Gray-200)',
                      visibility: isLastIndex ? 'hidden' : 'visible',
                    }}
                  />
                </TimelineSeparator>

                <TimelineContent
                  sx={{
                    ...timelineContentStyles,
                    borderColor: 'var(--Gray-200)',
                    boxShadow: 'none',
                    color: 'var(--Gray-700)',
                    '& p': {
                      ...timelineContentStyles['& p'],
                      color: 'var(--Gray-600)',
                    },
                  }}
                >
                  <Stack direction="row">
                    <Stack sx={{ gap: '0.25rem', padding: '1rem 1.5rem' }}>
                      <h3 className="title-section" style={{ fontWeight: 600 }}>
                        {`${++prefixNo}. ${t('approval')}: ${swName}`}
                      </h3>
                      <p>{`${t('duration')}: ${approver?.hr || 0} ${t('time_hr')} ${approver?.min || 0} ${t(
                        'time_min'
                      )}`}</p>

                      {approver?.due_date && (
                        <DueDate dueDate={approver.due_date} rootProps={{ justifyContent: 'flex-start' }} />
                      )}
                    </Stack>
                  </Stack>
                </TimelineContent>
              </TimelineItem>
            )}
          </React.Fragment>
        )
      })}
    </Timeline>
  )
}

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid var(--Gray-200)`,
  borderBottom: 0,
  borderLeft: 0,
  borderRight: 0,
  color: 'inherit',

  '&:last-child': {
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
  },

  '&::before': {
    display: 'none',
  },
}))
