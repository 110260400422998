import styled from 'styled-components'
import { useCallback, useState, useEffect  } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMeeting } from '../../redux/actions/dailysummary'
import { validateEmail } from '../../utils/validation'

import dayjs from 'dayjs'

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'


import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import SearchText from '../../components/form/SearchText'

import { getDepartmentsAndPositionsUseAuthen } from '../../redux/actions/master'

import { selectMaster,selectUser } from '../../redux/selectors'

import { getEmployeeByTaskReturnData,AddUserToTask } from '../../redux/actions/ofm'

import Dropdown from '../../components/form/Dropdown'

import { v4 as uuidv4 } from 'uuid'
/*
import {
    getUserManagementDepartment
  } from '../../redux/actions/userlist'
*/
import { LiaTimesSolid } from 'react-icons/lia'

import TableHeaderCheckBox from '../../components/common/TableHeaderCheckBox'

import Checkbox from '../../components/form/Checkbox'

import TextField from '../../components/form/TextField'

import iconDelete from '../../assets/images/icon-bin.svg'
import { useTranslation } from 'react-i18next'
const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  } 
  
  .content_header {
    padding: 14px 14px;
    
    width: ${(props) => props.width}px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 80px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : ${(props) => props.width}px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }

.wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        border-bottom: 2px solid #EAECF0;
        color: #667085;
        font-size: 14px;
    }

    .menu-active {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid var(--Primary-600) !important;
        color: #6941C6;
        background-color: #F9F5FF;
    }
}
`

const EmployeeDIV = styled.div`
.content-title {
  font-size: 36px;
  font-weight: 600;
  color: var(--Gray-900);
}
.border_header{
  border-bottom: 1px solid rgb(234, 236, 240);
  margin-bottom: 21px;
  margin-top: 21px;
}
.content-fillter-bg{
  min-height: 68px;
  background-color: var(--Gray-50);
  border-radius: 8px;
  margin-bottom: 21px;
  padding: 10px;
}
.content-fillter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  

  .search-wrapper {
    /*margin-right: 12px;*/
  }

  .filter-list {
    flex-basis: 100%;
    margin-top: 24px;
  }

  .group_filter {
    display: flex;
    align-items: center;
    column-gap: 1rem;
  }
}
.button_clear_all{
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #EAECF0;
}
.mr-1-rem {
  margin-right: 1rem;
}
.container {
  position: relative;
  display: inline-block;
}
.badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 10px;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
.table-wrapper {
  margin-top: 24px;
  border: 1px solid #EAECF0;
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  background-color: #FFFFFF;

  .table-header {
    /*
    display: flex;
    justify-content: space-between;
    */
    padding: 16px 14px;
    border-radius: 8px 8px 0 0;
    background: #FFFFFF;

    .group {
      display: flex;
      align-items: center;
    }

    .table-title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }

    .table-total {
      margin-left: 16px;
      font-size: 12px;
      font-weight: 500;
      padding: 2px 8px;
      color: var(--Primary-700);
      background: var(--Primary-50);
      border-radius: 16px;
    }
  }

  .create-bt {
    text-decoration: none;
  }
}
.cursor_pointer {
  cursor: pointer;
}
.text-bold {
  font-weight: bold;
}

.link {
  color: #7f56d9;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.dd_css{
    width: 100%;
}
.badge {
  padding: 2px 8px;
  align-items: center;
  color: var(--Pink-700);
  background: var(--Pink-50);
  border: 1px solid var(--Pink-200);
  border-radius: 16px;
}
.status-badge {
  width: 90px;
  padding: 0.2rem;
  text-align: center;
  border: 1.5px solid var(--Gray-300);
  border-radius: 6px;
  font-weight: 500;

  .status {
    font-size: 0.6rem;
    margin-right: 0.2rem;
    color: #17b26a;

    &.inactive {
      color: red;
    }
  }
}
.css_header_detail {
    font-size: 14px;
    color: #667085;
} 
.button_add_more{
    padding: 16px 14px;
  }
`

const DivTableGuest = styled.div`
  width: 100%;
  overflow-x: auto;
  .text_css{
    width: 100%;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: var(--Shadow-xs);

    tr {
      border: 1px solid var(--Gray-200);
    }

    th,
    td {
      padding: 16px 14px;
      text-align: left;
      font-size: 14px;
      color: var(--Gray-600);
      white-space: nowrap;
      background: var(--Base-White);
    }

    th {
      padding: 12x 14px;
      font-weight: 500;
      background: var(--Gray-50);
    }

    tr:hover {
      td {
        background: var(--Primary-50);
        color: var(--Primary-700);
      }
    }
  }

  .content-wrapper {
    text-align: center;
  }

  .pagination {
    margin: 30px 0;
  }

`

const ModalEmployee = ({ onClose, onSubmit,TaskId,TaskName,...props }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
  
  //--- ST TabMenu
  const [tabactive, setTabactive] = useState('Employee')
  const [menuTabList, setMenuTabList] = useState([
    {
      name: 'Employee',
      value: 'Employee',
      active_status: true,
    },
    {
        name: 'Non-Employee',
        value: 'Non-Employee',
        active_status: false,
    },
  ])
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }
  //--- ED TabMenu
  //--- ST Employee
  const [departmentSelected, setDepartmentSelected] = useState('')
  const [positionSelected, setPositionSelected] = useState('')
  const [departments, setDepartments] = useState([])
  const [positions, setPositions] = useState([])

  const { departments:departmentList } = useSelector(selectMaster)

  useEffect(() => {
    const newDepartmentList = departmentList?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setDepartments(newDepartmentList)
  }, [departmentList])

  useEffect(() => {
    const department = departmentList?.find((v) => v.id == departmentSelected)
    const newPositions = department?.positions?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setPositions(newPositions)
  }, [departmentSelected, departmentList])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [employeeData, setEmployeeData] = useState([])
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [Attendance, setAttendance] = useState([])

  const onChangeSelectAllCheckBox = (e) => {
    if(isSelectAll){
        setIsSelectAll(false)
        setAttendance([])
        const newState = newsetUpData.map((obj, index) => {
            return { ...obj, isChecked: false }
        })
      
        setnewsetUpData(newState)
    }else{
        setIsSelectAll(true)

        const data_check_box = newsetUpData.map((obj, index) => {
            return { 
              full_name: obj.full_name,
              email: obj.email_address,
              phone_no: obj.phone_no,
              employee_id: obj.id,
              employee: obj.id,
              meeting_id : TaskId,
              company: null,
              position: null,
              note: null,
              response: null,
              actual_attend: null 
          }  
        })
        /*
        const data_check_box = newsetUpData.map((v, index) => (
          v.id
        ))
        */
        setAttendance(data_check_box)

        const newState = newsetUpData.map((obj, index) => {
            return { ...obj, isChecked: true }
        })
      
        setnewsetUpData(newState)
    }
  }

  const handleChangeCheckBox = (e,v_inp,index_item) => {
    //alert(e);
    if(!e){
      //newsetUpData[index].isChecked = false;

      const newState = newsetUpData.map((obj, index) => {
        // 👇️ if id equals 2, update country property
        if (index === index_item) {
          return { ...obj, isChecked: false }  
        }
        return obj
      })
      setnewsetUpData(newState)
      setAttendance(Attendance.filter((v) => v.employee_id !== v_inp.id));
    }else{
      //newsetUpData[index_item].isChecked = true;
      const newState = newsetUpData.map((obj, index) => {
        // 👇️ if id equals 2, update country property
        if (index === index_item) {
          return { ...obj, isChecked: true }  
        }
        return obj
      })
      setnewsetUpData(newState)
      //alert(JSON.stringify(v_inp))
      
      let newDataElement = {
          full_name: v_inp.full_name,
          email: v_inp.email_address,
          phone_no: v_inp.phone_no,
          employee_id: v_inp.id,
          employee: v_inp.id,
          meeting_id : TaskId,
          company: null,
          position: null,
          note: null,
          response: null,
          actual_attend: null
      }
      setAttendance(current => [...current, newDataElement]);
    }
  }

  const EmployeeColumn = [
    {
        Header: <Checkbox checked={isSelectAll} onChange={onChangeSelectAllCheckBox} />,
        accessor: 'checkbox',
        disableSortBy: false,
      },
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('employee_id'),
      accessor: 'employ_id',
      disableSortBy: false,
    },
    {
      Header: t('full_name'),
      accessor: 'full_name',
      disableSortBy: false,
    },
    {
      Header: t('mobile'),
      accessor: 'phone_no',
      disableSortBy: false,
    },
    {
      Header: t('email_address'),
      accessor: 'email_address',
      disableSortBy: false,
    },
    {
      Header: t('department'),
      accessor: 'department',
      disableSortBy: false,
    },
    {
      Header: t('job_position'),
      accessor: 'job_position',
      disableSortBy: false,
    },
  ]

  const headerGroupGuest = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
        Header: t('full_name'),
        accessor: 'Full_Name',
        disableSortBy: false,
    },
    {
        Header: t('company'),
        accessor: 'Company',
        disableSortBy: false,
    },
    {
        Header: t('position'),
        accessor: 'Position',
        disableSortBy: false,
    },
    {
        Header: t('email'),
        accessor: 'Email',
        disableSortBy: false,
    },
    {
        Header: t('tel'),
        accessor: 'Tel',
        disableSortBy: false,
    },
    {
        accessor: t('del'),
        disableSortBy: false,
    },
  ]
  const [AttendanceGuest, setAttendanceGuest] = useState([])
  const onChangeTextGuest = (index_item, e,type_inp) => {
    const newState = AttendanceGuest.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        if(type_inp == 'full_name'){
            return { ...obj, full_name: e.target.value }
        }else if(type_inp == 'company'){
            return { ...obj, company: e.target.value }
        }else if(type_inp == 'position'){
            return { ...obj, position: e.target.value }
        }else if(type_inp == 'email'){
            return { ...obj, email: e.target.value }
        }else if(type_inp == 'phone_no'){
            return { ...obj, phone_no: e.target.value }
        }
        
      }
      return obj
    })

    setAttendanceGuest(newState)
  }
  const RemoveAttendanceGuest = (index_item) => {
    const currentListCopy = [...AttendanceGuest]
    currentListCopy.splice(index_item, 1)
    setAttendanceGuest(currentListCopy)
  }
  function handleAttendanceGuest(currentData) {
    // build a new element with a new ID based off the last element in the array
    let newDataElement = {
        full_name: null,
        email: null,
        phone_no: null,
        employee_id: null,
        employee: null,
        meeting_id : TaskId,
        company: null,
        position: null,
        note: null,
        response: null,
        actual_attend: null
    }

    setAttendanceGuest(current => [...current, newDataElement]);
  }
  const [search, setSearch] = useState('')
  
  const [totalEmployee, settotalEmployee] = useState(0)
  const [limitEmployee, setlimitEmployee] = useState(10)

  const { employeeList, limit, total, skip, isLoading } = useSelector(selectMaster)
  const [newsetUpData ,setnewsetUpData] = useState([])
  /*
  useEffect(() => {
    const newsetUpData = employeeList.map((v, index) => {
      const check_in_check_box = Attendance.filter((v_att) => v_att.employee_id === v.id);
      const is_isChecked = check_in_check_box.length > 0 ? true : false;
      return {
        isChecked : is_isChecked,
        id : v.id,
        employ_id : v.employ_id,
        full_name : v.first_name + ' ' + v.last_name,
        phone_no : v.phone_no,
        email_address : v.email,
        department : v.department,
        job_position : v.position,
        dept_color : v.dept_color,
      }
    })

    setnewsetUpData(newsetUpData)
  }, [employeeList])
  */

  useEffect(() => {
    const newEmployee = newsetUpData.map((v, index) => {
      const deptColor = v.dept_color ? JSON.parse(v.dept_color) : null;
      const check_in_check_box = Attendance.filter((v_att) => v_att.employee_id === v.id);
      const is_isChecked = check_in_check_box.length > 0 ? true : false;
      return {
        checkbox: <Checkbox key={v.id} checked={is_isChecked} label={''} onChange={(e) => handleChangeCheckBox(e,v,index)} />,
        no: limitEmployee * page - (9 - index),
        employ_id : <div className="text-bold">{v.employ_id}</div>,
        full_name: (
          <div className="text-bold">
            {v.full_name}
          </div>
        ),
        phone_no : <div>{v.phone_no ? v.phone_no : '-'}</div>,
        email_address : <div>{v.email_address ? v.email_address : '-'}</div>,
        department:
        v.department != '' ? (
          <span
            className="badge"
            style={{
              color: deptColor?.text,
              borderColor: deptColor?.border,
              backgroundColor: deptColor?.background,
            }}
          >
            {v.department}
          </span>
        ) : (
          <span>-</span>
        ),
        job_position : <div>{v.job_position ? v.job_position : '-'}</div>
      }
    })
    setEmployeeData(newEmployee)
  }, [newsetUpData])

  

  /*
  useEffect(() => {
    const totalPage = Math.ceil(total / limit)
    setTotalPages(totalPage)
  }, [total, skip, limit])
  */

  const fetchData = useCallback(
    async (filter) => {
      //dispatch(getEmployee(filter))
      if(TaskId){
        dispatch(getEmployeeByTaskReturnData(TaskId,filter)).then(({ type,data_info,data_meta }) => {
            if(type.endsWith('_SUCCESS')){
                if(data_info){
                    
                    const totalPage = Math.ceil(data_meta.pagination.total / limitEmployee)
                    settotalEmployee(data_meta.pagination.total)
                    setTotalPages(totalPage)
                    

                    const newsetUpData = data_info.map((v, index) => {
                      return {
                        isChecked : false,
                        id : v.id,
                        employ_id : v.employ_id,
                        full_name : v.first_name + ' ' + v.last_name,
                        phone_no : v.phone_no,
                        email_address : v.email,
                        department : v.department ? v.department.name : '',
                        job_position : v.position ? v.position.name : '',
                        dept_color : null,
                      }
                    })
                
                    setnewsetUpData(newsetUpData)

                    /*
                    const newBasesCellData = data_info.jobs?.map((v, index) => ({
                        job_id : v.id,
                        display_name : v.name,
                    }))
                    setBasesCellData(newBasesCellData)
                    */
                    
                }
            }
        })
    }
    },
    [dispatch]
  )

  useEffect(() => {
    const filter = {
      skip: 1,
      limit: limitEmployee,
      /*meeting_id : MeetingID*/
    }
    
    fetchData(filter)
  }, [fetchData])

  const [searchFilters, setSearchFilters] = useState([])
  const onSearch = () => {
    const newFilter = {
      key: uuidv4(),
      name: search,
    }
    const filter = {
      skip: 1,
      limit: limitEmployee,
      /*meeting_id : MeetingID,*/
      other_search :[...searchFilters, newFilter].map((v) => v.name).join(','),
      department : departmentSelected,
      position : positionSelected
    }
    //alert(JSON.stringify(filter));
    fetchData(filter)
    setPage(1)
    setSearchFilters((prev) => [...prev, newFilter])
  }

  
  const onClearSearch = () => {
    const filter = {
      skip: 1,
      limit: limitEmployee,
      /*meeting_id : MeetingID*/
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
  }

  useEffect(() => {
    dispatch(getDepartmentsAndPositionsUseAuthen())
  }, [dispatch])

  const onSearchdepartmentSelected = (value) => {
    setDepartmentSelected(value)
    const filter = {
      skip: 1,
      limit: limitEmployee,
      /*meeting_id : MeetingID,*/
      other_search:searchFilters.map((v) => v.name).join(','),
      department : value,
      position : positionSelected
    }
    
    fetchData(filter)
    setPage(1)
  }

  const onSearchpositionSelected = (value) => {
    setPositionSelected(value)
    const filter = {
      skip: 1,
      limit: limitEmployee,
      /*meeting_id : MeetingID,*/
      other_search:searchFilters.map((v) => v.name).join(','),
      department : departmentSelected,
      position : value
    }
    
    fetchData(filter)
    setPage(1)
  }

  const onClearAllSearch = () => {
    setSearchFilters([]);
    setDepartmentSelected('');
    setPositionSelected('');

    const filter = {
      skip: 1,
      limit: limitEmployee,
      /*meeting_id : MeetingID*/
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
  }

  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    
    const filter = {
      skip: 1,
      limit: limitEmployee,
      /*meeting_id : MeetingID,*/
      other_search: newSearchFilters.map((v) => v.name).join(','),
      department : departmentSelected,
      position : positionSelected
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
    
  }

  const onChangePagination = async (value) => {
    const filter = {
      //skip: value.page * limit - limit,
      skip: value.page,
      limit: limitEmployee,
      /*meeting_id : MeetingID,*/
      other_search:searchFilters.map((v) => v.name).join(','),
      department : departmentSelected,
      position : positionSelected
    }
    fetchData(filter)
    setPage(value.page)
  }


  //--- ED Employee

    //-- ST Add Data
    const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
    const ConfirmDialog = () => {
        setModalConfirmDialog(true)
    }
    const [Titlesuccess, setTitlesuccess] = useState('')
    const [SubTitlesuccess, setSubTitlesuccess] = useState('')
    const [Descriptionsuccess, setDescriptionsuccess] = useState('')
    const [successModal, setsuccessModal] = useState(false)
    const [TitleFail, setTitleFail] = useState('')
    const [nameItemFail, setnameItemFail] = useState('')
    const [DescriptionFail, setDescriptionFail] = useState('')
    const [failModal, setFailModal] = useState(false)
    const CreateNewDailySummary = () => {
      //alert('Confirm Invice Employee');
      //alert(JSON.stringify(Attendance));
      const data_array_api = Attendance.map((v, index) => {
        return {
          id : v.employee_id
        }
      });
      //alert(JSON.stringify(data_array_api));
      
      setModalConfirmDialog(false);
      
      
      
      const sent_data_api = data_array_api;
      dispatch(AddUserToTask(TaskId, sent_data_api)).then(({ type }) => {
        if(type.endsWith('_SUCCESS')){
          setTitlesuccess('Add User Success');
          setSubTitlesuccess('');
          setDescriptionsuccess(''); 
          setsuccessModal(true)
        }else{
          setTitleFail('Error Add User');
          setnameItemFail('');
          setDescriptionFail(''); 
          setFailModal(true)
        }    
      })
    }
    //-- ED Add Data
  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">Add New Employee - Task : {TaskName}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <hr />
      </div>
      
      <div className="dialog-content">
        
        {tabactive == 'Employee' && (
            <EmployeeDIV>
                <div className="content-fillter-bg">
                    <div className="content-fillter">
                        <div className="group_filter">
                        <div>
                            <SearchText
                            className="search-wrapper"
                            placeholder={t('search')}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onSubmit={onSearch}
                            onClear={onClearSearch}
                            />
                        </div>
                        {
                          /*
                          <div>
                              <Dropdown
                              placeHolder="Department"
                              className="dd_css"
                              optionList={departments}
                              value={departmentSelected}
                              onChange={(value) => onSearchdepartmentSelected(value)}
                              />
                          </div>
                          <div>
                              <Dropdown
                              placeHolder="Position"
                              className="dd_css"
                              optionList={positions}
                              value={positionSelected}
                              onChange={(value) => onSearchpositionSelected(value)}
                              />
                          </div>
                          */
                        }


                        <div>
                            <Button onClick={onClearAllSearch} className="button_clear_all mr-1-rem">
                            {t('clear_all')}
                            </Button>
                        </div>
                        
                        </div>

                        <div className="container">
                        </div>

                    </div>
                    <div className="badge-search-content">
                        {searchFilters.map((v) => (
                        <div className="badge" key={v.key}>
                            <span className="name">{v.name}</span>{' '}
                            <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
                        </div>
                        ))}
                    </div>
                </div>
                <div className="table-wrapper">
                    <div className="table-header">
                        <div className="group">
                            <div className="table-title">{t('total_employee')}</div>
                            <div className="table-total">{totalEmployee} {t('items')}</div>
                        </div>
                    </div>
                    <TableHeaderCheckBox
                    columns={EmployeeColumn}
                    data={employeeData}
                    onStateChange={onChangePagination}
                    pageCount={totalPages}
                    page={page}
                    loading={false}
                    />
                </div>
            </EmployeeDIV>
        )}    
      </div>
      
      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={onClose}>
          {t('cancel')}
        </ButtonOutline>
        <Button className="button_margin" onClick={ConfirmDialog}>
          {t('confirm')}
        </Button>
      </div>

        <DialogConfirm
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onSubmit={CreateNewDailySummary}
          icon={question}
          title={'Confirm Add User?'}
          description=""
          textYes={t('confirm')}
          textNo={t('back')}
        />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />
    </Div>
  )
}

export default ModalEmployee
