import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { DateTimeHHmmDisplay } from '../../utils/common'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import SearchText from '../../components/form/SearchText'
import TextField from '../../components/form/TextField'
import Table from '../../components/common/Table'
import SidePanel from '../../components/common/SidePanel'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import Dropdown from '../../components/form/Dropdown'
import CollapseMenu from '../../components/common/CollapseMenu'
import DialogConfirm from '../../components/dialog/DialogConfirm'
import DialogSuccess from '../../components/dialog/DialogSuccess'

import { LiaTimesSolid } from 'react-icons/lia'
import { GiCancel } from 'react-icons/gi'
import successIcon from '../../assets/images/success-icon.png'

import { getScopeOfWorks, createSow, updateSow, getScopeOfWorkById, deleteSow } from '../../redux/actions/scopeOfWork'
import { getTaskTypes, getTaskTypeById, createTaskType, updateTaskType } from '../../redux/actions/taskType'
import { selectScopeOfWork, selectTaskType } from '../../redux/selectors'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  .mr-1-rem {
    margin-right: 1rem;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .justify-content-center {
    justify-content: center;
  }

  .align-items-center {
    align-items: center;
  }

  .icon {
    cursor: pointer;
  }

  .side-panel-container {
    padding: 2rem;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .header {
      font-size: 20px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }

  .input-header {
    color: var(--gray-700);
    font-size: 14px;
    font-weight: 500;
  }

  .input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .error-input {
    border: 1px solid red;
  }

  .error {
    color: red;
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header-2 {
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .input-header-2 {
    display: flex;
    justify-content: space-between;

    .group {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      align-content: center;
      column-gap: 1rem;
    }

    .input-width {
      width: 600px;
    }
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--Gray-200, #eaecf0);
  }
`
const scopeOfWorkColumn = [
  {
    Header: 'SOW Id',
    accessor: 'task_scope_id',
    disableSortBy: false,
  },
  {
    Header: 'Scope of work',
    accessor: 'scope_work_name',
    disableSortBy: false,
  },
  {
    Header: 'Created Date',
    accessor: 'created_date',
    disableSortBy: false,
  },
  {
    Header: 'Created By',
    accessor: 'created_by',
    disableSortBy: false,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: false,
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
]
const taskColumn = [
  {
    Header: 'No',
    accessor: 'no',
    disableSortBy: false,
  },
  {
    Header: 'Task',
    accessor: 'task_type_name',
    disableSortBy: false,
  },
  {
    Header: 'Created By',
    accessor: 'created_by',
    disableSortBy: false,
  },
  {
    Header: 'Created Date',
    accessor: 'created_date',
    disableSortBy: false,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: false,
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
]

const initStatusDropdown = [
  {
    value: 'active',
    text: 'Active',
  },
  {
    value: 'inactive',
    text: 'Inactive',
  },
]

const initValidateForm = {
  name: null,
}

const initValidateEditForm = {
  name: null,
  status: null,
}

const initMessageModal = {
  headline: '',
  message: '',
}

export default function ScopeOfWork({ id }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { sows, sowInfo, page, total, limit, pageCount, isLoading, state } = useSelector(selectScopeOfWork)
  const taskTypeStore = useSelector(selectTaskType)
  const [sowName, setSowName] = useState('')
  const [taskName, setTaskName] = useState('')
  const [sowForm, setSowForm] = useState()
  const [taskForm, setTaskForm] = useState()
  const [sowId, setSowId] = useState('')
  const [taskId, setTaskId] = useState('')
  const [scopeOfWorkList, setScopeOfWorkList] = useState([])
  const [taskList, setTaskList] = useState([])
  const [statusSelected, setStatusSelected] = useState('')
  const [statusSelectedTask, setStatusSelectedTask] = useState('')
  const [sowNameDelete, setSowNameDelete] = useState('')
  const [taskNameDelete, setTaskNameDelete] = useState('')
  const [searchSow, setSearchSow] = useState('')
  const [searchSowFilters, setSearchSowFilters] = useState([])
  const [searchTask, setSearchTask] = useState('')
  const [searchTaskFilters, setSearchTaskFilters] = useState([])
  // panel
  const [isOpenSidePanelView, setIsOpenSidePanelView] = useState(false)
  const [isOpenSidePanelCreate, setIsOpenSidePanelCreate] = useState(false)
  const [isOpenSidePanelEdit, setIsOpenSidePanelEdit] = useState(false)
  const [isOpenSidePanelCreateTask, setIsOpenSidePanelCreateTask] = useState(false)
  const [isOpenSidePanelEditTask, setIsOpenSidePanelEditTask] = useState(false)
  // modal
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [confirmDeleteTaskModal, setConfirmDeleteTaskModal] = useState(false)
  const [confirmCreateSowModal, setConfirmCreateSowModal] = useState(false)
  const [confirmUpdateSowModal, setConfirmUpdateSowModal] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  // modal message
  const [successMessageModal, setSuccessMessageModal] = useState(initMessageModal)

  // validate
  const [errors, setErrors] = useState(initValidateForm)
  const [errorsTask, setErrorsTask] = useState(initValidateForm)

  const fetchSowData = useCallback(
    async (filter) => {
      dispatch(getScopeOfWorks(filter))
    },
    [dispatch]
  )

  const fetchTaskData = useCallback(
    async (filter) => {
      dispatch(getTaskTypes(filter))
    },
    [dispatch]
  )

  const viewScopeOfWork = useCallback((id, name) => {
    setSowId(id)
    const filter = {
      skip: 1,
      limit: limit,
      taskScopeId: id,
      filters: searchTaskFilters.map((v) => v.name),
      status: statusSelectedTask,
    }
    setSowNameDelete(name)
    fetchTaskData(filter)
    setIsOpenSidePanelView(true)
  }, [])

  const editScopeOfWork = useCallback(
    (id) => {
      setSowId(id)
      dispatch(getScopeOfWorkById(id))
      setIsOpenSidePanelEdit(true)
    },
    [dispatch]
  )

  const deleteScopeOfWork = useCallback((id, name) => {
    setSowId(id)
    setSowNameDelete(name)
    setConfirmDeleteModal(true)
  }, [])

  const editTaskAction = useCallback((id) => {
    setTaskId(id)
    dispatch(getTaskTypeById(id))
    setIsOpenSidePanelEditTask(true)
  }, [])

  const deleteTaskAction = useCallback((id, name) => {
    setTaskId(id)
    setTaskNameDelete(name)
    setConfirmDeleteTaskModal(true)
  }, [])

  useEffect(() => {
    if (state == 'CREATE_SOW.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: 'Create scope of work succeed.',
        message: '',
      })
      setSuccessModal(true)
    }
    if (state == 'UPDATE_STATUS_SOW.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: 'Update scope of work succeed.',
        message: '',
      })
      setSuccessModal(true)
    }
    if (state == 'DELETE_SOW.SUCCESS' && isLoading == false) {
      setSuccessMessageModal({
        headline: 'Delete scope of work succeed.',
        message: '',
      })
      setSuccessModal(true)
    }
  }, [state, isLoading])

  useEffect(() => {
    if (taskTypeStore?.taskInfo?.id) {
      const { name, status } = taskTypeStore?.taskInfo
      const newTaskForm = {
        name,
        status,
      }
      setTaskForm(newTaskForm)
    }
  }, [taskTypeStore?.taskInfo])

  useEffect(() => {
    const filter = {
      skip: 1,
      limit: limit,
      filters: searchSowFilters.map((v) => v.name),
      status: statusSelected,
      departmentId: id,
    }
    fetchSowData(filter)
  }, [fetchSowData, limit, id, statusSelected])

  useEffect(() => {
    const filter = {
      skip: 1,
      limit: limit,
      taskScopeId: sowId,
      filters: searchTaskFilters.map((v) => v.name),
      status: statusSelectedTask,
    }
    fetchTaskData(filter)
  }, [fetchTaskData, sowId, statusSelectedTask])

  useEffect(() => {
    if (sowInfo) {
      const { id, name, status } = sowInfo
      const newSowForm = {
        id: id,
        name: name,
        status: status,
      }
      setSowForm(newSowForm)
    }
  }, [sowInfo])

  useEffect(() => {
    const newScopeOfWorkList = sows.map((v) => {
      const list = [
        {
          text: 'Edit',
          action: () => editScopeOfWork(v.id),
        },
        {
          text: 'Delete',
          action: () => deleteScopeOfWork(v.id, v.scope_work_name),
        },
      ]
      return {
        ...v,
        scope_work_name: (
          <div className="text-bold link" onClick={() => viewScopeOfWork(v.id, v.scope_work_name)}>
            {v.scope_work_name}
          </div>
        ),
        created_date: DateTimeHHmmDisplay(v.created_date),
        action: <CollapseMenu list={list} />,
      }
    })
    setScopeOfWorkList(newScopeOfWorkList)
  }, [sows, viewScopeOfWork, editScopeOfWork, deleteScopeOfWork])

  useEffect(() => {
    const newTaskList = taskTypeStore?.taskTypes?.map((v, i) => {
      const list = [
        {
          id: `task-edit-${v.id}`,
          text: 'Edit',
          action: () => editTaskAction(v.id),
        },
        {
          id: `task-delete-${v.id}`,
          text: 'Delete',
          action: () => deleteTaskAction(v.id, v.task_type_name),
        },
      ]
      return {
        ...v,
        no: i + 1,
        created_date: DateTimeHHmmDisplay(v.created_date),
        action: <CollapseMenu list={list} />,
      }
    })
    setTaskList(newTaskList)
  }, [taskTypeStore?.taskTypes, editTaskAction, deleteTaskAction])

  // onChange
  const onChangeEditTaskForm = (event) => {
    const { value, name } = event.target
    setTaskForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeDropdownEditTaskForm = (value) => {
    setTaskForm((prev) => ({ ...prev, status: value }))
  }

  const onChangeEditSowForm = (event) => {
    const { value, name } = event.target
    setSowForm((prev) => ({ ...prev, [name]: value }))
  }

  const onChangeDropdownEditSowForm = (value) => {
    setSowForm((prev) => ({ ...prev, status: value }))
  }

  const onChangePaginationForSow = async (value) => {
    const filter = {
      skip: value.page,
      limit: limit,
      filters: searchSowFilters.map((v) => v.name),
      status: statusSelected,
      departmentId: id,
    }
    fetchSowData(filter)
  }

  const onChangePaginationForTask = async (value) => {
    const filter = {
      skip: value.page,
      limit: limit,
      taskScopeId: sowId,
      filters: searchTaskFilters.map((v) => v.name),
      status: statusSelectedTask,
    }
    fetchTaskData(filter)
  }

  // modal
  const openCreateScopeOfWorkPanel = () => {
    setIsOpenSidePanelCreate(true)
  }

  const closeViewScopeOfWorkPanel = () => {
    setIsOpenSidePanelView(false)
    setSowName('')
    setSearchTask('')
    setStatusSelectedTask('')
  }

  const closeCreateScopeOfWorkPanel = () => {
    setIsOpenSidePanelCreate(false)
    setSowName('')
    setErrors(initValidateForm)
  }

  const closeEditScopeOfWorkPanel = () => {
    setIsOpenSidePanelEdit(false)
    setSowName('')
    setStatusSelected('')
    setErrors(initValidateEditForm)
  }

  const closeCreateTaskPanel = () => {
    setIsOpenSidePanelCreateTask(false)
    setTaskName('')
    setErrorsTask(initValidateForm)
  }

  const closeEditTaskPanel = () => {
    setIsOpenSidePanelEditTask(false)
    setTaskName('')
    setStatusSelected('')
    setErrorsTask(initValidateEditForm)
  }

  const openConfirmCreateSow = () => {
    const isValid = validateForm()
    if (isValid) setConfirmCreateSowModal(true)
  }

  const openConfirmUpdateSow = () => {
    const isValid = validateEditForm()
    if (isValid) setConfirmUpdateSowModal(true)
  }

  const confirmSuccess = () => {
    setSuccessModal(false)
  }

  // submit
  const onSubmitcreateSow = () => {
    const { user } = JSON.parse(localStorage.getItem('admin'))
    const request = {
      data: {
        name: sowName,
        department: id,
        owner: user.id
      },
    }
    dispatch(createSow(request, id))
    setIsOpenSidePanelCreate(false)
    setConfirmCreateSowModal(false)
    setSowName('')
  }

  const editSow = () => {
    const request = {
      data: {
        name: sowForm?.name,
        status: sowForm.status,
      },
    }
    dispatch(updateSow(sowForm.id, id, request))
    setIsOpenSidePanelEdit(false)
    setConfirmUpdateSowModal(false)
  }

  const confirmDelete = () => {
    const request = {
      data: {
        status: 'delete',
      },
    }
    dispatch(deleteSow(sowId, id, request))
    setConfirmDeleteModal(false)
  }

  const confirmDeleteTask = () => {
    const request = {
      data: {
        status: 'delete',
      },
    }
    dispatch(updateTaskType(taskId, sowId, request))
    setConfirmDeleteTaskModal(false)
  }

  const createTask = () => {
    const isValid = validateTaskForm()
    if (isValid) {
      const { user } = JSON.parse(localStorage.getItem('admin'))
      const request = {
        data: {
          name: taskName,
          task_scope: sowId,
          owner: user.id,
        },
      }

      dispatch(createTaskType(request, sowId))
      setIsOpenSidePanelCreateTask(false)
      setTaskName('')
    }
  }

  const editTask = () => {
    const isValid = validateEditTaskForm()
    if (isValid) {
      const request = {
        data: {
          name: taskForm?.name,
          status: taskForm?.status,
        },
      }
      dispatch(updateTaskType(taskId, sowId, request))
      setIsOpenSidePanelEditTask(false)
    }
  }

  // request
  const onSearchSow = () => {
    const newFilter = {
      key: uuidv4(),
      name: searchSow,
    }
    const filter = {
      skip: 1,
      limit: limit,
      filters: [...searchSowFilters, newFilter].map((v) => v.name),
      status: statusSelected,
      departmentId: id,
    }
    fetchSowData(filter)
    setSearchSow('')
    setSearchSowFilters((prev) => [...prev, newFilter])
  }

  const onClearSearchSow = () => {
    const filter = {
      skip: 1,
      limit: limit,
      filters: [],
      status: '',
      departmentId: id,
    }
    fetchSowData(filter)
    setSearchSow('')
    setStatusSelected('')
    setSearchSowFilters([])
  }

  const deleteSowSearchBadge = (key) => {
    const newSearchFilters = searchSowFilters.filter((v) => v.key != key)
    setSearchSowFilters(newSearchFilters)

    const filter = {
      skip: 1,
      limit: limit,
      name: '',
      filters: newSearchFilters.map((v) => v.name),
      status: statusSelected,
      departmentId: id,
    }
    fetchSowData(filter)
    setSearchSow('')
  }

  const onSearchTask = () => {
    const newFilter = {
      key: uuidv4(),
      name: searchTask,
    }
    const filter = {
      skip: 1,
      limit: limit,
      taskScopeId: sowId,
      name: searchTask,
      filters: [...searchTaskFilters, newFilter].map((v) => v.name),
      status: statusSelectedTask,
    }
    fetchTaskData(filter)
    setSearchTask('')
    setSearchTaskFilters((prev) => [...prev, newFilter])
  }

  const onClearSearchTask = () => {
    const filter = {
      skip: 1,
      limit: limit,
      taskScopeId: sowId,
      name: '',
      filters: [],
      status: '',
    }
    fetchTaskData(filter)
    setSearchTask('')
    setStatusSelectedTask('')
    setSearchTaskFilters([])
  }

  const deleteTaskSearchBadge = (key) => {
    const newSearchFilters = searchTaskFilters.filter((v) => v.key != key)
    setSearchTaskFilters(newSearchFilters)

    const filter = {
      skip: 1,
      limit: limit,
      taskScopeId: sowId,
      name: searchTask,
      filters: newSearchFilters.map((v) => v.name),
      status: statusSelectedTask,
    }
    fetchTaskData(filter)
    setSearchTask('')
  }

  //  optional
  const validateForm = () => {
    let newErrors = {
      ...initValidateForm,
    }

    if (sowName == '') {
      newErrors.name = 'Scope of work Name is required'
    }

    setErrors(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  const validateEditForm = () => {
    let newErrors = {
      ...initValidateEditForm,
    }

    if (sowForm.name == '') {
      newErrors.name = 'Scope of work Name is required'
    }
    setErrors(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  const validateTaskForm = () => {
    let newErrors = {
      ...initValidateForm,
    }

    if (taskName == '') {
      newErrors.name = 'Task Name is required'
    }

    setErrorsTask(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  const validateEditTaskForm = () => {
    let newErrors = {
      ...initValidateEditForm,
    }

    if (taskForm?.name == '') {
      newErrors.name = 'Task Name is required'
    }
    setErrorsTask(newErrors)

    return Object.values(newErrors).every((x) => x === null)
  }

  return (
    <Div>
      <div className="table-wrapper">
        <div className="table-header-2">
          <div className="group mb-1-rem">
            <div className="table-title">Scope of work</div>
            <div className="table-total">{total} SOW</div>
          </div>

          <div className="input-header-2 mb-1-rem">
            <div className="group">
              <SearchText
                className="input-width"
                placeholder="Search for scope of work"
                value={searchSow}
                onChange={(e) => setSearchSow(e.target.value)}
                onSubmit={onSearchSow}
                onClear={onClearSearchSow}
              />
              <Dropdown
                className=""
                optionList={initStatusDropdown}
                value={statusSelected}
                onChange={(value) => setStatusSelected(value)}
              />
              <ButtonOutline className="d-flex justify-content-center" onClick={onClearSearchSow}>
                Clear
              </ButtonOutline>
            </div>
            <Button onClick={openCreateScopeOfWorkPanel}>New scope of work</Button>
          </div>
          <div className="badge-search-content">
            {searchSowFilters.map((v) => (
              <div className="badge" key={v.key}>
                <span className="name">{v.name}</span>{' '}
                <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSowSearchBadge(v.key)} />
              </div>
            ))}
          </div>
        </div>

        <Table
          columns={scopeOfWorkColumn}
          data={scopeOfWorkList}
          onStateChange={onChangePaginationForSow}
          pageCount={pageCount}
          page={page}
        />
      </div>

      {/* Modal */}
      <DialogConfirmDelete
        open={Boolean(confirmDeleteModal)}
        onClose={() => setConfirmDeleteModal(false)}
        onSubmit={confirmDelete}
        title={t('confrim_delete')}
        description={`${t('confirm_delete_header')} ${sowNameDelete}?`}
        //description={`Would you like to confirm the deletion of ${sowNameDelete}?`}
      />
      <DialogConfirmDelete
        open={Boolean(confirmDeleteTaskModal)}
        onClose={() => setConfirmDeleteTaskModal(false)}
        onSubmit={confirmDeleteTask}
        title={t('confrim_delete')}
        //description={t('confirm_delete_header')}
        description={`${t('confirm_delete_header')} ${sowNameDelete}?`}
      />
      <DialogConfirm
        open={Boolean(confirmCreateSowModal)}
        onClose={() => setConfirmCreateSowModal(false)}
        onSubmit={onSubmitcreateSow}
        title={t('confirm_create_sow_header')}
        description={sowName}
        textYes={t('confirm')}
        textNo={t('Back')}
      />
      <DialogConfirm
        open={Boolean(confirmUpdateSowModal)}
        onClose={() => setConfirmUpdateSowModal(false)}
        onSubmit={editSow}
        title={t('confirm_update_sow_header')}
        description={sowForm?.name}
        textYes={t('confirm')}
        textNo={t('Back')}
      />
      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setSuccessModal(false)}
        onSubmit={confirmSuccess}
        icon={successIcon}
        title={successMessageModal.headline}
        description={successMessageModal.message}
        textYes={t('done')}
      />

      {/* View Sow Panel */}
      <SidePanel isOpen={isOpenSidePanelView} setIsOpen={setIsOpenSidePanelView} width={800}>
        <Div>
          <div className="side-panel-container">
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2-rem">
                <div className="header">{sowNameDelete}</div>
                <LiaTimesSolid size={25} onClick={closeViewScopeOfWorkPanel} className="icon" />
              </div>

              <div className="input-header-2 mb-1-rem">
                <div className="group">
                  <SearchText
                    className="mr-1-rem"
                    placeholder="Search"
                    value={searchTask}
                    onChange={(e) => setSearchTask(e.target.value)}
                    onSubmit={onSearchTask}
                    onClear={onClearSearchTask}
                  />
                  <Dropdown
                    className="mr-1-rem"
                    optionList={initStatusDropdown}
                    value={statusSelectedTask}
                    onChange={(value) => setStatusSelectedTask(value)}
                  />
                  <ButtonOutline className="d-flex justify-content-center" onClick={onClearSearchTask}>
                    Clear
                  </ButtonOutline>
                </div>
              </div>
              <div className="badge-search-content mb-1-rem">
                {searchTaskFilters.map((v) => (
                  <div className="badge" key={v.key}>
                    <span className="name">{v.name}</span>{' '}
                    <LiaTimesSolid size={15} className="icon close" onClick={() => deleteTaskSearchBadge(v.key)} />
                  </div>
                ))}
              </div>
              <Button id="new-task" className="mb-1-rem" onClick={() => setIsOpenSidePanelCreateTask(true)}>
                New Task
              </Button>
              <Table
                columns={taskColumn}
                data={taskList}
                onStateChange={onChangePaginationForTask}
                pageCount={taskTypeStore?.pageCount}
                page={taskTypeStore?.page}
                loading={taskTypeStore?.isLoading}
              />
            </div>
          </div>
        </Div>
      </SidePanel>
      {/* Create Sow Panel */}
      <SidePanel isOpen={isOpenSidePanelCreate} setIsOpen={setIsOpenSidePanelCreate} width={450}>
        <Div>
          <div className="side-panel-container">
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2-rem">
                <div className="header">New Scope of Work</div>
                <LiaTimesSolid size={25} onClick={closeCreateScopeOfWorkPanel} className="icon" />
              </div>
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">Scope of Work Name</div>
                <div className="input-new">
                  <TextField
                    className={`${errors.name == null ? '' : 'error-input'}`}
                    placeholder="Scope of Work Name"
                    name="scope_of_work"
                    value={sowName}
                    onChange={(e) => setSowName(e.target.value)}
                  />
                  {errors.name && <p className="error">{errors.name}</p>}
                </div>
              </div>
            </div>

            <div>
              <div className="divider"></div>
              <div className="d-flex justify-content-flex-end">
                <ButtonOutline onClick={closeCreateScopeOfWorkPanel} className="mr-1-rem">
                  Cancel
                </ButtonOutline>
                <Button id="create-sow" onClick={openConfirmCreateSow}>
                  Create
                </Button>
              </div>
            </div>
          </div>
        </Div>
      </SidePanel>
      {/* Edit Sow Panel */}
      <SidePanel isOpen={isOpenSidePanelEdit} setIsOpen={setIsOpenSidePanelEdit} width={450}>
        <Div>
          <div className="side-panel-container">
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2-rem">
                <div className="header">Edit Scope of Work</div>
                <LiaTimesSolid size={25} onClick={closeEditScopeOfWorkPanel} className="icon" />
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">Scope of Work Name</div>
                <div className="input-new">
                  <TextField
                    className={`${errors.name == null ? '' : 'error-input'}`}
                    placeholder="Scope of Work Name"
                    name="name"
                    value={sowForm?.name}
                    onChange={onChangeEditSowForm}
                  />
                  {errors.name && <p className="error">{errors.name}</p>}
                </div>
              </div>
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">Status</div>
                <div className="input-new">
                  <Dropdown
                    name="status"
                    optionList={initStatusDropdown}
                    value={sowForm?.status}
                    onChange={onChangeDropdownEditSowForm}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="divider"></div>
              <div className="d-flex justify-content-flex-end">
                <ButtonOutline onClick={closeEditScopeOfWorkPanel} className="mr-1-rem">
                  Cancel
                </ButtonOutline>
                <Button id="create-sow" onClick={openConfirmUpdateSow}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Div>
      </SidePanel>
      {/* Create Task Panel */}
      <SidePanel isOpen={isOpenSidePanelCreateTask} setIsOpen={setIsOpenSidePanelCreateTask} width={450}>
        <Div>
          <div className="side-panel-container">
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2-rem">
                <div className="header">New Task</div>
                <LiaTimesSolid size={25} onClick={closeCreateTaskPanel} className="icon" />
              </div>

              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">Task Name</div>
                <div className="input-new">
                  <TextField
                    className={`${errorsTask.name == null ? '' : 'error-input'}`}
                    placeholder="Task"
                    name="task"
                    value={taskName}
                    onChange={(e) => setTaskName(e.target.value)}
                  />
                  {errorsTask.name && <p className="error">{errorsTask.name}</p>}
                </div>
              </div>
            </div>

            <div>
              <div className="divider"></div>
              <div className="d-flex justify-content-flex-end">
                <ButtonOutline onClick={closeCreateTaskPanel} className="mr-1-rem">
                  Cancel
                </ButtonOutline>
                <Button id="create-tas," onClick={createTask}>
                  Create
                </Button>
              </div>
            </div>
          </div>
        </Div>
      </SidePanel>
      {/* Edit Task Panel */}
      <SidePanel isOpen={isOpenSidePanelEditTask} setIsOpen={setIsOpenSidePanelEditTask} width={450}>
        <Div>
          <div className="side-panel-container">
            <div>
              <div className="d-flex justify-content-between align-items-center mb-2-rem">
                <div className="header">Edit Task</div>
                <LiaTimesSolid size={25} onClick={closeEditTaskPanel} className="icon" />
              </div>
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">Task Name</div>
                <div className="input-new">
                  <TextField
                    className={`${errorsTask.name == null ? '' : 'error-input'}`}
                    placeholder="Task"
                    name="name"
                    value={taskForm?.name}
                    onChange={onChangeEditTaskForm}
                  />
                  {errorsTask.name && <p className="error">{errorsTask.name}</p>}
                </div>
              </div>
              <div className="mb-1-rem">
                <div className="input-header mb-1-rem">Status</div>
                <div className="input-new">
                  <Dropdown
                    name="status"
                    optionList={initStatusDropdown}
                    value={taskForm?.status}
                    onChange={onChangeDropdownEditTaskForm}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="divider"></div>
              <div className="d-flex justify-content-flex-end">
                <ButtonOutline onClick={closeEditTaskPanel} className="mr-1-rem">
                  {t('cancel')}
                </ButtonOutline>
                <Button id="create-task" onClick={editTask}>
                  {t('save')}
                </Button>
              </div>
            </div>
          </div>
        </Div>
      </SidePanel>
    </Div>
  )
}
