import React from 'react'
import { Controller } from 'react-hook-form'

import styled from 'styled-components'
import { Box, Stack } from '@mui/material'
import Dropdown from 'components/form/Dropdown'
import Date from 'components/form/Date'
import DateTime from 'components/form/DateTime'
import Time from 'components/form/Time'
import SearchText from 'components/form/SearchText'

const Div = styled.div`
  .input-heading {
    color: var(--Gray-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 6px;
  }
  .w-100 {
    width: 100%;
  }
  .error {
    color: var(--Error-500);
  }
`

function DynamicFilter({ formFields, control, onSearch, onChangeFilter, setNewFormField }) {
  const renderInput = (key, value) => {
    const { title, type, attribute, reset_value, ref_fetch } = value
    const { disabled, options, placeholder } = attribute

    const rules = Object.entries(attribute)
      .filter(([key]) => key !== 'placeholder' && key !== 'options' && key !== 'disabled')
      .reduce((acc, atr) => {
        const [key, value] = atr
        if (value) return { ...acc, [key]: value }
        return acc
      }, {})

    switch (type) {
      case 'search-text': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => {
                return (
                  <>
                    <SearchText
                      placeholder={placeholder}
                      value={field.value}
                      onChange={field.onChange}
                      onSubmit={onSearch}
                      onClear={(_) => field.onChange('')}
                    />
                    {error && <p className="error">{error.message}</p>}
                  </>
                )
              }}
            />
          </Box>
        )
      }
      case 'dropdown': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Dropdown
                    value={field.value}
                    onChange={(newValue) => {
                      onChangeFilter(key, newValue)
                      field.onChange(newValue)
                    }}
                    error={error}
                    disabled={disabled}
                    optionList={options}
                    placeHolder={placeholder}
                  />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      case 'dropdown-api': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Dropdown
                    value={field.value}
                    onChange={(newValue) => {
                      field.onChange(newValue)
                      onChangeFilter(key, newValue)
                      setNewFormField(newValue, ref_fetch, reset_value)
                    }}
                    error={error}
                    disabled={disabled}
                    optionList={options}
                    placeHolder={placeholder}
                  />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      case 'date': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Date
                    value={field.value}
                    onChange={(newValue) => {
                      onChangeFilter(key, newValue)
                      field.onChange(newValue)
                    }}
                    error={error}
                    disabled={disabled}
                  />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      case 'date-time': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <DateTime
                    value={field.value}
                    onChange={(newValue) => {
                      onChangeFilter(key, newValue)
                      field.onChange(newValue)
                    }}
                    error={error}
                    disabled={disabled}
                  />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      case 'time': {
        return (
          <Box key={key}>
            <Box className="input-heading">{title}</Box>
            <Controller
              name={key}
              control={control}
              rules={{ ...rules }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <Time
                    value={field.value}
                    onChange={(newValue) => {
                      onChangeFilter(key, newValue)
                      field.onChange(newValue)
                    }}
                    error={error}
                    disabled={disabled}
                  />
                  {error && <p className="error">{error.message}</p>}
                </>
              )}
            />
          </Box>
        )
      }
      default: {
        return <Box key={key}>can't map type render {type}</Box>
      }
    }
  }

  return (
    <Div>
      <Stack direction="row" alignItems="flex-end" spacing={1}>
        {Object.entries(formFields).map(([key, value]) => (
          <Box key={key}>{renderInput(key, value)}</Box>
        ))}
      </Stack>
    </Div>
  )
}

export default DynamicFilter
