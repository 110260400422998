import { getAPI, postAPI, putAPI } from '../../utils/api'
import { GET_PROJECT_DASHBOARD, GET_PROJECT_MANAGER, GET_PROJECT_DASHBOARD_BY_ID, GET_LOGS } from '../actionTypes'

import { showLoading, hideLoading } from '../actions/loading'

export const getProjectDashboards =
  (filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_PROJECT_DASHBOARD.REQUEST })

      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-type': 'application/json',
      }

      let multipleSearch = {}
      if (filter.multipleSearch) {
        multipleSearch = Object.assign({}, ...filter.multipleSearch)
      }

      const params = {
        ...multipleSearch,
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'filters[company][id][$eq]': company_info?.id,
      }
      if (filter.project_manager !== '') params['filters[project_manager][id]'] = filter.project_manager
      if (filter.status !== '') params['filters[status][$eq]'] = filter.status
      if (filter.start_date !== '' && filter.end_date !== '') {
        params['filters[$or][0][$and][0][start_date][$gte]'] = filter.start_date
        params['filters[$or][0][$and][1][start_date][$lte]'] = filter.start_date
        params['filters[$or][1][$and][0][end_date][$gte]'] = filter.end_date
        params['filters[$or][1][$and][1][end_date][$lte]'] = filter.end_date
      }

      const { data, meta } = await getAPI({
        url: '/api/project-dashboards',
        headers,
        params,
      })

      return dispatch({
        type: GET_PROJECT_DASHBOARD.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_PROJECT_DASHBOARD.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Project Dashboard list failed!',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getProjectDashboardById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PROJECT_DASHBOARD_BY_ID.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-type': 'application/json',
    }
    const { data } = await getAPI({
      url: `/api/project-dashboards/${id}`,
      headers,
    })

    return dispatch({
      type: GET_PROJECT_DASHBOARD_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_PROJECT_DASHBOARD_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Project Dashboard by id failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getProjectManagers = () => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_PROJECT_MANAGER.REQUEST })

    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-type': 'application/json',
    }
    const params = {
      'pagination[page]': 1,
      'pagination[pageSize]': 100,
    }
    const { data } = await getAPI({
      url: `/api/project-dashboards/distinct/project-managers`,
      headers,
      params,
    })

    return dispatch({
      type: GET_PROJECT_MANAGER.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_PROJECT_MANAGER.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Project Managers failed!',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getLogs =
  (id, filter = { page: 1, pageSize: 10 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_LOGS.REQUEST })

      const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-type': 'application/json',
      }

      const params = {
        'pagination[page]': filter.page,
        'pagination[pageSize]': filter.pageSize,
        'sort[0][id]': 'desc',
        'fields[0]': 'message',
        'fields[1]': 'event_date',
        'fields[2]': 'event_level',
        'fields[3]': 'meta_data',
        'fields[4]': 'feature',
        'fields[5]': 'reference',
        'filters[feature]': 'project task',
        'filters[company][id]': company_info?.id,
        'filters[reference]': id,
      }

      const { data, meta } = await getAPI({
        url: '/api/logs-applications',
        headers,
        params,
      })

      dispatch({
        type: GET_LOGS.SUCCESS,
        data,
      })
      return {
        data,
        meta,
      }
    } catch (error) {
      dispatch({
        type: GET_LOGS.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Logs failed!',
      })
      return null
    } finally {
      dispatch(hideLoading())
    }
  }
