import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  createBusinessDocument,
  createCustomer,
  editBusinessDocument,
  editCustomer,
  getBusinessDocById,
  getCompanyInfoById,
  getDocumentSettingByCompanyId,
} from 'redux/actions/document'

import { selectDocument } from 'redux/selectors'
import styled from 'styled-components'
import CreateDocLayout from '../custom-view/CreateDocLayout'
import DialogDoc from '../custom-view/DialogDoc'

export default function CreditAdjustmentDocument({ previewType, currentPage, onCloseDialog, filter, itemSelected }) {
  
  const mockupDataForCreate = {
    doc_type: 'credit_adjustment_document',
    creator_id: 2, //employee id ของคนสร้าง doc
    sale_area: 'Bangkok',
    code_sale: 'SL123',
    doc_date: '2023-09-28',
    customer_id: 1,
    business_type: 'โรงงานน้ำตาล',
    authorized_purchaser: 'ชวนันท์',

    //body top
    reason: 'เหตุผล...',
    current_limit: 35000000,
    new_limit: 40000000,
    first_purchase_date: '2022-04-10',
    cheque_return_amount: 10,
    latest_purchase_date: '2023-12-12',
    credit_day: 30,
    avg_purchase: 30000000,
    avg_payment_day: 90,
    late_payment_day: 100,
    current_payment: 25000000,
    latest_change_date: '2023-07-27',
    latest_change_limit: 50000000,
    requirement: 'ข้อกำหนด...',

    //body bottom
    outstanding_balance: 20000000,
    is_approved: true,
    suggestion: 'ข้อเสนอแนะ...',

    company_id: 1, //company id ของคนสร้าง doc
    is_external: false,
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const docId = searchParams?.get('id')
  const navigateType = searchParams.get('navigate')
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { businessDocumentData, companyData, documentSettingInfo } = useSelector(selectDocument)

  // for create data
  const docTitle = 'เอกสารปรับปรุงสินเชื่อ'
  const docPreviewTitle = 'เอกสารปรับปรุงสินเชื่อ/รายละเอียดลูกค้า'

  const docType = 'credit_adjustment_document'
  const navigatePath = '/document/accounting?doctype=' + docType

  const HEADER_LIST = [
    [
      [
        {
          id: 'hd01',
          position: 'left',
          type: 'search_customer',
          title: 'Customer id',
          placeholder: 'Customer id',
          sub_type: 'customer_id',
          class_name_create: '',
          class_name_preview: '',
        },
        {
          id: 'hd02',
          position: 'left',
          type: 'search_customer',
          title: 'Customer Name',
          placeholder: 'Name',
          sub_type: 'customer_name',
        },
        {
          id: 'hd02-1',
          position: 'left',
          type: 'search_customer',
          title: 'Tax id',
          placeholder: 'Tax id',
          sub_type: 'tax_id',
        },
        {
          id: 'hd02-2',
          position: 'left',
          type: 'input_text',
          title: 'Office/Branch Number',
          placeholder: 'สำนักงาน/สาขาเลขที่',
        },
        {
          id: 'hd03',
          position: 'left',
          type: 'input_text',
          title: 'เบอร์โทรศัพท์',
          placeholder: '081-111-1111',
        },
        {
          id: 'hd04',
          position: 'left',
          type: 'input_text',
          title: 'เขตการขาย',
        },
        {
          id: 'hd05',
          position: 'left',
          type: 'input_text',
          title: 'Code sale',
        },
        {
          id: 'hd06',
          position: 'right',
          type: 'input_text_area',
          title: 'Address',
          placeholder: 'Address details',
        },
      ],
    ],
    [
      { id: 'hd07-0', type: 'input_date', title: 'วันที่' },
      { id: 'hd07', type: 'input_text', title: 'ประเภทกิจการ', placeholder: 'โรงงานน้ำตาล' },
      { id: 'hd08', type: 'input_text', title: 'ชื่อผู้มีอำนาจสั่งซื้อ', placeholder: 'ชวนันท์' },
    ],
  ]
  const BODY_LIST = [
    [
      {
        id: 'bd01',
        type: 'input_text',
        title: 'เหตุผลในการปรับปรุงวงเงิน / เปลี่ยนแปลงรายละเอียดลูกค้า',
        placeholder: 'details',
        class_name_create: 'width_100',
      },
    ],
    [
      {
        id: 'bd02',
        type: 'input_text',
        title: 'วงเงินปัจจุบัน',
        class_name_create: 'width_100 margin_right_10px',
        sub_type: 'number',
        step: '0.01',
        class_name_preview: 'flex_row text_bold padding_right_10px',
        preview_unit: 'บาท',
      },
      {
        id: 'bd03',
        type: 'input_text',
        title: 'วงเงินปรับใหม่',
        class_name_create: 'width_100',
        sub_type: 'number',
        step: '0.01',
        class_name_preview: 'flex_row text_bold padding_right_10px',
        preview_unit: 'บาท',
      },
    ],
    [
      {
        id: 'bd04',
        type: 'input_date',
        title: 'เริ่มติดต่อซื้อครั้งแรกตั้งแต่',
        class_name_create: 'width_100 margin_right_10px',
        class_name_preview: 'flex_row width_300_px',
      },
      {
        id: 'bd05',
        type: 'input_text',
        title: 'มีเช็คคืนรวม (ครั้ง)',
        class_name_create: 'width_100',
        sub_type: 'number',
        step: '1',
        class_name_preview: 'flex_row width_300_px preview_unit',
        preview_title: 'มีเช็คคืนรวม',
        preview_unit: 'ครั้ง',
      },
    ],
    [
      {
        id: 'bd06',
        type: 'input_date',
        title: 'ซื้อครั้งสุดท้ายวันที่',
        class_name_create: 'width_100 margin_right_10px',
        class_name_preview: 'flex_row width_300_px',
      },
      {
        id: 'bd07',
        type: 'input_text',
        class_name_create: 'width_100',
        title: 'เครดิต (วัน)',
        sub_type: 'number',
        step: '1',
        class_name_preview: 'flex_row width_300_px preview_unit',
        preview_title: 'เครดิต',
        preview_unit: 'วัน',
      },
    ],
    [
      {
        id: 'bd08',
        type: 'input_text',
        title: 'ซื้อเฉลี่ย 6 เดือนหลัง (บาท/เดือน)',
        class_name_create: 'width_100 margin_right_10px',
        sub_type: 'number',
        step: '0.01',
        class_name_preview: 'flex_row width_300_px preview_unit',
        preview_title: 'ซื้อเฉลี่ย 6 เดือนหลัง',
        preview_unit: 'บาท/เดือน',
      },
      {
        id: 'bd09',
        type: 'input_text',
        title: 'เฉลี่ยการชำระเงิน 6 เดือนหลัง (วัน)',
        class_name_create: 'width_100',
        sub_type: 'number',
        step: '0.01',
        class_name_preview: 'flex_row width_300_px preview_unit',
        preview_title: 'เฉลี่ยการชำระเงิน 6 เดือนหลัง',
        preview_unit: 'วัน',
      },
    ],
    [
      {
        id: 'bd10',
        type: 'input_text',
        title: 'ระยะการาชำระบิลที่ช้าที่สุด (วัน)',
        class_name_create: 'width_100 margin_right_10px',
        sub_type: 'number',
        step: '1',
        class_name_preview: 'flex_row width_300_px preview_unit',
        preview_title: 'ระยะการาชำระบิลที่ช้าที่สุด',
        preview_unit: 'วัน',
      },
      {
        id: 'bd11',
        type: 'input_text',
        title: 'จำนวนเงินที่ใช้อยู่ในปัจจุบัน (บาท)',
        class_name_create: 'width_100',
        sub_type: 'number',
        step: '0.01',
        class_name_preview: 'flex_row width_300_px preview_unit',
        preview_title: 'จำนวนเงินที่ใช้อยู่ในปัจจุบัน',
        preview_unit: 'บาท',
      },
    ],
    [
      {
        id: 'bd12',
        type: 'input_date',
        title: 'ปรับวงเงินครั้งก่อนเมื่อวันที่',
        class_name_create: 'width_100 margin_right_10px',
        class_name_preview: 'flex_row width_300_px',
      },
      {
        id: 'bd13',
        type: 'input_text',
        title: 'วงเงินใหม่ (บาท)',
        class_name_create: 'width_100',
        sub_type: 'number',
        step: '0.01',
        class_name_preview: 'flex_row width_300_px preview_unit',
        preview_title: 'วงเงินใหม่',
        preview_unit: 'บาท',
      },
    ],
    [
      {
        id: 'bd14',
        type: 'input_text',
        title: 'ข้อกำหนดอื่นๆในการปรับปรุงวงเงิน',
        class_name_create: 'width_100',
        class_name_preview: 'flex_row padding_right_10px',
      },
    ],
    [
      {
        id: 'bd15',
        type: 'finance',
        class_name_create: 'width_100',
        value: {
          text_outstanding_balance: '',
          is_agree: false,
          text_suggestions: '',
        },
      },
    ],
  ]

  const [isBindingDataDone, setIsBindingDataDone] = useState(false)
  const [previewStage, setPreviewStage] = useState(previewType || 'close')
  const [headerList, setHeaderList] = useState(HEADER_LIST)
  const [bodyList, setBodyList] = useState(BODY_LIST)
  const [documentId, setDocumentId] = useState()

  //optional
  const [customerData, setCustomerData] = useState()
  const [companyDocData, setCompanyDocData] = useState()

  useEffect(() => {
    dispatch(getCompanyInfoById())
    dispatch(getDocumentSettingByCompanyId())
  }, [])

  useEffect(() => {
    updateFieldByCustomerData()
  }, [customerData])

  useEffect(() => {
    if (docId || itemSelected?.id) {
      let tmpId = itemSelected?.id || docId
      setDocumentId(tmpId)
    }
  }, [docId, itemSelected])

  useEffect(() => {
    if (documentId && documentId != 'undefined') {
      dispatch(getBusinessDocById(documentId))
    }
  }, [documentId])

  useEffect(() => {
    if (documentId && businessDocumentData?.id) {
      bindEditData()
    }
  }, [businessDocumentData])

  useEffect(() => {
    let tmpCompanyData

    if (!documentId) {
      tmpCompanyData = {
        seller_logo: documentSettingInfo[docType]?.company_logo,
        seller_company_name: companyData?.company_name_TH,
        seller_address: companyData?.address,
        seller_tax_id: companyData?.tex_id,

        customer_company_id: customerData?.customer_id,
        customer_company_name: customerData?.name || 'Test user',
        customer_address: customerData?.address,
        customer_phone: customerData?.phone,

        header_title: 'ลูกค้า',
        customer_id_title: 'รหัสลูกค้า',
      }

      setCompanyDocData(tmpCompanyData)
    } else {
      if (businessDocumentData && customerData) {
        tmpCompanyData = {
          seller_logo: businessDocumentData?.company_logo,
          seller_company_name: businessDocumentData?.company?.company_name_TH,
          seller_address: businessDocumentData?.company?.address,
          seller_tax_id: businessDocumentData?.company?.tex_id,

          customer_company_id: customerData?.customer_id,
          customer_company_name: customerData?.name || 'Test user',
          customer_address: customerData?.address,
          customer_phone: customerData?.phone,

          sale_area: businessDocumentData?.sale_area,
          code_sale: businessDocumentData?.code_sale,

          doc_id: businessDocumentData?.doc_id,
          header_title: 'ลูกค้า',
        }

        setCompanyDocData(tmpCompanyData)
      }
    }
  }, [companyData, documentId, businessDocumentData, customerData, documentSettingInfo])

  const bindEditData = () => {
    setIsBindingDataDone(false)

    //bindHeader
    let tmpHeaderList = [...headerList]
    for (let colIndex = 0; colIndex < tmpHeaderList.length; colIndex++) {
      let colItem = tmpHeaderList[colIndex]
      //  col left
      if (colIndex == 0) {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]
          for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
            let dataItem = rowItem[dataIndex]
            if (dataItem?.type == 'search_customer') {
              let tmpText = ''
              if (dataItem?.sub_type == 'customer_id') {
                tmpText = businessDocumentData?.customer?.customer_id
              } else if (dataItem?.sub_type == 'customer_name') {
                tmpText = businessDocumentData?.customer?.name
              } else if (dataItem?.sub_type == 'tax_id') {
                tmpText = businessDocumentData?.customer?.tax_id
              }
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                customer_data: businessDocumentData?.customer,
                text: tmpText,
              }
            } else if (dataItem?.id == 'hd04') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                text: businessDocumentData?.sale_area || '',
              }
            } else if (dataItem?.id == 'hd05') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                text: businessDocumentData?.code_sale || '',
              }
            } else if (dataItem?.id == 'hd06') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                text: businessDocumentData?.customer?.address || '',
              }
            }
          }
        }
      }
      //  col right
      else if (colIndex == 1) {
        for (let dataIndex = 0; dataIndex < colItem.length; dataIndex++) {
          let dataItem = colItem[dataIndex]

          if (dataItem?.id == 'hd07-0') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.doc_date,
            }
          } else if (dataItem?.id == 'hd07') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.business_type,
            }
          } else if (dataItem?.id == 'hd08') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.authorized_purchaser,
            }
          }
        }
      }
    }

    //bindBody
    let tmpBodyList = JSON.parse(JSON.stringify(bodyList))

    for (let rowIndex = 0; rowIndex < tmpBodyList.length; rowIndex++) {
      let rowItem = tmpBodyList[rowIndex]
      for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
        let dataItem = rowItem[dataIndex]

        if (dataItem?.id == 'bd01') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.reason }
        } else if (dataItem?.id == 'bd02') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.current_limit }
        } else if (dataItem?.id == 'bd03') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.new_limit }
        } else if (dataItem?.id == 'bd04') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.first_purchase_date }
        } else if (dataItem?.id == 'bd05') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.cheque_return_amount }
        } else if (dataItem?.id == 'bd06') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.latest_purchase_date }
        } else if (dataItem?.id == 'bd07') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.credit_day }
        } else if (dataItem?.id == 'bd08') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.avg_purchase }
        } else if (dataItem?.id == 'bd09') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.avg_payment_day }
        } else if (dataItem?.id == 'bd10') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.late_payment_day }
        } else if (dataItem?.id == 'bd11') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.current_payment }
        } else if (dataItem?.id == 'bd12') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.latest_change_date }
        } else if (dataItem?.id == 'bd13') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.latest_change_limit }
        } else if (dataItem?.id == 'bd14') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.requirement }
        } else if (dataItem?.id == 'bd15') {
          tmpBodyList[rowIndex][dataIndex].value = {
            text_outstanding_balance: businessDocumentData?.outstanding_balance,
            is_agree: businessDocumentData?.is_approved,
            text_suggestions: businessDocumentData?.suggestion,
          }
        }
      }
    }

    setHeaderList(tmpHeaderList)
    setBodyList(tmpBodyList)
    setCustomerData(businessDocumentData?.customer)
    setIsBindingDataDone(true)
  }

  const updateFieldByCustomerData = () => {
    let tmpHeaderList = [...headerList]
    let tmpHeaderLeft = tmpHeaderList[0]

    for (let rowIndex = 0; rowIndex < tmpHeaderLeft.length; rowIndex++) {
      let rowItem = tmpHeaderLeft[rowIndex]
      for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
        let dataItem = rowItem[dataIndex]

        if (dataItem?.type == 'search_customer') {
          let tmpText = ''

          if (dataItem?.sub_type == 'customer_id') {
            tmpText = customerData?.customer_id
          } else if (dataItem?.sub_type == 'customer_name') {
            tmpText = customerData?.name
          } else if (dataItem?.sub_type == 'tax_id') {
            tmpText = customerData?.tax_id
          }

          tmpHeaderList[0][rowIndex][dataIndex].value = {
            customer_data: customerData,
            text: tmpText,
          }
        } else if (dataItem?.id == 'hd02-2') {
          tmpHeaderList[0][rowIndex][dataIndex].value = { text: customerData?.office }
        } else if (dataItem?.id == 'hd03') {
          tmpHeaderList[0][rowIndex][dataIndex].value = { text: customerData?.phone }
        } else if (dataItem?.id == 'hd06') {
          tmpHeaderList[0][rowIndex][dataIndex].value = { text: customerData?.address }
        }
      }
    }

    setHeaderList(tmpHeaderList)
  }

  const getHeaderValues = () => {
    let tmpValues = {}
    let tmpHeaderList = [...headerList]
    let tmpUpdateCustomerData = {}

    for (let colIndex = 0; colIndex < headerList.length; colIndex++) {
      let colItem = headerList[colIndex]
      if (colIndex == 0) {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]

          for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
            let dataItem = rowItem[dataIndex]

            if (dataItem?.id == 'hd01') {
              tmpUpdateCustomerData.customer_id = dataItem?.value?.text || ''
              if (dataItem?.value?.customer_data?.id) {
                tmpValues.customer_id = dataItem.value.customer_data.id
              }
            } else if (dataItem?.id == 'hd02') {
              tmpUpdateCustomerData.name = dataItem?.value?.text || ''
            } else if (dataItem?.id == 'hd02-1') {
              tmpUpdateCustomerData.tax_id = dataItem?.value?.text || ''
            } else if (dataItem?.id == 'hd02-2') {
              tmpUpdateCustomerData.office = dataItem?.value?.text || ''
            } else if (dataItem?.id == 'hd03') {
              tmpUpdateCustomerData.phone = dataItem?.value?.text || ''
            } else if (dataItem?.id == 'hd06') {
              tmpUpdateCustomerData.address = dataItem?.value?.text || ''
            } else if (dataItem?.id == 'hd04') {
              tmpValues.sale_area = dataItem?.value?.text || ''
            } else if (dataItem?.id == 'hd05') {
              tmpValues.code_sale = dataItem?.value?.text || ''
            }

            if (!dataItem?.value?.text && dataItem?.id != 'hd03') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                ...tmpHeaderList[colIndex][rowIndex][dataIndex]?.value,
                is_error: true,
              }
            }
          }
        }
        tmpValues.update_customer = tmpUpdateCustomerData
      } else {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]

          if (rowItem?.id == 'hd07' && rowItem?.value?.text) {
            tmpValues.business_type = rowItem.value.text
          } else if (rowItem?.id == 'hd07-0' && rowItem?.value?.text) {
            tmpValues.doc_date = rowItem.value.text
          } else if (rowItem?.id == 'hd08' && rowItem?.value?.text) {
            tmpValues.authorized_purchaser = rowItem.value.text
          }

          if (!rowItem?.value?.text) {
            tmpHeaderList[colIndex][rowIndex].value = {
              ...tmpHeaderList[colIndex][rowIndex]?.value,
              is_error: true,
            }
          }
        }
      }
    }
    setHeaderList(tmpHeaderList)
    return tmpValues
  }

  const getBodyValues = () => {
    let tmpBodyList = JSON.parse(JSON.stringify(bodyList))
    let tmpValues = {}

    for (let rowIndex = 0; rowIndex < tmpBodyList.length; rowIndex++) {
      let rowItem = tmpBodyList[rowIndex]
      for (let colIndex = 0; colIndex < rowItem.length; colIndex++) {
        let colItem = rowItem[colIndex]

        if (colItem?.id == 'bd01') {
          tmpValues.reason = colItem?.value?.text || ''
        } else if (colItem?.id == 'bd02') {
          tmpValues.current_limit = parseFloat(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd03') {
          tmpValues.new_limit = parseFloat(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd04') {
          tmpValues.first_purchase_date = colItem?.value?.text || ''
        } else if (colItem?.id == 'bd05') {
          tmpValues.cheque_return_amount = parseInt(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd06') {
          tmpValues.latest_purchase_date = colItem?.value?.text || ''
        } else if (colItem?.id == 'bd07') {
          tmpValues.credit_day = parseInt(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd08') {
          tmpValues.avg_purchase = parseFloat(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd09') {
          tmpValues.avg_payment_day = parseInt(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd10') {
          tmpValues.late_payment_day = parseInt(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd11') {
          tmpValues.current_payment = parseFloat(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd12') {
          tmpValues.latest_change_date = colItem?.value?.text || ''
        } else if (colItem?.id == 'bd13') {
          tmpValues.latest_change_limit = parseFloat(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd14') {
          tmpValues.requirement = colItem?.value?.text || ''
        } else if (colItem?.id == 'bd15') {
          tmpValues.is_approved = colItem?.value?.is_agree || false
          tmpValues.outstanding_balance = parseFloat(colItem?.value?.text_outstanding_balance || 0)
          tmpValues.suggestion = colItem?.value?.text_suggestions || ''
        }

        if (!colItem?.value?.text && (colItem?.id == 'bd01' || colItem?.id == 'bd02' || colItem?.id == 'bd03')) {
          tmpBodyList[rowIndex][colIndex].value = {
            ...tmpBodyList[rowIndex][colIndex]?.value,
            is_error: true,
          }
        }
      }
    }

    setBodyList(tmpBodyList)
    return tmpValues
  }

  const getIsSameCustomer = () => {
    let isSameCustomer = false
    let headerLeft = [...headerList[0]]
    let sumSameCustomer = 0

    for (let rowIndex = 0; rowIndex < headerLeft.length; rowIndex++) {
      let rowItem = headerLeft[rowIndex]
      for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
        let dataItem = rowItem[dataIndex]

        if (dataItem?.id == 'hd01' && dataItem?.value?.text && dataItem?.value?.text == customerData?.customer_id) {
          sumSameCustomer++
        } else if (dataItem?.id == 'hd02' && dataItem?.value?.text && dataItem?.value?.text == customerData?.name) {
          sumSameCustomer++
        } else if (dataItem?.id == 'hd02-1' && dataItem?.value?.text && dataItem?.value?.text == customerData?.tax_id) {
          sumSameCustomer++
        }
      }
    }

    if (sumSameCustomer > 0) {
      isSameCustomer = true
    }

    return isSameCustomer
  }

  const updateCustomer = async (isSameCustomer, updatedCustomerData) => {
    let customerId = customerData?.id

    if (customerId && isSameCustomer) {
      await dispatch(editCustomer({ data: updatedCustomerData }, customerId))
      return -1
    } else {
      const res = await dispatch(createCustomer({ data: { ...updatedCustomerData, company_id: company_info?.id } }))
      return res?.data?.id || -1
    }
  }

  const handelCloseDialog = () => {
    setPreviewStage('close')
    if (onCloseDialog) {
      onCloseDialog()
    }
  }
  const onBtnPreviewClicked = () => {
    setPreviewStage('preview_create')
  }

  const handleCompanyDocByHeader = (tmpHeader) => {
    const tmpCompanyDocData = { ...companyDocData }

    if (!documentId) {
      tmpCompanyDocData.seller_logo = documentSettingInfo[docType]?.company_logo || ''
      tmpCompanyDocData.seller_company_name = companyData?.company_name_TH || ''
      tmpCompanyDocData.seller_address = companyData?.address || ''
      tmpCompanyDocData.seller_tax_id = companyData?.tex_id || ''
    } else {
      tmpCompanyDocData.seller_logo = businessDocumentData?.company_logo || ''
      tmpCompanyDocData.seller_company_name = businessDocumentData?.company?.company_name_TH || ''
      tmpCompanyDocData.seller_address = businessDocumentData?.company?.address || ''
      tmpCompanyDocData.seller_tax_id = businessDocumentData?.company?.tex_id || ''
    }

    tmpCompanyDocData.customer_tax_id = tmpHeader[0][0][2]?.value?.text || ''
    tmpCompanyDocData.customer_phone = tmpHeader[0][0][4]?.value?.text || ''
    tmpCompanyDocData.sale_area = tmpHeader[0][0][5]?.value?.text || ''
    tmpCompanyDocData.code_sale = tmpHeader[0][0][6]?.value?.text || ''
    tmpCompanyDocData.customer_address = tmpHeader[0][0][7]?.value?.text || ''

    setCompanyDocData(tmpCompanyDocData)
  }

  const onBtnSaveClicked = async () => {
    let tmpSendData = {
      doc_type: docType,
      creator_id: employee_info?.id,
      company_id: company_info?.id,
    }

    let tmpHeaderValues = getHeaderValues()
    let tmpBodyValues = getBodyValues()

    if (
      !tmpHeaderValues?.update_customer?.customer_id ||
      !tmpHeaderValues?.update_customer?.name ||
      !tmpHeaderValues?.update_customer?.tax_id ||
      !tmpHeaderValues?.update_customer?.office ||
      !tmpHeaderValues?.sale_area ||
      !tmpHeaderValues?.code_sale ||
      !tmpHeaderValues?.business_type ||
      !tmpHeaderValues?.authorized_purchaser ||
      !tmpHeaderValues?.doc_date
    ) {
      return
    }

    if (!tmpBodyValues?.reason || !tmpBodyValues?.current_limit || !tmpBodyValues?.new_limit) {
      return
    }

    let isSameCustomer = getIsSameCustomer()
    const customerRes = await updateCustomer(isSameCustomer, tmpHeaderValues?.update_customer)

    delete tmpHeaderValues.update_customer

    if (customerRes != -1) {
      tmpSendData.customer_id = customerRes
    }

    let uploadData = {
      ...tmpSendData,
      ...tmpHeaderValues,
      ...tmpBodyValues,
      is_external: navigateType == 'external' ? true : false,
    }

    tmpSendData = {
      type: 'credit_adjustment_document',
      data: uploadData,
    }

    if (!documentId) {
      await dispatch(createBusinessDocument(tmpSendData))
      navigate(navigatePath)
    } else {
      dispatch(editBusinessDocument(tmpSendData, documentId))
    }
  }

  return (
    <Styled>
      {(previewStage == 'close' || previewStage == 'preview_create') && (
        <CreateDocLayout
          isEdit={docId ? true : false}
          headerList={headerList}
          bodyList={bodyList}
          docType={docType}
          title={docTitle}
          onChange={(e) => {
            if (e?.layout_type == 'header_left' || e?.layout_type == 'header_right') {
              setHeaderList(e?.data)
              handleCompanyDocByHeader(e?.data)

              if (e?.customer_data) {
                setCustomerData(e.customer_data)
              }
            } else if (e?.layout_type == 'body') {
              setBodyList(e?.data)
            }
          }}
          onBtnSaveClicked={onBtnSaveClicked}
          onBtnPreviewClicked={onBtnPreviewClicked}
        />
      )}
      {(previewStage == 'request_approve' ||
        previewStage == 'preview' ||
        previewStage == 'print' ||
        previewStage == 'download_pdf' ||
        previewStage == 'share' ||
        previewStage == 'preview_create') && (
        <DialogDoc
          docType={docType}
          docTitle={docPreviewTitle || docTitle}
          headerList={headerList}
          bodyList={bodyList}
          companyDocData={companyDocData}
          previewType={previewStage}
          onCloseClicked={handelCloseDialog}
          docId={documentId}
          isBindingDataDone={isBindingDataDone}
          copyNumber={
            businessDocumentData?.number_of_copy || documentSettingInfo?.credit_adjustment_document?.number_of_copy
          }
          currentPage={currentPage}
          filter={filter}
          itemSelected={itemSelected}
          rubberStamp={documentSettingInfo?.credit_adjustment_document?.rubber_stamp}
          signatureList={documentSettingInfo?.credit_adjustment_document?.signature_list}
        />
      )}
    </Styled>
  )
}

const Styled = styled.div`
  .credit_adjustment_document {
    .body {
      .body_row_item {
        .margin_right_10px {
          margin-right: 10px;
        }
      }
    }
  }

  .preview_document_wrap.credit_adjustment_document {
    .body {
      .body_row_item {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        .flex_row {
          display: flex;
          flex-direction: row;

          .wrp_text_preview {
            display: flex;
            flex-direction: row;
            .input_text_preview {
              display: flex;
            }
          }
        }
        .text_bold {
          .title,
          .input_text_preview {
            font-weight: bolder;
          }
        }
        .padding_right_10px {
          .title {
            padding-right: 10px;
          }
        }
        .width_300_px {
          .inner_row_left,
          .title {
            width: 300px;
          }
        }
        .preview_unit {
          .input_text_preview {
            display: flex;
          }
        }
        .text_preview_unit {
          margin-left: 10px;
        }
        .wrp_finance {
          padding-top: 0;
          border-top: none;
          .row {
            margin-top: 0;
          }
        }
      }

      .inner_row_left .title,
      .wrp_text_preview .title {
        font-weight: bold;
      }
      .body_row_item:nth-child(8),
      .body_row_item:nth-child(3) {
        margin-top: 10px;
      }
      .body_row_item:nth-child(1) .wrp_text_preview .title,
      .body_row_item:nth-child(8) .wrp_text_preview .title {
        font-weight: normal;
      }
    }
  }
`
