import { useEffect, useState } from 'react'
import styled from 'styled-components'
import SearchSuggestBox from './SearchSuggestBox'
import { useDispatch, useSelector } from 'react-redux'
import { selectSummaryTask, selectProjectTask } from '../../../redux/selectors'
import { getEmployee } from '../../../redux/actions/summaryTask'
import { getScopeWork } from '../../../redux/actions/projectTask'
import { dlog } from '../../../utils/helper'

export default function SearchSuggestScopeWork({ 
  selectedId, 
  placeHolder,
  onSelectedSuggest, 
  isError = false,
  isDisabled = false
}) {
  const dispatch = useDispatch()

  // const { employeeList } = useSelector(selectSummaryTask)
  const { scopeWorkDataList } = useSelector(selectProjectTask)
  const [dropDownViews, setDropDownView] = useState([])
  const [searchText, setSearchText] = useState('')
  const [selectedName, setSelectedName] = useState('')
  const [isOnBlur, setIsOnBlur] = useState(false)

  useEffect(() => {
    // dlog(scopeWorkDataList, "START SET SCOPE")
    if (scopeWorkDataList?.length > 0) {
      let tmpData = []
      for (let i = 0; i < scopeWorkDataList.length; i++) {
        let tmp = {
          type: 'item-image',
          value: {
            title: scopeWorkDataList[i].name || '',
            deparment: scopeWorkDataList[i].department?.name || '',
            position: scopeWorkDataList[i].positions?.name || '',
          },
        }
        tmpData = [...tmpData, tmp]
      }
      let views = []
      for (let i = 0; i < tmpData.length; i++) {
        if (tmpData[i]?.type == 'header') {
          views.push(<div className={'header'}>{tmpData[i]?.value}</div>)
        } else if (tmpData[i]?.type == 'item-normal' || tmpData[i]?.type == 'item-image') {
          views.push(
            <div className={'item-normal'}>
              <div className="item-texts">
                {tmpData[i]?.value?.title && <div className="title">{tmpData[i]?.value?.title}</div>}
                {tmpData[i]?.value?.deparment && <div className="desc">Department : {tmpData[i]?.value?.deparment}</div>}
                {tmpData[i]?.value?.position && <div className="desc">Position : {tmpData[i]?.value?.position}</div>}
              </div>
            </div>
          )
        }
      }
      setDropDownView([...views])
    } else {
      setDropDownView([])
    }
  }, [scopeWorkDataList])

  useEffect(() => {
    const pagination = {
      page: 1,
      pageSize: 1000,
    }
    let filters = {
      searchText: searchText
    };
    dispatch(getScopeWork(filters, pagination))
  }, [searchText])

  useEffect(() => {
    if (scopeWorkDataList?.length > 0 && selectedId) {
      for(let i = 0; i < scopeWorkDataList.length; i++) {
        if(scopeWorkDataList[i].id == selectedId) {
          setSelectedName(scopeWorkDataList[i].name)
        }
      }
    }
  }, [scopeWorkDataList, selectedId])

  const onSelectSuggest = (index) => {
    const data = scopeWorkDataList[index]
    setSelectedName(data.name)
    onSelectedSuggest(data)
    setIsOnBlur(true)
  }
  return (
    <Styled>
      <SearchSuggestBox
        onChange={(e) => {
          setSearchText(e)
        }}
        dropdownViews={dropDownViews}
        onSelected={(index) => onSelectSuggest(index)}
        className={'search-suggest'}
        searchWidth="200px"
        dropdownWidth="350px"
        placeHolder={placeHolder}
        initValue={selectedName}
        borderColor={isError ? "#d92d20": "#d0d5dd"}
        isDisabled={isDisabled}
      />
    </Styled>
  )
}

const Styled = styled.div`
  .title {
    font-weight: bold;
  }
  /* .title, */
  .desc {
    width: 320px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .title {
    font-size: 18px;
  }
  .desc {
    font-size: 14px;
  }
`
