import dayjs from 'dayjs'

import { initPagination } from 'constants/pagination'
import { OP } from 'redux/actionTypes'
import { getAPI, postAPI, putAPI } from 'utils/api'
import { hideLoading, showLoading } from '../loading'
import { formatDate } from 'utils/dateHelpers'

const { GET, POST, PUT, CLEAR } = OP
const pathname = '/api/op-chain-links'

const deliveriesParams = {
  populate: {
    files: {
      filters: { status: 'Active' },
      populate: {
        file: {
          fields: ['mime', 'url', 'createdAt'],
        },
        employee: {
          fields: ['first_name', 'last_name'],
        },
      },
    },
    document: {
      populate: {
        business_doc: {
          fields: ['id', 'doc_id'],
        },
        signers: {
          populate: {
            employee: {
              fields: ['first_name', 'last_name'],
              populate: {
                position: {
                  fields: ['name'],
                },
              },
            },
          },
        },
      },
    },
  },
}

export default function OPChainsLink({ headers, company_info, employee_info }) {
  const getCL = () => ({
    list: (payload) => {
      const { page, pageSize } = payload?.pagination || initPagination
      const { REQUEST, SUCCESS, FAILURE } = GET.CL_LIST

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: {
              ...payload?.filters,
              company: { id: company_info?.id },
              sps: { department: { id: employee_info?.department?.id } },
            },
            sort: ['createdAt:desc', 'status:desc'],
            pagination: { page, pageSize },
            fields: ['name', 'code', 'status', 'start_date', 'created_at'],
            populate: {
              chain_link_temp: {
                fields: ['name', 'code'],
              },
              employee: {
                fields: ['first_name', 'last_name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `${pathname}`,
            headers,
            params,
          })

          let updatedData = {}

          if (data) {
            updatedData = await Promise.all(
              data.map(async (cl) => {
                const { data: dashboard } = await getAPI({
                  url: `${pathname}/${cl.id}/dashboard`,
                  headers,
                })

                return {
                  ...cl,
                  due_date: dashboard?.due_date,
                  progress: dashboard?.progress,
                  created_by: cl?.employee && cl.employee.first_name + ' ' + cl.employee.last_name,
                }
              })
            )
          }

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    byId: (chainLinkId) => {
      const { REQUEST, SUCCESS, FAILURE } = GET.CL_ID

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            populate: {
              chain_link_temp: true,
              employee: {
                fields: ['first_name', 'last_name'],
              },
              company: {
                populate: {
                  working_times: true,
                },
              },
              tags: {
                fields: ['name'],
              },
              sps: {
                populate: {
                  sws: {
                    populate: {
                      department: {
                        fields: ['name'],
                      },
                      positions: {
                        fields: ['name'],
                      },
                      employee: {
                        fields: ['first_name', 'last_name'],
                      },
                      tasks: {
                        populate: {
                          deliveries: deliveriesParams,
                        },
                      },
                      deliveries: deliveriesParams,
                      reviewers: {
                        populate: {
                          position: {
                            fields: ['name'],
                          },
                          employee: {
                            fields: ['first_name', 'last_name'],
                            populate: {
                              department: {
                                fields: ['name'],
                              },
                            },
                          },
                        },
                      },
                      approver: {
                        populate: {
                          position: {
                            fields: ['name'],
                          },
                          employee: {
                            fields: ['first_name', 'last_name'],
                            populate: {
                              department: {
                                fields: ['name'],
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          }

          const { data } = await getAPI({
            url: `${pathname}/${chainLinkId}`,
            headers,
            params,
          })

          // const sws = data.sws.map((sw) => ({ id: sw.id, name: sw.name, code: sw.code, status: sw.status }))
          const sws = data.sps.map((sp) => ({
            id: sp.id,
            name: sp.name,
            code: sp.code,
            status: sp.status,
            sws: sp.sws.map((sw) => ({ id: sw.id, name: sw.name, code: sw.code, status: sw.status })),
          }))

          const { data: dashboard } = await getAPI({
            url: `${pathname}/${chainLinkId}/dashboard`,
            headers,
          })

          return dispatch({ type: SUCCESS, data: { ...data, dashboard }, options: sws })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    logs: (chainLinkId, payload) => {
      const { page, pageSize } = payload?.pagination || { ...initPagination, pageSize: 1000 }
      const { REQUEST, SUCCESS, FAILURE } = GET.CL_ID_LOGS

      return async (dispatch) => {
        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: {
              ...(payload?.filters || {}),
            },
            pagination: { page, pageSize },
            sort: ['createdAt:desc'],
            fields: ['action', 'createdAt', 'old_data', 'new_data'],
            populate: {
              employee: {
                fields: ['first_name', 'last_name'],
              },
              approver: {
                fields: ['id'],
                populate: {
                  position: {
                    fields: ['name'],
                  },
                },
              },
              reviewer: {
                fields: ['id'],
                populate: {
                  position: {
                    fields: ['name'],
                  },
                },
              },
              sw: {
                fields: ['code', 'name'],
              },
              task: {
                fields: ['code', 'name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `${pathname}/${chainLinkId}/logs`,
            headers,
            params,
          })

          const updatedData = data.map((log) => {
            const created_by = log?.employee ? log.employee?.first_name + ' ' + log.employee?.last_name : null
            const createdAt = formatDate(log?.createdAt) || null

            return {
              ...log,
              created_by,
              createdAt,
            }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    files: (chainLinkId, payload) => {
      const { page, pageSize } = payload?.pagination || initPagination

      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = GET.CL_ID_FILES

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: { ...payload?.filters, status: 'active' },
            sort: ['createdAt:desc'],
            pagination: { page, pageSize },
            fields: ['name'],
            populate: {
              file: {
                fields: ['mime', 'url', 'createdAt'],
              },
              employee: {
                fields: ['id', 'first_name', 'last_name'],
              },
              sp: {
                fields: ['name'],
              },
              sw: {
                fields: ['name'],
              },
              task: {
                fields: ['name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `${pathname}/${chainLinkId}/files`,
            headers,
            params,
          })

          const updatedData = data.map((file) => {
            return {
              ...file,
              created_by: file?.employee.first_name + ' ' + file?.employee.last_name,
              createdAt: dayjs(file?.createdAt).format('DD MMM YYYY') || null,
            }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    deliveries: (chainLinkId, payload) => {
      const { page, pageSize } = payload?.pagination || initPagination

      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = GET.CL_ID_DELIVERIES

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const params = {
            filters: { ...payload?.filters, status: 'active' },
            sort: ['createdAt:desc'],
            pagination: { page, pageSize },
            fields: ['name'],
            populate: {
              file: {
                fields: ['mime', 'url', 'createdAt'],
              },
              employee: {
                fields: ['id', 'first_name', 'last_name'],
              },
              sp: {
                fields: ['name'],
              },
              sw: {
                fields: ['name'],
              },
              task: {
                fields: ['name'],
              },
              delivery: {
                fields: ['name'],
              },
            },
          }

          const { data, meta } = await getAPI({
            url: `${pathname}/${chainLinkId}/delivery-files`,
            headers,
            params,
          })

          const updatedData = data.map((file) => {
            return {
              ...file,
              created_by: file?.employee.first_name + ' ' + file?.employee.last_name,
              createdAt: dayjs(file?.createdAt).format('DD MMM YYYY') || null,
            }
          })

          return dispatch({ type: SUCCESS, data: updatedData, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const postCL = () => ({
    create: (payload) => {
      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = POST.CL

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const company = { id: company_info?.id }

          const { data, meta } = await postAPI({
            url: '/api/op-chain-links',
            headers,
            data: {
              ...payload,
              id: payload?.chain_link_temp?.id,
              company,
            },
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    file: (chainLinkId, payload) => {
      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = POST.CL_FILE

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data, meta } = await postAPI({
            url: `${pathname}/${chainLinkId}/files`,
            headers,
            data: payload,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
    cancel: (chainLinkId) => {
      return async (dispatch) => {
        const { REQUEST, SUCCESS, FAILURE } = POST.CL_CANCEL

        dispatch(showLoading())
        dispatch({ type: REQUEST })

        try {
          const { data, meta } = await postAPI({
            url: `${pathname}/${chainLinkId}/cancel`,
            headers,
          })

          return dispatch({ type: SUCCESS, data, meta })
        } catch (error) {
          return dispatch({ type: FAILURE, error })
        } finally {
          dispatch(hideLoading())
        }
      }
    },
  })

  const putCL = (clId, payload) => {
    return async (dispatch) => {
      const { REQUEST, SUCCESS, FAILURE } = PUT.CL

      dispatch(showLoading())
      dispatch({ type: REQUEST })

      try {
        const { data, meta } = await putAPI({
          url: `${pathname}/${clId}`,
          headers,
          data: payload,
        })

        return dispatch({ type: SUCCESS, data, meta })
      } catch (error) {
        return dispatch({ type: FAILURE, error })
      } finally {
        dispatch(hideLoading())
      }
    }
  }

  const clearCL = () => {
    return async (dispatch) => {
      dispatch({ type: CLEAR.CL })
    }
  }

  return {
    getCL,
    postCL,
    putCL,
    clearCL,
  }
}
