import Timeline from '@mui/lab/Timeline'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getRequestApproveItem } from '../../../../redux/actions/summaryTask'
import { selectSummaryTask } from '../../../../redux/selectors'
import { dlog } from '../../../../utils/helper'
import Loading from '../../../common/Loading'
import IconCheck from '../../../widget/icon/IconCheck'
import IconCheckList from '../../../widget/icon/IconCheckList'
import IconPdf from '../../../widget/icon/IconPdf'
import IconSearch from '../../../widget/icon/IconSearch'
import SubTaskShowItem from '../subtask/SubTaskShowItem'

export default function Timelines({ requestId, itemList, onUpdate, docId, onClickEdit, isRoutine, taskId }) {
  const dispatch = useDispatch()
  const [subTaskList, setSubTaskList] = useState([])
  const [tmpSubTaskList, setTmpSubTaskList] = useState([])
  const [lastRequestId, setLastRequestId] = useState()
  const [selectSubTask, setSelectSubTask] = useState()
  const [isLoadUi, setIsLoadUi] = useState(true)
  const subTaskRef = useRef(null)
  const { approveInfo } = useSelector(selectSummaryTask)

  useEffect(() => {
    let tmpList = [...itemList]
    let lastReq = ''
    for (let i = 0; i < tmpList.length; i++) {
      let data = tmpList[i]
      if (
        data.subtask_type == 'Request Approve' &&
        data.subtask_detail == 'Document request approve' &&
        data.request_approve_id
      ) {
        lastReq = data.request_approve_id
      }
    }
    if (lastReq) {
      setLastRequestId(lastReq)
      for (let i = 0; i < tmpList.length; i++) {
        let data = tmpList[i]
        if (
          data.subtask_type == 'Request Approve' &&
          data.subtask_detail == 'Document request approve' &&
          data.request_approve_id
        ) {
          // dlog(data, '/////SUBTASK/////')
          dispatch(getRequestApproveItem(data.request_approve_id))
        }
      }
    } else {
      setSubTaskList(itemList)
    }
  }, [itemList])

  useEffect(() => {
    let tmpList = [...itemList]
    for (let i = 0; i < tmpList.length; i++) {
      if (
        tmpList[i].subtask_type == 'Request Approve' &&
        tmpList[i].subtask_detail == 'Document request approve' &&
        parseInt(approveInfo.id) == tmpList[i].request_approve_id
      ) {
        // dlog(approveInfo, '/////APPROVE/////')
        if (approveInfo?.attributes) {
          let tmpStatus = approveInfo.attributes?.request_approve_actions?.data
          if (tmpStatus.length > 0) {
            tmpList[i].status = tmpStatus[0].attributes.action
            tmpList[i].comment = tmpStatus[0].attributes.comment
            tmpList[i].approve_time = tmpStatus[0].attributes.createdAt
            let tmpRequestTo = approveInfo.attributes?.request_to.data.attributes
            tmpList[i].request_to_name = tmpRequestTo.first_name + ' ' + tmpRequestTo.last_name
          }
          let tmpNote = approveInfo.attributes?.request_approve_notes
          if (tmpNote) {
            tmpList[i].request_approve_notes = tmpNote
          }

          if (
            tmpList[i].subtask_detail == 'Document request approve' &&
            (tmpList[i].status == 'Open' || tmpList[i].status == 'Requesting Approval')
          ) {
            tmpList[i].status = 'Document Requesting Approval'
          }
        }
      }
    }
    setTmpSubTaskList(tmpList)
    if (lastRequestId == parseInt(approveInfo.id)) {
      setSubTaskList(tmpList)
    }
  }, [approveInfo])

  useEffect(() => {
    if (requestId) {
      if (subTaskList?.length > 0 && subTaskRef?.current) {
        for (let i = 0; i < subTaskList.length; i++) {
          if (subTaskList[i].request_approve_id === requestId) {
            setSelectSubTask(i)
          }
        }
        subTaskRef.current.scrollIntoView()
        setIsLoadUi(false)
      }
    } else {
      setIsLoadUi(false)
    }
  }, [subTaskList])

  const Checkline = (index, type) => {
    if (subTaskList.length > 1) {
      if (type == 'start') {
        if (index == 0) {
          return 'hide-line-first-last'
        } else if (index == subTaskList.length - 1) {
          return ''
        } else {
          return ''
        }
      } else {
        if (index == 0) {
          return ''
        } else if (index == subTaskList.length - 1) {
          return 'hide-line-first-last'
        } else {
          return ''
        }
      }
    }
    return 'hide-line-first-last'
  }
  const handleOnComplete = (index, action, note) => {
    let tmp = [...subTaskList]

    tmp[index].status = action
    tmp[index].complete_task_note = note

    setSubTaskList(tmp)
  }
  return (
    <Styled>
      <Timeline>
        {isLoadUi && (
          <div className="wrp-loading">
            <Loading />
          </div>
        )}
        {subTaskList?.length > 0 && (
          <>
            {subTaskList?.map((item, index) => (
              <TimelineItem key={'timeline-item-' + index}>
                <TimelineSeparator>
                  <TimelineConnector className={Checkline(index, 'start')} />

                  <div className="wrp-icon">
                    {item.status == 'Complete' ||
                    item.status == 'Approved' ||
                    item.status == 'Rejected' ||
                    item.status == 'Approve' ||
                    item.status == 'Reject' ? (
                      <IconCheck />
                    ) : item.subtask_type == 'Request Approve' && item.subtask_detail == 'Document request approve' ? (
                      <IconPdf width="25px" color="var(--Gray-600)" />
                    ) : item.subtask_type == 'Request Approve' && item.subtask_detail != 'Document request approve' ? (
                      <IconSearch width="25px" color="var(--Gray-600)" />
                    ) : (
                      <IconCheckList />
                    )}
                  </div>

                  <TimelineConnector className={Checkline(index, 'end')} />
                </TimelineSeparator>

                <TimelineContent sx={{ py: '12px', px: 2 }}>
                  <div
                    className="timeline-content"
                    ref={
                      item.subtask_type == 'Request Approve' && item.request_approve_id === requestId
                        ? subTaskRef
                        : null
                    }
                    onClick={() => (index == selectSubTask ? setSelectSubTask() : setSelectSubTask(index))}
                  >
                    <SubTaskShowItem
                      data={item}
                      onUpdate={onUpdate}
                      isSelected={selectSubTask === index}
                      onComplete={(action, note) => handleOnComplete(index, action, note)}
                      onClickEdit={() => onClickEdit(index)}
                      isRoutine={isRoutine}
                      taskId={taskId}
                    />
                  </div>
                </TimelineContent>
              </TimelineItem>
            ))}
          </>
        )}
      </Timeline>
    </Styled>
  )
}
const Styled = styled.div`
  padding-bottom: 50px;

  position: relative;
  .MuiTimelineContent-root {
    padding-right: 0 !important;
  }
  
  .wrp-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background-color: #ffffff;
    z-index: 1;
  }
  ul {
    /* overflow-y: auto; */

    li {
      ::before {
        background-color: yellow;
        display: none;
      }
    }
  }

  .hide-line-first-last {
    background-color: transparent;
  }
  .wrp-icon {
    width: 50px;
    height: 50px;
    /* background-color: var(--Gray-200); */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
  }
`
