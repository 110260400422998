import { combineReducers } from 'redux'
import {
  GET_SOW_BY_DEPARTMENT,
  GET_POSITIONS,
  GET_POSITION_BY_ID,
  CREATE_POSITION,
  UPDATE_POSITION,
  GET_POSITIONS_DROPDOWN,
  DELETE_POSITION,
} from '../actionTypes'

const initialState = {
  positions: [],
  positionsDropdown: [],
  positionInfo: {},
  sows: [],
  total: 1,
  page: 1,
  limit: 10,
  pageCount: 1,
  isLoading: false,
  state: '',
}

const jobPositionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_POSITIONS.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_POSITIONS.REQUEST',
      }
    case GET_POSITIONS.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        positions: data,
        total: meta.pagination.total,
        limit: meta.pagination.pageSize,
        page: meta.pagination.page,
        pageCount: meta.pagination.pageCount,
        isLoading: false,
        state: 'GET_POSITIONS.SUCCESS',
      }
    }
    case GET_POSITIONS.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_POSITIONS.FAILURE',
      }
    case GET_POSITIONS_DROPDOWN.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_POSITIONS_DROPDOWN.REQUEST',
      }
    case GET_POSITIONS_DROPDOWN.SUCCESS: {
      const { data } = action
      return {
        ...state,
        positionsDropdown: data,
        isLoading: false,
        state: 'GET_POSITIONS_DROPDOWN.SUCCESS',
      }
    }
    case GET_POSITIONS_DROPDOWN.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_POSITIONS_DROPDOWN.FAILURE',
      }
    case GET_POSITION_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_POSITION_BY_ID.REQUEST',
      }
    case GET_POSITION_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        positionInfo: data,
        isLoading: false,
        state: 'GET_POSITION_BY_ID.SUCCESS',
      }
    }
    case GET_POSITION_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_POSITION_BY_ID.FAILURE',
      }
    case GET_SOW_BY_DEPARTMENT.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_SOW_BY_DEPARTMENT.REQUEST',
      }
    case GET_SOW_BY_DEPARTMENT.SUCCESS: {
      const { data } = action
      return {
        ...state,
        sows: data,
        isLoading: false,
        state: 'GET_SOW_BY_DEPARTMENT.SUCCESS',
      }
    }
    case GET_SOW_BY_DEPARTMENT.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_SOW_BY_DEPARTMENT.FAILURE',
      }
    case CREATE_POSITION.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_POSITION.REQUEST',
      }
    case CREATE_POSITION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_POSITION.SUCCESS',
      }
    }
    case CREATE_POSITION.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_POSITION.FAILURE',
      }
    case UPDATE_POSITION.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'UPDATE_POSITION.REQUEST',
      }
    case UPDATE_POSITION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_POSITION.SUCCESS',
      }
    }
    case UPDATE_POSITION.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'UPDATE_POSITION.FAILURE',
      }
    case DELETE_POSITION.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'DELETE_POSITION.REQUEST',
      }
    case DELETE_POSITION.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_POSITION.SUCCESS',
      }
    }
    case DELETE_POSITION.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'DELETE_POSITION.FAILURE',
      }
    default:
      return state
  }
}

export default combineReducers({
  jobPositionReducer,
})
