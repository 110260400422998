import { Stack, Chip as MuiChip, Button as MuiButton } from '@mui/material'
import { styled } from '@mui/system'

import Reviewer from './reviewer'
import SoW from './sow'
import Approval from './approval'
import Task from './task'
import TaskDrawerTitle from './task-drawer-title'

const DrawerDetails = ({ children }) => {
  return <>{children}</>
}

DrawerDetails.Reviewer = Reviewer
DrawerDetails.SoW = SoW
DrawerDetails.Approval = Approval
DrawerDetails.Task = Task

export { TaskDrawerTitle }

export default DrawerDetails

export const InfoContainer = styled(Stack)`
  border-radius: 10px;
  padding: 1rem 0 0 0;
  width: 100%;
  height: ${(props) => props.height || '100%'};

  &::-webkit-scrollbar {
    display: none;
  }

  & > section {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    padding: 0 1.25rem;

    & > label {
      width: 160px;
    }

    & p {
      margin: 0;
      font-weight: 400 !important;
    }

    & a {
      color: var(--Blue-500, #2e90fa) !important;
      font-weight: 400;

      &:hover {
        text-decoration: underline;
      }
    }

    & > label,
    p,
    a {
      color: var(--Gray-700);

      /* Text sm/Medium */
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem;
    }

    & > div {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
    }
  }
`

export const Chip = styled(MuiChip)`
  font-family: 'Inter', 'Noto Sans Thai';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.125rem; /* 150% */
  color: var(--Gray-700);

  display: flex;
  padding: 0.1875rem 0.5rem;
  justify-content: center;
  align-items: center;

  border-radius: 1rem;
  border: 1px solid var(--Gray-300);
  background: var(--Gray-50);

  & .MuiChip-icon {
    margin-left: 0;
    margin-right: 0.5rem;
  }

  & .MuiChip-label {
    padding: 0;
  }

  &.MuiChip-colorSuccess,
  &.MuiChip-colorInfo,
  &.MuiChip-colorWarning,
  &.MuiChip-colorDefault {
    /* border-radius: 1rem !important; */
  }

  &.MuiChip-colorSuccess {
    color: var(--Success-700, #067647);
    border: 1px solid var(--Success-200, #abefc6) !important;
    background: var(--Success-50, #ecfdf3) !important;
  }

  &.MuiChip-colorWarning {
    color: var(--Warning-700, #b54708);
    border: 1px solid var(--Warning-200, #fedf89);
    background: var(--Warning-50, #fffaeb);
  }

  &.MuiChip-colorInfo {
    color: var(--Blue-700, #175cd3);
    border: 1px solid var(--Blue-200, #b2ddff) !important;
    background: var(--Blue-50, #eff8ff) !important;
  }

  &.MuiChip-colorError {
    color: var(--Error-700, #d32f2f);
    border: 1px solid var(--Error-200, #ffcdd2);
    background: var(--Error-50, #ffebee);
  }
`

export const Button = styled(MuiButton)`
  color: var(--Main-Button-Font);
  background-color: var(--Main-Button-Bg);
  border-radius: 0.5rem;
  text-transform: capitalize;
  box-shadow: none;
  height: fit-content;
  border: 1px solid var(--Main-Button-Bg);

  &:hover {
    color: var(--Main-Button-Font-Hover);
    background-color: var(--Main-Button-Bg-Hover);
    border: 1px solid var(--Main-Button-Bg);
  }

  &.Mui-disabled {
    color: var(--Gray-300);
    background-color: var(--Gray-50);
    border: 1px solid var(--Gray-200);
  }

  &.gray {
    color: var(--Gray-900);
    background-color: var(--Base-White);
    border: 1px solid var(--Gray-200);

    &.Mui-disabled {
      color: var(--Gray-300);
      background-color: var(--Gray-50);
      border: 1px solid var(--Gray-200);
    }

    &:hover {
      color: var(--Gray-900);
      background-color: var(--Gray-50);
      box-shadow: 0 2px 2px var(--Gray-50);
    }
  }
`

export const Footer = styled('footer')`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  padding: 1rem;
  border-top: ${(props) => (props.hideBorderTop ? 'none' : '1px solid var(--Gray-200)')};
  background-color: var(--Base-White);
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
`

export const Form = styled('form')`
  display: flex;
  gap: 1rem;
`

export const TabPanelContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  overflow-y: overlay;
  margin-bottom: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }
`
