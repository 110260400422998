import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  createCustomer,
  editCustomer,
  getBusinessDocById,
  getCompanyInfoById,
  getDocumentSettingByCompanyId,
} from 'redux/actions/document'

import { selectDocument } from 'redux/selectors'
import styled from 'styled-components'
import CreateDocLayout from '../custom-view/CreateDocLayout'
import DialogDoc from '../custom-view/DialogDoc'

export default function ReceiptVoucher({ previewType, currentPage, onCloseDialog, filter, itemSelected }) {
  const mockupDataForCreate = {
    doc_type: 'receipt_voucher',
    creator_id: 15, //employee id ของคนสร้าง doc
    details: 'รายละเอียดการรับ...',
    doc_date: '2023-09-28',
    payment_type: 'cash_cheque', //cash,cash_cheque
    cheque_number: 'c123', //for cash_cheque type
    cheque_date: '2023-09-28', //for cash_cheque type
    bank_name: 'Bank A', //for cash_cheque type
    deposit_bank_name: 'Bank B', //for cash_cheque type
    account_type: 'ออมทรัพย์', //for cash_cheque type
    payment_list: [
      { details: 'Detail a', amount: 1 },
      { details: 'Detail b', amount: 2 },
    ],
    account_list: [
      { account_number: '1234', account_name: 'ABC', debit: 1, credit: 0 },
      { account_number: '5678', account_name: 'DEF', debit: 0, credit: 1 },
    ],
    company_id: 1, //company id ของคนสร้าง doc
    is_external: false,
  }

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const docId = searchParams?.get('id')
  const navigateType = searchParams.get('navigate')
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { businessDocumentData, companyData, documentSettingInfo } = useSelector(selectDocument)

  // for create data
  const docTitle = 'ใบสำคัญรับ'
  const docPreviewTitle = 'ใบสำคัญรับ'

  const docType = 'receipt_voucher'
  const navigatePath = '/document/accounting?doctype=' + docType

  const HEADER_LIST = [
    [
      [
        {
          id: 'hd01',
          position: 'left',
          type: 'input_text_area',
          title: 'รายระเอียดการจ่าย',
          placeholder: 'กรอกรายละเอียด',
          class_name_create: '',
          class_name_preview: '',
        },
      ],
    ],
    [
      { id: 'hd02', type: 'input_date', title: 'วันที่', placeholder: 'select' },
      {
        id: 'hd03',
        type: 'input_dropdown',
        title: 'Payment Type',
        place_holder: 'Cash',
        data_list: [
          { text: 'Cash', value: 'cash' },
          { text: 'Cash Cheque', value: 'cash_cheque' },
        ],
        value: { text: 'cash' },
        view_type: 'hide_show',
      },
      { id: 'hd04', type: 'input_text', title: 'เลขที่', placeholder: '000000' },
      { id: 'hd05', type: 'input_date', title: 'เช็คลงวันที่', placeholder: 'select' },
      { id: 'hd06', type: 'input_text', title: 'ธนาคาร', placeholder: 'ไทยพาณิชย์' },
      { id: 'hd07', type: 'input_text', title: 'นำฝากธนาคาร', placeholder: 'ไทยพาณิชย์' },
      {
        id: 'hd08',
        type: 'input_dropdown',
        title: 'ประเภทการนำฝาก',
        place_holder: 'ออมทรัพย์',
        data_list: [
          { text: 'ออมทรัพย์', value: 'ออมทรัพย์' },
          { text: 'เงินฝากประจำ', value: 'เงินฝากประจำ' },
          { text: 'เงินฝากกระแสรายวัน', value: 'เงินฝากกระแสรายวัน' },
        ],
        value: { text: 'cash' },
        view_type: 'hide_show',
      },
    ],
  ]
  const BODY_LIST = [
    [
      {
        id: 'bd01',
        type: 'table',
        title: '',
        class_name_create: 'width_100',
        class_name_preview: 'width_100',
        value: {
          header: [
            {
              id: 'bd01-hd-1',
              type: 'text',
              title: 'รายละเอียด',
              class_name_create: 'col_width_100',
              class_name_preview: 'width_80',
            },
            {
              id: 'bd01-hd-2',
              type: 'text',
              title: 'จำนวนเงิน',
            },
            { id: 'bd01-hd-3', type: 'action', title: '' },
          ],
          body: [
            [
              {
                id: 'bd01-bd-1',
                type: 'input_text',
                placeholder: 'Details',
                class_name_create: '',
                class_name_preview: 'width_80 padding_left_right_10px',
              },
              {
                id: 'bd01-bd-2',
                type: 'input_text',
                placeholder: '0.00',
                sub_type: 'number',
                step: '0.01',
              },
              { id: 'bd01-bd-3', type: 'button_delete' },
            ],
          ],
        },
      },
    ],
    [
      {
        id: 'bd02',
        type: 'table',
        title: '',
        class_name_create: 'width_100',
        class_name_preview: 'width_100',
        value: {
          header: [
            { id: 'bd02-hd-1', type: 'text', title: 'รหัสบัญชี', class_name_preview: 'width_10' },
            {
              id: 'bd02-hd-2',
              type: 'text',
              title: 'ชื่อบัญชี',
              class_name_create: 'col_width_100',
              class_name_preview: '',
            },
            { id: 'bd02-hd-3', type: 'text', title: 'จำนวนเงิน เดบิท', class_name_preview: 'width_20' },
            { id: 'bd02-hd-4', type: 'text', title: 'จำนวนเงิน เครดิต', class_name_preview: 'width_20' },
            { id: 'bd02-hd-5', type: 'action', title: '' },
          ],
          body: [
            [
              { id: 'bd02-bd-1', type: 'input_text', placeholder: '0-00-0000', class_name_preview: 'width_10' },
              {
                id: 'bd02-bd-2',
                type: 'input_text',
                placeholder: 'Details',
                class_name_create: 'width_100',
                class_name_preview: 'text_align_left padding_left_right_10px',
              },
              {
                id: 'bd02-bd-3',
                type: 'input_text',
                placeholder: '0.00',
                sub_type: 'number',
                step: '0.01',
                class_name_preview: 'width_20',
              },
              {
                id: 'bd02-bd-4',
                type: 'input_text',
                placeholder: '0.00',
                sub_type: 'number',
                step: '0.01',
                class_name_preview: 'width_20',
              },
              { id: 'bd02-bd-5', type: 'button_delete' },
            ],
          ],
        },
      },
    ],
  ]

  const [isBindingDataDone, setIsBindingDataDone] = useState(false)
  const [previewStage, setPreviewStage] = useState(previewType || 'close')
  const [headerList, setHeaderList] = useState(HEADER_LIST)
  const [bodyList, setBodyList] = useState(BODY_LIST)
  const [documentId, setDocumentId] = useState()

  //optional
  const [customerData, setCustomerData] = useState()
  const [companyDocData, setCompanyDocData] = useState()

  useEffect(() => {
    dispatch(getCompanyInfoById())
    dispatch(getDocumentSettingByCompanyId())
  }, [])

  useEffect(() => {
    updateFieldByCustomerData()
  }, [customerData])

  useEffect(() => {
    if (docId || itemSelected?.id) {
      let tmpId = itemSelected?.id || docId
      setDocumentId(tmpId)
    }
  }, [docId, itemSelected])

  useEffect(() => {
    if (documentId && documentId != 'undefined') {
      dispatch(getBusinessDocById(documentId))
    }
  }, [documentId])

  useEffect(() => {
    if (documentId && businessDocumentData?.id) {
      bindEditData()
    }
  }, [businessDocumentData])

  useEffect(() => {
    let tmpCompanyData

    if (!documentId) {
      tmpCompanyData = {
        seller_logo: documentSettingInfo[docType]?.company_logo,
        seller_company_name: companyData?.company_name_TH,
        seller_address: companyData?.address,
        seller_tax_id: companyData?.tex_id,

        customer_company_id: customerData?.customer_id,
        customer_company_name: customerData?.name || 'Test user',
        customer_address: customerData?.address,
        customer_phone: customerData?.phone,

        header_title: 'ลูกค้า',
        customer_id_title: 'รหัสลูกค้า',
      }

      setCompanyDocData(tmpCompanyData)
    } else {
      if (businessDocumentData && customerData) {
        tmpCompanyData = {
          seller_logo: businessDocumentData?.company_logo,
          seller_company_name: businessDocumentData?.company?.company_name_TH,
          seller_address: businessDocumentData?.company?.address,
          seller_tax_id: businessDocumentData?.company?.tex_id,

          customer_company_id: customerData?.customer_id,
          customer_company_name: customerData?.name || 'Test user',
          customer_address: customerData?.address,
          customer_phone: customerData?.phone,

          sale_area: businessDocumentData?.sale_area,
          code_sale: businessDocumentData?.code_sale,

          doc_id: businessDocumentData?.doc_id,
          header_title: 'ลูกค้า',
        }

        setCompanyDocData(tmpCompanyData)
      }
    }
  }, [companyData, documentId, businessDocumentData, customerData, documentSettingInfo])

  const bindEditData = () => {
    setIsBindingDataDone(false)

    //bindHeader
    let tmpHeaderList = [...headerList]
    for (let colIndex = 0; colIndex < tmpHeaderList.length; colIndex++) {
      let colItem = tmpHeaderList[colIndex]
      //  col left
      if (colIndex == 0) {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]
          for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
            let dataItem = rowItem[dataIndex]
            if (dataItem?.type == 'search_customer') {
              let tmpText = ''
              if (dataItem?.sub_type == 'customer_id') {
                tmpText = businessDocumentData?.customer?.customer_id
              } else if (dataItem?.sub_type == 'customer_name') {
                tmpText = businessDocumentData?.customer?.name
              } else if (dataItem?.sub_type == 'tax_id') {
                tmpText = businessDocumentData?.customer?.tax_id
              }
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                customer_data: businessDocumentData?.customer,
                text: tmpText,
              }
            } else if (dataItem?.id == 'hd04') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                text: businessDocumentData?.sale_area || '',
              }
            } else if (dataItem?.id == 'hd05') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                text: businessDocumentData?.code_sale || '',
              }
            } else if (dataItem?.id == 'hd06') {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                text: businessDocumentData?.customer?.address || '',
              }
            }
          }
        }
      }
      //  col right
      else if (colIndex == 1) {
        for (let dataIndex = 0; dataIndex < colItem.length; dataIndex++) {
          let dataItem = colItem[dataIndex]

          if (dataItem?.id == 'hd07-0') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.doc_date,
            }
          } else if (dataItem?.id == 'hd07') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.business_type,
            }
          } else if (dataItem?.id == 'hd08') {
            tmpHeaderList[colIndex][dataIndex].value = {
              text: businessDocumentData?.authorized_purchaser,
            }
          }
        }
      }
    }

    //bindBody
    let tmpBodyList = JSON.parse(JSON.stringify(bodyList))

    for (let rowIndex = 0; rowIndex < tmpBodyList.length; rowIndex++) {
      let rowItem = tmpBodyList[rowIndex]
      for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
        let dataItem = rowItem[dataIndex]

        if (dataItem?.id == 'bd01') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.reason }
        } else if (dataItem?.id == 'bd02') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.current_limit }
        } else if (dataItem?.id == 'bd03') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.new_limit }
        } else if (dataItem?.id == 'bd04') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.first_purchase_date }
        } else if (dataItem?.id == 'bd05') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.cheque_return_amount }
        } else if (dataItem?.id == 'bd06') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.latest_purchase_date }
        } else if (dataItem?.id == 'bd07') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.credit_day }
        } else if (dataItem?.id == 'bd08') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.avg_purchase }
        } else if (dataItem?.id == 'bd09') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.avg_payment_day }
        } else if (dataItem?.id == 'bd10') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.late_payment_day }
        } else if (dataItem?.id == 'bd11') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.current_payment }
        } else if (dataItem?.id == 'bd12') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.latest_change_date }
        } else if (dataItem?.id == 'bd13') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.latest_change_limit }
        } else if (dataItem?.id == 'bd14') {
          tmpBodyList[rowIndex][dataIndex].value = { text: businessDocumentData?.requirement }
        } else if (dataItem?.id == 'bd15') {
          tmpBodyList[rowIndex][dataIndex].value = {
            text_outstanding_balance: businessDocumentData?.outstanding_balance,
            is_agree: businessDocumentData?.is_approved,
            text_suggestions: businessDocumentData?.suggestion,
          }
        }
      }
    }

    setHeaderList(tmpHeaderList)
    setBodyList(tmpBodyList)
    setCustomerData(businessDocumentData?.customer)
    setIsBindingDataDone(true)
  }

  const updateFieldByCustomerData = () => {
    let tmpHeaderList = [...headerList]
    let tmpHeaderLeft = tmpHeaderList[0]

    for (let rowIndex = 0; rowIndex < tmpHeaderLeft.length; rowIndex++) {
      let rowItem = tmpHeaderLeft[rowIndex]
      for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
        let dataItem = rowItem[dataIndex]

        if (dataItem?.type == 'search_customer') {
          let tmpText = ''

          if (dataItem?.sub_type == 'customer_id') {
            tmpText = customerData?.customer_id
          } else if (dataItem?.sub_type == 'customer_name') {
            tmpText = customerData?.name
          } else if (dataItem?.sub_type == 'tax_id') {
            tmpText = customerData?.tax_id
          }

          tmpHeaderList[0][rowIndex][dataIndex].value = {
            customer_data: customerData,
            text: tmpText,
          }
        } else if (dataItem?.id == 'hd02-2') {
          tmpHeaderList[0][rowIndex][dataIndex].value = { text: customerData?.office }
        } else if (dataItem?.id == 'hd03') {
          tmpHeaderList[0][rowIndex][dataIndex].value = { text: customerData?.phone }
        } else if (dataItem?.id == 'hd06') {
          tmpHeaderList[0][rowIndex][dataIndex].value = { text: customerData?.address }
        }
      }
    }

    setHeaderList(tmpHeaderList)
  }

  const getHeaderValues = () => {
    let tmpValues = {}
    let tmpHeaderList = [...headerList]

    for (let colIndex = 0; colIndex < headerList.length; colIndex++) {
      let colItem = headerList[colIndex]

      if (colIndex == 0) {
        for (let rowIndex = 0; rowIndex < colItem.length; rowIndex++) {
          let rowItem = colItem[rowIndex]

          for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
            let dataItem = rowItem[dataIndex]

            if (dataItem?.id == 'hd01' && dataItem?.value?.text) {
              tmpValues.details = dataItem?.value?.text
            }
            if (!dataItem?.value?.text) {
              tmpHeaderList[colIndex][rowIndex][dataIndex].value = {
                ...tmpHeaderList[colIndex][dataIndex]?.value,
                is_error: true,
              }
            }
          }
        }
      } else {
        for (let dataIndex = 0; dataIndex < colItem.length; dataIndex++) {
          let dataItem = colItem[dataIndex]

          if (dataItem?.id == 'hd02' && dataItem?.value?.text) {
            tmpValues.doc_date = dataItem.value.text
          } else if (dataItem?.id == 'hd03' && dataItem?.value?.text) {
            tmpValues.payment_type = dataItem.value.text
          } else if (dataItem?.id == 'hd04' && dataItem?.value?.text) {
            tmpValues.cheque_number = dataItem.value.text
          } else if (dataItem?.id == 'hd05' && dataItem?.value?.text) {
            tmpValues.cheque_date = dataItem.value.text
          } else if (dataItem?.id == 'hd06' && dataItem?.value?.text) {
            tmpValues.bank_name = dataItem.value.text
          } else if (dataItem?.id == 'hd07' && dataItem?.value?.text) {
            tmpValues.deposit_bank_name = dataItem.value.text
          } else if (dataItem?.id == 'hd08' && dataItem?.value?.text) {
            tmpValues.authorized_purchaser = dataItem.value.text
          } else if (dataItem?.id == 'hd08' && dataItem?.value?.text) {
            tmpValues.account_type = dataItem.value.text
          }

          if (dataItem[1]?.value?.text == 'cash') {
            delete tmpValues.cheque_number
            delete tmpValues.cheque_date
            delete tmpValues.bank_name
            delete tmpValues.deposit_bank_name
            delete tmpValues.authorized_purchaser
            delete tmpValues.account_type
          }

          if (!dataItem?.value?.text) {
            tmpHeaderList[colIndex][dataIndex].value = {
              ...tmpHeaderList[colIndex][dataIndex]?.value,
              is_error: true,
            }
          }
        }
      }
    }
    // console.log('tmpHeaderList: ' + JSON.stringify(tmpHeaderList, null, 2))
    setHeaderList(tmpHeaderList)
    return tmpValues
  }

  const getBodyValues = () => {
    let tmpBodyList = JSON.parse(JSON.stringify(bodyList))
    let tmpValues = {}

    for (let rowIndex = 0; rowIndex < tmpBodyList.length; rowIndex++) {
      let rowItem = tmpBodyList[rowIndex]
      for (let colIndex = 0; colIndex < rowItem.length; colIndex++) {
        let colItem = rowItem[colIndex]

        if (colItem?.id == 'bd01') {
          tmpValues.reason = colItem?.value?.text || ''
        } else if (colItem?.id == 'bd02') {
          tmpValues.current_limit = parseFloat(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd03') {
          tmpValues.new_limit = parseFloat(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd04') {
          tmpValues.first_purchase_date = colItem?.value?.text || ''
        } else if (colItem?.id == 'bd05') {
          tmpValues.cheque_return_amount = parseInt(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd06') {
          tmpValues.latest_purchase_date = colItem?.value?.text || ''
        } else if (colItem?.id == 'bd07') {
          tmpValues.credit_day = parseInt(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd08') {
          tmpValues.avg_purchase = parseFloat(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd09') {
          tmpValues.avg_payment_day = parseInt(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd10') {
          tmpValues.late_payment_day = parseInt(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd11') {
          tmpValues.current_payment = parseFloat(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd12') {
          tmpValues.latest_change_date = colItem?.value?.text || ''
        } else if (colItem?.id == 'bd13') {
          tmpValues.latest_change_limit = parseFloat(colItem?.value?.text || 0)
        } else if (colItem?.id == 'bd14') {
          tmpValues.requirement = colItem?.value?.text || ''
        } else if (colItem?.id == 'bd15') {
          tmpValues.is_approved = colItem?.value?.is_agree || false
          tmpValues.outstanding_balance = parseFloat(colItem?.value?.text_outstanding_balance || 0)
          tmpValues.suggestion = colItem?.value?.text_suggestions || ''
        }

        if (!colItem?.value?.text && (colItem?.id == 'bd01' || colItem?.id == 'bd02' || colItem?.id == 'bd03')) {
          tmpBodyList[rowIndex][colIndex].value = {
            ...tmpBodyList[rowIndex][colIndex]?.value,
            is_error: true,
          }
        }
      }
    }

    setBodyList(tmpBodyList)
    return tmpValues
  }

  const getIsSameCustomer = () => {
    let isSameCustomer = false
    let headerLeft = [...headerList[0]]
    let sumSameCustomer = 0

    for (let rowIndex = 0; rowIndex < headerLeft.length; rowIndex++) {
      let rowItem = headerLeft[rowIndex]
      for (let dataIndex = 0; dataIndex < rowItem.length; dataIndex++) {
        let dataItem = rowItem[dataIndex]

        if (dataItem?.id == 'hd01' && dataItem?.value?.text && dataItem?.value?.text == customerData?.customer_id) {
          sumSameCustomer++
        } else if (dataItem?.id == 'hd02' && dataItem?.value?.text && dataItem?.value?.text == customerData?.name) {
          sumSameCustomer++
        } else if (dataItem?.id == 'hd02-1' && dataItem?.value?.text && dataItem?.value?.text == customerData?.tax_id) {
          sumSameCustomer++
        }
      }
    }

    if (sumSameCustomer > 0) {
      isSameCustomer = true
    }

    return isSameCustomer
  }

  const updateCustomer = async (isSameCustomer, updatedCustomerData) => {
    let customerId = customerData?.id

    if (customerId && isSameCustomer) {
      await dispatch(editCustomer({ data: updatedCustomerData }, customerId))
      return -1
    } else {
      const res = await dispatch(createCustomer({ data: { ...updatedCustomerData, company_id: company_info?.id } }))
      return res?.data?.id || -1
    }
  }

  const handelCloseDialog = () => {
    setPreviewStage('close')
    if (onCloseDialog) {
      onCloseDialog()
    }
  }
  const onBtnPreviewClicked = () => {
    setPreviewStage('preview_create')
  }

  const handleCompanyDocByHeader = (tmpHeader) => {
    const tmpCompanyDocData = { ...companyDocData }

    if (!documentId) {
      tmpCompanyDocData.seller_logo = documentSettingInfo[docType]?.company_logo || ''
      tmpCompanyDocData.seller_company_name = companyData?.company_name_TH || ''
      tmpCompanyDocData.seller_address = companyData?.address || ''
      tmpCompanyDocData.seller_tax_id = companyData?.tex_id || ''
    } else {
      tmpCompanyDocData.seller_logo = businessDocumentData?.company_logo || ''
      tmpCompanyDocData.seller_company_name = businessDocumentData?.company?.company_name_TH || ''
      tmpCompanyDocData.seller_address = businessDocumentData?.company?.address || ''
      tmpCompanyDocData.seller_tax_id = businessDocumentData?.company?.tex_id || ''
    }

    tmpCompanyDocData.customer_tax_id = tmpHeader[0][0][2]?.value?.text || ''
    tmpCompanyDocData.customer_phone = tmpHeader[0][0][4]?.value?.text || ''
    tmpCompanyDocData.sale_area = tmpHeader[0][0][5]?.value?.text || ''
    tmpCompanyDocData.code_sale = tmpHeader[0][0][6]?.value?.text || ''
    tmpCompanyDocData.customer_address = tmpHeader[0][0][7]?.value?.text || ''

    setCompanyDocData(tmpCompanyDocData)
  }

  const onBtnSaveClicked = async () => {
    let tmpSendData = {
      doc_type: docType,
      creator_id: employee_info?.id,
      company_id: company_info?.id,
    }

    let tmpHeaderValues = getHeaderValues()
    let tmpBodyValues = getBodyValues()

    console.log('tmpHeaderValues: ' + JSON.stringify(tmpHeaderValues, null, 2))

    if (
      !tmpHeaderValues?.update_customer?.customer_id ||
      !tmpHeaderValues?.update_customer?.name ||
      !tmpHeaderValues?.update_customer?.tax_id ||
      !tmpHeaderValues?.update_customer?.office ||
      !tmpHeaderValues?.sale_area ||
      !tmpHeaderValues?.code_sale ||
      !tmpHeaderValues?.business_type ||
      !tmpHeaderValues?.authorized_purchaser ||
      !tmpHeaderValues?.doc_date
    ) {
      return
    }

    if (!tmpBodyValues?.reason || !tmpBodyValues?.current_limit || !tmpBodyValues?.new_limit) {
      return
    }

    let isSameCustomer = getIsSameCustomer()
    const customerRes = await updateCustomer(isSameCustomer, tmpHeaderValues?.update_customer)

    delete tmpHeaderValues.update_customer

    if (customerRes != -1) {
      tmpSendData.customer_id = customerRes
    }

    let uploadData = {
      ...tmpSendData,
      ...tmpHeaderValues,
      ...tmpBodyValues,
      is_external: navigateType == 'external' ? true : false,
    }

    tmpSendData = {
      type: 'credit_adjustment_document',
      data: uploadData,
    }

    // if (!documentId) {
    //   await dispatch(createBusinessDocument(tmpSendData))
    //   navigate(navigatePath)
    // } else {
    //   dispatch(editBusinessDocument(tmpSendData, documentId))
    // }
  }

  return (
    <Styled>
      {(previewStage == 'close' || previewStage == 'preview_create') && (
        <CreateDocLayout
          isEdit={docId ? true : false}
          headerList={headerList}
          bodyList={bodyList}
          docType={docType}
          title={docTitle}
          onChange={(e) => {
            if (e?.layout_type == 'header_left' || e?.layout_type == 'header_right') {
              setHeaderList(e?.data)
              handleCompanyDocByHeader(e?.data)

              if (e?.customer_data) {
                setCustomerData(e.customer_data)
              }
            } else if (e?.layout_type == 'body') {
              setBodyList(e?.data)
            }
          }}
          onBtnSaveClicked={onBtnSaveClicked}
          onBtnPreviewClicked={onBtnPreviewClicked}
        />
      )}
      {(previewStage == 'request_approve' ||
        previewStage == 'preview' ||
        previewStage == 'print' ||
        previewStage == 'download_pdf' ||
        previewStage == 'share' ||
        previewStage == 'preview_create') && (
        <DialogDoc
          docType={docType}
          docTitle={docPreviewTitle || docTitle}
          headerList={headerList}
          bodyList={bodyList}
          companyDocData={companyDocData}
          previewType={previewStage}
          onCloseClicked={handelCloseDialog}
          docId={documentId}
          isBindingDataDone={isBindingDataDone}
          copyNumber={
            businessDocumentData?.number_of_copy || documentSettingInfo?.credit_adjustment_document?.number_of_copy
          }
          currentPage={currentPage}
          filter={filter}
          itemSelected={itemSelected}
          rubberStamp={documentSettingInfo?.credit_adjustment_document?.rubber_stamp}
          signatureList={documentSettingInfo?.credit_adjustment_document?.signature_list}
        />
      )}
    </Styled>
  )
}

const Styled = styled.div`
  .credit_adjustment_document {
    .body {
      .body_row_item {
        .margin_right_10px {
          margin-right: 10px;
        }
      }
    }
  }

  .preview_document_wrap.credit_adjustment_document {
    .body {
      .body_row_item {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        .flex_row {
          display: flex;
          flex-direction: row;

          .wrp_text_preview {
            display: flex;
            flex-direction: row;
            .input_text_preview {
              display: flex;
            }
          }
        }
        .text_bold {
          .title,
          .input_text_preview {
            font-weight: bolder;
          }
        }
        .padding_right_10px {
          .title {
            padding-right: 10px;
          }
        }
        .width_300_px {
          .inner_row_left,
          .title {
            width: 300px;
          }
        }
        .preview_unit {
          .input_text_preview {
            display: flex;
          }
        }
        .text_preview_unit {
          margin-left: 10px;
        }
        .wrp_finance {
          padding-top: 0;
          border-top: none;
          .row {
            margin-top: 0;
          }
        }
      }

      .inner_row_left .title,
      .wrp_text_preview .title {
        font-weight: bold;
      }
      .body_row_item:nth-child(8),
      .body_row_item:nth-child(3) {
        margin-top: 10px;
      }
      .body_row_item:nth-child(1) .wrp_text_preview .title,
      .body_row_item:nth-child(8) .wrp_text_preview .title {
        font-weight: normal;
      }
    }
  }
`
