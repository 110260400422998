import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getCompanyInfo } from '../../utils/common'


import HighchartsSankey from 'highcharts/modules/sankey'
import HighchartsOrganization from 'highcharts/modules/organization'
import Highcharts from 'highcharts'
import OrgChart from '../../components/feature/Organization/OrgChart'
import OrgChartEmployee from '../../components/feature/Organization/OrgChartEmployee'

import { useTranslation } from 'react-i18next'
import { GetOrgChart,GetEmployeeChart, getChartDepartMentAll } from '../../redux/actions/organizationChart'
import { selectOrganizationChart } from '../../redux/selectors'

import Dropdown from '../../components/form/Dropdown'

if (typeof Highcharts === 'object') {
  HighchartsSankey(Highcharts)
  HighchartsOrganization(Highcharts)
}

const Div = styled.div`
  .d-flex {
    display: flex;
  }

  .d-flex-chart {
    display: flex;
    justify-content: center;
  }
  p {
    color: #7f56d9;
  }
  .justify-content-between {
    justify-content: space-between;
  }

  .chart-container {
    width: 100%;
    /* height: 100vh; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    overflow: auto;
    position: relative;
  }
  .wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      border-bottom: 2px solid #EAECF0;
      color: #667085;
      font-size: 14px;
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      border-bottom: 2px solid var(--Primary-600) !important;
      color: #6941C6;
      background-color: #F9F5FF;
    }
  }
`

const options = {
  chart: {
    height: 400,
    inverted: true,
  },
  title: {
    text: '',
  },
  series: [
    {
      type: 'organization',
      name: 'Highsoft',
      keys: ['from', 'to'],
      // width: 400,
      levels: [
        {
          level: 0,
          color: '#f0eded',
          dataLabels: {
            color: 'black',
          },
        },
        {
          level: 1,
          color: '#f0eded',
          dataLabels: {
            color: 'black',
          },
        },
        {
          level: 2,
          color: '#f0eded',
          dataLabels: {
            color: 'black',
          },
        },
        {
          level: 3,
          color: '#f0eded',
          dataLabels: {
            color: 'black',
          },
        },
      ],
      colorByPoint: false,
      color: '#007ad0',
      dataLabels: {
        color: 'white',
      },
      borderColor: 'white',
      nodeWidth: 65,
    },
  ],
}

const dataChart = {
  text: '',
  value: '',
}
const initChartData = {
  "id": 51,
  "lv": 1,
  "head": {
      "position": "หัวหน้าแผนก_1",
      "department": "แผนก_1",
      "name": "wait_name",
      "img": "https://picsum.photos/200"
  },
  "isExpanded": true,
  "underPosition": [
      {
          "id": 52,
          "lv": 2,
          "head": {
              "position": "หัวหน้าแผนก_1_1",
              "department": "แผนก_1_1",
              "name": "wait_name",
              "img": "https://picsum.photos/200"
          },
          "isExpanded": true,
          "underPosition": [
              {
                  "id": 55,
                  "lv": 3,
                  "ref_departments_id": 52,
                  "type_connect": "department",
                  "position_id": 105,
                  "head": {
                      "position": "หัวหน้าแผนก_1_1_1",
                      "department": "แผนก_1_1_1",
                      "name": "wait_name",
                      "img": "https://picsum.photos/200"
                  },
                  "isExpanded": true,
                  "underPosition": [
                      {
                          "id": 106,
                          "lv": 2,
                          "ref_position_id": 105,
                          "type_connect": "position",
                          "position_id": 106,
                          "head": {
                              "position": "รองหัวหน้าแผนก_1_1_1_คนที่1",
                              "department": "แผนก_1_1_1",
                              "name": "wait_name",
                              "img": "https://picsum.photos/200"
                          },
                          "isExpanded": true,
                          "underPosition": []
                      },
                      {
                          "id": 107,
                          "lv": 2,
                          "ref_position_id": 105,
                          "type_connect": "position",
                          "position_id": 107,
                          "head": {
                              "position": "รองหัวหน้าแผนก_1_1_1_คนที่2",
                              "department": "แผนก_1_1_1",
                              "name": "wait_name",
                              "img": "https://picsum.photos/200"
                          },
                          "isExpanded": true,
                          "underPosition": []
                      }
                  ],
                  "temp_underData": [
                      {
                          "id": 106,
                          "lv": 2,
                          "ref_position_id": 105,
                          "type_connect": "position",
                          "position_id": 106,
                          "head": {
                              "position": "รองหัวหน้าแผนก_1_1_1_คนที่1",
                              "department": "แผนก_1_1_1",
                              "name": "wait_name",
                              "img": "https://picsum.photos/200"
                          },
                          "isExpanded": true,
                          "underPosition": []
                      },
                      {
                          "id": 107,
                          "lv": 2,
                          "ref_position_id": 105,
                          "type_connect": "position",
                          "position_id": 107,
                          "head": {
                              "position": "รองหัวหน้าแผนก_1_1_1_คนที่2",
                              "department": "แผนก_1_1_1",
                              "name": "wait_name",
                              "img": "https://picsum.photos/200"
                          },
                          "isExpanded": true,
                          "underPosition": []
                      }
                  ]
              },
              {
                  "id": 95,
                  "lv": 2,
                  "ref_position_id": 94,
                  "type_connect": "position",
                  "position_id": 95,
                  "head": {
                      "position": "รองหัวหน้าแผนก_1_1_คนที่1",
                      "department": "แผนก_1_1",
                      "name": "wait_name",
                      "img": "https://picsum.photos/200"
                  },
                  "isExpanded": true,
                  "underPosition": []
              },
              {
                  "id": 96,
                  "lv": 2,
                  "ref_position_id": 94,
                  "type_connect": "position",
                  "position_id": 96,
                  "head": {
                      "position": "รองหัวหน้าแผนก_1_1_คนที่2",
                      "department": "แผนก_1_1",
                      "name": "wait_name",
                      "img": "https://picsum.photos/200"
                  },
                  "isExpanded": true,
                  "underPosition": []
              },
              {
                  "id": 97,
                  "lv": 2,
                  "ref_position_id": 94,
                  "type_connect": "position",
                  "position_id": 97,
                  "head": {
                      "position": "รองหัวหน้าแผนก_1_1_คนที่3",
                      "department": "แผนก_1_1",
                      "name": "wait_name",
                      "img": "https://picsum.photos/200"
                  },
                  "isExpanded": true,
                  "underPosition": []
              },
              {
                  "id": 99,
                  "lv": 2,
                  "ref_position_id": 98,
                  "type_connect": "ref_other_position",
                  "position_id": 99,
                  "head": {
                      "position": "รองหัวแผนก_1_2_1_ข้ามขั่ว",
                      "department": "แผนก_1_2",
                      "name": "wait_name",
                      "img": "https://picsum.photos/200"
                  },
                  "isExpanded": true,
                  "underPosition": []
              }
          ]
      },
      {
          "id": 53,
          "lv": 2,
          "head": {
              "position": "หัวแผนก_1_2",
              "department": "แผนก_1_2",
              "name": "wait_name",
              "img": "https://picsum.photos/200"
          },
          "isExpanded": true,
          "underPosition": [
              {
                  "id": 99,
                  "lv": 2,
                  "ref_position_id": 98,
                  "type_connect": "position",
                  "position_id": 99,
                  "head": {
                      "position": "รองหัวแผนก_1_2_1_ข้ามขั่ว",
                      "department": "แผนก_1_2",
                      "name": "wait_name",
                      "img": "https://picsum.photos/200"
                  },
                  "isExpanded": true,
                  "underPosition": []
              },
              {
                  "id": 100,
                  "lv": 2,
                  "ref_position_id": 98,
                  "type_connect": "position",
                  "position_id": 100,
                  "head": {
                      "position": "รองหัวแผนก_1_2_2",
                      "department": "แผนก_1_2",
                      "name": "wait_name",
                      "img": "https://picsum.photos/200"
                  },
                  "isExpanded": true,
                  "underPosition": []
              },
              {
                  "id": 101,
                  "lv": 2,
                  "ref_position_id": 98,
                  "type_connect": "position",
                  "position_id": 101,
                  "head": {
                      "position": "รองหัวแผนก_1_2_3",
                      "department": "แผนก_1_2",
                      "name": "wait_name",
                      "img": "https://picsum.photos/200"
                  },
                  "isExpanded": true,
                  "underPosition": []
              }
          ]
      },
      {
          "id": 54,
          "lv": 2,
          "head": {
              "position": "หัวหน้าแผนก_1_3",
              "department": "แผนก_1_3",
              "name": "wait_name",
              "img": "https://picsum.photos/200"
          },
          "isExpanded": true,
          "underPosition": [
              {
                  "id": 103,
                  "lv": 2,
                  "ref_position_id": 102,
                  "type_connect": "position",
                  "position_id": 103,
                  "head": {
                      "position": "รองหัวหน้าแผนก_1_3_1",
                      "department": "แผนก_1_3",
                      "name": "wait_name",
                      "img": "https://picsum.photos/200"
                  },
                  "isExpanded": true,
                  "underPosition": []
              },
              {
                  "id": 104,
                  "lv": 2,
                  "ref_position_id": 102,
                  "type_connect": "position",
                  "position_id": 104,
                  "head": {
                      "position": "รองหัวหน้าแผนก_1_3_2",
                      "department": "แผนก_1_3",
                      "name": "wait_name",
                      "img": "https://picsum.photos/200"
                  },
                  "isExpanded": true,
                  "underPosition": []
              }
          ]
      },
      {
          "id": 93,
          "lv": 2,
          "head": {
              "position": "รองหัวหน้า",
              "department": "แผนก_1",
              "name": "wait_name",
              "img": "https://picsum.photos/200"
          },
          "isExpanded": true,
          "underPosition": []
      }
  ]
}

const initChartData_Forreader = {
  "id": 51,
  "lv": 1,
  "head": {
      "position": "หัวหน้าแผนก_1",
      "department": "แผนก_1",
      "name": "wait_name",
      "img": "https://picsum.photos/200"
  },
  "isExpanded": true,
  "underPosition": []
}

const initChartDataEmployee = {
  head: {
    position: 'Chief Executive Officer',
    department: 'Executive Management',
    name: 'Chavalit Chanyararakskul',
    img: 'https://picsum.photos/200',
  },
  isExpanded: false,
  underPosition: [
    {
      head: {
        position: 'Project Manager',
        department: 'Business & Development',
        name: 'Tadswan Chanyarakskul',
        img: 'https://picsum.photos/200',
      },
      isExpanded: false,
      underPosition: [
        {
          head: {
            name: 'Pichayut Prasertwit',
            position: 'Business and system analyst',
            department: 'Business & Development',
            img: 'https://picsum.photos/200',
          },
          isExpanded: false,
          underPosition: [],
        },
        {
          head: {
            name: 'Doungkamon Jaiya',
            position: 'UX UI Designer',
            department: 'Business & Development',
            img: 'https://picsum.photos/200',
          },
          isExpanded: false,
          underPosition: [],
        },
        {
          head: {
            name: 'Jirada chareewayruroj',
            position: 'New business development',
            department: 'Business & Development',
            img: 'https://picsum.photos/200',
          },
          isExpanded: false,
          underPosition: [
            {
              head: {
                name: 'Doungkamon Jaiya',
                position: 'UX UI Designer',
                department: 'Business & Development',
                img: 'https://picsum.photos/200',
              },
              isExpanded: false,
              underPosition: [],
            },
            {
              head: {
                name: 'Doungkamon Jaiya',
                position: 'UX UI Designer',
                department: 'Business & Development',
                img: 'https://picsum.photos/200',
              },
              isExpanded: false,
              underPosition: [],
            },
            {
              head: {
                name: 'Doungkamon Jaiya',
                position: 'UX UI Designer',
                department: 'Business & Development',
                img: 'https://picsum.photos/200',
              },
              isExpanded: false,
              underPosition: [],
            },
          ],
        },
      ],
    },
    {
      head: {
        position: 'Developer Manager',
        department: 'Software Developer',
        name: 'Chanyut Sinsuwannarak',
        img: 'https://picsum.photos/200',
      },
      isExpanded: false,
      underPosition: [],
    },
    {
      head: {
        position: 'Developer Manager',
        department: 'Software Developer',
        name: 'Siriphong Kaensar',
        img: 'https://picsum.photos/200',
      },
      isExpanded: false,
      underPosition: [
        {
          head: {
            name: 'Warunyu yuthaphan',
            position: 'Front-end Developer',
            department: 'Software Developer',
            img: 'https://picsum.photos/200',
          },
          isExpanded: false,
          underPosition: [],
        },
      ],
    },
  ],
}

const OrganizationChart = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const CompanyInfo = getCompanyInfo()
  const { organizationChartData, organizationChartNodes, departMentList } = useSelector(selectOrganizationChart)

  const [chartOptions, setChartOptions] = useState(options)

  const [departMentFrom, setDepartMentFrom] = useState(dataChart)

  const [departMentDropDown, setDepartMentDropDown] = useState([])


  useEffect(() => {
    dispatch(getChartDepartMentAll())
  }, [dispatch])

  useEffect(() => {
    const newList = departMentList.map((v) => ({
      text: v?.name,
      value: v?.id,
    }))
    setDepartMentDropDown(newList)
  }, [departMentList])



  const onChangeOrganizationManagementDropDown = (name, value) => {
    alert("onChangeOrganizationManagementDropDown")
    alert(name)
    alert(value)
  }

  const [all_initChartData, setall_initChartData] = useState(null)
  const [check_data, setcheck_data] = useState(false)
  /* //--- ปิดก่อน By Jame Task 278
  useEffect(() => {
    const sent_data_api = {
      data: {
        company_id : CompanyInfo?.id ? CompanyInfo?.id : null,
        department_id : -1,
      },
    }
    dispatch(GetOrgChart(sent_data_api)).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        if(data_info.status == 200){
          setall_initChartData(data_info.charData)
          setcheck_data(true)
        }else{
          setall_initChartData(null)
          setcheck_data(true)
        } 
        
      }
    })
  }, [dispatch])
  */


  const [tabactive, setTabactive] = useState('org')
  const [menuTabList, setMenuTabList] = useState([
      {
          name: 'Organization',
          value: 'org',
          active_status: true,
      },
      {
          name: 'Employee',
          value: 'employee',
          active_status: false,
      },
  ])

  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
        if (tmpList[i].value === data.value) {
            tmpList[i].active_status = true
            setTabactive(tmpList[i].value)
            festDataChart(tmpList[i].value)
        } else {
            tmpList[i].active_status = false
        }
    }
    setMenuTabList([...tmpList])
  }

  const festDataChart = (data) => {
    setcheck_data(false)
    if(data == 'org'){
      const sent_data_api = {
        data: {
          company_id : CompanyInfo?.id ? CompanyInfo?.id : null,
          department_id : -1,
        },
      }
      dispatch(GetOrgChart(sent_data_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if(data_info.status == 200){
            setall_initChartData(data_info.charData)
            setcheck_data(true)
          }else{
            setall_initChartData(null)
            setcheck_data(true)
          }         
        }
      })
    }else if(data == 'employee'){
      const sent_data_api = {
        data: {
          company_id : CompanyInfo?.id ? CompanyInfo?.id : null
        },
      }
      dispatch(GetEmployeeChart(sent_data_api)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if(data_info.status == 200){
            setall_initChartData(data_info.charData)
            setcheck_data(true)
          }else{
            setall_initChartData(null)
            setcheck_data(true)
          }         
        }
      })
    }
  }

  //if (!check_data) return <div>Loading...</div>;

  if (!check_data) return <div>Under Maintenance</div>;

 



  return (
    <Div>
      <div className="d-flex justify-content-between">
        <h3>{t('organization_chart')}</h3>
        <div>
            <div className="wrp-tab-menu">
                {menuTabList?.map((item, index) => (
                    <button
                        id={'this_name-' + item.name}
                        key={'btn-' + index}
                        className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                        onClick={() => handleClickTaskMenuTab(item)}
                    >
                        {item.name}
                    </button>
                ))}
            </div>
            {
              /*
              <Dropdown
                id="dataChart"
                optionList={departMentDropDown}
                value={departMentFrom?.text}
                onChange={(value) => onChangeOrganizationManagementDropDown('text', value)}
              />
              */
            }

        </div>
      </div>

      {tabactive == 'org' && all_initChartData && (
        <div className="chart-container">
          <OrgChart chartData={all_initChartData} />
        </div>
      )}
      {tabactive == 'employee' && all_initChartData && (
        <div className="chart-container">          
          <OrgChartEmployee chartData={all_initChartData} />
        </div>
      )}


    </Div>
  )
}

export default OrganizationChart
