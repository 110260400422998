import React, { useEffect, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { RiHome6Line } from 'react-icons/ri'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md'
import { TbPointFilled } from 'react-icons/tb'
import { BiTrash } from 'react-icons/bi'
import { LiaTimesSolid } from 'react-icons/lia'
import userIcon from '../../assets/images/user-icon.png'
import userAddIcon from '../../assets/images/users-plus.png'
import successIcon from '../../assets/images/success-icon.png'

import styled from 'styled-components'
import SearchText from '../../components/form/SearchText'
import Dropdown from '../../components/form/Dropdown'
import TextField from '../../components/form/TextField'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import Table from '../../components/common/Table'
import Button from '../../components/form/button/Button'
import SidePanel from '../../components/common/SidePanel'
import DialogConfirm from '../../components/dialog/DialogConfirm'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import Date from '../../components/form/Date'
import OutlineCheckbox from '../../components/form/OutlineCheckbox'

import { selectOrgTeam, selectMaster } from '../../redux/selectors'
import {
  getGroupTeamById,
  getTeamById,
  getMember,
  createTeamMember,
  createRepresentative,
  editTeamMember,
  deleteTeamMember,
} from '../../redux/actions/OrganizationTeam'
import { getEmployeeForAddMember } from '../../redux/actions/employee'
import { getDepartmentsAndPositionsUseAuthen } from '../../redux/actions/master'

// container style
const Wrapper = styled.div`
  .mb-6-px {
    margin-bottom: 6px;
  }
  .mb-12-px {
    margin-bottom: 12px;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }

  .mr-8-px {
    margin-right: 8px;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }

  .icon {
    cursor: pointer;
  }

  .highlight {
    color: var(--Primary-700);
    font-weight: 600;
  }

  .heading {
    color: var(--Gray-900);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  .name {
    :hover {
      cursor: pointer;
      text-decoration: underline;
      color: var(--Primary-700);
    }
  }
  .item-vertical-center {
    vertical-align: middle;
  }
`
const FlexContainer = styled.div`
  display: flex;

  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }
  &.align-items-center {
    align-items: center;
  }

  .w-20 {
    width: 20%;
  }
  .w-30 {
    width: 30%;
  }
  .w-50 {
    width: 50%;
  }
`
const FilterContainer = styled.div`
  border-radius: 12px;
  background: var(--Gray-50);
  padding: 12px 16px;

  .heading-search {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .item-vertical {
    vertical-align: middle;
  }

  .green-outline-btn {
    border-radius: 8px;
    border: 1px solid var(--success-200);
    background: var(--success-50);
    color: var(--success-700);
  }
`
const BadgeSearchContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .badge {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--Badge-Filter-Font);
    border: 1px solid var(--Badge-Filter-Stroke);
    background: var(--Badge-Filter-Bg);
    border-radius: 16px;
    padding: 6px;

    .name {
      margin-right: 0.5rem;
    }

    .close {
      color: var(--Badge-Filter-Close);
    }
  }
`
const TableWrapper = styled.div`
  .table-headers {
    padding: 1rem 0.875rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: var(--Base-White);
    border: 0.0625rem solid var(--Gray-200);

    .header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .table-header-name {
        color: var(--Gray-900);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        margin-right: 0.5rem;
      }

      .badge {
        border-radius: 16px;
        border: 1px solid var(--Doing-Badge-Stroke);

        color: var(--Doing-Badge-Font);
        text-align: center;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 500;

        display: inline-block;
        padding: 2px 8px;
      }
    }
  }

  .edit-btn-outline {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }
`
const SidePanelContainer = styled.div`
  padding: 24px;
  /* height: 100vh; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: var(--Gray-500);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  .highlight-text {
    color: var(--Primary-700);
  }

  .mb-5-px {
    margin-bottom: 5px;
  }
  .mb-6-px {
    margin-bottom: 6px;
  }
  .mb-12-px {
    margin-bottom: 12px;
  }
  .mb-14-px {
    margin-bottom: 14px;
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-24-px {
    margin-bottom: 24px;
  }
  .mr-8-px {
    margin-right: 8px;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .mr-20-px {
    margin-right: 20px;
  }

  .icon {
    cursor: pointer;
  }

  .heading {
    color: var(--Gray-900);
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
  }
  .heading-2 {
    color: var(--Gray-700);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .heading-3 {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
`
const TabMenuContainer = styled.div`
  display: flex;
  margin-bottom: 1.5rem;
  .tab-menu {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-500);
    padding: 0.75rem;
    :hover {
      cursor: pointer;
    }
    &.active {
      color: var(--Primary-700);
      border-bottom: 2px solid var(--Primary-700);
      background: var(--Primary-50);
    }
  }
`
// component style
const BadgeStatus = styled.div`
  border-radius: 16px;

  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;

  display: inline-block;
  padding: 2px 8px;

  &.green-badge {
    border: 1px solid var(--BADGE-4-Border);
    background: var(--BADGE-4-Bg);
    color: var(--BADGE-4-Font);
  }
  &.red-badge {
    border: 1px solid var(--BADGE-2-Border);
    background: var(--BADGE-2-Bg);
    color: var(--BADGE-2-Font);
  }

  .item-vertical {
    vertical-align: middle;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--Gray-200);
`
// column
const memberColumn = [
  {
    Header: 'No',
    accessor: 'no',
    disableSortBy: false,
  },
  {
    Header: 'Name-Surname',
    accessor: 'name',
    disableSortBy: false,
  },
  {
    Header: 'Employee ID',
    accessor: 'employee_no',
    disableSortBy: false,
  },
  {
    Header: 'Position',
    accessor: 'position',
    disableSortBy: false,
  },
  {
    Header: 'Appointment Order',
    accessor: 'appointment',
    disableSortBy: false,
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    disableSortBy: false,
  },
  {
    Header: 'Representative',
    accessor: 'representative',
    disableSortBy: false,
  },
  {
    Header: 'Status',
    accessor: 'status',
    disableSortBy: false,
  },
  {
    accessor: 'action',
    disableSortBy: false,
  },
]
const memberCreateColumn = [
  {
    Header: 'No',
    accessor: 'no',
    disableSortBy: false,
  },
  {
    Header: 'Employee ID',
    accessor: 'employ_id',
    disableSortBy: false,
  },
  {
    Header: 'Name-Surname',
    accessor: 'name',
    disableSortBy: false,
  },
  {
    Header: 'Department',
    accessor: 'department',
    disableSortBy: false,
  },
  {
    Header: 'Job Position',
    accessor: 'position',
    disableSortBy: false,
  },
  {
    Header: 'Email',
    accessor: 'email',
    disableSortBy: false,
  },
  {
    Header: 'Mobile',
    accessor: 'phone_no',
    disableSortBy: false,
  },
]
const representativeColumn = [
  {
    Header: 'No',
    accessor: 'no',
    disableSortBy: false,
  },
  {
    Header: 'Representative',
    accessor: 'representative',
    disableSortBy: false,
  },
  {
    Header: 'Position',
    accessor: 'position',
    disableSortBy: false,
  },
  {
    Header: 'Appointment Order',
    accessor: 'appointment',
    disableSortBy: false,
  },
  {
    Header: 'Start Date',
    accessor: 'start_date',
    disableSortBy: false,
  },
]
// initial data
const initialRepresentative = [
  {
    key: uuidv4(),
    representative: '',
    position: '',
    appointment: '',
    start_date: '',
  },
]

// initial dialog text
const initialDialogText = {
  heading: '',
  desc: '',
}

// initial list
const initialStatusDropdown = [
  {
    text: 'active',
    value: 'active',
  },
  {
    text: 'inactive',
    value: 'inactive',
  },
]

function OrganizationMember() {
  const { t } = useTranslation()
  const { id } = useParams()
  const lang = localStorage.getItem('lang')
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    memberList,
    employeeList,
    groupInfo,
    teamInfo,
    page,
    pageCount,
    total,
    pageEmployee,
    pageCountEmployee,
    totalEmployee,
    limit,
    isLoading,
    state,
  } = useSelector(selectOrgTeam)
  const { departments: departmentList } = useSelector(selectMaster)

  const [members, setMembers] = useState([])
  const [membersEdit, setMembersEdit] = useState([])
  const [membersForCreate, setMembersForCreate] = useState([])
  const [representative, setRepresentative] = useState([])
  const [isEdit, setIsEdit] = useState(false)
  const [checkedList, setCheckedList] = useState([])
  const [memberId, setMemberId] = useState('')
  // form
  const [formEdit, setFormEdit] = useState()
  const [formRepresentative, setFormRepresentative] = useState(initialRepresentative)
  // tab menu
  const [tab, setTab] = useState('employee')
  // filter
  const [search, setSearch] = useState('')
  const [searchForEmployee, setSearchForEmployee] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  const [searchFiltersForEmployee, setSearchFiltersForEmployee] = useState([])
  const [departmentSelected, setDepartmentSelected] = useState('')
  const [positionSelected, setPositionSelected] = useState('')
  // side panel
  const [isOpenAddMemberPanel, setIsOpenAddMemberPanel] = useState(false)
  // dialog
  const [isOpenConfirmAddMemberDialog, setIsOpenConfirmAddMemberDialog] = useState(false)
  const [isOpenConfirmEditDialog, setIsOpenConfirmEditDialog] = useState(false)
  const [isOpenSuccessDialog, setIsOpenSuccessDialog] = useState(false)
  const [isOpenConfirmDeleteDialog, setIsOpenConfirmDeleteDialog] = useState(false)
  // dialog text
  const [dialogText, setDialogText] = useState(initialDialogText)
  // dropdown
  const [departments, setDepartments] = useState([])
  const [positions, setPositions] = useState([])

  const getTeamInfo = useCallback(() => {
    dispatch(getTeamById(id))
  }, [dispatch, id])
  const getDepartmentAndPosition = useCallback(() => {
    dispatch(getDepartmentsAndPositionsUseAuthen())
  }, [dispatch])
  const getGroupTeamInfo = useCallback(
    (id) => {
      dispatch(getGroupTeamById(id))
    },
    [dispatch]
  )
  const fetchMemberList = useCallback(
    (filter) => {
      dispatch(getMember(filter))
    },
    [dispatch]
  )
  const fetchEmployoeeForAddMemeber = useCallback(
    (filter) => {
      dispatch(getEmployeeForAddMember(filter))
    },
    [dispatch]
  )
  const openConfirmDeleteMember = useCallback((id) => {
    setMemberId(id)
    setDialogText({
      heading: 'Confirm delete this Member?',
      desc: '',
    })
    setIsOpenConfirmDeleteDialog(true)
  }, [])
  const onSelectCheckBox = useCallback(
    (event, data) => {
      const isExisted = checkedList.find((v) => v.id === data?.id)
      if (isExisted) {
        const newInviteSelectedList = checkedList.filter((v) => v.id !== data?.id)
        setCheckedList(newInviteSelectedList)
      } else setCheckedList((prev) => [...prev, data])
    },
    [checkedList, setCheckedList]
  )
  const isSelected = useCallback(
    (id) => {
      return checkedList.some((v) => v.id == id)
    },
    [checkedList]
  )

  const addNewRepresentative = () => {
    const newRepresentative = {
      key: uuidv4(),
      representative: '',
      position: '',
      appointment: '',
      start_date: '',
    }

    setFormRepresentative((prev) => [...prev, newRepresentative])
  }

  useEffect(() => {
    getTeamInfo()
    getDepartmentAndPosition()
  }, [])

  useEffect(() => {
    const initialData = formRepresentative.map((v, i) => ({
      ...v,
      no: i + 1,
      representative: (
        <TextField
          value={v.representative}
          name="representative"
          onChange={(e) => handleChangeRepresentative(e, v.key)}
        />
      ),
      position: <TextField value={v.position} name="position" onChange={(e) => handleChangeRepresentative(e, v.key)} />,
      appointment: (
        <TextField value={v.appointment} name="appointment" onChange={(e) => handleChangeRepresentative(e, v.key)} />
      ),
      start_date: <Date value={v.start_date} onChange={(e) => handleChangeDateRepresentative(e, v.key)} />,
    }))

    setRepresentative(initialData)
  }, [formRepresentative])
  useEffect(() => {
    const request = {
      page: 1,
      pageSize: limit,
      teamId: id,
    }
    fetchMemberList(request)
  }, [id])
  useEffect(() => {
    const newDepartmentList = departmentList?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setDepartments(newDepartmentList)
  }, [departmentList])
  useEffect(() => {
    const department = departmentList?.find((v) => v.id == departmentSelected)
    const newPositions = department?.positions?.map((v) => ({
      value: v.id,
      text: v.name,
    }))
    setPositions(newPositions)
  }, [departmentSelected, departmentList])
  useEffect(() => {
    if (departmentSelected !== '' || positionSelected !== '') {
      const multipleSearch = searchFiltersForEmployee.map((v, i) => ({
        [`filters[$or][${2 * i}][first_name][$contains]`]: v.name,
        [`filters[$or][${2 * i + 1}][last_name][$contains]`]: v.name,
      }))
      const filter = {
        page: 1,
        pageSize: limit,
        department: departmentSelected,
        position: positionSelected,
        multipleSearch,
      }
      fetchEmployoeeForAddMemeber(filter)
    }
  }, [departmentSelected, positionSelected])
  useEffect(() => {
    if (isLoading === false && state === 'EDIT_TEAM_MEMBER.SUCCESS') {
      setDialogText({
        heading: 'Edit Member Success',
        desc: '',
      })
      setIsOpenSuccessDialog(true)
      const multipleSearch = searchFilters.map((v, i) => ({
        [`populate[employeename][${i}]`]: v.name,
      }))
      const request = {
        page: page,
        pageSize: limit,
        teamId: id,
        multipleSearch,
      }
      fetchMemberList(request)
    }
    if (isLoading === false && state === 'DELETE_TEAM_MEMBER.SUCCESS') {
      const multipleSearch = searchFilters.map((v, i) => ({
        [`populate[employeename][${i}]`]: v.name,
      }))
      setIsOpenConfirmDeleteDialog(false)
      const request = {
        page: page,
        pageSize: limit,
        teamId: id,
        multipleSearch,
      }
      fetchMemberList(request)
    }
    if ((isLoading === false && state === 'CREATE_TEAM_MEMBER.SUCCESS') || state === 'CREATE_REPRESENTATIVE.SUCCESS') {
      setDialogText({
        heading: 'Add Member to Team Success',
        desc: '',
      })
      setIsOpenSuccessDialog(true)

      const multipleSearch = searchFilters.map((v, i) => ({
        [`populate[employeename][${i}]`]: v.name,
      }))
      const request = {
        page: 1,
        pageSize: limit,
        teamId: id,
        multipleSearch,
      }
      fetchMemberList(request)
      setCheckedList([])
      setFormRepresentative(initialRepresentative)
    }
  }, [isLoading, state])
  useEffect(() => {
    setFormEdit(memberList)
  }, [memberList])
  useEffect(() => {
    const initCreateMemberData = employeeList.map((v, i) => ({
      ...v,
      no: (
        <div>
          <OutlineCheckbox
            className="mr-8-px item-vertical-center"
            checked={isSelected(v?.id)}
            onChange={(e) => onSelectCheckBox(e, v)}
            // disabled={customStatus !== 'Wait for response' && customStatus !== 'Not send'}
          />
          <span className="item-vertical-center">{i + 1}</span>
        </div>
      ),
      name: (
        <div className="name highlight">
          {v?.first_name} {v?.last_name}
        </div>
      ),
      department: v?.department?.name,
      position: v?.position?.name,
    }))
    setMembersForCreate(initCreateMemberData)
  }, [employeeList, isSelected, onSelectCheckBox])
  useEffect(() => {
    if (teamInfo.group) {
      getGroupTeamInfo(teamInfo.group.id)
    }
  }, [teamInfo])
  useEffect(() => {
    if (isEdit) {
      const initMemberData = memberList.map((v, i) => ({
        ...v,
        no: i + 1,
        name: <div className="name highlight">{lang === 'en' ? v.fullname_en : v.fullname_th}</div>,
        position: <TextField name="position" value={formEdit[i].position} onChange={(e) => handleChange(e, v.id)} />,
        appointment: (
          <TextField name="appointment" value={formEdit[i].appointment} onChange={(e) => handleChange(e, v.id)} />
        ),
        start_date: (
          <Date value={formEdit[i].start_date} onChange={(value) => handleDateChange(value, v.id, 'start_date')} />
        ),
        representative: (
          <TextField name="representative" value={formEdit[i].representative} onChange={(e) => handleChange(e, v.id)} />
        ),
        status: (
          <Dropdown
            optionList={initialStatusDropdown}
            value={formEdit[i].status}
            onChange={(value) => handleDropDownChange(value, v.id, 'status')}
          />
        ),
        // action: (
        //   <div>
        //     <BiTrash className="icon" size={18} />
        //   </div>
        // ),
      }))
      setMembersEdit(initMemberData)
    } else {
      const initMemberData = memberList.map((v, i) => ({
        ...v,
        no: i + 1,
        name: <div className="name highlight">{lang === 'en' ? v.fullname_en : v.fullname_th}</div>,
        status: (
          <BadgeStatus className={`${v.status === 'active' ? 'green-badge' : 'red-badge'}`}>
            <TbPointFilled className="item-vertical" />
            <span className="item-vertical"> {v.status}</span>
          </BadgeStatus>
        ),
        action: (
          <div>
            <BiTrash className="icon" size={18} onClick={() => openConfirmDeleteMember(v.id)} />
          </div>
        ),
        representative: v.representative ? v.representative : '-',
      }))
      setMembers(initMemberData)
    }
  }, [isEdit, formEdit, memberList, openConfirmDeleteMember])

  // toggle edit mode
  const toggleEditMode = () => {
    setIsEdit(!isEdit)
    setFormEdit(memberList)
  }

  // toggle menu
  const toggleTab = (tab) => {
    setTab(tab)
  }

  // navigate
  const backToGroupManagement = () => {
    navigate('/organization-management/organization?tab=group')
  }
  const backToPrevious = () => {
    navigate(-1)
  }

  // side panel
  const openAddMemberSidePanel = () => {
    setTab('employee')
    setIsOpenAddMemberPanel(true)
    const filter = {
      page: 1,
      pageSize: limit,
    }
    fetchEmployoeeForAddMemeber(filter)
  }
  const closeAddMemberSidePanel = () => {
    setIsOpenAddMemberPanel(false)
    setCheckedList([])
    setFormRepresentative(initialRepresentative)
    onClearSearchForEmployee()
  }
  const addMember = () => {
    setDialogText({
      heading: 'Confirm Add Member to Team?',
      desc: '',
    })
    setIsOpenConfirmAddMemberDialog(true)
  }

  // dialog
  const openConfirmEdit = () => {
    setDialogText({
      heading: 'Confirm Edit Member?',
      desc: '',
    })
    setIsOpenConfirmEditDialog(true)
  }

  // onChange
  const handleChange = (event, memberId) => {
    const { name, value } = event.target
    setFormEdit((prev) =>
      prev.map((member) => {
        if (member.id === memberId) {
          return {
            ...member,
            [name]: value,
          }
        }
        return member
      })
    )
  }
  const handleDateChange = (value, memberId, fieldName) => {
    setFormEdit((prev) =>
      prev.map((member) => {
        if (member.id === memberId) {
          return {
            ...member,
            [fieldName]: value,
          }
        }
        return member
      })
    )
  }
  const handleDropDownChange = (value, memberId, fieldName) => {
    setFormEdit((prev) =>
      prev.map((member) => {
        if (member.id === memberId) {
          return {
            ...member,
            [fieldName]: value,
          }
        }
        return member
      })
    )
  }
  const handleChangeRepresentative = (event, key) => {
    const { name, value } = event.target
    setFormRepresentative((prev) =>
      prev.map((v) => {
        if (v.key === key) {
          return {
            ...v,
            [name]: value,
          }
        }
        return v
      })
    )
  }
  const handleChangeDateRepresentative = (value, key) => {
    setFormRepresentative((prev) =>
      prev.map((v) => {
        if (v.key === key) {
          return {
            ...v,
            start_date: value,
          }
        }
        return v
      })
    )
  }

  // submit
  const onSubmitAddMember = () => {
    if (tab === 'employee') {
      const request = checkedList.map((v) => ({
        team: id,
        employee_id: v.id,
      }))

      dispatch(createTeamMember(request))
    } else {
      const request = formRepresentative.map((v) => ({
        ...v,
        team: id,
      }))
      dispatch(createRepresentative(request))
    }

    setIsOpenAddMemberPanel(false)
    setIsOpenConfirmAddMemberDialog(false)
  }
  const onSubmitEdit = () => {
    const request = formEdit.map((v) => ({
      id: v.id,
      position: v.position,
      appointment: v.appointment,
      start_date: v.start_date,
      representative: v.representative,
      status: v.status,
    }))
    dispatch(editTeamMember(request))
    setIsEdit(false)
    setIsOpenConfirmEditDialog(false)
  }
  const onSubmitDelete = () => {
    const request = [
      {
        id: memberId,
        status: 'delete',
      },
    ]
    dispatch(deleteTeamMember(request))
  }

  // filter member
  const onChangePagination = async (value) => {
    const multipleSearch = searchFilters.map((v, i) => ({
      [`populate[employeename][${i}]`]: v.name,
    }))
    const filter = {
      teamId: id,
      page: value.page,
      pageSize: limit,
      multipleSearch,
    }
    fetchMemberList(filter)
  }
  const onSearch = () => {
    if (search !== '') {
      const newFilter = {
        key: uuidv4(),
        name: search,
      }
      const multipleSearch = [...searchFilters, newFilter].map((v, i) => ({
        [`populate[employeename][${i}]`]: v.name,
      }))
      const filter = {
        teamId: id,
        page: 1,
        pageSize: limit,
        multipleSearch,
      }
      fetchMemberList(filter)
      setSearch('')
      setSearchFilters((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`populate[employeename][${i}]`]: v.name,
    }))
    const filter = {
      teamId: id,
      page: 1,
      pageSize: limit,
      multipleSearch,
    }
    fetchMemberList(filter)
    setSearch('')
  }
  const onClearSearch = () => {
    const filter = {
      teamId: id,
      page: 1,
      pageSize: limit,
      multipleSearch: [],
    }
    fetchMemberList(filter)
    clearData()
  }
  const clearData = () => {
    setSearch('')
    setSearchFilters([])
  }

  // filter employee
  const onChangePaginationForEmployee = async (value) => {
    const multipleSearch = searchFiltersForEmployee.map((v, i) => ({
      [`filters[$or][${2 * i}][first_name][$contains]`]: v.name,
      [`filters[$or][${2 * i + 1}][last_name][$contains]`]: v.name,
    }))
    const filter = {
      page: value.page,
      pageSize: limit,
      department: departmentSelected,
      position: positionSelected,
      multipleSearch,
    }
    fetchEmployoeeForAddMemeber(filter)
  }
  const onSearchForEmployee = () => {
    if (searchForEmployee !== '') {
      const newFilter = {
        key: uuidv4(),
        name: searchForEmployee,
      }
      const multipleSearch = [...searchFiltersForEmployee, newFilter].map((v, i) => ({
        [`filters[$or][${2 * i}][first_name][$contains]`]: v.name,
        [`filters[$or][${2 * i + 1}][last_name][$contains]`]: v.name,
      }))

      const filter = {
        page: 1,
        pageSize: limit,
        multipleSearch,
        department: departmentSelected,
        position: positionSelected,
      }
      fetchEmployoeeForAddMemeber(filter)
      setSearchForEmployee('')
      setSearchFiltersForEmployee((prev) => [...prev, newFilter])
    }
  }
  const deleteSearchBadgeForEmployee = (key) => {
    const newSearchFilters = searchFiltersForEmployee.filter((v) => v.key != key)
    setSearchFiltersForEmployee(newSearchFilters)
    const multipleSearch = newSearchFilters.map((v, i) => ({
      [`filters[$or][${2 * i}][first_name][$contains]`]: v.name,
      [`filters[$or][${2 * i + 1}][last_name][$contains]`]: v.name,
    }))
    const filter = {
      page: 1,
      pageSize: limit,
      multipleSearch,
      department: departmentSelected,
      position: positionSelected,
    }
    fetchEmployoeeForAddMemeber(filter)
    setSearchForEmployee('')
  }
  const onClearSearchForEmployee = () => {
    const filter = {
      page: 1,
      pageSize: limit,
      multipleSearch: [],
      department: '',
      position: '',
    }
    fetchEmployoeeForAddMemeber(filter)
    clearDataForEmployee()
  }
  const clearDataForEmployee = () => {
    setSearchForEmployee('')
    setDepartmentSelected('')
    setPositionSelected('')
    setSearchFiltersForEmployee([])
  }

  return (
    <Wrapper>
      <FlexContainer className="align-items-center mb-1-rem">
        <RiHome6Line size={20} className="mr-8-px" />
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px">{t('organization')}</span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px" onClick={backToGroupManagement}>
          Group
        </span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="icon mr-8-px" onClick={backToPrevious}>
          {groupInfo?.name}
        </span>
        <MdOutlineKeyboardArrowRight className="mr-8-px" />
        <span className="highlight">{teamInfo?.name}</span>
      </FlexContainer>

      <div className="heading mb-24-px">{teamInfo?.name}</div>
      <Divider className="mb-1-rem" />

      <FilterContainer className="mb-1-rem">
        <FlexContainer className="mb-6-px">
          <div className="w-30 heading-search mr-1-rem">Search</div>
        </FlexContainer>

        <FlexContainer className="mb-12-px">
          <SearchText
            className="w-30 item-vertical mr-1-rem"
            placeholder="Search Title / Attendee"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSubmit={onSearch}
            onClear={() => setSearch('')}
          />

          <ButtonOutline className="item-vertical" onClick={onClearSearch}>
            {t('clear_all')}
          </ButtonOutline>
        </FlexContainer>
        <BadgeSearchContainer>
          {searchFilters.map((v) => (
            <div className="badge" key={v.key}>
              <span className="name">{v.name}</span>{' '}
              <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadge(v.key)} />
            </div>
          ))}
        </BadgeSearchContainer>
      </FilterContainer>

      <TableWrapper>
        <div className="table-headers">
          <div className="header-container">
            <div>
              <span className="table-header-name">Member</span>
              <span className="badge">{total} Member</span>
            </div>
            {isEdit ? (
              <div>
                <ButtonOutline className="mr-8-px" onClick={toggleEditMode}>
                  {t('cancel')}
                </ButtonOutline>
                <Button onClick={openConfirmEdit}>{t('save_changes')}</Button>
              </div>
            ) : (
              <div>
                <ButtonOutline className="edit-btn-outline  mr-8-px" onClick={toggleEditMode}>
                  {t('Edit')}
                </ButtonOutline>
                <Button onClick={openAddMemberSidePanel}>Add Member</Button>
              </div>
            )}
          </div>
        </div>
        {isEdit ? (
          <Table
            columns={memberColumn}
            data={membersEdit}
            page={page}
            pageCount={pageCount}
            onStateChange={onChangePagination}
          />
        ) : (
          <Table
            columns={memberColumn}
            data={members}
            page={page}
            pageCount={pageCount}
            onStateChange={onChangePagination}
          />
        )}
      </TableWrapper>

      {/* side panel */}
      <SidePanel isOpen={isOpenAddMemberPanel} setIsOpen={setIsOpenAddMemberPanel} width={window.innerWidth - 80}>
        <SidePanelContainer>
          <div>
            <FlexContainer className="justify-content-space-between align-items-center mb-12-px">
              <div className="heading">Add Member to Team</div>
              <LiaTimesSolid size={18} className="icon" onClick={closeAddMemberSidePanel} />
            </FlexContainer>
            <div className="mb-12-px">{teamInfo?.name}</div>
            <Divider className="mb-12-px" />

            <TabMenuContainer>
              <span className={`tab-menu ${tab === 'employee' ? 'active' : ''}`} onClick={() => toggleTab('employee')}>
                {t('employee')}
              </span>
              <span
                className={`tab-menu ${tab === 'pepresentative' ? 'active' : ''}`}
                onClick={() => toggleTab('pepresentative')}
              >
                Representative
              </span>
            </TabMenuContainer>

            {tab === 'employee' ? (
              <React.Fragment>
                <FilterContainer className="mb-1-rem">
                  <FlexContainer className="mb-6-px">
                    <div className="w-30 heading-search mr-1-rem">Search</div>
                    <div className="w-20 heading-search mr-1-rem">Department</div>
                    <div className="w-20 heading-search">Job Position</div>
                  </FlexContainer>

                  <FlexContainer className="mb-6-px">
                    <SearchText
                      className="w-30 item-vertical mr-1-rem"
                      placeholder="Search Title / Attendee"
                      value={searchForEmployee}
                      onChange={(e) => setSearchForEmployee(e.target.value)}
                      onSubmit={onSearchForEmployee}
                      onClear={() => setSearchForEmployee('')}
                    />
                    <div className="w-20 mr-1-rem">
                      <Dropdown
                        optionList={departments}
                        value={departmentSelected}
                        onChange={(value) => setDepartmentSelected(value)}
                      />
                    </div>
                    <div className="w-20 mr-1-rem">
                      <Dropdown
                        optionList={positions}
                        value={positionSelected}
                        onChange={(value) => setPositionSelected(value)}
                      />
                    </div>

                    <ButtonOutline className="item-vertical" onClick={onClearSearchForEmployee}>
                      {t('clear_all')}
                    </ButtonOutline>
                  </FlexContainer>
                  <BadgeSearchContainer>
                    {searchFiltersForEmployee.map((v) => (
                      <div className="badge" key={v.key}>
                        <span className="name">{v.name}</span>{' '}
                        <LiaTimesSolid
                          size={15}
                          className="icon close"
                          onClick={() => deleteSearchBadgeForEmployee(v.key)}
                        />
                      </div>
                    ))}
                  </BadgeSearchContainer>
                </FilterContainer>

                <TableWrapper>
                  <div className="table-headers">
                    <div className="header-container">
                      <div>
                        <span className="table-header-name">Total Employee</span>
                        <span className="badge">{totalEmployee} Employees</span>
                      </div>
                    </div>
                  </div>
                  <Table
                    columns={memberCreateColumn}
                    data={membersForCreate}
                    page={pageEmployee}
                    pageCount={pageCountEmployee}
                    onStateChange={onChangePaginationForEmployee}
                  />
                </TableWrapper>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <TableWrapper className="mb-12-px">
                  <div className="table-headers">
                    <div className="header-container">
                      <div>
                        <span className="table-header-name">Add Representative</span>
                      </div>
                    </div>
                  </div>

                  <Table columns={representativeColumn} data={representative} />
                </TableWrapper>

                <Button onClick={addNewRepresentative}>Add</Button>
              </React.Fragment>
            )}
          </div>

          <div>
            <Divider className="mb-12-px" />
            <FlexContainer className="justify-content-end">
              <ButtonOutline className="mr-20-px" onClick={closeAddMemberSidePanel}>
                {t('cancel')}
              </ButtonOutline>
              <Button onClick={addMember}>{t('confirm')}</Button>
            </FlexContainer>
          </div>
        </SidePanelContainer>
      </SidePanel>
      {/* dialog */}
      <DialogConfirm
        open={Boolean(isOpenConfirmAddMemberDialog)}
        onClose={() => setIsOpenConfirmAddMemberDialog(false)}
        onSubmit={onSubmitAddMember}
        icon={userAddIcon}
        title={dialogText.heading}
        description={dialogText.desc}
        textYes={t('confirm')}
        textNo={t('Back')}
      />
      <DialogConfirm
        open={Boolean(isOpenConfirmEditDialog)}
        onClose={() => setIsOpenConfirmEditDialog(false)}
        onSubmit={onSubmitEdit}
        icon={userIcon}
        title={dialogText.heading}
        description={dialogText.desc}
        textYes={t('confirm')}
        textNo={t('Back')}
      />
      <DialogSuccess
        open={Boolean(isOpenSuccessDialog)}
        onClose={() => setIsOpenSuccessDialog(false)}
        onSubmit={() => setIsOpenSuccessDialog(false)}
        icon={successIcon}
        title={dialogText.heading}
        description={dialogText.desc}
        textYes={t('done')}
      />
      <DialogConfirmDelete
        open={Boolean(isOpenConfirmDeleteDialog)}
        onClose={() => setIsOpenConfirmDeleteDialog(false)}
        onSubmit={onSubmitDelete}
        title={dialogText.heading}
        description={dialogText.desc}
      />
    </Wrapper>
  )
}

export default OrganizationMember
