import { combineReducers } from 'redux'
import {
  ADD_TASK_TO_PROJECT_TASK,
  CREATE_MILESTONE,
  UPDATE_MILESTONE,
  CREATE_PROJECT_TASK,
  CREATE_TASK_TEMPLATE,
  DELETE_MILESTONE,
  DELETE_TASK_IN_PROJECT_TASK,
  GET_MILESTONE_BY_ID,
  GET_MILESTONE_BY_PROJECT_TASK_ID,
  GET_MILESTONE_SCOPE_BY_MILESTONE_ID,
  GET_MILESTONE_SCOPE_BY_PROJECT_TASK_ID,
  GET_MILESTONE_LIST_BY_PROJECT_TASK_ID,
  GET_MILESTONE_TASK_TYPE_BY_MILESTONE_TASK_SCOPE_ID,
  GET_MILESTONE_TASK_TYPE_INFO_BY_TASK_ID,
  GET_POSITION_BY_DEPARTMENT_ID,
  GET_PROJECT_TASK,
  GET_PROJECT_TASK_BY_ID,
  GET_TASK_TYPE_BY_TASK_SCOPE,
  UPDATE_SCOPE_STATUS_IN_MILESTONE,
  UPDATE_STATUS_PROJECT_TASK,
  UPDATE_TASK_TYPE_STATUS_IN_MILESTONE,
  GET_MILESTONE_SCOPE_INFO_BY_SCOPE_ID,
  GET_SCOPE_OF_WORK,
  GET_SCOPE_WORK_BY_POSITION_ID,
  GET_TASK_TYPE_BY_SCOPE_ID,
  GET_TASK_TEMPLATE_LIST,
  GET_TASK_TEMPLATE_BY_ID,
  GET_SCOPE_WORK_BY_MILESTONE_ID,
  GET_PROJECT_LIST_DROPDOWN,
  GET_LATEST_PROGRESS_UPDATE,
  GET_HISTORY_PROGRESS_UPDATE,
  GET_PROGRESS_UPDATE_BY_ID,
  CREATE_PROGRESS_UPDATE,
  EDIT_PROGRESS_UPDATE,
  GET_PROJECT_TASK_NOTES,
  CREATE_PROJECT_TASK_NOTES,
  GET_ASSIGNED_EMPLOYEE,
} from '../actionTypes'

const initialState = {
  projectList: [],
  projectListDropdown: [],
  milestoneList: [],
  milestoneScopeList: [],
  milestoneTaskTypeList: [],
  taskTypeDataList: [],
  scopeWorkDataList: [],
  scopeWorkByPositionList: [],
  taskTemplateList: [],
  projectTaskData: {},
  milestoneData: {},
  positionData: {},
  milestoneScopeInfo: {},
  milestoneTaskTypeInfo: {},
  taskTemplateInfo: {},
  lastestProgressUpdate: {},
  historyProgressUpdate: [],
  progressUpdateInfo: {},
  projectTaskNotes: [],
  assignedEmployee: [],
  skip: 0,
  limit: 10,
  isLoading: false,
  page: 1,
  pageSize: 10,
  pageCount: 0,
  total: 0,
  state: '',
}

const projectTaskReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // new enhancement
    case GET_LATEST_PROGRESS_UPDATE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_LATEST_PROGRESS_UPDATE.SUCCESS: {
      const { data } = action
      return {
        ...state,
        lastestProgressUpdate: data,
        isLoading: false,
      }
    }
    case GET_LATEST_PROGRESS_UPDATE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_HISTORY_PROGRESS_UPDATE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_HISTORY_PROGRESS_UPDATE.SUCCESS: {
      const { data } = action
      return {
        ...state,
        historyProgressUpdate: data,
        isLoading: false,
      }
    }
    case GET_HISTORY_PROGRESS_UPDATE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PROGRESS_UPDATE_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PROGRESS_UPDATE_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        progressUpdateInfo: data,
        isLoading: false,
      }
    }
    case GET_PROGRESS_UPDATE_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_PROGRESS_UPDATE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_PROGRESS_UPDATE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case CREATE_PROGRESS_UPDATE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case EDIT_PROGRESS_UPDATE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case EDIT_PROGRESS_UPDATE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case EDIT_PROGRESS_UPDATE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PROJECT_TASK_NOTES.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PROJECT_TASK_NOTES.SUCCESS: {
      const { data } = action
      return {
        ...state,
        projectTaskNotes: data,
        isLoading: false,
      }
    }
    case GET_PROJECT_TASK_NOTES.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_PROJECT_TASK_NOTES.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'CREATE_PROJECT_TASK_NOTES.REQUEST',
      }
    case CREATE_PROJECT_TASK_NOTES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_PROJECT_TASK_NOTES.SUCCESS',
      }
    }
    case CREATE_PROJECT_TASK_NOTES.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'CREATE_PROJECT_TASK_NOTES.FAILURE',
      }
    // old
    case GET_PROJECT_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PROJECT_TASK.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        projectList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_PROJECT_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PROJECT_LIST_DROPDOWN.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PROJECT_LIST_DROPDOWN.SUCCESS: {
      const { data } = action
      return {
        ...state,
        projectListDropdown: data,
        isLoading: false,
      }
    }
    case GET_PROJECT_LIST_DROPDOWN.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MILESTONE_BY_PROJECT_TASK_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MILESTONE_BY_PROJECT_TASK_ID.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        milestoneList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_MILESTONE_BY_PROJECT_TASK_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MILESTONE_SCOPE_BY_PROJECT_TASK_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MILESTONE_SCOPE_BY_PROJECT_TASK_ID.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        milestoneScopeList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_MILESTONE_SCOPE_BY_PROJECT_TASK_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MILESTONE_LIST_BY_PROJECT_TASK_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MILESTONE_LIST_BY_PROJECT_TASK_ID.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        milestoneScopeList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_MILESTONE_LIST_BY_PROJECT_TASK_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MILESTONE_SCOPE_BY_MILESTONE_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MILESTONE_SCOPE_BY_MILESTONE_ID.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        milestoneScopeList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_MILESTONE_SCOPE_BY_MILESTONE_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MILESTONE_TASK_TYPE_BY_MILESTONE_TASK_SCOPE_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MILESTONE_TASK_TYPE_BY_MILESTONE_TASK_SCOPE_ID.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        milestoneTaskTypeList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_MILESTONE_TASK_TYPE_BY_MILESTONE_TASK_SCOPE_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_TASK_TYPE_BY_TASK_SCOPE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_TASK_TYPE_BY_TASK_SCOPE.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        taskTypeDataList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_TASK_TYPE_BY_TASK_SCOPE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_SCOPE_WORK_BY_MILESTONE_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_SCOPE_WORK_BY_MILESTONE_ID.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        taskTypeDataList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_SCOPE_WORK_BY_MILESTONE_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_PROJECT_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_PROJECT_TASK.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_PROJECT_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PROJECT_TASK_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PROJECT_TASK_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        projectTaskData: data,
        isLoading: false,
      }
    }
    case GET_PROJECT_TASK_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MILESTONE_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MILESTONE_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        milestoneData: data,
        isLoading: false,
      }
    }
    case GET_MILESTONE_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_POSITION_BY_DEPARTMENT_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_POSITION_BY_DEPARTMENT_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        positionData: data,
        isLoading: false,
      }
    }
    case GET_POSITION_BY_DEPARTMENT_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case ADD_TASK_TO_PROJECT_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case ADD_TASK_TO_PROJECT_TASK.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case ADD_TASK_TO_PROJECT_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_MILESTONE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_MILESTONE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_MILESTONE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_MILESTONE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_MILESTONE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_MILESTONE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_MILESTONE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case DELETE_MILESTONE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_MILESTONE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_TASK_TEMPLATE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_TASK_TEMPLATE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_TASK_TEMPLATE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_STATUS_PROJECT_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_STATUS_PROJECT_TASK.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_STATUS_PROJECT_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_TASK_IN_PROJECT_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case DELETE_TASK_IN_PROJECT_TASK.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_TASK_IN_PROJECT_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_SCOPE_STATUS_IN_MILESTONE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_SCOPE_STATUS_IN_MILESTONE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_SCOPE_STATUS_IN_MILESTONE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MILESTONE_TASK_TYPE_INFO_BY_TASK_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MILESTONE_TASK_TYPE_INFO_BY_TASK_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        milestoneTaskTypeInfo: data,
        isLoading: false,
      }
    }
    case GET_MILESTONE_TASK_TYPE_INFO_BY_TASK_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    case UPDATE_TASK_TYPE_STATUS_IN_MILESTONE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_TASK_TYPE_STATUS_IN_MILESTONE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_TASK_TYPE_STATUS_IN_MILESTONE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_MILESTONE_SCOPE_INFO_BY_SCOPE_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_MILESTONE_SCOPE_INFO_BY_SCOPE_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        milestoneScopeInfo: data,
        isLoading: false,
      }
    }
    case GET_MILESTONE_SCOPE_INFO_BY_SCOPE_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_SCOPE_OF_WORK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_SCOPE_OF_WORK.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        scopeWorkDataList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_SCOPE_OF_WORK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_SCOPE_WORK_BY_POSITION_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_SCOPE_WORK_BY_POSITION_ID.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        scopeWorkByPositionList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_SCOPE_WORK_BY_POSITION_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_TASK_TYPE_BY_SCOPE_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_TASK_TYPE_BY_SCOPE_ID.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        taskTypeDataList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_TASK_TYPE_BY_SCOPE_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_TASK_TEMPLATE_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_TASK_TEMPLATE_LIST.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        taskTemplateList: data,
        isLoading: false,
      }
    }
    case GET_TASK_TEMPLATE_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_TASK_TEMPLATE_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_TASK_TEMPLATE_BY_ID.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        taskTemplateInfo: data,
        isLoading: false,
      }
    }
    case GET_TASK_TEMPLATE_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_ASSIGNED_EMPLOYEE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_ASSIGNED_EMPLOYEE.SUCCESS: {
      const { data } = action
      return {
        ...state,
        assignedEmployee: data,
        isLoading: false,
      }
    }
    case GET_ASSIGNED_EMPLOYEE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}

export default combineReducers({
  projectTaskReducer,
})
