import { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import PopperUnstyled from '@mui/base/Popper'

import Button from '../../components/form/button/Button'
import Dropdown from '../../components/form/Dropdown'
import TextField from '../../components/form/TextField'
import DateTime from '../../components/form/DateTime'
import Date from '../../components/form/Date'
import Time from '../../components/form/Time'
import { FIELD_TYPE, FILTER_CONDIION, MAP_FILTER_CONDIION_WORD } from '../../utils/constants'
import { validateInteger, validateNumber } from '../../utils/validation'
import { getFirstSplit } from '../../utils/common'
import { selectCmsContentType, selectCmsByType } from '../../redux/selectors'

const conditionList = [
  {
    text: MAP_FILTER_CONDIION_WORD[FILTER_CONDIION.$eq],
    value: FILTER_CONDIION.$eq,
    allow: [...Object.values(FIELD_TYPE)],
  },
  {
    text: MAP_FILTER_CONDIION_WORD[FILTER_CONDIION.$ne],
    value: FILTER_CONDIION.$ne,
    allow: [...Object.values(FIELD_TYPE)],
  },
  {
    text: MAP_FILTER_CONDIION_WORD[FILTER_CONDIION.$contains],
    value: FILTER_CONDIION.$contains,
    allow: [
      FIELD_TYPE.string,
      FIELD_TYPE.text,
      FIELD_TYPE.richtext,
      FIELD_TYPE.email,
      FIELD_TYPE.uid,
      FIELD_TYPE.relation,
    ],
  },
  {
    text: MAP_FILTER_CONDIION_WORD[FILTER_CONDIION.$notContains],
    value: FILTER_CONDIION.$notContains,
    allow: [
      FIELD_TYPE.string,
      FIELD_TYPE.text,
      FIELD_TYPE.richtext,
      FIELD_TYPE.email,
      FIELD_TYPE.uid,
      FIELD_TYPE.relation,
    ],
  },
  {
    text: MAP_FILTER_CONDIION_WORD[FILTER_CONDIION.$gte],
    value: FILTER_CONDIION.$gte,
    allow: [
      FIELD_TYPE.biginteger,
      FIELD_TYPE.date,
      FIELD_TYPE.datetime,
      FIELD_TYPE.decimal,
      FIELD_TYPE.integer,
      FIELD_TYPE.time,
    ],
  },
  {
    text: MAP_FILTER_CONDIION_WORD[FILTER_CONDIION.$lte],
    value: FILTER_CONDIION.$lte,
    allow: [
      FIELD_TYPE.biginteger,
      FIELD_TYPE.date,
      FIELD_TYPE.datetime,
      FIELD_TYPE.decimal,
      FIELD_TYPE.integer,
      FIELD_TYPE.time,
    ],
  },
]

const booleanList = [
  { text: 'true', value: true },
  { text: 'false', value: false },
]

const Div = styled.div``

const StyledPopper = styled(PopperUnstyled)`
  padding: 14px;
  background: var(--Base-White);
  border: 1px solid var(--Gray-300);
  box-shadow: var(--Shadow-xs);
  border-radius: 8px;
  z-index: 10;

  .form {
    display: flex;
    flex-direction: column;
    width: 342px;

    > div {
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .field > div {
      width: 100%;
    }

    > button {
      align-self: flex-end;
    }
  }
`

const FilterButton = ({ type, onSubmit, ...props }) => {
  const { attributes } = useSelector(selectCmsContentType(type))
  const { config } = useSelector(selectCmsByType(type))
  const [anchorEl, setAnchorEl] = useState(null)

  const { metadatas = {} } = config?.contentType || {}

  const fieldList = Object.keys(metadatas)
    .filter((k) => metadatas[k].list?.searchable === true)
    .map((v) => {
      const value = attributes[v].type === FIELD_TYPE.relation ? `${v}.${metadatas[v].list.mainField}` : v
      return {
        text: v,
        value,
      }
    })

  const toggleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const submitFilter = (data) => {
    onSubmit(data)
    setAnchorEl(null)
  }

  return (
    <Div {...props}>
      <Button onClick={toggleClick}>Filters</Button>
      {fieldList.length > 1 && (
        <StyledPopper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start">
          <FilterField fieldList={fieldList} attributes={attributes} onSubmit={submitFilter} />
        </StyledPopper>
      )}
    </Div>
  )
}

const FilterField = ({ fieldList, attributes, onSubmit }) => {
  const [field, setField] = useState(fieldList[0].value)
  const [condition, setCondition] = useState(FILTER_CONDIION.$eq)
  const [value, setValue] = useState('')

  const fieldType = attributes[getFirstSplit(field)].type
  const enumList = (attributes[getFirstSplit(field)].enum || []).map((v) => ({ text: v, value: v }))

  const isValid = field && condition && value

  const onChangeField = (v) => {
    setField(v)
    setCondition(FILTER_CONDIION.$eq)
    setValue('')
  }

  const handleSubmit = () => {
    onSubmit({
      field,
      condition,
      value,
    })
  }

  return (
    <div className="form">
      <Dropdown optionList={fieldList} value={field} onChange={onChangeField} />
      <Dropdown
        optionList={conditionList.filter((v) => v.allow.includes(fieldType))}
        value={condition}
        onChange={setCondition}
      />
      <div className="field">
        {(fieldType === FIELD_TYPE.string ||
          fieldType === FIELD_TYPE.text ||
          fieldType === FIELD_TYPE.email ||
          fieldType === FIELD_TYPE.relation ||
          fieldType === FIELD_TYPE.uid) && <TextField value={value} onChange={(e) => setValue(e.target.value)} />}
        {(fieldType === FIELD_TYPE.integer || fieldType === FIELD_TYPE.biginteger) && (
          <TextField
            value={value}
            onChange={({ target: { value } }) => (value === '' || validateInteger(value)) && setValue(value)}
          />
        )}
        {fieldType === FIELD_TYPE.decimal && (
          <TextField
            value={value}
            onChange={({ target: { value } }) => (value === '' || validateNumber(value)) && setValue(value)}
          />
        )}
        {fieldType === FIELD_TYPE.boolean && <Dropdown value={value} optionList={booleanList} onChange={setValue} />}
        {fieldType === FIELD_TYPE.enumeration && <Dropdown value={value} optionList={enumList} onChange={setValue} />}
        {fieldType === FIELD_TYPE.datetime && <DateTime value={value} onChange={setValue} />}
        {fieldType === FIELD_TYPE.date && <Date value={value} onChange={setValue} />}
        {fieldType === FIELD_TYPE.time && <Time value={value} onChange={setValue} />}
      </div>
      <Button onClick={handleSubmit} disabled={!isValid}>
        Add Filter
      </Button>
    </div>
  )
}

export default FilterButton
