import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Timelines from './Timelines'

export default function SubTaskList({ requestId,dataList, onUpdate, docId,onClickEdit,isRoutine,taskId }) {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={2}>
        <Timelines taskId={taskId} requestId={requestId} itemList={dataList} onUpdate={onUpdate} docId={docId} onClickEdit={onClickEdit} isRoutine={isRoutine}/>
      </Stack>
    </Box>
  )
}
