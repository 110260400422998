import { styled } from '@mui/system'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { forwardRef, useImperativeHandle } from 'react'

import { renderFields } from './renderFields'

import 'dayjs/locale/en-gb'
import 'dayjs/locale/th'

const langLocale = 'th'

/**
 * @typedef {Object} Field
 * @property {string} type - The type of the field.
 * @property {string} label - The label of the field.
 * @property {string} placeholder - The placeholder of the field.
 * @property {Object} validator - The validation rules for the field.
 * @property {string} validator.message - The validation error message.
 * @property {number} validator.minLength - The minimum length of the field value.
 * @property {number} validator.maxLength - The maximum length of the field value.
 * @property {Function} onchange - The function to call when the field value changes.
 * @property {Object} props - Additional properties for the field.
 */

/**
 * @typedef {Object} Schema
 * @property {Object.<string, Field>} properties - The properties of the fields in the form.
 * @property {string[]} required - The names of the fields that are required.
 */

/**
 * A dynamic form component that renders form fields based on a given schema.
 *
 * @component
 * @param {Object} methods - The form methods object from react-hook-form.
 * @param {Schema} schema - The schema object that defines the form fields.
 * @param {Function} onSubmit - The function to be called when the form is submitted.
 * @param {React.Ref} ref - The ref object used to expose the form's submit function.
 * @returns {React.Element} The rendered form component.
 */
const DynamicForm = forwardRef(function DynamicForm({ methods, schema, onSubmit, readonly, ...formProps }, ref) {
  const { handleSubmit } = methods

  useImperativeHandle(ref, () => ({
    submit: onSubmit && handleSubmit(onSubmit),
  }))

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={langLocale}>
      <FormWrapper {...formProps} ref={ref} onSubmit={handleSubmit(onSubmit)}>
        {renderFields(methods, schema, readonly)}
      </FormWrapper>
    </LocalizationProvider>
  )
})

export default DynamicForm

const FormWrapper = styled('form')`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;

  section {
    display: flex;
    column-gap: 2rem;
    row-gap: 0.5rem;
    align-self: stretch;
    align-items: start;

    font-size: 14px;
    font-weight: 400;
    color: inherit;

    & > :not(label) {
      width: 100%;
    }

    & > label {
      width: 280px;
      /* Text sm/Medium */
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 1.25rem; /* 142.857% */
      color: var(--Gray-700);
    }

    & div.form-field {
      display: flex;
      flex-direction: column;

      & p.error {
        font-size: 0.8rem;
        color: var(--Error-500);
        margin: 0;
        margin-top: 0.25rem;
      }
    }
  }

  section > label {
    color: var(--Gray-900);
    font-size: 14px;
  }

  /* Error styles */
  & .Mui-error {
    & input {
      border-color: var(--Error-300);
    }
  }

  p,
  h3,
  strong {
    margin: 0;
  }

  // Breakpoint for "max-sm"
  @media not all and (min-width: 640px) {
    section {
      flex-direction: column;
    }
  }
`
