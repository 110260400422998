import styled from 'styled-components'
import Modal from '../../components/common/Modal'
import Button from '../../components/form/button/Button'
import QRCode from "react-qr-code";
import html2canvas from 'html2canvas'

const Div = styled.div`
/* ST CSS ALL QR CODE */
.dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  /* Style for the dialog content */
  .dialog-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    position: relative;
    width: 400px;
    padding: 0 15px;
  }
  /* Style for the close icon */
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0;
  }

  .popup-image {
    width: 256px;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .dialog-qr-footer {
    /*margin-bottom: 15px;*/
    /*
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    */
    width : 100%;
    background-color : #FFFFFF;
    /*padding: 14px;*/
    .button_margin {
      margin: 10px;
    }
  }
  .box_qr_code{
    display: flex;
    justify-content: center;
    padding: 14px;
  }
  .dialog-conten-createdate{
    /*margin-top: 15px;*/
    font-size: 12px;
    font-weight: normal;
    color: #475467;
    /*padding: 16px;*/
    /*text-align: end;*/
  }
  .box_DowloadQRIMG{
    padding: 10px;
  }
  .box_qr_header{
    /*
    margin-bottom: 10px;
    margin-top: 10px;
    */
    padding: 14px 0px;
  }
  .table-body-head_cell {
    display: table-cell;
    border: 1px solid #D0D5DD;
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    text-align: center;
    font-weight: 600;
    /*background-color: #F2F4F7;*/
    color: #475467;
    font-size: 12px;
  }

  .box_qr_sub{
    display: grid;
    grid-template-columns: repeat(2, 20% [col-start] 80% [col-end]);
    /*gap: 20px;*/
    margin-bottom: 10px;
  }
  .box_qr_sub_topic {
    font-size: 14px;
    font-weight: 600;
    color: #101828;
  }
  .box_qr_sub_value {
    font-size: 14px;
    font-weight: normal;
    color: #101828;
    text-align: left;
  }
  .border_top_left_radius{
    border-top-left-radius: 8px;
  }
  .border_top_right_radius{
    border-top-right-radius: 8px;
  }
  .border_bottom_left_radius{
    border-bottom-left-radius:8px
  }
  .border_bottom_right_radius{
    border-bottom-right-radius: 8px;
  }
  .box_qr_header_content{
    font-size: 16px;
    font-weight: 600;
    color: var(--Gray-900);
    text-align: left;
  }
  /* ED CSS ALL QR CODE */
  .content_header {
    padding: 14px 14px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .content-title {
    font-size: 18px;
    font-weight: 600;
    color: #101828;
  }
  .css_header_detail {
    font-size: 14px;
    font-weight: normal;
    color: #475467;
  }
`

const ModalQR = ({ open, onClose, QRDisplay,valueQR, ...props }) => {
    const DowloadQR = async () => {
        //alert('DowloadQR');
        const element = document.getElementById('DowloadQRIMG'),
        canvas = await html2canvas(element),
        data = canvas.toDataURL('image/jpg'),
        link = document.createElement('a');
    
        link.href = data;
        link.download = 'QRCode.jpg';
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
  return (
    <Modal open={open} onClose={onClose} disableBackdropClick>
      <Div {...props}>
        <div className="dialog">
            <div className="dialog-content">
                <div>
                    <button className="close-icon" onClick={onClose}>&#10005;</button>
                </div>
                <div id="DowloadQRIMG" className="box_DowloadQRIMG">
                    <div className="content_header">
                        <div className="content-title">Scan QR Code</div>
                        <div className="css_header_detail">เพื่อกรอกข้อมูลการทำงาน</div>
                        
                    </div>
                    <hr />
                    <div className="box_qr_code">
                        <div className="popup-image">
                            <QRCode
                                id="QRCode" 
                                title="OFM"
                                value={JSON.stringify(valueQR)}
                                
                            />
                        </div>
                    </div>
                    <div className="box_qr_header">

                        <div className="box_qr_sub">
                            <div className="table-body-head_cell box_qr_sub_topic border_top_left_radius border_bottom_left_radius">
                                Zone
                            </div>
                            <div className="table-body-head_cell box_qr_sub_value border_top_right_radius border_bottom_right_radius">
                                {QRDisplay.display_zone}
                            </div>
                        </div>
                        <div className="box_qr_sub">
                            <div className="table-body-head_cell box_qr_sub_topic border_top_left_radius border_bottom_left_radius">
                                Base
                            </div>
                            <div className="table-body-head_cell box_qr_sub_value border_top_right_radius border_bottom_right_radius">
                                {QRDisplay.display_base}
                            </div>
                        </div>
                        {QRDisplay.display_task && (
                            <div className="box_qr_sub">
                                <div className="table-body-head_cell box_qr_sub_topic border_top_left_radius border_bottom_left_radius">
                                    Task 
                                </div>
                                <div className="table-body-head_cell box_qr_sub_value border_top_right_radius border_bottom_right_radius">
                                    {QRDisplay.display_task}
                                </div>
                            </div>
                        )}    


                        {
                            /*
                        <div className="box_qr_header_content">
                            {QRDisplay.display_zone}
                        </div>
                        <div className="box_qr_header_content">
                            {QRDisplay.display_base}
                        </div>
                        <div className="box_qr_header_content">
                            {QRDisplay.display_task}
                        </div>   
                            */
                        }              
                    </div>

                    <div className="dialog-conten-createdate">
                        {QRDisplay.created}
                    </div>
                </div>


                <div className="dialog-qr-footer">
                    <Button className="button_margin" onClick={DowloadQR}>
                        {'Download'}
                    </Button>
                </div>
                
            </div>
        </div>
      </Div>
    </Modal>
  )
}

export default ModalQR
