import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { useEffect, useRef, useState } from 'react'
import { pdfjs } from 'react-pdf'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import THBText from 'thai-baht-text'
import SignatureListView from '../../../../components/feature/document-setting/SignatureListView'
import IconXClose from '../../../../components/widget/icon/IconXClose'
import { getCompanyInfoById, getSaleDocumentById } from '../../../../redux/actions/document'
import { selectDocument } from '../../../../redux/selectors'
import { calculateDiscount, calculatePriceAfterTax, calculatePurchaseTotalPrice } from '../../../../utils/helper'

export default function DebitNoteDocument({
  onClose,
  docType,
  type,
  id,
  docId,
  onDownloaded,
  renderData,
  linkImage,
  settingData,
}) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const mockupData = {
    id: 53,
    doc_group: 'sales',
    doc_type: 'debit_note',
    doc_id: 'DN2024030001',
    details: '',
    customer: {
      id: 1,
      name: 'Edit name',
      address: 'Edit address',
      office: 'Edit office',
      tax_id: 'Edit Tax ID',
      customer_id: 'Edit Customer ID',
      phone: '',
    },
    company: {
      id: 1,
      company_name_TH: 'บริษัท ทูบีซิมเปิ้ล จำกัด',
      company_name_EN: '2BSIMPLE',
      address: 'No.5 soi taweecherdchoo',
      contact_name: 'Tadswan Chanyarakskul',
      contact_number: '0865684670',
      postcode: '10400',
      tex_id: '0105554145191',
      createdAt: '2023-08-18T02:09:39.582Z',
      updatedAt: '2023-09-16T16:46:05.510Z',
      publishedAt: '2023-08-18T02:09:41.904Z',
      prefix_employee_id: 'E',
      num_pad_employee_id: 5,
      lv: 1,
      sub_company_no: null,
      status: 'active',
      address_EN: null,
      contact_name_EN: null,
      url_api_ofm: null,
      theme: null,
      username_field: null,
      thai_province: {
        id: 1,
        name_th: 'กรุงเทพมหานคร',
        name_en: 'Bangkok',
        geography_id: 2,
        deleted_at: null,
        createdAt: '2019-08-08T20:33:09.000Z',
        updatedAt: '2022-05-15T23:31:03.000Z',
        publishedAt: '2023-04-07T09:35:27.000Z',
      },
      thai_amphure: {
        id: 1026,
        name_th: 'เขตดินแดง',
        name_en: 'Khet Din Daeng',
        province_id: 1,
        deleted_at: null,
        createdAt: '2019-08-08T20:33:09.000Z',
        updatedAt: '2022-05-15T23:31:26.000Z',
        publishedAt: '2023-04-07T09:36:36.000Z',
      },
      thai_tambon: {
        id: 102601,
        zip_code: '10400',
        name_th: 'ดินแดง',
        name_en: 'Din Daeng',
        amphure_id: 1026,
        deleted_at: null,
        createdAt: '2019-08-08T20:33:09.000Z',
        updatedAt: '2022-05-15T23:31:31.000Z',
        publishedAt: '2023-04-07T09:37:04.000Z',
      },
    },
    number_of_copy: 1,
    company_logo: '',
    rubber_stamp: '',
    seller_employee_id: 2,
    seller_name: 'Tadswan Chanyarakskul',
    is_external: false,
    signature_list: [],
    doc_date: '2023-09-28',
    tax_invoice_id: 'INV2024020001',
    tax_invoice_date: '2023-09-28',
    sales_territory: 'กรุงเทพ',
    include_tax: false,
    product_list: [
      {
        product_name: 'Product 1',
        quantity: 1,
        price: 10,
      },
      {
        product_name: 'Product 2',
        quantity: 1,
        price: 20,
      },
    ],
    note: 'note note note edit kfhjdgsl dkljfgs sdg  fdjgkfjdg sgfkfdlgjlk;fg',
    discount_percent: 10,
    old_value: 1000,
    vat: true,
  }

  const { saleDocumentData, companyData, documentSettingInfo } = useSelector(selectDocument)
  const dispatch = useDispatch()
  const HEADER_TABLE_LIST = ['No.', 'รายละเอียด', 'จำนวน', 'หน่วยละ', 'จำนวนเงิน']
  const [saleDocInfoData, setSaleDocInfoData] = useState()
  const [totalPrice, setTotalPrice] = useState(0) //ราคารวมสุทธิ
  const [discountPrice, setDiscountPrice] = useState(0) //ราคาส่วนลด
  const [priceAfterDiscount, setPriceAfterDiscount] = useState(0) //ราคาหลังส่วนลด
  const [priceVat, setPriceVat] = useState(0) //ราคา vat
  const [priceAfterVat, setPriceAfterVat] = useState(0)
  const [priceWithHoldingTax, setPriceWithHoldingTax] = useState(0)
  const [summaryPrice, setSummaryPrice] = useState(0)
  const [isSetDataDone, setIsSetDataDone] = useState(false)
  const pdfRef = useRef([]) // change to array ref
  const [copy, setCopy] = useState(2)

  useEffect(() => {
    if (id && type != 'render_preview') {
      dispatch(getSaleDocumentById(id))
    }
  }, [id])

  useEffect(() => {
    ;(async () => {
      if (Object.keys(saleDocumentData).length > 0 && type != 'render_preview') {
        setCopy(saleDocumentData?.number_of_copy)
        calculateAllPrice(saleDocumentData)
        setSaleDocInfoData(saleDocumentData)
        setIsSetDataDone(true)
      }
    })()
  }, [saleDocumentData])

  useEffect(() => {
    ;(async () => {
      if (type == 'render_preview' && renderData) {
        dispatch(getCompanyInfoById())
        calculateAllPrice(renderData)
        setSaleDocInfoData(renderData)
        setIsSetDataDone(true)
      } else if (type == 'document_setting') {
        calculateAllPrice(mockupData)
        setSaleDocInfoData(mockupData)
        setCopy(1)
        setIsSetDataDone(true)
      }
    })()
  }, [])

  const calculateAllPrice = (docData) => {
    if (docData.product_list.length > 0) {
      let tmpProductList = [...docData.product_list]

      // let currentTotalPrice = calculateTotalPrice(tmpProductList)
      let currentTotalPrice = calculatePurchaseTotalPrice(tmpProductList)
      setTotalPrice(currentTotalPrice)
      //คำนวณส่วนลด

      let { discountPrice, totalPrice } = calculateDiscount(currentTotalPrice, docData?.discount_percent || 0)
      setDiscountPrice(discountPrice)
      setPriceAfterDiscount(totalPrice)

      if (docData?.include_tax) {
        let { priceAfterVat, priceVat, priceWithHoldingTax, summaryPrice } = calculatePriceAfterTax(
          true,
          false,
          docData?.withholding_tax_percent || 0,
          totalPrice
        )
        setPriceAfterVat(priceAfterVat)
        setPriceVat(priceVat)
        // setPriceWithHoldingTax(priceWithHoldingTax)
        setSummaryPrice(summaryPrice)
      } else {
        let { priceAfterVat, priceVat, priceWithHoldingTax, summaryPrice } = calculatePriceAfterTax(
          false,
          docData?.vat,
          docData?.withholding_tax_percent || 0,
          totalPrice
        )
        setPriceAfterVat(priceAfterVat)
        setPriceVat(priceVat)
        // setPriceWithHoldingTax(priceWithHoldingTax)
        setSummaryPrice(summaryPrice)
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      if (type == 'download_pdf' && pdfRef && docId && isSetDataDone) {
        // await handleDownloadPdf()
        setTimeout(() => {
          handleDownloadPdf()
        }, 800)
      }
    })()
  }, [pdfRef, isSetDataDone])

  const handleDownloadPdf = async () => {
    const element = pdfRef.current
    if (element) {
      const pdf = new jsPDF()

      for (let i = 0; i < copy; i++) {
        // loop
        if (i > 0) {
          pdf.addPage()
        }

        const canvas = await html2canvas(element[i], {
          logging: true,
          letterRendering: 1,
          allowTaint: false,
          useCORS: true,
        })

        const data = canvas.toDataURL('image/png')

        const imgProperties = pdf.getImageProperties(data)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight)
      }

      pdf.save(docId + '.pdf')
      if (onDownloaded) {
        setSaleDocInfoData()
        onDownloaded()
      }
    }
  }

  const handleClickClose = () => {
    if (onClose) {
      onClose()
    }
  }
  const convertPriceToText = (price) => {
    if (price) {
      return THBText(price)
    }
    return ''
  }

  const convertIntComma = (val) => {
    if (val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
  const convertNumberToCommaTwoDecimal = (myNumber) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
    const tmpTotal = Number(myNumber).toLocaleString('en', options)
    return tmpTotal
  }

  const combineAddressCompany = (company) => {
    let tmpAddress = ''
    tmpAddress =
      company?.address +
      ', ' +
      (company?.thai_province
        ? (company?.thai_province?.name_en == 'Bangkok' ? 'แขวง' : 'ตำบล ') + company?.thai_tambon?.name_th + ', '
        : '') +
      // + (company?.thai_province?.name_en == "Bangkok" ? "เขต ": "อำเภอ ")
      (company?.thai_amphure ? company?.thai_amphure?.name_th + ', ' : '') +
      (company?.thai_province ? company?.thai_province?.name_th + ' ' : '') +
      company?.postcode

    return tmpAddress
  }
  const formatDate = (date) => {
    if (date) {
      const arr = date.split('-')
      return arr[2] + '-' + arr[1] + '-' + arr[0]
    }
    return date
  }
  return (
    <Styled
      isHaveBorder={type == 'document_setting' ? true : false}
      isDownload={type == 'download_pdf' ? true : false}
      isLineBreakBorder={type == 'print' || type == 'document_setting' ? false : true}
    >
      {saleDocInfoData && (
        <>
          {(type == 'preview' || type == 'share' || type == 'render_preview') && (
            <div className="wrp-btn-close">
              <div className="btn-close" onClick={handleClickClose}>
                <IconXClose />
              </div>
            </div>
          )}
          <div className="printarea">
            {[...Array(copy)].map((elementInArray, index) => (
              <div
                className={'main-content ' + (type == 'download_pdf' ? 'download-pdf-styled' : '')}
                ref={(element) => {
                  pdfRef.current[index] = element
                }}
                key={'pdf' + index}
              >
                <div className="container-top">
                  <div className="number-of-copy-bill">{index + 1 + '/' + copy}</div>
                  <div className="header-top">
                    <div className="text-header">
                      {linkImage?.url_company_logo ? (
                        <img className="image-company-logo" src={linkImage?.url_company_logo} />
                      ) : (
                        <>
                          {saleDocInfoData?.company_logo && saleDocInfoData?.signature ? (
                            <>
                              <img
                                className="image-company-logo"
                                src={saleDocInfoData?.company_logo + '?' + Math.random()}
                                crossOrigin="anonymous"
                              />
                            </>
                          ) : (
                            <>
                              {type == 'render_preview'
                                ? companyData?.company_name_EN
                                : saleDocInfoData?.company?.company_name_EN}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="header-type-bill">ใบเพิ่มหนี้</div>
                  </div>
                  <div className="header-detail">
                    <div className="header-detail-left">
                      <div className="header-company">
                        <div className="name-seller">
                          {type == 'render_preview'
                            ? companyData?.company_name_TH
                            : saleDocInfoData?.company?.company_name_TH}
                        </div>
                        <div className="address">
                          {type == 'render_preview'
                            ? combineAddressCompany(companyData)
                            : combineAddressCompany(saleDocInfoData?.company)}
                        </div>
                        <div className="tax-identification-number">
                          เลขประจำตัวผู้เสียภาษี{' '}
                          <span>
                            {'  ' + type == 'render_preview' ? companyData?.tex_id : saleDocInfoData?.company?.tex_id}
                          </span>
                        </div>
                        <div className="tax-identification-number">
                          เบอร์โทรศัพท์{' '}
                          <span>
                            {type == 'render_preview'
                              ? companyData?.contact_number
                              : saleDocInfoData?.company?.contact_number}
                          </span>
                        </div>
                      </div>
                      <div className="seller">
                        <div className="distributor">ลูกค้า</div>
                        <div className="name-seller">
                          {type == 'render_preview' ? saleDocInfoData?.name : saleDocInfoData?.customer?.name}
                        </div>
                        <div className="address">
                          {type == 'render_preview'
                            ? saleDocInfoData?.address + ' สาขา ' + saleDocInfoData?.office
                            : saleDocInfoData?.customer?.address + ' สาขา ' + saleDocInfoData?.customer?.office}
                        </div>
                        <div className="tax-identification-number">
                          เลขประจำตัวผู้เสียภาษี{' '}
                          <span>
                            {type == 'render_preview' ? saleDocInfoData?.tax_id : saleDocInfoData?.customer?.tax_id}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="header-detail-right">
                      <div className="detail-quotation">
                        <div className="top-detail-quotation">
                          <div className="contact-detail">
                            <div className="contact-detail-left">เลขที่ใบเพิ่มหนี้</div>
                            <div className="contact-detail-right">{saleDocInfoData?.doc_id}</div>
                          </div>
                          <div className="contact-detail">
                            <div className="contact-detail-left">วันที่</div>
                            <div className="contact-detail-right">
                              {saleDocInfoData?.doc_date ? formatDate(saleDocInfoData.doc_date) : '-'}
                            </div>
                          </div>
                          <div className="contact-detail">
                            <div className="contact-detail-left">อ้างอิงถึงใบกำกับภาษี</div>
                            <div className="contact-detail-right">{saleDocInfoData?.tax_invoice_id}</div>
                          </div>
                          <div className="contact-detail">
                            <div className="contact-detail-left">วันที่</div>
                            <div className="contact-detail-right">
                              {saleDocInfoData?.tax_invoice_date ? formatDate(saleDocInfoData.tax_invoice_date) : '-'}
                            </div>
                          </div>
                          {/* {saleDocInfoData?.payment_type != 'cash' && (
                            <div className="contact-detail">
                              <div className="contact-detail-left">เครดิต</div>
                              <div className="contact-detail-right">
                                {saleDocInfoData?.credit_day ? saleDocInfoData?.credit_day : '-'}
                              </div>
                            </div>
                          )}

                          {saleDocInfoData?.payment_type == 'credit day and due date' && (
                            <div className="contact-detail">
                              <div className="contact-detail-left">ครบกำหนด</div>
                              <div className="contact-detail-right">
                                {saleDocInfoData?.credit_due_date ? formatDate(saleDocInfoData.credit_due_date) : '-'}
                              </div>
                            </div>
                          )} */}
                          <div className="contact-detail">
                            <div className="contact-detail-left">พนักงานขาย</div>
                            <div className="contact-detail-right">{saleDocInfoData?.seller_name}</div>
                          </div>
                          <div className="contact-detail">
                            <div className="contact-detail-left">เขตการขาย</div>
                            <div className="contact-detail-right">{saleDocInfoData?.sales_territory || '-'}</div>
                          </div>
                        </div>
                        {/* <div className="bottom-detail-quotation">
                          <div className="contact-detail">
                            <div className="contact-detail-left">ผู้ติดต่อ</div>
                            <div className="contact-detail-right">{saleDocInfoData?.contact_person}</div>
                          </div>
                          <div className="contact-detail">
                            <div className="contact-detail-left">เบอร์โทรศัพท์</div>
                            <div className="contact-detail-right">{saleDocInfoData?.contact_phone}</div>
                          </div>
                          <div className="contact-detail">
                            <div className="contact-detail-left">อีเมล</div>
                            <div className="contact-detail-right">{saleDocInfoData?.contact_email}</div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="ref-text">
                    <div>อ้างอิง</div>
                    <div>{saleDocInfoData?.details || '-'}</div>
                  </div>

                  <table>
                    <thead>
                      <tr>
                        {HEADER_TABLE_LIST?.length > 0 &&
                          HEADER_TABLE_LIST.map((item, index) => (
                            <th className={index == 2 ? '' : 'text-center'} key={'header-table-' + index}>
                              {item}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody className="tbody">
                      {saleDocInfoData?.product_list?.length > 0 &&
                        saleDocInfoData?.product_list?.map((item, index) => (
                          <tr key={'item-' + index}>
                            <td>{index + 1 || '-'}</td>
                            <td>{item?.product_name || '-'}</td>
                            <td className={'text-right'}>{convertIntComma(item?.quantity) || '-'}</td>
                            <td className={'text-right'}>{convertNumberToCommaTwoDecimal(item?.price) || '-'}</td>

                            <td className={'text-right'}>
                              {convertNumberToCommaTwoDecimal(item?.quantity * item?.price) || 0}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <div className="calculate">
                    <div className="inner-calculate-left">
                      <div className="wrp-note">
                        <div className="note">หมายเหตุ</div>
                        <div className="note-detail">{saleDocInfoData?.note}</div>
                      </div>

                      {!isNaN(summaryPrice) && summaryPrice !== 0 && (
                        <div className="text-thai">({convertPriceToText(summaryPrice)})</div>
                      )}
                    </div>
                    <div className="inner-calculate-right">
                      <div className="summary">
                        <div className="top-summary">
                          <div className="detail">
                            <div className="detail-left">รวม</div>
                            <div className="detail-right">
                              {/* {!isNaN()}  */}
                              {convertNumberToCommaTwoDecimal(totalPrice)}
                              <div className="text-bath">บาท</div>
                            </div>
                          </div>
                          <div className="detail">
                            <div className="detail-left">หักส่วนลด</div>
                            <div className="detail-right">
                              {convertNumberToCommaTwoDecimal(totalPrice * (saleDocInfoData?.discount_percent / 100))}
                              <div className="text-bath">บาท</div>
                            </div>
                          </div>
                          {/* {saleDocInfoData.discount_percent > 0 && (
                            <div className="detail">
                              <div className="detail-left">ส่วนลด {saleDocInfoData.discount_percent} %</div>
                              <div className="detail-right">
                                {convertNumberToCommaTwoDecimal(discountPrice)}
                                <div className="text-bath">บาท</div>
                              </div>
                            </div>
                          )}
                          {saleDocInfoData.discount_percent > 0 && (
                            <div className="detail">
                              <div className="detail-left">ราคาหลังส่วนลด</div>
                              <div className="detail-right">
                                {convertNumberToCommaTwoDecimal(priceAfterDiscount)}
                                <div className="text-bath">บาท</div>
                              </div>
                            </div>
                          )} */}
                          {/* {saleDocInfoData?.vat && (
                            <div className="detail">
                              <div className="detail-left">ภาษีมูลค่าเพิ่ม 7%</div>
                              <div className="detail-right">
                                {convertNumberToCommaTwoDecimal(priceVat)}
                                <div className="text-bath">บาท</div>
                              </div>
                            </div>
                          )} */}

                          <div className="detail">
                            <div className="detail-left">
                              {saleDocInfoData?.include_tax ? 'ราคาไม่รวมภาษีมูลค่าเพิ่ม' : 'รวมจำนวนเงินทั้งสิ้น'}
                              {priceAfterDiscount}
                            </div>
                            <div className="detail-right">
                              {convertNumberToCommaTwoDecimal(
                                totalPrice * (saleDocInfoData?.discount_percent / 100) + priceAfterDiscount
                              )}
                              <div className="text-bath">บาท</div>
                            </div>
                          </div>
                        </div>
                        <div className="bottom-summary">
                          {/* {saleDocInfoData?.withholding_tax_percent > 0 && (
                            <div className="detail">
                              <div className="detail-left">
                                หักภาษี ณ ที่จ่าย {saleDocInfoData?.withholding_tax_percent} %
                              </div>
                              <div className="detail-right">
                                {convertNumberToCommaTwoDecimal(priceWithHoldingTax)}
                                <div className="text-bath">บาท</div>
                              </div>
                            </div>
                          )} */}
                          <div className="detail">
                            <div className="detail-left">มูลค่าของสินค้าหรือบริการตามใบกำกับภาษีเดิม</div>
                            <div className="detail-right">
                              {convertNumberToCommaTwoDecimal(saleDocInfoData?.old_value)}
                              <div className="text-bath">บาท</div>
                            </div>
                          </div>

                          <div className="detail">
                            <div className="detail-left">มูลค่าของสินค้าหรือบริการที่ถูกต้อง</div>
                            <div className="detail-right">
                              {convertNumberToCommaTwoDecimal(saleDocInfoData?.old_value + priceAfterDiscount)}
                              <div className="text-bath">บาท</div>
                            </div>
                          </div>

                          <div className="detail">
                            <div className="detail-left">ผลต่าง</div>
                            <div className="detail-right">
                              {convertNumberToCommaTwoDecimal(priceAfterDiscount)}
                              <div className="text-bath">บาท</div>
                            </div>
                          </div>

                          {/* {saleDocInfoData?.vat && ( */}
                          <div className="detail">
                            <div className="detail-left">ภาษีมูลค่าเพิ่ม 7%</div>
                            <div className="detail-right">
                              {convertNumberToCommaTwoDecimal(priceVat)}
                              <div className="text-bath">บาท</div>
                            </div>
                          </div>
                          {/* )} */}

                          <div className="detail">
                            <div className="detail-left">จำนวนเงินทั้งสิ้น</div>
                            <div className="detail-right">
                              {convertNumberToCommaTwoDecimal(summaryPrice)}
                              <div className="text-bath">บาท</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div>เงื่อนไขอื่นๆ</div>
              <div className="wrp-condition-detail">
                <div className="convert-html">{textCondition}</div>
              </div> */}
                </div>

                <div className="container-bottom">
                  <SignatureListView
                    rubberStamp={
                      type == 'document_setting' ? settingData[docType]?.rubber_stamp : saleDocInfoData?.rubber_stamp
                    }
                    signatureList={
                      type == 'document_setting'
                        ? settingData[docType]?.signature_list
                        : saleDocInfoData?.signature_list
                    }
                  />
                  {/* <div className="date-and-signature">
                    <div className="row">
                      <div className="left">
                        <div>ผู้สั่งซื้อสินค้า.....................................</div>
                        <div>วันที่................................</div>
                      </div>

                      {linkImage?.url_rubber_stamp ? (
                        <div className="center">
                          <div className="wrp-image-rubber-stamp">
                            <img className="image-rubber-stamp" src={linkImage?.url_rubber_stamp} />
                          </div>
                        </div>
                      ) : (
                        <>
                          {saleDocInfoData?.rubber_stamp &&
                            saleDocInfoData?.signature &&
                            type != 'document_setting' && (
                              <div className="center">
                                <div className="wrp-image-rubber-stamp">
                                  <img
                                    className="image-rubber-stamp"
                                    src={saleDocInfoData?.rubber_stamp + '?' + Math.random()}
                                    crossOrigin="anonymous"
                                  />
                                </div>
                              </div>
                            )}
                        </>
                      )}

                      <div className="right">
                        <div>ผู้อนุมัติสินค้า.....................................</div>
                        <div className="product-approver">
                          {linkImage?.url_signature_file ? (
                            <img className="image-signature-file" src={linkImage?.url_signature_file} />
                          ) : (
                            <>
                              {saleDocInfoData?.signature_image &&
                                saleDocInfoData?.signature &&
                                type != 'document_setting' && (
                                  <img
                                    className="image-signature-file"
                                    src={saleDocInfoData?.signature_image + '?' + Math.random()}
                                    crossOrigin="anonymous"
                                  />
                                )}
                            </>
                          )}
                        </div>
                        <div>วันที่................................</div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </Styled>
  )
}
const Styled = styled.div`
  background-color: #fff;
  width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: ${({ isHaveBorder }) => (isHaveBorder ? '1px solid var(--Gray-300)' : 'none')};

  .wrp-btn-close {
    display: flex;
    justify-content: flex-end;
    .btn-close {
      cursor: pointer;
      padding: 5px;
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    -webkit-print-color-adjust: exact;
    min-height: 1280px;
    border-bottom: ${({ isLineBreakBorder }) => (isLineBreakBorder ? '1px solid var(--Gray-600)' : 'none')};
    .container-top {
      padding: 24px;
    }
  }
  .download-pdf-styled {
    padding: 24px !important;

    break-before: auto;
    break-before: avoid;
    break-before: always;
    break-before: all;
  }
  .container-top {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .number-of-copy-bill {
    text-align: end;
  }
  .header-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .header-type-bill {
    font-size: 22px;
    color: var(--Primary-700);
  }
  .image-company-logo {
    /* width: 50px; */
    height: 100px;
  }

  .header-detail {
    display: flex;
    justify-content: space-between;

    .header-detail-left {
      flex: 1;
      margin-right: 50px;
      .header-company {
        .name-seller,
        .address,
        .tax-identification-number {
          /* padding: 5px 12px 0; */

          color: black;
        }
      }
      .text-header {
        color: var(--Gray-700);
        font-size: 18px;
        font-weight: 600;
      }
      .text-title {
        color: var(--Gray-700);
        font-size: 12px;
        font-weight: 400;
      }
    }

    .seller {
      margin-top: 20px;
      /* div {
        padding: 5px 12px 0;
      } */
      .distributor {
        width: 228px;
        padding: 0 12px;
        background-color: var(--Primary-700);
        color: #fff;
      }
      .name-seller,
      .address,
      .tax-identification-number {
        color: black;
      }
    }

    .header-detail-right {
      width: 350px;

      .top-detail-quotation {
        width: 100%;
        /* border-bottom: 1px solid var(--Gray-200); */
        padding-bottom: 8px;
        color: black;
      }
      .bottom-detail-quotation {
        width: 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
        color: black;
      }
      .contact-detail {
        display: flex;

        .contact-detail-left {
          width: 150px;
        }
        .contact-detail-right {
          flex: 1;
          word-break: break-all;
        }
      }
    }
  }

  table {
    /* background-color: red; */
    width: 100%;
    margin: 19px 0 23px;

    &,
    th,
    td {
      border: 1px solid var(--Gray-200);
      border-collapse: collapse;
    }
    th {
      background-color: var(--Primary-700);
      color: #fff;
    }
    th,
    td {
      text-align: start;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: end;
  }
  .tbody {
    color: black;
  }
  .calculate {
    display: flex;
    justify-content: space-between;

    .inner-calculate-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 20px;

      color: black;
      .wrp-note {
        .note-detail {
          margin-bottom: 20px;
        }
      }
    }
    .inner-calculate-right {
      /* width: 300px; */

      color: black;
      .summary {
        .top-summary {
          border-bottom: 1px solid var(--Gray-200);
          .detail {
            margin-bottom: 5px;
          }
        }
        .bottom-summary {
          .detail {
            margin-top: 5px;
          }
        }

        .detail {
          display: flex;

          .detail-left {
            display: flex;
            margin-right: 20px;
          }
          .detail-right {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            .text-bath {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .container-bottom {
    .date-and-signature {
      .row {
        display: flex;
        justify-content: space-between;
        position: relative;

        .left,
        .right {
          flex: 1;
          flex-direction: column;
          width: fit-content;
          text-align: center;

          color: black;
        }

        .right {
          position: relative;

          .product-approver {
            position: absolute;
            left: 41%;
            bottom: 70%;
            width: 160px;
            max-height: 100px;

            display: flex;
            align-items: flex-end;
            justify-content: center;
          }
          .image-signature-file {
            max-width: 100px;
            max-height: 100px;
          }
        }

        .center {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: absolute;

          left: 0;
          right: 0;
          top: -40px;

          .wrp-image-rubber-stamp {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .image-rubber-stamp {
            max-width: 100px;
            max-height: 100px;
          }
        }
      }
    }
  }

  canvas {
    width: 100% !important;
    height: auto !important;
  }
  .printarea {
    min-height: 1300px;
    display: flex;
    flex-direction: column;
  }
  .ref-text {
    margin-top: 15px;
    color: black;
  }
`
