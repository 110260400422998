import { combineReducers } from 'redux'
import {
  GET_EMPLOYEE_DASHBOARD,
  GET_EMPLOYEE_DASHBOARD_BY_ID,
  GET_SUMMARY_OF_EMPLOYEE_DASHBOARD,
  GET_TASKS_OF_EMPLOYEE_DASHBOARD,
  GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD,
  GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD,
} from '../actionTypes'

const initialState = {
  employeeDashboardList: [],
  employeeDashboardInfo: {},
  summary: {},
  tasks: [],
  currentProjects: [],
  dailySummaryReports: [],
  page: 1,
  pageCount: 1,
  total: 1,
  limit: 10,
  isLoading: false,
  state: '',
}
const employeeDashboardReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_EMPLOYEE_DASHBOARD.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_EMPLOYEE_DASHBOARD.REQUEST',
      }
    case GET_EMPLOYEE_DASHBOARD.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        employeeDashboardList: data,
        page: meta.pagination.page,
        limit: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
        state: 'GET_EMPLOYEE_DASHBOARD.SUCCESS',
      }
    }
    case GET_EMPLOYEE_DASHBOARD.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_EMPLOYEE_DASHBOARD.FAILURE',
      }
    case GET_EMPLOYEE_DASHBOARD_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_EMPLOYEE_DASHBOARD_BY_ID.REQUEST',
      }
    case GET_EMPLOYEE_DASHBOARD_BY_ID.SUCCESS: {
      const { data } = action
      return {
        ...state,
        employeeDashboardInfo: data,
        state: 'GET_EMPLOYEE_DASHBOARD_BY_ID.SUCCESS',
      }
    }
    case GET_EMPLOYEE_DASHBOARD_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_EMPLOYEE_DASHBOARD_BY_ID.FAILURE',
      }
    case GET_SUMMARY_OF_EMPLOYEE_DASHBOARD.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_SUMMARY_OF_EMPLOYEE_DASHBOARD.REQUEST',
      }
    case GET_SUMMARY_OF_EMPLOYEE_DASHBOARD.SUCCESS: {
      const { data } = action
      return {
        ...state,
        summary: data,
        isLoading: false,
        state: 'GET_SUMMARY_OF_EMPLOYEE_DASHBOARD.SUCCESS',
      }
    }
    case GET_SUMMARY_OF_EMPLOYEE_DASHBOARD.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_SUMMARY_OF_EMPLOYEE_DASHBOARD.FAILURE',
      }
    case GET_TASKS_OF_EMPLOYEE_DASHBOARD.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_TASKS_OF_EMPLOYEE_DASHBOARD.REQUEST',
      }
    case GET_TASKS_OF_EMPLOYEE_DASHBOARD.SUCCESS: {
      const { data } = action
      return {
        ...state,
        tasks: data,
        isLoading: false,
        state: 'GET_TASKS_OF_EMPLOYEE_DASHBOARD.SUCCESS',
      }
    }
    case GET_TASKS_OF_EMPLOYEE_DASHBOARD.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_TASKS_OF_EMPLOYEE_DASHBOARD.FAILURE',
      }
    case GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD.REQUEST',
      }
    case GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD.SUCCESS: {
      const { data } = action
      return {
        ...state,
        currentProjects: data,
        isLoading: false,
        state: 'GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD.SUCCESS',
      }
    }
    case GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_CURRENT_PROJECT_OF_EMPLOYEE_DASHBOARD.FAILURE',
      }
    case GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD.REQUEST:
      return {
        ...state,
        isLoading: true,
        state: 'GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD.REQUEST',
      }
    case GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD.SUCCESS: {
      const { data } = action
      return {
        ...state,
        dailySummaryReports: data,
        isLoading: false,
        state: 'GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD.SUCCESS',
      }
    }
    case GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD.FAILURE:
      return {
        ...state,
        isLoading: false,
        state: 'GET_DAILY_SUMMARY_REPORT_OF_EMPLOYEE_DASHBOARD.FAILURE',
      }
    default:
      return state
  }
}

export default combineReducers({
  employeeDashboardReducer,
})
