import { useState, useEffect, useCallback, useRef } from 'react'
import { NumberFormatter } from 'react-number-formatter'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

import { getCompanyInfo } from '../../utils/common'
import { validateEmail } from '../../utils/validation'
import { postAPI } from '../../utils/api'

import styled from 'styled-components'
import AutoComplete from '../../components/form/AutoComplete'
import Dropdown from '../../components/form/Dropdown'
import TextField from '../../components/form/TextField'
import IconButton from '../../components/common/IconButton'
import Button from '../../components/form/button/Button'
import Date from '../../components/form/Date'
import EmployeeSignatureForFormModal from '../../components/signature-modal/EmployeeSignatureForFormModal'
import CollapseMenu from '../../components/common/CollapseMenu'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import FileUpload from '../../components/form/FileUpload'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import DialogFail from '../../components/dialog/DialogFail'

import { GrStatusGoodSmall } from 'react-icons/gr'
import uploadIcon from '../../assets/images/icon-upload-round.svg'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import iconPerson from '../../assets/images/icon-person.svg'
import iconBin from '../../assets/images/icon-bin.svg'
import iconFile from '../../assets/images/icon-file-round.svg'
import iconDelete from '../../assets/images/icon-bin.svg'
import successIcon from '../../assets/images/success-icon.png'
import failIcon from '../../assets/images/fail-icon.png'

import { useDispatch, useSelector } from 'react-redux'
import {
  getEmployeeById,
  updateEmployee,
  UpdateUserAllinOne,
  getEmployeeReturnData,
} from '../../redux/actions/employee'
import { selectEmployee, selectEmployeeProfile, selectMaster } from '../../redux/selectors'
import { getPositionLevelReturnData } from '../../redux/actions/jobPosition'
import { getDistricts, getSubDistricts } from '../../redux/actions/master'
import { clearResponseUploadSignature, clearStateEmployeeProfile } from '../../redux/actions/employeeProfile'

const DivUploadList = styled.div`
  .file {
    width: 100%;
    display: inline-flex;
    padding: 14px;
    margin-top: 16px;
    background: var(--Base-White);
    border: 1px solid var(--Gray-300);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .icon-wrapper {
    width: 32px;
  }

  .detail-wrapper {
    flex: 1;
    margin: 0 14px;
    font-size: 14px;
    overflow: hidden;

    .file-name {
      margin-bottom: 2px;
      text-overflow: ellipsis;
      overflow: hidden;

      a {
        font-weight: 500;
        color: var(--Gray-700);
      }
    }
  }
`
const HiddenInput = styled.input.attrs({ type: 'file' })`
  display: none;
`
const DivErrorMessage = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}
const multiple = false
const error = false
const disabled = false

const EmployeeDiv = styled.div`
  .mr-24-px {
    margin-right: 24px;
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .css_bin_secondary {
    display: inline-block;
    align-self: flex-end;
  }
  .sub_title {
    font-size: 14px;
    color: #000000;
    font-weight: normal;
  }
  .content_header {
    padding: 14px 14px;

    width: 940px;
    position: fixed;
    background: #ffffff;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }

  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }

  .wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      border-bottom: 2px solid #eaecf0;
      color: #667085;
      font-size: 14px;
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      border-bottom: 2px solid var(--Primary-600) !important;
      color: #6941c6;
      background-color: #f9f5ff;
    }
  }

  .wrp-tab-menu div:last-child {
    margin-left: auto;
  }

  .dialog-content {
    padding: 0px 14px;
    padding-top: 128px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }

  .basic-Information-detail {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    padding-top: 10px;
    &.mr-24-px {
      margin-right: 24px;
    }

    .mb-12-px {
      margin-bottom: 12px;
    }

    .topic_fill {
      color: var(--Gray-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .topic_detail {
      font-size: 14px;
      color: #757575;
    }
    .signature-container {
      height: 128px;
      border: 1px solid var(--Gray-200);
      border-radius: 8px;
      padding: 10px;

      img {
        max-height: 100%;
      }

      .action {
        display: inline-block;
        position: relative;
        right: 0;
      }
    }
  }
  .topic_fill {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .basic-Information-detail-v3 {
    display: grid;
    grid-template-columns: repeat(3, 55% [col-start] 20% [col-middle] 20% [col-end]);
    gap: 20px;
    padding-top: 10px;
    .topic_fill {
      color: var(--Gray-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
    .text_css {
      width: 100%;
    }
  }
  .TextField_employee {
    width: 100%;
  }
  .info-detail {
    width: 66%;
  }
  .img-name-member-no {
    width: 34%;
  }
  .photo_padding {
    padding-left: 20px;
  }
  .group_photo {
    display: flex;
  }
  .mg_top_20 {
    margin-top: 20px;
  }
  .address_fill {
    width: 100%;
  }
  .dd_css {
    width: 100%;
  }

  .button_add_major {
    color: #6941c6;
    background-color: #f9f5ff;
    border: 1px solid #e9d7fe;
  }

  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width: 940px;
    background-color: #ffffff;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .div_img_profile {
    display: flex;
    .box_img_profile {
      height: 128px;
      width: 128px;
      position: relative;
    }
    .img-user {
      height: 128px;
      width: 128px;
      border-radius: 50%;
    }
    .box_edit_profile {
      position: absolute;
      bottom: -10px;
      right: 0;
      width: 20px;
    }
  }

  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border: 1.5px solid var(--Gray-300);
    border-radius: 6px;
    font-weight: 500;

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: #17b26a;

      &.inactive {
        color: red;
      }
    }
  }
  .react-number-formatter-dropbtn {
    height: 40px;
  }
`
const FlexContainer = styled.div`
  display: flex;
`
const SignaturePanel = styled.div`
  .heading {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;

    margin-bottom: 10px;
  }

  .image-container {
    height: 128px;
    border: 1px solid var(--Gray-200);
    border-radius: 8px;
    padding: 10px;

    img {
      max-height: 100%;
    }

    .action {
      display: inline-block;
      position: relative;
      right: 0;
    }
  }

  .panel {
    padding: 14px;
    color: var(--Gray-600);
    font-size: 14px;
    background: var(--Base-White);
    border: 1px solid var(--Gray-200);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    text-align: center;
    &:hover {
      cursor: pointer;
    }

    .text {
      color: var(--Gray-600);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin: 0;
    }

    .highlight {
      color: var(--Primary-700);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
  }
`

const EditEmployeeModal = ({ open, onClose, onSubmit, EmployeeId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const CompanyInfo = getCompanyInfo()

  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [successModal2, setsuccessModal2] = useState(false)
  const confirmsuccessModal = () => {
    setsuccessModal(false)
    onSubmit()
  }
  const confirmsuccessModal2 = () => {
    setsuccessModal2(false)
  }
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)
  const confirmFailModal = () => {
    setFailModal(false)
  }

  const { banks, nationalities, departments, provinces, districts, subdistricts } = useSelector(selectMaster)
  const [provinces_option, setProvinces_option] = useState([])
  const [amphure_option, setAmphure_option] = useState([])
  const [tambon_option, setTambon_option] = useState([])

  const [departmentList, setDepartmentList] = useState([])
  const [positionList, setPositionList] = useState([])
  useEffect(() => {
    const newList = departments.map((v) => ({
      text: v?.name,
      value: v?.id,
      positions: v?.positions,
    }))
    setDepartmentList(newList)
  }, [departments])

  const [nationalityList, setNationalityList] = useState([])
  useEffect(() => {
    const newList = nationalities.map((v) => ({
      text: v?.name,
      value: v?.id,
    }))
    setNationalityList(newList)
  }, [nationalities])

  const [bankList, setBankList] = useState([])
  useEffect(() => {
    const newList = banks.map((v) => ({
      text: v?.nam_th,
      value: v?.id,
    }))
    setBankList(newList)
  }, [banks])

  useEffect(() => {
    const newProvinces = provinces.map((v) => ({
      text: v.attributes.name_en,
      value: v.id,
    }))
    setProvinces_option([...newProvinces])
  }, [provinces])
  const onChangeProvince = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, thai_province: v })
    //--- CallAPI Get อำเภอ
    CallGetAmphure(v)
    //--- Clear ค่าอำเภอกับตำบล
    setAmphure_option([])
    setTambon_option([])
  }
  const CallGetAmphure = useCallback(
    async (provinces_id) => {
      if (provinces_id) {
        dispatch(getDistricts(provinces_id))
      }
    },
    [dispatch]
  )

  useEffect(() => {
    const newAmphure = districts.map((v) => ({
      text: v.attributes.name_en,
      value: v.id,
    }))
    setAmphure_option([...newAmphure])
  }, [districts])

  const onChangeAmphure = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, thai_amphure: v })
    //--- CallAPI Get ตำบล
    CallGetTambon(v)
    //--- Clear ค่าอำเภอกับตำบล
    setTambon_option([])
  }
  const CallGetTambon = useCallback(
    async (amphure_id) => {
      if (amphure_id) {
        dispatch(getSubDistricts(amphure_id))
      }
    },
    [dispatch]
  )
  useEffect(() => {
    const newTambon = subdistricts.map((v) => ({
      text: v.attributes.name_en,
      value: v.id,
    }))
    setTambon_option([...newTambon])
  }, [subdistricts])

  const onChangeTambon = (v) => {
    //setNewEmployeeData({...NewEmployeeData, thai_tambon: v});
    const thai_tambon_id = v
    if (thai_tambon_id) {
      const this_tambon = subdistricts.filter((v) => v.id == thai_tambon_id)
      setNewEmployeeData({
        ...NewEmployeeData,
        thai_tambon: thai_tambon_id,
        postcode: this_tambon[0].attributes.zip_code,
      })
    }
  }

  const errorMessage = ''
  const editable = true

  //---- ST Defalse
  useEffect(() => {
    if (EmployeeId) {
      dispatch(getEmployeeById(EmployeeId))
    }
  }, [dispatch, EmployeeId])

  const { employeeInfo } = useSelector(selectEmployee)

  const [NewEmployeeData, setNewEmployeeData] = useState({
    company_employee_id : null,
    employ_id: '', //-- "employ_id": "",
    title: '', //-- "title": "นาย",
    department: null, //-- "department": 1,
    first_name: null, //-- "first_name": "malik",
    last_name: null, //-- "last_name": "srirat",
    first_name_th: null, //-- "first_name": "malik",
    last_name_th: null, //-- "last_name": "srirat",
    photo: null, //-- "photo": 1,
    position: null, //-- "position": 1,
    email: null, //-- "email": "srirat.malik@gmail.com",
    phone_no: null, //-- "phone_no": "0850840288",
    martial_status: '', //-- "martial_status": "single",
    number_of_children: null, //-- "number_of_children": 3,
    nationality: null, //-- "nationality": 1,
    gender: '', //-- "gender": "male",
    birth_date: null, //-- "birth_date": "2023-05-01"
    old_identification_no: null,
    identification_no: null, //-- "identification_no": "12345678901236",
    passport_no: null, //-- "passport_no": "123456",
    bank: null, //--- "bank": 1,
    private_car_plate: null, //-- "private_car_plate": null,
    bank_account_no: null, //-- "bank_account_no": null,
    emergency_contact: null, //-- "emergency_contact": null,
    emergency_phone_no: null, //-- "emergency_phone_no": null,
    visa_no: null, //-- "visa_no": null,
    work_permit_no: null, //-- "work_permit_no": null,
    visa_expire_date: null, //-- "visa_expire_date": null,
    workpermit_expire_date: null, //-- "workpermit_expire_date": null,
    workpermi_no: null, //-- "workpermi_no": null,
    workpermit_file_id: null, //-- "workpermit_file_id": null,
    address: null, //-- "address": "49/13",
    thai_province: null, //-- "thai_province": 1,
    thai_amphure: null, //-- "thai_amphure": 1001,
    thai_tambon: null, //-- "thai_tambon": 100101,
    postcode: null, //-- "postcode": "43160",
    education: [
      {
        school_name: null,
        school_type: 'elementary school',
        entry_year: null,
        graduate_year: null,
      },
      {
        school_name: null,
        school_type: 'junior high school',
        entry_year: null,
        graduate_year: null,
      },
      {
        school_name: null,
        school_type: 'senior hign school',
        entry_year: null,
        graduate_year: null,
      },
    ],
    university: [
      {
        certificate_level: '',
        field_study: null,
        university_name: null,
        entry_year: null,
        graduate_year: null,
      },
    ],
    resume: null, //--- Lise File_ID //-- resume: [1]

    //---- ฟิวใหม่For Phane2 04/09/2023
    supervisors: null,
    position_level: null,
    secondary_job: [],

    //---- ฟิวใหม่ 02/10/2023
    employee_type: null,
    evaluation_date: null,
    employee_type_status: null,
    work_start_date: null,
    work_end_date: null,
  })
  const [employee_user_id, setemployee_user_id] = useState(null)
  const [admin_user_id, setadmin_user_id] = useState(null)

  useEffect(() => {
    if (employeeInfo.id == EmployeeId) {
      const this_department = employeeInfo.department?.data?.id ? employeeInfo.department.data.id : null
      const this_thai_province = employeeInfo.thai_province?.data?.id ? employeeInfo.thai_province.data.id : null
      const this_thai_amphure = employeeInfo.thai_amphure?.data?.id ? employeeInfo.thai_amphure.data.id : null
      const this_thai_tambon = employeeInfo.thai_tambon?.data?.id ? employeeInfo.thai_tambon.data.id : null

      const data_secondary_job = []
      for (const item_secondary_job of employeeInfo.secondary_job) {
        const departmentMatch = departmentList.find((v) => v.value == item_secondary_job.department)
        const newPosition = departmentMatch?.positions.map((v) => ({
          text: v.name,
          value: v.id,
        }))
        data_secondary_job.push({
          department: item_secondary_job.department,
          positionList: newPosition,
          position: item_secondary_job.position,
        })
      }

      if (employeeInfo.employee_user?.data?.id) {
        setemployee_user_id(employeeInfo.employee_user?.data?.id)
      }
      //alert(employeeInfo.admin_user_id)
      setadmin_user_id(employeeInfo.admin_user_id)
      const newEmployee = {
        company_employee_id: employeeInfo.company_employee_id,
        status: employeeInfo.status,
        employ_id: employeeInfo.employ_id,
        display_full_name: employeeInfo.title + employeeInfo.first_name + ' ' + employeeInfo.last_name,
        title: employeeInfo.title,
        department: this_department,
        first_name: employeeInfo.first_name,
        last_name: employeeInfo.last_name,
        first_name_th: employeeInfo.first_name_th,
        last_name_th: employeeInfo.last_name_th,
        photo: employeeInfo.photo,
        position: employeeInfo.position?.data?.id ? employeeInfo.position.data.id : null,
        email: employeeInfo.email,
        phone_no: employeeInfo.phone_no,
        martial_status: employeeInfo.martial_status,
        number_of_children: employeeInfo.number_of_children,
        nationality: employeeInfo.nationality?.data?.id ? employeeInfo.nationality.data.id : null,
        gender: employeeInfo.gender,
        birth_date: employeeInfo.birth_date,
        old_identification_no: employeeInfo.identification_no,
        identification_no: employeeInfo.identification_no,
        passport_no: employeeInfo.passport_no,
        bank: employeeInfo.bank?.data?.id ? employeeInfo.bank.data.id : null,
        private_car_plate: employeeInfo.private_car_plate,
        bank_account_no: employeeInfo.bank_account_no,
        emergency_contact: employeeInfo.emergency_contact,
        emergency_phone_no: employeeInfo.emergency_phone_no,
        visa_no: employeeInfo.visa_no,
        work_permit_no: employeeInfo.work_permit_no,
        visa_expire_date: employeeInfo.visa_expire_date,
        workpermit_expire_date: employeeInfo.workpermit_expire_date,
        workpermi_no: employeeInfo.workpermi_no,
        workpermit_file_id: employeeInfo.workpermit_file_id,
        address: employeeInfo.address,
        thai_province: this_thai_province,
        thai_amphure: this_thai_amphure,
        thai_tambon: this_thai_tambon,
        postcode: null,
        education:
          employeeInfo.employee_educations.data.length > 0
            ? employeeInfo.employee_educations.data.map((v) => ({
                school_name: v.attributes.school_name,
                school_type: v.attributes.school_type,
                entry_year: v.attributes.entry_year,
                graduate_year: v.attributes.graduate_year,
              }))
            : [
                {
                  school_name: null,
                  school_type: 'elementary school',
                  entry_year: null,
                  graduate_year: null,
                },
                {
                  school_name: null,
                  school_type: 'junior high school',
                  entry_year: null,
                  graduate_year: null,
                },
                {
                  school_name: null,
                  school_type: 'senior hign school',
                  entry_year: null,
                  graduate_year: null,
                },
              ],
        university: employeeInfo.employee_universities.data.map((v) => ({
          certificate_level: v.attributes.certificate_level,
          field_study: v.attributes.field_study,
          university_name: v.attributes.university_name,
          entry_year: v.attributes.entry_year,
          graduate_year: v.attributes.graduate_year,
        })),
        resume: employeeInfo.resumes_file.map((v) => v.id),
        //---- ฟิวใหม่For Phane2 04/09/2023
        supervisors: employeeInfo.supervisors?.data[0]?.id ? employeeInfo.supervisors?.data[0]?.id : null,
        position_level: employeeInfo.position_level?.data?.id ? employeeInfo.position_level?.data?.id : null,
        secondary_job: data_secondary_job,
        //---- ฟิวใหม่ 02/10/2023
        employee_type: employeeInfo.employee_type,
        evaluation_date: employeeInfo.evaluation_date,
        employee_type_status: employeeInfo.employee_type_status,
        work_start_date: employeeInfo.work_start_date,
        work_end_date: employeeInfo.work_end_date,
      }
      setNewEmployeeData(newEmployee)
      setImgPerson(employeeInfo.photo_images[0]?.url ? employeeInfo.photo_images[0].url : iconPerson)

      const this_resume = employeeInfo.resumes_file.map((v) => ({
        id: v.id,
        url: v.url,
        name: v.name,
        size: v.size,
      }))
      setFile_resume(this_resume)

      const this_Work_permit = employeeInfo.work_permit_file.map((v) => ({
        id: v.id,
        url: v.url,
        name: v.name,
        size: v.size,
      }))
      setFile_Work_permit(this_Work_permit)

      if (this_thai_province) {
        dispatch(getDistricts(this_thai_province))
        if (this_thai_amphure) {
          dispatch(getSubDistricts(this_thai_amphure))
        }
      }

      if (this_department) {
        const departmentMatch = departmentList.find((v) => v.value == this_department)

        const newPosition = departmentMatch?.positions.map((v) => ({
          text: v.name,
          value: v.id,
        }))
        setPositionList(newPosition)
      }
    }
  }, [employeeInfo, dispatch, departmentList])

  const [NewEmployeeError, setNewEmployeeError] = useState({
    title: {
      show_errorMessage: false,
      errorMessage: 'Fill Title',
    },
    first_name: {
      show_errorMessage: false,
      errorMessage: 'Fill First Name',
    },
    last_name: {
      show_errorMessage: false,
      errorMessage: 'Fill Last Name',
    },
    first_name_th: {
      show_errorMessage: false,
      errorMessage: 'Fill First Name',
    },
    last_name_th: {
      show_errorMessage: false,
      errorMessage: 'Fill Last Name',
    },
    email: {
      show_errorMessage: false,
      errorMessage: 'Fill Email',
    },
    identification_no: {
      show_errorMessage: false,
      errorMessage: 'Fill Identification No.',
    },

    department: {
      show_errorMessage: false,
      errorMessage: '',
    },
    position: {
      show_errorMessage: false,
      errorMessage: '',
    },
    phone_no: {
      show_errorMessage: false,
      errorMessage: '',
    },
    martial_status: {
      show_errorMessage: false,
      errorMessage: '',
    },
    nationality: {
      show_errorMessage: false,
      errorMessage: '',
    },
    gender: {
      show_errorMessage: false,
      errorMessage: '',
    },
    birth_date: {
      show_errorMessage: false,
      errorMessage: '',
    },
    address: {
      show_errorMessage: false,
      errorMessage: '',
    },
    bank: {
      show_errorMessage: false,
      errorMessage: '',
    },
    bank_account_no: {
      show_errorMessage: false,
      errorMessage: '',
    },
  })

  const [positionLevelList, setpositionLevelList] = useState([])
  const [supervisorList, setsupervisorList] = useState([])
  useEffect(() => {
    const filter = {
      skip: 1,
      limit: 1000,
    }
    dispatch(getPositionLevelReturnData(filter)).then(({ type, data_info, data_meta }) => {
      if (type.endsWith('_SUCCESS')) {
        if (data_meta) {
          const newpositionLevelList = data_info.map((v) => ({
            text: v?.name_th,
            value: v?.id,
          }))
          setpositionLevelList(newpositionLevelList)
        }
      }
    })

    dispatch(getEmployeeReturnData(filter)).then(({ type, data_info, data_meta }) => {
      if (type.endsWith('_SUCCESS')) {
        if (data_meta) {
          const newsupervisorList = data_info.map((v) => ({
            text: v?.first_name + ' ' + v?.last_name,
            value: v?.id,
          }))
          setsupervisorList(newsupervisorList)
        }
      }
    })
  }, [])

  const onChangeBirth_date = (value) => {
    setNewEmployeeData({ ...NewEmployeeData, birth_date: value })
  }
  const onChangeWorkPermitExpirationDate = (value) => {
    setNewEmployeeData({ ...NewEmployeeData, workpermit_expire_date: value })
  }
  const onChangeVisaExpireDate = (value) => {
    setNewEmployeeData({ ...NewEmployeeData, visa_expire_date: value })
  }
  const onTitle = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, title: v })
  }
  const onEmployee_type = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, employee_type: v })
  }
  const onChangeEvaluation_date = (value) => {
    setNewEmployeeData({ ...NewEmployeeData, evaluation_date: value })
  }
  const onChangework_start_date = (value) => {
    setNewEmployeeData({ ...NewEmployeeData, work_start_date: value })
  }
  const onemployee_type_status = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, employee_type_status: v })
  }
  const onChangework_end_date = (value) => {
    setNewEmployeeData({ ...NewEmployeeData, work_end_date: value })
  }
  const onDepartment = (inp_v) => {
    //alert()
    const departmentMatch = departmentList.find((v) => v.value == inp_v)

    const newPosition = departmentMatch?.positions.map((v) => ({
      text: v.name,
      value: v.id,
    }))
    setPositionList(newPosition)
    setNewEmployeeData({ ...NewEmployeeData, department: inp_v, position: '' })
  }
  const onJobPosition = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, position: v })
  }
  const onChangeMartialStatus = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, martial_status: v })
  }
  const onChangeNationality = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, nationality: v })
  }
  const onChangeGender = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, gender: v })
  }
  const onChangeBankAccount = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, bank: v })
  }

  const onChangeposition_level = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, position_level: v })
  }

  const onChangeSupervisor = (v) => {
    setNewEmployeeData({ ...NewEmployeeData, supervisors: v })
  }

  //--- ST Education Information
  const onChangeEducationGraduate_year = (index_item, v) => {
    const newState = NewEmployeeData.education.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      // alert(index + ' === ' + index_item )

      if (index === index_item) {
        //alert(JSON.stringify(obj))
        return { ...obj, graduate_year: v }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, education: newState })
  }

  const onChangeEducationEntry_year = (index_item, v) => {
    const newState = NewEmployeeData.education.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, entry_year: v }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, education: newState })
  }

  const onChangeEducationSchool_name = (index_item, e) => {
    const newState = NewEmployeeData.education.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, school_name: e.target.value }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, education: newState })
  }
  //--- ED Education Information

  //--- ST University
  const onChangeCertificate_level = (index_item, v) => {
    const newState = NewEmployeeData.university.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, certificate_level: v }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, university: newState })
  }

  const onChangeField_study = (index_item, e) => {
    const newState = NewEmployeeData.university.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, field_study: e.target.value }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, university: newState })
  }

  const onChangeUniversity_name = (index_item, e) => {
    const newState = NewEmployeeData.university.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, university_name: e.target.value }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, university: newState })
  }

  const onChangeEntry_year = (index_item, v) => {
    const newState = NewEmployeeData.university.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, entry_year: v }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, university: newState })
  }

  const onChangeGraduate_year = (index_item, v) => {
    const newState = NewEmployeeData.university.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, graduate_year: v }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    //setUniversitiesList(newState);
    setNewEmployeeData({ ...NewEmployeeData, university: newState })
  }
  const RemoveUniversities = (index_item) => {
    const currentListCopy = [...NewEmployeeData.university]
    currentListCopy.splice(index_item, 1)
    setNewEmployeeData({ ...NewEmployeeData, university: currentListCopy })
  }

  function handleAddUniversities(currentData) {
    // build a new element with a new ID based off the last element in the array
    let newDataElement = {
      certificate_level: '',
      field_study: null,
      university_name: null,
      entry_year: null,
      graduate_year: null,
    }

    // build a new state object
    //alert(JSON.stringify(currentData))
    const newStateData = [...currentData, newDataElement]

    // update the state
    //setUniversitiesList(newStateData);
    //alert(JSON.stringify(newStateData))
    setNewEmployeeData({ ...NewEmployeeData, university: newStateData })
  }
  //--- ED University

  //--- ST Secondary Job
  const onChangeSecondaryDepartment = (index_item, inp_v) => {
    const departmentMatch = departmentList.find((v) => v.value == inp_v)

    const newPosition = departmentMatch?.positions.map((v) => ({
      text: v.name,
      value: v.id,
    }))

    const newState = NewEmployeeData.secondary_job.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, department: inp_v, positionList: newPosition }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    setNewEmployeeData({ ...NewEmployeeData, secondary_job: newState })
  }

  const onChangeSecondaryPositon = (index_item, inp_v) => {
    const newState = NewEmployeeData.secondary_job.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        return { ...obj, position: inp_v }
      }

      // 👇️ otherwise return the object as is
      return obj
    })

    setNewEmployeeData({ ...NewEmployeeData, secondary_job: newState })
  }

  function handleAddSecondary_job(currentData) {
    // build a new element with a new ID based off the last element in the array
    let newDataElement = {
      department: null,
      positionList: [],
      position: null,
    }

    // build a new state object
    //alert(JSON.stringify(currentData))
    const newStateData = [...currentData, newDataElement]

    // update the state
    //setUniversitiesList(newStateData);
    //alert(JSON.stringify(newStateData))
    setNewEmployeeData({ ...NewEmployeeData, secondary_job: newStateData })
  }

  const RemoveSecondary_job = (index_item) => {
    const currentListCopy = [...NewEmployeeData.secondary_job]
    currentListCopy.splice(index_item, 1)
    setNewEmployeeData({ ...NewEmployeeData, secondary_job: currentListCopy })
  }

  //--- ED Secondary Job

  //--- ST File Photo
  const [file_photo, setFile_photo] = useState([])
  const [ImgPerson, setImgPerson] = useState(iconPerson)
  //const [file, setFile] = useState()

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const handleAddFilePhoto = async (value) => {
    const fetchList = []

    //const base64 = await convertBase64(value)
    //setImgPerson(base64)
    value.forEach((v) => {
      const formData = new FormData()
      formData.append('files', v)
      formData.append(
        'fileInfo',
        JSON.stringify({
          name: v.name,
          caption: v.name,
          alternativeText: v.name,
          folder: null,
        })
      )

      fetchList.push(
        postAPI({
          url: '/api/upload',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: null,
          },
          data: formData,
        })
      )
    })

    const res = await Promise.all(fetchList)

    setImgPerson(res[0][0].url)
    setNewEmployeeData({ ...NewEmployeeData, photo: res[0][0].id })
  }

  const handleDeleteFilePhoto = (id) => {}

  const onChangeFile = async (event) => {
    const file = event.target.files[0]
    //
    //const base64 = await convertBase64(file)
    //
    //setImgPerson(base64)
    //setFile(file)
    const fetchList = []
    const formData = new FormData()
    formData.append('files', file)
    formData.append(
      'fileInfo',
      JSON.stringify({
        name: file.name,
        caption: file.name,
        alternativeText: file.name,
        folder: null,
      })
    )

    fetchList.push(
      postAPI({
        url: '/api/upload',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: null,
        },
        data: formData,
      })
    )
    const res = await Promise.all(fetchList)
    //
    setImgPerson(res[0][0].url)
    setNewEmployeeData({ ...NewEmployeeData, photo: res[0][0].id })
  }
  //--- ED File Photo
  //--- ST File Resume
  const [file_resume, setFile_resume] = useState([])
  const onChangeFileResume = async (event) => {
    const file = event.target.files[0]
    //alert('onChangeFileResume')

    const fetchList = []
    const formData = new FormData()
    formData.append('files', file)
    formData.append(
      'fileInfo',
      JSON.stringify({
        name: file.name,
        caption: file.name,
        alternativeText: file.name,
        folder: null,
      })
    )

    fetchList.push(
      postAPI({
        url: '/api/upload',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: null,
        },
        data: formData,
      })
    )
    const res = await Promise.all(fetchList)
    //alert(res[0])
    //alert(res[0][0].id)
    if (NewEmployeeData.resume) {
      setNewEmployeeData({ ...NewEmployeeData, resume: [...NewEmployeeData.resume, res[0][0].id] })
    } else {
      setNewEmployeeData({ ...NewEmployeeData, resume: [res[0][0].id] })
    }

    setFile_resume([...file_resume, ...res.map((v) => v[0])])
  }

  const onDeleteFile = (id) => {
    setFile_resume(file_resume.filter((v) => v.id !== id))
    setNewEmployeeData({ ...NewEmployeeData, resume: NewEmployeeData.resume.filter((v) => v !== id) })
  }
  //--- ED File Resume

  //--- ST FILE WorkPermitNo
  const onChangeFilework_permit = async (event) => {
    const file = event.target.files[0]
    const fetchList = []
    const formData = new FormData()
    formData.append('files', file)
    formData.append(
      'fileInfo',
      JSON.stringify({
        name: file.name,
        caption: file.name,
        alternativeText: file.name,
        folder: null,
      })
    )

    fetchList.push(
      postAPI({
        url: '/api/upload',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: null,
        },
        data: formData,
      })
    )
    const res = await Promise.all(fetchList)
    setNewEmployeeData({ ...NewEmployeeData, workpermit_file_id: res[0][0].id })
  }
  //--- ED FILE WorkPermitNo

  const CreateEmployee = () => {
    //onSubmit()
    if (isValid().length == 0) {
      /* แบบ Update เฉพาะ Employee ตัวเอง
      const sent_data_api = {
        data: NewEmployeeData,
      }
      dispatch(updateEmployee(EmployeeId, sent_data_api)).then(({ type }) => {
        if(type.endsWith('_SUCCESS')){
          setTitlesuccess('Update successful');
          setSubTitlesuccess('');
          setDescriptionsuccess('');
          setsuccessModal(true)
        }else{
          setTitleFail('Error Update');
          setnameItemFail('');
          setDescriptionFail('');
          setFailModal(true)
        }
      })
      */
      //---- แบบ Updateไปยัง Table อื่นๆ เช่น admin_user,employee_user ไม่ใช่แล้ว By james เพราะอันใหม่เวลาแก้ employee จะแก้แค่ใน employee

      const sent_data_api_allinone = {
        data: {
          employee_user_id: employee_user_id,
          employee_no: NewEmployeeData.employ_id,
          admin_user_id: admin_user_id,
          first_name: NewEmployeeData.first_name,
          last_name: NewEmployeeData.last_name,
          old_id_card: NewEmployeeData.old_identification_no,
          id_card: NewEmployeeData.identification_no,
          email: NewEmployeeData.email,
          phone_no: NewEmployeeData.phone_no,
          department: NewEmployeeData.department,
          position: NewEmployeeData.position,
        },
      }
      dispatch(UpdateUserAllinOne(sent_data_api_allinone)).then(({ type, error_message }) => {
        if (type.endsWith('_SUCCESS')) {
          const sent_data_api = {
            data: NewEmployeeData,
          }
          dispatch(updateEmployee(EmployeeId, sent_data_api)).then(({ type }) => {
            if (type.endsWith('_SUCCESS')) {
              setTitlesuccess('Update successful')
              setSubTitlesuccess('')
              setDescriptionsuccess('')
              setsuccessModal(true)
            } else {
              setTitleFail('Error Update')
              setnameItemFail('')
              setDescriptionFail('')
              setFailModal(true)
            }
          })
        } else {
          setTitleFail('Error Update Employee')
          setnameItemFail('')
          setDescriptionFail(error_message)
          setFailModal(true)
        }
      })

      //onSubmit()
    }
  }

  const isValid = () => {
    let data_valid = NewEmployeeData
    let data_check_valid = NewEmployeeError
    let errors = []
    Object.keys(NewEmployeeError).forEach((k) => {
      let data = data_valid[k]
      if (data_check_valid[k].errorMessage != '') {
        if (!data || data < 0) {
          errors.push(data_check_valid[k].errorMessage)
          data_check_valid[k].show_errorMessage = true
          if (k == 'email') {
            data_check_valid[k].errorMessage = 'Fill Email'
          }
        } else {
          if (k == 'email') {
            //--- valid email
            if (!validateEmail(data)) {
              errors.push(data_check_valid[k].errorMessage)
              data_check_valid[k].errorMessage = 'Email is invalid'
              data_check_valid[k].show_errorMessage = true
            } else {
              data_check_valid[k].show_errorMessage = false
            }
          } else {
            data_check_valid[k].show_errorMessage = false
          }
        }
      }
    })
    setNewEmployeeError({ ...NewEmployeeError })
    return errors
  }

  const [tabactive, setTabactive] = useState('information')
  const [menuTabList, setMenuTabList] = useState([
    {
      name: 'Information',
      value: 'information',
      active_status: true,
    },
    {
      name: 'Position',
      value: 'position',
      active_status: false,
    },
    {
      name: 'Education',
      value: 'education',
      active_status: false,
    },
    {
      name: 'Resume',
      value: 'resume',
      active_status: false,
    },
    {
      name: 'Work Permit',
      value: 'work_permit',
      active_status: false,
    },
  ])
  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }
  //---- ST INFORMATION
  const Title_option = [
    { text: 'Mr.', value: 'Mr.' },
    { text: 'Mrs.', value: 'Mrs.' },
    { text: 'Miss.', value: 'Miss.' },
  ]
  const MartialStatus_option = [
    { text: 'Single', value: 'Single' },
    { text: 'Married', value: 'Married' },
    { text: 'Divorced', value: 'Divorced' },
    { text: 'Windowed', value: 'Windowed' },
    { text: 'Separated', value: 'Separated' },
    { text: 'Domestic', value: 'Domestic' },
  ]
  const Gender_option = [
    { text: 'Male', value: 'Male' },
    { text: 'Female', value: 'Female' },
  ]
  //---- ED INFORMATION
  //---- ST EDUCATION
  const [listYear, setListYear] = useState([])
  useEffect(() => {
    const currentYear = dayjs().year()
    const yearRange = 50
    const yearList = []

    for (let i = currentYear - yearRange; i <= currentYear + yearRange; i++) {
      yearList.push(i)
    }

    const newYearList = yearList.map((v) => ({
      text: v.toString(),
      value: v,
    }))
    setListYear(newYearList)
  }, [])

  const Certificate_Level_option = [
    /*
    { text: "Bachelor's Degree", value: "Bachelor's Degree" },
    { text: "Master's Degree", value: "Master's Degree" },
    { text: 'Doctorate Degree', value: 'Doctorate Degree' },
    */
    { text: 'ปริญญาตรี', value: 'ปริญญาตรี' },
    { text: 'ปริญญาโท', value: 'ปริญญาโท' },
    { text: 'ปริญญาเอก', value: 'ปริญญาเอก' },
  ]
  //---- ED EDUCATION
  //---- ST Position -> Employment Details
  const employee_type_option = [
    { text: 'Permanent', value: 'Permanent' },
    { text: 'Part-Time', value: 'Part-Time' },
    { text: 'Probation', value: 'Probation' },
  ]
  const employee_type_status_option = [
    { text: 'Active', value: 'Active' },
    { text: 'Resign', value: 'Resign' },
  ]
  //---- ED Position -> Employment Details

  const allowedTypes = []
  const acceptType = allowedTypes.reduce((pre, cur) => {
    return {
      ...pre,
      ...acceptList[cur],
    }
  }, {})

  const handleAddFileResume = async (value) => {
    const fetchList = []
    value.forEach((v) => {
      const formData = new FormData()
      formData.append('files', v)
      formData.append(
        'fileInfo',
        JSON.stringify({
          name: v.name,
          caption: v.name,
          alternativeText: v.name,
          folder: null,
        })
      )

      fetchList.push(
        postAPI({
          url: '/api/upload',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: null,
          },
          data: formData,
        })
      )
    })

    const res = await Promise.all(fetchList)
    if (NewEmployeeData.resume) {
      setNewEmployeeData({ ...NewEmployeeData, resume: [...NewEmployeeData.resume, res[0][0].id] })
    } else {
      setNewEmployeeData({ ...NewEmployeeData, resume: [res[0][0].id] })
    }

    setFile_resume([...file_resume, ...res.map((v) => v[0])])
  }

  const onDeleteFileResume = (id) => {
    setFile_resume(file_resume.filter((v) => v.id !== id))
    setNewEmployeeData({ ...NewEmployeeData, resume: NewEmployeeData.resume.filter((v) => v !== id) })
  }

  const [file_Work_permit, setFile_Work_permit] = useState([])
  const handleAddFileWork_permit = async (value) => {
    const fetchList = []
    value.forEach((v) => {
      const formData = new FormData()
      formData.append('files', v)
      formData.append(
        'fileInfo',
        JSON.stringify({
          name: v.name,
          caption: v.name,
          alternativeText: v.name,
          folder: null,
        })
      )

      fetchList.push(
        postAPI({
          url: '/api/upload',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: null,
          },
          data: formData,
        })
      )
    })

    const res = await Promise.all(fetchList)
    setNewEmployeeData({ ...NewEmployeeData, workpermit_file_id: res[0][0].id })

    setFile_Work_permit([...file_Work_permit, ...res.map((v) => v[0])])
  }

  const onDeleteFileWork_permit = (id) => {
    setFile_Work_permit(file_Work_permit.filter((v) => v.id !== id))
    setNewEmployeeData({ ...NewEmployeeData, workpermit_file_id: file_Work_permit.filter((v) => v.id !== id) })
  }

  const inputFileRef = useRef(null)
  const list = [
    {
      text: 'Edit',
      action: () => OpenInputFile(),
    },
  ]
  const OpenInputFile = () => {
    inputFileRef.current.click()
  }

  // signature modal
  const listAction = [
    {
      text: 'Edit',
      action: () => openSignatureModal(),
    },
  ]
  const [signatureModal, setSignatureModal] = useState(false)

  const openSignatureModal = () => {
    setSignatureModal(true)
  }
  const closeSignatureModal = () => {
    setSignatureModal(false)
  }

  // warning modal
  const initTextWarning = {
    headline: t('warning_upload_sign_heading'),
    description: t('warning_upload_sign_sub_heading'),
  }
  const [warningText] = useState(initTextWarning)
  const [isWarningModal, setIsWarningModal] = useState(false)

  // title th
  const initTitleTh = [
    {
      text: 'นาย',
      value: 'Mr.',
    },
    {
      text: 'นางสาว',
      value: 'Miss.',
    },
    {
      text: 'นาง',
      value: 'Mrs.',
    },
  ]

  // employee profile store
  const { response, state, isLoading } = useSelector(selectEmployeeProfile)
  const [signatureImage, setSignatureImage] = useState(null)

  useEffect(() => {
    if (open) {
      if (isLoading === false && state === 'UPLOAD_SIGNATURE_IMAGE.SUCCESS') {
        closeSignatureModal()
        setTitlesuccess('Upload done')
        setSubTitlesuccess('')
        setDescriptionsuccess('')
        setsuccessModal2(true)

        console.log('response when upload signature success', response)
        if (response[0]) {
          const { url, id } = response[0]
          setSignatureImage(url)
          setNewEmployeeData((prev) => ({
            ...prev,
            signature: id,
          }))
        }
      }
      if (isLoading === false && state === 'UPLOAD_SIGNATURE_IMAGE.FAILURE') {
        closeSignatureModal()
        setTitleFail('Upload Fail')
        setnameItemFail('')
        setDescriptionFail('')
        setFailModal(true)

        setSignatureImage(null)
        setNewEmployeeData((prev) => ({
          ...prev,
          signature: null,
        }))
      }
      dispatch(clearStateEmployeeProfile())
    } else {
      dispatch(clearResponseUploadSignature())
      dispatch(clearStateEmployeeProfile())
    }
  }, [open, state, isLoading])

  useEffect(() => {
    console.log('employeeInfo.employee_signature', employeeInfo.employee_signature)
    if (employeeInfo.employee_signature) {
      const { signature } = employeeInfo.employee_signature
      setSignatureImage(signature.url)
    } else setSignatureImage(null)
  }, [employeeInfo.employee_signature])

  useEffect(() => {
    console.log('signatureImage', signatureImage)
  }, [signatureImage])

  return (
    <EmployeeDiv>
      <div className="content_header">
        <header>
          <div className="content-title">Edit Employee</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="wrp-tab-menu">
          {menuTabList?.map((item, index) => (
            <button
              id={'this_name-' + item.name}
              key={'btn-' + index}
              className={item.active_status ? 'menu-active' : 'btn-menu-style'}
              onClick={() => handleClickTaskMenuTab(item)}
            >
              {item.name}
            </button>
          ))}
          <div className="css_ontop_right">
            <div className="status-badge">
              <GrStatusGoodSmall className={`status ${NewEmployeeData.status == 'inactive' ? 'inactive' : ''}`} />
              {NewEmployeeData.status}
            </div>
          </div>
        </div>
      </div>
      <div className="dialog-content">
        {tabactive == 'information' && (
          <div>
            <div>
              <div className="title">
                <div>Basic Information</div>
                <hr />
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Title (EN)*</div>
                  <div>
                    <Dropdown
                      id={`title`}
                      className="dd_employee"
                      value={NewEmployeeData.title}
                      optionList={Title_option}
                      onChange={onTitle}
                      error={Boolean(NewEmployeeError.title.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.title.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.title.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('first_name')}</div>
                  <div>
                    <TextField
                      name="first_name"
                      className="TextField_employee"
                      placeholder={t('first_name')}
                      value={NewEmployeeData.first_name}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, first_name: e.target.value })}
                      error={Boolean(NewEmployeeError.first_name.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.first_name.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.first_name.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('last_name')}</div>
                  <div>
                    <TextField
                      name="last_name"
                      className="TextField_employee"
                      placeholder={t('last_name')}
                      value={NewEmployeeData.last_name}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, last_name: e.target.value })}
                      error={Boolean(NewEmployeeError.last_name.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.last_name.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.last_name.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>

              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Title (TH)*</div>
                  <div>
                    <Dropdown
                      id={`title`}
                      className="dd_employee"
                      value={NewEmployeeData.title}
                      optionList={initTitleTh}
                      onChange={onTitle}
                      error={Boolean(NewEmployeeError.title.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.title.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.title.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('first_name_th')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('first_name_th')}
                      value={NewEmployeeData.first_name_th}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, first_name_th: e.target.value })}
                      error={Boolean(NewEmployeeError.first_name_th.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.first_name_th.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.first_name_th.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('last_name_th')}</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder={t('last_name_th')}
                      value={NewEmployeeData.last_name_th}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, last_name_th: e.target.value })}
                      error={Boolean(NewEmployeeError.last_name_th.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.last_name_th.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.last_name_th.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>

              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Phone number</div>
                  <div>
                    <NumberFormatter
                      onlyCountries="THA"
                      defaultCountry="TH"
                      placeholder={'+66 (000) 000 0000'}
                      value={NewEmployeeData.phone_no}
                      initialFormat={true}
                      getValue={(n) => setNewEmployeeData({ ...NewEmployeeData, phone_no: n })}
                    />

                    {NewEmployeeError.phone_no.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.phone_no.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Email</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder="Email"
                      value={NewEmployeeData.email}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, email: e.target.value })}
                      error={Boolean(NewEmployeeError.email.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.email.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.email.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Identification no</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder="Identification no"
                      value={NewEmployeeData.identification_no}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, identification_no: e.target.value })}
                      error={Boolean(NewEmployeeError.identification_no.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.identification_no.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.identification_no.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mg_top_20">
              <div className="title">
                <div>Photo and Signature</div>
                <hr />
              </div>

              <FlexContainer>
                <div className="mr-24-px">
                  <div className="topic_fill">{t('profile_photo')}</div>
                  {NewEmployeeData.photo && (
                    <div className="div_img_profile">
                      <div className="box_img_profile">
                        <img className="img-user" src={ImgPerson} />
                        <div className="box_edit_profile">
                          <CollapseMenu list={list} />
                        </div>
                        <HiddenInput ref={inputFileRef} id="custom-file-input" onChange={onChangeFile} />
                      </div>
                    </div>
                  )}
                  {!NewEmployeeData.photo && (
                    <div>
                      <FileUpload
                        multiple={multiple}
                        accept={acceptType}
                        file={file_photo.map((v) => ({ ...v, url: `${v.url}` }))}
                        onAddFile={handleAddFilePhoto}
                        onDeleteFile={handleDeleteFilePhoto}
                        error={error}
                        disabled={disabled}
                      />
                    </div>
                  )}
                </div>

                <SignaturePanel>
                  <div className="heading">Signature</div>

                  {signatureImage ? (
                    <div className="image-container">
                      <img src={signatureImage} alt="signature image" />
                      <CollapseMenu className="action" list={listAction} />
                    </div>
                  ) : (
                    <div className="panel" onClick={openSignatureModal}>
                      <img src={uploadIcon} alt="upload icon" />
                      <p className="text">
                        <b className="highlight">Click to upload</b> or drag and drop
                      </p>
                    </div>
                  )}
                </SignaturePanel>
              </FlexContainer>
            </div>

            <div className="mg_top_20">
              <div className="title">
                <div>Family & Citizenship Information</div>
                <hr />
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Martial status</div>
                  <div>
                    <Dropdown
                      className="dd_employee"
                      value={NewEmployeeData.martial_status}
                      optionList={MartialStatus_option}
                      onChange={onChangeMartialStatus}
                      error={Boolean(NewEmployeeError.martial_status.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.martial_status.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.martial_status.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Number of children</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder="Number of Children"
                      value={NewEmployeeData.number_of_children}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, number_of_children: e.target.value })}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Nationality</div>
                  <div>
                    <Dropdown
                      className="dd_employee"
                      value={NewEmployeeData.nationality}
                      optionList={nationalityList}
                      onChange={onChangeNationality}
                      error={Boolean(NewEmployeeError.nationality.show_errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                    {NewEmployeeError.nationality.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.nationality.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Gender</div>
                  <div>
                    <Dropdown
                      className="dd_employee"
                      value={NewEmployeeData.gender}
                      optionList={Gender_option}
                      onChange={onChangeGender}
                      error={Boolean(NewEmployeeError.gender.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.gender.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.gender.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Identification no.</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder="Identification No."
                      value={NewEmployeeData.identification_no}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, identification_no: e.target.value })}
                      error={Boolean(NewEmployeeError.identification_no.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.identification_no.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.identification_no.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Passport No.</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder="Passport No."
                      value={NewEmployeeData.passport_no}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, passport_no: e.target.value })}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Date of birth</div>
                  <div>
                    <Date
                      className={'input-calendar'}
                      value={NewEmployeeData.birth_date}
                      onChange={onChangeBirth_date}
                      error={Boolean(NewEmployeeError.birth_date.show_errorMessage)}
                    />
                    {NewEmployeeError.birth_date.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.birth_date.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mg_top_20">
              <div className="title">
                <div>Private Contact</div>
                <hr />
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Address</div>
                  <div>
                    <TextField
                      placeholder="Address"
                      className="address_fill"
                      value={NewEmployeeData.address}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, address: e.target.value })}
                      error={Boolean(NewEmployeeError.address.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.address.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.address.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Province</div>
                  <div className="css_autocom_address">
                    <AutoComplete
                      optionList={provinces_option}
                      value={NewEmployeeData.thai_province}
                      error={Boolean(errorMessage)}
                      onChange={onChangeProvince}
                    />
                    {/*
                      <Dropdown
                        className="address_file_dd"
                        value={NewEmployeeData.thai_province}
                        optionList={provinces_option}
                        onChange={onChangeProvince}
                        error={Boolean(errorMessage)}
                        disabled={!editable}
                      />
                      */}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">District</div>
                  <div className="css_autocom_address">
                    <AutoComplete
                      optionList={amphure_option}
                      value={NewEmployeeData.thai_amphure}
                      error={Boolean(errorMessage)}
                      onChange={onChangeAmphure}
                    />
                    {/*
                    <Dropdown
                        className="address_file_dd"
                        value={NewEmployeeData.thai_amphure}
                        optionList={amphure_option}
                        onChange={onChangeAmphure}
                        error={Boolean(errorMessage)}
                        disabled={!editable}
                    />
                    */}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Sub-district</div>
                  <div className="css_autocom_address">
                    <AutoComplete
                      optionList={tambon_option}
                      value={NewEmployeeData.thai_tambon}
                      error={Boolean(errorMessage)}
                      onChange={onChangeTambon}
                    />
                    {/*
                      <Dropdown
                        className="address_file_dd"
                        value={NewEmployeeData.thai_tambon}
                        optionList={tambon_option}
                        onChange={onChangeTambon}
                        error={Boolean(errorMessage)}
                        disabled={!editable}
                      />
                       */}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Passcode</div>
                  <div>
                    <TextField
                      placeholder="PostCode"
                      className="address_fill"
                      value={NewEmployeeData.postcode}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, postcode: e.target.value })}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Bank account</div>
                  <div>
                    <Dropdown
                      className="dd_employee"
                      value={NewEmployeeData.bank}
                      optionList={bankList}
                      onChange={onChangeBankAccount}
                      error={Boolean(NewEmployeeError.bank.show_errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                    {NewEmployeeError.bank.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.bank.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Account number</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder="Account Number"
                      value={NewEmployeeData.bank_account_no}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, bank_account_no: e.target.value })}
                      error={Boolean(NewEmployeeError.bank_account_no.show_errorMessage)}
                      disabled={!editable}
                    />
                    {NewEmployeeError.bank_account_no.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.bank_account_no.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Private car plate</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder="Private Car Plate"
                      value={NewEmployeeData.private_car_plate}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, private_car_plate: e.target.value })}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Emergency contact</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder="Emergency Contact"
                      value={NewEmployeeData.emergency_contact}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, emergency_contact: e.target.value })}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Emergency phone</div>
                  <div>
                    <TextField
                      className="TextField_employee"
                      placeholder="Emergency Phone"
                      value={NewEmployeeData.emergency_phone_no}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, emergency_phone_no: e.target.value })}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        )}
        {tabactive == 'position' && (
          <div>
            <div>
              <div className="title">
                <div>Employment Details</div>
                <div className="sub_title">Details of emploment</div>
                <hr />
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Company Employee ID</div>
                  <div>
                    <TextField
                      name="company_employee_id"
                      className="TextField_employee"
                      placeholder={'Company Employee ID'}
                      value={NewEmployeeData.company_employee_id}
                      onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, company_employee_id: e.target.value })}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Employee Type</div>
                  <div>
                    <Dropdown
                      id={`employee_type`}
                      className="dd_employee"
                      value={NewEmployeeData.employee_type}
                      optionList={employee_type_option}
                      onChange={onEmployee_type}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Work Start Date</div>
                  <div>
                    <Date
                      className={'input-calendar'}
                      value={NewEmployeeData.work_start_date}
                      onChange={onChangework_start_date}
                      error={false}
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">Evaluation Date</div>
                  <div>
                    <Date
                      className={'input-calendar'}
                      value={NewEmployeeData.evaluation_date}
                      onChange={onChangeEvaluation_date}
                      error={false}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Employee Status</div>
                  <div>
                    <Dropdown
                      id={`employee_type_status`}
                      className="dd_employee"
                      value={NewEmployeeData.employee_type_status}
                      optionList={employee_type_status_option}
                      onChange={onemployee_type_status}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">Work End Date</div>
                  <div>
                    <Date
                      className={'input-calendar'}
                      value={NewEmployeeData.work_end_date}
                      onChange={onChangework_end_date}
                      error={false}
                      disabled={!editable}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mg_top_20">
              <div className="title">
                <div>{t('primary_job_position')}</div>
                <div className="sub_title">{t('Your_main_job_in_this_company')}</div>
                <hr />
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('department')}</div>
                  <div>
                    <Dropdown
                      id={`department`}
                      className="dd_employee"
                      value={NewEmployeeData.department}
                      optionList={departmentList}
                      onChange={onDepartment}
                      error={Boolean(NewEmployeeError.department.show_errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                    {NewEmployeeError.department.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.department.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('job_position')}</div>
                  <div>
                    <Dropdown
                      id={`position`}
                      className="dd_employee"
                      value={NewEmployeeData.position}
                      optionList={positionList}
                      onChange={onJobPosition}
                      error={Boolean(NewEmployeeError.position.show_errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                    {NewEmployeeError.position.show_errorMessage == true && (
                      <DivErrorMessage>{NewEmployeeError.position.errorMessage}</DivErrorMessage>
                    )}
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail">
                <div>
                  <div className="topic_fill">{t('position_level')}</div>
                  <div>
                    <Dropdown
                      id={`department`}
                      className="dd_employee"
                      value={NewEmployeeData.position_level}
                      optionList={positionLevelList}
                      onChange={onChangeposition_level}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('supervisor')}</div>
                  <div>
                    <AutoComplete
                      optionList={supervisorList}
                      value={NewEmployeeData.supervisors}
                      error={Boolean(errorMessage)}
                      onChange={onChangeSupervisor}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mg_top_20">
              <div className="title">
                <div>{t('secondary_job_position_If_any')}</div>
                <div className="sub_title">{t('your_second_position_in_this_company')}</div>
                <hr />
              </div>
              {NewEmployeeData.secondary_job?.map((item, index) => (
                <div key={index}>
                  <div className="basic-Information-detail">
                    <div>
                      <div className="topic_fill">{t('department')}</div>
                      <div>
                        <Dropdown
                          id={`Department_` + index}
                          className="dd_css"
                          value={item.department}
                          optionList={departmentList}
                          onChange={(v) => onChangeSecondaryDepartment(index, v)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                          ValueplaceHolderIsNull={true}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">{t('job_position')}</div>
                      <div>
                        <Dropdown
                          id={`Position_` + index}
                          className="dd_css"
                          value={item.position}
                          optionList={item.positionList}
                          onChange={(v) => onChangeSecondaryPositon(index, v)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                          ValueplaceHolderIsNull={true}
                        />
                      </div>
                    </div>
                    <div className="css_bin_secondary">
                      <img src={iconDelete} title="Delete" onClick={() => RemoveSecondary_job(index)} />
                    </div>
                  </div>
                </div>
              ))}
              <div className="basic-Information-detail">
                <div>
                  <Button
                    className="button_add_major"
                    onClick={() => handleAddSecondary_job(NewEmployeeData.secondary_job)}
                  >
                    {'+ more position'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {tabactive == 'education' && (
          <div>
            <div>
              <div className="title">
                <div>{t('education_information')}</div>
                <hr />
              </div>
              <div className="basic-Information-detail-v3">
                <div>
                  <div className="topic_fill">{t('elementary_school')}</div>
                  <div>
                    <TextField
                      placeholder={t('elementary_school')}
                      className="text_css"
                      value={NewEmployeeData.education[0].school_name}
                      onChange={(e) => onChangeEducationSchool_name(0, e)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('entry_year')}</div>
                  <div>
                    <Dropdown
                      className="dropdrow_css"
                      value={NewEmployeeData.education[0].entry_year}
                      optionList={listYear}
                      onChange={(v) => onChangeEducationEntry_year(0, v)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('graduate_year')}</div>
                  <div>
                    <Dropdown
                      className="dropdrow_css"
                      value={NewEmployeeData.education[0].graduate_year}
                      optionList={listYear}
                      onChange={(v) => onChangeEducationGraduate_year(0, v)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail-v3">
                <div>
                  <div className="topic_fill">{t('junior_high_school')}</div>
                  <div>
                    <TextField
                      placeholder={t('junior_high_school')}
                      className="text_css"
                      value={NewEmployeeData.education[1].school_name}
                      onChange={(e) => onChangeEducationSchool_name(1, e)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('entry_year')}</div>
                  <div>
                    <Dropdown
                      className="dropdrow_css"
                      value={NewEmployeeData.education[1].entry_year}
                      optionList={listYear}
                      onChange={(v) => onChangeEducationEntry_year(1, v)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('graduate_year')}</div>
                  <div>
                    <Dropdown
                      className="dropdrow_css"
                      value={NewEmployeeData.education[1].graduate_year}
                      optionList={listYear}
                      onChange={(v) => onChangeEducationGraduate_year(1, v)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
              </div>
              <div className="basic-Information-detail-v3">
                <div>
                  <div className="topic_fill">{t('senior_high_school')}</div>
                  <div>
                    <TextField
                      placeholder={t('senior_high_school')}
                      className="text_css"
                      value={NewEmployeeData.education[2].school_name}
                      onChange={(e) => onChangeEducationSchool_name(2, e)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('entry_year')}</div>
                  <div>
                    <Dropdown
                      className="dropdrow_css"
                      value={NewEmployeeData.education[2].entry_year}
                      optionList={listYear}
                      onChange={(v) => onChangeEducationEntry_year(2, v)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
                <div>
                  <div className="topic_fill">{t('graduate_year')}</div>
                  <div>
                    <Dropdown
                      className="dropdrow_css"
                      value={NewEmployeeData.education[2].graduate_year}
                      optionList={listYear}
                      onChange={(v) => onChangeEducationGraduate_year(2, v)}
                      error={Boolean(errorMessage)}
                      disabled={!editable}
                      ValueplaceHolderIsNull={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mg_top_20">
              <div className="title">
                <div>{t('university')}</div>
                <hr />
              </div>
              {NewEmployeeData.university?.map((item, index) => (
                <div key={index}>
                  <div className="basic-Information-detail">
                    <div>
                      <div className="topic_fill">{t('certificate_level')}</div>
                      <div>
                        <Dropdown
                          id={`certificate_level_` + index}
                          className="dd_css"
                          value={item.certificate_level}
                          optionList={Certificate_Level_option}
                          onChange={(v) => onChangeCertificate_level(index, v)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">{t('Field_of_study')}</div>
                      <div>
                        <TextField
                          name={`field_study_` + index}
                          placeholder={t('Field_of_study')}
                          className="dd_css"
                          value={item.field_study}
                          onChange={(e) => onChangeField_study(index, e)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">{t('university')}</div>
                      <div>
                        <TextField
                          name={`university_name_` + index}
                          placeholder={t('university')}
                          className="dd_css"
                          value={item.university_name}
                          onChange={(e) => onChangeUniversity_name(index, e)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                          ValueplaceHolderIsNull={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="basic-Information-detail">
                    <div>
                      <div className="topic_fill">{t('entry_year')}</div>
                      <div>
                        <Dropdown
                          id={`university_entry_year_` + index}
                          className="dd_css"
                          value={item.entry_year}
                          optionList={listYear}
                          onChange={(v) => onChangeEntry_year(index, v)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                          ValueplaceHolderIsNull={true}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="topic_fill">{t('graduate_year')}</div>
                      <div>
                        <Dropdown
                          id={`university_graduate_year_` + index}
                          value={item.graduate_year}
                          optionList={listYear}
                          onChange={(v) => onChangeGraduate_year(index, v)}
                          error={Boolean(errorMessage)}
                          disabled={!editable}
                          ValueplaceHolderIsNull={true}
                        />
                      </div>
                    </div>
                    <div className="css_bin_secondary">
                      <img src={iconDelete} title="Delete" onClick={() => RemoveUniversities(index)} />
                    </div>
                  </div>
                </div>
              ))}
              <div className="basic-Information-detail">
                <div>
                  <Button
                    className="button_add_major"
                    onClick={() => handleAddUniversities(NewEmployeeData.university)}
                  >
                    {t('add_major')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {tabactive == 'resume' && (
          <div>
            <div className="title">
              <div>{t('resume')}</div>
              <hr />
            </div>
            <div className="basic-Information-detail">
              <div>
                <FileUpload
                  multiple={multiple}
                  accept={acceptType}
                  file={file_resume.map((v) => ({ ...v, url: `${v.url}` }))}
                  onAddFile={handleAddFileResume}
                  onDeleteFile={onDeleteFileResume}
                  error={error}
                  disabled={disabled}
                />
              </div>
              <div></div>
            </div>
          </div>
        )}
        {tabactive == 'work_permit' && (
          <div>
            <div className="title">
              <div>{t('work_permit')}</div>
              <hr />
            </div>
            <div className="basic-Information-detail">
              <div>
                <div className="topic_fill">{t('visa_no')}.</div>
                <div>
                  <TextField
                    placeholder={t('visa_no')}
                    className="TextField_employee"
                    value={NewEmployeeData.visa_no}
                    onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, visa_no: e.target.value })}
                    error={Boolean(errorMessage)}
                    disabled={!editable}
                  />
                </div>
              </div>
              <div>
                <div className="topic_fill">{t('visa_expire_date')}</div>
                <div>
                  <Date
                    className={'input-calendar'}
                    value={NewEmployeeData.visa_expire_date}
                    onChange={onChangeVisaExpireDate}
                  />
                </div>
              </div>
            </div>
            <div className="basic-Information-detail">
              <div>
                <div className="topic_fill">{t('work_permit_no')}.</div>
                <div>
                  <TextField
                    name="work_permit_no"
                    placeholder={t('work_permit_no')}
                    className="TextField_employee"
                    value={NewEmployeeData.work_permit_no}
                    onChange={(e) => setNewEmployeeData({ ...NewEmployeeData, work_permit_no: e.target.value })}
                    error={Boolean(errorMessage)}
                    disabled={!editable}
                  />
                </div>
              </div>
              <div>
                <div className="topic_fill">{t('work_permit_expire_date')}</div>
                <div>
                  <Date
                    className={'input-calendar'}
                    value={NewEmployeeData.workpermit_expire_date}
                    onChange={onChangeWorkPermitExpirationDate}
                  />
                </div>
              </div>
            </div>
            <div className="basic-Information-detail">
              <div>
                <div className="topic_fill">{t('upload_file')}</div>
                <div className="topic_detail">หากมีมากกว่า 1 ฉบับ ให้รวมเป็นไฟล์เดียว</div>
                {file_Work_permit.length > 0 && (
                  <div>
                    <DivUploadList>
                      {file_Work_permit?.map((v) => (
                        <div className="file" key={v.id}>
                          <div className="icon-wrapper">
                            <img src={iconFile} />
                          </div>
                          <div className="detail-wrapper">
                            <div className="file-name">
                              <a href={v.url} target="_blank" rel="noreferrer">
                                {v.name}
                              </a>
                            </div>
                            <div className="file-size">{v.size} KB</div>
                          </div>
                          <div className="action-wrapper">
                            <IconButton src={iconBin} title="Delete" onClick={() => onDeleteFileWork_permit(v.id)} />
                          </div>
                        </div>
                      ))}
                    </DivUploadList>
                  </div>
                )}
                {file_Work_permit.length == 0 && (
                  <div>
                    <FileUpload
                      multiple={multiple}
                      accept={acceptType}
                      file={file_Work_permit.map((v) => ({ ...v, url: `${v.url}` }))}
                      onAddFile={handleAddFileWork_permit}
                      onDeleteFile={onDeleteFileWork_permit}
                      error={error}
                      disabled={disabled}
                    />
                  </div>
                )}
              </div>
              <div></div>
            </div>
          </div>
        )}
      </div>
      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={onClose}>
          {t('cancel')}
        </ButtonOutline>
        <Button className="button_margin" onClick={CreateEmployee}>
          {t('save')}
        </Button>
      </div>

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={confirmsuccessModal}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

      <DialogSuccess
        open={Boolean(successModal2)}
        onClose={() => setsuccessModal2(false)}
        onSubmit={confirmsuccessModal2}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={confirmFailModal}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />

      {/* Modal */}
      <EmployeeSignatureForFormModal
        open={signatureModal}
        onClose={closeSignatureModal}
        setIsWarningModal={setIsWarningModal}
      />
    </EmployeeDiv>
  )
}

export default EditEmployeeModal
