import { DialogTitle, IconButton, Modal, ModalClose, ModalDialog, Tooltip } from '@mui/joy'
import { Container as MuiContainer, Stack, TextField } from '@mui/material'
import { styled } from '@mui/system'
import { forwardRef, useCallback, useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { BsPip } from 'react-icons/bs'
import { CgArrowsExpandLeft } from 'react-icons/cg'
import { RiAddLine, RiDeleteBinLine, RiEyeLine } from 'react-icons/ri'

import Button from 'components/common/Button'
import Chip from 'components/common/Chip'
import Tabs from 'components/common/Tabs'
import Typography from 'components/common/Typography'
import ViewTaskTpl from 'components/feature/operation-process/form/task-tpl/view'
import DynamicForm from 'components/form2/dynamic-form'
import Input, { InputAdornment } from 'components/form2/form-input'
import { Textarea } from 'components/form2/form-textarea'
import { Autocomplete, FieldLabel } from 'components/form2/renderFields'
import { SOW_TEMPLATE } from 'constants/operation-process'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'

const initModalProps = {
  task: {
    id: null,
    open: false,
    layout: 'center',
  },
}

const CreateSowTpl = forwardRef(function CreateSowTpl(props, formRef) {
  const { onSubmit, id, readonly, containerProps, noExtraActions } = props
  const lang = localStorage.getItem('lang') || 'en'
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [positionOptions, setPositionOptions] = useState([])
  const [previewModalProps, setPreviewModalProps] = useState(initModalProps)

  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)

  const { extra: extraActions, sowTpl: sowTplActions, taskTemp: taskTplActions } = opActions()
  const { departments, docTypes, sowTpl, taskTemps, taskTemp } = opProcSelector

  const { getExtra } = extraActions()
  const { getSowTpl, postSowTpl, deleteSowTpl, clearSowTpl } = sowTplActions()
  const { getTaskTemp: getTaskTpl, clearTaskTemp } = taskTplActions()

  const fetchSowTplByID = async (sowTplId) => {
    return dispatch(getSowTpl(sowTplId).byId())
  }

  const fetchDocumentTypes = useCallback(
    async (payload) => {
      return dispatch(getExtra().docTypes(payload))
    },
    [dispatch]
  )

  const fetchDepartments = useCallback(
    async (payload) => {
      return dispatch(getExtra().departments(payload))
    },
    [dispatch]
  )

  const fetchTaskTpls = useCallback(
    async (payload) => {
      return dispatch(getTaskTpl().list({ ...payload, pagination: { page: 1, pageSize: 1000 } }))
    },
    [dispatch]
  )

  const fetchSowTpls = useCallback(
    async (payload) => {
      return dispatch(getSowTpl().list(payload))
    },
    [dispatch]
  )

  const postActiveSowTpl = async (sowTplId) => {
    return dispatch(postSowTpl(sowTplId).active())
  }

  const postInActiveSowTpl = async (sowTplId) => {
    return dispatch(postSowTpl(sowTplId).inactive())
  }

  const defaultValues = {
    name: '',
    description: '',
    hr: 0,
    min: 0,
    department: undefined,
    positions: [],
    deliveries: [],
  }

  const methods = useForm({
    defaultValues: async () => {
      await fetchDepartments()
      await fetchDocumentTypes()
      await fetchTaskTpls()

      if (id) {
        const response = await fetchSowTplByID(id)

        if (response?.data) {
          return response.data
        }

        return defaultValues
      }

      return defaultValues
    },
  })

  const watchDepartment = methods.watch('department')
  const watchDeliveries = methods.watch('deliveries')

  const handleToggleModal = (modalKey, isOpen) => {
    setPreviewModalProps((prev) => ({
      ...prev,
      [modalKey]: {
        ...prev[modalKey],
        open: isOpen || !prev[modalKey].open,
      },
    }))
  }

  const handlePreviewTask = (taskId) => {
    setPreviewModalProps((prev) => {
      return {
        ...prev,
        task: {
          ...prev.task,
          id: taskId,
          open: true,
        },
      }
    })
  }

  const formSchema = {
    properties: {
      'section-1': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('detail')}</Typography>
            <Typography variant="sectionText">{t('sow_tpl_detail_subtitle')}</Typography>
          </HeaderSection>
        ),
      },
      'divider-1': { type: 'divider' },
      name: {
        type: 'text',
        label: t('sow_tpl_name'),
        placeholder: t('sow_tpl_name_placeholder'),
        validator: {
          required: true,
          message: 'This field is required',
          minLength: 1,
          maxLength: 50,
        },
      },
      description: {
        type: 'textArea',
        label: t('sow_tpl_guideline'),
        props: {
          minRows: 3,
          maxRows: 5,
          multiline: true,
        },
      },
      duration: {
        component: (
          <section key="duration">
            <FieldLabel key="duration" name={t('duration')} isRequired />

            <DurationField>
              {readonly ? (
                `${methods.getValues('hr')} ${t('time_hr')} ${methods.getValues('min')} ${t('time_min')}`
              ) : (
                <>
                  <Input
                    {...methods.register(`hr`, { required: `กรุณากรอกจำนวนชั่วโมง` })}
                    type="number"
                    sx={{ width: '100%' }}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10)

                      if (value < 0) {
                        methods.setValue(`hr`, 0)
                        return
                      }

                      methods.setValue(`hr`, value)
                    }}
                    endAdornment={<InputAdornment>{t('time_hr')}</InputAdornment>}
                  />

                  <Input
                    {...methods.register(`min`, { required: `กรุณากรอกจำนวนนาที` })}
                    type="number"
                    sx={{ width: '100%' }}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10)

                      if (value > 59) {
                        methods.setValue(`min`, 59)
                        return
                      }

                      if (value < 0) {
                        methods.setValue(`min`, 0)
                        return
                      }

                      methods.setValue(`min`, value)
                    }}
                    endAdornment={<InputAdornment>{t('time_min')}</InputAdornment>}
                  />
                </>
              )}
            </DurationField>
          </section>
        ),
      },
      department: {
        type: 'controlled-autocomplete',
        viewValue: (value) => value?.name,
        label: t('department'),
        placeholder: t('department_placeholder'),
        props: {
          options: departments?.map((dept) => ({ id: dept.id, name: dept.name })) || [],
          onChange(_event, selectVal) {
            methods.setValue('department', selectVal || undefined)
            methods.setValue('positions', [])
          },
          getOptionLabel(option) {
            return option.name || option
          },
          ...(id && {
            disabled: true,
          }),
        },
      },
      positions: {
        type: 'fieldArray',
        label: t('positions'),
        props: {
          isCanDrop: false,
          render({ field, index, remove }) {
            const key = `positions.${index}`
            const positionName = methods.getValues(`${key}.name`)

            return (
              <PositionContainer
                className={`position-field-container ${index === 0 ? 'first-item' : ''} ${
                  readonly ? 'readonly' : 'not-readonly'
                }`}
              >
                {readonly ? (
                  <Chip label={positionName} color="primary" sx={{ p: '4px 8px 4px 8px' }} />
                ) : (
                  <>
                    <Autocomplete
                      key={field.id}
                      {...methods.register(`${key}`, { required: `กรุณาเลือก${field.label}` })}
                      id={key}
                      options={positionOptions}
                      defaultValue={field}
                      onChange={(val, selectVal) => {
                        methods.setValue(`${key}`, selectVal)
                      }}
                      getOptionLabel={(option) => option?.name || ''}
                      renderOption={(props, option, { selected }) => (
                        <AutocompleteOption {...props}>
                          <p className="title">{`${option?.name}`}</p>
                        </AutocompleteOption>
                      )}
                      renderInput={(params) => <TextField {...params} placeholder={t('position_placeholder')} />}
                      sx={{ width: '100%' }}
                    />

                    <Tooltip title={t('remove_position')}>
                      <IconButton
                        className="remove-button"
                        size="sm"
                        onClick={() => {
                          const positions = methods.getValues('positions')

                          remove(index)
                          methods.setValue(
                            'positions',
                            positions.filter((_, i) => i !== index)
                          )
                        }}
                      >
                        <RiDeleteBinLine fontSize={16} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </PositionContainer>
            )
          },
          ...(!readonly && {
            actions({ append }) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    gap: '1rem',
                    marginTop: '1rem',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      append({ id: null })
                    }}
                    startIcon={<RiAddLine />}
                    disabled={methods.getValues('department') === undefined}
                  >
                    {t('add_more_position')}
                  </Button>
                </Stack>
              )
            },
          }),
        },
      },
      'divider-2': { type: 'divider' },
      'section-2': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('delivery_subject_sow_title')}</Typography>
            <Typography variant="sectionText">{t('delivery_subject_sow_subtitle')}</Typography>
          </HeaderSection>
        ),
      },
      deliveries: {
        type: 'fieldArray',
        props: {
          render({ field, index, remove }) {
            const key = `deliveries.${index}`

            return (
              <Draggable
                key={field.fieldId}
                draggableId={`delivery-item-${field.fieldId}`}
                index={index}
                isDragDisabled={readonly}
              >
                {(provided, snapshot) => {
                  const { isDragging } = snapshot || {}

                  return (
                    <ContentList
                      ref={provided.innerRef}
                      className={`${isDragging ? 'dragging' : ''}`}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <section key="name" style={{ marginBottom: readonly ? '0' : '1rem' }}>
                        <label>{t('delivery_name')}:</label>
                        <Stack gap="0.5rem">
                          <Stack direction="row" gap="1rem" alignItems="center">
                            {readonly ? (
                              methods.getValues(`${key}.name`)
                            ) : (
                              <Input
                                {...methods.register(`${key}.name`, { required: `กรุณากรอก${field.label}` })}
                                placeholder={t('delivery_name_placeholder')}
                                onChange={(e) => {
                                  const value = e.target.value
                                  methods.setValue(`${key}.name`, value)
                                }}
                                sx={{ width: '100%' }}
                              />
                            )}
                          </Stack>
                        </Stack>
                      </section>

                      <section key="info" style={{ marginBottom: readonly ? '0' : '1rem' }}>
                        <label>{t('delivery_info')}:</label>
                        <Stack gap="0.5rem">
                          <Stack direction="row" gap="1rem" alignItems="center">
                            {readonly ? (
                              methods.getValues(`${key}.info`)
                            ) : (
                              <Textarea
                                {...methods.register(`${key}.info`, { required: `กรุณากรอก${field.label}` })}
                                placeholder={t('delivery_info_placeholder')}
                                minRows={3} // FIXME: It's not working.
                                maxRows={5} // FIXME: It's not working.
                                sx={{ width: '100%' }}
                                onChange={(e) => {
                                  const value = e.target.value
                                  methods.setValue(`${key}.info`, value)
                                }}
                              />
                            )}
                          </Stack>
                        </Stack>
                      </section>

                      <section key="type" style={{ marginBottom: readonly ? '0' : '1rem' }}>
                        <label>{t('delivery_type')}:</label>
                        <Stack gap="0.5rem">
                          <Stack direction="row" gap="1rem" alignItems="center">
                            {readonly ? (
                              methods.getValues(`${key}.type`)
                            ) : (
                              <Autocomplete
                                {...methods.register(`${key}.type`, { required: `กรุณาเลือก${field.label}` })}
                                defaultValue={field.type || 'Upload File'}
                                options={['Upload File', 'Document']}
                                onChange={(val, selectVal) => {
                                  methods.setValue(`${key}.type`, selectVal)
                                }}
                                renderOption={(props, option, { selected }) => (
                                  <AutocompleteOption {...props}>
                                    <p className="title">{`${option}`}</p>
                                  </AutocompleteOption>
                                )}
                                renderInput={(params) => (
                                  <TextField {...params} placeholder={t('delivery_type_placeholder')} />
                                )}
                                sx={{ width: '100%' }}
                              />
                            )}
                          </Stack>
                        </Stack>
                      </section>

                      {watchDeliveries && watchDeliveries?.[index]?.type === 'Document' && (
                        <section key="document.type" style={{ marginBottom: readonly ? '0' : '1rem' }}>
                          <label>{t('document_type')}:</label>
                          <Stack gap="0.5rem">
                            <Stack direction="row" gap="1rem" alignItems="center">
                              {readonly ? (
                                docTypes &&
                                docTypes?.filter(
                                  (docType) => docType.name_id === methods.getValues(`${key}.document.type`)
                                )[0]?.[lang === 'en' ? 'name_en' : 'name_th']
                              ) : (
                                <Autocomplete
                                  {...methods.register(`${key}.document.type`, {
                                    required: `กรุณาเลือก${field.label}`,
                                  })}
                                  defaultValue={
                                    docTypes
                                      ?.filter((docType) => docType.name_id === methods.watch(`${key}.document.type`))
                                      ?.map((docType) => ({
                                        id: docType?.name_id,
                                        name: docType?.[lang === 'en' ? 'name_en' : 'name_th'],
                                      }))[0] || undefined
                                  }
                                  options={
                                    docTypes?.map((docType) => ({
                                      id: docType?.name_id,
                                      name: docType?.[lang === 'en' ? 'name_en' : 'name_th'],
                                    })) || []
                                  }
                                  onChange={(val, selectVal) => {
                                    methods.setValue(`${key}.document.type`, selectVal?.id || null)
                                  }}
                                  getOptionLabel={(option) => option?.name || ''}
                                  renderOption={(props, option, { selected }) => (
                                    <AutocompleteOption {...props}>
                                      <p className="title">{`${option?.name}`}</p>
                                    </AutocompleteOption>
                                  )}
                                  renderInput={(params) => (
                                    <TextField {...params} placeholder={t('document_type_placeholder')} />
                                  )}
                                  sx={{ width: '100%' }}
                                />
                              )}
                            </Stack>
                          </Stack>
                        </section>
                      )}

                      {!readonly && (
                        <Tooltip title={t('remove_delivery')}>
                          <IconButton
                            className="remove-button"
                            size="sm"
                            onClick={() => {
                              const deliveries = methods.getValues('deliveries')

                              remove(index)
                              methods.setValue(
                                'deliveries',
                                deliveries.filter((_, i) => i !== index)
                              )
                            }}
                          >
                            <RiDeleteBinLine fontSize={16} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ContentList>
                  )
                }}
              </Draggable>
            )
          },
          ...(!readonly && {
            actions({ append }) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    gap: '1rem',
                    marginTop: methods.getValues('deliveries') && methods.getValues('deliveries').length > 0 && '1rem',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      append({ name: '', info: '', type: 'Upload File' })
                    }}
                    startIcon={<RiAddLine />}
                  >
                    {t('add_delivery_work')}
                  </Button>
                </Stack>
              )
            },
          }),
        },
      },
    },
    required: !readonly ? ['name', 'hr', 'min', 'positions'] : [],
  }

  const formSchema_2 = {
    properties: {
      'section-1': {
        component: (
          <HeaderSection>
            <Typography variant="sectionTitle">{t('task_list_title')}</Typography>
            <Typography variant="sectionText">{t('task_list_subtitle')}</Typography>
          </HeaderSection>
        ),
      },
      'divider-2': { type: 'divider' },
      tasks: {
        type: 'fieldArray',
        props: {
          render({ field, index, remove }) {
            const key = `tasks.${index}`
            const taskSelectedId = methods.getValues(`${key}.id`)

            return (
              <Draggable
                key={field.fieldId}
                draggableId={`task-item-${field.fieldId}`}
                index={index}
                isDragDisabled={readonly}
              >
                {(provided, snapshot) => {
                  const { isDragging } = snapshot || {}

                  return (
                    <ContentList
                      ref={provided.innerRef}
                      className={`${isDragging ? 'dragging' : ''}`}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Stack
                        className="task-item-title"
                        sx={{
                          padding: '0.5rem 0.75rem',
                          borderRadius: '0.5rem',
                          background: 'var(--Primary-50)',
                          border: '1px solid var(--Primary-200)',
                          marginBottom: '1rem',
                          width: 'fit-content',
                        }}
                      >
                        <Typography variant="cardTitle" sx={{ color: 'var(--Primary-700)' }}>{`${t('task')} – ${
                          index + 1
                        }`}</Typography>
                      </Stack>

                      <section key={`${key}.department.id`} style={{ marginBottom: readonly ? '0' : '1rem' }}>
                        <label>{t('department')}:</label>
                        <Stack gap="0.5rem">
                          <Stack direction="row" gap="1rem" alignItems="center">
                            {readonly ? (
                              methods.getValues(`${key}.department.name`)
                            ) : (
                              <Controller
                                control={methods.control}
                                name={`${key}.department`}
                                rules={{ required: `กรุณาเลือก${field?.label}` }}
                                defaultValue={field.department || undefined}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    onChange={(val, selectVal) => {
                                      methods.setValue(`${key}.department`, selectVal)
                                      methods.setValue(`${key}.id`, null)
                                      methods.setValue(`${key}.name`, null)
                                    }}
                                    value={field.value || methods.watch(`${key}.department`)}
                                    options={departments || []}
                                    getOptionLabel={(option) => option?.name || ''}
                                    renderOption={(props, option, { selected }) => (
                                      <AutocompleteOption {...props}>
                                        <p className="title">{`${option?.name}`}</p>
                                      </AutocompleteOption>
                                    )}
                                    isOptionEqualToValue={(option, value) => {
                                      return option?.id === value?.id
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} placeholder={t('department_placeholder')} />
                                    )}
                                    sx={{ width: '100%' }}
                                  />
                                )}
                              />
                            )}
                          </Stack>
                        </Stack>
                      </section>

                      <section key={`${key}`} style={{ marginBottom: readonly ? '0' : '1rem' }}>
                        <label>{t('task')}:</label>
                        <Stack gap="0.5rem">
                          <Stack direction="row" gap="1rem" alignItems="center">
                            {readonly ? (
                              methods.getValues(`${key}.name`)
                            ) : (
                              <Controller
                                control={methods.control}
                                name={`${key}.id`}
                                rules={{ required: `กรุณาเลือก${field?.label}` }}
                                defaultValue={field || undefined}
                                render={({ field }) => (
                                  <Autocomplete
                                    {...field}
                                    disabled={!methods.watch('tasks')?.[index]?.department?.id}
                                    onChange={(val, selectVal) => {
                                      methods.setValue(`${key}.id`, selectVal?.id)
                                      methods.setValue(`${key}.name`, selectVal?.name)
                                      methods.setValue(`${key}.code`, selectVal?.code)
                                    }}
                                    value={methods.watch(`${key}`)}
                                    options={
                                      taskTemps?.filter(
                                        (i) => i.department.id === methods.watch('tasks')?.[index]?.department?.id
                                      ) || []
                                    }
                                    getOptionLabel={(option) => {
                                      if (option?.id && option?.name) {
                                        return `${option.name} - ${option.code}`
                                      }

                                      return ''
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                      <AutocompleteOption {...props}>
                                        <p className="title">{`${option?.name}`}</p>
                                        <p className="supporting-text">{`${option?.code}`}</p>
                                      </AutocompleteOption>
                                    )}
                                    isOptionEqualToValue={(option, value) => {
                                      return option?.id === value?.id
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} placeholder={t('task_placeholder')} />
                                    )}
                                    sx={{ width: '100%' }}
                                  />
                                )}
                              />
                            )}

                            {methods.watch('tasks')?.[index]?.department?.id && (
                              <IconButton
                                onClick={() => taskSelectedId && handlePreviewTask(taskSelectedId)}
                                size="sm"
                                variant="outlined"
                              >
                                <RiEyeLine />
                              </IconButton>
                            )}
                          </Stack>
                        </Stack>
                      </section>

                      {!readonly && (
                        <Tooltip title={t('remove_task')}>
                          <IconButton
                            className="remove-button"
                            size="sm"
                            onClick={() => {
                              const tasks = methods.watch('tasks')

                              remove(index)
                              methods.setValue(
                                'tasks',
                                tasks.filter((_, i) => i !== index)
                              )
                            }}
                          >
                            <RiDeleteBinLine fontSize={16} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </ContentList>
                  )
                }}
              </Draggable>
            )
          },
          ...(!readonly && {
            actions({ append }) {
              return (
                <Stack
                  direction="row"
                  sx={{
                    gap: '1rem',
                    marginTop: methods.getValues('tasks')?.length > 0 && '1rem',
                  }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      append({ department: { id: null }, id: null })
                    }}
                    startIcon={<RiAddLine />}
                  >
                    {t('add_task')}
                  </Button>
                </Stack>
              )
            },
          }),
        },
      },
    },
  }

  const tabItems = [
    {
      key: 'overview',
      label: t('overview'),
      content: (
        <DynamicForm
          ref={formRef}
          methods={methods}
          onSubmit={onSubmit}
          schema={formSchema}
          readonly={readonly}
          formProps={{
            className: 'sow-tpl-create-form',
          }}
        />
      ),
    },
    {
      key: 'task',
      label: t('task'),
      content: (
        <DynamicForm
          ref={formRef}
          methods={methods}
          onSubmit={onSubmit}
          schema={formSchema_2}
          readonly={readonly}
          formProps={{
            className: 'sow-tpl-create-form',
          }}
        />
      ),
    },
  ]

  const readonlyTabItems = [
    {
      key: 'logs',
      label: t('logs'),
      content: 'Not available yet.',
    },
  ]

  const handleEditClick = () => {
    if (sowTpl?.id) {
      navigate(`${location.pathname}?id=${sowTpl.id}&mode=edit`)
    }
  }

  const handleToggleStatus = () => {
    if (sowTpl) {
      switch (sowTpl.status) {
        case SOW_TEMPLATE.STATUS.Active:
          postInActiveSowTpl(sowTpl.id).then(() => {
            dispatch(getSowTpl(sowTpl.id).byId())
            fetchSowTpls()
          })
          break

        case SOW_TEMPLATE.STATUS.Inactive:
          postActiveSowTpl(sowTpl.id).then(() => {
            dispatch(getSowTpl(sowTpl.id).byId())
            fetchSowTpls()
          })
          break

        default:
          break
      }
    }
  }

  const handleDeleteSowTpl = () => {
    if (sowTpl?.id) {
      dispatch(deleteSowTpl(sowTpl.id)).then(() => {
        navigate(location.pathname)
        clearSowTpl()
        fetchSowTpls()
      })
    }
  }

  const renderExtraButton = () => {
    let componentsArray = []

    if (sowTpl?.status === SOW_TEMPLATE.STATUS.Inactive) {
      componentsArray.push(
        <Button variant="outlined" onClick={handleToggleStatus}>
          {t('active')}
        </Button>,
        <Button variant="outlined" color="error" onClick={handleDeleteSowTpl}>
          {t('delete')}
        </Button>
      )
    }

    if (sowTpl?.status === SOW_TEMPLATE.STATUS.Active) {
      componentsArray.push(
        <Button variant="outlined" color="error" onClick={handleToggleStatus}>
          {t('in_active')}
        </Button>
      )
    }

    return (
      <>
        {componentsArray.map((component, index) => {
          return component
        })}
        <Button variant="outlined" color="primary" onClick={handleEditClick}>
          {t('Edit')}
        </Button>
      </>
    )
  }

  useEffect(() => {
    if (watchDepartment) {
      const selectedDepartment = departments?.find((dept) => dept.id === watchDepartment?.id)

      if (selectedDepartment) {
        setPositionOptions(selectedDepartment.positions)
      }
    }
  }, [watchDepartment])

  useEffect(() => {
    if (methods.formState.errors) {
      // console.log(methods.formState.errors)
    }
  }, [methods.formState])

  return (
    <>
      <FormProvider {...methods}>
        <Container className="sow-tpl-form-container" sx={{ ...containerProps }}>
          <Tabs
            items={[...tabItems, ...(readonly ? readonlyTabItems : [])]}
            defaultActiveKey="overview"
            sx={{ marginTop: '0.5rem' }}
            tabBarExtraContent={
              readonly && !noExtraActions && <TabBarExtraContainer>{renderExtraButton()}</TabBarExtraContainer>
            }
          />
        </Container>
      </FormProvider>

      <Modal
        open={previewModalProps.task.open}
        onClose={() => {
          handleToggleModal('task', false)
          dispatch(clearTaskTemp())
        }}
      >
        <ModalDialog
          layout={previewModalProps.task.layout}
          maxWidth={previewModalProps.task.layout === 'fullscreen' ? '100vw' : 1440}
          minWidth={1440}
          sx={{
            padding: '0',
            background: 'var(--Base-White)',
          }}
        >
          <ModalClose />
          <DialogTitle
            sx={{
              justifyContent: 'flex-end',
              marginInlineEnd: '2.5rem',
              marginInlineStart: '1.5rem',
              paddingTop: '0.5rem',
              alignItems: 'center',
            }}
          >
            <Typography variant="sidepanelTitle" sx={{ flex: 'auto' }}>
              {t('preview')}: {taskTemp?.code}
            </Typography>

            {previewModalProps.task.layout === 'center' ? (
              <Tooltip title={t('expand_fullscreen')}>
                <IconButton
                  onClick={() =>
                    setPreviewModalProps((prev) => {
                      return {
                        ...prev,
                        task: {
                          ...prev.task,
                          layout: 'fullscreen',
                        },
                      }
                    })
                  }
                  size="sm"
                >
                  <CgArrowsExpandLeft fontSize={13} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t('minimize_to_center')}>
                <IconButton
                  onClick={() =>
                    setPreviewModalProps((prev) => {
                      return {
                        ...prev,
                        task: {
                          ...prev.task,
                          layout: 'center',
                        },
                      }
                    })
                  }
                  size="sm"
                >
                  <BsPip fontSize={16} />
                </IconButton>
              </Tooltip>
            )}
          </DialogTitle>
          <Stack
            sx={{
              height: '100%',
              overflow: 'overlay',
              alignItems: 'center',
              mx: 'calc(-1 * var(--ModalDialog-padding))',
              px: 'var(--ModalDialog-padding)',
            }}
          >
            <MuiContainer maxWidth="xl">
              <ViewTaskTpl id={previewModalProps.task.id} noExtraActions />
            </MuiContainer>
          </Stack>
        </ModalDialog>
      </Modal>
    </>
  )
})

export default CreateSowTpl

const TabBarExtraContainer = styled('div')`
  display: flex;
  gap: 0.5rem;
`

const PositionContainer = styled('div')`
  display: flex;
  gap: 0.25rem;

  &.position-field-container {
    &:not(.first-item) {
      margin-top: 0.5rem;
    }

    &.readonly {
    }

    &.not-readonly {
    }
  }

  @media (min-width: 640px) {
    &.position-field-container {
      > .MuiAutocomplete-root {
        max-width: 300px;
      }
    }
  }
`

const DurationField = styled('div')`
  display: flex;
  gap: 0.5rem;

  @media (min-width: 640px) {
    > div {
      max-width: 25%;
    }
  }
`

const HeaderSection = styled('section')`
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  margin-top: 1rem;
`

const Container = styled('div')`
  height: auto;
  padding: 0 1.25rem 1rem 1.25rem;
  overflow &::-webkit-scrollbar {
    width: 0.75rem;
    background: rgba(0, 0, 0, 0.25); /* make scrollbar invisible by default */
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
  }

  &:hover::-webkit-scrollbar-thumb,
  &:active::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
    opacity: 0.5;
  }

  &:active::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.5s ease-in-out;
  }

  &::-webkit-scrollbar-track {
    background: var(--Base-White);
    border: 1px solid var(--Gray-200);
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    margin: 0 0.5rem;
  }
`

const AutocompleteOption = styled('li')`
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 0 0.75rem !important;
  gap: 0.5rem;

  .title {
    font-size: 14px;
    font-weight: 500;
    color: var(--Gray-700);
    line-height: 20px;
  }

  .supporting-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: var(--Gray-500);
  }
`

const ContentList = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  background-color: var(--Base-White);
  padding: 1.5rem 3rem 1rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid var(--Gray-200);
  position: relative;
  transition: all 100ms ease-in-out;

  &.dragging {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    backdrop-filter: blur(4px);
    background: rgba(255, 255, 255, 0.8);
  }

  .remove-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    scale: 0;
    transition: all 100ms ease-in-out;
  }

  &:hover {
    scale: 1.005;

    .remove-button {
      scale: 1;
    }
  }

  @media not all and (min-width: 640px) {
    padding: 1rem 1.5rem 1rem 1.5rem;
  }
`
