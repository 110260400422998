import { Container, IconButton, Stack, Tooltip, styled } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { RiDeleteBinLine, RiDownloadLine, RiEyeLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'

import TableV3 from 'components/common/TableV3'
import Typography from 'components/common/Typography'
import FileUpload from 'components/form2/form-file-upload'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'
import { capitalizeEachWord } from 'utils/stringHelpers'

const ActionCell = ({ items = [] }) => {
  return (
    <Stack direction="row" sx={{ gap: '0.5rem' }}>
      {items.map((item, index) => (
        <Tooltip key={index} title={item?.text}>
          <IconButton onClick={item?.action} size="small">
            {item?.icon}
          </IconButton>
        </Tooltip>
      ))}
    </Stack>
  )
}

/**
 *
 * @todo Add pagination to the table component.
 */
const AssetTab = ({
  id,
  allowUpload = true,
  onActions = {
    onUploadFile: () => {},
    onDeleteFile: () => {},
  },
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { sp: spActions, sw: swActions, extra: extraActions } = opActions()
  const { getSP } = spActions()
  const { getSW, postSW } = swActions()
  const { deleteExtra } = extraActions()
  const opProcSelector = useSelector(selectOpProc)
  const { sw_files, sw_files_pagination } = opProcSelector
  const { page, pageCount, pageSize } = sw_files_pagination

  const tableColumns = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: t('file'),
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: t('task'),
      accessor: 'task',
      disableSortBy: false,
    },
    {
      Header: t('uploaded_by'),
      accessor: 'created_by',
      disableSortBy: false,
    },
    {
      Header: t('created_date'),
      accessor: 'createdAt',
      disableSortBy: false,
    },
    {
      accessor: 'actions',
      disableSortBy: false,
    },
  ]

  const fetchSWFiles = async (filters) => {
    dispatch(getSW().files(id, { filters }))
  }

  const fetchSPFiles = useCallback(
    async (spId) => {
      dispatch(getSP().files(spId, {}))
    },
    [dispatch]
  )

  const actionOptions = (fileUrl, fileName, fileId) => {
    return [
      {
        icon: <RiEyeLine />,
        text: t('preview'),
        action: () => {
          window.open(fileUrl, '_blank')
        },
      },
      {
        icon: <RiDownloadLine />,
        text: t('download'),
        action: () => {
          fetch(fileUrl)
            .then((response) => response.blob())
            .then((blob) => {
              const url = window.URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = fileName
              a.click()

              window.URL.revokeObjectURL(url) // free up storage--no longer needed.
            })
            .catch((error) => console.error(error))
        },
      },
      {
        icon: <RiDeleteBinLine />,
        text: t('delete'),
        action: () => {
          dispatch(deleteExtra().file(fileId)).then((res) => {
            if (res.type.endsWith('_SUCCESS') && !res.error) {
              fetchSWFiles()

              onActions?.onDeleteFile && onActions.onDeleteFile()
            }
          })
        },
      },
    ]
  }

  const transformSWFilesToTable = (data) => {
    if (!data || data.length === 0) {
      console.warn('No data provided or data is empty.')
      return []
    }

    const mapDataItem = (item, index) => {
      const actions = <ActionCell items={actionOptions(item?.file?.url, item?.name, item?.id)} />
      const task = item.task?.name || '-'
      const no = (page - 1) * pageSize + index + 1

      return {
        ...item,
        no,
        task,
        actions,
      }
    }

    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const handleTableChange = ({ page }) => {
    const updatedPagination = { ...sw_files_pagination, page }

    fetchSWFiles({ pagination: updatedPagination })
  }

  const handleUploadAssets = async (files) => {
    if (files) {
      await Promise.all(
        files.map((file) => {
          const objData = {
            file: {
              id: file.id,
            },
          }

          return dispatch(postSW().file(id, objData))
            .then((res) => {
              const { data, type } = res

              if (type.endsWith('_SUCCESS') && !res.error) {
                return res
              }
            })
            .catch((error) => {
              console.error('Error uploading file:', error)
            })
        })
      )

      fetchSWFiles()

      onActions?.onUploadFile && onActions.onUploadFile()
    }
  }

  useEffect(() => {
    fetchSWFiles()
  }, [])

  return (
    <StyledContainer maxWidth={false} disableGutters>
      <TableHeader>
        <Stack direction="row" sx={{ width: '100%', padding: '1rem 1.5rem', justifyContent: 'space-between' }}>
          <Stack direction="row" sx={{ width: '100%' }}>
            <Stack spacing="4px">
              <Typography variant="tableTitle">{capitalizeEachWord(t('asset'))}</Typography>
              <Typography variant="tableSubtitle">{t('table_asset_subtitle')}</Typography>
            </Stack>
          </Stack>

          <FileUpload
            id="sw-upload-btn"
            onUploadCallback={handleUploadAssets}
            inputFileProps={{ disabled: !allowUpload }}
            buttonProps={{ disabled: !allowUpload }}
          />
        </Stack>
      </TableHeader>
      <TableV3
        columns={tableColumns}
        data={transformSWFilesToTable(sw_files)}
        onStateChange={handleTableChange}
        pageCount={pageCount}
        page={page}
      />
    </StyledContainer>
  )
}

export default AssetTab

const StyledContainer = styled(Container)`
  height: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

/* TODO: This code is repeated in multiple places */
const TableHeader = styled(Stack)({
  width: '100%',
  border: '1px solid var(--Gray-200)',
  borderBottom: 'none',
})
