import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Button from '../../../../components/form/button/Button'
import ButtonOutline from '../../../../components/form/button/ButtonOutline'
import ViewByType from './ViewByType'

export default function CreateDocLayout({
  title,
  headerList,
  bodyList,
  footerList,
  onChange,
  isEdit = false,
  onBtnSaveClicked,
  onBtnPreviewClicked,
  onBtnCancelClicked,
  docType,
}) {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
    if (onBtnCancelClicked) {
      onBtnCancelClicked()
    }
  }

  const handleChange = (e) => {
    let tmpCustomerData
    if (e?.layout_type == 'header_left') {
      let columnLeftList = [...headerList[0]]
      for (let rowIndex = 0; rowIndex < columnLeftList.length; rowIndex++) {
        let rowList = columnLeftList[rowIndex]
        for (let itemIndex = 0; itemIndex < rowList.length; itemIndex++) {
          const index = rowList.findIndex((item) => item?.id === e?.id)

          if (index > -1) {
            columnLeftList[rowIndex][index].value = e.value

            if (rowList[index]?.type == 'search_customer') {
              tmpCustomerData = e?.value?.customer_data
            }

            break
          }
        }
      }
      let tmpHeaderList = [...headerList]
      tmpHeaderList[0] = columnLeftList

      if (onChange) {
        onChange({ data: tmpHeaderList, layout_type: 'header_left', customer_data: tmpCustomerData })
      }
    } else if (e?.layout_type == 'header_right') {
      let columnRightList = [...headerList[1]]
      let tmpSelectedValue
      for (let itemIndex = 0; itemIndex < columnRightList.length; itemIndex++) {
        const index = columnRightList.findIndex((item) => item?.id === e?.id)
        if (index > -1) {
          columnRightList[index].value = e.value
          tmpSelectedValue = e?.value?.text || ''
          break
        }
      }

      if (e?.view_type == 'hide_show') {
        for (let itemIndex = 0; itemIndex < columnRightList.length; itemIndex++) {
          const item = columnRightList[itemIndex]

          if (item?.select_type == tmpSelectedValue) {
            columnRightList[itemIndex].is_hidden = false
          } else if (item?.select_type) {
            columnRightList[itemIndex].is_hidden = true
          }
        }
      }

      let tmpHeaderList = [...headerList]
      tmpHeaderList[1] = columnRightList
      if (onChange) {
        onChange({ data: tmpHeaderList, layout_type: 'header_right' })
      }
    } else if (e?.layout_type == 'body') {
      let rowList = [...bodyList]
      let tmpRowIndex
      let tmpColIndex
      let tmpRowTableIndex
      let tmpColTableIndex
      let tmpValue
      let tmpViewType

      for (let rowIndex = 0; rowIndex < rowList.length; rowIndex++) {
        let innerRowList = rowList[rowIndex]

        for (let colIndex = 0; colIndex < innerRowList.length; colIndex++) {
          const index = innerRowList.findIndex((item) => item?.id === e?.id)

          if (index > -1) {
            tmpViewType = rowList[rowIndex][index].type

            if (rowList[rowIndex][index].type == 'table') {
              let tmpTableValue = rowList[rowIndex][index].value
              const bodyList = tmpTableValue.body

              for (let rowTableIndex = 0; rowTableIndex < bodyList.length; rowTableIndex++) {
                const tableColList = bodyList[rowTableIndex]
                for (let colTableIndex = 0; colTableIndex < tableColList.length; colTableIndex++) {
                  const tableColumnIndex = tableColList.findIndex((item) => item?.id === e?.sub_id)

                  if (tableColumnIndex > -1) {
                    tmpRowIndex = rowIndex
                    tmpColIndex = colIndex
                    tmpRowTableIndex = rowTableIndex
                    tmpColTableIndex = tableColumnIndex
                    tmpValue = e?.value
                    tmpTableValue.body[rowTableIndex][tableColumnIndex].value = tmpValue
                    break
                  }
                }
              }
              rowList[rowIndex][index].value = tmpTableValue
              break
            } else {
              tmpRowIndex = rowIndex
              tmpColIndex = colIndex
              tmpValue = e?.value
              rowList[rowIndex][index].value = tmpValue
              break
            }
          }
        }
      }
      // setBodyList(rowList)
      if (onChange) {
        onChange({
          data: rowList,
          layout_type: 'body',
          view_type: tmpViewType,
          view_value: tmpValue,
          row_index: tmpRowIndex,
          col_index: tmpColIndex,
          table_row_index: tmpRowTableIndex,
          table_col_index: tmpColTableIndex,
        })
      }
    } else if (e?.layout_type == 'footer') {
      let tmpList = [...footerList]
      const index = tmpList.findIndex((item) => item?.id === e?.id)
      if (index > -1) {
        tmpList[index].value = e.value
        if (onChange) {
          onChange({ data: tmpList, layout_type: 'footer' })
        }
      }
    }
  }

  const createHeaderView = (list) => {
    return (
      <div className="header">
        <div className="column left">
          {list[0]?.map((rowItem, index) => (
            <div key={'row_item_' + (index + 1)} className={'row row_' + (index + 1)}>
              {createLeftRightColumnViews(rowItem, 'header_left')}
            </div>
          ))}
        </div>
        <div className="column right">
          {list[1]?.map((rowItem, index) => (
            <div key={'row_item_' + (index + 1)} className={'row row_' + (index + 1)}>
              <ViewByType
                key={rowItem.id}
                dataItem={rowItem}
                onChange={(e) => {
                  handleChange({ ...e, layout_type: 'header_right' })
                }}
              />
            </div>
          ))}
        </div>
      </div>
    )
  }

  const createBodyView = (list) => {
    return (
      <div className={'body'}>
        {list?.map((bodyRowItem, bodyRowIndex) => (
          <div key={'body_row_item_' + bodyRowIndex} className="body_row_item">
            {bodyRowItem?.map((bodyColumnItem) => (
              <ViewByType
                key={bodyColumnItem.id}
                dataItem={bodyColumnItem}
                onTableChange={(e, id) => {
                  let rowList = [...bodyList]
                  let tmpRowIndex
                  let tmpColIndex
                  for (let rowIndex = 0; rowIndex < rowList.length; rowIndex++) {
                    let innerRowList = rowList[rowIndex]

                    const index = innerRowList.findIndex((item) => item?.id == id)

                    if (index > -1) {
                      tmpRowIndex = rowIndex
                      tmpColIndex = index
                      rowList[rowIndex][index].value = e
                      break
                    }
                  }
                  if (onChange) {
                    onChange({
                      data: rowList,
                      layout_type: 'body',
                      view_type: 'table',
                      row_index: tmpRowIndex,
                      col_index: tmpColIndex,
                    })
                  }
                }}
                onChange={(e) => {
                  handleChange({ ...e, layout_type: 'body' })
                }}
              />
            ))}
          </div>
        ))}
      </div>
    )
  }

  const createFooterView = (list) => {
    return <div className="footer">{createLeftRightColumnViews(list, 'footer')}</div>
  }

  const createLeftRightColumnViews = (list, sectionType) => {
    let leftList = []
    let rightList = []

    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      if (item?.position?.includes('left')) {
        leftList.push(item)
      } else if (item?.position?.includes('right')) {
        rightList.push(item)
      }
    }

    return (
      <>
        {leftList?.length > 0 && (
          <div className={'row_inner left'}>
            {leftList?.map((item) => (
              <ViewByType
                key={item.id}
                dataItem={item}
                onChange={(e) => {
                  handleChange({ ...e, layout_type: sectionType })
                }}
              />
            ))}
          </div>
        )}
        {rightList?.length > 0 && (
          <div className={'row_inner right'}>
            {rightList?.map((item) => (
              <ViewByType
                key={item.id}
                dataItem={item}
                onChange={(e) => {
                  handleChange({ ...e, layout_type: sectionType })
                }}
              />
            ))}
          </div>
        )}
      </>
    )
  }

  const handleClickPreview = () => {
    if (onBtnPreviewClicked) {
      onBtnPreviewClicked()
    }
  }
  const handleClickSave = () => {
    if (onBtnSaveClicked) {
      onBtnSaveClicked()
    }
  }

  return (
    <Styled docType={docType} className={docType}>
      <div className="document_title">
        <h1>
          {!isEdit ? 'Create' : 'Edit'} document - <span>{title ? title : ''}</span>
        </h1>
      </div>
      <div className="create_document_wrap">
        {headerList?.length > 0 && createHeaderView(headerList)}
        {bodyList?.length > 0 && createBodyView(bodyList)}
        {footerList?.length > 0 && createFooterView(footerList)}
      </div>

      <div className="document_bottom_bar">
        <ButtonOutline onClick={handleGoBack}>Cancel</ButtonOutline>
        <div className="wrp-btn-right">
          <Button className="btn-preview" onClick={handleClickPreview}>
            Preview
          </Button>
          <Button className="btn-create" onClick={handleClickSave}>
            {isEdit ? 'Save' : 'Create'}
          </Button>
        </div>
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  height: 100%;

  .document_title {
    h1 {
      font-size: 36px;
    }
    border-bottom: 1px solid var(--Gray-200);
  }

  .document_bottom_bar {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--Gray-200);
    padding: 16px 24px;

    .btn-preview {
      margin-right: 12px;
    }
  }
  /* layout css */
  .header,
  .footer {
    display: flex;
    flex-direction: row;
  }
  .column.left,
  .footer .left {
    flex: 2;
  }
  .column.right,
  .footer .right {
    flex: 1;
  }

  .header {
    position: relative;
    padding-top: 20px;

    .row {
      display: flex;
      justify-content: flex-start;
    }
    .row_inner {
      margin-right: 20px;
      min-width: 300px;
      .input_text .title,
      .input_text_area_wrp .title,
      .wrp_search_customer .title {
        margin-bottom: 6px;
      }
    }

    .view_item {
      margin-bottom: 24px;
    }
    .view_item .text_field,
    .view_item .input_text_area,
    .view_item .input_search {
      width: 100%;
    }
    .view_item .input_text_area {
      height: 135px;
      border-color: var(--Gray-300);
      border-width: 1px;
    }

    .column.right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 4px;
      .row {
        min-width: 300px;
      }
      .view_item {
        width: 100%;
      }
      .view_item .title {
        margin-bottom: 6px;
      }
      .view_item .dropdown_select {
        width: 100%;
      }
      .view_item .wrp-search-input,
      .view_item ul {
        width: 100%;
      }
    }
  }

  .body {
    padding-top: 20px;
    border-top: 1px solid var(--Gray-200);
    .body_row_item {
      display: flex;
      margin-bottom: 24px;

      .view_item .title {
        margin-bottom: 6px;
      }
    }
    .body_column_item {
      margin-right: 20px;
    }
  }

  .footer {
    margin-bottom: 24px;
    .row_inner.left {
      margin-right: 20px;

      .view_item {
        .input_text_area_wrp {
          .title {
            margin-bottom: 6px;
          }
          .input_text_area {
            width: 700px;
            height: 112px;
            border: 1px solid var(--Gray-200);
          }
        }
      }
    }
    .row_inner.right {
      .view_item {
        .wrp_calculate {
          .row {
            margin-bottom: 8px;
          }
        }
      }
    }
  }

  //other style
  .width_100 {
    width: 100%;
  }
  .width_500px {
    width: 500px;
  }
  .margin_right_16px {
    margin-right: 16px;
  }

  //payment voucher
  .header {
    .row_inner.left {
      flex: ${({ docType }) => (docType == 'payment_voucher' ? '1' : '0')};
    }
  }
  .body {
    border-top: ${({ docType }) => docType == 'payment_voucher' && 'none'};
  }
  th.col_width_100 {
    width: 100% !important;
  }
  &.credit_adjustment_document {
    .body_row_item:nth-child(2) {
      border-bottom: 1px solid var(--Gray-200);
      padding-bottom: 24px;
    }
  }
`
