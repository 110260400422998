import styled from 'styled-components'
import { useCallback,useState, useEffect } from 'react'

import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import { useDispatch, useSelector } from 'react-redux'
import { getDailySummaryById,getDailySummaryReturnData } from '../../redux/actions/dailysummary'
import { selectDailySummary } from '../../redux/selectors'
import dayjs from 'dayjs'
import Date from '../../components/form/Date'

import iconFile from '../../assets/images/icon-file-round.svg'
import Button from '../../components/form/button/Button'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  } 
  
  .content_header {
    padding: 14px 14px;
    width: 400px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 120px;
    margin-bottom: 16px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
    margin-top: 14px;
  }
  .InputTextArea_css {
    width: 100%;
  }
  .dialog-button {
    margin-bottom: 15px;
    text-align: end;
    .button_margin {
      margin: 10px;
    }
  }
  .basic-Information-detail {
    /*padding: 14px;*/
    width: 50%;
    display: flex;
  }
  .div_box_deatail{
    min-height: 128px;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background-color: #F9FAFB;
    font-size: 14px;
    padding: 10px;
    white-space: pre-wrap;
    line-break: anywhere;
  }
  .css_upload {
    margin-top: 10px;
  }
`

const DivUploadList = styled.div`
  .file {
    width: 100%;
    display: inline-flex;
    padding: 14px;
    margin-top: 16px;
    background: var(--Base-White);
    border: 1px solid var(--Gray-300);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .icon-wrapper {
    width: 32px;
  }

  .detail-wrapper {
    flex: 1;
    margin: 0 14px;
    font-size: 14px;
    overflow: hidden;

    .file-name {
      margin-bottom: 2px;
      text-overflow: ellipsis;
      overflow: hidden;

      a {
        font-weight: 500;
        color: var(--Gray-700);
      }
    }
  }
`

import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

const ViewDailySummary = ({ onClose, onSubmit, DailySummaryId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [ThisDailySummaryId, setThisDailySummaryId] = useState(DailySummaryId)
  useEffect(() => {
    if (DailySummaryId) {
      dispatch(getDailySummaryById(DailySummaryId))
    }
  }, [dispatch, DailySummaryId])
  

  const [currentDate, setcurrentDate] = useState(dayjs().format('YYYY-MM-DD'))
  const { DailySummaryInfo } = useSelector(selectDailySummary)
  const [DailySummaryData, setDailySummaryData] = useState({
    what_done_today: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sagittis, dolor et fermentum cursus",
    result: null,
    problems: null,
    how_problem_was_solved: null,
    tomorrow_plan: null,
  })
  
  useEffect(() => {
    if (DailySummaryInfo.id) {
      const setData = {
        what_done_today: DailySummaryInfo.what_done_today,
        result: DailySummaryInfo.result,
        problems: DailySummaryInfo.problems,
        how_problem_was_solved: DailySummaryInfo.how_problem_was_solved,
        tomorrow_plan: DailySummaryInfo.tomorrow_plan,
        display_date: dayjs(DailySummaryInfo.dailysummary_date_start).format('YYYY-MM-D'),
        display_report_by: DailySummaryInfo.create_by,
        daily_file: DailySummaryInfo.daily_file ? DailySummaryInfo.daily_file : null,
      }
      setDailySummaryData(setData)
    }
  }, [DailySummaryInfo])


  const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
  const [EmployeeIdCalendar, setEmployeeIdCalendar] = useState(employee_info?.id ? employee_info?.id : -99)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)
  const onChangecurrentDate = (value) => {
    setcurrentDate(value)

    const filter = {
      skip: 1,
      limit: 10000,
      active : 1,
      EmployeeId: EmployeeIdCalendar,
      filter_date : value
    }
    dispatch(getDailySummaryReturnData(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if(data_info.length > 0){
            const this_id = data_info[0].id;
            setThisDailySummaryId(this_id)
            dispatch(getDailySummaryById(this_id))
          }else{
            setTitleFail('ไม่พบข้อมูล');
            setnameItemFail('');
            setDescriptionFail(''); 
            setFailModal(true)
          }
          
        }else{
          setTitleFail('ไม่พบข้อมูล');
          setnameItemFail('');
          setDescriptionFail(''); 
          setFailModal(true)
        }  
    })
  }

  const GotoEdit = () => {
    onSubmit(ThisDailySummaryId)
  }
  return (
    <Div>
      <div className="content_header">
        <header>
          <div className="content-title">{t('daily_summary_report')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">
          <div>{t('edit_daily_summary_report_sub_header')}<br/>{DailySummaryData.display_report_by}</div>
          <div><Button onClick={GotoEdit}>{t('Edit')}</Button></div>
        </div> 
        <hr />
      </div>
      
      <div className="dialog-content">
        <div className="basic-Information-detail">
          <div >
            <Date className={'input-calendar'} value={DailySummaryData.display_date} onChange={onChangecurrentDate} />
          </div>
        </div>
        <div className="content">
          <div className="topic_main">{t('feature_daily_summary_report_what_done_today_column_header')}</div>
          <div className="div_box_deatail">
            {DailySummaryData.what_done_today}
          </div>
          <div className="topic_main">{t('create_daily_summary_report_result')}</div>
          <div className="div_box_deatail">
            {DailySummaryData.result}
          </div>
          <div className="topic_main">{t('create_daily_summary_report_problems')}</div>
          <div className="div_box_deatail">
            {DailySummaryData.problems}
          </div>
          <div className="topic_main">{t('create_daily_summary_report_how_problem_was_solved')}</div>
          <div className="div_box_deatail">
            {DailySummaryData.how_problem_was_solved}
          </div>
          <div className="topic_main">{t('create_daily_summary_report_tomorrow_plan')}</div>
          <div className="div_box_deatail">
            {DailySummaryData.tomorrow_plan}
          </div>
          <div className="css_upload">
                <DivUploadList>
                  {DailySummaryData.daily_file?.map((v) => (
                    <div className="file" key={v.id}>
                      <div className="icon-wrapper">
                        <img src={iconFile} />
                      </div>
                      <div className="detail-wrapper">
                        <div className="file-name">
                          <a href={v.url} target="_blank" rel="noreferrer">
                            {v.name}
                          </a>
                        </div>
                        <div className="file-size">{v.size} KB</div>
                      </div>
                    </div>
                  ))}
                </DivUploadList>
          </div>
        </div>
      </div>

       <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />             
    </Div>
  )
}

export default ViewDailySummary
