import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

import styled from 'styled-components'
import Button from '../../components/form/button/Button'
import SearchText from '../../components/form/SearchText'
import TextField from '../../components/form/TextField'
import Table from '../../components/common/Table'
import SidePanel from '../../components/common/SidePanel'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import DialogConfirm from '../../components/dialog/DialogConfirm'
import Checkbox from '../../components/form/Checkbox'
import OutlineCheckbox from '../../components/form/OutlineCheckbox'
import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import CollapseMenu from '../../components/common/CollapseMenu'
import Modal from '../../components/common/Modal'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import DialogSuccess from '../../components/dialog/DialogSuccess'

const Div = styled.div`
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }

  .mr-1-rem {
    margin-right: 1rem;
  }

  .mr-8-px {
    margin-right: 8px;
  }

  .d-flex {
    display: flex;
  }

  .justify-content-center {
    justify-content: center;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .align-items-center {
    align-items: center;
  }

  .input-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    align-content: center;
  }

  .button-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 12px;
  }

  .text-bold {
    font-weight: bold;
  }

  .text-2rem {
    font-size: 1.5rem;
  }

  .badge {
    padding: 2px 8px;
    align-items: center;
    color: var(--Gray-blue-700);
    background: var(--Gray-blue-50);
    border: 1px solid var(--Gray-blue-200);
    border-radius: 16px;

    &.recruiter {
      color: var(--Purple-700);
      background: var(--Purple-50);
      border: 1px solid var(--Purple-200);
    }
  }

  .status-badge {
    width: 90px;
    padding: 0.2rem;
    text-align: center;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid var(--Status-Icon-Stroke);
    color: var(--Status-Icon-Font);
    background: var(--Status-Icon-Bg);

    .status {
      font-size: 0.6rem;
      margin-right: 0.2rem;
      color: var(--Status-Active);

      &.inactive {
        color: var(--Status-Inactive);
      }

      &.draft {
        color: var(--Status-Draft);
      }
    }
  }

  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }

  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .search-container {
    display: grid;
    grid-template-columns: repeat(3, 250px) 120px;
    grid-template-rows: auto;
    align-content: center;
    column-gap: 1rem;
    margin-bottom: 1rem;
  }

  .content-header {
    margin-top: 24px;
  }

  .table-wrapper {
    margin-top: 24px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Primary-25);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Doing-Badge-Font);
        border-radius: 16px;
        border: 1px var(--Doing-Badge-Stroke) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }

  .drop-down {
    min-width: 10rem;
  }

  .icon {
    cursor: pointer;
  }

  .modal-header {
    color: #6941c6;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .modal-container {
    position: relative;
    padding: 24px;
    width: 100%;
    min-width: 40rem;
  }

  .dialog-button {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;

    button {
      justify-content: center;
    }
  }

  .dialog-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-area {
    width: 100%;
    height: 15rem;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    font-family: inherit;
    color: var(--Gray-500);
    font-size: 14px;
    line-height: 1.5;

    &.error {
      border-color: red;
    }
  }

  .error-input {
    border: 1px solid red;
  }

  .side-panel-container {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .side-panel-container-2 {
    padding: 2rem;
    color: var(--Gray-600);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .create-access-role-header {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
  }

  .input {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .input-header {
    color: var(--gray-700);
    font-size: 16px;
    font-weight: 500;
  }

  .permission-header {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .permission-content {
    display: grid;
    grid-template-columns: 3fr repeat(5, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }
  .text-underline {
    text-decoration: underline;
  }
  .text-color-primary {
    color: #7f56d9;
  }
  .link {
    color: #7f56d9;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .tab-menu-container {
    /* display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem; */

    .tab-menu {
      display: inline-block;
      cursor: pointer;
      padding: 12px;
      margin-right: 14px;
      background: var(--Tab-Bg-Default);
      color: var(--Tab-Font-Default);

      :last-child {
        margin-right: 0;
      }

      &.active {
        color: var(--Tab-Font-Current);
        border-bottom: 2px solid var(--Tab-Underline-Current);
        background: var(--Tab-Bg-Current);
        font-weight: bold;
      }
    }
  }
  .tab-content {
    overflow: auto;
    .view-information-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      align-content: center;
      gap: 1rem;
    }
  }

  .share-list-search-container {
    display: grid;
    grid-template-columns: repeat(2, 10rem) 8rem;
    grid-template-rows: auto;
    align-content: center;
    gap: 1rem;
  }

  .divider {
    margin: 2rem 0;
    border: 1px solid var(--gray-200, #eaecf0);
  }

  .modal-content {
    width: 640px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: var(--Gray-600);
  }

  .change-status-content {
    padding: 20px;
    width: 384px;
  }

  .category-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    height: 500px;

    .category {
      padding: 1rem 2rem;
      border-right: 1px solid var(--Gray-200);
      .category-item {
        display: flex;
        align-items: center;
        padding: 8px;
        border-radius: 8px;
        background: var(--Base-White);
        align-items: center;
        cursor: pointer;

        &.active {
          background: var(--Primary-600);
          color: var(--Base-White);
        }
      }
    }

    .category-view {
      padding: 1rem 2rem;
      .view-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .view-link {
          cursor: pointer;
          color: var(--Primary-700);
          font-weight: 600;
        }
      }
    }
  }

  .search-content {
    padding: 10px;
    border-bottom: 1px solid var(--Gray-200);
  }

  .search-input {
    position: relative;
    color: var(--Gray-500);

    .input-field {
      box-sizing: border-box;
      padding: 10px;
      padding-left: 40px;
      border: none;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
    }

    .start-icon {
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .end-icon {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .preview-button {
    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
    color: var(--Primary-700);
  }

  .headline {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }

  .error {
    color: var(--Error-600);
  }

  .error-border {
    border: 1px solid var(--Error-300);
  }
`

function MyFilesShareService() {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [tabMenu, setTabMenu] = useState('home')

  const toggleTab = (tab) => {
    setTabMenu(tab)
    switch (tab) {
      case 'home':
        navigate('/share-service/home')
        break
      case 'announcement':
        navigate('/share-service/announcement')
        break
      case 'company-information':
        navigate('/share-service/company-information')
        break
      case 'working-detail':
        navigate('/share-service/working-detail')
        break
      case 'compensation-tax':
        navigate('/share-service/compensation-tax')
        break
      case 'my-files':
        navigate('/share-service/my-files')
        break
      default:
        navigate('/share-service/home')
    }
  }

  useEffect(() => {
    const tab = pathname.split('/')[2]
    setTabMenu(tab)
  }, [pathname])

  return (
    <Div>
      <div className="tab-menu-container">
        <span className={`tab-menu ${tabMenu === 'home' ? 'active' : ''}`} onClick={() => toggleTab('home')}>
          Home
        </span>
        <span
          className={`tab-menu ${tabMenu === 'announcement' ? 'active' : ''}`}
          onClick={() => toggleTab('announcement')}
        >
          Announcement
        </span>
        <span
          className={`tab-menu ${tabMenu === 'company-information' ? 'active' : ''}`}
          onClick={() => toggleTab('company-information')}
        >
          Company information
        </span>
        <span
          className={`tab-menu ${tabMenu === 'working-detail' ? 'active' : ''}`}
          onClick={() => toggleTab('working-detail')}
        >
          Working detail
        </span>
        <span
          className={`tab-menu ${tabMenu === 'compensation-tax' ? 'active' : ''}`}
          onClick={() => toggleTab('compensation-tax')}
        >
          Compensation and Tax
        </span>
        <span className={`tab-menu ${tabMenu === 'my-files' ? 'active' : ''}`} onClick={() => toggleTab('my-files')}>
          My files
        </span>
      </div>
      <h1>MyFilesShareService</h1>
    </Div>
  )
}

export default MyFilesShareService
