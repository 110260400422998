import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getDepartmentsAndPositions } from '../../../../redux/actions/master'
import { selectMaster } from '../../../../redux/selectors'
import { dlog } from '../../../../utils/helper'
import Dropdown from '../../../form/Dropdown'
import Button from '../../../form/button/Button'
import SearchSuggestEmployee from '../../../widget/combo/SearchSuggestEmployee'
import ImageButton from '../../../widget/element/ImageButton'
import IconBin from '../../../widget/icon/IconBin'

import { useTranslation } from 'react-i18next'

export default function Team({ type, data, onDataChange }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { departments } = useSelector(selectMaster)
  const [departmentList, setDepartmentList] = useState([])
  const [teamList, setTeamList] = useState([])
  const [teamMemberList, setTeamMemberList] = useState([])
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))
  const [defaultList, setDefaultList] = useState([
    {
      text: 'Default',
      value: 'Default',
    },
  ])
  const [isSetDeafault, setIsSetDeafault] = useState(false)

  useEffect(() => {
    if (employee_info && !isSetDeafault) {
      let tmpList = [...defaultList]
      let obj = {
        text: employee_info.department,
        value: employee_info.department,
      }
      tmpList.push(obj)
      setDefaultList([...tmpList])
      setIsSetDeafault(true)
    }
  }, [employee_info])

  useEffect(() => {
    dispatch(getDepartmentsAndPositions())
  }, [dispatch])

  useEffect(() => {
    const newList = departments.map((v) => ({
      text: v?.name,
      value: v?.id,
      // positions: v?.positions,
    }))
    setDepartmentList(newList)
  }, [departments])

  useEffect(() => {
    if (type == 'new_project') {
      if (data?.task_teams?.length > 0) {
        let tmpTeamList = [...data.task_teams]
        let tmpMemberList = []
        for (let i = 0; i < tmpTeamList.length; i++) {
          let tmpObj = {
            department_selected_id: tmpTeamList[i]?.department_selected_id || '',
            member_id: tmpTeamList[i]?.member_id || '',
          }
          tmpMemberList.push(tmpObj)
        }
        setTeamMemberList([...tmpMemberList])
      }
    } else {
      if (data?.project_task_teams?.length > 0) {
        let tmpList = [...data.project_task_teams]
        let tmpTeamList = []

        for (let i = 0; i < tmpList.length; i++) {
          if (tmpList[i]?.employees[0]?.id != data?.project_manager?.id) {
            let tmpObj = {
              department_selected_id: tmpList[i]?.employees[0]?.department?.id || null,
              member_id: tmpList[i]?.employees[0]?.id || '',
            }
            tmpTeamList.push(tmpObj)
          }
        }
        setTeamMemberList([...tmpTeamList])
      }
    }
  }, [data])

  const handleClickBtnAdd = () => {
    setTeamMemberList([
      ...teamMemberList,
      {
        department_selected_id: '',
        member_id: '',
      },
    ])
  }
  const handleDepartmentSelected = (value, index) => {
    let tmpMemberList = [...teamMemberList]
    tmpMemberList[index].department_selected_id = value

    setTeamMemberList([...tmpMemberList])
  }
  const handleClickSelectEmployee = (employeeData, index) => {
    if (employeeData) {
      let tmpMemberList = [...teamMemberList]
      let isRepeat = checkRepeatMember(tmpMemberList, employeeData?.id)
      if (isRepeat) {
        tmpMemberList.splice(index, 1)
        setTeamMemberList([...tmpMemberList])
        alert('คุณได้เลือกบุคคลนี้ไปแล้ว กรุณาเลือกใหม่อีกครั้ง')
      } else {
        tmpMemberList[index].member_id = employeeData?.id
        setTeamMemberList([...tmpMemberList])
        if (onDataChange) {
          onDataChange(tmpMemberList)
        }
      }
    }
  }

  const checkRepeatMember = (memberList, newid) => {
    for (let i = 0; i < memberList.length; i++) {
      if (memberList[i].member_id == newid) {
        return true
      }
    }
    return false
  }

  const handleRemoveTeamMember = (index) => {
    let tmpMemberList = [...teamMemberList]
    tmpMemberList.splice(index, 1)
    setTeamMemberList([...tmpMemberList])
    if (type == 'edit_project') {
      if (onDataChange) {
        onDataChange(tmpMemberList) //tmpMemberList
      }
    }
  }

  return (
    <Styled>
      <div className="content-container team">
        <div className="wrp-input-default-wrapper">
          <div className="title">{t('team')}</div>
          {departmentList && (
            <div className="wrp-input-default" key={'person-for-team-owber'}>
              <div className="dropdown-add-person">
                <p className="txt-title-input">{t('department')}</p>
                <Dropdown
                  optionList={defaultList}
                  value={employee_info ? employee_info.department : 'Default'}
                  placeHolder={'เลือก Department'}
                  disabled={true}
                />
              </div>
              <div className="wrp-search-box pdr-48">
                <SearchSuggestEmployee
                  placeHolder={t('search_name')}
                  selectedId={employee_info.id}
                  onSelectedSuggest={(e) => handleClickSelectEmployee(e, index)}
                  isDisabled={true}
                />
              </div>
            </div>
          )}
        </div>

        {teamMemberList?.map((item, index) => (
          <div className="wrp-input-add-person mb-16" key={'person-for-team-' + index}>
            <div className="dropdown-add-person">
              <p className="txt-title-input">{t('department')}</p>
              <Dropdown
                optionList={departmentList}
                value={item.department_selected_id || ''}
                onChange={(e) => handleDepartmentSelected(e, index)}
                placeHolder={'เลือก Department'}
              />
            </div>
            <div className="wrp-search-box">
              <SearchSuggestEmployee
                placeHolder={t('search_name')}
                selectedId={item.member_id}
                departmentId={item.department_selected_id}
                onSelectedSuggest={(e) => handleClickSelectEmployee(e, index)}
                isFilterByDepartment={item.department_selected_id != '' ? true : false}
                isDisabled={item.department_selected_id == ''}
                isDisableOwnerId={true}
              />
            </div>
            <div className="btn-delete-team-member">
              <ImageButton onClick={() => handleRemoveTeamMember(index)} size={38} icon={<IconBin />} />
            </div>
          </div>
        ))}
        <Button onClick={() => handleClickBtnAdd()}>{t('add')}</Button>
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  .content-container {
    padding: 4px 20px 20px;
  }
  .wrp-input-default {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 100%;
  }
  .title {
    color: var(--Gray-700);
    font-weight: 600;
    margin-bottom: 8px;
  }
  .wrp-input-default {
    display: flex;
    align-items: flex-end;
  }
  .wrp-input-add-person {
    display: flex;
    align-items: flex-end;

    svg {
      position: relative;
      top: 5px;
    }
    .wrp-search-input {
      width: 100%;
    }
    .btn-delete-team-member{
      margin-left: 10px;
    }
  }
  .mb-16 {
    margin-bottom: 16px;
  }

  .dropdown-add-person {
    width: 250px !important;
    margin-right: 16px;
  }
  .wrp-search-box {
    flex: 1;

    .wrp-search-input {
      width: 100%;
    }
  }
  .pdr-48 {
    padding-right: 48px;
  }
  .txt-title-input {
    padding-bottom: 6px;
    margin: 0;
  }
`
