import { getAPI, postAPI, putAPI } from '../../utils/api'
import {
  GET_SOW_BY_DEPARTMENT,
  GET_POSITIONS,
  GET_POSITION_BY_ID,
  CREATE_POSITION,
  UPDATE_POSITION,
  GET_POSITIONS_DROPDOWN,
  DELETE_POSITION,
} from '../actionTypes'

import { showLoading, hideLoading } from './loading'

export const getPositions =
  (filter = { limit: 10, skip: 1 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_POSITIONS.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const params = {
        /*type: 'api',*/
        'filters[department][id]': filter.departmentId,
        'pagination[page]': filter.skip,
        'pagination[pageSize]': filter.limit,
        /*'filters[query]': filter.filters?.length > 0 ? JSON.stringify(filter.filters) : '[]',*/ //--- Fix เอาออกก่อน
      }
      if (filter.status != '') params['filters[status]'] = filter.status

      const { data, meta } = await getAPI({
        url: '/api/positions',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_POSITIONS.SUCCESS,
        data,
        meta,
      })
    } catch (error) {
      return dispatch({
        type: GET_POSITIONS.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Position List Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getPositionsDropdown =
  (filter = { limit: 10000, skip: 1 }) =>
  async (dispatch) => {
    try {
      dispatch(showLoading())
      dispatch({ type: GET_POSITIONS_DROPDOWN.REQUEST })
      const { jwt } = JSON.parse(localStorage.getItem('admin'))
      const headers = {
        Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json',
      }
      const params =
        filter.status != ''
          ? {
              /*type: 'api',*/
              'filters[name][$contains]': filter.name,
              'filters[status][$eq]': filter.status,
              'filters[id][$ne]': filter.id,
              'filters[department][id]': filter.departmentId,
              'filters[status][$ne]': 'delete',
              'pagination[page]': filter.skip,
              'pagination[pageSize]': filter.limit,
            }
          : {
              /*type: 'api',*/
              'filters[name][$contains]': filter.name,
              'filters[department][id]': filter.departmentId,
              'filters[id][$ne]': filter.id,
              'filters[status][$ne]': 'delete',
              'pagination[page]': filter.skip,
              'pagination[pageSize]': filter.limit,
            }
      const { data } = await getAPI({
        url: '/api/positions',
        headers: headers,
        params,
      })

      return dispatch({
        type: GET_POSITIONS_DROPDOWN.SUCCESS,
        data,
      })
    } catch (error) {
      return dispatch({
        type: GET_POSITIONS_DROPDOWN.FAILURE,
        error,
        showAlert: true,
        alertMessage: 'Get Position List for Dropdown Fail',
      })
    } finally {
      dispatch(hideLoading())
    }
  }

export const getPositionById = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_POSITION_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/positions/${id}?type=api`,
      headers: headers,
    })

    return dispatch({
      type: GET_POSITION_BY_ID.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_POSITION_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Position By Id Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const createPosition = (data, id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_POSITION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await postAPI({
      url: `/api/positions`,
      data,
      headers: headers,
    })

    const filter = { limit: 10, skip: 1, departmentId: id }
    dispatch(getPositions(filter))

    return dispatch({
      type: CREATE_POSITION.SUCCESS,
      showAlert: true,
      alertMessage: 'Create Position Successful',
    })
  } catch (err) {
    return dispatch({
      type: CREATE_POSITION.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Create Position Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const updatePosition = (id, departmentId, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_POSITION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `api/positions/${id}`,
      data,
      headers: headers,
    })

    const filter = { limit: 10, skip: 1, departmentId: departmentId }
    dispatch(getPositions(filter))
    dispatch(getPositionById(id))

    return dispatch({
      type: UPDATE_POSITION.SUCCESS,
      showAlert: true,
      alertMessage: 'Update Position Successful',
    })
  } catch (err) {
    return dispatch({
      type: UPDATE_POSITION.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Update Position Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const deletePositionStatus = (id, departmentId, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: DELETE_POSITION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `api/positions/${id}`,
      data,
      headers: headers,
    })

    const filter = { limit: 10, skip: 1, departmentId: departmentId }
    dispatch(getPositions(filter))
    dispatch(getPositionById(id))

    return dispatch({
      type: DELETE_POSITION.SUCCESS,
      showAlert: true,
      alertMessage: 'Delete Position Successful',
    })
  } catch (err) {
    return dispatch({
      type: DELETE_POSITION.FAILURE,
      error: err,
      showAlert: true,
      alertMessage: 'Delete Position Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getSowByDepartment = (departmentId) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_SOW_BY_DEPARTMENT.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    const params = {
      type: 'api',
      'filters[department][id]': departmentId,
      'filters[status][$ne]': 'delete',
      'pagination[page]': 1,
      'pagination[pageSize]': 10000,
    }
    const { data } = await getAPI({
      url: '/api/task-scopes',
      headers: headers,
      params,
    })

    return dispatch({
      type: GET_SOW_BY_DEPARTMENT.SUCCESS,
      data,
    })
  } catch (error) {
    return dispatch({
      type: GET_SOW_BY_DEPARTMENT.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Scope of work List By Department Fail',
    })
  } finally {
    dispatch(hideLoading())
  }
}

export const getPositionByIdReturnData = (id) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_POSITION_BY_ID.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    const { data } = await getAPI({
      url: `/api/positions/${id}?type=api`,
      headers: headers,
    })

    return {type : "getPositionByIdReturnData_SUCCESS" , data_info : data};

  } catch (error) {
    return {type : "getPositionByIdReturnData_ERROR"};
    /*
    return dispatch({
      type: GET_POSITION_BY_ID.FAILURE,
      error,
      showAlert: true,
      alertMessage: 'Get Position By Id Fail',
    })
    */
  } finally {
    dispatch(hideLoading())
  }
}


export const getPositionLevelReturnData = (filter = { limit: 1000, skip: 0 }) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: GET_POSITION_BY_ID.REQUEST })
    const { jwt, company_info } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }

    let params = {
      'pagination[page]': filter.skip,
      'pagination[pageSize]': filter.limit,
      'filters[active]' : 1 ,//--- เฉพาะรายการที่ยังไม่ถูกลบ,
      'filters[company][id]' : company_info?.id ? company_info?.id : null
    }

    const { data, meta } = await getAPI({
      url: '/api/position-levels',
      headers: headers,
      params,
    })
    return {type : "getPositionLevelReturnData_SUCCESS" , data_info : data , data_meta : meta};
  } catch (error) {
    return {type : "getPositionLevelReturnData_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
} 

export const CreatePositionLevel = (inp_data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: CREATE_POSITION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    //alert(JSON.stringify(inp_data))
    const { data } = await postAPI({
      url: `/api/position-levels`,
      data:inp_data,
      headers: headers,
    })

    return {type : "CreatePositionLevel_SUCCESS" , data_info : data};

  } catch (err) {
    return {type : "CreatePositionLevel_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}

export const updatePositionLevel = (id, data) => async (dispatch) => {
  try {
    dispatch(showLoading())
    dispatch({ type: UPDATE_POSITION.REQUEST })
    const { jwt } = JSON.parse(localStorage.getItem('admin'))
    const headers = {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    }
    await putAPI({
      url: `/api/position-levels/${id}`,
      data,
      headers: headers,
    })

    return {type : "updatePositionLevel_SUCCESS"};

  } catch (err) {
    return {type : "updatePositionLevel_ERROR"};
  } finally {
    dispatch(hideLoading())
  }
}
