import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyInfo } from '../../utils/common'
import { postAPIdiscord } from '../../utils/apidiscord'

import { createDailySummary,getDailySummaryReturnData } from '../../redux/actions/dailysummary'
import { getServerTime } from '../../redux/actions/master'

import dayjs from 'dayjs'

import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


import InputTextAreaV2024 from '../../components/widget/element/InputTextAreaV2024'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconBin from '../../assets/images/icon-bin.svg'
import iconFile from '../../assets/images/icon-file-round.svg'

import FileUploadProgress from '../../components/form/FileUploadProgress'
import { postAPI } from '../../utils/api'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import { useTranslation } from 'react-i18next'

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css{
      color: #7F56D9;
      font-weight: bold;
    }
  } 
  
  .content_header {
    padding: 14px 14px;
    
    width: 400px;
    position: fixed;
    background: #FFFFFF;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 118px;
    padding-bottom: 106px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width : 400px;
    background-color : #FFFFFF;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
`

const acceptList = {
  images: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files: {
    'text/csv': [],
    'application/pdf': [],
    'application/msword': [],
    'application/vnd.ms-excel': [],
    'application/zip': [],
  },
  videos: {
    'video/x-msvideo': [],
    'video/mp4': [],
    'video/mpeg': [],
    'video/webm': [],
  },
  audios: {
    'audio/mpeg': [],
    'audio/wav': [],
    'audio/webm': [],
  },
}

const NewDailySummary = ({ onClose, onSubmit }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const CompanyInfo = getCompanyInfo()

  const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
  //

  const [NewDailySummaryData, setNewDailySummaryData] = useState({
    what_done_today: null,
    result: null,
    problems: null,
    how_problem_was_solved: null,
    tomorrow_plan: null,
    dailysummary_date_start: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    dailysummary_date_end: dayjs().add(1, 'minute').format('YYYY-MM-DD HH:mm:ss'),
    created_by_id: null,
    employee : employee_info?.id,
    dailysummary_date : dayjs().format('YYYY-MM-DD'),
    file: null, //--- Lise File_ID //-- file: [1]
    //---- ฟิวใหม่For Phane2 04/09/2023
    company : CompanyInfo?.id,
  })
  const [ServerTime, setServerTime] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'));
  useEffect(() => {
    dispatch(getServerTime()).then(({ type,data_info }) => {
      if(type.endsWith('_SUCCESS')){
        //alert(JSON.stringify(data_info))
        if(data_info){
          setServerTime(dayjs(data_info).format('YYYY-MM-DD HH:mm:ss'));
          const setData = {
            what_done_today: null,
            result: null,
            problems: null,
            how_problem_was_solved: null,
            tomorrow_plan: null,
            dailysummary_date_start: dayjs(data_info).format('YYYY-MM-DD HH:mm:ss'),
            dailysummary_date_end: dayjs(data_info).add(1, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            created_by_id: null,
            employee : employee_info?.id,
            dailysummary_date : dayjs(data_info).format('YYYY-MM-DD'),
            file: null, //--- Lise File_ID //-- file: [1]
            //---- ฟิวใหม่For Phane2 04/09/2023
            company : CompanyInfo?.id,
          }
          setNewDailySummaryData(setData)
        }else{
          setTitleFail('Error Connect API');
          setnameItemFail('');
          setDescriptionFail(''); 
          setFailModal(true)
        }

      }else{
        setTitleFail('Error Connect API');
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }
    })
  }, [dispatch])

  const [ModalConfirmDialogLeave, setModalConfirmDialogLeave] = useState(false)
  const ConfirmDialogLeave = () => {
    setModalConfirmDialogLeave(true)
  }

  const [nameItem, setNameItem] = useState('AA')
  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [EmployeeIdCalendar, setEmployeeIdCalendar] = useState(employee_info?.id ? employee_info?.id : -99)
  const ConfirmDialog = async () => {
    if(EmployeeIdCalendar < 0){
      setTitleFail(t('you_may_not_fully_use_this_feature_since_you_are_not_employee'));
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }else{
      //--- ST ส่ง Discord
      try {
        const full_name_employee = user.first_name + ' ' + user.last_name  + ' (' + employee_info.employ_id + ')';
        const text_to_discode = `\n ---------TimeStam : ${ServerTime} -------------- \n ${full_name_employee} ได้สร้าง Daily Summary Report ของวันที่ ${dayjs(NewDailySummaryData.dailysummary_date).format('D MMM YYYY')}  \n ### input_data ### \n [${JSON.stringify(NewDailySummaryData)}]`;
        const data_to_discord = {
          "content" : text_to_discode.substring(0, 1800),
        };
        await postAPIdiscord({
          url: `https://discord.com/api/webhooks/1192396987446853632/O1IAXMn-k91utBVskLbkXDLf1QUy1sOP0okSNbNLD4DFyjZi8ugBUtkFRMkMOANNlSia`,
          data : data_to_discord,
          headers: {
            'Content-Type': 'application/json',
          },
        })
      } catch (error) {
        console.log(error);
      }

      //--- ED ส่ง Discord

      const filter = {
        skip: 1,
        limit: 10000,
        active : 1,
        EmployeeId: EmployeeIdCalendar,
        filter_date : NewDailySummaryData.dailysummary_date
      }
  
      dispatch(getDailySummaryReturnData(filter)).then(({ type,data_info }) => {
          if(type.endsWith('_SUCCESS')){
            if(data_info.length > 0){
              const this_id = data_info[0].id;
              setTitleFail('Daily Summary Report ' + dayjs(NewDailySummaryData.dailysummary_date).format('D MMM YYYY') + ' ถูกสร้างแล้ว');
              setnameItemFail('');
              setDescriptionFail(''); 
              setFailModal(true)
            }else{
              setModalConfirmDialog(true)
            }         
          }else{
            setModalConfirmDialog(true)
          }  
      })
    }

    
  }
  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)
  const CreateNewDailySummary = () => {
    setModalConfirmDialog(false);
    const sent_data_api = {
      data: NewDailySummaryData,
    }
    dispatch(createDailySummary(sent_data_api)).then(({ type }) => {
      if(type.endsWith('_SUCCESS')){
        setTitlesuccess('Daily summary report has been created');
        setSubTitlesuccess('');
        setDescriptionsuccess(''); 
        setsuccessModal(true)
      }else{
        setTitleFail('Error Create');
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }     
    })
    
    //onSubmit()
  }


 



  //--- ST UPLOAD FILE
  const [file_resume, setFile_resume] = useState([])
  const multiple = true;
  const error = false;
  const disabled = false;

  const handleDeleteFile = (id) => {
    setFile_resume(file_resume.filter((v) => v.id !== id))
    setNewDailySummaryData({ ...NewDailySummaryData, file: NewDailySummaryData.file.filter((v) => v !== id) })
    //onChange(file.filter((v) => v.id !== id))
  }
  /*
  const handleAddFile = async (value) => {
    const fetchList = []
    value.forEach((v) => {
      const formData = new FormData()
      formData.append('files', v)
      formData.append(
        'fileInfo',
        JSON.stringify({
          name: v.name,
          caption: v.name,
          alternativeText: v.name,
          folder: null,
        })
      )

      fetchList.push(
        postAPI({
          url: '/api/upload',
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: null,
          },
          data: formData,
        })
      )
    })

    const res = await Promise.all(fetchList)

    const result = multiple ? [...file_resume, ...res.map((v) => v[0])] : res[0]

    
    console.table(res.map((v) => v[0].id))
    //setNewDailySummaryData({ ...NewDailySummaryData, file: [...NewDailySummaryData.file,res.map((v) => v[0].id)] })
    
    if (NewDailySummaryData.file) {
      setNewDailySummaryData({ ...NewDailySummaryData, file: [...NewDailySummaryData.file,...res.map((v) => v[0].id)] })
    } else {
      setNewDailySummaryData({ ...NewDailySummaryData, file: res.map((v) => v[0].id) })
    }
    
    setFile_resume(result)
    //onChange(result)
  }
  */
  const handleAddFile = async (file_id) => {
    //setNewDailySummaryData({ ...NewDailySummaryData, file: [...NewDailySummaryData.file,file_id] })
    
    if (NewDailySummaryData.file) {
      setNewDailySummaryData({ ...NewDailySummaryData, file: [...NewDailySummaryData.file,file_id] })
    } else {
      setNewDailySummaryData({ ...NewDailySummaryData, file: [file_id] })
    }
    
  }
  const allowedTypes = [];
  const acceptType = allowedTypes.reduce((pre, cur) => {
    return {
      ...pre,
      ...acceptList[cur],
    }
  }, {})
  //--- ED UPLOAD FILE

  return (
    <Div>
      <div className="content_header">
        <header>
          <div className="content-title">{t('create_daily_summary_report_header')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={ConfirmDialogLeave} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">{t('create_daily_summary_report_sub_header')}</div>
        <div className="css_header_detail">
            <div>{user.firstname} {user.lastname}</div>
            <div className="date_css">{dayjs(ServerTime).format('D MMM YYYY')}</div>
        </div>
        <hr />
      </div>
      
      <div className="dialog-content">
        <div className="content">
          <div className="topic_main">{t('create_daily_summary_report_what_done_today')}</div>
          <div>
            <InputTextAreaV2024
              className="InputTextArea_css"
              initValue={NewDailySummaryData.what_done_today}
              onChange={(e) => setNewDailySummaryData({ ...NewDailySummaryData, what_done_today: e.target.value })}
              placeHolder={t('create_daily_summary_report_what_done_today_placeholder')}
              borderColor={'var(--Gray-300)'}
              borderWidth="1px"
            />
          </div>
          <div className="topic_main">{t('create_daily_summary_report_result')}</div>
          <div>
            <InputTextAreaV2024
              className="InputTextArea_css"
              initValue={NewDailySummaryData.result}
              onChange={(e) => setNewDailySummaryData({ ...NewDailySummaryData, result: e.target.value })}
              placeHolder={t('create_daily_summary_report_result_placeholder')}
              borderColor={'var(--Gray-300)'}
              borderWidth="1px"
            />
          </div>
          <div className="topic_main">{t('create_daily_summary_report_problems')}</div>
          <div>
            <InputTextAreaV2024
              className="InputTextArea_css"
              initValue={NewDailySummaryData.problems}
              onChange={(e) => setNewDailySummaryData({ ...NewDailySummaryData, problems: e.target.value })}
              placeHolder={t('create_daily_summary_report_problems_placeholder')}
              borderColor={'var(--Gray-300)'}
              borderWidth="1px"
            />
          </div>
          <div className="topic_main">{t('create_daily_summary_report_how_problem_was_solved')}</div>
          <div>
            <InputTextAreaV2024
              className="InputTextArea_css"
              initValue={NewDailySummaryData.how_problem_was_solved}
              onChange={(e) =>
                setNewDailySummaryData({ ...NewDailySummaryData, how_problem_was_solved: e.target.value })
              }
              placeHolder={t('create_daily_summary_report_how_problem_was_solved_placeholder')}
              borderColor={'var(--Gray-300)'}
              borderWidth="1px"
            />
          </div>
          <div className="topic_main">{t('create_daily_summary_report_tomorrow_plan')}</div>
          <div>
            <InputTextAreaV2024
              className="InputTextArea_css"
              initValue={NewDailySummaryData.tomorrow_plan}
              onChange={(e) => setNewDailySummaryData({ ...NewDailySummaryData, tomorrow_plan: e.target.value })}
              placeHolder={t('create_daily_summary_report_tomorrow_plan_placeholder')}
              borderColor={'var(--Gray-300)'}
              borderWidth="1px"
            />
          </div>
          <div className="css_upload">
            <FileUploadProgress
              multiple={multiple}
              accept={acceptType}
              file={file_resume.map((v) => ({ ...v, url: `${v.url}` }))}
              onAddFile={handleAddFile}
              onDeleteFile={handleDeleteFile}
              error={error}
              disabled={disabled}
              FileListData={file_resume}
              setFileListData={setFile_resume}
            />
          </div>
        </div>
      </div>
      
      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={ConfirmDialogLeave}>
          {t('cancel')}
        </ButtonOutline>
        <Button className="button_margin" onClick={ConfirmDialog}>
          {t('submit')}
        </Button>
      </div>

        <DialogConfirm
          open={Boolean(ModalConfirmDialog)}
          onClose={() => setModalConfirmDialog(false)}
          onSubmit={CreateNewDailySummary}
          icon={question}
          title={t('confirm_create_daily_summary_report_header')}
          description=""
          textYes={t('confirm')}
          textNo={t('back')}
        />

        <DialogConfirm
          open={Boolean(ModalConfirmDialogLeave)}
          onClose={() => setModalConfirmDialogLeave(false)}
          onSubmit={onClose}
          icon={question}
          title={t('leave_create_daily_summary_report_header')}
          textYes={t('leave')}
          textNo={t('cancel')}
        />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

        <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />
    </Div>
  )
}

export default NewDailySummary
