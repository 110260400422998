import moment from 'moment'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import RadioButton from '../../../../components/form/RadioButton'
import TextField from '../../../../components/form/TextField'
import IconClockRefresh from '../../../../components/widget/icon/IconClockRefresh'
import IconEdit from '../../../../components/widget/icon/IconEdit'
import IconXClose from '../../../../components/widget/icon/IconXClose'

export default function DocumentDetails({ docType, settingData, onDataChange, isReset }) {
  const mockupData = [
    {
      text: 'Year/Month0001',
      value: 'Year/Month0001',
    },
    {
      text: 'Year000001',
      value: 'Year000001',
    },
  ]

  const resetRunningNumberList = [
    {
      text: 'Every Month',
      value: 'Every Month',
    },
    {
      text: 'Every Year',
      value: 'Every Year',
    },
  ]

  const [runningNumberTypeList, setRunningNumberTypeList] = useState([])

  const [selectRunningNumberType, setSelectRunningNumberType] = useState('')
  const [selectResetRunningNumber, setSelectResetRunningNumber] = useState('Not Reset')

  const [inputTextPrefix, setInputTextPrefix] = useState('')
  // const [inputDate, setInputDate] = useState('')
  // const [inputNumber, setInputNumber] = useState('')
  const [inputNumberCopy, setInputNumberCopy] = useState('')

  const [isEditFormat, setIsEditFormat] = useState(false)
  const [isEditPrefix, setIsEditPrefix] = useState(false)

  const [documentSettingData, setDocumentSettingData] = useState()

  useEffect(() => {
    const load = async () => {
      if (docType != '' && settingData) {
        // console.log('docType: ' + JSON.stringify(docType, null, 2))
        // console.log('settingData: ' + JSON.stringify(settingData, null, 2))
        // console.log('settingData: ' + JSON.stringify(settingData[docType], null, 2))
        if(settingData[docType]) {
          let tmpText = settingData[docType]?.prefix + "000001";
          setRunningNumberTypeList([{ text: tmpText, value: tmpText }, ...mockupData])
          // setSelectRunningNumberType(tmpText)
          setSelectRunningNumberType(settingData[docType]?.running_number_type != "" ? settingData[docType]?.running_number_type : tmpText)
          setInputTextPrefix(settingData[docType]?.prefix)
          setInputNumberCopy(settingData[docType]?.number_of_copy)
          setSelectResetRunningNumber(settingData[docType]?.reset_running_number)
          
        }

        // setSelectRunningNumberType(settingData?.quotation_running_number_type)
        // setInputTextPrefix(settingData?.quotation_prefix)
        // setInputNumberCopy(settingData?.quotation_number_of_copy)
        // setSelectResetRunningNumber(settingData?.quotation_reset_running_number)
      }
      // if (docType == 'quotation') {
      //   setRunningNumberTypeList([{ text: 'QT000001', value: 'QT000001' }, ...mockupData])
      //   setSelectRunningNumberType('QT000001')
      // } else if (docType == 'billing') {
      //   setRunningNumberTypeList([{ text: 'BL000001', value: 'BL000001' }, ...mockupData])
      //   setSelectRunningNumberType('BL000001')
      // } else if (docType == 'invoice') {
      //   setRunningNumberTypeList([{ text: 'INV000001', value: 'INV000001' }, ...mockupData])
      //   setSelectRunningNumberType('INV000001')
      // } else if (docType == 'receipt') {
      //   setRunningNumberTypeList([{ text: 'RE000001', value: 'RE000001' }, ...mockupData])
      //   setSelectRunningNumberType('RE000001')
      // } else if (docType == 'purchase_order') {
      //   setRunningNumberTypeList([{ text: 'PO000001', value: 'PO000001' }, ...mockupData])
      //   setSelectRunningNumberType('PO000001')
      // }
    }
    load()
  }, [docType, settingData])

  // useEffect(() => {
  //   if (settingData && docType) {
  //     if (docType == 'quotation') {
  //       setSelectRunningNumberType(settingData?.quotation_running_number_type)
  //       setInputTextPrefix(settingData?.quotation_prefix)
  //       setInputNumberCopy(settingData?.quotation_number_of_copy)
  //       setSelectResetRunningNumber(settingData?.quotation_reset_running_number)
  //     } else if (docType == 'billing') {
  //       setSelectRunningNumberType(settingData?.billing_running_number_type)
  //       setInputTextPrefix(settingData?.billing_prefix)
  //       setInputNumberCopy(settingData?.billing_number_of_copy)
  //       setSelectResetRunningNumber(settingData?.billing_reset_running_number)
  //     } else if (docType == 'invoice') {
  //       setSelectRunningNumberType(settingData?.invoice_running_number_type)
  //       setInputTextPrefix(settingData?.invoice_prefix)
  //       setInputNumberCopy(settingData?.invoice_number_of_copy)
  //       setSelectResetRunningNumber(settingData?.invoice_reset_running_number)
  //     } else if (docType == 'receipt') {
  //       setSelectRunningNumberType(settingData?.receipt_running_number_type)
  //       setInputTextPrefix(settingData?.receipt_prefix)
  //       setInputNumberCopy(settingData?.receipt_number_of_copy)
  //       setSelectResetRunningNumber(settingData?.receipt_reset_running_number)
  //     } else if (docType == 'purchase_order') {
  //       setSelectRunningNumberType(settingData?.purchase_running_number_type)
  //       setInputTextPrefix(settingData?.purchase_prefix)
  //       setInputNumberCopy(settingData?.purchase_number_of_copy)
  //       setSelectResetRunningNumber(settingData?.purchase_reset_running_number)
  //     }
  //   }
  // }, [settingData, docType])

  // useEffect(() => {

  //   let tmpObj = {}
  //   if (docType == 'quotation') {
  //     tmpObj.quotation_running_number_type = selectRunningNumberType
  //     tmpObj.quotation_prefix = inputTextPrefix
  //     tmpObj.quotation_number_of_copy = inputNumberCopy
  //     tmpObj.quotation_reset_running_number = selectResetRunningNumber
  //   } else if (docType == 'purchase') {
  //     tmpObj.purchase_running_number_type = selectRunningNumberType
  //     tmpObj.purchase_prefix = inputTextPrefix
  //     tmpObj.purchase_number_of_copy = inputNumberCopy
  //     tmpObj.purchase_reset_running_number = selectResetRunningNumber
  //   }
  //   if (onDataChange) {
  //     onDataChange(tmpObj)
  //   }
  // }, [selectRunningNumberType, inputTextPrefix, inputNumberCopy, selectResetRunningNumber])

  useEffect(() => {
    if (isReset) {
      setIsEditFormat(false)
      setIsEditPrefix(false)
    }
  }, [isReset])

  const handleInputQuotation = (e, type) => {
    let tmpValue = e.target.value

    if (type == 'type') {
      if (tmpValue.length <= 3) {
        setInputTextPrefix(tmpValue)
        if (onDataChange) {
          let tmpObj = {...settingData}
          console.log('tmpObj[docType]: '+JSON.stringify(tmpObj[docType],null,2))
          tmpObj[docType].prefix = tmpValue
          console.log('tmpObj[docType]_AFTER: '+JSON.stringify(tmpObj[docType],null,2))
          // if (docType == 'quotation') {
          //   tmpObj.quotation_prefix = tmpValue
          // } else if (docType == 'billing') {
          //   tmpObj.billing_prefix = tmpValue
          // } else if (docType == 'invoice') {
          //   tmpObj.invoice_prefix = tmpValue
          // } else if (docType == 'receipt') {
          //   tmpObj.receipt_prefix = tmpValue
          // } else if (docType == 'purchase_order') {
          //   tmpObj.purchase_prefix = tmpValue
          // }
          onDataChange(tmpObj)
        }
      }
      // } else if (type == 'date') {
      //   setInputDate(e.target.value)
      // } else if (type == 'number') {
      //   setInputNumber(e.target.value)
    } else if (type == 'number_copy') {
      const re = /^[0-9\b]+$/ //rules
      if (tmpValue === '' || re.test(tmpValue)) {
        if (tmpValue.length <= 4) {
          setInputNumberCopy(tmpValue)
          if (onDataChange) {
            let tmpObj = {...settingData}
            tmpObj[docType].number_of_copy = Number(tmpValue)
            // if (docType == 'quotation') {
            //   tmpObj.quotation_number_of_copy = tmpValue
            // } else if (docType == 'billing') {
            //   tmpObj.billing_number_of_copy = tmpValue
            // } else if (docType == 'invoice') {
            //   tmpObj.invoice_number_of_copy = tmpValue
            // } else if (docType == 'receipt') {
            //   tmpObj.receipt_number_of_copy = tmpValue
            // } else if (docType == 'purchase_order') {
            //   tmpObj.purchase_number_of_copy = tmpValue
            // }
            onDataChange(tmpObj)
          }
        }
      }
    }
  }

  const handleClickEditPrefix = () => {
    setIsEditPrefix(!isEditPrefix)
  }

  const gerCurrentDate = () => {
    if (selectRunningNumberType == 'Year/Month0001') {
      return moment(Date.now()).format('YYYY-MM')
    } else if (selectRunningNumberType == 'Year000001') {
      return moment(Date.now()).format('YYYY')
    } else {
      return '0001'
    }
  }

  const handleSelectResetRunningNumber = (value) => {
    setSelectResetRunningNumber(value)
    if (onDataChange) {
      let tmpObj = {...settingData}
      tmpObj[docType].reset_running_number = value
      // if (docType == 'quotation') {
      //   tmpObj.quotation_reset_running_number = value
      // } else if (docType == 'billing') {
      //   tmpObj.billing_reset_running_number = value
      // } else if (docType == 'invoice') {
      //   tmpObj.invoice_reset_running_number = value
      // } else if (docType == 'receipt') {
      //   tmpObj.receipt_reset_running_number = value
      // } else if (docType == 'purchase_order') {
      //   tmpObj.purchase_reset_running_number = value
      // }
      onDataChange(tmpObj)
    }
  }
  const handleSelectRunningType = (value) => {
    let tmpObj = {...settingData}
    if (value == 'Year000001') {
      tmpObj[docType].reset_running_number = 'Every Year'
      // if (docType == 'quotation') {
      //   tmpObj.quotation_reset_running_number = 'Every Year'
      // } else if (docType == 'billing') {
      //   tmpObj.billing_reset_running_number = 'Every Year'
      // } else if (docType == 'invoice') {
      //   tmpObj.invoice_reset_running_number = 'Every Year'
      // } else if (docType == 'receipt') {
      //   tmpObj.receipt_reset_running_number = 'Every Year'
      // } else if (docType == 'purchase_order') {
      //   tmpObj.purchase_reset_running_number = 'Every Year'
      // }
    } else if (value == 'Year/Month0001') {
      tmpObj[docType].reset_running_number = 'Every Month'
      // if (docType == 'quotation') {
      //   tmpObj.quotation_reset_running_number = 'Every Month'
      // } else if (docType == 'billing') {
      //   tmpObj.billing_reset_running_number = 'Every Month'
      // } else if (docType == 'invoice') {
      //   tmpObj.invoice_reset_running_number = 'Every Month'
      // } else if (docType == 'receipt') {
      //   tmpObj.receipt_reset_running_number = 'Every Month'
      // } else if (docType == 'purchase_order') {
      //   tmpObj.purchase_reset_running_number = 'Every Month'
      // }
    } else {
      setSelectResetRunningNumber('Not Reset')
      tmpObj[docType].reset_running_number = 'Not Reset'
      // if (docType == 'quotation' && value == 'QT000001') {
      //   tmpObj.quotation_reset_running_number = 'Not Reset'
      // } else if (docType == 'billing' && value == 'BL000001') {
      //   tmpObj.billing_reset_running_number = 'Not Reset'
      // } else if (docType == 'invoice' && value == 'INV000001') {
      //   tmpObj.invoice_reset_running_number = 'Not Reset'
      // } else if (docType == 'receipt' && value == 'RE000001') {
      //   tmpObj.receipt_reset_running_number = 'Not Reset'
      // } else if (docType == 'purchase_order' && value == 'PO000001') {
      //   tmpObj.purchase_reset_running_number = 'Not Reset'
      // }
    }
    if (onDataChange) {
      tmpObj[docType].running_number_type = value
      // if (docType == 'quotation') {
      //   tmpObj.quotation_running_number_type = value
      // } else if (docType == 'billing') {
      //   tmpObj.billing_running_number_type = value
      // } else if (docType == 'invoice') {
      //   tmpObj.invoice_running_number_type = value
      // } else if (docType == 'receipt') {
      //   tmpObj.receipt_running_number_type = value
      // } else if (docType == 'purchase_order') {
      //   tmpObj.purchase_running_number_type = value
      // }
      setSelectRunningNumberType(value)
      onDataChange(tmpObj)
    }
  }

  const checkDisableRunningNumberType = () => {
    if (selectRunningNumberType == 'Year/Month0001') {
      return false
    }
    return true
  }

  const setHeaderName = (type) => {
    let textArray = type.split('_')
    let string = textArray.join(' ')
    return string
  }

  return (
    <Styled>
      <div className="top-document-Details">
        <div className="section">
          <div className="title-section">Running Number Types</div>
          <div className="wrp-choice-section">
            {runningNumberTypeList.length > 0 && (
              <div className="choice-section">
                <RadioButton
                  name="runningNumberType"
                  optionList={runningNumberTypeList}
                  value={selectRunningNumberType}
                  handleChange={(e) => handleSelectRunningType(e.target.value)}
                  disabled={!isEditFormat}
                />
              </div>
            )}
            <div
              className="wrp-btn-edit-format"
              onClick={() => {
                setIsEditFormat(!isEditFormat)
              }}
            >
              <div className="btn-reset">
                {isEditFormat ? <IconXClose width="15" color="red" /> : <IconClockRefresh width="20" />}
                <div className={!isEditFormat ? 'text-reset ' : 'text-red'}>
                  {isEditFormat ? 'ยกเลิก' : 'เปลี่ยน Format'}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <div className="title-section">Running Number Types</div>
          <div className="wrp-input-section">
            <div className="type-input">{setHeaderName(docType)}</div>
            <div className="wrp-box-input">
              <TextField
                className={'text-field type'}
                placeholder={'QT'}
                onChange={(e) => handleInputQuotation(e, 'type')}
                value={inputTextPrefix}
                disabled={!isEditPrefix}
              />
              <TextField
                className={'text-field date'}
                placeholder={gerCurrentDate()}
                // onChange={(e) => handleInputQuotation(e, 'date')}
                // value={inputDate}
                disabled={true}
              />
              <TextField
                className={'text-field number last'}
                placeholder={'0001'}
                // onChange={(e) => handleInputQuotation(e, 'number')}
                // value={inputNumber}
                disabled={true}
              />
            </div>

            <div className="wrp-btn-edit-prefix" onClick={handleClickEditPrefix}>
              <div className="btn-edit-prefix">
                {isEditPrefix ? <IconXClose width="15" color="red" /> : <IconEdit />}

                <div className={!isEditPrefix ? 'text-edit-prefix' : 'text-red'}>
                  {isEditPrefix ? 'ยกเลิก' : 'แก้ไข Prefix'}
                </div>
              </div>
            </div>

            <div className="number-of-copy">
              <div className="text-number-of-copy">Number of copy</div>
              <TextField
                className={'text-field number'}
                placeholder={'0001'}
                onChange={(e) => handleInputQuotation(e, 'number_copy')}
                value={inputNumberCopy}
              />
            </div>
          </div>
        </div>

        <div className="section">
          <div className="title-section">Running Number Types</div>
          <div className="wrp-choice-section">
            <div className="choice-section">
              <RadioButton
                name="resetRunningNumber"
                optionList={resetRunningNumberList}
                value={selectResetRunningNumber}
                handleChange={(e) => handleSelectResetRunningNumber(e.target.value)}
                disabled={checkDisableRunningNumberType()}
              />
            </div>
          </div>
        </div>
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .top-document-Details {
    flex: 1;
  }

  .section {
    margin-bottom: 24px;
    .wrp-choice-section {
      display: flex;

      .wrp-btn-edit-format {
        display: flex;
        align-items: center;
      }

      .btn-reset {
        display: flex;
        align-items: center;
        cursor: pointer;

        .text-reset {
          color: #9e77ed;
          margin-left: 10px;
        }
        .text-red {
          color: red !important;
          margin-left: 10px;
        }
      }
    }

    .wrp-input-section {
      display: flex;
      align-items: center;
      padding-top: 16px;

      .type-input {
        margin-right: 16px;
      }
      .wrp-box-input {
        margin-right: 16px;
      }

      .text-field {
        margin-right: 16px;

        &.last {
          margin-right: 0px;
        }
      }
      .type {
        width: 50px;
      }
      .date {
        width: 135px;
      }
      .number {
        width: 75px;
      }

      .btn-edit-prefix {
        display: flex;
        margin-right: 16px;
        cursor: pointer;

        .text-edit-prefix {
          color: #9e77ed;
          margin-left: 10px;
        }
        .text-red {
          color: red !important;
          margin-left: 10px;
        }
      }
      .number-of-copy {
        display: flex;
        align-items: center;

        .text-number-of-copy {
          margin-right: 16px;
        }
      }
    }
  }
`
