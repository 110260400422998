import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getActionPlanById, updateActionPlan, getActionPlanReturnData } from '../../redux/actions/dailysummary'
import { selectDailySummary } from '../../redux/selectors'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import iconPlus from '../../assets/images/icon-plus.svg'

import InputTextArea from '../../components/widget/element/InputTextArea'
import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import iconDelete from '../../assets/images/trash.png'
import iconDuplicate from '../../assets/images/Duplicate.png'
import iconDragDrop from '../../assets/images/DragDrop.png'

import Dropdown from '../../components/form/Dropdown'
import Date from '../../components/form/Date'
import TextField from '../../components/form/TextField'
import TextFieldTime from '../../components/form/TextFieldTime'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'
import { useTranslation } from 'react-i18next'
import { getServerTime } from '../../redux/actions/master'
import { postAPIdiscord } from '../../utils/apidiscord'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import TextEditorV2 from '../../components/widget/element/TextEditorV2'
import { Box, Stack } from '@mui/material'
import Divider from 'components/common/Divider'
import { FiPlusCircle } from 'react-icons/fi'
import Modal from 'components/common/Modal'
import MyTaskModalContent from 'components/feature/my-calendar/MyTaskModalContent'

import { LiaTimesSolid } from 'react-icons/lia'

import SidePanelV2 from 'components/common/SidePanelV2'
import DrawerDetails from 'components/feature/operation-process/default-cards'
import DialogTaskPreviewer from 'components/dialog/DialogTaskPreviewer'

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote'],
    ['link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ header: 2 }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ['clean'],
  ],
  clipboard: {
    matchVisual: false,
  },
}

const Div = styled.div`
  padding: 24px 32px;
  height: 100%;

  .icon {
    cursor: pointer;
  }

  .text-highlight {
    color: var(--Primary-700);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .add-from-my-task-btn {
    color: var(--Primary-700);
    font-weight: 600;

    border: 1px solid var(--Primary-200);
    background: var(--Primary-50);
  }
  .dd_type_of_activity {
    /*width: 250px;*/
    min-width: 280px;
  }
  .box_type_of_activity {
    margin-top: 10px;
  }
  .css_header_detail_how_to {
    font-weight: bold;
  }
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css {
      color: #7f56d9;
      font-weight: bold;
    }
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .content_header {
    padding: 14px 14px;

    width: ${(props) => props.width}px;
    position: fixed;
    background: #ffffff;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 118px;
    padding-bottom: 106px;
  }
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width: ${(props) => props.width}px;
    background-color: #ffffff;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .div_table {
    padding: 15px;
  }
  .resp-table {
    width: 100%;
    display: table;
  }
  .resp-table-body {
    display: table-row-group;
  }
  .resp-table-row {
    display: table-row;
  }
  .table-body-head_cell {
    display: table-cell;
    border: 1px solid #d0d5dd;
    padding: 8px;
    line-height: 1.42857;
    vertical-align: top;
    text-align: center;
    font-weight: 600;
    background-color: #f2f4f7;
    color: #475467;
    font-size: 12px;
  }
  .table-body-cell {
    display: table-cell;
    border: 1px solid #dddddd;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: center;
  }
  .width_time {
    /*width: 30%;*/
    width: 20%;
  }
  .width_activity {
    width: 37%;
  }
  .width_result {
    width: 37%;
  }
  .width_del {
    width: 6%;
    text-align: center;
  }
  .InputTextArea_css {
    width: 100%;
  }
  .dd_css {
    margin: 0px 5px;
  }
  .basic-Information-detail {
    /*padding: 14px;*/
    padding: 0px 14px;
    width: 50%;
    display: flex;
  }
  .css_calendar {
    margin-left: 15px;
  }
  .padding-detail {
    padding: 0px 14px;
  }
  .border_top_left_radius {
    border-top-left-radius: 8px;
  }
  .border_top_right_radius {
    border-top-right-radius: 8px;
  }
  .border_bottom_left_radius {
    border-bottom-left-radius: 8px;
  }
  .border_bottom_right_radius {
    border-bottom-right-radius: 8px;
  }
  .css_time_text {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
  }
  .TextField_css {
    width: 100%;
  }
  .css_cursor_pointer {
    cursor: pointer;
  }

  /* ST CSS POPUP IMG */
  .dialog-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  /* Style for the dialog content */
  .dialog-img-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    position: relative;
    max-width: 1200px;
    padding: 0 15px;
  }
  /* Style for the close icon */
  .dialog-img-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0;
  }

  .dialog-img-popup-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .dialog-img-popup_header {
    text-align: left;
  }
  .dialog-img-popup_detail {
    text-align: left;
    padding: 10px 0px;
  }
  /* ED CSS POPUP IMG */

  /* ST CSS ส่วนของการกรอกข้อมูล */
  .time_sort {
    padding: 10px 14px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #dddddd;

    width: 150px;
    font-size: 14px;
    font-weight: 700;
  }
  .group_main_box {
    padding: 0px 14px;
    padding-top: 14px;
  }
  .main_box {
    display: grid;
    grid-template-columns: repeat(3, 20% [col-start] 65% [col-middle] 15% [col-end]);
    gap: 10px;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .main_box_text_head {
    font-size: 14px;
    font-weight: 600;
    color: var(--Gray-700);
    margin-bottom: 10px;
  }
  .main_box_activity_result {
    display: grid;
    grid-template-columns: repeat(2, 50% [col-start] 50% [col-end]);
    gap: 10px;
  }
  .group_main_box_button {
    margin-left: 10px;
  }
  .main_box_button {
    display: flex;
    gap: 10px;
  }
  .ql-container {
    min-height: 130px;
    font-family: inherit;
  }
  .text-editor {
    min-height: 130px;
  }
  .div_box_time {
    background: var(--Table-Bg-Default);
    /*
    &:hover {
      background: var(--Table-Bg-Hover);
    }
    */
    /*
    &:active {
      background: var(--Table-Bg-Hover);
    }
    */
  }

  /* ED CSS ส่วนของการกรอกข้อมูล */

  @media (max-width: 600px) {
    .css_header_detail {
      flex-direction: column;
      align-items: flex-start;
    }
    header {
      flex-direction: row;
      align-items: flex-start;
    }
    .content_header {
      width: 100%;
      position: relative;
    }
    .dialog-content {
      padding: 0px 14px;
      padding-top: 15px;
      padding-bottom: 106px;
    }
    .dialog-footer {
      width: 100%;
      position: relative;
    }
    .basic-Information-detail {
      flex-direction: row;
      width: 100%;
    }
    .main_box {
      grid-template-columns: 1fr;
    }
    .main_box_activity_result {
      grid-template-columns: 1fr;
    }
    .dialog-img-content {
      width: 90%;
      padding: 0 10px;
    }
    .time_sort {
      width: 46%;
      margin-top: 10px;
    }
    .group_main_box_button,
    .main_box_button {
      width: 100%;
      display: flex;
      justify-content: end;
      position: absolute;
      right: 3%;
    }
    .ql-container {
      min-height: 100px;
    }
  }
`

const NewActionPlan = ({ onClose, onSubmit, ActionPlanId, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const errorMessage = ''
  const editable = true
  const [currentDate, setcurrentDate] = useState(dayjs().format('YYYY-MM-DD'))
  const { user, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const [ServerTime, setServerTime] = useState(dayjs().format('YYYY-MM-DD HH:mm:ss'))

  useEffect(() => {
    dispatch(getServerTime()).then(({ type, data_info }) => {
      if (type.endsWith('_SUCCESS')) {
        if (data_info) {
          setServerTime(dayjs(data_info).format('YYYY-MM-DD HH:mm:ss'))
        } else {
          setTitleFail('Error Connect API')
          setnameItemFail('')
          setDescriptionFail('')
          setFailModal(true)
        }
      } else {
        setTitleFail('Error Connect API')
        setnameItemFail('')
        setDescriptionFail('')
        setFailModal(true)
      }
    })
  }, [dispatch])

  const type_of_activity_option = [
    { text: 'Work', value: 'Work' },
    { text: 'CPD (development)', value: 'CPD (development)' },
    { text: 'AI Improvement', value: 'AI Improvement' },
    { text: 'Research', value: 'Research' },
    { text: 'Networking', value: 'Networking' },
    { text: 'ETC', value: 'ETC' },
  ]

  const [This_ActionPlanId, setThis_ActionPlanId] = useState(ActionPlanId)
  useEffect(() => {
    if (ActionPlanId) {
      dispatch(getActionPlanById(ActionPlanId))
    }
  }, [dispatch, ActionPlanId])

  const { ActionPlanInfo } = useSelector(selectDailySummary)
  const [ActionPlanData, setActionPlanData] = useState({
    display_date: null,
    display_report_by: null,
    data_action_plan_detail: [],
  })

  const [NewActionPlanData, setNewActionPlanData] = useState([])
  const [checkUpdate, checkUpdateModal] = useState(true)
  const [isSetInitValue, setIsSetInitValue] = useState(false)

  useEffect(() => {
    if (ActionPlanInfo.id == This_ActionPlanId) {
      if (ActionPlanInfo.employee?.data?.id) {
        if (ActionPlanInfo.employee?.data?.id != employee_info?.id) {
          checkUpdateModal(false)
        }
      }
      setcurrentDate(dayjs(ActionPlanInfo.action_plan_date_start).format('YYYY-MM-DD'))

      const initialData = ActionPlanInfo?.action_plan_details?.map((v) => ({
        ...v,
        key: uuidv4(),
      }))
      const data_action_plan_detail = initialData.map((v) => ({
        key: v.key,
        time_start: dayjs(v.action_plan_detail_date_start).format('HH.mm'),
        time_start_error: false,
        time_end: dayjs(v.action_plan_detail_date_end).format('HH.mm'),
        time_end_error: false,
        default_activity: v.activity ? v.activity : '<p></p>',
        activity: v.activity ? v.activity : '<p></p>',
        result: v.result ? v.result : '<p></p>',
        default_result: v.result ? v.result : '<p></p>',
        type_of_activity: v.type_of_activity ? v.type_of_activity : '',
        type_of_activity_error: v.type_of_activity ? false : true,
      }))
      setNewActionPlanData(data_action_plan_detail)

      const newMyTaskSelectedList = initialData
        .map((v) => {
          const {
            key,
            project_task_reference,
            task_reference,
            op_sw_reference,
            op_task_reference,
            op_approver_reference,
            op_reviewer_reference,
          } = v

          if (project_task_reference.id) {
            return {
              ...project_task_reference,
              key,
              type: 'project',
              id: project_task_reference.id_ref,
            }
          } else if (task_reference.id) {
            return {
              ...task_reference,
              key,
              type: 'task',
              id: task_reference.id_ref,
            }
          } else if (op_sw_reference.id) {
            return {
              ...op_sw_reference,
              key,
              type: 'op-sw',
              id: op_sw_reference.id_ref,
            }
          } else if (op_task_reference.id) {
            return {
              ...op_task_reference,
              key,
              type: 'op-task',
              id: op_task_reference.id_ref,
            }
          } else if (op_approver_reference.id) {
            return {
              ...op_approver_reference,
              key,
              type: 'op-approval',
              id: op_approver_reference.id_ref,
            }
          } else if (op_reviewer_reference.id) {
            return {
              ...op_reviewer_reference,
              key,
              type: 'op-review',
              id: op_reviewer_reference.id_ref,
            }
          } else {
            return null
          }
        })
        .filter((v) => v !== null)

      setMyTaskSelectedList(newMyTaskSelectedList)

      if (!isSetInitValue) {
        SentToDiscord('ได้เข้าหน้า แก้ไข ActionPlan', data_action_plan_detail)
        setIsSetInitValue(true)
      }
    }
  }, [ActionPlanInfo, This_ActionPlanId])

  const CheckAddTagP = (input_data) => {
    const regex = /(<([^>]+)>)/gi
    if (regex.test(input_data)) {
      return input_data
    } else {
      if (input_data) {
        return '<p>' + input_data + '</p>'
      } else {
        return input_data
      }
    }
  }

  const onChange_time = (index_item, e, type_time) => {
    const newState = NewActionPlanData?.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      //
      if (index === index_item) {
        if (type_time == 'time_start') {
          return {
            ...obj,
            time_start: e.target.value,
            time_start_error: !isValidTimeMinute(e.target.value),
          }
        } else if (type_time == 'time_end') {
          return {
            ...obj,
            time_end: e.target.value,
            time_end_error: !isValidTimeMinute(e.target.value),
          }
        }
      }

      // 👇️ otherwise return the object as is
      return obj
    })
    setNewActionPlanData(newState)
    //setNewActionPlanData(newState)
    /*
    if(isValidTimeMinute(e.target.value)){  
      const this_new_data = newState.sort((a, b) => {
        return parseInt(a.time_start.replace(".", "")) - parseInt(b.time_start.replace(".", "")) // ascending
        //return parseInt(b.time_start.replace(".", "")) - parseInt(a.time_start.replace(".", "")) // ascending
      })
      setNewActionPlanData(this_new_data)
    }else{
      setNewActionPlanData(newState)
    }
    */
  }

  const isValidTimeMinute = (data_inp) => {
    if (parseInt(data_inp.replace('.', '')) == 0) {
      //--- ไม่ให้กรอก 00.00 หรือ 0.00
      return false
    } else {
      var isValid = /^([0-1]?[0-9]|2[0-4]).([0-5][0-9])(.[0-5][0-9])?$/.test(data_inp)

      return isValid
    }
  }

  const onChangeActivity = (key, value) => {
    setNewActionPlanData((currentData) => currentData.map((v) => (v.key === key ? { ...v, activity: value } : v)))
  }

  const onChangeResult = (key, value) => {
    setNewActionPlanData((currentData) => currentData.map((v) => (v.key === key ? { ...v, result: value } : v)))
  }

  const onChange_type_of_activity = (index_item, e) => {
    //alert(e)
    const newState = NewActionPlanData?.map((obj, index) => {
      // 👇️ if id equals 2, update country property
      if (index === index_item) {
        //return { ...obj, result: e.target.value }
        return { ...obj, type_of_activity: e, type_of_activity_error: false }
      }

      // 👇️ otherwise return the object as is
      return obj
    })
    //setUniversitiesList(newState);
    setNewActionPlanData(newState)
  }

  const RemoveNewActionPlanData = (index_item) => {
    SentToDiscord('ได้กดปุ่มลบ Activity ActionPlan', NewActionPlanData)
    const currentListCopy = [...NewActionPlanData]
    currentListCopy.splice(index_item, 1)
    setNewActionPlanData(currentListCopy)
  }

  const DuplicateActionPlanData = (index_item) => {
    SentToDiscord('ได้กดปุ่ม Duplicate ActionPlan', NewActionPlanData)
    let newDataElement = {
      time_start: NewActionPlanData[index_item].time_start,
      time_end: NewActionPlanData[index_item].time_end,
      activity: NewActionPlanData[index_item].activity,
      result: NewActionPlanData[index_item].result,
    }
    const newStateData = [...NewActionPlanData, newDataElement]

    setNewActionPlanData(newStateData)
  }

  function handleAddNewActionPlanData(currentData) {
    SentToDiscord('ได้กดปุ่ม +Activity ActionPlan', NewActionPlanData)
    let newDataElement = {
      key: uuidv4(),
      time_start: '00.00',
      time_end: '00.00',
      activity: '<p></p>',
      result: '<p></p>',
      type_of_activity: '',
      type_of_activity_error: true,
    }

    const newStateData = [...currentData, newDataElement]

    setNewActionPlanData(newStateData)
  }

  const [ModalConfirmDialogLeave, setModalConfirmDialogLeave] = useState(false)
  const ConfirmDialogLeave = () => {
    SentToDiscord('ได้กดปุ่ม ปิด ActionPlan', NewActionPlanData)
    setModalConfirmDialogLeave(true)
  }

  const [ModalConfirmDialog, setModalConfirmDialog] = useState(false)
  const [Description, setDescription] = useState('')
  const [Description2, setDescription2] = useState('')

  const [Titlesuccess, setTitlesuccess] = useState('')
  const [SubTitlesuccess, setSubTitlesuccess] = useState('')
  const [Descriptionsuccess, setDescriptionsuccess] = useState('')
  const [successModal, setsuccessModal] = useState(false)
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const SentToDiscord = async (inp_text, full_data) => {
    //--- ST ส่ง Discord
    try {
      const full_name_employee = user.first_name + ' ' + user.last_name + ' (' + employee_info.employ_id + ')'
      const dis_input_data = JSON.stringify(full_data)
      const max_discord_content = 1800
      let begin = 0
      let last_num = max_discord_content
      let data_to_discord = {}
      for (let i = 0; i <= dis_input_data.length; i = i + max_discord_content) {
        if (i == 0) {
          data_to_discord = {
            content: `\n ---------TimeStam : ${ServerTime} -------------- \n ${full_name_employee} ${inp_text} (Id : ${This_ActionPlanId}) ของวันที่ ${dayjs(
              currentDate
            ).format('D MMM YYYY')}  \n ### input_data ### \n ${dis_input_data.substring(begin, last_num)}`,
          }

          postAPIdiscord({
            url: `https://discord.com/api/webhooks/1192391596952535144/tbMZy1ejPPbjxXAOpDNMfLOnxobZWEMcTid9ZI79uUYso1FuY8Y_VFuZGUzDxpJX7gPV`,
            data: data_to_discord,
            headers: {
              'Content-Type': 'application/json',
            },
          })
        } else {
          data_to_discord = {
            content: `${dis_input_data.substring(begin, last_num)}`,
          }

          postAPIdiscord({
            url: `https://discord.com/api/webhooks/1192391596952535144/tbMZy1ejPPbjxXAOpDNMfLOnxobZWEMcTid9ZI79uUYso1FuY8Y_VFuZGUzDxpJX7gPV`,
            data: data_to_discord,
            headers: {
              'Content-Type': 'application/json',
            },
          })
        }
        begin = begin + max_discord_content
        last_num = last_num + max_discord_content
      }
    } catch (error) {
      console.log(error)
    }

    //--- ED ส่ง Discord
  }

  const ConfirmDialog = async () => {
    //--- ST ส่ง Discord
    try {
      const full_name_employee = user.first_name + ' ' + user.last_name + ' (' + employee_info.employ_id + ')'
      const dis_input_data = JSON.stringify(NewActionPlanData)
      const max_discord_content = 1800
      let begin = 0
      let last_num = max_discord_content
      let data_to_discord = {}
      for (let i = 0; i <= dis_input_data.length; i = i + max_discord_content) {
        if (i == 0) {
          data_to_discord = {
            content: `\n ---------TimeStam : ${ServerTime} -------------- \n ${full_name_employee} ได้แก้ไข ActionPlan (Id : ${This_ActionPlanId}) ของวันที่ ${dayjs(
              currentDate
            ).format('D MMM YYYY')}  \n ### input_data ### \n ${dis_input_data.substring(begin, last_num)}`,
          }

          postAPIdiscord({
            url: `https://discord.com/api/webhooks/1192391596952535144/tbMZy1ejPPbjxXAOpDNMfLOnxobZWEMcTid9ZI79uUYso1FuY8Y_VFuZGUzDxpJX7gPV`,
            data: data_to_discord,
            headers: {
              'Content-Type': 'application/json',
            },
          })
        } else {
          data_to_discord = {
            content: `${dis_input_data.substring(begin, last_num)}`,
          }

          postAPIdiscord({
            url: `https://discord.com/api/webhooks/1192391596952535144/tbMZy1ejPPbjxXAOpDNMfLOnxobZWEMcTid9ZI79uUYso1FuY8Y_VFuZGUzDxpJX7gPV`,
            data: data_to_discord,
            headers: {
              'Content-Type': 'application/json',
            },
          })
        }
        begin = begin + max_discord_content
        last_num = last_num + max_discord_content
      }
    } catch (error) {
      console.log(error)
    }

    //--- ED ส่ง Discord
    const ObjTimeError = NewActionPlanData.filter((v) => v.time_start_error == true || v.time_end_error == true)
    const ObjTimeEror0000 = NewActionPlanData.filter((v) => v.time_start == '00.00' && v.time_end == '00.00')
    if (ObjTimeError.length > 0 || ObjTimeEror0000.length > 0) {
      setTitleFail('กรุณาระบุเวลาให้ถูกต้อง')
      setnameItemFail('')
      setDescriptionFail('')
      setFailModal(true)
    } else {
      const Objtype_of_activityError = NewActionPlanData.filter((v) => v.type_of_activity_error == true)
      if (Objtype_of_activityError.length > 0) {
        setTitleFail(t('please_fill_type_of_activity'))
        setnameItemFail('')
        setDescriptionFail('')
        setFailModal(true)
      } else {
        if (checkUpdate) {
          if (dayjs(currentDate).format('YYYY-MM-DD') < dayjs(ServerTime).format('YYYY-MM-DD')) {
            setTitleFail('Action Plan ไม่สามารถแก้ย้อนหลังได้')
            setnameItemFail('')
            setDescriptionFail('')
            setFailModal(true)
          } else {
            setDescription(user.first_name + ' ' + user.last_name + ' (' + employee_info.employ_id + ')')
            setDescription2(dayjs(currentDate).format('D MMM YYYY'))
            setModalConfirmDialog(true)
          }
        } else {
          setTitleFail('คุณไม่มีสิทธิ์แก้ไขรายการนี้')
          setnameItemFail('')
          setDescriptionFail('')
          setFailModal(true)
        }
      }
    }
  }

  const SaveEditActionPlan = () => {
    const data_action_plan_detail = NewActionPlanData?.map((v) => {
      const matchedAddedTask = myTaskSelectedList.find((it) => it.key === v.key)

      if (matchedAddedTask) {
        const { type } = matchedAddedTask
        const result = {
          key: v.key,
          activity: v.activity,
          result: v.result,
          action_plan_detail_date_start: dayjs(currentDate + ' ' + v.time_start.replace('.', ':') + ':00').format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          action_plan_detail_date_end: dayjs(currentDate + ' ' + v.time_end.replace('.', ':') + ':00').format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          type_of_activity: v.type_of_activity,
        }
        if (type === 'project') result['project_task_reference'] = matchedAddedTask.id
        if (type === 'task') result['task_reference'] = matchedAddedTask.task_id
        if (type === 'op-sw') result['op_sw_reference'] = matchedAddedTask.id
        if (type === 'op-task') result['op_task_reference'] = matchedAddedTask.id
        if (type === 'op-review') result['op_reviewer_reference'] = matchedAddedTask.id
        if (type === 'op-approval') result['op_approver_reference'] = matchedAddedTask.id

        return result
      } else {
        return {
          key: v.key,
          activity: v.activity,
          result: v.result,
          action_plan_detail_date_start: dayjs(currentDate + ' ' + v.time_start.replace('.', ':') + ':00').format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          action_plan_detail_date_end: dayjs(currentDate + ' ' + v.time_end.replace('.', ':') + ':00').format(
            'YYYY-MM-DD HH:mm:ss'
          ),
          type_of_activity: v.type_of_activity,
        }
      }
    })
    const minDate = data_action_plan_detail[0].action_plan_detail_date_start
    const maxDate = data_action_plan_detail[data_action_plan_detail.length - 1].action_plan_detail_date_end
    const sent_data_api = {
      data: {
        action_plan_date: currentDate,
        action_plan_date_start: minDate,
        action_plan_date_end: maxDate,
        action_plan_detail: data_action_plan_detail,
      },
    }

    dispatch(updateActionPlan(This_ActionPlanId, sent_data_api)).then(({ type }) => {
      if (type.endsWith('_SUCCESS')) {
        setTitlesuccess(t('success_update_action_plan_message'))
        setSubTitlesuccess('')
        setDescriptionsuccess('')
        setsuccessModal(true)
      } else {
        setTitleFail(t('error_create'))
        setnameItemFail('')
        setDescriptionFail('')
        setFailModal(true)
      }
    })

    setModalConfirmDialog(false)
  }

  const [EmployeeIdCalendar, setEmployeeIdCalendar] = useState(employee_info?.id ? employee_info?.id : -99)
  const onChangecurrentDate = (value) => {
    SentToDiscord('ได้กดปุ่มเปลี่ยนวันที่ ActionPlan', NewActionPlanData)
    setcurrentDate(value)

    const filter = {
      skip: 1,
      limit: 10000,
      active: 1,
      EmployeeId: EmployeeIdCalendar,
      filter_date: value,
    }
    dispatch(getActionPlanReturnData(filter)).then(({ type, data_info }) => {
      //---- ถ้าเช็คแล้วไม่มีข้อมูลของวันที่เลือก ก็จะถือว่าเป็นเคสที่ต้องการเปลี่ยนวันที่ เช่น ตอนสร้างระบบ 01/01/2000 แต่อยากเปลี่ยนเป็น 02/01/2000
      if (type.endsWith('_SUCCESS')) {
        if (data_info.length > 0) {
          const this_id = data_info[0].id
          setThis_ActionPlanId(this_id)
          dispatch(getActionPlanById(this_id))
        }
      }
    })
    /*
    dispatch(getActionPlanReturnData(filter)).then(({ type,data_info }) => {
        if(type.endsWith('_SUCCESS')){
          if(data_info.length > 0){
            const this_id = data_info[0].id;
            setThis_ActionPlanId(this_id)
            dispatch(getActionPlanById(this_id))
          }else{
            setTitleFail(t('no_information_found'));
            setnameItemFail('');
            setDescriptionFail(''); 
            setFailModal(true)
          }
          
        }else{
          setTitleFail(t('no_information_found'));
          setnameItemFail('');
          setDescriptionFail(''); 
          setFailModal(true)
        }  
    })
    */
  }

  const [isSortedAsc, setisSortedAsc] = useState(true)
  const SortData = (inp_isSortedAsc) => {
    SentToDiscord('ได้กดปุ่มReorder ActionPlan', NewActionPlanData)
    const this_isSortedAsc = !inp_isSortedAsc // true -> false , false -> true
    setisSortedAsc(this_isSortedAsc)

    const this_new_data = NewActionPlanData.sort((a, b) => {
      if (this_isSortedAsc) {
        return parseInt(a.time_start.replace('.', '')) - parseInt(b.time_start.replace('.', '')) // ascending
        //return parseInt(dayjs(a.attributes.action_plan_detail_date_start).format('HH.mm').replace(".", "")) - parseInt(dayjs(b.attributes.action_plan_detail_date_start).format('HH.mm').replace(".", "")) // ASC
      } else {
        return parseInt(b.time_start.replace('.', '')) - parseInt(a.time_start.replace('.', '')) // DESC
        //return parseInt(dayjs(b.attributes.action_plan_detail_date_start).format('HH.mm').replace(".", "")) - parseInt(dayjs(a.attributes.action_plan_detail_date_start).format('HH.mm').replace(".", "")) // DESC
      }
    })

    setNewActionPlanData(this_new_data)
    //setNewActionPlanData({ ...NewActionPlanData, data_action_plan_detail: this_new_data })
  }

  const [dialog, setDialog] = useState(false)
  const toggleDialog = () => {
    setDialog(true)
  }

  const ClosetoggleDialog = () => {
    setDialog(false)
  }

  //---- Function Move
  const handleOnDragEnd = (result) => {
    if (!result.destination) return
    let _NewActionPlanData = [...NewActionPlanData]
    const [reorderedItem] = _NewActionPlanData.splice(result.source.index, 1)
    _NewActionPlanData.splice(result.destination.index, 0, reorderedItem)
    setNewActionPlanData(_NewActionPlanData)
  }

  // isOpenMyTaskModal
  const [isOpenMyTaskModal, setIsOpenMyTaskModal] = useState(false)
  const [keyActionPlan, setKeyActionPlan] = useState('')

  const openMytaskModal = (key) => {
    SentToDiscord('กดปุ่ม Add from my task เพื่อเปิด modal my task', NewActionPlanData)
    setKeyActionPlan(key)
    setIsOpenMyTaskModal(true)
  }
  const closeMyTaskModal = () => {
    SentToDiscord('กดปิด modal my task', NewActionPlanData)
    setIsOpenMyTaskModal(false)
  }

  // current window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [widthModal, setWidthModal] = useState('')

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setWidthModal((windowWidth * 95) / 100)
  }, [windowWidth])

  // my task selected list
  const [myTaskSelectedList, setMyTaskSelectedList] = useState([])

  const addToAction = (key, type, value) => {
    SentToDiscord('ได้กดเพิ่ม task จาก modal my task', NewActionPlanData)
    const isExisted = myTaskSelectedList.some((v) => v.key === key)

    if (isExisted) {
      setMyTaskSelectedList((prev) =>
        prev.map((v) => {
          if (v.key == key)
            return {
              key: key,
              type,
              ...value,
            }
          else return v
        })
      )
    } else {
      setMyTaskSelectedList((prev) => [
        ...prev,
        {
          key,
          type,
          ...value,
        },
      ])
    }
  }
  const deleteTaskActionPlan = (key) => {
    SentToDiscord('ได้กดลบ task ที่เลือกมาจาก Add from my task', NewActionPlanData)
    setMyTaskSelectedList((prev) => prev.filter((v) => v.key !== key))
  }

  const [sidePanelData, setSidePanelData] = useState()

  const displayAddedTask = (key) => {
    const taskData = myTaskSelectedList.find((v) => v.key === key)

    return taskData ? (
      <Stack direction="row" alignItems="center" spacing={2}>
        {taskData.type.startsWith('op') ? (
          <>
            <Box className="text-highlight" onClick={() => openTaskSidePanel(taskData)}>
              <Box>SOP: {taskData.sop ?? '-'}</Box>
              <Box>SOW: {taskData.sow ?? '-'}</Box>
              <Box>Task: {taskData.task ?? '-'}</Box>
            </Box>
            <LiaTimesSolid size={18} className="icon" onClick={() => deleteTaskActionPlan(taskData.key)} />
          </>
        ) : (
          <>
            <Box className="text-highlight" onClick={() => openTaskSidePanel(taskData)}>
              <Box>Project: {taskData.project ?? '-'}</Box>
              <Box>Task Name: {taskData.task_name ?? '-'}</Box>
            </Box>
            <LiaTimesSolid size={18} className="icon" onClick={() => deleteTaskActionPlan(taskData.key)} />
          </>
        )}
      </Stack>
    ) : (
      false
    )
  }

  // op sow side panel
  const [isOpenDetailOPSoWSidePanel, setIsOpenDetailOPSoWSidePanel] = useState(false)

  const openDetailOPSoWSidePanel = () => {
    setIsOpenDetailOPSoWSidePanel(true)
  }
  const closeDetailOPSoWSidePanel = () => {
    setIsOpenDetailOPSoWSidePanel(false)
  }

  // op task side panel
  const [isOpenDetailOPTaskSidePanel, setIsOpenDetailOPTaskSidePanel] = useState(false)

  const openDetailOPTaskSidePanel = () => {
    setIsOpenDetailOPTaskSidePanel(true)
  }
  const closeDetailOPTaskSidePanel = () => {
    setIsOpenDetailOPTaskSidePanel(false)
  }

  // op review side panel
  const [isOpenDetailOPReviewSidePanel, setIsOpenDetailOPReviewSidePanel] = useState(false)

  const openDetailOPReviewSidePanel = () => {
    setIsOpenDetailOPReviewSidePanel(true)
  }
  const closeDetailOPReviewSidePanel = () => {
    setIsOpenDetailOPReviewSidePanel(false)
  }

  // op approval side panel
  const [isOpenDetailOPApprovalSidePanel, setIsOpenDetailOPApprovalSidePanel] = useState(false)

  const openDetailOPApprovalSidePanel = () => {
    setIsOpenDetailOPApprovalSidePanel(true)
  }
  const closeDetailOPApprovalSidePanel = () => {
    setIsOpenDetailOPApprovalSidePanel(false)
  }

  // dialog task preview side panel
  const [isShowDialogTaskPreview, setIsShowDialogTaskPreview] = useState(false)

  const openShowDialogTaskPreview = () => {
    setIsShowDialogTaskPreview(true)
  }
  const closeShowDialogTaskPreview = () => {
    setIsShowDialogTaskPreview(false)
  }

  const openTaskSidePanel = (data) => {
    SentToDiscord('ได้กดเปิด task side panel ที่ได้จากการเพิ่ม Add from my task', NewActionPlanData)
    if (data.type === 'task' || data.type === 'project') {
      setSidePanelData({
        ...data,
        type: data.type === 'task' ? 'task_routine' : 'task_milestone',
      })
    } else {
      setSidePanelData(data)
    }
    if (data.type === 'op-sw') {
      openDetailOPSoWSidePanel()
    } else if (data.type === 'op-task') {
      openDetailOPTaskSidePanel()
    } else if (data.type === 'op-review') {
      openDetailOPReviewSidePanel()
    } else if (data.type === 'op-approval') {
      openDetailOPApprovalSidePanel()
    } else {
      openShowDialogTaskPreview()
    }
  }

  return (
    <Div {...props}>
      <Box sx={{ mb: 2 }}>
        <header>
          <div className="content-title">{t('edit_action_plan')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={ConfirmDialogLeave} className="icon" />
          </div>
        </header>
        <div className="css_header_detail">{t('create_action_plan_sub_header')}</div>
        <div className="css_header_detail">{ActionPlanData.display_report_by}</div>
        <Box sx={{ mt: '12px' }}>
          <Divider />
        </Box>
      </Box>

      <Box sx={{ width: 200, mb: 2 }}>
        <Date className={'input-calendar'} value={currentDate} onChange={onChangecurrentDate} />
      </Box>

      <Box sx={{ mb: 2 }}>
        <div className="css_header_detail_how_to link" onClick={() => toggleDialog()}>
          {t('Read_How_to_fill_in_Action_Plan')}
        </div>
        <div className="css_header_detail">{t('create_action_plan_info')}</div>
      </Box>

      <Box>
        <div className="time_sort" onClick={() => SortData(isSortedAsc)}>
          Time Reorder
          <span>{isSortedAsc ? ' ↓' : ' ↑'}</span>
        </div>
      </Box>

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div className="group_main_box" {...provided.droppableProps} ref={provided.innerRef}>
              {NewActionPlanData?.map((item, index) => (
                <Draggable key={`item-${index}`} draggableId={`item-${index}`} index={index}>
                  {(provided) => (
                    <>
                      <div className="div_box_time" {...provided.draggableProps} ref={provided.innerRef}>
                        <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="center">
                          <Stack direction="row" spacing={2}>
                            <Box>
                              <div className="main_box_text_head">
                                {t('no')}. {index + 1}
                              </div>
                            </Box>
                            <Box>
                              <div className="main_box_text_head">{t('time_start')}</div>
                              <TextFieldTime
                                name={`time_start_hh_` + index}
                                className="TextField_css"
                                value={item.time_start}
                                onChange={(v) => onChange_time(index, v, 'time_start')}
                                error={Boolean(item.time_start_error)}
                                disabled={!editable}
                              />
                            </Box>
                            <Box>
                              <div className="main_box_text_head">{t('time_end')}</div>
                              <TextFieldTime
                                name={`time_start_mm_` + index}
                                className="TextField_css"
                                value={item.time_end}
                                onChange={(v) => onChange_time(index, v, 'time_end')}
                                error={Boolean(item.time_end_error)}
                                disabled={!editable}
                              />
                            </Box>
                            <Box>
                              <div className="main_box_text_head">{t('type_of_activity')}</div>
                              <div className="box_type_of_activity">
                                <Dropdown
                                  id={`type_of_activity_` + index}
                                  className="dd_type_of_activity"
                                  value={item.type_of_activity}
                                  optionList={type_of_activity_option}
                                  onChange={(v) => onChange_type_of_activity(index, v)}
                                  error={Boolean(item.type_of_activity_error)}
                                  placeHolder={t('please_select')}
                                />
                              </div>
                            </Box>
                            <Stack justifyContent="flex-end">
                              {/* <ButtonOutline
                                className="add-from-my-task-btn"
                                append={<FiPlusCircle size={20} />}
                                onClick={ }
                              >
                                Add from My Task
                              </ButtonOutline> */}
                              {displayAddedTask(item.key) ? (
                                displayAddedTask(item.key)
                              ) : (
                                <ButtonOutline
                                  className="add-from-my-task-btn"
                                  append={<FiPlusCircle size={20} />}
                                  onClick={() => openMytaskModal(item.key)}
                                >
                                  Add from My Task
                                </ButtonOutline>
                              )}
                            </Stack>
                          </Stack>

                          <Stack direction="row" alignItems="center" spacing={1}>
                            <div {...provided.dragHandleProps}>
                              <img src={iconDragDrop} className="css_cursor_pointer" title="Drag" />
                            </div>
                            <div>
                              <img
                                src={iconDuplicate}
                                className="css_cursor_pointer"
                                title="Duplicate"
                                onClick={() => DuplicateActionPlanData(index)}
                              />
                            </div>
                            <div>
                              <img
                                src={iconDelete}
                                className="css_cursor_pointer"
                                title="Delete"
                                onClick={() => RemoveNewActionPlanData(index)}
                              />
                            </div>
                          </Stack>
                        </Stack>{' '}
                        <Stack direction="row" spacing={2}>
                          <Box sx={{ width: '50%' }}>
                            <div className="main_box_text_head">{t('create_action_plan_time_table_activity')}</div>
                            {/* <TextEditorV2
                              className="text-editor"
                              placeHolder="Enter Activity"
                              initValue={item.default_activity}
                              // onBlur={(e) => onChange_activity(index, e)}
                              onChange={(value) => onChangeActivity(item.key, value)}
                            /> */}

                            <ReactQuill
                              key={item.key}
                              theme="snow"
                              modules={modules}
                              value={item.activity}
                              onChange={(content) => onChangeActivity(item.key, content)}
                            />
                          </Box>
                          <Box sx={{ width: '50%' }}>
                            <div className="main_box_text_head">{t('create_action_plan_time_table_result')}</div>
                            {/* <TextEditorV2
                              className="text-editor"
                              placeHolder="Enter Result"
                              initValue={item.default_result}
                              // onBlur={(e) => onChange_result(index, e)}
                              onChange={(value) => onChangeResult(item.key, value)}
                            /> */}

                            <ReactQuill
                              key={item.key}
                              theme="snow"
                              modules={modules}
                              value={item.result}
                              onChange={(content) => onChangeResult(item.key, content)}
                            />
                          </Box>
                        </Stack>
                        <Box sx={{ mb: 2, mt: 3 }}>
                          <Divider />
                        </Box>
                      </div>

                      {/* <div className="div_box_time" {...provided.draggableProps} ref={provided.innerRef}>
                        <div className="main_box">
                          <div>
                            <div className="main_box_text_head">
                              {index + 1}. {t('time')}
                            </div>

                            <div className="css_time_text">
                              <TextFieldTime
                                name={`time_start_hh_` + index}
                                className="TextField_css"
                                value={item.time_start}
                                onChange={(v) => onChange_time(index, v, 'time_start')}
                                error={Boolean(item.time_start_error)}
                                disabled={!editable}
                              />
                              -
                              <TextFieldTime
                                name={`time_start_mm_` + index}
                                className="TextField_css"
                                value={item.time_end}
                                onChange={(v) => onChange_time(index, v, 'time_end')}
                                error={Boolean(item.time_end_error)}
                                disabled={!editable}
                              />
                            </div>

                            <div className="main_box_text_head box_type_of_activity">{t('type_of_activity')}</div>
                            <div className="box_type_of_activity">
                              <Dropdown
                                id={`type_of_activity_` + index}
                                className="dd_type_of_activity"
                                value={item.type_of_activity}
                                optionList={type_of_activity_option}
                                onChange={(v) => onChange_type_of_activity(index, v)}
                                error={Boolean(item.type_of_activity_error)}
                                placeHolder={t('please_select')}
                              />
                            </div>
                          </div>
                          <div className="main_box_activity_result">
                            <div>
                              <div className="main_box_text_head">{t('create_action_plan_time_table_activity')}</div>
                              <div>
                                <TextEditorV2
                                  className="text-editor"
                                  placeHolder="Enter Activity"
                                  initValue={item.activity}
                                  onBlur={(e) => onChange_activity(index, e)}
                                />
                              </div>
                            </div>
                            <div>
                              <div className="main_box_text_head">{t('create_action_plan_time_table_result')}</div>
                              <div>
                                <TextEditorV2
                                  className="text-editor"
                                  placeHolder="Enter Result"
                                  initValue={item.result}
                                  onBlur={(e) => onChange_result(index, e)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="group_main_box_button">
                            <div className="main_box_text_head">&nbsp;</div>
                            <div className="main_box_button">
                              <div {...provided.dragHandleProps}>
                                <img src={iconDragDrop} className="css_cursor_pointer" title="Drag" />
                              </div>
                              <div>
                                <img
                                  src={iconDuplicate}
                                  className="css_cursor_pointer"
                                  title="Duplicate"
                                  onClick={() => DuplicateActionPlanData(index)}
                                />
                              </div>
                              <div>
                                <img
                                  src={iconDelete}
                                  className="css_cursor_pointer"
                                  title="Delete"
                                  onClick={() => RemoveNewActionPlanData(index)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="border_header"></div>
                      </div> */}
                    </>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button append={<img src={iconPlus} />} onClick={() => handleAddNewActionPlanData(NewActionPlanData)}>
        {t('create_action_plan_time_table_activity')}
      </Button>

      <Box sx={{ mt: 2, pb: 2, position: 'sticky', bottom: 0, backgroundColor: 'var(--Base-White)' }}>
        <Box sx={{ mb: 2 }}>
          <Divider />
        </Box>

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <ButtonOutline className="button_margin" onClick={ConfirmDialogLeave}>
            {t('cancel')}
          </ButtonOutline>
          <Button className="button_margin" onClick={ConfirmDialog}>
            {t('update')}
          </Button>
        </Stack>
      </Box>

      {/* Modal */}
      <Modal open={isOpenMyTaskModal} onClose={closeMyTaskModal} disableBackdropClick width={`${widthModal}px`}>
        <MyTaskModalContent keyActionPlan={keyActionPlan} addToAction={addToAction} onClose={closeMyTaskModal} />
      </Modal>

      <DialogConfirm
        open={Boolean(ModalConfirmDialog)}
        onClose={() => setModalConfirmDialog(false)}
        onSubmit={SaveEditActionPlan}
        icon={question}
        title={t('confirm_update_action_plan_header')}
        description={Description}
        description2={Description2}
        textYes={t('confirm')}
        textNo={t('back')}
      />

      <DialogConfirm
        open={Boolean(ModalConfirmDialogLeave)}
        onClose={() => setModalConfirmDialogLeave(false)}
        onSubmit={onClose}
        icon={question}
        title={t('leave_update_action_plan_header')}
        textYes={t('leave')}
        textNo={t('cancel')}
      />

      <DialogSuccess
        open={Boolean(successModal)}
        onClose={() => setsuccessModal(false)}
        onSubmit={() => onSubmit()}
        icon={successIcon}
        title={Titlesuccess}
        subtitle={SubTitlesuccess}
        description={Descriptionsuccess}
        textYes={t('done')}
      />

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />

      {dialog && (
        <div className="dialog-img">
          <div className="dialog-img-content">
            <h3 className="dialog-img-popup_header">{t('How_to_fill_in_Action_Plan')}</h3>
            <button className="dialog-img-close-icon" onClick={ClosetoggleDialog}>
              &#10005;
            </button>
            <div className="dialog-img-popup_detail">
              <div>{t('How_to_fill_in_Action_Plan_1')}</div>
              <div>{t('How_to_fill_in_Action_Plan_2')}</div>
              <div>{t('How_to_fill_in_Action_Plan_3')}</div>
              <div>{t('How_to_fill_in_Action_Plan_4')}</div>
              <div>{t('How_to_fill_in_Action_Plan_5')}</div>
              <div>{t('How_to_fill_in_Action_Plan_6')}</div>
            </div>
          </div>
        </div>
      )}

      {/* side panel */}
      <SidePanelV2
        titleText={sidePanelData?.sow}
        supportingText={`SOW Code: ${sidePanelData?.code}`}
        open={isOpenDetailOPSoWSidePanel}
        onClose={(event) => {
          closeDetailOPSoWSidePanel()
          // dispatch(clearSW())
          // handleToggleDrawer('view-sw', false)(event)
        }}
      >
        <DrawerDetails.SoW id={sidePanelData?.id} />
      </SidePanelV2>
      <SidePanelV2
        titleText={sidePanelData?.sow}
        supportingText={`SOW Code: ${sidePanelData?.code}`}
        open={isOpenDetailOPTaskSidePanel}
        onClose={(event) => {
          closeDetailOPTaskSidePanel()
        }}
      >
        <DrawerDetails.Task taskId={sidePanelData?.id} />
      </SidePanelV2>
      <SidePanelV2
        titleText={sidePanelData?.sow}
        supportingText={`SOW Code: ${sidePanelData?.code}`}
        open={isOpenDetailOPReviewSidePanel}
        onClose={(event) => {
          closeDetailOPReviewSidePanel()
        }}
      >
        <DrawerDetails.Reviewer reviewerId={sidePanelData?.id} />
      </SidePanelV2>
      <SidePanelV2
        titleText={sidePanelData?.sow}
        supportingText={`SOW Code: ${sidePanelData?.code}`}
        open={isOpenDetailOPApprovalSidePanel}
        onClose={(event) => {
          closeDetailOPApprovalSidePanel()
        }}
      >
        <DrawerDetails.Approval approverId={sidePanelData?.id} />
      </SidePanelV2>

      {isShowDialogTaskPreview && (
        <DialogTaskPreviewer
          taskId={sidePanelData?.id}
          open={isShowDialogTaskPreview}
          onClose={closeShowDialogTaskPreview}
          previewType={sidePanelData?.type}
        />
      )}
    </Div>
  )
}

export default NewActionPlan
