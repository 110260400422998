import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import CreateChequePaymentJournal from '../purchases/CreateChequePaymentJournal'
import CreateComparisonSchedule from '../purchases/CreateComparisonSchedule'
import CreatePaymentJournal from '../purchases/CreatePaymentJournal'
import CreatePettyCashVoucher from '../purchases/CreatePettyCashVoucher'
import CreatePurchaseOrder from '../purchases/CreatePurchaseOrder'
import CreatePurchaseRequest from '../purchases/CreatePurchaseRequest'
import CreateDebitNote from '../sales/CreateDebitNote'
import CreatePledgeProductCertificationRequest from '../sales/CreatePledgeProductCertificationRequest'
import CreateSaleDocument from '../sales/CreateSaleDocument'
// business-doc
import CreditAdjustmentDocument from './doc-view/CreditAdjustmentDocument'
import DeliveryAppointmentNote from './doc-view/DeliveryAppointmentNote'
import GoodsReceivedNote from './doc-view/GoodsReceivedNote'
import PaymentVoucher from './doc-view/PaymentVoucher'
import TransferRequest from './doc-view/TransferRequest'
import ReceiptVoucher from './doc-view/ReceiptVoucher'
import InternalMemo from './doc-view/InternalMemo'
import TravelExpenseClaim from './doc-view/TravelExpenseClaim'

export default function RootCreateDocument({ onCallback }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const viewType = searchParams.get('type')

  return (
    <Styled>
      {/* ------------ BusinessDoc ------------- */}
      {viewType == 'goods_received_note' && <GoodsReceivedNote />}
      {viewType == 'payment_voucher' && <PaymentVoucher />}
      {viewType == 'transfer_request' && <TransferRequest onCallback={onCallback} />}
      {viewType == 'delivery_appointment_note' && <DeliveryAppointmentNote onCallback={onCallback} />}
      {viewType == 'credit_adjustment_document' && <CreditAdjustmentDocument />}
      {viewType == 'credit_note_goods_receipt' && <CreditNoteGoodsReceipt />}
      {viewType == 'internal_memo' && <InternalMemo onCallback={onCallback} />}
      {viewType == 'travel_expense_claim' && <TravelExpenseClaim onCallback={onCallback} />}


      {/* ------------ PURCHASE ------------- */}
      {viewType == 'purchase_order' && <CreatePurchaseOrder onCallback={onCallback} />}
      {viewType == 'cheque_payment_journal' && <CreateChequePaymentJournal onCallback={onCallback} />}
      {viewType == 'payment_journal' && <CreatePaymentJournal onCallback={onCallback} />}
      {viewType == 'petty_cash_voucher' && <CreatePettyCashVoucher onCallback={onCallback} />}
      {viewType == 'purchase_request' && <CreatePurchaseRequest onCallback={onCallback} />}
      {viewType == 'comparison_schedule' && <CreateComparisonSchedule onCallback={onCallback} />}

      {/* ------------ SALES ---------------- */}
      {(viewType == 'quotation' || viewType == 'billing' || viewType == 'invoice' || viewType == 'receipt') && (
        <CreateSaleDocument onCallback={onCallback} />
      )}
      {viewType == 'debit_note' && <CreateDebitNote onCallback={onCallback} />}
      {viewType == 'pledge_product_certification_request' && <CreatePledgeProductCertificationRequest />}
    </Styled>
  )
}
const Styled = styled.div`
  /* height: 100%; */
`
