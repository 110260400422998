import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// get api
import { selectNotificatons } from '../../redux/selectors'
import { updateNotificationsList, getNotificationsReade } from '../../redux/actions/notifications'
import InfiniteScroll from 'react-infinite-scroll-component'

import { DateDisplay, TimeDisplayHHmm, DateTimeHHmmDisplay, DateTimeHHmmssDisplay } from '../../utils/common'

import styled from 'styled-components'
const Div = styled.div`
  display: flex;
  min-height: 100%;

  p {
    text-align: center;
  }
  .NoData {
    display: flex;
    justify-content: center;
  }
  .color-active-Rejected {
    color: #b54708;
    background: #fffaeb;
    border: 1px solid #fedf89;
  }
  .color-active-Approved {
    color: #067647;
    background: #ecfdf3;
    border: 1px solid #abefc6;
  }
  .color-active-Overdue {
    color: #b42318;
    background: #fef3f2;
    border: 1px solid #fecdca;
  }
  .stripe {
    border: 10px solid #949494;
    height: 20%;
    background-color: #babbbc;
    border-radius: 7px;
  }

  .small-stripe {
    width: 20%;
  }
  .small-stripe-sm {
    display: flex;
    width: 20%;
  }
  .medium-stripe {
    display: flex;
    margin-top: 12px;
    width: 80%;
  }

  .stripe {
    animation: hintloading 2s ease-in-out 0s infinite reverse;
    -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
  }
  @keyframes hintloading {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }

  @-webkit-keyframes hintloading {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  .badge-loading {
    padding: 10px 30px;
    align-items: center;
    background: rgb(186, 187, 188);
    border: 1px solid rgb(186, 187, 188);
    border-radius: 16px;
    margin-left: 60px;
  }
  .circle-loading {
    content: '';
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgb(186, 187, 188);
    border: 1px solid rgb(186, 187, 188);
    margin-left: 50px;
    vertical-align: middle;
  }
  .load-more-btn {
    margin-top: 70px;
    padding: 10px 30px;
    align-items: center;
    color: #6941c6;
    border: 1px solid #e9d7fe;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background: #f9f5ff;
    width: 128px;
    height: 36px;
    cursor: pointer;
  }
  .badge {
    padding: 2px 8px;
    align-items: center;
    border-radius: 16px;
    margin-left: 60px;
  }
  .icon {
    cursor: pointer;
  }

  .side-panel-container {
    display: block;
    padding: 2rem;
    min-width: 100%;
  }
  .d-flex {
    display: flex;
  }
  .justify-content-center {
    justify-content: center;
  }
  .justify-content-flex-end {
    justify-content: flex-end;
  }
  .justify-content-between {
    justify-content: space-between;
  }

  .create-access-role-header {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .icons {
    height: 100%;
    width: 70%;
    cursor: pointer;
  }

  .mb-1-rem {
    margin-bottom: 1rem;
  }

  > .side-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    border-right: 1px solid var(--Gray-200);

    .top-wrapper {
      flex: 1;
      display: flex;

      .main-menu {
        padding: 24px 16px;
        background: var(--Primary-600);

        .logo {
          width: 100%;
          justify-content: center;
          margin-bottom: 16px;
        }

        .menu {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 16px;
          width: 48px;
          height: 48px;
          border-radius: 6px;

          .fi-settings {
            color: #ffffff;
          }

          &.active {
            background: var(--Base-White);

            path {
              stroke: var(--Primary-700);
            }

            .fi-settings {
              background: var(--Base-White);
              color: var(--Primary-700);
            }
          }
        }
        .menu-notifications {
          margin-top: 320px;
          .notification-badge {
            position: absolute;
            margin-left: 40px;
            margin-bottom: 20px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            background-color: red;
            border-radius: 50%;
            font-size: 10px;
            font-weight: bold;
            color: #fff;
          }
        }
      }

      .sub-menu {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        padding: 24px;
        background: var(--Primary-800);
        width: 340px;

        .header-menu {
          display: flex;
          align-items: center;
          gap: 12px;
          color: var(--Base-White);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;

          .icon-header {
            font-size: 24px;
          }
        }

        .menu {
          cursor: pointer;
          display: block;
          margin-top: 4px;
          padding: 8px 12px;
          border-radius: 6px;
          color: #ffffff;
          font-size: 16;
          font-weight: 600;
          text-decoration: none !important;

          &.active {
            color: #ffffff;
            background: var(--Primary-600);
          }
        }

        .current-user {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: var(--Base-White);
          bottom: 0;

          .img-profile {
            width: 48px;
            border-radius: 50%;
          }

          .detail {
            flex: 1;
            font-size: 14px;

            .email {
              color: var(--Primary-200);
              font-weight: 400;
            }
          }

          .logout {
            color: var(--Primary-300);
          }
        }
      }
    }
  }

  > .main-content {
    flex: 1;
    padding: 24px;
    height: 100vh;
    overflow: auto;
  }
  .mt-5 {
    margin-top: 5px;
  }
  .mb-2-rem {
    margin-bottom: 2rem;
  }
  .tab-container {
    display: flex;
    border-bottom: 2px solid rgb(234, 236, 240);
    width: 35%;
    .tab-item {
      cursor: pointer;
      margin-right: 2rem;
      padding-bottom: 1rem;
      padding: 10px;
      &.active {
        color: #7f56d9;
        font-weight: 600;
        border-bottom: 2px solid #7f56d9;
        background-color: rgb(127 86 217 / 13%);
        padding: 10px;
      }
    }
  }

  .notifications {
    border: 1px solid rgb(245 245 245);
    margin: 0px -30px;
    margin-right: 0px;
  }

  .list-group {
    list-style: none;
    padding: 0;
    margin-left: 25px;
  }

  .list-group-item {
    border: none;
    background-color: rgb(255 255 255);
    padding: 10px;
    transition: background-color 0.3s;
    cursor: pointer;
  }
  .list-group-item:hover {
    background-color: #f3f3f3;
  }

  .notification-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .notification-sender {
    font-weight: bold;
    margin-right: 10px;
  }

  .notification-action {
    font-size: 14px;
    margin-right: 10px;
  }
  .notification-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .notification-due-date {
    font-size: 14px;
    span {
      color: #7f56d9;
      font-weight: 600;
    }
  }

  .notification-body {
    margin-bottom: 10px;
  }

  .notification-task-and-project {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
  }

  .circle {
    content: '';
    display: inline-block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: rgb(255 255 255);
    border: 1px solid #dbdbdb;
    margin-left: 50px;
    vertical-align: middle;
  }

  .notification-footer {
    color: #999;
    font-size: 14px;
  }

  .notification-date {
    font-weight: bold;
  }

  .img-profile {
    width: 48px;
    border-radius: 50%;
  }
  .wrp-noti-item-list {
    overflow-y: scroll;
    height: 700px;
    width: 100%;
  }
`

const NotificationReade = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { notificationReade } = useSelector(selectNotificatons)

  const [dataNotificationsRead, setDataNotificationsRead] = useState([])
  const [hasMore, setHasMore] = useState(true)

  // for lazy loading
  const [page, setPage] = useState(1)
  const itemsPerPage = 10

  function debounce(func, wait) {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }

      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }

  const fetchNotificationData = useCallback(
    debounce(() => {
      const filter = {
        page: page,
        pageSize: itemsPerPage,
      }
      dispatch(getNotificationsReade(filter))
        .then(({ data }) => {
          if (data.length === 0 || data.length < itemsPerPage) {
            setHasMore(false)
          }

          setDataNotificationsRead((prevState) => [...prevState, ...data])
          setPage((prevPage) => prevPage + 1)
        })
        .catch((error) => {
          setHasMore(false)
        })
    }, 250),
    [dispatch, page]
  )

  useEffect(() => {
    fetchNotificationData()
  }, [])

  const onClickupdateRead = (itemData) => {
    const update = {
      data: {
        is_read: true,
      },
    }
    dispatchispatch(updateNotificationsList(itemData.id, update)).then(() => {
      if (itemData.type == 'task') {
        if (itemData.metadata.task_type == 'routine') {
          setcurrentPreviewType('task_routine')
        } else {
          setcurrentPreviewType('task_milestone')
        }
        setTaskId(itemData.metadata.task_id)
        setMenu(itemData.metadata.menu || '')
        setRequestId(itemData.metadata.request_approve_id || '')
        setIsShowDialogPreviewer(true)
      } else if (itemData.type == 'doc') {
        if (itemData.metadata.page == 'approval') {
          navigate('/task/approvals', { replace: true })
        }
        if (itemData.metadata.page == 'quotation') {
          navigate('/document/sales/quotation', { replace: true })
        }
        if (itemData.metadata.page == 'purchase') {
          navigate('/document/purchases/purchase', { replace: true })
        }
      }
    })
  }

  return (
    <Div>
      <div className="wrp-noti-item-list" id="dataNotificationsRead">
        {dataNotificationsRead?.length > 0 ? (
          <InfiniteScroll
            dataLength={dataNotificationsRead?.length}
            next={fetchNotificationData}
            hasMore={hasMore}
            loader={
              <div className="notifications">
                <div className="list-group">
                  <div className="list-group-item">
                    <div className="notification-header">
                      <span className="notification-sender stripe small-stripe"></span>
                      <span className="notification-action stripe small-stripe"></span>
                      <div className="notification-due-date stripe small-stripe"></div>
                    </div>
                    <div className="notification-body">
                      <div className="notification-row">
                        <span className="notification-task-and-project stripe medium-stripe"></span>
                        <span className="badge-loading"></span>
                        <span className="circle-loading"></span>
                      </div>
                    </div>
                    <div className="notification-footer">
                      <span className="notification-date stripe small-stripe-sm"></span>
                    </div>
                  </div>
                </div>
              </div>
            }
            endMessage={<p>No more data to load.</p>}
            scrollableTarget="dataNotificationsRead"
          >
            {dataNotificationsRead?.map((item, index) => (
              <div className="notifications" key={index}>
                <div className="list-group" onClick={() => onClickupdateRead(item)}>
                  <div className="list-group-item">
                    <div className="notification-header">
                      <span className="notification-sender">{item.title}</span>
                      <span className="notification-action">{item.message}</span>
                      <div className="notification-due-date">
                        ภายในวันที่ <span>{DateDisplay(item.send_date)}</span>
                      </div>
                    </div>
                    <div className="notification-body">
                      <div className="notification-row">
                        <span className="notification-task-and-project">
                          Task: {item?.metadata?.task} Project: {item?.metadata?.project}
                        </span>
                        <span className={`badge color-active-${item?.status}`}>
                          {' '}
                          {item?.status != 'active' ? item?.status : null}
                        </span>
                      </div>
                    </div>
                    <div className="notification-footer">
                      <span className="notification-date">{DateTimeHHmmssDisplay(item.updatedAt)}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <span className="NoData">-No Data-</span>
        )}
      </div>
    </Div>
  )
}

export default NotificationReade
