import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Document, Page,pdfjs } from 'react-pdf'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getDocumentTask } from '../../../redux/actions/summaryTask'
import { selectSummaryTask } from '../../../redux/selectors'
import { dlog } from '../../../utils/helper'
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Moment from 'react-moment'

import { useTranslation } from 'react-i18next'

export default function ViewDoc({ docId, file, isUpdate, isNew, newData, isDownload, isDownloaded,isRoutine }) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { documentInfo } = useSelector(selectSummaryTask)
  const pdfRef = useRef(null)
  const [docInfo, setDocInfo] = useState()
  const [totalInfo, setTotalInfo] = useState()
  const [taxInfo, setTaxInfo] = useState()
  const [totalTaxInfo, setTotalTaxInfo] = useState()
  const [numPages, setNumPages] = useState()
  const getDocData = useCallback(
    async (id) => {
      dispatch(getDocumentTask(id,true))
    },
    [dispatch]
  )
  useEffect(() => {
    if (newData) {
      setDocInfo(makeItemMinLength(newData))
      calculate(newData.procurement_item)
    }
  }, [newData])

  useEffect(() => {
    if (docId) {
      getDocData(docId)
    }
  }, [docId, isUpdate])

  const makeItemMinLength = (data) => {
    if(!file){
      if(data?.procurement_items?.data?.length > 0 || data?.procurement_item?.length > 0){
        if (data?.procurement_items?.data?.length < 7 || data?.procurement_item?.length < 7 ) {
          let arr = new Array(7);
          let items
          if(data.procurement_items?.data?.length > 0){
            items = data.procurement_items.data
          }else if(data.procurement_item?.length > 0){
            items = data.procurement_item
          }
          for(let i=0;i<arr.length;i++){
            for(let j=0;j<items.length;j++){
              if(i==j&&items[j]){
                arr[i] = items[j]
              }else{
                if(!arr[i]){
                  arr[i] = {}
                }
                
              }
            }
          }
          let tmpDoc = {...data}
          if(data.procurement_items?.data?.length > 0){
            tmpDoc.procurement_items.data = arr
          }else if(data.procurement_item?.length > 0){
            tmpDoc.procurement_item = arr
          }
          return tmpDoc 
        }else{
          return data
        }
      }else{
        return data
      }
    }else{
      return data
    }
  }
  useEffect(() => {
    if (!isNew && documentInfo?.attributes && documentInfo.id == docId) {
      setDocInfo(makeItemMinLength(documentInfo.attributes))
      if (documentInfo.attributes.procurement_items?.data) {
        calculate(documentInfo.attributes.procurement_items.data)
      }
    }
  }, [documentInfo])

  const calculate = (data) => {
    if (data) {
      const arr = data

      const results = arr.filter(element => {
        if (Object.keys(element).length !== 0) {
          return true;
        }
      
        return false;
      });
      
      const sum = results.reduce((accumulator, object) => {
        if(object){
          if (object.attributes?.total_price) {
            return accumulator + parseFloat(object.attributes.total_price)
          } else {
            return accumulator + parseFloat(object.quantity * object.unit_price)
          }
        }
        
      }, 0)
      const sumTwoDigit = makeTwoDigit(sum)
      setTotalInfo(sumTwoDigit)
      const taxTwoDigit = makeTwoDigit(sumTwoDigit * 0.07)
      setTaxInfo(taxTwoDigit)
      setTotalTaxInfo(makeTwoDigit(parseFloat(sumTwoDigit) + parseFloat(taxTwoDigit)))
    }
  }

  useEffect(() => {
    ;(async () => {
      if (isDownload && pdfRef && totalTaxInfo) {
        await handleDownloadPdf()
      }
    })()
  }, [pdfRef, totalTaxInfo])

  const handleDownloadPdf = async () => {
    const element = pdfRef.current
    if (element) {
      const canvas = await html2canvas(element)

      const data = canvas.toDataURL('image/png')

      const pdf = new jsPDF()
      const imgProperties = pdf.getImageProperties(data)
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width

      pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight)
      pdf.save('print.pdf')
      if (isDownloaded) {
        isDownloaded()
      }
    }
  }

  const makeTwoDigit = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }

  const numToWord = (inputNum) => {
    if (!inputNum) {
      return ''
    }

    let number = inputNum

    const num = ['ศูนย์', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า', 'สิบ']
    const pos = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน']

    let newNum = number.replace(/,/g, '')

    newNum = newNum.replace(' ', '')

    const numArr = newNum.split('.')
    if (numArr.length > 2) {
      return ''
    }

    const bathLength = numArr[0].length

    let res = ''
    for (let i = 0; i < bathLength; i++) {
      let bath = numArr[0][i]
      if (bath !== '0') {
        if (i == bathLength - 1 && bath == 1 && bathLength != 1) {
          res += 'เอ็ด'
        } else if (i == bathLength - 2 && bath == 2) {
          res += 'ยี่'
        } else if (i == bathLength - 2 && bath == 1) {
          res += ''
        } else {
          res += num[parseInt(bath)]
        }

        res += pos[bathLength - (i + 1)]
      }
    }
    res += 'บาท'
    if (numArr.length == 1) {
      res += 'ถ้วน'
    } else {
      if (numArr[1] == '0' || numArr[1] == '00' || numArr[1] == '') {
        res += 'ถ้วน'
      } else {
        const stang = numArr[1].slice(0, 2)
        const stLength = stang.length
        for (let i = 0; i < stLength; i++) {
          let st = stang[i]
          if (st !== '0') {
            if (i > 0 && st == 1) {
              res += 'เอ็ด'
            } else if (i == 0 && st == 2) {
              res += 'ยี่'
            } else if (i == 0 && st == 1) {
              res += ''
            } else {
              res += num[parseInt(st)]
            }
            res += i == 0 ? pos[1] : ''
          }
        }
        res += 'สตางค์'
      }
    }
    return res
  }

  const onDocumentLoadSuccess = (v) => {
    if (v.numPages) {
      setNumPages(v.numPages)
    } 
  }

  return (
    <Styled isDownload={isDownload}>
      <div className="wrp-doc">
        {file ? (
          file.ext == '.png' || file.ext == '.jpg' || file.ext == '.jpeg' ? (
            <img src={file.url} />
          ) : file.ext == '.pdf' ? (
            <div className="printarea">
            <Document file={file.url} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page,i) => (
                  <Page scale={5} key={i} pageNumber={page} />
                ))}
            </Document>
            </div>
          ) : (
            ''
          )
        ) : (
          docInfo && (
            <div className="printarea" ref={pdfRef}>
              <div className="head-section">
                <div className='left-head-section'>
                  <h1>{t('my_company_name')}</h1>
                  <h2>{t('my_company_slogan')}</h2>
                </div>
                <div className='right-head-section'>
                  <h1>{docInfo.template == 'Procurement' ? 'Purchase Order' : ''}</h1>
                  <ul className='right-head-info'>
                    <li>
                      <span>{t('date')}</span>
                      <div>{docInfo.purchase_date ? <Moment format="D/MM/YYYY">{docInfo.purchase_date}</Moment> : ''}</div>
                    </li>
                    <li>
                      <span>{t('p_o_number')}</span>
                      <div>{docInfo.reference_number || ''}</div>
                    </li>
                    <li>
                      <span>{t('customer')}</span>
                      <div>000000</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='business-section'>
                <div className='business-info vendor'>
                  <h4>{t('vendor')}</h4>
                  <ul>
                    <li>
                      <span>{t('name')}</span>
                      <div>{docInfo.contact_person || ''}</div>
                    </li>
                    <li>
                      <span>{t('company_name')}</span>
                      <div>{docInfo.purchase_from || ''}</div>
                    </li>
                    <li>
                      <span>{t('address')}</span>
                      <div>{docInfo.address || ''} {docInfo.thai_tambon?.data?.attributes?.name_th || docInfo.thai_tambon || ''}{' '}
                      {docInfo.thai_amphure?.data?.attributes?.name_th || docInfo.thai_amphure || ''}{' '}
                      {docInfo.thai_province?.data?.attributes?.name_th || docInfo.thai_province || ''}
                      </div>
                    </li>
                    <li>
                      <span>{t('zip_or_post_code')}</span>
                      <div>{docInfo.post_code || ''}</div>
                    </li>
                    <li>
                      <span>{t('phone_number')}</span>
                      <div>{docInfo.phone_no || ''}</div>
                    </li>
                    <li>
                      <span>{t('fax')}</span>
                      <div>-</div>
                    </li>
                    <li>
                      <span>{t('email')}</span>
                      <div>{docInfo.email || ''}</div>
                    </li>
                  </ul>
                </div>
                <div className='business-info ship-to'>
                  <h4>{t('ship_to')}</h4>
                  <ul>
                    <li>
                      <span>{t('name')}</span>
                      <div>-</div>
                    </li>
                    <li>
                      <span>{t('company_name')}</span>
                      <div>-</div>
                    </li>
                    <li>
                      <span>{t('address')}</span>
                      <div>-</div>
                    </li>
                    <li>
                      <span>{t('zip_or_post_code')}</span>
                      <div>-</div>
                    </li>
                    <li>
                      <span>{t('phone_number')}</span>
                      <div>-</div>
                    </li>
                    <li>
                      <span>{t('fax')}</span>
                      <div>-</div>
                    </li>
                    <li>
                      <span>{t('email')}</span>
                      <div>-</div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='table-section'>
                <div className="wrp-box wrp-item-list">
                  <div className="wrp-list">
                    <ul className="list-head">
                      <li className="sku-item">{t('code')}</li>
                      <li className="name-item">{t('product_name')}</li>
                      <li className="qtt-item">{t('qty')}</li>
                      <li className="unit-item">{t('unit_price')}</li>
                      <li className="total-item">{t('total')}</li>
                    </ul>

                    {docInfo.procurement_items?.data?.length > 0
                      ? docInfo.procurement_items.data.map((item, i) => (
                          <ul key={'doctemp-' + i} className='list-body'>
                            <li className="sku-item">{item.attributes?.item_code || ''}</li>
                            <li className="name-item">{item.attributes?.name || ''}</li>
                            <li className="qtt-item">{item.attributes?.quantity || ''}</li>
                            <li className="unit-item">{item.attributes?.unit_price ? makeTwoDigit(item.attributes.unit_price) : ''}</li>
                            <li className="total-item">{item.attributes?.total_price ? makeTwoDigit(item.attributes.total_price) : ''}</li>
                          </ul>
                        ))
                      : ''}
                    {docInfo.procurement_item?.length > 0
                      ? docInfo.procurement_item.map((item, i) => (
                          <ul key={'doctemp-' + i} className='list-body'>
                            <li className="sku-item">{item.item_code || ''}</li>
                            <li className="name-item">{item.name || ''}</li>
                            <li className="qtt-item">{item.quantity || ''}</li>
                            <li className="unit-item">{item.unit_price ? makeTwoDigit(item.unit_price) :''}</li>
                            <li className="total-item">{(item.quantity && item.unit_price) ? makeTwoDigit(item.quantity * item.unit_price) : ''}</li>
                          </ul>
                        ))
                      : ''}
                  </div>
                </div>
              </div>

              <div className='sum-section'>
                <div className='sum-left'>
                  <div className="wrp-box wrp-item-list">
                    <div className="wrp-list">
                      <ul className="list-head">
                        <li>{t('product_name')}</li>
                      </ul>

                      {/* {docInfo.procurement_items?.data?.length > 0
                        ? docInfo.procurement_items.data.map((item, i) => (
                            <ul key={'doctemp-' + i} className='list-body'>
                              <li className="sku-item">{item.attributes?.item_code || ''}</li>
                              <li className="name-item">{item.attributes?.name || ''}</li>
                              <li className="qtt-item">{item.attributes?.quantity || ''}</li>
                              <li className="unit-item">{item.attributes?.unit_price || ''}</li>
                              <li className="total-item">{item.attributes?.total_price || ''}</li>
                            </ul>
                          ))
                        : ''}
                      {docInfo.procurement_item?.length > 0
                        ? docInfo.procurement_item.map((item, i) => (
                            <ul key={'doctemp-' + i} className='list-body'>
                              <li className="sku-item">{item.item_code || ''}</li>
                              <li className="name-item">{item.name || ''}</li>
                              <li className="qtt-item">{item.quantity || ''}</li>
                              <li className="unit-item">{item.unit_price || ''}</li>
                              <li className="total-item">{item.quantity * item.unit_price || ''}</li>
                            </ul>
                          ))
                        : ''} */}
                    </div>
                  </div>
                </div>
                <div className='sum-right'>
                  <ul>
                    <li>
                      <span>{t('subtotal')}</span>
                      <div>{totalInfo || ''}</div>
                    </li>
                    <li>
                      <span>{t('discount')}</span>
                      <div className='sum-border'>-</div>
                    </li>
                    <li>
                      <span>{t('sales_tax_rate')}</span>
                      <div className='sum-border'>-</div>
                    </li>
                    <li>
                      <span>{t('sales_tax')}</span>
                      <div>-</div>
                    </li>
                    <li>
                      <span>{t('other_cost')}</span>
                      <div className='sum-border'>-</div>
                    </li>
                    <li>
                      <span className='sub-total'>{t('sub_total')}</span>
                      <div className='sum-border'>{totalInfo || ''}</div>
                    </li>
                  </ul>
                </div>        
              </div>
              {/* <div className="wrp-sum-box">
                <div className="wrp-box">
                  <div className="wrp-list">
                    <ul>
                      <li className="sum-left-item">
                        <b>ภาษีมูลค่าเพิ่ม (บาท)</b> / VAT
                      </li>
                      <li className="sum-right-item">{taxInfo || ''}</li>
                    </ul>
                    <ul>
                      <li className="sum-left-item">
                        <b>จำนวนเงินรวมทั้งสิ้น (บาท)</b> / Grand Total
                      </li>
                      <li className="sum-right-item">{totalTaxInfo || ''}</li>
                    </ul>
                    <ul className="sum-total">
                      <li className="sum-left-item">
                        <b>จำนวนเงินรวมทั้งสิ้น</b>
                      </li>
                      <li className="sum-right-item">
                        <b>{numToWord(totalTaxInfo)}</b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div className='bottom-section'>
                <div className='bottom-left'><h4>{t('date')}...........................................................................</h4></div>
                <div className='bottom-right'><h4>{t('name')}...........................................................................</h4></div>
              </div>
              <div className='footer-section'>
                <small>Lorem Ipsum Address:Keas 69 Str.15234, ChalandriAthens,Greece +30-2106019311 (landline)+30-6977664062 (mobile phone)+30-2106398905 (fax)</small>
              </div>
            </div>
          )
        )}
      </div>
    </Styled>
  )
}
const Styled = styled.div`
  flex: 1;
  overflow: hidden;

  .head-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }
  .left-head-section h1 {
    margin-bottom: 0;
  }
  .left-head-section h2 {
    margin-top: 0;
    font-weight: normal;
  }
  .right-head-section h1 {
    color: #42307d;
    text-align: right;
  }
  .right-head-info li {
    display: flex;
  }
  .right-head-info li span {
    display: block;
    width: 130px;
    line-height: 36px;
  }
  .right-head-info li div {
    width: 160px;
    border: 1px solid #dedfe3;
    text-align: right;
    height: 36px;
    line-height: 36px;
    padding: 0 5px;
  }
  .business-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .business-info {
    width: 45%;
  }
  .business-info h4 {
    background-color: #6941C6 !important;
    color: #fff;
    padding: 5px 20px;
    margin-bottom: 0;
  }
  .business-info ul {
    margin-left: 20px;
  }
  .business-info li {
    display: flex;
    line-height: 30px;
  }
  .business-info li span {
    display: block;
    width: 170px;
    flex-shrink: 0;
  }
  .table-section {
    margin-bottom: 40px;
  }
  .sum-section {
    display: flex;
  }
  .sum-left {
    width: 60%;
  }
  .sum-right {
    width: 30%;
    margin-left: 10%;
  }
  .sum-right ul li {
    display: flex;
    justify-content: space-between;
  }
  .sum-right ul li span.sub-total {
    font-weight: bold;
  }
  .sum-right ul li span{
    line-height: 36px;
  }
  .sum-right ul li div {
    width: 160px;
    text-align: right;
    height: 36px;
    line-height: 36px;
    padding: 0 5px;
  }
  .sum-border {
    border: 1px solid #dedfe3;
  }
  .bottom-section {
    display: flex;
    justify-content: space-around;
    margin: 50px 0;
  }
  .footer-section {
    margin-top: auto;
    border-top: 1px solid #dedfe3;
    margin-left: -24px;
    margin-right: -24px;
    display: flex;
    justify-content: center;
    padding-top: 20px;
  }
  .footer-section small {
    width: 74%;
    text-align: center;
    color: #667085;
  }
  h1 {
    margin-top: 0;
  }
  .title-text {
    margin-bottom: 0;
  }
  .wrp-doc {
    overflow-y: auto;
    height: 100%;
    padding: 24px;
  }
  .wrp-doc img {
    width: 100%;
  }
  canvas {
    width: 100% !important;
    height: auto !important;
  }
  .printarea {
    padding: ${({ isDownload }) => (isDownload ? '60px' : 0)};
    min-height: 1500px;
    display: flex;
    flex-direction: column;
  }
  .wrp-info {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  }
  .wrp-left-info {
    flex: 2;
  }
  .wrp-right-info {
    flex: 1;
  }
  .info {
    display: flex;
  }
  .info-title {
    width: 100px;
    font-weight: bold;
  }
  .bottom-line {
    border-bottom: 1px solid var(--Gray-600);
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  .sum-left .wrp-box {
    height: 100%;
  }
  .wrp-box {
    border: 1px solid #dedfe3;
  }
  .wrp-sum-box {
    display: flex;
    justify-content: end;
    margin-top: -1px;
  }
  .wrp-sum-box .wrp-box {
    width: 70%;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .list-head {
    background-color: #6941C6 !important;
    color: #fff;
  }
  .wrp-list ul {
    display: flex;
    flex-shrink: 0;
    border-bottom: 1px solid #dedfe3;
  }
  .wrp-list ul li {
    border-right: 1px solid #dedfe3;
    padding: 0px 10px;
  }
  .wrp-list ul li:last-child {
    border: none;
  }
  .sku-item {
    width: 10%;
    text-align: center;
  }
  .name-item {
    width: 50%;
  }
  .qtt-item {
    width: 10%;
  }
  .unit-item {
    width: 15%;
  }
  .total-item {
    width: 15%;
  }
  .list-body,.list-head {
    height: 40px;
    line-height: 40px;
  }
  .list-body .qtt-item,
  .list-body .unit-item,
  .list-body .total-item {
    text-align: right;
  }
  .sum-left-item {
    width: 60%;
    text-align: right !important;
  }
  .sum-right-item {
    width: 40%;
  }
  .sum-total {
    border: none !important;
  }
  .sum-total .sum-left-item {
    text-align: left !important;
    border: none;
  }

  @media print {

    zoom: 90%;
    @page {
      size: A4;
    }
    .wrp-navigation,
    .wrp-bottom-bar {
      display: none;
    }

    .printarea {
      display: block;
    }
  }
`
