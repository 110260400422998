import styled from 'styled-components'
import dayjs from 'dayjs'

import Date from './Date'
import Time from './Time'

const Div = styled.div`
  display: inline-flex;
  align-items: center;

  .date {
    flex: 1;
  }

  .time {
    margin-left: 4px;
  }
`

const DateTime = ({ value, error, disabled, onChange, ...props }) => {
  const day = dayjs(value || undefined)
    .set('second', 0)
    .set('millisecond', 0)

  const handleDateChange = (v) => {
    const date = dayjs(v)
    const newDateTime = day
      .set('year', date.format('YYYY'))
      .set('month', date.format('MM') - 1)
      .set('date', date.format('DD'))

    onChange(newDateTime.toISOString())
  }

  const handleTimeChange = (v) => {
    const [hour, minute] = v.split(':')
    const newDate = day.set('hour', hour).set('minute', minute)

    onChange(newDate.toISOString())
  }

  return (
    <Div {...props}>
      <Date
        className="date"
        value={value ? day.format('YYYY-MM-DD') : ''}
        onChange={handleDateChange}
        disabled={disabled}
        error={error}
      />
      <Time
        className="time"
        value={value ? day.format('HH:mm') : ''}
        onChange={handleTimeChange}
        disabled={disabled}
        error={error}
      />
    </Div>
  )
}

export default DateTime
