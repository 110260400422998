import { Divider, Stack } from '@mui/material'
import { styled } from '@mui/system'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Chip from 'components/common/Chip'
import SidePanelV2 from 'components/common/SidePanelV2'
import TableV3 from 'components/common/TableV3'
import Typography from 'components/common/Typography'
import DrawerDetails, { TaskDrawerTitle } from 'components/feature/operation-process/default-cards'
import Tabs from 'components/feature/operation-process/tabs'
import { SW } from 'constants/operation-process'
import { GrStatusGoodSmall } from 'react-icons/gr'
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess } from 'redux/selectors'
import Filters from './filter-form'
import columns from './table-columns'

/** //TODO: seperate TABS enum to other file. */
const TABS = {
  SOW: 'sow',
  TASK: 'task',
}

const init_drawer = {
  'view-sw': false,
  'view-task': false,
}

const MainSection = () => {
  const { t } = useTranslation()

  const refSearch = useRef()
  const dispatch = useDispatch()
  const { sw: swActions, task: taskActions } = opActions()
  const { getSW, clearSW } = swActions()
  const { getTask, clearTask } = taskActions()
  const opProcSelector = useSelector(selectOperationProcess)

  const { sws, sws_pagination, sw } = opProcSelector
  const { page: swPage, pageCount: swPageCount, total: swTotal } = sws_pagination

  const { tasks, task: taskData, tasks_pagination } = opProcSelector
  const { page: taskPage, pageCount: taskPageCount, total: taskTotal } = tasks_pagination

  const [tableType, setTableType] = useState(TABS['SOW'])
  const [tableProps, setTableProps] = useState({})
  const [drawer, setDrawer] = useState(init_drawer)
  const [filters, setFilters] = useState({})

  const fetchSWsBoard = useCallback(
    async (payload) => {
      dispatch(getSW().board(payload))
    },
    [dispatch]
  )

  const fetchTasksBoard = useCallback(
    async (payload) => {
      dispatch(getTask().board(payload))
    },
    [dispatch]
  )

  const reloadPage = useCallback(() => {
    fetchSWsBoard()
    fetchTasksBoard()
  }, [fetchSWsBoard, fetchTasksBoard])

  const initToggleDrawerData = {
    swId: undefined,
    taskId: undefined,
  }

  const handleToggleDrawer = (drawerName, open, data = initToggleDrawerData) => {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }

      setDrawer({ ...init_drawer, [drawerName]: open, ...data })
    }
  }

  const handlePickupSW = (e) => {
    handleToggleDrawer('view-sw', false)(e)
    fetchSWsBoard()
    // reloadPage()
  }

  const handlePickupTask = (e) => {
    handleToggleDrawer('view-sw', false)(e)
    fetchTasksBoard()
    // reloadPage()
  }

  const handleTaskDrawer = (taskId) => {
    handleToggleDrawer('view-task', true, { taskId })()
  }

  const handleSubTabChange = (tabValue) => {
    setTableType(tabValue)
    refSearch.current.reset()

    switch (tabValue) {
      case TABS['SOW']:
        fetchSWsBoard()
        break

      case TABS['TASK']:
        fetchTasksBoard()
        break

      default:
        break
    }
  }

  const transformSWsBoardToTable = (data) => {
    if (!data || data.length === 0) {
      console.warn('No data provided or data is empty.')
      return []
    }

    const mapDataItem = (item) => {
      const code = (
        <Typography variant="tableLink" onClick={handleToggleDrawer('view-sw', true, { swId: item.id })}>
          {item.code}
        </Typography>
      )
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={item.status}
          color={SW.STATUS_CHIP[item.status]}
          sx={{ height: '32px' }}
        />
      )

      return { ...item, code, status }
    }

    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const transformTasksBoardToTable = (data) => {
    if (!data || data.length === 0) {
      console.warn('No data provided or data is empty.')
      return []
    }

    const mapDataItem = (item) => {
      const code = (
        <Typography variant="tableLink" onClick={() => handleTaskDrawer(item.id)}>
          {item.code}
        </Typography>
      )
      const status = (
        <Chip
          icon={<GrStatusGoodSmall fontSize={10} color="inherit" opacity="0.75" />}
          label={item.status}
          color={SW.STATUS_CHIP[item.status]}
          sx={{ height: '32px' }}
        />
      )

      return { ...item, code, status }
    }

    const mappedData = data.map(mapDataItem)

    return mappedData
  }

  const getTableProps = () => {
    let resultProps = {}

    switch (tableType) {
      case TABS.TASK:
        resultProps = {
          data: transformTasksBoardToTable(tasks),
          page: taskPage,
          pageCount: taskPageCount,
          onStateChange({ page }) {
            const pagination = { ...tasks_pagination, page }
            fetchTasksBoard({ pagination, filters })
          },
        }
        break

      case TABS.SOW:
        resultProps = {
          data: transformSWsBoardToTable(sws),
          page: swPage,
          pageCount: swPageCount,
          onStateChange({ page }) {
            const pagination = { ...sws_pagination, page }
            fetchSWsBoard({ pagination, filters })
          },
        }
        break

      default:
        break
    }

    return setTableProps(resultProps)
  }

  const handleFilters = (values) => {
    const keys = Object.keys(values)

    let fetchData
    switch (tableType) {
      case TABS.SOW:
        fetchData = fetchSWsBoard
        break
      case TABS.TASK:
        fetchData = fetchTasksBoard
        break
      default:
        break
    }

    if (keys.length === 0) {
      setFilters({})
      return fetchData()
    }

    let filters = {}
    keys.forEach((key) => {
      if (!values[key]) {
        return
      }

      switch (key) {
        case 'name':
          filters = { ...filters, $or: [{ name: { $contains: values[key] } }, { code: { $contains: values[key] } }] }
          break
        default:
          break
      }
    })

    if (Object.keys(filters).length === 0) {
      setFilters({})

      return fetchData()
    }

    setFilters(filters)
    return fetchData({ filters })
  }

  useEffect(() => {
    reloadPage()
  }, [reloadPage])

  useEffect(() => {
    getTableProps()
  }, [sws, tasks, tableType])

  return (
    <TableContainer key="Table_TaskBoard">
      <TableHeader>
        <Stack sx={{ width: '100%' }}>
          <Stack direction="row" sx={{ width: '100%', padding: '1rem 1.5rem', justifyContent: 'space-between' }}>
            <Stack direction="row" spacing={2} alignItems="center" minHeight="36.5px">
              <Typography variant="tableTitle">{t('task_board')}</Typography>
            </Stack>
          </Stack>

          <Divider sx={{ borderColor: 'var(--Gray-200)' }} />

          <Stack
            direction="row"
            alignItems="center"
            gap="1rem"
            padding="1rem 1.5rem"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Filters ref={refSearch} onFormChange={handleFilters} type={tableType} />

            <Tabs tabType="modern">
              <Tabs.TaskBoard tabCounts={{ sow: swTotal, task: taskTotal }} onChange={handleSubTabChange} />
            </Tabs>
          </Stack>
        </Stack>
      </TableHeader>

      <TableV3 {...tableProps} columns={columns(tableType)} />

      <SidePanelV2
        titleText={sw?.name}
        supportingText={`SW Code: ${sw?.code}`}
        open={drawer['view-sw']}
        onClose={(event) => {
          dispatch(clearSW())
          handleToggleDrawer('view-sw', false)(event)
        }}
      >
        {drawer?.swId && <DrawerDetails.SoW id={drawer.swId} openTask={handleTaskDrawer} onAssign={handlePickupSW} />}
      </SidePanelV2>

      <SidePanelV2
        titleText={
          <TaskDrawerTitle
            taskData={taskData}
            onClickBreadcrumb={handleToggleDrawer('view-sw', true, { swId: taskData?.sw?.id })}
          />
        }
        supportingText={`Task Code: ${taskData?.code}`}
        open={drawer['view-task']}
        onClose={(event) => {
          dispatch(clearTask())
          handleToggleDrawer('view-task', false)(event)
        }}
      >
        <DrawerDetails.Task
          taskId={drawer?.taskId}
          onAssign={handlePickupTask}
          onViewClick={handleToggleDrawer('view-task', false)}
        />
      </SidePanelV2>
    </TableContainer>
  )
}

export default MainSection

const TableContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const TableHeader = styled('div')({
  display: 'flex',
  width: '100%',
  border: '1px solid var(--Gray-200)',
  borderBottom: 'none',
  borderTopLeftRadius: '.5rem' /* 8px */,
  borderTopRightRadius: '.5rem' /* 8px */,
})
