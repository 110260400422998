import { useTranslation } from 'react-i18next'

/**
 *
 * @param {string} type 'chain-link' | 'sop' | 'sow' | 'task'
 * @returns TableColumn[]
 */
export default function (type = 'chain-link') {
  const { t } = useTranslation()

  switch (type) {
    case 'chain-link':
      return [
        {
          Header: t('chianLink_tpl_id'),
          accessor: 'code',
          disableSortBy: false,
        },
        {
          Header: t('name'),
          accessor: 'name',
          disableSortBy: false,
        },
        {
          Header: t('description'),
          accessor: 'description',
          disableSortBy: false,
        },
        {
          Header: t('status'),
          accessor: 'status',
          disableSortBy: false,
        },
        {
          Header: t('last_updated'),
          accessor: 'updatedAt',
          disableSortBy: false,
        },
        {
          accessor: 'actions',
          disableSortBy: true,
          width: 100,
        },
      ]
    case 'sop':
      return [
        {
          Header: t('sop_tpl_id'),
          accessor: 'code',
          disableSortBy: false,
        },
        {
          Header: t('name'),
          accessor: 'name',
          disableSortBy: false,
        },
        {
          Header: t('description'),
          accessor: 'description',
          disableSortBy: false,
        },
        {
          Header: t('department'),
          accessor: 'department.name',
          disableSortBy: false,
        },
        {
          Header: t('status'),
          accessor: 'status',
          disableSortBy: false,
        },
        {
          Header: t('last_updated'),
          accessor: 'updatedAt',
          disableSortBy: false,
        },
        {
          accessor: 'actions',
          disableSortBy: true,
          width: 100,
        },
      ]
    case 'sow':
      return [
        {
          Header: t('sow_id'),
          accessor: 'code',
          disableSortBy: false,
        },
        {
          Header: t('name'),
          accessor: 'name',
          disableSortBy: false,
        },
        {
          Header: t('description'),
          accessor: 'description',
          disableSortBy: false,
        },
        {
          Header: t('duration'),
          accessor: 'duration',
          disableSortBy: false,
        },
        {
          Header: t('type'),
          accessor: 'type',
          disableSortBy: false,
        },
        {
          Header: t('department'),
          accessor: 'department.name',
          disableSortBy: false,
        },
        {
          Header: t('status'),
          accessor: 'status',
          disableSortBy: false,
        },
        {
          Header: t('last_updated'),
          accessor: 'updatedAt',
          disableSortBy: false,
        },
        {
          accessor: 'actions',
          disableSortBy: true,
          width: 100,
        },
      ]
    case 'task':
      return [
        {
          Header: t('task_id'),
          accessor: 'code',
          disableSortBy: false,
        },
        {
          Header: t('name'),
          accessor: 'name',
          disableSortBy: false,
        },
        {
          Header: t('description'),
          accessor: 'description',
          disableSortBy: false,
        },
        {
          Header: t('department'),
          accessor: 'department.name',
          disableSortBy: false,
        },
        {
          Header: t('status'),
          accessor: 'status',
          disableSortBy: false,
        },
        {
          Header: t('last_updated'),
          accessor: 'updatedAt',
          disableSortBy: false,
        },
        {
          accessor: 'actions',
          disableSortBy: true,
          width: 100,
        },
      ]

    default:
      break
  }
}
