import { ClickAwayListener } from '@mui/base'
import { Dropdown, Menu as JoyMenu, MenuItem as JoyMenuItem, MenuButton } from '@mui/joy'
import { styled } from '@mui/system'
import { forwardRef, useRef } from 'react'
import { IoChevronForward } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

const modifiers = [
  {
    name: 'offset',
    options: {
      offset: ({ placement }) => {
        if (placement.includes('end')) {
          return [0, 20]
        }

        if (placement.includes('bottom')) {
          return [0, 4]
        }

        return [-12, 20]
      },
    },
  },
]

const DropdownTree = forwardRef(function DropdownTree(
  {
    menuKey,
    menuItems = [],
    labelKey = 'name',
    children,
    onSubmenuMouseEnter = (key, level) => {},
    menuButtonProps,
    ...props
  },
  ref
) {
  const navigate = useNavigate()
  const menuRef = useRef(true)

  const createHandleMenuClick = (menuItem) => {
    return () => {
      if (menuItem?.pathto) {
        if (typeof menuItem.pathto === 'function') {
          menuItem.pathto()
        } else {
          navigate(menuItem.pathto)
        }
      }
    }
  }

  const renderMenuItem = (item, index) => (
    <MenuItem
      key={index}
      onClick={createHandleMenuClick(item)}
      onMouseEnter={() => onSubmenuMouseEnter?.(item?.key, item?.level)}
    >
      {item?.children ? (
        <Dropdown open={menuKey.includes(item.key)}>
          <MenuButton
            slots={{ root: 'div' }}
            onMouseEnter={() => {
              onSubmenuMouseEnter?.(item?.key, item?.level)
            }}
          >
            {item?.[labelKey] || item}
            <span className="menu-item-expand-icon">
              <IoChevronForward fontSize={14} />
            </span>
          </MenuButton>

          <Menu className="menu-container" placement="right-start" modifiers={modifiers}>
            {item.children.map(renderMenuItem)}
          </Menu>
        </Dropdown>
      ) : (
        item?.[labelKey] || item
      )}
    </MenuItem>
  )

  return (
    <Dropdown {...props}>
      <MenuButton {...menuButtonProps} ref={menuRef} slots={{ root: 'div' }}>
        {children}
      </MenuButton>

      <ClickAwayListener
        onClickAway={(event) => {
          if (menuRef?.current?.contains(event.target)) {
            return
          }
          onSubmenuMouseEnter?.(null, 0)
        }}
      >
        <Menu
          className="menu-container"
          modifiers={modifiers}
          onMouseEnter={() => {
            onSubmenuMouseEnter?.(menuItems[0]?.key, menuItems[0]?.level)
          }}
          anchorEl={menuRef?.current}
          placement="bottom-start"
        >
          {menuItems.map(renderMenuItem)}
        </Menu>
      </ClickAwayListener>
    </Dropdown>
  )
})

export default DropdownTree

const Menu = styled(JoyMenu)`
  &.menu-item-container {
    width: 288px;

    .menu-item-expand-icon {
      display: flex;
    }
  }
`

const MenuItem = styled(JoyMenuItem)`
  font-family: 'Inter', 'Noto Sans Thai';
  font-size: 16px;
  line-height: 24px;

  .MuiMenuButton-root {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .menu-item-expand-icon {
    margin-left: auto;
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
  }
`
