import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompanyInfo, DateTimeHHmmDisplay, getAdminUser, getEmployeeInfo } from '../../utils/common'
import { useTranslation } from 'react-i18next'

import { getProvinces, getDistricts, getSubDistricts } from '../../redux/actions/master'
import { selectMaster } from '../../redux/selectors'
import { getSubCompanyProfileById, updateSubCompany } from '../../redux/actions/company'
import { selectCompanyProfile } from '../../redux/selectors'

import { getActivityLogReturnData } from '../../redux/actions/ofm'
import { getEmployeeById } from '../../redux/actions/master'

import dayjs from 'dayjs'

import TextField from '../../components/form/TextField'
import Dropdown from '../../components/form/Dropdown'
import InputTextArea from '../../components/widget/element/InputTextArea'
import { GiCancel } from 'react-icons/gi'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

import Button from '../../components/form/button/Button'
import ButtonOutline from '../../components/form/button/ButtonOutline'
import question from '../../assets/images/question.png'
import DialogConfirm from '../../components/dialog/DialogConfirm'

import iconPlus from '../../assets/images/icon-plus.svg'
import iconBin from '../../assets/images/icon-bin.svg'
import iconFile from '../../assets/images/icon-file-round.svg'

import FileUpload from '../../components/form/FileUpload'
import { postAPI } from '../../utils/api'

import successIcon from '../../assets/images/success-icon.png'
import DialogSuccess from '../../components/dialog/DialogSuccess'
import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import mapIcon from '../../assets/images/gg_map.png'

import IconSubmit from '../../assets/images/send-03.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import IconKeep from '../../assets/images/check-circle.png'
import IconEdit from '../../assets/images/edit-01.png'
import IconArchive from '../../assets/images/archive.png'
import IconPutBack from '../../assets/images/flip-backward.png'

const Div = styled.div`
  .css_header_detail {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 14px;
    .date_css {
      color: #7f56d9;
      font-weight: bold;
    }
  }

  .content_header {
    padding: 14px 14px;

    width: ${(props) => props.width}px;
    position: fixed;
    background: #ffffff;
    top: 0;
    right: 0;
    z-index: 1;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
  }
  .dialog-content {
    padding: 0px 14px;
    padding-top: 75px;
    padding-bottom: 170px;
    .title {
      font-size: 18px;
      font-weight: 600;
      color: var(--Gray-900);
    }
    background-color: #ffffff;
  }
  header {
    /*padding: 24px 14px;*/
    /*margin-top: 20px;*/
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
  .icon {
    cursor: pointer;
  }
  .content-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .wrp-tool-right {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }
  .topic_main {
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
    color: #344054;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }

  .wrp-tool {
    display: flex;
    justify-content: space-between;
  }
  .wrp-tool-left {
    display: flex;
    align-items: center;
    /* background-color: red; */
  }
  .mb-1-rem {
    margin-bottom: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .css_upload {
    margin-top: 10px;
  }
  .topic_fill {
    font-size: 14px;
    color: #344054;
    margin: 5px 0px;
  }
  .TextField_employee {
    width: 100%;
  }
  .content {
    margin: 10px 0px;
  }
  .InputTextArea_css {
    width: 100%;
    font-size: 14px;
  }

  .prefix_icon {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .report_box_form {
    border: 2px solid rgb(208, 213, 221);
    border-radius: 8px;
    padding: 16px 24px;
    margin-bottom: 20px;
    background-color: #fcfcfd;
  }
  .report_formname {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
    /*margin-bottom:16px;*/
  }
  .report_sub_value {
    display: grid;
    /*grid-template-columns: repeat(2, 35% [col-start] 65% [col-end]);*/
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .report_sub_value_title {
    /*
    font-size: 16px;
    color: #101828;
    */
    font-size: 16px;
    color: #101828;
    font-weight: 500;
  }
  .report_sub_value_value {
    color: #475467;
    font-size: 16px;
    font-weight: 400;
  }
  .report_img {
    display: grid;
    grid-template-columns: repeat(5, 120px);
    gap: 20px;
    margin-bottom: 16px;
  }

  .cursor_pointer {
    cursor: pointer;
  }
  .img_in_row {
    width: 120px;
    border-radius: 12px;
  }

  /* ST CSS POPUP IMG */
  .dialog-img {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  /* Style for the dialog content */
  .dialog-img-content {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
    position: relative;
    max-width: 1200px;
    padding: 0 15px;
  }
  /* Style for the close icon */
  .dialog-img-close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    border: none;
    color: #333;
    cursor: pointer;
    padding: 0;
  }

  .dialog-img-popup-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  /* ED CSS POPUP IMG */
  .dialog-footer {
    /*margin-bottom: 15px;*/
    text-align: end;
    position: fixed;
    right: 0;
    bottom: 0;
    width: ${(props) => props.width}px;
    background-color: #ffffff;
    padding: 14px;
    .button_margin {
      margin: 10px;
    }
  }
  .dialog-footer-report {
    font-size: 20px;
    font-weight: 600;
    color: var(--Gray-900);
  }

  .dialog-footer-report-info {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 20px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .img-profile {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  .user_img_char_1 {
    border-radius: 50%;
    background-color: var(--Main-Button-Bg);
    width: 48px;
    height: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 12px;
    color: #ffffff;
    cursor: pointer;
  }
  .dialog-footer-report-info-full-name {
    font-size: 18px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .dialog-footer-report-info-position {
    font-size: 16px;
    font-weight: 400;
    color: var(--Primary-700);
  }
  .dialog-footer-report-info-location {
    font-size: 16px;
    font-weight: 500;
    color: var(--Gray-600);
  }
`

const DivBox = styled.div`
  .main_box {
    display: grid;
    grid-template-columns: repeat(2, 25% [col-start] 75% [col-end]);
    /*gap: 20px;*/
    padding-top: 10px;
    .main_box_text_head {
      font-size: 14px;
      font-weight: 600;
      color: var(--Gray-700);
    }
    .main_box_text_sub {
      font-size: 14px;
      color: var(--Gray-600);
    }
    .main_box_edit {
      border: 1px solid rgb(208, 213, 221);
      border-radius: 8px;
    }
    .main_box_fill_data {
      margin: 16px 24px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      gap: 20px;
      .topic_fill {
        font-size: 14px;
        color: #212121;
        margin-bottom: 5px;
      }
      .topic_detail {
        font-size: 14px;
        color: #757575;
      }
    }
    .main_box_button {
      margin: 16px 24px;
      text-align: end;
    }
    .border_buttom {
      border-bottom: 1px solid rgb(234, 236, 240);
    }
  }

  .TextField_employee {
    width: 100%;
  }
  .border_header {
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
`
const initCompanyFrom = {
  company_name_TH: '',
  company_name_EN: '',
  address: '',
  contact_name: '',
  contact_number: '',
  tex_id: '',
  thai_province: '',
  thai_amphure: '',
  thai_tambon: '',
  postcode: '',
  prefix_employee_id: '',

  address_EN: '',
  display_province_EN: '',
  display_amphure_EN: '',
  display_tambon_EN: '',
  contact_name_EN: '',

  theme: '',
}

const initialColorTheme = [
  {
    text: 'Purple',
    value: 'Default',
  },
  {
    text: 'Dark Green',
    value: 'Credence',
  },
  {
    text: 'Light Green',
    value: 'Wangkanai',
  },
]

const ModalEditCompany = ({ onClose, onSubmit, CompanyId, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const user = getAdminUser()
  const employee_info = getEmployeeInfo()
  const CompanyInfo = getCompanyInfo()

  const { provinces, districts, subdistricts } = useSelector(selectMaster)

  const [companyFrom, setCompanyFrom] = useState(initCompanyFrom)
  const [provinceList, setProvinceList] = useState([])
  const [districtList, setDistrictList] = useState([])
  const [subDistrictList, setSubDistrictList] = useState([])

  useEffect(() => {
    dispatch(getSubCompanyProfileById(CompanyId))
    dispatch(getProvinces())
  }, [dispatch])

  const { subcompanyList } = useSelector(selectCompanyProfile)

  useEffect(() => {
    const newList = provinces.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
      text_en: v?.attributes?.name_en,
    }))
    setProvinceList(newList)
  }, [provinces])

  useEffect(() => {
    const newList = districts.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
      text_en: v?.attributes?.name_en,
    }))
    setDistrictList(newList)
  }, [districts])

  useEffect(() => {
    const newList = subdistricts.map((v) => ({
      text: v?.attributes?.name_th,
      value: v?.id,
      postcode: v?.attributes?.zip_code,
      text_en: v?.attributes?.name_en,
    }))

    setSubDistrictList(newList)
  }, [subdistricts])

  useEffect(() => {
    setCompanyFrom((v) => ({
      ...v,
      company_name_TH: subcompanyList.company_name_TH,
      company_name_EN: subcompanyList.company_name_EN,
      address: subcompanyList.address,
      contact_name: subcompanyList.contact_name,
      contact_number: subcompanyList.contact_number,
      tex_id: subcompanyList.tex_id,
      thai_province: subcompanyList.thai_province?.id,
      thai_amphure: subcompanyList.thai_amphure?.id,
      thai_tambon: subcompanyList.thai_tambon?.id,
      postcode: subcompanyList.thai_tambon?.zip_code,
      prefix_employee_id: subcompanyList.prefix_employee_id,

      address_EN: subcompanyList.address_EN,
      display_province_EN: subcompanyList.thai_province?.name_en,
      display_amphure_EN: subcompanyList.thai_amphure?.name_en,
      display_tambon_EN: subcompanyList.thai_tambon?.name_en,
      contact_name_EN: subcompanyList.contact_name_EN,

      theme: subcompanyList.theme,
    }))
  }, [subcompanyList])

  useEffect(() => {
    if (companyFrom?.thai_province) {
      const dataMatch = provinceList.find((v) => v.value === companyFrom.thai_province)
      setCompanyFrom((prev) => ({
        ...prev,
        display_province_EN: dataMatch?.text_en,
      }))
      dispatch(getDistricts(companyFrom?.thai_province))
    }
  }, [companyFrom?.thai_province, dispatch])

  useEffect(() => {
    if (companyFrom?.thai_amphure) {
      const dataMatch = districtList.find((v) => v.value === companyFrom.thai_amphure)
      setCompanyFrom((prev) => ({
        ...prev,
        display_amphure_EN: dataMatch?.text_en,
      }))
      dispatch(getSubDistricts(companyFrom?.thai_amphure))
    }
  }, [companyFrom?.thai_amphure, dispatch])

  useEffect(() => {
    if (companyFrom.thai_tambon != '') {
      const dataMatch = subDistrictList.find((v) => v.value === companyFrom.thai_tambon)
      setCompanyFrom((prev) => ({
        ...prev,
        postcode: dataMatch?.postcode,
        display_tambon_EN: dataMatch?.text_en,
      }))
    }
  }, [companyFrom.thai_tambon])

  const onChangeFormEditCompany = (event) => {
    const { name, value } = event.target
    setCompanyFrom((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const onChangeDropDown = (name, value) => {
    if (name === 'thai_province') {
      setCompanyFrom((prev) => ({
        ...prev,
        [name]: value,
        thai_amphure: '',
        thai_tambon: '',
        postcode: '',
        display_amphure_EN: '',
        display_tambon_EN: '',
      }))
    } else if (name === 'thai_amphure') {
      setCompanyFrom((prev) => ({
        ...prev,
        [name]: value,
        thai_tambon: '',
        postcode: '',
        display_tambon_EN: '',
      }))
    } else if (name === 'thai_tambon') {
      setCompanyFrom((prev) => ({
        ...prev,
        [name]: value,
        postcode: '',
      }))
    } else {
      setCompanyFrom((prev) => ({
        ...prev,
        [name]: value,
      }))
    }
  }

  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  const onSubmitCreateBranch = () => {
    /*
    const fromData = {
      data: {
        ...branchFrom,
      },
    }
    dispatch(createBranchCompany(fromData))
    */
    const fromData = {
      data: {
        ...companyFrom,
      },
    }
    dispatch(updateSubCompany(CompanyId, fromData)).then(({ type }) => {
      if (type.endsWith('_SUCCESS')) {
        onSubmit()
      } else {
        setTitleFail('Error Create')
        setnameItemFail('')
        setDescriptionFail('')
        setFailModal(true)
      }
    })
  }

  return (
    <Div {...props}>
      <div className="content_header">
        <header>
          <div className="content-title">{t('edit_sub_company')}</div>
          <div className="wrp-tool-right">
            <CloseOutlinedIcon size={30} onClick={onClose} className="icon" />
          </div>
        </header>
        <hr />
      </div>

      <div className="dialog-content">
        <DivBox>
          <div className="main_box">
            <div>
              <div className="main_box_text_head">Company Information (TH)</div>
              <div className="main_box_text_sub">Basic information of company in Thai</div>
            </div>
            <div>
              <div className="main_box_edit">
                <div className="main_box_fill_data">
                  <div>
                    <div className="topic_fill">{t('company_name_th')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('company_name_th')}
                        name="company_name_TH"
                        value={companyFrom?.company_name_TH}
                        onChange={onChangeFormEditCompany}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="topic_fill">{t('tax_id_no')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('tax_id_no')}
                        name="tex_id"
                        value={companyFrom?.tex_id}
                        onChange={onChangeFormEditCompany}
                      />
                    </div>
                  </div>
                </div>
                <div className="main_box_fill_data">
                  <div>
                    <div className="topic_fill">{t('address')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('address')}
                        name="address"
                        value={companyFrom?.address}
                        onChange={onChangeFormEditCompany}
                      />
                    </div>
                  </div>
                </div>
                <div className="main_box_fill_data">
                  <div>
                    <div className="topic_fill">{t('province')}</div>
                    <div>
                      <Dropdown
                        id="Province"
                        optionList={provinceList}
                        value={companyFrom?.thai_province}
                        onChange={(value) => onChangeDropDown('thai_province', value)}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="topic_fill">{t('district')}</div>
                    <div>
                      <Dropdown
                        id="District"
                        optionList={districtList}
                        value={companyFrom?.thai_amphure}
                        onChange={(value) => onChangeDropDown('thai_amphure', value)}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="topic_fill">{t('sub_district')}</div>
                    <div>
                      <Dropdown
                        id="Sub-District"
                        optionList={subDistrictList}
                        value={companyFrom?.thai_tambon}
                        onChange={(value) => onChangeDropDown('thai_tambon', value)}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="topic_fill">{t('postcode')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('postcode')}
                        name="Passcode"
                        value={companyFrom?.postcode}
                        onChange={onChangeFormEditCompany}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="main_box_fill_data">
                  <div>
                    <div className="topic_fill">{t('contact_name')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('contact_name')}
                        name="contact_name"
                        value={companyFrom?.contact_name}
                        onChange={onChangeFormEditCompany}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="topic_fill">{t('contact_no')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('contact_no')}
                        name="contact_number"
                        value={companyFrom?.contact_number}
                        onChange={onChangeFormEditCompany}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="topic_fill">{t('prefix_employee_id_max_3_characters')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('prefix_employee_id')}
                        name="prefix_employee_id"
                        value={companyFrom?.prefix_employee_id}
                        onChange={onChangeFormEditCompany}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border_header"></div>

          <div className="main_box">
            <div>
              <div className="main_box_text_head">Company Information (EN)</div>
              <div className="main_box_text_sub">Basic information of company in English</div>
            </div>
            <div>
              <div className="main_box_edit">
                <div className="main_box_fill_data">
                  <div>
                    <div className="topic_fill">{t('company_name_en')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('company_name_en')}
                        name="company_name_EN"
                        value={companyFrom?.company_name_EN}
                        onChange={onChangeFormEditCompany}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="topic_fill">{t('tax_id_no')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('tax_id_no')}
                        name="tex_id"
                        value={companyFrom?.tex_id}
                        onChange={onChangeFormEditCompany}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="main_box_fill_data">
                  <div>
                    <div className="topic_fill">{t('address')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('address')}
                        name="address_EN"
                        value={companyFrom?.address_EN}
                        onChange={onChangeFormEditCompany}
                      />
                    </div>
                  </div>
                </div>

                <div className="main_box_fill_data">
                  <div>
                    <div className="topic_fill">{t('province')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('province')}
                        name="Passcode"
                        value={companyFrom?.display_province_EN}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="topic_fill">{t('district')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('district')}
                        name="Passcode"
                        value={companyFrom?.display_amphure_EN}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="topic_fill">{t('sub_district')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('sub_district')}
                        name="Passcode"
                        value={companyFrom?.display_tambon_EN}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="topic_fill">{t('postcode')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('postcode')}
                        name="Passcode"
                        value={companyFrom?.postcode}
                        onChange={onChangeFormEditCompany}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="main_box_fill_data">
                  <div>
                    <div className="topic_fill">{t('contact_name')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('contact_name')}
                        name="contact_name_EN"
                        value={companyFrom?.contact_name_EN}
                        onChange={onChangeFormEditCompany}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="topic_fill">{t('contact_no')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('contact_no')}
                        name="contact_number"
                        value={companyFrom?.contact_number}
                        onChange={onChangeFormEditCompany}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="topic_fill">{t('prefix_employee_id_max_3_characters')}</div>
                    <div>
                      <TextField
                        className="TextField_employee"
                        placeholder={t('prefix_employee_id')}
                        name="prefix_employee_id"
                        value={companyFrom?.prefix_employee_id}
                        onChange={onChangeFormEditCompany}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border_header"></div>

          <div className="main_box">
            <div>
              <div className="main_box_text_head">Other Setting</div>
              <div className="main_box_text_sub">Other system settings for company</div>
            </div>
            <div>
              <div className="main_box_edit">
                <div className="main_box_fill_data">
                  <div>
                    <div className="topic_fill">Color theme</div>
                    <div>
                      <Dropdown
                        id="color-theme"
                        optionList={initialColorTheme}
                        value={companyFrom?.theme}
                        onChange={(value) => onChangeDropDown('theme', value)}
                      />
                      <p>Select color theme for website</p>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </DivBox>
      </div>

      <div className="dialog-footer">
        <hr />
        <ButtonOutline className="button_margin" onClick={onClose}>
          {t('cancel')}
        </ButtonOutline>
        <Button className="button_margin" onClick={onSubmitCreateBranch}>
          {t('save')}
        </Button>
      </div>

      <DialogFail
        open={Boolean(failModal)}
        onClose={() => setFailModal(false)}
        onSubmit={() => setFailModal(false)}
        icon={failIcon}
        title={TitleFail}
        nameItem={nameItemFail}
        description={DescriptionFail}
        textYes={t('ok')}
      />
    </Div>
  )
}

export default ModalEditCompany
