import React, { useState, useCallback, useEffect } from 'react'
import { DateTimeHHmmDisplay, formatNumber } from 'utils/common'
import { v4 as uuidv4 } from 'uuid'
import { useForm } from 'react-hook-form'

import { useDispatch, useSelector } from 'react-redux'
import { selectDynamicForm } from 'redux/selectors'
import {
  getDataDynamicForm,
  getDynamicDropdownOptions,
  getDynamicDropdownOptionsWithSpecificId,
} from 'redux/actions/dynamicForm'

import styled from 'styled-components'
import Table from 'components/common/Table'
import { Box, Stack } from '@mui/material'
import ButtonOutline from 'components/form/button/ButtonOutline'
import Button from 'components/form/button/Button'
import SidePanel from 'components/common/SidePanel'
import Divider from 'components/common/Divider'
import BadgeSearch from 'components/common/BadgeSearch'
import Badge from 'components/common/Badge'
import CreateDynamicContent from 'components/dynamic-form/CreateDynamicContent'
import ViewDynamicContent from './ViewDynamicContent'
import DynamicFilter from './DynamicFilter'

const Div = styled.div`
  .link {
    &:hover {
      cursor: pointer;
    }
  }
  .highlight-text {
    color: var(--Primary-700);
    font-weight: 600;
  }
`
const TableWrapper = styled.div`
  .link {
    font-weight: bold;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .icon {
    cursor: pointer;
  }

  .table-header {
    padding: 1.25rem;
    border-radius: 0.5rem 0.5rem 0 0;
    background: var(--Base-White);
    border: 1px solid var(--Gray-200);
  }

  .input-filter-heading {
    color: var(--Gray-700);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 6px;
  }

  .total-heading {
    color: var(--Gray-900);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }
`

function DynamicLandingPage({ name }) {
  // external hook
  const dispatch = useDispatch()

  // schema landing page
  const { landingPageSchema, state, isLoading } = useSelector(selectDynamicForm)

  // initiate data
  const fetchDynamicData = useCallback(
    (filter = { page: 1, pageSize: 10 }) => {
      dispatch(getDataDynamicForm(name, filter))
    },
    [dispatch, landingPageSchema, name]
  )
  const fetchDropdownOptions = async (endpoint) => {
    const result = await dispatch(getDynamicDropdownOptions(endpoint))
    return result
  }
  const fetchDropdownOptionsSpecific = async (id, endpoint) => {
    const result = await dispatch(getDynamicDropdownOptionsWithSpecificId(id, endpoint))
    return result
  }
  const initiateDropdownApi = async (initialForms, formFields) => {
    const allDropdownAPI = Object.entries(landingPageSchema?.filters)
      .filter(([_, value]) => {
        return value.type === 'dropdown-api'
      })
      .map(([key, value]) => ({
        key,
        value,
      }))

    const options = {}
    for (let item of allDropdownAPI) {
      const { key, value } = item
      const { endpoint } = value

      const isSpecific = endpoint.includes(':id')
      if (isSpecific) {
        const id = key === 'amphure' ? initialForms?.province : initialForms?.amphure
        const newDropdownOptions = await fetchDropdownOptionsSpecific(id, endpoint)
        options[key] = newDropdownOptions
      } else {
        const newDropdownOptions = await fetchDropdownOptions(endpoint)
        options[key] = newDropdownOptions
      }
    }

    const changedFormField = {}
    for (let [key, value] of Object.entries(options)) {
      changedFormField[key] = {
        ...formFields[key],
        attribute: {
          ...formFields[key].attribute,
          options: value,
        },
      }
    }

    const initialFormFields = {
      ...landingPageSchema?.filters,
      ...changedFormField,
    }

    setFormFields(initialFormFields)
  }

  useEffect(() => {
    if (
      (state === 'GET_LANDING_PAGE_SCHEMA.SUCCESS' ||
        state === 'CREATE_DYNAMIC_FORM.SUCCESS' ||
        state === 'UPDATE_DYNAMIC_FORM.SUCCESS' ||
        state === 'DELETE_DYNAMIC_FORM.SUCCESS') &&
      isLoading === false
    ) {
      fetchDynamicData()
    }
  }, [state, isLoading, name])

  // width window
  const [widthWindow, setWidthWindow] = useState(window.innerWidth)
  const [widthModal, setWidthModal] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidthWindow(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    const newWidthSidePanel =
      widthWindow < 769
        ? widthWindow
        : widthWindow > 769 && widthWindow < 1024
        ? (widthWindow * 80) / 100
        : (widthWindow * 55) / 100
    setWidthModal(newWidthSidePanel)
  }, [widthWindow])

  // form fields filters
  const { control, watch, setValue, reset } = useForm({
    defaultValues: {},
  })
  const formFilters = watch()
  const [formFields, setFormFields] = useState()
  const [initialFilters, setInitialFilters] = useState()
  const [searchFilters, setSearchFilters] = useState([])

  // sew new options and reset value for dropdown ref [dropdown dependency]
  const setNewFormField = async (value, ref_fetch, reset_value) => {
    // set new value for ref key
    if (reset_value) {
      for (let item of reset_value) {
        const { key, refKey } = item

        if (refKey === null) setValue(key, '')
        else {
          const { key_ref, key_value } = refKey
          const { options } = formFields[key_ref]?.attribute
          const matchedValue = options.find((v) => v.value === value)

          setValue(key, matchedValue ? matchedValue[key_value] : '', { shouldValidate: true })
        }
      }
    }

    // fetch new options for ref key
    if (ref_fetch) {
      const { endpoint } = formFields[ref_fetch]
      const newOptions = await fetchDropdownOptionsSpecific(value, endpoint)

      setNewOptionsForFormFields(ref_fetch, newOptions)
    }
  }
  const setNewOptionsForFormFields = async (key, newOptions) => {
    setFormFields((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        attribute: {
          ...prev[key]?.attribute,
          options: newOptions,
        },
      },
    }))
  }
  // filter function
  const onChangeFilter = (key, value) => {
    const keySearchText = getKeySearchText()
    const params = formFields[keySearchText]?.params

    const currentFilters = {
      ...formFilters,
      [key]: value,
    }
    const mappedFilters = Object.entries(currentFilters)
      .map(([key, value]) => ({
        key,
        value,
      }))
      .filter((v) => v.value !== '' && v.key !== keySearchText)

    if (mappedFilters?.length > 0) {
      const filters = mappedFilters.reduce((acc, cur) => {
        const { key, value } = cur
        acc[key] = value
        return acc
      }, {})
      const finalFilters = Object.entries(formFields)
        .map(([key, value]) => ({
          key,
          value,
        }))
        .reduce((acc, cur) => {
          const { key, value } = cur
          const { params } = value
          if (filters[key]) acc[params] = filters[key]
          return acc
        }, {})
      const multipleSearch = searchFilters?.map((v, i) => {
        const finalParams = params.reduce((acc, cur) => {
          acc[cur.replace('{index}', i)] = v.name
          return acc
        }, {})

        return {
          ...finalParams,
        }
      })

      const request = {
        ...finalFilters,
        page: 1,
        pageSize,
        multipleSearch,
      }
      fetchDynamicData(request)
    }
  }
  const onChangePagination = (value) => {
    const key = getKeySearchText()
    const params = formFields[key]?.params

    const multipleSearch = searchFilters?.map((v, i) => {
      const finalParams = params.reduce((acc, cur) => {
        acc[cur.replace('{index}', i)] = v.name
        return acc
      }, {})

      return {
        ...finalParams,
      }
    })
    const usedFilter = Object.entries(formFilters)
      .map(([key, value]) => ({
        key,
        value,
      }))
      .filter((v) => v.key !== 'name')
      .reduce((acc, cur) => {
        const { key, value } = cur
        if (value !== '') acc[key] = value
        return acc
      }, {})

    const filters = {
      ...usedFilter,
      page: value.page,
      pageSize: pageSize,
      multipleSearch,
    }
    fetchDynamicData(filters)
  }
  const onSearch = () => {
    const key = getKeySearchText()
    const params = formFields[key]?.params

    if (formFilters[key] !== '') {
      const newSearchFilter = {
        key: uuidv4(),
        name: formFilters[key],
      }

      const multipleSearch = [...searchFilters, newSearchFilter]?.map((v, i) => {
        const finalParams = params.reduce((acc, cur) => {
          acc[cur.replace('{index}', i)] = v.name
          return acc
        }, {})

        return {
          ...finalParams,
        }
      })
      const usedFilter = Object.entries(formFilters)
        .map(([key, value]) => ({
          key,
          value,
        }))
        .filter((v) => v.key !== key)
        .reduce((acc, cur) => {
          const { key, value } = cur
          if (value !== '') acc[key] = value
          return acc
        }, {})

      const filters = {
        ...usedFilter,
        page: 1,
        pageSize: pageSize,
        multipleSearch,
      }

      fetchDynamicData(filters)
      setValue(key, '')
      setSearchFilters((prev) => [...prev, newSearchFilter])
    }
  }
  const deleteSearchBadge = (key) => {
    const keySearchText = getKeySearchText()
    const params = formFields[keySearchText]?.params

    const currentSearchFilters = searchFilters?.filter((v) => v.key !== key)
    const multipleSearch = currentSearchFilters?.map((v, i) => {
      const finalParams = params.reduce((acc, cur) => {
        acc[cur.replace('{index}', i)] = v.name
        return acc
      }, {})

      return {
        ...finalParams,
      }
    })
    const usedFilter = Object.entries(formFilters)
      .map(([key, value]) => ({
        key,
        value,
      }))
      .filter((v) => v.key !== 'name')
      .reduce((acc, cur) => {
        const { key, value } = cur
        if (value !== '') acc[key] = value
        return acc
      }, {})

    const filters = {
      ...usedFilter,
      page: 1,
      pageSize: pageSize,
      multipleSearch,
    }

    fetchDynamicData(filters)
    setSearchFilters(currentSearchFilters)
  }
  const onClearSearch = () => {
    const filters = {
      page: 1,
      pageSize: pageSize,
      multipleSearch: [],
    }

    fetchDynamicData(filters)
    clearFilter()
  }
  const clearFilter = () => {
    reset(initialFilters)
    setSearchFilters([])
  }

  useEffect(() => {
    if (Object.keys(landingPageSchema).length > 0) {
      const initialForm = Object.keys(landingPageSchema?.filters).reduce((acc, key) => {
        acc[key] = ''
        return acc
      }, {})

      initiateDropdownApi(initialForm, landingPageSchema?.filters).then((_) => {
        reset(initialForm)
        setInitialFilters(initialForm)
      })
    }
  }, [landingPageSchema, reset])

  // table data
  const { dynamicDatas, total, page, pageCount, pageSize } = useSelector(selectDynamicForm)
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    const isLinkColumn = landingPageSchema?.columns?.find((v) => v.isLink)
    const newTableData = dynamicDatas?.map((v, i) => {
      const mappedData = Object.entries(v)
        .map(([key, value]) => {
          return {
            key,
            value: value,
          }
        })
        .reduce((acc, cur) => {
          const { key, value } = cur
          acc[key] = typeof value === 'number' ? formatNumber(value) : value ? value : '-'
          return acc
        }, {})

      return {
        ...mappedData,
        [isLinkColumn?.accessor]: (
          <Box className="highlight-text link" onClick={() => openViewSidePanel(v.id)}>
            {mappedData[isLinkColumn?.accessor]}
          </Box>
        ),
        updatedAt: DateTimeHHmmDisplay(v.updatedAt),
      }
    })

    setTableData(newTableData)
  }, [dynamicDatas])

  // selected id
  const [selectedId, setSelectedId] = useState('')

  // create side panel
  const [isOpenCreateSidePanel, setIsOpenCreateSidePanel] = useState(false)

  const openCreateSidePanel = () => {
    setIsOpenCreateSidePanel(true)
  }
  const closeCreateSidePanel = () => {
    setIsOpenCreateSidePanel(false)
  }

  // view side panel
  const [isOpenViewSidePanel, setIsOpenViewSidePanel] = useState(false)

  const openViewSidePanel = (id) => {
    setSelectedId(id)
    setIsOpenViewSidePanel(true)
  }
  const closeViewSidePanel = () => {
    setIsOpenViewSidePanel(false)
  }

  // other function
  const getKeySearchText = () => {
    const result = Object.entries(formFields)
      .map(([key, value]) => ({ key, type: value.type }))
      .find((v) => v.type === 'search-text')

    return result?.key ?? null
  }

  return (
    <Div>
      <TableWrapper>
        <div className="table-header">
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={1}>
              <div className="total-heading">{landingPageSchema?.title_table}</div>
              <Badge>{total} items</Badge>
            </Stack>
          </Stack>
          <Box sx={{ my: 2 }}>
            <Divider />
          </Box>

          <Box>
            <Stack sx={{ mb: 1 }} direction="row" justifyContent="space-between" alignItems="flex-end" spacing={1}>
              <Stack direction="row" alignItems="flex-end" spacing={1}>
                <form>
                  {formFields && (
                    <DynamicFilter
                      formFields={formFields}
                      control={control}
                      onSearch={onSearch}
                      onChangeFilter={onChangeFilter}
                      setNewFormField={setNewFormField}
                    />
                  )}
                </form>

                <ButtonOutline onClick={onClearSearch}>Clear</ButtonOutline>
              </Stack>
              <Stack direction="row" spacing={1}>
                <Button onClick={openCreateSidePanel}>{landingPageSchema?.submit_button?.text}</Button>
              </Stack>
            </Stack>

            <BadgeSearch searchFilters={searchFilters} deleteSearchBadge={deleteSearchBadge} />
          </Box>
        </div>

        {Object.keys(landingPageSchema).length > 0 && (
          <Table
            columns={landingPageSchema?.columns}
            data={tableData}
            onStateChange={onChangePagination}
            page={page}
            pageCount={pageCount}
          />
        )}
      </TableWrapper>

      {/* side panel */}
      <SidePanel isOpen={isOpenCreateSidePanel} setIsOpen={setIsOpenCreateSidePanel} width={widthModal}>
        <CreateDynamicContent name={name} onClose={closeCreateSidePanel} />
      </SidePanel>
      <SidePanel isOpen={isOpenViewSidePanel} setIsOpen={setIsOpenViewSidePanel} width={widthModal}>
        <ViewDynamicContent id={selectedId} name={name} onClose={closeViewSidePanel} />
      </SidePanel>
    </Div>
  )
}

export default DynamicLandingPage
