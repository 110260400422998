import { combineReducers } from 'redux'
import {
  ADD_DOCUMENT_ITEM,
  CREATE_DOCUMENT_TASK,
  CREATE_DOC_REQUEST_APPROVE,
  CREATE_SUB_TASK,
  CREATE_TASK,
  DELETE_DOCUMENT_ITEM,
  DELETE_DOCUMENT_TASK,
  EDIT_PROJECT_TASK,
  EDIT_SUB_TASK,
  EDIT_TASK,
  GET_AMPHURE_LIST,
  GET_DOCUMENT_TASK,
  GET_EMPLOYEE_TASK,
  GET_EMPLOYEE_TABLE_TASK,
  GET_PROVINCE_LIST,
  GET_REQUEST_APPROVES,
  GET_REQUEST_APPROVE_ITEM,
  GET_SUB_TASK_BY_ID,
  GET_SUMMARY_TASK,
  GET_SUMMARY_MY_TASK,
  GET_TAMBON_LIST,
  GET_TASK_BY_ID,
  UPDATE_DOCUMENT_TASK,
  UPDATE_NOTE_ACTION,
  UPDATE_REQUEST_APPROVE_ITEM,
  UPDATE_SUB_TASK_ACTION,
  UPDATE_TASK_ACTION,
  UPDATE_TASK_ROUTINE,
} from '../actionTypes'

const initialState = {
  taskList: [],
  employeeList: [],
  employeeListTable: [],
  taskInfo: {},
  subTaskInfo: {},
  documentInfo: {},
  documentItem: {},
  approveInfo: {},
  provinceListStore: [],
  amphureListStore: [],
  tambonListStore: [],
  approveList: [],
  skip: 0,
  limit: 10,
  isLoading: false,

  page: 1,
  pageSize: 10,
  pageCount: 0,
  total: 0,
  totals: {},
}

const summaryTaskReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SUMMARY_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
    }
    case GET_SUMMARY_TASK.SUCCESS: {
      const { data, totals, meta } = action
      return {
        ...state,
        taskList: data,
        // total: meta.total,
        // skip: meta.skip,
        // limit: meta.limit,
        // pagination: {
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        // },
        totals: totals,
        isLoading: false,
      }
    }
    case GET_SUMMARY_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
    }
    case GET_SUMMARY_MY_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
    }
    case GET_SUMMARY_MY_TASK.SUCCESS: {
      const { data, total, meta } = action
      // 
      return {
        ...state,
        taskList: data,
        total: meta.total,
        skip: meta.skip,
        limit: meta.limit,
        // page: meta.pagination.page,
        // pageSize: meta.pagination.pageSize,
        // pageCount: meta.pagination.pageCount,
        // total: meta.pagination.total,
        totals: total,
        isLoading: false,
      }
    }
    case GET_SUMMARY_MY_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
    }
    case GET_TASK_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_TASK_BY_ID.SUCCESS:
      return {
        ...state,
        taskInfo: action.data,
        isLoading: false,
      }
    case GET_TASK_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_TASK.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case EDIT_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case EDIT_TASK.SUCCESS: {
      const { data } = action
      return {
        ...state,
        taskInfo: data.attributes,
        isLoading: false,
      }
    }
    case EDIT_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_TASK_ACTION.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_TASK_ACTION.SUCCESS: {
      const { data } = action
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_TASK_ACTION.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_SUB_TASK_ACTION.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_SUB_TASK_ACTION.SUCCESS: {
      const { data } = action
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_SUB_TASK_ACTION.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case EDIT_SUB_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case EDIT_SUB_TASK.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case EDIT_SUB_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_SUB_TASK_BY_ID.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_SUB_TASK_BY_ID.SUCCESS:
      return {
        ...state,
        subTaskInfo: action.data,
        isLoading: false,
      }
    case GET_SUB_TASK_BY_ID.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_EMPLOYEE_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_EMPLOYEE_TASK.SUCCESS: {
      const { data, meta } = action
      return {
        ...state,
        employeeList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_EMPLOYEE_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
      case GET_EMPLOYEE_TABLE_TASK.REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case GET_EMPLOYEE_TABLE_TASK.SUCCESS: {
        const { data, meta } = action
        return {
          ...state,
          employeeListTable: data,
          page: meta.pagination.page,
          pageSize: meta.pagination.pageSize,
          pageCount: meta.pagination.pageCount,
          total: meta.pagination.total,
          isLoading: false,
        }
      }
      case GET_EMPLOYEE_TABLE_TASK.FAILURE:
        return {
          ...state,
          isLoading: false,
        }

    case UPDATE_NOTE_ACTION.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_NOTE_ACTION.SUCCESS: {
      const { data } = action
      return {
        ...state,
        // taskInfo: data.attributes,
        isLoading: false,
      }
    }
    case UPDATE_NOTE_ACTION.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_DOCUMENT_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_DOCUMENT_TASK.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_DOCUMENT_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_DOCUMENT_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_DOCUMENT_TASK.SUCCESS:
      const { data } = action
      return {
        ...state,
        documentInfo: data,
        isLoading: false,
      }
    case GET_DOCUMENT_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_DOCUMENT_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_DOCUMENT_TASK.SUCCESS: {
      // const { data } = action
      return {
        ...state,
        // taskInfo: data.attributes,
        isLoading: false,
      }
    }
    case UPDATE_DOCUMENT_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_DOCUMENT_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case DELETE_DOCUMENT_TASK.SUCCESS: {
      const { data } = action
      return {
        ...state,
        isLoading: false,
      }
    }
    case DELETE_DOCUMENT_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case ADD_DOCUMENT_ITEM.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case ADD_DOCUMENT_ITEM.SUCCESS: {
      const { data } = action

      return {
        ...state,
        documentItem: data,
        isLoading: false,
      }
    }
    case ADD_DOCUMENT_ITEM.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case DELETE_DOCUMENT_ITEM.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case DELETE_DOCUMENT_ITEM.SUCCESS: {
      const { data } = action
      return {
        ...state,
        isLoading: false,
      }
    }
    case DELETE_DOCUMENT_ITEM.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_DOC_REQUEST_APPROVE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_DOC_REQUEST_APPROVE.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_DOC_REQUEST_APPROVE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_REQUEST_APPROVES.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_REQUEST_APPROVES.SUCCESS: {
      const { data, meta } = action

      return {
        ...state,
        approveList: data,
        page: meta.pagination.page,
        pageSize: meta.pagination.pageSize,
        pageCount: meta.pagination.pageCount,
        total: meta.pagination.total,
        isLoading: false,
      }
    }
    case GET_REQUEST_APPROVES.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_REQUEST_APPROVE_ITEM.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_REQUEST_APPROVE_ITEM.SUCCESS:
      const { approveData } = action
      return {
        ...state,
        approveInfo: approveData,
        isLoading: false,
      }
    case GET_REQUEST_APPROVE_ITEM.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_REQUEST_APPROVE_ITEM.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_REQUEST_APPROVE_ITEM.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_REQUEST_APPROVE_ITEM.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_PROVINCE_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_PROVINCE_LIST.SUCCESS:
      const { dataProvince } = action
      return {
        ...state,
        provinceListStore: dataProvince,
        isLoading: false,
      }
    case GET_PROVINCE_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_AMPHURE_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_AMPHURE_LIST.SUCCESS:
      const { dataAmphure } = action
      return {
        ...state,
        amphureListStore: dataAmphure,
        isLoading: false,
      }
    case GET_AMPHURE_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_TAMBON_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case GET_TAMBON_LIST.SUCCESS:
      const { dataTambon } = action
      return {
        ...state,
        tambonListStore: dataTambon,
        isLoading: false,
      }
    case GET_TAMBON_LIST.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_SUB_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case CREATE_SUB_TASK.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case CREATE_SUB_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case EDIT_PROJECT_TASK.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case EDIT_PROJECT_TASK.SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case EDIT_PROJECT_TASK.FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case UPDATE_TASK_ROUTINE.REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case UPDATE_TASK_ROUTINE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
      }
    }
    case UPDATE_TASK_ROUTINE.FAILURE:
      return {
        ...state,
        isLoading: false,
      }

    default:
      return state
  }
}

export default combineReducers({
  summaryTaskReducer,
})
