import dayjs from 'dayjs'
import * as buddhistEra from 'dayjs/plugin/buddhistEra'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/th'
import Papa from 'papaparse'

const ADMIN = 'admin'
dayjs.extend(buddhistEra)
dayjs.extend(localizedFormat)
const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'

export const setAdminData = (data) => {
  localStorage.setItem(ADMIN, JSON.stringify(data))
}

export const removeAdminData = () => {
  localStorage.removeItem(ADMIN)
}

export const getAdminToken = () => {
  const { jwt } = JSON.parse(localStorage.getItem(ADMIN)) || {}
  return jwt
}

export const getAdminUser = () => {
  const admin = JSON.parse(localStorage.getItem(ADMIN)) || null
  return admin?.user ?? null
}

export const getFirstSplit = (word, split = '.') => {
  return word.split(split)[0]
}

export const displayDate = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = lang == 'th' ? date.format('D MMM BBBB h:mm A') : date.format('D MMM YYYY h:mm A')
    return formattedDate
  }
  return null
}

//--- ST Format ตอนแสดงผล วันที่ และเวลาของระบบ จะใช่ format เดี่ยวกันตาม By James
/*
วันที่จะแสดงเป็น 1 Jun 2023 เวลาจะเป็น 24 ชั่วโมง
*/
export const DateDisplay = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = lang == 'th' ? date.format('D MMM BBBB') : date.format('D MMM YYYY')
    return formattedDate
  }
  return null
}

export const DateTimeHHmmssDisplay = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = lang == 'th' ? date.format('D MMM BBBB HH.mm.ss') : date.format('D MMM YYYY HH.mm.ss')
    return formattedDate
  }
  return null
}

export const DateTimeHHmmDisplay = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = lang == 'th' ? date.format('D MMM BBBB HH.mm') : date.format('D MMM YYYY HH.mm')
    return formattedDate
  }
  return null
}

export const TimeDisplayHHmm = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = date.format('HH.mm')
    return formattedDate
  }
  return null
}

export const TimeDisplayHHmmss = (inputDate) => {
  if (inputDate) {
    const date = dayjs(inputDate).locale(lang)
    const formattedDate = date.format('HH.mm.ss')
    return formattedDate
  }
  return null
}

//--- ED Format ตอนแสดงผล วันที่ และเวลาของระบบ จะใช่ format เดี่ยวกันตาม By James

export const getEmployeeInfo = () => {
  const admin = JSON.parse(localStorage.getItem(ADMIN)) || null
  return admin?.employee_info ?? null
}

export const getCompanyInfo = () => {
  const admin = JSON.parse(localStorage.getItem(ADMIN)) || null
  return admin?.company_info ?? null
}

// read csv file
export const csvToJson = (csv) => {
  const parsed = Papa.parse(csv, {
    header: true,
    skipEmptyLines: true,
  })

  const langFormat = parsed.data.reduce((acc, cur) => {
    Object.keys(cur).forEach((key) => {
      if (key !== 'key') {
        if (!acc[key]) {
          acc[key] = { translation: {} }
        }

        acc[key].translation[cur.key] = cur[key]
      }
    })

    return acc
  }, {})

  return langFormat
}

export const getAllLocalAdmin = () => {
  return JSON.parse(localStorage.getItem(ADMIN)) || {}
}

export const numberWithCommas = (value) => {
  if (integerCheck(value)) {
    let nf = new Intl.NumberFormat('en-US')
    return nf.format(value)
  } else {
    return value
  }

  //return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const integerCheck = (num) => {
  /*
  const isInt = (n) => Number(n) === n && n % 1 === 0
  const isFloat = (n) => Number(n) === n && n % 1 !== 0
  return (isInt(num) || !isFloat(num))   
  */
  return !isNaN(Number(num))
}

export const GetPermissionAction = (key_menu, key_submenu_path, key_tab, key_permission) => {
  const fix_open_permission = {
    is_read: true,
    is_edit: true,
  }
  const fix_no_permission = null
  const { permission } = JSON.parse(localStorage.getItem('admin'))
  const this_sub_menu_permission = permission[key_menu].filter((it_2) => it_2.path === key_submenu_path)
  if (key_tab) {
    //--- Permission Button อยู่ใต้ Tab
    //console.log('console.log(this_sub_menu_permission);')
    //console.log(JSON.stringify(this_sub_menu_permission));
    const this_tab = this_sub_menu_permission[0].permission_tab?.find(
      (item) => item.menu_setting.rout_path === key_tab && item.is_read === true
    )
    //console.log('console.log(this_tab);')
    //console.log(JSON.stringify(this_tab));
    if (this_tab) {
      const this_permission = this_tab.permission_other?.find((item) => item.menu_setting.rout_path === key_permission)
      if (this_permission) {
        return this_permission
      } else {
        return fix_no_permission
      }
    } else {
      return fix_no_permission
    }
  } else {
    //--- Permission Button อยู่ใต้ Submenu
    if (this_sub_menu_permission.length > 0) {
      const this_permission = this_sub_menu_permission[0].permission_other?.find(
        (item) => item.menu_setting.rout_path === key_permission
      )
      if (this_permission) {
        return this_permission
      } else {
        return key_submenu_path == '/system-configuration/access-role-management'
          ? fix_open_permission
          : fix_no_permission
      }
    } else {
      //---- เฉพาะกรณี Access Role จะเกิดปัญหาตอนเอาขึ้นเพราะแรกเริ่มไม่มีสิทนี้ แล้วจะทำให้แก้ไขไม่ได้ เลยต้องเปิดหากไม่มี
      return key_submenu_path == '/system-configuration/access-role-management'
        ? fix_open_permission
        : fix_no_permission
    }
  }
}

export const getCurrentDateTime = () => {
  const now = new Date()

  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')

  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export const getAdmin = () => {
  const admin = JSON.parse(localStorage.getItem(ADMIN)) || null
  return admin ?? null
}

export const getStartOfWeek = (d) => {
  const date = new Date(d)
  const day = date.getDay()
  const diff = date.getDate() - day + (day === 0 ? -6 : 1)
  date.setDate(diff)
  date.setHours(0, 0, 0, 0)
  return date
}
export const getEndOfWeek = (d) => {
  const date = new Date(getStartOfWeek(d))
  date.setDate(date.getDate() + 4)
  date.setHours(23, 59, 59, 999)
  return date
}

export const GetPermissionOther = (key_menu, key_permission_other) => {
  const fix_open_permission = {
    canRead: true,
    canUpdate: true,
  }
  const fix_no_permission = null
  const { menu_list } = JSON.parse(localStorage.getItem('admin'))
  let this_permission_other = null

  for (const menu_lv1 of menu_list) {
    if (menu_lv1.key == key_menu) {
      this_permission_other = menu_lv1.permission_other?.find((item) => item.key === key_permission_other)
      break
    }
    if (menu_lv1.permission_tab) {
      //--- Tab
      for (const tab_item of menu_lv1.permission_tab) {
        if (tab_item.key == key_menu) {
          this_permission_other = tab_item.permission_other?.find((item) => item.key === key_permission_other)
          break
        }
      }
    }
    if (menu_lv1.children) {
      //--- MenuLV2
      for (const menu_lv2 of menu_lv1.children) {
        if (menu_lv2.key == key_menu) {
          this_permission_other = menu_lv2.permission_other?.find((item) => item.key === key_permission_other)
          break
        }
        if (menu_lv2.permission_tab) {
          //--- Tab
          for (const tab_item of menu_lv2.permission_tab) {
            if (tab_item.key == key_menu) {
              this_permission_other = tab_item.permission_other?.find((item) => item.key === key_permission_other)
              break
            }
          }
        }
        if (menu_lv2.children) {
          //--- MenuLV3
          for (const menu_lv3 of menu_lv2.children) {
            if (menu_lv3.key == key_menu) {
              this_permission_other = menu_lv3.permission_other?.find((item) => item.key === key_permission_other)
              break
            }
            if (menu_lv3.permission_tab) {
              //--- Tab
              for (const tab_item of menu_lv3.permission_tab) {
                if (tab_item.key == key_menu) {
                  this_permission_other = tab_item.permission_other?.find((item) => item.key === key_permission_other)
                  break
                }
              }
            }
            if (menu_lv3.children) {
              //--- MenuLV4
              for (const menu_lv4 of menu_lv3.children) {
                if (menu_lv4.permission_tab) {
                  //--- Tab
                  for (const tab_item of menu_lv4.permission_tab) {
                    if (tab_item.key == key_menu) {
                      this_permission_other = tab_item.permission_other?.find(
                        (item) => item.key === key_permission_other
                      )
                      break
                    }
                  }
                }
                if (menu_lv4.key == key_menu) {
                  this_permission_other = menu_lv4.permission_other?.find((item) => item.key === key_permission_other)
                  break
                }
              }
            }
          }
        }
      }
    }
  }

  //--- เฉพาะ เมนูกำหนดสิทหากยังไม่มีการเซ็ตสิทจะเปิดหมดก่อน
  if (this_permission_other) {
    return this_permission_other
      ? this_permission_other
      : key_menu == 'access_role_management'
      ? fix_open_permission
      : fix_no_permission
  } else {
    return key_menu == 'access_role_management' ? fix_open_permission : fix_no_permission
  }
}

export const GetPermissionTabList = (key_menu) => {
  let tab_list = []
  const { menu_list } = JSON.parse(localStorage.getItem('admin'))
  for (const menu_lv1 of menu_list) {
    if (menu_lv1.key == key_menu) {
      tab_list = menu_lv1.permission_tab ? menu_lv1.permission_tab : []
      return tab_list
    }
    if (menu_lv1.children) {
      //--- MenuLV2
      for (const menu_lv2 of menu_lv1.children) {
        if (menu_lv2.key == key_menu) {
          tab_list = menu_lv2.permission_tab ? menu_lv2.permission_tab : []
          return tab_list
        }
        if (menu_lv2.children) {
          //--- MenuLV3
          for (const menu_lv3 of menu_lv2.children) {
            if (menu_lv3.key == key_menu) {
              tab_list = menu_lv3.permission_tab ? menu_lv3.permission_tab : []
              return tab_list
            }
            if (menu_lv3.children) {
              //--- MenuLV4
              for (const menu_lv4 of menu_lv3.children) {
                if (menu_lv4.key == key_menu) {
                  tab_list = menu_lv4.permission_tab ? menu_lv4.permission_tab : []
                  return tab_list
                }
              }
            }
          }
        }
      }
    }
  }
}

export const formatNumber = (number) => {
  const isInteger = number % 1 === 0

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: isInteger ? 0 : 2,
    maximumFractionDigits: isInteger ? 0 : 2,
  }).format(number)
}
