// stringHelpers.js

/**
 * Capitalize the first letter of each word in the string and make the rest of the string lower case.
 * @param {string} str - The string to capitalize.
 * @return {string} The capitalized string.
 * @example
 * capitalizeEachWord('hello world'); // returns 'Hello World'
 *
 * @note
 * This function is not affective for non-English characters.
 */
export function capitalizeEachWord(str) {
  if (typeof str !== 'string') return ''
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

/**
 * Convert a string to lower case.
 * @param {string} str - The string to convert.
 * @return {string} The lower case string.
 * @example
 * toLowerCase('HELLO WORLD'); // returns 'hello world'
 */
export function toLowerCase(str) {
  if (typeof str !== 'string') return ''
  return str.toLowerCase()
}

/**
 * Convert a string to upper case.
 * @param {string} str - The string to convert.
 * @return {string} The upper case string.
 * @example
 * toUpperCase('hello world'); // returns 'HELLO WORLD'
 */
export function toUpperCase(str) {
  if (typeof str !== 'string') return ''
  return str.toUpperCase()
}

/**
 * Reverse a string.
 * @param {string} str - The string to reverse.
 * @return {string} The reversed string.
 * @example
 * reverseString('hello world'); // returns 'dlrow olleh'
 */
export function reverseString(str) {
  if (typeof str !== 'string') return ''
  return str.split('').reverse().join('')
}

/**
 * Convert a string to snake case.
 * @param {string} str - The string to convert.
 * @return {string} The snake case string.
 * @example
 * snakeCase('Hello World'); // returns 'hello_world'
 */
export function snakeCase(str) {
  if (typeof str !== 'string') return ''
  return str.toLowerCase().replace(/[\s]/g, '_')
}

/**
 * Convert a string to camel case.
 * @param {string} str - The string to convert.
 * @return {string} The camel case string.
 * @example
 * camelCase('Hello World'); // returns 'helloWorld'
 */
export function camelCase(str) {
  if (typeof str !== 'string') return ''
  return str
    .split(' ')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase()
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    })
    .join('')
}

/**
 * Convert a string to kebab case.
 * @param {string} str - The string to convert.
 * @return {string} The kebab case string.
 * @example
 * kebabCase('Hello World'); // returns 'hello-world'
 */
export function kebabCase(str) {
  if (typeof str !== 'string') return ''
  return str.toLowerCase().replace(/[\s]/g, '-')
}
