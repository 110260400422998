import { useEffect, useState } from 'react'

import Empty from 'components/common/Empty'
import ActivityLogs from 'components/feature/operation-process/activity-logs'

const initOnActionProps = {
  onClickSW: () => {},
  onClickTask: () => {},
}

/**
 * !!!NOTE: Don't support load more logs for now
 *
 * Renders a tab for displaying logs related to a specific operation process.
 *
 * @returns {JSX.Element} The rendered LogsTab component.
 */
const LogsTab = ({
  items = [],
  onActions = initOnActionProps,
  notFoundDesc = 'There are no activities found for this SOP.',
}) => {
  const [logs, setLogs] = useState(items)

  useEffect(() => {
    setLogs(items)
  }, [items])

  return (
    <ActivityLogs style={{ padding: '0 1.25rem 1rem 1.25rem' }}>
      {logs.map((log) => (
        <ActivityLogs.LogList
          key={log.id}
          log={log}
          onClickTask={onActions.onClickTask}
          onClickSW={onActions.onClickSW}
        />
      ))}
    </ActivityLogs>
  )
}

export default LogsTab
