import { Chip, Stack, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

/** Component modules */
import Button from 'components/common/Button'
import DynamicDialog from 'components/common/DynamicDialog'
import PageHeader from 'components/common/PageHeader'
import SidePanelV2 from 'components/common/SidePanelV2'
import EditSP from 'components/feature/operation-process/form/sop/edit'

/** Redux modules */
import opActions from 'redux/actions/operation-process'
import { selectOperationProcess as selectOpProc } from 'redux/selectors'

import { SP } from 'constants/operation-process'
import FileSection from './file-section'
import InfoSection from './info-section'

const initDrawer = {
  editSOP: false,
  sopId: undefined,
}

function OPSopDetailsPage() {
  const { t } = useTranslation()
  const { id } = useParams()
  const dispatch = useDispatch()
  const opProcSelector = useSelector(selectOpProc)
  const refEditSOP = useRef(null)

  const [dialogProps, setDialogProps] = useState({ isOpen: false })
  const [drawer, setDrawer] = useState(initDrawer)

  const { sp: spActions } = opActions()
  const { getSP, postSP, putSP } = spActions()
  const { sp } = opProcSelector

  const isCanEdit = sp?.status === SP.STATUS.Open || sp?.status === SP.STATUS.InQueue

  const handleToggleDrawer = (drawerName, open, data = {}) => {
    return (event) => {
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return
      }

      setDrawer({ ...initDrawer, [drawerName]: open, ...data })
    }
  }

  const fetchSPByID = async (spId) => {
    dispatch(getSP().byId(spId))
  }

  const fetchSPLogs = async (spId) => {
    dispatch(getSP().logs(spId))
  }

  const confirmedCancelSP = (spId) => {
    dispatch(postSP().cancel(spId)).then((res) => {
      const { type, error } = res

      if (type.endsWith('_SUCCESS') && !error) {
        setDialogProps({ isOpen: false })
        fetchSPByID(spId)
        return
      }
    })
  }

  const handleCancelSP = (spId) => {
    const dialogConfirmCancel = {
      isOpen: true,
      title: 'Please confirm',
      description: 'Do you want to cancel this SOP?',
      actions: (
        <>
          <Button variant="outlined" onClick={() => setDialogProps({ isOpen: false })}>
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={() => confirmedCancelSP(spId)}>
            {t('confirm')}
          </Button>
        </>
      ),
    }

    setDialogProps(dialogConfirmCancel)
  }

  const handleEditSPSubmit = (data) => {
    const { id: spId } = data

    dispatch(putSP(spId, data)).then((res) => {
      const { data, type } = res

      if (type.endsWith('_SUCCESS') && data !== null && !res.error) {
        fetchSPByID(id)
        return
      }
    })
  }

  const pageActions = [
    <Button
      key="edit-btn"
      variant="outlined"
      disabled={!isCanEdit}
      onClick={handleToggleDrawer('editSOP', true, { sopId: id })}
      sx={{ height: 'fit-content' }}
    >
      {t('Edit')}
    </Button>,
    <Button
      key="cancel-sp-btn"
      disabled={sp?.status === SP.STATUS.Canceled || sp?.status === SP.STATUS.Completed}
      variant="contained"
      color="error"
      onClick={() => handleCancelSP(id)}
      sx={{ height: 'fit-content' }}
    >
      {t('cancel_sop')}
    </Button>,
  ]

  useEffect(() => {
    fetchSPByID(id)
    fetchSPLogs(id)
  }, [])

  return (
    <PageContainer spacing={2} sx={{ height: '100%' }}>
      <PageHeader
        title={
          <CustomHeader>
            <div className="header-title">
              <Typography variant="h4" fontWeight={600} fontSize={36} color={'var(--Gray-900)'}>
                {sp?.name}
              </Typography>

              <CustomBadge label={sp?.status || 'N/A'} color={SP.STATUS_CHIP[sp?.status || 'default']} size="medium" />
            </div>
          </CustomHeader>
        }
        supportingText={`${t('code')}: ${sp?.code || '-'}`}
        actionProps={{ direction: 'row', justifyContent: 'flex-end', spacing: 2 }}
        actions={pageActions}
      />

      <PageContent sx={{ gap: '2.5rem' }}>
        <InfoSection />
        <FileSection />
        {/** NoteSection */}
      </PageContent>

      <DynamicDialog open={dialogProps.isOpen} onClose={() => setDialogProps({ isOpen: false })} {...dialogProps} />

      <SidePanelV2
        titleText="Edit SOP"
        supportingText={`SOP Code: ${sp?.code || '...'}`}
        open={drawer.editSOP}
        onClose={handleToggleDrawer('editSOP', false)}
        footerEl={
          (sp.status === SP.STATUS.InQueue || sp.status === SP.STATUS.Open) && (
            <FooterDrawer>
              <Button variant="outlined" onClick={handleToggleDrawer('editSOP', false)}>
                {t('cancel')}
              </Button>
              <Button variant="contained" onClick={() => refEditSOP.current.submit()}>
                {t('save')}
              </Button>
            </FooterDrawer>
          )
        }
      >
        <EditSP ref={refEditSOP} spId={drawer.sopId} onSubmit={handleEditSPSubmit} />
      </SidePanelV2>
    </PageContainer>
  )
}

export default OPSopDetailsPage

const FooterDrawer = styled('footer')({
  display: 'flex',
  width: '100%',
  gap: '1rem',
  justifyContent: 'flex-end',
  padding: '1rem',
})

const CustomHeader = styled(Stack)`
  align-self: stretch;
  gap: 1rem;

  & > .header-title {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
  }
`

const CustomBadge = styled(Chip)`
  display: flex;
  width: fit-content;
  padding: 0.1rem 0.7rem 0.1rem 0.575rem;
  align-items: center;
  gap: 0.25rem;
  cursor: default;

  & .MuiChip-icon {
    margin-left: 0;
    margin-right: 0.175rem;
  }

  & .MuiChip-label {
    padding: 0;
    text-align: center;

    /* Text xs/Medium */
    font-family: 'Inter', 'Noto Sans Thai';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.125rem; /* 150% */
  }

  &.MuiChip-colorSuccess,
  &.MuiChip-colorInfo,
  &.MuiChip-colorWarning,
  &.MuiChip-colorDefault {
    border-radius: 1rem !important;
    color: var(--Gray-700, #344054);
    border: 1px solid var(--Gray-200, #eaecf0);
    background: var(--Gray-50, #f9fafb);
  }

  &.MuiChip-colorSuccess {
    color: var(--Success-700, #067647);
    border: 1px solid var(--Success-200, #abefc6) !important;
    background: var(--Success-50, #ecfdf3) !important;
  }

  &.MuiChip-colorWarning {
    color: var(--Warning-700, #b54708);
    border: 1px solid var(--Warning-200, #fedf89) !important;
    background: var(--Warning-50, #fffaeb) !important;
  }

  &.MuiChip-colorInfo {
    color: var(--Blue-700, #175cd3);
    border: 1px solid var(--Blue-200, #b2ddff) !important;
    background: var(--Blue-50, #eff8ff) !important;
  }
`

const PageContainer = styled(Stack)`
  @media screen and (max-width: 1025px) {
    padding: 1rem;
  }
`

const PageContent = styled(Stack)`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background: var(--Base-White);
`
