import { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getMileStoneTaskTypeInfoByTaskId } from '../../../../redux/actions/projectTask'
import { createDocumentTask, getTaskById } from '../../../../redux/actions/summaryTask'
import { selectSummaryTask } from '../../../../redux/selectors'
import { getAdminUser } from '../../../../utils/common'
import { dlog } from '../../../../utils/helper'
import Table from '../../../common/Table'
import Dropdown from '../../../form/Dropdown'
import SearchText from '../../../form/SearchText'
import Button from '../../../form/button/Button'
import OptionBox from '../../../widget/combo/OptionBox'
import InputUploadFile from '../../../widget/element/InputUploadFile'

export default function TaskContentDocument({
  data,
  onNewDocumentClicked,
  onViewClicked,
  onEditClicked,
  onDeleteClicked,
  onDownloadClicked,
  onRequestApproveClicked,
  onSearchFilterChange,
  isRoutine,
  onUploaded
}) {
  const dispatch = useDispatch()
  const user = getAdminUser()
  const { company_info, employee_info } = JSON.parse(localStorage.getItem('admin'))
  const { taskInfo } = useSelector(selectSummaryTask)
  //search filter
  const typeList = [
    {
      text: 'All Type',
      value: -1,
    },
    {
      text: 'Procurement',
      value: 'Procurement',
    },
  ]
  const statusList = [
    {
      text: 'All Status',
      value: -1,
    },
    {
      text: 'Active',
      value: 'active',
    },
    {
      text: 'Wait for Approve',
      value: 'waiting approve',
    },
    {
      text: 'Approve',
      value: 'approve',
    },
    {
      text: 'Reject',
      value: 'reject',
    },
  ]
  const tmpDataSearch = {
    searchText: null,
    typeSelected: null,
    statusSelected: null,
  }
  const [actionEl, setActionEl] = useState(null)
  const open = Boolean(actionEl)
  const [dataSearch, setDataSearch] = useState(tmpDataSearch)
  const [searchText, setSearchText] = useState('')
  const [taskData, setTaskData] = useState()
  const [files, setFiles] = useState([])
  const headerList = [
    {
      Header: 'Document Number',
      accessor: 'no',
      disableSortBy: false,
    },
    {
      Header: 'Document Name',
      accessor: 'name',
      disableSortBy: false,
    },
    {
      Header: 'Type',
      accessor: 'template',
      disableSortBy: false,
    },
    {
      Header: 'Status',
      accessor: 'status',
      disableSortBy: false,
    },
    {
      Header: 'Update Date',
      accessor: 'updatedAt',
      disableSortBy: false,
    },
    {
      Header: 'Create By',
      accessor: 'createBy',
      disableSortBy: false,
    },
    {
      Header: 'Action',
      accessor: 'action',
      disableSortBy: false,
    },
  ]
  const [dataList, setDataList] = useState([])

  useEffect(() => {
    if (taskInfo?.id) {
      // dlog(taskInfo, "Get Task Info")
      if (taskInfo?.procurement_templates) {
        let docTempList = data?.procurement_templates
        let viewList = docTempList.map((item, index) => ({
          ...item,
          no: item?.no,
          name: <span className={item?.template == 'Upload File' ? 'file-item' : ''} onClick={() => {
            if(item?.template == 'Upload File' && item?.file_detail){
              if(item?.file_detail?.url) {
                window.open(item?.file_detail?.url)
              }
            }
          }}>{item?.name}</span>,
          template: item?.template,
          status: makeStatusDisplay(item?.status, item),
          updatedAt: <Moment format="D / MMMM / YYYY">{item?.updatedAt}</Moment>,
          createBy: data?.createdBy?.data?.attributes?.firstname + ' ' + data?.createdBy?.data?.attributes?.lastname,
          action: (
            <OptionBox
              className={'option-box'}
              datalist={
                item?.template == 'Upload File' && item?.status == 'waiting approve'
                  ? [
                      { id: 'delete', text: 'Delete' },
                      { id: 'download', text: 'Download' },
                    ]
                  : item?.template == 'Upload File'
                  ? [
                      { id: 'delete', text: 'Delete' },
                      { id: 'download', text: 'Download' },
                      { id: 'request', text: 'Request Approve' },
                    ]
                  : item?.status == 'waiting approve'
                  ? [
                      { id: 'view', text: 'View' },
                      { id: 'edit', text: 'Edit' },
                      { id: 'delete', text: 'Delete' },
                      { id: 'download', text: 'Download' },
                    ]
                  : item?.status == 'delete'
                  ? []
                  : (item?.template != 'Upload File' && item?.procurement_items?.data?.length==0)
                  ? 
                  [
                    { id: 'view', text: 'View' },
                    { id: 'edit', text: 'Edit' },
                    { id: 'delete', text: 'Delete' },
                    { id: 'download', text: 'Download' }
                  ]
                  : [
                      { id: 'view', text: 'View' },
                      { id: 'edit', text: 'Edit' },
                      { id: 'delete', text: 'Delete' },
                      { id: 'download', text: 'Download' },
                      { id: 'request', text: 'Request Approve' },
                    ]
              }
              onSelected={(i) => handleSelectMenu(i, item)}
            />
          ),
        }))
        setDataList([...viewList])
      }
    }
  }, [taskInfo])

  useEffect(() => {
    if (data) {
      setTaskData(data)

      if (data?.procurement_templates) {
        let docTempList = data?.procurement_templates
        const viewList = docTempList.map((item, index) => ({
          ...item,
          no: item?.no,
          name: <span className={item?.template == 'Upload File' ? 'file-item' : ''} onClick={() => {
            if(item?.template == 'Upload File' && item?.file_detail){
              if(item?.file_detail?.url) {
                window.open(item?.file_detail?.url)
              }
            }
          }}>{item?.name}</span>,
          template: item?.template,
          status: makeStatusDisplay(item?.status, item),
          updatedAt: <Moment format="D / MMMM / YYYY">{item?.updatedAt}</Moment>,
          createBy: isRoutine
            ? item?.creator?.data?.attributes?.first_name + ' ' + item?.creator?.data?.attributes?.last_name
            : item?.creator?.first_name + ' ' + item?.creator?.last_name,
          action: (
            <OptionBox
              datalist={
                item?.template == 'Upload File' && item?.status == 'waiting approve'
                  ? [
                      { id: 'delete', text: 'Delete' },
                      { id: 'download', text: 'Download' },
                    ]
                  : item?.template == 'Upload File'
                  ? [
                      { id: 'delete', text: 'Delete' },
                      { id: 'download', text: 'Download' },
                      { id: 'request', text: 'Request Approve' },
                    ]
                  : item?.status == 'waiting approve'
                  ? [
                      { id: 'view', text: 'View' },
                      { id: 'edit', text: 'Edit' },
                      { id: 'delete', text: 'Delete' },
                      { id: 'download', text: 'Download' },
                    ]
                  : item?.status == 'delete'
                  ? []
                  : (item?.template != 'Upload File' && item?.procurement_items?.data?.length==0)
                  ? 
                  [
                    { id: 'view', text: 'View' },
                    { id: 'edit', text: 'Edit' },
                    { id: 'delete', text: 'Delete' },
                    { id: 'download', text: 'Download' }
                  ]
                  : [
                      { id: 'view', text: 'View' },
                      { id: 'edit', text: 'Edit' },
                      { id: 'delete', text: 'Delete' },
                      { id: 'download', text: 'Download' },
                      { id: 'request', text: 'Request Approve' },
                    ]
              }
              onSelected={(i) => handleSelectMenu(i, item)}
            />
          ),
        }))
        //status
        setDataList(viewList)
      }
    }
  }, [data])

  const handleSelectMenu = async (index, item) => {
    if (item?.template == 'Upload File') {
      if (index == 0) {
        if (onDeleteClicked) {
          onDeleteClicked(item.id)
        }
      } else if (index == 1) {
        if (item?.file_detail?.url) {
          const link = document.createElement('a')
          link.download = item.name || 'download'
          link.href = item?.file_detail?.url
          link.target = '_blank'
          link.click()
        }
      } else if (index == 2) {
        if (onRequestApproveClicked) {
          onRequestApproveClicked(item.id)
        }
      }
    } else {
      if (index == 0) {
        if (onViewClicked) {
          onViewClicked(item.id)
        }
      } else if (index == 1) {
        if (onEditClicked) {
          onEditClicked(item.id)
        }
      } else if (index == 2) {
        if (onDeleteClicked) {
          onDeleteClicked(item.id)
        }
      } else if (index == 3) {
        if (onDownloadClicked) {
          onDownloadClicked(item.id)
        }
      } else if (index == 4) {
        if (onRequestApproveClicked) {
          onRequestApproveClicked(item.id)
        }
      }
    }
  }
  const handleSearchSelected = (value, type) => {
    let tmpDataSearch = { ...dataSearch }
    if (type == 'text-search') {
      tmpDataSearch.searchText = value
      // setDataSearch({ ...dataSearch, searchText: value })
    } else if (type == 'type') {
      if (value != -1) {
        tmpDataSearch.typeSelected = value
      } else {
        tmpDataSearch.typeSelected = null
      }
    } else if (type == 'status') {
      if (value != -1) {
        tmpDataSearch.statusSelected = value
      } else {
        tmpDataSearch.statusSelected = null
      }
    }
    setDataSearch({ ...tmpDataSearch })
    dispatch(getTaskById(data?.id, tmpDataSearch))
  }
  const handleClickBtnNewDocument = () => {
    if (onNewDocumentClicked) {
      onNewDocumentClicked()
    }
  }
  const makeStatusDisplay = (status, item) => {
    if (status == 'waiting approve') {
      return 'Waiting for ' + item?.request_to?.first_name + ' to Approve'
    } else {
      return capitalizeFirstCharacter(status)
    }
  }
  const capitalizeFirstCharacter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  const onChangeUploadFile = (value) => {
    setFiles(value)

    let tmpObj = {
      file_id: value[0].id,
      template: 'Upload File',
      owner: employee_info?.id,
    }
    if (isRoutine) {
      tmpObj.task = data?.id
    } else {
      tmpObj.milestone_task_type = data?.id
    }
    dispatch(createDocumentTask({ data: tmpObj })).then(() => {
      if (onUploaded) {
        onUploaded()
      }
    })
  }
  return (
    <Styled>
      <div className="wrp-task-content-document">
        <div className="wrp-btn-document">
          <div className="left">
            <div className="box-input">
              <SearchText
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value, 'text-search')}
                onSubmit={(e) => handleSearchSelected(e, 'text-search')}
                onClear={() => {
                  setSearchText('')
                  handleSearchSelected('', 'text-search')
                }}
                // onChange={(e) => handleSearchSelected(e.target.value, 'text-search')}
              />
            </div>
            <div className="box-input">
              <div className="title-input">All Type</div>
              <Dropdown
                optionList={typeList}
                value={dataSearch.typeSelected || -1}
                onChange={(e) => handleSearchSelected(e, 'type')}
                // placeHolder={'เลือก Type'}
              />
            </div>
            <div className="box-input">
              <div className="title-input">All Status</div>
              <Dropdown
                optionList={statusList}
                value={dataSearch.statusSelected || -1}
                onChange={(e) => handleSearchSelected(e, 'status')}
                // placeHolder={'เลือก Status'}
              />
            </div>
          </div>

          <div className="right">
            <InputUploadFile
              multiple={false}
              allowedTypes={['jpg', 'png', 'pdf', 'csv', 'word', 'excel', 'zip','vdoms','mp4','vdompeg','vdowebm','adompeg','wav','adowebm','gif','xmls','ppt','txt','appleFiles']}
              onChange={onChangeUploadFile}
              placeHolder={'Upload File'}
            />

            <Button className={'btn-new-doc'} onClick={handleClickBtnNewDocument}>
              New Document
            </Button>
          </div>
        </div>

        <div className="wrp-table">
          <Table columns={headerList} data={dataList} />
        </div>
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  height: 100%;
  .wrp-task-content-document {
    height: 100%;
  }
  .bodyCellAction {
    display: flex;
    justify-content: center;
  }
  .wrp-icon-dot-horizontal {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: relative;

    svg {
      position: absolute;
      top: 15px;
      left: 8px;
      display: block;
    }
  }

  .wrp-btn-document {
    width: 100%;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;

    .left {
      flex: 1;
      display: flex;
      align-items: flex-end;

      .box-input {
        width: 100%;
        margin-right: 7px;
        .title-input {
          margin-bottom: 6px;
        }
      }
    }
    .right {
      display: flex;
      align-items: flex-end;
    }
  }

  .wrp-table {
    width: 100%;
    overflow-x: auto;
    padding: 0 24px;
    margin-top: 16px;
    height: 100%;
  }
  .wrp-table > div {
    height: 100%;
  }
  .btn-new-doc {
    margin-left: 10px;
  }
  .pd-10 {
    padding: 10px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: style none;

    .item:hover {
      background-color: var(--Primary-600);
      color: var(--Base-White);
    }
  }
  .file-item {
    cursor: pointer;
  }
  .file-item:hover {
    color: var(--Primary-600);
  }
`
