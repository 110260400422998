import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import failIcon from '../../../assets/images/fail-icon.png'
import {
  getShareDocumentUrl,
  sendEmailDocument,
} from '../../../redux/actions/document'
import { removeArrayItemByIndex } from '../../../utils/helper'
import { validateEmail } from '../../../utils/validation'
import Loading from '../../common/Loading'
import TextField from '../../form/TextField'
import Button from '../../form/button/Button'
import ButtonOutline from '../../form/button/ButtonOutline'
import CapsuleStatus from '../element/CapsuleStatus'
import ModalBox from '../element/ModalBox'
import TextEditor from '../element/TextEditor'
import IconPdf from '../icon/IconPdf'
import IconXCircle from '../icon/IconXCircle'

export default function DocumentShareAndSendEmail({ type = 'quotation', data, onClickPreview, onClose }) {
  const dispatch = useDispatch()
  const [menuTabList, setMenuTabList] = useState([
    {
      name: 'แชร์ลิงก์',
      value: 'link',
      active_status: true,
    },
    {
      name: 'อีเมล',
      value: 'e-mail',
      active_status: false,
    },
  ])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMailList, setErrorMailList] = useState([])
  const [currentMenuTabSelected, setCurrentMenuTabSelected] = useState('link')
  const [currentUrlShare, setCurrentUrlShare] = useState('')
  const [emailName, setEmailName] = useState('')
  const [emailData, setEmailData] = useState({
    to: [],
    title: '',
    message: '',
  })

  useEffect(() => {
    ;(async () => {
      if (data?.id) {
        let urlRes = await dispatch(getShareDocumentUrl(data?.id))

        if (urlRes?.data) {
          setCurrentUrlShare(urlRes?.data?.url)
        }
      }
    })()
  }, [])

  const handleClickTaskMenuTab = (menuData) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == menuData.value) {
        tmpList[i].active_status = true
      } else {
        tmpList[i].active_status = false
      }
    }
    setCurrentMenuTabSelected(menuData.value)
    setMenuTabList([...tmpList])
  }
  const handleClickBtnClickClose = () => {
    let tmpList = [...menuTabList]

    tmpList[0].active_status = true
    tmpList[1].active_status = false

    setMenuTabList([...tmpList])

    setCurrentMenuTabSelected('link')
    if (onClose) {
      onClose()
    }
  }
  const handleClickBtnSendTabEmail = async () => {
    if (emailData && emailData?.to?.length > 0 && emailData?.title != '') {
      setIsLoading(true)
      let countEmails = emailData?.to?.length
      const request = { data: emailData }
      await dispatch(sendEmailDocument(request, data?.id)).then((res) => {
        let sendEmailRes = res?.data?.data
        if (sendEmailRes?.accepted?.length == countEmails) {
          if (onClose) {
            setEmailData({ to: [], title: '', message: '' })
            setIsLoading(false)
            onClose()
          }
        } else {
          if (sendEmailRes?.rejected?.length > 0) {
            setErrorMailList([...sendEmailRes?.rejected])
            setIsLoading(false)
            setIsError(true)
          }
        }
      })
    } else {
      if (emailData?.to?.length == 0) {
        alert('กรุณากรอกอีเมลแล้วกดปุ่มเพิ่ม')
      }
    }
  }
  const handleClickBtnNewEmail = () => {
    let tmpEmailName = validateEmail(emailName)
    if (tmpEmailName == false) {
      alert('กรุณาใส่ Email ให้ถูกต้อง')
    } else {
      if (emailName != '' && emailData) {
        let tmpEmailData = { ...emailData }
        let tmpEmailList = [...tmpEmailData.to]
        tmpEmailList.push(emailName)
        tmpEmailData.to = [...tmpEmailList]
        setEmailName('')
        setEmailData(tmpEmailData)
      }
    }
  }
  const onChangTextMessage = (e, type) => {
    if (emailData) {
      let tmpEmailData = { ...emailData }
      if (type == 'title') {
        tmpEmailData.title = e.target.value
      } else if (type == 'message') {
        tmpEmailData.message = e.html
      }
      setEmailData(tmpEmailData)
    }
  }
  const handleClickDeleteEmail = (index) => {
    let tmpEmailData = { ...emailData }
    let tmpEmailList = [...tmpEmailData.to]
    if (tmpEmailList.length > 0) {
      let tmpList = removeArrayItemByIndex(tmpEmailList, index)
      tmpEmailData.to = [...tmpList]
    }
    setEmailData(tmpEmailData)
  }
  const handleCopyLink = () => {
    navigator.clipboard.writeText(currentUrlShare)
  }

  const handleClickBtnPreview = () => {
    if (onClickPreview) {
      onClickPreview()
    }
  }

  return (
    <Styled>
      {currentUrlShare && (
        <ModalBox
          // className={isPreview ? 'wrp-modal modal-share preview' : 'wrp-modal modal-share'}
          onClosed={() => handleClickBtnClickClose()}
          height="200px"
          width="700px"
          // title={'แชร์เอกสาร ' + data?.doc_id}
          title={
            <div className="title-modal-share">
              <div className="title-text">แชร์เอกสาร {data?.doc_id}</div>
              <div className="wrp-tab-menu">
                {menuTabList?.map((item, index) => (
                  <button
                    onClick={() => handleClickTaskMenuTab(item)}
                    key={'btn-' + index}
                    className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                  >
                    {item.name}
                  </button>
                ))}
              </div>
            </div>
          }
          body={
            <>
              {!isLoading && !isError && (
                <div className="body">
                  {currentMenuTabSelected == 'link' && (
                    <div>
                      <div>ลิงก์เอกสาร</div>
                    </div>
                  )}
                  {currentMenuTabSelected == 'e-mail' && (
                    <div className="wrp-input-email">
                      <div className="input-email">
                        <div className="title">ถึง:</div>
                        <div className="wrp-text-field-email">
                          <TextField onChange={(e) => setEmailName(e.target.value)} value={emailName} />

                          <Button className="btn-add-email" onClick={() => handleClickBtnNewEmail()}>
                            เพิ่ม
                          </Button>
                        </div>

                        {emailData?.to?.length > 0 && (
                          <div className="wrp-show-email-add">
                            {emailData?.to?.length > 0 &&
                              emailData?.to?.map((item, index) => (
                                <div className={'box-email '} key={'email-' + index}>
                                  <div className="item-email">
                                    <CapsuleStatus status={item} />

                                    <div className="icon-x-close-circle" onClick={() => handleClickDeleteEmail(index)}>
                                      <IconXCircle color="#9e77ed" />
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>

                      <div className="input-email">
                        <div className="title">Topic email:</div>
                        <TextField onChange={(e) => onChangTextMessage(e, 'title')} />
                      </div>

                      <div className="input-email">
                        <div className="title">ข้อความ:</div>
                        <div className="wrp-text-area-limit">
                          <TextEditor className={'text-editor'} onBlur={(e) => onChangTextMessage(e, 'message')} />
                        </div>
                        <div className="button-file-pdf">
                          <div className="file-pdf">
                            <IconPdf width="25" />
                            <div className="text-file">{data?.doc_id}.pdf</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {isLoading && (
                <div className="wrp-loading">
                  <Loading />
                  <p>กรุณารอสักครู่ ระบบกำลังดำเนินการ ...</p>
                </div>
              )}

              {isError && (
                <div className="wrp-fail-dialog">
                  <img src={failIcon} />
                  <h4>พบข้อผิดพลาด</h4>
                  <span>กรุณาตรวจสอบข้อมูล</span>
                  {errorMailList?.length > 0 && (
                    <ul>
                      {errorMailList.map((mail, index) => (
                        <li key={'email-' + index}>{mail}</li>
                      ))}
                    </ul>
                  )}
                  <Button
                    className={'btn-submit-error'}
                    onClick={() => {
                      setIsError(false)
                    }}
                  >
                    ok
                  </Button>
                </div>
              )}
            </>
          }
          footer={
            <>
              {!isLoading && !isError && (
                <div className="bottom-modal-share">
                  {currentMenuTabSelected == 'link' && (
                    <div className="footer-modal-link">
                      <div className="link-share">
                        <div className={'text-link-share'}>{currentUrlShare}</div>
                      </div>

                      <Button className={'btn-copy-link'} onClick={handleCopyLink}>
                        Copy Link
                      </Button>
                      <ButtonOutline className={'btn-preview'} onClick={handleClickBtnPreview}>
                        Preview
                      </ButtonOutline>
                    </div>
                  )}
                  {currentMenuTabSelected == 'e-mail' && (
                    <div className="footer-modal-email">
                      <div className="footer-modal-email-left">
                        <ButtonOutline onClick={handleClickBtnPreview}>Preview</ButtonOutline>
                      </div>
                      <div className="footer-modal-email-right">
                        <ButtonOutline onClick={handleClickBtnClickClose}>Cancel</ButtonOutline>
                        <Button className="btn-send" onClick={handleClickBtnSendTabEmail}>
                          Send
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          }
        />
      )}
    </Styled>
  )
}
const Styled = styled.div`
  .title-text {
    padding-bottom: 20px;
  }
  .wrp-tab-menu {
    width: fit-content;
    display: flex;
    border-bottom: 1px solid var(--Gray-200);

    .btn-menu-style {
      border: none;
      border-bottom: 2px solid transparent;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: transparent;
      color: var(--Gray-500);
    }

    .menu-active {
      border: none;
      padding: 10px 18px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      background-color: var(--Primary-50);
      border-bottom: 2px solid var(--Primary-700) !important;
      color: var(--Primary-700);
    }
  }

  .body {
    .wrp-input-email {
      .input-email {
        margin-bottom: 10px;
        .wrp-text-field-email {
          display: flex;
          align-items: center;
          .btn-add-email {
            margin-left: 10px;
          }
        }
        .wrp-show-email-add {
          display: flex;
          .box-email {
            margin-right: 10px;
            .item-email {
              margin-top: 8px;
              display: flex;
              align-items: center;
              .icon-x-close-circle {
                margin-left: 10px;
              }
            }
          }
        }
        .text-editor {
          min-height: 165px;

          .ql-toolbar {
            border-radius: 8px 8px 0 0;
          }
          .ql-container {
            border-radius: 0 0 8px 8px;
            .ql-editor {
              border-radius: 0 0 8px 8px;
            }
          }
        }
        .button-file-pdf {
          margin-top: 20px;
          border: 1px solid var(--Gray-300);
          border-radius: 8px;
          padding: 15px;
          width: fit-content;
          .file-pdf {
            display: flex;
            align-items: center;
            .text-file {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
  .bottom-modal-share {
    width: 100%;

    .footer-modal-link {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .link-share {
        width: 288px;
        border: 1px solid var(--Gray-300);
        border-radius: 8px;
        display: flex;
        align-items: center;
        .text-link-share {
          padding: 0 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .btn-copy-link,
      .btn-preview {
        margin-left: 10px;
      }
    }
  }
  .footer-modal-email {
    display: flex;
    justify-content: space-between;
    .footer-modal-email-right {
      .btn-send {
        margin-left: 10px;
      }
    }
  }

  .wrp-loading {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .wrp-fail-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 20px;
      margin-top: 0;
      margin-bottom: 10px;
    }
    ul {
      margin: 15px 0;
      list-style-type: none;
      padding-left: 0;
    }
    .btn-submit-error {
      width: 150px;
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
`
