import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { updateNoteAction } from '../../../../redux/actions/summaryTask'
import { getAdminUser } from '../../../../utils/common'
import { dlog } from '../../../../utils/helper'
import InputTextArea from '../../../widget/element/InputTextArea'
import IconArrowRightSend from '../../../widget/icon/IconArrowRightSend'
import DialogFail from '../../../dialog/DialogFail'
import failIcon from '../../../../assets/images/fail-icon.png'

export default function TaskContentNote({ data, onSubmitNote, isRoutine }) {
  const user = getAdminUser()
  const { employee_info } = JSON.parse(localStorage.getItem('admin'))
  const dispatch = useDispatch()
  const [taskData, setTaskData] = useState()
  const [noteText, setNoteText] = useState('')
  const [isSetNoteData, setIsSetNoteData] = useState(false)
  const [isSetNewTextArea, setIsSetNewTextArea] = useState(false)
  const [editorList, setEditorList] = useState([])
  const [textSearchKey, setTextSearchKey] = useState(generateNumber(5))
  const bottomRef = useRef()
  const [isShowFailedModal, setIsShowFailedModall] = useState(false)

  useEffect(() => {
    if (data) {
      setTaskData(data)
      if (isRoutine) {
        if (data?.task_notes?.data) {
          let tmpArray = data?.task_notes?.data
          setEditorList(tmpArray)
        }
      } else {
        if (data?.task_notes) {
          let tmpArray = data?.task_notes
          setEditorList(tmpArray)
        }
      }
      setIsSetNoteData(true)
      setIsSetNewTextArea(false)
    }
  }, [data, isSetNoteData])

  useEffect(() => {
    bottomRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
      //   inline: 'nearest',
    })
  }, [editorList])

  const handleClickBtnSubmit = () => {
    if (noteText != '' && user && taskData) {
      if(Object.keys(employee_info).length > 0) {
        let tmpObj = {
          note: noteText,
          // created_by: user?.id,
          created_by: employee_info?.id,
        }
        if (isRoutine) {
          tmpObj.task = taskData?.id
        } else {
          tmpObj.milestone_task_type = taskData?.id
        }
  
        const data = tmpObj
        const request = { data }
        dispatch(updateNoteAction(request)).then(() => {
          //ทุบวิว//
          setNoteText('')
          setTextSearchKey(generateNumber(5))
          //
          setIsSetNoteData(false)
          setIsSetNewTextArea(true)
          setEditorList([])
          if (onSubmitNote) {
            onSubmitNote()
          }
        })
      } else {
        setIsShowFailedModall(true)
      }
    }
    // setEditorList([
    //   ...editorList,
    //   {
    //     name: 'Chomchia Prasertwit',
    //     date: 'DD/MM/YYYY',
    //     time: 14.45,
    //     detail: 'Updated task to the product launch',
    //   },
    // ])
  }

  const displayDateFormat = (dateTime) => {
    const formattedDateTime = moment(dateTime).format('DD/MM/YYYY : hh.mm ')
    return formattedDateTime ? formattedDateTime : '-'
  }

  const generateId = (length) => {
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var result = ''
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
    }
    return result
  }

  return (
    <Styled>
      <div className="preview-text-editor">
        {editorList?.map((item, index) => (
          <div key={'note-list-item-' + generateId(5)} className="item-message">
            {isRoutine ? (
              <>
                <div className="name-date-time">
                  <div className="name">
                    {item?.attributes?.creator?.data?.attributes?.first_name +
                      ' ' +
                      item?.attributes?.creator?.data?.attributes?.last_name}
                  </div>{' '}
                  <div className="date-time">{displayDateFormat(item?.attributes?.publishedAt) + 'น.'}</div>
                </div>
                <div className="detail">{item?.attributes?.note}</div>
              </>
            ) : (
              <>
                <div className="name-date-time">
                  {item?.creator?.first_name + ' ' + item?.creator?.last_name} -{' '}
                  {displayDateFormat(item?.publishedAt) + 'น.'}
                </div>
                <div className="detail">{item?.note}</div>
              </>
            )}
          </div>
        ))}
        <div ref={bottomRef}></div>
      </div>

      <div className="bottom-content">
        {/* <TextEditor className="text-editor" /> */}

        <div className="title-text-area">Note</div>
        <div className="text-area-bottom">
          <div className="wrp-text-area">
            <InputTextArea
              // key={isSetNewTextArea && 'text-note-' + generateId(5)}
              key={textSearchKey}
              className={'input-text-area'}
              placeHolder="Write a comment"
              initValue={noteText}
              onChange={(e) => {
                setNoteText(e.target.value)
              }}
            />
          </div>

          <div className="wrp-btn-add-file-submit">
            {/* <div className="btn-add-file">
            <div className="icon-paper-clip">
              <IconPaperclip width="21" />
            </div>
            <div className="txt-btn-add-file">Add files</div>
          </div> */}
            <div className="btn-submit">
              <div className="btn-arrow-send" onClick={() => handleClickBtnSubmit()}>
                <IconArrowRightSend width="24px" />
              </div>
              {/* <Button disabled={noteText == '' ? true : false} onClick={() => handleClickBtnSubmit()}>
              Submit
            </Button> */}
            </div>
          </div>
        </div>
      </div>

      {isShowFailedModal &&
          <DialogFail
            open={Boolean(isShowFailedModal)}
            onClose={() => setIsShowFailedModall(false)}
            onSubmit={() => setIsShowFailedModall(false)}
            icon={failIcon}
            title={'You may not fully use this feature, since you are not employee.'}
            nameItem={''}
            description={''}
            textYes="Ok"
          />
        }
    </Styled>
  )
}

export function generateNumber(length) {
  var randomChars = '0123456789'
  var result = ''
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
  }
  return result
}

const Styled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .preview-text-editor {
    flex: 1;
    padding: 0 24px 24px;
    overflow: auto;
  }
  .item-message {
    margin-bottom: 24px;
  }
  .name-date-time {
    margin-bottom: 12px;
    display: flex;
  }
  .name {
    font-weight: bold;
  }
  .date-time {
    margin-left: 8px;
  }
  .detail {
    border: 1px solid var(--Gray-300);
    border-radius: 8px;
    padding: 12px;
  }
  .icon-file {
    margin: 1rem 0 2rem 2rem;
  }

  .bottom-content {
    padding: 24px;

    .text-editor {
      padding: 0 2rem;
    }

    .title-text-area {
      margin-bottom: 12px;
    }
    .text-area-bottom {
      display: flex;

      .wrp-text-area {
        display: flex;
        width: 100%;

        .input-text-area {
          flex: 1;
          border: 1px solid var(--Gray-300);
          font-size: 15px;
          resize: none;
          padding: 10px 16px;
          border-radius: 6px;
        }
      }
      .wrp-btn-add-file-submit {
        display: flex;
        /* align-items: center;
        justify-content: flex-end; */
        align-items: flex-end;
        padding: 0 0 0;
        .btn-submit {
          margin-left: 12px;

          .btn-arrow-send {
            width: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .btn-add-file {
    display: flex;
  }
`
