import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Chip from 'components/common/Chip'

export default function DepartmentOperationTabs({ tabCounts = { ['chain-link']: 0, sop: 0 }, onChange }) {
  const { t } = useTranslation()

  const items = [
    { id: 'chain-link', label: t('chain_link') },
    { id: 'sop', label: t('sop') },
  ]

  const [activeTab, setActiveTab] = useState(items[0].id)

  useEffect(() => {
    onChange && onChange(activeTab)
  }, [activeTab])

  return items.map((tab) => {
    const isActive = activeTab === tab.id

    return (
      <div key={tab.id} className={`tab-item ${isActive ? 'active' : ''}`} onClick={() => setActiveTab(tab.id)}>
        {tab.label}{' '}
        <Chip
          label={tabCounts[tab.id] > 1000 ? '999+' : tabCounts[tab.id]}
          color={isActive ? 'primary' : 'default'}
          sx={{ p: '2px 8px 2px 8px', cursor: 'pointer' }}
        />
      </div>
    )
  })
}
