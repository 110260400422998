import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'
import { useEffect, useRef, useState } from 'react'
import { pdfjs } from 'react-pdf'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import THBText from 'thai-baht-text'
import SignatureListView from '../../../../components/feature/document-setting/SignatureListView'
import IconXClose from '../../../../components/widget/icon/IconXClose'
import { getCompanyInfoById, getSaleDocumentById } from '../../../../redux/actions/document'
import { selectDocument } from '../../../../redux/selectors'
import { calculateDiscount, calculatePriceAfterTax, calculatePurchaseTotalPrice } from '../../../../utils/helper'

export default function PurchaseDocument({
  onClose,
  docType,
  type,
  id,
  docId,
  onDownloaded,
  renderData,
  linkImage,
  textCondition = '',
  settingData,
}) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

  const mockupData = {
    id: 48,
    doc_group: 'purchases',
    doc_type: 'purchase_order',
    doc_id: 'PO2024020013',
    seller: {
      id: 15,
      name: 'mama waiwai',
      address: 'ตึกมาม่าซัง',
      seller_id: 'MAMAWAIWAI',
      phone: '0891231234',
      fax: '029999888',
    },
    company: {
      id: 1,
      company_name_TH: 'บริษัท ทูบีซิมเปิ้ล จำกัด',
      company_name_EN: '2BSIMPLE',
      address: 'No.5 soi taweecherdchoo',
      contact_name: 'Tadswan Chanyarakskul',
      contact_number: '0865684670',
      postcode: '10400',
      tex_id: '0105554145191',
      createdAt: '2023-08-18T02:09:39.582Z',
      updatedAt: '2023-09-16T16:46:05.510Z',
      publishedAt: '2023-08-18T02:09:41.904Z',
      prefix_employee_id: 'E',
      num_pad_employee_id: 5,
      lv: 1,
      sub_company_no: null,
      status: 'active',
      address_EN: null,
      contact_name_EN: null,
      url_api_ofm: null,
      theme: null,
      username_field: null,
      thai_province: {
        id: 1,
        name_th: 'กรุงเทพมหานคร',
        name_en: 'Bangkok',
        geography_id: 2,
        deleted_at: null,
        createdAt: '2019-08-08T20:33:09.000Z',
        updatedAt: '2022-05-15T23:31:03.000Z',
        publishedAt: '2023-04-07T09:35:27.000Z',
      },
      thai_amphure: {
        id: 1026,
        name_th: 'เขตดินแดง',
        name_en: 'Khet Din Daeng',
        province_id: 1,
        deleted_at: null,
        createdAt: '2019-08-08T20:33:09.000Z',
        updatedAt: '2022-05-15T23:31:26.000Z',
        publishedAt: '2023-04-07T09:36:36.000Z',
      },
      thai_tambon: {
        id: 102601,
        zip_code: '10400',
        name_th: 'ดินแดง',
        name_en: 'Din Daeng',
        amphure_id: 1026,
        deleted_at: null,
        createdAt: '2019-08-08T20:33:09.000Z',
        updatedAt: '2022-05-15T23:31:31.000Z',
        publishedAt: '2023-04-07T09:37:04.000Z',
      },
    },
    number_of_copy: 1,
    company_logo: 'https://cdn.pixabay.com/photo/2016/11/07/13/04/yoga-1805784_1280.png',
    rubber_stamp: 'https://cdn.pixabay.com/photo/2013/07/13/11/53/best-seller-158885_1280.png',
    signature_list: [
      {
        label: 'ผู้จัดทำ',
        rubber_stamp: false,
      },
      {
        label: 'ผู้อนุมัติ',
        rubber_stamp: true,
      },
    ],
    purchase_date: '2024-02-29',
    payment_type: 'credit day and due date',
    credit_day: 1,
    credit_due_date: '2024-03-01',
    quotation_id: 'QT888999',
    quotation_date: '2024-03-02',
    agency: 'Sale Office',
    shipping_deadline: 'พรุ่งนี้ ไม่เกินมะรืนนี้',
    contact_person: 'คุณชาย',
    contact_phone: '0895557777',
    shipping_location: 'ตึกมาม่าซัง',
    shipping_note: 'มาม่าหมูสับรวมกับไวไวหมูสับ',
    ref_number: 'mama007',
    include_tax: false,
    product_list: [
      {
        sku: 'mama001',
        product_name: 'mama moo sub',
        quantity: 100,
        uom: 'pack',
        price: 100,
        discount_percent: '10.00',
      },
      {
        sku: 'waiwai001',
        product_name: 'waiwai moo sub',
        quantity: 100,
        uom: 'pack',
        price: 200,
        discount_percent: '10.00',
      },
    ],
    note: 'มาม่าซัง กะลามังดัง ปัง ปัง ปัง',
    discount_percent: 0,
    vat: true,
    withholding_tax_percent: 3,
  }

  const { saleDocumentData, companyData } = useSelector(selectDocument)
  const dispatch = useDispatch()
  const HEADER_TABLE_LIST = ['#', 'SKU', 'Product name', 'Qty', 'UOM', 'Unit Price', 'Discount %', 'Total']
  const [purchaseInfoData, setPurchaseInfoData] = useState()
  const [totalPrice, setTotalPrice] = useState(0) //ราคารวมสุทธิ
  const [discountPrice, setDiscountPrice] = useState(0) //ราคาส่วนลด
  const [priceAfterDiscount, setPriceAfterDiscount] = useState(0) //ราคาหลังส่วนลด
  const [priceVat, setPriceVat] = useState(0) //ราคา vat
  const [priceAfterVat, setPriceAfterVat] = useState(0)
  const [priceWithHoldingTax, setPriceWithHoldingTax] = useState(0)
  const [summaryPrice, setSummaryPrice] = useState(0)
  const [isSetDataDone, setIsSetDataDone] = useState(false)

  const pdfRef = useRef([]) // change to array ref
  const [copy, setCopy] = useState(1)

  useEffect(() => {
    if (id && type != 'render_preview') {
      dispatch(getSaleDocumentById(id))
    } else {
    }
  }, [id])
  useEffect(() => {
    ;(async () => {
      if (Object.keys(saleDocumentData).length > 0 && type != 'render_preview') {
        calculateAllPrice(saleDocumentData)
        setCopy(saleDocumentData?.number_of_copy)
        setPurchaseInfoData(saleDocumentData)
        setIsSetDataDone(true)
      }
    })()
  }, [saleDocumentData])

  useEffect(() => {
    ;(async () => {
      if (type == 'render_preview' && renderData) {
        dispatch(getCompanyInfoById())

        calculateAllPrice(renderData)
        setPurchaseInfoData(renderData)
        setIsSetDataDone(true)
      } else if (type == 'document_setting') {
        calculateAllPrice(mockupData)
        setPurchaseInfoData(mockupData)
        setCopy(1)
        setIsSetDataDone(true)
      }
    })()
  }, [])

  const calculateAllPrice = (docData) => {
    if (docData?.product_list?.length > 0) {
      let tmpProductList = [...docData.product_list]

      let currentTotalPrice = calculatePurchaseTotalPrice(tmpProductList)
      setTotalPrice(currentTotalPrice)
      //คำนวณส่วนลด

      let { discountPrice, totalPrice } = calculateDiscount(currentTotalPrice, docData?.discount_percent || 0)
      setDiscountPrice(discountPrice)
      setPriceAfterDiscount(totalPrice)

      if (docData?.include_tax) {
        let { priceAfterVat, priceVat, priceWithHoldingTax, summaryPrice } = calculatePriceAfterTax(
          true,
          false,
          docData?.withholding_tax_percent || 0,
          totalPrice
        )
        setPriceAfterVat(priceAfterVat)
        setPriceVat(priceVat)
        setPriceWithHoldingTax(priceWithHoldingTax)
        setSummaryPrice(summaryPrice)
      } else {
        let { priceAfterVat, priceVat, priceWithHoldingTax, summaryPrice } = calculatePriceAfterTax(
          false,
          docData?.vat,
          docData?.withholding_tax_percent || 0,
          totalPrice
        )
        setPriceAfterVat(priceAfterVat)
        setPriceVat(priceVat)
        setPriceWithHoldingTax(priceWithHoldingTax)
        setSummaryPrice(summaryPrice)
      }
    }
  }

  useEffect(() => {
    ;(async () => {
      if (type == 'download_pdf' && pdfRef && docId && isSetDataDone) {
        setTimeout(() => {
          handleDownloadPdf()
        }, 1000)
      }
    })()
  }, [pdfRef, isSetDataDone])

  const handleDownloadPdf = async () => {
    const element = pdfRef.current
    if (element) {
      const pdf = new jsPDF()

      for (let i = 0; i < copy; i++) {
        if (i > 0) {
          pdf.addPage()
        }

        const canvas = await html2canvas(element[i], {
          logging: true,
          letterRendering: 1,
          allowTaint: false,
          useCORS: true,
        })

        const data = canvas.toDataURL('image/png')

        const imgProperties = pdf.getImageProperties(data)
        const pdfWidth = pdf.internal.pageSize.getWidth()
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width

        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight)
      }
      pdf.save(docId + '.pdf')
      if (onDownloaded) {
        setPurchaseInfoData()
        onDownloaded()
      }
    }
  }

  const handleClickClose = () => {
    if (onClose) {
      onClose()
    }
  }
  const convertPriceToText = (price) => {
    if (price) {
      return THBText(price)
    }
    return ''
  }

  const convertIntComma = (val) => {
    if (val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
  const convertNumberToCommaTwoDecimal = (myNumber) => {
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
    const tmpTotal = Number(myNumber).toLocaleString('en', options)
    return tmpTotal
  }

  const combineAddressCompany = (company) => {
    let tmpAddress = ''
    tmpAddress =
      company?.address +
      ', ' +
      (company?.thai_province
        ? (company?.thai_province?.name_en == 'Bangkok' ? 'แขวง' : 'ตำบล ') + company?.thai_tambon?.name_th + ', '
        : '') +
      (company?.thai_amphure ? company?.thai_amphure?.name_th + ', ' : '') +
      (company?.thai_province ? company?.thai_province?.name_th + ' ' : '') +
      company?.postcode

    return tmpAddress
  }
  const formatDate = (date) => {
    const arr = date.split('-')
    return arr[2] + '-' + arr[1] + '-' + arr[0]
  }

  return (
    <Styled
      isHaveBorder={type == 'document_setting' ? true : false}
      isDownload={type == 'download_pdf' ? true : false}
      isLineBreakBorder={type == 'print' || type == 'document_setting' ? false : true}
    >
      {purchaseInfoData && (
        <>
          {(type == 'preview' || type == 'share' || type == 'render_preview') && (
            <div className="wrp-btn-close">
              <div className="btn-close" onClick={handleClickClose}>
                <IconXClose />
              </div>
            </div>
          )}

          <div className="printarea">
            {[...Array(copy)].map((item, index) => (
              <div
                className={'main-content ' + (type == 'download_pdf' ? 'download-pdf-styled' : '')}
                ref={(element) => {
                  pdfRef.current[index] = element
                }}
                key={'pdf' + index}
              >
                <div className="container-top">
                  <div className="number-of-copy-bill">{index + 1 + '/' + copy}</div>
                  <div className="header-top">
                    <div className="text-header">
                      {linkImage?.url_company_logo ? (
                        <img className="image-company-logo" src={linkImage?.url_company_logo} />
                      ) : (
                        <>
                          {purchaseInfoData?.company_logo ? (
                            <img
                              className="image-company-logo"
                              src={purchaseInfoData?.company_logo + '?' + Math.random()}
                              crossOrigin="anonymous"
                            />
                          ) : (
                            <>
                              {type == 'render_preview'
                                ? companyData?.company_name_EN
                                : purchaseInfoData?.company?.company_name_EN}
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="header-type-bill">ใบสั่งซื้อ</div>
                  </div>
                  <div className="header-detail">
                    <div className="header-detail-left">
                      <div className="header-company">
                        <div className="name-seller">
                          {type == 'render_preview'
                            ? companyData?.company_name_TH
                            : purchaseInfoData?.company?.company_name_TH}
                        </div>
                        <div className="address">
                          {type == 'render_preview'
                            ? combineAddressCompany(companyData)
                            : combineAddressCompany(purchaseInfoData?.company)}
                        </div>
                        <div className="tax-identification-number">
                          เลขประจำตัวผู้เสียภาษี{' '}
                          <span>
                            {type == 'render_preview' ? companyData?.tex_id : purchaseInfoData?.company?.tex_id}
                          </span>
                        </div>
                      </div>
                      <div className="seller">
                        <div className="distributor">ผู้ขาย</div>
                        <div className="name-seller">
                          {type == 'render_preview' ? purchaseInfoData?.name : purchaseInfoData?.seller?.name}
                        </div>
                        <div className="address">
                          {type == 'render_preview' ? purchaseInfoData?.address : purchaseInfoData?.seller?.address}
                        </div>
                        <div className="seller-contact">
                          <div className="seller-phone-fax">
                            โทร{' '}
                            {type == 'render_preview'
                              ? purchaseInfoData?.seller_phone
                              : purchaseInfoData?.seller?.phone}
                          </div>
                          <div className="seller-phone-fax">
                            โทรสาร{' '}
                            {type == 'render_preview' ? purchaseInfoData?.seller_fax : purchaseInfoData?.seller?.fax}
                          </div>
                        </div>

                        <div className="tax-identification-number">
                          รหัสผู้ขาย{' '}
                          <span>
                            {type == 'render_preview'
                              ? purchaseInfoData?.seller_id
                              : purchaseInfoData?.seller?.seller_id}
                          </span>
                        </div>
                      </div>

                      <div className="supplier-info">
                        <div>ส่งถึง {purchaseInfoData?.contact_person || '-'}</div>
                        <div>โทร {purchaseInfoData?.contact_phone || '-'}</div>
                        <div>ส่งสินค้าที่ {purchaseInfoData?.shipping_location || '-'}</div>
                        <div>หมายเหตุ {purchaseInfoData?.shipping_note || '-'}</div>
                      </div>
                    </div>

                    <div className="header-detail-right">
                      <div className="detail-quotation">
                        <div className="top-detail-quotation">
                          <div className="contact-detail">
                            <div className="contact-detail-left">เลขที่ใบสั่งซื้อ</div>
                            <div className="contact-detail-right">{purchaseInfoData?.doc_id}</div>
                          </div>
                          <div className="contact-detail">
                            <div className="contact-detail-left">วันที่</div>
                            <div className="contact-detail-right">
                              {purchaseInfoData?.purchase_date ? formatDate(purchaseInfoData.purchase_date) : '-'}
                            </div>
                          </div>

                          <div className="contact-detail">
                            <div className="contact-detail-left">กำหนดชำระเงิน</div>
                            <div className="contact-detail-right">
                              {purchaseInfoData?.payment_type == 'cash'
                                ? 'เงินสด'
                                : purchaseInfoData?.payment_type == 'credit day' ||
                                  purchaseInfoData?.payment_type == 'credit day and due date'
                                ? 'Credit ' + purchaseInfoData?.credit_day + ' วัน'
                                : '-'}
                            </div>
                          </div>

                          {/* {purchaseInfoData?.payment_type != 'cash' && (
                            <div className="contact-detail">
                              <div className="contact-detail-left">เครดิต</div>
                              <div className="contact-detail-right">
                                {purchaseInfoData?.credit_day ? purchaseInfoData?.credit_day + " วัน" : '-'}
                              </div>
                            </div>
                          )} */}

                          {/* {purchaseInfoData?.payment_type == 'credit day and due date' && (
                            <div className="contact-detail">
                              <div className="contact-detail-left">ครบกำหนด</div>
                              <div className="contact-detail-right">
                                {purchaseInfoData?.credit_due_date ? formatDate(purchaseInfoData.credit_due_date) : '-'}
                              </div>
                            </div>
                          )} */}
                          {/* <div className="contact-detail">
                            <div className="contact-detail-left">วันที่กำหนดส่ง</div>
                            <div className="contact-detail-right">
                              {purchaseInfoData?.send_due_date ? formatDate(purchaseInfoData.send_due_date) : '-'}
                            </div>
                          </div> */}
                          {/* <div className="contact-detail">
                            <div className="contact-detail-left">เงื่อนไขการชำระเงิน</div>
                            <div className="contact-detail-right">{purchaseInfoData?.payment_condition}</div>
                          </div> */}
                          {/* <div className="contact-detail">
                            <div className="contact-detail-left">ผู้สั่งซื้อ</div>
                            <div className="contact-detail-right">{purchaseInfoData?.purchaser_name}</div>
                          </div> */}
                        </div>
                        <div className="bottom-detail-quotation">
                          <div className="contact-detail">
                            <div className="contact-detail-left">ใบเสนอราคาเลขที่</div>
                            <div className="contact-detail-right">{purchaseInfoData?.quotation_id}</div>
                          </div>
                          <div className="contact-detail">
                            <div className="contact-detail-left">วันที่ตามใบเสนอราคา</div>
                            <div className="contact-detail-right">{purchaseInfoData?.quotation_date}</div>
                          </div>
                          <div className="contact-detail">
                            <div className="contact-detail-left">หน่วยงาน</div>
                            <div className="contact-detail-right">{purchaseInfoData?.agency || '-'}</div>
                          </div>
                          <div className="contact-detail">
                            <div className="contact-detail-left">กำหนดส่งสินค้า</div>
                            <div className="contact-detail-right">{purchaseInfoData?.shipping_deadline || '-'}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <table>
                    <thead>
                      <tr>
                        {HEADER_TABLE_LIST?.length > 0 &&
                          HEADER_TABLE_LIST.map((item, index) => (
                            <th className={index == 2 ? '' : 'text-center'} key={'header-table-' + index}>
                              {item}
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody className="tbody">
                      {purchaseInfoData?.product_list?.length > 0 &&
                        purchaseInfoData?.product_list?.map((item, index) => (
                          <tr key={'item-' + index}>
                            <td>{index + 1 || '-'}</td>
                            <td className={'text-center'}>{item?.sku || '-'}</td>
                            <td>{item?.product_name || '-'}</td>
                            <td className={'text-right'}>{convertIntComma(item?.quantity) || '-'}</td>
                            <td className={'text-right'}>{item?.uom || '-'}</td>
                            <td className={'text-right'}>{convertNumberToCommaTwoDecimal(item?.price) || '-'}</td>
                            <td className={'text-right'}>{item?.discount_percent + ' %' || '-'}</td>
                            <td className={'text-right'}>
                              {convertNumberToCommaTwoDecimal(
                                item?.quantity * item?.price -
                                  (item?.quantity * item?.price * item?.discount_percent) / 100
                              ) || '-'}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <div className="calculate">
                    <div className="inner-calculate-left">
                      <div className="wrp-note">
                        <div className="note">หมายเหตุ</div>
                        <div className="note-detail">{purchaseInfoData?.note}</div>
                      </div>

                      {!isNaN(summaryPrice) && summaryPrice != 0 && (
                        <div className="text-thai">({convertPriceToText(summaryPrice)})</div>
                      )}
                    </div>
                    <div className="inner-calculate-right">
                      <div className="summary">
                        <div className="top-summary">
                          <div className="detail">
                            <div className="detail-left">รวมเป็นเงิน</div>
                            <div className="detail-right">
                              {convertNumberToCommaTwoDecimal(totalPrice)}
                              <div className="text-bath">บาท</div>
                            </div>
                          </div>
                          {purchaseInfoData.discount_percent > 0 && (
                            <div className="detail">
                              <div className="detail-left">ส่วนลด {purchaseInfoData.discount_percent} %</div>
                              <div className="detail-right">
                                {convertNumberToCommaTwoDecimal(discountPrice)}
                                <div className="text-bath">บาท</div>
                              </div>
                            </div>
                          )}
                          {purchaseInfoData.discount_percent > 0 && (
                            <div className="detail">
                              <div className="detail-left">ราคาหลังส่วนลด</div>
                              <div className="detail-right">
                                {convertNumberToCommaTwoDecimal(priceAfterDiscount)}
                                <div className="text-bath">บาท</div>
                              </div>
                            </div>
                          )}
                          {purchaseInfoData?.vat && (
                            <div className="detail">
                              <div className="detail-left">ภาษีมูลค่าเพิ่ม 7%</div>
                              <div className="detail-right">
                                {convertNumberToCommaTwoDecimal(priceVat)}
                                <div className="text-bath">บาท</div>
                              </div>
                            </div>
                          )}

                          <div className="detail">
                            <div className="detail-left">
                              {purchaseInfoData?.include_tax ? 'ราคาไม่รวมภาษีมูลค่าเพิ่ม' : 'รวมจำนวนเงินทั้งสิ้น'}
                            </div>
                            <div className="detail-right">
                              {convertNumberToCommaTwoDecimal(priceAfterVat)}
                              <div className="text-bath">บาท</div>
                            </div>
                          </div>
                        </div>
                        <div className="bottom-summary">
                          {purchaseInfoData?.withholding_tax_percent > 0 && (
                            <div className="detail">
                              <div className="detail-left">
                                หักภาษี ณ ที่จ่าย {purchaseInfoData?.withholding_tax_percent} %
                              </div>
                              <div className="detail-right">
                                {convertNumberToCommaTwoDecimal(priceWithHoldingTax)}
                                <div className="text-bath">บาท</div>
                              </div>
                            </div>
                          )}
                          <div className="detail">
                            <div className="detail-left">ยอดชำระ</div>
                            <div className="detail-right">
                              {convertNumberToCommaTwoDecimal(summaryPrice)}
                              <div className="text-bath">บาท</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div>เงื่อนไขอื่นๆ</div>
                  <div className="wrp-condition-detail">
                    <div className="convert-html">
                      {type == 'render_preview' || type == 'document_setting'
                        ? textCondition
                        : purchaseInfoData?.condition}
                    </div>
                  </div> */}
                </div>

                <div className="container-bottom">
                  <SignatureListView
                    rubberStamp={
                      type == 'document_setting' ? settingData[docType]?.rubber_stamp : purchaseInfoData?.rubber_stamp
                    }
                    signatureList={
                      type == 'document_setting'
                        ? settingData[docType]?.signature_list
                        : purchaseInfoData?.signature_list
                    }
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </Styled>
  )
}

const Styled = styled.div`
  background-color: #fff;
  width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  border: ${({ isHaveBorder }) => (isHaveBorder ? '1px solid var(--Gray-300)' : 'none')};

  .wrp-btn-close {
    display: flex;
    justify-content: flex-end;
    .btn-close {
      cursor: pointer;
      padding: 5px;
    }
  }

  .main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    -webkit-print-color-adjust: exact;
    min-height: 1280px;

    border-bottom: ${({ isLineBreakBorder }) => (isLineBreakBorder ? '1px solid var(--Gray-600)' : 'none')};

    .container-top {
      padding: 24px;
    }
  }
  .download-pdf-styled {
    padding: 24px !important;
  }
  .container-top {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .number-of-copy-bill {
    text-align: end;
  }
  .header-top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .image-company-logo {
    height: 100px;
  }

  .header-detail {
    display: flex;
    justify-content: space-between;

    .header-detail-left {
      flex: 1;
      margin-right: 50px;
      .text-header {
        color: var(--Gray-700);
        font-size: 18px;
        font-weight: 600;
      }
      .text-title {
        color: var(--Gray-700);
        font-size: 12px;
        font-weight: 400;
      }
    }

    .seller {
      margin-top: 20px;
      color: var(--Gray-700);
      .distributor {
        width: 228px;
        padding: 0 12px;
        background-color: var(--Primary-700);
        color: #fff;
      }
      .seller-contact {
        display: flex;
        flex-direction: row;
        padding: 0;
      }
    }
    .supplier-info {
      margin-top: 20px;
      color: var(--Gray-700);
    }

    .header-detail-right {
      width: 350px;

      .top-detail-quotation {
        width: 100%;
        border-bottom: 1px solid var(--Gray-200);
        padding-bottom: 8px;
      }
      .bottom-detail-quotation {
        width: 100%;
        padding-top: 8px;
        display: flex;
        flex-direction: column;
      }
      .contact-detail {
        display: flex;

        .contact-detail-left {
          width: 150px;
        }
        .contact-detail-right {
          flex: 1;
          word-break: break-all;
        }
      }
    }
  }

  table {
    width: 100%;
    margin: 19px 0 23px;

    &,
    th,
    td {
      border: 1px solid var(--Gray-200);
      border-collapse: collapse;
    }
    th {
      background-color: var(--Primary-700);
      color: #fff;
    }
    th,
    td {
      text-align: start;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: end;
  }
  .tbody {
    color: black;
  }

  .calculate {
    display: flex;
    justify-content: space-between;

    .inner-calculate-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 20px;

      color: black;
      .wrp-note {
        .note-detail {
          margin-bottom: 20px;
        }
      }
    }
    .inner-calculate-right {
      /* width: 300px; */

      color: black;
      .summary {
        .top-summary {
          border-bottom: 1px solid var(--Gray-200);
          .detail {
            margin-bottom: 5px;
          }
        }
        .bottom-summary {
          .detail {
            margin-top: 5px;
          }
        }

        .detail {
          display: flex;

          .detail-left {
            display: flex;
            margin-right: 20px;
          }
          .detail-right {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            .text-bath {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .wrp-condition-detail {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .convert-html {
    white-space: pre;
  }

  canvas {
    width: 100% !important;
    height: auto !important;
  }
  .printarea {
    min-height: 1300px;
    display: flex;
    flex-direction: column;
  }
`
