import styled from 'styled-components'
import * as React from 'react'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { DateDisplay,TimeDisplayHHmm,GetPermissionTabList,GetPermissionOther } from '../../utils/common'
import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  useNavigate } from 'react-router-dom'

import { getMeeting,getMeetingRoom, updateMeeting,getMeetingRoomAll } from '../../redux/actions/dailysummary'

import { selectDailySummary } from '../../redux/selectors'
import { GetPermissionAction } from '../../utils/common'

import SearchText from '../../components/form/SearchText'
import iconPlus from '../../assets/images/icon-plus.svg'
import iconEdit from '../../assets/images/edit-icon.png'
import iconDelete from '../../assets/images/icon-bin-red.svg'
import Button from '../../components/form/button/Button'
import TableV2 from '../../components/common/TableV2'

import SidePanel from '../../components/common/SidePanel'
import NewRoom from '../../pages/my-calendar/NewRoom'
import EditRoom from '../../pages/my-calendar/EditRoom'

import DialogMeetingRoomStatus from '../../pages/my-calendar/DialogMeetingRoomStatus'

import NewMeeting from '../../pages/my-calendar/NewMeeting'


import MeetingCalendar from '../../pages/my-calendar/MeetingCalendar'
import EditActionPlan from '../../pages/my-calendar/EditActionPlan.js'
import ViewActionPlan from '../../pages/my-calendar/ViewActionPlan'
import DialogConfirmDelete from '../../components/dialog/DialogConfirmDelete'
import Date from '../../components/form/Date'
import { LiaTimesSolid } from 'react-icons/lia'
import Dropdown from '../../components/form/Dropdown'
import { GrStatusGoodSmall } from 'react-icons/gr'

import CollapseMenu from '../../components/common/CollapseMenu'

import { useTranslation } from 'react-i18next'

import failIcon from '../../assets/images/fail-icon.png'
import DialogFail from '../../components/dialog/DialogFail'

import { log_ } from '../../utils/apiLog'

const DivMyCalendar = styled.div`
  header {
    padding: 24px 14px;
    border: 1px solid var(--Primary-50);
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
  }
  .content-header-v2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  .content-title {
    font-size: 36px;
    font-weight: 600;
    color: var(--Gray-900);
  }
  .border_header{
    border-bottom: 1px solid rgb(234, 236, 240);
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .content-fillter-bg{
    min-height: 68px;
    background-color: var(--Gray-50);
    border-radius: 8px;
    margin-bottom: 21px;
    padding: 10px;
  }
  .content-fillter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
  
    .search-wrapper {
      margin-right: 12px;
    }
  
    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }
  
    .group_filter {
      display: flex;
      align-items: center;
    }
  }
  .content-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    .search-wrapper {
      margin-right: 12px;
    }

    .filter-list {
      flex-basis: 100%;
      margin-top: 24px;
    }

    .group_filter {
      display: flex;
      align-items: center;
    }
  }
  .box_search {
    margin-top: 1rem;
  }
  .mr-1-rem {
    margin-right: 1rem;
  }
  .table-wrapper {
    margin-top: 24px;
    border: 1px solid #EAECF0;
    box-shadow: var(--Shadow-xs);
    border-radius: 8px;
    background-color: #FFFFFF;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 16px 14px;
      border-radius: 8px 8px 0 0;
      background: var(--Table-Bg-Header);

      .group {
        display: flex;
        align-items: center;
      }

      .table-title {
        font-size: 18px;
        font-weight: 600;
        color: var(--Gray-900);
      }

      .table-total {
        margin-left: 16px;
        font-size: 12px;
        font-weight: 500;
        padding: 2px 8px;
        color: var(--Primary-700);
        background: var(--Primary-50);
        border-radius: 16px;
        border: 1px var(--Primary-200) solid;
      }
    }

    .create-bt {
      text-decoration: none;
    }
  }
  .cursor_pointer {
    cursor: pointer;
  }
  .button_clear_all{
    color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
  }
  .badge-search-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    margin-top: 10px;

    .badge {
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--Badge-Filter-Font);
      border: 1px solid var(--Badge-Filter-Stroke);
      background: var(--Badge-Filter-Bg);
      border-radius: 16px;
      padding: 6px;

      .name {
        margin-right: 0.5rem;
      }

      .close {
        color: var(--Badge-Filter-Close);
      }
    }
  }
  .col-no{
    font-size:14px;
    width:5%;
  }
  .col-buttonDelete{
    font-size:14px;
    width:5%;
  }
  .col-linkEdit{
    font-size:14px;
    width:5%;
  }

  .col-Title{
    font-size:14px;
    width:30%;
  }

  .col-Meeting_No{
    font-size:14px;
    width:10%;
  }
  .col-Meeting_Date{
    font-size:14px;
    width:10%;
  }
  .col-Meeting_Time{
    font-size:14px;
    width:10%;
  }
  .col-Meeting_Room{
    font-size:14px;
    width:13%;
  }

  .col-Meeting_link{
    font-size:14px;
    width:7%;
  }

  .col-Meeting_File{
    font-size:14px;
    width:5%;
  }
  
  


  .text-bold {
    font-weight: bold;
  }
  
  .link {
    color: var(--Td-Font-Color-Link);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .Onlinelink{
    font-weight: bold;
    color: rgb(127, 86, 217);
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .dropdown_css{
    min-width : 220px;
  }
.wrp-tab-menu {
    width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;

    .btn-menu-style {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;
        border-bottom: 2px solid #EAECF0;
        color: #667085;
        font-size: 14px;
    }

    .menu-active {
        border: none;
        padding: 10px 18px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 600;
        border-bottom: 2px solid var(--Primary-600) !important;
        color: #6941C6;
        background-color: #F9F5FF;
    }
}
.status-badge {
  width: 90px;
  padding: 0.2rem;
  text-align: center;
  border: 1.5px solid var(--Gray-300);
  border-radius: 6px;
  font-weight: 500;

  .status {
    font-size: 0.6rem;
    margin-right: 0.2rem;
    color: #17b26a;

    &.inactive {
      color: red;
    }
  }
}
.col-all_room_no{
  width:5%;
}  
.col-all_room_action{
  width:10%;
}
.col-all_room_Meeting_Room{
  width:50%;
}
.col-all_location{
  width:20%;
}
.col-all_room_status{
  width:15%;
}
.link_view_map{
  color: #344054;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}
.css_text_not_employee{
  color: red;
}
`

const Meeting = () => {
  const { t } = useTranslation()
  const initLang = localStorage.getItem('lang')
  const [tabactive, setTabactive] = useState('meeting_allmeeting')
  const [menuTabList, setMenuTabList] = useState([])

   //--- ST ส่วนของ Permission LV3-4
   //const { permission } = JSON.parse(localStorage.getItem('admin'))
   const permissionMeetingCreate = GetPermissionOther('meeting_allmeeting','create_meeting_allmeeting');
   const permissionMeetingDelete = GetPermissionOther('meeting_allmeeting','delete_meeting_allmeeting');
   const permissionMeetingEdit = GetPermissionOther('meeting_allmeeting','edit_meeting_allmeeting');


   const permissionSpaceCreate = GetPermissionOther('meeting_spaces','create_meeting_spaces');
   const permissionSpaceEdit = GetPermissionOther('meeting_spaces','edit_meeting_spaces');

   useEffect(() => {
    log_("MyCalendar","Meeting","enter","");
    /*
    const this_sub_menu_permission = permission['Calendar'].filter((it_2) => it_2.path === '/MyCalendar/Meeting'); //--- Path ของ SubMenu
    if(this_sub_menu_permission.length > 0){
      const tab_permission = this_sub_menu_permission[0].permission_tab?.filter((it_2) => it_2.is_read === true)
      let temp_tab = [];
      let index_tab = 0;
      for (const  objtab_permission of tab_permission) {
        temp_tab.push({
          name : initLang == 'th' ? objtab_permission.menu_setting.name_th : objtab_permission.menu_setting.name_en,
          value : objtab_permission.menu_setting.rout_path,
          active_status : index_tab == 0 ? true : false
        })
        
        if(index_tab == 0){
          setTabactive(objtab_permission.menu_setting.rout_path)
          
        }
        index_tab = index_tab + 1;
      }
      setMenuTabList(temp_tab)
    }
    */
    const this_tab_list = GetPermissionTabList('meeting');
    if(this_tab_list.length > 0){
      const tab_permission = this_tab_list?.filter((it_2) => it_2.canRead === true)
      let temp_tab = [];
      let index_tab = 0;
      for (const  objtab_permission of tab_permission) {
        temp_tab.push({
          name : initLang == 'th' ? objtab_permission.name_th : objtab_permission.name_en,
          value : objtab_permission.key,
          active_status : index_tab == 0 ? true : false
        })
        
        if(index_tab == 0){
          setTabactive(objtab_permission.key)
          
        }
        index_tab = index_tab + 1;
      }
      setMenuTabList(temp_tab)
    }
  }, [])
   //--- ST ส่วนของ Permission LV3-4
  const [TitleFail, setTitleFail] = useState('')
  const [nameItemFail, setnameItemFail] = useState('')
  const [DescriptionFail, setDescriptionFail] = useState('')
  const [failModal, setFailModal] = useState(false)

  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user,employee_info } = JSON.parse(localStorage.getItem('admin'))
  const [EmployeeIdCalendar, setEmployeeIdCalendar] = useState(employee_info?.id ? employee_info?.id : -99)
  const [currentDate, setcurrentDate] = useState(null);
  // ตัวแปรจาก STORE และตัวที่เก็บข้อมูลจากการเรียก API
  const { MeetingList,MeetingRoomList, limit, total, skip,MeetingRoomListAll, total_roomlist, skip_roomlist, limit_roomlist, isLoading } = useSelector(selectDailySummary)

  // เรียกใช้งาน API ผ่าน dispatch เช่น dispatch(getEmployee(filter))
  useEffect(() => {
    dispatch(getMeetingRoom())
  }, [dispatch])

  const fetchData = useCallback(
    async (filter) => {
      dispatch(getMeeting(filter))
    },
    [dispatch]
  )
  useEffect(() => {
    
    const filter = {
      skip: 1,
      limit: 10,
      active : 1,
    }
    fetchData(filter)
  }, [fetchData])

  //---- ST MeetingRoomAll
  const fetchDataRoomAll = useCallback(
    async (filter) => {
      dispatch(getMeetingRoomAll(filter))
    },
    [dispatch]
  )
  useEffect(() => {
    
    const filter = {
      skip: 1,
      limit: 10,
    }
    fetchDataRoomAll(filter)
  }, [fetchDataRoomAll])

  const [page_roomlist, setPage_roomlist] = useState(1)
  const [totalPages_roomlist, setTotalPages_roomlist] = useState(0)
  const [TableCellData_roomlist, setTableCellData_roomlist] = useState([])
  const TableColumn_roomlist = [
    {
      Header: t('no'),
      accessor: 'all_room_no',
      disableSortBy: false,
    },
    {
        Header: t('meeting_room'),
        accessor: 'all_room_Meeting_Room',
        disableSortBy: false,
    },
    {
      Header: t('location_map_link'),
      accessor: 'all_location',
      disableSortBy: false,
  },
    {
      Header: t('status'),
      accessor: 'all_room_status',
      disableSortBy: false,
    },
    {
      accessor: 'all_room_action',
      disableSortBy: false,
    },
  ]

  useEffect(() => {
    
    const newTableCellData = MeetingRoomListAll.map((v, index) => {
      const list = [
        {
          text: t('Edit'),
          action: () => EditDataMeetingRoom(v),
        },
        {
          text: t('change_status'),
          action: () => ChangeStatus(v.id,v.active),
        },
      ]
      const listNoEdit = [
        {
          text: t('change_status'),
          action: () => ChangeStatus(v.id,v.active),
        },
      ]
      return {
        all_room_no: index + 1,
        all_room_Meeting_Room: (
          <div className="text-bold">
            {v.name_th}
          </div>
        ),
        all_location: v.location ? <div className="link_view_map" onClick={() => OnLinkUrl(v.location)}>view map</div> : '-',
        all_room_status: (
          <div className="status-badge">
            <GrStatusGoodSmall className={`status ${v.active == false ? 'inactive' : ''}`} />
            {v.active == false ? 'inactive' : 'active'}
          </div>
        ),
        all_room_action: <CollapseMenu list={ permissionSpaceEdit?.canRead === true ? list : listNoEdit} />
      }
    })
    setTableCellData_roomlist(newTableCellData)
  }, [MeetingRoomListAll])

  useEffect(() => {
    const totalPage = Math.ceil(total_roomlist / limit_roomlist)
    setTotalPages_roomlist(totalPage)
  }, [total_roomlist, skip_roomlist, limit_roomlist])

  const [search, setSearch] = useState('')
  const [searchFilters, setSearchFilters] = useState([])
  const onSearchSpace = () => {
    const newFilter = {
      key: uuidv4(),
      name: search,
    }
    const filter = {
      skip: 1,
      limit: 10,
      meeting_room_name:[...searchFilters, newFilter].map((v) => v.name).join(','),
    }
    fetchDataRoomAll(filter)
    setPage_roomlist(1)
    setSearchFilters((prev) => [...prev, newFilter])
  }

  const onClearSearchSpace = () => {
    setSearch('')
  }


  const deleteSearchBadgeSpace = (key) => {
    
    const newSearchFilters = searchFilters.filter((v) => v.key != key)
    setSearchFilters(newSearchFilters)
    
    const filter = {
      skip: 1,
      limit: 10,
      meeting_room_name: newSearchFilters.map((v) => v.name).join(','),
    }
    fetchDataRoomAll(filter)
    setSearch('')
    setPage_roomlist(1)
    
  }

  const onClearAllSearchSpace = () => {
    setSearchFilters([])
    const filter = {
      skip: 1,
      limit: 10,
    }
    fetchDataRoomAll(filter)
    setSearch('')
    setPage_roomlist(1)
  }

  const onChangePagination_roomlist = async (value) => {
    
    const filter = {
      skip: value.page,
      limit: limit_roomlist,
      meeting_room_name: searchFilters.map((v) => v.name).join(','),
    }
    fetchDataRoomAll(filter)
    setPage_roomlist(value.page)
  }

  const [ModalCreateRoom, setModalCreateRoom] = useState(false)
  const OpenCreateModalRoom = () => {
    
    if(permissionSpaceCreate?.canUpdate === true){
      setModalCreateRoom(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionSpaceCreate?.name_en ;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionSpaceCreate?.name_th;
      }
      setTitleFail(message_permission);
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }
  }

  const CloseModalCreateRoom = () => {
    setModalCreateRoom(false)
  }

  const SubmitModalCreateRoom = () => {
    setModalCreateRoom(false)
  }

  const [MeetingRoomId, setMeetingRoomId] = useState('')
  const [MeetingRoomStatus, setMeetingRoomStatus] = useState('')
  const [ModalEditRoom, setModalEditRoom] = useState(false)
  const [DataEdit, setDataEdit] = useState('')
  const EditDataMeetingRoom = (data) => {
    
    if(permissionSpaceEdit?.canUpdate === true){
      setDataEdit(data)
      setModalEditRoom(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionSpaceEdit?.name_en;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionSpaceEdit?.name_th;
      }
      setTitleFail(message_permission);
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }
  }

  const [ModalChangeStatusMeetingRoom, setModalChangeStatusMeetingRoom] = useState(false)
  const ChangeStatus = (id,data_status) => {
    setMeetingRoomId(id)
    if(data_status){
      setMeetingRoomStatus('active')
    }else{
      setMeetingRoomStatus('inactive')
    }
    
    setModalChangeStatusMeetingRoom(true)
  }

  
  const CloseChangeStatusMeetingRoomModal = () => {
    setModalChangeStatusMeetingRoom(false)
  }

  const SubmitChangeStatusMeetingRoomProcess = () => {
    setModalChangeStatusMeetingRoom(false)
  }
  //---- ED MeetingRoomAll


  


  //-- ST Filter
  
  const onChangecurrentDate = (value) => {
    
    setcurrentDate(value)
    const filter = {
      skip: 1,
      limit: limit,
      active : 1,
      filter_date : value,
      meeting_room : fillterMeetingSpace
    }
    
    fetchData(filter)
    setPage(1)
  }

  const onClearAllSearch = () => {
    setfillterMeetingSpace(null)
    setcurrentDate(null)
    const filter = {
      skip: 1,
      limit: 10,
      active : 1,
    }
    fetchData(filter)
    setSearch('')
    setPage(1)
  }

  //-- ED Filter


  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [TableCellData, setTableCellData] = useState([])
  const TableColumn = [
    {
      Header: t('no'),
      accessor: 'no',
      disableSortBy: false,
    },
    {
        Header: t('title'),
        accessor: 'Title',
        disableSortBy: false,
    },
    {
        Header: t('meeting_no'),
        accessor: 'Meeting_No',
        disableSortBy: false,
    },
    {
      Header: t('date'),
      accessor: 'Meeting_Date',
      disableSortBy: false,
    },
    {
        Header: t('time'),
        accessor: 'Meeting_Time',
        disableSortBy: false,
    },
    {
        Header: t('meeting_room'),
        accessor: 'Meeting_Room',
        disableSortBy: false,
    },
    {
        Header: t('online_link'),
        accessor: 'Meeting_link',
        disableSortBy: false,
    },
    {
        Header: t('file'),
        accessor: 'Meeting_File',
        disableSortBy: false,
    },
    
    {
      accessor: 'buttonDelete',
      disableSortBy: true,
    },
    {
      accessor: 'linkEdit',
      disableSortBy: true,
    },
  ]

  // bind ข้อมูลที่ได้จาก API โดยรับมาจาก ตัวแปรของ Store ที่ประกาศไว้
  useEffect(() => {
    const newTableCellData = MeetingList.map((v, index) => ({
      ...v,
      no: index + 1,
      Title: (
        <div className="text-bold link" onClick={() => EditData(v.id)}>
          {v.title}
        </div>
      ),
      Meeting_No: v.meeting_no ? v.meeting_no : '-',
      Meeting_Date: DateDisplay(v.meeting_date),
      Meeting_Time: TimeDisplayHHmm(v.meeting_date_start) + ' - ' + TimeDisplayHHmm(v.meeting_date_end),
      Meeting_Room: v.meeting_room_name ? v.meeting_room_name : '-',
      Meeting_link: v.online_meeting_link ? <div className="Onlinelink" onClick={() => OnLinkUrl(v.online_meeting_link)}>Link</div> : '-',
      Meeting_File: '0',
      buttonDelete: permissionMeetingDelete?.canRead === true ? <img className="cursor_pointer" src={iconDelete} title="Delete" onClick={() => DelData(v.id)} /> : '-',
      linkEdit: permissionMeetingEdit?.canRead === true ? <img className="cursor_pointer" src={iconEdit} title="Edit" onClick={() => EditData(v.id)} />  : '-',
    }))
    setTableCellData(newTableCellData)
  }, [MeetingList])

  const [TypeDel, setTypeDel] = useState('Action Plan')
  const [ThisId, setThisId] = useState('')
  const [ModalConfirmDel, setModalConfirmDel] = useState(false)
  const [ModalEditData, setModalEditData] = useState(false)
  /*
  const EditData = (data) => {
    //alert(data)
    setThisId(data)
    setModalEditData(true)
  }
  */

  const EditData = useCallback(
    (id) => {
      // dispatch(getApplicationFormById(id))
      // setIsOpenSidePanelEdit(true)
      // setApplicationId(id)
      if(permissionMeetingEdit?.canUpdate === true){
        log_("MyCalendar","view Meeting","click","{id:"+id+"}");
        navigate(`/MyCalendar/meeting-form/${id}`)
      }else{
        let message_permission = 'No permission allowed ' + permissionMeetingEdit?.name_en;
        if(initLang == 'th'){
          message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionMeetingEdit?.name_th;
        }
        setTitleFail(message_permission);
        setnameItemFail('');
        setDescriptionFail(''); 
        setFailModal(true)
      }
      
    },
    [dispatch,permissionMeetingEdit]
  )

  const DelData = (data) => {
    //alert(data)
    
    if(permissionMeetingDelete?.canUpdate === true){
      setThisId(data)
      setModalConfirmDel(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionMeetingDelete?.name_en;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionMeetingDelete?.name_th;
      }
      setTitleFail(message_permission);
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }
  }
  const CloseModalConfirmDel = () => {
    setModalConfirmDel(false)
  }
  const SubmitModalConfirmDel = () => {
    const sent_data_api = {
      data: { active: 0 },
    }
    dispatch(updateMeeting(ThisId, sent_data_api)).then(({ type }) => {
      if(type.endsWith('_SUCCESS')){
        onClearAllSearch()
        log_("MyCalendar","del Meeting","click","{id:"+ThisId+"}");
        setModalConfirmDel(false)
      }    
    })
    
  }

  const SubmitModalEditData = () => {
    setModalEditData(false)
  }

  const CloseModalEditData = () => {
    setModalEditData(false)
  }

  useEffect(() => {
    const totalPage = Math.ceil(total / limit)
    setTotalPages(totalPage)
  }, [total, skip, limit])



  const [ModalCreate, setModalCreate] = useState(false)
  const OpenCreateModal = () => {
    if(permissionMeetingCreate?.canUpdate === true){
      log_("MyCalendar","create Meeting","click","");
      setModalCreate(true)
    }else{
      let message_permission = 'No permission allowed ' + permissionMeetingCreate?.name_en ;
      if(initLang == 'th'){
        message_permission = 'คุณไม่มีสิทธิ์เข้าใช้งาน ' + permissionMeetingCreate?.name_th;
      }
      setTitleFail(message_permission);
      setnameItemFail('');
      setDescriptionFail(''); 
      setFailModal(true)
    }
    
  }

  const CloseModalCreate = () => {
    setModalCreate(false)
  }

  const SubmitModalCreate = () => {
    log_("MyCalendar","submit Meeting","click","");
    setModalCreate(false)
    onClearAllSearch()
  }

  const [ModalViewActionPlan, setModalViewActionPlan] = useState(false)
  const [ActionPlanId, setActionPlanId] = useState('')
  const OnLinkUrl = (Link_URL) => {
    //alert('OnLinkUrl' + Link_URL)
    window.open(Link_URL, '_blank', 'noreferrer');
  }
  //--- View ActionPlan
  const OnModalViewActionPlan = (id) => {
    setActionPlanId(id)
    setModalViewActionPlan(true)
  }
  const CloseModalViewActionPlan = () => {
    setModalViewActionPlan(false)
  }

  // Function การใช้งานอื่นๆ
  const onChangePagination = async (value) => {
    
    const filter = {
      skip: value.page,
      limit: limit,
      active : 1,
      filter_date : currentDate,
      meeting_room : fillterMeetingSpace
    }
    fetchData(filter)
    setPage(value.page)
  }
  const [MeetingSpace_option, setMeetingSpace_option] = useState([])

  useEffect(() => {
    const newProvinces = MeetingRoomList.map((v) => ({
      text: v.name_th,
      value: v.id,
    }))
    setMeetingSpace_option([...newProvinces])
  }, [MeetingRoomList])

  const [fillterMeetingSpace, setfillterMeetingSpace] = useState(null)
  const onfillterMeetingSpace = (v) => {
    
    setfillterMeetingSpace(v)
    const filter = {
      skip: 1,
      limit: limit,
      active : 1,
      filter_date : currentDate,
      meeting_room : v
    }
    
    fetchData(filter)
  }
  const [ModalMeetingCalendar, setModalMeetingCalendar] = useState(false)
  const [MeetingData, setMeetingData] = useState({
    meeting_type : null,
    meeting_room : null,
    meeting_space : '',
    title : null,
    meeting_no : null,
    meeting_date : dayjs().format('YYYY-MM-DD'),
    time_start:'00.00',
    time_end:'00.00',
    online_meeting_link : null,
    online_meeting_id : null,
    online_meeting_code : null,
    contact_person : null,
    google_map_link : null,
    note : null,
    project : null,
    agenda_detail : null,
    created_by_id : user.id,
    employee : employee_info?.id
  })
  const [WidthMeetingCalendar, setWidthMeetingCalendar] = useState('1400')
  const onOpenMeetingCalendar = () => {
    setWidthMeetingCalendar(window.innerWidth - 80)
    setModalMeetingCalendar(true)
  }
  const CloseModalMeetingCalendar = () => {
    setModalMeetingCalendar(false)
    setModalCreate(true)
  }
  const SubmitModalMeetingCalendar = () => {
    setModalMeetingCalendar(false)
    setModalCreate(true)
  }

  const handleClickTaskMenuTab = (data) => {
    let tmpList = [...menuTabList]
    for (let i = 0; i < tmpList.length; i++) {
      if (tmpList[i].value == data.value) {
        tmpList[i].active_status = true
        setTabactive(tmpList[i].value)
      } else {
        tmpList[i].active_status = false
      }
    }
    setMenuTabList([...tmpList])
  }

  return (
    <DivMyCalendar>
      <div className="content-header-v2">
        <div className="content-title">{t('meeting')}</div>
        <div>
            <div className="wrp-tab-menu">
                {menuTabList?.map((item, index) => (
                <button
                    id={'this_name-' + item.name}
                    key={'btn-' + index}
                    className={item.active_status ? 'menu-active' : 'btn-menu-style'}
                    onClick={() => handleClickTaskMenuTab(item)}
                >
                    {item.name}
                </button>
                ))}
            </div>
        </div>
      </div>
      
      {tabactive == 'meeting_allmeeting' && (
      <div>
      <div className="">{t('feature_meeting_sub_header')}</div>
      <div className="border_header"></div>
      <div className="content-fillter-bg">
          <div className="content-fillter">
            <div className="group_filter">
              <div>
                    <Dropdown
                      id={`fillterMeetingSpace`}
                      className="dropdown_css mr-1-rem"
                      value={fillterMeetingSpace}
                      optionList={MeetingSpace_option}
                      onChange={onfillterMeetingSpace}
                      disabled={!true}
                      placeHolder={t('all_room')}
                      ValueplaceHolderIsNull={true}
                    />
              </div>
              <div>
                <Date className={'mr-1-rem'} value={currentDate} onChange={onChangecurrentDate} />
              </div>
              <div>
                <Button onClick={onClearAllSearch} className="button_clear_all mr-1-rem">
                 {t('clear_all')}
                </Button>
              </div>
              
            </div>
            {permissionMeetingCreate?.canRead === true && (
              <div className="container">
                

                {EmployeeIdCalendar > 0 && (
                  <Button append={<img src={iconPlus} />} onClick={OpenCreateModal}>
                    {t('create_new_meeting')}
                  </Button>
                )}
                {EmployeeIdCalendar < 0 && (
                  <div className="css_text_not_employee">{t('you_may_not_fully_use_this_feature_since_you_are_not_employee')}</div>
                )}
      
              </div>
            )}
          </div>
        </div>

      <div className="table-wrapper">
        <div className="table-header">
          <div className="group">
            <div className="table-title">{t('manage')} {t('all_meeting')}</div>
            <div className="table-total">{total} {t('meeting')}</div>
          </div>
          
        </div>
        <TableV2
          columns={TableColumn}
          data={TableCellData}
          onStateChange={onChangePagination}
          pageCount={totalPages}
          page={page}
          loading={false}
        />
      </div>
      </div>
      )}
      {tabactive == 'meeting_spaces' && (
        <div>
          <div className="">{t('meeting_space_management_sub_header')}</div>
          <div className="border_header"></div>
          <div className="content-fillter-bg">
            <div className="content-fillter">
              <div className="group_filter">
                <div>
                  <SearchText
                    className="search-wrapper"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onSubmit={onSearchSpace}
                    onClear={onClearSearchSpace}
                  />
                </div>
                <div>
                  <Button onClick={onClearAllSearchSpace} className="button_clear_all mr-1-rem">
                    {t('clear_all')}
                  </Button>
                </div>
                
              </div>

              <div className="container">
              </div>

            </div>
            <div className="badge-search-content">
                  {searchFilters.map((v) => (
                    <div className="badge" key={v.key}>
                      <span className="name">{v.name}</span>{' '}
                      <LiaTimesSolid size={15} className="icon close" onClick={() => deleteSearchBadgeSpace(v.key)} />
                    </div>
                  ))}
            </div>
          </div>

          <div className="table-wrapper">
            <div className="table-header">
              <div className="group">
                <div className="table-title">{t('meeting_space_list')}</div>
                <div className="table-total">{total_roomlist} {t('spaces')}</div>
              </div>
              <div>
                {permissionSpaceCreate?.canRead === true && (
                  <Button append={<img src={iconPlus} />} onClick={OpenCreateModalRoom}>
                    {t('create_new_space')}
                  </Button>
                )}
              </div>
            </div>
            <TableV2
              columns={TableColumn_roomlist}
              data={TableCellData_roomlist}
              onStateChange={onChangePagination_roomlist}
              pageCount={totalPages_roomlist}
              page={page_roomlist}
              loading={false}
            />
          </div>
        </div>
      )}


      <SidePanel isOpen={ModalCreate} setIsOpen={setModalCreate} width={400}>
        <NewMeeting width={400} onClose={CloseModalCreate} onSubmit={SubmitModalCreate} onReserveSpace={onOpenMeetingCalendar} MeetingData={MeetingData} setMeetingData={setMeetingData} />
      </SidePanel>

      <SidePanel isOpen={ModalMeetingCalendar} setIsOpen={setModalMeetingCalendar} width={WidthMeetingCalendar}>
          <MeetingCalendar width={WidthMeetingCalendar} onClose={CloseModalMeetingCalendar} onSubmit={SubmitModalMeetingCalendar} MeetingData={MeetingData} setMeetingData={setMeetingData} />
      </SidePanel>

      <SidePanel isOpen={ModalEditData} setIsOpen={setModalEditData} width={800}>
        <EditActionPlan onClose={CloseModalEditData} onSubmit={SubmitModalEditData} ActionPlanId={ThisId} />
      </SidePanel>
      <SidePanel isOpen={ModalViewActionPlan} setIsOpen={setModalViewActionPlan} width={800}>
          <ViewActionPlan
            onClose={CloseModalViewActionPlan}
            onSubmit={EditData}
            ActionPlanId={ActionPlanId}
          />
        </SidePanel>


        <SidePanel isOpen={ModalCreateRoom} setIsOpen={setModalCreateRoom} width={400}>
          <NewRoom width={400} onClose={CloseModalCreateRoom} onSubmit={SubmitModalCreateRoom} />
        </SidePanel>

        <SidePanel isOpen={ModalEditRoom} setIsOpen={setModalEditRoom} width={400}>
            <EditRoom width={400} onClose={() => setModalEditRoom(false)} onSubmit={() => setModalEditRoom(false)} DataEdit={DataEdit} />
        </SidePanel>


        <DialogConfirmDelete
          open={Boolean(ModalConfirmDel)}
          onClose={() => setModalConfirmDel(false)}
          onSubmit={SubmitModalConfirmDel}
          title={t('confirm_delete_meeting_header')}
          nameItem=""
          description=""
        />

      <DialogMeetingRoomStatus
        OpenModal={ModalChangeStatusMeetingRoom}
        onClose={CloseChangeStatusMeetingRoomModal}
        onSubmit={SubmitChangeStatusMeetingRoomProcess}
        MeetingRoomId={MeetingRoomId}
        ThisStatus={MeetingRoomStatus}
      />

      <DialogFail
          open={Boolean(failModal)}
          onClose={() => setFailModal(false)}
          onSubmit={() => setFailModal(false)}
          icon={failIcon}
          title={TitleFail}
          nameItem={nameItemFail}
          description={DescriptionFail}
          textYes={t('ok')}
        />
    </DivMyCalendar>
  )
}

export default Meeting
